import React, {Component, Fragment} from "react";
import {Checkbox, Dropdown, Menu, message, Table, Tooltip, Button} from "antd";
import {DownOutlined} from "@ant-design/icons";
import TransactionRender from 'B/components/TransactionHistory';
import { formatPercent, formatCurrencyNumber, I} from 'Utils';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import {noPermission} from 'components/Permission';
import {formatDate} from "Utils/format/formatDate";
import openCertificateModal from 'components/CertificateDetail';
import { post } from 'srcPath/http';
import Loading from 'components/Loading';
import { LOADING,certificateStatus } from "Utils/constant";
class OptionTable extends Component {
    state = {
        loading : false,
        selectedRowKeys : [],
        selectColumnFormat : {},
    }

    componentDidMount(){
        const { onRef } = this.props;
        onRef(this);
        this.initFilterColumn();
    }

    initFilterColumn = () => {
        const tableColumn = this.tableColumns();
        const { saveState } = this.props;
        saveState('tableColumn', tableColumn);
    }

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    setCurSelectSecurity = ( index) => {
        const { setCurSelectSecurity } = this.props;
        setCurSelectSecurity( index);
    }

    /**
     * vested_bar 列展示内容
     * @param value
     * @returns {JSX.Element}
     */
    getProgressBar = (value) =>{
        const barWidth = formatPercent(value.vested_quantity / value.quantity);
        return <div className="progress option-list-vested-bar">
            <div style={{width: barWidth}} className="progress-bar option-progress-bar" role="progressbar" aria-valuenow={value.vested_quantity / value.quantity} aria-valuemin="0" aria-valuemax="100">
                <span className="sr-only">{barWidth} Complete</span>
            </div>
        </div>
    }

    /**
     * 表格最后一列菜单配置
     * @param data
     * @param index
     * @returns {JSX.Element}
     */
    dropdownInfo = (text, data, index) => {
        const { visibleStatus } = data;
        return (
            <Dropdown
                visible={visibleStatus}
                overlay={this.menu( data, index)}
                onVisibleChange={this.handleVisibleChange}
                placement="bottomRight"
                trigger="click"
                onClick={(e)=>{
                    e.stopPropagation();
                    this.setCurSelectSecurityNew(text, data, index);
                }}>
                <div className="tableMenu"><DownOutlined style={{ color: '#FFF' }}/></div>
            </Dropdown>
        );
    }

    /**
     * 更新列表中的菜单显示状态
     * @param index 列表下标
     * @param flag 菜单显示状态
     */
    updateSecurityListsVisible = (data, flag) => {
        const { securityLists, saveState } = this.props;
        let list = [...securityLists];
        const index = list.findIndex((v,k,arr)=>{
            return v.id == data.id
        })
        if(flag === false) {
            list.forEach((v, k) => {
                list[k]['visibleStatus'] = false;
            });
        }
        if(data !== false){
            list[index]['visibleStatus'] = true;
        }
        saveState('optionLists', list);

    }

    handleVisibleChange = flag => {
        this.updateSecurityListsVisible(false, flag);
    }

    /**
     * 菜单
     * @param data
     * @param index
     * @returns {JSX.Element}
     */
    menu = (data, index) => {
        const { notes } = this.props;
        const isCanceled = data.status == "Canceled" ? 1 : 0;
        const isExercisable = data.status.substring(0,11) == "Exercisable" ? 1 : 0;
        const isModifyVesting = data.vesting_schedule_id == 0 && data.custom_schedule_id == 0 ? 0 : 1;
        const reverseCancellation = data.status=="Canceled" || data.status=="Repurchased" ? 1 : 0;
        const canExercise = data.status == "Partially exercised" || data.status == "Outstanding" || isExercisable == 1 ? 1 : 0;
        const isEmptyTerminationDate = data.termination_date =="" ? 1 : 0;
        const isConvertAtIpo = data.status =="Convert at IPO" ? 1 : 0;
        const hasBeenExercised = data.status =="Partially exercised" || data.status == "Fully exercised" ? 1 : 0;
        const addOrEdit = ( notes == ""||null ) ? __("Add certificate notes"): __("Edit certificate notes");
        const docu_sign_id = I(data.docu_sign_id, '');
        const docu_sign_status = I(data.docu_sign_status, 0);
        const showResendDocusign = !!(docu_sign_id && (docu_sign_status == 0 || docu_sign_status == 3 || docu_sign_status == 4) && docu_sign_status != 1 && docu_sign_status != 2 && docu_sign_status != 7);

        return (
            <Menu >
                { (noPermission('securities_EA_modify') === false ) && <Menu.Item ><a onClick={this.stopDetailModel.bind(this,data,index)} data-toggle="modal" data-target="#modifyOptionsModal">{__('Modify option grant')}</a></Menu.Item> }
                { (noPermission('securities_EA_vestingSchedule') === false ) && ( data.type !== "ps" && hasBeenExercised==0 && isModifyVesting==0 && isConvertAtIpo ==0 && <Menu.Item ><a onClick={this.stopDetailModel.bind(this,data,index)} data-toggle="modal" data-target="#addVestingScheduleModal">{__('Add vesting schedule')}</a></Menu.Item> )}
                { (noPermission('securities_EA_vestingSchedule') === false ) && ( data.type !== "ps" && hasBeenExercised==0 && isModifyVesting==1 && isConvertAtIpo ==0 &&<Menu.Item ><a onClick={this.getVestingData.bind(this,data,index)}  data-toggle="modal" data-target="#addVestingScheduleModal">{__('Modify vesting schedule')}</a></Menu.Item> )}
                { (noPermission('securities_EA_vestingSchedule') === false ) && isModifyVesting==1 && hasBeenExercised==0 && data.isMilestone==1 && data.status!="Canceled" && isConvertAtIpo ==0 &&<Menu.Item ><a data-toggle="modal" onClick={this.getVestMilestone.bind(this, data, index)} data-target="#vestMilestonesModal">{__('Vest milestones')}</a></Menu.Item>}
                { (noPermission('securities_EA_vestingSchedule') === false ) && isModifyVesting==1 && hasBeenExercised==0 && data.isMilestone==0 && data.status!="Canceled" && isConvertAtIpo ==0 &&<Menu.Item ><a data-toggle="modal" onClick={this.getInitVestingList.bind(this, data, index)} data-target="#accelerateVestingModal">{__('Accelerate vesting')}</a></Menu.Item>}
                { (noPermission('securities_EA_achieve') === false ) && isCanceled == 0 && canExercise == 1  && isConvertAtIpo ==0 &&<Menu.Item ><a data-toggle="modal"  onClick={this.getQuantityToExercise.bind(this, data, index)} data-target="#exerciseOptionModal">{__('Exercise options')}</a></Menu.Item>}
                { (noPermission('securities_EA_achieve') === false && isConvertAtIpo == 1 ) && <Menu.Item ><a onClick={this.convertToShares.bind(this,data,index)} >{__('Convert to shares')}</a></Menu.Item>}
                { (noPermission('securities_EA_achieve_cancel') === false ) && (data.status === "Outstanding" && isConvertAtIpo ==0 &&<Menu.Item ><a onClick={this.stopDetailModel.bind(this, data, index)} data-toggle="modal" data-target="#cancelModal">{__('Cancel option grant')}</a></Menu.Item> )}
                { (noPermission('securities_EA_achieve_reverse') === false ) && (reverseCancellation == 1 && isConvertAtIpo ==0 &&<Menu.Item ><a onClick={this.reverseCancellation.bind(this, data, index)} className="reverse-btn text-muted">{__('Reverse cancellation')}</a></Menu.Item> )}
                { (noPermission('securities_EA_achieve_reverse') === false ) && (reverseCancellation == 0 && isConvertAtIpo ==0 && <Menu.Item className="shares-table-menu-item"><a onClick={this.stopDetailModel.bind(this, data, index)}  className="reverse-btn text-muted no-cursor">{__('Reverse cancellation')}<p className="reverse-tip">{__('Can only apply to cancelled')}<br/><span> {__('or')} </span> {__('repurchased certificate(s).')}</p> </a></Menu.Item> )}
                { (noPermission('securities_EA_addNotes') === false ) && <Menu.Item ><a onClick={this.setInitShareNote.bind(this, data, index)}  data-toggle="modal" data-target="#addAdminNotesModal">{ addOrEdit }</a></Menu.Item> }
                { (noPermission('securities_EA_resend') === false ) && (data.sign_status == "1" && isConvertAtIpo ==0 && <Menu.Item ><a onClick={this.resendEmail.bind(this, data, index)} >{__('Resend to officers')}</a></Menu.Item> )}
                { (noPermission('securities_EA_resend') === false ) && (data.sign_status == "2" && isConvertAtIpo ==0 && <Menu.Item ><a onClick={this.resendEmail.bind(this, data, index)} >{__('Resend to stakeholder')}</a></Menu.Item> )}
                { showResendDocusign && <Menu.Item ><span onClick={this.resendESign.bind(this, data.id)}>{__('Resend for e-signature')}</span></Menu.Item> }
                {/*{ (noPermission('securities_EA_terminate') === false) && isEmptyTerminationDate == 1 &&  isModifyVesting==1 && data.status != "Forfeited" && isConvertAtIpo ==0 && <Menu.Item ><a onClick={this.stopDetailModel.bind(this, data, index)} data-toggle="modal" data-target="#terminateStakeholderModal">Terminate stakeholder</a></Menu.Item>}*/}
                { (noPermission('securities_EA_terminate') === false) && data.isCanBeTerminated == 1 &&  <Menu.Item ><a onClick={this.stopDetailModel.bind(this, data, index)} data-toggle="modal" data-target="#terminateStakeholderModal">{__('Terminate stakeholder')}</a></Menu.Item>}
                { (noPermission('securities_EA_reverse_termination') === false) && isEmptyTerminationDate == 0 && isConvertAtIpo ==0 && <Menu.Item ><a onClick={this.reverseTermination.bind(this, data, index)}>{__('Reverse termination')}</a></Menu.Item>}

            </Menu>
        )
    }

    /**
     * 重发e-sign邮件
     * @param id
     * @param index
     * @param e
     * @returns {Promise<void>}
     */
    resendESign = async (id, e) => {
        e.stopPropagation();
        Loading.global(true);
        try {
            const { data: resData } = await post('resendDocusignEmail', {id})
            const { data, code } = resData || {}
            if (code == 0) {
                message.success(__('Success'));
            } else {
                message.error(data)
            }
        } catch(err) {
            message.error(err && err.message)
        }
        Loading.global(false);
    }


    getVestingData = (data, index, e) => {
        this.stopDetailModel(data, index, e);
        const { getVestingData } = this.props;
        getVestingData(index);
    }
    getVestMilestone = (data, index, e) => {
        this.stopDetailModel(data, index, e);
        const { getVestMilestone } = this.props;
        getVestMilestone();
    }
    getInitVestingList = (data, index, e) => {
        this.stopDetailModel(data, index, e);
        const { getInitVestingList } = this.props;
        getInitVestingList();
    }
    getQuantityToExercise = (data, index, e) => {
        this.stopDetailModel(data, index, e);
        const { getQuantityToExercise } = this.props;
        getQuantityToExercise(data.id);
    }
    reverseCancellation = (data, index, e) => {
        this.stopDetailModel(data, index, e);
        const { reverseCancellation } = this.props;
        reverseCancellation();
    }
    setInitShareNote = (data, index, e) => {
        this.stopDetailModel(data, index, e);
        const { setInitShareNote } = this.props;
        setInitShareNote();
    }
    resendEmail = (data, index, e) => {
        this.stopDetailModel(data, index, e);
        const { resendEmail } = this.props;
        resendEmail();
    }
    reverseTermination = (data, index, e) => {
        this.stopDetailModel(data, index, e);
        const { reverseTermination } = this.props;
        reverseTermination(data,index);
    }
    setSelectId = () => {
        const { setSelectId } = this.props;
        setSelectId();
    }
    checkSecurityStatus = () => {
        const { checkSecurityStatus } = this.props;
        checkSecurityStatus();
    }
    resetSecurityStatus = () =>{
        const { resetSecurityStatus } = this.props;
        resetSecurityStatus();
    }
    convertToShares = (data, index, e) => {
        this.stopDetailModel(data, index, e);
        const { convertToShares } = this.props;
        convertToShares();
    }
    setCurSelectSecurityNew = (text, data, index) => {
        const { setCurSelectSecurityNew } = this.props;
        setCurSelectSecurityNew(text);
        this.updateSecurityListsVisible(data, true);
    }

    stopDetailModel=(data, index, e)=>{
        e.stopPropagation();
        this.updateSecurityListsVisible(false, false);
    }

    /**
     * E-Sign Status 字典表
     */
    eSignStatusArray = [
        {key: 'Awaiting for e-sign', color: 'yellow'},
        {key: 'Completed', color: 'green'},
        {key: 'Expired', color: 'red'},
        {key: 'Awaiting officer e-sign', color: 'yellow'},
        {key: 'Awaiting holder e-sign', color: 'yellow'},
        {key: 'Officer declined', color: 'red'},
        {key: 'Stakeholder declined', color: 'red'},
        {key: 'Expired', color: 'red'}
    ];

    tableColumns = () => {
        const{ award_category}=this.props
        let awardCategoryList = [];
        award_category.forEach( (v, k) => {
            awardCategoryList.push({text: v.name, value: v.name});
        })
        let tableColumns = [
            {
                title : __('Stakeholder'),
                dataIndex : 'name',
                sorter: true,
                sortName: 'stakeholder',
                width: 180,
                ellipsis: true,
                show: false,
                isChecked: true,
            },
            {
                title : __('Stakeholder email'),
                dataIndex : 'email',
                align : 'left',
                width: 160,
                ellipsis: {
                    showTitle: false,
                },
                render: (text) => <Tooltip placement={'topLeft'} title={text}><span>{ text }</span></Tooltip>,
                isChecked: true,
            },
            {
                title : __('Status'),
                dataIndex : 'status',
                width: 220,
                ellipsis: {
                    showTitle: false,
                },
                sorter: true,
                sortName: 'status',
                render : (text, value) => {
                    let status = value.status;
                    let statusArr = status.split("until ");
                    status = statusArr[0]+" until "  + formatDate(statusArr[1])
                    return <Tooltip  placement={'topLeft'} title = {<span>{value.status.substring(0,17) == "Exercisable until" ? status : value.status} <TransactionRender transferSecurity={value.transferSecurity} /></span>}>
                        <div  style={{ display: 'flex', alignItems: 'center', whiteSpace: 'noWrap'}}>
                            {
                                (value.status === "Outstanding" || value.status==="Issued") &&
                                <p className="file_text green-disc">&nbsp;&nbsp;&nbsp;&nbsp;{certificateStatus[value.status]}</p>
                            }
                            {value.status != "Outstanding" && value.status != "Partially exercised" && value.status != "Issued" && value.status.substring(0,11) != "Exercisable" && value.status.substring(0,10) != "Convert at" &&
                            <p className="red-disc file_text">&nbsp;&nbsp;&nbsp;&nbsp;{certificateStatus[value.status]}</p>
                            }
                            {value.status.substring(0,17) == "Exercisable until" &&
                            <p className="yellow-disc file_text">&nbsp;&nbsp;&nbsp;&nbsp;{status}</p>
                            }
                            {(value.status.substring(0,10) == "Convert at" || value.status == "Partially exercised" || value.status === "Exercisable")&&
                            <p className="yellow-disc file_text">&nbsp;&nbsp;&nbsp;&nbsp;{certificateStatus[value.status]}</p>
                            }
                            <TransactionRender  transferSecurity={value.transferSecurity} />
                        </div>
                    </Tooltip>
                },
                isChecked: true,
            },
            {
                title : __('E-Sign Status'),
                dataIndex : 'docu_sign_status',
                width: 160,
                render: (text, data) => {
                    text = I(text, 0);
                    return I(data.docu_sign_id, '') ? <p className={`${this.eSignStatusArray[text].color}-disc file_text`}>&nbsp;&nbsp;&nbsp;&nbsp;{this.eSignStatusArray[text].key}</p> : '--';
                },
                isChecked: false,
            },
            {
                title : __('Issued quantity'),
                dataIndex : 'quantity',
                align : 'right',
                width: 160,
                render: (text) => <span>{ formatFractionalNumber(text) }</span>,
                isChecked: true,

            },
            {
                title : __('Forfeited quantity'),
                dataIndex : 'forfeited_quantity',
                align : 'right',
                width: 160,
                render: (text) => <span>{ formatFractionalNumber(text) }</span>,
                isChecked: true,

            },
            {
                title : __('Exercised quantity'),
                dataIndex : 'exercised_quantity',
                align : 'right',
                width: 180,
                render: (text) => <span>{ formatFractionalNumber(text) }</span>,
                isChecked: false,
            },
            {
                title : __('Vested quantity'),
                dataIndex : 'vested_quantity',
                align : 'right',
                width: 140,
                render: text => formatFractionalNumber(text),
                isChecked: true,
            },
            {
                title : __('Vested %'),
                dataIndex : 'vested_percent',
                align : 'right',
                width: 120,
                render: (text, value) => <span>{ formatPercent( value.vested_quantity/ value.quantity )}</span>,
                isChecked: true,
            },
            {
                title : __('Progress'),
                dataIndex : 'vested_bar',
                align : 'left',
                width: 120,
                render : (text, value) => this.getProgressBar(value)
            },
            {
                title : __('Exercise price'),
                dataIndex : 'exercise_price',
                align : 'right',
                width: 140,
                ellipsis: true,
                render : (text, value) => {
                    return <Tooltip  placement={'topLeft'} title = {formatCurrencyNumber(value.exercise_price)}> <span className={"file_text"}>{ formatCurrencyNumber(value.exercise_price)}</span></Tooltip>;
                }
            },
            {
                title : __('Type'),
                dataIndex : 'security_type',
                align : 'left',
                width: 120,
                render: (text) => <span>{ text }</span>
            },
            {
                title : __('Award category'),
                dataIndex : 'award_category',
                align : 'left',
                width: 160,
                render: (text) => <span>{ text }</span>,
                // filters: awardCategoryList,
                // filterSearch: true,
                onFilter: (value, record) => record.award_category.indexOf(value) === 0,
            },
            {
                title : __('Grant date'),
                dataIndex : 'grant_date',
                align : 'left',
                width: 140,
                sorter: true,
                sortName: 'grant_date',
                render: (text, value) => {
                    return <span>{ formatDate(value.grant_date) }</span>
                },
            },
            {
                title : __('Last modified'),
                dataIndex : 'last_modified',
                align : 'left',
                width: 160,
                render: (text, value) => {
                    return <span>{ formatDate(value.last_modified) }</span>
                },
            },
            {
                title : __('Job title'),
                dataIndex : 'job_title',
                align : 'left',
                width: 160,
                isChecked: false,
            },
            {
                title : __('Country'),
                dataIndex : 'country',
                align : 'left',
                width: 160,
                isChecked: false,
            },
            {
                title : __('Option plan'),
                dataIndex : 'option_plan',
                align : 'left',
                width: 160,
                ellipsis: true,
                sorter: true,
                sortName: 'option_plan',
                render: (text) => <Tooltip title = {text}><span>{ text }</span></Tooltip>,
                isChecked: true,
            },
            {
                title : __('Vesting schedule'),
                dataIndex : 'vesting_schedule',
                align : 'left',
                width: 160,
                ellipsis: true,
                render : (text) => <Tooltip title = { text == "" ? __("No schedule") : text}><span>{ text == "" ? __("No schedule") : text }</span></Tooltip>,
                isChecked: true,
            },
        ];
        let securityColumn = [
            {
                title : __('Security'),
                align : 'left',
                dataIndex : 'custom_label_id',
                key : 'keyId',
                sorter: true,
                sortName: 'security',
                fixed: 'left',
                width: 120,
                isChecked: true,
                isDisabled: true,
                render: (text, record) => <Button type="link" onClick={() => openCertificateModal(record.id)}>{text}</Button>
            },
        ];
        let btnColumns = [
            {
                title : '',
                dataIndex : 'id',
                render:(text, value, index) => this.dropdownInfo(text, value, index),
                width : 50,
                fixed: 'right',
                isHide: true,
            },
        ];
        let curTableColumns = [...tableColumns];
        return [...securityColumn, ...curTableColumns, ...btnColumns];
    }

    /**
     * 表格checkbox自定义配置
     * @param checked
     * @param record
     * @param index
     * @param selectedRowKeys
     */
    securityHover = (checked, record, index, selectedRowKeys) => {

        const { sign_status } = record;
        const message = {
            1 : __('Awaiting officer signature'),
            2 : __('Awaiting holder signature')
        }
        return (
            <Fragment >
                {
                    (sign_status == 1 || sign_status == 2) &&
                    <div className="signTipBox"  onClick={(e)=>{
                        e.stopPropagation();
                    }} checked={checked} onChange={this.checkChange.bind(this, record, index, checked)}>
                        <div className={sign_status == 1 ? 'officer-tip-triangle' : 'holder-tip-triangle'}></div>
                        <p className={['sign-tiptext', sign_status == 1 ? 'bg-lightorange' : 'bg-lightgreen'].join(' ')} style={{display:'block'}}>{message[sign_status]}</p>
                    </div>
                }
                <Checkbox onClick={(e)=>{
                    e.stopPropagation();
                }} checked={checked} onChange={this.checkChange.bind(this, record, index, checked)} ></Checkbox>
            </Fragment>
        );
    }

    /**
     * 清空checkbox所有选中内容
     */
    clearAll = () => {
        this.setState(()=>({
            selectedRowKeys : []
        }))
    }

    /**
     * 表格checkbox change事件
     * @param record
     * @param index
     * @param boxChecked
     * @param e
     */
    checkChange = (record, index, boxChecked, e) => {
        const { checked } = e.target;
        const { selectedRowKeys } = this.state;
        const { updateSelectCount } = this.props;
        const { id } = record;
        let key_list = [...selectedRowKeys];
        if(checked){
            key_list.push(id);
        }else{
            key_list.forEach( (v, k) => {
                if(v == id){
                    key_list.splice(k, 1);
                }
            })
        }
        updateSelectCount(key_list.length, [{selectIndex: index,checked:checked}]);
        this.setState(()=>({
            selectedRowKeys : key_list
        }))
        this.props.setSelectId(key_list[0]);
        this.props.checkSecurityStatus()
    }

    /**
     * 全选change事件
     * @param selectedRowKeys
     */
    onSelectChange = selectedRowKeys => {
        const { securityLists, updateSelectCount } = this.props;
        const count = selectedRowKeys.length;
        if(!count){
            updateSelectCount(count, [], true);
        }else{
            let selectIndexArray = [];
            securityLists.forEach((v, k) => {
                selectIndexArray.push({selectIndex:k, checked:true});
            })
            updateSelectCount(count, selectIndexArray);
        }
        this.setState({ selectedRowKeys }, ()=> this.props.checkSecurityStatus());
    };

    rowSelection = (selectedRowKeys) => ({
        selectedRowKeys,
        onChange: this.onSelectChange,
        renderCell : (checked, record, index, originNode) => this.securityHover(checked, record, index, selectedRowKeys)
    })
    /**
     * Row点击事件
     * @param security_id
     */
    onRowClick = security_id => {
        if(security_id){
            openCertificateModal(security_id);
        }
    };
    /**
     * table 行 鼠标移入移出
     * @param row 对象
     * @param index 下标
     * @returns {{onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave}}
     */
    onRow = (row, index) => {
        const { updateShowMsgStatus } = this.props;
        return {
            //鼠标移入
            onMouseEnter : () => {
                updateShowMsgStatus(index, true);
            },
            //鼠标移出
            onMouseLeave : () => {
                updateShowMsgStatus(index, false);
            }
        }
    }

    handleTableChange = (pagination, filters, sorter) => {
        let sort = {}
        const { order, column } = sorter

        if (column?.sortName) {
            sort = {
                column: column.sortName,
                type: order
            }
            this.props.sortTable?.(sort)
        }else{
            sort = {}
            this.props.sortTable?.(sort)
        }
    }

    render() {
        const { selectedRowKeys } = this.state;
        const { securityLists, tableColumn, paginationConfig, loading } = this.props;
        return (
            <Table
                onRow={ this.onRow}
                locale={ loading && { emptyText: LOADING }}
                pagination={ paginationConfig ? {...paginationConfig, position: ['bottomCenter'] } : false }
                className="warrant-table-new bigger_checkbox"
                columns={tableColumn}
                dataSource={securityLists}
                rowKey="id"
                scroll={{x: true, y: window.innerHeight - 360}}
                rowSelection={this.rowSelection( selectedRowKeys)}
                rowClassName="stake-table-row"
                onChange={this.handleTableChange}
            />
        );
    }
}
export default OptionTable;