import React from 'react';
import 'assets/css/merge.css';
import Modal from 'components/Modal';
import {message, Spin, Button, Table, Input} from 'antd';
import { post } from 'srcPath/http';
import AddNewWallet from '../components/AddNewWallet'
import './mergeWallet.scss'

class MergeWallet extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            tableList : [],
            filterData: [],
            mergeName: '',
            columns: [
                {
                    title : '',
                    align : 'left',
                    dataIndex : 'index1',
                    width : '23%',
                    render: (text, record) => (
                        <div className="table-attr">{text}</div>
                    )
                },
                {
                    title : 'Wallet 1',
                    align : 'left',
                    dataIndex : 'index2',
                    width : '25%',
                    render: (text, record) => (
                        <span>{text}</span>
                    )
                },
                {
                    title : 'Wallet 2',
                    align : 'left',
                    dataIndex : 'index3',
                    width : '25%',
                    render: (text, record) => (
                        <span >{text}</span>
                    )
                },
                {
                    title : 'Updated Wallet',
                    align : 'left',
                    dataIndex : 'index4',
                    width : '27%',
                    render: (text, record, index) => {
                        if (index > 0) {
                            return <p style={{ whiteSpace: 'pre-line' }}>{text}</p>
                        }
                        return <Input className="can-input color808080" bordered={false} onChange={this.mergeInputChange.bind(this)} />
                    }
                },
            ]
        }
    }

    componentDidMount () {
        const { data } = this.props
        const filteredData = data.filter((i) => i.status == 1)
        // 组装数据 将两条wallet组装成表格需要的数据格式 并存储成三条数据 
        const finalList = [
            {
                index1: 'Name',
                index2: filteredData[0].name,
                index3: filteredData[1].name,
                index4: '',
            },
            {
                index1: 'Wallet',
                index2: filteredData[0].wallet_address,
                index3: filteredData[1].wallet_address,
                index4: `${filteredData[0].wallet_address}\n${filteredData[1].wallet_address}`,
            },
            {
                index1: 'Email',
                index2: filteredData[0].stakeholder_email,
                index3: filteredData[1].stakeholder_email,
                index4: `${filteredData[0].stakeholder_email}\n${filteredData[1].stakeholder_email}`,
            },
        ]
        this.setState({
            tableList: finalList,
            filterData: filteredData,
        })
    }

    mergeInputChange = (e) => {
        this.setState({
            mergeName: e.target.value
        })
    }

    saveData = () => {
        const { close, cb } = this.props;
        const { mergeName, filterData } = this.state
        console.info('merge参数', mergeName, filterData)
        close();
        cb()
    }

    closeModels = () => {
        const { close } = this.props;
        close();
    }

    render(){
        const { loading, tableList, columns } = this.state
        return(
            <Spin tip="Loading..." spinning={loading}>
                <div className="merge-stakeholder-header">
                    <h5 className="title">Merge Wallets</h5>
                    <div className="close " onClick={this.closeModels} >×</div>
                </div>

                <div className="merge-stakeholder-content">
                    <dl>
                        <dd>
                            <Table
                                className="table"
                                pagination={false}
                                columns={columns}
                                dataSource={tableList}
                                rowKey="key"
                            >
                            </Table>
                        </dd>
                    </dl>
                </div>
                <div className="information-w100">
                    <Button className="information-button" type="primary" onClick={this.saveData}>Merge Wallets</Button>
                </div>
            </Spin>
        )
    }

}

export default function MergeWalletModal(holderLists, cb) {
	return Modal.open({
		component: <MergeWallet data={holderLists} cb={cb} />,
		maskClosable: true,
        width: 620,
        className: 'add-stakeholder-modal',
	})
}

