export const documentsTypeMap = {
	1: {
		type: 'Option grants',
		value: 'Option grant',
		file: {
			file1: __('Grant Letter'),
			// file1: 'Form of Option Agreement',
			file2: __('Form of Exercise Agreement'),
			file3: __('Equity Incentive Plan'),
			additionalDocuments: __('Additional documents')
		},
	},
	2: {
		type: 'RSAs',
		value: 'RSA',
		file: {
			file1: __('Purchase Agreement'),
			file2: __('Equity Incentive Plan'),
			additionalDocuments: __('Additional documents')
		},
	},
	3: {
		type: 'RSUs',
		value: 'RSU',
		file: {
			file1: __('Grant Letter'),
			// file1: 'Form of Grant Agreement',
			file2: __('Form of Settlement Agreement'),
			file3: __('Equity Incentive Plan'),
			additionalDocuments: __('Additional documents')
		},
	},
	4: {
		type: 'SARs',
		value: 'SAR',
		file: {
			file1: __('Grant Letter'),
			// file1: 'Form of Grant Agreement',
			file2: __('Form of Settlement Agreement'),
			file3: __('Equity Incentive Plan'),
			additionalDocuments: __('Additional documents')
		}
	},
	5: {
		type: 'Vesting',
		value: 'Vesting',
		file: {
			file1: __('Vesting offer letter '),
			file2: __('Token purchase agreement'),
			file3: __('Equity Incentive Plan'),
			additionalDocuments: __('Additional documents')
		},
	},
	0: {
		file: {}
	}
}

export const typeOptions = [
	{
		label: 'Option grants',
		value: '1',
	},
	{
		label: 'RSAs',
		value: '2',		
	},
	{
		label: 'RSUs',
		value: '3',		
	},
	{
		label: 'SARs',
		value: '4',
	},
	{
		label: 'Vesting',
		value: '5',
	}
]