import React, { useEffect, useState } from 'react';
import Modal, { ModalContent } from 'components/Modal';
import { Button, Checkbox, message } from 'antd';
import { post } from 'srcPath/http';
import security from 'stores/security';
import { sprintf } from 'Utils/i18n';
import { webSiteMap } from 'Utils/constant';
import Loading from 'components/Loading';

import './style.scss';

let MODALOPOENED = false

function TOCTipsContent({ close }) {
    useEffect(() => {
        MODALOPOENED = true
        return () => { MODALOPOENED = false }
    }, [])

    const [checked, setChecked] = useState(false)

    async function confirm(callback) {
        Loading.global(true)

        try {
            await post('confirmTOC')
            await security.getStatus()
            callback()
            MODALOPOENED = false
        } catch (error) {
            message.error(error.message)
        }

        Loading.global(false)
    }   

    function handleChecked(value) {
        setChecked(value.target.checked)
    }   
    return <div className="sprout-toc-tip">
        <div className="sprout-toc-tip-content">
            <Checkbox checked={checked} onChange={handleChecked}>{sprintf(__('I have read and agreed the %s'), <a href={`${webSiteMap[security.env]}/legal/tos`} target='_blank'>{__('Terms and Conditons.')}</a>)}</Checkbox>
        </div>
        <div className="sprout-toc-tip-btns">
            <Button disabled={!checked} type="primary" onClick={() => confirm(close)}>{__('Confirm')}</Button>
        </div>
    </div>
}

export default function openTOCTips() {
    const terms_confirm_status = security.userInfo?.terms_confirm_status // '0' 未确认， '1' 已经确认

    if (terms_confirm_status === '0' && !MODALOPOENED && security.hasLogin) {
		Modal.open({
			component: ({close}) => <ModalContent title={__('Terms and Conditions')} showClose={false}>
                <TOCTipsContent close={close}/>
			</ModalContent>,
			width: 520,
			className: 'sprout-modal'
        })
        
        return true
    }

    return false
}