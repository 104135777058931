
// // Legal Admin
// const permissions1 = {
// 	all: true,
// 	company: true,
// 	company_setup: true,
// 	company_summary: true,
// 	company_permission: true,
// company_permission_accountUsers: true,
// company_permission_accountUsers_add: true,
// company_permission_accountUsers_edit: true,
// company_permission_accountUsers_delete: true,
// company_permission_accountUsers_resend: true,
// 	company_dataRoom: true,
// 	company_setting: true,


// 	captable: true,
// 	captable_byShareClass: true,
// 	captable_byStakeholder: true,
//	captable_finacingHistory: true,


// 	securities: true,
// 	securities_shares: true,
// 	securities_EA: true,
// 	securities_convertibles: true,
// 	securities_templates: true,


// 	stakeholders: true,
// 	stakeholders_allStakeholders: true,
// 	stakeholders_transactions: true,


// 	board: true,
// 	board_document: true,
// 	board_document_addFolder: true,
// 	board_document_addFile: true,
// 	board_document_rename: true,
// 	board_document_delete: true,
// 	board_document_view: true,

// 	board_meeting: true,
// 	board_meeting_create: true,
// 	board_meeting_delete: true,
// 	board_meeting_history: true,
// 	board_meeting_detail: true,
// 	board_meeting_detail_publish: true, // achive ?
// 	board_meeting_detail_agenda: true,
// 	board_meeting_detail_edit: true, // Meeting details
// 	board_meeting_detail_attendees: true,

// 	board_notification: true,

// 	board_consent: true
// }

// {
// 	1: 'Legal Admin',
// 	2: 'Company Edit',
// 	3: 'Company Viewer',
// 	4: 'HR Admin',
// 	5: 'Esop Admin',
// 	6: 'ShareClass Admin'
// }

// Legal Admin or Super admin 1.
const legalAdmin = {
	all: true,
	company: true,
	company_setup: true,
	company_summary: true,
	company_permission: true,

	company_permission_accountUsers: true,
	company_permission_accountUsers_add: true,
	company_permission_accountUsers_edit: true,
	company_permission_accountUsers_delete: true,
	company_permission_accountUsers_resend: true,

	company_permission_board: true,

	company_permission_board_add: true,
	company_permission_board_edit: true,
	company_permission_board_delete: true,
	company_permission_board_resend: true,

	company_permission_roles: true,
	company_permission_roles_edit: true,

	company_permission_signatories: true,
	company_permission_signatories_edit: true,

	company_dataRoom: true,
	company_dataRoom_rename: true,
	company_dataRoom_moveFile: true,
	company_dataRoom_delete: true,
	company_dataRoom_addFolder: true,
	company_dataRoom_upload: true,
	company_dataRoom_viewFile: true,
	company_dataRoom_download: true,
	company_dataRoom_editSetting: true,
	company_dataRoom_editUser: true,
	company_setting: true,
	company_setting_settings: true,
	company_setting_profile: true,
	company_setting_billing: true,
    company_setting_plan: true,


	captable: true,
	captable_byShareClass: true,
	captable_byStakeholder: true,
	captable_report: true,
	tax_reports: true,
	sbc_reports: true,

	// captable_finacingHistory: true,
	rights_and_preferences: true,
	// captable_financingRound: true,

	fundraising: true,
	fundraising_finacingHistory: true,
	fundraising_scenarioModeling: true,
	fundraising_wireInstructions: true,
	fundraising_wireInstructions_deit: true,


	securities: true,
	securities_shares: true,
	securities_shares_shareClass: true,
	securities_shares_certificate_edit: true,
	securities_shares_certificate_cancel: true,
	securities_shares_certificate_print: true,
	securities_shares_vestingSchedule: true,
	securities_shares_transfer: true,
	securities_shares_adminNotes: true,
	securities_shares_resend: true,
	securities_shares_actions: true,
	securities_shares_terminate: true,
	securities_shares_reverse_termination: true,


	securities_draftShares: true,
	securities_draftShares_send: true,
	securities_draftShares_delete: true,
	securities_draftShares_addCertificate: true,
	securities_draftShares_printDraft: true,


	securities_EA: true,
	securities_EA_manageEquityPlans: true,
	securities_EA_modify: true,
	securities_EA_vestingSchedule: true,
	securities_EA_vestingMilestones: true,
	securities_EA_achieve: true,
	securities_EA_achieve_cancel: true,
	securities_EA_achieve_reverse: true,
	securities_EA_addNotes: true,
	securities_EA_resend: true,
	securities_EA_print: true,
	securities_EA_actions: true,

	securities_EA_transfer: true,

	securities_EA_terminate: true,
	securities_EA_reverse_termination: true,

	securities_draftEA: true,
	securities_draftEA_send: true,
	securities_draftEA_delete: true,
	securities_draftEA_add: true,
	securities_draftEA_set: true,

	securities_warrants: true, //warrants相关只加了legalAdmin，其他待加。
	securities_warrants_warrantBlock: true,
	securities_warrants_modify: true,
	securities_warrants_cancel: true,
	securities_warrants_reverse: true,
	securities_warrants_print: true,
	securities_warrants_vestingSchedule: true,
	securities_warrants_achieve: true,
	securities_warrants_transfer: true,
	securities_warrants_adminNotes: true,
	securities_warrants_resend: true,
	securities_warrants_actions: true,

	securities_warrants_terminate: true,
	securities_warrants_reverse_termination: true,

	securities_draftWarrants: true, //draftWarrants
	securities_draftWarrants_send: true,
	securities_draftWarrants_delete: true,
	securities_draftWarrants_addWarrant: true,
	securities_draftWarrants_printDraft: true,

	securities_convertibles: true,
	securities_convertibles_manageItems: true,
	securities_convertibles_SAFE_transfer: true,
	securities_convertibles_SAFE_cancel: true,
	securities_convertibles_SAFE_print: true,
	securities_convertibles_reverseCancellation: true,
	securities_convertibles_addAdminNotes: true,
	securities_convertibles_resend: true,
	securities_convertibles_actions: true,

	securities_draftConvertibles: true,
	securities_draftConvertibles_send: true,
	securities_draftConvertibles_delete: true,
	securities_draftConvertibles_addCertificate: true,
	securities_draftConvertibles_draftSet: true,

	securities_templates: true,

	stakeholders: true,
	stakeholders_allStakeholders: true,
	stakeholders_transactions: true,
	stakeholders_access: true,


	board: true,
	board_document: true,
	board_document_addFolder: true,
	board_document_addFile: true,
	board_document_rename: true,
	board_document_delete: true,
	board_document_view: true,
	board_document_moveFile: true,
	board_document_upload: true,
	board_document_download: true,
	board_document_editSetting: true,
	
	board_meeting: true,
	board_meeting_create: true,
	board_meeting_delete: true,
	board_meeting_history: true,
	board_meeting_detail: true,
	board_meeting_detail_publish: true, // achive ?
	board_meeting_detail_agenda: true,
	board_meeting_detail_edit: true, // Meeting details
	board_meeting_detail_attendees: true,

	board_notification: true,

	board_consent: true,
	check_plan: true,

	compliance: true,
	compliance_fairmarketvalue: true,
	compliance_tokenvaluation: true,

	onboarding_issue: true,
	tokens: true,
}

// Company Edit 2.
const companyEdit = {
	all: true,
	company: true,
	company_summary: true,
	company_permission: true,
	company_dataRoom: true,
	company_setting: true,

	company_permission_accountUsers: true,
	company_permission_accountUsers_add: true,
	company_permission_accountUsers_edit: true,
	company_permission_accountUsers_delete: true,
	company_permission_accountUsers_resend: true,

	company_permission_board: true,

	company_permission_board_add: true,
	company_permission_board_edit: true,
	company_permission_board_delete: true,
	company_permission_board_resend: true,

	company_permission_roles: true,
	company_permission_roles_edit: true,

	company_permission_signatories: true,
	company_permission_signatories_edit: true,

	company_dataRoom: true,
	company_dataRoom_rename: true,
	company_dataRoom_moveFile: true,
	company_dataRoom_delete: true,
	company_dataRoom_addFolder: true,
	company_dataRoom_download: true,
	company_dataRoom_upload: true,
	company_dataRoom_viewFile: true,
	company_dataRoom_editSetting: true,
	company_dataRoom_editUser: true,

	company_setting: true,
	company_setting_settings: true,
	company_setting_profile: true,

	captable: true,
	captable_byShareClass: true,
	captable_byStakeholder: true,
	captable_report: true,
	tax_reports: true,
	sbc_reports: true,
	// captable_finacingHistory: true,
	rights_and_preferences: true,
	// captable_financingRound: true,

	fundraising: true,
	fundraising_finacingHistory: true,
	fundraising_scenarioModeling: true,
	fundraising_wireInstructions: true,
	fundraising_wireInstructions_deit: true,

	securities: true,
	securities_shares: true,
	securities_shares_shareClass: true,
	securities_shares_resend: true,
	securities_shares_certificate_print: true,
	securities_shares_actions: true,
	securities_shares_terminate: true,
	securities_shares_reverse_termination: true,

	securities_draftShares: true,
	securities_draftShares_delete: true,
	securities_draftShares_addCertificate: true,
	securities_draftShares_printDraft: true,

	securities_EA: true,

	securities_EA_resend: true,
	securities_EA_print: true,
	securities_EA_terminate: true,
	securities_EA_reverse_termination: true,

	securities_draftEA: true,
	securities_draftEA_delete: true,
	securities_draftEA_add: true,
	securities_draftEA_set: true,

	securities_warrants: true,
	securities_warrants_resend: true,
	securities_warrants_print: true,
	securities_warrants_terminate: true,
	securities_warrants_reverse_termination: true,

	securities_draftWarrants: true,
	securities_draftWarrants_delete: true,
	securities_draftWarrants_addWarrant: true,


	securities_convertibles: true,
	securities_convertibles_SAFE_print: true,
	securities_convertibles_resend: true,

	securities_draftConvertibles: true,
	securities_draftConvertibles_delete: true,
	securities_draftConvertibles_addCertificate: true,
	securities_draftConvertibles_draftSet: true,

	securities_templates: true,
	stakeholders: true,
	stakeholders_allStakeholders: true,
	stakeholders_transactions: true,
	stakeholders_access: true,


	board: true,
	board_document: true,
	board_document_addFolder: true,
	board_document_addFile: true,
	board_document_rename: true,
	board_document_delete: true,
	board_document_view: true,
	board_document_moveFile: true,
	board_document_upload: true,
	board_document_download: true,
	board_document_editSetting: true,

	board_meeting: true,
	board_meeting_create: true,
	board_meeting_delete: true,
	board_meeting_history: true,
	board_meeting_detail: true,
	board_meeting_detail_publish: true,
	board_meeting_detail_agenda: true,
	board_meeting_detail_edit: true, // Meeting details
	board_meeting_detail_attendees: true,

	board_notification: true,

	board_consent: true,
	check_plan: true,
	compliance:true,
	company_setup: true,
	compliance_fairmarketvalue: true,
	compliance_tokenvaluation: true,

	tokens: true,
}

// Company Viewer 3.
const companyViewer = {
	all: true,
	company: true,
	company_summary: true,
	company_dataRoom: true,

	captable: true,
	captable_byShareClass: true,
	captable_byStakeholder: true,
	captable_report: true,
	tax_reports: true,
	sbc_reports: true,
	// captable_finacingHistory: true,
	rights_and_preferences: true,

	fundraising: true,
	fundraising_finacingHistory: true,
	fundraising_scenarioModeling: true,
	fundraising_wireInstructions: true,

	company_dataRoom: true,
	company_dataRoom_rename: false,
	company_dataRoom_moveFile: false,
	company_dataRoom_delete: false,
	company_dataRoom_addFolder: false,
	company_dataRoom_download: true,
	company_dataRoom_upload: false,
	company_dataRoom_viewFile: true,
	company_dataRoom_editSetting: false,
	company_dataRoom_editUser: false,


	securities: true,
	securities_shares: true,
	securities_shares_certificate_print: true,

	securities_EA: true,
	securities_EA_resend: true,
	securities_EA_print: true,

	securities_warrants: true,
	securities_warrants_resend: true,
	securities_warrants_print: true,


	securities_convertibles: true,
	securities_convertibles_SAFE_print: true,

	securities_templates: true,

	stakeholders: true,
	stakeholders_allStakeholders: true,
    check_plan: true,
    company_setup: true,
	tokens: true,
}

// HR Admin 4.
const hrAdmin = {
	all: true,
	company_dataRoom: true,
	captable_byShareClass: true,
	securities: true,
	securities_templates: true, stakeholders: true,
	stakeholders_allStakeholders: true,
	stakeholders_transactions: true,
	check_plan: true,
	tokens: true,
}

// Esop Admin 5.
const esopAdmin = {
	all: true,
	company: true,
	company_setup: true,
	company_summary: true,

	company_dataRoom: true,
	company_dataRoom_rename: false,
	company_dataRoom_moveFile: false,
	company_dataRoom_delete: false,
	company_dataRoom_addFolder: false,
	company_dataRoom_upload: false,
	company_dataRoom_viewFile: true,
	company_dataRoom_download: true,
	company_dataRoom_editSetting: false,
	company_dataRoom_editUser: false,

	captable: true,
	captable_byShareClass: true,
	// captable_finacingHistory: true,

	securities: true,

	securities_draftShares: true,
	securities_draftShares_send: true,
	securities_draftShares_delete: true,
	securities_draftShares_addCertificate: true,
	securities_draftShares_printDraft: true,


	securities_EA: true,
	securities_EA_manageEquityPlans: true,
	securities_EA_modify: true,
	securities_EA_vestingSchedule: true,
	securities_EA_vestingMilestones: true,
	securities_EA_achieve: true,
	securities_EA_achieve_cancel: true,
	securities_EA_achieve_reverse: true,
	securities_EA_addNotes: true,
	securities_EA_resend: true,
	securities_EA_print: true,
	securities_EA_actions: true,

	securities_EA_transfer: true,

	securities_draftEA: true,
	securities_draftEA_send: true,
	securities_draftEA_delete: true,
	securities_draftEA_add: true,
	securities_draftEA_set: true,


	securities_templates: true,

	stakeholders: true,
	stakeholders_allStakeholders: true,
	stakeholders_transactions: true,

	board: false,
	board_document: true,
	board_document_addFolder: true,
	board_document_addFile: true,
	board_document_rename: true,
	board_document_delete: true,
	board_document_view: true,
	board_document_moveFile: true,
	board_document_upload: true,
	board_document_download: true,
	board_document_editSetting: true,

	board_meeting: true,
	board_meeting_create: true,
	board_meeting_delete: true,
	board_meeting_history: true,
	board_meeting_detail: true,
	board_meeting_detail_publish: true, // achive ?
	board_meeting_detail_agenda: true,
	board_meeting_detail_edit: true, // Meeting details
	board_meeting_detail_attendees: true,

	board_notification: true,
	check_plan: true,

	tokens: false,
}

// ShareClass Admin or Captable Admin 6.
const shareClassAdmin = {
	all: true,
	company: true,
	company_setup: true,
	company_summary: true,

	company_dataRoom: true,
	company_dataRoom_rename: false,
	company_dataRoom_moveFile: false,
	company_dataRoom_delete: false,
	company_dataRoom_addFolder: false,
	company_dataRoom_upload: false,
	company_dataRoom_viewFile: true,
	company_dataRoom_download: true,
	company_dataRoom_editSetting: false,
	company_dataRoom_editUser: false,

	captable: true,
	captable_byShareClass: true,
	// captable_finacingHistory: true,
	rights_and_preferences: true,
	// captable_financingRound: true,

	fundraising: true,
	fundraising_finacingHistory: true,
	fundraising_scenarioModeling: true,
	fundraising_wireInstructions: true,

	securities: true,


	securities_shares: true,
	securities_shares_shareClass: true,
	securities_shares_certificate_edit: true,
	securities_shares_certificate_cancel: true,
	securities_shares_certificate_print: true,
	securities_shares_vestingSchedule: true,
	securities_shares_transfer: true,
	securities_shares_adminNotes: true,
	securities_shares_resend: true,
	securities_shares_actions: true,

	securities_draftShares: true,
	securities_draftShares_send: true,
	securities_draftShares_delete: true,
	securities_draftShares_addCertificate: true,
	securities_draftShares_printDraft: true,



	securities_warrants: true,
	securities_warrants_warrantBlock: true,
	securities_warrants_modify: true,
	securities_warrants_cancel: true,
	securities_warrants_reverse: true,
	securities_warrants_print: true,
	securities_warrants_vestingSchedule: true,
	securities_warrants_achieve: true,
	securities_warrants_transfer: true,
	securities_warrants_adminNotes: true,
	securities_warrants_resend: true,
	securities_warrants_actions: true,

	securities_draftWarrants: true,
	securities_draftWarrants_send: true,
	securities_draftWarrants_delete: true,
	securities_draftWarrants_addWarrant: true,
	securities_draftWarrants_printDraft: true,


	securities_convertibles: true,
	securities_convertibles_manageItems: true,
	securities_convertibles_SAFE_transfer: true,
	securities_convertibles_SAFE_cancel: true,
	securities_convertibles_SAFE_print: true,
	securities_convertibles_reverseCancellation: true,
	securities_convertibles_addAdminNotes: true,
	securities_convertibles_resend: true,
	securities_convertibles_actions: true,

	securities_draftConvertibles: true,
	securities_draftConvertibles_send: true,
	securities_draftConvertibles_delete: true,
	securities_draftConvertibles_addCertificate: true,
	securities_draftConvertibles_draftSet: true,

	securities_templates: true,

	stakeholders: true,
	stakeholders_allStakeholders: true,
	stakeholders_transactions: true,


	board: false,
	board_document: true,
	board_document_addFolder: true,
	board_document_addFile: true,
	board_document_rename: true,
	board_document_delete: true,
	board_document_view: true,
	board_document_moveFile: true,
	board_document_upload: true,
	board_document_download: true,
	board_document_editSetting: true,

	board_meeting: true,
	board_meeting_create: true,
	board_meeting_delete: true,
	board_meeting_history: true,
	board_meeting_detail: true,
	board_meeting_detail_publish: true, // achive ?
	board_meeting_detail_agenda: true,
	board_meeting_detail_edit: true, // Meeting details
	board_meeting_detail_attendees: true,

	board_notification: true,
	check_plan: true,

	tokens: false,
}


// Board Director 1.
const boardDirector = {
	all: true,
	board: true,
	board_document: true,
	board_document_addFolder: true,
	board_document_addFile: true,
	board_document_rename: true,
	board_document_view: true,
	board_document_moveFile: true,
	board_document_upload: true,
	board_document_download: true,
	board_document_editSetting: true,

	board_meeting: true,

	board_meeting_detail: true,

	board_notification: true,

	board_consent: true,
    check_plan: true,
	tokens: false,
}

// Board Observer 2.
const boardObserver = {
	all: true,
	board: true,
	board_document: true,
	board_document_view: true,
	board_document_moveFile: false,
	board_document_upload: false,
	board_document_download: true,
	board_document_editSetting: true,

	board_meeting: true,
	board_meeting_detail: true,
	board_consent: true,
    check_plan: true,
	tokens: false,
}

// Board Admin 3.
const boardAdmin = {
	all: true,
	company: true,

	captable: true,
	captable_byShareClass: true,
	// captable_finacingHistory: true,
	rights_and_preferences: true,


	securities: true,
	securities_shares: true,
	securities_convertibles: true,


	stakeholders: true,
	stakeholders_allStakeholders: true,
	stakeholders_transactions: true,
	board_consent: true,

	company: true,
	company_dataRoom: false, // 无权限
	company_dataRoom_rename: true,
	company_dataRoom_moveFile: true,
	company_dataRoom_delete: true,
	company_dataRoom_addFolder: true,
	company_dataRoom_upload: true,
	company_dataRoom_viewFile: true,
	company_dataRoom_download: true,
	company_dataRoom_editSetting: true,
	company_dataRoom_editUser: true,
    check_plan: true,
	tokens: false,
}

const dataRoom = {
	all: true,
	company: true,
	company_summary: false,
	company_permission: false,
	company_permission_accountUsers: false,
	company_permission_board: false,
	company_setting: false,
	company_dataRoom: true,
	captable_report: false,
	captable_report: false,
	company_dataRoom_rename: false,
	company_dataRoom_moveFile: false,
	company_dataRoom_delete: false,
	company_dataRoom_addFolder: false,
	company_dataRoom_download: true,
	company_dataRoom_upload: false,
	company_dataRoom_viewFile: true,
	company_dataRoom_editUser: false,
	company_dataRoom_editSetting: false, //set access
    check_plan: true,
	tokens: false,
}

//payment 过期、欠费时各角色权限
//superAdmin 已完成onboarding 权限
const superAdminArrears = {
    company: true,
    company_setting: true,
    company_setting_billing: true,
    company_setting_plan: true,
    check_plan: true,
};
//superAdmin 未完成onboarding 权限
const superAdminNotOnboardingArrears = {
    check_plan: true,
};
//非superAdmin权限
const otherArrears = {
    check_plan: true,
};


//功能（菜单）payment权限配置字典表
export const functionAuthConfig = {
    '/home/reports/captable': __('Equity reports'),
    '/home/fundraise/scenario-modeling': __('Scenario modeling'),
    '/home/datarooms': __('Data room'),
    '/home/compliance': __('Compliance'),
    '/home/board': __('Board'),
};

// company editor 或 company view 才能成为board admin

const adminPermission = {
	0: {},
	1: legalAdmin,
	2: companyEdit,
	3: companyViewer,
	4: hrAdmin,
	5: esopAdmin,
	// 6: captableAdmin
	6: shareClassAdmin,
	8: dataRoom,

    100: superAdminArrears,
    101: otherArrears,
    102: superAdminNotOnboardingArrears,
}

const boardPermission = {
	0: {},
	1: boardDirector,
	2: boardObserver,
	3: boardAdmin
}

const dataRoomPermission = {
	0: {},
	1: dataRoom,
}

export function setPermissions(flag, board_flag, data_room_flag, arrears, planControl, finishOnboarding, parentId) {
    //通过"arrears、flag"验证是否欠费，如欠费状态且则强制更改flag，其目的是所有功能失效
	// planControl === '2' && (!!!arrears && flag == 1 && (flag = finishOnboarding ? 100 : 102) || flag != 1 && !!!arrears && (flag = 101));
	return Object.assign({}, { ...(boardPermission[board_flag] || {}) }, { ...(dataRoomPermission[data_room_flag] || {}) }, { ...(adminPermission[flag] || {}) })
}

/**
 * 获取未授权的功能标识
 * @param flag 等级
 * @param arrears 是否欠费。（true:不欠费，false:欠费）
 * @param rbacFunction 已获得授权的功能列表
 * @param planControl 验证开关。（1:不验证，2：验证）
 * @returns {*[]} 差集
 */
export function authFunction(flag, arrears, rbacFunction = [], planControl) {
    const auth = [];
    let authConfig = {...functionAuthConfig};
    planControl == 1 && (authConfig = {});
    !!authConfig && Object.keys(authConfig).forEach((v)=>{
        auth.push(v);
    })
    return [...auth].filter( x => [...rbacFunction].every( y => y !== x));
}

export const getIntercom = callback => {
	var w = window;
	var ic = w.Intercom;
	if (typeof ic === 'function') {
		ic('reattach_activator');
		ic('update', w.intercomSettings);
	} else {
		var d = document;
		var i = function () {
			i.c(arguments);
		};
		i.q = [];
		i.c = function (args) {
			i.q.push(args);
		};
		w.Intercom = i;
		var l = () => {
			var s = d.createElement('script');
			s.type = 'text/javascript';
			s.async = true;
			s.src = 'https://widget.intercom.io/widget/enzreqg8';
			var x = d.getElementsByTagName('script')[0];
			x.parentNode.insertBefore(s, x);
			s.onload = callback
		};
		l();
	}
}

export function resetPlatform(platform, corporations, portfolios) {
	let res = platform === 'EMPTY' ? '' : platform;

	if (!res && corporations?.length > 0) {
		return 'B'
	}

	if (!res && corporations?.length === 0 && portfolios?.length > 0) {
		return 'C'
	}

	if (res === 'B' && corporations?.length === 0 && portfolios?.length > 0 ) { 
		res = 'C'
	}

	if (res === 'C' && portfolios?.length === 0 && corporations?.length > 0) { 
		res = 'B'
	}

	if (!(portfolios?.length > 0 || corporations?.length > 0)) {
		res='EMPTY'
	}

	return res
}