import React, { Component } from 'react';
import { Tooltip } from 'antd';
import Modal from 'components/Modal';
import DigitalFront from './DigitalFront';
import DigitalBack from './DigitalBack';
import front from './images/stock-front.png';
import back from './images/stock-back.png';
import './style.scss'

export default class DigitalCertificate extends Component {
	
    openDialog(dialogType) {
        const { data: digital_certificate, type } = this.props || {}
        Modal.open({
            component: dialogType === 'front' ? <DigitalFront data={digital_certificate} type={type} /> : <DigitalBack data={digital_certificate}  type={type} />,
            className: 'certificate-modal-wrap',
            width: '900px',
            maskClosable: true
        })
    }

    render() {
        return <div>
            <Tooltip title={__('View certificate front')}><img className="stock-shares-icon" src={front} onClick={this.openDialog.bind(this, 'front')} /></Tooltip>
            <Tooltip title={__('View certificate back')}><img className="stock-shares-icon" src={back} onClick={this.openDialog.bind(this, 'back')}  /></Tooltip>
        </div>
    }
}