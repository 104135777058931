import React, {Component} from 'react'
import Modal from 'components/Modal';
import './style.scss'
import {Button, message, Select, Table} from "antd";
import { post } from 'srcPath/http';
import DateSelector from "components/DateSelector";
import formatFractionalNumber from "Utils/format/formatFractionalNumber";
import UploadItem from 'B/components/UploadItem'
import classnames from "classnames";
import moment from "moment";
import { I } from 'Utils';

const { Option } = Select

class BulkTransfer extends Component{
    constructor(props){
        super(props);
        this.state = {
            dateFormat: "DD/MM/YYYY",
            loading:false,
            csPlanList:[],
            data: props.data.map((d)=> Object.assign({},d)),
            dataCopy: props.data.map((d)=> Object.assign({},d)),

        }
    }

    componentDidMount() {
        this.getData()
    }

    getData = async () => {
        this.setState({ loading: true })

        try {
            const { data: resData } = await post('getShareClassPlanList')

            const { code, data } = resData

            if(code === 0) {
                this.setState({ csPlanList: data || [] })
            } else {
                message.error(data)
            }
        } catch (err) {
            message.error(err && err.message)
        }
        this.setState({ loading: false })
    }

    closeModels = () => {
        const { close } = this.props;
        close && close()
    }

    getColumns = () => {
        const { csPlanList, data, dateFormat } = this.state;
        const { stakeholders } = this.props;
        return [
            {
                title: 'Security',
                render: (text, record, index) => {
                    return record.custom_label_id;
                },
                ellipsis: true,
                width: 120
            },
            {
                title: 'Transfer date',
                dataIndex: 'transfer_date',
                render: (text, record, index) =>
                    <DateSelector
                        className={classnames({ 'has-error': record.transfer_dateError })}
                        value={I(data[index]['transfer_date_moment'], I(data[index]['transfer_date'], '') ? moment(data[index]['transfer_date'], dateFormat) : '')}
                        onChange={ (transfer_date) => { this.handleChange( transfer_date, 'transfer_date', index) }}
                    />,
                width: 160,
            },
            {
                title: 'Stakeholder name',
                dataIndex: 'stakeholder_id',
                render: (text, record, index) => <Select style={{ width: 150 }}  defaultValue={record.stakeholder_id} placeholder="Select stakeholder"  onChange={(stakeholder_id) => this.handleChange(stakeholder_id, 'stakeholder_id', index)}  >
                    { stakeholders.map((val, index) => <Option value={val.id} key={index}>{val.nick_name}</Option>) }
                </Select>,
                ellipsis: true,
                width: 160
            },
            {
                title: 'Share class',
                dataIndex: 'share_class',
                render: (text, record, index) => <Select style={{ width: 150 }} value={I(data[index].share_class, '')}  placeholder="Select share class"  className={classnames({ 'has-error': record.share_classError })} onChange={(share_class) => this.handleChange(share_class, 'share_class', index)} >
                    { csPlanList.map((val, index) => <Option value={val.id} key={index} >{val.share_class_name}</Option>) }
                </Select>,
                width: 160
            },
            {
                title: 'Shares',
                className: 'nowrap',
                render: (text, record, index) => {
                    return formatFractionalNumber(record.quantity)
                },
                width:120,
                align: 'right',
            },
            {
                title: 'Agreement',
                width: 230,
                dataIndex: 'transfer_agreement_file',
                align: 'right',
                render: (text, record, index) => {
                    return 	<UploadItem fileKey="file_obj" onChange = { (fileObj) => this.handleFileChange(fileObj, index)} />

                }
            }
        ]
    }

    handleChange = (value, type, index) => {
        const { data } = this.state;
        const { dateFormat } = this.state;
        const tmpData = data[index];

        if (index === 0) { // 如果修改的是第一条记录，同时修改所有数据
            data.forEach((item, idx) => {
                const tmpItem = { ...item };
                if (type === 'transfer_date') {
                    tmpItem[type] = value?.format(dateFormat);
                } else {
                    tmpItem[type] = value;
                }
                tmpItem[`${type}Error`] = this.checkError(value, type);
                data[idx] = tmpItem;
            });
        } else { // 如果修改的是其他记录，只修改对应的数据
            if (type === 'transfer_date') {
                tmpData[type] = value?.format(dateFormat);
            } else {
                tmpData[type] = value;
            }
            tmpData[`${type}Error`] = this.checkError(value, type);
            data[index] = tmpData;
        }

        this.setState({ data });
    };

    handleFileChange = (fileObj, index) => {
        const {data} = this.state;
        data[index].transfer_agreement_file = fileObj
    }

    checkError = (value, type, show = true) => {
        if (type === 'transfer_date') {
            if (!value) {
                show && message.error('Transfer date is required')

                return true
            }
        }
        if (type === 'share_class') {
            if (!value) {
                show && message.error('Share class is required')

                return true
            }
        }

        return false
    }

    onSubmit = async () => {
        const {data, dataCopy} = this.state;

        this.setState(((pre)=>({
            data : pre.dataCopy,
            dataCopy: []
        })))

        for( let i = 0;  i < data.length; i++){
            const { transfer_date, share_class } = data[i]
            const share_classError = this.checkError(share_class, 'share_class', false)
            const transfer_dateError = this.checkError(transfer_date, 'transfer_date', false)
            if ( share_classError || transfer_dateError ) {
                data[i].share_classError = share_classError
                data[i].transfer_dateError = transfer_dateError

                this.setState({ data })

                return
            }

        }

        this.setState({ loading: true })

        const transferData = data.map(({id, transfer_date, share_class, stakeholder_id, transfer_agreement_file}) => ({id, transfer_date, share_class, stakeholder_id, transfer_agreement_file}))

        try {
            const { close } = this.props;

            const { data: resData } = await post( 'bulkTransfer', { transferData })

            const { code, data } = resData

            if (code == 0) {
                close && close();
                this.props.callback()
            } else {
                message.error(data)
            }
        } catch(err) {
            message.error(err && err.message)
        }

        this.setState({ loading: false })
    }

    render(){
        const { stakeholders } = this.props
        const { loading, data } = this.state

        return(
            <div className="bulk-transfer-root ">
                <div className="modal-header box-header">
                    <button type="button" className="close shut-btn"  onClick={this.closeModels}>×</button>
                    <h4 className="modal-title" >{__('Bulk transfer certificates')}</h4>
                </div>
                <div className="modal-body text-center bulk-transfer-content">
                    <Table
                        stakeholders={stakeholders}
                        rowKey={(value, index) => index}
                        columns={this.getColumns()}
                        pagination={false}
                        dataSource={data}
                        loading={loading}
                        scroll={{
                            y: 400,
                            x: 1040,
                        }}
                    />
                </div>
                <div className="modal-footer box-footer">
                    <Button onClick={() => this.onSubmit()} type={'primary'} loading={loading} className="btn width120">{__('Confirm')} </Button>
                </div>
            </div>
        )
    }
}
export default function bulkTransferModal(data, stakeholders, callback) {
    return Modal.open({
        component: <BulkTransfer data={data} stakeholders={stakeholders} callback={callback}/>,
        maskClosable: true,
        width: 1040,
        className: 'add-stakeholder-modal',
    })
}