import React, { Component } from 'react';
import { Menu, Badge, Dropdown, Space, Card } from 'antd';
import { GlobalOutlined, DownOutlined, UserOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import security from 'stores/security';
import { cloneDeep } from 'lodash';
import { observer } from 'mobx-react';
import { webSiteMap } from 'Utils/constant';
import openFileListModal from './components/FileListModal';
import EnvAlert from 'components/EnvAlert';
import { post } from 'srcPath/http';
import ui from 'stores/ui'
import logo from './logo.png';
import walletIcon from './wallet-icon.png';
import roboLogo from './robo185.png';

// 'DEV' | 'STAGING' | 'QA' | 'PROD'

import './style.scss';

const SubMenu = Menu.SubMenu
const limitCount = 15

@withRouter
@observer
export default class Header extends Component {
	logout = () => {
		security.clear()
	}

	onPortfolioChange = (type, value) => {
		const companyId = value.company_id || value.id;
		const companyInfo = (type === 'corporations' ? security.corporations : security.portfolios)?.find(val => val.company_id == companyId)

		security.setCompanyInfo = companyInfo

		let ACCESS_TOKEN = localStorage.getItem('ACCESS_TOKEN')
		if (security.isProfile == 1) {
			post('changeCompanyLog', { access_token: ACCESS_TOKEN }).then(res => {
			}).catch(function (error) {
				message.error(error.data)
			});
		}
		security.setPlatform = type === 'corporations' ? 'B' : 'C';
		security.sendEvent()
		this.props.history.push("/");
	}

	jumpToSearchPage = (type) => {
		if (type === 'corporations') {
			security.setCompanyInfo = security.corporations[0]
			security.setPlatform = 'B'
			this.props.history.push("/home/exchange");
		} else {
			security.setPlatform = 'C'
			this.props.history.push("/client/exchange");
		}
	}

	goToPersonalOrTutorials = (path) => {
		const pathMap = {
			B: `/home/${path}`,
			C: `/client/${path}`,
			TASK: `/task/${path}`,
			EMPTY: `/empty/${path}`
		}

		this.props.history.push(pathMap[security.platform] || '/')
	}

	renderPersonalMenu = () => {
		return <Menu>
			{security.isProfile == 0 &&
				<Menu.Item>
					<a onClick={() => this.goToPersonalOrTutorials('personal-center')}>{__('Profile and security')}</a>
				</Menu.Item>}
			<Menu.Item>
				<a href={webSiteMap[security.env] + '/tos'} target="_blank">{__('Terms and privacy')}</a>
			</Menu.Item>
			<Menu.Item>
				<a onClick={this.logout} >{__('Log out')}</a>
			</Menu.Item>
		</Menu>
	}

	renderAccountMenu = () => {
		const corporations = cloneDeep(!!security.defaultAdmin?.length ? security.defaultAdmin : security.corporations)
		const portfolios = cloneDeep(!!security.defaultStakeholder?.length ? security.defaultStakeholder : security.portfolios)
		let portfoliosFlag = false
		let corporationsFlag = false
		let portfoliosList = portfolios
		let corporationsList = corporations
		if (portfolios?.length >= limitCount) {
			portfoliosFlag = true
			portfoliosList.length = limitCount
		}
		if (corporations?.length >= limitCount) {
			corporationsFlag = true
			corporationsList.length = limitCount
		}
		return <Menu selectedKeys={[security.platform + security.companyId]}>
			{portfoliosList?.length > 0 && <div style={{ marginLeft: 8, fontWeight: 500 }} >{__('PORTFOLIOS')}</div>}
			{
				portfoliosList?.map((value, index) => (<Menu.Item key={'C' + (value.company_id || value.id)} style={{ lineHeight: 1, paddingLeft: '25px', color: 'gray' }} onClick={() => this.onPortfolioChange('portfolios', value)}>{value.company_name}<span style={{ marginLeft: '5px' }}></span></Menu.Item>))
			}
			{portfoliosList?.length > 0 && (<Menu.Item key={'C' + portfolios?.length} style={{ lineHeight: 1, paddingLeft: '25px', color: 'gray', textDecoration: 'underline' }} onClick={() => this.jumpToSearchPage('portfolios')}>{__('See More Portfolios')}</Menu.Item>)}
			{portfoliosList?.length > 0 && <div style={{ marginBottom: 10 }} />}
			{corporationsList?.length > 0 && <div style={{ marginLeft: 8, fontWeight: 500 }}>{__('COMPANIES')}</div>}
			{
				corporationsList?.map((value, index) => (<Menu.Item key={'B' + (value.company_id || value.id)} style={{ lineHeight: 1, paddingLeft: '25px', color: 'gray' }} onClick={() => this.onPortfolioChange('corporations', value)}>{value.company_name}<span style={{ marginLeft: '5px' }}></span></Menu.Item>))
			}
			{corporationsList?.length > 0 && (<Menu.Item key={'B' + corporationsList?.length} style={{ lineHeight: 1, paddingLeft: '25px', color: 'gray', textDecoration: 'underline' }} onClick={() => this.jumpToSearchPage('corporations')}>{__('See More Companies')}</Menu.Item>)}
			{corporationsList?.length > 0 && <div style={{ marginBottom: 10 }} />}
		</Menu>
	}

	renderTaskMenu = () => {
		const { taskDetail = {} } = security.task || {}

		const menuConfig = [
			{
				title: __('Accept certificates'),
				path: '/task/certificate',
				disabled: false,
				access: true,
				children: null,
				task: Number(taskDetail.acceptCertificates)
			},
			{
				title: __('Board consents'),
				path: '/task/board',
				disabled: false,
				access: true,
				task: Number(taskDetail.boardConsents)

			},
			{
				title: __('Sign certificate'),
				path: '/task/sign',
				disabled: false,
				access: true,
				task: Number(taskDetail.signCertificates)
			},
			{
				title: 'Requests',
				path: '/task/requests',
				disabled: false,
				access: true,
				task: Number(taskDetail.requests)	
			}
		]

		const menus = menuConfig.filter(val => val.task > 0)

		return <Menu>
			{
				menus.map((val, index) => <Menu.Item onClick={() => this.changeToTask(val.path)} key={index}><div style={{ display: 'flex', alignItems: 'center' }}><span style={{ whiteSpace: 'nowrap' }}>{val.title}&nbsp;</span><Badge style={{ fontSize: 12 }} size="small" overflowCount={9} size="small" count={val.task || 0} /></div></Menu.Item>)
			}
		</Menu>
	}

	changeToTask = path => {
		security.setPlatform = 'TASK'

		if (path) {
			this.props.history.push(path)
		}
	}

	onLogoClick = () => {
		// if(security.hasLogin) {

		// 	return // 暂时去掉跳转 2022-03-27  <AP-1488>
		// 	security.setPlatform = security.corporations.length > 0 ? 'B' : 'C'

		// 	security.setCompanyInfo = (security.platform === 'B' ? security.corporations[0] : security.portfolios[0]) || {}

		// 	this.props.history.push('/')

		// 	return
		// }

		window.open('https://folium.xyz/', '_self')
	}

	render() {
		const en = security.env
		const wallet_address_list = security?.companyInfo?.wallet_address_list
		const hasWalletAddress = wallet_address_list?.length > 0

		const roboCompany = ['185', '303', '182', '183', '184']

		let AdmintoolUserRole = localStorage.getItem('AdmintoolUserRole')

		return <div className="sprout-header-c">
			<div className="sprout-menu-logo-wrap">
				<div className="sprout-menu-logo" onClick={this.onLogoClick} style={{backgroundImage: `url(${logo})`}} />
				<div className="sprout-menu-account-change">
					<Dropdown overlay={this.renderAccountMenu()} placement="bottomLeft" trigger={['click']} overlayStyle={{ maxHeight: 600 }}>
						<div className="sprout-header-account">
							<div className="sprout-header-avatar header-button">
								<span className="sprout-header-name">{__('ACCOUNTS')}</span>
								<span className="sprout-header-company" title={security.companyInfo?.company_name}>{security.companyInfo?.company_name}</span>
								<DownOutlined className="glyphicon" />
							</div>
						</div>
					</Dropdown>
				</div>
			</div>
			<div className="sprout-menu-wrap">
				{/* { en !== 'prod' && <EnvAlert />} */}
				<div className="sprout-header-task-wrap">
					{hasWalletAddress && (
						<Dropdown
							className="sprout-header-wallet"
							overlay={
								<div style={{ width: 300, background: '#ffffff', padding: '6px', boxShadow: '0px 5px 13px 0px rgba(0,0,0,0.2)' }}>
									{wallet_address_list.map((item) => (
										item?.wallet_address && <p style={{ color: '#4691C1', background: '#F9F9F9', borderRadius: '2px', margin: 0, wordBreak: 'break-all', cursor: 'pointer' }}>{item?.wallet_address}</p>
									)) }
								</div>
							}
							placement="bottomRight">
							<div className="sprout-header-task header-button">
								<Space size={4}>
									<img src={walletIcon} style={{ width: '20px' }} />
									My Wallets 
								</Space>
							</div>
						</Dropdown>
					)}
					{en !== 'prod' && <Dropdown
						overlay={
							<Menu onClick={val => ui.setLang(val.key)} selectedKeys={[ui.lang]}>
								<Menu.Item key="en_US">English</Menu.Item>
								<Menu.Item key="zh_CN">简体中文</Menu.Item>
								<Menu.Item key="zh_TW">繁體中文</Menu.Item>
							</Menu>
						}
						placement="bottomRight">
						<div className="sprout-header-task header-button">
							<Space size={4}>
								<span>{ui.lang === 'en_US' ? 'EN' : 'CH'}</span>
								<GlobalOutlined />
							</Space>
						</div>
					</Dropdown>}
					{
						(security.task && security.task.total) > 0 ? <Dropdown overlay={this.renderTaskMenu()} placement="bottomLeft" trigger={['click']} overlayStyle={{ width: 170 }}>
							<div className="sprout-header-task header-button">
								<span>{__('Task')}&nbsp;</span>
								<Badge overflowCount={9} size="small" count={security.task.total || 0} />
							</div>
						</Dropdown> : security.platform !== 'EMPTY' && <div className="sprout-header-task header-button" onClick={this.changeToTask}>
							{__('Task')}
						</div>
					}
					{
						(security.fileList && security.fileList?.length > 0) && <div onClick={() => openFileListModal()} className="sprout-header-task header-button">
							<span>{__('Downloads')}&nbsp;</span>
							<Badge size="small" count={security.fileList.filter(val => val.status === '0').length || 0} />
						</div>
					}
					<Dropdown overlay={this.renderPersonalMenu()} placement="bottomLeft" trigger={['click']}>
						<div className="sprout-header-avatar header-button">
							<UserOutlined className="glyphicon" style={{ top: '0px' }} />
							<span className="sprout-header-name">{AdmintoolUserRole == 'admin' ? 'Folium Admin' : security.userInfo.user_name}</span>
							<DownOutlined className="glyphicon" />
						</div>
					</Dropdown>
				</div>
			</div>
			
		</div>
	}
}