import React, { Component } from 'react';
import { post } from 'srcPath/http';
import { Spin, message, Table, Button } from 'antd';
import { formatNumber } from 'Utils';
import openCreateMeetingModal from '../components/CreateMeetingModal';
import { ExclamationCircleTwoTone, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import Permission from 'components/Permission';
import Modal from 'components/Modal';
import './style.scss';
import { formatDate } from '../Detail/config';
import { formatDate as UtilFormatDate } from 'Utils/format/formatDate';

const colorMap = {
	Draft: '#bdd1d5',
	Archived: '#364453',
	Published: '#25ba86'
}

export default class List extends Component {
	modalRef = null
	state = {
		tableData: [],
		loading: true
	}

	componentDidMount() {
		this.getTableData()
	}

	componentWillUnmount() {
		this.modalRef && this.modalRef.close()
	}

	getTableData = async () => {
		this.setState({ loading: true })

		try {
			const { data: resData } = await post('getBoardMeetList')

			const { code, data } = resData || {}

			if (code == 0) {
				data.forEach((val, index) => { val.index = index })

				this.setState({ tableData: data })
				// this.setState({ tableData: mockData })
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}

		this.setState({ loading: false })
	}	

	deleteMeeting = async(meeting_id, callback) => {
		this.setState({ loading: true })

		try {
			const { data: resData } = await post('delMeeting', { meeting_id })
			const { data, code } = resData

			if (code == 0) {
				this.getTableData()
				callback && callback()
			} else {
				message.error(data)
			}

		} catch(err) {
			message.error(err && err.message)
		}

		this.setState({ loading: false })
	}

	onDelete = (data) => {
		this.modalRef = Modal.open({
			component: props => <div>
				<p><ExclamationCircleTwoTone />&nbsp;{__('Are you sure to delete this meeting?')}</p>
				<div style={{ textAlign: 'center', marginTop: 20 }}>
					<Button onClick={props.close}>{__('Cancel')}</Button> &nbsp;&nbsp;
					<Button type="primary" onClick={() => { this.deleteMeeting(data.meeting_id, props.close) }}>{__('Ok')}</Button>
				</div>
			</div>
		})
	}

	sorterDateOrderBy = (a, b, key) => {
		const dateA = a[key].slice(0,10)
		const dateB = b[key].slice(0,10)
        const dataA = moment(dateA, 'DD/MM/YYYY').format('YYYYMMDD');
        const dataB = moment(dateB, 'DD/MM/YYYY').format('YYYYMMDD');
        return dataA - dataB;
    }

	getColumns = () => {
		return [
			{
				title: __('Name'),
				dataIndex: 'meeting_name',
				sorter: (a, b) => a.meeting_name.length - b.meeting_name.length,
				ellipsis: true
			},
			{
				title: __('Date'),
				dataIndex: 'meeting_date',
				sorter: (a, b) => this.sorterDateOrderBy(a, b, 'meeting_date'),
				render: value => {
					const newDate = value?.slice(0, 10)
					return `${UtilFormatDate(newDate)} ${value?.slice(10)}`
				},
				width: '30%'
			},
			{
				title: __('Status'),
				dataIndex: 'status',
				sorter: (a, b) => a.status.length - b.status.length,
				render: text => <div className="meeting-list-status"> <div className="status-dot" style={{ background: colorMap[text] || '#bdd1d5' }}/> {text} </div>
			},
			{
				title: __('Agenda items'),
				dataIndex: 'agenda_items',
				render: text => formatNumber(text, 0)
			},
			{
				title: '',
				dataIndex: '',
				align: 'right',
				width: 160,
				render: props => <div className="meeting-list-btns">
					<Permission type="board_meeting_detail"><Button  type="link" className="sprout-btn-divider" onClick={() => { this.props.history.push('/home/board/meetings/detail',  { name: props.meeting_name, id: props.meeting_id }) }}>{__('view')}</Button></Permission>
					<Permission type="board_meeting_delete"><Button type="link" danger disabled={props.status === 'Archived'} onClick={() => this.onDelete(props)} >{__('delete')}</Button></Permission>
				</div>
			},
		]
	}

	render() {
		const { tableData, loading } = this.state

		return <div className="meeting-list-root">
			<div className="meeting-list-banner">
				<Permission type="board_meeting_create" ><Button type="primary" onClick={() => { openCreateMeetingModal(this.getTableData)}}><PlusOutlined />{__('Create board meeting')}</Button></Permission>
			</div>
			<Table size="small" rowKey="index" columns={this.getColumns()} dataSource={tableData} pagination={false} loading={loading} />
		</div>
	}
}