import React, { Component } from 'react';
import Modal, { ModalContent } from 'components/Modal';
import { post } from 'srcPath/http';
import { message, Radio, Button, Input, Tooltip } from 'antd';
import { FormItem, withForm } from 'react-antd-formutil';
import { QuestionCircleOutlined } from '@ant-design/icons';
import './style.scss';

@withForm
class AddCoin extends Component {
	state = {
		loading: false,
		publicList: [],
		coinList: [],
	}

    handleSubmit = async () => {
        const { $formutil, callback, close } = this.props
		const {  $invalid, $batchDirty, $params, $getFirstError } = $formutil

		if ($invalid) {
			$batchDirty(true)
			message.error($getFirstError())

			return
		}

        this.setState({ loading: true })

        try {
			const { data: resData } = await post('addCustomCoin', { ...$params })

			const { code, data } = resData || {}

			if (code == 0) {
				message.success(__('success'))
                close();
                callback && callback()
			} else if (code == '10001') {
				message.error(__('repeat'))
			} else{
                message.error(data)
            }
		} catch(err) {
			message.error(err && err.message)
		}

        this.setState({ loading: false })        
    }

	render () {
        const { loading } = this.state

		return <div className="add-coin-root" >
                    <div className="add-coin-form">
                        <FormItem
                            name="status"
                            colon={true}
                            itemProps={{
                                label: __('Status'),
                                colon: false
                            }}
                            required
                            validMessage={{
                                required: __('Status is required')
                            }}
                            $defaultValue="Public"
                        >
                            <Radio.Group>
                                <Radio value="Public">{__('Public')}</Radio>
                                <span>
                                    <Radio disabled={true} value="Private">{__('Private')}</Radio>
                                    <Tooltip title={__('If your token is on your private chain, please contact us at "support@getsprout.co"')}>
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </span>
                            </Radio.Group>
                        </FormItem>
                        <FormItem 
                            name="address"
                            colon={true}
                            itemProps={{
                                label: __('Chain link'),
                                colon: false
                            }}
                            required
                            validMessage={{
                                required: __('Chain link is required')
                            }}
                        >
                            <Input />
                        </FormItem>
                        <FormItem 
                            name="notes"
                            colon={false}
                            itemProps={{
                                label: __('Notes'),
                                colon: false
                            }}
                        >
                            <Input.TextArea 
                                autoSize={{ minRows: 4 }}
                            />
                        </FormItem>
                    </div>
				<div className="add-coin-btns">
                    <Button loading={loading} onClick={this.handleSubmit} type="primary">
                        {__('Confirm')}
                    </Button>
                </div>
		</div>
	}
}

export function openAddCoinModal(callback) {
    return Modal.open({
        component: props => <ModalContent close={props.close} title={__("Create token pool")}><AddCoin close={props.close} callback={callback} /></ModalContent>,
        width: 600,
        className: 'sprout-modal',
        maskClosable: false,
    }, )
}

export default AddCoin