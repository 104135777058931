import React, { Component } from 'react';
import { Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import security from 'stores/security';
import Holdings from './components/Holdings';
import Capitalization from './components/Capitalization';
import FinancingHistory from '../../components/FinancingHistory';
import companyImg from './images/company.png';
import addressImg from './images/address.png';
import dataImg from './images/data.png';
import textOnlyImg from './images/textOnly.png';
import webImg from './images/web.png';

import './style.scss';

const renderData = value => value || '--'

const renderProfile = data => {
	return <div className="portfolio-profile-detail">
		<div className="portfolio-profile-name">
			<img src={data.img} />
			<span>{data.label}</span>
		</div>
		<div className="portfolio-profile-value">{renderData(data.value)}</div> 
	</div>
}

export default class Overview extends Component {
	render() {
		const { data = {}, onLink } = this.props
		const { accessLevel, currency } = data || {}
		const showMore = accessLevel && accessLevel >= 2
		const showToken = (security.companyInfo && security.companyInfo.is_show_coin) === '1'
		const { address, business_description, company_name, founded_date, website  } = data.profile || {}

		return <div className="portfolio-overview-root">
			{/* equity */}
			<div className="portfolio-overview-section">
				<Holdings type="Equity" data={data} onLink={onLink} />
			</div>
			{/* crypto */}
			{/* FIXME: 这里的条件写的反的 */}
			{/* {!showToken && ( */}
				<div className="portfolio-overview-section">
					<Holdings type="Crypto" data={data} onLink={onLink} />
				</div>
			{/* )} */}
		</div>
	}
}