import React from "react";
import { post } from 'srcPath/http';

class RenameDraftSet extends React.Component{
    constructor(props){
        super(props);
        this.state={
            createStatus:0,
            id:"",
            data:{
                name:"",
            }
        }
    }
    initData=(key,initData)=>{
        this.setState({
            data:initData,
            id:initData.id
        });
    }
    recordData=(e)=>{
        let curData = this.state.data;
        curData.name = e.target.value;
        this.setState({
            data:curData
        })
    }
    renameForDraftSet=()=>{
        if(this.state.createStatus==1){
            return;
        }
        this.setState({
            createStatus:1
        })
        let requestData = this.state.data;
        post('editDraftSet', {name:requestData.name,id:this.state.id}).then((response)=> {
            if(response.data.code === 0){
                this.props.getDraftSetList();
                this.props.changeDraftId(this.state.id,this.state.data.name)
                this.props.getDraftSecurityList();
                window.$("#enterNewNameForDraftsModal").modal("hide");
                    this.setState({
                        createStatus:0,
                    })
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    render(){
        return(
            <div className="modal fade" id="enterNewNameForDraftsModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="modal-dialog dialog-top40">
                    <div className="modal-content content500">
                        <div className="modal-header box-header relation-header">
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Enter')}</h4>
                        </div>
                        <div className="modal-body captable-body no-paddingbottom">
                            <form className="captable-form">
                                <div className="form-group">
                                    <div className="row">
                                        <label className="col-xs-8 add-label">{__('Enter a new name for')} '{this.state.data.name}':</label>
                                        <div className="col-xs-4 no-paddingleft">
                                            <input name="name" value={this.state.data.name} onChange={this.recordData} className="form-control" type="text" />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer box-footer padding203020">
                            <a data-dismiss="modal" onClick={this.renameForDraftSet} type="button" className="btn boxbtn-drafts bg-darkblue width80">{__('Ok')}</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default RenameDraftSet;