import React, { Component } from 'react';
import Modal, { ModalContent } from 'components/Modal';
import { FormItem, withForm } from 'react-antd-formutil';
import { Input, Button, message, Select, Alert } from 'antd';
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined, BulbOutlined } from '@ant-design/icons';
import { post } from 'srcPath/http';
import Utils, { bindKeyEvent } from 'Utils';
import { permissionList, permissionsType, permissionsTypeMpa } from 'Utils/constant';
import security from 'stores/security';
import './style.scss';
import { printf } from 'Utils/i18n';

const email = new RegExp('(?![\\-_.])[\\w\\-.]+@(?:(?![\\-_])[\\w\\-\u4e00-\u9fa5]{1,63}\\.)+[A-Za-z0-9]{2,}')

// const permissionsOption = [
// 	// {label: 'Legal administrator', value: '1'},
// 	{ label: 'Super admin', value: '1' },
// 	{ label: 'Company editor', value: '2' },
// 	{ label: 'Company viewer', value: '3' },
// 	{ label: 'ESOP admin', value: '5' },
// 	{ label: 'Share class admin', value: '6' },
// 	{ label: 'Data room user', value: '8' },
// ]

// const permissionsType = [
// 	{
// 		label: 'View company data',
// 		value: true
// 	},
// 	{
// 		label: 'Download reports',
// 		value: true
// 	},
// 	{
// 		label: 'Draft securities',
// 		value: true
// 	},
// 	{
// 		label: 'Invite company editors',
// 		value: true
// 	},
// 	{
// 		// label: 'Invite legal administrators',
// 		label: 'Invite super admin',
// 		value: true
// 	},
// 	{
// 		label: 'Issue securities',
// 		value: true
// 	},
// 	{
// 		label: 'Edit securities',
// 		value: true
// 	},
// ]
//
// const permissionsTypeMpa = {
// 	1: [true, true, true, true, true, true, true],
// 	2: [true, true, true, true, false, false, false],
// 	3: [true, true, false, false, false, false, false],
// 	5: [true, true, true, false, false, false, false],
// 	6: [true, true, true, false, false, false, false],
//
// }

function processPermissonType(flag) {
	const flags = ['1', '2', '3', '5', '6', '8']

	if (!flags.includes(flag)) {
		return []
	}

	return permissionsTypeMpa[flag].map((val, index) => { permissionsType[index].value = val; return permissionsType[index] })
}

const Option = Select.Option

class AddUser extends Component {
	state = {
		loading: false,
		error: '',
		resendMembers: ''
	}

	componentDidMount() {

		const { data, $formutil, resendMembers } = this.props;
		if (data) {
			const { email, id, name, permissions, titles } = data

			$formutil.$setValues({
				invite_email: email, id, name, permissions, titles
			})
		}
		this.setState({ resendMembers })
		this.removeKeyEvent = bindKeyEvent(this.onSubmit)
	}

	componentWillUnmount() {
		this.removeKeyEvent && this.removeKeyEvent()
	}

	onSubmit = async () => {
		const { $formutil, close, callback, data } = this.props
		const { $invalid, $batchDirty, $params, $getFirstError } = $formutil

		if ($invalid) {
			$batchDirty(true)
			message.error($getFirstError())

			return
		}

		this.setState({ loading: true })

		const api = data ? 'updateUsers' : 'inviteAdmin'
		const params = data ? {
			...$params,
			email: $params.invite_email
		} : $params

		try {
			const { data: resData } = await post(api, params)
			const { code, data } = resData || {}

			if (code == 3) {
				this.setState({ error: __('You cannot invite the same user more than one time.') })
			}

			if (code == 2) {
				this.setState({ error: __('You cannot invite yourself as a second user. Please add another user.') })
			}
			if (code == 6) {
				this.setState({ error: data })
			}
			if (code == 0) {
				close && close()
				callback && callback(!!data)
			}
		} catch (e) {
			message.error(e.message)
		}

		this.setState({ loading: false })
	}

	render() {
		const { error, resendMembers } = this.state
		const { $formutil, data } = this.props
		const { $params = {} } = $formutil
		const flag = (security.companyInfo && security.companyInfo.flag) || 0

		return <div className="permisson-user-modal">
			<div className="permisson-user-modal-content">
				{error && <Alert style={{ marginBottom: 24 }} message={error} type="error" showIcon />}
				<FormItem
					name="name"
					required
					label={__('Name')}
					colon={false}
					validMessage={{
						required: __('Name is required')
					}}
				>
					<Input placeholder="e.g. John" disabled={!!data} />
				</FormItem>
				<FormItem
					name="invite_email"
					label={__('Email address')}
					required
					colon={false}
					pattern={email}
					validMessage={{
						required: __('Email is required'),
						pattern: __('Email is invalid')
					}}
				>
					<Input placeholder={__('e.g. John@example.com')} disabled={!!data} />
				</FormItem>
				<FormItem
					name="titles"
					label={<span>{__('Title')}&nbsp;<i className="text-muted">-{__('Optional')}</i></span>}
					colon={false}
				>
					<Input />
				</FormItem>

				<FormItem
					name="permissions"
					label={__('Permissions')}
					required
					colon={false}
					validMessage={{
						required: __('Permissions is required'),
					}}
				>
					<Select placeholder="Select">
						{	// 过滤掉权限比user 权限大的选项
							// permissionsOption.filter((val) => Number(val.value) >= Number(flag)).map((value, index) => <Option key={index} value={value.value}>{value.label}</Option>)
							permissionList.filter((val) => Number(val.value) >= Number(flag)).map((value, index) => <Option key={index} value={value.value}>{value.text}</Option>)
						}
					</Select>
				</FormItem>
				{
					$params.permissions && <div className="permisson-user-type">
						<div className="title"><BulbOutlined /> {__('Permission details')}:</div>
						{processPermissonType($params.permissions).map((val, index) => {
							if (val.value) {
								return <div className="items" key={index}>
									{val.value ? <CheckOutlined style={{ color: '#63ba22' }} /> : <CloseOutlined style={{ color: '#e95252' }} />}<div className="value" key={index}>{val.label}</div>
								</div>
							}
						}
						)}
					</div>
				}
				{$params.permissions == 1 ?
					<div className='permission-user-tips'>
						<ExclamationCircleOutlined /> {__('Super admins have access to all the information associated with your account, including all the shareholding details of all the subsidiaries associated with the account.')}
					</div> : <div className='permission-user-tips'>
						<ExclamationCircleOutlined /> {__('Please select another person to be a super admin. Sprout operates on a minimum two super admin system to ensure your company is transparently managing critical information with proper corporate oversight.')}
					</div>
				}
				{ resendMembers&&
					<div className='permission-user-tips'>
					<ExclamationCircleOutlined /> {printf(__("Your invited users will receive a reminder email every %s days if they haven't logged in."), resendMembers)}
				</div>
				}
				<div className="btn-wrap">
					<Button type="primary" loading={this.state.loading} onClick={this.onSubmit}>{__('Save')}</Button>
				</div>
			</div>
		</div>
	}
}

export const AddUserContent = withForm(AddUser)

export default function openAddUserModal(callback, data, resendMembers) {
	const { name } = data || {}

	return Modal.open({
		component: props => <ModalContent close={props.close} title={data ? `Update ${name}` : 'Add user'}> <AddUserContent data={data} close={props.close} callback={callback} resendMembers={resendMembers} /></ModalContent>,
		width: 500,
		className: 'sprout-modal'
	})
}

