import React from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import { post } from 'srcPath/http';
import Utils, { formatNumber, calculator } from "Utils";
import okGif from 'assets/images/finish-gray.gif';
import okURL from 'assets/images/ok.png';
import '../../../../../assets/css/relationship.css';
import {DeleteOutlined} from "@ant-design/icons";
class CreateVestingSchedule extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isOnBoarding:0,
            isEdit:"0",
            createStatus:"0",
            updateStatus:0,
            standardError:0,
            standardEmptyError:0,
            timeBasedError:0,
            milestoneError:0,
            isIncludeCliff:0,
            standardRequiredKey:["schedule_name","length_of_schedule"],
            isIncludeVesting:0,
            data:{
                id:"",
                is_in_use:0,
                type:"0",
                schedule_name:"",
                vesting_occurs:{period:1,exact_data:1},
                length_of_schedule:"",
                vesting_schedule_summary:"",
                cliff_percentage:"",
                cliff_length:{value:"",date_type:"days"},
                immediate_vesting:"",
                condition_name:"",
                condition_type:"",
                condition_details:"",
                custom_type:"Milestone",
                custom_details_json:[
                    {period:1,tLength:"",frequency:"days",percentage:0,vesting_occurs:""},
                    ],
                custom_details_data:{
                    timebased:[
                        {period:1,tLength:"",frequency:"days",percentage:0,vesting_occurs:""},
                    ],
                    milestone:[
                        {milestone:"",percentage:0}
                    ]
                }
            },
            type0RequiredKey:["schedule_name","length_of_schedule"],
            type2RequiredKey:["condition_name","condition_type","condition_details",],
            periodList:[
                {id:1,content:"Every day"},
                {id:2,content:"Every week"},
                {id:3,content:"Every month"},
                {id:4,content:"Every 2 month"},
                {id:5,content:"Every 3 month"},
                {id:6,content:"Every 6 month"},
                {id:7,content:"Every 12 month"},
                {id:8,content:"Calendar quarters"},
            ],
            exactDataList:[
                {id:1,content:"on the same day as the start date"},
                {id:2,content:"on the same day as the start date, minus one"},
                {id:3,content:"on the first day of the month"},
                {id:4,content:"on the last day of the month"},
            ],
            scheduleNameError:0,
            vestingSchedulesList:[],
            dataKey:''
        }
    }

    componentDidMount(){
        window.$("[href='#home']").tab("show");
    }
    changeToEditStatus=()=>{
        this.setState({
            isEdit:1
        })

    }
    changeToCreateStatus=()=>{
        this.setState({
            isEdit:0
        })
    }
    changeToOnBoarding=()=>{
        this.setState({
            isOnBoarding:1
        })
    }
    changeScheduleType=(key)=>{
        let curData = this.state.data;
        curData.type = key;
        if(key==0 && (curData.vesting_occurs.period==""|| curData.vesting_occurs.exact_data=="")){
            curData.vesting_occurs.period = 1;
            curData.vesting_occurs.exact_data = 1;
        }
        this.setState({
            data:curData,
            standardEmptyError:0,
            timeBasedError:0,
            milestoneError:0,

        })
        for(let i=0;i<3;i++){
            if(i===key){
                $("#createScheduleBtn"+key).addClass("active")
            }else{
                $("#createScheduleBtn"+i).removeClass("active")
            }
        }
    }

    initData=(dataKey,data)=>{
        let initData={...data}
        initData.custom_details_data = [];
        if(initData.custom_type=="Date"){
            initData.custom_details_data.timebased = initData.custom_details_json
        }else{
            initData.custom_details_data.milestone = initData.custom_details_json
        }
        this.setState({
            dataKey,
            data:initData,
        });
    }
    setCustomScheduleType=(e)=>{
        let curData = this.state.data;
        curData.custom_type = e.target.value;
        this.setState({
            data:curData,
            timeBasedError:0,
            milestoneError:0
        });
    }
    setTimebasedData=(numKey,valueKey,e)=>{
        let curData = this.state.data;
        if(valueKey=="frequency"){
            curData.custom_details_data.timebased[numKey][valueKey] = e.target.value;
        }else if(valueKey=="percentage"){
            curData.custom_details_data.timebased[numKey][valueKey] = Utils.check10DecimalNumInputWithoutComma(e.target.value);
        }else{
            curData.custom_details_data.timebased[numKey][valueKey] = Utils.checkNumInput(e.target.value);
        }
        this.setState({
            data:curData
        });
    }
    minusTimebased = (key)=>{
        let curData = this.state.data;
        curData.custom_details_data.timebased.splice(key,1);
        this.setState({
            data:curData
        });
    }
    plusTimebased=()=>{
        let curData = this.state.data;
        let curLength = curData.custom_details_data.timebased.length;
        let curCount = curData.custom_details_data.timebased[curLength-1].period;
        curData.custom_details_data.timebased.push(
            {period:curCount+1,tLength:"",frequency:"days",percentage:0,vesting_occurs:""}
        )
        this.setState({
            data:curData
        });
    }
    setMilestoneData=(numKey,valueKey,e)=>{
        let curData = this.state.data;
        if(valueKey=="milestone"){
            curData.custom_details_data.milestone[numKey][valueKey] = e.target.value;
        }else if(valueKey=="percentage"){
            curData.custom_details_data.milestone[numKey][valueKey] = Utils.check10DecimalNumInputWithoutComma(e.target.value);
        }else{
            curData.custom_details_data.milestone[numKey][valueKey] = Utils.checkNumInput(e.target.value);
        }
        this.setState({
            data:curData
        });
    }
    minusMilestone = (key)=>{
        let curData = this.state.data;
        curData.custom_details_data.milestone.splice(key,1);
        this.setState({
            data:curData
        });
    }
    plusMilestone=()=>{
        let curData = this.state.data;
        let curLength = curData.custom_details_data.milestone.length;
        // let curCount = curData.custom_details_data.milestone[curLength-1].period;
        curData.custom_details_data.milestone.push(
            {milestone:"",percentage:0}
        )
        this.setState({
            data:curData
        });
    }

    recordData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        });
    }
    setVestingOccurs=(valueKey,e)=>{
        let dataVal = e.target.value;
        let curData = this.state.data;
        if(dataVal == 8){
            curData.vesting_occurs.exact_data = 4;
            curData.vesting_occurs.exact_data_disabled = true;
        }else{
            curData.vesting_occurs.exact_data_disabled = false;
        }
        curData.vesting_occurs[valueKey] = dataVal;
        this.setState({
            data:curData
        })
    }

    setCliffLength=(valueKey,e)=>{
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData.cliff_length[valueKey] = dataVal;
        this.setState({
            data:curData
        })
    }
    ifIncludeCliff=()=>{
        let curData = this.state.data;
        if(this.state.isIncludeCliff ==0){
            this.setState({
                isIncludeCliff:1
            })
        }else{
            curData.cliff_percentage = "";
            curData.cliff_length.value = "";
            this.setState({
                data:curData,
                isIncludeCliff:0
            })
        }

    }
    ifIncludeVesting=()=>{
        if(this.state.isIncludeVesting ==0){
            this.setState({
                isIncludeVesting:1
            })
        }else {
            this.setState({
                isIncludeVesting:0
            })
        }
    }
    resetErrorStatus=()=>{
        let curData = this.state.data;
        let requiredKey = this.state.standardRequiredKey;
        $(".cliff_length_value").removeClass("has-error")
        for(let i=0;i<requiredKey.length;i++){
            $(".standard_"+requiredKey[i]).removeClass("has-error")
            this.setState({
                standardEmptyError:0
            })
        }
        curData.type = 0;
        // this.setState({
        //     data:curData
        // })
        // this.changeScheduleType(0)

        this.setState({
            createStatus:0,
            isIncludeCliff:0,
            standardEmptyError:0,
            timeBasedError:0,
            milestoneError:0,
            scheduleNameError:0,
            data:{
                id:"",
                type:"0",
                schedule_name:"",
                vesting_occurs:{period:1,exact_data:1},
                length_of_schedule:"",
                vesting_schedule_summary:"",
                cliff_percentage:"",
                cliff_length:{value:"",date_type:"days"},
                immediate_vesting:"",
                condition_name:"",
                condition_type:"",
                condition_details:"",
                custom_type:"Milestone",
                custom_details_json:[
                    {period:1,tLength:"",frequency:"days",percentage:0,vesting_occurs:""},
                ],
                custom_details_data:{
                    timebased:[
                        {period:1,tLength:"",frequency:"days",percentage:0,vesting_occurs:""},
                    ],
                    milestone:[
                        {milestone:"",percentage:10}
                    ]
                }
            },
        })

    }
    createVestingPlan=()=>{
            let requestData = this.state.data;
            let schedule_name = requestData.schedule_name
            let vestingSchedulesList = this.state.vestingSchedulesList
            let isError = vestingSchedulesList && vestingSchedulesList.findIndex(item => item.schedule_name === schedule_name)
            let requiredKey = this.state.standardRequiredKey;
            for(let i=0;i<requiredKey.length;i++){
                $(".standard_"+requiredKey[i]).removeClass("has-error")
            }
            if( isError !== -1){
                $(".standard_schedule_name").addClass("has-error")
                this.setState({
                    standardEmptyError : 0,
                    scheduleNameError : 1
                });
                return
            }
            if(this.state.createStatus ===1){
                return;
            }
           //在这里进行数据监测，如果发现问题数据，直接将错误信息展示出来，并且return;
            if(this.state.data.type==0 && this.state.isIncludeCliff ==1 && this.state.data.cliff_percentage==""){
                this.setState({
                    standardError : 1,
                });
                return;
            }else if(this.state.data.type==0 && this.state.isIncludeCliff ==1 && this.state.data.cliff_length==""){
                this.setState({
                    standardError : 1,
                });
                return;
            }else{
                this.setState({
                    standardError : 0,
                });
            }
            if(this.state.data.type== 1 && this.state.data.custom_type=="Date") {
                for(let i =0;i<this.state.data.custom_details_data.timebased.length;i++){
                    if(this.state.data.custom_details_data.timebased[i].tLength == ""){
                        this.setState({
                            timeBasedError : 1,
                        })
                        return;
                    }else{
                        this.setState({
                            timeBasedError : 0,
                        })
                    }
                }
            }
            if(this.state.data.type== 1 && this.state.data.custom_type=="Milestone") {
                for(let k =0;k<this.state.data.custom_details_data.milestone.length;k++){
                    if(this.state.data.custom_details_data.milestone[k].milestone == ""){
                        this.setState({
                            milestoneError : 1,
                        })
                        return;
                    }else{
                        this.setState({
                            milestoneError : 0,
                        })
                    }
                }
            }
            this.setState({
                createStatus:1
            });

            if(requestData.type== 1 && requestData.custom_type=="Date"){
                requestData.custom_details_json = requestData.custom_details_data.timebased
            }else if(requestData.type== 1 && requestData.custom_type=="Milestone"){
                requestData.custom_details_json = requestData.custom_details_data.milestone
            }

            post('createVestingSchedule', requestData).then((response)=> {
                    if(response.data.code === 0){
                        window.$("#createVestingScheduleModal").modal("hide");
                        if(this.state.isOnBoarding==1){
                            $('.finished img').attr('src',okURL);
                            $("#button-3-1 button").html("Add More");
                            $("#ok-3-1").addClass('finished');
                            $('#ok-3-1 img').show();
                            $('#ok-3-1 img').attr('src',okGif);
                            $("#complete-3-1").html("Completed by you just now");
                            $("#button-3-2").removeClass("hiddenrain");
                        }
                        this.resetErrorStatus();
                        this.setState({
                            createStatus:0,
                            isIncludeCliff:0,
                            standardEmptyError:0,
                            timeBasedError:0,
                            milestoneError:0,
                            data:{
                                id:"",
                                type:"0",
                                schedule_name:"",
                                vesting_occurs:{period:"",exact_data:""},
                                length_of_schedule:"",
                                vesting_schedule_summary:"",
                                cliff_percentage:"",
                                cliff_length:{value:"",date_type:"days"},
                                immediate_vesting:"",
                                condition_name:"",
                                condition_type:"",
                                condition_details:"",
                                custom_type:"Milestone",
                                custom_details_json:[
                                    {period:1,tLength:"",frequency:"days",percentage:0,vesting_occurs:""},
                                ],
                                custom_details_data:{
                                    timebased:[
                                        {period:1,tLength:"",frequency:"days",percentage:0,vesting_occurs:""},
                                    ],
                                    milestone:[
                                        {milestone:"",percentage:10}
                                    ]
                                }
                            },
                        })
                        if(this.state.isOnBoarding==0){
                            this.props.getScheduleList();
                        }
                        // window.location.reload();
                    }else{
                        console.log("failed, error code is: "+response.data.code);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
    }
    updateVestingPlan=()=>{
        let requestData = this.state.data;
        let dataKey = this.state.dataKey;
        let schedule_name = requestData.schedule_name
        let vestingSchedulesList = [...this.state.vestingSchedulesList]
        let idx = vestingSchedulesList && vestingSchedulesList.findIndex(item => item.id == vestingSchedulesList[dataKey].id)
        vestingSchedulesList.splice(idx, 1)
        let isError = vestingSchedulesList && vestingSchedulesList.findIndex(item => item.schedule_name === schedule_name)
        let requiredKey = this.state.standardRequiredKey;
        for(let i=0;i<requiredKey.length;i++){
            $(".standard_"+requiredKey[i]).removeClass("has-error")
        }
        if( isError !== -1){
            $(".standard_schedule_name").addClass("has-error")
            this.setState({
                standardEmptyError : 0,
                scheduleNameError : 1
            });
            return
        }
        if(this.state.updateStatus ===1){
            return;
        }
        if(this.state.data.type==0 && this.state.isIncludeCliff ==1 && this.state.data.cliff_percentage==""){
            this.setState({
                standardError : 1,
            });
            return;
        }else if(this.state.data.type==0 && this.state.isIncludeCliff ==1 && this.state.data.cliff_length==""){
            this.setState({
                standardError : 1,
            });
            return;
        }else{
            this.setState({
                standardError : 0,
            });
        }
        if(this.state.data.type== 1 && this.state.data.custom_type=="Date") {
            for(let i =0;i<this.state.data.custom_details_data.timebased.length;i++){
                if(this.state.data.custom_details_data.timebased[i].tLength == ""){
                    this.setState({
                        timeBasedError : 1,
                    })
                    return;
                }else{
                    this.setState({
                        timeBasedError : 0,
                    })
                }
            }
        }
        if(this.state.data.type== 1 && this.state.data.custom_type=="Milestone") {
            for(let k =0;k<this.state.data.custom_details_data.milestone.length;k++){
                if(this.state.data.custom_details_data.milestone[k].milestone == ""){
                    this.setState({
                        milestoneError : 1,
                    })
                    return;
                }else{
                    this.setState({
                        milestoneError : 0,
                    })
                }
            }
        }
        if(requestData.type== 1 && requestData.custom_type=="Date"){
            requestData.custom_details_json = requestData.custom_details_data.timebased
        }else if(requestData.type== 1 && requestData.custom_type=="Milestone"){
            requestData.custom_details_json = requestData.custom_details_data.milestone
        }
        post('updateVestingSchedule', requestData).then((response)=> {
            if(response.data.code === 0){
                window.$("#createVestingScheduleModal").modal("hide");
                this.setState({
                    updateStatus:0,
                    standardEmptyError:0,
                    timeBasedError:0,
                    milestoneError:0,
                })
                this.props.getScheduleList();
                // window.location.reload();
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    delVestingSchedule=()=>{
        let requestData = this.state.data;
        post('deleteVestingSchedule', {id:requestData.id,}).then((response)=> {
            if(response.data.code === 0){
                window.$("#createVestingScheduleModal").modal("hide");
                this.setState({
                    createStatus:0
                })
                this.props.getScheduleList();
                // window.location.reload();
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    showEmptyError=(e)=>{
        let curData = this.state.data;
        let requiredKey = this.state.standardRequiredKey;
        for(let i=0;i<requiredKey.length;i++){
            if(curData[requiredKey[i]]==""){
                $(".standard_"+requiredKey[i]).addClass("has-error")
                this.setState({
                    standardEmptyError:1
                })
            }else{
                $(".standard_"+requiredKey[i]).removeClass("has-error")
            }
        }
        if(this.state.isIncludeCliff==1){
            if(curData.cliff_length.value==""){
                $(".cliff_length_value").addClass("has-error")
                this.setState({
                    standardEmptyError:1
                })
            }else{
                $(".cliff_length_value").removeClass("has-error")
            }

        }
    }
    checkEmptyValue4Percentage=()=>{
        if(this.state.data.cliff_percentage.length === 0){
            this.calcPercentage();
        }
    }

    calcPercentage=()=>{
        if(this.state.data.cliff_length.value.length>0 && this.state.data.length_of_schedule.length>0){
            let cliffLength = this.state.data.cliff_length.value;
            let scheduleLength = this.state.data.length_of_schedule;
            let dateTypeOfSchedule = this.state.data.cliff_length.date_type;
            if(dateTypeOfSchedule=="days"){
                cliffLength = cliffLength/30;
            }
            if(dateTypeOfSchedule=="years"){
                cliffLength = cliffLength *12;
            }
            let percentage = parseFloat(10000*(parseFloat(cliffLength))/parseInt(scheduleLength));
            let curData = this.state.data;
            curData.cliff_percentage = Math.round(percentage)/100;
            this.setState({
                data:curData
            })
        }
    }
    getVestingSchedulesList=(list)=>{
        let vestingSchedulesList=[...list]
        this.setState({
            vestingSchedulesList
        })
    }

    render(){
        let curData = this.state.data;
        let percentageDate = 0;
        let percentageMilestone = 0;
        if(curData.custom_details_data.timebased){
            for (let i=0;i<curData.custom_details_data.timebased.length;i++){
                // if(curData.custom_details_data.timebased[i].percentage==""||curData.custom_details_data.timebased[i].percentage=="null"){
                //     curData.custom_details_data.timebased[i].percentage = 0;
                // }
                if(curData.custom_details_data.timebased[i].percentage!=""){
                    // percentageDate += (parseFloat(curData.custom_details_data.timebased[i].percentage)*10000)/10000 ;
                    percentageDate = calculator.add( percentageDate , (parseFloat(curData.custom_details_data.timebased[i].percentage)*10000)/10000 );
                }
            };
        }
        if(curData.custom_details_data.milestone){
            for (let y=0;y<curData.custom_details_data.milestone.length;y++){
                // if(curData.custom_details_data.milestone[y].percentage==""||curData.custom_details_data.milestone[y].percentage=="null"){
                //     curData.custom_details_data.milestone[y].percentage = 0;
                // }
                if(curData.custom_details_data.milestone[y].percentage!=""){
                    percentageMilestone = calculator.add( percentageMilestone, (parseFloat(curData.custom_details_data.milestone[y].percentage)*10000)/10000 );
                }
            }
        }
        let scheduleCreateBtn = <button type="button" className="btn boxbtn-to marginTop10 send-disabledBtn">{__('Create vesting schedule')}</button>;
        let scheduleEditBtn = <button type="button" className="btn boxbtn-to marginTop10 send-disabledBtn">{__('Update vesting schedule')}</button>
        if(curData.type == 0){
            if(curData.schedule_name!=""&& curData.length_of_schedule!=""){
                if(this.state.isIncludeCliff=="0"){
                    scheduleCreateBtn = <button type="button" onClick={this.createVestingPlan} className="btn boxbtn-to update-btn bg-darkblue">{__('Create vesting schedule')}</button>;
                    scheduleEditBtn = <button type="button" onClick={this.updateVestingPlan} className="btn boxbtn-to bg-darkblue update-btn">{__('Update vesting schedule')}</button>
                }else if(this.state.isIncludeCliff=="1" && curData.cliff_percentage!=""){
                    scheduleCreateBtn = <button type="button" onClick={this.createVestingPlan} className="btn boxbtn-to update-btn bg-darkblue">{__('Create vesting schedule')}</button>;
                    scheduleEditBtn = <button type="button" onClick={this.updateVestingPlan} className="btn boxbtn-to bg-darkblue update-btn">{__('Update vesting schedule')}</button>
                }else{
                    scheduleCreateBtn = <button type="button" onClick={this.showEmptyError} className="btn boxbtn-to update-btn bg-darkblue">{__('Create vesting schedule')}</button>;
                    scheduleEditBtn = <button type="button" onClick={this.showEmptyError} className="btn boxbtn-to bg-darkblue update-btn">{__('Update vesting schedule')}</button>
                }
            }else{
                scheduleCreateBtn = <button type="button" onClick={this.showEmptyError} className="btn boxbtn-to update-btn bg-darkblue">{__('Create vesting schedule')}</button>;
                scheduleEditBtn = <button type="button" onClick={this.showEmptyError} className="btn boxbtn-to bg-darkblue update-btn">{__('Update vesting schedule')}</button>
            }
        }
        if(curData.type == 1 && curData.schedule_name !== "" && percentageDate == 100){
            scheduleCreateBtn = <button type="button" onClick={this.createVestingPlan} className="btn boxbtn-to update-btn bg-darkblue">{__('Create vesting schedule')}</button>;
            scheduleEditBtn = <button type="button" onClick={this.updateVestingPlan} className="btn boxbtn-to bg-darkblue update-btn">{__('Update vesting schedule')}</button>
        }
        if(curData.type == 1 && curData.schedule_name !== "" && percentageMilestone == 100){
            scheduleCreateBtn = <button type="button" onClick={this.createVestingPlan} className="btn boxbtn-to update-btn bg-darkblue">{__('Create vesting schedule')}</button>;
            scheduleEditBtn = <button type="button" onClick={this.updateVestingPlan} className="btn boxbtn-to bg-darkblue update-btn">{__('Update vesting schedule')}</button>
        }
        return(
            <div className="modal fade" id="createVestingScheduleModal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog vesting-dialog">
                    <div className="modal-content vesting-content">
                        <div className="modal-header box-header">
                            {this.state.isEdit==1 && this.state.data.is_in_use == 0 &&
                            <DeleteOutlined className="colorWhite vesting-trash" onClick={this.delVestingSchedule} />
                            }
                            <button type="button" className="close shut-btn" onClick={this.resetErrorStatus} data-dismiss="modal" aria-hidden="true">×</button>
                            <h4 className="modal-title" id="myModalLabel">{this.state.isEdit == "1" ? __("Update vesting schedule") : __("Create vesting schedule")}</h4>
                        </div>
                        <div className="modal-body equity-body">
                            <div className="vesting-box">
                                {this.state.isEdit == "0" &&
                                <ul className="nav nav-pills vesting-nav" role="tablist">
                                    <li id="createScheduleBtn0" role="presentation" className="vesting_btn active"><a className="vesting-a" onClick={this.changeScheduleType.bind(this,0)}>{__('Standard')}</a></li>
                                    <li id="createScheduleBtn1" role="presentation"><a className="vesting_btn vesting-a" onClick={this.changeScheduleType.bind(this,1)}>{__('Custom')}</a></li>
                                </ul>
                                }
                                <div className="tab-content reset_margin">
                                    {this.state.data.type== 0 &&
                                    <div >
                                        {this.state.standardEmptyError == 1 &&
                                        <div className="alert alert-warning alert-dismissable pink-box marginTop30" role="alert">
                                            <p>{__('Required field can not be empty.')}</p>
                                        </div>
                                        }
                                        {this.state.scheduleNameError == 1 &&
                                        <div className="alert alert-warning alert-dismissable pink-box marginTop30" role="alert">
                                            <p>{__('Duplicated schedule name!')}</p>
                                        </div>
                                        }
                                        <form className="form-horizontal vesting-form">
                                            <div className="form-group ">
                                                <label className="col-xs-4 add-label">{__('Schedule name')}<br/><i><small className="text-muted">- {__('Required')}</small></i></label>
                                                <div className="col-xs-5 standard_schedule_name">
                                                    <input name="schedule_name" value={this.state.data.schedule_name} onChange={this.recordData} className="form-control" required type="text" placeholder={__("e.g. Founder vesting")} />
                                                    <div className="vesting-tip">
                                                        <span></span>
                                                        <p className="text-muted">{__('This name will be shown to the security holder.')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group ">
                                                <label className="col-xs-4 add-label">{__('Vesting occurs')}</label>
                                                <div className="col-xs-5">
                                                    <select onChange={this.setVestingOccurs.bind(this,"period")} value={this.state.data.vesting_occurs.period} className="form-control select-container select-occurs1">
                                                        {this.state.periodList.map((value,key)=>{
                                                            return(
                                                                <option key={key} value={value.id}>{value.content}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group ">
                                                <div className="col-xs-5 col-xs-push-4">
                                                    <select disabled={this.state.data.vesting_occurs?.exact_data_disabled} onChange={this.setVestingOccurs.bind(this,"exact_data")} value={this.state.data.vesting_occurs.exact_data} className="form-control select-container select-occurs1 vesting-occurs-padding">
                                                        {this.state.exactDataList.map((value,key)=>{
                                                            return(
                                                                <option key={key} value={value.id}>{value.content}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group ">
                                                <label className="col-xs-4 add-label">{__('Length of schedule')}<br/><i><small className="text-muted">- {__('Required')}</small></i></label>
                                                <div className="col-xs-3">
                                                    <div className="input-group standard_length_of_schedule">
                                                        <input name="length_of_schedule" onBlur={this.calcPercentage} onChange={this.recordData} value={this.state.data.length_of_schedule} type="text" required className="form-control" aria-describedby="length_of_schedule_addon"/>
                                                        <span className="input-group-addon" id="length_of_schedule_addon">{__('Months')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group ">
                                                <label className="col-xs-4 add-label">{__('Vesting schedule summary')}</label>
                                                <div className="col-xs-5">
                                                    <textarea name="vesting_schedule_summary" onChange={this.recordData} value={this.state.data.vesting_schedule_summary} className="form-control"  type="text"/>
                                                </div>
                                                <div className="col-xs-push-4 col-xs-7 vesting-tip">
                                                    <span></span>
                                                    <p className="text-muted">{__('This vesting schedule will be displayed in a board consent if an option grant linked to the schedule is sent for board approval.')}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="form-group">
                                                    <a role="button" data-toggle="collapse" href="#cliff" onClick={this.ifIncludeCliff} className="vesting-collapse-a text-blue col-xs-3 col-xs-push-4">{this.state.isIncludeCliff==1?__("Remove cliff"):__("Include cliff")}</a>
                                                </div>
                                                <div className="collapse" id="cliff">
                                                    <div className="form-group ">
                                                        <label className="col-xs-4 add-label">{__('Cliff length')}<br/><i><small className="text-muted">- {__('Required')}</small></i></label>
                                                        <div className="col-xs-3 cliff_length_value">
                                                            <input type="text" onBlur={this.calcPercentage} onChange={this.setCliffLength.bind(this,"value")} value={this.state.data.cliff_length.value} className="form-control" required />
                                                        </div>
                                                        <div className="col-xs-2 no-paddingleft">
                                                            <select name="cliff_length" onBlur={this.calcPercentage} onChange={this.setCliffLength.bind(this,"date_type")} value={this.state.data.cliff_length.date_type} className="form-control select-container">
                                                                <option value="days">{__('days')}</option>
                                                                <option value="months">{__('months')}</option>
                                                                <option value="years">{__('years')}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-xs-4 add-label">{__('Cliff percentage')}<br/><i><small className="text-muted">- {__('Required')}</small></i></label>
                                                        <div className="col-xs-3">
                                                            <div className="input-group">
                                                                <input onBlur={this.checkEmptyValue4Percentage} name="cliff_percentage" disabled={true} value={this.state.data.cliff_percentage} type="text" required className="form-control" aria-describedby="vesting-addon2"/>
                                                                <span className="input-group-addon" id="vesting-addon2">%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="form-group">
                                                    <a role="button" data-toggle="collapse" href="#immediate" onClick={this.ifIncludeVesting} className="vesting-collapse-a text-blue col-xs-4 col-xs-push-4">{this.state.isIncludeVesting==0?__("Include immediate vesting"):__("Remove immediate vesting")}</a>
                                                </div>
                                                <div className="collapse" id="immediate">
                                                    <div className="form-group ">
                                                        <label className="col-xs-4 add-label">{__('Immediate vesting')}</label>
                                                        <div className="col-xs-3">
                                                            <div className="input-group">
                                                                <input name="immediate_vesting" onChange={this.recordData} value={this.state.data.immediate_vesting} type="text" required className="form-control" aria-describedby="vesting-addon2"/>
                                                                <span className="input-group-addon" id="vesting-addon2">%</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-push-4 col-xs-7 vesting-tip">
                                                            <span></span>
                                                            <p className="text-muted">{__('The percentage of the shares that will vest immediately on the vesting start date.')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    }
                                    {this.state.data.type == 1 &&
                                    <div>
                                        {this.state.data.custom_type=="Date" && this.state.timeBasedError == 1 &&
                                        <div className="alert alert-warning alert-dismissable pink-box marginTop30" role="alert">
                                            <p>{__('Length: This field is required.')}</p>
                                        </div>
                                        }
                                        {this.state.data.custom_type=="Date" && percentageDate>100 &&
                                        <div className="alert alert-warning alert-dismissable pink-box marginTop30" role="alert">
                                            <p>{__('Total percentage cannot exceed 100%')}</p>
                                        </div>
                                        }
                                        {this.state.data.custom_type=="Milestone" && percentageMilestone>100 &&
                                        <div className="alert alert-warning alert-dismissable pink-box marginTop30" role="alert">
                                            <p>{__('Total percentage cannot exceed 100%')}</p>
                                        </div>
                                        }
                                        {this.state.data.custom_type=="Milestone" && this.state.milestoneError == 1 &&
                                        <div className="alert alert-warning alert-dismissable pink-box marginTop30" role="alert">
                                            <p>{__('Milestone: This field is required.')}</p>
                                        </div>
                                        }
                                        <form className="form-horizontal vesting-form">
                                            <div className="form-group ">
                                                <label className="col-xs-4 add-label">{__('Schedule name')}<br/><i><small className="text-muted">- {__('Required')}</small></i></label>
                                                <div className="col-xs-5 custom_schedule_name">
                                                    <input name="schedule_name" onChange={this.recordData}
                                                           value={this.state.data.schedule_name}
                                                           className="form-control" required type="text"
                                                           placeholder="e.g. Founder vesting"/>
                                                </div>
                                                <div className=" col-xs-5 vesting-tip">
                                                    <span></span>
                                                    <p className="text-muted">{__('This name will be shown to the security holder.')}</p>
                                                </div>
                                            </div>
                                            {this.state.isEdit==0 &&
                                            <div className="form-group ">
                                                <label className="col-xs-4 add-label">{__('Type of vesting schedule')}</label>
                                                <div className="col-xs-5">
                                                    <select onChange={this.setCustomScheduleType}
                                                            value={this.state.data.custom_type} name="custom_type"
                                                            className="form-control select-container">
                                                        <option value="Date">{__('Time based')}</option>
                                                        <option value="Milestone">{__('Milestone/Performance')}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            }
                                            <div className="form-group ">
                                                <label className="col-xs-4 add-label">{__('Vesting schedule summary')}</label>
                                                <div className="col-xs-5">
                                                    <textarea name="vesting_schedule_summary" onChange={this.recordData} value={this.state.data.vesting_schedule_summary} className="form-control"  type="text"/>
                                                </div>
                                                <div className="col-xs-push-4 col-xs-7 vesting-tip">
                                                    <span></span>
                                                    <p className="text-muted">{__('This vesting schedule will be displayed in a board consent if an option grant linked to the schedule is sent for board approval.')}</p>
                                                </div>
                                            </div>
                                        </form>
                                        {
                                            this.state.data.custom_type === "Date" &&
                                            <table className="table vesting-table">
                                                <tbody>
                                                <tr className="bg-seablue vesting-tr vesting-tittr">
                                                    <td>Period</td>
                                                    <td className="length-td">{__('Length')}</td>
                                                    <td className="frequency-td">{__('Frequency')}</td>
                                                    <td className="perc-td">{__('Percentage')}</td>
                                                    <td className="occurs-td">{__('Vesting occurs')}</td>
                                                    <td>&nbsp;</td>
                                                </tr>

                                                {this.state.data.custom_details_data.timebased.map((value, key) => {
                                                    let curCount = key + 1;
                                                    return (
                                                        <tr key={key} className="vesting-tr">
                                                            <td>{curCount}</td>
                                                            <td className="length-td"><input value={value.tLength}
                                                                                             onChange={this.setTimebasedData.bind(this, key, "tLength")}
                                                                                             type="text"
                                                                                             placeholder="e.g. 1"/></td>
                                                            <td className="frequency-td">
                                                                <select value={value.frequency}
                                                                        onChange={this.setTimebasedData.bind(this, key, "frequency")}
                                                                        className="select-container select-frequency">
                                                                    <option value="days">{__('days')}</option>
                                                                    <option value="months">{__('months')}</option>
                                                                    <option value="years">{__('years')}</option>
                                                                </select>
                                                            </td>
                                                            <td className="perc-td"><input value={value.percentage}
                                                                                           onChange={this.setTimebasedData.bind(this, key, "percentage")}
                                                                                           type="text"
                                                                                           placeholder="e.g. 10"/></td>
                                                            <td className="occurs-td">
                                                                <select value={value.vesting_occurs}
                                                                        onChange={this.setTimebasedData.bind(this, key, "vesting_occurs")}
                                                                        className="select-container select-occurs vesting-tboccurs-padding">
                                                                    {this.state.exactDataList.map((value, key) => {
                                                                        return (
                                                                            <option key={key}
                                                                                    value={value.id}>{value.content}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </td>
                                                            <td className="vesting-lasttd">
                                                                <div className="btn-box btnBox-top6">
                                                                    {key === (this.state.data.custom_details_data.timebased.length - 1) &&
                                                                    <button onClick={this.plusTimebased} type="button"
                                                                            className="btn-mp plus"></button>
                                                                    }
                                                                    {this.state.data.custom_details_data.timebased.length > 1 &&
                                                                    <button
                                                                        onClick={this.minusTimebased.bind(this, key)}
                                                                        type="button"
                                                                        className="btn-mp minus"></button>}

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )

                                                })}
                                                <tr className="bg-skyblue">
                                                    <td className="font_weight500 width100perc input_color text_align-right">{__('Total percentage')}: {formatNumber(percentageDate,null)}% {percentageDate == 100 && <span className="color_green glyphicon glyphicon-ok"></span>}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        }
                                        {this.state.data.custom_type === "Milestone" &&
                                        <table className="table vesting-milestone-table">
                                            <tbody>
                                            <tr className="bg-seablue">
                                                <td>{__('Milestone')}</td>
                                                <td className="per-width per-titcolor">{__('Percentage')}</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            {this.state.data.custom_details_data.milestone.map((value, key) => {
                                                return (
                                                    <tr key={key} className="vesting-tr">
                                                        <td className=""><input  className="input_color" value={value.milestone}
                                                                                onChange={this.setMilestoneData.bind(this, key, "milestone")}
                                                                                type="text"
                                                                                placeholder={__("e.g. First milestone")}/>
                                                        </td>
                                                        <td className="per-width"><input value={value.percentage}
                                                                                         onChange={this.setMilestoneData.bind(this, key, "percentage")}
                                                                                         type="text" placeholder="0"/>
                                                        </td>
                                                        <td className="vesting-lasttd">
                                                            <div className="btn-box btnBox-top6">
                                                                {key === (this.state.data.custom_details_data.milestone.length - 1) &&
                                                                <button onClick={this.plusMilestone} type="button"
                                                                        className="btn-mp plus"></button>
                                                                }
                                                                {this.state.data.custom_details_data.milestone.length > 1 &&
                                                                <button onClick={this.minusMilestone.bind(this, key)}
                                                                        type="button"
                                                                        className="btn-mp minus"></button>}

                                                            </div>
                                                        </td>
                                                    </tr>
                                                )

                                            })}
                                            <tr className="bg-skyblue">
                                                <td colSpan="3">{__('Total percentage')}: {formatNumber(percentageMilestone,null)}% {percentageMilestone == 100 && <span className="color_green glyphicon glyphicon-ok"></span>}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        }

                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer box-footer vesting-footer">
                            {this.state.isEdit==0 &&
                                <div>
                                    {scheduleCreateBtn}
                                </div>
                            }
                            {this.state.isEdit==1 &&
                            // <button type="button" onClick={this.updateVestingPlan} className="btn boxbtn-to bg-darkblue update-btn">Update vesting schedule</button>
                            <div>
                            {scheduleEditBtn}
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default CreateVestingSchedule;

