import React from 'react';
import { observer } from 'mobx-react';
import openTaskTips from './TaskTips';
import openTOCTips from './TOCTips'
import security from 'stores/security';

export default observer(function Notification() {
    // TOC 弹窗优先级最高
    if (!openTOCTips()) {
        if (security.task && security.task.total > 0) {
            openTaskTips()
        }
    }
    return <div>
    </div>
}) 