import React, { Component } from "react";
import signupPlanImg from "assets/images/signup-plan-2.png";
import security from 'stores/security';
import ReportIssue from './ReportIssue';
import {Button} from "antd";
import Modal, { ModalContent } from 'components/Modal';

class ReportIssueModel extends Component {
    //reportIssue弹层
    reportIssueModal = null;
    getReportIssue = () => {
        this.reportIssueModal = Modal.open({
            component:  props => <ModalContent title="Report Issue" close={props.close}>
                <ReportIssue callback={props.close} hideHeader={true} />
            </ModalContent>,
            width: '700px',
            maskClosable: true,
            className: 'sprout-modal'
        })
    }

    /**
     * 卸载
     */
    componentWillUnmount() {
        if(this.advanceTermsModal) {
            this.advanceTermsModal.close();
        }
    }

    render() {
        const { company_name } = security.companyInfo;
        return (
            <div className="plan-message-root">
                <img src={signupPlanImg} />
                <p className="mt-10">{company_name} is still onboarding and is not available for all users.<br/>
                    Please contact your company admin if you any questions or concerns.</p>
                <Button type="primary" onClick={this.getReportIssue}>Contact admin</Button>
            </div>
        );
    }
}
export default ReportIssueModel;