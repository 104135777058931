import React from "react";
import Dot from 'components/Dot';
import { I, tableSort } from "Utils";
import { Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { relationshipList } from "Utils/constant";
import { renderWalletAddress } from 'Utils/RenderWalletAddress'
import { formatNumber, formatPercent } from 'Utils';
import formatShares from 'Utils/format/formatShares'
import formatTokenNumber from 'Utils/format/formatTokens';
import './style.scss'

export const columnsConfig = (flag, isEsopOrCaptableAdmin, props, cb) => {
    /**
     * 表格最后一列菜单配置
     * @param data
     * @param index
     * @returns {JSX.Element}
     */
    const dropdownInfo = (text, data, index) => {
        const { setCurSelectStakeholderNew } = props;
        return (
            <Dropdown onClick={setCurSelectStakeholderNew.bind(this, text)} overlay={menu(data, text)} placement="bottomRight" trigger="click">
                <div className="tableMenu"><DownOutlined style={{ color: '#FFF' }}/></div>
            </Dropdown>
        );
    }

    const menu = (value, id) => {
        const { stakeholder_id, wallet_label_id } = value
        // 如果有stakeholder_email 那么则是 
        const { editStakeholderOrWallet,cost_list } = props;
        // 根据是否关联了stakeholder来判断显示哪一个
        // Edit stakeholder跳转到之前的Edit stakeholder编辑页面
        // Edit Wallet 打开弹窗 第一期只可以编辑name
        return (
            <Menu>
                {stakeholder_id > 0 && (
                    <Menu.Item onClick={editStakeholderOrWallet.bind(this,value,"editStakeholder",cost_list)}><a data-toggle="modal" data-target="#editStakeholderModal" >{__('Edit stakeholder')}</a></Menu.Item>
                )}
                {wallet_label_id > 0 && (
                    <Menu.Item onClick={editStakeholderOrWallet.bind(this,value,"editWallet")}><a data-toggle="modal" data-target="#EditStPropertiesModal">{__('Edit Wallet')}</a></Menu.Item>
                )}
            </Menu>
        )
    }

    let relationshipFilter = [];
    relationshipList.forEach( (v, k) => {
        relationshipFilter.push({text: v, value: v});
    })

    let columns = [
        {
            title: 'Wallet',
            dataIndex: 'wallet_address',
            align: 'left',
            width: 200,
            fixed: 'left',
            isChecked: true,
            sorter: true,
            sortName: 'wallet_address',
            render: (text, record) => {
                const noAction = !record.name
                return renderWalletAddress(text, cb, noAction)
            }
        },
        {
            title: 'Stakeholder',
            dataIndex: 'name',
            align: 'left',
            isChecked: true,
            sorter: true,
            sortName: 'stakeholder',
            render: (text, record) => {
                const { updateName } = props
                if (text) {
                    return text
                }
                return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}><a style={{ background: '#D3F9FF', borderRadius: '4px', color: '#139FC5', fontSize: '12px', marginTop: '5px', padding: '0 3px' }} onClick={updateName.bind(this, record)}>Update</a></div>
            }
        },
        // {
        //     title: 'Stakeholder Email',
        //     dataIndex: 'stakeholder_email',
        //     sorter: (a, b) => tableSort(a.stakeholder_email, b.stakeholder_email),
        //     align: 'left',
        //     width: 220,
        //     render: (text, record) => {
        //         const { createNewStakeholder, connectStakeholder } = props
        //         if (text?.indexOf('@') > -1) {
        //             return text
        //         }
        //         return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}><a style={{ background: '#D3F9FF', borderRadius: '4px', color: '#139FC5', fontSize: '12px', padding: '0 3px', alignItems: 'flex-start' }} onClick={connectStakeholder.bind(this, record)}>Connect to existing stakeholder</a><a style={{ background: '#D3F9FF', borderRadius: '4px', color: '#139FC5', fontSize: '12px', marginTop: '5px', padding: '0 3px' }} onClick={createNewStakeholder.bind(this, record)}>Create new stakeholder</a></div>
        //     }
        // },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            align: 'right',
            isChecked: true,
            sorter: true,
            sortName: 'quantity',
            render: text => <div className="nowrap">{ formatTokenNumber(text)}</div>
        },
        {
            title: 'Ownership',
            dataIndex: 'percentage',
            align: 'left',
            isChecked: true,
            sorter: true,
            sortName: 'ownership',
            render: text => formatPercent(text)
        },
        {
            title: 'Value',
            dataIndex: 'value',
            align: 'left',
            isChecked: true,
            sorter: true,
            sortName: 'value',
            onFilter: (value, record) => record.security.indexOf(value) === 0,
            render: (text) => {
                return formatNumber(text, 2)
            }
        },
        {
            title: 'Relationship',
            dataIndex: 'relationship',
            align: 'left',
            // filters: relationshipFilter,
            filterSearch: true,
            isChecked: true,
            sorter: true,
            sortName: 'relationship',
            onFilter: (value, record) => record.relationship.indexOf(value) === 0,
        },
        {
            title: 'Registered',
            dataIndex: 'is_init',
            align: 'left',
            isChecked: true,
            sorter: true,
            sortName: 'registered',
            // onFilter: (value, record) => record.security.indexOf(value) === 0,
            render: (text, value) => {
                const registerMap = {
                    0 : <Dot color="red">{__('Not yet')}</Dot>,
                    1: <Dot color="green" >{__('Yes')}</Dot>
                }
                return <div>{I(registerMap[value.is_init], '--')}</div>
            }
        },
        {
            title: '',
            dataIndex: 'id',
            align: 'left',
            width: '5%',
            fixed: 'right',
            isHide: true,
            render:(text, value, index) => dropdownInfo(text, value, index)
        }
    ];

    return columns;
}