import React from 'react';
import { FormItem } from 'react-antd-formutil';
import { DateRangeSelector } from 'components/DateSelector';
import { formatDate2Api, formatDateForDatePicker } from 'Utils/format/formatDate';

export default function FormsDateSelect(props) {
    const { name, label } = props
    return <FormItem
        name={name || 'date'}
        itemProps={{
            label: label || __('Date'),
            labelAlign: 'left'
        }}
        $formatter={(form_date) => (form_date && [formatDateForDatePicker(form_date.start_date), formatDateForDatePicker(form_date.end_date)])}
        $parser={(form_date) => (form_date ? { start_date: formatDate2Api(form_date[0]), end_date: formatDate2Api(form_date[1]) } : {})}
    >
        <DateRangeSelector />
    </FormItem>
}