import { observable, action } from 'mobx';
import moment from 'moment';
import { message } from 'antd';
import axios from 'axios';
import { post } from 'srcPath/http'
import url from 'url';
import {setPermissions, authFunction, functionAuthConfig, getIntercom, resetPlatform} from './config';
import NoPermission from "components/NoPermission";

const token = url.parse(window.location.href, true).query.token;

class SecurityStore {
	/*
	{
		timezone_list: Array<{ client_text: string; server_value: string  }>
	}
	*/
	@observable
	constant = {} // { time }

	getConstantData = async () => {
		const { data: resData } = await post('getConfig')

		const { code, data } = resData || {}

		if (code === 0) {
			this.constant = data || {}
		}
	}

	@observable
	companyId = localStorage.getItem('COMPANY_ID')

	set setCompanyId(companyId) {
		this.companyId = companyId

		localStorage.setItem('COMPANY_ID', companyId)
	}

	get hasLogin() {
		return this.SPROUT_TOKEN
	}

	@observable // 'dev' | 'staging' | 'qa' | 'prod'
	env = process.env.REACT_APP_ENV || 'dev'

	@observable
	api = localStorage.getItem('SPROUT_API') || '/api/dev'

	@observable
	sessionExpired = false

	//task
	@observable
	task = {}
	
	set setTask(count) {
		this.task = count
	} 

	getTaskData() {
		return post('getTaskSummary').then((res) => {
			const { data: resData } = res || {};
			const { code, data } = resData

			if (code == 0) {
				this.task = data
			} else {
				message.error(data)
			}
		}).catch((err) => {
			message.error(err && err.message)
		})
	}


	// download files

	@observable
	fileList = []
	
	set setTask(list) {
		this.fileList = list
	} 

	getFileData() {
		return post('getDownloadList').then((res) => {
			const { data: resData } = res || {};
			const { code, data } = resData

			if (code === 0) {
				this.fileList = data
			} else {
				message.error(data)
			}
		}).catch((err) => {
			message.error(err && err.message)
		})
	}

	// id
	// user_name
	// email
	@observable
	userInfo = {}

	set setUserInfo(value) {
		this.userInfo = value || {}
	}

	@observable
	companyInfo = {}

    @observable
    rbacFunction = []

    @observable
    isArrears = 0

	@observable
	parentId = 0

    @observable
    planControl = 1

    @observable
    eSign = 0

    @observable
    subscriptionId = ''

    @observable
    flag = 0

    @observable
    setRbacFunction

	set setCompanyInfo(val) {
		let { company_id, flag, board_flag, data_room_flag = 0, arrears, parent_id: parentId, function: rbacFunction, plan_control, e_sign, subscription_id } = val || {};
        //company 各模块访问权限
		if (rbacFunction) { 
			this.rbacFunction = rbacFunction
		}

		if (company_id || company_id == 0) { 
			this.setCompanyId = company_id
		}
		//欠费标识
		this.isArrears = arrears;
		//子母公司标识
		this.parentId = parentId;
		this.flag = flag;
		this.planControl = plan_control;
        this.eSign = e_sign;
        this.subscriptionId = subscription_id;
		const finishOnboarding = val?.is_finished_onboarding && val?.is_finished_onboarding === '1';
		
		this.permission = setPermissions(flag, board_flag, data_room_flag, arrears, plan_control, finishOnboarding, parentId)
		this.setRbacFunction = authFunction(flag, arrears, rbacFunction, plan_control);
		this.companyInfo = val;
	}

    @observable
    functionAuthConfig = functionAuthConfig

    @action
    hasFunction = (routeList) => {
	    routeList.forEach((v, k)=>{
            !!this.setRbacFunction && this.setRbacFunction.indexOf(v.path) !== -1 && (routeList[k].component = NoPermission);
        })
        return routeList;
    };

    @action
    hasFunctionSingle = path => !!this.setRbacFunction && this.setRbacFunction.indexOf(path) !== -1;

    @observable
    authFunction = {}

	@observable
	clientInfo = {}

	@observable
	permission = {}

	@observable
	insertIntercom = false


	@action
	hasPermission = (key) => {
		return !!this.permission[key]
	}
	// {
	// 	admin_id: "6"
	// 	company_id: "4"
	// 	company_name: "Sun"
	// 	flag: "1"
	// 	is_board: "1"
	// 	is_finished_onboarding: "0"
	// 	nick_name: "Tony"	
	// }

	@observable
	corporations = []

	@observable
	defaultAdmin = []

	set setDefaultAdmin(data) {
		this.defaultAdmin = data || []
	}

	@observable
	defaultStakeholder = []

	set setDefaultStakeholder(data) {
		this.defaultStakeholder = data || []
	}
	// {
	//	 company_id: "8"
	//   flag: "0"
	//   nick_name: "Tony"
	//   stakeholder_id: "39"
	// }
	@observable
	portfolios = []

	set setPlatform(type) {
		this.prePlatform = this.platform

		this.platform = type;
		localStorage.setItem('SPROUT_PLATFORM', type)
		localStorage.setItem('SPROUT_PRE_PLATFORM', this.prePlatform || type )
	}
	
	@observable // B / C / TASK
	platform =  localStorage.getItem('SPROUT_PLATFORM') === 'null' ? undefined : localStorage.getItem('SPROUT_PLATFORM')

	@observable // B / C / TASK
	prePlatform = localStorage.getItem('SPROUT_PRE_PLATFORM')


	@observable
	SPROUT_TOKEN = localStorage.getItem('SPROUT_TOKEN')

	set setSproutToken(token) {
		this.SPROUT_TOKEN = token;
		localStorage.setItem('SPROUT_TOKEN', token)
	}

	@observable
	isProfile = 0

	@observable
	ACCESS_TOKEN = localStorage.getItem('ACCESS_TOKEN')

	@action
	clear = () => {
		localStorage.removeItem('SPROUT_TOKEN')
		localStorage.removeItem('SPROUT_EXPIRE')
		localStorage.removeItem('SPROUT_USER')
		localStorage.removeItem('AdmintoolUserToken')
		localStorage.removeItem('AdmintoolUserRole')
		this.userInfo = {};
		this.companyInfo = {};
		this.defaultAdmin = [];
		this.defaultStakeholder = [];
		this.SPROUT_TOKEN = null;
		this.task = null
		security.insertIntercom = false

        if (window.Intercom) {
			window.Intercom('shutdown');
        }
	}

	getStatus = () => {
		if(token) {
			this.setPlatform = ''
			// localStorage.clear();
			this.clear()

			localStorage.setItem('SPROUT_EXPIRE', moment().add(5, 'h').valueOf())

			this.SPROUT_TOKEN = token
		}

		if(!this.SPROUT_TOKEN || this.SPROUT_TOKEN === 'null') {
			this.SPROUT_TOKEN = null
			return
		}

		return axios.post('/checkToken.php', {
	        token: this.SPROUT_TOKEN,
	        action:'checkToken',
	        data:{}
		}).then( async res => {
			const { data } = res
			const { data: userData } = data
			const { userInfo, portfolios, corporations, defaultAdmin = [], defaultStakeholder = [], flag } = userData || {}

			if (data.code !== 0) {
				this.clear()
				message.error(__('Your session has expired. Please login again.'))

				this.SPROUT_TOKEN = null
				return
			}

			localStorage.setItem('SPROUT_TOKEN', this.SPROUT_TOKEN)

			const companyId = this.companyId

			this.corporations = corporations || []
			this.portfolios = portfolios || []

			this.defaultAdmin = defaultAdmin
			this.defaultStakeholder = defaultStakeholder

			this.setPlatform = resetPlatform(this.platform, corporations, portfolios)

			const getInfoPlatform = this.platform === 'TASK' ? (this.prePlatform || 'B') : this.platform

			const companyList = getInfoPlatform === 'B' ? corporations : portfolios 

			this.setCompanyInfo = companyList?.find(val => val.company_id == companyId) || companyList?.[0] || {}

			this.setUserInfo = userInfo
			
			this.isProfile = flag
			// intercom
			// if (!this.insertIntercom) {
			// 	const init = () => {
			// 		window.Intercom('boot', {
			// 		   app_id: 'enzreqg8',
			// 		   email: this.userInfo.email,
			// 		   name: this.userInfo.user_name,
			// 		   created_at: + new Date(),
			// 		   alignment: 'right',
			// 		   horizontal_padding: 55,
			// 		   vertical_padding: 0
			// 		})
			// 		this.insertIntercom = true
			// 	}

			// 	if (window.Intercom) {
			// 		init()
			// 	} else {
			// 		getIntercom(init)
			// 	}
			// }
		}).catch(error => {
			message.error(error && error.message)
		})
	}

	sendEvent = (data) => {
		const { flag, board_flag } = this.companyInfo || {}
		const { email } = this.userInfo || {}

		return post('sendEvent',  Object.assign({
			event_name: 'switchToCompany',
			flag,
			board_flag,
			email
		}, data))
	}

	init = async () => {
		await this.getStatus();
		this.getTaskData();
		this.getFileData();
		this.getConstantData()
	}

	loadFileLoop = () => {
		setTimeout( async () => { 
			try {
				await this.getFileData()
			} catch (e) { 

			}
			this.loadFileLoop()
		}, 20000)
	}
}

const security = new SecurityStore()

export default security