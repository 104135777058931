import React from 'react';
import Modal, { ModalContent } from 'components/Modal';
import { LeftOutlined } from '@ant-design/icons';
import ImageGallery from 'components/ImageGallery';
import basic1 from '../images/basic-1.png';
import basic2 from '../images/basic-2.png';

import detailed1 from '../images/detailed-1.png';
import detailed2 from '../images/detailed-2.png';
import detailed3 from '../images/detailed-3.png';
import detailed4 from '../images/detailed-4.png';
import detailed5 from '../images/detailed-5.png';

import summary1 from '../images/summary-1.png';
import summary2 from '../images/summary-2.png';
import summary3 from '../images/summary-3.png';
import summary4 from '../images/summary-4.png';
import summary5 from '../images/summary-5.png';

import intermediate1 from '../images/intermediate-1.png';
import intermediate2 from '../images/intermediate-2.png';
import intermediate3 from '../images/intermediate-3.png';
import intermediate4 from '../images/intermediate-4.png';
import intermediate5 from '../images/intermediate-5.png';

import individual1 from '../images/individual-1.png';
import individual2 from '../images/individual-2.png';

import './style.scss';

const imagesMap = {
    1: [basic1, basic2],
    2: [individual1, individual2],
    3: [summary1, summary2, summary3, summary4, summary5],
    4: [intermediate1, intermediate2, intermediate3, intermediate4, intermediate5],
    5: [detailed1, detailed2, detailed3, detailed4, detailed5 ]
}

export default function PreviewContent({ data, close }) {
    const { value } = data || {} 
    return <div className="access-level-preview">
        {
            value > 0 && <ImageGallery
                items={imagesMap[value]}
                showFullscreenButton={true}
                showPlayButton={false}
                showIndex={true}
                infinite={true}
                showBullets={true}
            />
        }
        <div className="preview-button" onClick={close}><LeftOutlined />{__('Go Back')}</div>
    </div>
}

// data: { name: '0' |  '1' | '2' | '3' | '4' | '5'; value: string }
export function openPreviewModal(data) {
    return Modal.open({
        component: props => <ModalContent close={props.close} title={data.name}><PreviewContent close={props.close} data={data} /></ModalContent>,
        className: 'sprout-modal',
        width: 1100
    })
}