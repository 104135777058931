import React, { Component} from "react";
import {Button, Dropdown, Menu, message, Tooltip} from "antd";
import './style.scss';
import { DownOutlined } from '@ant-design/icons';
import MergeWallet from '../../StakeholdersModal/MergeWallet'

class MergeStakeholdersModels extends Component {

    constructor(props) {
        super(props);
        this.state = {
            helpStr : 'You can only merge two stakeholders at a time.',
        }
    }

    /**
     * Merge Stakeholders Information 弹层
     */
    mergeStakeholders = () => {
        const { holderLists, clearSelect } = this.props;
        MergeWallet(holderLists, clearSelect)
    }

    /**
     * actions 菜单
     * @returns {JSX.Element}
     */
    actionsMenu = (selectCount) => {
        const { helpStr } = this.state;
        return (
            <Menu>
                {selectCount > 2 || selectCount < 2 ?
                <Menu.Item disabled onClick={this.mergeStakeholders}>
                    <Tooltip title={helpStr} placement="right" color="#2b3946">Merge Wallets</Tooltip>
                </Menu.Item> :
                <Menu.Item onClick={this.mergeStakeholders}>Merge Wallets</Menu.Item>}
            </Menu>
        )
    }

    render() {
        const { selectCount } = this.props;
        return (
            <div className="action-box onset">
                <div className="btn-group">
                    <Dropdown className="shares-actions" overlay={this.actionsMenu.bind(this, selectCount)}>
                        <Button
                            type="primary"
                            className="merge-stakeholder-actions"
                        ><div className="actions-left">Actions</div><DownOutlined className="actions-right" /></Button>
                    </Dropdown>
                </div>
            </div>
        );
    }
}
export default MergeStakeholdersModels;