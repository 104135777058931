import React, { Component, useState, useEffect }from 'react';
import { message, Table, Spin, Descriptions, Col, Empty, Radio, Progress } from 'antd';
import { post } from 'srcPath/http';
import security from 'stores/security';
import echarts from 'echarts';
import { formatDate } from 'Utils/format/formatDate';
import { formatNumber, formatCurrencyNumber, formatPercent, isMobile } from 'Utils'
import classnames from 'classnames';
import acceptIcon from './images/accept_icon.png'
import signIcon from './images/sign_icon.png'
import nodataIcon from './images/no-data.png'
import TokenTransactionTable from 'components/TokenTransaction';
import { foundColumns } from './components/form-config'
import TokenSelector, { getDefaultTokenId } from 'B/components/TokenSelector';
import { cloneDeep } from 'lodash';
import EmptyTips from '../EmptyTips'

import './style.scss'

const colorMap = {
  0: '#6BC7A3',
  1: '#6DC1CB',
  2: '#73B1D8',
  3: '#599FCC',
  4: '#548FBC',
}


export default function Dashboard (props) {
    const [loading, setLoading ] = useState(false)
    const [loadingAsset, setLoadingAsset] = useState(true)
    const [hasAsset, setHasAsset] = useState(false)
    const [timeUnit, setTimeUnit] = useState('all')
    const [showTab, setShowTab] = useState('equity')
    const [historyData, setHistoryData] = useState([])
    const [publicToken, setPublicToken] = useState([])
    const [portfolioValue, setPortfolioValue] = useState({})
    const [coinId, setCoinId ] = useState(getDefaultTokenId() ?? 45)
    const [portfolioChart, setPortfolioChart] = useState([])
    const customCoinList = (security.companyInfo && security.companyInfo.custom_coin_list) || []
    const hasToken = (security.companyInfo && security.companyInfo.is_show_coin) === '1'

    useEffect(() => {
      getAssets()
    }, [])

    useEffect(() => {
      if (showTab && security?.companyInfo?.stakeholder_id && hasAsset) {
        getPortfolioView()
        if (showTab === 'equity') {
          fetchHistoryByDate(timeUnit)
          }
          if (showTab === 'token' && coinId && timeUnit) {
          fetchHistoryByDate(timeUnit)
          }
      }
    }, [showTab, coinId, security?.companyInfo?.stakeholder_id, hasAsset])

useEffect(() => {
  if (historyData?.length > 0 && hasAsset) {
    initHistoryCharts()
  }
}, [historyData, hasAsset])


   

   useEffect(() => {
    if (portfolioValue && !loadingAsset) {
      initCharts()
      getPublicToken()
    }
}, [portfolioValue, loadingAsset])

const getAssets = async () => {
  try {
    const { data: resData } = await post('getPortfolioViewStatus', {
      stakeholder_id: security.companyInfo && security.companyInfo.stakeholder_id
    })

    setLoadingAsset(false)
    const { code, data } = resData || {}

    if (code === 0) {
      setHasAsset(+data?.status === 1)
    }
  } catch (err) {
    message.error(err.message)
  }
}

const getPublicToken = async () => {
  try {
    setLoading(true)
    const { data: resData } = await post('getPublicToken', {})
    setPublicToken(resData?.data ?? [])
  } catch(err) {
      message.error(err & err.message)
  }
  setLoading(false)
}

const initHistoryCharts = () => {
  const lineChart = echarts.init(document.getElementById('portfolio-chart-1'))
  lineChart ? lineChart?.setOption(lineOption) : ''
  window.addEventListener("resize", function () {
    lineChart.resize();
  });
}


const getPortfolioView = async () => {
        const { data = {} } = props
        const { id } = data || {}
        const url = showTab === 'equity' ? 'getPortfolioDashboardEquity' : 'getPortfolioDashboardToken'
        const params = { stakeholder_id: security?.companyInfo?.stakeholder_id ?? 0 } // security?.companyInfo?.stakeholder_id ?? 0
        if (showTab === 'token') {
          params.coin_id = coinId
        }
        setLoading(true)
            try {
                const { data: resData } = await post(url, params)
                const { code, data } = resData || {}
                if (showTab === 'token') {
                  data.total_overview = data.total_quantity
                  data.vesting = {
                    ...data.total_vesting,
                    total_quantity: data.total_vesting?.total_quantity?.quantity,
                  }
                }
                if (code === 0) {
                    setPortfolioValue(data)
                }
            } catch(err) {
                message.error(err & err.message)
            }
            setLoading(false)
    }

      // const { your_update: { create_account = {}, equity_info = [] } = {}, portfolio_value: { equity_info: equityInfoPortFolio = {}, token_info: tokenInfoPortFolio = {} } = {}, portfolio_breakdown = {}, historical_value = {} } = portfolioValue || {} 
      const { break_down, convertibles, options = [], shares = [], vesting, warrant, total_overview, total_quantity, historical_value, your_update, equityInfoPortFolio = {}, tokenInfoPortFolio = {}, create_account, my_wallet_transaction, stakeHolder_wallet, phantom_options = {}, transactions, moon_sheet } = portfolioValue || {} 
      const moonSheetList = []
      // 这里需要替换

      const xDataList = Array.isArray(moon_sheet?.token_time) ? moon_sheet?.token_time?.map(i => formatDate(i * 1000)) : (Array.isArray(moon_sheet?.current_token_price_100?.value) ? moon_sheet?.current_token_price_100?.value?.map(i => '') : [])
      const coinName = customCoinList.find(m => m.id === coinId)?.name
      for (let key in moon_sheet) {
        let name = `$${formatNumber(moon_sheet?.[key]?.price)}`
        if (key === 'current_token_price_100') {
          name = `${name}(${coinName})`
        }
        if (key !== 'token_time') {
          moonSheetList.push({
            name: name,
            type: 'line',
            stack: 'Total',
            data: moon_sheet?.[key]?.value,
          })
        }
        
      }
      // 非空数据集
      const isMoonSheetNotEmpty = (moonSheetList.filter(i => i?.data?.length > 0 && !!i?.data))?.length > 0
      const { taskDetail = {} } = security.task || {}


      const timeData = historyData?.map(i => formatDate(i.time * 1000))
      const equityValueData = historyData?.map(i => i.price)

      const shareXData = shares?.list?.map(i => i?.custom_label_id)
      const shareYData1 = shares?.list?.map(i => i?.vested)
      const shareYData2 = shares?.list?.map(i => i?.un_vested)
      const optionXData = options?.list?.map(i => i?.custom_label_id)
      const optionYData1 = options?.list?.map(i => i?.vested)
      const optionYData2 = options?.list?.map(i => i?.un_vested)

      const shareDataExist = shareYData1?.length + shareYData2?.length > 0
      const optionDataExist = optionYData1?.length + optionYData2?.length > 0


      const chart1Data = [{name: __('Convertibles'), percentage: total_overview?.convertibles?.percentage, value: total_overview?.convertibles?.quantity}, { name: __('Shares'), percentage: total_overview?.shares?.percentage, value: total_overview?.shares?.quantity }, { name: __('Unavailable'), percentage: total_overview?.unavailable?.percentage, value: total_overview?.unavailable?.quantity }, { name: __('Available'), percentage: total_overview?.available?.percentage, value: total_overview?.available?.quantity }]
      const chart1DataToken = [{name: __('Circulation'), percentage: total_overview?.Circulation?.percentage, value: total_overview?.Circulation?.quantity}, { name: __('Fully diluted'), percentage: total_overview?.fully_diluted?.percentage, value: total_overview?.fully_diluted?.quantity }]
      
      const chart2Data = [{ name: __('Exercised'), value: vesting?.exercise?.percentage, quantity: vesting?.exercise?.quantity}, { name: __('Vested'), percentage: vesting?.vested?.percentage, value: vesting?.vested?.quantity }, { name: __('Unvested'), percentage: vesting?.un_vested?.percentage, value: vesting?.un_vested?.quantity }]
      const chart2DataToken = [{ name: __('Vesting'), value: vesting?.vesting?.percentage, quantity: vesting?.vesting?.quantity}, { name: __('Warrants'), percentage: vesting?.warrants?.percentage, value: vesting?.warrants?.quantity }]

      const displayData1 = showTab === 'equity' ? chart1Data : chart1DataToken
      const displayData2 = showTab === 'equity' ? chart2Data : chart2DataToken

      const existDisplayData1Value = displayData1.find(i => +i.value > 0)

      const displayData1Copy = cloneDeep(displayData1)
      if (existDisplayData1Value) {
        displayData1Copy.map((i) => {
          if (+i.value === 0) {
            i.value = '--'
          }
        })
      }
      
      const existDisplayData2Value = displayData2.find(i => +i.value > 0)
      const displayData2aCopy = cloneDeep(displayData2) // existDisplayData2Value ? displayData2.filter(i => +i?.value > 0) : displayData2
      if (existDisplayData2Value) {
        displayData2aCopy.map((i) => {
          if (+i.value === 0) {
            i.value = '--'
          }
        })
      }
      


      const chartOptions = {
        tooltip: {
          trigger: 'item'
        },
        color: !!existDisplayData1Value ? ['#6BC7A3', '#6DC1CB', '#73B1D8', '#599FCC', '#548FBC'] : ['#CCCCCC', '#CCCCCC', '#CCCCCC', '#CCCCCC', '#CCCCCC'],
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '60%'],
            avoidLabelOverlap: true,
            data: displayData1Copy,
          }
        ]
      }

      const moonOptions = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: moonSheetList?.map(i => i?.name),
        },
        grid: {
          left: '3%',
          right: '8%',
          bottom: '3%',
          containLabel: true
        },
        color: ['#07C1D7', '#0FE6FF', '#67F075', '#92FE9D', '#E9D600', '#FFEB0B', '#007EDF', '#0F97FF', '#F690F3', '#FFB8FD'],
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xDataList, // moonSheetList里的单项数组同等长度的空数组
        },
        yAxis: {
          type: 'value'
        },
        series: moonSheetList,
      }
      
      const vestingOptions = {
        tooltip: {
          trigger: 'item'
        },
        label: {
          show: true,
          position: 'center'
        },
        color: !!existDisplayData2Value ? ['#6BC7A3', '#6DC1CB', '#73B1D8'] : ['#CCCCCC', '#CCCCCC', '#CCCCCC'],
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['35%', '50%'],
            avoidLabelOverlap: true,
            data: displayData2aCopy, // , { name: 'Un_vested', value: vesting?.un_vested?.quantity }
            label: {
              formatter: (value) => {
                return `${value?.data?.name}: ${formatNumber(+value?.data?.value)}`
              }
            },
          }
        ]
      }
      
      const convertibleData = [{ name: __('Convertibles'), value: convertibles?.total_quantity ?? 0 }]
      const existConvertiblesValue = +convertibles?.total_quantity > 0
      const convertibleDataCopy = existConvertiblesValue ? convertibleData.filter(i => +i?.value > 0) : convertibleData
      if (existConvertiblesValue) {
        convertibleDataCopy.map((i) => {
          if (+i.value === 0) {
            i.value = '--'
          }
        })
      }
      
      const convertOptions = {
        tooltip: {
          trigger: 'item',
          label: {
            show: true,
            position: 'center',
            formatter: (value) => {
              return value?.data?.quantity
            }
          },
        },
        // label: {
        //   show: true,
        // },
        color: +convertibles?.total_quantity === 0 ? ['#cccccc'] :  ['#0DCEF6'],
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '60%'],
            avoidLabelOverlap: true,
            data: convertibleDataCopy,
            label: {
              formatter: (value) => {
                return value?.data?.quantity
              }
            },
          }
        ]
      }


      const isEmptyWarrant = +warrant?.exercise?.quantity + +warrant?.vested?.quantity + +warrant?.un_vested?.quantity === 0

      const warrantData = [{ name: __('Exercised'), value: warrant?.exercise?.quantity }, { name: __('Vested'), value: warrant?.vested?.quantity }, { name: __('Unvested'), value: warrant?.un_vested?.quantity }]
      const existWarrantValue = warrantData.find(i => +i.value > 0)
      const warrantDataCopy = existWarrantValue ? warrantData.filter(i => +i?.value > 0) : warrantData
      if (existWarrantValue) {
        warrantDataCopy.map((i) => {
          if (+i.value === 0) {
            i.value = '--'
          }
        })
      }
      const warrantOptions = {
        tooltip: {
          trigger: 'item'
        },
        label: {
          show: true,
          position: 'center'
        },
        color: isEmptyWarrant ? ['#cccccc', '#cccccc', '#cccccc'] : ['#6BC7A3', '#6DC1CB', '#73B1D8'],
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '60%'],
            avoidLabelOverlap: true,
            data: warrantDataCopy
          }
        ]
      }
      const isPhantomEmpty = +phantom_options?.esop?.quantity + +phantom_options?.rsa?.quantity + +phantom_options?.rsu?.quantity + +phantom_options?.sar?.quantity === 0

      const phantomOriginData = [{ name: __('ESOP'), value: phantom_options?.esop?.quantity }, { name: __('RSA'), value: phantom_options?.rsa?.quantity }, { name: __('RSU'), value: phantom_options?.rsu?.quantity }, { name: __('SAR'), value: phantom_options?.sar?.quantity }]
      const existValue = phantomOriginData.find(i => +i.value > 0)
      const phantomOriginDataCopy = existValue ? phantomOriginData.filter(i => +i?.value > 0) : phantomOriginData
      phantomOriginDataCopy.map((i) => {
        if (+i.value === 0) {
          i.value = '--'
        }
      })
      const phantomOptions = {
        tooltip: {
          trigger: 'item'
        },
        label: {
          show: true,
          position: 'center'
        },
        color: isPhantomEmpty ? ['#cccccc', '#cccccc', '#cccccc', '#cccccc'] : ['#6BC7A3', '#6DC1CB', '#73B1D8', '#599FCC', '#548FBC'],
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '60%'],
            avoidLabelOverlap: true,
            data: phantomOriginDataCopy
          }
        ]
      }


      const lineOption = {
        title: {},
        tooltip: {
          trigger: 'axis',
          // axisPointer: {
          //   type: 'cross',
          //   label: {
          //     backgroundColor: '#0F97FF'
          //   }
          // }
        },
        // color: ['#83D0BB'],  color: ['#0FE6FF', '#92FE9D', '#FFEB0B', '#0F97FF', '#f28513'],
        // legend: {
        //   data: ['Equity']
        // },
        color: ['#0F97FF'],
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: timeData,
            axisLine: {
              show: false
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: false
            }
          }
        ],
        series: [
          {
            radius: '50%',
            name: __('Equity'),
            type: 'line',
            smooth: true,
            stack: 'Total',
            data: equityValueData
          },
        ]
      };
      const emphasisStyle = {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: 'rgba(0,0,0,0.3)'
        }
      };
      const shareOption = {
        xAxis: {
          type: 'category',
          data: shareXData
        },
        yAxis: {
          type: 'value'
        },
        tooltip: {},
        grid: {
          left: '5%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        series: [
          {
            name: __('Vested'),
            radius: '50%',
            data: shareYData1,
            type: 'bar',
            stack: 'one',
            barWidth: 30,
            emphasis: emphasisStyle,
            itemStyle: {
              normal: {
                color: (param) => {
                  const colorList = ['#6BC7A3', '#6DC1CB', '#73B1D8', '#599FCC', '#548FBC']
                  return colorList[param?.dataIndex % 5]
                }
              }
            },
          },
          {
            name: __('Unvested'),
            radius: '50%',
            data: shareYData2,
            type: 'bar',
            stack: 'one',
            barWidth: 30,
            emphasis: emphasisStyle,
            itemStyle: {
              normal: {
                color: (param) => {
                  const colorList = ['#89D8B9', '#82D4DE', '#89C3E7', '#65B1E1', '#548FBC']
                  return colorList[param?.dataIndex % 5]
                }
              }
            },
          }
        ]
      }

      const optionOption = {
        xAxis: {
          type: 'category',
          data: optionXData
        },
        yAxis: {
          type: 'value'
        },
        tooltip: {},
        grid: {
          left: '5%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        series: [
          {
            radius: '50%',
            name: __('Vested'),
            data: optionYData1,
            type: 'bar',
            stack: 'one',
            barWidth: 30,
            emphasis: emphasisStyle,
            itemStyle: {
              normal: {
                color: (param) => {
                  const colorList = ['#6BC7A3', '#6DC1CB', '#73B1D8', '#599FCC', '#548FBC'] // ['#07C1D7', '#0FE6FF', '#67F075', '#92FE9D', '#E9D600', '#FFEB0B', '#007EDF', '#0F97FF', '#F690F3', '#FFB8FD']
                  return colorList[param?.dataIndex % 5]
                }
              }
            },
          },
          {
            name: __('Unvested'),
            radius: '50%',
            data: optionYData2,
            type: 'bar',
            stack: 'one',
            barWidth: 30,
            emphasis: emphasisStyle,
            itemStyle: {
              normal: {
                color: (param) => {
                  const colorList = ['#89D8B9', '#82D4DE', '#89C3E7', '#65B1E1', '#548FBC'] // ['#07C1D7', '#0FE6FF', '#67F075', '#92FE9D', '#E9D600', '#FFEB0B', '#007EDF', '#0F97FF', '#F690F3', '#FFB8FD']
                  return colorList[param?.dataIndex % 5]
                }
              }
            },
          }
        ]
      }

      const equityBreakdown = break_down || {}
      delete equityBreakdown.total_quantity
      const equityBreakdownList = []
      let totalEquityValue = 0
      for (let key in equityBreakdown) {
        equityBreakdownList.push({
            name: key,
            value: equityBreakdown[key],
        })
        totalEquityValue += (equityBreakdown[key]?.price ?? 0)
      }
    

      const formatName = (name) => {
        const newName = [...name]
        newName[0] = newName[0]?.toUpperCase()
        const finalName = newName.join('')
        if (finalName === 'Option') {
          return __('Options')
        } else if (finalName === 'Warrant') {
          return __('Warrants')
        }
        return finalName
      }

      const handleChanges = (e) => {
        setShowTab(e?.target?.value)
        setTimeUnit('all')
      }

      const initCharts = () => {
        const isEquity = showTab === 'equity'
        const myChart = !!existDisplayData1Value ? echarts.init(document.getElementById('portfolio-chart-0')) : ''
        !!existDisplayData1Value && myChart && myChart?.setOption(chartOptions)

        const moonNode = document.getElementById('portfolio-chart-moon')
        const moonChart = moonSheetList?.length > 0 && moonNode ? echarts.init(moonNode) : ''
        moonSheetList?.length > 0 && moonChart && moonNode && moonChart?.setOption(moonOptions)
        

        const vestingChart = !!existDisplayData2Value ? (isEquity ? echarts.init(document.getElementById('portfolio-chart-2')) : echarts.init(document.getElementById('portfolio-chart-vesting'))) : ''
        !!existDisplayData2Value && vestingChart && vestingChart?.setOption(vestingOptions)


        if (isEquity) {
          const convertChart = !!convertibles?.total_quantity ? echarts.init(document.getElementById('portfolio-chart-4')) : ''
          !!convertibles?.total_quantity && convertChart && convertChart.setOption(convertOptions)
          // share chart
          const shareChart = shareDataExist ? echarts.init(document.getElementById('portfolio-chart-share')) : ''
          isEquity && shareDataExist && shareChart ? shareChart?.setOption(shareOption) : ''

          // option chart
          const optionChart = optionDataExist ? echarts.init(document.getElementById('portfolio-chart-option')) : ''
          isEquity && optionDataExist && optionChart ? optionChart?.setOption(optionOption) : ''
          
          const warrantChart = isEquity && existWarrantValue ? echarts.init(document.getElementById('portfolio-chart-5')) : ''
          isEquity && existWarrantValue && warrantChart ? warrantChart.setOption(warrantOptions) : ''

          // Share option awards
          const domNode6 = document.getElementById('portfolio-chart-6')
          const phantomOptionChart = isEquity && !isPhantomEmpty && domNode6 ? echarts.init(domNode6) : ''
          isEquity && !isPhantomEmpty && phantomOptionChart ? phantomOptionChart.setOption(phantomOptions) : ''
          window.addEventListener("resize", function () {
            if (isEquity) {
              existWarrantValue && warrantChart.resize();
              !isPhantomEmpty && phantomOptionChart && phantomOptionChart.resize();
              shareDataExist && shareChart.resize();
              !!convertibles?.total_quantity && convertChart.resize();
            }
          });
        }
        window.addEventListener("resize", function () {
          !!existDisplayData2Value && vestingChart.resize();
          !!existDisplayData1Value && myChart?.resize();
        });
        
        
      }

      const changeToTask = path => {
        security.setPlatform ='TASK'
        if(path) {
          props.history.push(path)
        }
      }

      const fetchHistoryByDate = async (timeunit) => {
        try {
          setLoading(true)
          const params = {
            timenit: timeunit,
            stakeholder_id: security?.companyInfo?.stakeholder_id ?? 0,
          }
          if (showTab === 'token') {
            params.token_id = coinId
          }
          const { data: resData } = await post('getHistoryByDate', params)
          setHistoryData(resData?.data ?? [])
        } catch(err) {
            message.error(err & err.message)
        }
        setLoading(false)
      }

      const handleClickZoom = (timeunit) => {
        setTimeUnit(timeunit)
        fetchHistoryByDate(timeunit)
      }

      const renderEmptyData = (style) => (
        <div className="dash-board-item-content-chart-1">
            <div className="dash-board-item-chart portfolio-chart-item no-data" style={style}>
              <img src={nodataIcon} />
              <span>{__('No data to view')}</span>
            </div>
        </div>
      )

    if (loadingAsset) {
      return <Spin style={{ width: '100%', margin: '0 auto' }} spinning={loadingAsset}></Spin>
    }
    if (!hasAsset && !loadingAsset) {
      return <EmptyTips />
    }

    return (
        <div>
            <Spin spinning={loading}>
                  <div className={classnames('dashboard-container-root', { 'dashboard-container-root-mobile': isMobile() })}>
                  <div className="dashboard-container-top-container">
                    <div className="dashboard-container-radio">
                        <Radio.Group defaultValue="a" onChange={(e) => handleChanges(e)}>
                          <Radio.Button value="equity" className={classnames({ 'ant-radio-button-wrapper-active': showTab === 'equity' })}>{__('Equity')}</Radio.Button>
                          {hasToken && <Radio.Button value="token" className={classnames({ 'ant-radio-button-wrapper-active': showTab === 'token' })}>{__('Token')}</Radio.Button>}
                        </Radio.Group>
                      </div>
                    {showTab === 'token' && (
                      <div className="transaction-crypto-banner sprout-flex" style={{ marginBottom: '20px' }}>
                        <div className="sprout-flex">
                          <TokenSelector value={coinId} onChange={ val => setCoinId(val)} />
                        </div>
                      </div>
                    )}
                  </div>
                  {showTab === 'token' && (
                    <div className="dash-board-top-token-price">
                      <p>{__("Today's Tokens Prices by Market Cap")}</p>
                      <div className="dash-board-top-token-container">
                        {publicToken?.map((item, index) => <div className="dash-board-top-token-item">
                          <span className="dash-board-top-token-item-sort">{index + 1}</span><span className="dash-board-top-token-item-name"><img src={item?.image} />{item?.name}<span className="no">{item?.abbr}</span></span><span className="dash-board-top-token-item-change">{`${item?.percentage}%`}</span>
                        </div>)}
                      </div>
                    </div>
                  )}
                  <div className="dash-board-top">
                    <div className="dash-board-item dash-board-top-1">
                      <div className="dash-board-item-title">{__('Portfolio Breakdown by Asset type')}</div>
                      <div className="both-detail">
                          <p className="dash-board-item-sub-title"><span>{showTab === 'equity' ? __('Equity') : __('Token')}</span><span className="value bold">{formatCurrencyNumber(totalEquityValue ?? 0, 2)}</span></p>
                          {break_down && (
                              <div className={classnames('equity-area display-area', { 'border-line': break_down })}>
                                  <div className="detail-item">
                                    {equityBreakdownList.map((item, index) => (
                                      <>
                                        <p><div><span className="item-cycle" style={{ borderColor: colorMap[index] }}></span>
                                        <span className="item">{formatName(item.name)}</span></div>
                                        <div className="value">
                                          <p>{formatCurrencyNumber(item.value?.price ?? 0, 2)}</p>
                                          <p>{__('Quantity:')} {formatNumber(+item.value?.quantity ?? 0)}</p>
                                          <p>{formatPercent(item.value?.percentage)}</p>
                                        </div>
                                        </p>
                                        <Progress showInfo={false} strokeWidth={item.value?.percentage > 0 ? 10 : 2} strokeColor={colorMap[index]} percent={(item.value?.percentage * 100).toFixed(2) ?? 0} />
                                      </>
                                    ))}
                                  </div>
                              </div>
                          )}
                      </div>
                    </div>
                    {isMobile() && (
                      <div className="dash-board-item dash-board-item-mobile">
                        <div className="dash-board-item dash-board-top-2">
                          <div className="dash-board-top-pie-chart dash-board-top-pie-chart-1">
                            <div className="dash-board-item-title">{__('Total Overview')}</div>
                            <div className="dash-board-item-content">
                            <div className="dash-board-item-sub-title"><span>{__('Total Quantity')}:</span> <span className="quantity">{formatNumber(+total_overview?.total_quantity?.quantity || +total_overview?.total_quantity || 0)}</span></div>
                                <div className="dash-board-item-content-chart">
                                    {!!existDisplayData1Value && (
                                      <>
                                        <div className="portfolio-chart-item" id="portfolio-chart-0"></div>
                                        {displayData1.length > 0 && (
                                          <div className="dash-board-item-content-chart-legend progress-list" className={classnames({ 'dash-board-item-content-chart-legend-token': showTab === 'token' })} style={{ padding: '10px 20px'}}>{displayData1.map((item, index) => (
                                            <div className="dash-board-item-content-chart-legend-item" >
                                              <p style={{ display: 'flex', justifyContent: 'space-between' }}>{item.name} <span>{formatPercent(item.percentage)}</span></p>
                                              <Progress showInfo={false} strokeColor={existDisplayData1Value && +item.percentage > 0 ? colorMap[index] : '#cccccc'} size="small" percent={100} />
                                            </div>
                                          ))}</div>
                                        )}
                                      </>
                                    )}
                                    {!existDisplayData1Value && (
                                      renderEmptyData()
                                    )}
                                </div>
                            </div>
                          </div>
                        </div>
                        <div className="dash-board-item dash-board-top-3">
                            <div className="dash-board-item-title">{__('Tasks')}</div>
                            <div className="task-list">
                                <div className="task-accept"><img src={acceptIcon} /> <a onClick={() => changeToTask("/task/certificate")}>{__('Accept')}</a><span>{taskDetail.acceptCertificates}</span></div>
                                <div className="task-sign"><img src={signIcon} /><a onClick={() => changeToTask("/task/sign")}>{__('Approve')}</a> <span>{taskDetail.signCertificates}</span></div>
                                <div className="task-view"><a onClick={() => changeToTask("/task/certificate")}>{__('View all tasks')}</a></div>
                            </div>
                        </div>
                      </div>
                    )}
                    {!isMobile() && (
                      <>
                        <div className="dash-board-item dash-board-top-2">
                          <div className="dash-board-top-pie-chart dash-board-top-pie-chart-1">
                            <div className="dash-board-item-title">{__('Total Overview')}</div>
                            <div className="dash-board-item-sub-title"><span>{__('Total Quantity')}:</span> <span className="quantity">{formatNumber(+total_overview?.total_quantity?.quantity || +total_overview?.total_quantity || 0)}</span></div>
                            <div className="dash-board-item-content">
                                <div className="dash-board-item-content-chart">
                                    {!!existDisplayData1Value && (
                                      <>
                                        <div className="portfolio-chart-item" id="portfolio-chart-0"></div>
                                        {displayData1.length > 0 && (
                                          <div className="dash-board-item-content-chart-legend progress-list" className={classnames({ 'dash-board-item-content-chart-legend-token': showTab === 'token' })} style={{ padding: '10px 20px'}}>{displayData1.map((item, index) => (
                                            <div className="dash-board-item-content-chart-legend-item" >
                                              <p style={{ display: 'flex', justifyContent: 'space-between' }}>{item.name} <span>{formatPercent(item.percentage)}</span></p>
                                              <Progress showInfo={false} strokeColor={existDisplayData1Value && +item.percentage > 0 ? colorMap[index] : '#cccccc'} size="small" percent={100} />
                                            </div>
                                          ))}</div>
                                        )}
                                      </>
                                    )}
                                    {!existDisplayData1Value && (
                                      renderEmptyData({ margin: '60px auto' })
                                      // <div className="dash-board-item-content-chart-1">
                                      //     <div className="dash-board-item-chart portfolio-chart-item no-data" style={{ margin: '60px auto' }}>
                                      //       <img src={nodataIcon} />
                                      //       <span>{__('No data to view')}</span>
                                      //     </div>
                                      // </div>
                                    )}
                                </div>
                            </div>
                          </div>
                        </div>
                        <div className="dash-board-item dash-board-top-3">
                            <div className="dash-board-item-title">{__('Tasks')}</div>
                            <div className="task-list">
                                <div className="task-accept"><img src={acceptIcon} /> <a onClick={() => changeToTask("/task/certificate")}>{__('Accept')}</a><span>{taskDetail.acceptCertificates}</span></div>
                                <div className="task-sign"><img src={signIcon} /><a onClick={() => changeToTask("/task/sign")}>{__('Approve')}</a> <span>{taskDetail.signCertificates}</span></div>
                                <div className="task-view"><a onClick={() => changeToTask("/task/certificate")}>{__('View all tasks')}</a></div>
                            </div>
                        </div>
                      </>
                    )}
                  </div>
                  {showTab === 'token' && (
                    <div className="dash-board-sub-top dash-board-sub-top-token dash-board-middle dash-board-middle-token">
                    <div className="dash-board-item dash-board-sub-top-1">
                      <div className="dash-board-item-title">{__('Historical Value of Your Holdings')}</div>
                      <div className="zoom-container">
                      <div className="zoom-container-tip">{__('Zoom')}</div>
                          <div className={classnames('zoom-container-item',{ 'zoom-container-item-selected': timeUnit === 'month' })} onClick={() => handleClickZoom('month')}>{__('1m')}</div>
                          <div className={classnames('zoom-container-item',{ 'zoom-container-item-selected': timeUnit === 'halfYear' })} onClick={() => handleClickZoom('halfYear')}>{__('6m')}</div>
                          <div className={classnames('zoom-container-item',{ 'zoom-container-item-selected': timeUnit === 'year' })} onClick={() => handleClickZoom('year')}>{__('1y')}</div>
                          <div className={classnames('zoom-container-item',{ 'zoom-container-item-selected': timeUnit === 'all' })} onClick={() => handleClickZoom('all')}>{__('All')}</div>
                      </div>
                      {equityValueData?.length > 0 && (
                        <div className="dash-board-item-content-chart-1">
                            <div className="dash-board-item-chart portfolio-chart-item"  id="portfolio-chart-1" ></div>
                        </div>
                      )}
                      {equityValueData?.length === 0 && (
                        renderEmptyData()
                      )}
                    </div>
                    <div className="dash-board-item dash-board-sub-top-3">
                      <div className="dash-board-item-title">{__('Moonsheet')}</div>
                      
                      {isMoonSheetNotEmpty && (
                        <div className="dash-board-item-content-chart-1">
                            <div className="dash-board-item-chart portfolio-chart-item"  id="portfolio-chart-moon" ></div>
                        </div>
                      )}
                      {!isMoonSheetNotEmpty && (
                        renderEmptyData()
                      )}
                    </div>
                    <div className="dash-board-item dash-board-sub-top-2">
                        <div className="dash-board-item-title-box">
                          <div className="dash-board-item-sub-title"><span>{__('Total Quantity')}:</span> <span className="quantity">{formatNumber(+vesting?.total_quantity || 0)}</span></div>
                        </div>
                        
                        {!!existDisplayData2Value && (
                          <div className="dash-board-item-flex">
                            <div className="dash-board-item-content-box">
                              <p><span className="item">{__('Vesting')}</span><span className="value">{formatPercent((vesting?.vesting?.percentage))}</span></p>
                              <Progress percent={vesting?.vesting?.percentage * 100} showInfo={false} size="small" strokeColor={vesting?.vesting?.percentage > 0 ? colorMap[0] : '#ccc'} />
                              <p><span className="item">Warrants</span><span className="value">{formatPercent((vesting?.warrants?.percentage))}</span></p>
                              <Progress percent={vesting?.warrants?.percentage * 100} showInfo={false} size="small" strokeColor={vesting?.warrants?.percentage > 0 ? colorMap[1] : '#ccc'} />
                            </div>
                            <div className="dash-board-item-chart portfolio-chart-item"  id="portfolio-chart-vesting" ></div>
                          </div>
                        )}
                        {!existDisplayData2Value && (
                              renderEmptyData()
                            )}
                      </div>
                  </div>
                  )}
                  {showTab === 'equity' && (
                    <div className="dash-board-sub-top">
                      <div className="dash-board-item dash-board-sub-top-1">
                        <div className="dash-board-item-title">{__('Historical Value of Your Holdings')}</div>
                        <div className="zoom-container">
                          <div className="zoom-container-tip">{__('Zoom')}</div>
                          <div className={classnames('zoom-container-item',{ 'zoom-container-item-selected': timeUnit === 'month' })} onClick={() => handleClickZoom('month')}>{__('1m')}</div>
                          <div className={classnames('zoom-container-item',{ 'zoom-container-item-selected': timeUnit === 'halfYear' })} onClick={() => handleClickZoom('halfYear')}>{__('6m')}</div>
                          <div className={classnames('zoom-container-item',{ 'zoom-container-item-selected': timeUnit === 'year' })} onClick={() => handleClickZoom('year')}>{__('1y')}</div>
                          <div className={classnames('zoom-container-item',{ 'zoom-container-item-selected': timeUnit === 'all' })} onClick={() => handleClickZoom('all')}>{__('All')}</div>
                        </div>
                        {/* <div className="dash-board-item-content-chart-1">
                            <div className="dash-board-item-chart portfolio-chart-item"  id="portfolio-chart-1" ></div>
                        </div> */}
                        {equityValueData?.length > 0 && (
                        <div className="dash-board-item-content-chart-1">
                            <div className="dash-board-item-chart portfolio-chart-item"  id="portfolio-chart-1" ></div>
                        </div>
                        )}
                        {equityValueData?.length === 0 && (
                          <div className="dash-board-item-content-chart-1">
                              <div className="dash-board-item-chart portfolio-chart-item no-data" >
                                <img src={nodataIcon} />
                                <span>{__('No data to view')}</span>
                              </div>
                          </div>
                        )}
                      </div>
                      <div className="dash-board-item dash-board-sub-top-2">
                        <div className="dash-board-item-title">{__('Transactions')}</div>
                        <div className="dash-board-item-content-chart-1">
                          {transactions?.length > 0 && (
                            <Table
                              className="dashboard-equity-transaction-table"
                              columns={foundColumns()}
                              dataSource={transactions}
                              pagination={false}
                              scroll={{ y: 180, x: 700 }}
                              loading={loading}
                            />
                          )}
                          {transactions?.length === 0 && (
                            renderEmptyData()
                          )}
                        </div>
                      </div>
                    </div>
                  )}  
                  {showTab === 'equity' && (
                    <div className="dash-board-sub-top-second">
                      <div className="dash-board-item dash-board-sub-top-1">
                        <div className="dash-board-item-title-box">
                          <div className="dash-board-item-title">{__('Shares')}</div>
                          <div className="dash-board-item-sub-title">{__('Total Quantity')}: {formatNumber(shares?.total_quantity ?? 0)}</div>
                        </div>
                        {shareDataExist && (
                        <div className="dash-board-item-content-chart-1">
                            <div className="dash-board-item-chart portfolio-chart-item"  id="portfolio-chart-share" ></div>
                        </div>
                        )}
                        {!shareDataExist && (
                          renderEmptyData()
                        )}
                      </div>
                      <div className="dash-board-item dash-board-sub-top-2">
                        
                        <div className="dash-board-item-title-box">
                          <div className="dash-board-item-title">{__('Options')}</div>
                          <div className="dash-board-item-sub-title">{__('Total Quantity')}: {formatNumber(+(options?.total_quantity ?? 0))}</div>
                        </div>

                        {optionDataExist && (
                          <div className="dash-board-item-content-chart-1">
                            <div className="dash-board-item-chart portfolio-chart-item"  id="portfolio-chart-option" ></div>
                          </div>
                        )}
                        {!optionDataExist && (
                          renderEmptyData()
                        )}
                      </div>
                      <div className="dash-board-item dash-board-sub-top-3">
                        <div className="dash-board-top-pie-chart">
                        <div className="dash-board-item-title-box">
                          <div className="dash-board-item-title">{__('Vesting and Exercised')}</div>
                          <div className="dash-board-item-sub-title">{__('Total Quantity')}: {formatNumber(vesting?.total_quantity?.quantity ?? vesting?.total_quantity ?? 0)}</div>
                        </div>
                        <div className="dash-board-item-content" style={{ justifyContent: 'center' }}>
                            {!!existDisplayData2Value && (
                              <div className="dash-board-item-content-chart">
                                <div className="portfolio-chart-legend">
                                  {displayData2.length > 0 && (
                                    <div className="dash-board-item-content-chart-legend" className={classnames({ 'dash-board-item-content-chart-legend-token': showTab === 'token' })} style={{ padding: '10px 20px' }}>{displayData2.map((item, index) => (
                                      <div className="dash-board-item-content-chart-legend-item">
                                        <p style={{ display: 'flex', justifyContent: 'space-between' }}>{item.name} <span>{formatPercent(item.percentage ?? 0)}</span></p>
                                        <Progress showInfo={false} strokeColor={existDisplayData2Value && +item.percentage > 0 ? colorMap[index] : '#cccccc'} size="small" percent={100} />
                                      </div>
                                    ))}</div>
                                  )}
                                </div>
                                <div className="portfolio-chart-item" id="portfolio-chart-2"></div>
                              </div>
                            )}
                            {!existDisplayData2Value && (
                              renderEmptyData()
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  )}
                   {/* &&  */}
                  {showTab === 'equity' && (!isPhantomEmpty&&existWarrantValue&&!!convertibles?.total_quantity) &&(
                    <div className="dash-board-middle">
                      <div className="dash-board-item dash-board-middle-1">
                        <div className="dash-board-item-title-box">
                          <div className="dash-board-item-title">{__('Convertibles')}</div>
                          <div className="dash-board-item-sub-title">{__('Total Quantity')}: {formatNumber(+convertibles?.total_quantity ?? 0)}</div>
                        </div>
                        {!convertibles?.total_quantity && (
                          renderEmptyData({ margin: '60px auto' })
                        )}
                        {!!convertibles?.total_quantity && (
                          <div className="dash-board-item-flex">
                            <div className="dash-board-item-content-box">
                              <p><span className="item">{__('Converted')}</span><span className="value">{formatPercent((convertibles?.percentage))}</span></p>
                              <Progress percent={100} showInfo={false} size="small" strokeColor={convertibles?.total_quantity ? colorMap[0] : '#cccccc'} />
                            </div>
                            <div className="dash-board-item-chart portfolio-chart-item"  id="portfolio-chart-4" ></div>
                          </div>
                        )}
                      </div>
                      <div className="dash-board-item dash-board-middle-2">
                        <div className="dash-board-item-title-box">
                          <div className="dash-board-item-title">{__('Warrants')}</div>
                          <div className="dash-board-item-sub-title">{__('Total Quantity')}: {formatNumber(+warrant?.total_quantity ?? 0)}</div>
                        </div>
                        {existWarrantValue && (
                            <div className="dash-board-item-flex">
                            <div className="dash-board-item-content-box">
                              <p><span className="item">{__('Exercised')}</span><span className="value">{formatPercent((warrant?.exercise?.percentage))}</span></p>
                              <Progress percent={100} showInfo={false} size="small" strokeColor={existWarrantValue ? colorMap[0] : '#cccccc'} />
                              <p><span className="item">{__('Vested')}</span><span className="value">{formatPercent((warrant?.vested?.percentage))}</span></p>
                              <Progress percent={100} showInfo={false} size="small" strokeColor={existWarrantValue ? colorMap[1] : '#cccccc'} />
                              <p><span className="item">{__('Unvested')}</span><span className="value">{formatPercent((warrant?.un_vested?.percentage))}</span></p>
                              <Progress percent={100} showInfo={false} size="small" strokeColor={existWarrantValue ? colorMap[2] : '#cccccc'} />
                            </div>
                            <div className="dash-board-item-chart portfolio-chart-item" id="portfolio-chart-5"></div>
                          </div>
                        )}
                        {!existWarrantValue && (
                          renderEmptyData({ margin: '60px auto' })
                        )}
                      </div>
                      <div className="dash-board-item dash-board-middle-3">
                        <div className="dash-board-item-title-box">
                        <div className="dash-board-item-title">{__('Share option awards')}</div>
                          <div className="dash-board-item-sub-title">{__('Total Quantity')}: {formatNumber(+phantom_options.total_quantity ?? 0)}</div>
                        </div>
                        {isPhantomEmpty && (
                            renderEmptyData({ margin: '60px auto' })
                        )}
                         {!isPhantomEmpty && (
                           <div className="dash-board-item-flex">
                           <div className="dash-board-item-content-box">
                             <p><span className="item">{__('ESOP')}</span><span className="value">{formatPercent((phantom_options?.esop?.percentage))}</span></p>
                             <Progress percent={100} showInfo={false} size="small" strokeColor={existValue && +phantom_options?.esop?.percentage > 0 ? colorMap[0] : '#cccccc'} />
                             <p><span className="item">{__('RSA')}</span><span className="value">{formatPercent((phantom_options?.rsa?.percentage))}</span></p>
                             <Progress percent={100} showInfo={false} size="small" strokeColor={existValue  && +phantom_options?.rsa?.percentage > 0 ? colorMap[1] : '#cccccc'} />
                             <p><span className="item">{__('RSU')}</span><span className="value">{formatPercent((phantom_options?.rsu?.percentage))}</span></p>
                             <Progress percent={100} showInfo={false} size="small" strokeColor={existValue  && +phantom_options?.rsu?.percentage > 0 ? colorMap[2] : '#cccccc'} />
                             <p><span className="item">{__('SAR')}</span><span className="value">{formatPercent((phantom_options?.sar?.percentage))}</span></p>
                             <Progress percent={100} showInfo={false} size="small" strokeColor={existValue  && +phantom_options?.sar?.percentage > 0 ? colorMap[3] : '#cccccc'} />
                           </div>
                           <div className="dash-board-item-chart portfolio-chart-item" id="portfolio-chart-6"></div>
                         </div>
                         )}
                        
                      </div>
                    </div>
                  )}
                  {showTab === 'token' && (
                    <div className="token-part-area">
                      <div className="dash-board-item-title">{__('My wallet transactions')}</div>
                      <TokenTransactionTable 
                        list={my_wallet_transaction}
                        callback={getPortfolioView}
                        noAction={true}
                        isPortfolio
                        paginationConfig={false}
                      />
                    </div>
                  )}
              </div>
            </Spin>
        </div>
    )
}