import React from 'react';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import security from 'stores/security';

@observer
class EnsureLogin extends React.Component {
    render() {
        if (!security.hasLogin) {
            return (
                <Redirect
                    to={{
                        pathname: '/auth/login',
                        // search: qs.stringify(
                        //     Object.assign(qs.parse(props.location.search, { ignoreQueryPrefix: true }), {
                        //         next: props.location.pathname
                        //     })
                        // ),
                        state: { error: 'You need to log in before you can access this page.' }
                    }}
                />
            );
        }

        return this.props.children;
    }
}

// 确保页面在登陆状态下访问
function ensureLogin(WrappedComponent) {
    return withRouter((props) => (
        <EnsureLogin>
            <WrappedComponent {...props} />
        </EnsureLogin>
    ));
}

export default ensureLogin;
