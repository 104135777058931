import React, { Component } from "react";
import ReactQuill, { Quill } from 'react-quill-with-table';
import 'react-quill-with-table/dist/quill.snow.css';
import {Button, Card, message, Space} from "antd";
import {quillModules} from "../config";
import { variable, signButton, escapeVariable } from "components/Template"
import Loading from 'components/Loading';
import {LeftOutlined} from "@ant-design/icons";
import { post } from 'srcPath/http';
import officerSignImg from "components/Template/img/officer-sign.png"
import stakeholderSignImg from "components/Template/img/stakeholder-sign.png"
import Modal, { ModalContent } from 'components/Modal';
import "../css/toolbar.css";
import "../style.scss";
import ImageResize from 'quill-image-resize-module-react';
import TemplateRename from "./TemplateRename";
import { calculator } from "Utils";
import {ceil} from "mathjs";

Quill.register('modules/imageResize', ImageResize);

class TemplatesDocusign extends Component {

    quillRef = null;
    reactQuillRef = null;
    saveNameModel = null;
    qlEditorDom = null;
    callbackScrollTop = 0;

    state = {
        loading: false,
        html: '',
        currPage: 1,
        clientX: 0,
        clientY: 0,
        dragZIndex: -1,
        menuButtonValue: {},
    };

    componentDidMount() {
        const {saveIsHideBar} = this.props;
        saveIsHideBar(true);
        this.setState({html: this.initHtml()})
        this.attachQuillRefs();
        this.qlEditorDom = document.getElementsByClassName('ql-editor')[0];
        this.qlEditorDom.addEventListener('scroll', this.scrollChange);

        this.mouseMove();
    }



    scrollChange = (e) => {
        const { currPage } = this.state;
        let scrollTop  = this.qlEditorDom.scrollTop; //滚动条滚动高度
        let clientHeight = this.qlEditorDom.clientHeight; //可视区域高度
        const pageHeight = 1050;
        let newCurrPage = calculator.agile(`(${scrollTop} + ${clientHeight}) / ${pageHeight}`);
        newCurrPage = newCurrPage > 0 ? ceil(newCurrPage) : 1;
        currPage != newCurrPage && this.setState({currPage: newCurrPage });
    }

    componentDidUpdate() {
        this.attachQuillRefs()
    }

    attachQuillRefs = () => {
        if (typeof this.reactQuillRef.getEditor !== 'function') return;
        this.quillRef = this.reactQuillRef.getEditor();
    }

    initHtml = () => {
        const { id, html } = this.props;
        const newHtml = escapeVariable(0, id, html);
        return newHtml;
    }

    insertText = (value, color) => {
        // 获取编辑器光标位置
        let index = this.quillRef.selection.savedRange.index;
        this.quillRef.clipboard.dangerouslyPasteHTML(index, `<span>&nbsp;</span><span id_docusign_data="1" style="background-color: ${color}; color: #FFF; font-weight: bold;">[${value}]</span><span>&nbsp;</span>`);
    }

    insertSignText = (value, color, type) => {
        let index = this.quillRef.selection.savedRange.index;
        this.quillRef.insertEmbed(index, 'image', ( type === 1 ? officerSignImg : stakeholderSignImg ), 'api');
    }

    quillChange = (html) => {
        this.setState({html})
    }

    quill = () => {
        const { html } = this.state;
        return <ReactQuill ref={(el) => { this.reactQuillRef = el }} value={html} modules={quillModules} onChange={this.quillChange} onChangeSelection={this.selectionChange} />
    }

    selectionChange = (range, source, editor) => {
        const index = range ? range.index : 0;
        const delta = editor.getContents(index);
        const data = delta && delta.ops[0];
        if(!data){
            return;
        }
        const { insert, attributes } = data;
        const { background } = attributes || {};
        const checkIndex = variable.findIndex(v=>v.color===background) ;
        if(checkIndex !== -1){
            const { value } = variable[checkIndex];
            const searchValue = `[${value}]`;
            const insertIndex = searchValue.indexOf(insert);
            this.quillRef.setSelection(index-insertIndex, insert.length);
        }
    }

    variableButton = () => {
        return variable.map((v, k)=>{
            return <div
                onMouseDown={this.menuMouse.bind(this, v, 1, false)}
                key={k}
                className="docusign-quill-menu-button"
                onClick={this.insertText.bind(this, v.value, v.color)}
                style={{backgroundColor: v.color}}
            >{v.value}</div>
        })
    }

    menuMouse = (data, isShow, isSign) => {
        if(!isShow){
            const { value, color, type } = data;
            if(isSign){
                this.insertSignText(value, color, type);
            }else{
                this.insertText(value, color);
            }
        }
        data.isSign = isSign;
        this.setState({dragZIndex: isShow === 1 ? 99999 : -1, menuButtonValue: data});
    }

    signButton = () => {
        return signButton.map((v, k)=>{
            return <div
                onMouseDown={this.menuMouse.bind(this, v, 1, true)}
                key={k}
                className="docusign-quill-menu-button"
                onClick={this.insertSignText.bind(this, v.value, v.color, v.type)}
                style={{backgroundColor: v.color}}
            >{v.value}</div>
        })
    }

    saveTemplate = (id, html='') => {
        html = html ? html : this.quillRef.root.innerHTML;
        //动态变量转义，使后端可正常读取
        const newHtml = escapeVariable(1, id, html);
        const signatureLocation = this.getSignatureLocation(newHtml);
        id > 0 ? this.checkAddStakeholder(id, newHtml, signatureLocation) : this.openSaveNameModel(id, newHtml, signatureLocation);
    }

    checkAddStakeholder = async (id, newHtml, signature_location, template_name='') => {
        Loading.global(true);
        let subData = {id, template_content: newHtml, signature_location};
        template_name && (subData.template_name = template_name);
        try {
            const { data: resData } = await post('createSignTemplate', subData);
            const { code, data } = resData;
            if(code === 0) {
                message.success(__('success'));
                this.showPage(2, id, '', true);
            } else {
                message.error(data);
            }
        } catch(err) {
            message.error(err && err.message);
        }
        Loading.global(false);
    }

    getSignatureLocation = (newHtml) => {
        const isStakeholderSign = RegExp(/<p id="stakeholder-sign">.*<div style="clear: both;" STAKEHOLDER_SIGNATURE ><\/div><\/div><\/p>/).test(newHtml);
        const isOfficerSign = RegExp(/<p id="officer-sign">.*<div style="clear: both;" OFFICER_SIGNATURE ><\/div><\/div><\/p>/).test(newHtml);
        let signatureLocation = [];
        isOfficerSign && signatureLocation.push({recipient: 1});
        isStakeholderSign && signatureLocation.push({recipient: 2});
        return JSON.stringify(signatureLocation);
    }

    showPage = (page, id = 0, html = '', hideBar = false) => {
        const { saveIsHideBar, saveState, updateActiveKey } = this.props;
        saveIsHideBar(hideBar);
        const newHtml = this.quillRef.root.innerHTML;
        saveState ? saveState({ page, id, html:newHtml}) : updateActiveKey('4', false);
    }

    openSaveNameModel = (id, newHtml, signatureLocation) => {
        this.saveNameModel = Modal.open({
            component: props => (
                <ModalContent close={props.close} title={__("Add E-Sign template")}>
                    <TemplateRename
                        close={props.close}
                        id={id}
                        newHtml={newHtml}
                        signatureLocation={signatureLocation}
                        getData={this.getData}
                        checkAddStakeholder={this.checkAddStakeholder}
                    />
                </ModalContent>
            ),
            width: 642,
            className: 'sprout-modal',
            maskClosable: false
        });
    }

    drag = () => {
        const { clientX, clientY, dragZIndex, menuButtonValue } = this.state;
        const { color, value, isSign = false } = menuButtonValue;
        return (
            <div
                style={{top:clientY, left: clientX, zIndex: dragZIndex, backgroundColor: color}}
                onMouseUp={this.menuMouse.bind(this, menuButtonValue, 0, isSign)}
                className="docusign-quill-edit-drag">{value}</div>
        )
    }

    mouseMove = () => {
        document.onmousemove = (ev) => {
            const { clientX, clientY } = ev;
            this.setState({clientX:clientX-30, clientY: clientY-10});
        }
    }

    /**
     * 卸载
     */
    componentWillUnmount() {
        this.saveNameModel && this.saveNameModel.close();
    }

    render() {
        const { currPage } = this.state;
        const { id } = this.props;
        return (
            <Space size={20} direction="vertical" className="docusign-quill-edit">
                {this.drag()}
                <div className="docusign-quill-edit-button">
                    <Button onClick={this.showPage.bind(this, 2, id, '', true)}><LeftOutlined />{__('Back')}</Button>
                    <Button type="primary" onClick={this.saveTemplate.bind(this, id, '')}>{__('Save')}</Button>
                </div>
                <Card className="docusign-quill-edit-card">
                    <div className="docusign-quill-menu">
                        <div className="docusign-quill-menu-title">{__('VARIABLES')}</div>
                        {this.variableButton()}
                        <div className="docusign-quill-menu-title">{__('SPECIAL')}</div>
                        {this.signButton()}
                    </div>
                    <div>{this.quill()}<div className="docusign-quill-edit-page"><span>{currPage}</span></div></div>
                </Card>
            </Space>
        );
    }
}
export default TemplatesDocusign;