import React, { Component } from 'react';
import Overview from "./components/overview";
import Setting from "./components/setting";
import ChangePlan from "./components/ChangePlanModel";
import SpeakToUs from "./components/SpeakToUsModel";
import Suspended from "./components/SuspendedModel";
import './style.scss';
import HistoryModel from "./components/HistoryModel";

export default class Billing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageName: 'overview'
        }
    }

    getPage = (pageName) => {
        this.setState({ pageName });
    }

    content = () => {
        const { pageName } = this.state;
        let html;
        switch (pageName){
            case 'overview':
                html = <Overview history={this.props.history} pageName={pageName} getPage={this.getPage} />
                break;
            case 'settings':
                html = <Setting pageName={pageName} getPage={this.getPage} />
                break;
            case 'SpeakToUs':
                html = <SpeakToUs pageName={pageName} getPage={this.getPage} />
                break;
            case 'suspended':
                html = <Suspended pageName={pageName} getPage={this.getPage} />
                break;
            case 'history':
                html = <HistoryModel pageName={pageName} getPage={this.getPage} />
                break
            default:
                html = <Overview pageName="overview" getPage={this.getPage} />
        }
        return html;
    }

    render() { return this.content(); }
}