import React, {Component, Fragment} from "react";
import {Checkbox, Table} from "antd";
import Utils, { I, formatCurrencyNumber, formatNumber  } from 'Utils';
import formatExercisePrice from 'Utils/format/formatExercisePrice';
import security from 'stores/security';
import {formatDate} from "Utils/format/formatDate";

import updateDefaultImg from "modules/Business/Securities/images/update-default.png";
import updateHoverImg from "modules/Business/Securities/images/update-hover.png";
import dynamicImg from "modules/Business/Securities/images/dynamic.gif";

class DraftWarrantsTable extends Component {

    state = {
        loading: false,
        selectedRowKeys: [],
        updateHoverShow: {},
    }

    componentDidMount() {
        const { updateHoverShow } = this.state;
        const { data } = this.props;
        data && data.forEach((v, k)=>{
            updateHoverShow[v.id] = 0;
        })
        this.setState({updateHoverShow});
    }

    /**
     * 字符串排序
     * @param a
     * @param b
     * @returns {number}
     */
    sorterStrOrderBy = (a, b, key) => {
        const stringA = a[key].toUpperCase();
        const stringB = b[key].toUpperCase();
        return stringA < stringB ? -1 : stringA > stringB ? 1 : 0;
    }

    /**
     * 表格头部配置
     */
    tableColumns = () => {
        const { listKey } = this.props;
        return [
            {
                title : '',
                dataIndex : 'custom_label_id',
                width: 22,
                className: 'prefer-left-edit-th',
                render: (text, data, index) => {
                    const { updateHoverShow } = this.state;
                    return (
                        <Fragment>
                            {index == listKey && <img className="show-img" src={dynamicImg} />}
                            {index != listKey && <img className={!updateHoverShow?.[data?.id] && 'show-img'} src={updateDefaultImg} />}
                            {index != listKey && <img className={updateHoverShow?.[data?.id] && 'show-img'} src={updateHoverImg} />}
                        </Fragment>
                    )
                }
            },
            {
                title : __('Vesting ID'),
                align : 'left',
                dataIndex : 'vesting_id',
                sorter: (a, b) => {
                    const { formatWarrantBlockList } = this.props;
                    const nameA = a.vesting_id;
                    const nameB = b.vesting_id;
                    const stringA = nameA?.toUpperCase();
                    const stringB = nameB?.toUpperCase();
                    return stringA < stringB ? -1 : stringA > stringB ? 1 : 0;
                },
                render : (text, value) => {
                    return <div>{value.vesting_id }</div>
                }
            },
            {
                title : __('Token'),
                align : 'left',
                dataIndex : 'token_name',
                sorter: (a, b) => this.sorterStrOrderBy(a, b, 'token_name'),
                render : (text, value) => {
                    return <div>{ I(value.token_name, __('No name')) }</div>
                }
            },
            {
                title : __('Wallet'),
                align : 'left',
                dataIndex : 'destination_wallet_address',
                // sorter: (a, b) => this.sorterStrOrderBy(a, b, 'issue_date'),
                render : (text, value) => {
                    return <div>{ I(value.destination_wallet_address, '--') }</div>
                    // return <div>{ I(value.issue_date, '') ? formatDate(value.issue_date) : __('No issue date')}</div>
                }
            },
            {
                title : __('Stakeholder/Wallet name'),
                align : 'right',
                dataIndex : 'stakeholder_name',
                sorter: (a, b) => a.stakeholder_name > b.stakeholder_name,
                render : (text, value) => {
                    return <div>{text}</div>
                }
            },
            {
                title : __('Issued quantity'),
                align : 'right',
                dataIndex : 'quantity',
                sorter: (a, b) => a.quantity > b.quantity,
                render : (text, value) => {
                    return <div>{value.quantity==0?__("No quantity"):formatNumber(value.quantity,null)}</div>
                }
            },
            {
                title : __('Vesting schedule'),
                dataIndex : 'vesting_schedule_name',
                sorter: (a, b) => a.vesting_schedule_id > b.vesting_schedule_id,
                render : (text, value) => {
                    return <div>{ I(value.vesting_schedule_id, '--') }</div>
                }
            },
            {
                title : __('Vesting start'),
                dataIndex : 'vesting_start_date',
                sorter: (a, b) => this.sorterStrOrderBy(a, b, 'vesting_start_date'),
                render : (text, value) => {
                    return <div>{ I(value.vesting_start_date, '') ? formatDate(value.vesting_start_date) : '--'}</div>
                }
            }
        ]
    }

    updateImg = (id, type) => {
        const { updateHoverShow } = this.state;
        updateHoverShow[id] = (type === 0);
        this.setState({updateHoverShow})
    }

    /**
     * 表格checkbox change事件
     * @param record
     * @param index
     * @param boxChecked
     * @param e
     */
    checkChange = (record, index, boxChecked, e) => {
        const { checked } = e.target;
        const { selectedRowKeys } = this.state;
        const { updateSelectCount } = this.props;
        const { id } = record;
        let key_list = [...selectedRowKeys];
        if(checked){
            key_list.push(id);
        }else{
            key_list.forEach( (v, k) => {
                if(v == id){
                    key_list.splice(k, 1);
                }
            })
        }
        updateSelectCount(key_list.length, [{selectIndex: index,checked:checked}]);
        this.setState(()=>({
            selectedRowKeys : key_list
        }))
    }

    /**
     * 表格checkbox自定义配置
     * @param checked
     * @param record
     * @param index
     * @param selectedRowKeys
     */
    securityHover = (checked, record, index) => {

        const { is_show_error } = record;
        return (
            <Fragment>
                {
                    is_show_error == 1 &&
                    <a className="signTipBox" >
                        <a>
                            <div className="error-tip-triangle"></div>
                        </a>
                        <p className={"sign-tiptext error-tiptext bg-dangered font_weight500 draft_error_tip"+index}>{__('Error')}</p>
                    </a>
                }
                <Checkbox checked={checked} onChange={this.checkChange.bind(this, record, index, checked)} ></Checkbox>
            </Fragment>
        );
    }

    /**
     * 全选change事件
     * @param selectedRowKeys
     */
    onSelectChange = selectedRowKeys => {
        const { data, updateSelectCount } = this.props;
        const count = selectedRowKeys.length;
        if(!count){
            updateSelectCount(count, [], true);
        }else{
            let selectIndexArray = [];
            data.forEach((v, k) => {
                selectIndexArray.push({selectIndex:k, checked:true});
            })
            updateSelectCount(count, selectIndexArray);
        }
        this.setState({ selectedRowKeys });
    };

    rowSelection = (selectedRowKeys) => ({
        selectedRowKeys,
        onChange: this.onSelectChange,
        renderCell : (checked, record, index, originNode) => this.securityHover(checked, record, index, selectedRowKeys)
    })

    /**
     * 行点击高亮样式设置
     * @returns {string}
     */
    setRowClassName = (value, index) => {
        const { listKey } = this.props;
        let rowClass = '';
        rowClass = index == listKey ? 'edit_row' : '';
        return value.is_show_error == 1 ? 'checked-error-state' : rowClass;
    }

    /**
     * 行点击事件
     * @param row
     * @param index
     * @returns {{onClick: onClick}}
     */
    onRow = (row, index) => {
        return {
            onClick: event => {
                const { transferKey } = this.props;
                this.setState({selectedRowKeys: []})
                transferKey(index);
            },
            onMouseEnter: event => {
                this.updateImg(row?.id, 0);
            },
            onMouseLeave: event => {
                this.updateImg(row?.id, 1);
            },
        }
    }

    render() {
        const { loading, selectedRowKeys } = this.state;
        const { data } = this.props;
        return (
            <Table
                onRow={ this.onRow}
                loading={loading}
                pagination={false}
                className="stake-table draft-warrants-table"
                rowClassName={this.setRowClassName}
                columns={this.tableColumns()}
                dataSource={data}
                rowKey="id"
                rowSelection={this.rowSelection( selectedRowKeys)}
            />
        );
    }
}
export default DraftWarrantsTable;