import React, {Component, Fragment} from "react";
import {Button, Checkbox, Dropdown, Menu, Table, Tooltip} from "antd";
import {DownOutlined} from "@ant-design/icons";
import TransactionRender from 'B/components/TransactionHistory';
import { I, formatCurrencyNumber } from 'Utils';
import moment from "moment";
import { noPermission } from 'components/Permission';
import {formatDate} from "Utils/format/formatDate";
import openCertificateModal from 'components/CertificateDetail';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import { LOADING,certificateStatus } from "Utils/constant";
class WarrantsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRowKeys : []
        }
    }

    componentDidMount(){
        const { onRef } = this.props;
        onRef(this);
        this.initFilterColumn();
    }

    initFilterColumn = () => {
        const tableColumn = this.tableColumns();
        const { saveState } = this.props;
        saveState('tableColumn', tableColumn);
    }

    /**
     * 字符串排序
     * @param a
     * @param b
     * @returns {number}
     */
    sorterStrOrderBy = (a, b, key) => {
        const stringA = a[key].toUpperCase();
        const stringB = b[key].toUpperCase();
        return stringA < stringB ? -1 : stringA > stringB ? 1 : 0;
    }

    /**
     * 时间排序
     * @param a
     * @param b
     * @param key
     * @returns {number}
     */
    sorterDateOrderBy = (a, b, key) => {
        const dataA = moment(a[key], 'DD/MM/YYYY').format('YYYYMMDD');
        const dataB = moment(b[key], 'DD/MM/YYYY').format('YYYYMMDD');
        return dataA - dataB;
    }

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    setCurSelectSecurity = ( index) => {
        const { setCurSelectSecurity } = this.props;
        setCurSelectSecurity( index);
    }

    /**
     * status 列展示内容
     * @param value
     * @returns {JSX.Element}
     */
    getStatusValue = (value) => {
        let status = value.status;
        let statusArr = status.split("until ");
        status = statusArr[0] +" until " + formatDate(statusArr[1])
        return <Tooltip  placement={'topLeft'} title = {<span>{value.status.substring(0,17) == "Exercisable until" ? status :value.status} <TransactionRender transferSecurity={value.transferSecurity} /></span>}>
         <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'noWrap' }}>
            {
                (value.status === "Outstanding" || value.status==="Issued") &&
                <p className="green-disc">&nbsp;&nbsp;&nbsp;&nbsp;{certificateStatus[value.status]}</p>
            }
            {value.status != "Outstanding" && value.status != "Partially exercised" && value.status != "Issued" &&  value.status.substring(0,11) != "Exercisable" &&
            <p className="red-disc">&nbsp;&nbsp;&nbsp;&nbsp;{certificateStatus[value.status]}</p>}
            { value.status.substring(0,17) == "Exercisable until"  &&
            <p className="yellow-disc file_text">&nbsp;&nbsp;&nbsp;&nbsp;{status}</p>
            }
             {( value.status == "Partially exercised" || value.status === "Exercisable") &&
             <p className="yellow-disc file_text">&nbsp;&nbsp;&nbsp;&nbsp;{certificateStatus[value.status]}</p>
             }
            <TransactionRender transferSecurity={value.transferSecurity} />
        </div>
        </Tooltip>
    }

    /**
     * 表格最后一列菜单配置
     * @param data
     * @param index
     * @returns {JSX.Element}
     */
    dropdownInfo = (text, data, index) => {
        const { visibleStatus } = data;
        return (
            <Dropdown
                visible={visibleStatus}
                onVisibleChange={this.handleVisibleChange}
                onClick={(e)=>{
                    e.stopPropagation();
                    this.setCurSelectSecurityNew(text, data, index)
                    }
                }
                overlay={this.menu( data, index)}
                placement="bottomRight"
                trigger="click">
                <div className="tableMenu"><DownOutlined style={{ color: '#FFF' }}/></div>
            </Dropdown>
        );
    }

    /**
     * 菜单
     * @param data
     * @param index
     * @returns {JSX.Element}
     */
    menu = (value, index) => {
        const { curSelectSecurity } = this.props;
        const isExercisable = value.status.substring(0,11) == "Exercisable" ? 1 : 0;
        const canExercise = value.status=="Partially exercised"||value.status=="Outstanding"||value.status=="Issued"||isExercisable==1 ? 1 : 0;
        const isModifyVesting = value.vesting_schedule_id==0 && value.custom_schedule_id==0 ? 0 : 1;
        const reverseCancellation = value.status=="Canceled"||value.status=="Repurchased" ? 1 : 0;
        const isExpired = value.status == "Expired"||value.status == "Forfeited"||value.status == "Canceled" ? 1 : 0;
        const isTransferred = value.status == "Transferred" ? 1 : 0;
        const isFullyExercised = value.status == "Fully exercised" ? 1 : 0;
        const isEmptyTerminationDate = value.termination_date =="" ? 1 : 0;
        const hasBeenExercised = value.status =="Partially exercised" || value.status == "Fully exercised" ? 1 : 0;
        const addOrEdit = ( curSelectSecurity.notes == ""||null ) ? __("Add certificate notes"): __("Edit certificate notes")

        return (
            <Menu>
                { (noPermission('securities_warrants_modify') === false ) && ( <Menu.Item><a data-toggle="modal" onClick={this.stopDetailModel.bind(this,value,index)} data-target="#modifyWarrantModal">{__('Modify warrant')}</a></Menu.Item>)}
                { (noPermission('securities_warrants_vestingSchedule') === false && isTransferred == 0 && value.type !== "ps" && hasBeenExercised==0 && isModifyVesting==0 ) && <Menu.Item><a data-toggle="modal"  onClick={this.stopDetailModel.bind(this,value,index)} data-target="#addVestingScheduleModal">{__('Add vesting schedule')}</a></Menu.Item>}
                { (noPermission('securities_warrants_vestingSchedule') === false && isTransferred == 0 && value.type !== "ps" && hasBeenExercised==0 && isModifyVesting==1 ) && <Menu.Item><a data-toggle="modal" onClick={this.getVestingData.bind(this,value,index)} data-target="#addVestingScheduleModal">{__('Modify vesting schedule')}</a></Menu.Item>}
                { (noPermission('securities_warrants_vestingSchedule') === false && isModifyVesting==1 && hasBeenExercised==0 && value.isMilestone==1 && value.status!="Canceled" && isTransferred == 0) && <Menu.Item ><a data-toggle="modal" onClick={this.getVestMilestone.bind(this,value,index)} data-target="#vestMilestonesModal">{__('Vest milestones')}</a></Menu.Item> }
                { (noPermission('securities_warrants_vestingSchedule') === false && isModifyVesting==1 && hasBeenExercised==0 && value.isMilestone==0 && value.status!="Canceled" && isTransferred == 0) && <Menu.Item ><a data-toggle="modal" onClick={this.getInitVestingList.bind(this,value,index)} data-target="#accelerateVestingModal">{__('Accelerate vesting')}</a></Menu.Item>}
                { (noPermission('securities_warrants_achieve') === false && isTransferred == 0 && canExercise == 1 ) && <Menu.Item ><a data-toggle="modal"  onClick={this.getQuantityToExercise.bind(this,value,index)} data-target="#exerciseOptionModal">{__('Exercise warrant')}</a></Menu.Item>}
                { (noPermission('securities_warrants_transfer') === false && isFullyExercised == 0 && isTransferred == 0 && isExpired == 0 ) && <Menu.Item><a data-toggle="modal" onClick={this.getPrincipal.bind(this,value,index)} data-target="#TransferSafeModal">{__('Transfer warrants')}</a></Menu.Item>}
                { (noPermission('securities_warrants_cancel') === false && isTransferred == 0 && value.status === "Outstanding" ) && <Menu.Item><a data-toggle="modal" onClick={this.stopDetailModel.bind(this,value,index)} data-target="#cancelModal">{__('Cancel warrant')}</a></Menu.Item>}
                { (noPermission('securities_warrants_reverse') === false && reverseCancellation == 1 ) && <Menu.Item ><a onClick={this.reverseCancellation.bind(this,value,index)} className="reverse-btn text-muted" >{__('Reverse cancellation')}</a></Menu.Item>}
                { (noPermission('securities_warrants_reverse') === false && isTransferred == 0 && reverseCancellation == 0 ) && <Menu.Item><a onClick={this.stopDetailModel.bind(this,value,index)} className="reverse-btn text-muted no-cursor" >{__('Reverse cancellation')}<p className="reverse-tip">{__('Can only apply to cancelled')}<br/><span> {__('or')} </span>{__(' repurchased certificate(s).')}</p></a></Menu.Item>}
                { (noPermission('securities_warrants_adminNotes') === false ) && <Menu.Item ><a onClick={this.setInitShareNote.bind(this,value,index)} data-toggle="modal" data-target="#addAdminNotesModal">{ addOrEdit }</a></Menu.Item>}
                { (noPermission('securities_warrants_resend') === false && isTransferred == 0 && value.sign_status == "1" ) && <Menu.Item ><a onClick={this.resendEmail.bind(this,value,index)}>{__('Resend to officers')}</a></Menu.Item>}
                { (noPermission('securities_warrants_resend') === false && isTransferred == 0 && value.sign_status == "2" ) && <Menu.Item ><a onClick={this.resendEmail.bind(this,value,index)}>{__('Resend to stakeholder')}</a></Menu.Item>}
                {/*{ (noPermission('securities_warrants_terminate') === false) && isEmptyTerminationDate == 1 && isModifyVesting==1 && value.status != "Forfeited" && isTransferred == 0 && <Menu.Item ><a data-toggle="modal" onClick={this.stopDetailModel.bind(this,value,index)} data-target="#terminateStakeholderModal">Terminate stakeholder</a></Menu.Item>}*/}
                { (noPermission('securities_warrants_terminate') === false) && value.isCanBeTerminated == 1 && <Menu.Item ><a data-toggle="modal" onClick={this.stopDetailModel.bind(this,value,index)} data-target="#terminateStakeholderModal">{__('Terminate stakeholder')}</a></Menu.Item>}
                { (noPermission('securities_warrants_reverse_termination') === false) && isEmptyTerminationDate == 0 && isTransferred == 0 && <Menu.Item ><a onClick={this.reverseTermination.bind(this,value,index)}>{__('Reverse termination')}</a ></Menu.Item>}
            </Menu>
        )
    }

    resetSecurityStatus = (index) => {
        const { resetSecurityStatus } = this.props;
        resetSecurityStatus(index);
    }

    setSelectId = (index) => {
        const { setSelectId } = this.props;
        setSelectId(index);
    }

    setCurSelectSecurityNew = (text, data, index) => {
        const { setCurSelectSecurityNew } = this.props;
        setCurSelectSecurityNew(text);
        this.updateSecurityListsVisible(data,true)
    }

    getVestingData = (data,index,e) => {
        this.stopDetailModel(data,index,e);
        const { getVestingData } = this.props;
        getVestingData(index);
    }
    getVestMilestone = (data,index,e) => {
        this.stopDetailModel(data,index,e);
        const { getVestMilestone } = this.props;
        getVestMilestone();
    }
    getInitVestingList = ( data,index,e) => {
        this.stopDetailModel(data,index,e);
        const { getInitVestingList } = this.props;
        getInitVestingList();
    }
    getQuantityToExercise = (data,index,e) => {
        this.stopDetailModel(data,index,e);
        const { getQuantityToExercise } = this.props;
        getQuantityToExercise( data.id );
    }
    getPrincipal = (data,index,e) => {
        this.stopDetailModel(data,index,e);
        const { getPrincipal } = this.props;
        getPrincipal( );
    }
    reverseCancellation = (data,index,e) => {
        this.stopDetailModel(data,index,e);
        const { reverseCancellation } = this.props;
        reverseCancellation();
    }
    setInitShareNote = (data,index,e) => {
        this.stopDetailModel(data,index,e);
        const { setInitShareNote } = this.props;
        setInitShareNote();
    }
    resendEmail = (data,index,e) => {
        this.stopDetailModel(data,index,e);
        const { resendEmail } = this.props;
        resendEmail();
    }
    reverseTermination = (data,index,e) => {
        this.stopDetailModel(data,index,e);
        const { reverseTermination } = this.props;
        reverseTermination(data, index);
    }

    /**
     * 更新列表中的菜单显示状态
     * @param index 列表下标
     * @param flag 菜单显示状态
     */
    updateSecurityListsVisible = (data, flag) => {
        const { securityLists, saveState } = this.props;
        let list = [...securityLists];
        const index = list.findIndex((v,k,arr)=>{
            return v.id == data.id
        })
        if(flag === false) {
            list.forEach((v, k) => {
                list[k]['visibleStatus'] = false;
            });
        }
        if(data !== false){
            list[index]['visibleStatus'] = true;
        }
        saveState('securityLists', list);

    }

    handleVisibleChange = flag => {
        this.updateSecurityListsVisible(false, flag);
    }
    stopDetailModel=(data, index, e)=>{
        e.stopPropagation();
        this.updateSecurityListsVisible(false, false);
    }

    tableColumns = () => {
        return [
            {
                title : __('Security'),
                align : 'left',
                dataIndex : 'custom_label_id',
                key : 'keyId',
                sorter: true,
                sortName: 'security',
                fixed: 'left',
                width: 120,
                isChecked: true,
                isDisabled: true,
                render: (text, record) => <Button type="link" onClick={() => openCertificateModal(record.id)}>{text}</Button>
            },
            {
                title : __('Stakeholder'),
                dataIndex : 'name',
                sorter: true,
                sortName: 'stakeholder',
                isChecked: true,
            },
            {
                title : __('Status'),
                dataIndex : 'status',
                sorter: true,
                sortName: 'status',
                render : (text, value) => this.getStatusValue(value),
                isChecked: true,
            },
            {
                title : __('Remaining'),
                dataIndex : 'remaining',
                align : 'right',
                render : (text, value) => {
                    const remainingQuantity = I(value.quantity-value.exercised_quantity, 0);
                    return <div>{formatFractionalNumber(remainingQuantity)}</div>;
                },
                isChecked: true,
            },
            {
                title : __('Exercise price'),
                dataIndex : 'exercisePrice',
                align : 'right',
                render : (text, value) => {
                    return <div>{formatCurrencyNumber(value.exercise_price)}</div>;
                },
                isChecked: true,
            },
            {
                title : __('Purchase price'),
                dataIndex : 'purchase_price',
                align : 'right',
                render : (text, value) => {
                    return <div>{formatCurrencyNumber(value.purchase_price)}</div>;
                },
                isChecked: true,
            },
            {
                title : __('Issue date'),
                dataIndex : 'issue_date',
                align : 'left',
                render: (text, value) => {
                    return <span>{ formatDate(value.issue_date) }</span>
                },
                isChecked: true,
            },
            {
                title : __('Expiration date'),
                dataIndex : 'grant_expiration_date',
                align : 'left',
                render: (text, value) => {
                    return <span>{ formatDate(value.grant_expiration_date) }</span>
                },
                isChecked: true,
            },
            {
                title: __('Vesting schedule'),
                dataIndex: 'vestingScheduleName',
                align: 'left',
                isChecked: true,
                render: text => <span>{I(text, '--')}</span>
            },
            {
                title : '',
                dataIndex : 'id',
                render:(text, value, index) => this.dropdownInfo(text, value, index),
                width : 50,
                fixed: 'right',
                isHide: true,
            },
        ];
    }

    handleTableChange = (pagination, filters, sorter, extra) => {
        let sort = {}
        const { order, column } = sorter

        // TODO: cancel sort
        if (column?.sortName) {
            sort = {
                column: column.sortName,
                type: order
            }

            this.props.sortTable?.(sort)
        }else{
            // Ning 新加cancel sort
            sort = {}
            this.props.sortTable?.(sort)
        }
    }

    /**
     * 表格checkbox自定义配置
     * @param checked
     * @param record
     * @param index
     * @param selectedRowKeys
     */
    securityHover = (checked, record, index, selectedRowKeys) => {

        const { sign_status } = record;
        const message = {
            1 : __('Awaiting officer signature'),
            2 : __('Awaiting holder signature')
        }
        return (
            <Fragment>
                {
                    (sign_status == 1 || sign_status == 2) &&
                    <div className="signTipBox">
                        <div className={sign_status == 1 ? 'officer-tip-triangle' : 'holder-tip-triangle'}></div>
                        <p className={['sign-tiptext', sign_status == 1 ? 'bg-lightorange' : 'bg-lightgreen'].join(' ')} style={{display:'block'}}>{message[sign_status]}</p>
                    </div>
                }
                <Checkbox onClick={(e)=>{
                    e.stopPropagation();
                }} checked={checked} onChange={this.checkChange.bind(this, record, index, checked)} ></Checkbox>
            </Fragment>
        );
    }

    /**
     * 清空checkbox所有选中内容
     */
    clearAll = () => {
        this.setState(()=>({
            selectedRowKeys : []
        }))
    }

    /**
     * 表格checkbox change事件
     * @param record
     * @param index
     * @param boxChecked
     * @param e
     */
    checkChange = (record, index, boxChecked, e) => {
        const { checked } = e.target;
        const { selectedRowKeys } = this.state;
        const { updateSelectCount } = this.props;
        const { id } = record;
        let key_list = [...selectedRowKeys];
        if(checked){
            key_list.push(id);
        }else{
            key_list.forEach( (v, k) => {
                if(v == id){
                    key_list.splice(k, 1);
                }
            })
        }
        updateSelectCount(key_list.length, [{selectIndex: index,checked:checked}]);
        this.setState(()=>({
            selectedRowKeys : key_list
        }))
        this.props.setSelectId(key_list)
        this.props.checkSecurityStatus()
    }

    /**
     * 全选change事件
     * @param selectedRowKeys
     */
    onSelectChange = selectedRowKeys => {
        const { securityLists, updateSelectCount } = this.props;
        const count = selectedRowKeys.length;
        if(!count){
            updateSelectCount(count, [], true);
        }else{
            let selectIndexArray = [];
            securityLists.forEach((v, k) => {
                selectIndexArray.push({selectIndex:k, checked:true});
            })
            updateSelectCount(count, selectIndexArray);
        }
        this.setState({ selectedRowKeys }, ()=> this.props.checkSecurityStatus());
    };

    rowSelection = (selectedRowKeys) => ({
        selectedRowKeys,
        onChange: this.onSelectChange,
        renderCell : (checked, record, index, originNode) => this.securityHover(checked, record, index, selectedRowKeys)
    })

    /**
     * table 行 鼠标移入移出
     * @param row 对象
     * @param index 下标
     * @returns {{onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, onClick: onRowClick}}
     */
    onRow = (row, index) => {
        const { updateShowMsgStatus, curSelectSecurity } = this.props;
        return {
            //鼠标移入
            onMouseEnter : () => {
                // updateShowMsgStatus(index, true);
            },
            //鼠标移出
            onMouseLeave : () => {
                // updateShowMsgStatus(index, false);
            }
        }
    }
    render() {
        const { selectedRowKeys } = this.state;
        const { securityLists, tableColumn, paginationConfig, loading } = this.props;
        return (
            <Table
                pagination={ paginationConfig ? {...paginationConfig, position: ['bottomCenter'] } : false }
                className="warrant-table-new bigger_checkbox"
                columns={tableColumn}
                dataSource={securityLists}
                rowKey="id"
                locale={ loading && { emptyText: LOADING }}
                scroll={{ x: 1200, y: innerHeight ? innerHeight - 280 : false }}
                rowSelection={this.rowSelection( selectedRowKeys)}
                rowClassName="stake-table-row"
                // onRow={this.onRow}
                onChange={this.handleTableChange}
            />
        );
    }
}
export default WarrantsTable;