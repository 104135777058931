import React from 'react';
import Utils, { I, formatNumber } from "Utils";
import DeleteDoc from "../../components/DeleteDoc";
import { post } from 'srcPath/http';
import {Empty} from "antd";
import Loading from 'components/Loading';
import moment from "moment";
import DateSelector from "../../../../../components/DateSelector";
import {formatDate} from "Utils/format/formatDate";

class VestMilestones extends React.Component{
    constructor(props){
        super(props);
        this.state={
            dateFormat:"DD/MM/YYYY",
            loadingStatus:1,
            milestoneList:[
                {id:1,milestone:"gg",percentage:"23124",completion_date:"22/09/2020",complete_status:1,checked:true,},
                {id:2,milestone:"kk",percentage:"35435",completion_date:"",complete_status:0,checked:false,},
                {id:3,milestone:"gk",percentage:"38835",completion_date:"",complete_status:0,checked:false,},
            ],
            data:{
                id:3,
                milestoneList:[
                    {id:1,completion_date:"22/09/2020"}
                ],
            },
            vestStatus: 0
        }
    }
    getMilestoneList=()=>{
        const { listName, curSelectSecurity } = this.props;
        const getMilestoneListApi = listName == 'grantsList' ? 'getMilestoneListByGrantId' : 'getMilestoneList';
        const requestData = listName == 'grantsList' ? {grant_id:curSelectSecurity.id} : {id:curSelectSecurity.id};
        post(getMilestoneListApi,requestData).then((response)=> {
            if(response.data.code === 0){
                let responseData = response.data.data;
                for(let i=0;i<response.data.data.length;i++){
                    if(responseData[i].complete_status==1){
                        responseData.checked=true
                    }
                    if(responseData[i].complete_status==0){
                        responseData.checked=false
                    }
                }
                this.setState({
                    milestoneList:response.data.data,
                    loadingStatus:0
                })
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    changeCompleteStatus=(key)=>{
        let curMilestoneList = this.state.milestoneList;
        curMilestoneList[key].checked=!curMilestoneList[key].checked
        this.setState({
            milestoneList:curMilestoneList
        })
    }
    resetAction=()=>{
        window.$("#vestMilestonesStep2").removeClass("active");
        window.$("#vestMilestonesStep1").addClass("active");
    }
    vestMilestone=()=>{
        if(this.state.vestStatus == 1) return;
        this.setState({vestStatus: 1})
        let requestData = {};
        requestData.id = this.props.curSelectSecurity.id;
        requestData.milestoneList = [
        ];
        for(let i=0;i<this.state.milestoneList.length;i++){
            if(this.state.milestoneList[i].complete_status==0 && this.state.milestoneList[i].checked==true){
                requestData.milestoneList.push(this.state.milestoneList[i]);
                // requestData.milestoneList[i].id=this.state.milestoneList[i].id;
                // requestData.milestoneList[i].completion_date=this.state.milestoneList[i].completion_date;
            }
        }
        const { listName } = this.props;
        const vestMilestoneApi = listName == "grantsList" ? "vestMilestoneByGrants" : "vestMilestone";
        post(vestMilestoneApi,requestData).then((response)=> {
            if(response.data.code === 0){
                this.setState({vestStatus: 0})
                window.location.reload();
                // window.$("#vestMilestonesModal").modal("hide");
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    /**
     * vestMilestoneDateChange
     * @param name
     * @param key
     * @param date
     */
    vestMilestoneDateChange = (name, key, date) => {
        const { data, dateFormat } = this.state;
        let curData = this.state.milestoneList;
        curData[key][name] = date.format( dateFormat);
        this.setState({
            milestoneList:curData
        });
    }

    render(){
        let disabledNext = 1;
        let curStatus = this.state.milestoneList;
        for(let i=0;i<this.state.milestoneList.length;i++){
            if(curStatus[i].complete_status==0 && curStatus[i].checked==true && curStatus[i].completion_date!=""){
                disabledNext = 0;
            }
        }
        const { dateFormat } = this.state;
        const { curSelectSecurity, listName } = this.props;
        return(
            <div className="modal fade" id="vestMilestonesModal" tabIndex="-1" role="dialog" aria-hidden="true" >
                {/*<DeleteDoc  modifyKey={this.state.data.modify_id} />*/}
                <div className="modal-dialog dialog960 dialog-top40">
                    <div className="tab-content">
                        <div role="tabpanel" className="modal-content content760 tab-pane active" id="vestMilestonesStep1">
                            <div className="modal-header box-header">
                                <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.resetAction}>×</button>
                                <h4 className="modal-title" id="myModalLabel">{__('Complete milestone for')} { listName == 'grantsList' ? curSelectSecurity.grant_name : curSelectSecurity.custom_label_id}</h4>
                            </div>
                            <div className="modal-body padding2030">
                                <p className="text-muted">{__('Milestones marked completed in the future will not vest until that date.')}</p>
                                {this.state.loadingStatus==1 &&
                                <div className="termsLoadingPosition">
                                    <Loading/>
                                </div>
                                }
                                {this.state.loadingStatus==0 &&
                                <div className="modify-content">
                                    <table className="table" id="vest_milestone_table">
                                        <tbody>
                                        <tr className="modify-firstRow">
                                            <td>{__('Milestone Title')}</td>
                                            <td>{__('Number of Options to Vest')}</td>
                                            <td>{__('Completion Date')}</td>
                                            <td className="text-right">{__('Complete')}</td>
                                        </tr>
                                        {this.state.milestoneList.length==0&&
                                        <tr>
                                            <td colSpan={4}  className="onboarding_empty_can">
                                                <Empty className="onboarding_empty_pic"/>
                                            </td>
                                        </tr>
                                        }
                                        {this.state.milestoneList&&this.state.milestoneList.map((value,key)=>{
                                            let datepickerStatus = true;
                                            if(value.complete_status==0 && value.checked==true){
                                                datepickerStatus = false;
                                            }
                                            return(
                                                <tr className="modify-contentRow" key={key}>
                                                    <td>{value.milestone}</td>
                                                    <td className="vest_num_td">{formatNumber(value.percentage,null)}</td>
                                                    {value.complete_status==1 &&
                                                    <td>{value.completion_date}</td>
                                                    }
                                                    {value.complete_status==0 &&
                                                    <td className="completion_date_td">
                                                        <DateSelector
                                                            disabled={datepickerStatus}
                                                            onChange={this.vestMilestoneDateChange.bind(this, 'completion_date', key)}
                                                            value={ I(value.completion_date, '') ? moment(value.completion_date, dateFormat) : ''}
                                                            inputReadOnly={false}
                                                        />
                                                    </td>
                                                    }
                                                    {value.complete_status==1 &&
                                                    <td >
                                                        <div className="check-box">
                                                            <input id={"completeCheck"+key} type="checkbox" checked={true} disabled={true} className="re-checkbox modify-checkbox complete_checkbox bg-waterblue"/>
                                                            <label htmlFor={"completeCheck"+key}></label>
                                                        </div>
                                                    </td>
                                                    }
                                                    {value.complete_status == 0 &&
                                                    <td>
                                                        <div className="check-box">
                                                            <input id={"completeCheck"+key} name={key} type="checkbox"
                                                                   checked={value.checked== true }
                                                                   className="re-checkbox modify-checkbox complete_checkbox"
                                                                   onChange={this.changeCompleteStatus.bind(this,key)}/>
                                                            <label htmlFor={"completeCheck"+key}></label>
                                                        </div>
                                                    </td>
                                                    }
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                                }
                            </div>
                            <div role="presentation" className="modal-footer box-footer no-paddingtop">
                                {disabledNext == 0 &&
                                <a href="#vestMilestonesStep2" role="tab" data-toggle="tab" onClick={this.setCompleteList} className="btn boxbtn-to bg-darkblue">{__('Next: review vesting details')}</a>
                                }
                                {disabledNext == 1 &&
                                <a className="btn boxbtn-to send-disabledBtn">{__('Next: review vesting details')}</a>
                                }
                            </div>
                        </div>
                        <div role="tabpanel" className="modal-content content760 tab-pane" id="vestMilestonesStep2">
                            <div className="modal-header box-header">
                                <a href="#vestMilestonesStep1"  aria-controls="vestMilestonesStep1" role="tab" data-toggle="tab" className="back-btn"></a>
                                <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.resetAction}>×</button>
                                <h4 className="modal-title">{__('Complete Milestone for')} {listName == 'grantsList' ? curSelectSecurity.grant_name : curSelectSecurity.custom_label_id}</h4>
                            </div>
                            <div className="modal-body padding2030">
                                <p className="text-muted">{__('The following milestone will be vested.')}</p>
                                <div className="modify-content">
                                    <table className="table" id="vest_milestone_table">
                                        <tbody>
                                        <tr className="modify-firstRow">
                                            <td>{__('Milestone Title')}</td>
                                            <td>{__('Number of Options to Vest')}</td>
                                            <td className="text-right">{__('Completion Date')}</td>
                                        </tr>
                                        {this.state.milestoneList.map((value,key)=>{
                                            if(value.complete_status==0 && value.checked==true){
                                                return(
                                                    <tr className="modify-contentRow" key={key}>
                                                        <td>{value.milestone}</td>
                                                        <td className="vest_num_td">{formatNumber(value.percentage,null)}</td>
                                                        <td className="text-right">{formatDate(value.completion_date)}</td>
                                                    </tr>
                                                )
                                            }
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div role="presentation" className="modal-footer box-footer no-paddingtop">
                                <a onClick={this.vestMilestone} className="btn boxbtn-to bg-darkblue width200">{__('Vest milestone')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default VestMilestones;