import React from 'react';

import Stakehoder from '../forms/Stakeholder';
import Relationship from '../forms/Relationship';
import RegisterStatus from '../forms/RegisterStatus';
import Country from '../forms/Country';
import JobTitle from '../forms/JobTitle';
import Type from '../forms/Type';
import OptionPlan from '../forms/OptionPlan';
import Status from '../forms/Status';
import VestingSchedule from '../forms/VestingSchedule';
import AwardCategory from '../forms/AwardCategory';
import FormsDate from '../forms/FormsDate';

export default function Form() { 
    return <div>
        <Type />
        <Stakehoder type='options'/>
        <OptionPlan type='options' />
        <FormsDate name='grant_date' label={__('Grant date')}/>
        <VestingSchedule />
        <AwardCategory />
        <Country type='options'/>
        <JobTitle type='options'/>
        <Status />
    </div>
}