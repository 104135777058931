import React, { Component } from 'react';
import { Table} from 'antd';
import { formatCurrencyNumber } from 'Utils';
import {formatDate} from 'Utils/format/formatDate'
import moment from "moment";

const stringOrderBy = (a, b, key) => {
    const stringA = a[key].toUpperCase();
    const stringB = b[key].toUpperCase();
    return stringA < stringB ? -1 : stringA > stringB ? 1 : 0;
}
class FMVTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataSource: [],
            columns: [
                {
                    title: __('Fair market value'),
                    dataIndex: 'fair_market_value',
                    align: 'left',
                    width: '25%',
                    render: val => formatCurrencyNumber(val),
                    sorter: (a, b) => a.fair_market_value - b.fair_market_value,
                },
                {
                    title: __('Source'),
                    dataIndex: 'valuation_source',
                    align: 'left',
                    width: '20%',
                    sorter: (a, b) => stringOrderBy(a, b, 'valuation_source'),
                },
                {
                    title: __('Status'),
                    dataIndex: 'status',
                    align: 'left',
                    width: '20%',
                    sorter: (a, b) => a.status - b.status,
                    render: (text, value) => {
                        return <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'noWrap' }}>
                            {value.status == '0' && <div className="file_text red-disc">&nbsp;&nbsp;&nbsp;&nbsp;Pending</div >}
                            {value.status == '1' && <div className="file_text green-disc">&nbsp;&nbsp;&nbsp;&nbsp;Complete</div >}
                        </div>
                    }
                },
                {
                    title: __('Effective date'),
                    dataIndex: 'effective_date',
                    align: 'left',
                    width: '20%',
                    sorter: (a, b) => {
                        const dataA = moment(a.effective_date, 'DD/MM/YYYY').format('YYYYMMDD');
                        const dataB = moment(b.effective_date, 'DD/MM/YYYY').format('YYYYMMDD');
                        return dataA - dataB;
                    },
                    render: (text,val) => formatDate(val.effective_date),
                    
                },
                {
                    title: __('Expiration date'),
                    dataIndex: 'expiration_date',
                    align: 'left',
                    width: '10%',
                    sorter: (a, b) => {
                        const dataA = moment(a.expiration_date, 'DD/MM/YYYY').format('YYYYMMDD');
                        const dataB = moment(b.expiration_date, 'DD/MM/YYYY').format('YYYYMMDD');
                        return dataA - dataB;
                    },
                    render: (text,val) => formatDate(val.expiration_date),
                },
            
            ],
        }
    }

    payView = () => {
        const { payOverView } = this.props
        payOverView()
    }

    render() {
        const { columns, } = this.state
        const { dataSource } = this.props
        return (
            <div>
                <Table
                    rowClassName={record => {
                        if (record.status == 0) return 'tablecolor';
                    }}
                    columns={columns}
                    pagination={false}
                    dataSource={dataSource}
                    rowKey={record => record.id}
                />
            </div>
        )
    }
}
export default FMVTable;