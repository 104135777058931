import React, { Component } from "react";
import { Table } from 'antd';
import { formatCurrencyNumber } from 'Utils';

class BreakpointsModels extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stakeholderColumns : [
                {
                    title : __('Description'),
                    align : 'left',
                    dataIndex : 'description',
                    key : 'description',
                    render: (text, record) => (
                        <div>{text}</div>
                    )
                },
                {
                    title : __('From'),
                    align : 'right',
                    dataIndex : 'from',
                    key : 'from',
                    render: (text, record) => (
                        <div>{formatCurrencyNumber(text)}</div>
                    )
                },
                {
                    title : __('To'),
                    align : 'right',
                    dataIndex : 'to',
                    key : 'to',
                    render: (text, record) => (
                        <div>{formatCurrencyNumber(text)}</div>
                    )
                },
                {
                    title : __('Delta'),
                    align : 'right',
                    dataIndex : 'delta',
                    key : 'delta',
                    render: (text, record) => (
                        <div>{formatCurrencyNumber(text)}</div>
                    )
                },
                {
                    title : __('Value in tier'),
                    align : 'right',
                    dataIndex : 'valueInTier',
                    key : 'valueInTier',
                    render: (text, record) => (
                        <div>{formatCurrencyNumber(text)}</div>
                    )
                }
            ]
        }
    }

    render() {
        const { breakPointsList, loading } = this.props;
        return (
            <Table
                className="waterfall-stakeholders-table breakpoints-table"
                size="middle"
                pagination={false}
                columns={this.state.stakeholderColumns}
                dataSource={breakPointsList}
                rowKey="keyId"
                loading={loading}
            />
        );
    }
}
export default BreakpointsModels;