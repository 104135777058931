import React, { Component } from "react";
import TemplateList from "./components/TemplateList";
import TemplateDetail from "./components/TemplateDetail";
import TemplateEdit from "./components/TemplateEdit";
import TemplateDocusignEdit from "./components/TemplateDocusignEdit";
import "./style.scss";

class DocusignTemplate extends Component {

    state = {
        page: 1,
        html: '',
        id: 0,
        pageCount: 1,
    }

    componentDidMount() {
        const { page } = this.props;
    }

    saveState = data => {
        this.setState(data)
    };

    getContent = () => {
        const { page, id, html, pageCount } = this.state;
        const { saveIsHideBar, updateActiveKey } = this.props;
        const returnObj = {
            1: <TemplateList saveIsHideBar={saveIsHideBar} saveState={this.saveState} updateActiveKey={updateActiveKey} />,
            2: <TemplateDetail saveIsHideBar={saveIsHideBar} saveState={this.saveState} id={id} />,
            3: <TemplateEdit saveIsHideBar={saveIsHideBar} saveState={this.saveState} id={id} html={html} pageCount={pageCount} />,
            4: <TemplateDocusignEdit saveIsHideBar={saveIsHideBar} saveState={this.saveState} />
        };
        return returnObj[page];
    }

    render() {
        return (
            <div className="docusign-template">{this.getContent()}</div>
        )
    }
}
export default DocusignTemplate;