import React, { Component, Fragment } from 'react';
import { Table, Button, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import openAddBoardModal from '../components/AddBoardModal';
import openResendModal from '../components/ResendEmail';
import openDeleteUserModal from '../components/DeleteAccountUser';
import Permission from 'components/Permission';
import { post } from 'srcPath/http';
import Modal from 'components/Modal';
import Dot from 'components/Dot';
import { constData } from 'Utils';
import { formatDate } from 'Utils/format/formatDate'
import { sprintf, printf } from 'Utils/i18n';
import './style.scss';

const renderValue = val => val || '--'

const statusMap = {
	0: <Dot color="gray">{__('Past member')}</Dot>,
	1: <Dot color="green" >{__('Current member')}</Dot>
}

const registerMap = {
	0: <Dot color="red">{__('Not yet')}</Dot>,
	1: <Dot color="green" >{__('Yes')}</Dot>
}

export default class AccountUsers extends Component {
	state = {
		loading: true,
		list: [],
		resendMembers:''
	}

	componentDidMount() {
		this.getData()
		this.resendMembers()
	}

	resendMembers = async () => {
		this.setState({ loading: true })

		try {
			const { data: resData } = await post('getResendEmailRule', { type: 2 })

			const { data, code } = resData || {}

			const { interval } = data || {}
			if (code == 0) {
				this.setState({ resendMembers: interval, loading: false })
			} else {
				message.error(data)
			}
		} catch (err) {
			message.error(err && err.message)
		}

		this.setState({ loading: false })
	}
	getData = async () => {
		this.setState({ loading: true })

		try {
			const { data: resData } = await post('getBoardMembers')

			const { data = [], code } = resData || {}

			if (code == 0) {
				// status = 0: 过期
				const filterData = data.filter(item => +item.status !== 0)
				if (data.length === 0 || !filterData.length) {
					this.modalRef = Modal.open({
						component: props => <div>
							<p>{sprintf(__('Welcome to the Board Manager.  You will need to add members to conduct any board business.  Please add your board member(s) %s .'), <a onClick={() => {
								openAddBoardModal()
								props.close()
							}}>{__('here')}</a>)}</p>

							<div style={{ textAlign: 'center', marginTop: 20 }}><Button type="primary" onClick={props.close}>{__('Not now')}</Button></div>
						</div>,
						className: 'sprout-modal sprout-notification-modal',
					})
				}
				this.setState({ list: data, loading: false })
			} else {
				message.error(data)
			}
		} catch (err) {
			message.error(err && err.message)
		}

		this.setState({ loading: false })
	}

	columns = [
		{
			title: __('Name'),
			dataIndex: 'name',
			render: renderValue,
			className: 'nowrap',
			fixed: true
		},
		{
			title: __('Email'),
			dataIndex: 'email',
			render: renderValue,
			className: 'nowrap'
		},
		{
			title: __('Role'),
			dataIndex: 'role',
			render: text => renderValue(constData.boardRole[text]),
			className: 'nowrap'
		},
		{
			title: __('Start date'),
			dataIndex: 'start_date',
			render: formatDate,
			className: 'nowrap'
		},
		{
			title: __('End date'),
			dataIndex: 'end_date',
			render: formatDate,
			className: 'nowrap'
		},
		{
			title: __('Status'),
			dataIndex: 'status',
			className: 'nowrap',
			render: text => renderValue(statusMap[text])
		},
		{
			title: __('Registered'),
			dataIndex: 'isRegistered',
			className: 'nowrap',
			render: text => renderValue(registerMap[text])
		},
		{
			title: '',
			align: 'right',
			className: 'nowrap',
			render: (text, record = {}) => <Fragment>
				{record.isRegistered == 0 && <Permission type="company_permission_board_add"><Button type="link" className="sprout-btn-divider" onClick={() => openResendModal(this.getData, record, 'resendEmail2BoardUser')}>{__('Resend')}</Button></Permission>}
				{record.status == 1 && <Permission type="company_permission_board_edit"><Button type="link" className="sprout-btn-divider" onClick={() => openAddBoardModal(this.getData, record,this.state.resendMembers)} >{__('Edit')}</Button></Permission>}
				<Permission type="company_permission_board_resend"><Button type="link" danger onClick={() => openDeleteUserModal(this.getData, { ...record, isBoard: true })} >{__('Delete')}</Button></Permission>
			</Fragment>
		},
	]

	render() {
		const { loading, list = [], resendMembers } = this.state

		return <div className="permission-board">
			<div className="bannere">
				<Permission type="company_permission_board_add"><Button type="primary" onClick={() => openAddBoardModal(this.getData,'',resendMembers)} ><PlusOutlined />{__('Add member')}</Button></Permission>
			</div>
			<div className="contenet">
				<Table scroll={{ x: true }} rowKey="id" columns={this.columns} dataSource={list} pagination={false} loading={loading} />
			</div>
		</div>
	}
}