import React, { Component } from 'react';
import { Select } from 'antd';
import { observer } from 'mobx-react';
import security from 'stores/security';

@observer
export default class CurrencySelector extends Component {
	render() {
		const { currency_list = [] } = security.constant || {}

		return <Select
			showSearch
			placeholder="Select currency"
			{...this.props}
			filterOption={(input, option) => {
				console.log(option)
				const children = option.children.props.children;

				return (
					`${children[0].props.children}${children[1].props.children}`
						.toLowerCase()
						.indexOf(input.toLowerCase()) >= 0
				);
			}}

		>
			{
				currency_list.map((val, index) => <Select.Option key={val.value} value={val.value}>
					<div>
						<span>{val.name}</span>
						<span>{` (${val.value})`}</span>
					</div>
				</Select.Option>)
			}
		</Select>
	}
}