import React, {Component, Fragment, createRef} from "react";
import {Button, Collapse, Space} from "antd";
import ConfirmEmailModels from "../ConfirmEmailModels";
import arrowBottom from "./images/arrow-bottom.png"
import emailIcon from "./images/email-icon.png"
import "./style.scss";

class ConfirmInviteSelectedModels extends Component {

    state = {
        iconBottom: 1,
    };

    selectEmail = () => {
        const { selectEmail } = this.props;
        return selectEmail && selectEmail.map((v, k)=>{
            return (
                <dd><img src={emailIcon} />{v}</dd>
            )
        });
    }

    resendStakeholderEmail = () => {
        const { resendStakeholderEmail, close, id } = this.props;
        resendStakeholderEmail ? resendStakeholderEmail(id, close) : close();
    }

    closeModel = () => {
        const { close } = this.props;
        close();
    }

    getContent = () => {
        const { content } = this.props;
        return content && content.map(v=><p className="confirm-invite-title">{v}</p>);
    }

    templateChange = () => {
        let { iconBottom } = this.state;
        this.setState({iconBottom: iconBottom === 1 ? 0 : 1})
    }

    render() {
        const { iconBottom } = this.state;
        const { userAccessControl, selectEmail, button, stakeholderId } = this.props;
        return (
            <div>
                {
                    userAccessControl > 0 ? (
                        <Fragment>
                            <div class="confirm-invite-top">
                                <Space size={14} direction="vertical">
                                    {this.getContent()}
                                </Space>
                                {selectEmail ? <div class="confirm-invite-email">
                                    <dl>{this.selectEmail()}</dl>
                                </div>: ''}
                                <div className="confirm-invite-button">
                                    <Space size={110}>
                                        <Button onClick={this.closeModel}>{button?.[0] ? button[0] : __('Cancel')}</Button>
                                        <Button type="primary" onClick={this.resendStakeholderEmail}>{button?.[1] ? button[1] : __('Ok')}</Button>
                                    </Space>
                                </div>
                            </div>
                            <Collapse
                                className="confirm-invite-template"
                                ghost
                                onChange={this.templateChange}
                            >
                                <Collapse.Panel
                                    showArrow={false}
                                    header={(
                                        <div>{__('Email template')}
                                            <img className={iconBottom === 1 ? 'confirm-invite-icon-bottom' : 'confirm-invite-icon-top'} src={arrowBottom} />
                                        </div>
                                    )}
                                    forceRender={true}
                                >
                                    <ConfirmEmailModels stakeholderId={stakeholderId} />
                                </Collapse.Panel>
                            </Collapse>
                        </Fragment>
                    ) : (
                        <div class="confirm-invite-top confirm-invite-top-mb">

                            <p className="confirm-invite-title">{__('Resend invite is not enabled.')}<br/>{__('Please go to the Setting page to turn on the Stakeholder Access to enable it.')}</p>
                            <div className="confirm-invite-button">
                                <Button type="primary" onClick={this.closeModel}>{__('Ok')}</Button>
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}
export default ConfirmInviteSelectedModels;