import React from 'react';
import { FormItem } from 'react-antd-formutil';
import { Checkbox } from 'antd'
import { omit } from 'lodash';
import './style.scss';

const options = ['ISO','NSO','INTL']

const Group = Checkbox.Group

export default function Relationship(props) {
    const { name, label } = props

    return <div className="filter-relationship"><FormItem
        name={name || 'type'}
        label={label || __('Type')}
    >
        <Group {...omit(props, ['name', 'label'])} showSearch placeholder={__('Select')} options={options} />
    </FormItem>
    </div>
}