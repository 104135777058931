import React, { Component } from "react";
import {Button} from "antd";
import security from "stores/security";
import "./style.scss";

class Popup extends Component {

    state = {
        content: {
            maxNumber: 'The maximum number of users has been exceeded and cannot be created!',
            auth: ' is a premium feature, upgrade your plan and enjoy the feature for free.'
        }
    }

    getBilling = () => {
        this.props.history.push('/home/setting/billing');
    }

    render() {
        const { content } = this.state;
        const { authName, contentType } = this.props;

        return (
            <div className="no-permission-float-root">
                <p>{authName}{content[contentType]}</p>
                {security.flag == 1 ? <Button type="primary" onClick={()=>this.getBilling()}>Unlock</Button> : <div className="no-permission-float-root-bottom"></div>}
            </div>
        );
    }
}
export default Popup;