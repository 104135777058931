import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Warrants from './Warrants';
import DraftWarrants from './DraftWarrants';

import security from 'stores/security';

export default class Security extends Component {
    path = this.props.match.path

    config = [
        {
            path: `${this.path}`,
            component: Warrants,
            permission: 'securities_warrants',
            exact: true,
        },
        {
            path: `${this.path}/draft-warrants`,
            component: DraftWarrants,
            permission: 'securities_draftWarrants',
            exact: true,
        },
    ]
    

    render() {
        return <div className="sprout-captable-root sprout-token-captable-root">
            <Switch>
                {
                    this.config.map((val, index) => security.hasPermission(val.permission) && <Route key={index} exact={val.exact} path={val.path} component={val.component} />)
                }
            </Switch>
        </div>
    }
}