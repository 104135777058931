import React, { Component } from 'react';
import SimpleTable from '../components/SimpleTable';
import { formatDate } from 'Utils/format/formatDate';
import { formatCurrencyNumber } from 'Utils';
import formatFractionalNumber from "../../../modules/Utils/format/formatFractionalNumber";

export default function ExercisePeriods(props) {
    const { data, accountUsers } = props;
	const { is_in_option_awards, payment_collected, payment_method, payment_date, payment_date_moment, payment_verified_admin_id,
        amount_reimbursed, amount_reimbursement_date, amount_reimbursement_date_moment, actual_exercise_price } = data || {};

    const getAdminName = () => {
        const index = accountUsers.findIndex(v=>v.id == payment_verified_admin_id);
        return index !== -1 ? accountUsers[index].name : '';
    }

	const config = [
		{
			label: __('Payment date'),
			value: payment_date && formatDate(payment_date)
		},
		{
			label: __('Payment collected'),
			value: formatCurrencyNumber(payment_collected),
		},
        {
            label: __('Payment method'),
            value: payment_method ? payment_method : '--',
        },
		{
			label: __('Payment verified by'),
			value: payment_verified_admin_id && getAdminName(),
		},
        {
            label: __('Prepay price'),
            value: formatCurrencyNumber(actual_exercise_price),
        },
		{
			label: __('Amount reimbursed by company'),
			value: amount_reimbursed
		},
        {
            label: __('Amount reimbursement date'),
            value: amount_reimbursement_date && formatDate(amount_reimbursement_date)
        }
	];

	return <div className="certificate-detail-section exercise-details-section">
		<SimpleTable title="Payment information" config={config} />
	</div>
}