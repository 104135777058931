import React, { Component } from "react";
import { Button } from "antd";
import "./style.scss";
import StartTrialImg from "../Public/img/start-trial-background.png";
import moment from 'moment';
import { I, formatNumber } from 'Utils';
import {LeftOutlined} from "@ant-design/icons";

class StartTrial extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentDate: moment().format("DD MMM, YYYY"),
        }
    }

    /**
     * 创建订单
     */
    createOrder = () => {
        const { createOrder } = this.props;
        createOrder();
    }

    getNextPage = (type) => {
        const { updateTabCurrentType } = this.props;
        updateTabCurrentType(type);
    }

    /**
     * 统计账单金额及展示明细
     * @returns {*}
     */
    invoiceInfo = () => {
        const { itemList, currency } = this.props;
        console.log(itemList);
        return itemList.map( ( v) => {
            const price = isNaN(parseFloat(v.price)) ? '0.00' : I(v.price, 0);
            return (
                <div className="start-trial-detail-info">
                    <span>{v.plan_name}</span>
                    <span><b>{ `$${formatNumber(parseFloat(price), 2)}` }</b> {currency}</span>
                </div>
            )
        });
    }

    render() {
        const { currentDate } = this.state;
        const { total, loading, currency, itemList } = this.props;
        return (
            <div className="start-trial-root">
                <div className="start-trial-banner">
                    <div className="start-trial-background">
                        <img src={StartTrialImg} />
                        <div className="start-trial-title">Start Date</div>
                        <div className="start-trial-time">{currentDate}</div>
                    </div>
                </div>
                <div className="start-trial-content">
                    <div className="start-trial-detail">
                        <dl>
                            <dt>{itemList[0].plan_name}</dt>
                            <dd>
                                <div className="start-trial-solid mt-15"></div>
                                {this.invoiceInfo()}
                                <div className="start-trial-solid mt-10"></div>
                                <div className="start-trial-detail-info">
                                    <span>Total</span>
                                    <span><b>${formatNumber(parseFloat(total), 2)}</b> {currency}</span>
                                </div>
                            </dd>
                        </dl>
                    </div>
                    <div className="start-trial-desc">
                        By pressing "Confirm and Pay" button, you acknowledge that you have read our <a href="https://www.getsprout.co/legal/pps/" target="_blank">Privacy Policy</a> and agree to our <a href="https://www.getsprout.co/legal/tos/" target="_blank">Terms of Service</a>.
                    </div>
                    <div className="start-trial-content-button">
                        <div className="start-trial-content-button-back" onClick={this.getNextPage.bind(this, 1)} >
                            <LeftOutlined className="start-trial-content-button-back-icon" />
                        </div>
                        <div className={['start-trial-button-bac', (loading && 'button-loading')]. join(' ')}>
                            <Button loading={loading} onClick={this.createOrder} type="primary" className="start-trial-button" >Confirm and Pay</Button>
                        </div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
        );
    }
}
export default StartTrial;