import React, { useEffect, useState } from 'react';
import { Select, message } from 'antd';
import { post } from 'srcPath/http';

const Option = Select.Option

export default function WalletLabel(props) {
	const [option, setOption] = useState([])

	useEffect(() => {
		const getOptions = async () => {
			try {
				const { data: resData } = await post('getWalletLabelList')

				const { code, data } = resData || {}

				if (code === 0) {
					setOption(data)
				} else {
					message.error(data)
				}
			}catch(e) {
				message.error(e.message)
			} 
		}

		getOptions()
	}, [])

	return <Select 
		{...props}
		showSearch
		filterOption={(inputValue, options) => (options.children??'').toLowerCase().includes((inputValue??'').toLowerCase())}
	>
		{
			option.map((val, index) => {
				return <Option key={index} value={val.id}>{val.name}</Option>
			})
		}
	</Select>
}