import React, { useState, useEffect } from 'react';
import { Table, Button, message, Form, Input, Row, Col, AutoComplete } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import { cloneDeep } from 'lodash';
import { post } from 'srcPath/http';
// import SelectStakeholder from 'components/SelectStakeholder'
import SelectAccount from 'components/SelectAccount'
import { FormItem, withForm } from 'react-antd-formutil';
import { accessColumns } from './columns';
import icon from './images/user-group-icon.png';
import addPlus from './images/add-plus.png';
import './style.scss'


function AccessCom(props) {
    const emailReg = new RegExp('(?![\\-_.])[\\w\\-.]+@(?:(?![\\-_])[\\w\\-\u4e00-\u9fa5]{1,63}\\.)+[A-Za-z0-9]{2,}')
    const { data = {} } = props;
    const { access = [], id, name, client_name } = data
    const [userList, setUserList] = useState([{ name: '', email: '' }])
    const [accessList, setAccessList] = useState([])
    const [loading, setLoading] = useState(false)
    const { $formutil } = props
    const [selectList, setSelectList] = useState([{ name: '', email: '' }])
    useEffect(() => {
        fetchAccessByFolder()
        getAccountUsers()
    }, [])

    const fetchAccessByFolder = async () => {
        const { $setValues, $reset } = $formutil
        setLoading(true)
        const { access = [], id, name, client_name } = data
        const params = {
            dir_id: id || 0,
        }
        try {
            const { data: resData } = await post('getAccessForFolder', params)

            const { code } = resData || {}
            if (code === 0) {
                const accessData = (resData?.data || [])?.filter(i => i)
                setAccessList(accessData || [])
                $reset()
                userList.forEach((i, index) => {
                    $setValues({
                        [`name_${index}`]: '',
                        [`email_${index}`]: ''
                    })
                })
                setUserList([{ name: '', email: '' }])
            }
        } catch (err) {
            message.error(err & err.message)
        }
        setLoading(false)
    }
    const getAccountUsers = async () => {
        setLoading(true)
        try {
            const { data: resData } = await post('getAccountUsers', {
                keyword: '',
            })
            const { data, code } = resData || {}
            if (code === 0) {
                setSelectList(data)
            } else {
                message.error(data)
            }
        } catch (err) {
            message.error(err && err.message)
        }
        setLoading(false)
    }

    const handleSubmit = async () => {
        const { $invalid, $batchDirty, $params, $getFirstError, $setErrors } = $formutil
        if ($invalid) {
            $batchDirty(true)
            message.error($getFirstError())
            return
        }
        const newUserList = []
        userList.forEach((user, index) => {
            newUserList.push(
                {
                    name: $params[`name_${index}`],
                    email: $params[`email_${index}`],
                }
            )
        })
        let isErr = true
        let errorObj = {}
        newUserList.forEach((item, i) => {
            if (!emailReg.test(item.email)) {
                isErr = false
                errorObj[`email_${i}`] = {
                    required: __('Email is invalid')
                }
            }
        })
        if (!isErr) {
            $setErrors(errorObj)
            return
        }
        const params = {
            dir_id: id || 0,
            userList: newUserList,
            title: 'viewer'
        };
        try {
            const { data: resData } = await post('addDataRoomPermissions', params)

            const { code } = resData || {}

            if (code === 0) {
                const data = resData.data || []
                let hasIssue = false
                const errorObj = {}
                data.forEach((i, index) => {
                    console.info(i.status !== '0', 12222, $params)
                    if (i.status !== '0') {
                        hasIssue = true
                        errorObj[`email_${index}`] = {
                            required: i?.message
                        }
                    }
                })
                $setErrors(errorObj)
                if (!hasIssue) {
                    message.success('Success!')
                    fetchAccessByFolder()
                    // nextStep()
                } else {
                    $batchDirty(true)
                    message.error($getFirstError())
                }
            }
        } catch (err) {
            message.error(err & err.message)
        }
    }

    // const nextStep = () => {
    //     props.onReload && props.onReload()
    // }

    const handleClickRow = async (record, dirId, e) => {
        const { id } = record
        const action = e?.target?.innerText
        if (action && action === 'Yes') {
            const params = {
                admin_id: id,
                dir_id: dirId || 0
            }
            try {
                const { data: resData } = await post('removeDataRoomPerson', params)
                const { code } = resData || {}
                if (code == 0) {
                    message.success('Remove success!')
                    fetchAccessByFolder()
                }
            } catch (err) {
                message.error(err && err.message)
            }
        }
    }
    const removeUser = (index, user) => {
        const { $invalid, $batchDirty, $params, $getFirstError, $setValues, $setErrors } = $formutil
        const newUserList = cloneDeep(userList)
        const newParams = { ...$params, }
        newUserList.map((i, idx) => {
            if (idx >= index) {
                newParams[`name_${idx}`] = newParams[`name_${idx + 1}`]
                newParams[`email_${idx}`] = newParams[`email_${idx + 1}`]
            }
        })

        $setValues({
            ...newParams,
        })
        const newUserLists = userList.filter((val, idx) => idx !== index)
        setUserList(newUserLists)

        console.info(newParams, 'newParams')

    }

    const addUser = () => {
        const newUserList = cloneDeep(userList)
        newUserList.push({
            name: '',
            email: '',
        })
        setUserList(newUserList)
    }

    const handleSelectChange = (name, data, index, isEmail) => {
        const { $setValues, $params } = $formutil
        if (isEmail && !data?.name) {
            $setValues({
                ...$params,
                [`email_${index}`]: data.email || ''
            })
        } else if (!isEmail && !data?.email) {
            $setValues({
                ...$params,
                [`name_${index}`]: data.name || ''
            })
        } else if (data?.name && data?.email) {
            $setValues({
                ...$params,
                [`name_${index}`]: data.name || '',
                [`email_${index}`]: data.email || ''
            })
        }


        const newUserList = []
        userList.forEach((user, index) => {
            newUserList.push(
                {
                    name: $params[`name_${index}`],
                    email: $params[`email_${index}`],
                }
            )
        })
        setUserList(userList, 'userList')
    }


    const selectChange = (value, index) => {
        const { $setValues, $params } = $formutil
        $setValues({
            [`name_${index}`]: selectList[value].name || '',
            [`email_${index}`]: selectList[value].email || ''
        })
    }

    const AccountUsers = () =>{
        props.onReload()
        props.props.history.push('/home/permissions')
    }

    return (
        <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
            <div className="access-add-container-new">
                <div className="access-add-content">
                    <img src={icon} alt="img" />
                    <div className="access-add-content-desc">
                        <p>{__('Account users and board members have automatic access to this Documents and its content based on company permissions.')}.</p>
                        <p>{__('Can view Documents: “Board observer” permission')} <br />
                            {__('Can edit Documents: “Super admin”, “Company editor”, “Board admin” and “Board director” permissions')}</p>
                    </div>
                    {/* <div className="access-add-content-btn-area"><a href="/home/permissions/board"><Button type="primary" className="access-add-content-btn">View Account Users</Button></a></div> */}
                </div>
                <div className="access-add-table-content">
                    <Table
                        pagination={false}
                        columns={accessColumns}
                        dataSource={accessList}
                        loading={loading}
                        scroll={{ x: true, y: 200 }}
                        locale={{ emptyText: 'No Data' }}
                        onRow={record => {
                            return {
                                onClick: (e) => handleClickRow(record, id, e),
                            };
                        }}
                    />
                </div>
            </div>
            <div className="invited-people-table">
                <h3 className="access-add-header">{__('Invite users to')} {name ? name : client_name ? __('Documents drive') : __('Documents drive')}</h3>
                <p className="access-add-desc">{__('The users you invite from here will be added as board members with “Board observer” permission by default. You can edit the user information via')} <a onClick={AccountUsers}>{__('Permissions and roles > Board.')}</a>.</p>
                <div>
                    {userList.map((user, index) => {
                        return (
                            <div className="invited-people-form">
                                <Row type="flex" justify="start">
                                    <Col span={11}>
                                        <FormItem
                                            name={`name_${index}`}
                                            colon={false}
                                            label={__('Name')}
                                            required
                                            validMessage={{
                                                required: __('Name is required')
                                            }}
                                        >
                                            <AutoComplete
                                                className="billing-contacts-card-select"
                                                placeholder={__('Select or Enter')}
                                                onSelect={(val) => selectChange(val, index)}
                                                filterOption={(inputValue, option) => {
                                                    const { children } = option.props.children.props.children[0].props;
                                                    return children && children.indexOf(inputValue) >= 0;
                                                }}
                                                allowClear
                                            >
                                                {
                                                    selectList.map((v, k) => (
                                                        <AutoComplete.Option key={k} value={k}>
                                                            <div className="billing-contacts-card-select-option">
                                                                <span style={{ marginRight: 10 }}>{v.name}</span>
                                                                <span>{v.email}</span>
                                                            </div>
                                                        </AutoComplete.Option>
                                                    ))
                                                }
                                            </AutoComplete>
                                        </FormItem>
                                    </Col>
                                    <Col span={11} style={{ marginLeft: 10 }}>
                                        <FormItem
                                            name={`email_${index}`}
                                            label={__('Email')}
                                            required
                                            colon={false}
                                            // pattern={emailReg}
                                            validMessage={{
                                                required: __('Email is required')
                                            },  { pattern:new RegExp('^[0-9a-zA-Z_]{1,}$','g'),message:'只允许包含数字、字母、下划线'}}
                                        >
                                            <AutoComplete
                                                className="billing-contacts-card-select"
                                                placeholder={__('Select or Enter')}
                                                onSelect={(val) => selectChange(val, index)}
                                                filterOption={(inputValue, option) => {
                                                    const { children } = option.props;
                                                    return children && children.indexOf(inputValue) >= 0;
                                                }}
                                                allowClear
                                            >
                                                {
                                                    selectList.map((v, k) => (
                                                        <AutoComplete.Option key={k} value={k}>{v.email}</AutoComplete.Option>
                                                    ))
                                                }
                                            </AutoComplete>
                                        </FormItem>
                                    </Col>
                                    {(index !== 0) && <Col span={1} style={{ marginLeft: 10, textAlign: 'right' }}>
                                        <Button icon={<MinusOutlined />} onClick={() => removeUser(index, user)} />
                                    </Col>}
                                </Row>
                            </div>
                        )
                    })}
                </div>
                <div className="invited-people-operate" onClick={addUser}><img src={addPlus} /><span>{__('New user')}</span></div>
            </div>
            <div className="invited-people-btn">
                <Button onClick={handleSubmit} type="primary" htmlType="submit" className="access-invite-btn">{__('Invite')}</Button>
            </div>
        </div >
    )
}

export default withForm(AccessCom)