import React, { Component, Fragment } from 'react';
import {  Collapse, message } from 'antd';
import { formatPercent, formatCurrencyNumber } from 'Utils';
import formatShares from 'Utils/format/formatShares';
import { post } from 'srcPath/http';
import security from 'stores/security';
import openCertificateModal from 'components/CertificateDetail';
import commonImg from '../../../../images/common.png';
import preferredImg from '../../../../images/preferred.png';
import optionImg from '../../../../images/option.png';
import convertibleImg from '../../../../images/convertible.png';

import Loading from 'components/Loading';
import './style.scss';

const { Panel } = Collapse;

const renderLabels = (number, label, decimal = 0) => <>
	<span>{formatShares(number)}</span>&nbsp;
	<span>{ number > 1 ? `${label}s` : label }</span>
</>


export default class Captable extends Component {
	state = {
		loading: true,
		totalFullyDiluted: 1,
		totalOutstanding: 1
	}

	componentDidMount() {
		this.getData()
	}

	getData = async () => {
		this.setState({ loading: true })

		try {
			const { data: resData } = await post('getProfileCaptable', {
				stakeholder_id: security.companyInfo && security.companyInfo.stakeholder_id
			})

			const { code, data } = resData || {}
			const { total = {} } = data || {}

			if (code === 0) {
				this.setState({ data, totalFullyDiluted: total.fullyDiluted, totalOutstanding: total.outstanding })
			}

		} catch (err) {
			message.error(err.message)
		}

		this.setState({ loading: false })
	}

	renderCommonHeader = data => {
		const { shareClassName, sharesCount, outstanding, authorized, fullyDiluted } = data || {}
		const { totalFullyDiluted, totalOutstanding } = this.state

		return <div className="certificate-content-header">
			<div className="header-name">
				<div>{shareClassName}</div>
				<div className="description">
					{
						renderLabels(sharesCount, 'certificate')
					}
					<span> • </span>
					<span>{formatShares(authorized)} </span>
					<span>{__('authorized')}</span>
				</div>
			</div>
			<div className="table-td">{formatShares(outstanding)}</div>
			<div className="table-td">{formatPercent(outstanding/totalOutstanding)}</div>
			<div className="table-td">{formatShares(fullyDiluted)}</div>
			<div className="table-td">{formatPercent(fullyDiluted/totalFullyDiluted)}</div>
		</div>
	}

	renderCommonHeader2 = data => {
		const { ownerName, outstanding, count, fullyDiluted } = data || {}
		const { totalFullyDiluted, totalOutstanding } = this.state

		return <div className="certificate-content-header">
			<div className="header-name">
				<div>{ownerName}</div>
				<div className="description">
					{
						renderLabels(count, 'certificate')
					}
				</div>
			</div>
			<div className="table-td">{formatShares(outstanding)}</div>
			<div className="table-td">{formatPercent(outstanding/totalOutstanding)}</div>
			<div className="table-td">{formatShares(fullyDiluted)}</div>
			<div className="table-td">{formatPercent(fullyDiluted/totalFullyDiluted)}</div>
		</div>
	}

	renderCommonItem = (data, isPreferred) => {
		const { certificateName, issuedDate, outstanding, fullyDiluted, certificateId } = data || {}
		const { totalFullyDiluted, totalOutstanding } = this.state

		return <div className="certificate-content-item">
			<div className="item-name" onClick={() => openCertificateModal(certificateId)}>
				<img src={ isPreferred ? preferredImg : commonImg} width="40" height="32" />
				<div className="name">
					<div>{certificateName} </div>
					<div className="description">{__('Issued')} <span>{issuedDate}</span></div>
				</div>
			</div>
			<div className="table-td">{formatShares(outstanding)}</div>
			<div className="table-td">{formatPercent(outstanding/totalOutstanding)}</div>
			<div className="table-td">{formatShares(fullyDiluted)}</div>
			<div className="table-td">{formatPercent(fullyDiluted/totalFullyDiluted)}</div>
		</div>
	}

	renderOptionHeader = data => {
		const { shareClassName, sharesCount, outstanding, authorized, fullyDiluted } = data || {}
		const { totalFullyDiluted, totalOutstanding } = this.state

		return <div className="certificate-content-header">
			<div className="header-name" title={shareClassName}>
				{shareClassName}
				<div className="description">
					{
						renderLabels(sharesCount, 'grant')
					}
					<span> • </span>
					<span>{formatShares(authorized)} </span>
					<span>{__('authorized')}</span>
				</div>
			</div>
			<div className="table-td">{formatShares(outstanding)}</div>
			<div className="table-td">{formatPercent(outstanding/totalOutstanding)}</div>
			<div className="table-td">{formatShares(fullyDiluted)}</div>
			<div className="table-td">{formatPercent(fullyDiluted/totalFullyDiluted)}</div>
		</div>
	}

	renderWarrantHeader = data => {
		const { shareClassName, sharesCount, authorized, fullyDiluted } = data || {}
		const { totalFullyDiluted } = this.state

		return <div className="certificate-content-header">
			<div className="header-name">
				<div>{shareClassName}</div>
				<div className="description">
					{
						renderLabels(sharesCount, 'grant', null)
					}
					<span> • </span>
					<span>{formatShares(authorized)} </span>
					<span>{__('authorized')}</span>
				</div>
			</div>
			<div className="table-td">--</div>
			<div className="table-td">--</div>
			<div className="table-td">{formatShares(fullyDiluted)}</div>
			<div className="table-td">{formatPercent(fullyDiluted/totalFullyDiluted)}</div>
		</div>
	}

	renderOptionHeader2 = data => {
		const { name, count, fullyDiluted } = data || {}
		const { totalFullyDiluted } = this.state

		return <div className="certificate-content-header">
			<div className="header-name">
				<div>{name}</div>
				<div className="description">
					{
						renderLabels(count, 'grant')
					}
				</div>
			</div>
			<div className="table-td">--</div>
			<div className="table-td">--</div>
			<div className="table-td">{formatShares(fullyDiluted)}</div>
			<div className="table-td">{formatPercent(fullyDiluted/totalFullyDiluted)}</div>
		</div>
	}


	renderOptionItem = (data, isPreferred) => {
		const { certificateName, issuedDate, fullyDiluted, certificateId } = data || {}
		const { totalFullyDiluted } = this.state

		return <div className="certificate-content-item">
			<div className="item-name" onClick={() => openCertificateModal(certificateId)}>
				<img src={optionImg} width="40" height="32" />
				<div className="name">
					<div>{certificateName} </div>
					<div className="description">{__('Issued')} <span>{issuedDate}</span></div>
				</div>
			</div>
			<div className="table-td">--</div>
			<div className="table-td">--</div>
			<div className="table-td">{formatShares(fullyDiluted)}</div>
			<div className="table-td">{formatPercent(fullyDiluted/totalFullyDiluted)}</div>
		</div>
	}

	renderConvertibleHeader = data => {
		const { shareClassName, count, raised, principal, interestRate } = data || {}

		return <div className="certificate-content-header">
			<div className="header-name">
				<div>{shareClassName}</div>
				<div className="description">
					{
						renderLabels(count, 'Convertible debt')
					}
					<span> • </span>
					<span>{formatShares(raised)} </span>
					<span>{__('raised')}</span>
				</div>
			</div>
			<div className="table-td">--</div>
			<div className="table-td">{formatCurrencyNumber(principal)}</div>
			<div className="table-td">{formatShares(interestRate)}</div>
			<div className="table-td">{formatCurrencyNumber(principal)}</div>
		</div>
	}

	renderConvertibleHeader2 = data => {
		const { ownerName, count, principal, interestRate } = data || {}
		const { currency } = this.props.data || {}

		return <div className="certificate-content-header">
			<div className="header-name">
				<div>{ownerName}</div>
				<div className="description">
					{
						renderLabels(count, 'Convertible debt')
					}
				</div>
			</div>
			<div className="table-td">--</div>
			<div className="table-td">{formatCurrencyNumber(principal)}</div>
			<div className="table-td">{formatShares(interestRate)}</div>
			<div className="table-td">{formatCurrencyNumber(principal)}</div>
		</div>
	}

	renderConvertibleItem = (data, isPreferred) => {
		const { certificateName, issuedDate, principal, interestRate, maturityDate, certificateId } = data || {}

		return <div className="certificate-content-item">
			<div className="item-name" onClick={() => openCertificateModal(certificateId)}>
				<img src={convertibleImg} width="40" height="32" />
				<div className="name">
					<div>{certificateName} </div>
					<div className="description">{__('Issued')} <span>{issuedDate}</span></div>
				</div>
			</div>
			<div className="table-td">{maturityDate}</div>
			<div className="table-td">{formatCurrencyNumber(principal)}</div>
			<div className="table-td">{formatShares(interestRate)}</div>
			<div className="table-td">{formatCurrencyNumber(principal)}</div>
		</div>
	}

	render() {
		const { loading, data, totalFullyDiluted } = this.state
		const { accessLevel } = this.props.data || {}
		const { common = [], preferred= [], total = {}, options = {}, warrant = [], convertible = {} } = data || {}
		const { fullyDiluted, outstanding } = total || {}
		const { data: optionsList = [], sharesAvailable: optionsAvailable = [] } = options || {}
		const { list: convertibleList = [], total: convertibleTotal = {} } = convertible || {}

		if (loading) {
			return <Loading />
		}

	// 0: 'No Access',
	// 1: 'Basic view',
	// 2: 'Individual view',
	// 3: 'Summary view',
	// 4: 'Intermediate view',
	// 5: 'Detailed view',


		// 不同的level 可以打开不同的panel

		//   common  preferred  option  warrant  cn
		// 5  true     true      true    true     true
		// 4  false    true      false   false    fasle
		// 3  false    false     false   false    fasle

		return <div className="portfolio-capitalization-captable-root">
				<div className="portfolio-capitalization-title">{__('By Share Class')}</div>
				<div className="capitalization-header-wrap">
					<div className="certificate-title"></div>
					<div className="table-th">{__('Outstanding')}</div>
					<div className="table-th">{__('Ownership')}</div>
					<div className="table-th">{__('Fully diluted')}</div>
					<div className="table-th">{__('Ownership')}</div>
				</div>
				<Collapse>
					{
						common && common.map((value, index) => (
							<Panel key={index + 'common'} header={this.renderCommonHeader(value)} className="common" collapsible={  accessLevel === '5' ? true : 'disabled'}>
								<Collapse>
									{
										value.shareClassDetails && value.shareClassDetails.map((data, itemIndex) => (
											<Panel key={itemIndex} header={this.renderCommonHeader2(data)}>
												{data.listByOwner && data.listByOwner.map((val, key) => <div>{this.renderCommonItem(val)}</div>)}
											</Panel>
										))
									}
								</Collapse>
							</Panel>
						))
					}
					{
						preferred && preferred.map((value, index) => (
							<Panel key={index + 'preferred'} header={this.renderCommonHeader(value)} className="preferred" collapsible={accessLevel === '3' ? 'disabled' : true}>
								<Collapse>
									{
										value.shareClassDetails && value.shareClassDetails.map((data, itemIndex) => (
											<Panel key={itemIndex} header={this.renderCommonHeader2(data)}>
												{data.listByOwner && data.listByOwner.map((val, key) => <div>{this.renderCommonItem(val, true)}</div>)}
											</Panel>
										))
									}
								</Collapse>
							</Panel>
						))
					}
					{
						optionsList && optionsList.map((value, index) => <Fragment>
							<Panel key={index + 'options'} header={this.renderOptionHeader(value)} className="option" collapsible={  accessLevel === '5' ? true : 'disabled'}>
								<Collapse>
									{
										value.listByOwner && value.listByOwner.map((data, itemIndex) => (
											<Panel key={itemIndex} header={this.renderOptionHeader2(data)}>
												{data.certificateDetail && data.certificateDetail.map((val, key) => <div>{this.renderOptionItem(val)}</div>)}
											</Panel>
										))
									}
								</Collapse>
							</Panel>
							<div className="options-available-wrap">
								<div className="options-available-first">{__('Shares available under')} {optionsAvailable[index] && optionsAvailable[index].plan_name}</div>
								<div className="options-available-item">--</div>
								<div className="options-available-item">--</div>
								<div className="options-available-item">{formatShares(optionsAvailable[index] && optionsAvailable[index].size)}</div>
								<div className="options-available-item">{formatPercent((optionsAvailable[index] && optionsAvailable[index].size)/totalFullyDiluted)}</div>
							</div>
						</Fragment>)
					}
					{
						warrant && warrant.map((value, index) => (
						<Panel key={index + 'warrant'} header={this.renderWarrantHeader(value)} className="warrants" collapsible={accessLevel === '5' ? true : 'disabled'}>
							<Collapse>
								{
									value.listByOwner && value.listByOwner.map((data, itemIndex) => (
										<Panel key={itemIndex} header={this.renderOptionHeader2(data)}>
											{data.certificateDetail && data.certificateDetail.map((val, key) => <div>{this.renderOptionItem(val)}</div>)}
										</Panel>
									))
								}
							</Collapse>
						</Panel>
						))
					}
				</Collapse>
				<div className="table-total">
					<div className="table-total-td-first">{__('Total')}</div>
					<div className="table-total-td">{formatShares(outstanding)}</div>
					<div className="table-total-td">{formatPercent(1)}</div>
					<div className="table-total-td">{formatShares(fullyDiluted)}</div>
					<div className="table-total-td">{formatPercent(1)}</div>
				</div>
				{
					(convertibleList && convertibleList.length > 0) && <div className="capitalization-convertible">
						<div className="portfolio-capitalization-title">{__('Convertibles')}</div>
						<div className="capitalization-header-wrap">
							<div className="certificate-title"></div>
							<div className="table-th">{__('Maturity Date')}</div>
							<div className="table-th">{__('Principal')}</div>
							<div className="table-th">{__('Interest')}</div>
							<div className="table-th">{__('Total')}</div>
						</div>
						<Collapse>
							{
								convertibleList && convertibleList.map((value, index) => (
									<Panel key={index + 'convertible'} header={this.renderConvertibleHeader(value)} className="convertible" collapsible={accessLevel === '5' ? true : 'disabled'}>
										<Collapse>
											{
												value.listByOwner && value.listByOwner.map((data, itemIndex) => (
													<Panel key={itemIndex} header={this.renderConvertibleHeader2(data)}>
														{data.certificateDetail && data.certificateDetail.map((val, key) => <div>{this.renderConvertibleItem(val)}</div>)}
													</Panel>
												))
											}
										</Collapse>
									</Panel>
								))
							}
						</Collapse>
						<div className="table-total">
							<div className="table-total-td-first">{__('Total')}</div>
							<div className="table-total-td">--</div>
							<div className="table-total-td">{formatCurrencyNumber(convertibleTotal.principal)}</div>
							<div className="table-total-td">--</div>
							<div className="table-total-td">{formatCurrencyNumber(convertibleTotal.principal)}</div>
						</div>
					</div>					
				}
		</div>
	}
}