import React, { Component } from 'react';
import { Select, message, Spin } from 'antd';
import { post } from 'srcPath/http';
import { debounce, omit } from 'lodash';

const Option = Select.Option;

class SelectAccount extends Component {
    state = {
        options: [],
        loading: false,
        getTimeStap: + new Date(),
        symbol: '',
        defaultOption: [],

    };
    componentDidMount(){
        this.getData('', 1)
    }
    handleSearch = symbol => {
        this.setState({ symbol })

        if (symbol) {
            this.getData(symbol);
        } else {
            this.setState({ options: [] })
        }
    };

    onChange = name => {
        const { options } = this.state;
        const { onChange, isEmail=false } = this.props;
        if (isEmail) {
            onChange &&
                onChange(
                    name,
                    options.find(item => item.email === name)
                );
        } else {
            onChange(
                name,
                options.find(item => item.name === name)
            );
        }
    };

    handleClear = () => {
        this.setState({ options: this.state.defaultOption, getTimeStap: + new Date() })
    };

    onSelect = name => {
        const { options } = this.state;
        const { onSelect, isEmail } = this.props;
        if (isEmail) {
            onSelect &&
            onSelect(
                    name,
                    options.find(item => item.email === name),
                    true,
                );
        } else {
            onSelect(
                name,
                options.find(item => item.name === name),
                false
            );
        }
    };

    getData = debounce(async (val, isInit) => {
        this.setState({ loading: true });
        const { add=true , isEmail=false } = this.props;
        try {
            const { data: resData } = await post('getAccountUsers', {
                keyword: val,
                type: isEmail ? 'mail' : 'name'
            })
            const { data, code } = resData || {}
            if (code === 0) {
                if (data) {
                    const symbolData = isEmail ? { email: this.state.symbol } : { name: this.state.symbol }
                    this.setState({ options: (data && data.length) > 0 ? data : add ? [symbolData] : [], getTimeStap: + new Date() })
                    if (isInit) {
                        this.setState({
                            defaultOption: (data && data.length) > 0 ? data : []
                        })
                    }
                }
            } else {
                message.error(data)
            }
        } catch(err) {
            message.error(err && err.message)
        }

        this.setState({ loading: false });
    }, 300);

    render() {
        const { options = [], loading, getTimeStap } = this.state;
        const { isEmail=false} = this.props;
        return (
            <Select
                {...omit(this.props, 'add')}
                defaultActiveFirstOption={true}
                filterOption={false}
                style={{ width: '100%' }}
                showSearch
                allowClear
                onClear={this.handleClear}
                onSearch={this.handleSearch}
                showArrow={false}
                onChange={this.onChange}
                onSelect={this.onSelect}
                dropdownMatchSelectWidth={false}
                optionLabelProp="value"
                notFoundContent={loading ? <Spin size="small" /> : 'No data'}
            >
                {options.map((item, index) => (
                    <Option key={getTimeStap + index} value={isEmail ? item.email : item.name}>
                        <div style={{ display: 'flex', maxWidth: 600, justifyContent: 'space-between' }}>
                            <div style={{ minWidth: 80, marginRight: 10 }}>{item.name}</div>
                            <div style={{ color: '#1890ff' }} className="text-left whitespace-normal">{item.email}</div>
                        </div>
                    </Option>
                ))}
            </Select>
        );
    }
}

export default SelectAccount;
