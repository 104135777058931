import React from 'react';
import { Radio } from 'antd';

import './style.scss';

// interface Data {
// 	label: string;
// 	value: string;

// }

// interface RadioButtonsProps {
// 	data: Data[];
// }

export default function RadioButtons(props) {
	return <Radio.Group className="sprout-radio-buttons" {...props} buttonStyle="solid" optionType="button" />
}

/* eg:

const radioData = [
	{
		label: 'Transactions',
		value: 'transactions'
	},
	{
		label: 'Tokenholders',
		value: 'tokenholders'
	}
]

const changeTab = (e) => {
	const value = e.target.value

	setTab(value)
}

<RadioButtons value={tab} options={radioData} onChange={changeTab}/>


*/