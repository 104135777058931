import React, { Component, Fragment } from 'react';
import Modal, { ModalContent } from 'components/Modal';
import {message, Table, Input, Button, Dropdown, Menu} from 'antd';
import { cloneDeep } from 'lodash';
import classnames from 'classnames';
import { post } from 'srcPath/http';
import Permission from 'components/Permission';
import Utils, { I } from 'Utils';
import EditDocumentSet from './components/EditDocumentSet';
import DeleteDocumentSet from './components/DeleteDocumentSet';
import { PaperClipOutlined } from '@ant-design/icons';
import { documentsTypeMap } from './config';
import {formatDate} from "Utils/format/formatDate";
import Template from "components/Template"
import { PlusOutlined } from '@ant-design/icons';
import ESignDocumentSet from "./components/ESignDocumentSet";
import lock from 'components/Layout/NavMenu/images/Lock.png';
import security from 'stores/security';
import './style.scss';

const renderValue = val => val || '--'

class Documents extends Component {

	state = {
		loading: false,
		tableData: [],
		filterString: ''
	}

	modalRef = null
    templateModel = null;
	eSignModel = null;
    noPermissionFloatModal = null;
    lockModel = null;

	columns = [
	    {
	      title: __('Document set name'),
	      dataIndex: 'title',
	      className: 'nowrap',
	      render: renderValue
	    },
        {
            title: __('E-Sign'),
            dataIndex: 'is_docusign',
            className: 'nowrap',
            render: text => text == 1 ? 'DocuSign' : '',
        },
	    {
	      title: __('Security type'),
	      dataIndex: 'type',
	      render:  text => renderValue(documentsTypeMap[text] && documentsTypeMap[text].value),
	      className: 'nowrap'
	    },
	    {
	      title: __('Last update'),
	      dataIndex: 'lastModifyDate',
	      className: 'nowrap',
		  render: (text, value) => {return <span>{ formatDate(value.lastModifyDate) }</span>},
		},
	    {
	      title: __('Securities-Tool-Documents'),
	      dataIndex: '',
	      render: (text, record) => this.renderDocuments(record),
	      className: 'nowrap',
	      width: 450
	    },
	    {
	      title: '',
	      render: (text, record) => {
	          const { is_docusign, docusign_type } = record;
	          return (
                  <Fragment>
                      <Button type="link" className="sprout-btn-divider" onClick={ I(is_docusign, '') ? this.getESignDocument.bind(this, 'edit', record, docusign_type) : this.editDocumentSet.bind(this, record)}>{__('Edit')}</Button>
                      <Button type="link" danger onClick={() => this.onDelete(record)}>{__('Delete')}</Button>
                  </Fragment>
              )
          },
	      className: 'nowrap',
	      align: 'right'
	    },
  	]

	componentDidMount() {
		this.getList()
	}

	componentWillUnmount() {
		this.modalRef && this.modalRef.close();
        this.templateModel && this.templateModel.close();
        this.eSignModel && this.eSignModel.close();
        this.noPermissionFloatModal && this.noPermissionFloatModal.close();
        this.lockModel && this.lockModel.close();
	}

	onDelete = value => {
		this.modalRef = Modal.open({
			component: props => <ModalContent title={__("Delete document set")} close={props.close}><DeleteDocumentSet data={value} close={props.close} callback={this.getList} /></ModalContent>,
			className: 'sprout-modal',
			width: 600
		})
	}

    getESignModel = (type, record, docusignType) => {
	    const title = `${type === 'add' ? __('Add E-Sign document set') : __('Edit E-Sign document set')} `;
        this.noPermissionFloatModal = Modal.open({
            component:  props => <ModalContent title={title} close={props.close}>
                <ESignDocumentSet type={type} record={record} docusignType={docusignType} close={props.close} callback={this.getList} />
            </ModalContent>,
            width: 650,
            maskClosable: false,
            className: 'sprout-modal'
        })
    }

    getLockModel = () => {
        this.lockModel = Modal.open({
            component:  props => <ModalContent title="Reminder" close={props.close}>
                <p className="documents-set-model-lock">{__('E-Sign document set is a premium feature, please contact us to unlock the feature.')}</p>
            </ModalContent>,
            width: 460,
            maskClosable: false,
            className: 'sprout-modal'
        })
    }

	getList = async () => {
		this.setState({ loading: true })

		try {
			const { data: resData } = await post('getDocumentSetList')

			const { code, data } = resData

			if (code === 0) {
				this.setState({ tableData: data })
			} else {
				message.error(data)
			}

		} catch(e) {
			message.error(e.message)
		}

		this.setState({ loading: false })
	} 

	openFile = value => {
		const {name, default_name, file_name } = value

		window.open(Utils.getRealUrlForFiles(name || file_name, 'documentset'), 'blank')
	}

    openTemplate = (id) => {
        this.openTemplateModel(id);
    }

    openTemplateModel = (data) => {
        this.templateModel = Modal.open({
            component: props => (
                <ModalContent close={props.close} title={data.name ? data.name : data.file_name}>
                    <Template id={data.id} />
                </ModalContent>
            ),
            width: 900,
            className: 'sprout-modal documents-set-model',
            maskClosable: false
        });
    }

	renderDocuments = record => {
		const { type, documents, isSystem, is_docusign, templateList } = record
		const data = Object.entries((!I(is_docusign, 0) ? documents : templateList) || {});
		return <div>
			{
				data.map((val, index) => <div className="documents-set-table-render" key={index}>
					<div className="documents-set-class">{renderValue(documentsTypeMap[type].file[val[0]])}</div>
                    <div className="documents-set-file-list">{val[1] && (Array.isArray(val[1]) ? val[1].map((item, key) => (<div onClick={() => { !I(is_docusign, '') ? this.openFile(item) : this.openTemplate(item.id) }} className="documents-set-file file-link" key={key}><PaperClipOutlined/> {item.default_name}</div>)) : <span className="file-link" onClick={() => !I(is_docusign, '') ? this.openFile(val[1]) : this.openTemplate(val[1]) }><PaperClipOutlined />{renderValue(val[1] && val[1].default_name)}</span>) || '--'}</div></div>)
			}
		</div>
	}

	docusignTemplateList = (templateList) => {
        return Object.entries(templateList || {})
    }

	handleFilter = value => {
		this.setState({ filterString: value })
	}

	addDocumentSet = () => {
		this.modalRef = Modal.open({
			component: props => <ModalContent title={__('Create document set')} close={props.close}><EditDocumentSet close={props.close} callback={this.getList} /></ModalContent>,
			className: 'sprout-modal',
			width: 650
		})
	}

	editDocumentSet = value => {
		this.modalRef = Modal.open({
			component: props => <ModalContent title={__('Update document set')} close={props.close}><EditDocumentSet defaultValue={value} close={props.close} callback={this.getList} /></ModalContent>,
			className: 'sprout-modal',
			width: 650
		})
	}

    getESignDocument = (type, record, docusignType = 1) => {
	    const { eSign  } = security;
        docusignType = parseInt(docusignType);
	    I(eSign, 0) ? this.getESignModel(type, record, docusignType) : this.getLockModel();
    }

    /**
     * 添加菜单
     * @returns {JSX.Element}
     */
	addMenu = () => {
        const menu = (
            <Menu className="documents-set-banner-menu">

                <Menu.Item onClick={this.getESignDocument.bind(this, 'add', '', 1)} >
                    <div className="documents-set-banner-li">
                        { !I(security.eSign, 0) ? <div className="documents-set-banner-li-img" ><img src={lock} alt="img" className="documents-set-lock" /></div> : ''}
                        <span>{__('Add e-sign document set')}</span>
                    </div>
                </Menu.Item>
                {I(security.eSign, 0) ? <Menu.Item onClick={this.getESignDocument.bind(this, 'add', '', 2)} >
                    <div className="documents-set-banner-li">
                        <span>{__('Add e-sign PDF document set')}</span>
                    </div>
                </Menu.Item>:''}
                <Menu.Item onClick={this.addDocumentSet} >
                    <div className="documents-set-banner-li">
                        { !I(security.eSign, 0) ? <div className="documents-set-banner-li-img" ></div> : ''}
                        <div>{__('Add document set')}</div>
                    </div>
                </Menu.Item>
            </Menu>
        );
        return (
            <Dropdown trigger="click" overlay={menu} placement="bottomLeft">
                <Button type="primary"><PlusOutlined />{__('Add')}</Button>
            </Dropdown>
        )
    }

	render () {
		const { loading, tableData, filterString } = this.state

		const filteredData = tableData.filter(val => !!!filterString ? true : (val.title && val.title.includes(filterString)))

		return <div className="documents-set-root">
			<div className="documents-set-banner">
				<Input.Search className="documents-set-filter" enterButton={__("Search")} onSearch={this.handleFilter} onChange={e => this.handleFilter(e.target.value.trim())} />
				{/*<Button type="primary" onClick={this.addDocumentSet}>Add Documents set</Button>*/}
                {this.addMenu()}
			</div>
            <Table
                columns={this.columns}
                dataSource={filteredData}
                pagination={false}
                scroll={{ x: true }}
                loading={loading}
                rowKey="id"
                size="small"
            />
		</div>
	}
}

export default Documents