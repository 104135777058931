import React, { Component } from 'react';
import { BulbOutlined } from '@ant-design/icons';
import openCertificateModal from 'components/CertificateDetail';
import './style.scss';

export default class HeaderTips extends Component {
	openDetail(e) {
		const certificateId = e.target.dataset.id;
		const tagName = e.target.tagName;
		if (tagName === 'A') openCertificateModal(certificateId)
	}

	render() {
		const { data } = this.props;
		const { certificateList = [], formatText = '' } = data || {};
		const splitArr = formatText.split(" #");
		const filterArr = splitArr.filter((item) => {
			return item !== '';
		});
		let finalText = '';
		filterArr.map((item, index) => {
			if (!certificateList.length || !certificateList[index]) {
				finalText += ` ${item}`
			} else {
				finalText += `${item} <a className="headerLink" data-id=${certificateList[index].certificateId}>${certificateList[index].certificateName}</a>`
			}
		})
		if (finalText[finalText.length - 1] !== '.') finalText += '.';

		return <div className="headerTips">
				<BulbOutlined style={{fontSize: '16px'}} />&nbsp;&nbsp;
				<span dangerouslySetInnerHTML = {{__html:finalText}} onClick={this.openDetail}></span>
			</div>
	}
}