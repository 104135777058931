import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import NavTab from 'components/NavTab'
import AccountUsers from "./AccountUsers";
import Board from "./Board";
import Roles from "./Roles";
import Signatories from "./Signatories";
import security from 'stores/security';
import './style.scss';

export default class Permission extends Component {
	path = this.props.match.path

	config = [
		{
			label: __('Account users'),
			path: `${this.path}`,
			permission: 'company_permission_accountUsers',
			component: AccountUsers
		},
		{
			label: __('Board'),
			path: `${this.path}/board`,
			permission: 'company_permission_board',
			component: Board
		},
		{
			label: __('Roles'),
			path: `${this.path}/roles`,
			permission: 'company_permission_roles',
			component: Roles
		},
		{
			label: __('Signatories'),
			path: `${this.path}/signatories`,
			permission: 'company_permission_signatories',
			component: Signatories
		},
	]

	render() {
		return <div className="sprout-permissions-root">
			{/* <div className="permission-sidebar">
				<NavTab config={this.config} />
			</div> */}
			<div className="sprout-permissions-content">
				<Switch>
					{
						this.config.map((val, index) => security.hasPermission(val.permission) && <Route key={index} exact path={val.path} component={val.component} />)
					}
					<Redirect to={`${this.path}`} />
				</Switch>
			</div>
		</div>
	}
}