import Summary from './Company/Summary'
import Permission from './Company/Permission'
import DataRoom from "./Company/DataRoom";
import Setting from "./Company/Setting";
import security from 'stores/security';
import CapTable  from './CapTable';
import Securities from "./Securities";
import Stakeholder from './Stakeholder';
import Reports from './Reports';
import Board from './Board';
import Token from './Token';
import Compliance from './Compliance'
import Fundraise from './Fundraise';
import Help from 'modules/HelpNew';

const routeConfig = ({ showToken }) => [
        {path:"/home/summary", component: Summary, exact: false, permission: 'company_summary'},
        {path:"/home/permissions", component: Permission, exact: false, permission: 'company_permission'},
        {path:"/home/datarooms", component: DataRoom, exact: false, permission: 'company_dataRoom'},
        {path:"/home/setting", component: Setting, exact: false, permission: 'company_setting'},
        {path:"/home/captable", component: CapTable, exact: false, permission: 'captable'},
        {path:"/home/reports", component: Reports, exact: false, permission: 'captable'},
        {path:"/home/fundraise", component: Fundraise, exact: false, permission: 'fundraising'},
        {path:"/home/securities", component: Securities, exact: false, permission: 'securities'},
        {path:"/home/stakeholders", component: Stakeholder, exact: false, permission: 'stakeholders'},
        {path:"/home/board",component: Board, exact: false, permission: 'board'},
        {path:"/home/help",component: Help, exact: false, permission: 'all'},
        {path:"/home/compliance",component: Compliance, exact: false, permission: 'compliance'},
        ...(showToken ? [{path:"/home/token",component: Token, exact: false, permission: 'all'}] : []),
]

export const getRouteConfig = ({ showToken }) => {
    return security.hasFunction(routeConfig({ showToken }) );
}

export function processMenuconfig({permission, showToken}) {
    const check = type => security.hasPermission(type) && (security.companyInfo?.is_finished_onboarding === '1' || !security.hasPermission('company_setup'));
    const menuConfig = [
        {
            title: __('Company'),
            disabled: false,
            access: check('company'),
            children: [
                {
                    title: __('Summary'),
                    path: '/home/summary',
                    access: check('company_summary'),
                    disabled: false,
                },
                {
                    title: __('Permissions and roles'),
                    path: '/home/permissions',
                    access: check('company_permission'),
                    disabled: false,
                    subMenu: [
                        {
                            label: __('Account users'),
                            path: '/home/permissions',
                            permission: 'company_permission_accountUsers',
                        },
                        {
                            label: __('Board'),
                            path: `/home/permissions/board`,
                            permission: 'company_permission_board',
                        },
                        {
                            label: __('Roles'),
                            path: `/home/permissions/roles`,
                            permission: 'company_permission_roles',
                        },
                        {
                            label: __('Signatories'),
                            path: `/home/permissions/signatories`,
                            permission: 'company_permission_signatories',
                        },
                    ]
                },
                {
                    title: __('Data room'),
                    path: '/home/datarooms',
                    access: check('company_dataRoom'),
                    disabled: false,
                },
                {
                    title: __('Settings'),
                    path: '/home/setting',
                    access: check('company_setting'),
                    disabled: false,
                    subMenu: [
                        {
                            label: __('General'),
                            path: '/home/setting',
                            permission: 'company_setting_settings',
                            exact: true,
                        },
                        {
                            label: __('Profile'),
                            path: `/home/setting/profile`,
                            permission: 'company_setting_profile',
                            exact: true,
                        },
                        {
                            label: __('Billing'),
                            path: `/home/setting/billing`,
                            permission: 'company_setting_billing',
                            exact: false,
                            // company plan control。(1:隐藏，2：展示)，如果是关闭状态，则需要隐藏billing模块
                            hide: (security.planControl == 1 || security.parentId > 0),
                        },
                        {
                            label: __('Plan'),
                            path: `/home/setting/plan`,
                            permission: 'company_setting_plan',
                            exact: false,
                            // company plan control。(1:隐藏，2：展示)，如果是关闭状态，则需要隐藏billing模块
                            hide: (security.planControl == 1 || security.parentId > 0 || !(security.isArrears === true && security.subscriptionId )),
                        },
                    ]
                },
            ]
        },
        {
            title: __('Cap table'),
            path: '/home/captable',
            disabled: false,
            access: check('captable'),
            children: [
                {
                    title: __('By share class'),
                    path: '/home/captable/byshareclass',
                    access: check('captable_byShareClass'),
                    disabled: false,
                },
                {
                    title: __('By stakeholder'),
                    path: '/home/captable/bystakeholder',
                    access: check('captable_byStakeholder'),
                    disabled: false,
                },
                {
                    title: __('By voting power'),
                    path: '/home/captable/byvotingpower',
                    access: check('captable_byStakeholder'), // 权限同 bystakeholder
                    disabled: false,
                    showTag: false,
                },
                {
                    title: __('Rights and preferences'),
                    path: '/home/captable/rights-and-preferences',
                    access: check('rights_and_preferences'),
                    disabled: false,
                    showTag: false,
                },
            ]
        },
        {
            title: __('Fundraising'),
            path: '/home/fundraise',
            disabled: false,
            access: check('fundraising'),
            children: [
                {
                    title: __('Financing history'),
                    path: '/home/fundraise/financing-history',
                    access: check('fundraising_finacingHistory'),
                    disabled: false,
                },
                {
                    title: __('Scenario modeling'),
                    path: '/home/fundraise/scenario-modeling',
                    access: check('fundraising_scenarioModeling'),
                    disabled: false,
                    showTag: false,
                },
                {
                    title: __('Wire instructions'),
                    path: '/home/fundraise/wire_instructions',
                    disabled: false,
                    showTag: false,
                    access: check('fundraising_wireInstructions')
                },

            ]
        },
        {
            title: __('Securities'),
            path: 'securities',
            disabled: false,
            access: check('securities'),
            children: [
                {
                    title: __('Shares'),
                    path: '/home/securities/shares',
                    access: check('securities_shares'),
                    disabled: false,
                },
                {
                    title: __('Share option awards'),
                    path: '/home/securities/stock-option-awards',
                    access: check('securities_EA'),
                    disabled: false,
                },
                {
                    title: __('Warrants'),
                    path: '/home/securities/warrants',
                    access: check('securities_warrants'),
                    showTag: false,
                    disabled: false,
                },
                {
                    title: __('Convertibles'),
                    path: '/home/securities/convertibles',
                    access: check('securities_convertibles'),
                    disabled: false,
                },
                {
                    title: __('Securities tools'),
                    path: '/home/securities/templates',
                    access: check('securities_templates'),
                    disabled: false,
                },
            ]
        },
        {
            title: __('Tokens'),
            path: '/home/token',
            disabled: false,
            access: showToken && check('tokens'),
            children: [
                {
                    title: __('Summary'),
                    path: '/home/token/dashboard',
                    disabled: false,
                    access: check('all')
                },
                {
                    title: __('Ownership'),
                    path: '/home/token/ownership',
                    disabled: false,
                    access: check('all')
                },
                {
                    title: __('Vesting'),
                    path: '/home/token/token-option-plan',
                    access: showToken,
                    disabled: false,
                },
                {
                    title: __('Warrants(SAFTs)'),
                    path: '/home/token/token-warrants',
                    access: showToken,
                    showTag: false,
                    disabled: false,
                },
                {
                    title: __('Transactions'),
                    path: '/home/token/transaction',
                    access: showToken,
                    showTag: false,
                    disabled: false,
                },
            ]
        },
        {
            title: __('Stakeholders'),
            disabled: false,
            access: check('stakeholders'),
            path: '/home/stakeholders',
            children: [
                {
                    title: __('All stakeholders'),
                    path: '/home/stakeholders/stakeholders-list',
                    access: check('stakeholders_allStakeholders'),
                    disabled: false,
                },
                {
                    title: __('All wallets'),
                    path: '/home/stakeholders/wallets-list',
                    access: showToken,
                    disabled: false,
                },
                {
                    title: __('Transactions'),
                    path: '/home/stakeholders/stakeholder-transactions',
                    access: check('stakeholders_transactions'),
                    disabled: false,
                },
                {
                    title: __('Stakeholder access'),
                    path: '/home/stakeholders/stakeholder-access',
                    access:  check('stakeholders_access'),
                    disabled: false,
                },
            ]
        },
        {
            title: __('Reports'),
            disabled: false,
            access: check('captable_report'),
            path: '/home/reports',
            children: [
                {
                    title: __('Equity reports'),
                    path: '/home/reports/captable',
                    access: check('captable_report'),
                    disabled: false,
                    showTag: false,
                },
                ...( security.env === 'prod' ? [] : [
                    {
                        title: __('Tax reports'),
                        path: '/home/reports/tax_reports',
                        access: check('tax_reports'),
                        disabled: false,

                    },
                ]),
                {
                    title: __('Token reports'),
                    path: '/home/reports/token',
                    access: showToken,
                    showTag: false,
                    disabled: false,
                }
            ]
        },
        {
            title: __('Board'),
            path: '/home/board',
            disabled: false,
            access: check('board'),
            children: [
                {
                    title: __('Documents'),
                    path: '/home/board/documents',
                    disabled: false,
                    access: check('board_document')
                },
                {
                    title: __('Meetings'),
                    path: '/home/board/meetings',
                    disabled: false,
                    access: check('board_meeting')
                },
                {
                    title: __('Consents'),
                    path: '/home/board/consents',
                    disabled: false,
                    showTag: false,
                    access: check('board_consent')
                },
                {
                    title: __('Notifications'),
                    path: '/home/board/notifications',
                    disabled: false,
                    access: check('board_consent') // board_notification
                },
            ]
        },
        {
            title: __('Compliance'),
            path: '/home/compliance',
            disabled: false,
            access: check('compliance'),
            children: [
                {
                    title: __('Fair market value'),
                    path: '/home/compliance/fairmarketvalue',
                    disabled: false,
                    access: check('compliance_fairmarketvalue')
                },
                {
                    title: __('Token Valuation'),
                    path: '/home/compliance/tokenvaluation',
                    disabled: false,
                    access: check('compliance_tokenvaluation')
                },
                {
                    title: __('SBC reports'),
                    path: '/home/compliance/sbc_reports',
                    access: check('sbc_reports'),
                    showTag:true,
                    disabled: false,
                }
            ]
        }
    ]
    return menuConfig
}

