import React from 'react';
import { formatDate } from 'Utils/format/formatDate';
import moment from 'moment';
import OperateButton from './components/operateButton';
import Subject from './components/subject';

const colorMap = {
    1: '#21CB59',
    2: '#FF892A',
    3: '#21CB59',
    4: '#d91e18', // B9CCD1
    6: '#FF4141',
}

const renderValue = val => val || '--'

const statusMap = {
    1: __('Delivered'), //草稿
    2: __('Pending board approval'), // 待审核
	3: __('Approved'), //审核通过
    4: __('Canceled'), //取消
    5: __('Delete'), //删除
    6: __('Expired'), //过期
}

const sorterDateOrderBy = (a, b, key) => {
    const dataA = moment(a[key]).format('YYYYMMDD');
    const dataB = moment(b[key]).format('YYYYMMDD');
    return dataA - dataB;
}

const columns = () => {
    return [
        {
            title: __('Date'),
            dataIndex: 'create_time',
            render: formatDate,
            sorter: (a, b) => sorterDateOrderBy(a, b, 'create_time'),
        },
        {
            title: __('Subject'),
            dataIndex: 'subject',
            sorter: (a, b) => a.subject.length - b.subject.length,
            render: (value) => {
                return <div>[Action required] {value}</div>
            }
        },
        {
            title: __('Sent to'),
            dataIndex: 'to_mail',
            sorter: (a, b) => a.to_mail.length - b.to_mail.length,
            render: (value) => renderValue(value)
        },
        {
            title: __('Status'),
            dataIndex: 'status',
            // sorter: (a, b) => a.status.length - b.status.length,
            render: text => <div className="notifications-list-status"> <div className="status-dot" style={{ background: colorMap[text] || '#bdd1d5' }}/> {statusMap[text]} </div>
        },
        {
            title: '',
            dataIndex: '',
            align: 'right',
            render: (value, record) => <OperateButton data={record} />,
            width: 60,
        },
    ]
}


export default colorMap;



export { colorMap, statusMap, columns };