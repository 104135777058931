import React, { useState } from 'react';
import { relationshipList, countries } from "Utils/constant";
import Utils from "Utils";
import DeleteDraftSecurity from "../components/DeleteDraftSecurity";
import CreateDraftSet from "../components/CreateDraftSet";
import RenameDraftSet from "../components/RenameDraftSet";
import DeleteDraftSet from "../components/DeleteDraftSet";
import SendInfoForSignature from "../components/SendInfoForSignature";
import { post } from 'srcPath/http';
import moment from 'moment';
import Loading from 'components/Loading';
import Permission from 'components/Permission';
import security from 'stores/security';
import { formatNumber, formatCurrencyNumber, formatDateForDatePicker, I } from "Utils";
import { withForm, FormItem } from 'react-antd-formutil';
import { Input, TimePicker, DatePicker, Select, Button, message, Checkbox } from 'antd';
import SelectStakeholder from 'components/SelectStakeholder'
import DraftWarrantsTable from "./WarrantsModal/DraftWarrantsTable";
import DateSelector from "../../../../components/DateSelector";
import CountrySelector from 'components/CountrySelector';
import { cloneDeep } from 'lodash';
import { printf, sprintf } from 'Utils/i18n';
import {CloseOutlined} from "@ant-design/icons";
class DraftWarrants extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingStatus: 1,
            curPlanId: 0,
            sendResult: {
                "1": {
                    "id": 1,
                    "is_success": 1,//1-成功，0表示失败
                    "error_reason": ""
                },
                "4": {
                    "id": 4,
                    "is_success": 0,//1-成功，0表示失败
                    "error_reason": [
                        { "title": "Name", "message": "Can't be empty!" },
                        { "title": "age", "message": "Can't be bigger than 150!" },
                        { "title": "Certificate Id", "message": "Duplicated" }
                    ]
                },
            },
            successDetails: {},
            showError: {},
            saveStatus: -1,
            listKey: -1,
            type: "WARRANT",
            addingStatus: 0,
            curSelectRelation: "all",
            draft_name: "",
            draft_id: "",
            currency: "",
            curSelectShareClassDraftSet: { id: 1, name: "24/08/2020", count: "2" },
            securityLists: [
                { security_name: 'CS-5', stakeholder: 'Cody Belseager', security_status: 'Outstanding', shares: '100', price: '1.00', transaction_value: '100.00', issue_date: '12/12/2019', status: 'unchecked', sign_status: 'officerSignature' },
                { security_name: 'CS-5', stakeholder: 'Cody Belseager', security_status: 'Outstanding', shares: '100', price: '1.00', transaction_value: '100.00', issue_date: '12/12/2019', status: 'unchecked', sign_status: 'holderSignature' },

            ],
            data: [],
            checkedId: [],
            checkedDraftSecurity: [],
            draftSharesRequiredKey: [
                "board_approval_date",
                "email",
                "issue_date",
                "relationship",
                "name",
                "quantity",
            ],
            warrantDraftSetList: [],
            warrantBlockList: [
                { id: "66", block_name: "c", prefix: "22" },
            ],
            formatWarrantBlockList: {
                66: { id: "66", block_name: "cccccc", prefix: "22" },
            },
            cnSecuritiesList: [
                { id: 3, custom_label_id: 4 },
            ],
            currencyList: ['USD', 'HKD', 'SGD',],
            schedule: [
                { id: 1, schedule_name: "" },
            ],
            pageData: {
                name: "Warrant",
                title: "Warrant",
                text: "warrant",
                error_status: 0,
                checked_count: "",
                type: 3
            },
            dateFormat: 'DD/MM/YYYY',
            genderList: [
                {
                    name: __('Woman')
                },
                {
                    name: __('Man')
                },
                {
                    name: __('Non-binary/ non-conforming')
                },
                {
                    name: __('Prefer not to respond')
                },
            ],
        }
    }
    transferDraftSetData = (key, refName, e) => {
        this.refs[refName].initData(key, this.state.warrantDraftSetList[key]);
    }
    getCheckedId = () => {
        this.refs.deleteDraftSecurity.getCheckedId();
    }
    getDeleteDraftId = () => {
        this.refs.deleteDraftSecurity.getDeleteDraftId();
    }
    changeStatus = (key) => {
        var securityLists = this.state.data;
        if (securityLists[key].checkbox_status == false) {
            securityLists[key].checkbox_status = true;
            if (securityLists[key].is_show_error == 1) {
                $(".draft_error_shares_tip" + key).removeClass("showErrorTip")
            }
        } else {
            securityLists[key].checkbox_status = false;
            if (securityLists[key].is_show_error == 1) {
                $(".draft_error_shares_tip" + key).addClass("showErrorTip")
            }
            $('#cs_check01').prop("checked", false);
        }
        this.setState({
            data: securityLists,
        })
    }

    /**
     * 更新table checkbox
     * @param selectCount 选中总计
     * @param selectIndexArray 有更新的checkbox下标和状态
     * @param isEmpty checkbox是否为全不选状态
     */
    updateSelectCount = (selectCount, selectIndexArray, isEmpty = false) => {
        const { data, listKey } = this.state;
        let list = [...data];
        if (isEmpty) {
            list.forEach((v, k) => {
                list[k]['checkbox_status'] = false;
            })
        } else {
            selectIndexArray.forEach((v, k) => {
                list[v.selectIndex]['checkbox_status'] = v.checked;
            })
        }
        this.setState(() => ({
            selectCount,
            selectIndex: selectIndexArray[0],
            data: list,
            listKey: selectCount > 0 ? -1 : listKey,
        }))
    }

    quanXuan = () => {
        var securityLists = this.state.data;
        if ($('#cs_check01').prop("checked")) {
            $('.listCheckbox').prop('checked', true);
            for (let i = 0; i < securityLists.length; i++) {
                securityLists[i].checkbox_status = true;
            }
        } else {
            $('.listCheckbox').prop('checked', false);
            for (let i = 0; i < securityLists.length; i++) {
                securityLists[i].checkbox_status = false;
            }
        }
        this.setState({
            data: securityLists
        })
    }
    componentDidMount() {
        this.getWarrantDraftSetList();
    }
    getWarrantDraftSetList = () => {
        // getDraftSetList
        post('getCoinDraftWarrantSetList', { type: "WARRANT" }).then((response) => {
            if (response.data.code === 0) {
                for (let i = 0; i < response.data.data.draftList.length; i++) {
                    response.data.data.draftList[i].checkbox_status = false;
                    response.data.data.draftList[i].is_show_error = 0;
                    if (response.data.data[i]) {
                        response.data.data[i].holder_name = response.data.data?.[i]?.name;
                    }
                }
                var formatObj = {};
                for (let j = 0; j < response.data.data.warrantBlockList.length; j++) {
                    formatObj[response.data.data.warrantBlockList[j].id] = response.data.data.warrantBlockList[j];
                }
                this.setState({
                    warrantDraftSetList: response.data.data.setList,
                    data: response.data.data.draftList,
                    draft_id: response.data.data.defaultSetInfo.id,
                    draft_name: response.data.data.defaultSetInfo.name,
                    warrantBlockList: response.data.data.warrantBlockList,
                    // shareClassList:response.data.data.shareClassList,
                    formatWarrantBlockList: formatObj,
                    cnSecuritiesList: response.data.data.cnSecuritiesList,
                    currency: response.data.data.currency,
                    schedule: response.data.data.vestingScheduleList,
                    loadingStatus: 0,
                });
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    recordData = (e) => {
        let formatWarrantBlockList = this.state.formatWarrantBlockList;
        let listKey = this.state.listKey;
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[listKey][dataKey] = dataVal;
        if (dataKey == "warrant_block_id") {
            curData[listKey].warrant_block_id = dataVal;
            curData[listKey].exercise_price = formatWarrantBlockList[dataVal].exercise_price;
        }
        this.setState({
            data: curData
        })
        if (dataKey == "warrant_block_id" || dataKey == "relationship" || dataKey == "vesting_schedule_id") {
            this.saveDraft();
        }
    }
    recordNumber = (e) => {
        let isFractionalShare = security.companyInfo.fractional_control;
        let listKey = this.state.listKey;
        let curData = this.state.data;
        let curVal = e.target.value;
        let dataKey = e.target.name;
        if (dataKey == "quantity") {
            if (isFractionalShare == 1) {
                curData[listKey][dataKey] = Utils.check5DecimalNumInputWithoutComma(curVal);
            } else {
                curData[listKey][dataKey] = Utils.checkNumInput(curVal);
            }
        } else if (dataKey == "exercise_price") {
            curData[listKey][dataKey] = Utils.check5DecimalNumInputWithoutComma(curVal);
        } else {
            curData[listKey][dataKey] = Utils.check2DecimalNumInputWithoutComma(curVal);
        }
        this.setState({
            data: curData
        });
    }
    setDataFormat = (e) => {
        let listKey = this.state.listKey;
        let curData = this.state.data;
        let curVal = e.target.value;
        let dataKey = e.target.name;
        curData[listKey][dataKey] = Utils.resetNumberWithCommas(curVal);
        this.setState({
            data: curData
        });
    }
    resetDataFormat = (e) => {
        let isFractionalShare = security.companyInfo.fractional_control;
        let listKey = this.state.listKey;
        let curData = this.state.data;
        let curVal = e.target.value;
        let dataKey = e.target.name;
        if (dataKey == "quantity") {
            if (isFractionalShare == 1) {
                curData[listKey][dataKey] = formatNumber(curVal, 5);
            } else {
                curData[listKey][dataKey] = formatNumber(curVal, null);
            }
        } else if (dataKey == "exercise_price") {
            curData[listKey][dataKey] = formatNumber(curVal, null);
        } else {
            curData[listKey][dataKey] = formatNumber(curVal);
        }
        this.setState({
            data: curData
        });
        this.saveDraft(e);
    }
    changeDraftId = (key1, key2) => {
        this.setState({
            draft_id: key1,
            draft_name: key2
        })
    }
    changeCheckedStatus = (key) => {
        let listKey = this.state.listKey;
        let curChecked = this.state.data;
        curChecked[listKey][key].checked = !curChecked[listKey][key].checked;
        this.setState({
            data: curChecked
        })
    }
    recordChecked = () => {
        let listKey = this.state.listKey;
        let curData = this.state.data;
        if (curData[listKey].is_certificated == 0) {
            curData[listKey].is_certificated == 1
        } else {
            curData[listKey].is_certificated == 0
        }
        this.setState({
            data: curData
        })
        this.saveDraft();
    }
    fileSelect = (e) => {
        let fileObj = e.target.files[0];
        let listKey = this.state.listKey;
        let dataKey = e.target.name;
        Utils.uploadFile(fileObj).then((result) => {
            let curData = this.state.data;
            if (dataKey == "additional_documents") {
                let additionalDoc = { name: fileObj.name, file_obj: result };
                if (curData[listKey].additional_documents[0].name == '') {
                    curData[listKey].additional_documents[0] = additionalDoc;
                    $(".additional_documents0").removeClass("hidden");
                } else {
                    curData[listKey].additional_documents.push(additionalDoc);
                }
            } else {
                if (typeof (curData[listKey][dataKey]) == 'string') {
                    curData[listKey][dataKey] = JSON.parse(curData[listKey][dataKey])
                    curData[listKey][dataKey].name = fileObj.name;
                    curData[listKey][dataKey].file_obj = result;
                } else {
                    curData[listKey][dataKey].name = fileObj.name;
                    curData[listKey][dataKey].file_obj = result;
                }

            }
            this.setState({
                data: curData
            });
            this.saveDraft();
        })
    }
    fileDelete = (key, additionalKey) => {
        let listKey = this.state.listKey;
        this.refs[key].value = "";
        let curData = this.state.data;
        if (additionalKey != -1) {
            if (curData[listKey][key].length == 1) {
                curData[listKey][key][0].name = "";
                curData[listKey][key][0].file_obj = "";
            } else {
                curData[listKey][key].splice(additionalKey, 1)
            }
        } else {
            curData[listKey][key].name = "";
            curData[listKey][key].file_obj = "";
        }
        this.setState({
            data: curData
        })
        this.saveDraft();
    }
    createWarrantDraft = () => {
        if (this.state.addingStatus == 1) {
            return;
        }
        this.setState({
            addingStatus: 1
        })
        let curData = this.state.data;
        for (let i = 0; i < this.state.data.length; i++) {
            this.state.data[i].checkbox_status = false
        }
        this.setState({
            data: curData
        });
        post('createCoinWarrantDraft', { draft_set_id: this.state.draft_id }).then((response) => {
            let curData = this.state.data;
            let draftSetList = this.state.warrantDraftSetList;
            let setId = this.state.draft_id;

            if (response.data.code === 0) {
                response.data.data.checkbox_status = false;
                response.data.data.is_show_error = 0;
                response.data.data.exercise_price = this.state.formatWarrantBlockList[response.data.data.warrant_block_id]?.exercise_price;
                curData.push(response.data.data);
                for (let i = 0; i < curData.length; i++) {
                    curData[i].is_show_error = 0
                }
                for (let j = 0; j < draftSetList.length; j++) {
                    if (draftSetList[j].id == setId) {
                        draftSetList[j].count += 1
                    }
                }
                this.setState({
                    data: curData,
                    addingStatus: 0,
                    // listKey: this.state.data.length - 1,
                    warrantDraftSetList: draftSetList
                })
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            this.setState({
                addingStatus: 0
            })
            console.log(error);
        });
    }
    transferKey = (key) => {
        const { listKey, data } = this.state;
        let curKey = listKey;
        let list = [...data];
        if (curKey == key) {
            curKey = -1;
            this.setState({
                listKey: curKey,
            })
        } else {
            curKey = key;
            list.forEach((v, k)=>{
                v.checkbox_status = false;
                list[k] = {...v};
            });
            $('.collapse').removeClass('in');
            this.setState({
                listKey: curKey,
                data: list,
            })
        }
    }
    selectDraftSet = (key) => {
        let curDraftList = this.state.warrantDraftSetList;
        this.setState({
            draft_name: curDraftList[key].name,
            draft_id: curDraftList[key].id,
            listKey: -1
        });
        post('getCoinDraftWarrantListById', { id: curDraftList[key].id }).then((response) => {
            if (response.data.code === 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    response.data.data[i].checkbox_status = false;
                    response.data.data[i].is_show_error = 0;
                    if (response.data.data?.[i]) {
                        response.data.data[i].holder_name = response.data.data?.[i]?.name;
                    }
                }
                this.setState({
                    data: response.data.data,
                    listKey: -1
                })
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    getDraftSecurityList = () => {
        post('getCoinDraftWarrantListById', { id: this.state.draft_id }).then((response) => {
            if (response.data.code === 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    response.data.data[i].checkbox_status = false;
                    response.data.data[i].is_show_error = 0;
                    if (response.data.data[i]) {
                        response.data.data[i].holder_name = response.data.data?.[i]?.name;
                    }
                }
                this.setState({
                    data: response.data.data,
                    listKey: -1
                })
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    saveDraft = (e) => {
        if (this.state.saveStatus == 1) {
            return;
        }
        this.setState({
            saveStatus: 0
        })
        let requestData = { ...this.state.data[this.state.listKey] };
        requestData.relationship_list = JSON.stringify(requestData.relationship_list)
        requestData.job_title_list = JSON.stringify(requestData.job_title_list)
        requestData.holder_name = requestData.holder_name || requestData.name
        post('modifyCoinWarrantDraft', requestData).then((response) => {
            if (response.data.code === 0) {
                this.setState({
                    saveStatus: 1
                })
                setTimeout(() => {
                    this.setState({
                        saveStatus: -1
                    });
                }, 300)
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    /**
     * 更新日历
     * @param key
     * @param moment_key
     * @param date
     */
    handleDraftWarrantDateChange = (key, moment_key, date) => {
        const { data, listKey, dateFormat } = this.state;
        let curData = [...data];
        curData[listKey][key] = date.format(dateFormat);
        curData[listKey][moment_key] = date;
        this.setState({
            data: curData
        });
        this.saveDraft();
    }

    removeSuccessData = (result) => {
        this.setState({
            data: this.state.data?.filter(val => !result.includes(val.id)),
            listKey: -1
        })
    }
    getErrorMessage = (result) => {
        this.setState({
            sendResult: result,
        })
        console.log(result)
        let curData = this.state.data;
        for (let i = 0; i < this.state.data.length; i++) {
            if (this.state.sendResult.hasOwnProperty(this.state.data[i].id)) {
                this.state.data[i].is_show_error = 1
            }
        }
        this.setState({
            data: curData
        })
    }
    selectCount = () => {
        let selectLength = this.state.pageData;
        let selectCount = 0;
        for (let j = 0; j < this.state.data.length; j++) {
            if (this.state.data[j].checkbox_status === true) {
                selectCount++;
            }
        }
        selectLength.checked_count = selectCount;
        this.setState({
            pageData: selectLength
        })
        this.refs.sendForSignature.getSignatureAdminList();
        this.refs.sendForSignature.getCheckedId();
    }
    handleSelectChange = (name, data) => {

        let curData = this.state.data;
        let listKey = this.state.listKey;
        if (data) {
            curData[listKey].holder_name = data.nick_name || "";
            curData[listKey].email = data.email || "";
        }
        this.setState({
            selectedData: data,
            data: curData
        })
    }
    CheckboxChange(isChecked) {
        const { data, listKey } = this.state;
        let list = [...data];
        if (isChecked == 1) {
            list[listKey].relationship_list = [
                { effective_date: '', relationship: "" },
            ]
            list[listKey].job_title_list = [
                { effective_date: '', job_title: "" },
            ]
            list[listKey].employee_id = ''
            list[listKey].passport = ''
            list[listKey].date_of_birth = ''
            list[listKey].gender = ''
            list[listKey].annual_salary = ''
            list[listKey].country = ''
            list[listKey].address_details = ''
            list[listKey].name = ''
            list[listKey].email = ''
            list[listKey].quantity = ''
            list[listKey].exercise_price = ''
        } else {
            list[listKey].name = ''
            list[listKey].email = ''
            list[listKey].quantity = ''
            list[listKey].exercise_price = ''
            list[listKey].relationship_list = [
                { effective_date: Utils.getCurDate(), relationship: "Investor" },
            ]
            list[listKey].job_title_list = [
                { effective_date: Utils.getCurDate(), job_title: "" },
            ]
        }
        list[listKey].choose_type = isChecked
        this.setState({
            data: list
        })
        this.saveDraft()
    }
    relationshipEffctiveDateChange = (listName, name, key, date) => {
        const { data, dateFormat, listKey } = this.state;
        let curData = this.state.data[listKey];
        curData[listName][key][name] = date.format(dateFormat);
        data[listKey] = curData
        this.setState({
            data
        });
    }
    setRelation = (key, e) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        curList.relationship_list[key].relationship = e.target.value;
        data[listKey] = curList
        this.setState({
            data
        })

    }
    addRelation = () => {
        const { data, listKey } = this.state;
        let one = { effective_date: Utils.getCurDate(), relationship: "Investor" }
        let curList = this.state.data[listKey];
        curList.relationship_list.push(one);
        data[listKey] = curList
        this.setState({
            data
        })
    }
    delRelation = (num) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        if (curList.relationship_list.length <= 1) {
            return;
        }
        curList.relationship_list.splice(num, 1);
        data[listKey] = curList
        this.setState({
            data
        })
    }


    JobRecordData = (key, e) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        curList.job_title_list[key].job_title = e.target.value;
        data[listKey] = curList
        this.setState({
            data
        })
    }
    handleDraftOptionDateChange = (key, moment_key, date) => {
        const { data, listKey, dateFormat } = this.state;
        let curData = [...data];
        curData[listKey][key] = date.format(dateFormat);
        curData[listKey][moment_key] = date;
        this.setState({
            data: curData
        });
        this.saveDraft();
    }
    delJobTitle = (num) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        if (curList.job_title_list.length <= 1) {
            return;
        }
        curList.job_title_list.splice(num, 1);
        data[listKey] = curList
        this.setState({
            data
        })
        this.saveDraft()
    }
    addJobTitle = () => {
        let one = { effective_date: Utils.getCurDate(), job_title: "" }
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        curList.job_title_list.push(one);
        data[listKey] = curList
        this.setState({
            data
        })
    }
    countryRecordData = (val) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        curList['country'] = val
        data[listKey] = curList
        this.setState({
            data
        })
    }

    bindStakeholders = () => {
        this.props.history.push('/home/stakeholders/stakeholders-list')
    }

    closeDraftLeft = () => {
        this.setState({ listKey: -1 });
    }

    render() {
        if (this.state.addingStatus === 1) {
            return (
                <div className="loading_position">
                    <Loading />
                </div>
            )
        }
        let selectCount = 0;
        let listKey = this.state.listKey;
        if (this.state.data.length > 0) {
            for (let j = 0; j < this.state.data.length; j++) {
                if (this.state.data[j].checkbox_status == true) {
                    selectCount++;
                }
            }
        }
        let actionButtonClass = 'btn action-disabledBtn action-btn dropdown-toggle';
        let sendButtonClass = 'btn boxbtn-to send-disabledBtn';

        if (selectCount >= 1) {
            actionButtonClass = 'btn action-availableBtn action-btn dropdown-toggle';
            sendButtonClass = 'btn boxbtn-to send-availableBtn';
        }
        const { currency = '', data, formatWarrantBlockList, dateFormat, genderList, warrantBlockList } = this.state;
        const certificateKey = warrantBlockList.findIndex(v=>v?.id == data[listKey]?.warrant_block_id);
        return (
            <div className="draftsContent token-warrant-draft">
                <RenameDraftSet ref="renameDraftSet" getDraftSecurityList={this.getDraftSecurityList} getDraftSetList={this.getWarrantDraftSetList} draft_name={this.state.draft_name} draft_id={this.state.draft_id} changeDraftId={this.changeDraftId} />
                <DeleteDraftSet ref="deleteDraftSet" getDraftSecurityList={this.getDraftSecurityList} getWarrantDraftSetList={this.getWarrantDraftSetList} draft_name={this.state.draft_name} draft_id={this.state.draft_id} changeDraftId={this.changeDraftId} />
                <DeleteDraftSecurity ref="deleteDraftSecurity" curData={this.state.data} getDraftSecurityList={this.getDraftSecurityList} listKey={this.state.listKey} />
                <CreateDraftSet type={this.state.type} setList={this.state.warrantDraftSetList} getDraftSecurityList={this.getDraftSecurityList} getWarrantDraftSetList={this.getWarrantDraftSetList} draft_name={this.state.draft_name} draft_id={this.state.draft_id} changeDraftId={this.changeDraftId} />
                <SendInfoForSignature curPlanId={this.state.curPlanId} removeSuccessData={this.removeSuccessData} getErrorMessage={this.getErrorMessage} ref="sendForSignature" pageData={this.state.pageData} curData={this.state.data} />

                <div className="">
                    <div className={`prefer-left ${listKey < 0 && 'prefer-left-max'}`}>
                        <div className="holders-nav minWidth400 clearfix">
                            <div className="action-box onset">
                                <div className="btn-group">
                                    <button type="button" className={actionButtonClass} data-toggle="dropdown">{__('Actions')}<span className="triangle triangle-whitedown action-triangle"></span></button>
                                    {selectCount >= 1 &&
                                        <ul className="dropdown-menu mange-downmenu action-downmenu">
                                            <Permission type="securities_draftWarrants_printDraft" hide><li><a >{__('Print drafts')}</a></li></Permission>
                                            <Permission type="securities_draftWarrants_delete" hide><li><a data-toggle="modal" onClick={this.getCheckedId} data-target={"#deleteDraftSecurityModal"} >{__('Delete')}</a></li></Permission>
                                        </ul>
                                    }
                                </div>
                                <div className="btn-group drafts-btn-group draft_share_btnGroup">
                                    <a className="dropdown-toggle text-blue blue-triangle-down drafts-btn" data-toggle="dropdown">{this.state.draft_name}&nbsp;&nbsp;</a>
                                    <ul className="dropdown-menu drafts-downmenu">
                                        {this.state.warrantDraftSetList.map((value, key) => {
                                            return (
                                                <li key={key}>
                                                    <a className="resetDrafts" value={value.id} onClick={this.selectDraftSet.bind(this, key)}>{value.name}
                                                        {/*{value.count >0 &&*/}
                                                        <span className="drafts-trash-can glyphicon glyphicon-trash" type="button" onClick={this.transferDraftSetData.bind(this, key, "deleteDraftSet")} data-toggle="modal" data-target="#deleteDraftSetModal"></span>
                                                        {/*}*/}
                                                        <span className="drafts-icon" type="button" onClick={this.transferDraftSetData.bind(this, key, "renameDraftSet")} data-toggle="modal" data-target="#enterNewNameForDraftsModal">{value.count}</span>
                                                    </a>
                                                </li>
                                            )
                                        })}
                                        <li><a data-toggle="modal" data-target="#createNewDraftSetModal">{__('Create a new draft set')}</a></li>
                                    </ul>
                                </div>
                            </div>
                            <Permission type="securities_draftWarrants_send" hide>
                                <div className="form-group search-group banner-rightBox offset">
                                    <div className="btn-group">
                                        {selectCount >= 1 && <a onClick={this.selectCount} data-toggle="modal" data-target="#sendInfoForSignatureModal" className={sendButtonClass}>{__('Send')}</a>}
                                        {selectCount == 0 && <a className={sendButtonClass}>{__('Send')}</a>}
                                    </div>
                                </div>
                            </Permission>
                        </div>
                        {this.state.loadingStatus === 1 &&
                            <div className="loading_position">
                                <Loading />
                            </div>
                        }
                        {this.state.loadingStatus === 0 &&
                            <div>
                                {this.state.data.length == 0 &&
                                    <div className="empty_option ">
                                        <div className="empty_option_box marginTop80">
                                            <span className="empty_option_icon bg_draft_file"></span>
                                            <h5 className="empty_option_text">{__('This set has no warrants.')}</h5>
                                            <Permission type="securities_draftWarrants_addWarrant" hide>
                                                <button className="btn action-availableBtn action-btn white-text font_weight500"
                                                    onClick={this.createWarrantDraft}>{__('Add Warrant')}
                                                </button>
                                            </Permission>
                                        </div>
                                    </div>
                                }
                                {this.state.data.length >= 1 &&
                                    <div className="draftList">
                                        <div className="scroll-div">

                                            <DraftWarrantsTable
                                                data={data}
                                                updateSelectCount={this.updateSelectCount}
                                                listKey={listKey}
                                                transferKey={this.transferKey}
                                                formatWarrantBlockList={formatWarrantBlockList}
                                            />

                                        </div>
                                        <Permission type="securities_draftWarrants_addWarrant" hide>
                                            <button type="button" onClick={this.createWarrantDraft}
                                                className="btn resetFont white-text bg-darkblue marginTop10">+ {__('Add Warrant')}
                                            </button>
                                        </Permission>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <div className={`prefer-right ${listKey < 0 && 'prefer-right-max'}`}>
                        <div>
                            {this.state.listKey == -1 &&
                                <div className="empty_option">
                                    <div className="empty_option_box marginTop186">
                                        <span className="edit-icon"></span>
                                        <h5 className="edit_text font_weight500">{__('Select a draft to edit')}</h5>
                                    </div>
                                </div>
                            }
                            {this.state.listKey >= 0 &&
                                <div>
                                    {this.state.data[listKey].is_show_error == 1 &&
                                        <div className="error_details error_tip_bar bg-lightred alert alert-dismissible" role="alert">
                                            <div className="error_icon_box bg-redbtn">
                                                <span className="error_tip_icon  glyphicon glyphicon-exclamation-sign"></span>
                                            </div>
                                            <div className="error_tip_text bg-lightred font_weight500">
                                                <h5 className="error_tip_text">{__('There were some problems with this certificate.')}</h5>
                                                <div className="error_list">
                                                    {this.state.sendResult && this.state.sendResult[this.state.data[listKey].id].error_reason.map((val, k) => {
                                                        return (
                                                            <p className="marginTop10 mb-0" key={k}>{val.title}: {val.message}</p>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            <div className="error_close bg-redbtn">
                                                <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                            </div>
                                        </div>
                                    }
                                    {this.state.saveStatus == 0 &&
                                        <div className="banner-download draft_save_banner bg-apple-green no-marginBottom ">
                                            <div className="circle-border draft-circle-border"></div>
                                            <p className="download-message">{__('Saving')}</p>
                                        </div>
                                    }
                                    {this.state.saveStatus == 1 &&
                                        <div className="banner-download draft_save_banner bg-apple-green no-marginBottom ">
                                            <span className="colorWhite glyphicon glyphicon-ok"></span>
                                            <p className="download-message">{__('Draft saved')}</p>
                                        </div>
                                    }
                                    <ul>
                                        <li className="draft-titLi clearfix">
                                            <div className="draft-titleLi-group">
                                                <p className="onset">{__('Warrant')} ({warrantBlockList?.[certificateKey]?.prefix}-{data[listKey]?.certificate})</p>
                                                <CloseOutlined onClick={this.closeDraftLeft} />
                                            </div>
                                            {/*<div className="offset">
                                                <button type="button" className="draft-certificate-btn marginRig6" ><span className="white-text glyphicon glyphicon-search"></span></button>
                                                <Permission type="securities_draftWarrants_delete" hide>
                                                    <button type="button" onClick={this.getDeleteDraftId} data-toggle="modal" data-target={"#deleteDraftSecurityModal"} className="draft-certificate-btn" ><span className="white-text glyphicon glyphicon-trash"></span></button>
                                                </Permission>
                                            </div>*/}
                                        </li>
                                        <li>
                                            <a className="prefer-togglebtn" href="#warrants_prefix_li" data-toggle="collapse">{__('Warrant')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                            <div className="collapse" id="warrants_prefix_li">
                                                <form className="row">
                                                    <div className="form-group col-xs-6">
                                                        <label htmlFor="draft_warrant">{__('Warrant')} #<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                        <input id="draft_warrant" onBlur={this.saveDraft} name="certificate" value={this.state.data[listKey].certificate} onChange={this.recordData} type="text" className="form-control" />
                                                    </div>
                                                    <div className="form-group col-xs-6">
                                                        <label htmlFor="warrants_prefix">{__('Warrant prefix')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                        <select id="warrants_prefix" name="warrant_block_id" value={this.state.data[listKey].warrant_block_id} onChange={this.recordData} className="form-control select-container">
                                                            {this.state.warrantBlockList.map((value, key) => {
                                                                return (
                                                                    <option key={key} value={value.id} >{value.block_name} ({value.prefix})</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </form>
                                            </div>
                                        </li>
                                        <li>
                                            <a className="prefer-togglebtn" href="#option_value_li" data-toggle="collapse">{__('Transaction value')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                            <div className="collapse" id="option_value_li">
                                                <div className="row">
                                                    <div className="form-group col-xs-6">
                                                        <label htmlFor="warrants_quantity">{__('Issued quantity')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                        <input type="text" id="warrants_quantity" onFocus={this.setDataFormat} onBlur={this.resetDataFormat} name="quantity" value={I(this.state.data[listKey].quantity, '')} onChange={this.recordNumber} className="form-control" />
                                                    </div>
                                                    <div className="form-group col-xs-6">
                                                        <label htmlFor="warrants_purchase_price">{__('Purchase price')}</label>
                                                        <div className="input-group"><span className="input-group-addon">{this.state.currency}</span>
                                                            <input id="warrants_purchase_price" name="purchase_price" onFocus={this.setDataFormat} onChange={this.recordNumber} onBlur={this.resetDataFormat} value={this.state.data[listKey].purchase_price} className="form-control" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-xs-6">
                                                        <label htmlFor="warrants_exercise_price">{__('Exercise price per token')}<br /><i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                        <div className="input-group"><span className="input-group-addon">{this.state.currency}</span>
                                                            <input type="text" id="warrants_exercise_price" name="exercise_price" onFocus={this.setDataFormat} onBlur={this.resetDataFormat} value={this.state.data[listKey].exercise_price} onChange={this.recordNumber} className="form-control" />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </li>
                                        <li>
                                            <a className="prefer-togglebtn" href="#warrant_holder_li" data-toggle="collapse">{__('Warrant holder')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                            <div className="collapse" id="warrant_holder_li">
                                                <form className="form-draftflextwo">
                                                    <div className="row draft-checked">
                                                        {sprintf(__('Add new stakeholders on the %s page. This page only selects existing stakeholders.'), <a onClick={this.bindStakeholders}>{__('All stakeholders')}</a>)}
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-xs-6 no-paddingleft">
                                                            <label htmlFor="warrants_name">{__('Name')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                            <SelectStakeholder value={this.state.data[listKey].holder_name} add={false} onBlur={this.saveDraft} onChange={this.handleSelectChange} />
                                                        </div>
                                                        <div className="form-group col-xs-6 no-paddingright">
                                                            <label htmlFor="warrants_email">{__('Email')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                            <SelectStakeholder value={this.state.data[listKey].email} add={false} isEmail={true} onBlur={this.saveDraft} onChange={this.handleSelectChange} />                                                    </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </li>
                                        <li>
                                            <a className="prefer-togglebtn" href="#warrants_board_consent_li" data-toggle="collapse">{__('Board consent')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                            <div className="collapse" id="warrants_board_consent_li">
                                                <form className="row">
                                                    <div className="row">
                                                        <div className="form-group col-xs-6 ">
                                                            <label htmlFor="warrants_board_approval_date">{__('Board approval date')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                            <DateSelector
                                                                onChange={this.handleDraftWarrantDateChange.bind(this, 'board_approval_date', 'board_approval_date_moment')}
                                                                value={I(data[listKey]['board_approval_date_moment'], I(data[listKey]['board_approval_date'], '') ? moment(data[listKey]['board_approval_date'], dateFormat) : '')}
                                                                inputReadOnly={false}
                                                            />
                                                        </div>
                                                        <div className="form-group col-xs-6 ">
                                                            <label htmlFor="warrants_issue_date">{__('Grant date')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                            <DateSelector
                                                                onChange={this.handleDraftWarrantDateChange.bind(this, 'issue_date', 'issue_date_moment')}
                                                                value={I(data[listKey]['issue_date_moment'], I(data[listKey]['issue_date'], '') ? moment(data[listKey]['issue_date'], dateFormat) : '')}
                                                                inputReadOnly={false}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-xs-6 ">
                                                            <label htmlFor="warrants_expiration_date">{__('Expiration date')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                            <DateSelector
                                                                onChange={this.handleDraftWarrantDateChange.bind(this, 'expiration_date', 'expiration_date_moment')}
                                                                value={I(data[listKey]['expiration_date_moment'], I(data[listKey]['expiration_date'], '') ? moment(data[listKey]['expiration_date'], dateFormat) : '')}
                                                                inputReadOnly={false}
                                                            />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </li>
                                        {/* <li>
                                        <a className="prefer-togglebtn" href="#warrant_details_li" data-toggle="collapse">Warrant details<span className="triangle triangle-down prefer-triangle"></span></a>
                                        <div className="collapse" id="warrant_details_li">

                                        </div>
                                    </li> */}
                                        <li>
                                            <a className="prefer-togglebtn" href="#warrants_vesting_schedule_li" data-toggle="collapse">{__('Vesting schedule')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                            <div className="collapse" id="warrants_vesting_schedule_li">
                                                <form>
                                                    <div className="row">
                                                        <div className="form-group col-xs-6 ">
                                                            <label htmlFor="warrants_schedule">{__('Schedule')}</label>
                                                            <select id="warrants_schedule" className="form-control select-container" onBlur={this.saveDraft} value={this.state.data[listKey].vesting_schedule_id} name="vesting_schedule_id" onChange={this.recordData}>
                                                                <option value="">{__('No Schedule')}</option>
                                                                {this.state.schedule.map((value, key) => {
                                                                    return (
                                                                        <option id="shares_schedule_name" key={key} value={value.id}>{value.schedule_name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                        {this.state.data[listKey].vesting_schedule_id != "" && this.state.data[listKey].vesting_schedule_id != 0 &&
                                                            <div className="form-group col-xs-6 ">
                                                                <label htmlFor="warrants_vesting_start_date">{__('Vesting start date')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                                <DateSelector
                                                                    onChange={this.handleDraftWarrantDateChange.bind(this, 'vesting_start_date', 'vesting_start_date_moment')}
                                                                    value={I(data[listKey]['vesting_start_date_moment'], I(data[listKey]['vesting_start_date'], '') ? moment(data[listKey]['vesting_start_date'], dateFormat) : '')}
                                                                    inputReadOnly={false}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </form>
                                            </div>
                                        </li>
                                        <li>
                                            <a className="prefer-togglebtn" href="#warrants_document_note_li" data-toggle="collapse">{__('Document and notes')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                            <div className="collapse" id="warrants_document_note_li">
                                                <form>
                                                    <div className="row">
                                                        <div>
                                                            <div className="form-group row marginBott20">
                                                                <label className="col-xs-12 text-muted">{__('Form of warrant')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                                <div className="col-xs-4 ">
                                                                    <div className="select-btn select-blue convertiblenote-select-btn">{__('Choose a file')}
                                                                        <label htmlFor="warrant_doc_select0" className="sr-only" >{__('File input')}</label>
                                                                        <input name="form_of_warrant_file" onChange={this.fileSelect} ref="form_of_warrant_file" type="file" id="warrant_doc_select0" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-xs-8 filename-box no-paddingright repur-fileName">
                                                                    {!this.state.data[listKey].form_of_warrant_file?.name &&
                                                                        <p className="text-muted paddingLeft6">{__('No file selected')}</p>
                                                                    }
                                                                    {!!this.state.data[listKey].form_of_warrant_file.name &&
                                                                        <p className="text-muted ">{this.state.data[listKey].form_of_warrant_file.name}
                                                                            <span onClick={this.fileDelete.bind(this, 'form_of_warrant_file', -1)}
                                                                                className="draft_trash form_of_warrant_file glyphicon glyphicon-trash"></span>
                                                                        </p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="form-group row marginBott20">
                                                                <label className="col-xs-12 text-muted">{__('Warrant purchase agreement')}</label>
                                                                <div className="col-xs-4">
                                                                    <div className="select-btn select-blue convertiblenote-select-btn">{__('Choose a file')}
                                                                        <label htmlFor="warrant_doc_select1" className="sr-only" >{__('File input')}</label>
                                                                        <input name="purchase_agreement_file" onChange={this.fileSelect} ref="purchase_agreement_file" type="file" id="warrant_doc_select1" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-xs-8 filename-box no-paddingright repur-fileName">
                                                                    {!this.state.data[listKey].purchase_agreement_file?.name &&
                                                                        <p className="text-muted paddingLeft6">{__('No file selected')}</p>
                                                                    }
                                                                    {!!this.state.data[listKey].purchase_agreement_file.name &&
                                                                        <p className="text-muted ">{this.state.data[listKey].purchase_agreement_file.name}
                                                                            <span onClick={this.fileDelete.bind(this, 'purchase_agreement_file', -1)}
                                                                                className="draft_trash purchase_agreement_file glyphicon glyphicon-trash"></span>
                                                                        </p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="form-group row marginBott20">
                                                                <label className="text-muted col-xs-12">{__('Additional document')}</label>
                                                                <div className="col-xs-4 no-paddingright">
                                                                    <div className={"select-btn convertiblenote-select-btn select-blue"}>{__('Choose a file')}
                                                                        <label htmlFor="warrant_doc_select2" className="sr-only">{__('File input')}</label>
                                                                        <input name="additional_documents" onChange={this.fileSelect} ref="additional_documents" type="file" id="warrant_doc_select2" />
                                                                    </div>
                                                                </div>

                                                                {Array.from(Array.isArray(this.state.data[listKey]?.additional_documents) ? this.state.data[listKey]?.additional_documents : JSON.parse(this.state.data[listKey]?.additional_documents) || []).map((val, k) => {
                                                                    let isPush = "";
                                                                    let isRow = "";
                                                                    if (k != 0) {
                                                                        isPush = "col-xs-push-4";
                                                                        isRow = "row"
                                                                    }
                                                                    return (
                                                                        <div className={isRow} key={k}>
                                                                            <div className={"col-xs-8 filename-box " + isPush}>
                                                                                {val.name == "" &&
                                                                                    <p className="text-muted paddingLeft6">{__('No file selected')}</p>
                                                                                }
                                                                                {!!val.name &&
                                                                                    <p className="text-muted paddingLeft6 paddingRight18 ">{this.state.data[listKey].additional_documents[k].name}
                                                                                        <span onClick={this.fileDelete.bind(this, 'additional_documents', k)} className="draft_trash additional_documents glyphicon glyphicon-trash"></span>
                                                                                    </p>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-xs-12 ">
                                                            <label htmlFor="warrants_notes">{__('Notes')}</label>
                                                            <textarea id="warrants_notes" type="text" name="notes" onBlur={this.saveCnDraft} className="form-control minHeight100" value={this.state.data[listKey].notes} onChange={this.recordData}></textarea>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}

export default DraftWarrants;

