import React, { Component } from "react";
import Loading from 'components/Loading';
import { Spin, message } from 'antd';
import { post } from 'srcPath/http';
import "./unpkg.css";
import "./style.scss";
import url from "url";

class SelfHelpDetail extends Component {

    state = {
        detail: '',
    };

    componentDidMount() {
        let queryObj = url.parse( this.props.location.search, true);
        const { id } = queryObj.query;
        this.getArticleDetail(id);
    }

    getArticleDetail = async (id) => {
        Loading.global(true)
        try {
            const { data: resData } = await post('getArticleDetailStakeholder', {id});
            const { code, data } = resData;
            if (code === 0) {
                this.setState({detail: data});
            } else {
                message.error('Error!');
            }
        } catch (e) {
            message.error(e.message)
        }
        Loading.global(false)
    }

    render() {
        const { detail } = this.state;
        const { article_title, article_content } = detail;
        return (
            <div className="self-help-detail-root">
                <h3>{article_title}</h3>
                <div className="self-help-detail-content" id='self-help-detail-content' dangerouslySetInnerHTML = {{__html: article_content}}></div>
            </div>
        );
    }
}
export default SelfHelpDetail;