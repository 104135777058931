import React, { Component } from "react";
import { post } from 'srcPath/http';
import Loading from 'components/Loading';
import { message } from 'antd';
import stakeholderSignImg from "./img/stakeholder-sign.png";
import officerSign from "./img/officer-sign.png";
import "./style.scss";
import officerSignImg from "components/Template/img/officer-sign.png";

export const variable = [
    {key: 'companyName', value: 'COMPANY_NAME', color: '#007f00'},
    // {key: 'officerName', value: 'OFFICER_NAME', color: 'rgba(210,48,48,0.85)'},
    // {key: 'officerJobTitle', value: 'OFFICER_JOB_TITLE', color: '#d252e2'},
    {key: 'date', value: 'DATE', color: '#00a19f'},
    {key: 'stakeholderJobTitle', value: 'STAKEHOLDER_JOB_TITLE', color: '#e11400'},
    {key: 'stakeholderName', value: 'STAKEHOLDER_NAME', color: '#7e46b1'},
    {key: 'stakeholderEmail', value: 'STAKEHOLDER_EMAIL', color: '#e5a98f'},
    {key: 'stakeholderAddress', value: 'STAKEHOLDER_ADDRESS', color: '#ca72d2'},
    {key: 'quantity', value: 'QUANTITY', color: '#585e58'},
    {key: 'grantDate', value: 'GRANT_DATE', color: '#5f898e'},
    {key: 'expirationDate', value: 'EXPIRATION_DATE', color: '#0378c6'},
    {key: 'exercisePrice', value: 'EXERCISE_PRICE', color: '#ecda5f'},
    {key: 'currency', value: 'CURRENCY', color: '#74480a'},
    {key: 'offer30Date', value: 'OFFER_30_DATE', color: '#dc5e5e'},
    {key: 'security', value: 'SECURITY', color: '#ef9e9e'},
    {key: 'country', value: 'COUNTRY', color: '#d49417'},
    {key: 'passport', value: 'PASSPORT', color: '#5fd3f3'},
    {key: 'exerciseQuantity', value: 'EXERCISE_QUANTITY', color: '#7c3e92'},
    {key: 'vestingDuration', value: 'VESTING_DURATION', color: '#5eb86a'},
    {key: 'vestingOccurrence', value: 'VESTING_OCCURRENCE', color: '#392c3d'},
    {key: 'cliffLength', value: 'CLIFF_LENGTH', color: '#f5bded'},
];

export const signButton = [
    {key: 'officerSignature', value: 'OFFICER_SIGNATURE', imgKey: 'officer-sign', imgSrc: officerSignImg, color: '#495e72', type: 1},
    {key: 'stakeholderSignature', value: 'STAKEHOLDER_SIGNATURE', imgKey: 'stakeholder-sign', imgSrc: stakeholderSignImg, color: '#b685e5', type: 2}
];


export const escapeVariable = (type, id, html) => {
    variable.forEach(v=>{
        html = replaceText(type, html, v.key, v.value);
    });
    signButton.forEach(v=>{
        html = replaceSignImg(type, html, v);
    })
    return html;
}

export const replaceText = (type, html, key, value) => {
    const regValue = type === 1 ? value : `{${key}}`;
    const reg = `/(<strong style="color: rgb\\(255, 255, 255\\); background-color: rgb\\([0-9]{1,3}, [0-9]{1,3}, [0-9]{1,3}\\);\\">[)${regValue}(]<\\/strong>)/g`;
    const replaceValue = type === 1 ? `{${key}}` : value;
    const newHtml = html.replace(eval(reg), `$1${replaceValue}$2`);
    return newHtml;
}

export const replaceSignImg = (isEn, html, data) => {
    const { imgKey, imgSrc, type, value } = data;
    if(isEn === 1){
        html = html.replace(eval(`/<img src="\\/static\\/media\\/${imgKey}\\..{8,16}\\.png">/g`), signHtml(type));
    }else{
        html = html.replace(eval(`/<p id="${imgKey}">.*<div style="clear: both;" ${value} ><\\/div><\\/div><\\/p>/g`), `<img src="${imgSrc}" />`);
    }
    return html;
}

export const signHtml = signType => `<p id="${signType === 1 ? 'officer-sign' : 'stakeholder-sign'}"><p style="font-weight: bold;">Executed by ${signType === 1 ? '[ADMIN_NAME]:' : '[STAKEHOLDER_NAME]:'}<span style="color: rgb(255,255,255)">${signType === 1 ? '[ADMIN_ONLY_ID]' : '[STAKEHOLDER_ONLY_ID]'}</span></p><div style="width: 100%"><div style="width: 50%; float: left; "><p style="margin-left: 10%; margin-top: 20px; margin-bottom: 10px; height: 60px; width: 10px;"></p><div style="height: 50px; width: 80%; border-top: 1px solid #000; margin: 0 10%; text-indent: 20px;padding-top:10px;">Signature of ${signType === 1 ? '[ADMIN_NAME]' : '[STAKEHOLDER_NAME]'}</div></div><div style="width: 50%; float: left; "><p style="margin-left: 10%; margin-top: 20px; margin-bottom: 10px; height: 60px; width: 10px;"></p><div style="height: 50px; width: 80%; border-top: 1px solid #000; margin: 0 10%; text-indent: 20px;padding-top:10px;">Date</div></div><div style="clear: both;" ${signType === 1 ? 'OFFICER_SIGNATURE' : 'STAKEHOLDER_SIGNATURE'} ></div></div></p>`;

export default class Template extends Component {

    state = {
        html: '',
    };

    componentDidMount() {
        this.getTemplate();
    }

    getTemplate = async () => {
        Loading.global(true);
        const { id, postData } = this.props;
        const url = postData ? 'getTemplatePreviewById' : 'getTemplateById';
        const subData = postData ? postData : {id};
        try {
            const { data: resData } = await post(url, subData);
            const { data, code } = resData || {}
            if (code == 0) {
                this.setState({html: escapeVariable(0, id, postData ? data : (data && data.template_content))});
            } else {
                message.error(data);
            }
        } catch(err) {
            message.error(err && err.message);
        }
        Loading.global(false);
    }

    render() {
        const { html } = this.state;
        return (
            <div className="e-sign-template-root">
                <div dangerouslySetInnerHTML={{__html: html}}></div>
            </div>
        );
    }
}

