import React, { Component } from 'react';
import Loading from 'components/Loading'
import uploadIcon from '../../images/upload.png'
import warningIcon from '../../images/warningIcon.png'
import errorIcon from '../../images/error.png'
import classnames from 'classnames';
import moment from 'moment';
import "./style.scss";
import { post } from 'srcPath/http';
import { formatDate, } from 'Utils/format/formatDate';
import { formatCurrencyNumber } from 'Utils';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import security from 'stores/security';
import ConfirmNotifyModels from "../ConfirmNotifyModels";
import { I } from "Utils";
import Modal, { ModalContent } from 'components/Modal';
import { bulkUploadLang, relationshipList } from 'Utils/constant';
import { language, printf } from 'Utils/i18n';

import * as XLSX from 'xlsx'
import { Upload, message } from 'antd';
import { Link } from "react-router-dom";
const { Dragger } = Upload;

class StakeholdersBatchUploadModels extends Component {

	ConfirmNotifyModels = null;

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			data: [],			 //表格数据
			tableData: [],			 //表格数据
			shares_title: {},	 //表头
			addStatus: 0,		 //add按钮禁止点击   0禁止  1允许
			excelStatusShow: 0,  //表格显示  0 不显示   1显示
			fileName: '',        //上传文件名
			titleRequiredRrr: [],//必填项标题未填
			tableErr: false,      //table前端验证
			tableErrInfo: '',		 //验证内容

			isOpen: false,        //展开历史数据
			shareClassNameList: [],  //share name 列表
			shareClassNameErr: [],   //share name 错误列表
			excel_title_arr: [],   //表头数组
			errorArr: [],   //表头数组


			errorList: [],
			StakeholderType: [__("Non-individual"), __('Individual')],
			genderList: [__('Female'), __('Male'), __('Non-binary / non-conforming'), __('Prefer not to respond')],
			warning_msg_list: [],
			warning_row_list: [],
			downloadInfo: {},
			const_center_list: [__('Marketing department'), __('Human resources'), __('Research and development'), __('Work office'), __('Quality assurance'), __('Engineering'), __('Logistics'), __('Procurement'), __('Others')],
			titleData: []
		}
	}
	componentDidMount() {
		this.getTableList()
	}
	getTableList = async () => {
		Loading.global(true)

		try {
			const { data: resData } = await post('getDraftExcelTitle')
			const { data, code } = resData || {}
			if (code === 0) {
				const { stakeholder: excel_title } = data || {}
				let excel_title_arr = Object.keys(excel_title)
				this.setState({
					excel_title,
					excel_title_arr
				})
			} else {
				message.error(data)
			}
		} catch (err) {
			message.error(err && err.message)
		}

		post('getBulkUploadTemplates').then(res => {
			let { data } = res.data || {}
			this.setState({
				downloadInfo: data.find(item => item.type == 'Stakeholders')
			})
		}).catch(function (error) {
		});
		Loading.global(false)
	}
	handleDropFile = (e) => {
		// console.log(e, 'handleDropFile');
	}
	handleBeforeUpload = (e) => {
		return false
	}
	// 初始化数据
	bincModelCancel = () => {
		this.setState({
			data: [],
			addStatus: 0,
			excelStatusShow: 0,
			fileName: '',
			titleRequiredRrr: [],
			tableErr: false,
			tableErrInfo: '',
			shareClassNameErr: [],
			titleRequiredRrrWeb: [],
			shareClassNameErrWeb: [],
			errorListWeb: [],
			custom_id_list: [],
			errorArr: [],   //报错数组
			errorList: [],   //报错数组
			warning_msg_list: [],
			warning_row_list: [],
			titleData: []
		})
	}
	handleChangeFile = (info) => {
		this.bincModelCancel()
		const { country_list = [] } = security.constant || {}
		let { excel_title, StakeholderType, stakeholderRelationshipList, genderList, const_center_list } = this.state
		let excel_title_arr = [...this.state.excel_title_arr]
		let titleLength = excel_title_arr.length
		let excel_title_list = [...excel_title_arr]
		let that = this
		let file = info.file//获取第一个文件
		let name = file.name.split('.')[file.name.split('.').length - 1].toLowerCase()
		if (name !== 'xls' && name !== 'xlsx') {
			this.setState({
				tableErr: true,
				tableErrInfo: __('Error parsing the file! Please make sure you are using the correct template.'),
				fileName: file.name
			})
			return
		}
		Loading.global(true)
		let reader = new FileReader()
		reader.readAsBinaryString(file)//读取这个文件
		reader.onload = function (event) {
			try {
				let result = event.target.result
				let xlsxdata = XLSX.read(result, { type: 'binary' })//读取xlsx
				let Newstakeholdersol = XLSX.utils.sheet_to_json(xlsxdata.Sheets["New stakeholders"], { header: 1, defval: '', blankrows: true })//解析为数组
				let Stakeholders = XLSX.utils.sheet_to_json(xlsxdata.Sheets["Stakeholders"], { header: 1, defval: '', blankrows: true })//解析为数组
				let col = [...Newstakeholdersol, ...Stakeholders]
				let data = []
				let titleData = []
				if (col.length == 0) {
					that.setState({
						tableErr: true,
						tableErrInfo: __('Error parsing the file! Please make sure you are using the correct template.'),
						fileName: file.name
					})
					return
				}
				col = col.slice(0, 500)
				col.forEach((item, index) => {
					if (index == 1) {
						let newArr = []
						for (let i = 0; i < titleLength; i++) {
							newArr = [...newArr, (col[index][i])]
						}
						data.push(newArr)
					} else if (index == 2) {
						let newArr = []
						for (let i = 0; i < titleLength; i++) {
							newArr = [...newArr, col[index][i]]
						}
						titleData = (newArr)
					} else if (index == 0 || index == 3) {
						let newArr = []
						for (let i = 0; i < titleLength; i++) {
							newArr = [...newArr, '']
						}
						data.push(newArr)
					} else if (index >= 500) {
						return
					} else {
						let newArr = []
						for (let i = 0; i < titleLength; i++) {
							let name = excel_title_list[i]
							if (name == 'Date of birth') {
								newArr = [...newArr, col[index][i] ? that.formatExcelTime(col[index][i]) : '']
							} else {
								newArr = [...newArr, (col[index][i])]
							}
						}
						newArr.join('') !== '' && data.push(newArr)
					}
				})
				data = data.filter(item => item.join('') !== '')
				if (data.length < 2) {
					that.setState({
						tableErr: true,
						tableErrInfo: __('Form is required - content is empty!'),
						fileName: file.name
					})
					return
				}
				let table_arr_title = data[0]
				let diffHeader = JSON.stringify(table_arr_title) === JSON.stringify(excel_title_arr)
				if (!diffHeader) {
					that.setState({
						tableErr: true,
						tableErrInfo: __('Error parsing the file! Please make sure you are using the correct template.'),
						fileName: file.name
					})
					return
				}

				that.setState({
					tableData: data,
					fileName: file.name,
					excelStatusShow: 1,
					titleData
				})
				let errorList = []
				for (let i = 0; i < data.length; i++) {
					for (let j = 0; j < data[i].length; j++) {
						let name = excel_title_list[j]
						let zhName= titleData[j]
						if (!data[i][j]) {
							if (excel_title[name] == "required") {
								errorList.push({ "col": j, "row": i, "msg": printf(__("%s is required!"), zhName) })
							}
						} else if (i > 0 && name == 'Stakeholder type') {
							let status = StakeholderType.findIndex(item => item == data[i][j])
							if (status == -1) {
								errorList.push({ "col": j, "row": i, "msg": __("Stakeholder type must be one of the options provided in the template.") })
							}
						} else if (i > 0 && name == 'Relationship') {
							let status = relationshipList.findIndex(item => item == data[i][j])
							if (status == -1) {
								errorList.push({ "col": j, "row": i, "msg": __("Relationship type must be one of the options provided in the template.") })
							}
						} else if (i > 0 && name == 'Country/ Region') {
							let status = country_list.findIndex(item => item.name == data[i][j])
							if (status == -1) {
								errorList.push({ "col": j, "row": i, "msg": __("Country/ Region must be one of the options provided in the template.") })
							}
						} else if (i > 0 && name == 'Gender') {
							let status = genderList.findIndex(item => item == data[i][j])
							if (status == -1) {
								errorList.push({ "col": j, "row": i, "msg": __("Gender must be one of the options provided in the template.") })
							}
						} else if (i > 0 && (name == 'Annual salary')) {
							if (formatCurrencyNumber(data[i][j]) == '--') {
								errorList.push({ "col": j, "row": i, "msg": printf(__("%s must be a number."), zhName) })
							}
						} else if (i > 0 && (name == 'Cost center')) {
							let status = const_center_list.findIndex(item => item == data[i][j])
							if (status == -1) {
								errorList.push({ "col": j, "row": i, "msg": __("Cost center must be one of the options provided in the template.") })
							}
						}
						else if (i > 0 && name == 'Date of birth') {
							if (data[i][j] == '--') {
								errorList.push({ "col": j, "row": i, "msg": printf(__("%s must be a valid date!"), zhName) })
							}
						}
					}
				}

				if (errorList.length > 0) {
					that.setState({
						errorListWeb: errorList
					})
				}
				that.checkSubmit('check')
				// }
			} catch (err) {

			}

		}
		Loading.global(false)
	}
	downloadTemplate = async (e) => {
		let { downloadInfo } = this.state
		var a = document.createElement('a')
		a.download = ''
		try {
			const { data: resData } = await post('getTemplateDownloadUrl', { type: downloadInfo.type })
			const { code, data } = resData || {}
			a.href = data.download_url;
			a.click()
		} catch (err) {

		}
	}
	exportTemplate = () => {
		Loading.global(true)
		post('exportStakeholderList').then(res => {
			let { data } = res.data || {}
			let url = data.file_url
			let a = document.createElement('a')
			a.download = ''
			a.href = url;
			a.click()
			Loading.global(false)
		}).catch(function (error) {
			Loading.global(false)
		});
	}
	formatExcelTime = (num) => {
		num = Number(num);

		let millisecond = (num - 25569) * 60 * 60 * 24 * 1000;
		let data = moment(millisecond).format('DD/MM/YYYY')
		return data == 'Invalid date' || formatDate(data) == '--' ? '--' : data
	}
	bindGetOpen = () => {
		let { isOpen } = this.state
		this.setState({
			isOpen: !isOpen
		})
	}

	confirmStakeholder = (stakeholderId) => {
		const { resendStakeholderEmail } = this.props;
		const userAccessControl = security.companyInfo && security.companyInfo.user_access_control;
		if (I(userAccessControl, '')) {
			this.ConfirmNotifyModels = Modal.open({
				component: props => <ModalContent title={__('Send invitation email')} close={props.close}>
					<ConfirmNotifyModels
						close={props.close}
						addStakeholder={resendStakeholderEmail}
						stakeholderId={stakeholderId}
					/>
				</ModalContent>,
				width: 1458,
				maskClosable: false,
				className: 'sprout-modal'
			})
		} else {
			this.checkSubmit('add');
		}
	}

	checkSubmit = (type = 'add') => {

		const { close, callback } = this.props
		let { tableData, excel_title_arr, errorListWeb } = this.state
		let excel_title = [...excel_title_arr]
		let dataList = []
		for (let i = 1; i < tableData.length; i++) {
			let obj = {}
			for (let j = 0; j < tableData[i].length; j++) {
				let name = excel_title[j]
				if (name == "Stakeholder type") {
					tableData[i][j] == __("Non-individual") ? obj[name] = 0 : obj[name] = 1
				} else if (name == "Email") {
					obj[name] = tableData[i][j].toString().trim()
				} else {
					obj[name] = tableData[i][j].toString()
				}
			}
			dataList.push(obj)
		}
		let newDataList = []
		dataList.forEach(item => {
			if (language != 'en_US') {
				item['Cost center'] = item['Cost center'] && bulkUploadLang[language].costCenter[item['Cost center']]
				item['Gender'] = item['Gender'] && bulkUploadLang[language].Gender[item['Gender']]
				item['Relationship'] = item['Relationship'] && bulkUploadLang[language].relationshipList[item['Relationship']]
			}
			newDataList.push(item)
		})
		Loading.global(true)
		let data_list = { ...newDataList }
		post('bulkAddStakeholderData', { data_list, type }).then(res => {
			let { data, code, } = res.data || []
			let { error_list = [], warning_msg_list = [], warning_row_list = [] } = data || []
			if (type == 'check') {
				if (errorListWeb.length > 0 || code == 1) {
					error_list && error_list.forEach(item => item.row = item.row + 1)
					let error_arr = [...error_list, ...errorListWeb]
					error_arr.sort(function (a, b) {
						var value1 = a.row,
							value2 = b.row;
						if (value1 === value2) {
							return a.col - b.col
						}
						return value1 - value2;
					});
					let errorArr = error_arr.map(item => item.msg)
					this.setState({
						errorList: error_arr,
						errorArr: [...new Set(errorArr)],
						addStatus: 0,
						data: tableData,
					})
					Loading.global(false)
				} else {
					this.setState({
						addStatus: 1,
						data: tableData
					})
					Loading.global(false)
				}
				warning_row_list && warning_row_list.forEach(item => item.row = item.row + 1)
				this.setState({
					warning_msg_list,
					warning_row_list
				})
			}
			if (type == 'add') {
				if (code === 0) {
					callback && callback()
					close()
					Loading.global(false)
					data && data.length > 0 && this.confirmStakeholder(data);
				} else if (code == 1) {
					error_list && error_list.forEach(item => item.row = item.row + 1)
					let error_arr = [...error_list, ...errorListWeb]
					error_arr.sort(function (a, b) {
						var value1 = a.row,
							value2 = b.row;
						if (value1 === value2) {
							return a.col - b.col
						}
						return value1 - value2;
					});
					let errorArr = error_arr.map(item => item.msg)
					this.setState({
						errorList: error_arr,
						errorArr: [...new Set(errorArr)],
						addStatus: 0,
						data: tableData,
					})
					Loading.global(false)
				} else {
					message.error(data)
					Loading.global(false)
				}
			}
		}).catch(function (error) {
			Loading.global(false)
		});
	}
	getErrClass = (key, k) => {
		let { errorList, warning_row_list } = this.state
		let status_err = errorList.findIndex(item => item.row == key && item.col == k)
		let status_war = warning_row_list.findIndex(item => item.row == key)
		// warning_msg_list:[],
		// warning_row_list:[]
		if (status_err != -1) {
			return 'active-bac'
		}
		if (status_war != -1) {
			return 'active-warning'
		}

	}
	getTextParams = (key, k, value) => {
		let { excel_title_arr, titleData } = this.state
		let titleArr = [...excel_title_arr]
		let name = titleArr[k]
		if (key > 0) {
			if (name === 'Annual salary') {
				return value && formatCurrencyNumber(value)
			} else if (name === "Date of birth") {
				return value && formatDate(value)
			} else {
				return value
			}
		} else {
			return titleData[k]
		}
	}
	bindGoBack = () => {
		const { close } = this.props
		close()
	}
	render() {
		const { addStatus, excelStatusShow, fileName, tableErr, tableErrInfo, errorArr, warning_msg_list, downloadInfo } = this.state
		return <div className="modal-shareholder">
			<div className="modal-body uploadDoc-body">
				<div className="modal-header-title">
					<ExclamationCircleOutlined /><span className="title-info">{__('Note: uploading information about existing stakeholders will update their data according to the last update.')}</span>
				</div>
				<div className="modal-top">
					<div className="modal-top-date">
						<button onClick={this.downloadTemplate} type="button" className="btn boxbtn-to bg-darkblue wdt200 maRi15">{__('Download template')}</button>
						<button onClick={this.exportTemplate} type="button" className="btn boxbtn-to bg-darkblue wdt200">{__('Download stakeholder list')}</button>
					</div>
					{fileName && <div className="excel-del">
						<div className="excel-name">{fileName}</div>
						<div className="excel-del-btn" onClick={this.bincModelCancel}>
							<DeleteOutlined style={{ color: '#fff' }} />

						</div>
					</div>}
				</div>
				<div className="marBtm20">{printf(__('Last updated on %s'), formatDate(downloadInfo.last_update_time))}</div>
				<div className='purchased'>
					<div className="dragger">
						<Dragger onDrop={this.handleDropFile} action='' onChange={this.handleChangeFile} beforeUpload={this.handleBeforeUpload} showUploadList={false}>
							<div className="ant-upload-drag-icon">
								<img src={uploadIcon} alt="" className="upload-icon" />
							</div>
							<div className="ant-upload-text">{__('Choose a file or drop here to import spreadsheet')}</div>
						</Dragger>
					</div>
				</div>
				{tableErr && <div className="missing-field"> <img src={errorIcon} alt="" />{tableErrInfo}</div>}
				{excelStatusShow == 1 &&
					<div>
						<div className="line"></div>
						<div className="new-hotTable">
							<table>
								<tbody>

									{this.state.data.map((item, key) => {
										return (<tr key={key} className={classnames({ 'new-hotTable-body': key % 2 == 0 }, { 'new-hotTable-header': key == 0 })}>
											{item.map((params, k) => {
												return (
													<td key={k} className={this.getErrClass(key, k)}>

														{this.getTextParams(key, k, params)}

													</td>)
											})}
										</tr>)
									})}
								</tbody>
							</table>
						</div>
					</div>
				}
				<div className="title-required-err">
					{errorArr && errorArr.map(item => {
						return (<div className="required-err-list"><img src={errorIcon} alt="" />
							{item}
						</div>)
					})}
					{warning_msg_list && warning_msg_list.map(item => {
						return (<div className="required-war-list"><img src={warningIcon} alt="" />
							{item.msg}
						</div>)
					})}

				</div>
			</div>

			<div className="modal-footer box-footer no-paddingtop">
				{/* <a className="btn boxbtn-to bg-darkblue wdt200" onClick={this.bindGoBack}>Go back</a> */}
				<button onClick={this.checkSubmit.bind(this, 'add')} type="button" className={classnames("btn boxbtn-to bg-darkblue wdt200 ", { 'prohibit-btn': addStatus == 0 })}>{__('Upload')}</button>
			</div>

		</div>
	}
}

export default StakeholdersBatchUploadModels