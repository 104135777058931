import React, { Component } from 'react';
import Empty from 'components/Empty';
import './style.scss';

export default function Section(props) {
	return <div className="sprout-section">
		{ !props.hideHeader && <div className="sprout-section-banner">{ props.title }</div> }
		<div className="sprout-section-content">
			{props.children}
		</div>
	</div>
}