import React from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import Utils, { I } from "Utils";
import { post } from 'srcPath/http';
import Loading from 'components/Loading';
import AddVestingSchedule from "../../Securities/components/AddVestingSchedule";
import CancelModal from "../../Securities/components/CancelModal";
import EditTokenPlan from "./components/EditTokenPlan";
import CreationOfTokenGrantPlan from "./components/CreationOfTokenGrantPlan";
import GrantTableModel from "./GrantModel/GrantTableModel";
import Permission from 'components/Permission';
import 'assets/css/stakeholders.css';
import 'assets/css/securities.css';
import './style.scss';
import { Input, message } from "antd";
import VestMilestones from "../../Securities/EquityAwards/EquityAwardsModal/VestMilestone";
import AccelerateVesting from "../../Securities/EquityAwards/EquityAwardsModal/AccelerateVesting";

import { Button, Dropdown, Menu, Badge} from 'antd';
import {CloseOutlined, DownOutlined, SearchOutlined} from '@ant-design/icons';
import TerminateStakeholder from "../../Stakeholder/Stakeholder/StakeholdersModal/TerminateStakeholder";
import openAssignTokenGrantModal from "./components/AssignTokenGrants";
import ShareTableModels from "../../Securities/Shares/SharesModal/ShareTableModels";
import reverseTerminationModal from "../../Securities/components/ReverseTermination";
import { printf } from 'Utils/i18n';
import Filter from 'components/Filter';

const orderMap = {
    null: 0,
    ascend: 4,
    descend: 3,
    undefined: 0
}

class Grants extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            keyWord: '',
            total: 0,
            pageSize: 10,
            pageNumber: 1,
            listName:"grantsList",
            existing_prefix:[],
            canBeCanceled:1,
            isShowCancelTip:0,
            currency:'USD',
            curSelectRelation:"all",
            dateOfIncorporation:"",
            noResult:0,
            loading:false,
            isAbleToClear:0,
            draftWarrantsCount: 0,
            vestingScheduleList:[
                {id:1,schedule_name:"abc1"},
                {id:2,schedule_name:"ddd2"}
            ],
            formatScheduleList:{},
            stakeholders:[
                {id:1,nick_name:"rain"},
                {id:3,nick_name:"rain22"},
                {id:4,nick_name:"rain55"},
                {id:2,nick_name:"rain2"},
            ],
            tokenPlanList:[
                // {id:0,name:"Plan A",size:150000,available:14999,},
            ],
            sort: {column: "", type: 'descend'},
            formatTokenPlanList:{},
            grantsList : [],
            formatGrantsList:{},
            curSelectGrant:{},
            acceptanceStatus:[],
            pageData:{
                name:"Token Grant",
                title:"Token grant",
                text:"token grant",
            },
            selectCount: 0,
            selectIndex: 0,
            selectId:0,
            tableChild : {},
            searchValue: '',
            searchSecurityLists: [],
            draftSharesCount: '',
            activeBtnDisable:0,
            formatSecurityList:[],
        }
    }

    async componentDidMount() {
        await this.getTokenOptionPlanList();
        this.getData();
        this.getTokenPlansPrefixList();
    }

    /**
     * 初始化table高度，目的是固定表头
     * @param nextProps
     */
    componentWillReceiveProps (nextProps){
        const contentHeight = $('.sprout-client-content').height();
        this.setState(()=>({
            contentHeight
        }))
    }

    /**
     *
     * @returns {Promise<void>}
     */
    getData = async () => {
        const { pageNumber, pageSize, sort, filter, keyWord } = this.state
        this.setState({ loading: true })
        try {
            const { data: resData } = await post('getTokenGrantList', {
                pageNumber,
                pageSize,
                filter,
                sort: { ...sort, type: orderMap[sort?.type] || 0 },
                search: keyWord,
            })
            const { code, data } = resData;
            if(code === 0) {
                let formatScheduleList = this.state.formatScheduleList;
                let formatGrantsList = this.state.formatGrantsList;
                const draftWarrantsCount = data.draftCount || 0
                for(let i=0;i<data.vestingScheduleList.length;i++){
                    formatScheduleList[data.vestingScheduleList[i].id] = data.vestingScheduleList[i];
                }
                for(let k=0;k<data.grantsList.length;k++){
                    formatGrantsList[data.grantsList[k].id] = data.grantsList[k];
                }
                this.setState({ grantsList: data.grantsList, draftWarrantsCount, total: data.total, searchSecurityLists: data.grantsList || [], loaded: true, vestingScheduleList:data.vestingScheduleList, formatGrantsList, formatScheduleList })

            } else {
                message.error(data)
            }
        } catch(err) {
            message.error(err && err.message)
        }

        this.setState({ loading: false })
    }

    // 筛选条件逻辑，如果有分页，需要把查询条件保存在state 供分页使用
    onFilterChange = (value = {}) => {
        if (JSON.stringify(value) !== JSON.stringify(this.state.filter)) {
            this.setState({
                filter: value,
                pageNumber: 1
            }, () => this.getData())
        }
    }

    setFilterData=(data)=>{
        this.setState({
            grantsList:data,
            searchSecurityLists: data,
        });
        if(this.state.grantsList.length==0){
            this.setState({
                noResult:1
            })
        }
        if(this.state.grantsList.length>0){
            this.setState({
                noResult:0,
                isAbleToClear:1
            })
        }
    }
    resetVestingName=(id,vestingName,vestingId)=>{
        let curData = this.state.grantsList;
        for(let i=0;i<this.state.grantsList.length;i++){
            if(curData[i].id==id){
                if(vestingId!=0){
                    curData[i].vesting_schedule_id = vestingId;
                    curData[i].vesting_schedule = this.state.formatScheduleList[vestingId].schedule_name
                }else{
                    curData[i].vesting_schedule = vestingName;
                }
            }
        }
        this.setState({
            grantsList:curData,
            searchSecurityLists: curData
        })
    }

    /**
     * 清空选中效果
     */
    clear = () => {
        const { grantsList, tableChild } = this.state;
        const list = [...grantsList];
        list.forEach( (v, k) => {
            list[k]['checkbox_status'] = false;
        })
        this.setState({
            grantsList:list,
            searchSecurityLists: list,
            selectCount: 0,
            selectIndex: 0
        })
        tableChild.clearAll();
    }
    clearInput=()=>{
        this.refs.inputContent.value = "";
    }

    //resetSecurityStatus
    resetSecurityStatus = (id,status,type=0) =>{
        let securityLists = this.state.grantsList;
        securityLists.forEach( (v, k)=>{
            if(v.id == id){
                v.status = status;
                if(type == 1){
                    v.termination_date = ""
                }
            }
        })
        this.setState({
            grantsList:securityLists
        })

    }

    //Id定位security
    setCurSelectSecurityNew = (id) => {
        const { grantsList } = this.state;
        let info = {};
        grantsList.forEach( (v, k)=>{
            if(v.id == id){
                info = {...v}
            }
        });
        this.setState({
            curSelectGrant: info
        });
    }

    saveState = (key, value) => {
        let data = {};
        data[key] = value;
        this.setState(data)
    }

    //resetCancel
    resetCancel = (id,status,cancellation_date) =>{
        let grantsList = this.state.grantsList;
        grantsList.forEach( (v, k)=>{
            if(v.id == id){
                v.status = status;
                v.cancellation_date = cancellation_date
            }
        })
        this.setState({
            grantsList
        })
    }

    refreshData=(id)=>{
        let curData = this.state.grantsList;
        for(let i=0;i<this.state.grantsList.length;i++){
            if(curData[i].id==id){
                curData[i].status="Outstanding"
            }
        }
        this.setState({
            grantsList:curData,
            searchSecurityLists: curData,
        })
    }

    setModifiedSecurity=(key,security,listName)=>{
        let curSecurityList = this.state.securityLists;
        curSecurityList[key] = security;
        this.setState({
            securityLists:curSecurityList,
            searchSecurityLists: curSecurityList,
        })
    }
    setDataForModifiedSecurity=(id,security)=>{
        let curSecurityLists = this.state.securityLists;
        curSecurityLists.forEach( (v, k)=>{
            if(v.id == id){
                curSecurityLists[k] = security;
            }
        })
        this.setState({
            securityLists:curSecurityLists,
            searchSecurityLists: curSecurityLists
        })
    }
    openDetails=(key)=>{
        this.refs.details.addClass("in")
    }

    setInitShareNote=()=>{
        this.refs.addSharesAdminNote.setInitNote();
    }

    resendEmail = ()=>{
        if(this.state.sendingStatus === 1){
            return;
        }
        this.setState({
            sendingStatus:1
        });
        axios.post('https://api.getsprout.co', {
            company_id:Utils.getLocalCompanyInfo().company_id,
            action:'sendEmail2Signatories',
            data:{
                security_id: this.state.curSelectGrant.id
            }
        }).then((response)=> {
            if(response.data.code === 0){
                message.success(__("Resent successfully!"));
                this.setState({
                    sendingStatus:0
                });
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }


    changeCancelNum=()=>{
        this.refs.cancelModal.changeCancelNum();
    }
    checkSecurityStatus=()=>{
        let curList = this.state.grantsList;
        let flag =1;
        for (let i=0;i<curList.length;i++){
            if(curList[i].checkbox_status == true && curList[i].status=="Canceled"){
                flag = 0
            }else if(curList[i].checkbox_status == true && curList[i].status=="Transferred"){
                flag = 0
            }

        }
        if(flag == 0){
            this.setState({
                canBeCanceled:0
            })
        }

    }
    getVestingData=(key)=>{
        this.refs.addVestingToGrant.getVestingData(key);
    }
    showCertificateTip=()=>{
        this.setState({
            isShowCancelTip:1
        })
    }
    deselect=()=>{
        let grantsList = this.state.grantsList;
        $('#check01').prop("checked",false);
        for(let i=0;i<grantsList.length;i++){
            if(grantsList[i].status=="Canceled"||grantsList[i].status=="Transferred"){
                grantsList[i].checkbox_status=false
            }
        }
        this.setState({
            grantsList:grantsList,
            searchSecurityLists: grantsList,
            canBeCanceled:1,
            isShowCancelTip:0,
        })
    }
    setStatusToRepurchased=(id)=>{
        let curData = this.state.securityLists;
        for(let i=0;i<this.state.securityLists.length;i++){
            if(curData[i].id==id){
                curData[i].status="Repurchased"
            }
        }
        this.setState({
            securityLists:curData,
            searchSecurityLists: curData,
        })
    }
    getVestMilestone=()=>{
        this.refs.vestMilestone.getMilestoneList();
    }
    getInitVestingList=()=>{
        this.refs.acceleratedVesting.getInitVestingList();
        this.refs.acceleratedVesting.setDefaultDate();
    }

    setPsActive=()=>{
        this.refs.createShareClass.setCurrency();
    }

    /**
     * 更新表格行样式
     * @param index 行下标
     * @param status 样式状态 true, false
     */
    updateShowMsgStatus = (index, status) => {
        const { grantsList } = this.state;
        let list = [...grantsList];
        list[index]['showMsgStatus'] = status;
        this.setState(()=>({
            grantsList : list,
        }))
    }

    /**
     * Actions菜单
     * @returns {JSX.Element}
     */
    actionsMenu = () => {
        const { canBeCanceled, selectCount, selectIndex:indexArray, selectId } = this.state;
        return (
            <Menu>
                {selectCount == 1 &&  <Menu.Item onClick={this.setCurSelectSecurityNew.bind(this, selectId)}><a data-toggle="modal" data-target="#">Modify token grant</a></Menu.Item> }
                {/*{canBeCanceled == 1 && <Menu.Item ><a  data-toggle="modal" onClick={this.changeCancelNum} data-target="#cancelModal">Cancel token grant</a></Menu.Item>}*/}
                {/*{canBeCanceled == 0 && <Menu.Item ><a  data-toggle="showTip" onClick={this.showCertificateTip} data-target="#cancelModal">Cancel token grant</a></Menu.Item>}*/}
            </Menu>
        )
    }

    /**
     * Actions按钮
     * @returns {JSX.Element}
     */
    getActionsBottom = () => {
        const { selectCount, selectId, activeBtnDisable } = this.state;
        return <Dropdown disabled={selectCount > 0 && activeBtnDisable == 0 ? false : true} overlay={this.actionsMenu} placement="bottomRight" trigger="click">
            <Button
                type="primary"
                className="shares-actions"
                onClick={this.setCurSelectSecurityNew.bind(this, selectId)}
            ><div className="actions-left">{__('Actions')}</div><DownOutlined className="actions-right" /></Button>
        </Dropdown>
    }

    /**
     * 更新table checkbox
     * @param selectCount 选中总计
     * @param selectIndexArray 有更新的checkbox下标和状态
     * @param isEmpty checkbox是否为全不选状态
     */
    updateSelectCount = (selectCount, selectIndexArray, isEmpty = false) => {
        const { grantsList } = this.state;
        let list = [...grantsList];
        if(isEmpty){
            list.forEach( (v, k) =>{
                list[k]['checkbox_status'] = false;
            })
        }else{
            selectIndexArray.forEach( (v, k) => {
                list[v.selectIndex]['checkbox_status'] = v.checked;
            })
        }
        this.setState(() => ({
            selectCount,
            selectIndex : selectIndexArray[0],
            grantsList : list,
        }))
    }

    tableObj = (ref) => {
        this.setState(()=>({
            tableChild : ref
        }))
    }

    /**
     * 搜索列表数据
     * @param e
     */
    searchList = (e) => {
        const { value } = e.target;
        this.setState({
            searchValue: value,
        })
    }


    searchByKeyWord = (value) => {
        this.setState({
            keyWord: value
        }, () => {
            this.getData()
        })
    }

    /**
     * 清空搜索内容
     */
    closeSearch = () => {
        const { searchSecurityLists } = this.state;
        this.setState({
            searchValue: '',
            grantsList: searchSecurityLists
        });
    }

    /**
     * 获取已经存在的prefix
     */
    getTokenPlansPrefixList=()=>{
        post('getTokenPlansPrefixList').then((response)=> {
            if(response.data.code === 0){
                this.setState({
                    existing_prefix:response.data.data.existing_prefix
                })
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    /**
     * 获取token plan list
     */
    getTokenOptionPlanList=()=>{
        post('getTokenOptionPlanList').then((response)=> {
            if(response.data.code === 0){
                let formatTokenPlanList = this.state.formatTokenPlanList;
                let resList = response.data.data.plan_list;
                for(let i=0; i<resList.length; i++){
                    formatTokenPlanList[resList[i].id] = resList[i];
                }
                this.setState({
                    tokenPlanList : resList,
                    formatTokenPlanList,
                })
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    /**
     * reverse termination
     */

    openReverseTermination = (record, id) =>{
        reverseTerminationModal(record,id, this.getData)
    }

    // 搜索、过滤变化
    handleTableChange = (pagination, filters, sorter) => {
        let sort = {}
        const { order, column } = sorter

        if (column?.sortName) {
            sort = {
                column: column.sortName,
                type: order
            }
        }else{
            sort = {}
        }
        this.setState({
            sort,
            pageNumber: pagination?.current || 1,
            pageSize: pagination?.pageSize || 10,
        }, () => {
            this.getData()
        })
    }

    render(){
        const { currency = '', grantsList, curSelectGrant, searchValue, tokenPlanList, total = grantsList?.length, pageSize, pageNumber, sort, draftWarrantsCount } = this.state
        let selectCount=0;
        let selectKey = "";
        for(let j=0;j< grantsList.length;j++){
            if(grantsList[j].checkbox_status === true){
                selectCount++;
                selectKey = j;
            }
        }

        // let actionButtonClass ='btn action-disabledBtn action-btn dropdown-toggle';
        //
        // if(selectCount >=1){
        //     actionButtonClass = 'btn action-availableBtn action-btn dropdown-toggle';
        // }

        return(
            <div className="content">
                <AddVestingSchedule getSecurityList={this.getData} ref={"addVestingToGrant"} listName={this.state.listName} resetVestingName={this.resetVestingName} vestingScheduleList={this.state.vestingScheduleList}   curSelectSecurity={this.state.curSelectGrant} />
                <AccelerateVesting listName={this.state.listName}  ref={"acceleratedVesting"} curSelectSecurity={this.state.curSelectGrant} />
                <CancelModal currency={this.state.currency} resetCancel={this.resetCancel} resetSecurityStatus={this.resetSecurityStatus} ref="cancelModal" setModifiedSecurity={this.setModifiedSecurity} dateOfIncorporation={this.state.dateOfIncorporation} securityList={this.state.grantsList} pageData={this.state.pageData} curSelectSecurity={this.state.curSelectGrant}/>
                <EditTokenPlan ref={"editTokenPlan"}  getData={this.getData} getTokenPlanList={this.getTokenOptionPlanList} tokenPlanList={this.state.tokenPlanList} formatTokenPlanList={this.state.formatTokenPlanList} getTokenPlansPrefixList={this.getTokenPlansPrefixList}  existingPrefix={this.state.existing_prefix}/>
                <CreationOfTokenGrantPlan getTokenPlanList={this.getTokenOptionPlanList} getTokenPlansPrefixList={this.getTokenPlansPrefixList} getData={this.getData} existingPrefix={this.state.existing_prefix}/>
                <VestMilestones listName={this.state.listName} ref="vestMilestone" curSelectSecurity={this.state.curSelectGrant}/>
                <TerminateStakeholder getTerminatedList={this.getData} setModifiedSecurity={this.setModifiedSecurity} curSelectStakeholder={this.state.curSelectGrant}/>
                <div className="stakeholder-conCenter scroll-cont draft-statistic">
                    <div className="holders-nav clearfix">
                        <div className="action-box onset">
                            <Permission type="securities_shares_actions" hide>
                                <div className="btn-group">
                                    {this.getActionsBottom()}
                                    {this.state.isShowCancelTip == 1 &&
                                    <p className="text-muted cancel_pop">{__(`Canceled, transferred, and repurchased certificates can't be canceled.`)}<br/><a onClick={this.deselect} className="text-blue">{__('Deselect conflicting rows')}</a></p>
                                    }
                                </div>
                            </Permission>
                        </div>
                        <div className="form-group search-group banner-rightBox offset">
                            {selectCount>=1 &&
                            <div className="stateBox">
                                <p className="items-tip shares_items_tip">{printf(__('%s of %s items selected'), selectCount, this.state.grantsList.length)}</p>
                                <button onClick={this.clear} type="button" className="bg-dark holders-clearBtn">{__('Clear selection')}</button>
                            </div>
                            }
                            {/* <ColumnController filterList={tableColumn} redisKey="vesting_columns" thisObj={this} /> */}
                            {this.state.noResult==0 && this.state.isAbleToClear==1 &&
                            <a  className="mt-10 mr-10" onClick={this.clearFilterData}>{__('Clear Filter')}</a>
                            }
                            <div className="mr-10">
                                <Filter callback={val => this.onFilterChange(val)} type="vesting" />
                                &nbsp;&nbsp;
                            </div>
                            <div className="input-group holders-search ">
                                <Input
                                    className="stakeholder-search-input"
                                    value={searchValue}
                                    onChange={this.searchList}
                                    prefix={<SearchOutlined className="search-prefix-icon" />}
                                    suffix={<CloseOutlined style={{opacity: searchValue ? 1 : 0}} onClick={this.closeSearch} className="search-close-icon" />}
                                    placeholder={__('Search')}
                                    onBlur={this.searchByKeyWord.bind(this, searchValue)}
                                />
                            </div>
                            <Permission type="securities_shares_shareClass" hide>
                                <div className="btn-group">
                                    <a className="btn drafts_btn security-manageBtn bg-darkblue dropdown-toggle grants_down_menu" data-toggle="dropdown">{__('Manage plans')}<span className="triangle triangle-whitedown manage_triangle"></span></a>
                                    <ul className="dropdown-menu mange-downmenu shares-downmenu grants_down_menu">
                                        {/*<li><a className="add-stake"  data-toggle="modal" onClick={this.getTokenOptionPlanList} data-target="#editTokenPlanModal">Edit token option plans</a></li>*/}
                                        <li><a className="add-stake"  data-toggle="modal" data-target="#editTokenPlanModal">{__('Edit plans')}</a></li>
                                        <li><a  data-toggle="modal" data-target="#creationOfTokenGrantPlan">{__('Create plans')}</a></li>
                                    </ul>
                                </div>
                            </Permission>
                            <Permission type="securities_shares_shareClass" hide>
                                {tokenPlanList.length ==0 ?
                                    <button className="btn drafts_btn marginRig6 send-disabledBtn disabled">{__('Assign vesting')}</button>:
                                    <button className="btn drafts_btn marginRig6 bg-darkblue" onClick={() => openAssignTokenGrantModal(this.getData, null, this.props.history)} >{__('Assign vesting')}</button>
                                }
                            </Permission>

                            <Permission type="securities_templates" hide>
                                <div><Link to={{ pathname : `/home/securities/templates`,state:{activeKey:'2'}} } className="btn marginRig6 drafts_btn bg-darkblue">{__('Manage schedule')}</Link></div>
                            </Permission>
                            <Badge className="draft-statistic-badge" count={I(draftWarrantsCount, 0)}>
                                {
                                    this.state.grantsList.length == 0 && this.state.tokenPlanList?.length === 0 ? <div className="btn-group draft-statistic-group">
                                            <a disabled className="btn drafts_btn send-disabledBtn">{__('Draft vesting')}</a></div> :
                                        <Permission type="securities_draftWarrants" hide>
                                            <Link to="/home/token/token-option-plan/draft-vesting" className="btn drafts_btn bg-darkblue">{__('Draft vesting')}</Link>
                                        </Permission>
                                }
                            </Badge>

                        </div>
                    </div>
                    {/*{this.state.noResult == 1 &&*/}
                    {/*<div className="empty_option ">*/}
                    {/*    <div className="empty_option_box marginTop186">*/}
                    {/*        <span className="empty_option_icon bg_draft_file"></span>*/}
                    {/*        <h5 className="empty_option_text mb-25">No results.</h5>*/}
                    {/*        <button className="btn action-availableBtn action-btn white-text font_weight500"  onClick={this.clearFilterData} >Clear Filter</button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*}*/}
                    {this.state.loading==true &&
                    <div className="loading_position">
                        <Loading/>
                    </div>
                    }
                    {this.state.loading == false && grantsList.length==0 &&
                    <div className="empty_option ">
                        <div className="empty_option_box marginTop80">
                            <span className="empty_option_icon bg_draft_edit"></span>
                            <h5 className="empty_option_text">{__('No token grants have been issued yet.')}</h5>
                            <Permission type="securities_EA_manageEquityPlans" hide>
                                <button className="btn action-availableBtn action-btn white-text font_weight500" data-toggle="modal" data-target="#creationOfTokenGrantPlan">{__('Create token option plan')}</button>
                            </Permission>
                        </div>
                    </div>
                    }
                    {this.state.loading==false && grantsList.length != 0 &&
                    // <div className=" table-box scroll-div">
                    <div className=" table-box">
                        <GrantTableModel
                            loading={this.state.loading}
                            grantsList={grantsList}
                            getData={this.getData}
                            updateShowMsgStatus={this.updateShowMsgStatus}
                            currency={currency}
                            admin_note={curSelectGrant.admin_note}
                            notes={curSelectGrant.notes}
                            setModifyId={this.setModifyId}
                            setCurSelectSecurityNew={this.setCurSelectSecurityNew}
                            resetSecurityStatus={this.resetSecurityStatus}
                            resetCancel={this.resetCancel}
                            getVestingData={this.getVestingData}
                            getVestMilestone={this.getVestMilestone}
                            getInitVestingList={this.getInitVestingList}
                            setInitShareNote={this.setInitShareNote}
                            resendEmail={this.resendEmail}
                            openReverseTermination={this.openReverseTermination}
                            updateSelectCount={this.updateSelectCount}
                            onRef={this.tableObj}
                            curSelectSecurity={this.state.curSelectGrant}
                            saveState={this.saveState}
                            onChange={this.handleTableChange}
                            sort={sort}
                            paginationConfig={{
                                total,
                                current: pageNumber,
                                pageSize: pageSize,
                                showSizeChanger: true,
                                showTotal: (sum) => <span>{sum} {__('items')}</span>
                            }}
                        />
                    </div>
                    }
                </div>
            </div>
        )
    }
}

export default Grants;

