import React, { Component } from "react";
import { Button } from "antd";
import "../style.scss";
/**
 * scenario modeling header
 */
class Header extends Component {

    state = {
        buttonList: [
            { value: __('Financing round'), path: 'financing-round' },
            { value: __('Waterfall'), path: 'waterfall' },
        ],
    };

    /**
     * 链接跳转
     * @param path
     */
    getModel = (path) => {
        const { history } = this.props;
        history.push(`/home/fundraise/scenario-modeling/${path}`);
    }

    /**
     * 平铺button
     * @returns {unknown[]}
     */
    getButton = () => {
        const { buttonList } = this.state;
        const { path } = this.props;
        return buttonList.map((v, k)=>{
            return <Button key={k} type="primary" className={v.path == path && 'active'} onClick={this.getModel.bind(this, v.path)}>{v.value}</Button>
        });
    }

    render(){
        return <div className="scenario-modeling-header"> {this.getButton()} </div>
    }
}
export default Header;