import React, { useState, useEffect } from 'react';
import { Button, message, Space, Radio, Select, Table } from 'antd';
import Loading from 'components/Loading';
import DateSelector from 'components/DateSelector';
import { FormItem, withForm } from 'react-antd-formutil';
import moment from 'moment';
import { post } from 'srcPath/http';
import security from 'stores/security';
import icon from '../../images/icon.png';
import { formatCurrencyNumber, formatNumber } from 'Utils';
import openFileListModal from 'components/Layout/Header/components/FileListModal';
import Modal, { ModalContent } from 'components/Modal';

function Sbc({ $formutil, props }) {
	const { $invalid, $batchDirty, $params } = $formutil
	const [start_date] = useState(moment())
	const [end_date] = useState(moment())
	const [activeKey, setactiveKey] = useState('0')
	const [dataSource, setDataSource] = useState([])
	const [sbc_type, setSbcType] = useState('1')
	const [sbc_status, setSbcStatus] = useState('1')
	const [isShowTable, setShowTable] = useState(false)
	const yearOptions = [
		{
			value: '2020'
		},
		{
			value: '2021'
		},
		{
			value: '2022'
		},
		{
			value: '2023'
		},
		{
			value: '2024'
		},
		{
			value: '2025'
		},
		{
			value: '2026'
		},
	]
	const onSubmit = async (is_export) => {
		if ($invalid) {
			$batchDirty(true)
			return
		}
		let start_date = ''
		let end_date = ''

		if ($params.year_val == 1) {
			if ($params.year_date == 2020) {
				start_date = '01/01/2020'
				end_date = '01/01/2021'
			} else if ($params.year_date == 2021) {
				start_date = '01/01/2021'
				end_date = '01/01/2022'
			} else {
				start_date = '01/01/2022'
				end_date = '01/01/2023'
			}
		} else {
			start_date = $params.start_date && $params.start_date.format('DD/MM/YYYY') || moment().format('DD/MM/YYYY')
			end_date = $params.end_date && $params.end_date.format('DD/MM/YYYY') || moment().format('DD/MM/YYYY')
		}
		let info = { start_date, end_date, sbc_status, sbc_type, is_export }
		Loading.global(true);
		try {

			const { data: resData } = await post('getSbcReport', info)

			const { data, code } = resData || {}
			const { byGrant, byCenter, byStakeholder } = data || {}


			if (code === 0) {
				setShowTable(true)
				if (is_export == 0) {
					message.success(__('Success'))
					byStakeholder.forEach((item, i) => {
						item.key = i
						item.children = item.stakeholder_list
						item.children.forEach(params => {
							params.stakeholder_name = ''
						})
					})
					byCenter.forEach((item, i) => {
						item.key = i
						item.children = item.costCenter
					})
					let DataSource = [byGrant, byStakeholder, byCenter]
					setDataSource(DataSource)
				} else {
					openFileListModal()
				}


			} else if (code == 8888) {
				Modal.open({
					component: props => <ModalContent title={__('FMV information is missing')} showClose={false}>
						<div className="sprout-task-tip fmv-link-model">
							<div className="sprout-task-tip-content">
								<div>The following report cannot be generated without FMV value.</div>
								{data && data.map((item, key) => {
									return (<div> {item.label_id} is missing FMV at grant date, which is {item.grant_date} </div>)
								})}
								<div>Please input FMV value <a onClick={() => onBindClick()}>here</a> </div>
								<div>(You can go to Compliance - fair Market Value tab)</div>
							</div>
							<div className="sprout-task-tip-btns">
								<Button type="primary" onClick={() => props.close()}>{__('Confirm')}</Button>
							</div>
						</div>
					</ModalContent >,
					width: 500,
					className: 'sprout-modal'
				})
			} else {
				Modal.open({
					component: ({ close }) => <ModalContent title={__('TIPS')} showClose={false}>
						<div className="sprout-task-tip">
							<div className="sprout-task-tip-content">
								<p>{data}</p>
							</div>
							<div className="sprout-task-tip-btns">
								<Button type="primary" onClick={() => close()}>{__('I understand')}</Button>
							</div>
						</div>
					</ModalContent>,
					width: 500,
					className: 'sprout-modal'
				})
			}

		} catch (e) {
			message.error(e.message)
		}

		Loading.global(false)
	}

	useEffect(() => {
		getTableList(0)
	}, [])

	const getTableList = async (is_export) => {
		Loading.global(true);
		try {
			const { data: resData } = await post('getSbcReport', {})

			const { data, code } = resData || {}
			const { byGrant, byCenter, byStakeholder } = data || {}


			if (code === 0 && byGrant) {
				setShowTable(true)
				byStakeholder.forEach((item, i) => {
					item.key = i
					item.children = item.stakeholder_list
					item.children.forEach(params => {
						params.stakeholder_name = ''
					})
				})
				byCenter.forEach((item, i) => {
					item.key = i
					item.children = item.costCenter
				})
				let DataSource = [byGrant, byStakeholder, byCenter]
				setDataSource(DataSource)
			}

		} catch (e) {
			message.error(e.message)
		}



		Loading.global(false)
	}
	const getActiveKey = (e) => {
		setactiveKey(e)
	}
	const onBindClick = () => {
		props.history.push('/home/compliance/fairmarketvalue')
	}


	const cnColumns = [
		[
			{
				title: __('Grant plan'),
				dataIndex: 'plan_name',
				width: 150,
			},
			{
				title: __('Grant Name'),
				dataIndex: 'label_id',
				width: 150,
			},
			{
				title: __('Stakeholder'),
				dataIndex: 'stakeholder_name',
				width: 150,
			},
			{
				title: __('Email'),
				dataIndex: 'email',
				width: 150,
			},
			{
				title: __('Cost Center'),
				dataIndex: 'center',
				width: 150,
			},
			{
				title: __('Grant Amount'),
				dataIndex: 'grant_amount',
				width: 150,
				render: val => formatNumber(val),
			},
			{
				title: __('Total Accumulated Vested Quantity'),
				dataIndex: 'vested_quantity',
				width: 250,
				render: val => formatNumber(val),
			},
			{
				title: __('Grant Date'),
				dataIndex: 'grant_date',
				width: 150,
			},
			{
				title: __('FMV'),
				dataIndex: 'fmv',
				render: val => formatCurrencyNumber(val),
				width: 150,
			},
			{
				title: __('Exercise Price'),
				dataIndex: 'exercise_price',
				render: val => formatCurrencyNumber(val),
				width: 150,
			},
			{
				title: __('Vested Quantity'),
				dataIndex: 'period_vested_quantity',
				width: 200,
				render: val => formatNumber(val),
			},
			{
				title: __('Unvested Quantity'),
				dataIndex: 'period_unvested_quantity',
				width: 200,
				render: val => formatNumber(val),
			},
			{
				title: __('Forfeited Quantity'),
				dataIndex: 'period_forfeited_quantity',
				width: 200,
				render: val => formatNumber(val),
			},
			{
				title: __('Amortized Expense'),
				dataIndex: 'amortized_expense',
				render: val => formatCurrencyNumber(val),
				width: 200,
			},
			{
				title: __('Unamortized Expense'),
				dataIndex: 'unamortized_expense',
				render: val => formatCurrencyNumber(val),
				width: 180,
			},
			{
				title: <div className="capitalization-header-wrap">
					<div className="table-th-content private-bygrant">
						<div className="table-th-border"></div>
						<span>{__('Total Expense')}</span>
						<div className="table-th-desc-tit">{__('For the selected period')}</div>
					</div>
				</div>,
				dataIndex: 'total_expense',
				render: val => formatCurrencyNumber(val),
				width: 150,
			}
		],
		[
			{
				title: __('Stakeholder'),
				dataIndex: 'stakeholder_name',
				width: 150,
			},
			{
				title: __('Email'),
				dataIndex: 'email',
				width: 150,
			},
			{
				title: __('Grant Type'),
				dataIndex: 'security_type',
				width: 150,
			},
			{
				title: __('Grant Name'),
				dataIndex: 'label_id',
				width: 150,
			},
			{
				title: __('Grant Date'),
				dataIndex: 'grant_date',
				width: 150,
			},
			{
				title: __('Cost Center'),
				dataIndex: 'center',
				width: 150,
			},
			{
				title: __('Grant Amount'),
				dataIndex: 'grant_amount',
				width: 150,
				render: val => formatNumber(val),
			},
			{
				title: __('FMV'),
				dataIndex: 'fmv',
				render: val => formatCurrencyNumber(val),
				width: 150,
			},
			{
				title: __('Exercise Price'),
				dataIndex: 'exercise_price',
				render: val => formatCurrencyNumber(val),
				width: 150,
			},
			{
				title: __('Total Accumulated Vested Quantity'),
				dataIndex: 'vested_quantity',
				render: val => formatCurrencyNumber(val),
				width: 250,
			},
			{
				title: __('Vested Quantity'),
				dataIndex: 'period_vested_quantity',
				width: 200,
				render: val => formatNumber(val),
			},
			{
				title: __('Unvested Quantity'),
				dataIndex: 'period_unvested_quantity',
				width: 200,
				render: val => formatNumber(val),
			},
			{
				title: __('Forfeited Quantity'),
				dataIndex: 'period_forfeited_quantity',
				width: 200,
				render: val => formatNumber(val),
			},
			{
				title: __('Amortized Expense'),
				dataIndex: 'amortized_expense',
				render: val => formatCurrencyNumber(val),
				width: 150,
			},
			{
				title: __('Unamortized Expense'),
				dataIndex: 'unamortized_expense',
				render: val => formatCurrencyNumber(val),
				width: 180,
			},
			{
				title: <div className="capitalization-header-wrap">
					<div className="table-th-content private-bystakeholder">
						<div className="table-th-border"></div>
						<span>{__('Total Expense')}</span>
						<div className="table-th-desc-tit">{__('For the selected period')}</div>
					</div>
				</div>,
				dataIndex: 'total_expense',
				render: val => formatCurrencyNumber(val),
				width: 150,
			}
		],
		[
			{
				title: __('Cost Center'),
				dataIndex: 'cost_name',
				width: 150,
			},
			{
				title: __('Stakeholder Name'),
				dataIndex: 'stakeholder_name',
				render: (val, k) => val || k.count + 'stakeholder(s)',
				width: 150,
			},
			{
				title: __('Email'),
				dataIndex: 'email',
				width: 150,
			},

			{
				title: __('Grant Amount'),
				dataIndex: 'grant_amount',
				width: 150,
				render: val => formatNumber(val),
			},

			{
				title: __('Total Accumulated Vested Quantity'),
				dataIndex: 'vested_quantity',
				width: 250,
				render: val => formatNumber(val),
			},

			{
				title: __('Vested Quantity'),
				dataIndex: 'period_vested_quantity',
				width: 200,
				render: val => formatNumber(val),
			},
			{
				title: __('Unvested Quantity'),
				dataIndex: 'period_unvested_quantity',
				width: 200,
				render: val => formatNumber(val),
			},
			{
				title: __('Forfeited Quantity'),
				dataIndex: 'period_forfeited_quantity',
				width: 200,
				render: val => formatNumber(val),
			},
			{
				title: __('Amortized Expense'),
				dataIndex: 'amortized_expense',
				render: val => formatCurrencyNumber(val),
				width: 150,
			},
			{
				title: __('Unamortized Expense'),
				dataIndex: 'unamortized_expense',
				render: val => formatCurrencyNumber(val),
				width: 180,
			},
			{
				title: <div className="capitalization-header-wrap">
					<div className="table-th-content private-byconstcenter">
						<div className="table-th-border"></div>
						<span>{__('Total Expense')}</span>
						<div className="table-th-desc-tit">{__('For the selected period')}</div>
					</div>
				</div>,
				dataIndex: 'total_expense',
				render: val => formatCurrencyNumber(val),
				width: 150,
			}
		],
	]

	const getTypeVal = e => {
		let val = e.target.value
		setSbcType(val)
		setSbcStatus('1')
	}

	const getStatusVal = e => {
		let val = e.target.value
		setSbcStatus(val)
	}

	return <div className="sprout-reports-item-container sbc-container">
		<div className="sprout-reports-item-left">
			<div className="sprout-reports-item-row marBtm25">
				<div className="sprout-reports-icon">
					<img src={icon} alt="icon" />
				</div>
				<div className="sprout-reports-item-content">
					<div className="title">{__('SBC report')}</div>
					<div className="desc">{__('Reporting period')}</div>
					<div className="item-title">{__('Select')}:</div>
					<div className="item-section">
						<FormItem
							validMessage={{ required: 'Select year' }}
							required
							name="year_val"
							$defaultValue={'1'}
						>
							<Radio.Group>
								<Space direction="vertical">
									{/* <Radio value="1">{__('Last tax year')}</Radio> */}
									<div className="custom_val">
										<Radio value="1">{__('Year')}</Radio>
										<FormItem
											className=""
											name="year_date"
											$defaultValue={'2022'}
										>
											<Select
												placeholder={__('Select year')}
												className="city_select"
												options={yearOptions}
											/>
										</FormItem>
									</div>
									<div className="custom_val">
										<Radio value="2">
											{__('Custom time range:')}
										</Radio>
										<div className="custom_date">{__('From')}
											<FormItem
												className="maR10"
												name="start_date"
											>
												<DateSelector bordered={true} format={security.companyInfo && security.companyInfo.date_format} defaultValue={start_date} />
											</FormItem>
											{__('to')}
											<FormItem
												className="maR10"
												name="end_date"
											>
												<DateSelector bordered={true} format={security.companyInfo && security.companyInfo.date_format} defaultValue={end_date} />
											</FormItem>
										</div>
									</div>
								</Space>
							</Radio.Group>
						</FormItem>
					</div>
				</div>

			</div>
			<div className="sprout-reports-item-row marBtm25">
				<div className="sprout-reports-icon">
				</div>
				<div className="sprout-reports-item-content">
					<div className="desc">{__('Accounting framework')}</div>
					<div className="item-title">{__('Select')}:</div>
					<div className="item-section">
						<Radio.Group onChange={getTypeVal} value={sbc_type}>
							<Space direction="vertical">
								<Radio value="1">{__('IFRS')}</Radio>
								<Radio value="2">{__('US GAAP')}</Radio>
							</Space>
						</Radio.Group>
					</div>
				</div>
			</div>
			<div className="sprout-reports-item-row marBtm25">
				<div className="sprout-reports-icon">
				</div>
				<div className="sprout-reports-item-content">
					<div className="desc">{__('Price')}</div>
					<div className="item-title">{__('Select')}:</div>
					<div className="item-section">
						<Radio.Group onChange={getStatusVal} value={sbc_status}>
							<Space direction="vertical">
								<Radio value="1">{__('Exercise price at grant date')}</Radio>
								<Radio value="2" disabled={sbc_type == '2'}>{__('Current FMV- exercise price')}</Radio>
								<Radio value="3" disabled={sbc_type == '1'}>{__('FMV at grant date')}</Radio>
							</Space>
						</Radio.Group>
					</div>
				</div>
			</div>
		</div>
		<div className="btns">
			<Space>
				<Button className="sbc_export_btn" type="primary" onClick={() => onSubmit(0)}>{__('Preview')}</Button>
			</Space>
		</div>

		{isShowTable && <div className="export-table">
			<div className="export-table-export-btn" >
				<div className="export-table-btn">
					<Button type={activeKey === '0' && 'primary'} onClick={() => getActiveKey('0')} >{__('By Grant')}</Button>
					<Button type={activeKey === '1' && 'primary'} onClick={() => getActiveKey('1')} >{__('By Stakeholder')}</Button>
					<Button type={activeKey === '2' && 'primary'} onClick={() => getActiveKey('2')} >{__('By Cost Center')}</Button>
				</div>
				<div className="btns">
					<Space>
						<Button className="export_sbc_btn shares-actions" type="primary" onClick={() => onSubmit(1)}>{__('Export')}</Button>
					</Space>
				</div>
			</div>
			<div className="export-table-list">
				<Table
					className="sbc-table-w"
					columns={cnColumns[activeKey]}
					dataSource={dataSource[activeKey]}
					pagination={false}
					scroll={{ x: true, y: 500 }}
				/>
			</div>
		</div>
		}

	</div >
}

export default withForm(Sbc)
