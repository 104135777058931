import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Shares from './Shares';
import DraftShares from './DraftShares';

import security from 'stores/security';
import Templates from "../Templates";

export default class Security extends Component {
    path = this.props.match.path

    config = [
        {
            path: `${this.path}`,
            component: Shares,
            permission: 'securities_shares',
            exact: true,
        },
        {
            path: `${this.path}/draft-shares`,
            component: DraftShares,
            permission: 'securities_draftShares',
            exact: true,
        },
        {
            path: `${this.path}/draft-shares/:activeKey`,
            component: DraftShares,
            permission: 'securities_draftShares',
            exact: true,
        },
    ]

    render() {
        return <div className="sprout-captable-root">
            <Switch>
                {
                    this.config.map((val, index) => security.hasPermission(val.permission) && <Route key={index} exact={val.exact} path={val.path} component={val.component} />)
                }
            </Switch>
        </div>
    }
}