import React, { Component } from "react";
import {Button, Dropdown, Menu, Space, Checkbox, Spin} from "antd";
import { I } from "Utils";
import security from 'stores/security';
import "./style.scss";

export default class ColumnController extends Component {

    state = {
        visible: false,
        loading: false,
    };

    /**
     * 获取缓存中的filter数据
     */
    initFilterList = () => {
        const { filterList, redisKey } = this.props;
        if(!I(filterList, []).length){
            return;
        }
        const uid = security.userInfo && security.userInfo.id;
        const { companyId } = security;
        const filterJson = localStorage.getItem('filter');
        const filter = I(filterJson, '') && JSON.parse(filterJson);
        return I(filter[redisKey], '') && filter[redisKey][`${uid}-${companyId}`] && filter[redisKey][`${uid}-${companyId}`];
    }

    menuChange = (data) => {
        this.setState({loading: true})
        const { key } = data;
        const { redisKey, thisObj } = this.props;
        const columnList = this.getColumnList();
        const index = columnList.findIndex( v => v.dataIndex == key );
        if(index === -1){
            return;
        }
        let newFilterList = [...columnList];
        newFilterList[index].isChecked = !!!I(newFilterList[index].isChecked, false);
        redisKey && this.saveFilter(newFilterList, redisKey);
        this.forceUpdate();
        thisObj.forceUpdate();
        this.setState({loading: false})
    }

    /**
     * 缓存filter数据,用于记录用户行为
     * @param newFilterList
     */
    saveFilter = (newFilterList, key) => {
        const uid = security.userInfo && security.userInfo.id;
        const { companyId } = security;
        const filter = localStorage.getItem('filter');
        const filterObj = I(filter, '') ? JSON.parse(filter) : {};
        filterObj[key] = {...filterObj[key], ...{[`${uid}-${companyId}`]: newFilterList}};
        localStorage.setItem('filter', JSON.stringify(filterObj));
    }

    menu = () => {
        return (
            <Menu multiple={false} onClick={this.menuChange} className="table-column-menu" >
                <Menu.ItemGroup title={__('SELECT COLUMN')}>
                    {this.menuList()}
                </Menu.ItemGroup>
            </Menu>
        );
    }

    menuList = () => {
        const columnList = this.getColumnList();
        return columnList && columnList.map((v)=>{
            const { isChecked, isDisabled, title, dataIndex, isHide } = v;
            return (
                <Menu.Item key={dataIndex} disabled={isDisabled} className={isHide ? 'table-column-hide' : ''}><Space size={6}><Checkbox checked={isChecked} disabled={isDisabled} /><span>{title}</span></Space></Menu.Item>
            )
        });
    }

    getColumnList = () => {
        const { filterList } = this.props;
        const redisFilterList = this.initFilterList();
        const newRedisFilterList = redisFilterList && !this.checkColumnList(redisFilterList, I(filterList, '') ) ? redisFilterList : I(filterList, '');
        return newRedisFilterList;
    }

    checkColumnList = (redisFilterList, filterList) => {
        let isReset = false;
        redisFilterList && I(filterList, '') && redisFilterList.forEach((v, k)=>{
            if(v.dataIndex != filterList[k].dataIndex || v.title != filterList[k].title){
                isReset = true;
            }
        });
        redisFilterList && I(filterList, '') && redisFilterList.length != filterList.length && (isReset = true);
        return isReset;
    }

    onVisibleChange = (visible) => {
        this.setState({visible})
    }

    render() {
        const { visible } = this.state;
        return (
            <Dropdown
                overlay={this.menu}
                trigger={['click']}
                getPopupContainer={ triggerNode => triggerNode.parentNode }
                visible={visible}
                onVisibleChange={this.onVisibleChange}
                className="table-column"
            >
                <Button type="primary" className="table-column-filter">
                    <div className="set_filter-pic"></div>
                    <div className="triangle triangle-whitedown filter-triangle"></div>
                </Button>
            </Dropdown>
        );
    }
}

/**
 * 控制 table column 数据是否展示
 * @param tableColumn
 * @param redisKey
 * @returns {*[]}
 */
export const columnsFilter = (tableColumn, redisKey, sort) => {
    const uid = security.userInfo && security.userInfo.id;
    const { companyId } = security;
    const filterJson = localStorage.getItem('filter');
    const filter = I(filterJson, '') && JSON.parse(filterJson);
    const newColumn = I(filter[redisKey], '') && filter[redisKey][`${uid}-${companyId}`] && filter[redisKey][`${uid}-${companyId}`];
    let newTableColumn = [];
    if(newColumn){
        newColumn.forEach((v, k)=>{
            tableColumn.forEach((v1, k1) => {
                const sortOrder = (v1?.sortName && v1.sortName === sort.column) ? sort?.type : null
                k == k1 && v.dataIndex == v1.dataIndex && v.isChecked && newTableColumn.push({...v1, sortOrder});
            })
        });
        const operationIndex = tableColumn.findIndex( v => I(v.isHide, false) === true );
        operationIndex !== -1 && newTableColumn.push(tableColumn[operationIndex]);
    }else{
        tableColumn.forEach( v => ( v.isChecked || I(v.isHide, false) ) && newTableColumn.push(v));
    }
    return newTableColumn;
}