import React, { Component } from 'react';
import Echarts from 'components/Echarts';
import { message, Spin } from 'antd';
import { post } from 'srcPath/http';
import emptyImg from 'assets/images/bar.png';
import { formatCurrencyNumber, tranNumber } from 'Utils';
import FinancingHistory from '../FinancingHistory';
import './style.scss';

export default class FinancingHistoryPage extends Component {
    formatFoundOption = () => {
        const { financingHistory = [], currency } = this.props.data || {};
        const getPriceRender = (defVal, newVal) => (!!newVal ? newVal : defVal);
        const optionObj = {
            nameList: [],
            cashRaisedList: [],
            postMoneyList: []
        };
        financingHistory.map((item) => {
            optionObj.nameList.push(item.name);
            optionObj.cashRaisedList.push(getPriceRender(item.cashRaised, item.history_cashRaised));
            optionObj.postMoneyList.push(getPriceRender(item.post_money_valuation, item.history_post_money_valuation));
        });
        const option = {
            grid: {
                containLabel: true,
                left: 10,
                top: 20,
                right: 20,
                bottom: 40
            },
            legend: {
                data: [__('Cash raised'), __('Post-money valuation')],
                right: 20,
                bottom: 0
            },
            tooltip: {
                formatter(params){
                    return params.name + '<br />' + params.seriesName + ':' + formatCurrencyNumber(params.value, 0, currency);
                }
            },
            xAxis: {
                data: optionObj.nameList,
                axisTick: {
                    show: false
                },
                axisLine: {
                    lineStyle: {
                        color: '#524e4ed9'
                    }
                }                
            },
            yAxis: {
                type: 'value',
                axisTick: {
                    show: false
                },
                axisLabel: {
                    formatter: (value) => {
                        return tranNumber(value, 1, currency);
                    }
                },
                axisLine: {
                    lineStyle: {
                        color: '#524e4ed9'
                    }
                }                
            },
            series: [
                {
                    name: __('Cash raised'),
                    type: 'bar',
                    data: optionObj.cashRaisedList,
                    barMaxWidth: 50,
                    barGap: 1,
                    itemStyle: {
                        normal: {
                            label: {
                              formatter: (params) => {
                                return formatCurrencyNumber(params.value, 0, currency)
                              },
                              show: true,
                              position: "top",
                              textStyle: {
                                fontSize: "12",
                                color: "#898989"
                              }
                            },
                        },
                    }
                },
                {
                    name: __('Post-money valuation'),
                    type: 'bar',
                    data: optionObj.postMoneyList,
                    barMaxWidth: 50,
                    barGap: 1,
                    itemStyle: {
                        normal: {
                            label: {
                              formatter: (params) => {
                                return formatCurrencyNumber(params.value, 0, currency)
                              },
                              show: true,
                              position: "top",
                              textStyle: {
                                fontSize: "12",
                                color: "#898989"
                              }
                            },
                        },
                    }
                }
            ],
        };
        return option;
    }

	render() {
		const { data } = this.props
		const { financingHistory = [], currency } = data || {}

		return <div className="client-portfolio-history-root">
			<div className="client-portfolio-history-chart">
                {
    				financingHistory && financingHistory.length === 0 ? <img className="empty-icon" src={emptyImg} /> : <Echarts option={this.formatFoundOption()}  />			
    			}
            </div>
			<div className="client-portfolio-history-table">
				<FinancingHistory data={financingHistory} currency={currency} />
			</div>
		</div>
	}
}