import React, { Component } from 'react';
import { withForm, FormItem } from 'react-antd-formutil';
import Modal, { ModalContent } from 'components/Modal';
import { Input, TimePicker, DatePicker, Select, Button, message } from 'antd';
import DateSelector from 'components/DateSelector';
import TimeZone from 'components/TimeZone';
import { post } from 'srcPath/http';
import moment from 'moment';
import security from 'stores/security';
import { bindKeyEvent, formatDateForDatePicker, urlReg } from 'Utils';
import { formatDate, form } from 'Utils/format/formatDate';

import './style.scss';

const { RangePicker } = TimePicker
const { Option } = Select
// meeting_date: "29/10/2020 10:00 13:00 HKT"
// meeting_link: "test"
// meeting_location: "ftest"
// meeting_name: "test1111"


class MeetingModalContent extends Component {
	state = {
		loading: false
	}

	componentDidMount() {
		// this.removeKeyEvent = bindKeyEvent(this.onSubmit)
		const { data, $formutil } = this.props;

//  30/04/2021 00:00 06:06 HKT

		if (data) {
			const { meeting_date, meeting_link, meeting_name, meeting_location } = data

			const [ date, start, end, timeZone ] = meeting_date.split(' ')

			// console.log([moment('1/1/2020 20:00:00'), moment() ])

			$formutil.$setValues({
				date: date,
				time: [moment(`1/1/2020 ${start}`), moment(`1/1/2020 ${end}`)],
				meeting_link,
				meeting_name,
				timeZone,
				meeting_location
			})
		}
	}

	componentWillUnmount() {	
		// this.removeKeyEvent && this.removeKeyEvent()
	}

	onSubmit = async () => {
		const { $invalid, $batchDirty, $params, $getFirstError } = this.props.$formutil
		const { callback, close, data: defaultData } = this.props 

		const meeting_id = defaultData && defaultData.meeting_id

		if ($invalid) {
			$batchDirty(true)
			message.error($getFirstError())

			return
		}

		let { timeZone, date, time, meeting_link, meeting_name, meeting_location } = $params

		// return

		if (time[0] instanceof moment) {
			time = [moment(time[0]).format('HH:mm'), moment(time[1]).format('HH:mm')]
		}

		date = moment(date).format('MM/DD/YYYY')

		const meeting_date = `${date} ${time[0]} ${time[1]} ${timeZone}`

		this.setState({ loading: true })

		try {
			const { data: resData } = await post( defaultData ? 'editBoardMeeting' : 'addBoardMeeting', {
				meeting_name,
				meeting_location,
				meeting_link,
				meeting_date,
				meeting_id
			})

			const { code, data } = resData

			if (code == 0) {
				const meetingId = data.meeting_id

				this.setState({ loading: false })

				callback && callback(meetingId)
				close()
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}

		this.setState({ loading: false })
	}


	render() {
		const { loading } = this.state
		const { data } = this.props
		const disabled =  data && data.status === 'Archived'
		const formatter = security.companyInfo && security.companyInfo.date_format || 'DD/MM/YYYY'

		return <div className="create-meeting-modal-root">
			<FormItem
				name="meeting_name"
				itemProps={{
					label: __('Name')
				}}
				required
				validMessage={{
					required: __('Meeting name is required')
				}}

			>
				<Input placeholder="e.q. Q4 2020 board meeting" disabled={disabled} />
			</FormItem>
			<FormItem
				name="date"
				itemProps={{
					label: __('Date')
				}}
				required
				$formatter={(value, setValue) => { return formatDateForDatePicker(value)}}
				$parser={val => moment(val).format(formatter)}
				validMessage={{
					required: __('Meeting date is required')
				}}
			>
				<DateSelector disabled={disabled} allowClear placeholder={formatter} allowClear={false} disabledDate={val => !moment().startOf('day').isBefore(val)} />
			</FormItem>
			<FormItem
				name="time"
				itemProps={{
					label: 'Time'
				}}
				required
				$parser={([start, end], setView) => { return [moment(start).format('HH:mm'), moment(end).format('HH:mm')]}}
				validMessage={{
					required: __('Meeting time is required')
				}}
			>
				<RangePicker disabled={disabled} allowClear={false} format="HH:mm" />
			</FormItem>
			<FormItem
				name="timeZone"
				itemProps={{
					label: __('Time zone')
				}}
				$defaultValue='Etc/GMT-8 Hong Kong SAR'
				required
				validMessage={{
					required: __('TimeZone is required')
				}}
			>
				<TimeZone disabled={disabled} className="form-select"  placeholder={__("Select")}/>
			</FormItem>
			<FormItem
				name="meeting_location"
				itemProps={{
					label: __('Location')
				}}
				required
				validMessage={{
					required: __('Location is required')
				}}
			>
				<Input.TextArea disabled={disabled} rows={4} placeholder={__("Please fill out location of meeting")}/>
			</FormItem>
			<FormItem
				name="meeting_link"
				itemProps={{
					label: __('Conference link')
				}}
				pattern={urlReg}
				validMessage={{
					required: __('Meeting link is required'),
					pattern: __('Please enter the correct URL')
				}}
			>
				<Input disabled={disabled} placeholder="e.g. http://sprout.zoom.hk/" />
			</FormItem>

			<div className="btns-wrap"><Button disabled={disabled} loading={loading} type="primary" onClick={this.onSubmit}>{__('Save')}</Button></div>
		</div>
	} 
}

const CreateMeetingModal = withForm(MeetingModalContent)

export default function openCreateMeetingModal(callback, data, name) {
	return Modal.open({
		component: props => <ModalContent close={props.close} title={ name || __("Create board meeting")}> <CreateMeetingModal data={data} close={props.close} callback={callback} /></ModalContent>,
		width: 600,
		className: 'sprout-modal'
	})
}


