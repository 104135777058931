import React from "react";
import Utils, { I,formatNumber, filterAmount, calculator } from "Utils";
import { post } from 'srcPath/http';
import security from 'stores/security';
import moment from "moment";
import DateSelector from "../../../../../components/DateSelector";
import { formatDate } from "Utils/format/formatDate";
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import {format} from "echarts/src/export";
import { printf } from 'Utils/i18n';

class SettleRsu extends React.Component {
    constructor(props){
        super(props);
        this.state={
            dateFormat:"DD/MM/YYYY",
            data:{
                settlement_date:"",
                quantity_to_settle:0,
            },
            canBeSettledQuantity:0,
            saveStatus: 0
        }
    }
    getDateRange=()=>{
        let startDate = security.companyInfo.date_of_incorporation
    }
    getQuantityToSettled=()=>{
        const { minus } = calculator;
        let canBeSettledQuantity = minus( this.props.curSelectSecurity.outstanding, this.props.curSelectSecurity.settled_quantity);
        this.setState({
            canBeSettledQuantity:canBeSettledQuantity
        })
    }
    refreshData =() =>{
        this.setState({
            data:{
                settlement_date:"",
                quantity_to_settle:0,
            }
        })
    }
    resetActive=()=>{
        window.$("#settleRsuModal .tab-pane").removeClass("active");
        window.$("#settle_step1").addClass("active");
        this.refreshData();
    }
    settleUnits=()=>{
        if(this.state.saveStatus == 1) return;
        this.setState({ saveStatus: 1})
        let requestData = this.state.data;
        requestData.id = this.props.curSelectSecurity.id;
        requestData.quantity_to_settle = Utils.resetNumberWithCommas(this.state.data.quantity_to_settle);
        post('settleRsu',requestData).then((response)=> {
            if(response.data.code == 0){
                // window.location.reload();
                // this.setState({
                //     exerciseStatus:0,
                // })
                this.setState({saveStatus: 0})
                this.refreshData();
                this.props.getEaList();
                window.$("#settleRsuModal").modal("hide")
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    /**
     * input change通用事件
     * @param isDecimal 是否需要小数
     * @param e
     * 5 digit
     */
    inputChange5Digit = (isDecimal,e) => {
        const { target: element } = e;
        const { name: dataKey } = element;
        const filter = filterAmount(e, 9999999999999, isDecimal, 5);
        let curData = this.state.data;
        curData[dataKey] = filter.value;
        this.setState({
            data: curData
        }, () => {
            element.selectionStart = element.selectionEnd = filter.cursorPositionX;
        });
    }
    checkSettledQuantity=()=>{
        let curData = this.state.data;
        const { minus } = calculator;
        if(minus(Utils.getNumFromFormatNum(curData.quantity_to_settle), Utils.getNumFromFormatNum(this.state.canBeSettledQuantity))>0){
            curData.quantity_to_settle = formatFractionalNumber(this.state.canBeSettledQuantity)
        }
        this.setState({
            data:curData
        })
    }
    checkEmptyError=()=>{
        if(this.state.data.settlement_date== "" && this.state.data.quantity_to_settle== ""){
            this.setState({
                emptyError:1,
            })
        }
    }
    setSettleDateChange = (key, moment_key, date) => {
        const { dateFormat } = this.state;
        let curData = this.state.data;
        curData[key] = date.format( dateFormat);
        curData[moment_key] = date;
        this.setState({
            data:curData
        });
    }
    render(){
        let curTextStatus = 1;
        for(let key in this.state.data){
            if(this.state.data[key].length ===0||this.state.data.quantity_to_settle==0){
                curTextStatus = 0;
            }
        }
        const isFractionalShare = security.companyInfo.fractional_control;
        const { data, dateFormat, canBeSettledQuantity } = this.state;
        const { minus } = calculator
        return(
            <div className="modal fade" id="settleRsuModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="modal-dialog tab-content dialog-top40">
                    <div className="modal-content content520 tab-pane active" id="settle_step1">
                        <div className="modal-header box-header">
                            <button type="button" onClick={this.refreshData} className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Settle')} {this.props.curSelectSecurity.custom_label_id}</h4>
                        </div>
                        <div className="modal-body padding202620">
                            <div className="body-one">
                                <form className="form-horizontal font_reset_form">
                                    <div className="form-group add-items ">
                                        <label className="col-xs-5 add-label">{__('Settlement date')}</label>
                                        <div className="col-xs-5 ">
                                            <DateSelector
                                                onChange={this.setSettleDateChange.bind(this, 'settlement_date', 'settlement_date_moment')}
                                                value={I(data['settlement_date_moment'], I(data['settlement_date'], '') ? moment(data['settlement_date'], dateFormat) : '')}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group add-items">
                                        <label className="col-xs-5 add-label">{__('Quantity to settle')}</label>
                                        <div className="col-xs-5">
                                            { isFractionalShare == 1 ?
                                                <input value={data.quantity_to_settle} onBlur={this.checkSettledQuantity} name="quantity_to_settle" onChange={this.inputChange5Digit.bind(this,true)}  className="form-control" type="text" placeholder={"e.g. "+this.state.canBeSettledQuantity} /> :
                                                <input value={data.quantity_to_settle} onBlur={this.checkSettledQuantity} name="quantity_to_settle" onChange={this.inputChange5Digit.bind(this,false)}  className="form-control" type="text" placeholder={"e.g. "+this.state.canBeSettledQuantity} />
                                            }
                                        </div>
                                        <p className="text-muted col-xs-7 col-xs-push-5 mb-0">{printf(__('Up to %s units can be settled.'),minus(Utils.resetNumberWithCommas(data.quantity_to_settle),canBeSettledQuantity)>=0 ? 0 : formatFractionalNumber( minus(canBeSettledQuantity,Utils.resetNumberWithCommas(data.quantity_to_settle)))) }</p>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer box-footer">
                            {curTextStatus==0 && <button type="button" onClick={this.checkEmptyError} className="btn boxbtn-to send-disabledBtn">{__('Next: review certificate details')}</button>}
                            {curTextStatus==1 && <button type="button" data-toggle="tab" data-target="#settle_step2" className="btn boxbtn-to bg-darkblue">{__('Next: review certificate details')}</button>}
                        </div>
                    </div>
                    <div className="modal-content content480 tab-pane " id="settle_step2">
                        <div className="modal-header box-header">
                            <a data-target="#settle_step1" className="back-btn" aria-controls="repurchase-step1" role="tab" data-toggle="tab"></a>
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.resetActive}>×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Settle')} {this.props.curSelectSecurity.custom_label_id}</h4>
                        </div>
                        <div className="modal-body padding2026">
                            <div className="body-one">
                                <p className="text-muted">{__('The following certificate will be issued.')}</p>
                                <table className="table merge-table review-relationship-table mb-0">
                                    <tbody>
                                    <tr>
                                        <td>{__('Certificate number')}</td>
                                        <td>{this.props.curSelectSecurity.custom_label_id} ({this.props.curSelectSecurity.security_type})</td>
                                    </tr>
                                    <tr>
                                        <td>{__('Shareholder name')}</td>
                                        <td>{this.props.curSelectSecurity.name}</td>
                                    </tr>
                                    <tr>
                                        <td>{__('Issue date')}</td>
                                        <td>{ formatDate(data.settlement_date)}</td>
                                    </tr>
                                    <tr>
                                        <td>{__('Number of shares')}</td>
                                        <td> {formatFractionalNumber(data.quantity_to_settle) }</td>
                                    </tr>
                                    <tr>
                                        <td>{__('Price per share')}</td>
                                        <td>{__('None')}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer box-footer invite-footer">
                            <button type="button" className="btn boxbtn-to bg-darkblue update-btn" onClick={this.settleUnits}>{__('Settle units')}</button>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
export default SettleRsu;