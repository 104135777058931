import React from 'react';
import { Link } from "react-router-dom";
import { post } from 'srcPath/http';
import Utils, { I } from "Utils";
//import $ from 'jquery';;
import axios from "axios";
import moment from "moment";
import DateSelector from "../../../../../components/DateSelector";

class EditCnTermsModal extends React.Component {
    constructor(props){
        super(props);
        this.state={
            data:
                {
                    convertible_type:"SAFE",
                    terms_name:"SAFE 2",
                    prefix:"CN",
                    board_approval_date:"",
                    valuation_cap:"",
                    conversion_discount:"",
                    change_in_control_percent:"",
                    conversion_trigger:"",
                    interest_rate:"10",
                    interest_accrual_end_date:"",
                    day_count_convention:"",
                    interest_accrual_period:"interest_accrual_period",
                    interest_start_date:"",
                    interest_compounding_period:"interest_compounding_period",
                    interest_payout:"interest_payout",
                    day_count_basis:"day_count_basis",
                    note_purchase_agreement_file:{name:"No file uploaded",file_obj:""},
                    form_of_convertible_file:{name:"No file uploaded",file_obj:""},
                    uploadProgress:0,
                },
            dateFormat:"DD/MM/YYYY",
            cnTermsRequiredKey:["terms_name","prefix"],
            modifyStatus:0,
            existingPrefix:["abc"],
            is_able_delete:"",
            isExisingTerms:0 ,
            isShowEmptyError:0,
            checkedArr:{
                convertible_debt:"",
                convertible_security:"",
                safe:"",
            },
            dayCountList:[
                "30/360",
                "Actual/365",
                "Actual/360",
            ],
            interestAccrualPeriodList:[
                "Daily",
                "Weekly",
                "Monthly",
                "Monthly(first day of month)",
                "Semi-annually",
                "Annually",
                "Quarterly(calendar year)",
                "Semi-annually(calendar year)",
                "Annually(calendar year)",
            ],
            interestCompoundingPeriodList:[
                "Simple interest",
                "Daily",
                "Monthly",
                "Semi-annually",
                "Annually",
                "Quarterly(calendar year)",
            ],
            interestPayoutList:[
                "Deferred",
                "Cash",
            ],

        }
    }

    handleCnTermDateChange = (key, moment_key, date) => {
        const { dateFormat } = this.state;
        let curData = this.state.data;
        curData[key] = date.format( dateFormat);
        curData[moment_key] = date;
        this.setState({
            data:curData
        });
    }
    recordData=(e)=>{
        let dataKey = e.target.name;
        let curData = this.state.data;
        let dataVal = e.target.value;
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        })
    }
    initData=(key,initData)=>{
        let curData = this.state.data;
        curData.id = initData.id;
        this.setState({
            data:curData,
            is_able_delete:initData.is_able_delete,
        })
        this.getConvertibleTermsInfo();
    }
    getConvertibleTermsInfo=()=>{
        let requestData = this.state.data;
        post('getCnTermInfo', {id:requestData.id}).then((response)=> {
            if(response.data.code === 0){
                requestData = response.data.data;
                requestData.note_purchase_agreement_file.name = response.data.data.note_purchase_agreement_file.client_name||"No file uploaded";
                requestData.form_of_convertible_file.name = response.data.data.form_of_convertible_file.client_name||"No file uploaded";
                let checkedArr = this.state.checkedArr;
                for(let oneKey in checkedArr){
                    checkedArr[oneKey] = "";
                }
                checkedArr[requestData.convertible_type] = "checked";
                this.setState({
                    data:requestData,
                    checkedArr:checkedArr
                })
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
            //用到this要注意this指向
        }).catch(function (error) {
            console.log(error);
        });
    }
    resetAction=()=>{
        window.$("#EditConvertibleTermsModal .tab-pane").removeClass("active");
        window.$("#terms_box").addClass("active");
        for(let i = 0; i<this.state.cnTermsRequiredKey.length;i++){
            $(".edit_"+this.state.cnTermsRequiredKey[i]).removeClass("has-error")
        }
        this.setState({
            isShowEmptyError:0,
        })
    }
    changeDetailsData=(key)=>{
        window.$(".alert_tip").removeClass("hidden")
        let curData = this.state.data;
        curData.terms_name = key;
        this.setState({
            data:curData
        })
    }
    setConvertibleType=(typeName)=>{
        let curData = this.state.data;
        let curCheckedArr = this.state.checkedArr;
        for(let rain in curCheckedArr){
            curCheckedArr[rain] = "";
        }
        curCheckedArr[typeName] = "checked";

        curData.convertible_type = typeName;
        this.setState({
            data:curData,
            checkedArr:curCheckedArr
        });
        if(typeName === "convertible_debt"){
            this.setState({
                showStatusClass:""
            })
        }else{
            this.setState({
                showStatusClass:"conver-hidden"
            })
        }
    }
    setFileName=(key,e)=>{
        let fileObj = e.target.files[0];
        Utils.uploadFile(fileObj).then((result)=>{
            let curData = this.state.data;
            curData[key].name = fileObj.name;
            curData[key].file_obj = result;
            this.setState({
                data:curData
            });
            $("."+key).removeClass("hidden");
            console.log(curData);
        });
    }
    fileDelete=(key,e)=>{
        this.refs[key].value="";
        let curData = this.state.data;
        curData[key].name="No file uploaded";
        curData[key].file_obj="";
        $("."+key).addClass("hidden");
        this.setState({
            data:curData
        })
    }
    recordNumData=(e)=>{
        let curData = this.state.data;
        let dataKey = e.target.name;
        let dataVal =e.target.value;
        if(dataKey=="interest_rate"){
            curData[dataKey] = Utils.getNumFormatWithTwoComma(dataVal);
            if(e.target.value>0){
                window.$(".rate-dropdown").removeClass("hidden")
            }else{
                window.$(".rate-dropdown").addClass("hidden")
            }
        }else if(dataKey=="conversion_discount"){
            curData[dataKey] = Utils.getNumFormatWithTwoComma(dataVal);
        }else{
            curData[dataKey] = Utils.checkNumInput(dataVal);
        }
        this.setState({
            data:curData
        })

    }
    checkStatus=()=>{
        let curData = this.state.data;
        for(let i = 0; i<this.state.cnTermsRequiredKey.length;i++){
            if(curData[this.state.cnTermsRequiredKey[i]]==""){
                $(".edit_"+this.state.cnTermsRequiredKey[i]).addClass("has-error")
            }else{
                $(".edit_"+this.state.cnTermsRequiredKey[i]).removeClass("has-error")
            }
        }
        this.setState({
            isShowEmptyError:1
        })
    }
    editCnTerms=()=>{
        let isLegalPrefix = 1; //默认为合法的输入
        for(let i=0;i<this.state.existingPrefix.length;i++){
            if(this.state.data.prefix == this.state.existingPrefix[i]){
                isLegalPrefix = 0;  //有重复改为非法
            }
        }
        if(isLegalPrefix === 0){
            return;
        }
        if(Utils.isLocal()){
            this.props.history.push("/home/securities/convertibles");
            return;
        }

        if(this.state.modifyStatus === 1){
            return;
        }
        this.setState({
            modifyStatus:1
        })
        this.setState({
            isShowUploadProgress:1
        })
        axios.post('https://api.getsprout.co', {
            action:"editConvertibleNote",
            company_id:Utils.getLocalCompanyInfo().company_id,
            data:this.state.data
        },{
            'Content-Type':'application/json;charset=UTF-8',
            onUploadProgress:(progressEvent)=>{
                let complete = (progressEvent.loaded / progressEvent.total * 100 | 0);
                console.log('上传 ' + complete+'%') ;
                this.setState({
                    uploadProgress:complete
                });
                if(complete == 100){
                    this.setState({
                        isShowUploadProgress:0
                    })
                }
            }
        }).then((response)=> {
            if(response.data.code === 0){
                this.setState({
                    modifyStatus:0
                });
                this.props.closeTermsListModal();
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
            //用到this要注意this指向
        }).catch(function (error) {
            console.log(error);
        });
    }
    autoFill2Decimals=(e)=>{
        let dataVal = e.target.value;
        let dataKey = e.target.name;
        let curData = this.state.data;
        if(dataKey=="conversion_discount"||dataKey=="interest_rate"){
            if(dataVal==""){
                curData[dataKey]="0.00";
            }else if(dataVal.indexOf(".")==0){
                curData[dataKey]=Utils.getNumWith2Decimal( "0"+dataVal);
            }else{
                curData[dataKey] = Utils.getNumWith2Decimal(dataVal);
            }
        }else{
            curData[dataKey] = dataVal
        }
        this.setState({
            data:curData
        })
    }

    goToTermsList=()=>{
        this.props.goToTermsList();
    }
    shutDown=()=>{
        window.$(".alert_tip").addClass("hidden")
    }
    showData=()=>{
        // console.log("sssss")
    }

    recordNumCurrency=(e)=>{
        let dataKey = e.target.name;
        let dataVal = Utils.checkNumInputWithStr (e.target.value,"$ ",0);
        let curData = this.state.data;
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        })
    }

    render(){
        let disabledStatus = true;
        if(this.state.is_able_delete==1){
            disabledStatus = false;
        }
        let curTextStaus = 1;
        if(this.state.data.prefix.length === 0 || this.state.data.terms_name.length ===0){
            curTextStaus = 0;
        }
        let isExistingTerms = 'no';
        if(this.state.existingPrefix.length > 0){
            isExistingTerms = "yes";
        }

        let isLegalPrefix = 1; //默认为合法的输入
        for(let i=0;i<this.state.existingPrefix.length;i++){
            if(this.state.data.prefix == this.state.existingPrefix[i]){
                isLegalPrefix = 0;  //有重复改为非法
            }
        }
        const { data, dateFormat } = this.state;
        return(
            <div className="modal-content conver-content">
                <div className="modal-header box-header">
                    <button type="button" onClick={this.resetAction} className="close shut-btn" data-toggle="tab" data-target="#termsBox">×</button>
                    <h4 className="modal-title" id="myModalLabel">{this.state.data.terms_name}</h4>
                </div>
                <div className="modal-body equity-body">
                    {this.state.is_able_delete==0 &&
                    <div className="alert_tip bg-orange width100perc clearfix marginBott20">
                        <button type="button" className="close shut-btn terms_shut_btn offset" onClick={this.shutDown}>×</button>
                        <div className="flexDiv onset">
                            <span className="terms-tip glyphicon glyphicon-exclamation-sign white-text"></span>
                            <div>
                                <p className="white-text mb-0">{__('These terms are in use.')}</p>
                                <p className="white-text mb-0">{__('Information about these terms can be viewed but not modified.')}</p>
                            </div>
                        </div>
                    </div>
                    }
                    <div>
                        <div className="equity-item-header bg-seablue">
                            <h5>{__('Basic terms information')}</h5>
                        </div>
                        <form className="form-horizontal equity-item-form">
                            <div className="form-group">
                                <label className="col-xs-5 add-label">{__('Convertible type ')}</label>
                                <div className="col-xs-7 no-paddingright">
                                    <div className="checkbox send-check text-muted">
                                        <input checked={this.state.checkedArr.safe} disabled={disabledStatus} onChange={this.setConvertibleType.bind(this,"safe")} value="safe" type="radio" name="convertibleNote" className="send-box" id="send-box003"/>
                                        <label className="send-div" htmlFor="send-box003"></label><span className="send-label"> &nbsp;&nbsp;{__('SAFE')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-xs-7 col-xs-push-5 no-paddingright">
                                    <div className="checkbox send-check text-muted">
                                        <input checked={this.state.checkedArr.convertible_debt} disabled={disabledStatus} onChange={this.setConvertibleType.bind(this,"convertible_debt")} type="radio" value="convertible_debt" name="convertibleNote" className="send-box" id="send-box001"/>
                                        <label className="send-div" htmlFor="send-box001"></label><span className="send-label"> &nbsp;&nbsp;{__('Convertible debt')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-xs-7 col-xs-push-5 no-paddingright">
                                    <div className="checkbox send-check text-muted">
                                        <input checked={this.state.checkedArr.convertible_security} disabled={disabledStatus} onChange={this.setConvertibleType.bind(this,"convertible_security")} value="convertible_security" type="radio" name="convertibleNote" className="send-box" id="send-box002" />
                                        <label className="send-div" htmlFor="send-box002"></label><span className="send-label"> &nbsp;&nbsp;{__('Convertible security')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group ">
                                <label className="col-xs-5 add-label">{__('Terms name')}</label>
                                <div className="col-xs-7 edit_terms_name">
                                    <input value={this.state.data.terms_name} onChange={this.recordData} disabled={disabledStatus} name="terms_name" className="form-control" type="text" placeholder={__("e.g. SAFE $5M valuation cap")} />
                                </div>
                            </div>
                            <div className="form-group ">
                                <label className="col-xs-5 add-label convertible-tipbox">
                                    {__('Prefix')}
                                    <a className="convertible-ques">
                                        <p className="convertible-tiptext tiptext-top86">{__('Convertible block prefixes can be reused for organizational purposes.')}</p>
                                    </a>
                                </label>
                                <div className="col-xs-4 edit_prefix">
                                    <input value={this.state.data.prefix}  onChange={this.recordData} disabled={disabledStatus} name="prefix" className="form-control uppercase_form" type="text" placeholder="CN" />
                                </div>
                            </div>
                            <div className="form-group ">
                                <div className="col-xs-5">
                                    <label className=" add-label ">{__('Board approval date ')}<br /><i className="text-muted">- {__('Optional')}</i></label>
                                </div>
                                <div className="col-xs-4">
                                    <DateSelector
                                        disabled={disabledStatus}
                                        onChange={this.handleCnTermDateChange.bind(this, 'board_approval_date', 'board_approval_date_moment')}
                                        value={I(data['board_approval_date_moment'], I(data['board_approval_date'], '') ? moment(data['board_approval_date'], dateFormat) : '')}
                                        inputReadOnly={false}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div>
                        <div className="equity-item-header bg-seablue">
                            <h5>{__('Enter terms')}</h5>
                        </div>
                        <form className="form-horizontal equity-item-form">
                            <div className="form-group ">
                                <div className="col-xs-5">
                                    <label className=" add-label">{__('Valuation cap ')}<i className="text-muted">- {__('Optional')}</i></label>
                                </div>
                                <div className="col-xs-4">
                                    <input onChange={this.recordNumCurrency} disabled={disabledStatus} value={this.state.data.valuation_cap} name="valuation_cap" className="form-control" type="text" placeholder="e.g. $4,000,000" />
                                </div>
                            </div>
                            <div className="form-group ">
                                <div className="col-xs-5">
                                    <label className=" add-label">{__('Conversion discount ')}<br /><i className="text-muted">- {__('Optional')}</i></label>
                                </div>
                                <div className="col-xs-4">
                                    <div className="input-group">
                                        <input name="conversion_discount" onBlur={this.autoFill2Decimals} disabled={disabledStatus} onInput={this.recordNumData} value={this.state.data.conversion_discount} className="form-control" type="text" placeholder="e.g. 20" />
                                        <span className="input-group-addon" id="convertible-addon">%</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-xs-5 add-label convertible-tipbox">{__('Interest rate ')}<i className="text-muted">- {__('Optional')}</i>
                                    <a className="convertible-ques">
                                        <p className="convertible-tiptext tiptext-top50">{__('Annual Percentage Rate.')}</p>
                                    </a>
                                </label>
                                <div className="col-xs-4">
                                    <div className="input-group">
                                        <input onInput={this.recordNumData} onBlur={this.autoFill2Decimals} disabled={disabledStatus} value={this.state.data.interest_rate} name="interest_rate" className="form-control" type="text" placeholder="e.g. 10" />
                                        <span className="input-group-addon" id="convertible-addon">%</span>
                                    </div>
                                </div>
                            </div>

                            <div className="rate-dropdown hidden">
                                <div className="form-group">
                                    <label className="col-xs-6 add-label convertible-tipbox">{__('Interest accrual end date')}<br /><i className="text-muted">- {__('Optional')}</i>
                                        <a className="convertible-ques">
                                            <p className="convertible-tiptext tiptext-top86">{__('Total interest will be calculated up to this date instead of maturity date.')}</p>
                                        </a>
                                    </label>
                                    <div className="col-xs-6">
                                        <DateSelector
                                            disabled={disabledStatus}
                                            onChange={this.handleCnTermDateChange.bind(this, 'interest_accrual_end_date', 'interest_accrual_end_date_moment')}
                                            value={I(data['interest_accrual_end_date_moment'], I(data['interest_accrual_end_date'], '') ? moment(data['interest_accrual_end_date'], dateFormat) : '')}
                                            inputReadOnly={false}
                                        />
                                    </div>
                                </div>
                                <div className="form-group ">
                                    <label className="col-xs-6 add-label convertible-tipbox">{__('Day count convention')}<br /><i className="text-muted">- {__('Optional')}</i>
                                        <a className="convertible-ques">
                                            <p className="convertible-tiptext tiptext-top86">{__('Total interest will be calculated starting from this date instead of the issue date.')}</p>
                                        </a>
                                    </label>
                                    <div className="col-xs-6">
                                        <select onChange={this.recordData} disabled={disabledStatus} value={this.state.data.day_count_convention} name="day_count_convention" className="form-control select-container select-bgwhite">
                                            <option value=""></option>
                                            <option>30/360</option>
                                            <option>Actual/365</option>
                                            <option>Actual/360</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-xs-6 add-label convertible-tipbox">{__('Interest accrual period')}<br /><i className="text-muted">- {__('Optional')}</i></label>
                                    <div className="col-xs-6">
                                        <select onChange={this.recordData} disabled={disabledStatus} value={this.state.data.interest_accrual_period} name="interest_accrual_period" className="form-control select-container select-bgwhite">
                                            <option value=""></option>
                                            {this.state.interestAccrualPeriodList.map((value,key)=>{
                                                return(
                                                    <option key={key} value={value}>{value}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-xs-6 add-label convertible-tipbox">{__('Interest start date')}<br /><i className="text-muted">- {__('Optional')}</i>
                                        <a className="convertible-ques">
                                            <p className="convertible-tiptext tiptext-top86">{__('Total interest will be calculated starting from this date instead of the issue date.')}</p>
                                        </a>
                                    </label>
                                    <div className="col-xs-6">
                                        <DateSelector
                                            disabled={disabledStatus}
                                            onChange={this.handleCnTermDateChange.bind(this, 'interest_start_date', 'interest_start_date_moment')}
                                            value={I(data['interest_start_date_moment'], I(data['interest_start_date'], '') ? moment(data['interest_start_date'], dateFormat) : '')}
                                            inputReadOnly={false}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-xs-6 add-label convertible-tipbox">{__('Interest compounding period')}<br /><i className="text-muted">- {__('Optional')}</i></label>
                                    <div className="col-xs-6">
                                        <select onChange={this.recordData} value={this.state.data.interest_compounding_period} name="interest_compounding_period" className="form-control select-container select-bgwhite">
                                            <option value=""></option>
                                            {this.state.interestCompoundingPeriodList.map((value,key)=>{
                                                return(
                                                    <option key={key} value={value}>{value}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-xs-6 add-label convertible-tipbox">{__('Interest payout ')}<i className="text-muted">- {__('Optional')}</i></label>
                                    <div className="col-xs-6">
                                        <select onChange={this.recordData} disabled={disabledStatus} value={this.state.data.interest_payout} name="interest_payout" className="form-control select-container select-bgwhite">
                                            <option value=""></option>
                                            <option>Deferred</option>
                                            <option>Cash</option>
                                        </select>
                                    </div>
                                </div>
                            </div>


                            {this.state.data.interest_rate!="" && this.showData()}
                            <div className={this.state.showStatusClass}>
                                <div className="form-group">
                                    <div className="col-xs-5 convertible-tipbox">
                                        <label className=" add-label ">{__('Change in control percent ')}<br /><i className="text-muted">- {__('Optional')}</i>
                                            <a className="convertible-ques">
                                                <p className="convertible-tiptext tiptext-top124">{__("Use this field if there's any premium or multiplier applied to the principal in the event of a change in control prior to the maturity date.")}</p>
                                            </a>
                                        </label>
                                    </div>
                                    <div className="col-xs-4">
                                        <div className="input-group">
                                            <input onInput={this.recordNumData} disabled={disabledStatus} value={this.state.data.change_in_control_percent} name="change_in_control_percent" className="form-control" type="text" placeholder="e.g. 25" />
                                            <span className="input-group-addon" id="convertible-addon">%</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col-xs-5 convertible-tipbox">
                                        <label className="add-label ">{__('Conversion trigger ')}<br /><i className="text-muted">- {__('Optional')}</i>
                                            <a className="convertible-ques">
                                                <p className="convertible-tiptext tiptext-top86">{__('The minimum dollar size of the next financing round required for the notes to convert.')}</p>
                                            </a>
                                        </label>
                                    </div>
                                    <div className="col-xs-4">
                                        <input onChange={this.recordNumCurrency} disabled={disabledStatus} value={this.state.data.conversion_trigger} name="conversion_trigger" className="form-control" type="text" placeholder="e.g. $1,000,000" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-xs-5 add-label convertible-tipbox">
                                        {__('Day count basis')}
                                        <a className="convertible-ques">
                                            <p className="convertible-tiptext tiptext-top86">{__('Choose the day count basis that applies to this convertible note.')}</p>
                                        </a>
                                    </label>
                                    <div className="col-xs-7">
                                        <select onChange={this.recordData} disabled={disabledStatus} value={this.state.data.day_count_basis} name="day_count_basis" className="form-control select-container select-bgwhite">
                                            <option value=""></option>
                                            <option>30/360</option>
                                            <option>Actual/365</option>
                                            <option>Actual/360</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div>
                        <div className="equity-item-header bg-seablue">
                            <h5>{__('Upload term documents')}<i className="optional-color optional-font">- {__('Optional')}</i></h5>
                        </div>
                        <form className="form-horizontal equity-item-form">
                            <p className="text-muted file-seletext">{__('Upload default term documents. You can replace them when you issue convertibles.')}</p>
                            <div className="form-group ">
                                <label className="col-xs-5 add-label">{__('Note purchase agreement')}</label>
                                <div className="col-xs-3">
                                    <div className="select-btn convertiblenote-select-btn conver-seldark equ-choose">
                                        {__('Choose a file')}
                                        <label htmlFor="files-select" className="sr-only" >File input</label>
                                        <input disabled={disabledStatus} onChange={this.setFileName.bind(this,"note_purchase_agreement_file")} ref="note_purchase_agreement_file" type="file" />
                                    </div>
                                </div>
                                <div className="col-xs-4 amend-file-name no-paddingleft">
                                    <span className="close files-del hidden note_purchase_agreement_file" onClick={this.fileDelete.bind(this,'note_purchase_agreement_file')}>x</span>
                                    <p className="text-muted">{this.state.data.note_purchase_agreement_file.name}</p>
                                </div>

                            </div>
                            <div className="form-group ">
                                <label className="col-xs-5 add-label">{__('Form of convertible')}</label>
                                <div className="col-xs-3 ">
                                    <div className="select-btn convertiblenote-select-btn conver-seldark equ-choose">
                                        {__('Choose a file')}
                                        <label htmlFor="files-select" className="sr-only" >File input</label>
                                        <input disabled={disabledStatus} onChange={this.setFileName.bind(this,"form_of_convertible_file")} ref="form_of_convertible_file" type="file" />
                                    </div>
                                </div>
                                <div className="col-xs-4 amend-file-name no-paddingleft">
                                    <span onClick={this.fileDelete.bind(this,'form_of_convertible_file')} className="close files-del hidden form_of_convertible_file">x</span>
                                    <p className="text-muted ">{this.state.data.form_of_convertible_file.name}</p>
                                </div>
                            </div>
                            {this.state.isShowUploadProgress === 1 &&
                            <div>
                                <div className="progress file-progress lib-progress">
                                    <div className="progress-bar progress-bar-info progress-noshadow" role="progressbar" aria-valuenow={this.state.uploadProgress}
                                         aria-valuemin="0" aria-valuemax="100" style={{width:this.state.uploadProgress+"%"}}>
                                        <span className="sr-only">{this.state.uploadProgress}% Complete</span>
                                    </div>
                                </div>

                                <div style={{textAlign:"center"}}>{this.state.uploadProgress}% {__('Complete')}</div>
                            </div>
                            }
                            {this.state.isShowEmptyError ===1 &&
                            <div className="alert alert-warning alert-dismissible pink-box eq-tipbox" role="alert">
                                <p>{__('Required field(s) can not be empty.')}</p>
                            </div>
                            }
                        </form>
                    </div>
                </div>
                {isExistingTerms !== "yes" &&
                <div className="modal-footer box-footer paddingBottom20">
                    {curTextStaus ===1 &&<button id="createCnButton" onClick={this.editCnTerms} type="button" className="btn boxbtn-to bg-darkblue update-btn">{this.state.modifyStatus==0 ? __("Modify terms"):__("Modifying")}</button>}
                    {curTextStaus ===0 &&<button type="button" onClick={this.checkStatus} className="btn boxbtn-to bg-darkblue update-btn">{__('Modify terms')}</button>}
                </div>
                }
                {isExistingTerms === "yes" &&
                <div className="modal-footer box-footer paddingBottom20">
                    {curTextStaus ===1 &&<button id="createCnButton" onClick={this.editCnTerms} type="button" className="btn boxbtn-to bg-darkblue width160">{this.state.modifyStatus==0 ? __("Modify terms"):__("Modifying")}</button>}
                    {curTextStaus ===0 &&<button type="button" onClick={this.checkStatus} className="btn boxbtn-to bg-darkblue width160">{__('Modify terms')}</button>}
                </div>
                }
            </div>
        )
    }
}
export default EditCnTermsModal;