import React from 'react';
import axios from "axios/index";
import Utils, { formatNumber } from "Utils";
import Loading from 'components/Loading';
import { formatDate } from "Utils/format/formatDate";
import { certificateRelationship } from 'Utils/constant';
import formatFractionalNumber from "Utils/format/formatFractionalNumber";
import {Empty} from "antd";

class ViewStakeholderHoldings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                id: "",
            },
            loadingStatus: 1,
            propsKey: "",
            detailsList: {
                name: "",
                email: "",
                passport_or_national_id: "",
                employee_id: "",
                issue_date_relationship: "",
                relationship: "",
                address: "",
                // city:"",
                // state:"",
                // post_code:"",
                country_region: "",
                date_of_birth: "",
            },
            holdingsData: {
                certificatesDataList: [],
                convertiblesDataList: [],
                equityAwardDataList: [],
            },
            relationshipList: [],
            jobTitleList: [],
        }
    }
    initData = (key, initData) => {
        // window.$("#ViewSthHoldingModal .tab-pane").removeClass("active");
        // window.$("#holdings").addClass("active");
        this.setState({
            // relationshipList:initData.relationshipDataList,
            // jobTitleList:initData.jobTitleList,
            propsKey: key
        });
        axios.post('https://api.getsprout.co', {
            action: 'getStakeholderHoldingList',
            company_id: Utils.getLocalCompanyInfo().company_id,
            data: { id: this.props.curSelectStakeholder.id },
        }).then((response) => {
            if (response.data.code === 0) {
                this.setState({
                    detailsList: response.data.data.detailsList,
                    holdingsData: response.data.data.holdingsData,
                    relationshipList: response.data.data.relationshipList,
                    jobTitleList: response.data.data.jobTitleList,
                    loadingStatus: 0
                })
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    getNameLength = (name) => {
        let l = name.length
        if (l > 11 && l < 14) {
            return "f16 holders-h3"
        } else if (l >= 14) {
            return "f12 holders-h3"
        } else {
            return "holders-h3"
        }
    }
    render() {
        let curStakeholderData = this.state.detailsList
        const { holdingsData, loadingStatus } = this.state
        const csLength = holdingsData.certificatesDataList?.length
        const eaLength = holdingsData.equityAwardDataList?.length
        const cnLength = holdingsData.convertiblesDataList?.length
        const warrantLength = holdingsData.warrantsDataList?.length
        const emptyValue = <td className="none_entered">{__('None Entered')}</td>
        return (
            <div className="modal fade" id="ViewSthHoldingsModal" tabIndex="-1" role="dialog">

                <div className="modal-dialog dialog960 dialog-top40">
                    <div className="modal-content content960 min-height no-border-radius">
                        {loadingStatus == 1 &&
                            <div className="loading_position stakeholder_loading_position">
                                <Loading />
                            </div>
                        }
                        {loadingStatus == 0 &&
                            <div className="viewHoldingsBox">
                                <div className="holdings-navBox">
                                    <p>{__('Stakeholder')}</p>
                                    <p className={this.getNameLength(this.state.detailsList.name)}>{this.state.detailsList.name}</p>
                                    <ul className="nav holdings-navUl" role="tablist">
                                        <li><a role="tab" aria-controls="details" data-toggle="tab" href="#details">{__('Details')}</a></li>
                                        <li className="active"><a role="tab" aria-controls="holdings" data-toggle="tab" href="#holdings">{__('Holdings')}</a></li>
                                        <li><a role="tab" aria-controls="relationship" data-toggle="tab" href="#viewRelationship">{__('Relationship')}</a></li>
                                        <li><a role="tab" aria-controls="jobtitle" data-toggle="tab" href="#jobtitle">{__('Job title')}</a></li>
                                    </ul>
                                </div>
                                <div className="tab-content viewHoldingsContent">
                                    <div id="details" className="tab-pane">
                                        <p className="viewSthHoldings-p">{__('Stakeholder')}</p>
                                        <table className="holdings-detailsTable">
                                            <tbody>
                                                <tr>
                                                    <td className="width50perc">{__('Full name')}</td>
                                                    {curStakeholderData.name !== "" ?
                                                        <td className="width50perc">{curStakeholderData.name}</td>:
                                                        <td className="none_entered width50perc">{__('None Entered')}</td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <td>{__('Email')}</td>
                                                    {curStakeholderData.email ?
                                                        <td>{curStakeholderData.email}</td> : emptyValue
                                                    }
                                                </tr>
                                                <tr>
                                                    <td>{__('Passport or National ID')}</td>
                                                    {curStakeholderData.passport_or_national_id !== "" ?
                                                        <td>{curStakeholderData.passport_or_national_id}</td>: emptyValue
                                                    }
                                                </tr>
                                                <tr>
                                                    <td>{__('Employee ID')}</td>
                                                    {curStakeholderData.employee_id !== "" ?
                                                        <td>{curStakeholderData.employee_id}</td> : emptyValue
                                                    }
                                                </tr>
                                                <tr>
                                                    <td>{__('Issue date relationship')}</td>
                                                    {curStakeholderData.issue_date_relationship !== "" ?
                                                        <td>{curStakeholderData.issue_date_relationship}</td> : emptyValue
                                                    }
                                                </tr>
                                                <tr>
                                                    <td>{__('Relationship')}</td>
                                                    {curStakeholderData.relationship !== "" ?
                                                        <td>{certificateRelationship[curStakeholderData.relationship]}</td> : emptyValue
                                                    }
                                                </tr>
                                                <tr>
                                                    <td>{__('Address')}</td>
                                                    {curStakeholderData.address !== "" ?
                                                        <td>{curStakeholderData.address}</td> : emptyValue
                                                    }
                                                </tr>
                                                <tr>
                                                    <td>{__('Country')}</td>
                                                    {curStakeholderData.country_region !== "" ?
                                                        <td>{curStakeholderData.country_region}</td> : emptyValue
                                                    }
                                                </tr>
                                                <tr>
                                                    <td>{__('Date of birth')}</td>
                                                    {curStakeholderData.date_of_birth !== "" ?
                                                        <td>{formatDate(curStakeholderData.date_of_birth)}</td> : emptyValue
                                                    }
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div id="holdings" className="tab-pane active">
                                        <ul className="nav nav-pills esp-navbox" role="tablist">
                                            {csLength >0 &&
                                            <li className={csLength > 0  ? 'active' : ''}>
                                                <button className="btn wdt200 vesting-a" type="button" href="#certificates" aria-controls="certificates" role="tab" data-toggle="tab">{__('Certificates')}</button>
                                            </li>
                                            }
                                            { cnLength > 0 &&
                                                <li role="presentation" className={(csLength == 0 && eaLength == 0 && cnLength >0) ? 'active' : ''}>
                                                    <button className="btn wdt200 vesting-a" type="button" href="#convertibles" aria-controls="convertibles" role="tab" data-toggle="tab">{__('Convertibles')}</button>
                                                </li>
                                            }
                                            {eaLength > 0 &&
                                                <li role="presentation" className={(csLength == 0 && eaLength > 0) ? 'active' : ''}>
                                                    <button className="btn wdt200 vesting-a" type="button" href="#holdings_equityAwards" aria-controls="holdings_equityAwards" role="tab" data-toggle="tab">{__('Equity awards')}</button>
                                                </li>
                                            }
                                            {warrantLength > 0 &&
                                            <li role="presentation" className={(csLength == 0 && eaLength == 0 && cnLength== 0 && warrantLength > 0) ? 'active' : ''}>
                                                <button className="btn wdt200 vesting-a" type="button" href="#holdings_warrant" aria-controls="holdings_warrant" role="tab" data-toggle="tab">{__('Warrants')}</button>
                                            </li>
                                            }
                                        </ul>
                                        <div className="tab-content">
                                            {csLength == 0 && cnLength == 0 && eaLength == 0 && warrantLength == 0 &&
                                            <Empty/>
                                            }
                                            <div className={csLength > 0  ? 'tab-pane active' : 'tab-pane'} id="certificates">
                                                {csLength >= 1 &&
                                                    <table className="table viewHoldings-table">
                                                        <tbody>
                                                            <tr className="first-row">
                                                                <td className="">{__('Security')}</td>
                                                                <td>{__('Status')}</td>
                                                                <td>{__('Shares')}</td>
                                                                <td>{__('Vesting schedule')}</td>
                                                                <td>{__('Issue date')}</td>
                                                            </tr>
                                                            {holdingsData.certificatesDataList.map((value, key) => {
                                                                let discColor = 'green-disc'
                                                                if(value.status == 'Exercisable') discColor = 'yellow-disc'
                                                                if (value.status != 'Outstanding' && value.status != 'Issued' && value.status != 'Exercisable') {
                                                                    discColor = 'red-disc'
                                                                }
                                                                return (
                                                                    <tr key={key} className="holdings-tr">
                                                                        <td>{value.security}</td>
                                                                        <td className={discColor}>&nbsp;&nbsp;{value.status}</td>
                                                                        <td>{formatFractionalNumber(value.shares)}</td>
                                                                        <td>{value.vesting=='No vesting' ? 'No vesting schedule' : value.vesting}</td>
                                                                        <td>{formatDate(value.issued)}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                }
                                            </div>

                                            <div className={(cnLength > 0 && csLength == 0 && eaLength == 0) ? 'tab-pane active' : 'tab-pane'} id="convertibles">
                                                {cnLength >= 1 &&
                                                    <table className="table viewHoldings-table">
                                                        <tbody>
                                                            <tr className="first-row">
                                                                <td>{__('Security')}</td>
                                                                <td>{__('Status')}</td>
                                                                <td>{__('Dollar amount')}</td>
                                                                <td>{__('Interest Rate')}</td>
                                                                <td>{__('Issue date')}</td>
                                                            </tr>

                                                            {holdingsData.convertiblesDataList.map((value, key) => {
                                                                let cnCircle = 'green-disc'
                                                                if(value.status == 'Exercisable') cnCircle = 'yellow-disc'
                                                                if (value.status != 'Outstanding' && value.status != 'Issued' && value.status != 'Exercisable') {
                                                                    cnCircle = 'red-disc'
                                                                }
                                                                return (
                                                                    <tr key={key} className="holdings-tr">
                                                                        <td>{value.security}</td>
                                                                        <td className={cnCircle}>&nbsp;&nbsp;{value.status}</td>
                                                                        <td>{formatFractionalNumber(value.dollar_amount)}</td>
                                                                        <td>{formatFractionalNumber(value.interest_rate)}</td>
                                                                        <td>{formatDate(value.issued)}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                }
                                            </div>

                                            <div className={( csLength == 0 && eaLength > 0) ? 'tab-pane active' : 'tab-pane'} id="holdings_equityAwards">
                                                {eaLength >= 1 &&
                                                    <table className="table viewHoldings-table">
                                                        <tbody>
                                                            <tr className="first-row">
                                                                <td>{__('Security')}</td>
                                                                <td>{__('Status')}</td>
                                                                <td>{__('Vesting schedule')}</td>
                                                                <td>{__('Grant date')}</td>
                                                                <td>{__('Type')}</td>
                                                                <td>{__('Remaining')}</td>
                                                            </tr>
                                                            {holdingsData.equityAwardDataList.map((value, key) => {
                                                                let eaCircle = 'green-disc'
                                                                if(value.status == 'Exercisable') eaCircle = 'yellow-disc'
                                                                if (value.status != 'Outstanding' && value.status != 'Issued' && value.status != 'Exercisable') {
                                                                    eaCircle = 'red-disc'
                                                                }
                                                                return (
                                                                    <tr key={key} className="holdings-tr">
                                                                        <td>{value.security}</td>
                                                                        <td className={eaCircle}>&nbsp;&nbsp;{value.status}</td>
                                                                        <td>{value.vesting=='No Vesting' ? 'No vesting schedule' : value.vesting}</td>
                                                                        <td>{formatDate(value.grant_date)}</td>
                                                                        <td>{value.type}</td>
                                                                        <td>{formatFractionalNumber(value.remaining)}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                }
                                            </div>

                                            <div className={( csLength == 0 && warrantLength > 0) ? 'tab-pane active' : 'tab-pane'} id="holdings_warrant">
                                                {warrantLength >= 1 &&
                                                <table className="table viewHoldings-table">
                                                    <tbody>
                                                    <tr className="first-row">
                                                        <td>{__('Security')}</td>
                                                        <td>{__('Status')}</td>
                                                        <td>{__('Shares')}</td>
                                                        <td>{__('Vesting schedule')}</td>
                                                        <td>{__('Issue date')}</td>
                                                    </tr>
                                                    {holdingsData.warrantsDataList.map((value, key) => {
                                                        let eaCircle = 'green-disc'
                                                        if(value.status == 'Exercisable') eaCircle = 'yellow-disc'
                                                        if (value.status != 'Outstanding' && value.status != 'Issued' && value.status != 'Exercisable') {
                                                            eaCircle = 'red-disc'
                                                        }
                                                        return (
                                                            <tr key={key} className="holdings-tr">
                                                                <td>{value.security}</td>
                                                                <td className={eaCircle}>&nbsp;&nbsp;{value.status}</td>
                                                                <td>{formatFractionalNumber(value.remaining)}</td>
                                                                <td>{value.vesting=='No Vesting' ? 'No vesting schedule' : value.vesting}</td>
                                                                <td>{formatDate(value.issued)}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                    </tbody>
                                                </table>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div id="jobtitle" className="tab-pane">
                                        <p className="viewSthHoldings-p">{__('Job title')}</p>
                                        <table className="table viewHoldings-table viewRelTable">
                                            <tbody>
                                                <tr className="first-row">
                                                    <td className="">{__('Effective date')}</td>
                                                    <td>{__('Job title')}</td>
                                                </tr>
                                                {this.state.jobTitleList && this.state.jobTitleList.length === 0 &&
                                                    <tr className="holdings-tr">
                                                        <td className="none_entered">{__('None Entered')}</td>
                                                        <td className="none_entered">{__('None Entered')}</td>
                                                    </tr>
                                                }
                                                { this.state.jobTitleList && this.state.jobTitleList.length > 0 && this.state.jobTitleList.map((value, key) => {
                                                    return (
                                                        <tr className="holdings-tr" key={key}>
                                                            {value.effective_date === "" ?
                                                                <td className="none_entered">{__('None Entered')}</td>:
                                                                <td>{formatDate(value.effective_date)}</td>
                                                            }
                                                            {value.job_title === "" ?
                                                                <td className="none_entered">{__('None Entered')}</td>:
                                                                <td>{value.job_title}</td>
                                                            }
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div id="viewRelationship" className="tab-pane">
                                        <p className="viewSthHoldings-p">{__('Relationship')}</p>
                                        <table className="table viewHoldings-table viewRelTable">
                                            <tbody>
                                                <tr className="first-row">
                                                    <td className="">{__('Effective date')}</td>
                                                    <td>{__('Relationship')}</td>
                                                </tr>
                                                {this.state.relationshipList.length === 0 &&
                                                    <tr className="holdings-tr">
                                                        <td className="none_entered">{__('None Entered')}</td>
                                                        <td className="none_entered">{__('None Entered')}</td>
                                                    </tr>
                                                }
                                                {this.state.relationshipList.length > 0 && this.state.relationshipList.map((value, key) => {
                                                    return (
                                                        <tr className="holdings-tr" key={key}>
                                                            <td>{formatDate(value.effective_date)}</td>
                                                            <td>{certificateRelationship[value.relationship]}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
export default ViewStakeholderHoldings;