import React, { Component } from 'react';
import { Button, message, Spin } from 'antd';

export default class EmptyPage extends Component {
	render() {
		return <div className="no-account-root">
            <div className="modal-header box-header">
                <button type="button" onClick={this.props.close} className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                <h4 className="modal-title" id="myModalLabel">TIPS</h4>
            </div>
			<div className="no-account-container">
                <p>The access to Board module was restricted by your company administrator.Please contact your company administrator if you would like to change it.</p>
                <Button type="primary" onClick={this.props.close}>I understand</Button>
            </div>
		</div>
	}
}