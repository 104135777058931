import React, { Component } from "react";
import "./style.scss";
import "../PickPlan/style.scss"
import {Button, Image, Input, message, Spin, Statistic, Row, Col} from "antd";
import { post } from 'srcPath/http';
import SuccessIcon from "../Public/img/contact.png";

import "../Notify/style.scss";

class Contact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            title: 'Let’s meet and choose',
            desc: 'the right plan for you!',
            notifyHtml: [
                {
                    boxClass: ['signup-notify-box', 'notify-box-show'],
                    imgSrc: SuccessIcon,
                    title: 'Let’s meet and choose',
                    desc: 'the right plan for you!',
                    titleClass: 'signup-notify-success-title',
                    buttonValue: 'Book a meeting'
                }
            ],
            deadline: Date.now() + 1000 * 4,
        };
    }

    componentDidMount() {
        this.finishSignUp().then(r => {
            this.setState({
                loading: false,
            })
        });
    }

    inputChange = (k, e) => {
        const { dataValue } = this.state;
        let dataList = [...dataValue];
        dataList[k]['value'] = e.target.value;
        this.setState({
            dataValue: dataList
        })
    }

    getForm = () => {
        const { dataValue } = this.state;
        return dataValue.map( (v, k) => (
            <Input key={k} value={v.value} placeholder={v.placeholder} onChange={this.inputChange.bind(this, k)} />
        ));
    }

    backPickPlan = () => {
        const { isContactChange } = this.props;
        isContactChange(0);
    }

    getSprout = () => {
        const { history } = this.props;
        history.push('/');
    }

    /**
     * 完成信息，让商务可以查看
     * @returns {Promise<void>}
     */
    finishSignUp = async () => {
        const { accountInfo } = this.props;
        try {
            const { data: resData } = await post('finishSignUp', { id: accountInfo.id })
            const { code, data } = resData;
            if( code === 0){
            }else{
                message.error(data);
            }
        } catch(err) {
            message.error(err && err.message)
        }
    }

    onFinish = () => {
        // const { history } = this.props;
        // history.push('/');
        //window.location.href = 'https://www.getsprout.co/';
    }

    getPickPlan = async () => {
        const { accountInfo } = this.props;
        try {
            const { data: resData } = await post('backSignUp', { id: accountInfo.id })
            const { code, data } = resData;
            if( code === 0){
            }else{
                message.error(data);
            }
        } catch(err) {
            message.error(err && err.message)
        }
    }

    /**
     * 返回pick a plan
     */
    getNextPage = () => {
        const { updateTabCurrentType, isContactChange } = this.props;
        updateTabCurrentType(1);
        isContactChange(0);
    }

    render() {
        const { notifyHtml, deadline, loading } = this.state;
        const { Countdown } = Statistic;
        return (
            <div className="signup-notify-root">
                {
                    notifyHtml.map( (v, k) => {
                        return (
                            <div key={k} className={v.boxClass.join(' ')}>
                                <img className="signup-notify-success-icon" src={v.imgSrc} />
                                <div className="signup-notify-success-tit">{v.title}</div>
                                <div className={v.titleClass}>{v.desc}</div>
                                <div className="signup-notify-button-bak">
                                    <Button loading={loading} type="primary" className="signup-notify-button">
                                        <a href="https://meetings.hubspot.com/jake-low1" >{v.buttonValue}</a>
                                    </Button>
                                </div>
                                <div onClick={this.getNextPage} className="signup-notify-count-down"><span>Return to Pick a plan</span></div>
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}
export default Contact;