import React, { Component, Fragment } from 'react';
import { Table, Button, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { post } from 'srcPath/http';
import Dot from 'components/Dot';
import { constData } from 'Utils';
import openAddUserModal from '../components/AddUserModal';
import openResendModal from '../components/ResendEmail';
import openDeleteUserModal from '../components/DeleteAccountUser';
import Permission from 'components/Permission';
import security from 'stores/security';
import { formatDate } from 'Utils/format/formatDate';
import './style.scss';

const renderValue = val => val || '--'

export default class AccountUsers extends Component {
	state = {
		loading: true,
		list: [],
		resendMembers: {}
	}

	componentDidMount() {
		this.getData()
		this.resendMembers()
	}

	resendMembers = async () => {
		this.setState({ loading: true })

		try {
			const { data: resData } = await post('getResendEmailRule', { type: 1 })

			const { data, code } = resData || {}

			const { interval } = data || {}
			if (code == 0) {
				this.setState({ resendMembers: interval, loading: false })
			} else {
				message.error(data)
			}
		} catch (err) {
			message.error(err && err.message)
		}

		this.setState({ loading: false })
	}
	getData = async () => {
		this.setState({ loading: true })

		try {
			const { data: resData } = await post('getAccountUsers')

			const { data, code } = resData || {}

			if (code == 0) {
				this.setState({ list: data, loading: false })
			} else {
				message.error(data)
			}
		} catch (err) {
			message.error(err && err.message)
		}

		this.setState({ loading: false })
	}

	callback = async () => {
		await this.getData()
		security.getStatus()
	}

	getColumns = () => {
		const userFlag = security.companyInfo && security.companyInfo.flag
		const { resendMembers } = this.state

		return [
			{
				title: __('Name'),
				dataIndex: 'name',
				className: 'nowrap',
				fixed: true,
				render: renderValue
			},
			{
				title: __('Title'),
				dataIndex: 'titles',
				className: 'nowrap',
				render: renderValue
			},
			{
				title: __('Email'),
				dataIndex: 'email',
				className: 'nowrap',
				render: renderValue
			},
			{
				title: __('Permissions'),
				dataIndex: 'permissions',
				className: 'nowrap',
				render: text => constData.adminRole[text] || '--'
			},
			{
				title: __('Registered'),
				dataIndex: 'isRegistered',
				className: 'nowrap',
				render: text => text == 1 ? <Dot color="green" >{__('StakeholdersTable-Registered-Yes')}</Dot> : <Dot color="red">{__('Not yet')}</Dot>
			},
			{
				title: __('Last login'),
				dataIndex: 'last_login_time',
				className: 'nowrap',
				width: 250,
				render: text => formatDate(text)
			},
			{
				title: '',
				align: 'right',
				className: 'nowrap',
				width: 300,
				render: (text, record = {}) => <Fragment>
					{record.isRegistered == 0 && <Permission disabled={userFlag > record.permissions} type="company_permission_accountUsers_resend"><Button type="link" className="sprout-btn-divider" onClick={() => openResendModal(this.getData, record, 'resendEmail2AccountUser')}>{__('Resend')}</Button></Permission>}
					<Permission disabled={userFlag > record.permissions} type="company_permission_accountUsers_edit"><Button type="link" className="sprout-btn-divider" onClick={() => openAddUserModal(this.callback, record, resendMembers)} >{__('Edit')}</Button></Permission>
					<Permission disabled={userFlag > record.permissions} type="company_permission_accountUsers_delete"><Button type="link" danger onClick={() => openDeleteUserModal(this.callback, record)}>{__('Delete')}</Button></Permission>
				</Fragment>
			},
		]
	}

	render() {
		const { loading, list = [], resendMembers } = this.state

		return <div className="permission-account-user">
			<div className="bannere">
				<Permission type="company_permission_accountUsers_add"><Button type="primary" onClick={() => openAddUserModal(this.getData, '', resendMembers)}><PlusOutlined />{__('Add user')}</Button></Permission>
			</div>
			<div className="contenet">
				<Table scroll={{ x: true }} rowKey="id" columns={this.getColumns()} dataSource={list} pagination={false} loading={loading} />
			</div>
		</div>
	}
}