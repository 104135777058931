import React from 'react';
import Section from '../components/Section';
import RoundTags from 'components/RoundTags';
import { Table } from 'antd';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import { formatDate } from 'Utils/format/formatDate';
import { renderEllipsis } from 'components/TokenTransaction'

const renderValue = val => val || '--'

export default function Transaction(props) {
    const data = props.data || [];

	const transactionColumns = [
		{
			title: __('Txn hash'),
            dataIndex: 'txn_hash',
            render: text => renderEllipsis(text, 120),
            ellipsis: true
		},
		{
			title: __('Method'),
            dataIndex: 'method',
            render: text => renderEllipsis(text, 120)
		},
		{
			title: __('Date time'),
			dataIndex: 'transaction_timestamp',
            align: 'center',
            className: 'nowrap',
			render: text => formatDate(text)
		},
		{
			title: __('Tag'),
			dataIndex: 'tag',
			align: 'center',
			render: text => <RoundTags tags={text || []} />
        },
        {
            title: __('Note'),
            dataIndex: 'notes',
             className: 'nowrap',
            render: renderValue
        },
        {
            title: __('From Stakeholder'),
            dataIndex: 'from_stakeholder',
             className: 'nowrap',
            render: renderValue
        },
        {
            title: __('From Stakeholder'),
            dataIndex: 'from_stakeholder',
            className: 'nowrap',
            render: renderValue
        },
        {
            title: __('Quantity Claimend'),
            dataIndex: 'quantity',
            className: 'nowrap',
            align: 'right',
            render: text => formatFractionalNumber(text)
        }
	]

    return <Section title={__('Transaction history')}>
		<div className="ea-transaction-table">
            <Table rowKey="txn_hash" size="small" columns={transactionColumns} dataSource={data} pagination={false} scroll={{x: true}}/>
		</div>
	</Section>
}