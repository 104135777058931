import moment from 'moment';


export function renderDuration(start, end) {
	if (!(end - start >=0)) {
		console.error('renderDuration 参数不对')

		return '--'
	}

	const data = moment.duration(end - start)._data;

	const { years, months, days, hours, minutes } = data || {}

	const map = {
		years: years > 0 ? `${years} years ` : '',
		months: months > 0 ?  `${months} months ` : '',
		days: days > 0 ? `${days} days ` : '',
		hours: hours > 0 ? `${hours} hours ` : '',
		minutes: (minutes > 0 && !hours)  ? `${minutes} minutes ` : '',
	}

	let res = ''

	Object.keys(map).forEach(val => res += map[val])

	return res
}
