import React, { Component, Fragment } from "react";
import {Radio, Input, Space, Tooltip} from 'antd';
import {CheckCircleFilled} from "@ant-design/icons";
import Modal from 'components/Modal';
import ConvertibleExample from "./ConvertibleExample";
import { I } from "Utils";

class ConvertibleNoteMethodologyModels extends Component {

    convertibleExample = null;

    constructor(props) {
        super(props);
        this.state = {
            optionPoolRadioVal : 1,
            postFinancingDilutionHelp : __('Convertible converts with new money and dilute both current shareholders and new investors.'),
            preFinancingDilutionHelp : __('Convertible converts pre-financing and dilute only the current shareholder, not the new investors.'),
            dollarsInvestedHelp : __('New investors take dilution from principal and accrued interest on the convertibles. Founders take dilution only from discounted portion of converted shares.'),
        }
        this.conversionMethodChange = this.conversionMethodChange.bind(this);
    }

    /**
     * conversionMethodChange change事件
     * @param e
     */
    conversionMethodChange = (e) => {
        const value = e.target.value;
        const { conversionMethodChange } = this.props;
        conversionMethodChange( value);
    }

    /**
     * optionPool 案例浮层
     */
    getExampleModal = () => {
        this.convertibleExample = Modal.open({
            component: props => <ConvertibleExample close={props.close} />,
            className: 'certificate-modal-wrap advance-terms-example',
            width: '700px',
            maskClosable: true
        })
    }

    /**
     * 卸载
     */
    componentWillUnmount() {
        this.convertibleExample && this.convertibleExample.close();
    }

    render() {
        const { conversionMethod } = this.props;
        const { postFinancingDilutionHelp, preFinancingDilutionHelp } = this.state;
        return (
            <div className="advance-model-box">
                <CheckCircleFilled className="advance-model-option-circle" />
                <div className="advance-model-option-content">
                    <div>{__('Convertible note methodology')}</div>
                    <div>{__('Conversion Method：A convertible (SAFE or convertible note) is converted into shares based on terms that are set by the next round’s investors. Select the method in which you expect your convertibles to be calculated.')}&nbsp;
                        <a onClick={this.getExampleModal}>{__('Example')}</a>
                    </div>
                    <div>
                        <dl>
                            <dt>{__('Conversion method')}</dt>
                            <dd>
                                <Radio.Group onChange={this.conversionMethodChange} value={I(conversionMethod, 2)}>
                                    <Space direction="vertical">
                                        <Radio value={2}>
                                            <span>{__('Pre-financing dilution')}</span>
                                            <Tooltip title={preFinancingDilutionHelp} color="#2b3946">
                                                <span className="table_icon title_icons">?</span>
                                            </Tooltip>
                                        </Radio>
                                        <Radio value={1} className="advance-model-radio">
                                            <span>{__('Post-financing dilution')}</span>
                                            <Tooltip title={postFinancingDilutionHelp} color="#2b3946">
                                                <span className="table_icon title_icons">?</span>
                                            </Tooltip>
                                        </Radio>
                                        {/*<Radio value={3}>
                                            <span>Dollars-invested</span>
                                            <Tooltip title={this.state.dollarsInvestedHelp} color="#2b3946">
                                                <span className="table_icon title_icons">?</span>
                                            </Tooltip>
                                        </Radio>*/}
                                    </Space>
                                </Radio.Group>
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className="clear"></div>
                <div className="advance-model-height-1"></div>
            </div>
        )

    }
}

export default ConvertibleNoteMethodologyModels
