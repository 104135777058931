import React, { useState, useEffect } from 'react';
import { FormItem } from 'react-antd-formutil';
import { Checkbox, } from 'antd'
import { relationshipList } from 'Utils/constant';
import { omit } from 'lodash';
import { post } from 'srcPath/http';
import './style.scss';

const Group = Checkbox.Group

const options1 = [__('Outstanding')]

const options2 = [__('Convert at IPO'), __('Exercisable'), __('Exercisable until'), __('Partially exercised'), __('Partially settled'), __('Settleable'), __('Settleable until')]

const options3 = [__('Canceled'), __('Converted'), __('Exercise at IPO'), __('Expired'), __('Forfeited'), __('Fully exercised'), __('Not purchased'), __('Redeem to cash'), __('Repurchased'), __('Transferred'),__('Settled')]

export default function SecurityStatus(props) {
    const { name, label } = props
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);

    return <div className="filter-rooter-status">
        <FormItem
            name={name || 'status'}
            label={label || __('Status')}
        >
            <Group {...omit(props, ['name', 'label'])} showSearch placeholder={__('Select')}>
                <div>
                    {
                        options1 && options1.map((item, key) => {
                            return (<Checkbox key={key} value={item}>{item}</Checkbox>)
                        })
                    }
                </div>
                <div>
                    {
                        options2 && options2.map((item, key) => {
                            return (<Checkbox key={key} value={item}>{item}</Checkbox>)
                        })
                    }
                </div>
                <div>
                    {
                        options3 && options3.map((item, key) => {
                            return (<Checkbox key={key} value={item}>{item}</Checkbox>)
                        })
                    }
                </div>
            </Group>
        </FormItem>
    </div>
}