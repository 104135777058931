import React, { Component, Fragment } from "react";
import {Radio, Input, Space, Tooltip, Select} from 'antd';
import { I, filterAmount } from "Utils";
import {CheckCircleFilled} from "@ant-design/icons";
class RightsAndPreferencesModels extends Component {

    constructor(props) {
        super(props);
        this.state = {
            DividendsHelp : __('Non-cash dividend: Indicates whether the investors will be receiving non-cash dividends, such as shares. The annual percentage rate of the investment that is paid back to the investor in additional shares. Select whether the non-cash dividend will be Non-Cumulative or Cumulative. This information is not reflected in the capitalization table.')
        }
        this.dividendTypeChange = this.dividendTypeChange.bind(this);
        this.dividendYieldChange = this.dividendYieldChange.bind(this);
        this.dividendAccrualChange = this.dividendAccrualChange.bind(this);
        this.compoundingInterestChange = this.compoundingInterestChange.bind(this);

    }

    /**
     * dividend Type change事件
     * @param e
     */
    dividendTypeChange = (e) => {
        const dividendType = e.target.value;
        const { dividendTypeChange } = this.props;
        dividendTypeChange(dividendType);
    }

    /**
     * dividend Yield change事件
     * @param e
     */
    dividendYieldChange = (e) => {
        const { dividendYieldChange } = this.props;
        const obj = filterAmount(e);
        dividendYieldChange(obj, e.target);
    }

    /**
     * dividend accrual change事件
     * @param e
     */
    dividendAccrualChange = (val) => {
        const { dividendAccrualChange } = this.props;
        dividendAccrualChange(val);
    }

    /**
     * compounding interest change事件
     * @param e
     */
    compoundingInterestChange = (val) => {
        const { compoundingInterestChange } = this.props;
        compoundingInterestChange(val);
    }

    render() {
        const { DividendsHelp } = this.state;
        const { dividendType, dividendYield, dividendAccrual, compoundingInterest} = this.props;
        return (
            <div className="advance-model-box">
                <CheckCircleFilled className="advance-model-option-circle" />
                <div className="advance-model-option-content">
                    <div>
                        {__('Dividends')}
                        <Tooltip title={DividendsHelp} color="#2b3946">
                            <span className="table_icon title_icons">?</span>
                        </Tooltip>
                    </div>
                    <div>{__('Enter the dividend details of the share class.')}</div>
                    <div>
                        <dl>
                            <dt>{__('Dividend type')}</dt>
                            <dd>
                                <Radio.Group value={dividendType} onChange={this.dividendTypeChange} >
                                    <Space direction="vertical">
                                        <Radio value={1} className="advance-model-radio">
                                            <span>{__('Cumulative')}</span>
                                        </Radio>
                                        <Radio value={2}>
                                            <span>{__('Non-Cumulative')}</span>
                                        </Radio>
                                    </Space>
                                </Radio.Group>
                            </dd>
                        </dl>
                        <div style={{display: dividendType == 1 ? 'block' : 'none'}}>
                            <dl className="pt-0">
                                <dt>{__('Dividend yield')}</dt>
                                <dd>
                                    <Input value={dividendYield} onChange={this.dividendYieldChange} className="advance-model-input" addonAfter="%" defaultValue="" />
                                </dd>
                            </dl>

                            <dl className="pt-0">
                                <dt>{__('Dividend accrual')}</dt>
                                <dd>
                                    <Select value={dividendAccrual} defaultValue={dividendAccrual} onChange={this.dividendAccrualChange} className="advance-model-select">
                                        <Select.Option value={1}>{__('Daily')}</Select.Option>
                                        <Select.Option value={2}>{__('Weekly')}</Select.Option>
                                        <Select.Option value={3}>{__('Monthly')}</Select.Option>
                                        <Select.Option value={4}>{__('Quarterly')} ({__('calendar year')})</Select.Option>
                                        <Select.Option value={5}>{__('Annually')}</Select.Option>
                                    </Select>
                                </dd>
                            </dl>

                            <dl className="pt-0">
                                <dt>{__('Compounding interest')}</dt>
                                <dd>
                                    <Select value={compoundingInterest} defaultValue={compoundingInterest} onChange={this.compoundingInterestChange} className="advance-model-select">
                                        <Select.Option value={6}>{__('Simple interest')}</Select.Option>
                                        <Select.Option value={1}>{__('Daily')}</Select.Option>
                                        <Select.Option value={3}>{__('Monthly')}</Select.Option>
                                        <Select.Option value={4}>{__('Quarterly (calendar year)')}</Select.Option>
                                        <Select.Option value={5}>{__('Annually')}</Select.Option>
                                        <Select.Option value={7}>{__('Semi-annually')}</Select.Option>
                                    </Select>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>
                <div className="clear"></div>
            </div>
        )

    }
}

export default RightsAndPreferencesModels
