import React from 'react';
import Gallery from 'react-image-gallery'; // https://github.com/xiaolin/react-image-gallery
import 'react-image-gallery/styles/scss/image-gallery.scss';
import './style.scss';

export default function ImageGallery(props) {
    const { items } = props
    return <Gallery
        {...props}
        items={
            items.map(val => ({
                ...val,
                original: val,
                bulletClass: 'sprout-image-callery-bullet'
            }))
        }
    />
}