import React from "react";
import Dot from 'components/Dot';
import { Dropdown, Menu, Tooltip } from "antd";
import security from 'stores/security'
import { I, formatPercent } from "Utils";
import RoundTags from 'components/RoundTags'
import { DownOutlined } from "@ant-design/icons";
import { relationshipList } from "Utils/constant";
import { renderWalletAddress } from 'Utils/RenderWalletAddress';
import { formatDate } from 'Utils/format/formatDate';

export const columnsConfig = (flag, isEsopOrCaptableAdmin, props, allPrefix=[]) => {
    const showToken = (security.companyInfo && security.companyInfo.is_show_coin) === '1'
    /**
     * 表格最后一列菜单配置
     * @param data
     * @param index
     * @returns {JSX.Element}
     */
    const dropdownInfo = (text, data, index) => {
        const { setCurSelectStakeholderNew } = props;
        return (
            <Dropdown onClick={setCurSelectStakeholderNew.bind(this, text)} overlay={menu( data, text)} placement="bottomRight" trigger="click">
                <div className="tableMenu"><DownOutlined style={{ color: '#FFF' }}/></div>
            </Dropdown>
        );
    }

    const menu = (value, id) => {
        const { transferInfoNew, openResendModal, editStakeholderOrWallet, cost_list } = props;
        return (
            <Menu>
                {!isEsopOrCaptableAdmin && <Menu.Item onClick={transferInfoNew.bind(this,id,"viewHoldings")}><a data-toggle="modal" data-target="#ViewSthHoldingsModal" >{__('View stakeholder holdings')}</a></Menu.Item>}
                <Menu.Item onClick={editStakeholderOrWallet.bind(this,value,id,cost_list)}><a data-toggle="modal" data-target="#editStakeholderModal" >{__('Edit stakeholder')}</a></Menu.Item>
                {/*<Menu.Item onClick={transferInfoNew.bind(this,id,"editProperties")}><a data-toggle="modal" data-target="#EditStPropertiesModal">{__('Edit stakeholder properties')}</a></Menu.Item>*/}
                {/*{value.is_show_terminate_button && <Menu.Item ><a data-toggle="modal" data-target="#terminateStakeholderModal">{__('Terminate stakeholder')}</a></Menu.Item>}*/}
                { value && value.is_init === '0' && <Menu.Item onClick={ ()=> openResendModal(value)}><a>{__('Resend invitation')}</a></Menu.Item>}
            </Menu>
        )
    }

    const columnsFilter = (columns, filterList) => {
        for(let i in filterList){
            for(let j in columns){
                if(columns[j].dataIndex == filterList[i].key && filterList[i].isChecked == false){
                    columns.splice(j, 1);
                }
                if (!showToken && columns[j].dataIndex === 'wallet') {
                    columns.splice(j, 1);
                }
            }
        }
        return [...columns];
    }

    let relationshipFilter = [];
    relationshipList.forEach( (v, k) => {
        relationshipFilter.push({text: v, value: v});
    })

    let columns = [
        {
            title: __('Stakeholder'),
            dataIndex: 'name',
            align: 'left',
            className: 'nowrap',
            fixed: 'left',
            isChecked: true,
            isDisabled: true,
            sorter: true,
            sortName: 'stakeholder',
            render: (text, value) => {
                return <Dot color={ value.activeStatus == true ? 'green' : 'red' }>{text}</Dot>
            },
        },
        {
            title: __('Portfolio email'),
            dataIndex: 'email',
            align: 'left',
            with: 120,
            render: (text, value) => {
                return <div>{I(value.email, 'Pending')}</div>
            },
            isChecked: true,
            isDisabled: false,

        },
        {
            title: __('Wallet'),
            dataIndex: 'wallet_address_list',
            align: 'left',
            render: (wallet_address_list = [], record) => {
                if (Array.isArray(wallet_address_list)) {
                    const addressList = wallet_address_list?.map((item) => {
                        return item.wallet_address
                    })
                    if (!addressList) {
                        return '--'
                    }
                    return renderWalletAddress(addressList, null, true)
                }
            },
        },
        {
            title: __('Rounds'),
            dataIndex: 'prefix',
            align: 'right',
            render: (text, data) => {
                let newPrefix = text && Array.from(new Set(text));
                return I(allPrefix, '').length && newPrefix && <div style={{maxWidth: 200, display: 'inline-block'}}><Tooltip title={<RoundTags isPick allTags={allPrefix || []}  tags={newPrefix}/>}><span><RoundTags allTags={allPrefix || []}  tags={newPrefix}/></span></Tooltip></div>
            },
        },
        {
            title: __('Relationship'),
            dataIndex: 'relationship',
            align: 'left',
            sorter: true,
            sortName: 'relationship',
            isChecked: true,
        },
        {
            title: __('Ownership'),
            dataIndex: 'ownership',
            align: 'left',
            sorter: true,
            sortName: 'ownership',
            render: text => <div>{text === 0 ? 'None' : (text == 'CN holder' ? 'CN holder': formatPercent(text, '')) }</div>,
            isChecked: true,
        },
        {
            title: __('Access level'),
            dataIndex: 'accessLevel',
            align: 'left',
            sorter: true,
            sortName: 'access_level',
            isChecked: true,
        },
        {
            title: __('Employee ID'),
            dataIndex: 'employee_id',
            align: 'left',
            sorter: false,
            sortName: 'employee_id',
            isChecked: true,
        },
        {
            title: __('Job title'),
            dataIndex: 'job',
            align: 'left',
            render: text => text || '--'
        },
        {
            title: __('Gender'),
            dataIndex: 'gender',
            align: 'left',
            render: (text, value) => {
                return <div>{I(value.gender, '--')}</div>
            },
        },
        {
            title: __('Country'),
            dataIndex: 'country_region',
            align: 'left',
            render: text => text&&text.trimStart() ? text : '--',
        },
        {
            title: __('Registered'),
            dataIndex: 'registered_status',
            align: 'left',
            width: 120,
            sorter: true,
            sortName: 'registered',
            render: (text) => {
                const obj = {
                    0: {color: 'red', registeredValue: __('Not yet')},
                    1: {color: 'green', registeredValue: __('StakeholdersTable-Registered-Yes')},
                    2: {color: '#FFC600', registeredValue: __('Not required')},
                };
                // return <Dot color={obj[text].color} >{obj[text].registeredValue}</Dot>
                return obj[text].registeredValue
            },
            isChecked: true
        },
        {
            title: __('Last login'),
            dataIndex: 'last_login_time',
            align: 'left',
            render: text => formatDate(text),
            isChecked: true
        },
        {
            title: '',
            dataIndex: 'id',
            align: 'left',
            width: 60,
            fixed: 'right',
            render:(text, value, index) => dropdownInfo(text, value, index),
            isHide: true,
        }
    ];

    const cryptoColumns = [
        {
            title: 'Stakeholder',
            dataIndex: 'name',
            sorter: (a, b) => stringOrderBy(a, b, 'name'),
            align: 'left',
            width:'10%',
            onFilter: (value, record) => record.security.indexOf(value) === 0,
        },
        {
            title: 'Portfolio Email',
            dataIndex: 'email',
            sorter: (a, b) => stringOrderBy(a, b, 'email'),
            align: 'left',
            width:'18%',
            onFilter: (value, record) => record.security.indexOf(value) === 0,
            render: (text, value) => {
                return <div>{I(value.email, 'Pending')}</div>
            }
        },
        {
            title: 'Wallet',
            dataIndex: 'wallet',
            sorter: (a, b) => stringOrderBy(a, b, 'email'),
            align: 'left',
            width:'18%',
            onFilter: (value, record) => record.security.indexOf(value) === 0,
            render: (text, value) => {
                return <div>{I(value.email, 'None entered')}</div>
            }
        },
        {
            title: 'Relationship',
            dataIndex: 'relationship',
            sorter: (a, b) => a.security > b.security,
            align: 'left',
            width:'10%',
            filters: relationshipFilter,
            onFilter: (value, record) => record.relationship && record.relationship.includes(value),
        },
        {
            title: 'Equity',
            dataIndex: 'equity',
            sorter: (a, b) => a.security > b.security,
            align: 'left',
            filters: relationshipFilter,
            onFilter: (value, record) => record.relationship && record.relationship.includes(value),
        },
        {
            title: 'Ownership',
            dataIndex: 'ownership',
            sorter: (a, b) => a.ownership - b.ownership,
            align: 'left',
            onFilter: (value, record) => record.security.indexOf(value) === 0,
            render: (text, value) => {
                const ownership = value.ownership === 0 ? 'None' : (Math.round(value.ownership)/100) + '%' ;
                return <div>{ownership}</div>
            }
        },
        {
            title: 'Token ownership',
            dataIndex: 'tokenOwnership',
            sorter: (a, b) => a.ownership - b.ownership,
            align: 'left',
            onFilter: (value, record) => record.security.indexOf(value) === 0,
            render: (text, value) => {
                const ownership = value.ownership === 0 ? 'None' : (Math.round(value.ownership)/100) + '%' ;
                return <div>{ownership}</div>
            }
        },
        {
            title: 'Registered',
            dataIndex: 'is_init',
            sorter: (a, b) => a.is_init - b.is_init,
            align: 'left',
            onFilter: (value, record) => record.security.indexOf(value) === 0,
            width: 100,
            render: (text, value) => {
                const registerMap = {
                    0 : <Dot color="red">Not yet</Dot>,
                    1: <Dot color="green" >Yes</Dot>
                }
                return <div className="nowrap">{I(registerMap[value.is_init], '--')}</div>
            }
        },
        {
            title: '',
            dataIndex: 'id',
            align: 'left',
            width: 60,
            fixed: 'reight',
            render:(text, value, index) => dropdownInfo(text, value, index)
        }
    ];

    columnsFilter(columns, props.filterList);


    return columns
}