import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import openCertificateModal from 'components/CertificateDetail';
import './style.scss';

const TransactionRender = props => {
  const { transferSecurity, value } = props
  const dataArray = transferSecurity || []

  let res = ''

  if(!dataArray || dataArray.length === 0) {
    return value || ''
  }

  if (dataArray.length >= 2 ) {
    res =  <div className="transaction-history-render">
      {value && <div>{value}</div>}
      <div className="transaction-share-name" onClick={() => openCertificateModal(dataArray[0].id)}>{dataArray[0].name}</div>
      <Dropdown 
        overlay={
          <Menu>
            {
              dataArray.map((val, index) => <Menu.Item key={index} onClick={() => openCertificateModal(val.id) } >{val.name}</Menu.Item>)
            }
          </Menu>
        }
      >
        <DownOutlined className="transaction-share-name" />
      </Dropdown>
    </div>
  }  else {
    res = <div className="transaction-history-render">
      { value && <div>{value}</div>}
      <div className="transaction-share-name" onClick={() => openCertificateModal(dataArray[0].id)}>{dataArray[0].name}</div>
      {/*dataArray[1] && <div onClick={() => openCertificateModal(dataArray[1].id)}>{dataArray[1].name}</div>*/}
    </div>
  }

  return res
}


export default TransactionRender