import React from "react";
import Utils from "Utils";
class ActionCancelModal extends React.Component {
    constructor(props){
        super(props);
        this.state={
            data:{
                cancellation_date:"",
                cancellation_reason:"",
                realized_value:"",
                notes:"",
            },
            cancellation_reasonList:[
                "Choose a reason",
                "Acquisition",
                "Buyback",
                "Clerical error",
                "Conversion",
                "Dissolved",
                "Donation",
                "Merger",
                "Relationship change",
                "Secondary",
                "Stock split",
                "Other",
                "Write off cancel",
                "Repricing cancel",
            ],
            reviewCancellationList:[
                {name:"Certificate",value:"CS-5 to Cody Belseager"},
                {name:"Certificate date",value:"01/08/2020"},
                {name: "Certificate reason",value:"Other"},
                {name:"Realized value",value:"$1.00"},
                {name:"Notes",value:"1231323"},
            ]
        }
    }
    componentDidMount() {
        window.$('.cancellation_datepicker').datepicker({
            maxViewMode: 3,
            todayBtn: "linked",
            todayHighlight: true,
            autoclose: true,
            format: "dd/mm/yyyy"
        }).on("changeDate", (e) => {
            let curData = this.state.data;
            curData.cancellation_date = e.format();
            this.setState({
                data: curData
            });
        })
    }
    recordData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        if(dataKey=="notes"){
            if(dataVal==""){
                curData[dataKey]="No notes"
            }
        }
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        })
    }
    recordNumber=(key,e)=>{
        let curData = this.state.data;
        curData[key] = Utils.checkNumInput(e.target.value);
        this.setState({
            data:curData
        });
    }
    resetActive=()=>{
        window.$("#actionCancelModal .tab-pane").removeClass("active");
        window.$("#action_cancel_step1").addClass("active");
    }
    render(){
        return(
            <div className="modal fade" id="actionCancelModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="modal-dialog tab-content">
                    <div className="modal-content content500 tab-pane active" id="action_cancel_step1">
                        <div className="modal-header box-header">
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                            <h4 className="modal-title" id="myModalLabel">Cancel {this.props.pageData.active_cancel_name}</h4>
                        </div>
                        <div className="modal-body padding2026">
                            <div className="body-one">
                                <form className="form-horizontal font_reset_form">
                                    <div className="form-group add-items ">
                                        <label className="col-xs-5 add-label">Cancellation date</label>
                                        <div id="first_name" className="col-xs-5 ">
                                            <input value={this.state.data.cancellation_date} name="cancellation_date" onChange={this.recordData} className="form-control date-calendar-with10padding calender-positionReset datepicker cancellation_datepicker" type="text"  placeholder="DD/MM/YYYY" />
                                        </div>
                                    </div>
                                    <div className="form-group add-items">
                                        <label className="col-xs-5 add-label">Cancellation reason</label>
                                        <div className="col-xs-5">
                                            <select value={this.state.data.cancellation_reason}  onChange={this.recordData}  name="cancellation_reason" className="form-control select-container">
                                                {this.state.cancellation_reasonList.map((value,key)=>{
                                                    return(
                                                        <option key={key}>{value}</option>
                                                    )
                                                })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group add-items">
                                        <div className="row  profile-row">
                                            <label className="col-xs-5 add-label">Notes</label>
                                            <div className="col-xs-6">
                                                <textarea name="notes" value={this.state.data.notes} onChange={this.recordData} className="form-control text-area" type="text"/>
                                            </div>
                                            <p className="marginTop2 text-muted col-xs-7 col-xs-push-5">Add any notes regarding the cancellation. They will be included in the option notes.</p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer box-footer invite-footer">
                            <button type="button" data-toggle="tab" data-target="#action_cancel_step2" className="btn boxbtn-to bg-darkblue">Next: Review Cancellation</button>
                        </div>
                    </div>
                    <div className="modal-content content500 tab-pane " id="action_cancel_step2">
                        <div className="modal-header box-header">
                            <a data-target="#action_cancel_step1" type="button" className="back-btn" aria-controls="repurchase-step1" role="tab" data-toggle="tab"></a>
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.resetActive}>×</button>
                            <h4 className="modal-title" id="myModalLabel">Cancel {this.props.pageData.active_cancel_name}</h4>
                        </div>
                        <div className="modal-body padding2026">
                            <div className="body-one">
                                <table className="table merge-table review-relationship-table no-marginBottom ">
                                    <tbody>
                                    <tr>
                                        <td className="width40perc">Number of canceled options</td>
                                        <td className="width40perc">{this.props.selectCount}</td>
                                    </tr>
                                    <tr>
                                        <td>Cancellation date</td>
                                        <td>{this.state.data.cancellation_date}</td>
                                    </tr>
                                    <tr>
                                        <td>Certificate reason</td>
                                        <td>{this.state.data.cancellation_reason}</td>
                                    </tr>
                                    <tr>
                                        <td>Notes</td>
                                        <td>{this.state.data.notes}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer box-footer invite-footer">
                            <button type="button" data-dismiss="modal"  className="btn boxbtn-to bg-darkblue update-btn"  onClick={this.resetActive}>Cancel Option</button>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
export default ActionCancelModal;