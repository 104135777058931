import React, { Component, Fragment } from "react";
import {Button, Col, DatePicker, Input, Row, Select, Tooltip} from "antd";
import { I, clearNoNum } from 'Utils';
import DateSelector from 'components/DateSelector';

class WaterfallInputModels extends Component {

    constructor(props) {
        super(props);
    }

    /**
     * exit value change
     * @param e
     */
    exitValueChange = (e) => {
        const {exitValueChange} = this.props;
        exitValueChange(e);
    }

    /**
     * cap table value change (select)
     * @param value
     */
    capTableValueChange = (value) => {
        const {capTableValueChange} = this.props;
        capTableValueChange(value);
    }

    /**
     * non convertible debt value change
     * @param e
     */
    nonConvertibleDebtValueChange = (e) => {
        const {nonConvertibleDebtValueChange} = this.props;
        nonConvertibleDebtValueChange(e);
    }

    /**
     * projected exit date change
     * @param date
     */
    projectedExitDateChange = (date) => {
        const {projectedExitDateChange} = this.props;
        projectedExitDateChange(date);
    }

    getCapTableList = (list) => {
        return list.map( (v, k) => {
            return (<Select.Option value={v.id}>{v.round_name} as of {v.financing_date}</Select.Option>);
        })
    }

    advancedTermsChange = (id) => {
        const { advancedTermsChange } = this.props;
        advancedTermsChange();
    }

    saveTerms = () => {
        const { saveTerms } = this.props;
        saveTerms();
    }

    /**
     * 链接跳转
     * @param path
     */
    getModel = (path) => {
        const { history } = this.props;
        history.push(`/home/fundraise/scenario-modeling/${path}`);
    }

    render() {
        const { exitValue, capTableValue, nonConvertibleDebtValue, projectedExitDate, capTableList,
            capTableLoading, dateFormat, viewResultsLoading, __LANG, errorShow } = this.props;
        return (

            <Fragment>
                {/*错误提示*/}
                <div className="mb-20 mt-20" style={{display: errorShow ? '' : 'none'}}>
                    <div data-show="true" className="ant-alert ant-alert-error add_error_css" role="alert"><span
                        role="img" aria-label="exclamation-circle"
                        className="anticon anticon-exclamation-circle ant-alert-icon error_icon"><svg
                        viewBox="64 64 896 896" focusable="false" data-icon="exclamation-circle" width="1em"
                        height="1em" fill="currentColor" aria-hidden="true"><path
                        d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296zm32 440a48.01 48.01 0 010-96 48.01 48.01 0 010 96z"></path></svg></span>
                        <div className="ant-alert-content">
                            <div className="ant-alert-message">
                                <div>{__('Exit value required to model a waterfall.')}</div>
                            </div>
                            <div className="ant-alert-description"></div>
                        </div>
                    </div>
                </div>
                <div className="from_box">
                    <div className="waterfall-input">
                        <Row>
                            <Col span={6}>
                                <dl>
                                    <dt>{__LANG.exitValue}</dt>
                                    <dd className="pr-8">
                                        <Input
                                            value={exitValue}
                                            className="mb-3 mt-3 height-40"
                                            addonBefore={__LANG.amountSymbol}
                                            addonAfter=""
                                            defaultValue=""
                                            onChange={this.exitValueChange}
                                        />
                                        <small>&nbsp;</small>
                                    </dd>
                                </dl>
                            </Col>
                            <Col span={6}>
                                <dl>
                                    <dt>{__LANG.capTable}</dt>
                                    <dd className="pr-8">
                                        <Select
                                            className="mb-3 mt-3 w100 height-40"
                                            value={capTableValue}
                                            onChange={this.capTableValueChange}
                                            loading={capTableLoading}
                                        >
                                            {this.getCapTableList(capTableList)}
                                            <Select.Option value={0}>{__('Master cap table')}</Select.Option>
                                        </Select>
                                        <small className="small-str cursor-poi color-1" onClick={this.getModel.bind(this, 'financing-round')}>{__('Create a pro forma cap table ›')}</small>
                                    </dd>
                                </dl>
                            </Col>
                            <Col span={6}>
                                <dl>
                                    <dt>{__LANG.nonConvertibleDebt}
                                        <Tooltip title={__LANG.nonConvertibleDebtHelp} color="#2b3946">
                                            <span className="table_icons title_icons">?</span>
                                        </Tooltip>
                                    </dt>
                                    <dd className="pr-8">
                                        <Input
                                            value={nonConvertibleDebtValue}
                                            onChange={this.nonConvertibleDebtValueChange}
                                            className="mb-3 mt-3 height-40"
                                            addonBefore={__LANG.amountSymbol}
                                            defaultValue=""
                                        />
                                        <small className="small_str"></small>
                                    </dd>
                                </dl>
                            </Col>
                            <Col span={6}>
                                <dl>
                                    <dt>{__LANG.projectedExitDate}</dt>
                                    <dd>
                                        <DateSelector
                                            className="height-40 mb-3 mt-3"
                                            inputReadOnly={true}
                                            allowClear={false}
                                            showToday={true}
                                            value={projectedExitDate}
                                            onChange={this.projectedExitDateChange}
                                        />
                                        <small className="small_str"></small>
                                    </dd>
                                </dl>
                            </Col>
                        </Row>
                    </div>

                    <div className="mt-20 button_box">
                        <Button loading={viewResultsLoading} className="report_button" type="primary" onClick={this.saveTerms}>{__('View Results')}</Button>
                        <Button className="report_button" type="primary" onClick={this.advancedTermsChange.bind(this, 0)}>{__('Advanced Terms')}</Button>
                        <div className="clear"></div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default WaterfallInputModels;