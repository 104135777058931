import React, { Component } from "react";
import {message} from "antd";
import { post } from 'srcPath/http';
import Loading from 'components/Loading';
import "./style.scss";

class ConfirmEmailModels extends Component {

    state = {
        emailHtml: '',
    };

    componentDidMount() {
        this.emailHtml();
    }

    emailHtml = async() => {
        const { type, isWallet: is_wallet, stakeholderId: id } = this.props;
        Loading.global(true)
        try {
            const { data: resData } = await post('getEmailTemplateResend', {is_wallet, id})
            const { code, data } = resData || {}
            if (code == 0) {
                this.setState({ emailHtml: data });
            } else {
                message.error(data)
            }
        } catch(err) {
            message.error(err && err.message)
        }
        Loading.global(false)
    }

    render() {
        const { emailHtml } = this.state;
        return (
            <div className="confirm-invite-html">
                <div className="confirm-invite-html-content" dangerouslySetInnerHTML = {{__html: emailHtml}}></div>
                <p class="confirm-invite-html-sample">{__('Email template')}</p>
            </div>
        );
    }
}
export default ConfirmEmailModels;