import React, { Component } from 'react';
import { Form, FormItem, withForm } from 'react-antd-formutil';
import { Input, Button, message, Checkbox } from 'antd';
import { post } from 'srcPath/http';
import security from 'stores/security';
import { bindKeyEvent } from 'Utils';

import './style.scss';

const email = new RegExp('(?![\\-_.])[\\w\\-.]+@(?:(?![\\-_])[\\w\\-\u4e00-\u9fa5]{1,63}\\.)+[A-Za-z0-9]{2,}')

class AccountProfileModal extends Component {
	state = {
		loading: false,
		isSync:false
	}

	componentDidMount() {
		this.removeKeyEvent = bindKeyEvent(this.onSubmit)
	}

	componentWillUnmount() {
		this.removeKeyEvent && this.removeKeyEvent()
	}

	onSubmit = async () => {
		const { $formutil, close, callback } = this.props
		const { $invalid, $batchDirty, $params, $getFirstError } = $formutil
		const { isSync } = this.state
		if ($invalid) {
			$batchDirty(true)
			message.error($getFirstError())

			return
		}

		this.setState({ loading: true })

		try {
			const { data } = await post('editAccountProfileInfo', {
				uid: security.userInfo.id,
				...$params,
				isSync
			})

			if (data.code === 200) {
				this.setState({ loading: false })

				security.userInfo.user_name = $params.userName

				callback && callback()
				close();
			}

		} catch (e) {
			message.error(e.message)
		}

		this.setState({ loading: false })
	}
	checkChange = () =>{
		const { isSync } = this.state
		this.setState({
			isSync:!isSync
		})
	}
	render() {
		const { userName, contactEmail } = this.props.defaultValue
		const { isSync } = this.state
		return <div className="account-profile-modal">
			<div className="account-profile-form">
					<FormItem
						name="userName"
						$defaultValue={userName}
						required
						itemProps={{
							label: __('User Name')
						}}
						colon={false}
						validMessage={{
							required: __('User Name is required')
						}}
					>
						<Input placeholder={__('e.g.John Doe')} />

					</FormItem>
					<Checkbox checked={isSync} onChange={this.checkChange.bind(this)}  className="check">{__('Apply user name to signature name.')}</Checkbox>
				<FormItem
					name="currentEmail"
					$defaultValue={contactEmail}
					required
					pattern={email}
					itemProps={{
						label: __('Email')
					}}
					colon={false}
					validMessage={{
						required: __('Email is required'),
						pattern: __('Email address is invalid')
					}}

				>
					<Input placeholder={__('e.g.John@example.com')} disabled />
				</FormItem>


			</div>
			<Button type="primary" loading={this.state.loading} onClick={this.onSubmit}>{__('Save Changes')}</Button>
		</div>
	}
}

export default withForm(AccountProfileModal)