import React, { Component } from 'react';
import { message, Table, Typography } from 'antd';
import { cashRaisedColumns } from './column-config';
import Charts from './CashCharts';
import Utils, { formatNumber, formatCurrencyNumber } from "Utils";
import '../style.scss'

const { Text } = Typography;


export default class CashRaised extends Component {
    render() {
        const { data = [], currency = '' } = this.props;
        return (
            <div>
                <div className="finacing-history-chart">
                    <div className="banner">{__('Cash raised')}</div>
                    <div className="content">
                        <Charts currency={currency} data={data} />
                    </div>
                </div>
                <div className="finacing-history-found-table">
                    <Table
                        pagination={false}
                        columns={cashRaisedColumns}
                        dataSource={data}
                        scroll={{ x: true }}
                        locale={{ emptyText: __('No Data') }}
                        summary={pageData => {
                            const obj = {
                                'cashPaid': 0,
                                'debtCanceled': 0,
                                'ipTransferred': 0,
                                'otherConsideration': 0,
                                'shares': 0
                            };
                            const nameList = [__('Total'), '- -', '- -', 'cashPaid', 'debtCanceled', 'ipTransferred', 'otherConsideration', 'shares'];
                            pageData.forEach(({ cashPaid, debtCanceled, ipTransferred, otherConsideration, shares }) => {
                                obj.cashPaid += Number(cashPaid);
                                obj.debtCanceled += Number(debtCanceled);
                                obj.ipTransferred += Number(ipTransferred);
                                obj.otherConsideration += Number(otherConsideration);
                                obj.shares += Number(shares);
                            });

                            return (
                                <Table.Summary.Row>
                                    {nameList.map((item, index) => {
                                        if (index < 3) return <Table.Summary.Cell key={index}><Text strong>{item}</Text></Table.Summary.Cell>
                                        else if (index === 7) return <Table.Summary.Cell key={index}><Text strong>{formatNumber(obj[item], 2)}</Text></Table.Summary.Cell>
                                        else return <Table.Summary.Cell key={index}><Text strong>{formatCurrencyNumber(obj[item], 2)}</Text></Table.Summary.Cell>
                                    })}
                                </Table.Summary.Row>
                            );
                        }}
                    />
                </div>
            </div>
        )
    }
}