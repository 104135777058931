import React from 'react';
import Progress from './progress';
import moment from 'moment';
import OperateBtnGroup from './operateBtnGroup';
import OperateButton from './operateButton';
import { constData } from 'Utils';
import { formatDate as formatDateUtil } from 'Utils/format/formatDate';

const colorMap = {
    1: '#B9CCD1',
    2: '#FF892A',
    3: '#21CB59',
    4: '#d91e18',
    6: '#FF4141',
}

const renderValue = val => val || '--'

const statusMap = {
    1: __('Draft'), //草稿
    2: __('Pending board approval'), // 待审核
	3: __('Approved'), //审核通过
    4: __('Canceled'), //取消
    5: __('Delete'), //删除
    6: __('Expired'), //过期
}

const rederDate = (date) => {
    if (!date) return '--';
    return formatDateUtil(moment(date * 1000).format('DD/MM/YYYY'));
}

const renderExpireDate = (date) => {
    if (!date) return '--';
    return formatDateUtil(date)
}

const columns = [
    {
        title: __('Name'),
        dataIndex: 'consent_name',
        sorter: (a, b) => a.consent_name.length - b.consent_name.length,
        ellipsis: true,
    },
    {
        title: __('Create date'),
        dataIndex: 'create_date',
        sorter: (a, b) => a.create_date - b.create_date,
        render: date => rederDate(date),
    },
    {
        title: __('Sent for approval date'),
        dataIndex: 'publish_date',
        sorter: (a, b) => a.publish_date - b.publish_date,
        render: date => rederDate(date),
    },
    {
        title: __('Expiration date'),
        dataIndex: 'expiration_date',
        sorter: (a, b) => a.expiration_date - b.expiration_date,
        render: date => renderExpireDate(date),
    },{
        title: __('Approval date'),
        dataIndex: 'approval_date',
        sorter: (a, b) => a.approval_date - b.approval_date,
        render: date => rederDate(date),
    },
    {
        title: __('Status'),
        dataIndex: 'status',
        sorter: (a, b) => a.status - b.status,
        render: text => <div className="meeting-list-status"> <div className="status-dot" style={{ background: colorMap[text] || '#bdd1d5' }}/> {statusMap[text]} </div>
    },
    {
        title: __('Progress'),
        dataIndex: 'status',
        sorter: (a, b) => a.status - b.status,
        render: (status, record) => <Progress data={record} />,
        width: 150,
    },
    {
        title: '',
        dataIndex: '',
        align: 'right',
        render: (value, record) => <OperateBtnGroup data={record} type="consent"  />,
        width: 60,
    },
]

const templateColumns = [
    {
        title: __('Name'),
        dataIndex: 'template_name',
        ellipsis: true,
        width: 220,
    },
    {
        title: __('Last edited'),
        dataIndex: 'update_time',
        render: date => rederDate(date),
    },
    {
        title: '',
        dataIndex: '',
        align: 'right',
        render: (value, record) => <OperateBtnGroup data={record} type="template" />,
        width: 60,
    },
]

const templateSelect = [
    {
        title: __('Name'),
        dataIndex: 'template_name',
        ellipsis: true,
        render: (name, record) => <OperateButton type="radio" name={name} record={record} />,
    },
    {
        title: __('Last edited'),
        dataIndex: 'update_time',
        render: date => formatDateUtil(date * 1000),
    },
    {
        title: '',
        dataIndex: '',
        render: () => <OperateButton type="preview" />,
    },
];

const boardMemberColumns = [
    {
        title: __('Name'),
        dataIndex: 'nick_name',
    },
    {
        title: __('Email'),
        dataIndex: 'email',
        render: (email) => <OperateButton type="mail" name={email} />,
    },
    {
        title: __('Role'),
        dataIndex: 'board_role',
        render: text => renderValue(constData.boardRole[text]),
    },
]


export default colorMap;



export { colorMap, statusMap, columns, templateColumns, templateSelect, boardMemberColumns };