import React, { Component, Fragment } from 'react';
import { Button, Card, Skeleton, message, Row, Col, Collapse, Popover } from 'antd';
import { I, formatWithArray } from 'Utils';
import { post } from 'srcPath/http';
import { formatDate } from 'Utils/format/formatDate';
import Header from "./header";
import { DownOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import security from 'stores/security';
import Contacts from "./contacts";
import '../style.scss';

export default class Overview extends Component {

    state = {
        loading: false,
        delayTime: 0,
        data: {
            planName: '',
        },
        help: <span>A "Folium User" is defined as an unique user with any of the following permissions/login: 1) company account 2) stakeholder account or 3) board member/observer account.  <br />If a Folium User has all three permissions, it is still consider as 1 Folium User.</span>,
        boxList: [
            { title: 'Active stakeholders', value: '', duplicatesExcluded: '' },
            { title: 'Payment recurrence', value: '' },
            { title: 'Next billing date', value: '' },
            { title: 'Total charge on next billing date', value: '' },
        ],
    };

    componentDidMount() {
        this.payOverView();
    }

    payOverView = async () => {
        this.setState({ loading: true })
        try {
            const { data: resData } = await post('payOverView')
            const { code, data } = resData;
            if (code === 0) {
                if (!I(data, '')) {
                    message.error(data)
                    return;
                }
                data['planPrice'] = '';
                this.init(data);
            } else {
                message.error(data)
            }
        } catch (err) {
            message.error(err && err.message)
        }
        this.setState({ loading: false })
    }

    init = (data) => {
        const { boxList } = this.state;
        let newBoxList = [...boxList];
        const { member, memberMax, planAmount, groupDetail } = data;
        const newMember = I(groupDetail?.stakeholderTotal, 0) > 0 ? I(groupDetail?.stakeholderTotal, 0) : member;

        const message = <div className="billing-overview-duplicates-excluded ">{__('The number of stakeholders displayed here represents the total number of unique individuals or groups with a vested interest in the current company and all of its subsidiary companies.')}<br />{__('The current number of stakeholders of each company are:')}</div>
        //stakeholder
        newBoxList[0]['value'] = <div className="billing-overview-total-font"><span className="billing-overview-total-font" style={{ color: I(newMember, 0) >= I(memberMax, 0) ? '#FF5151' : 'black' }}>{newMember}</span> / {memberMax}</div>;
        const company = groupDetail?.groupData && groupDetail?.groupData.map((v, k) => {
            const { company_name, total } = v;
            return (
                <Fragment key={k}>
                    {k === 0 && message}
                    <div className="billing-overview-subsidiary-info">{company_name} ({total} {total == 1 ? __('Active stakeholder') : __('Active stakeholders')})</div>
                </Fragment>
            )
        });
        newBoxList[0].duplicatesExcluded = company;
        //Payment recurrence
        newBoxList[1]['value'] = this.getRecurrence(data);
        //Next billing date
        newBoxList[2]['value'] = formatDate(I(data.endDate, ''));
        //Total charge on next billing date
        newBoxList[3]['value'] = (I(planAmount, '') ? `$${formatWithArray(I(planAmount, ''))} USD` : data.planName == "Beta Plan" ? 'Contact' : '--');
        this.setState({ boxList: newBoxList, data })
    }

    /**
     * 展示bill email数据
     * @param emailList
     * @returns {JSX.Element}
     */
    getEmailList = () => {
        const { data } = this.state;
        const { contact, superAdmin } = data;
        const emailList = I(contact, '') ? [...contact, ...I(superAdmin, [])] : [];
        let email = [];
        let billingEmail;
        if (I(emailList, '')) {
            emailList.map((v, k) => {
                email.push(I(v.contact_mail, v.email));
            });
            billingEmail = <p>Billing emails are sent to {
                email.map((v, k) => {
                    return (
                        <span key={k}><b>{v}</b> {k < (email.length - 1) && ' and '}</span>
                    )
                })
            }.</p>;
        } else {
            billingEmail = <p>Billing emails are empty. <a onClick={this.getPage.bind(this, 'contacts')}>Please add billing emails.</a></p>;
        }
        return billingEmail;
    }

    getPage = (value) => {
        const { getPage, history } = this.props;
        if (value === 'changePlan') {
            history.push('/home/setting/plan');
        } else {
            getPage(value);
        }
    }
    getContacts = () => {
        window.open('https://meetings.hubspot.com/jake-low1', '_blank')
    }

    /**
     * 获取计划订阅周期
     */
    getRecurrence = (data) => {
        const payType = { 0: '--', 4: 'Annual', 3: 'Monthly' };
        return !!data.planName ? payType[data.planType] : '--';
    }

    /**
     * overview 框体内容
     * @returns {unknown[]}
     */
    boxInfo = () => this.state.boxList.map((v, k) => <Col className="billing-overview-box-plan" key={k} span={6}>
        <div className="billing-overview-box-item">
            <div>
                <h5>{v.title}</h5>
                {v.value == 'Contact' &&
                    <a className="billing-overview-box-item-value-active" onClick={this.getContacts.bind()}>Contact us</a>
                }
                {v.value != 'Contact' &&
                    <div className="billing-overview-box-item-value">{v.value}</div>
                }
            </div>
        </div>
        {v.duplicatesExcluded && <Popover
            content={v.duplicatesExcluded}
            trigger="click"
            placement="bottomLeft"
            getPopupContainer={triggerNode => triggerNode.parentNode}
            autoAdjustOverflow={false}
        ><div className="billing-overview-box-item-excluded">
                <span>Duplicates excluded </span>
                <div className="billing-overview-box-item-excluded-help">?</div>
            </div></Popover>}
    </Col>);

    render() {
        const { data, loading, help } = this.state;
        const { member, memberMax } = data;
        const { isArrears } = security;
        const { ...props } = this.props;
        return (
            <div className="sprout-setting-billing">
                <Header {...props} />
                <Card>
                    <Skeleton loading={loading} active >
                        <div className="billing-setting-overview-title">
                            {isArrears === false && <div className="billing-setting-overview-title-suspended"><ExclamationCircleFilled />Account Suspended. Please contact us.</div>}
                            <h6>Current plan</h6>
                            <div className="overview-title-box">
                                {<h2>{I(data.planName, 'Beta Plan')}</h2>}
                                {isArrears === false && <Button type="primary" onClick={this.getPage.bind(this, 'suspended')}>Contact us</Button>}
                                {isArrears === true && security.subscriptionId && <Button type="primary" onClick={this.getPage.bind(this, 'changePlan')}>Change</Button>}
                            </div>
                        </div>
                        {I(member, 0) >= I(memberMax, 0) ? <div className="billing-overview-warning">You've reached the users cap, we highly recommend you upgrade your plan.</div> : ''}
                        <Row gutter={10} className="billing-overview-box">{this.boxInfo()}</Row>
                        <Collapse ghost={true} bordered={false} className="billing-contacts-content">
                            <Collapse.Panel className="billing-contacts-content-main" header={<span>Billing contacts</span>} key="1" >
                                <Contacts pageName={'contacts'} getPage={this.props.getPage} />
                            </Collapse.Panel>
                        </Collapse>
                    </Skeleton>
                </Card>
            </div>
        )
    }
}