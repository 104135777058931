import React, { Component } from 'react';
import {message, Spin, Button} from 'antd';
import { post } from 'srcPath/http';
import { I } from 'Utils';
import ContactsAdd from "./ContactsModel/ContactsAdd";
import Modal, { ModalContent } from 'components/Modal';
import Loading from 'components/Loading';
import '../style.scss';
import userRemove from "./ContactsModel/img/user-remove.png";

export default class Contacts extends Component {

    contactAddModel = null;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            contractList: [],
            adminList: [],
            address: '',
            selectValue: '',
            data: [],
        }
    }

    componentDidMount = () => {
        this.getContactList();
    }

    /**
     * 初始化
     * @returns {Promise<void>}
     */
    getContactList = async() => {
        this.setState({ loading: true })
        try {
            const { data: resData } = await post('getContactList')
            const { code, data } = resData;
            if (code === 0) {
                const { contact_list, admin_list, superAdmin } = data;
                if(!I(data, '')){ return; }
                const contractList = this.delContractListRepetitive(superAdmin, contact_list);
                let adminList = [];
                I(admin_list, []).map( (v)=>adminList.push({
                    'name': I(v.nick_name, ''),
                    'email': I(v.email, '')
                }));
                this.setState({
                    contractList,
                    adminList,
                    defAdminList:adminList,
                });
            } else {
                message.error(data)
            }
        } catch (err) {
            message.error(err && err.message)
        }
        this.setState({ loading: false })
    }

    delContractListRepetitive = (superAdmin, contractList) => {
        let newContractList = [];
        newContractList = this.removeRepetitive(superAdmin, newContractList, 'nick_name', 'email', true);
        newContractList = this.removeRepetitive(contractList, newContractList, 'contact_name', 'contact_mail');
        return newContractList;
    }

    removeRepetitive = (list, newList, nameKey, emailKey, isSuper=false) => {
        list && list.forEach( v => {
            const checkIndex = newList.findIndex( v1 => v1.name == v[nameKey] && v1.email == v[emailKey]);
            checkIndex === -1 && newList.push({ id: v.id, name: v[nameKey], email: v[emailKey], customer: 'Billing email', isSuper })
        });
        return newList;
    }

    /**
     * 邮件列表
     * @returns {unknown[]}
     */
    contractList = () => {
        const { contractList } = this.state;
        return contractList.map((contract, key) => (
            <div className="contact-list-item" key={contract.id}>
                <div className="contact-list-item-header">{I(contract.name, contract.email).substring(0, 1).toUpperCase()}</div>
                <div className="contact-list-item-info">
                    <span className="contact-list-item-info-email">{contract.email}</span>
                    <span className="contact-list-item-info-name">{I(contract.name, '') ? `${I(contract.name, '').substring(0, 1).toUpperCase()}${(I(contract.name, '')).slice(1)}` : ''}</span>
                </div>
                {!I(contract.isSuper, false) && <div className="operate-btn"><Button type="link" onClick={this.delContacts.bind(this, key)}><img src={userRemove} />&nbsp;Remove</Button></div>}
            </div>
        ));
    }

    /**
     * 拼接邮箱
     * @returns {string}
     */
    emailStr = () => {
        const { contractList } = this.state;
        if(!I(contractList, '')){
            return 'Billing emails are empty. Please add billing emails.';
        }
        return 'Billing emails will be sent to billing contacts from this list.';
    }

    /**
     * 添加邮件
     */
    addEmailAddress = (value, close) => {
        this.saveContacts(value, close);
    }

    /**
     * 添加邮件异步
     * @param value
     * @returns {Promise<boolean>}
     */
    saveContacts = async(value, close) => {
        Loading.global(true)
        const { contractList } = this.state;
        let newList = [...contractList];
        const dataAttr = {
            contactName : value.contactName,
            contactMail : value.emailAddress,
        };
        try {
            const { data: resData } = await post('editContact', dataAttr)
            const { code, data } = resData;
            if (code === 0) {
                if(!I(data, '')){
                    return;
                }
                newList.push({
                    id: data,
                    name: dataAttr.contactName,
                    email: dataAttr.contactMail,
                    customer: 'Billing email'
                });
                //overview添加邮件信息
                this.updateDataEmail('add', {contact_mail: dataAttr.contactMail});
                this.setState({
                    contractList: newList,
                    loading: false,
                })
            }
        } catch (err) {
            message.error(err && err.message)
        }
        Loading.global(false);
        close();
    }

    /**
     * 删除邮件
     */
    delContacts = async (key) => {
        Loading.global(true)
        const { contractList } = this.state;
        let contractListNew = [...contractList];
        try {
            const { data: resData } = await post('deleteContact', {id:contractList[key]['id']})
            const { code, data } = resData;
            if (code === 0) {
                if(!I(data, '')){
                    return;
                }
                contractListNew.splice(key, 1)
                this.setState({ contractList: contractListNew })
                //overview删除邮件信息
                this.updateDataEmail('del', key);
            }
        } catch (err) {
            message.error(err && err.message)
        }
        Loading.global(false)
    }

    /**
     * 更新contact数据
     * @param value
     */
    updateDataEmail = (type, value) => {
        const { data } = this.state;
        let dataList = {...data};
        dataList['contact'] = I(dataList['contact'], []);
        if(type == 'add'){
            dataList['contact'].push(value);
        }else{
            dataList['contact'].splice(value, 1);
        }
        this.setState({
            data: dataList
        })
    }

    showAddContactModel = () => {
        this.contactAddModel = Modal.open({
            component: props => (
                <ModalContent close={props.close} title="Add billing contact">
                    <ContactsAdd close={props.close} addEmailAddress={this.addEmailAddress} {...this.state} />
                </ModalContent>
            ),
            width: 600,
            className: 'sprout-modal',
            maskClosable: false
        });
    }

    componentWillUnmount() {
        this.contactAddModel && this.contactAddModel.close()
    }

	render() {
		const { loading } = this.state;
		return (
            <div className="sprout-setting-billing-component">
                <div className="billing-contacts-card-header">
                    <div className="billing-contacts-card-header-tit">
                        <h5 className="billing-contacts-card-header-h5">{this.emailStr()}</h5>
                        <h5 className="billing-contacts-card-header-h5-desc">You may add additional billing contacts (for example, your accounting department).</h5>
                    </div>
                    <Button loading={loading} type="primary" onClick={this.showAddContactModel}>Add billing contact</Button>
                </div>
                <Spin tip="Loading..." spinning={loading}>{this.contractList()}</Spin>
            </div>
        )
	}
}