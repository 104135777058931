import React, {Component, Fragment} from "react";
import {Button, Radio, Space, Row, Col, Input, Tooltip, message, Form} from "antd";
import Contact from "../Contact";
import { I, calculator, formatNumber } from 'Utils';
import successImg from "../Public/img/success.png";
import successImgWathet from "../Public/img/scccess-wathet.png";
import speakUs from "../Public/img/speak-us.png";
import triangle from "../Public/img/triangle.png";
import talk from "../Public/img/talk.png";
import { post } from 'srcPath/http';
import Loading from 'components/Loading';
import "../PickPlan/style.scss";

class PickPlan extends Component {

    formRef = React.createRef();

    state = {
        loading: false,
        speakShow: 2,
        stretchShow: false,
        planType: 1,
        goodsCodeError: false,
        goodsCodeErrorStr: '',
        goodsCodeDisabled: true,
    }

    Lang = {
        goodsCodeError: 'Invalid product code. Please try again.'
    }

    getPage = (planId, key) => {
        const { updateProductType } = this.props;
        updateProductType(key);
        this.updateDataList(planId);
        this.getNextPage();
    }

    getGoodsInfo = (planId, key, goodsCode) => {
        this.setState({goodsCodeError: false});
        if(!I(goodsCode, '')){
            this.setState({goodsCodeError: true});
            return;
        }
        this.getGoodsPriceByCode(planId, goodsCode);
    }

    getGoodsPriceByCode = async (planId, goodsCode) => {
        Loading.global(true);
        try {
            const { data: resData } = await post('getGoodsPriceByCode', { code: goodsCode })
            const { code, data } = resData;
            if( code === 0){
                const { goods, goods_price } = data;
                const { updateState, payment } = this.props;
                const { subscriptionCycle } = payment;
                if(!I(goods_price, '')){
                    return;
                }
                const goodsPrice = goods_price[subscriptionCycle];
                let item = {id: goodsPrice.id, plan_name: goods.goods_name, recurring: goodsPrice.recurring, price: goodsPrice.price};
                updateState({itemList: [item], total: item.price, planId: goods.id});
                this.getNextPage();
            }else{
                this.setState({goodsCodeError: 'error', goodsCodeErrorStr: this.Lang.goodsCodeError});
            }
        } catch(err) {
            message.error(err && err.message)
        }
        Loading.global(false);
    }

    updateDataList = (planId) => {
        const { updateState, planList, payment } = this.props;
        const { subscriptionCycle } = payment;
        let itemList = planList[planId][subscriptionCycle];
        itemList.price = itemList.item_price;
        updateState({itemList: [itemList], total: itemList.price, planId});
    }

    getPlanLabel = (key) => {
        const { planList, payment } = this.props;
        const newPlanList = Object.values(planList);
        const { subscriptionCycle } = payment;
        const newKey = key === 2 ? key+1 : key-1;
        const { plan_label } = newPlanList[newKey] && newPlanList[newKey][subscriptionCycle] || {}
        return <span>{plan_label}</span>;
    }

    speakShowChange = (stretchShow, key) => {
        if(stretchShow){
            const speakShow = key === 2 ? 3 : 2;
            this.setState({speakShow, stretchShow: false})
        }
    }

    stretchShowChange = () => {
        const { stretchShow } = this.state;
        this.setState({stretchShow: !stretchShow})
    }

    getPlanMPrice = (price) => {
        const mPrice = calculator.agile(`${price} / 12`);
        const newPrice = mPrice && mPrice.split('.');
        const decimal = newPrice && newPrice[1];
        return decimal > 0 ? formatNumber(mPrice, 0) : mPrice;
    }

    /**
     * 获取 PickPlan 详细内容
     */
    getPickPlanList = () => {
        const { speakShow, stretchShow, goodsCodeError, goodsCodeErrorStr, goodsCodeDisabled } = this.state;
        const { loading, planList, payment } = this.props;
        const { subscriptionCycle, subscriptionCycleDesc } = payment;
        const goodsCode = I((this.formRef.current && this.formRef.current.getFieldValue('goodsCode')), '');
        return Object.keys(planList).map( (v, k)=>{
            if((speakShow === 2 && k === 3) || (speakShow === 3 && k === 2)){
                return '';
            }
            const { plan_name, plan_remark, plan_label, item_price, recurring, plan_details } = planList[v] && planList[v][subscriptionCycle]  || {};
            const { item_price: newItemPrice } = planList[v] && planList[v][subscriptionCycle] || {};
            const planDescDetail = plan_details && JSON.parse(plan_details);
            return <Col className="gutter-row" span={8} key={k}>
                <div className={ `pick-plan-content-box ${k >= 2 && 'pick-plan-content-right'}` }>
                    {k >= 2 ? <div className="pick-plan-content-bottom"></div> : ''}
                    <dl>
                        <dt>
                            <div className="pick-plan-content-title">{plan_name}</div>
                            <div className="pick-plan-content-desc">{plan_remark}</div>
                            {
                                k >= 2 ? (
                                    <div className="pick-plan-content-speak">
                                        <img className="pick-plan-content-speak-usimg" src={speakUs} />
                                        {/*<div className="pick-plan-content-speak-desc pick-plan-content-speak-desccur" onClick={this.stretchShowChange}>{plan_label}<img src={triangle} /></div>*/}
                                        <div className="pick-plan-content-speak-desc pick-plan-content-speak-desccur">{plan_label}</div>
                                    </div>
                                ) : (
                                    <Fragment>
                                        <div className="pick-plan-content-box-important">
                                            <div className="pick-plan-content-setup">
                                                <span className="pick-plan-content-setup-price">US${recurring == 4 ? this.getPlanMPrice(item_price) : item_price}</span><span className="pick-plan-content-setup-cycle">&nbsp;/&nbsp;month</span>
                                                <div className="pick-plan-content-setup-pricedesc">{`(US$${formatNumber(item_price, 0)} a ${I(subscriptionCycleDesc[recurring], '')})`}</div>
                                                <p className="pick-plan-content-setup-desc">{plan_label}</p>
                                            </div>
                                        </div>
                                        <div className="pick-plan-content-graphical"></div>
                                    </Fragment>
                                )
                            }
                        </dt>
                        <dd className="pick-plan-content-detail">
                            <div className="pick-plan-content-detail-title">{planDescDetail ? planDescDetail[0] : ''}</div>
                            <ul>{this.getDetailList(planDescDetail, k)}</ul>
                        </dd>
                        <dd className="pick-plan-content-button">
                            {k == 2 && (
                                <Form className="pick-plan-content-button-code" ref={this.formRef} onFinish={this.onFinish.bind(this, v, k)} onValuesChange={this.formChange}>
                                    <Form.Item name="goodsCode" validateStatus={goodsCodeError} help={goodsCodeErrorStr}>
                                    {/*<Form.Item name="goodsCode" validateStatus={'error'} help={'Invalid product code. Please try again.'}>*/}
                                        <Input placeholder="Enter product code" />
                                    </Form.Item>
                                    <Tooltip placement="right" title="Enter the code we provided to complete your subscription."><span className="pick-plan-content-button-code-icon">!</span></Tooltip>
                                    <Form.Item className="pick-plan-content-button-item"><Button disabled={goodsCodeDisabled} type="primary" htmlType="submit">Subscribe</Button></Form.Item>
                                </Form>
                            )}
                            {k < 2 && <Button onClick={this.getPage.bind(this, v, k)} >Subscribe</Button>}
                        </dd>
                    </dl>
                </div>
            </Col>
        })
    }

    onFinish = (planId, key, data) => {
        const { goodsCode } = data;
        const { updateProductType } = this.props;
        updateProductType(key);
        this.getGoodsInfo(planId, key, goodsCode);
    }

    formChange = (changeValue) => {
        const { goodsCode } = changeValue;
        this.setState({goodsCodeError: false, goodsCodeErrorStr: '', goodsCodeDisabled: !I(goodsCode, '') });
    }

    /**
     * 权益说明
     * @param data
     * @returns {*}
     */
    getDetailList = (list, key) => {
        return list && list.map( (v, k) => (k !== 0 && <li key={k}><img src={key >= 2 ? successImgWathet :successImg} className="pick-plan-content-detail-icon" /><div className="pick-plan-content-detail-value">{v}</div></li>))
    }

    getTokenPlanDetail = (list) => {
        list = list && JSON.parse(list);
        return list && list.map(v=><li><img src={successImg} className="pick-plan-content-detail-icon" /><div className="pick-plan-content-detail-value">{v}</div></li>);
    }

    /**
     * 下一页
     */
    getNextPage = () => {
        const { updateTabCurrentType } = this.props;
        updateTabCurrentType(2);
    }

    isContactChange = (type) => {
        const { updateState } = this.props;
        updateState('isContact', type);
    }

    /**
     * 更新订阅周期
     */
    cycleChange = (type) => {
        const { payment, updateState } = this.props;
        let paymentObj = {...payment};
        paymentObj.subscriptionCycle = type;
        updateState('payment', paymentObj);
    }

    planTypeChange = (e) => {
        const { value: planType } = e.target;
        this.setState({planType})
    }

    render() {
        const { loading, planType } = this.state;
        const { payment, isContact, tokenItem, ...props } = this.props;
        return (
            <Fragment>
                {isContact ? <Contact  isContactChange={this.isContactChange} {...props} /> :
                    <div className="pick-plan">
                        <div className="pick-plan-background" >
                            <div className="pick-plan-background-a"></div>
                            <div className="pick-plan-background-b"></div>
                            <div className="pick-plan-title">Plans and Pricing</div>
                            <Radio.Group className="pick-plan-background-radio" value={planType} onChange={this.planTypeChange}>
                                <Space size={95}>
                                    <Radio value={1} className="pick-plan-background-radio-value">Equity Management</Radio>
                                    <Radio value={2} className="pick-plan-background-radio-value">Token Management</Radio>
                                </Space>
                            </Radio.Group>
                            {1==2 && planType == 1 ? (
                                <>
                                    <div className="pick-plan-background-button">
                                        <span
                                            className={payment.subscriptionCycle === 3 ? 'pick-plan-background-button-span-white' : 'pick-plan-background-button-span-black'}
                                            onClick={this.cycleChange.bind(this, 3)} >
                                            Monthly
                                        </span>
                                        <span className={payment.subscriptionCycle === 4 ? 'pick-plan-background-button-span-white' : 'pick-plan-background-button-span-black'}
                                              onClick={this.cycleChange.bind(this, 4)} >
                                            Annual
                                        </span>
                                        <div className={['pick-plan-background-button-bkg', payment.subscriptionCycle === 4 ? 'pick-plan-background-button-right' : 'pick-plan-background-button-left'].join(' ')}></div>
                                    </div>
                                    <p className="pick-plan-background-desc">Save up to 20% on annual subscriptions</p>
                                </>
                            ) : ''}
                        </div>
                        <div className={`pick-plan-content ${planType === 2 && 'pick-plan-content-mt' }`}>

                            {planType === 1 ? <Row gutter={58} >{this.getPickPlanList()}</Row> : (
                                <div className="pick-plan-content-box pick-plan-content-token">
                                    <dl>
                                        <dt>
                                            <div className="pick-plan-content-title">{tokenItem.plan_name}</div>
                                            <div className="pick-plan-content-desc">{tokenItem.plan_remark}</div>
                                            <div className="pick-plan-content-box-important">
                                                <div className="pick-plan-content-setup"><span
                                                    className="pick-plan-content-setup-price">US$<div>{tokenItem.item_price}</div></span><span
                                                    className="pick-plan-content-setup-cycle">&nbsp;/&nbsp;day*</span>
                                                    <p className="pick-plan-content-setup-desc">{tokenItem.plan_label}</p>
                                                </div>
                                            </div>
                                            <div className="pick-plan-content-graphical"></div>
                                        </dt>

                                        <dd className="pick-plan-content-detail pick-plan-content-token-detail">
                                            <ul>
                                                {this.getTokenPlanDetail(tokenItem.plan_details)}
                                            </ul>
                                        </dd>
                                    </dl>
                                    <dl className="pick-plan-content-token-button" >
                                        {/*<Button disabled={loading} onClick={this.getPage.bind(this, tokenItem.item_id, tokenItem.id, 1)}>Subscribe</Button>*/}
                                        <Button disabled={loading} onClick={this.isContactChange.bind(this, 1)}>Speak to us</Button>
                                    </dl>
                                    {/*<dl className="pick-plan-content-token-speak">*/}
                                    {/*    <p>Customized solutions for larger organization</p>*/}
                                    {/*    <Button type="primary" onClick={this.isContactChange.bind(this, 1)}>Speak to us</Button>*/}
                                    {/*</dl>*/}
                                </div>
                            )}

                        </div>
                        <div className="pick-plan-desc">Sprout supports companies at every stage - whether starting out or mature; new at equity or entering into tokens. Sprout has you covered.</div>
                        <div className="pick-plan-talk" onClick={this.isContactChange.bind(this, 1)}><Space size={6}><img src={talk} /><span className="pick-plan-talk-span">Talk to us if you have any concerns</span></Space></div>
                        {/*<div className="pick-plan-discover">
                            <Space size={250}>
                                <p>Discover how Sprout is helping businesses like yours with their equity and token management needs!</p>
                                <Button>VIDEO</Button>
                            </Space>
                        </div>*/}
                    </div>
                }
            </Fragment>
        );
    }
}
export default PickPlan;