import React, { Component } from 'react';
import { post } from 'srcPath/http';
import { Link } from "react-router-dom";
import { message, Button, Table, Input } from 'antd';
import security from 'stores/security';
import { RightOutlined } from '@ant-design/icons';
import url from 'url';
import moment from 'moment';
import TimeLine from './TimeLine';
import { boardMemberColumns } from './config';
import DateSelector from 'components/DateSelector';
import './index.scss';

const { TextArea } = Input;

export default class BoardSetting extends Component {
	state = {
		tableData: [],
		loading: true,
		textValue: '',
		expirationDate: '',
		consent: {}
	}

	componentDidMount() {
		this.getInitConsent();
	}

	getInitConsent = async () => {
		const state = this.props.location.state || {};
		const { consentId } = state;
		try {
			const { data: resData } = await post('getConsentDetail', { consent_id: consentId })

			const { code, data } = resData || {}
			if (code == 0) {
				const content = data.consent_detail;
				const board_user_list = data.board_user_list || {};
				const newData = board_user_list.filter((val, index) => { 
					val.index = index 
					return [7, 8].includes(val.board_role)
				})
				this.setState({
					consent: content,
					expirationDate: content.expiration_date,
					textValue: content.custom_message,
					tableData: newData
				});
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}
	}

	handleSave = async (jump) => {
		
		const { consent, textValue, expirationDate } = this.state;
		const state = this.props.location.state || {};
		const { consentId } = state;
		if (!expirationDate || !textValue) {
			return message.warn(__('Please set an expiration date and email notification'));
		}
		const params = {
			...consent,
			consent_id: consentId,
			expiration_date: expirationDate,
			custom_message: textValue,
		};

		try {
			const { data: resData } = await post('editConsent', params)

			const { code, data } = resData || {}

			if (code == 0) {
				if (jump) {
					this.props.history.push({
						pathname: '/home/board/consents/board/publish',
						state: { consentId }
					});
				} else {
					this.props.history.push('/home/board/consents/list');
				}
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}
	}

	onChange(date, dateString) {
		this.setState({
			expirationDate: date
		});
	}

	async handlePreviewEmail() {
		const { consent, expirationDate, textValue } = this.state;
		const { consent_id, consent_name } = consent;
		if (!consent_id || !consent_name || !expirationDate || !textValue) {
			return message.warn(__('Please set an expiration date and email notification'));
		}
		const params = {
			consent_id, 
			consent_name,
			expire_date: moment(expirationDate).format('DD/MM/YYYY'),
			custom_message: textValue
		};
		try {
			const data = await post('getMailView', params)
			localStorage.setItem('SPROUT_EMAIL_PREVIEW', data.data.data);
			window.open('/home/board/consents/viewemail');
		} catch(err) {
			message.error(err && err.message)
		}
	}

	handleNextStep() {
		this.handleSave('jump');
	}

	handleInputChange(e) {
		this.setState({
			textValue: e.target.value
		});
	}

	render() {
		const { tableData, textValue, expirationDate } = this.state;
		const state = this.props.location.state || {};
		const { templateId } = state;
		return <div className="new-board-consents-root">
                <div className="board-consents-operate-area">
					<TimeLine step="2" type={Number(templateId) ? 'template' : 'consent'} />
					<Button className="board-consents-operate-save" onClick={this.handleSave.bind(this, null)}>{__('Save and finish later')}</Button>
					<Button type="primary" onClick={this.handleNextStep.bind(this)}>{__('Next')}</Button>
                </div>
                <div className="board-consents-wrapper">
					<div className="board-setting-right-wrapper">
						<div className="board-setting-approve">
							<h5 className="board-menber-title">{__('Board members')}</h5>
							<p>{__('Unanimous consent will be sent to all board members below.')} <Link to="/home/permissions/board">{__('Manage board members')} ›</Link></p>
							<Table 
								size="small" 
								rowKey="index" 
								columns={boardMemberColumns}
								dataSource={tableData}
								pagination={false}
							/>
						</div>
						<div className="board-setting-date">
							<h5 className="board-menber-title board-title-padding">{__('Expiration date')}</h5>
							<p>{__('Set an expiration date. If consent isn’t approved before the expiration date, it will be expired.')}</p>
							<DateSelector style={{ width: '200px' }} value={expirationDate ? moment(expirationDate) : ''} onChange={this.onChange.bind(this)} placeholder="DD/MM/YYYY" format={security.companyInfo && security.companyInfo.date_format} allowClear={false} />					
						</div>
						<div className="board-setting-message">
							<h5 className="board-menber-title board-title-padding">{__('Email notification')}</h5>
							<p>{__('All board directors will be notified when the consent is published.')}</p>
							<TextArea value={textValue} onChange={this.handleInputChange.bind(this)} rows={4} />
							<a target='_blank' onClick={this.handlePreviewEmail.bind(this)}>{__('Preview email notification')} <RightOutlined /></a>
						</div>
						</div>
				</div>
            </div>
	}
}