import React, { Component } from 'react';
import { withForm } from 'react-antd-formutil';
import { Form, Input, Button, message } from 'antd';
import { post } from 'srcPath/http';
import security from 'stores/security';
import Loading from 'components/Loading'
import './style.scss';

class SwitchEmailModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
		}
	}
	onSubmit = () => {
		let { primary, secondary, close, callback } = this.props
		Loading.global(true)
		post('switchEmail', { uid: security.userInfo.id, email: primary, secondary_email: secondary }).then(res => {
			let {data}=res
			if (data.code === 0) {
				callback && callback()
				close()
			} else {
				message.error(data.data)
			}
		}).catch(function (error) {
			message.error(data.data)
		});
		Loading.global(false)
	}
	render() {
		const { primary, secondary } = this.props
		return <div className="switch-modal">
			<div className="switch-form">
				After switched, your primary email will become {secondary},<br /> while your additional email will become {primary}. <br />Are you sure you want to switch?
			</div>
			<Button type="primary" onClick={this.onSubmit}>Switch</Button>
		</div>
	}
}

export default SwitchEmailModal