import React, { Component } from "react";
import {Button} from "antd";
import "./style.scss";

class NoPermissionFloat extends Component {

    getBilling = () => {
        this.props.history.push('/home/setting/billing');
    }

    render() {
        return (
            <div className="no-permission-float-root">
                <p>The maximum number of users has been exceeded and cannot be created!</p>
                <Button type="primary" onClick={()=>this.getBilling()}>Unlock</Button>
            </div>
        );
    }
}
export default NoPermissionFloat;