/**
 * Created by rain on 2019/7/30.
 */
import React from 'react';
import axios from 'axios';
import Utils, { I } from "Utils";
import '../../../../../assets/css/adduser.css'
import DateSelector from "../../../../../components/DateSelector";
import moment from "moment";
import {DatePicker} from "antd";

class CreateCnTermsModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            dateFormat:"DD/MM/YYYY",
            sendingStatus:0,
            existingCnCount:0,
            existingPrefix:["abc"],
            existingPlanName:["abc"],
            isShowEmptyError:0,
            data:{
                convertible_type:"convertible_debt",
                terms_name:"",
                prefix:"",
                board_approval_date:"",
                issue_date:"",
                maturity_date:"",
                valuation_cap:"",
                conversion_discount:"",
                change_in_control_percent:"",
                conversion_trigger:"",
                interest_rate:"",
                interest_accrual_end_date:"",
                day_count_convention:"",
                interest_accrual_period:"",
                interest_start_date:"",
                interest_compounding_period:"",
                interest_payout:"",
                day_count_basis:"",
                note_purchase_agreement_file:{name:__("No file uploaded"),file_obj:""},
                form_of_convertible:{name:__("No file uploaded"),file_obj:""},
            },
            cnTermsRequiredKey:["terms_name","prefix"],
            uploadProgress:0,
            dateOfIncorporation:"20/07/2020",
            compoundingPeriodList:[
                "Simple interest",
                "Daily",
                "Monthly",
                "Monthly (first day of month)",
                "Semi-annually",
                "Annually",
                "Quarterly (calendar year)",
            ],
            accrualPeriodList:[
                "Daily",
                "Weekly",
                "Monthly",
                "Monthly (first day of month)",
                "Semi-annually",
                "Annually",
                "Quarterly (calendar year)",
                "Semi-annually (calendar year)",
                "Annually (calendar year)",
            ],

            isShowUploadProgress:0,
            isExisingTerms:0 ,
            showStatusClass:"",
            checkedArr:{
                convertible_debt:"checked",
                convertible_security:"",
                safe:"",
            }
        }
    }
    getIncorporationDate=()=>{
        axios.post('https://api.getsprout.co', {
            action:'getDateOfIncorporation',
            company_id:Utils.getLocalCompanyInfo().company_id,
            data:{
                withPrefix:1,
                withPlanName:1,
            }
        }).then((response)=> {
            if(response.data.code === 0){
                this.setState({
                    dateOfIncorporation:response.data.data.date_of_incorporation,
                    existingPrefix:response.data.data.prefixList,
                    existingPlanName:response.data.data.planNameList,
                    existingCnCount:response.data.data.existingCnCount,
                });
                console.log(this.state.dateOfIncorporation)
                window.$(".convertible_note_datepicker").datepicker({
                    maxViewMode: 3,
                    todayBtn: "linked",
                    todayHighlight: true,
                    autoclose:true,
                    format:"dd/mm/yyyy",
                    startDate :this.state.dateOfIncorporation,
                }).on("changeDate",(e)=>{
                    let curData = this.state.data;
                    let dataKey = e.target.name;
                    curData[dataKey] = e.format();
                    this.setState({
                        data:curData
                    });
                });
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
            //用到this要注意this指向
        }).catch(function (error) {
            console.log(error);
        });
        console.log(this.state.dateOfIncorporation)
    }
    setExistingPrefix=()=>{
        this.getIncorporationDate();
    }
    componentDidMount(){
        // this.props.onRef(this);
        this.setConvertibleType("safe");
        $('input:radio:first').attr('checked', 'checked');
        window.$('.maturity_datepicker').datepicker({
            maxViewMode: 3,
            todayBtn: "linked",
            todayHighlight: true,
            autoclose:true,
            format:"dd/mm/yyyy",
            startDate:"10/09/2020",
            // startDate:this.state.data.issue_date,
        }).on("changeDate",(e)=>{
            console.log(this.state.data.issue_date)
            let curData = this.state.data;
            curData.maturity_date = e.format();
            this.setState({
                data:curData
            });
        })
    }
    createNote=()=>{
        let isLegalPrefix = 1; //默认为合法的输入
        for(let i=0;i<this.state.existingPrefix.length;i++){
            if(this.state.data.prefix == this.state.existingPrefix[i]){
                isLegalPrefix = 0;  //有重复改为非法
            }
        }
        if(isLegalPrefix === 0){
            return;
        }
        for(let i=0;i<this.state.existingPlanName.length;i++){
            if(this.state.data.terms_name == this.state.existingPlanName[i]){
                return;  //有重复改为非法
            }
        }
        if(Utils.isLocal()){
            this.props.history.push("/home/convertibles");
            return;
        }
        if(this.state.sendingStatus === 1){
            return;
        }
        this.setState({
            sendingStatus:1
        })
        $("#createCnButton").html("Creating");
        this.setState({
            isShowUploadProgress:1
        })
        axios.post('https://api.getsprout.co', {
            action:"createConvertibleNote",
            company_id:Utils.getLocalCompanyInfo().company_id,
            data:this.state.data
        },{
            'Content-Type':'application/json;charset=UTF-8',
            onUploadProgress:(progressEvent)=>{
                let complete = (progressEvent.loaded / progressEvent.total * 100 | 0);
                console.log('上传 ' + complete+'%') ;
                this.setState({
                    uploadProgress:complete
                });
                if(complete == 100){
                    this.setState({
                        isShowUploadProgress:0
                    })
                }
            }
        }).then((response)=> {
            console.log(response);
            if(response.data.code === 0){
                this.setState({
                    sendingStatus:0,
                    data:{
                        convertible_type:"convertible_debt",
                        terms_name:"",
                        prefix:"",
                        board_approval_date:"",
                        issue_date:"",
                        maturity_date:"",
                        valuation_cap:"",
                        conversion_discount:"",
                        change_in_control_percent:"",
                        conversion_trigger:"",
                        interest_rate:"",
                        interest_accrual_end_date:"",
                        day_count_convention:"",
                        interest_accrual_period:"",
                        interest_start_date:"",
                        interest_compounding_period:"",
                        interest_payout:"",
                        day_count_basis:"",
                        note_purchase_agreement_file:{name:__("No file uploaded"),file_obj:""},
                        form_of_convertible:{name:__("No file uploaded"),file_obj:""},
                    },
                });
                window.$("#createCnTermsModal").modal("hide");
                this.props.getCnList();
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    setConvertibleType=(typeName)=>{
        let curData = this.state.data;
        let curCheckedArr = this.state.checkedArr;
        for(let rain in curCheckedArr){
            curCheckedArr[rain] = "";
        }
        curCheckedArr[typeName] = "checked";

        curData.convertible_type = typeName;
        this.setState({
            data:curData,
            checkedArr:curCheckedArr
        });
        if(typeName === "convertible_debt"){
            this.setState({
                showStatusClass:""
            })
        }else{
            this.setState({
                showStatusClass:"conver-hidden"
            })
        }
    }
    recordData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        })


        console.log(this.state.data.issue_date)
    }
    recordNumData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        if(dataKey == "interest_rate"){
            curData[dataKey] = Utils.getNumFormatWithTwoComma( dataVal);
            if(e.target.value>0){
                window.$(".rate-dropdown").removeClass("hidden")
            }else{
                window.$(".rate-dropdown").addClass("hidden")
            }
        }else if(dataKey=="conversion_discount"){
            curData[dataKey] = Utils.getNumFormatWithTwoComma(dataVal);
        }else{
            curData[dataKey] = Utils.checkNumInput( dataVal);
        }
        this.setState({
            data:curData
        })
    }
    recordNumCurrency=(e)=>{
        let dataKey = e.target.name;
        let dataVal = Utils.checkNumInputWithStr (e.target.value,"$ ",0);
        let curData = this.state.data;
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        })
    }

    recordNumDiscount=(e)=>{
        let dataKey = e.target.name;
        let dataVal = Utils.checkNumInputWithStr (e.target.value,"%",99);
        let curData = this.state.data;
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        })
        console.log(this.state.data)

    }
    setFileName=(key,e)=>{
        let fileObj = e.target.files[0];
        Utils.uploadFile(fileObj).then((result)=>{
            let curData = this.state.data;
            curData[key].name = fileObj.name;
            curData[key].file_obj = result;
            this.setState({
                data:curData
            });
            $("."+key).removeClass("hidden");
        });
    }
    fileDelete=(key,e)=>{
        this.refs[key].value="";
        let curData = this.state.data;
        curData[key].name="No file uploaded";
        curData[key].file_obj="";
        $("."+key).addClass("hidden");
        this.setState({
            data:curData
        })
    }
    autoFill2Decimals=(e)=>{
        let dataVal = e.target.value;
        let dataKey = e.target.name;
        let curData = this.state.data;
        if(dataKey=="conversion_discount"||dataKey=="interest_rate"){
            if(dataVal==""){
                curData[dataKey]="0.00";
            }else if(dataVal.indexOf(".")==0){
                curData[dataKey]=Utils.getNumWith2Decimal( "0"+dataVal);
            }else{
                curData[dataKey] = Utils.getNumWith2Decimal(dataVal);
            }
        }else{
            curData[dataKey] = dataVal
        }
        this.setState({
            data:curData
        })
    }

    checkStatus=()=>{
        let curData = this.state.data;
        for(let i = 0; i<this.state.cnTermsRequiredKey.length;i++){
            if(curData[this.state.cnTermsRequiredKey[i]]==""){
                $(".create_"+this.state.cnTermsRequiredKey[i]).addClass("has-error")
            }else{
                $(".create_"+this.state.cnTermsRequiredKey[i]).removeClass("has-error")
            }
        }
        this.setState({
            isShowEmptyError:1
        })
    }
    resetCreateData=()=>{
        for(let i = 0; i<this.state.cnTermsRequiredKey.length;i++){
            $(".create_"+this.state.cnTermsRequiredKey[i]).removeClass("has-error")
        }
        this.setState({
            isShowEmptyError:0
        })
    }
    showData=()=>{
        // console.log("sssss")

    }

    handleCnTermDateChange = (key, moment_key, date) => {
        const { dateFormat } = this.state;
        let curData = this.state.data;
        curData[key] = date.format( dateFormat);
        curData[moment_key] = date;
        this.setState({
            data:curData
        });
    }


    render(){
        let curConvertibleType = this.state.data.convertible_type;
        let radioArr = ["checked","",""];
        let curTextStaus = 1;
        if(this.state.data.prefix.length === 0 || this.state.data.terms_name.length ===0){
            curTextStaus = 0;
        }
        //判断是否显示按钮：using existing terms
        let isExistingTerms = 'no';
        if(this.state.existingCnCount > 0){
            isExistingTerms = "yes";
        }

        let isLegalPrefix = 1; //默认为合法的输入
        for(let i=0;i<this.state.existingPrefix.length;i++){
            if(this.state.data.prefix == this.state.existingPrefix[i]){
                isLegalPrefix = 0;  //有重复改为非法
            }
        }
        let isLegalPlanName = 1; //默认为合法的输入
        for(let i=0;i<this.state.existingPlanName.length;i++){
            if(this.state.data.terms_name == this.state.existingPlanName[i]){
                isLegalPlanName = 0;  //有重复改为非法
            }
        }
        const { data, dateFormat } = this.state;
        return(
            <div className="modal fade" id="createCnTermsModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="modal-dialog dialog-top40">
                    <div className="modal-content conver-content">
                        <div className="modal-header box-header">
                            <button type="button" className="close shut-btn" onClick={this.resetCreateData} data-dismiss="modal" aria-hidden="true">×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Convertible terms creation')}</h4>
                        </div>
                        <div className="modal-body equity-body">
                            <div>
                                <div className="equity-item-header bg-seablue">
                                    <h5>{__('Basic terms information')}</h5>
                                </div>
                                <form className="form-horizontal equity-item-form">
                                    <div className="form-group">
                                        <label className="col-xs-5 add-label">{__('Convertible type ')}</label>
                                        <div className="col-xs-7 no-paddingright">
                                            <div className="checkbox send-check text-muted">
                                                <input checked={this.state.checkedArr.safe} onChange={this.setConvertibleType.bind(this,"safe")} value="safe" type="radio" name="convertibleNote" className="send-box" id="send-box-c"/>
                                                <label className="send-div" htmlFor="send-box-c"></label><span className="send-label"> &nbsp;&nbsp;{__('SAFE/KISS')}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-xs-7 col-xs-push-5 no-paddingright">
                                            <div className="checkbox send-check text-muted">
                                                <input checked={this.state.checkedArr.convertible_debt} onChange={this.setConvertibleType.bind(this,"convertible_debt")} type="radio" value="convertible_debt" name="convertibleNote" className="send-box" id="send-box-a"/>
                                                <label className="send-div" htmlFor="send-box-a"></label><span className="send-label"> &nbsp;&nbsp;{__('Convertible debt')}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-xs-7 col-xs-push-5 no-paddingright">
                                            <div className="checkbox send-check text-muted">
                                                <input checked={this.state.checkedArr.convertible_security} onChange={this.setConvertibleType.bind(this,"convertible_security")} value="convertible_security" type="radio" name="convertibleNote" className="send-box" id="send-box-b" />
                                                <label className="send-div" htmlFor="send-box-b"></label><span className="send-label"> &nbsp;&nbsp;{__('Convertible security')}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group ">
                                        <label className="col-xs-5 add-label">{__('Terms name')}</label>
                                        <div className="col-xs-7 create_terms_name">
                                            <input onChange={this.recordData} value={this.state.data.terms_name} name="terms_name" className="form-control" type="text" placeholder="e.g. SAFE $5M valuation cap" />
                                        </div>
                                    </div>
                                    <div className="form-group ">
                                        <label className="col-xs-5 add-label convertible-tipbox">
                                            {__('Prefix')}
                                            <a className="convertible-ques">
                                                <p className="convertible-tiptext tiptext-top86">{__('Convertible block prefixes can be reused for organizational purposes.')}</p>
                                            </a>
                                        </label>
                                        <div className="col-xs-4 create_prefix">
                                            <input onChange={this.recordData} value={this.state.data.prefix} name="prefix" className="form-control uppercase_form" type="text" placeholder="CN" />
                                        </div>
                                    </div>
                                    <div className="form-group ">
                                        <div className="col-xs-5">
                                            <label className=" add-label ">{__('Issue date ')}<br /><i className="text-muted">- {__('Optional')}</i></label>
                                        </div>
                                        <div className="col-xs-4">
                                            <DateSelector
                                                onChange={this.handleCnTermDateChange.bind(this, 'issue_date', 'issue_date_moment')}
                                                value={I(data['issue_date_moment'], I(data['issue_date'], '') ? moment(data['issue_date'], dateFormat) : '')}
                                                inputReadOnly={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group ">
                                        <div className="col-xs-5">
                                            <label className=" add-label ">{__('Maturity date ')}<br /><i className="text-muted">- {__('Optional')}</i></label>
                                        </div>
                                        <div className="col-xs-4">
                                            <DateSelector
                                                onChange={this.handleCnTermDateChange.bind(this, 'maturity_date', 'maturity_date_moment')}
                                                value={I(data['maturity_date_moment'], I(data['maturity_date'], '') ? moment(data['maturity_date'], dateFormat) : '')}
                                                inputReadOnly={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group ">
                                        <div className="col-xs-5">
                                            <label className=" add-label ">{__('Board approval date ')}<br /><i className="text-muted">- {__('Optional')}</i></label>
                                        </div>
                                        <div className="col-xs-4">
                                            <DateSelector
                                                onChange={this.handleCnTermDateChange.bind(this, 'board_approval_date', 'board_approval_date_moment')}
                                                value={I(data['board_approval_date_moment'], I(data['board_approval_date'], '') ? moment(data['board_approval_date'], dateFormat) : '')}
                                                inputReadOnly={false}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div>
                                <div className="equity-item-header bg-seablue">
                                    <h5>{__('Enter terms')}</h5>
                                </div>
                                <form className="form-horizontal equity-item-form">
                                    <div className="form-group ">
                                        <div className="col-xs-5">
                                            <label className=" add-label">{__('Valuation cap ')}<i className="text-muted">- {__('Optional')}</i></label>
                                        </div>
                                        <div className="col-xs-4">
                                            <input onChange={this.recordNumCurrency} value={this.state.data.valuation_cap} name="valuation_cap" className="form-control" type="text" placeholder="e.g. $4,000,000" />
                                        </div>
                                    </div>
                                    <div className="form-group ">
                                        <div className="col-xs-5">
                                            <label className=" add-label">{__('Conversion discount ')}<br /><i className="text-muted">- {__('Optional')}</i></label>
                                        </div>
                                        <div className="col-xs-4">
                                            <div className="input-group">
                                                <input name="conversion_discount"  onBlur={this.autoFill2Decimals} onInput={this.recordNumData} value={this.state.data.conversion_discount} className="form-control" type="text" placeholder="e.g. 20" />
                                                <span className="input-group-addon" id="convertible-addon">%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-xs-5 add-label convertible-tipbox">{__('Interest rate ')}<i className="text-muted">- {__('Optional')}</i>
                                            <a className="convertible-ques">
                                                <p className="convertible-tiptext tiptext-top50">{__('Annual Percentage Rate.')}</p>
                                            </a>
                                        </label>
                                        <div className="col-xs-4">
                                            <div className="input-group">
                                                <input onInput={this.recordNumData}  onBlur={this.autoFill2Decimals} value={this.state.data.interest_rate} name="interest_rate" className="form-control" type="text" placeholder="e.g. 10" />
                                                <span className="input-group-addon" id="convertible-addon">%</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rate-dropdown hidden">
                                        <div className="form-group">
                                            <label className="col-xs-6 add-label convertible-tipbox">{__('Interest accrual end date')}<br /><i className="text-muted">- {__('Optional')}</i>
                                                <a className="convertible-ques">
                                                    <p className="convertible-tiptext tiptext-top86">{__('Total interest will be calculated up to this date instead of maturity date.')}</p>
                                                </a>
                                            </label>
                                            <div className="col-xs-6">
                                                {/*<input value={this.state.data.interest_accrual_end_date} name="interest_accrual_end_date" placeholder="DD/MM/YYYY" onChange={this.recordData} className="form-control convertible_note_datepicker calender-positionReset date-calendar-with10padding datepicker" type="text" />*/}
                                                <DateSelector
                                                    onChange={this.handleCnTermDateChange.bind(this, 'interest_accrual_end_date', 'interest_accrual_end_date_moment')}
                                                    value={I(data['interest_accrual_end_date_moment'], I(data['interest_accrual_end_date'], '') ? moment(data['interest_accrual_end_date'], dateFormat) : '')}
                                                    inputReadOnly={false}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group ">
                                            <label className="col-xs-6 add-label convertible-tipbox">{__('Day count convention')}<br /><i className="text-muted">- {__('Optional')}</i>
                                                <a className="convertible-ques">
                                                    <p className="convertible-tiptext tiptext-top86">{__('Total interest will be calculated starting from this date instead of the issue date.')}</p>
                                                </a>
                                            </label>
                                            <div className="col-xs-6">
                                                <select onChange={this.recordData} value={this.state.data.day_count_convention} name="day_count_convention" className="form-control select-container select-bgwhite">
                                                    <option value=""></option>
                                                    <option>30/360</option>
                                                    <option>Actual/365</option>
                                                    <option>Actual/360</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-xs-6 add-label convertible-tipbox">{__('Interest accrual period')}<br /><i className="text-muted">- {__('Optional')}</i></label>
                                            <div className="col-xs-6">
                                                <select onChange={this.recordData} value={this.state.data.interest_accrual_period} name="interest_accrual_period" className="form-control select-container select-bgwhite">
                                                    <option value=""></option>
                                                    {this.state.accrualPeriodList.map((value,key)=>{
                                                        return(
                                                            <option key={key} value={value}>{value}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-xs-6 add-label convertible-tipbox">{__('Interest start date')}<br /><i className="text-muted">- {__('Optional')}</i>
                                                <a className="convertible-ques">
                                                    <p className="convertible-tiptext tiptext-top86">{__('Total interest will be calculated starting from this date instead of the issue date.')}</p>
                                                </a>
                                            </label>
                                            <div className="col-xs-6">
                                                <DateSelector
                                                    onChange={this.handleCnTermDateChange.bind(this, 'interest_start_date', 'interest_start_date_moment')}
                                                    value={I(data['interest_start_date_moment'], I(data['interest_start_date'], '') ? moment(data['interest_start_date'], dateFormat) : '')}
                                                    inputReadOnly={false}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-xs-6 add-label convertible-tipbox">{__('Interest compounding period')}<br /><i className="text-muted">- {__('Optional')}</i></label>
                                            <div className="col-xs-6">
                                                <select onChange={this.recordData} value={this.state.data.interest_compounding_period} name="interest_compounding_period" className="form-control select-container select-bgwhite">
                                                    <option value=""></option>
                                                    {this.state.compoundingPeriodList.map((value,key)=>{
                                                        return(
                                                            <option key={key} value={value}>{value}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-xs-6 add-label convertible-tipbox">{__('Interest payout ')}<i className="text-muted">- {__('Optional')}</i></label>
                                            <div className="col-xs-6">
                                                <select onChange={this.recordData} value={this.state.data.interest_payout} name="interest_payout" className="form-control select-container select-bgwhite">
                                                    <option value=""></option>
                                                    <option>Deferred</option>
                                                    <option>Cash</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>


                                    {this.state.data.interest_rate!="" && this.showData()}
                                    <div className={this.state.showStatusClass}>
                                        <div className="form-group">
                                            <div className="col-xs-5 convertible-tipbox">
                                                <label className=" add-label ">{__('Change in control percent ')}<br /><i className="text-muted">- {__('Optional')}</i>
                                                    <a className="convertible-ques">
                                                        <p className="convertible-tiptext tiptext-top124">{__("Use this field if there‘s any premium or multiplier applied to the principal in the event of a change in control prior to the maturity date.")}</p>
                                                    </a>
                                                </label>
                                            </div>
                                            <div className="col-xs-4">
                                                <div className="input-group">
                                                    <input onInput={this.recordNumData} value={this.state.data.change_in_control_percent} name="change_in_control_percent" className="form-control" type="text" placeholder="e.g. 25" />
                                                    <span className="input-group-addon" id="convertible-addon">%</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="col-xs-5 convertible-tipbox">
                                                <label className="add-label ">{__('Conversion trigger ')}<br /><i className="text-muted">- {__('Optional')}</i>
                                                    <a className="convertible-ques">
                                                        <p className="convertible-tiptext tiptext-top86">{__('The minimum dollar size of the next financing round required for the notes to convert.')}</p>
                                                    </a>
                                                </label>
                                            </div>
                                            <div className="col-xs-4">
                                                <input onChange={this.recordNumCurrency} value={this.state.data.conversion_trigger} name="conversion_trigger" className="form-control" type="text" placeholder="e.g. $1,000,000" />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-xs-5 add-label convertible-tipbox">
                                                {__('Day count basis')}
                                                <a className="convertible-ques">
                                                    <p className="convertible-tiptext tiptext-top86">{__('Choose the day count basis that applies to this convertible note.')}</p>
                                                </a>
                                            </label>
                                            <div className="col-xs-7">
                                                <select onChange={this.recordData} value={this.state.data.day_count_basis} name="day_count_basis" className="form-control select-container select-bgwhite">
                                                    <option value=""></option>
                                                    <option>30/360</option>
                                                    <option>Actual/365</option>
                                                    <option>Actual/360</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div>
                                <div className="equity-item-header bg-seablue">
                                    <h5>{__('Upload term documents')}<i className="optional-color optional-font">- {__('Optional')}</i></h5>
                                </div>
                                <form className="form-horizontal equity-item-form">
                                    <p className="text-muted file-seletext">{__('Upload default term documents. You can replace them when you issue convertibles.')}</p>
                                    <div className="form-group ">
                                        <label className="col-xs-5 add-label">{__('Note purchase agreement')}</label>
                                        <div className="col-xs-3">
                                            <div className="select-btn convertiblenote-select-btn conver-seldark equ-choose">
                                                {__('Choose a file')}
                                                <label htmlFor="files-select" className="sr-only" >File input</label>
                                                <input onChange={this.setFileName.bind(this,"note_purchase_agreement_file")} ref="note_purchase_agreement_file" type="file" />
                                            </div>
                                        </div>
                                        <div className="col-xs-4 amend-file-name no-paddingleft">
                                            <span className="close files-del hidden note_purchase_agreement_file" onClick={this.fileDelete.bind(this,'note_purchase_agreement_file')}>x</span>
                                            <p className="text-muted">{this.state.data.note_purchase_agreement_file.name}</p>
                                        </div>
                                    </div>
                                    <div className="form-group ">
                                        <label className="col-xs-5 add-label">{__('Form of convertible')}</label>
                                        <div className="col-xs-3 ">
                                            <div className=" select-btn convertiblenote-select-btn conver-seldark equ-choose">
                                                {__('Choose a file')}
                                                <label htmlFor="files-select" className="sr-only" >File input</label>
                                                <input onChange={this.setFileName.bind(this,"form_of_convertible")} ref="form_of_convertible" type="file" />
                                            </div>
                                        </div>
                                        <div className="col-xs-4 amend-file-name no-paddingleft">
                                            <span onClick={this.fileDelete.bind(this,'form_of_convertible')} className="close files-del hidden form_of_convertible">x</span>
                                            <p className="text-muted ">{this.state.data.form_of_convertible.name}</p>
                                        </div>
                                    </div>
                                    {this.state.isShowUploadProgress === 1 &&
                                    <div>
                                        <div className="progress file-progress lib-progress">
                                            <div className="progress-bar progress-bar-info progress-noshadow" role="progressbar" aria-valuenow={this.state.uploadProgress}
                                                 aria-valuemin="0" aria-valuemax="100" style={{width:this.state.uploadProgress+"%"}}>
                                                <span className="sr-only">{this.state.uploadProgress}% Complete</span>
                                            </div>
                                        </div>

                                        <div style={{textAlign:"center"}}>{this.state.uploadProgress}% {__('Complete')}</div>
                                    </div>
                                    }
                                </form>
                            </div>
                            {this.state.isShowEmptyError ===1 &&
                            <div className="alert alert-warning alert-dismissible pink-box eq-tipbox" role="alert">
                                <p>{__('Required field(s) can not be empty.')}</p>
                            </div>
                            }
                            {isLegalPrefix ===0 &&
                            <div className="stock-form" id="cn_prefix_exist_error">
                                <div className="alert alert-warning alert-dismissable pink-box" role="alert">
                                    <p>{__('Share class prefix already exists. Please select another.')}</p>
                                </div>
                            </div>
                            }
                            {isLegalPlanName ===0 &&
                            <div className="stock-form" id="cn_prefix_exist_error">
                                <div className="alert alert-warning alert-dismissable pink-box" role="alert">
                                    <p>{__('Terms name already exists. Please select another.')}</p>
                                </div>
                            </div>
                            }
                        </div>
                        {isExistingTerms !== "yes" &&
                        <div className="modal-footer box-footer paddingBottom20">
                            {curTextStaus ===1 &&<button id="createCnButton" onClick={this.createNote} type="button" className="btn boxbtn-to bg-darkblue update-btn">{this.state.sendingStatus==0 ? __("Create terms"):__("Creating")}</button>}
                            {curTextStaus ===0 &&<button type="button" onClick={this.checkStatus} className="btn boxbtn-to bg-darkblue update-btn">{__('Create terms')}</button>}
                        </div>
                        }
                        {isExistingTerms === "yes" &&
                        <div className="modal-footer box-footer paddingBottom20">
                            {curTextStaus ===1 &&<button id="createCnButton" onClick={this.createNote} type="button" className="btn boxbtn-to bg-darkblue width160">{this.state.sendingStatus==0 ? __("Create terms"):__("Creating")}</button>}
                            {curTextStaus ===0 &&<button type="button" onClick={this.checkStatus} className="btn boxbtn-to bg-darkblue width160">{__('Create terms')}</button>}
                        </div>
                        }
                    </div>
                </div>
            </div>
        )
    }

}

export default CreateCnTermsModal;

