import React, { Component, useState, useEffect }from 'react';
import { message, Table, Spin } from 'antd';
import { logColumns } from './columns';
import { post } from 'srcPath/http';

import './style.scss'


export default function AccessLogs (props) {
    const [ loading, setLoading ] = useState(false)
    const [ accessLogs, setAccessLogs] = useState([])

    useEffect(() => {
        getAccessLogs()
   }, [])

    const getAccessLogs = async () => {
        const { data = {} } = props
        const { id } = data || {}
        setLoading(true)
            try {
                const { data: resData } = await post('getDataRoomAccessLog')
                const { code, data } = resData || {}
                if (code === 0) {
                    setAccessLogs(data)
                }
            } catch(err) {
                message.error(err & err.message)
            }
            setLoading(false)
    }

    return (
        <div>
            <Spin spinning={loading}>
                <div className="finacing-history-found-table">
                    <Table
                        pagination={false}
                        columns={logColumns}
                        dataSource={accessLogs}
                        scroll={{ x: 'auto', y: 480 }}
                        locale={{emptyText: 'No Data'}}
                    />
                </div>
            </Spin>
        </div>
    )
}