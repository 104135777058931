import React, { Component } from 'react';
import { Select } from 'antd';
import { observer } from 'mobx-react';
import security from 'stores/security';

const Option = Select.Option

@observer
class TimeZone extends Component {
	render() {
		const { timezone_list = [] } = security.constant || {}

		return <Select showSearch {...this.props}>
		{timezone_list.map((val, index) => <Option key={index} value={val.server_value}>{val.client_text}</Option>)}
	</Select>

	}
}

export default TimeZone