import React, { Component, useState } from 'react';
import onboardingBg from './image/onboardingBg.png';
import homePic from './image/home_pic.png';
import InvitePic from './image/InvitePic.png';
import tooltipIcon from './image/tooltip.png';
import uploadIcon from './image/uploadIcon.png';
import reduceIcon from './image/reduce.png';
import fillPic from './image/fillPic_new.png';
import rolesPic from './image/rolesPic.png';
import signatoriesPic from './image/signatoriesPic.png';
import addressPic from './image/addressPic.png';
import uploadPic from './image/uploadPic.png';
import lastPic from './image/lastPic.png';
import addIcon from './image/add.png';
import fileIcon from './image/file-icon.png'
import addInviteIcon from './image/invite.png'
import { FolderOutlined } from '@ant-design/icons';
import connectTokens from './image/connect-tokens.png';
import CountrySelector from 'components/CountrySelector';
import CurrencySelector from 'components/CurrencySelector';
import TimeZone from 'components/TimeZone';
import DateFormatSelector from 'components/DateFormatSelector';
import DateSelector from 'components/DateSelector';
import Loading from 'components/Loading';
import { post } from 'srcPath/http';
import classnames from 'classnames';
import Utils from "Utils";
import './style.scss';
import { printf } from 'Utils/i18n';

import { Spin, Form, Input, Select, message, DatePicker, Tooltip, Radio, Upload, Button, Alert } from 'antd';
const { Option } = Select;
const { Dragger } = Upload;

const layout = {
	labelCol: { span: 6 },
};

const fillOutLayout = {
	labelCol: { span: 8 },
};

const emailReg = new RegExp('(?![\\-_.])[\\w\\-.]+@(?:(?![\\-_])[\\w\\-\u4e00-\u9fa5]{1,63}\\.)+[A-Za-z0-9]{2,}')
class onboardingModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			onboarding_type: '1',// 1:5 2:3 3:6
			lastScreen: false,
			current_step: 0, //第几步
			loading: false,
			firstScreen: true,
			inviteFormErr: '',
			inviteForm: {
				name: '',
				invite_email: '',
				titles: '',
				permissions: '1'
			},
			fillOutForm: {
				company_name: '',
				date_of_lncorporation: '',
				country_of_incorporation: '',
				currency: '',
				timezone: '',
				date_format: '',
			},
			setRolesForm: {
				stakeholder_relations_id: '',
				primary_contact_id: '',
			},
			setSignatoriesForm: {
				radio: '2',
				certificate1_user_id: '',
				certificate2_user_id: '',
				equity_plan_awards: '',
				convertibles: '',
				warrants: ''
			},
			selectOptions: [],
			onboardingTypeList: [],
			signatoriesList: [],
			// tokenAddressList: [
			// 	{
			// 		id: 0,
			// 		val: ''
			// 	}
			// ]

			tokenAddressList: [''],
			uploadFiles: [],
			testFileList: [],

			tokkenAddressConfirm: false,
			UploadConfirm: false,

			//验证表单名

			FormRequired: false,
			emailErr: false,


			//几个用户
			InviteAdminNum: 0,

			token_num: 3
		}
	}
	inviteFormRef = React.createRef()
	fillOutFormRef = React.createRef()
	setRolesFormRef = React.createRef()
	setSignatoriesFormRef = React.createRef()
	componentDidMount() {
		this.getOnboardingStatus()
	}
	getOnboardingStatus = async () => {
		Loading.global(true)
		let onboardingTypeList = {
			1: ['Invite admin', 'Fill out company profile', 'Set roles', 'Set signatories', 'Upload documents'],
			2: ['Invite admin', 'Fill out company profile', 'Connect your tokens'],
			3: ['Invite admin', 'Fill out company profile', 'Set roles', 'Set signatories', 'Connect your tokens', 'Upload documents'],
		}
		try {
			const { data } = await post('v2/getOnboardingConfig')
			if (data.code === 0) {
				let { current_step, onboarding_type, company_info, dir_id, token_num } = data.data
				// let dir_id = data.data.dir_id
				// let company_info = {
				// 	company_name: data.data.company_info.company_name
				// }
				// let current_step =4
				// let onboarding_type = 3
				let fillOutForm = this.state.fillOutForm
				// let token_num = data.data.token_num
				fillOutForm.company_name = company_info.company_name || ''
				this.setState({
					fillOutForm,
					current_step: Number(current_step),
					onboarding_type,
					onboardingTypeList: onboardingTypeList[onboarding_type],
					dir_id,
					token_num
				})
				// 最后一个
				if (current_step >= onboardingTypeList[onboarding_type].length) {
					this.setState({
						firstScreen: false,
						lastScreen: true
					})
					// 第一个
				} else if (current_step == 0) {
					this.setState({
						firstScreen: true
					})
				} else {
					this.setState({
						firstScreen: false
					})
				}
				if (Number(current_step) == 1) {
					this.fillOutFormRef.current.setFieldsValue({
						company_name: fillOutForm.company_name
					})
				}
			} else {
				message.error(data.data)
			}
		} catch (e) {
			message.error(e.message)
		}
		try {
			const { data } = await post('v2/getInviteAdminNum')
			if (data.code === 0) {
				this.setState({
					InviteAdminNum: data.data
				})
			} else {
				message.error(data.data)
			}
		} catch (e) {
			message.error(e.message)
		}
		this.getuserList()
		Loading.global(false)
	}

	getuserList = async () => {
		Loading.global(true)
		try {
			const { data: resData } = await post('getUserList')

			const { data, code } = resData || {}

			if (code == 0) {
				const { company_editor = [], legal_admin = [] } = data || {}
				this.setState({
					selectOptions: [...company_editor, ...legal_admin],
					signatoriesList: legal_admin
				})
			} else {
				message.error(data)
			}
		} catch (err) {
			message.error(err && err.message)
		}

	}
	bindOnboardingStart = () => {
		this.setState({
			firstScreen: false
		})
	}

	inviteInput = (name, e) => {
		let inviteForm = this.state.inviteForm
		inviteForm[name] = e.target.value
		this.setState({
			inviteForm,
			FormRequired: false,
			emailErr: false
		}, () => { this.inviteFormRef.current.validateFields(); })
	}

	getInviteFormNum = async () => {
		let { inviteForm, current_step } = this.state
		this.setState({
			FormRequired: true,
			inviteFormErr: '',
		}, async () => {
			let value = await this.inviteFormRef.current.validateFields();
			if (!value) { return }
			if (!emailReg.test(inviteForm.invite_email)) {
				this.setState({
					emailErr: true
				})
				this.inviteFormRef.current.validateFields();
				return
			}
			let body = { ...inviteForm }
			Loading.global(true)
			try {
				const { data } = await post('inviteAdmin', body)
				if (data.code === 0) {
					// this.getuserList()
					message.success('Success')
					// this.stepSwiper()
					this.setState({
						InviteAdminNum: this.state.InviteAdminNum + 1
					})
				} else if (data.code === 3) {
					this.setState({ inviteFormErr: 'You cannot invite the same user more than one time.' })
				} else if (data.code === 2) {
					this.setState({ inviteFormErr: 'You cannot invite yourself as a second user. Please add another user.' })
				} else if (data.code === 6) {
					this.setState({ error: data })
				} else {
					message.error(data.data)
				}
			} catch (e) {
				message.error(e.message)
			}
			Loading.global(false)
		})
	}

	getInviteForm = async () => {
		let { inviteForm, fillOutForm, current_step, onboardingTypeList, setRolesForm, setSignatoriesForm, tokenAddressList, onboarding_type, uploadFiles, dir_id, InviteAdminNum } = this.state

		if (onboardingTypeList[current_step] === 'Invite admin') {
			if (InviteAdminNum == 0) {
				return
			}
			Loading.global(true)
			try {
				const { data } = await post('v2/finishInviteAdmin', { v: 'v2', step: current_step + 1 })
				if (data.code === 0) {
					this.getuserList()
					message.success('Success')
					this.stepSwiper()
				} else {
					message.error(data.data)
				}
			} catch (e) {
				message.error(e.message)
			}
			Loading.global(false)
		} else if (onboardingTypeList[current_step] === 'Fill out company profile') {
			this.setState({
				FormRequired: true
			}, async () => {
				let value = await this.fillOutFormRef.current.validateFields();
				this.setState({
					FormRequired: false
				})
				if (!value) { return }
				Loading.global(true)
				let body = { ...fillOutForm, step: current_step + 1 }
				try {
					const { data } = await post('v2/fillOutCompanyProfile', body)
					if (data.code === 0) {

						message.success('Success')
						this.stepSwiper()
					} else {
						message.error(data.data)
					}
				} catch (e) {
					message.error(e.message)
				}
				Loading.global(false)
			})

		} else if (onboardingTypeList[current_step] === 'Set roles') {
			this.setState({
				FormRequired: true
			}, async () => {
				let value = await this.setRolesFormRef.current.validateFields();
				if (!value) { return }
				Loading.global(true)
				let body = { ...setRolesForm, step: current_step + 1 }
				try {
					const { data } = await post('v2/setRoles', body)
					if (data.code === 0) {

						message.success('Success')
						this.stepSwiper()
					} else {
						message.error(data.data)
					}
				} catch (e) {
					message.error(e.message)
				}
				Loading.global(false)
			})
		} else if (onboardingTypeList[current_step] === 'Set signatories') {
			this.setState({
				FormRequired: true
			}, async () => {
				let value = await this.setSignatoriesFormRef.current.validateFields();
				if (!value) { return }
				Loading.global(true)
				let { certificate1_user_id, certificate2_user_id, equity_plan_awards, convertibles, warrants,radio } = setSignatoriesForm
				// let body = { ...setSignatoriesForm, step: current_step + 1 }
				let body = { certificate_type:radio,certificate1_user_id, certificate2_user_id, equity_plan_awards, convertibles, warrants, step: current_step + 1 }
				try {
					const { data } = await post('v2/setSignatories', body)
					if (data.code === 0) {
						message.success(data.data)
						this.stepSwiper()
					} else {
						message.error(data.data)
					}
				} catch (e) {
					message.error(e.message)
				}
				Loading.global(false)
			})
		} else if (onboardingTypeList[current_step] === 'Connect your tokens') {
			let token_address = this.state.tokenAddressList.filter(item => item && item.trim())
			if (token_address.length <= 0) {
				return
			}
			if (onboarding_type == 2) {
				if (token_address.length <= 0) {
					message.warning('Please connect at least one token')
					return
				}
				// onboarding完成
			}
			Loading.global(true)
			let body = { token_address: JSON.stringify(token_address), step: current_step + 1 }
			try {
				const { data } = await post('v2/connectToken', body)
				if (data.code === 0) {
					message.success(data.data)
					this.stepSwiper()
				} else {
					message.error(data.data)
				}
			} catch (e) {
				message.error(e.message)
			}


		} else if (onboardingTypeList[current_step] === 'Upload documents') {

			if (uploadFiles.length <= 0) {
				return
			}
			let dir_info = dir_id ? uploadFiles : [{ fileList: uploadFiles, name: 'Onboarding', fileType: 'dir', size: 0, file_obj: '' }]
			Loading.global(true)
			let body = { parent_id: dir_id ? dir_id : 0, dir_info, step: current_step + 1, v: 'v2' }
			try {
				const { data } = await post('uploadFile', body)
				if (data.code === 0) {
					message.success(data.data)
					this.stepSwiper()
				} else {
					message.error(data.data)
				}
			} catch (e) {
				message.error(e.message)
			}
		}

		Loading.global(false)

	}
	getSkipForm = async (name) => {
		let { current_step } = this.state
		if (name == 'connect') {
			Loading.global(true)
			let body = { token_address: '', step: current_step + 1 }
			try {
				const { data } = await post('v2/connectToken', body)
				if (data.code === 0) {
					message.success(data.data)
					this.stepSwiper()
				} else {
					message.error(data.data)
				}
			} catch (e) {
				message.error(e.message)
			}
		} else if (name == 'upload') {
			Loading.global(true)
			let body = { parent_id: 0, dir_info: [], step: current_step + 1, v: 'v2' }
			try {
				const { data } = await post('uploadFile', body)
				if (data.code === 0) {
					message.success(data.data)
					this.stepSwiper()
				} else {
					message.error(data.data)
				}
			} catch (e) {
				message.error(e.message)
			}
		}

		Loading.global(false)
	}

	stepSwiper = () => {
		let { current_step, onboardingTypeList } = this.state
		current_step = current_step + 1
		this.setState({
			current_step
		})
		if (Number(current_step) == 1) {
			this.fillOutFormRef.current.setFieldsValue({
				company_name: this.state.fillOutForm.company_name
			})
		}
		if (current_step >= onboardingTypeList.length) {
			this.setState({
				firstScreen: false,
				lastScreen: true
			})
			return
		}


	}

	handleBirthdayChange = (e, date) => {

		let fillOutForm = this.state.fillOutForm
		fillOutForm['date_of_lncorporation'] = date && date.format('DD/MM/YYYY')
		this.setState({
			fillOutForm,
			FormRequired: false
		})

	}

	getInputValue = (formDate, params, e) => {
		let stateForm = this.state[formDate];
		stateForm[params] = e.target.value
		this.setState({
			FormRequired: false,
			formDate: stateForm,
			emailErr: false
		})
	}

	getSelectValue = (formDate, params, date) => {
		let stateForm = this.state[formDate];
		stateForm[params] = date
		this.setState({
			formDate: stateForm,
			FormRequired: false
		})
	}
	getSelectCertificateValue = (params, val) => {
		let setSignatoriesForm = this.state.setSignatoriesForm;
		setSignatoriesForm.certificate1_user_id = val
		if (val == setSignatoriesForm.certificate2_user_id) {
			setSignatoriesForm.certificate2_user_id = ''
			this.setSignatoriesFormRef.current.setFieldsValue({
				certificate2_user_id: ''
			})
		}

		this.setState({
			setSignatoriesForm
		})
	}


	getRadioValue = (e) => {
		let setSignatoriesForm = this.state.setSignatoriesForm;
		setSignatoriesForm.radio = e.target.value
		setSignatoriesForm.certificate2_user_id = ''
		this.setState({
			setSignatoriesForm
		})
		this.setSignatoriesFormRef.current.setFieldsValue({
			certificate2_user_id: ''
		})

	}

	checkCertificate = (a, val, callback) => {
		let setSignatoriesForm = this.state.setSignatoriesForm;
		if (val == setSignatoriesForm.certificate1_user_id) {
			callback('Can not same with Certificate (1st)');
			setSignatoriesForm.certificate2_user_id = ''
			this.setSignatoriesFormRef.current.setFieldsValue({
				certificate2_user_id: ''
			})
		}

		callback();
	}

	bindAddTokenAddress = () => {
		let arrlist = this.state.tokenAddressList
		if (arrlist.length >= 3) {
			return
		}
		let tokenAddressList = [...arrlist, '']
		this.setState({
			tokenAddressList
		})
	}


	getTokenAddress = (idx, e) => {

		let tokenAddressList = this.state.tokenAddressList
		tokenAddressList[idx] = e.target.value
		this.setState({
			tokenAddressList
		})


		let token_address = tokenAddressList.filter(item => item && item.trim())
		if (token_address.length > 0) {
			this.setState({
				tokkenAddressConfirm: true
			})
		} else {
			this.setState({
				tokkenAddressConfirm: false
			})
		}
	}

	handleDropFile = (e) => {
		console.log('Dropped files', e.dataTransfer.files);
	}

	handleDropCLick = (event) => {
		event.preventDefault();
		event.stopPropagation();
	}

	handleBeforeUpload = (file, fileList, e) => {
		const isLt500M = file.size / 1024 / 1024 < 100;
		if (!isLt500M) {
			return message.error('Files larger than 100 MB are not currently supported.')
		}
		const fileArray = [...this.state.uploadFiles]
		fileList.map((i) => {
			Utils.uploadFile(i).then((result) => {
				fileArray.push({
					name: i.name,
					file_obj: result,
					size: i.size,
					fileType: 'file'
				})
				this.setState({
					uploadFiles: fileArray
				})
			});
		})
		return false
	}
	minus = (key) => {
		let curFiles = this.state.uploadFiles;
		curFiles.splice(key, 1);
		this.setState({
			uploadFiles: curFiles
		})
	}
	handleBeforeUploadFolders = (file, fileList) => {
		let testFileList = this.state.testFileList
		testFileList.push(file)
		if (testFileList.length == fileList.length) {
			let isLt100M = 0
			fileList.forEach(item => isLt100M += item.size)
			isLt100M = isLt100M / 1024 / 1024 < 100;
			if (!isLt100M) {
				message.error('Files larger than 100 MB are not currently supported.')
				return
			}
			this.getFileList(testFileList)
			this.state.testFileList = []
		}
	}
	getFileList = (fileList) => {
		let fileArray = []
		fileList.map((i) => {
			const { webkitRelativePath } = i;
			// 分解文件路径
			const dirList = webkitRelativePath?.split('/')
			let temporary = '.DS_Store'
			let iswenjian = dirList.findIndex(item => item == temporary)
			if (iswenjian === -1) {
				dirList.forEach((item, idx) => {
					// 最后一个是文件
					if (dirList.length - 1 === idx) {
						let parentName = ''
						let petName = webkitRelativePath
						dirList.forEach((item, i) => {
							parentName += (dirList.length - i) > 1 ? item + '/' : ''
						})
						Utils.uploadFile(i).then((result) => {
							fileArray.push({
								parentName,
								petName,
								name: item,
								file_obj: result,
								size: i.size,
								fileType: "file",
							})
						});
					} else {
						// 文件夹名
						if (idx === 0) {
							let isIdx = fileArray.findIndex(item => item.petName == item + '/')
							if (isIdx === -1) {
								fileArray.push({
									parentName: '',
									petName: item + '/',
									name: item,
									file_obj: '',
									size: i.size,
									fileType: "dir",
									fileList: []
								})
							}
						} else {
							let parentName = ''
							let petName = ''
							dirList.forEach((item, idxs) => {
								parentName += idxs < idx ? item + '/' : ''
								petName += idxs <= idx ? item + '/' : ''
							})
							let isIdx = fileArray.findIndex(item => item.petName == petName)
							if (isIdx == -1) {
								fileArray.push({
									parentName,
									petName,
									name: item,
									file_obj: '',
									size: i.size,
									fileType: "dir",
								})
							}
						}

					}
				})
			}
		})
		setTimeout(() => {
			this.setState({
				uploadFiles: [...this.state.uploadFiles, this.convert(fileArray)]
			})
		}, 1000);
	}

	convert(data) {
		let result;
		let map = {};
		data.forEach(item => {
			map[item.petName] = item;
		});
		data.forEach(item => {
			let parent = map[item.parentName];
			if (parent) {
				(parent.fileList || (parent.fileList = [])).push(item);
			} else {
				result = item;
			}
		});
		return result;
	}

	async getCloseForm() {
		Loading.global(true)
		try {
			const { data } = await post('v2/finishOnboarding')
			if (data.code === 0) {
				let { close, props } = this.props
				message.success(data.data)
				window.location.reload()
				close && close()
			} else {
				message.error(data.data)
			}
		} catch (e) {
			message.error(e.message)
		}
		Loading.global(false)
	}


	setStopPropagation = e => {
		//阻止冒泡 
		e.stopPropagation();
	};

	render() {
		let { firstScreen, lastScreen, inviteForm, inviteFormErr, emailErr, FormRequired, onboardingTypeList, onboarding_type, current_step, fillOutForm, selectOptions, setSignatoriesForm, signatoriesList, tokenAddressList, uploadFiles, tokkenAddressConfirm, UploadConfirm, InviteAdminNum, token_num } = this.state
		return <div className="onboarding-">
			<div className="onboarding-form">
				<img className="onboarding_bac" src={onboardingBg}></img>
				
				{firstScreen && <div className="form-item form-home">
					<div className="form-lft-info">
						<div className="lft-pic">
							<img className="icon" src={homePic}></img>
						</div>
						{/* <h1>Welcome onboard</h1> */}
					</div>
					<div className="form-rit-info">
						<h2>{__('Hi there and welcome to Folium!')}</h2>
						<h3>{__("Let's get you started.")}</h3>
					</div>
					<div className="confirm confirm-start" onClick={this.bindOnboardingStart.bind()} >{__('Start')}</div>
				</div>}

				{!firstScreen && !lastScreen && <div className="form-item form-swiper">
					{onboardingTypeList[current_step] === 'Invite admin' && <div className="form-item invite-admin">
						<div className="form-lft-info">
							<div className="title">{__('Invite')}</div>
							<div className="describe">{__('1 more super admin')}</div>
							<div className="lft-pic">
								<img className="icon" src={InvitePic}></img>
							</div>
						</div>
						<div className="form-rit-info">
							<div className="title">{__('Folium operates on a minimum two super admin system to ensure your company is transparently managing critical information with oversight. Super admins have maximum access and authority to your Folium account so please invite another person to join Folium.')}</div>
							<div className="fit-form">
								<div className="invite-admin-num">{printf(__('You have successfully invited %s user(s).'), InviteAdminNum)}</div>
								<Form {...layout} ref={this.inviteFormRef} name="control-hooks">
									<Form.Item name="name" label={__('Name')}
										colon={false}
										defaultValue={inviteForm.name}
										labelAlign='left'
										rules={[
											{ required: FormRequired, message: __('Name is required') }
										]}
									>
										<Input placeholder={__('John')} value={inviteForm.name} onChange={this.getInputValue.bind(this, 'inviteForm', 'name',)} />
									</Form.Item>
									<Form.Item name="email" label={__('Email address')}
										colon={false}
										defaultValue={inviteForm.invite_email}
										labelAlign='left'
										rules={
											[
												{
													required: FormRequired,
													message: __('Email address is required')
												},
												({ filedValue }) => ({
													validator(_, value) {
														return (!value || !emailErr) ? Promise.resolve() : Promise.reject(new Error(__('Email is invalid')));
													},
												})
											]
										}
									>
										<Input placeholder="john@example.com" value={inviteForm.invite_email} onChange={this.inviteInput.bind(this, 'invite_email')} />
									</Form.Item>
									<div className="title-set">
										<Form.Item name="titles" label={__('Title')}
											labelAlign='left'
											colon={false}
											defaultValue={inviteForm.titles}>
											<Input placeholder={__('Head of People')} value={inviteForm.titles} onChange={this.getInputValue.bind(this, 'inviteForm', 'titles')} />
										</Form.Item>
										<div className="option">-{__('Optional')}</div>
									</div>
								</Form>
								<div className="invite-btn" onClick={this.getInviteFormNum.bind(this)}>
									<img src={addInviteIcon}></img>
									{__('Invite')}
								</div>

								{inviteFormErr && <Alert style={{ marginTop: 20 }} message={inviteFormErr} type="error" showIcon />}
							</div>
						</div>
						<div className={classnames('confirm confirm-btn', { 'no-bind': InviteAdminNum == 0 })} onClick={this.getInviteForm.bind(this)}>{__('Confirm and continue')}</div>
					</div>}
					{onboardingTypeList[current_step] === 'Fill out company profile' && <div className="form-item fill-out">
						<div className="form-lft-info">
							<div className="title">{__('Fill out')}</div>
							<div className="describe">{__('your company profile')}</div>
							<div className="lft-pic">
								<img className="icon" src={fillPic}></img>
							</div>
						</div>
						<div className="form-rit-info">
							<div className="title">{__('Tell us more about your company to customize your experience on the platform.')}</div>
							<div className="fit-form">

								<Form {...fillOutLayout} ref={this.fillOutFormRef} name="control-hooks">
									<Form.Item name="company_name" label={__('Company name')}
										colon={false}
										labelAlign='left'
										rules={[
											{
												required: FormRequired,
												message: __('Company name is required'),
											},
										]}>
										<Input placeholder="" value={fillOutForm.company_name} onChange={this.getInputValue.bind(this, 'fillOutForm', 'company_name')} />
									</Form.Item>
									<Form.Item name="date_of_lncorporation" label={__('Date of incorporation')}
										colon={false}
										labelAlign='left'
										rules={[
											{
												required: FormRequired,
												message: __('Date is required'),
											},
										]}>
										<DateSelector
											className=""
											onChange={this.handleBirthdayChange.bind(this, 'date_of_lncorporation')}
											inputReadOnly={false}
											value={fillOutForm.date_of_lncorporation}
										/>
									</Form.Item>

									<Form.Item name="country_of_incorporation" label={__('Country of incorporation')}
										colon={false}
										labelAlign='left'
										rules={[
											{
												required: FormRequired,
												message: __('Country of incorporation is required'),
											},
										]}>
										<CountrySelector onChange={this.getSelectValue.bind(this, 'fillOutForm', 'country_of_incorporation')} />
									</Form.Item>
									<Form.Item name="currency" label={__('Currency')}
										colon={false}
										labelAlign='left'
										rules={[
											{
												required: FormRequired,
												message: __('Currency is required'),
											},
										]}>
										<CurrencySelector onChange={this.getSelectValue.bind(this, 'fillOutForm', 'currency')} />
									</Form.Item>
									<Form.Item name="timezone" label={__('Time zone')}
										colon={false}
										labelAlign='left'
										rules={[
											{
												required: FormRequired,
												message: __('Time zone is required'),
											},
										]}>
										<TimeZone placeholder="" onChange={this.getSelectValue.bind(this, 'fillOutForm', 'timezone')} />
									</Form.Item>
									<Form.Item name="date_format" label={__('Date format')}
										colon={false}
										labelAlign='left'
										rules={[
											{
												required: FormRequired,
												message: __('Date format is required'),
											},
										]}>
										<DateFormatSelector onChange={this.getSelectValue.bind(this, 'fillOutForm', 'date_format')} />
									</Form.Item>
								</Form>
							</div>
						</div>
						<div className="confirm confirm-btn" onClick={this.getInviteForm.bind(this)}>{__('Confirm and continue')}</div>
					</div>}
					{onboardingTypeList[current_step] === 'Set roles' && <div className="form-item set-roles">
						<div className="form-lft-info">
							<div className="title">{__('Set')}</div>
							<div className="describe">{__('company roles')}</div>
							<div className="lft-pic">
								<img className="icon" src={rolesPic}></img>
							</div>
						</div>
						<div className="form-rit-info">
							<div className="title">{__('These roles assign responsibility for specific actions within Folium to designated individuals.')}</div>
							<div className="fit-form">
								<Form {...fillOutLayout} ref={this.setRolesFormRef} name="control-hooks">
									<div className="tooltip-rela">
										<Form.Item name="primary_contact_id" label={__('Primary contact')}
											colon={false}
											labelAlign='left'
											rules={[
												{
													required: FormRequired,
													message: __('Primary contact is required'),
												},
											]}>
											<Select onChange={this.getSelectValue.bind(this, 'setRolesForm', 'primary_contact_id')}>
												{selectOptions.map((val, index) => <Option key={index} value={val.user_id}>{val.name}, {val.titles ? val.titles : '--'}</Option>)}
											</Select>
										</Form.Item>
										<Tooltip title={__('This is the contact person whom Folium will contact for account related questions and communications.')} className="tooltip-abs primary-contact-tooltip">
											<img src={tooltipIcon} className="tooltipIcon"></img>
										</Tooltip>
									</div>
									<div className="tooltip-rela">
										<Form.Item name="stakeholder_relations_id" label={__('Stakeholder relations')}
											colon={false}
											labelAlign='left'
											rules={[
												{
													required: FormRequired,
													message: __('Stakeholder relations is required'),
												},
											]}>
											<Select onChange={this.getSelectValue.bind(this, 'setRolesForm', 'stakeholder_relations_id')}>
												{selectOptions.map((val, index) => <Option key={index} value={val.user_id}>{val.name}, {val.titles ? val.titles : '--'}</Option>)}
											</Select>
										</Form.Item>
										<Tooltip title={__('Recipient of questions and requests from stakeholders to view ownership information, closing documents or financials.')} className="tooltip-abs stakeholder-relations-tooltip">
											<img src={tooltipIcon} className="tooltipIcon"></img>
										</Tooltip>
									</div>
								</Form>
							</div>
						</div>
						<div className="confirm confirm-btn" onClick={this.getInviteForm.bind(this)}>{__('Confirm and continue')}</div>
					</div>}
					{onboardingTypeList[current_step] === 'Set signatories' && <div className="form-item set-signatories">
						<div className="form-lft-info">
							<div className="title">{__('Set')}</div>
							<div className="describe">{__('signatories')}</div>
							<div className="lft-pic">
								<img className="icon" src={signatoriesPic}></img>
							</div>
						</div>
						<div className="form-rit-info">
							<div className="title">{__('Signatories will be required to sign securities upon issuance. Signatories privileges must be reassigned before a person can be deleted.')}</div>
							<div className="fit-form">
								<Form {...fillOutLayout} ref={this.setSignatoriesFormRef} name="control-hooks">
									<div className="tooltip-rela">
										<Form.Item name="radio" label={__('I need')}
											colon={false}
											labelAlign='left'>
											<div className='form-radio'>
												<Radio.Group onChange={this.getRadioValue.bind(this)} defaultValue='2' value={setSignatoriesForm.radio}>
													<Radio value='2'>{__('Two signatories')}</Radio>
													<Radio value='1'>{__('Only one signatory')}</Radio>
												</Radio.Group>
											</div>
										</Form.Item>
										<Tooltip title={__('Please contact us if you need to change the number of signatories required.')} className="tooltip-abs radio-tooltip">
											<img src={tooltipIcon} className="tooltipIcon"></img>
										</Tooltip>
									</div>
									{setSignatoriesForm.radio == 1 &&
										<div className="tooltip-rela">
											<Form.Item name="certificate1_user_id" label={__('Certificate')}
												colon={false}
												labelAlign='left'
												rules={[
													{
														required: FormRequired,
														message: __("Certificate is required"),
													},
												]}>
												<Select onChange={this.getSelectValue.bind(this, 'setSignatoriesForm', 'certificate1_user_id')}>
													{selectOptions.map((val, index) => <Option key={index} value={val.user_id}>{val.name}, {val.titles ? val.titles : '--'}</Option>)}
												</Select>
											</Form.Item>
											{/* <Tooltip title="" className="tooltip-abs certificate_user_id">
												<img src={tooltipIcon} className="tooltipIcon"></img>
											</Tooltip> */}
										</div>}
									{setSignatoriesForm.radio == 2 &&
										<div className="tooltip-rela">
											<Form.Item name="certificate1_user_id" label={__('Certificate (1st)')}
												colon={false}
												labelAlign='left'
												rules={[
													{
														required: FormRequired,
														message: __("Certificate (1st) is required"),
													},
												]}>
												<Select onChange={this.getSelectCertificateValue.bind(this, 'certificate1_user_id')}>
													{selectOptions.map((val, index) => <Option key={index} value={val.user_id}>{val.name}, {val.titles ? val.titles : '--'}</Option>)}
												</Select>
											</Form.Item>
											{/* <Tooltip title="" className="tooltip-abs certificate1_user_id">
												<img src={tooltipIcon} className="tooltipIcon"></img>
											</Tooltip> */}
										</div>}
									{setSignatoriesForm.radio == 2 &&
										<div className="tooltip-rela">
											<Form.Item name="certificate2_user_id" label={__('Certificate (2nd)')}
												colon={false}
												labelAlign='left'
												rules={[
													{
														required: FormRequired,
														message: __("Certificate (2nd) is required"),

													}, { validator: (rule, value, callback) => this.checkCertificate(rule, value, callback) },
												]}>
												<Select onChange={this.getSelectValue.bind(this, 'setSignatoriesForm', 'certificate2_user_id')}>
													{selectOptions.map((val, index) => <Option key={index} value={val.user_id}>{val.name}, {val.titles ? val.titles : '--'}</Option>)}
												</Select>
											</Form.Item>
											{/* <Tooltip title="" className="tooltip-abs primary-contact-tooltip">
												<img src={tooltipIcon} className="tooltipIcon"></img>
											</Tooltip> */}
										</div>}
									<div className="tooltip-rela">
										<Form.Item name="equity_plan_awards" label={__('Equity awards')}
											colon={false}
											labelAlign='left'
											rules={[
												{
													required: FormRequired,
													message: __("Equity awards is required"),
												},
											]}>
											<Select onChange={this.getSelectValue.bind(this, 'setSignatoriesForm', 'equity_plan_awards')}>
												{selectOptions.map((val, index) => <Option key={index} value={val.user_id}>{val.name}, {val.titles ? val.titles : '--'}</Option>)}
											</Select>
										</Form.Item>
										{/* <Tooltip title="" className="tooltip-abs equity_plan_awards">
											<img src={tooltipIcon} className="tooltipIcon"></img>
										</Tooltip> */}
									</div>
									<div className="tooltip-rela">
										<Form.Item name="convertibles" label={__('Convertibles')}
											colon={false}
											labelAlign='left'
											rules={[
												{
													required: FormRequired,
													message: __("Convertibles is required"),
												},
											]}>
											<Select onChange={this.getSelectValue.bind(this, 'setSignatoriesForm', 'convertibles')}>
												{selectOptions.map((val, index) => <Option key={index} value={val.user_id}>{val.name}, {val.titles ? val.titles : '--'}</Option>)}
											</Select>
										</Form.Item>
										{/* <Tooltip title="" className="tooltip-abs convertibles">
											<img src={tooltipIcon} className="tooltipIcon"></img>
										</Tooltip> */}
									</div>
									<div className="tooltip-rela">
										<Form.Item name="warrants" label={__('Warrants')}
											colon={false}
											labelAlign='left'
											rules={[
												{
													required: FormRequired,
													message: __("Warrants is required"),
												},
											]}>
											<Select onChange={this.getSelectValue.bind(this, 'setSignatoriesForm', 'warrants')}>
												{selectOptions.map((val, index) => <Option key={index} value={val.user_id}>{val.name}, {val.titles ? val.titles : '--'}</Option>)}
											</Select>
										</Form.Item>
										{/* <Tooltip title="" className="tooltip-abs warrants">
											<img src={tooltipIcon} className="tooltipIcon"></img>
										</Tooltip> */}
									</div>
								</Form>
							</div>
						</div>
						<div className="confirm confirm-btn" onClick={this.getInviteForm.bind(this)}>{__('Confirm and continue')}</div>
					</div>}


					{onboardingTypeList[current_step] === 'Connect your tokens' && <div className="form-item connect">
						<div className="form-lft-info">
							<div className="title">{__('Connect')}</div>
							<div className="describe">{__('your tokens')}</div>
							<div className="lft-pic">
								<img className="icon" src={addressPic}></img>
							</div>
						</div>
						<div className="form-rit-info">
							<div className="title">{__('Connect at least one token to start tracking it immediately.')}</div>
							<div className="fit-form">
								{tokenAddressList.map((item, i) => {
									return <Input value={item} className="token-address-input" onChange={this.getTokenAddress.bind(this, i)}></Input>
								})}
								{tokenAddressList.length < token_num && <img src={addIcon} className="add" onClick={this.bindAddTokenAddress.bind(this)}></img>}
							</div>

							<div className="copy-token">{__('Tooltip: Copy your Token contract')}</div>
							<img src={connectTokens}></img>
						</div>
						<div className={classnames('confirm confirm-btn', { 'no-bind': !tokkenAddressConfirm })} onClick={this.getInviteForm.bind(this)}>{__('Confirm and continue')}</div>

						{onboarding_type == 3 && <div className="skip-continue" onClick={this.getSkipForm.bind(this, 'connect')}>{__('Skip and continue')}</div>}
					</div>}
					{onboardingTypeList[current_step] === 'Upload documents' && <div className="form-item upload-documents">
						<div className="form-lft-info">
							<div className="title">{__('Upload')}</div>
							<div className="describe">{__('documents')}</div>
							<div className="lft-pic">
								<img className="icon" src={uploadPic}></img>
							</div>
						</div>
						<div className="form-rit-info">
							{/* <Tooltip title="Please be aware that Data Room is a premium feature, access to Data Room will be blocked without purchase.">
								<img src={tooltipIcon} className="tooltipIcon"></img>
							</Tooltip> */}
							<div className="title">{__('This is the Data Room where you will upload and manage all your company stakeholders and company documents.')}</div>
							<div className="fit-form ant-upload-drag-main">
								<Dragger className={classnames('file-upload-drapper', { 'file-upload-drapper-active': !!uploadFiles.length })} ondragover={this.dragOver} onDrop={this.handleDropFile} multiple={true} beforeUpload={this.handleBeforeUpload.bind(this)} showUploadList={false} onClick={this.handleDropCLick}>
									<p className="ant-upload-text">{__('Drop documents here')}</p>
									<img src={uploadIcon} />
									<div className="dragger-btn" onClick={this.setStopPropagation.bind(this)}>
										<Upload action="" multiple={true} beforeUpload={this.handleBeforeUpload.bind(this)} showUploadList={false}>
											<Button type="primary" className="btnWid">{__('Browser files')}</Button>
										</Upload>
										<Upload directory action="" multiple={true} beforeUpload={this.handleBeforeUploadFolders} showUploadList={false}>
											<Button type="primary" className="folders-btn btnWid"  >{__('Browser folders')}</Button>
										</Upload>
									</div>
								</Dragger>

								<div className="upload-file-list">
									{uploadFiles.map((i, index) => (
										<p className="file-upload-list">
											<Tooltip title={i.name} color="#2b3946">
												<span className="file-upload-name-area">{i.fileType == 'file' ? <img style={{ width: '15px', height: '20px', marginRight: '5px' }} src={fileIcon} /> : <FolderOutlined style={{ width: '15px', height: '20px', marginRight: '5px' }} />}{i.name}</span>
											</Tooltip>
											<img src={reduceIcon} className="reduce" onClick={this.minus.bind(this, index)}></img>
										</p>
									))}
								</div>
							</div>

						</div>
						<div className={classnames('confirm confirm-btn', { 'no-bind': uploadFiles.length <= 0 })} onClick={this.getInviteForm.bind(this)}>{__('Upload and complete')}</div>
						<div className="skip-continue" onClick={this.getSkipForm.bind(this, 'upload')}>{__('Skip and complete')}</div>
						{/* {onboarding_type == 3 && } */}
					</div>}
					<div className="swiper-pagination">
						{onboardingTypeList && onboardingTypeList.map((item, i) => {
							return <div className={classnames('swiper-pagination-bullet', { 'swiper-pagination-bullet-active': i == current_step })}></div>
						})}
					</div>
				</div>}

				{lastScreen && <div className="form-item form-swiper">
					<div className="form-item last-form">
						<div className="form-lft-info">
							<div className="title">{__('Completed!')}</div>
							<div className="lft-pic">
								<img className="icon" src={lastPic}></img>
							</div>
						</div>
						<div className="form-rit-info">
							{onboarding_type == 1 && <div className="title">
								<div>{printf(__('Congratulations! You have successfully setup %s.'), fillOutForm.company_name)}</div>
								<div>{__("You are now ready to use Folium to effectively manage your company's equity.")}</div>
								<div>{__('If you ever have questions, please contact us at support@getsprout.co or the help feature here.')}</div>
							</div>}
							{onboarding_type == 2 && <div className="title">
								<div>{printf(__('Congratulations! You have successfully setup %s.'), fillOutForm.company_name)}</div>
								<div>You are now ready to use Folium to effectively manage your company's Token. </div>
								<div>{__('If you ever have questions, please contact us at support@getsprout.co or the help feature here.')}</div>
							</div>}
							{onboarding_type == 3 && <div className="title">
								<div>{printf(__('Congratulations! You have successfully setup %s.'), fillOutForm.company_name)}</div>
								<div>{__("You are now ready to use Folium to effectively manage your company's equity and Token.")}</div>
								<div>{__('If you ever have questions, please contact us at support@getsprout.co or the help feature here.')}</div>
							</div>}
						</div>
						<div className="confirm confirm-btn" onClick={this.getCloseForm.bind(this)}>{__('OK')}</div>
					</div>

				</div>}
			</div>
		</div>
	}
}

export default onboardingModal
