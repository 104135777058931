import React, { Component } from 'react';
import SimpleTable from '../components/SimpleTable';
import { formatDate } from 'Utils/format/formatDate';
import { formatCurrencyNumber } from 'Utils';
import formatFractionalNumber from "../../../modules/Utils/format/formatFractionalNumber";

export default function RepurchaseDetails(props) {

	const { repurchase_date, is_return_to_pool, is_represent_a_sale, transaction_value, repurchase_agreement } = props.data || {};

	const config = [
		{
			label: __('Repurchase date'),
			value: repurchase_date && formatDate(repurchase_date)
		},
		{
			label: __('Return shares to pool'),
			value: is_return_to_pool == "" ? "--" : (is_return_to_pool == 0 ? 'No' : 'Yes')
		},
		{
			label: __('This repurchase represent a sale'),
			value: is_represent_a_sale == "" ? "--" : (is_represent_a_sale == 0 ? 'No' : 'Yes')
		},
		{
			label: __('Transaction value'),
			value: transaction_value ? formatCurrencyNumber(transaction_value) : '--',
		},
		{
			label: __('Repurchase agreement'),
			value: repurchase_agreement.name && repurchase_agreement.name,
			url: repurchase_agreement.file_url,
		}
	];

	return <div className="certificate-detail-section exercise-details-section">
			{/*return 	<SimpleTable key={key} title={"Repurchase details for "+val.target_security+", "+formatFractionalNumber(val.quantity) +" shares"} config={config} />*/}
				<SimpleTable title={"Repurchase details"} config={config} />
	</div>
}