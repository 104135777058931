import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Header from 'components/Layout/Header';
import NavMenu from 'components/Layout/NavMenu';
import PersonalCenter from 'components/PersonalCenter';
import Help from 'modules/Help';
import withLogin from 'components/withLogin';
import Portfolio from './Portfolio';
import Dashboard from './Dashboard';
import Documents from './Documents';
import EmptyPage from './EmptyPage';
import SearchPage from './SearchPage';
import EmptyTips from './SearchPage';


import './style.scss';

@withLogin
export default class Client extends Component {
	path = this.props.match.path

	componentDidMount() {
		// console.log(this.props.match)
	}

	render() {
		return <div className="sprout-client-root">
			<Header />
			<NavMenu />
			<div className="sprout-client-content-wrap">
				<div className="sprout-client-content">
					<Switch>
						<Route exact path={`${this.path}/portfolio`} component={Portfolio} />
						<Route path={`${this.path}/dashboard`} component={Dashboard}/>
						<Route path={`${this.path}/documents`} component={Documents} />
						<Route path={`${this.path}/exchange`} component={SearchPage} />
						<Route path={`${this.path}/personal-center`} component={PersonalCenter} />
						<Route path={`${this.path}/help`} component={Help} />
						<Route path={`${this.path}/empty`} component={EmptyPage} />


						{/* <Route path={`${this.path}/account/summary`} component={() => <div>Summary</div>}/>
						<Route path={`${this.path}/account/people`} component={() => <div>People</div>}/>
						<Route path={`${this.path}/account/setting`} component={() => <div>setting</div>} />
						<Route path={`${this.path}/account/pay`} component={() => <div>Pay</div>} />
						<Route path={`${this.path}/personal-center`} component={PersonalCenter} />
						<Route path={`${this.path}/help`} component={Help} />
						<Route path={`${this.path}/exchange`} component={SearchPage} />
						<Route path={`${this.path}/empty`} component={EmptyPage} /> */}
						<Redirect to={`${this.path}/dashboard`} />
					</Switch>
				</div>
			</div>
		</div>
	}
}
