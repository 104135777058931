import React, { Component } from "react";
import { Button } from "antd";
import { I } from "Utils";
import SuccessIcon from "../Public/img/success-icon.jpg";
import ErrorIcon from "../Public/img/error-icon.jpg";
import url from "url";
import "./style.scss";

class Notify extends Component {

    constructor(props) {
        super(props);
        this.state = {
            notifyHtml: [
                {
                    boxClass: ['signup-notify-box'],
                    imgSrc: SuccessIcon,
                    title: 'Payment Successful',
                    titleClass: 'signup-notify-success-title',
                    buttonValue: 'Close'
                },
                {
                    boxClass: ['signup-notify-box'],
                    imgSrc: ErrorIcon,
                    title: 'Your payment failed',
                    titleClass: 'signup-notify-error-title',
                    buttonValue: 'Try again'
                }
            ]
        }
    }

    componentDidMount() {
        const { notifyHtml } = this.state;
        let html = [...notifyHtml];
        const { sessionId, payType, loginSprout } = this.props;

        if(!I(payType, '')){
            loginSprout();
        }
        html[payType]['boxClass'].push('notify-box-show');
        this.setState(()=>({
            notifyHtml: html
        }))
    }

    getUrl = () => {
        const { history, payType,id, email } = this.props;
        if(I(payType, '')){
            window.location.href = `/signup?id=${id}&email=${encodeURIComponent(email)}&is_login=1`;
        }else{
            history.push('/');
        }
    }

    render() {
        const { notifyHtml } = this.state;
        return (
            <div className="signup-notify-root">
                {
                    notifyHtml.map( (v, k) => {
                        return (
                            <div key={k} className={v.boxClass.join(' ')}>
                                <img className="signup-notify-success-icon" src={v.imgSrc} />
                                <div className={v.titleClass}>{v.title}</div>
                                <div className="signup-notify-button-bak">
                                    <Button onClick={this.getUrl.bind(this)} type="primary" className="signup-notify-button">{v.buttonValue}</Button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}
export default Notify;