import React, { Component, Fragment } from "react";
import {Button, Spin, Checkbox} from "antd";
import "../AddOnes/style.scss";
import addOnesBackgroundImg from "../Public/img/add-ons-background.png";
import { I, calculator, formatNumber, clearNoNum } from 'Utils';
import {LeftOutlined} from "@ant-design/icons";
import {isNaN} from "mathjs";

class AddOnes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addOnesTitle: 'Additional services to add-on to your plan',
            currency: 'USD',
            dataList: {
                1: {
                    itemTitle: 'Starter Plan Options',
                    title: 'Starter Plan',
                    titleDesc: '',
                    price: '',
                    originalPrice: '',
                    desc:'',
                    checked: false,
                },
                16: {
                    itemTitle: 'Starter Plan Options',
                    title: 'Starter Plan',
                    titleDesc: '',
                    price: '',
                    originalPrice: '',
                    desc:'',
                    checked: false,
                },
                2: {
                    title: 'Onboarding & Setup',
                    titleDesc: '',
                    price: '',
                    originalPrice: '',
                    desc: this.getOnboardingDesc(),
                    checked: true,
                },
                3: {
                    itemTitle: 'Growth Plan Options',
                    title: 'Growth Plan',
                    titleDesc: this.getPlanTitleDesc(),
                    price: '',
                    originalPrice: '',
                    desc:'',
                    checked: true,
                },
                5: {
                    itemTitle: 'Growth Plan Options',
                    title: 'Growth Plan',
                    titleDesc: this.getPlanTitleDesc(),
                    price: '',
                    originalPrice: '',
                    desc:'',
                    checked: true,
                },
                9: {
                    title: '409A/83B Services',
                    titleDesc: '',
                    price: '',
                    originalPrice: '',
                    desc:<p>If your company offers options, you need a 409A valuation by an independent provider. After your first 409A, we’ll deliver an updated report every year or every time you fundraise to keep you compliant.</p>,
                    checked: false,
                },
                10: {
                    title: 'Consulting',
                    titleDesc: 'Growth Plan',
                    price: '',
                    originalPrice: '',
                    desc: this.getStartupConsultingDesc(),
                    checked: false,
                },
                12: {
                    title: 'Onboarding & Setup',
                    titleDesc: '',
                    price: 'Included',
                    originalPrice: '$249.00 USD',
                    desc: this.getOnboardingDesc(),
                    checked: true,
                },
                13: {
                    title: 'Consulting',
                    titleDesc: 'Growth Plan',
                    price: '',
                    originalPrice: '',
                    desc: this.getStartupConsultingDesc(),
                    checked: false,
                }
            },
            itemList: [],
        }
    }

    getPlanTitleDesc = () => {
        const { subscriptionCycle } = this.props.payment;
        return <div>Billed {subscriptionCycle == 1 ? 'annually' : 'monthly'} </div>
    }

    /**
     * Onboarding 说明
     * @returns {JSX.Element}
     */
    getOnboardingDesc = () => {
        return <Fragment>
            <p>Onboarding and setup made easy by Sprout. Just hand us your data (spreadsheet, documents, reports, etc) and our trusted team of onboarding professionals will cross check your documents for accuracy and investment readiness.</p>
        </Fragment>
    }

    /**
     * Startup Consulting 说明
     * @returns {JSX.Element}
     */
    getStartupConsultingDesc = () => {
        return <p>Not sure where to start? Talk to us about how Sprout and our wide ecosystem of partners can help you resolve the range of questions, issues and anxieties around setting up your employee equity and share option programs. For more details on how we can help, schedule time to talk to our experts.</p>
    }

    componentDidMount = () => {
        this.itemList();
    }

    /**
     * 下一页
     */
    getNextPage = (type) => {
        const { itemList } = this.state;
        const { updateTabCurrentType, updateState } = this.props;
        updateState('itemList', itemList);
        updateTabCurrentType(type);
    }

    /**
     * 初始化页面数据
     */
    itemList = () => {
        const { payment, productType, productItem, plan } = this.props;
        const { subscriptionCycle } = payment;
        //区分用户选择是plan
        //productType 0:start plan 1：其他
        //subscriptionCycle 0：月，1年
        const number = {0:{0:0,1:1},1:{0:2,1:3}};
        this.dataList(plan[number[productType][subscriptionCycle]]);
    }

    dataList = (itemIds) => {
        const { dataList, currency } = this.state;
        const { productItem, updateState, mandatoryItem } = this.props;
        let list = [], total = 0;
        itemIds.map( (v, k) => {
            const price = productItem[v].item_price;
            dataList[v].price = I( dataList[v].price, '') ? dataList[v].price : `${price} ${currency}`;
            dataList[v].id = v;
            dataList[v].member_number = productItem[v].member_number;
            //指定必选产品
            dataList[v].checked = this.inArray(v, mandatoryItem) ? true : false;
            list.push(dataList[v]);
            if(list.length <= 2){
                total = calculator.add(total, price);
            }
        });
        this.setState({
            itemList: list
        })
        updateState('itemList', list);
        updateState('total', total);
    }

    /**
     * 判断数组中是否含有某个数值
     * @param search
     * @param array
     * @returns {boolean}
     */
    inArray = (search, array) => {
        for(let i in array){
            if(array[i]==search){
                return true;
            }
        }
        return false;
    }

    /**
     * 获取金额，方便账单统计
     * @param price
     * @returns {string|*}
     */
    getPrice = (price) => {
        return isNaN(parseFloat(price)) ? '0.00' : I(price, 0);
    }

    /**
     * 产品列表
     * @returns {JSX.Element}
     */
    getItem = () => {
        const { itemList } = this.state;
        if(I(itemList, '')){
            return itemList.map( ( v, k) => {
                if( k == 0){
                    return (
                        <div key={k} className="add-ones-content-start-up-plan-info">
                            <div className="add-ones-first-title">
                                <div className="add-ones-plan-l">{I(v.itemTitle, '') ? v.itemTitle : v.title}</div>
                                {v.titleDesc && <div className="add-ones-plan-l-desc">{v.titleDesc}</div>}
                            </div>
                            <div className="add-ones-plan-r">{isNaN(parseFloat(v.price)) ? v.price : `$${v.price}`}</div>
                        </div>
                    )
                }else{
                    return (
                        <div key={k} className={['add-ones-content-onboarding', v.checked && 'add-ones-content-onboarding-active'].join(' ')}><dl>
                            <dt className="add-ones-onboarding-dt">
                                <div className="add-ones-onboarding-dt-l">
                                    <div className="add-ones-onboarding-dt-l-checkbox">
                                        <Checkbox checked={v.checked} onChange={this.itemChecked.bind(this, v, k)} ></Checkbox>
                                    </div>
                                    <div className="add-ones-onboarding-dt-l-title">{v.title}</div>
                                </div>
                                <div className={['add-ones-onboarding-dt-r', `add-ones-onboarding-dt-r-color${k}`].join(' ')}>
                                    <div>{v.originalPrice}</div>
                                    <div>{isNaN(parseFloat(v.price)) ? v.price : `$${v.price}` }</div>
                                </div>
                            </dt>
                            <dd>{v.desc}</dd></dl>
                        </div>
                    )
                }
            });
        }
    }

    /**
     * 选择产品及账单金额统计
     * @param data
     * @param key
     * @param e
     */
    itemChecked = ( data, key, e) => {
        const { itemList } = this.state;
        const { updateState } = this.props;
        const { checked } = e.target;
        let list = [...itemList], total = 0;
        // 按业务需求 onboarding 为必选项
        if(data.id != 2 && data.id != 12){
            list[key].checked = checked;
            this.setState({
                itemList: list,
            })
        }
        //账单统计
        list.map( ( v, k) => {
            if(v.checked){
                const price = isNaN(parseFloat(v.price)) ? '0.00' : I(v.price, 0);
                total = calculator.add(total, clearNoNum( {value: price} ).value );
            }
        })
        updateState('total', total);
    }

    /**
     * 统计账单金额及展示明细
     * @returns {*}
     */
    invoiceInfo = () => {
        const { itemList, currency } = this.state;
        return itemList.map( ( v, k) => {
            const price = isNaN(parseFloat(v.price)) ? '0.00' : I(v.price, 0);
            return (
                <div key={k} className="add-ones-amount-detail">
                    <span>{v.title}</span>
                    <span><b>{ v.checked ? `$${formatNumber(parseFloat(price), 2)}` : '$0.00' }</b> {currency}</span>
                </div>
            )
        });
    }

    render() {
        const { addOnesTitle, currency } = this.state;
        const { isOnboarding, total, loading, productItem, productType } = this.props;
        return (
            <div className="add-ones-root">
                <div className="add-ones-background"> </div>
                <div className="add-ones-content">
                    <div className="add-ones-content-left">
                        <img className="add-ones-content-back" src={addOnesBackgroundImg} />
                        <h2>{addOnesTitle}</h2>
                        <div className="add-ones-content-start-up-plan">
                            {this.getItem()}
                            <div className="add-ones-content-button-back" onClick={this.getNextPage.bind(this, 1)} >
                                <LeftOutlined className="add-ones-content-button-back-icon" />
                            </div>
                            <div className="add-ones-next-button-bak" onClick={this.getNextPage.bind(this, 3)} >
                                <Button type="primary" className="add-ones-next-button">Next Step</Button>
                            </div>
                            <div className="clear"></div>
                        </div>
                    </div>
                    <div className="add-ones-content-right">
                        <div className="add-ones-right-content">
                            <dl>
                                <dt>Summary</dt>
                                <dd>
                                    <div className="add-ones-solid"></div>
                                    <div className="add-ones-all-startup">
                                        <div className="add-ones-all-desc">
                                            <div>{productType == 1 ? 'Growth Plan' : 'Starter Plan'}</div>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                    <div className="add-ones-solid"></div>
                                    {this.invoiceInfo()}
                                    <div className="add-ones-solid mt-10"></div>
                                    <div className="add-ones-total"><b>${formatNumber(total, 2)}</b> {currency}</div>
                                </dd>
                            </dl>
                        </div>
                    </div>
                    <div className="clear"></div>
                </div>
            </div>
        );
    }
}
export default AddOnes;