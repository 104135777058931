import React, { Component } from 'react';
import { FormItem, withForm } from 'react-antd-formutil';
import { Input, Button, message, Space } from 'antd';
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { Redirect } from 'react-router-dom';
import Modal from 'components/Modal';
import { post } from 'srcPath/http';
import security from 'stores/security';
import { bindKeyEvent } from 'Utils';
import { Link } from 'react-router-dom'

const email = new RegExp('(?![\\-_.])[\\w\\-.]+@(?:(?![\\-_])[\\w\\-\u4e00-\u9fa5]{1,63}\\.)+[A-Za-z0-9]{2,}')

class Forgot extends Component {
	state = {
		loading: false,
		sent: false,
		time: 0
	}

	modalRef = null

	componentDidMount() {
		this.removeKeyEvent = bindKeyEvent(this.onSubmit)
	}

	componentWillUnmount() {
		this.removeKeyEvent && this.removeKeyEvent()

		this.modalRef && this.modalRef.close()
	}

	openModal = () => {
		this.modalRef = Modal.open({
			component: props => <div>	
				<p>
					<Space>
						<ExclamationCircleTwoTone />
						<span>{__('The password recovery email has been sent to')}</span>
						<i>{this.props.$formutil.$params.email || '--'}</i>
					</Space>
				</p>
				<div style={{ textAlign: 'center', marginTop: 30 }}><Button onClick={props.close}>{__('Cancel')}</Button>&nbsp;&nbsp;<Button style={{ width: 80 }} type="primary" onClick={props.dismiss}>{__('OK')}</Button></div>
			 </div>,
			width: 600
		})

		this.modalRef.result.catch(() => { this.props.history.push('/auth/login') })
	}

	onSubmit = async () => {
		const { $formutil } = this.props
		const {  $invalid, $batchDirty, $params, $getFirstError } = $formutil

		if ($invalid) {
			$batchDirty(true)
			message.error($getFirstError())

			return
		}

		this.setState({ loading: true })

		try {
			const { data } = await post('forgetPassword', $params)

			if (data.code == 200) {
				this.setState({ time: 60 }, () => { this.setTime() })
				this.openModal()
			} else {
				message.error(data.data)
			}

		} catch (e) {
			message.error(e.message)
		}

		this.setState({ loading: false })
	}

	setTime = () => {
		let { time } = this.state
		if (time >= 1) {
			setTimeout(() => {
				time = time - 1
				this.setState({ time })
				this.setTime()
			}, 1000)
		}
	}

	render() {
		const { loading, time } = this.state

		if(security.hasLogin) {
			return <Redirect to="/" />
		}

		return <div>
			<div className="sprout-auth-title">{__('Reset password')}</div>
			<div className="sprout-login-form">
				<div className="sprout-forgot-tips">{__("Enter your email address,and we'll send you a link to reset your password.")}</div>
				<div className="sprout-forgot-label">{__('Email Address')}</div>
				<FormItem 
					name="email" 
					required
					pattern={email}
					validMessage={{
						required: __('Email is required'),
						pattern: __('Email address is invalid')
					}}
				>
					<Input className="sprout-auth-input" size="large" placeholder="Email"/>
				</FormItem>
				<Button loading={loading} className="sprout-auth-bth" type="primary" onClick={this.onSubmit} disabled={time > 0}>{__('Request New Password')} {time > 0 && ` (${time})`}</Button>
				<div className="sprout-auth-link"><Link to="/auth/login">{__('Login')}</Link></div>				
			</div>
		</div>
	}
}

export default withForm(Forgot)