import React, { Component, Fragment } from "react";
import {DownOutlined} from "@ant-design/icons";
import {Menu, Dropdown, message} from "antd";
import SaveFinancingHistory from "./SaveFinancingHistory";
import CustomizeModel from "./CustomizeModel"
import Modal, { ModalContent } from 'components/Modal';
import { post } from 'srcPath/http';
import { I } from 'Utils';
import Loading from 'components/Loading';
import { printf } from 'Utils/i18n';

class DropdownMenu extends Component {

    //sub-round 弹层
    saveFinancingHistory = null;
    //customize 弹层
    customizeModel = null;

    /**
     * 卸载
     */
    componentWillUnmount() {
        this.saveFinancingHistory && this.saveFinancingHistory.close();
    }

    /**
     * sub-found 弹层页面
     */
    getSaveFinancingHistory = (shareClassId, index, type) => {
        const { data } = this.props;
        this.saveFinancingHistory = Modal.open({
            component: props => <ModalContent title={__('Add sub-round')} close={props.close}>
                <SaveFinancingHistory
                    shareClassId={shareClassId}
                    data={data}
                    type={type}
                    close={props.close}
                    saveHistory={this.saveHistory}
                />
            </ModalContent>,
            className: 'certificate-modal-wrap',
            width: '780px',
            maskClosable: false,
        });
    }

    /**
     * Customize 弹层
     */
    getCustomize = (data) => {
        const { name, id } = data;
        const { callback } = this.props;
        this.customizeModel = Modal.open({
            component: props => <ModalContent title={printf(__('Customize %s'), name)} close={props.close}>
                <CustomizeModel id={id} data={data} close={props.close} callback={callback} close={props.close} />
            </ModalContent>,
            className: 'certificate-modal-wrap',
            width: '780px',
            maskClosable: false,
        });
    }

    dropdown = () => {
        const { data, index, query, listLoading } = this.props;
        const {  isShow, customizeId } = query;
        !I(this.customizeModel, '') && !listLoading && data.id == customizeId && isShow && this.getCustomize(data);
        return <Dropdown
            overlay={this.menu( data, index)}
            placement="bottomRight"
            trigger="click">
            <div className="table-menu"><DownOutlined style={{ color: '#FFF' }}/></div>
        </Dropdown>
    }

    /**
     * 保存 sub-round
     * @returns {Promise<void>}
     */
    saveHistory = async(addData, close) => {
        Loading.global(true);
        const { getData } = this.props;
        try {
            const { data: resData } = await post('addSubRound', addData)
            const { code, data } = resData || {}
            if (code == 0) {
                message.success(__('success!'));
                getData();
                close();
            } else {
                message.error(data)
            }
        } catch(err) {
            message.error(err && err.message)
        }
        Loading.global(false);
    }

    /**
     * 删除 sub-round
     * @returns {Promise<void>}
     */
    removeSubRound = async(id, shareClassId) => {
        Loading.global(true);
        try {
            const { data: resData } = await post('delSubRound', { id, share_class_id: shareClassId })
            const { code, data } = resData || {}
            if (code == 0) {
                const { getData } = this.props;
                message.success(__('success!'));
                getData();
            } else {
                message.error(data);
            }
        } catch(err) {
            message.error(err && err.message)
        }
        Loading.global(false);
    }


    getRestoreDefaults = async(shareClassId) => {
        Loading.global(true);
        try {
            const { data: resData } = await post('restoreDefaults', { id: shareClassId })
            const { code, data } = resData || {}
            if (code == 0) {
                const { getData } = this.props;
                message.success(__('success!'));
                getData();
            } else {
                message.error(data);
            }
        } catch(err) {
            message.error(err && err.message)
        }
        Loading.global(false);
    }

    menu = (data, index) => {
        const { id, shareClassId } = data;
        return (
            <Menu>
                {
                    !I(data.isChildren, false) ? (
                        <Fragment>
                            <Menu.Item onClick={this.getSaveFinancingHistory.bind(this, shareClassId, index, 'add')}>{__('Add sub-round')}</Menu.Item>
                            <Menu.Item onClick={this.getCustomize.bind(this, data)}>{__('Customize')}</Menu.Item>
                            <Menu.Item onClick={this.getRestoreDefaults.bind(this, shareClassId)}>{__('Restore defaults')}</Menu.Item>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Menu.Item onClick={this.getSaveFinancingHistory.bind(this, shareClassId, index, 'edit')}>{__('Edit')}</Menu.Item>
                            <Menu.Item onClick={this.removeSubRound.bind(this, id, shareClassId)}>{__('Remove')}</Menu.Item>
                        </Fragment>
                    )
                }
            </Menu>
        )
    }

    render = () => this.dropdown();
}
export default DropdownMenu;




