import React, { Component } from 'react';
import { Button, Table } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { formatPercent } from 'Utils';
import RoundTags from 'components/RoundTags'

const columns = [
	{
		title: __('Share Class'),
		dataIndex: 'shareClass',
		ellipsis: true

	},
	{
		title: __('Rounds'),
		dataIndex: 'rounds',
		ellipsis: true,
		render: (value, record) => (<RoundTags isPick allTags={record.allRounds || []}  tags={[value]} />)
	},
	{
		title: __('Ownership'),
		dataIndex: 'ownerShip',
		align: 'right',
		width: 100,
		render: value => formatPercent(value / 100)
	}
]



export default class Capitalization extends Component {

	render() {
		const { data, onLink } = this.props || {}

		return <div>
			<div className="overview-banner">
				<div className="title">{__('Capitalization')}</div>
				<Button type="link" onClick={() => onLink('capitalization')}>{__('View details')} <RightOutlined /></Button>
			</div>
			<Table size="small" columns={columns} rowKey="rounds" dataSource={data || []} pagination={false}/>
		</div>
	}
}