import React from 'react';
import { post } from 'srcPath/http';
import Utils, { I } from "Utils";
import {Input, message} from 'antd';
import moment from "moment";
import DateSelector from "components/DateSelector";

class EditStakeholdersProperties extends React.Component {
    constructor(props){
        super(props);
        this.state={
            data:{
                id:"",
                jobTitleList:[
                    {effective_date:'',job_title:""},
                ],
                relDataList:[
                    {effective_date:'',relationship:"Investor"},
                ],
                costCenterList:[
                    {effective_date:'',cost_center:""},
                ],
            },
            sendStatus:0,
            dateFormat:"DD/MM/YYYY",
        }
    }
    initData=(key,initData)=>{
        let curData = this.state.data;
        curData.id = initData.id;
        // initData.relationshipDataList.map((val,k)=>{
        //     if(val.effective_date!=""){
        //         val.effective_date = formatDateForDatePicker(val.effective_date)
        //     }
        // })
        // initData.jobTitleList.map((value,key)=>{
        //     if(value.effective_date!=""){
        //         value.effective_date = formatDateForDatePicker(value.effective_date)
        //     }
        // })
        // initData.costCenterList.map((value,key)=>{
        //     if(value.effective_date!=""){
        //         value.effective_date = formatDateForDatePicker(value.effective_date)
        //     }
        // })
        curData.relDataList = initData.relationshipDataList;
        curData.jobTitleList = initData.jobTitleList;
        curData.costCenterList = initData.costCenterList;
        this.setState({
            data:curData
        })
    }
    editStakeholderProperties=()=>{
        if(this.state.sendStatus===1){
            return;
        }
        this.setState({
            sendStatus:1,
        })
        let requestData = this.state.data;

        const { callback } = this.props

        post('editStakeholderProperties', requestData).then((response)=>{
            if(response.data.code === 0){
                callback && callback()

                message.success('Success!')

                this.setState({
                    sendStatus:0
                })
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        })
    }
    addRelation=()=> {
        let one = {effective_date: Utils.getCurDate(), relationship: "Investor"}
        let curList = this.state.data;
        curList.relDataList.push(one);
        this.setState({
            data:curList
        })
    }
    delRelation=(num)=>{
        let curList = this.state.data;
        if(curList.relDataList.length <=1){
            message.error("You only have one relationship now!")
            return;
        }
        curList.relDataList.splice(num,1);
        this.setState({
            data:curList
        })
    }
    delJobTitle=(num)=>{
        let curList = this.state.data;
        if(curList.jobTitleList.length <=1){
            message.error("You only have one job title now!")
            return;
        }
        curList.jobTitleList.splice(num,1);
        this.setState({
            data:curList
        })
    }
    setRelation=(key,e)=>{
        let curList = this.state.data;
        curList.relDataList[key].relationship = e.target.value;
        this.setState({
            data:curList
        })
    }
    addJobTitle=()=> {
        let one = {effective_date: Utils.getCurDate(), job_title: ""}
        let curList = this.state.data;
        curList.jobTitleList.push(one);
        this.setState({
            data:curList
        })
    }

    recordData=(key,e)=>{
        let curList = this.state.data;
        curList.jobTitleList[key].job_title = e.target.value;
        this.setState({
            data:curList
        })
    }

    recordCostData=(key,e)=>{
        let curList = this.state.data;
        curList.costCenterList[key].cost_center = e.target.value;
        this.setState({
            data:curList
        })
    }

    addCostCenter=()=> {
        let one = {effective_date: Utils.getCurDate(), cost_center: ""}
        let curList = this.state.data;
        curList.costCenterList.push(one);
        console.log(curList);
        this.setState({
            data:curList
        })
    }

    delCostCenter=(num)=>{
        let curList = this.state.data;
        if(curList.costCenterList.length <=1){
            message.error("You only have one cost center now!")
            return;
        }
        curList.costCenterList.splice(num,1);
        this.setState({
            data:curList
        })
    }

    /**
     * relationshipEffectiveDateChange
     * @param listName
     * @param name
     * @param key
     * @param date
     */
    relationshipEffectiveDateChange = (listName, name, key, date) => {
        const { data, dateFormat } = this.state;
        let curData = {...data};
        curData[listName][key][name] = I(date, '') ? date.format( dateFormat) : '';
        this.setState({
            data:curData
        });
    }

    render(){
        const { data, dateFormat } = this.state;
        return(
            <div className="modal fade" id="EditStPropertiesModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={{display: "none"}} >
                    <div className="modal-dialog dialog-top40">
                        <div className="modal-content box-content relation-content">
                            <div className="modal-header box-header relation-header">
                                <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true"  onClick={this.removeActive}>×</button>
                                <h4 className="modal-title" id="myModalLabel">{__("Edit stakeholder's properties")}</h4>
                            </div>
                            <div className="modal-body box-body relation-body">
                                <ul className="nav nav-pills esp-navbox" role="tablist">
                                    <li className="active">
                                        <a className="btn wdt200 vesting-a" type="button" href="#cost_center" aria-controls="cost_center" role="tab" data-toggle="tab">Cost Center</a>
                                    </li>
                                    {/* <li>
                                        <a className="btn wdt200 vesting-a" type="button" href="#job_title" aria-controls="job_title" role="tab" data-toggle="tab">Job Title</a>
                                    </li>
                                    <li role="presentation">
                                        <a className="btn wdt200 vesting-a" type="button" href="#edit_relationship" aria-controls="edit_relationship" role="tab" data-toggle="tab">Relationship</a>
                                    </li> */}
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane active" id="cost_center">
                                        <table className="table table-striped table-rel">
                                            <tbody>
                                            <tr className="first-row">
                                                <td className="effective-date">{__('Effective date')}</td>
                                                <td>{__('Cost Center')}</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            {this.state.data.costCenterList && this.state.data.costCenterList.map((value,key)=>{

                                                if(this.state.data.costCenterList.length == (key+1)){
                                                    return(
                                                        <tr key={key}>
                                                            <td className={"width180"}>
                                                                <DateSelector
                                                                    onChange={this.relationshipEffectiveDateChange.bind(this,'costCenterList', 'effective_date', key)}
                                                                    value={ I(value.effective_date, '') ? moment(value.effective_date, dateFormat) : ''}
                                                                    inputReadOnly={false}
                                                                />
                                                            </td>
                                                            <td className="advisor-td">
                                                                <Input className="link-cursor" value={value.cost_center}  onChange={this.recordCostData.bind(this,key)} />
                                                            </td>
                                                            <td className="box-lasttd">
                                                                <div className="btn-box relationship-btnBox">
                                                                    <button type="button" onClick={this.addCostCenter} className="btn-mp plus"></button>
                                                                    <button type="button" onClick={this.delCostCenter.bind(this,key)} className="btn-mp minus"></button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }else{
                                                    return(
                                                        <tr key={key}>
                                                            <td>
                                                                <DateSelector
                                                                    onChange={this.relationshipEffectiveDateChange.bind(this,'costCenterList', 'effective_date', key)}
                                                                    value={ I(value.effective_date, '') ? moment(value.effective_date, dateFormat) : ''}
                                                                    inputReadOnly={false}
                                                                />
                                                            </td>
                                                            <td className="advisor-td">
                                                                <Input className="link-cursor" value={value.cost_center}  onChange={this.recordCostData.bind(this,key)} />
                                                            </td>
                                                            <td className="box-lasttd">
                                                                <div className="btn-box relationship-btnBox">
                                                                    <button onClick={this.delCostCenter.bind(this,key)} type="button" className="btn-mp minus"></button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            })}


                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="tab-pane" id="job_title">
                                        <table className="table table-striped table-rel">
                                            <tbody>
                                            <tr className="first-row">
                                                <td className="effective-date">{__('Effective date')}</td>
                                                <td>{__('Job title')}</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            {this.state.data.jobTitleList && this.state.data.jobTitleList.map((value,key)=>{
                                                if(this.state.data.jobTitleList.length == (key+1)){
                                                    return(
                                                        <tr key={key}>
                                                            <td className={"width180"}>
                                                                <DateSelector
                                                                    onChange={this.relationshipEffectiveDateChange.bind(this,'jobTitleList', 'effective_date', key)}
                                                                    value={ I(value.effective_date, '') ? moment(value.effective_date, dateFormat) : ''}
                                                                    inputReadOnly={false}
                                                                />
                                                            </td>
                                                            <td className="advisor-td">
                                                                <Input className="link-cursor" value={value.job_title}  onChange={this.recordData.bind(this,key)} />
                                                            </td>
                                                            <td className="box-lasttd">
                                                                <div className="btn-box relationship-btnBox">
                                                                    <button type="button" onClick={this.addJobTitle} className="btn-mp plus"></button>
                                                                    <button type="button" onClick={this.delJobTitle.bind(this,key)} className="btn-mp minus"></button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }else{
                                                    return(
                                                        <tr key={key}>
                                                            <td>
                                                                <DateSelector
                                                                    onChange={this.relationshipEffectiveDateChange.bind(this,'jobTitleList', 'effective_date', key)}
                                                                    value={ I(value.effective_date, '') ? moment(value.effective_date, dateFormat) : ''}
                                                                    inputReadOnly={false}
                                                                />
                                                            </td>
                                                            <td className="advisor-td">
                                                                <input className="link-cursor" value={value.job_title}  onChange={this.recordData.bind(this,key)} />
                                                            </td>
                                                            <td className="box-lasttd">
                                                                <div className="btn-box relationship-btnBox">
                                                                    <button onClick={this.delJobTitle.bind(this,key)} type="button" className="btn-mp minus"></button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            })}

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="tab-pane" id="edit_relationship">
                                        <table className="table table-striped table-rel">
                                            <tbody>
                                            <tr className="first-row">
                                                <td className="effective-date">{__('Effective date')}</td>
                                                <td>{__('Relationship')}</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            {this.state.data.relDataList && this.state.data.relDataList.map((value,key)=>{

                                                if(this.state.data.relDataList.length == (key+1)){
                                                    return(
                                                        <tr key={key}>
                                                            <td className={"width180"}>
                                                                <DateSelector
                                                                    onChange={this.relationshipEffectiveDateChange.bind(this,'relDataList', 'effective_date', key)}
                                                                    value={ I(value.effective_date, '') ? moment(value.effective_date, dateFormat) : ''}
                                                                    inputReadOnly={false}
                                                                />
                                                            </td>
                                                            <td className="advisor-td relationship-td">
                                                                <select className="select-box relationship_selectbox" name={"relationship"+key} value={value.relationship} onChange={this.setRelation.bind(this,key)}>
                                                                    {this.props.relationshipList.map((val,k)=>{
                                                                        return(
                                                                            <option key={k} value={val}>{val}</option>
                                                                            )
                                                                    })}
                                                                </select>
                                                            </td>
                                                            <td className="box-lasttd">
                                                                <div className="btn-box relationship-btnBox">
                                                                    <button type="button" onClick={this.addRelation} className="btn-mp plus"></button>
                                                                    <button type="button" onClick={this.delRelation.bind(this,key)} className="btn-mp minus"></button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }else{
                                                    return(
                                                        <tr key={key}>
                                                            <td>
                                                                <DateSelector
                                                                    onChange={this.relationshipEffectiveDateChange.bind(this, 'relDataList', 'effective_date', key)}
                                                                    value={ I(value.effective_date, '') ? moment(value.effective_date, dateFormat) : ''}
                                                                    inputReadOnly={false}
                                                                />
                                                            </td>
                                                            <td className="advisor-td relationship-td">
                                                                <select className="select-box relationship_selectbox"  name={"relationship"+key} value={value.relationship} onChange={this.setRelation.bind(this,key)}>
                                                                    {this.props.relationshipList.map((val,k)=>{
                                                                        return(
                                                                            <option key={k} value={val}>{val}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </td>
                                                            <td className="box-lasttd">
                                                                <div className="btn-box relationship-btnBox">
                                                                    <button onClick={this.delRelation.bind(this,key)} type="button" className="btn-mp minus"></button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            })}


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer box-footer relation-footer">
                                <a  onClick={this.editStakeholderProperties} className="btn boxbtn-to bg-darkblue wdt200" >
                                    {this.state.sendStatus===0 && __("Save")}
                                    {this.state.sendStatus===1 && __("Saving")}
                                </a>
                            </div>
                        </div>
                    </div>
            </div>
        )
    }
}
export default EditStakeholdersProperties;