import React, { Component } from 'react';
import Modal, { ModalContent } from 'components/Modal';
import { Button, message } from 'antd';
import { post } from 'srcPath/http';
import Utils, { bindKeyEvent } from 'Utils';
import './style.scss';

class DeleteAccountUser extends Component {
	state = {
		loading: false,
	}

	componentDidMount() {
		this.removeKeyEvent = bindKeyEvent(this.onSubmit)
	}

	componentWillUnmount() {
		this.removeKeyEvent && this.removeKeyEvent()
	}

	onSubmit = async () => {
		const { close, callback, data: defaultData } = this.props	
		this.setState({ loading: true })

		const api = defaultData && defaultData.isBoard ? 'deleteBoardMember' : 'deleteUser'

		try { // deleteBoardMember 
			const { data: resData } = await post(api, { id: defaultData.id })
			const { code, data } = resData || {}

			if (code == 0 || code == 200) {
				close && close()
				callback && callback()
			} else {
				message.error(data)
			}
		} catch (e) {
			message.error(e.message)
		}

		this.setState({ loading: false })
	}

	render() {
		const { data } = this.props
		const { name, titles } = data || {}

		return <div className="permisson-user-delete-modal">
			<p>{__('Do you want to delete user')} <strong> {name} </strong> { titles && <span>({__('title')}: {titles})</span> } ?</p>
			<div className="btn-wrap">
				<Button type="primary" loading={this.state.loading} onClick={this.onSubmit}>{__('Delete')}</Button>
			</div>
		</div>
	}
}


export default function openDeleteUserModal(callback, data) {
	const { name } = data || {}
	
	return Modal.open({
		component: props => <ModalContent close={props.close} title={__('Delete user %s').replace('%s', name)}> <DeleteAccountUser data={data} close={props.close} callback={callback} /></ModalContent>,
		width: 600,
		className: 'sprout-modal'
	})
}

