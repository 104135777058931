import { formatDate } from 'Utils/format/formatDate';

const renderValue = data => data || '--';

export const renderDate = (date) => {
  if (!date) {
    return '--';
  } else if (date == 'Not vested') {
    return date;
  } else {
    return formatDate(date)
  }
}

const formatNumber = (value) => {
    const number = value && value.split('.');
    let leftNumber = number?.[0];
    leftNumber = leftNumber && ((leftNumber+'').replaceAll(/\d{1,3}(?=(\d{3})+$)/g,'$&,'))
    return `${leftNumber}${number?.[1] ? `.${number?.[1]}` : ''}`;
}

export const getColumns = (isToken) => [
    {
        title: __('Period'),
        dataIndex: 'period',
        sorter: (a, b) => a.period - b.period,
        render: renderValue,
        className: 'nowrap'
    },
    {
        title: __('Date'),
        dataIndex: 'date',
        render: renderDate,
        className: 'nowrap',
        width: 200,
    },
    {
        title: isToken ? __('Tokens') : __('Shares vested'),
        dataIndex: 'shareVested',
        sorter: (a, b) => a.shareVested - b.shareVested,
        render: formatNumber,
        align: 'right',
        className: 'nowrap'
    },
    {
        title: __('Cumulative vested'),
        dataIndex: 'cumulative',
        sorter: (a, b) => a.cumulative - b.cumulative,
        render: formatNumber,
        align: 'right',
        className: 'nowrap'
    },
];
