
import React, { useEffect } from 'react';
import Header from 'components/Layout/Header';
import withLogin from 'components/withLogin';
import { Switch, Route, Redirect } from 'react-router-dom';
import PersonalCenter from 'components/PersonalCenter';
import Modal, { ModalContent } from 'components/Modal';
import { Button } from 'antd';
import EmptyTips from '../Client/EmptyTips'
import './style.scss';

export default withLogin(function Empty() {
    console.log('%c [  ]-13', 'font-size:13px; background:pink; color:#bf2c9f;', location)
    const pathName = location?.pathname
    useEffect(() => {
        Modal.open({
            component: props => <ModalContent close={props.close} title={__('Reminder')}>
                <div style={{ padding: "20px 30px" }}>
                    <p>{__("Please contact your company's administrator to enable your access.")}</p>
                    <p>{__('Note:  Access can be enabled from Settings - Portfolio Access or from All Stakeholders - Cap table access.')}</p>

                    <div style={{ textAlign: 'center', marginTop: 30 }}>
                        <Button onClick={props.close} type="primary">{__('I understand')}</Button>
                    </div>
                </div>
            </ModalContent>,
            className: 'sprout-modal'
        })
    }, [])

    return <div className="sprout-empty-root">
        <Header />
        <div className="sprout-empty-content-wrap">
            <div className="sprout-empty-content">
                {pathName === '/empty' && <EmptyTips />}
                <Switch>
                    <Route path="/empty/personal-center" component={PersonalCenter} />
                    <Redirect to="/empty" />
                </Switch>  
            </div>
        </div>
    </div>
})