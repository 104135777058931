import React from 'react';
import Utils, { I, filterAmount } from "Utils";
import { language } from "Utils/i18n";
import {Input, DatePicker, message, Select } from 'antd';
import Modal from 'components/Modal';
import RelationshipSelector from 'components/RelationshipSelector';
import { post } from 'srcPath/http';
import CountrySelector from 'components/CountrySelector';
import CitySelector from 'components/CitySelector';
import moment from "moment";
import security from 'stores/security';
import DateSelector from "components/DateSelector";
import AddNewWallet from '../../Wallets/components/AddNewWallet'
import SelectCostCenter from "components/SelectCostCenter";
import { formatDate2Api } from 'Utils/format/formatDate'

import 'assets/css/merge.css';
import 'assets/css/addStakeholder.css';

const { Option } = Select;

class EditStakeholder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateFormat: "DD/MM/YYYY",
            cost_list:[],
            data: {
                id: "",
                stakeholder_type: 1,
                name: "",
                email: "",
                date_of_birth: "",
                annual_salary: "",
                country_region: "Hongkong",
                city: "",
                address: "",
                passport_or_national_id: "",
                employee_id: "",
                relationshipDataList: [
                    { effective_date: Utils.getCurDate(), relationship: "" },
                ],
                gender: "",
                jobTitle: [
                    { effective_date: Utils.getCurDate(), job_title: "" },
                ],
                costCenterList: [
                    { effective_date: Utils.getCurDate(), cost_center: "", id:"" },
                ],
            },
            walletList: [{
                address: ''
            }],

            genderList: [
                {
                    name:''
                },
                {
                    name: 'Female'
                },
                {
                    name: 'Male'
                },
                {
                    name: 'Non-binary / non-conforming'
                },
                {
                    name: 'Prefer not to respond'
                },
            ],
            sendStatus: 0,
            errorStatus: 0,
            duplicateError: 0,
            requiredKey: ["name", "email", "relationshipDataList"],
            relationRequiredKey: ["effective_date", "relationship"],
        }
    }
    componentDidMount () {
        const { id, data: propsData = {}, cost_list } = this.props
        const walletList = []
        if (propsData.wallet_address?.length || propsData.wallet_address_list?.length) {
            if (Array.isArray(propsData.wallet_address || propsData.wallet_address_list)) {
                (propsData.wallet_address || propsData.wallet_address_list).map((item) => {
                    walletList.push({
                        address: item.wallet_address,
                        token_tag: item.token_tag
                    })
                })
            } else {
                walletList.push({
                    address: propsData.wallet_address
                })
            }
            this.setState({
                walletList
            })
        } else {
            walletList.push({ address: '' })
        }
        this.initData(id, propsData)
    }
    initData = (key, initData) => {

        let curData = this.state.data;
        curData.stakeholder_type = initData.stakeholder_type;

        const { relationshipDataList, jobTitleList, costCenterList } = this.initRelationshipAndJob(initData);
        costCenterList?.map((v,k)=>{
            if(v.id==0){
                v.id=""
            }
        })
        initData.jobTitleList = jobTitleList;
        initData.jobTitle = initData.jobTitleList;
        initData.relationshipDataList = relationshipDataList;
        initData.costCenterList = costCenterList;

        this.setState({
            data:JSON.parse(JSON.stringify(initData)),
            propsKey: key
        });
    }

    initRelationshipAndJob = (initData) => {
        const { relationshipDataList, jobTitleList, costCenterList } = initData;
        relationshipDataList && relationshipDataList.forEach((v, k)=>{
            !v?.effective_date && ( relationshipDataList[k].effective_date = formatDate2Api(moment()) );
        })
        jobTitleList && jobTitleList.forEach((v, k)=>{
            !v?.effective_date && ( jobTitleList[k].effective_date = formatDate2Api(moment()) );
        })
        costCenterList && costCenterList.forEach((v, k)=>{
            !v?.effective_date && ( costCenterList[k].effective_date = formatDate2Api(moment()) );
        })
        return {relationshipDataList, jobTitleList, costCenterList};
    }

    showErrorTip = () => {
        this.setState({
            errorStatus: 1
        })
        this.showEditStakeholderError();
    }
    showEditStakeholderError = () => {
        // $("#editStakeholderInputError").removeClass("hidden");
        let curData = this.state.data;
        for (let i = 0; i < curData.relationshipDataList.length; i++) {
            this.state.relationRequiredKey.map((val, k) => {
                if (curData.relationshipDataList[i][val].length == 0) {
                    $("#valid_edit_relationship").removeClass("hidden")
                } else {
                    $("#valid_edit_relationship").addClass("hidden")
                }
            })
        }
        this.state.requiredKey.map((value, key) => {
            if (curData[value].length == 0) {
                $("#edit_" + value).addClass("has-error");
            } else {
                $("#edit_" + value).removeClass("has-error");

            }
        })
        let isEmail = Utils.isEmail(this.state.data.email);
        if (!isEmail) {
            $("#edit_email").addClass("has-error");
        } else {
            $("#edit_email").removeClass("has-error");
        }
    }
    editStakeholder = () => {
        const { cb, close } = this.props
        const { data, walletList = [] } = this.state
        const wallet_address_list = []

        if (this.state.sendStatus === 1) {
            return;
        }
        this.setState({
            sendStatus: 1
        })

        walletList.map((i) => {
            wallet_address_list.push({
                wallet_address: i.address,
                token_tag: '',
            })
        })
        var requestData = data;
        requestData.wallet_address_list = wallet_address_list

        post('editStakeholderData', requestData).then((response) => {
            if (response.data.code === 0) {
                message.success('Success!')
                this.setState({
                    sendStatus: 0,
                    errorStatus: 0,
                })
                close()
                cb && cb()
            } else if (response.data.code === 201) {
                this.showAddStakeholderError();
                this.setState({
                    duplicateError: 1,
                    errorStatus: 0,
                    sendStatus: 0,
                })
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    addRelation = () => {
        let one = { effective_date: Utils.getCurDate(), relationship: "Investor" }
        let curList = this.state.data;
        curList.relationshipDataList.push(one);
        this.setState({
            data: curList
        })
    }
    setRelation = (key, value) => {
        let curList = this.state.data;
        curList.relationshipDataList[key].relationship = value;
        curList.relationship = value;
        this.setState({
            data: curList
        })
    }
    delRelation = (num) => {
        let curList = this.state.data;
        if (curList.relationshipDataList.length <= 1) {
            message.error("You only have one relationship now!")
            return;
        }
        curList.relationshipDataList.splice(num, 1);
        this.setState({
            data: curList
        })
    }
    recordData = (e) => {
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = dataVal;
        this.setState({
            data: curData
        });
    }

    /**
     * input change通用事件
     * @param isDecimal 是否需要小数
     * @param e
     */
    inputChange = (isDecimal, e) => {
        const { target: element } = e;
        const { name: dataKey } = element;
        const filter = filterAmount(e, 9999999999999, isDecimal);
        let curData = this.state.data;
        curData[dataKey] = filter.value;
        this.setState({
            data: curData
        }, () => {
            element.selectionStart = element.selectionEnd = filter.cursorPositionX;
        });
    }

    handleCountryChange = country => {
        const { data = {} } = this.state

        data['country_region'] = country

        this.setState({ data })
    }

    handleCityChange = city => {
        let curList = this.state.data;

        curList['city'] = city

        this.setState({ data: curList })
    }

    /**
     * date_of_birth
     * @param key
     * @param moment_key
     * @param date
     */
    handleBirthdayChange = (key, moment_key, date) => {
        const { dateFormat } = this.state;
        let curData = this.state.data;
        curData[key] = date?.format(dateFormat);
        curData[moment_key] = date;
        this.setState({
            data: curData
        });
    }

    /**
     * relationshipEffectiveDateChange
     * @param name
     * @param key
     * @param date
     */
    relationshipEffectiveDateChange = (listName, name, key, date) => {
        const { data, dateFormat } = this.state;
        let curData = { ...data };
        curData[listName][key][name] = date?.format(dateFormat);
        this.setState({
            data: curData
        });
    }
    genderSelectChange = (gender) => {
        let curList = this.state.data
        curList['gender'] = gender
        this.setState({
            data: curList
        })
    }
    deljobTitle = (num) => {
        let curList = this.state.data;
        if (curList.jobTitle.length <= 1) {
            message.error("You only have one job title now!")
            return;
        }
        curList.jobTitle.splice(num, 1);
        this.setState({
            data: curList
        })
    }
    addjobTitle = () => {
        let one = { effective_date: Utils.getCurDate(), job_title: "" }
        let curList = this.state.data;
        curList.jobTitle.push(one);
        this.setState({
            data: curList
        })
    }
    walletAddressChange = (walletList) => {
        this.setState({ walletList })
    }
    JobRecordData = (key, e) => {
        let curList = this.state.data;
        curList.jobTitle[key].job_title = e.target.value;
        this.setState({
            data: curList
        })
    }

    setCost = (key, value) => {
        const { cost_list } = this.props;
        const languageType = language =='zh_CN' ? 'zh_cn' : (language == 'en_US' ? 'en_us' : 'zh_tw' )
        let curList = this.state.data;
        if(value){
            curList.costCenterList[key].id = value;
            curList.costCenterList[key].cost_center = cost_list[cost_list.findIndex(i=>i.id == value)][languageType];
        }else{
            curList.costCenterList[key].id = '';
            curList.costCenterList[key].cost_center = '';
        }
        this.setState({
            data: curList
        })
    }

    addCost = () => {
        let one = { effective_date: Utils.getCurDate(), cost_center: "", id:"" }
        let curList = this.state.data;
        curList.costCenterList.push(one);
        this.setState({
            data: curList
        })
    }

    delCost = (num) => {
        let curList = this.state.data;
        if (curList.costCenterList.length <= 1) {
            message.error("You only have one cost center now!")
            return;
        }
        curList.costCenterList.splice(num, 1);
        this.setState({
            data: curList
        })
    }

    closeModels = () => {
        const { close } = this.props;
        close && close()
    }
    render() {
        const { walletList } = this.state
        let curErrorStatus = 1;
        let curData = this.state.data;
        this.state.requiredKey.map((value, key) => {
            if (value === "email") {  //email check
                let isEmail = Utils.isEmail(this.state.data.email);
                if (!isEmail) {
                    curErrorStatus = 0;
                }
            }
            if (value === "relationshipDataList") {
                for (let i = 0; i < curData.relationshipDataList.length; i++) {
                    if (curData.relationshipDataList[i].effective_date.length === 0 || curData.relationshipDataList[i].relationship.length === 0) {
                        curErrorStatus = 0
                    }
                }
            }
            if (this.state.data[value].length === 0) {
                curErrorStatus = 0;
            }

        })
        const { data, dateFormat, genderList } = this.state;
        const { currency } = security.companyInfo
        const { cost_list } = this.props;
        return (
            <div>
                <div>
                    <div className="modal-content box-content add-content width620" style={{border:'none'}}>
                        <div className="modal-header box-header">
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.closeModels}>×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Edit stakeholder')}</h4>
                        </div>
                        <div className="modal-body box-body add-body">
                            <div>
                                <div className="body-one">
                                    <p className="basic-header header-border">{__('Basic information')}</p>
                                    <form className="add-form form-horizontal">
                                        <div className="from-group add-items">
                                            <span className="col-xs-4 label-nopadding add-label">{__('Stakeholder type')}</span>
                                            <div className="col-xs-8">
                                                <div className="radio status-radio holders-radio">
                                                    <input id="edit_individual" type="radio" name="stakeholder_type" value="1" checked={this.state.data.stakeholder_type == 1} onChange={this.recordData} />
                                                    <label htmlFor="edit_individual" className="radio-label holder-label holders-radioLabel">{__('Individual')}</label>
                                                </div>
                                                <div className="radio status-radio holders-radio">
                                                    <input id="edit_non_individual" type="radio" name="stakeholder_type" value="0" checked={this.state.data.stakeholder_type == 0} onChange={this.recordData} />
                                                    <label htmlFor="edit_non_individual" className="radio-label holder-label holders-radioLabel">{__('Non-individual')}</label>
                                                </div>
                                                <p className="text-muted">{__('Non-individual stakeholders can be investment firms companies, corporations, etc')}</p>
                                            </div>
                                        </div>
                                        <div className="form-group add-items">
                                            <label className="col-xs-4 add-label">{__('Name')}<br />
                                                <i><small className="text-muted">- {__('Required')}</small></i>
                                            </label>
                                            <div className="col-xs-6" id={"edit_name"}>
                                                <input className="form-control" name="name" value={data.name} onChange={this.recordData} type="text" />
                                            </div>
                                        </div>
                                        <div className="form-group add-items">
                                            <label className="col-xs-4 add-label">{__('Email')}<br />
                                                <i><small className="text-muted">- {__('Required')}</small></i>
                                            </label>
                                            <div className="col-xs-6" id={"edit_email"}>
                                                <input readOnly={true}  className="form-control" name="email" value={data.email} onChange={this.recordData} type="email" />
                                            </div>
                                        </div>

                                        {this.state.data.stakeholder_type == 1 &&
                                        <div className="form-group add-items">
                                            <label className="col-xs-4 add-label">{__('Date of birth')}<br />
                                                <i><small className="text-muted">- {__('Optional')}</small></i>
                                            </label>
                                            <div className="col-xs-4">
                                                <DateSelector
                                                    onChange={this.handleBirthdayChange.bind(this, 'date_of_birth', 'date_of_birth_moment')}
                                                    value={I(data['date_of_birth_moment'], I(data['date_of_birth'], '') ? moment(data['date_of_birth'], dateFormat) : '')}
                                                    inputReadOnly={false}
                                                />
                                            </div>
                                        </div>
                                        }
                                        {this.state.data.stakeholder_type == 1 &&
                                        <div className="form-group add-items">
                                            <label className="col-xs-4 add-label">{__('Gender')}<br />
                                                <i><small className="text-muted">- {__('Optional')}</small></i>
                                            </label>
                                            <div className="col-xs-6">

                                                <Select className="form-control select-container gender-select" name="gender" value={data.gender} defaultValue={data.gender} onChange={this.genderSelectChange} type="text">
                                                    {genderList.map((value, key) => {
                                                        return (
                                                            <Option value={value.name} key={key}>{value.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        }
                                        {this.state.data.stakeholder_type == 1 &&
                                        <div className="form-group add-items ">
                                            <label className="col-xs-4 add-label">{__('Annual salary')}<br />
                                                <i><small className="text-muted">- {__('Optional')}</small></i>
                                            </label>
                                            <div className="col-xs-4">
                                                <div className="input-group"><span className="input-group-addon">{currency}</span>
                                                    <input name="annual_salary" className="form-control" value={data.annual_salary} onChange={this.inputChange.bind(this, true)} />
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </form>
                                </div>
                                <div className="relationship marginBott10">
                                    <p className="marginBott10">{__('Relationship')}<br />
                                        <i><small className="text-muted">- {__('Required')}</small></i>
                                    </p>
                                    <table className="table table-striped table-rel">
                                        <tbody>
                                        <tr className="first-row">
                                            <td className="effective-date">{__('Effective date')}</td>
                                            <td>{__('Relationship')}</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        {this.state.data.relationshipDataList.map((value, key) => {

                                            if (this.state.data.relationshipDataList.length == (key + 1)) {
                                                return (
                                                    <tr key={key}>
                                                        <td className={"width180"}>
                                                            <DateSelector
                                                                onChange={this.relationshipEffectiveDateChange.bind(this, 'relationshipDataList', 'effective_date', key)}
                                                                value={I(value.effective_date, '') ? moment(value.effective_date, dateFormat) : ''}
                                                                inputReadOnly={false}
                                                                allowClear={false}
                                                            />
                                                        </td>
                                                        <td className="width260 relationship-td">
                                                            <RelationshipSelector style={{ width: 254 }} value={value.relationship} onChange={this.setRelation.bind(this, key)} />
                                                        </td>
                                                        <td className="box-lasttd">
                                                            <div className="btn-box btnBox-top12">
                                                                <button type="button" onClick={this.addRelation} className="btn-mp plus"></button>
                                                                <button type="button" onClick={this.delRelation.bind(this, key)} className="btn-mp minus"></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            } else {
                                                return (
                                                    <tr key={key}>
                                                        <td>
                                                            <DateSelector
                                                                onChange={this.relationshipEffectiveDateChange.bind(this, 'relationshipDataList', 'effective_date', key)}
                                                                value={I(value.effective_date, '') ? moment(value.effective_date, dateFormat) : ''}
                                                                inputReadOnly={false}
                                                                allowClear={false}
                                                            />
                                                        </td>
                                                        <td className="width260 relationship-td">
                                                            <RelationshipSelector style={{ width: 254 }} value={value.relationship} onChange={this.setRelation.bind(this, key)} />
                                                        </td>
                                                        <td className="box-lasttd">
                                                            <div className="btn-box btnBox-top12">
                                                                <button onClick={this.delRelation.bind(this, key)} type="button" className="btn-mp minus"></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        })}
                                        </tbody>
                                    </table>
                                    <p className="alarm_text hidden" id="valid_edit_relationship"><span className="alarm_text glyphicon glyphicon-exclamation-sign"></span> A valid relationship is required.</p>
                                </div>

                                {/*AP-3251 cost center目前在qa和staging, prod暂时不上。*/}
                                <div className="relationship marginBott10">
                                    <p className="marginBott10">{__('Cost center')}<br />
                                        <i><small className="text-muted">- {__('Optional')}</small></i></p>
                                    <table className="table table-striped table-rel marginBott20">
                                        <tbody>
                                        <tr className="first-row">
                                            <td className="effective-date">{__('Effective date')}</td>
                                            <td>{__('Cost center')}</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        {this.state.data.costCenterList?.map((value, key) => {
                                            if (this.state.data.costCenterList.length == (key + 1)) {
                                                return (
                                                    <tr key={key}>
                                                        <td className={"width180"}>
                                                            <DateSelector
                                                                onChange={this.relationshipEffectiveDateChange.bind(this, 'costCenterList', 'effective_date', key)}
                                                                value={I(value.effective_date, '') ? moment(value.effective_date, dateFormat) : ''}
                                                                inputReadOnly={false}
                                                                allowClear={false}
                                                            />
                                                        </td>
                                                        <td className="width270 relationship-td" id={"add_cost" + key}>
                                                            <SelectCostCenter  cost_list={ cost_list } style={{ width: 254 }} value={value.id} label={value.cost_center}  onChange={this.setCost.bind(this, key)}  />
                                                        </td>
                                                        <td className="box-lasttd">
                                                            <div className="btn-box btnBox-top12">
                                                                <button type="button" onClick={this.addCost} className="btn-mp plus"></button>
                                                                <button type="button" onClick={this.delCost.bind(this, key)} className="btn-mp minus"></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            } else {
                                                return (
                                                    <tr key={key}>
                                                        <td>
                                                            <DateSelector
                                                                onChange={this.relationshipEffectiveDateChange.bind(this, 'costCenterList', 'effective_date', key)}
                                                                value={I(value.effective_date, '') ? moment(value.effective_date, dateFormat) : ''}
                                                                inputReadOnly={false}
                                                                allowClear={false}
                                                            />
                                                        </td>
                                                        <td className="width270 relationship-td" id={"add_cost" + key}>
                                                            <SelectCostCenter cost_list={ cost_list } style={{ width: 254 }} value={value.id} label={value.cost_center}  onChange={this.setCost.bind(this, key)}/>
                                                        </td>
                                                        <td className="box-lasttd">
                                                            <div className="btn-box btnBox-top12">
                                                                <button onClick={this.delCost.bind(this, key)} type="button" className="btn-mp minus"></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }

                                        })}
                                        </tbody>
                                    </table>
                                    <p className="alarm_text  hidden" id="valid_relationship"><span className="alarm_text glyphicon glyphicon-exclamation-sign"></span> A valid relationship is required.</p>

                                </div>

                                {this.state.data.stakeholder_type == 1 &&
                                <div className="Jobtitle marginBott10">
                                    <p className="marginBott10">{__('Job title')}<br />
                                        <i><small className="text-muted">- {__('Optional')}</small></i></p>
                                    <table className="table table-striped table-rel marginBott20">
                                        <tbody>
                                        <tr className="first-row">
                                            <td className="effective-date">{__('Effective date')}</td>
                                            <td>{__('Job title')}</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        {this.state.data.jobTitle.map((value, key) => {

                                            if (this.state.data.jobTitle.length == (key + 1)) {
                                                return (
                                                    <tr key={key}>
                                                        <td className={"width180"}>
                                                            <DateSelector
                                                                onChange={this.relationshipEffectiveDateChange.bind(this, 'jobTitle', 'effective_date', key)}
                                                                value={I(value.effective_date, '') ? moment(value.effective_date, dateFormat) : ''}
                                                                inputReadOnly={false}
                                                                allowClear={false}
                                                            />
                                                        </td>
                                                        <td className="width260">
                                                            <Input className="link-cursor" style={{ width: 254 }} value={value.job_title} onChange={this.JobRecordData.bind(this, key)} />
                                                        </td>
                                                        <td className="box-lasttd">
                                                            <div className="btn-box btnBox-top12">
                                                                <button type="button" onClick={this.addjobTitle} className="btn-mp plus"></button>
                                                                <button type="button" onClick={this.deljobTitle.bind(this, key)} className="btn-mp minus"></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            } else {
                                                return (
                                                    <tr key={key}>
                                                        <td>
                                                            <DateSelector
                                                                onChange={this.relationshipEffectiveDateChange.bind(this, 'jobTitle', 'effective_date', key)}
                                                                value={I(value.effective_date, '') ? moment(value.effective_date, dateFormat) : ''}
                                                                inputReadOnly={false}
                                                                allowClear={false}
                                                            />
                                                        </td>
                                                        <td className="width260">
                                                            <Input className="link-cursor" style={{ width: 254 }} value={value.job_title} onChange={this.JobRecordData.bind(this, key)} />
                                                        </td>
                                                        <td className="box-lasttd">
                                                            <div className="btn-box btnBox-top12">
                                                                <button onClick={this.deljobTitle.bind(this, key)} type="button" className="btn-mp minus"></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                                }
                                <div className="body-one">
                                    <p className="basic-header header-border">{__('Address information')}</p>
                                    <form className="add-form form-horizontal">
                                        <div className="form-group add-items">
                                            <label className="col-xs-4 add-label">{__('Country / Region')}<br />
                                                <i><small className="text-muted">- {__('Optional')}</small></i>
                                            </label>
                                            <div className="col-xs-6">
                                                <CountrySelector style={{ width: '100%' }} value={data.country_region} onChange={this.handleCountryChange} />
                                            </div>
                                        </div>
                                        <div className="form-group add-items">
                                            <label className="col-xs-4 add-label">{__('Address details')}<br />
                                                <i><small className="text-muted">- {__('Optional')}</small></i>
                                            </label>
                                            <div className="col-xs-6">
                                                <input className="form-control text-area" name="address" value={data.address} onChange={this.recordData} type="text" />
                                            </div>
                                        </div>
                                        {security.env !== 'prod' &&
                                        <div className="form-group add-items">
                                            <label className="col-xs-4 add-label">{__('Tax city')}<br/>
                                                <i><small className="text-muted">- {__('Optional')}</small></i>
                                            </label>
                                            <div className="col-xs-6">
                                                <CitySelector style={{width: '100%'}} value={this.state.data.city}
                                                              onChange={this.handleCityChange}/>
                                            </div>
                                        </div>
                                        }
                                    </form>
                                </div>
                                <div className="body-one">
                                    <p className="basic-header header-border">{__('Identification numbers')}</p>
                                    <form className="add-form form-horizontal">
                                        <div className="form-group add-items">
                                            <label className="col-xs-4 add-label">{__('Passport or National ID')}<br />
                                                <i><small className="text-muted">- {__('Optional')}</small></i>
                                            </label>
                                            <div className="col-xs-6">
                                                <input className="form-control" name="passport_or_national_id" value={data.passport_or_national_id} onChange={this.recordData} type="text" />
                                            </div>
                                        </div>
                                        <div className="form-group add-items">
                                            <label className="col-xs-4 add-label">{__('Employee ID')}<br />
                                                <i><small className="text-muted">- {__('Optional')}</small></i>
                                            </label>
                                            <div className="col-xs-4">
                                                <input className="form-control" name="employee_id" value={data.employee_id} onChange={this.recordData} type="text" />
                                            </div>
                                        </div>
                                        <div className="form-group add-items">
                                            <label className="col-xs-4 add-label">{__('Property ID')}<br/>
                                                <i><small className="text-muted">- {__('Optional')}</small></i>
                                            </label>
                                            <div className="col-xs-4">
                                                <input className="form-control"  name="property_id" value={data.property_id} onChange={this.recordData} type="text"/>
                                            </div>
                                        </div>
                                        <div className="form-group add-items">
                                            <label className="col-xs-4 add-label">{__('Note')}<br/>
                                                <i><small className="text-muted">- {__('Optional')}</small></i>
                                            </label>
                                            <div className="col-xs-4">
                                                <input className="form-control"  name="note" value={data.note} onChange={this.recordData} type="text"/>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <AddNewWallet addType={1} data={walletList} onChange={this.walletAddressChange} errorStatus={0} />
                                {this.state.errorStatus === 1 &&
                                    <div id="editStakeholderInputError" className="alert alert-warning alert-dismissible pink-box">
                                        <p>{__('Required field(s) or email cannot be empty or in the wrong format.')}</p>
                                    </div>
                                }
                                {this.state.duplicateError === 1 &&
                                    <div id="editDuplicateError" className="alert alert-warning alert-dismissible pink-box">
                                        <p>{__('Duplicate emails, please confirm.')}</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="modal-footer box-footer add-footer">
                            {curErrorStatus === 0 && <button type="button" onClick={this.showErrorTip} className="btn boxbtn-to width200 bg-darkblue">{__('Save')}</button>}
                            {curErrorStatus === 1 &&
                                <button type="button" onClick={this.editStakeholder} className="btn boxbtn-to width200 bg-darkblue">
                                    {this.state.sendStatus === 0 && __("Save")}
                                    {this.state.sendStatus === 1 && __("Editing")}
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default function editStakeholderModal(data, id, cost_list, cb) {
	return Modal.open({
		component: <EditStakeholder data={data} id={id} cost_list={cost_list} cb={cb}  />,
		maskClosable: true,
        width: 620,
        className: 'add-stakeholder-modal',
	})
}