import React, { Component } from 'react';
import { Switch, Route, Redirect, Link } from 'react-router-dom';
import Login from './Login';
import Signup from './Signup';
import SignupAnother from './Signup/signupAnother';
import Forget from './Forgot';
import InitPassword from './InitPassword';
import logo from './images/logo.png';
import { removeModal } from 'Utils';
import security from 'stores/security';
import { webSiteMap } from 'Utils/constant';
import { Menu, Dropdown } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import './style.scss';

class Auth extends Component {
	path = '/auth'

	render() {
		removeModal()

		const href = webSiteMap[security.env]

		return <div className="sprout-auth-root">
			<div className="sprout-auth-header-wrap">
				<div className="sprout-auth-header auth-section">
					<div className="header-menu">
						<a className="logo" href="https://folium.xyz/">
							<img width="127" src={logo} />
						</a>
						<div className="menu-wrap">
							{/* <div className="menu-item"><a href={`${href}/features/`}>{__('Features')}</a></div>
							<div className="menu-item"><a href={`${href}/the-perfect-plan-for-your-business/`}>{__('Pricing')}</a></div>
							<div className="menu-item"><a href={`${href}/partners/`}>{__('Partners')}</a></div>
							<div className="menu-item">
								<Dropdown overlay={
									<Menu>
										<Menu.Item>
											<a href={`${href}//insights/`}>{__('Insights')}</a>
										</Menu.Item>
										{
											security.env === 'prod' && <Menu.Item>
												<a href="https://www.getsprout.co/equity-calc/">Co-founder Equity Calculator</a>
											</Menu.Item>
										}
										<Menu.Item>
											<a href={`${href}/faq/`}>{__('FAQ')}</a>
										</Menu.Item>
									</Menu>
								}> 
									<span>{__('Resources')}<CaretDownOutlined /></span>
								</Dropdown>
							</div> */}
						</div>
					</div>
					<div className="header-menu-right">
						<div className="menu-item"><Link to="/auth/login">{__('Sign in')}</Link></div>
						<div className="menu-item"><Link to="/auth/signup">{__('Sign up')}</Link></div>
						{/* <div className="button">
							<a href={`${href}/requestdemo/`}>{__('Request a Demo')}</a>
						</div> */}
					</div>
				</div>
			</div>
			<div className="sprout-auth-content">
				<div className="sprout-auth-content-bg" />
				<div className="auth-section">
					<div className="sprout-auth-form">	
						<div className="sprout-auth-form-conent">	
							<Switch>
								<Route exact path={`${this.path}/login`} component={Login} />
								<Route exact path={`${this.path}/forgot`} component={Forget} />
								<Route exact path={`${this.path}/init`} component={InitPassword} />
                                <Route exact path={`${this.path}/signup`} component={Signup} />
                                <Route exact path={`${this.path}/signup-another`} component={SignupAnother} />
								<Redirect to={`${this.path}/login`} />		
							</Switch>
						</div>
					</div>
				</div>
			</div>
		</div>
	}
}

export default Auth