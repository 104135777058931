import React from "react";
import { formatDate } from 'Utils/format/formatDate';

const getValue = (value) => value ? value : '--';
const getStatus = (value) => value == 1 ? <div class="green-disc">{__('Completed')}</div> : <div class="red-disc">{__('Failed')}</div>;
export const columnsConfig = [
    {
        title: __('Order ID'),
        dataIndex: 'orderNo',
        align: 'left',
        render: value => getValue(value),
    },
    {
        title: __('Product'),
        dataIndex: 'productName',
        align: 'left',
        render: value => getValue(value),
    },
    {
        title: __('Billing date'),
        dataIndex: 'date',
        align: 'left',
        render: value => formatDate(value),
    },
    {
        title: __('Amount'),
        dataIndex: 'orderPrice',
        align: 'left',
        width: 300,
        render: value => value > 0 ? <div>${value} USD</div> : '--'
    },
    {
        title: __('Status'),
        dataIndex: 'payStatus',
        align: 'left',
        render: value => getStatus(value)
    },
];