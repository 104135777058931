import React from 'react';
class DownloadTaskModal extends React.Component {
    constructor(props){
        super(props);
        this.state={
            data:[
                {
                    file_name:"MSFFF Ventures's Stakeholder contact spreadsheet",
                    date:"02/04/2010 at 11:50 PM"
                },
                {
                    file_name:"MSLL Ventures's Stakeholder contact spreadsheet",
                    date:"02/05/2010 at 11:50 PM"
                },
                {
                    file_name:"MS Ventures's Stakeholder contact spreadsheet",
                    date:"02/02/2010 at 11:50 PM"
                },
            ]
        }
    }
    removeTask=(key)=>{
        let curData = this.state.data;
        curData.splice(key,1);
        this.setState({
            data:curData
        })
    }
    render(){
        let curData = this.state.data;
        return(
            <div className="modal fade" id="downloadModal" tabIndex="-1" role="dialog"
                 aria-hidden="true">
                <div className="modal-dialog dialog520 dialog-top40">
                    <div className="modal-content re-content">
                        <div className="modal-header box-header">
                            <button type="button" className="close" data-dismiss="modal"
                                    aria-hidden="true">x
                            </button>
                            <p className="modal-title" id="myModalLabel">{__('Downloads')}</p>
                        </div>
                        <div className="modal-body re-body">
                            {this.state.data.length===0 &&
                                <div className="noTask">
                                    <p>{__('No downloads available')}</p>
                                </div>
                            }
                            {this.state.data.map((value,key)=>{
                                    return(
                                        <div key={key} className="tasksBox">
                                            <p>{value.file_name}</p>
                                            <small className="text-muted">{value.date}&nbsp;&nbsp; <span><button onClick={this.removeTask.bind(this,key)} className="text-blue">{__('remove')}</button></span></small>
                                            <button className="btn download bg-darkblue downloadBtn" type="button">{__('Download')}</button>
                                        </div>
                                    )
                            })}

                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
export default DownloadTaskModal;