import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import List from './notificationList'

class BoardNotifications extends Component {
	path = this.props.match.path

	render () {
		return <div className="board-consents-root" >
			<Switch>
				<Route exact path={`${this.path}/list`} component={List} />
				<Redirect to={`${this.path}/list`} />
			</Switch>
		</div>
	}
}

export default BoardNotifications;