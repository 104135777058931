import React, { Component } from "react";
import {Button, Input, message, Select, Spin, Table} from "antd";
import { post } from 'srcPath/http';
import Utils, { I } from 'Utils';
import CountrySelector from 'components/CountrySelector';
import moment from "moment";
import DateSelector from 'components/DateSelector';
import { formatDate } from 'Utils/format/formatDate'
import { formatDate2Api, formatDateForDatePicker } from 'Utils/format/formatDate';
import Loading from 'components/Loading';

class InformationModels extends Component {
    state = {
        desc : __('Compare the stakeholder details below and enter the updated values in the cells on the right. Click next to review your updated stakeholder information.'),
        columns : [
            {
                title : '',
                align : 'left',
                dataIndex : 'attr',
                key : 'attr',
                width : '23%',
                render: (text) => (
                    <div className="table-attr">{text}</div>
                )
            },
            {
                title : __('Stakeholder 1'),
                align : 'left',
                dataIndex : 'stakeholderA',
                key : 'stakeholderA',
                width : '25%',
                render: (text) => {
                    const { data } = this.state;
                    return <span>{text[data[0].id]}</span>
                }
            },
            {
                title : __('Stakeholder 2'),
                align : 'left',
                dataIndex : 'stakeholderB',
                key : 'stakeholderB',
                width : '25%',
                render: (text) => {
                    const { data } = this.state;
                    return <span>{text[data[1].id]}</span>
                }
            },  
            {
                title : __('Updated Stakeholder'),
                align : 'left',
                dataIndex : 'stakeholderC',
                width : '27%',
                className : 'merge-stakeholder-c',
                render: (text, record, index) => {
                    return <div className={`merge-stakeholder-c-box ${record.type === 7 && 'merge-stakeholder-securities'} ${!!record.isError && 'merge-stakeholder-c-red'}`}>{this.stakeholderCValue(text, record, index)}</div>
                },
            },
        ],
        tableList : [],
        id: '',
        mergeId: '',
        defaultRelationship: [ { effective_date: formatDate2Api(moment()) } ],
    }

    stakeholderCValue = (text, record, index) => {
        const { data } = this.state;
        const { stakeholdersType, genderList } = this.props;
        const { stakeholderA, stakeholderB } = record;
        let html = '';
        switch (record['type']){
            case 1:
                html = <Input className="can-input color808080" bordered={false} onChange={this.mergeInputChange.bind(this, index)} value={record.stakeholderC} />
                break;
            case 2:
                html = <DateSelector
                    allowClear={false}
                    onChange={this.handleFinancingDateChange.bind(this, index)}
                    value={record.stakeholderC}
                    suffixIcon={false}
                    inputReadOnly={true}
                    bordered={false}
                    className="date-calendar-with10padding calender-positionReset datepicker vesting_start_datepicker mr-6 w100 ml10 color808080"
                />
                break;
            case 3:
                html = <CountrySelector
                    className="w100 ml10 color808080"
                    bordered={false}
                    value={record.stakeholderC}
                    onChange={this.mergeSelectChange.bind(this, index)}
                />
                break;
            case 4:
                html = <Select
                    className="w100 ml10 color808080"
                    bordered={false}
                    onChange={this.mergeSelectChange.bind(this, index)}
                    value={record.stakeholderC}
                >
                    { stakeholdersType.map( (value) => <Select.Option value={value.name} key={value.type}>{value.name}</Select.Option> ) }
                </Select>
                break;
            case 5:
                html = <Select
                    className="w100 ml10 color808080"
                    bordered={false}
                    onChange={this.mergeSelectChange.bind(this, index)}
                    value={record.stakeholderC}
                >
                    { genderList.map( (value) => <Select.Option value={value.name} key={value.name}>{value.name}</Select.Option> ) }
                </Select>
                break;
            case 6:
                html = <Select
                    className="w100 ml10 color808080"
                    bordered={false}
                    onChange={this.initStakeholderC.bind(this, index)}
                    value={record.stakeholderC}
                >
                    <Select.Option value={data[0].id}>{I(stakeholderA[data[0].id], 'Pending')}</Select.Option>
                    <Select.Option value={data[1].id}>{I(stakeholderB[data[1].id], 'Pending')}</Select.Option>
                </Select>
                break;
            case 7:
                html = <div className="merge-stakeholder-txt-left">{record.stakeholderC}</div>
                break;
            default:
        }
        return html;
    }

    /**
     * 初始化表格
     * @returns {Promise<void>}
     */
    async componentDidMount() {
        const { holderLists } = this.props;
        //获取已选择的stakeholders
        const ids = this.getUseStakeholders(holderLists);

        Loading.global(true)
        try {
            const { data: resData } = await post('getMergeStakeholderInfo', {
                stakeholder_id1 : ids[0],
                stakeholder_id2 : ids[1]
            })
            const { code, data } = resData;
            if (code === 0) {
                //组合table所用到的数据
                this.combinedData( data);
            } else {
                message.error(data)
            }
        } catch (err) {
            message.error(err && err.message)
        }

        Loading.global(false)
    }

    /**
     * 获取已选择的stakeholders ID
     * @param holderLists
     * @returns {[]}
     */
    getUseStakeholders = (holderLists) => {
        let resList = [];
        holderLists.forEach( (v, k) => {
            if(I(v.status, 0) == 1){
                resList.push(v.id);
            }
        })
        return resList;
    }

    /**
     * 组合table可展示的数据
     */
    combinedData = (data) => {
        const { attr, stakeholderData, stakeholdersType } = this.props;
        data.forEach( (v, k) => {
            data[k].email = I(data[k].email, 'Pending');
            stakeholdersType.forEach((v1, k1) => {
                if(v1.type == v.stakeholderType){
                    data[k]['stakeholderTypeValue'] = v1.name;
                }
            })
        })
        let tableList = [];
        attr.forEach( (v, k) => {
            let stakeholderC = stakeholderData[v.stakeholderDataKey];
            if(v.stakeholderDataKey === 'securities'){
                const securitiesArray = [];
                data[0][v.key] && securitiesArray.push(data[0][v.key]);
                data[1][v.key] && securitiesArray.push(data[1][v.key]);
                stakeholderC = I(stakeholderData[v.stakeholderDataKey], '') ? stakeholderData[v.stakeholderDataKey] : securitiesArray.join(',');
            }
            const dateA = formatDate(I(data[0][v.key], ''));
            const dateB = formatDate(I(data[1][v.key], ''));
            const stakeholderA = { [data[0].id]: v.type == 2 ? (dateA === '--' ? '' : dateA) : I(data[0][v.key], '') }
            const stakeholderB = { [data[1].id]: v.type == 2 ? (dateB === '--' ? '' : dateB) : I(data[1][v.key], '') }
            tableList.push({
                key : v.key,
                stakeholderDataKey : v.stakeholderDataKey,
                attr : v.name,
                stakeholderA,
                stakeholderB,
                stakeholderC,
                type : v.type,
                isRequired: !!v.isRequired,
                checkType: v.checkType
            });
        });
        this.setState(() => ({
            tableList,
            loading : false,
            data,
            id: I(stakeholderData.id, ''),
        }))
    }

    /**
     * 表格input通用change事件
     * @param index
     * @param e
     */
    mergeInputChange = (index, e) => {
        const { tableList } = this.state;
        let mergeInfo = [...tableList];
        mergeInfo[index]['stakeholderC'] = e.target.value;
        this.setState(() => ({
            tableList : mergeInfo
        }));
    }

    /**
     * 表格select通用change事件
     * @param index
     * @param value
     * @param data
     */
    mergeSelectChange = (index, value, data) => {
        const { tableList } = this.state;
        let mergeInfo = [...tableList];
        mergeInfo[index]['stakeholderC'] = value;
        this.setState(() => ({
            tableList : mergeInfo
        }));
    }

    /**
     * 通过用户选择的stakeholder来初始化数据
     * AP-1901
     */
    initStakeholderC = (index, value) => {
        const { tableList, data } = this.state;
        let newTableList = [...tableList];
        const dataIndex = data.findIndex(v=>v.id == value);
        const stakeholderKey = dataIndex !== -1 && dataIndex == 0 ? 'stakeholderA' : 'stakeholderB';

        newTableList.forEach((v, k)=>{
            if(v.key !== 'securities'){
                const dataValue = newTableList[k][stakeholderKey][value];
                newTableList[k].stakeholderC = v.type !== 2 ? dataValue : ( dataValue ? moment(dataValue) : '' );
                newTableList[k].isError = false;
            }
        })
        this.setState(() => ({
            tableList : newTableList,
            id: value,
            mergeId: (dataIndex == 1 ? data[0].id : data[1].id)
        }));
    }

    /**
     * 表格通用日历change事件
     * @param index
     * @param date
     */
    handleFinancingDateChange = (index, date) => {
        const { tableList } = this.state;
        let mergeInfo = [...tableList];
        mergeInfo[index]['stakeholderC'] = moment(date);

        this.setState(() => ({
            tableList : mergeInfo
        }));
    }

    /**
     * 验证填写信息、整合数据、打开第二录入页
     */
    saveFirstData = () => {
        //验证填写信息
        if(!this.checkRequest()){
            return;
        }
        //整合数据并保存
        this.mergeRequestData();
    }

    /**
     * 验证填写内容
     */
    checkRequest = () => {
        const { tableList } = this.state;
        const { holderLists } = this.props;
        const ids = this.getUseStakeholders(holderLists);
        let isError = 0;
        //验证 name,email 是否填写
        for(let i=1; i<4; i++){
            const { stakeholderC, key, stakeholderA, stakeholderB } = tableList[i];
            if(!I(stakeholderC, '')){
                if(key == 'securities'){
                    if(!I(stakeholderA?.[ids[0]], '') && !I(stakeholderB?.[ids[1]], '')){
                        tableList[i].isError = false;
                    }else{
                        tableList[i].isError = true;
                        isError++;
                    }
                }else{
                    tableList[i].isError = true;
                    isError++;
                }
            }else{
                tableList[i].isError = false;
            }

        }
        this.setState({tableList: [...tableList]});
        return !isError;
    }

    /**
     * 整合当前页面填写的数据
     * 保存并跳转下一页
     */
    mergeRequestData = () => {
        const { tableList, id, mergeId, data: newData } = this.state;
        const { mergeRequestData, close,getRelationshipModels } = this.props;
        let data = {id, mergeId};
        tableList.forEach( (v, k) => {
            data[v.stakeholderDataKey] = v.stakeholderC;
        });
        //relationship, jobTitle
        const index = newData.findIndex(v=>v.id==id);
        if(index === -1){
            message.error('Portfolio email is error!');
            return;
        }
        data = this.initRelationshipJobTitle(data, newData, index);
        mergeRequestData(data, ()=>{
            //打开第二录入页
            getRelationshipModels();
            close();
        });
    }

    initRelationshipJobTitle = (data, newData, index) => {
        const { defaultRelationship } = this.state;
        let relationship = I(newData[index].relationship, defaultRelationship);
        let jobTitle = I(newData[index].job_title, defaultRelationship);
        relationship && relationship.forEach((v, k)=>{
            relationship[k].effective_date = formatDateForDatePicker(v.effective_date);
        });
        jobTitle && jobTitle.forEach((v, k)=>{
            jobTitle[k].effective_date = formatDateForDatePicker(v.effective_date);
        });
        data.relationship = relationship;
        data.job_title = jobTitle;
        return data;
    }

    /**
     * 关闭model
     */
    closeModels = () => {
        const { close, initStakeHolderData } = this.props;
        initStakeHolderData();
        close();
    }

    render() {
        const { desc, columns, tableList } = this.state;
        return (
            <>
                <div className="merge-stakeholder-header">
                    <h5 className="title">{__("Merge Stakeholders' Information")}</h5>
                    <div className="close " onClick={this.closeModels} >×</div>
                </div>
                <div className="merge-stakeholder-content new-merge-stakeholder-content">
                    <dl>
                        <dt>{desc}</dt>
                        <dd>
                            <Table
                                className="table"
                                pagination={false}
                                columns={columns}
                                dataSource={tableList}
                                rowKey="key"
                            />
                        </dd>
                    </dl>
                </div>
                <div className="information-w100">
                    <Button className="information-button" type="primary" onClick={this.saveFirstData}>{__('Next: correct relationship history')}</Button>
                </div>
            </>
        );
    }
}
export default InformationModels;