import React, { Component } from 'react';
import { Progress } from 'antd';
import moment from 'moment';
import { formatDate } from "Utils";
import { printf } from 'Utils/i18n';

import './index.scss';
class ProgressCom extends Component {
	render () {
        const { status, progress = 0, userList } = this.props.data;
		return <div className="board-consents-progress" >
			<Progress
                strokeColor={{
                    from: '#B9CCD1',
                    to: '#00A9CD',
                }}
                percent={progress.toFixed(2) * 100}
                status="active"
            />
            
            { status == '1' &&
                <p className="board-consents-progress-content">{__('Consent in draft')}</p> 
            }
            { status == '2' &&
                <p className="board-consents-progress-content">{userList.length} {__('approvers pending')}</p> 
            }
            { status == '3' &&
                <p className="board-consents-progress-content">{printf(__('Consent approved on %s by %s approvers'), formatDate(this.props.data.approval_date * 1000), userList.length)}</p>
            }
            { status == '4' &&
                <p className="board-consents-progress-content">{printf(__('Consent canceled on %s by %s'), formatDate(this.props.data.cancel_date * 1000), this.props.data.cancel_by)}</p> 
            }
            { status == '6' &&
                <p className="board-consents-progress-content">{printf(__('Consent expired on %s'), moment(this.props.data.expiration_date).format('DD/MM/YYYY'))}</p> 
            }
		</div>
	}
}

export default ProgressCom;
export { ProgressCom as Progress }