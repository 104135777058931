import React, { Component, Fragment } from "react";
import {Input, Button, message} from 'antd';
import Utils, { I } from 'Utils';
import PwdRisk from 'components/PwdRisk';
import Background from '../Public/img/create-account-background.png';
import "../CreateAccount/style.scss";

/**
 * signup => create account
 */
class CreateAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputList: [
                { title: 'Email', key: 'email', type: 'text', onBlur: 'password', desc: '', errorValue: 'Please input your email.', rules: ['required'], disabledRes: true },
                { title: 'Password', key: 'password', type: 'password', onBlur: 'confirmPassword', desc: '', errorValue: 'Please input your password.', rules: ['required'] },
                { title: 'Password', key: 'confirmPassword', type: 'password', onBlur: 'companySize', desc: '', errorValue: 'Please make sure the passwords are the same.', rules: [] },
                //{ title: 'Company Size', key: 'companySize', type: 'number', desc: '', errorValue: 'Please input company size.', rules: ['required'] },
            ],
            checkPasswordType: false,
            passwdError: '8 character at least, 1 number, 1 upper case, 1 lower case, 1 special character',
        }
    }

    /**
     * name,password change事件
     * @param e
     * @param key
     */
    inputChange = (key, e) => {
        const { inputList, passwdError } = this.state;
        const { updateAccount, accountInfo } = this.props;
        let data = {...accountInfo};
        let list = [...inputList];
        data[key] = e.target.value;
        updateAccount(data);
        if(key == 'password'){
            if(this.checkPassword(data[key])){
                list[1]['desc'] = '';
                this.setState({
                    inputList: list,
                    checkPasswordType : true,
                })
            }else{
                list[1]['desc'] = passwdError;
                this.setState({
                    inputList: list,
                    checkPasswordType : true,
                })
            }
        }
    }

    /**
     * 切换指定 input 焦点（因浏览器input自动填充问题）
     * @param key
     */
    onBlurChange = (key, e) => {
        const that = this;
        if(I(key, '')){
            if(e.keyCode == 9){
                setTimeout(function (){
                    const input = that.refs[key];
                    input.focus();
                },1);
            }
        }
    }

    /**
     * 验证password是否合法
     * @param key
     * @param value
     */
    onBlurPwd = (key, value) => {
        const { inputList, passwdError } = this.state;
        let list = [...inputList];


        if(key == 'password'){
            if(this.checkPassword(value)){
                list[1]['desc'] = '';
                this.setState({
                    checkPasswordType : false,
                    inputList: list,
                })
            }

        }
    }

    checkPassword = value => {
        return (!value || value.length < 8 || PwdRisk.judgeRisk(value) <= 2.5  ) ? false : true;
    };

    /**
     * 设置用户密码
     */
    subSignup = () => {
        const { subSignup } = this.props;
        if(this.checkInput()){
            subSignup();
        }
    }

    /**
     * 验证表单信息
     */
    checkInput = () => {
        const { accountInfo } = this.props;
        const { inputList, passwdError } = this.state;
        let inputSetting = [...inputList], error = 0;
        inputSetting[1]['desc'] = inputSetting[2]['desc'] = '';
        console.log(accountInfo);
        inputSetting.map( ( v, k) => {
            if((v.rules.indexOf('required')) >= 0){
                if(!I(accountInfo[v.key], '')){
                    inputSetting[k]['desc'] = v.errorValue;
                    error++;
                }else{
                    inputSetting[k]['desc'] = '';
                }
            }
        })
        if(!this.checkPassword(accountInfo['password'])){
            inputSetting[1]['desc'] = passwdError;
            error++;
        }
        if(accountInfo['password'] != accountInfo['confirmPassword']){
            inputSetting[2]['desc'] = inputSetting[2]['errorValue'];
            error++;
        }
        this.setState(()=>({
            inputList: inputSetting
        }));
        if(error){
            return false;
        }
        return true;
    }

    /**
     * 表单内容
     * @returns {*}
     */
    getInputList = () => {
        const { accountInfo } = this.props;
        const { inputList, checkPasswordType } = this.state;
        return inputList.map( (v, k) => {
            const LabelInput = v.type == 'password' ? Input.Password : Input;
            return (
                <div key={k} style={{marginBottom: v.desc ? '14px' : '26px'}}>
                    <div className="signup-content-input-box" style={{borderColor: v.desc ? '#FF4D4F' : '#DCDCDC'}}>
                        <Input type={v.type} bordered={false} className="signup-content-input-hidden" />
                        {
                            <LabelInput
                                className={['signup-content-input',(accountInfo[v.key] && 'filled')].join(' ')}
                                ref={v.key}
                                type={v.type}
                                placeholder=""
                                id={v.key}
                                bordered={false}
                                value={accountInfo[v.key]}
                                onChange={this.inputChange.bind(this, v.key)}
                                onKeyDown={this.onBlurChange.bind(this, v.onBlur)}
                                onBlur={this.onBlurPwd.bind(this, v.key, accountInfo[v.key])}
                                disabled={I(v.disabledRes, false)}
                            />
                        }
                        <label className="signup-content-placeholder" htmlFor={v.key}>{v.title}</label>
                        <Input type={v.type} bordered={false} className="signup-content-input-hidden" />
                    </div>
                    <p className={['signup-content-input-desc', v.desc ? 'signup-create-input-mt12' : ''].join(' ')}>{v.desc}</p>
                    {v.key == 'password' &&
                    <div className={['signup-create-password', checkPasswordType ? 'signup-create-password-show' : '' ].join(' ')} >
                        <PwdRisk  password={accountInfo[v.key]}/>
                    </div>}
                </div>
            );
        });
    }

    render() {
        const { buttonLoading } = this.props;
        return (
            <div className="signup-content">
                <div className="signup-content-left">
                    <dl>
                        <dt>Create Your <span>Company Account</span></dt>
                        <dd>{this.getInputList()}</dd>
                        <dd className={['create-button-background', (buttonLoading && 'button-loading')]. join(' ')}>
                            <Button loading={buttonLoading} onClick={this.subSignup} type="primary" className="create-button" >Register</Button>
                        </dd>
                    </dl>
                </div>
                <div className="signup-content-right">
                    <img src={Background} />
                </div>
                <div className="clear"></div>
            </div>
        );
    }
}

export default CreateAccount;