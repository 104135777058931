import React, { useState, useEffect } from 'react';
import Modal, { ModalContent } from 'components/Modal';
import { Button, Select, Spin, message } from 'antd';
import { post } from 'srcPath/http';

import './style.scss';

const Option = Select.Option

function UpdateTags(props) {
	const { data = {}, close, callback } = props;
	const { id, tag } = data
	const [ loading, setLoading ] = useState(false)
	const [ tagsOptions, setTagsOptions ] = useState([])
	const [ selectedTag, setSelectedTag ] = useState(tag || [])

	useEffect(() => {
		async function getTags() {
			setLoading(true)

			try {
				const { data: resData } = await post('getCoinTransactionTags')
				const { code, data } = resData || {}
				const { tags = [] } = data || {}

				if (code === 0) {
					setTagsOptions(tags)
				}
			} catch(e) {
				message.error(e.message)
			}

			setLoading(false)
		}

		getTags()
	}, [])

	const handleChange = value => {
		setSelectedTag(value)
	}

	const upDateTags = async () => {
		setLoading(true)

		try {
			await post('updateCoinTransactionTags', {
				id,
				tags: selectedTag
			})


			callback && callback()
			close()
		} catch(err) {
			message.error(err.message)
		}
	}

	return <div className="coin-transaction-updatetags">
		<Spin spinning={loading}>
			<div className="coin-transaction-updatetags-content">
				<div className="coin-transaction-updatetags-label">Tags</div>
				<Select value={selectedTag} onChange={handleChange} mode="tags">
					{
						tagsOptions.map(val => (<Option key={val} value={val}>{val}</Option>))
					}
				</Select>
			</div>
			<div className="coin-transaction-updatetags-btn">
				<Button onClick={() => close()}>Cancel</Button>
				<Button type="primary" onClick={() => upDateTags()}>Confirm</Button>
			</div>
		</Spin>
	</div>
}

export function openUpdateModal(data, callback) {
	return Modal.open({
		component: ({ close }) => <ModalContent showClose={false} title="Update Tags">
			<UpdateTags data={data} close={close} callback={callback} />
		</ModalContent>,
		className: 'sprout-modal',
		width: 600
	})
}