import React, { Component } from "react";
import {Button, Card} from "antd";
import SuccessIcon from "../../img/success-icon.jpg";
import ErrorIcon from "../../img/error-icon.jpg";
import url from "url";
import { I } from "Utils";
import "./style.scss";


class CallBack extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: 0,
            notifyHtml: [
                {
                    boxClass: ['signup-notify-box'],
                    imgSrc: ErrorIcon,
                    title: 'Your payment failed',
                    titleClass: 'signup-notify-error-title',
                    buttonValue: 'Try again'
                },
                {
                    boxClass: ['signup-notify-box'],
                    imgSrc: SuccessIcon,
                    title: 'Payment Successful',
                    titleClass: 'signup-notify-success-title',
                    buttonValue: 'Back'
                }
            ]
        }
    }

    getUrl = () => {
        const { type } = this.state;
        const { getPage, history } = this.props;
        if(type == 1){
            history.push('/home/setting/billing');
        }else{
            getPage(0);
        }
    }

    componentDidMount() {
        const { notifyHtml } = this.state;
        const { query } = url.parse( window.location.search, true);
        const type = I(query.type, 0);
        notifyHtml[type].boxClass.push('notify-box-show');
        this.setState({type, notifyHtml: [...notifyHtml]})
    }

    render() {
        const { notifyHtml } = this.state;
        return (
            <Card className="signup-notify-root">
                {
                    notifyHtml.map( (v, k) => {
                        return (
                            <div key={k} className={v.boxClass.join(' ')}>
                                <img className="signup-notify-success-icon" src={v.imgSrc} />
                                <div className={v.titleClass}>{v.title}</div>
                                <div className="signup-notify-button-bak">
                                    <Button onClick={this.getUrl.bind(this)} type="primary" className="signup-notify-button">{v.buttonValue}</Button>
                                </div>
                            </div>
                        )
                    })
                }
            </Card>
        );
    }
}
export default CallBack;