import React from "react";

export const errorPrompt = {

    nameEmptyError: 'Name is required',

    titleEmptyError: 'Title is required',

    companyNameEmptyError: 'Company name is required',

    companySizeEmptyError: 'Company size is required',

    codeEmptyError: 'Verification code is required',
    codeError: 'Verification code is error',

    emailEmptyError: 'Email is required',
    emailInvalidError: 'Email is invalid',
    emailDuplicateError: 'This account already exists. If you forgot your password, you can use "Forgot password" on the Sign in page and directly reset your password to access your account.',

    passwordEmptyError: 'Password is required',
    passwordConfirmError: 'Please confirm your password',
    passwordError: '8 character at least, 1 number, 1 upper case, 1 lower case, 1 special character',
    passwordNotMatchError: 'The two passwords that you entered do not match',
    passwordIncorrectError: 'The email address or password is incorrect!',
};