import React from "react";
import { post } from 'srcPath/http';

class AddAdminNotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                id:"",
                admin_note:"",
            },
            listName:"",
        }
    }
    recordData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        });
    }
    setListName=(val)=>{
        this.setState({
            listName:val
        })
    }
    setInitNote=()=>{
        let data = {admin_note:this.props.curSelectSecurity.notes}
        this.setState({
            data:data,
        });
    }
    addSharesAdminNote=(listName,key)=>{
        var requestData = {};
        requestData.admin_note = this.state.data.admin_note;
        requestData.id = this.props.curSelectSecurity.id;
        post('addAdminNote',requestData).then((response)=> {
            if(response.data.code === 0){
                window.$("#addAdminNotesModal").modal("hide");
                let targetShareInfo = this.props.curSelectSecurity;
                targetShareInfo.notes = this.state.data.admin_note;
                // this.props.setModifiedSecurity(this.props.curSelectSecurity.key,targetShareInfo,this.state.listName);
                this.props.setDataForModifiedSecurity(requestData.id,targetShareInfo,this.state.listName);
                this.setState({
                    data:{admin_note:""}
                });
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    render(){
        return(
            <div className="modal fade" id="addAdminNotesModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="modal-dialog dialog-top40">
                    <div className="tab-content">
                        <div className="modal-content content480">
                            <div className="modal-header box-header">
                                <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                                <h4 className="modal-title" id="myModalLabel">{__('Admin notes')}</h4>
                            </div>
                            <div className="modal-body padding2030">
                                <textarea name="admin_note" value={this.state.data.admin_note} onChange={this.recordData} placeholder= {__("This note will be visible on Certificate details under Documents and Notes section.") } className="form-control text-area minHeight140" type="text" />
                            </div>
                            <div role="presentation" className="modal-footer box-footer">
                                <a  onClick={this.addSharesAdminNote} className="btn boxbtn-to bg-darkblue">{__('Save admin notes')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default AddAdminNotes;