import React, { useEffect, useState } from 'react';
import { Button, Space, Badge } from 'antd';
import Modal from 'components/Modal';
import { withForm, useForm } from 'react-antd-formutil';
import filterIcon from './images/filter.png';
import { filterCount } from './config';
import './style.scss';

// type添加类型
import Allstakeholder from './Allstakeholder';
import Option from './Option';
import Transaction from './Transaction';
import Rsas from './Rsas';
import Rsus from './Rsus';
import Sars from './Sars';
import Shares from './Shares';
import Warrants from './Warrants';
import Convertibles from './Convertibles';
import Vesting from './Vesting';
import BillingHistory from './BillingHistory';

const formType = {
    stakeholder: <Allstakeholder />,
    option: <Option />,
    transaction: <Transaction />,
    rsas: <Rsas />,
    rsus: <Rsus />,
    sars: <Sars />,
    shares: <Shares />,
    warrants: <Warrants />,
    convertibles: <Convertibles />,
    vesting: <Vesting />,
    billingHistory: <BillingHistory />,
}

function FilterContent(props) {
    const { $params = {}, $setValues, $reset } = useForm()
    const { filterData = {}, close, callback, type } = props;

    useEffect(() => {
        $setValues(filterData)
    }, [])

    const reset = () => {
        $reset();
        callback?.({})
        close?.()
    };

    const apply = () => {
        callback?.({ ...$params });
        close?.();
    }

    return <div className="filter-rooter">
        {formType[type]}
        <div className="fiter-btns">
            <Space>
                <Button type="primary" onClick={reset}>{__('Clear all filters')}</Button>
                <Button type="primary" onClick={apply}>{__('Apply')}</Button>
            </Space>
        </div>
    </div>
}

const FilterForm = withForm(FilterContent)

function openFilter(filterData, callback, type) {
    return Modal.open({
        component: <FilterForm filterData={filterData} callback={callback} type={type} />,
        width: 760,
        className: "sprout-modal",
        maskClosable: true
    })
}

// callback、 type 必传
export default function Filter(props) {
    const { fiterData = {}, callback, type } = props
    const [data, setData] = useState(fiterData)
    const onCallback = data => {
        setData(data);
        callback?.(data)
    }
    return <Badge count={filterCount(data)} size="small">
        <Button type="primary" onClick={() => { openFilter(data, onCallback, type) }}>
            <img height="24" src={filterIcon} alt="icon" />
        </Button>
    </Badge>
}