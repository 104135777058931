import React, { Component } from 'react'
import Modal from 'components/Modal';
import './style.scss'
import {Button, message} from "antd";
import { post } from 'srcPath/http';

class ReverseTermination extends Component{
    constructor(props){
        super(props);
        this.state = {
            loading:false
        }
    }

    closeModels = () => {
        const { close } = this.props;
        close && close()
    }

    handleReverseTermination = async (securityId, securityType) => {
        this.setState({loading:true})
        try {
            const {getSecurityList, resetSecurityStatus, close } = this.props;
            const { data: resData } = await post('reverseTermination',{ id : securityId, security_type: securityType })
            const { data, code } = resData || {}
            if (code == 0) {
                getSecurityList && getSecurityList();
                close && close();
                // resetSecurityStatus(securityId, data.status, 1);
            } else {
                message.error(data)
            }
        } catch(err) {
            message.error(err && err.message)
        }
        this.setState({loading:false})
    }

    render(){
        const { data } = this.props
        const { loading } = this.state
        return(
            <div className="modal-content termination-modal">
                <div className="modal-header box-header">
                    <button type="button" className="close shut-btn"  onClick={this.closeModels}>×</button>
                    <h4 className="modal-title" >{__('Confirm')}</h4>
                </div>
                <div className="modal-body text-center">
                    <p className="text-muted">{__('Are you sure you want to reverse terminate')} {data.custom_label_id}?</p>
                </div>
                <div className="modal-footer box-footer">
                    <Button type={'primary'} onClick={this.closeModels} className="btn width120">{__('No, cancel')}</Button>
                    <Button type={'primary'} loading={loading} className="btn width120" onClick={this.handleReverseTermination.bind(this,data.id, data.security_type)} >{__('Yes, confirm')}</Button>
                </div>
            </div>
        )
    }
}
export default function reverseTerminationModal(data, id, getSecurityList) {
    return Modal.open({
        component: <ReverseTermination data={data} id={id} getSecurityList={getSecurityList}/>,
        maskClosable: true,
        width: 520,
        className: 'add-stakeholder-modal',
    })
}