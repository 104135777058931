import React, { Component } from 'react';
import { Timeline, Button, Steps } from 'antd';
import { Link } from "react-router-dom";
import { LeftOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import './index.scss';

const { Step } = Steps;


class TimeLineCom extends Component {
    step = this.props.step;
    type = this.props.type;
    

	render () {
        const isTemplate = this.type === 'template';
		return <div className="board-consents-left-wrapper">
            <div className="back-btn-area">
                <Button><LeftOutlined /><Link to="/home/board/consents/list">{__('Back to consents')}</Link></Button>
            </div>
            <div className="time-line-wrapper" >
                <ul className="step-list-content">
                    {isTemplate && <li className={classnames({ 'finish': this.step > 0, 'current': this.step == 0 })}>1
                    <span>{__('Select template')}</span>
                    </li>}
                    <li className={classnames({ 'finish': this.step > 1, 'current': this.step == 1 })}>{isTemplate ? 2 : 1}<span>{__('Edit content')}</span></li>
                    <li className={classnames({ 'finish': this.step > 2, 'current': this.step == 2 })}>{isTemplate ? 3 : 2}<span>{__('Settings')}</span></li>
                    <li className={classnames({ 'finish': this.step > 3, 'current': this.step == 3 })}>{isTemplate ? 4 : 3}<span>{__('Publish')}</span></li>
                    
                </ul>

            </div>
        </div>
	}
}

export default TimeLineCom;