import React, { Component, Fragment } from "react";
import {Button, Col, Row, Space, Table} from "antd";
import tableAttr from "../img/table-attr.png";
import downArrow from "../img/down-arrow.png";
import {calculator, formatNumber, I, formatCurrencyNumber} from "Utils";
import {columnsA, columnsB} from "../conf/column-config";
import ExportExcel from "./ExportExcel";
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import { printf } from 'Utils/i18n';
import FinancingEcharts  from "../components/FinancingEcharts/index"

class CapitalizationBreakdown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            maxNumber: 5,
            cnTitle: ['SAFEs', 'Convertible debt', 'Convertible security'],
            data: props.data,
            sharesList: [],
            sharesTotal: [],
            myEcharts: [],
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const { data } = this.props;
        const { stakeholderData, active, financingData } = data;
        if(active == 2){
            const sharesList = this.stakeholderDataList(active, stakeholderData, financingData);
            const sharesTotal = this.shareClassTotalList(active);
            sharesList && this.setState({sharesList, sharesTotal});
        }
    }

    /**
     * 组合 stakeholder, shares 列表数据
     * @param active
     * @param stakeholderData
     * @param financingData
     * @returns {*[]}
     */
    stakeholderDataList = (active, stakeholderData, financingData) => {
        const { maxNumber, isMultiple } = this.state;
        const { add } = calculator;
        const endIndex = I(financingData.length, 0) ? financingData.length - 1 : 0;
        //加载新轮统计数据 listB
        let newStakeholderData = stakeholderData && JSON.parse(JSON.stringify(stakeholderData)) || [];
        financingData = JSON.parse(JSON.stringify(financingData));
        newStakeholderData.forEach((v, k)=>{
            financingData.forEach((v1, k1)=>{
                const { stakeholderListB, sharesListB } = v1;
                const list = (active == 1 ? stakeholderListB : sharesListB);
                list && list.forEach((v2)=>{
                    if((v2.key == v.key && v2.isNew) || (v2.id == v.id && !v2.isNew)){
                        for(let i in v2){
                            newStakeholderData[k][`${i}${k1}`] = v2[i];
                        }
                    }
                })
            })
        });
        const financingDataLen = financingData.length || 0;
        newStakeholderData.forEach((v, k)=>{
            let show = 0;
            for(let i=0;i<financingDataLen;i++){
                v?.[`shares${i}`] > 0 && (show = 1);
            }
            !show && newStakeholderData.splice(k, 1);
        });

        //排序
        newStakeholderData.sort( (x, y) => parseFloat(y[`sortNo${endIndex}`]) - parseFloat(x[`sortNo${endIndex}`]) );
        if(active == 1){
            //拆分stakeholder列表，多出的放入other折叠层中
            let newList = [], other = [], stakeholderOwnershipTotal = 0, stakeholderSharesTotal = 0, valuationTotal = 0;
            newStakeholderData.forEach((v, k)=>{
                const { quantity, ownership, valuation } = v;
                if(k < maxNumber){
                    newList.push(v)
                }else{
                    other.push(v);
                    stakeholderSharesTotal = add(stakeholderSharesTotal, I(quantity, 0));
                    stakeholderOwnershipTotal = add(stakeholderOwnershipTotal, I(ownership, 0));
                    valuationTotal = add(valuationTotal, I(valuation, 0));
                }
            })
            const newListObjAttr = this.getStakeholderOtherTotal(other, I(financingData.length, 0));
            newList.push({
                id: 0, key: -1,
                name: "Other",
                ownership: stakeholderOwnershipTotal,
                quantity: stakeholderSharesTotal,
                valuation: valuationTotal,
                children: other,
                ...newListObjAttr,
            });
            newStakeholderData = newList;
        }else{
            //获取by share class convertibles折叠层数据
            const modeledRound = this.convertibles(financingData, newStakeholderData);
            newStakeholderData.push(modeledRound);
        }
        return newStakeholderData;
    }

    getStakeholderOtherTotal = (otherList, financingDataCount) => {
        const { add } = calculator;
        let returnObj = {};
        otherList.forEach((v)=>{
            for(let i=0;i<financingDataCount;i++){
                returnObj[`shares${i}`] = add(I(returnObj[`shares${i}`], 0), I(v[`shares${i}`], 0));
                returnObj[`ownership1${i}`] = add(I(returnObj[`ownership1${i}`], 0), I(v[`ownership1${i}`], 0));
                returnObj[`ownership2${i}`] = add(I(returnObj[`ownership2${i}`], 0), I(v[`ownership2${i}`], 0));
                returnObj[`valuation${i}`] = add(I(returnObj[`valuation${i}`], 0), I(v[`valuation${i}`], 0));
            }
        })
        return returnObj;
    }

    /**
     * 获取 by share class convertibles 折叠层数据
     * @param financingData
     * @param newStakeholderData
     */
    convertibles = (financingData, newStakeholderData) => {
        //初始化后台才能执行
        if( newStakeholderData.findIndex( v => I(v.newPricePerShare0, '') ) === -1 ){
            return ;
        }
        let modeledRound = {
            id: 'modeledRound',
            name: 'Modeled round',
            quantity: 0,
            ownership: 0,
            children: [
                { name: __('Convertibles'), quantity: 0, ownership: 0, key: 1 },
                { name: __('New round investors'), quantity: 0, ownership: 0, key: 2 },
            ],
        };
        financingData.forEach((v, k)=>{
            const shareTableAttr = this.sharesModeledRound(newStakeholderData, v, k);
            modeledRound = {...modeledRound, ...shareTableAttr.dataAttr};
            modeledRound.children.forEach((v1, k1)=>{
                modeledRound.children[k1] = {...modeledRound.children[k1], ...shareTableAttr.other[k1]};
            })
        })
        return modeledRound;
    }

    getColumns = () => {
        const { financingData, active } = this.props.data;
        let columns = [], isCheckedCount = 0;
        financingData.forEach((v, k)=> {
            const { isChecked } = v;
            if(isChecked){
                const columnsRight = columnsB(I(isCheckedCount, 0));
                let newColumnsRight = [];
                columnsRight.children.forEach((v1, k1)=>{
                    v1.dataIndex += k;
                    newColumnsRight.push(v1);
                });
                columns.push({
                    title: <span className="new-financing-table-right-title">{v.detailName}</span>,
                    children: newColumnsRight,
                });
                isCheckedCount++;
            }
        });
        const columnsLeft = columnsA(I(isCheckedCount, 0), active);
        return [columnsLeft, ...columns];
    }

    /**
     * stakeholder 原有数据统计
     */
    stakeholderTotalList = (active) => {
        const { data } = this.props;
        const { financingData, issuePrice } = this.props.data;
        const { fullDiluted = 0, surplusEa = 0 } = data;
        let newFinancingData = [];
        financingData.forEach(v=>v.isChecked && newFinancingData.push(v));
        const totalList = [
            {
                name: __('New round investors'),
                quantity1: '',
                quantity2: '',
                valuation: '',
            },
            {
                name: __('Option Pool'),
                quantity1: surplusEa,
                quantity2: calculator.agile(`${surplusEa} / ${fullDiluted} * 100`),
                valuation: calculator.agile(`${surplusEa} * ${I(issuePrice, 0)}`),
            },
            {
                name: __('Total'),
                quantity1: fullDiluted,
                quantity2: '100',
                valuation: calculator.agile(`${fullDiluted} * ${I(issuePrice, 0)}`),
            },
        ];
        financingData.forEach((v, k)=>{
            const { stakeholderListB } = v;
            const totalBList = this.totalBList(stakeholderListB, k, active);
            totalBList && totalBList.forEach((v1, k1)=>{
                totalList[k1] = {...totalList[k1], ...totalBList[k1]};
            })
        });
        return totalList;
    }

    /**
     * share class 原有数据统计
     */
    shareClassTotalList = (active) => {
        const { data } = this.props;
        const { fullDiluted = 0, surplusEa = 0, sendEa = 0, financingData, issuePrice } = data;
        const totalList = [
            {
                name: __('Shares outstanding under equity plan(s)'),
                quantity1: sendEa,
                quantity2: calculator.agile(`${sendEa} / ${fullDiluted} * 100`),
                valuation: calculator.agile(`${sendEa} * ${I(issuePrice, 0)}`),
            },
            {
                name: __('Shares available under equity plan(s)'),
                quantity1: surplusEa,
                quantity2: calculator.agile(`${surplusEa} / ${fullDiluted} * 100`),
                valuation: calculator.agile(`${surplusEa} * ${I(issuePrice, 0)}`),
            },
            {
                name: __('Total'),
                quantity1: fullDiluted,
                quantity2: '100',
                valuation: calculator.agile(`${fullDiluted} * ${I(issuePrice, 0)}`),
            },
        ];
        financingData.forEach((v, k)=>{
            const { stakeholderListB } = v;
            const totalBList = this.totalBList(stakeholderListB, k, active);
            totalBList && totalBList.forEach((v1, k1)=>{
                totalList[k1] = {...totalList[k1], ...totalBList[k1]};
            })
        });
        return totalList;
    }

    /**
     * 下面是结合stakeholder、shares的统计数据
     */
    totalBList = (pageData, key, active) => {
        if(!I(pageData, '')){
            return ;
        }
        const { newPricePerShare, unassignedNewInvestment, newTotal } = pageData[0];
        if(!parseFloat(newPricePerShare)){
            return ;
        }
        const { agile, mul } = calculator;
        const { calculation, data:propsData, getNumber, clearNumber } = this.props;
        const { fullDiluted, surplusEa, sendEa, financingData } = propsData;
        const { postMoneyValuation } = financingData[key].statistics;
        //未分配的新一轮投资股数
        const newRoundInvestorsShares = calculation('unnamedNewRoundInvestorsShares', {unassignedNewInvestment, newPricePerShare}, key);
        //未分配股份占比
        const newRoundInvestorsOwnership2 = agile(`${newRoundInvestorsShares} / ${newTotal} * 100`);
        //期权池占比股数
        const optionPoolType = calculation('postMoneyOptionPoolType', null, key);
        let optionPoolShares = optionPoolType === 1 ? calculation('optionPoolShares', {newTotal}, key) : calculation('postMoneyOptionPoolShares', null, key);
        optionPoolShares = clearNumber(getNumber(optionPoolShares, null, false, true));

        //share class EA 占新股的百分比
        const eaNewOwnership2 = agile(`${sendEa} / ${newTotal} * 100 `);
        const eaNewOwnership1 = agile(`${eaNewOwnership2} - (${sendEa} / ${fullDiluted} * 100) `);
        //option pool 占比
        let optionPoolPercentage = optionPoolType === 1 ? mul(calculation('postMoneyOptionPool', null, key), 100) : calculation('optionPoolPercentage', {newTotal}, key);

        optionPoolPercentage = clearNumber(getNumber(optionPoolPercentage, 2));
        //通过option pool ownership 计算出 valuation
        //ea 增加百分比
        const eaPercentage = agile(` ${optionPoolPercentage} - ( ( ${surplusEa} / ${fullDiluted} ) * 100 ) `);
        const postMoneySharesSec = agile(`${I(optionPoolShares, 0)} / ( ${optionPoolPercentage} / 100 ) `);
        //查询option pool 类别，如果两个类别同时存在，则容错计算，不是则取真实数据
        let allOptionPoolType =  [];
        financingData.forEach(v=>v.isChecked === true && allOptionPoolType.push(v.postMoneyOptionPoolType));
        allOptionPoolType = Array.from(new Set(allOptionPoolType));
        const allOptionPoolTypeLen = allOptionPoolType.length;
        const postMoneyShares = allOptionPoolTypeLen > 1 ? postMoneySharesSec : newTotal;
        const investorOwnership1 = active == 2 ? eaNewOwnership1 : newRoundInvestorsOwnership2;
        const investorOwnership2 = active == 2 ? eaNewOwnership2 : newRoundInvestorsOwnership2;
        const investorValuation = active == 2 ? agile(` ${newPricePerShare} * ${sendEa}`) : unassignedNewInvestment;
        const investorShares = active == 2 ? sendEa : newRoundInvestorsShares;

        const totalBList = [
            {
                [`ownership1${key}`]: investorShares < 0 ? '' : investorOwnership1,
                [`ownership2${key}`]: investorShares < 0 ? '' : investorOwnership2,
                [`valuation${key}`]: investorShares < 0 ? '' : investorValuation,
                [`shares${key}`]: investorShares < 0 ? '' : investorShares,
                [`isTotal${key}`]: true,
            },
            {
                [`ownership1${key}`]: eaPercentage,
                [`ownership2${key}`]: optionPoolPercentage,
                [`valuation${key}`]: agile(`( ${postMoneyValuation} * ( ${optionPoolPercentage} / 100 ) )`),
                // [`valuation${key}`]: agile(`( ${optionPoolShares} * ${newPricePerShare} ) / ( ${optionPoolOwnership2} / 100 )`),
                [`shares${key}`]: optionPoolShares,
                [`isTotal${key}`]: true,
            },
            {
                [`ownership1${key}`]: '',
                [`ownership2${key}`]: '100',
                [`valuation${key}`]: postMoneyValuation,
                [`shares${key}`]: postMoneyShares,
                [`isTotal${key}`]: true,
            },
        ];
        return totalBList;
    }

    sharesModeledRound = (list, financingDataInfo, k) => {

        const { cnDataStatistics, clearNumber, financingData } = this.props;
        const { newTotal } = financingDataInfo;
        const newPricePerShare = I(list, '') ? list[0]?.[`newPricePerShare${k}`] || 0 : 0;
        const { agile, add } = calculator;
        //CN每行数据计算
        const { sharesAllTotal, ownershipAllTotal } = cnDataStatistics(financingData, newTotal, newPricePerShare, k);
        //加载子集列表
        const totalNewInvestment = clearNumber(financingDataInfo.totalNewInvestment) || 0;
        const newInvestmentShares = agile(`${totalNewInvestment} / ${newPricePerShare}`);
        const newInvestmentOwnership = agile(`${newInvestmentShares} / ${newTotal} * 100`);
        const cnValuation = agile(`${newPricePerShare} * ${sharesAllTotal}`);
        const other = [
            {
                [`ownership1${k}`]: ownershipAllTotal,
                [`ownership2${k}`]: ownershipAllTotal,
                [`valuation${k}`]: cnValuation,
                [`shares${k}`]: sharesAllTotal,
            },
            {
                [`ownership1${k}`]: newInvestmentOwnership,
                [`ownership2${k}`]: newInvestmentOwnership,
                [`valuation${k}`]: totalNewInvestment,
                [`shares${k}`]: newInvestmentShares,
            },
        ];
        return {
            dataAttr: {
                [`ownership1${k}`]: add(ownershipAllTotal, newInvestmentOwnership),
                [`ownership2${k}`]: add(ownershipAllTotal, newInvestmentOwnership),
                [`valuation${k}`]: add(cnValuation, totalNewInvestment),
                [`shares${k}`]: add(sharesAllTotal, newInvestmentShares),
            },
            other,
        }
    }

    totalList = () => {
        const { getNumber, data } = this.props;
        const { active, financingData } = data;
        const totalList = active == 1 ? this.stakeholderTotalList(active) : this.shareClassTotalList(active);
        return (
            totalList.map((v, k)=>(
                <Table.Summary.Row key={k}>
                    <Table.Summary.Cell index={0}>{v.name}</Table.Summary.Cell>
                    <Table.Summary.Cell index={1} className="new-financing-align-right">{v.quantity1 && formatFractionalNumber(v.quantity1) || '--' }</Table.Summary.Cell>
                    <Table.Summary.Cell index={2} className="new-financing-align-right">{v.quantity2 && `${formatNumber(v.quantity2)}%` || '--'}</Table.Summary.Cell>
                    <Table.Summary.Cell index={3} className="new-financing-align-right">{v.valuation && `${getNumber(v.valuation, null, true)}` || '--'}</Table.Summary.Cell>
                    {
                        financingData.map((v1, k1)=>{
                            const { isChecked } = v1;
                            if(isChecked){
                                return (
                                    <Fragment key={k1}>
                                        <Table.Summary.Cell className="new-financing-table-border new-financing-align-right">
                                            <span className={`${v[`ownership1${k1}`] > 0 && 'new-financing-round-green'} ${ !I(v[`ownership1${k1}`], '') && 'new-financing-round-def'} ${v[`ownership1${k1}`] < 0 && 'new-financing-round-red'} `} >
                                                {`${v[`ownership1${k1}`] > 0 ? '+' : '' }${I(v[`ownership1${k1}`], '') ? formatNumber(v[`ownership1${k1}`], 2) : '--'}${ I(v[`ownership1${k1}`], '') ? '%' : ''}`}
                                            </span>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell className="new-financing-align-right">{I(getNumber(v[`shares${k1}`], null, false, true), '--')}</Table.Summary.Cell>
                                        <Table.Summary.Cell className="new-financing-align-right">{I(getNumber(v[`ownership2${k1}`], 2), '--')}{I(v[`ownership2${k1}`], '') ? '%' : ''}</Table.Summary.Cell>
                                        <Table.Summary.Cell className="new-financing-align-right">{I(v[`valuation${k1}`], '') ? getNumber(v[`valuation${k1}`], null, true) : '--'}</Table.Summary.Cell>
                                    </Fragment>
                                )
                            }
                        })
                    }
                </Table.Summary.Row>
            ))
        )
    }

    /**
     * stakeholder, by share class 列表
     * @returns {JSX.Element}
     */
    showStakeholderShares = () => {
        const { data } = this.props;
        const { stakeholderData, loading, active, financingData } = data;
        return !loading && (
            <div className="new-financing-breakdown-table">
                <Table
                    className="new-financing-breakdown-table-left"
                    columns={ this.getColumns() }
                    dataSource={this.stakeholderDataList(active, stakeholderData, financingData)}
                    pagination={false}
                    bordered={false}
                    loading={loading}
                    summary={ __ => this.totalList()}
                    scroll={{ x: 'auto', y: 480 }}
                    rowKey={'id'}
                />
            </div>
        );
    }

    /**
     * 切换
     * @param active
     */
    showTable = (active) => {
        const { data, switchData } = this.props;
        const { shareClassDataList, stakeholderDataList } = data;
        const stakeholderData = active == 1 ? [...stakeholderDataList] : [...shareClassDataList];
        switchData({stakeholderData, active});
    }

    /**
     * scenario 总记录条数
     * @returns {number}
     */
    financingDataCount = () => {
        const { data } = this.props;
        const { financingData } = data;
        let count = 0;
        financingData.forEach((v)=>{
            v.isChecked && (count++);
        })
        return count;
    }

    sharesEcharts = () => {
        const { sharesList, sharesTotal, myEcharts } = this.state;
        const { financingData, data } = this.props;
        const { stakeholderData } = data;
        let currentData = [];
        let echartsList = [];

        sharesList && sharesList.forEach(v=>{
            if(v){
                if(v.id === 'modeledRound'){
                    v.children.forEach(v1=>{
                        currentData.push({ title: __('Current Cap table'), name: v1.name, value: I(v1.quantity, 0)});
                    });
                }else{
                    currentData.push({ title: __('Current Cap table'), name: v.name, value: I(v.quantity, 0)});
                }
                financingData && financingData.forEach((value,key)=>{
                    if(value.isChecked){
                        echartsList[key] = I(echartsList[key], []);
                        if(v.id === 'modeledRound'){
                            v.children.forEach(v1=>{
                                echartsList[key].push({ title: value.detailName, name: v1.name, value: I(v1[`shares${key}`], 0)});
                            });
                        }else{
                            echartsList[key].push({ title: value.detailName, name: v.name, value: I(v[`shares${key}`], 0)});
                        }

                    }
                });
            }
        });
        if(currentData.length > 0){
            currentData.push({title: echartsList?.[0]?.[0]?.title, name: sharesTotal?.[0]?.name, value: I(sharesTotal?.[0]?.quantity1, 0)});
            currentData.push({title: echartsList?.[0]?.[0]?.title, name: sharesTotal?.[1]?.name, value: I(sharesTotal?.[1]?.quantity1, 0)});
            echartsList.unshift(currentData)
            echartsList = echartsList.filter(item => item !== null);

            echartsList.forEach((v, k)=>{
                const index = financingData.findIndex(v1=>v1.detailName === v?.[0].title);

                if(k > 0){
                    echartsList[k].push({title: echartsList?.[k]?.[0]?.title, name: sharesTotal?.[0]?.name, value: I(sharesTotal?.[0]?.[`shares${index}`], 0)});
                    echartsList[k].push({title: echartsList?.[k]?.[0]?.title, name: sharesTotal?.[1]?.name, value: I(sharesTotal?.[1]?.[`shares${index}`], 0)});
                }
            })
        }
        // z(480, echartsList);
        return echartsList && echartsList.map( (v, k)=>{
            const span = parseInt(24 / I(echartsList.length, 0));
            return <Col span={span} key={k}>
                {v && <div className="new-financing-echarts-box">
                <h3>{v?.[0]?.title}</h3>
                <FinancingEcharts data={v} k={k} myEcharts={myEcharts} updateMyEcharts={this.updateMyEcharts} /></div>}
            </Col>
        })
    }

    updateMyEcharts = (myEcharts) => {
        this.setState({myEcharts});
    }

    render() {
        const { sharesList } = this.state;
        const { data, updateState, checkRequest } = this.props;
        const { active } = data;
        return (
            <div className="new-financing-round-breakdown-box">
                <div className="new-financing-breakdown-title">
                    <div className="new-financing-breakdown-title-left">
                        <ExportExcel
                            data={data}
                            stakeholderDataList={this.stakeholderDataList}
                            shareClassTotalList={this.shareClassTotalList}
                            stakeholderTotalList={this.stakeholderTotalList}
                            updateState={updateState}
                            checkRequest={checkRequest}
                        />
                        <Row gutter={16} className="new-financing-echarts">
                            {this.sharesEcharts()}
                        </Row>
                        <div className="new-financing-breakdown-title-left-button">
                            <Button onClick={this.showTable.bind(this, 2)} className={active == 2 && 'active'}>{__('By share class')}</Button>
                            <Button onClick={this.showTable.bind(this, 1)} className={active == 1 && 'active'}>{__('By stakeholder')}</Button>
                        </div>
                    </div>
                    <div className="new-financing-breakdown-title-right">
                        <Button type="primary" style={{display:'none'}}>
                            <Space size={8}>
                            <img src={tableAttr} />
                            <img className="new-financing-breakdown-title-right-down" src={downArrow} />
                            </Space>
                        </Button>
                        <span>{printf( __('Comparing %s scenario'), this.financingDataCount() )}</span>
                    </div>
                </div>
                { this.showStakeholderShares() }
            </div>
        );
    }
}
export default CapitalizationBreakdown;

function z(number, obj){
    console.log(number, JSON.parse(JSON.stringify(obj)));
}