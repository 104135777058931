import React, { Component, Fragment } from "react";
import {Button, Input, Checkbox, Table, message} from "antd";
import { post } from 'srcPath/http';
import Utils, { I, formatNumber } from 'Utils';
import DateSelector from 'components/DateSelector';
import { formatDate } from 'Utils/format/formatDate'
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import { DateCustomer } from 'components/DateSelector'
import Loading from 'components/Loading';
import './style.scss'
import {UpOutlined, DownOutlined, FileSearchOutlined} from "@ant-design/icons";
import RelationshipSelector from "components/RelationshipSelector";
import TerminationTypeSelector from "components/TerminationTypeSelector";
import moment from "moment";
import security from 'stores/security';

class BulkTerminateStakeholders extends Component {
    state = {
        terminationList : [],
        confirmChecked: false,
        tableList : [],
        id: '',
        mergeId: '',
        dateFormat:'DD/MM/YYYY',
    }


    /**
     * 初始化表格
     * @returns {Promise<void>}
     */
    async componentDidMount() {
        const { holderLists } = this.props;
        //获取已选择的stakeholders
        this.getUseStakeholders(holderLists);
        this.getTerminateStakeholders(holderLists)

        Loading.global(false)
    }

    /**
     * 获取已选择的stakeholders ID
     * @param holderLists
     * @returns {[]}
     */
    getUseStakeholders = async(holderLists) => {
        let resList = [];
        holderLists.forEach( (v, k) => {
            if(I(v.status, 0) == 1){
                resList.push(v.id);
            }
        })
        return resList;
    }

    /**
     * 获取已选择的stakeholders ID
     * @param holderLists
     * @returns {[]}
     */
    getTerminateStakeholders = async(holderLists) => {
        let terminateList = [];
        holderLists.forEach( (v, k) => {
            if(I(v.status, 0) == 1){
                terminateList.push({stakeholder_id:v.id, name:v.name, relationship: v.relationship, new_relationship_date:'', new_relationship:'', terminate_type:'', terminate_date:'', email:v.email, terminationInfo: [], tokenTerminationInfo:[]});
            }
        })
        this.setState({
            terminationList : terminateList
        })
    }

    /**
     * 验证填写内容
     */
    checkRequest = () => {
        const { tableList } = this.state;
        const { holderLists } = this.props;
        const ids = this.getUseStakeholders(holderLists);
        let isError = 0;
        //验证 name,email 是否填写
        for(let i=1; i<4; i++){
            const { stakeholderC, key, stakeholderA, stakeholderB } = tableList[i];
            if(!I(stakeholderC, '')){
                if(key == 'securities'){
                    if(!I(stakeholderA?.[ids[0]], '') && !I(stakeholderB?.[ids[1]], '')){
                        tableList[i].isError = false;
                    }else{
                        tableList[i].isError = true;
                        isError++;
                    }
                }else{
                    tableList[i].isError = true;
                    isError++;
                }
            }else{
                tableList[i].isError = false;
            }

        }
        this.setState({tableList: [...tableList]});
        return !isError;
    }
    /**
     * 关闭model
     */
    closeModels = () => {
        const { close, initStakeHolderData } = this.props;
        initStakeHolderData();
        close();
    }
    getTerminationInfoById = (id, date) =>{
        const { terminationList } = this.state;
        let dataIndex = terminationList.findIndex(item => item.stakeholder_id == id)
        let terminationInfo = terminationList[dataIndex].terminationInfo
        let tokenTerminationInfo = terminationList[dataIndex].tokenTerminationInfo
        post('getPreTerminateInfo',{stakeholder_id: id, terminate_date: date}).then((response)=> {
            if(response.data.code === 0){
                terminationList[dataIndex].stakeholderKey = dataIndex
                const affectList = response.data.data.affectedShareList;
                const tokenAffectList = response.data.data.affectedTokenGrantList
                for(let i = 0; i < affectList.length; i++){
                    if(terminationInfo.indexOf(affectList[i]) === -1)
                    terminationInfo.push(affectList[i])
                }
                for(let j = 0; j < tokenAffectList.length; j++){
                    if(tokenTerminationInfo.indexOf(tokenAffectList[j]) === -1)
                    tokenTerminationInfo.push(tokenAffectList[j])
                }
                this.setState({
                    terminationList
                })
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    /**
     * lastDayToExerciseChange
     * @param name
     * @param key
     * @param date
     */
    lastDayToExerciseChange = (name, key, stakeholderKey, date) => {
        const { terminationInfo } = this.state.terminationList[stakeholderKey];
        let curData = {...terminationInfo};
        const curTerminationDate = Utils.dateFormatTransfer(this.state.terminationList[stakeholderKey].terminate_date);
        terminationInfo[key].last_day_to_exercise = date ? date : '';
        terminationInfo[key].days_remaining = formatNumber(Utils.getDaysBetween(curTerminationDate,Utils.dateFormatTransfer( (terminationInfo[key].last_day_to_exercise == -1) ? '' : terminationInfo[key].last_day_to_exercise )),null)
        this.setState({
            terminationInfo:curData
        });
    }

    /**
     * last day to exercise for terminateInfo
     * @param data
     * @param index
     * @returns {JSX.Element}
     */
    handleLastDayToExercise = (text, data, index, stakeholderIndex) => {
        const expirationDateLongDesc = {'-1': __('No expiration date')};
        return (
            <DateCustomer
                customerValue={-1}
                required
                onChange={this.lastDayToExerciseChange.bind(this, 'terminationInfo', index, stakeholderIndex)}
                footerText={expirationDateLongDesc['-1']}
                lastDayToExerciseChange={this.lastDayToExerciseChange}
            />
        );
    }


    /**
     * lastDayToTokenExerciseChange for token
     * @param name
     * @param key
     * @param date
     */
    tokenLastDayToExerciseChange = (name, key, stakeholderKey, date) => {
        const { tokenTerminationInfo } = this.state.terminationList[stakeholderKey];
        let curData = {...tokenTerminationInfo};
        const curTerminationDate = Utils.dateFormatTransfer(this.state.terminationList[stakeholderKey].terminate_date);
        tokenTerminationInfo[key].last_day_to_exercise = date ? date : '';
        tokenTerminationInfo[key].days_remaining = formatNumber(Utils.getDaysBetween(curTerminationDate,Utils.dateFormatTransfer( (tokenTerminationInfo[key].last_day_to_exercise == -1) ? '' : tokenTerminationInfo[key].last_day_to_exercise )),null)
        this.setState({
            tokenTerminationInfo:curData
        });
    }
    /**
     * last day to exercise for tokenTerminateInfo
     * @param data
     * @param index
     * @returns {JSX.Element}
     */
    handleTokenLastDayToExerciseChange = (text, data, index, stakeholderIndex) => {

        const expirationDateLongDesc = {'-1': __('No expiration date')};
        return (
            <DateCustomer
                customerValue={-1}
                required
                onChange={this.tokenLastDayToExerciseChange.bind(this, 'tokenTerminationInfo', index, stakeholderIndex)}
                footerText={expirationDateLongDesc['-1']}
                tokenLastDayToExerciseChange={this.tokenLastDayToExerciseChange}
            />
        );
    }
    /**
     * 展示证书列表
     * @param record
     * @returns {JSX.Element}
     */
    showCertificateInfo = ( record ) =>{
        let showContent = '';
        const stakeholderId = record.stakeholderKey

        const columns = [
            {
                title: 'Security',
                dataIndex: 'prefix',
                key: 'prefix',
                render: (text) => <span className="text-blue">{text}</span>
            },
            {
                title: 'Acceptance',
                dataIndex: 'sign_status',
                key: 'sign_status',
                render: (text) => <span>{ text == 0 ? "Accepted" : (text == 1 ? "Awaiting officer signature" : "Awaiting holder signature")}</span>

            },
            {
                title: 'Issue date',
                dataIndex: 'issue_date',
                key: 'issue_date',
                render: (text) => <span>{formatDate(text)}</span>
            },
            {
                title: 'Granted',
                dataIndex: 'granted',
                key: 'granted',
                align : 'right',
                render: (text) => <span>{formatFractionalNumber(text)}</span>
            },
            {
                title: 'Vested',
                dataIndex: 'vested',
                key: 'vested',
                align : 'right',
                render: (text) => <span>{ formatFractionalNumber(text) }</span>,
            },
            {
                title: 'Unvested',
                dataIndex: 'unvested',
                key: 'unvested',
                align : 'right',
                render: (text) => <span>{ formatFractionalNumber(text) }</span>,
            },
            {
                title: 'Exercised',
                dataIndex: 'exercised_quantity',
                key: 'exercised_quantity',
                align : 'right',
                render: (text) => <span>{ formatFractionalNumber(text) }</span>,
            },
            {
                title: 'Last day to exercise',
                dataIndex: 'last_day_to_exercise',
                key: 'last_day_to_exercise',
                align : 'left',
                width:200,
                render:(text, value, index) => this.handleLastDayToExercise(text, value, index, stakeholderId),
            },
            {
                title: 'Days remaining',
                dataIndex: 'days_remaining',
                key: 'days_remaining',
                align : 'right',
                render:(text)=> <span>{text} {'days'}</span>
            },
            ]

        const tokenColumns = [
            {
                title : __('Grant name'),
                align : 'left',
                dataIndex : 'grant_name',
                key : 'keyId',
                fixed: 'left',
                width: 100,
                isChecked: true,
                isDisabled: true,
                render: (text) => <span className="text-blue">{text}</span>
            },
            {
                title : __('Grant date'),
                dataIndex : 'grant_date',
                align : 'right',
                width: 100,
                render: (text, value) => {
                    return <span>{ formatDate(value.grant_date) }</span>
                },
                isChecked: true,
            },
            {
                title : __('Issued quantity'),
                dataIndex : 'quantity',
                align : 'right',
                width:120,
                render: (text) => <span>{ formatFractionalNumber(text) }</span>,
            },
            {
                title : __('Vested'),
                dataIndex : 'vested',
                align : 'right',
                width: 120,
                render: (text, value) => {
                    return <span>{formatFractionalNumber(text)}</span>
                },
                isChecked: true,
            },
            {
                title : __('Unvested'),
                dataIndex : 'unvested',
                align : 'right',
                width: 120,
                render: (text) => <span>{ formatFractionalNumber(text) }</span>,
                isChecked: true,
            },
            {
                title : __('Claimed'),
                dataIndex : 'claimed',
                align : 'right',
                width:120,
                render: (text) => <span>{ formatFractionalNumber(text) }</span>,
            },
            {
                title : __('Unclaimed'),
                dataIndex : 'unclaimed',
                align : 'right',
                width:120,
                render: (text) => <span>{ formatFractionalNumber(text) }</span>,
            },
            {
                title : __('Last day to exercise'),
                dataIndex : 'last_day_to_exercise',
                key: 'last_day_to_exercise',
                align: 'left',
                width:200,
                render:(text, value, index) => this.handleTokenLastDayToExerciseChange(text, value, index, stakeholderId),
                isHide: true,
            },
            {
                title: 'Days remaining',
                dataIndex: 'days_remaining',
                key: 'days_remaining',
                align : 'right',
                render:(text)=> <span>{text} {'days'}</span>
            },
        ]
        if(!record.new_relationship_date || !record.new_relationship || !record.terminate_type || !record.terminate_date || !record.email){
            showContent = <p key={record.stakeholder_id} ><FileSearchOutlined className='fileSize' /><br/>{__('Enter new relationship, termination type, and valid termination date above to see equity details.')}</p>;
        }else{
            if(record.terminationInfo?.length===0 && record.tokenTerminationInfo?.length === 0){
                showContent = <p key={record.stakeholder_id} ><FileSearchOutlined className='fileSize' /><br/>{__('This stakeholder does not have equity eligible for termination.')}</p>
            }else{
                showContent = (
                    <Fragment>
                        {record.terminationInfo?.length == 0 ? '' : <Table
                            title={()=>__('Equity')}
                            className='certificate-table'
                            rowKey={record.stakeholder_id}
                            columns={columns}
                            pagination={false}
                            dataSource={record.terminationInfo}
                            style={{marginBottom:'30px'}}
                        />}
                        {record.tokenTerminationInfo?.length == 0 ? '' :<Table
                            title={()=>"Token"}
                            className='certificate-table'
                            rowKey={record.stakeholder_id}
                            columns={tokenColumns}
                            pagination={false}
                            dataSource={record.tokenTerminationInfo}
                        />}
                    </Fragment>
                )
            }
        }
        return showContent
    }


    /**
     *日期
     * @param key
     * @param moment_key
     * @param id
     * @param dateIndex
     * @param date
     */
    handleDateChange = (key, moment_key, id, dateIndex, date) => {
        const { dateFormat } = this.state;
        let curData = this.state.terminationList;
        if( dateIndex === 0 ){
            for(let i = 0; i < curData.length; i ++ ){
                curData[i][key] = date?.format(dateFormat);
                curData[i][moment_key] = date;
                if(key=='terminate_date'){
                    this.getTerminationInfoById(curData[i].stakeholder_id, curData[0].terminate_date)
                }
            }
        }else{
            curData[dateIndex][key] = date?.format(dateFormat);
            curData[dateIndex][moment_key] = date;
            if(key=='terminate_date')
                this.getTerminationInfoById(id,curData[dateIndex].terminate_date)
        }
        this.setState({
            terminationList:curData
        });
    }

    /**
     * termination data
     * @param key
     * @param record
     * @param dataIndex
     * @param value
     */
    handleTerminationDataChange = (key, record, dataIndex, value) =>{
        let curData = this.state.terminationList;
        if( dataIndex === 0 && key!= 'email' ){
            for(let i = 0; i < curData.length; i ++ ){
                curData[i][key] = value
            }
        }else{
            curData[dataIndex][key] = value
        }

        this.setState({
            terminationList: curData
        });
    }

    /**
     * confirm data
     */
    confirmData = ( ) =>{
        const { confirmChecked } = this.state
        this.setState({
            confirmChecked:!confirmChecked
        })
    }

    /**
     * 批量terminate
     */
    bulkTermination = () =>{
        const { terminationList } = this.state;
        const { close, forceUpdates } = this.props;

        let requestTerminateInfo = [];
        let terminate_token_grant_list = [];
        for(let i=0; i<terminationList.length; i++){
            requestTerminateInfo = terminationList[i].terminationInfo
                .filter(item => item.last_day_to_exercise !== '')
                .map(({id, last_day_to_exercise})=>({id, last_day_to_exercise}))
            terminationList[i].requestTerminationInfo = requestTerminateInfo
        }
        for(let i=0; i<terminationList.length; i++){
            terminate_token_grant_list = terminationList[i].tokenTerminationInfo
                .filter(item => item.last_day_to_exercise !== '')
                .map(({id, last_day_to_exercise})=>({id, last_day_to_exercise}))
            terminationList[i].terminate_token_grant_list = terminate_token_grant_list
        }
        this.setState({
            terminationList
        })
        let requestData = terminationList.map(({stakeholder_id, new_relationship_date, new_relationship, terminate_type, terminate_date, requestTerminationInfo, terminate_token_grant_list, email})=>({stakeholder_id, new_relationship_date, new_relationship, terminate_type, terminate_date, requestTerminationInfo, terminate_token_grant_list, email}))
        post('bulkTerminateStakeholder',requestData).then((response)=> {
            if(response.data.code === 0){
                forceUpdates();
                close();
                message.success('Success')
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    /**
     * 表格列参数配置
     * @returns {[{sorter: (function(*, *): number), dataIndex: string, onFilter: (function(*=, *): boolean), filters: [{text: string, value: string}, {text: string, value: string}, {text: string, value: string}, {text: string, value: string}, {text: string, value: string}, null], title: string, align: string, key: string}, {filterDropdown: function({setSelectedKeys: *, selectedKeys?: *, confirm?: *, clearFilters?: *}): *, sorter: (function(*, *): number), dataIndex: string, filterIcon: function(*): *, onFilter: function(*, *): *|string, onFilterDropdownVisibleChange: function(*=): void, title: string}, {sorter: (function(*, *): number), dataIndex: string, title: string, render: (function(*, *))}, {sorter: (function(*, *)), dataIndex: string, title: string, align: string, render: (function(*=))}, {sorter: (function(*=, *=)), dataIndex: string, title: string, align: string, render: (function(*, *=))}, null, null, null]}
     */
    tableColumns = () => {
        const { dateFormat } = this.state;
        return [
            {
                title : __('Stakeholder name'),
                align : 'left',
                dataIndex : 'name',
                key : 'keyId',
                fixed: 'left',
                width: 140,
                render: (text) => <span>{text}</span>
            },
            {
                title : __('Current relationship'),
                align : 'left',
                dataIndex : 'relationship',
                width: 160,
            },
            {
                title : __('New relationship date'),
                dataIndex : 'new_relationship_date',
                align : 'center',
                width: 150,
                render: (text, value, index) => {
                    return <DateSelector style={{width:'100%'}} format={security.companyInfo && security.companyInfo.date_format} value={text ? moment(text, dateFormat) : text} onChange={this.handleDateChange.bind(this,'new_relationship_date','new_relationship_date_moment',value.stakeholder_id, index)}></DateSelector>
                },
            },
            {
                title : __('New relationship'),
                dataIndex : 'new_relationship',
                align : 'right',
                width:190,
                render: (text, value, index) =>{
                    return <RelationshipSelector style={{width:'100%'}} value={text} onChange={this.handleTerminationDataChange.bind(this,'new_relationship',value, index)}/>
                },
            },
            {
                title : __('Terminate date'),
                dataIndex : 'terminate_date',
                align : 'center',
                width: 150,
                render: (text, value, index) => {
                    return <DateSelector style={{width:'100%'}} format={security.companyInfo && security.companyInfo.date_format} value={text ? moment(text, dateFormat) : text} onChange={this.handleDateChange.bind(this,'terminate_date','terminate_date_moment',value.stakeholder_id, index)} />

                },
            },
            {
                title : __('Terminate type'),
                dataIndex : 'terminate_type',
                align : 'right',
                width: 150,
                render: (text, value, index) => {
                    return <TerminationTypeSelector style={{width:'100%'}} value={text} onChange={this.handleTerminationDataChange.bind(this,'terminate_type', value, index)}/>
                },
            },
            {
                title : __('Contact email'),
                dataIndex : 'email',
                align : 'right',
                width:180,
                render: (text, value, index) => <Input defaultValue={text} style={{width:'100%'}} value={this.state.terminationList[index].email} onChange={(e)=>this.handleTerminationDataChange('email', value,  index, e.target.value)}/>,
            },
        ]
    }

    getExpandIcon = (expanded, onExpand, record) => {
        return (
            expanded ? (
                <UpOutlined onClick={e => onExpand(record, e)} />
            ) : (
                <DownOutlined onClick={e => onExpand(record, e)} />
            )
        )
    }
    render() {
        const { terminationList, confirmChecked } = this.state;
        let canBeConfirm = 0;
        for(let i=0; i<terminationList.length; i++){
            let baseData = terminationList[i].terminate_date && terminationList[i].new_relationship_date && terminationList[i].new_relationship && terminationList[i].terminate_date && terminationList[i].terminate_type && terminationList[i].email ? 1 : 0
            let isDoneLastDay = 0
            let isDoneTokenLastDay = 0
            if(terminationList[i].terminationInfo?.length >0 ){
                for(let j=0; j<terminationList[i].terminationInfo.length; j++){
                    if( !terminationList[i].terminationInfo[j].last_day_to_exercise){
                        isDoneLastDay = 0
                        break;
                    }else{
                        isDoneLastDay = 1
                    }
                }
            }else{
                isDoneLastDay = 1
            }
            if(terminationList[i].tokenTerminationInfo?.length > 0){
                for(let j=0; j<terminationList[i].tokenTerminationInfo.length; j++){
                    if( !terminationList[i].tokenTerminationInfo[j].last_day_to_exercise){
                        isDoneTokenLastDay = 0
                        break;
                    }else{
                        isDoneTokenLastDay = 1
                    }
                }
            }else{
                isDoneTokenLastDay = 1
            }
            if( !baseData || !isDoneLastDay || !isDoneTokenLastDay){
                canBeConfirm = 0
                break;
            }else{
                canBeConfirm = 1
            }

        }
        return (
            <>
                <div className="terminate-stakeholder-header">
                    <h5 className="title">{__("Terminate stakeholders")}</h5>
                    <div className="close " onClick={this.closeModels} >×</div>
                </div>
                <div className="merge-stakeholder-content new-merge-stakeholder-content">
                    <Table
                        className="stake-table bulk_terminate-table"
                        pagination={false}
                        columns={this.tableColumns()}
                        expandable={{
                            expandedRowRender: record => (
                                this.showCertificateInfo(record)
                            ),
                            expandIcon: ({ expanded, onExpand, record }) => this.getExpandIcon(expanded, onExpand, record)
                        }}
                        dataSource={terminationList}
                        rowKey={record => record.stakeholder_id}
                        rowClassName="stake-table-row"
                        scroll={{ x: true }}

                    />
                    <div className='bulk-confirm'>
                        <Checkbox disabled={!canBeConfirm} checked={confirmChecked} className="confirm-check" required onChange={this.confirmData} >{__('I confirm that the last day to exercise is correct for each grant.')}</Checkbox>
                    </div>
                </div>
                <div className="terminate-w100">
                    <Button disabled={!confirmChecked} danger className="terminate-button" type="primary" onClick={this.bulkTermination}>{__('Terminate')}</Button>
                </div>
            </>
        );
    }
}
export default BulkTerminateStakeholders;