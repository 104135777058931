import React from 'react';
import Security from '../forms/Security';
import Stakeholder from '../forms/Stakeholder';
import Operator from '../forms/Operator';
import TransactionType from '../forms/TransactionType';
import Quantity from "../forms/Quantity";
import FormsDate from '../forms/FormsDate';

export default function Form() { 
    return <div>
        <Security />
        <Stakeholder type='transactions'/>
        <Quantity />
        <FormsDate name='applied_date' label={__('Applied date')}/>
        <FormsDate name='transaction_date' label={__('Transaction date')}/>
        <Operator />
        <TransactionType />
    </div>
}