import React, { Component } from 'react';
import Modal, { ModalContent } from 'components/Modal';
import { Button, message } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { post } from 'srcPath/http';
import Utils, { bindKeyEvent } from 'Utils';
import './style.scss';
import { sprintf } from 'Utils/i18n';

class ResendEmail extends Component {
	state = {
		loading: false,
	}

	componentDidMount() {
		this.removeKeyEvent = bindKeyEvent(this.onSubmit)
	}

	componentWillUnmount() {
		this.removeKeyEvent && this.removeKeyEvent()
	}

	onSubmit = async () => {
		const { close, callback, data: defaultData, api } = this.props

		if (!api) {
			message.error('No api')

			return
		}

		this.setState({ loading: true })

		try {
			const { data: resData } = await post(api, { id: defaultData.id })
			const { code, data } = resData || {}

			if (code == 0 || code == 200) {
				close && close()
				callback && callback()
			} else {
				message.error(data)
			}
		} catch (e) {
			message.error(e.message)
		}

		this.setState({ loading: false })
	}

	render() {
		const { data } = this.props

		return <div className="permisson-user-resend-modal">
			<p>{sprintf(__('Confirm you want to resend invitation email to %s'), <strong>{data && data.email}</strong>)}</p>
			<div className="btn-wrap">
				<Button type="primary" loading={this.state.loading} onClick={this.onSubmit}><SendOutlined />{__('Resend')}</Button>
			</div>
		</div>
	}
}


export default function openResendModal(callback, data, api) {
	const { name } = data || {}

	return Modal.open({
		component: props => <ModalContent close={props.close} title={__('Confirm')}> <ResendEmail api={api} data={data} close={props.close} callback={callback} /></ModalContent>,
		width: 600,
		className: 'sprout-modal'
	})
}

