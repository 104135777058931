import React from 'react';
import 'assets/css/dataroommodal.css';
import axios from 'axios';
import Utils from "Utils";
import Modal from 'components/Modal';
import { printf } from 'Utils/i18n';
class RenameModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            data:{
                name:"",
                id:0
            },
            isRenamingTopFolder:0,
            targetId:0,
            fileType:"dir",
            parentType:"top",
            parentId:0
        }
    }
    //这个会被除了顶层dir之外的其他rename调用，默认值0用来区分
    setTargetId=(id,name,fileType,parentType,parentId)=>{
        let curData = this.state.data;
        curData.id = id;
        curData.name = name;
        this.setState({
            targetId:id,
            fileType:fileType,
            parentType:parentType,
            parentId:parentId,
            data:curData
        });
    }

    initDirInfo=()=>{
        let curData = this.state.data;
        let topDirInfo = this.props.dirInfo
        curData.name = topDirInfo.name;
        curData.id = topDirInfo.id;
        this.setState({
            data:curData,
        });
    }
    setName=(e)=>{
        let curData = this.state.data;
        curData.name = e.target.value;
        this.setState({
            data:curData
        })
    }

    renameDoc=()=>{
        if(this.state.isRenamingTopFolder === 1){
            return;
        }
        var curData ={...this.state.data};
        if(!curData.name.trim()){
            return
        }
        curData.id = this.props?.dirInfo?.id
        this.setState({
            isRenamingTopFolder:1,
        });
        axios.post('https://api.getsprout.co', {
            company_id:Utils.getLocalCompanyInfo().company_id,
            action:'renameDoc',
            data:curData
        }).then((response)=> {
            if(response.data.code === 0){
                this.setState({
                    isRenamingTopFolder:0,
                    targetId:0,
                });
                this.props.onReload('Rename', this.props.dirInfo)
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }


    render(){
        const { client_name, name } = this.props?.dirInfo || {}
        return(
            <div>
                <div style={{paddingRight:0}}>
                    <div>
                    <div className="modal-body body-reset2030">
                        <span>{printf(__('Rename %s as:'), client_name||name)}</span>
                        <input style={{ marginTop: '10px' }} onChange={this.setName} value={this.state.data.name} type="text" className="form-control" placeholder="e.g. Document" />
                    </div>
                    <div className="modal-footer box-footer no-paddingtop">
                        <button onClick={this.renameDoc} type="button" className="btn dataroom-btnReset" data-dismiss="modal">{__('Save')}</button>
                    </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default RenameModal;

