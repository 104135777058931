import React, { Component } from 'react';
import { Button } from 'antd';
import './index.scss';
class EditCom extends Component {
	render () {
        const { status, progress = 0, userList } = this.props.data;
		return <div className="board-consents-progress" >
			<Button type="link"><span data-action="edit">{__('Edit')}</span></Button>
		</div>
	}
}

export default EditCom;