/**
 * Created by rain on 2019/7/30.
 */
import React from 'react';
import 'assets/css/updateStakeholder.css';
class UpdateProperties extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            spreadsheet:[
                {
                    file_name:"No file uploaded",
                }
            ]
        }
    }
    selectFile=(key,e)=>{
        let curSpreadsheet = this.state.spreadsheet;
        curSpreadsheet[key].file_name=e.target.files[0].name;
        this.setState({
            spreadsheet:curSpreadsheet
        })
        window.$(".sprsheet-delBtn").removeClass("hidden");
    }
    delFileSelected=(key)=>{
        let curSpreadsheet = this.state.spreadsheet;
        curSpreadsheet[0].file_name = "No file uploaded";
        this.setState({
            spreadsheet:curSpreadsheet
        })
        this.refs.propertiesFiles.value = "";
        window.$(".sprsheet-delBtn").addClass("hidden");

    }
    render(){
        return(
            <div className="modal fade" id="updatePropertiesModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={{display: "none"}} >
                <div className="modal-dialog dialog-top40">
                    <div className="modal-content box-content">
                        <div className="modal-header box-header">
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                            <h4 className="modal-title" id="myModalLabel">Stakeholder Properties For Folium</h4>
                        </div>
                        <div className="modal-body box-body update-body reset_margin">
                            <div className="update-box">
                                <p className="update-tit">Step 1</p>
                                <p className="update-text text-muted">Download the stakeholder properties spreadsheet template for MS Ventures and add your information.</p>
                                <button className="btn update-btn update-darkBtn"><span className="glyphicon glyphicon-download-alt down-pic"></span>Download template</button>
                            </div>
                            <div className="update-box">
                                <p className="update-tit">Step 2</p>
                                <p className="update-text text-muted">Upload your revised spreadsheet. We'll check your data for errors before we import it.</p>
                                {this.state.spreadsheet.map((value,key)=>{
                                    return(
                                        <div key={key}>
                                            <div className="btn update-btn update-darkBtn select-btn sprsheet-selBtn">Select spreadsheet
                                                <label htmlFor="files-select" className="sr-only" >File input</label>
                                                <input type="file" ref="propertiesFiles" onChange={this.selectFile.bind(this,key)} />
                                            </div>
                                            <div className="sprsheet-filenameBox ">
                                                <p className="text-muted">{value.file_name} <span className="sprsheet-delBtn hidden"><button className="text-muted" onClick={this.delFileSelected.bind(key)}>x</button></span></p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="modal-footer box-footer no-paddingtop">
                            <button type="button" className="btn boxbtn-to btn-toDetails bg-darkblue"  data-dismiss="modal" >Import spreadsheet</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default UpdateProperties;

