import React, { Component } from 'react';
import { FormItem, withForm } from 'react-antd-formutil';
import {Button, Tooltip} from 'antd';
import { PaperClipOutlined, UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import Utils from 'Utils';
import './style.scss';

export default class UploadItem extends Component {
	static propTypes = {
		multi: PropTypes.bool, // 是否为多选模式
		value: PropTypes.array,
		onChange: PropTypes.func,
		fileKey: PropTypes.string
	}

	state = {
		fileList: this.props.value || []
	}

	componentWillReceiveProps(nextProps) {
		if(nextProps.value !== this.state.fileList) {
			this.setState({ fileList: nextProps.value || [] })
		}
	}

	handleClick = () => {
		const input = document.createElement('input')
		const body = document.querySelector('body');
		const { multi, onChange, fileKey = 'file' } = this.props  

		input.type = 'file';
		input.style.display = 'none';

		input.onchange = async (event) => {
			const { files = [] } = event.target
			const file = files[0] || {}
			const { name } = file

			const file_obj = await Utils.uploadFile(file)

			body.removeChild(input);

			let { fileList = [] } = this.state

			if (multi) {
				fileList.push({ name, [fileKey]: file_obj })
			} else {
				fileList = { name, [fileKey]: file_obj }
			}

			this.setState({ fileList }, () => { 
				onChange && onChange(fileList)
			})
		}

		body.appendChild(input)

		input.click()
	}

	clearFile = (index, signle) => {
		const { fileList } = this.state
		const { onChange } = this.props

		if (signle) {
			this.setState({ fileList: undefined }, () => {
				onChange && onChange(undefined)
			})

			return
		}

		fileList.splice(index, 1)

		const value = cloneDeep(fileList)

		this.setState({ fileList: value })

		onChange && onChange(value)
	}

	render() {
		const { fileList = [] } = this.state
		const { multi } = this.props

		return <div className="upload-item-root">
			<Button type="primary" onClick={() => this.handleClick()}><UploadOutlined/>{__(' Upload')}</Button>
			<div className="upload-item-list">
				{
					multi ? (
					fileList && fileList.length > 0 ? fileList.map((value, index) => {
						return <div key={index} className="upload-item-file-list">
							<PaperClipOutlined /> <span className="file-name" title={value.name}>{value.name} </span> <DeleteOutlined className="delete-icon"  onClick={ () => this.clearFile(index)} />
						</div>
					}) : <span className="file-name">{__('No file uploaded')}</span>) : (fileList && fileList.name) ? <div className="upload-item-file-list">
						<PaperClipOutlined /> <span className="file-name"><Tooltip className="upload-item-tooltip" placement="topLeft" title={fileList.name}>{fileList.name}</Tooltip></span> <DeleteOutlined className="delete-icon"  onClick={ () => this.clearFile(null, true)} />
					</div> : <span className="file-name">{__('No file uploaded')}</span>
				}
			</div>
		</div>
	}
}