import React from "react";
import Utils, { I, formatCurrencyNumber, formatNumber, calculator, filterAmount } from "Utils";
import { paymentMethod, paidViaList } from "Utils/constant";
import security from "../../../../../stores/security";
import { post } from 'srcPath/http';
import {DatePicker, message, Tooltip} from "antd";
import moment from "moment";
import DateSelector from "../../../../../components/DateSelector";
import { formatDate } from "Utils/format/formatDate";
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import { printf } from 'Utils/i18n';

class ExerciseOptions extends React.Component {
    constructor(props){
        super(props);
        this.state={
            isCanBeKept: true,
            exerciseQuantity:0,
            isAdvanceCollapse:0,
            data:{
                exercise_date:"",
                quantity_to_exercise:0,
                exercise_agreement:{name:__("No file uploaded"),file_obj:""},
                // associated_share_class_id:"",
                payment_date:"",
                amount_to_be_collected:0,
                payment_collected:"",
                actual_exercise_price:0,
                payment_method:"",
                payment_verified_admin_id:"",
                is_in_option_awards:0,
                amount_reimbursed:"",
                amount_reimbursement_date:"",
                market_price:0,
                paid_via:"",
            },
            dateFormat: "DD/MM/YYYY",
            canBeExerciseQuantity:0,
            emptyError:0,
            exercisedQuantityError:0,
            exerciseStatus:0,
        }
    }
    getQuantityToExercise = ( id ) => {
        const { minus } = calculator;
        if(this.props.pageData.title!="Warrant"){
            let vestedQuantity = Utils.resetNumberWithCommas(this.props.curSelectSecurity.vested_quantity);
            let exerciseQuantity = Utils.resetNumberWithCommas(this.props.curSelectSecurity.exercised_quantity);
            let canBeExerciseQuantity = minus(vestedQuantity, exerciseQuantity);
            this.setState({
                canBeExerciseQuantity: canBeExerciseQuantity
            })
        }
        if(this.props.pageData.title=="Warrant"){
            let vestedQuantity = Utils.resetNumberWithCommas(this.props.curSelectSecurity.vested_quantity);
            let totalQuantity = Utils.resetNumberWithCommas(this.props.curSelectSecurity.quantity);
            let exercisedQuantity = Utils.resetNumberWithCommas(this.props.curSelectSecurity.exercised_quantity);
            let remainingQuantity = minus(totalQuantity, exercisedQuantity);
            let canBeExerciseQuantity = minus(vestedQuantity, remainingQuantity) >0 ? remainingQuantity : vestedQuantity
            this.setState({
                canBeExerciseQuantity:canBeExerciseQuantity,
            })
        }
        post('getExerciseQuantity',{ id: id }).then((response)=> {
            if(response.data.code === 0){
                this.setState({
                    isCanBeKept: response.data.data.exercise_status,
                    exerciseQuantity: response.data.data.exercise_quantity
                })
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });

    }
    recordData=(e)=>{
        const { minus } = calculator;
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        if(dataKey=="is_in_option_awards"){
            if(dataVal==0){
                curData[dataKey] = 1;
                curData.quantity_to_exercise = this.state.exerciseQuantity
                this.setState({
                    exercisedQuantityError:0
                })
            }else{
                curData[dataKey] = 0;
                if( minus(Utils.resetNumberWithCommas(this.state.exerciseQuantity), Utils.resetNumberWithCommas(curData.quantity_to_exercise))>= 0){
                    this.setState({
                        exercisedQuantityError: 0
                    })
                }else{
                    this.setState({
                        exercisedQuantityError: 1
                    })
                }
            }
        }else{
            curData[dataKey] = dataVal;
        }
        this.setState({
            data:curData,
        })
    }
    setFileName=(key,e)=>{
        let curData = this.state.data;
        let fileObj = e.target.files[0];
        Utils.uploadFile(fileObj).then((result)=>{
            curData[key].name = fileObj.name;
            curData[key].file_obj = result;
            this.setState({
                data:curData
            })
        })
    }
    fileDelete=(key,e)=>{
        this.refs[key].value="";
        let curData = this.state.data;
        curData[key].name=__("No file uploaded");
        curData[key].file_obj="";
        $("."+key).addClass("hidden");
        this.setState({
            data:curData
        })
    }
    resetActive=()=>{
        window.$("#exerciseOptionModal .tab-pane").removeClass("active");
        window.$("#exercise_step1").addClass("active");
        window.$("#advanceOptions").removeClass("in");
    }
    refreshDataForExercise=()=>{
        this.setState({
            data:{
                exercise_date:"",
                quantity_to_exercise:"",
                exercise_agreement:{name:__("No file uploaded"),file_obj:""},
                payment_date:"",
                amount_to_be_collected:0,
                payment_collected:0,
                payment_verified_admin_id:"",
                is_in_option_awards:0,
                amount_reimbursed:"",
                amount_reimbursement_date:"",
                market_price:0,
                paid_via:""
            },
            emptyError:0,
            exercisedQuantityError:0,
            isAdvanceCollapse:0,
            isCanBeKept: true,
            exerciseQuantity:0,
        })
        this.resetActive();
    }
    exerciseOption=()=>{
        if(this.state.exerciseStatus == 1) return;
        this.setState({
            exerciseStatus:1,
        })
        let requestData = this.state.data;
        requestData.id = this.props.curSelectSecurity.id;
        if(this.props.pageData.title == "Warrant"){
            post('exerciseWarrant',requestData).then((response)=> {
                if(response.data.code === 0){
                    this.setState({
                        exerciseStatus:0,
                    })
                    const file = document.getElementById('exercise_select')
                    file.value = ''
                    window.$("#exerciseOptionModal").modal("hide")
                    this.props.getWarrantList();
                    this.refreshDataForExercise();
                }else{
                    console.log("failed, error code is: "+response.data.code);
                }
            }).catch(function (error) {
                console.log(error);
            });
        }else if(this.props.pageData.title == "SAR"){
            post('exerciseSar',requestData).then((response)=> {
                if(response.data.code === 0){
                    this.setState({
                        exerciseStatus:0,
                    })
                    const file = document.getElementById('exercise_select')
                    file.value = ''
                    this.refreshDataForExercise();
                    this.props.getEaList();
                    window.$("#exerciseOptionModal").modal("hide")
                }else{
                    console.log("failed, error code is: "+response.data.code);
                }
            }).catch(function (error) {
                console.log(error);
            })
        }else{
            post('exerciseOption',requestData).then((response)=> {
                if(response.data.code === 0){
                    // window.location.reload();
                    this.setState({
                        exerciseStatus:0,
                    })
                    window.$("#exerciseOptionModal").modal("hide")
                    this.refreshDataForExercise();
                    const file = document.getElementById('exercise_select')
                    file.value = ''
                    this.props.getEaList();
                }else{
                    console.log("failed, error code is: "+response.data.code);
                }
            }).catch(function (error) {
                console.log(error);
            });
        }
    }
    /**
     * input change通用事件
     * @param isDecimal 是否需要小数
     * @param e
     * 5 digit
     */
    inputChange5Digit = (isDecimal,e) => {
        const { mul, division } = calculator;
        const { curSelectSecurity } = this.props;
        const { target: element } = e;
        const { name: dataKey } = element;
        const filter = filterAmount(e, 9999999999999, isDecimal, 5);
        let curData = this.state.data;
        curData[dataKey] = filter.value;
        if(dataKey == "quantity_to_exercise"){
            curData.amount_to_be_collected = mul( Utils.resetNumberWithCommas(curData.quantity_to_exercise), Utils.resetNumberWithCommas(curSelectSecurity.exercise_price));
            curData.actual_exercise_price = division( Utils.resetNumberWithCommas(curData.payment_collected), Utils.resetNumberWithCommas(curData.quantity_to_exercise));
        }
        if(dataKey == "payment_collected"){
            curData.actual_exercise_price = division( Utils.resetNumberWithCommas(curData.payment_collected), Utils.resetNumberWithCommas(curData.quantity_to_exercise));
        }
        this.setState({
            data: curData
        }, () => {
            element.selectionStart = element.selectionEnd = filter.cursorPositionX;
        });
    }
    checkExercisedQuantity=()=>{
        const { minus } = calculator;
        // let curData = this.state.data;
        const { data, canBeExerciseQuantity} = this.state;
        let exerciseQuantity = Utils.getNumFromFormatNum(data.quantity_to_exercise)
        if(exerciseQuantity==0 || exerciseQuantity==""){
            this.setState({
                exercisedQuantityError:2,
            })
        }else{
            if( data.is_in_option_awards == 1 && minus(canBeExerciseQuantity, exerciseQuantity)>0){
                this.setState({
                    exercisedQuantityError:3,
                })
            }else if(exerciseQuantity > canBeExerciseQuantity){
                this.setState({
                    exercisedQuantityError:1,
                })
            }else{
                this.setState({
                    exercisedQuantityError:0,
                })
            }
        }
    }

    handleExerciseDateChange = (key, moment_key, date) => {
        const { dateFormat } = this.state;
        let curData = this.state.data;
        curData[key] = date.format( dateFormat);
        curData[moment_key] = date;
        this.setState({
            data:curData
        });
    }

    checkEmptyError=()=>{
        if(this.state.data.transfer_date== "" || this.state.data.quantity_to_exercise== "" || this.state.data.exercise_agreement.file_obj==""){
            this.setState({
                emptyError:1,
        })
        }
    }
    isAdvanceCollapse=()=>{
        let isAdvanceCollapse = this.state.isAdvanceCollapse;
        if(isAdvanceCollapse==0){
            this.setState({
                isAdvanceCollapse:1
            })
        }else{
            this.setState({
                isAdvanceCollapse:0
            })
        }
    }
    render(){
        const { mul, division, minus } = calculator;

        const currency = security.companyInfo.currency;
        const { data, dateFormat, isCanBeKept, canBeExerciseQuantity } = this.state;
        const { pageData } = this.props;
        let isFractionalShare = security.companyInfo.fractional_control;
        let curEaTpye = __("option");
        if(pageData.title=="SAR"){
            curEaTpye = __("SAR")
        }
        if(pageData.title=="Warrant"){
            curEaTpye = __("warrant")
        }
        let buttonName = __("Exercise options")
        if(pageData.title=="Warrant") {
            buttonName = __("Exercise warrant")
        }
        if(pageData.title=="SAR"){
            buttonName = __("Exercise SARs")
        }

        let curTextStatus = 1;
        if(
            data.exercise_agreement.file_obj==""
            ||data.exercise_date==""
            ||data.quantity_to_exercise==""
            || minus(Utils.resetNumberWithCommas(data.quantity_to_exercise), canBeExerciseQuantity)>0
            ||this.state.exercisedQuantityError != 0
        ){
            curTextStatus = 0;
        }

        let marketPriceIsEmpty = 0;
        if(pageData.title=='SAR' && ( data.market_price==0 || data.market_price=="" )){
            marketPriceIsEmpty =1
        }

        let triangleIcon = "blue-triangle-down"
        if(this.state.isAdvanceCollapse==1){
            triangleIcon = "blue-triangle-up"
        }
        const { curSelectSecurity, verifiedAdminList, formatVerifiedAdminList } = this.props;

        let amountCollected = formatFractionalNumber(mul(Utils.resetNumberWithCommas(data.quantity_to_exercise), Utils.resetNumberWithCommas(curSelectSecurity.exercise_price)));
        let minusPrice = formatFractionalNumber(minus(Utils.resetNumberWithCommas(data.market_price), Utils.resetNumberWithCommas(curSelectSecurity.exercise_price)));
        let amountToBePaid = formatFractionalNumber(mul(Utils.resetNumberWithCommas(data.quantity_to_exercise), Utils.resetNumberWithCommas(minusPrice)));
        let formatActualExercisePrice = formatCurrencyNumber(division(Utils.resetNumberWithCommas(data.payment_collected), Utils.resetNumberWithCommas(data.quantity_to_exercise))).split(" ");
        let actualExercisePrice = formatActualExercisePrice[1];
        let actualQuantity = minus(this.state.canBeExerciseQuantity,Utils.resetNumberWithCommas(data.quantity_to_exercise));
        return(
            <div className="modal fade" id="exerciseOptionModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="modal-dialog tab-content">
                    <div className="modal-content content520 tab-pane active" id="exercise_step1">
                        <div className="modal-header box-header">
                            <button type="button" className="close shut-btn" onClick={this.refreshDataForExercise} data-dismiss="modal" aria-hidden="true">×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Exercise')}  {this.props.curSelectSecurity.custom_label_id}</h4>
                        </div>
                        <div className="modal-body padding202620">
                            <div className="body-one">
                                {this.state.emptyError == 1 &&
                                <div id="exerciseEmptyError" className="alert alert-warning alert-dismissible pink-box">
                                    <p>{__('Required field(s) cannot be empty.')}</p>
                                </div>
                                }
                                {this.state.exercisedQuantityError == 1 &&
                                <div className="alert alert-warning alert-dismissible pink-box">
                                    <p>The number of {curEaTpye} you're trying to exercise is greater than the number of {curEaTpye} available.</p>
                                </div>
                                }
                                {this.state.exercisedQuantityError == 2 &&
                                <div className="alert alert-warning alert-dismissible pink-box">
                                    <p>{__("The number of %s you're trying to exercise can not be 0.").replace('%s',curEaTpye)}</p>
                                </div>
                                }
                                {this.state.exercisedQuantityError == 3 &&
                                <div className="alert alert-warning alert-dismissible pink-box">
                                    <p>{__('"Convert at IPO" requires that all issued shares are vested and exercised.')}</p>
                                </div>
                                }
                                <form className="form-horizontal font_reset_form">
                                    <div className="form-group add-items ">
                                        <label className="col-xs-6 add-label">{__('Exercise date')}<br/>
                                            <i><small className="text-muted">- {__('Required')}</small></i></label>
                                        <div className="col-xs-5 ">
                                            <DateSelector
                                                onChange={this.handleExerciseDateChange.bind(this, 'exercise_date', 'exercise_date_moment')}
                                                value={I(data['exercise_date_moment'], I(data['exercise_date'], '') ? moment(data['exercise_date'], dateFormat) : '')}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group add-items">
                                        <label className="col-xs-6 add-label">{__('Quantity to exercise')}<br/>
                                            <i><small className="text-muted">- {__('Required')}</small></i></label>
                                        <div className="col-xs-5">
                                            {isFractionalShare == 1 ?
                                                <input value={data.quantity_to_exercise}
                                                       onBlur={this.checkExercisedQuantity} name="quantity_to_exercise"
                                                       onChange={this.inputChange5Digit.bind(this, true)}
                                                       className="form-control text-right" type="text" placeholder="100"/> :
                                                <input value={data.quantity_to_exercise}
                                                       onBlur={this.checkExercisedQuantity} name="quantity_to_exercise"
                                                       onChange={this.inputChange5Digit.bind(this, false)}
                                                       className="form-control text-right" type="text" placeholder="100"/>
                                            }
                                        </div>
                                        <p className="text-muted col-xs-6 mb-0">{printf(__('Up to %s %s can be exercised.'),actualQuantity >0 ? formatFractionalNumber(actualQuantity) : 0 , curEaTpye)}</p>
                                    </div>
                                    <div className="form-group ">
                                        <label className="col-xs-6 add-label">{__('Exercise agreement')}<br/>
                                            <i><small className="text-muted">- {__('Required')}</small></i></label>
                                        <div className="col-xs-3 no-paddingright">
                                            <div className="select-btn select-blue convertiblenote-select-btn">
                                                {__('Choose a file')}
                                                <label htmlFor="exercise_select" className="sr-only" >File input</label>
                                                <input onChange={this.setFileName.bind(this,"exercise_agreement")} name="exercise_agreement" ref="exercise_agreement" type="file" id="exercise_select" />
                                            </div>
                                        </div>
                                        <div className="col-xs-3 amend-file-name">
                                            <span onClick={this.fileDelete.bind(this,'exercise_agreement')} className="close files-del hidden exercise_agreement">x</span>
                                            <p className="text-muted repur-fileName mb-0">{data.exercise_agreement.name}</p>
                                        </div>
                                        <p className="text-muted col-xs-6 marginTop6 mb-0">{__('Attach a signed Exercise Agreement.')}</p>
                                    </div>
                                    <div className="form-group add-items ">
                                        <label className="col-xs-6 add-label">{__('Payment date')}</label>
                                        <div className="col-xs-5 ">
                                            <DateSelector
                                                onChange={this.handleExerciseDateChange.bind(this, 'payment_date', 'payment_date_moment')}
                                                value={I(data['payment_date_moment'], I(data['payment_date'], '') ? moment(data['payment_date'], dateFormat) : '')}
                                            />
                                        </div>
                                    </div>
                                    {pageData.title != "SAR" &&
                                    <div>
                                        <div className="form-group add-items">
                                            <label className="col-xs-6 add-label">{__('Amount to be collected')}</label>
                                            <div className="col-xs-5">
                                                <div className="input-group">
                                                    <span className="input-group-addon">{currency}</span>
                                                    <input disabled name="amount_to_be_collected"
                                                           className="form-control text-right" type="text"
                                                           value={amountCollected}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group add-items">
                                            <label className="col-xs-6 add-label">{__('Payment collected')}</label>
                                            <div className="col-xs-5">
                                                {isFractionalShare == 1 ?
                                                    <div className="input-group">
                                                        <span className="input-group-addon"
                                                              id="realized_value">{currency}</span>
                                                        <input value={data.payment_collected} name="payment_collected"
                                                               onChange={this.inputChange5Digit.bind(this, true)}
                                                               className="form-control text-right" type="text"/>
                                                    </div> :
                                                    <div className="input-group">
                                                        <span className="input-group-addon"
                                                              id="realized_value">{currency}</span>
                                                        <input value={data.payment_collected} name="payment_collected"
                                                               onChange={this.inputChange5Digit.bind(this, false)}
                                                               className="form-control text-right" type="text"/>
                                                    </div>

                                                }
                                            </div>
                                        </div>
                                        <div className="form-group add-items">
                                            <label className="col-xs-6 add-label">{__('Prepay price')}</label>
                                            <div className="col-xs-5">
                                                <div className="input-group">
                                                    <span className="input-group-addon"
                                                          id="realized_value">{currency}</span>
                                                    <input disabled name="actual_exercise_price"
                                                           className="form-control text-right" type="text"
                                                           value={actualExercisePrice}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {pageData.title == "SAR" &&
                                    <div>
                                        <div className="form-group add-items">
                                            <label className="col-xs-6 add-label">{__('Market price')}<br/>
                                                <i><small className="text-muted">- {__('Required')}</small></i></label>
                                            <div className="col-xs-5">
                                                {isFractionalShare == 1 ?
                                                    <div className="input-group">
                                                        <span className="input-group-addon">{currency}</span>
                                                        <input value={data.market_price} name="market_price"
                                                               onChange={this.inputChange5Digit.bind(this, true)}
                                                               className="form-control text-right" type="text"/>
                                                    </div> :
                                                    <div className="input-group">
                                                        <span className="input-group-addon"
                                                              id="realized_value">{currency}</span>
                                                        <input value={data.market_price} name="market_price"
                                                               onChange={this.inputChange5Digit.bind(this, false)}
                                                               className="form-control text-right" type="text"/>
                                                    </div>

                                                }
                                            </div>
                                        </div>

                                        <div className="form-group add-items">
                                            <label className="col-xs-6 add-label">{__('Amount to be paid')}</label>
                                            <div className="col-xs-5">
                                                <div className="input-group">
                                                    <span className="input-group-addon">{currency}</span>
                                                    <input name="amount_to_be_paid" disabled
                                                           className="form-control text-right" type="text"
                                                           value={amountToBePaid}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group add-items">
                                            <label className="col-xs-6 add-label">{__('Paid via')}</label>
                                            <div className="col-xs-5">
                                                <select value={data.paid_via}  name="paid_via" onChange={this.recordData}  className="form-control select-container">
                                                    <option value={""}> -- </option>
                                                    {paidViaList && paidViaList.map((v,k)=>{
                                                        return (
                                                            <option key={k} value={v}>{v}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {pageData.title != "SAR" &&
                                    <div className="form-group add-items">
                                        <label className="col-xs-6 add-label">{__('Payment method')}</label>
                                        <div className="col-xs-5">
                                            <select value={data.payment_method} name="payment_method"
                                                    onChange={this.recordData}
                                                    className="form-control select-container">
                                                <option value={""}> --</option>
                                                {paymentMethod.map((v, k) => {
                                                    return (
                                                        <option key={k} value={v}>{v}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    }
                                    <div className="form-group add-items">
                                        <label className="col-xs-6 add-label">{__('Payment verified by')}</label>
                                        <div className="col-xs-5">
                                            <select value={data.payment_verified_admin_id}  name="payment_verified_admin_id" onChange={this.recordData}  className="form-control select-container">
                                                <option value={""}> -- </option>
                                                {verifiedAdminList && verifiedAdminList.map((v,k)=>{
                                                    return(
                                                        <option key={k} value={v.admin_id}>{v.admin_name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    {pageData.title =="Option grant" &&
                                    <div>
                                        <div className="marginBott20">
                                            <div className="checkbox send-check" id="keep_checkbox">
                                                <a className="convertible-ques left58">
                                                    <p className="convertible-tiptext modify-tiptext116">This will change the option award status to “Convert at IPO” while remaining as an option awards.</p>
                                                </a>
                                                { isCanBeKept == true ?
                                                    <input type="checkbox" className="send-box email-sendbox" id="isKeep" name="is_in_option_awards" disabled={ false } checked={this.state.data.is_in_option_awards}  value={this.state.data.is_in_option_awards} onClick={this.recordData}/> :
                                                    <input type="checkbox" className="send-box email-sendbox disabled-send-box" disabled={ true }/>
                                                }
                                                <label className="send-div" htmlFor="isKeep"></label>
                                                <span className=" emailMe">{__('Keep the exercised shares in Option awards.')}</span>
                                                {isCanBeKept == false &&
                                                <a className="cantTip">
                                                    <p className="convertible-tiptext cantTip-text modify-tiptext76">Only applicable to fully vested or terminated option award(s).</p>
                                                </a>
                                                }
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <a role="button" data-toggle="collapse" href="#advanceOptions" onClick={this.isAdvanceCollapse} className={triangleIcon+" vesting-collapse-a text-blue col-xs-4"}>{__('Advance options')}</a>
                                        </div>
                                        <div className="collapse advance_table" id="advanceOptions">

                                            <div className="form-group add-items">
                                                <label className="col-xs-6 add-label paddingLeft21">{__('Amount reimbursed by company')}</label>
                                                <div className="col-xs-5">
                                                    {isFractionalShare == 1 ?
                                                        <div className="input-group">
                                                            <span className="input-group-addon" id="realized_value">{currency}</span>
                                                            <input value={data.amount_reimbursed} name="amount_reimbursed"
                                                                   onChange={this.inputChange5Digit.bind(this, true)}
                                                                   className="form-control text-right" type="text"/>
                                                        </div>:
                                                        <div className="input-group">
                                                            <span className="input-group-addon" id="realized_value">{currency}</span>
                                                            <input value={data.amount_reimbursed}  name="amount_reimbursed"
                                                                   onChange={this.inputChange5Digit.bind(this,false)}
                                                                   className="form-control text-right" type="text"/>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="form-group add-items ">
                                                <label className="col-xs-6 add-label paddingLeft21">{__('Amount reimbursement date')}</label>
                                                <div className="col-xs-5 ">
                                                    <DateSelector
                                                        onChange={this.handleExerciseDateChange.bind(this, 'amount_reimbursement_date', 'amount_reimbursement_date_moment')}
                                                        value={I(data['amount_reimbursement_date_moment'], I(data['amount_reimbursement_date'], '') ? moment(data['amount_reimbursement_date'], dateFormat) : '')}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer box-footer invite-footer">
                            {(curTextStatus==0 || marketPriceIsEmpty==1) && <button type="button" className="btn boxbtn-to send-disabledBtn">{__('Review details')}</button>}
                            {(curTextStatus==1 && marketPriceIsEmpty==0) && <button type="button" data-toggle="tab" data-target="#exercise_step2" className="btn boxbtn-to bg-darkblue">{__('Review details')}</button>}
                        </div>
                    </div>
                    <div className="modal-content content480 tab-pane " id="exercise_step2">
                        <div className="modal-header box-header">
                            <a data-target="#exercise_step1" className="back-btn" aria-controls="repurchase-step1" role="tab" data-toggle="tab"></a>
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.refreshDataForExercise}>×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Exercise')} {this.props.curSelectSecurity.custom_label_id}</h4>
                        </div>
                        <div className="modal-body padding2026">
                            <div className="body-one">
                                <table className="table merge-table review-relationship-table">
                                    <tbody>
                                            <tr>
                                                <td>{__('Certificate number')}</td>
                                                <td>{this.props.curSelectSecurity.custom_label_id} ({this.props.curSelectSecurity.security_type})</td>
                                            </tr>
                                            <tr>
                                                <td>{__('Stakeholder name')}</td>
                                                <td>{this.props.curSelectSecurity.name}</td>
                                            </tr>
                                            <tr>
                                                <td>{__('Issue date')}</td>
                                                <td>{ formatDate(data.exercise_date) }</td>
                                            </tr>
                                            <tr>
                                                <td>{this.props.pageData.title=="Warrant"?__("Number of warrants") : __("Number of shares")}</td>
                                                <td>{formatFractionalNumber(data.quantity_to_exercise)}</td>
                                            </tr>
                                            <tr>
                                                <td>{__('Exercise Price')}</td>
                                                <td>{formatCurrencyNumber(this.props.curSelectSecurity.exercise_price)}</td>
                                            </tr>
                                            <tr>
                                                <td>{__('Payment date')}</td>
                                                <td>{ formatDate(data.exercise_date) }</td>
                                            </tr>
                                            {pageData.title != "SAR" &&
                                            <tr>
                                                <td>{__('Amount to be collected')}</td>
                                                <td>{formatCurrencyNumber(data.amount_to_be_collected)}</td>
                                            </tr>
                                            }
                                            {pageData.title != "SAR" &&
                                            <tr>
                                                <td>{__('Payment collected')}</td>
                                                <td>{formatCurrencyNumber(data.payment_collected)}</td>
                                            </tr>
                                            }
                                            {pageData.title != "SAR" &&
                                            <tr>
                                                <td>{__('Prepay price')}</td>
                                                <td>{formatCurrencyNumber(data.actual_exercise_price)}</td>
                                            </tr>
                                            }
                                            {pageData.title != "SAR" &&
                                            <tr>
                                                <td>{__('Prepay method')}</td>
                                                <td>{data.payment_method=="" ? "--" : data.payment_method}</td>
                                            </tr>
                                            }
                                            {pageData.title == "SAR" &&
                                            <tr>
                                                <td>{__('Market price')}</td>
                                                <td>{formatCurrencyNumber(data.market_price)}</td>
                                            </tr>
                                            }
                                            {pageData.title == "SAR" &&
                                            <tr>
                                                <td>{__('Amount to be paid')}</td>
                                                <td>{formatCurrencyNumber(amountToBePaid)}</td>
                                            </tr>
                                            }
                                            {pageData.title == "SAR" &&
                                            <tr>
                                                <td>{__('Paid via')}</td>
                                                <td>{data.paid_via =="" ? "--" : data.paid_via}</td>
                                            </tr>
                                            }
                                            <tr>
                                                <td>{__('Payment verified by')}</td>
                                                <td>{(data.payment_verified_admin_id==""||data.payment_verified_admin_id==0)? "--" : formatVerifiedAdminList[data.payment_verified_admin_id].admin_name}</td>
                                            </tr>
                                            {pageData.title == "Option grant" && data.is_in_option_awards == 1 &&
                                            <tr>
                                                <td>{__('Keep the exercised shares in Option awards')}</td>
                                                <td>{__('Yes')}</td>
                                            </tr>
                                            }
                                            {pageData.title=="Option grant" &&
                                            <tr>
                                                <td>{__('Amount reimbursed by company')}</td>
                                                <td>{formatCurrencyNumber(data.amount_reimbursed)}</td>
                                            </tr>
                                            }
                                            {pageData.title=="Option grant" &&
                                            <tr>
                                                <td>{__('Amount reimbursement date')}</td>
                                                <td>{formatDate(data.amount_reimbursement_date)}</td>
                                            </tr>
                                            }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer box-footer invite-footer">
                            <button type="button" className="btn boxbtn-to bg-darkblue update-btn" onClick={this.exerciseOption}>{this.state.exerciseStatus==1?__("Exercising"): buttonName}</button>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
export default ExerciseOptions;