import React, { Component } from 'react';
import { formatNumber } from 'Utils'
import classnames from 'classnames';
import './style.scss'
import backImg from './images/stock-digital-bg-back.png'
import backImgEa from './images/stock-digital-bg-back-green.png'

export default function DigitalCertificateBack(props) {
	const { data: digital_certificate, type } = props || {}
    const EAType = ['EA','RSA','RSU','ISO','NSO','INTL','SAR']
    const isEA = EAType.includes(type)

	return <div className={classnames('shares-stock-digital-back', { 'shares-stock-digital-back-ea': isEA })}>
        <img className="frontImg" src={!isEA ? backImg : backImgEa} />
        <div className='top-container'>
        <div className="shares-stock-digital-count"></div>
        {/* <div className="shares-stock-digital-title"></div> */}
        <p className="shares-stock-digital-content" style={{ whiteSpace: 'pre-line', marginTop: '20px' }}>{digital_certificate.back_content}</p>
        </div>
    </div>
}