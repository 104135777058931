import React, { Component, useState } from 'react';
import { withForm } from 'react-antd-formutil';
import { Form, Input, Button, message } from 'antd';
import { post } from 'srcPath/http';
import security from 'stores/security';
import Loading from 'components/Loading'
import './style.scss';

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
};
class AdditionalEmailModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			emailValue: '',
			emailPassword: '',
			passwordValidate: {
				validateStatus: 'success',
				errorMsg: null
			},
			emailValidate: {
				validateStatus: 'success',
				errorMsg: null
			}
		}
	}
	formRef = React.createRef()
	bindChangeEmail(e) {
		let emailValue = e.target.value
		this.setState({
			emailValue
		})
		this.validateEmail()
	}
	bindChangePassword(e) {
		let emailPassword = e.target.value
		this.setState({
			emailPassword
		})
		this.validatePasswrod()
	}
	onSubmit = () => {
		const { close, callback } = this.props
		let { emailValue, emailPassword } = this.state
		Loading.global(true)
		post('addSecondaryEmail', { uid: security.userInfo.id, secondary_email: emailValue, password: emailPassword }).then(res => {
			let { data } = res
			if (data.code === 0) {
				callback && callback()
				close()
			} else if (data.code === 1) {
				let emailValidate = {
					validateStatus: 'error',
					errorMsg: data.data
				}
				this.setState({
					emailValidate
				})
			} else if (data.code === 2) {
				let passwordValidate = {
					validateStatus: 'error',
					errorMsg: data.data
				}
				this.setState({
					passwordValidate
				})
			}
		}).catch(function (error) {
			message.error(data.data)
		});
		Loading.global(false)
	}
	validateEmail() {
		let emailValidate = {
			validateStatus: 'success',
			errorMsg: null
		}
		this.setState({
			emailValidate
		})
	}
	validatePasswrod() {
		let passwordValidate = {
			validateStatus: 'success',
			errorMsg: null
		}
		this.setState({
			passwordValidate
		})
	}
	render() {
		const { emailValue, emailPassword, passwordValidate, emailValidate } = this.state
		const { email } = this.props
		return <div className="email-modal">
			<div className="email-form">
				<Form {...layout} ref={this.formRef} name="control-hooks" className='email-model'>
					<Form.Item colon={false} name="emailValue" label={__('Additional email address')} validateStatus={emailValidate.validateStatus} help={emailValidate.errorMsg}>
						<Input value={emailValue} onChange={this.bindChangeEmail.bind(this)} autoComplete="off" />
					</Form.Item>
					<Form.Item colon={false} name="emailPassword" label={__('Confirm your password')} extra={__('Input the password you use to login with') + ' ' + email} validateStatus={passwordValidate.validateStatus} help={passwordValidate.errorMsg}>
						<Input type="password" value={emailPassword} onChange={this.bindChangePassword.bind(this)} autoComplete="new-password" />
					</Form.Item>
				</Form>
			</div>
			<div className="email-warning">{__('* An email will be sent to verify your additional email address.')}</div>
			<Button type="primary" loading={this.state.loading} onClick={this.onSubmit}>{__('Confirm')}</Button>
		</div>
	}
}

export default AdditionalEmailModal