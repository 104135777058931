import React from "react";
import { post } from 'srcPath/http';
import Utils from "Utils";
import Loading from 'components/Loading';
import { Link } from 'react-router-dom';
import security from 'stores/security';
import {formatNumber} from "../../../Utils";
import { printf, language } from 'Utils/i18n';

class SendInfoForSignature extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingStatus:1,
            sendStatus:0,
            draft_type:0,
            checkedIds:[],
            signatureAdminList: {
                certificate1_admin_name: "ning",
                certificate2_admin_name: "Ning Da",
                equity_plan_admin_name: "ning",
                convertibles_admin_name: "Ning Da",
                warrants_admin_name:""
            },
            errorMessage:{},
        }
    }
    getSignatureAdminList=()=>{
        post('getSignatureAdminList').then((response)=> {
            if(response.data.code === 0){
                this.setState({
                    signatureAdminList:response.data.data,
                    loadingStatus:0
                })
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    getCheckedId=()=>{
        let checkedIds = this.state.checkedIds;
        for (let i=0;i<this.props.curData.length;i++){
            if(this.props.curData[i].checkbox_status==true){
                checkedIds.push(this.props.curData[i].id)
            }
        }
        this.setState({
            checkedIds:checkedIds
        })
    }

    sendCertificateForSignature=()=>{
        if(this.state.sendStatus==1){
            return;
        }
        this.setState({
            sendStatus:1,
            loadingStatus:1,
        })
        post('createCoinWarrantsFromDrafts', {ids:this.state.checkedIds,admin_id:Utils.getLocalCompanyInfo().admin_id,warrant_block_id:this.props.curPlanId}).then((response)=> {
            if(response.data.code === 0){
                let successDetails = [];
                let failureDetails = {};
                //返回两种情况：is_success:1 成功  is_success:0 失败
                const data = response.data?.data || {}

                this.state.checkedIds?.forEach(val => {
                    if (data[val]?.is_success === 1) {
                        successDetails.push(val)
                    } else {
                        failureDetails[val] = data[val]
                    }
                })

                //成功即将此条数据从draft列表中移除
                if(successDetails?.length > 0){
                    this.props.removeSuccessData(successDetails) //执行父组件方法将成功数据从列表移除
                }
                //失败即显示失败提示
                if (Object.keys(failureDetails)?.length > 0) {
                    this.props.getErrorMessage(failureDetails)
                }
                this.setState({
                    sendStatus:0,
                    checkedIds:[],
                    // loadingStatus:0,
                })
                window.$("#sendInfoForSignatureModal").modal('hide');
                // this.getTaskData();
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    closeSendModel=()=>{
        $("#sendInfoForSignatureModal").modal("hide")
    }
    render(){
        let Shares = 0;
        let Count = 0;
        let FormatShares = 0;
        if(this.props.curData){
            for (let i=0;i<this.props.curData.length;i++){
                if(this.props.curData[i].checkbox_status==true){
                    Shares += parseFloat(Utils.resetNumberWithCommas(this.props.curData[i].quantity)) ;
                    Count += 1;
                    if(Shares!=0){
                        FormatShares = formatNumber(Shares,null);
                    }
                }
            }
        }
        let signatureAdmin = this.state.signatureAdminList.equity_plan_admin_name;
        let signatureTip = 0;
        if(this.props.pageData.type==0){
            if(this.state.signatureAdminList.certificate_type==1){
                if(this.state.signatureAdminList.certificate1_admin_name!=""){
                    signatureAdmin = this.state.signatureAdminList.certificate1_admin_name
                }else if(this.state.signatureAdminList.certificate1_admin_name==""){
                    signatureTip = 1
                    signatureAdmin = "--"
                }
            }else{
                if(this.state.signatureAdminList.certificate1_admin_name!=""&&this.state.signatureAdminList.certificate2_admin_name!=""){
                    signatureAdmin = this.state.signatureAdminList.certificate1_admin_name + " and " + this.state.signatureAdminList.certificate2_admin_name +","
                }else if(this.state.signatureAdminList.certificate1_admin_name==""&&this.state.signatureAdminList.certificate2_admin_name!=""){
                    signatureTip = 1
                    signatureAdmin = "-- and " + this.state.signatureAdminList.certificate2_admin_name +","
                }else if(this.state.signatureAdminList.certificate1_admin_name!=""&&this.state.signatureAdminList.certificate2_admin_name==""){
                    signatureTip = 1
                    signatureAdmin = this.state.signatureAdminList.certificate1_admin_name+" and --" +","
                }else if(this.state.signatureAdminList.certificate1_admin_name==""&&this.state.signatureAdminList.certificate2_admin_name==""){
                    signatureTip = 1
                    signatureAdmin = "-- "
                }
            }
        }else if(this.props.pageData.type==2){
            if(this.state.signatureAdminList.convertibles_admin_name===""){
                signatureTip = 1
                signatureAdmin = "--"
            }else{
                signatureAdmin = this.state.signatureAdminList.convertibles_admin_name
            }
        }else if(this.props.pageData.type==3){
            if(this.state.signatureAdminList.warrants_admin_name==""||this.state.signatureAdminList.warrants_admin_name==null){
                signatureTip = 1
                signatureAdmin = "--"
            }else{
                signatureAdmin = this.state.signatureAdminList.warrants_admin_name
            }

        }

        let propsTexts = this.props.pageData.text
        if (language === 'en_US') {
            propsTexts = propsTexts+"s"
        }
        let certiName = __('shares');
        if(this.props.pageData.title == __('RSU')) certiName = __('units')

        return(
            <div className="modal fade" id="sendInfoForSignatureModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="modal-dialog dialog-top40">
                    <div className="modal-content content580">
                        <div className="modal-header box-header">
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                            <h4 className="modal-title" id="myModalLabel">{ printf(__('Send %s for signature'),propsTexts )}</h4>
                        </div>
                        <div className="modal-body padding2026">
                            {this.state.loadingStatus === 1 &&
                            <div className="termsLoadingPosition">
                                <Loading />
                            </div>
                            }
                            {this.state.loadingStatus === 0 &&
                            <div>
                                {signatureTip==1 &&
                                <div className="alert alert-warning alert-dismissable pink-box eq-tipbox marginBott20" role="alert">
                                    <p>{__('Please set signatory first.')} <Link onClick={this.closeSendModel} to="/home/permissions/signatories">{__('Set Signatories')}</Link></p>
                                </div>
                                }
                                <div className="flexDiv">
                                    <div>
                                        <span className="send-ok"></span>
                                    </div>
                                    <div>
                                        <p className="transfer-title">{this.props.pageData.title}s</p>
                                        <p className="text-muted">{printf(__('%s %s(s) totaling %s %s will be issued.'), Count, this.props.pageData.text, FormatShares, certiName)}</p>
                                    </div>
                                </div>
                                <hr/>
                                <div className="flexDiv">
                                    <div>
                                        <span className="send-ok"></span>
                                    </div>
                                    <div>
                                        <p className="transfer-title">{__('Signatories')}</p>
                                        <p className="text-muted">{signatureAdmin} {__('will receive email notification(s) to sign the')} {this.props.pageData.text}.</p>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                        {signatureTip==1 &&
                        <div className="captable-footer box-footer">
                            <a className="btn boxbtn-to width160 send-disabledBtn">{__('Send for Signature')}</a>
                        </div>
                        }
                        {signatureTip == 0 &&
                        <div className="captable-footer box-footer">
                            <a onClick={this.sendCertificateForSignature} className="btn boxbtn-to width160 bg-darkblue ">{this.state.sendStatus==0?__("Send for Signature"):__("Sending")}</a>
                        </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
export default SendInfoForSignature;