import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { post } from 'srcPath/http';
import url from 'url';
import { message, Button, Input, Spin } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import * as Editor from 'wangeditor'
import i18n from 'i18next';
import './index.scss';

let editor = {}
export default class EditTemplate extends Component {
	state = {
		templateName: __('Template name'),
		consentContent: '',
		expirationDate: '',
		customMessage: '',
		loading: false,
		templateId: 0,
	}

	componentDidMount() {
		this.initQuery();
	}

	componentWillUnmount() {
		editor.destroy();
	}

	async initQuery() {
		const state = this.props.location.state || {};
		const { templateId } = state;
		if (templateId) {
			try {
				this.setState({
					templateId,
					loading: true
				});
				const { data: resData } = await post('getTemplateDetail', { template_id: templateId })
	
				const { code, data } = resData || {}
				this.setState({
					loading: false
				});
				if (code == 0) {
					const content = data.template_content;
					this.setState({
						templateName: data.template_name
					});
					this.renderEditContent(content);
				} else {
					message.error(data)
				}
			} catch(err) {
				message.error(err && err.message)
			}
		} else {
			this.renderEditContent()
		}
	}

	handleInput(e) {
		this.setState({
			templateName: e.target.value
		});
	}

	handleSave = async () => {
		const consentContent = editor.txt.html();
		const { templateName, templateId } = this.state;
		const params = {
			template_name: templateName,
			template_content: consentContent,
		};
		this.setState({ loading: true });
		let url = 'createTemplate';
		if (templateId) {
			url = 'updateTemplate';
			params.template_id = templateId;
		}

		try {
			const { data: resData } = await post(url, params)

			const { code, data } = resData || {}

			if (code == 0) {
				this.props.history.push('/home/board/consents/templates')
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}

		this.setState({ loading: false })
	}

	renderEditContent(content) {
		editor = new Editor('#new-board-template');
		editor.config.excludeMenus = [
			'emoticon',
			'video',
			'todo',
			'image',
			'table',
			'code',
    		'splitLine',
		];
		editor.config.lang = 'en';
		editor.config.fontSizes = {
			'12px': { name: '12px', value: '1' },
			'14px': { name: '14px', value: '2' },
			'16px': { name: '16px', value: '3' },
			'18px': { name: '18px', value: '4' },
			'20px': { name: '20px', value: '5' },
			'22px': { name: '22px', value: '6' },
			'24px': { name: '24px', value: '7' },
		  }
		editor.i18next = i18n;
		editor.create();
		if (content) {
			editor.txt.html(content);
		}
	}

	render() {
		const { templateName, loading } = this.state;

		return <div className="new-templates-root">
				<div className="back-btn-area">
					<Button><LeftOutlined /><Link to="/home/board/consents/templates">{__('Back to templates')}</Link></Button>
				</div>
                <div className="template-operate-area">
					<Button type="primary" className="board-consents-operate-save" onClick={this.handleSave.bind(this)}>{__('Save')}</Button>
                </div>
                <div className="board-consents-wrapper">
					<Spin tip="Loading..." spinning={loading}>
						<div className="board-consents-right-wrapper">
							<Input placeholder="Input consents name" value={templateName} onChange={this.handleInput.bind(this)} />
							<div id="new-board-template"></div>
						</div>
					</Spin>
				</div>
            </div>
	}
}