import React from 'react';
import { Input, Row, Col } from 'antd';
import { FormItem } from 'react-antd-formutil';
import { calculator } from 'Utils'
import './style.scss';

function Ownership(props) {
    const parser = (viewValue, $setViewValue) => {
        const reg = /^\D*(\d*(?:\.\d{0,2})?).*$/g;

        const value = viewValue.replace(reg, '$1');

        $setViewValue(value);

        return viewValue === '' ? null : calculator.division(Number(value), 1);
    }

    return <Row gutter={2} className="filter-ownership-root">
        <Col span={11}>
            <FormItem
                name="ownership.min"
                label={__('Ownership')}
                $parser={parser}
            >
                <Input addonAfter="%" placeholder={__('Min')}/>
            </FormItem>
        </Col>
        <Col span={2} className="filter-ownership-words">{__('to')}</Col>
        <Col span={11} className="filter-ownership-ownership">
            <FormItem
                name="ownership.max"
                label=""
                $parser={parser}
            >
                <Input addonAfter="%" placeholder={__('Max')}/>
            </FormItem>            
        </Col>
    </Row>
}

export default Ownership