import React from 'react';

import Stakehoder from '../forms/StakeholderOrWallet';
import Token from '../forms/Token';
import PlanSelect from '../forms/PlanSelect';
import VestingSchedule from '../forms/VestingSchedule'
import FormsDate from '../forms/FormsDate';
import Status from '../forms/Status';


export default function Form() { 
    return <div>
        <Token name="token_name" label="Token name" />
        <Stakehoder name="stakeholder_name" label="Stakeholder/Wallet name" />
        <PlanSelect name="plan_name" label="Plan name" />
        <FormsDate name='grant_date' label={__('Grant date')}/>
        <VestingSchedule />
        <FormsDate name='vesting_start_date' label={__('Vesting start date')}/>
        <Status />        
    </div>
}