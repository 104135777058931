import React from 'react';
import emptyFolderIcon from '../images/empty-folder-icon.png'
import './index.scss';

export default function SproutEmpty(props) {
	return <div className="documents-port-folio">
            {!props.hideTitle && <p className="sprout-empty-name">{props.title || __('Empty folder')}</p>}
            {!props.hideSubTitle && <p className="sprout-empty-sub-title">{props.description || __('Add new folder or upload files here.')}</p>}
            <div className="sprout-empty-content">
                <img src={props.image || emptyFolderIcon} />
            </div>
            {props.hideTitle && <p style={{ marginTop: '40px' }} className="sprout-empty-sub-title">{__('Please get in touch with the company admin to grant access.')}</p>}
        </div>
}