import React from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import security from "stores/security";
import Utils, { I } from "Utils";
import { post } from 'srcPath/http';
import Loading from 'components/Loading';
import ModifyWarrant from "./WarrantsModal/ModifyWarrant";
import AddVestingSchedule from "../components/AddVestingSchedule";
// import TransferShares from "../EquityAwards/EquityAwardsModal/TransferShares";
import CancelModal from "../components/CancelModal";
import AddAdminNotes from "../components/AddAdminNotes";
import Permission from 'components/Permission';
import 'assets/css/stakeholders.css';
import 'assets/css/securities.css';
import {Empty, Input, message, Badge, Dropdown, Button, Menu} from "antd";
import TransactionRender from 'B/components/TransactionHistory';
import VestMilestones from "../EquityAwards/EquityAwardsModal/VestMilestone";
import {formatCurrencyNumber, formatNumber} from "../../../Utils";
import CreateWarrantBlock from "./WarrantsModal/createWarrantBlock";
import WarrantBlockList from "./WarrantsModal/WarrantBlockList";
import TransferSafe from "../Convertibles/ConvertibleModal/TransferSafe";
import ExerciseOptions from "../EquityAwards/EquityAwardsModal/ExerciseOptions";
import WarrantsTable from "./WarrantsModal/WarrantsTable";
import AccelerateVesting from "../EquityAwards/EquityAwardsModal/AccelerateVesting";
import TerminateStakeholder from "../../Stakeholder/Stakeholder/StakeholdersModal/TerminateStakeholder";
import {CloseOutlined, DownOutlined, SearchOutlined} from "@ant-design/icons";
import ColumnController, { columnsFilter } from "components/ColumnController";
import reverseTerminationModal from "../components/ReverseTermination";
import Filter from 'components/Filter';
import { printf } from 'Utils/i18n';

const orderMap = {
    null: 0,
    descend: 3,
    ascend: 4,
    undefined: 0
}

class Warrants extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            checkedIds:[],
            current:1,
            warrantPageSize:100,
            listName:"securityLists",
            canBeCanceled:1,
            isShowCancelTip:0,
            currency:'USD',
            curSelectRelation:"all",
            dateOfIncorporation:"",
            noResult:0,
            isAbleToClear:0,
            warrantBlockList:[],
            vestingScheduleList:[
                {id:1,schedule_name:"abc1"},
                {id:2,schedule_name:"ddd2"}
            ],
            formatScheduleList:{},
            formatVerifiedAdminList:{},
            verifiedAdminList:[],
            stakeholders:[
                {id:1,nick_name:"rain"},
                {id:3,nick_name:"rain22"},
                {id:4,nick_name:"rain55"},
                {id:2,nick_name:"rain2"},
            ],
            securityLists : [],
            relationshipList:[
                {checked:false,relationShip:"Founder"},
                {checked:false,relationShip:"Investor"},
                {checked:false,relationShip:"Employee"},
                {checked:false,relationShip:"Officer"},
                {checked:false,relationShip:"Board member"},
                {checked:false,relationShip:"None entered"},
            ],
            curSelectSecurity:{
                key:0,
                id:2,
                security_type:"cs",
                admin_note:"",
                notes:"",
                email:"",
                schedule:"",
                custom_label_id:'CS-666',
                name:'Cody Belseager',
                status:'Outstanding',
                quantity:'100',
                payment_date:"",
                payment_collected:"",
                payment_verified_admin_id:"",
                issue_price_per_share:'1.00',
                transaction_value:'$100.00',
                issue_date:'12/12/2019',
                checkbox_status:false,
                sign_status:'1',
                purchase_agreement_file:{name:"",real_name:""},
                form_of_warrant_file:{name:"",real_name:""},

            },
            acceptanceStatus:[],
            filterList:[
                {
                    status:0,
                    name:"Equity plan",
                    jsonKey:"equity_plan_id",
                    value:[
                        {value:"",text:"Choose an equity plan"},
                        {value:"",text:"MS Ventures Equity Option Plan"},
                        {value:"",text:"test"},
                    ],
                },
                {
                    status:0,
                    name:"Share class",
                    jsonKey:"share_class_id",
                    value:[
                        {value:"",text:"Choose a share class"},
                        {value:"1",text:"MS Ventures Common Plan"},
                        {value:"2",text:"test2"},
                        {value:"3",text:"PS"},
                    ],
                },
                {
                    status:0,
                    name:"Acceptance status",
                    jsonKey:"sign_status",
                    value:[
                        {value:"",text:"Choose an acceptance status"},
                        {value:1,text:"Awaiting officer signature"},
                        {value:2,text:"Awaiting holder signature"},
                        {value:0,text:"Accepted"},
                    ],
                },
            ],
            filterName:{
                name:"Certificate",
                dateKey:"",

            },
            pageData:{
                name:"Warrant",
                title:"Warrant",
                text:"warrant",
            },
            tableChild : {},
            searchValue: '',
            searchSecurityLists: [],
            selectColumnList:[
                {checked:true,columnName:"Security",jsonKey:"custom_label_id",},
                {checked:true,columnName:"Stakeholder",jsonKey:"name",},
                {checked:false,columnName:"Status",jsonKey:"status",},
                {checked:false,columnName:"Issue date",jsonKey:"issue_date",},
                {checked:false,columnName:"Expiration date",jsonKey:"grant_expiration_date",},
            ],
            selectId: 0,
            draftWarrantsCount: '',
            formatSecurityList:[],
            activeBtnDisable:0,
            tableColumn: [],
            redisKey: 'warrants',
            // 翻页参数，搜索条件
            total: 0,
            pageNumber: 1,
            pageSize: 100,
            filter: {},
            sort: {}
        }
    }
    componentDidMount() {
        let certificateTip =  "Canceled, transferred, and repurchased certificates can't be canceled. Deselect conflicting rows";

        window.$(".cancel_pop").popover(
            {
                trigger:'click',
                placement : 'top',
                content : certificateTip,  //这里可以直接写字符串，也可以 是一个函数，该函数返回一个字符串；
                animation: false

            });
        this.getWarrantList();
    }
    getWarrantList=()=>{
        let pageSize = localStorage.getItem('warrantPageSize') || this.state.pageSize;
        this.setState({
            pageSize,
            loading: true
        })
        let { pageNumber, filter, searchValue, sort } = this.state;

        Loading.global(true)
        post('getWarrantList',{ pageSize, pageNumber, filter, search: searchValue, sort:{ ...sort, type: orderMap[sort?.type] || 0 }}).then((response)=> {
                if(response.data.code === 0){
                    let searchList = [...response.data.data.warrantList];
                    let searchRes = [];
                    searchList.map( ( v, k) => {
                        if( v ){
                            searchRes.push(v);
                        }
                    });
                let formatScheduleList = this.state.formatScheduleList;
                for(let i=0;i<response.data.data.vestingScheduleList.length;i++){
                    formatScheduleList[response.data.data.vestingScheduleList[i].id]=response.data.data.vestingScheduleList[i];
                }
                let formatSecurityList = this.state.formatSecurityList;
                for(let i=0;i<searchRes.length;i++){
                    formatSecurityList[searchRes[i].id]=searchRes[i];
                }
                this.setState({
                    warrantPageSize:pageSize,
                    total:response.data.data.total,
                    securityLists:searchRes || [],
                    searchSecurityLists:searchRes || [],
                    currency:response.data.data.currency,
                    stakeholders:response.data.data.stakeholders,
                    dateOfIncorporation:response.data.data.date_of_incorporation,
                    vestingScheduleList:response.data.data.vestingScheduleList,
                    warrantBlockList:response.data.data.warrantBlockList,
                    formatScheduleList,
                    formatSecurityList,
                    draftWarrantsCount: response.data.data.draftWarrantsCount,
                    filter,
                    loading: false
                });
                this.getPaymentAdmin();
                this.clear()
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
            Loading.global(false)
            this.setState({ loading: false })
        }).catch(function (error) {
            this.setState({ loading: false })
            Loading.global(false)
        });
    }

    getPaymentAdmin = () =>{
        let company_id = security.companyInfo.company_id;
        post('getPaymentAdmin',company_id).then((response)=> {
            if(response.data.code === 0){
                let formatVerifiedAdminList = this.state.formatVerifiedAdminList;
                response.data.data.map((v,k)=>{
                    formatVerifiedAdminList[v.admin_id] = response.data.data[k];
                })
                this.setState({
                    verifiedAdminList:response.data.data,
                    formatVerifiedAdminList
                })
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    resetVestingName=(id,vestingName,vestingId)=>{
        let curData = this.state.securityLists;
        for(let i=0;i<this.state.securityLists.length;i++){
            if(curData[i].id==id){
                if(vestingId!=0){
                    curData[i].vesting_schedule_id = vestingId;
                    curData[i].vesting_schedule = this.state.formatScheduleList[vestingId].schedule_name
                }else{
                    curData[i].vesting_schedule = vestingName;
                }
            }
        }
        this.setState({
            securityLists:curData,
            searchSecurityLists: curData,
        })
    }
    changeStatus=(num)=>{
        let securityLists = this.state.securityLists;
        if(securityLists[num].checkbox_status === false){
            securityLists[num].checkbox_status = true;
        }else{
            securityLists[num].checkbox_status = false;
            $('#warrant_check01').prop("checked",false);
        }
        this.setState({
            securityLists:securityLists,
            searchSecurityLists: securityLists,
        })
    }
    selectAllSecurities=()=>{
        var securityLists = this.state.securityLists;
        if($('#warrant_check01').prop("checked")){
            $('.listCheckbox').prop('checked',true);
            for(let i=0;i<securityLists.length;i++){
                securityLists[i].checkbox_status = true;
            }
        }else{
            $('.listCheckbox').prop('checked',false);
            for(let i=0;i<securityLists.length;i++){
                securityLists[i].checkbox_status = false;
            }
        }
        this.setState({
            securityLists:securityLists,
            searchSecurityLists: securityLists,
        })
    }
    clear=()=>{
        const { securityLists, tableChild } = this.state;
        const list = [...securityLists];
        list.forEach( (v, k) => {
            list[k]['checkbox_status'] = false;
        })
        this.setState({
            securityLists:list,
            searchSecurityLists: list,
            selectCount: 0,
            selectIndex: 0
        })
        tableChild.clearAll();
    }
    clearInput=()=>{
        this.refs.inputContent.value = "";
    }

    setFilter=(key)=>{
        for(let i=0;i<this.state.relationshipList.length;i++){
            if(i !== key){
                this.state.relationshipList[i].checked = false;
            }
        }
        let curRelationshipList = this.state.relationshipList;
        curRelationshipList[key].checked=!curRelationshipList[key].checked;
        this.setState({
            relationshipList:curRelationshipList
        });
        if(curRelationshipList[key].checked === false){
            this.setState({
                curSelectRelation:"all"
            });
        }else{
            this.setState({
                curSelectRelation:this.state.relationshipList[key].relationShip
            });
        }
    }
    removeTip=()=>{
        window.$(".signTipBox").addClass("removeTip");
    }

    //resetSecurityStatus
    resetSecurityStatus = (id,status) =>{
        let securityLists = this.state.securityLists;
        securityLists.forEach( (v, k)=>{
            if(v.id == id){
                v.status = status;
                v.termination_date = ""
            }
        })
        this.setState({
            securityLists:securityLists
        })

    }

    //Id定位security
    setCurSelectSecurityNew = (id) => {
        const { securityLists } = this.state;
        let info = {};
        securityLists.forEach( (v, k)=>{
            if(v.id == id){
                info = {...v}
            }
        });
        this.setState({
            curSelectSecurity: info
        });
    }

    saveState = (key, value) => {
        let data = {};
        data[key] = value;
        this.setState(data)
    }

    //resetCancel
    resetCancel = (id,status,cancellation_date) =>{
        let securityLists = this.state.securityLists;
        securityLists.forEach( (v, k)=>{
            if(v.id == id){
                v.status = status;
                v.cancellation_date = cancellation_date
            }
        })
        this.setState({
            securityLists:securityLists
        })

    }

    setSelectId = (id) =>{
        const { formatSecurityList } = this.state;
        this.setState({
            selectId: id[0]
        });
        for(let i = 0; i < id.length; i++){
            if(formatSecurityList[id[i]].status != 'Transferred'){
                this.setState({
                    activeBtnDisable : 0
                })
            }else{
                this.setState({
                    activeBtnDisable : 1
                })
                return;
            }
        }
    }

    setModifiedSecurity=(key,security,listName)=>{
        let curSecurityList = this.state.securityLists;
        curSecurityList[key] = security;
        this.setState({
            securityLists:curSecurityList,
            searchSecurityLists: curSecurityList,
        })
    }

    setDataForModifiedSecurity=(id,security)=>{
        let curSecurityLists = this.state.securityLists;
        curSecurityLists.forEach( (v, k)=>{
            if(v.id == id){
                curSecurityLists[k] = security;
            }
        })
        this.setState({
            securityLists:curSecurityLists,
            searchSecurityLists: curSecurityLists
        })
    }

    setInitShareNote=()=>{
        this.refs.addSharesAdminNote.setInitNote();
    }

    /**
     * reverse termination
     */
    reverseTermination = (record, id) =>{
        reverseTerminationModal(record,id, this.getWarrantList)
    }

    resendEmail = ()=>{
        if(this.state.sendingStatus === 1){
            return;
        }
        this.setState({
            sendingStatus: 1
        });

        Loading.global(true)
        post('sendEmail2Signatories',{security_id:this.state.curSelectSecurity.id}).then((response)=> {
            if(response.data.code === 0){
                message.success(__("Resent successfully!"));
                this.setState({
                    sendingStatus: 0
                });
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
            Loading.global(false)
        }).catch(function (error) {
            Loading.global(false)
        });
    }
    reverseCancellation=()=>{
        if(Utils.isLocal()){
            let curSelectSecurity = this.state.curSelectSecurity;
            this.resetSecurityStatus(curSelectSecurity.id,'Outstanding');
            return;
        }
        var requestData = {};
        requestData.id = this.state.curSelectSecurity.id;
        requestData.security_type = this.state.curSelectSecurity.security_type;

        Loading.global(true)
        post('reverseCancellation',requestData).then((response)=> {
            if(response.data.code === 0){
                this.resetSecurityStatus(requestData.id,'Outstanding');
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
            Loading.global(false)
        }).catch(function (error) {
            console.log(error);
            Loading.global(false)
        });
    }

    clearFilterData=()=>{
        this.refs.filterCertificate.clearFilterData();
        this.getWarrantList();
        this.setState({
            noResult:0,
            isAbleToClear:0,
        })
    }
    changeCancelNum = () => {
        const { securityLists, checkedIds } = this.state;
        this.refs.cancelModal.changeCancelNum();
        if(securityLists)
        for(let i=0; i<securityLists.length; i++){
            if(securityLists[i].checkbox_status){
                checkedIds.push(securityLists[i].id)
            }
        }
        this.setState({
            checkedIds
        })
    }
    checkSecurityStatus=()=>{
        let curList = this.state.securityLists;
        let flag =1;
        for (let i=0;i<curList.length;i++){
            if(curList[i].checkbox_status == true && curList[i].status!="Outstanding" ){
                flag = 0;
                break;
            }
        }
        if(flag == 0){
            this.setState({
                canBeCanceled:0
            })
        }else{
            this.setState({
                canBeCanceled:1
            })
        }

    }
    getVestingData=(key)=>{
        this.refs.addVesting.getVestingData(key);
        // this.refs.addVesting.getScheduleDetail();
    }
    showCertificateTip=()=>{
        // window.$(".cancel_pop").removeClass("hidden");
        this.setState({
            isShowCancelTip:1
        })
    }
    deselect=()=>{
        let securityLists = this.state.securityLists;
        $('#warrant_check01').prop("checked",false);
        for(let i=0;i<securityLists.length;i++){
            if(securityLists[i].status=="Canceled"||securityLists[i].status=="Transferred"){
                securityLists[i].checkbox_status=false
            }
        }
        this.setState({
            securityLists:securityLists,
            searchSecurityLists: securityLists,
            canBeCanceled:1,
            isShowCancelTip:0,
        })
    }
    setStatusToRepurchased=(id)=>{
        let curData = this.state.securityLists;
        for(let i=0;i<this.state.securityLists.length;i++){
            if(curData[i].id==id){
                curData[i].status="Repurchased"
            }
        }
        this.setState({
            securityList:curData
        })
    }
    getVestMilestone=()=>{
        this.refs.vestMilestone.getMilestoneList();
    }
    getPrincipal=()=>{
        this.refs.transferWarrant.getPrincipal();
    }
    getInitVestingList=()=>{
        this.refs.acceleratedVesting.getInitVestingList();
        this.refs.acceleratedVesting.setDefaultDate();
    }
    getWarrantBlockList=()=>{
        this.refs.warrantBlockList.getWarrantBlockList();
    }
    initPageData=()=>{
        this.refs.warrantBlockModal.initPageData();
    }
    getQuantityToExercise=( id )=>{
        this.refs.exerciseWarrant.getQuantityToExercise( id );
    }

    /**
     * 更新table checkbox
     * @param selectCount 选中总计
     * @param selectIndexArray 有更新的checkbox下标和状态
     * @param isEmpty checkbox是否为全不选状态
     */
    updateSelectCount = (selectCount, selectIndexArray, isEmpty = false) => {
        const { securityLists } = this.state;
        let list = [...securityLists];
        if(isEmpty){
            list.forEach( (v, k) =>{
                list[k]['checkbox_status'] = false;
            })
        }else{
            selectIndexArray.forEach( (v, k) => {
                list[v.selectIndex]['checkbox_status'] = v.checked;
            })
        }
        this.setState(() => ({
            selectCount,
            selectIndex : selectIndexArray[0],
            securityLists : list
        }))
    }

    tableObj = (ref) => {
        this.setState(()=>({
            tableChild : ref
        }))
    }

    /**
     * 搜索列表数据
     * @param e
     */
    searchList = (e) => {
        const { value } = e.target;
        this.setState({
            searchValue: value,
        })
    }

    /**
     * Actions按钮
     * @returns {JSX.Element}
     */
    getActionsBottom = () => {
        const { selectCount, canBeCanceled } = this.state;
        const modifyDisable = selectCount == 1 ? false : true;
        const cancelDisable = selectCount > 0 && canBeCanceled == 1 ? false : true;
        return <Dropdown disabled={modifyDisable && cancelDisable ? true : false} overlay={this.actionsMenu} placement="bottomRight" trigger="click">
            <Button
                type="primary"
                className="shares-actions"
            ><div className="actions-left">{__('Actions')}</div><DownOutlined className="actions-right" /></Button>
        </Dropdown>
    }

    /**
     * Actions菜单
     * @returns {JSX.Element}
     */
    actionsMenu = () => {
        const { canBeCanceled, selectCount, selectIndex: indexArray, selectId } = this.state;
        return (
            <Menu>
                {selectCount == 1 && <Menu.Item onClick={this.setCurSelectSecurityNew.bind(this, selectId)}><a data-toggle="modal" data-target="#modifyWarrantModal">{__('Modify warrant')}</a></Menu.Item>}
                {canBeCanceled == 1 && <Menu.Item ><a data-toggle="modal" onClick={this.changeCancelNum} data-target="#cancelModal">{__('Cancel warrant')}</a></Menu.Item>}
            </Menu>
        )
    }

    /**
     * 清空搜索内容
     */
    closeSearch = () => {
        this.setState({
            searchValue: '',
        }, ()=> this.getWarrantList());
    }

    /**
     * column 排序
     * @param sort
     */
    sortTable = sort => {
        this.setState({ sort }, ()=>this.getWarrantList())
    }

    onPageChange = (current, pageSize) => {
        localStorage.setItem('warrantPageSize', pageSize);
        this.setState({
            pageNumber: pageSize === this.state.pageSize ? current : 1,
            pageSize
        })
    }
    updateState = data => this.setState(data);
    // 筛选条件逻辑，如果有分页，需要把查询条件保存在state 供分页使用
    onFilterChange = (value = {}) => {
        if (JSON.stringify(value) !== JSON.stringify(this.state.filter)) {
            this.setState({
                filter: value,
                pageNumber: 1
            }, () => this.getWarrantList())
        }
    }

    render(){
        const { currency = '', securityLists, curSelectSecurity, searchValue, selectId, draftWarrantsCount, activeBtnDisable, tableColumn, redisKey, total, pageSize, pageNumber, loading } = this.state
        let selectCount=0;
        let selectKey = "";
        for(let j=0;j< this.state.securityLists.length;j++){
            if(this.state.securityLists[j].checkbox_status === true){
                selectCount++;
                selectKey = j;
            }
        }
        let actionButtonClass ='btn action-disabledBtn action-btn dropdown-toggle disabled';

        if(selectCount >=1 && activeBtnDisable == 0){
            actionButtonClass = 'btn action-availableBtn action-btn dropdown-toggle';
        }
        return(
            <div className="content">
                <TerminateStakeholder getTerminatedList={this.getWarrantList} setModifiedSecurity={this.setModifiedSecurity} curSelectStakeholder={this.state.curSelectSecurity}/>
                <ModifyWarrant formatVerifiedAdminList={ this.state.formatVerifiedAdminList} verifiedAdminList={ this.state.verifiedAdminList} getWarrantList={this.getWarrantList} currency={this.state.currency} dateOfIncorporation={this.state.dateOfIncorporation} ref="modifyShares" setModifiedSecurity={this.setModifiedSecurity}  curSelectSecurity={this.state.curSelectSecurity}/>
                <AddVestingSchedule getSecurityList={this.getWarrantList} ref={"addVesting"} listName={this.state.listName} resetVestingName={this.resetVestingName} vestingScheduleList={this.state.vestingScheduleList}   curSelectSecurity={this.state.curSelectSecurity} />
                {/*<RepurchaseShares setStatusToRepurchased={this.setStatusToRepurchased} listName={this.state.listName} setModifiedSecurity={this.setModifiedSecurity}  curSelectSecurity={this.state.curSelectSecurity} />*/}
                <ExerciseOptions  formatVerifiedAdminList={ this.state.formatVerifiedAdminList} verifiedAdminList={this.state.verifiedAdminList} getWarrantList={this.getWarrantList} pageData={this.state.pageData} ref="exerciseWarrant" curSelectSecurity={this.state.curSelectSecurity}/>
                <TransferSafe ref={'transferWarrant'} pageData={this.state.pageData} currency={this.state.currency} curSelectSecurity={this.state.curSelectSecurity} getWarrantList={this.getWarrantList}/>
                <CancelModal resetCancel={this.resetCancel} resetSecurityStatus={this.resetSecurityStatus} currency={this.state.currency} ref="cancelModal" setModifiedSecurity={this.setModifiedSecurity} dateOfIncorporation={this.state.dateOfIncorporation} securityList={this.state.securityLists} pageData={this.state.pageData} curSelectSecurity={this.state.curSelectSecurity} checkedIds={this.state.checkedIds} getData={this.getWarrantList} />
                <AddAdminNotes setModifiedSecurity={this.setModifiedSecurity} setDataForModifiedSecurity={this.setDataForModifiedSecurity} ref="addSharesAdminNote" curSelectSecurity={this.state.curSelectSecurity} />
                {/*<RequestStockSplit/>*/}
                <VestMilestones listName={this.state.listName} ref="vestMilestone" curSelectSecurity={this.state.curSelectSecurity}/>
                <AccelerateVesting ref={"acceleratedVesting"} listName={this.state.listName} getData={this.getWarrantList} curSelectSecurity={this.state.curSelectSecurity} />
                <WarrantBlockList ref="warrantBlockList" currency={this.state.currency} getWarrantList={this.getWarrantList}/>
                <CreateWarrantBlock ref="warrantBlockModal" currency={this.state.currency} getWarrantList={this.getWarrantList}/>
                <div className="stakeholder-conCenter scroll-cont">
                    <div className="holders-nav warrant-table clearfix">
                        <div className="action-box onset">
                            <Permission type="securities_warrants_actions" hide>
                                <div className="btn-group">
                                    {this.getActionsBottom()}
                                </div>
                            </Permission>
                        </div>
                        <div className="form-group search-group banner-rightBox offset draft-statistic">
                            {selectCount>=1 &&
                            <div className="stateBox">
                                <p className="items-tip shares_items_tip">{printf(__('%s of %s items selected'),selectCount,this.state.securityLists.length)}</p>
                                <button onClick={this.clear} type="button" className="bg-dark holders-clearBtn">{__('Clear selection')}</button>
                            </div>
                            }
                            {/*{this.state.noResult==0 && this.state.isAbleToClear==1 &&*/}
                            {/*<a  className="mt-10 mr-10" onClick={this.clearFilterData}>Clear Filter</a>*/}
                            {/*}*/}

                            <ColumnController filterList={tableColumn} redisKey={redisKey} thisObj={this} />
                            <div className="mr-10">
                                    <Filter callback={val => this.onFilterChange(val)} fiterData={this.state.filter} type="warrants" />
                                    &nbsp;&nbsp;
                            </div>
                            <div className="stakeholder-search-box">
                                <Input
                                    className="stakeholder-search-input"
                                    value={searchValue}
                                    onChange={this.searchList}
                                    onPressEnter={this.getWarrantList}
                                    prefix={<SearchOutlined className="search-prefix-icon" onClick={this.getWarrantList} />}
                                    suffix={<CloseOutlined style={{opacity: searchValue ? 1 : 0}} onClick={this.closeSearch} className="search-close-icon" />}
                                    placeholder={__('Search')}
                                />
                            </div>

                            <Permission type="securities_warrants_warrantBlock" hide>
                                <div className="btn-group">
                                    <a className="btn drafts_btn security-manageBtn bg-darkblue dropdown-toggle" data-toggle="dropdown">{__('Manage warrant blocks')}<span className="triangle triangle-whitedown manage_triangle"></span></a>
                                    <ul className="dropdown-menu mange-downmenu shares-downmenu">
                                        <li><a className="add-stake"  data-toggle="modal" onClick={this.getWarrantBlockList} data-target="#warrantBlockListModal">{__('Edit warrant blocks')}</a></li>
                                        <li><a  data-toggle="modal" onClick={this.initPageData} data-target="#createWarrantBlockModal">{__('Create warrant block')}</a></li>
                                    </ul>
                                </div>
                            </Permission>
                            <Permission type="securities_templates" hide>
                                <Link to={{ pathname : `/home/securities/templates/2`,state:{activeKey:'2'}} } className="btn marginRig6 drafts_btn bg-darkblue">{__('Manage vesting')}</Link>
                            </Permission>

                            <Badge className="draft-statistic-badge" count={I(draftWarrantsCount, 0)}>
                                {
                                    this.state.warrantBlockList.length == 0 ? <div className="btn-group draft-statistic-group">
                                            <a disabled className="btn drafts_btn send-disabledBtn">{__('Draft warrants')}</a></div> :
                                        <Permission type="securities_draftWarrants" hide>
                                            <Link to="/home/securities/warrants/draft-warrants" className="btn drafts_btn bg-darkblue">{__('Draft warrants')}</Link>
                                        </Permission>
                                }
                            </Badge>
                        </div>
                    </div>
                    <div className=" table-box">

                        <WarrantsTable
                            loading={loading}
                            setCurSelectSecurity={this.setCurSelectSecurity}
                            setCurSelectSecurityNew={this.setCurSelectSecurityNew}
                            resetSecurityStatus={this.resetSecurityStatus}
                            resetCancel={this.resetCancel}
                            setSelectId={this.setSelectId}
                            checkSecurityStatus={this.checkSecurityStatus}
                            securityLists={securityLists}
                            getVestingData={this.getVestingData}
                            getVestMilestone={this.getVestMilestone}
                            getInitVestingList={this.getInitVestingList}
                            getQuantityToExercise={this.getQuantityToExercise}
                            getPrincipal={this.getPrincipal}
                            reverseCancellation={this.reverseCancellation}
                            setInitShareNote={this.setInitShareNote}
                            curSelectSecurity={curSelectSecurity}
                            resendEmail={this.resendEmail}
                            updateSelectCount={this.updateSelectCount}
                            onRef={this.tableObj}
                            reverseTermination={this.reverseTermination}
                            saveState={this.saveState}
                            sortTable={this.sortTable}
                            tableColumn={columnsFilter(tableColumn, redisKey, this.state.sort)}
                            paginationConfig={{
                                total,
                                pageSize,
                                current:pageNumber,
                                showSizeChanger:true,
                                onChange: this.onPageChange,
                                showTotal: (sum) => <span>{sum} {__('items')}</span>
                            }}

                        />

                    </div>
                </div>
            </div>
        )
    }
}

export default Warrants;

