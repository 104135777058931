import React, { Component, Fragment } from 'react';
import withLogin from 'components/withLogin';
import security from 'stores/security';
import { observer } from 'mobx-react';
import { Input, message, Tabs, Tooltip } from 'antd';
import { post } from 'srcPath/http';
import { cloneDeep } from 'lodash';
const pinyin = require('pinyin');
import { QuestionCircleOutlined } from '@ant-design/icons';
import plusIcon from './images/star.png'
import removeIcon from './images/star-active.png'
import classnames from 'classnames';
import { printf } from 'Utils/i18n';
import './style.scss';

const { Search } = Input;
const { TabPane } = Tabs;
const limitCount = 15
@withLogin
@observer
export default class SearchPage extends Component {
    state = {
        wordList: ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'],
        selectWord: '',
        searchWord: '',
        wordFilterList: [],
        currentTab: '2',
        defaultAdmin: [],
        defaultStakeholder: []
    }

    componentDidMount() {
        this.init()
    }

    init = () => {
        const { defaultAdmin = [], defaultStakeholder = [] } = security || {}
        const { wordList, currentTab } = this.state
        const wordObj = {}
		const corporations = currentTab === '1' ? security.corporations : security.portfolios || []
        const reg = /[^\u4E00-\u9FA5]/

        wordList.map((item) => {
            corporations.map((corp) => {
                let firstLetter = corp?.company_name || corp
                if (!reg.test(firstLetter[0])) { // 是汉字 则需要转化
                    const letter = pinyin(firstLetter[0])
                    firstLetter = letter[0][0]
                }
                if (firstLetter.toUpperCase().startsWith(item)) {
                    if (!wordObj[item]) {
                        wordObj[item] = [{
                            name: corp.company_name || corp,
                            id: corp.company_id
                        }]
                    } else {
                        wordObj[item].push(
                            {
                                name: corp.company_name || corp,
                                id: corp.company_id
                            }
                        )
                    }
                }
            })
        })
        corporations.map((corp) => {
            let firstLetter = corp?.company_name || corp
            const reg = new RegExp('[a-zA-Z\\s]+')
            if (!isNaN(firstLetter[0]) || !reg.test(firstLetter[0])) { // 数字开头或者其他字符开头
                if (!wordObj['Others']) {
                    wordObj['Others'] = [{
                        name: corp.company_name || corp,
                        id: corp.company_id
                    }]
                } else {
                    wordObj['Others'].push(
                        {
                            name: corp.company_name || corp,
                            id: corp.company_id
                        }
                    )
                }
            }
        })

        let wordFilterList = []
        for (let key in wordObj) {
            wordFilterList.push({
                key,
                value: wordObj[key]
            })
        }
        this.setState({
            wordFilterList: wordFilterList,
            defaultAdmin,
            defaultStakeholder
        })
    }

    onSearch = (value) => {
        this.setState({
            searchWord: value
        })
    }

    handleSelect = (e) => {
        const { currentTab } = this.state;
        const id = e.target.getAttribute("data-id");
        if (e.target.tagName === 'SPAN' && id) {
            const companyList = currentTab === '1' ?  security.corporations : security.portfolios

            const currentItem = companyList.find(item => +id === +item.company_id) 

            if (security.isProfile == 1) {
                post('changeCompanyLog', { access_token: security.SPROUT_TOKEN }).then(res => {
                }).catch(function (error) {
                    message.error(error.data)
                });
            }
            if (currentItem) {
                security.sendEvent();

                security.setCompanyInfo = currentItem
                security.setPlatform =  currentTab === '1' ?  'B' : 'C'
                this.props.history.push("/");
            }
        }
    }

    handleClickWord = (e) => {
        let id = e.target.getAttribute("data-id");
        const { selectWord } = this.state
        if (selectWord === id) {
            id = ''
        }
        if (e.target.tagName === 'SPAN') {
            this.setState({
                selectWord: id
            })
        }
    }

    handleOperate = async (id, type) => {
        const { defaultAdmin = [], defaultStakeholder = [] } = this.state
        const isAdmin = type === 'admin'
        
        const filterList = (isAdmin ? defaultAdmin : defaultStakeholder) || []
        const index = filterList.findIndex(i => i.id === id)
        const action = index > -1 ? 'delete' : 'add'

        if (isAdmin) {
            if (defaultAdmin.length > (limitCount - 1) && action === 'add') {
                return message.warn(printf(__('You can add up to %s accounts'), limitCount))
            } else if (defaultAdmin.length < 6 && action === 'delete') {
                return message.warn('You need to keep at least five')
            }
        } else {
            if (defaultStakeholder.length > (limitCount - 1) && action === 'add') {
                return message.warn(printf(__('You can add up to %s accounts'), limitCount))
            } else if (defaultStakeholder.length < 6 && action === 'delete') {
                return message.warn(__('You need to keep at least five'))
            }
        }
        
        // 增删操作
        const params = {
            type,
            action,
            default_company_id: id
        }
        try {
			const { data: resData } = await post('editUserDefaultCompany', params)
			const { code, data } = resData || {}

			if (code == 0) {
                if (type === 'admin') {
                    this.setState({
                        defaultAdmin: data || []
                    })
                    security.setDefaultAdmin = data
                } else {
                    this.setState({
                        defaultStakeholder: data || []
                    })
                    security.setDefaultStakeholder = data
                }
			}
		} catch (e) {
			message.error(e.message)
		}
    }

    handleChangeTab = (activeKey) => {
        this.setState({
            currentTab: activeKey
        }, () => {
            this.init()
        })
    }

    formatIcon = (id, type) => {
        const { defaultAdmin = [], defaultStakeholder = [] } = this.state
        const filterList = (type === 'admin' ? defaultAdmin : defaultStakeholder) || []
        const index = filterList.findIndex(i => i.id === id)
        return index > -1 ? removeIcon : plusIcon
    }

    formatTooltip = (id, type) => {
        const { defaultAdmin = [], defaultStakeholder = [] } = this.state
        const filterList = (type === 'admin' ? defaultAdmin : defaultStakeholder) || []
        const index = filterList.findIndex(i => i.id === id)
        return index > -1 ? __('Remove from the dropdown menu') : __('Add to the dropdown menu')
    }

    render() {
        const { wordList, wordFilterList, selectWord, searchWord, defaultAdmin = [], defaultStakeholder = [], currentTab } = this.state
		return <div className="sprout-search-company-root">
			<div className="search-company-wrap">
                <Search
                    addonBefore={<QuestionCircleOutlined />}
                    placeholder={__('Input search text')}
                    allowClear
                    className="search-input-company"
                    enterButton={__('Search Now')}
                    size="large"
                    onSearch={this.onSearch.bind(this)}
                />
                <div className="word-list" onClick={this.handleClickWord.bind(this)}>
                    {wordList.map((word) => (
                        <span data-id={word} className={classnames('word-item', {'word-item-active': selectWord === word} )} key={word}>{word}</span>
                    ))}
                </div>
                <Tabs defaultActiveKey={currentTab} className="sprout-search-tabs" type="card" onChange={this.handleChangeTab.bind(this)}>
                    <TabPane tab={__('Corporations')} key="1">
                    <div className="detail-list" onClick={this.handleSelect.bind(this)}>
                        {wordFilterList.map((item) => (
                            <div className={classnames('detail-list-item', {'detail-list-item-active': selectWord === item.key} )}>
                                <div className="fliter-title">{item.key}</div>
                                {item.value.map((filterItem, index) => (
                                    <div key={filterItem.id} data-id={filterItem.id} className={classnames('filter-item', {'filter-item-match': !!searchWord && filterItem.name.includes(searchWord)} )}>
                                        <Tooltip title={filterItem.name} trigger="hover"><span data-id={filterItem.id} className="word-item" key={index}>{filterItem.name}</span></Tooltip>
                                        <Tooltip title={this.formatTooltip(filterItem.id, 'admin')}>
                                            <img src={this.formatIcon(filterItem.id, 'admin')} onClick={this.handleOperate.bind(this, filterItem.id, 'admin')} />
                                        </Tooltip>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    </TabPane>
                    <TabPane tab={__('Portfolios')} key="2">
                        <div className="detail-list" onClick={this.handleSelect.bind(this)}>
                            {wordFilterList.map((item) => (
                                <div className={classnames('detail-list-item', {'detail-list-item-active': selectWord === item.key} )}>
                                    <div className="fliter-title">{item.key}</div>
                                    {item.value.map((filterItem, index) => (
                                        <div key={filterItem.id} data-id={filterItem.id} className={classnames('filter-item', {'filter-item-match': !!searchWord && filterItem.name.includes(searchWord)} )}>
                                            <Tooltip title={filterItem.name} trigger="hover"><span data-id={filterItem.id} className="word-item" key={index}>{filterItem.name}</span></Tooltip>
                                            <Tooltip title={this.formatTooltip(filterItem.id, 'stakeholder')}>
                                                <img src={this.formatIcon(filterItem.id, 'stakeholder')} onClick={this.handleOperate.bind(this, filterItem.id, 'stakeholder')} />
                                            </Tooltip>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </TabPane>
                </Tabs>
			</div>
		</div>
	}
}