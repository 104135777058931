import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Stakeholders from './Stakeholder';
import Wallets from './Wallets';
import Transactions from './Transactions';
import CaptableAccess from './CaptableAccess';
import security from 'stores/security';

export default class Stakholder extends Component {
	path = this.props.match.path
 	showToken = (security.companyInfo && security.companyInfo.is_show_coin) === '1'

	config = [
		{
			path: `${this.path}/stakeholders-list`,
			component: Stakeholders,
			permission: 'stakeholders_allStakeholders',
			exact: true,
			access: true,
		},
		{
			path: `${this.path}/wallets-list`,
			component: Wallets,
			permission: 'stakeholders_allStakeholders',
			exact: true,
			// TODO: 暂时放开
			// access: this.showToken,
			access: true,
		},
		{
			path: `${this.path}/stakeholders-list/:inviteUnregistered`,
			component: Stakeholders,
			permission: 'stakeholders_allStakeholders',
			exact: true,
			access: true,
		},
		{
			path: `${this.path}/stakeholder-transactions`,
			component: Transactions,
			permission: 'stakeholders_transactions',
			exact: true,
			access: true,
		},
		{
			// path: `${this.path}/captable-access`,
			path: `${this.path}/stakeholder-access`,
			component: CaptableAccess,
			permission: 'stakeholders_access',
			exact: true,
			access: true
		},
	]

	render() {
		const routeConfig = this.config.filter((value, key) => security.hasPermission(value.permission) && value.access) 

		return <div className="sprout-captable-root">
			<Switch>
				{
					routeConfig.map((val, index) => <Route key={index} exact={val.exact} path={val.path} component={val.component} />)
				}
				<Redirect to={routeConfig && routeConfig[0] && routeConfig[0].path || this.path} />
			</Switch>
		</div>
	}
}