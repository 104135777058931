import React from 'react';
import { Link } from "react-router-dom";
import { post } from 'srcPath/http';
import Utils, { I } from "Utils";
import Loading from 'components/Loading';
import ModifyCertificate from "./SharesModal/ModifyCertificate";
import RepurchaseShares from "./SharesModal/RepurchaseShares";
import AddVestingSchedule from "../components/AddVestingSchedule";
import TransferShares from "../EquityAwards/EquityAwardsModal/TransferShares";
import CancelModal from "../components/CancelModal";
import AddAdminNotes from "../components/AddAdminNotes";
import EditShareClasses from "./SharesModal/EditShareClasses";
import RequestStockSplit from "../components/RequestStockSplit";
import CreateShareClass from "./SharesModal/CreateShareClass";
import CreatePsPlan from "B/components/CreatePsPlan";
import Permission from 'components/Permission';
import { Input, message, Badge } from "antd";
import VestMilestones from "../EquityAwards/EquityAwardsModal/VestMilestone";
import AccelerateVesting from "../EquityAwards/EquityAwardsModal/AccelerateVesting";

import ShareTableModels from "./SharesModal/ShareTableModels";
import { Button, Dropdown, Menu} from 'antd';
import {CloseOutlined, DownOutlined, SearchOutlined} from '@ant-design/icons';
import TerminateStakeholder from "../../Stakeholder/Stakeholder/StakeholdersModal/TerminateStakeholder";
import PurchaseRSA from "../EquityAwards/EquityAwardsModal/PurchaseRSA";
import ColumnController, { columnsFilter } from "components/ColumnController";
import reverseTerminationModal from '../components/ReverseTermination'
import bulkTransferModal from '../Shares/components/BulkTransfer'
import Filter from 'components/Filter';
import { printf } from 'Utils/i18n';
import 'assets/css/stakeholders.css';
import 'assets/css/securities.css';
import './style.scss';

const orderMap = {
    null: 0,
    descend: 3,
    ascend: 4,
    undefined: 0
}

class Shares extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            sharePlanCount:0,
            checkedIds:[],
            formatCnList:{},
            cnList:[],
            sharePageSize:100,
            current:1,
            listName:"securityLists",
            canBeCanceled:1,
            canBeTransferred:1,
            isShowCancelTip:0,
            currency:'USD',
            curSelectRelation:"all",
            dateOfIncorporation:"",
            isAbleToClear:0,
            vestingScheduleList:[
                {id:1,schedule_name:"abc1"}
            ],
            formatScheduleList:{},
            stakeholders:[
                {id:1,nick_name:"rain"}
            ],
            securityLists : [],
            relationshipList:[
                {checked:false,relationShip:"Founder"},
                {checked:false,relationShip:"Investor"},
                {checked:false,relationShip:"Employee"},
                {checked:false,relationShip:"Officer"},
                {checked:false,relationShip:"Board member"},
                {checked:false,relationShip:"None entered"},
            ],
            curSelectSecurity:{
                key:1,
                id:2,
                security_type:"",
                admin_note:"",
                notes:"",
                email:"",
                schedule:"",
                custom_label_id:'',
                name:'',
                status:'',
                quantity:'',
                issue_price_per_share:'',
                transaction_value:'',
                issue_date:'',
                checkbox_status:false,
                sign_status:'',
            },
            dateList:[],
            equityPlanList:[],
            shareClassList:[],
            acceptanceStatus:[],
            filterName:{
                name:"Certificate",
                dateKey:"",

            },
            pageData:{
                name:__("Certificate"),
                title:__("Certificate"),
                text:__("certificate"),
            },
            selectCount: 0,
            selectIndex: 0,
            selectId:0,
            transferItems:[],
            tableChild : {},
            searchValue: '',
            searchSecurityLists: [],
            draftSharesCount: '',
            activeCanModify: 0,
            formatSecurityList:[],
            tableColumn: [],
            redisKey: 'Shares',
            // 翻页参数，搜索条件
            total: 0,
            pageNumber: 1,
            pageSize: 100,
            filter: {},
            search:'',
            sort: {},
            loading: false
        }
    }
    setModifyId = async (id) => {
        const { formatSecurityList } = this.state;
        let transferList = [];
        let transferItems = this.state.transferItems;
        this.setState({
            selectId : id[0],
        })
        for(let i = 0; i < id.length; i++){
            if(formatSecurityList[id[i]].status==='Outstanding'){
                transferList[i] = formatSecurityList[id[i]]
                transferItems = transferList.map(({id, custom_label_id, stakeholder_id, quantity}) => ({id, custom_label_id, stakeholder_id, quantity, transfer_date:'', share_class:'', transfer_agreement_file: {file_obj:'',file_name:''}}))
            }
            if(formatSecurityList[id[i]].status != 'Transferred'){
                this.setState({
                    activeCanModify: 0,
                })
            }else{
                this.setState({
                    activeCanModify:1,
                })
                return;
            }
        }
        await this.setState({
            transferItems
        })
    }
    componentDidMount() {

        let certificateTip =  "Canceled, transferred, and repurchased certificates can't be canceled. Deselect conflicting rows";

        window.$(".cancel_pop").popover(
            {
                trigger:'click',
                placement : 'top',
                content : certificateTip,  //这里可以直接写字符串，也可以 是一个函数，该函数返回一个字符串；
                animation: false

            });
        this.getShareClassList();
    }

    getShareClassList=()=>{
        let pageSize = localStorage.getItem('sharePageSize') || this.state.pageSize;
        this.setState({
            pageSize,
            loading: true
        })
        let { pageNumber, filter, sort, searchValue } = this.state;

        Loading.global(true)
        post('getShareList', { pageSize, pageNumber, filter, search: searchValue, sort: { ...sort, type: orderMap[sort?.type] || 0 } }).then((response)=> {
                if(response.data.code === 0){

                let formatScheduleList = this.state.formatScheduleList;
                let formatSecurityList = this.state.formatSecurityList;
                for(let i=0;i<response.data.data.vestingScheduleList.length;i++){
                    formatScheduleList[response.data.data.vestingScheduleList[i].id]=response.data.data.vestingScheduleList[i];
                }
                let searchList = [...response.data.data.securityLists];
                let searchRes = [];
                searchList.map( ( v, k) => {
                    if( v ){
                        searchRes.push(v);
                    }
                });
                for(let k=0;k<searchRes.length;k++){
                    //初始化操作菜单显示状态
                        formatSecurityList[searchRes[k].id] = searchRes[k];
                }
                let formatCnList = this.state.formatCnList;
                if(response.data.data.cnLists)
                for(let i=0;i<response.data.data.cnLists.length;i++){
                    formatCnList[response.data.data.cnLists[i].id]=response.data.data.cnLists[i];
                }
                this.setState({
                    sharePlanCount: response.data.data.sharePlanCount,
                    sharePageSize:pageSize,
                    total:response.data.data.total,
                    securityLists:searchRes || [],
                    searchSecurityLists: searchRes || [],
                    cnList: response.data.data.cnLists,
                    formatCnList,
                    currency:response.data.data.currency,
                    stakeholders:response.data.data.stakeholders,
                    dateOfIncorporation:response.data.data.date_of_incorporation,
                    vestingScheduleList:response.data.data.vestingScheduleList,
                    formatScheduleList:formatScheduleList,
                    formatSecurityList:formatSecurityList,
                    draftSharesCount: response.data.data.draftSharesCount,
                    filter,
                })
                this.clear();
            }else{
                console.log("failed, error code is: "+response.data.code);
                }
            Loading.global(false)
            this.setState({ loading: false })
        }).catch(function (error) {
            console.log(error);
            Loading.global(false)
            this.setState({ loading: false })
        });
    }

    resetVestingName=(id,vestingName,vestingId)=>{
        let curData = this.state.securityLists;
        for(let i=0;i<this.state.securityLists.length;i++){
            if(curData[i].id==id){
                if(vestingId!=0){
                    curData[i].vesting_schedule_id = vestingId;
                    curData[i].vesting_schedule = this.state.formatScheduleList[vestingId].schedule_name
                }else{
                    curData[i].vesting_schedule = vestingName;
                }
            }
        }
        this.setState({
            securityLists:curData,
            searchSecurityLists: curData
        })
    }

    /**
     * 清空选中效果
     */
    clear = () => {
        const { securityLists, tableChild } = this.state;
        const list = [...securityLists];
        list.forEach( (v, k) => {
            list[k]['checkbox_status'] = false;
        })
        this.setState({
            securityLists:list,
            searchSecurityLists: list,
            selectCount: 0,
            selectIndex: 0
        })
        tableChild.clearAll();
    }
    clearInput=()=>{
        this.refs.inputContent.value = "";
    }

    //resetSecurityStatus
    resetSecurityStatus = (id,status,type=0) =>{
        let securityLists = this.state.securityLists;
        securityLists.forEach( (v, k)=>{
            if(v.id == id){
                v.status = status;
                if(type == 1){
                    v.termination_date = ""
                }
            }
        })
        this.setState({
            securityLists:securityLists
        })

    }

    //Id定位security
    setCurSelectSecurityNew = (id) => {
        const { securityLists } = this.state;
        let info = {};
        let transferIds = [];
        securityLists.forEach( (v, k)=>{
            if(v.id == id){
                info = {...v}
            }
            if(v.checkbox_status=='true' && v.status == 'Outstanding'){
                transferIds.push(v.id)
            }
        });
        this.setState({
            curSelectSecurity: info
        });
        this.checkSecurityStatus(transferIds)
    }

    saveState = (key, value) => {
        let data = {};
        data[key] = value;
        this.setState(data)
    }

    //resetCancel
    resetCancel = (id,status,cancellation_date) =>{
        let securityLists = this.state.securityLists;
        securityLists.forEach( (v, k)=>{
            if(v.id == id){
                v.status = status;
                v.cancellation_date = cancellation_date
            }
        })
        this.setState({
            securityLists:securityLists
        })
    }

    refreshData=(id)=>{
        let curData = this.state.securityLists;
        for(let i=0;i<this.state.securityLists.length;i++){
            if(curData[i].id==id){
                curData[i].status="Outstanding"
            }
        }
        this.setState({
            securityLists:curData,
            searchSecurityLists: curData,
        })
    }

    setModifiedSecurity=(key,security,listName)=>{
        let curSecurityList = this.state.securityLists;
        curSecurityList[key] = security;
        this.setState({
            securityLists:curSecurityList,
            searchSecurityLists: curSecurityList,
        })
    }
    setDataForModifiedSecurity=(id,security)=>{
        let curSecurityLists = this.state.securityLists;
        curSecurityLists.forEach( (v, k)=>{
            if(v.id == id){
                curSecurityLists[k] = security;
            }
        })
        this.setState({
            securityLists:curSecurityLists,
            searchSecurityLists: curSecurityLists
        })
    }
    openDetails=(key)=>{
        this.refs.details.addClass("in")
    }

    setInitShareNote=()=>{
        this.refs.addSharesAdminNote.setInitNote();
    }

    getIsAbleToReturn = () => {
        this.refs.repurchaseShares.initIsAbleToReturn();
    }

    resendEmail = ()=>{
        if(this.state.sendingStatus === 1){
            return;
        }
        this.setState({
            sendingStatus:1
        });

        Loading.global(true)
        post('sendEmail2Signatories', {security_id: this.state.curSelectSecurity.id}).then((response)=> {
            if(response.data.code === 0){
                message.success(__("Resent successfully!"));
                this.setState({
                    sendingStatus:0
                });
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
            Loading.global(false)
        }).catch(function (error) {
            console.log(error);
            Loading.global(false)
        });
    }

    /**
     * reverse termination
     */

    openReverseTermination = (record, id) =>{
        reverseTerminationModal(record,id, this.getShareClassList)
    }

    reverseCancellation=()=>{
        if(Utils.isLocal()){
            this.resetSecurityStatus(this.state.curSelectSecurity.id,'Outstanding')
            return;
        }
        var requestData = {};
        requestData.id = this.state.curSelectSecurity.id;
        requestData.security_type = this.state.curSelectSecurity.security_type;

        Loading.global(true)
        post('reverseCancellation', requestData).then((response)=> {
            if(response.data.code === 0){
                this.resetSecurityStatus(requestData.id,'Outstanding')
                message.success(__("success! "))
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
            Loading.global(false)
        }).catch(function (error) {
            console.log(error);
            Loading.global(false)
        });
    }
    getShareClassPlanList=()=>{
        this.refs.shareClassPlan.getShareClassPlanList();
    }
    getCsPsPlanList=()=>{
        this.refs.transferShares.getShareClassPlanList();
    }

    changeCancelNum = () => {
        const { securityLists, checkedIds } = this.state;
        this.refs.cancelModal.changeCancelNum();
        for(let i=0; i<securityLists.length; i++){
            if(securityLists[i].checkbox_status){
                checkedIds.push(securityLists[i].id)
            }
        }
        this.setState({
            checkedIds
        })
    }
    checkSecurityStatus= async (id) =>{
        const { formatSecurityList } = this.state;
        let transferList = [];
        let transferItems = this.state.transferItems;
        for(let i = 0; i < id.length; i++){
            if(formatSecurityList[id[i]].status==='Outstanding'){
                transferList[i] = formatSecurityList[id[i]]
                transferItems = transferList.map(({id, custom_label_id, stakeholder_id, quantity}) => ({id, custom_label_id, stakeholder_id, quantity, transfer_date:'', share_class:'', transfer_agreement_file: {file_obj:'',file_name:''}}))
            }
        }
        await this.setState({
            transferItems
        })
        let curList = this.state.securityLists;
        let flag =1;
        for (let i=0;i<curList.length;i++){
            if(curList[i].checkbox_status == true && curList[i].status !="Outstanding"){
                flag = 0;
                break;
            }
        }
        if(flag == 0){
            this.setState({
                canBeCanceled:0,
                canBeTransferred:0
            })
        }else{
            this.setState({
                canBeCanceled:1,
                canBeTransferred:1
            })
        }

    }
    getVestingData=(key)=>{
        // console.log(key);
        this.refs.addVesting.getVestingData(key);
    }
    showCertificateTip=()=>{
        // window.$(".cancel_pop").removeClass("hidden");
        this.setState({
            isShowCancelTip:1
        })
    }
    deselect=()=>{
        let securityLists = this.state.securityLists;
        $('#check01').prop("checked",false);
        for(let i=0;i<securityLists.length;i++){
            if(securityLists[i].checkbox_status == true && (securityLists[i].status=="Canceled"||securityLists[i].status=="Transferred"||securityLists[i].status=="Repurchased")){
                securityLists[i].checkbox_status=false
            }
        }
        this.setState({
            securityLists:securityLists,
            searchSecurityLists: securityLists,
            canBeCanceled:1,
            canBeTransferred:1,
            isShowCancelTip:0,
        })
    }
    setStatusToRepurchased=(id)=>{
        let curData = this.state.securityLists;
        for(let i=0;i<this.state.securityLists.length;i++){
            if(curData[i].id==id){
                curData[i].status="Repurchased"
            }
        }
        this.setState({
            securityLists:curData,
            searchSecurityLists: curData,
        })
    }
    getVestMilestone=()=>{
        this.refs.vestMilestone.getMilestoneList();
    }
    getInitVestingList=()=>{
        this.refs.acceleratedVesting.getInitVestingList();
        this.refs.acceleratedVesting.setDefaultDate();
    }

    setPsActive=()=>{
        this.refs.createShareClass.setCurrency();
    }

    /**
     * 更新表格行样式
     * @param index 行下标
     * @param status 样式状态 true, false
     */
    updateShowMsgStatus = (index, status) => {
        const { securityLists } = this.state;
        let list = [...securityLists];
        list[index]['showMsgStatus'] = status;
        this.setState(()=>({
            securityLists : list,
        }))
    }

    /**
     * Actions菜单
     * @returns {JSX.Element}
     */
    actionsMenu = () => {
        const { canBeCanceled, selectCount, selectIndex:indexArray, selectId, canBeTransferred } = this.state;
        return (
            <Menu>
                {selectCount == 1 &&  <Menu.Item onClick={this.setCurSelectSecurityNew.bind(this, selectId)}><a data-toggle="modal" data-target="#modifyShareModal">{__('Modify certificate')}</a></Menu.Item> }
                {canBeCanceled == 1 && <Menu.Item ><a  data-toggle="modal" onClick={this.changeCancelNum} data-target="#cancelModal">{__('Cancel certificate')}</a></Menu.Item>}
                {canBeTransferred == 1 && <Menu.Item ><a onClick={this.openBulkTransfer} >{__('Transfer certificates')}</a></Menu.Item>}
            </Menu>
        )
    }

    /**
     * Actions按钮
     * @returns {JSX.Element}
     */
    getActionsBottom = () => {
        const { selectCount, selectId, activeCanModify, canBeCanceled } = this.state;
        const modifyDisable = selectCount == 1 && activeCanModify == 0 ? false : true
        const cancelDisable = selectCount > 0 && canBeCanceled == 1 ? false : true
        // return <Dropdown disabled={selectCount > 0 && activeCanModify == 0 ? false : true} overlay={this.actionsMenu} placement="bottomRight" trigger="click">
        return <Dropdown disabled={modifyDisable && cancelDisable ? true : false} overlay={this.actionsMenu} placement="bottomRight" trigger="click">
            <Button
                type="primary"
                className="shares-actions"
                onClick={this.setCurSelectSecurityNew.bind(this, selectId)}
            ><div className="actions-left">{__('Actions')}</div><DownOutlined className="actions-right" /></Button>
        </Dropdown>
    }

    /**
     * 更新table checkbox
     * @param selectCount 选中总计
     * @param selectIndexArray 有更新的checkbox下标和状态
     * @param isEmpty checkbox是否为全不选状态
     */
    updateSelectCount = (selectCount, selectIndexArray, isEmpty = false) => {
        const { securityLists } = this.state;
        let list = [...securityLists];
        if(isEmpty){
            list.forEach( (v, k) =>{
                list[k]['checkbox_status'] = false;
            })
        }else{
            selectIndexArray.forEach( (v, k) => {
                list[v.selectIndex]['checkbox_status'] = v.checked;
            })
        }
        this.setState(() => ({
            selectCount,
            selectIndex : selectIndexArray[0],
            securityLists : list,
        }))
    }

    tableObj = (ref) => {
        this.setState(()=>({
            tableChild : ref
        }))
    }

    searchList = (e) => {
            const { value } = e.target;
            this.setState({
                searchValue: value,
            })
    }

    /**
     * column 排序
     */
    sortTable = sort => {
        this.setState({ sort }, ()=>this.getShareClassList())
    }

    /**
     * 清空搜索内容
     */
    closeSearch = () => {
        this.setState({
            searchValue: '',
        },()=>this.getShareClassList());
    }

    onPageChange = (current, pageSize) => {
        localStorage.setItem('sharePageSize', pageSize);
        this.setState({
            pageNumber: pageSize === this.state.pageSize ? current : 1,
            pageSize
        })
        // }, () => this.getShareClassList())
    }

    // 筛选条件逻辑，如果有分页，需要把查询条件保存在state 供分页使用
    onFilterChange = (value = {}) => {
        if (JSON.stringify(value) !== JSON.stringify(this.state.filter)) {
            this.setState({
                filter: value,
                pageNumber: 1
            }, () => this.getShareClassList())
        }
    }

    /**
     * open bulk transfer
     */

    openBulkTransfer = () =>{
        const { transferItems, stakeholders } = this.state;
        bulkTransferModal(transferItems, stakeholders, this.getShareClassList)
    }

    render(){
        let selectCount=0;
        let selectKey = "";
        for(let j=0;j< this.state.securityLists.length;j++){
            if(this.state.securityLists[j] && this.state.securityLists[j].checkbox_status === true){
                selectCount++;
                selectKey = j;
            }
        }

        const {currency = '', securityLists, curSelectSecurity, searchValue, draftSharesCount, tableColumn, redisKey, total, pageSize, pageNumber, sharePlanCount, loading } = this.state
        return(
            <div className="content">
                <TerminateStakeholder getTerminatedList={this.getShareClassList} setModifiedSecurity={this.setModifiedSecurity} curSelectStakeholder={this.state.curSelectSecurity}/>
                <ModifyCertificate formatCnList={this.state.formatCnList} currency={this.state.currency} dateOfIncorporation={this.state.dateOfIncorporation} ref="modifyShares" setModifiedSecurity={this.setModifiedSecurity}  curSelectSecurity={this.state.curSelectSecurity} cnList={this.state.cnList}/>
                <AddVestingSchedule getSecurityList={this.getShareClassList} ref={"addVesting"} listName={this.state.listName} resetVestingName={this.resetVestingName} vestingScheduleList={this.state.vestingScheduleList}   curSelectSecurity={this.state.curSelectSecurity} />
                <AccelerateVesting ref={"acceleratedVesting"} curSelectSecurity={this.state.curSelectSecurity} listName={this.state.listName} getData={this.getShareClassList} />
                <RepurchaseShares ref="repurchaseShares" currency={this.state.currency} setStatusToRepurchased={this.setStatusToRepurchased} listName={this.state.listName} setModifiedSecurity={this.setModifiedSecurity}  curSelectSecurity={this.state.curSelectSecurity} getSecurityList={this.getShareClassList} />
                <TransferShares ref={'transferShares'} currency={this.state.currency} stakeholders={this.state.stakeholders} curSelectSecurity={this.state.curSelectSecurity} />
                <PurchaseRSA currency={this.state.currency}  refreshData={this.refreshData} curSelectSecurity={this.state.curSelectSecurity}/>
                <CancelModal currency={this.state.currency} resetCancel={this.resetCancel} resetSecurityStatus={this.resetSecurityStatus} ref="cancelModal" setModifiedSecurity={this.setModifiedSecurity} dateOfIncorporation={this.state.dateOfIncorporation} securityList={this.state.securityLists} pageData={this.state.pageData} curSelectSecurity={this.state.curSelectSecurity} checkedIds={this.state.checkedIds}  getData={this.getShareClassList}/>
                <AddAdminNotes setModifiedSecurity={this.setModifiedSecurity} setDataForModifiedSecurity={this.setDataForModifiedSecurity} ref="addSharesAdminNote" curSelectSecurity={this.state.curSelectSecurity} />
                <EditShareClasses ref="shareClassPlan" getShareClassList={this.getShareClassList}/>
                <CreateShareClass ref="createShareClass" getShareClassList={this.getShareClassList} />
                <CreatePsPlan/>
                <RequestStockSplit/>
                <VestMilestones listName={this.state.listName} ref="vestMilestone" curSelectSecurity={this.state.curSelectSecurity}/>
                <div className="stakeholder-conCenter scroll-cont draft-statistic">
                    <div className="holders-nav clearfix">
                        <div className="action-box onset">
                            <Permission type="securities_shares_actions" hide>
                                <div className="btn-group">
                                    {/*<button type="button" onClick={this.checkSecurityStatus} className={actionButtonClass} data-toggle="dropdown" >Actions<span className="triangle triangle-whitedown action-triangle"></span></button>*/}

                                    {this.getActionsBottom()}

                                    {this.state.isShowCancelTip == 1 &&
                                    <p className="text-muted cancel_pop">{__("Canceled, transferred, and repurchased certificates can't be canceled.")}<br/><a onClick={this.deselect} className="text-blue">{__('Deselect conflicting rows')}</a></p>
                                    }
                                </div>
                            </Permission>
                        </div>
                        <div className="form-group search-group banner-rightBox offset">
                            {selectCount>=1 &&
                            <div className="stateBox">
                                <p className="items-tip shares_items_tip">{printf(__('%s of %s items selected'),selectCount,this.state.securityLists.length)}</p>
                                <button onClick={this.clear} type="button" className="bg-dark holders-clearBtn">{__('Clear selection')}</button>
                            </div>
                            }
                            <ColumnController filterList={tableColumn} redisKey={redisKey} thisObj={this} />
                            <div className="mr-10">
                                    <Filter callback={val => this.onFilterChange(val)} fiterData={this.state.filter} type="shares" />
                                    &nbsp;&nbsp;
                                </div>
                            <div className="input-group holders-search ">
                                <Input
                                    className="stakeholder-search-input"
                                    value={searchValue}
                                    onChange={this.searchList}
                                    onPressEnter={this.getShareClassList}
                                    prefix={<SearchOutlined className="search-prefix-icon" onClick={this.getShareClassList} />}
                                    suffix={<CloseOutlined style={{opacity: searchValue ? 1 : 0}} onClick={this.closeSearch} className="search-close-icon" />}
                                    placeholder={__('Search')}
                                />
                            </div>
                            
                            <Permission type="securities_shares_shareClass" hide>
                                <div className="btn-group">
                                    <a className="btn drafts_btn security-manageBtn bg-darkblue dropdown-toggle" data-toggle="dropdown">{__('Manage share classes')}<span className="triangle triangle-whitedown manage_triangle"></span></a>
                                    <ul className="dropdown-menu mange-downmenu shares-downmenu">
                                        <li><a className="add-stake"  data-toggle="modal" onClick={this.getShareClassPlanList} data-target="#editShareClassModal">{__('Edit share classes')}</a></li>
                                        <li><a  data-toggle="modal" onClick={this.setPsActive} data-target="#createShareClassModal">{__('Create share class')}</a></li>
                                    </ul>
                                </div>
                            </Permission>
                            <Permission type="securities_templates" hide>
                                <div><Link to={{ pathname : `/home/securities/templates/2`,state:{activeKey:'2'}} } className="btn marginRig6 drafts_btn bg-darkblue">{__('Manage vesting')}</Link></div>
                            </Permission>

                            <Permission type="securities_draftShares" hide>
                                <Badge className="draft-statistic-badge" count={I(draftSharesCount, 0)}>
                                    { sharePlanCount == 0 ?
                                        <a disabled className="btn drafts_btn send-disabledBtn">{__('Draft shares')}</a> :
                                        <Link to="/home/securities/shares/draft-shares" className="btn drafts_btn bg-darkblue">{__('Draft shares')}</Link>
                                    }
                                </Badge>
                            </Permission>
                        </div>
                    </div>
                    <div className=" table-box">
                        <ShareTableModels
                            securityLists={securityLists}
                            updateShowMsgStatus={this.updateShowMsgStatus}
                            currency={currency}
                            admin_note={curSelectSecurity.admin_note}
                            notes={curSelectSecurity.notes}
                            loading={loading}
                            // setCurSelectSecurity={this.setCurSelectSecurity}
                            setModifyId={this.setModifyId}
                            checkSecurityStatus={this.checkSecurityStatus}
                            setCurSelectSecurityNew={this.setCurSelectSecurityNew}
                            resetSecurityStatus={this.resetSecurityStatus}
                            resetCancel={this.resetCancel}
                            getVestingData={this.getVestingData}
                            getVestMilestone={this.getVestMilestone}
                            getInitVestingList={this.getInitVestingList}
                            reverseCancellation={this.reverseCancellation}
                            setInitShareNote={this.setInitShareNote}
                            resendEmail={this.resendEmail}
                            openReverseTermination={this.openReverseTermination}
                            updateSelectCount={this.updateSelectCount}
                            getIsAbleToReturn={this.getIsAbleToReturn}
                            onRef={this.tableObj}
                            curSelectSecurity={this.state.curSelectSecurity}
                            saveState={this.saveState}
                            getCsPsPlanList={this.getCsPsPlanList}
                            sortTable={this.sortTable}
                            tableColumn={columnsFilter(tableColumn, redisKey, this.state.sort)}
                            callback={this.getShareClassList}
                            paginationConfig={{
                                total,
                                pageSize,
                                current:pageNumber,
                                showSizeChanger:true,
                                onChange: this.onPageChange,
                                showTotal: (sum) => <span>{sum} {__('items')}</span>
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default Shares;

