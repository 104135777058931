import React, {Component, Fragment} from "react";
import Header from "../components/Header";
import CapitalizationTitle from "./components/CapitalizationTitle";
import CapitalizationForm from "./components/CapitalizationForm";
import CapitalizationStakeholder from "./components/CapitalizationStakeholder";
import CapitalizationCn from "./components/CapitalizationCn";
import CapitalizationBreakdown from "./components/CapitalizationBreakdown";
import AdvanceTermsModels from "./components/AdvanceTermsModels";
import SaveNameModel from "./components/SaveNameModel";
import ConfirmModel from "./components/ConfirmModel";
import {Alert, Button, Card, Checkbox, Divider, Dropdown, Menu, message, Radio, Space} from "antd";
import { post } from 'srcPath/http';
import {calculator, formatNumber, I, setCursorPos, clearNoNum, formatDateForDatePicker, formatWithArray} from "Utils";
import formatShares from 'Utils/format/formatShares'
import moment from 'moment';
import { formatDate } from 'Utils/format/formatDate'
import Modal, { ModalContent } from 'components/Modal';
import security from 'stores/security';
import Loading from 'components/Loading';
import Formula from "./tool/formula";
import {DownOutlined} from "@ant-design/icons";
import deleteIcon from "./img/delete-icon.png";
import editIcon from "./img/edit-icon.png"
import { printf } from 'Utils/i18n';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import "./style.scss";

class FinancingRound extends Component {

    saveNameModel = null;
    //advanceTerms弹层
    advanceTermsModal = null;

    state = {
        loading: false,
        financingData: [],
        amountSymbol: security.companyInfo.currency,
        // 1: by stakeholder, 2: by share class
        active: 2,
        stakeholderSharesShow: false,
        // optionErrorMessage: <span><b>Adjust the value of 1 or more of the highlighted fields below</b><br/>The combination of these values has resulted in an error in our calculations. Either your pre-money valuation or total new investment is too low, or your option pool needs to be adjusted. For help, contact support.</span>,
        optionErrorMessage: <span><b>{__('You have an error to fix')}</b><br/>{__('An error occurred during the conversion calculation.')}</span>,
        maxModel: 5,
        maxScenario: 6,
        multiple: {},
        isMultiple: 0,
    }

    /**
     * 公式集合
     * 获取四种组合其中一种算法，根据advancedTermsId选择
     * @param key 公式key
     * @param value 公式所需的数据支持，可以是数组或对象
     * @returns {*}
     */
    calculation = ( key, value, formDataId = 0 ) => {
        const { data, financingData, stakeholderData, active } = this.state;
        if(!I(data, '')){
            return 0;
        }
        //计算公式
        const Formulas = new Formula(data, financingData, formDataId, stakeholderData, active);
        return Formulas.getFormula(key, value);
    };

    componentDidMount() {
        this.getFinancingDetailModelList();
    }

    /**
     * 获取 select model 列表
     */
    getFinancingDetailModelList = async (id, name) => {
        Loading.global(true);
        try {
            const { data: resData } = await post('getFinancingDetailModelList')
            const { code, data } = resData || {}
            if (code == 0) {
                //如果数据为空，则需要初始化
                const newData = this.initializationModelList(I(data, []));
                //更新 financingData
                const financingData = this.updateFinancingData(newData, id);
                const idIndex = I(id, '') ? newData.findIndex( v => v.id == id) : 0;
                const isMultiple = this.checkFinancingIsMultiple(financingData);
                this.setState({
                    financingDetailModelList: newData,
                    financingId: newData[idIndex].id,
                    createTime: newData[idIndex].create_time,
                    scenarioModelingName: newData[idIndex].round_name,
                    stakeholderSharesShow: false,
                    DetailModelListData: data,
                    financingData,
                    isMultiple,
                }, ()=>{
                    this.init();
                })
            } else {
                message.error(data)
            }
        } catch(err) {
            message.error(err && err.message)
        }
        // Loading.global(false);
    }

    /**
     * 验证是否是多轮计划
     * @param financingData
     * @returns {boolean}
     */
    checkFinancingIsMultiple = (financingData) => {
        let topIds = [];
        financingData && financingData.forEach((v, k)=>{
            topIds.push(I(v.topId, v.top_id));
        })
        topIds = [...new Set(topIds)].length;
        return topIds > 1;
    }

    resetScenarioData = () => {
        let { stakeholderData, shareClassDataList, data, active, isMultiple, financingData } = this.state;
        if(isMultiple){
            const stakeholderInvestorsKey = stakeholderData.findIndex(v=>v.key === 'second_round_investors_key');
            const sharesCnKey = shareClassDataList.findIndex(v=>v.key === 'second_round_cn_key');
            const sharesInvestorsKey = shareClassDataList.findIndex(v=>v.key === 'second_round_investors_key');
            if(stakeholderInvestorsKey === -1){
                stakeholderData = this.mergeStakeholder(stakeholderData, financingData, true);
            }
            if(sharesCnKey === -1 && sharesInvestorsKey === -1){
                shareClassDataList = this.mergeShareClass(shareClassDataList, financingData, true);
            }
            this.setState({stakeholderData: [...stakeholderData], shareClassDataList: [...shareClassDataList]}, ()=>{
                this.generalCalculationFormula();
            })
        }else{
            this.generalCalculationFormula();
        }
    }

    /**
     * 通用计算函数，计算展示区所有联动数据
     */
    generalCalculationFormula = (callback = false) => {
        const { financingData, stakeholderData, shareClassDataList, optionErrorMessage, data, active, isMultiple } = this.state;
        const {sendEa, surplusEa} = data;
        let newFinancingData = [...financingData];
        let error = 0;
        //模拟financingData
        const { defaultSelectedIds } = this.reSerializeFinancingData(newFinancingData);
        //需要模拟的ID
        const [firstId, secondId] = defaultSelectedIds;
        //计算多轮融资数据
        let firstFinancingData = [], firstIndex = 0;
        for(let i in newFinancingData){
            if(!this.checkCalculation(newFinancingData[i])){
                error++;
                continue;
            }
            //计算新一轮融资总fullDiluted股数
            const newTotal = this.calculationNewTotal(i);
            const newPricePerShare = this.calculation('newPricePerShare', {newTotal}, i);
            if(!I(newPricePerShare, '')){
                error++;
                newFinancingData[i].isError = true;
                newFinancingData[i].cardBorderRed = false;
                newFinancingData[i].errorAlertMessage = optionErrorMessage;
                continue;
            }
            //错误信息提示
            if(!this.verificationFinancing(newPricePerShare, i)){
                error++;
                continue;
            }
            //CN每行数据计算
            const cn = this.cnDataStatistics(financingData, newTotal, newPricePerShare, i);
            const { cnData, sharesAllTotal, ownershipAllTotal, cnTotalList } = cn;
            //头部统计数据
            const headerStatistics = this.getTitle(newTotal, cnData, newPricePerShare, i);
            const { newMoney, safeNote } = headerStatistics;
            if(newMoney < 0){
                error++;
                newFinancingData[i].isError = true;
                newFinancingData[i].cardBorderRed = false;
                newFinancingData[i].errorAlertMessage = optionErrorMessage;
                continue;
            }
            //计算未分配金额及股数
            const unassignedNewInvestment = this.getUnassignedNewInvestment(financingData, i);
            //导出CN格式化的数据
            const cnStakeholder = this.cnStakeholder(financingData, newTotal, newPricePerShare, i);
            //导出Investment数据
            const investment = this.getInvestment(financingData, newPricePerShare, i);
            const postMoneyOptionPoolType = this.calculation('postMoneyOptionPoolType', null, i);
            let optionPoolShares = postMoneyOptionPoolType === 1 ? this.calculation('optionPoolShares', {newTotal}, i) : this.calculation('postMoneyOptionPoolShares', null, i);
            optionPoolShares = this.clearNumber(this.getNumber(optionPoolShares, null, false, true));
            let optionPoolPercentage = postMoneyOptionPoolType === 1 ? calculator.mul(this.calculation('postMoneyOptionPool', null, i), 100) : this.calculation('optionPoolPercentage', {newTotal}, i);
            optionPoolPercentage = this.clearNumber(this.getNumber(optionPoolPercentage, 2));
            const newRoundInvestorsShares = this.calculation('unnamedNewRoundInvestorsShares', {unassignedNewInvestment, newPricePerShare}, i);
            //计算新一轮stakeholder、shares class
            const stakeholderListB = this.getListB(stakeholderData, newTotal, unassignedNewInvestment, cnStakeholder, investment, newPricePerShare, cn, i);
            const sharesListB = this.getListB(shareClassDataList, newTotal, unassignedNewInvestment, cnStakeholder, investment, newPricePerShare, cn, i);
            //计算第二轮相关数据
            //初始化cnData
            newFinancingData[i].cnData = cnData;
            newFinancingData[i].sharesAllTotal = sharesAllTotal;
            newFinancingData[i].ownershipAllTotal = ownershipAllTotal;
            newFinancingData[i].cnTotalList = cnTotalList;
            newFinancingData[i].stakeholderListB = stakeholderListB;
            newFinancingData[i].sharesListB = sharesListB;
            newFinancingData[i].statistics = headerStatistics;
            newFinancingData[i].newTotal = newTotal;
            if(financingData[i].id == firstId){
                newFinancingData[i].isFirstId = 1;
                firstFinancingData = [...newFinancingData];
                firstIndex = i;
                newFinancingData[i].firstOptionPoolShares = optionPoolShares;
                newFinancingData[i].firstOptionPoolPercentage = optionPoolPercentage;
                newFinancingData[i].newRoundInvestorsShares = newRoundInvestorsShares;
            }
            if(financingData[i].id == secondId && firstFinancingData?.[firstIndex]){
                newFinancingData = this.secondNewTotalShares(firstFinancingData, firstIndex, secondId, {stakeholderListB: firstFinancingData[firstIndex].stakeholderListB, sharesListB: firstFinancingData[firstIndex].sharesListB}, active, isMultiple, sendEa);
                newFinancingData[i].isSecondId = 1;
            }
        }
        const stakeholderSharesShow = this.checkStakeholderShareShow(newFinancingData, error);
        let setData = {financingData: newFinancingData, stakeholderSharesShow};
        this.setState(setData, ()=>{
            callback && callback();
        });
    }

    secondNewTotalShares = (firstFinancingData, firstIndex, secondId, list, active, isMultiple, sendEa) => {
        const cnData = {};
        const formDataId = firstFinancingData.findIndex(v=>v.id==secondId);
        const { newTotal } = firstFinancingData[firstIndex];
        let { sharesListB, stakeholderListB } = list;
        //计算公式
        stakeholderListB = this.newStakeholderDataList(stakeholderListB, newTotal);
        // sharesListB = this.newShareDataList(sharesListB, newTotal);
        stakeholderListB.forEach((v, k)=>{
            v.key === 'second_round_investors_key' && (stakeholderListB[k].quantity = firstFinancingData[firstIndex]?.newRoundInvestorsShares || 0 )
        })
        sharesListB.forEach((v, k)=>{
            if(v.key === 'second_round_investors_key'){
                sharesListB[k].quantity = firstFinancingData[firstIndex]?.newRoundInvestorsShares || 0;
                sharesListB[k].financingDataKey = formDataId;
            }
            if(v.key === 'second_round_cn_key'){
                sharesListB[k].quantity = sharesListB[k].quantity = firstFinancingData[firstIndex]?.sharesAllTotal || 0;
                sharesListB[k].financingDataKey = formDataId;
            }
            // v.key === 'second_round_investors_key' && (sharesListB[k].quantity = firstFinancingData[firstIndex]?.newRoundInvestorsShares || 0 )
            // v.key === 'second_round_cn_key' && (sharesListB[k].quantity = firstFinancingData[firstIndex]?.sharesAllTotal || 0 )
        });
        //cnData 需要清空，因为在第二轮计算时cn已经转换为stakeholder了
        firstFinancingData[formDataId].cnData = {};
        const Formulas = new Formula( {fullDiluted: newTotal, sendEa}, firstFinancingData, formDataId, active == 1 ? stakeholderListB : sharesListB, active);
        const postMoneyOptionPoolType = Formulas.getFormula('postMoneyOptionPoolType', null, formDataId);
        const secondNewTotal = Formulas.getFormula('newTotal', 3);
        const secondNewPricePerShare = Formulas.getFormula('newPricePerShare', {newTotal: secondNewTotal}, formDataId);

        //CN每行数据计算
        const cn = this.cnDataStatistics(firstFinancingData, secondNewTotal, secondNewPricePerShare, formDataId);
        //CN统计
        const cnTotal = this.cnStatistics(cnData, formDataId);
        //头部统计数据
        const { sharesAllTotal } = cnTotal;
        const postMoneyValuation = Formulas.getFormula('postMoneyValuation', {newTotal: secondNewTotal, newPricePerShare: secondNewPricePerShare}, formDataId);
        const newMoney = Formulas.getFormula('newMoney', {newTotal: secondNewTotal, newPricePerShare: secondNewPricePerShare}, formDataId);
        const safeNote = Formulas.getFormula('safeNote', {sharesAllTotal, newTotal: secondNewTotal}, formDataId);
        const postMoneyOptionPool = calculator.mul(Formulas.getFormula('postMoneyOptionPool', null, formDataId), 100);
        const postMoneyOptionPoolShares = Formulas.getFormula('postMoneyOptionPoolShares', null, formDataId);
        const totalDilution = Formulas.getFormula('totalDilution', {fullDiluted: newTotal, newTotal: secondNewTotal}, formDataId);
        const headerStatistics = {
            postMoneyValuation,
            newPricePerShare: secondNewPricePerShare,
            newMoney,
            safeNote,
            postMoneyOptionPool,
            postMoneyOptionPoolShares,
            postMoneyOptionPoolType,
            totalDilution,
        }
        //计算未分配金额及股数
        const unassignedNewInvestment = this.getUnassignedNewInvestment(firstFinancingData, formDataId);
        // //导出CN格式化的数据
        const cnStakeholder = this.cnStakeholder(firstFinancingData, secondNewTotal, secondNewPricePerShare, formDataId);
        //导出Investment数据
        const investment = this.getInvestment(firstFinancingData, secondNewPricePerShare, formDataId);

        //计算新一轮stakeholder、shares class
        const secondStakeholderListB = this.getListB(stakeholderListB, secondNewTotal, unassignedNewInvestment, {}, investment, secondNewPricePerShare, cn, formDataId, isMultiple, 1);
        const secondSharesListB = this.getListB(sharesListB, secondNewTotal, unassignedNewInvestment, {}, investment, secondNewPricePerShare, cn, formDataId, isMultiple, 2);
        let secondFinancingData = [...firstFinancingData];

        secondFinancingData[formDataId].cnData = cnData;
        secondFinancingData[formDataId].sharesAllTotal = sharesAllTotal;
        secondFinancingData[formDataId].ownershipAllTotal = '';
        secondFinancingData[formDataId].cnTotalList = [];
        secondFinancingData[formDataId].stakeholderListB = secondStakeholderListB;
        secondFinancingData[formDataId].sharesListB = secondSharesListB;
        secondFinancingData[formDataId].statistics = headerStatistics;
        secondFinancingData[formDataId].newTotal = secondNewTotal;
        secondFinancingData[formDataId].firstOptionPoolShares = firstFinancingData[firstIndex].firstOptionPoolShares;
        secondFinancingData[formDataId].firstOptionPoolPercentage = firstFinancingData[firstIndex].firstOptionPoolPercentage;

        return secondFinancingData;
    }

    /**
     * 请求financing round 接口数据并重组
     * @returns {Promise<void>}
     */
    init = async () => {
        Loading.global(true);
        try {
            const { data: resData } = await post('getFinancingDetail')
            const { code, data } = resData || {}
            if (code == 0) {
                this.initData(data);
            } else {
                message.error(data)
            }
        } catch(err) {
            message.error(err && err.message)
        }
        // Loading.global(false);
    }

    /**
     * 初始化stakeholder，把cn数据合并到stakeholder中
     * @param stakeholderData
     * @param newFinancingData
     * @returns {*}
     */
    initStakeholderData = (stakeholderData, newFinancingData) => {
        newFinancingData && newFinancingData.forEach((v)=>{
            const stakeholderCn = v.stakeholderCn && JSON.parse(v.stakeholderCn);
            if(stakeholderCn){
                Object.keys(stakeholderCn).forEach(v1=>{
                    stakeholderCn[v1] && stakeholderCn[v1].forEach(v2=>{
                        stakeholderData[v2.stakeholder_id] = {id: v2.stakeholder_id, name: v2.stakeholder, quantity: 0, valuation: 0};
                    })
                })
            }
        })

        return stakeholderData;
    }

    switchData = (dataValue) => {
        this.setState(dataValue, ()=>{
            this.resetScenarioData();
        })
    }

    /**
     * 初始化数据
     * @param data
     */
    initData = (data) => {
        const { financingData, active } = this.state;
        const { shareClassData, stakeholderData, fullDiluted, cashRaised, sendEa, surplusEa, shareholderList, cnData, issuePrice } = data;
        let newFinancingData = [...financingData];
        const newStakeholderData = this.initStakeholderData(stakeholderData, newFinancingData);
        for(let i in newFinancingData){
            let newCnData = this.getCnData(cnData, newFinancingData[i].stakeholderCn);
            newCnData = this.initCnData(newCnData);
            newFinancingData[i].cnData = newCnData;
            newFinancingData[i].selectedRowKeys = this.cnDataList(newCnData);
        }
        this.setState({
            shareClassDataList: this.newShareDataList(shareClassData, fullDiluted, newFinancingData),
            stakeholderDataList: this.newStakeholderDataList(newStakeholderData, fullDiluted),
            // stakeholderData: this.newStakeholderDataList(newStakeholderData, fullDiluted),
            stakeholderData: active == 1 ? this.newStakeholderDataList(newStakeholderData, fullDiluted) : this.newShareDataList(shareClassData, fullDiluted, newFinancingData),
            financingData: newFinancingData,
            fullDiluted, cashRaised, sendEa, surplusEa, data, shareholderList, issuePrice
        }, ()=>{
            //计算多轮融资数据并赋值
            this.generalCalculationFormula();
            Loading.global(false);
        })
    }

    initCnData = (cnData) => {
        const number = Math.ceil(Math.random()*1000000);
        const date = moment().format('DD/MM/YYYY');
        // newFinancingData[k].selectedRowKeys = cnDataList(cnDataDetail);
        const newCn = {
            stakeholder_id: `stakeholderId${number}`,
            custom_label_id: `CN-${number}`,
            principal: '',
            valuation_cap: '',
            conversion_discount: '',
            issue_date: date,
            interest_rate: '0',
            stakeholder: '',
            principalInterest: '',
            isExist: 0,
            isNew: true,
        };
        if(!cnData?.safe ){
            cnData.safe = [{...newCn}];
        }
        // if(!cnData?.convertible_debt){
        //     cnData.convertible_debt = [{...newCn}];
        // }
        // if(!cnData?.convertible_security){
        //     cnData.convertible_security = [{...newCn}];
        // }
        return JSON.parse(JSON.stringify(cnData));
    }

    /**
     * stakeholder列表加入investor
     */
    updateStakeholderData = (newFinancingData, stakeholderData) => {
        let newStakeholderData = [];
        stakeholderData.forEach( (v) => !v.isNew && newStakeholderData.push(v) );
        newFinancingData.forEach((v, k)=>{
            v.investmentList.forEach((v1, k1)=>{
                const { investorName, investment, investorId } = v1;
                !investorId && newStakeholderData.push({
                    name: investorName,
                    investment: investment,
                    isNew: true,
                    financingDataKey: k,
                    ownership: 0,
                    key: `${k1}${k}`,
                });
            })
        })
        return newStakeholderData;
    }

    getCnData = (cnData, stakeholderCn) => {
        stakeholderCn = stakeholderCn && JSON.parse(stakeholderCn);
        let customizeData = {};
        const date = moment().format('DD/MM/YYYY');
        stakeholderCn && Object.keys(stakeholderCn).forEach(v=>{
            customizeData[v] = [];
            stakeholderCn[v] && stakeholderCn[v].forEach(v1=>{
                const { stakeholder_id, custom_label_id, principal, valuation_cap, conversion_discount, stakeholder, principalInterest } = v1;
                customizeData[v].push({ stakeholder_id, custom_label_id, principal, valuation_cap, conversion_discount, issue_date: date, interest_rate: '0', stakeholder, principalInterest, isExist: 0, isNew: true });
            })
        });
        let newCnData = {};
        cnData && Object.keys(cnData).forEach((v)=>{
            cnData[v] && cnData[v].forEach((__, k1)=>cnData[v][k1].id = Number(k1));
            newCnData[v] = [];
            newCnData[v] = [...(customizeData[v] ? customizeData[v] : []), ...cnData[v]];
        });
        return newCnData;
    }

    newStakeholderDataList = (stakeholderData, fullDiluted) => {
        const { financingData, isMultiple } = this.state;
        let data = this.shareStakeholder(stakeholderData, fullDiluted);
        data = this.mergeStakeholder(data, financingData, isMultiple);
        return data;
    }

    newShareDataList = (stakeholderData, fullDiluted, newFinancingData) => {
        const { isMultiple } = this.state;
        const data = this.shareStakeholder(stakeholderData, fullDiluted);
        const returnArray = this.mergeShareClass(data, newFinancingData, isMultiple);
        return returnArray;
    }

    /**
     * 对象转数组并计算 current ownership
     */
    shareStakeholder = (data, fullDiluted) => {
        data = this.objToArray(data);
        const { agile } = calculator;
        data.forEach((v, k)=>{
            data[k]['ownership'] = agile(`${I(v.quantity, 0)} / ${fullDiluted} * 100`);
        })
        return [...data];
    }

    addData = (key) => {
        const { financingData, data } = this.state;
        let newFinancingDataInfo = {...financingData[key]};
        // newFinancingDataInfo.investmentList = [{
        //     type: 2, investorId: '', investorName: '', proRata: 1, proRataList: [], proRataValue: ['No'],
        //     investment: '', key: `0${key}`, financingDataKey: key,
        // }];
        newFinancingDataInfo.investmentList = JSON.parse(JSON.stringify(newFinancingDataInfo.investmentList));
        newFinancingDataInfo.advanceTerms = {...financingData[key].advanceTerms};
        newFinancingDataInfo.id = moment().valueOf()+Math.floor(Math.random()*1000)
        newFinancingDataInfo.cnData = {...financingData[key].cnData};
        financingData.push(newFinancingDataInfo);
        financingData[key].stretch = false;
        newFinancingDataInfo.detailName = `Scenario ${String.fromCharCode(65+(financingData.length-1))}`;
        newFinancingDataInfo.isChecked = false;
        this.setState({financingData: financingData}, ()=>{
            // this.initData(data);
        })
    }

    /**
     * 删除 scenario
     * @param key
     */
    deleteData = (key) => {
        const { financingData, data } = this.state;
        let newFinancingData = [...financingData];
        newFinancingData.splice(key, 1);
        const stakeholderSharesShow = this.checkStakeholderShareShow(newFinancingData);
        this.setState({financingData: newFinancingData, stakeholderSharesShow});
    }

    mergeShareClass = (sharesClassList, newFinancingData, isMultiple = false) => {
        let isCheckIndex = [];
        newFinancingData.forEach((v, k)=>v.isChecked && isCheckIndex.push(k));
        const name = newFinancingData[isCheckIndex[0]]?.detailName;
        if(isMultiple){
            sharesClassList.push({
                key: "second_round_investors_key",
                name: printf(__('%s new round investors'), name),
                quantity: 0,
                type: '',
                valuation: 0,
                ownership: 0,
                financingDataKey: isCheckIndex[1],
                isNew: true,
                investment: '',
            },{
                key: "second_round_cn_key",
                name: printf(__('%s convertibles'), name),
                quantity: 0,
                type: '',
                valuation: 0,
                ownership: 0,
                financingDataKey: isCheckIndex[1],
                isNew: true,
                investment: '',
            });
        }
        return sharesClassList;
    }

    mergeStakeholder = (newStakeholderData, financingData, isMultiple = false) => {
        let isCheckIndex = [];
        financingData.forEach((v, k)=>v.isChecked && isCheckIndex.push(k));
        const name = financingData[isCheckIndex[0]]?.detailName;
        let newData = [];
        newStakeholderData.forEach( v => !v.isNew && newData.push(v) );
        financingData.forEach((v, k)=>{
            const { investmentList, stakeholderCn } = v;
            investmentList.forEach((v1, k1)=>{
                v1.type == 2 && I(v1.investment, 0) && newData.push({
                    name: v1.investorName,
                    investment: v1.investment,
                    isNew: true,
                    financingDataKey: k,
                    ownership: 0,
                    key: `${k1}${k}`,
                })
            });
        });
        if(isMultiple){
            newData.push({
                name: printf(__('%s new round investors'), name),
                investment: '',
                financingDataKey: isCheckIndex[1],
                ownership: 0,
                key: 'second_round_investors_key',
                isNew: true,
                quantity: ''
            });
        }
        return newData;
    }

    checkStakeholderShareShow = (financingData, error = 0) => {
        let isShow = false;
        for(let i in financingData){
            if(financingData[i].isChecked && this.checkCalculation(financingData[i]) && !error){
                isShow = true
                break;
            }
        }
        return isShow;
    }

    /**
     * CN默认为选中状态，通过此标识来判断是否参与计算
     * @param cnData
     * @returns {*}
     */
    cnDataList = (cnData) => {
        let selectedRowKeys = {};
        for(let i in cnData){
            selectedRowKeys[i] = [];
            for(let j in cnData[i]){
                selectedRowKeys[i].push(cnData[i][j].custom_label_id);
            }
        }
        return selectedRowKeys;
    }

    /**
     * 初步统计（头部）
     * @param pricePreShare
     * @param newTotal
     */
    getTitle = (newTotal, cnData, newPricePerShare, key) => {
        const { fullDiluted } = this.state;
        //CN统计
        const cnTotal = this.cnStatistics(cnData, key);
        const { sharesAllTotal } = cnTotal;
        const postMoneyValuation = this.calculation('postMoneyValuation', {newTotal, newPricePerShare}, key);
        const newMoney = this.calculation('newMoney', {newTotal, newPricePerShare}, key);
        const safeNote = this.calculation('safeNote', {sharesAllTotal, newTotal}, key);
        const postMoneyOptionPool = calculator.mul(this.calculation('postMoneyOptionPool', null, key), 100);
        const postMoneyOptionPoolShares = this.calculation('postMoneyOptionPoolShares', null, key);
        const postMoneyOptionPoolType = this.calculation('postMoneyOptionPoolType', null, key);
        const totalDilution = this.calculation('totalDilution', {fullDiluted, newTotal}, key);

        return {
            postMoneyValuation,
            newPricePerShare,
            newMoney,
            safeNote,
            postMoneyOptionPool,
            postMoneyOptionPoolShares,
            postMoneyOptionPoolType,
            totalDilution,
        }
    }

    clearNumber = (value) => clearNoNum({value}).value;

    /**
     * cn stakeholder占股总和
     */
    cnStakeholder = (financingData, newTotal, newPricePerShare, key) => {
        const { cnData, selectedRowKeys } = financingData[key];
        //因CN数据最多可以分三组，所以重新序列化CN再做统计
        let newCnData = [];
        for(let i in cnData){
            for(let j in cnData[i]){
                if(selectedRowKeys[i].indexOf(cnData[i][j].custom_label_id) !== -1){
                    newCnData.push(cnData[i][j]);
                }
            }
        }
        let stakeholder = {};
        newCnData.forEach((v, k)=>{
            v.conversion_discount = I(v.conversion_discount, 0);
            v.interest_rate = I(v.interest_rate, 0);
            const cnShares = this.calculation('cnShares', {newPricePerShare, ...v},  key);
            stakeholder[key] = I(stakeholder[key], {});
            stakeholder[key][v.stakeholder_id] = calculator.add(cnShares, I(stakeholder[key][v.stakeholder_id], 0));
        })
        return stakeholder;
    }

    /**
     * cn 每行数据进行公式计算
     * 计算总ownership、shares
     * @returns {{sharesAllTotal: number, cnTotalList: {}, cnData, ownershipAllTotal: number}}
     */
    cnDataStatistics = (financingData, newTotal, newPricePerShare, key) => {
        let { cnData } = financingData[key];
        const { agile } = calculator;
        for( let i in cnData){
            let cnList = [...cnData[i]];
            cnList.forEach((v, k)=>{
                let { conversion_discount, valuation_cap } = v;
                const valuationCap = I(valuation_cap, 0);
                const conversionDiscount = I(conversion_discount, 0);
                const cnShares = this.calculation('cnShares', {newPricePerShare, ...v},  key);
                cnList[k].id = k;
                cnList[k].sharePrice = I(newPricePerShare, '') && this.calculation('cnPricePerShare', {newPricePerShare, valuationCap, conversionDiscount}, key) || '';
                cnList[k].shares = cnShares;
                cnList[k].ownership = agile(`${I(cnList[k].shares, 0)} / ${newTotal} * 100`);
            })
            cnData[i] = cnList;
        }
        const total = this.cnStatistics(cnData, key);
        const { sharesAllTotal, ownershipAllTotal, cnTotalList} = total;
        return { cnData, sharesAllTotal, ownershipAllTotal, cnTotalList };
    }

    /**
     * cn 统计出列表最后一行数据
     */
    cnStatistics = (cnData, key) => {
        const { financingData } = this.state;
        const { selectedRowKeys } = financingData?.[key] || {};
        let cnTotalList = {};
        let sharesAllTotal = 0, ownershipAllTotal = 0;
        for(let i in cnData){
            let ownershipTotal = 0, sharesTotal = 0;
            I(cnData[i], []).forEach((v, j) => {
                if(selectedRowKeys?.[i] && selectedRowKeys[i].indexOf(cnData[i][j].custom_label_id) !== -1){
                    ownershipTotal = calculator.add(v.ownership, I(ownershipTotal, 0));
                    sharesTotal = calculator.add(v.shares, I(sharesTotal, 0));
                }
            })
            sharesAllTotal = calculator.add(sharesTotal, I(sharesAllTotal, 0));
            ownershipAllTotal = calculator.add(ownershipTotal, I(ownershipAllTotal, 0));
            cnTotalList[i] = {ownershipTotal, sharesTotal};
        }
        return {sharesAllTotal, ownershipAllTotal, cnTotalList}
    }

    getInvestment = (financingData, newPricePerShare, key) => {
        const { investmentList } = financingData[key];
        const { agile } = calculator;
        let newStakeholderById = {};
        //investorId
        investmentList.forEach((v)=>{
            const { investorId, investment } = v;
            const newInvestment = this.clearNumber(investment);
            /**
             * by stakeholder
             * 存在的合并，不存在的追加
             */
            if(investorId){
                newStakeholderById[investorId] = agile(`${I(newInvestment, 0)} / ${newPricePerShare}`);
            }
        });
        return { newStakeholderById: {[key]: newStakeholderById} }
    }

    /**
     * 获取未分配的金额
     * @returns {*}
     */
    getUnassignedNewInvestment = (financingData, key) => {
        //需要和stakeholder一同处理
        const { agile } = calculator;
        const { totalNewInvestment, investmentList } = financingData[key];
        let newTotalNewInvestment = this.clearNumber(totalNewInvestment);
        investmentList && investmentList.forEach((v)=>{
            newTotalNewInvestment = agile(`${I(newTotalNewInvestment, 0)} - ${I(this.clearNumber(v.investment), 0)}`);
        })
        return newTotalNewInvestment ? newTotalNewInvestment : '0';
    }

    /**
     * 验证是否允许计算
     */
    checkCalculation = (data) => {
        const { preMoneyValuation, postMoneyOptionPool, totalNewInvestment, postMoneyOptionPoolShares, postMoneyOptionPoolType} = data;
        return preMoneyValuation && (postMoneyOptionPoolType === 1 ? postMoneyOptionPool : postMoneyOptionPoolShares) && true || false;
    }

    /**
     * 计算新一轮full diluted
     * @returns {*}
     */
    calculationNewTotal = (formDataId) => {
        const { financingData } = this.state;
        const newTotal = this.calculation('newTotal', null, formDataId);
        financingData[formDataId]['newTotal'] = newTotal;
        return newTotal;
    }

    /**
     * 计算预投资记录
     * @param newTotal 预计总股数
     * @param unassignedNewInvestment 未分配股数
     * @param cnStakeholder CN数据
     * @param key
     */
    getListB = (stakeholderData, newTotal, unassignedNewInvestment, cnStakeholder, investment, newPricePerShare, cn, key, isMultiple = false, t = 0) => {
        const { agile, add } = calculator;
        const { newStakeholderById } = investment;
        let stakeholderListB = [];
        //shares、stakeholder 列表数值计算
        stakeholderData.forEach((v, k)=>{
            const { id, ownership, isNew, financingDataKey, quantity, name, investment, key: investorKey } = v;
            //计算每行数据和CN的股数
            let stakeholderValuation = 0;
            //最新股份金额
            let rowShare = 0;
            if(isNew){
                const newInvestment = I(this.clearNumber(investment), 0);
                stakeholderValuation = financingDataKey == key ? newInvestment : 0;
                rowShare = financingDataKey == key ? agile(`${stakeholderValuation} / ${newPricePerShare}`) : 0;
                if(( investorKey === 'second_round_investors_key' || investorKey === 'second_round_cn_key' ) && financingDataKey == key && isMultiple){
                    rowShare = quantity;
                    stakeholderValuation = agile(` ${rowShare} * ${newPricePerShare} `);
                }
            }else{
                rowShare = agile(`${I(quantity, 0)} + ${ cnStakeholder[key] && I(cnStakeholder[key][id], 0) || 0} + ${ newStakeholderById[key] && I(newStakeholderById[key][id], 0) || 0}`);
                stakeholderValuation = agile(`${I(rowShare, 0)} * ${newPricePerShare}`);
            }
            //获取每条数据占总股数的百分比
            const rowSharePercentage = agile(`${rowShare} / ${newTotal} * 100`);
            //比对原始数据百分比
            const ownership1 = agile(`${rowSharePercentage} - ${this.clearNumber(ownership)}`);
            let resData = {
                id: I(id, 0),
                ownership,
                investment,
                ownership1: ownership1,
                ownership2: rowSharePercentage,
                valuation: stakeholderValuation,
                shares: rowShare,
                quantity: rowShare,
                newPricePerShare: newPricePerShare,
                unassignedNewInvestment,
                newTotal,
                name,
                key: investorKey,
                financingDataKey: key,
                isNew: I(isNew, false),
                //排序使用
                sortNo: stakeholderValuation,
            };
            stakeholderListB.push(resData);
        })
        return stakeholderListB;
    }

    /**
     * 对象转数组
     * @param obj
     */
    objToArray = (obj) => {
        let newArray = [];
        for(let i in obj){
            newArray.push(obj[i]);
        }
        return newArray;
    }

    /**
     * 更新变量
     */
    updateState = (key, value, element, cursorPositionX, type = true, callback = false) => {
        if(!key){
            this.forceUpdate();
            return;
        }
        const data = typeof key == 'object' ? key : {[key]: value};
        this.setState(data, ()=>{
            if(!!element){
                setCursorPos(element, cursorPositionX);
            }
            if(type){
                this.generalCalculationFormula(callback);
            }
        });
    }

    /**
     * financing checkbox change
     * @param index
     * @param e
     */
    financingCheckboxChange = (index, e) => {
        const { financingData, isMultiple } = this.state;
        let data = [...financingData];
        if(isMultiple){
            data.forEach((v, k)=>{
                if(v.topId == financingData[index].topId){
                    data[k].isChecked = false;
                }
            })
            data[index].isChecked = true;
        }else{
            data[index].isChecked = e.target.checked;
        }
        const stakeholderSharesShow = this.checkStakeholderShareShow(data);
        this.updateState({financingData: data});
    }

    /**
     * 更新 advanceTerms
     * @param advanceTerms
     * @param key
     */
    advanceTermsSubmit = (advanceTerms, key, close) => {
        const {financingData} = this.state;
        let newFinancingData = [...financingData];
        const { optionPool, conversionMethod } = advanceTerms;
        const advancedTermsId = this.getAdvancedTermsId(optionPool, conversionMethod);
        newFinancingData[key].advanceTerms = advanceTerms;
        newFinancingData[key].advancedTermsId = advancedTermsId;
        this.updateState({financingData: newFinancingData, advancedTermsId});
        close();
    }

    /**
     * 更新公式ID
     * 1: post financing, post money
     * 2: post financing, pre money
     * 3: pre financing, pre money
     * 4: pre financing, post money
     * @param optionPool
     * @param conversionMethod
     * @returns {*}
     */
    getAdvancedTermsId = (optionPool = 1, conversionMethod = 1) => {
        const advancedTerms = { 1: { 1: 1, 2: 4}, 2: { 1: 2, 2: 3} };
        return advancedTerms[optionPool][conversionMethod];
    }

    getCardHtml = () => {
        const { financingData, ...data } = this.state;
        const { defaultSelectedIds, newFinancingData } = this.reSerializeFinancingData(financingData);
        const financingList = Object.keys(newFinancingData);
        const financingLen = financingList.length;
        return financingList.map((v, k)=>{
            return (
                <Fragment key={k}>
                    <div >
                        <h2>{k !== 0 && <MinusCircleOutlined onClick={this.removeFinancing.bind(this, newFinancingData[v]?.topId)} />}{I(newFinancingData[v]?.financingName, printf( __('Financing Round %s'), k+1))}<span className="new-financing-round-model-desc">{`R${k+1}`}</span></h2>
                        <p>{__('Select scenarios for comparison in capitalization breakdown below')}</p>
                        {this.getCardInfoHtml(newFinancingData[v], data)}
                    </div>
                    {financingLen <= 1 && (
                        <div>
                            <h2><PlusCircleOutlined onClick={this.updateFinancing.bind(this, newFinancingData[v])} />Add Financing Round 2</h2>
                            <p>{__('Select scenarios for comparison in capitalization breakdown below')}</p>
                        </div>
                    )}
                </Fragment>
            )
        })
    }

    removeFinancing = (topId) => {
        const { financingData, stakeholderDataList } = this.state;
        let newFinancingData = [...financingData];
        newFinancingData.forEach((v, k)=>{
            if(v.topId == topId){
                newFinancingData.splice(k, 1);
            }
        });

        this.updateState({financingData: newFinancingData, isMultiple: false});
    }

    updateFinancing = (data) => {
        const { financingData } = this.state;
        const k = (data?.info).length;
        const firstIndex = financingData.findIndex(v=> !!v.isFirstId);
        let newFinancingDataInfo = {
            id: moment().valueOf()+Math.floor(Math.random()*1000),
            topId: moment().valueOf()+Math.floor(Math.random()*1000),
            financingName: 'Financing Round 2',
            advancedTermsId: financingData[0]?.advancedTermsId,
            //估值
            preMoneyValuation: '',
            //期权池
            postMoneyOptionPool: '',
            postMoneyOptionPoolShares: '',
            postMoneyOptionPoolType: 1,
            //预融资金
            totalNewInvestment: '',
            //日期
            closingDate: moment(),
            //预计 fullDiluted
            newTotal: 0,
            //预 shares、stakeholder
            stakeholderListB: [],
            //头部基础统计数据
            statistics: '',
            //Investment
            investmentList: [{
                type: 2, investorId: '', investorName: '', proRata: 1, proRataList: [], proRataValue: ['No'],
                investment: '', key: `0${k}`, financingDataKey: k,
            }],
            cnData: {},
            //CN统计
            sharesAllTotal: 0,
            ownershipAllTotal: 0,
            cnTotalList: 0,
            selectedRowKeys: [],
            advanceTerms: financingData[0]?.advanceTerms,
            //是否展开标识
            stretch: true,
            //是否选中标识
            isChecked: true,
            isError: false,
            investorError: false,
            cardBorderRed: false,
            errorAlertMessage: '',
            detailName: `Scenario ${String.fromCharCode(65 + k)}`,
            stakeholderCn: [],
            isSecondId: 1,
            firstOptionPoolShares: financingData?.[firstIndex]?.firstOptionPoolShares,
            firstOptionPoolPercentage: financingData?.[firstIndex]?.firstOptionPoolPercentage,
        };
        financingData.push(newFinancingDataInfo);
        this.updateState({financingData, isMultiple: true});
    }

    getCardInfoHtml = (newFinancingData) => {
        const { financingData, ...data } = this.state;
        return newFinancingData?.info && newFinancingData?.info.map((v, k)=>{
            const { stretch, isChecked, cardBorderRed, errorAlertMessage } = v;
            const finKey = financingData.findIndex(item => item.id == v.id);
            return (
                <div key={v.id} className={`new-financing-round-capitalization-collapse ${cardBorderRed && 'new-financing-round-border-red'} ${!stretch && 'new-financing-round-stretch'}`} >
                    <div className="new-financing-round-capitalization-collapse-checkbox">
                        {data.isMultiple ? <Radio checked={isChecked} onClick={this.financingCheckboxChange.bind(this, finKey)}></Radio> : <Checkbox checked={isChecked} onChange={this.financingCheckboxChange.bind(this, finKey)} ></Checkbox>}
                    </div>
                    <div className="new-financing-round-capitalization-content">
                        <CapitalizationTitle
                            updateState={this.updateState}
                            stretch={stretch}
                            data={data}
                            financingData={financingData}
                            k={finKey}
                            addData={this.addData}
                            deleteData={this.deleteData}
                            getNumber={this.getNumber}
                            clearNumber={this.clearNumber}
                        />
                        {I(errorAlertMessage, '') && <Alert message={errorAlertMessage} type="error" />}
                        <div className="new-financing-round-capitalization-content-form">
                            <CapitalizationForm
                                updateState={this.updateState}
                                data={data}
                                financingData={financingData}
                                advancedTermsChange={this.advancedTermsChange}
                                generalCalculationFormula={this.generalCalculationFormula}
                                resetScenarioData={this.resetScenarioData}
                                k={finKey}
                                getInvestorSum={this.getInvestorSum}
                            />
                            <CapitalizationStakeholder
                                updateState={this.updateState}
                                data={data}
                                financingData={financingData}
                                generalCalculationFormula={this.generalCalculationFormula}
                                getInvestorSum={this.getInvestorSum}
                                k={finKey}
                            />
                            <CapitalizationCn
                                updateState={this.updateState}
                                data={data}
                                financingData={financingData}
                                cnDataList={this.cnDataList}
                                calculation={this.calculation}
                                newStakeholderDataList={this.newStakeholderDataList}
                                k={finKey}
                            />
                        </div>
                    </div>
                </div>
            )
        })
    }

    /**
     * 获取多轮计划默认需要对比的ID
     * @param financingData
     * @param topIdName
     * @returns {{newFinancingData: {}, defaultSelectedIds: *[]}}
     */
    reSerializeFinancingData = (financingData, topIdName = 'topId') => {
        let newFinancingData = {}, defaultSelectedIds = [];
        financingData && financingData.forEach((v, k)=>{
            const topId = v?.[topIdName];
            newFinancingData[topId] = newFinancingData?.[topId] || {};
            newFinancingData[topId].topId = topId;
            newFinancingData[topId].financingName = v.financingName;
            newFinancingData[topId].info = newFinancingData[topId]?.info || [];
            (newFinancingData[topId].info).length == 0 && defaultSelectedIds.push(v.id);
            newFinancingData[topId].info.push(v);
        });
        return { defaultSelectedIds, newFinancingData };
    }

    /**
     * 初始化model数据
     */
    initializationModelList = (data) => {
        let newData = [...data];
        if(newData.length < 1){
            newData.push({
                id: moment().valueOf()+Math.floor(Math.random()*1000),
                round_name: printf( __('%s - New model'), formatDate(moment().format()) ),
                list: [{
                    id: moment().valueOf()+Math.floor(Math.random()*1000),
                    pre_money: '',
                    option_pool: '',
                    option_type: 1,
                    investment: '',
                    closing_date: '',
                    stakeholder_info: [{
                        holderId: 0,
                        investorName: '',
                        is_pro_rata: 1,
                        amountInvested: '',
                    }],
                    advanceTerms: {
                        //1:post money, 2:pre money
                        optionPool: 2,
                        //1:post financing, 2:pre financing
                        conversionMethod: 2,
                        "seniority": 2,
                        "pariPassu": 1,
                        "multiplier": "",
                        "participatingPreferred": "0",
                        "participatingPreferredCap": "",
                        "dividendType": 2,
                        "dividendYield": "",
                        "dividendAccrual": 1,
                        "compoundingInterest": 1
                    },
                    alert: { isShow: false, message: '' },
                    top_id: moment().valueOf()+Math.floor(Math.random()*1000),
                    financing_name: '',
                }],
            });
        }
        return newData;
    }

    /**
     * 初始化并更新 financing round数据
     * @param data
     * @param id
     * @returns {*[]}
     */
    updateFinancingData = (data, id = 0) => {
        const financingId = I(id, data[0].id);
        let newData = [...data];
        let financingData = [];
        const financingIdKey = I(newData, '') && newData.findIndex((v)=>v.id == financingId) || 0;
        const { defaultSelectedIds } = this.reSerializeFinancingData(newData?.[financingIdKey]?.list, 'top_id');
        I(newData[financingIdKey], '') && newData[financingIdKey].list.forEach((v, k)=>{
            const { id, pre_money, option_pool, option_type, investment, closing_date, stakeholder_info,
                advanceTerms, detail_name: detailName, stakeholder_cn:stakeholderCn, top_id: topId, financing_name: financingName } = v;
            const { optionPool, conversionMethod } = advanceTerms;
            const advancedTermsId = this.getAdvancedTermsId(optionPool, conversionMethod);
            let investmentList = [];

            I(stakeholder_info, '') ? stakeholder_info.forEach((v1, k1)=>{
                const { holderId, holderName, is_pro_rata, amountInvested, pro_rata_prefix } = v1;
                const preFix= pro_rata_prefix && JSON.parse(pro_rata_prefix);
                const stakeholderId = I(holderId, 0);
                investmentList.push({
                    // type.(1:Existing, 2:new)
                    type: stakeholderId ? 1 : 2,
                    investorId: stakeholderId,
                    investorName: I(holderName, ''),
                    proRata: I(is_pro_rata, 1),
                    proRataList: [],
                    proRataValue: pro_rata_prefix && preFix.length > 0 ? preFix : ['No'],
                    investment: I(amountInvested, '') && formatNumber(amountInvested),
                    key: `${k1}${k}`,
                    financingDataKey: k,
                });
            }) : ( investmentList = [{
                type: 2, investorId: '', investorName: '', proRata: 1, proRataList: [], proRataValue: ['No'],
                investment: '', key: `0${k}`, financingDataKey: k,
            }] );
            financingData.push(
                {
                    id: id,
                    topId,
                    financingName,
                    advancedTermsId,
                    //估值
                    preMoneyValuation: I(pre_money, '') && formatNumber(pre_money, 2),
                    //期权池
                    postMoneyOptionPool: option_type == 2 ? '' : option_pool && formatNumber( option_pool, 2),
                    postMoneyOptionPoolShares: option_type == 1 ? '' : option_pool && formatNumber( option_pool, 2),
                    postMoneyOptionPoolType: parseInt(I(option_type, 1)),
                    //预融资金
                    totalNewInvestment: I(investment, '') && formatNumber(investment, 2),
                    //日期
                    closingDate: closing_date ? formatDateForDatePicker(closing_date) : moment(),
                    //预计 fullDiluted
                    newTotal: 0,
                    //预 shares、stakeholder
                    stakeholderListB: [],
                    //头部基础统计数据
                    statistics: '',
                    //Investment
                    investmentList: I(investmentList, []),
                    cnData: {},
                    //CN统计
                    sharesAllTotal: 0,
                    ownershipAllTotal: 0,
                    cnTotalList: 0,
                    selectedRowKeys: [],
                    advanceTerms: advanceTerms,
                    //是否展开标识
                    stretch: k == 0 ? true : false,
                    //是否选中标识
                    isChecked: defaultSelectedIds.includes(id),
                    isError: false,
                    investorError: false,
                    cardBorderRed: false,
                    errorAlertMessage: '',
                    detailName: detailName ? detailName : `Scenario ${String.fromCharCode(65+k)}`,
                    stakeholderCn,
                }
            );
        });
        return financingData;
    }

    /**
     * option pool 错误提醒
     */
    verificationFinancing = (pricePerShare, key) => {
        const { financingData, optionErrorMessage } = this.state;
        let newFinancingData = [...financingData];
        const result = parseFloat(pricePerShare) >= 0;
        const errorAlertMessage = !result ? optionErrorMessage : '';
        newFinancingData[key].isError = !result;
        newFinancingData[key].cardBorderRed = false;
        newFinancingData[key].errorAlertMessage = errorAlertMessage;
        newFinancingData[key].statistics = {
            postMoneyValuation: '',
            newPricePerShare: '',
            newMoney: '',
            safeNote: '',
            postMoneyOptionPool: '',
            totalDilution: '',
        };
        newFinancingData[key].stakeholderListB = [];
        this.setState({financingData: newFinancingData, stakeholderSharesShow: false})
        return result;
    }

    /**
     * 表单错误提示汇总
     */
    checkRequest = () => {
        const { financingData, optionErrorMessage } = this.state;
        let newFinancingData = [...financingData];
        let error = 0, errorAlertMessage = '';
        for(let i in newFinancingData) {
            newFinancingData[i].isError = false;
            newFinancingData[i].investorError = false;
            newFinancingData[i].cardBorderRed = false;
            newFinancingData[i].errorAlertMessage = '';
            const { preMoneyValuation, postMoneyOptionPool, postMoneyOptionPoolShares, postMoneyOptionPoolType, totalNewInvestment} = newFinancingData[i];
            //验证表单内容
            if( !I(preMoneyValuation, '')  ){
                let errorArray = [];
                !I(preMoneyValuation, '') && errorArray.push(` ${__('pre-money valuation')}`);
                !I(totalNewInvestment, '') && errorArray.push(` ${__('total new investment')}`);
                if(postMoneyOptionPoolType === 1){
                    !I(postMoneyOptionPool, '') && errorArray.push(` ${__('post-money option pool')}`);
                }else{
                    !I(postMoneyOptionPoolShares, '') && errorArray.push(` ${__('post-money option pool shares')}`);
                }
                let errorStr = printf( __('%s required to model a round'), errorArray.join(',') );
                errorStr = errorStr.charAt(1).toUpperCase() + errorStr.slice(2);
                newFinancingData[i].cardBorderRed = true;
                newFinancingData[i].errorAlertMessage = <span><b>{__('Required field(s) missing')}</b><br/>{errorStr}</span>
                error++;
                continue;
            }
            //验证新轮investor是否超额认购
            const investorRes = this.getInvestorSum(newFinancingData, i);
            if(investorRes !== true){
                newFinancingData[i].investorError = true;
                error++;
            }
            //计算新一轮融资总fullDiluted股数
            const newTotal = this.calculationNewTotal(i);
            const newPricePerShare = this.calculation('newPricePerShare', {newTotal}, i);
            //验证 option poll 是否超限
            if(parseFloat(newPricePerShare) <= 0){
                errorAlertMessage = optionErrorMessage;
                newFinancingData[i].isError = true;
                newFinancingData[i].cardBorderRed = false;
                newFinancingData[i].errorAlertMessage = errorAlertMessage;
                error++;
            }
            newFinancingData[i]['newTotal'] = newTotal;
        }
        if(!error){
            return true;
        }
        this.setState({financingData: newFinancingData});
        return false;
    }

    /**
     * 计算 investor 总和
     * @param value
     * @returns {boolean}
     */
    getInvestorSum = (financingData, i) => {
        const { investmentList, totalNewInvestment } = financingData[i];
        const newTotalNewInvestment = I(this.clearNumber(totalNewInvestment), 0);
        let investmentSum = 0;
        investmentList && investmentList.forEach((v)=>{
            investmentSum = calculator.agile(`${investmentSum} + ${I(this.clearNumber(v.investment), 0)}`);
        })
        if(Number(newTotalNewInvestment) < Number(investmentSum)){
            return calculator.minus(newTotalNewInvestment, investmentSum);
        }
        return true;
    }

    /**
     * 更新financing round name值
     * @param id
     * @param name
     * @returns {Promise<void>}
     */
    saveName = async(id, name, close) => {

        Loading.global(true);
        try {
            const { data: resData } = await post('updateFinancingDetailModel', {id, roundName:name})
            const { code, data } = resData || {}
            if (code == 0) {
                this.updateFinancingDetailModelList({id, name});
                message.success(__('success!'));
                close();
            } else {
                message.error(data)
            }
        } catch(err) {
            message.error(err && err.message)
        }
        Loading.global(false);
    }

    /**
     * 更新 name
     * @param value
     */
    updateFinancingDetailModelList = (value) => {
        const { financingDetailModelList, financingId } = this.state;
        let newModelList = [...financingDetailModelList];
        for(let i in newModelList){
            if(newModelList[i].id == value.id){
                newModelList[i].round_name = value.name;
            }
        }
        let data = {financingDetailModelList};
        if(financingId == value.id){
            data.scenarioModelingName = value.name;
        }
        this.setState(data);
    }

    deleteFinancingRound = async(id, close, isMessage = true) => {
        Loading.global(true);
        try {
            const { data: resData } = await post('deleteFinancingModel', {id})
            const { code, data } = resData || {}
            if (code == 0) {
                isMessage && message.success(__('success!'));
                close && close();
            }
        } catch(err) {
            message.error(err && err.message)
        }
        Loading.global(false);
    }

    /**
     * 更新 financing round name model
     */
    openSaveNameModel = (title, type, {id, name}, refresh) => {
        this.saveNameModel = Modal.open({
            component: props => <ModalContent close={props.close} title={title}>
                {
                    (type == 'add' || type == 'edit' || type == 'editAll') && <SaveNameModel
                        close={props.close}
                        id={id}
                        name={name}
                        saveName={this.saveName}
                        saveFinancing={this.saveFinancing}
                        type={type}
                        refresh={refresh}
                    /> || <ConfirmModel
                        close={props.close}
                        id={id}
                        deleteFinancingRound={this.deleteFinancingRound}
                        getFinancingDetailModelList={this.getFinancingDetailModelList}
                    />
                }
            </ModalContent>,
            width: 700,
            className: 'sprout-modal',
            maskClosable: false
        })

    }

    /**
     * 卸载
     */
    componentWillUnmount() {
        this.saveNameModel && this.saveNameModel.close();
        this.advanceTermsModal && this.advanceTermsModal.close();
    }

    /**
     * Scenario modeling model 菜单
     * @returns {JSX.Element}
     */
    modelMenu = () => {
        const { financingDetailModelList: list, financingId, financingData } = this.state;
        return <Menu className="new-financing-round-model-menu">
            {
                list && list.map((v, k)=>(
                    <Menu.Item key={k} onClick={v.id.toString().length != 13 && this.updateFinancingId.bind(this, v.id, v.round_name, v.create_time)} className={financingId == v.id && 'model-menu-active'}>
                        <span>{v.round_name}</span>
                        {v.id.toString().length != 13 && <div>
                            {!!v.id && <img src={editIcon} onClick={this.openSaveNameModel.bind(this, __('Rename round model'), 'edit', {id:v.id, name:v.round_name}, false)} />}
                            {!!v.id && <img src={deleteIcon} onClick={this.openSaveNameModel.bind(this, __('Delete model'), 'delete', {id:v.id}, false)} />}
                        </div>}
                    </Menu.Item>
                ))
            }
        </Menu>
    }

    updateFinancingId = (id, name, time) => {
        const { DetailModelListData } = this.state;
        //如果数据为空，则需要初始化
        const newData = this.initializationModelList(I(DetailModelListData, []));
        //更新 financingData
        const financingData = this.updateFinancingData(newData, id);
        this.setState({
            financingDetailModelList: newData,
            financingId: id,
            createTime: time,
            scenarioModelingName: name,
            stakeholderSharesShow: false,
            financingData,
        }, ()=>{
            this.init();
        })
    }

    getCustomizeCnData = (cnData) => {
        let newCnData = {};
        cnData && Object.keys(cnData).forEach((v=>{
            newCnData[v] = [];
            cnData[v] && cnData[v].forEach(v1=>{
                const { stakeholder_id, custom_label_id, principal, valuation_cap, conversion_discount, stakeholder, principalInterest } = v1;
                v1.isNew && newCnData[v].push({stakeholder_id, custom_label_id, principal, valuation_cap, conversion_discount, stakeholder, principalInterest});
            });
        }));
        return newCnData;
    }

    /**
     * 组合需要提交的数据
     * @returns {{modelName: string, modelList: *[]}}
     */
    getAddData = (isNew, name, type) => {
        const { financingData: financing, scenarioModelingName, createTime } = this.state;
        const financingData = isNew ? [{
            preMoneyValuation: '',
            totalNewInvestment: '',
            postMoneyOptionPool: '',
            postMoneyOptionPoolShares: '',
            postMoneyOptionPoolType: 1,
            closingDate: '',
            investmentList: [],
            advanceTerms: {
                //1:post money, 2:pre money
                optionPool: 2,
                //1:post financing, 2:pre financing
                conversionMethod: 2,
            },
            detailName: '',
        }] : financing;
        let modelList = [];
        financingData && financingData.forEach((v)=>{
            let { id, preMoneyValuation, totalNewInvestment, postMoneyOptionPool, postMoneyOptionPoolShares,
                postMoneyOptionPoolType, closingDate, investmentList, advanceTerms, detailName, cnData, topId } = v;
            //取出cn自定义数据
            const stakeholderCn = this.getCustomizeCnData(cnData);
            //估值
            preMoneyValuation = this.clearNumber(preMoneyValuation);
            //新轮融资金额
            totalNewInvestment = this.clearNumber(totalNewInvestment);
            //investment
            let investmentData = [];
            investmentList && investmentList.forEach((v1)=>{
                if(v1.type == 2){
                    investmentData.push({
                        holderName: v1.investorName,
                        amountInvested: this.clearNumber(v1.investment),
                    });
                }else{
                    investmentData.push({
                        holderId: v1.investorId,
                        is_pro_rata: 1,
                        amountInvested: this.clearNumber(v1.investment),
                        pro_rata_prefix: JSON.stringify(v1.proRataValue),
                    });
                }
            });
            const { optionPool, conversionMethod, seniority, pariPassu, multiplier,
                participatingPreferred, participatingPreferredCap, dividendType, dividendYield,
                dividendAccrual, compoundingInterest } = advanceTerms;
            modelList.push({...{
                    id,
                    preMoneyValuation,
                    totalNewInvestment,
                    optionPool: this.clearNumber( (postMoneyOptionPoolType === 1 ? postMoneyOptionPool : postMoneyOptionPoolShares) ),
                    option_type: postMoneyOptionPoolType,
                    closingDate: closingDate && closingDate.format('DD/MM/YYYY'),
                    shareHolder: investmentData,
                    detail_name: detailName,
                    advanceTerms: {
                        optionPool,
                        conversionMethod,
                        seniority,
                        pariPassu,
                        multiplier,
                        participatingPreferred,
                        participatingPreferredCap,
                        dividendType,
                        dividendYield,
                        dividendAccrual,
                        compoundingInterest,
                    },
                    stakeholder_cn: JSON.stringify(stakeholderCn),
                    topId
                }});
        })
        return { modelName: (I(name, '') ? name : scenarioModelingName || printf( __('%s - New model'), formatDate(moment().format()) )), create_time: (type !== 'add' ? I(createTime, '') : ''), modelList: modelList};
    }

    saveFinancing = async(isNew, name= '', close, type, refresh) => {
        const { financingId } = this.state;

        if(type !== 'add' && !isNew && financingId){
            //先删除，后添加
            this.deleteFinancingRound(financingId, null, false).then(r => {
                this.addFinancing(false, name, close, type, refresh, financingId);
            });
        }else{
            if(!isNew && !this.checkRequest()){
                return ;
            }
            this.addFinancing(isNew, name, close, type, refresh);
        }
    }

    /**
     * 添加
     * @returns {Promise<void>}
     */
    addFinancing = async(isNew, name, close, type, refresh, financingId) => {
        const addData = this.getAddData(isNew, name, type);
        const { modelName } = addData;
        Loading.global(true);
        const { financingDetailModelList } = this.state;
        try {
            const { data: resData } = await post('addFinancingDetailModel', addData)
            const { code, data } = resData || {}
            if (code == 0) {
                message.success(__('Data added successfully!'));
                //获取 select model 列表
                // financingId && financingDetailModelList.forEach((v, k)=> v.id == financingId && (financingDetailModelList[k].id = data));
                // refresh ? this.getFinancingDetailModelList(data, modelName) : this.setState({financingId: data, financingDetailModelList});
                this.getFinancingDetailModelList();
                close && close();
            } else {
                message.error(data)
            }
        } catch(err) {
            message.error(err && err.message)
        }
        Loading.global(false);
    }

    /**
     * Advanced Terms 弹层
     */
    advancedTermsChange = (key) => {
        const { financingData } = this.state;
        const { advanceTerms } = financingData[key];
        this.advanceTermsModal = Modal.open({
            component: props => <AdvanceTermsModels
                close={props.close}
                advanceTerms={ advanceTerms }
                roundNameValue={ `Scenario ${String.fromCharCode(65+key)}` }
                advanceTermsSubmit={this.advanceTermsSubmit}
                k={key}
            />,
            className: 'certificate-modal-wrap',
            width: '780px',
            maskClosable: false
        })
    }

    createFinancing = (type, refresh) => {
        const { financingId, scenarioModelingName } = this.state;
        const data = {
            add: { title: __('Create new model round model'), type, value: {} },
            editAll: { title: __('Save model round model'), type, value: {id: financingId, name: scenarioModelingName} },
        };
        const { title, type: types, value } = data[type];
        if(!this.checkRequest() && type != 'add'){
            return ;
        }
        this.openSaveNameModel( title, types, value, refresh);
    }

    /**
     * scenario modeling 小数格式化
     * @param value 内容
     * @param decimal 小数位 最多5位
     * @param isPrice 是否是金额
     * @param isShare 是否是 shares
     * @returns {string|*}
     */
    getNumber = (value, decimal, isPrice = false, isShare = false) => {
        value = I(value, '0').toString();
        const { currency, fractional_digit, share_digit } = security.companyInfo;
        decimal = decimal || parseInt(isShare ? share_digit : fractional_digit);
        let {0:leftValue, 1:rightValue} = value.split('.');
        const right = (I(rightValue, '').padEnd(decimal, '0')).substring(0, decimal);
        const returnValue = `${leftValue}.${right}`;
        if( I(rightValue, '').toString().substring(decimal, decimal+1) === '9' && isPrice === false){
            const returnNumber = calculator.agile(`${returnValue} + 0.${'1'.padStart(decimal, '0')}`);
            return formatWithArray(returnNumber);
        }
        return `${isPrice ? `${currency} ` : ''}${formatWithArray(returnValue)}`;
    };

    sortFinancingData = (financingData) => {
        financingData.sort( (x, y) => x.topId - y.topId );
        return financingData
    }

    render() {
        const { financingDetailModelList, ...data } = this.state;
        const { history } = this.props;
        const { loading, scenarioModelingName, financingId, financingData } = data;
        this.sortFinancingData(financingData);
        return (
            <div className="new-financing-round-root">
                <Header history={history} path="financing-round" />
                <Card className="new-financing-round-capitalization">
                    <div className="new-financing-round-central">
                        <h5>{__('Round Modeling')}</h5>
                        <div className="new-financing-round-add">
                            <p>{__('This tool can model the effects of various future financing terms and scenarios on your current capitalization. This tool uses common market terms which may vary based on specific financing documents used. Consult your counsel for final calculations')}.</p>
                            {I(financingId, '') && I(financingDetailModelList, '').length < 5 && <Button type="primary" onClick={this.createFinancing.bind(this, 'add', true)} >{__('Create new model')}</Button>}
                        </div>
                        <Divider />
                        <h5 className="new-financing-round-mt-20">{__('Select model')}</h5>
                        <div className="new-financing-round-central-button">
                            <Dropdown className="new-financing-round-model" overlay={this.modelMenu} trigger={['click']}>
                                <Button type="default">{I(scenarioModelingName, `New model`)} <DownOutlined /></Button>
                            </Dropdown>
                            <Button className="new-financing-round-save"
                                    type="primary"
                                    loading={loading}
                                    onClick={this.createFinancing.bind(this, 'editAll', !I(financingId, ''))}
                            >
                                { I(financingId, '') ? __('Save change') : __('Save') }
                            </Button>
                        </div>
                    </div>
                    <Space style={{width: '100%'}} direction="vertical" size={60}>{this.getCardHtml()}</Space>
                </Card>
                {/*Capitalization Breakdown*/}
                <Card className="new-financing-round-breakdown">
                    <CapitalizationBreakdown
                        data={data}
                        calculation={this.calculation}
                        updateState={this.updateState}
                        cnDataStatistics={this.cnDataStatistics}
                        clearNumber={this.clearNumber}
                        financingData={financingData}
                        getNumber={this.getNumber}
                        checkRequest={this.checkRequest}
                        updateStakeholderData={this.updateStakeholderData}
                        initData={this.initData}
                        switchData={this.switchData}
                    />
                </Card>
            </div>
        );
    }
}
export default FinancingRound;