
import React from 'react';
import axios from 'axios';
import okGif from 'assets/images/finish-gray.gif';
import okURL from 'assets/images/ok.png';
import Utils from "Utils";
import {Link} from "react-router-dom";
import { post } from 'srcPath/http';
import {formatNumber} from "Utils";

class CreatePsPlan extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            sendingStatus:0,
            existingPrefix:["ABC"],
            existingPlanName:["ABC"],
            prefixError:0,
            requiredKey:["seniority_level","voting_multiple","dividend_yield","dividend_type","dividend_accrual","compounding_interest",],
            data:{
                isOnboarding:1,
                share_class_type:"PS",
                share_class_name:"",
                prefix:"",
                total_authorized_shares:"",
                is_certificate_shares:1,
                issue_price:"",
                seniority_level:1,
                pari_passu:0,
                voting_multiple:1,
                original_issue_price:"",
                conversion_price:"",
                conversion_ratio:"",
                multiplier:"",
                participating_preferred:0,
                participating_preferred_cap:"",
                dividend:"Cash",
                dividend_yield:"",
                dividend_type:"Cumulative",
                dividend_accrual:"",
                compounding_interest:"",
                payout_order:"1",
                type:"new",
            },
            showErrorArr : ["dontshow","dontshow","dontshow","dontshow","dontshow"],
            currency:"USD",
            tabKey:1,
            seniorityDetails:[
                // {share_class_name:"Series Seed II Preferred",seniority_level:1,payout_order:"1st"},
                // {share_class_name:"Series Seed I Preferred",seniority_level:"33",payout_order:"3rd"},
                // {share_class_name:"Series Seed III Preferred",seniority_level:4,payout_order:"4th"},
                // {share_class_name:"Series D Preferred",seniority_level:5,payout_order:"5th"},
                // {share_class_name:"Series D Preferred",seniority_level:1,payout_order:"1st"},
                // {share_class_name:"Series Seed II Preferred",seniority_level:2,payout_order:"2nd"},
                // {share_class_name:"Series Seed II Preferred",seniority_level:2,payout_order:"2nd"},
                // {share_class_name:"Common",seniority_level:6,payout_order:"6th"},
            ],
            dividendAccrual:[
                "Daily",
                "Weekly",
                "Monthly",
                "Quarterly (calendar year)",
                "Annually",
            ],
            compoundingInterest:[
                "Simple interest",
                "Daily",
                "Monthly",
                "Semi-annually",
                "Annually",
                "Quarterly (calendar year)",

            ],

        }
    }

    componentDidMount(){

    }

    setCurrency=()=>{
        $("#psStep1").addClass("active");
        axios.post('https://api.getsprout.co', {
            "company_id":Utils.getLocalCompanyInfo().company_id,
            action: 'getCurrency',
            data: {
                "withPrefix":1,
                "withPlanName":1,
                "withSeniorityDetails":1
            }
        }).then((response) => {
            console.log(response);
            if (response.data.code === 0) {
                this.setState({
                    currency:response.data.data.currency,
                    // currency:response.data.data.currency.substring(0,2),
                    existingPrefix:response.data.data.prefixList,
                    existingPlanName:response.data.data.planNameList,
                    seniorityDetails: response.data.data.seniorityDetails
                });
                this.sortSeniorityLevel(true);
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
            //用到this要注意this指向
        }).catch(function (error) {
            console.log(error);
        });
        $("#psInputContent").removeClass("hidden");
    }

    changeTab=(key)=>{
        this.setState({
            tabKey:key
        })
        $("#psStep"+(key-1)).addClass("stock-did-li");
        if(key==2){
            this.insertNewSeniority();
        }
        if(key==4){
            let curErrorArr = this.state.showErrorArr;
            curErrorArr[2] = "dontshow";
            this.removeError();
            this.setState({
                showErrorArr:curErrorArr
            });
        }
    }

    sortSeniorityLevel=(isInit)=>{
        let seniorityDetails = this.state.seniorityDetails;
        //先给上初始数据
        for(let i=0;i<seniorityDetails.length;i++){
            if(!seniorityDetails[i].hasOwnProperty('init_seniority_level')){
                //没有init_seniority_level，要把初始值给上
                seniorityDetails[i].init_seniority_level = seniorityDetails[i].seniority_level;
            }
            if(isInit === true){
                //第一次来排序的，排完序后需要给所有的seniority level+1
                seniorityDetails[i].seniority_level = parseInt(seniorityDetails[i].seniority_level)+1;
            }
        }
        //排序
        seniorityDetails.sort(function(a,b){return Utils.getNumFromFormatNum(a.seniority_level)>Utils.getNumFromFormatNum(b.seniority_level)?1:-1});
        let keyObj = {};
        let payout_order = 0;
        //加sort order
        for(let i=0;i<seniorityDetails.length;i++){
            //循环给上payout_order和init_seniority_level
            if(keyObj.hasOwnProperty(seniorityDetails[i].seniority_level)){
                seniorityDetails[i].payout_order = payout_order;
            }else{
                payout_order++;
                keyObj[seniorityDetails[i].seniority_level] = 1;
                seniorityDetails[i].payout_order = payout_order;
            }
        }
        this.setState({
            seniorityDetails:seniorityDetails
        });
    }

    insertNewSeniority=()=>{
        let seniorityDetails = this.state.seniorityDetails;
        let curData = this.state.data;
        let isInserted = false; //默认没有插入
        for(let i=0;i<seniorityDetails.length;i++){
            //如果发现已经有插入的元素，则将标志位变为true
            if(seniorityDetails[i].hasOwnProperty("type")){
                isInserted = true;
            }
        }
        if(!isInserted){
            seniorityDetails.unshift(curData);
            this.setState({
                seniorityDetails:seniorityDetails
            })
        }
        this.sortSeniorityLevel(false);
    }
    nextStep=(start,end)=>{
        $("li").removeClass("active");
        $("li a").attr({"aria-expanded":false});
        if(start===1 && end ===2){
            let curErrorArr = this.state.showErrorArr;
            curErrorArr[0] = "dontshow";
            this.setState({
                showErrorArr:curErrorArr
            })
            $("#psStep2").addClass("active");
            $("#psStep1").addClass("stock-did-li");
            this.changeTab(2);
        }
        if(start===2 && end ===3){
            let curErrorArr = this.state.showErrorArr;
            curErrorArr[1] = "dontshow";
            this.setState({
                showErrorArr:curErrorArr
            });
            $("#psStep3").addClass("active");
            $("#psStep2").addClass("stock-did-li");
            this.changeTab(3)
        }
        if(start===3 && end ===4){
            let curErrorArr = this.state.showErrorArr;
            curErrorArr[2] = "dontshow";
            this.removeError();
            this.setState({
                showErrorArr:curErrorArr
            });
            if(this.state.data.seniority_level==""){
                let curData = this.state.data;
                curData.seniority_level=0;
                this.setState({
                    data:curData
                })
            }
            $("#psStep4").addClass("active");
            $("#psStep3").addClass("stock-did-li");
            this.changeTab(4)
        }
        if(start===2 && end ===1){
            let curErrorArr = this.state.showErrorArr;
            curErrorArr[2] = "dontshow";
            this.setState({
                showErrorArr:curErrorArr
            })
            $("#psStep1").addClass("active");
            this.changeTab(1);
        }
        if(start===3 && end ===2){
            let curErrorArr = this.state.showErrorArr;
            curErrorArr[3] = "dontshow";
            this.setState({
                showErrorArr:curErrorArr
            })
            $("#psStep2").addClass("active");
            this.changeTab(2)
        }
        if(start===4 && end ===3){
            $("#psStep3").addClass("active");
            $("#psStep4").addClass("stock-did-li");
            this.changeTab(3)
        }
    }
    removeError=()=>{
        this.state.requiredKey.map((value,key)=>{
            if(this.state.data[this.state.requiredKey[key]].length>0){
                $("#"+value).removeClass("has-error");
            }
        });
    }
    recordChecked=(e)=>{
        let dataKey = e.target.name;
        let curData = this.state.data;
        if(curData[dataKey]==1){
            curData[dataKey]=0
        }else{
            curData[dataKey]=1
        }
        if(dataKey == 'pari_passu'){
            //选择pari_passu之后要重新进行排序，
            let seniorityDetails = this.state.seniorityDetails;
            if(curData[dataKey] == 0){
                //这是变成0了，要进行排除同等级的
                //先遍历一遍看看是不是需要进行排除同级的
                let isDuplicatedSeniorityLevel = false;
                for(let i=0;i<seniorityDetails.length;i++){
                    if(Utils.getNumFromFormatNum(seniorityDetails[i].seniority_level)==Utils.getNumFromFormatNum(this.state.data.seniority_level)){
                        //等级相同并且不是new,
                        if(!seniorityDetails[i].hasOwnProperty("type")){
                            isDuplicatedSeniorityLevel = true;
                        }
                    }
                }
                if(isDuplicatedSeniorityLevel){
                    for(let i=0;i<seniorityDetails.length;i++){
                        if(Utils.getNumFromFormatNum(seniorityDetails[i].seniority_level)>=Utils.getNumFromFormatNum(this.state.data.seniority_level)){
                            if(!seniorityDetails[i].hasOwnProperty("type")){
                                seniorityDetails[i].seniority_level = parseInt(seniorityDetails[i].seniority_level)+1;
                            }
                        }
                    }
                }
            }else{
                //这里要根据init_seniority_level进行还原
                for(let i=0;i<seniorityDetails.length;i++){
                    if(Utils.getNumFromFormatNum(seniorityDetails[i].seniority_level)>=Utils.getNumFromFormatNum(this.state.data.seniority_level)){
                        if(!seniorityDetails[i].hasOwnProperty("type")){
                            seniorityDetails[i].seniority_level = parseInt(seniorityDetails[i].init_seniority_level);
                        }
                    }
                }
            }
            this.setState({
                seniorityDetails:seniorityDetails
            });
            this.sortSeniorityLevel(false);

        }
        this.setState({
            data:curData
        })
    }
    recordData=(e)=>{
        let curData = this.state.data;
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        })
    }
    initiaLizeActive=()=>{
        $("#createPsPlanModal .tab-pane").removeClass("active");
        $("#shareClassPs").addClass("active");
        $(".ps_plan_nav .stock-li").removeClass("active");
        $("#psStep1").addClass("active");
        $("#prefix_exist_error").addClass("hidden");
        $("#name_exist_error").addClass("hidden");
        this.setState({
            tabKey:1
        })
    }
    createNewShareClass=()=>{
        if(Utils.isLocal()){
            window.$("#createPsPlanModal").modal("hide");
            setTimeout(()=>{
                this.initStep();
            },300);
            return;
        }
        if(this.state.sendingStatus === 1){
            return;
        }
        this.setState({
            sendingStatus:1
        });
        let requestData = this.state.data;
        let requestSeniorityArr = [];
        for(let i=0;i<this.state.seniorityDetails.length;i++){
            if(this.state.seniorityDetails[i].hasOwnProperty("id")){
                requestSeniorityArr.unshift({
                    id:this.state.seniorityDetails[i].id,
                    seniority_level:this.state.seniorityDetails[i].seniority_level,
                })
            }
        }
        requestData.seniorityDetails = requestSeniorityArr;
        axios.post('https://api.getsprout.co', {
            action: 'createShareClass',
            company_id: Utils.getLocalCompanyInfo().company_id,
            data: requestData
        }).then((response) => {
            if (response.data.code === 0) {
                $("#button-3-5 button").html("Add More");
                $('.finished img').attr('src', okURL);
                $("#ok-3-5").addClass('finished');
                $('#ok-3-5 img').show();
                $('#ok-3-5 img').attr('src', okGif);
                $("#complete-3-5").html("Completed by you just now");
                $("#button-3-6").removeClass('hiddenrain');
                window.$("#createPsPlanModal").modal("hide");
                this.initiaLizeActive();
                this.setState({
                    sendingStatus:0
                });
                setTimeout(()=>{
                    this.initStep();
                },300);
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    showError=(key,e)=>{
        let curErrorArr = this.state.showErrorArr;
        curErrorArr[key] = "show";
        if(key===2){
            this.state.requiredKey.map((value,key)=>{
                if(this.state.data[this.state.requiredKey[key]].length==0){
                    $("#"+value).addClass("has-error");
                }
            });
        }
        this.setState({
            showErrorArr:curErrorArr
        });
    }
    initStep=()=>{
        $("#createPsPlanModal .tab-pane").removeClass("active");
        $("#shareClassPs").addClass("active");
        $(".ps_plan_nav .stock-li").removeClass("active");
        $("#psStep1").addClass("active");
        let initData = {
            share_class_type:"PS",
            share_class_name:"",
            prefix:"",
            total_authorized_shares:"",
            is_certificate_shares:1,
            issue_price:"",
            seniority_level:1,
            pari_passu:0,
            voting_multiple: 1,
            original_issue_price:"",
            conversion_price:"",
            conversion_ratio:"",
            multiplier:"",
            participating_preferred:0,
            participating_preferred_cap:"",
            dividend:"Cash",
            dividend_yield:"",
            dividend_type:"Cumulative",
            dividend_accrual:"",
            compounding_interest:"",
            payout_order: 1
        };
        this.setState({
            tabKey:1,
            data:initData
        })
    }
    focusNumber=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = Utils.resetNumberWithCommas(dataVal);
        this.setState({
            data:curData
        })
    }
    formatNumber=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = Utils.checkNumInput(dataVal);
        this.setState({
            data:curData
        })
    }
    recordNumber=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        if(e.target.name==="seniority_level"){
            let seniorityDetails = this.state.seniorityDetails;
            //遍历seniorityDetails，将type为new的seniority改为新值，并重新排序
            for(let i=0;i<seniorityDetails.length;i++){
                if(seniorityDetails[i].hasOwnProperty("type")){
                    seniorityDetails[i].seniority_level = dataVal;
                    this.setState({
                        seniorityDetails:seniorityDetails
                    });
                }
            }
            this.sortSeniorityLevel(false);
        }
        curData[dataKey] = Utils.checkIntInputWithoutComma(dataVal);
        this.setState({
            data:curData
        })
    }
    recordDecimalNumber=(e)=>{
        let curData = this.state.data;
        let curVal = e.target.value;
        let dataKey = e.target.name;
        if(dataKey=="issue_price"){
            curData.issue_price = Utils.check2DecimalNumInputWithoutComma(curVal);
            curData.original_issue_price = curData.issue_price;
        }else if(dataKey=="voting_multiple"){
            curData[dataKey] = Utils.check5DecimalNumInputWithoutComma(curVal);
        }else if(dataKey=="original_issue_price"){
            curData[dataKey] = Utils.check10DecimalNumInputWithoutComma(curVal);
        }else{
            curData[dataKey] = Utils.check2DecimalNumInputWithoutComma(curVal);
        }
        this.setState({
            data:curData
        });
    }
    formatDecimalNumber=(e)=>{
        let curData = this.state.data;
        let curVal = e.target.value;
        let dataKey = e.target.name;
        if(dataKey=="issue_price"){
            curData.issue_price = formatNumber(curVal);
            curData.original_issue_price = curData.issue_price;
        }else if(dataKey=="voting_multiple"){
            curData[dataKey] = Utils.getNumFormatWithComma5(curVal);
        }else if(dataKey=="original_issue_price"){
            curData[dataKey] = Utils.getNumFormatWithComma(Utils.getNumWith10Decimal(curVal));
        }else{
            curData[dataKey] = formatNumber(curVal);
        }
        this.setState({
            data:curData
        });
    }
    render(){
        let contentWidth = 'fillout-content';
        let step1Error = 1;
        let step2Error = 1;
        let step3Error = 0;
        if(this.state.data.share_class_name && this.state.data.prefix){
        // if(this.state.data.share_class_name && this.state.data.prefix  && this.state.data.total_authorized_shares){
            step1Error = 0;
        }
        if(this.state.data.original_issue_price){
            step2Error = 0;
        }
        if(this.state.data.dividend=="Non-cash"){
            if(this.state.data.dividend_type.length==0
                ||this.state.data.dividend_yield.length==0
                ||this.state.data.dividend_accrual.length==0
                ||this.state.data.compounding_interest.length==0){
                step3Error = 1;
            }
        }
        if(this.state.data.seniority_level=="" || this.state.data.voting_multiple===""){
            step3Error = 1;
        }
        if(this.state.tabKey===3){
            contentWidth = 'content860'
        }
        if(this.state.tabKey===4){
            contentWidth = 'content760'
        }
        let isLegalPrefix = 1; //默认为合法的输入
        for(let i=0;i<this.state.existingPrefix.length;i++){
            if(this.state.data.prefix == this.state.existingPrefix[i]){
                isLegalPrefix = 0;  //有重复改为非法
            }
        }
        let isLegalPlanName = 1; //默认为合法的输入
        for(let i=0;i<this.state.existingPlanName.length;i++){
            if(this.state.data.share_class_name == this.state.existingPlanName[i]){
                isLegalPlanName = 0;  //有重复改为非法
            }
        }
        let defaultOriginalIssuePrice = this.state.data.issue_price;

        return(
            <div className="modal fade" id="createPsPlanModal" tabIndex="-1" role="dialog" aria-labelledby="createPsPlanModal" aria-hidden="true">
                <div className="modal-dialog dialog900">
                    <div className={"modal-content "+contentWidth}>
                        <div className="modal-header box-header">
                            {this.state.tabKey==2 &&
                            <Link to="#shareClassPs" onClick={this.nextStep.bind(this,2,1)} aria-controls="shareClassPs" role="tab" data-toggle="tab" className="back-btn"></Link>
                            }
                            {this.state.tabKey==3 &&
                            <Link to="#sharePricesPs" aria-controls="sharePricesPs" onClick={this.nextStep.bind(this,3,2)} role="tab" data-toggle="tab" className="back-btn"></Link>
                            }
                            {this.state.tabKey==4 &&
                            <Link to="#preferencesPs" aria-controls="preferencesPs" onClick={this.nextStep.bind(this,4,3)} role="tab" data-toggle="tab" className="back-btn"></Link>
                            }
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.initStep}>×</button>
                            <h4 className="modal-title">Creation of Preferred Stock</h4>
                        </div>
                        <div className="modal-body">
                            <div className="vesting-box">
                                <ul className="nav vesting-nav ps_plan_nav" role="tablist">
                                    <li role="presentation" className="stock-li active" id="psStep1">
                                        <a className="stock-a " onClick={this.changeTab.bind(this,1)} href="#shareClassPs" role="tab" data-toggle="tab">1</a>
                                        <p className="shares_p">Create a share class</p>
                                        <div className="hr1"></div>
                                    </li>
                                    {step1Error === 0 && isLegalPrefix===1 && isLegalPlanName===1 &&
                                    <li role="presentation" className="stock-li" id="psStep2">
                                        <a className="stock-a " onClick={this.changeTab.bind(this,2)}  href="#sharePricesPs" role="tab" data-toggle="tab">2</a>
                                        <p className="shares_p">Share prices</p>
                                        <div className="hr1"></div>
                                    </li>
                                    }
                                    {(step1Error ===1 || isLegalPrefix === 0 || isLegalPlanName===0)  &&
                                    <li role="presentation" className="stock-li stock-nodoing">
                                        <a className="stock-a " href="javascript:return false;">2</a>
                                        <p className="shares_p">Share prices</p>
                                        <div className="hr1"></div>
                                    </li>
                                    }
                                    {step2Error === 0 &&
                                    <li role="presentation" className="stock-li" id="psStep3">
                                        <a className="stock-a " onClick={this.changeTab.bind(this,3)}  href="#preferencesPs" role="tab" data-toggle="tab">3</a>
                                        <p className="shares_p">Preferences</p>
                                        <div className="hr1"></div>
                                    </li>
                                    }
                                    {step2Error ===1 &&
                                    <li role="presentation" className="stock-li stock-nodoing">
                                        <a className="stock-a " href="javascript:return false;">3</a>
                                        <p className="shares_p">Preferences</p>
                                        <div className="hr1"></div>
                                    </li>
                                    }
                                    {step3Error ===0 &&
                                    <li role="presentation" className="stock-li" id="psStep4">
                                        <a className="stock-a "  onClick={this.changeTab.bind(this,4)}  href="#reviewPs" role="tab" data-toggle="tab">4</a>
                                        <p className="shares_p">Review</p>
                                    </li>
                                    }
                                    {step3Error ===1 &&
                                    <li role="presentation" className="stock-li stock-nodoing">
                                        <a className="stock-a " href="javascript:return false;">4</a>
                                        <p className="shares_p">Review</p>
                                    </li>
                                    }
                                </ul>
                            </div>
                            <div id="psInputContent" className="tab-content">
                                <div role="tabpanel" className="tab-pane active" id="shareClassPs">
                                    <div className="stock-titbox">
                                        <p>Create a share class</p>
                                    </div>
                                    {this.state.showErrorArr[0] ==="show" &&
                                    <div className="stock-form">
                                        <div className="alert alert-warning alert-dismissable pink-box" role="alert">
                                            <p>Required field(s) can't be empty.</p>
                                        </div>
                                    </div>
                                    }
                                    {isLegalPrefix ===0 &&
                                    <div className="stock-form" id="prefix_exist_error">
                                        <div className="alert alert-warning alert-dismissable pink-box" role="alert">
                                            <p>Share class prefix already exists. Please select another.</p>
                                        </div>
                                    </div>
                                    }
                                    {isLegalPlanName ===0 &&
                                    <div className="stock-form" id="name_exist_error">
                                        <div className="alert alert-warning alert-dismissable pink-box" role="alert">
                                            <p>Share class name already exists. Please select another.</p>
                                        </div>
                                    </div>
                                    }
                                    <div className="form-horizontal stock-form">
                                        <div className="form-group">
                                            <label className="col-xs-4 add-label">Class type</label>
                                            <div className="col-xs-5">
                                                <div className="checkbox text-muted equity-checkbox">
                                                    <input type="checkbox" checked={this.state.data.share_class_type==="PS"} required readOnly={true} className="send-box equity-checkbox" id="ps_plan_type" name="share_class_type"/>
                                                    <label className="send-div" htmlFor="ps_plan_type"></label><span className="type-label"> &nbsp;&nbsp;Preferred</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group ">
                                            <label className="col-xs-4 add-label">Share class</label>
                                            <div className="col-xs-5">
                                                <input name="share_class_name" onChange={this.recordData} value={this.state.data.share_class_name} className="form-control" required type="text" placeholder="Series A" />
                                            </div>
                                        </div>
                                        <div className="form-group ">
                                            <div>
                                                <label className="col-xs-4 add-label">Share class prefix</label>
                                                <div className="col-xs-2">
                                                    <input name="prefix" value={this.state.data.prefix} onChange={this.recordData} className="form-control uppercase_form" required type="text" placeholder="PSA" />
                                                </div>
                                                <p className="text-muted prefix-p col-xs-7 col-xs-push-4">Letters and numbers only.</p>
                                            </div>
                                        </div>
                                        <div className="form-group ">
                                            <label className="col-xs-4 add-label">Total authorized shares</label>
                                            <div className="col-xs-4">
                                                <div className="input-group">
                                                    <input type="text" name="total_authorized_shares" onBlur={this.formatNumber} onFocus={this.focusNumber} onChange={this.recordNumber} value={this.state.data.total_authorized_shares} required className="form-control" placeholder="2,000,000"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-xs-4 add-label ques-label">Certificated shares
                                                <a id="stock-quespop" className="create-ques stock-ques">
                                                    <p className="captable-tiptext tiptext-top108">If you do not select this, Folium can help your company to issue digital share certificates to stakeholders.</p>
                                                    {/*<p className="captable-tiptext stock-tiptext">If you do not select this, your company has yet to issue shares to stakeholders and Folium will help you issue digital share certificates.</p>*/}
                                                </a>
                                            </label>
                                            <div className="col-xs-5">
                                                <div className="checkbox text-muted equity-checkbox">
                                                    <input type="checkbox" className="send-box equity-checkbox" id="ps_certificated_shares" name="is_certificate_shares" onChange={this.recordChecked} checked={this.state.data.is_certificate_shares} />
                                                    <label className="send-div" htmlFor="ps_certificated_shares"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div role="tabpanel" className="tab-pane " id="sharePricesPs">
                                    <div className="stock-titbox">
                                        <p>Create prices</p>
                                    </div>
                                    <div className="stock-form">
                                        {this.state.showErrorArr[1] === "show" &&
                                        <div className="alert alert-warning alert-dismissable pink-box" role="alert">
                                            <p>Required field(s) can't be empty.</p>
                                        </div>
                                        }
                                    </div>
                                    <div className="form-horizontal stock-form">
                                        <div className="form-group ">
                                            <label className="col-xs-4 add-label">Original issue price</label>
                                            <div className="col-xs-4">
                                                <div className="input-group " id="original_issue_price_inputbox">
                                                    <span className="input-group-addon">{this.state.currency}</span>
                                                    <input type="text" name="original_issue_price" onBlur={this.formatDecimalNumber} onFocus={this.focusNumber} onChange={this.recordDecimalNumber} value={this.state.data.original_issue_price} required className="form-control" placeholder="e.g. 0.01"/>
                                                </div>
                                            </div>
                                            <div className="stock-tipbox">
                                                <p className="col-xs-7 col-xs-push-4 text-muted">
                                                    This is your post-money valuation (pre-money plus new investment) divided by full diluted shares outstanding (outstanding plus all convertible and exercisable shares).
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div role="tabpanel" className="tab-pane " id="preferencesPs">
                                    <div className="stock-titbox">
                                        <p>Preferences</p>
                                    </div>
                                    <div className="stock-form">
                                        {this.state.showErrorArr[2] === "show" &&
                                        <div className="alert alert-warning alert-dismissable pink-box" role="alert">
                                            <p>Required field(s) can't be empty.</p>
                                        </div>
                                        }
                                    </div>
                                    <div className="stock-form">
                                        <div className="marginBott50">
                                            <div>
                                                <p className="font_weight500">Seniority details</p>
                                                <p className="text-muted">Enter the seniority details of the share class.</p>
                                                <div className="equity-tipbox">
                                                    <div className="light-tip"></div>
                                                    <p>Share class seniority is ordered so that seniority 1 is the most senior and will be paid out first.</p>
                                                </div>
                                            </div>
                                            <div className="form-horizontal">
                                                <div className="form-group add-items">
                                                    <label className="col-xs-3 add-label">Seniority level</label>
                                                    <div className="col-xs-4" id="seniority_level">
                                                        <input value={this.state.data.seniority_level} name="seniority_level" placeholder="e.g. 1" onBlur={this.formatNumber} onFocus={this.focusNumber} onChange={this.recordNumber} className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-xs-3 add-label ques-label">Pari-passu<br /><i className="text-muted">- Optional</i></label>
                                                    <div className="col-xs-9">
                                                        <div className="checkbox text-muted equity-checkbox">
                                                            <input type="checkbox" className="send-box equity-checkbox" checked={this.state.data.pari_passu==1} id="pari_passu" name="pari_passu"  onChange={this.recordChecked}/>
                                                            <label className="send-div" htmlFor="pari_passu"></label>
                                                        </div>
                                                        <p className="text-muted mb-0">Mark this share class as having equal seniority to an existing share class. Share classes with equal seniority will be paid out at the same time.</p>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-xs-3 add-label ques-label">Seniority preview</label>
                                                    <div className="col-xs-9">
                                                        <table className="table viewHoldings-table vested-table marginTop10 no-marginBottom">
                                                            <tbody>
                                                            <tr className="first-row">
                                                                <td>Share class</td>
                                                                <td className="vested-3Td">Seniority</td>
                                                                <td>Payout order</td>
                                                            </tr>
                                                            {
                                                                this.state.seniorityDetails && this.state.seniorityDetails.map((value,key)=>{
                                                                    let payOutOrder = 1;
                                                                    if(value.payout_order==1){
                                                                        payOutOrder = value.payout_order +"st"
                                                                    }else if(value.payout_order==2){
                                                                        payOutOrder = value.payout_order +"nd"
                                                                    }else if(value.payout_order==3){
                                                                        payOutOrder = value.payout_order +"rd"
                                                                    }else{
                                                                        payOutOrder = value.payout_order +"th"
                                                                    }
                                                                    var trClassName = "bg-lightgray";
                                                                    if(value.hasOwnProperty("type")){
                                                                        trClassName = "bg-skyblue";
                                                                    }
                                                                    return(
                                                                        <tr  className={trClassName} key={key}>
                                                                            <td>{value.share_class_name}</td>
                                                                            <td className="vested-3Td">{value.seniority_level!==""? value.seniority_level: <p className="mb-0 text-danger">N/A</p>}</td>
                                                                            <td>{payOutOrder}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="form-group add-items">
                                                    <label className="col-xs-3 add-label">Voting multiple</label>
                                                    <div className="col-xs-4"  id="voting_multiple">
                                                        <input value={this.state.data.voting_multiple}
                                                               name="voting_multiple" placeholder="e.g. 1"  onBlur={this.formatDecimalNumber} onFocus={this.focusNumber}
                                                               onChange={this.recordDecimalNumber} className="form-control"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="marginBott50">
                                            <div className="marginBott20">
                                                <p className="font_weight500">Rights and preferences</p>
                                                <p className="text-muted">Enter the rights and preferences of the share class.</p>
                                            </div>
                                            <div className="form-horizontal">
                                                <div className="form-group ">
                                                    <label className="col-xs-3 add-label">Conversion price<br /><i className="text-muted">- Optional</i></label>
                                                    <div className="col-xs-4">
                                                        <div className="input-group">
                                                            <span className="input-group-addon">{this.state.currency}</span>
                                                            <input type="text" name="conversion_price"  onBlur={this.formatDecimalNumber} onFocus={this.focusNumber}  onChange={this.recordDecimalNumber} value={this.state.data.conversion_price} required className="form-control" placeholder="e.g. 0.01"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group ">
                                                    <label className="col-xs-3 add-label">Conversion Ratio<br /><i className="text-muted">- Optional</i></label>
                                                    <div className="col-xs-4">
                                                        <input type="text" name="conversion_ratio" onBlur={this.formatDecimalNumber} onFocus={this.focusNumber}  onChange={this.recordDecimalNumber} value={this.state.data.conversion_ratio} required className="form-control" placeholder="e.g. 0.01"/>
                                                    </div>
                                                    <div className="stock-tipbox">
                                                        <p className="col-xs-9  text-muted">Conversion will be calculated with conversion ratio instead of conversion price if both are entered.</p>
                                                    </div>
                                                </div>
                                                <div className="form-group ">
                                                    <label className=" col-xs-3 add-label">Multiplier<br /><i className="text-muted">- Optional</i></label>
                                                    <div className=" col-xs-4">
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" placeholder="1" name="multiplier" value={this.state.data.multiplier} onBlur={this.formatDecimalNumber} onFocus={this.focusNumber}  onChange={this.recordDecimalNumber}/>
                                                            <span className="input-group-addon">X</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-xs-3 add-label">Participating Preferred</label>
                                                    <div className="col-xs-1">
                                                        <div className="checkbox text-muted equity-checkbox">
                                                            <input type="radio"  onChange={this.recordChecked} className="send-box equity-checkbox" id="participating_preferred_yes" name="participating_preferred" value="1" checked={this.state.data.participating_preferred==1} />
                                                            <label className="send-div" htmlFor="participating_preferred_yes"></label><span className="type-label"> &nbsp;&nbsp;Yes</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-1">
                                                        <div className="checkbox text-muted equity-checkbox">
                                                            <input type="radio"  onChange={this.recordChecked} className="send-box equity-checkbox" id="participating_preferred_no" name="participating_preferred" value="0"  checked={this.state.data.participating_preferred==0}/>
                                                            <label className="send-div" htmlFor="participating_preferred_no"></label><span className="type-label"> &nbsp;&nbsp;No</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.data.participating_preferred==1 &&
                                                <div className="form-group ">
                                                    <label className=" col-xs-3 add-label">Participating preferred cap<br /><i className="text-muted">- Optional</i></label>
                                                    <div className=" col-xs-4">
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" placeholder="1" name="participating_preferred_cap" value={this.state.data.participating_preferred_cap} onBlur={this.formatDecimalNumber} onFocus={this.focusNumber}  onChange={this.recordDecimalNumber}/>
                                                            <span className="input-group-addon">X</span>
                                                        </div>
                                                    </div>
                                                    <div className="stock-tipbox">
                                                        <p className="col-xs-7 text-muted">Blank or zero means there is no cap. No cap means the preferred classes are fully participating.</p>
                                                    </div>
                                                </div>
                                                }

                                            </div>
                                        </div>
                                        <div className="marginBott20">
                                            <div className="marginBott20">
                                                <p className="font_weight500">Dividends</p>
                                                <p className="text-muted">Enter the dividend details of the share class.</p>
                                            </div>
                                            <div className="form-horizontal">
                                                <div className="form-group">
                                                    <label className="col-xs-3 add-label">Dividend</label>
                                                    <div className="col-xs-2">
                                                        <div className="checkbox text-muted equity-checkbox">
                                                            <input type="radio"  onChange={this.recordData} className="send-box equity-checkbox" id="ps_dividend_non_cash" name="dividend" value="Non-cash" checked={this.state.data.dividend=="Non-cash"} />
                                                            <label className="send-div" htmlFor="ps_dividend_non_cash"></label><span className="type-label"> &nbsp;&nbsp;Non-cash</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-1">
                                                        <div className="checkbox text-muted equity-checkbox">
                                                            <input type="radio"  onChange={this.recordData} className="send-box equity-checkbox" id="ps_dividend_cash" name="dividend" value="Cash" checked={this.state.data.dividend=="Cash"}/>
                                                            <label className="send-div" htmlFor="ps_dividend_cash"></label><span className="type-label"> &nbsp;&nbsp;Cash</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.data.dividend=="Non-cash" &&
                                                <div>
                                                    <div className="form-group ">
                                                        <label className="col-xs-3 add-label">Dividend yield</label>
                                                        <div className="col-xs-4" id="dividend_yield">
                                                            <div className="input-group">
                                                                <input type="text" name="dividend_yield" onBlur={this.formatDecimalNumber} onFocus={this.focusNumber}  onChange={this.recordDecimalNumber} value={this.state.data.dividend_yield} required className="form-control" placeholder="e.g. 3.5%"/>
                                                                <span className="input-group-addon">%</span>
                                                            </div>
                                                        </div>
                                                        <div className="stock-tipbox">
                                                            <p className="col-xs-7 col-xs-push-3 text-muted">(Per share dividend ÷ Price/share) x 100 = Dividend yield<br />
                                                                E.g. ($0.01125 ÷ $0.75) x 100 = 1.5%.</p>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-xs-3 add-label">Dividend Type</label>
                                                        <div className="col-xs-2">
                                                            <div className="checkbox text-muted equity-checkbox">
                                                                <input type="radio"  onChange={this.recordData} className="send-box equity-checkbox" id="dividend_type_cumulative" name="dividend_type" value="Cumulative" checked={this.state.data.dividend_type=="Cumulative"} />
                                                                <label className="send-div" htmlFor="dividend_type_cumulative"></label><span className="type-label"> &nbsp;&nbsp;Cumulative</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-3">
                                                            <div className="checkbox text-muted equity-checkbox">
                                                                <input type="radio"  onChange={this.recordData} className="send-box equity-checkbox" id="dividend_type_non_cumulative" name="dividend_type" value="Non-cumulative"  checked={this.state.data.dividend_type=="Non-cumulative"}/>
                                                                <label className="send-div" htmlFor="dividend_type_non_cumulative"></label><span className="type-label"> &nbsp;&nbsp;Non-cumulative</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group add-items">
                                                        <label className="col-xs-3 add-label">Dividend accrual</label>
                                                        <div className="col-xs-4"  id="dividend_accrual">
                                                            <select value={this.state.data.dividend_accrual} name="dividend_accrual" onChange={this.recordData} className="form-control select-container">
                                                                <option value=""></option>
                                                                {this.state.dividendAccrual.map((value,key)=>{
                                                                    return(
                                                                        <option key={key} value={value}>{value}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group add-items">
                                                        <label className="col-xs-3 add-label">Compounding interest</label>
                                                        <div className="col-xs-4"  id="compounding_interest">
                                                            <select value={this.state.data.compounding_interest} name="compounding_interest" onChange={this.recordData} className="form-control select-container">
                                                                <option value=""></option>
                                                                {this.state.compoundingInterest.map((value,key)=>{
                                                                    return(
                                                                        <option key={key} value={value}>{value}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div role="tabpanel" className="tab-pane " id="reviewPs">
                                    <div className="stock-titbox">
                                        <p>Review</p>
                                    </div>
                                    <div className="review-contentbox">
                                        <p><span className="title-img glyphicon glyphicon-file"></span>General Information</p>
                                        <div className="review-tablebox">
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td>Class type</td>
                                                    <td>{this.state.data.share_class_type}</td>
                                                </tr>
                                                <tr>
                                                    <td>Name</td>
                                                    <td>{this.state.data.share_class_name}</td>
                                                </tr>
                                                <tr>
                                                    <td>Prefix</td>
                                                    <td>{this.state.data.prefix}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total authorized shares</td>
                                                    <td>{this.state.data.total_authorized_shares}</td>
                                                </tr>
                                                <tr>
                                                    <td>Certificated shares</td>
                                                    <td>{Utils.getYesOrNoText(this.state.data.is_certificate_shares)}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="review-contentbox">
                                        <p><span className="title-img glyphicon glyphicon-usd"></span>Share prices</p>
                                        <div className="review-tablebox">
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td>Current Issue Price</td>
                                                    <td>{this.state.currency} {this.state.data.original_issue_price}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="review-contentbox">
                                        <p><span className="title-img glyphicon glyphicon-usd"></span>Preferences</p>
                                        <div className="review-tablebox">
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td>Seniority</td>
                                                    <td>{this.state.data.seniority_level}</td>
                                                </tr>
                                                <tr>
                                                    <td>Pari-passu</td>
                                                    <td>{Utils.getYesOrNoText(this.state.data.pari_passu)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Voting multiple</td>
                                                    <td>{this.state.data.voting_multiple}</td>
                                                </tr>
                                                <tr>
                                                    <td>Original issue price</td>
                                                    <td>{this.state.currency} {this.state.data.original_issue_price}</td>
                                                </tr>
                                                <tr>
                                                    <td>Conversion price</td>
                                                    {this.state.data.conversion_price==""&&
                                                    <td>0</td>
                                                    }
                                                    {this.state.data.conversion_price !== "" &&
                                                    <td>{this.state.currency} {this.state.data.conversion_price}</td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <td>Conversion ratio</td>
                                                    {this.state.data.conversion_ratio == "" &&
                                                    <td>0</td>
                                                    }
                                                    {this.state.data.conversion_ratio !== "" &&
                                                    <td>{this.state.data.conversion_ratio}x</td>
                                                    }

                                                </tr>
                                                <tr>
                                                    <td>Multiplier</td>
                                                    {this.state.data.multiplier == "" &&
                                                    <td>0</td>
                                                    }
                                                    {this.state.data.multiplier !== "" &&
                                                    <td>{this.state.data.multiplier}x</td>
                                                    }

                                                </tr>
                                                <tr>
                                                    <td>Participating preferred</td>
                                                    <td>{Utils.getYesOrNoText(this.state.data.participating_preferred)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Preference cap</td>
                                                    {this.state.data.participating_preferred_cap == "" &&
                                                    <td>0</td>
                                                    }
                                                    {this.state.data.participating_preferred_cap !== "" &&
                                                    <td>{this.state.data.participating_preferred_cap}x</td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <td className="no_border_bottom">Non-cash dividend</td>
                                                    <td className="no_border_bottom">{this.state.data.dividend}</td>
                                                </tr>
                                                {this.state.data.dividend==='Non-cash' &&
                                                <tr>
                                                    <td colSpan="2" className="inside_table">
                                                        <table>
                                                            {this.state.data.dividend_yield === "" &&
                                                            <tbody>
                                                            <tr>
                                                                <td>Coupon</td>
                                                                <td>&nbsp;</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Type</td>
                                                                <td>&nbsp;</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Dividend accrual</td>
                                                                <td>&nbsp;</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Compounding interest</td>
                                                                <td>&nbsp;</td>
                                                            </tr>
                                                            </tbody>
                                                            }
                                                            {this.state.data.dividend_yield !== "" &&
                                                            <tbody>
                                                            <tr>
                                                                <td>Coupon</td>
                                                                <td>{this.state.data.dividend_yield}%</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Type</td>
                                                                <td>{this.state.data.dividend_type}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Dividend accrual</td>
                                                                <td>{this.state.data.dividend_accrual}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Compounding interest</td>
                                                                <td>{this.state.data.compounding_interest}</td>
                                                            </tr>
                                                            </tbody>
                                                            }
                                                        </table>
                                                    </td>

                                                </tr>
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="nav" role="tablist">
                            {this.state.tabKey==1 &&
                            <div role="presentation" className="modal-footer box-footer vesting-footer">
                                <a data-dismiss="modal" className="btn boxbtn-to bg-darkblue width120">Go Back</a>
                                {step1Error ===0 && isLegalPrefix===1 && isLegalPlanName===1 && <a data-target="#sharePricesPs" onClick={this.nextStep.bind(this,1,2)} role="tab" data-toggle="tab" className="btn boxbtn-to bg-darkblue">Next: Share prices</a>}
                                {step1Error ===1 && <a onClick={this.showError.bind(this,0)}  className="btn boxbtn-to bg-darkblue">Next: Share prices</a>}
                                {(isLegalPrefix ===0 || isLegalPlanName===0) && step1Error===0 && <button  className="btn boxbtn-to bg-darkblue">Next: Share prices</button>}
                            </div>
                            }
                            {this.state.tabKey == 2 &&
                            <div role="presentation" className="modal-footer box-footer preStock-footer">
                                <a data-toggle="tab" data-target="#shareClassPs"  onClick={this.nextStep.bind(this,2,1)} className="btn boxbtn-to bg-darkblue width120">Go Back</a>
                                {step2Error === 0 && <a  data-target="#preferencesPs" role="tab" data-toggle="tab" onClick={this.nextStep.bind(this,2,3)} className="btn boxbtn-to bg-darkblue prices-btn">Next: Preferences</a>}
                                {step2Error === 1 && <a onClick={this.showError.bind(this,1)}  className="btn boxbtn-to bg-darkblue prices-btn">Next: Preferences</a>}
                            </div>
                            }
                            {this.state.tabKey == 3 &&
                            <div role="presentation" className="modal-footer box-footer preStock-footer">
                                <a data-toggle="tab" href="#sharePricesPs"  onClick={this.nextStep.bind(this,3,2)} className="btn boxbtn-to bg-darkblue width120">Go Back</a>
                                {step3Error===0 &&
                                <a  data-target="#reviewPs" role="tab" data-toggle="tab" onClick={this.nextStep.bind(this,3,4)} className="btn boxbtn-to bg-darkblue prices-btn">Next: Review</a>
                                }
                                {step3Error===1 &&
                                <a  onClick={this.showError.bind(this,2)} className="btn boxbtn-to bg-darkblue prices-btn">Next: Review</a>
                                }
                                {/*{this.state.data.issue_price && this.state.data.original_issue_price && <a  data-target="#reviewPs" role="tab" data-toggle="tab" onClick={this.nextStep.bind(this,3,4)} className="btn boxbtn-to bg-darkblue prices-btn">Next: Review</a>}*/}
                                {/*{(!this.state.data.issue_price || !this.state.data.original_issue_price)  && <a onClick={this.showError.bind(this,2)}  className="btn boxbtn-to bg-darkblue prices-btn">Next: Review</a>}*/}
                            </div>
                            }
                            {this.state.tabKey == 4 &&
                            <div role="presentation" className="modal-footer box-footer preStock-footer">
                                <a data-toggle="tab" href="#preferencesPs"  onClick={this.nextStep.bind(this,4,3)} className="btn boxbtn-to bg-darkblue width120">Go Back</a>
                                <button onClick={this.createNewShareClass} type="button" className="btn boxbtn-to bg-darkblue prices-btn">Create new share class</button>
                            </div>
                            }
                        </div>
                    </div>

                </div>
            </div>
        )
    }

}

export default CreatePsPlan;

