import React, { Component } from "react";
import { Button, Input } from "antd";
import moment from "moment";
import { formatDate } from 'Utils/format/formatDate'
import { I } from "Utils"

class SaveNameModel extends Component {

    constructor(props) {
        super(props);
        const { id, name, type, refresh } = props;
        this.state = {
            id, name, type, refresh, defaultValue: `${formatDate(moment().format())} - New model`,
        };
    }

    inputChange = (e) => {
        const newName = e.target.value;
        this.setState({name: newName})
    }

    saveName = () => {
        const { id, name, type, refresh } = this.state;
        const { saveName, close, saveFinancing } = this.props;
        if(type === 'add'){
            saveFinancing(true, name, close, type, refresh);
        }else if(type === 'editAll'){
            saveFinancing(false, name, close, type, refresh);
        }else{
            saveName(id, name, close);
        }
    }

    render() {
        const { name, defaultValue } = this.state;
        return (
            <div className="new-financing-round-save-name">
                <div>
                    <label>{__('Name of round model')}</label>
                    <Input value={I(name, '') ? name : defaultValue} onChange={this.inputChange} />
                </div>
                <Button type="primary" onClick={this.saveName}>{__('Save')}</Button>
            </div>

        );
    }
}
export default SaveNameModel;