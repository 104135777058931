import React, { Component } from 'react';
import { Button } from 'antd';
import '../index.scss';

class OperateButton extends Component {
	render () {
		return <div className="template-operate" >
            <Button data-action="preview">{__('View')}</Button>
        </div>
	}
}

export default OperateButton;