import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import security from 'stores/security';
import withLogin from 'components/withLogin';
import { I } from "Utils";
import { observer } from 'mobx-react';
import Permission from 'components/Permission';
import Header from 'components/Layout/Header';
import NavMenu from 'components/Layout/NavMenu';
import Onboarding from './NewOnboarding';
import SearchPage from './SearchPage'
import Loading from 'components/Loading';
import PersonalCenter from 'components/PersonalCenter';
import CheckPlan from './CheckPlan';

import { processMenuconfig, getRouteConfig } from './config'

@observer
@withLogin
export default class Business extends Component {
    path = this.props.match.path
    showToken = (security.companyInfo && security.companyInfo.is_show_coin) === '1'

    render() {
        if (!security.companyInfo?.is_finished_onboarding) {
            return <Loading />
        }

        const config = getRouteConfig({ showToken: this.showToken }).filter((value, key) => security.hasPermission(value.permission))
        const finishOnboarding = security.companyInfo?.is_finished_onboarding && security.companyInfo?.is_finished_onboarding === '1'
        // 是否有onboarding 权限
        const showOnboarding = security.hasPermission('company_setup')
        // 如果没有onboarding 权限 或者完成了 onboarding，需要展示其他路由
        const showOthers = finishOnboarding || !showOnboarding
        const { pay_status, pay_type, is_finished_onboarding } = security.companyInfo;

        // const showOthers = true\
        // 定制化需求 id 为446的公司固定跳转
        const redirectPath = security.env === 'prod' && ['446', '411'].includes(security.companyInfo?.company_id) ? '/home/token/ownership' : config?.[0]?.path
        return <div className="sprout-client-root">
            <Header />
            <NavMenu menuConfig={processMenuconfig({ showToken: this.showToken })} />
            <div className="sprout-client-content-wrap">
                <div className="sprout-client-content">
                    <Switch>
                        <Route exact path="/home/personal-center" component={PersonalCenter} />
                        <Route exact path="/home/exchange" component={SearchPage} />
                        <Route path="/home/check-plan" component={CheckPlan} exact />
                        {    // 有权限且没有完成onboarding
                            !finishOnboarding && showOnboarding && !(pay_type == 1 && !I(pay_status, 0) && !I(security.companyInfo.is_finished_onboarding, '')) && <Route path="/home/onboarding" component={Onboarding} />
                        }
                        {
                            showOthers && config.map((value, key) => (<Route key={key} exact={!!(value.exact)} path={value.path} component={value.component} />))
                        }
                        <Redirect to={showOthers && redirectPath || '/home/check-plan'} />
                    </Switch>
                </div>
            </div>
        </div>
    }
}

