import React, { useState, useEffect } from 'react';
import { Button, message, Checkbox, DatePicker, Space } from 'antd';
import Loading from 'components/Loading';
import DateSelector from 'components/DateSelector';
import { FormItem, withForm } from 'react-antd-formutil';
import moment from 'moment';
import { post } from 'srcPath/http';
import security from 'stores/security';
import icon from '../../images/icon.png';
import { formatDate2Api } from 'Utils/format/formatDate';
import Modal, { ModalContent } from 'components/Modal';

const CheckboxGroup = Checkbox.Group;

const data = [
	{
		label: __('By common shares'),
		value: '1'
	},
	{
		label: __('By preferred shares'),
		value: '2'
	},
	{
		label: __('By convertibles'),
		value: '3'
	},
]

function Securities({ $formutil, getPreviewList }) {
	const [indeterminate, setIndeterminate] = useState(true);
	const [checkAll, setCheckAll] = useState(false);
	const { $invalid, $batchDirty, $params, $getFirstError, $setValues } = $formutil

	const onSubmit = async () => {
		if ($invalid) {
			$batchDirty(true)
			message.error($getFirstError())

			return
		}

		Loading.global(true);
		try {

			const { data: resData } = await post('exportShareSecurity', { ledger: $params.ledger, is_export: 0 })

			const { data, code } = resData || {}

			if (code === 0) {
				message.success(__('Success'))
				getPreviewList(data, $params,'Securities')
				security.getFileData()
			} else {
				message.error(data)
			}

		} catch (e) {
			message.error(e.message)
		}

		Loading.global(false)
	}

	useEffect(() => {
		const checkedLength = $params.ledger && $params.ledger.length || 0

		setIndeterminate(checkedLength > 0 && checkedLength < data.length)

		setCheckAll(checkedLength === data.length)
	}, [$params.ledger])

	const onCheckAllChange = e => {
		const checked = e.target.checked
		setCheckAll(checked)
		setIndeterminate(false)

		$setValues({ ledger: checked ? data.map(val => val.value) : [] })
	}

	return <div className="sprout-reports-item-container">
		<div className="sprout-reports-item-left">
			<div className="sprout-reports-item-row">
				<div className="sprout-reports-icon">
					<img src={icon} />
				</div>
				<div className="sprout-reports-item-content">
					<div className="title">{__('Securities')}</div>
					<div className="desc">{__('See ledgers of securities grouped by type.')}</div>
					<div className="item-section">
						<Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>{__('All share class')}</Checkbox>
						<div className="item-section-sub">
							<FormItem
								validMessage={{ required: __('Select one at least') }}
								required
								name="ledger"
								$defaultValue={['1']}
							>
								<CheckboxGroup>
									<Space direction="vertical">
										{
											data.map(val => <Checkbox key={val.value} value={val.value}>{val.label}</Checkbox>)
										}
									</Space>
								</CheckboxGroup>
							</FormItem>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="btns">
			<Space size={15}>
				<span>{__('As of')} {formatDate2Api(moment())}</span>
				{/*<DateSelector bordered={true} disabled format={security.companyInfo && security.companyInfo.date_format} defaultValue={moment()} />*/}
				<Button type="primary" onClick={onSubmit}>{__('Preview')}</Button>
			</Space>
		</div>
	</div>
}

export default withForm(Securities)
