import React, { useEffect, useState } from 'react';
import Echarts from 'components/Echarts';
import { message, Spin } from 'antd';
import { post } from 'srcPath/http';
import { formatCurrencyNumber, tranNumber } from 'Utils';
import { formatDate } from 'Utils/format/formatDate';
import emptyImg from 'assets/images/bar.png';
import './style.scss';

var option = {
    tooltip: {
		show: true,
		formatter: (data) => {
			const name = data.data.terms_name || data.data.share_class_name;
			return `${name}: ${formatCurrencyNumber(data.value, 0)}`
		}
    },	
    grid: {
    	containLabel: true,
    	left: 10,
    	top: 20,
    	right: 20,
    	bottom: 10
    },
    xAxis: {
        type: 'category',
        axisLine: {
        	lineStyle: {
        		color: '#524e4ed9'
        	}
        },
        axisTick: {
            show: false
        }
    },
    yAxis: {
    	show: true,
        axisLine: {
			show: true,
        	lineStyle: {
        		color: '#524e4ed9'
        	}
        },    	
        axisTick: {
            show: false
		},
		axisLabel: {
			formatter: (value) => {
				return tranNumber(value, 1, true);
			}
		}
    },
    series: [
        {
        	type: 'bar', 
			barMaxWidth: 40, 
        	label: {
        		show: true,
        		position: 'top',
        		formatter: params => formatCurrencyNumber(params.data && (params.data.cashRaised || params.data.cn_principal), 0),
        		color: '#000'
        	},
			itemStyle: {
				normal: {
					barBorderRadius: [4, 4, 0, 0],
					color: (params) => {
						return params.data && params.data.cashRaised !== undefined ? '#88C84D' : 'rgb(2, 161, 254)'
					}, 
				},
			}
        },
    ]
};

function processData(data = []) {
	let xAxisData = []
	let seriesData = []

	data.forEach((val) => {
		xAxisData.push(val.closing_date ? `${val.prefix}\n${formatDate(val.closing_date)}` : val.prefix)
		seriesData.push({...val, value: val.cashRaised || val.cn_principal})
	})

	option.xAxis.data = xAxisData
	option.series[0].data =seriesData

	return option
}

export default function Valution(props) {
	const [loading, setLoading] = useState(true)
	const [data, setData] = useState([])

	useEffect(() => {
		const getData = async () => {
			setLoading(true)

			try {
				const { data: resData } = await post('getSummaryCashPaid')

				const { code, data } = resData || {}

				const { ps, cn } = data;

				const fData = (ps || []).concat(cn || []);

				const filterData = fData.filter((item) => {
					return +item?.cn_principal > 0 || +item?.cashRaised > 0;
				});
				if (`${code}` === '0') {
					setData(filterData)
				} else {
					message.error(data)
				}

			} catch (err) {
				message.error(err && err.message)
			}

			setLoading(false)
		}

		getData()
	}, [])

	return <div className="valuation-chart-root">
		<Spin spinning={loading}>
			{
				(data && data.length === 0) ? <img className="empty-icon" src={emptyImg} /> : <Echarts option={processData(data)}  />			
			}
		</Spin>	
	</div>
}