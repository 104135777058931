import React from 'react';
import { FileSearchOutlined, FullscreenOutlined, DeleteOutlined, DownloadOutlined, FormOutlined } from '@ant-design/icons';
import iconView from './images/icon-view.png'
import classnames from 'classnames';
import iconDownload from './images/icon-download.png'
import iconOpen from './images/icon-open.png'
import { formatDate } from 'Utils/format/formatDate';

import tagFolder from './images/tag-folder.png'
import iconDoc from './images/tag-doc.png'
import iconTxt from './images/tag-txt.png'
import iconExcel from './images/tag-excel.png'
import iconPdf from './images/tag-pdf.png'
import iconPic from './images/tag-pic.png'
import iconZip from './images/tag-zip.png'
import { Space } from 'antd';

const tagMap = {
    pic: {
        tag: iconPic
    },
    doc: {
        tag: iconDoc
    },
    txt: {
        tag: iconTxt
    },
    excel: {
        tag: iconExcel
    },
    pdf: {
        tag: iconPdf
    },
    zip: {
        tag: iconZip
    },
    folder: {
        tag: tagFolder
    },
}

const getFileType = (fileName) => {
    let suffix = ''; // 后缀获取
    let result = ''; // 获取类型结果
    if (fileName) {
      const flieArr = fileName.split('.'); // 根据.分割数组
      suffix = flieArr[flieArr.length - 1]; // 取最后一个
    }
    if (!suffix) return false; // fileName无后缀返回false
    suffix = suffix.toLocaleLowerCase(); // 将后缀所有字母改为小写方便操作
    // 匹配图片
    const imgList = ['png', 'jpg', 'jpeg', 'bmp', 'gif']; // 图片格式
    result = imgList.find(item => item === suffix);
    if (result) return 'pic';
    // 匹配txt
    const txtList = ['txt'];
    result = txtList.find(item => item === suffix);
    if (result) return 'txt';
    // 匹配excel
    const excelList = ['xls', 'xlsx'];
    result = excelList.find(item => item === suffix);
    if (result) return 'excel';
    // 匹配word
    const wordList = ['doc', 'docx'];
    result = wordList.find(item => item === suffix);
    if (result) return 'doc';
    // 匹配pdf
    const pdfList = ['pdf'];
    result = pdfList.find(item => item === suffix);
    if (result) return 'pdf';
    // 匹配ppt
    const pptList = ['ppt', 'pptx'];
    result = pptList.find(item => item === suffix);
    if (result) return 'ppt';
    // 匹配zip
    const zipList = ['rar', 'zip', '7z'];
    result = zipList.find(item => item === suffix);
    if (result) return 'zip';
    // 匹配视频
    const videoList = ['mp4', 'm2v', 'mkv', 'rmvb', 'wmv', 'avi', 'flv', 'mov', 'm4v'];
    result = videoList.find(item => item === suffix);
    if (result) return 'video';
    // 匹配音频
    const radioList = ['mp3', 'wav', 'wmv'];
    result = radioList.find(item => item === suffix);
    if (result) return 'radio';
    // 其他文件类型
    return 'other';
}



const formatColumns = (selectedKeys) => {
    const shouldDisabled = !_.isEmpty(selectedKeys)
    return [
        {
          title: __('Name'),
          dataIndex: 'name',
          render: (value, record) => {
              const name = record.name
            if (record.file_type === 'dir') {
                return <Space><img data-action="open" style={{ width: '18px', cursor: 'pointer' }} src={tagFolder} /><span style={{ cursor: 'pointer', color: '#004286'  }} data-action="open">{value}</span></Space>
            } else {
                const type = getFileType(record.client_name)
                return <Space><img style={{ width: '18px', cursor: 'pointer' }} src={tagMap[type]?.tag ?? iconTxt} /><span style={{ color: '#004286' }}>{record?.client_name}</span></Space>
            }
          }
        },
        {
          title: __('Size'),
          dataIndex: 'file_size',
          render: (value, record) => {
            if (record.file_type === 'dir') {
              return <span style={{ color: '#004286' }}>{`${record?.fileList?.length ?? 0} Doc(s)` ?? '--'}</span>
            }
            return <span style={{ color: '#004286' }}>{`${value} KB` ?? '--'}</span>
          }
        },
        {
          title: __('Last modified'),
          dataIndex: 'upload_date',
          render: (value) => <span style={{ color: '#004286' }}>{formatDate(value) ?? '--'}</span>
        },
        {
          title: '',
          dataIndex: 'ss',
          render: (value, record) => {
            if (record.file_type === 'dir') {
                return <Space data-action="open" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}><img data-action="open" style={{ width: '18px', cursor: 'pointer' }} src={iconOpen} /><span data-action="open" style={{ color: '#004286' }}>{__('Open')}</span></Space>
            }
            return <Space style={{ cursor: 'pointer' }}><span data-action="view" className={classnames({ 'disabled-documents-operation': shouldDisabled })} style={{ color: '#004286', display: 'flex', alignItems: 'center' }}><img data-action="view" className={classnames({ 'disabled-documents-operation': shouldDisabled })} style={{ width: '18px', cursor: 'pointer', marginRight: '5px'}} src={iconView} />{__('View')}</span><span data-action="download" className={classnames({ 'disabled-documents-operation': shouldDisabled })} style={{ marginLeft: '18px', color: '#004286', display: 'flex', alignItems: 'center' }}><img data-action="download" className={classnames({ 'disabled-documents-operation': shouldDisabled })} style={{ width: '18px', cursor: 'pointer', marginRight: '5px' }} src={iconDownload} />{__('Download')}</span></Space>
          }
        },
      ];
  }

  export { 
    formatColumns,
  }
  export default formatColumns