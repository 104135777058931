import React, { Component, Fragment } from "react";
import security from 'stores/security';
import noPermissionImg from "./Img/no-permission.png";
import {Button} from "antd";
import Modal, { ModalContent } from 'components/Modal';
import ReportIssue from "../Message/components/ReportIssue";

class NoPermission extends Component {

    constructor(props) {
        super(props);
        this.state = {
            content: '',
        };
    }

    componentDidMount() {
        //如果 planControl == 2（越过验证）或者 (flag != 1 and isArrears（是否欠费）=true）则跳回主页
        security.planControl == 2 && security.flag != 1 && !!security.isArrears && this.props.history.push('/home');
    }

    //no permission弹层
    reportIssueModal = null;
    getReportIssue = () => {
        this.reportIssueModal = Modal.open({
            component:  props => <ModalContent title="Report Issue" close={props.close}>
                <ReportIssue callback={props.close} hideHeader={true} />
            </ModalContent>,
            width: '700px',
            maskClosable: true,
            className: 'sprout-modal'
        })
    }

    /**
     * 卸载
     */
    componentWillUnmount() {
        if(this.reportIssueModal) {
            this.reportIssueModal.close();
        }
    }

    getContent = () => {
        const { company_name, flag } = security.companyInfo;
        const checkResult = !!(flag !== 0 && flag == 1);
        return <Fragment>
            {checkResult && <p>{company_name} is currently not available and requires renewal. <br/>Please contact us at <a href="mailto:support@getsprout.co">support@getsprout.co</a> to reactivate your account.</p>}
            {!checkResult && <p>{company_name} is currently not available and requires renewal.<br/>
                Please contact your company admin if you have any questions or concerns.</p>}
            {!checkResult && <Button type="primary" onClick={this.getReportIssue}>Contact admin</Button>}
        </Fragment>
    }

    render() {
        return (
            <div className="plan-message-root sprout-no-permission-root">
                <img src={noPermissionImg} />
                {this.getContent()}
            </div>
        );
    }
}
export default NoPermission;