import React from 'react';
import Modal from 'components/Modal';
import { message, Button} from 'antd';
import { post } from 'srcPath/http';
import AddNewWallet from '../components/AddNewWallet';
import AddNewRelationship from '../components/AddNewRelationship';


import 'assets/css/addStakeholder.css';

class AddWalletHolder extends React.Component{
    state = {
        data:{
            status:0,
            stakeholder_type:1,
            name:"",
        },
        walletList: [{
            address: ''
        }],
        relationshipDataList: [{
            effective_date: '',
            relationship: 'Advisor'
        }],
        loading: false,
        errorStatus:0,
        requiredKey:["name"],
        relationRequiredKey:["effective_date","relationship"],
    }

    async componentDidMount() {
        const { data } = this.state
        const { type, data: propsData } = this.props
        let walletList = []
        let relationshipDataList
        if (type === 'edit' && propsData.wallet_label_id > 0) {
            try {
                const { data: resData } = await post('getWalletLabelInfoById', {id: propsData.wallet_label_id}) 
                const { code, data } = resData || {}
    
                if (code === 0) {
                    const { address_list = [], relationshipDataList: newRelationshipDataList } = data
                    address_list.map((i) => {
                        return i.address = i.wallet_address
                    })
                    walletList = address_list
                    relationshipDataList = newRelationshipDataList
                } else {
                    message.error(data)
                }
            } catch(e) {
                message.error(e.message)
            }
    
            data.name = propsData.name
            this.setState({
                data,
                walletList,
                relationshipDataList,
            })
        } else {
            // add 有address的时候
            if (propsData.wallet_address) {
                walletList = [
                    {
                        address: propsData.wallet_address
                    }
                ]
                this.setState({
                    walletList,
                })
            }
            if (propsData.relationshipDataList?.length > 0) {
                relationshipDataList = relationshipDataList
                this.setState({
                    relationshipDataList,
                })
            }
        }
    }

    showErrorTip=()=>{
        this.setState({
            errorStatus:1
        })
        this.showAddWalletHolderError();
    }
    walletRelationShipChange = (walletList) => {
        this.setState({ relationshipDataList: walletList })
    }
    walletAddressChange = (walletList) => {
        this.setState({ walletList })
    }
    showAddWalletHolderError=()=>{
        let curData = this.state.data;
        this.state.requiredKey.map((value,key)=>{
            if(curData[value]?.length==0){
                $("#add_"+value).addClass("has-error");
            }else{
                $("#add_"+value).removeClass("has-error");

            }
        })
    }
    addWallet = async () => {
        const { type, data: propsData, close, cb, coinId } = this.props
        const isAdd = type === 'add'
        const { data, walletList, relationshipDataList } = this.state
        const wallet_address_list = []
        const url = isAdd ? 'addWalletLabel' : 'updateWalletLabel';

        walletList.map((i) => {
            wallet_address_list.push({
                wallet_address: i.address,
                token_tag: ''
            })
        })

        const params = {
            name: data.name,
            wallet_address_list,
            relationshipDataList,
        }

        if (!isAdd) {
            params.id = propsData.wallet_label_id
        } else {
            params.coin_id = coinId
        }

        this.setState({ loading: true })

        try {
            const { data: resData } = await post(url, params) 
            const { code, data } = resData || {}

            if (code === 0) {
                message.success('Success!')
                cb && cb()
                close()
            } else {
                message.error(data)
            }

        } catch(e) {
            message.error(e.message)
        }

        this.setState({ loading: false })
    }

    handleClose = () => {
        this.props.close()
    }
    recordData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        });
    }

    render(){
        const { walletList, errorStatus, requiredKey, data, loading, relationshipDataList } = this.state
        let curErrorStatus = 1;
        requiredKey.map((value,key)=>{
            if(data[value]?.length ===0){
                curErrorStatus = 0;
            }
        })
        // wallet信息校验
        walletList?.map((wallet) => {
            if (!wallet?.address) {
                curErrorStatus = 0
            }
        })

        return(
            <div>
                <div className="modal-content box-content add-content width620">
                    <div className="modal-header box-header">
                        <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.handleClose.bind(this)}>×</button>
                        <h4 className="modal-title" id="myModalLabel">Add/Update wallet</h4>
                    </div>
                    <div className="modal-body box-body add-body">
                        <div>
                            <div className="body-one">
                                <p className="basic-header header-border">Basic information</p>
                                <form className="add-form form-horizontal">
                                    <div className="form-group add-items">
                                        <label className="col-xs-4 add-label">Name<br/>
                                            <i><small className="text-muted">- Required</small></i>
                                        </label>
                                        <div className="col-xs-6" id={"add_name"}>
                                            <input className="form-control" name="name" value={this.state.data.name} onChange={this.recordData} type="text" />
                                        </div>
                                    </div>
                                </form>
                                <AddNewRelationship data={relationshipDataList} onChange={this.walletRelationShipChange} errorStatus={errorStatus} />
                                <AddNewWallet data={walletList} onChange={this.walletAddressChange} errorStatus={errorStatus} />
                            </div>
                            {this.state.errorStatus===1 &&
                            <div id="addStakeholderInputError" className="alert alert-warning alert-dismissible pink-box mt20">
                                <p>Required field(s) cannot be empty.</p>
                            </div>
                            }
                        </div>
                        <div className="modal-footer box-footer add-footer">
                            <Button type="primary" onClick={ curErrorStatus === 0 ? this.showErrorTip : this.addWallet} >
                                {
                                    loading? 'Adding' : 'Confirm'
                                }
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default function addWalletHolderModal(data, type, cb, coinId) {
	return Modal.open({
		component: <AddWalletHolder data={data} type={type} cb={cb} coinId={coinId} />,
		maskClosable: true,
        width: 620,
        className: 'add-stakeholder-modal',
	})
}

