import React from "react";
import Utils, { I, removeModal} from "Utils";
import { post } from 'srcPath/http';
import security from 'stores/security';
import moment from "moment";
import {DatePicker} from "antd";
import DateSelector from "../../../../../components/DateSelector";
import { formatDate} from "Utils/format/formatDate";
import { printf } from 'Utils/i18n';

class PurchaseRSA extends React.Component {
    constructor(props){
        super(props);
        this.state={
            dateFormat:"DD/MM/YYYY",
            data:{
                issue_date:"",
            },
            signatureAdminList: {
                certificate1_admin_name: "ning",
                certificate2_admin_name: "Ning Da",
                equity_plan_admin_name: "ning",
                convertibles_admin_name: "Ning Da",
            },
            saveStatus: 0
        }
    }
    getSignatureAdminList=()=>{
        post('getSignatureAdminList').then((response)=> {
            if(response.data.code === 0){
                this.setState({
                    signatureAdminList:response.data.data,
                })
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    componentDidMount() {
        this.getSignatureAdminList();
    }
    setPurchaseDateChange = (key, moment_key, date) => {
        const { dateFormat } = this.state;
        let curData = this.state.data;
        curData[key] = date.format( dateFormat);
        curData[moment_key] = date;
        this.setState({
            data:curData
        });
    }
    resetActive=()=>{
        window.$("#purchaseRsaModal .tab-pane").removeClass("active");
        window.$("#purchase_step1").addClass("active");
    }
    purchaseRsa=()=>{
        if(this.state.saveStatus == 1) return;
        this.setState({ saveStatus: 1})
        let requestData = this.state.data;
        requestData.id = this.props.curSelectSecurity.id;
        post('purchaseRsa',requestData).then((response)=> {
            if(response.data.code === 0){
                // window.location.reload()
                this.setState({ saveStatus : 0})
                window.$("#purchaseRsaModal").modal("hide");
                this.resetActive();
                this.props.refreshData(requestData.id,requestData.issue_date);
                // removeModal();
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    render(){
        const { data, dateFormat} = this.state;
        return(
            <div className="modal fade" id="purchaseRsaModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="modal-dialog dialog-top40 tab-content">
                    <div className="modal-content content520 tab-pane active" id="purchase_step1">
                        <div className="modal-header box-header">
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Purchase restricted stock award')} {this.props.curSelectSecurity.custom_label_id}</h4>
                        </div>
                        <div className="modal-body padding202620">
                            <div className="body-one">
                                <form className="form-horizontal font_reset_form">
                                    <div className="form-group add-items ">
                                        <label className="col-xs-4 add-label">{__('Enter issue date')}</label>
                                        <div className="col-xs-5 ">                                            
                                            <DateSelector
                                                onChange={this.setPurchaseDateChange.bind(this, 'issue_date', 'issue_date_moment')}
                                                value={I(data['issue_date_moment'], I(data['issue_date'], '') ? moment(data['issue_date'], dateFormat) : '')}
                                            />
                                        </div>
                                        <p className="text-muted col-xs-8 col-xs-push-4 mb-0">{printf(__('This is the date when payment is received. It must be on or after the board approval date of %s.'),formatDate(this.props.curSelectSecurity.board_approval_date))}</p>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer box-footer invite-footer">
                            {this.state.data.issue_date == "" && <button type="button" className="btn boxbtn-to send-disabledBtn">{__('Next: review RSA purchase')}</button>}
                            {this.state.data.issue_date!= "" && <button type="button" data-toggle="tab" data-target="#purchase_step2" className="btn boxbtn-to bg-darkblue">{__('Next: review RSA purchase')}</button>}
                        </div>
                    </div>
                    <div className="modal-content 520 tab-pane " id="purchase_step2">
                        <div className="modal-header box-header">
                            <a data-target="#purchase_step1" type="button" className="back-btn" aria-controls="repurchase-step1" role="tab" data-toggle="tab"></a>
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.resetActive}>×</button>
                            <h4 className="modal-title" id="myModalLabel">{printf(__('Purchase restricted stock award %s'), this.props.curSelectSecurity.custom_label_id )}</h4>
                        </div>
                        <div className="modal-body padding2026">
                            <div className="flexDiv">
                                <div>
                                    <span className="send-ok"></span>
                                </div>
                                <div>
                                    <p className="transfer-title  mb-0">{__('Certificates')}</p>
                                    <p className="text-muted mb-0">{printf(__('%s will be issued as outstanding common stock as of %s.'), this.props.curSelectSecurity.custom_label_id, formatDate(data.issue_date ))}</p>
                                </div>
                            </div>
                            <hr />
                            <div className="flexDiv">
                                <div>
                                    <span className="send-ok"></span>
                                </div>
                                <div>
                                    <p className="transfer-title mb-0">{__('Signatories')}</p>
                                    <p className="text-muted mb-0">{printf(__('%s and %, will receive email notification(s) to sign the certificate.'), this.state.signatureAdminList.certificate1_admin_name, this.state.signatureAdminList.certificate2_admin_name )}</p>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer box-footer invite-footer">
                            <button type="button" className="btn boxbtn-to bg-darkblue update-btn" onClick={this.purchaseRsa}>{__('Purchase RSA')}</button>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
export default PurchaseRSA;