import React from 'react';
import security from 'stores/security';
import { Select } from 'antd';
import moment from 'moment';
import { language } from 'Utils/i18n';

const Option = Select.Option

export default function DateFormatSelector(props) {
	const { labelValue } = props
	const date = labelValue && moment(labelValue).isValid() ? labelValue : moment()

	const OptionData = [
		{
			label: date.format('DD/MM/YYYY'),
			value: 'DD/MM/YYYY',
			labelValue: 'DD/MM/YYYY'
		},
		{
			label: date.format('MM/DD/YYYY'),
			value: 'MM/DD/YYYY',
			labelValue: 'MM/DD/YYYY'
		},
		{
			label: ['zh_CN', 'zh_TW'].includes(language) ? date.format('YYYY/MM/DD') : date.format('MMM DD, YYYY'),
			value: 'MMM DD, YYYY',
			labelValue: ['zh_CN', 'zh_TW'].includes(language) ? 'YYYY/MM/DD' : 'MMM DD, YYYY'
		},
		{
			label: ['zh_CN', 'zh_TW'].includes(language) ? date.format('YYYY年MM月DD日') : date.format('DD MMM, YYYY'),
			value: 'DD MMM, YYYY',
			labelValue: ['zh_CN', 'zh_TW'].includes(language) ? 'YYYY年MM月DD日' : 'DD MMM, YYYY'
		}
	]

	return <Select placeholder="" {...props} >
		{
			OptionData.map(val => (<Option value={val.value} key={val.value}>{val.label}{` (${val.labelValue})`}</Option>))
		}
	</Select>
}
