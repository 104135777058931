import React, { Component, Fragment } from "react";
import {Button, Col, DatePicker, Form, Input, message, Row} from "antd";
import DateSelector from 'components/DateSelector';
import { post } from 'srcPath/http';
import moment from 'moment';
import security from 'stores/security';
import { formatDate, formatDate2Api, formatDateForDatePicker } from 'Utils/format/formatDate';
import { filterAmount, setCursorPos, I } from "Utils";
import "../style.scss";

class SaveFinancingHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            formData: [
                { label: __('Round name'), name:'sub_round_name', key: 'name', value: '', type: 'string' },
                { label: __('Closing date'), name:'closing_date', key: 'closingDate', value: moment(), type: 'date', help: __('This is the last issue date of the funding round.') },
                { label: __('Cash raised'), name:'cash_raised', key: 'cashRaised', value: '', type: 'decimal' },
                { label: __('Shares issued'), name:'shares_issued', key: 'sharesIssued', value: '', type: 'int' },
                { label: __('Original issue price'), name:'issue_price', key: 'originalIssuePrice', value: '', type: 'decimal' },
                { label: __('Fully diluted share'), name:'fully_diluted', key: 'fullyDilutedShares', value: '', type: 'int' },
            ],
        };
    }

    componentDidMount() {
        this.initData();
    }

    /**
     * 初始化
     */
    initData = () => {
        const { formData } = this.state;
        const { data, type } = this.props;
        if(type === 'edit'){
            formData.forEach((v, k)=>{
                formData[k].value = v.key === 'closingDate' ? data[v.key] && moment(data[v.key], 'DD/MM/YYYY') : I(data[v.key], '');
            })
        }
        this.setState({formData: [...formData]})
    }

    getListIndex = (list, key, name) => list.findIndex( v => v[key] == name );

    /**
     * 表单input change
     * @param name
     * @param type
     * @param e
     */
    inputChange = (name, type, e) => {
        const { formData } = this.state;
        const { target } = e;
        const { value:inputValue } = target;
        const { value, cursorPositionX } = filterAmount(e);
        let newFormData = [...formData];
        const index = this.getListIndex(newFormData, 'name', name);
        newFormData[index].value = type === 'string' ? inputValue : value;
        this.setState({ formData: newFormData }, () => {
            type !== 'string' && setCursorPos( target, cursorPositionX);
        });
    }

    /**
     * 日期change
     * @param name
     * @param type
     * @param value
     */
    dateTimeChange = (name, type, value) => {
        let { formData } = this.state;
        let newFormData = [...formData];
        const index = this.getListIndex(newFormData, 'name', name);
        newFormData[index].value = value;
        this.setState({ formData: newFormData });
    }

    /**
     * 平铺循环时区分日期插件
     * @param label
     * @param name
     * @param value
     * @param type
     * @returns {JSX.Element}
     */
    getFormValue = (label, name, value, type) => {
        const { currency } = security.companyInfo;
        if(type == 'date'){
            return <DateSelector
                inputReadOnly={true}
                value={value}
                onChange={this.dateTimeChange.bind(this, name, type)}
            />
        }else{
            return <Input
                value={value}
                onChange={this.inputChange.bind(this, name, type)}
                className="financing-history-input"
                addonBefore={type === 'decimal' && currency}
            />
        }
    }

    /**
     * 平铺表单内容
     * @returns {*}
     */
    formHtml = () => {
        const { formData } = this.state;
        return formData.map((v)=> {
            const {label, name, value, type} = v;
            return (
                <Row className="financing-history-row">
                    <Col span={8}>{label}</Col>
                    <Col span={16}>{this.getFormValue( label, name, value, type)}</Col>
                </Row>
            )
        });
    }

    /**
     * 组合sub-round数据
     * @returns {{share_class_id}}
     */
    getSaveData = () => {
        const { formData } = this.state;
        const { shareClassId, type, data } = this.props;
        let returnData = { share_class_id: shareClassId };
        formData.forEach((v)=>{
            returnData[v.name] = v.type === 'date' ? formatDate2Api(v.value) : v.value;
        })
        type === 'edit' && (returnData.id = data.id);
        return returnData;
    }

    saveHistory = () => {
        const { saveHistory, close } = this.props;
        saveHistory(this.getSaveData(), close);
    }

    render() {
        const { loading } = this.state;
        return (
            <div className="financing-history-form">
                {this.formHtml()}
                <Button loading={loading} type="primary" onClick={this.saveHistory}>{__('Confirm')}</Button>
            </div>
        )
    }
}
export default SaveFinancingHistory;