import React, { Component } from 'react';
import { FormItem, withForm } from 'react-antd-formutil';
import { Button, Select, message, Input } from 'antd';
import { typeOptions, documentsTypeMap } from '../../config'
import { post } from 'srcPath/http';
import UploadItem from 'B/components/UploadItem';
import './style.scss';

const Option = Select.Option

@withForm
export default class EditDocumentSetModal extends Component {
	state = {
		loading: false,
		initData: this.props.defaultValue
	}

	componentDidMount() {
		const { defaultValue, $formutil } = this.props
		const { type, title, documents = {} } = defaultValue || {}

		if (defaultValue) {
			const { $setValues } = $formutil
			let { additionalDocuments, ...others } = documents

			if (additionalDocuments && additionalDocuments.length > 0) {
				additionalDocuments = additionalDocuments.map((val) => ({ ...val, name: val.default_name, }))
			}

			const files = {}

			Object.entries(others).forEach(([key, value]) => {
				if (key) {
					files[key] = { name: value.default_name }
				}
			})

			$setValues({
				type,
				title,
				additionalDocuments,
				...files
			})
		}
	}

	makeData = data => {
		const { type, title, additionalDocuments, ...others } = data
		const { initData } = this.state

		const res = { type, title, documents: {} }

		const otherKeys = Object.keys(others)

		if (additionalDocuments && additionalDocuments.length > 0) {
			const available = additionalDocuments.filter((val) => val.file)

			if (available.length > 0) {
				res.documents.additionalDocuments = available
			}
		}

		const originAdditionalDocuments = initData && initData.documents && initData.documents.additionalDocuments || []

		const orignOtherKeys = Object.keys(initData && initData.documents || {})

		let deleteIds = []
		let deleteFiles = []

		if (originAdditionalDocuments.length > 0) {
			originAdditionalDocuments.forEach(val => {
				if (!additionalDocuments.some(item => item.id === val.id)) {
					deleteIds.push(val.id)
				}
			})
		}

		if (orignOtherKeys.length > 0) {
			orignOtherKeys.forEach(val => {
				if (!others[val] && val !== 'additionalDocuments') {
					deleteFiles.push(val)
				}
			})
		}

		otherKeys.forEach(value => {
			if (others[value] && others[value].file) {
				res.documents[value] = others[value]
			}
		})


		if (deleteIds.length > 0) {
			res.documents.deleteIds = deleteIds
		}

		if (deleteFiles.length > 0) {
			res.documents.deleteFiles = deleteFiles
		}

		return res
	}


	onSubmit = async () => {
		const { $formutil, close, data, callback, defaultValue } = this.props
		const { $invalid, $batchDirty, $params, $setErrors } = $formutil.$new()

		if ($invalid) {
			$batchDirty(true)
			// message.error($getFirstError())

			return
		}

		this.setState({ loading: true })

		const api = defaultValue ? 'editDocumentSet' : 'addDocumentSet'

		try {
			const { data: resData } = await post(api, {
				id: defaultValue && defaultValue.id,
				...this.makeData($params)
			})
			const { code, data } = resData

			if (code == 200 || code == 0) {
				message.success(__('Success!'))

				close && close()

				callback && callback()
			} else {
				if (code === 30003) {
					$setErrors({
						title: {
							required: __('Duplicated document set title!')
						}
					})
				} else { 
					message.error(data)
				}
			}
		} catch (e) {
			message.error(e.message)
		}

		this.setState({ loading: false })
	}

	handleTypeChange = (val, preValue) => {
		if (val !== preValue) {
			const { $setValues, $params, $reset } = this.props.$formutil
			const { type } = $params || {}

			$setValues({ type: val, file1: undefined, file2: undefined, file3: undefined })
		}
	}

	renderFile = () => {
		const { $formutil, defaultValue } = this.props
		const { $params } = $formutil
		const type = defaultValue ? defaultValue.type : ($params.type) || '1'
		const files = documentsTypeMap[type].file

		return Object.entries(files).map((val, index) => <FormItem key={index} name={val[0]} label={val[1]}><UploadItem multi={val[0] === 'additionalDocuments'} /></FormItem>)
	}

	// checkTitleAsync = value => {
	// 	if (value) {
	// 		const { defaultValue, $formutil } = this.props
	// 		const { type, title } = defaultValue || {}

	// 		if (defaultValue && value === title) { // 编辑的时候不修改type ，所以如果名字没有修改，校验自动通过
	// 			return true
	// 		}

	// 		return new Promise(async (resolve, reject) => {
	// 			const { data: resData } = await post('checkDocumentSetName', {
	// 				type:  type || $formutil?.$params?.type,
	// 				title: value
	// 			})

	// 			const { data = {} } = resData || {}

	// 			if (data.isAvailable === 1) {
	// 				return resolve(true)
	// 			} else { 
	// 				return reject('Duplicated document set title!')
	// 			}
	// 		})
	// 	}

	// 	return true
	// }

	render() {
		const { loading } = this.state
		const { defaultValue = {}, $formutil } = this.props

		return <div className="edit-document-set-root">
			<div className="edit-document-set-content">
				<div className="edit-document-set-title">{__('Create a document set to attach equity plan documents to securities.')}</div>
				<FormItem
					name="type"
					label={__("Security type")}
					$defaultValue={defaultValue.type || '1'}
					$onFieldChange={this.handleTypeChange}
					required
				>
					<Select disabled={!!this.props.defaultValue}>
						{
							typeOptions.map((val, index) => (<Option key={index} value={val.value}>{val.label}</Option>))
						}
					</Select>

				</FormItem>
				<FormItem 
					name="title" 
					label={__("Document set title")}
					required
					validMessage={{
						required: __('Document set title is required'),
					}}
				>
					<Input />
				</FormItem>
				{this.renderFile()}
				<div className="btn-wrap">
					<Button type="primary" loading={loading} onClick={this.onSubmit} >{__('Save')}</Button>
				</div>
			</div>
		</div>
	}
}
