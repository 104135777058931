import React, { Component } from 'react';
import { FormItem, withForm } from 'react-antd-formutil';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Input, Button, message } from 'antd';
import { ExclamationCircleTwoTone, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import moment from 'moment'
import  { bindKeyEvent } from 'Utils';
import Modal from 'components/Modal';
import security from 'stores/security';
import axios from 'axios';
import url from "url";
import './style.scss';

const email = new RegExp('(?![\\-_.])[\\w\\-.]+@(?:(?![\\-_])[\\w\\-\u4e00-\u9fa5]{1,63}\\.)+[A-Za-z0-9]{2,}')


class Login extends Component {
	queryObj = url.parse(this.props.location.search, true);
	modalRef = null
	mode = this.queryObj.query.mode === 'confirm' && 1
	defaultEmail = this.props.location.state ? this.props.location.state.email : this.queryObj.query && this.queryObj.query.email

	componentDidMount() {
		this.removeKeyEvent = bindKeyEvent(this.onSubmit)
	}

	componentWillUnmount() {
		this.modalRef && this.modalRef.close()

		this.removeKeyEvent && this.removeKeyEvent()
	}

	state = {
		loading: false
	}

	onSubmit = async () => {
		const { mode } = this
		const { $formutil } = this.props
		const { $invalid, $batchDirty, $params, $getFirstError } = $formutil

		if ($invalid) {
			$batchDirty(true)
			message.error($getFirstError())

			return
		}
		this.setState({ loading: true })

		try {
			const { data } = await axios.post('', Object.assign({ action: 'signin', mode }, $params), { useForm: true })

			if (data.code === 0) {
				security.setSproutToken = data.data

				localStorage.setItem('SPROUT_EXPIRE', moment().add(5, 'h').valueOf())
				security.sessionExpired = false

				await security.init()
				await security.sendEvent({ event_name: 'loginToCompany' })
			} else {
				if (data.code === 1) {
					message.error(__('Email or password is error!'))
				}

				if (data.code === 4) {
					this.modalRef = Modal.open({
						component: props => <div>
							<p>
								<ExclamationCircleTwoTone />&nbsp;{__('Your account')}<strong><i>{this.props.$formutil.$params.email || '--'} </i></strong>{__('requires activation. Please check your inbox for the activation email.')}
							</p>
							<div style={{ textAlign: 'center', marginTop: 30 }}><Button style={{ width: 80 }} type="primary" onClick={props.dismiss}>OK</Button></div>
						</div>,
						width: 600
					})
				}

				if (data.code !== 1 && data.code !== 4) {
					message.error(data.data)
				}
			}

		} catch (e) {
			message.error(e.message)
		}

		this.setState({ loading: false })
	}

	render() {
		const { loading } = this.state
		
		if (security.hasLogin) {
			return <Redirect to="/" />
		}
		return <div className="sprout-login-root">
			<div className="sprout-auth-title">{__('Sign in')}</div>
			<div className="sprout-auth-sub-title">{__('Welcome to Folium')}</div>
			<div className="sprout-login-form">
				<FormItem
					name="email"
					required
					pattern={email}
					$defaultValue={this.defaultEmail}
					validMessage={{
						required: __('Email is required'),
						pattern: __('Email address is invalid')
					}}
				>
					<Input className="sprout-auth-input" size="large" placeholder="Email" />
				</FormItem>
				<FormItem
					name="password"
					required
					validMessage={{
						required: __('Password is required'),
					}}
				>
					<Input.Password className="sprout-auth-input" size="large" placeholder="Password" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
				</FormItem>
				<Button loading={loading} className="sprout-auth-bth" type="primary" onClick={this.onSubmit}>{__('Sign in')}</Button>
				<div className="sprout-auth-link"><Link to="/auth/forgot">{__('Forgot password?')}</Link></div>
				<div>{__('Already have an account,')}<Link to="/auth/signup-another">{__('create another company?')}</Link></div>
			</div>
		</div>
	}
}

export default withForm(Login)