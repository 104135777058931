import React from 'react';
import Modal, { ModalContent } from 'components/Modal';
import { Button } from 'antd';
import moment from 'moment';
import security from 'stores/security'
import './style.scss';

const taskTipLabel = 'SPROUT-TASK-TIP'

export default function openTaskTips() {
	const userId = (security.userInfo || {})['id']

	if (!userId) {
		return // TODO
	}

	const localTimeData = JSON.parse(localStorage.getItem(taskTipLabel) || '{}')

	const localTime = localTimeData[userId]

	if (!localTime || (moment().valueOf() > localTime && !security.sessionExpired) && security.hasLogin) {
		localStorage.setItem(taskTipLabel,  JSON.stringify({
			...localTimeData,
			[userId]: moment().add(1, 'days').valueOf()
		}))

		Modal.open({
			component: ({close}) => <ModalContent title={__('TIPS')} showClose={false}>
				<div className="sprout-task-tip">
					<div className="sprout-task-tip-content">
						<p>{__('You have outstanding certificate(s) requiring signature(s), please go to the task page to accept!')}</p>
					</div>
					<div className="sprout-task-tip-btns">
						<Button type="primary" onClick={() => close()}>{__('I understand')}</Button>
					</div>
				</div>
			</ModalContent>,
			width: 520,
			className: 'sprout-modal'
		})
	}
}