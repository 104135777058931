import React, { useState, useEffect } from 'react';
import Modal, { ModalContent } from 'components/Modal';
import { Button, Spin, message, Input } from 'antd';
import { post } from 'srcPath/http';

function UpdateNotes(props) {
	const { data = {}, close, callback } = props;
	const { id, notes } = data
	const [ loading, setLoading ] = useState(false)
	const [note, setNote] = useState(notes)

	const handleChange = e => {
		setNote(e.target.value)
	}

	const update = async () => {
		setLoading(true)

		try {
			await post('updateCoinTransactionNote', {
				id,
				notes: note 
			})


			callback && callback()
			close()
		} catch(err) {
			message.error(err.message)
		}
	}

	return <div className="coin-transaction-updatetags">
		<Spin spinning={loading}>
			<div className="coin-transaction-updatetags-content">
				<div className="coin-transaction-updatetags-label">Note text</div>
				<Input.TextArea onChange={handleChange} value={note} />
			</div>
			<div className="coin-transaction-updatetags-btn">
				<Button onClick={() => close()}>Cancel</Button>
				<Button type="primary" onClick={() => update()}>Confirm</Button>
			</div>
		</Spin>
	</div>
}

export function openUpdateNoteModal(data, callback) {
	return Modal.open({
		component: ({ close }) => <ModalContent showClose={false} title="Update Notes">
			<UpdateNotes data={data} close={close} callback={callback} />
		</ModalContent>,
		className: 'sprout-modal',
		width: 600
	})
}