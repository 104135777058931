import React, { useEffect, useState } from "react";
import { post } from 'srcPath/http';
import { message } from "antd";
import Loading from 'components/Loading';
import SideBar from '../components/SideBar';
import Detail from './Detail';
import Transaction from "./Transaction";
import VestingSchedule from '../components/VestingSchedule/vestingSchedule';
import PersonalDetail from './PersonalDetail';
import Documents from "./Documents";


const menuConfig = [
	{
		label: __('Details'),
		key: 'details'
	},
	{
		label: __('Transaction history'),
		key: 'transactionHistory'
	},
	{
		label: __('Vesting schedule'),
		key: 'vestingSchedule'		
	},
	{
		label: __('Personal detail'),
		key: 'personalDetails'
    },
    {
        label: __('Documents'),
        key: 'documents'
    }
];

export default function GrantDetail({ id }) {
    const [data, setData] = useState({})
    const [checkedKey, setCheckedKey] = useState('details')
    const getData = async () => { 

        Loading.global(true) 
        try {
            const { data: resData } = await post('getCoinGrantDetail', { id })

            const { code, data } = resData || {};

            if (code === 0) {
                setData(data)
            } else { 
                message.error(data)
            }
        } catch (e) { 
            message.error(e.message)
        }

        Loading.global(false)
    }

    useEffect(() => { 
        getData()
    }, [])

    const handleSideBar = value => { 
        setCheckedKey(value)
    }

    const renderContent = () => { 
        const { details = {}, transactionHistory = [], vestingSchedule = {}, personal_details = {}, documents_and_notes = {} } = data || {}

        const contentMap = {
            details: <Detail data={{ ...details, wallet_address_list: personal_details?.personal_details || [] }} />,
            transactionHistory: <Transaction data={transactionHistory} />,
            vestingSchedule: <VestingSchedule isToken data={{ name: data.grant_name, ...vestingSchedule }} />,
            personalDetails: <PersonalDetail data={personal_details} />,
            documents: <Documents data={documents_and_notes} />
        }

        return contentMap[checkedKey] || ""
     }

    return <div className="certificate-modal-root">
        <div className="certificate-detail-root">
            <SideBar onChange={handleSideBar} value={checkedKey} config={menuConfig} type={__('Token grant')} name={data.grant_name}/>
            <div className="certificate-detail-content">
                { renderContent() }
            </div>
        </div>
    </div>

}