import React, { Component } from "react";
import {Button, Space} from "antd";
import "./style.scss";
import ConfirmEmailModels from "components/ConfirmEmailModels";

class ConfirmNotifyModels extends Component {


    saveStakeholder = () => {
        const { addStakeholder, close, id, stakeholderId } = this.props;
        addStakeholder && addStakeholder(id, close, stakeholderId);
    }

    render() {
        const { isWallet, type, close } = this.props;
        return (
            <div className="confirm-notify-root">
                <div className="confirm-notify-left">
                    <ConfirmEmailModels isWallet={isWallet} type={type} />
                </div>
                <div className="confirm-notify-right">
                    <Space size={40} direction="vertical">
                        <h3>Congratulations!</h3>
                        <h3>You have successfully added stakeholders. On the left side, you will find the email template that the stakeholders will receive.</h3>
                        <h3>To initiate the sending of invitation emails, please select 'Yes' below.</h3>
                    </Space>
                    <Space size={110} className="confirm-notify-button">
                        <Button type="primary" onClick={()=>close()}>No, not this time</Button>
                        <Button type="primary" onClick={this.saveStakeholder}>Yes</Button>
                    </Space>
                    <p>If you do not wish to send email notifications to your stakeholders in the future, you can go to 'Company > Settings > General' and disable the 'Stakeholder access control' feature.</p>
                </div>
            </div>
        );
    }
}
export default ConfirmNotifyModels;