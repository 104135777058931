import React from 'react';
import Utils, { I, formatNumber, formatCurrencyNumber } from "Utils";
import DeleteDraftSecurity from "../components/DeleteDraftSecurity";
import CreateDraftSet from "../components/CreateDraftSet";
import RenameDraftSet from "../components/RenameDraftSet";
import DeleteDraftSet from "../components/DeleteDraftSet";
import { post } from 'srcPath/http';
import Loading from 'components/Loading';
import AddToLedger from "./EquityAwardsModal/AddToLedger";
import Permission from 'components/Permission';
import security from 'stores/security';
import SelectStakeholder from 'components/SelectStakeholder'
import DraftRsaTable from "./components/DraftRsaTableModels";
import moment from "moment";
import { DatePicker, Checkbox,Button } from "antd";
import DateSelector from "../../../../components/DateSelector";
import { relationshipList, countries } from "Utils/constant";
import CountrySelector from 'components/CountrySelector';
import EnterRsasInfomation from "../components/EnterRsasInfomation";
import Modal, { ModalContent } from 'components/Modal';
import {CloseOutlined, DeleteOutlined, ExclamationCircleOutlined, LeftOutlined} from '@ant-design/icons';
import { sprintf } from 'Utils/i18n';
import SendInfoForSignature from "../components/SendInfoForSignature";
class DraftRsas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedIds:[],
            dateFormat: "DD/MM/YYYY",
            loadingStatus: 1,
            successDetails: {},
            curPlanId: 0,
            sendResult: {
                "1": {
                    "id": 1,
                    "is_success": 1,//1-成功，0表示失败
                    "error_reason": ""
                },
                "4": {
                    "id": 4,
                    "is_success": 0,//1-成功，0表示失败
                    "error_reason": [
                        { "title": "Name", "message": "Can't be empty!" },
                        { "title": "age", "message": "Can't be bigger than 150!" },
                        { "title": "Certificate Id", "message": "Duplicated" }
                    ]
                },
            },
            saveStatus: -1,
            listKey: -1,
            type: "RSA",
            addingStatus: 0,
            curSelectRelation: "all",
            draft_name: "",
            draft_id: "",
            currency: "",
            eaRsaDraftSetList: [
                { id: 11, name: "24/08/2020", count: "2" },
            ],
            documentSet: [
                { id: 1, set_name: "setRsa1", purchase_agreement_file: { name: "11", file_obj: "" }, incentive_plan_file: { name: "11", file_obj: "" }, additional_documents: { name: "11", file_obj: "" } },
            ],
            selectedDocKey: -1,
            curSelectShareClassDraftSet: { id: 1, name: "24/08/2020", count: "2" },
            securityLists: [
                { security_name: 'CS-5', stakeholder: 'Cody Belseager', security_status: 'Outstanding', shares: '100', price: '1.00', transaction_value: '100.00', issue_date: '12/12/2019', status: 'unchecked', sign_status: 'officerSignature' },
                { security_name: 'CS-5', stakeholder: 'Cody Belseager', security_status: 'Outstanding', shares: '100', price: '1.00', transaction_value: '100.00', issue_date: '12/12/2019', status: 'unchecked', sign_status: 'holderSignature' },

            ],
            draftRsaRequiredKey: [
                "board_approval_date",
                "email",
                "issue_date",
                "relationship",
                "name",
                "quantity",
            ],
            data: [],
            checkedId: [],
            checkedDraftSecurity: [],
            stakeholderRelationshipList: [
                "Advisor",
                "Board member",
                "Consultant",
                "Employee",
                "Ex-employee",
                "Executive",
                "Founder",
                "International employee",
                "Investor",
                "Officer",
            ],
            statusList: [
                { value: "Not purchased", text: "Not purchased" },
                { value: "Outstanding", text: "Purchased" }
            ],
            shareClassList: [
                { id: "66", share_class_name: "cccccc", prefix: "22" },
                { id: "67", share_class_name: "rrr", prefix: "23" },
                { id: "68", share_class_name: "ggg", prefix: "24" },
            ],
            formatShareClassList: {
                66: { id: "66", share_class_name: "cccccc", prefix: "22" },
            },
            currencyList: ['USD', 'HKD', 'SGD',],
            scheduleList: [],
            pageData: {
                name: "Certificate",
                title: "Certificate",
                text: "certificate",
                error_status: 0,
                checked_count: "",
                type: 0
            },
            genderList: [
                {
                    name: 'Female'
                },
                {
                    name: 'Male'
                },
                {
                    name: 'Non-binary / non-conforming'
                },
                {
                    name:'Prefer not to respond'
                },
            ],
            award_category: [],
            stakeholderType: [
                {
                    name: __('Individual'),
                    value: 1
                },
                {
                    name: __('Non-individual'),
                    value: 0
                },
            ],
        }
    }
    transferDraftSetData = (key, refName, e) => {
        this.refs[refName].initData(key, this.state.eaRsaDraftSetList[key]);
    }
    getCheckedId = () => {
        this.refs.deleteDraftSecurity.getCheckedId();
    }
    getDeleteDraftId = () => {
        this.refs.deleteDraftSecurity.getDeleteDraftId();
    }
    changeStatus = (key) => {
        var rsaList = this.state.data;
        if (rsaList[key].checkbox_status == false) {
            rsaList[key].checkbox_status = true;
            if (rsaList[key].is_show_error == 1) {
                $(".draft_error_rsa_tip" + key).removeClass("showErrorTip")
            }
        } else {
            rsaList[key].checkbox_status = false;
            if (rsaList[key].is_show_error == 1) {
                $(".draft_error_rsa_tip" + key).addClass("showErrorTip")
            }
            $('#cs_check01').prop("checked", false);
        }
        this.setState({
            data: rsaList,
        })
    }
    fileDelete = (key, additionalKey) => {
        let listKey = this.state.listKey;
        this.refs[key].value = "";
        let curData = this.state.data;
        if (additionalKey != -1) {
            if (curData[listKey][key].length == 1) {
                curData[listKey][key][0].name = "";
                curData[listKey][key][0].file_obj = "";
            } else {
                curData[listKey][key].splice(additionalKey, 1)
            }
        } else {
            curData[listKey][key].name = "";
            curData[listKey][key].file_obj = "";
        }
        this.setState({
            data: curData
        })
        this.editEaRsaDraft();
    }
    quanXuan = () => {
        var rsaList = this.state.data;
        if ($('#cs_check01').prop("checked")) {
            $('.listCheckbox').prop('checked', true);
            for (let i = 0; i < rsaList.length; i++) {
                rsaList[i].checkbox_status = true;
            }
        } else {
            $('.listCheckbox').prop('checked', false);
            for (let i = 0; i < rsaList.length; i++) {
                rsaList[i].checkbox_status = false;
            }
        }
        this.setState({
            data: rsaList
        })
    }
    componentDidMount() {
        this.getEaRsaDraftSetList();
        // this.getDraftScheduleList();
        // this.getCurrency();
    }
    getEaRsaDraftSetList = () => {
        post('getDraftSetList', { type: "RSA" }).then((response) => {
            if (response.data.code === 0) {
                for (let i = 0; i < response.data.data.draftList.length; i++) {
                    response.data.data.draftList[i].checkbox_status = false;
                    response.data.data.draftList[i].is_show_error = 0;

                }
                var formatObj = {};
                for (let j = 0; j < response.data.data.eaPlanList.length; j++) {
                    formatObj[response.data.data.eaPlanList[j].id] = response.data.data.eaPlanList[j];
                }
                this.setState({
                    eaRsaDraftSetList: response.data.data.setList,
                    data: response.data.data.draftList,
                    draft_id: response.data.data.defaultSetInfo.id,
                    draft_name: response.data.data.defaultSetInfo.name,
                    shareClassList: response.data.data.eaPlanList,
                    formatShareClassList: formatObj,
                    cnSecuritiesList: response.data.data.cnSecuritiesList,
                    scheduleList: response.data.data.vestingScheduleList,
                    currency: response.data.data.currency,
                    loadingStatus: 0,
                    documentSet: response.data.data.documentSet,
                    award_category: response.data.data.award_category,
                });
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    recordData = (e) => {
        let listKey = this.state.listKey;
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;

        // if(dataKey=="vesting_schedule_id"){
        //     if(curData[listKey].vesting_schedule_id!=""){
        //         setTimeout(()=>{
        //             this.bindDatepicker((curData.length-1));
        //         },300)
        //     }
        // }else{
        //     curData[listKey].vesting_start_date=""
        // }
        if (dataKey == "documentset_id") {
            if (dataVal == -1) {
                curData[listKey].documentset_id = -1
            } else if (dataVal == -2) {
                this.props.history.push({pathname:'/home/securities/templates',state:{activeKey:'1'}})
            } else if (dataVal == -3) {
                curData[listKey].documentset_id = 0
                curData[listKey].purchase_agreement_file.name = "";
                curData[listKey].incentive_plan_file.name = "";
                curData[listKey].additional_documents = [{ name: "", file_obj: "" }];
            } else {
                let data_val=this.state.documentSet.findIndex(item=>item.id==dataVal)
                curData[listKey].documentset_id = this.state.documentSet[data_val].id;
                curData[listKey].purchase_agreement_file.name = this.state.documentSet[data_val].purchase_agreement_file.name || "";
                curData[listKey].purchase_agreement_file.real_name = this.state.documentSet[data_val].purchase_agreement_file.real_name;
                curData[listKey].incentive_plan_file.name = this.state.documentSet[data_val].incentive_plan_file.name || "";
                curData[listKey].incentive_plan_file.real_name = this.state.documentSet[data_val].incentive_plan_file.real_name;
                curData[listKey].additional_documents = this.state.documentSet[data_val].additional_documents;
            }
            this.setState({
                selectedDocKey: dataVal
            })
        }
        if (dataKey == 'stakeholder_type' && dataVal == 0) {
            curData[listKey].job_title_list = [
                { effective_date: '', job_title: "" },
            ]
            curData[listKey].gender = ''
            curData[listKey].date_of_birth = ''
            curData[listKey].annual_salary = ''
        }
        curData[listKey][dataKey] = dataVal;
        this.setState({
            data: curData
        })
        if (dataKey == "security_plan_id" || dataKey == "relationship" || dataKey == "status") {
            this.editEaRsaDraft();
        }
    }
    recordNumber = (e) => {
        const isFractionalShare = security.companyInfo.fractional_control;
        let listKey = this.state.listKey;
        let curData = this.state.data;
        let curVal = e.target.value;
        let dataKey = e.target.name;
        if (dataKey == "quantity") {
            if (isFractionalShare == 1) {
                curData[listKey][dataKey] = Utils.check5DecimalNumInputWithoutComma(curVal);
            } else {
                curData[listKey][dataKey] = Utils.checkNumInput(curVal);
            }
        } else {
            curData[listKey][dataKey] = Utils.check2DecimalNumInputWithoutComma(curVal);
        }
        this.setState({
            data: curData
        });
    }
    setDataFormat = (e) => {
        let listKey = this.state.listKey;
        let curData = this.state.data;
        let curVal = e.target.value;
        let dataKey = e.target.name;
        curData[listKey][dataKey] = Utils.resetNumberWithCommas(curVal);
        this.setState({
            data: curData
        });
    }
    resetDataFormat = (e) => {
        let isFractionalShare = security.companyInfo.fractional_control;
        let listKey = this.state.listKey;
        let curData = this.state.data;
        let curVal = e.target.value;
        let dataKey = e.target.name;
        if (dataKey == "quantity") {
            if (isFractionalShare == 1) {
                curData[listKey][dataKey] = formatNumber(curVal, 5);
            } else {
                curData[listKey][dataKey] = formatNumber(curVal, null);
            }
        } else {
            curData[listKey][dataKey] = formatNumber(curVal);
        }
        this.setState({
            data: curData
        });
        this.editEaRsaDraft();
    }
    changeDraftId = (key1, key2) => {
        this.setState({
            draft_id: key1,
            draft_name: key2
        })
    }
    changeCheckedStatus = (key) => {
        let listKey = this.state.listKey;
        let curChecked = this.state.data;
        curChecked[listKey][key].checked = !curChecked[listKey][key].checked;
        this.setState({
            data: curChecked
        })
    }

    setFileName = (e) => {
        let listKey = this.state.listKey;
        let fileObj = e.target.files[0];
        let dataKey = e.target.name;
        let curData = this.state.data;
        Utils.uploadFile(fileObj).then((value) => {
            if (dataKey == "additional_documents") {
                let additionalDoc = { name: fileObj.name, file_obj: value };
                if (curData[listKey].additional_documents[0].name == '') {
                    curData[listKey].additional_documents[0] = additionalDoc;
                    $(".additional_documents0").removeClass("hidden");
                } else {
                    curData[listKey].additional_documents.push(additionalDoc);
                }
            } else {
                curData[listKey][dataKey].name = fileObj.name;
                curData[listKey][dataKey].file_obj = value;
            }
            this.setState({
                data: curData
            })
            this.editEaRsaDraft();
        })
    }

    createEaDraft = () => {
        if (this.state.addingStatus == 1) {
            return;
        }
        this.setState({
            addingStatus: 1
        })
        let curData = this.state.data;
        for (let i = 0; i < this.state.data.length; i++) {
            this.state.data[i].checkbox_status = false
        }
        this.setState({
            data: curData
        });
        post('createEaRsaDraft', { draft_set_id: this.state.draft_id, security_plan_id: this.state.shareClassList[0].id }).then((response) => {
            let curData = this.state.data;
            let draftSetList = this.state.eaRsaDraftSetList;
            let setId = this.state.draft_id;
            if (response.data.code === 0) {
                response.data.data.checkbox_status = false;
                response.data.data.is_show_error = 0;
                curData.push(response.data.data)
                for (let i = 0; i < curData.length; i++) {
                    curData[i].is_show_error = 0
                }
                for (let j = 0; j < draftSetList.length; j++) {
                    if (draftSetList[j].id == setId) {
                        draftSetList[j].count = parseInt(draftSetList[j].count)+1
                    }
                }
                this.setState({
                    data: curData,
                    addingStatus: 0,
                    // listKey: this.state.data.length - 1,
                    eaRsaDraftSetList: draftSetList
                })
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    transferKey = (key) => {
        const { listKey, data } = this.state;
        let curKey = listKey;
        let list = [...data];
        if (curKey === key) {
            curKey = -1;
        } else {
            curKey = key;
            list.forEach((v, k)=>{
                v.checkbox_status = false;
                list[k] = {...v};
            });
        }
        $('.collapse').removeClass('in');
        this.setState({
            listKey: curKey,
            data: list,
        })
    }
    selectDraftSet = (key) => {
        let curDraftList = this.state.eaRsaDraftSetList;
        this.setState({
            draft_name: curDraftList[key].name,
            draft_id: curDraftList[key].id
        })
        post('getDraftSecurityListById', { id: curDraftList[key].id }).then((response) => {
            if (response.data.code === 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    response.data.data[i].checkbox_status = false
                    response.data.data[i].is_show_error = 0;
                }
                this.setState({
                    data: response.data.data,
                    listKey: -1
                })
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    getDraftSecurityList = () => {
        post('getDraftSecurityListById', { id: this.state.draft_id }).then((response) => {
            if (response.data.code === 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    response.data.data[i].checkbox_status = false;
                    response.data.data[i].is_show_error = 0;
                }
                this.setState({
                    data: response.data.data,
                    listKey: -1
                })
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    editEaRsaDraft = (e) => {
        const { listKey } = this.state

        if (this.state.saveStatus == 1) {
            return;
        }
        this.setState({
            saveStatus: 0
        })
        let requestData = { ...this.state.data[listKey] };
        requestData.relationship_list = JSON.stringify(requestData.relationship_list)
        requestData.job_title_list = JSON.stringify(requestData.job_title_list)
        post('editEaRsaDraft', requestData).then((response) => {
            if (response.data.code === 0) {
                if (response.data.data.certificate) {
                    requestData['certificate'] = response.data.data.certificate;
                    this.refreshDraftSecurityList();
                }
                this.setState({
                    saveStatus: 1
                })
                setTimeout(() => {
                    this.setState({
                        saveStatus: -1
                    });
                }, 300)
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    /**
     * 设置certificate之后更新列表
     */
    refreshDraftSecurityList = () => {
        post('getDraftSecurityListById', { id: this.state.draft_id }).then((response) => {
            if (response.data.code === 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    response.data.data[i].checkbox_status = false;
                    response.data.data[i].is_show_error = 0;
                }
                this.setState({
                    data: response.data.data,
                })
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    selectCount = () => {
        let selectLength = this.state.pageData;
        let selectCount = 0;
        for (let j = 0; j < this.state.data.length; j++) {
            if (this.state.data[j].checkbox_status === true) {
                selectCount++;
            }
        }
        selectLength.checked_count = selectCount;
        this.setState({
            pageData: selectLength
        })
        this.refs.addToLedger.getSignatureAdminList();
        this.refs.addToLedger.getCheckedId();
    }
    removeSuccessData = (result) => {
        let delIds = [];
        for (let i in result) {
            delIds.push(parseInt(i))
        }
        delIds.sort();
        let curData =[...this.state.data];
        let newData =[...this.state.data];
        for (let i = curData.length-1; i>=0; i--) {
            for (let j = 0; j< delIds.length;  j++) {
                if (curData[i].id == delIds[j]) {
                    newData.splice(i, 1);
                }
            }
        }
        this.setState({
            data: newData,
            listKey: -1
        })
    }
    getErrorMessage = (result) => {
        this.setState({
            sendResult: result,
        })
        let curData = [...this.state.data];
        for (let i = 0; i < this.state.data.length; i++) {
            if (this.state.sendResult.hasOwnProperty(this.state.data[i].id)) {
                this.state.data[i].is_show_error = 1
            }
        }
        this.setState({
            data: curData
        })
    }
    handleSelectChange = (name, data) => {
        let curData = this.state.data;
        let listKey = this.state.listKey;
        if (data) {
            curData[listKey].name = data.nick_name || "";
            curData[listKey].email = data.email || "";
        }
        this.setState({
            selectedData: data,
            data: curData
        })
    }

    /**
     * 更新table checkbox
     * @param selectCount 选中总计
     * @param selectIndexArray 有更新的checkbox下标和状态
     * @param isEmpty checkbox是否为全不选状态
     */
    updateSelectCount = (selectCount, selectIndexArray, checkedIdArray, isEmpty = false) => {
        const { data, listKey } = this.state;
        let list = [...data];
        if (isEmpty) {
            list.forEach((v, k) => {
                list[k]['checkbox_status'] = false;
            })
        } else {
            selectIndexArray.forEach((v, k) => {
                list[v.selectIndex]['checkbox_status'] = v.checked;
            })
        }
        this.setState(() => ({
            selectCount,
            selectIndex: selectIndexArray[0],
            data: list,
            listKey: selectCount > 0 ? -1 : listKey,
            checkedIds: [].concat(checkedIdArray)
        }))
    }

    /**
     * checkbox change事件
     * @param selectedRowKeys
     */
    securityListsChange = (selectedRowKeys) => {
        const { data, listKey } = this.state;
        let checked = false;
        let securityLists = [...data];
        securityLists.forEach((v, k) => {
            securityLists[k]['checkbox_status'] = false;
        });
        selectedRowKeys.forEach((v) => {
            securityLists.forEach((item) => {
                if (item.id == v) {
                    item['checkbox_status'] = true;
                    checked = true;
                }
            })
        })
        this.setState({data: securityLists, listKey: checked ? -1 : listKey, checkedIds: [].concat(selectedRowKeys)});
    }

    /**
     * board_approval_date
     * @returns {JSX.Element}
     */
    handleDraftRsaDateChange = (key, moment_key, date) => {
        const { data, listKey, dateFormat } = this.state;
        let curData = [...data];
        curData[listKey][key] = date.format(dateFormat);
        curData[listKey][moment_key] = date;
        this.setState({
            data: curData
        });
        this.editEaRsaDraft();
    }
    CheckboxChange(isChecked) {
        const { data, listKey } = this.state;
        let list = [...data];
        if (isChecked == 1) {
            list[listKey].relationship_list = [
                { effective_date: '', relationship: "" },
            ]
            list[listKey].job_title_list = [
                { effective_date: '', job_title: "" },
            ]
            list[listKey].employee_id = ''
            list[listKey].passport = ''
            list[listKey].date_of_birth = ''
            list[listKey].gender = ''
            list[listKey].annual_salary = ''
            list[listKey].country = ''
            list[listKey].address_details = ''
            list[listKey].name = ''
            list[listKey].email = ''
            list[listKey].stakeholder_type = ''
        } else {
            list[listKey].name = ''
            list[listKey].email = ''
            list[listKey].relationship_list = [
                { effective_date: Utils.getCurDate(), relationship: "Investor" },
            ]
            list[listKey].job_title_list = [
                { effective_date: Utils.getCurDate(), job_title: "" },
            ]
            list[listKey].stakeholder_type = 1
        }
        list[listKey].choose_type = isChecked
        this.setState({
            data: list
        })
        this.editEaRsaDraft()
    }
    relationshipEffctiveDateChange = (listName, name, key, date) => {
        if(!date){
            return
        }
        const { data, dateFormat, listKey } = this.state;
        let curData = this.state.data[listKey];
        curData[listName][key][name] = date.format(dateFormat);
        data[listKey] = curData
        this.setState({
            data
        });
    }
    setRelation = (key, e) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        curList.relationship_list[key].relationship = e.target.value;
        data[listKey] = curList
        this.setState({
            data
        })

    }
    addRelation = () => {
        const { data, listKey } = this.state;
        let one = { effective_date: Utils.getCurDate(), relationship: "Investor" }
        let curList = this.state.data[listKey];
        curList.relationship_list.push(one);
        data[listKey] = curList
        this.setState({
            data
        })
    }
    delRelation = (num) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        if (curList.relationship_list.length <= 1) {
            return;
        }
        curList.relationship_list.splice(num, 1);
        data[listKey] = curList
        this.setState({
            data
        })
    }
    JobRecordData = (key, e) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        curList.job_title_list[key].job_title = e.target.value;
        data[listKey] = curList
        this.setState({
            data
        })
    }
    handleDraftOptionDateChange = (key, moment_key, date) => {
        const { data, listKey, dateFormat } = this.state;
        let curData = [...data];
        curData[listKey][key] = date ? date.format(dateFormat) : '';
        curData[listKey][moment_key] = date || '';
        this.setState({
            data: curData
        });
        this.editEaRsaDraft();
    }
    delJobTitle = (num) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        if (curList.job_title_list.length <= 1) {
            return;
        }
        curList.job_title_list.splice(num, 1);
        data[listKey] = curList
        this.setState({
            data
        })
        this.editEaRsaDraft()
    }
    addJobTitle = () => {
        let one = { effective_date: Utils.getCurDate(), job_title: "" }
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        curList.job_title_list.push(one);
        data[listKey] = curList
        this.setState({
            data
        })
    }
    countryRecordData = (val) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        curList['country'] = val
        data[listKey] = curList
        this.setState({
            data
        })
    }
    bindManageTemplates=()=>{
        this.props.history.push({ pathname: '/home/securities/templates', state: { activeKey: '1' } })
    }
    bindBatchUploadModel = () => {
        this.modalRef = Modal.open({
            component: props => <ModalContent close={props.close} title={__('Bulk upload')}>
                {/* historyList={eaHistoryList} callback={this.getEaHistoryList} */}
                <EnterRsasInfomation close={props.close} callback={this.getEaRsaDraftSetList} />
            </ModalContent>,
            className: 'sprout-modal email-modal',
            width: 1300
        })
    }
    bindSecurities = ()=>{
        this.props.history.push({ pathname: '/home/securities/stock-option-awards', state: { activeKey: '2' } })
    }

    bindStakeholders = () =>{
        this.props.history.push('/home/stakeholders/stakeholders-list')
    }

    closeDraftLeft = () => {
        this.setState({ listKey: -1 });
    }

    render() {
        if (this.state.addingStatus === 1) {
            return (
                <div className="loading_position">
                    <Loading />
                </div>
            )
        }
        let selectCount = 0;
        let listKey = this.state.listKey;
        if (this.state.data.length > 0) {
            for (let j = 0; j < this.state.data.length; j++) {
                if (this.state.data[j].checkbox_status == true) {
                    selectCount++;
                }
            }
        }
        let actionButtonClass = 'btn action-disabledBtn action-btn dropdown-toggle';
        let sendButtonClass = 'btn boxbtn-to send-newdisabledBtn';

        if (selectCount >= 1) {
            actionButtonClass = 'btn action-availableBtn action-btn dropdown-toggle';
            sendButtonClass = 'btn boxbtn-to send-newavailableBtn';
        }
        let isShowDocList = 0;
        let isCustomDoc = 0;
        let hasAdditionalDoc = 0;
        if (listKey != "-1") {
            for (let k = 0; k < this.state.data[listKey].additional_documents.length; k++) {
                if (this.state.data[listKey].additional_documents[k].real_name != "") {
                    hasAdditionalDoc = 1
                }
            }
        }
        if (listKey >= 0) {
            if (this.state.data[listKey].documentset_id == 0) {
                isCustomDoc = 1;
            }
        }
        if (listKey >= 0) {
            if (this.state.data[listKey].purchase_agreement_file.real_name != ""
                || this.state.data[listKey].incentive_plan_file.real_name != ""
                || hasAdditionalDoc == 1
                || isCustomDoc == 1
            ) {
                isShowDocList = 1;
            }
        }
        const { selectedData, currency = '', data, formatShareClassList, dateFormat, genderList, stakeholderRelationshipList, stakeholderType, shareClassList } = this.state;
        const certificateKey = shareClassList.findIndex(v=>v?.id == data[listKey]?.security_plan_id);
        return (
            <div className="draftsContent">
                <RenameDraftSet ref="renameDraftSet" getDraftSecurityList={this.getDraftSecurityList} getDraftSetList={this.getEaRsaDraftSetList} draft_name={this.state.draft_name} draft_id={this.state.draft_id} changeDraftId={this.changeDraftId} />
                <DeleteDraftSet ref="deleteDraftSet" getDraftSecurityList={this.getDraftSecurityList} getDraftSetList={this.getEaRsaDraftSetList} draft_name={this.state.draft_name} draft_id={this.state.draft_id} changeDraftId={this.changeDraftId} />
                <DeleteDraftSecurity ref="deleteDraftSecurity" curData={this.state.data} getDraftSecurityList={this.getDraftSecurityList} listKey={this.state.listKey} checkedIds={this.state.checkedIds} />
                <CreateDraftSet type={this.state.type} setList={this.state.eaRsaDraftSetList} getDraftSecurityList={this.getDraftSecurityList} getEaRsaDraftSetList={this.getEaRsaDraftSetList} draft_name={this.state.draft_name} draft_id={this.state.draft_id} changeDraftId={this.changeDraftId} />
                <AddToLedger formatShareClassList={this.state.formatShareClassList} ref="addToLedger" curPlanId={this.state.curPlanId} removeSuccessData={this.removeSuccessData} getErrorMessage={this.getErrorMessage} pageData={this.state.pageData} curData={this.state.data} checkedIds={this.state.checkedIds}/>

                <div className="">
                    <div className={`prefer-left ${listKey < 0 && 'prefer-left-max'}`}>
                        <Button className="billing-change-plan-back" onClick={this.bindSecurities}>
                            <LeftOutlined />{__('Back')}
                        </Button>
                        <div className="holders-nav minWidth400 clearfix">
                            <div className="action-box onset">
                                <Permission type="securities_draftEA_delete" hide>
                                    <button type="button" className={actionButtonClass+" width80"} data-toggle="modal" onClick={this.getCheckedId} data-target={"#deleteDraftSecurityModal"}>{__('Delete')}</button>
                                </Permission>
                                <Permission type="securities_draftEA_set" hide>
                                    <div className="btn-group drafts-btn-group draft_share_btnGroup">
                                        <a className="dropdown-toggle text-blue blue-triangle-down drafts-btn" data-toggle="dropdown">{this.state.draft_name}&nbsp;&nbsp;</a>
                                        <ul className="dropdown-menu drafts-downmenu">
                                            {this.state.eaRsaDraftSetList.map((value, key) => {
                                                return (
                                                    <li key={key}>
                                                        <a className="resetDrafts" value={value.id} onClick={this.selectDraftSet.bind(this, key)}>{value.name}
                                                            {/*{value.count >0 &&*/}
                                                            <DeleteOutlined className='drafts-trash-can' onClick={this.transferDraftSetData.bind(this, key, "deleteDraftSet")} data-toggle="modal" data-target="#deleteDraftSetModal"/>
                                                            {/*}*/}
                                                            <span className="drafts-icon" onClick={this.transferDraftSetData.bind(this, key, "renameDraftSet")} data-toggle="modal" data-target="#enterNewNameForDraftsModal">{value.count}</span>
                                                        </a>
                                                    </li>
                                                )
                                            })}
                                            <li><a data-toggle="modal" data-target="#createNewDraftSetModal">{__('Create a new draft set')}</a></li>
                                        </ul>
                                    </div>
                                </Permission>
                            </div>
                            <div className="form-group search-group banner-rightBox offset">
                                <Permission type="securities_draftEA_add" hide>
                                    <div className="btn-group marginLf10">
                                        <a className="btn boxbtn-to send-availableBtn" onClick={this.bindBatchUploadModel}>{__('Bulk upload')}</a>
                                    </div>
                                    <div className="btn-group marginLf10">
                                        <a className="btn boxbtn-to bg-darkblue" onClick={this.createEaDraft}>+ {__('Add Certificate')}</a>
                                    </div>
                                </Permission>
                                <Permission type="securities_draftEA_send" hide>
                                    <div className="btn-group marginLf10">
                                        {selectCount >= 1 && <a onClick={this.selectCount} data-toggle="modal" data-target="#addToLedgersModal" className={sendButtonClass}>{__('Add to ledger')}</a>}
                                        {selectCount == 0 && <a className={sendButtonClass}>{__('Add to ledger')}</a>}
                                    </div>
                                </Permission>
                            </div>
                        </div>
                        {this.state.loadingStatus === 1 &&
                            <div className="loading_position">
                                <Loading />
                            </div>
                        }
                        {this.state.loadingStatus === 0 &&
                            <div>
                                {this.state.data.length == 0 &&
                                    <div className="empty_option ">
                                        <div className="empty_option_box marginTop80">
                                            <span className="empty_option_icon bg_draft_file"></span>
                                            <h5 className="empty_option_text">{__('This set has no certificate.')}</h5>
                                            <Permission type="securities_draftEA_add" hide>
                                                <button className="btn action-availableBtn action-btn white-text font_weight500"
                                                    onClick={this.createEaDraft}>
                                                        {__('Add Certificate')}
                                                </button>
                                            </Permission>
                                        </div>
                                    </div>
                                }
                                {this.state.data.length >= 1 &&
                                    <div className="draftList">
                                        <div className="scroll-div">

                                            <DraftRsaTable
                                                tableList={data}
                                                transferKey={this.transferKey}
                                                formatShareClassList={formatShareClassList}
                                                listKey={listKey}
                                                securityListsChange={this.securityListsChange}
                                                updateSelectCount={this.updateSelectCount}
                                            />

                                        </div>
                                        {/* <Permission type="securities_draftEA_add" hide>
                                            <button type="button" onClick={this.createEaDraft}
                                                className="btn resetFont white-text bg-darkblue marginTop10">+ Add Certificate
                                            </button>
                                        </Permission> */}
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <div className={`prefer-right ${listKey < 0 && 'prefer-right-max'}`}>
                        <div>
                            {this.state.listKey === -1 &&
                                <div className="empty_option">
                                    <div className="empty_option_box marginTop186">
                                        <span className="edit-icon"></span>
                                        <h5 className="edit_text font_weight500">{__('Select a draft to edit')}</h5>
                                    </div>
                                </div>
                            }
                            {this.state.listKey >= 0 &&
                                <div>
                                    {this.state.data[listKey].is_show_error === 1 &&
                                        <div className="error_details error_tip_bar bg-lightred alert alert-dismissible" role="alert">
                                            <div className="error_icon_box bg-redbtn">
                                                <ExclamationCircleOutlined style={{color:'#d42f2e'}} />
                                            </div>
                                            <div className="error_tip_text bg-lightred font_weight500">
                                                <h5 className="error_tip_text">{__('There were some problems with this certificate.')}</h5>
                                                <div className="error_list">
                                                    {this.state.sendResult && this.state.sendResult[this.state.data[listKey].id].error_reason.map((val, k) => {
                                                        return (
                                                            <p className="marginTop10 mb-0" key={k}>{val.title}: {val.message}</p>
                                                        )

                                                    })}
                                                </div>
                                            </div>
                                            <div className="error_close bg-redbtn">
                                                <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                            </div>
                                        </div>
                                    }
                                    {this.state.saveStatus == 0 &&
                                        <div className="banner-download draft_save_banner bg-apple-green no-marginBottom ">
                                            <div className="circle-border draft-circle-border"></div>
                                            <p className="download-message">{__('Saving')}</p>
                                        </div>
                                    }
                                    {this.state.saveStatus == 1 &&
                                        <div className="banner-download draft_save_banner bg-apple-green no-marginBottom ">
                                            <span className="colorWhite glyphicon glyphicon-ok"></span>
                                            <p className="download-message">{__('Draft saved')}</p>
                                        </div>
                                    }
                                    <ul>
                                        <li className="draft-titLi clearfix">
                                            <div className="draft-titleLi-group">
                                                <p className="onset">{__('Certificate')} ({shareClassList?.[certificateKey]?.share_class_prefix}-{data[listKey]?.certificate})</p>
                                                <CloseOutlined onClick={this.closeDraftLeft} />
                                            </div>
                                            {/* <div className="offset">
                                                <button type="button" className="draft-certificate-btn marginRig6" ><span className="white-text glyphicon glyphicon-search"></span></button>
                                                <button type="button" onClick={this.getDeleteDraftId} data-toggle="modal" data-target={"#deleteDraftSecurityModal"} className="draft-certificate-btn" ><span className="white-text glyphicon glyphicon-trash"></span></button>
                                            </div> */}
                                        </li>
                                        <li>
                                            <a className="prefer-togglebtn" href="#certificate_li" data-toggle="collapse">{__('Certificate')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                            <div className="collapse" id="certificate_li">
                                                <form className="row">
                                                    <div className="form-group col-xs-6">
                                                        <label htmlFor="draft_rsa">{__('Share class')}</label>
                                                        <select id="draft_rsa" name="security_plan_id" value={this.state.data[listKey].security_plan_id} onChange={this.recordData} className="form-control select-container">
                                                            {this.state.shareClassList.map((value, key) => {
                                                                return (
                                                                    <option key={key} value={value.id} >{value.plan_name} ({value.share_class_prefix})</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-xs-6">
                                                        <label htmlFor="draft_certificate">{__('Certificate')} #</label>
                                                        <input id="draft_certificate" onBlur={this.editEaRsaDraft} name="certificate" value={this.state.data[listKey].certificate} onChange={this.recordData} type="text" className="form-control" />
                                                    </div>
                                                    <div className="form-group col-xs-6">
                                                        <label htmlFor="draft_award_category">{__('Award Category')}</label>
                                                        <select id="draft_award_category" name="award_category"
                                                            value={this.state.data[listKey].award_category}
                                                            onBlur={this.editEaRsaDraft}
                                                            className="form-control select-container"
                                                            onChange={this.recordData}>
                                                            <option value="">No Category</option>
                                                            {this.state.award_category.map((value, key) => {
                                                                return (
                                                                    <option key={key} value={value.name}>{value.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </form>
                                            </div>
                                        </li>
                                        <li>
                                            <a className="prefer-togglebtn" href="#convert_note_li" data-toggle="collapse" >{__('Convert note')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                            <div className="collapse" id="convert_note_li">
                                                <form className="row reset_formFlex" >
                                                    <div className="form-group col-xs-6">
                                                        <label htmlFor="draft_convertible">{__('Convertible')}</label>
                                                        <select id="draft_convertible" onBlur={this.editEaRsaDraft} name="convertible_id" className="form-control select-container" value={this.state.data[listKey].convertible_id} onChange={this.recordData}>
                                                            <option value="">No Convertible</option>
                                                            {this.state.cnSecuritiesList.map((value, key) => {
                                                                return (
                                                                    <option key={key} value={value.id}>{value.custom_label_id}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </form>
                                                <p className="text-muted draft-tip">{__('Choose a note to convert it to a stock certificate, The selected note will be converted when this certificate is issued.')}<span><a className="text-blue"> Learn more</a></span></p>
                                            </div>
                                        </li>
                                        <li>
                                            <a className="prefer-togglebtn" href="#transaction_value_li" data-toggle="collapse">{__('Transaction value')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                            <div className="collapse" id="transaction_value_li">
                                                <form className="form-draftflextwo">
                                                    <div className="row">
                                                        <div className="form-group col-xs-6 no-paddingleft">
                                                            <label htmlFor="draft_number_of_shares">{__('Issued shares')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                            <input type="text" id="draft_number_of_shares" onBlur={this.resetDataFormat} onFocus={this.setDataFormat} name="quantity" value={I(this.state.data[listKey].quantity, '')} onChange={this.recordNumber} className="form-control" />
                                                        </div>
                                                        <div className="form-group col-xs-6 no-paddingright ">
                                                            <label htmlFor="draft_cash_paid">{__('Cash paid')}</label>
                                                            <div className="input-group"><span className="input-group-addon">{this.state.currency}</span>
                                                                <input id="draft_cash_paid" name="cash_paid" onChange={this.recordNumber} onFocus={this.setDataFormat} onBlur={this.resetDataFormat} value={this.state.data[listKey].cash_paid} className="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-xs-6 no-paddingleft">
                                                            <label htmlFor="draft_debt_canceled">{__('Debt canceled')}</label>
                                                            <div className="input-group"><span className="input-group-addon">{this.state.currency}</span>
                                                                <input type="text" id="draft_debt_canceled" name="debt_canceled" onFocus={this.setDataFormat} onBlur={this.resetDataFormat} value={this.state.data[listKey].debt_canceled} onChange={this.recordNumber} className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-xs-6 no-paddingright ">
                                                            <label htmlFor="draft_value_of_IP_transferred">{__('Value of IP transferred')}</label>
                                                            <div className="input-group"><span className="input-group-addon">{this.state.currency}</span>
                                                                <input type="email" id="draft_value_of_IP_transferred" name="value_of_ip_transferred" onFocus={this.setDataFormat} onBlur={this.resetDataFormat} value={this.state.data[listKey].value_of_ip_transferred} onChange={this.recordNumber} className="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-xs-6 no-paddingleft">
                                                            <label htmlFor="draft_other_consideration">{__('Other consideration')}</label>
                                                            <div className="input-group"><span className="input-group-addon">{this.state.currency}</span>
                                                                <input id="draft_other_consideration" name="other_consideration" type="text" onFocus={this.setDataFormat} onBlur={this.resetDataFormat} value={this.state.data[listKey].other_consideration} onChange={this.recordNumber} className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-xs-6 no-paddingright">
                                                            <label htmlFor="draft_rsa_status">{__('Status')}</label>
                                                            <select id="draft_rsa_status" value={this.state.data[listKey].status} onChange={this.recordData} name="status" className="form-control select-container">
                                                                {this.state.statusList.map((value, key) => {
                                                                    return (
                                                                        <option key={key} value={value.value}>{value.text}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </li>
                                        <li>
                                            <a className="prefer-togglebtn" href="#shareholder_li" data-toggle="collapse">{__('Shareholder')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                            <div className="collapse" id="shareholder_li">
                                                <form className="form-draftflextwo">
                                                    <div className="row draft-checked">
                                                        {sprintf(__('Add new stakeholders on the %s page. This page only selects existing stakeholders.'), <a onClick={this.bindStakeholders}>{__('All stakeholders')}</a>)}
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-xs-6 no-paddingleft">
                                                            <label htmlFor="draft_name">{__('Name')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                            <SelectStakeholder value={this.state.data[listKey].name} add={false} onBlur={this.editEaRsaDraft} onChange={this.handleSelectChange} />
                                                        </div>
                                                        <div className="form-group col-xs-6 no-paddingright">
                                                            <label htmlFor="draft_email">{__('Email')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                            <SelectStakeholder value={this.state.data[listKey].email} add={false} isEmail={true} onBlur={this.editEaRsaDraft} onChange={this.handleSelectChange} />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </li>
                                        <li>
                                            <a className="prefer-togglebtn" href="#rsa_board_content_li" data-toggle="collapse">{__('Board consent')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                            <div className="collapse" id="rsa_board_content_li">
                                                <form className="row">
                                                    <div className="form-group col-xs-6 ">
                                                        <label htmlFor="rsa_board_approval_date">{__('Board approval date')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                        <DateSelector
                                                            onChange={this.handleDraftRsaDateChange.bind(this, 'board_approval_date', 'board_approval_date_moment')}
                                                            value={I(data[listKey]['board_approval_date_moment'], I(data[listKey]['board_approval_date'], '') ? moment(data[listKey]['board_approval_date'], dateFormat) : '')}
                                                            inputReadOnly={false}
                                                        />
                                                    </div>
                                                    <div className="form-group col-xs-6 ">
                                                        <label htmlFor="rsa_issue_date">{__('Issue date')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                        <DateSelector
                                                            onChange={this.handleDraftRsaDateChange.bind(this, 'issue_date', 'issue_date_moment')}
                                                            value={I(data[listKey]['issue_date_moment'], I(data[listKey]['issue_date'], '') ? moment(data[listKey]['issue_date'], dateFormat) : '')}
                                                            inputReadOnly={false}
                                                        />
                                                    </div>
                                                </form>
                                            </div>
                                        </li>
                                        <li>
                                            <a className="prefer-togglebtn" href="#vesting_schedule_li" data-toggle="collapse">{__('Vesting schedule')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                            <div className="collapse" id="vesting_schedule_li">
                                                <form className="row">
                                                    <div className="form-group col-xs-6 ">
                                                        <label htmlFor="draft_schedule">{__('Schedule')}</label>
                                                        <select id="draft_schedule" name="vesting_schedule_id" onBlur={this.editEaRsaDraft} value={this.state.data[listKey].vesting_schedule_id} onChange={this.recordData} className="form-control select-container">
                                                            <option value="">No Vesting</option>
                                                            {this.state.scheduleList.map((value, key) => {
                                                                return (
                                                                    <option key={key} value={value.id}>{value.schedule_name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    {this.state.data[listKey].vesting_schedule_id != "" && this.state.data[listKey].vesting_schedule_id != 0 &&
                                                        <div className="form-group col-xs-6 ">
                                                            <label htmlFor="rsu_grant_date">{__('Vesting start date')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                            <DateSelector
                                                                onChange={this.handleDraftRsaDateChange.bind(this, 'vesting_start_date', 'vesting_start_date_moment')}
                                                                value={I(data[listKey]['vesting_start_date_moment'], I(data[listKey]['vesting_start_date'], '') ? moment(data[listKey]['vesting_start_date'], dateFormat) : '')}
                                                                inputReadOnly={false}
                                                            />
                                                        </div>
                                                    }
                                                </form>
                                            </div>
                                        </li>
                                        <li>
                                            <a className="prefer-togglebtn" href="#rsa_document_note_li" data-toggle="collapse">{__('Document and notes')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                            <div className="collapse" id="rsa_document_note_li">
                                                <form>
                                                    <div className="row">
                                                        <div className="form-group col-xs-6">
                                                            <label htmlFor="draft_rsa_doc">{__('Document sets')}</label>
                                                            <select id="draft_rsa_doc" name="documentset_id"
                                                                onBlur={this.editEaRsaDraft}
                                                                value={this.state.data[listKey].documentset_id}
                                                                onChange={this.recordData}
                                                                className="form-control select-container">
                                                                <option value="-3">Custom documents</option>
                                                                {this.state.documentSet.map((value, key) => {
                                                                    return (
                                                                        <option key={key} value={value.id}>{value.set_name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                        <div className="form-group col-xs-6 document_set">
                                                        <div className="manage_document" onClick={this.bindManageTemplates}>{__('Manage document set')}</div>
                                                    </div>
                                                        {isShowDocList == 1 &&
                                                            <div>
                                                                <div className="form-group row marginBott20">
                                                                    <label className="col-xs-12 text-muted">{__('Purchase Agreement')}</label>
                                                                    <div className="col-xs-4 no-paddingright">
                                                                        <div
                                                                            className={"select-btn convertiblenote-select-btn select-blue"}>
                                                                                {__('Choose a file')}
                                                                            <label htmlFor="doc_select2" className="sr-only">File
                                                                                input</label>
                                                                            <input name="purchase_agreement_file"
                                                                                onChange={this.setFileName}
                                                                                ref="purchase_agreement_file" type="file"
                                                                                id="doc_select2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xs-8 filename-box no-paddingright">
                                                                        {this.state.data[listKey].purchase_agreement_file.name == "" &&
                                                                            <p className="text-muted paddingLeft6">{__('No file selected')}</p>
                                                                        }
                                                                        {this.state.data[listKey].purchase_agreement_file.name != "" &&
                                                                            <p className="text-muted paddingLeft6 paddingRight18 ">{this.state.data[listKey].purchase_agreement_file.name}
                                                                                {/*<span onClick={this.fileDelete.bind(this, 'purchase_agreement_file', -1)} className="draft_trash purchase_agreement_file glyphicon glyphicon-trash"></span>*/}
                                                                                {/*<DeleteOutlined className="draft_trash" onClick={this.fileDelete.bind(this, 'purchase_agreement_file', -1)} />*/}                                                                            </p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row marginBott20">
                                                                    <label className="col-xs-12 text-muted">{__('Equity incentive plan')}</label>
                                                                    <div className="col-xs-4 no-paddingright">
                                                                        <div
                                                                            className={"select-btn convertiblenote-select-btn select-blue"}>{__('Choose a file')}
                                                                            <label htmlFor="doc_select3" className="sr-only">File
                                                                                input</label>
                                                                            <input name="incentive_plan_file"
                                                                                onChange={this.setFileName}
                                                                                ref="incentive_plan_file" type="file"
                                                                                id="doc_select3" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xs-8 filename-box no-paddingright">
                                                                        {this.state.data[listKey].incentive_plan_file.name == "" &&
                                                                            <p className="text-muted paddingLeft6">{__('No file selected')}</p>
                                                                        }
                                                                        {this.state.data[listKey].incentive_plan_file.name != "" &&
                                                                            <p className="text-muted paddingLeft6 paddingRight18 ">{this.state.data[listKey].incentive_plan_file.name}
                                                                                {/*<span onClick={this.fileDelete.bind(this, 'incentive_plan_file', -1)} className="draft_trash incentive_plan_file glyphicon glyphicon-trash"></span>*/}
                                                                                {/*<DeleteOutlined className="draft_trash" onClick={this.fileDelete.bind(this, 'incentive_plan_file', -1)} />*/}                                                                            </p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row marginBott20">
                                                                    <label className="text-muted col-xs-12">{__('Additional documents')}</label>
                                                                    <div className="col-xs-4 no-paddingright">
                                                                        <div className={"select-btn convertiblenote-select-btn select-blue"}>{__('Choose a file')}
                                                                            <label htmlFor="doc_select4" className="sr-only">File input</label>
                                                                            <input name="additional_documents"
                                                                                onChange={this.setFileName}
                                                                                ref="additional_documents" type="file"
                                                                                id="doc_select4" />
                                                                        </div>
                                                                    </div>
                                                                    {this.state.data[listKey].additional_documents.map((val, k) => {
                                                                        let isPush = "";
                                                                        let isRow = "";
                                                                        if (k != 0) {
                                                                            isPush = "col-xs-push-4";
                                                                            isRow = "row"
                                                                        }
                                                                        return (
                                                                            <div className={isRow} key={k}>
                                                                                <div className={"col-xs-8 filename-box " + isPush}>
                                                                                    {(this.state.data[listKey].additional_documents[k].name == "" || this.state.data[listKey].additional_documents[k].name == null) &&
                                                                                        <p className="text-muted paddingLeft6">{__('No file selected')}</p>
                                                                                    }
                                                                                    {this.state.data[listKey].additional_documents[k].name != "" && this.state.data[listKey].additional_documents[k].name != null &&
                                                                                        <p className="text-muted paddingLeft6 paddingRight18 ">{this.state.data[listKey].additional_documents[k].name}
                                                                                            {/*<span onClick={this.fileDelete.bind(this, 'additional_documents', k)} className="draft_trash additional_documents glyphicon glyphicon-trash"></span>*/}
                                                                                            {/*<DeleteOutlined className="draft_trash" onClick={this.fileDelete.bind(this, 'additional_documents', k)} />*/}                                                                                        </p>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="form-group col-xs-12 ">
                                                            <label htmlFor="options_notes">{__('Notes')}</label>
                                                            <textarea id="options_notes" type="text"
                                                                onBlur={this.editEaOptionDraft} name="notes"
                                                                className="form-control minHeight100"
                                                                value={this.state.data[listKey].notes}
                                                                onChange={this.recordData}></textarea>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}

export default DraftRsas;

