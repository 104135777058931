import React, { useState, useEffect } from 'react';
import { FormItem } from 'react-antd-formutil';
import { Checkbox, } from 'antd'
import { omit } from 'lodash';
import { post } from 'srcPath/http';

const Group = Checkbox.Group

export default function SecurityStatus(props) {
    const { name, label, list, url } = props
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getData = async () => {
            try {
                const { data: resData } = await post(url);
                setOptions(resData?.data || [])
            } catch (e) {
            }
            setLoading(false)
        }
        !!list ? setOptions(list || []) : getData();
    }, [])
    return <FormItem
        name={name || 'status'}
        label={label || 'Status'}
    >
        <Group {...omit(props, ['name', 'label'])}  showSearch placeholder="Select" options={[...(options&&options.map(value => ({ label: value, value })))]} />
    </FormItem>

}