import React, { Component, Fragment } from "react";
import {Table, Checkbox, Dropdown, Menu, Tooltip, Button} from 'antd';
import Utils, { I } from 'Utils';
import TransactionRender from 'B/components/TransactionHistory';
import {DownOutlined} from "@ant-design/icons";
import { noPermission } from 'components/Permission';
import {formatDate} from "Utils/format/formatDate";
import openCertificateModal from 'components/CertificateDetail';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import { LOADING,certificateStatus } from "Utils/constant";

class RsuTable extends Component {
    state = {
        selectedRowKeys: [], // Check here to configure the default column
        loading: false,
        reverseDesc : 'Can reverse cancel only certificates, that have been canceled, repurchased.',
        searchText: '',
        searchedColumn: '',
    };

    componentDidMount(){
        this.props.onRef(this);
        this.initFilterColumn();
    }

    initFilterColumn = () => {
        const tableColumn = this.tableColumns();
        const { saveState } = this.props;
        saveState('tableColumn', tableColumn);
    }

    /**
     * 表格checkbox自定义配置
     * @param sign_status 状态值
     * @returns {JSX.Element}
     */
    securityHover = (checked, record, index) => {

        let showMsgStatus = I(record.showMsgStatus, false);
        const { sign_status } = record;
        const message = {
            1 : __('Awaiting officer signature'),
            2 : __('Awaiting holder signature')
        }
        return (
            <Fragment>
                {
                    (sign_status == 1 || sign_status == 2) &&
                    <div className="signTipBox">
                        <div className={sign_status == 1 ? 'officer-tip-triangle' : 'holder-tip-triangle'}></div>
                        <p className={['sign-tiptext', sign_status == 1 ? 'bg-lightorange' : 'bg-lightgreen', showMsgStatus && 'shares-row-msg-show'].join(' ')} style={{display:'block'}}>{message[sign_status]}</p>
                    </div>
                }
                <Checkbox onClick={(e)=>{
                    e.stopPropagation();
                }} checked={checked} onChange={this.checkChange.bind(this, record, index, checked)} ></Checkbox>
            </Fragment>
        );
    }

    /**
     * 清空checkbox所有选中内容
     */
    clearAll = () => {
        this.setState(()=>({
            selectedRowKeys : []
        }))
    }

    /**
     * 表格checkbox change事件
     * @param record
     * @param index
     * @param boxChecked
     * @param e
     */
    checkChange = (record, index, boxChecked, e) => {
        const { checked } = e.target;
        const { selectedRowKeys } = this.state;
        const { updateSelectCount } = this.props;
        const { id } = record;
        let key_list = [...selectedRowKeys];
        if(checked){
            key_list.push(id);
        }else{
            key_list.forEach( (v, k) => {
                if(v == id){
                    key_list.splice(k, 1);
                }
            })
        }
        updateSelectCount(key_list.length, [{selectIndex: index,checked:checked}]);
        this.setState(()=>({
            selectedRowKeys : key_list
        }))
        this.props.setSelectId(key_list[0])
        this.props.checkSecurityStatus()
    }

    /**
     * 表格列参数配置
     * @returns {[{sorter: (function(*, *): number), dataIndex: string, onFilter: (function(*=, *): boolean), filters: [{text: string, value: string}, {text: string, value: string}, {text: string, value: string}, {text: string, value: string}, {text: string, value: string}, null], title: string, align: string, key: string}, {filterDropdown: function({setSelectedKeys: *, selectedKeys?: *, confirm?: *, clearFilters?: *}): *, sorter: (function(*, *): number), dataIndex: string, filterIcon: function(*): *, onFilter: function(*, *): *|string, onFilterDropdownVisibleChange: function(*=): void, title: string}, {sorter: (function(*, *): number), dataIndex: string, title: string, render: (function(*, *))}, {sorter: (function(*, *)), dataIndex: string, title: string, align: string, render: (function(*=))}, {sorter: (function(*=, *=)), dataIndex: string, title: string, align: string, render: (function(*, *=))}, null, null, null]}
     */
    tableColumns = () => {
        return [
            {
                title : __('Security'),
                align : 'left',
                dataIndex : 'custom_label_id',
                width:120,
                key : 'keyId',
                sorter: true,
                sortName: 'security',
                isChecked: true,
                isDisabled: true,
                render: (text, record) => <Button type="link" onClick={() => openCertificateModal(record.id)}>{text}</Button>
            },
            {
                title : __('Stakeholder'),
                dataIndex : 'name',
                width: 180,
                ellipsis: true,
                sorter: true,
                sortName: 'stakeholder',
                isChecked: true,
            },
            {
                title : __('Status'),
                dataIndex : 'status',
                width: 140,
                ellipsis: {
                    showTitle: false,
                },
                render : (text, value) => {
                    let status = value.status;
                    let statusArr = status.split("until ");
                    status = statusArr[0] +" until " + formatDate(statusArr[1])
                    return <Tooltip  placement={'topLeft'} title = {<span>{value.status.substring(0,16) == "Settleable until" ? status :value.status} <TransactionRender transferSecurity={value.transferSecurity} /></span>}>
                        <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'noWrap' }}>
                            {
                                (value.status === "Outstanding" || value.status==="Issued") &&
                                <p className="file_text green-disc">&nbsp;&nbsp;&nbsp;&nbsp;{certificateStatus[value.status]}</p>}
                            {value.status != "Outstanding" && value.status != "Partially settled" && value.status != "Issued" && value.status.substring(0,10) != "Settleable" &&
                            <p className="file_text red-disc">&nbsp;&nbsp;&nbsp;&nbsp;{certificateStatus[value.status]}</p>}
                            {value.status.substring(0,16) == "Settleable until" &&
                            <p className="yellow-disc file_text">&nbsp;&nbsp;&nbsp;&nbsp;{status}</p>
                            }
                            { (value.status == "Partially settled" || value.status === "Settleable") &&
                            <p className="yellow-disc file_text">&nbsp;&nbsp;&nbsp;&nbsp;{certificateStatus[value.status]}</p>
                            }
                            <TransactionRender transferSecurity={value.transferSecurity} />
                        </div>
                    </Tooltip>
                },
                isChecked: true,
            },
            {
                title : __('Quantity'),
                dataIndex : 'quantity',
                align : 'right',
                width:120,
                render: (text) => <span>{ formatFractionalNumber(text) }</span>,
                isChecked: true,
            },
            {
                title : __('Outstanding'),
                dataIndex : 'outstanding',
                align : 'right',
                width:120,
                render: (text) => <span>{ formatFractionalNumber(text) }</span>,
                isChecked: true,
            },
            {
                title : __('Settled'),
                dataIndex : 'settled_quantity',
                align : 'right',
                width:100,
                render: (text) => <span>{ formatFractionalNumber(text) }</span>,
                isChecked: true,
            },
            {
                title : __('Issue date'),
                dataIndex : 'issue_date',
                align : 'center',
                width:120,
                render: (text, value) => {
                    return <span>{ formatDate(value.issue_date) }</span>
                },
                isChecked: true,
            },
            {
                title : __('Vesting schedule'),
                dataIndex : 'vesting_schedule',
                align : 'left',
                ellipsis: true,
                width: 160,
                render:  text =>{
                    return(
                        <Tooltip title = {text == "" ? "No schedule" : text }><span>{ text == "" ? "No schedule" : text}</span></Tooltip>
                    )
                },
                isChecked: true,
            },
            {
                title : '',
                dataIndex : 'id',
                width: 50,
                render:(text, value, index) => this.dropdownInfo(text, value, index),
                isHide: true,
            }
        ]
    }

    /**
     * 计算 price shares列金额，目的是为了方便排序
     * @param value
     * @param defaultPrice
     * @returns {number|*}
     */
    priceShareOrderBy = (value, defaultPrice) => {
        let transactionVal = Math.round(Utils.resetNumberWithCommas(value.cash_paid) ) + Math.round(Utils.resetNumberWithCommas(value.other_consideration)) + Math.round (Utils.resetNumberWithCommas(value.debt_canceled)) + Math.round (Utils.resetNumberWithCommas(value.value_of_ip_transferred))
        const sharePrice = value.quantity!=0 ? transactionVal/value.quantity : defaultPrice;
        return sharePrice;
    }



    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    setCurSelectSecurity = (data, index, e) => {
        const { setCurSelectSecurity } = this.props;
        setCurSelectSecurity( index);
    }
    setCurSelectSecurityNew = (text, data, index) => {
        const { setCurSelectSecurityNew } = this.props;
        setCurSelectSecurityNew( text);
        this.updateSecurityListsVisible(data, true)
    }
    resetSecurityStatus = ( ) => {
        const { resetSecurityStatus } = this.props;
        resetSecurityStatus( );
    }
    setSelectId = ( index) => {
        const { setSelectId } = this.props;
        setSelectId( index);
    }
    checkSecurityStatus = () => {
        const { checkSecurityStatus } = this.props;
        checkSecurityStatus();
    }

    getVestingData = ( data, index, e) => {
        this.stopDetailModel(data, index, e);
        const { getVestingData } = this.props;
        getVestingData( index);
    }
    getQuantityToSettled = (data, index, e) =>{
        this.stopDetailModel(data, index, e);
        const { getQuantityToSettled } = this.props;
        getQuantityToSettled( );
    }
    getVestMilestone = ( data, index, e) => {
        this.stopDetailModel(data, index, e);
        const { getVestMilestone } = this.props;
        getVestMilestone();
    }

    getInitVestingList = ( data, index, e) => {
        this.stopDetailModel(data, index, e);
        const { getInitVestingList } = this.props;
        getInitVestingList();
    }

    reverseCancellation = (data, index, e) => {
        this.stopDetailModel(data, index, e);
        const { reverseCancellation } = this.props;
        reverseCancellation();
    }

    setInitShareNote = (data, index, e) => {
        this.stopDetailModel(data, index, e);
        const { setInitShareNote } = this.props;
        setInitShareNote();
    }

    resendEmail = (data, index, e) => {
        this.stopDetailModel(data, index, e);
        const { resendEmail } = this.props;
        resendEmail();
    }

    reverseTermination = (data, index, e) => {
        this.stopDetailModel(data, index, e);
        const { reverseTermination } = this.props;
        reverseTermination(data,index);
    }

    /**
     * 更新列表中的菜单显示状态
     * @param index 列表下标
     * @param flag 菜单显示状态
     */
    updateSecurityListsVisible = (data, flag) => {
        const { securityLists, saveState } = this.props;
        let list = [...securityLists];
        const index = list.findIndex((v,k,arr)=>{
            return v.id == data.id
        })
        if(flag === false) {
            list.forEach((v, k) => {
                list[k]['visibleStatus'] = false;
            });
        }
        if(data !== false){
            list[index]['visibleStatus'] = true;
        }
        saveState('rsusList', list);

    }

    handleVisibleChange = flag => {
        this.updateSecurityListsVisible(false, flag);
    }

    stopDetailModel = (data, index, e) => {
        e.stopPropagation();
        this.updateSecurityListsVisible(false,false);
    }

    /**
     * 表格最后一列菜单配置
     * @param data
     * @param index
     * @returns {JSX.Element}
     */
    dropdownInfo = (text, data, index) => {
        const { visibleStatus } = data;
        return (
            <Dropdown
                visible={ visibleStatus }
                onVisibleChange={this.handleVisibleChange}
                onClick={(e)=>{
                    e.stopPropagation();
                    this.setCurSelectSecurityNew(text, data, index)
                    }
                }
                overlay={this.menu( data, index)}
                placement="bottomRight"
                trigger="click">
                <div className="tableMenu"><DownOutlined style={{ color: '#FFF' }}/></div>
            </Dropdown>
        );
    }
    /**
     * Waterfall models 菜单
     * @param data
     * @param index
     * @returns {JSX.Element}
     */
    menu = (data, index) => {
        const { reverseDesc }     = this.state;
        const { notes }      = this.props;
        const isSettleable = (data.status.substring(0,10) == "Settleable"|| data.status == "Partially settled" || data.status == "Outstanding") ? 1 : 0;
        const isModifyVesting     = data.vesting_schedule_id==0 && data.custom_schedule_id==0 ? 0 : 1;
        const reverseCancellation = data.status=="Canceled"||data.status=="Repurchased" ? 1 : 0;
        const canBeCanceled = data.status == "Canceled"||data.status == "Repurchased"||data.status == "Transferred"||data.status == "Exercised" ? 0 : 1;
        const isEmptyTerminationDate = data.termination_date =="" ? 1 : 0;
        const addOrEdit = ( notes == ""||null ) ? __("Add certificate notes"): __("Edit certificate notes")
            return (
            <Menu >
                { (noPermission('securities_EA_modify') === false ) && <Menu.Item ><a onClick={this.stopDetailModel.bind(this,data,index)}  data-toggle="modal" data-target="#modifyRsuModal">{__('Modify RSU')}</a></Menu.Item> }
                { (noPermission('securities_EA_vestingSchedule') === false ) && ( data.type !== "ps" && isModifyVesting==0 && <Menu.Item ><a onClick={this.stopDetailModel.bind(this,data,index)}  data-toggle="modal" data-target="#addVestingScheduleModal">{__('Add vesting schedule')}</a></Menu.Item> )}
                { (noPermission('securities_EA_vestingSchedule') === false ) && ( data.type !== "ps" && isModifyVesting==1 && <Menu.Item ><a onClick={this.getVestingData.bind(this,data,index)} data-toggle="modal" data-target="#addVestingScheduleModal">{__('Modify vesting schedule')}</a></Menu.Item> )}
                { (noPermission('securities_EA_vestingSchedule') === false ) && isModifyVesting==1 && data.isMilestone==1 && data.status!="Canceled" && <Menu.Item ><a data-toggle="modal" onClick={this.getVestMilestone.bind(this,data,index)} data-target="#vestMilestonesModal">{__('Vest milestones')}</a></Menu.Item>}
                { (noPermission('securities_EA_vestingSchedule') === false ) && isModifyVesting==1 && data.isMilestone==0 && data.status!="Canceled" && <Menu.Item ><a data-toggle="modal" onClick={this.getInitVestingList.bind(this,data,index)} data-target="#accelerateVestingModal">{__('Accelerate vesting')}</a></Menu.Item>}
                { (noPermission('securities_EA_vestingSchedule') === false ) && isModifyVesting==1 && <Menu.Item ><a onClick={this.stopDetailModel.bind(this,data,index)}  data-toggle="modal" data-target="#modifyVestingConditionModal">{__('Modify vesting condition')}</a></Menu.Item>}
                {/*{data.status!="Settled" && data.status == "Expired" && data.status == "Canceled" && <Menu.Item ><a data-toggle="modal"  onClick={this.getQuantityToSettled} data-target="#settleRsuModal">Settle RSU</a></Menu.Item>}*/}
                { (noPermission('securities_EA_achieve') === false ) && data.status!="Settled" && data.status != "Expired" && data.status != "Forfeited" && data.status != "Canceled"  && isSettleable!=0 && <Menu.Item ><a data-toggle="modal"  onClick={this.getQuantityToSettled.bind(this,data,index)} data-target="#settleRsuModal">{__('Settle RSU')}</a></Menu.Item>}
                { (noPermission('securities_EA_achieve_cancel') === false ) && (canBeCanceled == 1 && <Menu.Item ><a onClick={this.stopDetailModel.bind(this,data,index)}  data-toggle="modal" data-target="#cancelModal">{__('Cancel RSU')}</a></Menu.Item> )}
                { (noPermission('securities_EA_achieve_reverse') === false ) && (reverseCancellation == 1 && <Menu.Item ><a onClick={this.reverseCancellation.bind(this,data,index)} className="reverse-btn text-muted">{__('Reverse cancellation')}</a></Menu.Item> )}
                { (noPermission('securities_EA_achieve_reverse') === false ) && (reverseCancellation == 0 && <Menu.Item className="shares-table-menu-item"><a onClick={this.stopDetailModel.bind(this,data,index)}  className="reverse-btn text-muted no-cursor">{__('Reverse cancellation')}<p className="reverse-tip">{__('Can only apply to cancelled')}<br/><span> {__('or')} </span>{__(' repurchased certificate(s).')}</p> </a></Menu.Item> )}
                { (noPermission('securities_EA_addNotes') === false ) && <Menu.Item ><a onClick={this.setInitShareNote.bind(this,data,index)} data-toggle="modal" data-target="#addAdminNotesModal">{ addOrEdit }</a></Menu.Item> }
                { (noPermission('securities_EA_resend') === false ) && (data.sign_status == "1" && <Menu.Item ><a onClick={this.resendEmail.bind(this,data,index)} >{__('Resend to officers')}</a></Menu.Item> )}
                { (noPermission('securities_EA_resend') === false ) && (data.sign_status == "2" && <Menu.Item ><a onClick={this.resendEmail.bind(this,data,index)} >{__('Resend to stakeholder')}</a></Menu.Item> )}
                { (noPermission('securities_EA_terminate') === false) && data.isCanBeTerminated == 1 && <Menu.Item ><a onClick={this.stopDetailModel.bind(this,data,index)}  data-toggle="modal" data-target="#terminateStakeholderModal">{__('Terminate stakeholder')}</a></Menu.Item>}
                { (noPermission('securities_EA_reverse_termination') === false) && isEmptyTerminationDate == 0 && <Menu.Item ><a onClick={this.reverseTermination.bind(this,data,index)}>{__('Reverse termination')}</a></Menu.Item>}

            </Menu>
        )
    }

    /**
     * table 行 鼠标移入移出
     * @param row 对象
     * @param index 下标
     * @returns {{onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave}}
     */
    onRow = (row, index) => {
        const { updateShowMsgStatus } = this.props;
        return {
            //鼠标移入
            onMouseEnter : () => {
                updateShowMsgStatus(index, true);
            },
            //鼠标移出
            onMouseLeave : () => {
                updateShowMsgStatus(index, false);
            }
        }
    }

    /**
     * 全选change事件
     * @param selectedRowKeys
     */
    onSelectChange = selectedRowKeys => {
        const { securityLists, updateSelectCount } = this.props;
        const count = selectedRowKeys.length;
        if(!count){
            updateSelectCount(count, [], true);
        }else{
            let selectIndexArray = [];
            securityLists.forEach((v, k) => {
                selectIndexArray.push({selectIndex:k, checked:true});
            })
            updateSelectCount(count, selectIndexArray);
        }
        this.setState({ selectedRowKeys }, ()=> this.props.checkSecurityStatus());
    };

    rowSelection = (selectedRowKeys) => ({
        selectedRowKeys,
        onChange: this.onSelectChange,
        renderCell : (checked, record, index, originNode) => this.securityHover(checked, record, index, selectedRowKeys)
    })

    handleTableChange = (pagination, filters, sorter) => {
        let sort = {}
        const { order, column } = sorter

        if (column?.sortName) {
            sort = {
                column: column.sortName,
                type: order
            }
            this.props.sortTable?.(sort)
        }else{
            sort = {}
            this.props.sortTable?.(sort)
        }
    }

    render() {
        const { selectedRowKeys } = this.state;
        const { securityLists, tableColumn, paginationConfig, loading } = this.props;

        return (
            <Table
                onRow={this.onRow}
                className="stake-table shares-table bigger_checkbox"
                locale={ loading && { emptyText: LOADING }}
                columns={tableColumn}
                dataSource={securityLists}
                rowSelection={this.rowSelection(selectedRowKeys)}
                rowKey="id"
                pagination={ paginationConfig ? {...paginationConfig, position:['bottomCenter']} : false}
                onChange={this.handleTableChange}
                scroll={{ y: innerHeight ? innerHeight - 280 : false }}
            />
        );
    }
}

export default RsuTable;