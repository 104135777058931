import React from 'react';
import { formatNumber, formatCurrencyNumber } from "Utils";
import EditCom from './components/EditBtn'
import { getYesOrNoText } from 'Utils/constant';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';

const renderValue = data => data || '--';
const formData = value => formatCurrencyNumber(value);
const formNumberData = value => formatNumber(value);
const formatBooleanData = value => getYesOrNoText(value)
const formatPar = value => value > 0 ? `${Number(value)}x` : '--'


const foundColumns = [
    {
      title: __('Share class'),
      dataIndex: 'share_class',
      render: renderValue
    },
    {
      title: __('Outstanding'),
      dataIndex: 'outstanding',
      render: text => formatFractionalNumber(text)
    },
    {
      title: __('Seniority'),
      dataIndex: 'seniority',
      render: renderValue
    },
    {
      title: __('Original issue price'),
      dataIndex: 'original_issue_price',
      render: formData
    },
    {
      title: __('Conversion price'),
      dataIndex: 'conversion_price',
      render: formData
    },
    {
      title: __('Conversion ratio'),
      dataIndex: 'conversion_ratio',
      render: text => formatNumber(text, 0)
    },
    {
      title: __('Multiplier'),
      dataIndex: 'multiplier',
      render: formatPar
    },
    {
      title: '',
      dataIndex: '',
      align: 'right',
      render: (status, record) => <EditCom data={record} />,
      width: 100
    }
  ];
  
  const dividendsColumns = [
    {
      title: __('Share class'),
      dataIndex: 'share_class',
    },
    {
      title: __('Cumulative'),
      dataIndex: 'cumulative',
    },
    {
      title: __('Dividend rate'),
      dataIndex: 'dividend_rate',
      render: text => renderValue(text)
    },
    {
      title: __('Dividend accrual'),
      dataIndex: 'dividend_accrual',
      render: formData
    },
    {
      title: __('Compounding status'),
      dataIndex: 'compounding_status',
      render: formData
    },
    {
      title: __('Compounding interest'),
      dataIndex: 'compounding_interest',
      render: formatBooleanData
    },
    {
      title: '',
      dataIndex: '',
      align: 'right',
      render: (status, record) => <EditCom data={record} />,
      width: 100
    }
  ];

  const participtionColumns = [
    {
      title: __('Share class'),
      dataIndex: 'share_class',
    },
    {
      title: __('Participating'),
      dataIndex: 'participating',
      render: formatBooleanData
    },
    {
      title: __('Compounding interest'),
      dataIndex: 'participation_cap',
      render: text => renderValue(text)
    },
    {
      title: '',
      dataIndex: '',
      align: 'right',
      render: (status, record) => <EditCom data={record} />,
      width: 100
    }
  ];

  export { foundColumns, dividendsColumns, participtionColumns }
