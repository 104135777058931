import React, { Component } from 'react';
import { post } from 'srcPath/http';
import { message, Table, Button, Dropdown, Menu, Row, Col, Tooltip } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import security from 'stores/security';
import { columns } from './config';
import Loading from 'components/Loading';
import { openAddCoinModal } from './components/AddCoin'
import './style.scss';
class Dashboard extends Component {
	state = {
		publicList: [],
		coinList: [],
	}

	modalRef = null

	componentDidMount() {
		this.getTokenInfo()
	}

	handleMenuClick = ({key}) => {
		if (key === 'add') {
			this.handleAddCoin()

			return
		}

		this.props.history.push({
			pathname: '/home/token/detail',
			state: key
		})
	}

	compare (property) {
		return function(a,b){
			var value1 = a[property];
			var value2 = b[property];
			return value1 - value2;
		}
	}

	getTokenInfo = async () => {
		Loading.global(true)

		try {
			const { data: resData } = await post('getCoinDashboard')

			const { code, data: { custom_coin_list = [], public_coin_list = [] } } = resData || {}
			const publicList = public_coin_list.sort(this.compare('position_num'))

			if (code === 0) {
				this.setState({
					publicList,
					coinList: custom_coin_list
				});
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}

		Loading.global(false)
	}

	handleAddCoin = () => {
		openAddCoinModal(() => { 
			security.getStatus(); 
			this.getTokenInfo()  
		})
	}

	render () {
		const { publicList, coinList } = this.state
		const { custom_coin_list = [], max_coin_num = 0 } = security.companyInfo || {}
		const showAdd = custom_coin_list.length < max_coin_num

		const menu = (
			<Menu onClick={this.handleMenuClick.bind(this)}>
				{
					custom_coin_list.map((val, indxe) => (<Menu.Item key={val.id}>{val.name}</Menu.Item>))
				}
				{
					showAdd && <Menu.Item key="add">
						+{__('Add token')}
					</Menu.Item>
				}
			</Menu>
		  )

		return <div className="token-dash-board-root" >
			<div className="token-dash-board-container">
				<div className="token-dash-board-title">
					<p>{__('Summary of Tokens')}</p>
					<Dropdown overlay={menu}>
						<Button className="sprout-btn-white">
							{__('Select token')} <DownOutlined />
						</Button>
					</Dropdown>
				</div>
				<div className="coin-list">
					<h3>{__('Today’s Tokens Prices by Market Cap')}</h3>
					<Row className="coin-container" gutter={16}>
						{publicList.map((publicCoin, index) => (
							<Col key={index} flex={1}>
								<div className="coin-list-item">
									<div className="coin-list-left">
										<span className="coin-list-left-order">{publicCoin.position_num}</span>
										<span className="coin-list-left-icon"><img src={publicCoin.image} /></span>
										<span className="coin-list-left-name">{publicCoin.name}</span>
										<span className="coin-list-left-short-name">{publicCoin.abbr}</span>
									</div>
									<div className="coin-list-right">
										<div className="coin-list-right-rise-area rise-area">
										{`${publicCoin.percentage}%`}
										</div>
									</div>
								</div>
							</Col>
						))}
					</Row>
				</div>
				<div className="coin-table">
					<div className="coin-table-header sprout-flex">
						<h3>{__('Token')}</h3>
						{
							showAdd ? <Button type="primary" onClick={this.handleAddCoin} >{__('Add token')}</Button> : <Tooltip title={`${__('The maximum number of tokens you can add is')} ${max_coin_num}`}>
								<Button type="primary" disabled >{__('Add token')}</Button>
							</Tooltip>
						}
					</div>
					<div className="coin-table-body">
						<Table 
							size="small" 
							rowKey="id" 
							columns={columns} 
							dataSource={coinList} 
							pagination={false} 
							scroll={{ x: true }}
						/>
					</div>
				</div>
			</div>
		</div>
	}
}

export default Dashboard