import React, { Component } from 'react';
import { Col, Row, Table, Spin, message, Tooltip, Button } from 'antd';
import MessageCard from 'components/MessageCard';
import RoundTags from 'components/RoundTags'
import { post } from 'srcPath/http';
import { formatNumber, formatPercent, formatCurrencyNumber, calculator } from 'Utils';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import FundingHistory from './components/FundingHistory';
import Valuation from './components/Valuation';
import SummaryCharts from './components/SummaryChart';
import { noPermission } from 'components/Permission';
import security from 'stores/security';
import { formatDate } from 'Utils/format/formatDate';
import Loading from 'components/Loading';
import Fairmarketvalue from '../../Compliance/FairMarketValue';
import { printf } from 'Utils/i18n';
import { certificateStatus } from "Utils/constant";

import './style.scss';

const transactionColumns = [
	{
		title: __('Security'),
		dataIndex: 'custom_label_id',

	},
	{
		title: __('Transaction'),
		dataIndex: 'transaction_type',
		render: (text, record) => certificateStatus[text]
	},
	{
		title: __('Date'),
		dataIndex: 'create_time',
		width: 180,
		align: 'center',
		render: text => formatDate(text && text.split(' ')?.[0])
	},
	{
		title: __('Quantity'),
		dataIndex: 'quantity',
		align: 'right',
		render: value => formatFractionalNumber(value)

	},
]

export default class Summary extends Component {
	state = {
		roundTags: [],
		dataInfo: {
			stakeholder_number: null,
			transaction: [],
			top_stakeholder: [],
			total: {
				total: null,
				cashRaised: null,
			},
			share_prefix: []
		},
		isShowOnboarding: false
	}

	getHolderColumns = () => {
		const { dataInfo = {} } = this.state
		const { total = {} } = dataInfo

		return [
			{
				title: __('Name'),
				dataIndex: 'stakeholder_name',
				ellipsis: true

			},
			{
				title: __('Rounds'),
				dataIndex: 'prefix',
				ellipsis: true,
				render: value => { value = typeof value === 'string' ? [value] : value; return <Tooltip title={<RoundTags isPick allTags={dataInfo.share_prefix || []} tags={value} />}><span><RoundTags allTags={dataInfo.share_prefix || []} tags={value} /></span></Tooltip> },
				width: '40%'

			},
			{
				title: __('Ownership'),
				dataIndex: 'quantity',
				align: 'right',
				width: 100,
				render: value => formatPercent(value / (total.total || 1))
			}
		]
	}

	componentDidMount() {
		this.getData()
	}

	getData = async () => {
		Loading.global(true)

		try {
			const { data } = await post('overviewAdmin')

			if (data.code === 0) {
				this.setState({ dataInfo: data.data })
			} else {
				message.error(data.data)
			}
		} catch (e) {
			message.error(e.message)
		}

		Loading.global(false)
	}

	gotoLink = path => {
		this.props.history.push(path)
	}

	componentWillUnmount() {
		this.modalRef && this.modalRef.close()
	}

	render() {
		const { dataInfo } = this.state
		const { total = {}, stakeholder_number, stakeholder_active_number  } = dataInfo || {}
		const {minus} = calculator;
		const stakeholderInfoA = __('Stakeholders and/or tokenholders that own share option awards, equity, warrants, convertible notes, token warrants or token vestings.')
		const stakeholderInfoB = printf(__('Total stakeholders: %s'), stakeholder_number)
		const stakeholderInfoC = printf(__('Deactivated stakeholders: %s'), minus(stakeholder_number, stakeholder_active_number))
		const stakeholderInfo = <p>{stakeholderInfoA}<br/>{stakeholderInfoB}<br/>{stakeholderInfoC}</p>
		// 判断用户是否属于 Esop admin 或者 Captable admin
		const flag = security.companyInfo && security.companyInfo.flag
		const isEsopOrCaptableAdmin = flag === '5' || flag === '6'

		return <div className="content">
			<div className="admin-summary-root">
				<div className="admin-summary-section">
					<div className="admin-summary-banner">
						<Row gutter={20}>
							<Col span={8}>
								{/*<MessageCard title={__('Stakeholders')} color="#63c2b0" icon={!noPermission('stakeholders_allStakeholders')} onIconClick={() => this.gotoLink('/home/stakeholders/stakeholders-list')}>*/}
								<MessageCard title={__('Active stakeholders')} stakeholderInfo={stakeholderInfo} color="#63c2b0" icon={!noPermission('stakeholders_allStakeholders')} onIconClick={() => this.gotoLink('/home/stakeholders/stakeholders-list')}>
									<div className="banner-content">
										{formatNumber(stakeholder_active_number, 0)}
									</div>
								</MessageCard>
							</Col>
							<Col span={8}>
								<MessageCard title={__('Fully Diluted Shares')} color="#e1d148" icon={!noPermission('captable_byShareClass')} onIconClick={() => this.gotoLink('/home/captable/byshareclass')}>
									<div className="banner-content">
										{formatFractionalNumber(total.total)}
									</div>
								</MessageCard>
							</Col>
							<Col span={8}>
								<MessageCard title={__('Total Cash Raised')} color="#d1d84c" icon={!noPermission('fundraising_finacingHistory')} onIconClick={() => this.gotoLink('/home/fundraise/financing-history')}>
									<div className="banner-content">
										{formatCurrencyNumber(total.cashRaised)}
									</div>
								</MessageCard>
							</Col>
						</Row>
					</div>
				</div>
				{/* 新增两个图表 */}
				{	/* 非 esop 或是 captable  admin 显示这部分内容 */
					!isEsopOrCaptableAdmin && <div className="admin-summary-section">
						<Row gutter={20} type="flex">
							<Col span={12} flex={1}>
								<MessageCard title={__('Cap Table Summary')} color="#f7f8fc" icon={!noPermission('captable')} bannerHeight={50} onIconClick={() => this.gotoLink('/home/captable')}>
									<div className="summary-table">
										<SummaryCharts />
										{/* <Table size="small" columns={this.getHolderColumns()} rowKey="stakeholder_id" dataSource={dataInfo.top_stakeholder || []} pagination={false} /> */}
									</div>
								</MessageCard>
							</Col>
							<Col span={12}>
								<MessageCard title={__('Fair market value')} color="#f7f8fc" icon={!noPermission('stakeholders_transactions')} bannerHeight={50} onIconClick={() => this.gotoLink('/home/stakeholders/stakeholder-transactions')} >
									<div className="summary-table">
										<Fairmarketvalue isHideTable />
										{/* <Table size="small" columns={transactionColumns} rowKey="id" dataSource={dataInfo.transaction || []} pagination={false} /> */}
									</div>
								</MessageCard>
							</Col>
						</Row>
					</div>
				}

				{	/* 非 esop 或是 captable  admin 显示这部分内容 */
					!isEsopOrCaptableAdmin && <div className="admin-summary-section">
						<Row gutter={20} type="flex">
							<Col span={12}>
								<MessageCard title={__('Latest Transactions')} color="#f7f8fc" icon={!noPermission('stakeholders_transactions')} bannerHeight={50} onIconClick={() => this.gotoLink('/home/stakeholders/stakeholder-transactions')} >
									<div className="summary-table">
										<Table size="small" columns={transactionColumns} rowKey="id" dataSource={dataInfo.transaction || []} pagination={false} />
									</div>
								</MessageCard>
							</Col>
							<Col span={12} flex={1}>
								<MessageCard title={__('Cap Table Summary')} color="#f7f8fc" icon={!noPermission('captable')} bannerHeight={50} onIconClick={() => this.gotoLink('/home/captable')}>
									<div className="summary-table">
										<Table size="small" columns={this.getHolderColumns()} rowKey="stakeholder_id" dataSource={dataInfo.top_stakeholder || []} pagination={false} />
									</div>
								</MessageCard>
							</Col>
						</Row>
					</div>
				}
				<div className="admin-summary-section">
					<Row gutter={20} type="flex">
						<Col span={12}>
							<MessageCard title={__('Funding History')} color="#f7f8fc" bannerHeight={50} icon={!noPermission('fundraising_finacingHistory')} onIconClick={() => this.gotoLink('/home/fundraise/financing-history')}>
								<Valuation />
							</MessageCard>
						</Col>
						<Col span={12} flex={1}>
							<MessageCard title={__('Valuation')} color="#f7f8fc" bannerHeight={50} icon={!noPermission('fundraising_finacingHistory')} onIconClick={() => this.gotoLink('/home/fundraise/financing-history')}>
								<FundingHistory />
							</MessageCard>
						</Col>
					</Row>
				</div>
			</div>
		</div >
	}
}