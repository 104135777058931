import React, { Component } from 'react';
import Empty from 'components/Empty';
import './style.scss';

export default function SimpleTable(props) {
	const dataConfig = props.config || []

	return <div className="sprout-simple-table">
		<div className="sprout-simple-table-banner">{ props.title }</div>
		<div className="sprout-simple-table-content">
			{
				dataConfig.length > 0 ?  dataConfig.map((val, index) => 
				!val.hide && 
				<div className="sprout-simple-table-content-item" key={index}>
					<div className="sprout-simple-table-content-label" style={{ width: props.rate || '30%' }}>{val.label}</div>
					{!val?.url && <div className="sprout-simple-table-content-value">{val.value || '--'}</div>}
					{val?.url && <div className="sprout-simple-table-content-value">{val.value ? <a target="_blank" href={val.url}>{val.value}</a> : '--' }</div>}
				</div>
				) : <Empty />
			}
		</div>
	</div>
}