import React from 'react';
import { Select } from 'antd';

export const terminationTypeList = [

    {
        label: __('Voluntary'),
        value: 'Voluntary'
    },
    {
        label: __('Involuntary'),
        value: 'Involuntary'
    },
    {
        label: __('With cause'),
        value: 'With cause'
    },
    {
        label: __('Disability'),
        value: 'Disability'
    },
    {
        label: __('Retirement'),
        value: 'Retirement'
    },
    {
        label: __('Death'),
        value: 'Death'
    }
]

export default function TerminationTypeSelector(props)  {

    return <Select showSearch allowClear placeholder={__('Choose a reason')} {...props}>
        {
            terminationTypeList.map((val, index) => <Select.Option key={index} value={val.value}>{val.label}</Select.Option>)
        }
    </Select>
}

export function mapTerminationType(val) {
    return terminationTypeList.find((item) => item.value === val)?.label || '--'
}