import React, { Component } from "react";
import { PDFReader } from 'react-read-pdf';
import {message, Spin} from "antd";
import { post } from 'srcPath/http';

class PdfPreview extends Component {

    state = {
        loading: false,
        pageCount: 1,
    }

    componentDidMount() {
        this.getTemplateInfo();
    }

    getTemplateInfo = async () => {
        const { id, postData } = this.props;
        const subData = postData ? postData : {id};
        this.setState({ loading: true })
        try {
            const { data: resData } = await post('getTemplatePreviewById', subData)
            const { data, code } = resData || {}
            if (code == 0) {
                this.setState({
                    pdfFile: data && atob(data),
                })
            } else {
                message.error(data)
            }
        } catch(err) {
            message.error(err && err.message)
        }
        this.setState({ loading: false })
    }

    pushTemplateInfo = (pageCount) => {
        this.setState({pageCount})
    }

    getTemplatePage = () => {
        const { pageCount, pdfFile } = this.state;
        const pageArray = [...new Array(pageCount).keys()];
        return pdfFile && pageArray.map((v, k)=>{
            return <PDFReader key={k} data={pdfFile} width={800} page={(k+1)} onDocumentComplete={this.pushTemplateInfo}/>
        })
    }

    render() {
        const { loading } = this.state;
        return (
            <Spin spinning={loading}>
                <div className="pdf-preview-content">{this.getTemplatePage()}</div>
            </Spin>
        );
    }
}
export default PdfPreview;