import { message } from 'antd';
import axios from 'axios';
import security from 'stores/security';
import ui from 'stores/ui';
import crypto from 'crypto-js';

const isDevelop = process.env.NODE_ENV === 'development'

export const post = (actionType, data = {}, ...args) => {
	return axios.post(security.api, {
		action: actionType,
        data
	}, ...args)
}

export const get = (actionType, data) => {
	return axios.get(security.api, {
		params: {
			data,
		}
	})
}

function dataSerializer(data) {
    let key;
    const result  = [];

    if (typeof data === 'string') {
        return data;
    }

    for (key in data) {
        if (data.hasOwnProperty(key) && data[key]) {
            result.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]));
        }
    }

    return result.join('&');
}


axios.interceptors.request.use(config => {
    if (!config.timeout) {
        config.timeout = 600 * 1000;
    }

    if (config.useForm && (config.method === 'post' || config.method === 'put')) {
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        config.transformRequest = dataSerializer;
    }

    const expire =  localStorage.getItem('SPROUT_EXPIRE')

    if (expire && (+ new Date() > expire) && !security.sessionExpired) {

        security.sessionExpired = true

        message.error(__('Your session has expired. Please login again.'), 2)

        security.clear()

        // setTimeout(security.redirect, 2000)
    }

    const company_id = security.companyId
    const uid = security.userInfo && security.userInfo.id

    config.data = Object.assign(
        {
            company_id,
            uid,
            lang: ui.lang

           // other global params
        },
        config.data,
    );

    const url = config.url

    if (isDevelop) {
        config.url = security.api
    } else {
        config.url = url === '/checkToken.php' ?  url : '/request.php'
    }

    if (!config.useForm) {
        config.headers.s = crypto.MD5(`Sprout_${JSON.stringify(config.data)}`).toString()
    }
    
    return config
})

axios.interceptors.response.use(response => {
    const { data = {} } = response
    const { code } = data || {};

    if(code === 10000 && !(security.sessionExpired)) {
        security.sessionExpired = true
        message.error(__('Your session has expired. Please login again.'), 5)

        security.clear()
    }

    return response
})


export default axios;