import React from 'react';
import 'assets/css/dataroommodal.css';
import 'assets/css/addfolder.css';
import axios from 'axios';
import Utils from "Utils";
import { Upload, Button, Tooltip, message } from 'antd';
import { cloneDeep } from 'lodash';
import classnames from 'classnames';
import { FolderOutlined } from '@ant-design/icons';
import uploadIcon from '../images/upload-icon.png'
import fileIcon from '../images/file-icon.png'
import './upload.scss'
import { post } from 'srcPath/http';
import security from 'stores/security';

const { Dragger } = Upload;
class UploadFiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uploadFiles: [],
            data: {
                parent_id: 0,
            },
            finishUpload: false,
            showName: "",
            uploadProgress: 0,
            sendingStatus: 0,
            testFileList: []
        }

    }

    componentDidMount() {
        const { dirInfo } = this.props
        const { id, name } = dirInfo || {}
        this.setParentId(id, name)
    }
    setParentId = (parentId, showName) => {
        let curData = this.state.data;
        curData.parent_id = parentId || 0;
        this.setState({
            data: curData,
            showName: showName
        });
        // console.log(parentId);
    }
    minus = (key) => {
        let curFiles = this.state.uploadFiles;
        curFiles.splice(key, 1);
        this.setState({
            uploadFiles: curFiles
        })
    }

    uploadFiles = async () => {
        if (this.state.uploadFiles.length === 0) {
            return message.error('Please select a file first')
        }
        if (this.state.sendingStatus === 1) {
            return;
        }
        this.setState({
            sendingStatus: 1
        });

        let uploadFiles = this.state.uploadFiles;

        try {
            const { data } = await post('newAddBoardDocumentFile', {
                parent_id: this.props.dirInfo.id || 0,
                owner: security.userInfo.user_name,
                dir_info: uploadFiles
            })
            if (data.code === 0) {
                var curDirInfo = this.props.dirInfo;
                this.props.onReload && this.props.onReload('Upload', curDirInfo)
                message.success(__("Success!"));
            } else {
                message.error(__('upload failed'));
            }

        } catch (e) {
            message.error(e.message)
        }
    }
    handleBeforeUploadFolders = (file, fileList) => {
        let testFileList = this.state.testFileList
        testFileList.push(file)
        if (testFileList.length == fileList.length) {
            let isLt100M = 0
            fileList.forEach(item => isLt100M += item.size)
            isLt100M = isLt100M / 1024 / 1024 < 100;
            if (!isLt100M) {
                message.error('Files larger than 100 MB are not currently supported.')
                return
            }
            this.getFileList(testFileList)
            this.state.testFileList = []
        }
    }
    getFileList = (fileList) => {
        let fileArray = []
        fileList.map((i) => {
            const { webkitRelativePath } = i;
            // 分解文件路径
            const dirList = webkitRelativePath?.split('/')
            let temporary = '.DS_Store'
            let iswenjian = dirList.findIndex(item => item == temporary)
            if (iswenjian === -1) {
                dirList.forEach((item, idx) => {
                    // 最后一个是文件
                    if (dirList.length - 1 === idx) {
                        let parentName = ''
                        let petName = webkitRelativePath
                        dirList.forEach((item, i) => {
                            parentName += (dirList.length - i) > 1 ? item + '/' : ''
                        })
                        Utils.uploadFile(i).then((result) => {
                            fileArray.push({
                                parentName,
                                petName,
                                name: item,
                                file_obj: result,
                                size: i.size,
                                fileType: "file",
                                owner: security.userInfo.user_name,
                            })
                        });
                    } else {
                        // 文件夹名
                        if (idx === 0) {
                            let isIdx = fileArray.findIndex(item => item.petName == item + '/')
                            if (isIdx === -1) {
                                fileArray.push({
                                    parentName: '',
                                    petName: item + '/',
                                    name: item,
                                    file_obj: '',
                                    size: i.size,
                                    fileType: "dir",
                                    fileList: [],
                                    owner: security.userInfo.user_name,
                                })
                            }
                        } else {
                            let parentName = ''
                            let petName = ''
                            dirList.forEach((item, idxs) => {
                                parentName += idxs < idx ? item + '/' : ''
                                petName += idxs <= idx ? item + '/' : ''
                            })
                            let isIdx = fileArray.findIndex(item => item.petName == petName)
                            if (isIdx == -1) {
                                fileArray.push({
                                    parentName,
                                    petName,
                                    name: item,
                                    file_obj: '',
                                    size: i.size,
                                    fileType: "dir",
                                    owner: security.userInfo.user_name,
                                })
                            }
                        }

                    }
                })
            }
        })
        setTimeout(() => {
            this.setState({
                uploadFiles: [...this.state.uploadFiles, this.convert(fileArray)]
            })
        }, 1000);
    }
    convert(data) {
        let result;
        let map = {};
        data.forEach(item => {
            map[item.petName] = item;
        });
        data.forEach(item => {
            let parent = map[item.parentName];
            if (parent) {
                (parent.fileList || (parent.fileList = [])).push(item);
            } else {
                result = item;
            }
        });
        return result;
    }
    handleBeforeUpload = (file, fileList) => {
        const isLt500M = file.size / 1024 / 1024 < 100;
        if (!isLt500M) {
            return message.error('Files larger than 100 MB are not currently supported.')
        }
        const fileArray = cloneDeep(this.state.uploadFiles)
        fileList.map((i) => {
            Utils.uploadFile(i).then((result) => {
                fileArray.push({
                    name: i.name,
                    file_obj: result,
                    size: i.size,
                    fileType: 'file',
                    owner: security.userInfo.user_name,
                })
                this.setState({
                    uploadFiles: fileArray
                })
            });
        })
        return false
    }
    handleDropCLick = (event) => {
        event.preventDefault();
        event.stopPropagation();
    }

    handleDropFile = (e) => {
        console.log('Dropped files', e.dataTransfer.files);
    }
    render() {
        const { uploadFiles } = this.state
        const { close } = this.props
        let showName = "";
        if (this.state.showName?.length !== 0) {
            showName = this.state.showName;
        } else {
            showName = this.props.dirInfo?.name;
        }
        return (
            <div>
                <div>
                    <div className="modal-content box-content data-room-upload-modal">
                        <div className="modal-header box-header">
                            <button type="button" className="close shut-btn" onClick={close}>×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Upload')}</h4>
                        </div>
                        <div className="modal-body folder-body">
                            <div className="folder-body-header">
                                <p className="text-muted">{__('Documents will be added to')}:</p>
                                <p className="text-muted"><span className="text-muted glyphicon"><FolderOutlined /></span>&nbsp;&nbsp;{showName || __("Documents drive")}</p>
                            </div>


                            <div className="select-files delRowMargin row">
                                <div className={"form-group no-paddingleft show"}>
                                    <Upload action="" multiple={true} beforeUpload={this.handleBeforeUpload} showUploadList={false}>
                                        <Button type="primary" className="btnWid">{__('Browser files')}</Button>
                                    </Upload>
                                    <Upload directory action="" multiple={true} beforeUpload={this.handleBeforeUploadFolders} showUploadList={false}>
                                        <Button type="primary" className="folders-btn btnWid">{__('Browser folders')}</Button>
                                    </Upload>
                                    <Dragger className={classnames('file-upload-drapper', { 'file-upload-drapper-active': !!uploadFiles.length })} onDrop={this.handleDropFile} multiple={true} beforeUpload={this.handleBeforeUpload} showUploadList={false} onClick={this.handleDropCLick}>
                                        <p className="ant-upload-text">{__('or drop documents here')}</p>
                                        <img src={uploadIcon} />
                                        <p className="ant-upload-info">{__('We accept doc, pdf, xls, video and more. Max 100 MB.')}</p>
                                    </Dragger>

                                    {uploadFiles.map((i, index) => (
                                        <p className="file-upload-list">
                                            <Tooltip title={i.name} color="#2b3946">
                                                <span className="file-upload-name-area">{i.fileType == 'file' ? <img style={{ width: '15px', height: '20px', marginRight: '5px' }} src={fileIcon} /> : <FolderOutlined style={{ width: '15px', height: '20px', marginRight: '5px' }} />}{i.name}</span>
                                            </Tooltip>
                                            <span className="file-icon-remove" onClick={this.minus.bind(this, index)}>一</span>
                                        </p>
                                    ))}

                                    {/* {!uploadFiles.length && <p style={{ color: '#999999', marginTop: '20px' }}>* No file selected</p>} */}

                                </div>
                            </div>
                            {this.state.uploadProgress > 0 &&
                                <div className="select-progress ">
                                    <div className="col-xs-12 files">
                                        <div className="progress file-progress no-marginBottom">
                                            <div className="progress-bar progress-bar-info progress-noshadow" role="progressbar" aria-valuenow="60"
                                                aria-valuemin="0" aria-valuemax="100" style={{ width: this.state.uploadProgress + "%" }}>
                                                <span className="sr-only">{this.state.uploadProgress}% {__('Complete')}</span>
                                            </div>
                                        </div>
                                        <div className="progress-tip">{this.state.uploadProgress}% {__('Complete')}</div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="modal-footer box-footer no-paddingtop">
                            <button id="dataroomModalButton" type="button" className="btn boxbtn-to bg-darkblue update-btn hidden" data-dismiss="modal">{__('Upload')}</button>
                            <button onClick={this.uploadFiles} type="button" className="btn boxbtn-to bg-darkblue update-btn">{__('Upload')}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default UploadFiles;
