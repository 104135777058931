import React from 'react';
import { Link } from "react-router-dom";
import Utils, { I } from "Utils";
import { post } from 'srcPath/http';
import Loading from 'components/Loading';
import { Route } from "react-router-dom";
import AddVestingSchedule from "../components/AddVestingSchedule";
import TransferSafe from "./ConvertibleModal/TransferSafe";
import CancelModal from "../components/CancelModal";
import AddAdminNotes from "../components/AddAdminNotes";
import EditShareClasses from "../components/EditShareClasses";
import RequestStockSplit from "../components/RequestStockSplit";
import ModifyConvertibleModal from "./ConvertibleModal/ModifyConvertibleModal";
import EditConvertibleTerms from "./ConvertibleModal/EditConvertibleTerms";
import TermsList from "./ConvertibleModal/TermsList";
import ActionCancelModal from "../components/ActionCancelModal";
import CreateCnTermsModal from "./ConvertibleModal/CreateCnTermsModal";
import Permission from 'components/Permission';
import { formatPercent } from 'Utils';
import {Empty, Input, message, Badge, Dropdown, Button, Menu} from 'antd';
import TransactionRender from 'B/components/TransactionHistory';
import ConvertibleTable from "./ConvertibleModal/ConvertibleTable";
import redeemToCashModal from './components/RedeemToCash'

import 'assets/css/securities.css';
import 'assets/css/stakeholders.css';
import '../../../../assets/css/board.css';
import {CloseOutlined, DownOutlined, SearchOutlined} from "@ant-design/icons";
import ColumnController, { columnsFilter } from "components/ColumnController";
import Filter from 'components/Filter';
import { printf } from 'Utils/i18n';

const orderMap = {
    null: 0,
    ascend: 4,
    descend: 3,
    undefined: 0
}

class Convertibles extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            checkedIds:[],
            cnPageSize:100,
            current:1,
            currency:"",
            curSelectRelation:"all",
            vestingScheduleList:[
                {id:1,schedule_name:"abc1"},
                {id:2,schedule_name:"ddd2"}
            ],
            cnTermsList:[],
            securityLists : [],
            checkedSecurity:[],
            canBeCanceled:1,
            isShowCancelTip:0,
            // showCancelTip:0,
            curSelectSecurity:{
                // key:0,
                // id:2,
                // type:"cn",
                // custom_label_id:'CN-666',
                // name:'Cody Belseager',
                // email:"bb",
                // custom_label:"",
                // principal:'750,000.00',
                // interest_rate:'1.00%',
                // interest_start_date:'31/05/2019',
                // interest_accrued:'1',
                // total_amount:'750,000.00',
                // quantity:'100',
                // init_email: "78420344@qq.com",
                // status: "Outstanding",
                // checkbox_status: "unchecked",
                // sign_status: "holderSignature",
                admin_note:"",
                purchase_agreement_file:{name:"",real_name:""},
                purchase_agreement:{name:"",real_name:""},
                form_of_convertible:{name:"",real_name:""},

            },
            pageData:{
                name:"Convertible",
                title:"Convertible",
                text:"convertible",
                active_cancel_name:"Convertible",
                dayCountList:[
                    "30/360",
                    "Actual/365",
                    "Actual/360",
                ],
                interestAccrualPeriodList:[
                    "Daily",
                    "Weekly",
                    "Monthly",
                    "Monthly(first day of month)",
                    "Semi-annually",
                    "Annually",
                    "Quarterly(calendar year)",
                    "Semi-annually(calendar year)",
                    "Annually(calendar year)",
                ],
                interestCompoundingPeriodList:[
                    "Simple interest",
                    "Daily",
                    "Monthly",
                    "Semi-annually",
                    "Annually",
                    "Quarterly(calendar year)",
                ],
                interestPayoutList:[
                    "Deferred",
                    "Cash",
                ],
            },
            tableChild : {},
            searchValue: '',
            selectId:0,
            searchSecurityLists: [],
            draftConvertiblesCount: '',
            formatSecurityList:[],
            activeBtnDisable:0,
            tableColumn: [],
            redisKey: 'Convertibles',
            // 翻页参数，搜索条件
            total: 0,
            pageNumber: 1,
            pageSize: 100,
            filter: {},
            sort: {}
        }
    }
    componentDidMount() {
        this.getCnList()
    }

    changeStatus=(num)=>{
        var securityLists = this.state.securityLists;
        if(securityLists[num].checkbox_status === false){
            securityLists[num].checkbox_status = true;
        }else{
            securityLists[num].checkbox_status = false;
            $('#check01').prop("checked",false);
        }
        this.setState({
            securityLists:securityLists,
            searchSecurityLists: securityLists,
        })
    }

    getCnList=()=>{
        let pageSize = localStorage.getItem('cnPageSize') || this.state.pageSize;
        this.setState({
            pageSize,
            loading: true
        })
        let { pageNumber, filter, searchValue, sort } = this.state;

        Loading.global(true)
        post('getCnList',{ pageSize, pageNumber, filter, search: searchValue, sort: { ...sort, type: orderMap[sort?.type] || 0 } }).then((response)=> {
            if(response.data.code === 0){
                let searchList = [...response.data.data.securityLists];
                let searchRes = [];
                searchList.map( ( v, k) => {
                    if( v ){
                        searchRes.push(v);
                    }
                });
                for(let i=0;i<searchRes.length;i++){
                    searchRes[i].checkbox_status = false;
                }
                let formatSecurityList = this.state.formatSecurityList;
                for(let i=0;i<searchRes.length;i++){
                    formatSecurityList[searchRes[i].id]=searchRes[i];
                }
                this.setState({
                    cnPageSize:pageSize,
                    total:response.data.data.total,
                    securityLists:searchRes || [],
                    searchSecurityLists: searchRes || [],
                    currency:response.data.data.currency,
                    stakeholders:response.data.data.stakeholders,
                    dateOfIncorporation:response.data.data.date_of_incorporation,
                    vestingScheduleList:response.data.data.vestingScheduleList,
                    formatSecurityList,
                    cnTermsList:response.data.data.cnTermsList,
                    draftConvertiblesCount:response.data.data.draftConvertiblesCount,
                    filter,
                    loading: false
                })
                this.clear()
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
            Loading.global(false)
            this.setState({ loading: false })
        }).catch(function (error) {
            this.setState({ loading: false })
            Loading.global(false)
        });
    }

    /**
     * 更新table checkbox
     * @param selectCount 选中总计
     * @param selectIndexArray 有更新的checkbox下标和状态
     * @param isEmpty checkbox是否为全不选状态
     */
    updateSelectCount = (selectCount, selectIndexArray, isEmpty = false) => {
        const { securityLists } = this.state;
        let list = [...securityLists];
        if(isEmpty){
            list.forEach( (v, k) =>{
                list[k]['checkbox_status'] = false;
            })
        }else{
            selectIndexArray.forEach( (v, k) => {
                list[v.selectIndex]['checkbox_status'] = v.checked;
            })
        }
        this.setState(() => ({
            selectCount,
            selectIndex : selectIndexArray[0],
            securityLists : list,
        }))
    }

    clear=()=>{
        const { tableChild } = this.state;
        var securityLists = this.state.securityLists;
        $('.listCheckbox').prop('checked',false);
        $('#check01').prop("checked",false);
        for(let i=0;i<securityLists.length;i++){
            securityLists[i].checkbox_status = false;
        }
        this.setState({
            securityLists:securityLists,
            searchSecurityLists: securityLists,
        })
        tableChild.clearAll();
    }
    clearInput=()=>{
        this.refs.inputContent.value = "";
    }


    removeTip=()=>{
        window.$(".signTipBox").addClass("removeTip");
    }
    showCertificateTip=()=>{
        window.$(".cancel_pop").removeClass("hidden");
    }


    setInitShareNote=()=>{
        this.refs.addSharesAdminNoteCn.setInitNote();
    }
    deselectConflictingRows=()=>{
        var unAvailabelStatusList = {"Canceled":1,"Converted":1,"Transfered":1};
        for(let i=0;i<this.state.securityLists.length;i++) {
            if(this.state.securityLists[i].checkbox_status == true){
                let curStatus = this.state.securityLists[i].status;
                if(curStatus in unAvailabelStatusList){
                    this.state.securityLists[i].checkbox_status = false;
                    this.setState({
                        isAbleCancel:1,
                        showCancelTip:0
                    })
                }
            }
        }
    }
    getCheckedKey=()=> {
        let unAvailabelStatusList = {"Canceled":1,"Converted":1,"Transfered":1};
        for(let i=0;i<this.state.securityLists.length;i++){
            if(this.state.securityLists[i].checkbox_status==true){
                let curStatus = this.state.securityLists[i].status;
                if(curStatus in unAvailabelStatusList){
                    this.setState({
                        isAbleCancel:0
                    })
                }
            }
        }
    }
    cnReverseCancellation=()=>{
        if(Utils.isLocal()){
            let curSelectSecurity = this.state.curSelectSecurity;
            this.resetSecurityStatus(curSelectSecurity.id,"Outstanding");
            return;
        }
        var requestData = {};
        requestData.id = this.state.curSelectSecurity.id;
        requestData.security_type = this.state.curSelectSecurity.security_type;

        Loading.global(true)
        post('reverseCancellation', requestData ).then((response)=> {
            if(response.data.code === 0){
                this.resetSecurityStatus(requestData.id,"Outstanding");
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
            Loading.global(false)
        }).catch(function (error) {
            console.log(error);
            Loading.global(false)
        });
    }
    loadCnExistingData=()=>{
        this.refs.createCnTerms.setExistingPrefix();
    }

    setModifiedSecurity=(key,security)=>{
        // console.log("debug---",key,security)
        let curSecurityList = this.state.securityLists;
        curSecurityList[key] = security;
        this.setState({
            securityLists:curSecurityList,
            searchSecurityLists: curSecurityList,
        })
    }

    setDataForModifiedSecurity=(id,security)=>{
        let curSecurityLists = this.state.securityLists;
        curSecurityLists.forEach( (v, k)=>{
            if(v.id == id){
                curSecurityLists[k] = security;
            }
        })
        this.setState({
            securityLists:curSecurityLists,
            searchSecurityLists: curSecurityLists
        })
    }
    // setCurSelectSecurity=(key)=>{
    //     //把key先传进去方便返回的时候修改
    //     this.state.securityLists[key].key=key;
    //     this.setState({
    //         curSelectSecurity:this.state.securityLists[key]
    //     });
    // }
    //resetSecurityStatus
    resetSecurityStatus = (id,status) =>{
        let securityLists = this.state.securityLists;
        securityLists.forEach( (v, k)=>{
            if(v.id == id){
                v.status = status
            }
        })
        this.setState({
            securityLists:securityLists
        })

    }

    //Id定位security
    setCurSelectSecurityNew = (id) => {
        const { securityLists } = this.state;
        let info = {};
        securityLists.forEach( (v, k)=>{
            if(v.id == id){
                info = {...v}
            }
        });
        this.setState({
            curSelectSecurity: info
        });
    }

    saveState = (key, value) => {
        let data = {};
        data[key] = value;
        this.setState(data)
    }

    //resetCancel
    resetCancel = (id,status,cancellation_date) =>{
        let securityLists = this.state.securityLists;
        securityLists.forEach( (v, k)=>{
            if(v.id == id){
                v.status = status;
                v.cancellation_date = cancellation_date
            }
        })
        this.setState({
            securityLists:securityLists
        })

    }

    setSelectId = (id) =>{
        const { formatSecurityList } = this.state;
        this.setState({
            selectId: id[0]
        });
        for(let i = 0; i < id.length; i++){
            if(formatSecurityList[id[i]].status != 'Transferred'){
                this.setState({
                    activeBtnDisable : 0
                })
            }else{
                this.setState({
                    activeBtnDisable : 1
                })
                return;
            }
        }

    }
    getTermsList=()=>{
        this.refs.convertibleTermsList.getCnTermsList();
    }
    changeCancelNum = () => {
        const { securityLists, checkedIds } = this.state;
        this.refs.cancelModal.changeCancelNum();
        for(let i=0; i<securityLists.length; i++){
            if(securityLists[i].checkbox_status){
                checkedIds.push(securityLists[i].id)
            }
        }
        this.setState({
            checkedIds
        })
    }
    checkSecurityStatus=()=>{
        let curList = this.state.securityLists;
        let flag =1;
        for (let i=0;i<curList.length;i++){
            if(curList[i].checkbox_status == true && curList[i].status!="Outstanding" ){
                flag = 0
            }
        }
        if(flag == 0){
            this.setState({
                canBeCanceled:0
            })
        }else{
            this.setState({
                canBeCanceled:1
            })
        }

    }
    resendEmail = ()=>{
        if(this.state.sendingStatus === 1){
            return;
        }
        this.setState({
            sendingStatus:1
        });

        Loading.global(true)
        post('sendEmail2Signatories',{security_id: this.state.curSelectSecurity.id}).then((response)=> {
            if(response.data.code === 0){
                message.success(__('Resent successfully!'));
                this.setState({
                    sendingStatus:0
                });
            }else{
                message.error("failed, error code is: "+response.data.code);
            }
            Loading.global(false)
        }).catch(function (error) {
            message.error(error);
            Loading.global(false)
        });
    }
    showCertificateTip=()=>{
        // window.$(".cancel_pop").removeClass("hidden");
        this.setState({
            isShowCancelTip:1
        })
    }
    deselect=()=>{
        let securityLists = this.state.securityLists;
        $('#check01').prop("checked",false);
        for(let i=0;i<securityLists.length;i++){
            if(securityLists[i].status=="Canceled"||securityLists[i].status=="Transferred"||securityLists[i].status=="Converted"){
                securityLists[i].checkbox_status=false
            }
        }
        this.setState({
            securityLists:securityLists,
            searchSecurityLists: securityLists,
            canBeCanceled:1,
            isShowCancelTip:0,
        })
    }
    selectModifyKey=()=>{
        let selectKey = "";
        for(let j=0;j<this.state.securityLists.length;j++){
            if(this.state.securityLists[j].checkbox_status === true){
                selectKey = j
            }
        }
        this.setCurSelectSecurity(selectKey);
    }
    getTransferPrincipal=()=>{
        this.refs.transferSafe.getPrincipal();
    }

    tableObj = (ref) => {
        this.setState(()=>({
            tableChild : ref
        }))
    }

    /**
     * Actions按钮
     * @returns {JSX.Element}
     */
    getActionsBottom = () => {
        const { selectCount, canBeCanceled } = this.state;
        const modifyDisable = selectCount == 1 ? false : true;
        const cancelDisable = selectCount > 0 && canBeCanceled == 1 ? false : true;
        return <Dropdown disabled={modifyDisable && cancelDisable ? true : false} overlay={this.actionsMenu} placement="bottomRight" trigger="click">
            <Button
                type="primary"
                className="shares-actions"
            ><div className="actions-left">{__('Actions')}</div><DownOutlined className="actions-right" /></Button>
        </Dropdown>
    }

    /**
     * Actions菜单
     * @returns {JSX.Element}
     */
    actionsMenu = () => {
        const { canBeCanceled, selectCount, selectIndex: indexArray, selectId } = this.state;
        return (
            <Menu>
                {selectCount == 1 && <Menu.Item onClick={this.setCurSelectSecurityNew.bind(this, selectId)}><a data-toggle="modal" data-target="#modifyConvertibleModal">{__('Modify convertibles')}</a></Menu.Item>}
                {canBeCanceled == 1 && <Menu.Item ><a data-toggle="modal" onClick={this.changeCancelNum} data-target="#cancelModal">{__('Cancel convertibles')}</a></Menu.Item>}
            </Menu>
        )
    }

    /**
     * Redeem to cash
     */
    redeemToCashModal = (record, id) => {
        redeemToCashModal(record, id, this.getCnList)
    }

    /**
     * 搜索列表数据
     * @param e
     */
    searchList = (e) => {
        const { value } = e.target;
        this.setState({
            searchValue: value,
        })
    }

    /**
     * 清空搜索内容
     */
    closeSearch = () => {
        this.setState({
            searchValue: '',
        },()=> this.getCnList());
    }

    /**
     * column 排序
     * @param sort
     */
    sortTable = sort => {
        this.setState({ sort }, ()=> this.getCnList())
    }

    onPageChange = (current, pageSize) => {
        localStorage.setItem('cnPageSize', pageSize);
        this.setState({
            pageNumber: pageSize === this.state.pageSize ? current : 1,
            pageSize
        })
        // }, () => this.getCnList())
    }
    updateState = data => this.setState(data);
    // 筛选条件逻辑，如果有分页，需要把查询条件保存在state 供分页使用
    onFilterChange = (value = {}) => {
        if (JSON.stringify(value) !== JSON.stringify(this.state.filter)) {
            this.setState({
                filter: value,
                pageNumber: 1
            }, () => this.getCnList())
        }
    }

    render(){
        const { currency = '', securityLists, curSelectSecurity, searchValue, selectId, draftConvertiblesCount, activeBtnDisable, tableColumn, redisKey, total, pageSize, pageNumber, sort, loading } = this.state
        let selectCount=0;
        for(let j=0;j<this.state.securityLists.length;j++){
            if(this.state.securityLists[j].checkbox_status === true){
                selectCount++;
            }
        }
        let actionButtonClass ='btn action-disabledBtn action-btn dropdown-toggle disabled';

        if(selectCount >=1 && activeBtnDisable == 0){
            actionButtonClass = 'btn action-availableBtn action-btn dropdown-toggle';
        }

        return(
            <div className="content">
                <ModifyConvertibleModal  currency={this.state.currency}  setModifiedSecurity={this.setModifiedSecurity} checkedSecurity={this.state.checkedSecurity} pageData={this.state.pageData} curSelectSecurity={this.state.curSelectSecurity}/>
                <AddVestingSchedule  getSecurityList={this.getCnList} vestingScheduleList={this.state.vestingScheduleList} curSelectSecurity={this.state.curSelectSecurity} />
                <TransferSafe getCnList={this.getCnList} ref={"transferSafe"} pageData={this.state.pageData} currency={this.state.currency} curSelectSecurity={this.state.curSelectSecurity}/>
                <CancelModal resetCancel={this.resetCancel} resetSecurityStatus={this.resetSecurityStatus} currency={this.state.currency} id="cancelModal" ref="cancelModal" securityList={this.state.securityLists} checkedSecurity={this.state.checkedSecurity} pageData={this.state.pageData} curSelectSecurity={this.state.curSelectSecurity} checkedIds={this.state.checkedIds} getData={this.getCnList} />
                <AddAdminNotes setModifiedSecurity={this.setModifiedSecurity} setDataForModifiedSecurity={this.setDataForModifiedSecurity} ref="addSharesAdminNoteCn"  curSelectSecurity={this.state.curSelectSecurity}/>
                <EditShareClasses/>
                <RequestStockSplit/>
                <EditConvertibleTerms pageData={this.state.pageData} getCnList={this.getCnList}/>
                <TermsList ref="convertibleTermsList" getCnList={this.getCnList}/>
                <CreateCnTermsModal ref="createCnTerms" getCnList={this.getCnList}/>
                {/*<CreateConvertibleTerms pageData={this.state.pageData}/>*/}
                <ActionCancelModal selectCount={selectCount} pageData={this.state.pageData} curSelectSecurity={this.state.curSelectSecurity}/>
                <div className="stakeholder-conCenter scroll-cont">
                    <div className="holders-nav clearfix">
                        <div className="action-box onset">
                            {this.state.isShowCancelTip == 1 &&
                            <p className="text-muted cancel_pop">{__("Canceled, transferred, and converted notes can't be canceled.")}<br/><a onClick={this.deselect} className="text-blue">{__('Deselect conflicting rows')}</a></p>
                            }
                            <div className="btn-group">
                                {this.getActionsBottom()}
                                {/*<button type="button" onClick={this.checkSecurityStatus} className={actionButtonClass} data-toggle="dropdown">Actions<span className="triangle triangle-whitedown action-triangle"></span></button>*/}
                                {/*{selectCount>=1 && activeBtnDisable == 0 &&*/}
                                {/*<ul className="dropdown-menu mange-downmenu action-downmenu">*/}
                                {/*    {selectCount==1 &&*/}
                                {/*    <li><a  data-toggle="modal" onClick={this.setCurSelectSecurityNew.bind(this,selectId)} data-target="#modifyConvertibleModal">Modify convertibles</a></li>*/}
                                {/*    }*/}
                                {/*    {this.state.canBeCanceled == 1 &&*/}
                                {/*    <li><a data-toggle="modal" onClick={this.changeCancelNum} data-target="#cancelModal">Cancel convertibles</a></li>*/}
                                {/*    }*/}
                                {/*    {this.state.canBeCanceled == 0 &&*/}
                                {/*    <li><a className="showTip" onClick={this.showCertificateTip} >Cancel convertibles</a></li>*/}
                                {/*    }*/}
                                {/*</ul>*/}
                                {/*}*/}
                            </div>
                        </div>
                        <div className="form-group search-group banner-rightBox offset draft-statistic">
                            {selectCount>=1 &&
                            <div className="stateBox">
                                <p className="items-tip">{printf(__('%s of %s items selected'),selectCount,this.state.securityLists.length)}</p>
                                <button onClick={this.clear} type="button" className="bg-dark holders-clearBtn">{__('Clear selection')}</button>
                            </div>
                            }

                            <ColumnController filterList={tableColumn} redisKey={redisKey} thisObj={this} />
                            <div className="mr-10">
                                    <Filter callback={val => this.onFilterChange(val)} fiterData={this.state.filter} type="convertibles" />
                                    &nbsp;&nbsp;
                            </div>
                            <div className="stakeholder-search-box">
                                <Input
                                    className="stakeholder-search-input"
                                    value={searchValue}
                                    onChange={this.searchList}
                                    onPressEnter={this.getCnList}
                                    prefix={<SearchOutlined className="search-prefix-icon" onClick={this.getCnList} />}
                                    suffix={<CloseOutlined style={{opacity: searchValue ? 1 : 0}} onClick={this.closeSearch} className="search-close-icon" />}
                                    placeholder={__('Search')}
                                />
                            </div>

                            <Permission type="securities_convertibles_manageItems" hide>
                                <div className="btn-group">
                                    <a className="btn drafts_btn convertible-manageBtn bg-darkblue dropdown-toggle" data-toggle="dropdown">{__('Manage convertible terms')}<span className="triangle triangle-whitedown manage_triangle"></span></a>
                                    <ul className="dropdown-menu mange-downmenu shares-downmenu">
                                        <li><a className="add-stake" onClick={this.getTermsList} data-toggle="modal" data-target="#TermsListModal">{__('Edit convertible terms')}</a></li>
                                        <li><a  data-toggle="modal" onClick={this.loadCnExistingData} data-target="#createCnTermsModal">{__('Create convertible terms')}</a></li>
                                    </ul>
                                </div>
                            </Permission>

                            <Permission type="securities_draftConvertibles" hide>
                                <Badge className="draft-statistic-badge" count={I(draftConvertiblesCount, 0)}>
                                    {
                                        this.state.cnTermsList.length==0 ? <a disabled  className="btn drafts_btn send-disabledBtn">{__('Draft convertibles')}</a> :
                                            <Link to="/home/securities/convertibles/draft-convertible" className="btn drafts_btn bg-darkblue">{__('Draft convertibles')}</Link>
                                    }
                                </Badge>
                            </Permission>
                        </div>
                    </div>
                    <div className=" table-box">

                        <ConvertibleTable
                            loading={loading}
                            securityLists={securityLists}
                            updateSelectCount={this.updateSelectCount}
                            onRef={this.tableObj}
                            setCurSelectSecurity={this.setCurSelectSecurity}
                            setSelectId={this.setSelectId}
                            checkSecurityStatus={this.checkSecurityStatus}
                            setCurSelectSecurityNew={this.setCurSelectSecurityNew}
                            resetSecurityStatus={this.resetSecurityStatus}
                            resetCancel={this.resetCancel}
                            getTransferPrincipal={this.getTransferPrincipal}
                            cnReverseCancellation={this.cnReverseCancellation}
                            setInitShareNote={this.setInitShareNote}
                            curSelectSecurity={curSelectSecurity}
                            resendEmail={this.resendEmail}
                            redeemToCashModal={this.redeemToCashModal}
                            saveState={this.saveState}
                            sortTable={this.sortTable}
                            tableColumn={columnsFilter(tableColumn, redisKey, sort)}
                            paginationConfig={{
                                total,
                                pageSize,
                                current:pageNumber,
                                showSizeChanger:true,
                                onChange: this.onPageChange,
                                showTotal: (sum) => <span>{sum} {__('items')}</span>
                            }}
                        />

                    </div>
                </div>
            </div>
        )
    }

}

export default Convertibles;

