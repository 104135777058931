import React from "react";
import axios from "axios/index";
import Loading from 'components/Loading';

import { post } from 'srcPath/http';
import Utils from "Utils";
import EditShareClassPlanModal from "./EditShareClassPlanModal";

class EditShareClasses extends React.Component {
    constructor(props){
        super(props);
        this.state={
            data:{
                seniority_level:"",
                class_type:{text:"Preferred",value:true,},
                class_type_status:{text:"Common",value:"common"},
                share_class:"Common",
                share_class_prefix:"CS",
                certificated_shares:{text:"Yes",value:true},
                document_status:"select_document",
                par_value:"",
                pari_passu:{text:"Yes",value:true,},

            },
            shareClassPlanList:[
                // {
                //     id:"",
                //     share_class_name:"Common",
                //     prefix:"CS1",
                //     total_authorized_shares:"5000",
                //     issue_shares:"3000",
                //     seniority_level:"1",
                // },
                // {
                //     id:"",
                //     share_class_name:"Preferred",
                //     prefix:"PS1",
                //     total_authorized_shares:"1000",
                //     issue_shares:"400",
                //     seniority_level:"2",
                // },
            ],

            seniorityList:[1,2,3,4,5,6],
        }
    }

    getShareClassPlanList=()=>{
        post('getShareClassPlanList', []).then((response)=> {
            if(response.data.code === 0){
                this.setState({
                    shareClassPlanList:response.data.data
                })
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
            //用到this要注意this指向
        }).catch(function (error) {
            console.log(error);
        });
    }
    componentDidMount(){

    }
    editShareClasses=(key)=>{
        this.refs.editShareClassPlan.initData(key,this.state.shareClassPlanList[key]);
        // console.log(this.state.shareClassPlanList[key])
        // this.refs.editShareClassPlan.getShareClassInfo();
        // this.refs.editShareClassPlan.changeToEditStatus();
        // this.refs.editShareClassPlan.resetUpdateActive();
    }
    recordData=(key,e)=>{
        //这里应该根据key找到对应的Plan，将id和要修改seniority_level传给服务器即可
        var newSeniorityLevel = e.target.value;
        var shareClassInfo = this.state.shareClassPlanList[key];
        var requestData = {
            id:shareClassInfo.id,
            seniority_level:newSeniorityLevel
        };
        post('editSeniorityLevel', requestData).then((response)=> {
            if(response.data.code === 0){
                let curShareClassList = this.state.shareClassPlanList;
                curShareClassList[key].seniority_level = newSeniorityLevel;
                this.setState({
                    shareClassPlanList:curShareClassList
                })
                // window.location.reload();
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    closeEditShareClass=()=>{
        window.$("#editShareClassModal").modal("hide");
        window.$("#editShareClass").removeClass("active");
        window.$("#shareClass").addClass("active");
    }
    render(){
        return(
            <div className="modal fade" id="editShareClassModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="modal-dialog dialog960 dialog-top40 tab-content">
                    <div className="modal-content content500 tab-pane active" id="shareClass">
                        <div className="modal-header box-header">
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                            <h4 className="modal-title" id="myModalLabel">Share Classes </h4>
                        </div>
                        <div className="modal-body padding203020 reset_margin">
                            <div className="body-one">
                                {this.state.shareClassPlanList.length===0 &&
                                <Loading/>
                                }
                                {this.state.shareClassPlanList.map((value,key)=>{
                                    return(
                                        <div key={key}>
                                            <div className="clearfix">
                                                <div className="onset">
                                                    <p className="">{value.share_class_name} ({value.prefix})</p>
                                                    <select className="text-blue select-container blue-triangle-down width90" name="seniority_level" value={value.seniority_level} onChange={this.recordData.bind(this,key)}>
                                                        {(value.seniority_level>this.state.length) && <option value={value.seniority_level} >Seniority {value.seniority_level}</option>}
                                                        {this.state.seniorityList.map((val,k)=>{
                                                            return(
                                                                <option value={val} key={k}>Seniority {val}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                <button type="button" className="btn editShareClassBtn bg-darkblue offset" onClick={this.editShareClasses.bind(this,key)} data-toggle="tab" data-target="#editShareClass" >Edit share class</button>
                                            </div>
                                            <div className="progress schedule-progress edit-shareClass-progress no-marginBottom">
                                                <div className="progress-bar" style={{width:parseInt(value.issue_shares) / parseInt(value.total_authorized_shares)*100 + "%", backgroundColor:"#00a9cc"}} role="progressbar"  aria-valuenow="80"
                                                     aria-valuemin="0" aria-valuemax="100">
                                                    <span className="sr-only">{parseInt(value.issue_shares) / parseInt(value.total_authorized_shares)*100}% Complete</span>
                                                </div>

                                            </div>
                                            <div className="clearfix">
                                                <small className="text-muted onset">{value.total_authorized_shares==0 ? "No" : Utils.checkNumInput(value.total_authorized_shares)} shares authorized</small>
                                                <small className="text-muted offset">{value.total_authorized_shares==0 ? "None" :Utils.checkNumInput(parseInt(value.total_authorized_shares) - parseInt(value.issued_shares))} available</small>
                                            </div>
                                            <hr />
                                        </div>
                                    )
                                })}
                             </div>
                        </div>
                    </div>
                    <div className="tab-pane" id="editShareClass">
                        <EditShareClassPlanModal ref="editShareClassPlan" closeEditShareClass={this.closeEditShareClass}/>
                    </div>

                </div>
            </div>
        )
    }
}
export default EditShareClasses;