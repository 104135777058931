import React, { useState }  from 'react';
import security from 'stores/security';
import { ossURLMap } from './constant'
import moment from 'moment';
import { message } from 'antd';
import {create, all, string} from 'mathjs';
import numeral from 'numeral';

const math = create(all, {
    epsilon: 1e-12,
    matrix: 'Matrix',
    number: 'BigNumber',
    precision: 64,
    predictable: false,
    randomSeed: null
});

class Utils {

    vestingSchedulePeriodListId2Text(id){
        let totalArr = {1:"Every day",2:"Every week",3:"Every month",4:"Every 2 month",5:"Every 3 month",6:"Every 6 month",7:"Every 12 month",8:"Calendar quarters"}
        return totalArr[id];
    }
    vestingScheduleExactDataListId2Text(id){
        let totalArr = {
            1:"on the same day as the start date",
            2:"on the same day as the start date, minus one",
            3:"on the first day of the month",
            4:"on the last day of the month",
        }
        return totalArr[id];
    }
    getNumWith2Decimal(num){
        //如果小数点不足两位，补齐两位
        let numArr = num.split(".");
        let returnNum = "";
        if(numArr.length === 1){
            returnNum = numArr[0] + ".00";
        }else{
            if(numArr[1].length >=2){
                returnNum = num;
            }else{
                let zeroNeedCount = 2 - numArr[1].length;
                let addZeroStr = "";
                for(let i=0;i<zeroNeedCount;i++){
                    addZeroStr += '0';
                }
                returnNum = num + addZeroStr;
            }
        }
        return returnNum;
    }
    getNumWith5Decimal(num){
        //如果小数点不足五位，补齐五位
        let numArr = num.split(".");
        let returnNum = "";
        if(numArr.length === 1){
            returnNum = numArr[0] + ".00000";
        }else{
            if(numArr[1].length >=5){
                returnNum = num;
            }else{
                let zeroNeedCount = 5 - numArr[1].length;
                let addZeroStr = "";
                for(let i=0;i<zeroNeedCount;i++){
                    addZeroStr += '0';
                }
                returnNum = num + addZeroStr;
            }
        }
        return returnNum;
    }
    getNumWith10Decimal(num){
        //如果小数点不足10位，补齐10位
        let numArr = num.split(".");
        let returnNum = "";
        if(numArr.length === 1){
            returnNum = numArr[0] + ".0000000000";
        }else{
            if(numArr[1].length >=10){
                returnNum = num;
            }else{
                let zeroNeedCount = 10 - numArr[1].length;
                let addZeroStr = "";
                for(let i=0;i<zeroNeedCount;i++){
                    addZeroStr += '0';
                }
                returnNum = num + addZeroStr;
            }
        }
        return returnNum;
    }
    setItemWithCompanyId(k,v){
        let newKey = k + security.companyId;
        localStorage.setItem(newKey,v);
    }

    getItemWithCompanyId(k){
        let newKey = k + security.companyId;
        return localStorage.getItem(newKey);
    }

    getRealUrlForFiles(filename, aliDir, companyId){
        const ID = companyId || security.companyId
        if (aliDir) {
            return `${ossURLMap[security.env]}/Company${ID}/${aliDir}/${filename}`;
        }
        //aliDir有 logo/dataroom/board/securityplandetails/report/


        return `${ossURLMap[security.env]}/Company${ID}/${filename}`;
    }

    uploadFile(file) {
        return new Promise(function(resolve, reject) {
            let reader = new window.FileReader()
            reader.readAsDataURL(file)
            reader.onload = function() {
                resolve(this.result)
            }
        })
    }

    isLocal(){
        return false;
        let hostname = window.location.hostname;
        if(hostname === 'localhost'){
            return true;
        }else{
            return false;
        }
    }

    getLocalCompanyInfo(){
        /*参数示意
        {
                "admin_id":1,
                "company_id": 666,
                "nick_name": "erere",
                "flag": "2",
                "is_board": "0",
                "company_name": null,
                "is_finished_onboarding": 0,
            }
         */
        return security.companyInfo
    }

    download(url,filename) {
        if(url.length === 0){
            return;
        }
        // var url = ['test.json','aaa.gif','8F.pdf'];
        // var filename = ["tee.json",'aaaa.gif',"888.pdf"];
        var urlPrefix = 'https://files.getsprout.co/docs/';
        for(let j=0;j<url.length;j++){
            url[j] = urlPrefix + url[j];
        }
        for(var i=0;i<url.length;i++){
            setTimeout( ((url,filename,i)=> {
                return ()=> {
                    this.getBlob(url[i],  (blob) =>{
                        this.saveAs(blob, filename[i]);
                    });
                }
            })(url,filename,i), i*1000 );
        }
    };

    getBlob(url, cb) {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = function () {
            if (xhr.status === 200) {
                cb(xhr.response);
            }
        };
        xhr.send();
    }

    saveAs(blob, filename) {
        if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, filename);
        } else {
            var link = document.createElement('a');
            var body = document.querySelector('body');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
// fix Firefox
            link.style.display = 'none';
            body.appendChild(link);
            link.click();
            body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
        };

    }


    getDayCountByMonth(month){
        let dayCountArr = [31,28,31,30,31,30,31,31,30,31,30,31];
        return dayCountArr[month];
    }
    getTodayWeightToCompare(){
        return new Date().getFullYear()*10000+(new Date().getMonth()+1)*100+new Date().getDate();
    }

    getWeightByDateString(dateString){
        //format:dd/mm/yyy
        let dateArr = dateString.split('/');
        let year = parseInt(dateArr[2]);
        let month = parseInt(dateArr[1]);
        let day = parseInt(dateArr[0]);
        return year*10000+month*100+day;

    }
    dateFormatTransfer(dateString){
        //format:dd/mm/yyyy
        let dateArr = dateString.split('/');
        let year = parseInt(dateArr[2]);
        let month = parseInt(dateArr[1]);
        let day = parseInt(dateArr[0]);
        // let newDate = year +","+ month + "," + day;
        let newDate = year +"-"+ month + "-" + day;
        return newDate;

    }
    getYesOrNoText(num){
        if(num !=0 && num !=1){
            return "";
        }
        var textArr = ['No','Yes'];
        return textArr[num];
    }


    getNumFromFormatNum(foratNum){
        var curValue = foratNum.toString();
        if(curValue.length === 0){
            curValue = "0";
        }
        return parseInt(curValue.replace(/,/g,""));
    }

    getNumFormatWithComma(num){
        var numString = num.toString();
        if(numString == '0'){
            return num;
        }
        if(numString.length == 2 && numString.indexOf(".")===1){
            return num;
        }
        //最大10位小数
        var maxDecimalCount = 10;
        var returnStr = "";
        if(numString.indexOf(".")===-1){ //int
            returnStr = this.checkNumInput(num);
        }else{
            //decimal
            let numArr = numString.split(".");
            let intPart = 0;
            if(numArr[0] != 0){
                if(numArr[0]){
                    intPart = this.checkNumInput(numArr[0]);
                }else{
                    intPart = "";
                }
            }
            var decimalPart = numArr[1].replace(/\D/g,'').substring(0,maxDecimalCount);
            returnStr = intPart.toString() + "." + decimalPart;
        }
        return returnStr;
    }
    getNumFormatWithComma5(num){
        var numString = num.toString();
        if(numString == '0'){
            return num;
        }
        if(numString.length == 2 && numString.indexOf(".")===1){
            return num;
        }
        //最大五位小数
        var maxDecimalCount = 5;
        var returnStr = "";
        if(numString.indexOf(".")===-1){ //int
            returnStr = this.checkNumInput(num);
        }else{
            //decimal
            let numArr = numString.split(".");
            let intPart = 0;
            if(numArr[0] != 0){
                if(numArr[0]){
                    intPart = this.checkNumInput(numArr[0]);
                }else{
                    intPart = "";
                }
            }
            var decimalPart = numArr[1].replace(/\D/g,'').substring(0,maxDecimalCount);
            returnStr = intPart.toString() + "." + decimalPart;
        }
        return returnStr;
    }

    getNumFormatWithTwoComma(num){
        if(num == 0){
            return num;
        }
        //最大两位小数
        var maxDecimalCount = 2;
        var numString = num.toString();
        var returnStr = "";
        if(numString.indexOf(".")===-1){ //int
            returnStr = this.checkNumInput(num);
        }else{
            //decimal
            let numArr = numString.split(".");
            if(numArr[0] && numArr[0]!=0){
                var intPart = this.checkNumInput(numArr[0]);
            }else if(numArr[0]==='0'){
                var intPart = 0;
            }else{
                var intPart = "";
            }
            var decimalPart = numArr[1].replace(/\D/g,'').substring(0,maxDecimalCount);
            returnStr = intPart + "." + decimalPart;
        }
        return returnStr;
    }
    check5DecimalNumInputWithoutComma(num){
        var numString = num.toString();
        if(numString == '0'){
            return num;
        }
        if(numString.length == 2 && numString.indexOf(".")===1){
            return num;
        }
        //最大五位小数
        var maxDecimalCount = 5;
        var returnStr = "";
        if(numString.indexOf(".")===-1){ //int
            returnStr = this.checkIntInputWithoutComma(num);
        }else{
            //decimal
            let numArr = numString.split(".");
            let intPart = 0;
            if(numArr[0] != 0){
                if(numArr[0]){
                    intPart = this.checkIntInputWithoutComma(numArr[0]);
                }else{
                    intPart = "";
                }
            }
            var decimalPart = numArr[1].replace(/\D/g,'').substring(0,maxDecimalCount);
            returnStr = intPart.toString() + "." + decimalPart;
        }
        return returnStr;
    }

    check2DecimalNumInputWithoutComma(num){
        var numString = num.toString();
        if(numString == '0'){
            return num;
        }
        if(numString.length == 2 && numString.indexOf(".")===1){
            return num;
        }
        //最大二位小数
        var maxDecimalCount = 2;
        var returnStr = "";
        if(numString.indexOf(".")===-1){ //int
            returnStr = this.checkIntInputWithoutComma(num);
        }else{
            //decimal
            let numArr = numString.split(".");
            let intPart = 0;
            if(numArr[0] != 0){
                if(numArr[0]){
                    intPart = this.checkIntInputWithoutComma(numArr[0]);
                }else{
                    intPart = "";
                }
            }
            var decimalPart = numArr[1].replace(/\D/g,'').substring(0,maxDecimalCount);
            returnStr = intPart.toString() + "." + decimalPart;
        }
        return returnStr;
    }
    check10DecimalNumInputWithoutComma(num){
        var numString = num.toString();
        if(numString == '0'){
            return num;
        }
        if(numString.length == 2 && numString.indexOf(".")===1){
            return num;
        }
        //最大10位小数
        var maxDecimalCount = 10;
        var returnStr = "";
        if(numString.indexOf(".")===-1){ //int
            returnStr = this.checkIntInputWithoutComma(num);
        }else{
            //decimal
            let numArr = numString.split(".");
            let intPart = 0;
            if(numArr[0] != 0){
                if(numArr[0]){
                    intPart = this.checkIntInputWithoutComma(numArr[0]);
                }else{
                    intPart = "";
                }
            }
            var decimalPart = numArr[1].replace(/\D/g,'').substring(0,maxDecimalCount);
            returnStr = intPart.toString() + "." + decimalPart;
        }
        return returnStr;
    }
    checkIntInputWithoutComma(inputNum){
        if (inputNum) {
            var curValue = inputNum.toString();
            if(curValue.length===1){
                curValue=curValue.replace(/[^1-9]/g,'');
            }else{
                curValue=curValue.replace(/\D/g,'');
                curValue = curValue.replace(/^0+/g,'');
            }
            return curValue;
        }else{
            return "";
        }
    }

    checkNumInput(inputNum){
        if (inputNum) {
            var curValue = inputNum.toString();
            if(curValue.length===1){
                curValue=curValue.replace(/[^1-9]/g,'');
            }else{
                curValue=curValue.replace(/\D/g,'');
                curValue = curValue.replace(/^0+/g,'');
                //开始格式化
                var num = curValue;
                for (var i = 0; i < Math.floor((num.length-(1+i))/3); i++) {
                    num = num.substring(0,num.length-(4*i+3))+','+num.substring(num.length-(4*i+3));
                }
                curValue = num;
            }
            return curValue;
        }else{
            return "";
        }
    }
    getPlaceholderValue(value){
        value.substring(5);
        return value;
    }
    checkNumInputWithStr(inputData,str,pos){
        str = str.toString();
        inputData = inputData.toString();
        var num = this.checkNumInput(inputData.replace(str,""));
        let returnStr = "";
        if(pos === 0){ //prefix
            returnStr = str+num;
        }else{
            returnStr = num+str;
        }
        return returnStr;

    }

    getDaysBetween=(dateStart,dateEnd)=>{
        // let dateStartTime = new Date(dateStart).getTime();
        // let dateEndTime = new Date(dateEnd).getTime();
        let dateStartTime = new Date(dateStart.replace(/-/g,'/')).getTime();
        let dateEndTime = new Date(dateEnd.replace(/-/g,'/')).getTime();
        var dayDiff = dateEndTime - dateStartTime;
        var days = dayDiff / (1000*60*60*24);
        return(days)
    }
    getCurDate(){
        let curDate = new Date();
        let curMonth = curDate.getMonth()+1;
        if(curMonth < 10){
            curMonth = '0'+curMonth;
        }
        let curDay = curDate.getDate();
        if(curDay < 10){
            curDay = '0' + curDay;
        }
        let curYear = curDate.getFullYear();
        return curDay+"/"+curMonth+"/"+curYear;
    }

    isEmail(strEmail) {
        strEmail=strEmail.replace(/\+/g,"");
        if (strEmail.search(/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/) != -1){
            return true;
        } else{
            return false;
        }
    }
    isRealNumOnlyHasValue(val){
        // isNaN()函数 把空串 空格 以及NUll 按照0来处理 所以先去除，
        if(val === "" || val ==null){
            return true;
        }
        if(!isNaN(val)){
            //对于空数组和只有一个数值成员的数组或全是数字组成的字符串，isNaN返回false，例如：'123'、[]、[2]、['123'],isNaN返回false,
            //所以如果不需要val包含这些特殊情况，则这个判断改写为if(!isNaN(val) && typeof val === 'number' )
            return true;
        }else{
            return false;
        }
    }

    isRealNum(val){
        // isNaN()函数 把空串 空格 以及NUll 按照0来处理 所以先去除，
        if(val === "" || val ==null){
            return false;
        }
        if(!isNaN(val)){
            //对于空数组和只有一个数值成员的数组或全是数字组成的字符串，isNaN返回false，例如：'123'、[]、[2]、['123'],isNaN返回false,
            //所以如果不需要val包含这些特殊情况，则这个判断改写为if(!isNaN(val) && typeof val === 'number' )
            return true;
        }else{
            return false;
        }
    }

    //Trim name for Tablecsbody pages
    trimSpaceLowerCase(value){
        if(typeof(value) !== 'number' && value){
            let returnValue = value.toLowerCase().replace(/\s/g, '');
            returnValue = returnValue.replace(/[^a-zA-Z0-9]/g, '');
            return returnValue
        } else {
            return value
        }

    }
    //For convertible principal and total format
    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    IsNum(num){
        let reNum=/^\d*$/;
        if(reNum.test(num)) {
            return true;
        } else {
            if(num < 0) {
                message.error("Cannot be negative!");

                return
            }
            return false;
        }}
    resetNumberWithCommas(x) {
        if(!x){
            return 0;
        }else{
            return x.toString().replace(/,/g, "");
        }
    }

    // dd/mm/yyyy => mm/dd/yyy
    dateFormatChange = (prop) => {
        if(prop) {
            var dateBefore = prop.split(/\//);
            var newDate = [ dateBefore[1], dateBefore[0], dateBefore[2] ].join('/'); //=> 'mm/dd/yyyy'
            return newDate
        }

        return '--'
    }

    // dd/mm/yyyy => Apr 1, 2019
    getNewDate = (param) => {
        if (param) {
            return moment(this.dateFormatChange(param)).format('ll')
        }

        return '--'
    }
}

// 千分位分隔数字 formatNumber(1234567.22234, 3) // 1,234,567.222
export function formatNumber(value, decimalCount = 2) {
    const dataString = (value + '').toString().replace(/^\s*|\s*$|,*/g, ''); // 去除空格

    if (dataString.match(/^-?\d*\.?\d*$/) === null || Number(dataString).toString() === 'NaN' || dataString.length === 0) {
        return '--'; // 不合法数据
    }

    if (decimalCount === null || decimalCount <= 0) {
        return  Number( decimalCount === 0 ? parseInt(dataString) : dataString).toLocaleString('en-US', { maximumFractionDigits: 16 }); // 不处理小数部分，保留原来数据。整数部分格式化处理
    }

    return numeral(value).format(`0,0.${new Array(decimalCount).fill(0).join('')}`)
}

// 带 currency 的数字格式化
export function formatCurrencyNumber(value, decimalCount, curr) {
    const decimal = decimalCount || parseInt(security.companyInfo.fractional_digit)
    const orignCurrency = curr || security.companyInfo.currency
    const currency = orignCurrency ||  '--'
    const number = formatNumber(value, decimal === 0 ? 0 : (decimal || 2))

    return number === '--' ? '--' : `${currency} ${number}`
}

// 讲大数转化为特定格式 如：10000000 -> 10M
// tranNumber(1000,2) // 1000
// tranNumber(26742238,2) // 26.74M
export function tranNumber(num = 0, point = 1, curr){
    // 将数字转换为字符串,然后通过split方法用.分隔,取到第0个
    let numStr = num.toString().split('.')[0];

    const currency = (typeof curr === 'boolean' ? security.companyInfo.currency : curr) || '--'

    let res = ''

    if(numStr.length < 6) { // 判断数字有多长,如果小于6,,表示10万以内的数字,让其直接显示
        res = num.toFixed(point);
    } else if (numStr.length >= 6 && numStr.length <= 8) { // 如果数字大于6位,小于8位,让其数字后面加单位万
        res = parseFloat(num / 1000000).toFixed(point) + 'M';
    } else if (numStr.length >8) { // 如果数字大于8位,让其数字后面加单位亿
        res = parseFloat(num / 1000000000).toFixed(point) + 'B';
    }

    return curr ? `${currency} ${res}` : res
}

export const removeModal = () => {
    const body = document.querySelector('body');
    const modal = document.querySelector('.modal-backdrop')

    if (body && modal) {
        body.removeChild(modal)
    }
}

export function formatPercent(num, type = 'multiply', decimalCount) {
    let decimal = decimalCount || security.companyInfo.fractional_digit || 2

    if (num === 0 || num === '0') {
        return '0.00%';
    }

    if (decimal <= 2 ) {
        decimal = 2
    }

    if (!!(num * 1)) {
        return type === 'multiply' ? (num * 100).toFixed(decimal) + '%' : (num / 100).toFixed(decimal) + '%';
    }

    return '--';
}

export function bindKeyEvent(func) {
    const callback = (e) => {
        const { key, keyCode } = e
        if (key === 'Enter' || keyCode === 13) {
            func && func()
        }
    }

    window.addEventListener('keydown', callback)

    return () => { window.removeEventListener('keydown', callback) }
}

export const formatDateForDatePicker = str => {
    if(!str) {
        return
    }

    const strArray = str.split('/')
    const [D, M, Y] = strArray

    if(strArray.length !== 3) {
        return
    }

    return moment(`${Y}-${M}-${D}`)
}

export const formatDate = dataStr => {
    if (moment(dataStr).isValid() && !dataStr) {
        return '--'
    }

    return moment(dataStr).format('DD/MM/YYYY')
}

// 字符串首字母大写
export function firstWordUpperCase(str) {
    return str.toLowerCase().replace(/(\s|^)[a-z]/g, function(char){
        return char.toUpperCase();
    });
}

export const roundColor = [
    '#88C84D', // green-shellow
    '#E3D213', // yellow
    '#006BAF', // blue
    '#28BC7D', // green
    '#00A9C9',
    '#6F89EB',
]

export const constData = {
    adminRole: {
        1: 'Super admin',
        2: 'Company editor',
        3: 'Company viewer',
        5: 'ESOP admin',
        6: 'Share class admin',
        8: 'Data room user'
    },
    boardRole: {
        7: 'Board director',
        8: 'Board observer',
        6: 'Board admin'
    }
}


export const urlReg = /^(((ht|f)tps?):\/\/)?[\w\-]+(\.[\w\-]+)+([\w\-.,@?^=%&:\/~+#]*[\w\-@?^=%&\/~+#])?$/

/**
 * 高精度浮点运算
 * add:加，minus:减，mul:乘，division:除
 * @type {{}}
 */
export const calculator = {
    checkRequest : (arg) => {
        return [calculator.I(arg[0], '0'), calculator.I(arg[1], '0')];
    },
    I : ( variable, defaultVal) => {
        if(
            variable == 'undefined' ||
            variable == undefined ||
            variable == null ||
            variable == 'null' ||
            variable == NaN ||
            variable == 'NaN' ||
            variable == '' ||
            variable == 0 ||
            variable == '0'
        ){
            return String( defaultVal);
        }
        return String( variable);
    },
    //加法
    add : ( arg1, arg2) => {
        const arg = calculator.checkRequest([arg1, arg2]);
        return math.evaluate(arg[0] + '+' + arg[1]).toString();
    },
    //减法
    minus : ( arg1, arg2) => {
        const arg = calculator.checkRequest([arg1, arg2]);
        return math.evaluate(arg[0] + '-' + arg[1]).toString();
    },
    //乘法
    mul : ( arg1, arg2) => {
        const arg = calculator.checkRequest([arg1, arg2]);
        return math.evaluate(arg[0] + '*' + arg[1]).toString();
    },
    //除法
    division : ( arg1, arg2) => {
        const arg = calculator.checkRequest([arg1, arg2]);
        return math.evaluate(arg[0] + '/' + arg[1]).toString();
    },
    //取余
    rem : (arg1, arg2) => {
        const arg = calculator.checkRequest([arg1, arg2]);
        return math.evaluate(arg[0] + '%' + arg[1]).toString();
    },
    //自定义
    agile : (arg) => {
        return math.evaluate(arg).toString();
    }
}

/**
 * 格式化数字，增加千分位，并返回对应光标位置
 * @param number 金额
 * @returns {string} 格式化后的金额
 */
export function formatWithArray(number) {
    let arr = (number + '').split('.');
    let int = arr[0].split('');
    let fraction = arr[1] || '';
    let r = "";
    let j = 0;
    int.reverse().forEach(function (v, i) {
        if (i !== 0 && i % 3 === 0) {
            r = v + "," + r;
            j++;
        } else {
            r = v + r;
        }
    })
    return ( r + (!!fraction ? "." + fraction : '') );
}

/**
 * 金额控制只能输入数字，保留两位小数
 * @param element DOM对象
 * @returns {{pos: *, value: (string|*)}} value:转换后的金额，pos:光标位置
 */
export function clearNoNum(element,digit=2) {
    element.value = String(element.value);
    //保存原始光标位置
    let CaretPos = 0;
    // IE Support
    if (document.selection) {
        //获取光标位置函数
        element.focus();
        let Sel = document.selection.createRange();
        Sel.moveStart('character', -element.value.length);
        CaretPos = Sel.text.length;
    }
    // Firefox/Safari/Chrome/Opera support
    else if (element.selectionStart || element.selectionStart == '0')
        CaretPos = element.selectionEnd;
    let pos = (CaretPos);

    //保存原始值
    let temp = (element.value).toString();
    //截取光标前字段
    let sub = temp.substring(0, pos);
    let checkStrRes = sub.match(/[^\d.]/g);
    if( checkStrRes != null){
        pos -= checkStrRes.length;
    }
    //清除“数字”和“.”以外的字符
    element.value = element.value.replace(/[^\d.]/g, "");
    //只保留第一个. 清除多余的
    element.value = element.value.replace(/\.{2,}/g, ".");
    element.value = element.value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
    if(digit == 2){
        element.value = element.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); //只能输入两个小数
    }else if(digit ==5){
        element.value = element.value.replace(/^(\-)*(\d+)\.(\d\d\d\d\d).*$/, '$1$2.$3'); //只能输入两个小数
    }else{
        //其他位数暂定为2位来处理，如需处理需要单独写逻辑
        element.value = element.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); //只能输入两个小数
    }
    if (element.value.indexOf('.') == 0) {//第一位就是 .
        element.value = '0' + element.value
    }
    if (element.value.indexOf(".") < 0 && element.value != "") { //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        element.value = parseFloat(element.value);
    }
    if(pos < 0) { pos = 0; }
    return {value:element.value, pos}
}

/**
 * 过滤金额,返回指定光标位置
 * @param e e.target 目标节点
 * @param max 录入金额最大值
 * @returns {{cursorPositionX: (*|number), value: string}} value:金额，cursorPositionX：光标位置
 */
export function filterAmount(e, max= 9999999999999, isDecimal = true,digit=2) {

    const element = e.target;
    let posRes = clearNoNum(element,digit);
    posRes.value = calculator.agile(`${I(posRes.value, '0')}`);
    //分离小数点左右数据
    let value = posRes.value.split('.', 2);
    //取出整数部分赋值到运算中
    if(max == 0){
        posRes.value = value[0];
    }else{
        posRes.value = value[0] > max ? String(max) : value[0];
    }
    //判断小数部分是否存在
    if(typeof (value[1]) != 'undefined'){
        if(digit == 2){
            value[1] = value[0] >= max && max != 0 ? '00' : value[1];
            value[1] = (value[1].substr(0, 2) ).length > 1 ? value[1].substr(0, 2) : value[1].substr(0, 2) + '0';
        }else if(digit == 5){
            value[1] = value[0] >= max && max != 0 ? '00000' : value[1];
            value[1] = (value[1].substr(0, 5) ).length > 4 ? value[1].substr(0, 5) : value[1].substr(0, 5) + '0';
        }else{
            value[1] = value[0] >= max && max != 0 ? '00' : value[1];
            value[1] = (value[1].substr(0, 2) ).length > 1 ? value[1].substr(0, 2) : value[1].substr(0, 2) + '0';
        }
    }else{
        if(digit == 2){
            value[1] = '00';
        }else if(digit == 5){
            value[1] = '00000';
        }else{
            //其他位数暂定为2位，如需添加单独处理
            value[1] = '00';
        }
    }
    //重新序列化数字
    let formatValue = formatWithArray(posRes.value);
    //计算光标前多出的,号
    let frontPart = posRes.value;
    //去除光标前的逗号
    let sub = formatValue.replace(/,/g, '');
    //截取光标前内容
    sub = sub.substring(0, posRes.pos);
    //除3取余
    let more_number = calculator.rem(posRes.value.length, 3);
    let more = 0;
    if(posRes.value.length > 3) {
        //光标前的长度 减去 除3的余数如果小于3，证明光标前只有一个逗号
        if ((sub.length - more_number) < 3 && (sub.length - more_number) != 0) {
            more = 1;
        }else if((sub.length - more_number) == 0){
            more = 0;
        } else {
            //如果 光标前的长度 减去 除3的余数不能被整除，证明光标前是数字而不是逗号，所以不用减1
            if( ((sub.length - more_number) % 3) > 0 ){
                more = Math.floor((sub.length - more_number) / 3) + (more_number > 0 ? 1: 0 );
            }else{
                more = Math.floor((sub.length - more_number) / 3) - 1 + (more_number > 0 ? 1: 0 );
            }
        }
    }else{
        more = 0;
    }
    // console.log('初始数据：'+posRes.value);
    // console.log('小数部分：'+ value[1]);
    // console.log('加逗号内容：'+formatValue);
    // console.log('光标前的内容：'+sub);
    // console.log('光标前的长度：'+sub.length);
    // console.log('除3的余数：'+more_number);
    // console.log('光标前的逗号总和：'+more);
    posRes.pos = more + posRes.pos;
    posRes.value = !formatValue ? '' : isDecimal ? formatValue + '.' + value[1] : formatValue;
    return {value:posRes.value, cursorPositionX:posRes.pos};
}

/**
 * 定位光标
 * @param element e.target 目标节点
 * @param pos 光标位置
 */
export function setCursorPos(element, pos) {
    //Firefox/Safari/Chrome/Opera
    if (element.setSelectionRange) {
        element.focus();
        element.setSelectionRange(pos, pos);
    } else if (element.createTextRange) { // IE
        let range = element.createTextRange();
        range.collapse(true);
        range.moveEnd('character', pos);
        range.moveStart('character', pos);
        range.select();
    }
}

/**
 * 验证变量是否为空
 * @param variable 内容
 * @param defaultVal 返回默认值
 * @returns {*}
 * @constructor
 */
export function I(variable, defaultVal){
    if(
        variable == 'undefined' ||
        variable == undefined ||
        variable == null ||
        variable == 'null' ||
        variable == NaN ||
        variable == 'NaN' ||
        variable == '' ||
        variable == 0 ||
        variable == '0'
    ){
        return defaultVal;
    }else{
        return variable;
    }
}

export function closePage() {
    if (navigator.userAgent.indexOf('MSIE') > 0) {
        if (navigator.userAgent.indexOf('MSIE 6.0') > 0) {
            window.opener = null;
            window.close();
        } else {
            window.open('', '_top');
            window.top?.close();
        }
    } else if (navigator.userAgent.indexOf('Firefox') > 0) {
        window.location.href = 'about:blank';
    } else {
        window.opener = null;
        window.open('', '_self', '');
        window.close();
    }
}

export function isMobile() {
    const userAgent = navigator.userAgent
    const reg = 'Mobile'
    return userAgent.indexOf(reg) > -1
}

/**
 * 表格排序
 * @param variableA 对比的内容A
 * @param variableB 对比的内容B
 * @param type 类型。(string / number)
 * @returns {number}
 */
export function tableSort(variableA, variableB, type){
    variableA = I(variableA, '');
    variableB = I(variableB, '');
    type = I(type, 'string');
    const format = 'DD/MM/YYYY', formatYMD = 'YYYYMMDD';
    switch (type){
        case 'string':
            return variableA.localeCompare(variableB, 'zh-CN', { numeric: true });
        case 'date':
            const dateA = I(moment(variableA, format).format(formatYMD), 0);
            const dateB = I(moment(variableB, format).format(formatYMD), 0);
            return dateA.localeCompare(dateB);
        case 'number':
        default:
            return variableA - variableB;
    }
}

export default new Utils();

