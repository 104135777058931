// 整个文件复制的stakeholder下的
import React from 'react';
import 'assets/css/stakeholders.css';
import { post } from 'srcPath/http';
import AddStakeholder from './StakeholdersModal/AddStakeholder';
import AddWalletHolder from './StakeholdersModal/AddWalletHolder';
import { openUpdateStakerholderModal } from 'B/components/WalletLabel/AssignToken'; 
import MergeStakeholdersModels from "./components/MergeStakeholdersModels/MergeStakeholdersModels";
import StakeholdersTableModels from './components/StakeholdersTableModels/StakeholdersTableModels'
import SelectStakeholderWalletLabel from 'B/Token/components/SelectStakeholderWalletLabel';
import {Input, message, Spin, Col, Row, Space, Button, Checkbox, Alert} from 'antd';
import { FormItem, withForm } from 'react-antd-formutil';
import ColumnController, { columnsFilter } from "components/ColumnController";
import Modal, { ModalContent } from 'components/Modal';
import ResendEmailModalConteent from './components/ResendEmailModal';
import Loading from 'components/Loading';
import TokenSelector, { getDefaultTokenId } from 'B/components/TokenSelector';
import filterIcon from './filter.png';
import { printf, sprintf } from 'Utils/i18n';
import './style.scss';
import {Link} from "react-router-dom";
import security from 'stores/security';
import ConfirmSaveStakeholderModels from "../Stakeholder/components/ConfirmSaveStakeholderModels";

const orderMap = {
    null: 0,
    ascend: 4,
    descend: 3,
    undefined: 0
}
class Wallets extends React.Component{

    ConfirmSaveStakeholderModels = null;

    stakeholderData = {}
    constructor(props){
        super(props);
        this.state = {
            showLabel: 0,
            inviteUnregistered:1,
            curSelectRelation:"all",
            loading: true,
            loaded: false,
            holderLists:[],
            total: 0,
            pageSize: 10,
            pageNumber: 1,
            coinId: getDefaultTokenId(),
            cost_list:[],
            curSelectKeysArr:[],
            relationshipList:[
                {checked:false,relationship:"Founder"},
                {checked:false,relationship:"Investor"},
                {checked:false,relationship:"Employee"},
                {checked:false,relationship:"Officer"},
                {checked:false,relationship:"Board member"},
                {checked:false,relationship:"None entered"},
            ],
            stakeholderRelationshipList:[
                "",
                "Advisor",
                "Board member",
                "Consultant",
                "Employee",
                "Ex-employee",
                "Executive",
                "Founder",
                "International employee",
                "Investor",
                "Officer",
            ],
            curSelectStakeholder:{
                id:"",
                security:"",
                securities:"",
                status:0,
                shares:"",
                vesting_schedule:"",
                issue_date:"",
                name:"",
                portfolio_email:"",
                contact_email:"",
                passport_or_national_id:"",
                employee_id:"",
                issue_date_relationship:"",
                annual_salary:"",
                relationship:"",
                address:"",
                ownership:"",
                city:"",
                state:"",
                post_code:"",
                country_region:"",
                date_of_birth:"",
                effective_date:"",
                job_title:"",
                relationshipDataList:[
                    {effective_date:'',relationship:""},
                    ],
                jobTitleList:[
                    {effective_date:'',job_title:""},
                ],
                costCenterList:[
                    {effective_date:'',cost_center:"", id:""},
                ],
            },
            tableChild : {},
            searchHolderLists: [],
            searchValue: '',
            tableColumn: [],
            redisKey: 'Wallet',
            filter: {},
            sort: {column: "ownership", type: 'descend'}
        }
    }

    /**
     * 卸载
     */
    componentWillUnmount() {
        this.ConfirmSaveStakeholderModels && this.ConfirmSaveStakeholderModels.close();
    }
    
    transferInfo=(key,refName,e)=>{
        this.refs[refName].initData(key,this.state.holderLists[key]);
    }
    transferInfoNew=( id, refName) => {
        const { holderLists } = this.state;
        let info = {};
        let key = 0;
        holderLists.forEach( (v, k)=>{
            if(v.id == id){
                info = {...v}
                key = k;
            }
        });
        this.refs[refName]?.initData(key, info);
    }

    viewHoldings=(key)=>{
        // this.refs.viewStakeholderHoldings.initData(key,this.state.holderLists[key]);
        // this.refs.viewStakeholderHoldings.getStakeholderHoldingsList();
    }
    componentDidMount() {
        if(this.props.match.params.inviteUnregistered=="invite-unregistered"){
            this.setState({
                inviteUnregisteredBtn:2
            })
        }
        this.getCostCenterList();
        this.getData();
    }

    /**
     * 通过costCenter获取costCenterList
     */
    getCostCenterList = () =>{
        post('costCenter', {}).then((response)=>{
            if(response.data.code === 0){
                this.setState({cost_list:response.data.data.cost_center})
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        })
    }

    onPageSizeChange = (current, size) => {
        this.setState({
            pageSize: size,
            pageNumber: 1
        })
	}

    onRemove = () => {
        this.getData();
    }

	onPageChange = (current, pageSize) => {
		this.setState({
            pageNumber: current
        }, () => {
            this.getData()
        })
        
	}

    onApply = () => {
        this.setState({
            pageNumber: 1
        }, () => {
            this.getData()
        })
	}

    onReset = async () => {
        this.setState({
            pageNumber: 1
        })
        const { $formutil } = this.props
        await $formutil.$reset(() => {
            this.stakeholderData = {}
			this.getData()
		})
	}

    handleSelectChange = (data) => {
        this.stakeholderData = data
    }

    handleMenu = (key) => {
		this.setState({
            coinId: key
        }, () => {
            this.getData()
        })
	}

    getData = async () => {
        const { pageNumber, pageSize, coinId, sort, showLabel = 0 } = this.state
        const { $formutil } = this.props
        const { $params } = $formutil.$new()
        const { wallet_address = '' } = $params || {}

        this.setState({ loading: true })
        try {
            const { data: resData } = await post('getWalletList', {
                pageNumber,
                pageSize,
                coin_id: coinId,
                stakeholder_id: this.stakeholderData.id,
                stakeholder_type: this.stakeholderData.type,
                wallet_address,
                showLabel,
                sort: { ...sort, type: orderMap[sort?.type] || 0 }
            })
            const { code, data } = resData;
            if(code === 0) {
                this.setState({ holderLists: data.walletList, searchHolderLists: data.walletList, loaded: true, total: data.total })
            } else {
                message.error(data)
            }
        } catch(err) {
            message.error(err && err.message)
        }

        this.setState({ loading: false })
    }
    clear=()=>{
        var holderLists = this.state.holderLists;
        $('.listCheckbox').prop('checked',false);
        $('#check01').prop("checked",false);

        const { tableChild } = this.state;
        tableChild.clearAll();

        for(let i=0;i<holderLists.length;i++){
            holderLists[i].status = 0;
        }
        this.setState({
            holderLists:holderLists,
            searchHolderLists: holderLists,
        })
    }


    setCurSelectStakeholderNew = (id) => {
        const { holderLists } = this.state;
        let info = {};
        holderLists.forEach( (v, k)=>{
            if(v.id == id){
                info = {...v}
            }
        });
        this.setState({
            curSelectStakeholder: info
        });
    }

    // table内部新增 需要携带数据
    createNewStakeholder = (record, type) => {
        AddStakeholder(record, 'add', this.getData, this.state.cost_list)
    }

    // 顶部新增
    openAddStakeholderModal = () => {
        const userAccessControl = security.companyInfo && security.companyInfo.user_access_control;
        const uid = security.userInfo && security.userInfo.id;
        const companyId = security.companyInfo && security.companyInfo.company_id;
        const allStakeholderControlRemind = localStorage.getItem(`ALL-STAKEHOLDER-CONTROL-REMIND-${uid}-${companyId}`);
        if(allStakeholderControlRemind){
            this.saveStakeholder();
        }else{
            if(userAccessControl > 0){
                this.saveStakeholder();
            }else{
                this.ConfirmSaveStakeholderModels = Modal.open({
                    component: props => <ModalContent title={__('Reminder')} close={props.close}>
                        <ConfirmSaveStakeholderModels
                            history={this.props.history}
                            type={'add'}
                            saveStakeholder={this.saveStakeholder}
                            close={props.close}
                        />
                    </ModalContent>,
                    width: '690px',
                    maskClosable: true,
                    className: 'sprout-modal'
                })
            }
        }
    }

    saveStakeholder = () => {
        AddStakeholder('', 'add', this.getData, this.state.cost_list, this.state.coinId);
    }


    openWalletHolderModal = () => {
        AddWalletHolder('', 'add', this.getData, this.state.coinId)
    }

    // connect
    connectStakeholder = (record, type) => {
        AddStakeholder(record, 'add', this.getData)
    }

    updateName = (record, type) => {
        console.log(this.state.cost_list)
        openUpdateStakerholderModal(record.wallet_address, this.getData, this.state.cost_list)
        // AddWalletHolder(record, 'edit', this.getData)
    }

    editStakeholderOrWallet = (record, type, cost_list) => {
        // EditStakeholder(record,  'edit', this.getData)
        if (type === 'editWallet') {
            AddWalletHolder(record, 'edit', this.getData)
        } else {
            AddStakeholder(record, 'edit', this.getData, this.state.cost_list)
        }
    }

    setModifiedSecurity=(key,security)=>{
        let curStakeholderList = this.state.holderLists;

        curStakeholderList[key] = security;

        this.setState({
            holderLists:curStakeholderList,
            searchHolderLists: curStakeholderList,
        })
    }

    openResendModal = (data) => {
        this.modlaRef = Modal.open({
            component: <ResendEmailModalConteent data={data} />
        })
    }
    inviteUnregistered=()=>{
        post('inviteAllStakeHolder').then((response)=> {
            if(response.data.code === 0){
                message.success("Success!")
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    forceUpdates = () => {
        const { tableChild } = this.state;
        this.clear();
        this.getData();
    }

    holderListStatusChange = (selectedRowKeys) => {
        const { holderLists } = this.state;
        let list = [...holderLists];
        list.forEach( (v, k) => {
            list[k]['status'] = 0;
        });
        list.forEach( (v, k) => {
            selectedRowKeys.forEach( (v1, k1) => {
                if(v1 == v.id){
                    list[k]['status'] = 1
                }
            })
        });
        this.setState(()=>({
            holderLists : list,
            //searchHolderLists: list,
        }))
    }

    tableObj = (ref) => {
        this.setState(()=>({
            tableChild : ref
        }))
    }

    /**
     * column 排序
     * @param sort
     */
    sortTable = sort => {
        this.setState({ sort }, ()=>this.getData())
    }

    /**
     * 更新变量
     */
    updateState = (value) => {
        this.setState(value);
    }

    onCheckChange = (e) => {
        this.setState({
            showLabel: e.target.checked ? 1 : 0,
        }, () => {
            this.getData()
        })
    }


    render(){
        const { loading, loaded, holderLists, pageNumber, pageSize, total, coinId, tableColumn, redisKey, sort, filterList, cost_list } = this.state

        if(loading && !loaded) {
            return(
                <div className="loading_position">
                    <Loading />
                </div>
            )
        }

        let selectCount=0;
        for(let j=0;j<this.state.holderLists.length;j++){
            if(this.state.holderLists[j].status === 1){
                selectCount++;
            }
        }
        const userAccessControl = security.companyInfo && security.companyInfo.user_access_control;

        return(
            <div className="stakeholder-wallets-root">
            <Spin spinning={loading}>
                {/* <EditStakeholder ref={"editStakeholder"} setModifiedSecurity={this.setModifiedSecurity}  relationshipList={this.state.stakeholderRelationshipList}  stakeholders={this} callback={() => { $('#editStakeholderModal').modal('hide'); this.getData() }}/> */}
                <div className="stakeholder-conCenter">
                    <div className="clearfix">
                        <Space>
                            <MergeStakeholdersModels
                                selectCount={selectCount}
                                holderLists={holderLists}
                                history={this.props.history}
                                forceUpdates={this.forceUpdates}
                                clearSelect={this.clear}
                            />
                            <TokenSelector onChange={(key) => this.handleMenu(key)} value={coinId} />
                        </Space>
                        <div className="holders-nav search-group banner-rightBox offset">
                            {selectCount>=1 &&
                                <div className="stateBox" style={{width:'200px', minWidth:'auto'}}>
                                    <p className="items-tip">{printf(__('%s of %s items selected'),selectCount,this.state.holderLists.length)}</p>
                                    <button onClick={this.clear} type="button" className="bg-dark holders-clearBtn">Clear selection</button>
                                </div>
                            }
                            <div className="btn-group">
                                <button className="btn manage-btn width180 bg-darkblue dropdown-toggle"
                                        data-toggle="dropdown">Manage stakeholders<span
                                    className="triangle triangle-whitedown action-triangle"></span></button>
                                <ul className="dropdown-menu mange-downmenu width180">
                                    <li><a className="add-stake" type="button" data-toggle="modal"
                                           onClick={this.openAddStakeholderModal} data-target="#addStakeholderModal">Add
                                        stakeholder</a></li>
                                    <li><a onClick={this.openWalletHolderModal} className="add-stake" type="button" data-toggle="modal"
                                           data-target="#addStakeholderModal">Add wallet</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="search-area-content">
                        <div className="search-area" style={{ margin: '20px 0 0 0' }}>
                            <Row gutter={24}>
                                <Col span={9}>
                                    <FormItem
                                        name="stakeholder"
                                        itemProps={{
                                            label: 'Stakeholder',
                                            labelAlign: 'left'
                                        }}
                                    >
                                        <SelectStakeholderWalletLabel allowClear={false} placeholder={__('Select')} onSelect={this.handleSelectChange} />
                                    </FormItem>
                                </Col>
                                <Col span={9}>
                                    <FormItem
                                        name="wallet_address"
                                        itemProps={{
                                            label: 'Wallet Address',
                                            labelAlign: 'left'
                                        }}
                                    >
                                        <Input allowClear placeholder="Wallet Address" />
                                    </FormItem>
                                </Col>
                                    <Col span={6}>
                                    <Button onClick={this.onApply.bind(this)} type="primary">Apply</Button>
                                    <Button onClick={this.onReset.bind(this)} style={{ marginLeft: '10px' }}>Reset</Button>
                                </Col>
                            </Row>
                        </div>
                        <div className="search-area-right">
                            <Checkbox onChange={this.onCheckChange}>Show only labelled wallets</Checkbox>
                            <ColumnController filterList={tableColumn} redisKey={redisKey} thisObj={this} />
                        </div>
                    </div>
                    
                    
                    {/* <Button type="primary" style={{ top: '5px' }}><img height="24" src={filterIcon} alt="icon" /></Button> */}
                    <div className=" table-box" style={{ marginTop: '10px' }}>

                        <StakeholdersTableModels
                            curSelectRelation={this.state.curSelectRelation}
                            holderLists={holderLists}
                            paginationConfig={{
                                total,
                                current: pageNumber,
                                pageSize: pageSize,
                                onShowSizeChange: this.onPageSizeChange,
                                onChange: this.onPageChange,
                                showSizeChanger: true,
                                showTotal: (sum) => <span>{sum} {__('items')}</span>
                            }}
                            filterList={filterList}
                            cost_list={ cost_list }
                            tableColumn={columnsFilter(tableColumn, redisKey, sort)}
                            onRemove={this.onRemove}
                            transferInfoNew={this.transferInfoNew}
                            setCurSelectStakeholderNew={this.setCurSelectStakeholderNew}
                            createNewStakeholder={this.createNewStakeholder}
                            connectStakeholder={this.connectStakeholder}
                            updateName={this.updateName}
                            editStakeholderOrWallet={this.editStakeholderOrWallet}
                            openResendModal={this.openResendModal}
                            holderListStatusChange={this.holderListStatusChange}
                            onRef={this.tableObj}
                            updateState={this.updateState}
                            sortTable={this.sortTable}
                        />

                    </div>
                </div>
                </Spin>
            </div>
        )
    }

}

export default withForm(Wallets)