import React, { useState } from 'react';
import Utils from "Utils";
import DeleteDraftSecurity from "../components/DeleteDraftSecurity";
import CreateDraftSet from "../components/CreateDraftSet";
import RenameDraftSet from "../components/RenameDraftSet";
import DeleteDraftSet from "../components/DeleteDraftSet";
import SendInfoForSignature from "../components/SendInfoForSignature";
import { post } from 'srcPath/http';
import axios from "axios";
import moment from 'moment';
import Loading from 'components/Loading';
import Permission from 'components/Permission';
import security from 'stores/security';
import { formatNumber, formatCurrencyNumber, formatDateForDatePicker } from "Utils";
import { withForm, FormItem } from 'react-antd-formutil';
import {Input, TimePicker, DatePicker, Select, Button, messageCheckbox, Checkbox, Tooltip} from 'antd';
import SelectStakeholder from 'components/SelectStakeholder'
import DraftSharesTable from "./components/DraftSharesTableModels/index";
import { I, filterAmount, setCursorPos } from 'Utils';
import './style.scss';
import { string } from "mathjs";
import {calculator, formatWithArray} from "../../../Utils";
import DateSelector from "../../../../components/DateSelector";
import { relationshipList, countries } from "Utils/constant";
import CountrySelector from 'components/CountrySelector';
import EnterShareInfomation from "./components/DraftSharesBatchUploadModel/EnterCsInfomationTwo";
import Modal, { ModalContent } from 'components/Modal';
import {ExclamationCircleOutlined, DeleteOutlined, CloseOutlined} from "@ant-design/icons";
import { printf, sprintf } from 'Utils/i18n';

const options = []

class DraftShares extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIds: [],
            debtVal:0,
            selectedData: {},
            loadingStatus: 1,
            curPlanId: 0,
            sendResult: {
                "1": {
                    "id": 1,
                    "is_success": 1,//1-成功，0表示失败
                    "error_reason": ""
                },
                "4": {
                    "id": 4,
                    "is_success": 0,//1-成功，0表示失败
                    "error_reason": [
                        { "title": "Name", "message": "Can't be empty!" },
                        { "title": "age", "message": "Can't be bigger than 150!" },
                        { "title": "Certificate Id", "message": "Duplicated" }
                    ]
                },
            },
            successDetails: {},
            showError: {},
            saveStatus: -1,
            listKey: -1,
            type: "SHARE_CLASS",
            addingStatus: 0,
            curSelectRelation: "all",
            draft_name: "",
            draft_id: "",
            currency: "",
            shareClassDraftSetList: [
                { id: 11, name: "24/08/2020", count: "2" },
                // {id:2,name:"25/08/2020",count:"1"},
                // {id:3,name:"26/08/2020",count:"2"},
            ],
            curSelectShareClassDraftSet: { id: 1, name: "24/08/2020", count: "2" },
            securityLists: [
                { security_name: 'CS-5', stakeholder: 'Cody Belseager', security_status: 'Outstanding', shares: '100', price: '1.00', transaction_value: '100.00', issue_date: '12/12/2019', status: 'unchecked', sign_status: 'officerSignature' },
                { security_name: 'CS-5', stakeholder: 'Cody Belseager', security_status: 'Outstanding', shares: '100', price: '1.00', transaction_value: '100.00', issue_date: '12/12/2019', status: 'unchecked', sign_status: 'holderSignature' },

            ],
            data: [],
            checkedId: [],
            checkedDraftSecurity: [],
            draftSharesRequiredKey: [
                "board_approval_date",
                "email",
                "issue_date",
                "relationship",
                "name",
                "quantity",
            ],
            stakeholderRelationshipList: [
                "Advisor",
                "Board member",
                "Consultant",
                "Employee",
                "Ex-employee",
                "Executive",
                "Founder",
                "International employee",
                "Investor",
                "Officer",
            ],
            shareClassList: [
                { id: "66", share_class_name: "cccccc", prefix: "22" },
                { id: "67", share_class_name: "rrr", prefix: "23" },
                { id: "68", share_class_name: "ggg", prefix: "24" },
            ],
            formatShareClassList: {
                66: { id: "66", share_class_name: "cccccc", prefix: "22" },
            },
            cnSecuritiesList: [
                { id: 3, custom_label_id: 4, principal:1000},
            ],
            currencyList: ['USD', 'HKD', 'SGD',],
            schedule: [
                { id: 1, schedule_name: "" },
            ],
            pageData: {
                name: "Certificate",
                title: "Certificate",
                text: "certificate",
                error_status: 0,
                checked_count: "",
                type: 0
            },
            dateFormat: 'DD/MM/YYYY',
            genderList: [
                {
                    name: 'Female'
                },
                {
                    name: 'Male'
                },
                {
                    name: 'Non-binary / non-conforming'
                },
                {
                    name:'Prefer not to respond'
                },
            ],
            stakeholderType: [
                {
                    name: __('Individual'),
                    value: 1
                },
                {
                    name: __('Non-individual'),
                    value: 0
                },
            ],
        }
    }

    transferDraftSetData = (key, refName, e) => {
        this.refs[refName].initData(key, this.state.shareClassDraftSetList[key]);
    }
    getCheckedId = () => {
        this.refs.deleteDraftSecurity.getCheckedId();
    }
    getDeleteDraftId = () => {
        this.refs.deleteDraftSecurity.getDeleteDraftId();
    }
    changeStatus = (key) => {
        var securityLists = this.state.data;
        if (securityLists[key].checkbox_status == false) {
            securityLists[key].checkbox_status = true;
            if (securityLists[key].is_show_error == 1) {
                $(".draft_error_shares_tip" + key).removeClass("showErrorTip")
            }
        } else {
            securityLists[key].checkbox_status = false;
            if (securityLists[key].is_show_error == 1) {
                $(".draft_error_shares_tip" + key).addClass("showErrorTip")
            }
            $('#cs_check01').prop("checked", false);
        }
        this.setState({
            data: securityLists,
        })
    }

    /**
     * checkbox change事件
     * @param selectedRowKeys
     */
    securityListsChange = (selectedRowKeys) => {
        const { data, listKey } = this.state;
        let checked = false;
        let securityLists = [...data];
        securityLists.forEach((v, k) => {
            securityLists[k]['checkbox_status'] = false;
        });
        selectedRowKeys.forEach((v) => {
            securityLists.forEach((item) => {
                if (item.id == v) {
                    item['checkbox_status'] = true;
                    checked = true;
                }
            })
        })
        this.setState({data: securityLists, listKey: checked ? -1 : listKey, checkedIds: [].concat(selectedRowKeys)});
    }
    componentDidMount() {
        this.getShareClassDraftSetList();
    }
    getShareClassDraftSetList = () => {
        post('getDraftSetList', { type: "SHARE_CLASS" }).then((response) => {
            const cnSecurityList = response.data.data.cnSecuritiesList.map(({id,custom_label_id,name,email,extra_info})=>({id,custom_label_id,name,email,extra_info}))
            if (response.data.code === 0) {
                for (let i = 0; i < response.data.data.draftList.length; i++) {
                    response.data.data.draftList[i].checkbox_status = false;
                    response.data.data.draftList[i].is_show_error = 0;
                    if (this.props.match.params.activeKey) {
                        response.data.data.draftList[0].convertible_ids[0] = this.props.match.params.activeKey
                        const status = cnSecurityList.findIndex(item => item.id == this.props.match.params.activeKey)
                        const stakeholderName = cnSecurityList[status].name
                        const stakeholderEmail = cnSecurityList[status].email
                        const debtValue = cnSecurityList[status].extra_info.principal
                        response.data.data.draftList[0].debt_canceled = debtValue
                        response.data.data.draftList[0].name = stakeholderName
                        response.data.data.draftList[0].email = stakeholderEmail
                    }
                }
                var formatObj = {};
                for (let j = 0; j < response.data.data.shareClassList.length; j++) {
                    formatObj[response.data.data.shareClassList[j].id] = response.data.data.shareClassList[j];
                }
                const { draftList } = response.data.data;
                let draftListNew = [...draftList];
                draftListNew.map((v, k) => {
                    draftListNew[k]['quantity'] = formatWithArray(v.quantity);
                    draftListNew[k]['cash_paid'] = formatWithArray(v.cash_paid);
                    draftListNew[k]['debt_canceled'] = formatWithArray(v.debt_canceled);
                    draftListNew[k]['value_of_ip_transferred'] = formatWithArray(v.value_of_ip_transferred);
                    draftListNew[k]['other_consideration'] = formatWithArray(v.other_consideration);
                });
                //根据 custom_label_id 重新排序
                draftListNew.sort((x, y) => {
                    const stringA = x['custom_label_id'].toUpperCase();
                    const stringB = y['custom_label_id'].toUpperCase();
                    return stringA < stringB ? -1 : stringA > stringB ? 1 : 0;
                });
                this.setState({
                    shareClassDraftSetList: response.data.data.setList,
                    data: draftListNew,
                    draft_id: response.data.data.defaultSetInfo.id,
                    draft_name: response.data.data.defaultSetInfo.name,
                    shareClassList: response.data.data.shareClassList,
                    formatShareClassList: formatObj,
                    // cnSecuritiesList: response.data.data.cnSecuritiesList,
                    cnSecuritiesList: cnSecurityList,
                    currency: response.data.data.currency,
                    schedule: response.data.data.vestingScheduleList,
                    loadingStatus: 0,
                });
                for (let i = 0; i< cnSecurityList.length; i++){
                    const optionItem = {
                        label: cnSecurityList[i].custom_label_id + ' (' + cnSecurityList[i].name +')',
                        value: cnSecurityList[i].id
                    }
                    options[i] = optionItem
                }
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    recordConvertible = (e) => {
        let listKey = this.state.listKey;
        let curData = this.state.data;
        curData[listKey].convertible_ids = e ;
        this.setState({
            data: curData,
        },()=>this.setDebtValue())
    }

    setDebtValue = () =>{
        const { add } = calculator;
        const { listKey, cnSecuritiesList } = this.state;
        let curData = this.state.data;
        let debtValue = 0;
        if(listKey!= -1 && curData[listKey].convertible_ids!=[]){
            curData[listKey].convertible_ids.map((v,k)=>{
                const status = cnSecuritiesList.findIndex(item => item.id == v)
                debtValue = add (debtValue, cnSecuritiesList[status].extra_info.principal)
            })
            curData[listKey].debt_canceled = debtValue
            this.setState({
                debtVal: debtValue,
                data: curData
            })
        }
    }

    recordData = (e) => {
        let listKey = this.state.listKey;
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[listKey][dataKey] = dataVal;
        if (dataKey == 'stakeholder_type' && dataVal == 0) {
            curData[listKey].job_title_list = [
                { effective_date: '', job_title: "" },
            ]
            curData[listKey].gender = ''
            curData[listKey].date_of_birth = ''
            curData[listKey].annual_salary = ''
        }
        this.setState({
            data: curData
        })
        if (dataKey == "security_plan_id" || dataKey == "relationship" || dataKey == "vesting_schedule_id") {
            this.saveDraft();
        }
    }
    recordNumber = (e) => {
        let listKey = this.state.listKey;
        let curData = this.state.data;
        let curVal = e.target.value;
        let dataKey = e.target.name;
        // if(dataKey=="quantity"){
        //     curData[listKey][dataKey] = Utils.checkIntInputWithoutComma(curVal);
        // }else{
        //     curData[listKey][dataKey] = Utils.check2DecimalNumInputWithoutComma(curVal);
        // }
        if (dataKey == "quantity") {
            curData[listKey][dataKey] = Utils.check5DecimalNumInputWithoutComma(curVal);
        } else {
            curData[listKey][dataKey] = Utils.check2DecimalNumInputWithoutComma(curVal);
        }
        this.setState({
            data: curData
        });
    }

    /**
     * input change通用事件
     * @param isDecimal 是否需要小数
     * @param e
     */
    inputChange = (isDecimal, e) => {
        const { target: element } = e;
        const { name: dataKey } = element;
        const { data, listKey } = this.state;
        const filter = filterAmount(e, 9999999999999, isDecimal);
        let curData = [...data];
        curData[listKey][dataKey] = filter.value;
        this.setState({
            data: curData
        }, () => {
            element.selectionStart = element.selectionEnd = filter.cursorPositionX;
        });
    }

    /**
     * input change通用事件
     * @param isDecimal 是否需要小数
     * @param e
     * 5 digit
     */
    inputChange5Digit = (isDecimal, e) => {
        const { target: element } = e;
        const { name: dataKey } = element;
        const { data, listKey } = this.state;
        const filter = filterAmount(e, 9999999999999, isDecimal, 5);
        let curData = [...data];
        curData[listKey][dataKey] = filter.value;
        this.setState({
            data: curData
        }, () => {
            element.selectionStart = element.selectionEnd = filter.cursorPositionX;
        });
    }

    setDataFormat = (e) => {
        let listKey = this.state.listKey;
        let curData = this.state.data;
        let curVal = e.target.value;
        let dataKey = e.target.name;
        curData[listKey][dataKey] = Utils.resetNumberWithCommas(curVal);
        this.setState({
            data: curData
        });
    }
    resetDataFormat = (e) => {
        const isFractionalShare = security.companyInfo.fractional_control;
        let listKey = this.state.listKey;
        let curData = this.state.data;
        let curVal = e.target.value;
        let dataKey = e.target.name;
        if (dataKey == "quantity") {
            if (isFractionalShare == 1) {
                curData[listKey][dataKey] = formatNumber(curVal, 5);
            } else {
                curData[listKey][dataKey] = formatNumber(curVal, null);
            }
        } else {
            curData[listKey][dataKey] = formatNumber(curVal);
        }
        this.setState({
            data: curData
        });
        this.saveDraft(e);
    }
    changeDraftId = (key1, key2) => {
        this.setState({
            draft_id: key1,
            draft_name: key2
        })
    }
    changeCheckedStatus = (key) => {
        let listKey = this.state.listKey;
        let curChecked = this.state.data;
        curChecked[listKey][key].checked = !curChecked[listKey][key].checked;
        this.setState({
            data: curChecked
        })
    }
    recordChecked = () => {
        let listKey = this.state.listKey;
        let curData = this.state.data;
        if (curData[listKey].is_certificate_shares == 0) {
            curData[listKey].is_certificate_shares = 1
        } else {
            curData[listKey].is_certificate_shares = 0
        }
        this.setState({
            data: curData
        })
        this.saveDraft();
    }
    fileSelect = (e) => {
        let fileObj = e.target.files[0];
        let listKey = this.state.listKey;
        let dataKey = e.target.name;
        Utils.uploadFile(fileObj).then((result) => {
            //获取选择文件的fileObj
            let curData = this.state.data;
            curData[listKey][dataKey][0].name = fileObj.name;
            curData[listKey][dataKey][0].file_obj = result;
            this.setState({
                data: curData
            });
            this.saveDraft();
        })
    }
    createShareClassDraft = () => {
        if (this.state.addingStatus == 1) {
            return;
        }
        this.setState({
            addingStatus: 1
        })
        let curData = this.state.data;
        for (let i = 0; i < this.state.data.length; i++) {
            this.state.data[i].checkbox_status = false
        }
        this.setState({
            data: curData
        });
        post('createShareClassDraft', { draft_set_id: this.state.draft_id, security_plan_id: this.state.shareClassList[0].id }).then((response) => {
            let curData = this.state.data;
            let draftSetList = this.state.shareClassDraftSetList;
            let setId = this.state.draft_id;
            if (response.data.code === 0) {
                response.data.data.checkbox_status = false;
                response.data.data.is_show_error = 0;
                if (this.props.match.params.activeKey) {
                    response.data.data.convertible_ids[0] = this.props.match.params.activeKey
                }
                curData.push(response.data.data);
                for (let i = 0; i < curData.length; i++) {
                    curData[i].is_show_error = 0
                }
                for (let j = 0; j < draftSetList.length; j++) {
                    if (draftSetList[j].id == setId) {
                        draftSetList[j].count = parseInt(draftSetList[j].count)+1
                    }
                }

                this.setState({
                    data: curData,
                    addingStatus: 0,
                    // listKey: this.state.data.length - 1,
                    shareClassDraftSetList: draftSetList
                })
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
        // this.getShareClassDraftSetList();
    }
    transferKey = (key) => {
        const { listKey, data } = this.state;
        let curKey = listKey;
        let list = [...data];
        // let curData = this.state.data;
        if (curKey === key) {
            curKey = -1;
        } else {
            curKey = key;
            list.forEach((v, k)=>{
                v.checkbox_status = false;
                list[k] = {...v};
            });
        }
        $('.collapse').removeClass('in');
        this.setState({
            listKey: curKey,
            data: list,
            // data:curData,
        })
    }

    closeDraftLeft = () => {
        this.setState({ listKey: -1 });
    }

    selectDraftSet = (key) => {
        let curDraftList = this.state.shareClassDraftSetList;
        this.setState({
            draft_name: curDraftList[key].name,
            draft_id: curDraftList[key].id,
            listKey: -1
        });
        post('getDraftSecurityListById', { id: curDraftList[key].id }).then((response) => {
            if (response.data.code === 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    response.data.data[i].checkbox_status = false;
                    response.data.data[i].is_show_error = 0;
                }
                this.setState({
                    data: response.data.data,
                    listKey: -1
                })
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    getDraftSecurityList = () => {
        post('getDraftSecurityListById', { id: this.state.draft_id }).then((response) => {
            if (response.data.code === 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    response.data.data[i].checkbox_status = false;
                    response.data.data[i].is_show_error = 0;
                }
                this.setState({
                    data: response.data.data,
                    listKey: -1
                })
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    /**
     * 设置certificate之后更新列表
     */
    refreshDraftSecurityList = () => {
        post('getDraftSecurityListById', { id: this.state.draft_id }).then((response) => {
            if (response.data.code === 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    response.data.data[i].checkbox_status = false;
                    response.data.data[i].is_show_error = 0;
                }
                this.setState({
                    data: response.data.data,
                })
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    /**
     * 更新table checkbox
     * @param selectCount 选中总计
     * @param selectIndexArray 有更新的checkbox下标和状态
     * @param isEmpty checkbox是否为全不选状态
     */
    updateSelectCount = (selectCount, selectIndexArray, checkedIdArray, isEmpty = false) => {
        const { data, listKey } = this.state;
        let list = [...data];
        if (isEmpty) {
            list.forEach((v, k) => {
                list[k]['checkbox_status'] = false;
            })
        } else {
            selectIndexArray.forEach((v, k) => {
                list[v.selectIndex]['checkbox_status'] = v.checked;
            })
        }

        this.setState(() => ({
            selectCount,
            selectIndex: selectIndexArray[0],
            data: list,
            listKey: selectCount > 0 ? -1 : listKey,
            checkedIds: [].concat(checkedIdArray)
        }))
    }

    saveDraft = (e) => {
        const { listKey } = this.state;
        if (this.state.saveStatus == 1) {
            return;
        }
        this.setState({
            saveStatus: 0
        })
        let requestData = { ...this.state.data[listKey] };
        requestData.relationship_list = JSON.stringify(requestData.relationship_list)
        requestData.job_title_list = JSON.stringify(requestData.job_title_list)
        post('editShareClassDraft', requestData).then((response) => {
            if (response.data.code === 0) {
                if (response.data.data.certificate) {
                    requestData['certificate'] = response.data.data.certificate;
                    this.refreshDraftSecurityList();
                }
                this.setState({
                    saveStatus: 1
                })
                setTimeout(() => {
                    this.setState({
                        saveStatus: -1
                    });
                }, 300)
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    handleDraftShareDateChange = (key, moment_key, date) => {
        const { data, listKey, dateFormat } = this.state;
        let curData = [...data];
        curData[listKey][key] = date.format(dateFormat);
        curData[listKey][moment_key] = date;
        this.setState({
            data: curData
        });
        this.saveDraft();
    }
    removeSuccessData = (result) => {
        //第一步, 将需要删除的id拿到
        let delIds = [];
        for (let k in result) {
            delIds.push(parseInt(k));
        }
        // 将delIds正序排列
        delIds.sort();
        //开始删除
        let curData =[...this.state.data];
        let newData =[...this.state.data];
        for (let i = curData.length-1; i>=0; i--) {
            for (let j = 0; j< delIds.length;  j++) {
                if (curData[i].id == delIds[j]) {
                    newData.splice(i, 1);
                }
            }
        }
        this.setState({
            data: newData,
            listKey: -1
        })
    }
    getErrorMessage = (result) => {
        this.setState({
            sendResult: result,
        })
        let curData =[...this.state.data];
        for (let i = 0; i < this.state.data.length; i++) {
            if (this.state.sendResult.hasOwnProperty(this.state.data[i].id)) {
                this.state.data[i].is_show_error = 1
            }
        }
        this.setState({
            data: curData
        })
    }
    selectCount = () => {
        let selectLength = this.state.pageData;
        let selectCount = 0;
        for (let j = 0; j < this.state.data.length; j++) {
            if (this.state.data[j].checkbox_status === true) {
                selectCount++;
            }
        }
        selectLength.checked_count = selectCount;
        this.setState({
            pageData: selectLength
        })
        this.refs.sendForSignature.getSignatureAdminList();
        this.refs.sendForSignature.getCheckedId();
    }
    handleSelectChange = (name, data) => {
        let curData = this.state.data;
        let listKey = this.state.listKey;
        if (data) {
            curData[listKey].name = data.nick_name || "";
            curData[listKey].email = data.email || "";
        }
        this.setState({
            selectedData: data,
            data: curData
        })
    }
    CheckboxChange(isChecked) {
        const { data, listKey } = this.state;
        let list = [...data];
        if (isChecked == 1) {
            list[listKey].relationship_list = [
                { effective_date: '', relationship: "" },
            ]
            list[listKey].job_title_list = [
                { effective_date: '', job_title: "" },
            ]
            list[listKey].employee_id = ''
            list[listKey].passport = ''
            list[listKey].date_of_birth = ''
            list[listKey].gender = ''
            list[listKey].annual_salary = ''
            list[listKey].country = ''
            list[listKey].address_details = ''
            list[listKey].name = ''
            list[listKey].email = ''
            list[listKey].stakeholder_type = ''
        } else {
            list[listKey].name = ''
            list[listKey].email = ''
            list[listKey].relationship_list = [
                { effective_date: Utils.getCurDate(), relationship: "Investor" },
            ]
            list[listKey].job_title_list = [
                { effective_date: Utils.getCurDate(), job_title: "" },
            ]
            list[listKey].stakeholder_type = 1
        }
        list[listKey].choose_type = isChecked
        this.setState({
            data: list
        })
        this.saveDraft()
    }
    relationshipEffctiveDateChange = (listName, name, key, date) => {
        if (!date) {
            return
        }
        const { data, dateFormat, listKey } = this.state;
        let curData = this.state.data[listKey];
        curData[listName][key][name] = date && date.format(dateFormat);
        data[listKey] = curData
        this.setState({
            data
        });
    }
    setRelation = (key, e) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        curList.relationship_list[key].relationship = e.target.value;
        data[listKey] = curList
        this.setState({
            data
        })

    }
    addRelation = () => {
        const { data, listKey } = this.state;
        let one = { effective_date: Utils.getCurDate(), relationship: "Investor" }
        let curList = this.state.data[listKey];
        curList.relationship_list.push(one);
        data[listKey] = curList
        this.setState({
            data
        })
    }
    delRelation = (num) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        if (curList.relationship_list.length <= 1) {
            return;
        }
        curList.relationship_list.splice(num, 1);
        data[listKey] = curList
        this.setState({
            data
        })
    }
    JobRecordData = (key, e) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        curList.job_title_list[key].job_title = e.target.value;
        data[listKey] = curList
        this.setState({
            data
        })
    }
    handleDraftOptionDateChange = (key, moment_key, date) => {
        const { data, listKey, dateFormat } = this.state;
        let curData = [...data];
        curData[listKey][key] = date ? date.format(dateFormat) : '';
        curData[listKey][moment_key] = date || '';
        this.setState({
            data: curData
        });
        this.saveDraft();
    }
    delJobTitle = (num) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        if (curList.job_title_list.length <= 1) {
            return;
        }
        curList.job_title_list.splice(num, 1);
        data[listKey] = curList
        this.setState({
            data
        })
        this.saveDraft()
    }
    addJobTitle = () => {
        let one = { effective_date: Utils.getCurDate(), job_title: "" }
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        curList.job_title_list.push(one);
        data[listKey] = curList
        this.setState({
            data
        })
    }
    countryRecordData = (val) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        curList['country'] = val
        data[listKey] = curList
        this.setState({
            data
        })
    }
    bindBatchUploadModel = () => {
        this.modalRef = Modal.open({
            component: props => <ModalContent close={props.close} title={__('Bulk upload')}>
                {/* historyList={eaHistoryList} callback={this.getEaHistoryList} */}
                <EnterShareInfomation close={props.close}  callback={this.getShareClassDraftSetList}/>
            </ModalContent>,
            className: 'sprout-modal email-modal',
            width: 1300
        })
    }

    getSecurity = (value) => {
        let { eaPlanList } = this.props
        let status = eaPlanList.findIndex(item => item.id == value.security_plan_id)
        if(eaPlanList[status]) {
            let customLabel = value.custom_label_id.substring(0, eaPlanList[status].prefix.length) == eaPlanList[status].prefix ? value.custom_label_id : (eaPlanList[status].prefix + '-' + value.custom_label_id);
            if (status != -1) {
                return customLabel
            } else {
                return '-'
            }
        }
    }

    bindStakeholders = () =>{
        this.props.history.push('/home/stakeholders/stakeholders-list')
    }

    render() {
        let isFractionalShare = security.companyInfo.fractional_control;
        if (this.state.addingStatus === 1) {
            return (
                <div className="loading_position">
                    <Loading />
                </div>
            )
        }
        let selectCount = 0;
        let listKey = this.state.listKey;
        if (this.state.data.length > 0) {
            for (let j = 0; j < this.state.data.length; j++) {
                if (this.state.data[j].checkbox_status == true) {
                    selectCount++;
                }
            }
        }
        let actionButtonClass = 'btn action-disabledBtn action-btn dropdown-toggle';
        let sendButtonClass = 'btn boxbtn-to send-newdisabledBtn';

        if (selectCount >= 1) {
            actionButtonClass = 'btn action-availableBtn action-btn dropdown-toggle';
            sendButtonClass = 'btn boxbtn-to send-newavailableBtn';
        }
        const { currency = '', data, formatShareClassList, dateFormat, orignData, debtVal, shareClassList } = this.state;
        const { selectedData, genderList, stakeholderRelationshipList, stakeholderType } = this.state
        const certificateKey = shareClassList.findIndex(v=>v?.id == data[listKey]?.security_plan_id);
        return (
            <div className="draftsContent">
                <RenameDraftSet ref="renameDraftSet" getDraftSecurityList={this.getDraftSecurityList} getDraftSetList={this.getShareClassDraftSetList} draft_name={this.state.draft_name} draft_id={this.state.draft_id} changeDraftId={this.changeDraftId} />
                <DeleteDraftSet ref="deleteDraftSet" getDraftSecurityList={this.getDraftSecurityList} getDraftSetList={this.getShareClassDraftSetList} draft_name={this.state.draft_name} draft_id={this.state.draft_id} changeDraftId={this.changeDraftId} />
                <DeleteDraftSecurity ref="deleteDraftSecurity" curData={this.state.data} getDraftSecurityList={this.getDraftSecurityList} listKey={this.state.listKey} checkedIds={this.state.checkedIds} />
                <CreateDraftSet type={this.state.type} setList={this.state.shareClassDraftSetList} getDraftSecurityList={this.getDraftSecurityList} getShareClassDraftSetList={this.getShareClassDraftSetList} draft_name={this.state.draft_name} draft_id={this.state.draft_id} changeDraftId={this.changeDraftId} />
                <SendInfoForSignature curPlanId={this.state.curPlanId} removeSuccessData={this.removeSuccessData} getErrorMessage={this.getErrorMessage} ref="sendForSignature" pageData={this.state.pageData} curData={this.state.data} checkedIds={this.state.checkedIds} />

                <div className="">
                    <div className={`prefer-left ${listKey < 0 && 'prefer-left-max'}`}>
                        <div className="holders-nav minWidth400 clearfix">
                            <div className="action-box onset">
                                <div className="btn-group">
                                    <button type="button" className={actionButtonClass} data-toggle="dropdown">{__('Actions')}<span className="triangle triangle-whitedown action-triangle"></span></button>
                                    {selectCount >= 1 &&
                                        <ul className="dropdown-menu mange-downmenu action-downmenu">
                                            <Permission type="securities_draftShares_delete" hide><li><a data-toggle="modal" onClick={this.getCheckedId} data-target={"#deleteDraftSecurityModal"} >{__('Delete')}</a></li></Permission>
                                        </ul>
                                    }
                                </div>
                                <div className="btn-group drafts-btn-group draft_share_btnGroup">
                                    <a className="dropdown-toggle text-blue blue-triangle-down drafts-btn" data-toggle="dropdown">{this.state.draft_name}&nbsp;&nbsp;</a>
                                    <ul className="dropdown-menu drafts-downmenu">
                                        {this.state.shareClassDraftSetList.map((value, key) => {
                                            return (
                                                <li key={key}>
                                                    <a className="resetDrafts" value={value.id} onClick={this.selectDraftSet.bind(this, key)}>{value.name}
                                                        {/*{value.count >0 &&*/}
                                                        <DeleteOutlined className='drafts-trash-can' onClick={this.transferDraftSetData.bind(this, key, "deleteDraftSet")} data-toggle="modal" data-target="#deleteDraftSetModal" />
                                                        {/*<span className="drafts-trash-can glyphicon glyphicon-trash" type="button" onClick={this.transferDraftSetData.bind(this, key, "deleteDraftSet")} data-toggle="modal" data-target="#deleteDraftSetModal"></span>*/}
                                                        {/*}*/}
                                                        <span className="drafts-icon" type="button" onClick={this.transferDraftSetData.bind(this, key, "renameDraftSet")} data-toggle="modal" data-target="#enterNewNameForDraftsModal">{value.count}</span>
                                                    </a>
                                                </li>
                                            )
                                        })}
                                        <li><a data-toggle="modal" data-target="#createNewDraftSetModal">{__('Create a new draft set')}</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="form-group search-group banner-rightBox offset">
                                <Permission type="securities_draftShares_addCertificate" hide>
                                    <div className="btn-group marginLf10">
                                        <a className="btn boxbtn-to send-availableBtn" onClick={this.bindBatchUploadModel}>{__('Bulk upload')}</a>
                                    </div>
                                    <div className="btn-group marginLf10">
                                        <a className="btn boxbtn-to bg-darkblue" onClick={this.createShareClassDraft}>+ {__('Add Certificate')}</a>
                                    </div>
                                </Permission>
                                <Permission type="securities_draftShares_send" hide>
                                    <div className="btn-group marginLf10">
                                        {selectCount >= 1 && <a onClick={this.selectCount} data-toggle="modal" data-target="#sendInfoForSignatureModal" className={sendButtonClass}>{__('Send')}</a>}
                                        {selectCount == 0 && <a className={sendButtonClass}>{__('Send')}</a>}
                                    </div>
                                </Permission>
                            </div>
                        </div>
                        {this.state.loadingStatus === 1 &&
                            <div className="loading_position">
                                <Loading />
                            </div>
                        }
                        {this.state.loadingStatus === 0 &&
                            <div>
                                {this.state.data.length == 0 &&
                                    <div className="empty_option ">
                                        <div className="empty_option_box marginTop80">
                                            <span className="empty_option_icon bg_draft_file"></span>
                                            <h5 className="empty_option_text">{__('This set has no certificate.')}</h5>
                                            <Permission type="securities_draftShares_addCertificate" hide>
                                                <button className="btn action-availableBtn action-btn white-text font_weight500"
                                                    onClick={this.createShareClassDraft}>
                                                        {__('Add Certificate')}
                                                </button>
                                            </Permission>
                                        </div>
                                    </div>
                                }
                                {this.state.data.length >= 1 &&
                                    <div className="draftList">
                                        <div className="scroll-div">
                                            <DraftSharesTable
                                                tableList={data}
                                                transferKey={this.transferKey}
                                                formatShareClassList={formatShareClassList}
                                                listKey={listKey}
                                                securityListsChange={this.securityListsChange}
                                                updateSelectCount={this.updateSelectCount}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <div className={`prefer-right ${listKey < 0 && 'prefer-right-max'}`}>
                        <div>
                            {listKey == -1 &&
                                <div className="empty_option">
                                    <div className="empty_option_box marginTop186">
                                        <span className="edit-icon"></span>
                                        <h5 className="edit_text font_weight500">{__('Select a draft to edit')}</h5>
                                    </div>
                                </div>
                            }
                            {listKey >= 0 &&
                                <div>
                                    {listKey >= 0 && data[listKey].is_show_error == 1 &&
                                        <div className="error_details error_tip_bar bg-lightred alert alert-dismissible" role="alert">
                                            <div className="error_icon_box bg-redbtn">
                                                <ExclamationCircleOutlined style={{color:'#d42f2e'}} />
                                            </div>
                                            <div className="error_tip_text bg-lightred font_weight500">
                                                <h5 className="error_tip_text">{__('There were some problems with this certificate.')}</h5>
                                                <div className="error_list">
                                                    {this.state.sendResult && this.state.sendResult[this.state.data[listKey].id].error_reason.map((val, k) => {
                                                        return (
                                                            <p className="marginTop10 mb-0" key={k}>{val.title}: {val.message}</p>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            <div className="error_close bg-redbtn">
                                                <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                            </div>
                                        </div>
                                    }
                                    {this.state.saveStatus == 0 &&
                                        <div className="banner-download draft_save_banner bg-apple-green no-marginBottom ">
                                            <div className="circle-border draft-circle-border"></div>
                                            <p className="download-message">{__('Saving')}</p>
                                        </div>
                                    }
                                    {this.state.saveStatus == 1 &&
                                        <div className="banner-download draft_save_banner bg-apple-green no-marginBottom ">
                                            <span className="colorWhite glyphicon glyphicon-ok"></span>
                                            <p className="download-message">{__('Draft saved')}</p>
                                        </div>
                                    }
                                    <ul>
                                        <li className="draft-titLi clearfix">
                                            <div className="draft-titleLi-group">
                                                <p className="onset">{__('Certificate')} ({shareClassList?.[certificateKey]?.prefix}-{data[listKey]?.certificate})</p>
                                                <CloseOutlined onClick={this.closeDraftLeft} />
                                            </div>
                                        </li>
                                        <li>
                                            <a className="prefer-togglebtn" href="#certificate_li" data-toggle="collapse" >{__('Certificate')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                            <div className="collapse" id="certificate_li">
                                                <form className="row">
                                                    <div className="form-group col-xs-6">
                                                        <label htmlFor="draft_share_class">{__('Share class')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                        <select id="draft_share_class" name="security_plan_id" value={this.state.data[listKey].security_plan_id} onChange={this.recordData} className="form-control select-container">
                                                            {this.state.shareClassList.map((value, key) => {
                                                                return (
                                                                    <option key={key} value={value.id} >{value.share_class_name} ({value.prefix})</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-xs-6">
                                                        <label htmlFor="draft_certificate">{__('Certificate')} #<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                        <input id="draft_certificate" onBlur={this.saveDraft} name="certificate" value={this.state.data[listKey].certificate} onChange={this.recordData} type="text" className="form-control" />
                                                    </div>
                                                </form>
                                                <form className="row">
                                                    <div className="form-group col-xs-12">
                                                        <label className="col-xs-4 add-label ques-label no-paddingleft">
                                                            {__('Certificated')}
                                                            <a id="stock-quespop" className="create-ques stock-ques">
                                                                <p className="captable-tiptext tiptext-top120">{__('If you do not select this, Sprout can help your company to issue digital share certificates to stakeholders.')}</p>
                                                            </a>
                                                        </label>
                                                        <div className="col-xs-2">
                                                            <div className="checkbox text-muted">
                                                                <input type="checkbox" className="send-box top_minus3" id="draft_certificated_shares" name="is_certificate_shares" onChange={this.recordChecked} checked={this.state.data[listKey].is_certificate_shares == 1} />
                                                                <label className="send-div" htmlFor="draft_certificated_shares"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </li>
                                        <li>
                                            <a className="prefer-togglebtn" href="#convert_note_li" data-toggle="collapse" >{__('Convert note')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                            <div className="collapse" id="convert_note_li">
                                                <form className="row reset_formFlex" >
                                                    <div className="form-group col-xs-6">
                                                        <label htmlFor="draft_convertible">{__('Convertible')}</label>
                                                        <Select
                                                            value={data[listKey].convertible_ids || []}
                                                            name='convertible_ids'
                                                            mode='multiple'
                                                            style={{width:'100%'}}
                                                            placeholder='Please select'
                                                            options={options}
                                                            onChange={this.recordConvertible}
                                                            onBlur={this.saveDraft}
                                                        >
                                                        </Select>
                                                    </div>
                                                </form>
                                                <p className="text-muted draft-tip">{__('Choose note(s) to convert it to a stock certificate, The selected note(s) will be converted when this certificate is issued.')}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <a className="prefer-togglebtn" href="#transaction_value_li" data-toggle="collapse">{__('Transaction value')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                            <div className="collapse" id="transaction_value_li">
                                                <form className="form-draftflextwo">
                                                    <div className="row">
                                                        <div className="form-group col-xs-6 no-paddingleft">
                                                            <label htmlFor="draft_number_of_shares">{__('Issued shares')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                            {isFractionalShare == 1 ?
                                                                <Input
                                                                    id="draft_number_of_shares"
                                                                    type="text"
                                                                    name="quantity"
                                                                    value={I(data[listKey].quantity, '')}
                                                                    onChange={this.inputChange5Digit.bind(this, true)}
                                                                    onBlur={this.resetDataFormat}
                                                                /> :
                                                                <Input
                                                                    id="draft_number_of_shares"
                                                                    type="text"
                                                                    name="quantity"
                                                                    value={I(data[listKey].quantity, '')}
                                                                    onChange={this.inputChange5Digit.bind(this, false)}
                                                                    onBlur={this.resetDataFormat}
                                                                />
                                                            }
                                                        </div>
                                                        <div className="form-group col-xs-6 no-paddingright ">
                                                            <label htmlFor="draft_cash_paid">{__('Cash paid')}</label>
                                                            <Input
                                                                addonBefore={currency}
                                                                id="draft_cash_paid"
                                                                type="text"
                                                                name="cash_paid"
                                                                value={I(data[listKey].cash_paid, '')}
                                                                onChange={this.inputChange.bind(this, true)}
                                                                onBlur={this.resetDataFormat}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-xs-6 no-paddingleft">
                                                            <label htmlFor="draft_debt_canceled">
                                                                {__('Convertibles w/ int. converted')}
                                                                <Tooltip title="Convertibles w/ int. converted: This is for convertible notes (Safes / KISS, debt, etc) where the total amount of principal and interest converted into shares."><a className="create-ques debt-ques"></a></Tooltip>
                                                            </label>
                                                            <Input
                                                                addonBefore={currency}
                                                                id="draft_debt_canceled"
                                                                type="text"
                                                                name="debt_canceled"
                                                                defaultValue={ debtVal }
                                                                value={I(data[listKey].debt_canceled, '')}
                                                                onChange={this.inputChange.bind(this, true)}
                                                                onBlur={this.resetDataFormat}
                                                            />
                                                        </div>
                                                        <div className="form-group col-xs-6 no-paddingright ">
                                                            <label htmlFor="draft_value_of_IP_transferred">{__('Value of IP transferred')}</label>
                                                            <Input
                                                                addonBefore={currency}
                                                                id="draft_value_of_IP_transferred"
                                                                type="text"
                                                                name="value_of_ip_transferred"
                                                                value={I(data[listKey].value_of_ip_transferred, '')}
                                                                onChange={this.inputChange.bind(this, true)}
                                                                onBlur={this.resetDataFormat}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-xs-6 no-paddingleft">
                                                            <label htmlFor="draft_other_consideration">{__('Other consideration')}</label>
                                                            <Input
                                                                addonBefore={currency}
                                                                id="draft_other_consideration"
                                                                type="text"
                                                                name="other_consideration"
                                                                value={I(data[listKey].other_consideration, '')}
                                                                onChange={this.inputChange.bind(this, true)}
                                                                onBlur={this.resetDataFormat}
                                                            />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </li>
                                        <li>
                                            <a className="prefer-togglebtn" href="#shareholder_li" data-toggle="collapse">{__('Shareholder')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                            <div className="collapse" id="shareholder_li">
                                                <form className="form-draftflextwo">
                                                    <div className="row draft-checked">
                                                        {sprintf(__('Add new stakeholders on the %s page. This page only selects existing stakeholders.'), <a onClick={this.bindStakeholders}>{__('All stakeholders')}</a>)}
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-xs-6 no-paddingleft">
                                                            <label htmlFor="draft_name">{__('Name')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                            <SelectStakeholder value={this.state.data[listKey].name} add={false} onBlur={this.saveDraft} onChange={this.handleSelectChange} />
                                                        </div>
                                                        <div className="form-group col-xs-6 no-paddingright">
                                                            <label htmlFor="draft_email">{__('Email')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                            <SelectStakeholder value={this.state.data[listKey].email} add={false} isEmail={true} onBlur={this.saveDraft} onChange={this.handleSelectChange} />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </li>
                                        <li>
                                            <a className="prefer-togglebtn" href="#shares_board_content_li" data-toggle="collapse">{__('Board consent')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                            <div className="collapse" id="shares_board_content_li">
                                                <form className="row">
                                                    <div className="form-group col-xs-6 ">
                                                        <label htmlFor="shares_board_approval_date">{__('Board approval date')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                        <DateSelector
                                                            onChange={this.handleDraftShareDateChange.bind(this, 'board_approval_date', 'board_approval_date_moment')}
                                                            value={I(data[listKey]['board_approval_date_moment'], I(data[listKey]['board_approval_date'], '') ? moment(data[listKey]['board_approval_date'], dateFormat) : '')}
                                                            inputReadOnly={false}
                                                        />
                                                    </div>
                                                    <div className="form-group col-xs-6 ">
                                                        <label htmlFor="shares_issue_date">{__('Issue date')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                        <DateSelector
                                                            onChange={this.handleDraftShareDateChange.bind(this, 'issue_date', 'issue_date_moment')}
                                                            value={I(data[listKey]['issue_date_moment'], I(data[listKey]['issue_date'], '') ? moment(data[listKey]['issue_date'], dateFormat) : '')}
                                                            inputReadOnly={false}
                                                        />
                                                    </div>
                                                </form>
                                            </div>
                                        </li>
                                        <li>
                                            <a className="prefer-togglebtn" href="#shares_vesting_schedule_li" data-toggle="collapse">{__('Vesting schedule')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                            <div className="collapse" id="shares_vesting_schedule_li">
                                                <form>
                                                    <div className="row">
                                                        <div className="form-group col-xs-6 ">
                                                            <label htmlFor="shares_draft_schedule">{__('Schedule')}</label>
                                                            <select id="shares_draft_schedule" className="form-control select-container" onBlur={this.saveDraft} value={this.state.data[listKey].vesting_schedule_id} name="vesting_schedule_id" onChange={this.recordData}>
                                                                <option value="">{__('No schedule')}</option>
                                                                {this.state.schedule.map((value, key) => {
                                                                    return (
                                                                        <option id="shares_schedule_name" key={key} value={value.id}>{value.schedule_name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                        {this.state.data[listKey].vesting_schedule_id != "" && this.state.data[listKey].vesting_schedule_id != 0 &&
                                                            <div className="form-group col-xs-6 ">
                                                                <label htmlFor="shares_vesting_start_date">{__('Vesting start date')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                                <DateSelector
                                                                    onChange={this.handleDraftShareDateChange.bind(this, 'vesting_start_date', 'vesting_start_date_moment')}
                                                                    value={I(data[listKey]['vesting_start_date_moment'], I(data[listKey]['vesting_start_date'], '') ? moment(data[listKey]['vesting_start_date'], dateFormat) : '')}
                                                                    inputReadOnly={false}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </form>
                                            </div>
                                        </li>
                                        <li>
                                            <a className="prefer-togglebtn" href="#document_note_li" data-toggle="collapse">{__('Document and notes')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                            <div className="collapse" id="document_note_li">
                                                <form className="row">
                                                    <div className="form-group col-xs-12 ">
                                                        <label htmlFor="shareclass">{__('Additional documents')}</label>
                                                        <div className="file-box">
                                                            <p className="text-muted ">{this.state.data[listKey].additional_documents[0].name == "" ? __("No file selected") : this.state.data[listKey].additional_documents[0].name}</p>
                                                        </div>
                                                        <div className="select-btn select-blue width120 marginTop10">
                                                            {__('Choose a file')}
                                                            <label htmlFor="shares_addi_files" className="sr-only" >File input</label>
                                                            <input type="file" ref="files" id="shares_addi_files" name="additional_documents" onChange={this.fileSelect} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-xs-12 ">
                                                        <label htmlFor="draft_notes">{__('Notes')}</label>
                                                        <textarea id="draft_notes" type="text" name="notes" onBlur={this.saveDraft} className="form-control minHeight100" value={this.state.data[listKey].notes} onChange={this.recordData}></textarea>
                                                    </div>
                                                </form>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}

export default DraftShares;

