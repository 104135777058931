import React, {Component, Fragment} from "react";
import {Button, Checkbox, Input, Space, Table} from 'antd';
import {SearchOutlined} from "@ant-design/icons";
import Utils, {formatNumber, formatCurrencyNumber, I, tableSort } from 'Utils';
import './style.scss';
import moment from "moment";
import {formatDate} from "Utils/format/formatDate";
import updateDefaultImg from "modules/Business/Securities/images/update-default.png";
import updateHoverImg from "modules/Business/Securities/images/update-hover.png";
import dynamicImg from "modules/Business/Securities/images/dynamic.gif";

class DraftSharesTable extends Component {

    state = {
        loading: false,
        selectedRowKeys: [],
        updateHoverShow: {},
    }

    componentDidMount() {
        const { updateHoverShow } = this.state;
        const { tableList } = this.props;
        tableList && tableList.forEach((v, k)=>{
            updateHoverShow[v.id] = 0;
        })
        this.setState({updateHoverShow});
    }

    /**
     * Price/Share列计算逻辑，排序使用
     * @param value
     * @param defaultPrice
     * @returns {*|string}
     */
    priceShareOrderBy = (value, defaultPrice) => {
        return value.quantity == 0 ? defaultPrice : ((parseFloat(Utils.resetNumberWithCommas(value.cash_paid)) + parseFloat(Utils.resetNumberWithCommas(value.other_consideration)) + parseFloat(Utils.resetNumberWithCommas(value.debt_canceled)) + parseFloat(Utils.resetNumberWithCommas(value.value_of_ip_transferred))) / Utils.getNumFromFormatNum(value.quantity)).toFixed(2);
    }

    transactionValueOrderBy = (value) => {
        return (parseFloat(Utils.resetNumberWithCommas(value.cash_paid)) + parseFloat(Utils.resetNumberWithCommas(value.other_consideration)) + parseFloat(Utils.resetNumberWithCommas(value.debt_canceled)) + parseFloat(Utils.resetNumberWithCommas(value.value_of_ip_transferred))).toFixed(2);
    }

    updateImg = (id, type) => {
        const { updateHoverShow } = this.state;
        updateHoverShow[id] = (type === 0);
        this.setState({updateHoverShow})
    }

    /**
     * 表头配置
     */
    tableColumns = () => {
        const { formatShareClassList, listKey } = this.props;
        return [
            {
                title : '',
                dataIndex : 'custom_label_id',
                width: 22,
                className: 'prefer-left-edit-th',
                render: (text, data, index) => {
                    const { updateHoverShow } = this.state;
                    return (
                        <Fragment>
                            {index == listKey && <img className="show-img" src={dynamicImg} />}
                            {index != listKey && <img className={!updateHoverShow?.[data?.id] && 'show-img'} src={updateDefaultImg} />}
                            {index != listKey && <img className={updateHoverShow?.[data?.id] && 'show-img'} src={updateHoverImg} />}
                        </Fragment>
                    )
                }
            },
            {
                title: __('Security'),
                align: 'left',
                dataIndex: 'custom_label_id',
                key: 'custom_label_id',
                sorter: (a, b) => tableSort(a.custom_label_id, b.custom_label_id),
                /*filters: [
                    {text: 'CSA', value: 'CSA'},
                    {text: 'CSB', value: 'CSB'},
                    {text: 'EA', value: 'EA'},
                    {text: 'CN', value: 'CN'},
                    {text: 'PSA', value: 'PSA'},
                    {text: 'PSB', value: 'PSB'},
                ],
                onFilter: (value, record) => record.custom_label_id.indexOf(value) === 0,*/
                render: (text, value, index) => {
                    const securityPrefix = formatShareClassList[value.security_plan_id].prefix;
                    let customLabel = value.certificate.substring(0,securityPrefix.length) == securityPrefix ? value.certificate : (securityPrefix+'-'+value.certificate);
                    return <div >{value.security_plan_id != 0 && customLabel }</div>
                }
            },
            {
                title : __('Stakeholder'),
                dataIndex : 'name',
                sorter: (a, b) => tableSort(a.name, b.name),
                render: (text, value, index) => {
                    const { transferKey } = this.props;
                    return <div>{ value.name == 0 ? __('No name') : value.name}</div>
                }
            },
            {
                title : __('Board approval date'),
                dataIndex : 'board_approval_date',
                render: (text, value) => {
                    return <div>{I(value.board_approval_date, '') ? formatDate(value.board_approval_date) : __('No board approval date')}</div>
                }
            },
            {
                title : __('Issue date'),
                dataIndex : 'issue_date',
                render: (text, value) => {
                    return <div>{ I(value.issue_date, '') ? formatDate(value.issue_date) : __('No issue date')}</div>
                },
                sorter: (a, b) => tableSort(a.issue_date, b.issue_date, 'date'),
            },
            {
                title : __('Shares'),
                dataIndex : 'quantity',
                render: (text, value) => {
                    return <div>{ value.quantity == 0 ? __('No quantity') : formatNumber(value.quantity,null)}</div>
                },
                align: 'right',
                width: 110,
                sorter: (a, b) => tableSort(a.quantity, b.quantity, 'number'),
            },
            {
                title : __('Price/Share'),
                dataIndex : '',
                align : 'right',
                sorter: (a, b) => {
                    const sharePriceA = this.priceShareOrderBy(a, 0);
                    const sharePriceB = this.priceShareOrderBy(b, 0);
                    return tableSort(sharePriceA, sharePriceB, 'number');
                },
                render: (text, value) => {
                    const priceShareValue = this.priceShareOrderBy(value, 0);
                    return <div>{formatCurrencyNumber( I(priceShareValue, '0.00') )}</div>
                }
            },
            {
                title : __('Transaction value'),
                dataIndex : '',
                align : 'right',
                sorter: (a, b) => {
                    const sharePriceA = this.transactionValueOrderBy(a);
                    const sharePriceB = this.transactionValueOrderBy(b);
                    return tableSort(sharePriceA, sharePriceB, 'number');
                },
                render: (text, value) => {
                    return <div>{formatCurrencyNumber(this.transactionValueOrderBy(value))}</div>
                }
            }
        ];
    }

    /**
     * checkbox事件
     * @param selectedRowKeys
     * @returns {{onChange: StakeholdersTableModels.onSelectChange, selectedRowKeys}}
     */
    rowSelection = (selectedRowKeys) => ({
        selectedRowKeys,
        onChange: this.onSelectChange,
        renderCell : (checked, record, index, originNode) => this.securityHover(checked, record, index, selectedRowKeys)
    })

    /**
     * 表格checkbox自定义配置
     * @param checked
     * @param record
     * @param index
     * @param selectedRowKeys
     */
    securityHover = (checked, record, index) => {

        const { is_show_error } = record;
        return (
            <Fragment>
                {
                    is_show_error == 1 &&
                    <a className="signTipBox" >
                        <a>
                            <div className="error-tip-triangle"></div>
                        </a>
                        <p className={"sign-tiptext error-tiptext bg-dangered font_weight500 draft_error_tip"+index}>{__('Error')}</p>
                    </a>
                }
                <Checkbox checked={checked} onChange={this.checkChange.bind(this, record, index, checked)} ></Checkbox>
            </Fragment>
        );
    }

    /**
     * 表格checkbox change事件
     * @param record
     * @param index
     * @param boxChecked
     * @param e
     */
    checkChange = (record, index, boxChecked, e) => {
        const { checked } = e.target;
        const { selectedRowKeys } = this.state;
        const { updateSelectCount } = this.props;
        const { id } = record;
        let key_list = [...selectedRowKeys];
        if(checked){
            key_list.push(id);
        }else{
            key_list.forEach( (v, k) => {
                if(v == id){
                    key_list.splice(k, 1);
                }
            })
        }
        updateSelectCount(key_list.length, [{selectIndex: index,checked:checked}], key_list);
        this.setState(()=>({
            selectedRowKeys : key_list
        }))
    }

    /**
     * 全选change事件
     * @param selectedRowKeys
     */
    onSelectChange = selectedRowKeys => {
        const { securityListsChange } = this.props;
        this.setState({ selectedRowKeys });
        securityListsChange(selectedRowKeys);
    };

    /**
     * 行点击事件
     * @param row
     * @param index
     * @returns {{onClick: onClick}}
     */
    onRow = (row, index) => {
        return {
            onClick: event => {
                const { transferKey } = this.props;
                this.setState({selectedRowKeys: []})
                transferKey(index);
            },
            onMouseEnter: event => {
                this.updateImg(row?.id, 0);
            },
            onMouseLeave: event => {
                this.updateImg(row?.id, 1);
            },
        }
    }

    /**
     * 行点击高亮样式设置
     * @returns {string}
     */
    setRowClassName = (value, index) => {
        const { listKey } = this.props;
        let rowClass = '';
        rowClass = index == listKey ? 'edit_row' : '';
        return value.is_show_error == 1 ? 'checked-error-state' : rowClass;
    }

    render() {
        const { loading, selectedRowKeys } = this.state;
        const { tableList } = this.props;

        return (
            <Table
                onRow={ this.onRow}
                className="stake-table draft-shares-table"
                rowClassName={this.setRowClassName}
                loading={loading}
                columns={this.tableColumns()}
                dataSource={tableList}
                rowKey="id"
                pagination={false}
                rowSelection={this.rowSelection( selectedRowKeys)}
            />
        );
    }
}
export default DraftSharesTable;