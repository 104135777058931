import React from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import Utils, { I } from "Utils";
import { post } from 'srcPath/http';
import {formatNumber, filterAmount, calculator } from "Utils";
import {DatePicker, Empty} from "antd";
import moment from "moment";
import DateSelector from "../../../../../components/DateSelector";
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import security from 'stores/security';
import CreationOfTokenGrantPlan from "../components/CreationOfTokenGrantPlan";

class EditTokenPlan extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loadingStatus:1,
            isLegalPrefix:1,
            pre_prefix:"",
            data:{
                id:"",
                coin_id:"",
                name:"",
                prefix: "EA",
                total_tokens: "",
            },
            requiredKey:["plan_name","prefix","size"],
        }
    }

    recordData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        })
    }

    /**
     * prefix只允许输入大写字母
     * @param e
     */
    upperPrefix = (e)=>{
        let curData = this.state.data;
        let dataVal = e.target.value;
        curData.prefix = dataVal.toUpperCase();
        this.setState({
            data:curData
        })
        this.checkLegalPrefix();
    }

    /**
     * 判断prefix是否重复
     */
    checkLegalPrefix = ()=>{
        const { data, pre_prefix } = this.state;
        const { existingPrefix } = this.props;
        for(let k=0; k<existingPrefix.length; k++){
            if(data.prefix == existingPrefix[k] && data.prefix !== pre_prefix){
                this.setState({
                    isLegalPrefix: 0
                })
                break;
            }else{
                this.setState({
                    isLegalPrefix : 1
                })
            }
        }
    }

    /**
     * input change通用事件
     * @param isDecimal 是否需要小数
     * @param e
     * 5 digit
     */
    inputChange5Digit = (isDecimal,e) => {
        const { target: element } = e;
        const { name: dataKey } = element;
        const filter = filterAmount(e, 9999999999999, isDecimal, 5);
        let curData = this.state.data;
        curData[dataKey] = filter.value;
        this.setState({
            data: curData
        }, () => {
            element.selectionStart = element.selectionEnd = filter.cursorPositionX;
        });
    }
    // showErrorList=(emptyCheck,selectCheck,e)=>{
    //     console.log(emptyCheck,selectCheck)
    //     this.state.requiredKey.map((value,key)=>{
    //         if(this.state.data[value].length ===0){
    //             $("#"+value).addClass("has-error");
    //         }else{
    //             $("#"+value).removeClass("has-error");
    //         }
    //     });
    //     this.setState({
    //         isShowEmptyError:emptyCheck,
    //         showErrorInfo:selectCheck,
    //     });
    // }


    // getTokenOptionPlanList=()=>{
    //     post('getTokenOptionPlanList').then((response)=> {
    //         if(response.data.code === 0){
    //             let formatTokenPlanList = this.state.formatTokenPlanList;
    //             let resList = response.data.data.plan_list;
    //             for(let i=0; i<resList.length; i++){
    //                 formatTokenPlanList[resList[i].id] = resList[i];
    //             }
    //             this.setState({
    //                 tokenPlanList : resList,
    //                 loadingStatus : 0
    //             })
    //         }else{
    //             console.log("failed, error code is: "+response.data.code);
    //         }
    //     }).catch(function (error) {
    //         console.log(error);
    //     });
    // }
    editTokenOptionPlan=()=>{
        const posData = this.state.data;
        post('editTokenOptionPlan',posData).then((response)=> {
            if(response.data.code === 0){
                window.$("#editTokenPlanModal").modal("hide");
                this.props.getData();
                this.props.getTokenPlanList();
                this.props.getTokenPlansPrefixList();
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    getTokenPlanInfoById=(coinId)=>{
        const { formatTokenPlanList } = this.props;
        let curData = this.state.data;
        curData.id = coinId;
        curData.coin_id = formatTokenPlanList[coinId].coin_id;
        curData.name = formatTokenPlanList[coinId].name;
        curData.total_tokens = formatTokenPlanList[coinId].total_tokens
        curData.prefix =  formatTokenPlanList[coinId].prefix;
        this.setState({
            data:curData,
            pre_prefix:formatTokenPlanList[coinId].prefix
        })
    }
    closeEditTokenPlan=()=>{
        window.$("#editTokenPlanModal").modal("hide");
        window.$("#editTokenOptionPlan").removeClass("active");
        window.$("#token_plan_list").addClass("active");
    }
    resetActive=()=>{
        window.$("#editTokenPlanModal .tab-pane").removeClass("active");
        window.$("#token_plan_list").addClass("active");
    }
    render(){
        const { minus, division } = calculator;
        let isFractionalShare = security.companyInfo.fractional_control;

        const { data, isLegalPrefix } = this.state;
        const { tokenPlanList } = this.props;
        let requiredTest = 0;
        if(data.coin_id==""||data.prefix=="" || data.total_tokens==""||isLegalPrefix == 0){
            requiredTest = 1;
        }
        const customCoinList = (security.companyInfo && security.companyInfo.custom_coin_list) || []
        let formatCustomCoinList = {};
        for (let i=0; i<customCoinList.length; i++){
            formatCustomCoinList[customCoinList[i].id] = customCoinList[i]
        }
        return(
            <div className="modal fade" id="editTokenPlanModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="modal-dialog dialog-top40 dialog1230">
                    <div className="tab-content">
                        <div className="modal-content content680 tab-pane active" id="token_plan_list">
                            <div className="modal-header box-header">
                                <button type="button" onClick={this.closeEditTokenPlan} className="close shut-btn" aria-hidden="true">×</button>
                                <h4 className="modal-title">{__('Token option plans')}</h4>
                            </div>
                            <div className="modal-body equity-body">
                                <table className="table viewHoldings-table equity_awards_table width100perc">
                                    <tbody>
                                    <tr className="first-row">
                                        <td>{__('Token option plans')}</td>
                                        <td>{__('Plan size')}</td>
                                        <td>{__('Available')}</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    {tokenPlanList.length===0 &&
                                    <tr>
                                        <td colSpan={5}><Empty/></td>
                                    </tr>
                                    }
                                    {tokenPlanList.length>0 && tokenPlanList.map((value,key)=>{
                                        let restSize = minus( value.total_tokens, value.available)
                                        let proportion = 0;
                                        if(value.available<=0){
                                            proportion =100
                                        }else{
                                            proportion = division(restSize,value.total_tokens)*100
                                        }
                                        return(
                                            <tr className="equity_contentRow bg-lightgray" key={key}>
                                                <td>{value.name}</td>
                                                <td>{formatFractionalNumber(value.total_tokens)}</td>
                                                <td>{formatFractionalNumber(value.available)}</td>
                                                <td>
                                                    <div className="progress option-progress equity_awards_progress">
                                                        <div style={{width:proportion+"%"}} className="progress-bar option-progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                                                            <span className="sr-only"> {__('Complete')}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <button className="btn width130btn height32 white-text bg-darkblue width160" onClick={this.getTokenPlanInfoById.bind(this,value.id)} data-toggle="tab" data-target="#edit_tokenPlanInput" >{__('Edit plan details')}<span className="triangle triangle-whitedown action-triangle"></span></button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-content content500 tab-pane" id="edit_tokenPlanInput">
                            <div className="modal-header box-header">
                                <button type="button" className="close shut-btn" data-dismiss="modal" onClick={this.resetActive} aria-hidden="true">×</button>
                                <h4 className="modal-title" id="myModalLabel">{__('Edit token plan')}</h4>
                            </div>
                            <div className="modal-body padding2040 ">
                                <div className="body-one">
                                    <form className="form-horizontal">
                                        {isLegalPrefix == 0 &&
                                        <div>
                                            <div className="alert alert-warning alert-dismissable pink-box" role="alert">
                                                <p>{__('Plan prefix already exists. Please select another.')}</p>
                                            </div>
                                        </div>
                                        }
                                        <div className="stock-titbox token-title-box">
                                            <p>{__('Edit token plan')}</p>
                                        </div>
                                        <div className="form-group add-items">
                                            <label className="col-xs-6 add-label">{__('Token')}</label>
                                            <div className="col-xs-6">
                                                <select value={this.state.data.coin_id} disabled={true}  onChange={this.recordData}  name="coin_id" className="form-control select-container">
                                                    <option value="">--</option>
                                                    {customCoinList && customCoinList.map((value,key)=>{
                                                        return(
                                                            <option key={key} value={value.id}>{value.name}</option>
                                                        )
                                                    })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group add-items">
                                            <label className="col-xs-6 add-label">{__('Plan name')}</label>
                                            <div className="col-xs-6">
                                                <input type="text" name="name" onChange={this.recordData} value={this.state.data.name} className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="form-group add-items">
                                            <label className="col-xs-6 add-label">{__('Plan prefix')}</label>
                                            <div className="col-xs-6">
                                                <input type="text" name="prefix" onChange={this.recordData} onBlur={this.upperPrefix} value={this.state.data.prefix} className="form-control uppercase_form"/>
                                            </div>
                                        </div>
                                        <div className="form-group add-items">
                                            <label className="col-xs-6 add-label">{__('Total number of tokens')}</label>
                                            <div className="col-xs-6">
                                                { isFractionalShare == 1 ?
                                                    <input type="text" name="total_tokens" onChange={this.inputChange5Digit.bind(this, true)} value={this.state.data.total_tokens} className="form-control"/> :
                                                    <input type="text" name="total_tokens" onChange={this.inputChange5Digit.bind(this, false)} value={this.state.data.total_tokens} className="form-control"/>
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer box-footer invite-footer">
                                {requiredTest==1 &&
                                <button type="button" className="btn boxbtn-to width200 send-disabledBtn ">{__('Next: Preview')}</button>
                                }
                                {requiredTest==0 &&
                                <button type="button" data-toggle="tab" data-target="#edit_reviewTokenGrantPlan" className="btn boxbtn-to bg-darkblue update-btn">{__('Next: Preview')}</button>
                                }
                            </div>
                        </div>
                        <div className="modal-content content500 tab-pane " id="edit_reviewTokenGrantPlan">
                            <div className="modal-header box-header">
                                <a data-target="#edit_tokenPlanInput" className="back-btn" aria-controls="edit_tokenPlanInput" role="tab" data-toggle="tab"></a>
                                <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.resetActive}>×</button>
                                <h4 className="modal-title" id="myModalLabel">{__('Confirm changes')}</h4>
                            </div>
                            <div className="modal-body padding2026">
                                <div className="body-one">
                                    <div className="shareTitleBox marginBott10">
                                        <div className="iconBox bg-darkblue">
                                            <span className="glyphicon glyphicon-list-alt"></span>
                                        </div>
                                        <div className="shareTextBox">
                                            <p className="shareTitle marginTop6">{__('Preview')}</p>
                                        </div>
                                    </div>
                                    <table className="table merge-table review-relationship-table no-marginBottom ">
                                        <tbody>
                                        <tr>
                                            <td>{__('Token')}</td>
                                            <td>{formatCustomCoinList[data.coin_id] && formatCustomCoinList[data.coin_id].name}</td>
                                        </tr>
                                        <tr>
                                            <td>{__('Plan name')}</td>
                                            <td>{data.name}</td>
                                        </tr>
                                        <tr>
                                            <td>{__('Plan prefix')}</td>
                                            <td>{data.prefix}</td>
                                        </tr>
                                        <tr>
                                            <td>{__('Total number of tokens')}</td>
                                            <td>{formatFractionalNumber(data.total_tokens)}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer box-footer invite-footer">
                                <button type="button" onClick={this.editTokenOptionPlan} className="btn boxbtn-to bg-darkblue update-btn" >{__('Save')} </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default EditTokenPlan;

