import React from 'react';
import classnames from 'classnames';
import './style.scss'

export default function SideBar(props) {
	return <div className="certificate-side-bar" style={{ width: props.width || 180 }}>
		<div className="certificate-side-bar-name">
			<div className="certificate-side-bar-title">{props.type}</div>
			<div className="certificate-side-bar-value">
				{props.name}
			</div>
		</div>
		<div className="certificate-side-bar-menu">
			{
				(props.config || []).map((val, index) => <div 
						onClick={() => { props.onChange(val.key) }} 
						key={index} 
						className={classnames('certificate-side-bar-menu-item', { 'certificate-side-bar-menu-item-checked': props.value === val.key })}
					>
					{val.label}
				</div>)
			}
		</div>
	</div>
}