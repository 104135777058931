import React from 'react';
import { Link } from "react-router-dom";
import Utils, { I } from "Utils";
import { post } from 'srcPath/http';
import security from "stores/security";
import Loading from 'components/Loading';
import moment from "moment";
import {DatePicker} from "antd";
import DateSelector from "../../../../../components/DateSelector";

class CreateWarrantBlock extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            dateFormat:"DD/MM/YYYY",
            loadingStatus:0,
            createStatus:0,
            cs_plan_list:[],
            existingPrefix:["abc"],
            existingBlockName:["abc"],
            data:{
                block_name:"",
                associated_share_class_id: "",
                prefix: "",
                board_approval_date: "",
                exercise_price:"",
                purchase_agreement_file:{name:__("No file selected"),file_obj:""},
                form_of_warrant_file:{name:__("No file selected"),file_obj:""},
            },
            isShowEmptyError:0,
            requiredKey:["block_name","associated_share_class_id","prefix","exercise_price"],
        }
    }
    initStep=()=>{
        for(let i = 0; i<this.state.requiredKey.length;i++){
            $("#"+this.state.requiredKey[i]).removeClass("has-error")
        }
        this.setState({
            isShowEmptyError:0
        })
    }
    resetCreateData=()=>{
        this.setState({
            data:{
                block_name:"",
                associated_share_class_id: "",
                prefix: "",
                board_approval_date: "",
                exercise_price:"",
                purchase_agreement_file:{name:__("No file selected"),file_obj:""},
                form_of_warrant_file:{name:__("No file selected"),file_obj:""},
            }
        })
        this.removeFileDeleteIcon();
        this.initStep();
    }
    removeFileDeleteIcon=()=>{
        let fileArr = ["purchase_agreement_file","form_of_warrant_file"];
        fileArr.map((val,k)=>{
            $("."+val).addClass("hidden")
        })
    }
    recordData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        })
    }
    recordNumber=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = Utils.check5DecimalNumInputWithoutComma(dataVal);
        this.setState({
            data:curData
        })
    }
    showFormatNumber=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = Utils.getNumFormatWithComma5(Utils.getNumWith5Decimal(dataVal));
        this.setState({
            data:curData
        })
    }
    focusNumber=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = Utils.resetNumberWithCommas(dataVal);
        this.setState({
            data:curData
        })
    }
    setFileName=(e)=>{
        let fileObj = e.target.files[0];
        let curData = this.state.data;
        let fileKey = e.target.name;
        Utils.uploadFile(fileObj).then((result)=>{
            curData[fileKey].name=fileObj.name;
            curData[fileKey].file_obj= result;
            this.setState ({
                data:curData
            });
        })
        $("."+fileKey).removeClass("hidden")
    }
    fileDelete =(fileKey)=>{
        var curData = this.state.data;
        curData[fileKey].name = "No file selected";
        curData[fileKey].file_obj = "";
        this.setState ({
            data:curData
        });
        $("."+fileKey).addClass("hidden");
    }
    initPageData=()=>{
        this.setState({
            loadingStatus:1
        })
        post('getCreateWarrantBlockCheckList').then((response)=> {
            if(response.data.code === 0){
                this.setState({
                    cs_plan_list:response.data.data.shareClassList,
                    existingPrefix:response.data.data.prefixList,
                    existingBlockName:response.data.data.blockNameList,
                });
                this.setState({
                    loadingStatus:0
                })
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    checkEmptyData=(e)=>{
        this.state.requiredKey.map((value,key)=>{
            if(this.state.data[value] ==""){
                window.$("#"+value).addClass("has-error");
            }else{
                window.$("#"+value).removeClass("has-error");
            }
        });
        this.setState({
            isShowEmptyError:1
        });
    }
    createWarrantBlock=()=>{
        if(this.state.createStatus==1){
            return
        }
        this.setState({
            createStatus:1
        })
        let requestData = this.state.data;
        post('createWarrantBlock',requestData).then((response)=> {
            if(response.data.code === 0){
                // window.location.reload();
                this.setState({
                    createStatus:0
                })
                window.$("#createWarrantBlockModal").modal("hide");
                this.resetCreateData();
                this.props.getWarrantList();
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    warrantDateChange = (key, moment_key, date) => {
        const { dateFormat } = this.state;
        let curData = this.state.data;
        curData[key] = date.format( dateFormat);
        curData[moment_key] = date;
        this.setState({
            data:curData
        });
    }

    render(){
        let isShowEmptyError = 0;
        if(this.state.data.block_name.length===0|| this.state.data.associated_share_class_id.length===0 || this.state.data.prefix.length===0||this.state.data.exercise_price.length===0){
            isShowEmptyError = 1;
        }
        let showButton="";
        if(isShowEmptyError===0){
            showButton = <button onClick={this.createWarrantBlock}  className="btn boxbtn-to bg-darkblue update-btn">{this.state.createStatus == 0 ? __("Create Warrant Block"):__("Creating")}</button>
        }else{
            showButton = <button onClick={this.checkEmptyData} className="btn boxbtn-to bg-darkblue update-btn">{__('Create Warrant Block')}</button>;
        }
        let isLegalPrefix = 1; //默认为合法的输入
        for(let i=0;i<this.state.existingPrefix.length;i++){
            if(this.state.data.prefix == this.state.existingPrefix[i]){
                isLegalPrefix = 0;  //有重复改为非法
            }
        }
        if(isLegalPrefix === 0){
            //重复的prefix，按钮无任何反应
            showButton = <button className="btn boxbtn-to bg-darkblue update-btn">Create Warrant Block</button>
        }
        const { currency = '' } = this.props;
        const { data, dateFormat } = this.state;
        return(
            <div className="modal fade" id="createWarrantBlockModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="modal-dialog dialog680">
                    <div className="modal-content content680">
                        <div className="modal-header box-header">
                            <button type="button" onClick={this.initStep} className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Create warrant block')}</h4>
                        </div>
                        <div className="modal-body equity-body">
                            <div className="position-relative">
                                <div className="equity-item-header bg-seablue">
                                    <h5>{__('Basic warrant block information')}</h5>
                                </div>
                                {this.state.loadingStatus==1 &&
                                <div className="termsLoadingPosition">
                                    <Loading/>
                                </div>
                                }
                                <form className="form-horizontal form_bg">
                                    <div className="form-group ">
                                        <label className="col-xs-5 add-label">{__('Warrant block name')}</label>
                                        <div id="block_name" className="col-xs-7">
                                            <input name="block_name" onChange={this.recordData}
                                                   value={this.state.data.block_name} className="form-control"
                                                   type="text" placeholder="e.g. Series A Warrant Block"/>
                                        </div>
                                    </div>
                                    <div className="form-group ">
                                        <label className="col-xs-5 add-label">{__('Associated share class')}</label>
                                        <div className="col-xs-4" id="associated_share_class_id">
                                            <select name="associated_share_class_id" onChange={this.recordData}
                                                    value={this.state.data.associated_share_class_id}
                                                    className="form-control select-container select-bgwhite">
                                                <option value="">--</option>
                                                {Object.values(this.state.cs_plan_list).map((value, key) => {
                                                    return (
                                                        <option key={key}
                                                                value={value.id}>{value.share_class_name} ({value.prefix})</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group ">
                                        <label className="col-xs-5 add-label">{__('Warrant prefix')}</label>
                                        <div id="prefix" className="col-xs-4">
                                            <input name="prefix" onChange={this.recordData}
                                                   value={this.state.data.prefix}
                                                   className="form-control uppercase_form" type="text" placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="form-group ">
                                        <div className="col-xs-5">
                                            <label className="add-label">{__('Board approval date')}<br/><i
                                                className="text-muted">- {__('Optional')}</i></label>
                                        </div>
                                        <div className="col-xs-4">
                                            <DateSelector
                                                onChange={this.warrantDateChange.bind(this, 'board_approval_date', 'board_approval_date_moment')}
                                                value={I(data['board_approval_date_moment'], I(data['board_approval_date'], '') ? moment(data['board_approval_date'], dateFormat) : '')}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-xs-5">
                                            <label className="add-label">{__('Exercise price')}</label>
                                        </div>
                                        <div id="exercise_price" className="col-xs-4">
                                            <div className="input-group"><span className="input-group-addon"
                                                                               id="exercise-price-addon">{currency}</span>
                                                <input type="text" aria-describedby="exercise-price-addon"
                                                       onBlur={this.showFormatNumber} onFocus={this.focusNumber}
                                                       name="exercise_price"
                                                       value={this.state.data.exercise_price}
                                                       onChange={this.recordNumber}
                                                       className="form-control"/>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                            <div>
                                <div className="equity-item-header bg-seablue">
                                    <h5>{__('Upload warrant block documents')}</h5>
                                </div>
                                <form className="form-horizontal form_bg">
                                    <p className="text-muted">{__('Upload default documents. You can replace them when you issue warrants.')}</p>
                                    <div className="form-group ">
                                        <div className="col-xs-5">
                                            <label className=" add-label">{__('Warrant purchase agreement')}<br/><i
                                                className="text-muted">- {__('Optional')}</i></label>
                                        </div>
                                        <div className="col-xs-3 ">
                                            <div className="select-btn select-blue equ-choose">
                                                {__('Choose a file')}
                                                <label htmlFor="files-select" className="sr-only">File input</label>
                                                <input ref="ea_plan" type="file"
                                                       name="purchase_agreement_file" onChange={this.setFileName}/>
                                            </div>
                                        </div>
                                        <div className="col-xs-4 no-paddingleft">
                                            <div className="file-box equity-file marginTop0 create_equity-file ">
                                                <p className="text-muted">{this.state.data.purchase_agreement_file.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group ">
                                        <div className="col-xs-5">
                                            <label className=" add-label">{__('Form of warrant')}<br/><i className="text-muted">-
                                                {__('Optional')}</i></label>
                                        </div>
                                        <div className="col-xs-3 ">
                                            <div className="select-btn select-blue equ-choose">
                                                {__('Choose a file')}
                                                <label htmlFor="files-select" className="sr-only">File input</label>
                                                <input ref="ea_plan" type="file"
                                                       name="form_of_warrant_file" onChange={this.setFileName}/>
                                            </div>
                                        </div>
                                        <div className="col-xs-4 no-paddingleft">
                                            <div className="file-box equity-file marginTop0 create_equity-file ">
                                                <p className="text-muted">{this.state.data.form_of_warrant_file.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {this.state.showErrorInfo === "show" &&
                            <div id="" className="alert alert-warning alert-dismissible pink-box eq-tipbox"
                                 role="alert">
                                <p>{__('If you select a box, please input a corresponding number.')}</p>
                            </div>
                            }
                            {this.state.isShowEmptyError === 1 &&
                            <div className="alert alert-warning alert-dismissible pink-box eq-tipbox" role="alert">
                                <p>{__('Required field(s) can not be empty.')}</p>
                            </div>
                            }
                            {isLegalPrefix === 0 &&
                                <div className="alert alert-warning alert-dismissable pink-box eq-tipbox" role="alert">
                                    <p>{__('Warrant prefix already exists. Please select another.')}</p>
                                </div>
                            }
                        </div>
                        <div className="modal-footer box-footer">
                            {showButton}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default CreateWarrantBlock;

