import React, {Component, Fragment} from 'react';
import security from 'stores/security';
import { sprintf,printf } from 'Utils/i18n';
import {Col, Row, Radio, message, Space, Checkbox, Divider} from 'antd';
import helpBestImg from "../../image/help-best.png";
import helpEquityImg from "../../image/help-equity.png";
import helpTokenImg from "../../image/help-token.png";
import helpRadioImg from "../../image/help-radio.png";
import { RightOutlined } from '@ant-design/icons';
import Loading from 'components/Loading';
import { post } from 'srcPath/http';
import { I } from 'Utils';
import "./style.scss";

class SelfHelp extends Component {

	state = {
		equity: [],
		third: [],
		token: [],
		data: {},
		isChecked: [],
	};

	/**
	 * 一级分类名称
	 * @returns {{third: *, equity: *, token: *}}
	 */
	getTitle = (key) => {
		const data = {equity: __('Equity Tutorials'), third: __('Company Settings'), token: __('Token Tutorials')};
		return I(data?.[key], '');
	}

	componentDidMount() {
		this.getArticleList();
	}

	/**
	 * help列表
	 * @returns {Promise<void>}
	 */
	getArticleList = async () => {
		Loading.global(true)
		try {
			const { data: resData } = await post('getArticleList', {});
			const { code, data } = resData;
			if (code === 0) {
				this.setState({
					equity: data?.equity,
					third: data?.third,
					token: data?.token,
					data,
				});
			} else {
				message.error('Error!');
			}
		} catch (e) {
			message.error(e.message)
		}
		Loading.global(false)
	}

	showDetail = (id) => {
		window.open(`/home/help/detail?id=${id}`)
	}

	cmsList = (type) => {
		const { data, isChecked } = this.state;
		return data?.[type] && data[type].map((v, k)=>{
			return (
				<Fragment>
					{( isChecked.length == 0 || (isChecked.length > 0 && isChecked.findIndex(v1=>v1 == v.id) !== -1 ) ) && (
						<Fragment>
							<div className="self-help-cat-first"><img src={helpRadioImg} /><div>{v?.article_title}</div></div>
							{
								v?.list && v.list.map((v1, k1)=>(
									<div className="self-help-ul">
										<div className="self-help-li" onClick={this.showDetail.bind(this, v1?.id)}>
											<span>{v1.article_title}</span>
											<RightOutlined />
										</div>
									</div>
								))
							}
						</Fragment>
					)}
				</Fragment>
			)
		})
	}

	newsChange = (id, key, name, e) => {
		const { data } = this.state;
		const { checked } = e.target;
		let newData = {...data};
		if(newData?.[name]?.[key]){
			newData[name][key].checked = checked;
			newData[name] = [...newData[name]];
			let isChecked = [];
			Object.keys(newData).forEach((v, k)=>{
				for(let i in newData[v]){
					newData[v][i].checked && isChecked.push( newData[v][i].id);
				}
			});
			this.setState({data: newData, isChecked});
			console.log(isChecked);
		}
	}

	getNav = () => {
		const { data, token } = this.state;
		return data && Object.keys(data).map((v, k)=>(
			<Fragment key={k}>
				<div className="self-help-nav-t1">{this.getTitle(v)}</div>
				<ul>
					{data?.[v] && data?.[v].map((v1, k1)=>(
						<li key={v1.id} className="self-help-nav-t2"><Checkbox checked={v1?.checked} onChange={this.newsChange.bind(this, v1.id, k1, v)}>{v1.article_title}</Checkbox></li>
					))}
				</ul>
			</Fragment>
		));
	}

	render() {
		const { user_name: userName } = security.userInfo;
		const { token } = this.state;
		return (
			<div class="self-help-root">
				<h3>{sprintf(__('Welcome %s!'), userName)}</h3>
				<div className="self-help-content">
					<Row gutter={15}>
						<Col span={6}>
							<div className="self-help-nav">{this.getNav()}</div>
						</Col>
						<Col span={9}>
							<div className="self-help-box">
								<dt>
									<h5>{this.getTitle('equity')}</h5>
									<div className="self-help-title-img">
										<img src={helpBestImg} />
									</div>
								</dt>
								<dd>{this.cmsList('equity')}</dd>
							</div>
						</Col>
						<Col span={9}>
							{I(token, '') && (
								<div className="self-help-box self-help-token-box">
									<dt>
										<h5>{this.getTitle('token')}</h5>
										<div className="self-help-title-img">
											<img src={helpTokenImg} />
										</div>
									</dt>
									<dd className="self-help-dd-token">{this.cmsList('token')}</dd>
								</div>
							)}
							<div className={`self-help-box self-help-best-box ${!I(token, '') && 'self-help-best-box-notoken'}`}>
								<dt>
									<h5>{this.getTitle('third')}</h5>
									<div className="self-help-title-img">
										<img src={helpEquityImg} />
									</div>
								</dt>
								<dd className="self-help-dd-best">{this.cmsList('third')}</dd>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}
export default SelfHelp;