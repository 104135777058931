import React from 'react';
import 'assets/css/dataroommodal.css';
import axios from 'axios';
import Utils from "Utils";
import { post } from 'srcPath/http';
import security from 'stores/security';
import { Input, Button, message } from 'antd';
class AddChildFolderModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            folder_name: ''
        }
    }

    setName = (e) => {
        this.setState({
            folder_name: e.target.value
        })
    }

    createTopFolder = async () => {
        if (!this.state.folder_name.trim()) {
            return
        }
        try {
            const { data } = await post('newAddBoardDocumentFolder', {
                pid: this.props.dirInfo?.id || '0',
                folder_name: this.state.folder_name,
                owner: security.userInfo.user_name
            })
            if (data.code === 0) {
                const { onReload } = this.props
                onReload && onReload('Add', this.props.dirInfo)
            } else {
                message.error(data.data);
            }

        } catch (e) {
            message.error(e.message)
        }
    }




    render() {
        const { close } = this.props
        return (
            <div>
                <div style={{ paddingRight: 0 }}>
                    <div className="modal-dialog">
                        <div className="modal-content content-reset">
                            <div className="modal-header box-header">
                                <button type="button" className="close" onClick={close}>×</button>
                                <h4 className="modal-title" id="myModal2Label">{__('Add Folder')}</h4>
                            </div>
                            <div className="modal-body body-reset2030">
                                <p className="text-muted">{__('This folder will be added to')}:</p>
                                <p className="text-muted addfolder-name"><span className="text-muted glyphicon glyphicon-folder-close"></span>&nbsp;&nbsp;{this.props.dirInfo.name || __('Documents')}</p>
                                <input onChange={this.setName} value={this.state.folder_name} type="text" className="form-control" placeholder={__('e.g. Document')} />
                            </div>
                            <div className="modal-footer box-footer no-paddingtop">
                                <button onClick={this.createTopFolder} type="button" className="btn dataroom-btnReset" data-dismiss="modal">{__('Add')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default AddChildFolderModal;

