import React from 'react';

import Stakehoder from '../forms/Stakeholder';
import Status from '../forms/Status';
import VestingSchedule from '../forms/VestingSchedule';
import FormsDate from '../forms/FormsDate';

export default function Form() { 
    return <div>
         <Stakehoder type='rsas'/>
         <FormsDate name='issue_date' label={__('Issue date')}/>
         <VestingSchedule />
         <Status />
    </div>
}