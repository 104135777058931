import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ByShareclassNew from './ByShareclass';
import ByStakeholder from './ByStakeholder';
import ByVotingPower from './ByVotingPower';
import { CommonReports } from 'B/components/Reports';
import RightsAndPreferences from './RightsAndPreferences/index';
import security from 'stores/security';
import './style.scss';

export default class Captable extends Component {
	path = this.props.match.path

	config = security.hasFunction([
		{
			path: `${this.path}/byshareclass`,
			component: ByShareclassNew,
			permission: 'captable_byShareClass',
			exact: true
		},
		{
			path: `${this.path}/bystakeholder`,
			component: ByStakeholder,
			permission: 'captable_byStakeholder',
			exact: true,
		},
		{
			path: `${this.path}/byvotingpower`,
			component: ByVotingPower,
			permission: 'captable_byStakeholder',
			exact: true,
		},
		{
			path: `${this.path}/reports`,
			component: CommonReports,
			permission: 'captable_report',
			exact: true
		},
		{
			path: `${this.path}/reports`,
			component: CommonReports,
			permission: 'tax_reports',
			exact: true
		},
		{
			path: `${this.path}/reports`,
			component: CommonReports,
			permission: 'sbc_reports',
			exact: true
		},
		{
			path: `${this.path}/rights-and-preferences`,
			component: RightsAndPreferences,
			permission: 'rights_and_preferences',
			exact: true
		}
	]);

	render() {
		const routeConfig = this.config.filter((value, key) => security.hasPermission(value.permission))
		return <div className="sprout-captable-root">
			<Switch>
				{
					routeConfig.map((val, index) => <Route key={index} exact={val.exact} path={val.path} component={val.component} />)
				}
				<Redirect to={routeConfig && routeConfig[0] && routeConfig[0].path || this.path} />
			</Switch>
		</div>
	}
}