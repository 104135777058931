import React, { Component } from 'react'
import Modal, { ModalContent } from 'components/Modal';
import './style.scss'
import {Button, Input, message, Space, Row, Col, Tooltip} from "antd";
import { post } from 'srcPath/http';
import {FormItem, withForm} from "react-antd-formutil";
import security from 'stores/security';
import DateSelector from 'components/DateSelector';

import UploadItem from 'B/components/UploadItem'
import { formatDateForDatePicker, formatDate2Api } from 'Utils/format/formatDate';

class RedeemToCash extends Component{
    constructor(props){
        super(props);
        this.state = {
            loading:false
        }
    }

    closeModels = () => {
        const { close } = this.props;
        close && close()
    }

    handleRedeemToCash = async () => {
        this.setState({loading:true})
        const { data, $formutil } = this.props
        const { $params } = $formutil
        const params = {id: data.id, ...$params }
        try {
            const {getSecurityList, close } = this.props;
            const { data: resData } = await post('redeemToCash', params )
            const { data, code } = resData || {}
            if (code == 0) {
                getSecurityList && getSecurityList();
                close && close();
            } else {
                message.error(data.errorCode)
            }
        } catch(err) {
            message.error(err && err.message)
        }
        this.setState({loading:false})
    }

    render(){
        const { data } = this.props
        const { currency } = security.companyInfo
        const { loading } = this.state
        const startDate = formatDateForDatePicker(security.companyInfo.date_of_incorporation)
        const isFractionalShare = security.companyInfo.fractional_control == '1';

        return(
            <div className="modal-content redeem-modal">
                <div className="modal-header box-header">
                    <button type="button" className="close shut-btn"  onClick={this.closeModels}>×</button>
                    <h4 className="modal-title" >{__('Redeem convertible note to cash')}</h4>
                </div>
                <div className="modal-body redeem-content">
                    <FormItem
                        name="convert_date"
                        label={<div>
                            <Space>
                                {__('Redeem date')}
                            </Space>
                        </div>}
                        colon={false}
                        $formatter={formatDateForDatePicker}
                        $parser={val => val && formatDate2Api(val)}
                        validMessage={{
                            required: 'Redeem date is required',
                        }}
                    >
                        <DateSelector
                            disabledDate={val => val && startDate && startDate.isValid() && val.isBefore(startDate)}
                        />
                    </FormItem>
                    <FormItem
                        name="payout_amount"
                        label={__("Payout amount")}
                        required
                        colon={false}
                        $parser={(viewValue, $setViewValue) => {
                            const reg = isFractionalShare ? /^\D*(\d*(?:\.\d{0,5})?).*$/g : /^\D*(\d*(?:)?).*$/g

                            const value = viewValue.replace(reg, '$1');

                            $setViewValue(value);

                            return Number(value);
                        }}

                        validMessage={{
                            required: 'Payment amount is required',
                        }}
                    >
                        <Input
                            addonBefore={currency}
                        />
                    </FormItem>
                    <FormItem
                        name="conversion_agreement"
                        label={__("Conversion agreement")}
                        required
                        validMessage={{
                            required: 'Conversion agreement file is required!'
                        }}
                    >

                            <UploadItem fileKey="file_obj">
                                <Tooltip className="draft-option-file-tooltip" placement="topRight" title={data.conversion_agreement.name}>{data.conversion_agreement.name}</Tooltip>
                            </UploadItem>
                    </FormItem>
                </div>
                <div className="modal-footer box-footer">
                    <Button type={'primary'} loading={loading} className="btn width120" onClick={this.handleRedeemToCash} >{__('Confirm')}</Button>
                </div>
            </div>
        )
    }
}
const CreateRedeemModal = withForm(RedeemToCash)

export default function redeemToCashModal(data, id, getSecurityList) {
    return Modal.open({
        component: <CreateRedeemModal data={data} id={id} getSecurityList={getSecurityList}/>,
        maskClosable: true,
        width: 580,
        className: 'add-stakeholder-modal',
    })
}