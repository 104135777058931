import React, { Component, Fragment } from 'react';
import { post } from 'srcPath/http';
import Loading from 'components/Loading';
import { Table, Input, Space, Spin, Tooltip, Button } from 'antd';
import Modal, { ModalContent } from 'components/Modal';
import AwardCategoryModel from './components/AwardCategoryModel/AwardCategoryModel'
import DeleteAwardCategoryModel from './components/DeleteAwardCategoryModel/DeleteAwardCategoryModel.js'
import './style.scss';

const renderValue = val => val || '--'
class AwardCategory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			awardTableList: [],
			columns: [
				{
					title: this.getTooltip,
					dataIndex: 'name',
					render: renderValue,
				},
				{
					title: __("Notes"),
					dataIndex: 'notes',
					render: renderValue,
				},
				{
					title: '',
					align: 'right',
					render: (text, record = {}) => <div>
						<Button type="link" className="sprout-btn-divider" onClick={this.openEditModal.bind(this, record)}>{__('Edit')}</Button>
						<Button type="link" danger onClick={this.openDeleteModal.bind(this, record)}>{__('Delete')}</Button>
					</div>
				},
			]
		}
	}
	componentDidMount = () => {
		this.getTableList()
	}
	openEditModal = (params) => {
		this.modalRef = Modal.open({
			component: props => <ModalContent close={props.close} title={__("Edit award category")}>
				<AwardCategoryModel close={props.close} isAdd={false} params={params} tableList={this.state.awardTableList} callback={this.getTableList} />
			</ModalContent>,
			className: 'sprout-modal',
			width: 600
		})
	}
	openDeleteModal = (params) => {
		const { id } = params
		this.modalRef = Modal.open({
			component: props => <ModalContent close={props.close} title={__("Delete award category")}>
				<DeleteAwardCategoryModel close={props.close} id={id} callback={this.getTableList} />
			</ModalContent>,
			className: 'sprout-modal',
			width: 600
		})
	
	}
	getTooltip = () => {
		return <div>{__('Award category name')}
			<Tooltip overlayClassName="category-tooltip" title={ __("Award category is an optional field that can be use to further organize and categorize option awards.") }>
				<div className="form-email-help-icon">?</div>
			</Tooltip>
		</div>
	}
	getTableList = () => {
		Loading.global(true)
		post('getAwardCategory').then(res => {
			let { data: resDate } = res || []
			let { code, data } = resDate || []
			if (code == 0) {
				let newTable = data
				this.updateSetState("awardTableList", newTable)
			}
			Loading.global(false)
		}).catch(err => {
			console.log(err);
			Loading.global(false)
		});

	}
	updateSetState = (p, k) => {
		this.setState({
			[p]: k
		})
	}
	editAwardCategory(index, text) {
		let { id, name } = text
		if (id == 0) {
			this.updateInvested(1, index, text)
			return
		}
		let data = { id, name }
		Loading.global(true)
		post("editAwardCategory", data).then(res => {
			let { data: resDate } = res || {}
			let { code } = resDate || {}
			if (code == 0) {
				this.getTableList()
			}
			Loading.global(false)
		}).catch(err => {
			Loading.global(false)
			console.log(err);
		});
	}
	addAwardCategory = () => {
		this.modalRef = Modal.open({
			component: props => <ModalContent close={props.close} title={__("Create award category")}>
				<AwardCategoryModel close={props.close} isAdd={true} tableList={this.state.awardTableList} callback={this.getTableList} />
			</ModalContent>,
			className: 'sprout-modal',
			width: 600
		})
	}
	componentWillUnmount() {
		this.modalRef && this.modalRef.close()
	}
	render() {
		const { awardTableList, columns } = this.state
		return (
			<div className="awardCategiry-template">
				<div className="header-nav">
					<Button type="primary" onClick={this.addAwardCategory}>{__('Create award category')}</Button>
				</div>
				<Table
					dataSource={awardTableList}
					rowKey={value => value.id}
					pagination={false}
					columns={columns}
				>
				</Table>

			</div>
		)
	}

}

export default AwardCategory;