import React from 'react';
import { Button, message, Checkbox, DatePicker, Space } from 'antd';
import Loading from 'components/Loading';
import DateSelector from 'components/DateSelector';
import { FormItem, withForm } from 'react-antd-formutil';
import moment from 'moment';
import { post } from 'srcPath/http';
import security from 'stores/security';
import icon from '../../images/icon.png';
import { formatDate2Api } from 'Utils/format/formatDate';
import Modal, { ModalContent } from 'components/Modal';
const CheckboxGroup = Checkbox.Group;

function VotingPower({ $formutil, getPreviewList }) {
	const { $invalid, $batchDirty, $params, $getFirstError } = $formutil

	const onSubmit = async () => {
		if ($invalid) {
			$batchDirty(true)
			message.error($getFirstError())

			return
		}

		Loading.global(true);
		try {

			const { data: resData } = await post('exportVotingPower', { is_export: 0 })

			const { data, code } = resData || {}

			if (code === 0) {

				data.forEach((item, i) => {
					item.key = i
					item.children = item.security
					item.Totals='Totals'
				})
				message.success(__('Success'))
				getPreviewList({"voting_power":data},'','VotingPower')
				security.getFileData()
			} else {
				message.error(data)
			}

		} catch(e) {
			message.error(e.message)
		}

		Loading.global(false)
	}

	return <div className="sprout-reports-item-container">
		<div className="sprout-reports-item-left">
			<div className="sprout-reports-item-row">
				<div className="sprout-reports-icon">
					<img src={icon} />
				</div>
				<div className="sprout-reports-item-content">
					<div className="title">{__('Voting Power')}</div>
					<div className="item-section">
						<FormItem
							validMessage={{ required: __('Please select')}}
							required
							name="ledger"
							$defaultValue={['1']}
						>
							<CheckboxGroup>
								<Space direction="vertical">
									<Checkbox value="1">{__('Voting power report')}</Checkbox>
								</Space>
							</CheckboxGroup>
						</FormItem>
					</div>
				</div>
			</div>
		</div>
		<div className="btns">
			<Space size={15}>
				<span>{__('As of')} {formatDate2Api(moment())}</span>
				{/*<DateSelector bordered={true} disabled format={security.companyInfo && security.companyInfo.date_format}  defaultValue={moment()} />*/}
				<Button type="primary" onClick={onSubmit}>{__('Preview')}</Button>
			</Space>
		</div>
	</div>
}

export default withForm(VotingPower)
