import React, { Component, createRef } from 'react';
import { Input, Button, message, Tooltip } from 'antd';
import { post } from 'srcPath/http';
import Utils, { bindKeyEvent } from 'Utils';
import security from 'stores/security';
import { printf } from 'Utils/i18n';
import './style.scss';

class SignatureModal extends Component {
	state = {
		loading: false,
		signature: security.userInfo.signature || ''
	}

	inputRef = createRef()

	componentDidMount() {
		this.inputRef.current.focus()
		this.removeKeyEvent = bindKeyEvent(this.onSubmit)
	}

	componentWillUnmount() {
		this.removeKeyEvent && this.removeKeyEvent()
	}

	onSubmit = async () => {
		const { callback, close, apiType, data } = this.props

		if (!this.state.signature) {
			message.error('Please input signature')

			return
		}

		if (security.userInfo.signature && (this.state.signature !== security.userInfo.signature)) {
			message.error(`Sign as "${security.userInfo.signature}" please`)

			return
		}

		this.setState({ loading: true })

		try {
			const { data: resData } = await post(apiType || 'signSecurity', data)

			if (resData.code == 0) {
				message.success(resData.data)
				callback && callback()
				close && close();
			} else {
				message.error(resData.data)
			}
		} catch (e) {
			message.error(e.message)
		}

		this.setState({ loading: false })
	}

	handleChange = event => {
		this.setState({
			signature: event.target.value
		})
	}

	render() {
		const { btnText, tips } = this.props
		const { signature } = this.state

		return <div className="signature-modal">
			<div className="signature-tips">
				{tips || 'These certificates needs your signature in order to be sent.  Your signature will appear on the front of the certificates.'}
			</div>
			<div className="signature-input">
				<Tooltip
					arrowPointAtCenter
					title={__('Sign Here')}
					color="volcano"
					placement="left"
					visible={!this.state.signature}>
					<Input ref={this.inputRef} bordered={false} onChange={this.handleChange} value={signature} />
				</Tooltip>
			</div>
			<div className="signature-current">{printf(__('Sign as "%s"'), security.userInfo.signature)} </div>
			<div className="signature-btn-wrap">
				<Button type="primary" loading={this.state.loading} onClick={this.onSubmit}>{btnText || __('Save Changes')}</Button>
			</div>
		</div>
	}
}

export default SignatureModal