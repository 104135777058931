import React, { Component } from "react";
import {message, Select} from "antd";
import ByStakeholderModels from "./ByStakeholderModels";
import ByShareClassModels from "./ByShareClassModels";
import BreakpointsModels from "./BreakpointsModels";
import { post } from 'srcPath/http';
import { I } from 'Utils';


class RightModels extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tableType : 1,
        }
    }

    tableTypeChange = (value) => {
        this.setState(()=>({
            tableType:value
        }));
    }

    render() {
        const { tableType } = this.state;
        const { bodyHeight, stakeHolderList, shareClassList, breakPointsList, prefix, loading } = this.props;
        return (
            <div className="waterfall-detail-right-box" style={{height:bodyHeight}}>
                <div className="detail-background">
                    <div className="content">
                        <dl>
                            <dt>
                                <div className="waterfall-payout-title">
                                    <h5>{__('Payouts')}</h5>
                                    <p>{__('View proceeds and metrics by share classes and top stakeholders for the exit.')}</p>
                                </div>
                                <div className="waterfall-payout-select">
                                    <Select className="payout-select" onChange={this.tableTypeChange} value={tableType}>
                                        <Select.Option value={1}>{__('By Stakeholder')}</Select.Option>
                                        <Select.Option value={2}>{__('By Share Class')}</Select.Option>
                                    </Select>
                                </div>
                                <div className="clear"></div>
                            </dt>
                            <dd>
                                {
                                    tableType == 1 ?
                                    <ByStakeholderModels
                                        stakeHolderList={stakeHolderList}
                                        prefix={prefix}
                                        loading={loading}
                                    /> :
                                    <ByShareClassModels
                                        shareClassList={shareClassList}
                                        loading={loading}
                                    />
                                }
                            </dd>
                        </dl>

                        <dl className="mt-20 mb-25">
                            <dt>
                                <div className="waterfall-payout-title breakpoints-table">
                                    <h5>{__('Breakpoints')}</h5>
                                </div>
                                <div className="clear"></div>
                            </dt>
                            <dd>
                                <BreakpointsModels
                                    breakPointsList={breakPointsList}
                                    loading={loading}
                                />
                            </dd>
                        </dl>

                    </div>
                </div>
            </div>
        );
    }
}
export default RightModels