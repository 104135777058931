import React, { useState } from 'react';
import Modal, { ModalContent } from 'components/Modal';
import { openEditStakeholderModal } from 'B/components/WalletLabel/EditStakeholder';
import { openEditWalletModal } from 'B/components/WalletLabel/EditWalletlabel';
import { FormItem, withForm } from 'react-antd-formutil';
import { Button, Radio, Space, Input, message, Alert } from 'antd';
import SelectStakeholder from '../components/StakeholderSelector';
import WalletLabel from '../components/WalletLabelSelector';
import Loading from 'components/Loading';
import { post } from 'srcPath/http';

import './style.scss';

function Content (props) {
	const { walletAddress, close, callback, $formutil, cost_list } = props;
	const wallet_address = Array.isArray(walletAddress) ? walletAddress[0] : walletAddress
	const { $invalid, $batchDirty, $params, $getFirstError } = $formutil;
	const [ errorMessage, setErrorMessage ] = useState('')

	const onNext = async () => {
		if (!wallet_address) {
			addNew()
		}

		if ($invalid) {
			$batchDirty(true)

			message.error($getFirstError())
			
			return
		}

		const { wallet_label_id, selected, stakeholder_id } = $params

		const api = selected === 'wallet' ? 'addOneAddressToWalletLabel' : 'addOneAddressToStakeholder'

		try {
			Loading.global(true)

			const { data: resData } =  await post(api, {
				wallet_label_id,
				stakeholder_id,
				wallet_address
			})

			const { code, data } = resData || {}

			if (code === 0) {
				callback && callback()

				close()
			} else {
				setErrorMessage(data)
			}

		} catch (e) {
			message.error(e.message)
		}

		Loading.global(false)
	}

	const addNew = () => {
		const updateFunc = $params.selected === 'wallet' ? openEditWalletModal : openEditStakeholderModal
		updateFunc(walletAddress, callback, cost_list)
		close()
	}

	return <div className="transaction-update-stakeholder">
		<div className="transaction-update-stakeholder-content">
			{
				wallet_address && <FormItem
			    	name="walletAddress"
			    	label="Address"
			    	$defaultValue={Array.isArray(walletAddress) ? walletAddress.join('\n') : walletAddress}
			    >
			    	<Input.TextArea autoSize/>
			    </FormItem>
			}
			<FormItem
				name="selected"
				label="Type"
				required
				$defaultValue="stakeholder"
			>
				<Radio.Group>
					<Space direction="horizontal">
						<Radio value="stakeholder">Add Stakeholder</Radio>
						<Radio value="wallet">Label Wallet</Radio>
					</Space>
				</Radio.Group>
			</FormItem>
			{
				wallet_address && ($params.selected === 'wallet' ?
				<FormItem
					name="wallet_label_id"
					label="Wallet label"
					key="wallet"
					required
				>
					<WalletLabel placeholder="Select" />
				</FormItem> :
				<FormItem
					name="stakeholder_id"
					label="Stakeholder"
					key="stakeholder"
					required
				>
					<SelectStakeholder placeholder="Select" />
				</FormItem>)
			}
			{
				wallet_address && <div className="add-new" onClick={addNew}>
				{__('Add new')} 
				</div>
			}
			{
				errorMessage && <Alert message={errorMessage} type="error" />
			}			
		</div>
		<div className="transaction-update-stakeholder-btn">
			<Button onClick={() => close()}>{__('Cancel')}</Button>
			<Button type="primary" onClick={() => onNext()}>{ wallet_address ? 'Submit' : 'Next' }</Button>
		</div>
	</div>
}

const UpdateStakeholderContent = withForm(Content) 

export function openUpdateStakerholderModal(wallet_address, callback, cost_list, title ) {
	return Modal.open({
		component: ({ close }) => <ModalContent close={close} title={title || 'Update Wallet'} >
			<UpdateStakeholderContent walletAddress={wallet_address} close={close} callback={callback} cost_list={cost_list} />
		</ModalContent>,
		className: 'sprout-modal',
		width: 720
	})
}