import React from "react";
import Utils,{ formatNumber } from "Utils";
import { post } from 'srcPath/http';
import Loading from 'components/Loading';
import security from 'stores/security';
import {Link} from "react-router-dom";
import { printf } from 'Utils/i18n';

class AddToLedger extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sendStatus:0,
            loadingStatus:1,
            type:"RSA",
            checkedIds:[],
            checkedData:[],
            errorKeys:[],
            signatureAdminList: {
                certificate1_admin_name: "ning",
                certificate2_admin_name: "Ning Da",
                equity_plan_admin_name: "ning",
                convertibles_admin_name: "Ning Da",
            },
        }
    }
    getSignatureAdminList=()=>{
        post('getSignatureAdminList').then((response)=> {
            if(response.data.code === 0){
                this.setState({
                    signatureAdminList:response.data.data,
                    loadingStatus:0,
                })
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    getCheckedId=()=>{
        const { checkedIds, curData } = this.props;
        let checkedData = [];
        for (let i = 0;i < curData?.length; i++){
            for(let j = 0; j< checkedIds?.length; j++){
                if(curData[i].id == checkedIds[j] ){
                    checkedData.push(curData[i])
                }
            }
        }
        this.setState({
            checkedIds,
            checkedData: [].concat(checkedData)
        })
    }
    addToLedger=()=>{
        if(this.state.sendStatus==1){
            return;
        }
        this.setState({
            sendStatus:1,
            loadingStatus:1,
        })
        post('createSecurityFromDraft', {ids:this.state.checkedIds,admin_id:Utils.getLocalCompanyInfo().admin_id,security_plan_id:this.props.curPlanId}).then((response)=> {
            if(response.data.code === 0){
                let successMessage = {};
                let failureMessage = {};
                //返回两种情况：is_success:1 成功  is_success:0 失败
                for (let i=0;i<this.state.checkedIds.length;i++){
                    if(response.data.data[this.state.checkedIds[i]].is_success==1){
                        successMessage[this.state.checkedIds[i]] = response.data.data[this.state.checkedIds[i]];
                    }else if(response.data.data[this.state.checkedIds[i]].is_success==0){
                        failureMessage[this.state.checkedIds[i]] = response.data.data[this.state.checkedIds[i]]
                    }
                }
                //成功即将此条数据从draft列表中移除
                if(successMessage){
                    this.props.removeSuccessData(successMessage) //执行父组件方法将成功数据从列表移除
                }
                //失败即显示失败提示
                if(failureMessage){
                    this.props.getErrorMessage(failureMessage)
                }
                this.setState({
                    sendStatus:0,
                    checkedIds:[],
                })
                security.init();
                window.$("#addToLedgersModal").modal('hide');
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    closeSendModel=()=>{
        $("#addToLedgersModal").modal("hide")
    }
    render(){
        const { checkedData } = this.state;
        let Shares = 0;
        let Count = 0;
        let FormatShares = 0;
        let PlanName = __("equity incentive plan");
        for (let i=0; i < checkedData.length; i++){
            Shares += parseFloat(Utils.resetNumberWithCommas(checkedData[i].quantity));
            Count += 1;
            FormatShares = formatNumber(Shares,null);

            if(Count ===1 && this.state.checkedIds[0] == checkedData[i].id){
                PlanName = this.props.formatShareClassList[checkedData[i].security_plan_id].plan_name;
            }
            if(Count>1){
                PlanName = __('equity incentive plan')
            }
        }
        let signatureTip = 0;
        let signatureAdmin = this.state.signatureAdminList.certificate1_admin_name + __(" and ") + this.state.signatureAdminList.certificate2_admin_name +",";
        if(this.state.signatureAdminList.certificate_type==1){
            if(this.state.signatureAdminList.certificate1_admin_name!=""){
                signatureAdmin = this.state.signatureAdminList.certificate1_admin_name
            }else if(this.state.signatureAdminList.certificate1_admin_name==""){
                signatureTip = 1
                signatureAdmin = "--"
            }
        }else{
        if(this.state.signatureAdminList.certificate1_admin_name==""&&this.state.signatureAdminList.certificate2_admin_name!=""){
            signatureTip = 1;
            signatureAdmin = "-- {__('and')} " + this.state.signatureAdminList.certificate2_admin_name
        }else if(this.state.signatureAdminList.certificate1_admin_name!=""&&this.state.signatureAdminList.certificate2_admin_name==""){
            signatureTip = 1;
            signatureAdmin = this.state.signatureAdminList.certificate1_admin_name+" {__('{__('and')}')} -- "
        }else if(this.state.signatureAdminList.certificate1_admin_name==""&&this.state.signatureAdminList.certificate2_admin_name==""){
            signatureTip = 1;
            signatureAdmin = "-- {__('and')} --"
        }
        }
        return(
            <div className="modal fade" id="addToLedgersModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="modal-dialog dialog-top40">
                    <div className="modal-content content580">
                        <div className="modal-header box-header">
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                            <h4 className="modal-title">{__('Add to ledger')}</h4>
                        </div>
                        <div className="modal-body padding2026">
                            {this.state.loadingStatus === 1 &&
                            <div className="termsLoadingPosition">
                                <Loading />
                            </div>
                            }
                            {this.state.loadingStatus === 0 &&
                            <div>
                                {signatureTip==1 &&
                                <div className="alert alert-warning alert-dismissable pink-box eq-tipbox marginBott20" role="alert">
                                    <p>{__('Please set signatory first. ')}<Link onClick={this.closeSendModel} to="/home/permissions/signatories">{__('Set signatories')}</Link></p>
                                </div>
                                }
                                <div className="flexDiv">
                                    <div>
                                        <span className="send-ok"></span>
                                    </div>
                                    <div>
                                        <p className="transfer-title mb_0">{__('Purchased restricted stock')}</p>
                                        <p className="text-muted mb_0">{printf(__('%s certificate(s) totaling %s shares will be drawn from the %s as of the board approval date.'), Count, FormatShares, PlanName)} {__('They will be issued as outstanding common stock as of the issue date.')}</p>
                                        <p className="text-muted mb_0">{printf(__('%s will receive email notification(s) to sign the certificate.'), signatureAdmin)}</p>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                        {signatureTip==1 &&
                        <div className="captable-footer box-footer">
                            <a className="btn boxbtn-to width160 send-disabledBtn">{__('Add to ledger')}</a>
                        </div>
                        }
                        {signatureTip == 0 &&
                        <div className="captable-footer box-footer">
                            <a onClick={this.addToLedger}
                               className="btn boxbtn-to width160 bg-darkblue ">{this.state.sendStatus == 0 ? __("Add to ledger") : __("Adding")}</a>
                        </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
export default AddToLedger;