import React from 'react';
import 'assets/css/stakeholders.css';
import { post } from 'srcPath/http';
import { Link } from "react-router-dom";
import { I } from 'Utils';
import 'assets/css/securities.css';
import 'assets/css/equityawards.css';
import Loading from 'components/Loading';
import { Button, Dropdown, Input, Menu, message, Badge } from 'antd';
import RequestRepricing from "../EquityAwardsModal/RequestRepricing";
import AddVestingSchedule from "../../components/AddVestingSchedule";
import CreateEquityAwards from "../EquityAwardsModal/CreateEquityAwards";
import EditEquityPlan from "../EquityAwardsModal/EditEquityPlan";
import AddAdminNotes from "../../components/AddAdminNotes";
import CancelModal from "../../components/CancelModal";
import VestMilestones from "../EquityAwardsModal/VestMilestone";
import ModifyRSUs from "../EquityAwardsModal/ModifyRSUs";
import RepurchaseShares from "../../Shares/SharesModal/RepurchaseShares";
import PurchaseRSA from "../EquityAwardsModal/PurchaseRSA";
import SettleRSU from "../EquityAwardsModal/SettleRSU";
import ModifyVestingCondition from "../EquityAwardsModal/ModifyVestingCondition";
import AccelerateVesting from "../EquityAwardsModal/AccelerateVesting";
import Permission from 'components/Permission';
import TerminateStakeholder from "../../../Stakeholder/Stakeholder/StakeholdersModal/TerminateStakeholder";
import RsuTable from "./components/RsuTable";
import { CloseOutlined, DownOutlined, SearchOutlined } from "@ant-design/icons";
import ColumnController, { columnsFilter } from "components/ColumnController";
import reverseTerminationModal from "../../components/ReverseTermination";
import Filter from 'components/Filter';
import { printf } from 'Utils/i18n';

const orderMap = {
    null: 0,
    ascend: 4,
    descend: 3,
    undefined: 0
}

class RsusList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            checkedIds:[],
            current: 1,
            rsuPageSize: 100,
            loadingStatus: 0,
            curSelectRelation: "all",
            vestingScheduleList: [
                { id: 1, schedule_name: "abc1" },
                { id: 2, schedule_name: "ddd2" }
            ],
            formatScheduleList: {},
            listName: "",
            isShowCancelTip: 0,
            canBeCanceled: 1,
            eaPlanList: [],
            formatEaPlanList: {},
            currency: "",
            curSelectSecurity: {
                key: 0,
                id: 2,
                custom_label_id: 'EA-666',
                name: 'Cody Belseager',
                status: 'Outstanding',
                quantity: '100',
                exercised_quantity: '100',
                vested_quantity: "200",
                exercise_history: [],
                vested: '100',
                vested_bar: "",
                exercise_price: '',
                security_type: 'IOS',
                grant_date: '12/12/2019',
                last_modified: "12/12/2019",
                schedule: "No vesting schedule",
                price_per_share: '$1.00',
                transaction_value: '$100.00',
                issue_date: '12/12/2019',
                checkbox_status: false,
                sign_status: 'officerSignature',
                custom_label: "",
                grant_expiration_date: "",
                board_approval_date: "",
                currency: "",
                option_plan: "",
                notes: "",
                voluntary_termination_count: "",
                voluntary_termination_period: "",
                involuntary_termination_count: "",
                involuntary_termination_period: "",
                involuntary_termination_cause_count: "",
                involuntary_termination_cause_period: "",
                death_exercise_count: "",
                death_exercise_period: "",
                disability_exercise_count: "",
                disability_exercise_period: "",
                retirement_exercise_count: "",
                retirement_exercise_period: "",
                iso_disqualification_date: "",
                national_exemption: "",
                local_exemption: "",
                admin_note: "",
                form_of_grant_file: { name: "", real_name: "" },
                form_of_option_file: { name: "", real_name: "" },
                incentive_plan_file: { name: "", real_name: "" },
                additional_documents: { name: "", real_name: "" },
                form_of_exercise_file: { name: "", real_name: "" },
                purchase_agreement_file: { name: "", real_name: "" },
                form_of_settlement_agreement_file: { name: "", real_name: "" },
            },
            rsusList: [],
            filterName: {
                name: "",
                dateKey: "",

            },
            pageData: {
                name: "RSU",
                title: "RSU",
                text: "RSU",
                active_cancel_name: "RSU",

            },
            rsusCount: 0,
            selectIndex: 0,
            selectId: 0,
            tableChild: {},
            searchValue: '',
            searchRsusList: [],
            selectColumnList: [
                { checked: true, columnName: "Security", jsonKey: "custom_label_id", special: '' },
                { checked: true, columnName: "Stakeholder", jsonKey: "name", special: '' },
                { checked: false, columnName: "Status", jsonKey: "status", special: '' },
                { checked: false, columnName: "Quantity", jsonKey: "quantity", special: '' },
                { checked: false, columnName: "Issue date", jsonKey: "issue_date", special: 'date' },
                { checked: false, columnName: "Vesting schedule", jsonKey: "vesting_schedule", special: '' },
            ],
            draftRsuCount: '',
            tableColumn: [],
            redisKey: 'eaRsus',

            // 翻页参数，搜索条件
            total: 0,
            pageNumber: 1,
            pageSize: 100,
            filter: {},
            sort: {}
        }
    }
    componentDidMount() {
        this.getEaList();
    }
    getEaList = () => {
        let pageSize = localStorage.getItem('rsuPageSize') || this.state.pageSize;
        this.setState({
            pageSize,
            loading: true
        })
        let { pageNumber, filter, searchValue, sort } = this.state;
        Loading.global(true)
        post('getEaList', { ea_type: 'rsu', pageSize, pageNumber, filter, search: searchValue, sort: { ...sort, type: orderMap[sort?.type] || 0} }).then((response) => {
            if (response.data.code === 0) {
                let searchList = [...response.data.data.rsusList];
                let searchRes = [];
                searchList.map( ( v, k) => {
                    if( v ){
                        searchRes.push(v);
                    }
                });
                for (let i = 0; i < searchRes.length; i++) {
                    searchRes[i].checkbox_status = false;
                }
                let formatScheduleList = this.state.formatScheduleList;
                for (let i = 0; i < response.data.data.vestingScheduleList.length; i++) {
                    formatScheduleList[response.data.data.vestingScheduleList[i].id] = response.data.data.vestingScheduleList[i];
                }
                let formatEaPlanList = this.state.formatEaPlanList;
                for (let j = 0; j < response.data.data.eaPlanList.length; j++) {
                    formatEaPlanList[response.data.data.eaPlanList[j].id] = response.data.data.eaPlanList[j];
                }
                this.setState({
                    rsuPageSize: pageSize,
                    total: response.data.data.total,
                    rsusList: searchRes || [],
                    searchRsusList: searchRes || [],
                    vestingScheduleList: response.data.data.vestingScheduleList,
                    formatScheduleList: formatScheduleList,
                    formatEaPlanList: formatEaPlanList,
                    eaPlanList: response.data.data.eaPlanList,
                    currency: response.data.data.currency,
                    draftRsuCount: response.data.data.draftRsuCount,
                    filter,
                    loading: false
                })
                this.clear();
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
            Loading.global(false)
            this.setState({ loading: false })
        }).catch((error) => {
            message.error(error.message)
            this.setState({ loading: false })
            Loading.global(false)
        });
    }
    setListName = (value) => {
        this.setState({
            listName: value
        })
    }
    changeCheckBoxStatus = (num, listName, e) => {
        let securityList = this.state[listName];
        if (securityList[num].checkbox_status === false) {
            securityList[num].checkbox_status = true;
        } else {
            securityList[num].checkbox_status = false;
            $('#check01_' + listName).prop("checked", false);
        }
        this.setState({
            listName: securityList
        })
    }
    selectAllItems = (listName) => {
        var securityLists = this.state[listName];
        if ($('#check01_' + listName).prop("checked")) {
            $('.listCheckbox_' + listName).prop('checked', true);
            for (let i = 0; i < securityLists.length; i++) {
                securityLists[i].checkbox_status = true;
            }
        } else {
            $('.listCheckbox_' + listName).prop('checked', false);
            for (let i = 0; i < securityLists.length; i++) {
                securityLists[i].checkbox_status = false;
            }
        }
        this.setState({
            listName: securityLists
        })
    }

    clearInput = () => {
        this.refs.inputContent.value = "";
    }

    setFilter = (key) => {
        let curColumnList = this.state.selectColumnList;
        curColumnList[key].checked = !curColumnList[key].checked;
        this.setState({
            selectColumnList: curColumnList
        });
    }
    removeTip = () => {
        window.$(".signTipBox").addClass("removeTip");
    }

    setModifiedSecurity = (key, security) => {
        let curSecurityList = this.state.rsusList;
        curSecurityList[key] = security;
        this.setState({
            rsusList: curSecurityList,
            searchRsusList: curSecurityList,
        })
    }

    setDataForModifiedSecurity = (id, security) => {
        let curSecurityLists = this.state.rsusList;
        curSecurityLists.forEach((v, k) => {
            if (v.id == id) {
                curSecurityLists[k] = security;
            }
        })
        this.setState({
            rsusList: curSecurityLists,
            searchSecurityLists: curSecurityLists
        })
    }
    setCurSelectSecurity = (key, e) => {
        //把key先传进去方便返回的时候修改
        this.state.rsusList[key].key = key;
        this.setState({
            curSelectSecurity: this.state.rsusList[key]
        });
    }

    //resetSecurityStatus
    resetSecurityStatus = (id, status, type = 0) => {
        let securityLists = this.state.rsusList;
        securityLists.forEach((v, k) => {
            if (v.id == id) {
                v.status = status;
                if (type == 1) {
                    v.termination_date = ""
                }
            }
        })
        this.setState({
            rsusList: securityLists
        })

    }

    //Id定位security
    setCurSelectSecurityNew = (id) => {
        const { rsusList } = this.state;
        let info = {};
        rsusList.forEach((v, k) => {
            if (v.id == id) {
                info = { ...v }
            }
        });
        this.setState({
            curSelectSecurity: info
        });
    }

    saveState = (key, value) => {
        let data = {};
        data[key] = value;
        this.setState(data)
    }

    //resetCancel
    resetCancel = (id, status, cancellation_date) => {
        let securityLists = this.state.rsusList;
        securityLists.forEach((v, k) => {
            if (v.id == id) {
                v.status = status;
                v.cancellation_date = cancellation_date
            }
        })
        this.setState({
            rsusList: securityLists
        })
    }

    setSelectId = (id) => {
        this.setState({
            selectId: id
        })
    }

    loadCreatePlanPage = () => {
        this.refs.createEaPlan.initPageData();
    }

    reverseCancellation = () => {
        var requestData = {};
        requestData.id = this.state.curSelectSecurity.id;
        requestData.security_type = this.state.curSelectSecurity.security_type;
        Loading.global(true)
        post('reverseCancellation', requestData).then((response) => {
            if (response.data.code === 0) {
                this.resetSecurityStatus(requestData.id, "Outstanding");
                message.success(__("success! "))
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
            Loading.global(false)
        }).catch(function (error) {
            console.log(error);
            Loading.global(false)
        });
    }

    checkSecurityStatus = () => {
        let curList = this.state.rsusList;
        let flag = 1;
        for (let i = 0; i < curList.length; i++) {
            if (curList[i].checkbox_status == true && curList[i].status != "Outstanding" ) {
                flag = 0
            }
        }
        if (flag == 0) {
            this.setState({
                canBeCanceled: 0
            })
        }else{
            this.setState({
                canBeCanceled: 1
            })
        }

    }

    changeCancelNum = () => {
        const { rsusList, checkedIds } = this.state;
        this.refs.cancelModal.changeCancelNum();
        if(rsusList)
        for(let i=0; i<rsusList.length; i++){
            if(rsusList[i].checkbox_status){
                checkedIds.push(rsusList[i].id)
            }
        }
        this.setState({
            checkedIds
        })
    }

    resendEmail = () => {
        if (this.state.sendingStatus === 1) {
            return;
        }
        this.setState({
            sendingStatus: 1
        });
        Loading.global(true)
        post('sendEmail2Signatories', { security_id: this.state.curSelectSecurity.id }).then((response) => {
            if (response.data.code === 0) {
                message.success(__('Resent successfully!'));
                this.setState({
                    sendingStatus: 0
                });
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
            Loading.global(false)
        }).catch((error) => {
            message.error(error.message)
            Loading.global(false)
        });
    }
    refreshData = (id) => {
        let curData = this.state.rsusList;
        for (let i = 0; i < this.state.rsusList.length; i++) {
            if (curData[i].id == id) {
                curData[i].status = "Outstanding"
            }
        }
        this.setState({
            rsusList: curData,
            searchRsusList: curData,
        })
    }
    setStatusToRepurchased = (id) => {
        let curData = this.state.rsusList;
        for (let i = 0; i < this.state.rsusList.length; i++) {
            if (curData[i].id == id) {
                curData[i].status = "Repurchased"
            }
        }
        this.setState({
            rsusList: curData,
            searchRsusList: curData,
        })
    }
    resetVestingName = (id, vestingName, vestingId) => {
        let curData = this.state.rsusList;
        for (let i = 0; i < this.state.rsusList.length; i++) {
            if (curData[i].id == id) {
                if (vestingId != 0) {
                    curData[i].vesting_schedule_id = vestingId;
                    curData[i].vesting_schedule = this.state.formatScheduleList[vestingId].schedule_name
                } else {
                    curData[i].vesting_schedule = vestingName;
                }
            }
        }
        this.setState({
            rsusList: curData,
            searchRsusList: curData,
        })
    }
    deselect = (listName) => {
        let securityLists = this.state.listName;
        $('#check01').prop("checked", false);
        for (let i = 0; i < securityLists.length; i++) {
            if (securityLists[i].status == "Canceled" || securityLists[i].status == "Transferred") {
                securityLists[i].checkbox_status = false
            }
        }
        this.setState({
            listName: securityLists,
            canBeCanceled: 1,
            isShowCancelTip: 0,
        })
    }
    getQuantityToSettled = () => {
        this.refs.settleRsu.getQuantityToSettled();
    }
    getQuantityToExercise = () => {
        this.refs.exerciseOption.getQuantityToExercise();
    }
    selectModifyKey = (listName) => {
        let selectKey = "";
        for (let j = 0; j < this.state[listName].length; j++) {
            if (this.state[listName][j].checkbox_status === true) {
                selectKey = j
            }
        }
        this.setCurSelectSecurity(listName, selectKey);
    }
    getVestMilestone = () => {
        this.refs.vestMilestone.getMilestoneList();
    }
    resetListName = (value) => {
        this.setState({
            listName: value
        })
        if (this.state.curSelectSecurity.schedule_name != "") {
            this.getVestingData();
        }
    }
    getVestingData = () => {
        this.refs.addVesting.getVestingData();
    }

    getInitVestingList = () => {
        this.refs.acceleratedVesting.getInitVestingList();
        this.refs.acceleratedVesting.setDefaultDate();
        // this.refs.acceleratedVesting.availableQuantityCalculation();
    }
    setInitShareNote = () => {
        this.refs.addSharesAdminNoteEa.setInitNote();
        this.refs.addSharesAdminNoteEa.setListName();

    }

    /**
     * revers termination
     */

    reverseTermination = (record, id) => {
        reverseTerminationModal(record, id, this.getEaList)
    }

    /**
     * 清空选中效果
     */
    clear = () => {
        const { rsusList, tableChild } = this.state;
        const list = [...rsusList];
        list.forEach((v, k) => {
            list[k]['checkbox_status'] = false;
        })
        this.setState({
            rsusList: list,
            searchRsusList: list,
            rsusCount: 0,
            selectIndex: 0
        })
        tableChild?.clearAll?.();
    }

    /**
     * Actions按钮
     * @returns {JSX.Element}
     */
    getActionsBottom = () => {
        const { rsusCount, canBeCanceled } = this.state;
        const modifyDisable = rsusCount == 1 ? false : true;
        const cancelDisable = rsusCount > 0 && canBeCanceled == 1 ? false : true;
        return <Dropdown disabled={modifyDisable && cancelDisable ? true : false} overlay={this.actionsMenu} placement="bottomRight" trigger="click">
            <Button
                type="primary"
                className="shares-actions"
            ><div className="actions-left">{__('Actions')}</div><DownOutlined className="actions-right" /></Button>
        </Dropdown>
    }

    /**
     * Actions菜单
     * @returns {JSX.Element}
     */
    actionsMenu = () => {
        const { canBeCanceled, rsusCount, selectIndex: indexArray, selectId } = this.state;
        return (
            <Menu>
                {rsusCount == 1 && <Menu.Item onClick={this.setCurSelectSecurityNew.bind(this, selectId)}><a data-toggle="modal" data-target="#modifyRsuModal">{__('Modify RSUs')}</a></Menu.Item>}
                {canBeCanceled == 1 && <Menu.Item ><a data-toggle="modal" onClick={this.changeCancelNum} data-target="#cancelModal">{__('Cancel RSUs')}</a></Menu.Item>}
            </Menu>
        )
    }

    /**
     * 更新表格行样式
     * @param index 行下标
     * @param status 样式状态 true, false
     */
    updateShowMsgStatus = (index, status) => {
        const { rsusList } = this.state;
        let list = [...rsusList];
        list[index]['showMsgStatus'] = status;
        this.setState(() => ({
            rsusList: list
        }))
    }

    /**
     * 更新table checkbox
     * @param selectCount 选中总计
     * @param selectIndexArray 有更新的checkbox下标和状态
     * @param isEmpty checkbox是否为全不选状态
     */
    updateSelectCount = (rsusCount, selectIndexArray, isEmpty = false) => {
        const { rsusList } = this.state;
        let list = [...rsusList];
        if (isEmpty) {
            list.forEach((v, k) => {
                list[k]['checkbox_status'] = false;
            })
        } else {
            selectIndexArray.forEach((v, k) => {
                list[v.selectIndex]['checkbox_status'] = v.checked;
            })
        }
        this.setState(() => ({
            rsusCount,
            selectIndex: selectIndexArray[0],
            rsusList: list
        }))
    }

    tableObj = (ref) => {
        this.setState(() => ({
            tableChild: ref
        }))
    }

    /**
     * 搜索列表数据
     * @param e
     */
    searchList = (e) => {
        const { value } = e.target;
        this.setState({
            searchValue: value,
        })
    }

    /**
     * 清空搜索内容
     */
    closeSearch = () => {
        this.setState({
            searchValue: '',
        }, ()=>this.getEaList());
    }

    onPageChange = (current, pageSize) => {
        localStorage.setItem('rsuPageSize', pageSize);
        this.setState({
            pageNumber: pageSize === this.state.pageSize ? current : 1,
            pageSize
        })
        // }, () => this.getEaList())
    }
    // 筛选条件逻辑，如果有分页，需要把查询条件保存在state 供分页使用
    onFilterChange = (value = {}) => {
        if (JSON.stringify(value) !== JSON.stringify(this.state.filter)) {
            this.setState({
                filter: value,
                pageNumber: 1
            }, () => this.getEaList())
        }
    }

    sortTable = sort => {
        this.setState({ sort }, ()=> this.getEaList())
    }

    render() {
        let rsusCount = 0;
        let selectRsaKey = "";
        for (let k = 0; k < this.state.rsusList.length; k++) {
            if (this.state.rsusList[k].checkbox_status === true) {
                rsusCount++;
                selectRsaKey = k;
            }
        }
        const { currency = '', rsusList, curSelectSecurity, searchValue, draftRsuCount, tableColumn, redisKey, total, pageSize, pageNumber, sort, loading, eaPlanList } = this.state;
        return (
            <div className="content option_con">
                <TerminateStakeholder eaPlanList={eaPlanList} getTerminatedList={this.getEaList} setModifiedSecurity={this.setModifiedSecurity} curSelectStakeholder={this.state.curSelectSecurity} />
                {/*<ActionCancelModal selectCount={selectCount} pageData={this.state.pageData}/>*/}
                <EditEquityPlan eaPlanList={eaPlanList} ref={"editEquityPlan"} getEaList={this.getEaList} />
                <AddVestingSchedule getSecurityList={this.getEaList} ref={"addVesting"} listName={this.state.listName} resetVestingName={this.resetVestingName} vestingScheduleList={this.state.vestingScheduleList} curSelectSecurity={this.state.curSelectSecurity} />
                <ModifyVestingCondition curSelectSecurity={this.state.curSelectSecurity} />
                <AccelerateVesting listName={this.state.listName} ref={"acceleratedVesting"} curSelectSecurity={this.state.curSelectSecurity} getData={this.getEaList} />
                <CancelModal resetCancel={this.resetCancel} resetSecurityStatus={this.resetSecurityStatus} currency={this.state.currency} ref="cancelModal" pageData={this.state.pageData} curSelectSecurity={this.state.curSelectSecurity} checkedIds={this.state.checkedIds} getData={this.getEaList} />
                <RequestRepricing currency={this.state.currency} />
                {/*<TransferShares currency={this.state.currency} stakeholders={this.state.stakeholders} curSelectSecurity={this.state.curSelectSecurity} />*/}
                <CreateEquityAwards ref="createEaPlan" />
                <VestMilestones listName={this.state.listName} ref="vestMilestone" curSelectSecurity={this.state.curSelectSecurity} />
                <AddAdminNotes setModifiedSecurity={this.setModifiedSecurity} setDataForModifiedSecurity={this.setDataForModifiedSecurity} ref="addSharesAdminNoteEa" curSelectSecurity={this.state.curSelectSecurity} />
                <RepurchaseShares currency={this.state.currency} setStatusToRepurchased={this.setStatusToRepurchased} listName={this.state.listName} setModifiedSecurity={this.setModifiedSecurity} curSelectSecurity={this.state.curSelectSecurity} />
                {/*<RepurchaseShares curSelectSecurity={this.state.curSelectSecurity}/>*/}
                <PurchaseRSA currency={this.state.currency} refreshData={this.refreshData} curSelectSecurity={this.state.curSelectSecurity} />
                <ModifyRSUs getEaList={this.getEaList} currency={this.state.currency} eaPlanList={eaPlanList} formatEaPlanList={this.state.formatEaPlanList} ref="modifyRsu" setModifiedSecurity={this.setModifiedSecurity} curSelectSecurity={this.state.curSelectSecurity} />
                <SettleRSU ref="settleRsu" curSelectSecurity={this.state.curSelectSecurity} getEaList={this.getEaList} />

                <div className="equity_awards_conCenter scroll-cont">
                    {this.state.loadingStatus === 1 &&
                        <div className="loading_position">
                            <Loading />
                        </div>
                    }
                    {this.state.loadingStatus === 0 &&
                        <div id="rsus">
                            <div className="holders-nav clearfix">
                                <Permission type="securities_EA_actions" hide>
                                    <div className="action-box onset">
                                        <div className="btn-group">
                                            {this.getActionsBottom()}
                                        </div>
                                    </div>
                                </Permission>
                                <div className="form-group search-group banner-rightBox offset draft-statistic">
                                    {rsusCount >= 1 &&
                                        <div className="stateBox">
                                            <p className="items-tip">{printf(__('%s of %s items selected'),rsusCount,this.state.rsusList.length)}</p>
                                            <button onClick={this.clear}
                                                type="button" className="bg-dark holders-clearBtn">{__('Clear selection')}
                                            </button>
                                        </div>
                                    }
                                    <ColumnController filterList={tableColumn} redisKey={redisKey} thisObj={this} />
                                    <div className="mr-10">
                                        <Filter callback={val => this.onFilterChange(val)} fiterData={this.state.filter} type="rsus" />
                                        &nbsp;&nbsp;
                                    </div>
                                    <div className="stakeholder-search-box">
                                        <Input
                                            className="stakeholder-search-input"
                                            value={searchValue}
                                            onChange={this.searchList}
                                            onPressEnter={this.getEaList}
                                            prefix={<SearchOutlined className="search-prefix-icon" onClick={this.getEaList} />}
                                            suffix={<CloseOutlined style={{ opacity: searchValue ? 1 : 0 }} onClick={this.closeSearch} className="search-close-icon" />}
                                            placeholder={__('Search')}
                                        />
                                    </div>

                                    <Permission type="securities_EA_manageEquityPlans" hide>
                                        <div className="btn-group">
                                            <a
                                                className="btn drafts_btn security-manageBtn bg-darkblue dropdown-toggle"
                                                data-toggle="dropdown">{__('Manage equity plans')}<span
                                                    className="triangle triangle-whitedown manage_triangle"></span>
                                            </a>
                                            <ul className="dropdown-menu mange-downmenu shares-downmenu">
                                                <li><a className="add-stake"
                                                    data-toggle="modal" data-target="#editEquityAwardsModal">{__('Edit equity plans')}</a></li>
                                                <li><a data-toggle="modal" onClick={this.loadCreatePlanPage}
                                                    data-target="#createEquityAwardsModal">{__('Create equity plan')}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </Permission>
                                    <Permission type="securities_templates" hide>
                                        <Link to={{ pathname: `/home/securities/templates/2`, state: { activeKey: '2' } }} className="btn marginRig6 drafts_btn bg-darkblue">{__('Manage vesting')}</Link>
                                    </Permission>

                                    <Permission type="securities_draftEA" hide>
                                        <Badge className="draft-statistic-badge" count={I(draftRsuCount, 0)}>
                                            {
                                                eaPlanList.length == 0 ? <a disabled className="btn drafts_btn send-disabledBtn">{__('Draft RSUs')}</a> :
                                                    <Link to="/home/securities/stock-option-awards/draft-rsus"
                                                        className="btn drafts_btn bg-darkblue">{__('Draft RSUs')}</Link>
                                            }
                                        </Badge>
                                    </Permission>
                                </div>
                            </div>
                            {eaPlanList.length === 0 && !loading &&
                                <div className="empty_option ">
                                    <div className="empty_option_box marginTop80">
                                        <span className="empty_option_icon bg_draft_edit"></span>
                                        <h5 className="empty_option_text">{__('No RSUs have been issued yet.')}</h5>
                                        <Permission type="securities_EA_manageEquityPlans" hide>
                                            <button
                                                className="btn action-availableBtn action-btn white-text font_weight500"
                                                data-toggle="modal" onClick={this.loadCreatePlanPage}
                                                data-target="#createEquityAwardsModal">{__('Create equity plan')}
                                            </button>
                                        </Permission>
                                    </div>
                                </div>
                            }
                            {
                                eaPlanList?.length > 0 && <div className=" table-box">
                                    <RsuTable
                                        loading={loading}
                                        securityLists={rsusList}
                                        updateShowMsgStatus={this.updateShowMsgStatus}
                                        currency={currency}
                                        admin_note={curSelectSecurity.admin_note}
                                        notes={curSelectSecurity.notes}
                                        // setCurSelectSecurity={this.setCurSelectSecurity}
                                        checkSecurityStatus={this.checkSecurityStatus}
                                        setCurSelectSecurityNew={this.setCurSelectSecurityNew}
                                        setSelectId={this.setSelectId}
                                        resetSecurityStatus={this.resetSecurityStatus}
                                        resetCancel={this.resetCancel}
                                        getVestingData={this.getVestingData}
                                        getVestMilestone={this.getVestMilestone}
                                        getInitVestingList={this.getInitVestingList}
                                        getQuantityToSettled={this.getQuantityToSettled}
                                        reverseCancellation={this.reverseCancellation}
                                        setInitShareNote={this.setInitShareNote}
                                        resendEmail={this.resendEmail}
                                        reverseTermination={this.reverseTermination}
                                        updateSelectCount={this.updateSelectCount}
                                        onRef={this.tableObj}
                                        saveState={this.saveState}
                                        sortTable={this.sortTable}
                                        tableColumn={columnsFilter(tableColumn, redisKey, sort)}
                                        paginationConfig={{
                                            total,
                                            pageSize,
                                            current: pageNumber,
                                            showSizeChanger: true,
                                            onChange: this.onPageChange,
                                            showTotal: (sum) => <span>{sum} {__('items')}</span>
                                        }}
                                    />
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }

}

export default RsusList;

