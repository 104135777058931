import React, { useEffect, useState } from 'react';
import { Table, Spin, message } from 'antd';
import formatShares from 'Utils/format/formatShares';
import { formatCurrencyNumber } from 'Utils'
import { post } from 'srcPath/http';
import security from 'stores/security';

import './style.scss';

const columns = [
	{
		title: __('Share Class'),
		dataIndex: 'shareClass',
		ellipsis: true,
	},
	{
		title: __('Outstanding'),
		dataIndex: 'outstanding',
		ellipsis: true,
		render: val => formatShares(val)
	},
	{
		title: __('Seniority'),
		dataIndex: 'seniority',
		ellipsis: true,
		// render: val => formatShares(val)
	},
	{
		title: __('Original Issue Price'),
		dataIndex: 'originalPrice',
		render: text => formatCurrencyNumber(text)
	},
	{
		title: __('Conversion Price'),
		dataIndex: 'conversionPrice',
		ellipsis: true,
		render: val => formatShares(val)
	},
	{
		title: __('Conversion Ratio'),
		dataIndex: 'conversionRatio',
		ellipsis: true,
		render: val => formatShares(val)
	},
	{
		title: __('Multiplier'),
		dataIndex: 'multiplier',
		ellipsis: true,
		render: val => formatShares(val)
	}
]

const dividendsColumns = [
	{
		title: __('Share Class'),
		dataIndex: 'shareClass',
		ellipsis: true,
	},
	{
		title: __('Cumulative'),
		dataIndex: 'cumulative ',
		ellipsis: true,
	},
	{
		title: __('Dividend Rate'),
		dataIndex: 'dividendRate',
		ellipsis: true,
		// render: val => formatNumber(val)
	},
]

const participationColumns = [
	{
		title: __('Share Class'),
		dataIndex: 'shareClass',
	},
	{
		title: __('Participating'),
		dataIndex: 'participating',
		ellipsis: true,
		// render: val => formatNumber(val)
	},
	{
		title: __('Participation Cap'),
		dataIndex: 'participatingCap',
		ellipsis: true,
		// render: val => formatNumber(val, 0)
	}
]

export default function FinancingHistory (props) {
	const [loading, setLoading] = useState(true)
	const [data, setData] = useState({ dividends: [], participation: [], shareClassDetail: [] })	

	const getData = async () => {
		try {
			const { data: resData } = await post('getProfileRightsAndPreference', {
				stakeholder_id: security.companyInfo && security.companyInfo.stakeholder_id
			})

			const { code, data } = resData || {}

			if (code === 0) {

				setData(data)
			}

		} catch (err) {
			message.error(err.message)
		}
		
		setLoading(false)
	}

	useEffect(() => {
		getData()
	}, [])

	return <div className="portfolio-capitalization-rights-root">
		<Spin spinning={loading}>
			<div className="portfolio-capitalization-rights-table">
				<div className="rights-title">{__('Share class details')}</div>
				<Table size="small" rowKey="round" columns={columns}  rowKey="id" dataSource={data.shareClassDetail || []} pagination={false} scroll={{ x: true }} />
			</div>
			<div className="portfolio-capitalization-rights-table">
				<div className="rights-title">{__('Dividends')}</div>
				<Table size="small" rowKey="round" columns={dividendsColumns}  rowKey="id" dataSource={data.dividends || []} pagination={false} scroll={{ x: true }} />
			</div>
			<div className="portfolio-capitalization-rights-table">
				<div className="rights-title">{__('Participation')}</div>
				<Table size="small" rowKey="round" columns={participationColumns}  rowKey="id" dataSource={data.participation || []} pagination={false} scroll={{ x: true }} />
			</div>
		</Spin>
	</div>
}
