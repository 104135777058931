import React, { Component } from "react";
import {Button, Card, } from "antd";
import security from "stores/security";
import { LeftOutlined } from '@ant-design/icons';
import noPermission from "assets/images/no-permission.png";
import "./style.scss";
class Suspended extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    getPage = (value) => {
        const { getPage } = this.props;
        getPage(value);
    }

    render() {
        const { company_name } = security.companyInfo;
        return (
            <div className="billing-change-plan-root billing-suspended-root">
                <Button className="billing-change-plan-back" onClick={this.getPage.bind(this, 'overview')} >
                    <LeftOutlined />Back
                </Button>
                <div className="billing-change-plan-title">Account status: Suspended</div>
                <Card className="billing-speak-us-card">
                    <img src={noPermission} />
                    <p>{company_name} is currently not available and requires renewal.<br/>Please contact us at <a href="mailto:support@getsprout.co">support@getsprout.co</a> to reactivate your account.</p>
                </Card>
            </div>
        );
    }
}
export default Suspended;