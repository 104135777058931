import React, { useState, useEffect } from 'react';
import Modal, { ModalContent } from 'components/Modal';
import { FormItem, withForm } from 'react-antd-formutil';
import { Button, Space, Input, message, Alert } from 'antd';
import SectionHeader from 'components/Layout/FormSection';
import Relationship from 'B/components/WalletLabel/components/Relationship'
import Wallet from 'B/components/WalletLabel/components/Wallet'
import { openUpdateStakerholderModal } from '../AssignToken';
import { getDefaultTokenId } from 'B/components/TokenSelector'

import Loading from 'components/Loading';
import { post } from 'srcPath/http';

function Content (props) {
	const { walletAddress, close, callback, $formutil } = props;
	const { $invalid, $batchDirty, $params, $setValues, $getFirstError } = $formutil;
	const [errorMessage, setErrorMessage] = useState('')

	useEffect(() => {
		if (walletAddress) { 
			$setValues({
				wallet_address_list: walletAddress
			})
		 }
	}, [])

	const onSubmit = async () => {
		if ($invalid) {
			$batchDirty(true)

			message.error($getFirstError())

			return
		}

		try {
			Loading.global(true)

			const { data: resData } = await post('addWalletLabel', {
				...$params,
				coin_id: getDefaultTokenId()
			})

			const { code, data } = resData || {}

			if (code === 0) {
				callback?.()

				close()
			} else {
				setErrorMessage(data)
			}


		} catch (e) {
			message.error(e.message)
		}
		
		Loading.global(false)
	}

	const onCancel = () => {
		const address = (walletAddress || []).map(val => val.wallet_address)
		
		openUpdateStakerholderModal(address, callback)

		close()
	}

	return <div className="token-edit-stakeholder">
		<div className="token-edit-stakeholder-content">
			<SectionHeader title="Basic information">
				<div className="token-edit-stakeholder-item">
					<div className="token-edit-stakeholder-item-label">Name <span className="form-required">- Required</span></div>
					<FormItem
						name="name"
						required
					>
					<Input placeholder="Input" />
					</FormItem>
			    </div>
			</SectionHeader>
			<SectionHeader title="Relationship" subtitle="- Required">
				<Relationship />
			</SectionHeader>
			<SectionHeader title="Wallet" subtitle="- Required">
				<Wallet list={[walletAddress]} />
			</SectionHeader>
		</div>
		{
			errorMessage && <Alert message={errorMessage} type="error" />
		}
		<div className="token-edit-stakeholder-btn">
			<Space>
				{
					walletAddress?.length > 0 ? <Button onClick={onCancel}>Go Back</Button> : <Button onClick={() => close()}>Cancel</Button>
				}
				<Button type="primary" onClick={() => onSubmit()}>Submit</Button>
			</Space>
		</div>
	</div>
}

const UpdateWalletContent = withForm(Content) 

export function openEditWalletModal(wallet_address, callback) {
	const address = Array.isArray(wallet_address) ? wallet_address.map(val => ({ wallet_address: val })) : [{ wallet_address }]

	return Modal.open({
		component: ({ close }) => <ModalContent title="Add wallet label" close={close}>
			<UpdateWalletContent walletAddress={wallet_address && address} close={close} callback={callback} />
		</ModalContent>,
		className: 'sprout-modal',
		width: 800
	})
}