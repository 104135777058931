import React, { Component } from 'react';
import FairHeader from './components/FairHeader'
import Echarts from './components/echarts/index'
import { formatDate } from 'Utils/format/formatDate'
import { message, Spin } from 'antd';
import moment from "moment";
import FMVTable from './components/FMVTable'
import Modal from 'components/Modal';
import AdvanceTermsModels from './components/AdvanceTermsModels/AdvanceTermsModels'
import './style.scss';
import { I, formatNumber, formatCurrencyNumber } from 'Utils';
import emptyImg from 'assets/images/bar.png';
import { post } from 'srcPath/http';


// table

class FairMarketValue extends Component {
    //advanceTerms浮层
    advanceTermsModal = null;
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            loading: false,
            data: '',
            shareClass: [], //CommA commB
            psClass: [],
            datalist:[],
            EchartsSourec:[]
        }
    }
    

    componentDidMount = () => {
        this.payOverView()
    }
    payOverView = async () => {
        this.setState({ loading: true })
        try {
            const { data: resData } = await post('getFairMarketValue')
            const { code, data } = resData;
            const { fmvList, psClass, shareClass,select_list,currency } = data|| {};
            if (code === 0) {
                if (!I(data, '')) {
                    message.error(data)
                    return;
                }
                let newPsClass = I(psClass, []).filter(item => item.status == 0)
                let addFmvlist = I(psClass, []).filter(item => item.status == 1)
                let newFmvlist = [...I(fmvList, []), ...I(addFmvlist, [])]
                newPsClass.sort(function (a, b) {
                    let dataA = moment(a.effective_date, 'DD/MM/YYYY').format('YYYYMMDD');
                    let dataB = moment(b.effective_date, 'DD/MM/YYYY').format('YYYYMMDD');
                    return dataA <= dataB ? 1 : -1;
                })
                newFmvlist.sort(function (a, b) {
                    let dataA = moment(a.effective_date, 'DD/MM/YYYY').format('YYYYMMDD');
                    let dataB = moment(b.effective_date, 'DD/MM/YYYY').format('YYYYMMDD');
                    return dataA <= dataB ? 1 : -1;
                })
                newFmvlist.forEach(item => {
                    let start_time = moment(item.expiration_date, 'DD/MM/YYYY').format('YYYY/MM/DD')
                    let data1 = new Date(start_time).getTime()
                    let data2 = new Date().getTime()
                    if (data1 - data2 > 0) {
                        item.expired=0
                    } else {
                        item.expired=1
                    }
                })
                let dataSource = [...I(newFmvlist, []), ...newPsClass]
                let EchartsSourec = [...I(fmvList, []), ...I(psClass, [])]
                EchartsSourec.sort(function (a, b) {
                    let dataA = moment(a.effective_date, 'DD/MM/YYYY').format('YYYYMMDD');
                    let dataB = moment(b.effective_date, 'DD/MM/YYYY').format('YYYYMMDD');
                    return dataA >= dataB ? 1 : -1;
                })
                let datalist = EchartsSourec.map((item) => {
                    return formatDate(item.effective_date)
                })
                let newdatalist = EchartsSourec.map((item) => {
                    return item.effective_date
                })
                let marketlist = EchartsSourec.map((item) => {
                    return formatNumber(item.fair_market_value)
                })

                this.setState({
                    dataSource,
                    datalist,
                    marketlist,
                    shareClass,
                    loading: false,
                    share: dataSource.length>0?formatNumber(dataSource[0].fair_market_value):formatNumber('0.00'),
                    eff_date: dataSource.length>0?formatDate(dataSource[0].effective_date):'',
                    select_list,
                    newdatalist,
                    currency,
                    EchartsSourec
                })

            } else {
                message.error(data)
            }
        } catch (err) {
            message.error(err && err.message)
        }
    }

    bindclick = () => {
        const { shareClass,select_list,newdatalist,currency ,EchartsSourec} = this.state
        this.advanceTermsModal = Modal.open({
            component: props => <AdvanceTermsModels
                close={props.close}
                shareClass={shareClass}
                payOverView={this.payOverView}
                isAdd={true}
                select_list={select_list}
                newdatalist={newdatalist}
                currency={currency}
                EchartsSourec={EchartsSourec}
            />,
            className: 'certificate-modal-wrap',
            width: '780px',
            maskClosable: false
        })
    }

    judgeTime = (data) => {
        var date = data.toString();
        var year = date.substring(0, 4);
        var month = date.substring(4, 6);
        var day = date.substring(6, 8);
        var d1 = new Date(year + '/' + month + '/' + day);
        var dd = new Date();
        var y = dd.getFullYear();
        var m = dd.getMonth() + 1;
        var d = dd.getDate();
        var d2 = new Date(y + '/' + m + '/' + d);
        var iday = parseInt(d2 - d1) / 1000 / 60 / 60 / 24;
        return iday;
    }
    /**
      * 卸载
      */
    componentWillUnmount() {
        if (this.advanceTermsModal) {
            this.advanceTermsModal.close();
        }
    }


    handleOk() {
        this.setState({
            isModalVisible: false
        })
    }
    handleCancel() {
        this.setState({
            isModalVisible: false
        })
    }

    render() {
        const { dataSource, datalist, marketlist, loading, shareClass, share, eff_date,select_list ,newdatalist,currency,EchartsSourec} = this.state
        const { isHideTable = false } = this.props
        
        return (
            <div className='fairmarketvalue'>

                {!isHideTable && (<FairHeader bindclick={this.bindclick}></FairHeader>)}
                <Spin tip="Loading..." spinning={loading}>
                    {dataSource?.length + datalist?.length === 0 ? <img className="empty-icon" src={emptyImg} /> : (
                        <>
                            <Echarts
                                dataSource={dataSource}
                                datalist={datalist}
                                marketlist={marketlist}
                                share={share}
                                eff_date={eff_date}
                            ></Echarts>
                            {!isHideTable && (
                            <FMVTable
                                select_list={select_list}
                                newdatalist={newdatalist}
                                dataSource={dataSource}
                                shareClass={shareClass}
                                payOverView={this.payOverView}
                                currency={currency}
                                EchartsSourec={EchartsSourec}
                            ></FMVTable>)}
                        </>
                    )}
                </Spin>
            </div >
        )
    }
}
export default FairMarketValue