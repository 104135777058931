import React, { Component } from 'react';
import { Button } from 'antd';
class FairHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    // handleClick = () => {
    //     this.props.bindclick(true)
    // }

    isModalVisibleChange = () => {
        const { bindclick } = this.props;
        bindclick();
    }

    render() {

        return (
            <div className='fair-header'>
                <div className='fair-content-title'>
                    {__('Token Valuation')}
                </div>
                <div className='fair-content-border'>
                    <div className='child-border'></div>
                </div>
                <div className='fair-info'>
                    <div className='fair-info-text'>{__('Add future projections to your token valuation.')}</div>
                    <Button type="primary" onClick={this.isModalVisibleChange}>{__('Add valuation')}</Button>
                </div>
            </div>
        )
    }
}
export default FairHeader;