import React from 'react';

import Stakehoder from '../forms/Stakeholder';
import Status from '../forms/Status';
import ValuationCap from '../forms/ValuationCap';

export default function Form() { 
    return <div>
         <Stakehoder type='convertibles'/>
         <ValuationCap />
         <Status />
    </div>
}