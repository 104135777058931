import React from 'react';
import Product from '../forms/Product';
import OrderStatus from '../forms/OrderStatus';
import FormsDate from '../forms/FormsDate';
import OrderPrice from "../forms/OrderPrice";

export default function Form() { 
    return <div>
        <Product />
        <FormsDate name="time" label="Time" />
        <OrderPrice />
        <OrderStatus name="order_status" label="Status" list={['Completed', 'Failed']} />
    </div>
}