import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import FinanceHistory from './FinancingHistory';
import ScenarioModeling from "./ScenarioModeling";
import WireInstructions from './WireInstructions';
import security from 'stores/security';

export default class Fundraise extends Component {
	path = this.props.match.path

	config = security.hasFunction([
		{
			path: `${this.path}/financing-history`,
			component: FinanceHistory,
			permission: 'fundraising_finacingHistory',
			exact: true
		},
		{
			path: `${this.path}/scenario-modeling`,
			component: ScenarioModeling,
			permission: 'fundraising_scenarioModeling',
            exact: false
		},
		{
			path: `${this.path}/wire_instructions`,
			component: WireInstructions,
			permission: 'fundraising_wireInstructions',
			exact: true
		},
	]);

	render() {
		const routeConfig = this.config.filter((value, key) => security.hasPermission(value.permission))

		return <div className="sprout-captable-root">
			<Switch>
				{
					routeConfig.map((val, index) => <Route key={index} exact={val.exact} path={val.path} component={val.component} />)
				}
				<Redirect to={routeConfig && routeConfig[0] && routeConfig[0].path || this.path} />
			</Switch>
		</div>
	}
}