import React, { Component } from 'react';
import SimpleTable from '../components/SimpleTable';
import { formatNumber } from 'Utils';
import { formatDate } from 'Utils/format/formatDate';
import { sprintf } from 'Utils/i18n';

export default function ExercisePeriods(props) {
	const { grantExpirationDate, term = {}, postTermination } = props.data || {};
	const { voluntaryTermination, involunaryTermination, terminationWithCause, death, disability, retirement } = postTermination || {};
				
	const config = [
		{
			label: __('Voluntary termination'),
			value: voluntaryTermination
		},
		{
			label: __('Involunary termination'),
			value: involunaryTermination
		},
		{
			label: __('Termination with cause'),
			value: terminationWithCause
		},
		{
			label: __('Death'),
			value: death
		},
		{
			label: __('Disability'),
			value: disability
		},
		{
			label: __('Retirement'),
			value: retirement
		}
	]

	const termConfig = [
		{
			label: __('Expiration date'),
			value: formatDate(grantExpirationDate)
		}
	]

	const onClick = () => {
		props.onChange('documents');
	}

	return <div className="certificate-detail-section">
		<SimpleTable title={__('Post-termination exercise periods')} config={config} />
		<div className="ea-description">
			{sprintf(__('Refer to your %s and %s for a more detailed explanation of post-termination exercise period.'), <a onClick={onClick}>{__('Equity Incentive Plan')}</a>,<a onClick={onClick}>{__(' Option Agreement')}</a>)}
		</div>
		<SimpleTable title={__('Term')} config={termConfig} />
	</div>
}