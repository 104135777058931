import React, { Component, Fragment } from 'react';
import SideBar from '../components/SideBar';
import Empty from 'components/Empty';
import security from 'stores/security';
import Detail from './Detail';
import RepurchaseDetails from './RepurchaseDetails';
import TransferDetails from './TransferDetails';
import Transaction from './Transaction';
import VestingSchedule from '../components/VestingSchedule/vestingSchedule';
import PersonalDetail from './PersonalDetail';
import Approvals from '../components/Approvals';
import Documents from '../components/Documents';
import Legend from '../components/Legend';
import ReportIssue from '../components/ReportIssue';
import { cloneDeep } from 'lodash';

import './style.scss';

const menuConfig = [
	{
		label: __('Details'),
		key: 'details'
	},
	{
		label: __('Repurchase details'),
		key: 'repurchaseDetails'
	},
	{
		label: __('Transfer details'),
		key: 'transferDetails'
	},
	{
		label: __('Transaction history'),
		key: 'transactionHistory'
	},
	{
		label: __('Vesting Schedule'),
		key: 'vestingSchedule'
	},
	{
		label: __('Personal details'),
		key: 'personalDetails'
	},
	{
		label: __('Approvals'),
		key: 'approvals'
	},
	{
		label: __('Legend'),
		key: 'legend'
	},
	{
		label: __('Documents and notes'),
		key: 'documents'
	},
	{
		label: __('Report issue'),
		key: 'reportIssue'
	},
]

class CS extends Component {
	state = {
		checkedKey: this.props.checkedKey || 'details'
	}

	handleSideBar = key => {
		this.setState({ checkedKey: key })
	}

	formatConfig() {
		const data = this.props.data;
		// const vestingSchedule = data.vestingSchedule || {};
		const newMenuConfig = cloneDeep(menuConfig)
		// const vestingIndex = newMenuConfig.findIndex((item) => {
		// 	return item.key === 'vestingSchedule';
		// });

		// if (!vestingSchedule.vesting && vestingIndex !== -1) {
		// 	newMenuConfig.splice(1, 1);
		// }
		const reportIndex = newMenuConfig.findIndex((item) => {
			return item.key === 'reportIssue';
		});
		if (reportIndex > -1 && security.platform === 'B') {
			newMenuConfig.splice(reportIndex, 1);
		}

		const transferIndex = newMenuConfig.findIndex((item) =>{
			return item.key === 'transferDetails';
		})
		if (data.transferDetails && data.transferDetails.length === 0 ) {
			newMenuConfig.splice(transferIndex, 1)
		}

		const repurchaseIndex = newMenuConfig.findIndex((item) =>{
			return item.key === 'repurchaseDetails';
		})
		if (!data.repurchaseDetails) {
			newMenuConfig.splice(repurchaseIndex, 1)
		}

		return newMenuConfig
	}

	renderContent = () => {
		const { checkedKey = 'details' } = this.state
		const { data } = this.props
		const { approvals, details, vestingSchedule, personal_details, legend, documents, companyId, certificateList, formatText, type, digital_certificate, exercisePeriods, transactionHistory, company_name, certificateHistory, repurchaseDetails, transferDetails } = data || {}
		const objMap = {
			details: <Detail data={{...details, certificateList, formatText, digital_certificate, company_name, certificateHistory}}/>,
			repurchaseDetails: <RepurchaseDetails data={repurchaseDetails}  />,
			transferDetails: <TransferDetails data={transferDetails}  />,
			transactionHistory: <Transaction data={transactionHistory} />,
			vestingSchedule: <VestingSchedule data={vestingSchedule}/>,
			personalDetails: <PersonalDetail data={personal_details} />,
			approvals: <Approvals data={approvals}/>,
			legend: <Legend data={legend} />,
			reportIssue: <ReportIssue />,
			documents: <Documents data={{companyId, ...documents, type}} />,
		}
		return objMap[checkedKey] || objMap.details
	}

	render() {
		const { checkedKey } = this.state;
		const { data } = this.props;
		const newMenuConfig = this.formatConfig();

		return <div className="certificate-detail-root">
			<SideBar onChange={this.handleSideBar} value={checkedKey} config={newMenuConfig} type={__('Certificate')} name={data.name}/>
			<div className="certificate-detail-content">
				{ this.renderContent() }
			</div>
		</div>
	}
}

export default CS