import React from 'react';
import Utils, { I, formatNumber } from "Utils";
import RenameDraftSet from "../components/RenameDraftSet";
import CreateDraftSet from "../components/CreateDraftSet";
import SendInfoForSignature from "../components/SendInfoForSignature";
import { post } from 'srcPath/http';
import { relationshipList, countries } from "Utils/constant";
import DeleteDraftSet from "../components/DeleteDraftSet";
import DeleteDraftSecurity from "../components/DeleteDraftSecurity";
import Loading from 'components/Loading';
import Permission from 'components/Permission';
import security from 'stores/security';
import SelectStakeholder from 'components/SelectStakeholder'
import DraftSarTable from "./components/DraftSarTableModels";
import moment from "moment";
import { DatePicker, Checkbox, Button } from "antd";
import DateSelector from "../../../../components/DateSelector";
import CountrySelector from 'components/CountrySelector';
import EnterSarsInfomation from "../components/EnterSarsInfomation";
import Modal, { ModalContent } from 'components/Modal';
import {CloseOutlined, DeleteOutlined, ExclamationCircleOutlined, LeftOutlined} from '@ant-design/icons';
import { printf, sprintf } from 'Utils/i18n';
import formatFractionalNumber from '../../../Utils/format/formatFractionalNumber';

class DraftSars extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedIds:[],
            dateFormat: "DD/MM/YYYY",
            currency: "",
            loadingStatus: 1,
            formatScheduleList: {},
            addingStatus: 0,
            saveStatus: -1,
            listKey: -1,
            type: "SAR",
            draft_id: "",
            draft_name: "",
            data: [],
            curPlanName: "",
            curPlanId: "",
            isIncludeAcceleration: 0,
            draftRsuRequiredKey: [
                "custom_label_id",
                "board_approval_date",
                "email",
                "form_of_option_agreement", //这里先不加，document_set逻辑需要重新改。document_set:{name:"custom document",incentive_option_plan:{name:"No file selected",file_obj:""}}
                "expiration_date",
                "grant_date",
                "relationship",
                "name",
                "quantity",
            ],
            shareClassList: [],
            currencyList: ['USD', 'HKD', 'SGD',],
            scheduleList: [
                { id: 1, schedule_name: "kkk" }
            ],
            periodList: ["", "Days", "Months", "Years",],
            pageData: {
                name: "SAR",
                title: "SAR",
                text: "SAR",
                checked_count: "",
                error_status: 0,
                type: 1
            },
            eaSarDraftSetList: [],
            eaPlanList: [],
            documentSet: [
                // {id:0,set_name:"Custom documents",form_of_grant_file:{name:"",file_obj:""},form_of_settlement_agreement_file:{name:"",file_obj:""},incentive_plan_file:{name:"",file_obj:""},additional_documents:{name:"",file_obj:""}},
                { id: 1, set_name: "setRsu1", form_of_grant_file: { name: "11", file_obj: "" }, form_of_settlement_agreement_file: { name: "11", file_obj: "" }, incentive_plan_file: { name: "11", file_obj: "" }, additional_documents: { name: "11", file_obj: "" } },
            ],
            selectedDocKey: -1,
            successDetails: {},
            sendResult: {
                "1": {
                    "id": 1,
                    "is_success": 1,//1-成功，0表示失败
                    "error_reason": ""
                },
                "2": {
                    "id": 2,
                    "is_success": 0,//1-成功，0表示失败
                    "error_reason": [
                        { "title": "Name", "message": "Can't be empty!" },
                        { "title": "age", "message": "Can't be bigger than 150!" },
                        { "title": "Certificate Id", "message": "Duplicated" }
                    ]
                },
                "22": {
                    "id": 22,
                    "is_success": 0,//1-成功，0表示失败
                    "error_reason": [
                        { "title": "Name", "message": "Can't be empty!" },
                        { "title": "age", "message": "Can't be bigger than 150!" },
                        { "title": "Certificate Id", "message": "Duplicated" }
                    ]
                },
            },
            genderList: [
                {
                    name: 'Female'
                },
                {
                    name: 'Male'
                },
                {
                    name: 'Non-binary / non-conforming'
                },
                {
                    name:'Prefer not to respond'
                },
            ],
            award_category: [],
            stakeholderType: [
                {
                    name: __('Individual'),
                    value: 1
                },
                {
                    name: __('Non-individual'),
                    value: 0
                },
            ],
            curPlan_id: 0
        }
    }
    getEaSarDraftSetList = () => {
        post('getDraftSetList', { type: "SAR" }).then((response) => {
            if (response.data.code === 0) {
                for (let i = 0; i < response.data.data.draftList.length; i++) {
                    response.data.data.draftList[i].checkbox_status = false;
                    response.data.data.draftList[i].is_show_error = 0;

                }
                let formatObj = {};
                for (let j = 0; j < response.data.data.shareClassList.length; j++) {
                    formatObj[response.data.data.shareClassList[j].id] = response.data.data.shareClassList[j];
                }
                let formatScheduleList = this.state.formatScheduleList;
                for (let i = 0; i < response.data.data.vestingScheduleList.length; i++) {
                    formatScheduleList[response.data.data.vestingScheduleList[i].id] = response.data.data.vestingScheduleList[i];
                }
                this.setState({
                    eaSarDraftSetList: response.data.data.setList,
                    data: response.data.data.draftList,
                    draft_id: response.data.data.defaultSetInfo.id,
                    draft_name: response.data.data.defaultSetInfo.name,
                    shareClassList: response.data.data.shareClassList,
                    formatShareClassList: formatObj,
                    eaPlanList: response.data.data.eaPlanList,
                    curPlanName: response.data.data.eaPlanList[0].plan_name,
                    curPlanId: response.data.data.eaPlanList[0].id,
                    scheduleList: response.data.data.vestingScheduleList,
                    formatScheduleList: formatScheduleList,
                    loadingStatus: 0,
                    currency: response.data.data.currency,
                    documentSet: response.data.data.documentSet,
                    award_category: response.data.data.award_category,
                });
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    changeStatus = (key) => {
        let sarList = this.state.data;
        if (sarList[key].checkbox_status === false) {
            sarList[key].checkbox_status = true;
            if (sarList[key].is_show_error == 1) {
                $(".draft_error_sar_tip" + key).removeClass("showErrorTip")
            }
        } else {
            sarList[key].checkbox_status = false;
            if (sarList[key].is_show_error == 1) {
                $(".draft_error_sar_tip" + key).addClass("showErrorTip")
            }
            $('#select_all_sar').prop("checked", false);
        }
        this.setState({
            data: sarList
        })
    }
    selectAllSecurity = () => {
        var sarList = this.state.data;
        if ($('#select_all_sar').prop("checked")) {
            $('.listCheckbox').prop('checked', true);
            for (let i = 0; i < sarList.length; i++) {
                sarList[i].checkbox_status = true;
            }
        } else {
            $('.listCheckbox').prop('checked', false);
            for (let i = 0; i < sarList.length; i++) {
                sarList[i].checkbox_status = false;
            }
        }
        this.setState({
            data: sarList
        })
    }
    changeDraftId = (key1, key2) => {
        this.setState({
            draft_id: key1,
            draft_name: key2
        })
    }
    componentDidMount() {
        this.getEaSarDraftSetList();
    }
    recordData = (e) => {
        let listKey = this.state.listKey;
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        let equityPlanSize = ''
        if (dataKey == "vesting_schedule_id") {
            curData[listKey].vesting_schedule_id = dataVal;
            if (dataVal) {
                curData[listKey].vesting_schedule_name = this.state.formatScheduleList[this.state.data[listKey].vesting_schedule_id].schedule_name
            } else {
                curData[listKey].vesting_schedule_name = "No schedule";
                curData[listKey].vesting_start_date = ""
            }
        }
        if (dataKey == "documentset_id") {
            if (dataVal == -1) {
                curData[listKey].documentset_id = -1
            } else if (dataVal == -2) {
                this.props.history.push({ pathname: '/home/securities/templates', state: { activeKey: '1' } })
            } else if (dataVal == -3) {
                curData[listKey].documentset_id = 0;
                curData[listKey].form_of_grant_file.name = "";
                curData[listKey].form_of_settlement_agreement_file.name = "";
                curData[listKey].incentive_plan_file.name = "";
                curData[listKey].additional_documents = [{ name: "", file_obj: "" }];
            } else {
                let data_val = this.state.documentSet.findIndex(item => item.id == dataVal)
                curData[listKey].documentset_id = this.state.documentSet[data_val].id
                curData[listKey].form_of_grant_file.name = this.state.documentSet[data_val]?.form_of_grant_file?.name;
                curData[listKey].form_of_grant_file.real_name = this.state.documentSet[data_val]?.form_of_grant_file?.real_name;
                curData[listKey].form_of_settlement_agreement_file.name = this.state.documentSet[data_val]?.form_of_settlement_agreement_file?.name;
                curData[listKey].form_of_settlement_agreement_file.real_name = this.state.documentSet[data_val]?.form_of_settlement_agreement_file?.real_name;
                curData[listKey].incentive_plan_file.name = this.state.documentSet[data_val]?.incentive_plan_file?.name;
                curData[listKey].incentive_plan_file.real_name = this.state.documentSet[data_val]?.incentive_plan_file?.real_name;
                curData[listKey].additional_documents = this.state.documentSet[data_val]?.additional_documents;
            }
            this.setState({
                selectedDocKey: dataVal
            })
        }
        if (dataKey == 'stakeholder_type' && dataVal == 0) {
            curData[listKey].job_title_list = [
                { effective_date: '', job_title: "" },
            ]
            curData[listKey].gender = ''
            curData[listKey].date_of_birth = ''
            curData[listKey].annual_salary = ''
        }
        if (dataKey == 'security_plan_id') {
            let eaPlanList = this.state.eaPlanList
            let status = eaPlanList.findIndex(item => item.id == dataVal)
            if (status != -1) {
                equityPlanSize = eaPlanList[status].available
            }
        }
        curData[listKey][dataKey] = dataVal;
        this.setState({
            data: curData,
            equityPlanSize
        })
        if (dataKey == "security_plan_id" || dataKey == "relationship" || dataKey == "vesting_schedule_id") {
            this.editEaSarDraft();
        }
    }
    recordNumber = (e) => {
        let isFractionalShare = security.companyInfo.fractional_control;
        let listKey = this.state.listKey;
        let curData = this.state.data;
        let curVal = e.target.value;
        let dataKey = e.target.name;
        if (dataKey == "annual_salary") {
            curData[listKey][dataKey] = Utils.check2DecimalNumInputWithoutComma(curVal);
        } else if (dataKey == "quantity") {
            if (isFractionalShare == 1) {
                curData[listKey][dataKey] = Utils.check5DecimalNumInputWithoutComma(curVal);
            } else {
                curData[listKey][dataKey] = Utils.checkNumInput(curVal);
            }
        } else if (dataKey == "exercise_price") {
            curData[listKey][dataKey] = Utils.check5DecimalNumInputWithoutComma(curVal);
        } else {
            curData[listKey][dataKey] = Utils.checkIntInputWithoutComma(curVal);
        }
        this.setState({
            data: curData
        });
    }
    setDataFormat = (e) => {
        let listKey = this.state.listKey;
        let curData = this.state.data;
        let curVal = e.target.value;
        let dataKey = e.target.name;
        curData[listKey][dataKey] = Utils.resetNumberWithCommas(curVal);
        this.setState({
            data: curData
        });
    }
    resetDataFormat = (e) => {
        let isFractionalShare = security.companyInfo.fractional_control;
        let listKey = this.state.listKey;
        let curData = this.state.data;
        let curVal = e.target.value;
        let dataKey = e.target.name;
        if (dataKey == "annual_salary") {
            curData[listKey][dataKey] = formatNumber(curVal);
        } else if (dataKey == "quantity") {
            if (isFractionalShare == 1) {
                curData[listKey][dataKey] = formatNumber(curVal, 5);
            } else {
                curData[listKey][dataKey] = formatNumber(curVal, null);
            }
        } else if (dataKey == "exercise_price") {
            curData[listKey][dataKey] = formatNumber(curVal, null);
        } else {
            curData[listKey][dataKey] = formatNumber(curVal, 0);
        }
        this.setState({
            data: curData
        });
        this.editEaSarDraft()
    }
    fileSelect = (e) => {
        let listKey = this.state.listKey;
        let fileObj = e.target.files[0];
        let dataKey = e.target.name;
        let curData = this.state.data;
        Utils.uploadFile(fileObj).then((value) => {
            if (dataKey == "additional_documents") {
                let additionalDoc = { name: fileObj.name, file_obj: value };
                if (curData[listKey].additional_documents[0].name == '') {
                    curData[listKey].additional_documents[0] = additionalDoc;
                    $(".additional_documents0").removeClass("hidden");
                } else {
                    curData[listKey].additional_documents.push(additionalDoc);
                }
            } else {
                curData[listKey][dataKey].name = fileObj.name;
                curData[listKey][dataKey].file_obj = value;
            }
            this.setState({
                data: curData
            })
            this.editEaSarDraft();
        })
    }
    fileDelete = (key, additionalKey) => {
        let listKey = this.state.listKey;
        this.refs[key].value = "";
        let curData = this.state.data;
        if (additionalKey != -1) {
            if (curData[listKey][key].length == 1) {
                curData[listKey][key][0].name = "";
                curData[listKey][key][0].file_obj = "";
            } else {
                curData[listKey][key].splice(additionalKey, 1)
            }
        } else {
            curData[listKey][key].name = "";
            curData[listKey][key].file_obj = "";
        }
        this.setState({
            data: curData
        })
        this.editEaSarDraft();
    }
    createDraftSar = () => {
        if (this.state.addingStatus == 1) {
            return;
        }
        this.setState({
            addingStatus: 1
        })
        let curData = this.state.data;
        for (let i = 0; i < this.state.data.length; i++) {
            this.state.data[i].checkbox_status = false
        }
        this.setState({
            data: curData
        });
        post('createEaSarDraft', { draft_set_id: this.state.draft_id, security_plan_id: this.state.eaPlanList[0].id }).then((response) => {
            let curData = this.state.data;
            let draftSetList = this.state.eaSarDraftSetList;
            let setId = this.state.draft_id;
            if (response.data.code === 0) {
                response.data.data.checkbox_status = false;
                response.data.data.is_show_error = 0;
                curData.push(response.data.data)
                for (let i = 0; i < curData.length; i++) {
                    curData[i].is_show_error = 0
                }
                for (let j = 0; j < draftSetList.length; j++) {
                    if (draftSetList[j].id == setId) {
                        draftSetList[j].count = parseInt(draftSetList[j].count) + 1
                    }
                }
                this.setState({
                    data: curData,
                    addingStatus: 0,
                    // listKey: this.state.data.length - 1,
                    eaSarDraftSetList: draftSetList
                })
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    editEaSarDraft = () => {
        if (this.state.saveStatus == 1) {
            return;
        }
        this.setState({
            saveStatus: 0
        })
        let requestData = { ...this.state.data[this.state.listKey] };
        requestData.relationship_list = JSON.stringify(requestData.relationship_list)
        requestData.job_title_list = JSON.stringify(requestData.job_title_list)
        post('editEaSarDraft', requestData).then((response) => {
            if (response.data.code === 0) {
                if (response.data.data.custom_label_id) {
                    requestData['custom_label_id'] = response.data.data.custom_label_id;
                    this.getDraftSecurityList();
                }
                this.setState({
                    saveStatus: 1
                })
                setTimeout(() => {
                    this.setState({
                        saveStatus: -1
                    });
                }, 300)
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    changeEaDraftSelect = (planName, planId, e) => {
        this.setState({
            curPlanName: planName,
            curPlanId: planId
        })
    }
    transferKey = (key) => {
        const { listKey, data } = this.state;
        let curKey = listKey;
        let curData = data;
        let equityPlanSize = this.state.equityPlanSize
        let eaPlanList = this.state.eaPlanList
        if (curKey === key ) {
            curKey = -1;
        } else {
            curKey = key;
            curData.forEach((v, k)=>{
                v.checkbox_status = false;
                curData[k] = {...v};
            });
            let status = eaPlanList.findIndex(item => item.id == curData[curKey].security_plan_id)
            if (status != -1) {
                equityPlanSize = eaPlanList[status].available
            }
            let data_val = this.state.documentSet.findIndex(item => item.id == curData[curKey].documentset_id)
            if (data_val >= 0 && curData[listKey]) {
                curData[listKey].documentset_id = this.state.documentSet[data_val].id
                curData[listKey].form_of_grant_file.name = this.state.documentSet[data_val].form_of_grant_file.name;
                curData[listKey].form_of_grant_file.real_name = this.state.documentSet[data_val].form_of_grant_file.real_name;
                curData[listKey].form_of_settlement_agreement_file.name = this.state.documentSet[data_val].form_of_settlement_agreement_file.name;
                curData[listKey].form_of_settlement_agreement_file.real_name = this.state.documentSet[data_val].form_of_settlement_agreement_file.real_name;
                curData[listKey].incentive_plan_file.name = this.state.documentSet[data_val].incentive_plan_file.name;
                curData[listKey].incentive_plan_file.real_name = this.state.documentSet[data_val].incentive_plan_file.real_name;
                curData[listKey].additional_documents = this.state.documentSet[data_val].additional_documents;
            }
        }
        $('.collapse').removeClass('in');
        this.setState({
            listKey: curKey,
            equityPlanSize,
            data: curData
        })
    }

    selectCount = () => {
        let selectLength = this.state.pageData;
        let selectCount = 0;
        for (let j = 0; j < this.state.data.length; j++) {
            if (this.state.data[j].checkbox_status === true) {
                selectCount++;
            }
        }
        selectLength.checked_count = selectCount;
        this.setState({
            pageData: selectLength
        })
        this.refs.sendForSignature.getSignatureAdminList();
        this.refs.sendForSignature.getCheckedId();
    }
    alertError = () => {
        window.$(".correct_error_tip").removeClass("hidden");
    }
    transferDraftSetData = (key, refName, e) => {
        this.refs[refName].initData(key, this.state.eaSarDraftSetList[key]);
    }
    getDraftSecurityList = () => {
        post('getDraftSecurityListById', { id: this.state.draft_id }).then((response) => {
            if (response.data.code === 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    response.data.data[i].checkbox_status = false;
                    response.data.data[i].is_show_error = 0;
                }
                this.setState({
                    data: response.data.data,
                })
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    selectDraftSet = (key) => {
        let curDraftList = this.state.eaSarDraftSetList;
        this.setState({
            draft_name: curDraftList[key].name,
            draft_id: curDraftList[key].id
        })
        post('getDraftSecurityListById', { id: curDraftList[key].id }).then((response) => {
            if (response.data.code === 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    response.data.data[i].checkbox_status = false;
                    response.data.data[i].is_show_error = 0;
                }
                this.setState({
                    data: response.data.data,
                })
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    getDeleteCheckedId = () => {
        this.refs.deleteDraftSecurity.getCheckedId();
    }
    getDeleteDraftId = () => {
        this.refs.deleteDraftSecurity.getDeleteDraftId();
    }

    /**
     * 从list中移除发送成功的security
     * @param result
     */
    removeSuccessData = (result) => {
        let delIds = [];
        for (let i in result) {
            delIds.push(parseInt(i))
        }
        delIds.sort();
        let curData = [...this.state.data];
        let newData = [...this.state.data];
        for (let i = curData.length - 1; i >= 0; i--) {
            for (let j = 0; j < delIds.length; j++) {
                if (curData[i].id == delIds[j]) {
                    newData.splice(i, 1);
                }
            }
        }
        this.setState({
            data: newData,
            listKey: -1
        })
    }

    getErrorMessage = (result) => {
        this.setState({
            sendResult: result,
        })
        let curData = [...this.state.data];
        for (let i = 0; i < this.state.data.length; i++) {
            if (this.state.sendResult.hasOwnProperty(this.state.data[i].id)) {
                this.state.data[i].is_show_error = 1
            }
        }
        this.setState({
            data: curData
        })
    }
    handleSelectChange = (name, data) => {
        let curData = this.state.data;
        let listKey = this.state.listKey;
        if (data) {
            curData[listKey].name = data.nick_name || "";
            curData[listKey].email = data.email || "";
        }
        this.setState({
            selectedData: data,
            data: curData
        })
    }
    ifIncludeAcceleration = () => {
        let curData = this.state.data;
        let listKey = this.state.listKey;
        if (this.state.isIncludeAcceleration == 0) {
            this.setState({
                isIncludeAcceleration: 1
            })
        } else {
            curData[listKey].acceleration_terms = "";
            this.setState({
                data: curData,
                isIncludeAcceleration: 0
            })
        }
    }

    /**
     * checkbox change事件
     * @param selectedRowKeys
     */
    securityListsChange = (selectedRowKeys) => {
        const { data, listKey } = this.state;
        let checked = false;
        let securityLists = [...data];
        securityLists.forEach((v, k) => {
            securityLists[k]['checkbox_status'] = false;
        });
        selectedRowKeys.forEach((v) => {
            securityLists.forEach((item) => {
                if (item.id == v) {
                    item['checkbox_status'] = true;
                    checked = true;
                }
            })
        })
        this.setState({data: securityLists, listKey: checked ? -1 : listKey, checkedIds: [].concat(selectedRowKeys)});
    }

    /**
     * 更新table checkbox
     * @param selectCount 选中总计
     * @param selectIndexArray 有更新的checkbox下标和状态
     * @param isEmpty checkbox是否为全不选状态
     */
    updateSelectCount = (selectCount, selectIndexArray, checkedIdArray, isEmpty = false) => {
        const { data, listKey } = this.state;
        let list = [...data];
        if (isEmpty) {
            list.forEach((v, k) => {
                list[k]['checkbox_status'] = false;
            })
        } else {
            selectIndexArray.forEach((v, k) => {
                list[v.selectIndex]['checkbox_status'] = v.checked;
            })
        }
        this.setState(() => ({
            selectCount,
            selectIndex: selectIndexArray[0],
            data: list,
            listKey: selectCount > 0 ? -1 : listKey,
            checkedIds: [].concat(checkedIdArray)
        }))
    }

    handleDraftSarDateChange = (key, moment_key, date) => {
        const { data, listKey, dateFormat } = this.state;
        let curData = [...data];
        curData[listKey][key] = date.format(dateFormat);
        curData[listKey][moment_key] = date;
        this.setState({
            data: curData
        });
        this.editEaSarDraft();
    }
    handleDraftRsuDateChange = (key, moment_key, date) => {
        const { data, listKey, dateFormat } = this.state;
        let curData = [...data];
        curData[listKey][key] = date.format(dateFormat);
        curData[listKey][moment_key] = date;
        this.setState({
            data: curData
        });
        this.editEaSarDraft();
    }
    CheckboxChange(isChecked) {
        const { data, listKey } = this.state;
        let list = [...data];
        if (isChecked == 1) {
            list[listKey].relationship_list = [
                { effective_date: '', relationship: "" },
            ]
            list[listKey].job_title_list = [
                { effective_date: '', job_title: "" },
            ]
            list[listKey].employee_id = ''
            list[listKey].passport = ''
            list[listKey].date_of_birth = ''
            list[listKey].gender = ''
            list[listKey].annual_salary = ''
            list[listKey].country = ''
            list[listKey].address_details = ''
            list[listKey].name = ''
            list[listKey].email = ''
            // list[listKey].quantity = ''
            // list[listKey].exercise_price = ''
            list[listKey].stakeholder_type = ''
        } else {
            list[listKey].name = ''
            list[listKey].email = ''
            // list[listKey].quantity = ''
            // list[listKey].exercise_price = ''
            list[listKey].relationship_list = [
                { effective_date: Utils.getCurDate(), relationship: "Investor" },
            ]
            list[listKey].job_title_list = [
                { effective_date: Utils.getCurDate(), job_title: "" },
            ]
            list[listKey].stakeholder_type = 1
        }
        list[listKey].choose_type = isChecked
        this.setState({
            data: list
        })
        this.editEaSarDraft()
    }
    relationshipEffctiveDateChange = (listName, name, key, date) => {
        if (!date) {
            return
        }
        const { data, dateFormat, listKey } = this.state;
        let curData = this.state.data[listKey];
        curData[listName][key][name] = date && date.format(dateFormat);
        data[listKey] = curData
        this.setState({
            data
        });
    }
    setRelation = (key, e) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        curList.relationship_list[key].relationship = e.target.value;
        data[listKey] = curList
        this.setState({
            data
        })

    }
    addRelation = () => {
        const { data, listKey } = this.state;
        let one = { effective_date: Utils.getCurDate(), relationship: "Investor" }
        let curList = this.state.data[listKey];
        curList.relationship_list.push(one);
        data[listKey] = curList
        this.setState({
            data
        })
    }
    delRelation = (num) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        if (curList.relationship_list.length <= 1) {
            return;
        }
        curList.relationship_list.splice(num, 1);
        data[listKey] = curList
        this.setState({
            data
        })
    }


    JobRecordData = (key, e) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        curList.job_title_list[key].job_title = e.target.value;
        data[listKey] = curList
        this.setState({
            data
        })
    }
    handleDraftOptionDateChange = (key, moment_key, date) => {
        const { data, listKey, dateFormat } = this.state;
        let curData = [...data];
        curData[listKey][key] = date ? date.format(dateFormat) : '';
        curData[listKey][moment_key] = date || '';
        this.setState({
            data: curData
        });
        this.editEaSarDraft();
    }
    delJobTitle = (num) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        if (curList.job_title_list.length <= 1) {
            return;
        }
        curList.job_title_list.splice(num, 1);
        data[listKey] = curList
        this.setState({
            data
        })
        this.editEaSarDraft()
    }
    addJobTitle = () => {
        let one = { effective_date: Utils.getCurDate(), job_title: "" }
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        curList.job_title_list.push(one);
        data[listKey] = curList
        this.setState({
            data
        })
    }
    countryRecordData = (val) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        curList['country'] = val
        data[listKey] = curList
        this.setState({
            data
        })
    }
    bindManageTemplates = () => {
        this.props.history.push({ pathname: '/home/securities/templates', state: { activeKey: '1' } })
    }
    bindBatchUploadModel = () => {
        this.modalRef = Modal.open({
            component: props => <ModalContent close={props.close} title={__('Bulk upload')}>
                <EnterSarsInfomation close={props.close} callback={this.getEaSarDraftSetList} />
            </ModalContent>,
            className: 'sprout-modal email-modal',
            width: 1300
        })
    }
    bindSecurities = () => {
        this.props.history.push({ pathname: '/home/securities/stock-option-awards', state: { activeKey: '4' } })
    }

    bindStakeholders = () =>{
        this.props.history.push('/home/stakeholders/stakeholders-list')
    }

    closeDraftLeft = () => {
        this.setState({ listKey: -1 });
    }

    render() {
        if (this.state.addingStatus === 1) {
            return (
                <div className="loading_position">
                    <Loading />
                </div>
            )
        }
        let listKey = this.state.listKey;
        let selectCount = 0;
        for (let j = 0; j < this.state.data.length; j++) {
            if (this.state.data[j].checkbox_status === true) {
                selectCount++;
            }
        }
        let actionButtonClass = 'btn action-disabledBtn action-btn dropdown-toggle';
        let sendButtonClass = 'btn boxbtn-to send-newdisabledBtn';

        if (selectCount >= 1) {
            actionButtonClass = 'btn action-availableBtn action-btn dropdown-toggle';
            sendButtonClass = 'btn boxbtn-to send-newavailableBtn';
        }
        let isShowDocList = 0;
        let hasAdditionalDoc = 0;
        let isCustomDoc = 0;
        if (listKey != "-1") {
            for (let k = 0; k < this.state.data[listKey].additional_documents.length; k++) {
                if (this.state.data[listKey].additional_documents[k].real_name != "") {
                    hasAdditionalDoc = 1
                }
            }
        }
        if (listKey >= 0) {
            if (this.state.data[listKey].documentset_id == 0) {
                isCustomDoc = 1;
            }
        }
        if (listKey >= 0) {
            if (this.state.data[listKey].form_of_grant_file.real_name != ""
                || this.state.data[listKey].form_of_settlement_agreement_file.real_name != ""
                || this.state.data[listKey].incentive_plan_file.real_name != ""
                || hasAdditionalDoc == 1
                || isCustomDoc == 1
            ) {
                isShowDocList = 1;
            }
        }
        let curData = this.state.data;
        let quantityInDraft = 0;
        for (let j = 0; j < curData.length; j++) {
            quantityInDraft += parseInt(Utils.resetNumberWithCommas(curData[j].quantity));
        }
        const { currency = '', data, dateFormat, genderList, stakeholderType, formatShareClassList, eaPlanList } = this.state;
        const certificateKey = eaPlanList.findIndex(v=>v?.id == data[listKey]?.security_plan_id);
        return (
            <div className="draftsContent">
                <RenameDraftSet type={this.state.type} ref="renameDraftSet" getDraftSecurityList={this.getDraftSecurityList} getDraftSetList={this.getEaSarDraftSetList} draft_name={this.state.draft_name} draft_id={this.state.draft_id} changeDraftId={this.changeDraftId} />
                <DeleteDraftSet ref="deleteDraftSet" getDraftSecurityList={this.getDraftSecurityList} getDraftSetList={this.getEaSarDraftSetList} draft_name={this.state.draft_name} draft_id={this.state.draft_id} changeDraftId={this.changeDraftId} />
                <DeleteDraftSecurity ref="deleteDraftSecurity" curData={this.state.data} getDraftSecurityList={this.getDraftSecurityList} listKey={this.state.listKey} checkedIds={this.state.checkedIds}/>
                <CreateDraftSet type={this.state.type} setList={this.state.eaSarDraftSetList} getDraftSecurityList={this.getDraftSecurityList} getDraftSetList={this.getEaSarDraftSetList} draft_name={this.state.draft_name} draft_id={this.state.draft_id} changeDraftId={this.changeDraftId} />
                <SendInfoForSignature curPlanId={this.state.curPlan_id} removeSuccessData={this.removeSuccessData} getErrorMessage={this.getErrorMessage} errorMessage={this.state.sendResult} targetError={this.targetError} curData={this.state.data} ref="sendForSignature" alertError={this.alertError} pageData={this.state.pageData} checkedIds={this.state.checkedIds}/>

                <div className="prefer-main">
                    <div className={`prefer-left ${listKey < 0 && 'prefer-left-max'}`}>
                        <Button className="billing-change-plan-back" onClick={this.bindSecurities}>
                            <LeftOutlined />
                            {__('Back')}
                        </Button>
                        <div className="holders-nav minWidth400 clearfix">
                            <div className="action-box onset align-center">
                                <Permission type="securities_draftEA_delete" hide><button type="button" className={actionButtonClass + " marginRig10 width80"} data-toggle="modal" onClick={this.getDeleteCheckedId} data-target={"#deleteDraftSecurityModal"}>{__('Delete')}</button></Permission>
                                {/*1.0之后dropdown添加print功能*/}
                                {/*<div className="btn-group marginRig10">*/}
                                {/*<button type="button" className={actionButtonClass} data-toggle="dropdown">Actions<span className="triangle triangle-whitedown action-triangle"></span></button>*/}
                                {/*{selectCount>=1 &&*/}
                                {/*<ul className="dropdown-menu mange-downmenu action-downmenu">*/}
                                {/*    <li><a  data-toggle="modal" data-target="#">Print drafts</a></li>*/}
                                {/*    <li><a   data-target={"#deleteDraftSecurityModal"} onClick={this.getDeleteCheckedId}>Delete</a></li>*/}
                                {/*</ul>*/}
                                {/*}*/}
                                {/*</div>*/}
                                <div>
                                    {/* <div className="btn-group drafts-btn-group draft_option_btnGroup">
                                        <a className="dropdown-toggle text-blue drafts-btn blue-triangle-down" data-toggle="dropdown" >{this.state.curPlanName}&nbsp;&nbsp;</a>
                                        <ul className="dropdown-menu draft_option_downmenu ">
                                            {this.state.eaPlanList && this.state.eaPlanList.map((value, key) => {
                                                let totalQuantity = parseInt(value.size);
                                                let availableQuantity = parseInt(value.available);
                                                let issuedQuantity = totalQuantity - availableQuantity;
                                                let issuedPercent = issuedQuantity * 100 / totalQuantity
                                                return (
                                                    <li key={key} className="option_li padding1010 link-cursor" onClick={this.changeEaDraftSelect.bind(this, value.plan_name, value.id)}>
                                                        <p className="mb-0">{value.plan_name}</p>
                                                        <div className="progress option-progress option_downmenu_progress">
                                                            <div className="progress-bar option-progress-bar"
                                                                role="progressbar" aria-valuenow={Math.ceil(issuedPercent)}
                                                                aria-valuemin="0" aria-valuemax="100" style={{ width: Math.ceil(issuedPercent) + "%" }}>
                                                                <span className="sr-only">% Complete</span>
                                                            </div>
                                                        </div>
                                                        <p><span className="draft_square marginRig10 mb-0 bg-seablue"></span>{quantityInDraft == 0 ? 0 : Utils.checkNumInput(quantityInDraft)} option in draft</p>
                                                        <p><span className="draft_square marginRig10 mb-0 bg-darkblue"></span>{issuedQuantity == 0 ? 0 : Utils.checkNumInput(issuedQuantity)} option issued</p>
                                                        <p><span className="draft_square marginRig10 mb-0 bg-gray"></span>{Utils.checkNumInput(totalQuantity)} options available</p>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div> */}
                                    <Permission type="securities_draftEA_set" hide>
                                        <div className="btn-group drafts-btn-group">
                                            <a className="dropdown-toggle text-blue blue-triangle-down drafts-btn" data-toggle="dropdown">{this.state.draft_name}&nbsp;&nbsp;</a>
                                            <ul className="dropdown-menu drafts-downmenu">
                                                {this.state.eaSarDraftSetList.map((value, key) => {
                                                    return (
                                                        <li key={key}>
                                                            <a className="resetDrafts" value={value.id} onClick={this.selectDraftSet.bind(this, key)}>{value.name}
                                                                <DeleteOutlined className='drafts-trash-can' onClick={this.transferDraftSetData.bind(this, key, "deleteDraftSet")} data-toggle="modal" data-target="#deleteDraftSetModal"/>
                                                                <span className="drafts-icon" onClick={this.transferDraftSetData.bind(this, key, "renameDraftSet")} data-toggle="modal" data-target="#enterNewNameForDraftsModal">{value.count}</span>
                                                            </a>
                                                        </li>
                                                    )
                                                })}
                                                <li><a data-toggle="modal" data-target="#createNewDraftSetModal">{__('Create a new draft set')}</a></li>
                                            </ul>
                                        </div>
                                    </Permission>
                                </div>
                            </div>
                            <div className="form-group search-group banner-rightBox offset">
                                <Permission type="securities_draftEA_add" hide>
                                    <div className="btn-group marginLf10">
                                        <a className="btn boxbtn-to send-availableBtn" onClick={this.bindBatchUploadModel}>{__('Bulk upload')}</a>
                                    </div>
                                    <div className="btn-group marginLf10">
                                        <a className="btn boxbtn-to bg-darkblue" onClick={this.createDraftSar}>+ {__('Add SAR')}</a>
                                    </div>
                                </Permission>
                                <Permission type="securities_draftEA_send" hide>
                                    <div className="btn-group marginLf10">
                                        {selectCount >= 1 && <a onClick={this.selectCount} data-toggle="modal" data-target="#sendInfoForSignatureModal" className={sendButtonClass}>{__('Send')}</a>}
                                        {selectCount == 0 && <a className={sendButtonClass}>{__('Send')}</a>}
                                    </div>
                                </Permission>
                            </div>
                        </div>
                        {this.state.loadingStatus === 1 &&
                            <div className="loading_position">
                                <Loading />
                            </div>
                        }
                        {this.state.loadingStatus === 0 &&
                            <div>
                                {this.state.data.length == 0 &&
                                    <div className="empty_option ">
                                        <div className="empty_option_box marginTop80">
                                            <span className="empty_option_icon bg_draft_file"></span>
                                            <h5 className="empty_option_text">{__('This set has no Sar.')}</h5>
                                            <Permission type="securities_draftEA_add" hide><button className="btn action-availableBtn action-btn white-text font_weight500" onClick={this.createDraftSar} >{__('Add SAR')}</button></Permission>
                                        </div>
                                    </div>
                                }
                                {this.state.data.length >= 1 &&
                                    <div className="draftList">
                                        <div className="scroll-div">

                                            <DraftSarTable
                                                tableList={data}
                                                transferKey={this.transferKey}
                                                listKey={listKey}
                                                eaPlanList={eaPlanList}
                                                securityListsChange={this.securityListsChange}
                                                removeSuccessData={this.removeSuccessData}
                                                updateSelectCount={this.updateSelectCount}
                                            />

                                        </div>
                                        {/* <Permission type="securities_draftEA_add" hide>
                                            <button type="button" onClick={this.createDraftSar} className="btn resetFont white-text bg-darkblue marginTop10">+ Add SAR</button>
                                        </Permission> */}
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <div className={`prefer-right ${listKey < 0 && 'prefer-right-max'}`}>
                        <div className="error_tip_bar alert correct_error_tip bg-danger_text hidden" role="alert">
                            <div className="error_icon_box bg-lightred">
                                <span className="error_tip_icon  glyphicon glyphicon-exclamation-sign"></span>
                            </div>
                            <p className="error_tip_text bg-danger_text font_weight500">{__('Correct the error in grant')} {this.state.listKey + 1}.</p>
                        </div>
                        {this.state.listKey === -1 &&
                            <div className="empty_option">
                                <div className="empty_option_box marginTop186">
                                    <span className="edit-icon"></span>
                                    <h5 className="edit_text font_weight500">{__('Select a draft to edit')}</h5>
                                </div>
                            </div>
                        }
                        {this.state.listKey > -1 &&
                            <div>
                                {this.state.data[listKey].is_show_error === 1 &&
                                    <div className="error_details error_tip_bar bg-lightred alert alert-dismissible" role="alert">
                                        <div className="error_icon_box bg-redbtn">
                                            <ExclamationCircleOutlined style={{color:'#d42f2e'}} />
                                        </div>
                                        <div className="error_tip_text bg-lightred font_weight500">
                                            <h5 className="error_tip_text">{__('There were some problems with this SAR.')}</h5>
                                            <div className="error_list">
                                                {this.state.sendResult && this.state.sendResult[this.state.data[listKey].id].error_reason.map((val, k) => {
                                                    return (
                                                        <p className="marginTop10 mb-0" key={k}>{val.title}: {val.message}</p>
                                                    )

                                                })}
                                            </div>
                                        </div>
                                        <div className="error_close bg-redbtn">
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                        </div>
                                    </div>
                                }
                                {this.state.saveStatus == 0 &&
                                    <div className="banner-download draft_save_banner bg-apple-green no-marginBottom ">
                                        <div className="circle-border draft-circle-border"></div>
                                        <p className="download-message">{__('Saving')}</p>
                                    </div>
                                }
                                {this.state.saveStatus == 1 &&
                                    <div className="banner-download draft_save_banner bg-apple-green no-marginBottom ">
                                        <span className="colorWhite glyphicon glyphicon-ok"></span>
                                        <p className="download-message">{__('Draft saved')}</p>
                                    </div>
                                }
                                <ul>
                                    <li className="draft-titLi clearfix">
                                        <div className="draft-titleLi-group">
                                            <p className="onset">{__('SAR')} ({eaPlanList?.[certificateKey]?.prefix}-{data[listKey]?.custom_label_id})</p>
                                            <CloseOutlined onClick={this.closeDraftLeft} />
                                        </div>
                                        {/* <div className="offset">
                                            <button type="button" className="draft-certificate-btn marginRig6" ><span className="white-text glyphicon glyphicon-search"></span></button>
                                            <button type="button" onClick={this.getDeleteDraftId} data-toggle="modal" data-target={"#deleteDraftSecurityModal"} className="draft-certificate-btn" ><span className="white-text glyphicon glyphicon-trash"></span></button>
                                        </div> */}
                                    </li>
                                    <li>
                                        <a className="prefer-togglebtn" href="#option_sar_li" data-toggle="collapse">{__('SAR')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                        <div className="collapse" id="option_sar_li">
                                            <form className="row">
                                                <div className="form-group col-xs-6 equity-plan">
                                                    <label htmlFor="draft_option_quantity">{__('Equity plan')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                    <select id="draft_option_type" name="security_plan_id"
                                                        value={this.state.data[listKey].security_plan_id}
                                                        className="form-control select-container"
                                                        onChange={this.recordData}>
                                                        {this.state.eaPlanList.map((value, key) => {
                                                            return (
                                                                <option key={key} value={value.id}>{value.plan_name} ({value.prefix})</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="form-group col-xs-6">
                                                    <label htmlFor="draft_option_custom_id"></label>
                                                    <div className="equity-plan-size">{printf(__('%s options available'), formatFractionalNumber(this.state.equityPlanSize) )}</div>
                                                </div>


                                            </form>
                                            <div className="row">
                                                <div className="form-group col-xs-6">
                                                    <label htmlFor="draft_sar_custom_id">{__('Custom ID')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                    <input id="draft_sar_custom_id" onBlur={this.editEaSarDraft} name="custom_label_id" value={this.state.data[listKey].custom_label_id} onChange={this.recordData} type="text" className="form-control" />
                                                </div>
                                                <div className="form-group col-xs-6">
                                                    <label htmlFor="draft_award_category">{__('Award Category')}</label>
                                                    <select id="draft_award_category" name="award_category"
                                                        value={this.state.data[listKey].award_category}
                                                        onBlur={this.editEaSarDraft}
                                                        className="form-control select-container"
                                                        onChange={this.recordData}>
                                                        <option value="">No Category</option>
                                                        {this.state.award_category.map((value, key) => {
                                                            return (
                                                                <option key={key} value={value.name}>{value.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <a className="prefer-togglebtn" href="#option_value_li" data-toggle="collapse">{__('Transaction value')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                        <div className="collapse" id="option_value_li">
                                            <div className="row">
                                                <div className="form-group col-xs-6">
                                                    <label htmlFor="draft_sar_quantity">{__('Issued shares')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                    <input id="draft_sar_quantity" name="quantity" onChange={this.recordNumber} onFocus={this.setDataFormat} onBlur={this.resetDataFormat} value={I(this.state.data[listKey].quantity, '')} className="form-control" />
                                                </div>
                                                <div className="form-group col-xs-6">
                                                    <label htmlFor="draft_sar_exercise_price">{__('Exercise price')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                    <div>
                                                        <div className="input-group"><span className="input-group-addon" id="exercise-price-addon">{security.companyInfo.currency}</span>
                                                            <input type="text" id="draft_sar_exercise_price" aria-describedby="exercise-price-addon"
                                                                onBlur={this.resetDataFormat} onFocus={this.setDataFormat} name="exercise_price"
                                                                value={this.state.data[listKey].exercise_price}
                                                                onChange={this.recordNumber}
                                                                className="form-control" />                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <a className="prefer-togglebtn" href="#sar_shareholder_li" data-toggle="collapse">{__('SAR holder')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                        <div className="collapse" id="sar_shareholder_li">
                                            <form className="form-draftflextwo">
                                                <div className="row draft-checked">
                                                    {sprintf(__('Add new stakeholders on the %s page. This page only selects existing stakeholders.'), <a onClick={this.bindStakeholders}>{__('All stakeholders')}</a>)}
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-xs-6 no-paddingleft">
                                                        <label htmlFor="draft_name">{__('Name')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                        <SelectStakeholder value={this.state.data[listKey].name} add={false} onBlur={this.editEaSarDraft} onChange={this.handleSelectChange} />
                                                    </div>
                                                    <div className="form-group col-xs-6 no-paddingright">
                                                        <label htmlFor="draft_sar_email">{__('Email')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                        <SelectStakeholder value={this.state.data[listKey].email} add={false} isEmail={true} onBlur={this.editEaSarDraft} onChange={this.handleSelectChange} />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </li>
                                    <li>
                                        <a className="prefer-togglebtn" href="#sar_board_content_li" data-toggle="collapse">{__('Board consent')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                        <div className="collapse" id="sar_board_content_li">
                                            <form className="row">
                                                <div className="row">
                                                    <div className="form-group col-xs-6 ">
                                                        <label htmlFor="sar_board_approval_date">{__('Board approval date')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                        <DateSelector
                                                            onChange={this.handleDraftSarDateChange.bind(this, 'board_approval_date', 'board_approval_date_moment')}
                                                            value={I(data[listKey]['board_approval_date_moment'], I(data[listKey]['board_approval_date'], '') ? moment(data[listKey]['board_approval_date'], dateFormat) : '')}
                                                            inputReadOnly={false}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-xs-6 ">
                                                        <label htmlFor="sar_grant_date">{__('Grant date')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                        <DateSelector
                                                            onChange={this.handleDraftSarDateChange.bind(this, 'grant_date', 'grant_date_moment')}
                                                            value={I(data[listKey]['grant_date_moment'], I(data[listKey]['grant_date'], '') ? moment(data[listKey]['grant_date'], dateFormat) : '')}
                                                            inputReadOnly={false}
                                                        />
                                                    </div>
                                                    <div className="form-group col-xs-6 ">
                                                        <label htmlFor="sar_expiration_date">{__('Expiration date')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                        <DateSelector
                                                            onChange={this.handleDraftSarDateChange.bind(this, 'expiration_date', 'expiration_date_moment')}
                                                            value={I(data[listKey]['expiration_date_moment'], I(data[listKey]['expiration_date'], '') ? moment(data[listKey]['expiration_date'], dateFormat) : '')}
                                                            inputReadOnly={false}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </li>
                                    <li>
                                        <a className="prefer-togglebtn" href="#sar_vesting_schedule_li" data-toggle="collapse">{__('Vesting schedule')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                        <div className="collapse" id="sar_vesting_schedule_li">
                                            <form className="row">
                                                <div className="form-group col-xs-6 ">
                                                    <label htmlFor="draft_sar_schedule">{__('Schedule')}</label>
                                                    <select id="draft_sar_schedule" className="form-control select-container" onBlur={this.editEaSarDraft} name="vesting_schedule_id" value={this.state.data[listKey].vesting_schedule_id} onChange={this.recordData}>
                                                        <option value="">No Vesting</option>
                                                        {this.state.scheduleList.map((value, key) => {
                                                            return (
                                                                <option key={key} value={value.id}>{value.schedule_name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                {this.state.data[listKey].vesting_schedule_id != "" && this.state.data[listKey].vesting_schedule_id != 0 &&
                                                    <div className="form-group col-xs-6 ">
                                                        <label htmlFor="rsu_grant_date">{__('Vesting start date')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                        <DateSelector
                                                            onChange={this.handleDraftSarDateChange.bind(this, 'vesting_start_date', 'vesting_start_date_moment')}
                                                            value={I(data[listKey]['vesting_start_date_moment'], I(data[listKey]['vesting_start_date'], '') ? moment(data[listKey]['vesting_start_date'], dateFormat) : '')}
                                                            inputReadOnly={false}
                                                        />
                                                    </div>
                                                }
                                                {this.state.data[listKey].vesting_schedule_id != "" && this.state.data[listKey].vesting_schedule_id != 0 &&
                                                    <div className="col-xs-12">
                                                        <div className="form-group">
                                                            <a role="button" data-toggle="collapse" onBlur={this.editEaSarDraft} onClick={this.ifIncludeAcceleration}
                                                                href="#sar_acceleration" className="acceleration vesting-collapse-a acceleration_terms_a text-blue">{this.state.isIncludeAcceleration == 0 ? __("Include acceleration terms") : __("Remove acceleration terms")}
                                                            </a>
                                                            <div className="collapse" id="sar_acceleration">
                                                                <textarea name="acceleration_terms" onBlur={this.editEaSarDraft}
                                                                    placeholder="Type or paste acceleration text here."
                                                                    onChange={this.recordData}
                                                                    value={this.state.data[listKey].acceleration_terms}
                                                                    type="text"
                                                                    className="form-control acceleration_terms_textarea" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </form>
                                        </div>
                                    </li>
                                    <li>
                                        <a className="prefer-togglebtn" href="#sar_document_note_li" data-toggle="collapse">{__('Document and notes')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                        <div className="collapse" id="sar_document_note_li">
                                            <form>
                                                <div className="row">
                                                    <div className="form-group col-xs-6">
                                                        <label htmlFor="draft_sar_doc">{__('Document sets')}</label>
                                                        <select id="draft_sar_doc" name="documentset_id" onBlur={this.editEaSarDraft} value={this.state.data[listKey].documentset_id} onChange={this.recordData} className="form-control select-container">
                                                            <option value="-3">Custom documents</option>
                                                            {this.state.documentSet.map((value, key) => {
                                                                return (
                                                                    <option key={key} value={value.id}>{value.set_name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-xs-6 document_set">
                                                        <div className="manage_document" onClick={this.bindManageTemplates}>{__('Manage document set')}</div>
                                                    </div>
                                                    {/* {isShowDocList == 1 && */}
                                                    <div>
                                                        <div className="form-group row marginBott20">
                                                            {/*<label className="col-xs-12 text-muted">Form of Grant Agreement<i className="text-muted"><small> - Required</small></i></label>*/}
                                                            <label className="col-xs-12 text-muted">{__('Grant letter')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                            <div className="col-xs-4 no-paddingright">
                                                                <div className={"select-btn convertiblenote-select-btn select-blue"}>
                                                                    {__('Choose a file')}
                                                                    <label htmlFor="sar_doc_select1" className="sr-only">File input</label>
                                                                    <input name="form_of_grant_file" onChange={this.fileSelect} ref="form_of_grant_file" type="file" id="sar_doc_select1" />
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-8 filename-box no-paddingright">
                                                                {this.state.data[listKey].form_of_grant_file.name == "" &&
                                                                    <p className="text-muted paddingLeft6">{__('No file selected')}</p>
                                                                }
                                                                {this.state.data[listKey].form_of_grant_file.name != "" &&
                                                                    <p className="text-muted paddingLeft6 paddingRight18 ">{this.state.data[listKey].form_of_grant_file.name}
                                                                        {/*<span onClick={this.fileDelete.bind(this, 'form_of_grant_file', -1)} className="draft_trash form_of_grant_file glyphicon glyphicon-trash"></span>*/}
                                                                        {/*<DeleteOutlined className="draft_trash" onClick={this.fileDelete.bind(this, 'form_of_grant_file', -1)} />*/}
                                                                    </p>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="form-group row marginBott20">
                                                            <label className="col-xs-12 text-muted">{__('Form of settlement agreement')}</label>
                                                            <div className="col-xs-4 no-paddingright">
                                                                <div className={"select-btn convertiblenote-select-btn select-blue"}>
                                                                    {__('Choose a file')}
                                                                    <label htmlFor="sar_doc_select2" className="sr-only">File input</label>
                                                                    <input name="form_of_settlement_agreement_file"
                                                                        onChange={this.fileSelect}
                                                                        ref="form_of_settlement_agreement_file"
                                                                        type="file" id="sar_doc_select2" />

                                                                </div>
                                                            </div>
                                                            <div className="col-xs-8 filename-box no-paddingright">
                                                                {this.state.data[listKey].form_of_settlement_agreement_file.name == "" &&
                                                                    <p className="text-muted paddingLeft6">{__('No file selected')}</p>
                                                                }
                                                                {this.state.data[listKey].form_of_settlement_agreement_file.name != "" &&
                                                                    <p className="text-muted paddingLeft6 paddingRight18 ">{this.state.data[listKey].form_of_settlement_agreement_file.name}
                                                                        {/*<span onClick={this.fileDelete.bind(this, 'form_of_settlement_agreement_file', -1)} className="draft_trash form_of_settlement_agreement_file glyphicon glyphicon-trash"></span>*/}
                                                                        {/*<DeleteOutlined className="draft_trash" onClick={this.fileDelete.bind(this, 'form_of_settlement_agreement_file', -1)} />*/}
                                                                    </p>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="form-group row marginBott20">
                                                            <label className="col-xs-12 text-muted">{__('Equity incentive plan')}</label>
                                                            <div className="col-xs-4 no-paddingright">
                                                                <div className={"select-btn convertiblenote-select-btn select-blue"}>
                                                                    {__('Choose a file')}
                                                                    <label htmlFor="sar_doc_select3" className="sr-only">File input</label>
                                                                    <input name="incentive_plan_file"
                                                                        onChange={this.fileSelect}
                                                                        ref="incentive_plan_file" type="file"
                                                                        id="sar_doc_select3" />
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-8 filename-box no-paddingright">
                                                                {this.state.data[listKey].incentive_plan_file.name == "" &&
                                                                    <p className="text-muted paddingLeft6">{__('No file selected')}</p>
                                                                }
                                                                {this.state.data[listKey].incentive_plan_file.name != "" &&
                                                                    <p className="text-muted paddingLeft6 paddingRight18 ">{this.state.data[listKey].incentive_plan_file.name}
                                                                        {/*<span onClick={this.fileDelete.bind(this, 'incentive_plan_file', -1)} className="draft_trash incentive_plan_file glyphicon glyphicon-trash"></span>*/}
                                                                        {/*<DeleteOutlined className="draft_trash" onClick={this.fileDelete.bind(this, 'incentive_plan_file', -1)} />*/}
                                                                    </p>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="form-group row marginBott20">
                                                            <label className="text-muted col-xs-12">{__('Additional document')}</label>
                                                            <div className="col-xs-4 no-paddingright">
                                                                <div className={"select-btn convertiblenote-select-btn select-blue"}>
                                                                    {__('Choose a file')}
                                                                    <label htmlFor="sar_doc_select4" className="sr-only">File input</label>
                                                                    <input name="additional_documents"
                                                                        onChange={this.fileSelect}
                                                                        ref="additional_documents" type="file"
                                                                        id="sar_doc_select4" />
                                                                </div>
                                                            </div>
                                                            {this.state.data[listKey].additional_documents.map((val, k) => {
                                                                let isPush = "";
                                                                let isRow = "";
                                                                if (k != 0) {
                                                                    isPush = "col-xs-push-4";
                                                                    isRow = "row"
                                                                }
                                                                return (
                                                                    <div className={isRow} key={k}>
                                                                        <div className={"col-xs-8 filename-box " + isPush}>
                                                                            {(this.state.data[listKey].additional_documents[k].name == "" || this.state.data[listKey].additional_documents[k].name == null) &&
                                                                                <p className="text-muted paddingLeft6">{__('No file selected')}</p>
                                                                            }
                                                                            {this.state.data[listKey].additional_documents[k].name != "" && this.state.data[listKey].additional_documents[k].name != null &&
                                                                                <p className="text-muted paddingLeft6 paddingRight18 ">{this.state.data[listKey].additional_documents[k].name}
                                                                                    {/*<span onClick={this.fileDelete.bind(this, 'additional_documents', k)} className="draft_trash additional_documents glyphicon glyphicon-trash"></span>*/}
                                                                                    {/*<DeleteOutlined className="draft_trash" onClick={this.fileDelete.bind(this, 'additional_documents', k)} />*/}
                                                                                </p>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>

                                                    </div>
                                                    {/* } */}
                                                    <div className="form-group col-xs-12 ">
                                                        <label htmlFor="draft_sar_notes">{__('Notes')}</label>
                                                        <textarea id="draft_sar_notes" type="text" onBlur={this.editEaSarDraft} name="notes" className="form-control minHeight100" value={this.state.data[listKey].notes} onChange={this.recordData}></textarea>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        }
                    </div>
                </div >
            </div >

        )
    }

}

export default DraftSars;

