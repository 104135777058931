import React, { Component, Fragment } from "react";
import {Dropdown, Menu, Table} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {__LANG} from "../../Lang/en-us";

class WaterfallModelsTableModels extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading : true,
            waterfallModelsColumns : [
                {
                    title : __('Name'),
                    align : 'left',
                    dataIndex : 'name',
                    key : 'keyId',
                    width : '65%',
                },
                {
                    title : __('Exit Value'),
                    align : 'left',
                    dataIndex : 'exitValue',
                    key : 'keyId',
                    width : '25%',

                },
                {
                    title : '',
                    align : 'right',
                    dataIndex : 'setting',
                    key : 'keyId',
                    width : '10%',
                    render : (text,data, index) => this.dropdownInfo(text, data, index)
                }
            ]
        }
    }

    dropdownInfo = (a,data, index) => {
        return (
            <Dropdown overlay={this.menu( data.id, data, index)} placement="bottomRight" trigger="click">
                <div className="tableMenu"><DownOutlined style={{ color: '#FFF' }}/></div>
            </Dropdown>
        );
    }

    /**
     * Waterfall models 菜单
     * @param id
     * @param data
     * @returns {JSX.Element}
     */
    menu = (id, data, index) => {
        return (
            <Menu>
                <Menu.Item onClick={this.showDetailModels.bind(this, id, data)}>
                    <span>{__('View')}</span>
                </Menu.Item>
                {/*<Menu.Item >*/}
                {/*    <span>Share</span>*/}
                {/*</Menu.Item>*/}
                {/*<Menu.Item >*/}
                {/*    <span>Export to PDF</span>*/}
                {/*</Menu.Item>*/}
                {/*<Menu.Item >*/}
                {/*    <span>Export to Excel</span>*/}
                {/*</Menu.Item>*/}
                <Menu.Item >
                    <span onClick={this.delModels.bind(this, id, index)}>{__('Delete')}</span>
                </Menu.Item>
            </Menu>
        )
    }

    showDetailModels = (id, data) => {
        const { detailIdChange } = this.props;
        detailIdChange(id, data);
    }

    delModels = (id, index) => {
        console.log(index);
        const { delModels } = this.props;
        delModels(id, index);
    }

    render() {

        const { waterfallModelsColumns } = this.state;
        const { waterfallLoading, waterfallModelsList } = this.props;
        return (
            <Fragment>
                <h2 className="title mt-8">{__LANG.waterfallModelsTitle}</h2>
                <Table
                    loading={waterfallLoading}
                    className="waterfall-models-table mt-15"
                    size="middle"
                    pagination={false}
                    showHeader={true}
                    columns={ waterfallModelsColumns }
                    dataSource={ waterfallModelsList }
                    rowKey="keyId"
                />
            </Fragment>

        );
    }
}

export default WaterfallModelsTableModels;