import React, { Component } from "react";
import {Button, Spin, Table} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";
import { formatDate } from 'Utils/format/formatDate'
import { formatDate2Api } from 'Utils/format/formatDate';
import { I } from 'Utils';

class ReviewStakeholderModels extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            desc: __('Review the following changes to stakeholder details to ensure they are correct.'),
            columns: [
                {
                    title : __('name'),
                    align : 'left',
                    dataIndex : 'name',
                    key : 'value',
                    width : '33%',
                },
                {
                    title : __('value'),
                    align : 'left',
                    dataIndex : 'value',
                    key : 'value',
                    render: (text, record) => <span>{record.type == 2 ? formatDate(formatDate2Api(text)) : text}</span>
                }
            ],
            tableList : []
        }
    }

    /**
     * 初始化数据
     */
    componentDidMount() {
        const { attr, stakeholderData } = this.props;
        //初始化table数据
        let tableList = [];
        attr.forEach( v => {
            tableList.push({
                name: v.name,
                value: I(stakeholderData[v.stakeholderDataKey], ''),
                type: v.type,
            });
        })
        this.setState({ loading: false, tableList })
    }

    saveThirdData = () => {
        const { close, getCompleteStakeholderMergeModels } = this.props;
        //打开第三确认页
        getCompleteStakeholderMergeModels();
        close();
    }
    getRelationshipModels = () => {
        const { close, getRelationshipModels } = this.props;
        getRelationshipModels();
        close();
    }

    /**
     * 关闭model
     */
    closeModels = () => {
        const { close, initStakeHolderData } = this.props;
        initStakeHolderData();
        close();
    }

    render() {
        const { loading, desc, columns, tableList } = this.state;
        return (
            <Spin tip="Loading..." spinning={loading}>
                <div className="merge-stakeholder-header">
                    <ArrowLeftOutlined className="go-back" onClick={this.getRelationshipModels} />
                    <h5 className="title">{__('Review Stakeholder Details')}</h5>
                    <div className="close " onClick={this.closeModels} >×</div>
                </div>
                <div className="merge-stakeholder-content new-merge-stakeholder-content">
                    <dl>
                        <dt>{desc}</dt>
                        <dd>
                            <Table
                                className="table review-table"
                                pagination={false}
                                columns={columns}
                                dataSource={tableList}
                                rowKey="keyId"
                                showHeader={false}
                            >
                            </Table>
                        </dd>
                    </dl>
                </div>
                <div className="information-w100">
                    <Button className="information-button" type="primary" onClick={this.saveThirdData} >{__('Next: complete stakeholder merge')}</Button>
                </div>
            </Spin>
        );
    }
}
export default ReviewStakeholderModels;