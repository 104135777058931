import React, { Component } from 'react';
import { Button, message, Input } from 'antd';
import classnames from 'classnames';
import { post } from 'srcPath/http';
import { cloneDeep } from 'lodash';
import './addNewWallet.scss'

export default class AddNewWallet extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }

    handleInputChange = (index, e) => {
        const newWalletList = cloneDeep(this.props.data)
        newWalletList[index].address = e.target.value
        this.props.onChange(newWalletList)
    }

    addWallet = () => {
        const newWalletList = cloneDeep(this.props.data)
        newWalletList.push({
            address: '',
        })
        this.props.onChange(newWalletList)
    }

    removeWallet = (index) => {
        const newWalletList = cloneDeep(this.props.data)
        newWalletList.splice(index, 1)
        this.props.onChange(newWalletList)
    }

	render () {
        const { errorStatus, addType, data: walletList = [{ address: '' }] } = this.props
		return <div>
            <p className="basic-header header-border">
                {__('Wallet')}
                <br />
                {/*<i><small className="text-muted">- {__('Required')}</small></i>*/}
                <i><small className="text-muted">- {addType ? __('Optional') : __('Required')}</small></i>
            </p>
            <table className="table table-striped relationship-table marginBott20 mt20">
                <tbody>
                <tr className="first-row">
                    <td className="effective-date">{__('Address')}</td>
                    {/* <td>Token tag</td> */}
                    <td>&nbsp;</td>
                </tr>
                {walletList.map((wallet, index) => {
                    if (index == 0) {
                        return (
                            <tr key={index}>
                            <td width="450px" className={classnames({ 'has-error': errorStatus == 1 && !wallet.address })}>
                                <Input value={wallet.address} onChange={this.handleInputChange.bind(this, index)} />
                            </td>
                            {/* <td className="advisor-td relationship-td" id={"add_relationship"+key}>
                                <select className="select-box relationship_selectbox"  name={"relationship"+key} value={value.relationship} onChange={this.setRelation.bind(this,key)}>
                                    <option value="">--</option>
                                    {relationshipList.map((val,k)=>{
                                        return(
                                            <option key={k} value={val}>{val}</option>
                                        )
                                    })}
                                </select>
                            </td> */}
                            <td className="box-lasttd">
                                <div className="btn-box relationship-btnBox">
                                    {walletList.length == 1 && <button type="button" onClick={this.addWallet} className="btn-mp plus"></button>}
                                    {walletList.length > 1 && <button type="button" onClick={this.removeWallet.bind(this, index)} className="btn-mp minus"></button>}
                                </div>
                            </td>
                        </tr>)
                    } else {
                        return (
                            <tr key={index}>
                            <td width="450px" className={classnames({ 'has-error': errorStatus == 1 && !wallet.address })}>
                                <Input value={wallet.address} onChange={this.handleInputChange.bind(this, index)} />
                            </td>
                            <td className="box-lasttd">
                                <div className="btn-box relationship-btnBox">
                                    {index == walletList.length - 1 && <button type="button" onClick={this.addWallet} className="btn-mp plus"></button>}
                                    <button type="button" onClick={this.removeWallet.bind(this, index)} className="btn-mp minus"></button>
                                </div>
                            </td>
                        </tr>)
                    }
                })}
                </tbody>
            </table>
            <p className="alarm_text  hidden" id="valid_relationship"><span className="alarm_text glyphicon glyphicon-exclamation-sign"></span>{__('A valid relationship is required.')}</p>
        </div>
	}
}