import React, { Component } from "react";
import { post } from 'srcPath/http';
import { Button } from "antd";
import { I } from "Utils";
import signupBdPlanImg from "assets/images/signup-plan-2.png";
import "../style.scss"

class SignupBdPlan extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    /**
     * 返回 pick plan
     * @returns {Promise<void>}
     */
    getSignup = async () => {
        this.setState({ loading: true })
        const { data: resData } = await post('backSignUp')
        const { code, data } = resData || {}
        if (code === 0) {
            //跳转到 signup 流程里
            const { getLink } = this.props;
            getLink();
        }
        this.setState({ loading: false })
    }

    render() {
        const { loading } = this.state;
        return (
            <div className="plan-message-root">
                <img src={signupBdPlanImg} />
                <h2>Thank you for choosing Folium!</h2>
                {/*<Button loading={loading} type="primary" onClick={this.getSignup}>Complete account activation</Button>*/}
                <p>If you are still waiting for our sales team’s manual activation,
                    <br/>please contact us at <a href="mailto:support@getsprout.co">support@getsprout.co</a>.
                </p>
            </div>
        );
    }
}
export default SignupBdPlan;