import React, { Component } from "react";
import {message, Table} from "antd";
import { columnsConfig } from './tableColumnsConfig'
import security from 'stores/security';
import { post } from 'srcPath/http';
import './style.scss';
import Loading from 'components/Loading';

class StakeholdersTableModels extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading : false,
            contentHeight : 0,
            selectedRowKeys : [],
            allPrefix: [],
        }
    }

    componentDidMount(){
        const { onRef } = this.props;
        //预加载，用户体验会好些
        this.initFilterColumn();
        this.getAllPrefix();
        onRef(this, 'tableChild');
    }

    initFilterColumn = () => {
        const flag = security.companyInfo && security.companyInfo.flag;
        const isEsopOrCaptableAdmin = flag === '5' || flag === '6';
        const { allPrefix } = this.state;
        const tableColumn = columnsConfig(flag, isEsopOrCaptableAdmin, this.props, allPrefix);
        const { updateState } = this.props;
        updateState({tableColumn});
    }

    /**
     * 清空checkbox所有选中内容
     */
    clearAll = () => {
        this.setState(()=>({
            selectedRowKeys : []
        }))
    }

    /**
     * checkbox事件
     * @param selectedRowKeys
     * @returns {{onChange: StakeholdersTableModels.onSelectChange, selectedRowKeys}}
     */
    rowSelection = (selectedRowKeys) => ({
        selectedRowKeys,
        columnWidth: 32,
        onChange: this.onSelectChange,
    })

    /**
     * 全选change事件
     * @param selectedRowKeys
     */
    onSelectChange = selectedRowKeys => {
        const { holderListStatusChange } = this.props;
        holderListStatusChange(selectedRowKeys);
        this.setState({ selectedRowKeys });
    };

    /**
     * 获取 prefix 结果集
     * @returns {Promise<void>}
     */
    getAllPrefix = async () => {
        // this.setState({loading: true});
        try {
            Loading.global(true)
            const { data: resData } = await post('getAllPrefix', {})
            const { code, data } = resData || {}
            if (code === 0) {
                //去重
                const newData = Array.from(new Set(data));
                this.setState({allPrefix: newData}, ()=>{
                    this.initFilterColumn();
                });
            } else {
                message.error(data)
            }
            Loading.global(false)
        } catch(err) {
            message.error(err && err.message)
            Loading.global(flase)
        }
        // this.setState({loading: false});
    }

    /**
     *
     * @param pagination
     * @param filters
     * @param sorter
     */
    handleTableChange = (pagination, filters, sorter) => {
        let sort = {}
        const { order, column } = sorter

        if (column?.sortName) {
            sort = {
                column: column.sortName,
                type: order
            }

            this.props.sortTable?.(sort)
        }else{
            sort = {}
            this.props.sortTable?.(sort)
        }
    }

    render() {
        const { loading, selectedRowKeys } = this.state;
        const { curSelectRelation, holderLists = [], tableColumn, paginationConfig } = this.props;
        return <Table
            pagination={paginationConfig? {...paginationConfig, position: ['bottomCenter']} : false}
            loading={loading}
            scroll={{ x: true, y: innerHeight ? innerHeight - 280 : false }}
            rowKey="id"
            className="all-stakeholders-table"
            columns={tableColumn || []}
            rowSelection={this.rowSelection( selectedRowKeys)}
            dataSource={curSelectRelation === 'all' ? holderLists : []}
            onChange={this.handleTableChange}
        />
        ;
    }
}

export default StakeholdersTableModels;