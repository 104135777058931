import React, { Fragment } from 'react';
import SimpleTable from '../components/SimpleTable';
import HeaderTips from '../components/HeaderTips';
import { formatCurrencyNumber, formatPercent } from 'Utils'
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import { formatDate } from 'Utils/format/formatDate';
import { certificateStatus } from 'Utils/constant';

export default function Certificate(props) {
	const { valuationCap, conversionDiscount, originalPrincipal, status, name, email, issuedBy, issueDate, boardApprovedDate, convertibleType, certificateList, formatText, certificateHistory } = props.data || {}
	const convertTypeMap = {
		safe: 'SAFE/KISS',
		convertible_security: 'Convertible security',
		convertible_debt: 'Convertible Debt',
	};
	const config = [
		{
			label: __('Valuation cap'),
			value: formatFractionalNumber(valuationCap)
		},
		{
			label: __('Conversion discount'),
			value: formatPercent(conversionDiscount/100, 'multiply')
		},
		{
			label: __('Convertible type'),
			value: convertTypeMap[convertibleType]
		}
	]

	const issuerConfig = [
		{
			label: __('Issued by'),
			value: issuedBy
		},
		{
			label: __('Issue date'),
			value: formatDate(issueDate)
		},
		{
			label: __('Board approval date'),
			value: formatDate(boardApprovedDate)
		}
	]


	const convertibleConfig = [
		{
			label: __('Name'),
			value: name
		},
		{
			label: __('Email'),
			value: email
		},
		{
			label: __('Status'),
			value: certificateStatus[status]
		},
	]

	if (certificateHistory?.length > 0) {
		certificateHistory.map((item) => {
			const statusText = item.type.replace('ed', '')	
			let label = ''
			if (item.source == 'from' && statusText == 'Convert') {
				label = `${statusText} date from ${item.certificatesName}`
			} else if (statusText == 'Convert') {
				label = `${statusText} date`
			} else {
				label = `${statusText} date for ${formatFractionalNumber(item.share)} shares ${item.source || 'from'} ${item.certificatesName}`
			}
			convertibleConfig.push({
				label,
				value: formatDate(item.date)
			})
		})
	}

	const valueConfig = [
		{
			label: __('Original principal'),
			value: formatCurrencyNumber(originalPrincipal)
		}
	]


	return <Fragment>
		{certificateList && certificateList.length && formatText &&
			<HeaderTips data={{ certificateList, formatText }} />
		}
		<div className="certificate-detail-section">
			<SimpleTable title={__('Convertible Holder')} config={convertibleConfig} />
		</div>
		<div className="certificate-detail-section">
			<SimpleTable title={__('Issuer')} config={issuerConfig} />
		</div>
		<div className="certificate-detail-section">
			<SimpleTable title={__('Value')} config={valueConfig} />
		</div>
		<div className="certificate-detail-section">
			<SimpleTable title={__('Terms')} config={config} />
		</div>
	</Fragment>
}