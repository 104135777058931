import React, { Component, useState, useEffect }from 'react';
import { message, Table, Spin, Descriptions, Col, Row, Breadcrumb, Dropdown, Menu } from 'antd';
import { post } from 'srcPath/http';
import security from 'stores/security';
import Echarts from 'components/Echarts';
import { formatDate } from 'Utils/format/formatDate';
import { formatNumber, formatCurrencyNumber } from 'Utils'
import classnames from 'classnames';
import { cloneDeep } from 'lodash';
import { formatColumns } from './config'
import operationIcon from './images/operation-icon-active.png'
import Utils from 'Utils';
import axios from 'axios';
import Permission, { noPermission } from 'components/Permission';
import Empty from './components/Empty';

import './style.scss'


export default function Documents (props) {
    const [ loading, setLoading ] = useState(false)
    const [ portfolioValue, setPortfolioValue] = useState([])
    const [ navMenu, setNavMenu] = useState([{ name: 'Documents', idTree: [] }])
    const [ navDisplayMenu, setDisplayNavMenu] = useState([{ name: 'Documents', idTree: [] }])
    const [ selectedKey, setSelectedKey] = useState([])
	const [ selectedRowKeys, setSelectedRowKey] = useState([])
    // 当前文件夹目录、文件目录 
	const [ currentDirList, setCurrentDirList] = useState([])
	const [ currentFileList, setCurrentFileList] = useState([])

    const [ dirList, setDirList] = useState([])
	const [ currentFolder, setCurrentFolder] = useState({})
	const [ fileList, setFileList] = useState([])

    useEffect(() => {
        getDocuments()
   }, [])

   useEffect(() => {
    const hash = location.hash
    if (!hash) {
        handleChangeFolder({})
    }
}, [location.hash])

    const getDocuments = async () => {
        const { data = {} } = props
        const { id } = data || {}
        setLoading(true)
            try {
                const { data: resData } = await post('getStakeholderDocument', { stakeholder_id: security?.companyInfo?.stakeholder_id ?? 0 }) // security?.companyInfo?.stakeholder_id ?? 0  2690
                const { code, data } = resData || {}
                if (code === 0) {
                    setDirList(data)
                    setCurrentDirList(data)
                    setCurrentFileList(data)
                }
            } catch(err) {
                message.error(err & err.message)
            }
            setLoading(false)
    }

    const renderFileListAndFolder = (fileInfo) => {
		const { fileList = [] } = fileInfo || {}
		const currentFileList = []
		const currentDirList = []
		fileList?.forEach((item) => {
			if (item.file_type === 'file' || !item.file_type) {
				currentFileList.push({...item, key: item?.id})
			} else {
				currentDirList.push(item)
			}
		})
		return { currentFileList, currentDirList }
	}

    // 打开文件夹
	const handleOpenFolder = (fileInfo) => {
		const newNavMenu = cloneDeep(navMenu) || [{ name: 'Documents', idTree: [] }]
		// 上一个节点
		const lastNodeTree = (newNavMenu[newNavMenu?.length - 1] || [{ name: 'Documents', idTree: [] }])?.idTree || []
		const idTree = cloneDeep(lastNodeTree)
		idTree?.push(fileInfo?.id)

		newNavMenu.push({ name: fileInfo?.name, idTree: idTree })
		if (newNavMenu.length > 5) {
			const cloneNewNavMenu = cloneDeep(newNavMenu)
			const spliceList = cloneNewNavMenu.splice(cloneNewNavMenu.length - 3)
			const navMenuList = [cloneNewNavMenu[0]].concat(spliceList)
			cloneNewNavMenu.shift()
			navMenuList.splice(1, 0, { name: '...', menu: cloneNewNavMenu, type: 'more' })
			setDisplayNavMenu(navMenuList)
		} else {
			setDisplayNavMenu(newNavMenu)
		}
		setNavMenu(newNavMenu)
		// 当前文件夹
		setCurrentFolder(fileInfo)
		setSelectedKey([])
		setSelectedRowKey([])
		const { currentFileList, currentDirList } = renderFileListAndFolder(fileInfo)
		setCurrentFileList(currentFileList)
		setCurrentDirList(currentDirList)
		location.hash = newNavMenu?.[newNavMenu?.length - 1]?.name
	}

    const handleClickRow = async (record, e) => {
        if (selectedRowKeys?.length > 0) {
            return
        }
        const action = e.target.getAttribute("data-action");
        if (action && action === 'open') {
            handleOpenFolder(record)
        } else if (action && action === 'view') {
            const url = Utils.getRealUrlForFiles(record?.name, 'dataroom')
            window.open(url)
        } else if (action && action === 'download') {
            handleDownloadFile(record)
        }
    }

    const downloadSingleFile = (id) => {
		const currentFile = currentFileList?.find(i => i.id === id)
		const companyId = Utils.getLocalCompanyInfo().company_id
		axios.post('https://api.getsprout.co', {
                    action:"singleDownloadFile",
                    company_id:companyId,
                    data:{ file_id: id }
                },{
                    'responseType': 'arraybuffer',
                }).then((response)=> {
                    const blob = response.data;
                    let link = document.createElement("a");
                    link.href = URL.createObjectURL( new Blob([blob]) );
                    link.download = currentFile?.client_name;
                    document.body.appendChild(link);
                    link.click();
                    URL.revokeObjectURL(link.href);
				})
	}

    const handleDownloadFile = async (record) => {
		let idList = []
		if (record?.id) {
			idList.push(record.id)
		} 
        if (selectedRowKeys?.length > 0) {
			idList = selectedRowKeys
		}

		const isSingle = idList?.length === 1
		if (isSingle) {
			downloadSingleFile(idList.join(''))
		} else {
			const params = {
                file_id: idList,
            }
			const companyId = Utils.getLocalCompanyInfo().company_id
			axios.post('https://api.getsprout.co', {
				action: "downloadFile",
				company_id:companyId,
				data:params,
			},{
				'responseType': 'blob',
			}).then((response)=> {
				const blob = response.data;
				let link = document.createElement("a");
				link.href = URL.createObjectURL( new Blob([blob]) );
				link.download = 'DataRoom.zip';
				document.body.appendChild(link);
				link.click();
				URL.revokeObjectURL(link.href);
			})
		}
	}

    const renderCrumbMenu = (nav) => {
		const { menu: idTree } = nav
		if (idTree?.length > 0) {
			const itemList = []
			idTree.forEach((tree, index) => {
				itemList.push(
					<Menu.Item key={index}>
						<a onClick={() => handleChangeFolder(tree)}>{tree.name}</a>
					</Menu.Item>
				)
			})
			const menu = (
				<Menu>{itemList}</Menu>
			  );
			  return menu
		}
	}

    // 面包屑导航 目录跳转
	const handleChangeFolder = (nav) => {
		let newNavMenu = cloneDeep(navMenu) || []
		const { idTree = [] } = nav || {}
		// 设置面包屑
		const index = newNavMenu.findIndex(i => i.idTree?.length === idTree?.length)
		if (index > -1) {
			newNavMenu.splice(index+1)
		}

		if (newNavMenu.length > 5) {
			const cloneNewNavMenu = cloneDeep(newNavMenu)
			const spliceList = cloneNewNavMenu.splice(cloneNewNavMenu.length - 3)
			const navMenuList = [cloneNewNavMenu[0]].concat(spliceList)
			cloneNewNavMenu.shift()
			navMenuList.splice(1, 0, { name: '...', menu: cloneNewNavMenu, type: 'more' })
			setDisplayNavMenu(navMenuList)
		} else {
			setDisplayNavMenu(newNavMenu)
		}
		setNavMenu(newNavMenu)
		setSelectedRowKey([])
		setSelectedKey([])
		// 设置当前目录
		if (idTree?.length === 0) { // 根目录
			setCurrentFolder({})
			setCurrentFileList(dirList)
			setCurrentDirList(dirList)
			location.hash = ''
			history.pushState(null, null, window.location.href.split('#')[0]);
		} else { //其他目录
			let currentFolder = {}
			idTree.forEach((id, index) => {
				if (index === 0) {
					if (id === 'share') {
						currentFolder = shareClassList
					} else {
						currentFolder = dirList.find(i => i.id === id)
					}
				} else {
					currentFolder = currentFolder?.fileList?.find(i => i?.id === id)
				}
			})
			setCurrentFolder(currentFolder)
			const { currentFileList, currentDirList } = renderFileListAndFolder(currentFolder)
			setCurrentFileList(currentFileList)
			setCurrentDirList(currentDirList)
			location.hash = newNavMenu?.[newNavMenu?.length - 1]?.name
		}
	}

    const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKey(selectedRowKeys)
	}

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		getCheckboxProps: (record) => ({
			disabled: record.file_type === 'dir',
		  }),
	}

    const menuClick = (type, dirInfo) => {
		setSelectedRowKey([])
		setSelectedKey([])
		if (type === 'download') {
			handleDownloadFile()
		} else {
            const currentFile = currentFileList.find(i => selectedRowKeys.includes(i.id) )
			const url = Utils.getRealUrlForFiles(currentFile?.name, 'dataroom')
            window.open(url)
		}
	  }

    const renderMenu = () => {
		if (selectedRowKeys?.length > 1) {
			return (
				<Menu>
					{!noPermission('company_dataRoom_download') && selectedKey?.length === 0 && (
						<Menu.Item key="download" disabled={selectedKey.length}>
							<a onClick={() => menuClick('download', {})}>{__('Download')}</a>
						</Menu.Item>
					)}
				</Menu>
			)
		}
        if (selectedRowKeys?.length === 1) {
			return (
				<Menu>
					<Menu.Item key="move">
                        <a onClick={()=> menuClick('view', {})}>{__('View')}</a>
                    </Menu.Item>
					<Menu.Item key="download" disabled={selectedKey.length}>
                        <a onClick={() => menuClick('download', {})}>{__('Download')}</a>
                    </Menu.Item>
				</Menu>
			)
		}
	}

    return (
        <div>
            <Spin spinning={loading}>
                <div className="documents-container-root">
                    <div className="">
                        <div className="">
                            {/* <div className="dash-board-item-title">Documents</div> */}
                            {/* 面包屑导航 */}
                            <div className="data-room-bread-crumb-inner inner-left">
                                <Breadcrumb separator=">">
                                    {navDisplayMenu?.map((nav, index) => (
                                        <Breadcrumb.Item overlay={nav.type ? renderCrumbMenu(nav) : null} dropdownProps={{ placement: 'bottomCenter' }}>
                                            {nav.type ? <a disabled={index + 1 === navDisplayMenu?.length} onClick={() => handleChangeFolder(nav)}>{nav.name}</a> : <a disabled={index + 1 === navDisplayMenu?.length} onClick={() => handleChangeFolder(nav)}>{nav.name}</a>}
                                        </Breadcrumb.Item>
                                    ))}
                                </Breadcrumb>
                            </div>
                            <div className="data-room-bread-crumb-inner inner-right">
                                {selectedKey.length + selectedRowKeys?.length > 0 && (
                                    <Dropdown
                                        placement="bottomRight"
                                        overlayClassName="data-room-bread-crumb-overlay"
                                        overlay={() => renderMenu('')}>
                                        <img className="data-room-bread-crumb-operation" style={{ width: '40px' }} src={operationIcon} />
                                    </Dropdown>
                                )}
                            </div>
                            {currentFileList?.length === 0 && (
                                <Empty hideSubTitle />
                            )}
                            {currentFileList?.length > 0 && (
                                <div className="access-add-table-content">
                                    <Table
                                        pagination={false}
                                        columns={formatColumns(selectedRowKeys)}
                                        dataSource={currentFileList}
                                        loading={loading}
                                        scroll={{ x: true }}
                                        // locale={{emptyText: 'No Data'}}
                                        onRow={record => {
                                            return {
                                            onClick: (e) => handleClickRow(record, e),
                                            };
                                        }}
                                        rowSelection={rowSelection}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                  </div>
            </Spin>
        </div>
    )
}