/**
 * 新版data-room交互
 * @author wuping
 */
import React, { Component, useState, useEffect } from 'react';
import 'assets/css/dataroom.css';
import axios from 'axios';
import { post } from 'srcPath/http';
import { cloneDeep } from 'lodash';
import { FileSearchOutlined, FullscreenOutlined, DeleteOutlined, DownloadOutlined, FormOutlined } from '@ant-design/icons';
import iconFileOpen from './images/icon-file-folder-open.png'
import iconFileClose from './images/icon-file-folder-close.png'
import lockIconFileClose from './images/icon-file-folder-lock-close.png'
import lockIconFileOpen from './images/icon-file-folder-lock-open.png'
import operationIcon from './images/operation-icon-active.png'
import emptyFolderIcon from './images/empty-folder-icon.png'
import fileIcon from './images/file-icon.png'
import excelIcon from './images/excel.png'
import currencyIcon from './images/currency.png'
import folderIcon from './images/folder.png'
import shareFolderIcon from './images/share_folder.png'
import pdfIcon from './images/pdf.png'
import picIcon from './images/pic.png'
import videoIcon from './images/video.png'
import wordIcon from './images/word.png'
import zipRarIcon from './images/zip_rar.png'
import tableOpen from './images/table_open.png'
import tableRename from './images/table_rename.png'
import tableMove from './images/table_move.png'
import tableDownload from './images/table_download.png'
import tableDelete from './images/table_delete.png'
import tableShare from './images/table_share.png'
import searchIcon from './images/search.png'
import lookIcon from './images/look.png'
import EllipsisIcon from './images/Ellipsis.png'
import viewIcon from './images/view.png'
import PlusOut from './images/PlusOut.png'
import UploadOut from './images/UploadOut.png'
import ShareOut from './images/ShareOut.png'
import noAccess from './images/noAccess.png'
import noNewfolder from './images/noNewfolder.png'
import noShare from './images/noShare.png'
import noUpload from './images/noUpload.png'
import { formatNumber } from 'Utils'
import { UserOutlined, SearchOutlined } from '@ant-design/icons';
// import moveIcon from './images/column-icon-move.png'
// import deleteIcon from './images/column-icon-delete.png'
// import renameIcon from './images/column-icon-rename.png'
// import viewIcon from './images/column-icon-view.png'
// import downloadIcon from './images/column-icon-download.png'

import classnames from 'classnames';
import Modal, { ModalContent } from 'components/Modal';
import './index.scss'

import AccessLogs from './AccessLogs'
import UploadFileModal from './modal/UploadFiles'
import MoveFile from './modal/MoveFile'
import RenameModal from './modal/RenameModal'
import DeleteModal from './modal/DeleteModal'
import AccessCom from './AccessCom'
import AddFolder from './AddChildFolderModal'


import Permission, { noPermission } from 'components/Permission';
import security from 'stores/security';
import { message, Tabs, Upload, Tooltip, Breadcrumb, Button, Space, Spin, Col, Row, Checkbox, Table, Dropdown, Menu, Input } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Empty from './components/Empty';
import {
	PlusOutlined,
	UploadOutlined,
	EllipsisOutlined,
	ShareAltOutlined
} from '@ant-design/icons';
import { formatDate } from 'Utils/format/formatDate'
import Loading from 'components/Loading';
import Utils from 'Utils';
import { renderValue } from '../../Token/CryptoDetail/config';
import { sort } from 'mathjs';


const { TabPane } = Tabs;
const { Dragger } = Upload;

let modalRef = null

export default function DataRoomNew(props) {
	const [loading, setLoading] = useState(false)
	const [isShareMenuOpen, setIsShareMenuOpen] = useState(false)
	const [isSecurityOpen, setIsSecurityOpen] = useState(false)

	const [isCommonMenuOpen, setIsCommonMenuOpen] = useState(false)
	const [isShareSelect, setIsShareSelect] = useState(false)
	const [isCommonSelect, setIsCommonSelect] = useState(false)

	const [dirList, setDirList] = useState([])
	const [currentFolder, setCurrentFolder] = useState({})
	const [fileList, setFileList] = useState([])

	// 当前文件夹目录、文件目录 
	const [currentDirList, setCurrentDirList] = useState([])
	const [currentFileList, setCurrentFileList] = useState([])
	const [currentSortDirList, setSortCurrentDirList] = useState([])
	const [currentSortFileList, setSortCurrentFileList] = useState([])

	const [selectedKey, setSelectedKey] = useState([])
	const [selectedRowKeys, setSelectedRowKey] = useState([])
	const [selectedRowList, setSelectedRowList] = useState([])

	const [shareClassList, setShareClassList] = useState([])
	// 面包屑导航
	const [navMenu, setNavMenu] = useState([{ name: __('Data Room'), idTree: [] }])
	const [navDisplayMenu, setDisplayNavMenu] = useState([{ name: __('Data Room'), idTree: [] }])

	// search
	const [keyword, setKeyword] = useState('')
	// search
	const [tableList, setTableList] = useState([])
	const [sorter, setSorter] = useState('')
	const { flag } = Utils.getLocalCompanyInfo()
	const isCompanyUser = ['1', '2', '3'].includes(flag)
	useEffect(() => {
		getFileList()
	}, [])


	useEffect(() => {
		const hash = location.hash
		if (!hash) {
			handleChangeFolder({})
		}
	}, [location.hash])

	const getFileList = async (lastDirList, lastFileList) => {
		setLoading(true)

		const { data: resData } = await post('getTopDirList')
		const { code, data } = resData || {}
		if (code === 0) {
			const { dataRoomFileList = [] } = data || {}
			const { currentFileList: fileList, currentDirList: dirListArr } = renderFileListAndFolder({ fileList: dataRoomFileList })
			const shareClassList = []

			const dirList = dirListArr.filter(item => {
				if (['Securities', 'Share Class Documents', 'Vesting', 'Warrants(SAFTs)', 'Token'].includes(item.name)) {
					shareClassList.push(item)
					return false
				}
				return true
			})

			setShareClassList(shareClassList)
			setDirList(dirList)
			setFileList(fileList)
			setSelectedKey([])
			setSelectedRowKey([])
			const idTree = navMenu?.length > 0 ? navMenu?.[navMenu?.length - 1]?.idTree || [] : []
			let currentFolderList = dataRoomFileList || []
			idTree && idTree.map((id, index) => {
				let mapList = index === 0 ? currentFolderList : currentFolderList?.fileList
				let newCurrentFolderList = mapList?.find((dir) => {
					return dir.id === id
				})
				currentFolderList = newCurrentFolderList
			})
			const param = currentFolderList?.fileList ? currentFolderList : { fileList: currentFolderList }
			const { currentFileList: newFileList, currentDirList: newDirList } = renderFileListAndFolder(param)
			if (idTree.length < 1) {
				setCurrentFolder({})
				setCurrentFileList(fileList)
				setCurrentDirList(dirList)
				setIsShareMenuOpen(false)
				setIsCommonMenuOpen(false)
				setTableList([...shareClassList, ...dirList, ...fileList])
			} else {
				setCurrentFileList(newFileList)
				setCurrentDirList(newDirList)
				setTableList([...newDirList, ...newFileList])
			}

			// if (lastDirList || lastFileList) {
			// 	if (idTree.length < 1) {
			// 		setTableList([...shareClassList, ...dirList, ...fileList])
			// 		setCurrentFolder({})
			// 	} else {
			// 		setTableList([...newDirList, ...newFileList])
			// 	}
			// } else {
			// 	setCurrentFolder({})
			// 	setCurrentFileList(newFileList)
			// 	setCurrentDirList(newDirList)
			// 	setTableList([...shareClassList, ...dirList, ...fileList])
			// }

		} else {
			message.error(data?.message)
		}
		setLoading(false)
		// } catch(err) {
		// 	setLoading(false)
		//     message.error(err & err.message)
		// }
	}

	const onChange = (item, e) => {
		if (item?.name === 'Securities' || selectedRowKeys?.length > 0) {
			return
		}
		if (e?.target?.tagName === 'A') {
			return
		}
		if (!item) {
			setIsShareSelect(!isShareSelect)
			setIsCommonSelect(false)
			return
		} else {
			setIsCommonSelect(!isCommonSelect)
			setIsShareSelect(false)
		}
		const id = item.id
		const newSelectedKey = cloneDeep(selectedKey)
		if (!newSelectedKey.includes(id)) {
			newSelectedKey.push(id)
			setCurrentFolder(item)
		} else {
			const index = newSelectedKey.findIndex((i) => i === id)
			if (index > -1) {
				newSelectedKey.splice(index, 1)
			}
		}
		// 没有选择的一项 则清空
		if (newSelectedKey?.length === 0) {
			setCurrentFolder({})
		}
		setSelectedKey(newSelectedKey)

	}

	const handleReload = (type, file) => {
		// accessLogReport(type, file, file?.file_type)
		closeModal()
		// 这三个操作之后 需要记住当前目录
		if (['Move', 'Rename', 'Delete', 'Upload', 'Add'].includes(type)) {
			getFileList(currentDirList, currentFileList)
		} else {
			getFileList()
		}
	}

	const handleDelete = () => {
		const idList = selectedKey?.length ? selectedKey : [currentFolder?.id]
		if (idList?.length > 0) {
			const id = idList?.[0]
			const currentDirLists = currentDirList.filter(i => !idList.includes(i.id))
			setCurrentDirList(currentDirLists)
		}
		handleReload('Delete', { ...currentFolder })
	}

	const closeModal = () => {
		modalRef && modalRef.close()
		setIsCommonSelect(false)
		setIsShareSelect(false)
	}

	const openModal = (type, record, openFolder) => {
		const topListObj = { name: __('Data Room'), parent_id: 0, file_type: 'dir', fileList: [], id: 0 }
		const newList = [...shareClassList, ...dirList]
		topListObj.fileList = newList
		const totalList = [topListObj]
		const selectFile = selectedKey.concat(selectedRowKeys)
		let component
		let title
		switch (type) {
			case 'move':
				title = `${__('Move to')}...`
				component = <MoveFile onReload={handleReload} dirList={totalList} dirInfo={record ?? openFolder} selectFile={record ? [record.id] : (selectedKey?.length || selectedRowKeys?.length ? selectFile : [openFolder?.id])} />
				break;
			case 'rename':
				title = __('Rename')
				component = <RenameModal onReload={() => handleReload('Rename', record ?? openFolder)} dirInfo={record ?? openFolder} dirList={dirList} />
				break;
			case 'log':
				title = __('Access logs')
				component = <AccessLogs data={openFolder} />
				break;
			case 'access':
				title = __('Set access')
				component = <AccessCom data={openFolder} onReload={closeModal} props={props} />
				break;
			case 'delete':
				title = __('Delete documents')
				component = <DeleteModal close={closeModal} onDelete={handleDelete} selectFile={record ? [record.id] : (selectedKey?.length || selectedRowKeys?.length ? selectFile : [openFolder?.id])} />
				break;
			default:
				break;
		}
		modalRef = Modal.open({
			component: props => <ModalContent {...props} title={title} close={closeModal}>
				{component}
			</ModalContent>,
			className: 'data-room-modal-wrap',
			width: ['rename', 'move'].includes(type) ? '360px' : '800px',
			maskClosable: true
		})
	}

	const openUploadModal = () => {
		modalRef = Modal.open({
			component: props => <UploadFileModal {...props} onReload={handleReload} dirInfo={currentFolder} close={closeModal} />,
			className: 'data-room-modal-wrap',
			maskClosable: true,
			width: 600
		})
	}

	// 创建文件夹
	const openAddFolderModal = () => {
		modalRef = Modal.open({
			component: <AddFolder onReload={handleReload} dirInfo={currentFolder} close={closeModal} />,
			className: 'data-room-modal-wrap',
			maskClosable: true
		})
	}

	// 打开文件夹
	const handleOpenFolder = (fileInfo, isShare, params) => {
		if (params !== 'header' && selectedRowKeys?.length > 0) {
			return
		}
		if (fileInfo.name === 'Securities' || fileInfo.name === 'Share Class Documents' || fileInfo.name === 'Vesting' || fileInfo.name === 'Warrants(SAFTs)' || fileInfo.name === 'Token') {
			setIsSecurityOpen(true)
		}else{
			setIsSecurityOpen(false)
		}
		// if (fileInfo.name === 'Share Class Documents') {
		// 	setIsShareMenuOpen(true)
		// } else {
		// 	setIsCommonMenuOpen(true)
		// }

		const newNavMenu = cloneDeep(navMenu) || [{ name: __('Data Room'), idTree: [] }]
		// 上一个节点
		const lastNodeTree = (newNavMenu[newNavMenu?.length - 1] || [{ name: __('Data Room'), idTree: [] }])?.idTree || []
		const idTree = cloneDeep(lastNodeTree)
		idTree?.push(fileInfo?.id)
		accessLogReport('Entered', { id: fileInfo.id, name: fileInfo.name })
		newNavMenu.push({ name: fileInfo.client_name || fileInfo.name, idTree: idTree })
		if (newNavMenu.length > 5) {
			const cloneNewNavMenu = cloneDeep(newNavMenu)
			const spliceList = cloneNewNavMenu.splice(cloneNewNavMenu.length - 3)
			const navMenuList = [cloneNewNavMenu[0]].concat(spliceList)
			cloneNewNavMenu.shift()
			navMenuList.splice(1, 0, { name: '...', menu: cloneNewNavMenu, type: 'more' })
			setDisplayNavMenu(navMenuList)
		} else {
			setDisplayNavMenu(newNavMenu)
		}
		setNavMenu(newNavMenu)
		// 当前文件夹
		setCurrentFolder(fileInfo)
		setSelectedKey([])
		setSelectedRowKey([])
		const { currentFileList, currentDirList } = renderFileListAndFolder(fileInfo)
		setCurrentFileList(currentFileList)
		setCurrentDirList(currentDirList)
		setTableList([...currentDirList, ...currentFileList])
		location.hash = newNavMenu?.[newNavMenu?.length - 1]?.name
	}

	const renderFileListAndFolder = (fileInfo) => {
		const { fileList = [] } = fileInfo || {}
		const currentFileList = []
		const currentDirList = []
		fileList?.forEach((item) => {
			if (item.file_type === 'file' || !item.file_type) {
				currentFileList.push({ ...item, key: item?.id })
			} else {
				currentDirList.push({ ...item, key: item?.id })
			}
		})
		return { currentFileList, currentDirList }
	}
	const handleRowClick = (type, record, params) => {
		if (params !== 'header' && selectedRowKeys?.length > 0) {
			return
		}
		if (type === 'view') {
			accessLogReport('View', record, 'file')
		} else
			if (type === 'download') {
				handleDownloadFile(record)
			} else {
				openModal(type, record)
			}
	}

	const downloadSingleFile = (id) => {
		const currentFile = currentFileList?.find(i => i.id === id)
		const companyId = Utils.getLocalCompanyInfo().company_id
		axios.post('https://api.getsprout.co', {
			action: "singleDownloadFile",
			company_id: companyId,
			data: { file_id: id }
		}, {
			'responseType': 'arraybuffer',
		}).then((response) => {
			const blob = response.data;
			let link = document.createElement("a");
			link.href = URL.createObjectURL(new Blob([blob]));
			link.download = currentFile?.client_name;
			document.body.appendChild(link);
			link.click();
			URL.revokeObjectURL(link.href);
		})
	}

	const handleDownloadFile = async (record) => {
		let idList = []
		if (record?.id) {
			idList.push(record.id)
		} else if (selectedRowKeys?.length > 0) {
			idList = selectedRowKeys
		} else {
			idList.push(currentFolder?.id)
		}

		const isSingle = idList?.length === 1
		if (isSingle) {
			downloadSingleFile(idList.join(''))
		} else {
			const params = {
				file_id: idList,
			}
			const companyId = Utils.getLocalCompanyInfo().company_id
			axios.post('https://api.getsprout.co', {
				action: "downloadFile",
				company_id: companyId,
				data: params,
			}, {
				'responseType': 'blob',
			}).then((response) => {
				const blob = response.data;
				let link = document.createElement("a");
				link.href = URL.createObjectURL(new Blob([blob]));
				link.download = 'DataRoom.zip';
				document.body.appendChild(link);
				link.click();
				URL.revokeObjectURL(link.href);
			})
		}
	}

	const accessLogReport = async (action, file, type) => {
		const { name: currentDirName } = currentFolder
		const { id, client_name, name } = file
		try {
			const params = {
				dir_id: id,
				action,
				document_name: client_name || name || __('Data Room')
			}
			if (action === 'View') {
				// const { data: resData } = await post('dataRoomLog', params)
				// const { code, data } = resData || {}
				// const url = Utils.getRealUrlForFiles(name, currentDirName === 'Securities' ? 'dataroom' : "dataroom")
				// type !== 'folder' && window.open(url)
				const { data: resData } = await post('getS3FileUrl', {file_name:name})
				const { code, data } = resData || {}
				window.open(data)
			} else {
				post('dataRoomLog', params)
			}
		} catch (err) {
			message.error(err & err.message)
		}
	}

	// 面包屑导航 目录跳转
	const handleChangeFolder = (nav) => {
		if (nav.type === 'more') {
			return
		}
		let newNavMenu = cloneDeep(navMenu) || []
		const { idTree = [], name } = nav || {}
		// 设置面包屑
		const index = newNavMenu.findIndex(i => i.idTree?.length === idTree?.length)
		if (index > -1) {
			newNavMenu.splice(index + 1)
		}

		if (newNavMenu.length > 5) {
			const cloneNewNavMenu = cloneDeep(newNavMenu)
			const spliceList = cloneNewNavMenu.splice(cloneNewNavMenu.length - 3)
			const navMenuList = [cloneNewNavMenu[0]].concat(spliceList)
			cloneNewNavMenu.shift()
			navMenuList.splice(1, 0, { name: '...', menu: cloneNewNavMenu, type: 'more' })
			setDisplayNavMenu(navMenuList)
		} else {
			setDisplayNavMenu(newNavMenu)
		}
		setNavMenu(newNavMenu)
		setSelectedRowKey([])
		setSelectedKey([])
		// 设置当前目录
		if (idTree?.length === 0) { // 根目录
			setCurrentFolder({})
			setCurrentFileList(fileList)
			setCurrentDirList(dirList)
			setIsShareMenuOpen(false)
			setIsCommonMenuOpen(false)
			setIsSecurityOpen(false)
			location.hash = ''
			history.pushState(null, null, window.location.href.split('#')[0]);
			setTableList([...shareClassList, ...dirList, ...fileList])
		} else { //其他目录
			let currentFolder = {}
			idTree.forEach((id, index) => {
				if (index === 0) {
					if (id === 'share' || name == "Securities") {
						currentFolder = shareClassList.find(item => item.id === id)
					} else {
						currentFolder = dirList.find(i => i.id === id) || shareClassList.find(item => item.id === id)
					}
				} else {
					currentFolder = currentFolder?.fileList?.find(i => i?.id === id)
				}
			})
			setCurrentFolder(currentFolder)
			const { currentFileList, currentDirList } = renderFileListAndFolder(currentFolder)
			setCurrentFileList(currentFileList)
			setCurrentDirList(currentDirList)
			setTableList([...currentDirList, ...currentFileList])
			location.hash = newNavMenu?.[newNavMenu?.length - 1]?.name
		}
	}

	const renderCrumbMenu = (nav) => {
		const { menu: idTree } = nav
		if (idTree?.length > 0) {
			const itemList = []
			idTree.forEach((tree, index) => {
				itemList.push(
					<Menu.Item key={index}>
						<a onClick={() => handleChangeFolder(tree)}>{tree.name}</a>
					</Menu.Item>
				)
			})
			const menu = (
				<Menu>{itemList}</Menu>
			);
			return menu
		}
	}
	const renderValue = (val, value) => {
		let fileType = value.file_type
		if (fileType == 'file') {
			let num = val / 1024
			if (num >= 1024) { return formatNumber(num / 1024, 2) + ' MB' }
			if (num) { return formatNumber(num, 2) + ' KB' }
			return '--'
		} else {
			let folderLength = value.fileList.filter(item => item.file_type == 'dir').length || 0
			let fileLength = value.fileList.filter(item => item.file_type == 'file').length || 0
			return folderLength + ' ' + __('folder(s)') + ', ' + fileLength + ' ' + __('doc(s)')
		}

	}
	const getFileIcon = (value) => {
		let fileType = value.file_type
		if (fileType === 'file') {
			let name = value.name.split('.')[value.name.split('.').length - 1].toLowerCase()
			if (name === 'xls' || name === 'xlsx') {
				return excelIcon
			} else if (name === 'pdf') {
				return pdfIcon
			} else if (name === 'doc' || name === 'docx') {
				return wordIcon
			} else if (name === 'png' || name === 'jpg') {
				return picIcon
			} else if (name === 'avi' || name === 'wmv' || name === 'mpg' || name === 'mov' || name === 'mp4') {
				return videoIcon
			} else if (name === 'zip' || name === 'rar') {
				return zipRarIcon
			} else {
				return currencyIcon
			}
		} else {
			if (value.name === 'Securities' || value.name === 'Share Class Documents' || value.name === 'Vesting' || value.name === 'Warrants(SAFTs)' || value.name === 'Token') {
				return shareFolderIcon
			} else {
				return folderIcon
			}
		}
	}
	const FileColumn = [
		{
			title: __('Name'),
			dataIndex: 'client_name',
			sorter: true,
			render: (value, record) => getTableName(value, record),
			width: 250
		},
		{
			title: __('Size'),
			dataIndex: 'file_size',
			render: (val, value) => renderValue(val, value),
			width: 250
		},
		{
			title: __('Last modified'),
			dataIndex: 'last_modify',
			render: (text, record) => {
				const upload_date = record.upload_date
				return `${formatDate(upload_date)} ${text || ''}`
			},
			width: 250
		},
		{
			title: '',
			dataIndex: 'action',
			align: 'right',
			render: (value, record) => getTableIcon(record)
		},
	]
	const getTableName = (value, record) => {
		return <Space className={classnames("client-name", { 'noPointer': selectedRowKeys.length > 0 })} onClick={() => record.file_type === 'dir' ? handleOpenFolder(record, false) : handleRowClick(__('view'), record)}>
			<img style={{ width: '40px' }} src={getFileIcon(record)} /><div className="name">{value || record?.name}</div>
		</Space>
	}
	const getTableIcon = record => {


		if (record.name === 'Securities' || record.name === 'Share Class Documents' || record.name === 'Vesting' || record.name === 'Warrants(SAFTs)' || record.name === 'Token') {
			return < Space size="middle" className={classnames("data-room-table-operate-cell", { 'noPointer': selectedRowKeys.length > 0 })} >
				<a style={{ color: '#525659' }} onClick={() => handleOpenFolder(record, false)} className={classnames({ 'noPointer': selectedRowKeys.length > 0 })}> <img src={tableOpen} />{__('Open')}</a>
				<Permission type="company_dataRoom_editSetting" hide={noPermission('company_dataRoom_editSetting')}><a style={{ color: '#525659' }} onClick={() => menuClick('access', record)} className={classnames({ 'noPointer': selectedRowKeys.length > 0 })}><img src={tableShare} />{__('Share')}</a> </Permission>
			</Space>
		}
		return < Space size="middle" className={classnames("data-room-table-operate-cell")} >
			{record.file_type == 'dir' && <a style={{ color: '#525659' }} onClick={() => handleOpenFolder(record, false)} className={classnames({ 'noPointer': selectedRowKeys.length > 0 })}> <img src={tableOpen} />{__('Open')}</a>}
			{record.file_type == 'file' && <Permission type="company_dataRoom_viewFile" hide={noPermission('company_dataRoom_viewFile')} > <a className={classnames({ 'noPointer': selectedRowKeys.length > 0 })} style={{ color: '#525659' }} onClick={() => handleRowClick('view', record)}><img src={viewIcon} />{__('View')}</a> </Permission>}
			{!isSecurityOpen && <Permission type="company_dataRoom_moveFile" hide={noPermission('company_dataRoom_moveFile')}><a className={classnames({ 'noPointer': selectedRowKeys.length > 0 })} style={{ color: '#525659' }} onClick={() => handleRowClick('move', record)}><img src={tableMove} />{__('Move')}</a> </Permission>}
			{!isSecurityOpen && <Permission type="company_dataRoom_rename" hide={noPermission('company_dataRoom_rename')}><a className={classnames({ 'noPointer': selectedRowKeys.length > 0 })} style={{ color: '#525659' }} onClick={() => handleRowClick('rename', record)}><img src={tableRename} />{__('Rename')}</a></Permission>}
			{record.file_type == 'file' && <Permission type="company_dataRoom_download" hide={noPermission('company_dataRoom_download')}><a className={classnames({ 'noPointer': selectedRowKeys.length > 0 })} style={{ color: '#525659' }} onClick={() => handleRowClick('download', record)}><img src={tableDownload} />{__('Download')}</a></Permission>}
			{record.file_type == 'dir' && <Permission type="company_dataRoom_editSetting" hide={noPermission('company_dataRoom_editSetting')}><a className={classnames({ 'noPointer': selectedRowKeys.length > 0 })} style={{ color: '#525659' }} onClick={() => menuClick('access', record)}><img src={tableShare} />{__('Share')}</a></Permission>}
			<Permission type="company_dataRoom_delete" hide={noPermission('company_dataRoom_delete')}><a className={classnames({ 'noPointer': selectedRowKeys.length > 0 })} style={{ color: '#525659' }} onClick={() => handleRowClick('delete', record)}><img src={tableDelete} />{__('Delete')}</a></Permission>
		</Space >
	}
	const getTableChange = (a, b, c) => {
		let sorter = c.order

		let { currentFileList: Filedata, currentDirList: Dirdata } = renderFileListAndFolder({ fileList: tableList })
		let Shardata = []

		Dirdata = Dirdata.filter(item => {
			if (['Securities', 'Share Class Documents', 'Vesting', 'Warrants(SAFTs)', 'Token'].includes(item.name)) {
				Shardata.push(item)
				return false
			}
			return true
		})

		if (sorter === 'ascend') {
			let shatList = ascSorter(Shardata, 'name')
			let dirList = ascSorter(Dirdata, 'name')
			let fileList = ascSorter(Filedata, 'client_name')
			setTableList([...shatList, ...dirList, ...fileList])
		} else if (sorter === 'descend') {
			let shatList = desSorter(Shardata, 'name')
			let dirList = desSorter(Dirdata, 'name')
			let fileList = desSorter(Filedata, 'client_name')
			setTableList([...shatList, ...dirList, ...fileList])
		}
		// else {
		// 	setTableList([...Shardata, ...Dirdata, ...Filedata])
		// }
	}

	const ascSorter = (arr, name) => {
		arr.sort((x, y) => {
			let a = x[name].toLowerCase();
			let b = y[name].toLowerCase();
			if (a > b) return 1;
			if (a < b) return -1;
			else return 0;
		})
		return arr
	}
	const desSorter = (arr, name) => {
		arr.sort((x, y) => {
			let a = x[name].toLowerCase();
			let b = y[name].toLowerCase();
			if (a > b) return -1;
			if (a < b) return 1;
			else return 0;
		})
		return arr
	}

	const menuClick = (type, dirInfo, params) => {
		if (params !== 'header' && selectedRowKeys?.length > 0) {
			return
		}

		if (type === 'download') {
			handleDownloadFile('share')
		} else {
			openModal(type, null, dirInfo)
		}
	}

	const renderMenuList = (isShare, dirInfo) => {
		let fileType = selectedRowList.map(item => item.file_type)
		let menuRules = { open: false, view: false, move: false, rename: false, share: false, download: false, delete: true };
		if (fileType.length == 1) {
			if (fileType[0] == 'dir') {
				menuRules.open = true
				menuRules.move = true
				menuRules.rename = true
				menuRules.share = true
				// console.log('1个文件夹');
			} else {
				menuRules.view = true
				menuRules.move = true
				menuRules.rename = true
				menuRules.download = true
				// console.log('1个文件');
			}
		} else {
			menuRules.move = true
			if (fileType.includes('dir') && fileType.includes('file')) {
				// 都存在
			} else if (fileType.includes('file')) {
				// 只存在文件
				menuRules.download = true
			}

			// else if (fileType.includes('file')) {
			// 	console.log('文件');
			// } else {
			// 	console.log('文件');
			// }

		}
		return (
			<Menu>
				{menuRules.open && (
					<Menu.Item key="open">
						<a onClick={() => handleOpenFolder(selectedRowList[0], false, 'header')}>{__('Open')}</a>
					</Menu.Item>
				)}
				{!noPermission('company_dataRoom_viewFile') && menuRules.view && (
					<Menu.Item key="view">
						<a onClick={handleDownloadFile}>{__('View')}</a>
					</Menu.Item>
				)}
				{!isSecurityOpen && !noPermission('company_dataRoom_moveFile') && menuRules.move && (
					<Menu.Item key="move">
						<a onClick={() => menuClick('move', selectedRowList[0], 'header')}>{__('Move to')}</a>
					</Menu.Item>
				)}
				{!isSecurityOpen && !noPermission('company_dataRoom_rename') && menuRules.rename && (
					<Menu.Item key="rename">
						<a onClick={() => menuClick('rename', selectedRowList[0], 'header')}>{__('Rename')}</a>
					</Menu.Item>
				)}
				{!noPermission('company_dataRoom_editSetting') && menuRules.share && (
					<Menu.Item key="set">
						<a onClick={() => menuClick('access', selectedRowList[0], 'header')}>{__('Share')}</a>
					</Menu.Item>
				)}
				{!noPermission('company_dataRoom_download') && menuRules.download && (
					<Menu.Item key="download" disabled={selectedKey.length}>
						<a onClick={handleDownloadFile}>{__('Download')}</a>
					</Menu.Item>
				)}
				{!noPermission('company_dataRoom_delete') && (
					<Menu.Item key="delete">
						<a onClick={() => menuClick('delete', selectedRowList[0], 'header')} style={{ color: '#f81d22' }}>{__('Delete')}</a>
					</Menu.Item>
				)}
			</Menu>
		)

	}
	const onSelectChange = (selectedRowKeys, selectedRowList) => {
		setSelectedRowKey(selectedRowKeys)
		setSelectedRowList(selectedRowList)
	}

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		getCheckboxProps: (record) => ({
			disabled: record.name === 'Securities' || record.name === 'Share Class Documents' || record.name === 'Vesting' || record.name === 'Warrants(SAFTs)' || record.name === 'Token'
		}),
	}

	if (loading) {
		return <Loading />
	}

	if (shareClassList === 0 && dirList?.length === 0 && !loading) {
		// 无文件上传权限的用户 联系admin获取权限
		if (noPermission('company_dataRoom_upload')) {
			return <Empty hideTitle hideSubTitle />
		}
		return <Empty />
	}



	const getKeyword = (e) => {
		let val = e.target.value.toLowerCase()
		setKeyword(val)
		const idTree = navMenu?.length > 0 ? navMenu?.[navMenu?.length - 1]?.idTree || [] : []
		let arr = []
		if (idTree.length < 1) {
			arr = [...shareClassList, ...currentDirList, ...currentFileList]

		} else {
			arr = [...currentDirList, ...currentFileList]
		}

		let tableList = arr.filter(item => {
			if (item.file_type === 'dir') {
				return item.name && item.name.toLowerCase().indexOf(val) !== -1
			} else {
				return item.client_name && item.client_name.toLowerCase().indexOf(val) !== -1
			}

		})
		setTableList(tableList)
	}
	return (
		<div className="data-room-box-new">
			{/* 顶部操作栏 */}
			<div className="data-room-bread-crumb">
				<div className="data-room-bread-crumb-inner inner-right header-btn">
					<Button disabled={(selectedKey?.length + selectedRowKeys?.length > 0)} type="primary" onClick={() => menuClick('log', currentFolder)}> <img className="access" src={selectedRowKeys.length > 0 ? noAccess : lookIcon} /> {__('Access logs')}</Button>
				</div>
			</div>
			{/* 搜索区域 */}
			<div className="data-search">
				<div className="data-room-bread-crumb-inner inner-left">
					<Breadcrumb separator=">">
						{navDisplayMenu?.map((nav, index) => (
							<Breadcrumb.Item overlay={nav.type ? renderCrumbMenu(nav) : null} dropdownProps={{ placement: 'bottomCenter' }}>
								{nav.type ? <a disabled={index + 1 === navDisplayMenu?.length} onClick={() => handleChangeFolder(nav)}>{nav.name}</a> : <a disabled={index + 1 === navDisplayMenu?.length} onClick={() => handleChangeFolder(nav)}>{nav.name}</a>}
							</Breadcrumb.Item>
						))}
					</Breadcrumb>
				</div>
				<div className="search-info">
					<Input className="search-input" value={keyword} onChange={(e) => getKeyword(e)} prefix={<SearchOutlined />} placeholder={__('Search name')} />
					<div className="data-room-bread-crumb-inner">
						<Space>

							{!isShareMenuOpen && (
								<Space className="header-btn">
									<Permission type="company_dataRoom_upload" hide><Button disabled={(selectedKey?.length + selectedRowKeys?.length > 0)} type="primary" onClick={openAddFolderModal}><img src={selectedRowKeys.length > 0 ? noNewfolder : PlusOut}></img> {__('New folder')}</Button></Permission>
									<Permission type="company_dataRoom_addFolder" hide><Button disabled={(selectedKey?.length + selectedRowKeys?.length > 0)} type="primary" onClick={openUploadModal}><img src={selectedRowKeys.length > 0 ? noUpload : UploadOut}></img> {__('Upload')}</Button></Permission>
									<Permission type="company_dataRoom_addFolder" hide><Button disabled={(selectedKey?.length + selectedRowKeys?.length > 0)} type="primary" onClick={() => menuClick('access', currentFolder)}><img className="shareOut" src={selectedRowKeys.length > 0 ? noShare : ShareOut}></img> {__('Share')}</Button></Permission>
								</Space>
							)}
							{selectedKey.length + selectedRowKeys?.length > 0 && (
								<Dropdown
									placement="bottomRight"
									overlayClassName="data-room-bread-crumb-overlay"
									overlay={() => renderMenuList('')}>
									{/* <img className="data-room-bread-crumb-operation" src={operationIcon} /> */}
									<Button className="data-room-bread-crumb-operation" type="primary"><img src={EllipsisIcon}></img> </Button>
								</Dropdown>
							)}
						</Space>
					</div>
				</div>
			</div>
			{/* 文件区域 */}
			<Spin spinning={loading}>
				<div className="data-room-file-content">
					{shareClassList.length === 0 && currentDirList?.length === 0 && currentFileList?.length === 0 && !loading && <Empty hideSubTitle={noPermission('company_dataRoom_upload')} hideTitle={noPermission('company_dataRoom_upload')} />}
					{/* <Row gutter={[16, 24]}>
						{currentDirList?.map((item) => (
							<Col className="gutter-row" span={6} xl={4}>
								<div className={classnames('data-room-file-content-item', { 'data-room-file-content-item-active': selectedKey?.includes(item.id) })} onDoubleClick={(e) => handleOpenFolder(item || currentFolder, false, item?.name === 'Securities')} onClick={(e) => onChange(item, e)}>
									<div className="data-room-file-content-item-operation">
										<span className="data-room-file-content-item-checkbox"><Checkbox onChange={(event) => onChange(item, event)} disabled={item?.name === 'Securities' || selectedRowKeys?.length > 0} checked={selectedKey?.includes(item.id)} /></span>
										{selectedKey?.length == 0 && (
											<Dropdown
												placement="bottomRight"
												overlayClassName="data-room-bread-crumb-overlay"
												overlay={() => renderMenu('', item)}
												disabled={selectedRowKeys?.length > 0}
											>
												<span className={classnames('data-room-file-content-item-more', { 'data-room-file-content-item-forbidden': selectedRowKeys?.length > 0 })} onClick={(e) => e?.stopPropagation()} onDoubleClick={(e) => e?.stopPropagation()} ><EllipsisOutlined size="large" /></span>
											</Dropdown>
										)}
									</div>
									<img className="data-room-file-content-item-icon" src={selectedKey.includes(item.id) ? (isShareMenuOpen ? lockIconFileOpen : iconFileOpen) : (isShareMenuOpen ? lockIconFileClose : iconFileClose)} />
									<div className="data-room-file-content-item-footer">
										<Tooltip title={item.name}>
											<p>{item.name || '--'}</p>
										</Tooltip>
										<span>{`${item?.fileCount || item?.fileList?.length || 0} document(s)`}</span>
									</div>
								</div>
							</Col>
						))}
					</Row> */}
					{/* {!isShareMenuOpen && !isCommonMenuOpen && !loading && isCompanyUser && (
						<Row gutter={[16, 24]}>
							<Col className="gutter-row" span={4}>
								<div className="data-room-file-content-item" onDoubleClick={(e) => handleOpenFolder(shareClassList || currentFolder, true)}>
									<div className="data-room-file-content-item-operation">
										<span className="data-room-file-content-item-checkbox" onClick={() => onChange('')}><Checkbox disabled={true} /></span>
										<Dropdown
											placement="bottomRight"
											overlay={() => renderMenu('share', shareClassList)}>
											<span className={classnames('data-room-file-content-item-more', { 'data-room-file-content-item-forbidden': selectedRowKeys?.length > 0 })}><EllipsisOutlined /></span>
										</Dropdown>
									</div>
									<img className="data-room-file-content-item-icon" src={isShareSelect ? lockIconFileOpen : lockIconFileClose} />
									<div className="data-room-file-content-item-footer">
										<Tooltip title='Share Class Documents'>
											<p>Share Class Documents</p>
										</Tooltip>
										<span>{`${shareClassList?.fileList?.length || 0} document(s)`}</span>
									</div>
									<Tooltip title='This is a system holder that automatically hold all the documents related to Convertible classes and Share Award classes.'>
										<span style={{ position: 'absolute', left: '40px', top: '10px' }}><QuestionCircleOutlined /></span>
									</Tooltip>
								</div>
							</Col>
						</Row>
					)} */}
					{!loading && (!!currentFileList?.length || !!currentDirList?.length || !!shareClassList?.length) && (
						<div className="data-room-file-table">
							<Table
								pagination={false}
								columns={FileColumn}
								dataSource={tableList}
								scroll={{ x: true }}
								locale={{ emptyText: 'No Data' }}
								rowSelection={rowSelection}
								onChange={(a, b, c) => getTableChange(a, b, c)}
							/>
						</div>
					)}
				</div>
			</Spin>
		</div>
	)
}
