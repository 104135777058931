import React, { Component } from 'react';
import SimpleTable from '../components/SimpleTable';
import { formatDate } from 'Utils/format/formatDate';
import { formatCurrencyNumber } from 'Utils';
import formatFractionalNumber from "../../../modules/Utils/format/formatFractionalNumber";

export default function TransferDetail(props) {

	return <div className="certificate-detail-section exercise-details-section">
		{ props.data.map((val,key)=>{

			const config = [
				{
					label: 'Transfer date',
					value: val.transfer_date && formatDate(val.transfer_date)
				},
				{
					label: 'Cash paid',
					value: formatCurrencyNumber(val.cash_paid),
				},
				{
					label: 'Debt canceled',
					value: formatCurrencyNumber(val.debt_canceled),
				},
				{
					label: 'IP transferred',
					value: formatCurrencyNumber(val.ip_transferred),
				},
				{
					label: 'Other considerations',
					value: formatCurrencyNumber(val.other_considerations),
				},
				{
					label: 'Notes',
					value: val.notes
				},
				{
					label: 'Transfer agreement',
					value: val.transfer_agreement.name && val.transfer_agreement.name,
					url: val.transfer_agreement.file_url,
				}
			];

			return 	<SimpleTable key={key} title={"Transfer details for "+val.target_security+", "+formatFractionalNumber(val.shares) +" shares"} config={config} />
		})}
	</div>
}