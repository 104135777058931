import React, { Component } from "react";
import { I } from "Utils";
import { CloseCircleFilled } from '@ant-design/icons';

class TemplateDocusignEdit extends Component {

    onLoad = (obj) => {
        const { contentDocument } = obj.target;
        I(contentDocument, '') && this.closeModel();
    }

    closeModel = () => {
        const { close, getData } = this.props;
        getData();
        close();
    }

    render() {
        const { url } = this.props;
        return (
            <div className="docusign-template-edit-root">
                <iframe className="template-docusign" frameBorder="0" src={url} onLoad={this.onLoad}/>
                <div className="docusign-template-edit-close" onClick={this.closeModel} ><CloseCircleFilled /></div>
            </div>
        )
    }
}
export default TemplateDocusignEdit;