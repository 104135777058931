import React from 'react';
import { Select } from 'antd';
import { relationshipList} from "Utils/constant";

export default function RelationshipSelector(props)  {

	return <Select showSearch placeholder="Select relationship" {...props}>
		{
			relationshipList.map((val, index) => <Select.Option key={index} value={val}>{val}</Select.Option>)
		}
	</Select>
}