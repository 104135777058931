import React, { Component, Fragment } from 'react';
import Modal, { ModalContent } from 'components/Modal';
import { Input, Select, Button, message, Table, Popconfirm } from 'antd';
import { post } from 'srcPath/http';
import classnames from 'classnames';
import { roleOption } from './config';
import { cloneDeep } from 'lodash';
import { PlusOutlined } from '@ant-design/icons';
import Empty from 'components/Empty'

import './style.scss';

const { Option } = Select

const emailParse = new RegExp('(?![\\-_.])[\\w\\-.]+@(?:(?![\\-_])[\\w\\-\u4e00-\u9fa5]{1,63}\\.)+[A-Za-z0-9]{2,}')

const attendeesStatus = {
	0: 'Not yet invited',
	1: 'Invited'
}

class MeetingModalContent extends Component {
	tableRef = null
	tmpData = cloneDeep(this.props.data.attendees || [])
	state = {
		loading: false,
		data: this.props.data.attendees || [],
		errorTip: 0
	}

	componentDidMount() {
		this.tableRef = document.querySelector('.ant-table-body')

		const { data = [] } = this.state

		if (data && data.length > 0) {
			return 
		}
		this.getData()
	}

	getData = async () => {
		this.setState({ loading: true })

		try {
			const { data: resData } = await post('getBoardMeetingAttendees', { meeting_id: this.props.data.meeting_id })

			const { code, data } = resData

			if(code === 200) {
				this.setState({ data: data || [] })
				this.tmpData = cloneDeep(data)
			} else {
				message.error(data)
			}
		} catch (err) {
			message.error(err && err.message)
		}

		this.setState({ loading: false })
	}

	onSubmit = async (record, index) => {
		const { attendees_name, email, role, id, status } = record
		const attends_id = id
		const attendees_nameError = this.checkError(attendees_name, 'attendees_name', false)
		const emailError = this.checkError(email, 'email', false)
		const roleError = this.checkError(role, 'role', false)

		if (attendees_nameError || emailError || roleError) {
			const { data } = this.state

			data[index].attendees_nameError = attendees_nameError
			data[index].roleError = roleError
			data[index].emailError = emailError


			this.setState({ data })

			return
		}

		this.setState({ loading: true })

		try {
			const { data: resData } = await post( id ? 'editBoardMeetingAttendees' : 'addBoardMeetingAttendees', {
				attendees_name, email, role,
				meeting_id: this.props.data.meeting_id,
				attends_id,
				status
			})

			const { code, data } = resData

			if (code == 200) {
				this.getData()
				this.props.callback()
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}

		this.setState({ loading: false })
	}

	close = () => {
		const { close, callback } = this.props

		close && close()
		// callback && callback()
	}

	checkError = (value, type, show = true) => {
		const data = cloneDeep(this.state.data)

		if (type === 'attendees_name') {
			if (!value) {
				show && message.error('Name is required')

				return true
			}
		}
		if (type === 'role') {
			if (!value) {
				show && message.error('Role is required')

				return true
			}
		}

		if (type === 'email') {
			if (!value) {
				show && message.error('Email is required')

				return true
			}
			
			if (!emailParse.test(value) ) {
				return true
			}

			for( let i=0;i<data.length;i++){
				if(data.length > 1 && data[i].email == value){
					this.setState({ errorTip : 1 })
					return true
				}else{
					this.setState({ errorTip : 0 })
					return false
				}
			}

		}

		return false
	}

	hancleChange = (value, type, index, record) => {
		const data = cloneDeep(this.state.data)

		const tmpData = data[index]

		tmpData[type] = value
		tmpData[`${type}Error`] = this.checkError(value, type)

		data[index] = tmpData

		this.setState({ data })
	}


	onButtonClick = (type, index) => {
		const { data } = this.state

		const tmpData = data[index]
		tmpData[type] 
	}

	handleEdit = (index) => {
		const data = cloneDeep(this.state.data)
		const tmpData = data[index]

		tmpData.isEdit = true

		data[index] = tmpData

		this.setState({ data })
	}

	handleCancel = (record, index) => {
		const data = cloneDeep(this.state.data)

		if (record.id) {
			data[index] =  cloneDeep(this.tmpData[index])
			data[index].isEdit = false
		} else {
			data.splice(index, 1)
		}

		this.setState({ data })
	}

	handleDelete = async (record) => {
		try{
			const { data: resData } = await post('deleteBoardMeetingAttendees', {
				attends_id: record.id,
				meeting_id: this.props.data.meeting_id
			})

			const { code, data } = resData || {}

			if (code == 200) {
				this.getData()
				this.props.callback()				
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}
	}

	getColumns = () => {
		return [
			{
				title: 'Name',
				render: (text, record, index) => record.isEdit ? <Input className={classnames({ 'has-error': record.attendees_nameError })}  value={record.attendees_name} placeholder="Enter name" onChange={ (e) => { this.hancleChange(e.target.value, 'attendees_name', index, record) }}/> :
				record.attendees_name,
				ellipsis: true,
				width: '20%'
			},
			{
				title: 'Email',
				render: (text, record, index) => record.isEdit ? <Input className={classnames({ 'has-error': record.emailError })}  value={record.email} placeholder="Enter email" onChange={(e) => { this.hancleChange(e.target.value, 'email', index) }} /> :
				record.email,
				width: 230,
				ellipsis: true
			},
			{
				title: 'Role',
				render: (text, record, index) => record.isEdit ? <Select allowClear className={classnames({ 'has-error': record.roleError })} style={{ width: 150 }} value={record.role} placeholder="Select role" onChange={(e) => { this.hancleChange(e, 'role', index) }} >
					{ roleOption.map((val, index) => <Option value={val} key={index}>{val}</Option>) }
				</Select> : record.role,
				width: 180
			},
			{
				title: 'Status',
				dataIndex: 'status',
				className: 'nowrap',
				render: text => attendeesStatus[text] || '--'

			},
			{
				title: '',
				width: 170,
				align: 'right',
				render: (text, record, index) => record.isEdit ? <Fragment>
					<Button type="primary" ghost onClick={() => this.onSubmit(record, index)}>Save</Button>&nbsp;
					<Button onClick={() => this.handleCancel(record, index)} >Cancel</Button>
					</Fragment> : <Fragment>
					<Button type="primary" ghost onClick={() => this.handleEdit(index)} >Edit</Button>&nbsp;
					<Popconfirm placement="top" title="Are you sure to delete this attendee?" onConfirm={ e => {e.preventDefault(); this.handleDelete(record)}} okText="Yes" cancelText="No">
						<Button ghost type="danger">Delete</Button>
					</Popconfirm>
				</Fragment>
			}
		]
	}

	handleAdd = () => {
		const data = cloneDeep(this.state.data)
		data.push({
			isEdit: true,
			status: 'Not yet invited'
		})

		this.setState({data} , () => {
			if (this.tableRef) {
				this.tableRef.scrollTop = 100000
			}
		})
	}

	render() {
		const { loading, data, errorTip } = this.state

		return <div className="meetings-attendees-root">

			{ errorTip == 1 && <div className="pink-box eq-tipbox error_tip" role="alert">
				<p>{__('Email can not be duplicate.')}</p>
			</div>}

			<Table
				rowKey={(value, index) => index}
				columns={this.getColumns()}
				pagination={false}
				dataSource={data}
				loading={loading}
				locale={{ emptyText: <Empty description="No Attendees" /> }}
				scroll={{
					y: 400
				}}
			/>
			<div><Button className="btns-add" type="primary" onClick={this.handleAdd}><PlusOutlined />Add</Button></div>
			<div className="btns-wrap"><Button onClick={this.close} type="primary">Ok</Button></div>
		</div>
	}
}

export default function openCreateAttendeeModal(callback, data, name) {
	return Modal.open({
		component: props => <ModalContent close={props.close} title={ name || "Manage attendees"}> <MeetingModalContent data={data} close={props.close} callback={callback} /></ModalContent>,
		width: 900,
		className: 'sprout-modal'
	})
}