import React, { Component, Fragment } from "react";
import {Alert, Checkbox, Input, message, Select, Space, Table} from "antd";
import { post } from 'srcPath/http';
import { I, clearNoNum, calculator, formatWithArray, filterAmount, formatCurrencyNumber } from "Utils";
import { printf } from 'Utils/i18n';

class CapitalizationStakeholder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    title: __('Type'),
                    width: '14%',
                    dataIndex: 'type',
                    render: (text, data, index) => this.getTypeInput(text, data, index),
                },
                {
                    title: __('Investor name'),
                    width: '27%',
                    render: (text, data, index) => this.getInvestorName(text, data, index),
                },
                {
                    title: <div>{__('Pro-rata')}</div>,
                    width: '16%',
                    render: (text, data, index) => this.getProRata(text, data, index),
                    className: 'financing-round-investment-td-pro-rata',
                },
                {
                    title: __('Investment'),
                    dataIndex: 'investment',
                    width: '26%',
                    render: (text, data, index) => this.getInvestment(text, data, index),
                },
                {
                    title: '',
                    render: (text, data, index) => this.getOperation(text, data, index),
                },
            ],
            selectLoading: false,
            investorRes: '',
        };
    }

    /**
     * stakeholder列表加入investor
     */
    updateStakeholderData = (newFinancingData, key) => {
        const { data } = this.props;
        const { stakeholderData } = data;
        let newStakeholderData = [];
        stakeholderData.forEach( (v) => !v.isNew && newStakeholderData.push(v) );

        newFinancingData.forEach((v, k)=>{
            v.investmentList.forEach((v1, k1)=>{
                const { investorName, investment, investorId } = v1;
                !investorId && newStakeholderData.push({
                    name: investorName,
                    investment: investment,
                    isNew: true,
                    financingDataKey: k,
                    ownership: 0,
                    key: `${k1}${k}`,
                });
            })
        })
        return newStakeholderData;
    }

    getInvestment = (text, data, index) => {
        const { amountSymbol } = this.props.data;
        return <Input
            addonBefore={amountSymbol}
            className="w100 financing-investment-investment"
            value={text}
            bordered={false}
            onChange={this.investmentInputChange.bind(this, index)}
            onBlur={this.inputBlur}
        />
    }

    getOperation = (text, data, index) => {
        let { financingData, k, updateState } = this.props;
        let investmentList = [...financingData[k].investmentList];
        return <div className="financing-investment-stakeholder-button">
            <Space size={2}>
                { (investmentList.length - 1) == index && <div className="icons_add" onClick={this.updateInvested.bind(this, 1, index)}>+</div> }
                <div className="icons_minus" onClick={this.updateInvested.bind(this, 0, index)}>-</div>
            </Space>
        </div>
    }

    updateInvested = (type, index) => {
        let { financingData, k, updateState, data } = this.props;
        const { shareholderList } = data;
        let investmentList = [...financingData[k].investmentList];
        if(type == 1){
            investmentList.push({
                type: 2,
                investorId: '',
                investorName: '',
                proRata: '',
                proRataList: [],
                proRataValue: ['No'],
                investment: '',
                financingDataKey: k,
            });
        }else{
            const id = investmentList[index].investorId;
            const idIndex = shareholderList.findIndex( v => v.id == id );
            idIndex !== -1 && (shareholderList[idIndex].isDisabled = false);
            investmentList.splice(index, 1);
            if(investmentList.length < 1 ){
                investmentList.push({
                    type: 2,
                    investorId: '',
                    investorName: '',
                    proRata: '',
                    proRataList: [],
                    proRataValue: ['No'],
                    investment: '',
                    financingDataKey: k,
                });
            }
        }
        financingData[k].investmentList = [...investmentList];
        financingData[k].investorError = false;
        updateState('financingData', [...financingData]);
    }

    /**
     * pro-rata change
     * @param index
     * @param proRataValue
     */
    proRataSelectChange = (index, data, proRataValue) => {
        const { financingData, k, updateState } = this.props;
        let { totalNewInvestment } = financingData[k];
        totalNewInvestment = totalNewInvestment && clearNoNum({value:totalNewInvestment}).value || 0;
        let { proRataList } = financingData[k].investmentList[index];
        let investment = 0;
        financingData[k].investmentList[index].proRataValue = proRataValue;
        proRataList && proRataList.forEach((v, k1)=>{
            const isChecked = proRataValue.indexOf(v.prefix) != -1;
            financingData[k].investmentList[index].proRataList[k1].isChecked = isChecked;
            if(isChecked){
                //通过pro-rata 计算出 investment
                investment = calculator.agile(`${investment} + ( ${totalNewInvestment} * ${v.fullyShip} / 100 )`);
            }
        });
        financingData[k].investmentList[index].investment = formatWithArray(investment);
        financingData[k].investmentList = JSON.parse(JSON.stringify(financingData[k].investmentList));
        updateState('financingData', [...financingData]);
    }

    getProRata = (text, data, index) => {
        const { selectLoading } = this.state;
        const { proRataList, type } = data;
        return <Select
            className="financing-investment-select-pro-rata w100"
            removeIcon={false}
            showArrow={true}
            showSearch={false}
            bordered={false}
            menuItemSelectedIcon={false}
            maxTagCount="responsive"
            optionLabelProp="label"
            mode="multiple"
            value={data.proRataValue}
            onChange={this.proRataSelectChange.bind(this, index, data)}
            loading={selectLoading}
            onClick={this.checkRataList.bind(this, index, data)}
            disabled={type == 2}
        >
            {
                I(proRataList, []) && proRataList.map((v, k)=>{
                    const { prefix, isChecked, fullyShip } = v;
                    return <Select.Option value={prefix} label={prefix} key={k} className="financing-investment-select-pro-rata-option">
                        <div><Checkbox checked={isChecked}></Checkbox>{prefix}&nbsp;({fullyShip}%)</div>
                    </Select.Option>
                })
            }
        </Select>
    }

    checkRataList = (index, data) => {
        const { proRataList, investorId, type, proRataValue } = data;
        if(type == 1 && I(investorId, 0) && I(proRataList, []).length <= 0){
            this.getProRataList(investorId, index, proRataValue);
        }
    }

    /**
     * type 选择更新
     */
    typeChange = (index, value) => {
        let { financingData, k, updateState } = this.props;
        // financingData[k].investmentList[index].type = value;
        let investmentList = [...financingData[k].investmentList];
        investmentList[index] = {
            type: value,
            investorId: '',
            investorName: '',
            proRata: '',
            proRataList: [],
            proRataValue: value == 2 ? ['No'] : [],
            investment: '',
            financingDataKey: k,
        };
        financingData[k].investmentList = investmentList;
        updateState('financingData', [...financingData]);
    }

    arrayColumn = (array, field) => {
        return array.map(v => v[field]);
    }

    /**
     * Investor name
     * @param text
     * @param data
     * @param index
     * @returns {JSX.Element}
     */
    getInvestorName = (text, data, index) => {
        let { financingData, k } = this.props;
        const investmentList = financingData[k].investmentList;
        const ids = this.arrayColumn(investmentList, 'investorId');
        const { type, investorId, investorName } = data;
        const { shareholderList } = this.props.data;
        if(type == 1){
            return <Select
                className="financing-investment-select-name w100"
                value={investorId}
                onChange={this.investorNameChange.bind(this, index)}
                bordered={false}
            >
                {
                    shareholderList && shareholderList.map((v, k)=>{
                        const { id, name } = v;
                        return <Select.Option disabled={ids.indexOf(id) !== -1} key={k} value={id}>{name}</Select.Option>
                    })
                }
            </Select>
        }
        return <Input
            defaultValue={investorName}
            bordered={false}
            className="financing-investment-input-name w100"
            onBlur={this.inputNameBlur.bind(this, index)}
        />
    }

    getProRataList = async(stakeholderId, index, proRataValue = '') => {
        let { updateState, financingData, k } = this.props;
        this.setState({selectLoading: true});
        try {
            const { data: resData } = await post('getFinancingRoundShareClassInfo', {stakeholderId})
            const { code ,data } = resData;
            if (code === 0) {
                proRataValue && data.forEach((v, k)=>{
                    data[k].isChecked = proRataValue.indexOf(v.prefix) !== -1;
                });
                financingData[k].investmentList[index].proRataList = [...data];
                if(!!financingData[k].isSecondId){
                    financingData[k].investmentList[index].proRataList = [];
                }
                financingData[k].investmentList = JSON.parse(JSON.stringify(financingData[k].investmentList))
                updateState({ financingData: [...financingData] })
            } else {
                message.error(resData)
            }
        } catch(err) {
            message.error(err.message)
        }
        this.setState({selectLoading: false});
    }

    /**
     * investor Existing/New change
     * @param index 下标
     * @param value id
     */
    investorNameChange = (index, e) => {
        let { updateState, financingData, k, data } = this.props;
        let { shareholderList } = data;
        //获取pro-rata数据
        this.getProRataList(e, index);
        financingData[k].investmentList[index].investorId = e;
        //重置pro-rata
        financingData[k].investmentList[index].proRataValue = [];
        financingData[k].investmentList = JSON.parse(JSON.stringify(financingData[k].investmentList));
        updateState({
            financingData,
            shareholderList: JSON.parse(JSON.stringify(shareholderList))
        }, null, null, false);
    }

    /**
     * investor new change
     * @param index
     * @param e
     */
    investorNameInputChange = (index, e) => {
        const { updateState, financingData, k } = this.props;
        financingData[k].investmentList[index].investorName = e.target.value;
        financingData[k].investmentList = JSON.parse(JSON.stringify(financingData[k].investmentList));
        updateState({ financingData}, null, null, null, false);
    }

    investmentInputChange = (index, e) => {
        const { updateState, financingData, k } = this.props;
        const element = e.target;
        const filter = filterAmount(e);
        let newFinancingData = [...financingData];
        newFinancingData[k].investmentList[index].investment = filter.value;
        newFinancingData[k].investmentList = JSON.parse(JSON.stringify(newFinancingData[k].investmentList));
        const stakeholderData = this.updateStakeholderData(newFinancingData, k);
        updateState({
            financingData: newFinancingData,
            stakeholderData,
        }, null, element, filter.cursorPositionX, false);
    }

    inputNameBlur = (index, e) => {
        const { getInvestorSum, financingData, k, updateState, data } = this.props;
        let newFinancingData = [...financingData];
        const investorRes = getInvestorSum(newFinancingData, k);
        newFinancingData[k].investorError = investorRes !== true;
        newFinancingData[k].investorRes = investorRes;
        this.investorNameInputChange(index, e);
        let stateData = {financingData: newFinancingData};
        if(data.active == 1){
            const stakeholderData = this.updateStakeholderData([...newFinancingData], k);
            stateData['stakeholderData'] = stakeholderData;
        }
        updateState(stateData);
    }

    inputBlur = (e) => {
        const { getInvestorSum, financingData, k, updateState } = this.props;
        let newFinancingData = [...financingData];
        const investorRes = getInvestorSum(newFinancingData, k);
        newFinancingData[k].investorError = investorRes !== true;
        newFinancingData[k].investorRes = investorRes;
        updateState({financingData: newFinancingData});
    }

    /**
     * type
     * @param text
     * @returns {JSX.Element}
     */
    getTypeInput = (text, data, index) => {
        const value = {1: __('Existing'), 2: __('New')}
        return (
            <Select
                value={data.type}
                className="financing-investment-select-type w100"
                bordered={false}
                onChange={this.typeChange.bind(this, index)}
            >
                { Object.keys(value).map( (k) => <Select.Option key={k} value={parseInt(k)}>{value[k]}</Select.Option> ) }
            </Select>
        )
    }

    render() {
        const { columns } = this.state;
        const { data, financingData, k } = this.props;
        const { investmentList, investorError, investorRes } = financingData[k];
        return (
            <Fragment>
                {investorError && (
                    <Alert
                        message={<span>{__('The round you modeled is oversubscribed')} ( <b className="new-financing-round-red">{ formatCurrencyNumber(investorRes, 0) }</b> ) {__('based on the deal terms and pro-rata selections.')}</span>}
                        type="error"
                        className="new-financing-stakeholder-error"
                    />
                )}
                <Table
                    className="new-financing-round-capitalization-stakeholder"
                    columns={columns}
                    dataSource={investmentList}
                    pagination={false}
                    loading={data.loading}
                />
            </Fragment>
        );
    }
}
export default CapitalizationStakeholder;