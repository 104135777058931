import React, { Component, Fragment } from "react";
import { typeOptions } from '../../config';
import {Button, Input, message, Select, Form, Space, Spin} from "antd";
import { post } from 'srcPath/http';
import { I } from "Utils";
import Loading from 'components/Loading';
import "./style.scss";

class ESignDocumentSet extends Component {
    formRef = React.createRef();
    state = {
        loading: false,
        eSignForm: [
            { label: __('Security type'), key:'type', data: typeOptions.slice(0, 1), type: 'select', value: '1', isRequired: true, disabled: true, isPdf: true },
            { label: __('E-sign service'), key:'sign_brand', data: [{ label: 'DocuSign', value: '1' }], type: 'select', isRequired: true, isPdf: true },
            { label: __('Document set title'), key:'title', type: 'input', isRequired: true, help:'', isPdf: true },
            { label: __('Grant Letter'), key: 'signType1', data: null, type: 'select', templateIdType: 1, isRequired: true, isPdf: false },
            { label: __('Form of Exercise Agreement'), key: 'signType2', data: null, type: 'select', templateIdType: 2, isRequired: false, isPdf: false },
            { label: __('Equity Incentive Plan'), key: 'signType3', data: null, type: 'select', templateIdType: 3, isRequired: false, isPdf: false },
            { label: __('Additional documents'), key: 'signType4', data: null, type: 'select', templateIdType: 4, isRequired: false, isPdf: false },
            { label: __('E-sign PDF template'), key: 'templateId', data: null, type: 'select', isRequired: true, isPdf: true },
        ],
    };

    componentDidMount() {
        this.getTemplateList();
    }

    /**
     * 获取 docusign template 列表
     * @returns {Promise<void>}
     */
    getTemplateList = async () => {
        const { record, type:clickType, docusignType } = this.props;
        this.setState({ loading: true });
        const { id, title, templateId, type, sign_brand, docusign_type } = record;
        try {
            const { data: resData } = await post('getSignTemplateList', id)
            const { code, data } = resData;
            if(code === 0){
                this.initTemplateList(data, docusignType);
                if(docusign_type == 2){
                    clickType === 'edit' && this.formRef.current.setFieldsValue({
                        title, type, sign_brand, templateId
                    });
                }else{
                    clickType === 'edit' ? this.formRef.current.setFieldsValue({
                        title, type, sign_brand,
                        signType1: templateId[1] && templateId[1].id,
                        signType2: templateId[2] && templateId[2].id,
                        signType3: templateId[3] && templateId[3].id,
                        signType4: templateId[4] && templateId[4].id,
                    }) : this.formRef.current.setFieldsValue({
                        title, type: '1', sign_brand: '1',
                    });
                }
            }
        } catch(err) {
            message.error(err && err.message)
        }
        this.setState({ loading: false });
    }

    /**
     * 初始化 template 数组
     */
    initTemplateList = (data, docusignType) => {
        if(!data){
            return false;
        }
        const { eSignForm } = this.state;
        let returnArray = [];
        data.forEach((v, k)=>{
            if(docusignType === 1 && v.template_type == 1){
                returnArray.push({label: v.template_name, value: v.id});
            }else if(docusignType === 2 && v.template_type == 2){
                returnArray.push({label: v.template_name, value: v.platform_template_id});
            }
        })
        eSignForm.forEach((v, k)=>{
            !I(v.data, '') && (eSignForm[k].data = returnArray);
        })
        this.setState({eSignForm})
    }

    /**
     * 重置 title 验证
     */
    inputChange = () => {
        const { eSignForm } = this.state;
        delete eSignForm[2].isError;
        this.setState({eSignForm})
    }

    /**
     * form 表单
     * @returns {unknown[]}
     */
    getForm = () => {
        const { eSignForm } = this.state;
        const { docusignType } = this.props;
        return eSignForm.map((v, k)=>{
            const { label, key, data, type, isRequired, isError, help, isPdf } = v;
            console.log(docusignType);
            return (
                <Fragment>
                    {
                        ((docusignType == 2 && isPdf ) || (docusignType == 1 && key != 'templateId') ) && <div className="e-sign-document-attr"><Form.Item
                            label={label}
                            name={key}
                            rules={[
                                { required: isRequired, message: `${label} is required` },
                                ({ filedValue }) => ({
                                    validator(_, value) {
                                        return (!value || !isError) ? Promise.resolve() : Promise.reject(new Error(help));
                                    },
                                })
                            ]}
                            key={v.key}
                        >
                            { type === 'select' ? (
                                <Select className="e-sign-document-attr-select">{ data && data.map(v1 => <Select.Option key={v1.value} value={v1.value}>{v1.label}</Select.Option> )}</Select>
                            ) : (
                                <Input className="e-sign-document-attr-input" onChange={this.inputChange} />
                            )}
                        </Form.Item></div>
                    }
                </Fragment>
            )
        })
    }

    /**
     * 组合表单参数
     * @param submitData
     * @returns {{id, title, type, templateId}}
     */
    combinationData = (submitData) => {
        const { record, docusignType } = this.props;
        const { id } = record;
        let signType = [];
        for(let i=1; i<=4; i++){
            I(submitData[`signType${i}`], '') && signType.push({id: submitData[`signType${i}`], type: i});
        }
        submitData.signType = JSON.stringify(signType);
        return {id: I(id, 0), title: I(submitData.title, ''), type: I(submitData.type, ''), templateId: docusignType === 1 ? I(submitData.signType, '') : submitData.templateId, signType: I(submitData.sign_brand, 1)};
    }

    /**
     * 提交表单
     * @param submitData
     * @returns {Promise<void>}
     */
    saveESignDocument = async (submitData) => {
        Loading.global(true);
        const parameter = this.combinationData(submitData);
        const { close, callback } = this.props;
        const { eSignForm } = this.state;
        try {
            const { data: resData } = await post('bindTemplateByDocumentId', parameter)
            const { code, data } = resData;
            if(code === 0){
                callback();
                message.success(__('Success!'));
                close();
            }else if (code === 30003){
                eSignForm[2].isError = true;
                eSignForm[2].help = data;
                this.setState({eSignForm},()=>{
                    this.formRef.current.validateFields(['title']);
                });
            }
        } catch(err) {
            message.error(err && err.message)
        }
        Loading.global(false);
    }

    render() {
        const { loading } = this.state;
        return (
            <Spin spinning={loading}><div className="e-sign-document-root">
                <div className="edit-document-set-title">{__('Create a document set to attach equity plan documents to securities.')}</div>
                <div className="e-sign-document-form">
                    <Form
                        ref={this.formRef}
                        onFinish={this.saveESignDocument}
                    >
                        {this.getForm()}
                        <Form.Item>
                            <div className="e-sign-document-button"><Button type="primary" htmlType="submit" >{__('Save')}</Button></div>
                        </Form.Item>
                    </Form>
                </div>
            </div></Spin>
        );
    }
}
export default ESignDocumentSet;