import React, { Component } from "react";
import {Button, Input, Select, Space, Spin, Table} from "antd";
import { relationshipList } from 'Utils/constant';
import { I } from 'Utils';
import {ArrowLeftOutlined} from "@ant-design/icons";
import DateSelector from 'components/DateSelector';
import { formatDate2Api, formatDateForDatePicker } from 'Utils/format/formatDate';
import moment from "moment";

class RelationshipModels extends Component {

    state = {
        loading : false,
        stakeholderData: {},
        relationship: {
            desc : __('Review stakeholder relationships and effective dates to ensure they are correct. '),
            columns : [
                {
                    title: __('Effective date'),
                    align: 'left',
                    dataIndex: 'effective_date',
                    key: 'attr',
                    width: '30%',
                    className: 'relationship-table-td-red',
                    render: (text, record, index) => this.dateRender(record, index, 1),
                },
                {
                    title : __('Relationship'),
                    align : 'left',
                    key : 'relationship',
                    width : '50%',
                    className: 'relationship-table-td-red',
                    render: (text, record, index) => this.relationshipRender(record, index),
                },
                {
                    title : '',
                    align : 'left',
                    dataIndex : 'attr',
                    key : 'attr',
                    className: 'relationship-table-td-red',
                    render: (text, record, index) => this.operationRender(index, 1),
                },
            ],
            tableList : [{
                effectiveDate : moment(),
                relationship : 'Investor',
            }],
        },
        jobTitle: {
            desc : __('Review stakeholder job titles and effective dates to ensure they are correct. (Optional)'),
            columns : [
                {
                    title: __('Effective date'),
                    align: 'left',
                    dataIndex: 'effective_date',
                    key: 'attr',
                    width: '30%',
                    render: (text, record, index) => this.dateRender(record, index, 2),
                },
                {
                    title : __('Job title'),
                    align : 'left',
                    dataIndex : 'job_title',
                    key : 'attr',
                    width : '50%',
                    className: 'relationship-table-td-red',
                    render: (text, record, index) => <Input className="can-input color808080" value={I(text, '')} onChange={this.jobTitleInputChange.bind(this, index)} />,
                },
                {
                    title : '',
                    align : 'left',
                    dataIndex : 'attr',
                    key : 'attr',
                    render: (text, record, index) => this.operationRender(index, 2),
                },
            ]
        },
        defaultRelationshipValue: 'Investor',
    }

    componentDidMount() {
        const { stakeholderData } = this.props;
        this.setState({
            stakeholderData
        });
    }

    /**
     * 日历插件
     * @param record
     * @param index
     * @returns {JSX.Element}
     */
    dateRender = (record, index, type) => {

        return <DateSelector
            value={record.effective_date}
            onChange={this.effectiveDateChange.bind(this, index, type)}
            allowClear={false}
            suffixIcon={false}
            inputReadOnly={true}
            bordered={false}
            className="date-calendar-with10padding calender-positionReset datepicker vesting_start_datepicker mr-6 w100 ml5"
        />
    }

    /**
     * relationship 列
     * @param record
     * @param index
     * @returns {JSX.Element}
     */
    relationshipRender = (record, index) => {
        const { defaultRelationshipValue } = this.state;
        return (
            <Select
                bordered={false}
                className="w100 ml5"
                value={ I(record.relationship, defaultRelationshipValue) }
                onChange={this.relationshipChange.bind(this, index)}
            >
                { relationshipList.map( (v, k) => <Select.Option key={k} value={v}>{v}</Select.Option> ) }
            </Select>
        )
    }

    jobTitleInputChange = (index, e) => {
        const value = e.target.value;
        const { stakeholderData } = this.state;
        const { job_title } = stakeholderData;
        let newJobTitle = [...job_title];
        newJobTitle[index].job_title = value;
        stakeholderData.job_title = newJobTitle;
        this.setState({stakeholderData})
    }

    /**
     * 操作 列
     * @param index
     * @param type 1:relationship, 2:job title
     * @returns {JSX.Element}
     */
    operationRender = (index, type = 1) => {
        const { stakeholderData } = this.state;
        const { relationship, job_title } = stakeholderData;
        const tableList = { 1: relationship, 2: job_title }
        return (
            <div className="operations">
                <Space size={10}>
                    { tableList[type].length == (index+1) ? (<div className="icon_add" onClick={this.addRow.bind(this, type)}>+</div>) : (<div className="icon_add empty-add"></div>)}
                    <div className="icon_minus" onClick={this.delRelationship.bind(this, index, type)}>-</div>
                </Space>
                <div className="clear"></div>
            </div>
        )
    }

    /**
     * 日历change事件
     * @param index
     * @param date
     */
    effectiveDateChange = (index, type, date) => {
        const { stakeholderData } = this.state;
        const { relationship, job_title } = stakeholderData;
        const key = type == 1 ? 'relationship' : 'job_title';
        let tableList = type == 1 ? [...relationship] : [...job_title];
        tableList[index]['effective_date'] = date;
        stakeholderData[key] = tableList;
        this.setState({stakeholderData});
    }

    /**
     * relationship change事件
     * @param index
     * @param value
     */
    relationshipChange = (index, value) => {
        const { stakeholderData } = this.state;
        const { relationship } = stakeholderData;
        let newRelationship = [...relationship];
        newRelationship[index]['relationship'] = value;
        stakeholderData.relationship = newRelationship;
        this.setState({stakeholderData});
    }

    /**
     * 添加一条 relationship
     */
    addRow = (type) => {
        const { stakeholderData } = this.state;
        const key = type == 1 ? 'relationship' : 'job_title';
        let list = [...stakeholderData[key]];
        list.push( {effective_date : moment(), [type == 1 ? 'relationship' : 'job_title'] : ( type == 1 ? 'Investor' : '')} );
        stakeholderData[key] = list;
        this.setState({stakeholderData});
    }

    /**
     * 删除一条 relationship
     * @param index
     */
    delRelationship = (index, type) => {
        const { stakeholderData } = this.state;
        const key = type == 1 ? 'relationship' : 'job_title';
        let list = [...stakeholderData[key]];
        if(list.length <= 1){
            return;
        }
        list.splice(index, 1);
        stakeholderData[key] = list;
        this.setState({stakeholderData})
    }

    /**
     * 下一页
     */
    saveSecondData = () => {
        const { close, getReviewStakeholderModels } = this.props;
        //验证填写信息
        if(!this.checkRequest()){
            return;
        }
        //整合数据
        this.mergeRequestData();
        //打开第三确认页
        getReviewStakeholderModels();
        close();
    }

    /**
     * 返回上一页
     */
    goBackModels = () => {
        const { close,mergeStakeholders } = this.props;
        mergeStakeholders();
        close();
    }

    checkRequest = () => {
        const { stakeholderData } = this.state;
        const { relationship } = stakeholderData;
        const {isError: relationshipError, dataList:newRelationship} = this.saveDataListError(relationship, 'relationship');
        stakeholderData.relationship = newRelationship;
        this.setState({stakeholderData});
        return !relationshipError;
    }

    saveDataListError = (dataList, key) => {
        let isError = 0;
        dataList.forEach((v, k)=>{
            const { effective_date } = v;
            if( !I(effective_date, '') || !I(v[key], '')){
                dataList[k].isError = true;
                isError++;
            }else{
                dataList[k].isError = false;
            }
        });
        return {isError, dataList};
    }

    /**
     * 整合当前页面填写的数据
     */
    mergeRequestData = () => {
        const { mergeRequestData } = this.props;
        const { stakeholderData } = this.state;
        mergeRequestData({ stakeholderData });
    }

    /**
     * 关闭model
     */
    closeModels = () => {
        const { close, initStakeHolderData } = this.props;
        initStakeHolderData();
        close();
    }

    render() {
        const { loading, relationship, jobTitle, stakeholderData } = this.state;

        const { relationship:relationshipList, job_title:jobTitleList } = stakeholderData;
        return (
            <Spin tip="Loading..." spinning={loading}>
                <div className="merge-stakeholder-header">
                    <ArrowLeftOutlined onClick={this.goBackModels} className="go-back" />
                    <h5 className="title">{__('Correct Relationship History')}</h5>
                    <div className="close " onClick={this.closeModels} >×</div>
                </div>

                <div className="merge-stakeholder-content new-merge-stakeholder-content">
                    <dl>
                        <dt>{relationship.desc}</dt>
                        <dd>
                            <Table
                                className="relationship-table"
                                pagination={false}
                                columns={relationship.columns}
                                dataSource={relationshipList}
                                rowKey="keyId"
                                rowClassName={(record, index)=>{
                                    return !!record.isError ? (index && relationshipList[index-1].isError ? 'relationship-table-tr-error-err' : 'relationship-table-tr-error') : '';
                                }}
                            />
                        </dd>
                    </dl>
                    <dl>
                        <dt>{jobTitle.desc}</dt>
                        <dd>
                            <Table
                                className="relationship-table"
                                pagination={false}
                                columns={jobTitle.columns}
                                dataSource={jobTitleList}
                                rowKey="keyId"
                                rowClassName={(record, index)=>{
                                    return !!record.isError ? (index && jobTitleList[index-1].isError ? 'relationship-table-tr-error-err' : 'relationship-table-tr-error') : '';
                                }}
                            />
                        </dd>
                    </dl>
                </div>
                <div className="information-w100">
                    <Button className="information-button" type="primary" onClick={this.saveSecondData}>{__('Next: review stakeholder details')}</Button>
                </div>
            </Spin>
        );
    }
}
export default RelationshipModels;
