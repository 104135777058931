import React from 'react';
import SimpleTable from '../components/SimpleTable';
import { formatDate } from 'Utils/format/formatDate';

export default function PersonalDetail(props) {
	const { birthday, country, email, employeeId, issue_date_relationship, full_name, passport, cost_center, relationship, job_title, annual_salary, gender, wallet_address_list = [] } = props.data || {}

	const config = [
		{
			label: __('Full name'),
			value: full_name
		},
		{
			label: __('Email'),
			value: email
        },
		{
			label: __('Passport or National ID'),
			value: passport
		},
		{
			label: __('Employee ID'),
			value: employeeId
		},
		{
			label: __('Issue date relationship'),
			value: issue_date_relationship
		},
		{
			label: __('Cost center'),
			value: cost_center?cost_center:'--'
		},
		{
			label: __('Relationship'),
			value: relationship
		},
		{
			label: __('Country'),
			value: country
		},
		{
			label: __('Date of birth'),
			value: formatDate(birthday)
		},
		{
			label: __('Job title'),
			value: job_title
		},
		{
			label: __('Annual salary'),
			value: annual_salary
		},
		{
			label: __('Gender'),
			value: gender
		},
	]

	return <div className="certificate-detail-section">
		<SimpleTable title={__('Personal detail')} config={config} />
	</div>
}