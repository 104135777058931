import React, { Component } from 'react';
import { post } from 'srcPath/http';
import { message, Table, Button, Dropdown } from 'antd';
import { Link } from "react-router-dom";
import { DownOutlined } from '@ant-design/icons';
import { columns } from './config';
import classnames from 'classnames';
import './index.scss';

export default class List extends Component {
	state = {
		tableData: [],
		loading: true,
		disabled: true,
		companyInfo: {}
	}

	async componentDidMount() {
		await this.getUserInfo();
		this.getTableData();
	}

	getUserInfo = async () => {
		this.setState({ loading: true })

		try {
			const { data: resData } = await post('getCompanyUserInfoById')

			const { code, data } = resData || {}

			if (code == 0) {
				localStorage.setItem('COMPANY_USER', JSON.stringify(data))
				this.setState({
					companyInfo: data
				});
			} else {
				message.error(data)
			}
		} catch(err) {
			localStorage.setItem('COMPANY_USER', JSON.stringify({}))
			message.error(err && err.message)
		}
	}

	getTableData = async () => {
		const { companyInfo } = this.state;
		this.setState({ loading: true })

		try {
			const { data: resData } = await post('getConsentList')

			const { code, data } = resData || {}

			if (code == 0) {
				const arr = [];
				const boardRole = companyInfo.consent_role;
				// 'Legal_Admin'：1,'Company_Editor' ： 2,'Share_Class_Admin' ：3,'Esop_Admin' ：4,'Company_Viewer' ：5,
				// 'Board_Admin' ：6,'Board_Director'：7,'Board_Observer'：8  126 可以编辑 7可以签名
				let disabled = [3, 4, 5, 7, 8].includes(boardRole);
				data.forEach((val, index) => {
					val.consent_detail.key = index;
					const userList = val.board_user_list.filter((item, index) => {
						item.key = index;
						return item.board_role == 7;
					});
					const filterUser = userList.filter((item) => {
						return item.approval_status
					});
					val.consent_detail.progress = filterUser.length / userList.length;
					val.consent_detail.userList = userList;
					val.key = index;
					val.consent_detail.disabled = disabled;
					arr.push(val.consent_detail);
				 })
				this.setState({ tableData: arr, disabled })
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}

		this.setState({ loading: false })
	}

	async handleDeleteConsent(consent_id, status) {
        try {
			const { data: resData } = await post('deleteConsent', { consent_id, status })
			const { data, code } = resData;

			if (code == 0) {
				message.success(__('remove success'));
				this.getTableData();
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}
    }


	handleClickRow(record, e) {
		const { disabled } = this.state;
		const consent_id = record.consent_id;
		if (!['svg', 'LI', 'SPAN', 'path'].includes(e.target.tagName)) {
			if (record.status === '1' && !disabled) { // 草稿跳转到编辑页
				this.props.history.push({
					pathname: '/home/board/consents/edit',
					state: { consentId: consent_id }
				});
			} else if (record.status !== '1') {
				this.props.history.push({
					pathname: '/home/board/consents/preview',
					state: { consentId: consent_id }
				});
			}
		} else {
			const action = e.target.getAttribute("data-action");
			if (action === 'delete') {
				if (disabled) return message.warn(__('Permission denied'));
				this.handleDeleteConsent(consent_id, record.status);
			} else if (action === 'new') {
				if (disabled) return message.warn(__('Permission denied'));
				this.props.history.push({
					pathname: '/home/board/consents/edit',
					state: { consentId: consent_id }
				});
			}
		}
	}

	render() {
		const { tableData, loading, disabled } = this.state;
		const content = <ul className="board-consents-operate-item">
			<li className={classnames({ 'board-consents-operate-disabled': disabled })}><Link disabled={disabled} to="/home/board/consents/edit">{__('New consent')}</Link></li>
			<li className={classnames({ 'board-consents-operate-disabled': disabled })}><Link disabled={disabled} to="/home/board/consents/template/select">{__('From template')}</Link></li>
		</ul>;

		return <div className="meeting-list-root">
                <div className="meeting-list-banner">
					<Dropdown overlay={content} placement="bottomCenter" trigger="click"><Button className="consent-btn-create" type="primary">{__('Create consent')} <DownOutlined className="consent-drop-down-icon" /></Button></Dropdown>
					<Button type="primary"><Link to="/home/board/consents/templates">{__('Manage templates')}</Link></Button>
                </div>
				<Table 
					size="small" 
					rowKey="index" 
					columns={columns} 
					dataSource={tableData} 
					pagination={false} 
					loading={loading}
					onRow={record => {
						return {
						  onClick: this.handleClickRow.bind(this, record),
						};
					  }}
				/>
            </div>
	}
}