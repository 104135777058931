import React from 'react';
import { Input, Row, Col } from 'antd';
import { FormItem } from 'react-antd-formutil';
import security from 'stores/security';
import { calculator } from 'Utils'
import './style.scss';

function Quantity(props) {
    const isFractionalShare = security.companyInfo.fractional_control == '1';
    const parser = (viewValue, $setViewValue) => {
        const reg = isFractionalShare ? /^\D*(\d*(?:\.\d{0,5})?).*$/g : /^\D*(\d*(?:)?).*$/g

        const value = viewValue.replace(reg, '$1');

        $setViewValue(value);

        return viewValue === '' ? null : calculator.division(Number(value), 1);
    }

    return <Row gutter={2} className="filter-ownership-root">
        <Col span={11}>
            <FormItem
                name="quantity.min"
                label={__('Quantity')}
                $parser={parser}
            >
                <Input placeholder="Min"/>
            </FormItem>
        </Col>
        <Col span={2} className="filter-ownership-words">{__('to')}</Col>
        <Col span={11} className="filter-ownership-ownership">
            <FormItem
                name="quantity.max"
                label=""
                $parser={parser}
            >
                <Input placeholder="Max"/>
            </FormItem>            
        </Col>
    </Row>
}

export default Quantity