import React, { Component } from 'react';
import { Button, message, Spin, Radio } from 'antd';
import { btnsConfig } from './config';
import { post } from 'srcPath/http';
import security from 'stores/security';
import Modal, { ModalContent } from 'components/Modal';
import ReportIssue from 'components/CertificateDetail/components/ReportIssue';
import Overview from './components/Overview';
import Holdings from './components/Holdings';
import Capitalization from './components/Capitalization';
import FinancingHistoryPage from './components/FinancingHistoryPage';
import Fairmarketvalue from './components/FairMarketValue';
import EmptyTips from '../EmptyTips'
import './style.scss';

// 0: 'No access', 
// 1: 'Individual view',
// 2: 'Summary view',
// 3: 'Intermediate view',
// 4: 'Detailed view',

// 'overview' | 'holdings' | 'capitalization' | 'financingHistory'
const levelArray = [
	{
		name: __('No Access'),
		value: '0',
		message: __('Stakeholders here will not have account access and see their holdings and other information about this company.'),
	},
	{
		name: __('Basic view'),
		value: '1',
		message: __('Shows types of equity securities owned without displaying the percentage of company ownership.'),
	},
	{
		name: __('Individual view'),
		value: '2',
		message: __('Fully diluted ownership view for a single stakeholder.'),
	},
	{
		name: __('Summary view'),
		value: '3',
		message: __('Share class view. Includes option strike price details.'),
	},
	{
		name: __('Intermediate view'),
		value: '4',
		message: __('Detailed view for preferred stakeholders. Share class view for non-preferred.'),
	},
	{
		name: __('Detailed view'),
		value: '5',
		message: __('Detailed information view for all stakeholders and securities.'),
	}
]

function LevelItem(props) {
	const { value, name } = props.data
	const active = value === props.value;
	return <div className="access-level-item" onClick={() => props.onChange(value)}>
		<Radio checked={active}><span className="name">{name}</span></Radio>
	</div>
}
export default class PortfolioNew extends Component {
	state = {
		tab: 'overview',
		level: 1,
		loading: true,
		data: {},
		AdmintoolUserRole: localStorage.getItem('AdmintoolUserRole') || '',
		hasAsset: false,
		loadingAsset: true
	}

	componentDidMount() {
		this.getAssets()
		this.getData();
	}


	handleClick = val => {
		const { tab } = this.state

		if (val && val !== tab) {
			this.setState({ tab: val })
		}
	}

	getAssets = async () => {
		try {
			const { data: resData } = await post('getPortfolioViewStatus', {
				stakeholder_id: security.companyInfo && security.companyInfo.stakeholder_id
			})

			const { code, data } = resData || {}
			console.log('%c [ data ]-96', 'font-size:13px; background:pink; color:#bf2c9f;', data)

			if (code === 0) {
				this.setState({
					hasAsset: +data?.status === 1
				})
			}
		} catch (err) {
			message.error(err.message)
		}
		this.setState({
			loadingAsset: false
		})
	}

	getData = async () => {
		try {
			const { data: resData } = await post('getProfileOverview', {
				stakeholder_id: security.companyInfo && security.companyInfo.stakeholder_id
			})

			const { code, data } = resData || {}

			if (code === 0) {
				const { accessLevel, holdings: { tokenVesting, tokenWarrant } } = data
				data.tokenHoldings = { tokenVesting, tokenWarrant }
				delete data.holdings.tokenVesting
				delete data.holdings.tokenWarrant

				this.setState({
					level: accessLevel,
					data
				}, ()=>{
					this.getFinancingHistory();
				})
			}
		} catch (err) {
			message.error(err.message)
		}

		this.setState({ loading: false })
	}

	getFinancingHistory = async () => {
		this.setState({ loading: true })
		try {
			const { data: resData } = await post('getFinancingHistory', {})
			const { code, data } = resData || {}
			if (code === 0) {
				const { fundingRounds } = data
				let { data:allData } = this.state;
				allData.financingHistory = fundingRounds;
				this.setState({data:{...allData}})
			}
		} catch (err) {
			message.error(err.message)
		}
		this.setState({ loading: false })
	}

	handleOpenDialog() {
		Modal.open({
			component: props => <ModalContent title={__("Report Issue")} close={props.close}>
				<ReportIssue callback={props.close} hideHeader={true} />
			</ModalContent>,
			width: '700px',
			maskClosable: true,
			className: 'sprout-modal'
		})

		return
	}

	handleLevelChange = async (e) => {
		let val = e.target.value
		this.setState({ level: val })
	}

	render() {
		const { level, tab, loading, data, AdmintoolUserRole, hasAsset, loadingAsset } = this.state

		if (loadingAsset) {
			return <Spin style={{ width: '100%', margin: '0 auto' }} spinning={loadingAsset} />
		}
		if (!hasAsset) {
			return <EmptyTips />
		}

		return <div className="client-portfolio-root">
			{AdmintoolUserRole &&
				<div>
					<div className="access-level-title">{__('Logged in as Sprout admin')}</div>
					{AdmintoolUserRole == 'admin' && <div className="access-level">
						<div className="access-level-item">
							<Radio.Group onChange={this.handleLevelChange.bind(this)} value={level}>
								{levelArray.map((val, index) =>
									<Radio value={val.value}>{val.name}</Radio>
								)}
							</Radio.Group>
						</div>
					</div>
					}
				</div>
			}
			{level == 0 &&
				<div className="access-level-noaccess">
					{__('The stakeholder will not be able to access and see the portfolio view for this company if "No Access" is selected.')}
				</div>
			}
			{level != 0 &&
				<div>
					<div className="portfolio-banner">{__('Sprout')} <a onClick={this.handleOpenDialog.bind(this)}>{__('Report Issue')}</a></div>
					<Spin spinning={loading}>
						<div className="portfolio-btns">
							{
								btnsConfig[level]?.map((val, index) => <Button type={tab === val.value ? 'primary' : ''} key={index} onClick={() => this.handleClick(val.value)} >{val.label}</Button>)
							}
						</div>
						<div className="portfolio-content">
							{tab === 'overview' && <Overview data={data} onLink={this.handleClick} />}
							{tab === 'holdings' && <Holdings currency={data && data.currency} />}
							{tab === 'capitalization' && <Capitalization data={data} />}
							{tab === 'financingHistory' && <FinancingHistoryPage data={data} />}
							{tab === 'fairmarketvalue' && <Fairmarketvalue data={data} />}
						</div>
					</Spin>
				</div>
			}
		</div>
	}
}