import React from 'react';
import { Link } from "react-router-dom";
import OptionsList from "./OptionsList";
import RsasList from "./RsasList";
import RsusList from "./RsusList";
import SarsList from "./SarsList";
import { Button } from 'antd';

import './style.scss';

class StockOptionAwards extends React.Component{
    state = {
        activeKey: '1',
    }

    callback = key => {
        this.setState({ activeKey: key });
    }

    componentDidMount() {
        const { state } = this.props.location
        state && this.setState(state)
    }
    
    render(){
        const { activeKey } = this.state
        return(
            <div className="security-template-root">
                <div className="btns-wrap">
                    <Button type={activeKey === '1' && 'primary'} onClick={this.callback.bind(null, '1')} >{__('Options')}</Button> <Button type={activeKey === '2' && 'primary'} onClick={this.callback.bind(null, '2')}>{__('RSAs')}</Button> <Button type={activeKey === '3' && 'primary'} onClick={this.callback.bind(null, '3')}>{__('RSUs')}</Button> <Button type={activeKey === '4' && 'primary'} onClick={this.callback.bind(null, '4')}>{__('SARs')}</Button>
                </div>
                <div className="security-template-content">
                    { activeKey === '1' && 'primary' && <OptionsList history={this.props.history}/> }
                    { activeKey === '2' && 'primary' && <RsasList /> }
                    { activeKey === '3' && 'primary' && <RsusList /> }
                    { activeKey === '4' && 'primary' && <SarsList /> }
                </div>
            </div>
        )
    }
}

export default StockOptionAwards;