import React from 'react';
import { formatNumber } from 'Utils';

export default {}

export const radioData = [
	{
		label: 'Transactions',
		value: 'transactions'
	},
	{
		label: 'All wallets',
		value: 'tokenholders'
	}
]

export const renderValue = val => val == 0 ? val : (val || '--')


const renderEllipsis = text =>  <div className="crypto-transaction-ellipsis">{renderValue(text)}</div>

export const getTransactionColum = () => {
	return [
	  {
	    title: 'Txn Hash',
	    dataIndex: 'txn_hash',
	    ellipsis: true,
	    render: renderEllipsis,
	    fixed: true
	  },
	  {
	    title: 'Method',
	    dataIndex: 'method',
	    render: renderValue
	  },
	  {
	    title: 'Age',
	    dataIndex: 'name',
	    render: renderValue
	  }, 
	  {
	    title: 'From Wallet',
	    dataIndex: 'from_wallet',
	    render: renderEllipsis
	  }, 
	  {
	    title: 'From Stakeholder',
	    dataIndex: 'name',
	    render: renderEllipsis
	  }, 
	  {
	    title: 'To Wallet',
	    dataIndex: 'to_wallet',
	    render: renderEllipsis
	  }, 
	  {
	    title: 'To Stakeholder',
	    dataIndex: 'name',
	    render: renderEllipsis
	  }, 
	  {
	    title: 'Quantity',
	    dataIndex: 'quantity',
	    render: text => formatNumber(text)
	  }, 
	  {
	    title: 'Tag',
	    dataIndex: 'tag',
	  }, 
	  {
	    title: 'Note',
	    dataIndex: 'notes',
	  }, 
  ]
}


export const getHolderColum = () => {

	return [
	  {
	    title: 'Walet address',
	    dataIndex: 'wallet_address',
	  },
	  {
	    title: 'Tokenholder',
	    dataIndex: 'name',
	  },
	  {
	    title: 'Portfolio email',
	    dataIndex: 'portfolio_email',
	  }, 
	  {
	    title: 'Stakeholder email',
	    dataIndex: 'stakeholder_email',
	  }, 
	  {
	    title: 'Quantity',
	    dataIndex: 'quantity',
	  }, 
	  {
	    title: 'Percentage',
	    dataIndex: 'percentage',
	  }, 
	  {
	    title: 'Value',
	    dataIndex: 'value',
	  }, 
	  {
	    title: 'Relationship',
	    dataIndex: 'relationship',
	  }, 
	  {
	    title: 'Registered',
	    dataIndex: 'registered',
	  }, 
  ]
}