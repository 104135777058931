import React, { Component } from 'react';
import { Spin, Form, Input, Select, message, DatePicker } from 'antd'
import { withForm } from 'react-antd-formutil';
import DateSelector from 'components/DateSelector';
import { filterAmount, setCursorPos } from 'Utils';
import moment from "moment";
import { post } from 'srcPath/http';
import './style.scss';



const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

class AdvanceTermsModels extends Component {
    constructor(props) {

        super(props);
        this.state = {
            dateFormat: "DD/MM/YYYY",
            loading: false,
            isAdd: true,
            valuationSource: '',//valuationSource
            valuationProvider: '', //valuationProvider
            fairMarketValue: '',//fairMarketValue
            effectiveDate: '',
            expirationDate: '01/22/2022',
            shareList: {
                Common: ''
            },
            data: {},
            commonlist: [],
            commonClass: '',
            sourceType: 1,
            id: ''
        }
    }
    formRef = React.createRef()
    componentDidMount = () => {
        const { isAdd, val } = this.props
        const { dateFormat } = this.state
        this.setState({
            isAdd
        })
        if (isAdd) {
            return
        }
        const { effectiveDate, expirationDate, fairMarketValue, id } = val
        this.setState({
            fairMarketValue,
            effectiveDate,
            expirationDate,
            id
        })
        this.formRef.current.setFieldsValue({
            fairMarketValue,
            effectiveDate: moment(effectiveDate, dateFormat),
            expirationDate: moment(expirationDate, dateFormat)
        });

    }

    /**
 * 关闭model
 */
    closeModels = () => {
        const { close } = this.props;
        close();
    }
    payView = () => {
        const { payOverView } = this.props
        payOverView()
    }

    onSubmit = async () => {

        const { fairMarketValue, effectiveDate, expirationDate, shareList, isAdd, id, sourceType: type } = this.state
        const { newdatalist, val, EchartsSourec } = this.props
        const { fmv_type } = val || {}
        if (!isAdd) {
            let isExistence = EchartsSourec.map((item, i) => {
                if (item.id != id) {
                    return item.effectiveDate
                }
            })
            this.getisExistence(isExistence, effectiveDate)
        } else {
            this.getisExistence(newdatalist, effectiveDate)
        }
        let value = await this.formRef.current.validateFields();
        if (!value) { return }
        let data = { fairMarketValue, effectiveDate, expirationDate, shareList, id, type, fmv_type }
        this.setState({
            loading: true
        })
        post(isAdd ? 'addCoinFairMarketValue' : 'updateCoinFairMarketValue', data).then(res => {
            if (res.status == 200) {
                this.setState({
                    loading: false
                })
                this.closeModels()
                this.payView()
            }
        }).catch(function (error) {
            this.setState({
                loading: false
            })
        });
    }
    // 判断是否存在日期
    getisExistence(datalist, effectiveDate) {
        datalist.forEach(item => {
            if (item == effectiveDate) {
                this.formRef.current.setFieldsValue({
                    effectiveDate: '',
                });
                return
            }
        })
    }
    filterAmount = (e) => {
        return filterAmount(e);
    }

    // fair Common
    bindChangeFairValue = (key, e) => {
        const { shareList } = this.state
        const filter = e.target;
        if (filter.value.length > 1 && filter.value.slice(0, 1) == "0" && filter.value.slice(1, 2) !== ".") {
            return
        }
        filter.value = filter.value.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符   
        filter.value = filter.value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的   
        filter.value = filter.value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
        filter.value = filter.value.replace(/^(\-)*(\d+)\.(\d{5}).*$/, '$1$2.$3');//只能输入两个小数
        this.setState({
            fairMarketValue: filter.value,
        })

        // const element = e.target;
        // const filter = filterAmount(e);
        // shareList[key] = filter.value
        // this.setState({
        //     fairMarketValue: filter.value,
        //     // shareList
        // }, () => {
        //     setCursorPos(element, filter.cursorPositionX);
        // });
    }
    bindChangeShareValue = (key, e) => {
        const { shareList } = this.state
        const element = e.target;
        const filter = filterAmount(e);
        shareList[key] = filter.value
        this.setState({
            shareList
        }, () => {
            setCursorPos(element, filter.cursorPositionX);
        });
    }
    handleChange = (valuationSource, value) => {
        const { select_list } = this.props
        this.setState({
            valuationSource,
            valuationProvider: '',
            sourceType: select_list[value.key].type,
            id: select_list[value.key].id
        })
        this.formRef.current.setFieldsValue({
            valuationProvider: ''
        })
    }
    bindValuationProvider(e) {
        this.setState({
            valuationProvider: e.target.value,
        })
    }
    handleChangevalue = (valuationProvider) => {
        this.setState({
            valuationProvider
        })
    }
    handleBirthdayChange = (key, date) => {

        const { dateFormat } = this.state;
        let year = date.year() + 1
        let month = date.get("month") + 1
        let day = date.date()
        if (month >= 1 && month <= 9) {
            month = "0" + month;
        }
        if (day >= 1 && day <= 9) {
            day = "0" + day;
        }
        let expirationDate = day + '/' + month + '/' + year
        this.setState({
            [key]: date.format(dateFormat),
        })
        if (key == 'effectiveDate') {
            this.setState({
                expirationDate
            })
            this.formRef.current.setFieldsValue({
                expirationDate: moment(expirationDate, dateFormat)
            });
        }

    }
    fixdate = (date) => {
        const { dateFormat } = this.state;
        return date.add(1, "years").format(dateFormat)
    }
    render() {
        const { loading, dateFormat, fairMarketValue, effectiveDate, expirationDate, commonClass } = this.state
        const { currency } = this.props
        return (
            <div className='Market-Value-Model'>
                <Spin tip="Loading..." spinning={loading}>
                    <div className="merge-stakeholder-header">
                        <h5 className="title">{__('Add fair market value')}</h5>
                        <div className="close " onClick={this.closeModels} >×</div>
                    </div>

                    <div className="merge-stakeholder-content">

                        <Form {...layout} ref={this.formRef} name="control-hooks" className='MarketValueForm'>
                            <div className='Fmvdaterange'>
                                <Form.Item label={__('Valuation date range')} className='data1' colon={false}
                                    name='effectiveDate'
                                    rules={[
                                        {
                                            required: true,
                                            message: __('Select a valuation date'),
                                        },
                                    ]}>
                                    <DateSelector
                                        disabledDate={current => current && current > moment().endOf('day')}
                                        className="MaRit5 2"
                                        onChange={this.handleBirthdayChange.bind(this, 'effectiveDate')}
                                        inputReadOnly={false}
                                        value={effectiveDate ? moment(effectiveDate, dateFormat) : effectiveDate}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className='data2'
                                    name='expirationDate'
                                    rules={[
                                        {
                                            required: true,
                                            message: __('Select a valuation date'),
                                        },
                                    ]}>

                                    <DateSelector
                                        onChange={this.handleBirthdayChange.bind(this, 'expirationDate')}
                                        inputReadOnly={false}
                                        value={expirationDate ? moment(expirationDate, dateFormat) : expirationDate}
                                    />
                                </Form.Item>
                            </div>

                            <Form.Item label={__('Price per token')} colon={false} className='ShareValues' name='fairMarketValue' rules={[
                                {
                                    required: true,
                                    message: __('Enter a Price per token'),
                                },
                            ]}>
                                <div className='ShareTableList'>
                                    <div className='wid35 name'>{currency}</div>
                                    <Input className='ShareInput FmvShare' value={fairMarketValue} onChange={this.bindChangeFairValue.bind(this, commonClass)} />
                                </div>

                            </Form.Item>

                        </Form>
                    </div>


                    <div className="information-w100">
                        <a className="btn boxbtn-to bg-darkgrey " data-dismiss="modal" onClick={this.closeModels}>{__('Cancel')}</a>
                        <a className="btn boxbtn-to bg-darkblue " onClick={this.onSubmit}>{__('Save')}</a>

                    </div>
                </Spin>
            </div>
        )
    }
}
export default withForm(AdvanceTermsModels);