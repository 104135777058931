import React, { Component, Fragment } from "react";
import { Button, Checkbox, Input, Space, Table } from 'antd';
import { SearchOutlined } from "@ant-design/icons";
import Utils, { formatCurrencyNumber, formatNumber, I, tableSort } from 'Utils';
import formatExercisePrice from 'Utils/format/formatExercisePrice';
import security from 'stores/security';
import './style.scss';
import moment from "moment";
import { formatDate } from "Utils/format/formatDate";
import updateDefaultImg from "modules/Business/Securities/images/update-default.png";
import updateHoverImg from "modules/Business/Securities/images/update-hover.png";
import dynamicImg from "modules/Business/Securities/images/dynamic.gif";

class DraftOptionTable extends Component {

    state = {
        loading: false,
        selectedRowKeys: [],
        updateHoverShow: {},
    }

    componentDidMount() {
        const { updateHoverShow } = this.state;
        const { tableList } = this.props;
        tableList && tableList.forEach((v, k)=>{
            updateHoverShow[v.id] = 0;
        })
        this.setState({updateHoverShow});
    }

    /**
     * 字符串排序
     * @param a
     * @param b
     * @returns {number}
     */
    stringOrderBy = (a, b, key) => {
        const stringA = a[key].toUpperCase();
        const stringB = b[key].toUpperCase();
        return tableSort(stringA, stringB);
    }

    /**
     * Price/Share列计算逻辑，排序使用
     * @param value
     * @param defaultPrice
     * @returns {*|string}
     */
    priceShareOrderBy = (value, defaultPrice) => {
        return value.quantity == 0 ? defaultPrice : ((parseFloat(Utils.resetNumberWithCommas(value.cash_paid)) + parseFloat(Utils.resetNumberWithCommas(value.other_consideration)) + parseFloat(Utils.resetNumberWithCommas(value.debt_canceled)) + parseFloat(Utils.resetNumberWithCommas(value.value_of_ip_transferred))) / Utils.getNumFromFormatNum(value.quantity)).toFixed(2);
    }

    transactionValueOrderBy = (value) => {
        return (parseFloat(Utils.resetNumberWithCommas(value.cash_paid)) + parseFloat(Utils.resetNumberWithCommas(value.other_consideration)) + parseFloat(Utils.resetNumberWithCommas(value.debt_canceled)) + parseFloat(Utils.resetNumberWithCommas(value.value_of_ip_transferred))).toFixed(2);
    }
    getSecurity = (value) => {
        let { eaPlanList } = this.props
        let status = eaPlanList.findIndex(item => item.id == value.security_plan_id)
        if(eaPlanList[status]) {
            let customLabel = value.custom_label_id.substring(0, eaPlanList[status].prefix.length) == eaPlanList[status].prefix ? value.custom_label_id : (eaPlanList[status].prefix + '-' + value.custom_label_id);
            if (status != -1) {
                return customLabel
            } else {
                return '-'
            }
        }
    }
    /**
     * 表头配置
     */
    tableColumns = () => {
        const { listKey } = this.props;
        return [
            {
                title : '',
                dataIndex : 'custom_label_id',
                width: 22,
                className: 'prefer-left-edit-th',
                render: (text, data, index) => {
                    const { updateHoverShow } = this.state;
                    return (
                        <Fragment>
                            {index == listKey && <img className="show-img" src={dynamicImg} />}
                            {index != listKey && <img className={!updateHoverShow?.[data?.id] && 'show-img'} src={updateDefaultImg} />}
                            {index != listKey && <img className={updateHoverShow?.[data?.id] && 'show-img'} src={updateHoverImg} />}
                        </Fragment>
                    )
                }
            },
            {
                title: __('Security'),
                align: 'left',
                dataIndex: 'custom_label_id',
                key: 'custom_label_id',
                sorter: (a, b) => this.stringOrderBy(a, b, 'custom_label_id'),
                render: (text, value, index) => {
                    return <div>{this.getSecurity(value)}</div>
                }
            },
            {
                title: __('Option holder'),
                dataIndex: 'name',
                sorter: (a, b) => this.stringOrderBy(a, b, 'name'),
                render: (text, value, index) => {
                    const { transferKey } = this.props;
                    return <div className={'ellipsis_140'}>{value.name == 0 ? __('No name') : value.name}</div>
                },
                align: 'left',
            },
            {
                title: __('Issued quantity'),
                dataIndex: 'quantity',
                render: (text, value) => {
                    return <div className={'ellipsis_120'}>{value.quantity == 0 ? __('No quantity') : formatNumber(value.quantity, null)}</div>
                },
                align: 'left',
                sorter: (a, b) => a.quantity - b.quantity,
            },
            {
                title: __('Grant date'),
                dataIndex: 'grant_date',
                align: 'left',
                render: (text, value) => {
                    return <div className={'ellipsis_120'}>{I(value.grant_date, '') ? formatDate(value.grant_date) : __('No grant date')}</div>
                },
                sorter: (a, b) => tableSort(a.grant_date, b.grant_date, 'date'),
            },
            {
                title: __('Exercise price'),
                dataIndex: 'exercise_price',
                align: 'left',
                sorter: (a, b) => {
                    const sharePriceA = this.priceShareOrderBy(a, 0);
                    const sharePriceB = this.priceShareOrderBy(b, 0);
                    return sharePriceA - sharePriceB;
                },
                render: (text, value) => {
                    return <div className={'ellipsis_140'}>{formatExercisePrice(value.exercise_price)}</div>;
                }
            },
            {
                title: __('Vesting schedule'),
                dataIndex: 'vesting_schedule_name',
                align: 'left',
                sorter: (a, b) => {
                    const stringA = a.vesting_schedule_name.toUpperCase();
                    const stringB = b.vesting_schedule_name.toUpperCase();
                    return stringA < stringB ? -1 : stringA > stringB ? 1 : 0;
                },
                render: (text) => <div className={'ellipsis_200'}>{text == "" ? __("No schedule") : text}</div>
            },
            {
                title: __('Vesting start'),
                dataIndex: 'vesting_start_date',
                align: 'left',
                render: (text, value) => {
                    return <div className={'ellipsis_120'}>{I(value.vesting_start_date, '') ? formatDate(value.vesting_start_date) : __('No start date')}</div>
                },
                sorter: (a, b) => {
                    const dataA = moment(a.vesting_start_date, 'DD/MM/YYYY').format('YYYYMMDD');
                    const dataB = moment(b.vesting_start_date, 'DD/MM/YYYY').format('YYYYMMDD');
                    return dataA - dataB;
                }
            },
        ];
    }

    /**
     * checkbox事件
     * @param selectedRowKeys
     * @returns {{onChange: StakeholdersTableModels.onSelectChange, selectedRowKeys}}
     */
    rowSelection = (selectedRowKeys) => ({
        selectedRowKeys,
        onChange: this.onSelectChange,
        renderCell: (checked, record, index, originNode) => this.securityHover(checked, record, index, selectedRowKeys)
    })

    /**
     * 表格checkbox自定义配置
     * @param checked
     * @param record
     * @param index
     * @param selectedRowKeys
     */
    securityHover = (checked, record, index) => {

        const { is_show_error } = record;
        return (
            <Fragment>
                {
                    is_show_error == 1 &&
                    <a className="signTipBox" >
                        <a>
                            <div className="error-tip-triangle"></div>
                        </a>
                        <p className={"sign-tiptext error-tiptext bg-dangered font_weight500 draft_error_tip" + index}>{__('Error')}</p>
                    </a>
                }
                <Checkbox checked={checked} onChange={this.checkChange.bind(this, record, index, checked)} ></Checkbox>
            </Fragment>
        );
    }

    /**
     * 表格checkbox change事件
     * @param record
     * @param index
     * @param boxChecked
     * @param e
     */
    checkChange = (record, index, boxChecked, e) => {
        const { checked } = e.target;
        const { selectedRowKeys } = this.state;
        const { updateSelectCount } = this.props;
        const { id } = record;
        let key_list = [...selectedRowKeys];
        if (checked) {
            key_list.push(id);
        } else {
            key_list.forEach((v, k) => {
                if (v == id) {
                    key_list.splice(k, 1);
                }
            })
        }
        updateSelectCount(key_list.length, [{ selectIndex: index, checked: checked }], key_list);
        this.setState(() => ({
            selectedRowKeys: key_list
        }))
    }

    /**
     * 全选change事件
     * @param selectedRowKeys
     */
    // onSelectChange = selectedRowKeys => {
    //     const { data, updateSelectCount } = this.props;
    //     const count = selectedRowKeys.length;
    //     if(!count){
    //         updateSelectCount(count, [], true);
    //     }else{
    //         let selectIndexArray = [];
    //         data.forEach((v, k) => {
    //             selectIndexArray.push({selectIndex:k, checked:true});
    //         })
    //         updateSelectCount(count, selectIndexArray);
    //     }
    //     this.setState({ selectedRowKeys });
    // };

    /**
     * 全选change事件
     * @param selectedRowKeys
     */
    onSelectChange = selectedRowKeys => {
        const { securityListsChange } = this.props;
        this.setState({ selectedRowKeys });
        securityListsChange(selectedRowKeys);
    };

    updateImg = (id, type) => {
        const { updateHoverShow } = this.state;
        updateHoverShow[id] = (type === 0);
        this.setState({updateHoverShow})
    }

    /**
     * 行点击事件
     * @param row
     * @param index
     * @returns {{onClick: onClick}}
     */
    onRow = (row, index) => {
        return {
            onClick: event => {
                const { selectedRowKeys } = this.state;
                const { transferKey } = this.props;
                this.setState({selectedRowKeys: []})
                transferKey(index);
            },
            onMouseEnter: event => {
                this.updateImg(row?.id, 0);
            },
            onMouseLeave: event => {
                this.updateImg(row?.id, 1);
            },
        }
    }

    /**
     * 行点击高亮样式设置
     * @returns {string}
     */
    setRowClassName = (value, index) => {

        const { listKey } = this.props;
        let rowClass = '';
        rowClass = index == listKey ? 'tr-row' : '';
        return value.is_show_error == 1 ? 'checked-error-state' : rowClass;

    }

    render() {
        const { loading, selectedRowKeys } = this.state;
        const { tableList } = this.props;
        return (
            <Table
                onRow={this.onRow}
                className="stake-table draft-options-table"
                rowClassName={this.setRowClassName}
                loading={loading}
                columns={this.tableColumns()}
                dataSource={tableList}
                rowKey="id"
                pagination={false}
                rowSelection={this.rowSelection(selectedRowKeys)}
            />
        );
    }
}
export default DraftOptionTable;