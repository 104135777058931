import React from 'react';
import { FormItem } from 'react-antd-formutil';
import { Checkbox, Radio, Tooltip } from 'antd'
import { omit } from 'lodash';
import './style.scss';

const options = [
    {
        label: 'Active stakeholders',
        value: 1
    },
    {
        label: __('Deactivated stakeholders'),
        value: 0
    },
    // {
    //     label: 'Not required',
    //     value: 2
    // }
]
const Group = Checkbox.Group

export default function ActiveStakeholders(props) {
    const { name, label } = props

    return <div className="filter-activestakeholders"><FormItem
        name={name || 'active'}
        label={label || __('Status')}
    >
        <Group {...omit(props, ['name', 'label'])} showSearch placeholder={__('Select')} className="filter-circle">
            <Checkbox value={1}> <span className="filter-circle-red"></span> {__('Active stakeholders')} <Tooltip placement="top" title={__('Stakeholders and/or tokenholders that own share option awards, equity, warrants, convertible notes, token warrants or token vestings.')}>
                <div className="help">?</div>
            </Tooltip></Checkbox>
            <Checkbox value={0}> <span className="filter-circle-green"></span> {__('Deactivated stakeholders')} </Checkbox>
        </Group>
    </FormItem>
    </div >
}