import React, { Component } from 'react';
import { post } from 'srcPath/http';
import { Spin, message, Table, Button, Checkbox } from 'antd';
import SignatureModal from 'components/SignatureModal';
import Modal, { ModalContent } from 'components/Modal';
import signIcon from '../images/sign.png';
import security from 'stores/security';
import { getOpenDetailFunc } from '../config'


import { formatNumber } from 'Utils'

import './style.scss';




export default class SignCertificate extends Component {
	state = {
		tableData: [],
		loading: true,
		selectedRows: []
	}

	modalRef = null

	componentDidMount() {
		this.getTableData()
	}

	componentWillUnmount() {
		this.modalRef && this.modalRef.close()
	}

	getTableData = async () => {
		this.setState({ loading: true })

		try {
			const { data: resData } = await post('getTaskList', { type: 'signCertificates' })

			const { code, data } = resData || {}

			if (code == 0) {
				this.setState({ tableData: data })
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}

		this.setState({ loading: false })
	}

	getColumns = () => {
		return [
			{
				title: __('Certificate'),
				dataIndex: 'certificateName',
				fixed: true,
				ellipsis: true
			},
			{
				title: __('Company'),
				dataIndex: 'companyName',

			},
			{
				title: __('Stakeholder'),
				dataIndex: 'shareHolderName',
				align: 'right',
			},
			{
				title: __('Shares/Tokens'),
				dataIndex: 'shares',
				align: 'right',
				render: text => formatNumber(text, 0)
			},
			{
				title: '',
				dataIndex: 'quantity',
				align: 'right',
				render: (text, record) => <Button type="link" onClick={() => { this.modalRef = getOpenDetailFunc(record.security_type)(record.certificateId, record.companyId) }}>{__('View')}</Button>
			},
		]
	}

	rowSelection = (selectedRowKeys, selectedRows) => {
		this.setState({
			selectedRows
		})
	}

	handleSelectAll = (event) => {
		const checked = event.target.checked;
		const { tableData } = this.state

		let selectedRows = [];

		if (checked) {
			selectedRows = tableData;
		}

		this.setState({ selectedRows })
	} 

	handleSign = () => {
		this.modalRef =  Modal.open({
			component: props => <ModalContent close={props.close} title="Sign and Send Certificates">
				<SignatureModal 
					data={(this.state.selectedRows || [] ).map(val =>({ admin_id: val.sign_user_id, security_id: val.certificateId, security_type: val.security_type }) )}
					apiType="signSecurity"
					close={props.close} 
					callback={this.callback} 
					btnText='Sign and Send to Shareholders'
				/>
			</ModalContent>,
			className: 'sprout-modal signature-modal',
			width: 600
		})		
	}

	callback = async () => {
		this.getTableData()
		this.setState({ selectedRows: [] })

		await security.getTaskData()
	}


	render() {
		const { tableData, loading, selectedRows } = this.state

	    const rowSelection = {
	      selectedRowKeys: selectedRows.map(val => val.taskId),
	      onChange: this.rowSelection,
	    };

		return <div className="task-sign-root">
{/*				 <div className="task-banner-wrap">
					<img src={signIcon} />
					{tableData.length > 0 &&<div>You have certificates to sign</div>}
				</div>
*/}			<Table 
				size="small" 
				columns={this.getColumns()}  
				rowKey="taskId" 
				dataSource={tableData} 
				pagination={false} 
				loading={loading}
				rowSelection={rowSelection}
				scroll={{ x: true }}
			/>
			<div className="task-sign-btns">
				 <Checkbox onChange={this.handleSelectAll} checked={ tableData.length && (selectedRows.length === tableData.length)}>{__('Select all')}</Checkbox>	
				 <Button disabled={selectedRows.length === 0}  type="primary" onClick={this.handleSign}>{__('Sign and send selected certificates')}</Button>
			</div>
		</div>
	}
}