import React from 'react';
import classnames from 'classnames';
import './style.scss';

export default function FormSection(props) {
	const { children, title, subtitle, className } = props

	return <div className={classnames('sprout-form-section', className)}>
		<div className="sprout-form-section-banner">
			<div className="sprout-form-section-title">
				{
					title
				}
				<div className="sprout-form-section-subtitle">{subtitle}</div>
			</div>
			<div className="sprout-form-section-line" />
		</div>
		<div className="sprout-form-section-content">
			{children}
		</div>
	</div>
}