import React, { Component, Fragment } from "react";
import {Radio, Input, Space, Tooltip, Popover} from 'antd';
import Modal from 'components/Modal';
import OptionPoolExample from "./OptionPoolExample";
import { CheckCircleFilled } from '@ant-design/icons';
import { I } from "Utils";

class OptionPoolModels extends Component {
    //optionPoolExample 浮层
    optionPoolExample  = null;
    constructor(props) {
        super(props);
        this.state = {
            preMoneyHelp : __('With the pre-money method, the option pool will be increased in the pre-money such that new investors will not be diluted from the option pool increase.'),
            postMoneyHelp : __('With the post-money method, the option pool will be increased post-money such that new investors do see dilution from the option pool increase'),

        }
        this.radioOnChange = this.radioOnChange.bind(this);
    }

    /**
     * Option Pool change事件
     * @param e
     */
    radioOnChange = (e) => {
        const { value } = e.target;
        const { optionPoolChange } = this.props;
        optionPoolChange(value);
    }

    /**
     * optionPool 案例浮层
     */
    getExampleModal = () => {
        this.optionPoolExample = Modal.open({
            component: props => <OptionPoolExample close={props.close} />,
            className: 'certificate-modal-wrap advance-terms-example',
            width: '700px',
            maskClosable: true
        })
    }

    /**
     * 卸载
     */
    componentWillUnmount() {
        this.optionPoolExample && this.optionPoolExample.close();
    }

    render() {
        const { optionPool } = this.props;
        return (
            <div className="advance-model-box">
                <CheckCircleFilled className="advance-model-option-circle" />
                <div className="advance-model-option-content">
                    <div>{__('Option pool')}</div>
                    <div>{__('Select whether an increase in the option pool should happen using the pre or post money valuation.')}&nbsp;
                        <a onClick={this.getExampleModal}>{__('Example')}</a>
                    </div>
                    <div>
                        <dl>
                            <dt>{__('Option pool')}</dt>
                            <dd>
                                <Radio.Group onChange={this.radioOnChange} value={I(optionPool, 2)}>
                                    <Space direction="vertical">
                                        <Radio value={2}>
                                            <span>{__('Pre-money')}</span>
                                            <Tooltip title={this.state.preMoneyHelp} color="#2b3946">
                                                <span className="table_icon title_icons">?</span>
                                            </Tooltip>
                                        </Radio>
                                        <Radio value={1} className="advance-model-radio">
                                            <span>{__('Post-money')}</span>
                                            <Tooltip title={this.state.postMoneyHelp} color="#2b3946">
                                                <span className="table_icon title_icons">?</span>
                                            </Tooltip>
                                        </Radio>
                                    </Space>
                                </Radio.Group>
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className="clear"></div>
                <div className="advance-model-height-1"></div>
            </div>
        )

    }
}

export default OptionPoolModels
