import React, { Component, Fragment} from "react";
import CreateNew from "./CreateNew";
import Detail from "./Detail";
import { I, formatWithArray } from 'Utils';
import {message} from "antd";
import { post } from 'srcPath/http';
import {__LANG} from '../Waterfall/Lang/en-us';
import Header from "../components/Header";

class Waterfall extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            loading : true,
            capTableLoading : true,
            waterfallLoading : true,
            viewResultsLoading : true,
            detailId : 0,
            capTableList : [],
            waterfallModelsList : [],
            __LANG : __LANG()
        }
        this.getWaterFallList = this.getWaterFallList.bind(this);
    }

    componentDidMount() {
        this.getWaterFallList().then(r => {});
    }

    /**
     * 获取 waterfall 列表
     * @returns {Promise<void>}
     */
    async getWaterFallList() {
        this.setState(()=>({
            loading : true,
            capTableLoading : true,
            waterfallLoading : true,
            viewResultsLoading : true,
        }))
        try {
            const { data: resData } = await post('getWaterFallList')
            const { code, data } = resData;
            if (code === 0) {
                //重新序列化 waterList;
                const waterList = this.serializationWaterList(I(data.waterList, []));
                this.setState(()=>({
                    loading : false,
                    capTableLoading : false,
                    waterfallLoading : false,
                    viewResultsLoading : false,
                    capTableList : I(data.roundList, []),
                    waterfallModelsList : waterList
                }));
            } else {
                message.error(data)
            }
        } catch (err) {
            message.error(err && err.message)
        }
    }

    /**
     * 更新loading
     */
    loadingChange = (key, value) => {
        let data = {};
        data[key] = value;
        this.setState(()=>(data))
    }

    /**
     * 重新序列化 waterList
     * @param waterList
     * @returns {[]}
     */
    serializationWaterList = (waterList) => {
        const { __LANG } = this.state;
        let newArray = [];
        waterList.forEach(( v, k) => {
            const exitValue = formatWithArray(v.exit_value);
            newArray.push({
                id : v.id,
                name : I(v.exit_value, '') && (__LANG.amountSymbol + exitValue +
                    __LANG.exitFor + v.exit_date ),
                exitValue : __LANG.amountSymbol + exitValue
            });
        });
        return newArray;
    }

    /**
     * waterfall 更新详情页ID
     * @param detailId
     */
    detailIdChange = (detailId) => {
        this.setState(() => ({ detailId }))
    }

    /**
     * 添加/删除 waterfall list
     */
    updateWaterfallModelsList = (type, data) => {
        const { waterfallModelsList } = this.state;
        let list = [...waterfallModelsList];
        if(type == 'add'){
            list.push(data);
        }else{
            list.splice(data, 1);
        }
        this.setState(() => ({
            waterfallModelsList : list
        }))
    }

    typeChange = (type) => {
        const { typeChange } = this.props;
        typeChange(type);
    }

    render() {
        const { detailId, capTableLoading, waterfallLoading, viewResultsLoading,capTableList,
            waterfallModelsList, loading } = this.state;
        const { history } = this.props;
        return (
            <Fragment>
                {!I(detailId, 0) && <Header history={history} path="waterfall" />}
                {
                    I(detailId, 0) &&
                    <Detail
                        capTableList={capTableList}
                        detailIdChange={this.detailIdChange}
                        detailId={detailId}
                        capTableLoading={capTableLoading}
                        typeChange={this.typeChange}
                        topLoading={loading}
                        getWaterFallList={this.getWaterFallList}
                        history={history}
                    /> ||
                    <CreateNew
                        detailIdChange={this.detailIdChange}
                        typeChange={this.typeChange}
                        capTableLoading={capTableLoading}
                        waterfallLoading={waterfallLoading}
                        viewResultsLoading={viewResultsLoading}
                        capTableList={capTableList}
                        waterfallModelsList={waterfallModelsList}
                        loadingChange={this.loadingChange}
                        getWaterFallList={this.getWaterFallList}
                        updateWaterfallModelsList={this.updateWaterfallModelsList}
                        history={history}
                    />
                }
            </Fragment>
        );
    }
}

export default Waterfall;