import moment from 'moment';
import security from 'stores/security';
import { language } from 'Utils/i18n';

const dateReg = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;

export const defaultDateFormat = 'DD MMM, YYYY'

export const DateSelectorFormat = {
	zh_CN: {
		'DD/MM/YYYY': 'DD/MM/YYYY',
		'MM/DD/YYYY': 'MM/DD/YYYY',
		'MMM DD, YYYY': 'YYYY/MM/DD',
		'DD MMM, YYYY': 'YYYY年MM月DD日',
	},
	zh_HK: {
		'DD/MM/YYYY': 'DD/MM/YYYY',
		'MM/DD/YYYY': 'MM/DD/YYYY',
		'MMM DD, YYYY': 'MMM DD, YYYY',
		'DD MMM, YYYY': 'DD MMM, YYYY',
	},
	zh_TW: {
		'DD/MM/YYYY': 'DD/MM/YYYY',
		'MM/DD/YYYY': 'MM/DD/YYYY',
		'MMM DD, YYYY': 'YYYY/MM/DD',
		'DD MMM, YYYY': 'YYYY年MM月DD日',
	},
	en_US: {
		'DD/MM/YYYY': 'DD/MM/YYYY',
		'MM/DD/YYYY': 'MM/DD/YYYY',
		'MMM DD, YYYY': 'MMM DD, YYYY',
		'DD MMM, YYYY': 'DD MMM, YYYY',
	}
}

// 格式化接口返回 ’DD/MM/YYYY‘ 格式的时间字符串，用于DatePicker (DateSelecotr) 初始化值 
// eg. date 值需要转递给时间选择组件， 该值存放再 state 里边，保存之前需要调用该函数进行格式化
export const formatDateForDatePicker = str => {
	if (dateReg.test(str)) {
		const strArray = str.split('/')
		const [D, M, Y] = strArray

		return moment(`${Y}-${M}-${D}`)
	}

	return str && moment(str).isValid() && moment(str)
}

// 时间参数提交给api 之前进行格式转换，接收 moment 对象 或者是合法的时间格式的字符串
export const formatDate2Api = momentData => {
	return moment(momentData).isValid() ? moment(momentData).format('DD/MM/YYYY') : ''
}

// 接口返回时间数据，用于页面显示（不赋值给时间组件） 
export const formatDate = str => {

	let format = (security.companyInfo && DateSelectorFormat[language][security.companyInfo.date_format] || defaultDateFormat)

	if (typeof str === 'number') {
		return moment(str).format(format)
	}

	const [date, time] = (str || '').split(' ')

	let correctDate = formatDateForDatePicker(date)


	if (correctDate && time) {
		format = format + ' HH:mm:ss'
		correctDate = moment(correctDate.format('YYYY-MM-DD') + ` ${time}`)
	}

	return correctDate ? correctDate.format(format) : '--'
}