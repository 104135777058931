import { message, Input, Button, Row, Col, Form, Space } from 'antd';
import React, { useState } from 'react';
import { FormItem } from 'react-antd-formutil';
import Modal, { ModalContent } from 'components/Modal';
import { useForm, withForm } from 'react-antd-formutil';
import { post } from 'srcPath/http';
import { printf } from 'Utils/i18n';
import formatShares from 'Utils/format/formatShares';
import Loading from 'components/Loading';

import './style.scss';

// interface Data {
//     name: string;
//     security_id: string;
//     security_type: 'COIN_GRANT' | 'COIN_WARRANT';
//     total: number;
//     quantity: number;
//     transactionId: string;
// }

const Operation = (props) => {
    const { grantData = [], warrantData = [], callback} = props.data || {}
    const { $params } = useForm()
    const disabledWarrant =  Object.values($params.grant || {}).filter(Boolean).length > 0
    const disabledGrant = Object.values($params.warrant || {}).filter(Boolean).length > 0

    const onSave = async () => {
        const commonData = grantData?.[0] || warrantData?.[0]
        const transactionId = commonData?.transactionId;

        if (!transactionId) {
            message.error(__('No data'))

            return
        }

        Loading.global(true)

        const params = Object.entries($params.grant || {}).map(([security_id, quantity]) => ({
            transaction_id: transactionId,
            security_type: 'COIN_GRANT',
            security_id,
            quantity: quantity || 0
        })).concat(
            Object.entries($params.warrant || {}).map(([security_id, quantity]) => ({
                transaction_id: transactionId,
                security_type: 'COIN_WARRANT',
                security_id,
                quantity: quantity || 0
            })))

        try {
            const { data: resData } = await post('bulkClaimQuantityFromTransaction', params)

            const { code, data } = resData || {}

            if (code === 0) {
                callback()
                props.close()
            } else {
                message.error(data)
            }
        } catch(error) {
            message.error(error.message)
        }

        Loading.global(false)
    }

    return <div className="token-transaction-operation">
        <Form layout="vertical">
        <Row gutter={24}>
            <Col style={{ borderRight: '1px solid #d9d9d9' }} span={12}>
                <h4>{__('Warrants')}{`(${warrantData.length})`}</h4>
                <div className="token-transaction-operation-wrap">
                    {
                        warrantData.map((val, key) => (
                            <FormItem
                                key={key}
                                $defaultValue={val.quantity}
                                name={`warrant.${val.security_id}`}
                                label={val.name}
                                max={Number(val.total || 0)}
                                help={printf(__('Up to %s can be claimable'), formatShares(Number(val.total) - ($params.warrant?.[val.security_id] || 0)))}
                                validMessage={{
                                    max: __('The input amount exceeded claimable amount.')
                                }}
                            >
                                <Input disabled={disabledWarrant} type='number' style={{ width: 320 }}/>
                            </FormItem>
                        ))
                    }
                </div>
            </Col>
            <Col span={12}>
                <h4>{__('Vestings')}{`(${grantData.length})`}</h4>
                <div className="token-transaction-operation-wrap">
                {
                        grantData.map((val, key) => (
                            <FormItem
                                key={key}
                                $defaultValue={val.quantity}
                                name={`grant.${val.security_id}`}
                                label={val.name}
                                max={Number(val.total || 0)}
                                help={printf(__('Up to %s can be claimable'), formatShares(Number(val.total) - ($params.grant?.[val.security_id] || 0)))}
                                validMessage={{
                                    max: __('The input amount exceeded claimable amount.')
                                }}
                            >
                                <Input disabled={disabledGrant} type='number' style={{ width: 320 }}/>
                            </FormItem>
                        ))
                    }
                </div>
            </Col>
        </Row>
        <div className="token-transaction-operation-btns">
            <Button size="large" disabled={!(Math.max(grantData.length, warrantData.length) > 0)} onClick={onSave} style={{ width: 200 }} type="primary">{__('Save')}</Button>
        </div>
        </Form>
    </div>
}

const Content = withForm(Operation)

export const openOperationModal = (data) => {
    return Modal.open({
        component: props => <ModalContent close={props.close} title={__('Vesting/Warrants')}>
            <Content data={data} close={props.close} dismiss={props.dismiss} />
        </ModalContent>,
        className: 'sprout-modal',
        width: 960
    })  
}

