import React, { Component } from 'react';
import { withForm, FormItem } from 'react-antd-formutil';
import Modal, { ModalContent } from 'components/Modal';
import { Input, Button, message, Table } from 'antd';
import { post } from 'srcPath/http';
// import { bindKeyEvent } from 'Utils';
import pageIcon from 'assets/images/meeting-icon.png';
import classnames from 'classnames';
import { LeftOutlined } from '@ant-design/icons';
import UploadFile from './components/UploadFile';

import './style.scss';


const { TextArea } = Input

const titleMap = {
	pdf: 'Add PDF file',
	link: 'Add External Link',
	text: 'Add Agenda item'
}

const typeMap = {
	pdf: 1,
	link: 2,
	text: 3
}

const columns = [
	{
		title: 'Name',
		dataIndex: 'attendees_name',
		render: text => text || '--'
	},
	{
		title: 'Email',
		dataIndex: 'email',
		render: text => text || '--'
	},
	{
		title: 'Role',
		dataIndex: 'role',
		render: text => text || '--'
	},
]

function processTableData(data) {
	const { board_members = [], attendees = [] } = data || {}
	let attendeeList = board_members.concat(attendees)

	attendeeList = attendeeList.map((val, index) => { return {...val, attendees_name: val.attendees_name || val.name, key: index} })

	return attendeeList || []
}

/*
	interface data {
		board_members: Array<any>;
		attendees: Array<any>;
		meeting_id: string | number;
		type: 'pdf' | 'link' | 'text'
	}
*/

class MeetingAgenda extends Component {
	attendeeList = processTableData(this.props.data)
	type = this.props.data && this.props.data.type // pdf link text
	state = {
		loading: false,
		addPermission: false,
		showMessage: false,
		textMessage: '',
		selectedRowKeys: []
	}

	componentDidMount() {
	}

	onSubmit = async () => {
		const { close, callback, $formutil } = this.props
		const { meeting_id } = this.props.data || {}
		const {  $invalid, $batchDirty, $params, $getFirstError } = $formutil

		if ($invalid) {
			$batchDirty(true)

			message.error($getFirstError())

			return
		}


		// 用于权限，暂时先不用
		// const members = this.attendeeList.filter(val => selectedRowKeys.includes(val.key)) || []
		// const board_members_notify = []


		// if (members.length > 0) {
		// 	members.forEach(val => {
		// 		const board_id = val.user_id
		// 		const attends_id = val.id

		// 		board_members_notify.push({ board_id, attends_id })
		// 	})
		// }

		this.setState({ loading: true })

		const { data: resData } = await post('addBoardMeetingItem', { meeting_id, type: typeMap[this.type], ...$params })

		const { code, data } = resData

		if(code == 0) {
			message.success('Successfully')

			callback && callback()
			close && close()
			this.setState({ loading: false })
		} else {
			message.error(data)
		}

		this.setState({ loading: false })
	}

	onSelectChange = selectedRowKeys => {
		this.setState({ selectedRowKeys })
	}

	setPermissions = () => {
		const { $formutil } = this.props
		const {  $invalid, $batchDirty, $params, $getFirstError } = $formutil

		if ($invalid) {
			$batchDirty(true)

			message.error($getFirstError())

			return
		}

		this.setState({ addPermission: true })
	}

	render() {
		const { loading, addPermission, selectedRowKeys, textMessage, showMessage} = this.state
		const { close, data = {} } = this.props

	    const rowSelection = {
	      selectedRowKeys,
	      onChange: this.onSelectChange,
	    };

		return <div className="meeting-agenda-root">
			<div className="sprout-modal-content">
				<div className="sprout-modal-header">
					{ addPermission && <LeftOutlined className="meeting-agenda-back" onClick={() => this.setState({ addPermission: false })} />}
					{titleMap[data.type || 'text']}
					<span className="close shut-btn sprout-modal-close" onClick={close}>X</span>
				</div>
				<div className="sprout-modal-detail">
					<div className={classnames('meeting-agenda-content', { 'meeting-agenda-content-transform': addPermission, 'meeting-agenda-content-reset': !addPermission })}>
						<div className="meeting-agenda-content-front">
							<div classnames="meeting-agenda-forms">
								<FormItem 
									label="Name"
									name="agenda_name"
									required
									validMessage={{
										required: 'Agenda name is required'
									}}						
								>
									<Input placeholder="e.g. Board Deck" />
								</FormItem>
								{
									this.type === 'pdf' && <FormItem 
										label="File"
										name="document"
										required
										validMessage={{
											required: 'File is required'
										}}						
									>
										<UploadFile />
									</FormItem>
								}
								{
									this.type === 'link' && <FormItem 
										label="Link"
										name="agenda_url"
										pattern={/^((ht|f)tps?):\/\/[\w\-]+(\.[\w\-]+)+([\w\-.,@?^=%&:\/~+#]*[\w\-@?^=%&\/~+#])?$/}
										required
										validMessage={{
											required: 'Video link is required',
											pattern: 'Please input full URL'
										}}							
									>
										<Input placeholder="e.g. http://sprout.zoom.hk" />
									</FormItem>
								}
								<FormItem 
									label="Agenda details"
									name="agenda_description"
									required
									validMessage={{
										required: 'Agenda details is required'
									}}
								>
									<TextArea rows={4} placeholder="e.g. Here we discuss the Q4 financials" />
								</FormItem>

							</div>
							<div className="meeting-agenda-btns">
								<Button onClick={this.setPermissions} type="primary">Next: Set Permissions</Button>
							</div>
						</div>
						<div className="meeting-agenda-content-end">
							<Table
								columns={columns}
								dataSource={this.attendeeList}
								pagination={false}
								rowSelection={rowSelection}
							/>
							<div className="meeting-agenda-btns">
								{
									<Button loading={loading} type="primary" onClick={this.onSubmit}>Save</Button>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	}
}

const Content = withForm(MeetingAgenda)

export default function openMeetingAgendaModal(data, callback) {
	return Modal.open({
		component: <Content data={data} callback={callback} />,
		width: 600,
		className: 'sprout-modal'
	})
}