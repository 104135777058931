import React from 'react';
import Section from '../components/Section';
import RoundTags from 'components/RoundTags';
import { Table } from 'antd';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import { formatDate } from 'Utils/format/formatDate';
import { formatCurrencyNumber } from 'Utils';
import { renderEllipsis } from 'components/TokenTransaction'

const renderValue = val => val || '--'

export default function ExerciseHistory(props) {
    const data = props.data || [];

	const column = [
		{
			title: __('Exercise Date'),
            dataIndex: 'exercise_date',
            className: 'nowrap'
		},
		{
			title: __('Quantity'),
            dataIndex: 'quantity',
            className: 'nowrap',
            align: 'right',
            render: text => formatFractionalNumber(text)
		},
		{
			title: __('Payment Date'),
			dataIndex: 'payment_date',
            align: 'center',
            className: 'nowrap',
			render: text => formatDate(text)
		},
        {
            title: __('Amnt to be collected'),
            dataIndex: 'amount_to_be_collected',
             className: 'nowrap',
            render: text => formatFractionalNumber(text)
        },
        {
            title: __('Payment Collected'),
            dataIndex: 'payment_collected',
             className: 'nowrap',
            render: text => formatFractionalNumber(text)
        },
        {
            title: __('Prepay Price'),
            dataIndex: 'prepay_price',
            className: 'nowrap',
            render: text => formatCurrencyNumber(text)
        },
        {
            title: __('Payment Method'),
            dataIndex: 'payment_method',
            className: 'nowrap',
            render: text => renderEllipsis(text, 120)
        },
        {
            title: __('Verfified By'),
            dataIndex: 'verified_by',
            className: 'nowrap'
        }
	]

	return <Section title="Transaction history">
		<div className="ea-transaction-table">
            <Table rowKey="txn_hash" size="small" columns={column} dataSource={data} pagination={false} scroll={{x: true}}/>
		</div>
	</Section>
}