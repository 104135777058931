import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import TokenReports from '../Token/Reports';
import { CommonReports, TaxReport,SbcReport } from 'B/components/Reports';
import security from 'stores/security';

export default class Reports extends Component {
	path = this.props.match.path
	showToken = (security.companyInfo && security.companyInfo.is_show_coin) === '1'

	config = security.hasFunction([
		{
			path: `${this.path}/token`,
			component: TokenReports,
			permission: 'all',
			exact: true
		},
		{
			path: `${this.path}/captable`,
			component: CommonReports,
			permission: 'all',
			exact: false
		},
		...(security.env === 'prod' ? [] :[
			{
				path: `${this.path}/tax_reports`,
				component: TaxReport,
				permission: 'all',
				exact: false
			},
		]),
	]);

	render() {
		const routeConfig = this.config.filter((value, key) => security.hasPermission(value.permission))

		return <div className="sprout-captable-root">
			<Switch>
				{
					routeConfig.map((val, index) => <Route key={index} exact={val.exact} path={val.path} component={val.component} />)
				}
				<Redirect to={routeConfig && routeConfig[0] && routeConfig[0].path || this.path} />
			</Switch>
		</div>
	}
}