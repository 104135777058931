import React, { Component, useState } from 'react';
import Loading from 'components/Loading'
import * as XLSX from 'xlsx'
import uploadIcon from '../images/upload.png'
import warningIcon from '../images/warning.png'
import classnames from 'classnames';
import moment from 'moment';
import { post } from 'srcPath/http';
import { formatDate, formatDate2Api } from 'Utils/format/formatDate';
import { I, formatCurrencyNumber } from 'Utils';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import country from 'components/CountrySelector/config';
import { relationshipList } from "Utils/constant";
import {DeleteOutlined} from "@ant-design/icons";
import { printf } from 'Utils/i18n';

import { Upload, Button, message } from 'antd';
const { Dragger } = Upload;
class EnterSarsInfomation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],			 //表格数据
            shares_title: {},	 //表头
            addStatus: 0,		 //add按钮禁止点击   0禁止  1允许
            excelStatusShow: 0,  //表格显示  0 不显示   1显示
            fileName: '',        //上传文件名
            titleRequiredRrr: [],//必填项标题未填
            tableErr: false,      //table前端验证
            tableErrInfo: '',		 //验证内容

            historyList: [],		 //历史数据列表
            isOpen: false,        //展开历史数据
            EquityPlanNameList: [],  //EquityPlanNameList 列表
            shareClassNameErr: [],   //share name 错误列表
            vesting_schedule_list: [],//验证vesting_schedule
            shares_title_arr: [],   //表头数组
            cn_securities_list: [],


            errorList: [],
            custom_id_list: [],
            StakeholderType: ["Non-individual", 'Individual'],
            EaType: ["NSO", 'INTERNATIONAL', 'ISO'],
            BoardApprovalStatus: ["Board approved", 'Not board approved'],
            stakeholderRelationshipList: [
                "Advisor",
                "Board member",
                "Consultant",
                "Employee",
                "Ex-employee",
                "Executive",
                "Founder",
                "International employee",
                "Investor",
                "Officer",
            ],
            genderList: ['Female', 'Male', 'Non-binary / non-conforming', 'Prefer not to respond'],

            terminationList: ["Day(s)", "Month(s)", "Year(s)"],
            // ['Stakeholder type','Issue date relationship','Country / Region','Gender']  下拉选项
            downloadInfo: {}
        }
    }
    componentDidMount() {
        this.getTableList()
    }
    getTableList = () => {
        Loading.global(true)
        post('getDraftExcelTitle').then(res => {
            let { data } = res.data || ''
            let { sar: shares_title } = data || {}
            let shares_title_arr = Object.keys(shares_title)
            if (res.data.code === 0) {
                this.setState({
                    shares_title,
                    shares_title_arr
                })
            }
        }).catch(function (error) {
        });
        post('getDateOfIncorporation', { withEaPlanName: 1 }).then(res => {
            let { data } = res.data || ''
            let { eaPlanNameList, vesting_schedule_list, cn_securities_list } = data || {}
            let EquityPlanNameList = eaPlanNameList
            if (res.data.code === 0) {
                this.setState({
                    EquityPlanNameList,
                    vesting_schedule_list,
                    cn_securities_list
                })
            }
        }).catch(function (error) {
        });
        post('getBulkUploadTemplates').then(res => {
            let { data } = res.data || {}
            this.setState({
                downloadInfo: data.find(item => item.type == 'SARs')
            })
        }).catch(function (error) {
        });
        Loading.global(false)
    }
    handleDropFile = (e) => {
        // console.log(e, 'handleDropFile');
    }
    handleBeforeUpload = (e) => {
        return false
    }
    // 初始化数据
    bincModelCancel = () => {
        this.setState({
            data: [],
            tableData: [],
            addStatus: 0,
            excelStatusShow: 0,
            fileName: '',
            titleRequiredRrr: [],
            tableErr: false,
            tableErrInfo: '',
            shareClassNameErr: [],

            titleRequiredRrrWeb: [],
            shareClassNameErrWeb: [],
            errorListWeb: [],
            errorList: []
        })
    }
    handleChangeFile = (info) => {
        this.bincModelCancel()
        let { shares_title, EquityPlanNameList, vesting_schedule_list, cn_securities_list, StakeholderType, stakeholderRelationshipList, genderList, BoardApprovalStatus, EaType, terminationList } = this.state
        let shares_title_arr = [...this.state.shares_title_arr]
        let titleLength = shares_title_arr.length
        let shares_title_list = [...shares_title_arr]
        let that = this
        let file = info.file//获取第一个文件
        let name = file.name.split('.')[file.name.split('.').length - 1].toLowerCase()
        if (name !== 'xls' && name !== 'xlsx') {
            this.setState({
                tableErr: true,
                tableErrInfo: 'Error parsing the file! Please make sure you are using the correct template.',
                fileName: file.name
            })
            return
        }
        Loading.global(true)
        let reader = new FileReader()
        reader.readAsBinaryString(file)//读取这个文件
        reader.onload = function (event) {
            try {
                let result = event.target.result
                let xlsxdata = XLSX.read(result, { type: 'binary' })//读取xlsx
                let n = "SARs draft set"
                let col = XLSX.utils.sheet_to_json(xlsxdata.Sheets[n], { header: 1, defval: '', blankrows: true })//解析为数组
                let data = []
                let titleData = []
                if (col.length == 0) {
                    that.setState({
                        tableErr: true,
                        tableErrInfo: 'Error parsing the file! Please make sure you are using the correct template.',
                        fileName: file.name
                    })
                    return
                }
                col = col.slice(0, 500)
                col.forEach((item, index) => {
                    if (index == 1) {
                        let newArr = []
                        for (let i = 0; i < titleLength; i++) {
                            newArr = [...newArr, col[index][i]]
                        }
                        data.push(newArr)
                    } else if (index == 2) {
                        let newArr = []
                        for (let i = 0; i < titleLength; i++) {
                            newArr = [...newArr, col[index][i]]
                        }
                        titleData = (newArr)
                    } else if (index == 0 || index == 3) {
                        let newArr = []
                        for (let i = 0; i < titleLength; i++) {
                            newArr = [...newArr, '']
                        }
                        data.push(newArr)
                    }else {
                        let newArr = []
                        for (let i = 0; i < titleLength; i++) {
                            let name = shares_title_list[i]
                            let dataInfo = col[index][i]
                            if (name == 'Expiration date' || name == 'Date of birth' || name == 'Board approval date' || name == 'Grant date') {
                                newArr = [...newArr, dataInfo ? that.formatExcelTime(dataInfo) : '']
                            } else if (name == 'Vesting start date') {
                                col[index][i - 1] ? newArr = [...newArr, dataInfo ? that.formatExcelTime(dataInfo) : ''] : newArr = [...newArr, '']
                            } else {
                                newArr = [...newArr, (dataInfo)]
                            }
                        }
                        newArr.join('') !== '' && data.push(newArr)
                    }
                })
                data = data.filter(item => item.join('') !== '')

                if (data.length < 2) {
                    that.setState({
                        tableErr: true,
                        tableErrInfo: 'Form is required - content is empty!',
                        fileName: file.name
                    })
                    return
                }
                let table_arr_title = data[0]
                let diffHeader = JSON.stringify(table_arr_title) === JSON.stringify(shares_title_arr)
                if (!diffHeader) {
                    that.setState({
                        tableErr: true,
                        tableErrInfo: 'Error parsing the file! Please make sure you are using the correct template.',
                        fileName: file.name
                    })
                    return
                }
                that.setState({
                    tableData: data,
                    fileName: file.name,
                    excelStatusShow: 1,
                    titleData
                })
                let titleRequiredRrr = []
                let shareClassNameErr = []
                let errorList = []
                for (let i = 0; i < data.length; i++) {
                    for (let j = 0; j < data[i].length; j++) {
                        let name = shares_title_list[j]
                        let zhName= titleData[j]
                        let dataInfo = data[i][j]
                        if (!dataInfo && dataInfo !== 0) {
                            if (shares_title[name] == "required") {
                                errorList.push({ "col": j, "row": i, "msg":  printf(__("%s is required!"), zhName) })
                            }
                            if (i > 0 && name == 'Vesting start date') {
                                data[i][j - 1] && errorList.push({ "col": j, "row": i, "msg": printf(__("%s is required!"), zhName) })
                            }
                        } else if (i > 0 && name == "Equity plan") {
                            let status = EquityPlanNameList.findIndex(item => item == dataInfo)
                            if (status == -1) {
                                errorList.push({ "col": j, "row": i, "msg": printf(__("%s is not an existing equity plan."), dataInfo) })
                            }
                        } else if (i > 0 && name == 'Vesting schedule') {
                            let status = vesting_schedule_list.findIndex(item => item == dataInfo)
                            if (status == -1) {
                                errorList.push({ "col": j, "row": i, "msg": printf(__("%s is not an existing vesting schedule."), dataInfo) })
                            }
                        }  else if (i > 0 && (name == 'Exercise price' || name == 'Annual salary')) {
                            if (formatCurrencyNumber(dataInfo) == '--') {
                                errorList.push({ "col": j, "row": i, "msg":  printf(__("%s must be a number."), zhName)})
                            }
                        } else if (i > 0 && name == 'Quantity') {
                            if (formatFractionalNumber(dataInfo) == '--') {
                                errorList.push({ "col": j, "row": i, "msg": printf(__("%s must be a number."), zhName) })
                            }
                        }

                    }
                }
                if (errorList.length > 0) {
                    that.setState({
                        errorListWeb: errorList
                    })
                }
                that.checkSubmit('check')
                // }
            } catch (err) {

            }

        }
        Loading.global(false)
    }
    downloadTemplate = async (e) => {
        let { downloadInfo } = this.state
        var a = document.createElement('a')
        a.download = ''
        try {
			const { data: resData } = await post('getTemplateDownloadUrl', { type: downloadInfo.type })
			const { code, data } = resData || {}
            a.href = data.download_url;
            a.click()
		} catch (err) {

		}
    }
    formatExcelTime = (num) => {
        num = Number(num);
        let millisecond = (num - 25569) * 60 * 60 * 24 * 1000;
        let data = moment(millisecond).format('DD/MM/YYYY')

        return formatDate(data) == '--' ? '--' : data
    }
    bindGetOpen = () => {
        let { isOpen } = this.state
        this.setState({
            isOpen: !isOpen
        })
    }
    checkSubmit = (type) => {
        const { close, callback } = this.props
        let { tableData, shares_title_arr, errorListWeb } = this.state
        let arr = ['Employee ID', 'Job title', 'Annual salary', 'Passport or National ID', 'Country/ Region', 'Date of birth', 'Gender', 'Address details']
        let shares_title = [...shares_title_arr, ...arr]
        let dataList = []
        for (let i = 1; i < tableData.length; i++) {
            let obj = {}
            for (let j = 0; j < tableData[i].length; j++) {
                let name = shares_title[j]
                if (name == "Stakeholder type") {
                    tableData[i][j] == "Non-individual" ? obj[name] = 0 : obj[name] = 1
                } else if (name == "Email") {
                    obj[name] = tableData[i][j].toString().trim()
                } else if (name == "Exercise price") {
                    obj[name] = tableData[i][j]
                } else {
                    obj[name] = tableData[i][j].toString()
                }
            }
            dataList.push(obj)
        }
        Loading.global(true)
        let data_list = { ...dataList }
        post('addDraftSarSecurities', { data_list, type }).then(res => {
            let { data, code } = res.data || []
            let { error_list = [], error_msg = [], custom_id_list = [] } = data || []
            if (type == 'check') {
                if (errorListWeb.length > 0 || code == 1) {
                    error_list && error_list.forEach(item => item.row = item.row + 1)
                    let error_arr = [...error_list, ...errorListWeb]
                    error_arr.sort(function (a, b) {
                        var value1 = a.row,
                            value2 = b.row;
                        if (value1 === value2) {
                            return a.col - b.col
                        }
                        return value1 - value2;
                    });
                    this.setState({
                        custom_id_list,
                        errorList: error_arr,
                        addStatus: 0,
                        data: tableData
                    })
                    Loading.global(false)
                } else {
                    this.setState({
                        custom_id_list,
                        addStatus: 1,
                        data: tableData
                    })
                    Loading.global(false)
                }
            }
            if (type == 'add') {
                if (code === 0) {
                    callback && callback()
                    close()
                    Loading.global(false)
                } else if (code == 1) {
                    error_list.forEach(item => item.row = item.row + 1)
                    let error_arr = [...error_list, ...errorListWeb]
                    error_arr.sort(function (a, b) {
                        var value1 = a.row,
                            value2 = b.row;
                        if (value1 === value2) {
                            return a.col - b.col
                        }
                        return value1 - value2;
                    });
                    this.setState({
                        custom_id_list,
                        errorList: error_arr,
                        addStatus: 0,
                        data: tableData
                    })
                    Loading.global(false)
                } else {
                    message.error(data)
                    Loading.global(false)
                }
            }
        }).catch(function (error) {
            Loading.global(false)
        });
    }
    getErrClass = (key, k) => {
        let errorList = this.state.errorList
        let status = errorList.findIndex(item => item.row == key && item.col == k)
        if (status !== -1) {
            return 'active-bac'
        }
    }
    getTextParams = (key, k, value) => {
        let { shares_title_arr,titleData } = this.state
        let titleArr = [...shares_title_arr]
        let name = titleArr[k]
        let { custom_id_list } = this.state
        if (key > 0) {
            for (let i = 0; i < custom_id_list.length; i++) {
                if (key - 1 == custom_id_list[i].row && k == custom_id_list[i].col) {
                    return custom_id_list[i].custom_id
                }
            }
            if (name == 'Exercise price' || name == 'Annual salary') {
                return value && formatCurrencyNumber(value)
            } else if (name == 'Quantity') {
                return value && formatFractionalNumber(value)
            } else if (name == 'Board approval date' || name == 'Grant date' || name == 'Expiration date' || name == 'Vesting start date' || name == 'Date of birth') {
                return value && formatDate(value)
            } else {
                return value
            }
        } else {
            return titleData[k]
        }
    }
    bindGoBack = () => {
        const { close, callback } = this.props
        close()
    }
    render() {
        const { addStatus, excelStatusShow, fileName, tableErr, tableErrInfo, errorList, downloadInfo } = this.state
        return <div className="modal-shareholder">
            <div className="modal-body uploadDoc-body">
                <div className="modal-top">
                    <div className="modal-top-date">
                        <button onClick={this.downloadTemplate} type="button" className="btn boxbtn-to bg-darkblue wdt200">{__('Download template')}</button>
                        <div>{printf(__('Last updated on %s'), formatDate(downloadInfo.last_update_time))}</div>
                    </div>
                    {fileName && <div className="excel-del">
                        <div className="excel-name">{fileName}</div>
                        <div className="excel-del-btn" onClick={this.bincModelCancel}>
                            <DeleteOutlined style={{color: '#fff'}} />
                        </div>
                    </div>}
                </div>
                <div className='purchased'>
                    <div className="dragger">
                        <Dragger onDrop={this.handleDropFile} action='' onChange={this.handleChangeFile} beforeUpload={this.handleBeforeUpload} showUploadList={false}>
                            <div className="ant-upload-drag-icon">
                                <img src={uploadIcon} alt="" className="upload-icon" />
                            </div>
                            <div className="ant-upload-text">{__('Choose a file or drop here to import spreadsheet')}</div>
                        </Dragger>
                    </div>
                </div>
                {tableErr && <div className="missing-field"> <img src={warningIcon} alt="" />{tableErrInfo}</div>}
                {excelStatusShow == 1 &&
                    <div>
                        <div className="line"></div>
                        <div className="new-hotTable">
                            <table>
                                <tbody>

                                    {this.state.data.map((item, key) => {
                                        return (<tr key={key} className={classnames({ 'new-hotTable-body': key % 2 == 0 }, { 'new-hotTable-header': key == 0 })}> {item.map((params, k) => {
                                            return (
                                                <td key={k} className={this.getErrClass(key, k)}>

                                                    {this.getTextParams(key, k, params)}

                                                </td>)
                                        })}
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
                <div className="title-required-err">
                    {errorList && errorList.map(item => {
                        return (<div className="required-err-list"><img src={warningIcon} alt="" />{item.msg}</div>)
                    })}
                </div>
            </div>

            <div className="modal-footer box-footer no-paddingtop">
                <button onClick={this.checkSubmit.bind(this, 'add')} id="checkSubmitButton" type="button" className={classnames("btn boxbtn-to bg-darkblue wdt200 ", { 'prohibit-btn': addStatus == 0 })}>Upload</button>
            </div>


        </div>
    }
}

export default EnterSarsInfomation