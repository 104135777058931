import React, { Component } from 'react';
import { Select, message, Spin } from 'antd';
import { post } from 'srcPath/http';
import { debounce, omit } from 'lodash';
import { language } from "../../modules/Utils/i18n";

const Option = Select.Option;
class SelectCostCenter extends Component {
    state = {
        options: [],
        loading: false,
        getTimesTamp: + new Date(),

    };

    componentDidMount(){
        // const { cost_list } = this.props;
        // this.setState({options: cost_list })
        const cost_list = [
            {
                "id": "1",
                "en_us": "Marketing department",
                "zh_cn": "营销部门",
                "zh_tw": "營銷部門"
            },
            {
                "id": "2",
                "en_us": "Human resources",
                "zh_cn": "人力资源",
                "zh_tw": "人力資源"
            },
            {
                "id": "3",
                "en_us": "Research and development",
                "zh_cn": "研究与开发",
                "zh_tw": "研究與開發"
            },
            {
                "id": "4",
                "en_us": "Work office",
                "zh_cn": "工作办公室",
                "zh_tw": "工作辦公室"
            },
            {
                "id": "5",
                "en_us": "Quality assurance",
                "zh_cn": "质量保证",
                "zh_tw": "質量保證"
            },
            {
                "id": "6",
                "en_us": "Engineering",
                "zh_cn": "工程",
                "zh_tw": "工程"
            },
            {
                "id": "7",
                "en_us": "Logistics",
                "zh_cn": "后勤",
                "zh_tw": "後勤"
            },
            {
                "id": "8",
                "en_us": "Procurement",
                "zh_cn": "采购",
                "zh_tw": "採購"
            },
            {
                "id": "9",
                "en_us": "Others",
                "zh_cn": "其他",
                "zh_tw": "其他"
            }
        ]
        this.setState({options: cost_list })
    }

    onChange = id => {
        const { options } = this.state;
        const { onChange } = this.props;
        onChange && onChange(
            id,
            options.find(item => item.id === id)
        );
    };

    onSelect = id => {
        const { options } = this.state;
        const { onSelect } = this.props;
        onSelect &&
            onSelect(
                id,
                options.find(item => item.id === id)
            );
    };

    render() {
        const { options = [], getTimesTamp } = this.state;

        return (
            <Select
                {...omit(this.props)}
                placeholder={__("Select cost center")}
                style={{ width: '100%' }}
                allowClear
                onChange={this.onChange}
                onSelect={this.onSelect}
                dropdownMatchSelectWidth={false}
                optionLabelProp="label"
            >
                {options.map((item, index) => (
                    <Option key={getTimesTamp + index} value={item.id} label={ language =='zh_CN' ? item.zh_cn : (language == 'en_US' ? item.en_us : item.zh_tw )}>
                        <div style={{ minWidth: 80, marginRight: 10 }}>{ language =='zh_CN' ? item.zh_cn : (language == 'en_US' ? item.en_us : item.zh_tw )}</div>
                    </Option>
                ))}
            </Select>
        );
    }
}

export default SelectCostCenter;
