import React from 'react';
import Utils, { I,filterAmount } from "Utils";
import { post } from 'srcPath/http';
import moment from "moment";
import DateSelector from "../../../../../components/DateSelector";
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import security from 'stores/security';

class CreateEquityAwards extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            selectFile:[
                {name:__(__("No file selected"))}
            ],
            dateFormat:"DD/MM/YYYY",
            createStatus:0,
            exceedErrorText:"exceeds total authorized shares",
            curAuthorizedShares:10000,
            existingPrefix:["abc"],
            existingPlanName:["abc"],
            data:{
                plan_name:"",
                share_class_id: "",
                prefix: "",
                size: "",
                plan_file:{name:__("No file selected"),file_obj:""},
                board_approval_date: "",
                shareholder_approval_date: "",
                terms_of_plan: "15 years",
                option_grant_expiration: {years:"10 years",minus_checked:false},
                exercise_periods: [
                    {title:__("Voluntary termination"),postKey:"voluntary_termination",value:true,num:"3",numType:"Months"},
                    {title:__("Involutary termination"),postKey:"involuntary_termination",value:false, num:"3",numType:"Months"},
                    {title:__("Termination with cause"),postKey:"involuntary_termination_cause",value:false, num:"1",numType:"Months"},
                    {title:__("Death"),value:false,postKey:"death_exercise", num:2,numType:"Years"},
                    {title:__("Disability"),value:false,postKey:"disability_exercise", num:1,numType:"Years"},
                    {title:__("Retirement"),value:false,postKey:"retirement_exercise", num:1,numType:"Years"},
                ],
                repurchase_periods: {num:"",numType:"Months"}
            },
            cs_plan_list:[],
            repurchasedStatus:false,
            showErrorInfo:"dontshow",
            isShowEmptyError:0,
            isShowExceedErrorInfo:0,
            requiredKey:["plan_name","share_class_id","prefix","size"],
            numberOfYears:[
                "-----" ,
                "1 year",
                "2 years",
                "3 years",
                "4 years",
                "5 years",
                "6 years",
                "7 years",
                "8 years",
                "9 years",
                "10 years",
                "11 years",
                "12 years",
                "13 years",
                "14 years",
                "15 years",
                "16 years",
                "17 years",
                "18 years",
                "19 years",
                "20 years",
            ],
        }
    }

    backToAddMore=()=>{
        //close:equityAwardsModal
        //open: setStockModal
        window.$("#equityAwardsModal").modal("hide");
        $("#button-2-1 button").trigger("click")
    }

    setFileName=(e)=>{
        let fileObj = e.target.files[0];
        let curData = this.state.data;
        Utils.uploadFile(fileObj).then((result)=>{
            curData.plan_file.name=fileObj.name;
            curData.plan_file.file_obj= result;
            this.setState ({
                data:curData
            });
            $(".create_equity-file").addClass("eqfile-box");
            $(".create_file-del").removeClass("hidden")
        })
    }
    fileDelete =()=>{
        var curData = this.state.data;
        curData.plan_file.name = "No file selected";
        curData.plan_file.file_obj = "";
        this.setState ({
            data:curData
        });
        $(".create_equity-file").removeClass("eqfile-box");
        $(".create_file-del").addClass("hidden");
    }

    initPageData=()=>{
        let requestData = {};
        requestData.withShareListType = "CS";
        requestData.withPrefix = 1;
        requestData.withPlanName = 1;
        post('getDateOfIncorporation',requestData).then((response)=> {
            if(response.data.code === 0){
                this.setState({
                    cs_plan_list:response.data.data.plan_list,
                    existingPrefix:response.data.data.prefixList,
                    existingPlanName:response.data.data.planNameList,
                });
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });

    }

    initStep=()=>{
        // this.props.history.push({pathname:"/home"});
    }

    changeStatus=(key,e)=>{
        let curData = this.state.data;
        curData.exercise_periods[key].value = !curData.exercise_periods[key].value;
        this.setState({
            data:curData
        })
    }

    setExercisePeriod(key,valueKey,e){ //第几个元素以及修改的是哪个值(num or numType)
        let curData = this.state.data;
        if(valueKey=="numType"){
            curData.exercise_periods[key][valueKey] = e.target.value;
        }else{
            curData.exercise_periods[key][valueKey] = Utils.checkNumInput(e.target.value);
        }
        this.setState({
            data:curData
        })
    }
    setRepurchasePeriods=(valueKey,e)=>{
        let curData = this.state.data;
        curData.repurchase_periods[valueKey] = Utils.checkNumInput(e.target.value);
        this.setState({
            data:curData
        })
    }
    optionGrantExpiration=(valueKey,e)=>{
        let curData = this.state.data;
        if(valueKey === "minus_checked"){
            curData.option_grant_expiration[valueKey] = !curData.option_grant_expiration[valueKey];
        }else{
            curData.option_grant_expiration[valueKey] = e.target.value;
        }
        this.setState({
            data:curData
        })
    }

    setRepuchaseState=()=>{
        this.setState({
            repurchasedStatus:!this.state.repurchasedStatus
        })
    }

    createEaPlan=()=>{
        localStorage.setItem("isLocalEaData","yes");
        localStorage.setItem("localEaData",JSON.stringify(this.state.data));
        // var postFlag = 0;
        // for(let i=0;i<this.state.selectFile.length;i++){
        //     if(this.state.selectFile[i].name !== "No file selected"){
        //         postFlag = 1;
        //     }
        // }
        if(this.state.createStatus==1){
            return
        }
        this.setState({
            createStatus:1
        })
        let requestData = this.state.data;
        post('createEaPlan',requestData).then((response)=> {
            if(response.data.code === 0){
                window.location.reload();
                // this.setState({
                //     createStatus:0
                // })
                // window.$("#createEquityAwardsModal").modal("hide");
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });

    }
    recordData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        })
    }
    /**
     * input change通用事件
     * @param isDecimal 是否需要小数
     * @param e
     * 5 digit
     */
    inputChange5Digit = (isDecimal,e) => {
        const { target: element } = e;
        const { name: dataKey } = element;
        const filter = filterAmount(e, 9999999999999, isDecimal, 5);
        let curData = this.state.data;
        curData[dataKey] = filter.value;
        this.setState({
            data: curData
        }, () => {
            element.selectionStart = element.selectionEnd = filter.cursorPositionX;
        });
    }
    showErrorList=(emptyCheck,selectCheck,e)=>{
        console.log(emptyCheck,selectCheck)
        this.state.requiredKey.map((value,key)=>{
            if(this.state.data[value] ==""){
                window.$("#"+value).addClass("has-error");
            }else{
                window.$("#"+value).removeClass("has-error");
            }
        });
        this.setState({
            isShowEmptyError:emptyCheck,
            showErrorInfo:selectCheck
        });
    }

    eaDateChange = (key, moment_key, date) => {
        const { dateFormat } = this.state;
        let curData = this.state.data;
        curData[key] = date.format( dateFormat);
        curData[moment_key] = date;
        this.setState({
            data:curData
        });
    }

    render(){
        let errorCheck = "dontshow";
        if(this.state.repurchasedStatus && this.state.data.repurchase_periods.num.length<1){
            errorCheck = "show";
        }
        this.state.data.exercise_periods.map((val,key)=>{
            if(val.value === true && val.num.length===0){
                errorCheck = "show";
            }
        })
        let isShowEmptyError = 0;
        if(this.state.data.plan_name.length===0|| this.state.data.share_class_id.length===0 || this.state.data.prefix.length===0 || this.state.data.size.length===0){
            isShowEmptyError = 1;
        }

        let showButton="";
        if(errorCheck==="dontshow" && isShowEmptyError===0){
            showButton = <button onClick={this.createEaPlan}  className="btn boxbtn-to bg-darkblue update-btn">{this.state.createStatus == 0 ? __("Create"):__("Creating")}</button>
        }else{
            showButton = <button onClick={this.showErrorList.bind(this,isShowEmptyError,errorCheck)} className="btn boxbtn-to bg-darkblue update-btn">{__('Create')}</button>;
        }
        let isLegalPrefix = 1; //默认为合法的输入
        for(let i=0;i<this.state.existingPrefix.length;i++){
            if(this.state.data.prefix == this.state.existingPrefix[i]){
                isLegalPrefix = 0;  //有重复改为非法
            }
        }
        let isLegalPlanName = 1; //默认为合法的输入
        for(let i=0;i<this.state.existingPlanName.length;i++){
            if(this.state.data.plan_name == this.state.existingPlanName[i]){
                isLegalPlanName = 0;  //有重复改为非法
            }
        }
        if(isLegalPrefix === 0 || isLegalPlanName ===0){
            //重复的prefix，按钮无任何反应
            showButton = <button  className="btn boxbtn-to bg-darkblue update-btn">Create</button>
        }
        const { data, dateFormat } = this.state;
        let isFractionalShare = security.companyInfo.fractional_control;
        return(
            <div className="modal fade" id="createEquityAwardsModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="modal-dialog dialog-top40">
                    <div className="modal-content equityAwards-content">
                        <div className="modal-header box-header">
                            <button type="button" onClick={this.initStep} className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Stock option awards plan creation')}</h4>
                        </div>
                        <div className="modal-body equity-body">
                            <div>
                                <div className="equity-item-header bg-seablue">
                                    <h5>{__('Basic plan information')}</h5>
                                </div>
                                <form className="form-horizontal form_bg">
                                    <div className="form-group ">
                                        <label className="col-xs-5 add-label">{__('Equity plan name')}</label>
                                        <div id="plan_name" className="col-xs-7">
                                            <input name="plan_name" onChange={this.recordData} value={this.state.data.plan_name} className="form-control" type="text" placeholder="e.g. Roots Tech Equity Incentive Plan" />
                                        </div>
                                    </div>
                                    <div className="form-group ">
                                        <label className="col-xs-5 add-label">{__('Common share class')}</label>
                                        <div className="col-xs-7" id="share_class_id">
                                            <select name="share_class_id" onChange={this.recordData} value={this.state.data.share_class_id} className="form-control select-container select-bgwhite">
                                                <option value="">--</option>
                                                {Object.values(this.state.cs_plan_list).map((value,key)=>{
                                                    return(
                                                        <option key={key} value={value.id}>{value.share_class_name} ({value.prefix})</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <p className="text-muted col-xs-7 col-xs-push-5">{__('When options are issued, this will be the share class used to issue them.')}</p>
                                    </div>
                                    <div className="form-group ">
                                        <label className="col-xs-5 add-label">{__('Prefix')}</label>
                                        <div id="prefix" className="col-xs-4">
                                            <input name="prefix" onChange={this.recordData} value={this.state.data.prefix} className="form-control uppercase_form" type="text" placeholder="EA" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div>
                                <div className="equity-item-header bg-seablue">
                                    <h5>{__('Plan details')}</h5>
                                </div>
                                <form className="form-horizontal form_bg">
                                    <div className="form-group ">
                                        <label className="col-xs-5 add-label">
                                            {__('Plan size')}
                                            <a className="convertible-ques">
                                                <p className="convertible-tiptext tiptext-top86">{__('We recommend the plan size to be 10-20% of Total Authorized Shares.')}</p>
                                            </a>
                                        </label>
                                        <div id="size" className="col-xs-4">
                                            {isFractionalShare == 1 ?
                                                <input name="size" onChange={this.inputChange5Digit.bind(this, true)}
                                                       value={this.state.data.size} className="form-control" type="text"
                                                       placeholder="e.g. 1,500,000"/>:
                                                <input name="size" onChange={this.inputChange5Digit.bind(this, false)}
                                                       value={this.state.data.size} className="form-control" type="text"
                                                       placeholder="e.g. 1,500,000"/>
                                            }
                                        </div>
                                        {/*{isExceedMaxSize === 1 &&<p style={{color:"red"}}>{this.state.exceedErrorText}</p>}*/}
                                    </div>
                                    <div className="form-group ">
                                        <div className="col-xs-5">
                                            <label className=" add-label">{__('Equity incentive plan')}<br /><i className="text-muted">- {__('Optional')}</i></label>
                                        </div>
                                        <div className="col-xs-4 ">
                                            <div className="select-btn select-blue equ-choose">
                                                {__('Choose a file')}
                                                <label htmlFor="files-select" className="sr-only" >File input</label>
                                                <input ref="ea_plan" type="file" name="plan_file" onChange={this.setFileName} />
                                            </div>
                                        </div>
                                        <div className="col-xs-4">
                                            <div className="file-box equity-file create_equity-file ">
                                                <span onClick={this.fileDelete} className="close file-del create_file-del eafile-del hidden">x</span>
                                                <p className="text-muted">{this.state.data.plan_file.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group ">
                                        <div className="col-xs-5">
                                            <label className="add-label">{__('Board approval date')}<br /><i className="text-muted">- {__('Optional')}</i></label>
                                        </div>
                                        <div className="col-xs-4">
                                            <DateSelector
                                                onChange={this.eaDateChange.bind(this, 'board_approval_date', 'board_approval_date_moment')}
                                                value={I(data['board_approval_date_moment'], I(data['board_approval_date'], '') ? moment(data['board_approval_date'], dateFormat) : '')}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group ">
                                        <div className="col-xs-5">
                                            <label className="add-label">{__('Shareholder approval date')}<br /><i className="text-muted">- {__('Optional')}</i></label>
                                        </div>
                                        <div className="col-xs-4">
                                            <DateSelector
                                                onChange={this.eaDateChange.bind(this, 'shareholder_approval_date', 'shareholder_approval_date_moment')}
                                                value={I(data['shareholder_approval_date_moment'], I(data['shareholder_approval_date'], '') ? moment(data['shareholder_approval_date'], dateFormat) : '')}
                                            />
                                        </div>
                                        <p className="text-muted col-xs-5">{__('Shareholder approval date can be entered later.')}</p>
                                    </div>
                                </form>
                            </div>
                            <div>
                                <div className="equity-item-header bg-seablue">
                                    <h5>{__('Term and expiration')}<i className="optional-color optional-font">- {__('Optional')}</i></h5>
                                </div>
                                <form className="form-horizontal form_bg">
                                    <div className="form-group ">
                                        <label className="col-xs-5 add-label">{__('Term of plan')}</label>
                                        <div className="col-xs-4">
                                            <select name="terms_of_plan" value={this.state.data.terms_of_plan} onChange={this.recordData} className="form-control select-container select-bgwhite">
                                                {this.state.numberOfYears.map((value,key)=>{
                                                    return(
                                                        <option key={key}>{value}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group ">
                                        <label className="col-xs-5 add-label">{__('Option grant expiration')}</label>
                                        <div className="col-xs-4">
                                            <select onChange={this.optionGrantExpiration.bind(this,"years")} value={this.state.data.option_grant_expiration.years} className="form-control select-container select-bgwhite">
                                                {this.state.numberOfYears.map((value,key)=>{
                                                    return(
                                                        <option key={key}>{value}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-xs-3 no-padding">
                                            <div className="checkbox send-check text-muted">
                                                <input onChange={this.optionGrantExpiration.bind(this,"minus_checked")} value={this.state.data.option_grant_expiration.minus_checked} type="checkbox" className="send-box" id="send-box"/>
                                                <label className="send-div" htmlFor="send-box"></label><span className="send-label"> &nbsp;{__('Minus one day')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div>
                                <div className="equity-item-header bg-seablue">
                                    <h5>{__('Exercise periods')}<i className="optional-color optional-font">- {__('Optional')}</i></h5>
                                </div>
                                <form className="form-horizontal form_bg">
                                    <div className="equity-tipbox">
                                        <div className="light-tip"></div>
                                        <p>{__('Changes to these fields will only apply to future issuances.')}</p>
                                    </div>
                                    {this.state.data.exercise_periods.map((val,k)=>{
                                        let inputKey = "check"+k;
                                        var showClass = "";
                                        if(val.value){
                                            showClass = 'checked-show';
                                        }else{
                                            showClass = 'checked-hidden';
                                        }
                                        return (
                                            <div key={k} className="form-group">
                                                <label className="col-xs-5 add-label">{val.title}</label>
                                                <div className="col-xs-1 no-paddingright">
                                                    <div className="checkbox send-check text-muted">
                                                        <input type="checkbox" className="send-box" onChange={this.changeStatus.bind(this,k)}  id={inputKey} checked={val.value} />
                                                        <label className="send-div" htmlFor={inputKey}></label>
                                                    </div>
                                                </div>
                                                <div className={showClass}>
                                                    <div className="col-xs-3">
                                                        <input  value={val.num} onChange={this.setExercisePeriod.bind(this,k,"num")} className="form-control" type="text" placeholder="e.g. 3" />
                                                    </div>
                                                    <div className="col-xs-3 no-paddingleft">
                                                        <select value={val.numType} onChange={this.setExercisePeriod.bind(this,k,"numType")} className="form-control select-container select-bgwhite">
                                                            <option>Days</option>
                                                            <option>Months</option>
                                                            <option>Years</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </form>
                            </div>
                            <div>
                                <div className="equity-item-header bg-seablue">
                                    <h5>{__('Repurchase periods')}<i className="optional-color optional-font">- {__('Optional')}</i></h5>
                                </div>
                                <form className="form-horizontal">
                                    <div className="equity-tipbox">
                                        <div className="light-tip"></div>
                                        <p>{__('Changes to these fields will only apply to future issuances.')}</p>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-xs-5">
                                            <label className=" add-label">{__('Repurchase ')}<i className="text-muted">- {__('Optional')}</i></label>
                                        </div>
                                        <div className="col-xs-1 no-paddingright">
                                            <div className="checkbox send-check text-muted">
                                                <input onChange={this.setRepuchaseState} type="checkbox" className="send-box" id="exercise06" checked={this.state.repurchasedStatus}/>
                                                <label className="send-div" htmlFor="exercise06"></label>
                                            </div>
                                        </div>

                                        <div className={!this.state.repurchasedStatus && "checked-hidden"}>
                                            <div className="col-xs-3">
                                                <input value={this.state.data.repurchase_periods.num} onChange={this.setRepurchasePeriods.bind(this,"num")} className="form-control" type="text" placeholder="e.g. 3" />
                                            </div>
                                            <div className="col-xs-3 no-paddingleft">
                                                <select value={this.state.data.repurchase_periods.numType} onChange={this.setRepurchasePeriods.bind(this,"numType")} className="form-control select-container select-bgwhite">
                                                    <option>Days</option>
                                                    <option>Months</option>
                                                    <option>Years</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {this.state.showErrorInfo === "show" &&
                            <div id="" className="alert alert-warning alert-dismissible pink-box eq-tipbox" role="alert">
                                <p>{__('If you select a box, please input a corresponding number.')}</p>
                            </div>
                            }
                            {this.state.isShowEmptyError ===1 &&
                            <div className="alert alert-warning alert-dismissible pink-box eq-tipbox" role="alert">
                                <p>{__('Required field(s) can not be empty.')}</p>
                            </div>
                            }
                            {this.state.isShowExceedErrorInfo ===1 &&
                            <div className="alert alert-warning alert-dismissible pink-box eq-tipbox" role="alert">
                                <p>Your Plan Size of Equity Awards will exceed total Authorized Shares.  Please adjust or return <a className="text-seablue link-cursor" onClick={this.backToAddMore}>here</a> to adjust your total Authorized Shares.</p>
                            </div>
                            }
                            {isLegalPrefix ===0 &&
                            <div className="stock-form" id="prefix_exist_error">
                                <div className="alert alert-warning alert-dismissable pink-box" role="alert">
                                    <p>{__('Prefix already exists. Please select another.')}</p>
                                </div>
                            </div>
                            }
                            {isLegalPlanName ===0 &&
                            <div className="stock-form" id="prefix_exist_error">
                                <div className="alert alert-warning alert-dismissable pink-box" role="alert">
                                    <p>{__('Plan name already exists. Please select another.')}</p>
                                </div>
                            </div>
                            }
                        </div>
                        <div className="modal-footer box-footer">
                            {showButton}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default CreateEquityAwards;

