import React, { Component } from 'react';
import SimpleTable from '../../components/SimpleTable';
import { certificateStatus } from 'Utils/constant';

export default function ShareHolder(props) {
	const { name, email, status } = props.data || {}

	const config = [
		{
			label: __('Name'),
			value: name
		},
		{
			label: __('Email'),
			value: email
		},
		{
			label: __('Status'),
			value: certificateStatus[status]
		}
	]

	return <div className="share-holder-root">
		<SimpleTable title={__('Shareholder')} config={config} />
	</div>
}