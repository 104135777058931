import React from "react";
import Utils, { formatNumber, I, formatPercent, calculator} from "Utils";
import Loading from 'components/Loading';
import security from 'stores/security';
import { post } from 'srcPath/http';
import { Link } from "react-router-dom";
import {parse} from "echarts/extension-src/dataTool/gexf";
import {DatePicker, message} from 'antd';
import moment from "moment";
import DateSelector from "../../../../components/DateSelector";
import { formatDate } from "Utils/format/formatDate";
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';

class AddVestingSchedule extends React.Component {
    constructor(props){
        super(props);
        this.state={
            dateFormat:"DD/MM/YYYY",
            MilestoneDetailList:[],
            ScheduleDetailList:[],
            uploadingStatus:0,
            uploadProgress:0,
            showFinalButton:0,
            isMilestone:1,
            loadingStatus:1,
            milestoneLength:0,
            vestedQuantity:0,
            totalQuantity:0,
            vestingStart:"",
            fullyVested:"",
            data:{
                security_id:0,
                is_custom:0, //是否是只为这一个security创建的
                schedule_name:"",
                schedule_id:0,
                start_date:"",
                early_exercise:"",
                acceleration_terms:"",
                reason:"",
                custom_type:"Date",
                milestone_file:{name:"No file uploaded",file_obj:""},
                milestoneList:[
                    {milestone:"",percentage:""},
                ],
                dateList:[
                    {period:"1", vesting_date:"", number_of_shares_to_vest:"0",
                        cumulative_vested:{
                            percentage:"0%",
                            value:"0",
                        },
                    },
                    {period:"2", vesting_date:"", number_of_shares_to_vest:"0",
                        cumulative_vested:{
                            percentage:"0%",
                            value:"0",
                        },
                    },
                ],
            },
            scheduleList:[
                {id:0,name:""},
                {id:2,name:"1/48 monthly, 1 year cliff"},
                {id:3,name:"1/48 monthly, no cliff"},
                {id:4,name:"1/24 monthly, no cliff"}
            ],
            earlyExerciseList:[
                "",
                "Yes",
                "No",
            ],
        }
    }

    handleStartDateChange = (key, moment_key, date) => {
        const { dateFormat } = this.state;
        let curData = this.state.data;
        curData[key] = date.format( dateFormat);
        curData[moment_key] = date;
        this.setState({
            data:curData
        });
    }

    /**
     * vesting_date
     * @param name
     * @param key
     * @param date
     */
    vestingDateChange = ( name, key, date) => {
        const { data, dateFormat } = this.state;
        let curData = {...data};
        curData['dateList'][key][name] = date.format( dateFormat);
        this.setState({
            data:curData
        });
    }

    changeIsCustom=(value,e)=>{
        let curData = this.state.data;
        curData.is_custom = value;
        this.setState({
            data:curData
        });
    }
    getVestingData=()=>{
        const { curSelectSecurity } = this.props;
        let curData = {...this.state.data};
        curData.schedule_name = curSelectSecurity.vesting_schedule_name;
        curData.schedule_id = curSelectSecurity.vesting_schedule_id == 0 ? curSelectSecurity.custom_schedule_id : curSelectSecurity.vesting_schedule_id ;
        curData.start_date = curSelectSecurity.vesting_start_date;
        curData.reason = curSelectSecurity.vesting_reason;
        this.setState({
            data:curData
        })
    }
    componentDidMount() {
        $(".acceleration").on("click",function(){
            var triangleChange=$(this).children("span").hasClass("glyphicon-menu-down");
            if(triangleChange){
                $(this).children("span").removeClass("glyphicon-menu-down").addClass("glyphicon-menu-up");
            }else{
                $(this).children("span").removeClass("glyphicon-menu-up").addClass("glyphicon-menu-down");
            }
        })
    }
    resetActive=()=>{
        window.$("#addVestingScheduleModal .tab-pane").removeClass("active");
        window.$("#schedule_step1").addClass("active");
        this.setState({
            data:{
                security_id:0,
                is_custom:0, //是否是只为这一个security创建的
                schedule_name:"",
                schedule_id:0,
                start_date:"",
                early_exercise:"",
                acceleration_terms:"",
                reason:"",
                custom_type:"Date",
                milestone_file:{name:"No file uploaded",file_obj:""},
                milestoneList:[
                    {milestone:"",percentage:""},
                ],
                dateList:[
                    {period:"1", vesting_date:"", number_of_shares_to_vest:"0",
                        cumulative_vested:{
                            percentage:"0%",
                            value:"0",
                        },
                    },
                    {period:"2", vesting_date:"", number_of_shares_to_vest:"0",
                        cumulative_vested:{
                            percentage:"0%",
                            value:"0",
                        },
                    },
                ],
            },
        })
    }
    saveVestingSchedule=()=>{
        const { listName, curSelectSecurity } = this.props;
        if(this.state.uploadingStatus ===1){
            return;
        }
        this.setState({
            uploadingStatus:1
        });
        if(Utils.isLocal()){
            window.$("#addVestingScheduleModal").modal("hide");
            window.$("#addVestingScheduleModal .tab-pane").removeClass("active");
            window.$("#schedule_step1").addClass("active");
            return;
        }
        var requestData = this.state.data;
        {listName == 'grantsList' ? requestData.grant_id = curSelectSecurity.id : requestData.warrant_id = curSelectSecurity.id}
        if(requestData.is_custom ===1){
            if(requestData.custom_type === 'Date'){
                requestData.custom_details_json = requestData.dateList;
            }else{
                requestData.custom_details_json = requestData.milestoneList;
            }
        }
        const addVestingApi = listName == 'grantsList' ? 'addGrantVestingSchedule' : 'addVestingSchedule4CoinWarrant';
        post(addVestingApi, requestData,{
            'Content-Type':'multipart/form-data',
            onUploadProgress:(progressEvent)=>{
                let complete = (progressEvent.loaded / progressEvent.total * 100 | 0);
                // console.log('上传 ' + complete+'%') ;
                this.setState({
                    uploadProgress:complete
                });
                if(complete == 100){
                    this.setState({
                        isShowUploadProgress:0
                    })
                }
            }
        }).then((response)=> {
            if(response.data.code === 0){
                //这里需要将返回的数据覆盖到主页面
                window.$("#addVestingScheduleModal").modal("hide");
                this.setState({
                    uploadProgress:0,
                    uploadingStatus:0
                });
                this.resetActive();
                // this.props.resetVestingName(this.props.curSelectSecurity.id,requestData.schedule_name,requestData.schedule_id);
                // window.location.reload();
                this.props.getSecurityList();
                // if(this.props.listName=="securityLists"){
                //     window.location.reload();
                // }else{
                //     this.props.resetVestingName(this.props.curSelectSecurity.id,this.props.listName,requestData.schedule_name,requestData.schedule_id);
                // }
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    selectMilestoneFile=(key,e)=>{
        let uploadFile = e.target.files[0];
        let curData = this.state.data;

        Utils.uploadFile(uploadFile).then((result)=>{
            curData[key].name = uploadFile.name;
            curData[key].file_obj=result;
            this.setState({
                data:curData
            });
            $("."+key).removeClass("hidden");
        });
    }
    recordData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        });
    }
    recordMilestoneData=(key,e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData.milestoneList[key][dataKey] = dataVal;
        this.setState({
            data:curData
        })
    }
    addMilestone=()=>{
        let one = {
            milestone:"",
            percentage:0,
        }
        let curList = this.state.data;
        curList.milestoneList.push(one);
        this.setState({
            data:curList
        })
    }
    removeMilestone=(key)=>{
        let curList = this.state.data;
        if(curList.milestoneList.length<=1){
            message.error('You only have one milestone name now!');
            return;
        }
        curList.milestoneList.splice(key,1);
        this.setState({
                data:curList
            }
        )
    }
    recordNumber=(key,e)=>{
        const { add, minus, mul, division  } = calculator;
        let curData = this.state.data;
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        curData.dateList[key][dataKey] = Utils.check10DecimalNumInputWithoutComma(dataVal);
        let exercisedQuantity = 0;
        if(this.props.curSelectSecurity.exercised_quantity){
            exercisedQuantity = parseFloat(this.props.curSelectSecurity.exercised_quantity)
        }
        //开始计算总和
        var totalShares = minus(parseFloat(this.props.curSelectSecurity.quantity),exercisedQuantity);
        var curTotal = 0;
        for(let i=0; i<this.state.data.dateList.length; i++){
            if(this.state.data.dateList[i].number_of_shares_to_vest.length === 0){
                continue;
            }
            curTotal = add( curTotal, parseFloat(this.state.data.dateList[i].number_of_shares_to_vest))
            // var curPercent = parseFloat(mul(division(curTotal,totalShares),100) );
            var curPercent = division(curTotal,totalShares);
            curData.dateList[i].cumulative_vested.value = formatFractionalNumber(curTotal);
            curData.dateList[i].cumulative_vested.percentage = formatPercent(curPercent);
        }
        if(curTotal == minus(parseFloat(this.props.curSelectSecurity.quantity),exercisedQuantity)){
            this.setState({
                showFinalButton:1
            });
        }else if(this.state.showFinalButton===1){
            this.setState({
                showFinalButton:0
            });
        }

        this.setState({
            data:curData
        });
    }
    recordMilestoneNumber=(key,e)=>{
        const { add, minus, mul, division  } = calculator;
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData.milestoneList[key][dataKey] = Utils.check10DecimalNumInputWithoutComma(dataVal) ;
        let exercisedQuantity = 0;
        if(this.props.curSelectSecurity.exercised_quantity){
            exercisedQuantity = parseFloat(this.props.curSelectSecurity.exercised_quantity)
        }
        //开始计算总和
        var totalShares = minus( parseFloat(this.props.curSelectSecurity.quantity),exercisedQuantity);
        var curTotal = 0;
        for(let i=0; i<this.state.data.milestoneList.length; i++){
            if(this.state.data.milestoneList[i].percentage.length === 0){
                continue;
            }
            curTotal = add( curTotal, parseFloat(this.state.data.milestoneList[i].percentage));
            var curPercent = division(curTotal,totalShares);
        }
        if(curTotal == (minus(parseFloat(this.props.curSelectSecurity.quantity),exercisedQuantity))){
            this.setState({
                showFinalButton:1
            });
        }else if(this.state.showFinalButton ===1){
            this.setState({
                showFinalButton:0
            });
        }
        this.setState({
            data:curData
        });
    }
    addSchedule=(key)=>{
        let one = {
            period:"1",
            vesting_date:"",
            number_of_shares_to_vest:"",
            cumulative_vested: {
                percentage: "",
                value: "",
            }
        }
        let curData = this.state.data;
        curData.dateList.push(one);
        this.setState({
            data:curData
        });

    }
    removeSchedule=(key)=>{
        let curList = this.state.data;
        if(curList.dateList.length<=1){
            message.error('You only have one row now!');
            return;
        }
        curList.dateList.splice(key,1);
        this.setState({
            data:curList
            }
        )
    }
    calcCurVestedSharesByName=()=>{
        let curVested = 0;
        var vestingList = this.getVestingDetailsList();
        for(let i=0; i< vestingList.length; i++){
            if(vestingList[i].isVested){
                curVested =vestingList[i].cumulativeVested;
            }
        }
        return curVested;
    }
    getCustomVestingInfo=()=>{
        post('getVestingInfoById',{id:this.state.data.schedule_id}).then((response)=> {
            if(response.data.code === 0){
                response.data.data.schedule_id = this.state.data.schedule_id;
                response.data.data.start_date = this.state.data.start_date;
                if(!response.data.data.milestone_file){
                    response.data.data.milestone_file = {name:"No file uploaded",file_obj:""}
                }
                if(response.data.data.milestoneList.length==0){
                    response.data.data.milestoneList = [{milestone:"",percentage:""},];
                }
                if(response.data.data.dateList.length==0){
                    response.data.data.dateList = [
                        {period:"1", vesting_date:"", number_of_shares_to_vest:"0",
                            cumulative_vested:{
                                percentage:"0%",
                                value:"0",
                            },
                        }
                        ]
                }
                this.setState({
                    data:response.data.data,
                    showFinalButton:1
                })
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    getVestingArrByTotalMonth(totalMonth){
        let returnArr = [];
        var curDate = this.state.data.start_date; // dd/mm/yyyy
        var share4EveryPeriod = parseInt(parseInt(this.props.curSelectSecurity.quantity)/totalMonth);
        var cumulativeShares = 0;
        var calcTodayWeight = new Date().getFullYear()*10000+(new Date().getMonth()+1)*100+new Date().getDate();
        for(let i=0;i<totalMonth;i++){
            var share4ThisPeriod = 0;
            var isVested = false;
            if(i<(totalMonth-1)){
                share4ThisPeriod = share4EveryPeriod;
                cumulativeShares += share4EveryPeriod;
            }else{
                share4ThisPeriod = parseInt(this.props.curSelectSecurity.quantity) - cumulativeShares;
                cumulativeShares = this.props.curSelectSecurity.quantity;
            }
            if(i > 0){
                var dateArr = curDate.split('/');
                var year = 0;
                var month = 0;
                var day = 0;
                if(parseInt(dateArr[1])===12){
                    year = parseInt(dateArr[2]) + 1;
                    month = 1;
                    day = dateArr[0];
                    curDate = day+'/'+month+'/'+year;
                }else{
                    year = dateArr[2];
                    month = parseInt(dateArr[1]) +1;
                    var dayCount = Utils.getDayCountByMonth(parseInt(dateArr[1]));
                    let originalDateArr = this.state.data.start_date.split("/");
                    if(dayCount >= parseInt(originalDateArr[0])){
                        day = originalDateArr[0];
                    }else{
                        day = dayCount;
                    }
                    curDate = day+'/'+month+'/'+year;
                }
            }
            if(Utils.getWeightByDateString(curDate) <= calcTodayWeight ){
                isVested = true;
            }
            returnArr.push({period:(i+1),isVested:isVested,date:curDate,vestedShares:share4ThisPeriod,cumulativeVested:cumulativeShares});
        }
        return returnArr;
    }

    getVestingDetailsList=()=>{
        var totalMonth = 0;
        if(this.state.data.schedule_name === '1/24 monthly, no cliff'){
            totalMonth = 24;
        }else{
            totalMonth = 48;
        }
        return this.getVestingArrByTotalMonth(totalMonth);
    }

    getCustomDetailsList=()=>{
        let returnArr = [];
        var todayWeight = Utils.getTodayWeightToCompare();
        for(let i=0; i<this.state.data.dateList.length; i++){
            var isVested = false;
            if(todayWeight >= Utils.getWeightByDateString(this.state.data.dateList[i].vesting_date)){
                isVested = true;
            }
            returnArr.push({period:(i+1),isVested:isVested,date:this.state.data.dateList[i].vesting_date,vestedShares:this.state.data.dateList[i].number_of_shares_to_vest,cumulativeVested:this.state.data.dateList[i].cumulative_vested.value});
        }
        return returnArr;
    }
    getScheduleDetail=()=>{
        const { listName, curSelectSecurity } = this.props;
        let requestData = this.state.data;
        // requestData.security_id = this.props.curSelectSecurity.id;
        {listName == 'grantsList' ? requestData.grant_id = curSelectSecurity.id : requestData.warrant_id = curSelectSecurity.id}
        const getScheduleDetailsApi = listName == 'grantsList' ? "getScheduleDetailsByGrantId" : "getScheduleDetailsByWarrantId";
        post(getScheduleDetailsApi, requestData).then((response)=> {
            if(response.data.code === 0){
                this.setState({
                    vestedQuantity:response.data.data.vestedShares,
                    totalQuantity:response.data.data.totalShares,
                    vestingStart:response.data.data.vestingStart,
                    fullyVested:response.data.data.fullyVested
                })
                if(response.data.data.isMilestone==1){
                    this.setState({
                        isMilestone:1,
                        MilestoneDetailList:response.data.data.DetailList,
                        loadingStatus:0,
                    })
                }else{
                    this.setState({
                        isMilestone:0,
                        ScheduleDetailList:response.data.data.DetailList,
                        loadingStatus:0,
                    })
                }
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    getCustomVestedShares=()=>{
        let curVested = 0;
        var vestingList = this.getCustomDetailsList();
        for(let i=0; i< vestingList.length; i++){
            if(vestingList[i].isVested){
                curVested =vestingList[i].cumulativeVested;
            }
        }
        return parseInt(curVested.toString().replace(/,/g,""));
    }
    closeModal=()=>{
        window.$("#addVestingScheduleModal").modal("hide")
    }

    deleteVesting=()=>{
        post('delVestingScheduleFromSec',{id:this.props.curSelectSecurity.id}).then((response)=> {
            if(response.data.code === 0){
                window.location.reload();
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    render(){
        // calc shares
        // let curVestedShares = 0;
        // if(this.state.data.is_custom === 0){
        //     curVestedShares = this.calcCurVestedSharesByName();
        // }else if(this.state.data.is_custom ===1 && this.state.data.custom_type==='Date'){
        //     curVestedShares = this.getCustomVestedShares();
        // }
        // let curPercent = parseInt(curVestedShares*100/parseInt(this.props.curSelectSecurity.quantity));

        const {add, minus, mul, division} = calculator;

        let exercisedQuantity = 0;
        if(this.props.curSelectSecurity.exercised_quantity){
            exercisedQuantity = Utils.resetNumberWithCommas(parseFloat(this.props.curSelectSecurity.exercised_quantity))
        }

        //get list
        // let vestingDetailsList = [];
        // let fullyVestedDate = "";
        // if(this.state.data.is_custom === 0 && this.state.data.start_date.length>0){
        //     vestingDetailsList = this.getVestingDetailsList();
        //     fullyVestedDate = vestingDetailsList[vestingDetailsList.length-1].date;
        // }else if(this.state.data.is_custom === 1 && this.state.data.custom_type==='Date'){
        //     vestingDetailsList = this.getCustomDetailsList();
        //     fullyVestedDate = vestingDetailsList[vestingDetailsList.length-1].date;
        // }
        var periodTrClass = 'vestedTr';
        if(this.props.curSelectSecurity.type === "cs"){
            periodTrClass = "bg-lightgray";
        }
        let addReasonBtn = <button type="button" className="btn boxbtn-to send-disabledBtn">Next: Add Modification Reason</button>;
        if(this.state.data.schedule_id!=""&&this.state.data.start_date!=""){
            addReasonBtn = <button onClick={this.changeIsCustom.bind(this,0)} type="button" data-toggle="tab" data-target="#schedule_step2" className="btn boxbtn-to bg-darkblue">Next: Add Modification Reason</button>
        }
        //custom_type:Milestone时，quantity计算
        let totalQuantity = 0;
        let curPercentage = 0;
        let milestoneVestedQuantity = 0;
        let availableQuantity = parseFloat(this.props.curSelectSecurity.quantity) ;
        // let issuedQuantity = parseFloat(this.props.curSelectSecurity.exercised_quantity) ;
        let issuedQuantity = exercisedQuantity ;
        if(this.state.data.milestoneList.length>0){
            for(let i=0;i<this.state.data.milestoneList.length;i++){
                totalQuantity = add(totalQuantity, parseFloat(this.state.data.milestoneList[i].percentage));
            }
        }
        if(availableQuantity!=0 ){
            curPercentage = formatPercent(division(totalQuantity,minus(availableQuantity,issuedQuantity)));
        }
        if(this.props.curSelectSecurity.vested_quantity){
            milestoneVestedQuantity = this.props.curSelectSecurity.vested_quantity
        }

        // Time percentage计算
        let timeTotalQuantity = 0;
        let timePercentage = 0;
        if(this.state.data.dateList.length>0){
            for(let i=0;i<this.state.data.dateList.length;i++){
                timeTotalQuantity = add(timeTotalQuantity,parseFloat(this.state.data.dateList[i].number_of_shares_to_vest))
            }
        }

        //验证日期
        let nextStep = 0;
        let isEmptyDate = 0;
        let isEmptyMilestone = 0;
        if(this.state.data.custom_type=="Date"){
            for(let i=0;i<this.state.data.dateList.length;i++){
                if(this.state.data.dateList[i].vesting_date==""){
                    isEmptyDate = 1;
                }
            }
            if(this.state.data.name!=""&& this.state.showFinalButton==1 && isEmptyDate ==0){
                nextStep = 1;
            }
        }
        if(this.state.data.custom_type=="Milestone"){
            for(let k=0;k<this.state.data.milestoneList.length;k++){
                if(this.state.data.milestoneList[k].milestone==""){
                    isEmptyMilestone = 1;
                }
            }
            if(this.state.data.name!=""&& this.state.showFinalButton==1 && isEmptyMilestone ==0){
                nextStep = 1;
            }
        }
        //vesting进度条详细信息
        let milestoneLength = 0;
        if(this.state.isMilestone==1){
            milestoneLength = this.state.MilestoneDetailList.length;
        }
        if(this.state.data.is_custom==1 && this.state.data.custom_type=="Milestone"){
            milestoneLength = this.state.data.milestoneList.length
        }
        let milestoneData = <p><small className="text-muted">{milestoneLength} Milestones</small></p>
        let dateTimesData = <div className="clearfix">
                                <small className="text-muted onset">Vesting start: { formatDate(this.state.data.start_date)}</small>
                                <small className="text-muted offset">Fully vested: {formatDate(this.state.fullyVested)}</small>
                            </div>
        let totalShares = Utils.resetNumberWithCommas(parseFloat(this.props.curSelectSecurity.quantity)) ;
        let vestingButton =  <a data-toggle="tab" href="#createSchedule" className="acceleration_terms_a text-blue">Create a custom schedule </a>
        if(this.props.curSelectSecurity.custom_schedule_id!=this.state.data.schedule_id){
            vestingButton = <a data-toggle="tab" href="#createSchedule" onClick={this.getCustomVestingInfo} className="acceleration_terms_a text-blue">Edit a custom schedule </a>
        }
        let removeVestingBtn = <a data-toggle="tab" href="#removeVestingSchedule" className="acceleration_terms_a text-blue">Remove vesting schedule </a>

        //后端取数据计算
        const { vestedQuantity } = this.state;
        let curVestedQuantity = Utils.resetNumberWithCommas( vestedQuantity);
        if( !vestedQuantity ){
            curVestedQuantity = 0;
        }
        let curPercent =formatPercent(division( curVestedQuantity, minus(totalShares,exercisedQuantity)));

        const { data, dateFormat } = this.state;
        const { curSelectSecurity } = this.props;
        let canRemove = 0;
        if(curSelectSecurity.status!="Exercised" && curSelectSecurity.status!="Partially exercised" && curSelectSecurity.status!="Settled" && curSelectSecurity.status!="Partially settled"){
            canRemove = 1;
        }
        return(
            <div className="modal fade" id="addVestingScheduleModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={{display: "none"}} >
                <div className="modal-dialog dialog900 dialog-top40 tab-content">
                    <div className="modal-content content440 tab-pane active" id="schedule_step1">
                        <div className="modal-header box-header">
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                            <h4 className="modal-title" id="myModalLabel">Add Vesting Schedule for {curSelectSecurity.custom_label_id ? curSelectSecurity.custom_label_id :curSelectSecurity.grant_name}</h4>
                        </div>
                        <div className="modal-body invite-body reset_margin">
                            <div id="inviteCompanyInputError" className="alert alert-warning alert-dismissible pink-box hidden">
                                <p>Required field(s) or email cannot be empty or in the wrong format.</p>
                            </div>
                            <div className="body-one">
                                <form className="form-horizontal">
                                    <div className="form-group add-items">
                                        <label className="col-xs-5 add-label">Schedule</label>
                                        <div className="col-xs-7 no-paddingleft">
                                            <select name="schedule_id" onChange={this.recordData} value={this.state.data.schedule_id} className="form-control select-container">
                                                <option value="">---</option>
                                                {(this.props.curSelectSecurity.custom_schedule_id!=0) &&
                                                    <option value={this.props.curSelectSecurity.custom_schedule_id}>{this.state.data.schedule_name}</option>
                                                }
                                                {this.props.vestingScheduleList.map((value,key)=>{
                                                        return(
                                                            <option value={value.id} key={key}>{value.schedule_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group add-items">
                                        <div className="col-xs-7 col-xs-push-5">
                                            <div className="form-group">
                                                <Link to={{ pathname : `/home/securities/templates/vesting`} } onClick={this.closeModal} className="acceleration_terms_a text-blue">{__('Manage vesting schedules')}</Link>
                                            </div>
                                        </div>
                                        <div className="col-xs-7 col-xs-push-5 ">
                                            <div className="form-group">
                                                {vestingButton}
                                            </div>
                                            {(curSelectSecurity.custom_schedule_id != 0 || curSelectSecurity.vesting_schedule_id != 0) &&
                                            <div className="form-group">
                                                {removeVestingBtn}
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group add-items">
                                        <label className="col-xs-5 add-label">Vesting start date</label>
                                        <div id="vesting_start_date" className="col-xs-5 no-paddingleft">
                                            <DateSelector
                                                onChange={this.handleStartDateChange.bind(this, 'start_date', 'start_date_moment')}
                                                value={I(data['start_date_moment'], I(data['start_date'], '') ? moment(data['start_date'], dateFormat) : '')}
                                            />
                                        </div>
                                    </div>

                                    {/*加速，后推到1.06*/}
                                    {/*<div className="col-xs-7 col-xs-push-5">*/}
                                    {/*    <div className="form-group">*/}
                                    {/*        <a role="button" data-toggle="collapse" href="#acceleration" className="acceleration acceleration_terms_a text-blue">Remove acceleration terms <span className="resetDownTri text-blue glyphicon glyphicon-menu-down"></span> </a>*/}
                                    {/*        <div className="collapse" id="acceleration">*/}
                                    {/*            <textarea name="acceleration_terms" placeholder="Type or paste acceleration text here." onChange={this.recordData} value={this.state.data.acceleration_terms} type="text"  className="form-control acceleration_terms_textarea" />*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                </form>
                            </div>
                        </div>
                        <div className="modal-footer box-footer invite-footer">
                            {addReasonBtn}
                        </div>
                    </div>
                    <div className="modal-content content580 tab-pane" id="schedule_step2">
                        <div className="modal-header box-header">
                            <a href="#schedule_step1" data-toggle="tab" type="button" className="back-btn" ></a>
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.resetActive}>×</button>
                            <h4 className="modal-title" id="myModalLabel">Add Vesting Schedule for {curSelectSecurity.custom_label_id ? curSelectSecurity.custom_label_id : curSelectSecurity.grant_name}</h4>
                        </div>
                        <div className="modal-body invite-body">
                            <div id="inviteCompanyInputError" className="alert alert-warning alert-dismissible pink-box hidden">
                                <p>Required field(s) or email cannot be empty or in the wrong format.</p>
                            </div>
                            <div className="body-one">
                                <form className="form-horizontal">
                                    <div className="form-group add-items">
                                        <label className="col-xs-5 add-label">Reason for modification</label>
                                        <div id="reason_for_modification" className="col-xs-7 no-paddingleft">
                                            <textarea name="reason" placeholder="Type or paste acceleration text here." onChange={this.recordData} value={this.state.data.reason} type="text"  className="form-control acceleration_terms_textarea" />
                                            {/*<p className="text-muted">The reason will be recorded on the certificate.</p>*/}{/*1.2加回来*/}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer box-footer invite-footer">
                            {this.state.data.reason == "" &&
                            <button type="button" className="btn boxbtn-to send-disabledBtn">Next: Review Modifications</button>
                            }
                            {this.state.data.reason !== "" && this.state.data.is_custom == 1 &&
                            <button type="button" data-toggle="tab" onClick={this.getScheduleDetail} data-target="#schedule_step3" className="btn boxbtn-to bg-darkblue">Next: Review Modifications</button>
                            }
                            {this.state.data.reason !== "" && this.state.data.is_custom == 0 &&
                            <button type="button" onClick={this.getScheduleDetail} data-toggle="tab" data-target="#schedule_step3" className="btn boxbtn-to bg-darkblue">Next: Review Modifications</button>
                            }
                        </div>
                    </div>
                    <div className="modal-content content680 tab-pane" id="schedule_step3">
                        <div className="modal-header box-header">
                            <a href="#schedule_step2" data-toggle="tab" type="button" className="back-btn" ></a>
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true"  onClick={this.resetActive}>×</button>
                            <h4 className="modal-title" id="myModalLabel">Add Vesting Schedule for {curSelectSecurity.custom_label_id ? curSelectSecurity.custom_label_id : curSelectSecurity.grant_name}</h4>
                        </div>
                        <div className="modal-body invite-body">
                            <div className="body-one">
                                <p className="text-muted marginBott10 ">The following modifications will be made to the vesting schedule <span className="resetFont">{curSelectSecurity.name ? curSelectSecurity.name : curSelectSecurity.stakeholder_name}</span> for {curSelectSecurity.custom_label_id ? curSelectSecurity.custom_label_id : curSelectSecurity.grant_name}.</p>
                                <div>
                                    <p className="text-muted">{this.state.vestedQuantity==0?0:formatFractionalNumber(this.state.vestedQuantity)} of {formatFractionalNumber(minus(totalShares,exercisedQuantity))} shares vested ({curPercent})</p>
                                    <div className="progress schedule-progress no-marginBottom">
                                        <div style={{width:curPercent}} className="progress-bar add-vesting-schedule-progress-bar" role="progressbar" aria-valuenow="60"
                                             aria-valuemin="0" aria-valuemax="100">
                                            <span className="sr-only">{curPercent} Complete</span>
                                        </div>
                                    </div>
                                    {this.state.data.is_custom==0 && this.state.isMilestone==1 && milestoneData}
                                    {this.state.data.is_custom==0 && this.state.isMilestone==0 && dateTimesData}
                                    {this.state.data.is_custom==1 && this.state.data.custom_type=="Milestone" && milestoneData}
                                    {this.state.data.is_custom==1 && this.state.data.custom_type=="Date" && dateTimesData}
                                </div>

                                {/*后推到1.06*/}
                                {/*<p className="acceleration-p">Acceleration: <span className="text-muted">Yes</span> <span className="text-blue">Show details</span> </p>*/}
                                {/*<small>Legend: <span className="legend-item text-blue">*/}
                                {/*    {this.props.curSelectSecurity.security_type=="cs" && "Vested common shares"}*/}
                                {/*    {this.props.curSelectSecurity.security_type=="ps" && "Vested preferred shares"}*/}
                                {/*</span></small>*/}

                                {this.state.data.is_custom==0 &&
                                <div>
                                    {this.state.loadingStatus==1 &&
                                        <div className="termsLoadingPosition">
                                            <Loading/>
                                        </div>
                                    }
                                    {this.state.loadingStatus == 0 &&
                                        <div>
                                            {this.state.isMilestone==1 &&
                                            <table className="table viewHoldings-table vested-table marginTop10">
                                                <tbody>
                                                <tr className="first-row">
                                                    <td>Milestone</td>
                                                    <td>Options to vest</td>
                                                    <td className="vested-3Td">Vesting date</td>
                                                </tr>
                                                {this.state.MilestoneDetailList.map((value,key)=>{
                                                    return(
                                                        <tr  className={periodTrClass} key={key}>
                                                            <td>{value.milestone}</td>
                                                            <td>{value.shareVested}</td>
                                                            <td className="vested-3Td">{ formatDate(value.vesting_date)}</td>
                                                        </tr>
                                                    )
                                                })}

                                                {this.state.uploadProgress >0 &&
                                                <tr className="reviewTb-contentRow ">
                                                    <td  colSpan={"4"}>{this.state.uploadProgress}% uploaded...</td>
                                                </tr>
                                                }
                                                </tbody>
                                            </table>
                                            }
                                            {this.state.isMilestone == 0 &&
                                            <table className="table viewHoldings-table vested-table marginTop10">
                                                <tbody>
                                                <tr className="first-row">
                                                    <td>Period</td>
                                                    <td>Date</td>
                                                    <td className="vested-3Td">Shares vested</td>
                                                    <td>Cumulative vested</td>
                                                </tr>
                                                {this.state.ScheduleDetailList && this.state.ScheduleDetailList.map((value, key) => {
                                                    return (
                                                        <tr className={periodTrClass} key={key}>
                                                            <td>{value.period}</td>
                                                            <td>{ formatDate(value.date) }</td>
                                                            <td className="vested-3Td">{formatNumber(value.shareVested)}</td>
                                                            <td>{formatNumber(value.cumulative)}</td>
                                                        </tr>
                                                    )
                                                })}
                                                {this.state.uploadProgress > 0 &&
                                                <tr className="reviewTb-contentRow ">
                                                    <td colSpan={"4"}>{this.state.uploadProgress}% uploaded...</td>
                                                </tr>
                                                }
                                                </tbody>
                                            </table>
                                            }
                                        </div>
                                    }
                                    </div>
                                    }
                                {this.state.data.is_custom==1 &&
                                    <div>
                                        {this.state.data.custom_type=="Milestone" &&
                                        <table className="table viewHoldings-table vested-table marginTop10">
                                            <tbody>
                                            <tr className="first-row">
                                                <td>Milestone</td>
                                                <td>Options to vest</td>
                                                <td className="vested-3Td">Vesting date</td>
                                            </tr>
                                            {this.state.data.milestoneList.map((value, key) => {
                                                return (
                                                    <tr className="scheduleTr bg-lightgray" key={key}>
                                                        <td className="">{value.milestone}</td>
                                                        <td className="">{value.percentage}</td>
                                                        <td className="box-lasttd noBorderTd">Not vested</td>
                                                    </tr>
                                                )
                                            })
                                            }
                                            </tbody>
                                        </table>
                                        }
                                        {this.state.data.custom_type=="Date" &&
                                        <table className="table viewHoldings-table vested-table marginTop10">
                                            <tbody>
                                            <tr className="first-row">
                                                <td>Period</td>
                                                <td>Date</td>
                                                <td className="vested-3Td">Shares vested</td>
                                                <td>Cumulative vested</td>
                                            </tr>
                                            {this.state.data.dateList.map((value, key) => {
                                                return (
                                                    <tr className={periodTrClass} key={key}>
                                                        {/*<td>{value.period}</td>*/}
                                                        <td>{key+1}</td>
                                                        <td>{ formatDate(value.vesting_date) }</td>
                                                        <td className="vested-3Td">{value.number_of_shares_to_vest}</td>
                                                        <td>{value.cumulative_vested.value}</td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="modal-footer box-footer invite-footer">
                            <button type="button" className="btn boxbtn-to bg-darkblue"  onClick={this.saveVestingSchedule}>Save Vesting Schedule</button>
                        </div>
                    </div>
                    <div className="modal-content content900 tab-pane" id="createSchedule">
                        <div className="modal-header box-header">
                            <a href="#schedule_step1" data-toggle="tab" type="button" className="back-btn" ></a>
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true"  onClick={this.resetActive}>×</button>
                            <h4 className="modal-title" id="myModalLabel">Add Vesting Schedule for {curSelectSecurity.custom_label_id ? curSelectSecurity.custom_label_id : curSelectSecurity.grant_name}</h4>
                        </div>
                        <div className="modal-body invite-body">
                            <div id="inviteCompanyInputError" className="alert alert-warning alert-dismissible pink-box hidden">
                                <p>Required field(s) or email cannot be empty or in the wrong format.</p>
                            </div>
                            <div className="body-one">
                                <div className="form-group add-items row">
                                    <label className="col-xs-4 add-label no-paddingleft">Schedule name</label>
                                    <div id="reason_for_modification" className="col-xs-6 no-paddingleft vesting-btn">
                                        <input name="schedule_name" placeholder="e.g. John Smith's vesting" onChange={this.recordData} value={this.state.data.schedule_name} type="text"  className="form-control " />
                                        <p className="scheduleInput-tiptext convertible-tiptext">This name will be shown to the share holder.</p>
                                    </div>
                                </div>
                                <div className="form-group add-items row">
                                    <label className="col-xs-4 add-label no-paddingleft">Schedule type</label>
                                    <div className="col-xs-2 no-paddingleft">
                                        <div className="checkbox send-check text-muted">
                                            <input id={"dateType"} name="custom_type" type="radio" value="Date" className="send-box schedule-checkbox" checked={this.state.data.custom_type=="Date"} onChange={this.recordData}/>
                                            <label htmlFor={"dateType"} className="send-div"></label>
                                            <span className="text-muted paddingLeft26">Date</span>
                                        </div>
                                    </div>
                                    <div className="col-xs-2 no-paddingleft ">
                                        <div className="checkbox send-check text-muted">
                                            <input  id={"milestoneType"}  name="custom_type" type="radio" value="Milestone" className="send-box schedule-checkbox" checked={this.state.data.custom_type=="Milestone"} onChange={this.recordData} />
                                            <label htmlFor={"milestoneType"}  className="send-div"></label>
                                            <span className="text-muted paddingLeft26">Milestone/Performance</span>
                                        </div>
                                    </div>
                                </div>
                                {this.state.data.custom_type === "Date" &&
                                <div>
                                    <div className="form-group add-items row">
                                        <label className="col-xs-4 add-label no-paddingleft">Vesting start date</label>
                                        <div className="col-xs-3 no-paddingleft">
                                            <DateSelector
                                                onChange={this.handleStartDateChange.bind(this, 'start_date', 'start_date_moment')}
                                                value={I(data['start_date_moment'], I(data['start_date'], '') ? moment(data['start_date'], dateFormat) : '')}
                                            />
                                        </div>
                                    </div>
                                    <div className="clearfix">
                                        <div className="onset">
                                            <p className="text-muted mb-0">The final period must total {formatFractionalNumber(minus(totalShares,exercisedQuantity))} shares (100%).</p>
                                        </div>

                                        {/*加速逻辑，后推到1.06*/}
                                        {/*<div className="offset">*/}
                                        {/*    <a className="btn colorWhite bg-dark vesting-btn">Incllude acceleration terms <span className="resetDownTri colorWhite glyphicon glyphicon-menu-down"></span>*/}
                                        {/*        <p className="convertible-tiptext vesting-tiptext">Start with a standard schedule, then edit it in this custom schedule editor.</p>*/}
                                        {/*    </a>*/}
                                        {/*</div>*/}
                                    </div>
                                    <table className="table viewHoldings-table marginTop10 schedule-table no-marginBottom">
                                        <tbody>
                                        <tr className="first-row">
                                            <td>Period</td>
                                            <td>Vesting date</td>
                                            <td className="vested-3Td">Number of shares to vest</td>
                                            <td className="vested-3Td">Cumulative vested</td>
                                            <td className="width80">&nbsp;</td>
                                        </tr>
                                        {this.state.data.dateList.map((value,key)=>{
                                            if(this.state.data.dateList.length=== (key+1)){
                                                return(
                                                    <tr className="scheduleTr bg-lightgray" key={key}>
                                                        <td className="noBorderTd">{key+1}</td>
                                                        <td>
                                                            <DateSelector
                                                                onChange={this.vestingDateChange.bind(this, 'vesting_date', key)}
                                                                value={ I(value.vesting_date, '') ? moment(value.vesting_date, dateFormat) : ''}
                                                            />
                                                        </td>
                                                        <td className=""><input type="text" className="vested-3Td width100perc" value={value.number_of_shares_to_vest} name="number_of_shares_to_vest" onChange={this.recordNumber.bind(this,key)} /></td>
                                                        <td className="vested-3Td"><span className="onset">{value.cumulative_vested.percentage}</span>{value.cumulative_vested.value}</td>
                                                        <td className="box-lasttd noBorderTd">
                                                            <div className="btn-box relationship-btnBox">
                                                                <button type="button" onClick={this.addSchedule}  className="btn-mp plus"></button>
                                                                <button type="button" onClick={this.removeSchedule.bind(this,key)} className="btn-mp minus"></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }else{
                                                return(
                                                    <tr className="scheduleTr bg-lightgray" key={key}>
                                                        <td className="noBorderTd">{key+1}</td>
                                                        <td>
                                                            <DateSelector
                                                                onChange={this.vestingDateChange.bind(this, 'vesting_date', key)}
                                                                value={ I(value.vesting_date, '') ? moment(value.vesting_date, dateFormat) : ''}
                                                            />
                                                        </td>
                                                        <td className=""><input type="text" className="vested-3Td width100perc" value={value.number_of_shares_to_vest} name="number_of_shares_to_vest" onChange={this.recordNumber.bind(this,key)} /></td>
                                                        <td className="vested-3Td"><span className="onset">{value.cumulative_vested.percentage}</span>{value.cumulative_vested.value}</td>
                                                        <td className="box-lasttd noBorderTd">
                                                            <div className="btn-box relationship-btnBox">
                                                                <button type="button" onClick={this.removeSchedule.bind(this,key)} className="btn-mp minus"></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        })}
                                        </tbody>
                                    </table>
                                    <div className="clearfix">
                                        <p className="text-muted offset marginTop10 marginBott10">The final period must total {formatFractionalNumber(minus(totalShares,exercisedQuantity))} shares (100%).</p>
                                    </div>
                                    {minus( timeTotalQuantity , (minus(totalShares,exercisedQuantity)))>0 &&
                                    <div className="alert alert-warning alert-dismissable pink-box marginTop30" role="alert">
                                        <p>{__('Total percentage cannot exceed 100%')}</p>
                                    </div>
                                    }
                                </div>
                                }
                                {this.state.data.custom_type === "Milestone" &&
                                <div>
                                    <div className="form-group  add-items row">
                                        <label className="col-xs-4 add-label no-paddingleft">Milestones</label>
                                        <div className="col-xs-3 no-paddingleft">
                                            <div className="select-btn select-blue convertiblenote-select-btn">Choose a file
                                                <label className="sr-only" >File input</label>
                                                <input ref="milestone_file" onChange={this.selectMilestoneFile.bind(this,"milestone_file")}  type="file" />
                                            </div>
                                            <p className="text-muted">Attach milestone terms.</p>
                                        </div>
                                        <div className="col-xs-4 no-paddingleft no-paddingright repur-fileName amend-file-name">
                                            <p className="text-muted milestone_file">{this.state.data.milestone_file.name}</p>
                                        </div>
                                    </div>
                                    <table className="table viewHoldings-table schedule-table marginTop10">
                                        <tbody>
                                        <tr className="first-row">
                                            <td>Milestone name</td>
                                            <td>Number of shares to vest</td>
                                            <td className="width80">&nbsp;</td>
                                        </tr>
                                        {this.state.data.milestoneList.map((value,key)=>{
                                            if(this.state.data.milestoneList.length=== (key+1)){
                                                return(
                                                    <tr className="scheduleTr bg-lightgray" key={key}>
                                                        <td className=""><input type="text" name="milestone" value={value.milestone} onChange={this.recordMilestoneData.bind(this,key)} /></td>
                                                        <td className=""><input type="text" name="percentage" value={value.percentage} onChange={this.recordMilestoneNumber.bind(this,key)} /> </td>
                                                        <td className="box-lasttd noBorderTd">
                                                            <div className="btn-box relationship-btnBox">
                                                                <button type="button" onClick={this.addMilestone}  className="btn-mp plus"></button>
                                                                <button type="button" onClick={this.removeMilestone.bind(this,key)} className="btn-mp minus"></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }else{
                                                return(
                                                    <tr className="scheduleTr bg-lightgray" key={key}>
                                                        <td className=""><input type="text" name="milestone" value={value.milestone}  onChange={this.recordMilestoneData.bind(this,key)} /></td>
                                                        <td className=""><input type="text" name="percentage" value={value.percentage}  onChange={this.recordMilestoneNumber.bind(this,key)} /> </td>
                                                        <td className="box-lasttd noBorderTd">
                                                            <div className="btn-box relationship-btnBox">
                                                                <button type="button" onClick={this.removeMilestone.bind(this,key)} className="btn-mp minus"></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        })}
                                        <tr className="vestedTr summaryTr">
                                            <td colSpan={2} className="width60perc ">
                                                <p className="width220">Total: {this.props.curSelectSecurity.quantity==0?0:formatFractionalNumber(this.props.curSelectSecurity.quantity)} </p>
                                                <p className="width220">Vested Quantity: {milestoneVestedQuantity==0?0:formatFractionalNumber(milestoneVestedQuantity)} </p>
                                                <p className="width220">Unexercised Quantity: {formatFractionalNumber(totalShares-exercisedQuantity)}</p>
                                            </td>
                                            <td className="vested-3Td width200"><span>Total:</span> {totalQuantity==0?0:formatFractionalNumber(totalQuantity)} shares ({curPercentage})</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    {minus(totalQuantity ,(minus(totalShares,exercisedQuantity)))>0 &&
                                    <div className="alert alert-warning alert-dismissable pink-box marginTop30" role="alert">
                                        <p>{__('Total percentage cannot exceed 100%')}</p>
                                    </div>
                                    }
                                </div>
                                }
                            </div>
                        </div>
                        <div className="modal-footer box-footer invite-footer">
                            {/*{(this.state.data.name == "" || this.state.showFinalButton===0 ||isEmptyMilestone==1) &&*/}
                            {nextStep==0 &&
                            <button type="button" className="btn boxbtn-to send-disabledBtn">Next: Add Modification Reason</button>
                            }
                            {/*{((this.state.data.name !== "" && this.state.showFinalButton===1) ||this.state.data.custom_type==='Milestone') &&*/}
                            {nextStep==1 &&
                            <button onClick={this.changeIsCustom.bind(this, 1)} type="button" data-toggle="tab" data-target="#schedule_step2" className="btn boxbtn-to bg-darkblue">Next: Add Modification Reason</button>
                            }
                        </div>
                    </div>
                    <div className="modal-content content500 tab-pane" id="removeVestingSchedule">
                        <div className="modal-header box-header">
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true"  onClick={this.resetActive}>×</button>
                            <h4 className="modal-title" >Confirm</h4>
                        </div>
                        <div className="modal-body invite-body text-center">
                            <p className="text-muted">{__('Please confirm that you want to remove vesting schedule from this security.')}</p>
                        </div>
                        <div className="modal-footer box-footer">
                            <button data-dismiss="modal" aria-hidden="true" onClick={this.resetActive} className="btn boxbtn-to bg-darkblue">Cancel</button>
                            { canRemove == 1 &&
                            <button className="btn boxbtn-to bg-darkblue" onClick={this.deleteVesting}>Confirm</button>
                            }
                            { canRemove == 0 &&
                            <a className="btn boxbtn-to bg-darkblue"  data-toggle="tab" href="#reminder">Confirm</a>
                            }
                        </div>
                    </div>
                    <div className="modal-content content500 tab-pane" id="reminder">
                        <div className="modal-header box-header">
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true"  onClick={this.resetActive}>×</button>
                            <h4 className="modal-title" >Reminder</h4>
                        </div>
                        <div className="modal-body invite-body text-center">
                            <p className="text-muted">You cannot remove vesting schedule from this security because it has been fully or partially exercised.</p>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
export default AddVestingSchedule;