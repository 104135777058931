import React, { Component, Fragment } from "react";
import {Radio, Input, Space, Tooltip, Checkbox, Table} from 'antd';
import { filterAmount, I } from "Utils";
import {CheckCircleFilled} from "@ant-design/icons";
class SeniorityDetailsModels extends Component {

    constructor(props) {
        super(props);
        this.state = {
            seniorityDetailsColumns : [
                {
                    title : '',
                    align : 'left',
                    dataIndex : 'name',
                    key : 'name',
                    width : '45%',
                },
                {
                    title : __('Seniority'),
                    align : 'left',
                    dataIndex : 'seniorityNew',
                    key : 'seniorityNew',
                    width : '27%',

                },
                {
                    title : __('Payout Order'),
                    align : 'left',
                    dataIndex : 'payoutOrder',
                    key : 'payoutOrder',
                    width : '27%',
                }
            ],
            seniorityHelp : __('Indicates the order in which the holders will get paid. Seniority ranks high to low, with 1 being the highest and will be paid out first. The higher the number, the less seniority that share class has.'),
            pariPassuHelp : __('If checked, this indicates that the share class as having equal seniority to an existing share class. Share classes with equal seniority will be paid out at the same time.'),
        }
        this.seniorityChange = this.seniorityChange.bind(this);
        this.pariPassuChange = this.pariPassuChange.bind(this);

    }

    componentDidMount() {
        const { advanceTerms } = this.props;
        /*const seniorityDetailsTable = advanceTerms.seniorityDetailsTable;
        this.setState({
            seniorityDetailsTable : seniorityDetailsTable
        })*/
    }

    /**
     * seniority change事件
     * @param e
     */
    seniorityChange = (e) => {
       const seniority = filterAmount(e, 9999999999999, false);
       const { seniorityChange } = this.props;
       seniorityChange(seniority, e.target);
    }

    /**
     * pariPassu change事件
     * @param e
     */
    pariPassuChange = (e) => {
        const pariPassu = e.target.checked;
        const { pariPassuChange } = this.props;
        pariPassuChange(pariPassu);
    }

    /**
     * 表格行高亮展示
     * @param record 表格行的内容
     * @param index 行索引
     * @returns {string}
     */
    setClassName=(record,index)=>{
        //判断索引相等时添加行的高亮样式
        const { roundNameValue } = this.props;
        return I(record.name, '') == I(roundNameValue, '') ? 'advance-model-table-active' : '';
    }

    render() {
        const { seniorityDetailsColumns, seniorityHelp, pariPassuHelp } = this.state;
        const { seniority, pariPassu, seniorityDetailsTable, loading } = this.props;
        const tableList = [...seniorityDetailsTable];

        return (
            <div className="advance-model-box">
                <CheckCircleFilled className="advance-model-option-circle" />
                <div className="advance-model-option-content">
                    <div>{__('Seniority details')}</div>
                    <div>{__('Enter the seniority details of the share class.')}</div>
                    <div>
                        <dl>
                            <dt>
                                {__('Seniority')}
                                <Tooltip title={seniorityHelp} color="#2b3946">
                                    <span className="table_icon title_icons">?</span>
                                </Tooltip>
                            </dt>
                            <dd>
                                <Input placeholder="" onChange={this.seniorityChange} value={seniority} className="advance-model-seniority" />
                            </dd>
                        </dl>
                        <dl className="pt-10">
                            <dt>
                                {__('Pari-passu')}
                                <Tooltip title={pariPassuHelp} color="#2b3946">
                                    <span className="table_icon title_icons">?</span>
                                </Tooltip>
                            </dt>
                            <dd>
                                <Checkbox checked={pariPassu} onChange={this.pariPassuChange} />
                            </dd>
                        </dl>
                    </div>
                </div>
                <div>
                    <Table
                        loading={loading}
                        className="advance-model-seniority-table"
                        size="middle"
                        pagination={false}
                        showHeader={true}
                        columns={ seniorityDetailsColumns }
                        dataSource={ tableList }
                        rowKey="keyId"
                        rowClassName={this.setClassName}
                    />
                </div>
                <div className="clear"></div>
                <div className="advance-model-height-1 mt-30"></div>
            </div>
        )

    }



}

export default SeniorityDetailsModels
