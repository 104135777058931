import React from "react";
//import $ from 'jquery';;
import Utils from "Utils";
import { post } from 'srcPath/http';
import {Link} from "react-router-dom";

class ModifyVestingCondition extends React.Component {
    constructor(props){
        super(props);
        this.state={
            data:{
                vesting_condition:"",
                modification_reason:"",
            },
            isError:0,
            vestingConditionList:[
                // {id:1,vesting_condition:""}
            ],
        }
    }
    showErrorTip = () =>{
        this.setState({
            isError:1
        })
    }
    componentDidMount() {
        // post('getVestingScheduleList', []).then((response)=> {
        //     if(response.data.code === 0){
        //         this.setState({
        //             vestingConditionList:response.data.data,
        //         });
        //     }else{
        //         console.log("failed, error code is: "+response.data.code);
        //     }
        // }).catch(function (error) {
        //     console.log(error);
        // });
    }

    recordData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        });
    }
    resetActive=()=>{
        window.$("#modifyVestingConditionModal .tab-pane").removeClass("active");
        window.$("#modify_condition_step1").addClass("active");
    }
    modifyVestingCondition=()=>{
        let requestData = this.state.data;
        requestData.id = this.props.curSelectSecurity.id;
        // post('exerciseEa',requestData).then((response)=> {
        //     if(response.data.code === 0){
        //         window.location.reload()
        //         // window.$("#exerciseOptionModal").modal("hide")
        //     }else{
        //         console.log("failed, error code is: "+response.data.code);
        //     }
        // }).catch(function (error) {
        //     console.log(error);
        // });
    }
    closeModal=()=>{
        window.$("#modifyVestingConditionModal").modal("hide")
    }
    render(){
        const { isError } = this.state;
        return(
            <div className="modal fade" id="modifyVestingConditionModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="modal-dialog tab-content dialog-top40">
                    <div className="modal-content content500 tab-pane active" id="modify_condition_step1">
                        <div className="modal-header box-header">
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Modify vesting condition for')} {this.props.curSelectSecurity.custom_label_id}</h4>
                        </div>
                        <div className="modal-body padding202620">
                            <div className="body-one">
                                <form className="form-horizontal font_reset_form">
                                    <div className="form-group add-items">
                                        {isError == 1 &&
                                            <div className="alert alert-warning alert-dismissible pink-box eq-tipbox marginBott20" role="alert">
                                                <p>{__('Vesting condition must be changed to continue.')}</p>
                                            </div>
                                        }
                                        <label className="col-xs-5 add-label">{__('Vesting condition')}<br/><i><span className="text-muted">{__('Required')}</span></i> </label>
                                        <div className="col-xs-7 ">
                                            <select name="vesting_condition" onChange={this.recordData} value={this.state.data.vesting_condition} className="form-control select-container">
                                                <option value="">{__('No additional vesting condition')}</option>
                                                {this.state.vestingConditionList.map((value,key)=>{
                                                    return(
                                                        <option value={value.id} key={key}>{value.vesting_condition}</option>
                                                    )
                                                })
                                                }
                                            </select>
                                        </div>
                                        <Link to={{ pathname : `/home/securities/templates`,state:{activeKey:'2'}} } onClick={this.closeModal} className="col-xs-7">{__('Manage vesting schedule')}</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer box-footer">
                            {this.state.data.vesting_condition!=="" && <button type="button" data-toggle="tab" data-target="#modify_condition_step2" className="btn boxbtn-to bg-darkblue">{__('Next: add modification reason')}</button>}
                            {this.state.data.vesting_condition=="" && <button type="button" onClick={this.showErrorTip}  className="btn boxbtn-to send-disabledBtn">{__('Next: add modification reason')}</button>}
                        </div>
                    </div>
                    <div className="modal-content content480 tab-pane " id="modify_condition_step2">
                        <div className="modal-header box-header">
                            <a data-target="#modify_condition_step1" type="button" className="back-btn" aria-controls="repurchase-step1" role="tab" data-toggle="tab"></a>
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.resetActive}>×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Modify vesting condition for')} {this.props.curSelectSecurity.custom_label_id}</h4>
                        </div>
                        <div className="modal-body padding2026">
                            <div className="body-one">
                                <form className="form-horizontal font_reset_form">
                                    <div className="form-group add-items">
                                        <label className="col-xs-5 add-label">{__('Modification reason')}<br/><i><span className="text-muted">{__('Required')}</span></i> </label>
                                        <div className="col-xs-7 ">
                                            <textarea name="modification_reason" value={this.state.data.modification_reason} onChange={this.recordData} placeholder="These notes are visible to legal administrators only." className="form-control text-area minHeight140" type="text" />
                                        </div>
                                        <p className="text-muted col-xs-7 col-xs-push-5 mb-0">{__('RSA boardmm01@gmail.com will be notified of this modification.')}</p>
                                    </div>
                                </form>

                            </div>
                        </div>
                        <div className="modal-footer box-footer invite-footer">
                            <button type="button" className="btn boxbtn-to bg-darkblue update-btn" onClick={this.modifyVestingCondition}>{__('Save')}</button>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
export default ModifyVestingCondition;