import React, { Component } from "react";
import {Button, Space} from "antd";
import "../style.scss";

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            buttonList: [
                { type: '', value: 'Overview'},
                { type: '', value: 'Settings'},
                { type: '', value: 'History'},
            ]
        }
    }

    getPage = (value) => {
        const { getPage } = this.props;
        getPage(value);
    }

    getButton = () => {
        const { buttonList } = this.state;
        const { pageName } = this.props;
        return buttonList.map((v, k)=>{
            const value = (v.value).toLowerCase();
            return <Button
                key={k}
                type={pageName.toLowerCase() == value && 'primary' }
                onClick={this.getPage.bind(this, value)}
            >{v.value}</Button>
        })
    }

    render() {


        return (
            <div className="sprout-setting-billing">
                <div className="sprout-setting-billing-tabs">
                    <Space size={5}>
                        {this.getButton()}
                    </Space>
                </div>
            </div>
        );
    }
}
export default Header;