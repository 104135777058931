import React, { Component } from 'react';
import { message, Button, Table } from 'antd';
import Loading from 'components/Loading';
import { post } from 'srcPath/http';
import { foundColumns, dividendsColumns, participtionColumns } from './column-config';
import attentionIcon from './images/attention-icon.png';
import completeIcon from './images/complete-icon.png';
import EditShareClassPlanModal from 'components/EditShareClassPlanModal';

import './style.scss'

export default class RightsAndPreferences extends Component {
    constructor(props){
        super(props);
        this.state = {
            shareClassList: [], 
            dividendsList: [],
            participationList: [],
            isShareClassComplete: true, 
            isDividendsComplete: true,
            isParticipationComplete: true,
            loading: true,
            showEditShareClass: false,
            initData: {}
        }
    }

    componentDidMount() {
		this.getData()
	}

	getData = async() => {

		try {
			const { data: resData } = await post('getRightsAndPreferences')

            const { code ,data } = resData;

            let isShareClassComplete = true;
            let isDividendsComplete = true;
            let isParticipationComplete = true;

			if (code === 0) {
                const { share_class_list, dividends_list, participation_list } = data;
                share_class_list.map((item) => {
                    if (!item.seniority) {
                        isShareClassComplete = false;
                    }
                    return item.key = item.share_class_id;
                })
                dividends_list.map((item) => {
                    if (!item.cumulative) {
                        isDividendsComplete = false;
                    }
                    return item.key = item.share_class_id;
                })
                participation_list.map((item) => {
                    if (!item.participating) {
                        isParticipationComplete = false;
                    }
                    return item.key = item.share_class_id;
                })
				this.setState({ shareClassList: share_class_list, dividendsList: dividends_list, participationList: participation_list, isShareClassComplete, isDividendsComplete, isParticipationComplete })
			} else {
				message.error(resData)
			}
		} catch(err) {
			message.error(err.message)
		}

		this.setState({ loading: false })
	}

    handleClickRow(record, e) {
		const share_class_id = record.share_class_id;
        const action = e.target.getAttribute("data-action");
        const innerText = e.target.innerText;
			if (action === 'edit') {
                this.setState({
                    showEditShareClass: true,
                    initData: { id: share_class_id }
                });
			}
    }
    
    closeEditShareClass() {
        this.setState({
            showEditShareClass: false
        });
    }

    render(){
        const { shareClassList, dividendsList, participationList, loading, showEditShareClass, initData, isShareClassComplete, isDividendsComplete, isParticipationComplete } = this.state;
        const notComplete = __('Incomplete: Do not use');
        const complete = __('table is complete')
        if (loading) {
            return <Loading />
        }

        return (
            <div className="rights-and-preferences-root">
                <h3>{__('Rights and Preferences')}</h3>
                <div className="rights-table-container">
                    <div className="rights-table-desc">
                        <img src={!isShareClassComplete ? attentionIcon : completeIcon} className="rights-table-desc-icon"></img>
                        <p className="rights-table-desc-title">{__('Share class details')}</p>
                        <p className="rights-table-desc-detail">{isShareClassComplete ? `${__('Detail')} ${complete}` : notComplete}</p>
                    </div>
                    <Table
                        columns={foundColumns}
                        dataSource={shareClassList}
                        pagination={false}
                        scroll={{ x: true }}
                        onRow={record => {
                            return {
                              onClick: this.handleClickRow.bind(this, record),
                            };
                          }}
                    />
                    <div className="rights-table-footer">{__('Lowest seniority number gets paid out first.')}</div>
                </div>
                <div className="rights-table-container">
                    <div className="rights-table-desc">
                        <img src={!isDividendsComplete ? attentionIcon : completeIcon} className="rights-table-desc-icon"></img>
                        <p className="rights-table-desc-title">{__('Dividends')}</p>
                        <p className="rights-table-desc-detail">{isDividendsComplete ? `${__('Dividends')} ${complete}` : notComplete}</p>
                    </div>
                    <Table
                        columns={dividendsColumns}
                        dataSource={dividendsList}
                        pagination={false}
                        scroll={{ x: true }}
                        onRow={record => {
                            return {
                              onClick: this.handleClickRow.bind(this, record),
                            };
                        }}
                    />
                </div>
                <div className="rights-table-container">
                    <div className="rights-table-desc">
                        <img src={!isParticipationComplete ? attentionIcon : completeIcon} className="rights-table-desc-icon"></img>
                        <p className="rights-table-desc-title">{__('Participation')}</p>
                        <p className="rights-table-desc-detail">{isParticipationComplete ? `${__('Participation')} ${complete}` : notComplete}</p>
                    </div>
                    <Table
                        columns={participtionColumns}
                        dataSource={participationList}
                        pagination={false}
                        scroll={{ x: true }}
                        onRow={record => {
                            return {
                              onClick: this.handleClickRow.bind(this, record),
                            };
                        }}
                    />
                </div>
                {showEditShareClass &&
                    <div className="editShareClassPlanHolder">
                        <EditShareClassPlanModal ref="editShareClassPlan" initData={initData} closeEditShareClass={this.closeEditShareClass.bind(this)} getData={this.getData}/>
                    </div>
                }
            </div>
        )
    }
}