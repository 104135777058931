import React from 'react';
import 'assets/css/dataroommodal.css';
import axios from 'axios';
import Utils from "Utils";
import {FolderOutlined} from "@ant-design/icons";
class AddChildFolderModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            data:{
                parent_id:"",
                name:""
            }
        }
    }

    setName=(e)=>{
        let curData = this.state.data;
        curData.name = e.target.value;
        curData.parent_id = this.props.dirInfo?.id || '0';
        this.setState({
            data:curData
        })
    }

    isDuplicateName=(name)=>{
        let topDirInfo = this.props.dirInfo;
        for(let i=0;i<topDirInfo.fileList?.length;i++){
            if(topDirInfo.fileList[i].type==="dir" && topDirInfo.fileList[i].id!==this.state.data.id && topDirInfo.fileList[i].name===name){
                return true;
            }
        }
        return false;
    }

    createTopFolder=()=>{
        if(!this.state.data.name.trim()){
            return
        }
        var targetName = this.state.data.name;
        if(this.isDuplicateName(targetName)){
            var duplicateCount = 1;
            var flag = true;
            var tmpName = "";
            while (flag){
                tmpName = targetName + " ("+duplicateCount+")";
                flag = this.isDuplicateName(tmpName);
                duplicateCount++;
            }
            let curData = this.state.data;
            curData.client_name = tmpName;
            this.setState({
                data:curData
            })
        }

        console.log(this.state.data, '=======');


        axios.post('https://api.getsprout.co', {
            company_id:Utils.getLocalCompanyInfo().company_id,
            action:'addNewSecFolder',
            data:this.state.data
        }).then((response)=> {
            console.log(response);
            if(response.data.code === 0){
                // let newTopDir = this.props.dirInfo;
                // newTopDir.fileList.unshift(response.data.data);
                // if (newTopDir?.fileList?.length > 0) {
                //     newTopDir.fileList[0].file_type = newTopDir.fileList[0].type || newTopDir.fileList[0].file_type
                // }
                this.setState({
                    data:{
                        parent_id:"",
                        name:""
                    }
                })
                const { onReload } = this.props
                onReload && onReload('Add', this.props.dirInfo)
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
            //用到this要注意this指向
        }).catch(function (error) {
            console.log(error);
        });
    }




    render(){
        const { close } = this.props
        return(
            <div>
                <div style={{paddingRight:0}}>
                    <div className="modal-dialog">
                        <div className="modal-content content-reset">
                            <div className="modal-header box-header">
                                <button type="button" className="close" onClick={close}>×</button>
                                <h4 className="modal-title" id="myModal2Label">{__('Add Folder')}</h4>
                            </div>
                            <div className="modal-body body-reset2030">
                                <p className="text-muted">{__('This folder will be added to')}:</p>
                                <p className="text-muted addfolder-name"><FolderOutlined />&nbsp;&nbsp;{this.props.dirInfo.name || __('Data Room drive')}</p>
                                <input onChange={this.setName} value={this.state.data.name} type="text" className="form-control" placeholder={__('e.g. Document')} />
                            </div>
                            <div className="modal-footer box-footer no-paddingtop">
                                <button onClick={this.createTopFolder} type="button" className="btn dataroom-btnReset" data-dismiss="modal">{__('Add')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default AddChildFolderModal;

