import React, { Component } from "react";
import {Button, Checkbox} from "antd";
import { printf, sprintf } from 'Utils/i18n';
import security from 'stores/security';
import "./style.scss";
class ConfirmSaveStakeholderModels extends Component {

    state = {
        checked: false
    };

    getSettingUrl = () => {
        const { checked } = this.state;
        this.saveLocalStorage(checked);
        close();
        this.props.history.push('/home/setting');
    }

    getSaveStakeholder = () => {
        const { checked } = this.state;
        const { type, saveStakeholder, close } = this.props;
        this.saveLocalStorage(checked);
        saveStakeholder(type);
        close();
    }

    checkedChange = (e) => {
        const { checked } = e.target;
        this.setState({checked});
    }

    saveLocalStorage = (checked) => {
        const uid = security.userInfo && security.userInfo.id;
        const companyId = security.companyInfo && security.companyInfo.company_id;
        if(checked){
            localStorage.setItem(`ALL-STAKEHOLDER-CONTROL-REMIND-${uid}-${companyId}`, '1');
        }else{
            localStorage.removeItem(`ALL-STAKEHOLDER-CONTROL-REMIND-${uid}-${companyId}`);
        }
    }

    render() {
        const { checked } = this.state;
        const { type } = this.props;
        return (
            <div className="stakeholders-confirm-save-root">
                <p>{__('Kindly be informed that your Stakeholder access control is presently disabled. As a result, stakeholders will be unable to view issued securities under their portfolio view, and emails will not be sent to them.')}<br/><br/>{__('We advise that you enable the control before adding stakeholders.')}</p>
                <div><Button type="primary" onClick={this.getSettingUrl}>{__('Go to enable')}</Button></div>
                <div><Button type="primary" onClick={this.getSaveStakeholder}>{printf(__('Continue to %s stakeholder'), type)}</Button></div>
                <div>
                    <Checkbox checked={checked} onChange={this.checkedChange}>{__('Don\'t remind me again')}</Checkbox>
                </div>
            </div>
        );
    }
}
export default ConfirmSaveStakeholderModels;