import React, {Component, Fragment} from "react";
import "./style.scss";
import {Button, Space} from "antd";
import { errorPrompt } from "../../errorPromptContent";

class ReminderModel extends Component {

    getLogin = () => {
        const { history } = this.props;
        close();
        history.push('/auth/login');
    }

    render() {
        const { userEmail, close, isSign } = this.props;
        return (
            <div className="signup-reminder-root">
                {isSign != 1 ? (
                    <Fragment>
                        <h4>Please be informed that an account has either been created or is currently being created for you by our company.<br/>To gain access, kindly contact your Company Administrator at <a href={`mailto:${userEmail}`}>{userEmail}</a>.</h4>
                        <Button type="primary" onClick={()=>{close()}}>Confirm</Button>
                    </Fragment>
                ) : (
                    <Fragment>
                        <h4>This account already exists. If you forgot your password, you can use "Forgot password" on the Sign in page and directly reset your password to access your account.</h4>
                        <Space size={20}>
                            <Button onClick={()=>{close()}}>Cancel</Button>
                            <Button type="primary" onClick={this.getLogin}>Ok</Button>
                        </Space>
                    </Fragment>
                ) }

            </div>
        );
    }
}
export default ReminderModel;