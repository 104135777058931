import React, { Component } from "react";
import {Button, message} from "antd";
import { calculator, I, formatNumber, formatCurrencyNumber } from "Utils";
import exportIcon from "../img/export-icon.png";
import Loading from 'components/Loading'
import { post } from 'srcPath/http';
import {printf} from "Utils/i18n";
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';

class ExportExcel extends Component {

    state = {
        loading: false,
    };

    compositeStakeholderData = () => {
        const { data, stakeholderDataList, stakeholderTotalList } = this.props;
        let { financingData, active, stakeholderData } = data;
        let excelData = [
            [ '', __('Current cap table'), '', ''],
            [ '', __('Current shares'), __('Current ownership'), __('Current valuation')],
        ];
        let empty = ['', '', '', ''];
        let newFinancingData = [];
        //header头部内容
        financingData.forEach((v, k)=>{
            if(v.isChecked){
                v.keyId = k;
                newFinancingData.push(v);
                excelData[0] = [...excelData[0], ...['', '', I(v.detailName, ''), ''] ];
                excelData[1] = [...excelData[1], ...['', __('Post-money shares'), __('Post-money ownership'), __('Post-money valuation')] ];
                empty = [...empty, ...['', '', '', '']];
            }
        });
        financingData = [...newFinancingData];
        excelData[0] = [...excelData[0], ...['isBold'] ];
        excelData[1] = [...excelData[1], ...['isBold'] ];
        const list = stakeholderDataList( 1, stakeholderData, financingData);

        list.forEach((v, k)=>{
            let stakeholder = [I(v.name, ''), `${formatFractionalNumber(I(v.quantity, ''))}isNum`, `${formatNumber(I(v.ownership, 0)) / 100}%`, `${formatCurrencyNumber(I(v.valuation, 0), 0)}isPrice`];
            financingData.forEach((v1, k1)=>{
                stakeholder = [...stakeholder, ...['', `${formatFractionalNumber(I(v[`shares${k1}`], 0))}isNum`, `${formatNumber(I(v[`ownership2${k1}`], 0)) / 100}%`, `${formatCurrencyNumber(I(v[`valuation${k1}`], 0), 0)}isPrice` ] ];
            });
            if(v.id == 0){
                const { children } = v;
                children && children.forEach(v1=>{
                    let stakeholderChildren = [I(v1.name, ''), `${formatFractionalNumber(I(v1.quantity, 0))}isNum`, `${formatNumber(I(v1.ownership, 0)) / 100}%`, `${formatCurrencyNumber(I(v1.valuation, 0))}isPrice`];
                    financingData.forEach((v2, k2)=>{
                        stakeholderChildren = [...stakeholderChildren, ...['', `${formatFractionalNumber(I(v1[`shares${k2}`], 0))}isNum`, `${formatNumber(I(v1[`ownership2${k2}`], 0)) / 100}%`, `${formatCurrencyNumber(I(v1[`valuation${k2}`], 0), 0)}isPrice` ] ];
                    });
                    excelData.push(stakeholderChildren);
                })
            }else{
                excelData.push(stakeholder);
            }
        });
        excelData.push(empty);
        const total = stakeholderTotalList(active);

        total.forEach(v=>{
            let totalAttr = [v.name, `${formatFractionalNumber(I(v.quantity1, 0))}isNum`, `${formatNumber(I(v.quantity2, 0)) / 100}%`, `${formatCurrencyNumber(I(v.valuation, 0))}isPrice`];
            financingData.forEach(v1=>{
                totalAttr = [...totalAttr, ...['', `${formatFractionalNumber(I(v[`shares${v1.keyId}`], 0))}isNum`, `${formatNumber(I(v[`ownership2${v1.keyId}`], 0)) / 100}%`, `${formatCurrencyNumber(I(v[`valuation${v1.keyId}`], 0), 0)}isPrice` ] ];
            });
            totalAttr = [...totalAttr, ...['isBold'] ];
            excelData.push(totalAttr);
        })
        excelData.push(empty);
        return excelData;
    }

    /**
     * shares需要导出的数据
     */
    compositeSharesData = () => {
        const { data, stakeholderDataList, shareClassTotalList } = this.props;
        let { financingData, shareClassDataList, isMultiple } = data;
        let convertibles;
        let excelData = [
            [ '', __('Current cap table'), '', ''],
            [ '', __('Current shares'), __('Current ownership'), __('Current valuation')],
            [ __('Common Share classes'), '', '', '', ''],
        ];
        let empty = ['', '', '', ''];
        let detailEmpty = [];
        //header头部内容
        let newFinancingData = [];
        financingData.forEach((v, k)=>{
            if(v.isChecked){
                v.keyId = k;
                newFinancingData.push(v);
                excelData[0] = [...excelData[0], ...['', '', I(v.detailName, ''), ''] ];
                excelData[1] = [...excelData[1], ...['', __('Post-money shares'), __('Post-money ownership'), __('Post-money valuation')] ];
                detailEmpty = [...detailEmpty, ...['', '', '', '']];
                empty = [...empty, ...['', '', '', '']]
            }
        });
        financingData = [...newFinancingData];
        excelData[0] = [...excelData[0], ...['isBold'] ];
        excelData[1] = [...excelData[1], ...['isBold'] ];
        excelData[2] = [...excelData[2], ...detailEmpty, ...['isBold'] ];

        const list = stakeholderDataList( 2, shareClassDataList, financingData);
        const csData = this.getCsPsExport(list, financingData, 'CS', __('Total common shares'));
        excelData = [...excelData, ...csData, ...[empty]];
        excelData.push([...[__('Preferred Share classes'), '', '', ''], ...detailEmpty, ...['isBold']]);
        let stock = [__('Scenario stock'), '', '', ''];
        list.forEach(v=>{
            if(v.id === 'modeledRound'){
                convertibles = v.children[0];
                const investors = v.children[1];
                financingData.forEach((v1, k1)=>{
                    stock = [...stock, ...['', `${formatFractionalNumber(I(investors[`shares${k1}`], 0))}isNum`, `${formatNumber(I(investors[`ownership2${k1}`], 0)) / 100}%`, `${formatCurrencyNumber(I(investors[`valuation${k1}`], 0))}isPrice` ] ];
                });
            }
        });
        excelData.push([...stock, ...['isBold']]);
        const psData = this.getCsPsExport(list, financingData, 'PS', __('Total preferred shares'));
        excelData = [...excelData, ...psData, ...[empty] ];
        //Warrants
        excelData.push([...[__('Warrants'), '', '', ''], ...detailEmpty, ...['isBold']]);
        const warrantData = this.getCsPsExport(list, financingData, 'WARRANT', __('Total warrant'));
        excelData = [...excelData, ...warrantData, ...[empty] ];

        //CN
        excelData.push([...[__('Convertibles'), '', '', ''], ...detailEmpty, ...['isBold']]);
        let cnList = [];
        financingData.forEach((v, k)=>{
            const {cnTotalList} = v;
            Object.keys(cnTotalList).forEach((v1, k1)=>{
                const cn = ['', `${formatFractionalNumber(I(cnTotalList[v1].sharesTotal, 0))}isNum`, `${formatNumber(I(cnTotalList[v1].ownershipTotal, 0)) / 100}%`, ''];
                cnList[v1] = [...(cnList[v1] ? cnList[v1] : [v1, '', '', '']), ...cn];
            })
        });

        let cnTotal = [__('Total convertibles'), '', '', ''];
        financingData.forEach((v, k)=>{
            cnTotal = [...cnTotal, ...['', `${formatFractionalNumber(I( convertibles[`shares${k}`], 0))}isNum`, `${formatNumber(I( convertibles[`ownership2${k}`], 0)) / 100}%`, '' ] ];
        });
        excelData.push([...cnTotal, ...['isTotal']]);

        if(isMultiple){
            const investorsKeyIndex = (financingData?.[1]?.sharesListB).findIndex(v=>v.key === 'second_round_investors_key');
            const cnKeyIndex = (financingData?.[1]?.sharesListB).findIndex(v=>v.key === 'second_round_cn_key');
            excelData.push([...[empty]]);
            const { valuation:investorsValuation = 0, quantity:investorsQuantity = 0, ownership2:investorsOwnership2 = 0 } = financingData?.[1]?.sharesListB?.[investorsKeyIndex];
            const { valuation:cnValuation = 0, quantity:cnQuantity = 0, ownership2:cnOwnership2 = 0 } = financingData?.[1]?.sharesListB?.[cnKeyIndex];
            excelData.push([...[printf(__('%s new round investors'), financingData?.[0].detailName), '', '', ''], ...[...['', '', '', ''], ...['', `${formatFractionalNumber(investorsQuantity)}isNum`, `${formatNumber(investorsOwnership2 / 100)}%`, `${formatCurrencyNumber(investorsValuation, 0)}isPrice` ]], ...['isBold']]);
            excelData.push([...[printf(__('%s convertibles'), financingData?.[0].detailName), '', '', ''], ...[...['', '', '', ''], ...['', `${formatFractionalNumber(cnQuantity)}isNum`, `${formatNumber(cnOwnership2 / 100)}%`, `${formatCurrencyNumber(cnValuation, 0)}isPrice` ]], ...['isBold']]);
            excelData.push([...[empty]]);
        }

        //EA
        const totalList = shareClassTotalList(2);
        excelData.push([...[__('Test incentive plan'), '', '', ''], ...detailEmpty, ...['isBold']]);
        totalList.forEach((v, k)=>{
            let ea = [I(v.name, ''), `${formatFractionalNumber(I(v.quantity1, ''))}isNum`, `${formatNumber(I(v.quantity2, 0)) / 100}%`, `${formatCurrencyNumber(I(v.valuation, 0), 0)}isPrice`];
            financingData.forEach((v1)=>{
                ea = [...ea, ...['', `${formatFractionalNumber(I(v[`shares${v1.keyId}`], 0))}isNum`, `${formatNumber(I(v[`ownership2${v1.keyId}`], 0)) / 100}%`, '' ] ];
            });
            excelData.push([...ea, ...(k===2 ? ['isTotal'] : [])]);
        });
        excelData.push(empty);
        return excelData;
    }

    getCsPsExport = (list, financingData, type, totalName) => {
        let total = [];
        let returnData = [];
        list.forEach((v)=>{
            if(v.type == type){
                total[0] = I(totalName, '');
                total[1] = calculator.agile(`${I(total[1], 0)} + ${I(v.quantity, '0')}`);
                total[2] = calculator.agile(`${I(total[2], 0)} + ${formatNumber(I(v.ownership, '0')) / 100 }`);
                total[3] = calculator.agile(`${I(total[3], 0)} + ${I(v.valuation, '0')}`);
                // let stakeholder = [I(v.name, ''), `${formatFractionalNumber(I(v.quantity, ''))}isNum`, `${formatNumber(I(v.ownership, 0)) / 100}%`, `${formatCurrencyNumber(I(v.valuation, 0), 0)}isPrice`];

                let cs = [I(v.name, ''), `${formatFractionalNumber(I(v.quantity, ''))}isNum`, `${formatNumber(I(v.ownership, 0)) / 100}%`, `${formatCurrencyNumber(I(v.valuation, 0), 0)}isPrice`];
                financingData.forEach((v1, k1)=>{
                    cs = [...cs, ...['', `${formatFractionalNumber(I(v[`shares${k1}`], 0))}isNum`, `${formatNumber(I(v[`ownership2${k1}`], 0)) / 100}%`, `${formatCurrencyNumber(I(v[`valuation${k1}`], 0), 0)}isPrice` ] ];
                    const i = k1 * 4 + 4 ;
                    total[i] = '';
                    total[i+1] = calculator.agile(`${I(total[i+1], 0)} + ${I(v[`shares${k1}`], '0')}`);
                    total[i+2] = calculator.agile(`${I(total[i+2], 0)} + ${formatNumber(I(v[`ownership2${k1}`], '0')) / 100}`);
                    total[i+3] = calculator.agile(`${I(total[i+3], 0)} + ${I(v[`valuation${k1}`], '0')}`);
                });
                returnData.push(cs);
            }
        })
        if(!returnData.length){
            return [];
        }
        total = total.concat(['isTotal']);
        total.forEach((v, k)=>{
            const i = calculator.agile(`${k} % 4`);
            i == 1 && (total[k] += 'isNum');
            i == 2 && (total[k] += '%');
            i == 3 && (total[k] += 'isPrice');
        });
        returnData.push(total);
        return returnData;
    }

    downloadExcel = () => {
        const { data, updateState, checkRequest } = this.props;
        const { stakeholderDataList } = data;
        const stakeholderData = [...stakeholderDataList];
        const checkRes = checkRequest();
        if(!checkRes){
            return;
        }
        updateState({stakeholderData, active: 1}, null , null, null, true, async () => {
            Loading.global(true);
            const postData = {
                shareList: JSON.stringify(this.compositeSharesData()),
                stakeholderList: JSON.stringify(this.compositeStakeholderData())
            };
            try {
                const { data: resData } = await post('exportScenarioModeling', postData)
                const { code, data } = resData || {}
                if (code == 0) {
                    message.success(__('success!'));
                } else {
                    message.error(data)
                }
            } catch(err) {
                message.error(err && err.message)
            }
            Loading.global(false);
        })
    }

    render() {
        const { loading } = this.state;
        return (
            <div className="new-financing-capitalization-tit">
                <div>
                    <h2>{__('Capitalization Breakdown')}</h2>
                    <div>{__('Results for selected scenarios above')}</div>
                </div>
                <Button loading={loading} type="primary" onClick={this.downloadExcel}><img src={exportIcon} />{__('Export')}</Button>
            </div>
        );
    }
}
export default ExportExcel;