import React, { Component } from "react";
import "../style.scss";
import {Button, message, Space, Spin} from "antd";
import { post } from 'srcPath/http';
import { escapeVariable } from "components/Template"
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { PDFReader } from 'react-read-pdf';


class TemplateDetail extends Component {

    state = {
        loading: false,
        html: '',
        pdfFile: '',
        pageCount: 1,
    };

    componentDidMount() {
        this.getTemplateInfo();
    }

    getTemplateInfo = async () => {
        const { id } = this.props;
        this.setState({ loading: true })
        try {
            const { data: resData } = await post('getTemplateById', {id})
            const { data, code } = resData || {}
            if (code == 0) {
                this.setState({
                    pdfFile: data.base64File && atob(data.base64File),
                    html: escapeVariable(0, id, data && data.template_content),
                })
            } else {
                message.error(data)
            }
        } catch(err) {
            message.error(err && err.message)
        }
        this.setState({ loading: false })
    }

    showPage = (page, id = 0, html = '', hideBar = false, pageCount) => {
        const { saveIsHideBar, saveState } = this.props;
        saveIsHideBar(hideBar);
        saveState({ page, id, html, pageCount});
    }

    pushTemplateInfo = (pageCount) => {
        this.setState({pageCount})
    }

    getTemplatePage = () => {
        const { pageCount, pdfFile } = this.state;
        const pageArray = [...new Array(pageCount).keys()];
        return pdfFile && pageArray.map((v, k)=>{
            return <PDFReader key={k} data={pdfFile} width={800} page={(k+1)} onDocumentComplete={this.pushTemplateInfo}/>
        })
    }

    render() {
        const { loading, html, pdfFile, pageCount } = this.state;
        const { id } = this.props;
        return (
            <div className="docusign-template-detail">
                <div className="docusign-template-button">
                    <Button className="docusign-template-callback" onClick={this.showPage.bind(this, 1)}><i className="fa fa-angle-left board-icon-1"></i>{__('Back To Template')}</Button>
                    <Button loading={loading} type="primary" onClick={this.showPage.bind(this, 3, id, html, true, pageCount)}>{__('Edit templates')}</Button>
                </div>
                <Spin spinning={loading}>
                    <div className="docusign-template-detail-a4"><div className="docusign-template-detail-a4-content">{this.getTemplatePage()}</div></div>
                </Spin>
            </div>
        );
    }
}
export default TemplateDetail;
