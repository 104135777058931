import React, { Fragment } from 'react';
import { Table } from 'antd';
import { formatCurrencyNumber } from 'Utils'

export default function Exercise(props) {
	const data = props.data.map((item, index) => {
		item.key = index;
		return item;
	}) || [];
	

	const renderValue = data => data || '--'

	const Columns = [
		{
		  title: __('Certificate'),
		  dataIndex: 'customlabelid',
		  render: (text, record) => <div><p><b>{text}</b></p>{record.exercise_date}<p></p></div>
		},
		{
		  title: __('Exercise'),
		  dataIndex: 'exercise',
		  // TODO
		  render: (text, record) => <div><p><b>{text} shares</b></p><p>{formatCurrencyNumber(record.exercise_price)}</p></div>
		},
		{
		  title: __('Approver'),
		  dataIndex: 'approver',
		  render: renderValue,
		},
		{
		  title: __('Cash Paid'),
		  dataIndex: 'cash_paid',
		  render: text => formatCurrencyNumber(text),
		},
	  ];


	return <Fragment>
 		<div className="certificate-detail-section">
		 <Table
			size="small"
			columns={Columns}
			locale={{emptyText: __('No Data')}}
			dataSource={data}
			pagination={false}
			scroll={{ x: true }}
		/>
		</div>	
	</Fragment>
}