import React, { Component, Fragment } from 'react';
import { Input, Button, message, Upload } from 'antd';
import security from 'stores/security';
import { post } from 'srcPath/http';
import Utils from 'Utils';

class DeleteModal extends Component {
	state = {
		loading: false
	}

	onSubmit = async () => {
		this.setState({ loading: true })

		const { data: itemData, callback, close } = this.props 

		try {
			const { data } = await post('deleteDocumentSet', {
				id: itemData.id
			})

			if (data.code === 0) {
				this.setState({ loading: false })

				callback && callback()
				close();
			} else {
				message.error(data.data);
			}
		} catch (e) {
			message.error(e.message)
		}

		this.setState({ loading: false })
	}

	render() {
		const { data, close } = this.props

		return <div style={{ padding: 30 }}>
				<p>Are you sure you want to delete this document set?</p>
				<div style={{ marginTop: 30, textAlign: 'center' }}>
					{
						<Fragment>				
							<Button style={{ marginRight: 6 }} onClick={close}>Cancel</Button>
							<Button type="primary" loading={this.state.loading} onClick={this.onSubmit}>Delete</Button>
						</Fragment>
					}
				</div>
		</div>
	}
}

export default DeleteModal