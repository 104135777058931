import React, { Component } from 'react';
import Modal, { ModalContent } from 'components/Modal';
import { Spin, message, Button, Tooltip, Dropdown, Menu } from 'antd';
import { DownOutlined } from "@ant-design/icons";
import { post } from 'srcPath/http';
import AccountProfileModal from './AccountProfileModal';
import PasswordModal from './PasswordModal';
import SignatureModal from './SignatureModal';
import AdditionalEmailModal from './AdditionalEmailModal';
import SwitchEmailModal from './SwitchEmailModal';
import security from 'stores/security';
import Loading from 'components/Loading'
import './style.scss';
import classnames from 'classnames';
const renderValue = val => val || '--'

class PersonalCenter extends Component {
	modalRef = null;

	state = {
		loading: true,
		infoData: {}
	}

	componentDidMount() {
		this.getAccountProfileInfo()
	}

	componentWillUnmount() {
		this.modalRef && this.modalRef.close()
	}
	
	actionCallback = () => {
		this.getAccountProfileInfo()
		security.getStatus()
	}

	openChangeNameModal = () => {
		const { userName, contactEmail } = this.state.infoData;

		this.modalRef = Modal.open({
			component: props => <ModalContent close={props.close} title={__('Edit Your Information')}>
				<AccountProfileModal defaultValue={{ userName, contactEmail }} close={props.close} callback={this.actionCallback} />
			</ModalContent>,
			className: 'sprout-modal account-profile-modal',
			width: 550
		})
	}

	openChangePasswordModal = () => {
		this.modalRef = Modal.open({
			component: props => <ModalContent close={props.close} title={__('Change Your Password')}>
				<PasswordModal close={props.close} />
			</ModalContent>,
			className: 'sprout-modal password-modal',
			width: 600
		})
	}


	openSignatrueModal = () => {
		const { documentSignature } = this.state.infoData;

		this.modalRef = Modal.open({
			component: props => <ModalContent close={props.close} title={__('Set Signature')}>
				<SignatureModal documentSignature={documentSignature} close={props.close} callback={this.actionCallback} />
			</ModalContent>,
			className: 'sprout-modal signature-modal',
			width: 500
		})
	}
	openChangeEmailModal = () => {
		let { infoData } = this.state;
		let { contactEmail } = infoData;
		this.modalRef = Modal.open({
			component: props => <ModalContent close={props.close} title={__('Add Additional Email')}>
				<AdditionalEmailModal close={props.close} email={contactEmail} callback={this.actionCallback} />
			</ModalContent>,
			className: 'sprout-modal email-modal',
			width: 700
		})
	}

	openSwitchEmailModal() {
		let { infoData } = this.state;
		let { contactEmail, secondaryEmail } = infoData;
		this.modalRef = Modal.open({
			component: props => <ModalContent close={props.close} title="Switch to primary email">
				<SwitchEmailModal close={props.close} primary={contactEmail} secondary={secondaryEmail} callback={this.actionCallback} />
			</ModalContent>,
			className: 'sprout-modal email-modal',
			width: 600
		})
	}
	getAccountProfileInfo = async () => {
		this.setState({ loading: true })

		try {
			const { data } = await post('getAccountProfileInfo', { uid: security.userInfo.id })

			if (data.code === 200) {
				this.setState({ infoData: data.data })
			}
		} catch (e) {
			message.error(e.message)
		}

		this.setState({ loading: false })
	}
	getMenuList = (val) => (
		<Menu onClick={this.operateClickEvent.bind(this, val)}>
			{val == 1 && <Menu.Item key="1">
				<div >Switch to primary email </div>
			</Menu.Item>}
			<Menu.Item key="2">
				<div>Remove</div>
			</Menu.Item>
		</Menu >
	)
	operateClickEvent = (val, index) => {
		let { key } = index
		switch (key) {
			case '1':
				this.openSwitchEmailModal(val)
				break;
			case '2':
				this.removeClickEvent(val)
				break;
			default:
				break;
		}
	}
	removeClickEvent() {
		let { infoData } = this.state;
		let { secondaryEmail } = infoData || {};
		Loading.global(true)
		post('removeSecondaryEmail',{ uid: security.userInfo.id, secondary_email: secondaryEmail }).then(res => {
			let {data}=res
			if (data.code === 0) {
				this.getAccountProfileInfo()
			} else {
				message.error(data.data)
			}
		}).catch(function (error) {
			message.error(data.data)
		});
		Loading.global(false)
	}

	render() {
		const { loading, infoData } = this.state;
		const { userName, contactEmail, documentSignature, secondaryEmail, secondaryEmailActivateState } = infoData || {};

		return <div className="content">
			<div className="personan-center-wrap">
				<Spin spinning={loading}>
					<div className="content-box">
						<div className="content-header">{__('Account profile')}</div>
						<div className="content-detail">
							<div className="personan-center-item">
								<div className="personan-center-label">{__('Name')}</div>
								<div className="personan-center-value">
									<span>{renderValue(userName)}</span>
									<Button type="primary" ghost onClick={this.openChangeNameModal}>{__('Edit')}</Button>
								</div>
							</div>
							<div className="personan-center-item">
								<div className="personan-center-label">{__('Primary email (Contact email)')}</div>
								<div className="personan-center-value">{renderValue(contactEmail)}</div>
							</div>
							<div className="personan-center-item">
								<div className="personan-center-label form-email-help">
									<div>{__('Additional email')}</div>
									<Tooltip title={__('Please add a personal email address as a backup login option. A verification email will be sent to this address to complete the process.')}>
										<div className="form-email-help-icon">?</div>
									</Tooltip>
								</div>
								<div className="personan-center-value">
									<div>
										<span className={classnames({'activate-state':secondaryEmailActivateState == 0})}>{renderValue(secondaryEmail)}</span>
										{secondaryEmail && secondaryEmailActivateState == 0 ? <span className="file_text red-disc">&nbsp;&nbsp;&nbsp;&nbsp;{__('Pending')}</span > : ""}
									</div>
									{secondaryEmail ?
										<Dropdown Dropdown overlay={this.getMenuList(secondaryEmailActivateState)} placement="bottomRight" trigger={['click']}>
											<div className="tableMenu" ><DownOutlined style={{ color: '#FFF' }} /></div>
										</Dropdown> : (<Button type="primary" ghost onClick={this.openChangeEmailModal}>{__('Add')}</Button>)}
								</div>
							</div>
						</div>
					</div>

					<div className="content-box">
						<div className="content-header">{__('Security')}</div>
						<div className="content-detail">
							<div className="personan-center-item">
								<div className="personan-center-label">{__('Password')}</div>
								<div className="personan-center-value">
									<span>********</span>
									<Button type="primary" ghost onClick={this.openChangePasswordModal}>{__('Edit')}</Button>
								</div>
							</div>
							<div className="personan-center-item">
								<div className="personan-center-label">{__('Document signature')}</div>
								<div className="personan-center-value">
									<span className="personan-center-signature">{renderValue(documentSignature)}</span>
									<Button type="primary" ghost onClick={this.openSignatrueModal}>{__('Edit')}</Button>
								</div>
							</div>
						</div>
					</div>
				</Spin>
			</div >
		</div >
	}
}

export default PersonalCenter