import React from 'react';
import { post } from 'srcPath/http';
import Loading from 'components/Loading';
import { CloseCircleOutlined } from "@ant-design/icons";
import { message, Tooltip, Popconfirm } from "antd";
import './style.scss';


export const renderWalletAddress = (value, cb, noAction = false) => {
    const addressData =  Array.isArray(value) ? value : [value]

    const handleDeleteLabel = async (address) => {
        Loading.global(true)
        try {

            const { data: resData } = await post('removeOneAddressAssociation', {
                wallet_address: address
            })

            const { code, data } = resData || {}

            if (code === 0) {
                message.success('success')
                cb && cb()
            } else  {
                message.error(data)
            }

        } catch(e) {
            message.error(e.message)
        }

        Loading.global(false)
    }

    return (
        <div>
            {addressData.map((item, index) => 
                <div className="table-cell-wallet" key={index}>
                    <Tooltip title={item}><a style={{ display: 'inline-block', width: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', paddingRight: '10px' }}>{item || '--'}</a></Tooltip>
                    {
                        !noAction && <Popconfirm
                            title={__("Are you sure you want to remove the wallet association?")}
                            onConfirm={handleDeleteLabel.bind(this, item)}
                            okText={__("Yes")}
                            cancelText={__("No")}
                        >
                            <a><CloseCircleOutlined /></a>
                        </Popconfirm>
                    }
                </div>
                
            )}
        </div>
    )
}

export default function RenderAddress({ children, cb, tooltip = true, address, width, noAction = false }) {
    const handleDeleteLabel = async () => {
        Loading.global(true)
        try {

            const { data: resData } = await post('removeOneAddressAssociation', {
                wallet_address: address
            })

            const { code, data } = resData || {}

            if (code === 0) {
                message.success(__('success'))
                cb && cb()
            } else  {
                message.error(data)
            }

        } catch(e) {
            message.error(e.message)
        }

        Loading.global(false)
    }

    return <div className="table-cell-wallet">
        <Tooltip title={children}>
            <div className="table-cell-wallet-content" style={ width ? { maxWidth: width } : {}}>
                {children}
            </div>
        </Tooltip>
        {
            !noAction && <Popconfirm
                title={__("Are you sure you want to remove the wallet association?")}
                onConfirm={handleDeleteLabel}
                okText={__("Yes")}
                cancelText={__("No")}
            >
                <a><CloseCircleOutlined /></a>
            </Popconfirm>
        }
    </div>
    


}

