import React, {Component, Fragment} from 'react';
import {Button, message} from 'antd';
import Loading from 'components/Loading';
import {post} from 'srcPath/http';
import {calculator, I} from 'Utils';
import FoundingRounds from './components/FundingRounds';
import CashRaised from './components/CashRaised';
import FullyDilutedShare from "./components/FullyDilutedShare";
import {formatDateForDatePicker} from 'Utils/format/formatDate';
import './style.scss'

export default class FinanceHistory extends Component {

    state = {
        userSettings: {},
        fundingRounds: [],
        cashRaised: [],
        activeKey: '1',
        loading: true,
        listLoading: true,
        query: '',
        fullyDilutedShareId: 0,
    }

    componentDidMount() {
		this.getData();
	}

	getData = async() => {
        this.setState({listLoading: true})
		try {
			const { data: resData } = await post( 'getFinancingHistory')

            const { code ,data } = resData;

			if (code === 0) {
                const { userSettings, newFundingRounds, cashRaised } = this.initFinancingHistory(data);
				this.setState({ userSettings, fundingRounds: newFundingRounds, cashRaised });
			} else {
				message.error(resData)
			}
		} catch(err) {
			message.error(err.message)
		}

		this.setState({ loading: false, listLoading: false })
	}

    /**
     * 初始化数据
     * @param data
     * @returns {{cashRaised, userSettings, newFundingRounds: *}}
     */
	initFinancingHistory = (data) => {
        const { userSettings, fundingRounds, cashRaised } = data;
        fundingRounds.map((fund, index) => {
            fund.key = index
            fund.currency = userSettings.currency;
            fund.shareClassId = fund.id;
            I(fund.sub_round_list, '') && (fund.children = this.getChildrenList(fund.id, fund.sub_round_list));
            fund.color = this.colorRandom();
            fund.ea_arr = this.initEaList(fund.ea_arr, fund.historyEaId);
        });
        //fundingRounds 按时间升序排序
        const newFundingRounds = this.dateSort(fundingRounds);
        cashRaised.map((cash, index) => {
            cash.key = index
            cash.currency = userSettings.currency;
        });
        return {userSettings, newFundingRounds, cashRaised};
    }

    initEaList = (eaList, newHistoryEaId) => {
	    let newEaList = I(eaList, '');
        newEaList && newEaList.forEach((v, k)=>{
            newEaList[k].isChecked = newHistoryEaId ? newHistoryEaId.indexOf(v.id) !== -1 : true;
        })
        return newEaList;
    }

    /**
     * fundingRounds 按时间排序
     * @param dataList
     * @returns {*}
     */
    dateSort = (dataList) => {
        dataList.sort( (a, b) => formatDateForDatePicker(a.closingDate).diff(formatDateForDatePicker(b.closingDate), 'days'));
        return [...dataList]
    }

    /**
     * 随机颜色
     * @returns {string}
     */
    colorRandom = () => {
        const color = ['#B6DC94', '#84C54C', '#EBE59F', '#EBE59F', '#EBE59F', '#13AACD', '#C8A5D5', '#BF70DE', '#7492AE', '#7492AE'];
        const random = Math.floor( calculator.mul( Math.random(), 10) );
        return color[random];
    }

    /**
     * 区分financing history子集列表（方便区分不同菜单）
     * @param subRoundList
     * @returns {*}
     */
	getChildrenList = ( shareClassId, subRoundList) => {
        I(subRoundList, '') && subRoundList.forEach((v, k)=>{
            subRoundList[k].shareClassId = shareClassId;
            subRoundList[k].isChildren = true;
        });
        return subRoundList;
    }

    callback = (key, fullyDilutedShareId = 0, query = '') => {
	    //如果是通过 Fully diluted shares(3) 进入到 (1) 需要重新请求列表接口
        query && this.getData();
        this.setState({ activeKey: key, fullyDilutedShareId, query });
    }

    saveState = (data, callback) => {
	    this.setState(data, () => callback && callback() );
    }

    render(){
        const { userSettings, fundingRounds, cashRaised, activeKey, loading, listLoading, query, fullyDilutedShareId } = this.state;
        if (loading) {
            return <Loading />
        }
        return (
            <Fragment>
            {activeKey !== '3' ?
                <div className="financing-history-root">
                    <div className="btns-wrap"><Button type={activeKey === '1' && 'primary'} onClick={this.callback.bind(null, '1')} >{__('Funding rounds')}</Button> <Button type={activeKey === '2' && 'primary'} onClick={this.callback.bind(null, '2')}>{__('Cash raised')}</Button></div>
                    {
                        activeKey === '1' ? <FoundingRounds listLoading={listLoading} callback={this.callback} query={query} getData={this.getData} currency={userSettings.currency} data={fundingRounds} /> : <CashRaised currency={userSettings.currency} data={cashRaised} />
                    }
                </div> :
                <FullyDilutedShare fundingRounds={fundingRounds} query={query} id={fullyDilutedShareId} callback={this.callback} saveState={this.saveState} />
            }
            </Fragment>
        )
    }
}