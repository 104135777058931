import React, { Component } from 'react';
import FairHeader from './components/FairHeader'
import Echarts from './components/echarts/index'
import { formatDate } from 'Utils/format/formatDate'
import { message, Spin } from 'antd';
import moment from "moment";
import FMVTable from './components/FMVTable'
import Modal from 'components/Modal';
import AdvanceTermsModels from './components/AdvanceTermsModels/AdvanceTermsModels'
import './style.scss';
import { I, formatNumber, formatCurrencyNumber } from 'Utils';
import { post } from 'srcPath/http';


// table

class TokenValuation extends Component {
    //advanceTerms浮层
    advanceTermsModal = null;
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            loading: false,
            data: '',
            shareClass: [], //CommA commB
            psClass: [],
            datalist: [],
            EchartsSourec: []
        }
    }

    componentDidMount = () => {
        this.payOverView()
    }
    payOverView = async () => {
        this.setState({ loading: true })
        try {
            const { data: resData } = await post('getCoinFairMarketValue')
            const { code, data } = resData;
            const { fvmList, currency } = data || {};
            if (code === 0) {
                if (!I(data, '')) {
                    message.error(data)
                    return;
                }
                let dataSource = fvmList
                let EchartsSourec = [...fvmList]
                EchartsSourec.sort(function (a, b) {
                    let dataA = moment(a.effectiveDate, 'DD/MM/YYYY').format('YYYYMMDD');
                    let dataB = moment(b.effectiveDate, 'DD/MM/YYYY').format('YYYYMMDD');
                    return dataA >= dataB ? 1 : -1;
                })
                let datalist = EchartsSourec.map((item) => {
                    return formatDate(item.effectiveDate)
                })
                let newdatalist = EchartsSourec.map((item) => {
                    return item.effectiveDate
                })
                let marketlist = EchartsSourec.map((item) => {
                    return formatNumber(item.fairMarketValue)
                })
                this.setState({
                    dataSource,
                    loading: false,
                    share: dataSource.length > 0 ? formatNumber(dataSource[0].fairMarketValue) : formatNumber('0.00'),
                    eff_date: dataSource.length > 0 ? formatDate(dataSource[0].effectiveDate) : '',
                    currency,
                    marketlist,
                    datalist,
                    newdatalist,
                    EchartsSourec
                })

            } else {
                message.error(data)
            }
        } catch (err) {
            message.error(err && err.message)
        }
    }

    bindclick = () => {
        const { shareClass, select_list, newdatalist, currency, EchartsSourec } = this.state
        this.advanceTermsModal = Modal.open({
            component: props => <AdvanceTermsModels
                close={props.close}
                payOverView={this.payOverView}
                isAdd={true}
                newdatalist={newdatalist}
                currency={currency}
                EchartsSourec={EchartsSourec}
            />,
            className: 'certificate-modal-wrap',
            width: '780px',
            maskClosable: false
        })
    }

    judgeTime = (data) => {
        var date = data.toString();
        var year = date.substring(0, 4);
        var month = date.substring(4, 6);
        var day = date.substring(6, 8);
        var d1 = new Date(year + '/' + month + '/' + day);
        var dd = new Date();
        var y = dd.getFullYear();
        var m = dd.getMonth() + 1;
        var d = dd.getDate();
        var d2 = new Date(y + '/' + m + '/' + d);
        var iday = parseInt(d2 - d1) / 1000 / 60 / 60 / 24;
        return iday;
    }
    /**
      * 卸载
      */
    componentWillUnmount() {
        if (this.advanceTermsModal) {
            this.advanceTermsModal.close();
        }
    }


    handleOk() {
        this.setState({
            isModalVisible: false
        })
    }
    handleCancel() {
        this.setState({
            isModalVisible: false
        })
    }

    render() {
        const { dataSource, datalist, marketlist, loading, shareClass, share, eff_date, select_list, newdatalist, currency, EchartsSourec } = this.state

        return (
            <div className='tokenvaluation'>

                <FairHeader bindclick={this.bindclick}></FairHeader>
                <Spin tip="Loading..." spinning={loading}>
                    <Echarts
                        dataSource={dataSource}
                        datalist={datalist}
                        marketlist={marketlist}
                        share={share}
                        eff_date={eff_date}
                    ></Echarts>
                    <FMVTable
                        newdatalist={dataSource}
                        dataSource={dataSource}
                        payOverView={this.payOverView}
                        currency={currency}
                        EchartsSourec={EchartsSourec}
                    ></FMVTable>

                </Spin>
            </div >
        )
    }
}
export default TokenValuation