import { observable } from 'mobx';
import i18n, { LOCAL_LANG_FLAG } from 'Utils/i18n';
import URL from 'Utils/URL';

class UI {
    @observable
    lang = i18n.language

    setLang(lang) { 
        localStorage.setItem(LOCAL_LANG_FLAG, lang)

        window.location.href = URL.merge(window.location.href, {
            query: {
                lang
            }
        })
    }
}

export default new UI()