import React, { Component, Fragment } from "react";
import Header from "./Public/template/Header";
import CreateAccount from "./CreateAccount";
import PickPlan from "./PickPlan";
import AddOnes from "./AddOnes";
import StartTrial from "./StartTrial";
import Notify from "./Notify"
import axios from "axios";
import url from "url";
import { I, calculator } from 'Utils';
import {message, Spin} from "antd";
import { post } from 'srcPath/http';
import moment from "moment";
import security from 'stores/security';
import Loading from 'components/Loading';
import './style.scss';

class Signup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            payLoading: false,
            buttonLoading: false,
            currency: 'USD',
            id: 0,
            email:'',
            //页签名称
            tab: ['1. Create an account', '2. Pick a plan', '3. Confirm and pay'],
            //通过指定值跳转不同页面
            tabCurrentType: 0,
            isLogin: 0,
            //account信息
            accountInfo: {id: '', name: '', password: '', confirmPassword: '', companySize: '', email: '',},
            sessionId: '',
            companyId: '',
            uid: '',
            //选择的产品标识
            productType: 1,
            //0：成功，1：失败
            payType: 1,
            //是否允许创建账户
            createAccountStatus: false,
            payment: {
                //订阅周期
                subscriptionCycle: 4,
                isOnboarding: 1,
                isServices: 0,
                isStartupConsulting: 0,
                isCoupon: 0,
                total: '0.00',
                subscriptionCycleDesc: {3:'month', 4:'year'},
            },
            //指定必选产品。（只要产品归类中含有下面的id，则必选且不允许更改）
            // mandatoryItem: [1, 2, 12, 3, 5],
            mandatoryItem: [],
            total: '0.00',
            itemList: [],
            isContact: 0,
            pageCurrent: 1,
            planList: '',
            list: [{id:1,name:'10'},{id:2,name:'20'}],
            stakeholder: [{id:1,name:'10'}],
            selectValue: 1,
            planId: 0,
            tokenItem: {},
        }


    }

    /**
     * 初始化get传参
     */
    componentDidMount() {
        let queryObj = url.parse( this.props.location.search, true);
        const { accountInfo } = this.state;
        let account = {...accountInfo}
        const { id, email, type, session_id:sessionId, pay_type:payType, is_login:isLogin } = queryObj.query;
        account['id'] = I(id, '') ? id.substr(6, id.length -10): 0;
        account['email'] = I(email, '');
        this.setState(()=>({
            accountInfo: account,
            tabCurrentType: parseInt(I(type, 0)),
            sessionId: I(sessionId, ''),
            isLogin,
            payType,
            id, email
        }));

        //如果支付失败返回则需要重新配置支付
        if( type != 4){
            this.getItemList(account['id']).then(r => {
                this.setState({ loading: false })
            });
        }else{
            this.setState({ loading: false })
        }
     }

    /**
     * 获取产品信息
     *
     */
    getItemList = async (id) => {
        const { accountInfo } = this.state;
        this.setState({ loading: true })
        try {
            const { data: resData } = await post('getProduct', { id })
            const { code, data } = resData;
            this.checkSignup(code, data);
        } catch(err) {
            message.error(err && err.message)
        }
    }

    /**
     * 验证signup是否允许访问
     * @param code
     * @param data
     */
    checkSignup = (code, data) => {

        const { isLogin } = this.state;
        const { planList, tokenItem } = data;
        const { id } = security.userInfo;
        if( I(planList, '') ){
            this.setState({ planList, tokenItem });
        }else{
            this.messageLogin(id);
            this.props.history.push("/");
            return ;
        }
        switch (code){
            case 0:
                this.setState({
                    createAccountStatus: true
                });
                break;
            case 301:
                this.messageLogin(id);
                this.props.history.push("/");
                break;
            case 302:
            case 303:
                this.getPikPlan(id, data, isLogin);
                break;
            default:
                this.messageLogin(id);
                this.props.history.push("/");
        }
    }

    getPikPlan = (id, data, isLogin) => {
        // if(I(id, '') && isLogin){
        //ID 这里的id可以强制验证是否已登录状态
        if(isLogin){
            this.setState({
                companyId: data.company_id
            })
            this.updateTabCurrentType(1);
        }else{
            this.messageLogin(id);
            this.props.history.push("/");
        }
    }

    messageLogin = (id) => {
        if(!I(id, '')){
            message.success('Account already created, please login.');
        }
    }

    /**
     * 获取中心内容
     * @returns {JSX.Element}
     */
    getContents = () => {
        const { tabCurrentType, accountInfo, loading, buttonLoading, payLoading, productType, isOnboarding, total, payType, sessionId,
            createAccountStatus, payment, itemList, currency, mandatoryItem, id, email, isContact, planList, tokenItem } = this.state;
        const { history } = this.props;
        switch ( tabCurrentType ){
            case 0:
                return createAccountStatus && <CreateAccount
                    updateTabCurrentType={this.updateTabCurrentType}
                    updateAccount={this.updateAccount}
                    accountInfo={accountInfo}
                    subSignup={this.subSignup}
                    buttonLoading={buttonLoading}
                    history={this.props.history}
                />
                break;
            case 1:
                return <PickPlan
                    updateTabCurrentType={this.updateTabCurrentType}
                    updateProductType={this.updateProductType}
                    productType={productType}
                    planList={planList}
                    loading={loading}
                    history={this.props.history}
                    accountInfo={accountInfo}
                    updateLoading={this.updateLoading}
                    payment={payment}
                    updateState={this.updateState}
                    isContact={isContact}
                    tokenItem={tokenItem}
                />
                break;
            /*case 2:
                return <AddOnes
                    updateTabCurrentType={this.updateTabCurrentType}
                    productType={productType}
                    isOnboarding={isOnboarding}
                    updateState={this.updateState}
                    total={total}
                    loading={loading}
                    payment={payment}
                    mandatoryItem={mandatoryItem}
                />
                break;*/
            case 2:
                return <StartTrial
                    updateTabCurrentType={this.updateTabCurrentType}
                    isOnboarding={isOnboarding}
                    total={total}
                    loading={payLoading}
                    createOrder={this.createOrder}
                    itemList={itemList}
                    currency={currency}
                    productType={productType}
                />
                break;
            case 4:
                return <Notify
                    payType={payType}
                    sessionId={sessionId}
                    history={history}
                    loginSprout={this.loginSprout}
                    id={id}
                    email={email}
                />
                break;
            default:
        }
    }

    /**
     * 创建订单
     */
    createOrder = () => {
        const { accountInfo, companyId, itemList, uid, planId } = this.state;
        const { id } = accountInfo;
        this.setState({payLoading: true})
        //获取已选产品ID
        let product = [];
        itemList.forEach(v=>product.push(v.id));
        try{
            axios.post('https://api.getsprout.co', { action:'createOrder', company_id: companyId,
                data:{ id, price_sn: itemList[0]['price_sn'], goods_sn: itemList[0]['goods_sn'], uid }
            }).then((response)=> {
                const { data, code } = response.data;
                if(code == 0){
                    window.location.href = data.pay_url;
                }else{
                    this.setState({payLoading: false})
                }
            });
        }catch (e) {
            message.error(e.message)
        }
    }

    /**
     * 更新 tabCurrentType 跳转指定页面
     * @param tabCurrentType
     */
    updateTabCurrentType = (tabCurrentType) => {
        const { companyId } = this.state;
        const numberArray = [1,2,3];
        if(numberArray.indexOf(tabCurrentType) >=0 ){
            if(!companyId){
                return false;
            }
        }
        this.setState(()=>({
            tabCurrentType,
            pageCurrent: tabCurrentType
        }));
    }

    /**
     * 更新 account 信息
     * @param accountInfo
     */
    updateAccount = ( accountInfo ) => {
        this.setState(() => ({
            accountInfo
        }));
    }
    updateProductType = (productType) => {
        this.setState(()=>({
            productType
        }));
    }

    updateState = (key, value) => {
        let data = {};
        typeof(key) === 'string' ? (data[key] = value) : (data = key);
        this.setState(data);
    }

    /**
     * 设置用户密码
     */
    subSignup = () => {
        const { id, name: company_name, password, companySize: company_size } = this.state.accountInfo;
        this.updateState('buttonLoading', true);
        try{
            axios.post('https://api.getsprout.co', {
                action:'setUserInfo',
                data:{
                    id,
                    company_name,
                    password,
                    company_size
                }
            }).then((response)=> {
                const { data, code } = response.data;
                if(code == 0){
                    const { company_id, user_id } = data;
                    this.setState(()=>({
                        companyId: company_id,
                        uid: user_id,
                    }))
                    this.updateState('buttonLoading', false);
                    this.updateTabCurrentType(1);
                }
            });
        }catch (e) {
            message.error(e.message)
        }
    }

    /**
     * 更新loading状态
     * @param value
     */
    updateLoading = (value) => {
        this.setState(()=>({
            loading: I(value, false)
        }))
    }

    /**
     * 模拟登录
     * @returns {Promise<void>}
     */
    loginSprout = async() => {
        try {
            const { email, password} = this.state.accountInfo;
            const { sessionId } = this.state;
            const { data } = await axios.post('', Object.assign({ action: 'signin'}, {
                email,
                password,
                isSignup: 1,
                sessionId: sessionId,
            }), { useForm: true })
            if (data.code === 0) {
                security.setSproutToken = data.data
                localStorage.setItem('SPROUT_EXPIRE', moment().add(5, 'h').valueOf())
                security.sessionExpired = false
                await security.init()
            } else {
                if (data.code === 1) {
                    message.error('Email or password is error!')
                }
            }
        } catch (e) {
            message.error(e.message)
        }
    }

    render() {
        const { tab, tabCurrentType, loading, uid, createAccountStatus, isContact, pageCurrent } = this.state;
        if(loading) {
            return <Spin spinning={loading}  className="signup-loading"> </Spin>
        }
        return (
            <div className="signup-root">
                <Header
                    tab={tab}
                    tabCurrentType={tabCurrentType}
                    updateTabCurrentType={this.updateTabCurrentType}
                    history={this.props.history}
                    createAccountStatus={createAccountStatus}
                    isContact={isContact}
                    pageCurrent={pageCurrent}
                    updateState={this.updateState}
                />
                <div className="signup-box">
                    <Spin className="signup-box-spin" spinning={loading}>{this.getContents()}</Spin>
                </div>
            </div>

        );
    }
}
export default Signup;