import React, { Component } from 'react';
import { FormItem, withForm } from 'react-antd-formutil';
import { post } from 'srcPath/http';
import PwdRisk from 'components/PwdRisk';
import { Input, Button, Spin, message } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import security from 'stores/security';
import moment from 'moment';
import axios from 'axios'
import './style.scss';

const emailReg = new RegExp('(?![\\-_.])[\\w\\-.]+@(?:(?![\\-_])[\\w\\-\u4e00-\u9fa5]{1,63}\\.)+[A-Za-z0-9]{2,}')

const parse = urlString => {
	const res = {}
	const queryString = (urlString || '').slice(1).split('&') || []

	queryString.forEach(val => {
		const [key, value] = (val || '').split('=')

		if (key && !res[key]) {
			res[key] = value
		}
	})

	return res
}


@withForm
class InitPassword extends Component {
	urlParams = parse(window.location.search)

	state = {
		loading: false,
		checkedEmail: false
	}

	async componentDidMount() {
		const { code, email } = this.urlParams

		const usedEmail = (location.search.split('&').pop() || '').replace('email=', '')


		if(!code) {
			this.props.history.push('/auth/login');

			return
		}

		const userEmail = security.userInfo && security.userInfo.email

		if (security.hasLogin && userEmail === email) {
			this.props.history.replace('/')

			return
		}

		security.clear()

		const { $setValues } = this.props.$formutil

		$setValues({
			email: emailReg.test(usedEmail) ? usedEmail : email,
			curPassword: code
		})

		await this.checkEmail()
	}

	checkEmail = async () => {
		const { code: curPassword, email, mark = '' } = this.urlParams

		this.setState({ checkedEmail: false })

		try {
			const { data: resData } = await post('checkoutRestPassword', {
				email,
				curPassword,
				mark
			})

			const { code, message: messageContent } = resData || {}

			if (code === 0) {
				this.setState({ checkedEmail: true })
			}

			if ([1, 2, 3].includes(code)) {
				message.error(messageContent, 2).then(() => {
					this.props.history.push({
						pathname: '/auth/login',
						state: {
							email
						}
					})
				})
			}
		} catch(err) {
			message.error(err.message)
		}
	}

	onSubmit = async () => {
		const { $formutil, history } = this.props
		const { mark = '' } = this.urlParams || {}
		const {  $invalid, $batchDirty, $params, $getFirstError } = $formutil

		if ($invalid) {
			$batchDirty(true)
			message.error($getFirstError())

			return
		}

		if (!emailReg.test($params.email)) {
			message.error('Email is wrong!')

			return
		}


		this.setState({ loading: true })

		const { email, curPassword, targetPassword } = $params

		try {
			const { data: resData } = await axios.post('', Object.assign({ action: 'init'}, { email, curPassword, targetPassword, mark }), { useForm: true })

			const { code, message: tipMessage = '', data } = resData

			if(code !== 0) {
				message.error(tipMessage)

				if (code === 3) {
					history.push({
						pathname: '/auth/login',
						state: {
							email
						}
					})
				}
			} else {
				security.setSproutToken = data

				localStorage.setItem('SPROUT_EXPIRE', moment().add(5, 'h').valueOf())

				await security.init()
				history.push('/')				
			}
		} catch (e) {
			message.error( e && e.message)
		}

		this.setState({ loading: false })
	}

    // 防止浏览器自动填充其他记录值
    removeReadOnly = e => {
        e.target.removeAttribute('readonly');
    }

    checkPassword = value => {
        if (!value || value.length < 8 || PwdRisk.judgeRisk(value) <= 2.5  ) {
        	return __('8 character at least, 1 number, 1 upper case, 1 lower case, 1 special character')
        }

        return true;
    };

	render() {
		const { loading, checkedEmail } = this.state;
		const { $params } = this.props.$formutil;

		if (!checkedEmail) {
			return <div />
		}

		return <div className="init-password-root">
			<Spin spinning={loading}>
			<div className="sprout-auth-title">{__('WELCOME TO Folium')}</div>
			<div className="sprout-login-form">
				<div className="init-password-email">
					<div className="sprout-forgot-label">{__('Email: ')}<span>{$params.email}</span></div>
				</div>
			{/*

				<FormItem 
					name="email" 
					required
					pattern={email}
					itemProps={{
						label: ''
					}}
					validMessage={{
						required: 'Email is required',
						pattern: 'Email address is invalid'
					}}
				>
					<Input className="sprout-auth-input" size="large" placeholder="Email" disabled/>
				</FormItem>
			*/}

				<div className="sprout-forgot-label">{__('New password')}</div>
				<FormItem 
					name="targetPassword"
					required
					onFocus={this.removeReadOnly}
					checker={this.checkPassword}
					colon={false}
					validMessage={{
						required: __('Password is required'),
						pattern: __('Password is invalid')
					}}
				>
					<Input.Password className="sprout-auth-input" size="large" autoComplete="off" readOnly iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
				</FormItem>
				<div className="pwd-risk-content">{ $params.password && <PwdRisk password={$params.password}/>}</div>
				<div className="sprout-forgot-label">{__('Re-enter new password')}</div>
				<FormItem 
					name="newPasswordAgain" 
					required
					colon={false}
					onFocus={this.removeReadOnly}
					checker={val => val === $params.targetPassword ? true : __('The password you entered did not match') }
					validMessage={{
						required: __('Password is required'),
					}}
				>
					<Input.Password className="sprout-auth-input" size="large" autoComplete="off" readOnly iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
				</FormItem>

				<Button loading={loading} className="sprout-auth-bth" type="primary" onClick={this.onSubmit}>{__('Submit')}</Button>
			</div>
			</Spin>
		</div>
	}
}

export default InitPassword