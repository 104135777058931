import React from 'react';
import 'assets/css/stakeholders.css';
import { post } from 'srcPath/http';
import AddStakeholder1 from './StakeholdersModal/AddStakeholder';
import AddStakeholder from './StakeholdersModal/AddStakeholder';
import UpdateStakeholder from './StakeholdersModal/UpdateStakeholder';
import EditStakeholderModal from "./StakeholdersModal/EditStakeholder";
import MergeStakeholders from "./StakeholdersModal/MergeStakeholders";
import EditStakeholdersProperties from "./StakeholdersModal/EditStakeholdersProperties";
import ViewStakeholderHoldings from "./StakeholdersModal/ViewStakeholderHoldings";
import TerminateStakeholder from "./StakeholdersModal/TerminateStakeholder";
import AddProperties from "./StakeholdersModal/AddProperties";
import UpdateProperties from "./StakeholdersModal/UpdateProperties";
import DownloadTaskModal from "./StakeholdersModal/DownloadTaskModal";
import Dot from 'components/Dot';
import MergeStakeholdersModels from "./components/MergeStakeholdersModels/MergeStakeholdersModels";
import StakeholdersTableModels from './components/StakeholdersTableModels/StakeholdersTableModels'
import ConfirmSaveStakeholderModels from "./components/ConfirmSaveStakeholderModels";
import {message, Spin, Card, Input, Alert, Button} from 'antd';
import ResendEmailModalConteent from './components/ResendEmailModal';
import Loading from 'components/Loading';
import security from 'stores/security';
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import InviteUnregistered from "./StakeholdersModal/InviteUnregistered";
import Modal, { ModalContent } from 'components/Modal';
import NoPermissionFloat from "../../CheckPlan/NoPermissionFloat";
import StakeholderRegistered from "./components/StakeholderRegistered";
import ColumnController, { columnsFilter } from "components/ColumnController";
import FoldImg from "./components/StakeholderRegistered/image/fold.png";
import OpenImg from "./components/StakeholderRegistered/image/open.png";
import Filter from 'components/Filter';
import StakeholdersBatchUploadModels from "./components/StakeholdersBatchUploadModels/StakeholdersBatchUploadModels";
import { printf, sprintf } from 'Utils/i18n'
import {Link} from "react-router-dom";
import ConfirmInviteSelectedModels from "components/ConfirmInviteSelectedModels";

const registerMap = {
    0 : <Dot color="red">{__('Not yet')}</Dot>,
    1: <Dot color="green" >{__('Yes')}</Dot>
}

const orderMap = {
    null: 0,
    ascend: 4,
    descend: 3,
    undefined: 0
}

class Stakeholders extends React.Component{

    NoPermissionFloatModal = null;
    ConfirmSaveStakeholderModels = null;
    ConfirmInviteSelectedModels = null;

    getNoPermissionFloat = () => {
        this.NoPermissionFloatModal = Modal.open({
            component:  props => <ModalContent title="Add stakeholders" close={props.close}>
                <NoPermissionFloat history={this.props.history} callback={props.close} hideHeader={true} />
            </ModalContent>,
            width: '450px',
            maskClosable: true,
            className: 'sprout-modal'
        })
    }

    /**
     * 卸载
     */
    componentWillUnmount() {
        this.NoPermissionFloatModal && this.NoPermissionFloatModal.close();
        this.ConfirmSaveStakeholderModels && this.ConfirmSaveStakeholderModels.close();
        this.ConfirmInviteSelectedModels && this.ConfirmInviteSelectedModels.close();
    }

    constructor(props){
        super(props);
        this.state = {
            inviteUnregistered:1,
            curSelectRelation:"all",
            loading: true,
            loaded: false,
            holderLists:[],
            curSelectKeysArr:[],
            relationshipList:[
                {checked:false,relationship:"Founder"},
                {checked:false,relationship:"Investor"},
                {checked:false,relationship:"Employee"},
                {checked:false,relationship:"Officer"},
                {checked:false,relationship:"Board member"},
                {checked:false,relationship:"None entered"},
            ],
            stakeholderRelationshipList:[
                "",
                "Advisor",
                "Board member",
                "Consultant",
                "Employee",
                "Ex-employee",
                "Executive",
                "Founder",
                "International employee",
                "Investor",
                "Officer",
            ],
            curSelectStakeholder:{
                id:"",
                security:"",
                securities:"",
                status:0,
                shares:"",
                vesting_schedule:"",
                issue_date:"",
                name:"",
                email:"",
                passport_or_national_id:"",
                employee_id:"",
                issue_date_relationship:"",
                annual_salary:"",
                relationship:"",
                address:"",
                ownership:"",
                city:"",
                state:"",
                post_code:"",
                country_region:"",
                date_of_birth:"",
                effective_date:"",
                job_title:"",
                relationshipDataList:[
                    {effective_date:'',relationship:""},
                    ],
                jobTitleList:[
                    {effective_date:'',job_title:""},
                ],
            },
            tableChild : {},
            searchHolderLists: [],
            searchValue: '',
            checkAddStakeholder: true,
            tableColumn: [],
            redisKey: 'Stakeholder',
            registeredChild: '',
            showEcharts: false,
            // 翻页参数，搜索条件
            total: 0,
            pageNumber: 1,
            pageSize: 50,
            filter: {},
            sort: {column: "ownership", type: 'descend'},
            cost_list:[]
        }
    }
    transferInfo=(key,refName,e)=>{
        this.refs[refName].initData(key,this.state.holderLists[key]);
    }
    transferInfoNew=( id, refName) => {
        const { holderLists } = this.state;
        let info = {};
        let key = 0;
        holderLists.forEach( (v, k)=>{
            if(v.id == id){
                info = {...v}
                key = k;
            }
        });
        this.refs[refName]?.initData(key, info);
    }
    // editProperties=(key)=>{
    //     this.refs.editProperties.initData(key,this.state.holderLists[key]);
    // }
    viewHoldings=(key)=>{
        // this.refs.viewStakeholderHoldings.initData(key,this.state.holderLists[key]);
        // this.refs.viewStakeholderHoldings.getStakeholderHoldingsList();
    }
    componentDidMount() {
        this.getData();
        this.initAddStakeholder();
        this.getCostCenterList();
    }

    editStakeholder = (record, id, cost_list) => {
        EditStakeholderModal(record, id, cost_list, this.getData)
    }

    /**
     * 通过API costCenter获取costCenterList
     */
    getCostCenterList = () =>{
        post('costCenter', {}).then((response)=>{
            if(response.data.code === 0){
                this.setState({cost_list:response.data.data.cost_center})
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        })
    }

    /**
     * 权限验证-》加载是否可以创建stakeholder
     * planControl。(1:忽略所有验证，2：正常验证)
     */
    initAddStakeholder = () => {
        security.planControl == 2 && this.checkAddStakeholder();
        if(this.props.match.params.inviteUnregistered=="invite-unregistered"){
            this.setState({
                inviteUnregisteredBtn:2
            })
        }
    }

    /**
     * 加载是否可以创建stakeholder
     * @returns {Promise<void>}
     */
    checkAddStakeholder = async () => {
        this.setState({ loading: true })
        try {
            const { data: resData } = await post('getMemberInfo')
            const { code, data } = resData;
            this.setState({checkAddStakeholder: !!!code})
        } catch(err) {
            message.error(err && err.message)
        }
        this.setState({ loading: false })
    }

    getData = async () => {
        this.setState({ loading: true })
        let pageSize = localStorage.getItem('stakeholdersPageSize') || this.state.pageSize;
        this.setState({
            pageSize
        })
        let { pageNumber, filter, searchValue, sort } = this.state;
        try {
            const { data: resData } = await post('getStakeHolderList', {
                filter,
                pageNumber,
                pageSize,
                search: searchValue,
                sort: { ...sort, type: orderMap[sort?.type] || 0 }
            })

            const { code, data } = resData;
            const { stakeholderList, total } = data || {}

            if(code === 0) {
                this.setState({ holderLists: stakeholderList, searchHolderLists: stakeholderList, loaded: true, total })
            } else {
                message.error(data)
            }
        } catch(err) {
            message.error(err && err.message)
        }

        this.setState({ loading: false })
    }

    changeStatus=(num)=>{
        var holderLists = this.state.holderLists;
        let curSelectKey = this.state.curSelectKeysArr;
        if(holderLists[num].status == 0){
            holderLists[num].status = 1;
            this.setCurSelectStakeholder(num);
            curSelectKey.push(num);
        }else{
            holderLists[num].status = 0;
            for(let i=0;i<this.state.curSelectKeysArr.length;i++){
                if(num === this.state.curSelectKeysArr[i]){
                    curSelectKey.splice(i,1)
                }
            }
            $('#check01').prop("checked",false);
        }
        this.setState({
            holderLists:holderLists,
            searchHolderLists: holderLists,
            curSelectKeysArr:curSelectKey
        })

    }
    clear=()=>{
        var holderLists = this.state.holderLists;
        $('.listCheckbox').prop('checked',false);
        $('#check01').prop("checked",false);

        const { tableChild } = this.state;
        tableChild.clearAll();

        for(let i=0;i<holderLists.length;i++){
            holderLists[i].status = 0;
        }
        this.setState({
            holderLists:holderLists,
            searchHolderLists: holderLists,
        })
    }

    clearInput=()=>{
        this.refs.stakeholder_search.value = "";
    }
    addActiveToMerge=()=>{
        window.$("#mergeStakeholders").addClass("active");
        this.refs.mergeStakeholder.bindBirthDatepicker();
        this.refs.mergeStakeholder.setMergeInitData();
    }
    setFilter=(key)=>{
        for(let i=0;i<this.state.relationshipList.length;i++){
            if(i !== key){
                this.state.relationshipList[i].checked = false;
            }
        }
        let curRelationshipList = this.state.relationshipList;
        curRelationshipList[key].checked=!curRelationshipList[key].checked;
        this.setState({
            relationshipList:curRelationshipList
        });
        if(curRelationshipList[key].checked === false){
            this.setState({
                curSelectRelation:"all"
            });
        }else{
            this.setState({
                curSelectRelation:this.state.relationshipList[key].relationship
            });
        }
    }
    hiddenDownloadTip=()=>{
        window.$(".load-banner").addClass("hidden");
    }
    showDownload=()=>{
        window.$(".load-banner").removeClass("hidden");
    }
    showVoting=()=>{
        window.$(".refresh-banner").removeClass("hidden");
    }
    setCurSelectStakeholder=(key,e)=>{
        //把key先传进去方便返回的时候修改
        this.state.holderLists[key].key=key;
        this.setState({
            curSelectStakeholder:this.state.holderLists[key]
        });
    }

    setCurSelectStakeholderNew = (id) => {
        const { holderLists } = this.state;
        let info = {};
        holderLists.forEach( (v, k)=>{
            if(v.id == id){
                info = {...v}
            }
        });
        this.setState({
            curSelectStakeholder: info
        });
    }

    setModifiedSecurity=(key,security)=>{
        let curStakeholderList = this.state.holderLists;

        curStakeholderList[key] = security;

        this.setState({
            holderLists:curStakeholderList,
            searchHolderLists: curStakeholderList,
        })
    }
   
    getDateOfIncorporation=()=>{
        const { checkAddStakeholder } = this.state;
        //通过用户数限制 add stakeholder
        // if(!checkAddStakeholder){
        //     this.getNoPermissionFloat();
        //     return false;
        // }
        this.refs.add_stakeholder.getIncorporationDate();
    }

    openResendModal = (data) => {
        const userAccessControl = security.companyInfo && security.companyInfo.user_access_control;
        this.resendStakeholderEmailModel(userAccessControl, data);

        // this.modlaRef = Modal.open({
        //     component: <ResendEmailModalConteent data={data} />
        // })
    }

    /**
     * resend
     * @param userAccessControl
     * @param data
     * @param type 1: invite selected, 0 || null: resend
     */
    resendStakeholderEmailModel = (userAccessControl, data, type) => {
        let { selectEmail } = this.getStakeholderEmailId();
        const { id, email } = data || {};
        data && email && (selectEmail = [email]);
        this.ConfirmInviteSelectedModels = Modal.open({
            component: props => <ModalContent title={userAccessControl > 0 ? __('Invite selected stakeholders') : __('Reminder')} close={props.close}>
                <ConfirmInviteSelectedModels
                    close={props.close}
                    selectEmail={selectEmail}
                    resendStakeholderEmail={type === 1 ? this.resendStakeholderEmail : this.resendEmail}
                    userAccessControl={userAccessControl}
                    stakeholderId={id}
                    id={id}
                    content={[__('Please be informed that your stakeholders will receive an invitation email with contents as shown in the email template.'), __('To confirm that you want to send the invitation email to the following stakeholders, please review the list below:')]}
                    button={[__('Cancel'), __('Ok')]}
                />
            </ModalContent>,
            width: 1025,
            maskClosable: false,
            className: 'sprout-modal confirm-invite'
        });
    }


    inviteUnregistered=()=>{
        post('inviteAllStakeHolder').then((response)=> {
            if(response.data.code === 0){
                message.success("Success!")
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    forceUpdates = () => {
        this.clear();
        this.getData();
    }

    holderListStatusChange = (selectedRowKeys) => {
        const { holderLists } = this.state;
        let list = [...holderLists];
        list.forEach( (v, k) => {
            list[k]['status'] = 0;
        });
        list.forEach( (v, k) => {
            selectedRowKeys.forEach( (v1, k1) => {
                if(v1 == v.id){
                    list[k]['status'] = 1
                }
            })
        });
        this.setState(()=>({
            holderLists : list,
            //searchHolderLists: list,
        }))
    }

    tableObj = (ref, key) => this.setState({ [key] : ref });

    /**
     * 搜索列表数据
     * @param e
     */
    searchList = (e) => {
        const { value } = e.target;
        this.setState({
            searchValue: value,
        })
    }

    /**
     * 清空搜索内容
     */
    closeSearch = () => {
        const { registeredChild } = this.state;
        this.setState({
            searchValue: '',
        },()=>this.getData());
        registeredChild && registeredChild.resetDefault();
    }

    /**
     * 更新变量
     */
    updateState = (value) => {
        this.setState(value);
    }

    /**
     * 批量发送邀请
     * @returns {Promise<void>}
     */
    resendStakeholderEmail = async(id, close, stakeholderId) => {
        this.setState({loading: true});
        let {newKey: stakeholder_id} = this.getStakeholderEmailId();
        stakeholder_id = id ? [id] : stakeholder_id;
        stakeholder_id = stakeholderId ? stakeholderId : stakeholder_id;
        try {
            const { data: resData } = await post('resendStakeholderEmailById', {stakeholder_id})
            const { code, data } = resData || {}
            if (code === 0) {
                message.success(__('Success!'));
                close && close();
            } else {
                message.error(data)
            }
        } catch(err) {
            message.error(err && err.message)
        }
        this.setState({loading: false});
    }

    resendEmail = async (id, close) => {
        this.setState({ loading: true })
        try {
            const { data: resData } = await post('resendStakeholderEmail', {id})
            const { code, data } = resData || {}
            if (code === 0) {
                message.success(__('Success!'))
                close && close()
            } else {
                message.error(data)
            }
        } catch(err) {
            message.error(err && err.message)
        }
        this.setState({ loading: false })
    }

    /**
     * 获取列表已选中ID且is_init=0
     */
    getStakeholderEmailId = () => {
        const { holderLists, tableChild } = this.state;
        const { selectedRowKeys } = tableChild.state;
        let newKey = [];
        let selectEmail = [];
        selectedRowKeys && selectedRowKeys.forEach((v)=>{
            const index = holderLists.findIndex( v1 => v1.id == v);
            const { is_init, email } = holderLists[index];
            if(is_init != 1){
                newKey.push(v);
                selectEmail.push(email);
            }
        })
        return { newKey, selectEmail};
    }

    showEcharts = () => {
        let { showEcharts } = this.state;
        showEcharts = !showEcharts;
        this.setState({showEcharts});
    }

    // 分页逻辑

    onPageChange = (current, pageSize) => {
        localStorage.setItem('stakeholdersPageSize',pageSize)
        this.setState({
            pageNumber: pageSize === this.state.pageSize ? current : 1,
            pageSize
        }, () => this.getData())
    }

    /**
     * column 排序
     * @param sort
     */
    sortTable = sort => {
        this.setState({ sort }, ()=>this.getData())
    }
    
    // 筛选条件逻辑，如果有分页，需要把查询条件保存在state 供分页使用
    onFilterChange = (value = {}) => {
        if (JSON.stringify(value) !== JSON.stringify(this.state.filter)) {
            this.setState({
                filter: value,
                pageNumber: 1
            }, () => this.getData())
        }
    }

    Bulkuploadstakeholders = () => {
        this.modalRef = Modal.open({
            component: props => <ModalContent close={props.close} title={__('Bulk edit stakeholders')}>
                <StakeholdersBatchUploadModels resendStakeholderEmail={this.resendStakeholderEmail} close={props.close}  callback={this.getData.bind()}/>
            </ModalContent>,
            className: 'sprout-modal',
            width: 1300
        })
    }

	exportTemplate = () => {
		Loading.global(true)
		post('exportStakeholderList').then(res => {
			let { data } = res.data || {}
			let url = data.file_url
			let a = document.createElement('a')
			a.download = ''
			a.href = url;
			a.click()
			Loading.global(false)
		}).catch(function (error) {
			Loading.global(false)
		});
	}

    openAddStakeholderModal = (type) => {
        const userAccessControl = security.companyInfo && security.companyInfo.user_access_control;
        const uid = security.userInfo && security.userInfo.id;
        const companyId = security.companyInfo && security.companyInfo.company_id;
        const allStakeholderControlRemind = localStorage.getItem(`ALL-STAKEHOLDER-CONTROL-REMIND-${uid}-${companyId}`);
        if(allStakeholderControlRemind){
            this.saveStakeholder(type);
        }else{
            if(userAccessControl > 0){
                this.saveStakeholder(type);
            }else{
                this.ConfirmSaveStakeholderModels = Modal.open({
                    component: props => <ModalContent title={__('Reminder')} close={props.close}>
                        <ConfirmSaveStakeholderModels
                            history={this.props.history}
                            type={type}
                            saveStakeholder={this.saveStakeholder}
                            close={props.close}
                        />
                    </ModalContent>,
                    width: '690px',
                    maskClosable: true,
                    className: 'sprout-modal'
                })
            }
        }
    }

    saveStakeholder = (type) => {
        if(type == 'add'){
            AddStakeholder('', 'add', this.getData, this.state.cost_list, this.state.coinId,)
        }else{
            this.Bulkuploadstakeholders();
        }
    }

    render(){
        const { total, pageNumber, pageSize, loading, loaded, holderLists, filterList, searchHolderLists, tableColumn, redisKey, showEcharts, searchValue, sort, cost_list } = this.state

        if(loading && !loaded) {
            return(
                <div className="loading_position">
                    <Loading />
                </div>
            )
        }

        let selectCount=0;
        let inviteCount = 0;
        for(let j=0;j<holderLists.length;j++){
            if(holderLists[j].status === 1){
                selectCount++;
                holderLists[j].is_init < 1 && inviteCount++;
            }
        }

        const userAccessControl = security.companyInfo && security.companyInfo.user_access_control;

        return(
            <div className="content">
                {userAccessControl <= 0 && <Alert
                    className="stakeholder-warning"
                    type="warning"
                    message={<span>{sprintf( __('Invitation emails to stakeholders are currently not enabled. Please go to the %s page to change your setting.'), <Link to="/home/setting">{__('Settings')}</Link>)}</span>}
                />}
                {/*<div className="stakeholder-card-box">*/}
                {/*    <div className="stakeholder-card-box-title">*/}
                {/*        <div className="stakeholder-card-box-title-bar"></div>*/}
                {/*        <div className="stakeholder-card-box-title-bar"></div>*/}
                {/*        <div className="stakeholder-card-box-title-bar"></div>*/}
                {/*    </div>*/}
                {/*    <div className="stakeholder-card-box-value">{__('Stakeholder registration summary')}</div>*/}
                {/*    <div className="stakeholder-card-box-img" onClick={this.showEcharts}>*/}
                {/*        {showEcharts ? <img src={FoldImg} /> : <img src={OpenImg} />}*/}
                {/*    </div>*/}
                {/*    <Card className={`stakeholder-registered-card ${showEcharts ? '' : 'stakeholder-registered-hide-width'}`}> <StakeholderRegistered onRef={this.tableObj} showEcharts={showEcharts} searchHolderLists={searchHolderLists} updateState={this.updateState} /> </Card>*/}
                {/*</div>*/}
            <Spin spinning={loading}>
                {/*<AddStakeholder cost_list={ cost_list } ref="add_stakeholder" stakeholders={this}/>*/}
                <UpdateStakeholder/>
                {/* TODO:1 */}
                {/* <EditStakeholder ref={"editStakeholder"} setModifiedSecurity={this.setModifiedSecurity}  relationshipList={this.state.stakeholderRelationshipList}  stakeholders={this} callback={() => { $('#editStakeholderModal').modal('hide'); this.getData() }}/> */}
                {/*<EditStakeholdersProperties ref={"editProperties"} curSelectStakeholder={this.state.curSelectStakeholder} relationshipList={this.state.stakeholderRelationshipList} callback={() => { $('#EditStPropertiesModal').modal('hide'); this.getData() }}/>*/}
                <MergeStakeholders ref="mergeStakeholder" setModifiedSecurity={this.setModifiedSecurity} curHolders={this.state.holderLists} curKey={this.state.curSelectKeysArr} curSelectStakeholder={this.state.curSelectStakeholder}  relationshipList={this.state.stakeholderRelationshipList}/>
                <ViewStakeholderHoldings ref={"viewHoldings"}  setModifiedSecurity={this.setModifiedSecurity} curSelectStakeholder={this.state.curSelectStakeholder}/>
                <TerminateStakeholder  setModifiedSecurity={this.setModifiedSecurity} curSelectStakeholder={this.state.curSelectStakeholder}/>
                <AddProperties/>
                <UpdateProperties/>
                <DownloadTaskModal/>
                <InviteUnregistered/>
                <div className="stakeholder-conCenter ">
                    <div className="holders-nav clearfix">

                        <MergeStakeholdersModels
                            selectCount={selectCount}
                            inviteCount={inviteCount}
                            holderLists={holderLists}
                            history={this.props.history}
                            forceUpdates={this.forceUpdates}
                            resendStakeholderEmailModel={this.resendStakeholderEmailModel}
                        />

                        <div className="form-group search-group banner-rightBox offset">

                            {selectCount>=1 &&
                                <div className="stateBox" style={{width:'200px', minWidth:'auto'}}>
                                    <p className="items-tip">{printf(__('%s of %s items selected'),selectCount,this.state.holderLists.length)}</p>
                                    <button onClick={this.clear} type="button" className="bg-dark holders-clearBtn">{__('Clear selection')}</button>
                                </div>
                            }
                            <ColumnController filterList={tableColumn} redisKey={redisKey} thisObj={this} />
                            <div className="mr-10">
                                    <Filter callback={val => this.onFilterChange(val)} type="stakeholder" />
                                    &nbsp;&nbsp;
                            </div>
                            <div className="stakeholder-search-box">
                                <Input
                                    className="stakeholder-search-input"
                                    value={searchValue}
                                    onChange={this.searchList}
                                    onPressEnter={this.getData}
                                    prefix={<SearchOutlined className="search-prefix-icon" onClick={this.getData} />}
                                    suffix={<CloseOutlined style={{opacity: searchValue ? 1 : 0}} onClick={this.closeSearch} className="search-close-icon" />}
                                    placeholder={__('Search')}
                                />
                            </div>

                            {/*<button className="btn manage-btn bg-darkblue add_stakeholder_btn" onClick={this.getDateOfIncorporation} data-toggle="modal" data-target="#addStakeholderModal">Add stakeholder</button>*/}
                            {/*1.0之后再加以下其他功能*/}
                            {this.state.inviteUnregisteredBtn != 2 &&
                            <div className="btn-group">
                                <button className="btn manage-btn width180 bg-darkblue dropdown-toggle"
                                        data-toggle="dropdown">{__('Manage stakeholders')}<span
                                    className="triangle triangle-whitedown action-triangle"></span></button>
                                <ul className="dropdown-menu mange-downmenu width180">
                                    <li><a className="add-stake" type="button" data-toggle="modal"
                                           onClick={this.openAddStakeholderModal.bind(this, 'add')} data-target="#addStakeholderModal">{__('Add stakeholder')}</a></li>
                                    <li><a className="add-stake" onClick={this.openAddStakeholderModal.bind(this, 'edit')}>{__('Bulk edit stakeholders')}</a></li>
                                </ul>
                            </div>
                            }
                            {this.state.inviteUnregisteredBtn==2 &&
                            <div className="btn-group open">
                                <button aria-expanded={true} className="btn manage-btn width180 bg-darkblue dropdown-toggle" data-toggle="dropdown">{__('Manage stakeholders')}<span className="triangle triangle-whitedown action-triangle"></span></button>
                                <ul className="dropdown-menu mange-downmenu width180">
                                    <li><a className="add-stake" type="button" data-toggle="modal"  onClick={this.getDateOfIncorporation}  data-target="#addStakeholderModal">{__('Add stakeholder')}</a></li>
                                    <li className={"invite_li"}><a className="add-stake" type="button" data-toggle="modal"  data-target="#inviteConfirmation">{__('Invite unregistered')}</a></li>
                                </ul>
                            </div>
                            }

                        </div>
                    </div>
                    <div className=" table-box">

                        <StakeholdersTableModels
                            curSelectRelation={this.state.curSelectRelation}
                            holderLists={holderLists}
                            transferInfoNew={this.transferInfoNew}
                            editStakeholderOrWallet={this.editStakeholder}
                            setCurSelectStakeholderNew={this.setCurSelectStakeholderNew}
                            openResendModal={this.openResendModal}
                            holderListStatusChange={this.holderListStatusChange}
                            onRef={this.tableObj}
                            filterList={filterList}
                            cost_list={ cost_list }
                            tableColumn={columnsFilter(tableColumn, redisKey, sort)}
                            updateState={this.updateState}
                            showEcharts={showEcharts}
                            sortTable={this.sortTable}
                            paginationConfig={{
                                total,
                                current: pageNumber,
                                pageSize: pageSize,
                                onChange: this.onPageChange,
                                showSizeChanger: true,
                                showTotal: (sum) => <span>{sum} {__('items')}</span>
                            }}  
                        />
                    </div>
                </div>
                </Spin>
            </div>
        )
    }

}

export default Stakeholders;