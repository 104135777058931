import React from "react";
import { Link } from "react-router-dom";
import security from "../../../../../stores/security";
import Utils, { I, formatCurrencyNumber, filterAmount, calculator } from "Utils";
import { post } from 'srcPath/http';
import axios from "axios";
import {DatePicker, message} from 'antd';
import moment from "moment";
import DateSelector from '../../../../../components/DateSelector';
import { formatDate } from 'Utils/format/formatDate';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import { printf } from 'Utils/i18n';

class TransferShares extends React.Component {
    constructor(props){
        super(props);
        this.state={
            uploadProgress:0,
            uploadingStatus:0,
            loadData:0,
            transferType:"", //full/partial
            currentFilledStakeholderKey:0,
            transferredCustomLabelId:[],
            shareClassPlanList:[],
            dateFormat:"DD/MM/YYYY",
            data:{
                id:"",
                transfer_date:"",
                target_plan_id:"",
                transferList:[
                    {
                        stakeholder_name:"",
                        stakeholder_id:"",
                        shares:"",
                        issue_price_per_share:"",
                        transfer_agreement:{name:"",file_obj:""},
                        cash_paid:"",
                        debt_canceled:"",
                        ip_transferred:"",
                        other_considerations:"",
                        notes:"",
                        email:"",

                    },
                    {
                        stakeholder_name:"",
                        stakeholder_id:"",
                        shares:"",
                        issue_price_per_share:"",
                        transfer_agreement:{name:"",file_obj:""},
                        cash_paid:"",
                        debt_canceled:"",
                        ip_transferred:"",
                        other_considerations:"",
                        notes:"",
                        email:"",
                    },
                ],
            },
        }
    }

    getShareClassPlanList=()=>{
        post('getShareClassPlanList', []).then((response)=> {
            if(response.data.code === 0){
                this.setState({
                    shareClassPlanList:response.data.data
                })
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    addTransfer=()=>{
        let one = {
            stakeholder_name:"",
            stakeholder_id:"",
            shares:"",
            issue_price_per_share:"",
            transfer_agreement:{name:"",file_obj:""},
            cash_paid:"",
            debt_canceled:"",
            ip_transferred:"",
            other_considerations:"",
            notes:"",
            email:"",
        }
        let curList = this.state.data;
        curList.transferList.push(one);
        this.setState({
            transferList:curList
        })
    }

    removeTransfer=(key)=>{
        let curList = this.state.data;
        if(curList.transferList.length<=1){
            message.error("You only have one row now!");
            return;
        }
        // this.state.data.transferList.map((value,key)=>{
        //     window.$(".manageAuth_datepicker"+key).datepicker('setDate',value.transfer_date);
        // })
        curList.transferList.splice(key,1);
        this.setState({
            transferList:curList
            }
        )
    }
    recordData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let numKeys = ["issue_price_per_share","cash_paid","debt_canceled","ip_transferred","other_considerations"];
        if(numKeys.indexOf(dataKey)>-1){
            dataVal = Utils.getNumFormatWithTwoComma(dataVal);
        }
        let curData = this.state.data;
        curData.transferList[this.state.currentFilledStakeholderKey][dataKey] = dataVal;
        this.setState({
            data:curData
        });
    }

    setStakeholderName=(k,e)=>{
        let curList = this.state.data;
        curList.transferList[k].stakeholder_id = e.target.value;
        var targetName = "";
        for(let i=0;i<this.props.stakeholders.length;i++){
            if(this.props.stakeholders[i].id == e.target.value){
                targetName = this.props.stakeholders[i].nick_name;
            }
        }
        curList.transferList[k].stakeholder_name = targetName;
        this.setState({
            data:curList
        });
    }
    setTransferFile=(e)=>{
        var uploadFile = e.target.files[0];
        Utils.uploadFile(uploadFile).then((result)=>{
            let curData = this.state.data;
            curData.transferList[this.state.currentFilledStakeholderKey].transfer_agreement.name = uploadFile.name;
            curData.transferList[this.state.currentFilledStakeholderKey].transfer_agreement.file_obj = result;
            this.setState({
                data:curData
            });
        });

    }
    /**
     * input change通用事件
     * @param isDecimal 是否需要小数
     * @param e
     * 5 digit
     */
    inputChange5Digit = (isDecimal,key, e) => {
        const { target: element } = e;
        const { name: dataKey } = element;
        const filter = filterAmount(e, 9999999999999, isDecimal, 5);
        let curData = this.state.data;
        let transferList = [...curData.transferList]
        transferList[key][dataKey] = filter.value;
        this.setState({
            data: curData
        }, () => {
            element.selectionStart = element.selectionEnd = filter.cursorPositionX;
        });
    }
    changeLegendsStatus=(key)=>{
        let curData = this.state.data;
        curData.transferList[this.state.currentFilledStakeholderKey][key].checked = !curData.transferList[this.state.currentFilledStakeholderKey][key].checked;
        this.setState({
            data:curData
        })
    }
    resetActive=()=>{
        this.setState({
            transferType:"",
            currentFilledStakeholderKey:0,
        });
        window.$("#transferModal .tab-pane").removeClass("active");
        window.$("#transfer_step1").addClass("active");
    }
    stakeholderTarget=()=>{
        window.$("#transferModal").modal("hide");
    }
    setTransferType=(type,e)=>{
        this.setState({
            transferType:type
        })
    }
    getTransferredCustomLabelId=()=>{
        let requestData = {};
        let curData = this.state.data;
        for(let i=0;i<curData.transferList.length;i++){
            if(!curData.transferList[i].shares || parseInt(curData.transferList[i].shares) <=0){
                curData.transferList.splice(i,1);
            }
        }
        this.setState({
            data:curData
        })
        // requestData.id= this.props.curSelectSecurity.id;
        requestData.id= curData.target_plan_id;
        requestData.count= curData.transferList.length;
        this.setState({
            loadData:1
        })
        post('getNewCustomLabelIdsByShareClassId',requestData).then((response)=> {
            if(response.data.code == 0){
                let curData = this.state.data;
                for(let i=0;i<curData.transferList.length;i++){
                    curData.transferList[i].custom_label_id=response.data.data.custom_label_ids[i]
                }
                this.setState({
                    data:curData,
                    loadData:0
                })
                this.targetToFillDetail();
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    targetToFillDetail=()=>{
        window.$("#transferModal .tab-pane").removeClass("active");
        window.$("#transfer_step3").addClass("active");
    }
    addCurrentFilledKey=()=>{
        let targetKey = this.state.currentFilledStakeholderKey + 1;
        this.setState({
            currentFilledStakeholderKey:targetKey
        })
    }
    minusCurrentFilledKey=()=>{
        let targetKey = this.state.currentFilledStakeholderKey - 1;
        this.setState({
            currentFilledStakeholderKey:targetKey
        })
    }
    autoFillTwoDecimal=(e)=>{
        let dataVal = e.target.value;
        let dataKey = e.target.name;
        let curData = this.state.data;
        if(dataVal==""){
            curData.transferList[this.state.currentFilledStakeholderKey][dataKey] = "";
        }else if(dataVal.indexOf(".")==0){
            curData.transferList[this.state.currentFilledStakeholderKey][dataKey] = Utils.getNumWith2Decimal( "0"+dataVal);
        }else{
            curData.transferList[this.state.currentFilledStakeholderKey][dataKey] = Utils.getNumWith2Decimal(dataVal);
        }
        this.setState({
            data:curData
        })
    }
    /**
     * transferDate
     * @param name
     * @param key
     * @param date
     */
    transferDateChange = (key, moment_key, date) => {
        const { dateFormat } = this.state;
        let curData = this.state.data;
        curData[key] = date.format( dateFormat);
        curData[moment_key] = date;
        this.setState({
            data:curData
        });
    }
    submitTransferData=()=>{
        if(Utils.isLocal()){
            window.$("#transferModal").modal("hide");
            window.location.reload();
            return;
            // window.$("#transferModal").modal("hide");
            // return;
        }
        if(this.state.uploadingStatus ===1){
            return;
        }
        this.setState({
            uploadingStatus:1
        });
        var requestData = this.state.data;
        requestData.id = this.props.curSelectSecurity.id;
        requestData.transfer_type = this.state.transferType;
        axios.post('https://api.getsprout.co', {
            action:"transferShares",
            company_id: Utils.getLocalCompanyInfo().company_id,
            data:requestData
        },{
            onUploadProgress:(progressEvent)=>{
                let complete = (progressEvent.loaded / progressEvent.total * 100 | 0);
                console.log('上传 ' + complete+'%') ;
                this.setState({
                    uploadProgress:complete
                });
                if(complete == 100){
                    this.setState({
                        isShowUploadProgress:0
                    })
                }
            }
        }).then((response)=> {
            console.log(response);
            if(response.data.code === 0){
                //这里需要将返回的数据覆盖到主页面
                //刷新active,强制刷新数据
                window.$("#transferModal").modal("hide");
                // this.resetActive();
                // this.setState({
                //     uploadProgress:0,
                //     uploadingStatus:0
                // });
                window.location.reload();
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });

    }

    setShareClassPlan = (e)=>{
        let curData = this.state.data;
        let curKey = e.target.name;
        let curVal = e.target.value;
        curData[curKey] = curVal
        this.setState({
            data:curData
        })
    }
    render(){
        const { add, minus } = calculator;
        const isFractionalShare = security.companyInfo.fractional_control;
        const { data, dateFormat, shareClassPlanList } = this.state;
        const { currency = '', curSelectSecurity } = this.props;
        let restAvailableShares = curSelectSecurity.quantity;
        let curInputTotal = 0;
        for(let i=0;i<data.transferList.length;i++){
            curInputTotal = add(Utils.resetNumberWithCommas(data.transferList[i].shares), curInputTotal)
        }
        restAvailableShares = minus(restAvailableShares,curInputTotal);
        if(restAvailableShares >= 0){
            restAvailableShares = formatFractionalNumber(restAvailableShares);
            var isHaveRestShares = true;
        }else if(restAvailableShares < 0){
            restAvailableShares = "-" + formatFractionalNumber(restAvailableShares);
        }
        let isShowStep1Button = 1;
        for(let i=0;i<data.transferList.length;i++){
            if(this.state.transferType == 'full'){
                if(restAvailableShares != 0){
                    isShowStep1Button = 0;
                }else{
                    if(data.transferList[i].shares!= ""
                        && (data.transferList[i].transfer_date==""
                            ||data.transferList[i].stakeholder_name =="")){
                        isShowStep1Button = 0;
                        break;
                    }
                }
            }else{
                if(!isHaveRestShares){ //只有当具有有效share时才检测
                    isShowStep1Button = 0;
                }else{
                    if(data.transferList[0].shares!=""&& data.transferList[0].stakeholder_name!="" && data.transferList[0].transfer_date!=""){
                        isShowStep1Button = 1;
                        break;
                    }else{
                        isShowStep1Button = 0;
                    }
                }
            }
        }

        let isShowStep2Button = 1;
        let curPersonKey = this.state.currentFilledStakeholderKey;
        if(data.transferList[curPersonKey].cash_paid.length ===0
            ||data.transferList[curPersonKey].other_considerations.length ===0
            ||data.transferList[curPersonKey].debt_canceled.length ===0
            ||data.transferList[curPersonKey].ip_transferred.length ===0
        ){
            isShowStep2Button = 0;
        }else if(data.transferList[curPersonKey].transfer_agreement.length ===0){
            isShowStep2Button = 0;
        }

        return(
            <div className="modal fade" id="transferModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="modal-dialog dialog960 dialog-top40 tab-content">
                    <div className="modal-content content720 tab-pane active" id="transfer_step1">
                        <div className="modal-header box-header">
                            <button type="button" onClick={this.resetActive} className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Transfer')} {curSelectSecurity.custom_label_id}</h4>
                        </div>
                        <div className="modal-body padding2026 reset_margin">
                            <a onClick={this.setTransferType.bind(this,'full')} data-target="#transfer_step2" data-toggle="tab" className="flexDiv">
                                <span className="transfer_dic"></span>
                                <div className="width580">
                                    <p className="transfer-title">{__('Full transfer')}</p>
                                    <p className="text-muted">{__('Transfer the full amount of certificate to one or multiple stakeholders. The original certificate will be marked as transferred. and the new certificates will be issued.')}</p>
                                </div>
                                <span className="transfer_triangle"></span>
                            </a>
                            <hr />
                            <a onClick={this.setTransferType.bind(this,"partial")} data-target="#transfer_step2" data-toggle="tab" className="flexDiv">
                                <span className="transfer_dic"></span>
                                <div className="width580">
                                    <p className="transfer-title">{__('Partial transfer')}</p>
                                    <p className="text-muted">{__('Transfer a portion of a certificate to one or multiple stakeholders. The original certificate will be marked as transferred, and the balance of the untransferred shares will be reissued to the original stakeholder as a new certificate.')}</p>
                                </div>
                                <span className="transfer_triangle"></span>
                            </a>
                        </div>
                    </div>
                    <div className="modal-content content960 tab-pane " id="transfer_step2">
                        <div className="modal-header box-header">
                            <a href="#transfer_step1" className="back-btn" aria-controls="transfer-step1" role="tab" data-toggle="tab"></a>
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.resetActive}>×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Transfer')} {curSelectSecurity.custom_label_id}</h4>
                        </div>
                        <div className="modal-body padding2026 reset_margin">
                            <div className="flexDiv">
                                <span className="transfer_dic"></span>
                                <div className="width580">
                                    <p className="transfer-title">{__('Select stakeholder to transfer to')}</p>
                                    <p className="text-muted">{__('Stakeholder must already exist on Folium. Add new stakeholders')}<Link to="/home/stakeholders"  onClick={this.stakeholderTarget} className="text-blue">{__('here')}</Link> </p>
                                </div>
                            </div>
                            <div className="paddingLeft42">
                                <table className="table viewHoldings-table marginTop10 schedule-table no-marginBottom">
                                    <tbody>
                                    <tr className="first-row">
                                        <td>{__('Transfer date')}</td>
                                        <td className={"paddingRight30"}>{__('Stakeholder name')}</td>
                                        <td className={"paddingRight30"}>{__('Share class')}</td>
                                        <td>{__('Shares')}</td>
                                        <td className="width80">&nbsp;</td>
                                    </tr>
                                    {data.transferList.map((value,key)=>{
                                        var isDisabled = "disabled";
                                        let isCursor = "not_pointer";
                                        if(key === 0){
                                            isDisabled = "";
                                            isCursor = ""
                                        }
                                        if(data.transferList.length=== (key+1)){
                                            return(
                                                <tr className="scheduleTr bg-lightgray" key={key}>
                                                    <td className="width180">
                                                        <DateSelector
                                                            onChange={this.transferDateChange.bind(this, 'transfer_date', 'transfer_date_moment')}
                                                            placeholder={dateFormat}
                                                            value={I(data['transfer_date_moment'], I(data['transfer_date'], '') ? moment(data['transfer_date'], dateFormat) : '')}
                                                            disabled={isDisabled}
                                                        />
                                                    </td>
                                                    <td className="width180">
                                                        <select className="select-box transfer_select paddingRight18" name="stakeholder_name" value={data.transferList[key].stakeholder_id} onChange={this.setStakeholderName.bind(this,key)}>
                                                            <option />
                                                            {this.props.stakeholders && this.props.stakeholders.map((val,k)=>{
                                                                return(
                                                                    <option  value={val.id}  key={k}>{val.nick_name}</option>
                                                                )
                                                            })
                                                            }
                                                        </select>
                                                    </td>
                                                    <td  className="width180">
                                                        <select className={"select-box transfer_select paddingRight18 "+isCursor } name="target_plan_id" value={data.target_plan_id} onChange={this.setShareClassPlan} disabled={isDisabled}>
                                                            <option />
                                                            {shareClassPlanList && shareClassPlanList.map((val,k)=>{
                                                                return(
                                                                    <option  value={val.id}  key={k} >{val.share_class_name}</option>
                                                                )
                                                            })
                                                            }
                                                        </select>
                                                    </td>
                                                    <td className=" width140">
                                                        {isFractionalShare == 1 ?
                                                        <input name="shares" type="text" value={value.shares} onChange={this.inputChange5Digit.bind(this, true, key)}/> :
                                                        <input name="shares" type="text" value={value.shares} onChange={this.inputChange5Digit.bind(this, false, key)}/>
                                                    }
                                                    </td>
                                                    <td className="box-lasttd noBorderTd">
                                                        <div className="btn-box relationship-btnBox">
                                                            <button type="button" onClick={this.addTransfer}  className="btn-mp plus"></button>
                                                            <button type="button" onClick={this.removeTransfer.bind(this,key)} className="btn-mp minus"></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }else{
                                            return(
                                                <tr className="scheduleTr bg-lightgray" key={key}>
                                                    <td className="width180">
                                                        <DateSelector
                                                            onChange={this.transferDateChange.bind(this, 'transfer_date', 'transfer_date_moment')}
                                                            value={I(data['transfer_date_moment'], I(data['transfer_date'], '') ? moment(data['transfer_date'], dateFormat) : '')}
                                                            disabled={isDisabled}
                                                        />
                                                    </td>
                                                    <td  className="width180">
                                                        <select className="select-box transfer_select paddingRight18" name="stakeholder_name" value={data.transferList[key].stakeholder_id} onChange={this.setStakeholderName.bind(this,key)}>
                                                            <option />
                                                            {this.props.stakeholders && this.props.stakeholders.map((val,k)=>{
                                                                return(
                                                                    <option  value={val.id}  key={k}>{val.nick_name}</option>
                                                                )
                                                            })
                                                            }
                                                        </select>
                                                    </td>
                                                    <td  className="width180">
                                                        <select className={"select-box transfer_select paddingRight18 "+isCursor } name="target_plan_id" value={data.target_plan_id} onChange={this.setShareClassPlan} disabled={isDisabled}>
                                                            <option />
                                                            {shareClassPlanList && shareClassPlanList.map((val,k)=>{
                                                                return(
                                                                    <option value={val.id}  key={k} >{val.share_class_name}</option>
                                                                )
                                                            })
                                                            }
                                                        </select>
                                                    </td>
                                                    <td className=" width140">
                                                        {isFractionalShare == 1 ?
                                                        <input name="shares" type="text" value={value.shares} onChange={this.inputChange5Digit.bind(this, true, key)}/> :
                                                        <input name="shares" type="text" value={value.shares} onChange={this.inputChange5Digit.bind(this, false, key)}/>
                                                        }
                                                    </td>
                                                    <td className="box-lasttd noBorderTd">
                                                        <div className="btn-box relationship-btnBox">
                                                            <button type="button" onClick={this.removeTransfer.bind(this,key)} className="btn-mp minus"></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="clearfix">
                                <p className="transfer-title offset">{printf(__('%s shares remaining'),restAvailableShares )}</p><br />
                                {this.state.transferType==="full" && <p className="text-muted offset">{printf(__('The final period must total %s shares (100%)'), formatFractionalNumber(curSelectSecurity.quantity))}</p>}
                            </div>
                        </div>
                        <div className="modal-footer box-footer invite-footer">
                            {isShowStep1Button === 1 && <button type="button" onClick={this.getTransferredCustomLabelId} data-toggle="tab" className="btn boxbtn-to bg-darkblue">{this.state.loadData==0?__("Next: enter security details"):__("Loading")}</button>}
                            {isShowStep1Button === 0 && <button type="button" className="btn boxbtn-to send-disabledBtn">{__('Next: enter security details')}</button>}

                        </div>
                    </div>
                    <div className="modal-content content760 tab-pane" id="transfer_step3">
                        <div className="modal-header box-header">
                            {this.state.currentFilledStakeholderKey === 0 && <a href="#transfer_step2" className="back-btn" aria-controls="transfer-step1" role="tab" data-toggle="tab"></a> }
                            {this.state.currentFilledStakeholderKey > 0 && <a className="back-btn" onClick={this.minusCurrentFilledKey} ></a>}
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true"  onClick={this.resetActive}>×</button>
                            <h4 className="modal-title" id="myModalLabel">Transfer {curSelectSecurity.custom_label_id}</h4>
                        </div>
                        <div className="modal-body padding2026 reset_margin">
                            <div className="flexDiv">
                                <span className="transfer_dic"></span>
                                <div className="width580">
                                    <p className="transfer-title">{printf(__("%s's Certificate"),data.transferList[this.state.currentFilledStakeholderKey].stakeholder_name )}</p>
                                    <p className="text-muted">{__('Enter security details')}</p>
                                </div>
                            </div>
                            <div className="padding2032">
                                {data.transferList.map((value,key)=>{
                                    let displayClass="hidden";
                                    if(key === this.state.currentFilledStakeholderKey){
                                        displayClass = "";
                                    }
                                    return(
                                        <div className={"form-group row "+displayClass}>
                                            <label className="col-xs-4 add-label">{__('Transfer agreement')}</label>
                                            <div className="col-xs-6 transfer-selBox">
                                                <div className="col-xs-6 no-paddingleft">
                                                    <div className="select-btn select-blue">
                                                        {__('Choose a file')}
                                                        <label className="sr-only" htmlFor={"transfer_file"+key} >File input</label>
                                                        <input id={"transfer_file"+key} ref={"transfer_agreement"+key} name={"transfer_agreement"+key} onChange={this.setTransferFile} type="file" />
                                                    </div>
                                                </div>
                                                <p className="col-xs-6 text-muted transfer-fileName">{data.transferList[key].transfer_agreement.name}</p>
                                            </div>
                                            <div className="stock-tipbox">
                                                <p className="col-xs-7 no-paddingleft col-xs-push-4 text-muted">{__('In most jurisdictions, regulators require that supporting documentation is included with each transfer.')}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className="form-group row">
                                    <label className="col-xs-4 add-label">{__('Cash paid ')}<br /><i><small className="text-muted">- {__('Required')}</small></i></label>
                                    <div className="col-xs-4 no-paddingleft">
                                        <div className="input-group">
                                            <span className="input-group-addon" id="cash_paid">{currency}</span>
                                            <input type="text" name="cash_paid" onBlur={this.autoFillTwoDecimal} onChange={this.recordData} value={data.transferList[this.state.currentFilledStakeholderKey].cash_paid}  className="form-control" aria-describedby="cash_paid" placeholder="100.00"/>
                                        </div>
                                    </div>
                                    <div className="stock-tipbox">
                                        <p className="col-xs-7 no-paddingleft  text-muted">{__('The amount of cash paid for this certificate. Certificates resulting from transfers will not show on the financing history page.')}</p>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-xs-4 add-label">{__('Debt canceled ')}<br /><i><small className="text-muted">- {__('Required')}</small></i></label>
                                    <div className="col-xs-4 no-paddingleft">
                                        <div className="input-group">
                                            <span className="input-group-addon" id="debt_canceled">{currency}</span>
                                            <input type="text" name="debt_canceled"  onBlur={this.autoFillTwoDecimal} onChange={this.recordData} value={data.transferList[this.state.currentFilledStakeholderKey].debt_canceled}  className="form-control" aria-describedby="debt_canceled" placeholder="100.00"/>
                                        </div>
                                    </div>
                                    <div className="stock-tipbox">
                                        <p className="col-xs-7 no-paddingleft  text-muted">{__('For convertible securities (e.g. convertible notes, SAFEs), the total value of principal and interest being converted into shares.')}</p>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-xs-4 add-label">{__('IP transferred ')}<br /><i><small className="text-muted">- {__('Required')}</small></i></label>
                                    <div className="col-xs-4 no-paddingleft">
                                        <div className="input-group">
                                            <span className="input-group-addon" id="ip_transferred">{currency}</span>
                                            <input type="text" name="ip_transferred"  onBlur={this.autoFillTwoDecimal} onChange={this.recordData} value={data.transferList[this.state.currentFilledStakeholderKey].ip_transferred}  className="form-control" aria-describedby="ip_transferred" placeholder="100.00"/>
                                        </div>
                                    </div>
                                    <div className="stock-tipbox">
                                        <p className="col-xs-7 no-paddingleft  text-muted">{__('Cash value of the technology or intellectual property exchanged for shares.')}</p>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-xs-4 add-label">{__('Other considerations')}<br /><i><small className="text-muted">- {__('Required')}</small></i></label>
                                    <div className="col-xs-4 no-paddingleft">
                                        <div className="input-group">
                                            <span className="input-group-addon" id="other_considerations">{currency}</span>
                                            <input type="text" name="other_considerations"  onBlur={this.autoFillTwoDecimal} onChange={this.recordData} value={data.transferList[this.state.currentFilledStakeholderKey].other_considerations} className="form-control" aria-describedby="other_considerations" placeholder="100.00"/>
                                        </div>
                                    </div>
                                    <div className="stock-tipbox">
                                        <p className="col-xs-7 no-paddingleft text-muted">{__('Any other value exchanged for the shares apart from cash, debt canceled, or IP transferred.')}</p>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-xs-4 add-label">{__('notes')}<br /><i><small className="text-muted">- {__('Optional')}</small></i></label>
                                    <div className="col-xs-6 no-paddingleft">
                                        <textarea type="text" name="notes" onChange={this.recordData} value={data.transferList[this.state.currentFilledStakeholderKey].notes} className="form-control minHeight160" aria-describedby="notes" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer box-footer invite-footer">
                            {isShowStep2Button ===1 && this.state.currentFilledStakeholderKey === (data.transferList.length-1) &&<button type="button" data-toggle="tab" data-target="#review_transfer_details" className="btn boxbtn-to bg-darkblue">{__('Next: review transfer details')}</button>}
                            {isShowStep2Button ===1 && this.state.currentFilledStakeholderKey < (data.transferList.length-1) && <button type="button" onClick={this.addCurrentFilledKey} className="btn boxbtn-to bg-darkblue">{__('Next: review transfer details')}</button>}
                            {isShowStep2Button ===0 && <button type="button" className="btn boxbtn-to send-disabledBtn">{__('Next: review transfer details')}</button>}

                        </div>
                    </div>
                    <div className="modal-content content760 tab-pane" id="review_transfer_details">
                        <div className="modal-header box-header">
                            <a href="#transfer_step3" className="back-btn" aria-controls="transfer-step3" role="tab" data-toggle="tab"></a>
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true"  onClick={this.resetActive}>×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Transfer')} {curSelectSecurity.custom_label_id}</h4>
                        </div>
                        <div className="modal-body padding2026 reset_margin">
                            <div className="marginBott20">
                                <div className="flexDiv">
                                    <span className="transfer_dic"></span>
                                    <div className="width580">
                                        <p className="transfer-title">{__('Original certificate')}</p>
                                        <p className="text-muted">{__('The following certificate will be transferred.')}</p>
                                    </div>
                                </div>
                                <div className="paddingLeft42">
                                    <table className="table viewHoldings-table marginTop10 no-marginBottom">
                                        <tbody>
                                        <tr className="first-row">
                                            <td>{__('Security')}</td>
                                            <td>{__('Stakeholder')}</td>
                                            <td>{__('Status')}</td>
                                            <td>{__('Shares')}</td>
                                            <td className="width100">&nbsp;</td>
                                        </tr>
                                        <tr className="scheduleTr bg-lightgray">
                                            <td className="width120">{curSelectSecurity.custom_label_id}</td>
                                            <td className="width140 ellipsisTd"><p>{curSelectSecurity.name}</p></td>
                                            <td>{curSelectSecurity.status}</td>
                                            <td className=" width160">{formatFractionalNumber(curSelectSecurity.quantity)}</td>
                                            <td className="box-lasttd noBorderTd">
                                                <a data-target="#details" data-toggle="collapse" className="link-cursor text-blue">{__('View details')}</a>
                                            </td>
                                        </tr>
                                        <tr className="transfer_detailsTr collapse" id="details">
                                            <td className="transfer_detailsTd" colSpan="5">
                                                    <table className="table transfer_detailsTable">
                                                        <tbody>
                                                            <tr className=" bg-lightgray">
                                                                <td>{__('Issue date')}</td>
                                                                <td>{ formatDate(curSelectSecurity.issue_date) }</td>
                                                            </tr>
                                                            <tr className=" bg-lightgray">
                                                                <td>{__('Price per share')}</td>
                                                                <td>
                                                                    {formatCurrencyNumber((parseFloat(Utils.resetNumberWithCommas(curSelectSecurity.cash_paid) ) + parseFloat(Utils.resetNumberWithCommas(curSelectSecurity.other_consideration)) + parseFloat (Utils.resetNumberWithCommas(curSelectSecurity.debt_canceled)) + parseFloat (Utils.resetNumberWithCommas(curSelectSecurity.value_of_ip_transferred)))/ curSelectSecurity.quantity)}
                                                                </td>
                                                            </tr>
                                                            <tr className=" bg-lightgray">
                                                                <td>{__('Total cost')}</td>
                                                                <td>{formatCurrencyNumber(curSelectSecurity.issue_price_per_share * curSelectSecurity.quantity)} </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="">
                                <div className="flexDiv">
                                    <span className="transfer_dic"></span>
                                    <div className="width580">
                                        <p className="transfer-title">{__('Resulting certificate')}</p>
                                        <p className="text-muted">{__('The following certificate will be issued as a result.')}</p>
                                    </div>
                                </div>
                                <div className="paddingLeft42">
                                    <table className="table viewHoldings-table marginTop10 no-marginBottom">
                                        <thead className="first-row">
                                        <tr>
                                            <td className="width120">{__('Security')}</td>
                                            <td className="width140 ">{__('Stakeholder')}</td>
                                            <td className="width140">{__('Status')}</td>
                                            <td className="width140">{__('Shares')}</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        </thead>
                                    {data.transferList.map((value,key)=>{
                                        return(
                                            <tbody key={key}>
                                                <tr className="scheduleTr bg-lightgray">
                                                    <td className="width120" >{value.custom_label_id}</td>
                                                    <td className="width140 ellipsisTd"><p>{value.stakeholder_name}</p></td>
                                                    <td className="width140">Outstanding</td>
                                                    <td className="width140">{formatFractionalNumber(value.shares)}</td>
                                                    <td className="box-lasttd noBorderTd">
                                                        <a data-target={"#result_details"+key} data-toggle="collapse" className="link-cursor text-blue">{__('View details')}</a>
                                                    </td>
                                                </tr>
                                                <tr className="transfer_detailsTr collapse" id={"result_details"+key}>
                                                    <td className="transfer_detailsTd" colSpan="5">
                                                        <table className="table transfer_detailsTable">
                                                            <tbody>
                                                            <tr className=" bg-lightgray">
                                                                <td>{__('Issue date')}</td>
                                                                <td>{ formatDate(data.transfer_date) }</td>
                                                            </tr>
                                                            <tr className=" bg-lightgray">
                                                                <td>{__('Price per share')}</td>
                                                                <td>{formatCurrencyNumber((parseFloat(Utils.resetNumberWithCommas(value.cash_paid)) + parseFloat(Utils.resetNumberWithCommas(value.debt_canceled)) + parseFloat(Utils.resetNumberWithCommas(value.ip_transferred)) +parseFloat(Utils.resetNumberWithCommas(value.other_considerations)))/Utils.resetNumberWithCommas(value.shares))}</td>
                                                            </tr>
                                                            <tr className=" bg-lightgray">
                                                                <td>{__('Total cost')}</td>
                                                                <td>{formatCurrencyNumber((parseFloat(Utils.resetNumberWithCommas(value.cash_paid)) + parseFloat(Utils.resetNumberWithCommas(value.debt_canceled)) + parseFloat(Utils.resetNumberWithCommas(value.ip_transferred)) + parseFloat(Utils.resetNumberWithCommas(value.other_considerations))))} </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                </tbody>
                                        )
                                    })}
                                    </table>
                                    {this.state.uploadProgress > 0 &&
                                    <div style={{textAlign:"center"}} align="center"  colSpan={"5"} className="marginTop10">{this.state.uploadProgress}% {__('uploaded')}...</div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer box-footer invite-footer">
                            <button onClick={this.submitTransferData} type="button" className="btn boxbtn-to width160 bg-darkblue">{__('Transfer')}</button>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
export default TransferShares;