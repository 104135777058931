import React from 'react';
import { Link } from "react-router-dom";
import Utils, { I } from "Utils";
import { post } from 'srcPath/http';
import security from "stores/security";
import Loading from 'components/Loading';
import moment from "moment";
import {DatePicker} from "antd";
import DateSelector from "../../../../../components/DateSelector";

class EditWarrantBlock extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            dateFormat:"DD/MM/YYYY",
            is_able_delete:0,
            initPrefix:"",
            loadingStatus:0,
            modifyStatus:0,
            cs_plan_list:{},
            existingPrefix:["abc"],
            existingBlockName:["abc"],
            data:{
                block_name:"",
                associated_coin_id: "",
                prefix: "",
                board_approval_date: "",
                exercise_price:"",
                purchase_agreement_file:{name:"No file selected",file_obj:""},
                form_of_warrant_file:{name:"No file selected",file_obj:""},
            },
            isShowEmptyError:0,
            requiredKey:["block_name","associated_coin_id","prefix","exercise_price"],
        }
    }
    initStep=()=>{
        window.$("#warrantBlockListModal .tab-pane").removeClass("active");
        window.$("#blockList").addClass("active");
        for(let i = 0; i<this.state.requiredKey.length;i++){
            $("#"+this.state.requiredKey[i]).removeClass("has-error")
        }
        this.setState({
            isShowEmptyError:0
        })
    }
    recordData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        })
    }
    recordNumber=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = Utils.check5DecimalNumInputWithoutComma(dataVal);
        this.setState({
            data:curData
        })
    }
    showFormatNumber=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = Utils.getNumFormatWithComma5(Utils.getNumWith5Decimal(dataVal));
        this.setState({
            data:curData
        })
    }
    focusNumber=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = Utils.resetNumberWithCommas(dataVal);
        this.setState({
            data:curData
        })
    }
    setFileName=(e)=>{
        let fileObj = e.target.files[0];
        let curData = this.state.data;
        let fileKey = e.target.name;
        Utils.uploadFile(fileObj).then((result)=>{
            curData[fileKey].name=fileObj.name;
            curData[fileKey].file_obj= result;
            this.setState ({
                data:curData
            });
        })
        $(".edit_"+fileKey).removeClass("hidden")
    }
    fileDelete =(fileKey)=>{
        var curData = this.state.data;
        curData[fileKey].name = "";
        curData[fileKey].file_obj = "";
        this.setState ({
            data:curData
        });
        $(".edit_"+fileKey).addClass("hidden");
    }
    initPageData=()=>{
        this.setState({
            loadingStatus:1
        })
        post('getCreateCoinWarrantBlockCheckList').then((response)=> {
            if(response.data.code === 0){
                this.setState({
                    cs_plan_list:response.data.data.shareClassList,
                    existingPrefix:response.data.data.prefixList,
                    existingBlockName:response.data.data.blockNameList,
                });
                this.setState({
                    loadingStatus:0
                })
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    checkEmptyData=(e)=>{
        this.state.requiredKey.map((value,key)=>{
            if(this.state.data[value] ==""){
                window.$("#edit_"+value).addClass("has-error");
            }else{
                window.$("#edit_"+value).removeClass("has-error");
            }
        });
        this.setState({
            isShowEmptyError:1
        });
    }
    fileDeleteIconStatus=()=>{
        let curData = this.state.data;
        let fileArr = ["purchase_agreement_file","form_of_warrant_file"];
        fileArr.map((val,k)=>{
            if(curData[val].file_obj!=""){
                $(".edit_"+val).removeClass("hidden")
            }else{
                $(".edit_"+val).addClass("hidden")
            }
        })
    }
    initData=(key,initData)=>{
        let curData = this.state.data;
        curData.id = initData.id;
        curData.purchase_agreement_file.name = initData.purchase_agreement_file.name;
        curData.form_of_warrant_file.name = initData.form_of_warrant_file.name;
        this.setState({
            data:initData,
            is_able_delete:initData.is_able_delete,
            initPrefix:initData.prefix
        })
        this.fileDeleteIconStatus();
        this.initPageData();
        // this.getWarrantBlockInfo();
    }
    modifyWarrantBlock=()=>{
        if(this.state.modifyStatus==1){
            return
        }
        this.setState({
            modifyStatus:1
        })
        let requestData = this.state.data;
        
        post('modifyCoinWarrantBlock',requestData).then((response)=> {
            if(response.data.code === 0){
                // window.location.reload();
                this.setState({
                    modifyStatus:0
                })
                // window.$("#warrantBlockModal").modal("hide");
                this.props.closeWarrantBlockDetailModal();
                this.props.getWarrantBlockList();
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    editWarrantDateChange = (key, moment_key, date) => {
        const { dateFormat } = this.state;
        let curData = this.state.data;
        curData[key] = date.format( dateFormat);
        curData[moment_key] = date;
        this.setState({
            data:curData
        });
    }

    render(){
        const { custom_coin_list = [] } = security.companyInfo || {}
        let isShowEmptyError = 0;
        if(this.state.data.block_name.length===0|| this.state.data.associated_coin_id.length===0 || this.state.data.prefix.length===0||this.state.data.exercise_price.length===0){
            isShowEmptyError = 1;
        }
        let showButton="";
        if(isShowEmptyError===0){
            showButton = <button onClick={this.modifyWarrantBlock}  className="btn boxbtn-to bg-darkblue update-btn">{this.state.modifyStatus == 0 ? "Modify Warrant Block":"Modifying"}</button>
        }else{
            showButton = <button onClick={this.checkEmptyData} className="btn boxbtn-to bg-darkblue update-btn">{__('Modify Warrant Block')}</button>;
        }
        let isLegalPrefix = 1; //默认为合法的输入
        for(let i=0;i<this.state.existingPrefix.length;i++){
            if(this.state.data.prefix!=this.state.initPrefix &&  this.state.data.prefix == this.state.existingPrefix[i]){
                isLegalPrefix = 0;  //有重复改为非法
            }
        }
        if(isLegalPrefix === 0){
            //重复的prefix，按钮无任何反应
            showButton = <button className="btn boxbtn-to bg-darkblue update-btn">{__('Modify Warrant Block')}</button>
        }
        let disabledStatus = false;
        if(this.state.is_able_delete==0){
            disabledStatus = true
        }
        const { currency = '' } = this.props;
        const { data, dateFormat } = this.state;
        return(
            <div className="modal-content content680">
                <div className="modal-header box-header">
                    <button type="button" onClick={this.initStep} className="close shut-btn"  data-toggle="tab" data-target="#blockList" aria-hidden="true" >×</button>
                    <h4 className="modal-title" id="myModalLabel">{__('Modify Warrant Block')}</h4>
                </div>
                <div className="modal-body equity-body">
                    {this.state.is_able_delete === 0 &&
                    <div className="alert alert-warning alert-dismissable pink-box eq-tipbox marginBott10" role="alert">
                        <p>{__('This warrant block is in use. Information about this warrant block can be viewed but not modified.')}</p>
                    </div>
                    }
                    <div className="position-relative">
                        <div className="equity-item-header bg-seablue">
                            <h5>{__('Basic warrant block information')}</h5>
                        </div>
                        {this.state.loadingStatus==1 &&
                        <div className="termsLoadingPosition">
                            <Loading/>
                        </div>
                        }
                        <form className="form-horizontal form_bg">
                            <div className="form-group ">
                                <label className="col-xs-5 add-label">{__('Warrant block name')}</label>
                                <div id="edit_block_name" className="col-xs-7">
                                    <input name="block_name" disabled={disabledStatus} onChange={this.recordData}
                                           value={this.state.data.block_name} className="form-control"
                                           type="text" placeholder="e.g. Series A Warrant Block"/>
                                </div>
                            </div>
                            <div className="form-group ">
                                <label className="col-xs-5 add-label">{__('Associated tokens')}</label>
                                <div className="col-xs-4" id="edit_associated_coin_id">
                                    <select name="associated_coin_id" disabled={disabledStatus} onChange={this.recordData}
                                            value={this.state.data.associated_coin_id}
                                            className="form-control select-container select-bgwhite">
                                        <option value="">--</option>
                                        {Object.values(custom_coin_list || {}).map((value, key) => {
                                            return (
                                                <option key={key}
                                                        value={value.id}>{value.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group ">
                                <label className="col-xs-5 add-label">{__('Warrant prefix')}</label>
                                <div id="edit_prefix" className="col-xs-4">
                                    <input name="prefix" disabled={disabledStatus} onChange={this.recordData}
                                           value={this.state.data.prefix}
                                           className="form-control uppercase_form" type="text" placeholder=""/>
                                </div>
                            </div>
                            <div className="form-group ">
                                <div className="col-xs-5">
                                    <label className="add-label">{__('Board approval date')}<br/><i
                                        className="text-muted">- {__('Optional')}</i></label>
                                </div>
                                <div className="col-xs-4">
                                    {/*<input placeholder="DD/MM/YYYY" name="board_approval_date"*/}
                                    {/*       value={this.state.data.board_approval_date} disabled={disabledStatus}*/}
                                    {/*       onChange={this.recordData}*/}
                                    {/*       className="form-control date-calendar date-calendar-with10padding calender-positionReset board_approval_date"*/}
                                    {/*       type="text"/>*/}
                                    {/*<DatePicker*/}
                                    {/*    disabled={disabledStatus}*/}
                                    {/*    allowClear={false} format={ dateFormat }*/}
                                    {/*    onChange={this.editWarrantDateChange.bind(this, 'board_approval_date', 'board_approval_date_moment')}*/}
                                    {/*    placeholder={dateFormat}*/}
                                    {/*    value={I(data['board_approval_date_moment'], I(data['board_approval_date'], '') ? moment(data['board_approval_date'], dateFormat) : '')}*/}
                                    {/*    suffixIcon={false}*/}
                                    {/*    inputReadOnly={false}*/}
                                    {/*    className="date-calendar-with10padding calender-positionReset  w100 "*/}
                                    {/*/>*/}
                                    <DateSelector
                                        disabled={disabledStatus}
                                        onChange={this.editWarrantDateChange.bind(this, 'board_approval_date', 'board_approval_date_moment')}
                                        value={I(data['board_approval_date_moment'], I(data['board_approval_date'], '') ? moment(data['board_approval_date'], dateFormat) : '')}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-xs-5">
                                    <label className="add-label">{__('Exercise price')}</label>
                                </div>
                                <div id="edit_exercise_price" className="col-xs-4">
                                    <div className="input-group"><span className="input-group-addon"
                                                                       id="exercise-price-addon">{currency}</span>
                                        <input type="text" aria-describedby="exercise-price-addon"
                                               onBlur={this.showFormatNumber} onFocus={this.focusNumber}
                                               name="exercise_price" disabled={disabledStatus}
                                               value={this.state.data.exercise_price}
                                               onChange={this.recordNumber}
                                               className="form-control"/>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                    <div>
                        <div className="equity-item-header bg-seablue">
                            <h5>Upload warrant block documents</h5>
                        </div>
                        <form className="form-horizontal form_bg">
                            <p className="text-muted">{__('Upload default documents. You can replace them when you issue warrants.')}</p>
                            <div className="form-group ">
                                <div className="col-xs-5">
                                    <label className=" add-label">{__('Warrant purchase agreement')}<br/><i
                                        className="text-muted">- {__('Optional')}</i></label>
                                </div>
                                <div className="col-xs-3 ">
                                    <div className="select-btn select-blue equ-choose">{__('Choose a file')}
                                        <label htmlFor="files-select" className="sr-only">{__('File input')}</label>
                                        <input ref="ea_plan" type="file" disabled={disabledStatus}
                                               name="purchase_agreement_file" onChange={this.setFileName}/>
                                    </div>
                                </div>
                                <div className="col-xs-4 no-paddingleft">
                                    <div className="file-box equity-file marginTop0 create_equity-file ">
                                                {/*<span onClick={this.fileDelete.bind(this, "purchase_agreement_file")}*/}
                                                {/*      className="close file-del create_file-del hidden edit_purchase_agreement_file">x</span>*/}
                                        <p className="text-muted">{this.state.data.purchase_agreement_file.name!=""?this.state.data.purchase_agreement_file.name:__("No file selected")}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group ">
                                <div className="col-xs-5">
                                    <label className=" add-label">{__('Form of warrant')}<br/><i className="text-muted">-
                                        {__('Optional')}</i></label>
                                </div>
                                <div className="col-xs-3 ">
                                    <div className="select-btn select-blue equ-choose">{__('Choose a file')}
                                        <label htmlFor="files-select" className="sr-only">{__('File input')}</label>
                                        <input ref="ea_plan" type="file" disabled={disabledStatus}
                                               name="form_of_warrant_file" onChange={this.setFileName}/>
                                    </div>
                                </div>
                                <div className="col-xs-4 no-paddingleft">
                                    <div className="file-box equity-file marginTop0 create_equity-file ">
                                                {/*<span onClick={this.fileDelete.bind(this, "form_of_warrant_file")}*/}
                                                {/*      className="close file-del create_file-del hidden edit_form_of_warrant_file">x</span>*/}
                                        <p className="text-muted">{this.state.data.form_of_warrant_file.name!=""?this.state.data.form_of_warrant_file.name:__("No file selected")}</p>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    {this.state.showErrorInfo === "show" &&
                    <div id="" className="alert alert-warning alert-dismissible pink-box eq-tipbox"
                         role="alert">
                        <p>{__('If you select a box, please input a corresponding number.')}</p>
                    </div>
                    }
                    {this.state.isShowEmptyError === 1 &&
                    <div className="alert alert-warning alert-dismissible pink-box eq-tipbox" role="alert">
                        <p>{__('Required field(s) can not be empty.')}</p>
                    </div>
                    }
                    {isLegalPrefix === 0 &&
                    <div className="alert alert-warning alert-dismissable pink-box eq-tipbox" role="alert">
                        <p>{__('Warrant prefix already exists. Please select another.')}</p>
                    </div>
                    }
                </div>
                <div className="modal-footer box-footer">
                    {showButton}
                </div>
            </div>
        )
    }

}

export default EditWarrantBlock;

