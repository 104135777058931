import React, { Component, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect, useHistory  } from "react-router-dom";
import { Spin, message, Select, Button, ConfigProvider } from 'antd';
import { language } from 'Utils/i18n';
import zh_CN from 'antd/es/locale/zh_CN';
import zh_HK from 'antd/es/locale/zh_HK';
import zh_TW from 'antd/es/locale/zh_TW';
import security from 'stores/security';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import Notification from 'components/Notification';

import 'assets/css/bootstrap.css';
import 'assets/css/outbox.css';
import 'assets/css/rain.css';
import 'assets/css/sidebar.css';
import 'assets/css/mainbox.css';
import 'assets/css/content1.css';
import 'assets/css/root.css';
import 'assets/css/public.css';
import './style.less'

import Business from 'modules/Business';
import Client from 'modules/Client';
import Task from 'modules/Task';
import Auth from 'modules/Auth';
import Test from 'modules/Test';
import Signup from 'modules/Signup';
import AdmintoolLogin from 'modules/AdmintoolLogin';
import AdmintoolUserLogin from 'modules/AdmintoolUserLogin';
import Empty from 'modules/Empty';

import Modal from 'components/Modal';
import Drawer from 'components/Drawer'

const isDevelop = process.env.NODE_ENV === 'development'
const Option = Select.Option

const apiArr = [
    {
        label: 'dev',
        value: '/api/dev'
    },
    {
        label: 'qa',
        value: '/api/qa'
    },
    {
        label: 'staging',
        value: '/api/staging'
    },
    {
        label: 'prod',
        value: '/api/prod'
    },    
]

const pathMap = {
    'C': '/client',
    'B': '/home',
    'TASK': '/task',
    'EMPTY': '/empty',
}

const localeData = {
    zh_CN: zh_CN,
    zh_HK: zh_HK,
    zh_TW: zh_TW
}

@observer
class App extends Component {
    state = {
        loading: true
    }

    modalRef = null

    async componentDidMount() {
        try {
            await security.init()

            this.setState({ loading: false })

            if (security.platform !== 'EMPTY') {
                security.loadFileLoop?.()
            }
        } catch(e) {
            if((e && e.message || '').includes('localStorage')) {
                this.modalRef = Modal.open({
                    component: props => <div>
                        <p>{__('Your browser is currently set to block cookies. You browser must allow cookies before you can use a Folium account.')}
                        <br/>
                        <br/>
                            {__("Cookies are small text files stored on your browser that tell site and service when you're signed in.To learn more about how to allow cookies, see online help in your web browser.")}
                        </p>
                        <div style={{ textAlign: 'center', marginTop: 20 }}><Button onClick={props.close} style={{ width: 100 }} type="primary">Ok</Button></div>
                    </div>
                })

                return
            }
            message.error(e.message)
        }
    }

    handleChange = val => {
        const api = '/api/' + val

        security.clear()

        security.env = val
        security.api = api
        localStorage.setItem('SPROUT_API', api)
    }

    render() {
        const { loading } = this.state

        if (loading) { 
            return <div className="global-loading">
               <div className="global-loading-wrap"><Spin /></div>
            </div>
        }

        return <div className="sprout-root">
            <Notification/>
            <Helmet>
                <title>{`${security.companyInfo?.company_name || 'Auth'} - Folium`}</title>
            </Helmet>
            {
                isDevelop && <div style={{ position: 'fixed', right: 110, top: 82, zIndex: 999 }}>
                    <Select onChange={this.handleChange} value={security.api} style={{ width: 150 }}>
                        {
                            apiArr.map((val, inxex) => <Option value={val.label} key={inxex} >{val.label}</Option>)
                        }
                    </Select>
                </div>                
            }
            <BrowserRouter>
                <ConfigProvider locale={localeData[language]}>
                    <AppContainer>
                        <Switch>
                            <Route path="/error" component={() => <div><a href="https://staging.getsprout.co/sign_in.html">login</a></div>} />
                            <Route path="/auth" component={Auth} />
                            <Route path="/signup" component={Signup} />
                            <Route path="/admintool_login" component={AdmintoolLogin} />
                            <Route path="/admintool_user_login" component={AdmintoolUserLogin} />
                            {isDevelop && <Route path="/test" component={Test} />}
                            { security.platform === 'B' && <Route path="/home" component={Business} />}
                            { security.platform === 'C' &&<Route path="/client" component={Client} />}
                            {security.platform === 'TASK' && <Route path='/task' component={Task} />}
                            {security.platform === 'EMPTY' && <Route path='/empty' component={Empty} />}
                            <Redirect to={pathMap[security.platform] || '/auth'} />
                        </Switch>
                    </AppContainer>
                </ConfigProvider>
            </BrowserRouter>
        </div>
    }
}

export default App;


function AppContainer({ children }) {
    const history = useHistory()

    useEffect(() => { 
        const unlisten = history.listen(() => {
            Modal.stack?.forEach(val => val?.())
            Modal.stack = []
            Drawer.stack?.forEach(val => val?.())
            Drawer.stack = []
        })

        return unlisten
     }, [])
    return <>{children}</>
}