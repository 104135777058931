import React, { useState, useEffect } from 'react';
import { Button, Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import security from 'stores/security';

export const tokenIdLabel = 'SPROUT_TOKEN_TOKENID'

export function getDefaultInfo() {
	const { custom_coin_list = [], company_id } = security.companyInfo || {}
	const defaultTokenInfo = JSON.parse(localStorage.getItem(tokenIdLabel) || '{}')[`${company_id}`] || custom_coin_list[0] || {}


	return defaultTokenInfo
}

function TokenSelector(props) {
	const companyInfo = security.companyInfo || {}
	const { custom_coin_list = [], company_id } = companyInfo
	const { value, onChange } = props;
	const [coinData, setCoinData] = useState();

	function setData(id) {
		const data = custom_coin_list.find(val => val.id === id)
    	
		localStorage.setItem(tokenIdLabel, JSON.stringify({ [company_id]: { ...data, id } }));

		setCoinData(data)

		return data || {}
	}

	useEffect(() => {
		setData(value)
	}, [value])

    const handleMenu = ({key}) => {
    	const data = setData(key)

    	onChange && onChange(key, data)
    }

	return <Dropdown
		overlay={
			<Menu onClick={handleMenu} selectedKeys={[value]}>
				{custom_coin_list.map(val => (<Menu.Item key={val.id}>{val.name}</Menu.Item>))}
			</Menu>
		}
	>
		<Button className="sprout-btn-white" style={{ display: 'flex', 'alignItems': 'center' }}>
			{coinData ? <span><img style={{ marginRight: 4, borderRadius: 4 }} height="22" src={coinData.image} />{coinData.name}</span> : 'Select'}
			<DownOutlined />
		</Button>
	</Dropdown>
}

export default (TokenSelector)

export const getDefaultTokenId = () => getDefaultInfo().id
