import React from 'react';
import 'assets/css/merge.css';
import { Link } from "react-router-dom";
import 'assets/css/sign.css';
import axios from "axios/index";
import Utils from "Utils";
import { message } from 'antd';

class MergeStakeholders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data:{
                name:'',
                email:'',
                securities:'',
                passport_or_national_id:'',
                employee_id:'',
                address:'',
                // city:'',
                // state:'',
                // post_code:'',
                country_region:'',
                date_of_birth:'',
                relationShipList:[],
                sign_name:"",
                merged_id_arr:[],

            },
            stakeholderOneData:{relationshipDataList:[]},
            stakeholderTwoData:{relationshipDataList:[]},
            sendStatus:0,
        }
    }

    componentDidMount(){
        window.$('.effective_date0').datepicker({
            maxViewMode: 3,
            todayBtn: "linked",
            todayHighlight: true,
            autoclose: true,
            format: "dd/mm/yyyy"
        }).on("changeDate",(e)=>{
            let curData = this.state.data;
            let targetKey =parseInt(e.target.name);
            curData.relationShipList[targetKey].effective_date = e.format();
            this.setState({
                data:curData
            });
        })

    }
    setMergeInitData=()=>{
        let relationshipListOne = this.props.curHolders[this.props.curKey[0]];
        let relationshipListTwo = this.props.curHolders[this.props.curKey[1]];
        let curData = this.state.data;
        for(let i=0;i<relationshipListOne.relationshipDataList.length;i++){
            curData.relationShipList.push(relationshipListOne.relationshipDataList[i]);
        }
        for(let i=0;i<relationshipListTwo.relationshipDataList.length;i++){
            curData.relationShipList.push(relationshipListTwo.relationshipDataList[i]);
        }
        curData.merged_id_arr = this.props.curKey;
        this.setState({
            stakeholderOneData: relationshipListOne,
            stakeholderTwoData: relationshipListTwo,
            data:curData
        })
        setTimeout(()=>{
            for(let i=0;i<this.state.data.relationShipList.length;i++){
                this.bindDatepicker((i));
            }
         },300)
        console.log(this.state.data.relationShipList)
    }
    recordData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        })
    }
    removeActive=()=>{
        window.$("#mergeStakeholdersModal .tab-pane").removeClass("active");
    }

    bindDatepicker=(key)=>{
        window.$('.effective_date'+key).datepicker({
            maxViewMode: 3,
            todayBtn: "linked",
            todayHighlight: true,
            autoclose:true,
            format:"dd/mm/yyyy"
        }).on("changeDate",(e)=>{
            let curData = this.state.data;
            let targetKey =parseInt(e.target.name);
            curData.relationShipList[targetKey].effective_date = e.format();
            this.setState({
                data:curData
            });
        });
    }

    bindBirthDatepicker=()=>{
        window.$('.date_of_birth').datepicker({
            maxViewMode: 3,
            todayBtn: "linked",
            todayHighlight: true,
            autoclose: true,
            format: "dd/mm/yyyy"
        }).on("changeDate",(e)=>{
            let curData = this.state.data;
            let targetKey = e.target.name;
            curData[targetKey] = e.format();
            console.log(targetKey)
            this.setState({
                data:curData
            });
        })
    }

    addRelation=()=> {
        let one = {effective_date: '', relationship: ""}
        let curList = this.state.data;
        curList.relationShipList.push(one);
        this.setState({
            data:curList
        })
        setTimeout(()=>{
            this.bindDatepicker((curList.relationShipList.length-1));
        },300)
    }
    delRelation=(num)=>{
        let curList = this.state.data;
        if(curList.relationShipList.length <=1){
            message.error("You only have one relationship now!")
            return;
        }
        curList.relationShipList.splice(num,1);
        this.setState({
            data:curList
        })
    }

    setRelation=(key,e)=>{
        let curList = this.state.data;
        curList.relationShipList[key].relationship = e.target.value;
        this.setState({
            data:curList
        })
    }
    resetActive=()=>{
        window.$("#mergeStakeholdersModal .tab-pane").removeClass("active");
        window.$("#mergeStakeholders").addClass("active");
    }
    mergeStakeholders=()=>{
        if(this.state.sendStatus===1){
            return;
        }
        this.setState({
            sendStatus:1
        })
        let requestData = this.state.data;
        axios.post('https://api.getsprout.co',{
            action:"mergeStakeholders",
            company_id:Utils.getLocalCompanyInfo().company_id,
            data:requestData,
        }).then((response)=>{
            if(response.data.code===0){
                message.success("Success!");
                window.$("#mergeStakeholdersModal").modal("hide");
                this.setState({
                    sendStatus:0
                })
                this.resetActive();
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    render(){

        return(
            <div className="modal fade" id="mergeStakeholdersModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={{display: "none"}} >
                <div className="tab-content">
                    <div id="mergeStakeholders" className="modal-dialog merge-dialog tab-pane active">
                        <div className="modal-content box-content merge-content">
                            <div className="modal-header box-header">
                                <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true"  onClick={this.removeActive}>×</button>
                                <h4 className="modal-title" id="myModalLabel">Merge Stakeholders' Information</h4>
                            </div>
                            <div className="modal-body relation-body">
                                <div id="merge_error" className="alert alert-warning alert-dismissible pink-box">
                                    <p>Correct the problems below</p>
                                    <p>Name:This field may not be blank.</p>
                                    <p>Email:This field may not be blank or enter a valid email address.</p>
                                </div>
                                <p className="merge-text box-text">Compare the stakeholder details below and enter the updated values in the cells on the right. Click next to review your u informationdated stakeholder</p>
                                {this.props.curKey.length=== 2 &&
                                <table className="table merge-table">
                                    <tbody>
                                    <tr className="first-row">
                                        <td>&nbsp;&nbsp;</td>
                                        <td>Stakeholder 1</td>
                                        <td>Stakeholder 2</td>
                                        <td className="update_td">Updated Stakeholder</td>
                                    </tr>
                                    <tr>
                                        <td>Name</td>
                                        <td>{this.state.stakeholderOneData.name}</td>
                                        <td>{this.state.stakeholderTwoData.name}</td>
                                        <td><input className="can-input" value={this.state.data.name} name="name"  onChange={this.recordData}></input></td>
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td>{this.state.stakeholderOneData.email}</td>
                                        <td>{this.state.stakeholderTwoData.email}</td>
                                        <td><input className="can-input" value={this.state.data.email} name="email"  onChange={this.recordData}></input></td>
                                    </tr>
                                    <tr>
                                        <td>Securities</td>
                                        <td>{this.state.stakeholderOneData.securities}</td>
                                        <td>{this.state.stakeholderTwoData.securities}</td>
                                        <td><input className="can-input" value={this.state.data.securities} name="securities"  onChange={this.recordData}></input></td>
                                    </tr>
                                    <tr>
                                        <td>Passport or National ID</td>
                                        <td>{this.state.stakeholderOneData.passport_or_national_id}</td>
                                        <td>{this.state.stakeholderTwoData.passport_or_national_id}</td>
                                        <td><input className="can-input" value={this.state.data.passport_or_national_id} name="passport_or_national_id"  onChange={this.recordData}></input></td>
                                    </tr>
                                    <tr>
                                        <td>Employee ID</td>
                                        <td>{this.state.stakeholderOneData.employee_id}</td>
                                        <td>{this.state.stakeholderTwoData.employee_id}</td>
                                        <td><input className="can-input" value={this.state.data.employee_id} name="employee_id"  onChange={this.recordData}></input></td>
                                    </tr>
                                    <tr>
                                        <td>Address</td>
                                        <td>{this.state.stakeholderOneData.address}</td>
                                        <td>{this.state.stakeholderTwoData.address}</td>
                                        <td><input className="can-input" value={this.state.data.address} name="address"  onChange={this.recordData}></input></td>
                                    </tr>
                                    {/*<tr>*/}
                                        {/*<td>City</td>*/}
                                        {/*<td>{this.state.stakeholderOneData.city}</td>*/}
                                        {/*<td>{this.state.stakeholderTwoData.city}</td>*/}
                                        {/*<td><input className="can-input" value={this.state.data.city} name="city"  onChange={this.recordData}></input></td>*/}
                                    {/*</tr>*/}
                                    {/*<tr>*/}
                                        {/*<td>District, Province or State</td>*/}
                                        {/*<td>{this.state.stakeholderOneData.state}</td>*/}
                                        {/*<td>{this.state.stakeholderTwoData.state}</td>*/}
                                        {/*<td><input className="can-input" value={this.state.data.state} name="state"  onChange={this.recordData}></input></td>*/}
                                    {/*</tr>*/}
                                    {/*<tr>*/}
                                        {/*<td>Post Code</td>*/}
                                        {/*<td>{this.state.stakeholderOneData.post_code}</td>*/}
                                        {/*<td>{this.state.stakeholderTwoData.post_code}</td>*/}
                                        {/*<td><input className="can-input" value={this.state.data.post_code} name="post_code"  onChange={this.recordData}></input></td>*/}
                                    {/*</tr>*/}
                                    <tr>
                                        <td>Country</td>
                                        <td>{this.state.stakeholderOneData.country_region}</td>
                                        <td>{this.state.stakeholderTwoData.country_region}</td>
                                        <td><input className="can-input" value={this.state.data.country_region} name="country_region"  onChange={this.recordData}></input></td>
                                    </tr>
                                    <tr>
                                        <td>Date of Birth</td>
                                        <td>{this.state.stakeholderOneData.date_of_birth}</td>
                                        <td>{this.state.stakeholderTwoData.date_of_birth}</td>
                                        <td><input placeholder="DD/MM/YYYY" name="date_of_birth" className="merge-calender can-input calender-positionReset date-calendar datepicker date_of_birth" type="text" /></td>
                                    </tr>
                                    </tbody>
                                </table>
                                }
                            </div>
                            <div className="modal-footer box-footer">
                                <a href="#relationship" type="button" data-toggle="tab" className="btn boxbtn-to btn-toRelationship bg-darkblue" >Next: correct relationship history</a>
                            </div>
                        </div>
                    </div>
                    <div className="modal-dialog dialog-top40 tab-pane" id="relationship">
                        <div className="modal-content box-content relation-content">
                            <div className="modal-header box-header relation-header">
                                <a href="#mergeStakeholders" data-toggle="tab" type="button" className="back-btn" ></a>
                                <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true"  onClick={this.removeActive}>×</button>
                                <h4 className="modal-title" id="myModalLabel">Correct Relationship History</h4>
                            </div>
                            <div className="modal-body box-body relation-body">
                                <p className="box-text">Review stakeholder relationships and effective dates to ensure they are correct. Click next to view your submission.</p>
                                <table className="table table-striped relationship-table">
                                    <tbody>
                                    <tr className="first-row">
                                        <td className="effective-date">Effective date</td>
                                        <td>Relationship</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    {this.state.data.relationShipList.map((value,key)=>{
                                        if(this.state.data.relationShipList.length == (key+1)){
                                            return(
                                                <tr key={key}>
                                                    <td><input name={key} value={value.effective_date} className={"relationship-date-input relationship-calender date-calendar-with10padding effective_date"+key} placeholder="DD/MM/YYYY" type="text" /></td>
                                                    <td className="advisor-td relationship-td">
                                                        <select className="select-box relationship_selectbox" value={value.relationship} onChange={this.setRelation.bind(this,key)}>
                                                            {this.props.relationshipList.map((value,key)=>{
                                                                return(
                                                                    <option key={key}>{value}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </td>
                                                    <td className="box-lasttd">
                                                        <div className="btn-box relationship-btnBox">
                                                            <button type="button" onClick={this.addRelation} className="btn-mp plus"></button>
                                                            <button type="button" onClick={this.delRelation.bind(this,key)} className="btn-mp minus"></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }else{
                                            return(
                                                <tr key={key}>
                                                    <td><input name={key} value={value.effective_date} className={"relationship-date-input relationship-calender date-calendar-with10padding effective_date"+key} placeholder="DD/MM/YYYY"  type="text" /></td>
                                                    <td className="advisor-td relationship-td">
                                                        <select className="select-box relationship_selectbox" value={value.relationship} onChange={this.setRelation.bind(this,key)}>
                                                            {this.props.relationshipList.map((value,key)=>{
                                                                return(
                                                                    <option key={key}>{value}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </td>
                                                    <td className="box-lasttd">
                                                        <div className="btn-box relationship-btnBox">
                                                            <button onClick={this.delRelation.bind(this,key)} type="button" className="btn-mp minus"></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }

                                    })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer box-footer relation-footer">
                                <a href="#reviewDetails" data-toggle="tab" className="btn boxbtn-to btn-toDetails bg-darkblue">Next: review stakeholder details</a>
                            </div>
                        </div>
                    </div>
                    <div className="modal-dialog dialog-top40 tab-pane" id="reviewDetails">
                        <div className="modal-content box-content relation-content">
                            <div className="modal-header box-header relation-header">
                                <a href="#relationship" data-toggle="tab" type="button" className="back-btn"></a>
                                <button type="button" className="close shut-btn" onClick={this.removeActive} data-dismiss="modal" aria-hidden="true">×</button>
                                <h4 className="modal-title" id="myModalLabel">Review Stakeholder Details</h4>
                            </div>
                            <div className="modal-body box-body relation-body">
                                <p className="box-text">Review the following changes to stakeholder details to ensure they are correct.</p>
                                <table className="table merge-table review-relationship-table">
                                    <tbody>
                                    <tr>
                                        <td className="width50perc">Name</td>
                                        <td>{this.state.data.name}</td>
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td>{this.state.data.email}</td>
                                    </tr>
                                    <tr>
                                        <td>Securities</td>
                                        <td>{this.state.data.securities}</td>
                                    </tr>
                                    <tr>
                                        <td>Passport or National ID</td>
                                        <td>{this.state.data.passport_or_national_id}</td>
                                    </tr>
                                    <tr>
                                        <td>Employee ID</td>
                                        <td>{this.state.data.employee_id}</td>
                                    </tr>
                                    <tr>
                                        <td>Address</td>
                                        <td>{this.state.data.address}</td>
                                    </tr>
                                    {/*<tr>*/}
                                        {/*<td>City</td>*/}
                                        {/*<td>{this.state.data.city}</td>*/}
                                    {/*</tr>*/}
                                    {/*<tr>*/}
                                        {/*<td>District,Province or State</td>*/}
                                        {/*<td>{this.state.data.state}</td>*/}
                                    {/*</tr>*/}
                                    {/*<tr>*/}
                                        {/*<td>Post Code</td>*/}
                                        {/*<td>{this.state.data.post_code}</td>*/}
                                    {/*</tr>*/}
                                    <tr>
                                        <td>Country</td>
                                        <td>{this.state.data.country_region}</td>
                                    </tr>
                                    <tr>
                                        <td>Date of Birth</td>
                                        <td>{this.state.data.date_of_birth}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer box-footer">
                                <a href="#completeSign" data-toggle="tab" className="btn boxbtn-to btn-toDetails bg-darkblue">Next: Complete stakeholder merge</a>
                            </div>
                        </div>
                    </div>
                    <div className="modal-dialog sign-dialog tab-pane" id="completeSign">
                        <div className="modal-content sign-content">
                            <div className="modal-header box-header relation-header">
                                <a href="#reviewDetails" data-toggle="tab" type="button" className="back-btn"></a>
                                <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.removeActive}>×</button>
                                <h4 className="modal-title" id="myModalLabel">Complete Stakeholder Merge</h4>
                            </div>
                            <div className="modal-body box-body relation-body">
                                <p className="box-text">Read and understand the terms below.</p>
                                <div className="understand-box">
                                    <p>I understand that merging these stakeholders is irreversible and that the information provided is accurate, I understand that this action may affect my cap table.</p>
                                </div>
                                <div className="">
                                    <div className="sign-here">
                                        <label className="label-text">Sign here</label>
                                        <input type="text" className="merge-sign-box" name="sign_name" value={this.state.data.sign_name} onChange={this.recordData} placeholder="Andy Lee" />
                                    </div>
                                    <p className="text-muted text_align_center">sign as "Polly Chow"</p>
                                </div>
                            </div>
                            <div className="modal-footer box-footer">
                                <button type="button" className="btn boxbtn-to bg-darkblue" data-dismiss="modal" onClick={this.removeActive}>Merge stakeholder</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default MergeStakeholders;