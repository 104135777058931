import React, { Component, Fragment } from "react";
import {Radio, Input, Space, Tooltip, Checkbox} from 'antd';
import { filterAmount, clearNoNum } from 'Utils';
import {CheckCircleFilled} from "@ant-design/icons";
class RightsAndPreferencesModels extends Component {

    constructor(props) {
        super(props);
        this.state = {
            multiplierHelp : __('The multiple of the original Issue price that will be paid out pursuant to the liquidation preferences.'),
            participatingPreferredHelp : __('Indicates that the holders of that preferred share class are paid out at the same time that the common stockholders are paid out, in addition to being previously paid out during their payout according to their seniority level (pursuant to the liquidation preferences).'),
        }
        this.radioOnChange = this.radioOnChange.bind(this);
        this.multiplierChange = this.multiplierChange.bind(this);
        this.participatingPreferredCapChange = this.participatingPreferredCapChange.bind(this);

    }

    /**
     * Option Pool change事件
     * @param e
     */
    radioOnChange = (e) => {
       const optionPoolRadioVal = e.target.value;
       this.setState({
           optionPoolRadioVal
       })
    }

    /**
     * multiplierChange change 事件
     */
    multiplierChange = (e) => {
        const { multiplierChange } = this.props;
        const obj = clearNoNum(e.target);
        multiplierChange( obj, e.target);
    }

    participatingPreferredCapChange = (e) => {
        const { participatingPreferredCapChange } = this.props;
        const obj = filterAmount(e, 9999);
        participatingPreferredCapChange(obj, e.target);
    }

    participatingPreferredChange = (e) => {

        const { participatingPreferredChange } = this.props;
        const value = e.target.checked;
        participatingPreferredChange(value);
    }



    render() {
        const { multiplierHelp, participatingPreferredHelp } = this.state;
        const { multiplier, participatingPreferred, participatingPreferredCap } = this.props;
        return (
            <div className="advance-model-box">
                <CheckCircleFilled className="advance-model-option-circle" />
                <div className="advance-model-option-content">
                    <div>{__('Rights and preferences')}</div>
                    <div>{__('Enter the rights and preferences of the share class.')}</div>
                    <div>
                        <dl >
                            <dt>
                                {__('Multiplier')}
                                <Tooltip title={multiplierHelp} color="#2b3946">
                                    <span className="table_icon title_icons">?</span>
                                </Tooltip>
                            </dt>
                            <dd>
                                <Input value={multiplier} onChange={this.multiplierChange} className="advance-model-input" addonAfter="x" defaultValue="" />
                            </dd>
                        </dl>
                        <dl className="pt-0">
                            <dt>
                                {__('Participating preferred')}
                                <Tooltip title={participatingPreferredHelp} color="#2b3946">
                                    <span className="table_icon title_icons">?</span>
                                </Tooltip>
                            </dt>
                            <dd>
                                <Checkbox checked={participatingPreferred} onChange={this.participatingPreferredChange} />
                            </dd>
                        </dl>
                        <dl className="pt-0" style={{display: participatingPreferred == 1 ? 'block' : 'none'}}>
                            <dt>{__('Participating preferred cap')}</dt>
                            <dd>
                                <Input value={participatingPreferredCap} onChange={this.participatingPreferredCapChange} className="advance-model-input" addonAfter="x" defaultValue="" />
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className="clear"></div>
                <div className="advance-model-height-1"></div>
            </div>
        )

    }
}

export default RightsAndPreferencesModels
