import React, { useEffect, useState } from "react";
import { post } from 'srcPath/http';
import { message, Alert } from "antd";
import Loading from 'components/Loading';
import SideBar from '../components/SideBar';
import Detail from './Detail';
import ExerciseHistory from "./ExerciseHistory";
import VestingSchedule from '../components/VestingSchedule/vestingSchedule';
import PersonalDetail from './PersonalDetail';
import Documents from "./Documents";
import Approvals from "../components/Approvals";
import { sprintf } from 'Utils/i18n';


const menuConfig = [
	{
		label: __('Details'),
		key: 'details'
	},
	{
		label: __('Exercise History'),
		key: 'exerciseHistory'
    },
    {
        label: __('Approvals'),
        key: 'approvals'
    },
	{
		label: __('Vesting schedule'),
		key: 'vestingSchedule'		
	},
    {
        label: __('Documents'),
        key: 'documents'
    },
	{
		label: __('Personal Detail'),
		key: 'personalDetails'
    }
];

export default function GrantDetail({ id }) {
    const [data, setData] = useState({})
    const [checkedKey, setCheckedKey] = useState('details')
    const getData = async () => { 

        Loading.global(true) 
        try {
            const { data: resData } = await post('getCoinWarrantDetail', { id })

            const { code, data } = resData || {};

            if (code === 0) {
                setData(data)
            } else { 
                message.error(data)
            }
        } catch (e) { 
            message.error(e.message)
        }

        Loading.global(false)
    }

    useEffect(() => { 
        getData()
    }, [])

    const handleSideBar = value => { 
        setCheckedKey(value)
    }

    const renderContent = () => { 
        const { approvals = [], details = {}, exerciseHistory = [], vestingSchedule = {}, personal_details = {}, documents_and_notes = {} } = data || {}

        const contentMap = {
            details: <Detail data={{...details, ...personal_details}} />,
            exerciseHistory: <ExerciseHistory data={exerciseHistory} />,
            approvals: <Approvals data={approvals} />,
            vestingSchedule: <VestingSchedule isToken data={{ name: data.grant_name, scheduleName: vestingSchedule.schedule_name, vestingStart: vestingSchedule.vesting_start, fullyVested: vestingSchedule.fully_vested, ...vestingSchedule  }} />,
            personalDetails: <PersonalDetail data={personal_details} />,
            documents: <Documents data={documents_and_notes} />
        }

        return contentMap[checkedKey] || ""
     }

    return <div className="certificate-modal-root">
        <div className="certificate-detail-root">
            <SideBar onChange={handleSideBar} value={checkedKey} config={menuConfig} type="Warrant" name={data.warrant_name}/>
            <div className="certificate-detail-content">
                {
                    data?.exerciseHistory?.length > 0 && <Alert style={{ marginBottom: 12 }} message={<span>{sprintf(__('This warrant has been exercised. Please refer to %s page for more details.'), <a onClick={() => { setCheckedKey('exerciseHistory')}}>{__('Exercise History')}</a>)}</span>} type="info" showIcon />
                }
                { renderContent() }
            </div>
        </div>
    </div>

}