import React, { Component } from 'react';
import { Table,  Collapse, message, Tooltip, Select } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { formatPercent, formatCurrencyNumber } from 'Utils';
import { post } from 'srcPath/http';
import security from 'stores/security';
import formatShares from 'Utils/format/formatShares';
import openCertificateModal from 'components/CertificateDetail';
import commonImg from 'C/Portfolio/images/common.png';
import preferredImg from 'C/Portfolio/images/preferred.png';
import convertibleImg from 'C/Portfolio/images/convertible.png';
import Loading from 'components/Loading';
import sharesIcon from './images/Shares.png';
import optionsIcon from './images/Options.png';
import convertiblesIcon from './images/Convertibles.png';
import warrantsIcon from './images/Warrants.png';
import { fmvOptionColumn, warrantColumn, tokenWarrantColumn, column, tokenVestingColumn } from './config';
import { getHoldingsStatus  } from 'C/Portfolio/config';

import './style.scss';

const {Option} =Select

const { Panel } = Collapse;

const renderTips = (text, img) => {
	let displayText = text
	switch (text) {
		case 'Warrants':
		displayText = __('Warrants')
		break;
		case 'Shares':
		displayText = __('Shares')
		break;
		case 'Options':
		displayText = __('Options')
		break;
		case 'Convertibles':
		displayText = __('Convertibles')
		break;
		case 'Vesting':
		displayText = __('Vesting')
		break;
		default:
		break;
	}
	if (!img) {
		if (text === 'Warrants') {
			displayText = __('Warrant(SAFT)')
		}
		return <span className="holdings-title">{displayText}</span>
	}
	return <Tooltip overlayClassName="client-portfolio-holdings-tip"title={<img src={img} />}>
		<span className="holdings-title">{displayText} <QuestionCircleTwoTone twoToneColor="#B9CCD1" /></span>
	</Tooltip>
}

class Holdings extends Component {
	state = {
		tab: 'capTable',
		data: {}
	}
	

	handleClick = tab => {
		this.setState({ tab })
	}

	componentDidMount() {
		this.getData()
	}

	getData = async () => {
		Loading.global(true)

		try {
			const { data: resData } = await post('getProfileHoldings', {
				stakeholder_id: security.companyInfo && security.companyInfo.stakeholder_id
			})

			const { code, data } = resData || {}

			if (code === 0) {
				this.setState({ data })
			}

		} catch (err) {
			message.error(err.message)
		}

		Loading.global(false)
	}

	renderSharesContent = (data, type) => {
		const { certificateId, certificateName, cost, gain, mktValue, purchaseDate, shares, status, totalGain } = data || {}

		return <div className="holdings-table-item">

            <div className="certificate-detail" onClick={() => openCertificateModal(certificateId)}>
                <div className="option-item"  onClick={() => openCertificateModal(certificateId)}>
                    <div className="certificate-img">
                        <img src={type === 'common' ? commonImg : preferredImg } />
                        <div className="certificate-status" style={getHoldingsStatus(status).style}>
                            {getHoldingsStatus(status).label}
                        </div>
                    </div>
                    <div className="certificate-name">{certificateName}</div>
                </div>
            </div>



			<div className="table-td">{formatShares(shares)}</div>
			<div className="table-td">{purchaseDate}</div>
			<div className="table-td">{formatCurrencyNumber(cost)}</div>
			<div className="table-td">{formatCurrencyNumber(mktValue)}</div>
			<div className="table-td">{formatCurrencyNumber(totalGain)}</div>
			<div className="table-td">{formatPercent(gain)}</div>
		</div>
	}

	renderSharesHeader = (data, type) => {
		const { cost, gain, mktValue, shares, totalGain } = data || {}

		return <div className="holdings-table-content">
			<div className="certificate-title">{type === 'common' ? 'Common' : 'Preferred'}</div>
			<div className="table-th">{formatShares(shares)}</div>
			<div className="table-th"></div>
			<div className="table-th">{formatCurrencyNumber(cost)}</div>
			<div className="table-th">{formatCurrencyNumber(mktValue)}</div>
			<div className="table-th">{formatCurrencyNumber(totalGain)}</div>
			<div className="table-th">{formatPercent(gain)}</div>
		</div>
	}

	renderConvertibleHeader = data => {
		const { amount, discount, intIncome, intRate, shareClass, valuationCap } = data || {}

		return <div className="holdings-table-content">
			<div className="certificate-title">{shareClass}</div>
			<div className="table-th">{formatShares(amount)}</div>
			<div className="table-th"></div>
			<div className="table-th">{formatCurrencyNumber(valuationCap)}</div>
			<div className="table-th">{formatPercent(discount/100)}</div>
			<div className="table-th">{formatPercent(intRate/100)}</div>
			<div className="table-th">{formatShares(intIncome)}</div>
		</div>		
	}

	renderConvertibleContent = data => {
		const { certificateId, certificateName, amount, discount, intIncome, intRate, maturityDate, valuationCap } = data || {}

		return <div className="holdings-table-item">
			<div className="certificate-detail" onClick={() => openCertificateModal(certificateId)}>
				<img src={convertibleImg} />
				<span>{certificateName}</span>
			</div>
			<div className="table-td">{formatShares(amount)}</div>
			<div className="table-td">{maturityDate}</div>
			<div className="table-td">{formatCurrencyNumber(valuationCap)}</div>
			<div className="table-td">{formatPercent(discount/100)}</div>
			<div className="table-td">{formatPercent(intRate/100)}</div>
			<div className="table-td">{formatShares(intIncome)}</div>
		</div>
	}

	handleFirstTabClick = tab => {
		this.setState({
			currentTab: tab
		})
	}

	render() {
		const { data, tab } = this.state
		const { convertibles = {}, options = {}, sharesFmv, shares,  warrant = {}, tokenVesting, tokenWarrant } = data || {}
		const isFkv = tab === 'fairmarketvalue'
		const { common = {}, preferred = {}, total = {} } =  (isFkv ? sharesFmv : shares) || {}
		const { total: convertiblesTotal, list: convertiblesList } = convertibles || {}

		return <div className="client-portfolio-holdings-wrap">
			<div className="latest-title">
				<div>{__('Basis for calculation')}:</div>
				<Select
					onChange={this.handleClick}
					value={tab}
				>
					<Option value="capTable">{__('Latest round')}</Option>
					<Option value="fairmarketvalue">{__('Latest company valuation')}</Option>
				</Select>
			</div>

			<div className="portfolio-capitalization-content">
				<div className="client-portfolio-holdings-root">
					{(common?.certificateList?.length > 0 || preferred?.certificateList?.length > 0) && (
						<div className="holdings-section">
							{renderTips('Shares', sharesIcon)}
							<div className="holdings-table-wrap">
								<div className="certificate-title">{__('Share Class')}</div>
								<div className="table-th">{__('Shares')}</div>
								<div className="table-th">{__('Purchase Date')}</div>
								<div className="table-th">{__('Cost')}</div>
								<div className="table-th">{__('Market Value')}</div>
								<div className="table-th">{__('Total Gain')}</div>
								<div className="table-th">% {__('Gain')}</div>
							</div>
							{
								(common.certificateList && common.certificateList.length > 0) && <Collapse >
									<Panel header={this.renderSharesHeader(common, 'common')} className="common" >
										{common.certificateList && common.certificateList.map((val, index) => (<div className="holdings-table-item-wrap" key={index}>{this.renderSharesContent(val)}</div>))}
									</Panel>
								</Collapse>
							}
							{
								(preferred.certificateList && preferred.certificateList.length > 0) && <Collapse>
									<Panel header={this.renderSharesHeader(preferred, 'preferred')} className="preferred">
										{preferred.certificateList && preferred.certificateList.map((val, index) => (<div className="holdings-table-item-wrap" key={index}>{this.renderSharesContent(val)}</div>))}
									</Panel>
								</Collapse>
							}
							<div className="table-total">
								<div className="table-total-td-first">{__('Total')}</div>
								<div className="table-total-td"></div>
								<div className="table-total-td"></div>
								<div className="table-total-td">{formatCurrencyNumber(total.cost)}</div>
								<div className="table-total-td">{formatCurrencyNumber(total.mktValue)}</div>
								<div className="table-total-td">{ total.totalGain >= 0 ? formatCurrencyNumber(total.totalGain) : <span style={{ color: 'red' }}>({formatCurrencyNumber(-total.totalGain)})</span> }  </div>
								<div className="table-total-td"></div>
							</div>			
						</div>
					)}
					{
						(options.list && options.list.length > 0) && <div className="holdings-section">
							{renderTips('Options', optionsIcon)}
							<Table 
								size="small" 
								columns={isFkv ? fmvOptionColumn : column} 
								rowKey="certificateId" 
								dataSource={options.list || []} 
								pagination={false} 
								scroll={{ x: true }}
								summary={() => {
								const { exercisable, exercised, totalMktValue, vestedShares, vestedValue, fmvTotalMktValue,fmvVestedValue } = options && options.total || {}

								return <Table.Summary.Row>
									<Table.Summary.Cell>{__('Total')}</Table.Summary.Cell>
									<Table.Summary.Cell></Table.Summary.Cell>
									<Table.Summary.Cell></Table.Summary.Cell>
									<Table.Summary.Cell align="right">{formatCurrencyNumber(isFkv ? fmvTotalMktValue : totalMktValue)}</Table.Summary.Cell>
									<Table.Summary.Cell align="right">{formatShares(vestedShares)}</Table.Summary.Cell>
									<Table.Summary.Cell></Table.Summary.Cell>
									<Table.Summary.Cell></Table.Summary.Cell>
									<Table.Summary.Cell align="right">{formatCurrencyNumber(isFkv ? fmvVestedValue : vestedValue)}</Table.Summary.Cell>
									<Table.Summary.Cell align="right">{formatShares(exercised)}</Table.Summary.Cell>
									<Table.Summary.Cell align="right">{formatShares(exercisable)}</Table.Summary.Cell>							
									<Table.Summary.Cell></Table.Summary.Cell>
								</Table.Summary.Row>}}
							/>
						</div>
					}
					{
						(warrant.list && warrant.list.length > 0) && <div className="holdings-section">
							{renderTips('Warrants', warrantsIcon)}
							<Table 
								size="small" 
								columns={warrantColumn} 
								rowKey="certificateId" 
								dataSource={warrant.list || []} 
								pagination={false} 
								scroll={{ x: true }}
								summary={() => {
								const { exercisable, exercised, totalMktValue, vestedShares, vestedValue } = warrant && warrant.total || {}

								return <Table.Summary.Row>
									<Table.Summary.Cell>{__('Total')}</Table.Summary.Cell>
									<Table.Summary.Cell></Table.Summary.Cell>
									<Table.Summary.Cell></Table.Summary.Cell>
									<Table.Summary.Cell align="right">{formatCurrencyNumber(totalMktValue)}</Table.Summary.Cell>
									<Table.Summary.Cell align="right">{formatShares(vestedShares)}</Table.Summary.Cell>
									<Table.Summary.Cell></Table.Summary.Cell>
									<Table.Summary.Cell></Table.Summary.Cell>
									<Table.Summary.Cell align="right">{formatCurrencyNumber(vestedValue)}</Table.Summary.Cell>
									<Table.Summary.Cell align="right">{formatShares(exercised)}</Table.Summary.Cell>
									<Table.Summary.Cell align="right">{formatShares(exercisable)}</Table.Summary.Cell>							
									<Table.Summary.Cell></Table.Summary.Cell>
								</Table.Summary.Row>}}
							/>
						</div>
					}
					{
						(convertibles.list && convertibles.list.length > 0) && <div className="holdings-section">
							{renderTips('Convertibles', convertiblesIcon)}
							<div className="holdings-table-wrap">
								<div className="certificate-title"></div>
								<div className="table-th">{__('Amount')}</div>
								<div className="table-th">{__('Maturity')}</div>
								<div className="table-th">{__('Valuation Cap')}</div>
								<div className="table-th">{__('Discount')}</div>
								<div className="table-th">{__('Int. Rate')}</div>
								<div className="table-th">{__('Int. Income')}</div>
							</div>
							{
								convertiblesList && convertiblesList.map((val, index) => (
									<Collapse key={index}>
										<Panel header={this.renderConvertibleHeader(val)} className="convertible">
											{val.list.map((data, itemIndex) => (<div className="holdings-table-item-wrap" key={itemIndex}>{this.renderConvertibleContent(data)}</div>))}
										</Panel>
									</Collapse>
								))
							}
							<div className="table-total">
								<div className="table-total-td-first">{__('Total')}</div>
								<div className="table-total-td">{formatShares(convertiblesTotal && convertiblesTotal.amount)}</div>
								<div className="table-total-td"></div>
								<div className="table-total-td">{formatShares(convertiblesTotal && convertiblesTotal.valuationCap)}</div>
								<div className="table-total-td"></div>
								<div className="table-total-td"></div>
								<div className="table-total-td"></div>
							</div>			
						</div>
					}

					{
						(tokenWarrant?.list && tokenWarrant?.list?.length > 0) && <div className="holdings-section">
							{renderTips('Warrants')}
							<Table 
								size="small" 
								columns={tokenWarrantColumn} 
								rowKey="certificateId" 
								dataSource={tokenWarrant?.list || []} 
								pagination={false} 
								scroll={{ x: true }}
								summary={() => {
								const { exercisable, exercised, totalMarketValue, vestedShares, vestedValue, issuedQuantity, unvestedMarketValue, unvested, vested } = tokenWarrant && tokenWarrant.total || {}

								return <Table.Summary.Row>
									<Table.Summary.Cell>{__('Total')}</Table.Summary.Cell>
									<Table.Summary.Cell></Table.Summary.Cell>
									<Table.Summary.Cell align="right">{formatShares(issuedQuantity)}</Table.Summary.Cell>
									<Table.Summary.Cell align="right">{formatCurrencyNumber(totalMarketValue)}</Table.Summary.Cell>
									<Table.Summary.Cell align="right">{formatShares(vested)}</Table.Summary.Cell>
									<Table.Summary.Cell align="right">{formatShares(unvested)}</Table.Summary.Cell>
									<Table.Summary.Cell></Table.Summary.Cell>
									<Table.Summary.Cell align="right">{formatCurrencyNumber(vestedValue)}</Table.Summary.Cell>
									<Table.Summary.Cell align="right">{formatShares(exercised)}</Table.Summary.Cell>
									<Table.Summary.Cell align="right">{formatShares(exercisable)}</Table.Summary.Cell>							
									<Table.Summary.Cell align="right">{formatCurrencyNumber(unvestedMarketValue)}</Table.Summary.Cell>
								</Table.Summary.Row>}}
							/>
						</div>
					}
					{
						(tokenVesting?.list && tokenVesting?.list?.length > 0) && <div className="holdings-section">
							{renderTips('Vesting')}
							<Table 
								size="small" 
								columns={tokenVestingColumn} 
								rowKey="certificateId" 
								dataSource={tokenVesting?.list || []} 
								pagination={false} 
								scroll={{ x: true }}
								summary={() => {
								const { claimed, totalMarketValue, vestedValue, remainingQuantity, unvested, unvestedMarketValue, vested } = tokenVesting && tokenVesting.total || {}
								return <Table.Summary.Row>
									<Table.Summary.Cell>{__('Total')}</Table.Summary.Cell>
									<Table.Summary.Cell></Table.Summary.Cell>
									<Table.Summary.Cell align="right">{formatShares(remainingQuantity)}</Table.Summary.Cell>
									<Table.Summary.Cell align="right">{formatCurrencyNumber(totalMarketValue)}</Table.Summary.Cell>
									<Table.Summary.Cell align="right">{formatShares(vested)}</Table.Summary.Cell>
									<Table.Summary.Cell align="right">{formatShares(unvested)}</Table.Summary.Cell>
									<Table.Summary.Cell></Table.Summary.Cell>
									<Table.Summary.Cell align="right">{formatCurrencyNumber(vestedValue)}</Table.Summary.Cell>
									<Table.Summary.Cell align="right">{formatCurrencyNumber(unvestedMarketValue)}</Table.Summary.Cell>
									<Table.Summary.Cell align="right">{formatShares(claimed)}</Table.Summary.Cell>
								</Table.Summary.Row>}}
							/>
						</div>
					}
				</div>
			</div>
		</div>
	}
}

export default Holdings;

