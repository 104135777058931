import React, { Component } from 'react';
import { message, Select, Spin, Button } from 'antd';
import { FormItem, withForm } from 'react-antd-formutil';
import { post } from 'srcPath/http';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import Permission, { noPermission } from 'components/Permission';
import { I } from 'Utils';
import './style.scss';

const Option = Select.Option

// 提交数据的格式
// board_admins: ["59"]
// certificate1_user_id: "59"
// certificate2_user_id: "63"
// convertibles: "59"
// equity_plan_awards: "59"
// primary_contact: "59"
// stakeholder_relations: "59"

@withForm
export default class Roles extends Component {
	state = {
		loading: true,
		bordList: [],
		selectOptions: [],
        accountList: [],
        selectAccountUser: [],
    }

	key = 1
    userKey = 1

	componentDidMount() {
		this.getData()
	}

	getData = async () => {
		this.setState({ loading: true })

		try {
			const { data: resData } = await post('getUserList')

			const { data, code } = resData || {}

			if (code == 0) {
				const { curRoleSettings = {}, company_editor = [], legal_admin = [], admin_list = [] } = data || {}
				const { $setValues } = this.props.$formutil
				const { board_admins = [] } = curRoleSettings || {}
				this.setState({ 
					selectOptions: [].concat(company_editor, legal_admin),
					bordList: board_admins.length > 0 ? board_admins : [1],
                    selectAccountUser: admin_list,
                    accountList: I(curRoleSettings.eSignReceivers, [1]),
				}, () => {
					$setValues({ ...curRoleSettings })
				})
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}

		this.setState({ loading: false })
	}

	onSubmit = async () => {
		const { $formutil, close, data } = this.props
		const {  $invalid, $batchDirty, $params, $getFirstError } = $formutil
		if ($invalid) {
			$batchDirty(true)
			message.error($getFirstError())

			return
		}

		this.setState({ loading: true })

		try {
			const { data: resData } = await post('setRolesAndSignatories' , $params)
			const { code, data } = resData

			if (code == 200 || code ==0) {
				message.success(__('Success!'))

				this.getData()
			} else {
				message.error(data)
			}
		} catch (e) {
			message.error(e.message)
		}

		this.setState({ loading: false })
	}

	 addBoardAdmin = () => {
		const bordList = this.state.bordList

		this.key = this.key + 1

		bordList.push(this.key)

		this.setState({ bordList })
	}

	deleteBoardAdmin = index => {
		const bordList = this.state.bordList
		bordList.splice(index, 1)

		this.setState({ bordList })
	}

    eSignReceivers = () => {
	    const { selectAccountUser, accountList } = this.state;
        const { $params } = this.props.$formutil
        const eSignReceivers = $params.eSignReceivers || []
        return (
            accountList && accountList.map((v, k)=> <div key={v} className="permission-roles-bord-item">
                {k !== 0 && <div className="ant-form-item-label" />}
                <FormItem
                    name={`eSignReceivers[${k}]`}
                    label={k === 0 ? __('E-Sign cc list') : ''}
                    colon={false}
                >
                    <Select className="form-length" className="form-length" placeholder="Select" >
                        {selectAccountUser.map((value, index) => <Option disabled={eSignReceivers.includes(value.id)} key={index} value={value.id}>{value.nick_name} ({value.title})</Option>)}
                    </Select>
                </FormItem>
                <div className="item-btn">
                    {((accountList.length - 1) === k) && accountList.length < 3 && <Button type="primary" onClick={this.updateAccountUser.bind(this, 'add')}><PlusOutlined/></Button>}
                    {(accountList.length !== 1 && k !== 0 )  && <Button onClick={this.updateAccountUser.bind(this, 'delete', k)}><MinusOutlined /></Button>}
                </div>
            </div>)
        );
    }

    updateAccountUser = (type, index) => {
        const { accountList } = this.state;
        this.userKey = this.userKey + 1;
        type === 'add' ? accountList.push(this.userKey) : accountList.splice(index, 1);
        this.setState({ accountList })
    }

	render() {
		const { loading, bordList, selectOptions } = this.state
		const { $params } = this.props.$formutil
		const board_admins = $params.board_admins || []
		const disabled = noPermission('company_permission_roles_edit')

		return <div className="sprout-permission-roles">
			<Spin spinning={loading}>
				<div className="sprout-permission-section">
					<div className="permission-section-banner">
						{__('Roles')}
					</div>
					<div className="permission-section-content">
						<div className="permission-roles-title">
							{__('Company roles')}
						</div>
						<p className="description">
							{__("Roles assign responsibility for specific actions within Folium to designated individuals. Users assigned to roles must have permission to access the company's account. To assign a role to a new user, first create the new user on the Add Administrative User page.")}
						</p>
						<FormItem 
							name="primary_contact"
							label={__('Primary contact')}
							required
							colon={false}
							validMessage={{
								required: __('Primary contact is required'),
							}}
						>
							<Select className="form-length" allowClear placeholder={__('Select')} disabled={disabled}>
								{selectOptions.map((val, index) => <Option key={index} value={val.user_id}>{val.name}, {val.titles}</Option>)}
							</Select>
						</FormItem>
						<div className="tips">
							{__('This is the contact person whom Folium will contact for account related questions and communications.')}
						</div>
						<FormItem 
							name="stakeholder_relations"
							label={__('Stakeholder relations')}
							required
							colon={false}
							validMessage={{
								required: __('Stakeholder relations is required'),
							}}
						>
							<Select className="form-length" allowClear placeholder="Select" disabled={disabled}>
								{selectOptions.map((val, index) => <Option key={index} value={val.user_id}>{val.name}, {val.titles}</Option>)}
							</Select>
						</FormItem>
						<div className="tips">
							{__('Recipient of questions and requests from stakeholders to view ownership information, closing documents or financials.')}
						</div>

						<div className="permission-roles-title">
							{__('Board admins')}
						</div>
						<p className="description">
							{/*Board meetings require an administrator to create and edit meetings, set times and location, add agenda items, and draft resolutions.*/}
							{__('Board meetings require an administrator to create meetings, manage agenda items and draft resolutions.')}
						</p>
						{
							bordList.map((val, index) => <div key={val} className="permission-roles-bord-item">
								{index !== 0 && <div className="ant-form-item-label" />}
								<FormItem 
									name={`board_admins[${index}]`}
									label={index === 0 ? __('Add board admins') : ''}
									required
									colon={false}
									validMessage={{
										required: __('Stakeholder relations is required'),
									}}
								>
									<Select className="form-length" className="form-length" placeholder={__('Select')} disabled={disabled}>
										{selectOptions.map((value, index) => <Option disabled={board_admins.includes(value.user_id)} key={index} value={value.user_id}>{value.name} ({value.titles})</Option>)}
									</Select>
								</FormItem>
								<div className="item-btn">
									{((bordList.length - 1) === index) && <Button disabled={disabled} type="primary" onClick={() => this.addBoardAdmin(val)}><PlusOutlined/></Button>}
									{(bordList.length !== 1 && index !== 0 )  && <Button disabled={disabled} onClick={() => this.deleteBoardAdmin(index)}><MinusOutlined /></Button>}
								</div>
							</div>)
						}
                        {this.eSignReceivers()}
						<div className="btns">
							<Permission type="company_permission_roles_edit"><Button loading={loading} type="primary" onClick={this.onSubmit}>{__('Update roles')}</Button></Permission>
						</div>
					</div>
				</div>
			</Spin>
		</div>
	}
}