import React, {Component, Fragment} from "react";
import { Link } from "react-router-dom";
import {Button, Checkbox, Dropdown, Menu, Table} from "antd";
import TransactionRender from 'B/components/TransactionHistory';
import {DownOutlined} from "@ant-design/icons";
import { formatCurrencyNumber, I, formatNumber } from 'Utils';
import { noPermission } from 'components/Permission';
import {formatDate} from "Utils/format/formatDate";
import openCertificateModal from 'components/CertificateDetail';
import { LOADING,certificateStatus } from "Utils/constant";

import '../style.scss';
class ConvertibleTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRowKeys : []
        }
    }

    componentDidMount(){
        const { onRef } = this.props;
        onRef(this);
        this.initFilterColumn();
    }

    initFilterColumn = () => {
        const tableColumn = this.tableColumns();
        const { saveState } = this.props;
        saveState('tableColumn', tableColumn);
    }

    /**
     * 清空checkbox所有选中内容
     */
    clearAll = () => {
        this.setState(()=>({
            selectedRowKeys : []
        }))
    }

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    /**
     * 字符串排序
     * @param a
     * @param b
     * @returns {number}
     */
    sorterStrOrderBy = (a, b, key) => {
        const stringA = a[key].toUpperCase();
        const stringB = b[key].toUpperCase();
        return stringA < stringB ? -1 : stringA > stringB ? 1 : 0;
    }

    tableColumns = () => {
        return [
            {
                title : __('Security'),
                align : 'left',
                dataIndex : 'custom_label_id',
                key : 'keyId',
                fixed: 'left',
                sorter: true,
                sortName: 'security',
                isChecked: true,
                isDisabled: true,
                width:120,
                render: (text, record) => <Button type="link" onClick={() => openCertificateModal(record.id)}>{text}</Button>
            },
            {
                title : __('Stakeholder'),
                dataIndex : 'name',
                align : 'left',
                sorter: true,
                sortName: 'stakeholder',
                isChecked: true,
                width:160
            },
            {
                title : __('Status'),
                dataIndex : 'status',
                align : 'left',
                width:180,
                sorter: true,
                sortName: 'status',
                render : (text, value) => {
                    return <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'noWrap' }}>
                        {
                            (value.status === "Outstanding" || value.status==="Issued" ) &&
                            <p className="green-disc">&nbsp;&nbsp;&nbsp;&nbsp;{certificateStatus[value.status]}</p>}
                        {value.status != "Outstanding" && value.status != "Issued" &&
                        <p className="red-disc">&nbsp;&nbsp;&nbsp;&nbsp;{certificateStatus[value.status]}</p>}
                        <TransactionRender transferSecurity={value.transferSecurity} />
                    </div>
                },
                isChecked: true,
            },
            {
                title : __('Principal amount'),
                align : 'right',
                dataIndex : 'principal',
                render : (text, value) => {
                    return <div>{formatCurrencyNumber(I(text, 0))}</div>
                },
                isChecked: true,
                width:180,
            },
            {
                title : __('Valuation cap'),
                align : 'right',
                dataIndex : 'valuation_cap',
                render : (text, value) => {
                    return <div>{formatCurrencyNumber(I(text, 0))}</div>
                },
                isChecked: true,
                width:140,
            },
            {
                title : __('Discount'),
                align : 'right',
                dataIndex : 'conversion_discount',
                render : (text, value) => {
                    return <div>{I(text, '') && (`${formatNumber(text)}%`) || '--'}</div>
                },
                isChecked: true,
                width:120,
            },
            {
                title : __('Interest rate'),
                align : 'right',
                dataIndex : 'interest_rate',
                render : (text, value) => {
                    return <div>{I(text, '') && (`${formatNumber(text)}%`) || '--'}</div>
                },
                isChecked: true,
                width:120
            },
            {
                title : __('Interest start'),
                align : 'right',
                dataIndex : 'interest_start_date',
                render: (text, value) => {
                    return <span>{ formatDate(value.interest_start_date) }</span>
                },
                isChecked: true,
                width:120
            },
            {
                title : __('Redeemed amount'),
                align : 'right',
                dataIndex : 'payout_amount',
                render : (text, value) => {
                    return <div>{formatCurrencyNumber(I(text, 0), 0)}</div>
                },
                isChecked: true,
                width:140
            },
            {
                title : '',
                align : 'left',
                dataIndex : 'id',
                width : 50,
                fixed: 'right',
                render:(text, value, index) => this.dropdownInfo(text, value, index),
                isHide: true,
            },
        ]
    }

    setCurSelectSecurity = ( index) => {
        const { setCurSelectSecurity } = this.props;
        setCurSelectSecurity( index);
    }

    /**
     * 表格最后一列菜单配置
     * @param data
     * @param index
     * @returns {JSX.Element}
     */
    dropdownInfo = (text, data, index) => {
        const { visibleStatus } = data;
        return (
            <Dropdown
                visible={ visibleStatus }
                onVisibleChange={this.handleVisibleChange}
                onClick={(e)=>{
                    e.stopPropagation();
                    this.setCurSelectSecurityNew(text, data, index)
                }}
                overlay={this.menu( data, index)}
                placement="bottomRight"
                trigger="click">
                <div className="tableMenu"><DownOutlined style={{ color: '#FFF' }}/></div>
            </Dropdown>
        );
    }
    setCurSelectSecurityNew = (text, data, index) => {
        const { setCurSelectSecurityNew } = this.props;
        setCurSelectSecurityNew(text);
        this.updateSecurityListsVisible(data,true)
    }

    setSelectId = (index) => {
        const { setSelectId } = this.props;
        setSelectId(index);
    }
    checkSecurityStatus = () => {
        const { checkSecurityStatus } = this.props;
        checkSecurityStatus();
    }
    getTransferPrincipal = (data, index, e) => {
        this.stopDetailModel(data, index, e);
        const { getTransferPrincipal } = this.props;
        getTransferPrincipal();
    }
    cnReverseCancellation = (data, index, e) => {
        this.stopDetailModel(data, index, e);
        const { cnReverseCancellation } = this.props;
        cnReverseCancellation();
    }
    setInitShareNote = (data, index, e) => {
        this.stopDetailModel(data, index, e);
        const { setInitShareNote } = this.props;
        setInitShareNote();
    }
    resendEmail = (data, index, e) => {
        this.stopDetailModel(data, index, e);
        const { resendEmail } = this.props;
        resendEmail();
    }

    redeemToCashModal = (data, index, e) => {
        this.stopDetailModel(data, index, e);
        const { redeemToCashModal } = this.props;
        redeemToCashModal(data, index);
    }

    /**
     * 更新列表中的菜单显示状态
     * @param index 列表下标
     * @param flag 菜单显示状态
     */
    updateSecurityListsVisible = (data, flag) => {
        const { securityLists, saveState } = this.props;
        let list = [...securityLists];
        //从原有默认index下标改为使用id来获取数组下标
        const index = list.findIndex((v, k, arr)=>{
            return v.id == data.id
        })
        if(flag === false) {
            list.forEach((v, k) => {
                list[k]['visibleStatus'] = false;
            });
        }
        if(data !== false){
            list[index]['visibleStatus'] = true;
        }
        saveState('securityLists', list);
    }

    handleVisibleChange = flag => {
        this.updateSecurityListsVisible(false, flag);
    }
    stopDetailModel=(data, index, e)=>{
        e.stopPropagation();
        this.updateSecurityListsVisible(false, false);
    }

    /**
     * Waterfall models 菜单
     * @param value
     * @param index
     * @returns {JSX.Element}
     */
    menu = (value, index) => {
        const { curSelectSecurity } = this.props;
        const isTransferred = value.status == "Transferred" ? 1 : 0;
        const reverseCancellation = value.status=="Canceled"||value.status=="Repurchased" ? 1 : 0;
        const addOrEdit = ( curSelectSecurity.notes == ""||null ) ? __("Add certificate notes"): __("Edit certificate notes")
        return (
            <Menu>
                { (noPermission('securities_convertibles_addAdminNotes') === false ) && <Menu.Item><a data-toggle="modal" onClick={this.stopDetailModel.bind(this,value,index)} data-target="#modifyConvertibleModal">{__('Modify convertible note')}</a></Menu.Item>}
                { (noPermission('securities_convertibles_SAFE_transfer') === false && isTransferred == 0 && value.status==='Outstanding') && <Menu.Item ><a data-toggle="modal" onClick={this.getTransferPrincipal.bind(this,value,index)} data-target="#TransferSafeModal">{__('Transfer')}</a></Menu.Item>}
                { (noPermission('securities_convertibles_SAFE_cancel') === false && isTransferred == 0 && value.status==='Outstanding') && <Menu.Item ><a data-toggle="modal"  onClick={this.stopDetailModel.bind(this,value,index)} data-target="#cancelModal">{__('Cancel')}</a></Menu.Item>}
                { (noPermission('securities_convertibles_reverseCancellation') === false && isTransferred == 0 && reverseCancellation == 1 ) && <Menu.Item ><a onClick={this.cnReverseCancellation.bind(this,value,index)} className="reverse-btn text-muted">{__('Reverse cancellation')}</a></Menu.Item>}
                { (noPermission('securities_convertibles_reverseCancellation') === false && isTransferred == 0 && reverseCancellation == 0) && <Menu.Item ><a onClick={this.stopDetailModel.bind(this,value,index)} className="reverse-btn text-muted no-cursor">{__('Reverse cancellation')}<p className="reverse-tip">{__('Can only apply to cancelled')}<br/><span> {__('or')} </span>{__(' repurchased certificate(s).')}</p></a></Menu.Item>}
                { (noPermission('securities_convertibles_addAdminNotes') === false ) && <Menu.Item ><a onClick={this.setInitShareNote.bind(this,value,index)} data-toggle="modal"  data-target="#addAdminNotesModal">{ addOrEdit }</a></Menu.Item>}
                { value.sign_status == "1" && isTransferred == 0 && ( (noPermission('securities_convertibles_resend') === false ) && <Menu.Item ><a onClick={this.resendEmail.bind(this,value,index)} >{__('Resend to officers')}</a></Menu.Item> )}
                { value.sign_status == "2" && isTransferred == 0 && ( (noPermission('securities_convertibles_resend') === false ) && <Menu.Item ><a onClick={this.resendEmail.bind(this,value,index)} >{__('Resend to stakeholder')}</a></Menu.Item> )}
                { (noPermission('securities_convertibles') === false && isTransferred == 0 && value.status==='Outstanding') && <Menu.Item ><Link to={{ pathname : `/home/securities/shares/draft-shares/`+ value.id} }  onClick={this.stopDetailModel.bind(this,value,index)} >{__('Convert to shares')}</Link></Menu.Item>}
                { (noPermission('securities_convertibles_manageItems') === false && value.status==='Outstanding') && <Menu.Item ><a data-toggle="modal"  onClick={this.redeemToCashModal.bind(this,value,index)}>{__('Redeem to cash')}</a></Menu.Item>}

            </Menu>
        )
    }

    /**
     * 表格checkbox自定义配置
     * @param checked
     * @param record
     * @param index
     * @param selectedRowKeys
     **/
    securityHover = (checked, record, index, selectedRowKeys) => {

        const { sign_status } = record;
        const message = {
            1 : __('Awaiting officer signature'),
            2 : __('Awaiting holder signature')
        }
        return (
            <Fragment>
                {
                    (sign_status == 1 || sign_status == 2) &&
                    <div className="signTipBox">
                        <div className={sign_status == 1 ? 'officer-tip-triangle' : 'holder-tip-triangle'}></div>
                        <p className={['sign-tiptext', sign_status == 1 ? 'bg-lightorange' : 'bg-lightgreen'].join(' ')} style={{display:'block'}}>{message[sign_status]}</p>
                    </div>
                }
                <Checkbox onClick={(e)=>{
                    e.stopPropagation();
                }} checked={checked} onChange={this.checkChange.bind(this, record, index, checked)} ></Checkbox>
            </Fragment>
        );
    }

    /**
     * 清空checkbox所有选中内容
     */
    clearAll = () => {
        this.setState(()=>({
            selectedRowKeys : []
        }))
    }

    /**
     * 表格checkbox change事件
     * @param record
     * @param index
     * @param boxChecked
     * @param e
     */
    checkChange = (record, index, boxChecked, e) => {
        const { checked } = e.target;
        const { selectedRowKeys } = this.state;
        const { updateSelectCount } = this.props;
        const { id } = record;
        let key_list = [...selectedRowKeys];
        if(checked){
            key_list.push(id);
        }else{
            key_list.forEach( (v, k) => {
                if(v == id){
                    key_list.splice(k, 1);
                }
            })
        }
        updateSelectCount(key_list.length, [{selectIndex: index,checked:checked}]);
        this.setState(()=>({
            selectedRowKeys : key_list
        }))
        this.props.setSelectId(key_list)
        this.props.checkSecurityStatus()
    }

    /**
     * table 行 鼠标移入移出
     * @param row 对象
     * @param index 下标
     * @returns {{onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave}}
     */
    onRow = (row, index) => {
        const {  } = this.props;
        return {
            //鼠标移入
            onMouseEnter : () => {

            },
            //鼠标移出
            onMouseLeave : () => {

            }
        }
    }

    /**
     * 全选change事件
     * @param selectedRowKeys
     */
    onSelectChange = selectedRowKeys => {
        const { securityLists, updateSelectCount } = this.props;
        const count = selectedRowKeys.length;
        if(!count){
            updateSelectCount(count, [], true);
        }else{
            let selectIndexArray = [];
            securityLists.forEach((v, k) => {
                selectIndexArray.push({selectIndex:k, checked:true});
            })
            updateSelectCount(count, selectIndexArray);
        }
        this.setState({ selectedRowKeys }, ()=> this.props.checkSecurityStatus());
    };

    rowSelection = (selectedRowKeys) => ({
        selectedRowKeys,
        onChange: this.onSelectChange,
        renderCell : (checked, record, index, originNode) => this.securityHover(checked, record, index, selectedRowKeys)
    })

    /**
     *
     * @param pagination
     * @param filters
     * @param sorter
     * @param extra
     */
     handleTableChange = (pagination, filters, sorter, extra) => {
        let sort = {}
        const { order, column } = sorter

        // TODO: cancel sort
        if (column?.sortName) {
            sort = {
                column: column.sortName,
                type: order
            }

            this.props.sortTable?.(sort)
        }else{
            // Ning 新加cancel sort
            sort = {}
            this.props.sortTable?.(sort)
        }
    }

    render() {

        const { selectedRowKeys } = this.state;
        const { securityLists, tableColumn, paginationConfig, loading } = this.props;
        return (
            <Table
                onRow={this.onRow}
                
                pagination={ paginationConfig ? {...paginationConfig, position: ['bottomCenter']} : false}
                className="warrant-table-new bigger_checkbox"
                rowClassName="stake-table-row"
                locale={ loading && { emptyText: LOADING }}
                columns={tableColumn}
                dataSource={securityLists}
                rowKey="id"
                scroll={{ x: 1200, y: innerHeight ? innerHeight - 280 : false }}
                rowSelection={this.rowSelection(selectedRowKeys)}
                onChange={this.handleTableChange}
            />
        );
    }
}
export default ConvertibleTable;