import React, { Component, useState, useEffect } from 'react';
import { Button, message, Checkbox, Space, Radio, Select, Table } from 'antd';
import Modal, { ModalContent } from 'components/Modal';
import Loading from 'components/Loading';
import DateSelector from 'components/DateSelector';
import { FormItem, withForm } from 'react-antd-formutil';
import moment from 'moment';
import { post } from 'srcPath/http';
import security from 'stores/security';
import icon from '../../images/icon.png';
import { formatDate } from "Utils/format/formatDate";
import { I, formatCurrencyNumber, formatPercent } from 'Utils';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import openFileListModal from 'components/Layout/Header/components/FileListModal';
const Option = Select.Option

function Tax({ $formutil }) {
	const { $invalid, $batchDirty, $params, $getFirstError } = $formutil
	const [start_date] = useState(moment().subtract(1, 'year'))
	const [end_date] = useState(moment())
	const [isShowTable, setShowTable] = useState(false)
	const [activeKey, setactiveKey] = useState('0')
	const [dataSource, setDataSource] = useState([])
	const [provinceList, setProvinceList] = useState([])
	const [cityAllList, setCityAllList] = useState([])
	const [provinceCode, setProvinceCode] = useState('')
	const [cityCode, setCityCode] = useState('')
	const [cityCoded, setCityCoded] = useState()
	const [cityList, setCityList] = useState([])
	const [options, setOptionList] = useState([])
	useEffect(() => {
		getCityList()
	}, [])


	const getCityList = async () => {

		try {
			const { data: resData } = await post('getRegionList', {})

			const { data, code } = resData || {}

			const { province, city } = data
			if (code === 0) {
				setOptionList(province);
				setCityCode('110000');
			}

		} catch (e) {
			message.error(e.message)
		}
	}
	const yearOptions = [
		{
			value: '2020'
		},
		{
			value: '2021'
		},
		{
			value: '2022'
		},
	]
	const cnColumns = [
		// render: (val, k) => val || k.count + 'stakeholder(s)',
		{
			title: __('Name'),
			dataIndex: 'name',
			width: 150,
		},
		{
			title: __('Tax city name'),
			dataIndex: 'cityName'
		},
		{
			title: __('Cost Center'),
			dataIndex: 'cost_center',
			width: 150,
		},
		{
			title: __('Label Id'),
			dataIndex: 'label_id',
			width: 150,
		},
		{
			title: __('Get'),
			dataIndex: 'get',
			render: val => formatCurrencyNumber(val), 
			width: 150,
		},
		{
			title: __('Exercise Price'),
			dataIndex: 'exercise_price',
			render: val => formatCurrencyNumber(val), 
			width: 150,
		},
		{
			title: __('Exercised Quantity'),
			dataIndex: 'exercised_quantity',
			render: val => formatFractionalNumber(val), 
			width: 150,
		},
		{
			title: __('Fmv Price'),
			dataIndex: 'fmv_price',
			render: val => formatCurrencyNumber(val), 
			width: 150,
		},
		{
			title: __('Total'),
			dataIndex: 'total',
			render: val => formatCurrencyNumber(val), 
			width: 150,
		},
		{
			title: __('House'),
			dataIndex: 'house',
			render: val => formatCurrencyNumber(val), 
			width: 150,
		},
		{
			title: __('Lose'),
			dataIndex: 'lose',
			render: val => formatCurrencyNumber(val), 
			width: 150,
		},
		{
			title: __('Medical'),
			dataIndex: 'medical',
			render: val => formatCurrencyNumber(val), 
			width: 150,
		},
		{
			title: __('Pension'),
			dataIndex: 'pension',
			render: val => formatCurrencyNumber(val), 
			width: 150,
		},
		{
			title: __('Tax'),
			dataIndex: 'tax',
			render: val => formatCurrencyNumber(val), 
			width: 150,
		}
	]
	const getActiveKey = (e) => {
		setactiveKey(e)
	}
	const onSubmit = async (is_export) => {
		// return
		if ($invalid) {
			$batchDirty(true)
			// message.error($getFirstError())
			return
		}
		let start_date = ''
		let end_date = ''

		if ($params.year_val == 1) {
			if ($params.year_date == 2020) {
				start_date = '01/01/2020'
				end_date = '01/01/2021'
			} else if ($params.year_date == 2021) {
				start_date = '01/01/2021'
				end_date = '01/01/2022'
			} else {
				start_date = '01/01/2022'
				end_date = '01/01/2023'
			}
		} else {
			start_date = $params.start_date && $params.start_date.format('DD/MM/YYYY') || moment().format('DD/MM/YYYY')
			end_date = $params.end_date && $params.end_date.format('DD/MM/YYYY') || moment().format('DD/MM/YYYY')
		}
		let idDefaultCity = $params.idDefaultCity
		let info = { start_date, end_date, idDefaultCity, cityCode, is_export }
		Loading.global(true);
		try {

			const { data: resData } = await post('exportTaxReport', info)

			const { data, code } = resData || {}



			if (code === 0) {
				setShowTable(true)
				if (is_export == 0) {
					message.success(__('Success'))
					let dataArr = Object.values(data)
					dataArr.forEach(item => {
						for (let i in item.exercise_info[0]) {
							item[i] = item.exercise_info[0][i]
						}
						for (let i in item.taxInfo) {
							item[i] = item.taxInfo[i]
						}
					})
					let DataSource = dataArr
					setDataSource(DataSource)
				} else {
					openFileListModal()
				}
			}

		} catch (e) {
			message.error(e.message)
		}

		Loading.global(false)
	}


	const getProvinceVal = (e) => {
		setProvinceCode(e)
		let cityList = cityAllList.filter(item => item.parent_id == e)
		setCityList(cityList)
		setCityCoded('')
	}
	const getCityVal = (e) => {
		setCityCode(e)
	}
	const getCityVald = (e) => {
		setCityCoded(e)
	}


	return <div className="sprout-reports-item-container sbc-container">
		<div className="sprout-reports-item-left">
			<div className="sprout-reports-item-row">
				<div className="sprout-reports-icon">
					<img src={icon} alt="icon" />
				</div>
				<div className="sprout-reports-item-content">
					<div className="title">{__('Tax report')}</div>
					<div className="desc">{__('Reporting period')}</div>
					<div className="item-title">{__('Select')}:</div>
					<div className="item-section">
						<FormItem
							validMessage={{ required: 'Select year' }}
							required
							name="year_val"
							$defaultValue={'1'}
						>
							<Radio.Group>
								<Space direction="vertical">
									{/* <Radio value="1">{__('Last tax year')}</Radio> */}
									<div className="custom_val">
										<Radio value="1">{__('Year')}</Radio>
										<FormItem
											className=""
											name="year_date"
											$defaultValue={'2022'}
										>
											<Select
												placeholder={__('Select year')}
												className="city_select"
												options={yearOptions}
											/>
										</FormItem>
									</div>
									<div className="custom_val">
										<Radio value="2">
											{__('Custom time range:')}
										</Radio>
										<div className="custom_date">{__('From')}
											<FormItem
												className="maR10"
												name="start_date"
											>
												<DateSelector bordered={true} format={security.companyInfo && security.companyInfo.date_format} defaultValue={start_date} />
											</FormItem>
											{__('to')}
											<FormItem
												className="maR10"
												name="end_date"
											>
												<DateSelector bordered={true} format={security.companyInfo && security.companyInfo.date_format} defaultValue={end_date} />
											</FormItem>
										</div>
									</div>
								</Space>
							</Radio.Group>
						</FormItem>
					</div>
					<div className="item-title">{__('Choose tax city')}:</div>
					<div className="item-section">
						<FormItem
							validMessage={{ required: 'select city' }}
							required
							name="idDefaultCity"
							$defaultValue={'1'}
						>
							<Radio.Group>
								<Space direction="vertical">
									<Radio value="1">{__('Use default tax cities of stakeholders')}</Radio>
									<div className="custom_val">
										<Radio value="2">{__('Override all with specific tax city rules')}:</Radio>
										<Select
											onChange={getCityVal}
											value={cityCode}
											placeholder={__('Select country')}
											className="city_select"
										>
											{
												options.map((val, inxex) => <Option value={val.tax_code} key={inxex} >{val.region_name}</Option>)
											}
										</Select>
										{/* <Select
											onChange={getProvinceVal}
											value={provinceCode}
											placeholder={__('Select country')}
											className="city_select maLft10"
										>
											{
												provinceList.map((val, inxex) => <Option value={val.region_id} key={inxex} >{val.region_name}</Option>)
											}
										</Select>

										<Select
											onChange={getCityVald}
											value={cityCoded}
											placeholder={__('Select country')}
											className="city_select maLft10"
										>
											{
												cityList.map((val, inxex) => <Option value={val.region_id} key={inxex} >{val.region_name}</Option>)
											}
										</Select> */}
									</div>
								</Space>
							</Radio.Group>
						</FormItem>
					</div>
				</div>
			</div>
		</div>
		<div className="btns">
			<Space>
				<Button className="sbc_export_btn" type="primary" onClick={() => onSubmit(0)}>{__('Preview')}</Button>
			</Space>
		</div>

		{isShowTable && <div className="export-table">
			<div className="export-table-export-btn" >
				<div className="export-table-btn">
					<Button type={activeKey === '0' && 'primary'} onClick={() => getActiveKey('0')} >{__('Table')}</Button>
				</div>
				<div className="btns">
					<Space>
						<Button className="export_sbc_btn shares-actions" type="primary" onClick={() => onSubmit(1)}>{__('Export')}</Button>
					</Space>
				</div>
			</div>
			<div className="export-table-list">
				<Table
					className="sbc-table"
					columns={cnColumns}
					dataSource={dataSource}
					pagination={false}
					scroll={{ x: true, y: 500 }}
				/>
			</div>

		</div>
		}
	</div >
}

export default withForm(Tax)
