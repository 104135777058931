import React, { Component } from 'react';
import { Select } from 'antd';
import { observer } from 'mobx-react';
import security from 'stores/security';

@observer
export default class CountrySelector extends Component {
	render() {
		const { country_list = [] } = security.constant || {}
		return <Select showSearch allowClear placeholder={__('Select country')} {...this.props}>
			{
				country_list.map((val, index) => <Select.Option key={val.abbreviation} value={val.name}>{val.name}</Select.Option>)
			}
		</Select>
	}
}