import React from "react";
import Utils, { I, formatNumber, formatCurrencyNumber, filterAmount } from "Utils";
import { post } from 'srcPath/http';
import security from 'stores/security';
import moment from "moment";
import {DatePicker} from "antd";
import DateSelector from "../../../../../components/DateSelector";
import { formatDate } from 'Utils/format/formatDate';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import { printf } from 'Utils/i18n';
import { ExclamationCircleOutlined } from '@ant-design/icons';

class RepurchaseShares extends React.Component {
    constructor(props){
        super(props);
        this.state={
            dateFormat:"DD/MM/YYYY",
            uploadProgress:0,
            uploadingStatus:0,
            errorTip:0,
            emptyError:0,
            fileError:0,
            data:{
                id:"",
                reason:"",
                repurchase_date:"",
                quantity:"",
                is_return_to_pool:true,
                is_represent_a_sale:1,
                transaction_value:"",
                repurchase_agreement:{name:"No file uploaded",file_obj:""}
            },
            repurchased_reasonList:[
                "Buyback",
                "Early exercise termination",
                "Relationship change",
                "Tender off",
            ],
            requiredKey:[
                "reason",
                "repurchase_date",
                "quantity",
                "transaction_value",
                "repurchase_agreement"
                ]
        }
    }

    initIsAbleToReturn = () =>{
        let curData = this.state.data
        if(this.props.curSelectSecurity.is_able_return_pool==0){
            curData.is_return_to_pool = false
            this.setState({
                data : curData
            })
        }
    }

    recordData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        })
    }
    checkedStatus=()=>{
        let curData = this.state.data;
        curData.is_return_to_pool = !curData.is_return_to_pool;
        this.setState({
            data:curData
        })
    }
    setFileName=(key,e)=>{
        let fileObj = e.target.files[0];
        let curData = this.state.data;
        Utils.uploadFile(fileObj).then((result)=>{
            curData[key].name = fileObj.name;
            curData[key].file_obj = result;
            this.setState({
                data:curData
            });
        });
        $("."+key).removeClass("hidden");
    }
    resetActive=()=>{
        window.$("#repurchaseModal .tab-pane").removeClass("active");
        window.$("#repurchase-step1").addClass("active");
        this.setState({
            data:{
                id:"",
                reason:"",
                repurchase_date:"",
                quantity:"",
                is_return_to_pool:true,
                is_represent_a_sale:1,
                transaction_value:"",
                repurchase_agreement:{name:"No file uploaded",file_obj:""}
            },
        })
    }
    handleRepurchaseDateChange = (key, moment_key, date) => {
        const { dateFormat } = this.state;
        let curData = this.state.data;
        curData[key] = date.format( dateFormat);
        curData[moment_key] = date;
        this.setState({
            data:curData
        });
    }
    /**
     * input change通用事件
     * @param isDecimal 是否需要小数
     * @param e
     * 5 digit
     */
    inputChange5Digit = (isDecimal,e) => {
        const { target: element } = e;
        const { name: dataKey } = element;
        const filter = filterAmount(e, 9999999999999, isDecimal, 5);
        let curData = this.state.data;
        curData[dataKey] = filter.value;
        this.setState({
            data: curData
        }, () => {
            element.selectionStart = element.selectionEnd = filter.cursorPositionX;
        });
    }

    /**
     * input change通用事件
     * @param isDecimal 是否需要小数
     * @param e
     * 2 digit
     */
    inputChange2Digit = (isDecimal,e) => {
        const { target: element } = e;
        const { name: dataKey } = element;
        const filter = filterAmount(e, 9999999999999, isDecimal);
        let curData = this.state.data;
        curData[dataKey] = filter.value;
        this.setState({
            data: curData
        }, () => {
            element.selectionStart = element.selectionEnd = filter.cursorPositionX;
        });
    }
    checkErrorStatus=()=>{
        let curData = this.state.data;
        this.state.requiredKey.map((value,key)=>{
            if(value=="repurchase_agreement"){
                if(curData[value].file_obj==""){
                    this.setState({
                        fileError:1
                    })
                }else{
                    this.setState({
                        fileError:0
                    })
                }
            }else if(curData[value].length ===0 ){
                $("#repurchase_"+value).addClass("has-error");
                this.setState({
                    emptyError:1
                })
            }else{
                $("#repurchase_"+value).removeClass("has-error");
            }
        });
    }
    removeErrorTip=()=>{
        this.state.requiredKey.map((value,key)=>{
            $("#repurchase_"+value).removeClass("has-error");
        });
        this.setState({
            emptyError:0,
            errorTip:0,
            fileError:0,
        })
    }
    checkQuantity=()=>{
        let curQuantity = Utils.getNumFromFormatNum(this.state.data.quantity);
        let propsQuantity = Utils.getNumFromFormatNum(this.props.curSelectSecurity.quantity);
        if(curQuantity > propsQuantity){
            this.setState({
                errorTip:1
            })
        }else{
            this.setState({
                errorTip:0
            })
        }
    }
    submitRepurchasedShares=()=>{
        if(Utils.isLocal()){
            let curSelectSecurity = this.props.curSelectSecurity;
            curSelectSecurity.quantity = parseInt(curSelectSecurity.quantity) - parseInt(this.state.data.quantity.replace(/,/g,""))
            this.props.setModifiedSecurity(this.props.curSelectSecurity.key,curSelectSecurity);
            window.$("#repurchaseModal").modal('hide');
            return;
        }
        if(this.state.uploadingStatus ===1){
            return;
        }
        this.setState({
            uploadingStatus:1
        });
        let requestData = this.state.data;
        requestData.combined_id = this.props.curSelectSecurity.combined_id;
        requestData.id = this.props.curSelectSecurity.id;
        post('repurchaseShares',requestData).then((response)=> {
            if(response.data.code === 0){
                window.$("#repurchaseModal").modal("hide");
                // window.location.reload();
                this.props.getSecurityList()
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }


    render(){
        // let isShowNextButton = 0;
        // if(this.state.data.repurchase_date.length>0 && this.state.data.quantity.length>0 && this.state.data.reason!= "" && this.state.data.repurchase_agreement.name!=="No file uploaded"){
        //     if(Utils.getNumFromFormatNum(this.state.data.quantity) <= Utils.getNumFromFormatNum(this.props.curSelectSecurity.quantity) ){
        //         isShowNextButton = 1;
        //     }
        // }
        let curDataStatus = 1;
        let curData = this.state.data;
        let requiredKey = this.state.requiredKey;
        for(let i=0; i<requiredKey.length; i++){
            if(requiredKey[i]=="repurchase_agreement"){
                if(curData.repurchase_agreement.file_obj==""){
                    curDataStatus = 0
                }
            }else{
                if(curData[requiredKey[i]] == ""){
                    curDataStatus = 0;
                }
            }
        }
        const currency  = security.companyInfo.currency || '--'
        const { dateFormat, data } = this.state;
        let isFractionalShare = security.companyInfo.fractional_control;
        return(
            <div className="modal fade" id="repurchaseModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="modal-dialog tab-content">
                    <div className="modal-content content580 tab-pane active" id="repurchase-step1">
                        <div className="modal-header box-header">
                            <button type="button" className="close shut-btn" onClick={this.resetActive} data-dismiss="modal" aria-hidden="true">×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Repurchase')} {this.props.curSelectSecurity.custom_label_id}</h4>
                        </div>
                        <div className="modal-body padding2026">
                            <div className="body-one">
                                {this.state.emptyError == 1 &&
                                <div  className="alert alert-warning alert-dismissible pink-box">
                                    <p>{__('Required field(s) cannot be empty.')}</p>
                                </div>
                                }
                                {this.state.errorTip == 1 &&
                                <div  className="alert alert-warning alert-dismissible pink-box">
                                    <p>{__("The number of shares you're trying to repurchase is greater than the number of shares available.")}</p>
                                </div>
                                }
                                <form className="form-horizontal">
                                    <div className="form-group add-items">
                                        <label className="col-xs-5 add-label">{__('Reason')}<br/>
                                            <i><small className="text-muted">- {__('Required')}</small></i>
                                        </label>
                                        <div className="col-xs-5" id={"repurchase_reason"}>
                                            <select value={this.state.data.reason}  onChange={this.recordData}  name="reason" className="form-control select-container repurchase_select">
                                                <option value="">{__('Select an option')}</option>
                                                {this.state.repurchased_reasonList.map((value,key)=>{
                                                    return(
                                                        <option key={key}>{value}</option>
                                                    )
                                                })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group add-items ">
                                        <label className="col-xs-5 add-label">{__('Repurchase date')}<br/><i><small className="text-muted">- {__('Required')}</small></i></label>
                                        <div className="col-xs-5 " id={"repurchase_repurchase_date"}>
                                            <DateSelector
                                                onChange={this.handleRepurchaseDateChange.bind(this, 'repurchase_date', 'repurchase_date_moment')}
                                                value={I(data['repurchase_date_moment'], I(data['repurchase_date'], '') ? moment(data['repurchase_date'], dateFormat) : '')}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group add-items">
                                        <label className="col-xs-5 add-label">{__('Number of shares')}<br/><i><small className="text-muted">- {__('Required')}</small></i></label>
                                        <div className="col-xs-5" id={"repurchase_quantity"}>
                                            {isFractionalShare == 1 ?
                                                <input value={this.state.data.quantity} onBlur={this.checkQuantity} name="quantity" onChange={this.inputChange5Digit.bind(this,true)}  className="form-control" type="text" /> :
                                                <input value={this.state.data.quantity} onBlur={this.checkQuantity} name="quantity" onChange={this.inputChange5Digit.bind(this,false)}  className="form-control" type="text" />
                                            }
                                        </div>
                                        <p className="text-muted col-xs-7 ">{printf(__('%s shares are available for repurchase.'), formatFractionalNumber(this.props.curSelectSecurity.quantity))}</p>
                                    </div>
                                    <div className="form-group add-items">
                                        <label className="col-xs-5 add-label">{__('Return shares to pool')}</label>
                                        <div className="col-xs-7">
                                            <div className="selected_remind_input">
                                                { this.props.curSelectSecurity?.is_able_return_pool == 0 ?
                                                    <input checked={this.state.data.is_return_to_pool}  name="is_return_to_pool" disabled={true} className="switch-checkbox switch_disable" id="example1" type="checkbox" /> :
                                                    <input checked={this.state.data.is_return_to_pool}  name="is_return_to_pool" onClick={this.checkedStatus} className="switch-checkbox" id="example1" type="checkbox" />
                                                }
                                                <label htmlFor="example1">Check</label>
                                            </div>
                                            <p className="text-muted">{__('All the repurchased shares will return to the Equity Incentive Plan.')}</p>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-xs-5 add-label">
                                            {__('Does this repurchase represent a sale?')}
                                        </label>
                                        <div className="col-xs-2">
                                            <div className="checkbox text-muted equity-checkbox">
                                                <input type="radio"  onChange={this.recordData} className="send-box equity-checkbox" id="repurchase_yes" name="is_represent_a_sale" value="1" checked={this.state.data.is_represent_a_sale=="1"} />
                                                <label className="send-div" htmlFor="repurchase_yes"></label><span className="type-label"> &nbsp;&nbsp;{__('Yes')}</span>
                                            </div>
                                        </div>
                                        <div className="col-xs-2">
                                            <div className="checkbox text-muted equity-checkbox">
                                                <input type="radio"  onChange={this.recordData} className="send-box equity-checkbox" id="repurchase_no" name="is_represent_a_sale" value="0" checked={this.state.data.is_represent_a_sale=="0"}/>
                                                <label className="send-div" htmlFor="repurchase_no"></label><span className="type-label"> &nbsp;&nbsp;{__('No')}</span>
                                            </div>
                                        </div>
                                        <p className="text-muted col-xs-7 marginTop6">{__('This information will be used to track changes to disposition.')}</p>
                                    </div>
                                    <div className="form-group add-items">
                                        <label className="col-xs-5 add-label">{__('Transaction value')}<br/><i><small className="text-muted">- {__('Required')}</small></i>
                                            <a className="convertible-ques">
                                                <p className="convertible-tiptext tiptext-top86">{printf(__('The %s value exchanged for this security after taxes.'),currency )}</p>
                                            </a>
                                        </label>
                                        <div className="col-xs-5 " id={"repurchase_transaction_value"}>
                                            <div className="input-group"><span className="input-group-addon">{currency}</span>
                                                <input value={this.state.data.transaction_value} name="transaction_value" onChange={this.inputChange2Digit.bind(this,true)} className="form-control" placeholder="100" />
                                            </div>
                                        </div>
                                        <p className="text-muted col-xs-7 ">{printf(__('Enter the total %s value for this repurchase including taxes. Folium defaults to the price per share * amount of shares. Update the total cost as needed.'),currency )}</p>
                                    </div>
                                    <div className="form-group ">
                                        <label className="col-xs-5 add-label">{__('Repurchase agreement')}<br/><i><small className="text-muted">- {__('Required')}</small></i></label>
                                        <div className="col-xs-3 no-paddingright" id={"repurchase_repurchase_agreement"}>
                                            <div className="select-btn select-blue convertiblenote-select-btn">
                                                {__('Choose a file')}
                                                <label htmlFor="repurchase-select" className="sr-only" >File input</label>
                                                <input onChange={this.setFileName.bind(this,"repurchase_agreement")} ref="repurchase_agreement" type="file" id="repurchase-select" />
                                            </div>
                                        </div>
                                        <div className="col-xs-4">
                                            <p className="text-muted file_name_posi">{this.state.data.repurchase_agreement.name}</p>
                                        </div>
                                        {this.state.fileError == 1 &&
                                        <div className="col-xs-7">
                                            <p className="alarm_text no-paddingleft"><ExclamationCircleOutlined className='icon-padding' />{__('This file is required.')}</p>
                                        </div>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer box-footer invite-footer">
                            { curDataStatus == 1 && <button type="button" onClick={this.removeErrorTip} data-toggle="tab" data-target="#repurchase-step2" className="btn boxbtn-to bg-darkblue">{__('Next: review repurchase details')}</button>}
                            { curDataStatus == 0 && <button type="button" onClick={this.checkErrorStatus} className="btn boxbtn-to bg-darkblue">{__('Next: review repurchase details')}</button>}
                        </div>
                    </div>
                    <div className="modal-content content480 tab-pane " id="repurchase-step2">
                        <div className="modal-header box-header">
                            <a data-target="#repurchase-step1" className="back-btn" aria-controls="repurchase-step1" role="tab" data-toggle="tab"></a>
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.resetActive}>×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Repurchase')}</h4>
                        </div>
                        <div className="modal-body padding2026">
                            <div className="body-one">
                                <p className="box-text">{__('Review repurchase details:')}</p>
                                <table className="table merge-table review-relationship-table">
                                    <tbody>
                                            <tr>
                                                <td className="width40perc">{__('Certificate')}</td>
                                                <td>{this.props.curSelectSecurity.custom_label_id}({this.props.curSelectSecurity.security_type})</td>
                                            </tr>
                                            <tr>
                                                <td>{__('Shareholder')}</td>
                                                <td>{this.props.curSelectSecurity.name}</td>
                                            </tr>
                                            <tr>
                                                <td>{__('Quantity')}</td>
                                                <td>{ formatFractionalNumber(data.quantity) } shares</td>
                                            </tr>
                                            <tr>
                                                <td>{__('Transaction value')}</td>
                                                <td>{ formatCurrencyNumber(data.transaction_value,null) }</td>
                                            </tr>
                                            <tr>
                                                <td>{__('Repurchase date')}</td>
                                                <td>{ formatDate(data.repurchase_date) }</td>
                                            </tr>
                                            <tr>
                                                <td>{__('Return to pool')}</td>
                                                <td>{ formatFractionalNumber(data.quantity) } shares will { data.is_return_to_pool ===false && "not"} be returned.</td>
                                            </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer box-footer invite-footer">
                            <button type="button" onClick={this.submitRepurchasedShares}  className="btn boxbtn-to bg-darkblue update-btn">{this.state.uploadingStatus==1?__("Repurchasing"):__("Repurchase shares")}</button>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
export default RepurchaseShares;