import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Dashboard from './Dashboard';
import CryptoDetail from './CryptoDetail';
import Warrants from './Warrants';
import TokenOptionPlan from './TokenOptionPlan';
import Ownership from './Ownership';
import Captable from './Captable';
import Reports from './Reports';
import Transaction from './Transaction';

import './style.scss';

import security from 'stores/security';
import Templates from "../Securities/Templates";

export default class Board extends Component {
    path = this.props.match.path

    config = [
        {
            path: `${this.path}/dashboard`,
            component: Dashboard,
            permission: 'all',
            exact: true,
        },
        {
            path: `${this.path}/token-option-plan`,
            component: TokenOptionPlan,
            permission: 'all',
            exact: false
        },
        {
            path: `${this.path}/ownership`,
            component: Captable,
            permission: 'all',
            exact: false
        },   
        {
            path: `${this.path}/token-warrants`,
            component: Warrants,
            permission: 'all',
            exact: false,
        },
        {
            path: `${this.path}/detail`,
            component: CryptoDetail,
            permission: 'all',
            exact: true,
        },
        {
            path: `${this.path}/transaction`,
            component: Transaction,
            permission: 'all',
            exact: true
        },
        {
            path: `${this.path}/reports`,
            component: Reports,
            permission: 'all',
            exact: true
        }
           
    ]

    render() {
        const routeConfig = this.config.filter((value, key) => security.hasPermission(value.permission))
        return <div className="sprout-token-root">
            <Switch>
                {
                    routeConfig.map((val, index) => <Route key={index} exact={val.exact} path={val.path} component={val.component} />)
                }
                <Redirect to={routeConfig && routeConfig[0] && routeConfig[0].path || this.path} />
            </Switch>
        </div>
    }
}