import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Button, Row, Col, message } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import RadioButtons from 'components/RadioButtons';
import { radioData, renderValue } from './config';
import { post } from 'srcPath/http';
import { formatNumber, formatCurrencyNumber } from 'Utils';
import security from 'stores/security';
import Loading from 'components/Loading';
import Transaction from './Transaction';
import Wallets from './Wallets';
import TokenSelector, { getDefaultTokenId } from 'B/components/TokenSelector'

import './style.scss';

export default function Crypto(props) { 
	const [tab, setTab] = useState('transactions')
	const [ coinData, setCoinData ] = useState({})
	const { coin_info = {}  } = coinData
	const { state }  = useLocation();
	const customCoinList = (security.companyInfo && security.companyInfo.custom_coin_list) || []
	const [ coinId, setCoinId ] = useState(state || getDefaultTokenId())
	const menuCoin = customCoinList.find(val => val.id === coinId) || {}

	const changeTab = useCallback((e) => {
		const value = e.target.value

		setTab(value)
	}, [])

	useEffect(() => {
		const getData = async () => {
			Loading.global(true)

			try {
				const { data: resData } = await post('getCustomCoinInfoById', { id: menuCoin.id })
				const { data, code } = resData || {}

				if (code === 0) {
					setCoinData(data)
				}
			} catch(e) {
				message.error(e.message)
			}

			Loading.global(false)
		}

		if (menuCoin.id || menuCoin.id == 0) {
			getData()
		}
	}, [menuCoin.id])

	const handleMenu = (key) => {
		setCoinId(key)
	}

	const getAddressData = async (walletAddress) => {
		Loading.global(true)

		try {

			const { data: resData } = await post('getCoinTransactions', {
				pageNumber: 1,
				pageSize: 100000,
				walletAddress
			})

			const { code, data } = resData || {}

			if (code === 0 && data.addressInfo) {
				setAddressInfo({
					walletAddress,
					...data.addressInfo
				})
			}

		} catch(e) {
			message.error(e.message)
		}
		Loading.global(false)	
	}


	return <div className="crypto-root">
		<div className="crypto-detail-title">Token Details</div>
		<div className="sprout-flex crypto-detail-banner">
			<Button className="sprout-btn-white">
				<Link to="/home/token/dashboard">
					<LeftOutlined />Back to summary
				</Link>
			</Button>
			<TokenSelector onChange={handleMenu} value={coinId} />
		</div>

			<div className="crypto-detail-summary">
				<Row gutter={12}>
					<Col span={12}>
						<div className="crypto-section">
							<div className="crypto-section-title">{__('Overview')}</div>
							<div className="crypto-section-content">
								<div className="crypto-section-content-item">
									<div className="crypto-label">
										<div>{__('PRICE')}</div>
										<div className="crypto-value">{formatCurrencyNumber(coin_info.price)}</div>
									</div>
									<div className="">
										<div>{__('FULLY DILUTED MARKET CAP')}</div>
										<div className="crypto-value">{formatCurrencyNumber(coin_info.fully_diluted_market_cap)}</div>
									</div>
								</div>
								<div className="crypto-section-content-item">
									<div className="crypto-label">{__('Max Total Supply:')}</div>
									<div className="crypto-value">{formatNumber(coin_info.max_total_supply)}</div>
								</div>
								<div className="crypto-section-content-item">
									<div className="crypto-label">{__('Holders:')}</div>
									<div className="crypto-value">{formatNumber(coin_info.holders)}</div>
								</div>
								<div className="crypto-section-content-item">
									<div className="crypto-label">{__('Transfers:')}</div>
									<div className="crypto-value">{formatNumber(coin_info.transfer_count)}</div>
								</div>
							</div>
						</div>
					</Col>
					<Col span={12}>
						<div className="crypto-section">
							<div className="crypto-section-title">{__('Profile Summary')}</div>
							<div className="crypto-section-content">
								<div className="crypto-section-content-item">
									<div className="crypto-label">{__('Contract:')}</div>
									<div className="crypto-value">
										<a>{renderValue(coin_info.contract)}</a>
									</div>
								</div>
								<div className="crypto-section-content-item">
									<div className="crypto-label">{__('Decimals:')}</div>
									<div className="crypto-value">{formatNumber(coin_info.decimals)}</div>
								</div>
								<div className="crypto-section-content-item">
									<div className="crypto-label">{__('Official Site:')}</div>
									<div className="crypto-value">
										<a href={coin_info.official_site} target="_blank">{renderValue(coin_info.official_site)}</a>
									</div>
								</div>
								<div className="crypto-section-content-item">
									<div className="crypto-label">{__('Social Profiles:')}</div>
									<div className="crypto-value">--</div>
								</div>
							</div>
						</div>				
					</Col>
				</Row>
			</div>
			<div className="crypto-filtered-data">
				{/*{addressInfo && <TokenFilteredData data={addressInfo} />}*/}
			</div>
			<div className="crypto-detail">
				<div className="crypto-section">
					<div className="crypto-detail-banner">
						<RadioButtons value={tab} options={radioData} onChange={changeTab}/>
						<div className="">
							<Link 
								to={
									{
										pathname: tab === 'transactions' ? '/home/token/transaction' : '/home/stakeholders/wallets-list',
									}
								}
							>
								{__('More')} <RightOutlined />
							</Link>
						</div>
					</div>
					{
						tab === 'transactions' ? <Transaction coin={coinId} getAddressData={getAddressData} /> : <Wallets contract={coin_info.contract} />
					}
				</div>
			</div>
	</div>
}