import React from 'react';
import openCertificateModal, { openTokenWarrant } from 'components/CertificateDetail';
import Utils, { formatNumber, formatCurrencyNumber, tableSort, I } from "Utils";
import { formatDate } from 'Utils/format/formatDate';
import DropdownMenu from "./DropdownMenu";
import TransactionRender from 'B/components/TransactionHistory';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import customized from "../img/customized.png";
import {Space} from "antd";

const renderValue = data => data || '--'
const formData = value => formatCurrencyNumber(value);

const foundColumns = (getData, query, callback, listLoading) => {

    const checkContent = (key, newKey, checkKey, data, formats) => {
        return I(data[checkKey], '') ? <span className="financing-history-table-red">{formats(data[newKey])}</span> : formats(data[key]);
    }

    const sortContent  = (a, b, key, newKey) => a[I(a.historySecurityList, '') ? newKey : key] - b[I(a.historySecurityList, '') ? newKey : key];
    return [
        {
            title: __('Round'),
            dataIndex: 'name',
            sorter: (a, b) => tableSort(a.name, b.name),
            className: 'financing-history-table-first-td',
            render: (text, data) => {
                const { historySecurityList, historyShareSecurityList } = data;
                return (
                    <div>
                        <div className="financing-history-table-line" style={{backgroundColor: data.color}}></div>
                        <div className="financing-history-table-name">
                            <Space size={10}>
                                <span>{text}</span>
                                { ( I(historySecurityList, '') || I(historyShareSecurityList, '') ) && <img src={customized} /> }
                                </Space>
                        </div>
                    </div>
                )
            },
            width: 250,
        },
        {
            title: __('Closing date'),
            dataIndex: 'closingDate',
            render: formatDate,
            className: 'nowrap',
            sorter: (a, b) => tableSort(a.closingDate, b.closingDate, 'date'),
        },
        {
            title: __('Cash raised'),
            dataIndex: 'cashRaised',
            sorter: (a, b) => sortContent(a, b, 'cashRaised', 'history_cashRaised'),
            render: (text, data) => checkContent( 'cashRaised', 'history_cashRaised', 'historySecurityList', data, formData),
            align: 'right',
        },
        {
            title: __('Shares issued'),
            dataIndex: 'sharesIssued',
            sorter: (a, b) => sortContent(a, b, 'sharesIssued', 'history_sharesIssued'),
            render: (text, data) => checkContent( 'sharesIssued', 'history_sharesIssued', 'historyShareSecurityList', data, formatFractionalNumber),
            align: 'right',
        },
        {
            title: __('Original issue price'),
            dataIndex: 'originalIssuePrice',
            sorter: (a, b) => a.originalIssuePrice - b.originalIssuePrice,
            render: formData,
            align: 'right',
        },
        {
            title: __('Fully diluted shares'),
            dataIndex: 'fullyDilutedShares',
            sorter: (a, b) => sortContent(a, b, 'fullyDilutedShares', 'history_fullyDilutedShares'),
            render: (text, data) => checkContent( 'fullyDilutedShares', 'history_fullyDilutedShares', 'historySecurityList', data, formatFractionalNumber),
            align: 'right',
        },
        {
            title: __('Post-money valuation'),
            dataIndex: 'post_money_valuation',
            sorter: (a, b) => sortContent(a, b, 'post_money_valuation', 'history_post_money_valuation'),
            render: (text, data) => checkContent( 'post_money_valuation', 'history_post_money_valuation', 'historySecurityList', data, formData),
            align: 'right',
        },
        {
            title: '',
            dataIndex: 'id',
            render: (text, data, index) => <DropdownMenu listLoading={listLoading} data={data} index={index} getData={getData} query={query} callback={callback}/>,
            align: 'right',
        },
    ];
}

const openModal = (record) => {
    if (record?.type === 'token_warrant') {
        return openTokenWarrant(record.security_id)
    }
    openCertificateModal(record.security_id)
}

const cashRaisedColumns = [
    {
        title: __('Security'),
        dataIndex: 'certificateNumber',
        sorter: (a, b) => tableSort(a.certificateNumber, b.certificateNumber),
        // 逻辑不完善，先不要加
        // filters: [
        //   {
        //     text: 'PSS-1',
        //     value: 'PSS-1',
        //   },
        //   {
        //     text: 'PSA-1',
        //     value: 'PSA-1',
        //   },

        //   {
        //     text: 'PS-B-1',
        //     value: 'PS-B-1',
        //   }
        // ],
        // onFilter: (value, record) => record.certificateNumber.indexOf(value) === 0,
    },
    {
        title: __('Stakeholder'),
        dataIndex: 'name',
        sorter: (a, b) => a.name.length - b.name.length,
    },
    {
        title: __('Issue date'),
        dataIndex: 'issueDate',
        render: formatDate,
        className: 'nowrap'
    },
    {
        title: __('Cash paid'),
        dataIndex: 'cashPaid',
        sorter: (a, b) => a.cashPaid - b.cashPaid,
        render: formData
    },
    {
        title: __('IP transferred'),
        dataIndex: 'ipTransferred',
        sorter: (a, b) => a.ipTransferred - b.ipTransferred,
        render: formData
    },
    {
        title: __('Convertibles w/ int. converted'),
        dataIndex: 'debtCanceled',
        sorter: (a, b) => a.debtCanceled - b.debtCanceled,
        render: formData
    },
    {
        title: __('Other consideration'),
        dataIndex: 'otherConsideration',
        sorter: (a, b) => a.otherConsideration - b.otherConsideration,
        render: formData
    },
    {
        title: __('Shares'),
        dataIndex: 'shares',
        sorter: (a, b) => a.shares - b.shares,
        render: text => formatFractionalNumber(text)
    },
    {
        title: '',
        key: 'action',
        render: (_, record) => <a onClick={() => openModal(record)}>{__('View')}</a>,
    }
];

const fullyDilutedColumn = () => {
    return [
        {
            title: __('Cert ID'),
            dataIndex: 'label_id',
            sorter: (a, b) => tableSort(a.label_id, b.label_id),
            fixed: 'left',
            width: 130,
            isChecked: true,
            isDisabled: true,
        },
        {
            title: __('Stakeholder'),
            dataIndex: 'stakeholder',
            sorter: (a, b) => tableSort(a.stakeholder, b.stakeholder),
            fixed: 'left',
            width: 180,
            isChecked: true,
            isDisabled: true,
        },
        {
            title : __('Status'),
            dataIndex : 'status',
            sorter: (a, b) => tableSort(a.status, b.status),
            width:180,
            fixed: 'left',
            render : (text, value) => {
                return <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'noWrap' }}>
                    {
                        (value.status === "Outstanding" || value.status==="Issued") &&
                        <p className="green-disc">&nbsp;&nbsp;&nbsp;&nbsp;{value.status}</p>}
                    {value.status != "Outstanding" && value.status != "Issued" &&
                    <p className="red-disc">&nbsp;&nbsp;&nbsp;&nbsp;{value.status}</p>}
                    <TransactionRender transferSecurity={value.transferSecurity} />
                </div>
            },
            isChecked: true,
            isDisabled: true,

        },
        {
            title: __('Issued quantity'),
            dataIndex: 'quantity',
            sorter: (a, b) => tableSort(a.quantity, b.quantity, 'number'),
            render: (text) => formatFractionalNumber(text),
            fixed: 'left',
            align: 'right',
            width: 155,
            isChecked: true,
            isDisabled: true,
        },
        {
            title: __('Create date'),
            dataIndex: 'create_date',
            sorter: (a, b) => tableSort(a.create_date, b.create_date, 'date'),
            render: (text) => formatDate(text),
            isChecked: true,
            isDisabled: false,
        },
        {
            title: __('Issued date/Granted date'),
            dataIndex: 'issued_date',
            sorter: (a, b) => tableSort(a.issued_date, b.issued_date, 'date'),
            render: (text) => formatDate(text),
            isChecked: true,
            isDisabled: false,
        },
        {
            title: __('Canceled date'),
            dataIndex: 'canceled_date',
            sorter: (a, b) => tableSort(a.canceled_date, b.canceled_date, 'date'),
            render: (text) => formatDate(text),
            isChecked: true,
            isDisabled: false,
        },
        {
            title: __('Transfer date'),
            dataIndex: 'transfer_date',
            sorter: (a, b) => tableSort(a.transfer_date, b.transfer_date, 'date'),
            render: (text) => formatDate(text),
            isChecked: true,
            isDisabled: false,
        },
        {
            title: __('Terminate date'),
            dataIndex: 'terminate_date',
            sorter: (a, b) => tableSort(a.terminate_date, b.terminate_date, 'date'),
            render: (text) => formatDate(text),
            isChecked: false,
            isDisabled: false,
        },
        {
            title: __('Forfeit date'),
            dataIndex: 'forfeit_date',
            sorter: (a, b) => tableSort(a.forfeit_date, b.forfeit_date, 'date'),
            render: (text) => formatDate(text),
            isChecked: false,
            isDisabled: false,
        },
        {
            title: __('Expired date'),
            dataIndex: 'expire_date',
            sorter: (a, b) => tableSort(a.expire_date, b.expire_date, 'date'),
            render: (text) => formatDate(text),
            isChecked: true,
            isDisabled: false,
        },
    ]
}
export { foundColumns, cashRaisedColumns, fullyDilutedColumn }
