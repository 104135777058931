import React, { Component, Fragment } from "react";
import {Table, Checkbox, Dropdown, Menu, Button} from 'antd';
import Utils, { I, formatCurrencyNumber, tableSort } from 'Utils';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import TransactionRender from 'B/components/TransactionHistory';
import openCertificateModal from 'components/CertificateDetail';
import {DownOutlined} from "@ant-design/icons";
import { noPermission } from 'components/Permission';
import {formatDate} from "Utils/format/formatDate";
import { LOADING,certificateStatus } from "Utils/constant";

class ShareTableModels extends Component {
    state = {
        selectedRowKeys: [], // Check here to configure the default column
        searchText: '',
        searchedColumn: '',
    };

    componentDidMount(){
        this.props.onRef(this);
        this.initFilterColumn();
    }
    initFilterColumn = () => {
        const tableColumn = this.tableColumns();
        const { saveState } = this.props;
        saveState('tableColumn', tableColumn);
    }

    handleTableChange = (pagination, filters, sorter, extra) => {
        let sort = {}
        const { order, column } = sorter

        // TODO: cancel sort
        if (column?.sortName) { 
            sort = {
                column: column.sortName,
                type: order
            }

            this.props.sortTable?.(sort)
        }else{
            // Ning 新加cancel sort
            sort = {}
            this.props.sortTable?.(sort)
        }
    }

    securityHover = (checked, record, index) => {

        let showMsgStatus = I(record.showMsgStatus, false);
        const { sign_status } = record;
        const message = {
            1 : __('Awaiting officer signature'),
            2 : __('Awaiting holder signature')
        }
        return (
            <Fragment>
                {
                    (sign_status == 1 || sign_status == 2) &&
                    <div className="signTipBox">
                        <div className={sign_status == 1 ? 'officer-tip-triangle' : 'holder-tip-triangle'}></div>
                        <p className={['sign-tiptext', sign_status == 1 ? 'bg-lightorange' : 'bg-lightgreen', showMsgStatus && 'shares-row-msg-show'].join(' ')} style={{display:'block'}}>{message[sign_status]}</p>
                    </div>
                }
                <Checkbox onClick={(e)=>{
                    e.stopPropagation();
                }} checked={checked} onChange={this.checkChange.bind(this, record, index, checked)} ></Checkbox>
            </Fragment>
        );
    }

    /**
     * 清空checkbox所有选中内容
     */
    clearAll = () => {
        this.setState(()=>({
            selectedRowKeys : []
        }))
    }

    /**
     * 表格checkbox change事件
     * @param record
     * @param index
     * @param boxChecked
     * @param e
     */
    checkChange = (record, index, boxChecked, e) => {
        const { checked } = e.target;
        const { selectedRowKeys } = this.state;
        const { updateSelectCount } = this.props;
        const { id } = record;
        let key_list = [...selectedRowKeys];
        if(checked){
            key_list.push(id);
        }else{
            key_list.forEach( (v, k) => {
                if(v == id){
                    key_list.splice(k, 1);
                }
            })
        }
        updateSelectCount(key_list.length, [{selectIndex: index,checked: checked, status:record.status}]);
        this.setState(()=>({
            selectedRowKeys : key_list
        }))
        this.props.setModifyId( key_list)
        this.props.checkSecurityStatus(key_list)
    }

    /**
     * 表格列参数配置
     * @returns {[{sorter: (function(*, *): number), dataIndex: string, onFilter: (function(*=, *): boolean), filters: [{text: string, value: string}, {text: string, value: string}, {text: string, value: string}, {text: string, value: string}, {text: string, value: string}, null], title: string, align: string, key: string}, {filterDropdown: function({setSelectedKeys: *, selectedKeys?: *, confirm?: *, clearFilters?: *}): *, sorter: (function(*, *): number), dataIndex: string, filterIcon: function(*): *, onFilter: function(*, *): *|string, onFilterDropdownVisibleChange: function(*=): void, title: string}, {sorter: (function(*, *): number), dataIndex: string, title: string, render: (function(*, *))}, {sorter: (function(*, *)), dataIndex: string, title: string, align: string, render: (function(*=))}, {sorter: (function(*=, *=)), dataIndex: string, title: string, align: string, render: (function(*, *=))}, null, null, null]}
     */
    tableColumns = () => {
        return [
            {
                title : __('Security'),
                align : 'left',
                dataIndex : 'custom_label_id',
                key : 'keyId',
                fixed: 'left',
                sorter: true,
                isChecked: true,
                isDisabled: true,
                sortName: 'security',
                render: (text, record) => <Button type="link" onClick={() => openCertificateModal(record.id)}>{text}</Button>
            },
            {
                title : __('Stakeholder'),
                dataIndex : 'name',
                sorter: true,
                sortName: 'stakeholder',
                isChecked: true,
            },
            {
                title : __('Status'),
                dataIndex : 'status',
                sorter:true,
                sortName: 'status',
                width:180,
                render : (text, value) => {
                    return <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'noWrap' }}>
                        {
                            (value.status === "Outstanding" || value.status==="Issued") &&
                            <p className="green-disc">&nbsp;&nbsp;&nbsp;&nbsp;{certificateStatus[value.status]}</p>}
                        {value.status != "Outstanding" && value.status != "Issued" &&
                        <p className="red-disc">&nbsp;&nbsp;&nbsp;&nbsp;{certificateStatus[value.status]}</p>}
                        <TransactionRender transferSecurity={value.transferSecurity} />
                    </div>
                },
                isChecked: true,
            },
            {
                title : __('Shares'),
                dataIndex : 'quantity',
                align : 'right',
                // sorter: (a, b) => tableSort(a.quantity, b.quantity, 'number'),
                render: (text) => <span>{ formatFractionalNumber(text) }</span>,
                isChecked: true,
            },
            {
                title : __('Price/Share'),
                dataIndex : 'price_share',
                align : 'right',
                // sorter: (a, b) => {
                //     const sharePriceA = this.priceShareOrderBy(a, 0);
                //     const sharePriceB = this.priceShareOrderBy(b, 0);
                //     return tableSort(sharePriceA, sharePriceB, 'number');
                // },
                render: (text, value) => {
                    const sharePrice = this.priceShareOrderBy(value, '--');
                    return <span>{formatCurrencyNumber(sharePrice)}</span>
                },
                isChecked: true,
            },
            {
                title : __('Transaction value'),
                dataIndex : 'transaction_value',
                align : 'right',
                // sorter: (a, b) => {
                //     const transactionValueA = this.transactionValueOrderBy(a, 0);
                //     const transactionValueB = this.transactionValueOrderBy(b, 0);
                //     return tableSort(transactionValueA, transactionValueB, 'number');
                // },
                render: (text, value) => {
                    // const transactionVal = this.transactionValueOrderBy(value,'--')
                    return <span>{formatCurrencyNumber(text)}</span>
                },
                isChecked: true,
            },
            {
                title : __('Vested quantity'),
                dataIndex : 'vested_quantity',
                align : 'right',
                // sorter: (a, b) => tableSort(a.vested_quantity, b.vested_quantity, 'number'),
                render: (text, value) => {
                    return <span>{formatFractionalNumber(text)}</span>
                },
                isChecked: true,
            },
            {
                title: __('Vesting schedule'),
                dataIndex: 'vestingScheduleName',
                align: 'left',
                // sorter: (a, b) => tableSort(a.vestingScheduleName, b.vestingScheduleName),
                isChecked: true,
                render: text => <span>{I(text, '--')}</span>
            },
            {
                title : __('Share class'),
                dataIndex : 'plan_name',
                align : 'left',
                sorter: true,
                sortName: 'option_plan',
                render: (text) => <span>{ !text ? "--" : text }</span>,
                isChecked: true,
            },
            {
                title : __('Issue date'),
                dataIndex : 'issue_date',
                align: 'center',
                sortName: 'issue_date',
                // sorter: (a, b) => tableSort(a.issue_date, b.issue_date, 'date'),
                sorter: true,
                render: (text, value) => {
                    return <span>{ formatDate(value.issue_date) }</span>
                },
                isChecked: true,
            },
            {
                title : '',
                dataIndex : 'id',
                width: 50,
                fixed: 'right',
                render:(text, value, index) => this.dropdownInfo(text, value, index),
                isHide: true,
            }
        ]
    }

    /**
     * 计算 price shares列金额，目的是为了方便排序
     * @param value
     * @param defaultPrice
     * @returns {number|*}
     */
    priceShareOrderBy = (value, defaultPrice) => {
        let transactionVal = Math.round(Utils.resetNumberWithCommas(value.cash_paid) ) + Math.round(Utils.resetNumberWithCommas(value.other_consideration)) + Math.round (Utils.resetNumberWithCommas(value.debt_canceled)) + Math.round (Utils.resetNumberWithCommas(value.value_of_ip_transferred))
        const sharePrice = value.quantity!=0 ? transactionVal/value.quantity : defaultPrice;
        return sharePrice;
    }

    /**
     * 计算 transaction value，目的是为了方便排序
     * @param value
     * @param defaultValue
     * @returns {number|*}
     */
    transactionValueOrderBy = (value, defaultValue) => {
        let transactionVal = Math.round(Utils.resetNumberWithCommas(value.cash_paid) ) + Math.round(Utils.resetNumberWithCommas(value.other_consideration)) + Math.round (Utils.resetNumberWithCommas(value.debt_canceled)) + Math.round (Utils.resetNumberWithCommas(value.value_of_ip_transferred))
        const transactionValue = transactionVal!=0 ? transactionVal : defaultValue;
        return transactionValue;
    }

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    // setCurSelectSecurity = ( index) => {
    //     const { setCurSelectSecurity } = this.props;
    //     setCurSelectSecurity( index);
    // }
    setCurSelectSecurityNew = ( text, data, index) => {
        const { setCurSelectSecurityNew } = this.props;
        setCurSelectSecurityNew( text);
        this.updateSecurityListsVisible(data, true);
    }

    /**
     * 更新列表中的菜单显示状态
     * @param index 列表下标
     * @param flag 菜单显示状态
     */
    updateSecurityListsVisible = (data, flag) => {
        const { securityLists, saveState } = this.props;
        let list = [...securityLists];
        //默认index改为使用id获取数组下标
        const index =list.findIndex((v,k,arr)=>{
            return v.id ==data.id
        })
        if(flag === false) {
            list.forEach((v, k) => {
                list[k]['visibleStatus'] = false;
            });
        }
        if(data !== false){
            list[index]['visibleStatus'] = true;
        }
        saveState('securityLists', list);
    }

    getVestingData = (data, index, e) => {
        this.stopModel(data,index,e);
        const { getVestingData } = this.props;
        getVestingData( index);
    }
    getShareClassPlanWhenTransfer = (data, index, e) => {
        this.stopModel(data,index,e);
        const { getCsPsPlanList } = this.props;
        getCsPsPlanList( index);
    }

    getVestMilestone = ( data, index, e) => {
        this.stopModel(data,index,e);
        const { getVestMilestone } = this.props;
        getVestMilestone();
    }

    getInitVestingList = ( data, index, e) => {
        this.stopModel(data,index,e);
        const { getInitVestingList } = this.props;
        getInitVestingList();
    }

    getIsAbleToReturn = (data, index, e) => {
        this.stopModel(data, index, e);
        const { getIsAbleToReturn } = this.props;
        getIsAbleToReturn();
    }
    reverseCancellation = (data,index,e) => {
        this.stopModel(data,index,e);
        const { reverseCancellation } = this.props;
        reverseCancellation();
    }

    resetSecurityStatus = () =>{
        const { resetSecurityStatus } = this.props;
        resetSecurityStatus();
    }
    setInitShareNote = (data, index, e) => {
        this.stopModel(data,index,e);
        const { setInitShareNote } = this.props;
        setInitShareNote();
    }

    resendEmail = (data, index, e) => {
        this.stopModel(data,index,e);
        const { resendEmail } = this.props;
        resendEmail();
    }

    openReverseTermination = (data, index,e) => {
        this.stopModel(data,index,e);
        const { openReverseTermination } = this.props;
        openReverseTermination(data,index);
    }

    reverseTermination = (data, index, e) => {
        this.stopModel(data,index,e);
        const { reverseTermination } = this.props;
        reverseTermination(data,index);
    }

    setModifyId = (index) => {
        const { setModifyId } = this.props;
        setModifyId();
    }

    checkSecurityStatus = (index) => {
        const { checkSecurityStatus } = this.props;
        checkSecurityStatus();
    }

    stopModel = (data, index, e) => {
        e.stopPropagation();
        this.updateSecurityListsVisible(false, false);
    }

    handleMenuClick = e => {
        this.setState({
            visibleList: false
        })
    }
    handleVisibleChange = flag => {
        this.updateSecurityListsVisible(false, flag);
    }

    /**
     * 表格最后一列菜单配置
     * @param data
     * @param index
     * @returns {JSX.Element}
     */
    dropdownInfo = (text, data, index) => {
        const { visibleStatus } = data;
        return (
            <Dropdown visible={visibleStatus} overlay={this.menu( data, index)}
                      onVisibleChange={this.handleVisibleChange}
                      placement="bottomRight"
                      trigger="click"
                      onClick={(e)=>{
                          e.stopPropagation();
                          this.setCurSelectSecurityNew(text, data, index);
                      }}>
                <div className="tableMenu"><DownOutlined style={{ color: '#FFF' }}/></div>
            </Dropdown>
        );
    }
    /**
     * Waterfall models 菜单
     * @param data
     * @param index
     * @returns {JSX.Element}
     */
    menu = (data, index) => {
        const { reverseDesc }     = this.state;
        const { notes }   = this.props;
        const isModifyVesting     = data.vesting_schedule_id==0 && data.custom_schedule_id==0 ? 0 : 1;
        const reverseCancellation = data.status=="Canceled"||data.status=="Repurchased" ? 1 : 0;
        const isTransferred = data.status == "Transferred" ? 1 : 0;
        const isRepurchased = data.status == "Repurchased" ? 1 : 0;
        const isNotPurchased = data.status == "Not purchased" ? 1 : 0;
        const isCanceled = data.status == "Canceled" ? 1 : 0;
        const isEmptyTerminationDate = data.termination_date =="" ? 1 : 0;
        const modifyCertificateText = data.security_type == "RSA" ? __("Modify RSA") : __("Modify certificate")
        const cancleCertificateText = data.security_type == "RSA" ? __("Cancel RSA") : __("Cancel certificate")
        const addOrEdit = ( notes == ""||null ) ? __("Add certificate notes"): __("Edit certificate notes")
        return (
            <Menu>
                { (noPermission('securities_shares_certificate_edit') === false ) && (<Menu.Item ><a data-toggle="modal" onClick={this.stopModel.bind(this,data,index)} data-target="#modifyShareModal">{ modifyCertificateText }</a></Menu.Item>) }
                { (noPermission('securities_shares_vestingSchedule') === false ) && ( isTransferred == 0 && isRepurchased == 0 && data.type !== "ps" && isModifyVesting==0 && <Menu.Item ><a data-toggle="modal" onClick={this.stopModel.bind(this,data,index)} data-target="#addVestingScheduleModal">{__('Add vesting schedule')}</a></Menu.Item> )}
                { (noPermission('securities_shares_vestingSchedule') === false ) && ( isTransferred == 0 && isRepurchased == 0 && data.type !== "ps" && isModifyVesting==1 && <Menu.Item ><a data-toggle="modal" onClick={this.getVestingData.bind(this,data,index)} data-target="#addVestingScheduleModal">{__('Modify vesting schedule')}</a></Menu.Item> )}
                { (noPermission('securities_shares_vestingSchedule') === false ) && isModifyVesting==1 && data.isMilestone==1 && isCanceled == 0 && isTransferred == 0 && isRepurchased == 0 && <Menu.Item ><a data-toggle="modal" onClick={this.getVestMilestone.bind(this,data,index)} data-target="#vestMilestonesModal">{__('Vest milestones')}</a></Menu.Item>}
                { (noPermission('securities_shares_vestingSchedule') === false ) && isModifyVesting==1 && data.isMilestone==0 && isCanceled == 0 && isTransferred == 0 && isRepurchased == 0 && <Menu.Item ><a data-toggle="modal" onClick={this.getInitVestingList.bind(this,data,index)} data-target="#accelerateVestingModal">{__('Accelerate vesting')}</a></Menu.Item>}
                { (noPermission('securities_EA_achieve') === false) && isNotPurchased==1 && <Menu.Item ><a data-toggle="modal"  onClick={this.stopModel.bind(this,data,index)} data-target="#purchaseRsaModal">{__('Purchase RSA')}</a></Menu.Item>}
                { (noPermission('securities_shares_transfer') === false ) &&(isNotPurchased == 0 && isTransferred == 0 && isRepurchased == 0 && isCanceled == 0 && <Menu.Item ><a data-toggle="modal"  onClick={this.getIsAbleToReturn.bind(this,data,index)} data-target="#repurchaseModal">{__('Repurchase shares')}</a></Menu.Item>) }
                { (noPermission('securities_shares_transfer') === false ) &&(isNotPurchased == 0 && isTransferred == 0 && isRepurchased == 0 && isCanceled == 0 && <Menu.Item ><a data-toggle="modal"  onClick={this.getShareClassPlanWhenTransfer.bind(this,data,index)} data-target="#transferModal">{__('Transfer shares')}</a></Menu.Item>) }
                { (noPermission('securities_shares_certificate_cancel') === false ) && (isTransferred == 0 && data.status === "Outstanding" &&isRepurchased == 0 &&  <Menu.Item ><a data-toggle="modal"  onClick={this.stopModel.bind(this,data,index)} data-target="#cancelModal">{ cancleCertificateText }</a></Menu.Item> )}
                { (noPermission('securities_shares_certificate_cancel') === false ) && (isTransferred == 0 && reverseCancellation == 1 && isRepurchased == 0 && <Menu.Item ><a onClick={this.reverseCancellation.bind(this,data,index)} className="reverse-btn text-muted">{__('Reverse cancellation')}</a></Menu.Item> )}
                { (noPermission('securities_shares_certificate_cancel') === false ) && (isTransferred == 0 && reverseCancellation == 0 && isRepurchased == 0 && <Menu.Item className="shares-table-menu-item"><a  onClick={this.stopModel.bind(this,data,index)} className="reverse-btn text-muted no-cursor">{__('Reverse cancellation')}<p className="reverse-tip">{__('Can only apply to cancelled')}<br/><span> {__('or')} </span>{__(' repurchased certificate(s).')}</p> </a></Menu.Item> )}
                { (noPermission('securities_shares_adminNotes') === false ) && <Menu.Item ><a data-toggle="modal" onClick={this.setInitShareNote.bind(this,data,index)} data-target="#addAdminNotesModal">{ addOrEdit }</a></Menu.Item> }
                { (noPermission('securities_shares_resend') === false ) && (isTransferred == 0 && data.sign_status == "1" && isRepurchased == 0 && <Menu.Item ><a onClick={this.resendEmail.bind(this,data,index)} >{__('Resend to officers')}</a></Menu.Item> )}
                { (noPermission('securities_shares_resend') === false ) && (isTransferred == 0 && data.sign_status == "2" && isRepurchased == 0 && <Menu.Item ><a onClick={this.resendEmail.bind(this,data,index)} >{__('Resend to stakeholder')}</a></Menu.Item> )}
                {/*{ (noPermission('securities_shares_terminate') === false) && isModifyVesting==1 && isEmptyTerminationDate == 1 && data.status != "Forfeited" && isTransferred == 0 && isRepurchased == 0 && <Menu.Item ><a data-toggle="modal"  onClick={this.stopModel.bind(this,data,index)} data-target="#terminateStakeholderModal">Terminate stakeholder</a></Menu.Item>}*/}
                { (noPermission('securities_shares_terminate') === false) && data.isCanBeTerminated == 1 && <Menu.Item ><a data-toggle="modal"  onClick={this.stopModel.bind(this,data,index)} data-target="#terminateStakeholderModal">{__('Terminate stakeholder')}</a></Menu.Item>}
                { (noPermission('securities_shares_reverse_termination') === false) && isEmptyTerminationDate == 0 && isTransferred == 0 && isRepurchased == 0 && <Menu.Item ><a onClick={this.openReverseTermination.bind(this,data,index)}>{__('Reverse termination')}</a></Menu.Item>}
            </Menu>
        )
    }

    /**
     * table 行 鼠标移入移出
     * @param row 对象
     * @param index 下标
     * @returns {{onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave}}
     */
    onRow = (row, index) => {
        const { updateShowMsgStatus, curSelectSecurity } = this.props;
        return {
            //鼠标移入
            onMouseEnter : () => {
                updateShowMsgStatus(index, true);
            },
            //鼠标移出
            onMouseLeave : () => {
                updateShowMsgStatus(index, false);
            }
        }
    }

    /**
     * 全选change事件
     * @param selectedRowKeys
     */
    onSelectChange = (selectedRowKeys) => {
        const { securityLists, updateSelectCount } = this.props;
        const count = selectedRowKeys.length;
        if(!count){
            updateSelectCount(count, [], true);
        }else{
            let selectIndexArray = [];
            securityLists.forEach((v, k) => {
                selectIndexArray.push({selectIndex:k, checked: true});
            })
            updateSelectCount(count, selectIndexArray);
        }
        this.setState({ selectedRowKeys }, ()=> this.props.checkSecurityStatus(selectedRowKeys));
    };

    rowSelection = (selectedRowKeys) => ({
        selectedRowKeys,
        onChange: this.onSelectChange,
        renderCell : (checked, record, index, originNode) => this.securityHover(checked, record, index, selectedRowKeys)
    })

    render() {
        const { selectedRowKeys } = this.state;
        const { securityLists, tableColumn, paginationConfig = {}, loading } = this.props;

        return (
            <Table
                onRow={this.onRow}
                className="stake-table shares-table bigger_checkbox"
                locale={ loading && { emptyText: LOADING }}
                columns={tableColumn}
                dataSource={securityLists}
                rowSelection={this.rowSelection(selectedRowKeys)}
                rowKey="id"
                pagination={ paginationConfig ? {...paginationConfig, position: ['bottomCenter'] } : false}
                scroll={{ x: 1400, y: innerHeight ? innerHeight - 280 : false }}
                rowClassName="stake-table-row"
                onChange={this.handleTableChange}
            />
        );
    }
}

export default ShareTableModels;