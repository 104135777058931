import React, { Fragment } from 'react';
import Utils, {formatNumber, I, formatCurrencyNumber, calculator } from "Utils";
import { paymentMethod } from "Utils/constant";
import DeleteDoc from "../../components/DeleteDoc";
import { post } from 'srcPath/http';
import axios from "axios";
import moment from "moment";
import { Collapse, message} from "antd";
import DateSelector from "../../../../../components/DateSelector";
import { DateCustomer } from 'components/DateSelector'
import {formatDate} from "Utils/format/formatDate";
import security from 'stores/security';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import { DeleteOutlined, EditOutlined, FolderOpenOutlined, DatabaseOutlined, HistoryOutlined, DollarCircleOutlined, DiffOutlined } from "@ant-design/icons";

class ModifyOptionGrant extends React.Component{
    constructor(props){
        super(props);
        this.state={
            quantityError:0,
            dateFormat:"DD/MM/YYYY",
            documentActionArr : {'add_document':1,'remove_document':1},
            document_action_type:"",
            is_remove:0,
            is_show_delete_modal:0,
            existedCustomLabelId:0,
            uploadProgress:0,
            uploadingStatus:0,
            isAvailable:-1,
            ableToStep3:0,
            errorKeyArr:[],
            isShowErrorTip:0,
            checkboxStatus:{
                name:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"name",fieldName:__("Name")},
                email:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"email",fieldName:__("Email")},
                custom_label_id:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"custom_label_id",fieldName:__("Custom label")},
                grant_date:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"grant_date",fieldName:__("Grant date")},
                grant_expiration_date:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"grant_expiration_date",fieldName:__("Grant expiration date")},
                board_approval_date:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"board_approval_date",fieldName:__("Board approval date")},
                quantity:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"quantity",fieldName:__("Quantity")},
                currency:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"currency",fieldName:__("Currency")},
                security_plan_id:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"security_plan_id",fieldName:__("Option plan")},
                award_category:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"award_category",fieldName:__("Award category")},
                last_day_to_exercise:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"last_day_to_exercise",fieldName:__("Exercisable date")},
                exercise_price:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"exercise_price",fieldName:__("Exercise price")},

                exercise_legend:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"exercise_legend",fieldName:__("Exercise legend")},
                incentive_plan_file:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"incentive_plan_file",fieldName:__("Equity incentive plan")},
                form_of_exercise_file:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"form_of_exercise_file",fieldName:__("Form of exercise")},
                form_of_option_file:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"form_of_option_file",fieldName:__("Grant letter")},
                additional_documents:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"additional_documents",fieldName:__("Add more additional documents")},
                add_document:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"add_document",fieldName:__("Add more additional documents")},
                remove_document:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"remove_document",fieldName:__("Remove all additional documents")},
                notes:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"notes",fieldName:__("Notes")},
                voluntary_termination_count:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"voluntary_termination_count",fieldName:__("Voluntary termination count")},
                voluntary_termination_period:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"voluntary_termination_period",fieldName:__("Voluntary termination period")},
                involuntary_termination_count:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"involuntary_termination_count",fieldName:__("Involuntary termination count")},
                involuntary_termination_period:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"involuntary_termination_period",fieldName:__("Involuntary termination period")},
                involuntary_termination_cause_count:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"involuntary_termination_cause_count",fieldName:__("Involuntary termination cause count")},
                involuntary_termination_cause_period:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"involuntary_termination_cause_period",fieldName:__("Involuntary termination cause period")},
                death_exercise_count:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"death_exercise_count",fieldName:__("Death exercise count")},
                death_exercise_period:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"death_exercise_period",fieldName:__("Death exercise period")},
                disability_exercise_count:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"disability_exercise_count",fieldName:__("Disability exercise count")},
                disability_exercise_period:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"disability_exercise_period",fieldName:__("Disability exercise period")},
                retirement_exercise_count:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"retirement_exercise_count",fieldName:__("Retirement exercise count")},
                retirement_exercise_period:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"retirement_exercise_period",fieldName:__("Retirement exercise period")},
                iso_disqualification_date:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"iso_disqualification_date",fieldName:__("ISO disqualification date")},
                national_exemption:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"national_exemption",fieldName:__("National exemption")},
                local_exemption:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"local_exemption",fieldName:__("Local exemption")},
            },

            checkExerciseHistory:[],
            formatExerciseHistoryList:{},
            currencyList:['USD','HKD','SGD',],
            modifyReason:[
                "Clerical error",
                "Amendment"
            ],
            periodList:["","Days","Months","Years",],
            data:{
                modify_id:"Option",
                reason:"",
                note:"",
                basicTemplateData:
                    {
                        name:"",
                        email:"",
                        custom_label_id:"",
                        grant_date:"",
                        grant_expiration_date:"",
                        board_approval_date:"",
                        quantity:"",
                        security_plan_id:"",
                        exercise_price:"",
                        exercise_history:[
                            {id:"",exercise_date:"",quantity:"",custom_label_id:"",exercise_file:{name:"No file selected", file_obj:""}}
                        ],
                        last_day_to_exercise:"",
                        payment_date:"",
                        amount_to_be_collected:0,
                        payment_collected:"",
                        actual_exercise_price:0,
                        payment_method: "",
                        payment_verified_admin_id:"",
                        amount_reimbursed:"",
                        amount_reimbursement_date:"",

                        add_document:{name:__("No file selected"),file_obj:""},
                        form_of_exercise_file:{name:__("No file selected"),file_obj:""},
                        form_of_option_file:{name:__("No file selected"),file_obj:""},
                        incentive_plan_file:{name:__("No file selected"),file_obj:""},
                        remove_document:"",

                        // document:"No file selected",
                        notes:"",
                        voluntary_termination_count:"",
                        voluntary_termination_period:"",
                        involuntary_termination_count:"",
                        involuntary_termination_period:"",
                        involuntary_termination_cause_count:"",
                        involuntary_termination_cause_period:"",
                        death_exercise_count:"",
                        death_exercise_period:"",
                        disability_exercise_count:"",
                        disability_exercise_period:"",
                        retirement_exercise_count:"",
                        retirement_exercise_period:"",
                        iso_disqualification_date:"",
                        national_exemption:"",
                        local_exemption:"",
                        award_category:"",
                    },
                changedKey:[],
            },
            requiredKey:[
                "custom_label_id",
                "grant_date",
                "grant_expiration_date",
                "board_approval_date",
                'last_day_to_exercise',
                "quantity",
                "security_plan_id",
                "exercise_price",
                "add_document",
                "form_of_exercise_file",
                "form_of_option_file",
                "incentive_plan_file",
                "notes",
                "voluntary_termination_period",
                "involuntary_termination_period",
                "involuntary_termination_cause_period",
                "death_exercise_period",
                "disability_exercise_period",
                "retirement_exercise_period",
                "award_category",
            ],
            optionalKey:[
                "voluntary_termination_count",
                "involuntary_termination_count",
                "involuntary_termination_cause_count",
                "death_exercise_count",
                "disability_exercise_count",
                "retirement_exercise_count",
                "iso_disqualification_date",
                "national_exemption",
                "local_exemption",
                "remove_document",
            ],
            exerciseHistoryOptionalKey:[
                "exercise_date",
                "payment_date",
                "payment_method",
                "exercise_file",
                "amount_to_be_collected",
                "payment_collected",
                "actual_exercise_price",
                "payment_verified_admin_id",
                "amount_reimbursed",
                "amount_reimbursement_date",
            ],
            initData: {
                key:0,
                id:2,
                custom_label_id:'',
                name:'',
                status:'',
                quantity:'',
                exercised_quantity:'',
                vested_quantity:"",
                vested:'',
                vested_bar:"",
                exercise_price:'',
                exercise_history:[],
                ea_type:'',
                grant_date:'',
                last_modified:"",
                vesting_schedule:"",
                price_per_share:'',
                transaction_value:'',
                issue_date:'',
                checkbox_status:'',
                sign_status:'',
                custom_label:"",
                grant_expiration_date:"",
                board_approval_date:"",
                currency:"",
                security_plan_id:"",
                notes:"",
                voluntary_termination_count:"",
                voluntary_termination_period:"",
                involuntary_termination_count:"",
                involuntary_termination_period:"",
                involuntary_termination_cause_count:"",
                involuntary_termination_cause_period:"",
                death_exercise_count:"",
                death_exercise_period:"",
                disability_exercise_count:"",
                disability_exercise_period:"",
                retirement_exercise_count:"",
                retirement_exercise_period:"",
                iso_disqualification_date:"",
                national_exemption:"",
                local_exemption:"",
                admin_note:"",
                form_of_grant_file:{name:"",real_name:""},
                form_of_option_file:{name:"",real_name:""},
                incentive_plan_file:{name:"",real_name:""},
                additional_documents:{name:"",real_name:""},
                form_of_exercise_file:{name:"",real_name:""},
                purchase_agreement_file:{name:"",real_name:""},
                form_of_settlement_agreement_file:{name:"",real_name:""},
            },
        }
    }
    submitActionForOption=()=>{
        const { checkExerciseHistory, formatExerciseHistoryList } = this.state
        if(this.state.uploadingStatus ===1){
            return;
        }
        this.setState({
            uploadingStatus:1
        });
        let requestData = {
            is_remove: this.state.is_remove,
            reason:this.state.data.reason,
            modify_note:this.state.data.modify_note,
            modify_id: this.state.initData.id,
        };
        for(let key in this.state.checkboxStatus){
            if(this.state.checkboxStatus[key].checked){
                requestData[key] = this.state.data.basicTemplateData[key];
            }
        }
        if(checkExerciseHistory.length>0){
            let exerciseHistory = []
            let dataKey = [
                "exercise_date",
                "payment_collected",
                "amount_reimbursement_date",
                "payment_verified_admin_id",
                "payment_method",
                "payment_date",
                "amount_reimbursed",
                "exercise_file",
            ]
            for(let i=0; i<checkExerciseHistory.length; i++){
                for(let j =0; j<dataKey.length; j++){
                    if(checkExerciseHistory[i][dataKey[j]+i].checked){
                        exerciseHistory.push(formatExerciseHistoryList[checkExerciseHistory[i].id])
                    }
                }
                // exerciseHistory.push(formatExerciseHistoryList[checkExerciseHistory[i].id])

            }
            requestData.exercise_history = exerciseHistory
        }
        if(this.state.document_action_type==="add_document" && this.state.data.basicTemplateData.add_document.name!==__('No file selected')){
            //这里说明选择新增并且上传了文件
            requestData.document_action_type = 'add_document';
        }else if(this.state.document_action_type==="remove_document"){
            requestData.document_action_type = 'remove_document';
        }else{
            requestData.document_action_type = '';
        }
        axios.post('https://api.getsprout.co', {
            company_id:Utils.getLocalCompanyInfo().company_id,
            action:"modifyOption",
            data:requestData
        },{
            onUploadProgress:(progressEvent)=>{
                let complete = (progressEvent.loaded / progressEvent.total * 100 | 0);
                console.log('上传 ' + complete+'%') ;
                this.setState({
                    uploadProgress:complete
                });
                if(complete == 100){
                    this.setState({
                        isShowUploadProgress:0
                    })
                }
            }
        }).then((response)=> {
            if(response.data.code === 0){
                //这里需要将返回的数据覆盖到主页面
                // for(let key in this.state.checkboxStatus){
                //     if(this.state.checkboxStatus[key].checked){
                //         if(key === "add_document" ||key==="remove_document"){
                //             continue;
                //         }
                //         this.state.initData[key] = this.state.data.basicTemplateData[key];
                //     }
                // }
                // let initData = this.state.initData;
                // initData.status = response.data.data.status;
                // initData.quantity = Utils.resetNumberWithCommas(initData.quantity);
                // if(response.data.data.hasOwnProperty("custom_label_id")){
                //     initData.custom_label_id = response.data.data.custom_label_id;
                // }
                // this.props.setModifiedSecurity(this.state.initData.key,initData);
                this.setState({
                    uploadProgress:0,
                    uploadingStatus:0
                });
                window.$("#modifyOptionsModal").modal("hide");
                this.resetAction();
                this.setState({
                    is_remove:0,
                })
                this.props.getEaList();
            }else{
                // console.log("failed, error code is: "+response.data.code);
                message.error(response.data.data)
            }
        }).catch(function (error) {
            // console.log(error);
            message.error(error && error.message)

        });
    }
    recordData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        });
    }

    /**
     * exercise history data onchange
     * @param e
     */
    recordExerciseData = (key,e) =>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData.basicTemplateData.exercise_history[key][dataKey] = dataVal
        this.setState({
            data:curData
        });
    }

    recordCurData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData.basicTemplateData[dataKey] = dataVal;
        this.setState({
            data:curData
        });
        let documentActionArr = this.state.documentActionArr;
        if(documentActionArr.hasOwnProperty(dataKey)){
            if(curData[dataKey].checked){
                this.setState({document_action_type:dataKey});
                for(var key in documentActionArr){
                    if(key !== dataKey){
                        curData[key].checked = false;
                        curData[key].bg="modify-darkInput"
                        curData[key].disabled="disabled";
                    }
                }
            }
        }
    }
    recordNumData=(e)=>{
        const isFractionalShare = security.companyInfo.fractional_control;
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        if(dataKey=="quantity"||dataKey=="payment_collected"||dataKey=="amount_reimbursed"){
            if(isFractionalShare==1){
                curData.basicTemplateData[dataKey] = Utils.check5DecimalNumInputWithoutComma(dataVal);
            }else{
                curData.basicTemplateData[dataKey] = Utils.checkNumInput(dataVal);
            }
        }else if(dataKey=="exercise_price"){
            curData.basicTemplateData[dataKey] = Utils.check5DecimalNumInputWithoutComma(dataVal);
        }else{
            if(dataVal!=0){
                curData.basicTemplateData[dataKey] =  Utils.checkIntInputWithoutComma(dataVal);
            }else{
                curData.basicTemplateData[dataKey] = 0
            }
        }
        this.setState({
            data:curData
        });
    }
    formatNumData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData.basicTemplateData[dataKey] = formatNumber(dataVal,null);
        this.setState({
            data:curData
        });
    }
    focusNumData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData.basicTemplateData[dataKey] =  Utils.resetNumberWithCommas(dataVal);
        this.setState({
            data:curData
        });
    }

    /**
     * Exercise history num onchange
     * @param e
     */

    recordExerciseNumData=(key,e)=>{
        const isFractionalShare = security.companyInfo.fractional_control;
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        if(isFractionalShare==1){
            curData.basicTemplateData.exercise_history[key][dataKey] = Utils.check5DecimalNumInputWithoutComma(dataVal);
        }else{
            curData.basicTemplateData.exercise_history[key][dataKey] = Utils.checkNumInput(dataVal);
        }
        this.setState({
            data:curData
        });
    }
    formatExerciseNumData=(key, e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData.basicTemplateData.exercise_history[key][dataKey] = formatNumber(dataVal,null);
        this.setState({
            data:curData
        });
    }
    focusExerciseNumData=(key,e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData.basicTemplateData.exercise_history[key][dataKey] =  Utils.resetNumberWithCommas(dataVal);
        this.setState({
            data:curData
        });
    }

    checkedStatus = (e)=>{
        let curChecked = this.state.checkboxStatus;
        let dataKey = e.target.name;
        curChecked[dataKey].checked = !curChecked[dataKey].checked;
        if(curChecked[dataKey].checked){
            curChecked[dataKey].bg="modify-whiteInput";
            curChecked[dataKey].disabled="";
        }else{
            curChecked[dataKey].bg="modify-darkInput"
            curChecked[dataKey].disabled="disabled";
        }
        let documentActionArr = this.state.documentActionArr;
        if(documentActionArr.hasOwnProperty(dataKey)){
            if(curChecked[dataKey].checked){
                this.setState({document_action_type:dataKey});
                for(let key in documentActionArr){
                    if(key !== dataKey){
                        curChecked[key].checked = false;
                        curChecked[key].bg="modify-darkInput"
                        curChecked[key].disabled="disabled";
                    }
                }
            }
        }
        this.setState({
            checkboxStatus:curChecked
        })
    }

    /**
     * onchange of checkbox in exercise history
     * @param e
     */
    exerciseCheckedStatus = (key,e)=>{
        let curChecked = this.state.checkExerciseHistory;
        let dataKey = e.target.name;
        curChecked[key][dataKey].checked = e.target.checked;
        if(curChecked[key][dataKey].checked){
            curChecked[key][dataKey].bg="modify-whiteInput";
            curChecked[key][dataKey].disabled="";
            // if(dataKey.substring(0,13)==='exercise_date'){
            //     curChecked[key]['is_collapse'+key].is_show = 1
            // }
        }else{
            curChecked[key][dataKey].bg="modify-darkInput"
            curChecked[key][dataKey].disabled="disabled";
            // if(dataKey.substring(0,13)==='exercise_date'){
            //     curChecked[key]['is_collapse'+key].is_show = 0
            // }
        }
        this.setState({
            checkExerciseHistory:curChecked
        })
    }

    selectFile=(e)=>{
        let uploadFile = e.target.files[0];
        let dataKey = e.target.name;
        let curData = this.state.data;
        Utils.uploadFile(uploadFile).then((result)=>{
            curData.basicTemplateData[dataKey].name = uploadFile.name;
            curData.basicTemplateData[dataKey].file_obj = result;
            this.setState({
                data:curData
            });
        });
    }

    selectExerciseFile=(key,e)=>{
        let uploadFile = e.target.files[0];
        let dataKey = e.target.name;
        let curData = this.state.data;
        Utils.uploadFile(uploadFile).then((result)=>{
            curData.basicTemplateData.exercise_history[key][dataKey].name = uploadFile.name;
            curData.basicTemplateData.exercise_history[key][dataKey].file_obj = result;
            curData.basicTemplateData.exercise_history[key][dataKey].file_size = uploadFile.size;
            this.setState({
                data:curData
            });
        });
    }

    checkCustomLabelId=()=>{
        const { data } = this.state;
        const { curSelectSecurity } = this.props;
        post('checkCustomLabelId',{custom_label_id:data.basicTemplateData.custom_label_id, security_plan_id: curSelectSecurity.security_plan_id, security_type: curSelectSecurity.security_type}).then((response)=> {
            if(response.data.code === 0){
                this.setState({
                    isAvailable:response.data.data.isAvailable
                }, ()=> this.checkNextAvailabel())
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    setOptionGrantInitInfo=()=>{
        const { curSelectSecurity,formatVerifiedAdminList } = this.props;
        let curData = this.state.data;
        let checkStatusExercise = this.state.checkExerciseHistory;
        let formatHistoryList = this.state.formatExerciseHistoryList;
        if(curSelectSecurity.exercise_history)
        for(let i=0;i<curSelectSecurity.exercise_history.length; i++){
            const exerciseHistoryObj = {
                ['is_collapse'+i]:
                    {checked:false,jsonKey:"is_collapse", is_show: 0 },
                ['exercise_date'+i]:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"exercise_date",fieldName:__("Exercise date")},
                ['payment_date'+i]:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"payment_date",fieldName:__("Payment date")},
                ['amount_to_be_collected'+i]:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"amount_to_be_collected",fieldName:__("Amount to be collected")},
                ['payment_collected'+i]:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"payment_collected",fieldName:__("Payment collected")},
                ['payment_method'+i]:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"payment_method",fieldName:__("Payment method")},
                ['actual_exercise_price'+i]:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"actual_exercise_price",fieldName:__("Actual exercise price")},
                ['payment_verified_admin_id'+i]:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"payment_verified_admin_id",fieldName:__("Payment verified by")},
                ['amount_reimbursed'+i]:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"amount_reimbursed",fieldName:__("Amount reimbursed by company")},
                ['amount_reimbursement_date'+i]:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"amount_reimbursement_date",fieldName:__("Amount reimbursement date")},
                ['exercise_file'+i]:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"exercise_file",fieldName:__("Form of exercise")},
            }
            curData.basicTemplateData.exercise_history[i] = Object.assign({},curSelectSecurity.exercise_history[i])
            curData.basicTemplateData.exercise_history[i].exercise_file = {name:"No file selected", real_name:"",file_size:""}
            exerciseHistoryObj.id = Object.assign({},curSelectSecurity.exercise_history[i]).id
            checkStatusExercise[i] = exerciseHistoryObj
            formatHistoryList[curData.basicTemplateData.exercise_history[i].id] = Object.assign({},curSelectSecurity.exercise_history[i]);

        }
        curData.basicTemplateData.security_plan_id = curSelectSecurity.security_plan_id;
        curData.basicTemplateData.award_category = curSelectSecurity.award_category;
        curData.basicTemplateData.payment_verified_admin_id = curSelectSecurity.payment_verified_admin_id;
        this.setState({
            data: curData,
            checkExerciseHistory: checkStatusExercise,
            initData:curSelectSecurity,
            formatExerciseHistoryList:formatHistoryList
        });

    }
    resetAction=()=>{
        let curData = this.state.data;
        curData.reason="";
        curData.modify_note="";
        curData.modify_id="";
        for(let key in this.state.checkboxStatus){
            if(this.state.checkboxStatus[key].checked){
                if(key=="form_of_exercise_file"||key=="form_of_option_file"||key=="add_document"||key=="incentive_plan_file"){
                    curData.basicTemplateData[key].name=__("No file selected");
                    curData.basicTemplateData[key].file_obj="";
                }else{
                    curData.basicTemplateData[key]="";
                }
                this.state.checkboxStatus[key].checked = false;
                this.state.checkboxStatus[key].disabled = "disabled";
            }
        }
        this.setState({
            data:curData,
            quantityError:0,
            isShowErrorTip:0,
            isAvailable:-1,
        })
        window.$("#modifyOptionsModal .tab-pane").removeClass("active");
        window.$("#modifyOptionReason").addClass("active");
    }
    targetToStep3=()=>{
            this.setState({
                quantityError:0,
                isShowErrorTip:0,
                isAvailable:-1,
            })
            window.$("#modifyOptionsModal .tab-pane").removeClass("active");
            window.$("#reviewOptionModifications").addClass("active");
    }
    formatExerciseList = () =>{
        const { data } = this.state
        let formatHistoryList = this.state.formatExerciseHistoryList
        for(let i=0; i<data.basicTemplateData.exercise_history.length; i++){
            formatHistoryList[data.basicTemplateData.exercise_history[i].id] = Object.assign({},data.basicTemplateData.exercise_history[i]);
        }
        this.setState({
            formatExerciseHistoryList:formatHistoryList
        })

    }
    checkNextAvailabel=()=>{
        this.formatExerciseList();
        let flag =true; //用于验证勾选的checkbox有没有填值
        let isCheckedForRequiredKey = false;//用于验证requiredKey有没有勾选checkbox
        let isCheckedForOptionalKey = false;//用于验证optionalKey有没有勾选checkbox
        let isCheckedForExerciseOptionalKey = false;//用于验证exerciseOptionalKey有没有勾选checkbox
        let curData = this.state.data;
        let curPlan = this.props.formatEaPlanList;
        let checkKey = this.state.requiredKey;
        let curQuantity = Utils.resetNumberWithCommas(curData.basicTemplateData.quantity);
        let availableQuantity = curPlan[curData.basicTemplateData.security_plan_id].available;
        
        for(let i=0;i<this.state.requiredKey.length;i++){
            // console.log(checkKey[i]);
            // if(this.state.checkboxStatus[checkKey[i]]){
            //     console.log(this.state.checkboxStatus[checkKey[i]]);
            // }else{
            //     console.log("---"+checkKey[i]+"----");
            // }//错误调试
            if(this.state.checkboxStatus[checkKey[i]].checked){
                isCheckedForRequiredKey = true;
                if(checkKey[i]=="add_document"||checkKey[i]=="form_of_exercise_file"||checkKey[i]=="form_of_option_file"||checkKey[i]=="incentive_plan_file"){
                    if(!curData.basicTemplateData[checkKey[i]].file_obj){
                        this.state.errorKeyArr.push(checkKey[i]);
                        flag = false;
                    }
                }else if(checkKey[i]=="custom_label_id"){
                    if(!curData.basicTemplateData[checkKey[i]] || this.state.isAvailable==0){
                        flag = false;
                    }
                }else if(checkKey[i]=="quantity"){
                    if(!curData.basicTemplateData[checkKey[i]]){
                        flag = false;
                    }
                    if(curQuantity>availableQuantity){
                        flag = false;
                        this.setState({
                            quantityError:1
                        })
                    }else{
                        this.setState({
                            quantityError:0
                        })
                    }
                }else if(checkKey[i]=="award_category"&&this.refs.awardCategory.value){
                    curData.basicTemplateData["award_category"] = this.refs.awardCategory.value;
                    this.setState({
                        data:curData
                     });
                }else{
                    if(!curData.basicTemplateData[checkKey[i]]){
                        this.state.errorKeyArr.push(checkKey[i]);
                        flag = false;
                    }
                }
            }
        }
        let optionalCheckKey = this.state.optionalKey;
        let optionalExerciseCheckKey = this.state.exerciseHistoryOptionalKey;
        if(!isCheckedForRequiredKey){
            for(let k=0;k<this.state.optionalKey.length;k++){
                if(this.state.checkboxStatus[optionalCheckKey[k]].checked){
                    flag = true;
                    isCheckedForOptionalKey = true;
                    if(!curData.basicTemplateData[optionalCheckKey[k]]){
                        curData.basicTemplateData[optionalCheckKey[k]] = this.state.initData[optionalCheckKey[k]];
                        this.setState({
                                data:curData
                            }
                        )
                    }
                }
            }
            for(let j=0; j<this.state.checkExerciseHistory.length; j++){
                for(let k=0;k<optionalExerciseCheckKey.length;k++){
                    const exerciseName = optionalExerciseCheckKey[k]+j
                    const exerciseLabel = this.state.checkExerciseHistory[j][exerciseName]
                    const exerciseLabelValue = curData.basicTemplateData.exercise_history[j][optionalExerciseCheckKey[k]]
                    if(exerciseLabel.checked){
                        flag = true;
                        isCheckedForExerciseOptionalKey = true;
                        if(!exerciseLabelValue){
                            curData.basicTemplateData.exercise_history[j][optionalExerciseCheckKey[k]] = this.state.initData.exercise_history[j][optionalExerciseCheckKey[k]];
                            this.setState({
                                    data:curData
                                }
                            )
                        }
                    }
                }

            }
        }
        if(!isCheckedForRequiredKey && !isCheckedForOptionalKey && !isCheckedForExerciseOptionalKey){
            flag = false;
        }
        if(flag){
            // //表示所有数据正常，可以开始跳转
            // if(isSelectCheckbox){
            //     this.targetToStep3();
            // }else{
            //     //这里表示没有勾选任何checkbox
            // }
            this.targetToStep3();
        }else{

            if(this.state.isAvailable==0){
                this.setState({
                    isShowErrorTip: 0,
                })
            }else if(!isCheckedForRequiredKey && !isCheckedForOptionalKey && !isCheckedForExerciseOptionalKey){

            }else if(this.state.quantityError==1){

            }else{
                if(this.state.quantityError==0){
                    //开始展示错误信息：勾选了但是没填
                    this.setState({
                        isShowErrorTip: 1,
                    })
                }

            }

        }
    }
    showDeleteModal=()=>{
        this.setState({
            is_show_delete_modal:1,
        })
    }
    closeDeleteModal=()=>{
        this.setState({
            is_show_delete_modal:0,
        })
    }
    deleteAllDoc=()=>{
        this.setState({
            is_remove:1,
        })
    }

    modifyOptionDateChange = (key, moment_key, date) => {
        const {dateFormat } = this.state;
        let curData = this.state.data;
        curData.basicTemplateData[key] = date.format( dateFormat);
        curData.basicTemplateData[moment_key] = date;
        this.setState({
            data:curData
        });
    }
    modifyExerciseDateChange = (key,name,date) => {
        const {data, dateFormat } = this.state;
        let curData = {...data};
        curData.basicTemplateData['exercise_history'][key][name] = date.format( dateFormat);
        this.setState({
            data:curData
        });
    }

    lastDayToExerciseChange = (date) => {
        const { data } = this.state;
        let curData = {...data};
        curData.basicTemplateData.last_day_to_exercise = date ?  date : '';
        this.setState({
            data:curData
        });
    }

    goTemplates=()=>{
        window.$("#modifyOptionsModal").modal("hide");
        this.props.history.push({pathname:'/home/securities/templates',state:{activeKey:'3'}})
    }

    render(){
        const { currency = '',curSelectSecurity, eaPlanList, formatEaPlanList,verifiedAdminList, formatVerifiedAdminList,award_category} = this.props;
        const {initData, data, dateFormat, checkboxStatus, checkExerciseHistory, quantityError, formatExerciseHistoryList } = this.state;
        let modifyKey = this.state.data.modify_id;
        let showOptionSelectCount = 0;
        let isAbleToModifyQuantity = 0;
        if(curSelectSecurity.status!="Exercised"
            && curSelectSecurity.status!="Partially exercised"
            && (curSelectSecurity.vested_quantity == 0 || curSelectSecurity.vested_quantity==curSelectSecurity.quantity)
            && curSelectSecurity.vesting_schedule_id == 0){
            isAbleToModifyQuantity = 1;
        }
        const { mul,division } = calculator;
        let actualExercisePrice = "";
        if(curSelectSecurity.exercised_quantity && curSelectSecurity.exercised_quantity!=0){
             actualExercisePrice = formatCurrencyNumber(division(Utils.resetNumberWithCommas(curSelectSecurity.payment_collected), Utils.resetNumberWithCommas(curSelectSecurity.exercised_quantity)));
        }
        let amountCollected = formatFractionalNumber(mul(Utils.resetNumberWithCommas(curSelectSecurity.exercised_quantity), Utils.resetNumberWithCommas(curSelectSecurity.exercise_price)));

        const expirationDateLongDesc = {'-1': __('No expiration date')};

        const disableCheck = curSelectSecurity.termination_date == "" ? "disabled-checkbox":""

        return(
            <div className="modal fade" id="modifyOptionsModal" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="modal-dialog dialog960 dialog-top40">
                    <div className="tab-content">
                        <div role="tabpanel" className="modal-content content720 tab-pane active" id="modifyOptionReason">
                            <div className="modal-header box-header">
                                <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.resetAction}>×</button>
                                <h4 className="modal-title" id="myModalLabel">{__('Modify options')}</h4>
                            </div>
                            <div className="modal-body padding2030">
                                <div className="shareTitleBox">
                                    <div className="iconBox bg-lightgreen">
                                        <EditOutlined />
                                    </div>
                                    <div className="shareTextBox">
                                        <p className="shareTitle">{__('Select a reason for modifying the selected option')}</p>
                                        <p className="text-muted">{__('Some modifications will trigger an email to the stakeholder. You can review the modifications and email notifications before saving your changes.')}</p>
                                    </div>
                                </div>
                                <div className="padding2026026">
                                    <div className="form-group add-items">
                                        <div className="row  profile-row">
                                            <label className="col-xs-5 add-label">{__('Reason')}<br/><i className="text-muted"><small>- {__('Required')}</small></i></label>
                                            <div className="col-xs-4">
                                                <select name="reason"  value={this.state.data.reason} onChange={this.recordData} className="form-control select-container">
                                                    <option value="">---</option>
                                                    {this.state.modifyReason.map((value,key)=>{
                                                        return(
                                                            <option key={key}>{value}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group add-items">
                                        <div className="row  profile-row">
                                            <label className="col-xs-5 add-label">{__('Note')}</label>
                                            <div className="col-xs-6">
                                                <textarea name="note" onChange={this.recordData} value={this.state.data.note} className="form-control text-area minHeight100" type="text"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div role="presentation" className="modal-footer box-footer no-paddingtop">
                                {this.state.data.reason==""&&
                                <a className="btn boxbtn-to send-disabledBtn">{__('Next: modify value')}</a>
                                }
                                {this.state.data.reason!=""&&
                                <a href="#modifyOptionValue"  onClick={this.setOptionGrantInitInfo} role="tab" data-toggle="tab" className="btn boxbtn-to bg-darkblue">{__('Next: modify value')}</a>
                                }
                            </div>
                        </div>
                        <div role="tabpanel" className="modal-content content900 tab-pane" id="modifyOptionValue">
                            <div className="modal-header box-header">
                                <a href="#modifyOptionReason"  aria-controls="modifyOptionReason" role="tab" data-toggle="tab" className="back-btn"></a>
                                <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.resetAction}>×</button>
                                <h4 className="modal-title" id="myModalLabel">{__('Modify options')} {this.state.initData.custom_label_id}</h4>
                            </div>
                            <div className="modal-body padding2030">
                                {this.state.isShowErrorTip == 1 && quantityError ==0 &&
                                <div className="alert alert-warning alert-dismissible pink-box">
                                    <p >{__('The required field(s) can not be empty.')}</p>
                                    {/*{this.state.errorKeyArr.map((value,key)=>{*/}
                                    {/*    return(*/}
                                    {/*        <p className="text-left" key={key}>{this.state.checkboxStatus[value].fieldName}: This field is required.</p>*/}
                                    {/*    )*/}
                                    {/*})}*/}
                                </div>
                                }
                                {quantityError == 1 &&
                                <div  className="alert alert-warning alert-dismissible pink-box">
                                    <p>{__('Quantity cannot exceed the available quantity.')}</p>
                                </div>
                                }
                                {this.state.isAvailable == 0 &&
                                <div  className="alert alert-warning alert-dismissible pink-box">
                                    <p>{__('Custom label already exists. Please select another.')}</p>
                                </div>
                                }
                                <p className="text-muted marginBott10">{__('Select the fields you would like to change and enter the new information.')}</p>
                                <div className="modify-content">
                                    <div className="shareTitleBox">
                                        <div className="iconBox bg-lightgreen">
                                            <DatabaseOutlined />
                                        </div>
                                        <div className="shareTextBox">
                                            <p className="shareTitle">{__('Grant details')}</p>
                                            <p className="text-muted">{__('Update information included in the grant agreements such as option plan, quantity and grant date.')}</p>
                                        </div>
                                    </div>
                                    <table className="table modify-table modify-value-table">
                                        <tbody>
                                        <tr className="modify-firstRow">
                                            <td>{__('Field')}</td>
                                            <td>{__('Value')}</td>
                                            <td>{__('New value')}</td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="grantDetailsCheck2" type="checkbox" name="custom_label_id" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.custom_label_id.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="grantDetailsCheck2"></label>
                                                    <span className="modify-label text-muted">{__('Custom label')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.custom_label_id}</td>
                                            <td><input type="text" className="form-control " name="custom_label_id" value={this.state.data.basicTemplateData.custom_label_id} disabled={this.state.checkboxStatus.custom_label_id.disabled} onChange={this.recordCurData} /></td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="grantDetailsCheck3" type="checkbox" name="grant_date" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.grant_date.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="grantDetailsCheck3"></label>
                                                    <span className="modify-label text-muted">{__('Grant date')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.grant_date=="" ? "" : formatDate(curSelectSecurity.grant_date)}</td>
                                            <td>
                                                <DateSelector
                                                    disabled={checkboxStatus.grant_date.disabled}
                                                    onChange={this.modifyOptionDateChange.bind(this, 'grant_date', 'grant_date_moment')}
                                                    value={I(data.basicTemplateData['grant_date_moment'], I(data.basicTemplateData['grant_date'], '') ? moment(data.basicTemplateData['grant_date'], dateFormat) : '')}
                                                    className="datepicker_opacity"
                                                />
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="grantDetailsCheck4" type="checkbox" name="grant_expiration_date" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.grant_expiration_date.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="grantDetailsCheck4"></label>
                                                    <span className="modify-label text-muted">{__('Grant expiration date')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.grant_expiration_date=="" ? "" : formatDate(curSelectSecurity.grant_expiration_date)}</td>
                                            <td>
                                                <DateSelector
                                                    disabled={checkboxStatus.grant_expiration_date.disabled}
                                                    onChange={this.modifyOptionDateChange.bind(this, 'grant_expiration_date', 'grant_expiration_date_moment')}
                                                    value={I(data.basicTemplateData['grant_expiration_date_moment'], I(data.basicTemplateData['grant_expiration_date'], '') ? moment(data.basicTemplateData['grant_expiration_date'], dateFormat) : '')}
                                                    className="datepicker_opacity"
                                                />
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="grantDetailsCheck5" type="checkbox" name="board_approval_date" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.board_approval_date.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="grantDetailsCheck5"></label>
                                                    <span className="modify-label text-muted">{__('Board approval date')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.board_approval_date=="" ? "" : formatDate(curSelectSecurity.board_approval_date)}</td>
                                            <td>
                                                <DateSelector
                                                    disabled={checkboxStatus.board_approval_date.disabled}
                                                    onChange={this.modifyOptionDateChange.bind(this, 'board_approval_date', 'board_approval_date_moment')}
                                                    value={I(data.basicTemplateData['board_approval_date_moment'], I(data.basicTemplateData['board_approval_date'], '') ? moment(data.basicTemplateData['board_approval_date'], dateFormat) : '')}
                                                    className="datepicker_opacity"
                                                />
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <a className="convertible-ques top2">
                                                        <p className="convertible-tiptext modify-tiptext106">{__('Quantity cannot be modified for awards that are exercised, amended, or have custom vesting.')}</p>
                                                    </a>
                                                    {isAbleToModifyQuantity==1 &&
                                                    <input id="grantDetailsCheck6" type="checkbox" name="quantity" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.quantity.checked} onChange={this.checkedStatus}/>
                                                    }
                                                    {isAbleToModifyQuantity==0 &&
                                                    <input type="checkbox" name="quantity" className="re-checkbox modify-checkbox disabled-checkbox" disabled={true}/>
                                                    }
                                                    <label htmlFor="grantDetailsCheck6"></label>
                                                    <span className="modify-label text-muted">{__('Quantity')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{formatFractionalNumber(curSelectSecurity.quantity)}</td>
                                            <td><input type="text" className="form-control " name="quantity" value={this.state.data.basicTemplateData.quantity} disabled={this.state.checkboxStatus.quantity.disabled} onBlur={this.formatNumData} onFocus={this.focusNumData} onChange={this.recordNumData} /></td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="grantDetailsCheck8" type="checkbox" name="security_plan_id" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.security_plan_id.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="grantDetailsCheck8"></label>
                                                    <span className="modify-label text-muted">{__('Option plan')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.option_plan}</td>
                                            <td>
                                                <select type="text" className="form-control select-container modify-select" name="security_plan_id" value={this.state.data.basicTemplateData.security_plan_id} disabled={this.state.checkboxStatus.security_plan_id.disabled} onChange={this.recordCurData} >
                                                    {eaPlanList.map((value,key)=>{
                                                        return(
                                                            <option value={value.id} key={key}>{value.plan_name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <a className="convertible-ques top2">
                                                        <p className="convertible-tiptext modify-tiptext124">{__('Award category is an optional field that can be use to further organize and categorize option awards. Set award categories from the')}<span className="text-blue" onClick={this.goTemplates}>{__('Award category page.')}</span></p>
                                                    </a>
                                                    <input id="grantDetailsCheck9" type="checkbox" name="award_category" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.award_category.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="grantDetailsCheck9"></label>
                                                    <span className="modify-label text-muted">{__('Award category')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.award_category}</td>
                                            <td>
                                                <select type="text" className="form-control select-container modify-select" ref="awardCategory" name="award_category" value={this.state.data.basicTemplateData.award_category} disabled={this.state.checkboxStatus.award_category.disabled} onChange={this.recordCurData} >
                                                    {award_category&&award_category.map((value,key)=>{
                                                        return(
                                                            <option value={value.name} key={value.id}>{value.name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="grantDetailsCheck10" type="checkbox" name="last_day_to_exercise" className={"re-checkbox modify-checkbox "+disableCheck} checked={this.state.checkboxStatus.last_day_to_exercise.checked} onChange={this.checkedStatus} disabled={curSelectSecurity.termination_date==""? true : false}/>
                                                    <label htmlFor="grantDetailsCheck10"></label>
                                                    <span className="modify-label text-muted">{__('Exercisable date')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.last_day_to_exercise =="" ? "" :(curSelectSecurity.last_day_to_exercise==-1? "No expiration date" : formatDate(curSelectSecurity.last_day_to_exercise))}</td>
                                            <td>
                                                <DateCustomer
                                                    customerValue={-1}
                                                    disabled={checkboxStatus.last_day_to_exercise.disabled}
                                                    onChange={this.lastDayToExerciseChange}
                                                    footerText={expirationDateLongDesc['-1']}
                                                />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="modify-content">
                                    <div className="shareTitleBox">
                                        <div className="iconBox bg-lightgreen">
                                            <DatabaseOutlined />
                                        </div>
                                        <div className="shareTextBox">
                                            <p className="shareTitle ">{__('Exercise details')}</p>
                                            <p className="text-muted">{__('Update information for exercises.')}</p>
                                        </div>
                                    </div>
                                    <table className="table modify-table modify-value-table">
                                        <tbody>
                                        <tr className="modify-firstRow">
                                            <td>{__('Field')}</td>
                                            <td>{__('Value')}</td>
                                            <td>{__('New value')}</td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td className="modify-historyTd">
                                                <div className="check-box">
                                                    <input id="historyCheck0" type="checkbox" name="exercise_price" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.exercise_price.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="historyCheck0"></label>
                                                    <span className="modify-label text-muted">{__('Exercise price')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{formatCurrencyNumber(curSelectSecurity.exercise_price)}</td>
                                            <td>
                                                <div className="input-group"><span className="input-group-addon">{currency}</span>
                                                    <input type="text" className={"form-control "} name="exercise_price" value={this.state.data.basicTemplateData.exercise_price} disabled={this.state.checkboxStatus.exercise_price.disabled} onBlur={this.formatNumData} onFocus={this.focusNumData} onChange={this.recordNumData} />
                                                </div>
                                            </td>
                                        </tr>
                                        {curSelectSecurity.exercise_history && checkExerciseHistory.length>0 && curSelectSecurity.exercise_history.length > 0 && curSelectSecurity.exercise_history.map((v,k)=>{
                                            const { Panel } = Collapse;
                                            const exerciseDetails = <tr>
                                                <td colSpan={3} className="no_padding">
                                                    <table className="table modify-table modify-value-table no_margin">
                                                        <tbody>
                                                        <tr className="modify-contentRow exercise_content_first_tr" >
                                                            <td>
                                                                <div className="check-box exercise_date_list">
                                                                    <input id={"exercise_date_check"+k} type="checkbox" name={"exercise_date"+k}
                                                                           className="re-checkbox modify-checkbox "
                                                                           checked={checkExerciseHistory[k]['exercise_date'+k].checked}
                                                                           onChange={this.exerciseCheckedStatus.bind(this,k)}/>
                                                                    <label htmlFor={"exercise_date_check"+k}></label>
                                                                    <span className="modify-label text-muted">{__('Exercise date')}</span>
                                                                </div>
                                                            </td>
                                                            <td className={"mid_column"}>
                                                                <p className="no-marginBottom">{v.exercise_date == "" ? "" : formatDate(v.exercise_date)}</p>
                                                            </td>
                                                            <td>
                                                                <DateSelector
                                                                    key={k}
                                                                    disabled={checkExerciseHistory[k]['exercise_date'+k].disabled}
                                                                    onChange={this.modifyExerciseDateChange.bind(this,k,'exercise_date')}
                                                                    // value={I(v.exercise_date, '') ? moment(v.exercise_date, dateFormat) : ''}
                                                                    value={I( I(data.basicTemplateData.exercise_history[k]['exercise_date'], '') ? moment(data.basicTemplateData.exercise_history[k]['exercise_date'], dateFormat) : '')}
                                                                    className="datepicker_opacity exercise_date_selector"
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr className="modify-contentRow">
                                                            <td>
                                                                <div className="check-box">
                                                                    <input id={"payment_date_check_"+k} type="checkbox" name={"payment_date"+k} className="re-checkbox modify-checkbox " checked={checkExerciseHistory[k]['payment_date'+k].checked} onChange={this.exerciseCheckedStatus.bind(this,k)}/>
                                                                    <label htmlFor={"payment_date_check_"+k}></label>
                                                                    <span className="modify-label text-muted">{__('Payment date')}</span>
                                                                </div>
                                                            </td>
                                                            <td className={"mid_column"}>{(curSelectSecurity.exercise_history[k].payment_date==""||null) ? "" : formatDate(curSelectSecurity.exercise_history[k].payment_date)}</td>
                                                            <td>
                                                                <DateSelector
                                                                    disabled={checkExerciseHistory[k]['payment_date'+k].disabled}
                                                                    onChange={this.modifyExerciseDateChange.bind(this, k, 'payment_date')}
                                                                    value={I( I(data.basicTemplateData.exercise_history[k]['payment_date'], '') ? moment(data.basicTemplateData.exercise_history[k]['payment_date'], dateFormat) : '')}
                                                                    className="datepicker_opacity"
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr className="modify-contentRow">
                                                            <td>
                                                                <div className="check-box">
                                                                    <input id={"payment_collected_check_"+k} type="checkbox" name={"payment_collected"+k} className="re-checkbox modify-checkbox " checked={checkExerciseHistory[k]['payment_collected'+k].checked} onChange={this.exerciseCheckedStatus.bind(this,k)}/>
                                                                    <label htmlFor={"payment_collected_check_"+k}></label>
                                                                    <span className="modify-label text-muted">{__('Payment collected')}</span>
                                                                </div>
                                                            </td>
                                                            <td className={"mid_column"}>{curSelectSecurity.exercise_history[k].payment_collected}</td>
                                                            <td>
                                                                <div className="input-group"><span className="input-group-addon">{currency}</span>
                                                                    <input type="text" className="form-control " name="payment_collected" value={this.state.data.basicTemplateData.exercise_history[k].payment_collected} disabled={checkExerciseHistory[k]['payment_collected'+k].disabled} onBlur={this.formatExerciseNumData.bind(this,k)} onFocus={this.focusExerciseNumData.bind(this,k)} onChange={this.recordExerciseNumData.bind(this,k)} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="modify-contentRow">
                                                            <td>
                                                                <div className="check-box">
                                                                    <input id={"payment_method_check_"+k} type="checkbox" name={"payment_method"+k} className="re-checkbox modify-checkbox " checked={checkExerciseHistory[k]['payment_method'+k].checked} onChange={this.exerciseCheckedStatus.bind(this,k)}/>
                                                                    <label htmlFor={"payment_method_check_"+k}></label>
                                                                    <span className="modify-label text-muted">{__('Payment method')}</span>
                                                                </div>
                                                            </td>
                                                            <td className={"mid_column"}>{(curSelectSecurity.exercise_history[k].payment_method && curSelectSecurity.exercise_history[k].payment_method!= "") && curSelectSecurity.exercise_history[k].payment_method}</td>
                                                            <td>
                                                                <select type="text" className="form-control select-container modify-select" name="payment_method" value={this.state.data.basicTemplateData.exercise_history[k].payment_method} disabled={checkExerciseHistory[k]['payment_method'+k].disabled} onChange={this.recordExerciseData.bind(this,k)} >
                                                                    <option value={""}> -- </option>
                                                                    {paymentMethod.map((val,key)=>{
                                                                        return(
                                                                            <option value={val} key={key}>{val}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr className="modify-contentRow">
                                                            <td>
                                                                <div className="check-box">
                                                                    <input id={"payment_verified_admin_id_check_"+k} type="checkbox" name={"payment_verified_admin_id"+k} className="re-checkbox modify-checkbox " checked={checkExerciseHistory[k]['payment_verified_admin_id'+k].checked} onChange={this.exerciseCheckedStatus.bind(this,k)}/>
                                                                    <label htmlFor={"payment_verified_admin_id_check_"+k}></label>
                                                                    <span className="modify-label text-muted">{__('Payment verified by')}</span>
                                                                </div>
                                                            </td>
                                                            <td className={"mid_column"}>{(curSelectSecurity.exercise_history[k].payment_verified_admin_id!=null&& curSelectSecurity.exercise_history[k].payment_verified_admin_id!=0) && formatVerifiedAdminList[curSelectSecurity.exercise_history[k].payment_verified_admin_id].admin_name}</td>
                                                            <td>
                                                                <select type="text" className="form-control select-container modify-select" name="payment_verified_admin_id" value={this.state.data.basicTemplateData.exercise_history[k].payment_verified_admin_id} disabled={checkExerciseHistory[k]['payment_verified_admin_id'+k].disabled} onChange={this.recordExerciseData.bind(this,k)} >
                                                                    <option value={""}> -- </option>
                                                                    {verifiedAdminList && verifiedAdminList.map((val,key)=>{
                                                                        return(
                                                                            <option value={val.admin_id} key={key}>{val.admin_name}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr className="modify-contentRow">
                                                            <td>
                                                                <div className="check-box">
                                                                    <input id={"amount_reimbursed_check_"+k} type="checkbox" name={"amount_reimbursed"+k} className="re-checkbox modify-checkbox " checked={checkExerciseHistory[k]['amount_reimbursed'+k].checked} onChange={this.exerciseCheckedStatus.bind(this,k)}/>
                                                                    <label htmlFor={"amount_reimbursed_check_"+k}></label>
                                                                    <span className="modify-label text-muted">{__('Amount reimbursed by company')}</span>
                                                                </div>
                                                            </td>
                                                            <td className={"mid_column"}>{curSelectSecurity.exercise_history[k].amount_reimbursed}</td>
                                                            <td>
                                                                <div className="input-group"><span className="input-group-addon">{currency}</span>
                                                                    <input type="text" className="form-control " name="amount_reimbursed" value={this.state.data.basicTemplateData.exercise_history[k].amount_reimbursed} disabled={checkExerciseHistory[k]['amount_reimbursed'+k].disabled} onBlur={this.formatExerciseNumData.bind(this,k)} onFocus={this.focusExerciseNumData.bind(this,k)} onChange={this.recordExerciseNumData.bind(this,k)} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="modify-contentRow">
                                                            <td>
                                                                <div className="check-box">
                                                                    <input id={"amount_reimbursement_date_check"+k} type="checkbox" name={"amount_reimbursement_date"+k} className="re-checkbox modify-checkbox " checked={checkExerciseHistory[k]['amount_reimbursement_date'+k].checked} onChange={this.exerciseCheckedStatus.bind(this,k)}/>
                                                                    <label htmlFor={"amount_reimbursement_date_check"+k}></label>
                                                                    <span className="modify-label text-muted">{__('Amount reimbursement Date')}</span>
                                                                </div>
                                                            </td>
                                                            <td className={"mid_column"}>{curSelectSecurity.exercise_history[k].amount_reimbursement_date=="" ? "" : formatDate(curSelectSecurity.exercise_history[k].amount_reimbursement_date)}</td>
                                                            <td>
                                                                <DateSelector
                                                                    disabled={checkExerciseHistory[k]['amount_reimbursement_date'+k].disabled}
                                                                    onChange={this.modifyExerciseDateChange.bind(this, k, 'amount_reimbursement_date')}
                                                                    value={I( I(data.basicTemplateData.exercise_history[k]['amount_reimbursement_date'], '') ? moment(data.basicTemplateData.exercise_history[k]['amount_reimbursement_date'], dateFormat) : '')}
                                                                    className="datepicker_opacity"
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr className="modify-contentRow ">
                                                            <td>
                                                                <div className="check-box">
                                                                    <input id={"exercise_file_check"+k} type="checkbox" name={"exercise_file"+k} className="re-checkbox modify-checkbox" checked={checkExerciseHistory[k]['exercise_file'+k].checked} onChange={this.exerciseCheckedStatus.bind(this,k)}/>
                                                                    <label htmlFor={"exercise_file_check"+k}></label>
                                                                    <span className="modify-label text-muted">{__('Form of exercise')}</span>
                                                                </div>
                                                            </td>
                                                            <td className={"mid_column"}><p className='file-con'>{curSelectSecurity.exercise_history[k].exercise_file?.name}</p></td>
                                                            <td className="fileTd ">
                                                                <div className="select-btn select-blue lib-choose marginRig10">{__('Choose a file')}
                                                                    <label htmlFor="exercise_file" className="sr-only" >File input</label>
                                                                    <input name="exercise_file"  disabled={checkExerciseHistory[k]['exercise_file'+k].disabled} type="file"  id="exercise_file"  onChange={this.selectExerciseFile.bind(this,k)} />
                                                                </div>
                                                                <div className="filename-box">
                                                                    <span className="close files-del hidden">x</span>
                                                                    <p className="text-muted">{data.basicTemplateData.exercise_history[k].exercise_file.name||__("No file selected")}</p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            const exerciseHeader = <tr>
                                                <td colSpan={3} className="text-muted">{__('Exercise details for')} <span
                                                    className="text-blue">{v.custom_label_id ? v.custom_label_id : "--"}</span>, <span
                                                    className="text-blue">{formatFractionalNumber(v.quantity)}</span> {__('shares')}</td>
                                            </tr>
                                            return(
                                                <tr className={'exercise_history_root'} key={k}>
                                                    <td colSpan={3} className={'exercise_history_item'}>
                                                        <Collapse bordered={false}>
                                                            <Panel key={k} header={ exerciseHeader } >
                                                                { exerciseDetails }
                                                            </Panel>
                                                        </Collapse>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="modify-content">
                                    <div className="shareTitleBox">
                                        <div className="iconBox bg-lightgreen">
                                            <FolderOpenOutlined />
                                        </div>
                                        <div className="shareTextBox">
                                            <p className="shareTitle ">{__('Documents and notes')}</p>
                                            <p className="text-muted ">{__('Update all attachment types and notes available to holder.')}</p>
                                        </div>
                                    </div>
                                    {this.state.is_show_delete_modal==1 &&
                                    <div className="deleteModalBox">
                                        <DeleteDoc closeDeleteModal={this.closeDeleteModal} deleteAllDoc={this.deleteAllDoc}  modifyKey={this.state.data.modify_id}   />
                                    </div>
                                    }
                                    <table className="table modify-table modify-value-table">
                                        <tbody>
                                        <tr className="modify-firstRow">
                                            <td>{__('Field')}</td>
                                            <td>{__('Value')}</td>
                                            <td>{__('New value')}</td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td>
                                                <div className="check-box">
                                                    <input id="option_docCheck0" type="checkbox" name="incentive_plan_file" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.incentive_plan_file.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="option_docCheck0"></label>
                                                    <span className="modify-label text-muted">{__('Equity incentive plan')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}><p className='file-con'>{curSelectSecurity.incentive_plan_file?.name}</p></td>
                                            <td className="fileTd ">
                                                <div className="select-btn select-blue lib-choose marginRig10">
                                                    {__('Choose a file')}
                                                    <label htmlFor="equity_incentive_plan" className="sr-only" >File input</label>
                                                    <input name="incentive_plan_file"  disabled={this.state.checkboxStatus.incentive_plan_file.disabled} type="file"  id="equity_incentive_plan"  onChange={this.selectFile} />
                                                </div>
                                                <div className="filename-box">
                                                    <span className="close files-del hidden">x</span>
                                                    <span className="text-muted">{this.state.data.basicTemplateData.incentive_plan_file.name}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td>
                                                <div className="check-box">
                                                    <input id="option_docCheck1" type="checkbox" name="form_of_exercise_file" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.form_of_exercise_file.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="option_docCheck1"></label>
                                                    <span className="modify-label text-muted">{__('Form of exercise')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}><p className='file-con'>{curSelectSecurity.form_of_exercise_file?.name}</p></td>
                                            <td className="fileTd ">
                                                <div className="select-btn select-blue lib-choose marginRig10">
                                                    {__('Choose a file')}
                                                    <label htmlFor="form_of_exercise" className="sr-only" >File input</label>
                                                    <input name="form_of_exercise_file"  disabled={this.state.checkboxStatus.form_of_exercise_file.disabled} type="file"  id="form_of_exercise"  onChange={this.selectFile} />
                                                </div>
                                                <div className="filename-box">
                                                    <span className="close files-del hidden">x</span>
                                                    <p className="text-muted">{this.state.data.basicTemplateData.form_of_exercise_file.name}</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td>
                                                <div className="check-box">
                                                    <input id="option_docCheck2" type="checkbox" name="form_of_option_file" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.form_of_option_file.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="option_docCheck2"></label>
                                                    <span className="modify-label text-muted">{__('Grant letter')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}><p className='file-con'>{curSelectSecurity.form_of_option_file?.name}</p></td>
                                            <td className="fileTd ">
                                                <div className="select-btn select-blue lib-choose marginRig10">
                                                    {__('Choose a file')}
                                                    <label htmlFor="form_of_option" className="sr-only" >File input</label>
                                                    <input name="form_of_option_file"  disabled={this.state.checkboxStatus.form_of_option_file.disabled} type="file"  id="form_of_option"  onChange={this.selectFile} />
                                                </div>
                                                <div className="filename-box">
                                                    <span className="close files-del hidden">x</span>
                                                    <p className="text-muted">{this.state.data.basicTemplateData.form_of_option_file.name}</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td>
                                                <div className="check-box">
                                                    <input id="documentsCheck0" type="checkbox" name="add_document" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.add_document.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="documentsCheck0"></label>
                                                    <span className="modify-label text-muted">{__('Add more additional documents')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{}</td>
                                            <td className="fileTd">
                                                <div className="select-btn select-blue marginRig10">
                                                    {__('Choose a file')}
                                                    <label htmlFor="add_doc_option" className="sr-only" >File input</label>
                                                    <input name="add_document"  ref="add_document" disabled={this.state.checkboxStatus.add_document.disabled} type="file"  id="add_doc_option"  onChange={this.selectFile} />
                                                </div>
                                                <div className="filename-box">
                                                    <span className="close files-del hidden">x</span>
                                                    <p className="text-muted">{this.state.data.basicTemplateData.add_document.name}</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td>
                                                <div className="check-box">
                                                    <input id="option_docCheck5" name="remove_document" type="checkbox" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.remove_document.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="option_docCheck5"></label>
                                                    <span className="modify-label text-muted">{__('Remove all additional documents')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}></td>
                                            {this.state.checkboxStatus.remove_document.checked===false &&
                                            <td>
                                                <div type="text" name="remove_document" className={"form-control "}  disabled={this.state.checkboxStatus.remove_document.disabled} >
                                                    <button type="button" ><DeleteOutlined style={{cursor:'not-allowed'}}/></button>
                                                </div>
                                            </td>
                                            }
                                            {this.state.checkboxStatus.remove_document.checked===true &&
                                            <td>
                                                <div type="text" name="remove_document" className={"form-control "}  disabled={this.state.checkboxStatus.remove_document.disabled} >
                                                    <button onClick={this.showDeleteModal} type="button" ><DeleteOutlined /></button>
                                                </div>
                                            </td>
                                            }
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td>
                                                <div className="check-box">
                                                    <input id="option_docCheck6" type="checkbox" name="notes" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.notes.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="option_docCheck6"></label>
                                                    <span className="modify-label text-muted">{__('Notes')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.notes}</td>
                                            <td>
                                                <textarea type="text" className={"form-control "} name="notes" value={this.state.data.basicTemplateData.notes} disabled={this.state.checkboxStatus.notes.disabled} onChange={this.recordCurData} />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="modify-content">
                                    <div className="shareTitleBox">
                                        <div className="iconBox bg-lightgreen">
                                            <HistoryOutlined />
                                        </div>
                                        <div className="shareTextBox">
                                            <p className="shareTitle ">{__('Post-termination exercise periods')}</p>
                                            <p className="text-muted">{__('Update the period avaiable for exercises based on termination type.')}</p>
                                        </div>
                                    </div>
                                    <table className="table modify-table modify-value-table">
                                        <tbody>
                                        <tr className="modify-firstRow">
                                            <td>{__('Field')}</td>
                                            <td>{__('Value')}</td>
                                            <td>{__('New value')}</td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td className="modify-historyTd">
                                                <div className="check-box">
                                                    <input id="postCheck0" type="checkbox" name="voluntary_termination_count" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.voluntary_termination_count.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="postCheck0"></label>
                                                    <span className="modify-label text-muted">{__('Voluntary termination count ')}<i className="text-muted"> - Optional</i> </span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.voluntary_termination_count}</td>
                                            <td><input type="text" className={"form-control "} name="voluntary_termination_count" value={this.state.data.basicTemplateData.voluntary_termination_count} disabled={this.state.checkboxStatus.voluntary_termination_count.disabled} onBlur={this.formatNumData} onFocus={this.focusNumData} onChange={this.recordNumData} /></td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="postCheck1" type="checkbox" name="voluntary_termination_period" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.voluntary_termination_period.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="postCheck1"></label>
                                                    <span className="modify-label text-muted">{__('Voluntary termination period')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.voluntary_termination_period}</td>
                                            <td>
                                                <select type="text" className="form-control select-container modify-select" name="voluntary_termination_period" value={this.state.data.basicTemplateData.voluntary_termination_period} disabled={this.state.checkboxStatus.voluntary_termination_period.disabled} onChange={this.recordCurData} >
                                                    {this.state.periodList.map((value,key)=>{
                                                        return(
                                                            <option key={key}>{value}</option>
                                                        )
                                                    })}
                                                </select>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td className="modify-historyTd">
                                                <div className="check-box">
                                                    <input id="postCheck2" type="checkbox" name="involuntary_termination_count" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.involuntary_termination_count.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="postCheck2"></label>
                                                    <span className="modify-label text-muted">{__('Involuntary termination count ')}<i className="text-muted"> - Optional</i> </span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.involuntary_termination_count}</td>
                                            <td><input type="text" className={"form-control "} name="involuntary_termination_count" value={this.state.data.basicTemplateData.involuntary_termination_count} disabled={this.state.checkboxStatus.involuntary_termination_count.disabled} onBlur={this.formatNumData} onFocus={this.focusNumData} onChange={this.recordNumData} /></td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="postCheck3" type="checkbox" name="involuntary_termination_period" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.involuntary_termination_period.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="postCheck3"></label>
                                                    <span className="modify-label text-muted">{__('Involuntary termination period')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.involuntary_termination_period}</td>
                                            <td>
                                                <select type="text" className="form-control select-container modify-select" name="involuntary_termination_period" value={this.state.data.basicTemplateData.involuntary_termination_period} disabled={this.state.checkboxStatus.involuntary_termination_period.disabled} onChange={this.recordCurData} >
                                                    {this.state.periodList.map((value,key)=>{
                                                        return(
                                                            <option key={key}>{value}</option>
                                                        )
                                                    })}
                                                </select>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td className="modify-historyTd">
                                                <div className="check-box">
                                                    <input id="postCheck4" type="checkbox" name="involuntary_termination_cause_count" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.involuntary_termination_cause_count.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="postCheck4"></label>
                                                    <span className="modify-label text-muted">{__('Involuntary termination cause count ')}<i className="text-muted"> - Optional</i> </span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.involuntary_termination_cause_count}</td>
                                            <td><input type="text" className={"form-control "} name="involuntary_termination_cause_count" value={this.state.data.basicTemplateData.involuntary_termination_cause_count} disabled={this.state.checkboxStatus.involuntary_termination_cause_count.disabled} onBlur={this.formatNumData} onFocus={this.focusNumData} onChange={this.recordNumData} /></td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="postCheck5" type="checkbox" name="involuntary_termination_cause_period" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.involuntary_termination_cause_period.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="postCheck5"></label>
                                                    <span className="modify-label text-muted">{__('Involuntary termination cause period')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.involuntary_termination_cause_period}</td>
                                            <td>
                                                <select type="text" className="form-control select-container modify-select" name="involuntary_termination_cause_period" value={this.state.data.basicTemplateData.involuntary_termination_cause_period} disabled={this.state.checkboxStatus.involuntary_termination_cause_period.disabled} onChange={this.recordCurData} >
                                                    {this.state.periodList.map((value,key)=>{
                                                        return(
                                                            <option key={key}>{value}</option>
                                                        )
                                                    })}
                                                </select>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td className="modify-historyTd">
                                                <div className="check-box">
                                                    <input id="postCheck6" type="checkbox" name="death_exercise_count" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.death_exercise_count.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="postCheck6"></label>
                                                    <span className="modify-label text-muted">{__('Death exercise count ')}<i className="text-muted"> - Optional</i> </span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.death_exercise_count}</td>
                                            <td><input type="text" className={"form-control "} name="death_exercise_count" value={this.state.data.basicTemplateData.death_exercise_count} disabled={this.state.checkboxStatus.death_exercise_count.disabled} onBlur={this.formatNumData} onFocus={this.focusNumData} onChange={this.recordNumData} /></td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="postCheck7" type="checkbox" name="death_exercise_period" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.death_exercise_period.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="postCheck7"></label>
                                                    <span className="modify-label text-muted">{__('Death exercise period')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.death_exercise_period}</td>
                                            <td>
                                                <select type="text" className="form-control select-container modify-select" name="death_exercise_period" value={this.state.data.basicTemplateData.death_exercise_period} disabled={this.state.checkboxStatus.death_exercise_period.disabled} onChange={this.recordCurData} >
                                                    {this.state.periodList.map((value,key)=>{
                                                        return(
                                                            <option key={key}>{value}</option>
                                                        )
                                                    })}
                                                </select>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td className="modify-historyTd">
                                                <div className="check-box">
                                                    <input id="postCheck8" type="checkbox" name="disability_exercise_count" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.disability_exercise_count.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="postCheck8"></label>
                                                    <span className="modify-label text-muted">{__('Disability exercise count ')}<i className="text-muted"> - Optional</i> </span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.disability_exercise_count}</td>
                                            <td><input type="text" className={"form-control "} name="disability_exercise_count" value={this.state.data.basicTemplateData.disability_exercise_count} disabled={this.state.checkboxStatus.disability_exercise_count.disabled} onBlur={this.formatNumData} onFocus={this.focusNumData}  onChange={this.recordNumData} /></td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="postCheck9" type="checkbox" name="disability_exercise_period" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.disability_exercise_period.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="postCheck9"></label>
                                                    <span className="modify-label text-muted">{__('Disability exercise period')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.disability_exercise_period}</td>
                                            <td>
                                                <select type="text" className="form-control select-container modify-select" name="disability_exercise_period" value={this.state.data.basicTemplateData.disability_exercise_period} disabled={this.state.checkboxStatus.disability_exercise_period.disabled} onChange={this.recordCurData} >
                                                    {this.state.periodList.map((value,key)=>{
                                                        return(
                                                            <option key={key}>{value}</option>
                                                        )
                                                    })}
                                                </select>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td className="modify-historyTd">
                                                <div className="check-box">
                                                    <input id="postCheck10" type="checkbox" name="retirement_exercise_count" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.retirement_exercise_count.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="postCheck10"></label>
                                                    <span className="modify-label text-muted">{__('Retirement exercise count ')}<i className="text-muted"> - Optional</i> </span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.retirement_exercise_count}</td>
                                            <td><input type="text" className={"form-control "} name="retirement_exercise_count" value={this.state.data.basicTemplateData.retirement_exercise_count} disabled={this.state.checkboxStatus.retirement_exercise_count.disabled} onBlur={this.formatNumData} onFocus={this.focusNumData}  onChange={this.recordNumData} /></td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="postCheck11" type="checkbox" name="retirement_exercise_period" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.retirement_exercise_period.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="postCheck11"></label>
                                                    <span className="modify-label text-muted">{__('Retirement exercise period')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.retirement_exercise_period}</td>
                                            <td>
                                                <select type="text" className="form-control select-container modify-select" name="retirement_exercise_period" value={this.state.data.basicTemplateData.retirement_exercise_period} disabled={this.state.checkboxStatus.retirement_exercise_period.disabled} onChange={this.recordCurData} >
                                                    {this.state.periodList.map((value,key)=>{
                                                        return(
                                                            <option key={key}>{value}</option>
                                                        )
                                                    })}
                                                </select>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="modify-content">
                                    <div className="shareTitleBox">
                                        <div className="iconBox bg-lightgreen">
                                            <DollarCircleOutlined />
                                        </div>
                                        <div className="shareTextBox">
                                            <p className="shareTitle ">{__('Compliance')}</p>
                                            <p className="text-muted">{__('Update exemption types or dates related to compliance regulations.')}</p>
                                        </div>
                                    </div>
                                    <table className="table modify-table modify-value-table">
                                        <tbody>
                                        <tr className="modify-firstRow">
                                            <td>{__('Field')}</td>
                                            <td>{__('Value')}</td>
                                            <td>{__('New value')}</td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td className="modify-historyTd">
                                                <div className="check-box">
                                                    <a className="convertible-ques top2">
                                                        <p className="convertible-tiptext modify-tiptext166">{__('This date will override the ISO disqualification date set once a grant is terminated. If set to null, the ISO disqualification date will be automatically be set to three months after the termination date.')}</p>
                                                    </a>
                                                    <input id="complianceCheck0" type="checkbox" name="iso_disqualification_date" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.iso_disqualification_date.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="complianceCheck0"></label>
                                                    <span className="modify-label text-muted">{__('ISO disqualification date ')}<i className="text-muted"> - {__('Optional')}</i> </span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.iso_disqualification_date==""?"": formatDate(curSelectSecurity.iso_disqualification_date)}</td>
                                            <td>
                                                {/*<input type="text" className=" form-control date-calendar-with10padding calender-rCenter datepicker share_class_datepicker" name="iso_disqualification_date" value={this.state.data.basicTemplateData.iso_disqualification_date} disabled={this.state.checkboxStatus.iso_disqualification_date.disabled} />*/}
                                                <DateSelector
                                                    disabled={checkboxStatus.iso_disqualification_date.disabled}
                                                    onChange={this.modifyOptionDateChange.bind(this, 'iso_disqualification_date', 'iso_disqualification_date_moment')}
                                                    value={I(data.basicTemplateData['iso_disqualification_date_moment'], I(data.basicTemplateData['iso_disqualification_date'], '') ? moment(data.basicTemplateData['iso_disqualification_date'], dateFormat) : '')}
                                                    className="datepicker_opacity"
                                                />
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="complianceCheck1" type="checkbox" name="national_exemption" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.national_exemption.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="complianceCheck1"></label>
                                                    <span className="modify-label text-muted">{__('National exemption')}<i className="text-muted"> - Optional</i></span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.national_exemption}</td>
                                            <td>
                                                <select type="text" className="form-control select-container modify-select" name="national_exemption" value={this.state.data.basicTemplateData.national_exemption} disabled={this.state.checkboxStatus.national_exemption.disabled} onChange={this.recordCurData} >
                                                    {this.state.currencyList.map((value,key)=>{
                                                        return(
                                                            <option key={key}>{value}</option>
                                                        )
                                                    })}
                                                </select>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td className="modify-historyTd">
                                                <div className="check-box">
                                                    <input id="complianceCheck2" type="checkbox" name="local_exemption" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.local_exemption.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="complianceCheck2"></label>
                                                    <span className="modify-label text-muted">{__('Local exemption ')}<i className="text-muted"> - Optional</i> </span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.local_exemption}</td>
                                            <td><input type="text" className={"form-control "} name="local_exemption" value={this.state.data.basicTemplateData.local_exemption} disabled={this.state.checkboxStatus.local_exemption.disabled} onBlur={this.formatNumData} onFocus={this.focusNumData} onChange={this.recordNumData} /></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    {this.state.uploadProgress >0 &&
                                    <p className="text-center">{this.state.uploadProgress}% {__('uploaded')}...</p>
                                    }
                                </div>
                            </div>
                            <div role="presentation" className="modal-footer box-footer no-paddingtop">
                                <a onClick={this.checkCustomLabelId}  role="tab" data-toggle="tab" className="btn boxbtn-to bg-darkblue">Next: review modifications</a>
                            </div>
                        </div>
                        <div role="tabpanel" className="modal-content content960 tab-pane" id="reviewOptionModifications">
                            <div className="modal-header box-header">
                                <a href="#modifyOptionValue"  aria-controls="modifyOptionValue" role="tab" data-toggle="tab" className="back-btn"></a>
                                <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.resetAction}>×</button>
                                <h4 className="modal-title" id="myModalLabel">{__('Modify option')} {this.state.initData.custom_label_id}</h4>
                            </div>
                            <div className="modal-body padding2030">
                                <div className="modify-content">
                                    {/*{this.props.curSelectSecurity.exercise_price!==this.state.data.basicTemplateData.exercise_price&&*/}
                                    {/*<div className="option_review_box marginBott10 ">*/}
                                    {/*    <p className="marginBott10">Updating the exercise price will change the grant as of the grant date. Request a repricing if you are trying to enter an amendment.</p>*/}
                                    {/*    <button className="btn" data-toggle="modal" data-target="#requestRepricingModal">Request a repricing</button>*/}
                                    {/*</div>*/}
                                    {/*}*/}
                                    <div className="shareTitleBox">
                                        <div className="iconBox bg-lightgreen">
                                            <DiffOutlined />                                        </div>
                                        <div className="shareTextBox">
                                            <p className="shareTitle ">{__('Review and apply modifications')}</p>
                                            <p className="text-muted">{__('Review the table below and save the modifications. For some fields on accepted securities, a notification will be sent out. Changes marked with  Yes on Sends email notification will send an email to the stakeholder.')}<br />
                                            </p>
                                        </div>
                                    </div>
                                    <table className="modify-table reviewTb">
                                        <tbody>
                                        <tr className="modify-firstRow">
                                            <td className="width100">{__('Security')}</td>
                                            <td className="width120" >{__('Stakeholder')}</td>
                                            <td className="width180" >{__('Field')}</td>
                                            <td className="width160">{__('Old value')}</td>
                                            <td className="width160" >{__('New value')}</td>
                                            <td className="width160" >{__('Sends email notification')}</td>
                                        </tr>
                                        {Object.values(this.state.checkboxStatus).map((value,key)=>{
                                            if(value.checked){
                                                showOptionSelectCount++;
                                                if(value.jsonKey === "form_of_exercise_file"|| value.jsonKey === "form_of_option_file"|| value.jsonKey === "incentive_plan_file"){
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showOptionSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showOptionSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd">{this.state.initData[value.jsonKey].name}</td>
                                                            <td className="newValueTd">{this.state.data.basicTemplateData[value.jsonKey].name}</td>
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    );
                                                }else if(value.jsonKey == "security_plan_id" ){
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showOptionSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showOptionSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd">{this.state.initData.option_plan}</td>
                                                            <td className="newValueTd">{this.state.data.basicTemplateData[value.jsonKey]=="" ? __("None"): formatEaPlanList[this.state.data.basicTemplateData[value.jsonKey]].plan_name}</td>
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    );
                                                }else if(value.jsonKey === "add_document" ){
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showOptionSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showOptionSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd">{__('None')}</td>
                                                            <td className="newValueTd">{this.state.data.basicTemplateData[value.jsonKey].name}</td>
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    );
                                                }else if(value.jsonKey === "remove_document" ){
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showOptionSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showOptionSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd">{__('None')}</td>
                                                            <td className="newValueTd">{this.state.is_remove==0 ? 'No' : 'Yes'}</td>
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    );
                                                }else if(value.jsonKey === "grant_date" || value.jsonKey === "board_approval_date" || value.jsonKey ==="grant_expiration_date" || value.jsonKey ==="iso_disqualification_date"|| value.jsonKey === "last_day_to_exercise"){
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showOptionSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showOptionSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd">{ this.state.initData[value.jsonKey] == -1 ? 'No expiration date' : formatDate(this.state.initData[value.jsonKey])}</td>
                                                            <td className="newValueTd">{data.basicTemplateData[value.jsonKey] == -1 ? 'No expiration date' : formatDate(data.basicTemplateData[value.jsonKey])}</td>
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    );
                                                }else if(value.jsonKey == "quantity"){
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showOptionSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showOptionSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd">{formatFractionalNumber(this.state.initData[value.jsonKey]) }</td>
                                                            <td className="newValueTd">{this.state.data.basicTemplateData[value.jsonKey]}</td>
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    );
                                                }else if(value.jsonKey == "exercise_price"){
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showOptionSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showOptionSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd">{formatCurrencyNumber(this.state.initData[value.jsonKey]) }</td>
                                                            <td className="newValueTd">{formatCurrencyNumber(this.state.data.basicTemplateData[value.jsonKey])}</td>
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    );
                                                }else{
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showOptionSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showOptionSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd">{this.state.initData[value.jsonKey]==""?__("None"):this.state.initData[value.jsonKey]}</td>
                                                            <td className="newValueTd">{this.state.data.basicTemplateData[value.jsonKey]==""?__("None"):this.state.data.basicTemplateData[value.jsonKey]}</td>
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    );
                                                }

                                            }

                                        })}
                                        {checkExerciseHistory.map((value,key)=>{
                                            return(
                                                <Fragment key={key}>
                                                    {Object.values(value).map((v,k)=>{
                                                        if(v.checked){
                                                            showOptionSelectCount++;
                                                            if(v.jsonKey == "exercise_date" || v.jsonKey ==="payment_date"|| v.jsonKey === "amount_reimbursement_date"){
                                                                return(
                                                                    <tr key={k} className="reviewTb-contentRow ">
                                                                        <td>{ showOptionSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                                        <td>{ showOptionSelectCount===1 && this.state.initData.name}</td>
                                                                        <td>
                                                                            <i><small><span className="text-blue">{formatExerciseHistoryList[value.id].custom_label_id} </span></small></i>
                                                                            {v.fieldName}
                                                                        </td>
                                                                        <td className="oldValueTd">
                                                                            {curSelectSecurity.exercise_history.map((val,ke)=>{
                                                                                if(val.id==value.id)
                                                                                    return(
                                                                                        <p key={k} className={"no-marginBottom"}>{formatDate(val[v.jsonKey])}</p>
                                                                                    )
                                                                            })}
                                                                        </td>
                                                                        <td className="newValueTd">
                                                                            {data.basicTemplateData.exercise_history.map((val,ke)=>{
                                                                                if(val.id==value.id)
                                                                                    return(
                                                                                        <p key={k} className={"no-marginBottom"}>{formatDate(val[v.jsonKey])}</p>
                                                                                    )
                                                                            })}

                                                                        </td>
                                                                        <td>X {__('No')}</td>
                                                                    </tr>
                                                                );
                                                            }else if(v.jsonKey == "payment_verified_admin_id" ){
                                                                return(
                                                                    <tr key={k} className="reviewTb-contentRow ">
                                                                        <td>{ showOptionSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                                        <td>{ showOptionSelectCount===1 && this.state.initData.name}</td>
                                                                        <td>
                                                                            <i><small><span className="text-blue">{formatExerciseHistoryList[value.id].custom_label_id} </span></small></i>
                                                                            {v.fieldName}
                                                                        </td>
                                                                        <td className="oldValueTd">{(curSelectSecurity.exercise_history[key].payment_verified_admin_id!=null&& curSelectSecurity.exercise_history[key].payment_verified_admin_id!=0) && formatVerifiedAdminList[curSelectSecurity.exercise_history[key].payment_verified_admin_id].admin_name||"--"}</td>
                                                                        <td className="newValueTd">{data.basicTemplateData.exercise_history[key][v.jsonKey]==null ? __("None"): formatVerifiedAdminList[data.basicTemplateData.exercise_history[key][v.jsonKey]].admin_name}</td>
                                                                        <td>X {__('No')}</td>
                                                                    </tr>
                                                                );
                                                            }else if(v.jsonKey == "exercise_price"||v.jsonKey == "payment_collected"||v.jsonKey=="amount_reimbursed"){
                                                                return(
                                                                    <tr key={k} className="reviewTb-contentRow ">
                                                                        <td>{ showOptionSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                                        <td>{ showOptionSelectCount===1 && this.state.initData.name}</td>
                                                                        <td>
                                                                            <i><small><span className="text-blue">{formatExerciseHistoryList[value.id].custom_label_id} </span></small></i>
                                                                            {v.fieldName}
                                                                        </td>
                                                                        <td className="oldValueTd">{formatCurrencyNumber(this.state.initData.exercise_history[key][v.jsonKey]) }</td>
                                                                        <td className="newValueTd">{formatCurrencyNumber(this.state.data.basicTemplateData.exercise_history[key][v.jsonKey])}</td>
                                                                        <td>X {__('No')}</td>
                                                                    </tr>
                                                                );
                                                            }else if(v.jsonKey == "exercise_file"){
                                                                return(
                                                                    <tr key={k} className="reviewTb-contentRow ">
                                                                        <td>{ showOptionSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                                        <td>{ showOptionSelectCount===1 && this.state.initData.name}</td>
                                                                        <td>
                                                                            <i><small><span className="text-blue">{formatExerciseHistoryList[value.id].custom_label_id} </span></small></i>
                                                                            {v.fieldName}
                                                                        </td>
                                                                        <td className="oldValueTd">{this.state.initData.exercise_history[key][v.jsonKey].name}</td>
                                                                        <td className="newValueTd">{this.state.data.basicTemplateData.exercise_history[key][v.jsonKey].name}</td>
                                                                        <td>X No</td>
                                                                    </tr>
                                                                );
                                                            } else{
                                                                return(
                                                                    <tr key={k} className="reviewTb-contentRow ">
                                                                        <td>{ showOptionSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                                        <td>{ showOptionSelectCount===1 && this.state.initData.name}</td>
                                                                        <td>
                                                                            <i><small><span className="text-blue">{formatExerciseHistoryList[value.id].custom_label_id} </span></small></i>
                                                                            {v.fieldName}
                                                                        </td>
                                                                        <td className="oldValueTd">{this.state.initData[v.jsonKey]==""?__("None"):this.state.initData[v.jsonKey]}</td>
                                                                        <td className="newValueTd">{this.state.data.basicTemplateData.exercise_history[key][v.jsonKey]}</td>
                                                                        <td>X {__('No')}</td>
                                                                    </tr>
                                                                );
                                                            }

                                                        }

                                                    })}
                                                </Fragment>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                    {this.state.uploadProgress >0 &&
                                    <p className="text-center">{this.state.uploadProgress}% {__('uploaded')}...</p>
                                    }
                                </div>
                            </div>
                            <div role="presentation" className="modal-footer box-footer no-paddingtop">
                                <a className="btn boxbtn-to bg-darkblue" onClick={this.submitActionForOption}>{__('Apply modifications')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ModifyOptionGrant;