import React, { Component } from 'react';
import SideBar from '../components/SideBar';
import Detail from './Detail';
import Documents from '../components/Documents';
import Approvals from '../components/Approvals';
import PersonalDetail from './PersonalDetail';
import Exercise from './Exercise';
import Transaction from "./Transaction";
import VestingSchedule from '../components/VestingSchedule/vestingSchedule';
import { cloneDeep } from 'lodash';

const menuConfig = [
	{
		label: __('Details'),
		key: 'details'
	},
	{
		label: __('Exercise history'),
		key: 'exercise'
	},
	// {
	// 	label: 'Transfer details',
	// 	key: 'transferDetails'
	// },
	{
		label: __('Transaction history'),
		key: 'transaction'
	},
	{
		label: __('Approvals'),
		key: 'approvals'
	},
	{
		label: __('Vesting schedule'),
		key: 'vestingSchedule'
	},
	{
		label: __('Documents and notes'),
		key: 'documents'
	},
	{
		label: __('Personal detail'),
		key: 'personalDetails'
    }
]

class Warrant extends Component {
	state = {
		checkedKey: this.props.checkedKey || 'details'
	}

	handleSideBar = key => {
		this.setState({ checkedKey: key })
	}

	formatConfig() {
		const data = this.props.data;

		const newMenuConfig = cloneDeep(menuConfig)

		// const transferIndex = newMenuConfig.findIndex((item) =>{
		// 	return item.key === 'transferDetails';
		// })
		// if (data.transferDetails && data.transferDetails.length === 0 ) {
		// 	newMenuConfig.splice(transferIndex, 1)
		// }
		return newMenuConfig
	}

	renderContent = () => {
		const { checkedKey } = this.state
		const { data } = this.props
		const { approvals, details, documents, vestingSchedule, companyId, certificateList, formatText, type, exerciseHistory, certificateHistory, personal_details, transactionHistory, transferDetails } = data || {};

		const objMap = {
			details: <Detail data={{ ...details, certificateList, formatText, certificateHistory }} />,
			exercise: <Exercise data={exerciseHistory}/>,
			// transferDetails: <TransferDetails data={transferDetails}/>,
			transaction: <Transaction data={transactionHistory}/>,
			approvals: <Approvals data={approvals} />,
			vestingSchedule: <VestingSchedule data={vestingSchedule} />,
			personalDetails: <PersonalDetail data={personal_details} />,
			documents: <Documents data={{ companyId, ...documents, type }} />,
		}

		return objMap[checkedKey] || objMap.details
	}

	render() {
		const { checkedKey } = this.state
		const { data } = this.props
		const newMenuConfig = this.formatConfig()

		return <div className="certificate-detail-root">
			<SideBar onChange={this.handleSideBar} value={checkedKey} config={newMenuConfig} type={__('Warrant')} name={data.name}/>
				<div className="certificate-detail-content">
					{ this.renderContent() }
			</div>
		</div>
	}
}

export default Warrant;