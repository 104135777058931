import React, { Component } from 'react';
import { post } from 'srcPath/http';
import { message, Table, Button } from 'antd';
import { formatNumber } from 'Utils';
import { getOpenDetailFunc } from '../config'
import security from 'stores/security';
import SignatureModal from 'components/SignatureModal';
import Modal, { ModalContent } from 'components/Modal';
import openCertificateModal from 'components/CertificateDetail';

import './style.scss';

// const mockData = [
// 	{
// 		certificateName: 'test',
// 		companyName: 'gbtest',
// 		issuedDate: '01/03/2020',
// 		shares: 10000,
// 		stockHolderName: 'gb-test'
// 	}
// ]

export default class AcceptCertificate extends Component {
	state = {
		tableData: [],
		loading: true
	}

	modalRef = null

	componentDidMount() {
		this.getTableData()
	}

	componentWillMount() {
		this.modalRef && this.modalRef.close()
	}

	callback = async () => {
		this.getTableData()

		await security.getTaskData()
	}

	getTableData = async () => {
		this.setState({ loading: true })

		try {
			const { data: resData } = await post('getTaskList', { type: 'acceptCertificates' })

			const { code, data } = resData || {}

			if (code == 0) {
				this.setState({ tableData: data })
				// this.setState({ tableData: mockData })
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}

		this.setState({ loading: false })
	}	

	handleClick = data => {
		if (data.certificateId) {
			this.modalRef = getOpenDetailFunc(data.security_type)(data.certificateId)
		}
	}

	getColumns = () => {
		return [
			{
				title: __('Certificate'),
				dataIndex: 'certificateName',
				fixed: true,
				ellipsis: true
			},
			{
				title: __('Company'),
				dataIndex: 'companyName',
				className: 'nowrap'

			},
			{
				title: __('Stakeholder'),
				dataIndex: 'stockHolderName',
				className: 'nowrap'
			},
			{
				title: __('Shares/Tokens'),
				dataIndex: 'shares',
				className: 'nowrap',
				render: text => formatNumber(text)
			},
			{
				title: __('Issued'),
				dataIndex: 'issuedDate',
				className: 'nowrap',
			},
			{
				title: '',
				width: 200,
				align: 'right',
				render: (text, record) => <div className="accept-btns">
					<Button type="link" className="sprout-btn-divider" onClick={() => this.handleClick(record)}>{__('View Details')}</Button>
					<Button type="link" onClick={() => this.handleSign(record)}>{__('Accept')}</Button>
				</div>
			}
		]
	}

	handleSign = (itemData) => {
		this.modalRef =  Modal.open({
			component: props => <ModalContent close={props.close} title={__('Sign and Accept Your Certificate')}>
				<SignatureModal 
					data={{ security_id: itemData.certificateId, security_type: itemData.security_type }} 
					apiType="acceptSecurity"
					close={props.close} 
					callback={this.callback} 
					btnText={__('Sign and Accept Your Certificate')}
					tips={__('This digital certificate needs your signature in order to be accepted. Your signature will appear on the front of the digital certificate.')}
				/>
			</ModalContent>,
			className: 'sprout-modal signature-modal',
			width: 600
		})		
	}

	render() {
		const { tableData, loading } = this.state

		return <div className="task-accept-root">
			
{/*				<div className="task-banner-wrap">
					<img src={signIcon} />
					{tableData.length > 0 && <div>You have certificates to accept.</div>}
				</div>
*/}			
			<Table size="small" columns={this.getColumns()}  rowKey="stakeholder_id" dataSource={tableData} pagination={false} loading={loading} scroll={{ x: true }} />
		</div>
	}
}