import React, { Component } from 'react';
import { Button, message } from 'antd';
import { post } from 'srcPath/http';
import { I } from 'Utils';

export default class RecendModalContent extends Component {
	state = {
		loading: false
	}

    resendEmail = async () => {
    	const { data, callback, close } = this.props
    	const { id } = data || {}

    	if(!id) {
    		message.error('No ID')

    		return
    	}

    	this.setState({ loading: true })

        try {

            const { data: resData } = await post('resendStakeholderEmail', {id})
            const { code, data } = resData || {}

            if (code === 0) {
                message.success('Success!')
                close && close()
                callback && callback()
            } else {
                message.error(data)
            }
        } catch(err) {
            message.error(err && err.message)
        }

		this.setState({ loading: false })
    }

	render () {
		const { loading } = this.state
		const { email } = this.props.data

		return <div>
            <p>Confirm you want to resend invitation email to <strong>{I(email, '--')}</strong></p>
            <div style={{ textAlign: 'center', marginTop: 20 }}>
                <Button onClick={this.props.close}>Cancel</Button>&nbsp;&nbsp;
                <Button type="primary" onClick={this.resendEmail} loading={loading}>Ok</Button>
            </div>
        </div>
	}
}