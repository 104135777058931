import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import List from './List';
import Detail from './Detail';

import './style.scss';

export default class Client extends Component {
	path = this.props.match.path


	componentDidMount() {
		// console.log(this.props)
		// console.log(this.props.match)
	}

	render() {
		return <div className="meetings-root">
				<div >
					<Switch>
						<Route exact path={`${this.path}/list`} component={List} />
						<Route exact path={`${this.path}/detail`} component={Detail} />

						<Redirect to={`${this.path}/list`} />
					</Switch>
				</div>
		</div>
	}
}
