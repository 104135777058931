import { Drawer } from 'antd';
import React, { Children, cloneElement, useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { isValidElementType } from 'react-is';
import classlist from 'classnames';
import './style.scss';

const drawerCloseSet = new Set();

const defaultConfig = {
    destroyOnClose: true,
    maskClosable: true,
    closable: false,
    width: 448
};

let renderToRoot = ReactDOM.render;

/**
 * @description 拓展antd Drawer，增加open方法和destory方法。open方法逻辑同@tiger/antd-modal。destory是销毁全部由open挂载的drawer的方法。
 */
(Drawer).open = config => {
    let withResolve;
    let withReject;
    let TheComponent = config.component;
    const _renderToRoot = renderToRoot;

    // @ts-ignore
    delete config.component;

    const div = document.createElement('div');

    document.body.appendChild(div);

    Drawer.stack = []

    function render(visible, animationCallback) {
        const { afterVisibleChange, onClose, className, ...props } = config;
        const drawerOnClose = onClose ? e => onClose(e, { close, dismiss }) : () => dismiss();
        const childProps = {
            close,
            dismiss
        };
        let children;

        Drawer.stack.push(close)

        if (isValidElementType(TheComponent)) {
            children = <TheComponent />;
        } else {
            children = TheComponent;
        }

        _renderToRoot(
            <Drawer
                className={classlist('custom-drawer-container', className)}
                onClose={drawerOnClose}
                afterVisibleChange={v => {
                    if (!v) {
                        if (!animationCallback) {
                            animationCallback = withReject;
                        }

                        animationCallback();
                        destroy();
                    }

                    afterVisibleChange && afterVisibleChange(v);
                }}
                {...defaultConfig}
                {...props}
                visible={visible}>
                {Children.map(children, child => cloneElement(child, childProps))}
            </Drawer>,
            div
        );
    }

    function destroy() {
        ReactDOM.unmountComponentAtNode(div);
        document.body.removeChild(div);
        drawerCloseSet.delete(close);
    }

    function close(data?) {
        render(false, () => withResolve(data));
    }

    function dismiss(reason?) {
        render(false, () => withReject(reason));
    }

    drawerCloseSet.add(dismiss);
    render(true);

    return {
        close,
        dismiss,
        result: new Promise((resolve, reject) => {
            withResolve = resolve;
            withReject = reject;
        }),
        render: (newComponent) => {
            TheComponent = newComponent;
            render(true);
        }
    };
};

(Drawer).destory = () => {
    drawerCloseSet.forEach(dismiss => {
        dismiss();
    });
};

export function DrawerRoot() {
    const [drawers, setDrawers] = useState({});
    const rootRef = useRef(document.createElement('div'));

    useEffect(() => {
        const root = rootRef.current;

        document.body.appendChild(root);

        renderToRoot = ((element, target) => {
            if (!target.id) {
                target.id = `ant-drawer-${Date.now() + Math.random()}`;
            }

            setDrawers(drawers => {
                const newDrawers = { ...drawers };
                const originalCallback = element.props.afterVisibleChange;

                newDrawers[target.id] = cloneElement(element, {
                    key: target.id,
                    afterVisibleChange: v => {
                        if (!v) {
                            setDrawers(drawers => {
                                const newDrawers = { ...drawers };

                                delete newDrawers[target.id];

                                return newDrawers;
                            });
                        }

                        originalCallback?.(v);
                    }
                });

                return newDrawers;
            });
        });

        return () => {
            document.body.removeChild(root);
            renderToRoot = ReactDOM.render;
        };
    }, []);

    return ReactDOM.createPortal(Object.values(drawers), rootRef.current);
}

export default Drawer;
