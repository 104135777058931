import React, { Component, Fragment } from 'react';
import { Collapse, message } from 'antd';
import { formatNumber, formatPercent, formatCurrencyNumber } from 'Utils';
import { formatDate, formatDate2Api } from 'Utils/format/formatDate';
import { post } from 'srcPath/http';
import security from 'stores/security';
import openCertificateModal from 'components/CertificateDetail';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import moment from 'moment';
import DateSelector from 'components/DateSelector';
import commonImg from './images/common.png';
import preferredImg from './images/preferred.png';
import optionImg from './images/option.png';
import convertibleImg from './images/convertible.png';
import { language } from 'Utils/i18n'

import Loading from 'components/Loading';
import './style.scss';

const { Panel } = Collapse;

const renderLabels = (number, label) => <>
	<span>{formatNumber(number, 0)}</span>&nbsp;
	<span>{ number > 1 ? (language?.startsWith('en') ? `${label}s` : label) : label }</span>
</>

export default class Captable extends Component {
	state = {
		loading: true,
		totalFullyDiluted: 1,
		totalOutstanding: 1,
        date: moment(),
	}

	componentDidMount() {
		this.getData()
	}

	getData = async () => {
	    const { date } = this.state;
		const capTableDate = formatDate2Api(date);

		Loading.global(true)

		try {
			const { data: resData } = await post('getProfileCaptable', {capTableDate})

			const { code, data } = resData || {}
			const { total = {} } = data || {}

			if (code === 0) {
				this.setState({ data, totalFullyDiluted: total.fullyDiluted, totalOutstanding: total.outstanding })
			}

		} catch (err) {
			message.error(err.message)
		}

		Loading.global(false)
	}

	renderCommonHeader = data => {
		const { shareClassName, sharesCount, outstanding, authorized, fullyDiluted } = data || {}
		const { totalFullyDiluted, totalOutstanding } = this.state

		return <div className="certificate-content-header">
			<div className="header-name" title={shareClassName}>
				{shareClassName}
				<div className="description">
					{
						renderLabels(sharesCount, __('certificate'))
					}
					<span> • </span>
					<span>{formatNumber(authorized, 0)} </span>
					<span>{__('authorized')}</span>
				</div>
			</div>
			<div className="table-td">{formatFractionalNumber(outstanding)}</div>
			<div className="table-td">{formatPercent(outstanding/totalOutstanding)}</div>
			<div className="table-td">{formatFractionalNumber(fullyDiluted)}</div>
			<div className="table-td">{formatPercent(fullyDiluted/totalFullyDiluted)}</div>
		</div>
	}

	renderCommonHeader2 = data => {
		const { ownerName, outstanding, count, fullyDiluted } = data || {}
		const { totalFullyDiluted, totalOutstanding } = this.state

		return <div className="certificate-content-header">
			<div className="header-name" title={ownerName}>
				{ownerName}
				<div className="description">
					{
						renderLabels(count, __('certificate'))
					}
				</div>
			</div>
			<div className="table-td">{formatFractionalNumber(outstanding)}</div>
			<div className="table-td">{formatPercent(outstanding/totalOutstanding)}</div>
			<div className="table-td">{formatFractionalNumber(fullyDiluted)}</div>
			<div className="table-td">{formatPercent(fullyDiluted/totalFullyDiluted)}</div>
		</div>
	}

	renderCommonItem = (data, isPreferred) => {
		const { certificateName, issuedDate, outstanding, fullyDiluted, certificateId } = data || {}
		const { totalFullyDiluted, totalOutstanding } = this.state

		return <div className="certificate-content-item">
			<div className="item-name" onClick={() => openCertificateModal(certificateId)}>
				<img src={ isPreferred ? preferredImg : commonImg} width="40" height="32" />
				<div className="name">
					<div>{certificateName}</div>
					<div className="description">{__('Issued')} <span>{formatDate(issuedDate)}</span></div>
				</div>
			</div>
			<div className="table-td">{formatFractionalNumber(outstanding)}</div>
			<div className="table-td">{formatPercent(outstanding/totalOutstanding)}</div>
			<div className="table-td">{formatFractionalNumber(fullyDiluted)}</div>
			<div className="table-td">{formatPercent(fullyDiluted/totalFullyDiluted)}</div>
		</div>
	}

	renderOptionHeader = data => {
		const { shareClassName, sharesCount, outstanding, authorized, fullyDiluted } = data || {}
		const { totalFullyDiluted, totalOutstanding } = this.state

		return <div className="certificate-content-header">
			<div className="header-name" title={shareClassName}>
				{shareClassName}
				<div className="description">
					{
						renderLabels(sharesCount, __('grant'))
					}
					<span> • </span>
					<span>{formatNumber(authorized, 0)} </span>
					<span>{__('authorized')}</span>
				</div>
			</div>
			<div className="table-td">{formatFractionalNumber(outstanding)}</div>
			<div className="table-td">{formatPercent(outstanding/totalOutstanding)}</div>
			<div className="table-td">{formatFractionalNumber(fullyDiluted)}</div>
			<div className="table-td">{formatPercent(fullyDiluted/totalFullyDiluted)}</div>
		</div>
	}


	renderWarrantHeader = data => {
		const { shareClassName, sharesCount, authorized, fullyDiluted } = data || {}
		const { totalFullyDiluted } = this.state

		return <div className="certificate-content-header">
			<div className="header-name">
				<div>{shareClassName}</div>
				<div className="description">
					{
						renderLabels(sharesCount, __('grant'))
					}
					<span> • </span>
					<span>{formatNumber(authorized, 0)} </span>
					<span>{__('authorized')}</span>
				</div>
			</div>
			<div className="table-td">--</div>
			<div className="table-td">--</div>
			<div className="table-td">{formatFractionalNumber(fullyDiluted)}</div>
			<div className="table-td">{formatPercent(fullyDiluted/totalFullyDiluted)}</div>
		</div>
	}

	renderOptionHeader2 = data => {
		const { name, count, fullyDiluted } = data || {}
		const { totalFullyDiluted } = this.state

		return <div className="certificate-content-header">
			<div className="header-name">
				<div>{name}</div>
				<div className="description">
					{
						renderLabels(count, __('grant'))
					}
				</div>
			</div>
			<div className="table-td">--</div>
			<div className="table-td">--</div>
			<div className="table-td">{formatFractionalNumber(fullyDiluted)}</div>
			<div className="table-td">{formatPercent(fullyDiluted/totalFullyDiluted)}</div>
		</div>
	}


	renderOptionItem = (data, isPreferred) => {
		const { certificateName, issuedDate, fullyDiluted, certificateId } = data || {}
		const { totalFullyDiluted } = this.state

		return <div className="certificate-content-item">
			<div className="item-name" onClick={() => openCertificateModal(certificateId)}>
				<img src={optionImg} width="40" height="32" />
				<div className="name">
					<div>{certificateName} </div>
					<div className="description">{__('Issued')} <span>{formatDate(issuedDate)}</span></div>
				</div>
			</div>
			<div className="table-td">--</div>
			<div className="table-td">--</div>
			<div className="table-td">{formatFractionalNumber(fullyDiluted)}</div>
			<div className="table-td">{formatPercent(fullyDiluted/totalFullyDiluted)}</div>
		</div>
	}

	renderConvertibleHeader = data => {
		const { shareClassName, count, raised, principal, interestRate } = data || {}

		return <div className="certificate-content-header">
			<div className="header-name">
				<div>{shareClassName}</div>
				<div className="description">
					{
						renderLabels(count, __('Convertible debt'))
					}
					<span> • </span>
					<span>{formatNumber(raised, 0)} </span>
					<span>{__('raised')}</span>
				</div>
			</div>
			<div className="table-td">--</div>
			<div className="table-td">{formatCurrencyNumber(principal)}</div>
			<div className="table-td">{formatNumber(interestRate)}</div>
			<div className="table-td">{formatCurrencyNumber(principal)}</div>
		</div>
	}

	renderConvertibleHeader2 = data => {
		const { ownerName, count, principal, interestRate } = data || {}

		return <div className="certificate-content-header">
			<div className="header-name">
				<div>{ownerName}</div>
				<div className="description">
					{
						renderLabels(count, __('Convertible debt'))
					}
				</div>
			</div>
			<div className="table-td">--</div>
			<div className="table-td">{formatCurrencyNumber(principal, 2)}</div>
			<div className="table-td">{formatNumber(interestRate)}</div>
			<div className="table-td">{formatCurrencyNumber(principal, 2)}</div>
		</div>
	}

	renderConvertibleItem = (data) => {
		const { certificateName, issuedDate, principal, interestRate, maturityDate, certificateId } = data || {}

		return <div className="certificate-content-item">
			<div className="item-name" onClick={() => openCertificateModal(certificateId)}>
				<img src={convertibleImg} width="40" height="32" />
				<div className="name">
					<div>{certificateName} </div>
					<div className="description">{__('Issued')} <span>{formatDate(issuedDate)}</span></div>
				</div>
			</div>
			<div className="table-td">{maturityDate}</div>
			<div className="table-td">{formatCurrencyNumber(principal, 2)}</div>
			<div className="table-td">{formatNumber(interestRate)}</div>
			<div className="table-td">{formatCurrencyNumber(principal, 2)}</div>
		</div>
	}

    /**
     * 查询日期change事件
     * @param date
     */
    dateChange = (date) => {
        this.setState({date}, ()=>{
            this.getData();
        });
    }

    /**
     * 不允许选择未来日期
     * @param current
     * @returns {boolean}
     */
    checkDate = (date) => {
        return date && date > moment().endOf('day');
    }

	render() {
		const { data, totalFullyDiluted, date } = this.state
		const { common = [], preferred= [], total = {}, options = {}, warrant = [], convertible = {} } = data || {}
		const { fullyDiluted, outstanding } = total || {}
		const { data: optionsList = [], sharesAvailable: optionsAvailable = [] } = options || {}
		const { list: convertibleList = [], total: convertibleTotal = {} } = convertible || {}

		// 判断用户是否属于 Esop admin
		const flag = security.companyInfo && security.companyInfo.flag
		const isEsopAdmin = flag === '5'
		const isCaptableAdmin = flag === '6'

		return <div className="captable-shareclass-root">
                {/*<div className="captable-shareclass-date">
                    <div className="captable-label">{__('View cap table as of')}</div>
                    <DateSelector
                        className="captable-shareclass-date-selector"
                        inputReadOnly={true}
                        value={date}
                        disabledDate={this.checkDate}
                        onChange={this.dateChange}
                    />
                </div>*/}
				<div className="capitalization-header-wrap capitalization-header-wrap-supplement">
					<div className="certificate-title"></div>
					<div className="table-th">
						<div >{__('Shares')}</div>
					</div>
					<div className="table-th">
						<div className="table-th-content">
							<div className="table-th-border"></div>
							<span>{__('Ownership')}</span>
							<div className="table-th-desc-tit tit-re-position">{__('Issued and Outstanding')}</div>
						</div>
					</div>
					<div className="table-th">
						<div >{__('Shares')}</div>
					</div>
					<div className="table-th">
						<div className="table-th-content">
							<div className="table-th-border"></div>
							<span>{__('Ownership')}</span>
							<div className="table-th-desc-tit">{__('Fully diluted')}</div>
						</div>
					</div>
				</div>
				<Collapse>
					{
						common && common.map((value, index) => (
							<Panel showArrow={value?.sharesCount > 0} key={index + 'common'} header={this.renderCommonHeader(value)} className="common" collapsible={isEsopAdmin ? 'disabled' : true}>
								<Collapse>
									{
										value.shareClassDetails && value.shareClassDetails.map((data, itemIndex) => (
											<Panel key={itemIndex} header={this.renderCommonHeader2(data)}>
												{data.listByOwner && data.listByOwner.map((val, key) => <div key={key}>{this.renderCommonItem(val)}</div>)}
											</Panel>
										))
									}
								</Collapse>
							</Panel>
						))
					}
					{
						preferred && preferred.map((value, index) => (
							<Panel showArrow={value?.sharesCount > 0} key={index + 'preferred'} header={this.renderCommonHeader(value)} className="preferred" collapsible={isEsopAdmin ? 'disabled' : true}>
								<Collapse>
									{
										value.shareClassDetails && value.shareClassDetails.map((data, itemIndex) => (
											<Panel key={itemIndex} header={this.renderCommonHeader2(data)}>
												{data.listByOwner && data.listByOwner.map((val, key) => <div key={key}>{this.renderCommonItem(val, true)}</div>)}
											</Panel>
										))
									}
								</Collapse>
							</Panel>
						))
					}
					{
						warrant && warrant.map((value, index) => (
						<Panel showArrow={value?.sharesCount > 0} key={index + 'warrant'} header={this.renderWarrantHeader(value)} className="warrants" collapsible={isEsopAdmin ? 'disabled' : true}>
							<Collapse>
								{
									value.listByOwner && value.listByOwner.map((data, itemIndex) => (
										<Panel key={itemIndex} header={this.renderOptionHeader2(data)}>
											{data.certificateDetail && data.certificateDetail.map((val, key) => <div>{this.renderOptionItem(val)}</div>)}
										</Panel>
									))
								}
							</Collapse>
						</Panel>
						))
					}
					{
						optionsList && optionsList.map((value, index) => <Fragment key={index}>
							<Panel showArrow={value?.sharesCount > 0} key={index + 'options'} header={this.renderOptionHeader(value)} className="option" collapsible={isCaptableAdmin ? 'disabled' : true}>
								<Collapse>
									{
										value.listByOwner && value.listByOwner.map((data, itemIndex) => (
											<Panel key={itemIndex} header={this.renderOptionHeader2(data)}>
												{data.certificateDetail && data.certificateDetail.map((val, key) => <div key={key}>{this.renderOptionItem(val)}</div>)}
											</Panel>
										))
									}
								</Collapse>
							</Panel>
							<div className="options-available-wrap">
								<div className="options-available-first" title={optionsAvailable[index] && optionsAvailable[index].plan_name}>{__('Shares available under')} {optionsAvailable[index] && optionsAvailable[index].plan_name}</div>
								<div className="options-available-item">--</div>
								<div className="options-available-item">--</div>
								<div className="options-available-item">{formatFractionalNumber(optionsAvailable[index] && optionsAvailable[index].size)}</div>
								<div className="options-available-item">{formatPercent((optionsAvailable[index] && optionsAvailable[index].size)/totalFullyDiluted)}</div>
							</div>
						</Fragment>)
					}
				</Collapse>
				<div className="table-total">
					<div className="table-total-td-first">{__('Total')}</div>
					<div className="table-total-td">{formatFractionalNumber(outstanding)}</div>
					<div className="table-total-td">{formatPercent(1)}</div>
					<div className="table-total-td">{formatFractionalNumber(fullyDiluted)}</div>
					<div className="table-total-td">{formatPercent(1)}</div>
				</div>
				{
					(convertibleList && convertibleList.length > 0) && <div className="capitalization-convertible">
						<div className="portfolio-capitalization-title">{__('Convertibles')}</div>
						<div className="capitalization-header-wrap">
							<div className="certificate-title"></div>
							<div className="table-th">{__('Maturity date')}</div>
							<div className="table-th">{__('Principal')}</div>
							<div className="table-th">{__('Interest(%)')}</div>
							<div className="table-th">{__('Total')}</div>
						</div>
						<Collapse>
							{
								convertibleList && convertibleList.map((value, index) => (
									<Panel showArrow={value?.count > 0} key={index + 'convertible'} header={this.renderConvertibleHeader(value)} className="convertible">
										<Collapse>
											{
												value.listByOwner && value.listByOwner.map((data, itemIndex) => (
													<Panel key={itemIndex} header={this.renderConvertibleHeader2(data)}>
														{data.certificateDetail && data.certificateDetail.map((val, key) => <div key={key}>{this.renderConvertibleItem(val)}</div>)}
													</Panel>
												))
											}
										</Collapse>
									</Panel>
								))
							}
						</Collapse>
						<div className="table-total">
							<div className="table-total-td-first">{__('Total')}</div>
							<div className="table-total-td">--</div>
							<div className="table-total-td">{formatCurrencyNumber(convertibleTotal.principal)}</div>
							<div className="table-total-td">--</div>
							<div className="table-total-td">{formatCurrencyNumber(convertibleTotal.principal)}</div>
						</div>
					</div>
				}
		</div>
	}
}