import React, { Component, Fragment } from "react";
import {Table, Checkbox, Dropdown, Menu, Button, Tooltip} from 'antd';
import Utils, { I, tableSort, calculator } from 'Utils';
import { withRouter } from 'react-router-dom';
import TransactionRender from 'B/components/TransactionHistory';
import {DownOutlined} from "@ant-design/icons";
import { noPermission } from 'components/Permission';
import {formatDate} from "Utils/format/formatDate";
import formatTokenNumber from 'Utils/format/formatTokens';
import openAssignTokenGrantModal from "../components/AssignTokenGrants";
import { openGrantDetail } from 'components/CertificateDetail';
import { formatPercent } from 'Utils'
import ColumnController, { columnsFilter } from "components/ColumnController";

class GrantTableModel extends Component {
    state = {
        selectedRowKeys: [], // Check here to configure the default column
        reverseDesc : __('Can reverse cancel only certificates, that have been canceled, repurchased.'),
        searchText: '',
        searchedColumn: '',
        contentHeight : 0,
    };

    componentDidMount(){
        this.props.onRef(this)
        //初始化table高度
        this.setState(()=>({
            contentHeight : $('.sprout-client-content').height()
        }))
    }

    /**
     * 初始化table高度，目的是固定表头
     * @param nextProps
     */
    componentWillReceiveProps (nextProps){
        const contentHeight = $('.sprout-client-content').height();
        this.setState(()=>({
            contentHeight
        }))
    }

    /**
     * 表格checkbox自定义配置
     * @param sign_status 状态值
     * @returns {JSX.Element}
     */
    securityHover = (checked, record, index, selectedRowKeys) => {

        let showMsgStatus = I(record.showMsgStatus, false);
        const { sign_status } = record;
        const message = {
            1 : __('Awaiting officer signature'),
            2 : __('Awaiting holder signature')
        }
        return (
            <Fragment>
                {
                    (sign_status == 1 || sign_status == 2) &&
                    <div className="signTipBox">
                        <div className={sign_status == 1 ? 'officer-tip-triangle' : 'holder-tip-triangle'}></div>
                        <p className={['sign-tiptext', sign_status == 1 ? 'bg-lightorange' : 'bg-lightgreen', showMsgStatus && 'shares-row-msg-show'].join(' ')} style={{display:'block'}}>{message[sign_status]}</p>
                    </div>
                }
                <Checkbox onClick={(e)=>{
                    e.stopPropagation();
                }} checked={checked} onChange={this.checkChange.bind(this, record, index, checked)} ></Checkbox>
            </Fragment>
        );
    }

    /**
     * 清空checkbox所有选中内容
     */
    clearAll = () => {
        this.setState(()=>({
            selectedRowKeys : []
        }))
    }

    /**
     * 表格checkbox change事件
     * @param record
     * @param index
     * @param boxChecked
     * @param e
     */
    checkChange = (record, index, boxChecked, e) => {
        const { checked } = e.target;
        const { selectedRowKeys } = this.state;
        const { updateSelectCount } = this.props;
        const { id } = record;
        let key_list = [...selectedRowKeys];
        if(checked){
            key_list.push(id);
        }else{
            key_list.forEach( (v, k) => {
                if(v == id){
                    key_list.splice(k, 1);
                }
            })
        }
        updateSelectCount(key_list.length, [{selectIndex: index,checked: checked, status:record.status}]);
        this.setState(()=>({
            selectedRowKeys : key_list
        }))
        // this.props.setModifyId( key_list)
    }

    /**
     * 表格列参数配置
     * @returns {[{sorter: (function(*, *): number), dataIndex: string, onFilter: (function(*=, *): boolean), filters: [{text: string, value: string}, {text: string, value: string}, {text: string, value: string}, {text: string, value: string}, {text: string, value: string}, null], title: string, align: string, key: string}, {filterDropdown: function({setSelectedKeys: *, selectedKeys?: *, confirm?: *, clearFilters?: *}): *, sorter: (function(*, *): number), dataIndex: string, filterIcon: function(*): *, onFilter: function(*, *): *|string, onFilterDropdownVisibleChange: function(*=): void, title: string}, {sorter: (function(*, *): number), dataIndex: string, title: string, render: (function(*, *))}, {sorter: (function(*, *)), dataIndex: string, title: string, align: string, render: (function(*=))}, {sorter: (function(*=, *=)), dataIndex: string, title: string, align: string, render: (function(*, *=))}, null, null, null]}
     */
    tableColumns = (sort) => {
        const { column, type } = sort || {}
        const { division } = calculator;
        const { grantsList } = this.props;
        let grantTokenNameFilter = [];
        const grantObj = {}
        grantsList.forEach( (v, k) => {
            if (!grantObj[v?.token_name]) {
                grantObj[v?.token_name] = 1
                grantTokenNameFilter.push({text: v?.token_name, value: v?.token_name});
            }
        })
        const initColumns = [
            {
                title : __('Vesting ID'),
                align : 'left',
                dataIndex : 'grant_name',
                fixed: 'left',
                width:120,
                sorter: true,
                sortName: 'grant_name',
                render: (text, record) => <Button type="link" onClick={e => { 
                    e.preventDefault();
                    openGrantDetail(record.id)
                 }}>{ text }</Button>
            },
            {
                title: __('Token'),
                dataIndex: 'token_name',
                width: 200,
                sorter: true,
                sortName: 'token_name',
                render: val => val || '--'
            },
            {
                title : __('Wallet'),
                align : 'left',
                dataIndex : 'destination_wallet_address',
                key : 'keyId',
                width:200,
                sorter: false,
                sortName: 'destination_wallet_address',
                render: (text, record) => {
                    return <span>{text}</span>
                }
            },
            {
                title : __('Stakeholder/Wallet name'),
                dataIndex : 'stakeholder_name',
                width:220,
                sorter: true,
                sortName: 'stakeholder_name',
                render: (text) => <span>{text ? text : '--'}</span>
            },
            {
                title : __('Status'),
                dataIndex : 'status',
                ellipsis: {
                    showTitle: false,
                },
                sorter: true,
                sortName: 'status',
                width:140,
                render : (text, value) => {
                    let status = value.status;
                    let statusArr = status.split("until ");
                    status = statusArr[0]+" until "  + formatDate(statusArr[1])
                    return <Tooltip  placement={'topLeft'} title = {<span>{value.status.substring(0,17) == "Exercisable until" ? status : value.status} <TransactionRender transferSecurity={value.transferSecurity} /></span>}>
                            <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'noWrap' }}>
                        {(value.status === "Outstanding" || value.status==="Issued") &&
                        <p className="green-disc">&nbsp;&nbsp;&nbsp;&nbsp;{value.status}</p>}
                        {value.status != "Outstanding" && value.status != "Issued" && value.status.substring(0,11) != "Exercisable" &&
                        <p className="red-disc">&nbsp;&nbsp;&nbsp;&nbsp;{value.status}</p>}
                        {value.status.substring(0,17) == "Exercisable until" &&
                        <p className="yellow-disc file_text">&nbsp;&nbsp;&nbsp;&nbsp;{status}</p>
                        }
                        {(value.status === "Exercisable")&&
                        <p className="yellow-disc file_text">&nbsp;&nbsp;&nbsp;&nbsp;{value.status}</p>
                        }
                        <TransactionRender transferSecurity={value.transferSecurity} />
                        </div>
                    </Tooltip>
                }
            },
            {
                title : __('Issued quantity'),
                dataIndex : 'quantity',
                align : 'right',
                width:140,
                sorter: true,
                sortName: 'quantity',
                render: (text) => <span>{ formatTokenNumber(text)}</span>
            },
            {
                title : __('Vested'),
                dataIndex : 'vested_tokens',
                align : 'right',
                width:120,
                sorter: true,
                sortName: 'vested_tokens',
                render: (text) => <span>{ formatTokenNumber(text)}</span>
            },
            {
                title : __('Vested %'),
                dataIndex : 'vested',
                align : 'right',
                width:100,
                render: (_, value) => formatPercent(division(value.vested_tokens, value.quantity))
            },
            {
                title : __('Claimed'),
                dataIndex : 'claimed_tokens',
                align : 'right',
                width:160,
                sorter: true,
                sortName: 'claimed_tokens',
                render: (text) => <span>{ formatTokenNumber(text)}</span>

            },
            {
                title : __('Grant date'),
                dataIndex : 'grant_date',
                align : 'right',
                width:160,
                // sorter: (a, b) => {
                //     const transactionValueA = this.transactionValueOrderBy(a, 0);
                //     const transactionValueB = this.transactionValueOrderBy(b, 0);
                //     return tableSort(transactionValueA, transactionValueB, 'number');
                // },
                sorter: true,
                sortName: 'grant_date',
                render: (text, value) => {
                    return <span>{ formatDate(text) }</span>
                },
            },
            {
                // title : 'Option plan',
                title : __('Plan name'),
                dataIndex : 'plan_name',
                align : 'right',
                width:160,
                sorter: true,
                sortName: 'plan_name',
                render: (text) => <span>{ !text ? "--" : text }</span>
            },
            {
                title : __('Vesting schedule'),
                dataIndex : 'vesting_schedule_name',
                align : 'center',
                sorter: false,
                sortName: 'vesting_schedule_name',
                width:180,
            },
            {
                title : '',
                dataIndex : 'id',
                width: 50,
                fixed: 'right',
                render:(text, value, index) => this.dropdownInfo(text, value, index)
            }
        ]
        if (column && !!type) {
            initColumns.map(i => {
                if (i.dataIndex === column) {
                    i.sortOrder = type
                }
            })
        }
        return initColumns
    }

    /**
     * 计算 price shares列金额，目的是为了方便排序
     * @param value
     * @param defaultPrice
     * @returns {number|*}
     */
    priceShareOrderBy = (value, defaultPrice) => {
        let transactionVal = Math.round(Utils.resetNumberWithCommas(value.cash_paid) ) + Math.round(Utils.resetNumberWithCommas(value.other_consideration)) + Math.round (Utils.resetNumberWithCommas(value.debt_canceled)) + Math.round (Utils.resetNumberWithCommas(value.value_of_ip_transferred))
        const sharePrice = value.quantity!=0 ? transactionVal/value.quantity : defaultPrice;
        return sharePrice;
    }

    /**
     * 计算 transaction value，目的是为了方便排序
     * @param value
     * @param defaultValue
     * @returns {number|*}
     */
    transactionValueOrderBy = (value, defaultValue) => {
        let transactionVal = Math.round(Utils.resetNumberWithCommas(value.cash_paid) ) + Math.round(Utils.resetNumberWithCommas(value.other_consideration)) + Math.round (Utils.resetNumberWithCommas(value.debt_canceled)) + Math.round (Utils.resetNumberWithCommas(value.value_of_ip_transferred))
        const transactionValue = transactionVal!=0 ? transactionVal : defaultValue;
        return transactionValue;
    }

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    setCurSelectSecurityNew = ( text, data, index) => {
        const { setCurSelectSecurityNew } = this.props;
        setCurSelectSecurityNew( text);
        this.updateSecurityListsVisible(data, true);
    }

    /**
     * 更新列表中的菜单显示状态
     * @param index 列表下标
     * @param flag 菜单显示状态
     */
    updateSecurityListsVisible = (data, flag) => {
        const { grantsList, saveState } = this.props;
        let list = [...grantsList];
        //默认index改为使用id获取数组下标
        const index =list.findIndex((v,k,arr)=>{
            return v.id ==data.id
        })
        if(flag === false) {
            list.forEach((v, k) => {
                list[k]['visibleStatus'] = false;
            });
        }
        if(data !== false){
            list[index]['visibleStatus'] = true;
        }
        saveState( grantsList, list);
    }

    getVestingData = (data, index, e) => {
        this.stopModel(data,index,e);
        const { getVestingData } = this.props;
        getVestingData( index);
    }

    modifyTokenGrant = (data,index,e) =>{
        this.stopModel.bind(data,index,e);
        openAssignTokenGrantModal(this.props.getData,data, this.props.history)
    }

    getVestMilestone = ( data, index, e) => {
        this.stopModel(data,index,e);
        const { getVestMilestone } = this.props;
        getVestMilestone();
    }

    getInitVestingList = ( data, index, e) => {
        this.stopModel(data,index,e);
        const { getInitVestingList } = this.props;
        getInitVestingList();
    }

    resetSecurityStatus = () =>{
        const { resetSecurityStatus } = this.props;
        resetSecurityStatus();
    }
    setInitShareNote = (data, index, e) => {
        this.stopModel(data,index,e);
        const { setInitShareNote } = this.props;
        setInitShareNote();
    }

    resendEmail = (data, index, e) => {
        this.stopModel(data,index,e);
        const { resendEmail } = this.props;
        resendEmail();
    }

    openReverseTermination = (data, index,e) => {
        this.stopModel(data,index,e);
        const { openReverseTermination } = this.props;
        openReverseTermination(data,index);
    }

    setModifyId = (index) => {
        const { setModifyId } = this.props;
        setModifyId();
    }
    stopModel = (data, index, e) => {
        e.stopPropagation();
        this.updateSecurityListsVisible(false, false);
    }

    handleMenuClick = e => {
        this.setState({
            visibleList: false
        })
    }
    handleVisibleChange = flag => {
        this.updateSecurityListsVisible(false, flag);
    }

    /**
     * 表格最后一列菜单配置
     * @param data
     * @param index
     * @returns {JSX.Element}
     */
    dropdownInfo = (text, data, index) => {
        const { visibleStatus } = data;
        return (
            <Dropdown visible={visibleStatus} overlay={this.menu( data, index)}
                      onVisibleChange={this.handleVisibleChange}
                      placement="bottomRight"
                      trigger="click"
                      onClick={(e)=>{
                          e.stopPropagation();
                          this.setCurSelectSecurityNew(text, data, index);
                      }}>
                <div className="tableMenu"><DownOutlined style={{ color: '#FFF' }}/></div>
            </Dropdown>
        );
    }
    /**
     * Waterfall models 菜单
     * @param data
     * @param index
     * @returns {JSX.Element}
     */
    menu = (data, index) => {
        const isModifyVesting = data.vesting_schedule_id==0 && data.custom_schedule_id==0 ? 0 : 1;
        const isEmptyTerminationDate = data.termination_date =="" ? 1 : 0;
        return (
            <Menu>
                { (noPermission('securities_shares_certificate_edit') === false ) && (<Menu.Item ><a onClick={ this.modifyTokenGrant.bind(this,data,index)}>Modify token grant</a></Menu.Item>) }
                { (noPermission('securities_shares_vestingSchedule') === false ) && (  isModifyVesting==0 && <Menu.Item ><a data-toggle="modal" onClick={this.stopModel.bind(this,data,index)} data-target="#addVestingScheduleModal">{__('Add vesting schedule')}</a></Menu.Item> )}
                { (noPermission('securities_shares_vestingSchedule') === false ) && (  isModifyVesting==1 && <Menu.Item ><a data-toggle="modal" onClick={this.getVestingData.bind(this,data,index)} data-target="#addVestingScheduleModal">{__('Modify vesting schedule')}</a></Menu.Item> )}
                {isModifyVesting==1 && data.isMilestone==1 && data.status!="Canceled" &&  <Menu.Item ><a data-toggle="modal" onClick={this.getVestMilestone.bind(this,data,index)} data-target="#vestMilestonesModal">{__('Vest milestones')}</a></Menu.Item>}
                {isModifyVesting==1 && data.isMilestone==0 && data.status!="Canceled" &&  <Menu.Item ><a data-toggle="modal" onClick={this.getInitVestingList.bind(this,data,index)} data-target="#accelerateVestingModal">{__('Accelerate vesting')}</a></Menu.Item>}
                { (noPermission('securities_shares_certificate_cancel') === false ) && ( data.status === "Outstanding" &&  <Menu.Item ><a data-toggle="modal"  onClick={this.stopModel.bind(this,data,index)} data-target="#cancelModal">{__('Cancel token grant')}</a></Menu.Item> )}
                { (noPermission('securities_shares_terminate') === false ) && ( data.isCanBeTerminated === 1 &&  <Menu.Item ><a data-toggle="modal"  onClick={this.stopModel.bind(this,data,index)} data-target="#terminateStakeholderModal">{__('Terminate stakeholder')}</a></Menu.Item> )}
                { (noPermission('securities_shares_reverse_termination') === false) && isEmptyTerminationDate == 0 && <Menu.Item ><a onClick={this.openReverseTermination.bind(this,data,index)}>{__('Reverse termination')}</a></Menu.Item>}
            </Menu>
        )
    }

    /**
     * table 行 鼠标移入移出
     * @param row 对象
     * @param index 下标
     * @returns {{onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, onClick: onRowClick}}
     */
    onRow = (row, index) => {
        const { updateShowMsgStatus, curSelectSecurity } = this.props;
        return {
            //鼠标移入
            onMouseEnter : () => {
                updateShowMsgStatus(index, true);
            },
            //鼠标移出
            onMouseLeave : () => {
                updateShowMsgStatus(index, false);
            },
            // onClick : ()=>{
            //     this.onRowClick(row.id)
            //
            // }
        }
    }

    /**
     * 全选change事件
     * @param selectedRowKeys
     */
    onSelectChange = (selectedRowKeys) => {
        const { grantsList, updateSelectCount } = this.props;
        const count = selectedRowKeys.length;
        if(!count){
            updateSelectCount(count, [], true);
        }else{
            let selectIndexArray = [];
            grantsList.forEach((v, k) => {
                selectIndexArray.push({selectIndex:k, checked:true});
            })
            updateSelectCount(count, selectIndexArray);
        }
        this.setState({ selectedRowKeys });
    };

    rowSelection = (selectedRowKeys) => ({
        selectedRowKeys,
        onChange: this.onSelectChange,
        renderCell : (checked, record, index, originNode) => this.securityHover(checked, record, index, selectedRowKeys)
    })

    handleTableChange = (pagination, filters, sorter) => {
        const { onChange } = this.props
        onChange && onChange(pagination, filters, sorter)
    }

    /**
     * Row点击事件
     * @param security_id
     */
    // onRowClick = security_id => {
    //     if(security_id){
    //         openCertificateModal(security_id);
    //     }
    // };

    render() {
        const { selectedRowKeys, contentHeight } = this.state;
        const { grantsList, paginationConfig, sort, loading } = this.props;
        const columns = this.tableColumns(sort)
        return (
            <Table
                onRow={ this.onRow}
                className="stake-table shares-table"
                loading={loading}
                columns={columns}
                dataSource={grantsList}
                rowSelection={this.rowSelection(selectedRowKeys)}
                rowKey="id"
                pagination={false}
                scroll={{ x: 1400 }}
                onChange={this.handleTableChange}
                pagination={{ 
                    ...paginationConfig,
                    position: ['bottomCenter'],
                 }}
            />
        );
    }
}

export default withRouter(GrantTableModel);