import React, { Component } from "react";
import {Button, Card, } from "antd";
import { LeftOutlined } from '@ant-design/icons';
import Calendar from "../../Img/calendar.png";
import "./style.scss";
class SpeakToUs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    getPage = (value) => {
        const { getPage } = this.props;
        getPage(value);
    }

    render() {
        return (
            <div className="billing-change-plan-root">
                <Button className="billing-change-plan-back" onClick={this.getPage.bind(this, 'changePlan')} >
                    <LeftOutlined />Back
                </Button>
                <div className="billing-change-plan-title">Speak with us</div>
                <Card className="billing-speak-us-card">
                    <img src={Calendar} width={89} />
                    <p>
                        If you are looking for customized solutions for your enterprises, <br/>please contact us at
                        &nbsp;<a href="mailto:support@getsprout.co">support@getsprout.co</a>
                        &nbsp;or schedule an appointment with us.
                    </p>

                </Card>
            </div>
        );
    }
}
export default SpeakToUs;