import React, { Component } from 'react';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import security from 'stores/security';
import { formatDate } from 'Utils/format/formatDate';
import classnames from 'classnames';
import moment from 'moment';
import './style.scss'
import frontImg from './images/stock-digital-bg.png'
import frontImgEa from './images/stock-digital-bg-green.png'


export default function DigitalCertificateFront(props) {
    const { data: digital_certificate, type } = props || {}
    const EAType = ['EA','RSA','RSU','ISO','NSO','INTL','SAR']
    const isEA = EAType.includes(type)
    let preContent = ''
    let afterContent = ''
    const positiveContent = digital_certificate.positive_content
    const vestInfo = digital_certificate.vestInfo || {}

    let years = '0'
    const finalTextList = []
    if (isEA) {
        const contentList = positiveContent.split('\n')
        contentList.map((item) => {
            const realText = item.replace(/[\r\n]/g, "")
            if (realText && realText.replace(/[ ]/g, "")) {
                finalTextList.push(realText)
            }
        })
        const t1 = moment(vestInfo.fullyVested)
        const t2 = moment(vestInfo.vestingStart)
        let dura = t1.format('x') - t2.format('x');

        let tempTime = moment.duration(dura);
        years = tempTime.asMonths() / 12
    }
    return <div className={classnames('shares-stock-digital-front', { 'shares-stock-digital-front-ea': isEA })}>
        <img className="frontImg" src={!isEA ? frontImg : frontImgEa} />
        <div className='top-container'>
        <div className="shares-stock-digital-count">
            <span>{digital_certificate.custom_label_id}</span>
            <span>{formatFractionalNumber(digital_certificate.quantity)}</span>
        </div>
        <div className="shares-stock-digital-title">{digital_certificate.company_name || '--'}</div>
        {!isEA && <p className="shares-stock-digital-content" style={{ whiteSpace: 'pre-line' }}>{digital_certificate.positive_content}</p>}
        {isEA && <div>
            <p className="shares-stock-digital-content" style={{ whiteSpace: 'pre-line' }}>{finalTextList[0]}</p>
            {vestInfo?.vestingStart &&
                <p className="shares-stock-digital-content shares-stock-digital-content-middle">
                    <span>Start date of vesting: {formatDate(vestInfo.vestingStart)}</span>
                    <span>Vesting period: {`${years.toFixed(2)} Year(s)`}</span>
                    <span>Cliff: {vestInfo.vestingCliff || 'None'}</span>
                    <span>Completion of full vesting: {formatDate(vestInfo.fullyVested)}</span>
                </p>
            }
            <p className="shares-stock-digital-content" style={{ marginBottom: '10px', marginTop: '15px' }}>{finalTextList[1]}</p>
            <p className="shares-stock-digital-content" style={{ textAlign: 'center' }}>{finalTextList[2]}</p>
        </div>}
        <div className="shares-stock-digital-master">
            {!isEA && (
                <>
                    <div className={classnames({'mar-auto':digital_certificate.certificate_type == 1})}>
                        <span className="sign-name-area">{digital_certificate.certificate1_user.sign ? digital_certificate.certificate1_user.nick_name : ''}</span>
                        <span className="sign-name-border">{digital_certificate.certificate1_user.nick_name}</span>
                    </div>
                    {digital_certificate.certificate_type == 2 &&
                        <div>
                            <span className="sign-name-area">{digital_certificate.certificate2_user.sign ? digital_certificate.certificate2_user.nick_name : ''}</span>
                            <span className="sign-name-border">{digital_certificate.certificate2_user.nick_name}</span>
                        </div>
                    }
                </>)
            }
            {isEA && <div className="esop-container">
                <span className="sign-name-area">{digital_certificate.esop_approver?.sign ? digital_certificate.esop_approver?.nick_name : ''}</span>
                <span className="sign-name-border">ESOP Approver</span>
            </div>}
        </div>
        </div>
    </div>
}