import React, { Component } from 'react';
import Modal, { ModalContent } from 'components/Modal';
import { Button, message } from 'antd';
import { post } from 'srcPath/http';
import { bindKeyEvent } from 'Utils';
import pageIcon from 'assets/images/meeting-icon.png';


class ArchiveMeeting extends Component {
	state = {
		loading: false,
	}

	componentDidMount() {
		this.removeKeyEvent = bindKeyEvent(this.onSubmit)
	}

	componentWillUnmount() {
		this.removeKeyEvent && this.removeKeyEvent()
	}

	onSubmit = async () => {
		const { close, callback } = this.props
		const meeting_id = this.props.id

		this.setState({ loading: true })

		const { data: resData } = await post('archiveMeeting', { meeting_id })

		const { code, data } = resData

		if(code == 0) {
			message.success('Successfully')

			callback && callback()
			close && close()
			this.setState({ loading: false })
		} else {
			message.error(data)
		}

		this.setState({ loading: false })
	}

	render() {
		const { loading} = this.state
		const { close } = this.props

		return <div className="publish-meeting-root">
			<div className="sprout-modal-content">
				<div className="sprout-modal-header">
					Archive Meeting
					<span className="close shut-btn sprout-modal-close" onClick={close}>X</span>
				</div>
				<div className="sprout-modal-detail">
					<div className="publish-meeting-content">
						<div className="publish-meeting-content-front">
							<div className="publish-meeting-img">
								<img src={pageIcon} />
								<div className="publish-meeting-tips">Archive the meeting to make all agenda items read-only. No notifications will be sent.</div>
							</div>
							<div className="publish-meeting-btns">
								 <Button loading={loading} onClick={this.onSubmit} type="primary">Archive Meeting</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	} 
}

export default function openArchiveMeetingModal(id, callback) {
	return Modal.open({
		component: <ArchiveMeeting id={id} callback={callback} />,
		width: 600,
		className: 'sprout-modal'
	})
}