import React, { Component } from "react";
import {Button, DatePicker, Input, Select, Tooltip, Spin, message} from "antd";
import Utils, { calculator, formatWithArray, clearNoNum, filterAmount, setCursorPos, I } from 'Utils';
import AdvanceTermsModels from "../../CreateNew/components/AdvancedTermsModels";
import Modal from 'components/Modal';
import moment from 'moment';
import { post } from 'srcPath/http';
import DateSelector from 'components/DateSelector';

class LeftModels extends Component {
    advanceTermsModal = null;

    constructor(props) {
        super(props);
        this.state = {
            exitValue : '',
            capTableValue : 0,
            nonConvertibleDebtValue : '',
            projectedExitDate : '',
            vestingValue : 1,
            dateFormat : 'DD/MM/YYYY',
        }
    }

    componentDidMount = () => {
        const { getLeftModel } = this.props;
        getLeftModel(this);
    }

    getCapTableList = (list) => {
        return list.map( (v, k) => {
            return (<Select.Option value={v.id}>{v.round_name} as of {v.financing_date}</Select.Option>);
        })
    }

    /**
     * cap table value change (select)
     * @param value
     */
    capTableValueChange = (value) => {
        this.setState(()=>({
            capTableValue : value
        }))
    }

    /**
     * exit value change
     * @param e
     */
    exitValueChange = (e) => {
        const { saveWaterInfo } = this.props;
        const { target } = e;
        const filter = filterAmount(e);
        this.setState(()=>({
            exitValue : filter.value
        }), () => {
            setCursorPos(target, filter.cursorPositionX);
        })
    }

    /**
     * nonConvertibleDebtValueChange
     * @param e
     */
    nonConvertibleDebtValueChange = (e) => {
        const { target } = e;
        const filter = filterAmount(e);
        this.setState(()=>({
            nonConvertibleDebtValue : filter.value
        }), () => {
            setCursorPos(target, filter.cursorPositionX);
        })
    }

    /**
     * projected exit date change
     * @param date
     */
    projectedExitDateChange = (date) => {
        this.setState(()=>({
            projectedExitDate : date
        }))
    }

    /**
     * 跳转页签
     * @param type 1: financing round , 2: waterfall
     */
    typeChange = (type) => {
        const {typeChange} = this.props;
        typeChange(type);
    }

    /**
     * 链接跳转
     * @param path
     */
    getModel = (path) => {
        const { history } = this.props;
        history.push(`/home/fundraise/scenario-modeling/${path}`);
    }

    /**
     * Advanced Terms click 事件
     */
    advancedTermsChange = () => {
        const { vestingValue } = this.state;
        this.advanceTermsModal = Modal.open({
            component: props => <AdvanceTermsModels
                close={props.close}
                vestingValue={vestingValue}
                vestingValueChange={this.vestingValueChange}
            />,
            className: 'certificate-modal-wrap',
            width: '669px',
            maskClosable: false
        })
    }

    vestingValueChange = (value) => {
        this.setState(()=>({
            vestingValue : value
        }))
    }

    componentWillReceiveProps  = (props) => {
        const { waterInfo } = props;
        const { dateFormat } = this.props;
        if(I(waterInfo)){
            this.setState( () => ({
                exitValue : formatWithArray(waterInfo.exit_value),
                capTableValue : waterInfo.round_id,
                nonConvertibleDebtValue : formatWithArray(waterInfo.cn_debt),
                projectedExitDate : moment(waterInfo.exit_date, dateFormat),
                vestingValue : I(parseInt(waterInfo.vesting_type), 0),
            }));
        }
    }

    /**
     * 卸载
     */
    componentWillUnmount() {
        if(this.advanceTermsModal) {
            this.advanceTermsModal.close();
        }
    }

    /**
     * 添加waterfall
     * @returns {Promise<void>}
     */
    saveTerms = async() => {
        const { capTableValue, errorShow, exitValue, projectedExitDate, nonConvertibleDebtValue,
            vestingValue, dateFormat } = this.state;
        const { detailIdChange } = this.props;
        let errorRes = errorShow;
        const stateData = {
            roundId : capTableValue,
            exitValue : clearNoNum({value:exitValue}).value,
            projectedExitDate : I(projectedExitDate, '') && projectedExitDate.format( dateFormat),
            nonConvertibleDebt : clearNoNum({value:nonConvertibleDebtValue}).value,
            vestingType : vestingValue,
            adminId : Utils.getLocalCompanyInfo().admin_id
        };
        //验证
        errorRes = stateData.exitValue > 0 ? false : true;
        this.setState(()=>({
            errorShow : errorRes
        }))
        if(errorRes)
            return;
        try {
            const { getWaterFallDetail } = this.props;
            const { data: resData } = await post('addWaterFall', stateData)
            const { code, data } = resData;
            if (code === 0) {
                message.success('success');
                detailIdChange(data);
                getWaterFallDetail(data);
            } else {
                message.error(data)
            }
        } catch (err) {
            message.error(err && err.message)
        }
    }

    /**
     * 删除waterfall
     * @param id
     * @param index
     * @returns {Promise<void>}
     */
    delModels = async(id) => {
        try {
            const { data: resData } = await post('deleteWaterFall', { id:id })
            const { code, data } = resData;
            if (code === 0) {
                await this.saveTerms();
            } else {
                message.error(data)
            }
        } catch (err) {
            this.setState({ loading: false });
        }
    }

    /**
     * 添加waterfall内容
     */
    addWaterfall = () => {
        const { waterfallLoadingChange } = this.props;
        this.updateWaterfallForm();
        waterfallLoadingChange(true);
        this.saveTerms().then(r => {
            waterfallLoadingChange(false);
        });
    }

    updateWaterfallForm = () => {
        const { capTableValue, exitValue, projectedExitDate, nonConvertibleDebtValue,
            vestingValue, dateFormat } = this.state;
        const { saveWaterInfo } = this.props;
        saveWaterInfo({
            exit_value : clearNoNum({value:exitValue}).value,
            round_id : capTableValue,
            cn_debt : clearNoNum({value:nonConvertibleDebtValue}).value,
            exit_date : I(projectedExitDate, '') && projectedExitDate.format( dateFormat),
            vesting_type : vestingValue,
        });
    }

    /**
     * 更新waterfall内容
     */
    saveWaterfall = () => {
        const { detailId, waterfallLoadingChange } = this.props;
        this.updateWaterfallForm();
        waterfallLoadingChange(true);
        this.delModels(detailId).then(r => {
            waterfallLoadingChange(false);
        });
    }

    render() {
        const { capTableValue, exitValue, nonConvertibleDebtValue, projectedExitDate } = this.state;
        const { bodyHeight, __LANG, capTableList, dateFormat, loading, saveLoading } = this.props;

        return (
            <div className="waterfall-detail-left-box" style={{height:bodyHeight}}>
                <Spin tip="Loading..." spinning={loading}>
                    <div className="content">
                        <div className="waterfall-left-title">{__('Inputs')}</div>
                        <dl>
                            <dt>{__('Exit value')}</dt>
                            <dd>
                                <Input
                                    className="mb-3 mt-3 height-40"
                                    addonBefore={__LANG.amountSymbol}
                                    addonAfter=""
                                    defaultValue=""
                                    value={exitValue}
                                    onChange={this.exitValueChange}
                                />
                            </dd>
                        </dl>

                        <dl>
                            <dt>{__('Cap table')}</dt>
                            <dd>
                                <Select
                                    className="mb-3 mt-3 w100 height-40 waterfall-detail-select"
                                    value={capTableValue}
                                    onChange={this.capTableValueChange}
                                    loading={loading}
                                >
                                    {this.getCapTableList(capTableList)}
                                    <Select.Option value="0">{__('Master cap table')}</Select.Option>
                                </Select>
                                <small className="small-str cursor-poi color-1" onClick={this.getModel.bind(this, 'financing-round')}>{__('Create a pro forma cap table ›')}</small>
                            </dd>
                        </dl>

                        <dl>
                            <dt>{__LANG.nonConvertibleDebt}
                                <Tooltip title={__LANG.nonConvertibleDebtHelp} color="#2b3946">
                                    <span className="table_icons title_icons">?</span>
                                </Tooltip>
                            </dt>
                            <dd>
                                <Input
                                    className="mb-3 mt-3 height-40"
                                    addonBefore={__LANG.amountSymbol}
                                    defaultValue=""
                                    value={nonConvertibleDebtValue}
                                    onChange={this.nonConvertibleDebtValueChange}
                                />
                                <small className="small_str"></small>
                            </dd>
                        </dl>

                        <dl className="mb30">
                            <dt>{__LANG.projectedExitDate}</dt>
                            <dd>
                                <DateSelector
                                    className="height-40 mb-3 mt-3"
                                    inputReadOnly={true}
                                    allowClear={false}
                                    value={projectedExitDate}
                                    onChange={this.projectedExitDateChange}
                                />
                                <small className="small_str"></small>
                            </dd>
                        </dl>

                        <div className="water-left-button-results">
                            <Button type="primary" onClick={this.advancedTermsChange.bind(this, 0)}>{__('Advanced Terms')}</Button>
                        </div>
                        <div className="water-left-button-terms">
                            <Button loading={saveLoading} type="primary" onClick={this.saveWaterfall}>{__('View Results')}</Button>
                        </div>

                    </div>
                </Spin>
            </div>
        );
    }
}
export default LeftModels