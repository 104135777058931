import React, { Component, Fragment } from 'react';
import SimpleTable from '../components/SimpleTable';
import ShareHolder from '../components/ShareHolder';
import HeaderTips from '../components/HeaderTips';
import DigitalCertificate from '../components/DigitalCertificate/DigitalCertificateCom';
import { formatNumber, formatCurrencyNumber } from 'Utils'
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import { formatDate } from 'Utils/format/formatDate';
import {Tooltip} from "antd";


export default function Certificate(props) {
	const { name, shareClass, quantity, certificateFrontAndBack, issuedBy, issueDate, boardApprovedDate, cashPaid, valueofip, otherconsideration, debtcanceled, email, status, shareClassPricePerShare, digital_certificate = {}, company_name, certificateHistory, certificateList, formatText } = props.data || {}
	const pricePerShare = (Number(cashPaid) + Number(valueofip) + Number(otherconsideration) + Number(debtcanceled)) / quantity;
	// 每一股的价格  = （cashPaid + valueofip + otherconsideration + debtcanceled）的和 除以 quantity数量

	const config = [
		{
			label: __('Share class'),
			value: shareClass
		},
		{
			label: __('Quantity'),
			value: formatFractionalNumber(quantity)
		},
		{
			label: __('Cash paid'),
			value: formatCurrencyNumber(cashPaid)
		},
        {
            label: <div>Other consideration<Tooltip title="Other consideration: any other value exchanged for the shares that is not cash, IP, or convertible debt." className="certificate-help-i">?</Tooltip></div>,
            value: formatCurrencyNumber(otherconsideration)
        },
        {
            label: <div>Value of IP transferred<Tooltip title="Value of IP: cash value of the technology or intellectual property given/transferred/granted for use to the company in exchange for shares." className="certificate-help-i">?</Tooltip></div>,
            value: formatCurrencyNumber(valueofip)
        },
		{
			label: <div>Convertibles w/ int. converted<Tooltip title="Convertibles w/ int. converted: This is for convertible notes (Safes / KISS, debt, etc) where the total amount of principal and interest converted into shares." className="certificate-help-i">?</Tooltip></div>,
			value: formatCurrencyNumber(debtcanceled)
		},
		{
			label: __('Share class price per share'),
			value: formatCurrencyNumber(shareClassPricePerShare)
		},
		{
			label: <div>Price per share<Tooltip title="Price per share: sum of all consideration (a, b, c, d, ) divided by the share quantity." className="certificate-help-i">?</Tooltip></div>,
			value: formatCurrencyNumber(pricePerShare),
		},
		{
			label: __('Certificate front and back'),
			value: <DigitalCertificate data={{ ...digital_certificate, company_name}} />
		}
	]

	const issuerConfig = [
		{
			label: __('Issued by'),
			value: issuedBy
		},
		{
			label: __('Issued date'),
			value: formatDate(issueDate)
		},
		{
			label: __('Board approved date'),
			value: formatDate(boardApprovedDate)
		}
	]

	if (certificateHistory?.length > 0) {
		certificateHistory.map((item) => {
			const statusText = item.type !== 'Canceled' ?  ( item.type === 'Settled' ? 'Settle' : (item.type ==='Transferred' ? 'Transfer' : ( item.type ==='Converted' ? 'Convert' : item.type.replace('d', ''))) ) : 'Cancellation'
			issuerConfig.push({
				label: `${statusText} date for ${formatFractionalNumber(item.share)} shares ${item.source || 'from'} ${item.certificatesName}`,
				value: formatDate(item.date)
			})
		})
	}

	return <Fragment>
		{certificateList && certificateList.length && formatText &&
			<HeaderTips data={{ certificateList, formatText }} />
		}
		<div className="certificate-detail-section"><ShareHolder data={{ name, email, status}} /></div>
		 <div className="certificate-detail-section">
			<SimpleTable title="Certificate" config={config} />
		</div>
 		<div className="certificate-detail-section">
			<SimpleTable title="Issuer" config={issuerConfig} />
		</div>		
	</Fragment>
}