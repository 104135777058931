import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useForm, FormItem } from 'react-antd-formutil';
import { formatDateForDatePicker, formatDate2Api } from 'Utils/format/formatDate';
import DateSelector from 'components/DateSelector';
import SelectCostCenter from "components/SelectCostCenter";
import { Button, Space } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import Utils from "Utils";

import './style.scss';

const Item = React.memo(({ index, onAdd, onDelete, total, cost_list}) => {

	return <tr>
		<td style={{width:'180px'}}>
			<FormItem
				name={`costCenterList[${index}].effective_date`}
				$formatter={formatDateForDatePicker}
				$parser={val => val && formatDate2Api(val)}
				$defaultValue= {Utils.getCurDate()}
			>
				<DateSelector allowClear={false} />
			</FormItem>
		</td>
		<td  style={{width:'264px'}}>
			<FormItem
				name={`costCenterList[${index}].id`}
			>
				<SelectCostCenter cost_list={ cost_list } />
			</FormItem>
		</td>
		<td valign="top" align="right">
			<Space>
				{ total === (index + 1) && <Button icon={<PlusOutlined />} onClick={onAdd} type="primary" />}
				{
					total > 1 && <Button onClick={onDelete} icon={<MinusOutlined />} />	
				}
			</Space >
		</td>
	</tr>
}, (currentProps, nextProps) => {
	return false
})

export default React.memo(function CostCenter(props) {
	let idx = useRef(1)
	const [list, setList] = useState([])

	useEffect(() => {
		const data = [...(props.list || [{}])]
		
		setList(data.map(val => {
			idx.current = idx.current + 1
			return { ...val, idx: idx.current }
		}))


	}, [])

	const addItem = () => {
		idx.current = idx.current + 1

		const data = [...list]
		
		data.push({ idx: idx.current })

		setList(data)
	}

	const deleteItem = idx => {
		setList(list.filter(val => val.idx !== idx))
	}

	return <div className="form-relations-root">
			<table>
				<thead>
					<tr>
						<th>{__('Effective date')}</th>
						<th style={{ width: 230 }}>{__('Cost center')}</th>
						<th style={{ width: 105 }}></th>
					</tr>
				</thead>
				<tbody>
					{
						list.map((val, index) => (<Item cost_list={props.cost_list} total={list.length} onAdd={addItem} onDelete={() => deleteItem(val.idx)} index={index} idx={val.idx} key={val.idx} />))
					}
				</tbody>
			</table>
	</div>
}, (currentProps, nextProps) => {
	return false
})



