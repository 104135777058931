import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Meetings from './Meetings';
import Documents from './DocumentsNew';
import BoardConsents from './BoardConsents';
import BoardNotifications from './Notifications';
import Modal from 'components/Modal';
import { message, Button} from 'antd';
import { post } from 'srcPath/http';
import { noPermission } from 'components/Permission';
import { sprintf,printf } from 'Utils/i18n';
import security from 'stores/security';
import './style.scss';

export default class Board extends Component {
    path = this.props.match.path
    modalRef = null

    componentDidMount() {
        !noPermission('company_permission_board_add') && this.getData()
    }

    componentWillUnmount() {
        this.modalRef && this.modalRef.close()
    }

    goToBoard = (event) => {
        event.preventDefault()

        this.props.history.push('/home/permissions/board')
    }

    getData = async () => {
        this.setState({ loading: true })

        try {
            const { data: resData } = await post('getBoardMembers')

            const { data = [], code } = resData || {}

            if (code == 0) {
                if(data.length === 0) {
                    this.modalRef = Modal.open({
                        component: props => <div>
                            <p>{sprintf(__('Welcome to the Board Manager.  You will need to add members to conduct any board business.  Please add your board member(s) %s .'), <a onClick={this.goToBoard}>{__('here')}</a>)}</p>
                            <div style={{ textAlign: 'center', marginTop: 20}}><Button type="primary" onClick={props.close}>{__('Not now')}</Button></div>
                        </div>,
                         className: 'sprout-modal sprout-notification-modal',
                    })
                }
            } else {
                message.error(data)
            }
        } catch(err) {
            message.error(err && err.message)
        }

        this.setState({ loading: false })
    }

    config = security.hasFunction([
        {
            path: `${this.path}/documents`,
            component: Documents,
            permission: 'board_document',
            exact: true,
        },
        {
            path: `${this.path}/meetings`,
            component: Meetings,
            permission: 'board_meeting',
            exact: false,
        },
        {
            path: `${this.path}/consents`,
            component: BoardConsents,
            permission: 'board_consent',
            exact: false,
        },
        {
            path: `${this.path}/notifications`,
            component: BoardNotifications,
            permission: 'board_consent', // board_notifications
            exact: false,
        },
    ]);

    render() {
        const routeConfig = this.config.filter((value, key) => security.hasPermission(value.permission))
        return <div className="sprout-captable-root">
            <Switch>
                {
                    routeConfig.map((val, index) => <Route key={index} exact={val.exact} path={val.path} component={val.component} />)
                }
                <Redirect to={routeConfig && routeConfig[0] && routeConfig[0].path || this.path} />
            </Switch>
        </div>
    }
}