import React, { Component, createRef } from 'react';
import { post } from 'srcPath/http';
import moment from 'moment';
import Modal from 'components/Modal';
import { Link } from "react-router-dom";
import classnames from 'classnames';
import { message, Button, Popover, Input, Dropdown } from 'antd';
import { LeftOutlined, DownOutlined } from '@ant-design/icons';
import url from 'url';
import security from 'stores/security';
import { constData } from 'Utils';
import { formatDate } from 'Utils/format/formatDate';
import { statusMap, colorMap } from './config';
import { Progress } from './progress';
import './index.scss';

export default class ConsentPreview extends Component {
	state = {
		userList: [],
		consent: {},
		signature: '',
		hasPermission: false,
		disabled: false,
		progress: 0,
		companyInfo: {}
	}

	inputRef = createRef()

	async componentDidMount() {
		await this.initCompanyInfo();
		this.getInitConsent();
	}

	async initCompanyInfo() {
		const companyInfo = JSON.parse(localStorage.getItem('COMPANY_USER') || '{}');
		if (!companyInfo.consent_role) {
			try {
				const { data: resData } = await post('getCompanyUserInfoById')
	
				const { code, data } = resData || {}
	
				if (code == 0) {
					this.setState({
						companyInfo: data || {}
					});
				} else {
					message.error(data)
				}
			} catch(err) {
				message.error(err && err.message)
			}
		} else {
			this.setState({
				companyInfo
			});
		}
		return companyInfo;
	}

	getInitConsent = async () => {
		const state = this.props.location.state || {};
		const { consentId, companyInfo = {} } = state;
		try {
			const { data: resData } = await post('getConsentDetail', { consent_id: consentId })

			const { code, data } = resData || {}
			if (code == 0) {
				const disabled = [3, 4, 5, 7, 8].includes(companyInfo.consent_role);
				const boardConsent = data.consent_detail;
				const userList = data.board_user_list.filter((item, index) => {
					item.key = index;
					return item.board_role == 7;
				});
				const filterUser = userList.filter((item) => {
					return item.approval_status
				});
				const progress = filterUser.length / userList.length;
				boardConsent.userList = userList;
				boardConsent.progress = progress;
				this.setState({
					consent: boardConsent,
					progress,
					userList,
					disabled
				});
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}
	}

	onChange(date, dateString) {
		this.setState({
			expirationDate: date
		});
	}

	downloadFile() {
		const bdhtml = window.document.body.innerHTML;
        const sprnstr="{/* startprint */}";
        const eprnstr="{/* endprint */}";
        let prnhtml=bdhtml.substring(bdhtml.indexOf(sprnstr)+17);
        prnhtml = prnhtml.substring(0,prnhtml.indexOf(eprnstr));
        window.document.body.innerHTML=prnhtml;
        window.print();
	}

	async handleOperate(e) {
		const { consent, disabled } = this.state;
		if (disabled) return; 
		const action = e.target.getAttribute("data-action");
		let url = '';
		const params = {
			consent_id: consent.consent_id,
			status: consent.status
		};
		switch (action) {
			case "download":
				this.downloadFile(consent.consent_id);
				break;
			case "edit":
				this.props.history.push({
					pathname: '/home/board/consents/edit',
					state: { consentId: consent.consent_id }
				});
				break;
			case "retract":
				url = 'updateRetractConsent';
				break;
			case "cancel":
				url = 'cancelContent';
				break;
			case "delete":
				url = 'deleteConsent';
				break;
			default:
				break;
		}
		if (['edit', 'download'].includes(action)) return;
		try {
			const { data: resData } = await post(url, params)

			const { code, data } = resData || {}

			if (code == 0) {
				if (['cancel', 'delete'].includes(action)) {
					this.props.history.push('/home/board/consents/list');
				} else {
					this.props.history.push({
						pathname: '/home/board/consents/edit',
						state: { consentId: consent.consent_id }
					});
				}
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}
	}

	handleApprove() {
		return Modal.open({
			component: props => <div>
				<p style={{ textAlign: 'center', paddingTop: 10, paddingBottom: 20, borderBottom: '1px solid #ccc', color: '#364453',  }}>{__('Approve')}</p>
				<p>{__('Approve Template name:')}</p>
				<Input ref={this.inputRef} className="consent-sign-input signature-family" onChange={this.handleChange.bind(this)} />
				<p>{__('Sign as')} "{security.userInfo.signature}".{__('Be sure to include any capitalizations or special characters that appear in your name.')}</p>
				<div style={{ textAlign: 'center', marginTop: 20 }}><Button onClick={this.handleSignNature.bind(this, props)} style={{ width: 100 }} type="primary">{__('Save')}</Button></div>
			</div>,
			closable: true,
			className: 'consent-modal-sign'
		})
	}

	handleChange = event => {
		this.setState({
			signature: event.target.value
		})
	}

	async handleSignNature(props) {
		const { consent, signature } = this.state;
		const consentId = consent.consent_id;
		// if (!signature || signature !== security.userInfo.signature) {
		// 	message.error('Incorrect signature');
		// 	return;
		// }
		try {
			const { data: resData } = await post('approveConsent', {consent_id: consentId, approver_name: signature })

			const { code, data } = resData || {}

			if (code == 0) {
				props.close();
				await security.getTaskData()
				this.getInitConsent();
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}
	}

	render() {
		const { userList, consent, disabled, companyInfo } = this.state;
		const currentUser = userList.find((item) => {
			return item.id == companyInfo.id;
		}) || {};
		
		const canSign = companyInfo.consent_role == 7;
		// 权限控制 暂时放开
		const operationList = <ul className="board-consents-operate-item" onClick={this.handleOperate.bind(this)}>
			{consent.status !== '3' && <li className={classnames({ 'board-consents-operate-disabled': disabled })} data-action="edit">{__('Edit')}</li>}
			{consent.status !== '3' && <li className={classnames({ 'board-consents-operate-disabled': disabled })} data-action="retract">{__('Retract consent')}</li>}
			{/* {consent.status && <li className={classnames({ 'board-consents-operate-disabled': disabled })} data-action="download">Download</li>} */}
			{consent.status !== '4' && <li className={classnames('board-consents-operate-cancel', { 'board-consents-operate-disabled': disabled })} data-action="cancel">{__('Cancel consent')}</li>}
            <li className={classnames('board-consents-operate-cancel', { 'board-consents-operate-disabled': disabled })} data-action="delete">{__('Delete consent')}</li>
        </ul>;

		return <div className="new-board-preview-root ">
					<div className="back-btn-area">
						<Button><LeftOutlined /><Link to="/home/board/consents/list">{__('Back to consents')}</Link></Button>
					</div>
					<div className="template-operate-area">
						<Dropdown overlay={operationList} placement="bottomCenter" trigger="click">
							<Button className="board-preview-action-btn">{__('Actions')} <DownOutlined className="board-preview-action-icon-down" /></Button>
						</Dropdown>
						&nbsp;
						{!currentUser.approval_status &&
							<Button type="primary" disabled={!canSign} onClick={this.handleApprove.bind(this)}>{__('Approve')}</Button>
						}
					</div>
					<div className="board-preview-wrapper-content">
					<div className="board-preview-wrapper-right">
						<div className="board-detail-display-wrapper">
								<p className="board-detail-display-title">{__('Details')}</p>
								<h5 className="board-consent-name">{consent.consent_name}</h5>
								<p className={classnames('board-consent-badge', { 'board-consent-badge-approve': consent.status === '3' })}>{statusMap[consent.status]}</p>
								<p className="board-consent-detail-text">{__('Board consent sent by')} {consent.sent_by} <br/> on {formatDate(consent.create_date)}</p>
						</div>
						<div className="board-detail-display-wrapper">
							<p className="board-detail-display-title">{__('Approvers')}</p>
							<Progress data={consent} />
							<div className="board-approve-members">
							{
									userList.map((v, k)=>{
										return (
											<div key={k} >
												<div>
													<p><b>{v.nick_name}</b><span className="status-dot-consent" style={{ background: v.approval_status ? colorMap[3] : colorMap[1] }}></span>{v.approval_status ? 'Approved' : 'Pending approval'}</p>
													<p><a href={`mailto:${v.email}`}>{v.email}</a></p>
													<p>{constData.boardRole[v.board_role]}</p>
												</div>
											</div>
										)
									})
								}
							</div>
						</div>
						<div className="board-detail-display-wrapper">
							<p className="board-detail-display-title">{__('Expiration and Reminder')}</p>
							<p className="board-consent-detail-text">{__('Expires on')} {formatDate(consent.expiration_date)}</p>
						</div>
					</div>
                	<div className="board-preview-wrapper">
						<div className="board-preview-wrapper-left">
						{/* <!--startprint--> */}
						<div className="board-preview-detail">
							<div className="board-preview-detail-bottom">
								<div dangerouslySetInnerHTML = {{__html:consent.consent_content}}></div>
								<div className="resolution-approvers">
								{
									userList.map((v, k)=>{
										return (
											<div key={k} className="directors-wrapper">
												{v.approval_status &&
													<p className="approve-sign-here"><b>{v.nick_name}</b></p>
												}
												<div className="directors-members">
													<p><b>{v.nick_name}</b></p>
													<p><a href={`mailto:${v.email}`}>{v.email}</a></p>
													<p>{constData.boardRole[v.board_role]}</p>
												</div>
											</div>
										)
									})
								}
								</div>
							</div>
						</div>
						{/* <!--endprint--> */}
					</div>
					</div>
				</div>
					
            </div>
	}
}