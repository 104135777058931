import React, { Component } from 'react';
import {Button, Table} from 'antd';
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import { foundColumns } from './column-config';
import Charts from './FoundCharts';
import { I } from 'Utils';
import customized from "../img/customized.png";

export default class FoundingRounds extends Component {

    getExpandIcon = (expanded, onExpand, record) => {
        const className = `financing-history-table-icon ${I(record.children, []).length <= 0 ? 'financing-history-table-icon-hidden' : ''}`;
        return (
            expanded ? (
                <DownOutlined className={className} onClick={e => onExpand(record, e)} />
            ) : (
                <RightOutlined className={className} onClick={e => onExpand(record, e)} />
            )
        )
    }

    render(){
        const { data = [], currency = 'HKD', getData, query, callback, listLoading } = this.props;
        return (
            <div className="funcing-rounds">
                <div className="finacing-history-chart">
                    <div className="banner">{__('Cash Raised and Post-money Valuation')}</div>
                    <div className="content">
                        <Charts currency={currency} data={data}/>
                    </div>
                </div>
                <div className="finacing-history-found-table">
                    <Table
                        columns={foundColumns(getData, query, callback, listLoading)}
                        dataSource={data}
                        pagination={false}
                        // scroll={{ x: true }}
                        expandable={{
                            expandIcon: ({ expanded, onExpand, record }) => this.getExpandIcon(expanded, onExpand, record),
                        }}
                        loading={listLoading}
                        rowClassName={data => ( I(data.historySecurityList, '')  || I(data.historyShareSecurityList, '') ) ? 'financing-history-found-table-yellow' : '' }
                    />
                </div>
                <div className="financing-history-found-desc">
                    <img src={customized} />
                    <span>:&nbsp;{__('Customized')}</span>
                </div>
            </div>
        )
    }
}