import React, { Component } from "react";
import noPermissionImg from "./Img/no-permission.png";
import security from "stores/security";
import {Button} from "antd";
import "./style.scss";
import { printf } from 'Utils/i18n';

class NoPermission extends Component {

    path = this.props.match.path

    getBilling = () => {
        this.props.history.push('/home/setting/billing');
    }

    render() {
        return (
            <div className="plan-message-root sprout-no-permission-root">
                <img src={noPermissionImg} />
                <p>{printf(__('%s is a premium feature, upgrade your plan and enjoy the feature for free.'), security.functionAuthConfig[this.path])}</p>
                {security.flag == 1 && <Button type="primary" onClick={this.getBilling}>{__('Unlock')}</Button>}
            </div>
        );
    }
}
export default NoPermission;