import React, { Component } from "react";
import { Steps } from 'antd';
import logo from "modules/Auth/images/logo.svg";

class Header extends Component {

    constructor(props) {
        super(props);
    }

    /**
     * 获取页签内容
     * @returns {*}
     */
    getTabList = () => {
        const { tab, tabCurrentType } = this.props;
        const { Step } = Steps;
        return tab.map( ( v, k) => {
            return (
                <Step key={k} status={k <= tabCurrentType ? ( k==tabCurrentType ? 'process' : 'finish' ) : 'wait'} title={v} icon={<div />} />
            );
        })
    }

    /**
     * 更新 tabCurrentType 跳转指定页面
     * @param key
     */
    updateTabCurrentType = (key) => {
        const { updateTabCurrentType } = this.props;
        updateTabCurrentType(key);
    }

    getSprout = () => {
        const { history } = this.props;
        history.push('/');
    }
    
    stepsChange = (i) => {
        const { updateTabCurrentType, createAccountStatus, updateState, pageCurrent } = this.props;
        if(pageCurrent >= i){
            if(i != 0) {
                updateTabCurrentType(parseInt(i));
            }
        }
    }


    render() {
        const { isContact, pageCurrent } = this.props;
        return (
            <div className="signup-header">
                <div className="signup-header-con">
                    <img src={logo} onClick={this.getSprout} />
                    <div className="signup-header-tab">
                        {!isContact && <Steps onChange={this.stepsChange}  current={pageCurrent}>{this.getTabList()}</Steps>}
                    </div>
                    <div className="clear"></div>
                </div>


            </div>
        );
    }
}
export default Header;