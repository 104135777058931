import React, { Component } from 'react';
import Empty from 'components/Empty';
import './style.scss';
import { post } from 'srcPath/http';

export default function LinkTable(props) {
	const dataConfig = props.config || [];

	const handleChangeFolder = async (name) => {
		try {
			const { data: resData } = await post('getS3FileUrl', { file_name: name })
			const { code, data } = resData || {}
			window.open(data);
		} catch (err) {

		}
	}
	
	return <div className="sprout-simple-table">
		<div className="sprout-simple-table-banner">{props.title}</div>
		<div className="sprout-simple-table-content marginBott20">
			{
				dataConfig.length > 0 ? dataConfig.map((val, index) => <div className="sprout-simple-table-content-item" key={index}>
					<div className="sprout-simple-table-content-label" style={{ width: props.rate || '30%' }}>{val.label}</div>
					{/* additional documents */}
					{val?.type && (
						<div className="sprout-simple-table-content-multiply-value">
							{val.value.length > 0 && val.value.map((item) => (
								<div key={item.url} className="sprout-simple-table-content-value">{item.name ? <div className="sprout-simple-table-content-a" onClick={() => handleChangeFolder(item.url)}>{item.name}</div> : '--'}</div>
							))}
							{!val.value.length && <div className="sprout-simple-table-content-value">{'--'}</div>}
						</div>
					)}
					{!val?.type && <div className="sprout-simple-table-content-value">{val.value ? <div className="sprout-simple-table-content-a" onClick={() => handleChangeFolder(val.url)}>{val.value}</div> : '--'}</div>}
				</div>) : <Empty />
			}
		</div>
	</div>
}