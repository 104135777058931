import React from 'react';
import axios from 'axios';
import Utils from "Utils";
import { message } from 'antd';

class AddProperties extends React.Component {
    constructor(props){
        super(props);
        this.state={
            data:[
                {property_name:''},
                {property_name:''},
            ],
            sendStatus:0,
        }
    }
    addPropertyName=()=> {
        let one = {property_name: ''}
        let curList = this.state.data;
        curList.push(one);
        this.setState({
            data:curList
        })
    }
    delPropertyName=(key)=>{
        let curList = this.state.data;
        if(curList.length<=1){
            message.error("You must have at least one custom property.");
            return;
        }
        curList.splice(key,1);
        this.setState({
            data:curList
        })
    }
    setData=(key,e)=>{
        let curList = this.state.data;
        curList[key].property_name =e.target.value;
        this.setState({
            data:curList
        })
    }
    saveProperties=()=>{
        if(this.state.sendStatus===1){
            return;
        }
        this.setState({
            sendStatus:1
        })
        let requestData = this.state.data;
        axios.post('https://api.getsprout.co',{
            action:"addProperties",
            company_id:Utils.getLocalCompanyInfo().company_id,
            data:requestData,
        }).then((response)=>{
            if(response.data.code===0){
                message.success("Success!")
                window.$("#addPropertiesModal").modal("hide");
                let initData = [
                    {property_name:''},
                    {property_name:''}
                ]
                this.setState({
                    data:initData,
                    sendStatus:0
                })
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    render(){
        return(
            <div className="modal fade" id="addPropertiesModal"  tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={{display: "none"}} >
                <div className="modal-dialog dialog-top40">
                    <div className="modal-content box-content relation-content">
                        <div className="modal-header box-header relation-header">
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                            <h4 className="modal-title" id="myModalLabel">Manage custom properties</h4>
                        </div>
                        <div className="modal-body relation-body">
                            <p className="box-text pro-text">Add, edit, or delete stakeholder properties.</p>
                            <table className="table table-striped properties-table">
                                <tbody>
                                    <tr className="first-row">
                                        <td className="proTd">Property name</td>
                                        <td></td>
                                    </tr>
                                    {this.state.data.map((value,key)=>{
                                        if(this.state.data.length == (key+1)){
                                        return(
                                            <tr key={key}>
                                                <td><input name={key} onChange={this.setData.bind(this,key)} className="can-input" placeholder="e.g. Location" value={value.property_name}/></td>
                                                <td className="box-lasttd">
                                                    <div className="btn-box">
                                                        <button type="button"  onClick={this.addPropertyName} className="btn-mp plus"></button>
                                                        <button type="button" onClick={this.delPropertyName.bind(this,key)} className="btn-mp minus"></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}else{
                                            return(
                                                <tr key={key}>
                                                    <td><input name={key} onChange={this.setData.bind(this,key)} className="can-input" placeholder="e.g. Location" value={value.property_name}/></td>
                                                    <td className="box-lasttd">
                                                        <div className="btn-box">
                                                            <button type="button" onClick={this.delPropertyName.bind(this,key)} className="btn-mp minus"></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer box-footer relation-footer">
                            <a  onClick={this.saveProperties}  className="btn boxbtn-to bg-darkblue width200">
                                {this.state.sendStatus===0 && "Save"}
                                {this.state.sendStatus===1 && "Saving"}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
           }
}
export default AddProperties;