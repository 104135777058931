import React from 'react';
import 'assets/css/merge.css';
import 'assets/css/addStakeholder.css';
import Modal, { ModalContent } from 'components/Modal';
import Utils, { I, filterAmount } from "Utils";
import { language } from "Utils/i18n";
import RelationshipSelector from 'components/RelationshipSelector';
import CountrySelector from 'components/CountrySelector';
import CitySelector from 'components/CitySelector';
import { post } from 'srcPath/http';
import {message, Button, Input, Select} from 'antd';
import moment from "moment";
import security from 'stores/security';
import DateSelector from "components/DateSelector";
import AddNewWallet from '../../Wallets/components/AddNewWallet'
import SelectCostCenter from "components/SelectCostCenter";
import ConfirmNotifyModels from "../components/ConfirmNotifyModels";
import Loading from 'components/Loading';

const { Option } = Select;

class AddStakeholder extends React.Component{

    ConfirmNotifyModels = null;

    state = {
        dateFormat:"DD/MM/YYYY",
        data:{
            status:0,
            stakeholder_type:1,
            name:"",
            email:"",
            date_of_birth:"",
            annual_salary:"",
            country_region:"Hong Kong",
            address:"",
            passport_or_national_id:"",
            employee_id:"",
            property_id:"",
            note:"",
            relationship:"",
            relationshipDataList:[
                {effective_date:Utils.getCurDate(),relationship:"Investor"},
            ],
            jobTitle:[
                {effective_date:Utils.getCurDate(),job_title:""}
            ],
            costCenterList:[
                {effective_date:Utils.getCurDate(),cost_center:"",id:""}
            ],
        },
        walletList: [{
            address: ''
        }],
        DuplicateEmails: '',
        genderList: [
            {
                name:''
            },
            {
                name: 'Female'
            },
            {
                name: 'Male'
            },
            {
                name: 'Non-binary / non-conforming'
            },
            {
                name: 'Prefer not to respond'
            },
        ],
        dateOfIncorporation:"",
        loading: false,
        errorStatus:0,
        requiredKey:["name","email","relationshipDataList"],
        relationRequiredKey:["effective_date","relationship"],
    }

    componentDidMount() {
        this.getIncorporationDate();
        const { type, data: propsData } = this.props
        const isEdit = type === 'edit' && propsData?.stakeholder_id > 0

        const { stakeholder_email, name } = propsData || {}
        const { data: newData } = this.state
        if (stakeholder_email) {
            newData.name = name
            newData.email = stakeholder_email
            this.setState({
                data: newData,
            })
        }

        const walletList = []
        if (propsData.wallet_address) {
            if (Array.isArray(propsData.wallet_address)) {
                propsData.wallet_address?.map((item) => {
                    walletList.push({
                        address: item
                    })
                })
            } else {
                walletList.push({
                    address: propsData.wallet_address
                })
            }
            this.setState({
                walletList
            })
        }
        if(isEdit){
            this.getStakeHolderDataById();
        }
    }

    getStakeHolderDataById = async()=>{
        const { data: propsData } = this.props

        try {
            const { data: resData } = await post('getStakeHolderDataById',{ id : propsData.stakeholder_id } )

            const { code, data } = resData || {}

            data.email = propsData.stakeholder_email

            if(!data.jobTitle)
            data.jobTitle = [{effective_date:Utils.getCurDate(),job_title:""}]

            data.costCenterList?.map((item) => {
                if(item.id===0){
                    item.id=""
                }
            })
            if (code === 0) {
                this.setState({ data })
            }
        } catch(e) {
            message.error(e.message)
        }
    }
    showErrorTip=()=>{
        this.setState({
            errorStatus:1
        })
        this.showAddStakeholderError();
    }
    handleCountryChange = country => {
        let curList = this.state.data;

        curList['country_region'] = country

        this.setState({ data: curList })
    }

    walletAddressChange = (walletList) => {
        this.setState({ walletList })
    }
    showAddStakeholderError=()=>{
        // $("#addStakeholderInputError").removeClass("hidden");
        let curData = this.state.data;
        for(let i=0;i<curData.relationshipDataList.length;i++){
            if(curData.relationshipDataList[i].effective_date==""){
                $("#valid_relationship").removeClass("hidden");
                return;
            }else{
                $("#valid_relationship").addClass("hidden")
            }
            // this.state.relationRequiredKey.map((val,k)=>{
            //     if(curData.relationshipDataList[i][val].length==0){
            //         $("#valid_relationship").removeClass("hidden")
            //     }else{
            //         $("#valid_relationship").addClass("hidden")
            //     }
            // })
        }
        this.state.requiredKey.map((value,key)=>{
            if(curData[value].length==0){
                $("#add_"+value).addClass("has-error");
            }else{
                $("#add_"+value).removeClass("has-error");

            }
        })
        let isEmail = Utils.isEmail(this.state.data.email);
        if(!isEmail){
            $("#add_contact_email").addClass("has-error");
        }else{
            $("#add_contact_email").removeClass("has-error");
        }
    }

    confirmStakeholder = () => {
        this.addStakeholder();
    }

    addStakeholder = async()=>{
        const { type, data: propsData, close, cb, coinId} = this.props
        const isEdit = type === 'edit' && propsData?.stakeholder_id > 0
        const { data, walletList } = this.state
        const isWallet = walletList?.[0]?.address ? 1 : 0;
        const userAccessControl = security.companyInfo && security.companyInfo.user_access_control;
        const wallet_address_list = []

        const url = isEdit ? 'editStakeholderData' : 'addStakeHolderData';

        walletList.map((i) => {
            wallet_address_list.push({
                wallet_address: i.address,
                token_tag: '',
            })
        })
        var requestData = data;
        if (isEdit) {
            requestData.id = propsData?.stakeholder_id
        }
        // 数据流 保存walletListFIXME: 这里格式需要调整 字段名
        requestData.wallet_address_list = wallet_address_list

        if (type === 'add') {
            requestData.coin_id = coinId
        }

        try {
            this.setState({ loading: true })

            const { data: resData } = await post(url, requestData)

            const { code, data } = resData || {}

            if (code === 201) {
                this.setState({ loading: false })
                message.error('Mailbox already exists')

                return
            }

            if (code === 10002) {
                this.setState({ loading: false })
                message.error(data)

                return
            }
            close()
            cb && cb()
            if(I(userAccessControl, '')){
                this.ConfirmNotifyModels = Modal.open({
                    component: props => <ModalContent title={__('Send invitation email')} close={props.close}>
                        <ConfirmNotifyModels
                            close={props.close}
                            addStakeholder={this.sendStakeHolderEmail}
                            isWallet={isWallet}
                            type={'addStakeholder'}
                            id={data}
                        />
                    </ModalContent>,
                    width: 1458,
                    maskClosable: false,
                    className: 'sprout-modal'
                })
            }
        } catch (e) {
            this.setState({ loading: false })
            message.error(e.message)
        }
    }

    sendStakeHolderEmail = async(id, close) => {
        Loading.global(true)
        try {
            const { data: resData } = await post('sendStakeHolderEmail', {id})
            const { code, data } = resData;
            if (code === 0) {
                message.success(__('Success!'));
                close && close();
            } else {
                message.error(`error: ${data?.errorCode}`);
            }
        } catch (err) {
            message.error(err && err.message)
        }
        Loading.global(false)
    }


    getIncorporationDate = async()=>{
        try {
            const { data: resData } = await post('getDateOfIncorporation')

            const { code, data } = resData || {}

            if (code === 0) {
                this.setState({ getDateOfIncorporation: data.date_of_incorporation })
            }

        } catch(e) {
            message.error(e.message)
        }
    }

    addRelation=()=> {
        let one = {effective_date: Utils.getCurDate(), relationship: "Investor"}
        let curList = this.state.data;
        curList.relationshipDataList.push(one);
        this.setState({
            data:curList
        })
    }
    setRelation=(key, value)=>{
        let curList = this.state.data;
        curList.relationshipDataList[key].relationship = value;
        curList.relationship = value;
        this.setState({
            data:curList
        })
    }

    JobRecordData = (key, e) => {
        let curList = this.state.data;
        curList.jobTitle[key].job_title = e.target.value;
        this.setState({
            data: curList
        })
    }

    delJobTitle = (num) => {
        let curList = this.state.data;
        if (curList.jobTitle.length <= 1) {
            message.error("You only have one job title now!")
            return;
        }
        curList.jobTitle.splice(num, 1);
        this.setState({
            data: curList
        })
    }
    addJobTitle = () => {
        let one = { effective_date: Utils.getCurDate(), job_title: "" }
        let curList = this.state.data;
        curList.jobTitle.push(one);
        this.setState({
            data: curList
        })
    }

    handleClose = () => {
        this.props.close()
    }
    delRelation=(num)=>{
        let curList = this.state.data;
        if(curList.relationshipDataList.length <=1){
            message.error("You only have one relationship now!")
            return;
        }
        curList.relationshipDataList.splice(num,1);
        this.setState({
            data:curList
        })
    }

    setCost = (key, value) => {
        const { cost_list } = this.props;
        const languageType = language =='zh_CN' ? 'zh_cn' : (language == 'en_US' ? 'en_us' : 'zh_tw' )
        let curList = this.state.data;
        if(value){
            curList.costCenterList[key].id = value;
            curList.costCenterList[key].cost_center = cost_list[cost_list.findIndex(i=>i.id == value)][languageType];
        }else{
            curList.costCenterList[key].id = '';
            curList.costCenterList[key].cost_center = '';
        }        this.setState({
            data: curList
        })
    }
    addCost = () => {
        let one = { effective_date: Utils.getCurDate(), cost_center: "",id:"" }
        let curList = this.state.data;
        curList.costCenterList.push(one);
        this.setState({
            data: curList
        })
    }

    delCost = (num) => {
        let curList = this.state.data;
        if (curList.costCenterList.length <= 1) {
            message.error("You only have one cost center now!")
            return;
        }
        curList.costCenterList.splice(num, 1);
        this.setState({
            data: curList
        })
    }

    recordData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        });
    }

    /**
     * input change通用事件
     * @param isDecimal 是否需要小数
     * @param e
     */
    inputChange = (isDecimal, e) => {
        const { target:element } = e;
        const { name:dataKey } = element;
        const filter = filterAmount(e, 9999999999999, isDecimal);
        let curData = this.state.data;
        curData[dataKey] = filter.value;
        this.setState({
            data: curData
        }, () => {
            element.selectionStart = element.selectionEnd = filter.cursorPositionX;
        });
    }

    /**
     * date_of_birth
     * @param key
     * @param moment_key
     * @param date
     */
    handleBirthdayChange = (key, moment_key, date) => {
        const { dateFormat } = this.state;
        let curData = this.state.data;
        curData[key] = date?.format( dateFormat);
        curData[moment_key] = date;
        this.setState({
            data:curData
        });
    }

    /**
     * relationshipEffectiveDateChange
     * @param listName
     * @param name
     * @param key
     * @param date
     */

    relationshipEffectiveDateChange = (listName, name, key, date) => {
        const { data, dateFormat } = this.state;
        let curData = { ...data };
        curData[listName][key][name] = date?.format(dateFormat);
        this.setState({
            data: curData
        });
    }
    genderSelectChange = (gender) => {
        let curList = this.state.data
        curList['gender'] = gender
        this.setState({
            data: curList
        })
    }
    delJobTitle = (num) => {
        let curList = this.state.data;
        if (curList.jobTitle.length <= 1) {
            message.error("You only have one job title now!")
            return;
        }
        curList.jobTitle.splice(num, 1);
        this.setState({
            data: curList
        })
    }
    addJobTitle = () => {
        let one = { effective_date: Utils.getCurDate(), job_title: "" }
        let curList = this.state.data;
        curList.jobTitle.push(one);
        this.setState({
            data: curList
        })
    }
    JobRecordData = (key, e) => {
        let curList = this.state.data;
        curList.jobTitle[key].job_title = e.target.value;
        this.setState({
            data: curList
        })
    }
    handleCountryChange = country => {
        let curList = this.state.data;

        curList['country_region'] = country

        this.setState({ data: curList })
    }

    handleCityChange = city => {
        let curList = this.state.data;

        curList['city'] = city

        this.setState({ data: curList })
    }
    
    walletAddressChange = (walletList) => {
        this.setState({ walletList })
    }

    render(){
        const { walletList, errorStatus, data, dateFormat, genderList, DuplicateEmails, loading } = this.state
        const { type, cost_list } = this.props
        let curErrorStatus = 1;
        let curData = this.state.data;
        this.state.requiredKey.map((value,key)=>{
            if(value == "email"){  //email check
                let isEmail = Utils.isEmail(this.state.data.email);
                if(!isEmail){
                    curErrorStatus = 0;
                }
            }
            if(value==="relationshipDataList"){
                for(let i=0;i<curData.relationshipDataList.length;i++){
                    if(curData.relationshipDataList[i].effective_date.length===0||curData.relationshipDataList[i].relationship.length===0){
                        curErrorStatus =0
                    }
                }
            }
            if(this.state.data[value].length ===0){
                curErrorStatus = 0;
            }

        })
        // wallet信息校验
        // walletList.map((wallet) => {
        //     if (!wallet?.address) {
        //         curErrorStatus = 0
        //     }
        // })
        const { currency } = security.companyInfo
        return(
            <div>
                <div>
                    <div className="modal-content box-content add-content width620" style={{border:'none'}}>
                        <div className="modal-header box-header">
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.handleClose.bind(this)}>×</button>
                            <h4 className="modal-title" id="myModalLabel">{`${type != 'edit' ?__('Add stakeholder'): __('Edit stakeholder')}`}</h4>
                        </div>
                        <div className="modal-body box-body add-body">
                            <div>
                                <div className="body-one">
                                    <p className="basic-header header-border">{__('Basic information')}</p>
                                    <form className="add-form form-horizontal">
                                        <div className="from-group add-items">
                                            <span className="col-xs-4 label-nopadding add-label">{__('Stakeholder type')}</span>
                                            <div className="col-xs-8">
                                                <div className="radio status-radio holders-radio">
                                                    <input id="individual" type="radio" name="stakeholder_type" value="1" checked={this.state.data.stakeholder_type=="1"} onChange={this.recordData} />
                                                    <label htmlFor="individual" className="radio-label holder-label holders-radioLabel">{__('Individual')}</label>
                                                </div>
                                                <div className="radio status-radio holders-radio">
                                                    <input id="non_individual" type="radio" name="stakeholder_type"  value="0" checked={this.state.data.stakeholder_type=="0"} onChange={this.recordData}/>
                                                    <label htmlFor="non_individual" className="radio-label holder-label holders-radioLabel">{__('Non-individual')}</label>
                                                </div>
                                                <p className="text-muted">{__('Non-individual stakeholders can be investment firms companies, corporations, etc')}</p>
                                            </div>
                                        </div>
                                        <div className="form-group add-items">
                                            <label className="col-xs-4 add-label">{__('Name')}<br/>
                                                <i><small className="text-muted">- {__('Required')}</small></i>
                                            </label>
                                            <div className="col-xs-6" id={"add_name"}>
                                                <input className="form-control" name="name" value={this.state.data.name} onChange={this.recordData} type="text" />
                                            </div>
                                        </div>
                                        <div className="form-group add-items">
                                            <label className="col-xs-4 add-label">{__('Email')}<br/>
                                                <i><small className="text-muted">- {__('Required')}</small></i>
                                            </label>
                                            <div className="col-xs-6" id={"add_contact_email"}>
                                                <input className="form-control" name="email" value={this.state.data.email} onChange={this.recordData} type="email" />
                                            </div>
                                        </div>
                                        {this.state.data.stakeholder_type == 1 &&
                                        <div className="form-group add-items">
                                            <label className="col-xs-4 add-label">{__('Date of birth')}<br/>
                                                <i><small className="text-muted">- {__('Optional')}</small></i>
                                            </label>
                                            <div className="col-xs-4">
                                                <DateSelector
                                                    onChange={this.handleBirthdayChange.bind(this, 'date_of_birth', 'date_of_birth_moment')}
                                                    value={I(data['date_of_birth_moment'], I(data['date_of_birth'], '') ? moment(data['date_of_birth'], dateFormat) : '')}
                                                    inputReadOnly={false}
                                                />
                                            </div>
                                        </div>
                                        }
                                        {this.state.data.stakeholder_type == 1 &&
                                        <div className="form-group add-items">
                                            <label className="col-xs-4 add-label">{__('Gender')}<br />
                                                <i><small className="text-muted">- {__('Optional')}</small></i>
                                            </label>
                                            <div className="col-xs-6">
                                                <Select className="form-control select-container gender-select" name="gender" defaultValue={data.gender} onChange={this.genderSelectChange}>
                                                    {genderList.map((value, key) => {
                                                        return (
                                                            <Option value={value.name} key={key}>{value.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        }
                                        {this.state.data.stakeholder_type == 1 &&
                                        <div className="form-group add-items ">
                                            <label className="col-xs-4 add-label">{__('Annual salary')}<br/>
                                                <i><small className="text-muted">- {__('Optional')}</small></i>
                                            </label>
                                            <div className="col-xs-4">
                                                <div className="input-group"><span
                                                    className="input-group-addon">{currency}</span>
                                                    <input name="annual_salary" className="form-control"
                                                           value={data.annual_salary}
                                                           onChange={this.inputChange.bind(this, true)}/>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        <div className="relationship marginBott10">
                                            <p className="marginBott10">{__('Relationship')}<br/>
                                            <i><small className="text-muted">- {__('Required')}</small></i></p>
                                            <table className="table table-striped relationship-table marginBott20">
                                        <tbody>
                                        <tr className="first-row">
                                            <td className="effective-date">{__('Effective date')}</td>
                                            <td>{__('Relationship')}</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        {this.state.data.relationshipDataList?.map((value,key)=>{

                                            if(this.state.data.relationshipDataList.length == (key+1)){
                                                return(

                                                    <tr key={key}>
                                                        <td id={"add_effective_date"+key} className={"width280"}>
                                                            <DateSelector
                                                                onChange={this.relationshipEffectiveDateChange.bind(this, 'relationshipDataList', 'effective_date', key)}
                                                                value={ I(value.effective_date, '') ? moment(value.effective_date, dateFormat) : ''}
                                                                inputReadOnly={false}
                                                                allowClear={false}
                                                            />
                                                        </td>
                                                        <td className="advisor-td relationship-td"  id={"add_relationship"+key}>
                                                            <RelationshipSelector style={{ width: 254 }} value={value.relationship} onChange={this.setRelation.bind(this,key)} />
                                                        </td>
                                                        <td className="box-lasttd">
                                                            <div className="btn-box relationship-btnBox">
                                                                <button type="button" onClick={this.addRelation} className="btn-mp plus"></button>
                                                                <button type="button" onClick={this.delRelation.bind(this,key)} className="btn-mp minus"></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }else{
                                                return(
                                                    <tr key={key}>
                                                        <td id={"add_effective_date"+key} className="width280">
                                                            <DateSelector
                                                                onChange={this.relationshipEffectiveDateChange.bind(this, 'relationshipDataList', 'effective_date', key)}
                                                                value={ I(value.effective_date, '') ? moment(value.effective_date, dateFormat) : ''}
                                                                inputReadOnly={false}
                                                                allowClear={false}
                                                            />
                                                        </td>
                                                        <td className="advisor-td relationship-td" id={"add_relationship"+key}>
                                                            <RelationshipSelector style={{ width: 254 }} value={value.relationship} onChange={this.setRelation.bind(this,key)} />
                                                        </td>
                                                        <td className="box-lasttd">
                                                            <div className="btn-box relationship-btnBox">
                                                                <button onClick={this.delRelation.bind(this,key)} type="button" className="btn-mp minus"></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        })}
                                        </tbody>
                                    </table>
                                            <p className="alarm_text  hidden" id="valid_relationship"><span className="alarm_text glyphicon glyphicon-exclamation-sign"></span> A valid relationship is required.</p>
                                        </div>
                                        <div className="relationship marginBott10">
                                            <p className="marginBott10">{__('Cost center')}<br />
                                                <i><small className="text-muted">- {__('Optional')}</small></i></p>
                                            <table className="table table-striped table-rel marginBott20">
                                                <tbody>
                                                <tr className="first-row">
                                                    <td className="effective-date">{__('Effective date')}</td>
                                                    <td>{__('Cost center')}</td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                {this.state.data.costCenterList?.map((value, key) => {

                                                    if (this.state.data.costCenterList.length == (key + 1)) {
                                                        return (
                                                            <tr key={key}>
                                                                <td className={"width180"}>
                                                                    <DateSelector
                                                                        onChange={this.relationshipEffectiveDateChange.bind(this, 'costCenterList', 'effective_date', key)}
                                                                        value={I(value.effective_date, '') ? moment(value.effective_date, dateFormat) : ''}
                                                                        inputReadOnly={false}
                                                                        allowClear={false}
                                                                    />
                                                                </td>
                                                                <td className="advisor-td relationship-td"  style={{ width: '271px' }} id={"add_cost" + key}>
                                                                    <SelectCostCenter cost_list={ cost_list }  value={value.id} label={value.cost_center}  onChange={this.setCost.bind(this, key)}  />
                                                                </td>
                                                                <td className="box-lasttd">
                                                                    <div className="btn-box btnBox-top12">
                                                                        <button type="button" onClick={this.addCost} className="btn-mp plus"></button>
                                                                        <button type="button" onClick={this.delCost.bind(this, key)} className="btn-mp minus"></button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }else {
                                                        return (
                                                            <tr key={key}>
                                                                <td className={'width180'}>
                                                                    <DateSelector
                                                                        onChange={this.relationshipEffectiveDateChange.bind(this, 'costCenterList', 'effective_date', key)}
                                                                        value={I(value.effective_date, '') ? moment(value.effective_date, dateFormat) : ''}
                                                                        inputReadOnly={false}
                                                                        allowClear={false}
                                                                    />
                                                                </td>
                                                                <td className="advisor-td relationship-td"  id={"add_cost" + key}>
                                                                    <SelectCostCenter cost_list={cost_list}  style={{ width: '254px' }} value={value.id}  label={value.cost_center} onChange={this.setCost.bind(this, key)}/>
                                                                </td>
                                                                <td className="box-lasttd">
                                                                    <div className="btn-box btnBox-top12">
                                                                        <button onClick={this.delCost.bind(this, key)} type="button" className="btn-mp minus"></button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                })}
                                                </tbody>
                                            </table>
                                            <p className="alarm_text  hidden" id="valid_relationship"><span className="alarm_text glyphicon glyphicon-exclamation-sign"></span> A valid relationship is required.</p>

                                        </div>

                                        {this.state.data.stakeholder_type == "1" &&
                                        <div className="Jobtitle marginBott10">
                                            <p className="marginBott10">{__('Job title')}<br />
                                                <i><small className="text-muted">- {__('Optional')}</small></i></p>
                                            <table className="table table-striped table-rel marginBott20 ">
                                                <tbody>
                                                <tr className="first-row">
                                                    <td className="effective-date">{__('Effective date')}</td>
                                                    <td>{__('Job title')}</td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                {this.state.data.jobTitle && this.state.data.jobTitle.map((value, key) => {

                                                    if (this.state.data.jobTitle.length == (key + 1)) {
                                                        return (
                                                            <tr key={key}>
                                                                <td className={"width280"}>
                                                                    <DateSelector
                                                                        onChange={this.relationshipEffectiveDateChange.bind(this, 'jobTitle', 'effective_date', key)}
                                                                        value={I(value.effective_date, '') ? moment(value.effective_date, dateFormat) : ''}
                                                                        inputReadOnly={false}
                                                                        allowClear={false}
                                                                    />
                                                                </td>
                                                                <td className="advisor-td relationship-td">
                                                                    <Input className="link-cursor" style={{width:254}} value={value.job_title} onChange={this.JobRecordData.bind(this, key)} />
                                                                </td>
                                                                <td className="box-lasttd">
                                                                    <div className="btn-box btnBox-top12">
                                                                        <button type="button" onClick={this.addJobTitle} className="btn-mp plus"></button>
                                                                        <button type="button" onClick={this.delJobTitle.bind(this, key)} className="btn-mp minus"></button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    } else {
                                                        return (
                                                            <tr key={key}>
                                                                <td className={'width280'}>
                                                                    <DateSelector
                                                                        onChange={this.relationshipEffectiveDateChange.bind(this, 'jobTitle', 'effective_date', key)}
                                                                        value={I(value.effective_date, '') ? moment(value.effective_date, dateFormat) : ''}
                                                                        inputReadOnly={false}
                                                                        allowClear={false}
                                                                    />
                                                                </td>
                                                                <td className="advisor-td relationship-td">
                                                                    <Input className="link-cursor" style={{width:254}} value={value.job_title} onChange={this.JobRecordData.bind(this, key)} />
                                                                </td>
                                                                <td className="box-lasttd">
                                                                    <div className="btn-box btnBox-top12">
                                                                        <button onClick={this.delJobTitle.bind(this, key)} type="button" className="btn-mp minus"></button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                })}
                                                </tbody>
                                            </table>
                                        </div>
                                        }
                                    </form>
                            </div>

                            <div className="body-one ">
                                <p className="basic-header header-border">{__('Address information')}</p>
                                <form className="add-form form-horizontal">
                                    <div className="form-group add-items">
                                        <label className="col-xs-4 add-label">{__('Country / Region')}<br/>
                                            <i><small className="text-muted">- {__('Optional')}</small></i>
                                        </label>
                                        <div className="col-xs-6">
                                            <CountrySelector style={{ width: '100%' }} value={this.state.data.country_region} onChange={this.handleCountryChange} />
                                        </div>
                                    </div>
                                    <div className="form-group add-items">
                                        <label className="col-xs-4 add-label">{__('Address details')}<br/>
                                            <i><small className="text-muted">- {__('Optional')}</small></i>
                                        </label>
                                        <div className="col-xs-6">
                                            <input className="form-control text-area" name="address" value={this.state.data.address} onChange={this.recordData} type="text"/>
                                        </div>
                                    </div>
                                    { security.env !== 'prod' &&
                                    <div className="form-group add-items">
                                        <label className="col-xs-4 add-label">{__('Tax city')}<br />
                                            <i><small className="text-muted">- {__('Optional')}</small></i>
                                        </label>
                                        <div className="col-xs-6">
                                            <CitySelector style={{ width: '100%' }} value={this.state.data.city} onChange={this.handleCityChange} />
                                        </div>
                                    </div>
                                    }
                                </form>
                            </div>
                            <div className="body-one">
                                <p className="basic-header header-border">{__('Identification numbers')}</p>
                                <form className="add-form form-horizontal">
                                    <div className="form-group add-items">
                                        <label className="col-xs-4 add-label">{__('Passport or National ID')}<br/>
                                            <i><small className="text-muted">- {__('Optional')}</small></i>
                                        </label>
                                        <div className="col-xs-6">
                                            <input className="form-control"  name="passport_or_national_id" value={this.state.data.passport_or_national_id} onChange={this.recordData} type="text"/>
                                        </div>
                                    </div>
                                    <div className="form-group add-items">
                                        <label className="col-xs-4 add-label">{__('Employee ID')}<br/>
                                            <i><small className="text-muted">- {__('Optional')}</small></i>
                                        </label>
                                        <div className="col-xs-4">
                                            <input className="form-control"  name="employee_id" value={this.state.data.employee_id} onChange={this.recordData} type="text"/>
                                        </div>
                                    </div>
                                    <div className="form-group add-items">
                                        <label className="col-xs-4 add-label">{__('Property ID')}<br/>
                                            <i><small className="text-muted">- {__('Optional')}</small></i>
                                        </label>
                                        <div className="col-xs-4">
                                            <input className="form-control"  name="property_id" value={this.state.data.property_id} onChange={this.recordData} type="text"/>
                                        </div>
                                    </div>
                                    <div className="form-group add-items">
                                        <label className="col-xs-4 add-label">{__('Note')}<br/>
                                            <i><small className="text-muted">- {__('Optional')}</small></i>
                                        </label>
                                        <div className="col-xs-4">
                                            <input className="form-control"  name="note" value={this.state.data.note} onChange={this.recordData} type="text"/>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <AddNewWallet addType={1}  data={walletList} onChange={this.walletAddressChange} errorStatus={0} />
                            </div>
                            {this.state.errorStatus===1 &&
                            <div id="addStakeholderInputError" className="alert alert-warning alert-dismissible pink-box mt20">
                                <p>{__('Required field(s) or email cannot be empty or in the wrong format.')}</p>
                            </div>
                            }
                        </div>
                        <div className="modal-footer box-footer add-footer">
                            {curErrorStatus===0 && <button type="button" onClick={this.showErrorTip} className="btn boxbtn-to width200 bg-darkblue">{`${type != 'edit' ?__('Add stakeholder'): __('Edit stakeholder')}`}</button>}
                            {curErrorStatus === 1 &&
                            <Button loading={loading} type="primary" onClick={this.confirmStakeholder}>
                                {loading  ? (`${type != 'edit' ?__('Adding'): __('Editing')}`) : (`${type != 'edit' ?__('Add stakeholder'): __('Edit stakeholder')}`)}
                            </Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default function addStakeholderModal(data, type, cb, cost_list, coinId) {
	return Modal.open({
        component: <AddStakeholder data={data} type={type} cb={cb} coinId={coinId} cost_list={cost_list} />,
		maskClosable: true,
        width: 620,
        className: 'add-stakeholder-modal',
	})
}

