import React, { Component } from 'react';
import { post } from 'srcPath/http';
import { message, Table, Button, Dropdown, Spin, Input } from 'antd';
import { noPermission } from 'components/Permission';
import './index.scss';

export default class WireInstructions extends Component {
	state = {
		textContent: '',
		loading: true,
		btnLoading: false
	}

	componentDidMount() {
		this.getInstructionsData();
    }

    async getInstructionsData() {
    	this.setState({ loading: true })

        try {
			const { data: resData } = await post('getInstructions')

			const { code, data } = resData || {}

			if (code == 0) {
				this.setState({
					textContent: data.instructions_content || ''
				});
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}

		this.setState({ loading: false })
	}
	
	handleTextChange (e) {
		const value = e.target.value;
		this.setState({
			textContent: value
		});
	}

	async handleUpdate() {
		const { textContent } = this.state;
		if (!textContent) {
			message.error('Please input the wire instructions first.');
			return;
		}

		this.setState({ btnLoading: true })

		try {
			const { data: resData } = await post('editInstructions', { instructions_content: textContent })

			const { code, data } = resData || {}

			if (code == 0) {
				this.setState({
					userInfo: data
				});
				message.success(__('success!'))
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}

		this.setState({ btnLoading: false })
	}

	render() {
		const { textContent, loading, btnLoading } = this.state;
		const disabled = noPermission('fundraising_wireInstructions_deit')

		return <div className="wire-instructions-root">
				<Spin spinning={loading}>
					<>
						<h3 className="wire-instructions-page-title">{__('Manage wire instructions')}</h3>
		                <div className="wire-instructions-content">
		                    <p className="wire-instructions-content-desc">{__('These wire instructions will be shown to your investors')}</p>
		                    <div className="wire-instructions-detail">
								<Input.TextArea  disabled={disabled} rows={8} value={textContent} onChange={this.handleTextChange.bind(this)}></Input.TextArea>
		                    </div>
		                    <div className="wire-instructions-btnwrap">
		                    	<Button disabled={disabled} loading={btnLoading} type="primary" className="wire-instructions-btn-update" onClick={this.handleUpdate.bind(this)}>{__('Update wire instructions')}</Button>
		                    </div>
		                </div>
	                </>
                </Spin>
            </div>
	}
}