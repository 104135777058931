import React, {Component, Fragment} from "react";
import {Button, Card, Col, Form, Input, message, Row, Tooltip} from "antd";
import { I, calculator, formatNumber } from 'Utils';
import { post } from 'srcPath/http';
import Loading from 'components/Loading';
import successImgWathet from "../../img/scccess-wathet.png";
import successImg from "../../img/success.png";
import speakUs from "../../img/speak-us.png";
import planEverything from "../../img/plan-everything.png";
import "./style.scss";

class UpdatePlan extends Component {

    formRef = React.createRef();

    state = {
        loading: false,
        stretchShow: false,
        goodsCodeError: false,
        goodsCodeDisabled: true,
        goodsCodeErrorStr: '',
        planList: {},
        //订阅周期
        subscriptionCycle: 3,
        planInfo: {},
        confirmData: {},
    }

    Lang = {
        subscriptionCycleDesc: {3:'month', 4:'year'},
        goodsCodeError: 'Invalid product code. Please try again.',
    };

    planType = {0:0, 1:4, 2:3, 3:3, 4:4};

    componentDidMount() {
        this.getItemList();
    }

    /**
     * 获取产品信息
     */
    getItemList = async () => {
        this.setState({ loading: true })
        try {
            const { data: resData } = await post('getProduct', { id: 0 })
            const { code, data } = resData;
            const { planList } = data;
            this.payOverView(planList);
        } catch(err) {
            message.error(err && err.message)
        }
    }

    payOverView = async(planList) => {
        try {
            const { data: resData } = await post('payOverView')
            const { code, data } = resData;
            if (code === 0) {
                if(!I(data, '')){
                    message.error(data)
                    return;
                }
                this.setState({ planInfo: data, subscriptionCycle: this.planType[data.planType], planList: this.initPlanList(planList, data.planId, data.is_stripe_import) });
            } else {
                message.error(data)
            }
        } catch (err) {
            message.error(err && err.message)
        }
        this.setState({ loading: false })
    }

    initPlanList = (planList, planId, isStripeImport) => {
        Object.keys(planList).map( (v, k)=>{
            if(k === 3 || (planId == 2 && v == 1) ){
                delete planList[v];
            }
        });
        if(I(isStripeImport, '') || planId >= 3){
            planList = {};
        }
        return {...planList}
    }

    /**
     * 权益说明
     * @param data
     * @returns {*}
     */
    getDetailList = (list, planId) => {
        return list && list.map( (v, k) => (k !== 0 && <li key={k}><img src={successImg} className="pick-plan-content-detail-icon" /><div className="pick-plan-content-detail-value">{v}</div></li>))
    }

    getPlanLabel = (key) => {
        const { planList, subscriptionCycle } = this.state;
        const newPlanList = Object.values(planList);
        const newKey = key === 2 ? key+1 : key-1;
        const { plan_label } = newPlanList[newKey] && newPlanList[newKey][subscriptionCycle] || {}
        return <span>{plan_label}</span>;
    }

    getPlanMPrice = (price) => {
        const mPrice = calculator.agile(`${price} / 12`);
        const newPrice = mPrice && mPrice.split('.');
        const decimal = newPrice && newPrice[1];
        return decimal > 0 ? formatNumber(mPrice, 0) : mPrice;
    }

    getPage = (data) => {
        const { getPage } = this.props;
        const { planInfo } = this.state;
        const { planEndDate } = planInfo;
        if(!planEndDate){
            message.error('Couldn\'t find the subscription number, please contact us to generate one.');
            return;
        }
        getPage(1, data);
    }

    onFinish = (planId, key, data) => {
        const { goodsCode } = data;
        this.getGoodsInfo(planId, key, goodsCode);
    }

    formChange = (changeValue) => {
        const { goodsCode } = changeValue;
        this.setState({goodsCodeError: false, goodsCodeErrorStr: '', goodsCodeDisabled: !I(goodsCode, '') });
    }

    getGoodsInfo = (planId, key, goodsCode) => {
        this.setState({goodsCodeError: false});
        if(!I(goodsCode, '')){
            this.setState({goodsCodeError: true});
            return;
        }
        this.getGoodsPriceByCode(planId, goodsCode);
    }

    getGoodsPriceByCode = async (planId, goodsCode) => {
        Loading.global(true);
        try {
            const { data: resData } = await post('getGoodsPriceByCode', { code: goodsCode })
            const { code, data } = resData;
            if( code === 0){
                const { goods, goods_price } = data;
                const { id, goods_name} = goods;
                const { subscriptionCycle, planInfo } = this.state;
                const { endDate, planType, planAmount, planName: oldPlanName, planStartDate, planEndDate } = planInfo;
                const { getPage } = this.props;
                if(!I(goods_price, '')){
                    Loading.global(false);
                    this.setState({goodsCodeError: 'error', goodsCodeErrorStr: this.Lang.goodsCodeError});
                    return;
                }
                const { id: priceId, price } = goods_price[subscriptionCycle] || {};
                if(!I(price, '')){
                    Loading.global(false);
                    this.setState({goodsCodeError: 'error', goodsCodeErrorStr: this.Lang.goodsCodeError});
                    return ;
                }
                getPage(1, {planId: id, oldPlanName, planName: goods_name, endDate, itemPrice: price, currentPrice: planAmount, subscriptionCycle, planStartDate, planEndDate, type: this.getConfirmType(planType, subscriptionCycle), priceId });
            }else{
                this.setState({goodsCodeError: 'error', goodsCodeErrorStr: this.Lang.goodsCodeError});
            }
        } catch(err) {
            message.error(err && err.message)
        }
        Loading.global(false);
    }

    /**
     * 判断是否是更新订阅
     * @param planType 1:year, 2:month
     * @param subscriptionCycle 3:month, 4:year
     * @returns {number}
     */
    // getConfirmType = (planType, subscriptionCycle) => ( planType == 1 && subscriptionCycle == 4 ) ? 1 : 2;
    getConfirmType = (planType, subscriptionCycle) => 1;

    getPickPlanList = () => {
        const { stretchShow, goodsCodeError, goodsCodeErrorStr, goodsCodeDisabled, loading, planList, subscriptionCycle, planInfo } = this.state;
        const { planId, endDate, planType, planAmount, planName: oldPlanName, planStartDate, planEndDate, isStripeImport } = planInfo;
        return Object.keys(planList).map( (v, k)=>{
            const { plan_name, plan_remark, plan_label, item_price, recurring, plan_details, id: priceId } = planList[v] && planList[v][subscriptionCycle]  || {};
            const planDescDetail = plan_details && JSON.parse(plan_details);
            return  <Col className="pick-plan-content-row" span={7} key={k}>
                <div className="pick-plan-content-box">
                    {v == 3 ? <div className="pick-plan-content-bottom"></div> : ''}
                    <dl>
                        <dt>
                            <div className="pick-plan-content-title">{plan_name}</div>
                            <div className="pick-plan-content-desc">{plan_remark}</div>
                            {
                                v == 3 ? (
                                    <div className="pick-plan-content-speak">
                                        <img className="pick-plan-content-speak-usimg" src={speakUs} />
                                        {/*<div className="pick-plan-content-speak-desc pick-plan-content-speak-desccur" onClick={this.stretchShowChange}>{plan_label}<img src={triangle} /></div>*/}
                                        <div className="pick-plan-content-speak-desc pick-plan-content-speak-desccur">{plan_label}</div>
                                    </div>
                                ) : (
                                    <Fragment>
                                        <div className="pick-plan-content-box-important">
                                            <div className="pick-plan-content-setup">
                                                <span className="pick-plan-content-setup-price">US${formatNumber(recurring == 4 ? this.getPlanMPrice(item_price) : item_price, 0)}</span><span className="pick-plan-content-setup-cycle">&nbsp;/&nbsp;month</span>
                                                <div className="pick-plan-content-setup-pricedesc">{recurring == 4 ? `(US$${formatNumber(item_price, 0)} a ${I(this.Lang.subscriptionCycleDesc[recurring], '')})` : ''}</div>
                                                <p className="pick-plan-content-setup-desc">{plan_label}</p>
                                            </div>
                                        </div>
                                        <div className="pick-plan-content-graphical"></div>
                                    </Fragment>
                                )
                            }
                        </dt>
                        <dd className="pick-plan-content-detail">
                            <div className="pick-plan-content-detail-title">{planDescDetail ? planDescDetail[0] : ''}</div>
                            <ul>{this.getDetailList(planDescDetail, v)}</ul>
                        </dd>
                        <dd className="pick-plan-content-button">
                            {v == 3 && (
                                <Form className="pick-plan-content-button-code" ref={this.formRef} onFinish={this.onFinish.bind(this, v, k)} onValuesChange={this.formChange}>
                                    <Form.Item name="goodsCode" validateStatus={goodsCodeError} help={goodsCodeErrorStr}>
                                        {/*<Form.Item name="goodsCode" validateStatus={'error'} help={'Invalid product code. Please try again.'}>*/}
                                        <Input placeholder="Enter product code" />
                                    </Form.Item>
                                    <Tooltip placement="right" title="Enter the code we provided to complete your subscription."><span className="pick-plan-content-button-code-icon">!</span></Tooltip>
                                    <Form.Item className="pick-plan-content-button-item"><Button disabled={goodsCodeDisabled} type="primary" htmlType="submit">Upgrade</Button></Form.Item>
                                </Form>
                            )}
                            {v != 3 && <Button onClick={this.getPage.bind(this, {planId: v, oldPlanName, planName: plan_name, endDate, itemPrice: item_price, currentPrice: planAmount, subscriptionCycle, planStartDate, planEndDate, type: this.getConfirmType(planType, subscriptionCycle), priceId })}
                                               disabled={planId == v }>{planId == v ? 'Subscribed' : 'Upgrade'}</Button>}
                        </dd>
                    </dl>
                </div>
            </Col>
        })
    }

    yearMonthChange = (value) => {
        this.setState({subscriptionCycle: value});
    }

    getEveryThingPlan = () => {
        const {goodsCodeError, goodsCodeErrorStr, goodsCodeDisabled, planInfo } = this.state;
        const { is_stripe_import: isStripeImport, planId } = planInfo;
        return ( I(isStripeImport, '') || planId >= 3 ) && <Col className="pick-plan-content-row" span={7}>
            <div className="pick-plan-content-box pick-plan-content-everything">
                <dl>
                    <dt>
                        <div className="pick-plan-content-title">Everything else</div>
                        <div className="pick-plan-content-desc">Customized solutions for larger organizations</div>
                        <div className="pick-plan-content-speak">
                            <img className="pick-plan-content-speak-everimg" src={planEverything} />
                        </div>
                    </dt>
                    <dd className="pick-plan-content-detail">
                        <div className="pick-plan-content-detail-p">Talk to us and we can find the ideal solution to fit your needs</div>
                    </dd>
                    <dd className="pick-plan-content-button">
                        <Form className="pick-plan-content-button-code" ref={this.formRef} onFinish={this.onFinish.bind(this, 1, 0)} onValuesChange={this.formChange}>
                            <Form.Item name="goodsCode" validateStatus={goodsCodeError} help={goodsCodeErrorStr}>
                                <Input placeholder="Enter product code" />
                            </Form.Item>
                            <Tooltip placement="right" title="Enter the code we provided to complete your subscription."><span className="pick-plan-content-button-code-icon">!</span></Tooltip>
                            <Form.Item className="pick-plan-content-button-item"><Button disabled={goodsCodeDisabled} type="primary" htmlType="submit">Upgrade</Button></Form.Item>
                        </Form>
                    </dd>
                </dl>
            </div>
        </Col>
    }

    render() {
        const { loading, planList, subscriptionCycle, planInfo } = this.state;
        const { planType } = planInfo;
        if (loading) {
            return <Loading />
        }
        return (
            <div className="setting-plan-root">
                <h2 className="setting-plan-title">Plans and pricing</h2>
                <Card className="setting-plan-card">
                    <Row className={`setting-plan-content ${Object.keys(planList).length == 2 && 'setting-plan-content-left'}`}>
                        {this.getPickPlanList()}
                        {this.getEveryThingPlan()}
                    </Row>
                </Card>
            </div>
        );
    }
}
export default UpdatePlan;