import React, {Fragment} from 'react';
import Utils, { formatNumber, I,formatCurrencyNumber, calculator } from "Utils";
import {Collapse, message} from "antd";
import { paidViaList } from "Utils/constant";
import DeleteDoc from "../../components/DeleteDoc";
import { post } from 'srcPath/http';
import axios from "axios";
import moment from "moment";
import DateSelector from "../../../../../components/DateSelector";
import { DateCustomer } from 'components/DateSelector'
import {formatDate} from "Utils/format/formatDate";
import security from 'stores/security';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import {DeleteOutlined, EditOutlined, FolderOpenOutlined, DatabaseOutlined, DiffOutlined } from "@ant-design/icons";

class ModifySARs extends React.Component{
    constructor(props){
        super(props);
        this.state={
            dateFormat:"DD/MM/YYYY",
            is_remove:0,
            is_show_delete_modal:0,
            uploadProgress:0,
            uploadingStatus:0,
            isAvailable:-1,
            ableToStep3:0,
            errorKeyArr:[],
            isShowErrorTip:0,
            quantityError:0,
            documentActionArr : {'add_document':1,'remove_document':1},
            checkboxStatus:{
                custom_label_id:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"custom_label_id",fieldName:__("Custom label")},
                issue_date:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"issue_date",fieldName:__("Issue date")},
                grant_expiration_date:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"grant_expiration_date",fieldName:__("Sar expiration date")},
                board_approval_date:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"board_approval_date",fieldName:__("Board approval date")},
                exercise_price:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"exercise_price",fieldName:__("Exercise price")},
                quantity:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"quantity",fieldName:__("Quantity")},
                last_day_to_exercise:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"last_day_to_exercise",fieldName:__("Exercisable date")},
                incentive_plan_file:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"incentive_plan_file",fieldName:__("Equity incentive plan")},
                form_of_settlement_agreement_file:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"form_of_settlement_agreement_file",fieldName:__("Form of settlement agreement")},
                form_of_grant_file:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"form_of_grant_file",fieldName:__("Grant letter")},
                add_document:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"add_document",fieldName:__("Add more additional documents")},
                remove_document:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"remove_document",fieldName:__("Remove all additional documents")},
                notes:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"notes",fieldName:__("Notes")},
            },
            checkExerciseHistory:[],
            formatExerciseHistoryList:{},
            currencyList:['USD','HKD','SGD',],
            modifyReason:[
                "Clerical error",
                "Amendment"
            ],
            optionPlan:[],
            eaPlanList:[],
            periodList:["","Days","Months","Years",],
            data:{
                modify_id:"Option",
                reason:"",
                note:"",
                basicTemplateData:
                    {
                        custom_label_id:"",
                        issue_date:"",
                        grant_expiration_date:"",
                        board_approval_date:"",
                        quantity:"",
                        exercise_price:"",
                        exercise_history:[
                            {id:"",exercise_date:"",quantity:"",custom_label_id:""}
                        ],
                        amount_to_be_collected:0,
                        payment_collected:"",
                        actual_exercise_price:0,
                        payment_verified_admin_id:"",
                        exercise_legend:"",
                        add_document:{name:__("No file selected"),file_obj:""},
                        form_of_settlement_agreement_file:{name:__("No file selected"),file_obj:""},
                        form_of_grant_file:{name:__("No file selected"),file_obj:""},
                        incentive_plan_file:{name:__("No file selected"),file_obj:""},
                        remove_document:"",
                        notes:"",
                    },
                changedKey:[],
            },
            requiredKey:[
                "custom_label_id",
                "issue_date",
                "grant_expiration_date",
                "board_approval_date",
                "exercise_price",
                "last_day_to_exercise",
                "quantity",
                "add_document",
                "form_of_settlement_agreement_file",
                "form_of_grant_file",
                "incentive_plan_file",
                "remove_document",
                "notes",
            ],
            exerciseHistoryOptionalKey:[
                "exercise_date",
                "exercise_file",
                "market_price",
                "payment_date",
                "paid_via",
                "payment_verified_admin_id",
            ],
            initData: {
                key:0,
                id:2,
                name:'Cody Belseager',
                status:'Outstanding',
                quantity:'100',
                exercised_quantity:'100',
                vested_quantity:"200",
                vested:'100',
                vested_bar:"",
                exercise_price:'',
                exercise_history:[],
                ea_type:'IOS',
                grant_date:'12/12/2019',
                last_modified:"12/12/2019",
                vesting_schedule:"No vesting schedule",
                price_per_share:'$1.00',
                transaction_value:'$100.00',
                issue_date:'12/12/2019',
                checkbox_status:'unchecked',
                sign_status:'2',
                custom_label_id:"",
                grant_expiration_date:"",
                board_approval_date:"",
                currency:"",
                notes:"",
                admin_note:"",
                form_of_grant_file:{name:"",file_obj:""},
                form_of_settlement_agreement_file:{name:"",file_obj:""},
                incentive_plan_file:{name:"",file_obj:""},
                add_document:{name:"",file_obj:""},
                remove_document:"",
                payment_date:"",
                amount_to_be_collected:0,
                payment_collected:"",
                actual_exercise_price:0,
                payment_verified_admin_id:"",
            },
        }
    }
    submitActionForSar=()=>{
        const { checkExerciseHistory, formatExerciseHistoryList } = this.state
        if(this.state.uploadingStatus ===1){
            return;
        }
        this.setState({
            uploadingStatus:1
        });
        // if(false && Utils.isLocal()){
        //     this.props.setModifiedSecurity(this.state.initData.key,this.state.initData);
        //     window.$("#modifySarModal").modal("hide");
        //     this.resetAction();
        //     return;
        // }
        let requestData = {
            reason:this.state.data.reason,
            modify_note:this.state.data.modify_note,
            modify_id: this.state.initData.id
        };
        for(let key in this.state.checkboxStatus){
            if(this.state.checkboxStatus[key].checked){
                requestData[key] = this.state.data.basicTemplateData[key];
            }
        }
        if(checkExerciseHistory.length>0){
            let exerciseHistory = []
            let dataKey = [
                "exercise_date",
                "market_price",
                "payment_verified_admin_id",
                "paid_via",
                "payment_date",
                "exercise_file",
            ]
            for(let i=0; i<checkExerciseHistory.length; i++){
                for(let j =0; j<dataKey.length; j++){
                    if(checkExerciseHistory[i][dataKey[j]+i].checked){
                        exerciseHistory.push(formatExerciseHistoryList[checkExerciseHistory[i].id])
                    }
                }
            }
            requestData.exercise_history = exerciseHistory
        }
        if(this.state.document_action_type==="add_document" && this.state.data.basicTemplateData.add_document.name!==__('No file selected')){
            //这里说明选择新增并且上传了文件
            requestData.document_action_type = 'add_document';
        }else if(this.state.document_action_type==="remove_document"){
            // if(this.state.data.basicTemplateData.remove_document !== 'DELETE'){
            //     alert("You should type DELETE while you want to remove all the documents!!");
            //     return;
            // }else{
            //     requestData.document_action_type = 'remove_document';
            // }
            requestData.document_action_type = 'remove_document';
        }else{
            requestData.document_action_type = '';
        }

        axios.post('https://api.getsprout.co', {
            company_id:Utils.getLocalCompanyInfo().company_id,
            action:"modifySar",
            data:requestData
        },{
            onUploadProgress:(progressEvent)=>{
                let complete = (progressEvent.loaded / progressEvent.total * 100 | 0);
                console.log('上传 ' + complete+'%') ;
                this.setState({
                    uploadProgress:complete
                });
                if(complete == 100){
                    this.setState({
                        isShowUploadProgress:0
                    })
                }
            }
        }).then((response)=> {
            if(response.data.code === 0){
                //这里需要将返回的数据覆盖到主页面
                this.props.getEaList();
                // for(let key in this.state.checkboxStatus){
                //     if(this.state.checkboxStatus[key].checked){
                //         if(key === "add_document" || key==="remove_document"){
                //             continue;
                //         }
                //         this.state.initData[key] = this.state.data.basicTemplateData[key];
                //     }
                // }
                // let initData = this.state.initData;
                // initData.status = response.data.data.status;
                // initData.quantity = Utils.resetNumberWithCommas(initData.quantity);
                // if(response.data.data.hasOwnProperty("custom_label_id")){
                //     initData.custom_label_id = response.data.data.custom_label_id;
                // }
                // this.props.setModifiedSecurity(this.state.initData.key,initData,"sarsList");
                window.$("#modifySarModal").modal("hide");
                this.setState({
                    uploadProgress:0,
                    uploadingStatus:0
                });
                this.resetAction();
                this.setState({
                    is_remove:0,
                })
            }else{
                message.error(response.data.data)
            }
        }).catch(function (error) {
            message.error(error && error.message)
        });


    }

    /**
     * exercise history data onchange
     * @param e
     */
    recordExerciseData = (key,e) =>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData.basicTemplateData.exercise_history[key][dataKey] = dataVal
        this.setState({
            data:curData
        });
    }

    recordData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        });

    }
    recordCurData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData.basicTemplateData[dataKey] = dataVal;
        this.setState({
            data:curData
        });
    }
    recordNumData=(e)=>{
         const isFractionalShare = security.companyInfo.fractional_control
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        if(dataKey == 'exercise_price'){
            curData.basicTemplateData[dataKey] = Utils.check5DecimalNumInputWithoutComma(dataVal);
        }else{
            if(isFractionalShare==1){
                curData.basicTemplateData[dataKey] = Utils.check5DecimalNumInputWithoutComma(dataVal);
            }else{
                curData.basicTemplateData[dataKey] = Utils.checkNumInput(dataVal);
            }
        }
        this.setState({
            data:curData
        });
    }
    formatNumData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData.basicTemplateData[dataKey] = formatNumber(dataVal,null);
        this.setState({
            data:curData
        });
    }
    focusNumData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData.basicTemplateData[dataKey] = Utils.resetNumberWithCommas(dataVal);
        this.setState({
            data:curData
        });
    }

    /**
     * Exercise history num onchange
     * @param e
     */

    recordExerciseNumData=(key,e)=>{
        const isFractionalShare = security.companyInfo.fractional_control;
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        if(isFractionalShare==1){
            curData.basicTemplateData.exercise_history[key][dataKey] = Utils.check5DecimalNumInputWithoutComma(dataVal);
        }else{
            curData.basicTemplateData.exercise_history[key][dataKey] = Utils.checkNumInput(dataVal);
        }
        this.setState({
            data:curData
        });
    }
    formatExerciseNumData=(key, e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData.basicTemplateData.exercise_history[key][dataKey] = formatNumber(dataVal,null);
        this.setState({
            data:curData
        });
    }
    focusExerciseNumData=(key,e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData.basicTemplateData.exercise_history[key][dataKey] =  Utils.resetNumberWithCommas(dataVal);
        this.setState({
            data:curData
        });
    }

    checkedStatus = (e)=>{
        let curChecked = this.state.checkboxStatus;
        let dataKey = e.target.name;
        curChecked[dataKey].checked = !curChecked[dataKey].checked;
        if(curChecked[dataKey].checked){
            curChecked[dataKey].bg="modify-whiteInput";
            curChecked[dataKey].disabled="";
        }else{
            curChecked[dataKey].bg="modify-darkInput"
            curChecked[dataKey].disabled="disabled";
        }
        let documentActionArr = this.state.documentActionArr;
        if(documentActionArr.hasOwnProperty(dataKey)){
            if(curChecked[dataKey].checked){
                this.setState({document_action_type:dataKey});
                for(let key in documentActionArr){
                    if(key !== dataKey){
                        curChecked[key].checked = false;
                        curChecked[key].bg="modify-darkInput"
                        curChecked[key].disabled="disabled";
                    }
                }
            }
        }
        this.setState({
            checkboxStatus:curChecked
        })
    }

    /**
     * onchange of checkbox in exercise history
     * @param e
     */
    exerciseCheckedStatus = (key,e)=>{
        let curChecked = this.state.checkExerciseHistory;
        let dataKey = e.target.name;
        curChecked[key][dataKey].checked = e.target.checked;
        if(curChecked[key][dataKey].checked){
            curChecked[key][dataKey].bg="modify-whiteInput";
            curChecked[key][dataKey].disabled="";
        }else{
            curChecked[key][dataKey].bg="modify-darkInput"
            curChecked[key][dataKey].disabled="disabled";
        }
        this.setState({
            checkExerciseHistory:curChecked
        })
    }

    selectFile=(e)=>{
        let uploadFile = e.target.files[0];
        let dataKey = e.target.name;
        let curData = this.state.data;
        Utils.uploadFile(uploadFile).then((result)=>{
            curData.basicTemplateData[dataKey].name = uploadFile.name;
            curData.basicTemplateData[dataKey].file_obj = result;
            this.setState({
                data:curData
            });
        });
    }

    selectExerciseFile=(key,e)=>{
        let uploadFile = e.target.files[0];
        let dataKey = e.target.name;
        let curData = this.state.data;
        Utils.uploadFile(uploadFile).then((result)=>{
            curData.basicTemplateData.exercise_history[key][dataKey].name = uploadFile.name;
            curData.basicTemplateData.exercise_history[key][dataKey].file_obj = result;
            curData.basicTemplateData.exercise_history[key][dataKey].file_size = uploadFile.size;
            this.setState({
                data:curData
            });
        });
    }

    checkCustomLabelId=()=>{
        const { data } = this.state;
        const { curSelectSecurity } = this.props;
        post('checkCustomLabelId',{custom_label_id: data.basicTemplateData.custom_label_id, security_plan_id: curSelectSecurity.security_plan_id, security_type: curSelectSecurity.security_type}).then((response)=> {
            if(response.data.code === 0){
                this.setState({
                    isAvailable:response.data.data.isAvailable
                }, ()=>this.checkNextAvailabel())
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    resetAction=()=>{
        let curData = this.state.data;
        curData.reason="";
        curData.modify_note="";
        curData.modify_id="";
        for(let key in this.state.checkboxStatus){
            if(this.state.checkboxStatus[key].checked){
                if(key=="form_of_settlement_agreement_file"||key=="form_of_grant_file"||key=="add_document"||key=="incentive_plan_file"){
                    curData.basicTemplateData[key].name=__("No file selected");
                    curData.basicTemplateData[key].file_obj="";
                }else{
                    curData.basicTemplateData[key]="";
                }
                this.state.checkboxStatus[key].checked = false;
                this.state.checkboxStatus[key].disabled = "disabled";
            }

        }
        this.setState({
            data:curData,
            quantityError:0,
            isShowErrorTip:0,
            isAvailable:-1,
        })
        window.$("#modifySarModal .tab-pane").removeClass("active");
        window.$("#modifySarReason").addClass("active");
    }
    targetToStep3=()=>{
        this.setState({
            quantityError:0,
            isShowErrorTip:0,
            isAvailable:-1,
        })
        window.$("#modifySarModal .tab-pane").removeClass("active");
        window.$("#reviewSarModifications").addClass("active");
    }

    formatExerciseList = () =>{
        const {data,} = this.state
        let formatHistoryList = this.state.formatExerciseHistoryList
        for(let i=0; i<data.basicTemplateData.exercise_history.length; i++){
            formatHistoryList[data.basicTemplateData.exercise_history[i].id] = Object.assign({},data.basicTemplateData.exercise_history[i]);
        }
        this.setState({
            formatExerciseHistoryList:formatHistoryList
        })

    }
    checkNextAvailabel=()=>{
        this.formatExerciseList()
        let flag =true; //用于验证勾选的checkbox有没有填值
        let isRequiredCheckbox = false;
        let isOptionalCheckbox = false;
        let isCheckedForExerciseOptionalKey = false;//用于验证exerciseOptionalKey有没有勾选checkbox

        let curData = this.state.data;
        let formatPlan = this.props.formatEaPlanList;
        let checkKey = this.state.requiredKey;
        let curQuantity = Utils.resetNumberWithCommas(curData.basicTemplateData.quantity);
        let availableQuantity = formatPlan[this.props.curSelectSecurity.security_plan_id].available;
        for(let i=0;i<this.state.requiredKey.length;i++){
            if(this.state.checkboxStatus[checkKey[i]].checked){
                isRequiredCheckbox = true;
                if(checkKey[i]=="add_document"||checkKey[i]=="form_of_settlement_agreement_file"||checkKey[i]=="form_of_grant_file"||checkKey[i]=="incentive_plan_file"){
                    if(!curData.basicTemplateData[checkKey[i]].file_obj){
                        this.state.errorKeyArr.push(checkKey[i]);
                        flag = false;
                    }
                }else if(checkKey[i]=="custom_label_id"){
                    if(!curData.basicTemplateData[checkKey[i]] || this.state.isAvailable==0) {
                        flag = false;
                    }
                }else if(checkKey[i]=="quantity"){
                    if(!curData.basicTemplateData[checkKey[i]]){
                        flag = false;
                    }
                    if(curQuantity>availableQuantity){
                        flag = false;
                        this.setState({
                            quantityError:1
                        })
                    }else{
                        this.setState({
                            quantityError:0
                        })
                    }
                }else{
                    if(!curData.basicTemplateData[checkKey[i]]){
                        this.state.errorKeyArr.push(checkKey[i]);
                        flag = false;
                    }
                }
            }
        }
        let optionalExerciseCheckKey = this.state.exerciseHistoryOptionalKey;
        if(!isRequiredCheckbox){
            for(let j=0; j<this.state.checkExerciseHistory.length; j++){
                for(let k=0;k<optionalExerciseCheckKey.length;k++){
                    const exerciseName = optionalExerciseCheckKey[k]+j
                    const exerciseLabel = this.state.checkExerciseHistory[j][exerciseName]
                    const exerciseLabelValue = curData.basicTemplateData.exercise_history[j][optionalExerciseCheckKey[k]]
                    if(exerciseLabel.checked){
                        flag = true;
                        isCheckedForExerciseOptionalKey = true;
                        if(!exerciseLabelValue){
                            curData.basicTemplateData.exercise_history[j][optionalExerciseCheckKey[k]] = this.state.initData.exercise_history[j][optionalExerciseCheckKey[k]];
                            this.setState({
                                    data:curData
                                }
                            )
                        }
                    }
                }

            }

        }
        if(!isRequiredCheckbox && !isCheckedForExerciseOptionalKey ){
            flag = false;
        }
        if(flag){
            // if(!isRequiredCheckbox){
            //
            // } else{
            //     this.targetToStep3();
            // }
                this.targetToStep3();

        }else {
            if (this.state.isAvailable == 0) {
                this.setState({
                    isShowErrorTip: 0,
                })
            } else {
                //开始展示错误信息：勾选了但是没填
                if(isRequiredCheckbox) {
                    this.setState({
                        isShowErrorTip: 1,
                    })
                }
            }
        }
    }
    setSarInitInfo=()=>{
        const { curSelectSecurity,formatVerifiedAdminList } = this.props;
        let curData = this.state.data;
        let checkStatusExercise = this.state.checkExerciseHistory;
        let formatHistoryList = this.state.formatExerciseHistoryList;
        for(let i=0;i<curSelectSecurity.exercise_history.length; i++){
            const exerciseHistoryObj = {
                ['exercise_date'+i]:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"exercise_date",fieldName:__("Exercise date")},
                ['payment_date'+i]:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"payment_date",fieldName:__("Payment date")},
                ['market_price'+i]:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"market_price",fieldName:__("Market price")},
                ['paid_via'+i]:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"paid_via",fieldName:__("Paid via")},
                ['payment_verified_admin_id'+i]:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"payment_verified_admin_id",fieldName:__("Payment verified by")},
                ['exercise_file'+i]:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"exercise_file",fieldName:__("Form of exercise")},
            }
            curData.basicTemplateData.exercise_history[i] = Object.assign({},curSelectSecurity.exercise_history[i])
            curData.basicTemplateData.exercise_history[i].exercise_file = {name:"No file selected", real_name:"",file_size:""}
            exerciseHistoryObj.id = Object.assign({},curSelectSecurity.exercise_history[i]).id
            checkStatusExercise[i] = exerciseHistoryObj
            formatHistoryList[curData.basicTemplateData.exercise_history[i].id] = Object.assign({},curSelectSecurity.exercise_history[i]);
        }
        this.setState({
            data: curData,
            checkExerciseHistory: checkStatusExercise,
            initData:curSelectSecurity,
            formatExerciseHistoryList:formatHistoryList
        });
    }
    showDeleteModal=()=>{
        this.setState({
            is_show_delete_modal:1,
        })
    }
    closeDeleteModal=()=>{
        this.setState({
            is_show_delete_modal:0,
        })
    }
    deleteAllDoc=()=>{
        this.setState({
            is_remove:1
        })
    }

    modifySarDateChange = (key, moment_key, date) => {
        const { dateFormat } = this.state;
        let curData = this.state.data;
        curData.basicTemplateData[key] = date.format( dateFormat);
        curData.basicTemplateData[moment_key] = date;
        this.setState({
            data:curData
        });
    }

    lastDayToExerciseChange = (date) => {
        const { data } = this.state;
        let curData = {...data};
        curData.basicTemplateData.last_day_to_exercise = date ?  date : '';
        this.setState({
            data:curData
        });
    }

    modifySarExerciseDateChange = (key,name,date) => {
        const {data, dateFormat } = this.state;
        let curData = {...data};
        curData.basicTemplateData['exercise_history'][key][name] = date.format( dateFormat);
        this.setState({
            data:curData
        });
    }
    render(){
        const { currency = '', curSelectSecurity, verifiedAdminList, formatVerifiedAdminList } = this.props
        const { data, dateFormat, checkboxStatus, checkExerciseHistory, quantityError, formatExerciseHistoryList } = this.state;
        let modifyKey = this.state.data.modify_id;
        let showSelectCount = 0;
        let isAbleToModifyQuantity = 0;
        if(this.props.curSelectSecurity.status!="Exercised"
            &&this.props.curSelectSecurity.status!="Partially exercised"
            &&this.props.curSelectSecurity.vested_quantity>0
            &&this.props.curSelectSecurity.vesting_schedule ==""){
            isAbleToModifyQuantity = 1;
        }

        const expirationDateLongDesc = {'-1': __('No expiration date')};
        const disableCheck = curSelectSecurity.termination_date == "" ? "disabled-checkbox":""

        return(
            <div className="modal fade" id="modifySarModal" tabIndex="-1"  data-backdrop="static" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="modal-dialog dialog960 dialog-top40">
                    <div className="tab-content">
                        <div role="tabpanel" className="modal-content content720 tab-pane active" id="modifySarReason">
                            <div className="modal-header box-header">
                                <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                                <h4 className="modal-title" id="myModalLabel">{__('Modify SAR')}</h4>
                            </div>
                            <div className="modal-body padding2030">
                                <div className="shareTitleBox">
                                    <div className="iconBox bg-lightgreen">
                                        <EditOutlined />
                                    </div>
                                    <div className="shareTextBox">
                                        <p className="shareTitle">{__('Select a reason for modifying the selected SAR')}</p>
                                        <p className="text-muted">{__('Some modifications will trigger an email to the stakeholder. You can review the modifications and email notifications before saving your changes.')}</p>
                                    </div>
                                </div>
                                <div className="padding2026026">
                                    <div className="form-group add-items">
                                        <div className="row  profile-row">
                                            <label className="col-xs-5 add-label">{__('Reason')}<br/><i className="text-muted"><small>- {__('Required')}</small></i></label>
                                            <div className="col-xs-4">
                                                <select name="reason"  value={this.state.data.reason} onChange={this.recordData} className="form-control select-container">
                                                    <option value="">---</option>
                                                    {this.state.modifyReason.map((value,key)=>{
                                                        return(
                                                            <option key={key}>{value}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group add-items">
                                        <div className="row  profile-row">
                                            <label className="col-xs-5 add-label">{__('Note')} </label>
                                            <div className="col-xs-6">
                                                <textarea name="note" onChange={this.recordData} value={this.state.data.note} className="form-control text-area minHeight100" type="text"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div role="presentation" className="modal-footer box-footer no-paddingtop">
                                {this.state.data.reason==""&&
                                <a className="btn boxbtn-to send-disabledBtn">{__('Next: modify value')}</a>
                                }
                                {this.state.data.reason!=""&&
                                <a href="#modifySarValue"  onClick={this.setSarInitInfo} role="tab" data-toggle="tab" className="btn boxbtn-to bg-darkblue">{__('Next: modify value')}</a>
                                }
                            </div>
                        </div>
                        <div role="tabpanel" className="modal-content content860 tab-pane" id="modifySarValue">
                            <div className="modal-header box-header">
                                <a href="#modifySarReason"  aria-controls="modifySarReason" role="tab" data-toggle="tab" className="back-btn"></a>
                                <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.resetAction}>×</button>
                                <h4 className="modal-title" id="myModalLabel">{__('Modify SAR')} {this.state.initData.custom_label_id}</h4>
                            </div>
                            <div className="modal-body padding2030">
                                {this.state.isShowErrorTip == 1 && quantityError == 0 &&
                                <div id="inviteCompanyInputError" className="alert alert-warning alert-dismissible pink-box">
                                    <p >{__('The required field(s) can not be empty.')}</p>
                                </div>
                                }
                                {quantityError == 1 &&
                                <div  className="alert alert-warning alert-dismissible pink-box">
                                    <p>{__('Quantity cannot exceed the available quantity.')}</p>
                                </div>
                                }
                                {this.state.isAvailable == 0 &&
                                <div  className="alert alert-warning alert-dismissible pink-box">
                                    <p>{__('Custom label already exists. Please select another.')}</p>
                                </div>
                                }
                                <p className="text-muted marginBott10">{__('Select the fields you would like to change and enter the new information. For fields marked optional, you can save a blank value.')}</p>
                                <div className="modify-content">
                                    <div className="shareTitleBox">
                                        <div className="iconBox bg-lightgreen">
                                            <DatabaseOutlined />
                                        </div>
                                        <div className="shareTextBox">
                                            <p className="shareTitle">{__('SAR details')}</p>
                                            <p className="text-muted">{__('Update information included in the award agreements such as option plan, quantity and issue date.')}</p>
                                        </div>
                                    </div>
                                    <table className="table modify-table modify-value-table">
                                        <tbody>
                                        <tr className="modify-firstRow">
                                            <td>{__('Field')}</td>
                                            <td>{__('Value')}</td>
                                            <td>{__('New value')}</td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="sarDetailsCheck2" type="checkbox" name="custom_label_id" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.custom_label_id.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="sarDetailsCheck2"></label>
                                                    <span className="modify-label text-muted">{__('Custom label')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.custom_label_id}</td>
                                            <td><input type="text" className="form-control " name="custom_label_id" value={this.state.data.basicTemplateData.custom_label_id} disabled={this.state.checkboxStatus.custom_label_id.disabled} onChange={this.recordCurData} /></td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="sarDetailsCheck3" type="checkbox" name="issue_date" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.issue_date.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="sarDetailsCheck3"></label>
                                                    <span className="modify-label text-muted">{__('Issue date')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.issue_date=="" ? "" : formatDate(curSelectSecurity.issue_date)}</td>
                                            <td>
                                                <DateSelector
                                                    disabled={checkboxStatus.issue_date.disabled}
                                                    onChange={this.modifySarDateChange.bind(this, 'issue_date', 'issue_date_moment')}
                                                    value={I(data.basicTemplateData['issue_date_moment'], I(data.basicTemplateData['issue_date'], '') ? moment(data.basicTemplateData['issue_date'], dateFormat) : '')}
                                                    className="datepicker_opacity"
                                                />
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="sarDetailsCheck4" type="checkbox" name="grant_expiration_date" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.grant_expiration_date.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="sarDetailsCheck4"></label>
                                                    <span className="modify-label text-muted">{__('SAR expiration date')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.grant_expiration_date=="" ? "" : formatDate(curSelectSecurity.grant_expiration_date)}</td>
                                            <td>
                                                <DateSelector
                                                    disabled={checkboxStatus.grant_expiration_date.disabled}
                                                    onChange={this.modifySarDateChange.bind(this, 'grant_expiration_date', 'grant_expiration_date_moment')}
                                                    value={I(data.basicTemplateData['grant_expiration_date_moment'], I(data.basicTemplateData['grant_expiration_date'], '') ? moment(data.basicTemplateData['grant_expiration_date'], dateFormat) : '')}
                                                    className="datepicker_opacity"
                                                />
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="sarDetailsCheck5" type="checkbox" name="board_approval_date" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.board_approval_date.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="sarDetailsCheck5"></label>
                                                    <span className="modify-label text-muted">{__('Board approval date')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.board_approval_date=="" ? "" : formatDate(curSelectSecurity.board_approval_date)}</td>
                                            <td>
                                                <DateSelector
                                                    disabled={checkboxStatus.board_approval_date.disabled}
                                                    onChange={this.modifySarDateChange.bind(this, 'board_approval_date', 'board_approval_date_moment')}
                                                    value={I(data.basicTemplateData['board_approval_date_moment'], I(data.basicTemplateData['board_approval_date'], '') ? moment(data.basicTemplateData['board_approval_date'], dateFormat) : '')}
                                                    className="datepicker_opacity"
                                                />
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <a className="convertible-ques top2">
                                                        <p className="convertible-tiptext modify-tiptext106">{__('Quantity cannot be modified for awards that are exercised, amended, or have custom vesting.')}</p>
                                                    </a>
                                                    { isAbleToModifyQuantity==0 &&
                                                    <input type="checkbox" name="quantity" className="re-checkbox modify-checkbox disabled-checkbox" disabled={true}/>
                                                    }
                                                    { isAbleToModifyQuantity==1 &&
                                                    <input id="sarDetailsCheck6" type="checkbox" name="quantity" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.quantity.checked} onChange={this.checkedStatus}/>
                                                    }
                                                    <label htmlFor="sarDetailsCheck6"></label>
                                                    <span className="modify-label text-muted">{__('Quantity')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{formatFractionalNumber(curSelectSecurity.quantity)}</td>
                                            <td><input type="text" className="form-control " name="quantity" value={this.state.data.basicTemplateData.quantity} disabled={this.state.checkboxStatus.quantity.disabled} onBlur={this.formatNumData} onFocus={this.focusNumData} onChange={this.recordNumData} /></td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="sarDetailsCheck7" type="checkbox" name="last_day_to_exercise" className={"re-checkbox modify-checkbox "+disableCheck} checked={this.state.checkboxStatus.last_day_to_exercise.checked} onChange={this.checkedStatus} disabled={curSelectSecurity.termination_date==""? true : false}/>
                                                    <label htmlFor="sarDetailsCheck7"></label>
                                                    <span className="modify-label text-muted">Exercisable date</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.last_day_to_exercise=="" ? "" :(curSelectSecurity.last_day_to_exercise==-1? "No expiration date" : formatDate(curSelectSecurity.last_day_to_exercise))}</td>
                                            <td>
                                                <DateCustomer
                                                    customerValue={-1}
                                                    disabled={checkboxStatus.last_day_to_exercise.disabled}
                                                    onChange={this.lastDayToExerciseChange}
                                                    footerText={expirationDateLongDesc['-1']}
                                                />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="modify-content">
                                    <div className="shareTitleBox">
                                        <div className="iconBox bg-lightgreen">
                                            <DatabaseOutlined />
                                        </div>
                                        <div className="shareTextBox">
                                            <p className="shareTitle ">{__('Exercise details')}</p>
                                            <p className="text-muted">{__('Update information for exercises.')}</p>
                                        </div>
                                    </div>
                                    <table className="table modify-table modify-value-table">
                                        <tbody>
                                        <tr className="modify-firstRow">
                                            <td>{__('Field')}</td>
                                            <td>{__('Value')}</td>
                                            <td>{__('New value')}</td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="sar_exercise_price" type="checkbox" name="exercise_price" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.exercise_price.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="sar_exercise_price"></label>
                                                    <span className="modify-label text-muted">{__('Exercise price')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{formatCurrencyNumber(curSelectSecurity.exercise_price)}</td>
                                            <td>
                                                <div className="input-group"><span className="input-group-addon">{currency}</span>
                                                    <input type="text" className="form-control" onBlur={this.formatNumData} onFocus={this.focusNumData} onChange={this.recordNumData} name="exercise_price" value={this.state.data.basicTemplateData.exercise_price} disabled={this.state.checkboxStatus.exercise_price.disabled} />
                                                </div>
                                            </td>
                                        </tr>
                                        {curSelectSecurity.exercise_history && checkExerciseHistory.length>0 && curSelectSecurity.exercise_history.length > 0 && curSelectSecurity.exercise_history.map((v,k)=>{
                                            const { Panel } = Collapse;

                                            const exerciseDetails = <tr key={k}>
                                                <td colSpan={3} className="no_padding">
                                                    <table className="table modify-table modify-value-table no_margin">
                                                        <tbody>
                                                        <tr className="modify-contentRow" >
                                                            <td>
                                                                <div className="check-box exercise_date_list">
                                                                    <input id={"sar_exercise_date_check"+k} type="checkbox" name={"exercise_date"+k}
                                                                           className="re-checkbox modify-checkbox "
                                                                           checked={checkExerciseHistory[k]['exercise_date'+k].checked}
                                                                           onChange={this.exerciseCheckedStatus.bind(this,k)}/>
                                                                    <label htmlFor={"sar_exercise_date_check"+k}></label>
                                                                    <span className="modify-label text-muted">{__('Exercise date')}</span>
                                                                </div>
                                                            </td>
                                                            <td className={"mid_column"}>
                                                                <p className="no-marginBottom">{v.exercise_date == "" ? "" : formatDate(v.exercise_date)}</p>
                                                            </td>
                                                            <td>
                                                                <DateSelector
                                                                    key={k}
                                                                    disabled={checkExerciseHistory[k]['exercise_date'+k].disabled}
                                                                    onChange={this.modifySarExerciseDateChange.bind(this,k,'exercise_date')}
                                                                    value={I( I(data.basicTemplateData.exercise_history[k]['exercise_date'], '') ? moment(data.basicTemplateData.exercise_history[k]['exercise_date'], dateFormat) : '')}
                                                                    className="datepicker_opacity exercise_date_selector"
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr className="modify-contentRow">
                                                            <td>
                                                                <div className="check-box">
                                                                    <input id={"sar_payment_date_check_"+k} type="checkbox" name={"payment_date"+k} className="re-checkbox modify-checkbox " checked={checkExerciseHistory[k]['payment_date'+k].checked} onChange={this.exerciseCheckedStatus.bind(this,k)}/>
                                                                    <label htmlFor={"sar_payment_date_check_"+k}></label>
                                                                    <span className="modify-label text-muted">{__('Payment date')}</span>
                                                                </div>
                                                            </td>
                                                            <td className={"mid_column"}>{(curSelectSecurity.exercise_history[k].payment_date==""||null) ? "" : formatDate(curSelectSecurity.exercise_history[k].payment_date)}</td>
                                                            <td>
                                                                <DateSelector
                                                                    key={k}
                                                                    disabled={checkExerciseHistory[k]['payment_date'+k].disabled}
                                                                    onChange={this.modifySarExerciseDateChange.bind(this,k,'payment_date')}
                                                                    value={ I(data.basicTemplateData.exercise_history[k]['payment_date'], '') ? moment(data.basicTemplateData.exercise_history[k]['payment_date'], dateFormat) : ''}
                                                                    className="datepicker_opacity"
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr className="modify-contentRow">
                                                            <td>
                                                                <div className="check-box">
                                                                    <input id={"market_price_check_"+k} type="checkbox" name={"market_price"+k} className="re-checkbox modify-checkbox " checked={checkExerciseHistory[k]['market_price'+k].checked} onChange={this.exerciseCheckedStatus.bind(this,k)}/>
                                                                    <label htmlFor={"market_price_check_"+k}></label>
                                                                    <span className="modify-label text-muted">{__('Market price')}</span>
                                                                </div>
                                                            </td>
                                                            <td className={"mid_column"}>{curSelectSecurity.exercise_history[k].market_price}</td>
                                                            <td>
                                                                <div className="input-group"><span className="input-group-addon">{currency}</span>
                                                                    <input type="text" className="form-control " name="market_price" value={this.state.data.basicTemplateData.exercise_history[k].market_price} disabled={checkExerciseHistory[k]['market_price'+k].disabled} onBlur={this.formatExerciseNumData.bind(this,k)} onFocus={this.focusExerciseNumData.bind(this,k)} onChange={this.recordExerciseNumData.bind(this,k)} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="modify-contentRow">
                                                            <td>
                                                                <div className="check-box">
                                                                    <input id={"paid_via_check_"+k} type="checkbox" name={"paid_via"+k} className="re-checkbox modify-checkbox " checked={checkExerciseHistory[k]['paid_via'+k].checked} onChange={this.exerciseCheckedStatus.bind(this,k)}/>
                                                                    <label htmlFor={"paid_via_check_"+k}></label>
                                                                    <span className="modify-label text-muted">{__('Paid via')}</span>
                                                                </div>
                                                            </td>
                                                            <td className={"mid_column"}>{(curSelectSecurity.exercise_history[k].paid_via!=null && curSelectSecurity.exercise_history[k].paid_via!= "") && curSelectSecurity.exercise_history[k].paid_via}</td>
                                                            <td>
                                                                <select type="text" className="form-control select-container modify-select" name="paid_via" value={this.state.data.basicTemplateData.exercise_history[k].paid_via} disabled={checkExerciseHistory[k]['paid_via'+k].disabled} onChange={this.recordExerciseData.bind(this,k)} >
                                                                    <option value={""}> -- </option>
                                                                    {paidViaList.map((val,key)=>{
                                                                        return(
                                                                            <option value={val} key={key}>{val}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr className="modify-contentRow">
                                                            <td>
                                                                <div className="check-box">
                                                                    <input id={"sar_payment_verified_admin_id_check_"+k} type="checkbox" name={"payment_verified_admin_id"+k} className="re-checkbox modify-checkbox " checked={checkExerciseHistory[k]['payment_verified_admin_id'+k].checked} onChange={this.exerciseCheckedStatus.bind(this,k)}/>
                                                                    <label htmlFor={"sar_payment_verified_admin_id_check_"+k}></label>
                                                                    <span className="modify-label text-muted">{__('Payment verified by')}</span>
                                                                </div>
                                                            </td>
                                                            <td className={"mid_column"}>{(curSelectSecurity.exercise_history[k].payment_verified_admin_id!=null&& curSelectSecurity.exercise_history[k].payment_verified_admin_id!=0) && formatVerifiedAdminList[curSelectSecurity.exercise_history[k].payment_verified_admin_id].admin_name}</td>
                                                            <td>
                                                                <select type="text" className="form-control select-container modify-select" name="payment_verified_admin_id" value={this.state.data.basicTemplateData.exercise_history[k].payment_verified_admin_id} disabled={checkExerciseHistory[k]['payment_verified_admin_id'+k].disabled} onChange={this.recordExerciseData.bind(this,k)} >
                                                                    <option value={""}> -- </option>
                                                                    {verifiedAdminList && verifiedAdminList.map((val,key)=>{
                                                                        return(
                                                                            <option value={val.admin_id} key={key}>{val.admin_name}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr className="modify-contentRow ">
                                                            <td>
                                                                <div className="check-box">
                                                                    <input id={"sar_exercise_file_check"+k} type="checkbox" name={"exercise_file"+k} className="re-checkbox modify-checkbox" checked={checkExerciseHistory[k]['exercise_file'+k].checked} onChange={this.exerciseCheckedStatus.bind(this,k)}/>
                                                                    <label htmlFor={"sar_exercise_file_check"+k}></label>
                                                                    <span className="modify-label text-muted">Form of exercise</span>
                                                                </div>
                                                            </td>
                                                            <td className={"mid_column"}><p className='file-con'>{curSelectSecurity.exercise_history[k].exercise_file?.name}</p></td>
                                                            <td className="fileTd ">
                                                                <div className="select-btn select-blue lib-choose marginRig10">Choose a file
                                                                    <label htmlFor="exercise_file" className="sr-only" >File input</label>
                                                                    <input name="exercise_file"  disabled={checkExerciseHistory[k]['exercise_file'+k].disabled} type="file"  id="exercise_file"  onChange={this.selectExerciseFile.bind(this,k)} />
                                                                </div>
                                                                <div className="filename-box">
                                                                    <span className="close files-del hidden">x</span>
                                                                    <p className="text-muted">{data.basicTemplateData.exercise_history[k].exercise_file.name||"No file selected"}</p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                            const exerciseHeader = <tr>
                                                <td colSpan={3} className="text-muted">Exercise details for <span className="text-blue">{v.custom_label_id ? v.custom_label_id : "--"}</span>,&nbsp;
                                                    <span className="text-blue">{formatFractionalNumber(v.quantity)}</span> shares
                                                </td>
                                            </tr>

                                            return(
                                                <tr className={'exercise_history_root'}>
                                                    <td colSpan={3} className={'exercise_history_item'}>
                                                        <Collapse bordered={false}>
                                                            <Panel  header={ exerciseHeader }>
                                                                { exerciseDetails }
                                                            </Panel>
                                                        </Collapse>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="modify-content">
                                    <div className="shareTitleBox">
                                        <div className="iconBox bg-lightgreen">
                                            <FolderOpenOutlined />
                                        </div>
                                        <div className="shareTextBox">
                                            <p className="shareTitle ">{__('Documents and notes')}</p>
                                            <p className="text-muted ">{__('Update all attachment types and notes available to holder.')}</p>
                                        </div>
                                    </div>
                                    {this.state.is_show_delete_modal==1 &&
                                    <div className="deleteModalBox">
                                        <DeleteDoc closeDeleteModal={this.closeDeleteModal} deleteAllDoc={this.deleteAllDoc}  modifyKey={this.state.data.modify_id}   />
                                    </div>
                                    }
                                    <table className="table modify-table modify-value-table">
                                        <tbody>
                                        <tr className="modify-firstRow">
                                            <td>{__('Field')}</td>
                                            <td>{__('Value')}</td>
                                            <td>{__('New value')}</td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td>
                                                <div className="check-box">
                                                    <input id="sar_docCheck0" type="checkbox" name="incentive_plan_file" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.incentive_plan_file.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="sar_docCheck0"></label>
                                                    <span className="modify-label text-muted">{__('Equity incentive plan')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.incentive_plan_file.name}</td>
                                            <td className="fileTd ">
                                                <div className="select-btn select-blue lib-choose marginRig10">
                                                    {__('Choose a file')}
                                                    <label htmlFor="sar_incentive_plan" className="sr-only" >File input</label>
                                                    <input name="incentive_plan_file"  disabled={this.state.checkboxStatus.incentive_plan_file.disabled} type="file"  id="sar_incentive_plan"  onChange={this.selectFile} />
                                                </div>
                                                <div className="filename-box">
                                                    <span className="close files-del hidden">x</span>
                                                    <span className="text-muted">{this.state.data.basicTemplateData.incentive_plan_file.name==""?__("No file selected"):this.state.data.basicTemplateData.incentive_plan_file.name}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td>
                                                <div className="check-box">
                                                    <input id="sar_docCheck1" type="checkbox" name="form_of_settlement_agreement_file" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.form_of_settlement_agreement_file.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="sar_docCheck1"></label>
                                                    <span className="modify-label text-muted">{__('Form of settlement agreement')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.form_of_settlement_agreement_file.name}</td>
                                            <td className="fileTd ">
                                                <div className="select-btn select-blue lib-choose marginRig10">
                                                    {__('Choose a file')}
                                                    <label htmlFor="form_of_settlement" className="sr-only" >File input</label>
                                                    <input name="form_of_settlement_agreement_file"  disabled={this.state.checkboxStatus.form_of_settlement_agreement_file.disabled} type="file"  id="form_of_settlement"  onChange={this.selectFile} />
                                                </div>
                                                <div className="filename-box">
                                                    <span className="close files-del hidden">x</span>
                                                    <p className="text-muted">{this.state.data.basicTemplateData.form_of_settlement_agreement_file.name==""?__("No file selected"):this.state.data.basicTemplateData.form_of_settlement_agreement_file.name}</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td>
                                                <div className="check-box">
                                                    <input id="sar_docCheck2" type="checkbox" name="form_of_grant_file" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.form_of_grant_file.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="sar_docCheck2"></label>
                                                    <span className="modify-label text-muted">{__('Grant letter')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.form_of_grant_file.name}</td>
                                            <td className="fileTd ">
                                                <div className="select-btn select-blue lib-choose marginRig10">
                                                    {__('Choose a file')}
                                                    <label htmlFor="form_of_grant_agreement" className="sr-only" >File input</label>
                                                    <input name="form_of_grant_file"  disabled={this.state.checkboxStatus.form_of_grant_file.disabled} type="file"  id="form_of_grant_agreement"  onChange={this.selectFile} />
                                                </div>
                                                <div className="filename-box">
                                                    <span className="close files-del hidden">x</span>
                                                    <p className="text-muted">{this.state.data.basicTemplateData.form_of_grant_file.name==""?__("No file selected"):this.state.data.basicTemplateData.form_of_grant_file.name}</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td>
                                                <div className="check-box">
                                                    <input id="sar_docCheck3" type="checkbox" name="add_document" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.add_document.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="sar_docCheck3"></label>
                                                    <span className="modify-label text-muted">{__('Add more additional documents')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}></td>
                                            <td className="fileTd">
                                                <div className="select-btn select-blue marginRig10">
                                                    {__('Choose a file')}
                                                    <label htmlFor="add_doc_sar" className="sr-only" >File input</label>
                                                    <input name="add_document"  ref="add_document" disabled={this.state.checkboxStatus.add_document.disabled} type="file"  id="add_doc_sar"  onChange={this.selectFile} />
                                                </div>
                                                <div className="filename-box">
                                                    <span className="close files-del hidden">x</span>
                                                    <p className="text-muted">{this.state.data.basicTemplateData.add_document.name==""?__("No file selected"):this.state.data.basicTemplateData.add_document.name}</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td>
                                                <div className="check-box">
                                                    <input id="sar_docCheck5" name="remove_document" type="checkbox" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.remove_document.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="sar_docCheck5"></label>
                                                    <span className="modify-label text-muted">{__('Remove all additional documents')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}></td>
                                            {this.state.checkboxStatus.remove_document.checked===false &&
                                            <td>
                                                <div type="text" name="remove_document" className={"form-control "} value={this.state.data.basicTemplateData.remove_document} disabled={this.state.checkboxStatus.remove_document.disabled} onChange={this.recordCurData}>
                                                    <button type="button" ><DeleteOutlined style={{cursor:'not-allowed'}}/></button>
                                                </div>
                                            </td>
                                            }
                                            {this.state.checkboxStatus.remove_document.checked===true &&
                                            <td>
                                                <div type="text" name="remove_document" className={"form-control "} value={this.state.data.basicTemplateData.remove_document} disabled={this.state.checkboxStatus.remove_document.disabled} onChange={this.recordCurData}>
                                                    <button type="button"  onClick={this.showDeleteModal}><DeleteOutlined /></button>
                                                </div>
                                            </td>
                                            }
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td>
                                                <div className="check-box">
                                                    <input id="sar_noteCheck6" type="checkbox" name="notes" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.notes.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="sar_noteCheck6"></label>
                                                    <span className="modify-label text-muted">{__('Notes')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.notes}</td>
                                            <td>
                                                <textarea type="text" className={"form-control "} name="notes" value={this.state.data.basicTemplateData.notes} disabled={this.state.checkboxStatus.notes.disabled} onChange={this.recordCurData} />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div role="presentation" className="modal-footer box-footer no-paddingtop">
                                <a onClick={this.checkCustomLabelId}  role="tab" data-toggle="tab" className="btn boxbtn-to bg-darkblue">{__('Next: review modifications')}</a>
                            </div>
                        </div>
                        <div role="tabpanel" className="modal-content content920 tab-pane" id="reviewSarModifications">
                            <div className="modal-header box-header">
                                <a href="#modifySarValue"  aria-controls="modifyOptionValue" role="tab" data-toggle="tab" className="back-btn"></a>
                                <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.resetAction}>×</button>
                                <h4 className="modal-title" id="myModalLabel">{__('Modify SAR')} {this.state.initData.custom_label_id}</h4>
                            </div>
                            <div className="modal-body padding2030">
                                <div className="modify-content">
                                    {/*{this.props.curSelectSecurity.exercise_price!==this.state.data.basicTemplateData.exercise_price&&*/}
                                    {/*<div className="option_review_box marginBott10 ">*/}
                                    {/*    <p className="marginBott10">Updating the exercise price will change the grant as of the grant date. Request a repricing if you are trying to enter an amendment.</p>*/}
                                    {/*    <button className="btn" data-toggle="modal" data-target="#requestRepricingModal">Request a repricing</button>*/}
                                    {/*</div>*/}
                                    {/*}*/}
                                    <div className="shareTitleBox">
                                        <div className="iconBox bg-lightgreen">
                                            <DiffOutlined />
                                        </div>
                                        <div className="shareTextBox">
                                            <p className="shareTitle ">{__('Review and apply modifications')}</p>
                                            <p className="text-muted">{__('Review the table below and save the modifications. For some fields on accepted securities, a notification will be sent out. Changes marked with  Yes on Sends email notification will send an email to the stakeholder.')}<br />
                                                {/*<a className="text-blue">Learn more about modifications ></a>*/}
                                            </p>
                                        </div>
                                    </div>
                                    <table className="modify-table reviewTb">
                                        <tbody>
                                        <tr className="modify-firstRow">
                                            <td className="width100">{__('Security')}</td>
                                            <td className="width120" >{__('Stakeholder')}</td>
                                            <td className="width140" >{__('Field')}</td>
                                            <td className="width160">{__('Old value')}</td>
                                            <td className="width160" >{__('New value')}</td>
                                            <td className="width180" >{__('Sends email notification')}</td>
                                        </tr>
                                        {Object.values(this.state.checkboxStatus).map((value,key)=>{
                                            if(value.checked){
                                                showSelectCount++;
                                                if(value.jsonKey == "form_of_settlement_agreement_file"|| value.jsonKey == "form_of_grant_file"|| value.jsonKey == "incentive_plan_file"){
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd">{this.state.initData[value.jsonKey].name}</td>
                                                            <td className="newValueTd">{this.state.data.basicTemplateData[value.jsonKey].name}</td>
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    );
                                                }else if(value.jsonKey === "add_document"){
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd"></td>
                                                            <td className="newValueTd">{this.state.data.basicTemplateData[value.jsonKey].name}</td>
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    );
                                                }else if(value.jsonKey == "quantity"){
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd">{formatFractionalNumber(this.state.initData[value.jsonKey]) }</td>
                                                            <td className="newValueTd">{formatFractionalNumber(this.state.data.basicTemplateData[value.jsonKey])}</td>
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    );
                                                }else if(value.jsonKey == "exercise_price"){
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd">{formatCurrencyNumber(this.state.initData[value.jsonKey]) }</td>
                                                            <td className="newValueTd">{formatCurrencyNumber(this.state.data.basicTemplateData[value.jsonKey])}</td>
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    );
                                                }else if(value.jsonKey === "issue_date" || value.jsonKey === "board_approval_date" || value.jsonKey ==="grant_expiration_date" || value.jsonKey === "last_day_to_exercise" ){
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd">{ this.state.initData[value.jsonKey] == -1 ? 'No expiration date' : formatDate(this.state.initData[value.jsonKey])}</td>
                                                            <td className="newValueTd">{data.basicTemplateData[value.jsonKey] == -1 ? 'No expiration date' : formatDate(data.basicTemplateData[value.jsonKey])}</td>
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    );
                                                }else{
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd">{this.state.initData[value.jsonKey]}</td>
                                                            <td className="newValueTd">{this.state.data.basicTemplateData[value.jsonKey]}</td>
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    );
                                                }

                                            }

                                        })}
                                        {checkExerciseHistory.map((value,key)=>{
                                            return(
                                                <Fragment key={key}>
                                                    {Object.values(value).map((v,k)=>{
                                                        if(v.checked){
                                                            showSelectCount++;
                                                            if(v.jsonKey == "exercise_date" || v.jsonKey ==="payment_date"){
                                                                return(
                                                                    <tr key={k} className="reviewTb-contentRow ">
                                                                        <td>{ showSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                                        <td>{ showSelectCount===1 && this.state.initData.name}</td>
                                                                        <td>
                                                                            <i><small><span className="text-blue">{formatExerciseHistoryList[value.id].custom_label_id} </span></small></i>
                                                                            {v.fieldName}
                                                                        </td>
                                                                        <td className="oldValueTd">
                                                                            {curSelectSecurity.exercise_history.map((val,ke)=>{
                                                                                if(val.id==value.id)
                                                                                    return(
                                                                                        <p key={k} className={"no-marginBottom"}>{formatDate(val[v.jsonKey])}</p>
                                                                                    )
                                                                            })}
                                                                        </td>
                                                                        <td className="newValueTd">
                                                                            {data.basicTemplateData.exercise_history.map((val,ke)=>{
                                                                                if(val.id==value.id)
                                                                                    return(
                                                                                        <p key={k} className={"no-marginBottom"}>{formatDate(val[v.jsonKey])}</p>
                                                                                    )
                                                                            })}

                                                                        </td>
                                                                        <td>X {__('No')}</td>
                                                                    </tr>
                                                                );
                                                            }else if(v.jsonKey == "payment_verified_admin_id" ){
                                                                return(
                                                                    <tr key={k} className="reviewTb-contentRow ">
                                                                        <td>{ showSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                                        <td>{ showSelectCount===1 && this.state.initData.name}</td>
                                                                        <td>
                                                                            <i><small><span className="text-blue">{formatExerciseHistoryList[value.id].custom_label_id} </span></small></i>
                                                                            {v.fieldName}
                                                                        </td>
                                                                        <td className="oldValueTd">{(curSelectSecurity.exercise_history[key].payment_verified_admin_id!=null&& curSelectSecurity.exercise_history[key].payment_verified_admin_id!=0) && formatVerifiedAdminList[curSelectSecurity.exercise_history[key].payment_verified_admin_id].admin_name}</td>
                                                                        <td className="newValueTd">{data.basicTemplateData.exercise_history[key][v.jsonKey]==null ? __("None"): formatVerifiedAdminList[data.basicTemplateData.exercise_history[key][v.jsonKey]].admin_name}</td>
                                                                        <td>X {__('No')}</td>
                                                                    </tr>
                                                                );
                                                            }else if(v.jsonKey == "market_price"){
                                                                return(
                                                                    <tr key={k} className="reviewTb-contentRow ">
                                                                        <td>{ showSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                                        <td>{ showSelectCount===1 && this.state.initData.name}</td>
                                                                        <td>
                                                                            <i><small><span className="text-blue">{formatExerciseHistoryList[value.id].custom_label_id} </span></small></i>
                                                                            {v.fieldName}
                                                                        </td>
                                                                        <td className="oldValueTd">{formatCurrencyNumber(this.state.initData.exercise_history[key][v.jsonKey]) }</td>
                                                                        <td className="newValueTd">{formatCurrencyNumber(this.state.data.basicTemplateData.exercise_history[key][v.jsonKey])}</td>
                                                                        <td>X {__('No')}</td>
                                                                    </tr>
                                                                );
                                                            }else if(v.jsonKey == "exercise_file"){
                                                                return(
                                                                    <tr key={k} className="reviewTb-contentRow ">
                                                                        <td>{ showSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                                        <td>{ showSelectCount===1 && this.state.initData.name}</td>
                                                                        <td>
                                                                            <i><small><span className="text-blue">{formatExerciseHistoryList[value.id].custom_label_id} </span></small></i>
                                                                            {v.fieldName}
                                                                        </td>
                                                                        <td className="oldValueTd">{this.state.initData.exercise_history[key][v.jsonKey].name}</td>
                                                                        <td className="newValueTd">{this.state.data.basicTemplateData.exercise_history[key][v.jsonKey].name}</td>
                                                                        <td>X No</td>
                                                                    </tr>
                                                                );
                                                            } else{
                                                                return(
                                                                    <tr key={k} className="reviewTb-contentRow ">
                                                                        <td>{ showSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                                        <td>{ showSelectCount===1 && this.state.initData.name}</td>
                                                                        <td>
                                                                            <i><small><span className="text-blue">{formatExerciseHistoryList[value.id].custom_label_id} </span></small></i>
                                                                            {v.fieldName}
                                                                        </td>
                                                                        <td className="oldValueTd">{this.state.initData[v.jsonKey]==""?__("None"):this.state.initData[v.jsonKey]}</td>
                                                                        <td className="newValueTd">{this.state.data.basicTemplateData.exercise_history[key][v.jsonKey]}</td>
                                                                        <td>X {__('No')}</td>
                                                                    </tr>
                                                                );
                                                            }

                                                        }

                                                    })}

                                                </Fragment>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div role="presentation" className="modal-footer box-footer no-paddingtop">
                                <a className="btn boxbtn-to bg-darkblue"  onClick={this.submitActionForSar}>{__('Apply modifications')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ModifySARs;