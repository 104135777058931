import React, { Component } from 'react';
import { Button } from 'antd';
import Utils from 'Utils';
import { CloseOutlined, FilePdfOutlined } from '@ant-design/icons';

class UploadFile extends Component {
	handleClick = () => {
		const { onChange } = this.props
		const input = document.createElement('input')
		const body = document.querySelector('body');

		input.type = 'file';
		input.style.display = 'none';
		input.accept = 'application/pdf'
		
		input.onchange = async (event) => {
			const { files = [] } = event.target
			const file = files[0] || {}
			const { name } = file

			const file_obj = await Utils.uploadFile(file)

			onChange && onChange({ fileName: name, fileContent: file_obj })

			body.removeChild(input);
		}

		body.appendChild(input);

		input.click()
	}

	deleteFile = () => {
		const { onChange } = this.props

		onChange && onChange()
	}

	render() {
		const { value = {} } = this.props
		const { fileName } = value
		console.log(value)
		return <div>
			<Button type="primary" onClick={this.handleClick} >Choose a file</Button> 
			{
				fileName && <div style={{ marginTop: 10 }}>
					<FilePdfOutlined />
					&nbsp;
					{fileName}
					&nbsp;
					<CloseOutlined onClick={this.deleteFile} />
				</div>				
			}
		</div>
	}
}

export default UploadFile