// summary 右边图
import React, { useEffect, useState } from 'react';
import Echarts from 'components/Echarts';
import { message, Spin } from 'antd';
import { post } from 'srcPath/http';
import emptyImg from 'assets/images/bar.png';
import { formatCurrencyNumber, tranNumber } from 'Utils';
import { formatDate } from 'Utils/format/formatDate';

import './style.scss';

var option = {
    tooltip: {
		show: true,
		formatter: (data) => {
			const name = data.data.name;
			return `${name}: ${formatCurrencyNumber(data.value)}`
		}
    },
    grid: {
    	containLabel: true,
    	left: 10,
    	top: 20,
    	right: 20,
    	bottom: 10
    },
    xAxis: {
        type: 'category',
        axisLine: {
        	lineStyle: {
        		color: '#524e4ed9'
        	}
        },
        axisTick: {
            show: false
        }
    },
    yAxis: {
    	show: true,
        axisLine: {
			show: true,
        	lineStyle: {
        		color: '#524e4ed9'
        	}
        },    	
        axisTick: {
            show: false
		},
		axisLabel: {
			formatter: (value) => {
				return tranNumber(value, 1, true);
			}
		}
    },
    series: [
        {
        	type: 'bar', 
        	barMaxWidth: 40,  
        	label: {
        		show: true,
        		position: 'top',
        		formatter: params => formatCurrencyNumber(params.data && params.data.post_money_valuation),
        		color: '#000'
        	},
        	itemStyle: {
        		barBorderRadius: [4, 4, 0, 0]
        	},
        },
    ]
};

function processData(data = []) {
	let xAxisData = []
	let seriesData = []

	data.forEach((val, index) => {
		xAxisData.push(val.closingDate ? `${val.name}\n${formatDate(val.closingDate)}` : val.name)
		seriesData.push({...val, value: val.post_money_valuation})
	})

	option.xAxis.data = xAxisData
	option.series[0].data =seriesData

	return option
}

export default function FundingHistory(props) {
	const [loading, setLoading] = useState(true)
	const [data, setData] = useState([])

	useEffect(() => {
		const getData = async () => {
			setLoading(true)

			try {
				const { data: resData } = await post('getFinancingHistory')
				const { code, data } = resData || {}

				if (code == 0) {
					setData(data && data.fundingRounds || [])
				} else {
					message.error(data)
				}

			} catch (err) {
				message.error(err && err.message)
			}

			setLoading(false)
		}

		getData()
	}, [])

	return <div className="funding-history-root">
		<Spin spinning={loading}>
			{
				data && data.length === 0 ? <img className="empty-icon" src={emptyImg} /> : <Echarts option={processData(data)}  />			
			}
		</Spin>	
	</div>
}