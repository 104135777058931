//  不同环境对应的market website 域名
export const webSiteMap = {
	'dev': 'https://dev.getsprout.co',
	'qa': 'https://qa.getsprout.co',
	'staging': 'https://staging.getsprout.co',
	'prod': 'https://getsprout.co'
}

export const ossURLMap = {
	'dev': 'https://sproutdevelop.s3.ap-southeast-1.amazonaws.com',
	'qa': 'https://sproutqa.s3.ap-southeast-1.amazonaws.com',
	'staging': 'https://sproutstaging.s3.ap-southeast-1.amazonaws.com',
	'prod': 'https://sproutprod.s3.ap-southeast-1.amazonaws.com',
}

export const countries = ['Hong Kong', 'BVI', 'Cayman Islands', 'China', 'Indonesia', 'Singapore', 'Taiwan', 'Thailand', 'Vietnam', "The British Virgin Islands (BVI)", "The Cayman Islands"]
export const cityList = ['北京', '上海', '广州', '杭州']
export const relationshipList = [__('Advisor'), __('Board member'), __('Community'), __('Consultant'), __('Contract'), __('Crypto market maker'), __('Employee'), __('Ex-employee'), __('Executive'), __('Founder'), __('International employee'), __('Investor'), __('Officer'), __('Treasury')]
export const paymentMethod = ['Prepaid', 'Payroll deduction', 'Cash or check', 'Electronic transfer or wire', 'Any other means to be agreed by the company']
export const paidViaList = ['Payroll', 'Cash or check', 'Electronic transfer or wire', 'Any other means to be agreed by the company']
export const permissionsType = [
	{ label: __('Edit and view company information'), value: true },
	{ label: __('View only company information'), value: true },
	{ label: __('View ESOP information only'), value: true },
	{ label: __('View Shares information only'), value: true },
	{ label: __('Download and generate reports'), value: true },
	{ label: __('Access and use Fundraising and Compliance tools'), value: true },
	{ label: __('Access to all Data Room folders'), value: true },
	{ label: __('Draft ESOP'), value: true },
	{ label: __('Draft Shares'), value: true },
	{ label: __('Access to selected Data Room folders'), value: true },
	{ label: __('Draft and issue securities'), value: true },
	{ label: __('Invite all users and stakeholders'), value: true },
	{ label: __('Invite some users and stakeholders'), value: true },

]
export const permissionsTypeMpa = {
	1: [true, false, false, false, true, true, true, false, false, false, true, true, false],
	2: [true, false, false, false, true, true, true, false, false, false, true, false, true],
	3: [false, true, false, false, true, true, true, false, false, false, false, false, false],
	5: [false, false, true, false, false, false, false, true, false, true, false, false, false],
	6: [false, false, false, true, false, false, false, false, true, true, false, false, false],
	8: [false, false, false, false, false, false, false, false, false, true, false, false, false],
}
export const permissionList = [
	{ text: __('Choose permissions'), value: '' },
	{ text: __('Super admin'), value: '1' },
	{ text: __('Company editor'), value: '2' },
	{ text: __('Company viewer'), value: '3' },
	{ text: __('ESOP admin'), value: '5' },
	{ text: __('Share class admin'), value: '6' },
	{ text: __('Data room user'), value: '8' },
]

const yesOrNoMap = {
	1: __('Yes'),
	0: __('No'),
	true: __('Yes'),
	false: __('No'),
};
// 格式化Yes、No字符
export const getYesOrNoText = val => yesOrNoMap[val] || '--'

export const accessLevelMap = {
	// old
	// 0: 'Basic view',
	// 1: 'Individual view',
	// 2: 'Summary view',
	// 3: 'Intermediate view',
	// 4: 'Detailed view',

	// new
	0: __('No Access'),
	1: __('Basic view'),
	2: __('Individual view'),
	3: __('Summary view'),
	4: __('Intermediate view'),
	5: __('Detailed view'),
}

export const cancellationReason = [
	"Acquisition",
	"Buyback",
	"Clerical error",
	"Conversion",
	"Dissolved",
	"Donation",
	"Merger",
	"Relationship change",
	"Secondary",
	"Stock split",
	"Other",
	"Write off cancel",
	"Repricing cancel",
]

export const costCenterList = [
	"营销部门",
	"人力资源",
	"研究与开发",
	"工作办公室",
	"质量保证",
	"工程",
	"后勤",
	"采购",
	"其他",
]

export const LOADING = __('Loading')

export const certificateStatus = {
	'Initiated': __('Initiated'),
	'Issued': __('Issued'),
	'Canceled': __('Canceled'),
	'Reverse Cancellation': __('Reverse Cancellation'),
	'Converted': __('Converted'),
	'Exercised': __('Exercised'),
	'Purchased': __('Purchased'),
	'Repurchased': __('Repurchased'),
	'Settled': __('Settled'),
	'Transferred': __('Transferred'),
	'Terminated': __('Terminated'),
	'Reverse Termination': __('Reverse Termination'),
	'Modified': __('Modified'),
	'Redeem to cash': __('Redeem to cash'),
	'Convert at IPO': __('Convert at IPO'),
	'Exercised at IPO': __('Exercised at IPO'),
	'Outstanding': __('Outstanding'),
	'Not purchased': __('Not purchased'),
	'Partially settled': __('Partially settled'),
	'Partially exercised': __('Partially exercised'),
	'Fully exercised': __('Fully exercised'),
	'Expired': __('Expired'),
	'Forfeited': __('Forfeited'),
	'Settled Offline': __('Settled Offline'),
	'Exercisable until': __('Exercisable until'),
	'Exercisable': __('Exercisable'),
	'Settleable until': __('Settleable until'),
	'Settleable': __('Settleable')
};


export const bulkUploadLang = {
	"zh_CN": {
		relationshipList: {
			'顾问': 'Advisor',
			'董事会成员': 'Board member',
			'社区': 'Community',
			'咨询师': 'Consultant',
			'合同': 'Contract',
			'加密币市场商': 'Crypto market maker',
			'员工': 'Employee', 
			'前员工': 'Ex-employee', 
			'高管': 'Executive',
			'创始人':'Founder',
			'国际员工':'International employee',
			'投资者':'Investor',
			'管理者':'Officer',
			'金库':'Treasury'
		},
		costCenter:{
			'营销部门':'Marketing department',
			'人力资源':'Human resources',
			'研究与开发':'Research and development',
			'工作办公室':'Work office',
			'质量保证':'Quality assurance',
			'工程':'Engineering',
			'后勤':'Logistics',
			'采购':'Procurement',
			'其他':'Others',
		},
		Gender:{
			'男士':'Male',
			'女士':'Female',
			'其他性别':'Non-binary / non-conforming',
			'不予提供':'Prefer not to respond',
		},
		StakeholderType:{
			'个人':'Individual',
			'非个人':'Non-individual'
		},
		BoardApprovalStatusList:{
			'董事会批准':'Board approved',
			'未经董事会批准':'Not board approved',
		},
		terminationStatusList:{
			'天':'Day(s)',
			'月':'Month(s)',
			'年':'Year(s)',
		},
		RsaStatusList:{
			'未购买':'Not purchased',
			'已购买':'Purchased'
		}
	},
	"zh_TW":{
		relationshipList: {
			'顧問': 'Advisor',
			'董事會成員': 'Board member',
			'社區': 'Community',
			'諮詢師': 'Consultant',
			'合同': 'Contract',
			'加密幣市場商': 'Crypto market maker',
			'員工': 'Employee', 
			'前員工': 'Ex-employee', 
			'高管': 'Executive',
			'創始人':'Founder',
			'國際員工':'International employee',
			'投資者':'Investor',
			'管理者':'Officer',
			'金庫':'Treasury'
		},
		costCenter:{
			'營銷部門':'Marketing department',
			'人力資源':'Human resources',
			'研究與開發':'Research and development',
			'工作辦公室':'Work office',
			'質量保證':'Quality assurance',
			'工程':'Engineering',
			'後勤':'Logistics',
			'採購':'Procurement',
			'其他':'Others',
		},
		Gender:{
			'男士':'Male',
			'女士':'Female',
			'其他性别':'Non-binary / non-conforming',
			'不予提供':'Prefer not to respond',
		},
		StakeholderType:{
			'個人':'Individual',
			'非個人':'Non-individual'
		},
		BoardApprovalStatusList:{
			'董事會批准':'Board approved',
			'未經董事會批准':'Not board approved',
		},
		terminationStatusList:{
			'天':'Day(s)',
			'月':'Month(s)',
			'年':'Year(s)',
		},
		RsaStatusList:{
			'未購買':'Not purchased',
			'已購買':'Purchased'
		}
	},
	"zh_HK":{
		relationshipList: {
			'顧問': 'Advisor',
			'董事會成員': 'Board member',
			'社區': 'Community',
			'諮詢師': 'Consultant',
			'合同': 'Contract',
			'加密幣市場商': 'Crypto market maker',
			'員工': 'Employee', 
			'前員工': 'Ex-employee', 
			'高管': 'Executive',
			'創始人':'Founder',
			'國際員工':'International employee',
			'投資者':'Investor',
			'管理者':'Officer',
			'金庫':'Treasury'
		},
		costCenter:{
			'營銷部門':'Marketing department',
			'人力資源':'Human resources',
			'研究與開發':'Research and development',
			'工作辦公室':'Work office',
			'質量保證':'Quality assurance',
			'工程':'Engineering',
			'後勤':'Logistics',
			'採購':'Procurement',
			'其他':'Others',
		},
		Gender:{
			'男士':'Male',
			'女士':'Female',
			'其他性别':'Non-binary / non-conforming',
			'不予提供':'Prefer not to respond',
		},
		StakeholderType:{
			'個人':'Individual',
			'非個人':'Non-individual'
		},
		BoardApprovalStatusList:{
			'董事會批准':'Board approved',
			'未經董事會批准':'Not board approved',
		},
		terminationStatusList:{
			'天':'Day(s)',
			'月':'Month(s)',
			'年':'Year(s)',
		},
		RsaStatusList:{
			'未購買':'Not purchased',
			'已購買':'Purchased'
		}
	}
}

export const certificateRelationship = {
	'Advisor': __('Advisor'),
	'Board member': __('Board member'),
	'Community': __('Community'),
	'Consultant': __('Consultant'),
	'Contract': __('Contract'),
	'Crypto market maker': __('Crypto market maker'),
	'Employee': __('Employee'),
	'Ex-employee': __('Ex-employee'),
	'Executive': __('Executive'),
	'Founder': __('Founder'),
	'International employee': __('International employee'),
	'Investor': __('Investor'),
	'Officer': __('Officer'),
	'Treasury': __('Treasury')
};


