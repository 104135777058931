import React, { Component } from 'react';
import security from 'stores/security';
import { observer } from 'mobx-react';
import Modal, { ModalContent } from 'components/Modal';
import { Button, Spin, message } from 'antd';
import Utils from 'Utils';
import { post } from 'srcPath/http';

import icon from './images/icon.png'
import loadingIcon from './images/loading.png';

import './style.scss';

@observer
class FileListModalContent extends Component {
	state = {
		loading: false
	}

	downLoadFile = async val => {
		this.setState({ loading: true })
		try {
			let a=await post('getS3FileUrl', {
				file_name:val.file_name,
				type:"report"
			})
			window.open(a.data.data); 
			security.getFileData()			
		} catch(err) {
			message.error(err.message)
		}

		this.setState({ loading: false })
	}

	render() {
		const fileList = security.fileList || []
		return <div className="file-list-root">
			<Spin spinning={this.state.loading}>
				<div className="file-list-wrap">
				{
					fileList.map((val, index) => <div key={index} className="file-list-item">
						<div className="file-list-item-wrap">
							<img src={icon} alt="img" />
							<div className="file-list-content">
								<div className="file-detail">
									<div className="file-name">{val.file_name}</div>
									<div className="file-date">
										{
											val.status === '2' ? <div className="reporting-wap">
												<img src={loadingIcon} alt="loading-icon" />
												<div className='reporting-tip' type="link">{__('Report generation is in progress')}</div>
												</div> : val.date
										}
										{val.status === '0' && <span className="file-new">{__('New')}</span>}
									</div>
								</div>
								<Button disabled={val.status === '2'} type="primary" onClick={() => { this.downLoadFile(val) }}>{__('Download')}</Button>
							</div>
						</div>
					</div>)
				}
				</div>
			</Spin>
		</div>
	}
}

export default function openFileListModal() {
	return Modal.open({
		component: props => <ModalContent close={props.close} title={__('Downloads')}>
			<FileListModalContent close={props.close} />
		</ModalContent>,
		width: 700,
		className: 'sprout-modal'
	})	
}