import React, { useState, useEffect } from 'react';
import { DatePicker, Button } from 'antd';
import { observer } from 'mobx-react';
import security from 'stores/security';
import moment from 'moment';
import { formatDate2Api, formatDateForDatePicker } from 'Utils/format/formatDate';
import zh_CN from 'antd/es/locale/zh_CN';
import zh_HK from 'antd/es/locale/zh_HK';
import zh_TW from 'antd/es/locale/zh_TW';
import { language } from 'Utils/i18n';
import './style.scss';

if (language?.startsWith('zh')) {
	require('moment/locale/zh-cn')
	moment.locale('zh-cn')
}

const localeData = {
	zh_CN: zh_CN,
	zh_HK: zh_HK,
	zh_TW: zh_TW,
}

const DateSelectorFormat = {
	zh_CN: {
		'DD/MM/YYYY': 'DD/MM/YYYY',
		'MM/DD/YYYY': 'MM/DD/YYYY',
		'MMM DD, YYYY': 'YYYY/MM/DD',
		'DD MMM, YYYY': 'YYYY年MM月DD日',
	},
	zh_HK: {
		'DD/MM/YYYY': 'DD/MM/YYYY',
		'MM/DD/YYYY': 'MM/DD/YYYY',
		'MMM DD, YYYY': 'MMM DD, YYYY',
		'DD MMM, YYYY': 'DD MMM, YYYY',
	},
	zh_TW: {
		'DD/MM/YYYY': 'DD/MM/YYYY',
		'MM/DD/YYYY': 'MM/DD/YYYY',
		'MMM DD, YYYY': 'YYYY/MM/DD',
		'DD MMM, YYYY': 'YYYY年MM月DD日',
	},
	en_US: {
		'DD/MM/YYYY': 'DD/MM/YYYY',
		'MM/DD/YYYY': 'MM/DD/YYYY',
		'MMM DD, YYYY': 'MMM DD, YYYY',
		'DD MMM, YYYY': 'DD MMM, YYYY',
	}
}

function DateSelector(props) {
	return <DatePicker locale={localeData[language]?.DatePicker} style={{ width: '100%' }} {...props} format={security.companyInfo && DateSelectorFormat[language][security.companyInfo.date_format]} />
}

export default observer(DateSelector)

export function DateRangeSelector(props) {
	return <DatePicker.RangePicker locale={localeData[language]?.DatePicker} style={{ width: '100%' }} {...props} format={security.companyInfo && DateSelectorFormat[language][security.companyInfo.date_format]} />
}



// 定制化,DatePicker, 登录后切换 /test 查看 demo
// onChange: (value: customerValue | '"27/10/2022"' ) => void;
const now = moment()

export function DateCustomer(props) {
	const { customerValue = -1, footerText, value, onChange, className, ...others } = props
	const [date, setDate] = useState()
	const [opened, setOpend] = useState(false)

	
	let format = security.companyInfo && security.companyInfo && DateSelectorFormat[language][security.companyInfo.date_format]

	const footerElement = <Button type="link" onClick={() => {
		setDate(now)
		onChange?.(customerValue)
		setOpend(false)
	}}>
		{footerText || 'N/A'}
	</Button>

	const handleChange = val => {
		setOpend(false)
		setDate(val)
		onChange?.(val && formatDate2Api(val))
	}

	useEffect(() => {
		if (value === customerValue) {
			setDate(now)
		} else {
			value && setDate(formatDateForDatePicker(value))
		}
	}, [value])

	if (footerText && date === now) {
		format = val => footerText
	}

	return <DatePicker
		{...others}
		locale={localeData[language]?.DatePicker}
		onBlur={() => setOpend(false)}
		onClick={() => setOpend(true)}
		open={opened}
		renderExtraFooter={() => footerElement}
		format={format}
		onChange={handleChange}
		value={date}
		className={`sprout-customer-datepicker  ${className}`}
	/>
}
