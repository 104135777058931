import React, { Component } from "react";
import {Button, Input, message} from "antd";
import { post } from 'srcPath/http';
import Loading from 'components/Loading';

class TemplateRename extends Component {

    state = {
        templateName: ''
    }

    componentDidMount() {
        const { templateName } = this.props;
        this.setState({templateName})
    }

    inputChange = (e) => {
        const { value: templateName } = e.target;
        this.setState({templateName})
    }

    saveName = () => {
        const { templateName } = this.state;
        const { checkAddStakeholder, id, newHtml, signatureLocation } = this.props;
        checkAddStakeholder ? checkAddStakeholder(id, newHtml, signatureLocation, templateName) : this.saveData(templateName);
    }

    saveData = async (template_name) => {
        Loading.global(true);
        const { id, close, getData } = this.props;
        try {
            const { data: resData } = await post('renameSignTemplate', { id, template_name });
            const { data, code } = resData || {}
            if (code == 0) {
                message.success(__("Success!"));
                close();
                //添加页不刷新列表
                getData && getData();
            } else {
                message.error(data);
            }
        } catch(err) {
            message.error(err && err.message);
        }
        Loading.global(false);
    }

    close = () => {
        const { close } = this.props;
        close();
    }

    render() {
        const { templateName } = this.state;
        return (
            <div className="docusign-template-rename-root">
                <h5>{__('E-sign template name')}</h5>
                <Input value={templateName} onChange={this.inputChange} />
                <div className="docusign-rename-button">
                    <Button type="primary" onClick={this.close}>{__('Cancel')}</Button>
                    <Button type="primary" onClick={this.saveName}>{__('Save')}</Button>
                </div>
            </div>
        );
    }
}
export default TemplateRename;