import React, { useState, useEffect } from 'react';
import { FormItem } from 'react-antd-formutil';
import { Select } from 'antd';
import { post } from 'srcPath/http';
import { omit } from 'lodash';

const Option = Select.Option

export default function RoundsSelect(props) {
    const { name, label, type } = props
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getData = async () => {
            try {
                const { data: resData } = await post('getFilterCountry',{type});

                setOptions([...options, ...(resData?.data || [])])
            } catch (e) { 

            }

            setLoading(false)
        } 

        getData()
    }, [])

    return <FormItem
        name={name || 'country'}
        label={label || __('Country')}
    >
        <Select {...omit(props, ['name', 'label'])} loading={loading} mode="multiple" showSearch placeholder={__('Select')}>
            {
                options.map((val, index) => (<Option key={index} value={val}>{val}</Option>))
            }
        </Select>
    </FormItem>

}