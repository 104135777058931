import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Input, Button, message } from 'antd';
import security from 'stores/security';
import moment from 'moment'
import axios from 'axios'
import Loading from 'components/Loading'
import url from "url";

class AdmintoolLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        security.clear()
        this.goLogin()
    }

    goLogin = async () => {
        let queryObj = url.parse(this.props.location.search, true);
        let admintool_token = queryObj.query.admintool_token
        let role = queryObj.query.role
        if (!admintool_token) {
            message.error('Invalid token')
            this.props.history.push('/');
            return
        }
        
        localStorage.setItem('AdmintoolUserToken', admintool_token)
        localStorage.setItem('AdmintoolUserRole', role)

        Loading.global(true)
        try {
            const { data } = await axios.post('', Object.assign({ action: 'signin' }, { admintool_token }), { useForm: true })

            if (data.code === 0) {
                security.setSproutToken = data.data
                localStorage.setItem('SPROUT_EXPIRE', moment().add(5, 'h').valueOf())
                security.sessionExpired = false
                security.setPlatform = 'C'
                await security.init()
                await security.sendEvent({ event_name: 'loginToCompany' })
                this.props.history.push('/');
            } else {
                this.props.history.push('/');
                message.error(data.data)
            }
            Loading.global(false)
        } catch (e) {
            message.error(e.message)
        }

    }
    render() {
        return <div className=""></div>
    }
}
export default AdmintoolLogin;