import React from 'react';
import axios from 'axios';
import Utils from "Utils";
import {Link} from "react-router-dom";
import { post } from 'srcPath/http';
import '../../../../../assets/css/public.css';
import {formatNumber, formatCurrencyNumber} from "Utils";
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import security from 'stores/security';
import {DollarOutlined, FileTextOutlined} from "@ant-design/icons";

class CreateShareClass extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            sendingStatus:0,
            existingPrefix:["ABC"],
            isLegalPrefix:1,
            existingPlanName:["ABC"],
            isLegalPlanName:1,
            isEdit:0,
            requiredKey:["seniority_level","voting_multiple","dividend_yield","dividend_accrual","compounding_interest",],
            data:{
                class_type:"CS",
                share_class_name:"",
                prefix:"",
                total_authorized_shares:"",
                is_certificate_shares:0,
                is_priced_round:0,
                issue_price:"",
                par_value:"",
                voting_multiple:1,
                seniority_level:1,
                pari_passu:0,
                original_issue_price:"",
                conversion_price:"",
                conversion_ratio:"",
                multiplier:"",
                par_preferred:0,
                participating_preferred_cap:"",
                isdividend:"Cash",
                dividend_yield:"",
                dividend_types:"Cumulative",
                dividend_accrual:"",
                compounding_interest:"",
                type:"new",
                payout_order:1
            },
            incorporationList : [],
            showErrorArr : ["dontshow","dontshow","dontshow","dontshow","dontshow"],
            currency:"USD",
            tabKey:1,

            seniorityDetails:[
                // {round_name:"Series Seed II Preferred",seniority:1,payout_order:"1st"},
                // {round_name:"Series Seed I Preferred",seniority:3,payout_order:"3rd"},
            ],
            dividendAccrual:[
                "Daily",
                "Weekly",
                "Monthly",
                "Quarterly (calendar year)",
                "Annually",
            ],
            compoundingInterest:[
                "Simple interest",
                "Daily",
                "Monthly",
                "Semi-annually",
                "Annually",
                "Quarterly (calendar year)",

            ],

        }
    }
    insertNewSeniority=()=>{
        let seniorityDetails = this.state.seniorityDetails;
        let curData = this.state.data;
        let isInserted = false; //默认没有插入
        for(let i=0;i<seniorityDetails.length;i++){
            //如果发现已经有插入的元素，则将标志位变为true
            if(seniorityDetails[i].hasOwnProperty("type")){
                isInserted = true;
            }
        }
        if(!isInserted){
            seniorityDetails.unshift(curData);
            this.setState({
                seniorityDetails:seniorityDetails
            })
        }
        this.sortSeniorityLevel(false);
    }
    setCurrency=()=>{
        $("#psStep1").addClass("active");
        axios.post('/request.php', {
            "company_id":Utils.getLocalCompanyInfo().company_id,
            action: 'getCurrency',
            data: {
                "withPrefix":1,
                "withPlanName":1,
                "withSeniorityDetails":1
            }
        }).then((response) => {
            console.log(response);
            if (response.data.code === 0) {
                this.setState({
                    currency:response.data.data.currency,
                    existingPrefix:response.data.data.prefixList,
                    existingPlanName:response.data.data.planNameList,
                    seniorityDetails: response.data.data.seniorityDetails
                })
                this.sortSeniorityLevel(true);
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
            //用到this要注意this指向
        }).catch(function (error) {
            console.log(error);
        });
    }

    changeTab=(key)=>{
        if(key==2){
            this.insertNewSeniority();
        }
        if(key==4){
            let curErrorArr = this.state.showErrorArr;
            curErrorArr[2] = "dontshow";
            this.removeError();
            this.setState({
                showErrorArr:curErrorArr
            });
        }
        this.setState({
            tabKey:key
        })
        if(this.state.data.class_type=="PS"){
            $("#psStep"+(key-1)).addClass("stock-did-li");
        }
        if(this.state.data.class_type=="CS"){
            $("#csStep"+(key-1)).addClass("stock-did-li");

        }
    }

    sortSeniorityLevel=(isInit)=>{
        let seniorityDetails = this.state.seniorityDetails;
        for(let i=0;i<seniorityDetails.length;i++) {
            if(!seniorityDetails[i].hasOwnProperty('init_seniority_level')){
                //没有init_seniority_level，要把初始值给上
                seniorityDetails[i].init_seniority_level = seniorityDetails[i].seniority_level;
            }
            if(isInit === true){
                //第一次来排序的，排完序后需要给所有的seniority level+1
                seniorityDetails[i].seniority_level = parseInt(seniorityDetails[i].seniority_level)+1;
            }
        }
        seniorityDetails.sort(function(a,b){return parseInt(Utils.resetNumberWithCommas(a.seniority_level))>parseInt(Utils.resetNumberWithCommas(b.seniority_level))?1:-1});
        let keyObj = {};
        let payout_order = 0;
        for(let i=0;i<seniorityDetails.length;i++){
            //循环给上payout_order和init_seniority_level
            if(keyObj.hasOwnProperty(seniorityDetails[i].seniority_level)){
                seniorityDetails[i].payout_order = payout_order;
            }else{
                payout_order++;
                keyObj[seniorityDetails[i].seniority_level] = 1;
                seniorityDetails[i].payout_order = payout_order;
            }
        }
        this.setState({
            seniorityDetails:seniorityDetails
        });
    }

    getIncorporationList=()=>{
        // $(".stock-li4").removeClass("stock-grayli").addClass("stock-did-li");
        axios.post('/request.php', {
            action:'getDocs',
            company_id:Utils.getLocalCompanyInfo().company_id,
            data:{}
        }).then((response)=> {
            console.log(response);
            if(response.data.code === 0){
                this.setState({
                    incorporationList:response.data.data.incorporation_docs
                })
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
            //用到this要注意this指向
        }).catch(function (error) {
            console.log(error);
        });

    }

    nextStep=(start,end)=>{
        $("li").removeClass("active");
        $("li a").attr({"aria-expanded":false});
        if(start===1 && end ===2){
            let curErrorArr = this.state.showErrorArr;
            curErrorArr[0] = "dontshow";
            this.setState({
                showErrorArr:curErrorArr
            })
            $("#psStep2").addClass("active");
            $("#psStep1").addClass("stock-did-li");
            this.changeTab(2);
        }
        if(start===2 && end ===3){
            let curErrorArr = this.state.showErrorArr;
            curErrorArr[1] = "dontshow";
            this.setState({
                showErrorArr:curErrorArr
            });
            $("#psStep3").addClass("active");
            $("#psStep2").addClass("stock-did-li");
            this.changeTab(3)
        }
        if(start===3 && end ===4){
            let curErrorArr = this.state.showErrorArr;
            curErrorArr[2] = "dontshow";
            this.removeError();
            this.setState({
                showErrorArr:curErrorArr
            });
            $("#psStep4").addClass("active");
            $("#psStep3").addClass("stock-did-li");
            this.changeTab(4)
        }
        if(start===2 && end ===1){
            let curErrorArr = this.state.showErrorArr;
            curErrorArr[2] = "dontshow";
            this.setState({
                showErrorArr:curErrorArr
            })
            $("#psStep1").addClass("active");
            this.changeTab(1);
        }
        if(start===3 && end ===2){
            let curErrorArr = this.state.showErrorArr;
            curErrorArr[3] = "dontshow";
            this.setState({
                showErrorArr:curErrorArr
            })
            $("#psStep2").addClass("active");
            this.changeTab(2)
        }
        if(start===4 && end ===3){
            $("#psStep3").addClass("active");
            this.changeTab(3)
        }
        if(start===11 && end ===12){
            let curErrorArr = this.state.showErrorArr;
            curErrorArr[0] = "dontshow";
            this.setState({
                showErrorArr:curErrorArr
            })
            $("#csStep2").addClass("active");
            $("#csStep1").addClass("stock-did-li");
            this.changeTab(2);
        }
        if(start===12 && end ===13){
            let curErrorArr = this.state.showErrorArr;
            curErrorArr[1] = "dontshow";
            this.setState({
                showErrorArr:curErrorArr
            })
            $("#csStep3").addClass("active");
            $("#csStep2").addClass("stock-did-li");
            this.changeTab(3);
        }
        if(start===13 && end ===12){
            let curErrorArr = this.state.showErrorArr;
            curErrorArr[1] = "dontshow";
            this.setState({
                showErrorArr:curErrorArr
            })
            $("#csStep2").addClass("active");
            this.changeTab(2);
        }
        if(start===12 && end ===11){
            let curErrorArr = this.state.showErrorArr;
            curErrorArr[0] = "dontshow";
            this.setState({
                showErrorArr:curErrorArr
            })
            $("#csStep1").addClass("active");
            this.changeTab(1);
        }
    }
    recordChecked=(e)=>{
        let dataKey = e.target.name;
        let curData = this.state.data;
        if(curData[dataKey]==1){
            curData[dataKey]=0
        }else{
            curData[dataKey]=1
        }
        if(dataKey == 'pari_passu'){
            //选择pari_passu之后要重新进行排序，
            let seniorityDetails = this.state.seniorityDetails;
            if(curData[dataKey] == 0){
                //这是变成0了，要进行排除同等级的
                //先遍历一遍看看是不是需要进行排除同级的
                let isDuplicatedSeniorityLevel = false;
                for(let i=0;i<seniorityDetails.length;i++){
                    if(Utils.getNumFromFormatNum(seniorityDetails[i].seniority_level)==Utils.getNumFromFormatNum(this.state.data.seniority_level)){
                        //等级相同并且不是new,
                        if(!seniorityDetails[i].hasOwnProperty("type")){
                            isDuplicatedSeniorityLevel = true;
                        }
                    }
                }
                if(isDuplicatedSeniorityLevel){
                    for(let i=0;i<seniorityDetails.length;i++){
                        if(Utils.getNumFromFormatNum(seniorityDetails[i].seniority_level)>=Utils.getNumFromFormatNum(this.state.data.seniority_level)){
                            if(!seniorityDetails[i].hasOwnProperty("type")){
                                seniorityDetails[i].seniority_level = parseInt(seniorityDetails[i].seniority_level)+1;
                            }
                        }
                    }
                }
            }else{
                //这里要根据init_seniority_level进行还原
                for(let i=0;i<seniorityDetails.length;i++){
                    if(Utils.getNumFromFormatNum(seniorityDetails[i].seniority_level)>=Utils.getNumFromFormatNum(this.state.data.seniority_level)){
                        if(!seniorityDetails[i].hasOwnProperty("type")){
                            seniorityDetails[i].seniority_level = parseInt(seniorityDetails[i].init_seniority_level);
                        }
                    }
                }
            }
            this.setState({
                seniorityDetails:seniorityDetails
            });
            this.sortSeniorityLevel(false);

        }
        this.setState({
            data:curData
        })
    }

    recordData=(e)=>{
        let dataKey = e.target.name;
        let curData = this.state.data;
        let dataVal = e.target.value;
        curData[dataKey] = dataVal;
        if(dataKey=="class_type"){
            curData.original_issue_price = "";
            this.setState({
                isLegalPrefix : 1,
                isLegalPlanName : 1
            })
            if(curData.class_type=="CS"){
                curData.share_class_name="Ordinary";
                curData.prefix= "CS"
            }else{
                curData.share_class_name="";
                curData.prefix= ""
            }
        }
        if(dataKey=="prefix"&& dataVal!=""){
            curData.prefix = dataVal.toUpperCase();
        }
        this.setState({
            data:curData
        })
        if(dataKey=="isdividend"){
            if(dataVal=="Cash"){
                let curErrorArr = this.state.showErrorArr;
                curErrorArr[2] = "dontshow";
                this.removeError();
                this.setState({
                    showErrorArr:curErrorArr
                });
            }
        }
    }
    createNewShareClass=()=>{
        if(Utils.isLocal()){
            // this.props.closeCreateModal();
            setTimeout(()=>{
                this.initStep();
            },300);
            return;
        }
        if(this.state.sendingStatus === 1){
            return;
        }
        this.setState({
            sendingStatus:1
        });
        var requestData = this.state.data;
        requestData.share_class_type = this.state.data.class_type;
        requestData.dividend = this.state.data.isdividend;
        requestData.dividend_type = this.state.data.dividend_types;
        requestData.participating_preferred = this.state.data.par_preferred;
        let requestSeniorityArr = [];
        for(let i=0;i<this.state.seniorityDetails.length;i++){
            if(this.state.seniorityDetails[i].hasOwnProperty("id")){
                requestSeniorityArr.unshift({
                    id:this.state.seniorityDetails[i].id,
                    seniority_level:this.state.seniorityDetails[i].seniority_level,
                })
            }
        }
        requestData.seniorityDetails = requestSeniorityArr;

        axios.post('/request.php', {
            action: 'createShareClass',
            company_id: Utils.getLocalCompanyInfo().company_id,
            data: requestData
        }).then((response) => {
            console.log(response);
            if (response.data.code === 0) {
                // this.props.closeCreateModal();
                window.$("#createShareClassModal").modal("hide");
                this.setState({
                    sendingStatus:0
                },()=> this.props.getShareClassList());
                setTimeout(()=>{
                    this.initStep();
                },300);
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    showError=(key,e)=>{
        let curErrorArr = this.state.showErrorArr;
        curErrorArr[key] = "show";
        if(key===2){
            this.state.requiredKey.map((value,key)=>{
                if(this.state.data[this.state.requiredKey[key]].length==0){
                    $("#create_"+value).addClass("has-error");
                }
            });
        }
        this.setState({
            showErrorArr:curErrorArr
        });
    }
    removeError=()=>{
        this.state.requiredKey.map((value,key)=>{
            if(this.state.data[this.state.requiredKey[key]].length>0){
                $("#create_"+value).removeClass("has-error");
            }
        });
    }
    initStep=()=>{
        $("#createShareClassModal .tab-pane").removeClass("active");
        $("#createShareClassStep1").addClass("active");
        $(".ps_plan_nav .stock-li").removeClass("active");
        $("#psStep1").addClass("active");
        let initData = {
            class_type:"CS",
            share_class_name:"",
            prefix:"",
            total_authorized_shares:"",
            is_certificate_shares:0,
            is_priced_round:0,
            issue_price:"",
            par_value:"",
            seniority_level:1,
            pari_passu:0,
            voting_multiple: "1",
            original_issue_price:"",
            conversion_price:"",
            conversion_ratio:"",
            multiplier:"",
            par_preferred:0,
            participating_preferred_cap:"",
            isdividend:"Cash",
            dividend_yield:"",
            dividend_types:"Cumulative",
            dividend_accrual:"",
            compounding_interest:"",
            payout_order: 1
        };
        this.setState({
            tabKey:1,
            data:initData
        })
    }

    recordNumber=(e)=>{
        const isFractionalShare = security.companyInfo.fractional_control;
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        if(dataKey==="seniority_level"){
            let seniorityDetails = this.state.seniorityDetails;
            //遍历seniorityDetails，将type为new的seniority改为新值，并重新排序
            for(let i=0;i<seniorityDetails.length;i++){
                if(seniorityDetails[i].hasOwnProperty("type")){
                    seniorityDetails[i].seniority_level =  Utils.checkNumInput(dataVal);
                    curData.seniority_level = Utils.checkNumInput(dataVal)
                    this.setState({
                        seniorityDetails:seniorityDetails
                    });
                }
            }
            this.sortSeniorityLevel(false);
        }
        if(dataKey==="total_authorized_shares"){
            if(isFractionalShare==1){
                curData[dataKey] = Utils.check5DecimalNumInputWithoutComma(dataVal);
            }else{
                curData[dataKey] = Utils.checkIntInputWithoutComma(dataVal);
            }
        }
        this.setState({
            data:curData
        })
    }
    focusNumber=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = Utils.resetNumberWithCommas(dataVal);
        this.setState({
            data:curData
        })
    }
    formatNumber=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = formatNumber(dataVal,null);
        this.setState({
            data:curData
        })
    }
    recordDecimalNumber=(e)=>{
        let curData = this.state.data;
        let curVal = e.target.value;
        let dataKey = e.target.name;
        if(dataKey=="issue_price"){
            curData.issue_price = Utils.check2DecimalNumInputWithoutComma(curVal);
            curData.original_issue_price = curData.issue_price;
        }else if(dataKey=="voting_multiple"){
            curData[dataKey] = Utils.check5DecimalNumInputWithoutComma(curVal);
        }else if(dataKey=="par_value"||dataKey=="original_issue_price"){
            curData[dataKey] = Utils.check10DecimalNumInputWithoutComma(curVal);
        }else{
            curData[dataKey] = Utils.check2DecimalNumInputWithoutComma(curVal);
        }
        this.setState({
            data:curData
        });
    }
    record5DecimalNumber=(e)=>{
        let curData = this.state.data;
        let curVal = e.target.value;
        let dataKey = e.target.name;
        curData[dataKey] = Utils.check5DecimalNumInputWithoutComma(curVal);
        this.setState({
            data:curData
        });
    }
    formatDecimalNumber=(e)=>{
        let curData = this.state.data;
        let curVal = e.target.value;
        let dataKey = e.target.name;
        if(dataKey=="issue_price"){
            curData.issue_price = formatNumber(curVal);
            curData.original_issue_price = curData.issue_price;
        }else if(dataKey=="voting_multiple"){
                curData[dataKey] = Utils.getNumFormatWithComma5(curVal);
        }else if(dataKey=="par_value"||dataKey=="original_issue_price"){
            curData[dataKey] = formatNumber(curVal,null);
        }else{
            curData[dataKey] = formatNumber(curVal);
        }
        this.setState({
            data:curData
        });
    }
    autoFillTwoDecimal=(e)=>{
        let dataVal = e.target.value;
        let dataKey = e.target.name;
        let curData = this.state.data;
        if(dataVal==""){
            curData[dataKey] = "";
        }else if(dataVal.indexOf(".")==0){
            curData[dataKey]=Utils.getNumWith2Decimal( "0"+dataVal);
        }else{
            curData[dataKey] = Utils.getNumWith2Decimal(dataVal);
        }
        this.setState({
            data:curData
        })
    }
    checkPrefix = () =>{
        const { data, existingPrefix}  = this.state;
        for( let i = 0; i<existingPrefix.length; i++){
            if(data.prefix == existingPrefix[i]){
                this.setState({
                    isLegalPrefix:0
                })
                break;
            }else{
                this.setState({
                    isLegalPrefix:1
                })
            }
        }
    }
    checkPlanName = () =>{
        const { data, existingPlanName}  = this.state;
        for( let i = 0; i<existingPlanName.length; i++){
            if(data.share_class_name == existingPlanName[i]){
                this.setState({
                    isLegalPlanName:0
                })
                break;
            }else{
                this.setState({
                    isLegalPlanName:1
                })
            }
        }
    }
    render(){
        const { data, isLegalPrefix, isLegalPlanName } = this.state;
        let contentWidth = 'fillout-content';
        let step1Error = 1;
        let step2Error = 0;
        let step3Error = 0;
        if(data.share_class_name.length>0 && data.prefix.length>0 ){
            step1Error = 0;
        }
        if(data.class_type=="PS"){
            if(data.original_issue_price.length==0){
                step2Error = 1;
            }
        }
        if(data.class_type=="CS"){
            if(data.voting_multiple.length==0||data.par_value.length==0){
                step2Error = 1;
            }
            if(data.is_priced_round == 1 && data.original_issue_price==""){
                step2Error = 1;
            }
        }
        if(data.isdividend=="Non-cash"){
            if(data.dividend_types.length==0
                ||data.dividend_yield.length==0
                ||data.dividend_accrual.length==0
                ||data.compounding_interest.length==0){
                step3Error = 1;
            }
        }
        if(data.seniority_level==""||data.voting_multiple===""){
            step3Error=1;
        }
        if(this.state.tabKey===3 && data.class_type==="PS"){
            contentWidth = 'content860'
        }
        if(this.state.tabKey===4){
            contentWidth = 'content760'
        }
        // let isLegalPrefix = 1; //默认为合法的输入
        // for(let i=0;i<this.state.existingPrefix.length;i++){
        //     if(data.prefix == this.state.existingPrefix[i]){
        //         isLegalPrefix = 0;  //有重复改为非法
        //     }
        // }
        // let isLegalPlanName = 1; //默认为合法的输入
        // for(let i=0;i<this.state.existingPlanName.length;i++){
        //     if(data.share_class_name == this.state.existingPlanName[i]){
        //         isLegalPlanName = 0;  //有重复改为非法
        //     }
        // }
        let totalAuth = "10,000,000";
        if(data.class_type === "PS"){
            totalAuth = "2,000,000"
        }
        let certificateShare = 'Yes';
        if(data.is_certificate_shares===0){
            certificateShare = 'No'
        }
        return(
            <div className="modal fade" id="createShareClassModal" tabIndex="-1" role="dialog" aria-labelledby="createShareClassModal" aria-hidden="true">
                <div className="modal-dialog dialog900">
                    {/*<CreateShareClassPlanModal  closeCreateModal={this.closeCreateModal}/>*/}
                    <div className={"reset_margin modal-content "+contentWidth} >
                        {data.class_type==="CS" &&
                        <div className="modal-header box-header">
                            {this.state.tabKey==2 &&
                            <Link to="#createShareClassStep1" onClick={this.nextStep.bind(this,12,11)} aria-controls="createShareClassStep1" role="tab" data-toggle="tab"  className="back-btn"></Link>
                            }
                            {this.state.tabKey==3 &&
                            <Link to="#sharePriceStep2" aria-controls="sharePriceStep2" onClick={this.nextStep.bind(this,13,12)} role="tab" data-toggle="tab"  className="back-btn"></Link>
                            }
                            <button type="button" className="close shut-btn" data-dismiss="modal"  onClick={this.initStep}>×</button>
                            <h4 className="modal-title">{__('Create ordinary / common share class')}</h4>
                        </div>
                        }
                        {data.class_type == "PS" &&
                        <div className="modal-header box-header">
                            {this.state.tabKey==2 &&
                            <Link to="#createShareClassStep1" onClick={this.nextStep.bind(this,2,1)} aria-controls="createShareClassStep1" role="tab" data-toggle="tab" className="back-btn"></Link>
                            }
                            {this.state.tabKey==3 &&
                            <Link to="#sharePriceStep2" aria-controls="sharePriceStep2" onClick={this.nextStep.bind(this,3,2)} role="tab" data-toggle="tab" className="back-btn"></Link>
                            }
                            {this.state.tabKey==4 &&
                            <Link to="#preferenceStep3" aria-controls="preferenceStep3" onClick={this.nextStep.bind(this,4,3)} role="tab" data-toggle="tab" className="back-btn"></Link>
                            }
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.initStep}>×</button>
                            <h4 className="modal-title">{data.class_type === "CS" && __("Create ordinary / common share class")}{data.class_type === "PS" && __("Creation of preferred stock")}</h4>
                        </div>
                        }
                        <div className="modal-body">
                            <div className="vesting-box">
                                {data.class_type === "CS" &&
                                <ul className="nav vesting-nav ps_plan_nav" role="tablist">
                                    <li role="presentation" className="stock-li active" id="csStep1">
                                        <a className="stock-a " onClick={this.changeTab.bind(this, 1)}
                                           href="#createShareClassStep1" role="tab" data-toggle="tab">1</a>
                                        <p className="shares_p">{__('Create a share class')}</p>
                                        <div className="hr1"></div>
                                    </li>
                                    {step1Error === 0 &&
                                    <li role="presentation" className="stock-li" id="csStep2">
                                        <a className="stock-a" onClick={this.changeTab.bind(this, 2)} href="#sharePriceStep2"
                                           role="tab" data-toggle="tab">2</a>
                                        <p className="shares_p">{__('Share prices')}</p>
                                        <div className="hr1"></div>
                                    </li>
                                    }
                                    {step1Error === 1 &&
                                    <li role="presentation" className="stock-li stock-nodoing">
                                        <a className="stock-a  " href="javascript:return false;">2</a>
                                        <p className="shares_p">{__('Share prices')}</p>
                                        <div className="hr1"></div>
                                    </li>
                                    }
                                    {step2Error === 0 &&
                                    <li role="presentation" className="stock-li" id="csStep3">
                                        <a className="stock-a" onClick={this.changeTab.bind(this, 3)} href="#reviewStep4"
                                           role="tab" data-toggle="tab">3</a>
                                        <p className="shares_p">{__('Review')}</p>
                                    </li>
                                    }
                                    {step2Error === 1 &&
                                    <li role="presentation" className="stock-li stock-nodoing">
                                        <a className="stock-a " href="javascript:return false;">3</a>
                                        <p className="shares_p">{__('Review')}</p>
                                    </li>
                                    }
                                </ul>
                                }
                                {data.class_type === "PS" &&
                                <ul className="nav vesting-nav ps_plan_nav" role="tablist">
                                    <li role="presentation" className="stock-li active" id="psStep1">
                                        <a className="stock-a " onClick={this.changeTab.bind(this, 1)} href="#createShareClassStep1" role="tab" data-toggle="tab">1</a>
                                        <p className="shares_p">{__('Create a share class')}</p>
                                        <div className="hr1"></div>
                                    </li>
                                    {step1Error === 0 && isLegalPrefix === 1 && isLegalPlanName===1 &&
                                    <li role="presentation" className="stock-li" id="psStep2">
                                        <a className="stock-a" onClick={this.changeTab.bind(this, 2)} href="#sharePriceStep2" role="tab" data-toggle="tab">2</a>
                                        <p className="shares_p">{__('Share prices')}</p>
                                        <div className="hr1"></div>
                                    </li>
                                    }
                                    {(step1Error === 1 || isLegalPrefix === 0 || isLegalPlanName===0) &&
                                    <li role="presentation" className="stock-li stock-nodoing">
                                        <a className="stock-a " href="javascript:return false;">2</a>
                                        <p className="shares_p">{__('Share prices')}</p>
                                        <div className="hr1"></div>
                                    </li>
                                    }
                                    {step2Error === 0 &&
                                    <li role="presentation" className="stock-li" id="psStep3">
                                        <a className="stock-a" onClick={this.changeTab.bind(this, 3)} href="#preferenceStep3" role="tab" data-toggle="tab">3</a>
                                        <p className="shares_p">{__('Preferences')}</p>
                                        <div className="hr1"></div>
                                    </li>
                                    }
                                    {step2Error === 1 &&
                                    <li role="presentation" className="stock-li stock-nodoing">
                                        <a className="stock-a " href="javascript:return false;">3</a>
                                        <p className="shares_p">{__('Preferences')}</p>
                                        <div className="hr1"></div>
                                    </li>
                                    }
                                    {step3Error === 1 &&
                                    <li role="presentation" className="stock-li stock-nodoing">
                                        <a className="stock-a " href="javascript:return false;">4</a>
                                        <p className="shares_p">{__('Review')}</p>
                                    </li>
                                    }
                                    {step3Error === 0 &&
                                    <li role="presentation" className="stock-li" id="psStep4">
                                        <a className="stock-a" onClick={this.changeTab.bind(this, 4)} href="#reviewStep4" role="tab" data-toggle="tab">4</a>
                                        <p className="shares_p">{__('Review')}</p>
                                    </li>
                                    }
                                    {/*<li role="presentation" className="stock-li" id="psStep4">*/}
                                    {/*    <a className="stock-a" onClick={this.changeTab.bind(this, 4)} href="#reviewStep4" role="tab" data-toggle="tab">4</a>*/}
                                    {/*    <p className="shares_p">Review</p>*/}
                                    {/*</li>*/}
                                </ul>
                                }
                            </div>
                            <div className="tab-content">
                                <div role="tabpanel" className="tab-pane active" id="createShareClassStep1">
                                    <div className="stock-titbox">
                                        <p>{__('Create a share class')}</p>
                                    </div>
                                    {this.state.showErrorArr[0] ==="show" &&
                                    <div className="stock-form">
                                        <div className="alert alert-warning alert-dismissable pink-box" role="alert">
                                            <p>{__("Required field(s) can't be empty.")}</p>
                                        </div>
                                    </div>
                                    }
                                    {isLegalPrefix ===0 &&
                                    <div className="stock-form" id="prefix_exist_error">
                                        <div className="alert alert-warning alert-dismissable pink-box" role="alert">
                                            <p>{__('Share class prefix already exists. Please select another.')}</p>
                                        </div>
                                    </div>
                                    }
                                    {isLegalPlanName ===0 &&
                                    <div className="stock-form" id="prefix_exist_error">
                                        <div className="alert alert-warning alert-dismissable pink-box" role="alert">
                                            <p>{__('Share class name already exists. Please select another.')}</p>
                                        </div>
                                    </div>
                                    }
                                    <div className="form-horizontal stock-form">
                                        <div className="form-group">
                                            <label className="col-xs-4 add-label">{__('Class type')}</label>
                                            <div className="col-xs-4">
                                                <div className="checkbox text-muted equity-checkbox">
                                                    <input type="radio"  onChange={this.recordData} className="send-box equity-checkbox" id={"common_type"} name="class_type" value="CS" checked={data.class_type==="CS"} />
                                                    <label className="send-div" htmlFor={"common_type"}></label><span className="type-label"> &nbsp;&nbsp;Ordinary / Common</span>
                                                </div>
                                            </div>
                                            <div className="col-xs-3">
                                                <div className="checkbox text-muted equity-checkbox">
                                                    <input type="radio" onChange={this.recordData} className="send-box equity-checkbox" id={"ps_type"} name="class_type" value="PS" checked={data.class_type==="PS"} />
                                                    <label className="send-div" htmlFor={"ps_type"}></label><span className="type-label"> &nbsp;&nbsp;Preferred</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group ">
                                            <label className="col-xs-4 add-label">{__('Share class')}</label>
                                            <div className="col-xs-5">
                                                {data.class_type === "PS" &&
                                                <input name="share_class_name" onChange={this.recordData} onBlur={this.checkPlanName}
                                                       value={data.share_class_name} className="form-control"
                                                       required type="text" placeholder="Series A"/>
                                                }
                                                {data.class_type === "CS" &&
                                                <input name="share_class_name" onChange={this.recordData} onBlur={this.checkPlanName}
                                                       value={data.share_class_name} className="form-control"
                                                       required type="text" placeholder="Ordinary"/>
                                                }
                                            </div>
                                        </div>
                                        <div className="form-group ">
                                            <div>
                                                <label className="col-xs-4 add-label">
                                                    {__('Share class prefix')}
                                                    {data.class_type=="CS" &&
                                                    <a id="stock-quespop" className="create-ques stock-ques">
                                                        <p className="captable-tiptext tiptext-top120">This creates a classification system. Shares start at 1(i.e. CS-1) and will continue in sequential order.</p>
                                                    </a>
                                                    }
                                                </label>
                                                <div className="col-xs-2">
                                                    {data.class_type === "PS" &&
                                                    <input name="prefix" value={data.prefix}
                                                           onChange={this.recordData} onBlur={this.checkPrefix} className="form-control uppercase_form" required
                                                           type="text" placeholder="PSA"/>
                                                    }
                                                    {data.class_type === "CS" &&
                                                    <input name="prefix" value={data.prefix}
                                                           onChange={this.recordData} onBlur={this.checkPrefix} className="form-control uppercase_form" required
                                                           type="text" placeholder="CS"/>
                                                    }
                                                </div>
                                                <p className="text-muted prefix-p col-xs-7 col-xs-push-4">{__('Letters and numbers only.')}</p>
                                            </div>
                                        </div>
                                        <div className="form-group ">
                                            <label className="col-xs-4 add-label">{__('Total authorized shares')}<br/><i><small className="text-muted">- Optional</small></i></label>
                                            <div className="col-xs-4">
                                                <div className="input-group">
                                                    <input type="text" name="total_authorized_shares" onBlur={this.formatNumber} onFocus={this.focusNumber} onChange={this.recordNumber} value={data.total_authorized_shares}  className="form-control" placeholder={totalAuth}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-xs-4 add-label ques-label">
                                                {__('Certificated shares')}
                                                <a id="stock-quespop" className="create-ques stock-ques">
                                                    <p className="captable-tiptext tiptext-top120">{__('If you do not select this, Folium can help your company to issue digital share certificates to stakeholders.')}</p>
                                                </a>
                                            </label>
                                            <div className="col-xs-5">
                                                <div className="checkbox text-muted equity-checkbox">
                                                    <input type="checkbox" className="send-box equity-checkbox" id="ps_certificated_shares" name="is_certificate_shares" onChange={this.recordChecked} checked={data.is_certificate_shares} />
                                                    <label className="send-div" htmlFor="ps_certificated_shares"></label>
                                                </div>
                                            </div>
                                        </div>
                                        {data.class_type === "CS" &&
                                        <div className="form-group">
                                            <label className="col-xs-4 add-label ques-label">
                                                {__('Priced round')}
                                                <a id="stock-quespop" className="create-ques stock-ques">
                                                    <p className="captable-tiptext tiptext-top96">{__('If you select this, this round will also be presented as a priced round in financing history.')}</p>
                                                </a>
                                            </label>
                                            <div className="col-xs-5">
                                                <div className="checkbox text-muted equity-checkbox">
                                                    <input type="checkbox" className="send-box equity-checkbox" id="ps_is_priced_round" name="is_priced_round" onChange={this.recordChecked} checked={data.is_priced_round} />
                                                    <label className="send-div" htmlFor="ps_is_priced_round"></label>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div role="tabpanel" className="tab-pane" id="sharePriceStep2">
                                    <div className="stock-titbox">
                                        <p>{__('Create prices')}</p>
                                    </div>
                                    <div className="stock-form">
                                        {this.state.showErrorArr[1] === "show" &&
                                        <div className="alert alert-warning alert-dismissable pink-box" role="alert">
                                            <p>{__("Required field (s) can't be empty.")}</p>
                                        </div>
                                        }
                                    </div>
                                    <form className="form-horizontal stock-form">
                                        {data.class_type === "CS" &&
                                        <div>
                                            <div className="form-group">
                                                <label className="col-xs-4 add-label">{__('Voting multiple')}</label>
                                                <div className="col-xs-4"  id="create_cs_voting_multiple">
                                                    <input value={data.voting_multiple}
                                                           name="voting_multiple" placeholder="e.g. 1" onBlur={this.formatDecimalNumber} onFocus={this.focusNumber}
                                                           onChange={this.recordDecimalNumber} className="form-control"/>
                                                </div>
                                            </div>
                                            <div className="form-group ">
                                                <label className="col-xs-4 add-label">{__('Par value')}</label>
                                                <div className="col-xs-4">
                                                    <div className="input-group">
                                                <span className="input-group-addon"
                                                      id="vesting-addon1">{this.state.currency}</span>
                                                        <input type="text" name="par_value" onFocus={this.focusNumber} onBlur={this.formatDecimalNumber} onChange={this.recordDecimalNumber}
                                                               value={data.par_value}  required className="form-control"
                                                               aria-describedby="vesting-addon1" placeholder="e.g. 0.00001"/>
                                                    </div>
                                                </div>
                                                <div className="stock-tipbox">
                                                    <p className="col-xs-6 col-xs-push-4 text-muted">
                                                        {__('This is the book or minimum accounting value of the shares. It is usually calculated as your registered capital divided by total authorized shares.')}
                                                    </p>
                                                </div>
                                            </div>
                                            { data.is_priced_round == 1 &&
                                            <div className="form-group ">
                                                <label className="col-xs-4 add-label">{__('Original issue price')}</label>
                                                <div className="col-xs-4">
                                                    <div className="input-group">
                                                        <span className="input-group-addon" id="cs_issue_price_addon">{this.state.currency}</span>
                                                        <input type="text" name="original_issue_price" onBlur={this.formatDecimalNumber} onFocus={this.focusNumber} onChange={this.record5DecimalNumber} value={data.original_issue_price} required className="form-control" aria-describedby="cs_issue_price_addon" placeholder="e.g. 0.00001"/>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                        }
                                        {data.class_type === "PS" &&
                                        <div className="form-group ">
                                            <label className="col-xs-4 add-label">{__('Original issue price')}</label>
                                            <div className="col-xs-4">
                                                <div className="input-group" id="create_original_issue_price_input">
                                                    <span className="input-group-addon">{this.state.currency}</span>
                                                    <input type="text" name="original_issue_price" onFocus={this.focusNumber} onBlur={this.formatDecimalNumber}  onChange={this.recordDecimalNumber} value={data.original_issue_price} required className="form-control" placeholder="e.g. 0.01"/>
                                                </div>
                                            </div>
                                            <div className="stock-tipbox">
                                                <p className="col-xs-7 col-xs-push-4 text-muted">
                                                    {__('This is your post-money valuation (pre-money plus new investment) divided by full diluted shares outstanding (outstanding plus all convertible and exercisable shares).')}
                                                </p>
                                            </div>
                                        </div>
                                        }
                                    </form>
                                </div>
                                <div role="tabpanel" className="tab-pane " id="preferenceStep3">
                                    <div className="stock-titbox">
                                        <p>{__('Preferences')}</p>
                                    </div>
                                    <div className="stock-form">
                                        {this.state.showErrorArr[2] === "show" &&
                                        <div className="alert alert-warning alert-dismissable pink-box" role="alert">
                                            <p>{__("Required field(s) can't be empty.")}</p>
                                        </div>
                                        }
                                    </div>
                                    <div className="stock-form">
                                        <div className="marginBott50">
                                            <div>
                                                <p className="font_weight500">{__('Seniority details')}</p>
                                                <p className="text-muted">{__('Enter the seniority details of the share class.')}</p>
                                                <div className="equity-tipbox">
                                                    <div className="light-tip"></div>
                                                    <p>{__('Share class seniority is ordered so that seniority 1 is the most senior and will be paid out first.')}</p>
                                                </div>
                                            </div>
                                            <div className="form-horizontal">
                                                <div className="form-group add-items">
                                                    <label className="col-xs-3 add-label">{__('Seniority level')}</label>
                                                    <div className="col-xs-4"  id="create_seniority_level">
                                                        <input value={data.seniority_level}
                                                               name="seniority_level" placeholder="e.g. 1" onBlur={this.formatNumber} onFocus={this.focusNumber}
                                                               onChange={this.recordNumber} className="form-control"/>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-xs-3 add-label ques-label">{__('Pari-passu')}<br/><i
                                                        className="text-muted">- {__('Optional')}</i></label>
                                                    <div className="col-xs-9">
                                                        <div className="checkbox text-muted equity-checkbox">
                                                            <input type="checkbox" className="send-box equity-checkbox" checked={data.pari_passu == 1} id="create_passu" name="pari_passu" onChange={this.recordChecked}/>
                                                            <label className="send-div" htmlFor="create_passu"></label>
                                                        </div>
                                                        <p className="text-muted">{__('Mark this share class as having equal seniority to an existing share class. Share classes with equal seniority will be paid out at the same time.')}</p>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-xs-3 add-label ques-label">{__('Seniority preview')}</label>
                                                    <div className="col-xs-9">
                                                        <table className="table viewHoldings-table vested-table marginTop10 no-marginBottom">
                                                            <tbody>
                                                            <tr className="first-row">
                                                                <td>{__('Share class')}</td>
                                                                <td className="vested-3Td">{__('Seniority')}</td>
                                                                <td>{__('Payout order')}</td>
                                                            </tr>
                                                            {
                                                                this.state.seniorityDetails && this.state.seniorityDetails.map((value,key)=>{
                                                                    let payOutOrder = 1;
                                                                    if(value.payout_order==1){
                                                                        payOutOrder = value.payout_order +"st"
                                                                    }else if(value.payout_order==2){
                                                                        payOutOrder = value.payout_order +"nd"
                                                                    }else if(value.payout_order==3){
                                                                        payOutOrder = value.payout_order +"rd"
                                                                    }else{
                                                                        payOutOrder = value.payout_order +"th"
                                                                    }
                                                                    let trClassName = "bg-lightgray";
                                                                    if(value.hasOwnProperty("type")){
                                                                        trClassName = "bg-skyblue";
                                                                    }
                                                                    return(
                                                                        <tr className={trClassName} key={key}>
                                                                            <td>{value.share_class_name}</td>
                                                                            <td className="vested-3Td">{value.seniority_level!==""? value.seniority_level: <p className="mb-0 text-danger">N/A</p>}</td>
                                                                            <td>{payOutOrder}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-xs-3 add-label">{__('Voting multiple')}</label>
                                                    <div className="col-xs-4"  id="create_voting_multiple">
                                                        <input value={data.voting_multiple}
                                                               name="voting_multiple" placeholder="e.g. 1"  onBlur={this.formatDecimalNumber} onFocus={this.focusNumber}
                                                               onChange={this.recordDecimalNumber} className="form-control"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="marginBott50">
                                            <div className="marginBott20">
                                                <p className="font_weight500">{__('Rights and preferences')}</p>
                                                <p className="text-muted">{__('Enter the rights and preferences of the share class.')}</p>
                                            </div>
                                            <div className="form-horizontal">
                                                <div className="form-group ">
                                                    <label className="col-xs-3 add-label">{__('Conversion price')}<br/><i
                                                        className="text-muted">- {__('Optional')}</i></label>
                                                    <div className="col-xs-4">
                                                        <div className="input-group">
                                                            <span className="input-group-addon">{this.state.currency}</span>
                                                            <input type="text" name="conversion_price"  onFocus={this.focusNumber} onBlur={this.formatDecimalNumber}  onChange={this.recordDecimalNumber} onBlur={this.autoFillTwoDecimal} value={data.conversion_price} required className="form-control" placeholder="e.g. 0.01"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group ">
                                                    <label className="col-xs-3 add-label">{__('Conversion Ratio')}<br/><i
                                                        className="text-muted">- {__('Optional')}</i></label>
                                                    <div className="col-xs-4">
                                                        <input type="text" name="conversion_ratio"
                                                               onChange={this.recordDecimalNumber} onFocus={this.focusNumber} onBlur={this.formatDecimalNumber}
                                                               value={data.conversion_ratio} required
                                                               className="form-control" placeholder="e.g. 0.01"/>
                                                    </div>
                                                    <div className="stock-tipbox">
                                                        <p className="col-xs-9  text-muted">{__('Conversion will be calculated with conversion ratio instead of conversion price if both are entered.')}</p>
                                                    </div>
                                                </div>
                                                <div className="form-group ">
                                                    <label className=" col-xs-3 add-label">{__('Multiplier')}<br/><i
                                                        className="text-muted">- {__('Optional')}</i></label>
                                                    <div className=" col-xs-4">
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" placeholder="1"
                                                                   name="multiplier" value={data.multiplier}
                                                                   onFocus={this.focusNumber} onBlur={this.formatDecimalNumber}   onChange={this.recordDecimalNumber}/>
                                                            <span className="input-group-addon">X</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-xs-3 add-label">{__('Participating preferred')}</label>
                                                    <div className="col-xs-1">
                                                        <div className="checkbox text-muted equity-checkbox">
                                                            <input type="radio" onChange={this.recordChecked} className="send-box equity-checkbox" id="preferred_yes" name="par_preferred" value="1" checked={data.par_preferred == 1}/>
                                                            <label className="send-div" htmlFor="preferred_yes"></label>
                                                            <span className="type-label"> &nbsp;&nbsp;{__('Yes')}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-1">
                                                        <div className="checkbox text-muted equity-checkbox">
                                                            <input type="radio" onChange={this.recordChecked} className="send-box equity-checkbox" id="preferred_no" name="par_preferred" value="0" checked={data.par_preferred == 0}/>
                                                            <label className="send-div" htmlFor="preferred_no"></label>
                                                            <span className="type-label"> &nbsp;&nbsp;{__('No')}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {data.par_preferred == 1 &&
                                                <div className="form-group ">
                                                    <label className=" col-xs-3 add-label">{__('Participating preferred cap')}<br/><i className="text-muted">- {__('Optional')}</i></label>
                                                    <div className=" col-xs-4">
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" placeholder="1"
                                                                   name="participating_preferred_cap"
                                                                   value={data.participating_preferred_cap}
                                                                   onFocus={this.focusNumber} onBlur={this.formatDecimalNumber} onChange={this.recordDecimalNumber}/>
                                                            <span className="input-group-addon">X</span>
                                                        </div>
                                                    </div>
                                                    <div className="stock-tipbox">
                                                        <p className="col-xs-7 text-muted">{__('Blank or zero means there is no cap. No cap means the preferred classes are fully participating.')}</p>
                                                    </div>
                                                </div>
                                                }

                                            </div>
                                        </div>
                                        <div className="marginBott20">
                                            <div className="marginBott20">
                                                <p className="font_weight500">{__('Dividends')}</p>
                                                <p className="text-muted">{__('Enter the dividend details of the share class.')}</p>
                                            </div>
                                            <div className="form-horizontal">
                                                <div className="form-group">
                                                    <label className="col-xs-3 add-label">{__('Dividend')}</label>
                                                    <div className="col-xs-2">
                                                        <div className="checkbox text-muted equity-checkbox">
                                                            <input type="radio"  onChange={this.recordData} className="send-box equity-checkbox" id="non_cash_dividend" name="isdividend" value="Non-cash" checked={data.isdividend=="Non-cash"} />
                                                            <label className="send-div" htmlFor="non_cash_dividend"></label><span className="type-label"> &nbsp;&nbsp;{__('Non-cash')}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-1">
                                                        <div className="checkbox text-muted equity-checkbox">
                                                            <input type="radio"  onChange={this.recordData} className="send-box equity-checkbox" id="cash_dividend" name="isdividend" value="Cash" checked={data.isdividend=="Cash"}/>
                                                            <label className="send-div" htmlFor="cash_dividend"></label><span className="type-label"> &nbsp;&nbsp;{__('Cash')}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {data.isdividend == "Non-cash" &&
                                                <div>
                                                    <div className="form-group ">
                                                        <label className="col-xs-3 add-label">{__('Dividend yield')}</label>
                                                        <div className="col-xs-4" id="create_dividend_yield">
                                                            <div className="input-group">
                                                                <input type="text" name="dividend_yield"
                                                                       onChange={this.recordDecimalNumber} onFocus={this.focusNumber} onBlur={this.formatDecimalNumber}
                                                                       value={data.dividend_yield} required
                                                                       className="form-control"
                                                                       placeholder="e.g. 3.5%"/>
                                                                <span className="input-group-addon">%</span>
                                                            </div>
                                                        </div>
                                                        <div className="stock-tipbox">
                                                            <p className="col-xs-7 col-xs-push-3 text-muted">{__('(Per share dividend ÷ Price/share) x 100 = Dividend yield')}<br/>
                                                                {__('E.g. ($0.01125 ÷ $0.75) x 100 = 1.5%.')}</p>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-xs-3 add-label">{__('Dividend type')}</label>
                                                        <div className="col-xs-2">
                                                            <div className="checkbox text-muted equity-checkbox">
                                                                <input type="radio" onChange={this.recordData}
                                                                       className="send-box equity-checkbox"
                                                                       id="cumulative_type"
                                                                       name="dividend_types" value="Cumulative"
                                                                       checked={data.dividend_types == "Cumulative"}/>
                                                                <label className="send-div"
                                                                       htmlFor="cumulative_type"></label><span
                                                                className="type-label"> &nbsp;&nbsp;{__('Cumulative')}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-3">
                                                            <div className="checkbox text-muted equity-checkbox">
                                                                <input type="radio" onChange={this.recordData}
                                                                       className="send-box equity-checkbox"
                                                                       id="non_cumulative_type"
                                                                       name="dividend_types" value="Non-cumulative"
                                                                       checked={data.dividend_types == "Non-cumulative"}/>
                                                                <label className="send-div"
                                                                       htmlFor="non_cumulative_type"></label><span
                                                                className="type-label"> &nbsp;&nbsp;{__('Non-cumulative')}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group add-items">
                                                        <label className="col-xs-3 add-label">{__('Dividend accrual')}</label>
                                                        <div className="col-xs-4"  id="create_dividend_accrual">
                                                            <select value={data.dividend_accrual}
                                                                    name="dividend_accrual" onChange={this.recordData}
                                                                    className="form-control select-container">
                                                                <option value=""></option>
                                                                {this.state.dividendAccrual.map((value, key) => {
                                                                    return (
                                                                        <option key={key} value={value}>{value}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group add-items">
                                                        <label className="col-xs-3 add-label">{__('Compounding interest')}</label>
                                                        <div className="col-xs-4" id="create_compounding_interest">
                                                            <select value={data.compounding_interest}
                                                                    name="compounding_interest"
                                                                    onChange={this.recordData}
                                                                    className="form-control select-container">
                                                                <option value=""></option>
                                                                {this.state.compoundingInterest.map((value, key) => {
                                                                    return (
                                                                        <option key={key} value={value}>{value}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div role="tabpanel" className="tab-pane " id="reviewStep4">
                                    <div className="stock-titbox">
                                        <p>{__('Review')}</p>
                                    </div>
                                    <div className="review-contentbox">
                                        <p><FileTextOutlined className='title-img'/>{__('General information')}</p>
                                        <div className="review-tablebox">
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td>{__('Class type')}</td>
                                                    <td>{data.class_type}</td>
                                                </tr>
                                                <tr>
                                                    <td>{__('Name')}</td>
                                                    <td>{data.share_class_name}</td>
                                                </tr>
                                                <tr>
                                                    <td>{__('Prefix')}</td>
                                                    <td className="uppercase_form">{data.prefix}</td>
                                                </tr>
                                                <tr>
                                                    <td>{__('Total authorized shares')}</td>
                                                    <td>{formatFractionalNumber(data.total_authorized_shares)}</td>
                                                </tr>
                                                <tr>
                                                    <td>{__('Certificated shares')}</td>
                                                    <td>{Utils.getYesOrNoText(data.is_certificate_shares)}</td>
                                                </tr>
                                                {data.class_type == "CS" &&
                                                <tr>
                                                    <td>{__('Priced round')}</td>
                                                    <td>{ data.is_priced_round == 0 ? "No" : "Yes"}</td>
                                                </tr>
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="review-contentbox">
                                        <p><DollarOutlined className='title-img'/>{__('Share prices')}</p>
                                        <div className="review-tablebox">
                                            <table>
                                                <tbody>
                                                {data.class_type === "PS" &&
                                                <tr>
                                                    <td>{__('Current issue price')}</td>
                                                    <td>{formatCurrencyNumber(data.original_issue_price)}</td>
                                                </tr>
                                                }
                                                {data.class_type === "CS" &&
                                                <tr>
                                                    <td>{__('Par value')}</td>
                                                    <td>{formatCurrencyNumber(data.par_value)}</td>
                                                </tr>
                                                }
                                                {data.class_type === "CS" &&
                                                <tr>
                                                    <td>{__('Voting multiple')}</td>
                                                    <td>{data.voting_multiple}</td>
                                                </tr>
                                                }
                                                {data.class_type === "CS" &&
                                                <tr>
                                                    <td>{__('Original issue price')}</td>
                                                    <td>{formatCurrencyNumber(data.original_issue_price)}</td>
                                                </tr>
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {data.class_type === "PS" &&
                                    <div className="review-contentbox">
                                        <p><DollarOutlined className='title-img'/>{__('Preferences')}</p>
                                        <div className="review-tablebox">
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td>{__('Seniority')}</td>
                                                    <td>{data.seniority_level}</td>
                                                </tr>
                                                <tr>
                                                    <td>{__('Pari-passu')}</td>
                                                    <td>{Utils.getYesOrNoText(data.pari_passu)}</td>
                                                </tr>
                                                <tr>
                                                    <td>{__('Voting multiple')}</td>
                                                    <td>{data.voting_multiple}</td>
                                                </tr>
                                                <tr>
                                                    <td>{__('Original issue price')}</td>
                                                    <td>{formatCurrencyNumber(data.original_issue_price)}</td>
                                                </tr>
                                                <tr>
                                                    <td>{__('Conversion price')}</td>
                                                    <td>{data.conversion_price == ""? 0 : formatCurrencyNumber(data.conversion_price)}</td>
                                                </tr>
                                                <tr>
                                                    <td>{__('Conversion ratio')}</td>
                                                    <td>{data.conversion_ratio == ""? 0 : data.conversion_ratio +" x"}</td>
                                                </tr>
                                                <tr>
                                                    <td>{__('Multiplier')}</td>
                                                    <td>{data.multiplier == ""? 0 : data.multiplier +" x"}</td>
                                                </tr>
                                                <tr>
                                                    <td>{__('Participating preferred')}</td>
                                                    <td>{Utils.getYesOrNoText(data.par_preferred)}</td>
                                                </tr>
                                                <tr>
                                                    <td>{__('Preference cap')}</td>
                                                    <td>{data.participating_preferred_cap == ""? 0 : data.participating_preferred_cap +" x"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="no_border_bottom">{__('Non-cash dividend')}</td>
                                                    <td className="no_border_bottom">{data.isdividend}</td>
                                                </tr>
                                                {data.isdividend === 'Non-cash' &&
                                                <tr>
                                                    <td colSpan="2" className="inside_table">
                                                        <table>
                                                            {data.dividend_yield === "" &&
                                                            <tbody>
                                                            <tr>
                                                                <td>{__('Coupon')}</td>
                                                                <td>&nbsp;</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{__('Type')}</td>
                                                                <td>&nbsp;</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{__('Dividend accrual')}</td>
                                                                <td>&nbsp;</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{__('Compounding interest')}</td>
                                                                <td>&nbsp;</td>
                                                            </tr>
                                                            </tbody>
                                                            }
                                                            {data.dividend_yield !== "" &&
                                                            <tbody>
                                                            <tr>
                                                                <td>{__('Coupon')}</td>
                                                                <td>{data.dividend_yield}%</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{__('Type')}</td>
                                                                <td>{data.dividend_types}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{__('Dividend accrual')}</td>
                                                                <td>{data.dividend_accrual}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{__('Compounding interest')}</td>
                                                                <td>{data.compounding_interest}</td>
                                                            </tr>
                                                            </tbody>
                                                            }
                                                        </table>
                                                    </td>
                                                </tr>
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {data.class_type === "PS" &&
                        <div className="nav" role="tablist">
                            {this.state.tabKey==1 &&
                            <div role="presentation" className="modal-footer box-footer vesting-footer">
                                <a data-dismiss="modal" className="btn boxbtn-to bg-darkblue width120">Go back</a>
                                {step1Error ===0 && isLegalPrefix===1 && isLegalPlanName===1 && <a data-target="#sharePriceStep2" onClick={this.nextStep.bind(this,1,2)} role="tab" data-toggle="tab" className="btn boxbtn-to bg-darkblue">{__('Next: share prices')}</a>}
                                {step1Error ===1 && <a onClick={this.showError.bind(this,0)}  className="btn boxbtn-to bg-darkblue">{__('Next: share prices')}</a>}
                                {(isLegalPrefix ===0 || isLegalPlanName===0) && step1Error===0 && <button  className="btn boxbtn-to bg-darkblue">{__('Next: share prices')}</button>}
                            </div>
                            }
                            {this.state.tabKey == 2 &&
                            <div role="presentation" className="modal-footer box-footer preStock-footer">
                                <a data-toggle="tab" data-target="#createShareClassStep1"  onClick={this.nextStep.bind(this,2,1)} className="btn boxbtn-to bg-darkblue width120">{__('Go back')}</a>
                                {step2Error === 0 && <a data-target="#preferenceStep3" role="tab" data-toggle="tab" onClick={this.nextStep.bind(this,2,3)} className="btn boxbtn-to bg-darkblue prices-btn">{__('Next: preferences')}</a>}
                                {step2Error === 1 && <a onClick={this.showError.bind(this,1)}  className="btn boxbtn-to bg-darkblue prices-btn">{__('Next: preferences')}</a>}
                            </div>
                            }
                            {this.state.tabKey == 3 &&
                            <div role="presentation" className="modal-footer box-footer preStock-footer">
                                <a data-toggle="tab" href="#sharePriceStep2"  onClick={this.nextStep.bind(this,3,2)} className="btn boxbtn-to bg-darkblue width120">{__('Go back')}</a>
                                {/*<a  data-target="#reviewStep4" role="tab" data-toggle="tab" onClick={this.nextStep.bind(this,3,4)} className="btn boxbtn-to bg-darkblue prices-btn">Next: Review</a>*/}
                                {step3Error ===0  &&  <a  data-target="#reviewStep4" role="tab" data-toggle="tab" onClick={this.nextStep.bind(this,3,4)} className="btn boxbtn-to bg-darkblue prices-btn">{__('Next: review')}</a>}
                                {step3Error ===1  && <a onClick={this.showError.bind(this,2)}  className="btn boxbtn-to bg-darkblue prices-btn">{__('Next: review')}</a>}
                            </div>
                            }
                            {this.state.tabKey == 4 &&
                            <div role="presentation" className="modal-footer box-footer preStock-footer">
                                <a data-toggle="tab" href="#preferenceStep3"  onClick={this.nextStep.bind(this,4,3)} className="btn boxbtn-to bg-darkblue width120">{__('Go back')}</a>
                                <button onClick={this.createNewShareClass} type="button" className="btn boxbtn-to bg-darkblue prices-btn">{__('Create new share class')}</button>
                            </div>
                            }
                        </div>
                        }
                        {data.class_type === "CS" &&
                        <div className="nav" role="tablist">
                            {this.state.tabKey==1 &&
                            <div role="presentation" className="modal-footer box-footer vesting-footer">
                                <a data-dismiss="modal" data className="btn boxbtn-to bg-darkblue width120">{__('Go back')}</a>
                                {step1Error ===0 && isLegalPrefix===1 && <a data-target="#sharePriceStep2" onClick={this.nextStep.bind(this,11,12)} role="tab" data-toggle="tab" className="btn boxbtn-to bg-darkblue">{__('Next: share prices')}</a>}
                                {step1Error ===1 && isLegalPrefix===1 && <a onClick={this.showError.bind(this,0)}    className="btn boxbtn-to bg-darkblue">{__('Next: share prices')}</a>}
                                {isLegalPrefix===0 && <a className="btn boxbtn-to bg-darkblue">{__('Next: share prices')}</a>}
                            </div>
                            }
                            {this.state.tabKey == 2 &&
                            <div className="modal-footer box-footer preStock-footer" role="presentation">
                                <a data-toggle="tab"  data-target="#createShareClassStep1"   onClick={this.nextStep.bind(this,12,11)} className="btn boxbtn-to bg-darkblue width120">{__('Go back')}</a>
                                {step2Error==0 && <a  data-target="#reviewStep4" role="tab" data-toggle="tab" onClick={this.nextStep.bind(this,12,13)} className="btn boxbtn-to bg-darkblue prices-btn">{__('Next: review')}</a>}
                                {step2Error==1 && <a onClick={this.showError.bind(this,1)}  className="btn boxbtn-to bg-darkblue prices-btn">{__('Next: review')}</a>}
                            </div>
                            }
                            {this.state.tabKey == 3 &&
                            <div className="modal-footer box-footer preStock-footer">
                                <a data-toggle="tab" href="#sharePriceStep2"  onClick={this.nextStep.bind(this,13,12)} className="btn boxbtn-to bg-darkblue width120">{__('Go back')}</a>
                                <button onClick={this.createNewShareClass} type="button"  className="btn boxbtn-to bg-darkblue prices-btn">{__('Create new share class')}</button>
                            </div>
                            }
                        </div>
                        }
                    </div>
                </div>
            </div>
        )
    }

}

export default CreateShareClass;

