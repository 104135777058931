import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { message, Select, Spin, Button, Switch, Row, Col, Space, Alert } from 'antd';
import { FormItem, withForm } from 'react-antd-formutil';
import { post } from 'srcPath/http';
import Permission from 'components/Permission';
import security from 'stores/security';
import CountrySelector from 'components/CountrySelector';
import CurrencySelector from 'components/CurrencySelector';
import TimeZone from 'components/TimeZone';
import DateFormatSelector from 'components/DateFormatSelector';
import { defaultDateFormat } from 'Utils/format/formatDate';
import moment from 'moment-timezone';
import Popup from "components/NoPermission/popup";
import Modal, { ModalContent } from 'components/Modal';
import Loading from 'components/Loading'
import ConfirmInviteSelectedModels from "components/ConfirmInviteSelectedModels";

const Option = Select.Option

@withForm
export default class Settings extends Component {
	loaded = false
	state = {
		loading: false,
	}
    //no permission弹层
    NoPermissionFloatModal = null;
	ConfirmInviteSelectedModels = null;

	componentDidMount() {
		this.getData();
	}

    getNoPermissionFloat = (type) => {
        const name = security.functionAuthConfig[type];
        this.NoPermissionFloatModal = Modal.open({
            component:  props => <ModalContent title={name} close={props.close}>
                <Popup authName={name} contentType="auth" history={this.props.history} callback={props.close} hideHeader={true} />
            </ModalContent>,
            width: '450px',
            maskClosable: true,
            className: 'sprout-modal'
        })
    }

    /**
     * 卸载
     */
    componentWillUnmount() {
		this.NoPermissionFloatModal && this.NoPermissionFloatModal.close();
		this.ConfirmInviteSelectedModels && this.ConfirmInviteSelectedModels.close();
    }

	getData = async () => {
		this.setState({ loading: !this.loaded })

		try {
			const { data: resData } = await post('getCompanyProfile')

			const { data, code } = resData || {}

			if (code == 0) {
				const { $setValues } = this.props.$formutil				

				if(!data.date_format) {
					data.date_format = defaultDateFormat
				}

				$setValues(data, ()=>this.setState({data}))
				this.loaded = true
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}

		this.setState({ loading: false })
	}

    /**
     * 验证function权限
     * @param data 提交内容
     * @param type 提交参数
     * @returns {boolean}
     */
	checkAuth = (data, type) => {
        if(data[type] == 0){
            return security.setRbacFunction.indexOf(type) === -1;
        }
        return true;
    }

	onSubmit = async (type) => {
	    const { data: settingInfo } = this.state;
		const { $formutil, close, data } = this.props
		const {  $invalid, $batchDirty, $params, $getFirstError, $setValues } = $formutil;
		const params = {...$params}
		const uid = security.userInfo && security.userInfo.id;
		const companyId = security.companyInfo && security.companyInfo.company_id;
        //验证是否有function权限
        if(!this.checkAuth(params, type)){
            this.getNoPermissionFloat(type);
            params[type] = settingInfo[type];
            $setValues(params);
            return;
        }
		if ($invalid) {
			$batchDirty(true)
			message.error($getFirstError())

			return
		}

		if (type !== 'user_access_control') {
			delete params.user_access_control;
		}

		Loading.global(true)

		try {
			const { data: resData } = await post('updateSettings' , params)
			const { code, data } = resData

			if (code === 0) {
				message.success('Success!')
				//重置 all stakeholder 添加修改confirm弹层标识
				this.getData()
				security.getStatus()
				if(type === 'user_access_control'){
					localStorage.removeItem(`ALL-STAKEHOLDER-CONTROL-REMIND-${uid}-${companyId}`);
					params.user_access_control == 1 && this.stakeholderEmailModel(params.user_access_control);
				}
			} else {
				message.error(data)
			}
		} catch (e) {
			message.error(e.message)
		}

		Loading.global(false)
	}

	stakeholderEmailModel = (userAccessControl) => {
		this.ConfirmInviteSelectedModels = Modal.open({
			component: props => <ModalContent title={__('Send invitation email')} close={props.close}>
				<ConfirmInviteSelectedModels
					close={props.close}
					resendStakeholderEmail={this.resendStakeholderByCompanyId}
					userAccessControl={userAccessControl}
					content={[__('The access control for stakeholders has been successfully enabled.'), __('To enhance your portfolio management, we strongly suggest that you send invitation emails to stakeholders who have not yet completed their registration. By selecting "Yes", you can initiate the sending of invitation emails.')]}
					button={[__('No'), __('Yes')]}
				/>
			</ModalContent>,
			width: 1025,
			maskClosable: false,
			className: 'sprout-modal confirm-invite'
		});
	}

	resendStakeholderByCompanyId = async(id, close) => {
		Loading.global(true)
		try {
			const { data: resData } = await post('resendStakeholderByCompanyId')
			const { code, data } = resData
			if (code === 0) {
				message.success('Success!')
				close();
			} else {
				message.error(data)
			}
		} catch (e) {
			message.error(e.message)
		}
		Loading.global(false)
	}

	handleAccessControlChange = (val, preValue) => {
		if (val !== preValue) {
			const { $setValues, $params, $reset } = this.props.$formutil
			const { board_email_control, board_access_control } = $params || {}
			$setValues({ board_email_control: board_access_control == 0 ? '0' : board_email_control })			
		}
	}

	render() {
		const { $formutil, close, data } = this.props
		const {  $invalid, $batchDirty, $params, $getFirstError } = $formutil
		const { board_access_control, board_email_control } = $params
		const { loading } = this.state

		return <Spin spinning={loading}>
				<div className="sprout-setting-section">
					<div className="setting-section-banner">
						{__('General settings')}
					</div>
					<div className="setting-section-content">
						<FormItem 
							name="country_of_incorporation"
							label={__('Country of Incorporation')}
							required
							colon={false}
							validMessage={{
								required: __('Currency is required'),
							}}
						>
							<CountrySelector />
						</FormItem>
						<FormItem 
							name="currency"
							label={__('Currency')}
							required
							colon={false}
							validMessage={{
								required: __('Currency is required'),
							}}						
						>
							<CurrencySelector />
						</FormItem>
						<FormItem 
							name="timezone"
							label={__('Time Zone')}
							required
							colon={false}
							validMessage={{
								required: __('Time Zone is required'),
							}}						
						>
							<TimeZone placeholder={__('Select')} />
						</FormItem>
						<FormItem 
							name="date_format"
							label={__('Date format')}
							required
							colon={false}
							validMessage={{
								required: __('Date format is required'),
							}}						
						>
							<DateFormatSelector placeholder={__('Select')} labelValue={moment().tz($params.timezone && $params.timezone.split(' ')[0])} />
						</FormItem>
						<div className="btns">
							<Permission type="company_permission_signatories_edit"><Button type="primary" onClick={this.onSubmit.bind(this, '')} >{__('Update settings')}</Button></Permission>
						</div>
					</div>
				</div>
				<Row gutter={8}>
					<Col span={8} >
						<div className="sprout-setting-section">
							<div className="setting-section-banner">
								{__('Stakeholder access control')}
							</div>
							<div className="sprout-setting-section-min-content">
								<Alert message={__('When this setting is disabled, users would not see issued securities under their portfolio view and emails are not sent to users.')} type="info" />
								<Alert message={<div>{__('If you would like to resend invitation emails to unregistered users, please go to the')} <Link to={{ pathname : `/home/stakeholders/stakeholders-list/invite-unregistered`} }>{__('All stakeholders')}</Link> {__('page. To control stakeholder access for individual users first enable stakeholder access below and then go to the')} <Link to={{ pathname : `/home/stakeholders/stakeholder-access`}}>{__('Stakeholder access')}</Link> {__('page to change individual access.')}</div>} />
								<FormItem
									name="user_access_control"
									label={__('Stakeholder access control')}
									colon={false}
									checked="1"
									unchecked="0"
								>
									<Switch style={{ width: 54 }} checkedChildren="On" unCheckedChildren="Off" />
								</FormItem>
								<div className="btns">
									<Permission type="company_permission_signatories_edit"><Button type="primary" onClick={this.onSubmit.bind(this, 'user_access_control')} >{__('Update settings')}</Button></Permission>
								</div>
							</div>
						</div>
					</Col>
					<Col span={8} >

						{/* Board access control */}
						<div className="sprout-setting-section">
							<div className="setting-section-banner">
								{__('Board access control')}
							</div>
							<div className="sprout-setting-section-min-content">
								<Alert message={__('When this setting is disabled, board members would not be able to access the platform and emails will not be sent to board members.')} type="info" />
								<FormItem
									name="board_access_control"
									label={__('Board access control')}
									colon={false}
									checked="1"
									unchecked="0"
									$onFieldChange={this.handleAccessControlChange}
								>
									<Switch style={{ width: 54 }} checkedChildren="On" unCheckedChildren="Off" />
								</FormItem>
								<Alert message={__('When this setting is disabled, board members will be able to access the platform but no emails will be sent to board members. If Board access control is disabled, this function will be automatically disabled too.')} type="info" />
								<FormItem
									name="board_email_control"
									label={__('Board email control')}
									colon={false}
									checked="1"
									unchecked="0"
								>
									<Switch style={{ width: 54 }} disabled={board_access_control == 0} checkedChildren="On" unCheckedChildren="Off" />
								</FormItem>
								<div className="btns">
									<Permission type="company_permission_signatories_edit"><Button type="primary" onClick={this.onSubmit.bind(this, 'board_access_control')} >{__('Update settings')}</Button></Permission>
								</div>
							</div>
						</div>
					</Col>
					<Col span={8} >
						<div className="sprout-setting-section">
							<div className="setting-section-banner">
								{__('Number display settings')}
							</div>
							<div className="sprout-setting-section-min-content">
								<Alert message={__('When enabled, all securities on the platform will be represented in decimal format, vesting and exercising in decimal numbers will be also supported. When disabled, all shares will be rounded down to the nearest whole number. Vesting and exercise will be in whole numbers.')} type="info" />
								<FormItem
									name="fractional_control"
									label={__('Enable decimal input for shares')}
									colon={false}
									checked="1"
									unchecked="0"
								>
									<Switch style={{ width: 54 }} checkedChildren="On" unCheckedChildren="Off" />
								</FormItem>
								<FormItem
									name="token_fractional_control"
									label={__('Enable decimal input for tokens')}
									colon={false}
									checked="1"
									unchecked="0"
								>
									<Switch style={{ width: 54 }} checkedChildren="On" unCheckedChildren="Off" />
								</FormItem>
								<FormItem
									name="fractional_digit"
									label={__('(Prices) Number of decimal')}
									colon={false}
									$defaultValue={2}
								>
									<Select style={{ width: 54 }}>
										<Option value={0}>0</Option>
										<Option value={1}>1</Option>
										<Option value={2}>2</Option>
										<Option value={3}>3</Option>
										<Option value={4}>4</Option>
										<Option value={5}>5</Option>

									</Select>
								</FormItem>
								<FormItem
									name="share_digit"
									label={__('(Shares) Number of decimal')}
									colon={false}
									$defaultValue={2}
								>
									<Select style={{ width: 54 }}>
										<Option value={0}>0</Option>
										<Option value={1}>1</Option>
										<Option value={2}>2</Option>
										<Option value={3}>3</Option>
										<Option value={4}>4</Option>
										<Option value={5}>5</Option>

									</Select>
								</FormItem>
								<FormItem
									name="token_fractional_digit"
									label={__('(Tokens) Number of decimal')}
									colon={false}
									$defaultValue={2}
								>
									<Select style={{ width: 54 }}>
										<Option value={0}>0</Option>
										<Option value={1}>1</Option>
										<Option value={2}>2</Option>
										<Option value={3}>3</Option>
										<Option value={4}>4</Option>
										<Option value={5}>5</Option>

									</Select>
								</FormItem>
								<div className="btns">
									<Permission type="company_permission_signatories_edit"><Button type="primary" onClick={this.onSubmit.bind(this, 'fractional')} >{__('Update settings')}</Button></Permission>
								</div>
							</div>
						</div>

					</Col>
				</Row>
			</Spin>
	}
}