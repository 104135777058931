import React, { Component } from 'react';
import { Input, Button, Table, message, Alert } from 'antd';
import { post } from 'srcPath/http';
import { formatPercent } from 'Utils';
import { accessLevelMap } from 'Utils/constant';
import RoundTags from 'components/RoundTags';
import openAccessLevelModal from './components/AccessModal';
import security from 'stores/security';
import { Link } from 'react-router-dom';
import { LOADING } from "Utils/constant";

import './style.scss';

const { Search } = Input;

/**
 * 字符串排序
 * @param a
 * @param b
 * @returns {number}
 */
const stringOrderBy = (a, b, key) => {
    const stringA = a[key].toUpperCase();
    const stringB = b[key].toUpperCase();
    return stringA < stringB ? -1 : stringA > stringB ? 1 : 0;
}

export default class CaptableAccess extends Component {
	state = {
		loading: false,
		tableData: [],
		selectedRows: [],
		filterString: ''
	}

	hasChecked = security.companyInfo && security.companyInfo.user_access_control === '1' // '1' 开关开启  ’0‘ 开关关闭

	componentDidMount() {
		this.getData()
	}

	componentWillUnmount() {
		this.modalRef && this.modalRef.close()
	}

	getData = async () => {
		this.setState({ loading: true })

		try {
			const { data: resData } = await post('getStakeHolderAccessList')

			const { code, data } = resData || {}

			if (code === 0) {
				this.setState({ tableData: data, selectedRows: [] })
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}

		this.setState({ loading: false })
	}

	rowSelection = (_, selectedRows) => {
		this.setState({
			selectedRows
		})
	}

	handleSearch = (value = '') => {
		this.setState({ filterString: value.trim(), selectedRows: [] })
	}

	onSetAccess = () => {
		this.modalRef = openAccessLevelModal(this.state.selectedRows, this.getData)
	}

	getColumn = () => {
		return [
			{
				title: __('Name'),
				dataIndex: 'stakeHolderName',
				fixed: true,
				ellipsis: true,
		        sorter: (a, b) => stringOrderBy(a, b, 'stakeHolderName')
			},
			{
				title: __('Rounds'),
				dataIndex: 'prefix',
				ellipsis: true,
				render: (text, record) => <RoundTags isPick allTags={record.share_prefix || []}  tags={text} />,
				width: '40%'
			},
			{
				title: __('Ownership'),
				dataIndex: 'owned',
				sorter: (a, b) => a.owned - b.owned,
				render:  text => formatPercent(text/10000)
			},
			{
				title: __('Stakeholder access level'),
				dataIndex: 'ownerShip',
				render: text => !this.hasChecked ? 'N/A' : (accessLevelMap[text] || '--'),
		        sorter: (a, b) => {
		            const stringA = accessLevelMap[a.ownerShip].toUpperCase();
		            const stringB = accessLevelMap[b.ownerShip].toUpperCase();
		            return stringA < stringB ? -1 : stringA > stringB ? 1 : 0;
		        }
			},
		]
	}

	render() {
		const { tableData, loading, selectedRows, filterString } = this.state
	    const rowSelection = {
	      selectedRowKeys: selectedRows.map(val => val.stakeHolderId),
	      onChange: this.rowSelection,
	      getCheckboxProps: data => ({ disabled: !this.hasChecked, ...data }) // 开关未开启没有权限，不允许选中
	    };
		const filteredData = tableData.filter(val => !!!filterString ? true : (val.stakeHolderName && val.stakeHolderName?.toLowerCase().includes(filterString?.toLowerCase())))

		return <div className="captable-access-root">
			<div className="captable-access-banner">
				{
					!this.hasChecked ? <Alert type="warning" message={<span>{__('Individual portfolio view is currently not enabled. Please go to the')} <Link to="/home/setting">{__('Settings')}</Link> {__('page to change your setting.')}</span>} /> : <Button type="primary" size="large" disabled={!(selectedRows && selectedRows.length)} onClick={this.onSetAccess}>{__('Set access')}</Button>
				}
				{
					this.hasChecked &&
				    <Search
				      allowClear
				      enterButton={__('Search')}
				      size="large"
				      onSearch={this.handleSearch}
				      onChange={ e => this.handleSearch(e.target.value)}
				      placeholder={__('Search')}
				    />
				}
			</div>
			<div className="captable-access-content">
				<Table 
					size="small"
                	locale={ loading && { emptyText: LOADING }}
					columns={this.getColumn()}  
					rowKey="stakeHolderId" 
					dataSource={filteredData} 
					pagination={false} 
					loading={loading}
					rowSelection={rowSelection}
					scroll={{ x: true, y: window.innerHeight - 280 }}
				/>
			</div>
		</div>
	}
}