import React, { Component } from 'react';
import { post } from 'srcPath/http';
import moment from 'moment';
import { message, Button, Table } from 'antd';
import url from 'url';
import TimeLine from './TimeLine';
import { boardMemberColumns } from './config';
import { constData } from 'Utils';
import { formatDate } from 'Utils/format/formatDate';
import './index.scss';


export default class BoardPublish extends Component {
	state = {
		tableData: [],
		loading: true,
		textValue: '',
		expirationDate: '',
		consent: {}
	}

	componentDidMount() {
		this.getInitConsent();
	}

	getInitConsent = async () => {
		const state = this.props.location.state || {};
		const { consentId } = state;
		try {
			const { data: resData } = await post('getConsentDetail', { consent_id: consentId })

			const { code, data } = resData || {}
			if (code == 0) {
				const content = data.consent_detail;
				const board_user_list = data.board_user_list || {};
				const newData = board_user_list.filter((val, index) => { 
					val.index = index 
					return val.board_role == 7;
				})
				this.setState({
					consent: content,
					tableData: newData
				});
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}
	}

	handleSave = async (jump) => {
		const { consent } = this.state;
		let requesturl = 'editConsent';
		if (jump) {
			requesturl = 'consentPublish';
		}

		try {
			const { data: resData } = await post(requesturl, consent)

			const { code, data } = resData || {}

			if (code == 0) {
				const state = this.props.location.state || {};
				const { consentId } = state;
				if (jump) {
					this.props.history.push({
						pathname: '/home/board/consents/preview',
						state: { consentId }
					});
				} else {
					this.props.history.push('/home/board/consents/list')
				}
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}
	}

	onChange(date, dateString) {
		console.log(date, dateString);
		this.setState({
			expirationDate: date
		});
	}

	handleNextStep() {
		this.handleSave('jump');
	}

	handleInputChange(e) {
		this.setState({
			textValue: e.target.value
		});
	}

	render() {
		const { tableData, consent } = this.state;
		const state = this.props.location.state || {};
		const { templateId } = state;
		return <div className="new-board-consents-root">
                <div className="board-consents-operate-area">
					<TimeLine step="3" type={Number(templateId) ? 'template' : 'consent'} />
					<Button className="board-consents-operate-save" onClick={this.handleSave.bind(this, null)}>{__('Save and finish later')}</Button>
					<Button type="primary" onClick={this.handleNextStep.bind(this)}>{__('Publish')}</Button>
                </div>
                <div className="board-consents-wrapper">
					<div className="board-setting-right-wrapper">
						<div className="board-consent-banner board-consent-banner-wrapper">
							<h4 className="board-consent-name">{consent.consent_name}</h4>
							<p className="board-expire-date">{__('Expires on')} {formatDate(consent.expiration_date)}</p>
							<p className="board-members">{tableData.length} {__('board directors')}</p>
							<Table 
								size="small" 
								rowKey="index" 
								columns={boardMemberColumns} 
								dataSource={tableData} 
								pagination={false} 
							/>
							<div className="board-consent-top">{__('After a consent is approved it can not be canceled and option grants will be sent for review by legal admin.')}</div>
						</div>
						<div className="board-consent-bottom">
							<div dangerouslySetInnerHTML = {{__html:consent.consent_content}}></div>
							<div className="resolution-approvers">
							{
								tableData.map((v, k)=>{
									return (
										<div className="directors-wrapper">
											<div className="directors-members">
												<p><b>{v.name}</b></p>
												<p><a href={`mailto:${v.email}`}>{v.email}</a></p>
												<p>{constData.boardRole[v.board_role]}</p>
											</div>
										</div>
									)
								})
							}
							</div>
						</div>
					</div>
				</div>
					
            </div>
	}
}