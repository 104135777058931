import React, { Fragment } from 'react';
import {Space, Table} from 'antd';
import { formatCurrencyNumber } from 'Utils';
import formatShares from 'Utils/format/formatFractionalNumber';
import { formatDate } from 'Utils/format/formatDate';
import customized from "../../images/customized.png";

export default function FinancingHistory (props) {
    const getRender = (defVal, newVal) => (!!newVal ? <span className="financing-history-blue">{formatShares(newVal)}</span> : formatShares(defVal));
    const getPriceRender = (defVal, newVal) => (!!newVal ? <span className="financing-history-blue">{formatCurrencyNumber(newVal)}</span> : formatCurrencyNumber(defVal));
	const columns = [
		{
			title: __('Round'),
			dataIndex: 'name',
			ellipsis: true,
			fixed: 'left',
            render: (val, data) => <Space size={10}><span>{val}</span>{data.isHistory ? <img src={customized} /> : ''}</Space>
		},
		{
			title: __('Closing Date'),
			dataIndex: 'closingDate',
			ellipsis: true,
            render: val => formatDate(val),
		},
		{
			title: __('Raised'),
			dataIndex: 'cashRaised',
			ellipsis: true,
			render: (val, data) => getPriceRender(val, data.history_cashRaised),
		},
		{
			title: __('Shares'),
			dataIndex: 'sharesIssued',
			render: (val, data) => getRender(val, data.history_sharesIssued),
		},
		{
			title: __('Original Issue Price'),
			dataIndex: 'originalIssuePrice',
			ellipsis: true,
			render: val => formatCurrencyNumber(val)
		},
		{
			title: __('Fully Diluted'),
			dataIndex: 'fullyDilutedShares',
			ellipsis: true,
            render: (val, data) => getRender(val, data.history_fullyDilutedShares),
		},
		{
			title: __('Post Money'),
			dataIndex: 'post_money_valuation',
			ellipsis: true,
			render: (val, data) => getPriceRender(val, data.history_post_money_valuation),
		}
	];

	return (
	    <Fragment>
            <Table size="small" rowKey="round" columns={columns} rowKey="round" dataSource={props.data || []} pagination={false} scroll={{ x: true }} className="finacing-history-found-table" rowClassName={data => data.isHistory ? 'financing-history-found-table-yellow' : ''} />
            <div className="financing-history-found-desc">
                <img src={customized} />
                <span>:&nbsp;{__('Customized')}</span>
            </div>
        </Fragment>
    )
}