import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import TokenTransactionTable from 'components/TokenTransaction';
import { post } from 'srcPath/http';

import './style.scss';

export default function Transaction(props) {
	const { coin, getAddressData } = props
	const [loading, setLoading] = useState(false);
	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [total, setTotal] = useState(0);
	const [list, setList] = useState([]);


	const getData = async() => {
		setLoading(true)

		try {
			const { data: resData } = await post('getCoinTransactions', {
				// 暂时取前二十条数据
				pageNumber: 1,
				pageSize: 20,
				coin_id: coin
			})

			const { code, data } = resData || {}

			if (code === 0) {
				const { total = 0, list = [] } = data || {}

				setTotal(total);
				setList(list)
			}

		} catch(e) {
			message.error(e.message)
		}

		setLoading(false)
	}

	useEffect(() => {
		getData()
	}, [pageSize, pageNumber, coin])

	const onPageSizeChange = (current, size) => {
		setPageSize(size)
		setPageNumber(1)
	}

	const onPageChange = (current, pageSize) => {
		setPageNumber(current)
	}

	return <div className="token-detail-transaction">
		<TokenTransactionTable 
			list={list}
			noAction
			loading={loading || props.loading}
			callback={getData}
			onClickWallet={getAddressData}
			paginationConfig={
				false
				// 暂时去掉
				// {
				// 	total,
				// 	current: pageNumber,
				// 	pageSize: pageSize,
				// 	onShowSizeChange: onPageSizeChange,
				// 	onChange: onPageChange
				// }
			}
		/>
	</div>
}
