import React, { Component } from 'react'
import Modal from 'components/Modal';
import { Spin, message } from 'antd';
import axios from 'axios';
import security from 'stores/security';

import CS from './CS';
import EA from './EA';
import PS from './PS';
import CN from './CN';
import Warrant from './Warrant';
import TokenGrantDetail from './TokenGrant';
import TokenWarrant from './TokenWarrant';


import './style.scss';

class Certifiacte extends Component {
	state = {
		loading: false,
		data: {},
	}

	componentDidMount() {
		this.getCertificateDetail();
	}

	getCertificateDetail = async() => {
		const {securityId, companyId } = this.props

		this.setState({ loading: true })

		await axios.post('', {
            company_id: companyId || security.companyInfo.company_id,
			uid: security.userInfo && security.userInfo.id,
            action:'getCertificateDetail',
            data: { security_id: securityId }
        }).then((response)=> {
            const { code, data } = response.data || {};

			if (code == 200) {
				this.setState({
					data
				})
			} else {
				message.error(data)
			}
        }).catch(function (error) {
            console.log(error);
        });

		this.setState({ loading: false })
	}

	renderCertificate = () => {
		const { data } = this.state;
		const { securityId } = this.props;
		data.securityId = securityId;
		const { type } = data || {}
		let res = <div className="certificate-detail-root"></div>

		switch(type) {
			case 'CS': res = <CS data={data} />; break;
			case 'EA': 
			case 'RSA': 
			case 'RSU': 
			case 'ISO':
			case 'NSO': 
			case 'INTL':
			case 'SAR':
			res = <EA data={data} />; break;
			case 'PS': res = <PS data={data} />; break;
			case 'CN': res = <CN data={data} />; break;
			case 'WARRANT': res = <Warrant data={data} />; break;
			default:
			res = <EA data={data} />; break;
		}

		return res
	}

	render() {
		const { loading } = this.state

		return <Spin tip="Loading..." spinning={loading}><div className="certificate-modal-root">
			{this.renderCertificate()}
		</div>
		</Spin>
	}
}

export default function openCertificateModal(securityId, companyId) {
	return Modal.open({
		component: <Certifiacte securityId={securityId} companyId={companyId} />,
		className: 'certificate-modal-wrap',
		width: '80%',
		maskClosable: true
	})
}

export function openGrantDetail(grantId) { 
	return Modal.open({
		component: <TokenGrantDetail id={grantId} />,
		className: 'certificate-modal-wrap',
		width: '80%',
		maskClosable: true
	})
}

export function openTokenWarrant(id) { 
	return Modal.open({
		component: <TokenWarrant id={id} />,
		className: 'certificate-modal-wrap',
		width: '85%',
		maskClosable: true
	})
}