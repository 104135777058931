import security from 'stores/security';
import { formatCurrencyNumber } from 'Utils';

const decimal = {
	331: 3, // Glints 
}

// 针对Glints 定制化需求
export default function formatExercisePrice(num) {
	return formatCurrencyNumber(num, decimal[security.companyId])
}