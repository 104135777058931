import React, { Component } from 'react';
import { Table, message, Dropdown, Menu } from 'antd';
import { I, formatCurrencyNumber } from 'Utils';
import { formatDate } from 'Utils/format/formatDate'
import Modal from 'components/Modal';
import moment from "moment";
import { post } from 'srcPath/http';
import { DownOutlined } from "@ant-design/icons";
import AdvanceTermsModels from '../AdvanceTermsModels/AdvanceTermsModels'
const stringOrderBy = (a, b, key) => {
    const stringA = a[key].toUpperCase();
    const stringB = b[key].toUpperCase();
    return stringA < stringB ? -1 : stringA > stringB ? 1 : 0;
}
class FMVTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataSource: [],
            columns: [
                {
                    title: __('Token Valuation'),
                    dataIndex: 'fairMarketValue',
                    align: 'left',
                    render: val => formatCurrencyNumber(val),
                    sorter: (a, b) => a.fairMarketValue - b.fairMarketValue,
                },
                {
                    title: __('Effective date'),
                    dataIndex: 'effectiveDate',
                    align: 'left',
                    sorter: (a, b) => {
                        const dataA = moment(a.effectiveDate, 'DD/MM/YYYY').format('YYYYMMDD');
                        const dataB = moment(b.effectiveDate, 'DD/MM/YYYY').format('YYYYMMDD');
                        return dataA - dataB;
                    },
                    render: (text, val) => formatDate(val.effectiveDate),

                },
                {
                    title: __('Expiration date'),
                    dataIndex: 'expirationDate',
                    align: 'right',
                    sorter: (a, b) => {
                        const dataA = moment(a.expirationDate, 'DD/MM/YYYY').format('YYYYMMDD');
                        const dataB = moment(b.expirationDate, 'DD/MM/YYYY').format('YYYYMMDD');
                        return dataA - dataB;
                    },
                    render: (text, val) => formatDate(val.expirationDate),
                },
                {
                    title: '',
                    dataIndex: '',
                    width: '60px',
                    // render: (text, value, index) => dropdownInfo(text, value, index)
                    render: (text, value, index) => {
                        return (
                            <Dropdown overlay={this.menu(text)} placement="bottomRight" trigger={['click']}>
                                <div className="tableMenu" ><DownOutlined style={{ color: '#FFF' }} /></div>
                            </Dropdown>
                        )
                    }
                },
            ],
        }
    }
    menu = (val) => (
        <Menu onClick={this.operate.bind(this, val)}>
            <Menu.Item key="0">
                <div>{__('Delete')}</div>
            </Menu.Item>
            <Menu.Item key="1">
                <div >{__('Edit Fair market value')}</div>
            </Menu.Item>
        </Menu >

    )
    operate = (val, index) => {
        let { key } = index
        switch (key) {
            case '0':
                this.delbindclick(val)
                break;
            case '1':
                this.editbindclick(val)
                break;
                break;
        }

    }
    payView = () => {
        const { payOverView } = this.props
        payOverView()
    }

    delbindclick = (val) => {
        const { id } = val
        let data = { id }
        post('deleteCoinFairMarketValue', data).then(res => {
            if (res.status == 200) {
                this.payView()
            }
        }).catch(function (error) {
        });
    }


    editbindclick = (val) => {
        const { payOverView, newdatalist, currency, EchartsSourec } = this.props
        this.advanceTermsModal = Modal.open({
            component: props => <AdvanceTermsModels
                close={props.close}
                val={val}
                isAdd={false}
                payOverView={payOverView}
                newdatalist={newdatalist}
                EchartsSourec={EchartsSourec}
                currency={currency}
            />,
            className: 'certificate-modal-wrap',
            width: '780px',
            maskClosable: false
        })
    }
    /**
    * 卸载
    */
    componentWillUnmount() {
        if (this.advanceTermsModal) {
            this.advanceTermsModal.close();
        }
    }
    render() {
        const { columns, } = this.state
        const { dataSource } = this.props
        return (
            <div>
                <Table
                    rowClassName={record => {
                        if (record.status == 0) return '';
                    }}
                    columns={columns}
                    pagination={false}
                    dataSource={dataSource}
                    rowKey={record => record.id}
                />
            </div>
        )
    }
}
export default FMVTable;