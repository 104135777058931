import React, { Component } from "react";
import dotImg from "../img/dot.png";
import {Divider, Dropdown, Input, Menu} from "antd";
import { formatNumber, I } from "Utils";
import editIcon from "../img/edit-icon.png"

class CapitalizationTitle extends Component {

    state = {
        visible: false,
    };

    stretch = (e) => {
        let { stretch, updateState, k, financingData } = this.props;
        const newStretch = stretch !== true;
        financingData[k]['stretch'] = newStretch;
        updateState('financingData', [...financingData], null, null, false);
    }

    returnData = (data, isFormat = true) => I(data, '') ? `${ isFormat ? this.props.clearNumber(data) : data }%` : '--';

    copyFinancing = (key, e) => {
        //阻止其他事件
        e.stopPropagation();
        const { addData } = this.props;
        addData(key);
        this.setState({visible:false})
    }

    deleteFinancing = (key, e) => {
        e.stopPropagation();
        const { financingData } = this.props;
        if(I(financingData.length, 0) <= 1){
            this.setState({visible:false})
            return;
        }
        const { deleteData } = this.props;
        deleteData(key);
        this.setState({visible:false})
    }

    /**
     * Scenario 菜单
     * @param key
     * @returns {JSX.Element}
     */
    menu = (key) => {
        const { financingData, data } = this.props;
        const { maxScenario } = data;
        return (
            <Menu>
                {I(financingData, '').length < maxScenario && <Menu.Item key="0">
                    <span onClick={this.copyFinancing.bind(this, key)}>{__('Duplicate scenario')}</span>
                </Menu.Item>}
                {
                    I(financingData.length, 0) > 1 && <Menu.Item key="1">
                        <span onClick={this.deleteFinancing.bind(this, key)}>{__('Delete scenario')}</span>
                    </Menu.Item>
                }
            </Menu>
        );
    }

    menuChange = (type, e) => {
        e.stopPropagation();
        this.setState({visible:true})
    };

    DropdownChange = (value) => {
        this.setState({visible:value})
    }

    updateName = (key, e) => {
        const { financingData, updateState } = this.props;
        let newFinancingData = financingData;
        newFinancingData[key].detailName = e.target.value;
        updateState('financingData', newFinancingData, false, false, false);
    }

    render() {
        const { visible } = this.state;
        const { financingData, k, getNumber } = this.props;
        const { statistics, detailName, isError } = financingData[k];
        const { postMoneyValuation, newPricePerShare, postMoneyOptionPool, postMoneyOptionPoolType, postMoneyOptionPoolShares, newMoney, safeNote, totalDilution } = statistics;
        return (
            <div className="new-financing-round-capitalization-title" onClick={this.stretch}>
                <div className="new-financing-round-capitalization-title-post">
                    <div className="new-financing-title-name">
                        <Input bordered={false} onClick={e=>e.stopPropagation()} onChange={this.updateName.bind(this, k)} value={detailName} suffix={<img src={editIcon} />} />
                    </div>
                    <div className="new-financing-round-title-right">
                        <ul>
                            <li>
                                <p>{__('Post-money valuation')}</p>
                                <p>{isError ? '--' : I(postMoneyValuation, '') ? getNumber(postMoneyValuation, null, true) : '--'}</p>
                            </li>
                            <li>
                                <p>{__('Share price')}</p>
                                <p>{isError ? '--' : I(newPricePerShare, '') ? getNumber(newPricePerShare, null, true) : '--'}</p>
                            </li>
                            <li className="new-financing-round-li-min">
                                <p>{__('New money')}</p>
                                <p>{this.returnData(newMoney)}</p>
                            </li>
                            <li className="new-financing-round-li-min">
                                <p>{__('SAFE')}&nbsp;/&nbsp;{__('Note')}</p>
                                <p>{this.returnData(safeNote)}</p>
                            </li>
                            <li className="new-financing-round-li-min">
                                <p>{__('Option pool')}</p>
                                <p>{postMoneyOptionPoolType === 1 ? this.returnData(postMoneyOptionPool) : formatNumber(postMoneyOptionPoolShares)}</p>
                            </li>
                            <li className="new-financing-round-li-min">
                                <p>{__('Total dilution')}</p>
                                <p>{this.returnData(totalDilution)}</p>
                            </li>
                        </ul>
                        <Dropdown overlay={this.menu.bind(this, k)} visible={visible} onVisibleChange={this.DropdownChange} trigger={['click']}>
                            <div onClick={this.menuChange.bind(this, true)} className="new-financing-round-title-right-dot" >
                                <img src={dotImg} />
                            </div>
                        </Dropdown>
                    </div>
                </div>
                <Divider />
            </div>
        );
    }
}
export default CapitalizationTitle;