import React, { Component } from 'react';
import { message, Select, Spin, Button, Input } from 'antd';
import { FormItem, withForm } from 'react-antd-formutil';
import { post } from 'srcPath/http';
import Permission from 'components/Permission';
import security from 'stores/security';
import moment from 'moment';
import { formatDateForDatePicker, urlReg } from 'Utils';
import DateSelector from 'components/DateSelector';
import { formatDate, form } from 'Utils/format/formatDate';
import './style.scss';

const email = new RegExp('(?![\\-_.])[\\w\\-.]+@(?:(?![\\-_])[\\w\\-\u4e00-\u9fa5]{1,63}\\.)+[A-Za-z0-9]{2,}')

const Option = Select.Option

const countries = ['Hong Kong','China','Indonesia','Singapore','Taiwan','Thailand','Vietnam']
const currencies = ['HKD','USD','SGD',]

@withForm
export default class Profile extends Component {
	state = {
		loading: false
	}

	componentDidMount() {
		this.getData()
	}

	getData = async () => {
		this.setState({ loading: true })

		try {
			const { data: resData } = await post('getCompanyProfile')

			const { data, code } = resData || {}

			if (code == 0) {
				const { $setValues } = this.props.$formutil
				$setValues(data)
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}

		this.setState({ loading: false })
	}


	onSubmit = async () => {
		const { $formutil, close, data } = this.props
		const {  $invalid, $batchDirty, $params, $getFirstError } = $formutil

		if ($invalid) {
			$batchDirty(true)
			message.error($getFirstError())

			return
		}

		this.setState({ loading: true })

		try {
			const { data: resData } = await post('updateSettings' , $params)
			const { code, data } = resData

			if (code == 200 || code == 0) {
				message.success('Success!')

				this.getData()
				security.getStatus()
			} else {
				message.error(data)
			}
		} catch (e) {
			message.error(e.message)
		}

		this.setState({ loading: false })
	}



	render() {
		const { loading } = this.state
		const formatter = security.companyInfo && security.companyInfo.date_format || 'DD/MM/YYYY'

		return <div className="sprout-setting-profile">
		<Spin spinning={loading}>
				<div className="sprout-setting-section">
					<div className="setting-section-banner">
						{__('Profile')}
					</div>
					<div className="setting-section-content">
						<div className="setting-section-content-form">
							<div className="profile-title-wrap">
								<div className="profile-title-block">
									<div className="profile-title">{__('Company details')}</div>
									<div className="profile-title-line" />
								</div>
								<div className="profile-tips">{__('Provide name, incorporation and accounting information.')}</div>
							</div>

							<FormItem 
								name="company_name"
								label={__('Company name')}
								colon={false}
								maxLength={129}
								required
								validMessage={{
									required: __('Company name is required'),
									maxLength: __('Max length is 128')
								}}
							>
								<Input placeholder={__('e.g. Alphabet Inc.')}/>
							</FormItem>
							<FormItem 
								name="dba"
								label={<div className="form-label">{__('Doing business as (DBA)')}<span> - {__('Optional')}</span></div>}
								colon={false}
								validMessage={{
									required: __('Company name is required'),
								}}
							>
								<Input />
							</FormItem>
							<FormItem 
								name="date_of_incorporation"
								label={__('Date of incorporation')}
								$formatter={formatDateForDatePicker}
								$parser={val => moment(val).format(formatter)}
								$parser={val => moment(val).format('DD/MM/YYYY')}
								required
								colon={false}
								validMessage={{
									required: __('Date of incorporation is required'),
								}}								
							>
								<DateSelector format={formatter} />
							</FormItem>
							<FormItem 
								name="business_registration"
								label={<div className="form-label">{__('Business registration')}<span> - {__('Optional')}</span></div>}
								colon={false}
							>
								<Input />
							</FormItem>
							<FormItem 
								name="legal_counsel"
								label={<div className="form-label">{__('Legal counsel (email)')}<span> - {__('Optional')}</span></div>}
								colon={false}
								pattern={email}
								validMessage={{
									pattern: __('Email address is invalid')
								}}
							>
								<Input />
							</FormItem>
							<FormItem 
								name="accounting_firm"
								label={<div className="form-label">{__('Company secretary (email)')}<span> - {__('Optional')}</span></div>}
								colon={false}
								pattern={email}
								validMessage={{
									pattern: __('Email address is invalid')
								}}
							>
								<Input />
							</FormItem>

							<div className="profile-title-wrap">
								<div className="profile-title-block">
									<div className="profile-title">{__('Company address')}</div>
									<div className="profile-title-line" />
								</div>
							</div>

							<FormItem 
								name="address"
								label={<div className="form-label">{__('Address')}<span> - {__('Optional')}</span></div>}
								colon={false}
							>
								<Input.TextArea autoSize={{minRows: 3}} />
							</FormItem>

							<div className="profile-title-wrap">
								<div className="profile-title-block">
									<div className="profile-title">{__('Additional information')}</div>
									<div className="profile-title-line" />
								</div>
								<div className="profile-tips">{__('Provide the company website and a short business description to complete the profile')}.</div>
							</div>
							<FormItem
								name="website"
								label={<div className="form-label">{__('Website')}<span> - {__('Optional')}</span></div>}
								pattern={urlReg}
								validMessage={{
									pattern: __('Please enter the correct URL')
								}}
							>
								<Input placeholder={__('e.g. http://sprout.zoom.hk/')} />
							</FormItem>
							<FormItem 
								name="business_description"
								label={<div className="form-label">{__('Business description')}<span> - {__('Optional')}</span></div>}
								colon={false}
							>
								<Input.TextArea autoSize={{minRows: 3}} />
							</FormItem>

							<div className="btns">
								<Permission type="company_permission_signatories_edit"><Button loading={loading} type="primary" onClick={this.onSubmit} >{__('Update settings')}</Button></Permission>
							</div>
						</div>
					</div>
				</div>
			</Spin>
		</div>
	}
}