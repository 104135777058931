import React, { Component } from "react";
import { Table } from 'antd';
import { shareClassConfig } from './TableColumnsConfig';
class ByShareClassModels extends Component {

    render() {
        const { shareClassList, loading } = this.props;
        return (
            <Table
                loading={loading}
                className="waterfall-stakeholders-table"
                size="middle"
                pagination={false}
                columns={shareClassConfig}
                dataSource={shareClassList}
                rowKey="keyId"
            />
        );
    }
}
export default ByShareClassModels;