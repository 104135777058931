import React, { Component, Fragment } from "react";
import { post } from 'srcPath/http';
import {Button, Dropdown, Input, Menu, message, Popconfirm, Space, Table} from "antd";
import Modal, { ModalContent } from 'components/Modal';
import {DownOutlined, PlusOutlined} from "@ant-design/icons";
import { formatDate } from 'Utils/format/formatDate';
import { I } from "Utils";
import TemplateRename from "./TemplateRename";
import Loading from 'components/Loading';
import templateImg from "../img/template-img.png";
import templatePdf from "../img/template-pdf.png";
import "../style.scss";
import TemplateAddChoice from "./TemplateAddChoice";
import TemplateDocusignEdit from "./TemplateDocusignEdit";

class TemplateList extends Component {

    saveNameModel = null;
    addModel = null;
    state = {
        loading: false,
        templateList: [],
        defTemplateList: [],
        searchValue: '',
    };

    componentDidMount() {
        this.getData();
    }

    column = () => {
        return [
            {
                title: __('Template name'),
                dataIndex: 'template_name',
                render: (text, data) => <Space size={12}><img src={ data.template_type == 2 ? templatePdf : templateImg } /><span>{text}</span></Space>,
                width: '50%',
            },
            {
                title: __('Type'),
                dataIndex: 'template_type',
                render: text => {
                    const type = {1: 'Editor', 2: 'PDF file'};
                    return type[text];
                }
            },
            {
                title: __('Updated'),
                dataIndex: 'update_time',
                render: text => formatDate(text),
            },
            {
                title: '',
                dataIndex: 'security',
                render: (text, data, index) => this.getMenu(data, index),
            },
        ];
    }

    /**
     * docusign 模板列表
     * @returns {Promise<void>}
     */
    getData = async () => {
        this.setState({ loading: true })
        try {
            const { data: resData } = await post('getSignTemplateList')
            const { data, code } = resData || {}
            if (code == 0) {
                this.setState({ templateList: data, defTemplateList: data, html: (data ? data[0].template_content : '') })
            } else {
                message.error(data)
            }
        } catch(err) {
            message.error(err && err.message)
        }
        this.setState({ loading: false })
    }

    /**
     * 列表、详情等页面切换
     */
    showPage = (data, index) => {
        const { template_type, id } = data;
        this.updateVisible(false, index);
        if(template_type == 1){
            const { saveIsHideBar, saveState } = this.props;
            saveIsHideBar(true);
            saveState({page: 2, id });
        }else{
            this.getDocusignTemplateUrl(id);
        }
    }

    openSaveNameModel = (data, index) => {
        const { id, template_name: templateName } = data;
        this.updateVisible(false, index);
        this.saveNameModel = Modal.open({
            component: props => (
                <ModalContent close={props.close} title={__("Rename E-Sign template")}>
                    <TemplateRename close={props.close} id={id} getData={this.getData} templateName={templateName} />
                </ModalContent>
            ),
            width: 642,
            className: 'sprout-modal',
            maskClosable: false
        });
    }

    /**
     * 模板编辑类别选择弹层
     */
    openAddModel = () => {
        const { updateActiveKey } = this.props;
        this.addModel = Modal.open({
            component: props => (
                <ModalContent close={props.close} title={__("Create new template")}>
                    <TemplateAddChoice close={props.close} updateActiveKey={updateActiveKey} getData={this.getData} openDocusignModel={this.openDocusignModel} />
                </ModalContent>
            ),
            width: 1000,
            className: 'sprout-modal',
            maskClosable: false
        });
    }

    /**
     * 删除模板
     * @param id
     * @param index
     */
    confirmDelete = (id, index) => {
        const { templateList, defTemplateList } = this.state;
        this.deleteTemplate(id);
        templateList[index].menuVisible = false;
        defTemplateList[index].menuVisible = false;
        this.setState({templateList, defTemplateList})
    };

    /**
     * 菜单显示状态改变事件
     * @param index
     * @param value
     */
    handleVisibleChange = (index, value) => {
        const { templateList, defTemplateList } = this.state;
        templateList[index].menuVisible = value;
        defTemplateList[index].menuVisible = value;
        this.setState({templateList, defTemplateList});
    }

    /**
     * 更新菜单显示状态
     * @param value
     * @param index
     */
    updateVisible = (value, index) => {
        const { templateList, defTemplateList } = this.state;
        templateList[index].menuVisible = value;
        defTemplateList[index].menuVisible = value;
        this.setState({templateList, defTemplateList});
    }

    /**
     * 删除模板
     */
    deleteTemplate = async (id) => {
        Loading.global(true);
        try {
            const { data: resData } = await post('deleteSignTemplate', {id})
            const { code, data } = resData;
            if(code === 0) {
                message.success(__("Success!"));
                this.getData();
            } else {
                message.error(data)
            }
        } catch(err) {
            message.error(err && err.message)
        }
        Loading.global(false);
    }

    /**
     * 菜单
     * @param data
     * @param index
     * @returns {JSX.Element}
     */
    menu = (data, index) => (
        <Menu>
            <Menu.Item onClick={this.showPage.bind(this, data, index)}>{data.template_type == 1 ? __('View details') : __('Edit')}</Menu.Item>
            <Menu.Item onClick={this.openSaveNameModel.bind(this, data, index)}>{__('Rename')}</Menu.Item>
            <Menu.Item>
                <Popconfirm
                    title="Are you sure to delete this?"
                    onConfirm={this.confirmDelete.bind(this, data.id, index)}
                    okText="Yes"
                    cancelText="No"
                    placement="topRight"
                >
                    <span className="docusign-color-red">{__('Delete')}</span>
                </Popconfirm>

            </Menu.Item>
        </Menu>
    )

    /**
     * 操作栏
     * @param data
     * @param index
     * @returns {JSX.Element}
     */
    getMenu = (data, index) => {
        return (
            <Dropdown
                overlay={this.menu( data, index)}
                onVisibleChange={this.handleVisibleChange.bind(this, index)}
                placement="bottomRight"
                trigger="click"
                visible={I(data.menuVisible, false)}
                getPopupContainer={ triggerNode => triggerNode.parentNode }
            >
                <div className="tableMenu"><DownOutlined style={{ color: '#FFF' }}/></div>
            </Dropdown>
        )
    }

    /**
     * 模板详情
     * @returns {JSX.Element}
     */
    getDetail = () => {
        const { html } = this.state;
        return (
            <Fragment>
                <Button className="docusign-template-callback"><i className="fa fa-angle-left board-icon-1"></i>{__('Cancel')}</Button>
                <div className="docusign-template-detail" dangerouslySetInnerHTML={{__html: html}}></div>
            </Fragment>
        )
    }

    /**
     * 卸载
     */
    componentWillUnmount() {
        this.saveNameModel && this.saveNameModel.close();
        this.addModel && this.addModel.close();
    }

    /**
     * 搜索列表
     * @param value
     */
    onSearch = (value) => {
        const { defTemplateList } = this.state;
        const templateList = defTemplateList.filter(v=>v.template_name.indexOf(value) === 0);
        this.setState({templateList})
    }

    addTemplate = () => {
        this.openAddModel();
    }

    getDocusignTemplateUrl = async (id) => {
        Loading.global(true);
        try {
            const { data: resData } = await post('editDocusignTemplate', {id})
            const { data, code } = resData || {}
            if (code == 0) {
                this.openDocusignModel(data);
            } else {
                message.error(data)
            }
        } catch(err) {
            message.error(err && err.message)
        }
        Loading.global(false);
    }

    openDocusignModel = (url) => {
        this.docusignModel = Modal.open({
            component: props => <TemplateDocusignEdit close={props.close} url={url} getData={this.getData} />,
            className: 'sprout-modal docusign-model',
            maskClosable: false
        });
    }

    render() {
        const { loading, templateList } = this.state;
        return (
            <div className="docusign-template-list-root">
                <div className="template-list-header">
                    <Input.Search className="template-list-header-search"  placeholder="" onSearch={this.onSearch} enterButton={__("Search")} />
                    <Button className="template-list-header-add" type="primary" onClick={this.addTemplate}><PlusOutlined />{__('Add')}</Button>
                </div>
                <Table
                    columns={this.column()}
                    dataSource={templateList}
                    pagination={false}
                    loading={loading}
                    rowKey={'id'}
                    className="warrant-table-new bigger_checkbox"
                />
            </div>
        );
    }
}
export default TemplateList;