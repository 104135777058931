import React, { Component } from 'react';

export default class EmailPreview extends Component {
	state = {
		emailHtml: ''
	}

	componentDidMount() {
		this.initData()
	}

	async initData() {
		const emailContent = await localStorage.getItem('SPROUT_EMAIL_PREVIEW');
		this.setState({
			emailHtml: emailContent
		});
		document.body.innerHTML = emailContent;
	}	

	render() {

		const { emailHtml } = this.state

		return <div dangerouslySetInnerHTML = {{__html:emailHtml}}></div>
	}
}