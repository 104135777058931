import React, { Component } from 'react';
import { Collapse, message, Tooltip, Space } from 'antd';
import { Link  } from 'react-router-dom'
import { QuestionCircleOutlined } from '@ant-design/icons';
import { formatNumber, formatPercent } from 'Utils';
import { post } from 'srcPath/http';
import security from 'stores/security';
import grantsIcon from '../images/grants-icon.png';
import warrantsIcon from '../images/warrants-icon.png';
import walletIcon from '../images/wallets-icon.png';
import classnames from 'classnames';
import TokenSelector, { getDefaultTokenId } from 'B/components/TokenSelector'
import  {openGrantDetail, openTokenWarrant } from 'components/CertificateDetail';

import Loading from 'components/Loading';
import '../style.scss'

const { Panel } = Collapse;

export default class RelationShip extends Component {
	state = {
		totalQuantity: 1,
		coinId: getDefaultTokenId(),
		totalInfo: {},
	}

	componentDidMount() {
		this.getData()
	}

	handleMenu = value => {
		this.setState({
			coinId: value
		})

		this.getData(value)
	}

	getData = async (coinIdKey) => {
		const { coinId } = this.state

		Loading.global(true)

		try {

			const { data: resData } = await post("getCoinCapTable", { id: coinIdKey || coinId })

			const { code, data } = resData || {}

			if (code === 0) {
				let totalQuantity = 0

				if (data?.category_list) {
					data?.category_list.map((category) => {
						totalQuantity += +category?.quantity ?? 0
					})
				}

				if (data?.unlabeled_list) {
					totalQuantity += +data?.unlabeled_list?.quantity ?? 0
				}

				this.setState({ data, totalQuantity, totalInfo: data?.total_info })
			}

		} catch (err) {
			message.error(err.message)
		}

		Loading.global(false)
	}

	renderCommonHeader = (data, totalInfo) => {
		const { relationship, outstanding, fully_diluted, user_list } = data || {}
		const { outstanding: totalOutstanding, fully_diluted: totalFullyDiluted } = totalInfo
		const holderText = user_list?.length > 1 ? 'holders' : 'holder'

		return <div className="certificate-content-header">
			<div className="header-name" title={relationship}>
				{relationship}
				<div className="description">
					<span>{formatNumber(user_list?.length, 0)}</span>&nbsp;
					<span>{ holderText}</span>
				</div>
			</div>
			<div className="table-td">{formatNumber(outstanding)}</div>
			<div className="table-td">{formatPercent(outstanding/totalOutstanding)}</div>
			<div className="table-td">{formatNumber(fully_diluted)}</div>
			<div className="table-td">{formatPercent(fully_diluted/totalFullyDiluted)}</div>
		</div>
	}

	renderCommonHeader2 = (data, totalInfo, type) => {
		const { stakeholder_name, fully_diluted, outstanding, wallet_address_list, coin_warrant_list, name, wallet_address } = data || {}
		const { outstanding: totalOutstanding, fully_diluted: totalFullyDiluted } = totalInfo
		const walletsText = wallet_address_list?.length > 1 ? 'certificates' : 'certificate'
		const isUnlabeled = type === 'unlabeled'
		const isCoin = type === 'coin'

		return <div className="certificate-content-header">
			<div className={classnames('header-name', { 'header-name-unlabeled': isUnlabeled })}>
				{isUnlabeled && (
					<Space>
					<Tooltip title="Unlabelled category shows Top-20 unlabelled wallets based on Ownership."> {__('Unlabeled')} <QuestionCircleOutlined /></Tooltip>
					<Link to="/home/stakeholders/wallets-list">{__('Label now')}</Link>
				</Space>
				)}

				{!isUnlabeled && (
					<span>{stakeholder_name}</span>
				)}
				
				{!isUnlabeled && !isCoin && (
					<div className="description">
						<span>{name}</span> <br />
						<span>{formatNumber((wallet_address_list?.length + coin_warrant_list?.length), 0)} {__('items')}</span>&nbsp;
					</div>
				)}
				{!isUnlabeled && isCoin && (
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%' }}>
						<img src={walletIcon} width="32" height="32" />
						<div className="name" style={{ marginLeft: '5px' }}>
							<a>{wallet_address}</a>
						</div>
					</div>
				)}
			</div>
			<div className="table-td">{formatNumber(outstanding)}</div>
			<div className="table-td">{formatPercent(outstanding/totalOutstanding)}</div>
			<div className="table-td">{formatNumber(fully_diluted)}</div>
			<div className="table-td">{formatPercent(fully_diluted/totalFullyDiluted)}</div>
		</div>
	}

	handleClick = (type, id) => {
		if (!type || type === 'COIN_WALLET') {
			return
		}
		type === 'COIN_WARRANT' ? openTokenWarrant(id) : openGrantDetail(id)	
	}

	renderCommonItem = (data) => {
		const { totalInfo } = this.state
		const { fully_diluted, outstanding, relationship, stakeholder_name, type, custom_label_id, id, wallet_address, coin_grant_list, wallet_address_list } = data || {}
		const { outstanding: totalOutstanding, fully_diluted: totalFullyDiluted } = totalInfo
		const percent = outstanding / totalOutstanding

		if (coin_grant_list?.length > 0) {
			return (
				<Collapse style={{ paddingLeft: '30px' }}>
					<Panel header={this.renderCommonHeader2(data, totalInfo, 'coin')}>
						{data.coin_grant_list && data.coin_grant_list.map((val, key) => <div key={key}>{this.renderCommonItem(val)}</div>)}
					</Panel>
				</Collapse>
			)
		}
		return (
			<div className="certificate-content-item">
				<div className="item-name" onClick={() => this.handleClick(type, id)}>
					{['COIN_WARRANT', 'COIN_GRANT', 'COIN_WALLET'].includes(type) && (
						<>
							<img src={type === 'COIN_WARRANT' ? warrantsIcon : (type === 'COIN_WALLET' ? walletIcon : grantsIcon)} width="32" height="32" />
							<div className="name">
								<a>{custom_label_id}</a>
							</div>
						</>
					)}
					{!['COIN_WARRANT', 'COIN_GRANT', 'COIN_WALLET'].includes(type) && !coin_grant_list?.length && (
						<>
							<img src={walletIcon} width="32" height="32" />
							<div className="name">
								<a>{wallet_address}</a>
							</div>
						</>
					)}
				</div>
				<div className="table-td">{formatNumber(outstanding)}</div>
				<div className="table-td">{formatPercent(percent)}</div>
				<div className="table-td">{formatNumber(fully_diluted)}</div>
				<div className="table-td">{formatPercent(fully_diluted/totalFullyDiluted)}</div>
			</div>
		)
	}

	render() {
		const { data, totalQuantity, coinId, totalInfo } = this.state
		const { category_list = [], unlabeled_list = [] } = data || {}

		// 判断用户是否属于 Esop admin
		const flag = security.companyInfo && security.companyInfo.flag
		const isEsopAdmin = flag === '5'

		return <div className="captable-token-root">
				<div className="captable-token--banner">
					<TokenSelector coinId value={coinId}  onChange={this.handleMenu} />
				</div>
				<div className="capitalization-header-wrap">
					<div className="certificate-title"></div>
					<div className="table-th">{__('Circulation')}</div>
					<div className="table-th">{__('Ownership')}</div>
					<div className="table-th">{__('Fully diluted')}</div>
					<div className="table-th">{__('Ownership')}</div>
				</div>
				<Collapse>
					{
						category_list && category_list.map((value, index) => (
							<Panel showArrow={value?.user_list?.length > 0} key={index + 'common'} header={this.renderCommonHeader(value, totalInfo)} className={`common${index%5}`} collapsible={isEsopAdmin ? 'disabled' : true}>
								<Collapse>
									{
										value.user_list && value.user_list.map((data, itemIndex) => (
											<Panel key={itemIndex} header={this.renderCommonHeader2(data, totalInfo)}>
												{data.wallet_address_list && data.wallet_address_list.map((val, key) => <div key={key}>{this.renderCommonItem(val)}</div>)}
												{data.coin_warrant_list && data.coin_warrant_list.map((val, key) => <div key={key}>{this.renderCommonItem(val)}</div>)}
											</Panel>
										))
									}
								</Collapse>
							</Panel>
						))
					}
					<Panel className="unlabeled" showArrow={unlabeled_list?.wallet_address_list?.length > 0} header={this.renderCommonHeader2(unlabeled_list, totalInfo, 'unlabeled')}>
						{unlabeled_list?.wallet_address_list?.length && unlabeled_list.wallet_address_list.map((val, key) => <div key={key} >{this.renderCommonItem(val)}</div>)}
					</Panel>
				</Collapse>
				<div className="table-total">
					<div className="table-total-td-first">{__('Total')}</div>
					<div className="table-total-td">{formatNumber(totalInfo?.outstanding)}</div>
					<div className="table-total-td">{formatPercent(1)}</div>
					<div className="table-total-td">{formatNumber(totalInfo?.fully_diluted)}</div>
					<div className="table-total-td">{formatPercent(1)}</div>
				</div>
		</div>
	}
}