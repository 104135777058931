import React, { Component } from "react";
import Header from "../header";
import {Card, Input, Table} from "antd";
import {columnsConfig} from "./columnsConfig";
import Filter from 'components/Filter';
import { post } from 'srcPath/http';
import Loading from 'components/Loading';
import "./style.scss";
import {CloseOutlined, SearchOutlined} from "@ant-design/icons";

class HistoryModel extends Component {

    state = {
        filter: {},
        searchValue: '',
        orderList: [],
        total: 0,
        pageSize: 10,
        page: 1,
    };

    componentDidMount() {
        this.getBillingHistoryList();
    }

    /**
     * 获取订单列表
     */
    getBillingHistoryList = () => {
        let pageSize = localStorage.getItem('historyPageSize') || this.state.pageSize;
        this.setState({ pageSize })
        let { page, filter, sort, searchValue } = this.state;
        Loading.global(true)
        post('getOrderList', { pageSize, page, filter, search: searchValue }).then((response)=> {
            if(response.data.code === 0){
                const { list = [], total } = response.data.data;
                this.setState({orderList: list, total, pageSize});
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
            Loading.global(false)
        }).catch(function (error) {
            console.log(error);
            Loading.global(false)
        });
    }

    /**
     * 每页记录条数change回调
     * @param _
     * @param pageSize
     */
    onShowSizeChange = ( _, pageSize) => {
        localStorage.setItem('historyPageSize', pageSize);
        this.getBillingHistoryList();
    }

    /**
     * 页数
     * @param page
     */
    onPageChange = (page) => {
        this.setState({page}, ()=>{
            this.getBillingHistoryList();
        })
    }

    searchInputChange = (e) => {
        this.setState({searchValue: e.target.value})
    }
    searchEnter = (e) => {
        const { value } = e.target;
        this.setState({searchValue: value}, ()=>this.getBillingHistoryList());
    }

    onFilterChange = (val) => {
        this.setState({filter: val}, ()=>{
            this.getBillingHistoryList();
        })
    }

    clearSearchValue = () => {
        this.setState({searchValue: ''});
        this.getBillingHistoryList();
    }

    render() {
        const { filter, searchValue, orderList, total, pageSize } = this.state;
        const { ...props } = this.props;
        return (
            <div className="sprout-setting-billing">
                <Header {...props} />
                <div className="sprout-setting-billing-history">
                    <div className="billing-history-title">
                        <h2>Billing history</h2>
                        <div className="billing-history-title-right">
                            <Filter callback={val => this.onFilterChange(val)} fiterData={filter} type="billingHistory" />
                            <Input
                                value={searchValue}
                                placeholder={__('Search order ID or product')}
                                onChange={this.searchInputChange}
                                onPressEnter={this.searchEnter}
                                prefix={<SearchOutlined className="search-prefix-icon" onClick={this.getBillingHistoryList} />}
                                suffix={searchValue && <CloseOutlined className="search-close-icon" onClick={this.clearSearchValue} />}
                            />
                        </div>
                    </div>
                    <Table
                        className="billing-history-table"
                        columns={columnsConfig}
                        pagination={{
                            position: ['bottomCenter'],
                            showSizeChanger: true, total,
                            onShowSizeChange: this.onShowSizeChange,
                            onChange: this.onPageChange,
                            pageSize,
                        }}
                        dataSource={orderList}
                    />
                </div>
            </div>
        );
    }
}
export default HistoryModel;