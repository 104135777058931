import React, { Component } from "react";
import {Table} from "antd";
import security from 'stores/security';
import { columnsConfig } from './tableColumnsConfig'
import './style.scss';

class StakeholdersTableModels extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading : false,
            selectedRowKeys : [],
        }
    }

    componentDidMount(){
        this.initFilterColumn();
        const { onRef } = this.props;
        onRef(this);
    }

    /**
     * 清空checkbox所有选中内容
     */
    clearAll = () => {
        this.setState(()=>({
            selectedRowKeys : []
        }))
    }

    /**
     * checkbox事件
     * @param selectedRowKeys
     * @returns {{onChange: StakeholdersTableModels.onSelectChange, selectedRowKeys}}
     */
    rowSelection = (selectedRowKeys) => ({
        selectedRowKeys,
        onChange: this.onSelectChange,
    })

    /**
     * 全选change事件
     * @param selectedRowKeys
     */
    onSelectChange = selectedRowKeys => {
        const { holderListStatusChange } = this.props;
        holderListStatusChange(selectedRowKeys);
        this.setState({ selectedRowKeys });
    };

    onAddressRemove = () => {
        const { onRemove } = this.props
        onRemove && onRemove()
    }

     /**
     *
     * @param pagination
     * @param filters
     * @param sorter
     */
    handleTableChange = (pagination, filters, sorter) => {
        let sort = {}
        const { order, column } = sorter

        if (column?.sortName) {
            sort = {
                column: column.sortName,
                type: order
            }

            this.props.sortTable?.(sort)
        }else{
            sort = {}
            this.props.sortTable?.(sort)
        }
    }

    initFilterColumn = () => {
        const flag = security.companyInfo && security.companyInfo.flag;
        const isEsopOrCaptableAdmin = flag === '5' || flag === '6';
        const tableColumn = columnsConfig(flag, isEsopOrCaptableAdmin, this.props, this.onAddressRemove)
        const { updateState } = this.props;
        updateState({tableColumn});
    }

    render() {
        // 判断用户是否属于 Esop admin 或者 Captable admin
        const flag = security.companyInfo && security.companyInfo.flag
        const isEsopOrCaptableAdmin = flag === '5' || flag === '6'
        const { contentHeight, loading, selectedRowKeys } = this.state;
        const { curSelectRelation, tableColumn, holderLists, paginationConfig } = this.props;
        return (
            <Table
                pagination={{ 
                    ...paginationConfig,
                    position: ['bottomCenter'],
                 }}
                scroll={{ x: true, y: innerHeight ? innerHeight - 280 : false }}
                loading={loading}
                rowKey="id"
                className="all-stakeholders-table"
                columns={tableColumn || []}
                // columns={columnsConfig(flag, isEsopOrCaptableAdmin, this.props, this.onAddressRemove)}
                rowSelection={this.rowSelection( selectedRowKeys)}
                dataSource={holderLists}
                onChange={this.handleTableChange}
            />
        );
    }
}

export default StakeholdersTableModels;