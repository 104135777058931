import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Grants from './Grants';
import security from 'stores/security';
import DraftVesting from './DraftVesting';

export default class Security extends Component {
    path = this.props.match.path

    config = [
        {
            path: `${this.path}`,
            component: Grants,
            permission: 'securities_shares',
            exact: true,
        },
        {
            path: `${this.path}/draft-vesting`,
            component: DraftVesting,
            permission: 'securities_draftWarrants',
            exact: true,
        },
    ]

    render() {
        return <div className="sprout-captable-root">
            <Switch>
                {
                    this.config.map((val, index) => security.hasPermission(val.permission) && <Route key={index} exact={val.exact} path={val.path} component={val.component} />)
                }
            </Switch>
        </div>
    }
}