import React, { useEffect, useState } from 'react';
import { Select, message, Button } from 'antd';
import { post } from 'srcPath/http';
import { openEditStakeholderModal } from 'B/components/WalletLabel/EditStakeholder';
import { openEditWalletModal } from 'B/components/WalletLabel/EditWalletlabel';
import { PlusOutlined } from '@ant-design/icons';
import { omit } from 'lodash'
import security from 'stores/security';
import Modal, { ModalContent } from 'components/Modal';
import ConfirmSaveStakeholderModels from "B/Stakeholder/Stakeholder/components/ConfirmSaveStakeholderModels";

const Option = Select.Option

export default function Stakeholder(props) {
	const [option, setOption] = useState([])
	const { includeLabel, showAdd = false, history } = props

	const getOptions = async () => {
		try {
			//Grants => Assign token grant => 列表中增添了label

			const getStakeholderListApi = includeLabel ? 'getNameList4Address' : 'getStakeHolderList'

			const { data: resData } = await post(getStakeholderListApi)

			const { code, data } = resData || {}

			if (code === 0) {
				setOption(data)
			} else {
				message.error(data)
			}
		}catch(e) {
			message.error(e.message)
		} 
	}

	useEffect(() => {
		getOptions()
	}, [])

	const saveStakeholder = () => {
		openEditStakeholderModal(null, getOptions)
	}

	const confirmAccessControl = () => {
		const userAccessControl = security.companyInfo && security.companyInfo.user_access_control;
		const uid = security.userInfo && security.userInfo.id;
		const companyId = security.companyInfo && security.companyInfo.company_id;
		const allStakeholderControlRemind = localStorage.getItem(`ALL-STAKEHOLDER-CONTROL-REMIND-${uid}-${companyId}`);
		if(allStakeholderControlRemind){
			saveStakeholder();
		}else{
			if(userAccessControl > 0){
				saveStakeholder();
			}else{
				return Modal.open({
					component: props => <ModalContent title={__('Reminder')} close={props.close}>
						<ConfirmSaveStakeholderModels
							history={history}
							type={'add'}
							saveStakeholder={saveStakeholder}
							close={props.close}
						/>
					</ModalContent>,
					width: '690px',
					maskClosable: true,
					className: 'sprout-modal'
				})
			}
		}
	}

	const renderStakehoderDrop = menu => <>
		<div className="sprout-flex">
			<Button type="link" onClick={() => confirmAccessControl()}>
				<PlusOutlined />{__('Add stakeholder')}
			</Button>
			<Button type="link" onClick={() => openEditWalletModal(null, getOptions)}>
				<PlusOutlined /> {__('Add wallet label')}
			</Button>
		</div>
		{menu}
	</>

	return <Select 
		{...omit(props, ['includeLabel', 'showAdd'])}
		showSearch
		optionFilterProp="children"
		dropdownMatchSelectWidth={false}
		dropdownRender={showAdd && renderStakehoderDrop}	
	>
		{
			option.map((val, index) => {
				return <Option key={index} value={val.id}>
					{val.name}{ val.email ? ` - ${val.email}` : '' }
				</Option>
			})
		}
	</Select>
}
