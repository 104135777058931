/**
 * 移动文件或者文件夹到另一个文件夹
 * @author wuping
 */
import React, { Component, useState, useEffect } from 'react';
import 'assets/css/dataroom.css';
import { post } from 'srcPath/http';
import { cloneDeep } from 'lodash';
import classnames from 'classnames';
import '../index.scss'
import Permission, { noPermission } from 'components/Permission';
import security from 'stores/security';
import { message, Tabs, Upload, Tooltip, Breadcrumb, Button, Space, Spin, Menu, Tree } from 'antd';
import { FolderOpenOutlined, FileOutlined, PlusOutlined, UploadOutlined, EllipsisOutlined, DownOutlined, } from '@ant-design/icons';

const { SubMenu } = Menu;
export default function MoveFile(props) {
    const [ loading, setLoading ] = useState(false)
    const [ treeData, setTreeData] = useState([])
    const [selectedKeys, setSelectedKeys] = useState([])
    const [expandKeys, setExpandKeys] = useState(['0'])

    useEffect(() => {
 		initTreeData()
        setExpandKeys(['0'])
	}, [])

    const initTreeData = () => {
        const { dirList = [] } = props
        const treeData = renderTreeNode(dirList, [])

        setTreeData(treeData)
    }

    const renderTreeNode = (dirList, treeData, disabledParent) => {
        const { selectFile = [] } = props
        dirList.forEach((dirInfo) => {
            if (dirInfo?.type != '2') {
                const treeObj = {
                    title: dirInfo.name,
                    key: dirInfo.id !== undefined ? String(dirInfo.id) : Math.random(),
                    icon: <FolderOpenOutlined />
                }
                if (dirInfo?.name === 'Consents' || dirInfo?.name === 'Meeting') {
                    treeObj.disabled = true
                }
                // 不可移动到自己目录下 并且 不可移动到自己的子目录下
                if (selectFile?.includes(dirInfo?.id) || disabledParent) {
                    treeObj.disabled = true
                }
                if (dirInfo?.fileList?.length > 0) {
                    treeObj.children = renderTreeNode(dirInfo?.fileList, [], treeObj.disabled)
                }
                if (dirInfo?.type != '1') {
                    return
                }
                
                treeData.push(treeObj)
            }
        })
        return treeData
    }

    const onSelect = (selectedKeys, info) => {
        setSelectedKeys(selectedKeys)
    }

    const onExpand = (expandedKeys, {expanded: bool, node}) => {
        // console.info(expandedKeys, {expanded: bool, node})
        setExpandKeys(expandedKeys)
    }
    

    const confirmMove = async () => {
        const { selectFile = [], dirInfo, onReload } = props
        const params = {
            id: selectFile,
            target_id: selectedKeys?.[0]
        }
        try {
            const { data: resData } = await post('newMoveDocument', params)

            const { code } = resData || {}

            if (code === 0) {
                message.success('success')
                onReload('Move', dirInfo)
            }
        } catch(err) {
            message.error(err & err.message)
        }
    }

    return (
        <div className="data-room-move-file-content">
			{/* 文件操作区域 */}
			<Spin spinning={loading}>
            <Tree
                expandedKeys={expandKeys}
                switcherIcon={<DownOutlined />}
                onExpand={onExpand}
                showIcon
                height={400}
                onSelect={onSelect}
                treeData={treeData}
            />

			</Spin>
            <div className="modal-footer box-footer">
                <button onClick={confirmMove} disabled={selectedKeys?.length == 0} type="button" className="btn boxbtn-to bg-darkblue update-btn">{__('Move here')}</button>
            </div>
        </div>
    )
}
    
