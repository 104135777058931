import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import StockOptionAwards from "./StockOptionAwards";
import DraftOptions from './DraftOptions';
import DraftRsas from './DraftRsas';
import DraftRsus from './DraftRsus';
import DraftSars from './DraftSars';
import security from 'stores/security';

export default class EquityAwards extends Component {
    path = this.props.match.path

    config = [
        {
            path: `${this.path}`,
            component: StockOptionAwards,
            permission: 'securities_EA',
            exact: true,
        },
        {
            path: `${this.path}/draft-option`,
            component: DraftOptions,
            permission: 'securities_draftEA',
            exact: true,
        },
        {
            path: `${this.path}/draft-rsas`,
            component: DraftRsas,
            permission: 'securities_draftEA',
            exact: true,
        },
        {
            path: `${this.path}/draft-rsus`,
            component: DraftRsus,
            permission: 'securities_draftEA',
            exact: true,
        },
        {
            path: `${this.path}/draft-sars`,
            component: DraftSars,
            permission: 'securities_draftEA',
            exact: true,
        },
    ]

    render() {
        return <div className="sprout-captable-root">
            <Switch>
                {
                    this.config.map((val, index) => security.hasPermission(val.permission) && <Route key={index} exact={val.exact} path={val.path} component={val.component} />)
                }
            </Switch>
        </div>
    }
}