import React from 'react';

import Stakehoder from '../forms/Stakeholder';
import Rounds from '../forms/Rounds';
import Relationship from '../forms/Relationship';
import RegisterStatus from '../forms/RegisterStatus';
import Ownership from '../forms/Ownership';
import Country from '../forms/Country';
import JobTitle from '../forms/JobTitle'
import AccessLevel from '../forms/AccessLevel'
import ActiveStakeholders from '../forms/ActiveStakeholders';

export default function Form() { 
    return <div>
        <ActiveStakeholders />
        <Stakehoder />
        <Rounds />
        <Ownership />
        <Country />
        <JobTitle />
        <Relationship />
        <AccessLevel />
        <RegisterStatus />
    </div>
}