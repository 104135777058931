import React, { Component, Fragment } from "react";
import {Button, Checkbox, message, Space, Table} from "antd";
import {fullyDilutedColumn} from "./column-config";
import { post } from 'srcPath/http';
import { I, calculator } from "Utils";
import { formatDate } from 'Utils/format/formatDate';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import security from 'stores/security';
import ColumnFilter, { columnsFilter } from "./ColumnFilter";

class FullyDilutedShare extends Component {

    state = {
        loading: false,
        securityList: [],
        selectedRowKeys: [],
        total: 300,
        filterList: [],
    }

    componentDidMount() {
        this.getHistorySecurity();
        this.initFilterList();
    }

    /**
     * 获取Fully diluted share列表数据
     * @returns {Promise<void>}
     */
    getHistorySecurity = async() => {
        const { id } = this.props;
        this.setState({ loading: true });
        try {
            const { data: resData } = await post( 'getHistorySecurity', {id})
            const { code ,data } = resData;
            if (code === 0) {
                this.initSecurityList();
                this.setState({securityList: data});
                this.fullyDilutedTotal();
            } else {
                message.error(resData)
            }
        } catch(err) {
            message.error(err.message)
        }
        this.setState({ loading: false });
    }

    /**
     * 获取缓存中的filter数据
     */
    initFilterList = () => {
        const uid = security.userInfo && security.userInfo.id;
        const { companyId } = security;
        const filter = localStorage.getItem('filter');
        const { historyCertificate } = I(filter, '') && JSON.parse(filter);
        this.setState({filterList: I(historyCertificate, '') && historyCertificate[`${uid}-${companyId}`] && historyCertificate[`${uid}-${companyId}`] || fullyDilutedColumn() })
    }

    /**
     * 初始化列表checkbox选中内容
     * openType. (1: Fully diluted share, 2: Shares issued)
     */
    initSecurityList = () => {
        const { id, fundingRounds, query } = this.props;
        const { openType } = query;
        const index = fundingRounds.findIndex( v => v.id == id );
        const { securityList, historySecurityList, shareIssuedList, historyShareSecurityList } = fundingRounds[index];
        const defIds = openType == 1 ? I(securityList, []) : I(shareIssuedList, []);
        const historyIds = openType == 1 ? I(historySecurityList, []) : I(historyShareSecurityList, []);
        //如果用户保存过数据则使用 historySecurityList 展示，无则 securityList
        const selectedRowKeys = I(historyIds, []).length ? historyIds : defIds;
        this.setState({selectedRowKeys, defSelectedRowKeys: selectedRowKeys});
    }

    /**
     * 保存 Fully diluted 列表选中数据
     * @returns {Promise<void>}
     */
    saveHistorySecurity = async() => {
        const { selectedRowKeys } = this.state;
        if(!selectedRowKeys.length){
            message.error('Please select at least one!')
            return;
        }
        const { id, callback, query } = this.props;
        const { openType } = query;
        const eaIds = this.getEaIds();
        const attrData =  {share_class_id: id, security_ids: I(selectedRowKeys, []), type: openType, ea_ids: eaIds};
        this.setState({ loading: true });
        try {
            const { data: resData } = await post( 'saveHistorySecurity', attrData)
            const { code ,data } = resData;
            if (code === 0) {
                message.success(__('success!'));
                callback('1', id, { isShow: true, customizeId: id });
            } else {
                message.error(resData)
            }
        } catch(err) {
            message.error(err.message)
        }
        this.setState({ loading: false });
    }

    getEaIds = () => {
        const { id, fundingRounds } = this.props;
        const index = fundingRounds.findIndex( v => v.id == id );
        const defId = ['0'];
        if(index === -1){
            return defId;
        }
        const { ea_arr: eaArrList } = fundingRounds[index];
        let eaIds = [];
        eaArrList && eaArrList.forEach(v=>{
            v.isChecked && eaIds.push(v.id);
        })
        eaIds = eaIds.length > 0 ? eaIds : defId;
        return eaIds;
    }

    /**
     * 计算checkbox已选的quantity总和。
     * 因Fully Diluted 并不是 已选的quantity总和，所以需要得到 默认选中数组和最新选中数组的差集
     * @param oldArray 上一次的更新记录
     * @param newArray 最新的更新记录
     * @returns {*}
     */
    getArrayDiff = (oldArray, newArray) => {
        const { securityList } = this.state;
        const { fundingRounds, id, query } = this.props;
        const { openType } = query;
        let total = 0;
        securityList && securityList.forEach(v=>{
            newArray.indexOf(v.id) !== -1 && (total = calculator.add(I(v.quantity, 0), total));
        });
        const index = fundingRounds.findIndex( v => v.id == id );
        const { ea_arr: eaArrList } = fundingRounds[index];
        openType == 1 && eaArrList && eaArrList.forEach(v=>{
            if(v.isChecked){
                total = calculator.add(I(v.size, 0), total);
            }
        });
        return total;
    }

    /**
     * 重新组合列表，使id作为键值，目的是方便检索数据
     * @param securityList
     * @returns {{}}
     */
    columnSecurityList = (securityList) => {
        let newSecurityList = {};
        securityList && securityList.forEach((v)=>{
            newSecurityList[v.id] = v;
        });
        return newSecurityList;
    }

    /**
     * checkbox 事件
     * @param selectedRowKeys
     * @param data
     */
    onSelectChange = (selectedRowKeys, data) => {
        const { selectedRowKeys:oldSelectKey } = this.state;
        const newTotal = this.getArrayDiff(oldSelectKey, selectedRowKeys);
        this.setState({ selectedRowKeys, total: newTotal});
    };

    rowSelection = (selectedRowKeys) => ({
        selectedRowKeys,
        hideSelectAll: false,
        columnWidth: 100,
        onChange: this.onSelectChange,
    });

    eaCheckboxChange = (eaArrList, k, e) => {
        const { selectedRowKeys } = this.state;
        const { fundingRounds, id, saveState } = this.props;
        const index = fundingRounds.findIndex( v => v.id == id );
        const { ea_arr } = fundingRounds[index];
        const { checked } = e.target;
        let newEaArr = [...ea_arr];
        newEaArr[k].isChecked = checked;
        fundingRounds[index].ea_arr = newEaArr;
        saveState({fundingRounds}, () => {
            const newTotal = this.getArrayDiff(selectedRowKeys, selectedRowKeys);
            this.setState({total: newTotal});
        });
    }

    /**
     * total 内容展示
     * @returns {boolean|unknown[]}
     */
    getTotalHtml = () => {
        const { loading, total } = this.state;
        const { fundingRounds, id, query } = this.props;
        const { openType } = query;
        const index = fundingRounds.findIndex( v => v.id == id );
        if(!I(fundingRounds, '') || index === -1){
            return false;
        }
        const { ea_arr: eaArrList, closingDate } = fundingRounds[index];
        let newEaArrList = openType !== 2 ? [...eaArrList] : [];
        newEaArrList.push({name: openType == 1 ? `${__('Fully Diluted Total')}: ` : `${__('Shares Issued Total')}: `, size: total, isTotal: true, board_approval_date: closingDate});
        return newEaArrList && !loading && newEaArrList.map((v, k)=>{
            const { name, size, isTotal, board_approval_date, isChecked } = v;
            return (
                <div key={k} className={`fully-diluted-total ${ !isTotal ? 'fully-diluted-total-color' : '' }`}>
                    <div className="fully-diluted-total-ea">
                        {!isTotal && <Checkbox className="fully-diluted-total-checkbox" checked={isChecked} onChange={this.eaCheckboxChange.bind(this, eaArrList, k)}/>}
                        <span className={`fully-diluted-total-ea-tit ${isTotal && 'fully-diluted-total-ea-t-width'}`} >{name}{!isTotal && ` size:`}&nbsp;</span>
                        <span className="fully-diluted-total-ea-issued">{formatFractionalNumber(size)}</span>
                    </div>
                    <div className="fully-diluted-total-date">
                        <span>{isTotal ? __('Closing') : __('Approved')} {__('date')}: </span>
                        <span>{I(formatDate(board_approval_date), '--')}</span>
                    </div>
                </div>
            );
        });
    }

    /**
     * 计算 Fully Diluted Total、Shares issued Total
     * openType. (1: Fully diluted share, 2: Shares issued)
     */
    fullyDilutedTotal = () => {
        const { fundingRounds, id, query } = this.props;
        const { openType } = query;
        const index = fundingRounds.findIndex( v => v.id == id );
        let total = 0;
        const { fullyDilutedShares, historySecurityList, history_fullyDilutedShares,
            sharesIssued, historyShareSecurityList, history_sharesIssued
        } = fundingRounds[index];
        if(openType == 1){
            total = I(historySecurityList, '') ? history_fullyDilutedShares : fullyDilutedShares;
        }else{
            total = I(historyShareSecurityList, '') ? history_sharesIssued : sharesIssued;
        }
        this.setState({total, defTotal: total})
    }

    /**
     * 返回按钮
     * @constructor
     */
    CancelUrl = () => {
        const { callback } = this.props;
        callback('1');
    }

    updateState = (data) => this.setState(data);

    render() {
        const { loading, securityList, selectedRowKeys, filterList } = this.state;
        return (
            <div className="fully-diluted-share">
                <div className="diluted-share-button">
                    <Button onClick={this.CancelUrl}><i className="fa fa-angle-left board-icon-1"></i>{__('Cancel')}</Button>
                    <div className="diluted-share-button-right">
                        <ColumnFilter saveFilter={this.saveFilter} filterList={filterList} updateState={this.updateState} />
                        <Button type="primary" onClick={this.saveHistorySecurity.bind(this, 1)}>{__('Save')}</Button>
                    </div>
                </div>
                <Table
                    className="diluted-share-table"
                    columns={columnsFilter( fullyDilutedColumn(), filterList)}
                    dataSource={securityList}
                    pagination={false}
                    scroll={{x: true}}
                    loading={loading}
                    rowSelection={this.rowSelection(selectedRowKeys)}
                    rowKey="id"
                />
                {this.getTotalHtml()}
            </div>
        );
    }
}
export default FullyDilutedShare;