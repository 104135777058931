import React, { Component } from "react";
import {message, Spin, Table} from "antd";
import Echarts from 'components/Echarts';
import { post } from 'srcPath/http';
import { I, calculator } from "Utils";
import emptyImg from 'assets/images/bar.png';
import "./style.scss";

class StakeholderRegistered extends Component {

    state = {
        loading: true,
        data: [],
        option: {
            title: { text: '', padding: [0,0,0,34], textStyle: {fontSize: 16} },
            legend: { left:'right', show: true },
            tooltip: { trigger: 'axis', axisPointer: { type: 'shadow', triggerOn: 'none' } },
            color: ['#64c7ff', '#d1d1d1'],
            xAxis: { type: 'category', splitLine: { color: 'red' }, triggerEvent: true, axisLabel: { interval:0, rotate: 0, clickable:true } },
            yAxis: { type: 'value' },
            series: [],
            grid: { top: 50, right: '1%', bottom: '1%', left: '0%', containLabel: true },
            height: 200,
        },
        echartsClickData: '',
    };

    componentDidMount(){
        const { onRef } = this.props;
        onRef(this, 'registeredChild');
        this.init();
    }

    init = async () => {
        this.setState({loading: true});
        try {
            const { data: resData } = await post('getSummaryStakeholderInfo')
            const { code, data } = resData || {}
            if (code == 0) {
                const newData = this.delEmptyData(data);
                //组合echarts option数据
                const { name, notRegistered, total } = this.splitData(newData);
                const option = this.updateOption(name, notRegistered, total, newData);
                this.setState({ option, data: newData })
            } else {
                message.error(data)
            }
        } catch (err) {
            message.error(err && err.message)
        }
        this.setState({loading: false});
    }

    /**
     * 删除没有证书的数据
     * @param data
     * @returns {*[]}
     */
    delEmptyData = (data) => {
        let newData = [];
        data && data.forEach(v => {
            ( I(v.no_reg, '') || I(v.reg, '') ) && (newData.push(v));
        })
        return newData;
    }

    splitData = (data) => {
        let name = [], notRegistered = [], total = [];
        data && data.forEach((v)=>{
            const { name: planName, no_reg, reg, stakeholder_id } = v;
            name.push(planName);
            notRegistered.push({ name: planName, value:I(no_reg, '0'), stakeholder_id });
            total.push( {name: planName, value: calculator.add(I(reg, '0'), I(no_reg, '0')), stakeholder_id} );
        });
        return { name, notRegistered, total };
    }

    /**
     * 组合echarts option数据
     */
    updateOption = (name, notRegistered, total, plan) => {

        const { option } = this.state;
        option.xAxis.data = name;
        option.series.push({ name: __('Total'), type: 'bar', data: total, label: { show: true, position: 'top'}});
        option.series.push({ name: __('Not registered'), type: 'bar', data: notRegistered, label: { show: true, position: 'top'} });
        return option;
    }

    /**
     * 实例化图表各个事件
     * @returns {[{callback: StakeholderRegistered.clickCallback, type: string}, {callback: StakeholderRegistered.globaloutCallback, type: string}]}
     */
    getEvent = () => {
        return [
            { type: 'click', callback: this.clickCallback, isZr: true },
            { type: 'globalout', callback: this.globaloutCallback }
        ];
    }

    /**
     * 图表点击事件
     * @param data
     * @param instance
     */
    clickCallback = (data, instance) => {
        const { option } = this.state;
        const { updateState } = this.props;
        const { offsetX, offsetY } = data;
        //获取选择的plan下标并得到 stakeholderId 结果集
        const pointInPixel = [ offsetX, offsetY ];
        const index = I( instance.containPixel('grid', pointInPixel) && instance.convertFromPixel({ seriesIndex: 0 }, pointInPixel)[0], 0);
        const planInfo = option.series[0].data[index];
        const { echartsClickData } = this.state;
        //验证是否为释放点击，如果释放则恢复stakeholder数据
        const isSecond = echartsClickData.dataIndex === index;
        const dataInfo = isSecond ? '' : {dataIndex: index, data: planInfo};
        const { stakeholder_id } = dataInfo && dataInfo.data;
        //查询
        this.searchList(stakeholder_id, isSecond);
        this.setState({echartsClickData: dataInfo });
        updateState({searchValue:''});
    }

    resetDefault = () => {
        const { echartsClickData } = this.state;
        if(I(echartsClickData, '')){
            const { data: planInfo } = echartsClickData;
            const { stakeholder_id } = planInfo;
            this.searchList(stakeholder_id, false);
        }
    }

    /**
     * 通过统计图选择的plan来查询指定stakeholder
     * @param stakeholderId
     */
    searchList = (stakeholderId, isSecond) => {
        const { searchHolderLists, updateState } = this.props;
        let searchList = [...searchHolderLists];
        if(isSecond){
            updateState({ holderLists: searchList });
            return;
        }
        let searchRes = [];
        stakeholderId && stakeholderId.forEach(v=>{
            const index = searchList.findIndex(v1=>v1.id==v);
            index !== -1 && searchRes.push(searchList[index]);
        });
        updateState({ holderLists: searchRes });
    }

    /**
     * 离开图表事件，返回最后一次选中 tooltip
     * @param data
     * @param instance
     */
    globaloutCallback = (data, instance) => {
        const { echartsClickData } = this.state;
        const { dataIndex } = echartsClickData;
        instance.dispatchAction({ type: 'showTip', seriesIndex: 0, dataIndex: dataIndex });
    }

    render() {
        const { loading, data, option } = this.state;
        const { showEcharts } = this.props;
        return (
            <div className={`stakeholder-registered-root ${!showEcharts ? 'stakeholder-registered-root-hide' : ''}`} >
                <Spin spinning={loading}>
                    { data && data.length > 0 && showEcharts && <Echarts event={this.getEvent()} option={option} classNames="stakeholder-registered-echarts" /> }
                </Spin>
            </div>
        );
    }
}
export default StakeholderRegistered;