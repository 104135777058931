import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import NavTab from 'components/NavTab'
import Settings from "./Settings";
import Profile from "./Profile";
import Billing from "./Billing";
import Plan from "./Plan";
import security from 'stores/security';
import { I } from 'Utils';
import './style.scss';

export default class Setting extends Component {
	path = this.props.match.path

	config = [
		{
			label: __('Settings'),
			path: `${this.path}`,
			permission: 'company_setting_settings',
			component: Settings,
            exact: true,
		},
		{
			label: __('Profile'),
			path: `${this.path}/profile`,
			permission: 'company_setting_profile',
			component: Profile,
            exact: true,
		},
		{
			label: __('Billing'),
			path: `${this.path}/billing`,
			permission: 'company_setting_billing',
			component: Billing,
            exact: false,
            // company plan control。(1:隐藏，2：展示)，如果是关闭状态，则需要隐藏billing模块
            hide: security.planControl == 1
		},
        {
            label: __('Plan'),
            path: `${this.path}/plan`,
            permission: 'company_setting_plan',
            component: Plan,
            exact: false,
            // company plan control。(1:隐藏，2：展示)，如果是关闭状态，则需要隐藏billing模块
            hide: (security.planControl == 1 || !security.subscriptionId)
        },
	].filter((val, key) => security.hasPermission( val.permission) && !val.hide );

	render() {
		return <div className="sprout-setting-root">
			{/* <div className="setting-sidebar">
				<NavTab config={this.config} />
			</div> */}
			<div className="sprout-setting-content">
				<Switch>
					{
						this.config.map((val, index) => security.hasPermission(val.permission) && <Route key={index} exact={val.exact} path={val.path} component={val.component} />)
					}
					<Redirect to={this.config[0] && this.config[0].path} />
				</Switch>
			</div>
		</div>
	}
}