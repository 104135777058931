import { formatNumber, calculator, clearNoNum, I, formatCurrencyNumber } from "Utils";
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import React from "react";
import {Input} from "antd";

const width = 'calc( (100vw - 328px) / 2 / 4 )';
/**
 * share class, stakeholder table column配置
 */
export function columnsA (financingDataCount, active) {

    const letTable = calculator.agile(`100/(${financingDataCount} + 1)`)

    return {
        title: <span className="new-financing-table-right-title">{__('Current Cap table')}</span>,
        children: [
            {
                title: active == 1 ? __('Stakeholder') : __('Share class'),
                dataIndex: 'name',
                fixed: 'left',
                className: 'new-financing-table-column new-financing-column-first',
                width,
                render: text => {
                    return <div className="new-financing-table-round-name">{text}</div>
                }
            },
            {
                title: __('Current shares'),
                align: 'right',
                dataIndex: 'quantity',
                fixed: 'left',
                className: 'new-financing-table-column',
                width,
                render: (text) => text > 0 ? formatFractionalNumber(text) : '--',
            },
            {
                title: __('Current ownership'),
                dataIndex: 'ownership',
                align: 'right',
                fixed: 'left',
                className: 'new-financing-table-column',
                width,
                render: (text) => getPercentage(text),
            },
            {
                title: __('Current valuation'),
                dataIndex: 'valuation',
                align: 'right',
                fixed: 'left',
                className: 'new-financing-table-column',
                width,
                render: (text) => I(text, '') ? formatCurrencyNumber(text,0) : '--',
            },
        ],
    };
}

export function columnsB (financingDataCount) {
    // const letTable = calculator.agile(`100/(${financingDataCount} + 1)`);
    const letTable = 2000;
    return {
        title: '',
        className: 'new-financing-column-group-right',
        children: [
            {
                title: '',
                dataIndex: 'ownership1',
                align: 'right',
                colSpan: 0,
                className: 'new-financing-table-border new-financing-color-right',
                width,
                render: (text) => {

                    const isFinite = Number.isFinite(Number(text));
                    return (
                        <span className={`${isFinite && text > 0 && 'new-financing-round-green'} ${!I(text, '') && 'new-financing-round-def'} ${isFinite && text < 0 && 'new-financing-round-red'}`}>
                            {getPercentage(text, true)}
                        </span>
                    )
                },
            },
            {
                title: __('Post-money shares'),
                dataIndex: 'shares',
                colSpan: 2,
                className: 'new-financing-color-right',
                align: 'right',
                width,
                render: (text) => text > 0 ? formatFractionalNumber(text) : '--',
            },
            {
                title: __('Post-money ownership'),
                dataIndex: 'ownership2',
                className: 'new-financing-color-right',
                align: 'right',
                width,
                render: (text) => getPercentage(text),
            },
            {
                title: __('Post-money valuation'),
                dataIndex: 'valuation',
                className: 'new-financing-color-right',
                align: 'right',
                width,
                render: (text) => text > 0 ? formatCurrencyNumber(text, 0) : '--',
            },
        ],
    };
}

const getPercentage = (text, symbol = false, decimal = 2) => {
    const isFinite = Number.isFinite(Number(text));
    return isFinite && ( text > 0 ? ( `${symbol ? '+' : ''}${formatNumber(text)}%` ) : (I(text, '') ? `${formatNumber(text)}%` : '--') ) || '--';
};