import React, { Component } from 'react';
import { render, createPortal, unmountComponentAtNode } from 'react-dom'
import { Spin } from 'antd';
import './style.scss';

export default class Loading extends Component {
	static loadingInstance = undefined

	render () {
		const { children, tip } = this.props
		return <div className="sprout-loading">
			<Spin tip={tip || ''}>
				{children}
			</Spin>
		</div>
	}
}

function global(show, children) {
	const body = document.querySelector('body');
	let div = Loading.loadingInstance

	if (show) {
		if (!div) {
			div = document.createElement('div')

			Loading.loadingInstance = div
			body.appendChild(div)
		}

		render(<div className="sprout-global-loading">
			<div className="loading-content">
				{
					children || <Spin />
				}
			</div>
		</div>, div)
	} else {
		if (div) {
			Loading.loadingInstance = undefined
			unmountComponentAtNode(div)

			body.removeChild(div)			
		}

		return
	}
}

Loading.global = global