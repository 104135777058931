import React, { Component, Fragment } from "react";
import {Table, Checkbox} from 'antd';
import Utils, { I, formatCurrencyNumber, tableSort } from 'Utils';
import {formatDate} from "Utils/format/formatDate";
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import { DateCustomer } from 'components/DateSelector'
import './style.scss'
import TerminateTokenGrantTable from "../TerminateTokenGrantTable";
class TerminateStakeholderTable extends Component {
    state = {
        selectedRowKeys: [], // Check here to configure the default column
        loading: false,
    };

    componentDidMount(){
        this.props.onRef(this);
    }

    /**
     * 表格checkbox自定义配置
     * @param sign_status 状态值
     * @returns {JSX.Element}
     */
    securityHover = (checked, record, index) => {
        return (
            <Fragment>
                <Checkbox checked={checked} onChange={this.checkChange.bind(this, record, index, checked)} ></Checkbox>
            </Fragment>
        );
    }

    /**
     * 清空checkbox所有选中内容
     */
    clearAll = () => {
        this.setState(()=>({
            selectedRowKeys : []
        }))
    }

    /**
     * 表格checkbox change事件
     * @param record
     * @param index
     * @param boxChecked
     * @param e
     */
    checkChange = (record, index, boxChecked, e) => {
        const { checked } = e.target;
        const { selectedRowKeys } = this.state;
        const { updateSelectCount } = this.props;
        const { id } = record;
        let key_list = [...selectedRowKeys];
        if(checked){
            key_list.push(id);
        }else{
            key_list.forEach( (v, k) => {
                if(v == id){
                    key_list.splice(k, 1);
                }
            })
        }
        updateSelectCount(key_list.length, [{selectIndex: index,checked: checked, status:record.status}]);
        this.setState(()=>({
            selectedRowKeys : key_list
        }))
    }

    /**
     * 表格列参数配置
     * @returns {[{sorter: (function(*, *): number), dataIndex: string, onFilter: (function(*=, *): boolean), filters: [{text: string, value: string}, {text: string, value: string}, {text: string, value: string}, {text: string, value: string}, {text: string, value: string}, null], title: string, align: string, key: string}, {filterDropdown: function({setSelectedKeys: *, selectedKeys?: *, confirm?: *, clearFilters?: *}): *, sorter: (function(*, *): number), dataIndex: string, filterIcon: function(*): *, onFilter: function(*, *): *|string, onFilterDropdownVisibleChange: function(*=): void, title: string}, {sorter: (function(*, *): number), dataIndex: string, title: string, render: (function(*, *))}, {sorter: (function(*, *)), dataIndex: string, title: string, align: string, render: (function(*=))}, {sorter: (function(*=, *=)), dataIndex: string, title: string, align: string, render: (function(*, *=))}, null, null, null]}
     */
    tableColumns = () => {
        return [
            {
                title : __('Prefix'),
                align : 'left',
                dataIndex : 'prefix',
                key : 'keyId',
                fixed: 'left',
                width: 100,
                isChecked: true,
                isDisabled: true,
                render: (text) => <span className="text-blue">{text}</span>
            },
            {
                title : __('Acceptance status'),
                align : 'left',
                dataIndex : 'sign_status',
                width: 180,
                isChecked: true,
                isDisabled: true,
                render: (text) => <span>{ text == 0 ? "Accepted" : (text == 1 ? "Awaiting officer signature" : "Awaiting holder signature")}</span>
            },
            {
                title : __('Issue date'),
                dataIndex : 'issue_date',
                align : 'center',
                width: 100,
                render: (text, value) => {
                    return <span>{ formatDate(value.issue_date) }</span>
                },
                isChecked: true,
            },
            {
                title : __('Granted'),
                dataIndex : 'granted',
                align : 'right',
                width:120,
                render: (text) => <span>{ formatFractionalNumber(text) }</span>,
            },
            {
                title : __('Vested'),
                dataIndex : 'vested',
                align : 'right',
                width: 120,
                render: (text, value) => {
                    return <span>{formatFractionalNumber(text)}</span>
                },
                isChecked: true,
            },
            {
                title : __('Unvested'),
                dataIndex : 'unvested',
                align : 'right',
                width: 120,
                render: (text) => <span>{ formatFractionalNumber(text) }</span>,
                isChecked: true,
            },
            {
                title : __('Exercise'),
                dataIndex : 'exercised_quantity',
                align : 'right',
                width:120,
                render: (text) => <span>{ formatFractionalNumber(text) }</span>,
            },
            {
                title : __('ISO disqualification date'),
                dataIndex : 'iso_disqualification_date',
                align : 'center',
                width: 120,
                render: (text, value) => {
                    return <span>{ formatDate(value.iso_disqualification_date) }</span>
                },
                isChecked: true,
            },
            {
                title : __('Last day to exercise'),
                dataIndex : 'last_day_to_exercise',
                width: 160,
                fixed: 'right',
                render:(text, value, index) => this.handleLastDayToExercise(text, value, index),
                isHide: true,
            }
        ]
    }

    lastDayToExerciseChange = (name,index,date) => {
        const { lastDayToExerciseChange } = this.props;
        lastDayToExerciseChange(name,index,date);
    }

    /**
     * 表格最后一列菜单配置
     * @param data
     * @param index
     * @returns {JSX.Element}
     */
    handleLastDayToExercise = (text, data, index) => {
        const inputStatus = (data.hasOwnProperty('checkbox_status') && data.checkbox_status) ? false : true;
        const expirationDateLongDesc = {'-1': __('No expiration date')};
        return (
            <DateCustomer
                customerValue={-1}
                disabled={ inputStatus }
                onChange={this.lastDayToExerciseChange.bind(this, 'confirm_date_list', index)}
                footerText={expirationDateLongDesc['-1']}
                lastDayToExerciseChange={this.lastDayToExerciseChange}
            />
        );
    }

    /**
     * 全选change事件
     * @param selectedRowKeys
     */
    onSelectChange = (selectedRowKeys) => {
        const { securityLists, updateSelectCount } = this.props;
        const count = selectedRowKeys.length;
        if(!count){
            updateSelectCount(count, [], true);
        }else{
            let selectIndexArray = [];
            securityLists.forEach((v, k) => {
                selectIndexArray.push({selectIndex:k, checked: true});
            })
            updateSelectCount(count, selectIndexArray);
        }
        this.setState({ selectedRowKeys });
    };

    rowSelection = (selectedRowKeys) => ({
        selectedRowKeys,
        onChange: this.onSelectChange,
        renderCell : (checked, record, index, originNode) => this.securityHover(checked, record, index, selectedRowKeys)
    })

    render() {
        const { loading, selectedRowKeys, } = this.state;
        const { securityLists } = this.props;

        return (
            <Table
                title={()=>__('Equity')}
                className="stake-table terminate-token-table bg-gray"
                loading={loading}
                pagination={false}
                columns={this.tableColumns()}
                dataSource={securityLists}
                rowSelection={this.rowSelection(selectedRowKeys)}
                rowKey="id"
                rowClassName="stake-table-row"
                scroll={{ x: true }}
                summary={() => {
                    let totalGranted = 0;
                    let totalVested = 0;
                    let totalUnVested = 0;
                    let totalExercised = 0;
                    securityLists.forEach(({ granted, vested, unvested, exercised_quantity }) => {
                        totalGranted += parseFloat(granted);
                        totalVested += parseFloat(vested);
                        totalUnVested += parseFloat(unvested);
                        totalExercised += parseFloat(exercised_quantity);
                    });
                    return <Table.Summary.Row fixed>
                        <Table.Summary.Cell align="right" index={0}>{__('Total')}</Table.Summary.Cell>
                        <Table.Summary.Cell index={1}></Table.Summary.Cell>
                        <Table.Summary.Cell index={2}></Table.Summary.Cell>
                        <Table.Summary.Cell index={3}></Table.Summary.Cell>
                        <Table.Summary.Cell align="right" index={4}>{formatFractionalNumber(totalGranted)}</Table.Summary.Cell>
                        <Table.Summary.Cell align="right" index={5}>{formatFractionalNumber(totalVested)}</Table.Summary.Cell>
                        <Table.Summary.Cell align="right" index={6}>{formatFractionalNumber(totalUnVested)}</Table.Summary.Cell>
                        <Table.Summary.Cell align="right" index={7}>{formatFractionalNumber(totalExercised)}</Table.Summary.Cell>
                        <Table.Summary.Cell index={8}></Table.Summary.Cell>
                        <Table.Summary.Cell index={9}></Table.Summary.Cell>
                    </Table.Summary.Row>}}
            />
        );
    }
}

export default TerminateStakeholderTable;