import React from 'react';
import { Link } from "react-router-dom";

class EditConvertibleTerms extends React.Component {
    constructor(props){
        super(props);
        this.state={

            data:
                {
                    convertible_type:"SAFE",
                    terms_name:"SAFE 2",
                    prefix:"CN",
                    board_approval_date:"",
                    valuation_cap:"",
                    conversion_discount:"",
                    change_in_control_percent:"",
                    conversion_trigger:"",
                    interest_rate:"10",
                    interest_accrual_end_date:"",
                    day_count_convention:"",
                    interest_accrual_period:"interest_accrual_period",
                    interest_start_date:"",
                    interest_compounding_period:"interest_compounding_period",
                    interest_payout:"interest_payout",

                    day_count_basis:"day_count_basis",
                    note_purchase_agreement_file:"No file uploaded",
                    form_of_convertible_file:"No file uploaded",
                    uploadProgress:0,

                    },
                checkedArr:{
                    convertible_debt:"",
                    convertible_security:"",
                    safe:"checked",
                    },

        }
    }
    resetAction=()=>{
        window.$("#EditConvertibleTermsModal .tab-pane").removeClass("active");
        window.$("#terms_box").addClass("active");
    }
    changeDetailsData=(key)=>{
        window.$(".alert_tip").removeClass("hidden")
        let curData = this.state.data;
        curData.terms_name = key;
        this.setState({
            data:curData
        })
    }
    shutDown=()=>{
        window.$(".alert_tip").addClass("hidden")
    }
    render(){
        return(
            <div className="modal fade" id="EditConvertibleTermsModal" tabIndex="-1" role="dialog" aria-labelledby="EditConvertibleTermsModal" aria-hidden="true" >
                <div className="modal-dialog dialog-top40">
                    <div className="tab-content">
                        <div className="modal-content content500 tab-pane active" id="terms_box">
                            <div className="modal-header box-header">
                                <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                                <h4 className="modal-title" id="myModalLabel">{__('Terms')}</h4>
                            </div>
                            <div className="modal-body padding203020 ">
                                <div className="body-one">
                                    <div className="clearfix">
                                        <p className="onset padding610">{__('SAFE')} 2</p>
                                        <button type="button" className="btn editShareClassBtn bg-darkblue offset" onClick={this.changeDetailsData.bind(this,"SAFE 2")}  data-toggle="tab" data-target="#terms_details" >{__('View Terms')}</button>
                                    </div>
                                    <hr />
                                    <div className="clearfix">
                                        <p className="onset padding610">{__('SAFE')} 1</p>
                                        <button type="button" data-toggle="tab" data-target="#terms_details"  onClick={this.changeDetailsData.bind(this,"SAFE 1")} className="btn editShareClassBtn bg-darkblue offset">{__('View terms')}</button>
                                    </div>
                                    <hr />
                                    <div className="clearfix">
                                        <p className="onset padding610">{__('SAFE Terms')}</p>
                                        <button type="button" data-toggle="tab" data-target="#terms_details"  onClick={this.changeDetailsData.bind(this,"SAFE Terms")} className="btn editShareClassBtn bg-darkblue offset">{__('View terms')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-content conver-content tab-pane" id="terms_details">
                            <div className="modal-header box-header">
                                <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.resetAction}>×</button>
                                <h4 className="modal-title" id="myModalLabel">{this.state.data.terms_name}</h4>
                            </div>
                            <div className="modal-body equity-body">
                                <div className="alert_tip bg-orange width100perc clearfix">
                                    <button type="button" className="close shut-btn terms_shut_btn offset" onClick={this.shutDown}>×</button>
                                    <div className="flexDiv onset">
                                        <span className="terms-tip glyphicon glyphicon-exclamation-sign white-text"></span>
                                        <div>
                                            <p className="white-text">{__('These terms are in use.')}</p>
                                            <p className="white-text">{__('Information about these terms can be viewed but not modified.')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="equity-item-header bg-seablue">
                                        <h5>Basic terms information</h5>
                                    </div>
                                    <form className="form-horizontal equity-item-form">
                                        <div className="form-group">
                                            <label className="col-xs-5 add-label">{__('Convertible type')}</label>
                                            <div className="col-xs-4 no-paddingright">
                                                <div className="checkbox send-check text-muted">
                                                    <input checked={this.state.checkedArr.safe} disabled value="safe" type="radio" name="convertibleNote" className="send-box" id="send-box003"/>
                                                    <label className="send-div" htmlFor="send-box003"></label><span className="send-label"> &nbsp;&nbsp;{__('SAFE')}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="col-xs-4 col-xs-push-5 no-paddingright">
                                                <div className="checkbox send-check text-muted">
                                                    <input checked={this.state.checkedArr.convertible_debt} disabled type="radio" value="convertible_debt" name="convertibleNote" className="send-box" id="send-box001"/>
                                                    <label className="send-div" htmlFor="send-box001"></label><span className="send-label"> &nbsp;&nbsp;{__('Convertible debt')}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="col-xs-4 col-xs-push-5 no-paddingright">
                                                <div className="checkbox send-check text-muted">
                                                    <input checked={this.state.checkedArr.convertible_security} disabled value="convertible_security" type="radio" name="convertibleNote" className="send-box" id="send-box002" />
                                                    <label className="send-div" htmlFor="send-box002"></label><span className="send-label"> &nbsp;&nbsp;{__('Convertible security')}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group ">
                                            <label className="col-xs-5 add-label">{__('Terms name')}</label>
                                            <div className="col-xs-7">
                                                <input value={this.state.data.terms_name} disabled name="terms_name" className="form-control" type="text" placeholder="e.g. SAFE $5M valuation cap" />
                                            </div>
                                        </div>
                                        <div className="form-group ">
                                            <label className="col-xs-5 add-label convertible-tipbox">{__('Prefix')}
                                                <a className="convertible-ques">
                                                    <p className="convertible-tiptext tiptext-top86">{__('Convertible block prefixes can be reused for organizational purposes.')}</p>
                                                </a>
                                            </label>
                                            <div className="col-xs-4">
                                                <input value={this.state.data.prefix} disabled name="prefix" className="form-control uppercase_form" type="text" placeholder="CN" />
                                            </div>
                                        </div>
                                        <div className="form-group ">
                                            <div className="col-xs-5">
                                                <label className=" add-label ">{__('Board approval date')}<br /><i className="text-muted">- {__('Optional')}</i></label>
                                            </div>
                                            <div className="col-xs-4">
                                                <input disabled value={this.state.data.board_approval_date} name="board_approval_date" className="form-control date-calendar datepicker calender-position114" type="text" />

                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div>
                                    <div className="equity-item-header bg-seablue">
                                        <h5>{__('Enter terms')}</h5>
                                    </div>
                                    <form className="form-horizontal equity-item-form">
                                        <div className="form-group ">
                                            <div className="col-xs-5">
                                                <label className=" add-label">{__('Valuation cap')}<i className="text-muted">- {__('Optional')}</i></label>
                                            </div>
                                            <div className="col-xs-4">
                                                <input disabled value={this.state.data.valuation_cap} name="valuation_cap" className="form-control" type="text" />
                                            </div>
                                        </div>
                                        <div className="form-group ">
                                            <div className="col-xs-5">
                                                <label className=" add-label">{__('Conversion discount')}<br /><i className="text-muted">- {__('Optional')}</i></label>
                                            </div>
                                            <div className="col-xs-4">
                                                <div className="input-group">
                                                    <input name="conversion_discount" disabled value={this.state.data.conversion_discount} className="form-control" type="text" />
                                                    <span className="input-group-addon" id="convertible-addon">%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div>
                                    <div className="equity-item-header bg-seablue">
                                        <h5>{__('Upload term documents')}<i className="optional-color optional-font">- {__('Optional')}</i></h5>
                                    </div>
                                    <form className="form-horizontal equity-item-form">
                                        <p className="text-muted file-seletext">{__('Upload default term documents. You can replace them when you issue convertibles.')}</p>
                                        <div className="form-group ">
                                            <label className="col-xs-5 add-label">{__('Note purchase agreement')}</label>
                                            <div className="col-xs-4 filename-box">
                                                <span className="close files-del hidden note_purchase_agreement_file">x</span>
                                                <p className="text-muted">{this.state.data.note_purchase_agreement_file}</p>
                                            </div>

                                        </div>
                                        <div className="form-group ">
                                            <label className="col-xs-5 add-label">{__('Form of convertible')}</label>
                                            <div className="col-xs-4 filename-box">
                                                <span className="close files-del hidden form_of_convertible_file">x</span>
                                                <p className="text-muted ">{this.state.data.form_of_convertible_file}</p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer box-footer">
                                {/*{curTextStaus ===1 &&<Link onClick={this.createNote} to="/home/onboarding/notexcel" type="button" className="btn boxbtn-to bg-darkblue update-btn">Create Terms</Link>}*/}
                                {/*{curTextStaus ===0 &&<button type="button" onClick={this.checkStatus} className="btn boxbtn-to bg-darkblue update-btn">Create Terms</button>}*/}
                                <button type="button" onClick={this.resetAction} data-dismiss="modal" className="btn boxbtn-to bg-darkblue update-btn">{__('Create terms')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default EditConvertibleTerms;