import React, { useState, useEffect } from 'react';
import { FormItem } from 'react-antd-formutil';
import { Select } from 'antd'
import { post } from 'srcPath/http';
import { omit } from 'lodash';

const Option = Select.Option

export default function PlanSelect(props) {
    const { name, label, type } = props
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getData = async () => {
            try {
                const { data: resData } = await post('getTokenOptionPlanList');
                setOptions(resData?.data.plan_list || [])
            } catch (e) {

            }

            setLoading(false)
        }

        getData()
    }, [])

    return <FormItem
        name={name || 'plan'}
        label={label || __('Plan name')}
    >
        <Select {...omit(props, ['name', 'label'])} loading={loading} placeholder={__('Select')} mode="multiple" showSearch>
            {
                options.map((val, index) => (<Option key={index} value={val.id}>{`${val.name}-${val.prefix}`}</Option>))
            }
        </Select>
    </FormItem>

}