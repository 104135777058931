import React from 'react';
import { formatNumber, formatPercent } from 'Utils';
import { Tooltip } from 'antd';

import icon from './images/icon.png'

import './style.scss';

// interface TokenFilteredDataProps {
// 	data: {

// 	};
// }

export default function TokenFilteredData(props) {
	const { data } = props;
	const { balance, percentage, value, walletAddress } = data || {}

	return <div className="token-filtered-data">
		<div className="token-filtered-data-item">
			<div className="token-filtered-data-label">
				<img className="token-filtered-data-icon" src={icon} alt="icon" />
				<span className="bold-title">FILTERED BY WALLET</span>
			</div>
			<Tooltip title={walletAddress}>
				<div className="token-filtered-data-value">
					{walletAddress}
				</div>
			</Tooltip>
		</div>
		<div className="token-filtered-data-item">
			<div className="token-filtered-data-label">
				BALANCE
			</div>
			<div className="token-filtered-data-value">
				{formatNumber(balance)}
			</div>		
		</div>
		<div className="token-filtered-data-item">
			<div className="token-filtered-data-label">
				VALUE
			</div>
			<div className="token-filtered-data-value">
				{ `$ ${formatNumber(value)} [${formatPercent(percentage)}]` } 
			</div>		
		</div>
	</div>
}