import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Header from 'components/Layout/Header';
import NavMenu from 'components/Layout/NavMenu';
import Loading from 'components/Loading'
import { observer } from 'mobx-react';
import { message } from 'antd';
import { Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import AcceptCertificate from './AcceptCertificate';
import Board from './Board';
import SignCertificate from './SignCertificate';
import withLogin from 'components/withLogin';
import security from 'stores/security';
import PersonalCenter from 'components/PersonalCenter';
import Help from 'modules/Help';
import EmptyTask from './Empty';

import './style.scss';

@withLogin
@observer
class Task extends Component {
	path = this.props.match.path

	getMenuConfig = () => { 
		const { taskDetail = {} } = security.task || {}

		const menuConfig = [
			{
				title: __('Accept certificates'),
				path: '/task/certificate',
				disabled: false,
				access: true,
				children: null,
				task: Number(taskDetail.acceptCertificates)
			},
			{
				title: __('Board consents'),
				path: '/task/board',
				disabled: false,
				access: true,
				task: Number(taskDetail.boardConsents)

			},
			{
				title: __('Sign certificate'),
				path: '/task/sign',
				disabled: false,
				access: true,
				task: Number(taskDetail.signCertificates)
			}		
		]

		return menuConfig.filter(val => val.task > 0)
	}

	skipTask = async () => {
		Loading.global(true)

		try {
			security.setPlatform = security.prePlatform || (security.corporations.length > 0 ? 'B' : 'C')
		} catch (e) { 
			e.message && message.error(e.message)
		}
		
		Loading.global(false)
	}

	render() {
		const config = this.getMenuConfig()

		return <div className="sprout-client-root">
			<Header />
				<NavMenu menuConfig={config}>
					<Button className="sprout-task-skip" type="link" onClick={this.skipTask}>{__('Skip tasks')}<RightOutlined /></Button>
			</NavMenu>

				<div className="sprout-client-content-wrap">
					<div className="sprout-client-content">
						<Switch>
							<Route path={`${this.path}/certificate`} component={AcceptCertificate} />
							<Route path={`${this.path}/board`} component={Board} />
							<Route path={`${this.path}/sign`} component={SignCertificate} />
							<Route path={`${this.path}/personal-center`} component={PersonalCenter} />
							<Route path={`${this.path}/help`} component={Help} />
							<Route path={`${this.path}/empty`} component={() => <EmptyTask pathData={config} />} />
							<Redirect to={(config[0] && config[0].path) || `${this.path}/empty`} />
						</Switch>
					</div>
				</div>
		</div>
	}
}

export default Task