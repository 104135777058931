import React, { useState } from 'react';
import Captable from './components/Captable';
import Securities from './components/Securities'
import Options from './components/Options'
import Warrant from './components/Warrant'
import VotingPower from './components/VotingPower'
import PreviewTable from './components/PreviewList'
import Token from './components/Token'
import Grant from './components/Grant'
import Transaction from './components/Transaction';
import TokenWarrant from './components/TokenWarrant';
import Tax from './components/Tax';
import Sbc from './components/Sbc';

import './style.scss';

export function CommonReports(props) {
	const [previewList, setPreviewList] = useState('');
	const [params, setParams] = useState('');
	const [reportName, setReportName] = useState('');
	const [activeList, setActiveList] = useState('');
	const [isShowTable, setisShowTable] = useState(false);

	const getPreviewList = (previewList, params, reportName) => {
		let activeList = Object.keys(previewList)
		setPreviewList(previewList)
		setParams(params)
		setReportName(reportName)
		setActiveList(activeList)
		setisShowTable(true)
	}

	return <div className="sprout-reports-root">
		<div className="sprout-reports-wrap">
			<div className="sprout-reports-banner">
				{__('Capitalization')}
			</div>
		</div>
		<Captable getPreviewList={getPreviewList} />
		<Securities getPreviewList={getPreviewList} />
		<Options getPreviewList={getPreviewList} />
		<Warrant getPreviewList={getPreviewList} />
		<VotingPower getPreviewList={getPreviewList} />
		<PreviewTable previewList={previewList} params={params} reportName={reportName} activeList={activeList} isShowTable={isShowTable} />
	</div>
}


export function TokenReprots(props) {
	const { id } = props
	return <div className="sprout-reports-root">
		<div className="sprout-reports-wrap">
			<div className="sprout-reports-banner">
				{__('Crypto')}
			</div>
		</div>
		<Token id={id} />
		<Grant id={id} />
		<Transaction id={id} />
		<TokenWarrant id={id} />
	</div>
}


export function TaxReport(props) {
	return <div className="sprout-reports-root">
		<div className="sprout-reports-wrap">
			<div className="sprout-reports-banner">
				{__('Tax report')}
			</div>
		</div>
		<Tax />

	</div>
}

export function SbcReport(props) {
	return <div className="sprout-reports-root">
		<div className="sprout-reports-wrap">
			<div className="sprout-reports-banner">
				{__('Stock-based compensation report')}
			</div>
		</div>
		<Sbc props={props} />

	</div>
}