import React from 'react';
/*
* 菜单路由配置，目前支持到二级菜单，后期根据有需求再扩展
* title: 路由显示名称
* path: 路由跳转路径
* disabled: 禁用
* children: 二级子路由
*/
const menuConfig = [
	{
		title: __('Dashboard'),
		path: '/client/dashboard',
		disabled: false,
		access: true,
		children: null
	},
	{
		title: __('Portfolio'),
		path: '/client/portfolio',
		disabled: false,
		access: true,
		children: null
	},
	{
		title: __('Documents'),
		path: '/client/documents',
		disabled: false,
		access: true,
		children: null
	},
	// {
	// 	title: 'Portfolio V2',
	// 	path: '/client/portfolio-new',
	// 	disabled: false,
	// 	access: true,
	// 	children: null
	// }
	
	// {
	// 	title: 'Account',
	// 	path: null,
	// 	disabled: false,
	// 	children: [
	// 		// {
	// 		// 	title: 'Summary',
	// 		// 	path: '/client/account/summary',
	// 		// 	disabled: false,
	// 		// },
	// 		// {
	// 		// 	title: 'People',
	// 		// 	path: '/client/account/people',
	// 		// 	disabled: false,
	// 		// },
	// 		// {
	// 		// 	title: 'Setting',
	// 		// 	path: '/client/account/setting',
	// 		// 	disabled: false,
	// 		// },
	// 		// {
	// 		// 	title: 'Payment Infomation',
	// 		// 	path: '/client/account/pays',
	// 		// 	disabled: false,
	// 		// },
	// 	]
	// },	
]

export default menuConfig