import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Fairmarketvalue from './FairMarketValue';
import TokenValuation from './TokenValuation';
import { SbcReport } from 'B/components/Reports';
import security from 'stores/security';
class Compliance extends Component {
    path = this.props.match.path

    config = security.hasFunction([
        {
            path: `${this.path}/fairmarketvalue`,
            component: Fairmarketvalue,
            permission: 'compliance_fairmarketvalue',
            exact: true,
        },
        {
            path: `${this.path}/tokenvaluation`,
            component: TokenValuation ,
            permission: 'compliance_tokenvaluation',
            exact: true,
        },
        {
            path: `${this.path}/sbc_reports`,
            component: SbcReport,
            permission: 'all',
            exact: false
        }
    ]);

    render() {
        return <div className="sprout-captable-root">
            <Switch>
                {
                    this.config.map((val, index) => security.hasPermission(val.permission) && <Route key={index} exact={val.exact} path={val.path} component={val.component} />)
                }
            </Switch>
        </div>
    }
}
export default Compliance;