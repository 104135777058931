import React from 'react';
import axios from "axios";
import DeleteDoc from "../../components/DeleteDoc";
import { post } from 'srcPath/http';
import Utils, { formatNumber, I} from "Utils";
import { cancellationReason } from "Utils/constant";
import moment from "moment";
import {DatePicker, message} from "antd";
import {formatDate} from "Utils/format/formatDate";
import DateSelector from "../../../../../components/DateSelector";
import security from 'stores/security';
import {DeleteOutlined, EditOutlined, FolderOpenOutlined, DatabaseOutlined, HistoryOutlined, DiffOutlined } from "@ant-design/icons";

class ModifyRSAs extends React.Component{
    constructor(props){
        super(props);
        this.state={
            dateFormat:"DD/MM/YYYY",
            is_remove:0,
            is_show_delete_modal:0,
            document_action_type:"",
            documentActionArr : {'add_document':1,'remove_document':1},
            uploadProgress:0,
            uploadingStatus:0,
            isShowErrorTip:0,
            quantityError:0,
            isAvailable:-1,
            initData:
                {
                    id: "2",
                    init_email: "78420344@qq.com",
                    combined_id: "212",
                    type: "cs",
                    name: "cs2 name",
                    email: "aaa",
                    prefix_label: null,
                    issue_date: "11/02/2021",
                    quantity: "22223",
                    currency: null,
                    price_per_share: "2",
                    board_approval_date: null,
                    cancellation_date: null,
                    cancellation_reason: null,
                    converted_from: null,
                    option_plan: null,
                    documents: null,
                    notes: null,
                    cash_paid: null,
                    debt_canceled: null,
                    value_of_ip_transferred: null,
                    other_consideration: null,
                    security: "CS-2",
                    status: "Outstanding",
                    create_timestamp: "2020-03-09 12:09:01",
                    transaction_value: 44446,
                    checkbox_status: "unchecked",
                    sign_status: "holderSignature"
                },
            checkboxStatus:{
                custom_label_id:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"custom_label_id",fieldName:__("Prefix label")},
                issue_date:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"issue_date",fieldName:__("Issue Date")},
                quantity:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"quantity",fieldName:__("Quantity")},
                // price_per_share:
                //     {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"price_per_share",fieldName:"Price per share"},
                board_approval_date:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"board_approval_date",fieldName:__("Board approval date")},
                cancellation_date:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"cancellation_date",fieldName:__("Canceled date")},
                cancellation_reason:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"cancellation_reason",fieldName:__("Canceled reason")},
                converted_from:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"converted_from",fieldName:__("Converted from")},
                // option_plan:
                //     {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"option_plan",fieldName:"Option plan"},
                incentive_plan_file:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"incentive_plan_file",fieldName:__("Equity incentive plan")},
                purchase_agreement_file:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"purchase_agreement_file",fieldName:__("Purchase agreement")},
                add_document:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"add_document",fieldName:__("Add more additional documents")},
                remove_document:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"remove_document",fieldName:__("Remove all additional documents")},
                notes:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"notes",fieldName:__("Notes")},
                cash_paid:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"cash_paid",fieldName:__("Cash paid")},
                debt_canceled:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"debt_canceled",fieldName:__("Debt canceled")},
                value_of_ip_transferred:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"value_of_ip_transferred",fieldName:__("Value of IP transferred")},
                other_consideration:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"other_consideration",fieldName:__("Other consideration")},
            },
            currencyList:['USD','HKD','SGD',],        
            convertedFrom:[
                "Select convertible",
                "CN-3 Zhende Fund",
                "CN- 1 Zhende Fund",
            ],
            modifyReason:[
                "Clerical error",
                "Amendment"
            ],
            optionPlan:["---","PLAN","MS Ventures Equity Option Plan"],
            data:{
                modify_id:"Rsa",
                reason:"",
                note:"",
                basicTemplateData:
                    {
                        custom_label_id:"",
                        issue_date:"",
                        quantity:"",
                        // price_per_share:"",
                        board_approval_date:"",
                        cancellation_date:"",
                        cancellation_reason:"",
                        converted_from:"",
                        option_plan:"",
                        incentive_plan_file :{name:__("No file selected"),file_obj:""},
                        purchase_agreement_file :{name:__("No file selected"),file_obj:""},
                        add_document:{name:__("No file selected"),file_obj:""},
                        remove_document:"",
                        notes:"",
                        cash_paid:"",
                        debt_canceled:"",
                        value_of_ip_transferred:"",
                        other_consideration:"",
                    },
                changedKey:[],
            },

            requiredKey:[
                "custom_label_id",
                "converted_from",
                "issue_date",
                "board_approval_date",
                "quantity",
                // "price_per_share",
                "cancellation_date",
                "cancellation_reason",
                "incentive_plan_file",
                "purchase_agreement_file",
                "add_document",
                "notes",
            ],
            optionalKey:[
                "cash_paid",
                "debt_canceled",
                "value_of_ip_transferred",
                "other_consideration",
            ],
            errorKeyArr:[],

        }
    }
    recordData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        });

    }
    recordCurData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData.basicTemplateData[dataKey] = dataVal;
        this.setState({
            data:curData
        });

    }
    recordNumData=(e)=>{
        const isFractionalShare = security.companyInfo.fractional_control;
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        if(dataKey=="quantity"){
            if(isFractionalShare==1){
                curData.basicTemplateData[dataKey] =  Utils.check5DecimalNumInputWithoutComma(dataVal);
            }else{
                curData.basicTemplateData[dataKey] =  Utils.checkNumInput(dataVal);
            }
        }else if(dataKey=="price_per_share"){
            curData.basicTemplateData[dataKey] =  Utils.check10DecimalNumInputWithoutComma(dataVal);
        }else{
            curData.basicTemplateData[dataKey] =  Utils.check2DecimalNumInputWithoutComma(dataVal);
        }
        this.setState({
            data:curData
        });
    }
    formatNumData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData.basicTemplateData[dataKey] = formatNumber(dataVal,null);
        this.setState({
            data:curData
        });
    }
    focusNumData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData.basicTemplateData[dataKey] = Utils.resetNumberWithCommas(dataVal);
        this.setState({
            data:curData
        });
    }

    checkedStatus = (e)=>{
        let curChecked = this.state.checkboxStatus;
        let dataKey = e.target.name;
        curChecked[dataKey].checked = !curChecked[dataKey].checked;
        if(curChecked[dataKey].checked){
            curChecked[dataKey].bg="modify-whiteInput";
            curChecked[dataKey].disabled="";
        }else{
            curChecked[dataKey].bg="modify-darkInput"
            curChecked[dataKey].disabled="disabled";
        }
        let documentActionArr = this.state.documentActionArr;
        if(documentActionArr.hasOwnProperty(dataKey)){
            if(curChecked[dataKey].checked){
                this.setState({document_action_type:dataKey});
                for(var key in documentActionArr){
                    if(key !== dataKey){
                        curChecked[key].checked = false;
                        curChecked[key].bg="modify-darkInput"
                        curChecked[key].disabled="disabled";
                    }
                }
            }
        }
        this.setState({
            checkboxStatus:curChecked
        })
    }
    selectFile=(e)=>{
        let fileObj = e.target.files[0];
        let curList = this.state.data;
        let dataKey = e.target.name;
        Utils.uploadFile(fileObj).then((result)=>{
            curList.basicTemplateData[dataKey].name = fileObj.name;
            curList.basicTemplateData[dataKey].file_obj = result;
            this.setState({
                data:curList
            })
        })

    }
    changeLegend=(e)=>{
        let curChecked = this.state.data;
        let dataKey = e.target.name;
        curChecked[dataKey].checked = !curChecked[dataKey].checked;
        this.setState({
            data:curChecked
        })
    }
    resetAction=()=>{
        let curData = this.state.data;
        curData.reason="";
        curData.modify_note="";
        curData.modify_id="";
        for(let key in this.state.checkboxStatus){
            if(this.state.checkboxStatus[key].checked){
                if(key=="incentive_plan_file"||key=="purchase_agreement_file"||key=="add_document"){
                    curData.basicTemplateData[key].name=__("No file selected");
                    curData.basicTemplateData[key].file_obj="";
                }else{
                    curData.basicTemplateData[key]="";
                }
                this.state.checkboxStatus[key].checked = false;
                this.state.checkboxStatus[key].disabled = "disabled";
            }

        }
        this.setState({
            data:curData,
            quantityError:0,
            isShowErrorTip:0,
            isAvailable:-1,
        })
        window.$("#modifyRSAsModal .tab-pane").removeClass("active");
        window.$("#rsas_modifyReason").addClass("active");
    }

    submitActionForRSA=()=>{
        if(this.state.uploadingStatus ===1){
            return;
        }
        this.setState({
            uploadingStatus:1
        });
        if(false && Utils.isLocal()){
            this.props.setModifiedSecurity(this.state.initData.key,this.state.initData,"optionsList");
            window.$("#modifyRSAsModal").modal("hide");
            this.resetAction();
            return;
        }

        let requestData = {
            reason:this.state.data.reason,
            modify_note:this.state.data.modify_note,
            modify_id: this.state.initData.id
        };
        for(let key in this.state.checkboxStatus){
            if(this.state.checkboxStatus[key].checked){
                requestData[key] = this.state.data.basicTemplateData[key];
            }
        }

        if(this.state.document_action_type==="add_document" && this.state.data.basicTemplateData.add_document.name!==__('No file selected')){
            //这里说明选择新增并且上传了文件
            requestData.document_action_type = 'add_document';
        }else if(this.state.document_action_type==="remove_document"){
            requestData.document_action_type = 'remove_document';
        }else{
            requestData.document_action_type = '';
        }

        axios.post('https://api.getsprout.co', {
            company_id:Utils.getLocalCompanyInfo().company_id,
            action:"modifyRsa",
            data:requestData
        },{
            onUploadProgress:(progressEvent)=>{
                let complete = (progressEvent.loaded / progressEvent.total * 100 | 0);
                console.log('上传 ' + complete+'%') ;
                this.setState({
                    uploadProgress:complete
                });
                if(complete == 100){
                    this.setState({
                        isShowUploadProgress:0
                    })

                }
            }
        }).then((response)=> {
            if(response.data.code === 0){
                //这里需要将返回的数据覆盖到主页面
                this.props.getEaList();
                // for(let key in this.state.checkboxStatus){
                //     if(this.state.checkboxStatus[key].checked){
                //         if(key === "add_document" || key==="remove_document"){
                //             continue;
                //         }
                //         this.state.initData[key] = this.state.data.basicTemplateData[key];
                //     }
                // }
                // let initData = this.state.initData;
                // initData.quantity = Utils.resetNumberWithCommas(initData.quantity);
                // if(response.data.data.hasOwnProperty("custom_label_id")){
                //     initData.custom_label_id = response.data.data.custom_label_id;
                // }
                // this.props.setModifiedSecurity(this.state.initData.key,initData,"rsasList");
                this.setState({
                    uploadProgress:0,
                    uploadingStatus:0
                });
                window.$("#modifyRSAsModal").modal("hide");
                this.resetAction();
                this.setState({
                    is_remove:0,
                })
            }else{
                // console.log("failed, error code is: "+response.data.code);
                message.error(response.data.data)
            }
        }).catch(function (error) {
            // console.log(error);
            message.error(error && error.message)
        });


    }
    setInitInfo=()=>{
        const { curSelectSecurity } = this.props;
        this.setState({
            initData:curSelectSecurity,
        });
    }
    checkPreficLabelId=()=>{
        const { data } = this.state;
        const { curSelectSecurity } = this.props;
        post('checkCustomLabelId',{custom_label_id:data.basicTemplateData.custom_label_id, security_plan_id: curSelectSecurity.security_plan_id, security_type: curSelectSecurity.security_type}).then((response)=> {
            if(response.data.code === 0){
                this.setState({
                    isAvailable:response.data.data.isAvailable
                }, ()=>this.checkNextAvailabel())
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    //Btn modify value function
    checkNextAvailabel=()=>{
        let flag =true;
        let isCheckedForRequiredKey = false;
        let isCheckedForOptionalKey = false;
        let curData = this.state.data;
        let curPlan = this.props.formatEaPlanList;
        let checkKey = this.state.requiredKey;
        let curQuantity = Utils.resetNumberWithCommas(curData.basicTemplateData.quantity);
        let availableQuantity = curPlan[this.props.curSelectSecurity.security_plan_id].available;
        for(let i=0;i<this.state.requiredKey.length;i++){
            if(this.state.checkboxStatus[checkKey[i]].checked){
                isCheckedForRequiredKey = true;
                if(checkKey[i]=="add_document"||checkKey[i]=="purchase_agreement_file"||checkKey[i]=="incentive_plan_file"){
                    if(!curData.basicTemplateData[checkKey[i]].file_obj){
                        this.state.errorKeyArr.push(checkKey[i]);
                        flag = false;
                    }
                }else if(checkKey[i]=="custom_label_id"){
                    if(!curData.basicTemplateData[checkKey[i]] || this.state.isAvailable==0) {
                        flag = false;
                    }
                }else if(checkKey[i]=="quantity"){
                    if(!curData.basicTemplateData[checkKey[i]]){
                        flag = false;
                    }
                    if(curQuantity>availableQuantity){
                        flag = false;
                        this.setState({
                            quantityError : 1,
                        })
                    }else{
                        this.setState({
                            quantityError : 0,
                        })
                    }

                }else{
                    if(!curData.basicTemplateData[checkKey[i]]){
                        this.state.errorKeyArr.push(checkKey[i]);
                        flag = false;
                    }
                }
            }
        }
        let optionalCheckKey = this.state.optionalKey;
        if(!isCheckedForRequiredKey){
            for(let k=0;k<this.state.optionalKey.length;k++){
                if(this.state.checkboxStatus[optionalCheckKey[k]].checked){
                    flag = true;
                    isCheckedForOptionalKey = true;
                    if(!curData.basicTemplateData[optionalCheckKey[k]]){
                        curData.basicTemplateData[optionalCheckKey[k]] = this.state.initData[optionalCheckKey[k]];
                        this.setState({
                                data:curData
                            }
                        )
                    }
                }
            }
        }
        if(!isCheckedForRequiredKey && !isCheckedForOptionalKey){
            flag = false;
        }
        if(flag){
            this.targetToStep3();
        }else{
            if(this.state.isAvailable==0){
                this.setState({
                    isShowErrorTip: 0,
                })
            }else if(!isCheckedForRequiredKey && !isCheckedForOptionalKey){

            }else {
                //开始展示错误信息：勾选了但是没填
                this.setState({
                    isShowErrorTip: 1,
                })
            }
        }
    }
    targetToStep3=()=>{
        this.setState({
            quantityError:0,
            isShowErrorTip:0,
            isAvailable:-1,
        })
        window.$("#modifyRSAsModal .tab-pane").removeClass("active");
        window.$("#rsas_reviewModifications").addClass("active");
    }
    showDeleteModal=()=>{
        this.setState({
            is_show_delete_modal:1,
        })
    }
    closeDeleteModal=()=>{
        this.setState({
            is_show_delete_modal:0,
        })
    }
    deleteAllDoc=()=>{
        this.setState({
            is_remove:1
        })
    }

    modifyRsaDateChange = (key, moment_key, date) => {
        const { dateFormat } = this.state;
        let curData = this.state.data;
        curData.basicTemplateData[key] = date.format( dateFormat);
        curData.basicTemplateData[moment_key] = date;
        this.setState({
            data:curData
        });
    }

    render(){
        const { currency = '', curSelectSecurity } = this.props
        const { data, dateFormat, checkboxStatus,quantityError } = this.state;
        let modifyKey = this.state.data.modify_id;
        let isAbleToModifyQuantity = 0;
        if( curSelectSecurity.status!="Exercised"
            && curSelectSecurity.status!="Partially exercised"
            && (curSelectSecurity.vested_quantity == 0 || curSelectSecurity.vested_quantity==curSelectSecurity.quantity)
            && curSelectSecurity.vesting_schedule_id == 0){
            isAbleToModifyQuantity = 1;
        }
        let showSelectCount = 0;
        return(
            <div className="modal fade" id="modifyRSAsModal" tabIndex="-1"  data-backdrop="static" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="modal-dialog dialog960 dialog-top40">
                    <div className="tab-content">
                        <div role="tabpanel" className="modal-content content720 tab-pane active" id="rsas_modifyReason">
                            <div className="modal-header box-header">
                                <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.resetAction}>×</button>
                                <h4 className="modal-title" id="myModalLabel">{__('Modify Share')}</h4>
                            </div>
                            <div className="modal-body padding2030">
                                <div className="shareTitleBox">
                                    <div className="iconBox bg-lightgreen">
                                        <EditOutlined/>
                                    </div>
                                    <div className="shareTextBox">
                                        <p className="shareTitle">{__('Select a reason for modifying the selected share')}</p>
                                        <p className="text-muted">{__('Some modifications will trigger an email to the stakeholder. You can review the modifications and email notifications before saving your changes.')}</p>
                                    </div>
                                </div>
                                <div className="padding2026026">
                                    <div className="form-group add-items">
                                        <div className="row  profile-row">
                                            <label className="col-xs-5 add-label">{__('Reason')}<br/><i className="text-muted"><small>- {__('Required')}</small></i></label>
                                            <div className="col-xs-4">
                                                <select name="reason"  value={this.state.data.reason} onChange={this.recordData} className="form-control select-container">
                                                    <option value="">---</option>
                                                    {this.state.modifyReason.map((value,key)=>{
                                                        return(
                                                            <option key={key}>{value}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group add-items">
                                        <div className="row  profile-row">
                                            <label className="col-xs-5 add-label">{__('Note')} </label>
                                            <div className="col-xs-6">
                                                <textarea name="note" onChange={this.recordData} value={this.state.data.note} className="form-control text-area minHeight100" type="text"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div role="presentation" className="modal-footer box-footer no-paddingtop">
                                {this.state.data.reason==""&&
                                <a className="btn boxbtn-to send-disabledBtn">{__('Next: modify value')}</a>
                                }
                                {this.state.data.reason!==""&&
                                <a href="#rsas_modifyValue" role="tab" onClick={this.setInitInfo} data-toggle="tab" className="btn boxbtn-to bg-darkblue">{__('Next: modify value')}</a>
                                }
                            </div>
                        </div>
                        <div role="tabpanel" className="modal-content content900 tab-pane" id="rsas_modifyValue">
                            <div className="modal-header box-header">
                                <a href="#rsas_modifyReason"  aria-controls="rsas_modifyReason" role="tab" data-toggle="tab" className="back-btn"></a>
                                <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.resetAction}>×</button>
                                <h4 className="modal-title" id="myModalLabel">{__('Modify Share')} {this.state.initData.custom_label_id}</h4>
                            </div>
                            <div className="modal-body padding2030">
                                {this.state.isShowErrorTip == 1 && quantityError ==0 &&
                                <div className="alert alert-warning alert-dismissible pink-box">
                                    <p >{__('The required field(s) can not be empty.')}</p>
                                </div>
                                }
                                {this.state.isAvailable == 0 &&
                                <div  className="alert alert-warning alert-dismissible pink-box">
                                    <p>{__('Prefix label already exists. Please select another.')}</p>
                                </div>
                                }
                                {quantityError == 1 &&
                                <div  className="alert alert-warning alert-dismissible pink-box">
                                    <p>{__('Quantity cannot exceed the available quantity.')}</p>
                                </div>
                                }
                                <p className="text-muted marginBott10">{__('Select the fields you would like to change and enter the new information.')}</p>
                                <div className="modify-content">
                                    <div className="shareTitleBox">
                                        <div className="iconBox bg-lightgreen">
                                            <DatabaseOutlined/>
                                        </div>
                                        <div className="shareTextBox">
                                            <p className="shareTitle">{__('Certificate details')}</p>
                                            <p className="text-muted">{__('Update information included in the certificate such as quantity, price per share and issue date.')}</p>
                                        </div>
                                    </div>
                                    <table className="table modify-table modify-value-table">
                                        <tbody>
                                        <tr className="modify-firstRow">
                                            <td>{__('Field')}</td>
                                            <td>{__('Value')}</td>
                                            <td>{__('New value')}</td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="detailsCheck2" type="checkbox" name="custom_label_id" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.custom_label_id.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="detailsCheck2"></label>
                                                    <span className="modify-label text-muted">{__('Prefix label')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.custom_label_id}</td>
                                            <td><input type="text" className="form-control " name="custom_label_id" value={this.state.data.basicTemplateData.custom_label_id} disabled={this.state.checkboxStatus.custom_label_id.disabled} onChange={this.recordCurData} /></td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <a className="convertible-ques top2">
                                                        <p className="convertible-tiptext modify-tiptext124">{__('Select one certificate to convert convertible. Certificates already linked to convertibles cannot be updated.')}</p>
                                                    </a>
                                                    <input id="detailsCheck10" type="checkbox" name="converted_from" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.converted_from.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="detailsCheck10"></label>
                                                    <span className="modify-label text-muted">{__('Converted from')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.converted_from}</td>
                                            <td>
                                                <select type="text" className="form-control select-container modify-select" name="converted_from" value={this.state.data.basicTemplateData.converted_from} disabled={this.state.checkboxStatus.converted_from.disabled} onChange={this.recordCurData} >
                                                    {this.state.convertedFrom.map((value,key)=>{
                                                        return(
                                                            <option key={key}>{value}</option>
                                                        )
                                                    })}
                                                </select>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <a className="convertible-ques top2">
                                                        <p className="convertible-tiptext modify-tiptext144">{__('Issue Date cannot be modified for certificates that are transferred canceled, terminated, converted repurchased or resulting from one of these transactions.')}</p>
                                                    </a>
                                                    <input id="detailsCheck3" type="checkbox" name="issue_date" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.issue_date.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="detailsCheck3"></label>
                                                    <span className="modify-label text-muted">{__('Issue Date')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.issue_date=="" ? "" : formatDate(curSelectSecurity.issue_date)}</td>
                                            <td>
                                                <DateSelector
                                                    disabled={checkboxStatus.issue_date.disabled}
                                                    onChange={this.modifyRsaDateChange.bind(this, 'issue_date', 'issue_date_moment')}
                                                    value={I(data.basicTemplateData['issue_date_moment'], I(data.basicTemplateData['issue_date'], '') ? moment(data.basicTemplateData['issue_date'], dateFormat) : '')}
                                                    className="datepicker_opacity"
                                                />
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="detailsCheck7" type="checkbox" name="board_approval_date" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.board_approval_date.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="detailsCheck7"></label>
                                                    <span className="modify-label text-muted">{__('Board approval date')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.board_approval_date=="" ? "" : formatDate(curSelectSecurity.board_approval_date)}</td>
                                            <td>
                                                <DateSelector
                                                    disabled={checkboxStatus.board_approval_date.disabled}
                                                    onChange={this.modifyRsaDateChange.bind(this, 'board_approval_date', 'board_approval_date_moment')}
                                                    value={I(data.basicTemplateData['board_approval_date_moment'], I(data.basicTemplateData['board_approval_date'], '') ? moment(data.basicTemplateData['board_approval_date'], dateFormat) : '')}
                                                    className="datepicker_opacity"
                                                />
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <a className="convertible-ques top2">
                                                        <p className="convertible-tiptext modify-tiptext124">{__('Quantity cannot be modified for multiple certificates or for certificates resulting from a transaction such as a transfer, exercise, or repurchase.')}</p>
                                                    </a>
                                                    { isAbleToModifyQuantity==0 &&
                                                    <input type="checkbox" name="quantity" className="re-checkbox modify-checkbox disabled-checkbox" disabled={true}/>
                                                    }
                                                    { isAbleToModifyQuantity == 1 &&
                                                    <input id="detailsCheck4" type="checkbox" name="quantity" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.quantity.checked} onChange={this.checkedStatus}/>
                                                    }
                                                    <label htmlFor="detailsCheck4"></label>
                                                    <span className="modify-label text-muted">{__('Quantity')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{formatNumber(curSelectSecurity.quantity,null)}</td>
                                            <td><input type="text" className="form-control " name="quantity" value={this.state.data.basicTemplateData.quantity} disabled={this.state.checkboxStatus.quantity.disabled} onBlur={this.formatNumData} onFocus={this.focusNumData} onChange={this.recordNumData} /></td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <a className="convertible-ques top2">
                                                        <p className="convertible-tiptext modify-tiptext144">{__('Canceled date cannot be modified for certificates that are transferred canceled, terminated, converted repurchased or resulting from one of these transactions.')}</p>
                                                    </a>
                                                    <input id="detailsCheck8" type="checkbox" name="cancellation_date" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.cancellation_date.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="detailsCheck8"></label>
                                                    <span className="modify-label text-muted">{__('Canceled date')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.cancellation_date=="" ? "" : formatDate(curSelectSecurity.cancellation_date)}</td>
                                            <td>
                                                <DateSelector
                                                    disabled={checkboxStatus.cancellation_date.disabled}
                                                    onChange={this.modifyRsaDateChange.bind(this, 'cancellation_date', 'cancellation_date_moment')}
                                                    value={I(data.basicTemplateData['cancellation_date_moment'], I(data.basicTemplateData['cancellation_date'], '') ? moment(data.basicTemplateData['cancellation_date'], dateFormat) : '')}
                                                    className="datepicker_opacity"
                                                />
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="detailsCheck9" type="checkbox" name="cancellation_reason" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.cancellation_reason.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="detailsCheck9"></label>
                                                    <span className="modify-label text-muted">{__('Cancellation reason')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.cancellation_reason}</td>
                                            <td>
                                                <select type="text" className="form-control select-container modify-select" name="cancellation_reason" value={this.state.data.basicTemplateData.cancellation_reason} disabled={this.state.checkboxStatus.cancellation_reason.disabled} onChange={this.recordCurData} >
                                                    <option value="">---</option>
                                                    {cancellationReason.map((value,key)=>{
                                                        return(
                                                            <option key={key}>{value}</option>
                                                        )
                                                    })}
                                                </select>
                                            </td>
                                        </tr>
                                        {/* 这里不需要option plan,简单起见先注释  */}
                                        {/*<tr className="modify-contentRow">*/}
                                        {/*    <td>*/}
                                        {/*        <div className="check-box">*/}
                                        {/*            <a className="convertible-ques top2">*/}
                                        {/*                <p className="convertible-tiptext modify-tiptext66">Option plan can only be modified on one RSA at a time.</p>*/}
                                        {/*            </a>*/}
                                        {/*            <input id="detailsCheck11" type="checkbox" name="option_plan" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.option_plan.checked} onChange={this.checkedStatus}/>*/}
                                        {/*            <label htmlFor="detailsCheck11"></label>*/}
                                        {/*            <span className="modify-label text-muted">Option plan</span>*/}
                                        {/*        </div>*/}
                                        {/*    </td>*/}
                                        {/*    <td>*/}
                                        {/*        <select type="text" className="form-control select-container modify-select" name="option_plan" value={this.state.data.basicTemplateData.option_plan} disabled={this.state.checkboxStatus.option_plan.disabled} onChange={this.recordCurData} >*/}
                                        {/*            {this.state.optionPlan.map((value,key)=>{*/}
                                        {/*                return(*/}
                                        {/*                    <option key={key}>{value}</option>*/}
                                        {/*                )*/}
                                        {/*            })}*/}
                                        {/*        </select>*/}
                                        {/*    </td>*/}
                                        {/*</tr>*/}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="modify-content">
                                    <div className="shareTitleBox">
                                        <div className="iconBox bg-lightgreen">
                                            <FolderOpenOutlined/>
                                        </div>
                                        <div className="shareTextBox">
                                            <p className="shareTitle">{__('Documents and notes')}</p>
                                            <p className="text-muted">{__('Update all attachment types and notes available to holder.')}</p>
                                        </div>
                                    </div>
                                    {this.state.is_show_delete_modal==1 &&
                                    <div className="deleteModalBox">
                                        <DeleteDoc closeDeleteModal={this.closeDeleteModal} deleteAllDoc={this.deleteAllDoc}  modifyKey={this.state.data.modify_id}   />
                                    </div>
                                    }
                                    <table className="table modify-table modify-value-table">
                                        <tbody>
                                        <tr className="modify-firstRow">
                                            <td>{__('Field')}</td>
                                            <td>{__('Value')}</td>
                                            <td>{__('New value')}</td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td>
                                                <div className="check-box">
                                                    <input id="rsas_doc_check0" type="checkbox" name="incentive_plan_file" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.incentive_plan_file.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="rsas_doc_check0"></label>
                                                    <span className="modify-label text-muted">{__('Equity incentive plan')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.incentive_plan_file?.name}</td>
                                            <td className="fileTd modify_file_td flexDiv">
                                                <div className="select-btn select-blue lib-choose marginRig10">
                                                    {__('Choose a file')}
                                                    <label htmlFor="equity_incentive_plan" className="sr-only" >File input</label>
                                                    <input name="incentive_plan_file"  ref="equity_incentive_plan" disabled={this.state.checkboxStatus.incentive_plan_file.disabled} type="file"  id="equity_incentive_plan"  onChange={this.selectFile} />
                                                </div>
                                                <div className="filename-box file_name_div">
                                                    <span className="close files-del hidden">x</span>
                                                    <p className="text-muted">{this.state.data.basicTemplateData.incentive_plan_file.name}</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td>
                                                <div className="check-box">
                                                    <input id="rsas_doc_check1" type="checkbox" name="purchase_agreement_file" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.purchase_agreement_file.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="rsas_doc_check1"></label>
                                                    <span className="modify-label text-muted">{__('Purchase agreement')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.purchase_agreement_file?.name}</td>
                                            <td className="fileTd ">
                                                <div className="select-btn select-blue lib-choose marginRig10">
                                                    {__('Choose a file')}
                                                    <label htmlFor="purchase_agreement" className="sr-only" >File input</label>
                                                    <input name="purchase_agreement_file"  ref="purchase_agreement" disabled={this.state.checkboxStatus.purchase_agreement_file.disabled} type="file"  id="purchase_agreement"  onChange={this.selectFile} />
                                                </div>
                                                <div className="filename-box">
                                                    <span className="close files-del hidden">x</span>
                                                    <p className="text-muted">{this.state.data.basicTemplateData.purchase_agreement_file.name}</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td>
                                                <div className="check-box">
                                                    <input id="rsas_doc_check2" type="checkbox" name="add_document" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.add_document.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="rsas_doc_check2"></label>
                                                    <span className="modify-label text-muted">{__('Add more additional documents')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}></td>
                                            <td className="fileTd ">
                                                <div className="select-btn select-blue lib-choose marginRig10">
                                                    {__('Choose a file')}
                                                    <label htmlFor="add_doc" className="sr-only" >File input</label>
                                                    <input name="add_document"  ref="add_document" disabled={this.state.checkboxStatus.add_document.disabled} type="file"  id="add_doc"  onChange={this.selectFile} />
                                                </div>
                                                <div className="filename-box">
                                                    <span className="close files-del hidden">x</span>
                                                    <p className="text-muted">{this.state.data.basicTemplateData.add_document.name}</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td>
                                                <div className="check-box">
                                                    <input id="rsas_doc_check4" name="remove_document" type="checkbox" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.remove_document.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="rsas_doc_check4"></label>
                                                    <span className="modify-label text-muted">{__('Remove all additional documents')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}></td>
                                            {this.state.checkboxStatus.remove_document.checked===false &&
                                            <td>
                                                <div type="text" name="remove_document" className={"form-control "} value={this.state.data.basicTemplateData.remove_document} disabled={this.state.checkboxStatus.remove_document.disabled} onChange={this.recordCurData}>
                                                    <button type="button" ><DeleteOutlined style={{cursor:'not-allowed'}}/></button>
                                                </div>
                                            </td>
                                            }
                                            {this.state.checkboxStatus.remove_document.checked===true &&
                                            <td>
                                                <div type="text" name="remove_document" className={"form-control "} value={this.state.data.basicTemplateData.remove_document} disabled={this.state.checkboxStatus.remove_document.disabled} onChange={this.recordCurData}>
                                                    <button type="button" onClick={this.showDeleteModal}><DeleteOutlined /></button>
                                                </div>
                                            </td>
                                            }
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td>
                                                <div className="check-box">
                                                    <input id="rsas_doc_check5" type="checkbox" name="notes" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.notes.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="rsas_doc_check5"></label>
                                                    <span className="modify-label text-muted">{__('Notes')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.notes}</td>
                                            <td>
                                                <textarea type="text" className={"form-control "} name="notes" value={this.state.data.basicTemplateData.notes} disabled={this.state.checkboxStatus.notes.disabled} onChange={this.recordCurData} />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="modify-content">
                                    <div className="shareTitleBox">
                                        <div className="iconBox bg-lightgreen">
                                            <HistoryOutlined/>
                                        </div>
                                        <div className="shareTextBox">
                                            <p className="shareTitle">{__('Financing history')}</p>
                                            <p className="text-muted">{__('Update the transaction value based on the type of payment.')}</p>
                                        </div>
                                    </div>
                                    <table className="table modify-table modify-value-table">
                                        <tbody>
                                        <tr className="modify-firstRow">
                                            <td>{__('Field')}</td>
                                            <td>{__('Value')}</td>
                                            <td>{__('New value')}</td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td className="modify-historyTd">
                                                <div className="check-box">
                                                    <a className="convertible-ques top2">
                                                        <p className="convertible-tiptext modify-tiptext66">{__('The amount of cash paid to the company for this certificate.')}</p>
                                                    </a>
                                                    <input id="rsaHistoryCheck0" type="checkbox" name="cash_paid" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.cash_paid.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="rsaHistoryCheck0"></label>
                                                    <span className="modify-label text-muted">{__('Cash paid ')}<i className="text-muted"> - {__('Optional')}</i> </span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.cash_paid}</td>
                                            <td>
                                                <div className="input-group"><span className="input-group-addon">{currency}</span>
                                                    <input type="text" className={"form-control "} name="cash_paid" value={this.state.data.basicTemplateData.cash_paid} disabled={this.state.checkboxStatus.cash_paid.disabled}  onBlur={this.formatNumData} onFocus={this.focusNumData} onChange={this.recordNumData} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td className="modify-historyTd">
                                                <div className="check-box">
                                                    <a className="convertible-ques top2">
                                                        <p className="convertible-tiptext modify-tiptext124">{__('For convertible securities(e.g. convertible notes, SAFEs), the total value of principal and interest being converted into shares.')}</p>
                                                    </a>
                                                    <input id="rsaHistoryCheck1" type="checkbox" name="debt_canceled" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.debt_canceled.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="rsaHistoryCheck1"></label>
                                                    <span className="modify-label text-muted">{__('Debt canceled ')}<i className="text-muted"> - {__('Optional')}</i> </span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.debt_canceled}</td>
                                            <td>
                                                <div className="input-group"><span className="input-group-addon">{currency}</span>
                                                    <input type="text" className={"form-control "} name="debt_canceled" value={this.state.data.basicTemplateData.debt_canceled} disabled={this.state.checkboxStatus.debt_canceled.disabled}  onBlur={this.formatNumData} onFocus={this.focusNumData} onChange={this.recordNumData} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td className="modify-historyTd">
                                                <div className="check-box">
                                                    <a className="convertible-ques top2">
                                                        <p className="convertible-tiptext modify-tiptext86">{__('Cash value of the technology or intellectual property exchanged for the shares.')}</p>
                                                    </a>
                                                    <input id="rsaHistoryCheck2" type="checkbox" name="value_of_ip_transferred" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.value_of_ip_transferred.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="rsaHistoryCheck2"></label>
                                                    <span className="modify-label text-muted">{__('Value of IP transferred ')}<i className="text-muted"> - {__('Optional')}</i> </span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.value_of_ip_transferred}</td>
                                            <td>
                                                <div className="input-group"><span className="input-group-addon">{currency}</span>
                                                    <input type="text" className={"form-control "} name="value_of_ip_transferred" value={this.state.data.basicTemplateData.value_of_ip_transferred} disabled={this.state.checkboxStatus.value_of_ip_transferred.disabled}  onBlur={this.formatNumData} onFocus={this.focusNumData} onChange={this.recordNumData} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td className="modify-historyTd">
                                                <div className="check-box">
                                                    <a className="convertible-ques top2">
                                                        <p className="convertible-tiptext modify-tiptext106">{__('Any other value exchanged for the shares apart from cash, debt canceled, or IP transferred.')}</p>
                                                    </a>
                                                    <input id="rsaHistoryCheck3" type="checkbox" name="other_consideration" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.other_consideration.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="rsaHistoryCheck3"></label>
                                                    <span className="modify-label text-muted">{__('Other consideration ')}<i className="text-muted"> - {__('Optional')}</i> </span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.other_consideration}</td>
                                            <td>
                                                <div className="input-group"><span className="input-group-addon">{currency}</span>
                                                    <input type="text" className={"form-control "} name="other_consideration" value={this.state.data.basicTemplateData.other_consideration} disabled={this.state.checkboxStatus.other_consideration.disabled}  onBlur={this.formatNumData} onFocus={this.focusNumData} onChange={this.recordNumData} />
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div role="presentation" className="modal-footer box-footer no-paddingtop">
                                <a onClick={this.checkPreficLabelId}  role="tab" data-toggle="tab" className="btn boxbtn-to bg-darkblue">{__('Next: review modifications')}</a>
                            </div>
                        </div>
                        <div role="tabpanel" className="modal-content content900 tab-pane" id="rsas_reviewModifications">
                            <div className="modal-header box-header">
                                <a href="#rsas_modifyValue"  aria-controls="rsas_modifyValue" role="tab" data-toggle="tab" className="back-btn"></a>
                                <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.resetAction}>×</button>
                                <h4 className="modal-title" id="myModalLabel">{__('Modify Share')} {this.state.initData.custom_label_id}</h4>
                            </div>
                            <div className="modal-body padding2030">
                                <div className="modify-content">
                                    <div className="shareTitleBox">
                                        <div className="iconBox bg-lightgreen">
                                            <DiffOutlined/>
                                        </div>
                                        <div className="shareTextBox">
                                            <p className="shareTitle">{__('Review and apply modifications')}</p>
                                            <p className="text-muted">{__('Review the table below and save the modifications. For some fields on accepted securities, a notification will be sent out. Changes marked with  Yes on Sends email notification will send an email to the stakeholder.')}<br />
                                                {/*<a className="text-blue">Learn more about modifications ></a>*/}
                                            </p>
                                        </div>
                                    </div>
                                    <table className="modify-table reviewTb">
                                        <tbody>
                                        <tr className="modify-firstRow">
                                            <td className="width60">{__('Security')}</td>
                                            <td className="width120" >{__('Stakeholder')}</td>
                                            <td className="width140" >{__('Field')}</td>
                                            <td className="width160">{__('Old value')}</td>
                                            <td className="width160" >{__('New value')}</td>
                                            <td className="width180" >{__('Sends email notification')}</td>
                                        </tr>
                                        {Object.values(this.state.checkboxStatus).map((value,key)=>{
                                            if(value.checked){
                                                showSelectCount++;
                                                if(value.jsonKey == "purchase_agreement_file"||  value.jsonKey == "incentive_plan_file"){
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd">{this.state.initData[value.jsonKey].name}</td>
                                                            <td className="newValueTd">{this.state.data.basicTemplateData[value.jsonKey].name}</td>
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    );
                                                }else if(value.jsonKey === "add_document"){
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd">{this.state.initData[value.jsonKey]}</td>
                                                            <td className="newValueTd">{this.state.data.basicTemplateData[value.jsonKey].name}</td>
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    );
                                                }else if(value.jsonKey == "quantity"){
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd">{formatNumber(this.state.initData[value.jsonKey],null) }</td>
                                                            <td className="newValueTd">{this.state.data.basicTemplateData[value.jsonKey]}</td>
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    );
                                                }else if(value.jsonKey === "issue_date" || value.jsonKey === "board_approval_date" || value.jsonKey ==="cancellation_date" ){
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd">{ formatDate(this.state.initData[value.jsonKey])}</td>
                                                            <td className="newValueTd">{ formatDate(data.basicTemplateData[value.jsonKey])}</td>
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    );
                                                }else{
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd">{this.state.initData[value.jsonKey]}</td>
                                                            <td className="newValueTd">{this.state.data.basicTemplateData[value.jsonKey]}</td>
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    );
                                                }
                                            }
                                        })}

                                        {this.state.uploadProgress >0 &&
                                        <tr className="reviewTb-contentRow ">
                                            <td colSpan={"6"}>{this.state.uploadProgress}% {__('uploaded')}...</td>
                                        </tr>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer box-footer no-paddingtop">
                                <a onClick={this.submitActionForRSA} className="btn boxbtn-to bg-darkblue">{__('Apply modifications')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ModifyRSAs;