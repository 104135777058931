import React, { Component } from 'react';
import { Button } from 'antd';
class FairHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    // handleClick = () => {
    //     this.props.bindclick(true)
    // }

    isModalVisibleChange = () => {
        const { bindclick } = this.props;
        bindclick();
    }

    render() {

        return (
            <div className='fair-header'>
                <div className='fair-content-title'>{__('FMV Dashboard')}</div>
                <div className='fair-content-border'>
                    <div className='child-border'></div>
                </div>
                <div className='fair-info'>
                    <div className='fair-info-title'> <div className='fontFamily'>{__('I')}</div>{__('s your fair market value up-to-date?')}</div>
                    <div className='fair-info-text'>{__('A company’s FMV can be affected by new financing rounds, mergers and acquisitions, or other significant changes. It is important to record these events so stakeholders can make decisions about exercising and to ensure the company’s tax documents and expense reporting are correct.')}</div>
                    <Button type="primary" onClick={this.isModalVisibleChange}>{__('Add fair market value')}</Button>
                </div>
            </div> 
        )
    }
}
export default FairHeader;