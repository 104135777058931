import React, { Component } from 'react';
import { Table, message, Dropdown, Menu } from 'antd';
import { I, formatCurrencyNumber } from 'Utils';
import { formatDate } from 'Utils/format/formatDate'
import Modal from 'components/Modal';
import moment from "moment";
import { post } from 'srcPath/http';
import { DownOutlined } from "@ant-design/icons";
import AdvanceTermsModels from '../AdvanceTermsModels/AdvanceTermsModels'
const stringOrderBy = (a, b, key) => {
    const stringA = a[key].toUpperCase();
    const stringB = b[key].toUpperCase();
    return stringA < stringB ? -1 : stringA > stringB ? 1 : 0;
}
class FMVTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataSource: [],
            columns: [
                {
                    title: __('Fair market value'),
                    dataIndex: 'fair_market_value',
                    align: 'left',
                    width: '25%',
                    render: val => formatCurrencyNumber(val),
                    sorter: (a, b) => a.fair_market_value - b.fair_market_value,
                },
                {
                    title: __('Source'),
                    dataIndex: 'valuation_source',
                    align: 'left',
                    width: '20%',
                    sorter: (a, b) => stringOrderBy(a, b, 'valuation_source'),
                },
                {
                    title: __('Status'),
                    dataIndex: 'status',
                    align: 'left',
                    width: '20%',
                    sorter: (a, b) => a.status - b.status,
                    render: (text, value) => {
                        return <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'noWrap' }}>
                            {value.status == '0' && <div className="file_text red-disc">&nbsp;&nbsp;&nbsp;&nbsp;Pending</div >}
                            {value.status == '1' && <div className="file_text green-disc">&nbsp;&nbsp;&nbsp;&nbsp;Complete</div >}
                        </div>
                    }
                },
                {
                    title: __('Effective date'),
                    dataIndex: 'effective_date',
                    align: 'left',
                    width: '20%',
                    sorter: (a, b) => {
                        const dataA = moment(a.effective_date, 'DD/MM/YYYY').format('YYYYMMDD');
                        const dataB = moment(b.effective_date, 'DD/MM/YYYY').format('YYYYMMDD');
                        return dataA - dataB;
                    },
                    render: (text, val) => formatDate(val.effective_date),

                },
                {
                    title: __('Expiration date'),
                    dataIndex: 'expiration_date',
                    align: 'left',
                    width: '10%',
                    sorter: (a, b) => {
                        const dataA = moment(a.expiration_date, 'DD/MM/YYYY').format('YYYYMMDD');
                        const dataB = moment(b.expiration_date, 'DD/MM/YYYY').format('YYYYMMDD');
                        return dataA - dataB;
                    },
                    render: (text, val) => formatDate(val.expiration_date),
                },
                {
                    title: '',
                    dataIndex: '',
                    // render: (text, value, index) => dropdownInfo(text, value, index)
                    render: (text, value, index) => {
                        return (
                            <Dropdown overlay={this.menu(text)} placement="bottomRight" trigger={['click']}>
                                <div className="tableMenu" ><DownOutlined style={{ color: '#FFF' }} /></div>
                            </Dropdown>
                        )
                    }
                },
            ],
        }
    }
    menu = (val) => (
        <Menu onClick={this.operate.bind(this, val)}>
            {val.status == 1 && <Menu.Item key="0">
                <div>{__('Delete')}</div>
            </Menu.Item>}
            {(val.expired == 0 || (val.status == 1 && val.fmv_type == 1)) && <Menu.Item key="1">
                <div >{__('Edit Fair market value')}</div>
            </Menu.Item>}
            {val.status == 0 && <Menu.Item key="2">
                <div>{__('Confirm')}</div>
            </Menu.Item>}
        </Menu >

    )
    operate = (val, index) => {
        let { key } = index
        switch (key) {
            case '0':
                this.delbindclick(val)
                break;
            case '1':
                this.editbindclick(val)
                break;
            case '2':
                this.conbindclick(val)
                break;

            default:
                break;
        }
    }
    payView = () => {
        const { payOverView } = this.props
        payOverView()
    }
    delbindclick = (val) => {
        const { id, fmv_type } = val
        let data = { id, fmv_type }
        post('deleteFairMarketValue', data).then(res => {
            if (res.status == 200) {
                this.payView()
            }
        }).catch(function (error) {
        });
    }
    conbindclick = (val) => {
        const { id } = val
        let data = { id }
        post('confirmFairMarketValue', data).then(res => {
            if (res.status == 200) {
                this.payView()
            }
        }).catch(function (error) {
        });
    }
    editbindclick = (val) => {
        const { shareClass, payOverView, select_list, newdatalist, currency,EchartsSourec } = this.props
        this.advanceTermsModal = Modal.open({
            component: props => <AdvanceTermsModels
                close={props.close}
                val={val}
                shareClass={shareClass}
                isAdd={false}
                payOverView={payOverView}
                select_list={select_list}
                newdatalist={newdatalist}
                currency={currency}
                EchartsSourec={EchartsSourec}
            />,
            className: 'certificate-modal-wrap',
            width: '780px',
            maskClosable: false
        })
    }
    /**
    * 卸载
    */
    componentWillUnmount() {
        if (this.advanceTermsModal) {
            this.advanceTermsModal.close();
        }
    }
    render() {
        const { columns, } = this.state
        const { dataSource } = this.props
        return (
            <div>
                <Table
                    rowClassName={record => {
                        if (record.status == 0) return 'tablecolor';
                    }}
                    columns={columns}
                    pagination={false}
                    dataSource={dataSource}
                    rowKey={record => record.id}
                />
            </div>
        )
    }
}
export default FMVTable;