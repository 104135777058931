import React, { Component } from 'react';
import Message from './Message';
import NoPermission from "./NoPermission";
import NoPermissionSubsidiary from "./NoPermissionSubsidiary";
import security from 'stores/security';
import Loading from 'components/Loading';
import SignupPlan from "./Message/components/SignupPlan";
import { post } from 'srcPath/http';
import { I } from 'Utils';
import {getRouteConfig} from "../config";

class CheckPlan extends Component {
    onboardingUrl = '/home/onboarding';
    state = {
        url: false,
    };
    componentDidMount() {
        this.getCompanyPayInfo();
    }

    getCompanyPayInfo = async () => {
        this.setState({loading: true});
        const { data: resData } = await post('getCompanyPayInfo')
        const { code, data } = resData || {}
        let returnUrl = '';

        if (code === 0) {
            const { pay_type, plan_id, pay_status, url } = data;
            if(pay_type == 2 && !I(plan_id, 0) ){
                //BD处理中
            }else if(pay_type == 2 && I(plan_id, 0)){
                // BD已设置plan
            }else if(pay_type == 1 && pay_status == 1){
                // Signup流程，并且已支付
            }else if(pay_type == 1 && !I(pay_status, 0) && !I(security.companyInfo.is_finished_onboarding, '')){
                // Signup流程，未支付
                returnUrl = url;
            }
        }
        this.setState({url: returnUrl, loading: false});
    }

    getLink = () => {
        const { history } = this.props;
        const { url } = this.state;
        history.push(url);
    }

    //验证权限
    check = () => {
        const { history } = this.props;
        const { url } = this.state;
        const { flag, planControl, isArrears } = security;
        if(flag == 0){
            return <NoPermissionSubsidiary />;
        }
        if(url){
            return <SignupPlan getLink={this.getLink} />
        }
        const config = getRouteConfig({ showToken: this.showToken }).filter((value, key) => security.hasPermission(value.permission))
        const finishOnboarding = security.companyInfo.is_finished_onboarding && security.companyInfo.is_finished_onboarding === '1'
        // 是否有onboarding 权限
        const showOnboarding = security.hasPermission('company_setup')
        // 如果没有onboarding 权限 或者完成了 onboarding，需要展示其他路由
        const showOthers = finishOnboarding || !showOnboarding
        //通过 plan control 判断是否需要欠费验证。（1：无需验证，2：需要验证）
        if(planControl == 1){
            //只有super admin 才能 onboarding 配置
            if(flag == 1 && url !== false){
                history.push(showOthers && config?.[0]?.path || this.onboardingUrl);
            }else{
                if(security.flag != 0){
                    return <Message />
                }
            }
        }else{
            //验证是否欠费，如欠费则无需验证角色等级，直接跳转欠费页面
            if(!!!isArrears && url !== false){
                return flag == 1 ? history.push(showOthers && config?.[0]?.path || this.onboardingUrl) : <NoPermission />;
            }else{
                //只有super admin 在没有欠费的情况下才能 onboarding 配置
                if(flag == 1 && url !== false){
                    history.push(showOthers && config?.[0]?.path || this.onboardingUrl);
                }else{
                    if(security.flag != 0){
                        return <Message />
                    }
                }
            }
        }
    }

    render() {
        const { loading, url } = this.state;
        if (loading) {
            return <Loading />
        }
        return (
            <>{this.check()}</>
        );
    }
}
export default CheckPlan;