import React, { Component, Fragment } from 'react';
import LinkTable from './LinkTable';
import Utils from 'Utils'
const { getRealUrlForFiles } = Utils;

export default function Documents(props) {
	const {
		notes,
		companyId,
		type,
		formOfConvertible = {},
		purchaseAgreement = {},
		additionalDocuments,
		formOfOptionAgreement = {},
		equiityIncentivePlan = {},
		formOfExerciseAgreement = {},
		warrant_purchase_agreement = {},
		form_of_warrant = {},
		additional_documents = []
	} = props.data || {};

	// const additionalDocuments = [
	// 	{
	// 		name: '1.txt',
	// 		real_name: '2.txt'
	// 	},
	// 	{
	// 		name: '3.txt',
	// 		real_name: '4.txt'
	// 	}
	// ]

	const finalAdditionalDocs = additionalDocuments || additional_documents

	const getUrl = (name) => getRealUrlForFiles(name, 'dataroom', companyId)
	const addDocs = []
	finalAdditionalDocs.map((item) => {
		addDocs.push({
			name: item.name,
			url: item.real_name
		})
	})

	const optionConfig = [
		{
			label: __('Note purchase agreement'),
			value: purchaseAgreement.name,
			url: purchaseAgreement.real_name,
		},
		{
			label: __('Form of convertible file'),
			value: formOfConvertible.name,
			url: formOfConvertible.real_name,
		},
		{
			label: __('Additional document'),
			value: addDocs,
			type: 'additional'
		},
		// {
		// 	label: 'Notes',
		// 	value: notes
		// }
	];

	const eaOptionConfig = [
		{
			// label: 'Form of option agreement',
			label: __('Grant letter'),
			value: formOfOptionAgreement.name,
			url: formOfOptionAgreement.real_name,
		},
		{
			label: __('Form of exercise agreement'),
			value: formOfExerciseAgreement.name,
			url: formOfExerciseAgreement.real_name,
		},
		{
			label: __('Equity incentive plan'),
			value: equiityIncentivePlan.name,
			url: equiityIncentivePlan.real_name,
		},
		{
			label: __('Additional document'),
			value: addDocs,
			type: 'additional'
		}
	];

	const psOptionConfig = [
		{
			label: __('Additional document'),
			value: addDocs,
			type: 'additional'
		},
		// {
		// 	label: 'Notes',
		// 	value: notes
		// }
	];

	const warOptionConfig = [
		{
			label: __('Additional document'),
			value: addDocs,
			type: 'additional'
		},
		{
			label: __('Form of warrant'),
			value: form_of_warrant.name,
			url: form_of_warrant.real_name,
		},
		{
			label: __('Warrant purchase agreement'),
			value: warrant_purchase_agreement.name,
			url: warrant_purchase_agreement.real_name,
		},
	];

	const notesConfig = [
		{
			label: __('Notes'),
			value: notes,
		},
	];

	let finalOptions = '';
	switch (type) {
		case 'PS':
		case 'CS':
			finalOptions = psOptionConfig;
			break;
		case 'CN':
			finalOptions = optionConfig;
			break;
		case 'WARRANT':
			finalOptions = warOptionConfig;
			break;
		case 'EA':
		case 'RSA':
		case 'RSU':
		case 'ISO':
		case 'NSO':
		case 'INTL':
			finalOptions = eaOptionConfig;
			break;
		default:
			finalOptions = eaOptionConfig;
			break;
	}

	return <Fragment>
		<div className="certificate-detail-section">
			<LinkTable title={__('Documents')} config={finalOptions} rate="40%" />
			<LinkTable title={__('Notes')} config={notesConfig} rate="40%" />
		</div>
	</Fragment>
}