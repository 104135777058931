import React, { Component, Fragment } from "react";
import LeftModels from "./components/LeftModels";
import HeaderModels from "./components/HeaderModels";
import RightModels from "./components/RightModels";
import { __LANG } from '../Lang/en-us'
import './style.scss'
import {message} from "antd";
import { I } from 'Utils';
import { post } from 'srcPath/http';

class Detail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bodyHeight : parseInt($('body').height()) - 235,
            __LANG : __LANG(),
            stakeHolderList : [],
            shareClassList : [],
            prefix : [],
            breakPointsList : [],
            waterInfo : [],
            loading : true,
            saveLoading : false,
            dateFormat : 'DD/MM/YYYY',
            leftModule : null
        };
        this.getWaterFallDetail = this.getWaterFallDetail.bind(this);
    }


    /**
     * 初始化
     * @returns {Promise<void>}
     */
    componentDidMount() {
        const { detailId } = this.props;
        this.getWaterFallDetail(detailId).then(r => {});
    }

    /**
     * 获取waterfall详情页
     * @returns {Promise<void>}
     */
    async getWaterFallDetail (detailId) {
        try {
            this.setState({ loading: true });
            const { data: resData } = await post('getWaterFallDetail', { id : detailId})
            const { code, data } = resData;
            if (code === 0) {
                this.setState(()=>({
                    stakeHolderList : I(data.stakeholderInfo, []),
                    shareClassList : I(data.shareClassInfo, []),
                    breakPointsList : I(data.breakPoints, []),
                    prefix : this.initPrefix(data.prefix),
                    waterInfo : I(data.waterInfo, []),
                    loading : false,
                }));
            } else {
                message.error(data)
            }
        } catch (err) {
            this.setState({ loading: false });
            message.error(err && err.message)
        }
    }

    /**
     * 更新waterfall内容
     * @param data
     */
    saveWaterInfo = (data) => {
        const { waterInfo } = this.state;
        let info = {...waterInfo};
        for(const i in data){
            info[i] = data[i];
        }
        this.setState(() => ({
            waterInfo : info
        }));
    }

    /**
     * 初始化prefix
     * @param data
     * @returns {*[]}
     */
    initPrefix = (data) => {
        if(!I(data, '')){
            return [];
        }
        let result_array = [];
        for(let i in data){
            for(let j in data[i]){
                result_array.push(data[i][j]);
            }
        }
        return result_array;
    }

    getLeftModel = (obj) => {
        this.setState(()=> ({
            leftModule : obj
        }));
    }

    /**
     * 调用子集保存方法
     */
    saveWaterfall = () => {
        const { leftModule } = this.state;
        leftModule.saveWaterfall();
    }

    /**
     * 添加waterfall
     */
    addWaterfall = () => {
        const { leftModule } = this.state;
        leftModule.addWaterfall();
    }

    /**
     * 更新waterfall时的loading状态
     * @param value
     */
    waterfallLoadingChange = (value) => {
        this.setState(() => ({
            saveLoading : value
        }))
    }

    render() {
        const { bodyHeight, __LANG, prefix, breakPointsList, shareClassList, stakeHolderList, waterInfo, loading,
            saveLoading, dateFormat} = this.state;
        const { ...props } = this.props;
        return (
            <div className="waterfall-detail-root">
                <HeaderModels
                    waterInfo={waterInfo}
                    saveWaterfall={this.saveWaterfall}
                    addWaterfall={this.addWaterfall}
                    loading={loading}
                    saveLoading={saveLoading}
                    {...props}
                />
                <div className="waterfall-detail-content">
                    <LeftModels
                        bodyHeight={bodyHeight}
                        __LANG={__LANG}
                        dateFormat={dateFormat}
                        waterInfo={waterInfo}
                        getLeftModel={this.getLeftModel}
                        getWaterFallDetail={this.getWaterFallDetail}
                        loading={loading}
                        saveLoading={saveLoading}
                        saveWaterInfo={this.saveWaterInfo}
                        waterfallLoadingChange={this.waterfallLoadingChange}
                        {...props}
                    />
                    <RightModels
                        stakeHolderList={stakeHolderList}
                        shareClassList={shareClassList}
                        breakPointsList={breakPointsList}
                        prefix={prefix}
                        bodyHeight={bodyHeight}
                        __LANG={__LANG}
                        loading={loading}
                        {...props}
                    />
                </div>
            </div>
        );
    }
}

export default Detail;