import React, { Component } from "react";
import IconPdf from "../img/icon-pdf.png";
import IconHtml from "../img/icon-html.png";
import {Button, message, Upload} from "antd";
import Loading from 'components/Loading';
import { post } from 'srcPath/http';
import "../style.scss";

class TemplateAddChoice extends Component {
    docusignModel = null;
    /**
     * 进入富文本编辑页
     */
    showTemplateEdit = () => {
        const { updateActiveKey, close } = this.props;
        updateActiveKey(5, true);
        close();
    }

    /**
     * 获取文件地址
     * @param options
     */
    customRequest = (options) => {
        const { file:newFile } = options;
        const reader = new FileReader();
        reader.readAsDataURL(newFile);
        reader.onload = (file) => {
            const { result } = file.target;
            this.getDocusignUrl(newFile.name, result);
        }
    }

    /**
     * 获取docusign
     * @param name
     * @param file
     * @returns {Promise<void>}
     */
    getDocusignUrl = async (name, file) => {
        Loading.global(true);
        try {
            const { data: resData } = await post('createDocusignTemplate', {name, file})
            const { code, data } = resData;
            const { close, openDocusignModel } = this.props;
            if(code === 0) {
                close();
                setTimeout(()=>openDocusignModel(data), 500);
            } else {
                message.error(data)
            }
        } catch(err) {
            message.error(err && err.message)
        }
        Loading.global(false);
    }

    /**
     * 卸载
     */
    componentWillUnmount() {
        this.docusignModel && this.docusignModel.close();
    }

    render() {
        return (
            <div className="template-choice-root">
                <p>{__('You can create a new template or upload your own PDF. You can make any changes from within the editor.')}</p>
                <div className="template-choice-content">
                    <div className="template-choice-content-left">
                        <img src={IconHtml} />
                        <h5>{__('Create a new template')}</h5>
                        <p>&nbsp;</p>
                        <Button type="primary" onClick={this.showTemplateEdit}>{__('Create')}</Button>
                    </div>
                    <div className="template-choice-content-right">
                        <img src={IconPdf} />
                        <h5>{__('Upload your own')}</h5>
                        <p>{__('Upload your template as a PDF')}</p>
                        <Upload
                            className="template-choice-content-right-upload"
                            listType="picture-card"
                            showUploadList={false}
                            customRequest={this.customRequest}
                            accept=".pdf"
                        >{__('Upload')}</Upload>
                    </div>
                </div>
            </div>
        );
    }
}
export default TemplateAddChoice;