import React, { Component } from 'react';
import { Button } from 'antd';
import Chart from 'components/Echarts';
import SelectStakeholder from 'components/SelectStakeholder';
import CountrySelector from 'components/CountrySelector';
import security from 'stores/security';
import Drawer from 'components/Drawer';

import { DateCustomer } from 'components/DateSelector'

import './style.scss';

var option = {
    title: {
        text: 'ECharts demo'
    },
    legend: {},
    tooltip: {},
    dataset: {
        source: [
            ['product', '2015', '2016', '2017'],
            ['Matcha Latte', 43.3, 85.8, 93.7],
            ['Milk Tea', 83.1, 73.4, 55.1],
            ['Cheese Cocoa', 86.4, 65.2, 0.5],
            ['Walnut Brownie', 72.4, 53.9, 39.1]
        ]
    },
    xAxis: {
        type: 'category',
        axisTick: {
            show: false
        }
    },
    yAxis: {
        axisTick: {
            show: false
        }        
    },
    series: [
        {type: 'bar', barMaxWidth: 20},
        {type: 'bar', barMaxWidth: 20},
        {type: 'bar', barMaxWidth: 20}
    ] 
};

export default class Summary extends Component {
	state = {
		option: option,
        selectedData: undefined
	}

	change = () => {
		const option = {...this.state.option}
		option.title.text = 'test' + Math.random()

		this.setState({ option })
	}

    handleSelectChange = (data) => {
        this.setState({
            selectedData: data
        })
    }

    openDrawer = () => { 
        Drawer.open({
            component: props => <div>test</div>,
            title: 'test',
            closable: true,
        })
    }


	render() {
        const { selectedData } = this.state

		return <div className="test-root">
			<div><Button type="primary" onClick={() => { this.props.history.push('/') }}>Back</Button></div>
            <br />
            <Button type="primary" onClick={() => this.openDrawer()}>Drawer</Button>
			<Button onClick={this.change} >change</Button>
			<Chart option={this.state.option} chartWidth={600} />
            {/*<SelectStakeholder onChange={this.handleSelectChange}/>*/}
            <CountrySelector style={{ width: 200 }} onChange={this.handleSelectChange} />
            {/*<Chart option={this.state.option} chartWidth={600} />*/}
            <SelectStakeholder onChange={this.handleSelectChange} />
            <DateCustomer customerValue={-3} onChange={this.handleSelectChange} footerText="hahah" />
            <span>SelectedStakeholder:</span>
            <pre>{JSON.stringify(selectedData, null, 2)}</pre>
		</div>
	}
}
