import React, { Component } from "react";
import signupPlanImg from "assets/images/signup-plan-2.png";
import "../style.scss"
import {Button} from "antd";
class SignupPlan extends Component {

    /**
     * 跳转到 signup 流程里
     */
    getSignup = () => {
        const { getLink } = this.props;
        getLink();
    }

    render() {
        return (
            <div className="plan-message-root">
                <img src={signupPlanImg} />
                <h2>Thank you for choosing Folium!</h2>
                <Button type="primary" onClick={this.getSignup}>Complete account activation</Button>
                <p>If you are still waiting for our sales team’s manual activation,
                    <br/>please contact us at <a href="mailto:support@getsprout.co">support@getsprout.co</a>.
                </p>
            </div>
        );
    }
}
export default SignupPlan;