import React, { Component } from 'react';
import { Select, message, Spin } from 'antd';
import { post } from 'srcPath/http';
import { debounce, omit } from 'lodash';

const Option = Select.Option;

class SelectStakeholder extends Component {
    state = {
        options: [],
        loading: false,
        getTimeStap: + new Date(),
        symbol: ''

    };
    componentDidMount(){
        this.getData('')
    }
    handleSearch = symbol => {
        this.setState({ symbol })

        if (symbol) {
            this.getData(symbol);
        } else {
            this.setState({ options: [] })
        }
    };

    onChange = id => {
        const { options } = this.state;
        const { onChange, isEmail=false } = this.props;
        onChange && onChange(
            id,
            options.find(item => item.id === id)
        );
    };

    onSelect = id => {
        const { options } = this.state;
        const { onSelect } = this.props;
        onSelect &&
            onSelect(
                id,
                options.find(item => item.id === id)
            );
    };

    getData = debounce(async val => {
        this.setState({ loading: true });
        const { add=true , isEmail=false } = this.props;

        try {
            const { data: resData } = await post(isEmail ? 'getStakeholderByMail' : 'getMailByStakeholder', {
                keyword: val
            })

            const { code, data = [] } = resData || {}
            if (code === 0) {
                if (data) {
                    this.setState({ options: (data && data.length) > 0 ? data : add ? [{ nick_name: this.state.symbol, id: 0 }] : [] })
                }
            } else {
                message.error(data)
            }
        } catch (error) {
            message.error(error.message);
        }

        this.setState({ loading: false });
    }, 300);

    render() {
        const { options = [], loading, getTimeStap } = this.state;
        const { isEmail = false } = this.props;
        
        return (
            <Select
                {...omit(this.props, 'add')}
                defaultActiveFirstOption={true}
                filterOption={false}
                style={{ width: '100%' }}
                showSearch
                // allowClear
                onSearch={this.handleSearch}
                showArrow={false}
                onChange={this.onChange}
                onSelect={this.onSelect}
                dropdownMatchSelectWidth={false}
                optionLabelProp="label"
                notFoundContent={loading ? <Spin size="small" /> : 'No data'}
            >
                {options.map((item, index) => (
                    <Option key={getTimeStap + index} value={item.id} label={isEmail ? item.email : item.nick_name}>
                        <div style={{ display: 'flex', maxWidth: 600, justifyContent: 'space-between' }}>
                            {/*<div style={{ display: 'flex', maxWidth: 600, justifyContent: 'space-between' }}>*/}
                            {/*<div style={{ color: '#1890ff', minWidth: 80 }}>{item.nick_name}</div>*/}
                            <div style={{ minWidth: 80, marginRight: 10 }}>{item.nick_name}</div>
                            <div style={{ color: '#1890ff' }} className="text-left whitespace-normal">{item.email}</div>
                        </div>
                    </Option>
                ))}
            </Select>
        );
    }
}

export default SelectStakeholder;
