import React, { Component } from 'react';
import { FormItem, withForm } from 'react-antd-formutil';
import Section from '../Section';
import { Input, Select, Button, Spin, message } from 'antd';
import { post } from 'srcPath/http';
import security from 'stores/security';
import './style.scss';

const { Option, OptGroup } = Select

const optionList = [
	{
		label: __('Incorrect security information'),
		list: [
			{
				label: __('Converted/expired securities still reflected as outstanding'),
				value: '3-1'
			},
			{
				label: __('Incorrect name'),
				value: '3-2'
			},
			{
				label: __('Incorrect security details (principle, cost, amount, issue date)'),
				value: '3-3'
			},
		]
	},
	{
		label: __('Missing securities'),
		list: [
			{
				label: __('Securities canceled due to partial transfer but not reissued'),
				value: '4-1'
			},
			{
				label: __('Securities canceled without clarification'),
				value: '4-2'
			},
		]
	},
	{
		label: __('Incorrect cap table details'),
		list: [
			{
				label: __('Incorrect rights & perferences'),
				value: '2-1'
			},
		]
	},
	{
		label: __('Exit(transaction)'),
		list: [
			{
				label: __('Dissolution'),
				value: '1-1'
			},
			{
				label: __('IPO'),
				value: '1-2'
			},
			{
				label: __('M&A'),
				value: '1-3'
			},
			{
				label: __('Secondary transaction'),
				value: '1-4'
			},
		]
	},
	{
		label: __('Other'),
		list: [
			{
				label: __('Other'),
				value: '5-1'
			}
		]
	},
]
@withForm
class ReportIssue extends Component {
	state = {
		loading: false
	}

	onSubmit = async () => {
		const { $formutil, callback } = this.props
		const {  $invalid, $batchDirty, $params, $getFirstError } = $formutil

		if ($invalid) {
			$batchDirty(true)
			message.error($getFirstError())

			return
		}

		try {
			const { data: resData } = await post('stakeholderIssue', { ...$params }) 
			const { data, code } = resData

			if (code === 0) {
				message.success(__('Success'))
			}

		} catch(e) {
			message.error(e.message)
		}
		
		callback && callback()
	}

	render() {
		const { certificateName, certificateId, hideHeader } = this.props
		const { loading } = this.state

		return <Section title={__("Report Issue")} hideHeader={hideHeader}>
			<Spin spinning={loading}>
				<div className="sprout-report-issue">
					<FormItem
						name="reason"
						colon={false}
						itemProps={{
							label: __('Reason')
						}}
						required
						validMessage={{
							required: __('Reason is required')
						}}
					>
						<Select>
							{optionList.map((option) => (
								<OptGroup key={option.label} label={option.label}>
									{option.list.map((item) => (
										<Option key={item.value} value={item.value}>{item.label}</Option>
									))}
							  </OptGroup>
							))}
						</Select>
					</FormItem>

					<FormItem 
						name="message"
						colon={false}
						itemProps={{
							label: __('Message')
						}}
						required
						validMessage={{
							required: __('Message is required')
						}}
					>
						<Input.TextArea 
							autoSize={{ minRows: 4 }}
						/>
					</FormItem>
					<div className="sprout-report-issue-btns">
						<Button width="440px" type="primary" onClick={this.onSubmit}>{__('Send')}</Button>
					</div>
				</div>
			</Spin>
		</Section>
	}
}

export default ReportIssue