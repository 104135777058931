import React, { Component } from "react";
import moment from "moment";
import {Button, Dropdown, Menu, Space} from "antd";
import {CloseOutlined, DownOutlined} from "@ant-design/icons";
import Utils, { I, formatWithArray } from "Utils";
import { formatDate } from 'Utils/format/formatDate'
import {__LANG} from '../../Lang/en-us';

class HeaderModels extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dateFormat : 'DD/MM/YYYY',
            __LANG : __LANG(),
            loading : false,
        }
    }

    exportMenu = () => {
        return <Menu style={{width:'150px'}}>
            <Menu.Item >
                <span>Export to PDF</span>
            </Menu.Item>
            <Menu.Item >
                <span>Export to Excel</span>
            </Menu.Item>
        </Menu>
    }

    saveMenu = () => {
        return <Menu style={{width:'150px'}}>
            <Menu.Item onClick={this.saveWaterfall}>
                <span>{__('Save')}</span>
            </Menu.Item>
            <Menu.Item onClick={this.addWaterfall}>
                <span>{__('Save as new')}</span>
            </Menu.Item>
        </Menu>
    }

    closeClick = () => {
        const { detailIdChange, getWaterFallList } = this.props;
        getWaterFallList();
        detailIdChange(0);
    }

    /**
     * 获取日期 默认格式 DD/MM/YYYY
     */
    dateTime = ( dateFormat = 'DD/MM/YYYY') => {
        const appendZero = (obj) => {
            return obj < 10 ? "0" +""+ obj : obj;
        }
        const date = new Date();
        const year = date.getFullYear();
        const month = appendZero( ((date.getMonth()) + 1) ) ;
        const day = appendZero( date.getDate() );
        const replacements = {YYYY:year, MM:month, DD:day};
        for( let i in replacements){
            dateFormat = dateFormat.replace(i, replacements[i]);
        }
        return dateFormat;
    }

    /**
     * 修改waterfall
     */
    saveWaterfall = () => {
        const { saveWaterfall } = this.props;
        saveWaterfall();
    }

    /**
     * 添加waterfall
     */
    addWaterfall = () => {
        const { addWaterfall, getWaterFallList } = this.props;
        addWaterfall();
    }

    render() {
        const { __LANG } = this.state;
        const { waterInfo, saveLoading } = this.props;

        const title = ( I(waterInfo.exit_value, '') && __LANG.amountSymbol + formatWithArray(waterInfo.exit_value) ) +
            (I(waterInfo.exit_date, '') && (` ${__('exit for')} ` + formatDate( I(waterInfo.exit_date, '') ) ) );
        return (
            <div className="waterfall-header-box">
                <div className="edit_box_remove_side">
                    <div className="financing_round_edit_dl">
                        <div className="edit_box_title">
                            <h2>{title}</h2>
                            <small>{__('Created by')} {Utils.getLocalCompanyInfo().nick_name} on { formatDate( I(waterInfo.exit_date, '') ) }.</small>
                        </div>
                        <div className="edit_box_content">
                            <Space size={5}>
                            {/*<Dropdown overlay={this.exportMenu} trigger={['click']}>*/}
                            {/*    <Button loading={ false } type="primary" className="edit_box_button share-button">Export<DownOutlined className="edit_box_button_symbol" /></Button>*/}
                            {/*</Dropdown>*/}
                            {/*<Button loading={ false } type="primary" className="edit_box_button share-button">Share</Button>*/}
                            <Dropdown  overlay={this.saveMenu} trigger={['click']}>
                                <Button loading={saveLoading} type="primary" className="edit_box_button">{__('Save')}<DownOutlined className="edit_box_button_symbol" /></Button>
                            </Dropdown>
                            <CloseOutlined className="edit_box_close" onClick={this.closeClick} />
                            </Space>
                        </div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
        );
    }
}
export default HeaderModels