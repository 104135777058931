import React, { useEffect } from 'react';
import { withForm, FormItem } from 'react-antd-formutil';
import Modal, { ModalContent } from 'components/Modal';
import { Button, message, Input, Space } from 'antd';
import Loading from 'components/Loading';
import { post } from 'srcPath/http';

import './style.scss';

function AssignToken(props) {
    const { close, stakeholderID = '', callback, $formutil } = props
    const { $invalid, $batchDirty, $params, $getFirstError } = $formutil;

    const [type, id] = stakeholderID.split('-')
    // stakeholder-2429
    // label-12
    const submit = async () => {
		if ($invalid) {
			$batchDirty(true)

			message.error($getFirstError())
			
			return
		}

        const api = type === 'label' ? 'addOneAddressToWalletLabel' : 'addOneAddressToStakeholder'

		Loading.global(true)

		try {
			const { data: resData } =  await post(api, {
                ...(type === 'label' ? { wallet_label_id: id } : { stakeholder_id: id }),
                ...$params
			})

			const { code, data } = resData || {}

            if (code === 0) {
				close()
				callback && callback()
			} else {
				message.error(data)
			}
		} catch (e) {
			message.error(e.message)
		}

		Loading.global(false)
    }

    return <div className="plan-assign-token">
        <FormItem
            name="wallet_address"
            label={__("Wallet Address")}
            required
            validMessage={{
                required: __('Wallet Address is required')
            }}
        >
            <Input />
        </FormItem>
        <div className="plan-assign-token-btn">
            <Space>
                <Button onClick={() => close()}>{__('Cancel')}</Button>
                <Button type="primary" onClick={() => submit()}>{__('Submit')}</Button>
            </Space>
        </div>
    </div>
}

const AssignTokenContent = withForm(AssignToken)

export default function openAssignToken(stakeholderID, callback) { 
    return Modal.open({
        component: props => <ModalContent close={props.close} title={__("Add new token")}>
            <AssignTokenContent close={props.close} stakeholderID={stakeholderID} callback={callback} />
        </ModalContent>,
        className: 'sprout-modal',
        width: 600
    })
}