import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Convertible from './Convertible';
import DraftConvertible from './DraftConvertible';

import security from 'stores/security';

export default class Convertibles extends Component {
    path = this.props.match.path

    config = [
        {
            path: `${this.path}`,
            component: Convertible,
            permission: 'securities_convertibles',
            exact: true,
        },
        {
            path: `${this.path}/draft-convertible`,
            component: DraftConvertible,
            permission: 'securities_draftConvertibles',
            exact: true,
        },
    ]

    render() {
        return <div className="sprout-captable-root">
            <Switch>
                {
                    this.config.map((val, index) => security.hasPermission(val.permission) && <Route key={index} exact={val.exact} path={val.path} component={val.component} />)
                }
            </Switch>
        </div>
    }
}