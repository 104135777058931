import React, { Component } from "react";
import { Table } from 'antd';
import { stakeHoldersConfig } from './TableColumnsConfig';
class ByStakeholderModels extends Component {

    render() {

        const { stakeHolderList, prefix, loading } = this.props;
        return (
            <Table
                loading={loading}
                className="waterfall-stakeholders-table"
                size="middle"
                pagination={false}
                columns={stakeHoldersConfig(prefix)}
                dataSource={stakeHolderList}
                rowKey="keyId"
            />
        );
    }
}
export default ByStakeholderModels;