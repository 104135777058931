import React from 'react';
import 'assets/css/stakeholders.css';
import { post } from 'srcPath/http';
import { Link } from "react-router-dom";
import { I } from 'Utils';
import 'assets/css/securities.css';
import 'assets/css/equityawards.css';
import Loading from 'components/Loading';
import { Button, Dropdown, Input, Menu, message, Badge } from 'antd';
import ModifyOptionGrant from "../EquityAwardsModal/ModifyOptionGrant";
import ExerciseOptions from "../EquityAwardsModal/ExerciseOptions";
import RequestRepricing from "../EquityAwardsModal/RequestRepricing";
import AddVestingSchedule from "../../components/AddVestingSchedule";
import CreateEquityAwards from "../EquityAwardsModal/CreateEquityAwards";
import EditEquityPlan from "../EquityAwardsModal/EditEquityPlan";
import AddAdminNotes from "../../components/AddAdminNotes";
import CancelModal from "../../components/CancelModal";
import VestMilestones from "../EquityAwardsModal/VestMilestone";
import RepurchaseShares from "../../Shares/SharesModal/RepurchaseShares";
import PurchaseRSA from "../EquityAwardsModal/PurchaseRSA";
import ModifyVestingCondition from "../EquityAwardsModal/ModifyVestingCondition";
import AccelerateVesting from "../EquityAwardsModal/AccelerateVesting";
import Permission from 'components/Permission';
import TerminateStakeholder from "../../../Stakeholder/Stakeholder/StakeholdersModal/TerminateStakeholder";
import OptionTable from "./components/OptionTable";
import { CloseOutlined, DownOutlined, SearchOutlined } from "@ant-design/icons";
import security from "../../../../../stores/security";
import ColumnController, { columnsFilter } from "components/ColumnController";
import reverseTerminationModal from "../../components/ReverseTermination";
import Filter from 'components/Filter';
import { printf } from 'Utils/i18n';

const orderMap = {
    null: 0,
    descend: 3,
    ascend: 4,
    undefined: 0
}

class OptionsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 1,
            optionPageSize: 100,
            converting: 1,
            vestingScheduleList: [],
            formatScheduleList: {},
            listName: "",
            isShowCancelTip: 0,
            canBeCanceled: 1,
            eaPlanList: [],
            formatEaPlanList: {},
            currency: "",
            curSelectSecurity: {
                key: 0,
                id: 2,
                custom_label_id: 'EA-666',
                name: 'Cody Belseager',
                status: 'Outstanding',
                quantity: '100',
                exercised_quantity: '100',
                vested_quantity: "200",
                vested: '100',
                vested_bar: "",
                exercise_price: '',
                exercise_history: [],

                payment_date: "",
                amount_to_be_collected: 0,
                payment_collected: "",
                actual_exercise_price: 0,
                payment_verified_admin_id: "",
                amount_reimbursed: "",
                amount_reimbursement_date: "",

                security_type: 'IOS',
                grant_date: '12/12/2019',
                last_modified: "12/12/2019",
                schedule: "No vesting schedule",
                price_per_share: '$1.00',
                transaction_value: '$100.00',
                issue_date: '12/12/2019',
                checkbox_status: false,
                sign_status: 'officerSignature',
                custom_label: "",
                grant_expiration_date: "",
                board_approval_date: "",
                currency: "",
                option_plan: "",
                notes: "",
                voluntary_termination_count: "",
                voluntary_termination_period: "",
                involuntary_termination_count: "",
                involuntary_termination_period: "",
                involuntary_termination_cause_count: "",
                involuntary_termination_cause_period: "",
                death_exercise_count: "",
                death_exercise_period: "",
                disability_exercise_count: "",
                disability_exercise_period: "",
                retirement_exercise_count: "",
                retirement_exercise_period: "",
                iso_disqualification_date: "",
                national_exemption: "",
                local_exemption: "",
                admin_note: "",
                form_of_grant_file: { name: "", real_name: "" },
                form_of_option_file: { name: "", real_name: "" },
                incentive_plan_file: { name: "", real_name: "" },
                additional_documents: { name: "", real_name: "" },
                form_of_exercise_file: { name: "", real_name: "" },
                purchase_agreement_file: { name: "", real_name: "" },
                form_of_settlement_agreement_file: { name: "", real_name: "" },

            },
            optionsList: [],
            formatVerifiedAdminList: {},
            verifiedAdminList: [],
            filterName: {
                name: "",
                dateKey: "",

            },
            pageData: {
                name: "Option Grant",
                title: "Option grant",
                text: "option grant",
                active_cancel_name: "Option",
            },
            optionsCount: 0,
            selectIndex: 0,
            selectId: 0,
            tableChild: {},
            searchValue: '',
            searchOptionsList: [],
            draftOptionCount: '',
            tableColumn: [],
            redisKey: 'eaOptions',
            award_category: [],
            // 翻页参数，搜索条件
            total: 0,
            pageNumber: 1,
            pageSize: 10,
            filter: {},
            sort: {},
            checkedIds:[]
        }
    }
    componentDidMount() {
        this.getEaList();
        this.getPaymentAdmin();

    }
    setSelectId = (id) => {
        this.setState({
            selectId: id
        })
    }
    getEaList = () => {
        let pageSize = localStorage.getItem('optionPageSize') || this.state.pageSize;
        this.setState({
            pageSize,
            loading: true,
        })
        let { pageNumber, filter, searchValue, sort } = this.state;
        Loading.global(true)
        post('getEaList', { ea_type: 'option', pageSize, pageNumber, filter, search: searchValue, sort: { ...sort, type: orderMap[sort?.type] || 0 } }).then((response) => {
            if (response.data.code === 0) {
                let formatScheduleList = this.state.formatScheduleList;
                for (let i = 0; i < response.data.data.vestingScheduleList.length; i++) {
                    formatScheduleList[response.data.data.vestingScheduleList[i].id] = response.data.data.vestingScheduleList[i];
                }
                let formatEaPlanList = this.state.formatEaPlanList;
                for (let j = 0; j < response.data.data.eaPlanList.length; j++) {
                    formatEaPlanList[response.data.data.eaPlanList[j].id] = response.data.data.eaPlanList[j];
                }
                let searchList = [...response.data.data.optionsList];
                let searchRes = [];
                searchList.map( ( v, k) => {
                    if( v ){
                        searchRes.push(v);
                    }
                });
                for (let i = 0; i < searchRes.length; i++) {
                    searchRes[i].checkbox_status = false;
                }
                this.setState({
                    optionPageSize: pageSize,
                    total: response.data.data.total,
                    optionsList: searchRes || [],
                    searchOptionList: searchRes || [],
                    vestingScheduleList: response.data.data.vestingScheduleList,
                    formatScheduleList: formatScheduleList,
                    formatEaPlanList: formatEaPlanList,
                    eaPlanList: response.data.data.eaPlanList,
                    currency: response.data.data.currency,
                    draftOptionCount: response.data.data.draftOptionCount,
                    award_category: response.data.data.award_category,
                    filter,
                    loading: false
                })
                this.clear();
            } else {
                this.setState({ loading: false })
                console.log("failed, error code is: " + response.data.code);
            }

            Loading.global(false)
        }).catch((error) => {
            message.error(error.message)
            Loading.global(false)
            this.setState({ loading: false })
        });
    }

    getPaymentAdmin = () => {
        let company_id = security.companyInfo.company_id;
        Loading.global(true)
        post('getPaymentAdmin', company_id).then((response) => {
            if (response.data.code === 0) {
                let formatVerifiedAdminList = this.state.formatVerifiedAdminList;
                response.data.data.map((v, k) => {
                    formatVerifiedAdminList[v.admin_id] = response.data.data[k];
                })
                this.setState({
                    verifiedAdminList: response.data.data,
                    formatVerifiedAdminList
                })
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
            Loading.global(false)
        }).catch(function (error) {
            console.log(error);
            Loading.global(false)
        });
    }
    clearInput = () => {
        this.refs.inputContent.value = "";
    }

    changeFilterInfo = (key) => {
        let curFilterName = this.state.filterName;
        curFilterName.name = key;
        this.setState({
            filterName: curFilterName,
        })
    }

    setModifiedSecurity = (key, security) => {
        let curSecurityList = this.state.optionsList;
        curSecurityList[key] = security;
        this.setState({
            optionsList: curSecurityList,
            searchOptionList: curSecurityList,
        })
    }

    setDataForModifiedSecurity = (id, security) => {
        let curSecurityLists = this.state.optionsList;
        curSecurityLists.forEach((v, k) => {
            if (v.id == id) {
                curSecurityLists[k] = security;
            }
        })
        this.setState({
            optionsList: curSecurityLists,
            searchSecurityLists: curSecurityLists
        })
    }

    //resetSecurityStatus
    resetSecurityStatus = (id, status, type = 0) => {
        let optionsList = this.state.optionsList;
        optionsList.forEach((v, k) => {
            if (v.id == id) {
                v.status = status;
                if (type == 1) {
                    v.termination_date = ""
                }
            }
        })
        this.setState({
            optionsList: optionsList
        })

    }

    //resetCancel
    resetCancel = (id, status, cancellation_date) => {
        let securityLists = this.state.optionsList;
        securityLists.forEach((v, k) => {
            if (v.id == id) {
                v.status = status;
                v.cancellation_date = cancellation_date
            }
        })
        this.setState({
            optionsList: securityLists
        })
    }

    setCurSelectSecurityNew = (id) => {
        const { optionsList } = this.state;
        let info = {};
        optionsList.forEach((v, k) => {
            if (v.id == id) {
                info = { ...v }
            }
        });
        this.setState({
            curSelectSecurity: info
        });
    }

    saveState = (key, value) => {
        let data = {};
        data[key] = value;
        this.setState(data)
    }

    loadCreatePlanPage = () => {
        this.refs.createEaPlan.initPageData();
    }

    reverseCancellation = () => {
        var requestData = {};
        requestData.id = this.state.curSelectSecurity.id;
        requestData.security_type = this.state.curSelectSecurity.security_type;
        Loading.global(true)
        post('reverseCancellation', requestData).then((response) => {
            if (response.data.code === 0) {
                this.resetSecurityStatus(requestData.id, 'Outstanding')
                message.success(__("success! "))
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
            Loading.global(false)
        }).catch(function (error) {
            console.log(error);
            Loading.global(false)
        });
    }

    checkSecurityStatus = () => {
        let curList = this.state.optionsList;
        let flag = 1;
        for (let i = 0; i < curList.length; i++) {
            if (curList[i].checkbox_status == true && curList[i].status != "Outstanding") {
                flag = 0;
                break;
            }
        }
        if (flag == 0) {
            this.setState({
                canBeCanceled: 0
            })
        }else{
            this.setState({
                canBeCanceled: 1
            })
        }

    }
    changeCancelNum = () => {
        const { optionsList, checkedIds } = this.state;
        this.refs.cancelModal.changeCancelNum();
        if(optionsList)
        for(let i=0; i<optionsList.length; i++){
            if(optionsList[i].checkbox_status){
                checkedIds.push(optionsList[i].id)
            }
        }
        this.setState({
            checkedIds
        })
    }

    resendEmail = () => {
        if (this.state.sendingStatus === 1) {
            return;
        }
        this.setState({
            sendingStatus: 1
        });
        Loading.global(true)
        post('sendEmail2Signatories', { security_id: this.state.curSelectSecurity.id }).then((response) => {
            if (response.data.code === 0) {
                message.success(__('Resent successfully!'));
                this.setState({
                    sendingStatus: 0
                });
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
            Loading.global(false)
        }).catch(function (error) {
            console.log(error);
            Loading.global(false)
        });
    }
    convertToShares = () => {
        Loading.global(true)
        post('convertEa', { id: this.state.curSelectSecurity.id }).then((response) => {
            if (response.data.code === 0) {
                message.success(__('convert successfully!'));
                this.getEaList();
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
            Loading.global(false)
        }).catch(function (error) {
            console.log(error);
            Loading.global(false)
        });
    }
    refreshData = (id) => {
        let curData = this.state.optionsList;
        for (let i = 0; i < this.state.optionsList.length; i++) {
            if (curData[i].id == id) {
                curData[i].status = "Outstanding"
            }
        }
        this.setState({
            optionsList: curData,
            searchOptionList: curData,
        })
    }
    setStatusToRepurchased = (id) => {
        let curData = this.state.optionsList;
        for (let i = 0; i < this.state.optionsList.length; i++) {
            if (curData[i].id == id) {
                curData[i].status = "Repurchased"
            }
        }
        this.setState({
            optionsList: curData,
            searchOptionList: curData,
        })
    }
    resetVestingName = (id, vestingName, vestingId) => {
        let curData = this.state.optionsList;
        for (let i = 0; i < this.state.optionsList.length; i++) {
            if (curData[i].id == id) {
                if (vestingId != 0) {
                    curData[i].vesting_schedule_id = vestingId;
                    curData[i].vesting_schedule = this.state.formatScheduleList[vestingId].schedule_name
                } else {
                    curData[i].vesting_schedule = vestingName;
                }
            }
        }
        this.setState({
            optionsList: curData,
            searchOptionList: curData,
        })
    }
    getQuantityToSettled = () => {
        this.refs.settleRsu.getQuantityToSettled();
    }
    getQuantityToExercise = (id) => {
        this.refs.exerciseOption.getQuantityToExercise(id);
    }
    getVestMilestone = () => {
        this.refs.vestMilestone.getMilestoneList();
    }
    resetListName = (value) => {
        this.setState({
            listName: value
        })
        if (this.state.curSelectSecurity.schedule_name != "") {
            this.getVestingData();
        }
    }
    getVestingData = () => {
        this.refs.addVesting.getVestingData();
    }

    getInitVestingList = () => {
        this.refs.acceleratedVesting.getInitVestingList();
        this.refs.acceleratedVesting.setDefaultDate();
        // this.refs.acceleratedVesting.availableQuantityCalculation();
    }
    setInitShareNote = () => {
        this.refs.addSharesAdminNoteEa.setInitNote();
        this.refs.addSharesAdminNoteEa.setListName();

    }

    /**
     * reverse termination
     */
    reverseTermination = (record, id) => {
        reverseTerminationModal(record, id, this.getEaList)
    }

    /**
     * 清空选中效果
     */
    clear = () => {
        const { optionsList, tableChild } = this.state;
        const list = [...optionsList];
        list.forEach((v, k) => {
            list[k]['checkbox_status'] = false;
        })
        this.setState({
            optionsList: list,
            searchOptionList: list,
            optionsCount: 0,
            selectIndex: 0
        })
        tableChild?.clearAll?.();
    }

    /**
     * Actions按钮
     * @returns {JSX.Element}
     */
    getActionsBottom = () => {
        const { optionsCount, canBeCanceled} = this.state;
        const modifyDisable = optionsCount == 1 ? false : true;
        const cancelDisable = optionsCount > 0 && canBeCanceled == 1 ? false : true
        return <Dropdown disabled={modifyDisable && cancelDisable ? true : false} overlay={this.actionsMenu} placement="bottomRight" trigger="click">
            <Button
                type="primary"
                className="shares-actions"
            ><div className="actions-left">{__('Actions')}</div><DownOutlined className="actions-right" /></Button>
        </Dropdown>
    }

    /**
     * Actions菜单
     * @returns {JSX.Element}
     */
    actionsMenu = () => {
        const { canBeCanceled, optionsCount, selectIndex: indexArray, selectId } = this.state;
        return (
            <Menu>
                {optionsCount == 1 && <Menu.Item onClick={this.setCurSelectSecurityNew.bind(this, selectId)}><a data-toggle="modal" data-target="#modifyOptionsModal">{__('Modify option grants')}</a></Menu.Item>}
                {canBeCanceled == 1 && <Menu.Item ><a data-toggle="modal" onClick={this.changeCancelNum} data-target="#cancelModal">{__('Cancel option grants')}</a></Menu.Item>}
            </Menu>
        )
    }

    /**
     * 更新表格行样式
     * @param index 行下标
     * @param status 样式状态 true, false
     */
    updateShowMsgStatus = (index, status) => {
        const { optionsList } = this.state;
        let list = [...optionsList];
        list[index]['showMsgStatus'] = status;
        this.setState(() => ({
            optionsList: list,
        }))
    }

    /**
     * 更新table checkbox
     * @param selectCount 选中总计
     * @param selectIndexArray 有更新的checkbox下标和状态
     * @param isEmpty checkbox是否为全不选状态
     */
    updateSelectCount = (optionsCount, selectIndexArray, isEmpty = false) => {
        const { optionsList } = this.state;
        let list = [...optionsList];
        if (isEmpty) {
            list.forEach((v, k) => {
                list[k]['checkbox_status'] = false;
            })
        } else {
            selectIndexArray.forEach((v, k) => {
                list[v.selectIndex]['checkbox_status'] = v.checked;
            })
        }
        this.setState(() => ({
            optionsCount,
            selectIndex: selectIndexArray[0],
            optionsList: list,
        }))
    }

    tableObj = (ref) => {
        this.setState(() => ({
            tableChild: ref
        }))
    }

    /**
     * 搜索列表数据
     * @param e
     */
    searchList = (e) => {
        const { value } = e.target;
        this.setState({
            searchValue: value,
        })
    }

    /**
     * 清空搜索内容
     */
    closeSearch = () => {
        this.setState({
            searchValue: '',
        },()=>this.getEaList());
    }

    /**
     * column 排序
     * @param sort
     */
    sortTable = sort => {
        this.setState({ sort }, ()=> this.getEaList())
    }

    setPageSize = (size) => {
        this.setState({
            optionPageSize: size
        })
    }

    onPageChange = (current, pageSize) => {
        localStorage.setItem('optionPageSize', pageSize);
        this.setState({
            pageNumber: pageSize === this.state.pageSize ? current : 1,
            pageSize
        })
        // }, () => this.getEaList())
    }
    updateState = data => this.setState(data);
    // 筛选条件逻辑，如果有分页，需要把查询条件保存在state 供分页使用
    onFilterChange = (value = {}) => {
        if (JSON.stringify(value) !== JSON.stringify(this.state.filter)) {
            this.setState({
                filter: value,
                pageNumber: 1
            }, () => this.getEaList())
        }
    }

    render() {
        let optionsCount = 0;
        for (let k = 0; k < this.state.optionsList.length; k++) {
            if (this.state.optionsList[k].checkbox_status === true) {
                optionsCount++;
            }
        }
        const { currency = '', optionsList, curSelectSecurity, searchValue, draftOptionCount, tableColumn, redisKey, total, pageSize, pageNumber, sort, loading, eaPlanList } = this.state;
        return (
            <div className="content option_con">
                <TerminateStakeholder eaPlanList={eaPlanList} getTerminatedList={this.getEaList} setModifiedSecurity={this.setModifiedSecurity} curSelectStakeholder={this.state.curSelectSecurity} />
                <EditEquityPlan eaPlanList={eaPlanList} ref={"editEquityPlan"} getEaList={this.getEaList} />
                <ModifyOptionGrant verifiedAdminList={this.state.verifiedAdminList} formatVerifiedAdminList={this.state.formatVerifiedAdminList} currency={this.state.currency} eaPlanList={eaPlanList} formatEaPlanList={this.state.formatEaPlanList} setModifiedSecurity={this.setModifiedSecurity} ref="modifyOption" curSelectSecurity={this.state.curSelectSecurity} getEaList={this.getEaList} award_category={this.state.award_category} history={this.props.history} />
                <AddVestingSchedule getSecurityList={this.getEaList} ref={"addVesting"} listName={this.state.listName} resetVestingName={this.resetVestingName} vestingScheduleList={this.state.vestingScheduleList} curSelectSecurity={this.state.curSelectSecurity} />
                <ModifyVestingCondition curSelectSecurity={this.state.curSelectSecurity} />
                <AccelerateVesting listName={this.state.listName} ref={"acceleratedVesting"} curSelectSecurity={this.state.curSelectSecurity} getData={this.getEaList} />
                <ExerciseOptions formatVerifiedAdminList={this.state.formatVerifiedAdminList} verifiedAdminList={this.state.verifiedAdminList} pageData={this.state.pageData} ref="exerciseOption" curSelectSecurity={this.state.curSelectSecurity} getEaList={this.getEaList} />
                <CancelModal resetCancel={this.resetCancel} checkedIds={this.state.checkedIds} currency={this.state.currency} ref="cancelModal" setModifiedSecurity={this.setModifiedSecurity} dateOfIncorporation={this.state.dateOfIncorporation} securityList={this.state.optionsList} pageData={this.state.pageData} curSelectSecurity={this.state.curSelectSecurity} getData={this.getEaList} />

                <RequestRepricing currency={this.state.currency} />
                <CreateEquityAwards ref="createEaPlan" />
                <VestMilestones listName={this.state.listName} ref="vestMilestone" curSelectSecurity={this.state.curSelectSecurity} />
                <AddAdminNotes setModifiedSecurity={this.setModifiedSecurity} setDataForModifiedSecurity={this.setDataForModifiedSecurity} ref="addSharesAdminNoteEa" curSelectSecurity={this.state.curSelectSecurity} />
                <RepurchaseShares currency={this.state.currency} setStatusToRepurchased={this.setStatusToRepurchased} listName={this.state.listName} setModifiedSecurity={this.setModifiedSecurity} curSelectSecurity={this.state.curSelectSecurity} />
                <PurchaseRSA currency={this.state.currency} refreshData={this.refreshData} curSelectSecurity={this.state.curSelectSecurity} />
                <div className="equity_awards_conCenter scroll-cont">
                    <div id="options">
                        <div className="holders-nav clearfix">
                            <Permission type="securities_EA_actions" hide>
                                <div className="action-box onset">
                                    <div className="btn-group">
                                        {this.getActionsBottom()}
                                    </div>
                                </div>
                            </Permission>
                            <div className="form-group search-group banner-rightBox offset draft-statistic">
                                {optionsCount >= 1 &&
                                <div className="stateBox">
                                    <p className="items-tip">{printf(__('%s of %s items selected'),optionsCount,this.state.optionsList.length)}</p>
                                    <button onClick={this.clear}
                                            type="button" className="bg-dark holders-clearBtn">{__('Clear selection')}
                                    </button>
                                </div>
                                }
                                <ColumnController filterList={tableColumn} redisKey={redisKey} thisObj={this} />
                                <div className="mr-10">
                                    <Filter callback={val => this.onFilterChange(val)} fiterData={this.state.filter} type="option" />
                                    &nbsp;&nbsp;
                                </div>
                                <div className="stakeholder-search-box">
                                    <Input
                                        className="stakeholder-search-input"
                                        value={searchValue}
                                        onChange={this.searchList}
                                        onPressEnter={this.getEaList}
                                        prefix={<SearchOutlined className="search-prefix-icon"  onClick={this.getEaList}/>}
                                        suffix={<CloseOutlined style={{opacity: searchValue ? 1 : 0}} onClick={this.closeSearch} className="search-close-icon" />}
                                        placeholder={__('Search')}
                                    />
                                </div>
                                <Permission type="securities_EA_manageEquityPlans" hide>
                                    <div className="btn-group">
                                        <a
                                            className="btn drafts_btn security-manageBtn bg-darkblue dropdown-toggle"
                                            data-toggle="dropdown">{__('Manage equity plans')}<span
                                            className="triangle triangle-whitedown manage_triangle"></span>
                                        </a>
                                        <ul className="dropdown-menu mange-downmenu shares-downmenu">
                                            <li><a className="add-stake"
                                                   data-toggle="modal" data-target="#editEquityAwardsModal">{__('Edit equity plans')}</a></li>
                                            <li><a data-toggle="modal" onClick={this.loadCreatePlanPage}
                                                   data-target="#createEquityAwardsModal">{__('Create equity plan')}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </Permission>
                                <Permission type="securities_templates" hide>
                                    <Link to={{ pathname: `/home/securities/templates/2`, state: { activeKey: '2' } }} className="btn marginRig6 drafts_btn bg-darkblue">{__('Manage vesting')}</Link>
                                </Permission>

                                <Permission type="securities_draftEA" hide>
                                    <Badge className="draft-statistic-badge" count={I(draftOptionCount, 0)}>
                                        {eaPlanList?.length == 0 ?
                                            <a disabled className="btn drafts_btn send-disabledBtn">{__('Draft options')}</a> :
                                            <Link to="/home/securities/stock-option-awards/draft-option" className="btn drafts_btn bg-darkblue">{__('Draft options')}</Link>
                                        }
                                    </Badge>
                                </Permission>
                            </div>
                        </div>
                        { eaPlanList?.length === 0 && !loading &&
                            <div className="empty_option ">
                                <div className="empty_option_box marginTop80">
                                    <span className="empty_option_icon bg_draft_edit"></span>
                                    <h5 className="empty_option_text">{__('No option grants have been issued yet.')}</h5>
                                    <Permission type="securities_EA_manageEquityPlans" hide>
                                        <button
                                            className="btn action-availableBtn action-btn white-text font_weight500"
                                            data-toggle="modal" onClick={this.loadCreatePlanPage}
                                            data-target="#createEquityAwardsModal">
                                                {__('Create equity plan')}
                                        </button>
                                    </Permission>
                                </div>
                            </div>
                        }
                        {
                            eaPlanList?.length > 0 && <div className=" table-box">
                                <OptionTable
                                    loading={loading}
                                    securityLists={optionsList}
                                    updateShowMsgStatus={this.updateShowMsgStatus}
                                    currency={currency}
                                    admin_note={curSelectSecurity.admin_note}
                                    notes={curSelectSecurity.notes}
                                    award_category={this.state.award_category}
                                    // setCurSelectSecurity={this.setCurSelectSecurity}
                                    checkSecurityStatus={this.checkSecurityStatus}
                                    setCurSelectSecurityNew={this.setCurSelectSecurityNew}
                                    setSelectId={this.setSelectId}
                                    resetSecurityStatus={this.resetSecurityStatus}
                                    resetCancel={this.resetCancel}
                                    getQuantityToExercise={this.getQuantityToExercise}
                                    getVestingData={this.getVestingData}
                                    getVestMilestone={this.getVestMilestone}
                                    getInitVestingList={this.getInitVestingList}
                                    reverseCancellation={this.reverseCancellation}
                                    setInitShareNote={this.setInitShareNote}
                                    resendEmail={this.resendEmail}
                                    convertToShares={this.convertToShares}
                                    reverseTermination={this.reverseTermination}
                                    updateSelectCount={this.updateSelectCount}
                                    onRef={this.tableObj}
                                    saveState={this.saveState}
                                    sortTable={this.sortTable}
                                    tableColumn={columnsFilter(tableColumn, redisKey,sort)}
                                    paginationConfig={{
                                        total,
                                        pageSize,
                                        current: pageNumber,
                                        showSizeChanger: true,
                                        onChange: this.onPageChange,
                                        showTotal: (sum) => <span>{sum} {__('items')}</span>
                                    }}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }

}

export default OptionsList;

