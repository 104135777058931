import React from "react";
import { post } from 'srcPath/http';

class DeleteDraftSet extends React.Component{
    constructor(props){
        super(props);
        this.state={
            deleteStatus:0,
            name:"",
            id:"",
        }
    }

    delDraftSet=()=>{
        if(this.state.deleteStatus==1){
            return;
        }
        this.setState({
            deleteStatus:1
        })
        post('deleteDraftSet', {id:this.state.id}).then((response)=> {
            if(response.data.code === 0){
                // window.$("#deleteDraftSetModal").modal("hide");
                this.setState({
                    deleteStatus:0,
                })
                window.location.reload();
                // this.props.getDraftSecurityList();
                // this.props.getShareClassDraftSetList();
                // this.props.changeDraftId(this.state.id,this.state.name)
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    initData=(key,initData)=>{
        this.setState({
            id:initData.id,
            name:initData.name
        });
    }
    render(){
        return(
            <div className="modal fade" id="deleteDraftSetModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="modal-dialog dialog-top40">
                    <div className="modal-content content500">
                        <div className="modal-header box-header relation-header">
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Delete')}</h4>
                        </div>
                        <div className="modal-body padding3232">
                            <p className="text-muted no-marginBottom">{__('Are you sure you want to delete the')} '{this.state.name}' draft set?</p>
                        </div>
                        <div className="modal-footer box-footer no-paddingtop">
                            <button type="button" onClick={this.delDraftSet} className="btn dataroom-btnReset">{__('Yes')}</button>
                            <button type="button" className="btn dataroom-btnReset" data-dismiss="modal">{__('No')}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default DeleteDraftSet;