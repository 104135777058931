import React from 'react';
import axios from 'axios';
import Utils from 'Utils';
import { Button } from 'antd';
class DeleteDoc extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            data:{
                id:"",
            },
        }
    }
    closeDeleteModal=()=>{
        this.props.closeDeleteModal();
    }
    deleteAllDoc=()=>{
        this.props.deleteAllDoc();
        this.props.closeDeleteModal();
    }
    render(){
        return(
            <div className="width420 delModal">
                <div className="modal-content content-reset">
                    <div className="modal-header box-header">
                        <button type="button" className="close"  onClick={this.closeDeleteModal} >×</button>
                        <h4 className="modal-title" id="myModal2Label">Delete</h4>
                    </div>
                    <div className="modal-body body-reset2020 text_align_center">
                        <p className="text-muted mb-0">Are you sure? This cannot be undone.</p>
                    </div>
                    <div className="modal-footer box-footer no-paddingtop">
                        <button onClick={this.deleteAllDoc} type="button" className="btn dataroom-btnReset">Yes</button>
                        <button onClick={this.closeDeleteModal}  type="button" className="btn dataroom-btnReset">Go Back</button>
                        {/*<Button  onClick={this.deleteAllDoc} ghost type="danger" >Yes</Button>*/}
                        {/*<Button onClick={this.closeDeleteModal}  type="primary" >Go Back</Button>*/}
                    </div>
                </div>
            </div>
        )
    }

}

export default DeleteDoc;

