import React, { Fragment } from 'react';
import SimpleTable from '../components/SimpleTable';
import HeaderTips from '../components/HeaderTips';
import { formatCurrencyNumber } from 'Utils'
import { formatDate } from 'Utils/format/formatDate';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import DigitalCertificate from '../components/DigitalCertificate/DigitalCertificateCom';
import formatExercisePrice from 'Utils/format/formatExercisePrice';
import { certificateStatus } from 'Utils/constant';

export default function Certificate(props) {
	const { type, email, name, status, originalQuantity, remainningQuantity, exercisedQuantity, shareClass, quantity, pricePerShare, rsaFrontAndBack, issuedBy, issuedFrom, grantDate, boardApprovedDate, certificateList, formatText, exercisePrice, digital_certificate, vestingSchedule, company_name, certificateHistory } = props.data || {}
	const digitalCertificate = {
		...digital_certificate,
		company_name,
		vestInfo: vestingSchedule
	}
	const isRSU = type === 'RSU';
	const isRSA = type === 'RSA';

	const table2Header = {
		rsa: __('RSA'),
		rsu: __('Quantities'),
	};
	const config = [
		{
			label: __('Issued quantity'),
			value: formatFractionalNumber(quantity)
		},
		{
			label: __('Exercised quantity'),
			value: formatFractionalNumber(exercisedQuantity)
		},
		{
			label: __('Remaining quantity'),
			value: formatFractionalNumber(remainningQuantity)
		},
		{
			label: __('Exercise price'),
			value: formatExercisePrice(exercisePrice),
			hide: isRSU
		}
	]

	const rsaConfig = [
		{
			label: __('Share class'),
			value: shareClass
		},
		{
			label: __('Quantity'),
			value: formatFractionalNumber(quantity),
		},
		{
			label: __('Price per share'),
			value: formatCurrencyNumber(pricePerShare)
		},
		// { 暂时隐藏
		// 	label: 'RSA front and back',
		// 	value: rsaFrontAndBack,
		// 	hide: !isRSA
		// }
	]

	const issuerConfig = [
		{
			label: __('Issued by'),
			value: issuedBy
		},
		{
			label: __('Issued from'),
			value: issuedFrom
		},
		{
			label: __('Grant date'),
			value: formatDate(grantDate)
		},
		{
			label: __('Board approved'),
			value: formatDate(boardApprovedDate)
		},
		{
			label: __('Certificate front and back'),
			value: <DigitalCertificate data={digitalCertificate} type="EA" />
		}
	]


	const optionConfig = [
		{
			label: __('Name'),
			value: name
		},
		{
			label: __('Email'),
			value: email
		},
		{
			label: __('Status'),
			value: certificateStatus[status]
		}
	]

	if (certificateHistory?.length > 0) {
		certificateHistory.map((item) => {
			// const statusText = item.type !== 'Exercised' ? (item.type === 'Settled' ? 'Settle' : (item.type ==='Transferred' ? 'Transfer' : item.type.replace('ed', '')) ) : 'Exercise'
			const statusText = item.type !== 'Canceled' ?  ( item.type === 'Settled' ? 'Settle' : (item.type ==='Transferred' ? 'Transfer' : ( item.type ==='Converted' ? 'Convert' : item.type.replace('d', ''))) ) : 'Cancellation'
			issuerConfig.push({
				label: `${statusText} date for ${formatFractionalNumber(item.share)} shares ${item.source || 'from'} ${item.certificatesName}`,
				value: formatDate(item.date)
			})
		})
	}

	const holderTitle = isRSU ? __('RSU holder') : __('Shareholder');


	return <Fragment>
		{certificateList && certificateList.length && formatText &&
			<HeaderTips data={{ certificateList, formatText }} />
		}
		<div className="certificate-detail-section">
			<SimpleTable title={holderTitle} config={optionConfig} />
		</div>
		<div className="certificate-detail-section">
			<SimpleTable title={table2Header[isRSA ? 'rsa' : 'rsu']} config={isRSA ? rsaConfig : config} />
		</div>
		<div className="certificate-detail-section">
			<SimpleTable title={__('Issuer')} config={issuerConfig} />
		</div>
	</Fragment>
}