import React from "react";
import security from 'stores/security';

/**
 * waterfall 语言文件
 * @private
 */
export const __LANG = () => {
    const lang = {
        title : __('Create a new scenario model'),
        financingRound : __('Financing Round'),
        waterfall : __('Waterfall'),
        exitValue : __('Exit value'),
        capTable : __('Cap table'),
        amountSymbol : security.companyInfo.currency,
        nonConvertibleDebt : __('Non Convertible Debt'),
        nonConvertibleDebtHelp : __('Add any additional debt that the company holds that is not captured in the capitalization structure.'),
        projectedExitDate : __('Projected Exit Date'),
        exitFor : __(' exit for '),
        waterfallModelsTitle : __('Waterfall models')
    }
    return lang;
}