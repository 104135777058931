import React, { Component, useStatem,Fragment } from 'react';
import { Form, Input, Button, message } from 'antd';
import { post } from 'srcPath/http';
import Loading from 'components/Loading'

class AwardCategoryModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
		}
	}
	componentDidMount = () => {

	}
	openDeleteModal = () => {
		const { id, callback, close } = this.props
		Loading.global(true)
		post('deleteAwardCategory', { id }).then(res => {
			let { data: resDate } = res || {}
			let { code } = resDate || {}
			if (code == 0) {
				callback && callback()
				close()
			} else {
				message.error(resDate.data)
			}
			Loading.global(false)
		}).catch(err => {
			message.error(err)
			Loading.global(false)
		});
	}
	closeModels = () => {
		const { close } = this.props
		close()
	}
	render() {
		return <div style={{ padding: 30 }}>
			<p>{__('Are you sure you want to delete this award category?')}</p>
			<div style={{ marginTop: 30, textAlign: 'center' }}>
				{
					<Fragment>
						<Button style={{ marginRight: 6 }} onClick={this.closeModels}>{__('Cancel')}</Button>
						<Button type="primary" onClick={this.openDeleteModal}>{__('Delete')}</Button>
					</Fragment>
				}
			</div>
		</div>


		// <div className="delete-model">
		// 	<div className="account-profile-modal">
		// 		<div>Are you sure you want to delete this award category?</div>
		// 		<div className="delete-model-footerr">
		// 			<Button size="small" onClick={this.closeModels}>Cancel</Button>
		// 			<Button type="primary" onClick={this.openDeleteModal}>Delete</Button>
		// 		</div>
		// 	</div>
		// </div>
	}
}

export default AwardCategoryModel
