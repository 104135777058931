import React, { useState, useEffect } from 'react';
import { Table, Tooltip, message, Select, Button } from 'antd';
import { openUpdateModal } from './components/UpdateTags';
import { openUpdateNoteModal } from './components/UpdateNotes';
import { openUpdateStakerholderModal } from 'B/components/WalletLabel/AssignToken';
import RenderStakeholder from 'Utils/RenderWalletAddress';
import { PlusOutlined } from '@ant-design/icons';
import RoundTags from 'components/RoundTags';
import formatTokenNumber from 'Utils/format/formatTokens';
import Loading from 'components/Loading';
import { post } from 'srcPath/http';
import { renderDuration } from './config';
import { formatDate } from 'Utils/format/formatDate';
import EditGrant from './components/EditGrant'

import './style.scss';

export const renderValue = val => val === 0 ? val : (val || '--')

export const renderEllipsis = (text, width) =>  <Tooltip title={text}><div title={text} style={{ width: width || 150, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'  }}>{renderValue(text)}</div></Tooltip>

export default function TokenTransactionTable(props) {
	const { list = [], paginationConfig = {}, callback, loading, onClickWallet, noAction = false, coinId, isPortfolio = false } = props;
	const [ showTime, setShowTime ] = useState(false)
	const [grantList, setGrantList] = useState([])
	const [warrantList, setWarrantList] = useState([])

	async function getGrantList() {
		try {
			const { data: resData } = await post('getGrantList4Menu')

			const { code, data } = resData || {}

			if (code === 0) {
				setGrantList(data)
			} else {
				message.error(data)
			}

		} catch(e) {
			message.error(e.message)
		}
	 }

	useEffect(() => {
 		getGrantList()
	}, [])

	async function getWarrantList() {
		try {
			const { data: resData } = await post('getCoinWarrantList4Menu', { coin_id: coinId })

			const { code, data } = resData || {}

			if (code === 0) {
				setWarrantList(data)
			} else {
				message.error(data)
			}

		} catch(e) {
			message.error(e.message)
		}
	 }

	useEffect(() => {
 		getWarrantList()
	}, [coinId])

	const changeAge = () => {
		setShowTime(!showTime)
	}

	const columnConfig = [
	  {
	    title: <Tooltip title={__("Click on the column title if you would like to switch the format")}><div className="token-transaction-table-date" onClick={changeAge}>{showTime ? __('Date Time') : __('Age')}</div></Tooltip>,
	    dataIndex: 'age',
		className: 'nowrap',
	    render: (text, item) => showTime ? formatDate(item.transaction_timestamp) : renderDuration(item.transaction_timestamp, item.current_timestamp)
	  },
	  {
	    title: __('From'),
	    dataIndex: 'from_stakeholder',
		render: (text, record) => {
			return <div>
				<div>
					{ noAction ? renderValue(text) :  text ? <RenderStakeholder noAction={noAction} tooltip={false} cb={callback} address={record.from_wallet}>{text}</RenderStakeholder> : <div className="update-button" onClick={(e) => { e.preventDefault(); openUpdateStakerholderModal(record.from_wallet, callback) }}><PlusOutlined/>Update</div> }
				</div>
				<div>{renderValue(record.from_stakeholder_relationship)}</div>
				<div>
					{
						paginationConfig ? <a onClick={e => { e.preventDefault(); onClickWallet && onClickWallet(record.from_wallet) }}>{renderEllipsis(record.from_wallet)}</a> : renderEllipsis(record.from_wallet)	
					}
				</div>
			</div>			  
		}
	  }, 
	  {
	    title: __('To'),
		dataIndex: 'to_stakeholder',
		render: (text, record) => {
			return <div>
				<div>
					{ noAction ? renderValue(text) :  text ? <RenderStakeholder noAction={noAction} tooltip={false} cb={callback} address={record.to_wallet}>{text}</RenderStakeholder> : <div className="update-button" onClick={(e) => { e.preventDefault(); openUpdateStakerholderModal(record.to_wallet, callback) }}><PlusOutlined/>Update</div> }
				</div>
				<div>{renderValue(record.to_stakeholder_relationship)}</div>
				<div>
					{
						paginationConfig ? <a onClick={e => { e.preventDefault(); onClickWallet && onClickWallet(record.to_wallet) }}>{renderEllipsis(record.to_wallet)}</a> : renderEllipsis(record.to_wallet)	
					}
				</div>
			</div>			  
		}  
      }, 
	  {
	    title: __('Tag'),
	    dataIndex: 'tag',
	    render: (text, item) => {
	    	return noAction ? text => <RoundTags tags={text || []} /> : <div>
	    		<RoundTags tags={text || []} />
	    		<div className="update-button" onClick={(e) =>{e.preventDefault(); openUpdateModal(item, callback)}}><PlusOutlined/>Update Tags</div>
	    </div>}
	  },
	  {
	    title: __('Note'),
	    dataIndex: 'notes',
	    width: 200,
		render: (text, item) => {
	    	return noAction ? <div className="token-transaction-table-notes">{renderValue(text)}</div> : <div>
	    		<div className="token-transaction-table-notes">{text}</div>
	    		<div className="update-button" onClick={(e) =>{e.preventDefault(); openUpdateNoteModal(item, callback)}}><PlusOutlined/>Update Notes</div>
	    </div>}	    
	  },
	  {
	    title: __('Quantity'),
		dataIndex: 'quantity',
		align: 'right',
	    render: text => formatTokenNumber(text)
	  },	  
	  {
		title: __('Vesting/Warrants'),
		dataIndex: 'grant_id',
		align: 'center',
		render: (text, record) => {
			return isPortfolio ? <> <Button type="link" onClick={ () => openGrantDetail(text) }>{grantList.find(val => val.id === text)?.grant_name}</Button> </> : <EditGrant callback={() => { getGrantList(); getWarrantList(); callback?.() }} coinId={coinId} record={record} grantList={grantList} warrantList={ warrantList } />
		}
	   }
	]

	return <div className="token-transaction-table">
		<Table
			columns={columnConfig}
			dataSource={list}
			rowKey="id"
			size="small"
			loading={loading}
			pagination={ paginationConfig ? {...paginationConfig,  position: ['bottomCenter'] } : false}
			scroll={{ x: true }}
		/>
	</div>
}
