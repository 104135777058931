import React from 'react';
import './style.scss';
import { CloseOutlined } from '@ant-design/icons';

export default function ModalContent(props) {
	const { title, showClose = true, close, children } = props

	return <div className="sprout-modal-content">
		<div className="sprout-modal-header">
			{title}
			{showClose && <span className="close shut-btn sprout-modal-close" onClick={close}><CloseOutlined /></span>}
		</div>
		<div className="sprout-modal-detail">
			{children}
		</div>
	</div>
}