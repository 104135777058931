import React, { Component } from 'react';
import { post } from 'srcPath/http';
import url from 'url';
import { message, Button, Input, Modal, Popover, Upload, Radio } from 'antd';
import { PlusOutlined, UploadOutlined, FilePdfOutlined, CloseOutlined } from '@ant-design/icons';
import * as Editor from 'wangeditor'
import i18n from 'i18next';
import Utils from 'Utils';
import TimeLine from './TimeLine';
import './index.scss';

let editor = {}

export default class EditPage extends Component {
	state = {
		consentName: 'Consent name',
		consentContent: '',
		content: {},
		loading: false,
		consentId: 0,
		templateId: 0,
		templateList: [],
		currentTemplateId: 0,
		isModalVisible:false,
		modalTitle: __('Upload a file'),
		modalType: 'file',
		fileLink: '',
		fileName: '',
		fileInfo: {}
	}

	componentDidMount() {
		this.initQuery();
		this.initTemplateList();
	}

	componentWillUnmount() {
		editor.destroy();
	}
	

	async initQuery() {
		const state = this.props.location.state || {};
		const { consentId, templateId } = state;
		if (consentId) {
			try {
				this.setState({
					consentId,
					loading: true
				});
				const { data: resData } = await post('getConsentDetail', { consent_id: consentId })
	
				const { code, data } = resData || {}
				this.setState({
					loading: false
				});
				if (code == 0) {
					const content = data.consent_detail.consent_content;
					this.setState({
						content: data.consent_detail,
						consentName: data.consent_detail.consent_name
					});
					this.renderEditContent(content);
				} else {
					message.error(data)
				}
			} catch(err) {
				message.error(err && err.message)
			}
		} else if (templateId) {
			try {
				const { data: resData } = await post('getTemplateDetail', { template_id: templateId })
	
				const { code, data } = resData || {}
				if (code == 0) {
					const content = data.template_content;
					this.setState({
						consentName: data.template_name,
						templateId
					});
					this.renderEditContent(content);
				} else {
					message.error(data)
				}
			} catch(err) {
				message.error(err && err.message)
			}
		} else {
			this.renderEditContent()
		}
	}

	async initTemplateList() {
		try {
			const { data: resData } = await post('getTemplateList')

			const { code, data } = resData || {}

			if (code == 0) {
				this.setState({
					templateList: data
				});
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}
	}

	handleInput(e) {
		this.setState({
			consentName: e.target.value
		});
	}

	handleSave = async (jump) => {
		const consentContent = editor.txt.html();
		const { consentName, consentId, content } = this.state;
		if (!consentName || !consentContent) {
			message.warn(__('Please input name and content first'));
			return;
		}
		const params = {
			...content,
			consent_name: consentName,
			consent_content: consentContent,
			status: content.status || 1,
		};
		this.setState({ loading: true });
		let url = 'createBoardConsent';
		if (Number(consentId)) {
			url = 'editConsent';
			params.consent_id = consentId;
		}

		try {
			const { data: resData } = await post(url, params)

			const { code, data } = resData || {}

			if (code == 0) {
				const urlObj = this.formatUrl(data.consent_detail ? data.consent_detail.consent_id : '');
				if (jump) {
					this.props.history.push({
						pathname: urlObj.url,
						state: urlObj.params
					});
				} else {
					this.props.history.push('/home/board/consents/list');
				}
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}

		this.setState({ loading: false })
	}

	formatUrl(id) {
		const url = '/home/board/consents/board/setting';
		let params = {};
		const { templateId, consentId } = this.state;
		if (Number(templateId) && Number(id || consentId)) {
			params = {
				templateId,
				consentId: id || consentId
			};
		} else if (Number(templateId)) {
			params = {
				templateId
			};
		} else if (Number(id || consentId)) {
			params = {
				consentId: id || consentId
			};
		}
		return { url, params };
	}

	handleNextStep() {
		this.handleSave('jump');
	}

	handleRenderUpload() {
		this.setState({
			modalType: 'file',
			modalTitle: __('Upload a file'),
			isModalVisible: true
		});
	}

	handleChooseFile = () => {
		const input = document.createElement('input')
		const body = document.querySelector('body');

		input.type = 'file';
		input.style.display = 'none';
		
		input.onchange = async (event) => {
			const { files = [] } = event.target
			const file = files[0] || {}
			const { name } = file

			const file_obj = await Utils.uploadFile(file)
			this.setState({
				fileName: name,
				fileInfo: file_obj
			});

			body.removeChild(input);
		}

		body.appendChild(input);

		input.click()
	}

	handleSelectTemplate(e) {
		const id = e.target.getAttribute("data-template");
		this.setState({
			currentTemplateId: id
		});
	}

	hanldSelectTemplate() {
		this.setState({
			isModalVisible: true,
			modalType: 'template',
			modalTitle: __('Select a resolution template to add')
		});
	}

	renderEditContent(content) {
		editor = new Editor('#board-new-content');
		editor.config.excludeMenus = [
			'emoticon',
			'video',
			'todo',
			'image',
			'table',
			'code',
    		'splitLine',
		];
		editor.config.lang = 'en';
		editor.config.fontSizes = {
			'12px': { name: '12px', value: '1' },
			'14px': { name: '14px', value: '2' },
			'16px': { name: '16px', value: '3' },
			'18px': { name: '18px', value: '4' },
			'20px': { name: '20px', value: '5' },
			'22px': { name: '22px', value: '6' },
			'24px': { name: '24px', value: '7' },
		  }
		editor.i18next = i18n;
		editor.create();
		if (content) {
			editor.txt.html(content);
		}
	}

	handleCancel() {
		this.setState({
			isModalVisible: false,
			currentTemplateId: 0
		});
	}

	async handleConfirm() {
		const { currentTemplateId, templateList, modalType, fileName, fileInfo } = this.state;
		if (modalType === 'file') {
			if (!fileName) return message.warn(__('Please select a file first'));
			try {
				const { data: resData } = await post('consentUploadFile', {
					consent_content_file: {
						file_name: fileName,
						file_obj: fileInfo
					},
				})
				const { code, data } = resData || {}
	
				if (code == 0) {
					editor.cmd.do('insertHTML', `<a href=${data.link}>${data.name}</a>`);
					this.setState({
						isModalVisible: false,
					});
				} else {
					message.error(data)
				}
			} catch(err) {
				message.error(err && err.message)
			}
		} else {
			const template = templateList.find((item) => {
				return item.template_id === currentTemplateId;
			})
			if (!template) return message.warn(__('Please select a template'));
			editor.cmd.do('insertHTML', template.template_content);
			this.setState({
				isModalVisible: false,
				currentTemplateId: 0
			});
		}
	}

	render() {
		const content = <ul className="board-consents-operate-items">
			<li onClick={this.handleRenderUpload.bind(this)}>{__('File')}</li>
			<li onClick={this.hanldSelectTemplate.bind(this)}>{__('Resolution a template')}</li>
		</ul>;
		const { consentName, templateList, isModalVisible, currentTemplateId, modalTitle, modalType, fileName,   } = this.state;
		const state = this.props.location.state || {};
		const { templateId } = state;
		return <div className="new-board-consents-root">
                <div className="board-consents-operate-area">
					<TimeLine step="1" type={templateId? 'template' : 'consent'} />
					<Button className="board-consents-operate-save" onClick={this.handleSave.bind(this, null)}>{__('Save and finish later')}</Button>
					<Button className="board-consents-operate-btn" type="primary" onClick={this.handleNextStep.bind(this)}>{__('Next')}</Button>
                </div>
                <div className="board-consents-wrapper">
					<div className="board-consents-right-wrapper">
						<Input placeholder="Input consents name" value={consentName} onChange={this.handleInput.bind(this)} />
						<div id="board-new-content"></div>
					</div>
					<Popover placement="left" content={content} trigger="hover"><span className="board-consent-operate-add"><PlusOutlined /></span></Popover>
					<Modal className="modal-select-template" title={modalTitle} visible={isModalVisible} onOk={this.handleConfirm.bind(this)} onCancel={this.handleCancel.bind(this)}>
						{modalType === 'template' && templateList.length > 0 &&
							<ul className="board-consent-template-list-select" onClick={this.handleSelectTemplate.bind(this)}>
								{templateList.map((template) => <Radio data-template={template.template_id} key={template.template_id} checked={template.template_id == currentTemplateId}><li>{template.template_name}</li></Radio>)}
							</ul>
						}
						{modalType === 'template' && !templateList.length &&
							<p>{__('No Templates')}</p>
						}
						{modalType === 'file' &&
							<div>
							<Button type="primary" onClick={this.handleChooseFile} >{__('Choose a file')}</Button> 
							{
								fileName && <div style={{ marginTop: 10 }}>
									<FilePdfOutlined />
									&nbsp;
									{fileName}
									&nbsp;
									<CloseOutlined onClick={this.deleteFile} />
								</div>				
							}
							</div>
						}
					</Modal>
				</div>
            </div>
	}
}