import React, { Component } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import classnames from 'classnames';
import './index.scss';
class OperateBtnGroup extends Component {
    data = this.props.data
    type = this.props.type
    
	render () {
        const content = <ul className="board-consents-operate-items">
            {/* { this.data.status != 3 && */}
            <li className={classnames({ 'board-consents-operate-disabled': this.data.disabled })} data-action="new">{__('Edit')}</li>
            {/* } */}
            <li className={classnames('board-consents-operate-cancel', { 'board-consents-operate-disabled': this.data.disabled })} data-action="delete">{__('Delete')}</li>
        </ul>;
		return <Popover placement="bottom" content={content} trigger="click">
            <div className="board-consents-operate" >
                <DownOutlined />
            </div>
        </Popover>
	}
}

export default OperateBtnGroup;