import React, { Component } from "react";
import {Button, Divider, Form, Input, message} from "antd";
import { LockOutlined } from '@ant-design/icons';
import { post } from 'srcPath/http';
import "./style.scss";
import {errorPrompt} from "./errorPromptContent";

class SignupAnother extends Component {

    formRef = React.createRef();

    state = {
        loading: false,
    };

    /**
     * 保存表单
     * @param data
     * @returns {Promise<void>}
     */
    saveForm = async (data) => {
        this.setState({loading: true});
        const {comment, company_name, email, password, title} = data;
        try {
            const { data: resData } = await post('joinSproutExist', {comment, company_name, email, password, title})
            const { code, data } = resData;
            if(code === 0){
                this.props.history.push(data.url);
            }else{
                message.error(errorPrompt.passwordIncorrectError);
            }
        } catch(err) {
            message.error(err && err.message)
        }
        this.setState({loading: false});
    }

    render() {
        const { loading } = this.state;
        return (
            <div className="sprout-signup-root">
                <div className="sprout-signup-right-background"></div>
                <div className="sprout-auth-title">{__('Create another company in Folium')}</div>
                <Divider />
                <dl className="sprout-signup-content">
                    <dt>{__('Make sure you already have an administrator account.')}</dt>
                    <dd>
                        <Form className="sprout-signup-form" ref={this.formRef} onFinish={this.saveForm}>
                            <Form.Item name="email" rules={[{required: true, message: errorPrompt.emailEmptyError}, {type: 'email', message: errorPrompt.emailInvalidError}]}><Input placeholder={__('Email')} autoComplete="username" /></Form.Item>
                            <Form.Item name="password" rules={[{required: true, message: errorPrompt.passwordEmptyError}]}><Input.Password autoComplete="new-password" prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder={__('Set a password')} /></Form.Item>
                            <Form.Item name="title" rules={[{required: true, message: errorPrompt.titleEmptyError}]}><Input placeholder={__('Title')} /></Form.Item>
                            <Form.Item name="company_name" rules={[{required: true, message: errorPrompt.companyNameEmptyError}]}><Input placeholder={__('Company name')} /></Form.Item>
                            <Form.Item name="company_size" ><Input placeholder={__('How many share and option holders do you have ?')} /></Form.Item>
                            <Form.Item className="sprout-signup-submit-button"><Button loading={loading} type="primary" htmlType="submit">{__('Confirm and Continue')}</Button></Form.Item>
                        </Form>
                    </dd>
                </dl>
            </div>
        );
    }
}
export default SignupAnother;