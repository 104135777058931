import React, { Component, Fragment } from 'react';
// import { withForm, FormItem } from 'react-antd-formutil';
import Modal, { ModalContent } from 'components/Modal';
import { Input, Button, message, Table, Checkbox } from 'antd';
import { post } from 'srcPath/http';
// import { bindKeyEvent } from 'Utils';
import pageIcon from 'assets/images/meeting-icon.png';
import classnames from 'classnames'
import { LeftOutlined, PlusOutlined } from '@ant-design/icons';


import './style.scss';

const { TextArea } = Input


const columns = [
	{
		title: 'Name',
		dataIndex: 'attendees_name',
		render: text => text || '--'
	},
	{
		title: 'Email',
		dataIndex: 'email',
		render: text => text || '--'
	},
	{
		title: 'Role',
		dataIndex: 'role',
		render: text => text || '--'
	},
]


function processTableData(data) {
	const { board_members = [], attendees = [] } = data || {}
	let attendeeList = board_members.concat(attendees)

	attendeeList = attendeeList.map((val, index) => { return {...val, attendees_name: val.attendees_name || val.name, key: index} })

	return attendeeList || []
}


class PublishMeeting extends Component {
	attendeeList = processTableData(this.props.data)

	state = {
		loading: false,
		check: true,
		addAttendee: false,
		showMessage: false,
		textMessage: '',
		selectedRowKeys: []
	}

	componentDidMount() {
	}

	onSubmit = async () => {
		const { close, callback } = this.props
		const { meeting_id } = this.props.data || {}
		const { check, textMessage, selectedRowKeys } = this.state
		const members = this.attendeeList.filter(val => selectedRowKeys.includes(val.key)) || []
		const board_members_notify = []
		const custom_message = textMessage

		if (members.length > 0) {
			members.forEach(val => {
				const board_id = val.user_id
				const attends_id = val.id

				board_members_notify.push({ board_id, attends_id })
			})
		}

		this.setState({ loading: true })

		const { data: resData } = await post('publishMeeting', { meeting_id, board_members_notify, custom_message })

		const { code, data } = resData

		if(code === 0) {
			message.success('Successfully')

			callback && callback()
			close && close()
			this.setState({ loading: false })
		} else {
			message.error(data)
		}

		this.setState({ loading: false })
	}

	handleCheckChange = (e) => {
		const value = e.target.checked

		this.setState({ check: value })
	}

	onSelectChange = selectedRowKeys => {
		this.setState({ selectedRowKeys })
	}

	handleMessage = event => {
		const value = event.target.value || ''
		if (value.length < 300) {
			this.setState({ textMessage: value })
		} else {
			message.error('Max characters is 300')
		}
	}

	render() {
		const { loading, check, addAttendee, selectedRowKeys, textMessage, showMessage} = this.state
		const { close, data } = this.props

	    const rowSelection = {
	      selectedRowKeys,
	      onChange: this.onSelectChange,
	    };

		return <div className="publish-meeting-root">
			<div className="sprout-modal-content">
				<div className="sprout-modal-header">
					{ addAttendee && <LeftOutlined className="publish-meeting-back" onClick={() => this.setState({ addAttendee: false })} />}
					Publish meeting
					<span className="close shut-btn sprout-modal-close" onClick={close}>X</span>
				</div>
				<div className="sprout-modal-detail">
					<div className={classnames('publish-meeting-content', { 'publish-meeting-content-transform': addAttendee })}>
						<div className="publish-meeting-content-front">
							<div className="publish-meeting-img">
								<img src={pageIcon} />
								<div className="publish-meeting-tips">After you publish the meeting, guests will be able to view meeting details and documents on the Folium website.</div>
							</div>
							<Checkbox checked={check}  onChange={this.handleCheckChange} >Send email notifications to meeting guests.</Checkbox>
							<div className="publish-meeting-btns">
								{
									check ? <Button onClick={() => this.setState({ addAttendee: true })} type="primary">Next: Select guest to notify</Button> : <Button loading={loading} onClick={this.onSubmit} type="primary">Publish meeting</Button>
								}
							</div>
						</div>
						<div className="publish-meeting-content-end">
							<Table
								columns={columns}
								dataSource={this.attendeeList}
								pagination={false}
								rowSelection={rowSelection}
							/>
							<div className="publish-meeting-message">
								<div className="message-btn"><Button type="primary" onClick={() => this.setState(state => ({ showMessage: !state.showMessage }) )}><PlusOutlined />Add message</Button></div>
								{
									showMessage && <Fragment><TextArea rows={4} value={textMessage}  onChange={this.handleMessage} placeholder="e.g. Greetings, please note the meeting information and review the documents prior to the meeting. Thank you." />
									<span style={{ fontSize: 12, color: '#9e9e9e'  }}>{textMessage.length} of 300 characters</span>
									</Fragment>
								}
							</div>
							<div className="publish-meeting-btns">
								{
									<Button loading={loading} type="primary" onClick={this.onSubmit}>Publish meeting</Button>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	} 
}

export default function openPublishMeetingModal(data, callback) {
	return Modal.open({
		component: <PublishMeeting data={data} callback={callback} />,
		width: 600,
		className: 'sprout-modal'
	})
}