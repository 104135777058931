import React, { Component } from 'react';
import { Collapse, message, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { formatNumber, formatPercent } from 'Utils';
import { post } from 'srcPath/http';
import security from 'stores/security';
import icon from '../images/wallets-icon.png';
import classnames from 'classnames';
import TokenSelector, { getDefaultTokenId } from 'B/components/TokenSelector'

import Loading from 'components/Loading';
import '../style.scss'

const { Panel } = Collapse;

export default class RelationShip extends Component {
	state = {
		totalQuantity: 1,
		coinId: getDefaultTokenId()
	}

	componentDidMount() {
		this.getData()
	}

	handleMenu = value => {
		this.setState({
			coinId: value
		})

		this.getData(value)
	}

	getData = async (coinIdKey) => {
		const { coinId } = this.state

		Loading.global(true)

		try {

			const { data: resData } = await post("getCapTableByRelationship", { id: coinIdKey || coinId })

			const { code, data } = resData || {}

			if (code === 0) {
				let totalQuantity = 0

				if (data?.category_list) {
					data?.category_list.map((category) => {
						totalQuantity += +category?.quantity ?? 0
					})
				}

				if (data?.unlabeled_list) {
					totalQuantity += +data?.unlabeled_list?.quantity ?? 0
				}

				this.setState({ data, totalQuantity })
			}

		} catch (err) {
			message.error(err.message)
		}

		Loading.global(false)
	}

	renderCommonHeader = data => {
		const { relationship, quantity, percentage, user_list } = data || {}
		const walletsText = user_list?.length > 1 ? 'Tokenholders' : 'Tokenholder'

		return <div className="certificate-content-header">
			<div className="header-name" title={relationship}>
				{relationship}
				<div className="description">
					<span>{formatNumber(user_list?.length, 0)}</span>&nbsp;
					<span>{ walletsText}</span>
				</div>
			</div>
			<div className="table-td">{formatNumber(quantity)}</div>
			<div className="table-td">{formatPercent(percentage)}</div>
		</div>
	}

	renderCommonHeader2 = (data, type = '') => {
		const { name = 'Unlabelled', value, quantity, percentage, wallet_address_list } = data || {}
		const isUnlabeled = type === 'unlabeled'
		const walletsText = wallet_address_list?.length > 1 ? 'Wallets' : 'Wallet'

		return <div className="certificate-content-header">
			<div className={classnames('header-name', { 'header-name-unlabeled': isUnlabeled })} title={name}>
				{isUnlabeled ? <Tooltip title="Unlabelled category shows Top-20 unlabelled wallets based on Ownership.">{name} <QuestionCircleOutlined /></Tooltip> : name}
				{!isUnlabeled && (
					<div className="description">
						<span>{formatNumber(wallet_address_list?.length, 0)} </span>&nbsp;
						<span>{walletsText}</span>
					</div>
				)}
			</div>
			<div className="table-td">{formatNumber(quantity)}</div>
			<div className="table-td">{formatPercent(percentage)}</div>
		</div>
	}

	renderCommonItem = (data, isPreferred) => {
		const { quantity, percentage, wallet_address } = data || {}

		return <div className="certificate-content-item">
			<div className="item-name">
				<img src={icon} width="32" height="32" />
				<Tooltip title={wallet_address} color="#2b3946">
					<div className="name">
						<div>{wallet_address}</div>
						{/* <div className="description">Issued <span>{formatDate(issuedDate)}</span></div> */}
					</div>
				</Tooltip>
			</div>
			<div className="table-td">{formatNumber(quantity)}</div>
			<div className="table-td">{formatPercent(percentage)}</div>
		</div>
	}

	render() {
		const { data, totalQuantity, coinId } = this.state
		const { category_list = [], unlabeled_list = [] } = data || {}

		// 判断用户是否属于 Esop admin
		const flag = security.companyInfo && security.companyInfo.flag
		const isEsopAdmin = flag === '5'

		return <div className="captable-shareclass-root-token">
			<div className="captable-shareclass-root">
				<div className="captable-shareclass--banner">
					<TokenSelector coinId value={coinId}  onChange={this.handleMenu} />
				</div>
				<div className="capitalization-header-wrap">
					<div className="certificate-title"></div>
					<div className="table-th">Quantity</div>
					<div className="table-th">Ownership</div>
				</div>
				<Collapse>
					{
						category_list && category_list.map((value, index) => (
							<Panel showArrow={value?.user_list?.length > 0} key={index + 'common'} header={this.renderCommonHeader(value)} className={`common${index%5}`} collapsible={isEsopAdmin ? 'disabled' : true}>
								<Collapse>
									{
										value.user_list && value.user_list.map((data, itemIndex) => (
											<Panel key={itemIndex} header={this.renderCommonHeader2(data)}>
												{data.wallet_address_list && data.wallet_address_list.map((val, key) => <div key={key}>{this.renderCommonItem(val)}</div>)}
											</Panel>
										))
									}
								</Collapse>
							</Panel>
						))
					}
					<Panel className="unlabeled" showArrow={unlabeled_list?.wallet_address_list?.length > 0} header={this.renderCommonHeader2(unlabeled_list, 'unlabeled')}>
						{unlabeled_list?.wallet_address_list?.length && unlabeled_list.wallet_address_list.map((val, key) => <div key={key} >{this.renderCommonItem(val)}</div>)}
					</Panel>
					
				</Collapse>
				<div className="table-total">
					<div className="table-total-td-first">Total</div>
					<div className="table-total-td">{formatNumber(totalQuantity)}</div>
					<div className="table-total-td">{formatPercent(1)}</div>
				</div>
			</div>
		</div>
	}
}