import React, { Component, Fragment } from 'react';
import Section from '../Section';
import { Steps } from 'antd';
import { formatDate } from 'Utils/format/formatDate';
import './style.scss';

import { printf } from 'Utils/i18n';
const { Step } = Steps

const statusWiew = {
	'0': 'finish',
	'1': 'wait',
	'2': 'finish',
	'3': 'wait',
	'4': 'finish'
}

const view = data => data || '--'

export default function Approvals(props) {
	const data = props.data || []

	return <Section title={__('Approvals')} >
		<div className="sprout-approvals">
			<Steps direction="vertical" className="sprout-steps">
				{
					data.map((val, index) => {
						const { status, user_name, create_time } = val
						if (!user_name) {
							return
						}
						const statusMap = {
							'0': printf(__('Initiated by %s'), view(user_name)),
							'1': printf(__('Received by %s (pending)'), view(user_name)),
							'2': printf(__('Signed by %s'), view(user_name)),
							'3': printf(__('Received by %s (pending)'), view(user_name)),
							'4': printf(__('Signed by %s'), view(user_name))
						}

						const descriptionMap = {
							'0': printf(__('Approved %s'), view(formatDate(create_time))),
							'1': __('Waiting for approval'),
							'2': printf(__('Approved %s'), view(formatDate(create_time))),
							'3': __('Waiting for approval'),
							'4': printf(__('Approved %s'), view(formatDate(create_time)))
						}


						return <Step key={index} status={statusWiew[val.status]} title={statusMap[status]} description={descriptionMap[status]} />
					})
				}
			</Steps>
		</div>
	</Section>
}