import moment from 'moment';
import { formatDateForDatePicker } from 'Utils'

export function formatDate(string) {
    if (!string) {
        return {}
    }

    const [ date, start, end, zone ] = string.split(' ')
    return {
        date: formatDateForDatePicker(date)?.format('LL'),
        time: `${start} - ${end} ${zone}`
    }
}

export const statusMap = {
    Draft: {
       status: __('Meeting draft'),
       description: __('Publish meeting to give access to guests.')
    },
    Published: {
        status: __('Meeting published'),
        description: __('Archive meeting to make all contents read-only and unchangeable.')
    },
    Archived:{
        status: __('Meeting archived'),
        description: __('Meeting documents are read-only.')
    }
}
