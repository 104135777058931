import React, { Component, Fragment } from "react";
import {Alert, Button, Divider, Form, Input, message} from "antd";
import { LockOutlined } from '@ant-design/icons';
import sendCode from "../images/send-code.png";
import { post } from 'srcPath/http';
import Utils, {I} from "Utils";
import PwdRisk from 'components/PwdRisk';
import md5 from "js-md5";
import {Link} from "react-router-dom";
import {errorPrompt} from "./errorPromptContent";
import Countdown from 'react-countdown';
import Modal, { ModalContent } from 'components/Modal';
import ReminderModel from "./components/ReminderModel/";
import "./style.scss";


class Signup extends Component {

    formRef = React.createRef();
    reminderModel = null;

    state = {
        loading: false,
        mailLoading: false,
        emailMd5: [],
        emailHelp: { validateStatus: false, help: false },
        codeHelp: { validateStatus: false, help: false },
        mailLoadingTime: Date.now(),
    };

    /**
     * 保存表单
     * @param data
     * @returns {Promise<void>}
     */
    saveForm = async (data) => {
        if(!this.checkEmail() || !this.checkCode() ){
            return;
        }
        this.setState({loading: true});
        const {comment, company_name, company_size, email, password, title, user_name} = data;
        try {
            const { data: resData } = await post('joinSproutPro', {comment, company_name, company_size, email, password, title, user_name})
            const { code, data } = resData;
            if(code === 0){
                const { email, signup_company_id } = data;
                this.getSignupUrl(email, signup_company_id);
            }else if(code === 1){
                this.checkEmail(true);
            }
        } catch(err) {
            message.error(err && err.message)
        }
        this.setState({loading: false});
    }

    /**
     * 跳转到 pick plan 页
     * @param email
     * @param signupCompanyId
     */
    getSignupUrl = (email, signupCompanyId) => {
        this.props.history.push(`/signup?id=sprout${signupCompanyId}a5cd&email=${encodeURIComponent(email)}&is_login=1`);
    }

    /**
     * 发送验证码
     * @returns {Promise<void>}
     */
    getVerifyCodeByEmail = async () => {
        if(!this.checkEmail()){
            return;
        }
        this.setState({loading: true});
        const email = this.formRef.current.getFieldValue('email');
        try {
            const { data: resData } = await post('getVerifyCodeByEmail', {email})
            const { code, data } = resData;
            if(code === 0){
                const { emailMd5 } = this.state;
                emailMd5.push({email, md5: data});
                this.setState({emailMd5, mailLoading: true, mailLoadingTime: Date.now() + 59000});
                message.success('Success!');
            }else{
                message.error(data.message ? data.message : 'Error!');
            }
        } catch(err) {
            message.error(err && err.message)
        }
        this.setState({loading: false});
    }

    checkMailByCompanyName = async () => {
        if(!this.checkEmail()){
            return;
        }
        this.setState({loading: true});
        const email = this.formRef.current.getFieldValue('email');
        try {
            const { data: resData } = await post('checkMailByCompanyName', {email})
            const { code, data } = resData;
            if(code === 0){
                if(data?.status == 0 && data?.is_sign != 1){
                    this.getVerifyCodeByEmail();
                }else{
                    this.getReminderModel(data);
                }
            }
        } catch(err) {
            message.error(err && err.message)
        }
        this.setState({loading: false});
    }

    getReminderModel = (data) => {
        this.reminderModel = Modal.open({
            component: props => <ModalContent close={props.close} title="Reminder">
                <ReminderModel
                    close={props.close}
                    userEmail={data?.user_email}
                    isSign={data?.is_sign}
                    history={this.props.history}
                />
            </ModalContent>,
            width: 750,
            className: 'sprout-modal',
            maskClosable: false
        })
    }

    /**
     * 卸载
     */
    componentWillUnmount() {
        this.reminderModel && this.reminderModel.close();
    }

    /**
     * 验证邮箱是否重复、空、格式不正确
     * @param isDuplicate
     * @returns {boolean}
     */
    checkEmail = (isDuplicate = false) => {
        const email = I(this.formRef.current.getFieldValue('email'), '');
        let error = false;
        if(isDuplicate){
            error = { emailHelp: { validateStatus: 'error', help: errorPrompt.emailDuplicateError, isLong: true } };
        }else if(!email){
            error = { emailHelp: { validateStatus: 'error', help: errorPrompt.emailEmptyError } };
        }else if(!Utils.isEmail(email)){
            error = { emailHelp: { validateStatus: 'error', help: errorPrompt.emailInvalidError } };
        }
        if(error){
            this.setState(error);
            return false;
        }
        this.setState({ emailHelp: { validateStatus: false, help: false } });
        return true;
    }

    /**
     * 验证密码
     * @param value
     * @returns {boolean}
     */
    checkPassword = value => !(!value || value.length < 8 || PwdRisk.judgeRisk(value) <= 2.5);

    /**
     * 验证码校验
     * @returns {boolean}
     */
    checkCode = () => {
        const email = I(this.formRef.current.getFieldValue('email'), '');
        const code = I(this.formRef.current.getFieldValue('code'), '');
        const { emailMd5 } = this.state;
        const result = emailMd5.findIndex(v=>v.email === email && v.md5 === md5(code));
        let error = false;
        if(!code){
            error = { codeHelp: { validateStatus: 'error', help: errorPrompt.codeEmptyError } };
        }else if(result === -1){
            error = { codeHelp: { validateStatus: 'error', help: errorPrompt.codeError } };
        }
        if(error){
            this.setState(error);
            return false;
        }
        this.setState({ codeHelp: { validateStatus: false, help: false } });
        return true;
    }

    /**
     * 表单数据更新 change
     * @param changeValue
     */
    formChange = (changeValue) => {
        changeValue = Object.keys(changeValue);
        switch (changeValue[0]){
            case 'email':
                this.checkEmail();
                break;
            case 'code':
                this.checkCode();
                break;
            default:
        }
    }

    /**
     * 单独验证邮箱和验证码
     * @param values
     * @param errorFields
     * @param outOfDate
     */
    checkAttr = ({ values, errorFields, outOfDate }) => {
        this.checkEmail();
        this.checkCode();
    }

    /**
     * 自定义验证 password, code
     * @param type
     */
    getRules = (type) => {
        switch (type){
            case 'password':
                return {rules: [{ required: true, message: errorPrompt.passwordEmptyError },{
                    validator: (_, value) => {
                        this.forceUpdate();
                        if (!value || this.checkPassword(value)) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error(errorPrompt.passwordError));
                    }
                }]};
            case 'confirmPassword':
                return {rules: [{ required: true, message: errorPrompt.passwordConfirmError },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error(errorPrompt.passwordNotMatchError));
                        },
                    })
                ]};
            default:
        }
    }

    resetMailButton = () => {
        this.setState({mailLoading: false})
    }

    render() {
        const { emailHelp, codeHelp, loading, mailLoading, mailLoadingTime } = this.state;
        const { validateStatus: emailStatus, help: emailHelpValue, isLong } = emailHelp;
        const { validateStatus: codeStatus, help: codeHelpValue } = codeHelp;
        const password = I((this.formRef.current && this.formRef.current.getFieldValue('password')), '');
        return (
            <div className="sprout-signup-root">
                <div className="sprout-signup-right-background"></div>
                <div className="sprout-auth-title">Create your company with Folium</div>
                <div>Looking to <Link to="/auth/login">Sign in</Link>?</div>
                <div>Already have an account, <Link to="/auth/signup-another">create another company</Link>?</div>
                {/* <Alert className="sprout-signup-message" message={
                    <div>Please be advised that this sign-up process is exclusively for new companies. If your company already has an existing account on Folium, kindly contact your internal company administrator or reach out to <a href="mailto:support@getsprout.co">support@getsprout.co</a>, and we will assist you in granting access.
                </div>} type="info" /> */}
                <Divider />
                <dl className="sprout-signup-content">
                    <dt>Welcome to Folium</dt>
                    <dd>
                        <Form className="sprout-signup-form" ref={this.formRef} onFinish={this.saveForm} onFinishFailed={this.checkAttr} onValuesChange={this.formChange}>
                            <Form.Item name="user_name" rules={[{required: true, message: errorPrompt.nameEmptyError}]} ><Input placeholder="Name" /></Form.Item>
                            <div className="sprout-signup-input-box">
                                <Form.Item className={`${isLong && 'sprout-signup-mail-open' }`} validateStatus={emailStatus} help={emailHelpValue} name="email"><Input placeholder="Email" autoComplete="username" /></Form.Item>
                                <Button disabled={mailLoading} loading={loading} type="primary" onClick={this.checkMailByCompanyName} className="sprout-signup-send-button">
                                    <span>{mailLoading ? <Countdown onComplete={this.resetMailButton} date={mailLoadingTime} renderer={({ seconds, completed }) => !completed && <span className="sprout-signup-time">{seconds}s</span>} /> : <Fragment><img src={sendCode}/>Send code</Fragment>} </span>
                                </Button>
                            </div>
                            <Form.Item name="code" validateStatus={codeStatus} help={codeHelpValue}><Input placeholder="Enter the 6-digit verification code" /></Form.Item>
                            <Form.Item name="name_def" style={{position: 'absolute', top: '-99999'}}><Input /></Form.Item>
                            <Form.Item name="password" {...this.getRules('password')}><Input.Password autoComplete="new-password" prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Set a password" /></Form.Item>
                            {password && <PwdRisk password={password}/>}
                            <Form.Item name="confirmPassword" dependencies={['password']} {...this.getRules('confirmPassword')}><Input.Password placeholder="Confirm a password" /></Form.Item>
                            <Form.Item name="title" rules={[{required: true, message: errorPrompt.titleEmptyError}]}><Input placeholder="Title" /></Form.Item>
                            <Form.Item name="company_name" rules={[{required: true, message: errorPrompt.companyNameEmptyError}]}><Input placeholder="Company name" /></Form.Item>
                            <Form.Item name="company_size" rules={[{required: true, message: errorPrompt.companySizeEmptyError}]}><Input placeholder="How many share and option holders do you have ?" /></Form.Item>
                            <Form.Item name="comment" ><Input placeholder="How did you hear about us? Any other comments? (Optional)" /></Form.Item>
                            <Form.Item className="sprout-signup-submit-button" ><Button loading={loading} type="primary" htmlType="submit">Sign up</Button></Form.Item>
                        </Form>
                    </dd>
                </dl>
            </div>
        );
    }
}
export default Signup;