import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Shares from './Shares';

import EquityAwards from './EquityAwards';
import Convertibles from './Convertibles';
import Warrants from './Warrants';

import Templates from "./Templates";

import security from 'stores/security';
import './style.scss';

export default class SecuritiesNew extends Component {
    path = this.props.match.path

    config = [
        {
            path: `${this.path}/shares`,
            component: Shares,
            permission: 'securities_shares',
            exact: false,
        },
        {
            path: `${this.path}/stock-option-awards`,
            component: EquityAwards,
            permission: 'securities_EA',
            exact: false,
        },
        {
            path: `${this.path}/warrants`,
            component: Warrants,
            permission: 'securities_warrants',
            exact: false
        },
        {
            path: `${this.path}/convertibles`,
            component: Convertibles,
            permission: 'securities_convertibles',
            exact: false
        },
        {
            path: `${this.path}/templates/:activeKey`,
            component: Templates,
            permission: 'securities_templates',
            exact: true,
        },
        
        {
            path: `${this.path}/templates`,
            component: Templates,
            permission: 'securities_templates',
            exact: true,
        }
    ]

    render() {
        const routeConfig = this.config.filter((value, key) => security.hasPermission(value.permission))

        return <div className="sprout-security-root">
            <Switch>
                {
                    routeConfig.map((val, index) => <Route key={index} exact={val.exact} path={val.path} component={val.component} />)
                }
                <Redirect to={routeConfig && routeConfig[0] && routeConfig[0].path || this.path} />
            </Switch>
        </div>
    }
}