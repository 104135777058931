import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Permission from 'components/Permission';

import './style.scss';

// const config = [
// 	{
// 		label: string; // 跳转名称
// 		permission: string; // 权限名称
// 		path: url // 跳转路径
// 	}
// ]

export default class NavTab extends Component {
	static propTypes = {
		config: PropTypes.array.isRequired
	}

	render() {
		const { config = [] } = this.props

		return <div className="sprout-navtab-root">
			{
				config.map((val, index) => <Permission type={val.permission} key={index} hide ><NavLink exact className="item" activeClassName="navtab-active" to={val.path}>{val.label}</NavLink></Permission> )
			}
		</div>
	}
}