import React, { Component } from 'react';
import echarts from 'echarts';
import { I, formatCurrencyNumber, clearNoNum } from 'Utils';
import success from 'assets/images/success.png';
import './chart.scss';
class Fairmarketvalue extends Component {
  constructor(props) {
    super(props);
    const { datalist, marketlist } = props;
    this.state = {
      datalist: datalist,
      marketlist: marketlist
    }
  }

  componentDidMount() {
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      datalist: nextProps.datalist,
      marketlist: nextProps.marketlist
    }, () => {
      this.initCharts();
    }
    );
  }
  //初始化
  initCharts = () => {
    let { datalist, marketlist } = this.state
    let myChart = echarts.init(document.getElementById('myChart'));


    let newMarketList = [];
    marketlist && marketlist.forEach((value, k) => {
      newMarketList.push(clearNoNum({value}).value);
    })
    marketlist = newMarketList;

    let option = {
      title: {
        // __('Fair market value')
        text: '',
        left: '30px',
        top: '20px',
        textStyle: {
          fontWeight: 'bold',
          fontSize: 18,
          color:"#000",
          }
      },
      tooltip: {
        formatter:(params)=>{
          return params.name+'<br/>'+params.seriesName+": "+ formatCurrencyNumber(params.value)
        }
      },
      color: ['#47a7c9'],
      grid: {
        top: '130px',
        left: '40px',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        axisTick: {
          show: false
        },
        axisLabel: {

        },
        data: datalist
      },
      yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: '#524e4ed9'
          }
        },
        axisTick:{
          show:false
        },
        axisLabel: {
          formatter: (value) => {
            return formatCurrencyNumber(value)
          }
        }
      },
      series: [
        {
          name: __('Email'),
          type: 'line',
          z: -1,
          itemStyle: {},
          areaStyle: {
            color: '#f3fbfc'
          },
          tooltip: {
            show: false
          },
          data: marketlist
        },
        {
          name: __('Fair market value'),
          type: 'scatter',
          itemStyle: {},
          areaStyle: {
            color: '#f3fbfc'
          },
          data:marketlist
        },

      ]
    };

    myChart.setOption(option);
    window.addEventListener("resize", function () {
      myChart.resize();
    });
  }

  render() {
    const {eff_date,share}=this.props
    return (
      <div className="chart">
        <div id="myChart">

        </div>
        <div className='chart-content'>
          {/* <div className='chart-header'>
            <div className='lft'>
              <div className='title'>Under safe harbor until Jan 19.2023</div>
              <div className='info'>Subscibed to Carta 409A.</div>
            </div>
            <div className='rit'>
              <img src={success}/>
            </div>
          </div> */}
          <div className='chart-data'>
            {__('Common FMV as of')} {eff_date}
          </div>
        </div>
        <div className='chart-share'>
        {formatCurrencyNumber(share)} /{__('share')}
        </div>
      </div>
    )
  }
}
export default Fairmarketvalue;



