import React, { Component } from 'react';
import Echarts from './components/echarts/index'
import { formatDate } from 'Utils/format/formatDate'
import { message, Spin } from 'antd';
import moment from "moment";
import FMVTable from './components/FMVTable'
import './style.scss';
import { I, formatNumber, formatCurrencyNumber } from 'Utils';
import { post } from 'srcPath/http';

// table
class FairMarketValue extends Component {
    //advanceTerms浮层
    advanceTermsModal = null;
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            loading: false,
            data: '',
            shareClass: [], //CommA commB
            psClass: [],
            loading: true
        }
    }
    componentDidMount = () => {
        this.payOverView()
    }
    payOverView = async () => {
        this.setState({ loading: true })
        try {
            const { data: resData } = await post('getFairMarketValue')
            const { code, data } = resData;
            const { fmvList, psClass, shareClass, effective_date, fair_market_value } = data;
            if (code === 0) {
                if (!I(data, '')) {
                    message.error(data)
                    return;
                }
                let newPsClass = psClass.filter(item => item.status == 0)
                let addFmvlist = psClass.filter(item => item.status == 1)
                let newFmvlist = [...I(fmvList, []), ...I(addFmvlist, [])]
                newFmvlist.sort(function (a, b) {
                    let dataA = moment(a.effective_date, 'DD/MM/YYYY').format('YYYYMMDD');
                    let dataB = moment(b.effective_date, 'DD/MM/YYYY').format('YYYYMMDD');
                    return dataA < dataB ? 1 : -1;
                })
                newFmvlist.forEach(item => {
                    let start_time = moment(item.expiration_date, 'DD/MM/YYYY').format('YYYY/MM/DD')
                    let data1 = new Date(start_time).getTime()
                    let data2 = new Date().getTime()
                    if (data1 - data2 > 0) {
                        item.expired=0
                    } else {
                        item.expired=1
                    }
                })
                let dataSource = [...I(newFmvlist, []), ...newPsClass]
                let EchartsSourec = [...I(fmvList, []), ...psClass]
                EchartsSourec.sort(function (a, b) {
                    let dataA = moment(a.effective_date, 'DD/MM/YYYY').format('YYYYMMDD');
                    let dataB = moment(b.effective_date, 'DD/MM/YYYY').format('YYYYMMDD');
                    return dataA >= dataB ? 1 : -1;
                })
                let datalist = EchartsSourec.map((item) => {
                    return formatDate(item.effective_date)
                })
                let newdatalist = EchartsSourec.map((item) => {
                    return item.effective_date
                })
                let marketlist = EchartsSourec.map((item) => {
                    return formatNumber(item.fair_market_value)
                })

                this.setState({
                    dataSource,
                    datalist,
                    marketlist,
                    shareClass,
                    loading: false,
                    share: dataSource.length>0?formatNumber(dataSource[0].fair_market_value):formatNumber('0.00'),
                    eff_date: dataSource.length>0?formatDate(dataSource[0].effective_date):'',
                    newdatalist
                })

            } else {
                message.error(data)
            }
        } catch (err) {
            message.error(err && err.message)
        }
    }
    render() {
        const { dataSource, datalist, marketlist, loading, shareClass, share, eff_date,newdatalist } = this.state

        return (
            <div className='fairmarketvalue'>

             
                <Spin tip={__("Loading...")} spinning={loading}>
                    <Echarts
                        dataSource={dataSource}
                        datalist={datalist}
                        marketlist={marketlist}
                        share={share}
                        eff_date={eff_date}
                    ></Echarts>
                    <FMVTable
                        newdatalist={newdatalist}
                        dataSource={dataSource}
                        shareClass={shareClass}
                        payOverView={this.payOverView}
                    ></FMVTable>
                </Spin>
            </div >
        )
    }
}
export default FairMarketValue