import React, { Component } from 'react';
import { Menu, Badge, Button, Tag } from 'antd';
import { withRouter } from 'react-router-dom';
import defaultMenuConfig from './config';
import security from 'stores/security';
import { Scrollbars } from 'react-custom-scrollbars';
import newTag from './images/tag-new-icon-2x.png';
import lock from './images/Lock.png';

import './style.scss';

const SubMenu = Menu.SubMenu

@withRouter
export default class NavMenu extends Component {
	defaultPath = this.props.location.pathname
	findOpendKeys = path => {
		const menuConfig = this.props.menuConfig || defaultMenuConfig
		let res = '', len = menuConfig.length

		for (let i = 0; i < len; i++) {
			const item = menuConfig[i]
			if (item.children && item.children.length > 0) {
				const tmp = item.children.find(item => path.includes(item.path))

				if (tmp) {
					return item.title
				}

				if (path.includes(item.path)) {
					return item.title
				}
			}
		}

		return res
	}

	isSubMenuMatch = (value, pathname) => {
		let isChildMatch = value.path === pathname
		if (isChildMatch) {
			return isChildMatch
		}
		return value?.subMenu?.length && value?.subMenu.some(i => i.path === pathname)
	}

	processPath = (pathname) => {
		const superPath = pathname.split('/').filter(Boolean)
		const menuConfig = this.props.menuConfig || defaultMenuConfig
		if (!menuConfig.some(val => val.children && val.children.some(value => this.isSubMenuMatch(value, pathname))) && superPath.length > 2) {
			const processedPathname = '/' + superPath.slice(0, (superPath.length - 1)).join('/')

			return processedPathname
		}

		return pathname
	}

	state = {
		selectedKey: this.processPath(this.defaultPath),
		openKeys: [this.findOpendKeys(this.defaultPath)],
	}

	handleClick = ({ key }) => {
		this.setState({ selectedKey: key })
	}

	goTo = path => {
		if (path) {
			this.props.history.push(path)
		}
	}

	componentWillReceiveProps(nextProps) {
		const { location } = nextProps;
		const { pathname } = location || {}
		const processedPathname = this.processPath(pathname)

		if (pathname && this.state.selectedKey !== processedPathname) {
			this.setState({ selectedKey: processedPathname, openKeys: [this.findOpendKeys(pathname)] })
		}
	}

	goToTutorials = (path) => {
		const pathMap = {
			B: `/home/${path}`,
			C: `/client/${path}`,
			TASK: `/task/${path}`
		}

		this.props.history.push(pathMap[security.platform] || '/')
	}

	render() {
		const { selectedKey } = this.state
		const menuConfig = this.props.menuConfig || defaultMenuConfig
		return <div className="sprout-menu-c">
			<div className="sprout-menu-wrap" >
				<Scrollbars>
					<Menu mode="horizontal" inlineIndent={12} triggerSubMenuAction="click" onSelect={this.handleClick} selectedKeys={[selectedKey]} onOpenChange={(val) => this.setState({ openKeys: val })}>
						{
							menuConfig.map((val) => {
								if (!val.children || val.children.length === 0 || security.hasFunctionSingle(val.path)) {
									return val.access && <Menu.Item onClick={() => { this.goTo(val.path) }} disabled={val.disabled} key={val.path}><div>
										<span>{val.title}&nbsp;</span>
										<Badge overflowCount={9} style={{ fontSize: 12 }} size="small" count={Number(val.task || 0)} title={val.task} />
									</div>{security.hasFunctionSingle(val.path) && <img src={lock} alt="img" className="tag-lock" />}</Menu.Item>
								} else return val.access && <SubMenu key={val.title} disabled={val.disabled} title={val.title}>
									{
										val.children.map(item => {
											if (item?.subMenu) {
												return item.access && <SubMenu key={item.title} disabled={item.disabled} title={item.title}>
													{
														item?.subMenu.map(subItem => {
															if (security.hasPermission(subItem?.permission)) {
																// return subItem.hide !== true && <Menu.Item key={subItem.key || subItem.path} onClick={() => {this.goTo(subItem.path)}}>{subItem.label}{subItem.showTag && <img src={newTag} alt="img" className="tag-new-icon" /> }{security.hasFunctionSingle(subItem.path) && <img src={lock} alt="img" className="tag-lock" />}</Menu.Item>
																return subItem.hide !== true && <Menu.Item key={subItem.key || subItem.path} onClick={() => { this.goTo(subItem.path) }}>{subItem.label}{subItem.showTag && <Tag color="#00A9CD" style={{ marginLeft: '5px' }}>Beta</Tag>}{security.hasFunctionSingle(subItem.path) && <img src={lock} alt="img" className="tag-lock" />}</Menu.Item>
															}
														})
													}
												</SubMenu>
											} else {
												// return (item.access) && <Menu.Item disabled={item.disabled} key={item.key || item.path} onClick={() => {this.goTo(item.path)}}>{item.title}{item.showTag && <img src={newTag} alt="img" className="tag-new-icon" /> }{security.hasFunctionSingle(item.path) && <img src={lock} alt="img" className="tag-lock" />}</Menu.Item>
												return (item.access) && <Menu.Item disabled={item.disabled} key={item.key || item.path} onClick={() => { this.goTo(item.path) }}>{item.title}{item.showTag && <Tag color="#00A9CD" style={{ marginLeft: '5px' }}>Beta</Tag>}{security.hasFunctionSingle(item.path) && <img src={lock} alt="img" className="tag-lock" />}</Menu.Item>
											}
										}).filter(Boolean)
									}
								</SubMenu>
							}).filter(Boolean)
						}
					</Menu>
					{this.props.children}
				</Scrollbars>
				{/* {security.platform == 'B' && */}
					<Button type="link" className="tutorials" onClick={() => this.goToTutorials('help')}>{__('Self-help')}</Button>
				{/* } */}
			</div>
		</div>
	}
}