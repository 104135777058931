import React from 'react';
import axios from 'axios';
import Utils from "Utils";
import {Link} from "react-router-dom";
import { post } from 'srcPath/http';

class EditShareClassPlanModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            saveStatus:0,
            existingPrefix:["ABC"],
            existingPlanName:["ABC"],
            data:{
                id:"",
                is_able_delete:"",
                issued_shares:45346,
                share_class_type:"PS",
                share_class_name:"",
                prefix:"",
                total_authorized_shares:"",
                certificate_shares_ornot:1,
                issue_price:"",
                par_value:"",
                seniority_level:1,
                pari_passu:0,
                original_issue_price:"",
                conversion_price:"",
                conversion_ratio:"",
                multiplier:"",
                parti_preferred:0,
                participating_preferred_cap:"",
                iscash:"Non-cash",
                dividend_yield:"",
                divi_type:"Cumulative",
                dividend_accrual:"",
                compounding_interest:"",
            },
            incorporationList : [],
            showErrorArr : ["dontshow","dontshow","dontshow","dontshow","dontshow"],
            currency:"USD",
            tabKey:1,

            seniorityDetails:[
                // {round_name:"Series Seed II Preferred",seniority:1,payout_order:"1st"},
                // {round_name:"Series Seed I Preferred",seniority:3,payout_order:"3rd"},
                // {round_name:"Series Seed III Preferred",seniority:4,payout_order:"4th"},
                // {round_name:"Series D Preferred",seniority:5,payout_order:"5th"},
                // {round_name:"Series D Preferred",seniority:1,payout_order:"1st"},
                // {round_name:"Series Seed II Preferred",seniority:2,payout_order:"2nd"},
                // {round_name:"Series Seed II Preferred",seniority:2,payout_order:"2nd"},
                // {round_name:"Common",seniority:6,payout_order:"6th"},
            ],
            dividendAccrual:[
                "Daily",
                "Weekly",
                "Monthly",
                "Quarterly (calendar year)",
                "Annually",
            ],
            compoundingInterest:[
                "Simple interest",
                "Daily",
                "Monthly",
                "Semi-annually",
                "Annually",
                "Quarterly (calendar year)",
            ],

        }
    }
    initData=(key,initData)=>{
        let curData = this.state.data;
        curData.id = initData.id;
        this.setState({
            data:curData
        })
        this.getShareClassInfo();
    }
    getShareClassInfo=()=>{
        let requestData = this.state.data;
        post('getShareClassInfo', {id:requestData.id}).then((response)=> {
            let serverData = response.data.data;
            if(response.data.code === 0){
                serverData.parti_preferred = serverData.participating_preferred;
                serverData.iscash = serverData.dividend;
                serverData.divi_type = serverData.dividend_type;
                serverData.certificate_shares_ornot = serverData.is_certificate_shares
                this.setState({
                    data:serverData
                })
                console.log(this.state.data)
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
            //用到this要注意this指向
        }).catch(function (error) {
            console.log(error);
        });
    }
    // setCurrency=()=>{
    //     axios.post('/request.php', {
    //         "company_id":Utils.getLocalCompanyInfo().company_id,
    //         action: 'getCurrency',
    //         data: {
    //             "withPrefix":1,
    //             "withPlanName":1,
    //         }
    //     }).then((response) => {
    //         console.log(response);
    //         if (response.data.code === 0) {
    //             this.setState({
    //                 currency:response.data.data.currency,
    //                 existingPrefix:response.data.data.prefixList,
    //                 existingPlanName:response.data.data.planNameList,
    //             })
    //         } else {
    //             console.log("failed, error code is: " + response.data.code);
    //         }
    //         //用到this要注意this指向
    //     }).catch(function (error) {
    //         console.log(error);
    //     });
    // }

    changeTab=(key)=>{
        this.setState({
            tabKey:key
        })
    }

    componentDidMount(){
        let seniorityDetails = this.state.seniorityDetails;
        seniorityDetails.sort(function(a,b){return a.seniority>b.seniority?1:-1});
    }

    getIncorporationList=()=>{
        // $(".stock-li4").removeClass("stock-grayli").addClass("stock-did-li");
        axios.post('/request.php', {
            action:'getDocs',
            company_id:Utils.getLocalCompanyInfo().company_id,
            data:{}
        }).then((response)=> {
            console.log(response);
            if(response.data.code === 0){
                this.setState({
                    incorporationList:response.data.data.incorporation_docs
                })
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
            //用到this要注意this指向
        }).catch(function (error) {
            console.log(error);
        });

    }

    nextStep=(start,end)=>{
        $("li").removeClass("active");
        $("li a").attr({"aria-expanded":false});
        if(start===1 && end ===2){
            let curErrorArr = this.state.showErrorArr;
            curErrorArr[0] = "dontshow";
            this.setState({
                showErrorArr:curErrorArr
            })
            $("#updateStep2").addClass("active");
            this.changeTab(2);
        }
        if(start===2 && end ===3){
            let curErrorArr = this.state.showErrorArr;
            curErrorArr[1] = "dontshow";
            this.setState({
                showErrorArr:curErrorArr
            });
            $("#updateStep3").addClass("active");
            this.changeTab(3)
        }
        if(start===3 && end ===4){
            let curErrorArr = this.state.showErrorArr;
            curErrorArr[2] = "dontshow";
            this.setState({
                showErrorArr:curErrorArr
            });
            $("#updateStep4").addClass("active");
            this.changeTab(4)
        }
        if(start===2 && end ===1){
            let curErrorArr = this.state.showErrorArr;
            curErrorArr[2] = "dontshow";
            this.setState({
                showErrorArr:curErrorArr
            })
            $("#updateStep1").addClass("active");
            this.changeTab(1);
        }
        if(start===3 && end ===2){
            let curErrorArr = this.state.showErrorArr;
            curErrorArr[3] = "dontshow";
            this.setState({
                showErrorArr:curErrorArr
            })
            $("#updateStep2").addClass("active");
            this.changeTab(2)
        }
        if(start===4 && end ===3){
            $("#updateStep3").addClass("active");
            this.changeTab(3)
        }
    }
    recordChecked=(e)=>{
        let dataKey = e.target.name;
        let curData = this.state.data;
        if(curData[dataKey]==1){
            curData[dataKey]=0
        }else{
            curData[dataKey]=1
        }
        this.setState({
            data:curData
        })
    }
    recordData=(e)=>{
        let dataKey = e.target.name;
        let curData = this.state.data;
        let dataVal = e.target.value;
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        })
        console.log(this.state.data.share_class_type)
    }
    recordDividend=(e)=>{
        let curData = this.state.data;
        if(curData.dividend ==="Non-cash"){
            curData.dividend = "Cash"
        }else{
            curData.dividend = "Non-cash"
        }
        this.setState({
            data:curData
        })
        console.log(this.state.data.dividend)
    }

    editNewShareClass=()=>{
        if(this.state.saveStatus === 1){
            return;
        }
        this.setState({
            saveStatus:1
        })

        var requestData = this.state.data;
        requestData.dividend = this.state.data.iscash;
        requestData.dividend_type = this.state.data.divi_type;
        requestData.participating_preferred = this.state.data.parti_preferred;
        requestData.is_certificate_shares = this.state.data.certificate_shares_ornot
        axios.post('/request.php', {
            action: 'editShareClass',
            company_id: Utils.getLocalCompanyInfo().company_id,
            data: requestData
        }).then((response) => {
            console.log(response);
            if (response.data.code === 0) {
                this.props.closeEditShareClass();
                this.closePlanModal();
                this.setState({
                    saveStatus:0
                });
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
            //用到this要注意this指向
        }).catch(function (error) {
            console.log(error);
        });
    }
    deleteShareClass=()=>{
        let requestData = this.state.data;
        post('deleteShareClass', {id:requestData.id,}).then((response)=> {
            if(response.data.code === 0){
                this.props.closeEditShareClass();
                this.setState({
                    createStatus:0
                })
                this.props.getScheduleList();
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
            //用到this要注意this指向
        }).catch(function (error) {
            console.log(error);
        });
    }
    showError=(key,e)=>{
        let curErrorArr = this.state.showErrorArr;
        curErrorArr[key] = "show";
        if(key===2){
            $("#original_issue_price_input").addClass("has-error");
        }
        this.setState({
            showErrorArr:curErrorArr
        });
    }
    resetDefaultActive=()=>{
        $("#plan_modal .tab-pane").removeClass("active");
        $(".ps_plan_nav .stock-li").removeClass("active");
        $("#updateShareClassStep1").addClass("active");
        $("#updateStep1").addClass("active");
    }
    closePlanModal=()=>{
        $("#plan_modal .tab-pane").removeClass("active");
        $(".ps_plan_nav .stock-li").removeClass("active");
        $("#updateShareClassStep1").addClass("active");
        $("#updateStep1").addClass("active");
        let initData = {
            share_class_type:"PS",
            share_class_name:"",
            prefix:"",
            total_authorized_shares:"",
            certificate_shares_ornot:1,
            issue_price:"",
            par_value:"",
            seniority_level:1,
            pari_passu:1,
            original_issue_price:"",
            conversion_price:"",
            conversion_ratio:"",
            multiplier:"",
            parti_preferred:0,
            participating_preferred_cap:"",
            iscash:"Non-cash",
            dividend_yield:"",
            divi_type:"Cumulative",
            dividend_accrual:"",
            compounding_interest:"",
        };
        this.setState({
            tabKey:1,
            data:initData
        })
    }
    recordNumber=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        if(e.target.name==="seniority_level"){
            let seniorityDetails = this.state.seniorityDetails;
            //遍历seniorityDetails，将type为new的seniority改为新值，并重新排序
            for(let i=0;i<seniorityDetails.length;i++){
                if(seniorityDetails[i].hasOwnProperty("type")){
                    seniorityDetails[i].seniority_level = dataVal;
                    this.setState({
                        seniorityDetails:seniorityDetails
                    });
                }
            }
            this.sortSeniorityLevel();
        }
        curData[dataKey] = Utils.checkNumInput(dataVal);
        this.setState({
            data:curData
        })
    }

    // recordNumber=(e)=>{
    //     let dataKey = e.target.name;
    //     let dataVal = e.target.value;
    //     let curData = this.state.data;
    //     if(e.target.name==="seniority"){
    //         var seniorityDetails = this.state.seniorityDetails;
    //         seniorityDetails.sort(function(a,b){return a.seniority>b.seniority?1:-1});
    //     }
    //     curData[dataKey] = Utils.checkNumInput(dataVal) ;
    //     this.setState({
    //         data:curData
    //     })
    // }
    recordDecimalNumber=(e)=>{
        let curData = this.state.data;
        let curVal = e.target.value;
        let dataKey = e.target.name;
        // if(e.target.name==="seniority"){
        //     var seniorityDetails = this.state.seniorityDetails;
        //     seniorityDetails.sort(function(a,b){return a.seniority>b.seniority?1:-1});
        // }
        curData[dataKey] = Utils.getNumFormatWithTwoComma(curVal);
        // curData[dataKey] = Utils.getNumFormatWithComma(curVal);
        this.setState({
            data:curData
        });
    }
    render(){
        let contentWidth = 'fillout-content';
        let step1Error = 1;
        let step2Error = 1;
        let step3Error = 1;
        if(this.state.data.share_class_name!== "" && this.state.data.prefix!== ""  && this.state.data.total_authorized_shares!== ""){
            step1Error = 0;
        }
        if(this.state.data.issue_price!== ""||this.state.data.par_value!== ""){
            step2Error = 0;
        }
        if(this.state.data.original_issue_price!== "" ){
            step3Error = 0;
        }
        if(this.state.tabKey===3 ){
            contentWidth = 'content860'
        }
        if(this.state.tabKey===4){
            contentWidth = 'content760'
        }
        let isLegalPrefix = 1; //默认为合法的输入
        for(let i=0;i<this.state.existingPrefix.length;i++){
            if(this.state.data.prefix == this.state.existingPrefix[i]){
                isLegalPrefix = 0;  //有重复改为非法
            }
        }
        let isLegalPlanName = 1; //默认为合法的输入
        for(let i=0;i<this.state.existingPlanName.length;i++){
            if(this.state.data.share_class_name == this.state.existingPlanName[i]){
                isLegalPlanName = 0;  //有重复改为非法
            }
        }
        let certificateShare = 'Yes';
        if(this.state.data.is_certificate_shares===0){
            certificateShare = 'No'
        }

        return(
            <div className={"reset_margin modal-content "+contentWidth} id={"plan_modal"}>
                <div className="modal-header box-header">
                    {this.state.tabKey ==1 &&
                    <Link to="#shareClass" aria-controls="shareClass" role="tab" data-toggle="tab" type="button" className="back-btn"></Link>
                    }
                    {this.state.tabKey==2 &&
                    <Link to="#updateShareClassStep1" onClick={this.nextStep.bind(this,2,1)} aria-controls="updateShareClassStep1" role="tab" data-toggle="tab" type="button" className="back-btn"></Link>
                    }
                    {this.state.tabKey==3 &&
                    <Link to="#updateSharePriceStep2" aria-controls="updateSharePriceStep2" onClick={this.nextStep.bind(this,3,2)} role="tab" data-toggle="tab" type="button" className="back-btn"></Link>
                    }
                    {this.state.tabKey==4 &&
                    <Link to="#updatePreferenceStep3" aria-controls="updatePreferenceStep3" onClick={this.nextStep.bind(this,4,3)} role="tab" data-toggle="tab" type="button" className="back-btn"></Link>
                    }
                    <button type="button" onClick={this.resetDefaultActive} className="close shut-btn" data-toggle="tab" data-target="#shareClass">×</button>
                    <h4 className="modal-title">Edit Share Class</h4>
                </div>
                <div className="modal-body">
                    <div className="vesting-box">
                        <ul className="nav vesting-nav ps_plan_nav" role="tablist">
                            <li role="presentation" className="stock-li active" id="updateStep1">
                                <a className="stock-a " onClick={this.changeTab.bind(this, 1)}
                                   href="#updateShareClassStep1" role="tab" data-toggle="tab">1</a>
                                <p className="shares_p">Update a share class</p>
                                <div className="hr1"></div>
                            </li>
                            {step1Error === 0 && isLegalPrefix === 1 && isLegalPlanName===1 &&
                            <li role="presentation" className="stock-li" id="updateStep2">
                                <a className="stock-a" onClick={this.changeTab.bind(this, 2)}
                                   href="#updateSharePriceStep2" role="tab" data-toggle="tab">2</a>
                                <p className="shares_p">Share prices</p>
                                <div className="hr1"></div>
                            </li>
                            }
                            {(step1Error === 1 || isLegalPrefix === 0|| isLegalPlanName===0) &&
                            <li role="presentation" className="stock-li stock-nodoing">
                                <a className="stock-a " href="javascript:return false;">2</a>
                                <p className="shares_p">Share prices</p>
                                <div className="hr1"></div>
                            </li>
                            }
                            {step2Error === 0  &&
                            <li role="presentation" className="stock-li" id="updateStep3">
                                <a className="stock-a" onClick={this.changeTab.bind(this, 3)}
                                   href="#updatePreferenceStep3" role="tab" data-toggle="tab">3</a>
                                <p className="shares_p">Preferences</p>
                                <div className="hr1"></div>
                            </li>
                            }
                            {step2Error === 1 &&
                            <li role="presentation" className="stock-li stock-nodoing">
                                <a className="stock-a " href="javascript:return false;">3</a>
                                <p className="shares_p">Preferences</p>
                                <div className="hr1"></div>
                            </li>
                            }
                            {step3Error === 1 &&
                            <li role="presentation" className="stock-li stock-nodoing">
                                <a className="stock-a " href="javascript:return false;">4</a>
                                <p className="shares_p">Review</p>
                            </li>
                            }
                            {step3Error === 0  &&
                            <li role="presentation" className="stock-li" id="updateStep4">
                                <a className="stock-a" onClick={this.changeTab.bind(this, 4)} href="#updateReviewStep4"
                                   role="tab" data-toggle="tab">4</a>
                                <p className="shares_p">Review</p>
                            </li>
                            }
                        </ul>
                    </div>
                    <div className="tab-content">
                        <div role="tabpanel" className="tab-pane active" id="updateShareClassStep1">
                            {this.state.data.issued_shares >0 &&
                            <div className="stock-form marginTop30">
                                <div className="alert alert-warning alert-dismissable pink-box" role="alert">
                                    <p>Contact Sprout to change the information below. <span><a >Get in touch</a></span></p>
                                </div>
                            </div>
                            }
                            <div className="stock-titbox edit_share_class_titbox">
                                <p>Update a share class</p>
                                {this.state.data.issued_shares === 0 &&
                                <Link  type="button" className="share_class_del_btn" onClick={this.deleteShareClass}>
                                    <span className="colorWhite glyphicon glyphicon-trash"></span>
                                </Link>
                                }
                            </div>
                            {this.state.showErrorArr[0] ==="show" &&
                            <div className="stock-form">
                                <div className="alert alert-warning alert-dismissable pink-box" role="alert">
                                    <p>Required field(s) can't be empty.</p>
                                </div>
                            </div>
                            }
                            {isLegalPrefix ===0 &&
                            <div className="stock-form" id="prefix_exist_error">
                                <div className="alert alert-warning alert-dismissable pink-box" role="alert">
                                    <p>Share class prefix already exists. Please select another.</p>
                                </div>
                            </div>
                            }
                            {isLegalPlanName ===0 &&
                            <div className="stock-form" id="prefix_exist_error">
                                <div className="alert alert-warning alert-dismissable pink-box" role="alert">
                                    <p>Share class name already exists. Please select another.</p>
                                </div>
                            </div>
                            }
                            {this.state.data.issued_shares === 0 &&
                            <div className="form-horizontal stock-form">
                                <div className="form-group">
                                    <label className="col-xs-4 add-label">Class type</label>
                                    <div className="col-xs-3">
                                        <div className="checkbox text-muted equity-checkbox">
                                            <input type="radio" onChange={this.recordData}
                                                   className="send-box equity-checkbox" id="edit_common_type"
                                                   name="share_class_type" value="CS"
                                                   checked={this.state.data.share_class_type == "CS"}/>
                                            <label className="send-div" htmlFor="edit_common_type"></label><span
                                            className="type-label"> &nbsp;&nbsp;Common</span>
                                        </div>
                                    </div>
                                    <div className="col-xs-3">
                                        <div className="checkbox text-muted equity-checkbox">
                                            <input type="radio" onChange={this.recordData} value="PS"
                                                   checked={this.state.data.share_class_type == "PS"}
                                                   className="send-box equity-checkbox" id="edit_ps_type"
                                                   name="share_class_type"/>
                                            <label className="send-div" htmlFor="edit_ps_type"></label><span
                                            className="type-label"> &nbsp;&nbsp;Preferred</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group ">
                                    <label className="col-xs-4 add-label">Share class</label>
                                    <div className="col-xs-5">
                                        {this.state.data.share_class_type === "PS" &&
                                        <input name="share_class_name" onChange={this.recordData}
                                               value={this.state.data.share_class_name} className="form-control"
                                               type="text" placeholder="Preferred"/>
                                        }
                                        {this.state.data.share_class_type === "CS" &&
                                        <input name="share_class_name" onChange={this.recordData}
                                               value={this.state.data.share_class_name} className="form-control"
                                               type="text" placeholder="Common"/>
                                        }
                                    </div>
                                </div>
                                <div className="form-group ">
                                    <div>
                                        <label className="col-xs-4 add-label">Share class prefix</label>
                                        <div className="col-xs-2">
                                            {this.state.data.share_class_type === "PS" &&
                                            <input name="prefix" value={this.state.data.prefix}
                                                   onChange={this.recordData} className="form-control" required
                                                   type="text" placeholder="PS"/>
                                            }
                                            {this.state.data.share_class_type === "CS" &&
                                            <input name="prefix" value={this.state.data.prefix}
                                                   onChange={this.recordData} className="form-control" required
                                                   type="text" placeholder="CS"/>
                                            }
                                        </div>
                                        <p className="text-muted prefix-p col-xs-7 col-xs-push-4">Letters and numbers
                                            only.</p>
                                    </div>
                                </div>
                                <div className="form-group ">
                                    <label className="col-xs-4 add-label">Total authorized shares</label>
                                    <div className="col-xs-4">
                                        <div className="input-group">
                                            <input type="text" name="total_authorized_shares"
                                                   onChange={this.recordNumber}
                                                   value={this.state.data.total_authorized_shares} required
                                                   className="form-control" placeholder="e.g. 1,000"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-xs-4 add-label ques-label">Certificated shares
                                        <a id="stock-quespop" className="create-ques stock-ques">
                                            <p className="captable-tiptext tiptext-top108">If you do not select this,
                                                Sprout can help your company to issue digital share certificates to
                                                stakeholders.</p>
                                        </a>
                                    </label>
                                    <div className="col-xs-5">
                                        <div className="checkbox text-muted equity-checkbox">
                                            <input type="checkbox" className="send-box equity-checkbox"
                                                   id="certificate_shares_ornot" name="certificate_shares_ornot"
                                                   onChange={this.recordChecked}
                                                   checked={this.state.data.certificate_shares_ornot == 1}/>
                                            <label className="send-div" htmlFor="certificate_shares_ornot"></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            {this.state.data.issued_shares >= 1 &&
                            <div className="form-horizontal stock-form">
                                <div className="form-group">
                                    <label className="col-xs-4 add-label">Class type</label>
                                    <div className="col-xs-3">
                                        <div className="checkbox text-muted equity-checkbox">
                                            <input type="radio"  onChange={this.recordData} disabled className="send-box equity-checkbox" id="edit_common_type" name="share_class_type" value="CS" checked={this.state.data.share_class_type==="CS"} />
                                            <label className="send-div" htmlFor="edit_common_type"></label><span className="type-label"> &nbsp;&nbsp;Common</span>
                                        </div>
                                    </div>
                                    <div className="col-xs-3">
                                        <div className="checkbox text-muted equity-checkbox">
                                            <input type="radio" onChange={this.recordData} value="PS" disabled checked={this.state.data.share_class_type==="PS"} className="send-box equity-checkbox" id="edit_ps_type" name="share_class_type"/>
                                            <label className="send-div" htmlFor="edit_ps_type"></label><span className="type-label"> &nbsp;&nbsp;Preferred</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group ">
                                    <label className="col-xs-4 add-label">Share class</label>
                                    <div className="col-xs-5">
                                        {this.state.data.share_class_type === "PS" &&
                                        <input name="share_class_name" disabled onChange={this.recordData} value={this.state.data.share_class_name} className="form-control"  type="text" placeholder="Preferred"/>
                                        }
                                        {this.state.data.share_class_type === "CS" &&
                                        <input name="share_class_name" disabled onChange={this.recordData} value={this.state.data.share_class_name} className="form-control"  type="text" placeholder="Common"/>
                                        }
                                    </div>
                                </div>
                                <div className="form-group ">
                                    <div>
                                        <label className="col-xs-4 add-label">Share class prefix</label>
                                        <div className="col-xs-2">
                                            {this.state.data.share_class_type === "PS" &&
                                            <input name="prefix" value={this.state.data.prefix} disabled onChange={this.recordData} className="form-control" required type="text" placeholder="PS"/>
                                            }
                                            {this.state.data.share_class_type === "CS" &&
                                            <input name="prefix" value={this.state.data.prefix} disabled onChange={this.recordData} className="form-control" required type="text" placeholder="CS"/>
                                            }
                                        </div>
                                        <p className="text-muted prefix-p col-xs-7 col-xs-push-4">Letters and numbers only.</p>
                                    </div>
                                </div>
                                <div className="form-group ">
                                    <label className="col-xs-4 add-label">Total authorized shares</label>
                                    <div className="col-xs-4">
                                        <div className="input-group">
                                            <input type="text" name="total_authorized_shares" disabled onChange={this.recordNumber} value={this.state.data.total_authorized_shares} required className="form-control" placeholder="e.g. 1,000"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-xs-4 add-label ques-label">Certificated shares
                                        <a id="stock-quespop" className="create-ques stock-ques">
                                            <p className="captable-tiptext tiptext-top108">If you do not select this, Sprout can help your company to issue digital share certificates to stakeholders.</p>
                                        </a>
                                    </label>
                                    <div className="col-xs-5">
                                        <div className="checkbox text-muted equity-checkbox">
                                            <input type="checkbox" className="send-box equity-checkbox" disabled id="ps_certificated_shares_ornot" name="certificate_shares_ornot" onChange={this.recordChecked} checked={this.state.data.certificate_shares_ornot==1} />
                                            <label className="send-div" htmlFor="ps_certificated_shares_ornot"></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                        <div role="tabpanel" className="tab-pane" id="updateSharePriceStep2">
                            <div className="stock-titbox">
                                <p>Create prices</p>
                            </div>
                            <div className="stock-form">
                                {this.state.showErrorArr[1] === "show" &&
                                <div className="alert alert-warning alert-dismissable pink-box" role="alert">
                                    <p>Required field (s) can't be empty.</p>
                                </div>
                                }
                            </div>
                            <form className="form-horizontal stock-form">
                                {this.state.data.share_class_type==="CS" &&
                                <div className="form-group ">
                                    <label className="col-xs-4 add-label">Par Value</label>
                                    <div className="col-xs-4">
                                        <div className="input-group">
                                            <span className="input-group-addon" id="vesting-addon1">{this.state.currency}</span>
                                            <input type="text" name="par_value" onChange={this.recordDecimalNumber} value={this.state.data.par_value} required className="form-control" aria-describedby="vesting-addon1" placeholder="e.g. 0.01"/>
                                        </div>
                                    </div>
                                    <div className="stock-tipbox">
                                        <p className="col-xs-6 col-xs-push-4 text-muted">
                                            The minimum price per share that shares must be issued for in order to be fully paid.
                                        </p>
                                    </div>
                                </div>
                                }
                                {this.state.data.share_class_type === "PS" &&
                                <div className="form-group ">
                                    <label className="col-xs-4 add-label">Issue price</label>
                                    <div className="col-xs-4">
                                        <div className="input-group">
                                                    <span className="input-group-addon"
                                                          id="ps_issue_price_addon1">{this.state.currency}</span>
                                            <input type="text" name="issue_price"
                                                   onChange={this.recordDecimalNumber}
                                                   value={this.state.data.issue_price} required
                                                   className="form-control"
                                                   aria-describedby="ps_issue_price_addon1"
                                                   placeholder="e.g. 1.00"/>
                                        </div>
                                    </div>
                                    <div className="stock-tipbox">
                                        <p className="col-xs-7 col-xs-push-4 text-muted">Pre-money valuation +
                                            investment (Post money valuation) divided by Fully diluted shares
                                            outstanding.</p>
                                    </div>
                                </div>
                                }
                            </form>
                        </div>
                        <div role="tabpanel" className="tab-pane " id="updatePreferenceStep3">
                            <div className="stock-titbox">
                                <p>Preferences</p>
                            </div>
                            <div className="stock-form">
                                {this.state.showErrorArr[2] === "show" &&
                                <div className="alert alert-warning alert-dismissable pink-box" role="alert">
                                    <p>Required field(s) can't be empty.</p>
                                </div>
                                }
                            </div>
                            <div className="stock-form">
                                <div className="marginBott50">
                                    <div>
                                        <p className="font_weight500">Seniority details</p>
                                        <p className="text-muted">Enter the seniority details of the share class.</p>
                                        <div className="equity-tipbox">
                                            <div className="light-tip"></div>
                                            <p>Share class seniority is ordered so that seniority 1 is the most senior and will be paid out first.</p>
                                        </div>
                                    </div>
                                    <div className="form-horizontal">
                                        <div className="form-group add-items">
                                            <label className="col-xs-3 add-label">Seniority level</label>
                                            <div className="col-xs-4">
                                                <input value={this.state.data.seniority_level}
                                                       name="seniority_level" placeholder="e.g. 1"
                                                       onChange={this.recordNumber} className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-xs-3 add-label ques-label">Pari-passu<br/><i
                                                className="text-muted">- Optional</i></label>
                                            <div className="col-xs-5">
                                                <div className="checkbox text-muted equity-checkbox">
                                                    <input type="checkbox" className="send-box equity-checkbox"
                                                           checked={this.state.data.pari_passu == 1}
                                                           id="passu" name="pari_passu"
                                                           onChange={this.recordChecked}/>
                                                    <label className="send-div" htmlFor="passu"></label>
                                                </div>
                                                <p className="text-muted">Mark this share class as having equal
                                                    seniority to an existing share class. Share classes with
                                                    equal seniority will be paid out at the same time.</p>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-xs-3 add-label ques-label">Seniority
                                                preview</label>
                                            <div className="col-xs-9">
                                                <table
                                                    className="table viewHoldings-table vested-table marginTop10 no-marginBottom">
                                                    <tbody>
                                                    <tr className="first-row">
                                                        <td>Share class</td>
                                                        <td className="vested-3Td">Seniority</td>
                                                        <td>Payout order</td>
                                                    </tr>
                                                    {
                                                        this.state.seniorityDetails.map((value, key) => {
                                                            return (
                                                                <tr className="bg-lightgray" key={key}>
                                                                    <td>{value.round_name}</td>
                                                                    <td className="vested-3Td">{value.seniority}</td>
                                                                    <td>{value.payout_order}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    <tr className="vestedTr">
                                                        <td>{this.state.data.share_class_name}-{this.state.data.prefix}</td>
                                                        <td className="vested-3Td">{this.state.data.seniority_level}</td>
                                                        <td>4th</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.data.share_class_type==="PS" &&
                                <div>
                                    <div className="marginBott50">
                                        <div className="marginBott20">
                                            <p className="font_weight500">Rights and preferences</p>
                                            <p className="text-muted">Enter the rights and preferences of the share
                                                class.</p>
                                        </div>
                                        <div className="form-horizontal">
                                            <div className="form-group ">
                                                <label className="col-xs-3 add-label">Original issue price</label>
                                                <div className="col-xs-4">
                                                    <div className="input-group" id="original_issue_price_input">
                                                        <span className="input-group-addon">{this.state.currency}</span>
                                                        <input type="text" name="original_issue_price"
                                                               onChange={this.recordDecimalNumber}
                                                               value={this.state.data.original_issue_price} required
                                                               className="form-control" placeholder="e.g. 0.0001"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group ">
                                                <label className="col-xs-3 add-label">Conversion price<br/><i
                                                    className="text-muted">- Optional</i></label>
                                                <div className="col-xs-4">
                                                    <div className="input-group">
                                                                <span
                                                                    className="input-group-addon">{this.state.currency}</span>
                                                        <input type="text" name="conversion_price"
                                                               onChange={this.recordDecimalNumber}
                                                               value={this.state.data.conversion_price} required
                                                               className="form-control" placeholder="e.g. 0.0001"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group ">
                                                <label className="col-xs-3 add-label">Conversion Ratio<br/><i
                                                    className="text-muted">- Optional</i></label>
                                                <div className="col-xs-4">
                                                    <input type="text" name="conversion_ratio"
                                                           onChange={this.recordDecimalNumber}
                                                           value={this.state.data.conversion_ratio} required
                                                           className="form-control" placeholder="e.g. 0.0001"/>
                                                </div>
                                                <div className="stock-tipbox">
                                                    <p className="col-xs-7  text-muted">Conversion will be
                                                        calculated with conversion ratio instead of conversion price
                                                        if both are entered.</p>
                                                </div>
                                            </div>
                                            <div className="form-group ">
                                                <label className=" col-xs-3 add-label">Multiplier<br/><i
                                                    className="text-muted">- Optional</i></label>
                                                <div className=" col-xs-4">
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="1"
                                                               name="multiplier" value={this.state.data.multiplier}
                                                               onChange={this.recordDecimalNumber}/>
                                                        <span className="input-group-addon">X</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-xs-3 add-label">Participating Preferred</label>
                                                <div className="col-xs-1">
                                                    <div className="checkbox text-muted equity-checkbox">
                                                        <input type="radio" onChange={this.recordChecked} className="send-box equity-checkbox" id="update_preferred_yes" name="parti_preferred" value="1" checked={this.state.data.parti_preferred == 1}/>
                                                        <label className="send-div" htmlFor="update_preferred_yes"></label>
                                                        <span className="type-label"> &nbsp;&nbsp;Yes</span>
                                                    </div>
                                                </div>
                                                <div className="col-xs-1">
                                                    <div className="checkbox text-muted equity-checkbox">
                                                        <input type="radio" onChange={this.recordChecked} className="send-box equity-checkbox" id="update_preferred_no" name="parti_preferred" value="0" checked={this.state.data.parti_preferred == 0}/>
                                                        <label className="send-div" htmlFor="update_preferred_no"></label>
                                                        <span className="type-label"> &nbsp;&nbsp;No</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.data.parti_preferred == 1 &&
                                            <div className="form-group ">
                                                <label className=" col-xs-3 add-label">Participating preferred
                                                    cap<br/><i className="text-muted">- Optional</i></label>
                                                <div className=" col-xs-4">
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="1"
                                                               name="participating_preferred_cap"
                                                               value={this.state.data.participating_preferred_cap}
                                                               onChange={this.recordDecimalNumber}/>
                                                        <span className="input-group-addon">X</span>
                                                    </div>
                                                </div>
                                                <div className="stock-tipbox">
                                                    <p className="col-xs-7 text-muted">Blank or zero means there is
                                                        no cap. No cap means the preferred classes are fully
                                                        participating.</p>
                                                </div>
                                            </div>
                                            }

                                        </div>
                                    </div>
                                    <div className="marginBott20">
                                        <div className="marginBott20">
                                            <p className="font_weight500">Dividends</p>
                                            <p className="text-muted">Enter the dividend details of the share
                                                class.</p>
                                        </div>
                                        <div className="form-horizontal">
                                            <div className="form-group">
                                                <label className="col-xs-3 add-label">Dividend</label>
                                                <div className="col-xs-2">
                                                    <div className="checkbox text-muted equity-checkbox">
                                                        <input type="radio"  onChange={this.recordData} className="send-box equity-checkbox" id="divi_non_cash" name="iscash" value="Non-cash" checked={this.state.data.iscash==="Non-cash"} />
                                                        <label className="send-div" htmlFor="divi_non_cash"></label><span className="type-label"> &nbsp;&nbsp;Non-cash</span>
                                                    </div>
                                                </div>
                                                <div className="col-xs-1">
                                                    <div className="checkbox text-muted equity-checkbox">
                                                        <input type="radio"  onChange={this.recordData} className="send-box equity-checkbox" id="divi_cash" name="iscash" value="Cash" checked={this.state.data.iscash==="Cash"}/>
                                                        <label className="send-div" htmlFor="divi_cash"></label><span className="type-label"> &nbsp;&nbsp;Cash</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.data.iscash == "Non-cash" &&
                                            <div>
                                                <div className="form-group ">
                                                    <label className="col-xs-3 add-label">Dividend yield</label>
                                                    <div className="col-xs-4">
                                                        <div className="input-group">
                                                            <input type="text" name="dividend_yield"
                                                                   onChange={this.recordDecimalNumber}
                                                                   value={this.state.data.dividend_yield} required
                                                                   className="form-control"
                                                                   placeholder="e.g. 3.5%"/>
                                                            <span className="input-group-addon">%</span>
                                                        </div>
                                                    </div>
                                                    <div className="stock-tipbox">
                                                        <p className="col-xs-7 col-xs-push-3 text-muted">(Per share
                                                            dividend ÷ Price/share) x 100 = Dividend yield<br/>
                                                            E.g. ($0.01125 ÷ $0.75) x 100 = 1.5%.</p>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-xs-3 add-label">Dividend Type</label>
                                                    <div className="col-xs-2">
                                                        <div className="checkbox text-muted equity-checkbox">
                                                            <input type="radio" onChange={this.recordData} className="send-box equity-checkbox" id="cumu_type" name="divi_type" value="Cumulative" checked={this.state.data.divi_type == "Cumulative"}/>
                                                            <label className="send-div" htmlFor="cumu_type"></label><span className="type-label"> &nbsp;&nbsp;Cumulative</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-3">
                                                        <div className="checkbox text-muted equity-checkbox">
                                                            <input type="radio" onChange={this.recordData} className="send-box equity-checkbox" id="non_cumu_type" name="divi_type" value="Non-cumulative" checked={this.state.data.divi_type == "Non-cumulative"}/>
                                                            <label className="send-div" htmlFor="non_cumu_type"></label><span
                                                            className="type-label"> &nbsp;&nbsp;Non-cumulative</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group add-items">
                                                    <label className="col-xs-3 add-label">Dividend accrual</label>
                                                    <div className="col-xs-4">
                                                        <select value={this.state.data.dividend_accrual}
                                                                name="dividend_accrual" onChange={this.recordData}
                                                                className="form-control select-container">
                                                            <option value=""></option>
                                                            {this.state.dividendAccrual.map((value, key) => {
                                                                return (
                                                                    <option key={key} value={value}>{value}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group add-items">
                                                    <label className="col-xs-3 add-label">Compounding
                                                        interest</label>
                                                    <div className="col-xs-4">
                                                        <select value={this.state.data.compounding_interest}
                                                                name="compounding_interest"
                                                                onChange={this.recordData}
                                                                className="form-control select-container">
                                                            <option value=""></option>
                                                            {this.state.compoundingInterest.map((value, key) => {
                                                                return (
                                                                    <option key={key} value={value}>{value}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        <div role="tabpanel" className="tab-pane " id="updateReviewStep4">
                            <div className="stock-titbox">
                                <p>Review</p>
                            </div>
                            <div className="review-contentbox">
                                <p><span className="title-img glyphicon glyphicon-file"></span>General Information</p>
                                <div className="review-tablebox">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>Class type</td>
                                            <td>{this.state.data.share_class_type}</td>
                                        </tr>
                                        <tr>
                                            <td>Name</td>
                                            <td>{this.state.data.share_class_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Prefix</td>
                                            <td>{this.state.data.prefix}</td>
                                        </tr>
                                        <tr>
                                            <td>Total authorized shares</td>
                                            <td>{this.state.data.total_authorized_shares}</td>
                                        </tr>
                                        <tr>
                                            <td>Certificated shares</td>
                                            <td>{Utils.getYesOrNoText(this.state.data.certificate_shares_ornot)}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="review-contentbox">
                                <p><span className="title-img glyphicon glyphicon-usd"></span>Share prices</p>
                                <div className="review-tablebox">
                                    <table>
                                        <tbody>
                                        {this.state.data.share_class_type === "CS" &&
                                        <tr>
                                            <td>Par value</td>
                                            <td>{this.state.currency} {this.state.data.par_value}</td>
                                        </tr>
                                        }
                                        {this.state.data.share_class_type === "PS" &&
                                        <tr>
                                            <td>Issue Price</td>
                                            <td>{this.state.currency} {this.state.data.issue_price}</td>
                                        </tr>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {this.state.data.share_class_type === "PS" &&
                            <div className="review-contentbox">
                                <p><span className="title-img glyphicon glyphicon-usd"></span>Preferences</p>
                                <div className="review-tablebox">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>Seniority</td>
                                            <td>{this.state.data.seniority_level}</td>
                                        </tr>
                                        <tr>
                                            <td>Pari-passu</td>
                                            <td>{Utils.getYesOrNoText(this.state.data.pari_passu)}</td>
                                        </tr>
                                        <tr>
                                            <td>Original issue price</td>
                                            <td>{this.state.currency} {this.state.data.original_issue_price}</td>
                                        </tr>
                                        <tr>
                                            <td>Conversion price</td>
                                            {this.state.data.conversion_price == "" &&
                                            <td>0</td>
                                            }
                                            {this.state.data.conversion_price !== "" &&
                                            <td>{this.state.currency} {this.state.data.conversion_price}</td>
                                            }
                                        </tr>
                                        <tr>
                                            <td>Conversion ratio</td>
                                            {this.state.data.conversion_ratio == "" &&
                                            <td>0</td>
                                            }
                                            {this.state.data.conversion_ratio !== "" &&
                                            <td>{this.state.data.conversion_ratio}</td>
                                            }

                                        </tr>
                                        <tr>
                                            <td>Multiplier</td>
                                            {this.state.data.multiplier == "" &&
                                            <td>0</td>
                                            }
                                            {this.state.data.multiplier !== "" &&
                                            <td>{this.state.data.multiplier} X</td>
                                            }

                                        </tr>
                                        <tr>
                                            <td>Participating preferred</td>
                                            <td>{Utils.getYesOrNoText(this.state.data.parti_preferred)}</td>
                                        </tr>
                                        <tr>
                                            <td>Preference cap</td>
                                            {this.state.data.participating_preferred_cap == "" &&
                                            <td>0</td>
                                            }
                                            {this.state.data.participating_preferred_cap !== "" &&
                                            <td>{this.state.data.participating_preferred_cap} X</td>
                                            }
                                        </tr>
                                        <tr>
                                            <td className="no_border_bottom">Non-cash dividend</td>
                                            <td className="no_border_bottom">{this.state.data.iscash}</td>
                                        </tr>
                                        {this.state.data.iscash === 'Non-cash' &&
                                        <tr>
                                            <td colSpan="2" className="inside_table">
                                                <table>
                                                    {this.state.data.dividend_yield === "" &&
                                                    <tbody>
                                                    <tr>
                                                        <td>Coupon</td>
                                                        <td>&nbsp;</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Type</td>
                                                        <td>&nbsp;</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Dividend accrual</td>
                                                        <td>&nbsp;</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Compounding interest</td>
                                                        <td>&nbsp;</td>
                                                    </tr>
                                                    </tbody>
                                                    }
                                                    {this.state.data.dividend_yield !== "" &&
                                                    <tbody>
                                                    <tr>
                                                        <td>Coupon</td>
                                                        <td>{this.state.data.dividend_yield}%</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Type</td>
                                                        <td>{this.state.data.divi_type}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Dividend accrual</td>
                                                        <td>{this.state.data.dividend_accrual}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Compounding interest</td>
                                                        <td>{this.state.data.compounding_interest}</td>
                                                    </tr>
                                                    </tbody>
                                                    }
                                                </table>
                                            </td>

                                        </tr>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="nav" role="tablist">
                    {this.state.tabKey==1 &&
                    <div role="presentation" className="modal-footer box-footer vesting-footer">
                        <a  data-dismiss="modal" className="btn boxbtn-to bg-darkblue width120">Go Back</a>
                        {step1Error ===0 && isLegalPrefix===1 && isLegalPlanName===1 && <a data-target="#updateSharePriceStep2" onClick={this.nextStep.bind(this,1,2)} role="tab" data-toggle="tab" className="btn boxbtn-to bg-darkblue">Next: Share prices</a>}
                        {step1Error ===1 && <a onClick={this.showError.bind(this,0)}  className="btn boxbtn-to bg-darkblue">Next: Share prices</a>}
                        {(isLegalPrefix ===0 || isLegalPlanName===0) && step1Error===0 && <button  className="btn boxbtn-to bg-darkblue">Next: Share prices</button>}
                    </div>
                    }
                    {this.state.tabKey == 2 &&
                    <div role="presentation" className="modal-footer box-footer preStock-footer">
                        <a  data-toggle="tab" data-target="#updateShareClassStep1"  onClick={this.nextStep.bind(this,2,1)} className="btn boxbtn-to bg-darkblue width120">Go Back</a>
                        {step2Error === 0 && <a data-target="#updatePreferenceStep3" role="tab" data-toggle="tab" onClick={this.nextStep.bind(this,2,3)} className="btn boxbtn-to bg-darkblue prices-btn">Next: Preferences</a>}
                        {step2Error === 1 && <a onClick={this.showError.bind(this,1)}  className="btn boxbtn-to bg-darkblue prices-btn">Next: Preferences</a>}
                    </div>
                    }
                    {this.state.tabKey == 3 &&
                    <div role="presentation" className="modal-footer box-footer preStock-footer">
                        <a  data-toggle="tab" href="#updateSharePriceStep2"  onClick={this.nextStep.bind(this,3,2)} className="btn boxbtn-to bg-darkblue width120">Go Back</a>
                        {step3Error ===0  &&  <a  data-target="#updateReviewStep4" role="tab" data-toggle="tab" onClick={this.nextStep.bind(this,3,4)} className="btn boxbtn-to bg-darkblue prices-btn">Next: Review</a>}
                        {step3Error ===1 && this.state.data.share_class_type == "CS" &&  <a  data-target="#updateReviewStep4" role="tab" data-toggle="tab" onClick={this.nextStep.bind(this,3,4)} className="btn boxbtn-to bg-darkblue prices-btn">Next: Review</a>}
                        {step3Error ===1 && this.state.data.share_class_type == "PS" && <a onClick={this.showError.bind(this,2)}  className="btn boxbtn-to bg-darkblue prices-btn">Next: Review</a>}
                    </div>
                    }
                    {this.state.tabKey == 4 &&
                    <div role="presentation" className="modal-footer box-footer preStock-footer">
                        <a  data-toggle="tab" href="#updatePreferenceStep3"  onClick={this.nextStep.bind(this,4,3)} className="btn boxbtn-to bg-darkblue width120">Go Back</a>
                        <button onClick={this.editNewShareClass} type="button" className="btn boxbtn-to bg-darkblue prices-btn">Save</button>
                    </div>
                    }
                </div>
            </div>
        )
    }

}

export default EditShareClassPlanModal;

