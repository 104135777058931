import React, { useState, useEffect } from 'react';
import { Table, Button, Tooltip, message } from 'antd';
// import { openUpdateStakerholderModal } from 'B/components/WalletLabel/AssignToken';
import { renderWalletAddress } from 'Utils/RenderWalletAddress'
import { PlusOutlined } from '@ant-design/icons';
import RoundTags from 'components/RoundTags';
import { formatNumber, formatPercent } from 'Utils';
import Dot from 'components/Dot';
import { post } from 'srcPath/http';

export const renderValue = val => val === 0 ? val : (val || '--')

import './style.scss';

export default function Wallets(props) {
	const { contract } = props
	const [loading, setLoading] = useState(false);
	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [total, setTotal] = useState(0);
	const [list, setList] = useState([]);

	const getData = async() => {
		setLoading(true)

		try {
			const { data: resData } = await post('getWalletList', {
				pageNumber: 1,
				pageSize: 20,
				contract_address: contract
			})

			const { code, data } = resData || {}

			if (code === 0) {
				const { total = 0, walletList = [] } = data || {}

				setTotal(total);
				setList(walletList)
			}

		} catch(e) {
			message.error(e.message)
		}

		setLoading(false)
	}

	useEffect(() => {
		getData()
	}, [pageSize, pageNumber, contract])

	const onPageSizeChange = (current, size) => {
		setPageSize(size)
		setPageNumber(1)
	}

	const onPageChange = (current, pageSize) => {
		setPageNumber(current)
	}

	const columns = [
        {
            title: __('Wallet'),
            dataIndex: 'wallet_address',
            align: 'left',
            width: 200,
            fixed: 'left',
            render: (text, record) => {
                return renderWalletAddress(text, getData, true)
            }
        },
        {
            title: __('Stakeholder'),
            dataIndex: 'name',
            align: 'left',
            render: renderValue //  (text, record) => (text || <div className="update-button" onClick={(e) =>{e.preventDefault(); openUpdateStakerholderModal(record.wallet_address, getData)}}><PlusOutlined/>Update</div>)
        },
        {
            title: __('Quantity'),
            dataIndex: 'quantity',
            align: 'right',
            render: (text) => {
                return formatNumber(text, 2)
            }
        },
        {
            title: __('Percentage'),
            dataIndex: 'percentage',
            align: 'right',
            render: text => formatPercent(text)
        },
        {
            title: __('Value'),
            dataIndex: 'value',
            align: 'right',
            render: (text) => {
                return formatNumber(text, 2)
            }
        },
        {
            title: __('Relationship'),
            dataIndex: 'relationship',
            align: 'left',
            render: renderValue
        },
        {
            title: __('Ownership'),
            dataIndex: 'ownership',
            align: 'left',
            render: (text, value) => {
                // const ownership = value.ownership === 0 ? 'None' : (Math.round(value.ownership)/100) + '%' ;
                return <div>None</div>
            }
        },
        {
            title: __('Registered'),
            dataIndex: 'is_init',
            align: 'left',
            render: (text, value) => {
                const registerMap = {
                    0 : <Dot color="red">Not yet</Dot>,
                    1: <Dot color="green" >Yes</Dot>
                }
                return <div>{renderValue(registerMap[text])}</div>
            }
        }
    ];

	return <div className="token-detail-wallets">
		<Table 
			columns={columns}
			dataSource={list}
			rowKey="id"
			size="small"
			loading={loading}
			scroll={{ x: true }}
			pagination={ false
                // 暂时去掉翻页
                // {
    			// 	total,
    			// 	current: pageNumber,
    			// 	pageSize: pageSize,
    			// 	onShowSizeChange: onPageSizeChange,
    			// 	onChange: onPageChange,
    			// 	position: ['bottomCenter'] 
			    // }
            }

		/>
	</div>
}
