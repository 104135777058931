import React from 'react';
import { Popconfirm, Space } from 'antd';
import removeUser from './images/remove-user.png'

import { formatDate } from 'Utils/format/formatDate'

const renderValue = data => data || '--'
const formData = data => data || 'N/A'

const logColumns = [
    {
      title: __('Documents-Table-Name'),
      dataIndex: 'name',
      sorter: (a, b) => a?.name?.length - b?.name?.length,
      render: text => <span style={{ paddingLeft: '15px' }}>{text}</span>
    },
    {
      title: __('Action'),
      dataIndex: 'action',
      width: 300,
    },
    {
      title: __('Date'),
      dataIndex: 'date',
      render: formatDate
    },
  ];

  const roleMap = {
    1: {
      name: __('Super admin'),
      action: __('Can edit'),
      access: false,
    },
    2: {
      name: __('Company editor'),
      action: __('Can edit'),
      access: false,
    },
    3: {
      name: __('Company viewer'),
      action: __('Can view'),
      access: false,
    },
    5: {
      name: __('ESOP admin'),
      action: __('Can view'),
      access: true,
    },
    6: {
      name: __('Share class admin'),
      action: __('Can view'),
      access: true,
    },
    8: {
      name: __('Data room user'),
      action: __('Can view'),
      access: true,
    },
  }

  const accessColumns = [
    {
      title: __('Name'),
      dataIndex: 'nick_name',
    },
    {
      title: __('Email'),
      dataIndex: 'email',
    },
    {
      title: __('Documents Access'),
      dataIndex: 'flag',
      render: (flag) => `${roleMap[flag]?.name} (${roleMap[flag]?.action})`
    },
    {
      title: '',
      dataIndex: '',
      render: (_, record) => {
        const { flag = 0 } = record || {}
        if (!roleMap[flag]?.access) {
          return ''
        }
        return (
          <Popconfirm title="Are you sure to remove？" okText="Yes" cancelText="No">
            <a style={{ color: '#010101' }} data-action="remove" className="data-room-table-operate-cell-a"><img src={removeUser} style={{ width: '13px' }} /> Remove</a>
          </Popconfirm>
        );
      },
    },
  ];

  export { logColumns, accessColumns }
