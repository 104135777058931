import React from 'react';
import EditCnTermsModal from '../ConvertibleModal/EditCnTermsModal';
import { post } from 'srcPath/http';
import Loading from 'components/Loading';
import { Empty } from 'antd';



class TermsList extends React.Component {
    constructor(props){
        super(props);
        this.state={
            termsList:[
                // {terms_name:"SAFE 1",is_able_delete:0,id:1},
            ],
            checkedArr:{
                convertible_debt:"",
                convertible_security:"",
                safe:"checked",
            },
            loadingStatus:0,

        }
    }
    getCnTermsList=()=>{
        if(this.state.loadingStatus==1){
            return;
        }
        this.setState({
            loadingStatus:1
        })
        post('getCnTermsList', []).then((response)=> {
            if(response.data.code === 0){
                this.setState({
                    termsList:response.data.data,
                    loadingStatus:0
                })
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    resetAction=()=>{
        window.$("#EditConvertibleTermsModal .tab-pane").removeClass("active");
        window.$("#terms_box").addClass("active");
    }
    closeTermsListModal=()=>{
        window.$("#TermsListModal").modal("hide")
        window.$("#cnTermsDetails").removeClass("active");
        window.$("#termsBox").addClass("active");
    }
    
    editTerms=(key)=>{
        this.refs.editCnTermsModal.initData(key,this.state.termsList[key]);
    }
    deleteTerms=(key)=>{
        post('deleteCnTerm', {id:this.state.termsList[key].id}).then((response)=> {
            if(response.data.code === 0){
                this.getCnTermsList();
                this.props.getCnList();
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    render(){
        return(
            <div className="modal fade" id="TermsListModal" tabIndex="-1" role="dialog" aria-labelledby="TermsListModal" aria-hidden="true" >
                <div className="modal-dialog dialog-top40 tab-content">
                    <div className="modal-content content500 tab-pane active" id="termsBox">
                        <div className="modal-header box-header">
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Terms')}</h4>
                        </div>
                        <div className="modal-body padding203020 ">
                            <div className="body-one">
                                {this.state.loadingStatus==1 &&
                                <div className="termsLoadingPosition">
                                    <Loading/>
                                </div>
                                }
                                {this.state.loadingStatus==0 && this.state.termsList.length===0 && <Empty/>}
                                {this.state.loadingStatus==0 && this.state.termsList.length>0 && this.state.termsList.map((value,key)=>{
                                    return(
                                    <div >
                                        <div className="clearfix terms_list_box" key={key}>
                                            <div className="padding610 onset">
                                                <p className=" mb-0">{value.terms_name}</p>
                                                {value.is_able_delete==1 &&
                                                    <a onClick={this.deleteTerms.bind(this,key)} className="">{__('Delete')}</a>
                                                }
                                            </div>
                                            <button type="button" onClick={this.editTerms.bind(this,key)} data-toggle="tab" data-target="#cnTermsDetails" className="btn editShareClassBtn terms_edit_btn bg-darkblue offset"  >{value.is_able_delete== 1 ? __("Edit Terms") : __("View Terms")}</button>
                                        </div>
                                        <hr />
                                    </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane" id="cnTermsDetails">
                        <EditCnTermsModal ref="editCnTermsModal" closeTermsListModal={this.closeTermsListModal} />
                    </div>
                </div>
            </div>
        )
    }
}
export default TermsList;