import React from 'react';
import {  Button } from 'antd';

import Loading from 'components/Loading';
import Modal, { ModalContent } from 'components/Modal';
import OnBoardingModal from './components/onboardingModal';

import signupPlanImg from "assets/images/signup-plan-2.png";
import './style.scss';

class Onboarding extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}
	componentDidMount() {
		this.openOnBoardingModal()
	}

	openOnBoardingModal = () => {
		this.modalRef = Modal.open({
			component: props => <ModalContent close={props.close}>
				<OnBoardingModal close={props.close} props={this.props} />
			</ModalContent>,
			className: 'sprout-modal onboarding-modal',
			width: 1509,
		})
	}

	render() {
		const { } = this.state
		return (
			<div className="content">
				<div className="admin-summary-root">
					<div className="plan-message-root">
						<img src={signupPlanImg} />
						<h2>{__('Thank you for choosing Folium!')}</h2>
						<Button type="primary" onClick={this.openOnBoardingModal.bind(this)}>{__('Continue onboarding process')}</Button>
					</div>
				</div>
			</div>
		)
	}

}

export default Onboarding;

