import React, { Component } from 'react';
import SimpleTable from '../components/SimpleTable';
import { formatDate } from 'Utils/format/formatDate';
import { formatCurrencyNumber } from 'Utils'

export default function PersonalDetail(props) {
	const { name, email, passportID, employeeId, issueDate, relationship, cost_center, address, country, birthday, job_title, annual_salary, gender } = props.data || {}

	const config = [
		{
			label: __('Full name'),
			value: name
		},
		{
			label: __('Email'),
			value: email
		},
		{
			label: __('Passport or National ID'),
			value: passportID
		},
		{
			label: __('Employee ID'),
			value: employeeId
		},
		{
			label: __('Issue date relationship'),
			value: issueDate
		},

		{
			label: __('Cost center'),
			value: cost_center?cost_center:'--'
		},
		{
			label: __('Relationship'),
			value: relationship
		},
		{
			label: __('Address'),
			value: address
		},
		// {
		// 	label: 'City',
		// 	value: 'city'
		// },		
		// {
		// 	label: 'District, Province or State',
		// 	value: ''
		// },
		// {
		// 	label: 'Post code',
		// 	value: ''
		// },
		{
			label: __('Country'),
			value: country
		},
		{
			label: __('Date of birth'),
			value: formatDate(birthday)
		},
		{
			label: __('Job title'),
			value: job_title
		},
		{
			label: __('Annual salary'),
			value: formatCurrencyNumber(annual_salary)
		},
		{
			label: __('Gender'),
			value: gender
		},
	]

	return <div className="certificate-detail-section">
		<SimpleTable title={__('Personal detail')} config={config} />
	</div>
}