import React from 'react';
import { FormItem } from 'react-antd-formutil';
import { Checkbox } from 'antd'
import { omit } from 'lodash';
import './style.scss';

const options = [
    {
        label: __('No Access'),
        value: 0
    },
    {
        label: __('Basic view'),
        value: 1
    },
    {
        label: __('Individual view'),
        value: 2
    },
    {
        label: __('Summary view'),
        value: 3
    },
    {
        label: __('Intermediate view'),
        value: 4
    },
    {
        label: __('Detailed view'),
        value: 5
    },
]

const Group = Checkbox.Group

export default function Relationship(props) {
    const { name, label } = props

    return <FormItem
        className="access_level"
        name={name || 'access_level'}
        label={label || __('Access Level')}
    >
        <Group {...omit(props, ['name', 'label'])} showSearch placeholder={__('Select')} options={options} />
    </FormItem>
}