import React, { Component } from 'react';
import Chart from 'components/Echarts';
import Utils, { tranNumber, formatCurrencyNumber, I } from "Utils";
import emptyImg from 'assets/images/bar.png';

class Echarts extends Component {
    formatFoundOption() {
        const { data = [] } = this.props;
        const optionObj = {
            nameList: [],
            cashRaisedList: [],
            postMoneyList: []
        };
        data.map((item) => {
            optionObj.nameList.push(item.name);
            optionObj.cashRaisedList.push(I(item.historySecurityList, '') ? item.history_cashRaised : item.cashRaised);
            optionObj.postMoneyList.push(I(item.historySecurityList, '') ? item.history_post_money_valuation :item.post_money_valuation);
        });
        const option = {
            grid: {
                containLabel: true,
                left: 10,
                top: 20,
                right: 20,
                bottom: 40
            },
            legend: {
                data: [__('Cash raised'), __('Post-money valuation')],
                right: 20,
                bottom: 0
            },
            tooltip: {
                formatter(params){
                    return params.name + '<br />' + params.seriesName + ':' + formatCurrencyNumber(params.value, 0);
                }
            },
            xAxis: {
                data: optionObj.nameList,
                axisTick: {
                    show: false
                },
                axisLine: {
                    lineStyle: {
                        color: '#524e4ed9'
                    }
                }                
            },
            yAxis: {
                type: 'value',
                axisTick: {
                    show: false
                },
                axisLabel: {
                    formatter: (value) => {
                        return tranNumber(value, 1, true);
                    }
                },
                axisLine: {
                    lineStyle: {
                        color: '#524e4ed9'
                    }
                }                
            },
            series: [
                {
                    name: __('Cash raised'),
                    type: 'bar',
                    data: optionObj.cashRaisedList,
                    barMaxWidth: 50,
                    barGap: 1,
                    itemStyle: {
                        normal: {
                            label: {
                              formatter: (params) => {
                                return formatCurrencyNumber(params.value, 0)
                              },
                              show: true,
                              position: "top",
                              textStyle: {
                                fontSize: "12",
                                color: "#898989"
                              }
                            },
                        },
                    }
                },
                {
                    name: __('Post-money valuation'),
                    type: 'bar',
                    data: optionObj.postMoneyList,
                    barMaxWidth: 50,
                    barGap: 1,
                    itemStyle: {
                        normal: {
                            label: {
                              formatter: (params) => {
                                return formatCurrencyNumber(params.value, 0)
                              },
                              show: true,
                              position: "top",
                              textStyle: {
                                // fontWeight: "bolder",
                                fontSize: "12",
                                color: "#898989"
                              }
                            },
                            // color: 'rgb(2, 161, 254)',
                        },
                    }
                }
            ],
        };
        return option;

    }

    render() {
        const { data = [] } = this.props

        const option = this.formatFoundOption();
    
        if (data.length === 0) {
            return  <div className="empty-img"><img src={emptyImg} /></div>
        }

        return (
            <Chart 
                option={option}
            />
        );
    }
}

export default Echarts;