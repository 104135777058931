import React from 'react';
import { Button, message, Checkbox, DatePicker, Space } from 'antd';
import Loading from 'components/Loading';
import DateSelector from 'components/DateSelector';
import { FormItem, withForm } from 'react-antd-formutil';
import moment from 'moment';
import { post } from 'srcPath/http';
import security from 'stores/security';
import { formatDate2Api } from 'Utils/format/formatDate';
import icon from '../../images/icon.png';

const CheckboxGroup = Checkbox.Group;

function Token({ $formutil, id }) {
	const { $invalid, $batchDirty, $params, $getFirstError } = $formutil

	const onSubmit = async () => {
		if ($invalid) {
			$batchDirty(true)
			message.error($getFirstError())

			return
		}

		Loading.global(true);
		try {

			const { data: resData } = await post('exportWallets', {
				token: id,
				...$params
			})

			const { data, code } = resData || {}

			if (code === 0) {
				message.success(__('Success'))

				security.getFileData()
			} else {
				message.error(data)
			}

		} catch(e) {
			message.error(e.message)
		}

		Loading.global(false)
	}

	return <div className="sprout-reports-item-container">
		<div className="sprout-reports-item-left">
			<div className="sprout-reports-item-row">
				<div className="sprout-reports-icon">
					<img src={icon} />
				</div>
				<div className="sprout-reports-item-content">
					<div className="title">{__('Wallets Report')}</div>
					<div className="item-section">
						<FormItem
							validMessage={{ required: __('Please select')}}
							required
							name="ledger"
							$defaultValue={['1']}
						>
							<CheckboxGroup>
								<Space direction="vertical">
									<Checkbox value="1">{__('Summary of All Wallets')}</Checkbox>
									<Checkbox value="2">{__('Summary of Ownership')}</Checkbox>

								</Space>
							</CheckboxGroup>
						</FormItem>
					</div>
				</div>
			</div>
		</div>
		<div className="btns">
			<Space size={15}>
				<span>{__('As of')} {formatDate2Api(moment())}</span>
				{/*<DateSelector bordered={true} disabled format={security.companyInfo && security.companyInfo.date_format}  defaultValue={moment()} />*/}
				<Button type="primary" onClick={onSubmit}>{__('Export')}</Button>
			</Space>
		</div>
	</div>
}

export default withForm(Token)
