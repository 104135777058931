import React from 'react';
import Utils, { I, formatCurrencyNumber, filterAmount,calculator } from "Utils";
import { post } from 'srcPath/http';
import moment from "moment";
import {DatePicker, message} from "antd";
import DateSelector from "../../../../../components/DateSelector";
import {formatDate} from "Utils/format/formatDate";
import SelectStakeholder from 'components/SelectStakeholder';
import RelationshipSelector from 'components/RelationshipSelector';

class TransferSafe extends React.Component{
    constructor(props){
        super(props);
        this.state={
            data:{
                transfer_date:'',
                userList:[
                    {name:'',email:'',relationship:'',principal_amount_to_transfer:0,transfer_agreements:{name:'',file_obj:''}},
                    ],
            },
            dateFormat:"DD/MM/YYYY",
            principalError:0,
            requiredKey:["name","email","relationship","principal_amount_to_transfer","transfer_agreements"],
            transferStatus:0,
            errorTip:0,
            relationshipList:[
                "",
                "Advisor",
                "Board member",
                "Consultant",
                "Employee",
                "Ex-employee",
                "Executive",
                "Founder",
                "International employee",
                "Investor",
                "Officer",
            ]
        }
    }

    setTransferSafeDateChange = (key, moment_key, date) => {
        const { dateFormat } = this.state;
        let curData = this.state.data;
        curData[key] = date?.format( dateFormat);
        curData[moment_key] = date;
        this.setState({
            data:curData
        });
    }


    getPrincipal=()=>{
        let curData = this.state.data;
        curData.userList[0].principal_amount_to_transfer = this.props.curSelectSecurity.principal;
        this.setState({
            data:curData
        })
    }
    recordData=(key,e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData.userList[key][dataKey] = dataVal;
        this.setState({
            data:curData
        })
    }
    recordNumber=(key,e)=>{
        let curData = this.state.data;
        curData.userList[key].principal_amount_to_transfer = Utils.checkIntInputWithoutComma(e.target.value);
        this.setState({
            data:curData
        });
    }
    /**
     * input change通用事件
     * @param isDecimal 是否需要小数
     * @param e
     * 2 digit
     */
    inputChange2Digit = (isDecimal,key,e) => {
        const { target: element } = e;
        const { name: dataKey } = element;
        const filter = filterAmount(e, 9999999999999, isDecimal);
        let curData = this.state.data;
        let userList = [...curData.userList]
        userList[key][dataKey] = filter.value;
        this.setState({
            data: curData
        }, () => {
            element.selectionStart = element.selectionEnd = filter.cursorPositionX;
        });
    }
    formatPrincipal=(key,e)=>{
        let curData = this.state.data;
        curData.userList[key].principal_amount_to_transfer = Utils.checkNumInput(e.target.value);
        this.setState({
            data:curData
        });
    }
    focusPrincipal=(key,e)=>{
        let curData = this.state.data;
        curData.userList[key].principal_amount_to_transfer = Utils.getNumFromFormatNum(e.target.value);
        this.setState({
            data:curData
        });
    }
    setFileName=(key,e)=>{
        let curList = this.state.data;
        let fileObj = e.target.files[0];
        Utils.uploadFile(fileObj).then((result)=>{
            curList.userList[key].transfer_agreements.name = fileObj.name;
            curList.userList[key].transfer_agreements.file_obj = result;
            this.setState({
                data:curList
            });
        })
        $(".filename"+key).removeClass("hidden");
        $(".fill-select"+key).addClass("hidden");
    }
    removeFile=(key)=>{
        this.refs.file.value="";
        let curData = this.state.data;
        curData.userList[key].transfer_agreements.name = ""
        curData.userList[key].transfer_agreements.file_obj = ""
        this.setState({
            data: curData
        })
        $(".filename"+key).addClass("hidden");
        $(".fill-select"+key).removeClass("hidden");
    }
    addAdditional=()=>{
        let one ={name:'',email:'',relationship:'',principal_amount_to_transfer:0,transfer_agreements:{name:'',file_obj:''}};
        let curData = this.state.data;
        curData.userList.push(one);
        this.setState({
            data:curData
        })
    }
    removeTransferee=(key)=>{
        let curData = this.state.data;
        curData.userList.splice(key,1);
        this.setState({
            data:curData
        })
    }
    resetActive=()=>{
        window.$("#TransferSafeModal .tab-pane").removeClass("active");
        window.$("#transferSafe").addClass("active");
    }
    transferConvertible=()=>{
        if(this.state.transferStatus==1){
            return;
        }
        this.setState({
            transferStatus:1
        })
        var requestData = this.state.data;
        requestData.id = this.props.curSelectSecurity.id;
        if(this.props.pageData.title=="Warrant"){
            post('transferWarrant', requestData).then((response)=> {
                if(response.data.code === 0){
                    // window.location.reload();
                    this.setState({
                        transferStatus:0
                    })
                    window.$("#TransferSafeModal").modal("hide");
                    this.props.getWarrantList();
                }else{
                    console.log("failed, error code is: "+response.data.code);
                }
            }).catch(function (error) {
                console.log(error);
            });
        }else{
            post('transferCnSecurity', requestData).then((response)=> {
                if(response.data.code === 0){
                    this.setState({
                        transferStatus:0
                    })
                    window.$("#TransferSafeModal").modal("hide");
                    this.props.getCnList();

                }else{
                    console.log("failed, error code is: "+response.data.code);
                }
            }).catch(function (error) {
                console.log(error);
            });
        }
    }
    refreshData=()=>{
        this.setState({
            data:{
                transfer_date:'',
                userList:[
                    {name:'',email:'',relationship:'',principal_amount_to_transfer:0,transfer_agreements:{name:'',file_obj:''}},
                ],
            },
        })
        this.removeFile(0);
    }
    closeTransfer=()=>{
        this.resetErrorStatus();
        this.refreshData();
    }
    resetErrorStatus=()=>{
        this.setState({
            errorTip:0,
            principalError:0,
        })
        let curData = this.state.data;
        let requiredKey = this.state.requiredKey;
        $(".transfer_date").removeClass("has-error")
        curData.userList.map((value,key)=> {
            for (let i=0;i<requiredKey.length;i++) {
                if(requiredKey[i]=="transfer_agreements") {
                    $("#transfer_agreements .transfer_agreements" + key).removeClass("error_box");
                }else{
                    $(".transfer_"+requiredKey[i]+key).removeClass("has-error");
                }
            }
            })
    }
    showErrorTipForTransfer=()=>{
        let listError = 1;
        let curData = this.state.data;
        let requiredKey = this.state.requiredKey;
        let dateError = 1;
        if(curData.transfer_date==""){
            $(".transfer_date").addClass("has-error")
            dateError = 1
            this.setState({
                errorTip:1
            })
        }else{
            $(".transfer_date").removeClass("has-error")
            dateError = 0;
            this.setState({
                errorTip:0
            })
        }
        curData.userList.map((value,key)=>{
            for (let i=0;i<requiredKey.length;i++){

                if(requiredKey[i]=="transfer_agreements"){
                    if(value.transfer_agreements.file_obj==""){
                        $("#transfer_agreements .transfer_agreements"+key ).addClass("error_box");
                        this.setState({
                            errorTip:1
                        })
                    }else{
                        $("#transfer_agreements .transfer_agreements"+key).removeClass("error_box");
                        this.setState({
                            errorTip:0
                        })
                    }
                }else if(requiredKey[i]=="email"){
                    let isEmail = Utils.isEmail(value.email);
                    if(!isEmail || value.email==""){
                        $(".transfer_"+requiredKey[i]+key).addClass("has-error-border");
                        this.setState({
                            errorTip:1
                        })
                    }else{
                        $(".transfer_"+requiredKey[i]+key).removeClass("has-error-border");
                        this.setState({
                            errorTip:0
                        })
                    }
                }else if(requiredKey[i]=="principal_amount_to_transfer"){
                    if(value[requiredKey[i]]==""){
                        $(".transfer_"+requiredKey[i]+key).addClass("has-error");
                        this.setState({
                            errorTip:1
                        })
                    }else{
                        $(".transfer_"+requiredKey[i]+key).removeClass("has-error");
                        this.setState({
                            errorTip:0
                        })
                    }
                }else{
                    if(value[requiredKey[i]]==""){
                        $(".transfer_"+requiredKey[i]+key).addClass("has-error-border");
                        this.setState({
                            errorTip:1
                        })
                    }else{
                        $(".transfer_"+requiredKey[i]+key).removeClass("has-error-border");
                        this.setState({
                            errorTip:0
                        })
                    }
                }
                if(value.name != "" && value.email!= "" && value.relationship != "" && value.transfer_agreements.file_obj!= "" && value.principal_amount_to_transfer!=""){
                    listError = 0
                }else{
                    listError = 1
                }
            }
        })

        let principalExceed = 0;
        if(curData.userList.length==1){
            curData.userList[0].principal_amount_to_transfer = this.props.curSelectSecurity.principal;
            this.setState({
                data:curData,
                principalError:0
            })
        }
        if(curData.userList.length>1){
            let principalSum = 0;
            for(let i=0;i<curData.userList.length;i++){
                principalSum += parseInt(Utils.getNumFromFormatNum(curData.userList[i].principal_amount_to_transfer))
            }
            if(principalSum > this.props.curSelectSecurity.principal){
                this.setState({
                    principalError:1
                })
                principalExceed = 1
            }else{
                this.setState({
                    principalError:0
                })
                principalExceed = 0
            }

        }
        if( principalExceed != 1 &&  dateError == 0 && listError == 0){
            this.setState({
                errorTip:0
            })
            this.reviewTransferInfo()
        }
    }
    reviewTransferInfo=()=>{
        window.$("#TransferSafeModal .tab-pane").removeClass("active");
        window.$("#transferDetails").addClass("active");
    }

    handleSelectChange = (key, name, data, e) => {
        let curData = this.state.data;
        if (data) {
            curData.userList[key].name = data.nick_name || "";
            curData.userList[key].email = data.email || "";
            // curData["userList"].stakeholder_id = data.id || '';
        }
        this.setState({
            // selectedData: data,
            data: curData
        })
    }

    setRelation = (key, value) => {
        let curList = this.state.data;
        curList.userList[key].relationship = value;
        this.setState({
            data: curList
        })
    }

    render(){
        const { data, dateFormat } = this.state;
        let exceedStyle = "";
        let isMinus = "";
        let principalAmount = parseFloat(Utils.resetNumberWithCommas(this.props.curSelectSecurity.principal||0));
        let principalAvailable = principalAmount;
        const { currency = '' } = this.props
        return(
            <div className="modal fade" id="TransferSafeModal" tabIndex="-1" role="dialog" aria-labelledby="TransferSafeModal" aria-hidden="true" >
                <div className="modal-dialog dialog-top40">
                    <div className="tab-content">
                        <div className="modal-content content500 tab-pane active" id="transferSafe">
                            <div className="modal-header box-header">
                                <button type="button" className="close shut-btn" onClick={this.closeTransfer} data-dismiss="modal" aria-hidden="true">×</button>
                                <h4 className="modal-title" id="myModalLabel">{__('Transfer')} {this.props.pageData.text} {this.props.curSelectSecurity.security} for {this.props.curSelectSecurity.name}</h4>
                            </div>
                            <div className="modal-body padding203020 ">
                                {this.state.errorTip===1 &&
                                <div className="alert alert-warning alert-dismissible pink-box">
                                    <p>{__('Required field(s) or email cannot be empty or in the wrong format.')}</p>
                                </div>
                                }
                                {this.state.principalError===1 &&
                                <div className="alert alert-warning alert-dismissible pink-box">
                                    <p>{__('The sum of principal amounts to transfer must equal the original note principal.')}</p>
                                </div>
                                }
                                <form className="form-horizontal">
                                    <div className="form-group add-items ">
                                        <label className="col-xs-5 add-label">{__('Transfer date')}<br/>
                                            <i><small className="text-muted">{__('Required')}</small></i>
                                        </label>
                                        <div className="col-xs-7 transfer_date">
                                            <DateSelector
                                                onChange={this.setTransferSafeDateChange.bind(this, 'transfer_date', 'transfer_date_moment')}
                                                value={I(data['transfer_date_moment'], I(data['transfer_date'], '') ? moment(data['transfer_date'], dateFormat) : '')}
                                                inputReadOnly={false}
                                                allowClear
                                            />
                                        </div>
                                    </div>
                                </form>
                                {this.state.data.userList.map((value,key)=>{
                                    return(
                                        <form className="form-horizontal" key={key}>
                                            <div className="form-group ">
                                                <label className="col-xs-5 add-label">{__('New shareholder name')}<br/>
                                                    <i><small className="text-muted">{__('Required')}</small></i>
                                                </label>
                                                <div className={"col-xs-7 transfer_name"+key}>
                                                    <SelectStakeholder name="name" value={value.name} add={false} onChange={this.handleSelectChange.bind(this,key)} />

                                                    {/*<input value={value.name} required name="name" className="form-control" type="text"  onChange={this.recordData.bind(this,key)}  />*/}
                                                </div>
                                                {this.state.data.userList.length > 1 &&
                                                    <a onClick={this.removeTransferee.bind(this,key)} className="text-blue link-cursor col-xs-7">{__('Remove transferee')}</a>
                                                }
                                            </div>
                                            <div className="form-group ">
                                                <label className="col-xs-5 add-label">{__('New shareholder email')}<br/>
                                                    <i><small className="text-muted">{__('Required')}</small></i>
                                                </label>
                                                <div className={"col-xs-7 transfer_email"+key}>
                                                    <SelectStakeholder name="email" value={value.email} add={false} isEmail={true} onChange={this.handleSelectChange.bind(this,key)} />
                                                    {/*<input value={value.email} required name="email" className="form-control " type="text"  onChange={this.recordData.bind(this,key)} />*/}
                                                </div>
                                            </div>
                                            <div className="form-group ">
                                                <label className="col-xs-5 add-label">{__('Relationship')}<br/>
                                                    <i><small className="text-muted">{__('Required')}</small></i>
                                                </label>
                                                <div className={"col-xs-7 transfer_relationship"+key}>
                                                    {/*<select name="relationship" value={value.relationship} required onChange={this.recordData.bind(this,key)} className="form-control select-container select-bgwhite">*/}
                                                    {/*    {this.state.relationshipList.map((val,k)=>{*/}
                                                    {/*        return(*/}
                                                    {/*            <option key={k}>{val}</option>*/}
                                                    {/*        )*/}
                                                    {/*    })}*/}
                                                    {/*</select>*/}
                                                    <RelationshipSelector name="relationship" value={value.relationship} style={{width:'100%' }} onChange={this.setRelation.bind(this,key)}/>
                                                </div>
                                            </div>
                                            {this.state.data.userList.length>1 &&
                                            <div className="form-group add-items">
                                                <label className="col-xs-5 add-label">{__('Principal amount to transfer')}<br/>
                                                    <i><small className="text-muted">{__('Required')}</small></i>
                                                </label>
                                                <div className="col-xs-7">
                                                    <div className={"input-group transfer_principal_amount_to_transfer"+key}>
                                                        <span className="input-group-addon">{currency}</span>
                                                        <input type="text" name="principal_amount_to_transfer" required onChange={this.inputChange2Digit.bind(this,true,key)} value={value.principal_amount_to_transfer} className="form-control" aria-describedby="realized_value" placeholder="100.00"/>
                                                    </div>
                                                </div>
                                                <div className="stock-tipbox">
                                                    <p className="col-xs-7  text-muted"><span className={exceedStyle}>{isMinus}{principalAvailable==0 ? formatCurrencyNumber(0):formatCurrencyNumber(principalAvailable)}</span> {__('available')}.</p>
                                                </div>
                                            </div>
                                            }
                                            <div className="form-group add-items">
                                                <label className="col-xs-5 add-label">{__('Transfer agreements')}<br/>
                                                    <i><small className="text-muted">{__('Required')}</small></i>
                                                </label>
                                                <div className={"col-xs-7"} id={"transfer_agreements"}>
                                                    <div className={"filebox transfer_agreements"+key}>
                                                        <p className={"fill-select fill-select"+key}>
                                                        <span className={"select-text text-blue"}> 
                                                        {__('Choose file')}
                                                            <label htmlFor={"file-select"+key} className="sr-only" >File input</label>
                                                            <input name="transfer_agreements" ref="file" type="file" required id={"file-select"+key} accept=".xls,.doc,.txt,.pdf,.docx" onChange={this.setFileName.bind(this,key)} />
                                                        </span>
                                                        </p>
                                                        <div className={"filename hidden filename"+key}>
                                                            <p className="filename-text">{value.transfer_agreements.name}</p>
                                                            <button type="button" className="text-blue removeBtn" onClick={this.removeFile.bind(this,key)}>{__('remove')}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.props.pageData.title=="Convertible" &&
                                            <div className="text_align_center">
                                                <a className="link-cursor text-blue" onClick={this.addAdditional}>{__('Add additional transferee')}</a>
                                            </div>
                                            }
                                            <hr/>
                                        </form>
                                    )
                                })}
                            </div>
                            <div className="modal-footer box-footer">
                                <button type="button" data-toggle="tab" onClick={this.showErrorTipForTransfer} className="btn boxbtn-to bg-darkblue">{__('Next: review transfer details')}</button>
                            </div>
                        </div>
                        <div className="modal-content content500 tab-pane" id="transferDetails">
                            <div className="modal-header box-header">
                                <a data-target="#transferSafe" className="back-btn" aria-controls="transferSafe-step1" role="tab" data-toggle="tab"></a>
                                <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true"  onClick={this.refreshData}>×</button>
                                <h4 className="modal-title" id="myModalLabel">{__('Transfer')} {this.props.pageData.text}</h4>
                            </div>
                            <div className="modal-body padding203020 ">
                                {this.state.data.userList.map((value,key)=>{
                                    return(
                                        <div className="body-one" key={key}>
                                            <table className="table merge-table review-relationship-table no-marginBottom ">
                                                <tbody>
                                                <tr>
                                                    <td >{__('Transfer date')}</td>
                                                    <td className="width50perc">{ formatDate(data.transfer_date)}</td>
                                                </tr>
                                                <tr>
                                                    <td>{__('New shareholder email')}</td>
                                                    <td className="overflow_td">{value.email}</td>
                                                </tr>
                                                <tr>
                                                    <td>{__('Relationship')}</td>
                                                    <td>{value.relationship}</td>
                                                </tr>
                                                {this.state.data.userList.length == 1 && this.props.pageData.title=="Convertible" &&
                                                <tr>
                                                    <td>{__('Principal amount to transfer')}</td>
                                                    <td>{formatCurrencyNumber(this.props.curSelectSecurity.principal)}</td>
                                                </tr>
                                                }
                                                {this.state.data.userList.length > 1 &&
                                                <tr>
                                                    <td>{__('Principal amount to transfer')}</td>
                                                    <td>{formatCurrencyNumber(value.principal_amount_to_transfer)}</td>
                                                </tr>
                                                }
                                                <tr>
                                                    <td>{__('Transfer agreements')}</td>
                                                    <td className="overflow_td">{value.transfer_agreements.name}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="modal-footer box-footer">
                                <button type="button" className="btn boxbtn-to bg-darkblue" onClick={this.transferConvertible} >{ this.state.transferStatus == 1 ? 'Transferring...' : (this.props.pageData.title == "Warrant" ? "Transfer warrant":"Transfer convertible")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default TransferSafe;