import React, { useState, useEffect } from 'react';
import Modal, { ModalContent } from 'components/Modal';
import { FormItem, withForm } from 'react-antd-formutil';
import { Button, Radio, Space, Input, message, Alert } from 'antd';
import DateSelector from 'components/DateSelector';
import SectionHeader from 'components/Layout/FormSection';
import CountrySelector from 'components/CountrySelector';
import Relationship from 'B/components/WalletLabel/components/Relationship'
import JobTitle from 'B/components/WalletLabel/components/JobTitle'
import CostCenter from 'B/components/WalletLabel/components/CostCenter'
import Wallet from 'B/components/WalletLabel/components/Wallet'
import { openUpdateStakerholderModal } from '../AssignToken'
import { getDefaultTokenId } from 'B/components/TokenSelector'
import security from 'stores/security'

import { formatDateForDatePicker, formatDate2Api } from 'Utils/format/formatDate';
import Loading from 'components/Loading';
import { post } from 'srcPath/http';
import './style.scss';
import CitySelector from "components/CitySelector";
import { I } from "Utils";
import ConfirmNotifyModels from "B/Stakeholder/Stakeholder/components/ConfirmNotifyModels";

const emailParse = new RegExp('(?![\\-_.])[\\w\\-.]+@(?:(?![\\-_])[\\w\\-\u4e00-\u9fa5]{1,63}\\.)+[A-Za-z0-9]{2,}')
function Content (props) {
	const { walletAddress, close, callback, $formutil, cost_list } = props;
	const { $invalid, $batchDirty, $params, $setValues, $getFirstError } = $formutil;
	const [errorMessage, setErrorMessage] = useState('')
	const userAccessControl = security.companyInfo && security.companyInfo.user_access_control;

	useEffect(() => {
		if (walletAddress) { 
			$setValues({
				wallet_address_list: walletAddress
			})
		}
	}, [])

	const sendStakeHolderEmail = async(id, close) => {
		Loading.global(true)
		try {
			const { data: resData } = await post('sendStakeHolderEmail', {id})
			const { code, data } = resData;
			if (code === 0) {
				message.success(__('Success!'));
				close && close();
			} else {
				message.error(`error: ${data?.errorCode}`);
			}
		} catch (err) {
			message.error(err && err.message)
		}
		Loading.global(false)
	}

	const onSubmit = async () => {
		if ($invalid) {
			$batchDirty(true)

			message.error($getFirstError())

			return
		}
		try {
			Loading.global(true)

			const { data: resData } = await post('addStakeHolderData', {
				...$params,
				coin_id: getDefaultTokenId()
			})

			const { code, data } = resData || {}

			if (code === 0) {
				callback?.()
				close()

				if(I(userAccessControl, '')){
					const isWallet = $params.wallet_address_list?.[0]?.wallet_address ? 1 : 0;
					return Modal.open({
						component: props => <ModalContent title={__('Send invitation email')} close={props.close}>
							<ConfirmNotifyModels
								close={props.close}
								addStakeholder={sendStakeHolderEmail}
								isWallet={isWallet}
								type={'addStakeholder'}
								id={data}
							/>
						</ModalContent>,
						width: 1458,
						maskClosable: false,
						className: 'sprout-modal'
					})
				}

			} else {
				setErrorMessage(data)
			}


		} catch (e) {
			message.error(e.message)
		}
		
		Loading.global(false)
	}

	const onCancel = () => {
		const address = (walletAddress || []).map(val => val.wallet_address)
		
		openUpdateStakerholderModal(address, callback)

		close()
	}

	return <div className="token-edit-stakeholder">
		<div className="token-edit-stakeholder-content">
			<SectionHeader title="Basic information">
				<div className="token-edit-stakeholder-item">
					<div className="token-edit-stakeholder-item-label">{__('Stakeholder type')}</div>
					<FormItem
						name="stakeholder_type"
						required
						$defaultValue="1"
					>
						<Radio.Group>
							<Space direction="vertical">
								<Radio value="1">{__('Individual')}</Radio>
								<Radio value="0">{__('Non-individual')}</Radio>
								<div>
									{__('Non-individual stakeholders can be investment firms companies, corporations, etc<')}
								</div>								
							</Space>
						</Radio.Group>
					</FormItem>
			    </div>
				<div className="token-edit-stakeholder-item">
					<div className="token-edit-stakeholder-item-label">{__('Name')}<span className="form-required">{__('- Required')}</span></div>
					<FormItem
						name="name"
						required
					>
					<Input placeholder="Input"/>
					</FormItem>
			    </div>
				<div className="token-edit-stakeholder-item">
					<div className="token-edit-stakeholder-item-label">{__('Email')}<span className="form-required">{__('- Required')}</span></div>
					<FormItem
						name="contact_email"
						required
						pattern={emailParse}
						validMessage={{
							required: 'Email is required',
							pattern: 'Email is invalid'
						}}
					>
					<Input placeholder="Input" />
					</FormItem>
			    </div>
			    {
			    	$params.stakeholder_type === '1' && <div className="token-edit-stakeholder-item">
						<div className="token-edit-stakeholder-item-label">{__('Date of birth')}</div>
						<FormItem
							name="date_of_birth"
							$formatter={formatDateForDatePicker}
							$parser={val => val && formatDate2Api(val)}
						>
							<DateSelector />
						</FormItem>
				    </div>			    	
			    }
				{
					$params.stakeholder_type === '1' && <div className="token-edit-stakeholder-item">
						<div className="token-edit-stakeholder-item-label">{__('Annual salary')}</div>
						<FormItem
							name="annual_salary"
						>
							<Input addonBefore={security.companyInfo?.currency} placeholder="Input"/>
						</FormItem>
					</div>
				}
			</SectionHeader>
			<SectionHeader title={__("Relationship")} subtitle="- Required">
				<Relationship />
			</SectionHeader>

			<SectionHeader title={__("Cost center")} subtitle="- Optional" cost_list={cost_list}>
				<CostCenter cost_list={cost_list} />
			</SectionHeader>

			<SectionHeader title={__("Job title")} subtitle="- Optional">
				<JobTitle />
			</SectionHeader>

			<SectionHeader title="Address information">

				<div className="token-edit-stakeholder-item">
					<div className="token-edit-stakeholder-item-label">{__('Country / Region')}</div>
					<FormItem
						name="country_region"
					>
						<CountrySelector placeholder="Select" />
					</FormItem>
			    </div>
				<div className="token-edit-stakeholder-item">
					<div className="token-edit-stakeholder-item-label">{__('Address details')}</div>
					<FormItem
						name="address"
					>
					<Input placeholder="Input" />
					</FormItem>
			    </div>
				{security.env !== 'prod' &&
				<div className="token-edit-stakeholder-item">
					<div className="token-edit-stakeholder-item-label">{__('Tax city')}</div>
					<FormItem
						name="city"
					>
						<CitySelector placeholder="Select"/>
					</FormItem>
				</div>
				}
			</SectionHeader>
			<SectionHeader title="Identification numbers">
				<div className="token-edit-stakeholder-item">
					<div className="token-edit-stakeholder-item-label">{__('Passport or National ID')}</div>
					<FormItem
						name="passport_or_national_id"
					>
					<Input placeholder="Input" />
					</FormItem>
			    </div>
				<div className="token-edit-stakeholder-item">
					<div className="token-edit-stakeholder-item-label">{__('Employee ID')}</div>
					<FormItem
						name="employee_id"
					>
					<Input placeholder="Input" />
					</FormItem>
			    </div>
				<div className="token-edit-stakeholder-item">
					<div className="token-edit-stakeholder-item-label">{__('Property ID')}</div>
					<FormItem
						name="property_id"
					>
						<Input placeholder="Input" />
					</FormItem>
				</div>
				<div className="token-edit-stakeholder-item">
					<div className="token-edit-stakeholder-item-label">{__('Note')}</div>
					<FormItem
						name="note"
					>
						<Input placeholder="Input" />
					</FormItem>
				</div>
			</SectionHeader>
			<SectionHeader title="Wallet" subtitle="- Required">
				<Wallet list={walletAddress} />
			</SectionHeader>
		</div>
		{
			errorMessage && <Alert message={errorMessage} type="error" />
		}
		<div className="token-edit-stakeholder-btn">
			<Space>
				{
					walletAddress?.length > 0 ? <Button onClick={onCancel}>{__('Go Back')}</Button> : <Button onClick={() => close()}>{__('Cancel')}</Button>
				}
				<Button type="primary" onClick={() => onSubmit()}>{__('Submit')}</Button>
			</Space>
		</div>
	</div>
}

const UpdateStakeholderContent = withForm(Content) 

export function openEditStakeholderModal(wallet_address, callback, cost_list ) {
	const address = Array.isArray(wallet_address) ? wallet_address.map(val => ({ wallet_address: val })) : [{ wallet_address }]
	return Modal.open({
		component: ({ close }) => <ModalContent title="Add stakeholder" close={close}>
			<UpdateStakeholderContent walletAddress={wallet_address && address} close={close} callback={callback} cost_list={ cost_list && cost_list} />
		</ModalContent>,
		className: 'sprout-modal',
		width: 800
	})
}