import React, { Component } from 'react';
import { post } from 'srcPath/http';
import { Link } from "react-router-dom";
import { message, Table, Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { templateColumns } from './config';
import './index.scss';


export default class Templetes extends Component {
	modalRef = null
	state = {
		tableData: [],
		loading: true,
		disabled: true
	}

	componentDidMount() {
		this.getTableData()
	}

	componentWillUnmount() {
		this.modalRef && this.modalRef.close()
	}

	getTableData = async () => {
		this.setState({ loading: true })

		try {
			const { data: resData } = await post('getTemplateList')

			const { code, data } = resData || {}

			if (code == 0) {
				const companyInfo = JSON.parse(localStorage.getItem('COMPANY_USER') || '{}')
				const disabled = [3, 4, 5, 7, 8].includes(companyInfo.consent_role);
				// 1 directer  2 observer  3 admin master 4: else
				data.forEach((val, index) => { 
					val.key = index
					val.disabled = disabled
					return val;
				})
				this.setState({ tableData: data, disabled })
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}

		this.setState({ loading: false })
	}	

	async handleDeleteTemplate(template_id) {
        try {
			const { data: resData } = await post('deleteTemplate', { template_id })
			const { data, code } = resData

			if (code == 0) {
				message.success(__('remove success'));
				this.getTableData();
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}
    }

	handleClickRow(record, e) {
		const { disabled } = this.state;
		if (disabled) return;
		const id = record.template_id;
		if (!['svg', 'LI', 'SPAN', 'path'].includes(e.target.tagName)) {
			this.props.history.push({
				pathname: '/home/board/consents/templates/edit',
				state: { templateId: id }
			});
		} else {
			const action = e.target.getAttribute("data-action");
			if (action === 'delete') {
				this.handleDeleteTemplate(id);
			} else if (action === 'new') {
				this.props.history.push({
					pathname: '/home/board/consents/templates/edit',
					state: { templateId: id }
				});
			}
		}
	}

	render() {
		const { tableData, loading, disabled } = this.state

		return <div className="new-templates-root">
				<div className="back-btn-area">
					<Button><LeftOutlined /><Link to="/home/board/consents/list">{__('Back to consents')}</Link></Button>
				</div>
                <div className="template-operate-area">
					<Button type="primary" disabled={disabled}><Link to="/home/board/consents/templates/edit">{__('Create template')}</Link></Button>
                </div>
                <div className="template-list-table">
					<Table 
						size="small" 
						rowKey="index" 
						columns={templateColumns} 
						dataSource={tableData} 
						pagination={false} 
						loading={loading} 
						onRow={record => {
							return {
							  onClick: this.handleClickRow.bind(this, record),
							};
						  }}
					/>
				</div>
            </div>
	}
}