import React, { Component } from "react";
import moment from "moment";
import {Button, Col, Input, Row, Tooltip} from "antd";
import security from 'stores/security';
import DateSelector from 'components/DateSelector';
import { I, formatNumber } from "Utils";
import { formatDateForDatePicker, formatDate2Api } from 'Utils/format/formatDate';
import formatPrice from 'Utils/format/formatPrice';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import editImg from "../img/edit.png";

class CustomizeModel extends Component {

    state = {
        loading: false,
        formData: [
            { label: __('Closing date'), name:'closing_date', key: 'closingDate', newKey: 'closingDate', checkKey: '', value: moment(), type: 'date', help: __('This is the last issue date of the funding round.') },
            { label: __('Cash raised'), name:'cash_raised', key: 'cashRaised', newKey: 'history_cashRaised', checkKey:'historySecurityList', value: '', type: 'decimal' },
            { label: __('Shares issued'), name:'shares_issued', key: 'sharesIssued', newKey: 'history_sharesIssued', checkKey:'historyShareSecurityList', value: '', type: 'int', hasEdit: true, openType: 2 },
            { label: __('Original issue price'), name:'issue_price', key: 'originalIssuePrice', newKey: 'originalIssuePrice', checkKey:'historySecurityList', value: '', type: 'decimal' },
            { label: __('Fully diluted share'), name:'fully_diluted', key: 'fullyDilutedShares', newKey: 'history_fullyDilutedShares', checkKey:'historySecurityList', value: '', type: 'int', hasEdit: true, openType: 1 },
        ],
    };

    componentDidMount() {
        const { data } = this.props;
        const { formData } = this.state;
        let newFormData = [...formData];
        newFormData.forEach((v, k)=>{
            newFormData[k].value = v.type === 'date' ? formatDateForDatePicker(I(data[v.key], '')): I( I(data[v.checkKey], '') ? data[v.newKey] : data[v.key], '');
        });
        this.setState({formData: newFormData})
    }

    /**
     * 平铺循环时区分日期插件
     * @param label
     * @param name
     * @param value
     * @param type
     * @returns {JSX.Element}
     */
    getFormValue = (label, name, value, type) => {
        const { currency } = security.companyInfo;
        if(type == 'date'){
            return <DateSelector
                inputReadOnly={true}
                value={value}
                className="financing-customize-date"
                readOnly={true}
                disabled={true}
            />
        }else{
            return <Input
                value={ type === 'decimal' ? formatPrice(value) : formatFractionalNumber(value) }
                className="financing-customize-input"
                addonBefore={type === 'decimal' && currency}
                readOnly={true}
                disabled={true}
            />
        }
    }

    /**
     * 平铺表单内容
     * @returns {*}
     */
    formHtml = () => {
        const { formData } = this.state;
        return formData.map((v)=> {
            const {label, name, value, type, hasEdit, help, openType } = v;
            return (
                <dl key={v.key} className="history-customize-form-data">
                    <dt>
                        <span>{label}</span>
                        {help && <Tooltip title={help}><div className="history-customize-form-help">?</div></Tooltip>}
                    </dt>
                    <dd>
                        {this.getFormValue( label, name, value, type)}
                        <Button onClick={this.getFullyDiluted.bind(this, openType)} type="primary" className={`history-customize-button ${!hasEdit && 'history-customize-button-hidden'}`}>
                            <img src={editImg} width={17} />&nbsp;&nbsp;<span>{__('Edit')}</span>
                        </Button>
                    </dd>
                </dl>
            )
        });
    }

    getFullyDiluted = (openType) => {
        const { callback, close, data } = this.props;
        const { id } = data;
        close();
        callback('3', id, { openType } );
    }

    render() {
        const { loading } = this.state;
        const { close } = this.props;
        return (
            <div className="financing-history-customize">
                {this.formHtml()}
                <Button className="history-customize-confirm" loading={loading} type="primary" onClick={close} >{__('Confirm')}</Button>
            </div>
        )
    }
}
export default CustomizeModel;