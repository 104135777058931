import React, { Component } from "react";
import "./style.scss";
import {Button, Form, Input, Select, AutoComplete, message} from "antd";
import { post } from 'srcPath/http';


class ContactsAdd extends Component {

    formRef = React.createRef();

    state = {
        userList: [],
        nameValue: '',
        emailValue: '',
    }

    componentDidMount() {
        this.formRef.current.setFieldsValue({
            emailAddress: '',
            contactName: '',
        });
        const { adminList } = this.props;
        this.setState({userList: adminList})
    }

    addEmailAddress = (value) => {
        const { addEmailAddress, close } = this.props;
        addEmailAddress(value, close);
    }

    checkForm = (value) => {
        const { contractList } = this.props;
        const checkIndex = contractList.findIndex( v => v.email == value);
        if(checkIndex === -1){
            return true;
        }
    }

    checkUnique = (rule, value, callback) => {
        if(!this.checkForm(value)){
            callback('Email already exists.');
        }
        callback();
    }

    selectChange = (value) => {
        const { userList } = this.state;
        this.formRef.current.setFieldsValue({
            contactName: userList[value].name,
            emailAddress: userList[value].email,
        });
    }

    render() {
        const { userList } = this.state;
        return (
            <Form
                ref={this.formRef}
                validateMessages={{required: '${label} is required!', types: {email:'Email address is not a valid email!'} }}
                style={{width: '100%'}}
                onFinish={this.addEmailAddress}
            >
                <div className="billing-contacts-card-add-email">
                    <div className="billing-contacts-card-add-email-left">
                        <Form.Item
                            label="Name"
                            name="contactName"
                            colon={false}
                            rules={[{ required: true }]}
                        >
                            <AutoComplete
                                className="billing-contacts-card-select"
                                placeholder={__('Select or Enter')}
                                onSelect={this.selectChange}
                                filterOption={(inputValue, option) => {
                                    const { children } = option.props.children.props.children[0].props;
                                    return children.indexOf(inputValue) >= 0;
                                }}
                            >
                                {
                                    userList.map( (v, k)=>(
                                        <AutoComplete.Option key={k} value={k}>
                                            <div className="billing-contacts-card-select-option">
                                                <span>{v.name}</span>
                                                <span>{v.email}</span>
                                            </div>
                                        </AutoComplete.Option>
                                    ))
                                }
                            </AutoComplete>
                        </Form.Item>
                        <Form.Item
                            label="Email address"
                            name="emailAddress"
                            colon={false}
                            rules={[
                                { required: true, type: 'email' },
                                { validator: (rule, value, callback) => this.checkUnique(rule, value, callback) },
                            ]}
                        >
                            <AutoComplete
                                className="billing-contacts-card-select"
                                placeholder={__('Select or Enter')}
                                onSelect={this.selectChange}
                                filterOption={(inputValue, option) => {
                                    const { children } = option.props;
                                    return children.indexOf(inputValue) >= 0;
                                }}
                            >
                                {
                                    userList.map( (v, k)=>(
                                        <AutoComplete.Option key={k} value={k}>{v.email}</AutoComplete.Option>
                                    ))
                                }
                            </AutoComplete>
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <div className="billing-contacts-card-add-email-submit">
                            <Button type="primary" htmlType="submit">Save</Button>
                        </div>
                    </Form.Item>
                    <Form.Item
                        name="contactName"
                        hidden={true}
                    >
                        <Input />
                    </Form.Item>
                </div>
            </Form>
        );
    }
}
export default ContactsAdd;