import React, { Component } from 'react';
import { Button, message, Spin } from 'antd';
import Modal, { ModalContent } from 'components/Modal';
import EmptyPage from './empty'
import './style.scss'



export default class EmptyPageContainer extends Component {
    openCount = 1
    componentDidMount () {
        if (this.openCount == 1) {
            this.handleOpenDialog()
            this.openCount += 1
        }
        
    }
	handleOpenDialog() {
        // this.props.close && this.props.close()
		Modal.open({
			component:  <EmptyPage close={this.props.close} />,
			width: '400px',
			maskClosable: true,
			className: 'sprout-modal'
		})

		return
	}

	render() {
		return <div className="client-portfolio-root">
		</div>
	}
}