import React, { Component } from 'react';
import { Spin, Form, Input, Select, message, DatePicker } from 'antd'
import { withForm } from 'react-antd-formutil';
import DateSelector from 'components/DateSelector';
import moment from "moment";
import { post } from 'srcPath/http';
import './style.scss';
const { Option } = Select;

import { filterAmount, setCursorPos, I } from 'Utils';


const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

class AdvanceTermsModels extends Component {
    constructor(props) {

        super(props);
        this.state = {
            dateFormat: "DD/MM/YYYY",
            loading: false,
            isAdd: true,
            valuationSource: '',//valuationSource
            valuationProvider: '', //valuationProvider
            fairMarketValue: '',//fairMarketValue
            effectiveDate: '',
            expirationDate: '01/22/2022',
            shareList: {
                Common: ''
            },
            data: {},
            provider_list: [
                {
                    name: 'Alvarez & Marsal'
                },
                {
                    name: 'American Business Appraisers'
                },
                {
                    name: 'Anderson Tax'
                },
                {
                    name: 'Anvil'
                },
                {
                    name: 'Aranca'
                },
                {
                    name: 'Arcstone'
                },
                {
                    name: 'Armanino'
                },
                {
                    name: 'Bay Valuation Advisors (BVA)'
                },
                {
                    name: 'BDO'
                },
                {
                    name: 'Cabrillo'
                },
                {
                    name: 'Deloitte'
                },
                {
                    name: 'Duff & Phelps'
                },
                {
                    name: 'Economic Partners (formerly Greener Equity)'
                },
                {
                    name: 'Ernst & Young'
                },
                {
                    name: 'Grant Thornton'
                },
                {
                    name: 'Intrinsic'
                },
                {
                    name: 'KPMG'
                },
                {
                    name: 'Meld Valuation'
                },
                {
                    name: 'McLean Group'
                },
                {
                    name: 'Oxford Partners'
                },
                {
                    name: 'Preferred Return'
                },
                {
                    name: 'PwC'
                },
                {
                    name: 'Redwood'
                },
                {
                    name: 'Scalar'
                },
                {
                    name: 'Simple409A'
                },
                {
                    name: 'Solium'
                },
                {
                    name: 'SVB'
                },
                {
                    name: 'Teknos'
                },
                {
                    name: 'Timan'
                },
                {
                    name: 'Valuation Research Corporation (VRC)'
                },
                {
                    name: 'Vantage Point Advisors'
                },
                {
                    name: 'Other'
                },
            ],
            commonlist: [],
            commonClass: '',
            sourceType: 1,
            id: ''
        }
    }
    formRef = React.createRef()
    componentDidMount = () => {
        const { isAdd, val, shareClass } = this.props
        const { dateFormat, shareList } = this.state
        let newlist = I(shareClass, []).map((item) => item.share_class_name)
        newlist.map(item => {
            shareList[item] = ''
        })
        let commonlist = [...shareClass]
        let commonClass = commonlist.shift().share_class_name;
        this.setState({
            isAdd,
            shareList,
            valuationSource: '409A',
            commonlist,
            commonClass
        })
        this.formRef.current.setFieldsValue({
            valuationSource: '409A',
        })
        if (isAdd) {
            return
        }
        const { effective_date, expiration_date, fair_market_value, id, valuation_source, valuation_provider, share_list } = val
        let new_share_list = share_list ? JSON.parse(share_list) : shareList
        this.setState({
            valuationSource: valuation_source,
            valuationProvider: valuation_provider,
            // fairMarketValue: new_share_list[commonClass] ? new_share_list[commonClass] : fair_market_value,
            fairMarketValue: fair_market_value,
            effectiveDate: effective_date,
            expirationDate: expiration_date,
            shareList: share_list ? JSON.parse(share_list) : shareList,
            id
        })
        this.formRef.current.setFieldsValue({
            valuationSource: valuation_source,
            valuationProvider: valuation_provider,
            fairMarketValue: fair_market_value,
            // fairMarketValue: new_share_list[commonClass] ? new_share_list[commonClass] : fair_market_value,
            effectiveDate: moment(effective_date, dateFormat),
            expirationDate: moment(expiration_date, dateFormat)

        });

    }

    /**
 * 关闭model
 */
    closeModels = () => {
        const { close } = this.props;
        close();
    }
    payView = () => {
        const { payOverView } = this.props
        payOverView()
    }

    onSubmit = async () => {

        const { valuationSource, valuationProvider, fairMarketValue, effectiveDate, expirationDate, shareList, isAdd, id, sourceType: type } = this.state
        const { newdatalist, val ,EchartsSourec} = this.props
        const { fmv_type } = val||{}
        if (!isAdd) {
            let isExistence = EchartsSourec.map((item, i) => {
                if (item.id != id) {
                    return item.effective_date
                }
            })
            this.getisExistence(isExistence, effectiveDate)
        } else {
            this.getisExistence(newdatalist, effectiveDate)
        }
        let value = await this.formRef.current.validateFields();
        if (!value) { return }
        let data = { valuationSource, valuationProvider, fairMarketValue, effectiveDate, expirationDate, shareList, id, type, fmv_type }
        this.setState({
            loading: true
        })
        post(isAdd ? 'addFairMarketValue' : 'updateFairMarketValue', data).then(res => {
            if (res.status == 200) {
                this.setState({
                    loading: false
                })
                this.closeModels()
                this.payView()
            }
        }).catch(function (error) {
            this.setState({
                loading: false
            })
        });
    }
    // 判断是否存在日期
    getisExistence(datalist, effectiveDate) {
        datalist.forEach(item => {
            if (item == effectiveDate) {
                this.formRef.current.setFieldsValue({
                    effectiveDate: '',
                });
                return
            }
        })
    }
    filterAmount = (e) => {
        return filterAmount(e);
    }

    // fair Common
    bindChangeFairValue = (key, e) => {
        const { shareList } = this.state
        const filter = e.target;
        if (filter.value.length > 1 && filter.value.slice(0, 1) == "0" && filter.value.slice(1, 2) !== ".") {
            return
        }
        filter.value = filter.value.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符   
        filter.value = filter.value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的   
        filter.value = filter.value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
        filter.value = filter.value.replace(/^(\-)*(\d+)\.(\d{5}).*$/, '$1$2.$3');//只能输入两个小数
        this.setState({
            fairMarketValue: filter.value,
        })

        // const element = e.target;
        // const filter = filterAmount(e);
        // shareList[key] = filter.value
        // this.setState({
        //     fairMarketValue: filter.value,
        //     // shareList
        // }, () => {
        //     setCursorPos(element, filter.cursorPositionX);
        // });
    }
    bindChangeShareValue = (key, e) => {
        const { shareList } = this.state
        const element = e.target;
        const filter = filterAmount(e);
        shareList[key] = filter.value
        this.setState({
            shareList
        }, () => {
            setCursorPos(element, filter.cursorPositionX);
        });
    }
    handleChange = (valuationSource, value) => {
        const { select_list } = this.props
        this.setState({
            valuationSource,
            valuationProvider: '',
            sourceType: select_list[value.key].type,
            id: select_list[value.key].id
        })
        this.formRef.current.setFieldsValue({
            valuationProvider: ''
        })
    }
    bindValuationProvider(e) {
        this.setState({
            valuationProvider: e.target.value,
        })
    }
    handleChangevalue = (valuationProvider) => {
        this.setState({
            valuationProvider
        })
    }
    handleBirthdayChange = (key, date) => {

        const { dateFormat } = this.state;
        let year = date.year() + 1
        let month = date.get("month") + 1
        let day = date.date()
        if (month >= 1 && month <= 9) {
            month = "0" + month;
        }
        if (day >= 1 && day <= 9) {
            day = "0" + day;
        }
        let expirationDate = day + '/' + month + '/' + year
        this.setState({
            [key]: date.format(dateFormat),
        })
        if (key == 'effectiveDate') {
            this.setState({
                expirationDate
            })
            this.formRef.current.setFieldsValue({
                expirationDate: moment(expirationDate, dateFormat)
            });
        }

    }
    fixdate = (date) => {
        const { dateFormat } = this.state;
        return date.add(1, "years").format(dateFormat)
    }
    render() {
        const { loading, dateFormat, valuationSource, valuationProvider, fairMarketValue, effectiveDate, expirationDate, shareList, provider_list, commonlist, commonClass, sourceType } = this.state
        const { shareClass, select_list, currency,val } = this.props
        const {fmv_type} =val||{}
        return (
            <div className='Market-Value-Model'>
                <Spin tip="Loading..." spinning={loading}>
                    <div className="merge-stakeholder-header">
                        <h5 className="title">{__('Add fair market value')}</h5>
                        <div className="close " onClick={this.closeModels} >×</div>
                    </div>

                    <div className="merge-stakeholder-content">

                        <Form {...layout} ref={this.formRef} name="control-hooks" className='MarketValueForm'

                        >
                            <Form.Item name="valuationSource" label={__('Valuation source')}
                                colon={false}
                                rules={[
                                    {
                                        required: true,
                                        message: __('Select a valid provider'),
                                    },
                                ]}>
                                <Select
                                    placeholder="Select a option and change input text above"
                                    allowClear
                                    onChange={this.handleChange}
                                    key={valuationSource}
                                    disabled={fmv_type==1}
                                // defaultValue={valuationSource}

                                >
                                    {
                                        select_list && select_list.map((item, key) => {
                                            return (<Option key={key} value={item.name}>{item.name}</Option>)
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            {valuationSource == '409A' &&
                                <Form.Item name="valuationProvider" label={__('Valuation provider')} colon={false} rules={[
                                    {
                                        required: true,
                                        message: __('Enter a valid provider'),
                                    },

                                ]}>
                                    <Input placeholder={__('Enter a valid provider.')} value={valuationProvider} onChange={this.bindValuationProvider.bind(this)} />
                                    {/* <Select
                                        placeholder="Select a valid provider."
                                        allowClear
                                        onChange={this.handleChangevalue}
                                    // defaultValue={valuationProvider}
                                    >
                                        {
                                            provider_list && provider_list.map((item, key) => {
                                                return (<Option key={key} value={item.name}>{item.name}</Option>)
                                            })
                                        }
                                    </Select> */}
                                </Form.Item>
                            }


                            <div className='Fmvdaterange'>
                                <Form.Item label={__('FMV date range')} className='data1' colon={false}
                                    name='effectiveDate'
                                    rules={[
                                        {
                                            required: true,
                                            message: __('Select a valid date'),
                                        },
                                    ]}>
                                    <DateSelector
                                        disabledDate={current => current && current > moment().endOf('day')}
                                        className="MaRit5 2"
                                        onChange={this.handleBirthdayChange.bind(this, 'effectiveDate')}
                                        inputReadOnly={false}
                                        value={effectiveDate ? moment(effectiveDate, dateFormat) : effectiveDate}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className='data2'
                                    name='expirationDate'
                                    rules={[
                                        {
                                            required: true,
                                            message: __('Select a valid date'),
                                        },
                                    ]}>

                                    <DateSelector
                                        onChange={this.handleBirthdayChange.bind(this, 'expirationDate')}
                                        inputReadOnly={false}
                                        value={expirationDate ? moment(expirationDate, dateFormat) : expirationDate}
                                    />
                                </Form.Item>
                            </div>

                            <Form.Item label={__('FMV per share')} colon={false} className='ShareValues' name='fairMarketValue' rules={[
                                {
                                    required: true,
                                    message: __('Enter a FMV per share'),
                                },
                            ]}>
                                <div className='ShareTableList'>
                                    <div className='wid35 name'>{currency}</div>
                                    <Input className='ShareInput FmvShare' value={fairMarketValue} onChange={this.bindChangeFairValue.bind(this, commonClass)} />
                                </div>

                            </Form.Item>

                            {/* <Form.Item label="FMV per Share" colon={false} className='ShareValues' name='fairMarketValue' rules={[
                                {
                                    required: true,
                                    message: 'Enter a common FMV per Share',
                                },
                            ]}>
                                <div className='ShareTableList'>
                                    <div className='wid55 name'>{commonClass}</div>
                                    <Input className='ShareInput' value={fairMarketValue} onChange={this.bindChangeFairValue.bind(this, commonClass)} />
                                </div>

                            </Form.Item> */}

                            {/* {commonlist.map((value, key) => {
                                return (<Form.Item label="label" colon={false} key={key} className='ShareValues ShareValues_list'>
                                    <div className='ShareTableList'>
                                        <div className='wid55 name'>{value.share_class_name}</div>
                                        <Input className='ShareInput' value={shareList[value.share_class_name]} onChange={this.bindChangeShareValue.bind(this, value.share_class_name)} />
                                    </div>

                                </Form.Item>)
                            })} */}


                        </Form>
                    </div>


                    <div className="information-w100">
                        <a className="btn boxbtn-to bg-darkgrey " data-dismiss="modal" onClick={this.closeModels}>{__('Cancel')}</a>
                        <a className="btn boxbtn-to bg-darkblue " onClick={this.onSubmit}>{__('Save')}</a>

                    </div>
                </Spin>
            </div>
        )
    }
}
export default withForm(AdvanceTermsModels);