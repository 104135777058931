import React, { useState, useEffect } from 'react';
import { FormItem } from 'react-antd-formutil';
import { Select } from 'antd'
import { post } from 'srcPath/http';
import { omit } from 'lodash';
import security from 'stores/security';

const Option = Select.Option

export default function TokenSelect(props) {
    const customCoinList = (security.companyInfo && security.companyInfo.custom_coin_list) || []
    const { name, label, type } = props
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setOptions(customCoinList || [])
    }, [customCoinList])

    return <FormItem
        name={name || 'token'}
        label={label || __('Token name')}
    >
        <Select {...omit(props, ['name', 'label'])} loading={loading} placeholder={__('Select')} mode="multiple" showSearch>
            {
                options.map((val) => (<Option key={val.id} value={val.name}>{val.name}</Option>))
            }
        </Select>
    </FormItem>

}