import React, { useState, useEffect } from 'react';
import { FormItem } from 'react-antd-formutil';
import { Checkbox,} from 'antd'
import { post } from 'srcPath/http';
import { omit } from 'lodash';
import { certificateStatus } from "Utils/constant";

const Group = Checkbox.Group

export default function TransactionTypeSelect(props) {
    const { name, label } = props
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => { 
        const getData = async () => {
            try {
                const { data: resData } = await post('getFilterTransactionType');

                setOptions(resData?.data || [])
            } catch (e) { 

            }

            setLoading(false)
        } 

        getData()
    }, [])

    return <FormItem
        name={name || 'transaction_type'}
        label={label || __('Transaction type')}
    >
        <Group {...omit(props, ['name', 'label'])} showSearch placeholder={__('Select')} options={[ ...(options.map(value => ({ label: certificateStatus[value], value })))]} />
    </FormItem>

}