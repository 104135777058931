import React, { Component, Fragment } from 'react';
import { Spin, message, Collapse, Tooltip } from 'antd';
import Loading from 'components/Loading';
import { post } from 'srcPath/http';
import { formatPercent, formatNumber } from 'Utils';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import openCertificateModal from 'components/CertificateDetail';
import RoundTags from 'components/RoundTags'
import image1 from 'assets/images/CS-Certificate.png';
import Empty from 'components/Empty';
import { formatDateForDatePicker, formatDate2Api } from 'Utils/format/formatDate';
import DateSelector from 'components/DateSelector';
import moment from "moment";
import './style.scss';


const { Panel } = Collapse

const formatPercentMulti = val => formatPercent(val, 'multiply')

export default class ByStakeholder extends Component {
	state = {
		data: {},
		loading: true,
		fullyDiluted: 0,
		share_prefix: [],
		totaVoting: 0,
		votes: 0,
        date: moment(),
	}

	componentDidMount() {
		this.getData()
	}

	renderItem(data, index, color, style) {
		const {  name, fullyDiluted, rounds, votingPower, votes } = data
		const { fullyDiluted: funnFullyDiluted, share_prefix, totaVoting } = this.state

		return <div className="captable-stake-holder-content" key={index}>
			<div className="stake-holder-content-first">
				{name}<br/>
			</div>
			<div className="item">{<Tooltip title={<RoundTags isPick allTags={share_prefix || []}  tags={rounds}/>}><span><RoundTags allTags={share_prefix || []}  tags={rounds}/></span></Tooltip>}</div>
			<div className="item">{formatFractionalNumber(fullyDiluted)}</div>
			<div className="item">--</div>
			<div className="item">{formatNumber(votes, 0)}</div>
			<div className="item">{formatPercentMulti(votingPower/totaVoting)}</div>

		</div>
	}

	renderPanel(data, index, style) {
		const { certificateNumber, fully, security_id, prefix, voting_multiple, voting_power, votes } = data
		const { fullyDiluted: funnFullyDiluted, share_prefix, totaVoting } = this.state

		return <div className="captable-stake-holder-content" key={index}>
			<div className="stake-holder-content-first">
				<img src={image1} onClick={() => { security_id && openCertificateModal(security_id)  }}/>
				{certificateNumber}<br/>
			</div>
			<div className="item">{<RoundTags allTags={share_prefix || []}  tags={[prefix]}/>}</div>
			<div className="item">{formatFractionalNumber(fully)}</div>
			<div className="item">{voting_multiple}</div>
			<div className="item">{formatNumber(votes, 0)}</div>
			<div className="item">{formatPercentMulti(voting_power/totaVoting)}</div>
		</div>
	}

	getData = async () => {
		this.setState({ loading: true })
        const { date } = this.state;
        const capTableDate = formatDate2Api(date);
		try {
			const { data: resData } = await post('getVotingPower', {capTableDate})

			const { data, code } = resData

			if (code == 0) {
				const { shareholders = [], share_prefix, total_voting } = data

				shareholders.forEach((val, index) => {
					shareholders[index] = this.processData(val)
				})

				this.setState({
					// 按照fullyDiluted 降序排序
					shareholders: shareholders, //.sort((pre, next) => next.fullyDiluted - pre.fullyDiluted),
					share_prefix,
					totaVoting: total_voting,
					...this.setSumData(shareholders)
				})
			}
		} catch (err) {
			message.error( err && err.message)
		}

		this.setState({ loading: false })
	}

	setSumData = (shareholders) => {
		let fullyDiluted = 0
		let votes = 0

		shareholders.forEach(val => {
			fullyDiluted += Number(val.fullyDiluted || 0)
			votes += Number(val.votes || 0)
		})

		return { fullyDiluted, votes }
	}

	processData = (data = {}) => {
		const { certificateDetails = [], name } = data
		const res = {
			name,
			fullyDiluted: 0,
			votingPower: 0,
			certificateDetails,
			rounds: [],
			votes: 0
		}
			certificateDetails.forEach(item => {
				let fullyDiluted = Number(item.fully || 0);
				res.fullyDiluted = res.fullyDiluted + fullyDiluted;

				let votingPower = Number((item.voting_power || 0))
				res.votingPower = res.votingPower + votingPower;
				item.votes = Number(item.voting_multiple || 0) * fullyDiluted
				res.votes = res.votes + item.votes

				if (item.prefix && !res.rounds.includes(item.prefix)) {
					res.rounds.push(item.prefix)
				}

			})

		return res
	}

    /**
     * 查询日期change事件
     * @param date
     */
    dateChange = (date) => {
        this.setState({date}, ()=>{
            this.getData();
        });
    }

    /**
     * 不允许选择未来日期
     * @param current
     * @returns {boolean}
     */
    checkDate = (date) => {
        return date && date > moment().endOf('day');
    }

	render() {
		const { shareholders = [], loading, fullyDiluted, votes, date } = this.state
		
		const isEmpty = shareholders.length === 0	

		if(loading) {
			return <Loading />
		}

		return <div className="captable-voting-power">
            <div className="captable-voting-power-date">
                {/*<div className="captable-label">{__('View cap table as of')}</div>
                <DateSelector
                    className="captable-voting-power-date-selector"
                    inputReadOnly={true}
                    value={date}
                    disabledDate={this.checkDate}
                    onChange={this.dateChange}
                />*/}
            </div>
			<div className="captable-stake-holder-header">
				<div className="stake-holder-header-first">{__('Name')}</div>
				<div className="item">{__('Rounds')}</div>
				<div className="item">{__('Fully diluted')}</div>
				<div className="item">{__('Voting multiple')}</div>
				<div className="item">{__('Votes')}</div>
				<div className="item">{__('Voting power')}</div>
			</div>
			{
				isEmpty ? <div className="captable-stake-holder-empty "><Empty /></div> : <div className="voting-power-wrap">
					{
						shareholders.sort((a, b) => b.votingPower - a.votingPower).map((val, index) => <Collapse key={index}>
							<Panel header={this.renderItem(val, index, '#59d9b0')} >
								{
									val.certificateDetails && (val.certificateDetails.sort((a, b) => b.voting_power - a.voting_power).map((item, count) => this.renderPanel(item, count)))
								}
							</Panel>
						</Collapse>)
					}
					<div className="captable-stake-holder-option captable-stake-holder-option-summary">
						<div className="captable-stake-holder-content" style={{ fontWeight: 'bold' }}>
							<div className="stake-holder-content-first">
								{__('Total')}
							</div>
							<div className="item">--</div>
							<div className="item">{formatNumber(fullyDiluted, 0)}</div>
							<div className="item">--</div>
							<div className="item">{formatNumber(votes, 0)}</div>
							<div className="item">{formatPercent(1)}</div>
						</div>
					</div>
				</div>
			}	
		</div>
	}
}