import React, { Component } from 'react';
import { Button, message, Spin, Menu, Dropdown } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { post } from 'srcPath/http';
import Modal from 'components/Modal';
import openCreateMeetingModal from '../components/CreateMeetingModal';
import openCreateAttendeeModal from '../components/CreateAttendees';
import openPublishMeetingModal from '../components/PublishMeeting';
import openArchiveMeetingModal from '../components/ArchiveMeeting';
import openMeetingAgendaModal from '../components/AddAgenda';
import security from 'stores/security';

import { pick } from 'lodash';
import groupImg from 'assets/images/group.png';
import editImg from 'assets/images/editIcon.png';
import memberImg from 'assets/images/person.png';
import dragImg from 'assets/images/dragIcon.png';

import pdfImg from 'assets/images/pdfReview.png';
import externalLinkImg from 'assets/images/externalLink.png';
import textOnlyImg from 'assets/images/textOnly.png';

import { PlusOutlined, DownOutlined } from '@ant-design/icons';
import { statusMap, formatDate } from './config';
import Permission from 'components/Permission';
import Empty from 'components/Empty';
import Utils from "Utils";
import moment from 'moment';
import { formatDate as UtilFormatDate } from 'Utils/format/formatDate';
import './style.scss';

const url = `https://getsprout.oss-cn-hongkong.aliyuncs.com/Company`

const imgMap = {
	1: pdfImg,
	2: externalLinkImg,
	3: textOnlyImg
}

class Detail extends Component {
	meetingData = this.props.location.state

	state = {
		loading: false,
		data: {},
		attendees: []
	}

	componentDidMount() {
		if (!this.meetingData) {
			this.props.history.push('/home/meetings');

			return
		}

		this.getData()
		this.getAttendees()
	}

	getData = async () => {
		this.setState({ loading: true })

		try {
			const { data: resData } = await post('getMeetDetail', { meeting_id: this.meetingData.id })

			const { data, code } = resData

			if (code == 0) {
				this.setState({ loading: false, data })
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}

		this.setState({ loading: false })
	}

	getAttendees = async () => {
		this.setState({ loading: true })

		try {
			const { data: resData } = await post('getBoardMeetingAttendees', { meeting_id: this.meetingData.id })

			const { code, data } = resData

			if(code === 200) {
				this.setState({ attendees: data || [] })
			} else {
				message.error(data)
			}
		} catch (err) {
			message.error(err && err.message)
		}

		this.setState({ loading: false })
	}

	onDeleteAgenda = async (agenda_id, close) => {
		this.setState({ loading: true })

		try {
			const { data: resData } = await post('delBoardMeetingItem', { agenda_id, meeting_id: this.meetingData.id  })

			const { code, data } = resData

			close && close()

			this.getData()

		} catch (err) {
			message.err(err && err.message)
		}

		this.setState({ loading: false })
	}

	openDeleteModal = id => {
		Modal.open({
			component: props => <div>
				<p>Are you sure to delete this agenda?</p>
				<div  style={{ textAlign: 'center', marginTop: 30 }}>
					<Button onClick={props.close}>Cancel</Button>&nbsp;&nbsp;
					<Button type="primary" onClick={() => this.onDeleteAgenda(id, props.close)}>Ok</Button>
				</div>
			</div>
		})
	}

	goBack = () => {
		this.props.history.goBack()
	}

	goPermission = () => {
		this.props.history.push('/home/permissions/board')
	}

	onEditMeeting = () => {
		const { data } = this.state

		this.modalRef = openCreateMeetingModal(this.getData, pick({ meeting_id: this.meetingData.id, ...data}, ['meeting_id', 'meeting_date', 'meeting_link', 'meeting_location', 'meeting_name', 'status']), 'Edit board meeting')
	}
// agenda_description: "pdf test"
// agenda_id: "22"
// agenda_name: "pdf"
// agenda_url: null
// fileName: null
// order: "0"
// originalFileName: null
// type: "1"
	renderAgendaItem = (data, index) => {
		const { agenda_description, agenda_id, type, agenda_name, fileName } = data || {}

		return <div className="agenda-item" key={index}>
			<div className="details">
				<img src={imgMap[type]} />
				<div className="agenda-description">
					<div className="name">{agenda_name}</div>
					<div className="description" dangerouslySetInnerHTML = {{__html:agenda_description}}></div>
				</div>
			</div>
			<div className="agenda-item-btns">
				{type ==1 && <Permission type="board_meeting_detail_agenda"><Button className="sprout-btn-divider"  type="link" onClick={ () => window.open(Utils.getRealUrlForFiles(fileName, 'board'))} >{__('View')}</Button></Permission>}
				&nbsp;
				<Permission type="board_meeting_detail_agenda"><Button disabled={this.state.data.status === 'Archived'} type="link" danger onClick={() => this.openDeleteModal(agenda_id)}>{__('Delete')}</Button></Permission>
			</div>
		</div>
	}
	//meeting status  Draft Published Archived
	renderHeaderButtons = () => {
		const { data, attendees } = this.state
		const { status } = data || {}
		const buttonsMap = {
			Draft: <Permission type="board_meeting_detail_publish"><Button type="primary" onClick={() => openPublishMeetingModal({ board_members: data.board_members, attendees, meeting_id: this.meetingData.id }, () => { this.getData(); this.getAttendees() })}>Publish meeting</Button></Permission>,
			Published: <Dropdown
				placement="bottomCenter"
				overlay={<Menu>
					{/* 2021-11-20 aiya 隐藏 */}
					{/* <Menu.Item>
						<a>Notify guests</a>
					</Menu.Item> */}
					<Menu.Item>
						<Permission type="board_meeting_detail_publish"><a onClick={() => openArchiveMeetingModal(this.meetingData.id, this.getData)}>{__('Archive meeting')}</a></Permission>
					</Menu.Item>					
				</Menu>}
			>
				<Button type="primary">{__('Manage Meeting')} <DownOutlined /></Button>
			</Dropdown>
		}

		return buttonsMap[status]
	}

	renderAgendaMenuu = (props) => {
		const { data, attendees } = this.state
		const { board_members = [], status  } = data || {}
		const params = { board_members, attendees, meeting_id: this.meetingData.id }	
		// Archived 状态下不允许编辑
		return <Permission type="board_meeting_detail_agenda" ><Dropdown
			overlay={<Menu>
				<Menu.Item>
					<a
						onClick={() => openMeetingAgendaModal(Object.assign(params, { type: 'pdf' }), this.getData)}
					>{__('PDF file')}</a>
				</Menu.Item>
				<Menu.Item>
					<a
						onClick={() => openMeetingAgendaModal(Object.assign(params, { type: 'link' }), this.getData)}
					>{__('External link')}</a>
				</Menu.Item>
				<Menu.Item>
					<a
						onClick={() => openMeetingAgendaModal(Object.assign(params, { type: 'text' }), this.getData)}
					>{__('Text only')}</a>
				</Menu.Item>			
			</Menu>}
		>
			<div className="add" type="primary" ><PlusOutlined /></div>
		</Dropdown></Permission>
	}

	render() {
		const { loading, data, attendees= [] } = this.state
		const { status, meeting_name, meeting_date, meeting_location, meeting_link, board_members = [], agenda_items = [] } = data || {}
		// const date = formatDate(meeting_date)
		const newDate = meeting_date?.slice(0, 10)
		const finalDate = moment(new Date(newDate)).format('DD/MM/YYYY')
		const formatDateStr =  UtilFormatDate(finalDate)
		const formatTime = meeting_date?.slice(10)

		// meeting status 0-Draft 1-Published 2-Archived
		return <div className="meetings-detail-root">
			<Spin spinning={loading}>
				<Button size="small" icon={<LeftOutlined />} onClick={this.goBack}>{__('Back')}</Button>
				<div className="meetings-detail-content">
					<div className="meetings-detail-left">
						<div className="meetings-detail-banner">
							<img src={groupImg} />
							<div className="banner-content">
								<div className="banner-item">{statusMap[status] && statusMap[status].status || '--' }</div>
								<div className="banner-item description">{statusMap[status] && statusMap[status].description || '--' }</div>
							</div>
						</div>
						<div className="meeting-message">
							<div className="meetings-name">
								<span>{__('Meeting details')}</span>
								<Permission type="board_meeting_detail_edit"><img className="meetings-edit-icon" src={editImg} onClick={this.onEditMeeting} /></Permission>
							</div>
							<p><strong>{meeting_name}</strong></p>
							<div className="meetings-time">
								{formatDateStr}
								<br />
								{formatTime}
								<div className="meetings-location">
									{meeting_location}
								</div>
							</div>
							{ meeting_link && <a href={`//${meeting_link.replace(/^((ht|f)tps?):\/\//, val => '')}`} target="_blank">{__('Meeting Link')}</a>}
						</div>
						<div className="meeting-message">
							<div className="meetings-name">
								<span>Board members</span>
								{ status === 'Draft' && <Permission type="company_permission"><img className="meetings-edit-icon" src={editImg} onClick={this.goPermission} /></Permission>}
							</div>
							{
								board_members.length > 0 ? board_members.map((val, index) => <div key={index} className="meetings-detail-banner">
									<img src={memberImg} />
									<div className="banner-content">
										<div className="banner-item">{val.name || '--'}</div>
										<div className="banner-item description">{val.role || '--' }</div>
									</div>
								</div>) : <Empty description="No board members" className="mettings-empay" imageStyle={{height: 50}} />
							}
						</div>
						<div className="meeting-message">
							<div className="meetings-name">
								<span>{__('Attendees')}</span>
								{status !== 'Archived' && <Permission type="board_meeting_detail_attendees"><img className="meetings-edit-icon" src={editImg} onClick={() => { openCreateAttendeeModal(this.getAttendees, Object.assign({attendees}, { meeting_id: this.meetingData.id })) }}/></Permission>}
							</div>
							{
								attendees.length > 0 ? attendees.map((val, index) => <div key={index} className="meetings-detail-banner">
									<img src={memberImg} />
									<div className="banner-content">
										<div className="banner-item">{val.attendees_name || '--'}</div>
										<div className="banner-item description">{val.role || '--' }</div>
									</div>
								</div>) : <Empty description="No Attendees" className="mettings-empay" imageStyle={{height: 50}} />
							}
						</div>		
					</div>
					<div className="meetings-detail-right">
						<div className="meetings-detail-right-banneer">
							{this.renderHeaderButtons()}
						</div>
						<div className="meetings-detail-right-content">
							<div className="meetings-detail-right-wrap">
								<div className="banner">
									{__('Agenda')}
									{
										status !== 'Archived' && this.renderAgendaMenuu()
									}
								</div>
								<div className="content">
									{
										agenda_items && agenda_items.length > 0 ? agenda_items.map((val, index) => this.renderAgendaItem(val, index)) : <Empty description="No Agenda Items" className="mettings-empay" imageStyle={{height: 50}} />
									}
								</div>
							</div>
						</div>
					</div>
					
				</div>
			</Spin>
		</div>
	}
}

export default Detail