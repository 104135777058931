import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { roundColor } from 'Utils';
import classnames from 'classnames';
import './style.scss';

const colorMap = {
	green: '#28BC7D',
	gray: '#bdd1d5',
	black: '#eeeeee'
}

export default class Dot extends Component {
	static propTypes = {
		color: PropTypes.string.isRequired,
		position:  PropTypes.string, // left | right
		size: PropTypes.number
	}

	render() {
		const { size = 8, color, position = 'left' } = this.props
		return <div className={classnames('sprout-dot-root', {'lay-right': position === 'right'} )}>
			{ position === 'left' && <div className="dot-icon" style={{ width: size, height: size, background: colorMap[color] || color }} />}
			<div className="dot-content">{this.props.children}</div>
			{ position === 'right' && <div className="dot-icon" style={{ width: size, height: size, background: colorMap[color] || color }} />}
		</div>
	}
}