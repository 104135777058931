import React from 'react';
import { message } from "antd";
import security from 'stores/security';
import { post } from 'srcPath/http';

class InviteUnregistered extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inviteStatus: 0
        }
    }
    inviteUnregistered = () => {
        if (this.state.inviteStatus == 1) {
            return;
        }
        this.setState({
            inviteStatus: 1
        })
        post('inviteAllStakeHolder').then((response) => {
            if (response.data.code === 0) {
                this.setState({
                    inviteStatus: 0
                })
                window.$('#inviteConfirmation').modal('hide');
                message.success("Success!")
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    render() {
        const hasChecked = security.companyInfo && security.companyInfo.user_access_control === '1' // '1' 开关开启  ’0‘ 开关关闭
        const text = !hasChecked ? __('Resend invite is not enabled. \nPlease go to the Setting page to turn on the Stakeholder Access to enable it.') : __('A new invitation email will be sent to all the unregistered users. This allows the stakeholders access according to their access level setting.')
        return (
            <div className="modal fade" id="inviteConfirmation" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={{ display: "none" }} >
                <div className="modal-dialog dialog440">
                    <div className="modal-content box-content content440">
                        <div className="modal-header box-header">
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Invite unregistered')}</h4>
                        </div>
                        <div className="modal-body box-body padding2026">
                            <p className="text-muted no-marginBottom" style={{ 'whiteSpace': 'pre-line' }}>{text}</p>
                        </div>
                        <div className="modal-footer box-footer">
                            {!hasChecked && <a className="btn boxbtn-to bg-darkblue width120" data-dismiss="modal" >{__('OK')}</a>}
                            {hasChecked && (
                                <>
                                    <a className="btn boxbtn-to bg-darkblue width120" data-dismiss="modal" >{__('Cancel')}</a>
                                    <a className="btn boxbtn-to bg-darkblue width120" onClick={this.inviteUnregistered}>{this.state.inviteStatus == 0 ? __("Confirm") : __('Sending') + "..."}</a>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default InviteUnregistered;

