import React, { Component } from 'react';
import SimpleTable from '../components/SimpleTable';
import { formatDate } from 'Utils/format/formatDate';
import { formatCurrencyNumber } from 'Utils';
import formatFractionalNumber from "../../../modules/Utils/format/formatFractionalNumber";
import { sprintf,printf } from 'Utils/i18n';
export default function ExerciseDetail(props) {
    const { data, accountUsers } = props;

	return <div className="certificate-detail-section exercise-details-section">
		{ props.data.map((val,key)=>{
            const getAdminName = () => {
                const index = accountUsers.findIndex(v=>v.id == val.payment_verified_admin_id);
                return index !== -1 ? accountUsers[index].name : '';
            }

			const config = [
				{
					label: __('Exercise date'),
					value: val.exercise_date && formatDate(val.exercise_date)
				},
				{
					label: __('Payment date'),
					value: val.payment_date && formatDate(val.payment_date)
				},
				{
					label: __('Payment collected'),
					value: formatCurrencyNumber(val.payment_collected),
				},
				{
					label: __('Payment method'),
					value: val.payment_method ? val.payment_method : '--',
				},
				{
					label: __('Payment verified by'),
					value: val.payment_verified_admin_id && getAdminName(),
				},
				{
					label: __('Prepay price'),
					value: formatCurrencyNumber(val.actual_exercise_price),
				},
				{
					label: __('Amount reimbursed by company'),
					value: val.amount_reimbursed
				},
				{
					label: __('Amount reimbursement date'),
					value: val.amount_reimbursement_date && formatDate(val.amount_reimbursement_date)
				},
				{
					label: __('Exercise agreement'),
					value: val.exercise_file.name && val.exercise_file.name,
					url: val.exercise_file.file_url,
				}
			];
			
			return 	<SimpleTable key={key} title={sprintf(__('Exercise details for %s shares'),val.target_security+", "+formatFractionalNumber(val.quantity))} config={config} />
		})}
	</div>
}