import React, { Component } from "react";
import {Button, Input, Spin, Table} from "antd";
import security from 'stores/security';
import {ArrowLeftOutlined} from "@ant-design/icons";
import { printf } from 'Utils/i18n';
class CompleteStakeholderMergeModels extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading : true,
            desc : __('Read and understand the terms below.'),
            explain : __('I understand that merging these stakeholders is irreversible and that the information provided is accurate. I understand that this action may affect my cap table.'),
            signature : security.userInfo.signature,
            buttonDisabled : true,
            signatureValue : ''
        }
        this.signatureValueChange = this.signatureValueChange.bind(this);
    }
    componentDidMount() {
        this.setState(()=>({
            loading : false
        }))
    }

    /**
     * 签名输入框赋值并验证是否允许提交
     * @param e
     */
    signatureValueChange = (e) => {
        const { signature } = this.state;
        const { value : signatureValue } = e.target;
        let buttonDisabled = true;
        if(signatureValue == signature){
            buttonDisabled = false
        }
        this.setState(()=>({
            signatureValue,
            buttonDisabled
        }))
    }

    /**
     * 提交 merge stakeholders
     */
    saveStakeholderData = () => {
        const { saveStakeholderData, close } = this.props;
        this.setState(()=>({
            loading : true
        }));
        saveStakeholderData(close);
    }


    goBackModels = () => {
        const { close, getReviewStakeholderModels } = this.props;
        getReviewStakeholderModels();
        close();
    }

    /**
     * 关闭model
     */
    closeModels = () => {
        const { close, initStakeHolderData } = this.props;
        initStakeHolderData();
        close();
    }

    render() {
        const { loading, desc, explain, signature, buttonDisabled, signatureValue } = this.state;
        const { saveLoading } = this.props;
        return (
            <Spin tip="Loading..." spinning={loading}>
                <div className="merge-stakeholder-header">
                    <ArrowLeftOutlined className="go-back" onClick={this.goBackModels} />
                    <h5 className="title">{__('Review Stakeholder Details')}</h5>
                    <div className="close " onClick={this.closeModels} >×</div>
                </div>
                <div className="merge-stakeholder-content new-merge-stakeholder-content">
                    <dl>
                        <dt className="confirm_dt">
                            <div>{desc}</div>
                            <div className="explain"><p>{explain}</p></div>
                        </dt>
                        <dd>
                            <div className="autograph"><Input value={signatureValue} onChange={this.signatureValueChange} addonBefore={__('Sign here')} className="autograph-input" bordered={false}/></div>
                            <div className="signature">{printf(__('Sign as "%s"'), signature)}</div>
                            
                        </dd>
                    </dl>
                </div>
                <div className="information-w100">
                    <Button loading={loading} className="information-button" type="primary" disabled={buttonDisabled} onClick={this.saveStakeholderData} >{__('Merge stakeholder')}</Button>
                </div>
            </Spin>
        );
    }
}
export default CompleteStakeholderMergeModels;