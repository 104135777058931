import React from "react";
import { post } from 'srcPath/http';

class CreateDraftSet extends React.Component{
    constructor(props){
        super(props);
        this.state={
            type:"",
            createStatus:0,
            data:{
                name:"",
            }
        }
    }
    recordData=(e)=>{
        let curData = this.state.data;
        curData.name = e.target.value;
        this.setState({
            data:curData
        })
    }
    createDraftSet=()=>{
        if(this.state.createStatus==1){
            return;
        }
        this.setState({
            createStatus:1
        })
        let requestData = this.state.data;
        post('createDraftSet', {name:requestData.name,type:this.props.type}).then((response)=> {
            if(response.data.code === 0){
                this.props.changeDraftId(response.data.data.id,requestData.name);
                let newSet = {id:response.data.data.id,name:requestData.name,count:"0"};
                this.props.setList.push(newSet);
                this.props.getDraftSecurityList();
                window.$("#createNewDraftSetModal").modal("hide");
                requestData.name = "",
                    this.setState({
                        createStatus:0,
                        data:requestData
                    })
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    render(){
        return(
            <div className="modal fade" id="createNewDraftSetModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="modal-dialog dialog-top40">
                    <div className="modal-content content500">
                        <div className="modal-header box-header relation-header">
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Enter')}</h4>
                        </div>
                        <div className="modal-body captable-body no-paddingbottom">
                            <form className="captable-form">
                                <div className="form-group">
                                    <div className="row">
                                        <label className="col-xs-6 add-label">{__('Enter a new name for this set')}:</label>
                                        <div className="col-xs-6 no-paddingleft">
                                            <input value={this.state.data.name} name="name" onChange={this.recordData} className="form-control" type="text" />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer box-footer padding203020">
                            {this.state.data.name==""&&
                            <a  className="btn boxbtn-drafts send-disabledBtn width80">{__('Ok')}</a>
                            }
                            {this.state.data.name!=""&&
                            <a  onClick={this.createDraftSet} className="btn boxbtn-drafts bg-darkblue width80">{__('Ok')}</a>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default CreateDraftSet;