import React, { Fragment } from 'react';
import VestingScheduleList from "./VestingScheduleList";
import DocumentSets from "./DocumentSets";
import AwardCategory from "./AwardCategory";
import DocusignTemplate from "./DocusignTemplate";
import RadioButtons from 'components/RadioButtons';
import './style.scss';
import TemplateEdit from "./DocusignTemplate/components/TemplateEdit";
import { I } from "Utils";

class Templates extends React.Component{

    state = {
        activeKey: '2',
        isHideBar: false,
    }

    saveIsHideBar = value => {
        this.setState({ isHideBar: value });
    }

    componentDidMount() {
        let { state, pathname } = this.props.location
        state = state ? state : {};
        const activeKey = I((pathname.split('/')).pop(), 2).toString();
        state['activeKey'] = activeKey > 0 && activeKey <= 5 ? activeKey : '2';
        state && this.setState(state)
    }

    switchPage = (e) => {
        const { value } = e.target;
        this.setState({ activeKey: value });
    }

    setBar = () => {
        const { activeKey, isHideBar } = this.state;
        const buttonList = [
            {label: __('Vesting schedules'), value: '2'},
            {label: __('Document sets'), value: '1'},
            {label: __('E-Sign templates'), value: '4'},
            {label: __('Award category'), value: '3'},
        ];
        return !isHideBar && <RadioButtons value={activeKey} options={buttonList} onChange={this.switchPage} />
    }

    getContent = () => {
        const { activeKey } = this.state
        const returnObj = {
            1: <DocumentSets />,
            2: <VestingScheduleList />,
            3: <AwardCategory history={this.props.history} />,
            4: <DocusignTemplate saveIsHideBar={this.saveIsHideBar} updateActiveKey={this.updateActiveKey} />,
            5: <TemplateEdit saveIsHideBar={this.saveIsHideBar} id={0} html="" updateActiveKey={this.updateActiveKey} />
        }
        return returnObj[activeKey];
    }

    updateActiveKey = (value, isHideBar) => {
        this.setState({ activeKey: value, isHideBar });
    }

    render(){
        return (
            <div className="security-template-root">
                <div className="security-template-header">
                    {this.setBar()}
                </div>
                <div className="security-template-content">{this.getContent()}</div>
            </div>
        )
    }
}

export default Templates;