import React, { Component } from "react";
import {Input, Select, Tooltip} from "antd";
import asterisk from "../img/asterisk.png";
import DateSelector from 'components/DateSelector';
import { formatCurrencyNumber, calculator, filterAmount, formatNumber } from "Utils";

class CapitalizationForm extends Component {

    inputChange = (value, k, e) => {
        const { key, max } = value;
        const { updateState, financingData } = this.props;
        const element = e.target;
        const filter = filterAmount(e, max);
        let newFinancingData = [...financingData];
        newFinancingData[k][key] = filter.value;
        updateState('newFinancingData', newFinancingData, element, filter.cursorPositionX, false);
    }

    selectChange = (k, key, value) => {
        const { updateState, financingData } = this.props;
        financingData[k] = {...financingData[k]};
        financingData[k][key] = value;
        updateState({financingData});
    }

    inputBlur = () => {
        const { resetScenarioData } = this.props;
        const { getInvestorSum, financingData, k, updateState } = this.props;
        let newFinancingData = [...financingData];
        const investorRes = getInvestorSum(newFinancingData, k);
        newFinancingData[k].investorError = investorRes !== true;
        newFinancingData[k].investorRes = investorRes;
        updateState({financingData: newFinancingData });
        resetScenarioData();
    }

    dateChange = (value) => {
        const { updateState, financingData, k } = this.props;
        let newFinancingData = [...financingData];
        newFinancingData[k]['closingDate'] = value;
        updateState('financingData', newFinancingData);
    }

    openAdvancedTermsModel = (key) => {
        const { advancedTermsChange } = this.props;
        advancedTermsChange(key);
    }

    optionPoolSelectAfter = () => {
        const { financingData, data, k } = this.props;
        const { preMoneyValuation, postMoneyOptionPool, postMoneyOptionPoolShares, postMoneyOptionPoolType, totalNewInvestment, closingDate, isError } = financingData[k];
        return (
            <Select
                value={postMoneyOptionPoolType}
                className="select-after"
                // getPopupContainer={ triggerNode => triggerNode.parentNode }
                dropdownMatchSelectWidth={false}
                onChange={this.selectChange.bind(this, k, 'postMoneyOptionPoolType')}
            >
                <Select.Option value={1}>%</Select.Option>
                <Select.Option value={2}>Shares</Select.Option>
            </Select>
        )
    }

    render() {
        const { financingData, data, k } = this.props;
        const { amountSymbol, surplusEa, fullDiluted } = data;
        const { preMoneyValuation, postMoneyOptionPool, postMoneyOptionPoolShares, postMoneyOptionPoolType, totalNewInvestment, closingDate, isError, firstOptionPoolShares, firstOptionPoolPercentage, isSecondId } = financingData[k];
        const newSurplusEa = !!isSecondId ? firstOptionPoolShares : surplusEa;
        const newSurplusEaPercentage = !!isSecondId ? firstOptionPoolPercentage : parseInt(calculator.division(newSurplusEa, fullDiluted)*10000)/100;

        return (
            <div className="new-financing-round-capitalization-form">
                <div className="new-financing-round-terms">
                    <span onClick={this.openAdvancedTermsModel.bind(this, k)}>{__('Advanced terms')}</span>
                </div>

                <div className="new-financing-form">
                    <div className="new-financing-form-input">
                        <label>{__('Pre-money valuation')}</label>
                        <Input
                            className={isError && 'new-financing-input-error'}
                            addonBefore={amountSymbol} value={preMoneyValuation}
                            onChange={this.inputChange.bind(this, {key:'preMoneyValuation'}, k)}
                            onBlur={this.inputBlur}
                        />
                    </div>
                    <div className="new-financing-form-input">
                        <label>{__('Available post-money option pool')}</label>
                        <Input
                            className={isError && 'new-financing-input-error'}
                            addonAfter={this.optionPoolSelectAfter()}
                            value={postMoneyOptionPoolType === 1 ? postMoneyOptionPool : postMoneyOptionPoolShares}
                            onChange={this.inputChange.bind(this, postMoneyOptionPoolType === 1 ? {key:'postMoneyOptionPool', max:100} : {key:'postMoneyOptionPoolShares'}, k)}
                            onBlur={this.inputBlur}
                        />
                        <div className="new-financing-form-input-desc">{__('Current available option pool')}: <span>{formatNumber(newSurplusEa, 2)} {`(${newSurplusEaPercentage}%)`}</span></div>
                    </div>
                    <div className="new-financing-form-input">
                        <label>{__('Total new investment')}</label>
                        <Input
                            className={isError && 'new-financing-input-error'}
                            addonBefore={amountSymbol} value={totalNewInvestment}
                            onChange={this.inputChange.bind(this, {key:'totalNewInvestment'}, k)}
                            onBlur={this.inputBlur}
                        />
                        {/*<div className="new-financing-form-input-desc">Cash raised to date: {formatCurrencyNumber(cashRaised, 0)}</div>*/}
                    </div>
                    <div className="new-financing-form-input">
                        <label>{__('Closing date')}
                            <Tooltip placement="top" title={__('Interest accrual for convertible notes stops on this date')}>
                                <div className="new-financing-form-input-help">?</div>
                            </Tooltip>
                        </label>
                        <DateSelector
                            className="new-financing-form-input-selector"
                            inputReadOnly={true}
                            value={closingDate}
                            onChange={this.dateChange}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
export default CapitalizationForm;