
export const quillModules = {
    toolbar: {
        container: [
            // 标题 —— 下拉选择
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            // 字体大小
            [{ size: ["small", false, "large", "huge"] }],
            // 有序、无序列表
            [{ list: "ordered" }, { list: "bullet" }],
            // 链接、图片
            ["link", "image"],
            // 对齐方式
            [{ align: [] }],
            // 缩进
            [{ indent: "-1" }, { indent: "+1" }],
            // 加粗 斜体 下划线 删除线
            ["bold", "italic", "underline", "strike"],
            // 字体颜色、字体背景颜色
            // [{ color: [] }, { background: [] }],
            // 下标/上标
            [{ 'script': 'sub' }, { 'script': 'super' }],
            ["table"],
            // 清除文本格式
            ["clean"],
        ]
    },
    imageResize: {
        // parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
    }
};