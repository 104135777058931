import React, { Component, cloneElement, Fragment, Children } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { observer } from 'mobx-react';
import security from 'stores/security';

export default class Permission extends Component {
	static propTypes = {
		type: PropTypes.string.isRequired, // 权限名称
		hide: PropTypes.bool, // 没有权限的话是否显示被包裹的组件，默认显示 disabled 状态，并 Tooltip
		replace: PropTypes.node, // 没有权限的前提下，如果传入 replace 则渲染replace， 替换成没有权限之后的组件。该方法主要针对 没有使用 ant 组件，后者使用 demo 1 方法造成样式错乱的情况，建议全部替换成 ant 组件。
		disabled: PropTypes.bool
	}
	render() {
		const { type, hide = false, replace, disabled = false } = this.props
		const hasPermission = disabled ? false : !!(security.hasPermission(type))

		return <Fragment>
			{
				Children.map(this.props.children, (child, index) => hasPermission ? child : (replace ? replace : !hide && <Tooltip title="No permission">{cloneElement(child, { disabled: 'disabled', className: child.props.className + ' sprout-permissiono-disabled', onClick: () => {} })}</Tooltip>)) 
			}
		</Fragment>
	}

}

export function noPermission(type) {
	return !(security.hasPermission(type))
}

/*

	1. <Permission type="company" ><Button>ok</Button></Permission> //  使用antd 组件的 Button 将会处于diisabled 状态，并且悬浮提示 'No permission'
	2. <Permission type="company" hide ><Route exact path={`${this.path}/summary`} component={Summary}/></Permission> // 用于隐藏一个组件
	3. <Permission 
			type="board_meeting_detail_edit"
			replace={<button type="button" class="btn disabled-btn">Done</button>} // 如果没有使用ant 组件，可以手动传入一个禁用状态下组件，replace原来的组件。
	   >
			<button type="button" class="btn">Done</button>
	   </Permission>
*/