import React, { useState } from 'react';
import TokenSelector, { getDefaultTokenId } from 'B/components/TokenSelector';
import { TokenReprots } from 'B/components/Reports'


import './style.scss';

export default function Report(props) {
	const [ tokenId, setTokenId ] = useState(getDefaultTokenId());

	const handleTokenChange = value => {
		setTokenId(value);
	}

	return <div className="token-repors-root">
		<div className="token-repors-filter">
			<TokenSelector value={tokenId} onChange={handleTokenChange} />
		</div>
		<TokenReprots id={tokenId} />
	</div>
}