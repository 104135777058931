import React from "react";
import Loading from 'components/Loading';
import { post } from 'srcPath/http';
import Utils, { calculator } from "Utils";
import EditShareClassPlanModal from "./EditShareClassPlanModal";
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import {Empty} from "antd";

class EditShareClasses extends React.Component {
    constructor(props){
        super(props);
        this.state={
            loadingStatus : 0,
            data:{
                seniority_level:"",
                class_type:{text:"Preferred",value:true,},
                class_type_status:{text:"Common",value:"common"},
                share_class:"Common",
                share_class_prefix:"CS",
                certificated_shares:{text:"Yes",value:true},
                document_status:"select_document",
                par_value:"",
                pari_passu:{text:"Yes",value:true,},

            },
            shareClassPlanList:[],
            seniorityList:[],
        }
    }

    getShareClassPlanList=()=>{
        this.setState({
            loadingStatus : 1,
        })
        post('getShareClassPlanList', []).then((response)=> {
            if(response.data.code === 0){
                let curSeniorityList = this.state.seniorityList;
                let defaultSeniorityList = [];
                this.setState({
                    shareClassPlanList:response.data.data
                })
                for(let i=0;i<this.state.shareClassPlanList.length;i++){
                    defaultSeniorityList[i]=this.state.shareClassPlanList[i].seniority_level
                }
                for(let k=0;k<defaultSeniorityList.length;k++){
                    if(curSeniorityList.indexOf(defaultSeniorityList[k])== -1){
                        curSeniorityList.push(defaultSeniorityList[k]);
                    }
                }
                this.setState({
                    seniorityList:curSeniorityList,
                    loadingStatus : 0
                })
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    editShareClasses=(key)=>{
        this.refs.editShareClassPlan.initData(key,this.state.shareClassPlanList[key]);
        // console.log(this.state.shareClassPlanList[key])
        // this.refs.editShareClassPlan.getShareClassInfo();
        // this.refs.editShareClassPlan.changeToEditStatus();
        // this.refs.editShareClassPlan.resetUpdateActive();
    }
    recordData=(key,e)=>{
        //这里应该根据key找到对应的Plan，将id和要修改seniority_level传给服务器即可
        var newSeniorityLevel = e.target.value;
        var shareClassInfo = this.state.shareClassPlanList[key];
        console.log(newSeniorityLevel,shareClassInfo);
        var requestData = {
            id:shareClassInfo.id,
            seniority_level:newSeniorityLevel
        };
        post('editSeniorityLevel', requestData).then((response)=> {
            if(response.data.code === 0){
                let curShareClassList = this.state.shareClassPlanList;
                curShareClassList[key].seniority_level = newSeniorityLevel;
                this.setState({
                    shareClassPlanList:curShareClassList
                })
                // window.location.reload();
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    closeEditShareClass=()=>{
        window.$("#editShareClassModal").modal("hide");
        window.$("#editShareClass").removeClass("active");
        window.$("#shareClass").addClass("active");
    }
    render(){
        const {shareClassPlanList, loadingStatus} = this.state;
        const { minus, division, mul } = calculator;
        return(
            <div className="modal fade" id="editShareClassModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="modal-dialog dialog960 dialog-top40 tab-content">
                    <div className="modal-content content500 tab-pane active" id="shareClass">
                        <div className="modal-header box-header">
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Share classes')}</h4>
                        </div>
                        <div className="modal-body padding203020 reset_margin">
                            <div className="body-one">
                                {loadingStatus == 1 && <Loading/>}
                                {loadingStatus == 0 && shareClassPlanList.length===0 &&
                                <Empty/>
                                }
                                {loadingStatus == 0 && shareClassPlanList.map((value,key)=>{
                                    let authorizedShares = Utils.resetNumberWithCommas(value.total_authorized_shares);
                                    let availableShares = Utils.resetNumberWithCommas(value.issued_shares);
                                    let divisionValue = division(availableShares, authorizedShares)
                                    return(
                                        <div key={key}>
                                            <div className="clearfix">
                                                <div className="onset">
                                                    <p className="">{value.share_class_name} ({value.prefix})</p>
                                                    <select className="text-blue select-container blue-triangle-down width90" name="seniority_level" value={value.seniority_level} onChange={this.recordData.bind(this,key)}>
                                                        {/*{<option value={value.seniority_level} >Seniority {value.seniority_level}</option>}*/}
                                                        {this.state.seniorityList.map((val,k)=>{
                                                            return(
                                                                <option value={val} key={k}>Seniority {val}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                <button type="button" className="btn editShareClassBtn bg-darkblue offset" onClick={this.editShareClasses.bind(this,key)} data-toggle="tab" data-target="#editShareClass" >{__('Edit share class')}</button>
                                            </div>
                                            <div className="progress schedule-progress edit-shareClass-progress no-marginBottom">
                                                <div className="progress-bar" style={{width:divisionValue*100 + "%", backgroundColor:"#00a9cc"}} role="progressbar"  aria-valuenow="80"
                                                     aria-valuemin="0" aria-valuemax="100">
                                                    <span className="sr-only">{divisionValue*100}% Complete</span>
                                                </div>

                                            </div>
                                            <div className="clearfix">
                                                <small className="text-muted onset">{value.total_authorized_shares==0 ? __("No shares authorized") : formatFractionalNumber(value.total_authorized_shares)+" "+__("shares authorized")} </small>
                                                <small className="text-muted offset">{value.total_authorized_shares==0 ? __("None available") :formatFractionalNumber( minus(value.total_authorized_shares, value.issued_shares) )+ __(" available")} </small>
                                            </div>
                                            <hr />
                                        </div>
                                    )
                                })}
                             </div>
                        </div>
                    </div>
                    <div className="tab-pane" id="editShareClass">
                        <EditShareClassPlanModal ref="editShareClassPlan" closeEditShareClass={this.closeEditShareClass} getShareClassList={this.props.getShareClassList}/>
                    </div>

                </div>
            </div>
        )
    }
}
export default EditShareClasses;