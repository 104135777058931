import React, { Component, Fragment } from 'react';
import SideBar from '../components/SideBar';
import Empty from 'components/Empty';
import Detail from './Detail';
import RightsAndPreferences from './RightsAndPreferences';
import VestingSchedule from '../components/VestingSchedule/vestingSchedule';
import Documents from '../components/Documents';
import PersonalDetail from './PersonalDetail';
import RepurchaseDetails from './RepurchaseDetails';
import TransferDetail from './TransferDetails';
import Approvals from '../components/Approvals';
import Legend from '../components/Legend';
import Transaction from "./Transaction";
import { cloneDeep } from 'lodash';

const menuConfig = [
	{
		label: __('Details'),
		key: 'details'
	},
	{
		label: __('Transaction history'),
		key: 'transactionHistory'
	},
	{
		label: __('Repurchase details'),
		key: 'repurchaseDetails'
	},
	{
		label: __('Transfer details'),
		key: 'transferDetails'
	},
	{
		label: __('Vesting schedule'),
		key: 'vestingSchedule'
	},
	{
		label: __('Rights and preferences'),
		key: 'rightsAndPreferences'
	},
	{
		label: __('Personal detail'),
		key: 'personalDetail'
	},
	{
		label: __('Approvals'),
		key: 'approvals'
	},
	{
		label: __('Legend'),
		key: 'legend'
	},
	{
		label: __('Documents and notes'),
		key: 'documents'
	},
]


class PS extends Component {
	state = {
		checkedKey: this.props.checkedKey || 'details'
	}

	handleSideBar = key => {
		this.setState({ checkedKey: key })
	}

	formatConfig() {
		const data = this.props.data;

		const newMenuConfig = cloneDeep(menuConfig)

		const transferIndex = newMenuConfig.findIndex((item) =>{
			return item.key === 'transferDetails';
		})
		if (data.transferDetails && data.transferDetails.length === 0 ) {
			newMenuConfig.splice(transferIndex, 1)
		}

		const repurchaseIndex = newMenuConfig.findIndex((item) =>{
			return item.key === 'repurchaseDetails';
		})
		if (!data.repurchaseDetails ) {
			newMenuConfig.splice(repurchaseIndex, 1)
		}

		return newMenuConfig
	}

	renderContent = () => {
		const { checkedKey } = this.state
		const { data } = this.props
		const { details, rightsAndPreferences, vestingSchedule, personal_details, approvals, legend, documents, companyId, type, digital_certificate, company_name, certificateHistory, transactionHistory, certificateList, formatText, repurchaseDetails, transferDetails } = data
		const objMap = {
			details: <Detail data={{...details, digital_certificate, company_name, certificateHistory, certificateList, formatText }} />,
            transactionHistory: <Transaction data={transactionHistory} />,
            repurchaseDetails: <RepurchaseDetails data={repurchaseDetails} />,
            transferDetails: <TransferDetail data={transferDetails} />,
			vestingSchedule: <VestingSchedule data={vestingSchedule}/>,
			rightsAndPreferences: <RightsAndPreferences data={rightsAndPreferences} />,
			personalDetail: <PersonalDetail data={personal_details}/>,
			approvals: <Approvals data={approvals} />,
			legend: <Legend data={legend} />,
			documents: <Documents data={{companyId, ...documents, type}} />,
		}

		return objMap[checkedKey] || objMap.details
	}

	render() {
		const { checkedKey } = this.state;
		const { data } = this.props;
		const newMenuConfig = this.formatConfig();

		return <div className="certificate-detail-root">
			<SideBar width={190} onChange={this.handleSideBar} value={checkedKey} config={newMenuConfig} type={__('Certificate')} name={data.name} />
				<div className="certificate-detail-content">
					{ this.renderContent() }
			</div>
		</div>
	}
}

export default PS