import React, { useMemo, createRef } from 'react';
import { EditTwoTone } from '@ant-design/icons';
import { openGrantDetail, openTokenWarrant } from 'components/CertificateDetail';
import { openOperationModal } from './Operation';
import RoundTags from '../../../RoundTags';
import './style.scss'


export default function EditGrant({ record, grantList = [], warrantList = [], coinId, callback }) {
	const { claimed_history = [], id: transactionId } = record

	const grantData = useMemo(() => {
		return grantList.reduce((res, current) => {
			if (current.destination_wallet_address === record.to_wallet) {
				const quantity = claimed_history.find(item => item.security_id === current.id)?.quantity

				res.push({
					name: current.grant_name,
					security_id: current.id,
					security_type: current.security_type,
					total: Number(current.claimable_quantity || 0) + Number(quantity || 0),
					quantity,
					transactionId
				})
			}

			return res
		}, [])
	}, [grantList, record, claimed_history, transactionId])

	const warrantData = useMemo(() => {
		return warrantList.map((val) => {
			const quantity = claimed_history.find(item => item.security_id === val.id)?.quantity
			return {
				name: val.custom_label_id,
				security_id: val.id,
				total: Number(val.claimable_quantity || 0) + Number(quantity || 0),
				quantity,
				security_type: val.security_type,
				transactionId
			}
		})
	}, [warrantList, claimed_history, transactionId])


	return <div className="transaction-edit-grant">
		<RoundTags tags={claimed_history.map(val => `${val.security_name} (${val.security_type === 'COIN_WARRANT' ? __('Warrants') : __('Grants')} ${val.quantity})`)}/>
		<EditTwoTone onClick={() => openOperationModal({ grantData, warrantData, coinId, transactionId, callback }).result.then(() => {})} />
	</div>
}