import React from 'react';
import Utils, { formatNumber, I, calculator, filterAmount, formatCurrencyNumber } from "Utils";
import axios from "axios";
import DeleteDoc from "../../components/DeleteDoc";
import { post } from 'srcPath/http';
import moment from "moment";
import {DatePicker, message, Select} from "antd";
import {formatDate} from "../../../../Utils/format/formatDate";
import DateSelector from "../../../../../components/DateSelector";
import security from 'stores/security';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import { cancellationReason } from "Utils/constant";
import 'assets/css/onboarding.css';
import {DeleteOutlined, EditOutlined, FolderOpenOutlined, DatabaseOutlined, HistoryOutlined, DiffOutlined } from "@ant-design/icons";

const options = []
class ModifyCertificate extends React.Component{
    constructor(props){
        super(props);
        this.state={
            convertedError:1,
            dateFormat:"DD/MM/YYYY",
            is_remove:0,
            is_show_delete_modal:0,
            dateOfIncorporation:"01/01/2020",
            document_action_type:"",
            isShowErrorTip:0,
            documentActionArr : {'add_document':1,'replace_document':1,'remove_document':1},
            uploadProgress:0,
            uploadingStatus:0,
            isAvailable:-1,
            initData: {
                    id: "2",
                    init_email: "78420344@qq.com",
                    security_type: "cs",
                    issue_date: "11/02/2021",
                    quantity: "22223",
                    issue_price_per_share: "2",
                    board_approval_date: null,
                    cancellation_date: null,
                    cancellation_reason: null,
                    converted_from: null,
                    option_plan: null,
                    transfer_date:"",
                    repurchase_date:"",
                    documents: null,
                    notes: null,
                    convertible_ids:[],
                    cash_paid: null,
                    debt_canceled: null,
                    value_of_ip_transferred: null,
                    other_consideration: null,
                    custom_label_id: "CS-2",
                    status: "Outstanding",
                    create_timestamp: "2020-03-09 12:09:01",
                    transaction_value: 44446,
                    checkbox_status: "unchecked",
                    sign_status: "2"
                },
            checkboxStatus:{
                // name:
                //     {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"name",fieldName:"Name"},
                // email:
                //     {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"email",fieldName:"Email"},
                custom_label_id:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"custom_label_id",fieldName:__("Prefix label")},
                issue_date:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"issue_date",fieldName:__("Issue Date")},
                quantity:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"quantity",fieldName:__("Quantity")},
                // issue_price_per_share:
                //     {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"issue_price_per_share",fieldName:"Price per share"},
                board_approval_date:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"board_approval_date",fieldName:__("Board approval date")},
                cancellation_date:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"cancellation_date",fieldName:__("Canceled date")},
                cancellation_reason:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"cancellation_reason",fieldName:__("Canceled reason")},
                converted_from:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"converted_from",fieldName:__("Converted from")},
                transfer_date:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"transfer_date",fieldName:__("Transfer date")},
                repurchase_date:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"repurchase_date",fieldName:__("Repurchase date")},
                option_plan:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"option_plan",fieldName:__("Option plan")},
                add_document:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"add_document",fieldName:__("Add more additional documents")},
                replace_document:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"replace_document",fieldName:__("Replace all current additional documents")},
                remove_document:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"remove_document",fieldName:__("Remove all additional documents")},
                notes:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"notes",fieldName:__("Notes")},
                convertible_ids:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"convertible_ids",fieldName:__("Converted from")},
                cash_paid:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"cash_paid",fieldName:__("Cash paid")},
                debt_canceled:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"debt_canceled",fieldName:__("Debt / int. converted")},
                value_of_ip_transferred:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"value_of_ip_transferred",fieldName:__("Value of IP transferred")},
                other_consideration:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"other_consideration",fieldName:__("Other consideration")},
            },
            modifyReason:[
                "Clerical error",
                "Amendment"
            ],
            optionPlan:["---","PLAN","MS Ventures Equity Option Plan"],
            requiredKey:[
                "custom_label_id",
                "issue_date",
                "quantity",
                // "issue_price_per_share",
                "board_approval_date",
                "cancellation_date",
                "cancellation_reason",
                "converted_from",
                "add_document",
                // "remove_document",
                "replace_document",
                "notes",
                "convertible_ids"
            ],
            optionalKey:[
                "repurchase_date",
                "transfer_date",
                "cash_paid",
                "debt_canceled",
                "value_of_ip_transferred",
                "other_consideration",
            ],
            data:{
                modify_id:"Certificate",
                reason:"",
                modify_note:"",
                basicTemplateData:
                    {
                        custom_label_id:"",
                        issue_date:"",
                        quantity:"",
                        currency:"HKD",
                        // issue_price_per_share:"",
                        board_approval_date:"",
                        cancellation_date:"",
                        cancellation_reason:"",
                        converted_from:"",
                        transfer_date:"",
                        repurchase_date:"",
                        add_document:{name:__("No file selected"),file_obj:""},
                        replace_document:{name:__("No file selected"),file_obj:""},
                        remove_document:"",
                        document:__("No file selected"),
                        notes:"",
                        convertible_ids:[],
                        cash_paid:"",
                        debt_canceled:"",
                        value_of_ip_transferred:"",
                        other_consideration:"",
                    },
                changedKey:[],
            },

        }
    }
    recordData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        });
    }
    recordCurData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData.basicTemplateData[dataKey] = dataVal;
        this.setState({
            data:curData
        });

    }

    recordNumData=(e)=>{
        const isFractionalShare = security.companyInfo.fractional_control;
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        if(dataKey=="quantity"){
            if(isFractionalShare==1){
                curData.basicTemplateData[dataKey] =  Utils.check5DecimalNumInputWithoutComma(dataVal);
            }else{
                curData.basicTemplateData[dataKey] =  Utils.checkNumInput(dataVal);
            }
        }else if(dataKey=="price_per_share"){
            curData.basicTemplateData[dataKey] =  Utils.check10DecimalNumInputWithoutComma(dataVal);
        }else{
            curData.basicTemplateData[dataKey] =  Utils.check2DecimalNumInputWithoutComma(dataVal);
        }
        this.setState({
            data:curData
        });
    }
    formatNumData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData.basicTemplateData[dataKey] = formatNumber(dataVal,null);
        this.setState({
            data:curData
        });
    }
    focusNumData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData.basicTemplateData[dataKey] = Utils.resetNumberWithCommas(dataVal);
        this.setState({
            data:curData
        });
    }

    checkedStatus = (e)=>{
        let curChecked = this.state.checkboxStatus;
        let dataKey = e.target.name;
        curChecked[dataKey].checked = !curChecked[dataKey].checked;
        if(curChecked[dataKey].checked){
            curChecked[dataKey].bg="modify-whiteInput";
            curChecked[dataKey].disabled="";
        }else{
            curChecked[dataKey].bg="modify-darkInput"
            curChecked[dataKey].disabled="disabled";
        }
        let documentActionArr = this.state.documentActionArr;
        if(documentActionArr.hasOwnProperty(dataKey)){
           if(curChecked[dataKey].checked){
               this.setState({document_action_type:dataKey});
               for(var key in documentActionArr){
                    if(key !== dataKey){
                        curChecked[key].checked = false;
                        curChecked[key].bg="modify-darkInput"
                        curChecked[key].disabled="disabled";
                    }
                }
           }
        }
        this.setState({
            checkboxStatus:curChecked
        })
    }
    selectFile=(e)=>{
        var uploadFile = e.target.files[0];
        let dataKey = e.target.name;
        let curData = this.state.data;
        Utils.uploadFile(uploadFile).then((result)=>{
            curData.basicTemplateData[dataKey].name = uploadFile.name;
            curData.basicTemplateData[dataKey].file_obj = result;
            this.setState({
                data:curData
            });
        });
    }

    resetAction=()=>{
        window.$("#modifyShareModal .tab-pane").removeClass("active");
        window.$("#modifyReason").addClass("active");
    }

    submitAction=()=>{
        if(this.state.uploadingStatus ===1){
            return;
        }
        this.setState({
            uploadingStatus:1
        });
        if(false && Utils.isLocal()){
            this.props.setModifiedSecurity(this.state.initData.key,this.state.initData);
            window.$("#modifyShareModal").modal("hide");
            window.$("#modifyShareModal .tab-pane").removeClass("active");
            window.$("#modifyReason").addClass("active");
            return;
        }
        var requestData = {
            reason:this.state.data.reason,
            modify_note:this.state.data.modify_note,
            modify_id: this.state.initData.id
        };
        for(let key in this.state.checkboxStatus){
            if(this.state.checkboxStatus[key].checked){
                requestData[key] = this.state.data.basicTemplateData[key];
            }
        }

        if(this.state.document_action_type==="add_document" && this.state.data.basicTemplateData.add_document.name!==__('No file selected')){
            //这里说明选择新增并且上传了文件
            requestData.document_action_type = 'add_document';
        }else if(this.state.document_action_type==="replace_document" && this.state.data.basicTemplateData.replace_document.name !== __("No file uploaded")){
            //这里说明选择了替代并且上传了文件
            requestData.document_action_type = 'replace_document';
        }else if(this.state.document_action_type==="remove_document"){
            // if(this.state.data.basicTemplateData.remove_document !== 'DELETE'){
            //     alert("You should type DELETE while you want to remove all the documents!!");
            //     return;
            // }else{
            //     requestData.document_action_type = 'remove_document';
            // }
            requestData.document_action_type = 'remove_document';
        }else{
            requestData.document_action_type = '';
        }

        axios.post('https://api.getsprout.co', {
            company_id:Utils.getLocalCompanyInfo().company_id,
            action:"modifyShares",
            data:requestData
        },{
            onUploadProgress:(progressEvent)=>{
                let complete = (progressEvent.loaded / progressEvent.total * 100 | 0);
                console.log('上传 ' + complete+'%') ;
                this.setState({
                    uploadProgress:complete
                });
                if(complete == 100){
                    this.setState({
                        isShowUploadProgress:0
                    })
                }
            }
        }).then((response)=> {
            if(response.data.code === 0){
                //这里需要将返回的数据覆盖到主页面
                // for(let key in this.state.checkboxStatus){
                //     if(this.state.checkboxStatus[key].checked){
                //         if(key === "add_document" || key==="replace_document"||key==="remove_document"){
                //             continue;
                //         }
                //         this.state.initData[key] = this.state.data.basicTemplateData[key];
                //     }
                // }
                // let initData = this.state.initData;
                // initData.quantity = Utils.resetNumberWithCommas(initData.quantity);
                // if(response.data.data.hasOwnProperty("custom_label_id")){
                //     initData.custom_label_id = response.data.data.custom_label_id;
                // }
                // this.props.setModifiedSecurity(this.state.initData.key,initData);
                // window.$("#modifyShareModal").modal("hide");
                // this.setState({
                //     uploadProgress:0,
                //     uploadingStatus:0
                // });
                // window.$("#modifyShareModal .tab-pane").removeClass("active");
                // window.$("#modifyReason").addClass("active");
                // let curData = this.state.data;
                // curData.reason="";
                // curData.modify_note="";
                // curData.modify_id="";
                // for(let key in this.state.checkboxStatus){
                //     if(this.state.checkboxStatus[key].checked){
                //         if(key=="replace_document"||key=="add_document"){
                //             curData.basicTemplateData[key].name="No file selected";
                //             curData.basicTemplateData[key].file_obj="";
                //         }else{
                //             curData.basicTemplateData[key]="";
                //         }
                //         this.state.checkboxStatus[key].checked = false;
                //         this.state.checkboxStatus[key].disabled = "disabled";
                //     }
                // }
                // this.setState({
                //     is_remove:0,
                //     data:curData
                // })
                window.location.reload();
            }else{
                message.error(response.data.data)
                // console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            // console.log(error);
            message.error(error && error.message)

        });
    }
    setInitInfo=()=>{
        const { cnList } = this.props;
        if( cnList )
        for (let i = 0; i< cnList.length; i++){
            options[i] = { label: cnList[i].custom_label_id, value: cnList[i].id }
        }
        this.setState({
            initData:this.props.curSelectSecurity
        });
    }
    checkConvertedAvailable = () =>{
        const { data } = this.state;
        const securityIds = data.basicTemplateData.convertible_ids
        if( this.state.checkboxStatus.convertible_ids.checked &&  securityIds != []){
            post('isBelongToOneStakeholder',{security_ids: data.basicTemplateData.convertible_ids }).then((response)=> {
                if(response.data.code === 0){
                    this.setState({
                        convertedError:response.data.data.isBelongToOneStakeholder
                    },()=>{
                        if(this.state.convertedError == 1)
                            this.checkCustomLabelId()
                    })
                }else{
                    console.log("failed, error code is: "+response.data.code);
                }
            }).catch(function (error) {
                console.log(error);
            });
        }else{
            this.checkCustomLabelId()
        }

    }
    checkCustomLabelId = ( )=>{
        const { data } = this.state;
        const { curSelectSecurity } = this.props;
        post('checkCustomLabelId',{custom_label_id: data.basicTemplateData.custom_label_id, security_plan_id: curSelectSecurity.security_plan_id, security_type: curSelectSecurity.security_type}).then((response)=> {
            if(response.data.code === 0){
                this.setState({
                    isAvailable:response.data.data.isAvailable
                },()=>this.checkNextAvailabel())

            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    targetToStep3=()=>{
        this.setState({
            isShowErrorTip:0,
            isAvailable:-1,
            convertedError:1
        })
        window.$("#modifyShareModal .tab-pane").removeClass("active");
        window.$("#reviewModifications").addClass("active");
    }
    checkNextAvailabel=()=>{
        let flag =true; //用于验证勾选的checkbox有没有填值
        let isSelectCheckboxForRequiredKey = false;//用于验证有没有勾选checkbox(requiredKey)
        let isSelectCheckboxForOptionalKey = false;//用于验证有没有勾选checkbox(optionalKey)
        let optionalCheckKey = this.state.optionalKey;
        let curData = this.state.data;
        let checkKey = this.state.requiredKey;
        for(let i=0;i<this.state.requiredKey.length;i++){
            if(this.state.checkboxStatus[checkKey[i]].checked){
                isSelectCheckboxForRequiredKey = true;
                if(checkKey[i]=="add_document"||checkKey[i]=="replace_document"){
                    if(!curData.basicTemplateData[checkKey[i]].file_obj){
                        flag = false;
                    }
                }else if(checkKey[i]=="custom_label_id"){
                    if(! curData.basicTemplateData[checkKey[i]] || this.state.isAvailable == 0 ){
                        flag = false
                    }
                }else{
                    if(!curData.basicTemplateData[checkKey[i]]){
                        flag = false;
                    }
                }
            }
        }
        if(!isSelectCheckboxForRequiredKey){
            for(let k=0;k<this.state.optionalKey.length;k++){
                if(this.state.checkboxStatus[optionalCheckKey[k]].checked){
                    isSelectCheckboxForOptionalKey = true;
                    flag = true;
                    if(!curData.basicTemplateData[optionalCheckKey[k]]){
                        curData.basicTemplateData[optionalCheckKey[k]] = this.state.initData[optionalCheckKey[k]];
                        this.setState({
                                data:curData
                            }
                        )
                    }
                }
            }
        }
        if(!isSelectCheckboxForRequiredKey && !isSelectCheckboxForOptionalKey){
            flag = false;
        }
        if(flag){
            this.targetToStep3();
        }else{
            //如果custom_label_id重复
            if(this.state.isAvailable==0){
                this.setState({
                    isShowErrorTip:0,
                })
            }else if(!isSelectCheckboxForRequiredKey && !isSelectCheckboxForOptionalKey){

            }else{
                //开始展示错误信息：勾选了但是没填
                this.setState({
                    isShowErrorTip:1,
                })
            }

        }
    }
    showDeleteModal=()=>{
        this.setState({
            is_show_delete_modal:1,
        })
    }
    closeDeleteModal=()=>{
        this.setState({
            is_show_delete_modal:0,
        })
    }
    deleteAllDoc=()=>{
        this.setState({
            is_remove:1
        })
    }

    modifyCertificateDateChange = (key, moment_key, date) => {
        const { dateFormat } = this.state;
        let curData = this.state.data;
        curData.basicTemplateData[key] = date.format( dateFormat);
        curData.basicTemplateData[moment_key] = date;
        this.setState({
            data:curData
        });
    }
    recordConvertible = (e) => {
        let curData = this.state.data;
        curData.basicTemplateData.convertible_ids = e ;
        this.setState({
            data: curData,
        })
    }

    render(){
        let modifyKey = this.state.data.modify_id;
        let showSelectCount = 0;
        const { currency = '', curSelectSecurity, cnList, formatCnList} = this.props;
        const { data, dateFormat, checkboxStatus } = this.state;
        const { minus } = calculator;
        const options = [];
        if(cnList)
        for (let i = 0; i< cnList.length; i++){
            options[i] = { label: cnList[i].custom_label_id, value: cnList[i].id }
        }
        return(
            <div className="modal fade" id="modifyShareModal"  data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="modal-dialog dialog960 dialog-top40">
                    <div className="tab-content reset_margin">
                        <div role="tabpanel" className="modal-content content720 tab-pane active" id="modifyReason">
                            <div className="modal-header box-header">
                                <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.resetAction}>×</button>
                                <h4 className="modal-title" id="myModalLabel">{__('Modify share')}</h4>
                            </div>
                            <div className="modal-body padding2030">
                                <div className="shareTitleBox">
                                    <div className="iconBox bg-lightgreen">
                                        <EditOutlined />
                                    </div>
                                    <div className="shareTextBox">
                                        <p className="shareTitle">{__('Select a reason for modifying the selected share')}</p>
                                        <p className="text-muted">{__('Some modifications will trigger an email to the stakeholder. You can review the modifications and email notifications before saving your changes.')}</p>
                                    </div>
                                </div>
                                <div className="padding2026026">
                                    <div className="form-group add-items">
                                        <div className="row  profile-row">
                                            <label className="col-xs-5 add-label">{__('Reason')}<br/><i className="text-muted"><small>- {__('Required')}</small></i></label>
                                            <div className="col-xs-4">
                                                <select name="reason"  value={this.state.data.reason} onChange={this.recordData} className="form-control select-container">
                                                    <option value="">---</option>
                                                    {this.state.modifyReason.map((value,key)=>{
                                                        return(
                                                            <option key={key}>{value}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group add-items">
                                        <div className="row  profile-row">
                                            <label className="col-xs-5 add-label">{__('Note')}</label>
                                            <div className="col-xs-6">
                                                <textarea name="modify_note" onChange={this.recordData} value={this.state.data.modify_note} className="form-control text-area minHeight100" type="text"/>
                                            </div>
                                            {/*<p className="marginTop2 text-muted col-xs-6 col-xs-push-5">Add a note to be sent to the stakeholder(s).</p>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div role="presentation" className="modal-footer box-footer no-paddingtop">
                                {this.state.data.reason=="" && <a className="btn boxbtn-to send-disabledBtn">{__('Next: modify value')}</a>}
                                {this.state.data.reason!==""&& <a onClick={this.setInitInfo} href="#modifyValue" role="tab" data-toggle="tab" className="btn boxbtn-to bg-darkblue">{__('Next: modify value')}</a>}
                            </div>
                        </div>
                        <div role="tabpanel" className="modal-content content900 tab-pane" id="modifyValue">
                            <div className="modal-header box-header">
                                <a href="#modifyReason"  aria-controls="modifyReason" role="tab" data-toggle="tab" className="back-btn"></a>
                                <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.resetAction}>×</button>
                                <h4 className="modal-title" id="myModalLabel">{__('Modify share')} {this.state.initData.custom_label_id}</h4>
                            </div>
                            <div className="modal-body padding2030 level_up">
                                {this.state.isShowErrorTip == 1 &&
                                <div className="alert alert-warning alert-dismissible pink-box">
                                    <p >{__('The required field(s) can not be empty.')}</p>
                                </div>
                                }
                                {this.state.isAvailable == 0 &&
                                <div  className="alert alert-warning alert-dismissible pink-box">
                                    <p>{__('Custom label already exists. Please select another.')}</p>
                                </div>
                                }
                                {this.state.convertedError == 0 &&
                                <div  className="alert alert-warning alert-dismissible pink-box">
                                    <p>{__('Please choose the same stakeholder to convert.')}</p>
                                </div>
                                }
                                <p className="text-muted marginBott20">{__('Select the fields you would like to change and enter the new information.')}</p>
                                <div className="modify-content">
                                    <div className="shareTitleBox">
                                        <div className="iconBox bg-lightgreen">
                                            <DatabaseOutlined />
                                        </div>
                                        <div className="shareTextBox">
                                            <p className="shareTitle">{__('Certificate details')}</p>
                                            <p className="text-muted">{__('Update information included in the certificate such as quantity, price per share and issue date.')}</p>
                                        </div>
                                    </div>
                                    <table className="table modify-table modify-value-table">
                                        <tbody>
                                        <tr className="modify-firstRow">
                                            <td>{__('Field')}</td>
                                            <td>{__('Value')}</td>
                                            <td>{__('New value')}</td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="detailsCheck2" type="checkbox" name="custom_label_id" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.custom_label_id.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="detailsCheck2"></label>
                                                    <span className="modify-label text-muted">{__('Prefix label')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.custom_label_id}</td>
                                            <td><input type="text" className="form-control " name="custom_label_id" value={this.state.data.basicTemplateData.custom_label_id} disabled={this.state.checkboxStatus.custom_label_id.disabled} onChange={this.recordCurData} /></td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <a className="convertible-ques top2">
                                                        <p className="convertible-tiptext modify-tiptext144">{__('Issue date cannot be modified for certificates that are transferred canceled, terminated, converted repurchased or resulting from one of these transactions.')}</p>
                                                    </a>
                                                    <input id="detailsCheck3" type="checkbox" name="issue_date" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.issue_date.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="detailsCheck3"></label>
                                                    <span className="modify-label text-muted">{__('Issue date')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.issue_date=="" ? "" :formatDate(curSelectSecurity.issue_date)}</td>
                                            <td>
                                                <DateSelector
                                                    disabled={checkboxStatus.issue_date.disabled}
                                                    onChange={this.modifyCertificateDateChange.bind(this, 'issue_date', 'issue_date_moment')}
                                                    value={I(data.basicTemplateData['issue_date_moment'], I(data.basicTemplateData['issue_date'], '') ? moment(data.basicTemplateData['issue_date'], dateFormat) : '')}
                                                    className="datepicker_opacity"
                                                />
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <a className="convertible-ques top2">
                                                        <p className="convertible-tiptext modify-tiptext124">{__('Quantity cannot be modified for multiple certificates or for certificates resulting from a transaction such as a transfer, exercise, or repurchase.')}</p>
                                                    </a>
                                                    <input id="detailsCheck4" type="checkbox" name="quantity" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.quantity.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="detailsCheck4"></label>
                                                    <span className="modify-label text-muted">{__('Quantity')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{formatFractionalNumber(curSelectSecurity.quantity)}</td>
                                            <td><input type="text" className="form-control " name="quantity" value={this.state.data.basicTemplateData.quantity} disabled={this.state.checkboxStatus.quantity.disabled} onChange={this.recordNumData} onBlur={this.formatNumData} onFocus={this.focusNumData}/></td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="detailsCheck7" type="checkbox" name="board_approval_date" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.board_approval_date.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="detailsCheck7"></label>
                                                    <span className="modify-label text-muted">{__('Board approval date')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.board_approval_date=="" ? "" :formatDate(curSelectSecurity.board_approval_date)}</td>
                                            <td>
                                                <DateSelector
                                                    disabled={checkboxStatus.board_approval_date.disabled}
                                                    onChange={this.modifyCertificateDateChange.bind(this, 'board_approval_date', 'board_approval_date_moment')}
                                                    value={I(data.basicTemplateData['board_approval_date_moment'], I(data.basicTemplateData['board_approval_date'], '') ? moment(data.basicTemplateData['board_approval_date'], dateFormat) : '')}
                                                    className="datepicker_opacity"
                                                />
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <a className="convertible-ques top2">
                                                        <p className="convertible-tiptext modify-tiptext144">{__('Canceled date cannot be modified for certificates that are transferred canceled, terminated, converted repurchased or resulting from one of these transactions.')}</p>
                                                    </a>
                                                    <input id="detailsCheck8" type="checkbox" name="cancellation_date" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.cancellation_date.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="detailsCheck8"></label>
                                                    <span className="modify-label text-muted">{__('Canceled date')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.cancellation_date=="" ? "" :formatDate(curSelectSecurity.cancellation_date)}</td>
                                            <td>
                                                <DateSelector
                                                    disabled={checkboxStatus.cancellation_date.disabled}
                                                    onChange={this.modifyCertificateDateChange.bind(this, 'cancellation_date', 'cancellation_date_moment')}
                                                    value={I(data.basicTemplateData['canceled_date_moment'], I(data.basicTemplateData['cancellation_date'], '') ? moment(data.basicTemplateData['cancellation_date'], dateFormat) : '')}
                                                    className="datepicker_opacity"
                                                />
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="detailsCheck9" type="checkbox" name="cancellation_reason" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.cancellation_reason.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="detailsCheck9"></label>
                                                    <span className="modify-label text-muted">{__('Cancellation reason')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.cancellation_reason}</td>
                                            <td>
                                                <select type="text" className="form-control select-container modify-select" name="cancellation_reason" value={this.state.data.basicTemplateData.cancellation_reason} disabled={this.state.checkboxStatus.cancellation_reason.disabled} onChange={this.recordCurData} >
                                                    <option value="">---</option>
                                                    {cancellationReason.map((value,key)=>{
                                                        return(
                                                            <option key={key}>{value}</option>
                                                        )
                                                    })}
                                                </select>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {curSelectSecurity.status == "Transferred" &&
                                <div className="modify-content">
                                    <div className="shareTitleBox">
                                        <div className="iconBox bg-lightgreen">
                                            <DatabaseOutlined />
                                        </div>
                                        <div className="shareTextBox">
                                            <p className="shareTitle">{__('Transfer date')}</p>
                                            <p className="text-muted">{__('Update information for transfer date.')}</p>
                                        </div>
                                    </div>
                                    <table className="table modify-table modify-value-table">
                                        <tbody>
                                        <tr className="modify-firstRow">
                                            <td>{__('Field')}</td>
                                            <td>{__('Value')}</td>
                                            <td>{__('New value')}</td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box transfer_date_list">
                                                    <input id="transferCheck1" type="checkbox" name="transfer_date"
                                                           className="re-checkbox modify-checkbox "
                                                           checked={this.state.checkboxStatus.transfer_date.checked}
                                                           onChange={this.checkedStatus}/>
                                                    <label htmlFor="transferCheck1"></label>
                                                    <ul>
                                                        {curSelectSecurity.transferSecurity.map((v, k) => {
                                                            return (
                                                                <li className="modify-label text-muted">Transfer date for <span className="text-blue">{formatFractionalNumber(v.quantity)}</span> shares to <span className="text-blue">{v.name}</span></li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.transfer_date == "" ? "" : formatDate(curSelectSecurity.transfer_date)}</td>
                                            <td>
                                                <DateSelector
                                                    disabled={checkboxStatus.transfer_date.disabled}
                                                    onChange={this.modifyCertificateDateChange.bind(this, 'transfer_date', 'transfer_date_moment')}
                                                    value={I(data.basicTemplateData['transfer_date_moment'], I(data.basicTemplateData['transfer_date'], '') ? moment(data.basicTemplateData['transfer_date'], dateFormat) : '')}
                                                    className="datepicker_opacity"
                                                />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                }
                                {curSelectSecurity.status == "Repurchased" &&
                                <div className="modify-content">
                                    <div className="shareTitleBox">
                                        <div className="iconBox bg-lightgreen">
                                            <DatabaseOutlined />
                                        </div>
                                        <div className="shareTextBox">
                                            <p className="shareTitle">{__('Repurchase details')}</p>
                                            <p className="text-muted">{__('Update information for repurchase date.')}</p>
                                        </div>
                                    </div>
                                    <table className="table modify-table modify-value-table">
                                        <tbody>
                                        <tr className="modify-firstRow">
                                            <td>{__('Field')}</td>
                                            <td>{__('Value')}</td>
                                            <td>{__('New value')}</td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box transfer_date_list">
                                                    <input id="repurchaseCheck1" type="checkbox" name="repurchase_date"
                                                           className="re-checkbox modify-checkbox "
                                                           checked={this.state.checkboxStatus.repurchase_date.checked}
                                                           onChange={this.checkedStatus}/>
                                                    <label htmlFor="repurchaseCheck1"></label>
                                                    <ul>
                                                        {curSelectSecurity.transferSecurity.map((v, k) => {
                                                            return (
                                                                <li key={k} className="modify-label text-muted">Repurchase date for <span className="text-blue">{formatFractionalNumber( minus(curSelectSecurity.quantity, v.quantity))}</span> shares from <span className="text-blue">{curSelectSecurity.custom_label_id}</span></li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.repurchase_date == "" ? "" : formatDate(curSelectSecurity.repurchase_date)}</td>
                                            <td>
                                                <DateSelector
                                                    disabled={checkboxStatus.repurchase_date.disabled}
                                                    onChange={this.modifyCertificateDateChange.bind(this, 'repurchase_date', 'repurchase_date_moment')}
                                                    value={I(data.basicTemplateData['repurchase_date_moment'], I(data.basicTemplateData['repurchase_date'], '') ? moment(data.basicTemplateData['repurchase_date'], dateFormat) : '')}
                                                    className="datepicker_opacity"
                                                />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                }
                                <div className="modify-content">
                                    <div className="shareTitleBox">
                                        <div className="iconBox bg-lightgreen">
                                            <FolderOpenOutlined />
                                        </div>
                                        <div className="shareTextBox">
                                            <p className="shareTitle">{__('Documents and notes')}</p>
                                            <p className="text-muted">{__('Update all attachment types and notes available to holder.')}</p>
                                        </div>
                                    </div>
                                    {this.state.is_show_delete_modal==1 &&
                                    <div className="deleteModalBox">
                                        <DeleteDoc closeDeleteModal={this.closeDeleteModal} deleteAllDoc={this.deleteAllDoc}  modifyKey={this.state.data.modify_id}   />
                                    </div>
                                    }
                                    <table className="table modify-table modify-value-table">
                                        <tbody>
                                        <tr className="modify-firstRow">
                                            <td>{__('Field')}</td>
                                            <td>{__('Value')}</td>
                                            <td>{__('New value')}</td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td>
                                                <div className="check-box">
                                                    <input id="documentsCheck0" type="checkbox" name="add_document" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.add_document.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="documentsCheck0"></label>
                                                    <span className="modify-label text-muted">{__('Add more additional documents')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}></td>
                                            <td className="fileTd ">
                                                <div className="select-btn select-blue lib-choose marginRig10">
                                                    {__('Choose a file')}
                                                    <label htmlFor="add_doc" className="sr-only" >File input</label>
                                                    <input name="add_document"  ref="add_document" disabled={this.state.checkboxStatus.add_document.disabled} type="file"  id="add_doc"  onChange={this.selectFile} />
                                                </div>
                                                <div className="filename-box">
                                                    <span className="close files-del hidden">x</span>
                                                    <p className="text-muted">{this.state.data.basicTemplateData.add_document.name}</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td>
                                                <div className="check-box">
                                                    <input id="documentsCheck1" type="checkbox" name="replace_document" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.replace_document.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="documentsCheck1"></label>
                                                    <span className="modify-label text-muted">{__('Replace all current additional documents')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}></td>
                                            <td className="fileTd ">
                                                <div className="select-btn select-blue lib-choose marginRig10">
                                                    {__('Choose a file')}
                                                    <label htmlFor="replace_doc" className="sr-only" >File input</label>
                                                    <input name="replace_document" ref="replace_document" disabled={this.state.checkboxStatus.replace_document.disabled} type="file"  id="replace_doc"  onChange={this.selectFile} />
                                                </div>
                                                <div className="filename-box">
                                                    <span className="close files-del hidden">x</span>
                                                    <p className="text-muted">{this.state.data.basicTemplateData.replace_document.name}</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td>
                                                <div className="check-box">
                                                    <input id="documentsCheck2" name="remove_document" type="checkbox" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.remove_document.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="documentsCheck2"></label>
                                                    <span className="modify-label text-muted">{__('Remove all additional documents')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}></td>
                                            {this.state.checkboxStatus.remove_document.checked===false &&
                                            <td>
                                                <div type="text" name="remove_document" className={"form-control "} value={this.state.data.basicTemplateData.remove_document} disabled={this.state.checkboxStatus.remove_document.disabled} onChange={this.recordCurData}>
                                                    <button type="button" ><DeleteOutlined style={{cursor:'not-allowed'}}/></button>
                                                </div>
                                            </td>
                                            }
                                            {this.state.checkboxStatus.remove_document.checked===true &&
                                            <td>
                                                <div type="text" name="remove_document" className={"form-control "} value={this.state.data.basicTemplateData.remove_document} disabled={this.state.checkboxStatus.remove_document.disabled} onChange={this.recordCurData}>
                                                    <button onClick={this.showDeleteModal} type="button" ><DeleteOutlined /></button>
                                                </div>
                                            </td>
                                            }
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td>
                                                <div className="check-box">
                                                    <input id="documentsCheck3" type="checkbox" name="notes" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.notes.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="documentsCheck3"></label>
                                                    <span className="modify-label text-muted">{__('Notes')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.notes}</td>
                                            <td>
                                                <textarea type="text" className={"form-control "} name="notes" value={this.state.data.basicTemplateData.notes} disabled={this.state.checkboxStatus.notes.disabled} onChange={this.recordCurData} />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="modify-content">
                                    <div className="shareTitleBox">
                                        <div className="iconBox bg-lightgreen">
                                            <HistoryOutlined />
                                        </div>
                                        <div className="shareTextBox">
                                            <p className="shareTitle">{__('Financing history')}</p>
                                            <p className="text-muted">{__('Update the transaction value based on the type of payment.')}</p>
                                        </div>
                                    </div>
                                    <table className="table modify-table modify-value-table">
                                        <tbody>
                                        <tr className="modify-firstRow">
                                            <td>{__('Field')}</td>
                                            <td>{__('Value')}</td>
                                            <td>{__('New value')}</td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    { curSelectSecurity.convertible_ids && curSelectSecurity.convertible_ids.length > 0 &&
                                                    <a className="convertible-ques top2">
                                                        <p className="convertible-tiptext modify-tiptext86">Converted from can't be edited. Please open a support ticket if you need this changed.</p>
                                                    </a>
                                                    }
                                                    { curSelectSecurity.convertible_ids && curSelectSecurity.convertible_ids.length ==0 &&
                                                    <input id="historyCheck4" type="checkbox" name="convertible_ids" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.convertible_ids.checked} onChange={this.checkedStatus}/>
                                                    }
                                                    { curSelectSecurity.convertible_ids && curSelectSecurity.convertible_ids.length > 0 &&
                                                    <input type="checkbox" name="convertible_ids" className="re-checkbox modify-checkbox disabled-checkbox" disabled={true}/>
                                                    }
                                                    {/*<input id="historyCheck4" type="checkbox" name="convertible_ids" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.convertible_ids.checked} onChange={this.checkedStatus}/>*/}
                                                    <label htmlFor="historyCheck4"></label>
                                                    <span className="modify-label text-muted">{__('Converted from')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>
                                                { curSelectSecurity.convertible_list && curSelectSecurity.convertible_list.map((v,k)=>{
                                                   return v.custom_label_id+" "
                                                })}
                                            </td>
                                            <td>
                                                <Select
                                                    disabled={this.state.checkboxStatus.convertible_ids.disabled}
                                                    value={data.basicTemplateData.convertible_ids || []}
                                                    name='convertible_ids'
                                                    mode='multiple'
                                                    style={{width:'100%'}}
                                                    placeholder='Please select'
                                                    options = { options }
                                                    onChange={this.recordConvertible}
                                                >
                                                </Select>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td className="modify-historyTd">
                                                <div className="check-box">
                                                    <a className="convertible-ques top2">
                                                        <p className="convertible-tiptext modify-tiptext66">{__('The amount of cash paid to the company for this certificate.')}</p>
                                                    </a>
                                                    <input id="historyCheck0" type="checkbox" name="cash_paid" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.cash_paid.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="historyCheck0"></label>
                                                    <span className="modify-label text-muted">{__('Cash paid ')}<i className="text-muted"> - {__('Optional')}</i> </span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{formatCurrencyNumber(curSelectSecurity.cash_paid)}</td>
                                            <td>
                                                <div className="input-group"><span className="input-group-addon">{currency}</span>
                                                    <input type="text" className={"form-control "} name="cash_paid" value={this.state.data.basicTemplateData.cash_paid} disabled={this.state.checkboxStatus.cash_paid.disabled} onChange={this.recordNumData} onBlur={this.formatNumData} onFocus={this.focusNumData} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td className="modify-historyTd">
                                                <div className="check-box">
                                                    <a className="convertible-ques top2">
                                                        <p className="convertible-tiptext modify-tiptext124">{__('For convertible securities(e.g. convertible notes, SAFEs), the total value of principal and interest being converted into shares.')}</p>
                                                    </a>
                                                    <input id="historyCheck1" type="checkbox" name="debt_canceled" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.debt_canceled.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="historyCheck1"></label>
                                                    <span className="modify-label text-muted">{__('Convertibles w/ int. converted ')}<i className="text-muted"> - {__('Optional')}</i> </span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{formatCurrencyNumber(curSelectSecurity.debt_canceled)}</td>
                                            <td>
                                                <div className="input-group"><span className="input-group-addon">{currency}</span>
                                                    <input type="text"  className={"form-control "} name="debt_canceled" value={this.state.data.basicTemplateData.debt_canceled} disabled={this.state.checkboxStatus.debt_canceled.disabled} onChange={this.recordNumData} onBlur={this.formatNumData} onFocus={this.focusNumData} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td className="modify-historyTd">
                                                <div className="check-box">
                                                    <a className="convertible-ques top2">
                                                        <p className="convertible-tiptext modify-tiptext86">{__('Cash value of the technology or intellectual property exchanged for the shares.')}</p>
                                                    </a>
                                                    <input id="historyCheck2" type="checkbox" name="value_of_ip_transferred" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.value_of_ip_transferred.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="historyCheck2"></label>
                                                    <span className="modify-label text-muted">{__('Value of IP transferred ')}<i className="text-muted"> - {__('Optional')}</i> </span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{formatCurrencyNumber(curSelectSecurity.value_of_ip_transferred)}</td>
                                            <td>
                                                <div className="input-group"><span className="input-group-addon">{currency}</span>
                                                    <input type="text"  className={"form-control "} name="value_of_ip_transferred" value={this.state.data.basicTemplateData.value_of_ip_transferred} disabled={this.state.checkboxStatus.value_of_ip_transferred.disabled} onChange={this.recordNumData} onBlur={this.formatNumData} onFocus={this.focusNumData} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td className="modify-historyTd">
                                                <div className="check-box">
                                                    <a className="convertible-ques top2">
                                                        <p className="convertible-tiptext modify-tiptext106">{__('Any other value exchanged for the shares apart from cash, debt canceled, or IP transferred.')}</p>
                                                    </a>
                                                    <input id="historyCheck3" type="checkbox" name="other_consideration" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.other_consideration.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="historyCheck3"></label>
                                                    <span className="modify-label text-muted">{__('Other consideration ')}<i className="text-muted"> - {__('Optional')}</i> </span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{formatCurrencyNumber(curSelectSecurity.other_consideration)}</td>
                                            <td>
                                                <div className="input-group"><span className="input-group-addon">{currency}</span>
                                                    <input type="text" className={"form-control "} name="other_consideration" value={this.state.data.basicTemplateData.other_consideration} disabled={this.state.checkboxStatus.other_consideration.disabled} onChange={this.recordNumData} onBlur={this.formatNumData} onFocus={this.focusNumData} />
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div role="presentation" className="modal-footer box-footer no-paddingtop">
                                <a onClick={this.checkConvertedAvailable}  role="tab" data-toggle="tab" className="btn boxbtn-to bg-darkblue">{__('Next: review modifications')}</a>
                            </div>
                        </div>
                        <div role="tabpanel" className="modal-content content900 tab-pane" id="reviewModifications">
                            <div className="modal-header box-header">
                                <a href="#modifyValue"  aria-controls="modifyReason" role="tab" data-toggle="tab" className="back-btn"></a>
                                <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.resetAction}>×</button>
                                <h4 className="modal-title" id="myModalLabel">{__('Modify share')}</h4>
                            </div>
                            <div className="modal-body padding2030">
                                <div className="modify-content">
                                    <div className="shareTitleBox">
                                        <div className="iconBox bg-lightgreen">
                                            <DiffOutlined />
                                        </div>
                                        <div className="shareTextBox">
                                            <p className="shareTitle">{__('Review and apply modifications')}</p>
                                            <p className="text-muted">{__('Review the table below and save the modifications. For some fields on accepted securities, a notification will be sent out. Changes marked with  Yes on Sends email notification will send an email to the stakeholder.')}<br />
                                                {/*<a className="text-blue">Learn more about modifications ></a>*/}
                                            </p>
                                        </div>
                                    </div>
                                    <table className="modify-table reviewTb">
                                        <tbody>
                                        <tr className="modify-firstRow">
                                            <td className="width80" >{__('Security')}</td>
                                            <td className="width160"  >{__('Stakeholder')}</td>
                                            <td className="width160" >{__('Field')}</td>
                                            <td >{__('Old value')}</td>
                                            <td  >{__('New value')}</td>
                                            <td  >{__('Sends email notification')}</td>
                                        </tr>
                                        {Object.values(this.state.checkboxStatus).map((value,key)=>{
                                            if(value.checked){
                                                showSelectCount++;
                                                if(value.jsonKey === "add_document" || value.jsonKey === "replace_document" ){
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd">{this.state.initData[value.jsonKey]}</td>
                                                            <td className="newValueTd">{this.state.data.basicTemplateData[value.jsonKey].name}</td>
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    );
                                                }else if(value.jsonKey === "repurchase_date" ||value.jsonKey === "transfer_date" ||value.jsonKey === "issue_date" || value.jsonKey === "board_approval_date" || value.jsonKey ==="cancellation_date" ){
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd">{ formatDate(this.state.initData[value.jsonKey])}</td>
                                                            <td className="newValueTd">{ formatDate(data.basicTemplateData[value.jsonKey])}</td>
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    );
                                                }else if(value.jsonKey == "quantity"){
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd">{formatFractionalNumber(this.state.initData[value.jsonKey]) }</td>
                                                            <td className="newValueTd">{formatFractionalNumber(this.state.data.basicTemplateData[value.jsonKey])}</td>
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    );
                                                }else if(value.jsonKey == "cash_paid"||value.jsonKey == "debt_canceled"||value.jsonKey == "value_of_ip_transferred"||value.jsonKey == "other_consideration"){
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd">{formatCurrencyNumber(this.state.initData[value.jsonKey]) }</td>
                                                            <td className="newValueTd">{formatCurrencyNumber(this.state.data.basicTemplateData[value.jsonKey])}</td>
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    );
                                                }else if(value.jsonKey == "convertible_ids"){
                                                    let convertedFrom = " ";
                                                    if(data.basicTemplateData.convertible_ids!=[])
                                                    data.basicTemplateData.convertible_ids.map((v,k)=>{
                                                        const index = cnList.findIndex( item=> item.id == v)
                                                        convertedFrom += cnList[index].custom_label_id+" "
                                                    })
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd">--</td>
                                                            <td className="newValueTd">{ convertedFrom }</td>
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    );
                                                }else{
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd">{this.state.initData[value.jsonKey]}</td>
                                                            <td className="newValueTd">{this.state.data.basicTemplateData[value.jsonKey]}</td>
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    );
                                                }

                                            }

                                        })}
                                        </tbody>
                                    </table>
                                    {this.state.uploadProgress >0 &&
                                    <p className="text-center">{this.state.uploadProgress}%  {__('uploaded')}...</p>
                                    }
                                </div>
                            </div>
                            <div className="modal-footer box-footer no-paddingtop">
                                <a className="btn boxbtn-to bg-darkblue"  onClick={this.submitAction}>{__('Apply modifications')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ModifyCertificate;