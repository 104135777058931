import React, { Component } from "react";
import {Table} from "antd";

class OptionPoolExample extends Component {

    constructor(props) {
        super(props);
    }

    /**
     * 表格 Post-money (Founder Friendly) 配置
     */
    columns = () => {
        return [
            {
                title: __('Stockholder Group'),
                dataIndex: 'stockholderGroup',
                key: 'stockholderGroup',
            },
            {
                title: __('Pre-Investment'),
                children: [
                    {
                        title: __('Shares'),
                        dataIndex: 'preShares',
                        key: 'preShares',
                        align: 'right'
                    },
                    {
                        title: __('% Ownership'),
                        dataIndex: 'preOwnership',
                        key: 'preOwnership',
                        align: 'right'
                    }
                ]
            },
            {
                title: __('Post-Investment'),
                children: [
                    {
                        title: __('Shares'),
                        dataIndex: 'postShares',
                        key: 'postShares',
                        align: 'right'
                    },
                    {
                        title: __('% Ownership'),
                        dataIndex: 'postOwnership',
                        key: 'postOwnership',
                        align: 'right'
                    }
                ]
            }
        ];
    }

    /**
     * header
     * @param value
     * @returns {JSX.Element}
     */
    getTitle = (value) => {
        return <div style={{textAlign: 'center'}}><b>{value}</b></div>;
    }

    getMoneyList = (type) => {
        let list = [];
        switch (type){
            case 'post':
                list = [
                    {
                        stockholderGroup : __('Founders'),
                        preShares : '1,000,000',
                        preOwnership : '100.00%',
                        postShares : '1,000,000',
                        postOwnership : '70.00%'
                    },
                    {
                        stockholderGroup : __('Note/Safe holders'),
                        preShares : '0',
                        preOwnership : '0.00%',
                        postShares : '178,571',
                        postOwnership : '12.50%'
                    },
                    {
                        stockholderGroup : __('Series A Investors'),
                        preShares : '0',
                        preOwnership : '0.00%',
                        postShares : '250,000',
                        postOwnership : '17.50%'
                    },
                    {
                        stockholderGroup : __('Total:'),
                        preShares : '1,000,000',
                        preOwnership : '100.00%',
                        postShares : '1,428,571',
                        postOwnership : '100.00%'
                    }
                ];
                break;
            default:
                list = [
                    {
                        stockholderGroup : __('Founders'),
                        preShares : '1,000,000',
                        preOwnership : '100.00%',
                        postShares : '1,000,000',
                        postOwnership : '65.12%'
                    },
                    {
                        stockholderGroup : __('Note/Safe holders'),
                        preShares : '0',
                        preOwnership : '0.00%',
                        postShares : '223,214',
                        postOwnership : '14.53%'
                    },
                    {
                        stockholderGroup : __('Series A Investors'),
                        preShares : '0',
                        preOwnership : '0.00%',
                        postShares : '312,500',
                        postOwnership : '20.35%'
                    },
                    {
                        stockholderGroup : __('Total:'),
                        preShares : '1,000,000',
                        preOwnership : '100.00%',
                        postShares : '1,535,714',
                        postOwnership : '100.00%'
                    },
                ];
        }
        return list;
    }

    render() {
        const { close } = this.props;

        return (
            <div className="example-content">
                <div className="advance-model-root">
                    <h5 className="advance-model-title">{__('Option pool example')}</h5>
                    <div className="advance-model-close " onClick={close} >×</div>
                </div>
                <div className="example-box">
                    <div>
                        <ul className="example-help-str">
                            <li>{__('Agreed Upon Pre-Money Valuation: $8 million')}</li>
                            <li>{__('Agreed Upon Post-Money Valuation: $10 million')}</li>
                            <li>{__('Amount Being Invested by New Series A Investors: $2 million')}</li>
                            <li>{__('Principal Plus Accrued Interest on Outstanding Promissory Notes/Original Purchase Price of Safes: $1 million')}</li>
                            <li>{__('Discount Rate for Conversion of Notes/Safes: 30%')}</li>
                            <li>{__('Shares Outstanding on a Fully-Diluted Basis, Pre-Investment: 1 million')}</li>
                            <li>{__('Option Pool: 20%')}</li>
                        </ul>
                        <div className="clear"></div>
                    </div>
                    <div>
                        <Table
                            className="advance-model-seniority-table"
                            size="middle"
                            pagination={false}
                            showHeader={true}
                            dataSource={ this.getMoneyList('post') }
                            rowKey="keyId"
                            title={()=>this.getTitle(__('Post-money (Founder Friendly)'))}
                            columns={this.columns()}
                            bordered={true}
                        />
                        <Table
                            className="advance-model-seniority-table"
                            size="middle"
                            pagination={false}
                            showHeader={true}
                            dataSource={ this.getMoneyList('pre') }
                            rowKey="keyId"
                            title={()=>this.getTitle(__('Pre-money (Investor Friendly)'))}
                            columns={this.columns()}
                            bordered={true}
                        />

                    </div>
                </div>
            </div>
        );
    }
}

export default OptionPoolExample;