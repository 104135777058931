import React from 'react';
import LinkTable from 'components/CertificateDetail/components/Documents/LinkTable';
import Utils from 'Utils'
const { getRealUrlForFiles } = Utils;

const getUrl = (obj) => getRealUrlForFiles(obj?.real_name, 'dataroom')

export default function Documents(props) {
	const { 
        purchase_agreement_file,
		form_of_warrant_file = {},
		additional_document = [],
		notes
	} = props.data || {};

	const addDocs = []
	additional_document.map((item) => {
		addDocs.push({
			name: item.name,
			url: getUrl(item)
		})
	})

	const eaOptionConfig = [
		{
			label: __('Form of warrant'),
			value: form_of_warrant_file.name,
			url: getUrl(form_of_warrant_file),
		},
		{
			label: __('Warrant purchase agreement'),
			value: purchase_agreement_file?.name,
			url: getUrl(purchase_agreement_file),
		},
		{
			label: __('Additional document'),
			value: addDocs,
			type: 'additional'
		}
	];

	const notesConfig = [
		{
			label: __('Notes'),
			value: notes,
		},
	];

	return <div className="certificate-detail-section">
		<LinkTable title={__('Documents')} config={eaOptionConfig} rate="40%" />
		<LinkTable title={__('Notes')} config={notesConfig} rate="40%" />
    </div>
}