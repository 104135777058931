import React from 'react';
import 'assets/css/dataroommodal.css';
import { post } from 'srcPath/http';
import { message } from 'antd';
class DeleteModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            data:{
                id:"",
            },
            targetId:0
        }
    }

    deleteDoc=async ()=>{
        const { selectFile = [] } = this.props
        const params = {
            id: selectFile
        }
        const { data: resData } = await post('deleteFile', params)
        const { code, data } = resData || {}
        if (code === 0) {
            message.success('remove success')
        } else {
            message.error(data?.message)
        }
        this.props.onDelete && this.props.onDelete()
    }

    handleCancel = () => {
        this.props.close && this.props.close()
    }

    render(){
        return(
            <div>
                <div>
                    <div>
                        <div>
                            <div className="modal-body body-reset2020">
                                <p className="text-muted">Are you sure you want to delete? Any documents saved under the selected directories will also be deleted.</p>
                            </div>
                            <div className="modal-footer box-footer no-paddingtop">
                                <button onClick={this.deleteDoc} type="button" className="btn dataroom-btnReset" data-dismiss="modal">Yes</button>
                                <button onClick={this.handleCancel} type="button" className="btn dataroom-btnReset" data-dismiss="modal">No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default DeleteModal;

