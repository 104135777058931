import React from 'react';
import {Table, message, Input} from 'antd';
import { foundColumns } from './column-config';
import { post } from 'srcPath/http';
import Filter from 'components/Filter';
import './style.scss';
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { LOADING } from "Utils/constant";

const orderMap = {
    null: 0,
    ascend: 4,
    descend: 3,
    undefined: 0
}

class Transactions extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            transactionList : [],
            loading: false,
            searchHolderLists: [],
            searchValue: '',
            filter:{},
            sort: {}
        }
    }
    componentDidMount(){
        this.getData()
    }
    
    getData = async () => {
        const { searchValue, pageNumber, pageSize, filter, sort } = this.state

        try {
            this.setState({ loading: true });
            const { data: resData } = await post('getTransactionList',{
                filter,
                search: searchValue,
                sort: { ...sort, type: orderMap[sort?.type] || 0 }
            })
            const { code, data } = resData;

            if (code === 0) {
                data.map((item, index) => {
                    return item.key = index;
                })
                this.setState({
                    transactionList: data,
                    searchHolderLists: data,
                    loading: false
                })
            } else {
                message.error(data)
            }
        } catch (err) {
            this.setState({ loading: false });
            message.error(err && err.message)
        }
    }


    /**
     * 搜索列表数据
     * @param e
     */
    searchList = (e) => {
        const { value } = e.target;
        this.setState({
            searchValue: value,
        })
    }

    /**
     * 清空搜索内容
     */
    closeSearch = () => {
        this.setState({
            searchValue: '',
        }, ()=>this.getData());
    }

    /**
     *
     * @param pagination
     * @param filters
     * @param sorter
     */
    handleTableChange = (pagination, filters, sorter) => {
        let sort = {}
        const { order, column } = sorter

        if (column?.sortName) {
            sort = {
                column: column.sortName,
                type: order
            }

            this.sortTable?.(sort)
        }else{
            sort = {}
            this.sortTable?.(sort)
        }
    }

    // 筛选条件逻辑，如果有分页，需要把查询条件保存在state 供分页使用
    onFilterChange = (value = {}) => {
        if (JSON.stringify(value) !== JSON.stringify(this.state.filter)) {
            this.setState({
                filter: value,
                pageNumber: 1
            }, () => this.getData())
        }
    }

    sortTable = sort =>{
        this.setState({ sort }, ()=>this.getData())
    }

    render() {
        const { loading, transactionList, searchValue, sort } = this.state

        return <div className="transaction-root">
            <div className="form-group search-group banner-rightBox">
                <div className="mr-10">
                    <Filter callback={val => this.onFilterChange(val)} type="transaction" />
                    &nbsp;&nbsp;
                </div>
                <div className="stakeholder-search-box">
                    <Input
                        className="stakeholder-search-input"
                        value={searchValue}
                        onChange={this.searchList}
                        onPressEnter={this.getData}
                        prefix={<SearchOutlined className="search-prefix-icon" onClick={this.getData} />}
                        suffix={<CloseOutlined style={{opacity: searchValue ? 1 : 0}} onClick={this.closeSearch} className="search-close-icon" />}
                        placeholder={__('Search')}
                    />
                </div>
            </div>
            <Table
                columns={foundColumns()}
                dataSource={transactionList}
                pagination={false}
                locale={ loading && { emptyText: LOADING }}
                scroll={{ y: innerHeight ? innerHeight - 280 : false }}
                loading={loading}
                onChange={this.handleTableChange}
            />
        </div>
    }

}

export default Transactions;

