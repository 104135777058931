import React, { Component } from "react";
import echarts from 'echarts';
import { debounce } from 'lodash';
import formatShares from 'Utils/format/formatShares';
import { cloneDeep } from 'lodash';
import "./styls.scss";
export default class FinancingEcharts extends Component {

    componentDidMount() {
        const { k, updateMyEcharts, myEcharts, data } = this.props;
        let newMyEcharts = myEcharts;
        newMyEcharts[k] = echarts.init(document.getElementById(`main${k}`));
        updateMyEcharts(newMyEcharts);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const { k, myEcharts, data } = nextProps;
        const isEmpty = data.findIndex(v=>v.value > 0);
        const color = isEmpty !== -1 ? ['#F3D072', '#89D8B9', '#89C3E7', '#65B1E1', '#A6A6FC', '#D193FD'] : ['#bfbdbd', '#bfbdbd', '#bfbdbd', '#bfbdbd', '#bfbdbd', '#bfbdbd']
        myEcharts[k].setOption({
            tooltip: {
                trigger: 'item',
                formatter: (v)=>{
                    const { name, data, percent } = v;
                    return `${name}:<br/>${formatShares(data.value)} (${percent}%)`;
                }
            },
            legend: {
                top: '0',
                left: 'center',
                formatter: function (name) {
                    if (!name) return '';
                    if (name.length > 10) {
                        name =  name.slice(0,10) + '...';
                    }
                    return name
                }
            },
            series: [
                {
                    name: '',
                    type: 'pie',
                    radius: ['52%', '70%'],
                    avoidLabelOverlap: true,
                    data: data,
                    top: '20%',
                    emphasis: {
                        label: {
                            show: true,
                            fontWeight: 'bold'
                        }
                    },
                    itemStyle: {
                        normal: {
                            color: function (params){
                                const index = params.dataIndex % 6;
                                return color?.[index];
                            }
                        }
                    }
                }


            ]
        });
        myEcharts[k].resize();
        window.addEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        const { k, myEcharts } = this.props;
        debounce(myEcharts[k].resize, 500)()
    }

    render() {
        const { k } = this.props;
        return (
            <div className="sprout-chart-root">
                <div className="sprout-chart-wrap" id={`main${k}`}></div>
            </div>
        );
    }
}