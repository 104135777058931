import React from 'react';
import { post } from 'srcPath/http';
import Loading from 'components/Loading';
import { Empty } from 'antd';
import { calculator } from 'Utils';
import EditWarrantBlock from "./EditWarrantBlock";
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';

class WarrantBlockList extends React.Component {
    constructor(props){
        super(props);
        this.state={
            warrantBlockList:[],
            loadingStatus:0,
        }
    }
    getWarrantBlockList=()=>{
        if(this.state.loadingStatus==1){
            return;
        }
        this.setState({
            loadingStatus:1
        })
        post('getCoinWarrantBlockList', []).then((response)=> {
            if(response.data.code === 0){
                this.setState({
                    warrantBlockList:response.data.data.warrantBlockList,
                    loadingStatus:0
                })
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    closeWarrantBlockDetailModal=()=>{
        window.$("#blockDetails").removeClass("active");
        window.$("#blockList").addClass("active");
    }
    editWarrantBlockDetail=(key)=>{
        this.refs.editWarrantBlockModal.initData(key,this.state.warrantBlockList[key]);
    }
    deleteWarrantBlock=(key)=>{
        post('deleteCoinWarrantBlock', {id:this.state.warrantBlockList[key].id}).then((response)=> {
            if(response.data.code === 0){
                this.getWarrantBlockList();
                this.props.getWarrantList();
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    render(){
        const { currency = '' } = this.props;
        const { minus } = calculator;
        return(
            <div className="modal fade" id="warrantBlockListModal" tabIndex="-1" role="dialog" aria-labelledby="warrantBlockListModal" aria-hidden="true" >
                <div className="modal-dialog dialog-top40 tab-content">
                    <div className="modal-content content500 tab-pane active" id="blockList">
                        <div className="modal-header box-header">
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Warrant Blocks')}</h4>
                        </div>
                        <div className="modal-body padding203020 ">
                            <div className="body-one">
                                {this.state.loadingStatus==1 &&
                                <div className="termsLoadingPosition">
                                    <Loading/>
                                </div>
                                }
                                {this.state.loadingStatus==0 && this.state.warrantBlockList.length===0 && <Empty/>}
                                {this.state.loadingStatus==0 && this.state.warrantBlockList.length>0 && this.state.warrantBlockList.map((value,key)=>{
                                    let authorizedNum = parseFloat(value.authorized);
                                    let availableNum = parseFloat(value.available);
                                    let issuedNum = minus(authorizedNum, availableNum);
                                    return(
                                        <div key={key}>
                                            <div className="clearfix terms_list_box">
                                                <div className="padding610 onset">
                                                    <p className=" mb-0">{value.block_name} ({value.prefix})</p>
                                                    {value.is_able_delete==1 &&
                                                    <a onClick={this.deleteWarrantBlock.bind(this,key)} className="">Delete</a>
                                                    }
                                                </div>
                                                <button type="button" data-toggle="tab" data-target="#blockDetails" onClick={this.editWarrantBlockDetail.bind(this,key)} className="btn editShareClassBtn warrant_edit_btn terms_edit_btn bg-darkblue offset"  >{value.is_able_delete== 1 ? "Edit" : "View"} {__('Warrant Block')}</button>
                                            </div>
                                            <div className="progress schedule-progress edit-shareClass-progress no-marginBottom">
                                                <div className="progress-bar" style={{width:issuedNum / parseFloat(value.authorized)*100 + "%", backgroundColor:"#00a9cc"}} role="progressbar"  aria-valuenow="80"
                                                     aria-valuemin="0" aria-valuemax="100">
                                                    <span className="sr-only">{issuedNum / parseFloat(value.authorized)*100}% {__('Complete')}</span>
                                                </div>

                                            </div>
                                            <hr />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane" id="blockDetails">
                        <EditWarrantBlock getWarrantBlockList={this.getWarrantBlockList} currency={this.props.currency} ref="editWarrantBlockModal" closeWarrantBlockDetailModal={this.closeWarrantBlockDetailModal} />
                    </div>
                </div>
            </div>
        )
    }
}
export default WarrantBlockList;