import React, { Component, useState, useEffect } from 'react';
import echarts from 'echarts';
import { formatDate } from 'Utils/format/formatDate'
import { message, Spin, Select, Switch, Space } from 'antd';
import './style.scss';
import { post } from 'srcPath/http';
import { cloneDeep } from 'lodash';
import { formatPercent, formatNumber } from 'Utils';
import emptyImg from 'assets/images/bar.png';

const { Option } = Select;
const colorMap = ['#f3d072', '#d193ff', '#65b1e1', '#5590bc', '#73deb3', '#e2e2e2']

export default function SummaryCharts (props) {
    const [ loading, setLoading ] = useState(false)
    const [source, setSource] = useState('topStakeholder')
    const [showUnDiluted, setShowUnDiluted] = useState(false)
    
    const [byRelationshipDiluted, setByRelationshipDiluted] = useState([])
    const [byRelationshipUnDiluted, setByRelationshipUnDiluted] = useState([])

    const [byShareClassDiluted, setByShareClassDiluted] = useState([])
    const [byShareClassUnDiluted, setByShareClassUnDiluted] = useState([])

    const [topStakeholderDiluted, setTopStakeholderDiluted] = useState([])
    const [topStakeholderUnDiluted, setTopStakeholderUnDiluted] = useState([])

    const [sourceData, setSourceData] = useState([])

    useEffect(() => {
        payOverView()
   }, [])

   useEffect(() => {
    let sourceData = []
    // showUnDiluted 显示unDiluted

    if (loading) return 
    if (source === 'topStakeholder') {
        if (showUnDiluted) {
            sourceData = topStakeholderUnDiluted
        } else {
            sourceData = topStakeholderDiluted
        }
    } else if (source === 'byRelationship') {
        if (showUnDiluted) {
            sourceData = byRelationshipUnDiluted
        } else {
            sourceData = byRelationshipDiluted
        }
    } else {
        if (showUnDiluted) {
            sourceData = byShareClassUnDiluted
        } else {
            sourceData = byShareClassDiluted
        }
    }
    setSourceData(sourceData)
    }, [showUnDiluted, source, loading])

    useEffect(() => {
        initCharts()
    }, [sourceData])


      const formatOption = () => {
        const chartOptions = {
            tooltip: {
                trigger: 'item',
                label: {
                  show: true,
                  position: 'center',
                  formatter: (value) => {
                    return `${value?.data?.name}(${formatPercent(value?.data?.quantity)})`
                  }
                },
              },
            legend: {
                orient: 'vertical',
                right: 10,
                top: 100,
                bottom: 20,
                icon: 'circle',
                formatter: function (name) {
                    const item = sourceData.find(i => i.name === name)
                    let displayName = name
                    if (displayName.length > 14) {
                        displayName = `${displayName.substring(0, 10)}...`
                    }
                    return `${displayName}(${formatPercent(item?.value)})`
                },
                tooltip: {
                    show: true,
                    formatter: function (record) {
                        const item = sourceData.find(i => i.name === record?.name)
                        return `${record?.name}(${formatPercent(item?.value)})`
                    }
                }
            },
            color: colorMap,
            series: [
              {
                name: '',
                type: 'pie',
                radius: ['30%', '45%'],
                avoidLabelOverlap: true,
                data: sourceData,
                center: ['40%', '50%'],
                label: {
                  formatter: (value) => {
                    let displayName = value?.data?.name
                    if (displayName.length > 10) {
                        displayName = `${displayName.substring(0, 9)}...`
                    }
                    return `${displayName}(${formatPercent(value?.data?.value)})`
                  }
                },
              }
            ]
          }
        return chartOptions
      }

    

   const initCharts  = () => {
    const chart = sourceData?.length > 0 ? echarts.init(document.getElementById('summary-chart')) : null
    if (chart) {
        const chartOption = formatOption()
        chart?.setOption(chartOption)
        window.addEventListener("resize", function () {
            chart.resize();
        });
    }
   }

    const payOverView = async () => {
        setLoading(true)
        try {
            const { data: resData } = await post('getCapTableSummary')
            const { code, data } = resData || {};
            let { by_relationship = [], by_share_class = [], top_stakeholder = [] } = data|| {};
            
            if (code === 0) {
                const byRelationshipDiluted = []
                const byRelationshipUnDiluted = []
                if (by_relationship?.length > 6) {
                    const newArray = cloneDeep(by_relationship)
                    const splitArray = by_relationship.splice(6)
                    let leftDilutedValue = 0
                    let leftUnDilutedValue = 0

                    splitArray.map((i) => {
                        leftDilutedValue += i.fully_diluted
                        leftUnDilutedValue += i.undiluted
                    })
                    by_relationship = newArray.splice(0, 6).concat([{name: 'Other', fully_diluted: leftDilutedValue, undiluted: leftUnDilutedValue }])
                }
                by_relationship.map((i) => {
                    byRelationshipDiluted.push({
                        name: i.ship || i.name,
                        value: i.fully_diluted
                    })
                    byRelationshipUnDiluted.push({
                        name: i.ship || i.name,
                        value: i.undiluted
                    })
                })
                setByRelationshipDiluted(byRelationshipDiluted)
                setByRelationshipUnDiluted(byRelationshipUnDiluted)

                const byShareClassDiluted = []
                const byShareClassUnDiluted = []
                if (by_share_class?.length > 6) {
                    const newArray = cloneDeep(by_share_class)
                    const splitArray = by_share_class.splice(6)
                    let leftDilutedValue = 0
                    let leftUnDilutedValue = 0
                    splitArray.map((i) => {
                        leftDilutedValue += i.fully_diluted
                        leftUnDilutedValue += i.undiluted
                    })
                    by_share_class = newArray.splice(0, 6).concat([{name: 'Other', fully_diluted: leftDilutedValue, undiluted: leftUnDilutedValue }])
                }
                by_share_class.map((i) => {
                    byShareClassDiluted.push({
                        name: i.plan_name || i.name,
                        value: i.fully_diluted
                    })
                    byShareClassUnDiluted.push({
                        name: i.plan_name || i.name,
                        value: i.undiluted
                    })
                })
                setByShareClassDiluted(byShareClassDiluted)
                setByShareClassUnDiluted(byShareClassUnDiluted)
                const topStakeholderDiluted = []
                const topStakeholderUnDiluted = []

                if (top_stakeholder?.length > 6) {
                    const newArray = cloneDeep(top_stakeholder)
                    const splitArray = top_stakeholder.splice(6)
                    let leftDilutedValue = 0
                    let leftUnDilutedValue = 0
                    splitArray.map((i) => {
                        leftDilutedValue += i.fully_diluted
                        leftUnDilutedValue += i.undiluted
                    })
                    top_stakeholder = newArray.splice(0, 6).concat([{name: 'Other', fully_diluted: leftDilutedValue, undiluted: leftUnDilutedValue }])
                }
                top_stakeholder.map((i) => {
                    topStakeholderDiluted.push({
                        name: i.nick_name || i.name,
                        value: i.fully_diluted
                    })
                    topStakeholderUnDiluted.push({
                        name: i.nick_name || i.name,
                        value: i.undiluted
                    })
                })
                setTopStakeholderDiluted(topStakeholderDiluted)
                setTopStakeholderUnDiluted(topStakeholderUnDiluted)
                
            } else {
                message.error(data)
            }
            setLoading(false)
        } catch (err) {
            setLoading(false)
            message.error(err && err.message)
        }
    }

    const handleChange = (value) => {
        setSource(value)
    }

    const onSwitchChange = (checked) => {
        setShowUnDiluted(checked)
    }

    return (
        <div className='summary-container'>
            <Spin tip="Loading..." spinning={loading}>
                <div className="summary-chart-select">
                    <Select defaultValue="topStakeholder" style={{ width: 160, zIndex: 999 }} onChange={handleChange}>
                        <Option value="topStakeholder">Top stakeholders</Option>
                        <Option value="byRelationship">By relationship</Option>
                        <Option value="byShareClass">By share class</Option>
                    </Select>
                </div>
                <div className="summary-chart-root">
                    <div className="summary-chart-box">
                        
                        {sourceData?.length > 0 ? <div id="summary-chart"></div> : <img className="empty-icon" src={emptyImg} />}
                    </div>
                    <div className="summary-chart-switch">
                        <Space>{__('Fully Diluted')} <Switch defaultChecked onChange={onSwitchChange} /> {__('Outstanding')}</Space>
                    </div>
                </div>
            </Spin>
        </div >
    )
}