import React from 'react';
import { formatNumber, I, tableSort } from "Utils";
import moment from 'moment';
import { DownOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import openCertificateModal from 'components/CertificateDetail';
import TransactionRender from 'B/components/TransactionHistory';
import { relationshipList,certificateStatus } from "Utils/constant";
import {formatDate} from "Utils/format/formatDate";
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';

export function foundColumns() {
    const renderValue = data => data || '--';
    let relationshipFilter = [];
    relationshipList.forEach( (v, k) => {
        relationshipFilter.push({text: v, value: v});
    })
    return [
        {
            title: __('Security'),
            dataIndex: 'security',
            sorter: true,
            sortName: 'security',
            className: 'firstAttr',
            align: 'left',
            width: '13%',
        },
        {
            title: __('Stakeholder'),
            dataIndex: 'name',
            sorter: true,
            sortName: 'stakeholder',
            render: renderValue,
            align: 'left',
            width: '13%'
        },
        {
            title: __('Transaction type'),
            dataIndex: 'transaction_type',
            render:(text, record) => <div>{certificateStatus[text]}</div>,
            // render: (text, record) => (<TransactionRender transferSecurity={record.transferSecurity} value={record.transaction} />),
            align: 'left',
            width: '16%',
            // filters: [
            //     {text: 'Canceled', value : 'Canceled'},
            //     {text: 'Repurchased', value : 'Repurchased'},
            //     {text: 'Expired', value : 'Expired'},
            //     {text: 'Settled', value : 'Settled'},
            //     {text: 'Partially Settled', value : 'Partially Settled'},
            //     {text: 'Initiated', value : 'Initiated'},
            //     {text: 'Fully exercised', value : 'Fully exercised'},
            //     {text: 'Partially exercised', value : 'Partially exercised'},
            //     {text: 'Forfeited', value : 'Forfeited'},
            //     {text: 'Terminated', value : 'Terminated'},
            //     {text: 'Reverse Cancellation', value : 'Reverse Cancellation'},
            // ],
            // onFilter: (value, record) => record.transaction.indexOf(value) === 0,
        },
        {
            title: __('Quantity'),
            dataIndex: 'quantity',
            render: text =>  formatFractionalNumber(text),
            align: 'right',
        },
        {
            title: (
                <div>
                    <span>{__('Applied date')}</span>
                    <Tooltip title={__('the actual date that operation was done')}>
                        <QuestionCircleOutlined  className="title-position" />
                    </Tooltip>
                </div>
            ),
            dataIndex: 'apply_date',
            render: (text, value) => {
                return <span>{ formatDate(value.apply_date) }</span>
            },
            sorter: true,
            sortName: 'applied_date',
            showSorterTooltip:false,
            align: 'right',
            width: '14%'
        },
        {
            title: (
                <div>
                    <span>{__('Transaction date')}</span>
                    <Tooltip title={__('the recorded date of the operation')}>
                        <QuestionCircleOutlined  className="title-position" />
                    </Tooltip>
                </div>
            ),
            dataIndex: 'transaction_date',
            render: (text, value) => {
                return <span>{ formatDate(value.transaction_date) }</span>
            },
            sorter: true,
            sortName: 'transaction_date',
            showSorterTooltip:false,
            align: 'right',
            width: '14%'
        },
         {
            title: __('Operator'),
            dataIndex: 'operator',
            render: renderValue,
            align: 'right',
            width: '13%'
        },

    ];
}
