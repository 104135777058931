import React, { useEffect } from 'react';
import Empty from 'components/Empty';
import { useHistory } from 'react-router-dom';

export default function EmptyTask(props) {
    const { pathData } = props 
    const history = useHistory()

    useEffect(() => {
        if (pathData?.length > 0) {
            history.push({
                pathname: pathData[0]?.path,
                replace: true
            })
        }
    }, [pathData?.length]) 

    return <Empty description={__('You currently have no tasks.')} />
}