import React, { Component } from 'react';
import echarts from 'echarts';
import { formatCurrencyNumber } from 'Utils';

import './chart.scss';
class Fairmarketvalue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datalist: props.datalist,
      marketlist: props.marketlist
    }
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      datalist: nextProps.datalist,
      marketlist: nextProps.marketlist
    }, () => {
      this.initCharts();
    }
    );
  }
  //初始化
  initCharts = () => {
    const { datalist, marketlist } = this.state
    let myChart = echarts.init(document.getElementById('myChart'));
    let option = {
      title: {
        text: __('Fair market value'),
        left: '30px',
        top: '20px',
        textStyle: {
          fontWeight: 'bold',
          fontSize: 18,
          color: "#000",
        }
      },
      tooltip: {
        formatter: (params) => {
          return params.name + '<br/>' + params.seriesName + ":" + formatCurrencyNumber(params.value)
        }
      },
      color: ['#47a7c9'],
      grid: {
        top: '130px',
        left: '40px',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        axisTick: {
          show: false
        },
        axisLabel: {

        },
        data: datalist
      },
      yAxis: {
        type: 'value',
        axisTick:{
          show:false
        },
        axisLine: {
          lineStyle: {
            color: '#524e4ed9'
          }
        },
        axisLabel: {
          formatter: (value) => {
            return formatCurrencyNumber(value)
          }
        }

        
      },
      series: [
        {
          name: 'Email',
          type: 'line',
          z: -1,
          itemStyle: {},
          areaStyle: {
            color: '#f3fbfc'
          },
          tooltip: {
            show: false
          },
          data: marketlist
        },
        {
          name: 'share',
          type: 'scatter',
          itemStyle: {},
          areaStyle: {
            color: '#f3fbfc'
          },
          data: marketlist
        },

      ]
    };

    myChart.setOption(option);
    window.addEventListener("resize", function () {
      myChart.resize();
    });
  }

  render() {
    const { eff_date, share } = this.props
    return (
      <div className="chart">
        <div id="myChart">

        </div>
        <div className='chart-content'>
          <div className='chart-data'>
            Common FMV as of {eff_date}
          </div>
        </div>
        <div className='chart-share'>
          {formatCurrencyNumber(share)} /share
        </div>
      </div>
    )
  }
}
export default Fairmarketvalue;



