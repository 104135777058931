import React, { Component, Fragment } from "react";
import ConfirmPlan from "./components/ConfirmPlan/index";
import UpdatePlan from "./components/UpdatePlan/index";
import CallBack from "./components/CallBack/index";
import url from "url";
import { I } from "Utils";

class Plan extends Component {

    state = {
        page: 0,
        confirmData: {},
    };

    componentDidMount() {
        const { query } = url.parse( window.location.search, true);
        const page = parseInt(I(query.page, 0));
        this.setState({page});
    }

    getPage = (page, confirmData) => this.setState({page, confirmData});

    config = () => {
        const { page, confirmData, notify } = this.state;
        const { history } = this.props;
        switch (page){
            case 0:
                return <UpdatePlan getPage={this.getPage} />
            case 1:
                return <ConfirmPlan getPage={this.getPage} confirmData={confirmData} />
            case 2:
                return <CallBack getPage={this.getPage} history={history} />
            default:
        }
    }
    render() {
        return this.config();
    }
}
export default Plan;