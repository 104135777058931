import React from 'react';
import { formatPercent, formatCurrencyNumber } from 'Utils';
import { formatDate } from 'Utils/format/formatDate'; 
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import formatExercisePrice from 'Utils/format/formatExercisePrice';
import formatShares from 'Utils/format/formatShares'
import optionImg from 'C/Portfolio/images/option.png';
import tokenImg from 'C/Portfolio/images/token.png';
import { getHoldingsStatus  } from 'C/Portfolio/config';
import openCertificateModal, { openTokenWarrant, openGrantDetail } from 'components/CertificateDetail';

const grant = (record, type) => {
	const openModal = type === 'grant' ? openGrantDetail : type === 'warrant' ? openTokenWarrant : openCertificateModal
    return (
        <div className="option-item"  onClick={() => openModal(record.certificateId)}>
            <div className="certificate-img">
                <img width="42" height="32" src={!!type ? tokenImg : optionImg} />
                <div className="certificate-status" style={getHoldingsStatus(record.status).style}>
                    {getHoldingsStatus(record.status).label}
                </div>
            </div>
            <div className="certificate-name">{record.certificateName}</div>
        </div>
    );
}

export const column = [
	{
		title: __('Option grant'),
		dataIndex: '',
		render: (text, record) => grant(record),
		ellipsis: true
	},
	{
		title: __('Grant date'),
		dataIndex: 'grantDate',
		className: 'nowrap',
		render: formatDate
	},
	{
		title: __('Remaining quantity'),
		dataIndex: 'grant',
		ellipsis: true,
		render: text => formatShares(text),
		align: 'right'
	},		
	{
		title: __('Total market value'),
		dataIndex: 'totalMktValue',
		ellipsis: true,
		render: text => formatExercisePrice(text),
		align: 'right'
	},		
	{
		title: __('Vested'),
		dataIndex: 'vestedShares',
		ellipsis: true,
		render: text =>  formatFractionalNumber(text),
		align: 'right'
	},		
	{
		title: __('Unvested'),
		dataIndex: 'unvested',
		render: text =>  formatFractionalNumber(text),
		ellipsis: true,
		align: 'right'
	},
	{
		title: __('Progress'),
		dataIndex: 'progress',
		ellipsis: true,
		render: text => formatPercent(text),
		align: 'right'
	},
	{
		title: __('Vested value'),
		dataIndex: 'vestedValue',
		render: text => formatCurrencyNumber(text),
		ellipsis: true,
		align: 'right'
	},
	{
		title: __('Exercised'),
		dataIndex: 'exercised',
		ellipsis: true,
		render: text => formatShares(text),
		align: 'right'
	},		
	{
		title: __('Exercisable'),
		dataIndex: 'exercisable',
		ellipsis: true,
		render: text => formatShares(text),
		align: 'right'
	},
	{
		title: __('Unvested market value'),
		dataIndex: 'unvestedMktValue',
		ellipsis: true,
		render: text => formatCurrencyNumber(text),
		align: 'right'
	}
]
export const warrantColumn = [
	{
		title: __('Warrant grant'),
		dataIndex: '',
		ellipsis: true,
        render: (text, record) => grant(record),
	},
	{
		title: __('Grant date'),
		dataIndex: 'grantDate',
		className: 'nowrap',
		render: formatDate
	},
	{
		title: __('Grant'),
		dataIndex: 'grant',
		ellipsis: true,
		render: text => formatShares(text),
		align: 'right'
	},		
	{
		title: __('Total market value'),
		dataIndex: 'totalMktValue',
		ellipsis: true,
		render: text => formatExercisePrice(text),
		align: 'right'
	},		
	{
		title: __('Vested'),
		dataIndex: 'vestedShares',
		ellipsis: true,
		render: text =>  formatFractionalNumber(text),
		align: 'right'
	},		
	{
		title: __('Unvested'),
		dataIndex: 'unvested',
		render: text =>  formatFractionalNumber(text),
		ellipsis: true,
		align: 'right'
	},
	{
		title: __('Progress'),
		dataIndex: 'progress',
		ellipsis: true,
		render: text => formatPercent(text),
		align: 'right'
	},
	{
		title: __('Vested value'),
		dataIndex: 'vestedValue',
		render: text => formatCurrencyNumber(text),
		ellipsis: true,
		align: 'right'
	},
	{
		title: __('Exercised'),
		dataIndex: 'exercised',
		ellipsis: true,
		render: text => formatShares(text),
		align: 'right'
	},		
	{
		title: __('Exercisable'),
		dataIndex: 'exercisable',
		ellipsis: true,
		render: text => formatShares(text),
		align: 'right'
	},
	{
		title: __('Unvested market value'),
		dataIndex: 'unvestedMktValue',
		ellipsis: true,
		render: text => formatCurrencyNumber(text),
		align: 'right'
	}
]

export const tokenWarrantColumn = [
	{
		title: __('Warrant grant'),
		dataIndex: '',
		ellipsis: true,
        render: (text, record) => grant(record, 'warrant'),
	},
	{
		title: __('Grant date'),
		dataIndex: 'grantDate',
		className: 'nowrap',
		render: formatDate
	},
	{
		title: __('Issued quantity'),
		dataIndex: 'issuedQuantity',
		ellipsis: true,
		render: text => formatFractionalNumber(text),
		align: 'right'
	},		
	{
		title: __('Total market value'),
		dataIndex: 'totalMarketValue',
		ellipsis: true,
		render: text => formatExercisePrice(text),
		align: 'right'
	},		
	{
		title: __('Vested'),
		dataIndex: 'vested',
		ellipsis: true,
		render: text =>  formatFractionalNumber(text),
		align: 'right'
	},		
	{
		title: __('Unvested'),
		dataIndex: 'unvested',
		render: text =>  formatFractionalNumber(text),
		ellipsis: true,
		align: 'right'
	},
	{
		title: __('Progress'),
		dataIndex: 'progress',
		ellipsis: true,
		render: (text, record) => formatPercent(+record?.vested / (+record?.issuedQuantity)),
		align: 'right'
	},
	{
		title: __('Vested value'),
		dataIndex: 'vestedValue',
		render: text => formatCurrencyNumber(text),
		ellipsis: true,
		align: 'right'
	},
	{
		title: __('Exercised'),
		dataIndex: 'exercised',
		ellipsis: true,
		render: text => formatShares(text),
		align: 'right'
	},		
	{
		title: __('Exercisable'),
		dataIndex: 'exercisable',
		ellipsis: true,
		render: text => formatShares(text),
		align: 'right'
	},
	{
		title: __('Unvested market value'),
		dataIndex: 'unvestedMarketValue',
		ellipsis: true,
		render: text => formatCurrencyNumber(text),
		align: 'right'
	}
]

export const tokenVestingColumn = [
	{
		title: __('Vesting ID'),
		dataIndex: 'certificateName',
		ellipsis: true,
        render: (text, record) => grant(record, 'grant'),
	},
	{
		title: __('Grant date'),
		dataIndex: 'grantDate',
		className: 'nowrap',
		render: formatDate
	},
	{
		title: __('Issued quantity'),
		dataIndex: 'remainingQuantity',
		render: text => formatShares(text),
		className: 'nowrap',
		align: 'right'
	},
	{
		title: __('Total market value'),
		dataIndex: 'totalMarketValue',
		ellipsis: true,
		render: text => formatExercisePrice(text),
		align: 'right'
	},		
	{
		title: __('Vested'),
		dataIndex: 'vested',
		ellipsis: true,
		render: text =>  formatFractionalNumber(text),
		align: 'right'
	},		
	{
		title: __('Unvested'),
		dataIndex: 'unvested',
		render: text =>  formatFractionalNumber(text),
		ellipsis: true,
		align: 'right'
	},
	{
		title: __('Progress'),
		dataIndex: 'progress',
		ellipsis: true,
		render: (text, record) => formatPercent(+record?.vested / (+record?.remainingQuantity)),
		align: 'right'
	},
	{
		title: __('Vested value'),
		dataIndex: 'vestedValue',
		render: text => formatCurrencyNumber(text),
		ellipsis: true,
		align: 'right'
	},
	{
		title: __('Unvested market value'),
		dataIndex: 'unvestedMarketValue',
		ellipsis: true,
		render: text => formatCurrencyNumber(text),
		align: 'right'
	},
	{
		title: __('Claimed'),
		dataIndex: 'claimed',
		ellipsis: true,
		render: text => formatShares(text),
		align: 'right'
	},		
]

export const fmvOptionColumn = [
		{
			title: __('Option grant'),
			dataIndex: '',
			render: (text, record) => grant(record),
			ellipsis: true
		},
		{
			title: __('Grant date'),
			dataIndex: 'grantDate',
			className: 'nowrap',
			render: formatDate
		},
		{
			title: __('Grant'),
			dataIndex: 'grant',
			ellipsis: true,
			render: text => formatShares(text),
			align: 'right'
		},		
		{
			title: __('Total market value'),
			dataIndex: 'fmvTotalMktValue',
			ellipsis: true,
			render: text => formatExercisePrice(text),
			align: 'right'
		},		
		{
			title: __('Vested'),
			dataIndex: 'vestedShares',
			ellipsis: true,
		render: text =>  formatFractionalNumber(text),

			align: 'right'
		},		
		{
			title: __('Unvested'),
			dataIndex: 'unvested',
		render: text =>  formatFractionalNumber(text),
			ellipsis: true,
			align: 'right'
		},
		{
			title: __('Progress'),
			dataIndex: 'progress',
			ellipsis: true,
			render: text => formatPercent(text),
			align: 'right'
		},
		{
			title: __('Vested value'),
			dataIndex: 'fmvVestedValue',
			render: text => formatCurrencyNumber(text),
			ellipsis: true,
			align: 'right'
		},
		{
			title: __('Exercised'),
			dataIndex: 'exercised',
			ellipsis: true,
			render: text => formatShares(text),
			align: 'right'
		},		
		{
			title: __('Exercisable'),
			dataIndex: 'exercisable',
			ellipsis: true,
			render: text => formatShares(text),
			align: 'right'
		},
		{
			title: __('Unvested market value'),
			dataIndex: 'unvestedMktValue',
			ellipsis: true,
			render: text => formatCurrencyNumber(text),
			align: 'right'
		}
]

