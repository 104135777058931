import React, { Fragment } from 'react';
import SimpleTable from '../components/SimpleTable';
import { formatDate } from 'Utils/format/formatDate';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import { formatCurrencyNumber } from 'Utils';
import { certificateStatus } from 'Utils/constant';

export default function Certificate({ data }) {
    const { full_name, email, quantity, status, exercised_quantity, remaining_quantity, purchase_price, exercise_price, exercise_info, wallet_address_list = [] } = data || {}

	const tokenHolderConfig = [
		{
			label: __('Name'),
			value:  full_name
		},
		{
			label: __('Email'),
			value: email
		},
		{
			label: __('Status'),
			value: certificateStatus[status]
		},
		{
			label: __('Wallet address'),
			value: wallet_address_list?.map((val, index) => <p key={index}>{val?.wallet_address}</p>)
		}
	]

	const grantDetailConfig = [
		{
			label: __('Original quantity'),
			value: formatFractionalNumber(quantity)
		},
		{
			label: __('Exercised quantity'),
			value: formatFractionalNumber(exercised_quantity),
		},
		{
			label: __('Remaining quantity'),
			value: formatFractionalNumber(remaining_quantity)
		},
        {
            label: __('Purchase price'),
            value: formatCurrencyNumber(purchase_price)
        },
        {
            label: __('Exercise price'),
            value: formatCurrencyNumber(exercise_price)
		},
		{
			label: __('Exercise into'),
            value: exercise_info
		}
	]

	return <Fragment>
 		<div className="certificate-detail-section">
			<SimpleTable title={__('Warrant holder')} config={tokenHolderConfig} />
		</div>

 		<div className="certificate-detail-section">
			<SimpleTable title={__('Quantities')} config={grantDetailConfig} />
		</div>
	</Fragment>
}