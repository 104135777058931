import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import List from './components/boardList'
import EditPage from './components/editPage'
import BoardSetting from './components/boardSetting'
import ConsentPublish from './components/consentPublish'
import ConsentPreview from './components/consentPreview'
import TemplateEditPage from './components/editTemplate'
import Templates from './components/template'
import EmailPreview from './components/emailPreview'
import SelectTemplate from './components/selectTemplate'


class BoardConsents extends Component {
	path = this.props.match.path

	render () {
		return <div className="board-consents-root" >
			<Switch>
				<Route exact path={`${this.path}/list`} component={List} />
				<Route exact path={`${this.path}/edit`} component={EditPage} />
				<Route exact path={`${this.path}/board/setting`} component={BoardSetting} />
				<Route exact path={`${this.path}/board/publish`} component={ConsentPublish} />
				<Route exact path={`${this.path}/preview`} component={ConsentPreview} />
				<Route exact path={`${this.path}/templates/edit`} component={TemplateEditPage} />
				<Route exact path={`${this.path}/template/select`} component={SelectTemplate} />
				<Route exact path={`${this.path}/templates`} component={Templates} />
				<Route exact path={`${this.path}/viewemail`} component={EmailPreview} />
				
				<Redirect to={`${this.path}/list`} />
			</Switch>
		</div>
	}
}

export default BoardConsents;