import React, { useState, useEffect, useRef } from 'react';
import { Button, Row, Col, Input, Select, message, Space } from 'antd';
import { DateRangeSelector } from 'components/DateSelector';
import { FormItem, withForm } from 'react-antd-formutil';
import TokenFilteredData from 'B/components/TokenFilteredData';
import TokenTransactionTable from 'components/TokenTransaction';
import SelectStakeholderWalletLabel from 'B/Token/components/SelectStakeholderWalletLabel';
import { formatDate2Api } from 'Utils/format/formatDate';
import security from 'stores/security';
import Loading from 'components/Loading';
import { post } from 'srcPath/http';
import { omit } from 'lodash';
import classnames from 'classnames';
import TokenSelector, { getDefaultTokenId } from 'B/components/TokenSelector';
import arrowIcon from './images/arrow.png';

import './style.scss';

function Crypto(props) {
	const { $formutil } = props
	const { $params, $setValues } = $formutil.$new()
	const [fold, setFold] = useState(true);
	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [tagsOption, setTagsOptions] = useState([]);
	const [total, setTotal] = useState(0);
	const [list, setList] = useState([]);
	const [addressInfo, setAddressInfo] = useState();
	const fromStakeholder = useRef()
	const toStakeholder = useRef()
	const [ coinId, setCoinId ] = useState(getDefaultTokenId())

	const toggleFold = () => {
		setFold(!fold)
	}

	useEffect(() => {
		async function getTags() {
			try {
				const { data: resData } = await post('getCoinTransactionTags')
				const { code, data } = resData || {}
				const { tags = [] } = data || {}

				if (code === 0) {
					setTagsOptions(tags)
				}
			} catch(e) {
				message.error(e.message)
			}

			Loading.global(false)
		}

		getTags()
	}, [])

	const getData = async(reset) => {
		Loading.global(true)

		try {
			const { date } = $params || {}
			const [fromDate, toDate] = date || []
			const params = {
				pageNumber,
				pageSize,
				fromDate,
				toDate,
				fromStakeholder: fromStakeholder.current?.id,
				fromStakeholderType: fromStakeholder.current?.type,
				toStakeholder: toStakeholder.current?.id,
				toStakeholderType: toStakeholder.current?.type,
				...omit($params, ['date', 'fromstake', 'tostake']),
				coin_id: coinId
			}

			const { data: resData } = await post('getCoinTransactions',  reset ? { pageNumber: 1, pageSize: 10, coin_id: coinId } : params)

			const { code, data } = resData || {}

			if (code === 0) {
				const { total = 0, list = [] } = data || {}

				setTotal(total);
				setList(list)
			}

		} catch(e) {
			message.error(e.message)
		}

		Loading.global(false)
	}

	const getAddressData = async (walletAddress) => {
		Loading.global(true)

		$formutil.$reset( () => {
			fromStakeholder.current = undefined;
			toStakeholder.current = undefined;
		})

		$setValues({ walletAddress })

		await getData({ walletAddress })

		try {

			const { data: resData } = await post('getCoinTransactions', {
				pageNumber: 1,
				pageSize: 100000,
				walletAddress
			})

			const { code, data } = resData || {}

			if (code === 0 && data.addressInfo) {
				setAddressInfo({
					walletAddress,
					...data.addressInfo
				})
			}

		} catch(e) {
			message.error(e.message)
		}
		Loading.global(false)
	}

	useEffect(() => {
		getData()
	}, [pageSize, pageNumber, toStakeholder, coinId])

	const onApply = () => {
		getData()
	}

	const onPageSizeChange = (current, size) => {
		setPageSize(size)
		setPageNumber(1)
	}

	const onPageChange = (current, pageSize) => {
		setPageNumber(current)
	}

	const reset = async (resetParams) => {
		await $formutil.$reset(() => {
			fromStakeholder.current = undefined;
			toStakeholder.current = undefined;
			getData(resetParams)
		})
	}

	const onExport = async () => { 
		Loading.global(true)

		try {
			const { date } = $params || {}
			const [fromDate, toDate] = date || []
			const params = {
				pageNumber,
				pageSize,
				fromDate,
				toDate,
				fromStakeholder: fromStakeholder.current?.id,
				fromStakeholderType: fromStakeholder.current?.type,
				toStakeholder: toStakeholder.current?.id,
				toStakeholderType: toStakeholder.current?.type,
				...omit($params, ['date', 'fromstake', 'tostake']),
				coin_id: coinId
			}

			const { data: resData } = await post('exportTransaction', params)

			const { code, data } = resData || {}

			if (code === 0) {
				message.success('Success!')
				security.getFileData()
			}
		} catch(e) {
			message.error(e.message)
		}

		Loading.global(false)
	}

	return <div className="transaction-crypto">
		<div className="transaction-crypto-banner sprout-flex">
			<div className="sprout-flex">
				<Button type="primary" onClick={toggleFold} className="transaction-crypto-banner-filter-btn">
					<span>{__('Filters')}</span>
					<img className="arrow" src={arrowIcon} />
				</Button>
				<Button onClick={() => onExport()}  disabled={list.length === 0} type="primary" className="transaction-crypto-banner-filter-btn">
					<span>{__('Export report')}</span>
				</Button>
				<TokenSelector value={coinId} onChange={ val => setCoinId(val)} />
			</div>
		</div>
		<div className={classnames('transaction-crypto-content', { 'transaction-crypto-content-active': !fold })}>
			<div className="transaction-crypto-search">
				<Row gutter={32}>
					<Col span={8}>
						<FormItem
							name="date"
							itemProps={{
								label: __('Date'),
								labelAlign: 'left'
							}}
							$parser={(date) => ( date && [formatDate2Api(date[0]), formatDate2Api(date[1])])}
						>
							<DateRangeSelector />
						</FormItem>
					</Col>
					<Col span={8}>
						<FormItem
							name="fromstake"
							itemProps={{
								label: __('From Stakeholder'),
								labelAlign: 'left'
							}}
						>
							<SelectStakeholderWalletLabel placeholder="Select" onSelect={(value) => { fromStakeholder.current = value }}/>
						</FormItem>
					</Col>
					<Col span={8}>
						<FormItem
							name="tostake"
							itemProps={{
								label: __('To Stakeholder'),
								labelAlign: 'left'
							}}
						>
							<SelectStakeholderWalletLabel placeholder="Select" onSelect={(value) => { toStakeholder.current = value }}/>
						</FormItem>
					</Col>
				</Row>
				<Row gutter={32}>
					<Col span={8}>
						<FormItem
							name="walletAddress"
							itemProps={{
								label: __('Wallet Address'),
								labelAlign: 'left'
							}}
							$onFieldChange={val => {
								if(!val) {
									setAddressInfo()
								}
							}}

						>						
							<Input allowClear placeholder="Input" />
						</FormItem>
					</Col>
					<Col span={8}>
						<FormItem
							name = "fromWalletAddress"
							itemProps={{
								label: __('From Wallet Address'),
								labelAlign: 'left'
							}}
						>
							<Input allowClear placeholder="Input" />
						</FormItem>
					</Col>	
					<Col span={8}>
						<FormItem
							name="toWalletAddress"
							itemProps={{
								label: __('To Wallet Address'),
								labelAlign: 'left'
							}}
						>
							<Input allowClear placeholder="Input" />
						</FormItem>
					</Col>					

				</Row>
				<Row gutter={32}>
					<Col span={16}>
						<FormItem
							name="tag"
							itemProps={{
								label: __('Category Tag'),
								labelAlign: 'left'
							}}
						>						
							<Select mode="tags" allowClear placeholder="Select">
								{
									tagsOption.map(val => (<Select.Option key={val} value={val}>{val}</Select.Option>))
								}
							</Select>
						</FormItem>
					</Col>
					<Col span={8}>
						<div className="transaction-crypto-btn">
							<Button onClick={reset}>{__('Reset')}</Button>
							&nbsp;&nbsp;
							<Button type="primary" onClick={onApply}>{__('APPLY')}</Button>
						</div>
					</Col>					 
				</Row>
			</div>
		</div>
		{addressInfo && <div className="transaction-crypto-fitered"><TokenFilteredData data={addressInfo} /></div>}
		<TokenTransactionTable 
			list={list}
			callback={getData}
			onClickWallet={getAddressData}
			coinId={coinId}
			paginationConfig={{
				total,
				current: pageNumber,
				pageSize: pageSize,
				onShowSizeChange: onPageSizeChange,
				onChange: onPageChange,
				showSizeChanger: true,
				showTotal: (sum) => <span>{sum} {__('items')}</span>
			}}
		/>
	</div>
}

export default withForm(Crypto)
