import React, { Component } from "react";
import { Button, Space } from "antd";

class ConfirmModel extends Component {

    deleteFinancingRound = () => {
        const { id, deleteFinancingRound, close, getFinancingDetailModelList } = this.props;
        deleteFinancingRound(id, close).then(r => {
            getFinancingDetailModelList();
        });
    }

    render() {
        const { close } = this.props;
        return (
            <div className="new-financing-round-delete">
                <p><b>Are you sure you want to delete New model? </b><br/>All scenarios in this model will be deleted. You cannot undo this action.</p>
                <Space size={16}>
                    <Button onClick={close}>Cancel</Button>
                    <Button type="primary" onClick={this.deleteFinancingRound}>Delete</Button>
                </Space>
            </div>
        );
    }
}
export default ConfirmModel;