import React, { Component } from 'react';
import { Form, FormItem, withForm } from 'react-antd-formutil';
import { Input, Button, message } from 'antd';
import { post } from 'srcPath/http';
import { bindKeyEvent } from 'Utils';
import PwdRisk from 'components/PwdRisk';
import security from 'stores/security';

import './style.scss';

class PasswordModal extends Component {
	state = {
		loading: false
	}

	componentDidMount() {
		this.removeKeyEvent = bindKeyEvent(this.onSubmit)
	}

	componentWillUnmount() {
		this.removeKeyEvent && this.removeKeyEvent()
	}

	onSubmit = async () => {
		const { $formutil, close, callback } = this.props
		const {  $invalid, $batchDirty, $params, $getFirstError } = $formutil

		if ($invalid) {
			$batchDirty(true)
			message.error($getFirstError())

			return
		}

		this.setState({ loading: true })

		const { currentPassword, newPassword } = $params

		try {
			const { data } = await post('changePassword', {
				uid: security.userInfo.id,
				currentPassword,
				newPassword
			})

			if (data.code === 200) {
				this.setState({ loading: false })
				callback && callback()
				close();
			} else {
				message.error(data.data);
			}

		} catch (e) {
			message.error(e.message)
		}

		this.setState({ loading: false })
	}

	parser = (viewValue, $setViewValue) => $setViewValue(viewValue.trim())

    // 防止浏览器自动填充其他记录值
    removeReadOnly = e => {
        e.target.removeAttribute('readonly');
    }

    checkPassword = value => {
        if (!value || value.length < 8 || PwdRisk.judgeRisk(value) <= 2.5  ) {
        	return '8 character at least, 1 number, 1 upper case, 1 lower case, 1 special character'
        }

        return true;
    };

	render() {
		const { $formutil } = this.props
		const { $params = {} } = $formutil
		const { newPassword } = $params

		return <div className="password-modal">
			<div className="password-form">
				<FormItem 
					name="currentPassword"
					required
					parser={this.parser}
					itemProps={{
						label: __('Current Password')
					}}
					onFocus={this.removeReadOnly}
					colon={false}
					validMessage={{
						required: __('Current Password is required')
					}}
				>
					<Input placeholder={__('e.g.Soriyt888_')} type="password" autoComplete="off" readOnly />
				</FormItem>
				<FormItem 
					name="newPassword" 
					required
					parser={this.parser}
					itemProps={{
						label: __('New Password')
					}}
					onFocus={this.removeReadOnly}
					checker={this.checkPassword}
					colon={false}
					validMessage={{
						required: __('Password is required'),
						pattern: __('Password is invalid')
					}}
				>
					<Input placeholder={__('e.g.Soriyt888_')} type="password" autoComplete="off" readOnly  />
				</FormItem>
				<div className="pwd-risk-content">{ $params.newPassword && <PwdRisk password={$params.newPassword}/>}</div>
				<FormItem 
					name="newPasswordAgain" 
					required
					parser={this.parser}
					itemProps={{
						label: __('New Password Again')
					}}
					colon={false}
					onFocus={this.removeReadOnly}
					checker={val => val === newPassword ? true : __('The password you entered did not match') }
					validMessage={{
						required: __('Password is required'),
					}}
				>
					<Input placeholder={__('e.g.Soriyt888_')} type="password" autoComplete="off" readOnly  />
				</FormItem>
			</div>
			<Button type="primary" loading={this.state.loading} onClick={this.onSubmit}>{__('Change Password')}</Button>
		</div>
	}
}

export default withForm(PasswordModal)