import React from "react";
import { post } from 'srcPath/http';

class DeleteDraftSecurity extends React.Component{
    constructor(props){
        super(props);
        this.state={
            deleteStatus:0,
            checkedIds:[],
        }
    }
    componentDidMount(){

    }
    getCheckedId=()=>{
        // let checkedIds = this.state.checkedIds;
        // for (let i=0;i<this.props.curData.length;i++){
        //     if(this.props.curData[i].checkbox_status==true){
        //         checkedIds.push(this.props.curData[i].id)
        //     }
        // }
        // this.setState({
        //     checkedIds:checkedIds
        // })
        this.setState({
            checkedIds:this.props.checkedIds
        })
    }
    getDeleteDraftId=()=>{
        let checkedIds = this.state.checkedIds;
        checkedIds[0] = this.props.curData[this.props.listKey].id
        this.setState({
            checkedIds: checkedIds,
        })
    }
    delDraftSecurity=()=>{
        if(this.state.deleteStatus==1){
            return;
        }
        this.setState({
            deleteStatus:1
        })
        post('deleteDraftSecurity', {ids:this.state.checkedIds}).then((response)=> {
            if(response.data.code === 0){
                window.$("#deleteDraftSecurityModal").modal("hide");
                this.props.getDraftSecurityList();
                this.setState({
                    deleteStatus:0,
                })
                // window.location.reload();
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    render(){
        const { deleteStatus } = this.state;
        return(
            <div className="modal fade" id="deleteDraftSecurityModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="modal-dialog dialog-top40">
                    <div className="modal-content content460">
                        <div className="modal-header box-header relation-header">
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Delete')}</h4>
                        </div>
                        <div className="modal-body padding3232 ">
                            <p className="text-muted text-center no-marginBottom">{__('Are you sure you want to delete?')}</p>
                        </div>
                        <div className="modal-footer box-footer no-paddingtop">
                            <button type="button" onClick={this.delDraftSecurity} className="btn dataroom-btnReset">{deleteStatus == 1 ? __('Loading') : __('Yes')}</button>
                            <button type="button" className="btn dataroom-btnReset" data-dismiss="modal">{__('No')}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default DeleteDraftSecurity;