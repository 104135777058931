import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import security from 'stores/security';
import FinancingRound from "./FinancingRoundNew";
import Waterfall from "./Waterfall";
import "./style.scss";

class ScenarioModeling extends Component {

    config = [
        {
            path: `${this.props.match.path}/financing-round`,
            permission: 'fundraising_scenarioModeling',
            component: FinancingRound,
            exact: true,
        },
        {
            path: `${this.props.match.path}/waterfall`,
            permission: 'fundraising_scenarioModeling',
            component: Waterfall,
            exact: true,
        },
    ]

    render() {
        return (
            <Switch>
                { this.config.map((val, index) => security.hasPermission(val.permission) && <Route key={index} exact={val.exact} path={val.path} component={val.component} />)}
                <Redirect to={this.config[0].path} />
            </Switch>
        )
    }
}
export default ScenarioModeling;
