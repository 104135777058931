import React from 'react';
import Section from '../Section';

export default function Legend (props) {
	const { data = [] } = props

	return <Section title={__('Legend')}>
		<div className="certificate-legend">
			{
				data.map((val, index) => <p key={index}>{val}</p>)
			}
		</div>
	</Section>
} 