import React from 'react';
import axios from 'axios';
import EditEquityPlan from "B/Securities/EquityAwards/EquityAwardsModal/EditEquityPlan";
import Utils from "Utils";
import { post } from 'srcPath/http';
import {relationshipList} from "Utils/constant";
import {formatNumber} from "Utils";
import Loading from 'components/Loading';
import Empty from 'components/Empty';
import moment from "moment";
import { I, formatDateForDatePicker } from 'Utils';
import { formatDate } from "Utils/format/formatDate";
import DateSelector from "components/DateSelector";
import { DateCustomer } from 'components/DateSelector'
import TerminateTokenGrantTable from "../components/TerminateStakeholder/components/TerminateTokenGrantTable";
import TerminateStakeholderTable from "../components/TerminateStakeholder/components/TerminateStakeholderTable";
import formatFractionalNumber from "../../../../Utils/format/formatFractionalNumber";
import { printf } from 'Utils/i18n';
import { ExclamationCircleOutlined } from '@ant-design/icons';

class TerminateStakeholder extends React.Component{

    state = {
        data:{
            termination_date:"",
            termination_type:"",
            new_relationship:"",
            new_email:"",
            stakeholder_termination_date:"",
            confirm_date_list:[
                // {id:"1",prefix:"ES- 2",sign_status:0,issue_date:"12/01/2020",granted:"200",vested:"200",unvested:"-",exercised_quantity:"100",iso_disqualification_date:"",last_day_to_exercise:"",},
            ],
            terminate_token_grant_list:[
                {
                    id:1,
                    security_type: "TA",
                    grant_name: "TA-8",
                    issue_date:"24/11/2022",
                    issued_quantity:1000,
                    vested: 100,
                    unvested:900,
                    claimed:200,
                    unclaimed:200,
                    last_day_to_exercise:"11/11/2022"
                }
            ],
            isEmailMe:0,

        },
        terminationList:[],
        tokenTerminationList:[],
        dateFormat : 'DD/MM/YYYY',
        loadingStatus:1,
        terminateStatus:0,
        isCorrect:0,
        new_email:"",
        isNewEmail:0,
        isShowErrorTip:0,
        requiredKey:["termination_date","termination_type","new_relationship","stakeholder_termination_date"],
        terminationType:[
            "Voluntary","Involuntary","With cause","Disability","Retirement","Death"
        ],
        showErrorArr : ["dontshow","dontshow","dontshow","dontshow"],
        summaryData:[
            // {summary_info:"Unvested shares returned to pool",amount:"500",date_returning:""},
        ],
        affectedEaPlans:[
            // {plan_name :"MS Ventures Equity Option Plan",pre_termination_quantity  :"1,999,500",post_termination_quantity:"2,000,000",},
        ],
        affectedTokenGrantPlanList:[],
        selectCount:0,
        selectTokenCount:0,
        totalSummaryInfo:[],
        totalTokenSummaryInfo:[],
        emailErrorFlag:0,
    }

    recordEmail=(e)=>{
        let dataVal = e.target.value;
        if(dataVal==0){
            this.setState({
                isNewEmail:0,
            })
        }else{
            this.setState({
                isNewEmail:1,
            },()=>this.getAditionalEmail())
        }
    }

    getAditionalEmail = () =>{
        let curData = this.state.data;
        post('getStakeHolderAdditionalEmail', { user_id: this.props.curSelectStakeholder.stakeholder_id }).then((response) => {
            if (response.data.code === 0) {
                curData.new_email = response.data.data || ""
                this.setState({
                    data: curData
                })

            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    recordData=(e)=>{
        let dataKey = e.target.name;
        let curData = this.state.data;
        let dataVal = e.target.value;
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        })
    }
    confirmOrNot=()=>{
        let curData = this.state.isCorrect;
        if(curData===0){
            curData=1
        }else{
            curData=0
        }
        this.setState({
            isCorrect:curData
        })
    }
    IsOrNot=(e)=>{
        let curData = this.state.data;
        let dataKey = e.target.name;
        if(curData[dataKey]===0){
            curData[dataKey]=1
        }else{
            curData[dataKey]=0
        }
        this.setState({
            data:curData
        })
    }

    getPreTerminateInfo=()=>{
        this.setState({
            loadingStatus:1
        });
        let curData = this.state.data;
        let stakeholderId = this.props.curSelectStakeholder.stakeholder_id;
        if(!stakeholderId){
            stakeholderId = this.props.curSelectStakeholder.id;
        }
        let terminate_date = curData.termination_date;
        post('getPreTerminateInfo',{stakeholder_id:stakeholderId, terminate_date: terminate_date}).then((response)=> {
            if(response.data.code === 0){
                curData.confirm_date_list = response.data.data.affectedShareList;
                curData.terminate_token_grant_list = response.data.data.affectedTokenGrantList
                this.setState({
                    data:curData,
                    affectedEaPlans:response.data.data.affectedEaPlans,
                    affectedTokenGrantPlanList:response.data.data.affectedTokenGrantPlanList,
                    loadingStatus:0,
                })
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    /**
     * lastDayToExerciseChange
     * @param name
     * @param key
     * @param date
     */
    lastDayToExerciseChange = (name, key, date) => {
        const { data } = this.state;
        let curData = {...data};
        curData[name][key].last_day_to_exercise = date ? date : '';
        this.setState({
            data:curData
        });
    }

    /**
     * 更新table checkbox
     * @param selectCount 选中总计
     * @param selectIndexArray 有更新的checkbox下标和状态
     * @param isEmpty checkbox是否为全不选状态
     */
    updateSelectCount = (selectCount, selectIndexArray, isEmpty = false) => {
        const { confirm_date_list } = this.state.data;
        let list = [...confirm_date_list];
        if(isEmpty){
            list.forEach( (v, k) =>{
                list[k]['checkbox_status'] = false;
            })
        }else{
            selectIndexArray.forEach( (v, k) => {
                list[v.selectIndex]['checkbox_status'] = v.checked;
            })
        }
        this.setState(() => ({
            selectCount,
            selectIndex : selectIndexArray[0],
            confirm_date_list : list,
        }))
    }

    updateTokenSelectCount = (selectTokenCount, selectIndexArray, isEmpty = false) => {
        const { terminate_token_grant_list } = this.state.data;
        let list = [...terminate_token_grant_list];
        if(isEmpty){
            list.forEach( (v, k) =>{
                list[k]['checkbox_status'] = false;
            })
        }else{
            selectIndexArray.forEach( (v, k) => {
                list[v.selectIndex]['checkbox_status'] = v.checked;
            })
        }
        this.setState(() => ({
            selectTokenCount,
            selectIndex : selectIndexArray[0],
            terminate_token_grant_list : list,
        }))
    }

    changeTab=(key)=>{
        this.setState({
            tabKey:key
        })
        $("#termiStep"+key).addClass("stock-did-li");
    }
    nextStep=(start,end)=>{
        $("li").removeClass("active");
        $("li a").attr({"aria-expanded":false});
        if(start===1 && end ===2){
            let curErrorArr = this.state.showErrorArr;
            curErrorArr[0] = "dontshow";
            this.setState({
                showErrorArr:curErrorArr
            })
            $("#termiStep2").addClass("active");
            $("#termiStep1").addClass("stock-did-li");
            this.changeTab(1)
            this.removeErrorBox();
        }
        if(start===2 && end ===3){
            let curErrorArr = this.state.showErrorArr;
            curErrorArr[1] = "dontshow";
            this.setState({
                showErrorArr:curErrorArr
            })
            $("#termiStep3").addClass("active");
            $("#termiStep2").addClass("stock-did-li");
            this.changeTab(2)
            this.removeErrorBox();
            this.getPreTerminateInfo();
            window.$("#notifications ").removeClass("active");
            window.$("#confirmDates").addClass("active");

        }
        if(start===3 && end ===4){
            let curErrorArr = this.state.showErrorArr;
            curErrorArr[2] = "dontshow";
            this.setState({
                showErrorArr:curErrorArr
            });
            $("#termiStep4").addClass("active");
            $("#termiStep3").addClass("stock-did-li");
            $("#confirm_checkbox input").removeClass("error_check");
            $("#confirm_check").addClass("hidden")

            this.changeTab(3)
            this.removeErrorBox();
            this.setState({
                isShowErrorTip:0,
            })
            window.$("#confirmDates ").removeClass("active");
            window.$("#review").addClass("active");
            this.confirmTerminationList()
        }
    }
    checkStatus=()=>{
        let curData = this.state.data;
        let requiredKey = this.state.requiredKey;
        for(let i=0; i<requiredKey.length; i++){
            if(curData[requiredKey[i]]==""){
                $("#"+requiredKey[i]+"_01").addClass("has-error")
            }else{
                $("#"+requiredKey[i]+"_01").removeClass("has-error")
            }
        }
    }
    checkEmail=()=>{
        const { curSelectStakeholder } = this.props;
        const { data } = this.state;
        if(this.state.isNewEmail==1){
            let isEmail = Utils.isEmail(this.state.data.new_email);
            if(data.new_email==""){
                $("#new_email").addClass("has-error");
                this.setState({
                    emailErrorFlag : 1
                })
            }else{
                if(!isEmail){
                    $("#new_email").addClass("has-error");
                    this.setState({
                        emailErrorFlag : 2
                    })
                }else if(data.new_email===curSelectStakeholder.email){
                    $("#new_email").addClass("has-error");
                    this.setState({
                        emailErrorFlag : 5
                    })
                }else{
                    post('checkNewStakeholderEmail', { user_id: this.props.curSelectStakeholder.stakeholder_id, new_email: this.state.data.new_email }).then((response) => {
                        if (response.data.code === 0) {
                            $("#new_email").removeClass("has-error");
                            this.setState({
                                emailErrorFlag : 0
                            })
                            this.nextStep(2,3)
                        } else if(response.data.code === 1){
                            $("#new_email").addClass("has-error");
                            this.setState({
                                emailErrorFlag : 3
                            })
                        }else if(response.data.code === 2){
                            $("#new_email").addClass("has-error");
                            this.setState({
                                emailErrorFlag : 4
                            })
                        }else{
                            console.log("failed, error code is: " + response.data.code);
                        }
                    }).catch(function (error) {
                        console.log(error);
                    });
                }
            }
        }else{
            this.nextStep(2,3)
        }


    }
    removeErrorBox=()=>{
        $("#terminateStakeholderModal div").removeClass("has-error");
    }
    showError=(key,e)=>{
        let curErrorArr = this.state.showErrorArr;
        curErrorArr[key] = "show";
        this.setState({
            showErrorArr:curErrorArr
        });
        if(key==0){
            this.checkStatus();
        }
        if(key==1){
            this.checkEmail();
        }
    }
    initStep=()=>{
        this.refreshData();
        $("#terminateStakeholderModal .tab-pane").removeClass("active");
        $("li").removeClass("active");
        $("li").removeClass("stock-did-li");
        $("#terminationDetails").addClass("active");
        $("#termiStep1").addClass("active")
    }

    refreshData = () =>{
        this.setState({
            data:{
                termination_date:"",
                termination_type:"",
                new_relationship:"",
                new_email:"",
                stakeholder_termination_date:"",
                confirm_date_list:[],
                terminate_token_grant_list:[
                    {
                        id:0,
                        security_type: "",
                        grant_name: "",
                        issue_date:"",
                        issued_quantity:"",
                        vested: "",
                        unvested:"",
                        claimed:"",
                        unclaimed:"",
                        last_day_to_exercise:""
                    }
                ],
                isEmailMe:0,

            },
            terminationList:[],
            tokenTerminationList:[],
            loadingStatus:1,
            terminateStatus:0,
            isCorrect:0,
            new_email:"",
            isNewEmail:0,
            isShowErrorTip:0,
            showErrorArr : ["dontshow","dontshow","dontshow","dontshow"],
            summaryData:[],
            affectedEaPlans:[],
            affectedTokenGrantPlanList:[],
            selectCount:0,
            selectTokenCount:0,
            totalSummaryInfo:[],
            totalTokenSummaryInfo:[],
            emailErrorFlag:0,
        })
    }

    confirmCheckError=()=>{
        const { isCorrect, data } = this.state;
        if( isCorrect == " "){
            $("#confirm_checkbox input").addClass("error_check");
            $("#confirm_check").removeClass("hidden")
        }else{
            $("#confirm_checkbox input").removeClass("error_check");
            $("#confirm_check").addClass("hidden")
        }
        let flag =false; //用于验证勾选的checkbox有没有填值
        let isCheckedForStakeholder = false;//用于验证stakeholderList有没有勾选checkbox
        let isCheckedForToken = false;//用于验证tokenList有没有勾选checkbox
        let stakeholderCheckList = data.confirm_date_list;
        let tokenCheckList = data.terminate_token_grant_list;
        for(let i=0;i<stakeholderCheckList.length;i++){
            if(stakeholderCheckList[i].checkbox_status && stakeholderCheckList[i].last_day_to_exercise){
                isCheckedForStakeholder = true;
                flag = true;
            }
        }
        if(!isCheckedForStakeholder){
            for(let i=0;i<tokenCheckList.length;i++){
                if(tokenCheckList[i].checkbox_status && tokenCheckList[i].last_day_to_exercise){
                    isCheckedForToken = true;
                    flag = true;
                }
            }
        }

        if((!isCheckedForStakeholder && !isCheckedForToken) || !isCorrect){
            flag = false;
        }
        if(flag){
            this.setState({ isShowErrorTip: 0})

            //表示所有数据正常，可以开始跳转
            this.nextStep(3,4);

        }else{
            if(isCorrect == " "){
                this.setState({
                    isShowConfirmTip: 0,
                })
            }else if(!isCheckedForStakeholder || !isCheckedForToken ){
                this.setState({ isShowErrorTip: 1})
            }
        }
    }


    confirmTerminationList = () =>{
        const { data } = this.state;
        let terminationList = this.state.terminationList;
        let tokenTerminationList = this.state.tokenTerminationList;

        let vestedTotal = 0;
        let unvestedTotal =0;
        let exercisedTotal = 0;
        let vestSummaryInfo = {};
        let unvestedSummaryInfo = {};
        let totalSummaryInfo = this.state.totalSummaryInfo;
        let vestedInfo = "Vested shares returned to pool";
        let unVestedInfo = __("Unvested shares returned to pool");
        for(let i= 0;i<data.confirm_date_list.length;i++){
            if(data.confirm_date_list[i].checkbox_status) {
                if(data.confirm_date_list[i].last_day_to_exercise){
                    let oneVested = parseFloat(data.confirm_date_list[i].return_to_pool);
                    let oneUnvested = parseFloat(data.confirm_date_list[i].unvested);
                    let dateToExercise = data.confirm_date_list[i].last_day_to_exercise
                    vestedTotal += oneVested;
                    unvestedTotal += oneUnvested ;
                    exercisedTotal += parseFloat(this.state.data.confirm_date_list[i].exercised_quantity);
                    if(oneVested > 0 && data.confirm_date_list[i].security_type != "CS" && data.confirm_date_list[i].security_type!="PS"){
                        vestSummaryInfo[dateToExercise] =
                            {summary_info:vestedInfo, amount:oneVested, date_returning: (dateToExercise == -1 ? '' : dateToExercise)};
                    }
                    if(oneUnvested > 0){
                        unvestedSummaryInfo[dateToExercise] =
                            {summary_info:unVestedInfo, amount:unvestedTotal, date_returning:data.termination_date};
                    }
                    terminationList.push(data.confirm_date_list[i])
                    this.setState({ terminationList})
                }
            }
        }
        totalSummaryInfo = Object.values(vestSummaryInfo).concat(Object.values(unvestedSummaryInfo));
        this.setState({ totalSummaryInfo })

        let vestedTokenTotal = 0;
        let unvestedTokenTotal =0;
        let vestTokenSummaryInfo = {};
        let unvestedTokenSummaryInfo = {};
        let totalTokenSummaryInfo = this.state.totalTokenSummaryInfo;
        let vestedTokenInfo = "Vested tokens returned to pool";
        let unVestedTokenInfo = "Unvested tokens returned to pool";
        for(let i= 0;i<data.terminate_token_grant_list.length;i++){
            if(data.terminate_token_grant_list[i].checkbox_status) {
                if(data.terminate_token_grant_list[i].last_day_to_exercise){
                    let oneTokenVested = parseFloat(data.terminate_token_grant_list[i].vested);
                    let oneTokenUnvested = parseFloat(data.terminate_token_grant_list[i].unvested);
                    let dateToExercise = data.terminate_token_grant_list[i].last_day_to_exercise
                    vestedTokenTotal += oneTokenVested;
                    unvestedTokenTotal += oneTokenUnvested;
                    exercisedTotal += parseFloat(this.state.data.terminate_token_grant_list[i].exercised_quantity);
                    if(oneTokenVested > 0 && data.terminate_token_grant_list[i].security_type != "CS" && data.terminate_token_grant_list[i].security_type!="PS"){
                        if(vestTokenSummaryInfo.hasOwnProperty(dateToExercise)){
                            vestTokenSummaryInfo[dateToExercise]['amount'] += oneTokenVested;
                        }else{
                            //初始化一条数据
                            vestTokenSummaryInfo[dateToExercise] =
                                {summary_info:vestedTokenInfo,amount:oneTokenVested,date_returning: (dateToExercise == -1 ? '' : dateToExercise)};
                        }
                    }
                    if(oneTokenUnvested > 0){
                        if(unvestedTokenSummaryInfo.hasOwnProperty(dateToExercise)){
                            unvestedTokenSummaryInfo[dateToExercise]['amount'] += oneTokenUnvested;
                        }else{
                            //初始化一条数据
                            unvestedTokenSummaryInfo[dateToExercise] =
                                {summary_info:unVestedTokenInfo,amount:oneTokenUnvested,date_returning:data.termination_date};
                        }
                    }
                    tokenTerminationList.push(data.terminate_token_grant_list[i])
                    this.setState({ tokenTerminationList})
                }
            }
        }
        totalTokenSummaryInfo = Object.values(vestTokenSummaryInfo).concat(Object.values(unvestedTokenSummaryInfo));
        this.setState({ totalTokenSummaryInfo })
    }

    terminateStakeholder=()=>{
        if(this.state.terminateStatus == 1) return;
        const { terminationList, tokenTerminationList } = this.state;
        this.setState({
            terminateStatus:1
        });
        let requestData = this.state.data;
        let stakeholderId = this.props.curSelectStakeholder.stakeholder_id;
        if(!stakeholderId){
            requestData.stakeholder_id = this.props.curSelectStakeholder.id;
        }else{
            requestData.stakeholder_id = stakeholderId
        }
        let stakeholderTerList = terminationList.map(({id,last_day_to_exercise})=>({id,last_day_to_exercise}))
        let tokenTerList = tokenTerminationList.map(({id,last_day_to_exercise})=>({id,last_day_to_exercise}))
        requestData.confirm_date_list = stakeholderTerList
        requestData.terminate_token_grant_list = tokenTerList
        post('terminateStakeholder',requestData).then((response)=> {
            if(response.data.code === 0){
                window.location.reload();
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    closeModalTermination=()=>{
        $("#terminateStakeholderModal").modal("hide")
    }

    handleTerminationDateChange = (key, moment_key, date) => {
        const { dateFormat } = this.state;
        let curData = this.state.data;
        curData[key] = date?.format( dateFormat);
        curData[moment_key] = date;
        this.setState({
            data:curData
        });
    }

    tableObj = (ref) => {
        this.setState(()=>({
            tableChild : ref
        }))
    }

    saveState = (key, value) => {
        let data = {};
        data[key] = value;
        this.setState(data)
    }

    render(){
        const { data, dateFormat, terminationList, tokenTerminationList, selectCount, selectTokenCount, totalSummaryInfo, totalTokenSummaryInfo, emailErrorFlag } = this.state;
        const expirationDateLongDesc = {'-1': __('No expiration date')};
        let step1Error = 1;
        if(this.state.data.termination_date!="" && this.state.data.termination_type!=""&&this.state.data.new_relationship!=""){
            step1Error = 0;
        };
        let step2Error = 0;
        let isEmail = Utils.isEmail(this.state.data.new_email);
        if(this.state.isNewEmail==1 && !isEmail){
            step2Error = 1
        }
        let step3Error = 0;
        let stakeholderCheck = 0;
        let tokenStakeholderCheck = 0;
        let curData = this.state.data;
        let curDate = Utils.getWeightByDateString(Utils.getCurDate()) ;
        let vestedInfo = __("Vested shares returned to pool");
        let unVestedInfo = __("Unvested shares returned to pool");
        let vestedTokenInfo = __("vested tokens returned to pool");
        let unVestedTokenInfo = __("Unvested tokens returned to pool");
        if(curData.confirm_date_list){
            for(let i=0;i<curData.confirm_date_list.length;i++){
                let dateToExercise = data.confirm_date_list[i].last_day_to_exercise;
                //验证日期
                if(curData.confirm_date_list[i].hasOwnProperty('checkbox_status') && dateToExercise){
                    stakeholderCheck = 1;
                    break;
                }
                }
        }
        if(curData.terminate_token_grant_list){
            for(let i=0;i<curData.terminate_token_grant_list.length;i++){
                let dateToExercise = data.terminate_token_grant_list[i].last_day_to_exercise;
                //验证日期
                if(curData.terminate_token_grant_list[i].hasOwnProperty('checkbox_status') && dateToExercise){
                    tokenStakeholderCheck = 1;
                    break;
                }
            }
        }

        let curTerminationDate = Utils.dateFormatTransfer(curData.termination_date)
        if((tokenStakeholderCheck==1 || stakeholderCheck==1)&& this.state.isCorrect){
            step3Error = 0
        }
        if((tokenStakeholderCheck ==0 && stakeholderCheck ==0) || !this.state.isCorrect){
            step3Error = 1
        }
        const emailError = emailErrorFlag == 0 ? " " : (emailErrorFlag == 1 ? __("Email is required.") : (emailErrorFlag == 2 ? __("Email address is invalid.") : (emailErrorFlag == 3 ? __("This email address already exists.") : (emailErrorFlag == 4 ? __("This email address is in use.") : __("Please choose this email address directly from above.")))) );
        return(
            <div className="modal fade" id="terminateStakeholderModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={{display: "none"}} >
                <div className="modal-dialog dialog1050 dialog-top40">
                    <div className="modal-content content1050">
                        <div className="modal-header box-header">
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.initStep}>×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Terminate')} {this.props.curSelectStakeholder.name|| this.props.curSelectStakeholder.stakeholder_name}</h4>
                        </div>
                        <div className="modal-body">
                            <div className="vesting-box">
                                <ul className="nav vesting-nav" role="tablist">
                                    <li role="presentation" className="active stock-li stock-did-li" id="termiStep1">
                                        <a className="stock-a" href="#terminationDetails"  onClick={this.changeTab.bind(this,0)} aria-controls="#terminationDetails" role="tab" data-toggle="tab">1</a>
                                        <p className="shares_p">{__('Termination details')}</p>
                                        <div className="hr1 terHr"></div>
                                    </li>
                                    {step1Error==0 &&
                                    <li role="presentation" className="stock-li stock-grayli stock-li2" id="termiStep2">
                                        <a href="#notifications"  aria-controls="#notifications" onClick={this.changeTab.bind(this,1)}  className="stock-a" role="tab" data-toggle="tab">2</a>
                                        <p className="shares_p">{__('Notifications')}</p>
                                        <div className="hr1 terHr"></div>
                                    </li>
                                    }
                                    {step1Error == 1 &&
                                    <li role="presentation" className="stock-li stock-nodoing">
                                        <a className="stock-a " href="javascript:return false;">2</a>
                                        <p className="shares_p">{__('Notifications')}</p>
                                        <div className="hr1 terHr"></div>
                                    </li>
                                    }
                                    {step2Error == 0 &&
                                    <li role="presentation" className="stock-li stock-grayli stock-li3" id="termiStep3">
                                        <a className="stock-a" aria-controls="#confirmDates"  onClick={this.changeTab.bind(this,2)} href="#confirmDates" role="tab" data-toggle="tab">3</a>
                                        <p className="shares_p">{__('Confirm dates')}</p>
                                        <div className="hr1 terHr"></div>
                                    </li>
                                    }
                                    {step2Error == 1 &&
                                    <li role="presentation" className="stock-li stock-nodoing">
                                        <a className="stock-a " href="javascript:return false;">3</a>
                                        <p className="shares_p">{__('Confirm dates')}</p>
                                        <div className="hr1 terHr"></div>
                                    </li>
                                    }
                                    {step3Error == 0 &&
                                    <li role="presentation" className="stock-li stock-grayli stock-li4" id="termiStep4">
                                        <a aria-controls="#review" className="stock-a" href="#review"  onClick={this.changeTab.bind(this,3)} role="tab" data-toggle="tab">4</a>
                                        <p className="shares_p">{__('Review and terminate')}</p>
                                    </li>
                                    }
                                    {step3Error == 1 &&
                                    <li role="presentation" className="stock-li stock-nodoing">
                                        <a className="stock-a " href="javascript:return false;">4</a>
                                        <p className="shares_p">{__('Review and terminate')}</p>
                                    </li>
                                    }
                                </ul>
                                <div className="tab-content">
                                    <div role="tabpanel" className="tab-pane active" id="terminationDetails">
                                        <div className="terminate-titbox reset_margin">
                                            {/*<p className="ter-title">Terminate {this.props.curSelectStakeholder.name}</p>*/}
                                            <p className="ter-title">{__('Terminate')} {this.props.curSelectStakeholder.name}</p>
                                            <p className="ter-subtitle">{__('Begin termination')}</p>
                                            <p className="ter-description">{__("Enter the details of the termination and update the stakeholder's relationship.")}</p>
                                        </div>
                                        {this.state.showErrorArr[0] ==="show" &&
                                        <div className="stock-form">
                                            <div className="alert alert-warning alert-dismissable pink-box" role="alert">
                                                <p>{__("Required field (s) can't be empty.")}</p>
                                            </div>
                                        </div>
                                        }
                                        <div className="form-horizontal stock-form">
                                            <div className="form-group row ter-row marginBott2">
                                                <label className="col-xs-4 add-label">{__('Termination date')}</label>
                                                <div className="col-xs-4" >
                                                    <DateSelector
                                                        allowClear={false}
                                                        onChange={this.handleTerminationDateChange.bind(this, 'termination_date', 'termination_date_moment')}
                                                        value={I(data['termination_date_moment'], I(data['termination_date'], '') ? moment(data['termination_date'], dateFormat) : '')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row ter-row ter-tipBox">
                                                <p className="col-xs-8 col-xs-push-4 text-muted ter-tipText">{__('Vesting will stop on this date. If termination date and vest date match, tranche will vest.')}</p>
                                            </div>
                                            <div className="form-group row ter-row marginBott2">
                                                <label className="col-xs-4 add-label">{__('Termination type')}</label>
                                                <div className="col-xs-4" id={"termination_type_01"}>
                                                    <select name="termination_type"  onChange={this.recordData} value={this.state.data.termination_type} className="form-control select-container" required type="text">
                                                        <option value="">--</option>
                                                        {this.state.terminationType.map((value,key)=>{
                                                            return(
                                                                <option value={value} key={key}>{value}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row ter-row ter-tipBox">
                                                <p className="col-xs-8 col-xs-push-4 text-muted ter-tipText">{__('This impacts post termination exercise dates and last day to exercise. The post termination exercise periods are set in the option plan for each type.')}</p>
                                            </div>
                                            <div className="form-group row ter-row marginBott20">
                                                <label className="col-xs-4 add-label">{__('New relationship date')}</label>
                                                <div className="col-xs-4" >
                                                    <DateSelector
                                                        allowClear={false}
                                                        onChange={this.handleTerminationDateChange.bind(this, 'stakeholder_termination_date', 'stakeholder_termination_date_moment')}
                                                        value={I(data['stakeholder_termination_date_moment'], I(data['stakeholder_termination_date'], '') ? moment(data['stakeholder_termination_date'], dateFormat) : '')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row ter-row marginBott2">
                                                <label className="col-xs-4 add-label">{__('New relationship')}</label>
                                                <div className="col-xs-4" id={"new_relationship_01"}>
                                                    <select name="new_relationship" onChange={this.recordData} value={this.state.data.new_relationship} className="form-control select-container" required type="text">
                                                        <option value="">--</option>
                                                        {relationshipList.map((value,key)=>{
                                                            return(
                                                                <option value={value} key={key}>{value}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row ter-row ter-tipBox">
                                                <p className="col-xs-8 col-xs-push-4 text-muted ter-tipText">{__('Relationship is used for expense reporting.')}
                                                    {/*<br /><span><a className="text-blue">Learn More ›</a></span>*/}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="nav" role="tablist">
                                            <div role="presentation" className="modal-footer box-footer vesting-footer">
                                                {step1Error ===0 && <a onClick={this.nextStep.bind(this,1,2)} href="#notifications" role="tab" data-toggle="tab" className="btn boxbtn-to bg-darkblue">{__('Next: notifications')}</a>}
                                                {step1Error ===1 && <a onClick={this.showError.bind(this,0)} className="btn boxbtn-to bg-darkblue">{__('Next: notifications')}</a>}
                                            </div>
                                        </div>
                                    </div>
                                    <div role="tabpanel" className="tab-pane" id="notifications">
                                        <div className="terminate-titbox notifications-titbox">
                                            <p className="ter-title">{__('Notifications')}</p>
                                            <p className="ter-subtitle">{__('Update email and review notifications')}</p>
                                            <p className="ter-description marginBott10">{__('To ensure the stakeholder can access Folium after termination, confirm or add a personal email. Notifications will be sent to all emails on file for the stakeholder.')}</p>
                                            <p className="ter-description">{__('Note: If you enter a new email address, we will ask the user to create a new login password to go with their new login email.')}</p>
                                        </div>
                                        {this.state.showErrorArr[1] ==="show" &&
                                        <div className="stock-form">
                                            <div className="alert alert-warning alert-dismissable pink-box" role="alert">
                                                <p>{__('The email cannot be empty or invalid.')}</p>
                                            </div>
                                        </div>
                                        }
                                        <div className="form-horizontal stock-form">
                                            <div className="form-group row ter-row">
                                                <label className="col-xs-4 add-label">{__('Personal email address')}</label>
                                                <div className="col-xs-8">
                                                    <div className="radio status-radio holders-radio">
                                                        <input id="engagedEmail" type="radio" name="isNewEmail" value={0} checked={this.state.isNewEmail===0} onClick={this.recordEmail} />
                                                        <label htmlFor="engagedEmail" className="radio-label holder-label holders-radioLabel">{this.props.curSelectStakeholder.email?this.props.curSelectStakeholder.email:this.props.curSelectStakeholder.email}</label>
                                                    </div>
                                                    <div className="radio status-radio holders-radio">
                                                        <input id="addNewEmail" type="radio" name="isNewEmail"  value={1} checked={this.state.isNewEmail===1} onClick={this.recordEmail}/>
                                                        <label htmlFor="addNewEmail" className="radio-label holder-label holders-radioLabel">{__('Add a new email')}</label>
                                                    </div>
                                                    <p className="text-muted add-tip">{__('Non-individual stakeholders can be investment firms companies, corporations, etc.')}</p>
                                                </div>
                                            </div>
                                            {this.state.isNewEmail===1 &&
                                                <div>
                                                    <div className="form-group row ter-row marginBott2 newEmailCon">
                                                        <label className="col-xs-4 add-label">{__('New email')}</label>
                                                        <div className="col-xs-5" id={"new_email"}>
                                                            <input name="new_email" required value={this.state.data.new_email} onChange={this.recordData} className="form-control" type="email" onBlur={this.checkIsEmail} />
                                                            <p className="error_tip">{ emailError }</p>
                                                        </div>
                                                    </div>
                                                    <div className="row ter-row ter-tipBox">
                                                        <p className="col-xs-8 col-xs-push-4 text-muted ter-tipText">{__('This will update the email address on the stakeholder ledger. The terminated stakeholder will have to update their login email on their end as needed.')}
                                                        </p>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                        <div className="nav" role="tablist">
                                            <div role="presentation" className="modal-footer box-footer vesting-footer">
                                                {/*{step2Error ===0 && <a onClick={this.nextStep.bind(this,2,3)} href="#confirmDates" role="tab" data-toggle="tab" className="btn boxbtn-to bg-darkblue">Next: confirm dates</a>}*/}
                                                {/*{step2Error ===1 && <a onClick={this.showError.bind(this,1)}    className="btn boxbtn-to bg-darkblue">Next: confirm dates</a>}*/}
                                                <a onClick={this.checkEmail}  role="tab" data-toggle="tab"  className="btn boxbtn-to bg-darkblue">{__('Next: confirm dates')}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div role="tabpanel" className="tab-pane" id="confirmDates">
                                        <div className="terminate-titbox notifications-titbox">
                                            <p className="ter-title">{__('Confirm dates')}</p>
                                        </div>
                                        <div className="stock-form">
                                            {this.state.showErrorArr[2] === "show" &&
                                            <div className="alert alert-warning alert-dismissable pink-box" role="alert">
                                                <p>{ printf(__('As of the termination date %s, there are no vested, exercisable options for %s. Proceed to the final review step to complete the termination.'), formatDate(data.termination_date) , this.props.curSelectStakeholder.name)}</p>
                                            </div>
                                            }
                                        </div>

                                        <div className="ter-review-titbox">
                                            <p className="ter-subtitle">{__('Confirm dates')}</p>
                                            <p className="ter-description marginBott10">{ printf(__('Review and confirm that the dates to exercise and repurchase are correct. These dates are set in the equity plan and based on the termination date and termination type selected: %s, With cause. Exercises and repurchases must be completed by 11:59 pm on selected date.'),formatDate(data.termination_date))}</p>
                                        </div>
                                        <div className="ter-reviewContent">
                                            <div className="ter-review-titbox">
                                                <p className="ter-review-subtitle">{__('Grants')}</p>
                                                <p className="ter-description marginBott10">{__('ISO grants will be disqualified if the last day to exercise is more than 3 months after the termination date.')}</p>
                                            </div>
                                            {data.confirm_date_list && data.confirm_date_list.length > 0 &&
                                            <div>
                                                <TerminateStakeholderTable
                                                    securityLists={data.confirm_date_list}
                                                    onRef={this.tableObj}
                                                    saveState={this.saveState}
                                                    lastDayToExerciseChange={this.lastDayToExerciseChange}
                                                    updateSelectCount={this.updateSelectCount}
                                                />
                                            </div>
                                            }
                                            {data.terminate_token_grant_list && data.terminate_token_grant_list.length > 0 &&
                                            <div>
                                                <TerminateTokenGrantTable
                                                    securityLists={data.terminate_token_grant_list}
                                                    onRef={this.tableObj}
                                                    saveState={this.saveState}
                                                    lastDayToExerciseChange={this.lastDayToExerciseChange}
                                                    updateSelectCount={this.updateTokenSelectCount}
                                                />
                                            </div>
                                            }
                                        </div>
                                        <div className=" confirm_date_checkbox">
                                            <div className="checkbox send-check text-muted" id="confirm_checkbox">
                                                <input type="checkbox" className="send-box email-sendbox" id="confirm_last_day" name="isCorrect" value={this.state.isCorrect} checked={this.state.isCorrect} onClick={this.confirmOrNot}/>
                                                <label className="send-div" htmlFor="confirm_last_day"></label>
                                                <span className="text-muted emailMe">{__('I confirm that the last day to exercise is correct for each grant.')}</span>
                                            </div>
                                            <p className="alarm_text marginBott20 hidden" id="confirm_check"><ExclamationCircleOutlined className='icon-padding' /> {__('This field is required.')}</p>
                                        </div>
                                        { (selectCount >0 || selectTokenCount >0) &&
                                        <div>
                                            <p className="ter-description marginBott10">{__('The table below shows the number of days remaining until last day to exercise (from termination date). These days are based on dates selected above.')}</p>
                                            <table className="table merge-table review-relationship-table">
                                                <tbody>
                                                {data.confirm_date_list && data.confirm_date_list.map((value,key)=>{
                                                    if(value.checkbox_status)
                                                    return(
                                                        <tr key={key}>
                                                            <td className="width40perc">{value.prefix}</td>
                                                            {value.last_day_to_exercise=="" &&
                                                            <td> {__('days')}</td>
                                                            }
                                                            {value.last_day_to_exercise!="" &&
                                                            <td> {formatNumber(Utils.getDaysBetween(curTerminationDate,Utils.dateFormatTransfer( (value.last_day_to_exercise == -1) ? '' : value.last_day_to_exercise )),null)} {__('days')}</td>
                                                            }
                                                        </tr>
                                                    )
                                                })}
                                                { data.terminate_token_grant_list && data.terminate_token_grant_list.map((v,k)=>{
                                                    if(v.checkbox_status)
                                                    return(
                                                        <tr key={k}>
                                                            <td className="width40perc">{v.grant_name}</td>
                                                            {v.last_day_to_exercise=="" &&
                                                            <td> {__('days')}</td>
                                                            }
                                                            {v.last_day_to_exercise!="" &&
                                                            <td> {formatNumber(Utils.getDaysBetween(curTerminationDate,Utils.dateFormatTransfer( (v.last_day_to_exercise == -1) ? '' : v.last_day_to_exercise )),null)} {__('days')}</td>
                                                            }
                                                        </tr>
                                                    )
                                                })}
                                                </tbody>
                                            </table>
                                            {this.state.isShowErrorTip == 1 &&
                                            <div className="alert alert-warning alert-dismissable pink-box" role="alert">
                                                <p>{__('The last day to exercise can not be empty.')}</p>
                                            </div>
                                            }
                                        </div>
                                        }
                                        <div className="nav" role="tablist">
                                            <div className="preStock-footer"  role="presentation">
                                                {/*{(step3Error == 1 || this.state.isCorrect==0) &&*/}
                                                <a onClick={this.confirmCheckError} data-toggle="tab"   className="btn boxbtn-to bg-darkblue ">{__('Next: review and terminate')}</a>
                                                {/*}*/}
                                                {/*{step3Error == 0 && this.state.isCorrect ==1 &&*/}
                                                {/*<a href="#review" role="tab" data-toggle="tab" onClick={this.nextStep.bind(this,3,4)}  className="btn boxbtn-to bg-darkblue ">Next: review and terminate</a>*/}
                                                {/*}*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div role="tabpanel" className="ter-review tab-pane" id="review">
                                        <div className="terminate-titbox">
                                            <p className="ter-title">{__('Review termination')}</p>
                                        </div>
                                        <div className="ter-review-titbox">
                                            <p className="ter-subtitle">{__('Review and terminate')}</p>
                                            <p className="ter-description marginBott10">{ printf(__('Review the termination of %s dated %s.'), this.props.curSelectStakeholder.name , formatDate(data.termination_date))}</p>
                                        </div>
                                        <div className="ter-reviewContent">
                                            {terminationList && terminationList.length > 0 &&
                                                <div>
                                                    <div className="ter-review-titbox">
                                                        <p className="ter-review-subtitle">{__('Grants')}</p>
                                                    </div>
                                                    <table className="table reviewTable grantTable marginTop20">
                                                        <tbody>
                                                        <tr className="table-titleTr">
                                                            <td>{__('Prefix')}</td>
                                                            <td>{__('Acceptance status')}</td>
                                                            <td>{__('Issue date')}</td>
                                                            <td>{__('Granted')}</td>
                                                            <td>{__('Vested')}</td>
                                                            <td>{__('Unvested')}</td>
                                                            <td>{__('Exercised')}</td>
                                                            <td>{__('ISO disqualification date')}</td>
                                                            <td className={"checkTd"}>
                                                                <span className="ter-checkColumn">{__('Check this column')}</span>
                                                                {__('Last day to exercise')}
                                                            </td>
                                                        </tr>
                                                        {terminationList.map((value, key) => {
                                                            return (
                                                                <tr className="table-contentTr" key={key}>
                                                                    <td>{value.prefix}</td>
                                                                    <td>{value.sign_status == 0 ? "Accepted" : (value.sign_status == 1 ? __("Awaiting officer signature") : __("Awaiting holder signature"))}</td>
                                                                    <td>{value.issue_date == "" ? "--" : formatDate(value.issue_date)}</td>
                                                                    <td>{formatFractionalNumber(value.granted)}</td>
                                                                    <td>{formatFractionalNumber(value.vested)}</td>
                                                                    <td>{formatFractionalNumber(value.unvested)}</td>
                                                                    <td>{formatFractionalNumber(value.exercised_quantity)}</td>
                                                                    <td>{value.iso_disqualification_date == "" ? "--" : formatDate(value.iso_disqualification_date)}</td>
                                                                    <td>{value.last_day_to_exercise == "" ? "--" : I(expirationDateLongDesc[value.last_day_to_exercise], formatDate(value.last_day_to_exercise))}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            }
                                            {tokenTerminationList && tokenTerminationList.length>0 &&
                                                <div className="marginTop40">
                                                    <div className="ter-review-titbox">
                                                        <p className="ter-review-subtitle">{__('Token')}</p>
                                                    </div>
                                                    <table className="table reviewTable grantTable marginTop20">
                                                        <tbody>
                                                        <tr className="table-titleTr">
                                                            <td>{__('Grant name')}</td>
                                                            <td>{__('Grant date')}</td>
                                                            <td>{__('Issued quantity')}</td>
                                                            <td>{__('Vested')}</td>
                                                            <td>{__('Unvested')}</td>
                                                            <td>{__('Claimed')}</td>
                                                            <td>{__('Unclaimed')}</td>
                                                            <td className={"checkTd"}>
                                                                <span className="ter-checkColumn">{__('Check this column')}</span>
                                                                {__('Last day to exercise')}
                                                            </td>
                                                        </tr>
                                                        {tokenTerminationList.map((value, key) => {
                                                            return (
                                                                <tr className="table-contentTr" key={key}>
                                                                    <td>{value.grant_name}</td>
                                                                    <td>{value.issue_date == "" ? "--" : formatDate(value.grant_date)}</td>
                                                                    <td>{formatFractionalNumber(value.quantity)}</td>
                                                                    <td>{formatFractionalNumber(value.vested)}</td>
                                                                    <td>{formatFractionalNumber(value.unvested)}</td>
                                                                    <td>{formatFractionalNumber(value.claimed)}</td>
                                                                    <td>{formatFractionalNumber(value.unclaimed)}</td>
                                                                    <td>{value.last_day_to_exercise == "" ? "--" : I(expirationDateLongDesc[value.last_day_to_exercise], formatDate(value.last_day_to_exercise))}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            }
                                        </div>
                                        <div className="ter-reviewContent">
                                            <div className="ter-review-titbox">
                                                <p className="ter-subtitle">{__('Overview of changes')}</p>
                                                <p className="ter-description">{printf(__('After %s all vested shares that are not exercised or repurchased will be returned to the equity pool and be available to issue out. The summary shown below are based on no transactions occurring and represent the most shares that will be returned to the equity pool.'), this.state.data.termination_date )}</p>
                                            </div>
                                            <table className="table reviewTable marginTop20">
                                                <tbody>
                                                <tr className="table-titleTr">
                                                    <td className="width440">{__('Summary Information')}</td>
                                                    <td>{__('Amount')}</td>
                                                    <td>{__('Date returning')}</td>
                                                </tr>
                                                {totalSummaryInfo.length==0 && totalTokenSummaryInfo.length==0 &&
                                                <tr>
                                                    <td colSpan={3}><Empty/></td>
                                                </tr>
                                                }
                                                {totalSummaryInfo && totalSummaryInfo.map((value,key)=>{
                                                    return(
                                                    <tr className="table-contentTr overviewTr"  key={key}>
                                                        <td className="width440">{value.summary_info}</td>
                                                        <td>{formatFractionalNumber(value.amount)}</td>
                                                        <td>{formatDate(value.date_returning)}</td>
                                                    </tr>
                                                    )
                                                })}
                                                {totalTokenSummaryInfo && totalTokenSummaryInfo.map((val,k)=>{
                                                    return(
                                                    <tr className="table-contentTr overviewTr"  key={k}>
                                                    <td className="width440">{val.summary_info}</td>
                                                    <td>{formatFractionalNumber(val.amount)}</td>
                                                    <td>{formatDate(val.date_returning)}</td>
                                                    </tr>
                                                    )
                                                })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="ter-reviewContent">
                                            <div className="ter-review-titbox">
                                                <p className="ter-description">{printf(__('The shares available amount reflects the current equity plan as of the termination date %s. Only shares unvested as of the termination date, will be reflected in post-termination numbers.'), this.state.data.termination_date )}</p>
                                            </div>
                                            <table className="table reviewTable marginTop20">
                                                <tbody>
                                                <tr className="table-titleTr">
                                                    <td className="width440">{__('Shares/Tokens available in plan')}</td>
                                                    <td>{__('Pre-termination')}</td>
                                                    <td>{__('Post-termination')}</td>
                                                </tr>
                                                {this.state.affectedEaPlans.length==0 &&
                                                <tr>
                                                    <td colSpan={3}><Empty/></td>
                                                </tr>
                                                }
                                                {this.state.affectedEaPlans && this.state.affectedEaPlans.map((value,key)=>{
                                                    let postTerminationQuantity = value.pre_termination_quantity;
                                                    for(let i=0;i<totalSummaryInfo.length;i++){
                                                        let sharesStatus = totalSummaryInfo[i].summary_info;
                                                        let dateReturning = Utils.getWeightByDateString(totalSummaryInfo[i].date_returning);
                                                        if(sharesStatus == vestedInfo && curDate > dateReturning){
                                                            postTerminationQuantity += totalSummaryInfo[i].amount;
                                                        }
                                                        if(sharesStatus == unVestedInfo && curDate >= dateReturning){
                                                            postTerminationQuantity += totalSummaryInfo[i].amount;
                                                        }
                                                    }
                                                    return(
                                                        <tr className="table-contentTr overviewTr"  key={key}>
                                                            <td className="width440">{value.plan_name}</td>
                                                            <td>{formatFractionalNumber(value.pre_termination_quantity) }</td>
                                                            <td>{formatFractionalNumber(postTerminationQuantity)}</td>
                                                        </tr>
                                                    )
                                                })}

                                                {this.state.affectedTokenGrantPlanList && this.state.affectedTokenGrantPlanList.map((value,key)=>{
                                                    let postTerminationTokenQuantity = value.pre_termination_quantity;
                                                    for(let i=0;i<totalTokenSummaryInfo.length;i++){
                                                        let sharesStatus = totalTokenSummaryInfo[i].summary_info;
                                                        let dateReturning = Utils.getWeightByDateString(totalTokenSummaryInfo[i].date_returning);
                                                        if(sharesStatus == vestedTokenInfo && curDate > dateReturning){
                                                            postTerminationTokenQuantity += totalTokenSummaryInfo[i].amount;
                                                        }
                                                        if(sharesStatus == unVestedTokenInfo && curDate >= dateReturning){
                                                            postTerminationTokenQuantity += totalTokenSummaryInfo[i].amount;
                                                        }
                                                    }
                                                    return(
                                                        <tr className="table-contentTr overviewTr"  key={key}>
                                                            <td className="width440">{value.plan_name}</td>
                                                            <td>{formatFractionalNumber(value.pre_termination_quantity) }</td>
                                                            <td>{formatFractionalNumber(postTerminationTokenQuantity)}</td>
                                                        </tr>
                                                    )
                                                })}

                                                </tbody>
                                            </table>


                                        </div>
                                        {/*<div className="ter-email-checkbox">*/}
                                        {/*    <div className="checkbox send-check text-muted">*/}
                                        {/*        <input type="checkbox" className="send-box email-sendbox" id="ter_email" name="isEmailMe" value={this.state.data.isEmailMe} onClick={this.IsOrNot}/>*/}
                                        {/*        <label className="send-div" htmlFor="ter_email"></label>*/}
                                        {/*        <span className="text-muted emailMe">Email me a copy of these changes</span>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="preStock-footer">
                                            <button type="button" onClick={this.terminateStakeholder} className="btn boxbtn-to bg-red prices-btn">{this.state.terminateStatus==0 ? __("Terminate stakeholder") : __("Terminating")}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default TerminateStakeholder;

