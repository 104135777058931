import React, { Component, Fragment } from "react";
import Loading from 'components/Loading';
import Modal from 'components/Modal';
import moment from 'moment';
import Utils, { calculator, formatWithArray, clearNoNum, filterAmount, setCursorPos, I } from 'Utils';
import Header from './components/HeaderModels';
import WaterfallInput from './components/WaterfallInputModels';
import WaterfallModelsTable from './components/WaterfallModelsTableModels';
import AdvanceTermsModels from "./components/AdvancedTermsModels";
import {message} from "antd";
import { post } from 'srcPath/http';
import {__LANG} from '../Lang/en-us';
import './style.scss';


class CreateNew extends Component {

    advanceTermsModal = null;

    constructor(props) {
        super(props);
        this.state = {
            loading : false,
            errorShow : false,
            exitValue : '',
            capTableValue : 0,
            nonConvertibleDebtValue : '',
            projectedExitDate : moment(),
            vestingValue : 1,
            dateFormat : 'DD/MM/YYYY',
            viewResultsLoading : false,
            __LANG : __LANG(),
        }
    }

    /**
     * 跳转页签
     * @param type 1: financing round , 2: waterfall
     */
    typeChange = (type) => {
        this.props.typeChange(type);
    }

    /**
     * exit value change
     * @param e
     */
    exitValueChange = (e) => {
        const { target } = e;
        const filter = filterAmount(e);
        this.setState(()=>({
            exitValue : filter.value
        }), () => {
            setCursorPos(target, filter.cursorPositionX);
        })
    }

    /**
     * cap table value change (select)
     * @param value
     */
    capTableValueChange = (value) => {
        this.setState(()=>({
            capTableValue : value
        }))
    }

    /**
     * nonConvertibleDebtValueChange
     * @param e
     */
    nonConvertibleDebtValueChange = (e) => {
        const { target } = e;
        const filter = filterAmount(e);
        this.setState(()=>({
            nonConvertibleDebtValue : filter.value
        }), () => {
            setCursorPos(target, filter.cursorPositionX);
        })
    }

    /**
     * projected exit date change
     * @param date
     */
    projectedExitDateChange = (date) => {
        this.setState(()=>({
            projectedExitDate : date
        }))
    }

    /**
     * Advanced Terms click 事件
     */
    advancedTermsChange = () => {
        const { vestingValue } = this.state;
        this.advanceTermsModal = Modal.open({
            component: props => <AdvanceTermsModels
                close={props.close}
                vestingValue={vestingValue}
                vestingValueChange={this.vestingValueChange}
            />,
            className: 'certificate-modal-wrap',
            width: '669px',
            maskClosable: false
        })
    }

    vestingValueChange = (value) => {
        this.setState(()=>({
            vestingValue : value
        }))
    }

    saveTerms = async() => {
        const { capTableValue, errorShow, exitValue, projectedExitDate, nonConvertibleDebtValue,
            vestingValue, dateFormat, __LANG } = this.state;
        const { getWaterFallList, detailIdChange, updateWaterfallModelsList } = this.props;
        let errorRes = errorShow;
        const stateData = {
            roundId : capTableValue,
            exitValue : clearNoNum({value:exitValue}).value,
            projectedExitDate : I(projectedExitDate, '') && projectedExitDate.format( dateFormat),
            nonConvertibleDebt : clearNoNum({value:nonConvertibleDebtValue}).value,
            vestingType : vestingValue,
            adminId : Utils.getLocalCompanyInfo().admin_id
        };

        //验证
        errorRes = stateData.exitValue > 0 ? false : true;
        this.setState(()=>({
            errorShow : errorRes
        }))
        if(errorRes)
            return;
        this.setState(()=>({
            viewResultsLoading : true,
        }))
        try {
            const { data: resData } = await post('addWaterFall', stateData)
            const { code, data } = resData;
            if (code === 0) {
                message.success('success');
                updateWaterfallModelsList('add', {
                    id : data,
                    name : __LANG.amountSymbol + exitValue + ' exit for ' + (I(projectedExitDate, '') && projectedExitDate.format( dateFormat)),
                    exitValue : __LANG.amountSymbol + exitValue
                });
                detailIdChange(data);
            } else {
                message.error(data)
            }
            this.setState(()=>({
                viewResultsLoading : false
            }))
        } catch (err) {
            this.setState({ loading: false });
            message.error(err && err.message)
        }
    }

    /**
     * 删除waterfall
     * @param id
     * @param index
     * @returns {Promise<void>}
     */
    delModels = async(id, index) => {
        const { waterfallModelsList } = this.state;
        const { loadingChange, getWaterFallList, detailIdChange, updateWaterfallModelsList } = this.props;
        loadingChange('waterfallLoading', true);
        try {
            const { data: resData } = await post('deleteWaterFall', { id:id })
            const { code, data } = resData;
            if (code === 0) {
                message.success('success')
                updateWaterfallModelsList('delete', index);
            } else {
                message.error(data)
            }
            loadingChange('waterfallLoading', false);
        } catch (err) {
            this.setState({ loading: false });
            message.error(err && err.message)
        }
    }

    /**
     * 卸载
     */
    componentWillUnmount() {
        if(this.advanceTermsModal) {
            this.advanceTermsModal.close();
        }
    }

    render() {
        const { loading, errorShow, exitValue, capTableValue, nonConvertibleDebtValue, projectedExitDate,
            dateFormat, __LANG, viewResultsLoading } = this.state;
        const { type, capTableLoading, waterfallLoading, capTableList,
            waterfallModelsList, ...props } = this.props;
        //加载
        if (loading) {
            return <Loading />
        }
        return (
            <Fragment>
                <div className="waterfall-root">
                    <h4 className="mt-10">{__('Create new Scenario model')}</h4>
                    {/*waterfall基础信息录入*/}
                    <WaterfallInput
                        errorShow={errorShow}
                        typeChange={this.typeChange}
                        exitValue={exitValue}
                        exitValueChange={this.exitValueChange}
                        capTableValue={capTableValue}
                        capTableLoading={capTableLoading}
                        capTableValueChange={this.capTableValueChange}
                        nonConvertibleDebtValue={nonConvertibleDebtValue}
                        nonConvertibleDebtValueChange={this.nonConvertibleDebtValueChange}
                        projectedExitDate={projectedExitDate}
                        projectedExitDateChange={this.projectedExitDateChange}
                        capTableList={capTableList}
                        advancedTermsChange={this.advancedTermsChange}
                        saveTerms={this.saveTerms}
                        dateFormat={dateFormat}
                        viewResultsLoading={viewResultsLoading}
                        __LANG={__LANG}
                        history={props.history}
                    />
                </div>
                <div className="waterfall-root mt-20">
                    {/*列表*/}
                    <WaterfallModelsTable
                        waterfallLoading={waterfallLoading}
                        waterfallModelsList={waterfallModelsList}
                        delModels={this.delModels}
                        {...props}
                    />
                </div>

            </Fragment>

        )
    }
}

export default CreateNew;