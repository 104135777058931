import React, { Component } from 'react';
import { Button, Radio } from 'antd';
import './index.scss';

class OperateButton extends Component {
    type = this.props.type
    name = this.props.name
    record = this.props.record

	render () {
        if (this.type === 'radio') {
            return <div className="template-operate-radio" >
                <Radio checked={!!this.record && this.record.checked} data-action="select">{this.name}</Radio>
            </div>
        }
        if (this.type === 'mail') {
            return <div className="template-operate-radio" >
                <a href={`mailto:${this.name}`}>{this.name}</a>
            </div>
        }
		return <div className="template-operate" >
            <Button data-action="preview">{__('Preview')}</Button>
        </div>
	}
}

export default OperateButton;