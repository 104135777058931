import React, { Component } from 'react';
import { Select } from 'antd';
import { observer } from 'mobx-react';
import { cityList} from "Utils/constant";

@observer
export default class CitySelector extends Component {
	render() {
		return <Select showSearch allowClear placeholder="Select city" {...this.props}>
			{
				cityList.map((val, index) => <Select.Option key={index} value={val}>{val}</Select.Option>)
			}
		</Select>
	}
}