export function filterCount(params){
    let count = 0;

    if (typeof params === 'object') {
        const keys = Object.keys(params)

        keys.forEach(value => { 
            if (Array.isArray(params[value]) && params[value]?.length > 0) {
                count = count + 1
            } else { 
                if (typeof params[value] === 'object') {
                    const subKeys = Object.keys(params[value])
                    if (subKeys.find(item => !!params[value][item])) {
                        count = count + 1
                    }
                } else { 
                    if (params[value]) { 
                        count = count + 1
                    }
                }
            }
        })
    }

    return count
}