import React, { Component } from 'react';
import SimpleTable from '../../components/SimpleTable';
import { renderDate } from '../VestingSchedule//column-config';

export default function ShareHolder(props) {
	const { scheduleName, vestingStart, cliff, vesting, fullyVested } = props.data || {}

	const config = [
		{
			label: __('Schedule name'),
			value: scheduleName
		},
		{
			label: __('Vesting start'),
			value: renderDate(vestingStart)
		},
		{
			label: __('Vesting stopped'),
			value: renderDate(fullyVested)
		},
		{
			label: __('Cliff'),
			value: cliff
		},
		{
			label: __('Vesting'),
			value: vesting
		}
	]

	return <div className="share-holder-root">
		<SimpleTable title={__('Summary')} config={config} />
	</div>
}