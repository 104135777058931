import React from 'react';
import { Button, message, Checkbox, Space } from 'antd';
import Loading from 'components/Loading';
import DateSelector from 'components/DateSelector';
import { FormItem, withForm } from 'react-antd-formutil';
import moment from 'moment';
import { post } from 'srcPath/http';
import security from 'stores/security';
import icon from '../../images/icon.png';
import { formatDate2Api } from 'Utils/format/formatDate';
import Modal, { ModalContent } from 'components/Modal';

const CheckboxGroup = Checkbox.Group;

function Captable({ $formutil, getPreviewList }) {
	const { $invalid, $batchDirty, $params, $getFirstError } = $formutil

	const onSubmit = async () => {
		if ($invalid) {
			$batchDirty(true)
			message.error($getFirstError())

			return
		}

		Loading.global(true);
		try {

			const { data: resData } = await post('exportCaptableSummary', { ledger: $params.ledger, is_export: 0 })

			const { data, code } = resData || {}

			if (code === 0) {
				message.success(__('Success'))
				let by_share_class = data.cap_table_by_share_class && data.cap_table_by_share_class
				let previewList = []
				if (by_share_class) {
					let entries = Object.entries(by_share_class)
					for (let [key, value, i] of entries) {
						if (key !== 'Totals') {
							previewList = [...previewList, { 'Share Class Name': key, type: 1, key, children: value,...value.pop() }]
						}
					}
					previewList.push(...by_share_class['Totals'])
					console.log(previewList, 'previewList');
					data.cap_table_by_share_class = previewList
				}

				data.cap_table_by_stakeholder && data.cap_table_by_stakeholder.forEach((item, i) => {
					item.key = i
					item.children = item.security_info
				})
				getPreviewList(data, $params, 'CapTable')
				security.getFileData(1, 2)
			} else {
				message.error(data)
			}

		} catch (e) {
			message.error(e.message)
		}

		Loading.global(false)
	}

	return <div className="sprout-reports-item-container">
		<div className="sprout-reports-item-left">
			<div className="sprout-reports-item-row">
				<div className="sprout-reports-icon">
					<img src={icon} alt="icon" />
				</div>
				<div className="sprout-reports-item-content">
					<div className="title">{__('Cap table')}</div>
					<div className="desc">{__('Select the worksheet tabs to include in your report.')}</div>
					<div className="item-section">
						<FormItem
							validMessage={{ required: 'By share class, By stakeholder, are needed at least one!' }}
							required
							name="ledger"
							$defaultValue={['1']}
						>
							<CheckboxGroup>
								<Space direction="vertical">
									<Checkbox value="1">{__('By share class')}</Checkbox>
									<Checkbox value="3">{__('By stakeholder')}</Checkbox>{/* https://jira.getsprout.co/browse/AP-2102 */}
								</Space>
							</CheckboxGroup>
						</FormItem>
					</div>
				</div>
			</div>
		</div>
		<div className="btns">
			<Space size={15}>
				<span>{__('As of')} {formatDate2Api(moment())}</span>
				{/*<DateSelector bordered={true} disabled format={security.companyInfo && security.companyInfo.date_format} defaultValue={moment()} />*/}
				<Button type="primary" onClick={onSubmit}>{__('Preview')}</Button>
			</Space>
		</div>
	</div>
}

export default withForm(Captable)
