import React from "react";
import { filterAmount} from "Utils";
import { post } from 'srcPath/http';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import security from 'stores/security';
class CreationOfTokenGrantPlan extends React.Component {
    constructor(props){
        super(props);
        this.state={
            isLegalPrefix:1,
            data:{
                coin_id:"",
                total_tokens:"",
                prefix:"",
                name:""
            },
        }
    }
    initData = (key,initData)=>{
        let curData = this.state.data;
        curData.id = initData.id;
        this.setState({
            data:curData
        })
    }
    recordData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        })
    }
    upperPrefix = (e)=>{
        let curData = this.state.data;
        let dataVal = e.target.value;
        curData.prefix = dataVal.toUpperCase();
        this.setState({
            data:curData
        })
        this.checkLegalPrefix();
    }
    /**
     * input change通用事件
     * @param isDecimal 是否需要小数
     * @param e
     * 5 digit
     */
    inputChange5Digit = (isDecimal,e) => {
        const { target: element } = e;
        const { name: dataKey } = element;
        const filter = filterAmount(e, 9999999999999, isDecimal,5);
        let curData = this.state.data;
        curData[dataKey] = filter.value;
        this.setState({
            data: curData
        }, () => {
            element.selectionStart = element.selectionEnd = filter.cursorPositionX;
        });
    }
    dataRefresh=()=>{
        this.setState({
            data: {
                coin_id: "",
                total_tokens: "",
                prefix: "",
                name:""
            }
        })
        this.resetActive();
    }
    resetActive=()=>{
        window.$("#creationOfTokenGrantPlan .tab-pane").removeClass("active");
        window.$("#tokenPlanInput").addClass("active");
    }
    createTokenGrantPlan=()=>{
        const posData = this.state.data;
        post('createTokenOptionPlan',posData).then((response)=> {
            if(response.data.code === 0){
                this.dataRefresh();
                window.$("#creationOfTokenGrantPlan").modal("hide");
                this.props.getTokenPlansPrefixList();
                this.props.getData();
                this.props.getTokenPlanList();
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    checkLegalPrefix = ()=>{
        const { data } = this.state;
        const { existingPrefix } = this.props;
        for(let k=0; k<existingPrefix.length; k++){
            if(data.prefix == existingPrefix[k]){
                this.setState({
                    isLegalPrefix: 0
                })
                break;
            }else{
                this.setState({
                    isLegalPrefix : 1
                })
            }
        }
    }
    render(){
        const { data, isLegalPrefix } = this.state;
        let isFractionalShare = security.companyInfo.fractional_control;
        let requiredTest = 0;
        if(data.coin_id==""||data.name==""||data.prefix=="" || data.total_tokens==""||isLegalPrefix == 0){
            requiredTest = 1;
        }
        const customCoinList = (security.companyInfo && security.companyInfo.custom_coin_list) || []
        let formatCustomCoinList = {};
        for (let i=0; i<customCoinList.length; i++){
            formatCustomCoinList[customCoinList[i].id] = customCoinList[i]
        }
        return(
            <div className="modal fade" id="creationOfTokenGrantPlan" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="modal-dialog tab-content">
                    <div className="modal-content content500 tab-pane active" id="tokenPlanInput">
                        <div className="modal-header box-header">
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Create plan')}</h4>
                        </div>
                        <div className="modal-body padding2040 ">
                            <div className="body-one">
                                <form className="form-horizontal">
                                    {isLegalPrefix == 0 &&
                                    <div>
                                        <div className="alert alert-warning alert-dismissable pink-box" role="alert">
                                            <p>{__('Plan prefix already exists. Please select another.')}</p>
                                        </div>
                                    </div>
                                    }
                                    <div className="stock-titbox token-title-box">
                                        <p>{__('Create plan')}</p>
                                    </div>
                                    <div className="form-group add-items">
                                        <label className="col-xs-6 add-label">{__('Token')}</label>
                                        <div className="col-xs-6">
                                            <select value={this.state.data.coin_id}  onChange={this.recordData}  name="coin_id" className="form-control select-container">
                                                <option value="">--</option>
                                                {customCoinList && customCoinList.map((value,key)=>{
                                                    return(
                                                        <option key={key} value={value.id}>{value.name}</option>
                                                    )
                                                })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group add-items">
                                        <label className="col-xs-6 add-label">{__('Plan name')}</label>
                                        <div className="col-xs-6">
                                            <input type="text" name="name" onChange={this.recordData} value={this.state.data.name} className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="form-group add-items">
                                        <label className="col-xs-6 add-label">{__('Plan prefix')}</label>
                                        <div className="col-xs-6">
                                            <input type="text" name="prefix" onChange={this.recordData} onBlur={this.upperPrefix} value={this.state.data.prefix} className="form-control uppercase_form"/>
                                        </div>
                                    </div>
                                    <div className="form-group add-items">
                                        <label className="col-xs-6 add-label">{__('Total number of tokens')}</label>
                                        <div className="col-xs-6">
                                            { isFractionalShare == 1 ?
                                            <input type="text" name="total_tokens" onChange={this.inputChange5Digit.bind(this, true)} value={this.state.data.total_tokens} className="form-control"/> :
                                            <input type="text" name="total_tokens" onChange={this.inputChange5Digit.bind(this, false)} value={this.state.data.total_tokens} className="form-control"/>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer box-footer invite-footer">
                            {requiredTest==1 &&
                            <button type="button" className="btn boxbtn-to width200 send-disabledBtn ">{__('Next: Preview')}</button>
                            }
                            {requiredTest==0 &&
                            <button type="button" data-toggle="tab" data-target="#reviewTokenGrantPlan" className="btn boxbtn-to bg-darkblue update-btn">{__('Next: Preview')}</button>
                            }
                        </div>
                    </div>
                    <div className="modal-content content500 tab-pane " id="reviewTokenGrantPlan">
                        <div className="modal-header box-header">
                            <a data-target="#tokenPlanInput" className="back-btn" aria-controls="repurchase-step1" role="tab" data-toggle="tab"></a>
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.dataRefresh}>×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Confirm changes')}</h4>
                        </div>
                        <div className="modal-body padding2026">
                            <div className="body-one">
                                <div className="shareTitleBox marginBott10">
                                    <div className="iconBox bg-darkblue">
                                        <span className="glyphicon glyphicon-list-alt"></span>
                                    </div>
                                    <div className="shareTextBox">
                                        <p className="shareTitle marginTop6">{__('Preview')}</p>
                                    </div>
                                </div>
                                <table className="table merge-table review-relationship-table no-marginBottom ">
                                    <tbody>
                                    <tr>
                                        <td>{__('Token')}</td>
                                        <td>{data.coin_id!="" ? formatCustomCoinList[data.coin_id].name :"--" }</td>
                                    </tr>
                                    <tr>
                                        <td>{__('Plan name')}</td>
                                        <td>{data.name}</td>
                                    </tr>
                                    <tr>
                                        <td>{__('Plan prefix')}</td>
                                        <td>{(data.prefix).toUpperCase()}</td>
                                    </tr>
                                    <tr>
                                        <td>{__('Total number of tokens')}</td>
                                        <td>{formatFractionalNumber(data.total_tokens)}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer box-footer invite-footer">
                            <button type="button" onClick={this.createTokenGrantPlan} className="btn boxbtn-to bg-darkblue update-btn" >{__('Save')} </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default CreationOfTokenGrantPlan;