import React, { Component, Fragment} from "react";
import {Button, Dropdown, Menu, message, Tooltip} from "antd";
import './style.scss';
import { DownOutlined } from '@ant-design/icons';
import Modal, { ModalContent } from 'components/Modal';
import { post } from 'srcPath/http';
import { I } from 'Utils';
import InformationModels from './components/InformationModels';
import RelationshipModels from './components/RelationshipModels';
import ReviewStakeholderModels from './components/ReviewStakeholderModels';
import CompleteStakeholderMergeModels from './components/CompleteStakeholderMergeModels';
import { formatDate2Api } from 'Utils/format/formatDate';
import moment from "moment";
import security from 'stores/security';
import BulkTerminateStakeholders from "../TerminateStakeholder/components/BulkTerminateStakeholders";

class MergeStakeholdersModels extends Component {

    InformationModels = null;
    RelationshipModels = null;
    ReviewStakeholderModels = null;
    CompleteStakeholderMergeModels = null;

    state = {
        helpStr : __('You can only merge two stakeholders at a time.'),
        //初始化需用户填写的信息字段
        stakeholderData : {
            stakeholderType : '',
            nick_name : '',
            email : '',
            securities : '',
            idNumber : '',
            employeeId : '',
            address : '',
            country : '',
            birthday : '',
            relationship : '',
            job_title: '',
            id: '',
            mergeId: '',
            contract_email: '',
        },
        attr : [
            { name : __('Type'), key : 'stakeholderTypeValue', type : 4, stakeholderDataKey:'stakeholderType'},
            { name : <span>{__('Name')}<i><small className="text-muted"> - {__('Required')}</small></i></span>, key : 'nickName', type : 6, stakeholderDataKey:'nick_name', isRequired: true },
            { name : <span>{__('Email')}<i><small className="text-muted"> - {__('Required')}</small></i></span>, key : 'email', type : 6, stakeholderDataKey:'email', checkType:'email', isRequired: true },
            // { name : <span>Contact email<i><small className="text-muted"> - Required</small></i></span>, key : 'contract_email', type : 1, stakeholderDataKey:'contract_email', checkType:'email' },
            { name : __('Securities'), key : 'securities', type : 7, stakeholderDataKey:'securities'},
            { name : __('Passport or National ID'), key : 'idNumber', type : 1, stakeholderDataKey:'idNumber'},
            { name : __('Employee ID'), key : 'employeeId', type : 1, stakeholderDataKey:'employeeId'},
            { name : __('Address'), key : 'address', type : 1, stakeholderDataKey:'address'},
            { name : __('Country'), key : 'country', type : 3, stakeholderDataKey:'country'},
            { name : __('Date of birth'), key : 'birthday', type : 2, stakeholderDataKey:'birthday'},
            { name : __('Gender'), key : 'gender', type : 5, stakeholderDataKey:'gender'},
            { name : __('Annual salary'), key : 'annual_salary', type : 1, stakeholderDataKey:'annualSalary'},
        ],
        stakeholdersType : [
            { type : 1, name : 'Individual' },
            { type : 0, name : 'Non-individual' }
        ],
        genderList: [
            {
                name: 'Female'
            },
            {
                name: 'Male'
            },
            {
                name: 'Non-binary / non-conforming'
            },
            {
                name:'Prefer not to respond'
            },
        ],
    }

    /**
     * 卸载
     */
    componentWillUnmount() {
        this.InformationModels && this.InformationModels.close();
        this.RelationshipModels && this.RelationshipModels.close();
        this.ReviewStakeholderModels && this.ReviewStakeholderModels.close();
        this.CompleteStakeholderMergeModels && this.CompleteStakeholderMergeModels.close();
        this.BulkTerminateStakeholders && this.BulkTerminateStakeholders.close();
    }

    /**
     * 重置merge stakeholders属性
     */
    initStakeHolderData = () => {
        const { stakeholderData } = this.state;
        for(let i in stakeholderData){
            stakeholderData[i] = '';
        }
        this.setState(() => ({
            stakeholderData
        }))
    }

    /**
     * Merge Stakeholders Information 弹层
     */
    mergeStakeholders = () => {
        const { stakeholderData, attr, stakeholdersType, genderList } = this.state;
        const { holderLists } = this.props;
        this.InformationModels = Modal.open({
            component: props => <InformationModels
                close={props.close}
                holderLists={holderLists}
                getRelationshipModels={this.getRelationshipModels}
                mergeRequestData={this.mergeRequestData}
                stakeholderData={stakeholderData}
                attr={attr}
                maskClosable={false}
                initStakeHolderData={this.initStakeHolderData}
                stakeholdersType={stakeholdersType}
                genderList={genderList}
            />,
            width: 1000,
            maskClosable: false,
            className: 'sprout-modal'
        })
    }

    /**
     * Merge Stakeholders Relationship 弹层
     */
    getRelationshipModels = () => {
        const { stakeholderData } = this.state;
        const { holderLists } = this.props;
        this.RelationshipModels = Modal.open({
            component: props => <RelationshipModels
                close={props.close}
                holderLists={holderLists}
                getReviewStakeholderModels={this.getReviewStakeholderModels}
                stakeholderData={stakeholderData}
                mergeRequestData={this.mergeRequestData}
                mergeStakeholders={this.mergeStakeholders}
                initStakeHolderData={this.initStakeHolderData}
            />,
            width: 600,
            maskClosable: false,
            className: 'sprout-modal'
        })
    }

    /**
     * 确认页 弹层
     */
    getReviewStakeholderModels = () => {
        const { stakeholderData, attr, stakeholdersType } = this.state;
        this.ReviewStakeholderModels = Modal.open({
            component: props => <ReviewStakeholderModels
                close={props.close}
                stakeholderData={stakeholderData}
                getCompleteStakeholderMergeModels={this.getCompleteStakeholderMergeModels}
                getRelationshipModels={this.getRelationshipModels}
                attr={attr}
                initStakeHolderData={this.initStakeHolderData}
                stakeholdersType={stakeholdersType}
            />,
            width: 600,
            maskClosable: false,
            className: 'sprout-modal'
        })
    }

    /**
     * 签名页 弹层
     */
    getCompleteStakeholderMergeModels = () => {
        const  { saveLoading } = this.state;
        this.CompleteStakeholderMergeModels = Modal.open({
            component: props => <CompleteStakeholderMergeModels
                close={props.close}
                saveStakeholderData={this.saveStakeholderData}
                getReviewStakeholderModels={this.getReviewStakeholderModels}
                initStakeHolderData={this.initStakeHolderData}
                saveLoading={saveLoading}
            />,
            width: 600,
            maskClosable: false,
            className: 'sprout-modal'
        })
    }

    /**
     * Terminate Stakeholders 弹层
     */
    terminateStakeholders = ( ) => {
        const { stakeholderData, attr, stakeholdersType, genderList } = this.state;
        const { holderLists, forceUpdates } = this.props;
        this.BulkTerminateStakeholders = Modal.open({
            component: props => <BulkTerminateStakeholders
                close={props.close}
                holderLists={holderLists}
                getRelationshipModels={this.getRelationshipModels}
                mergeRequestData={this.mergeRequestData}
                stakeholderData={stakeholderData}
                attr={attr}
                maskClosable={false}
                initStakeHolderData={this.initStakeHolderData}
                stakeholdersType={stakeholdersType}
                genderList={genderList}
                forceUpdates={forceUpdates}
            />,
            width: 1200,
            maskClosable: false,
            className: 'sprout-modal'
        })
    }

    /**
     * 获取已选择的stakeholders ID
     * @param holderLists
     * @returns {[]}
     */
    getUseStakeholders = (holderLists) => {
        let resList = [];
        holderLists.forEach( (v, k) => {
            if(I(v.status, 0) == 1){
                resList.push(v.id);
            }
        })
        return resList;
    }

    /**
     * 保存 merge stakeholder 数据
     */
    saveStakeholderData = (close) => {
        this.saveMergeStakeholders(close);
    }

    /**
     * merge stakeholder提交服务端
     * @param close
     * @returns {Promise<void>}
     */
    async saveMergeStakeholders(close) {
        const { stakeholderData, stakeholdersType } = this.state;
        const { holderLists, forceUpdates } = this.props;
        //获取已选择的stakeholders
        const ids = this.getUseStakeholders(holderLists);
        let attrData = {...stakeholderData,stakeholder_id1:ids[0],stakeholder_id2:ids[1]};
        //日历内容转字符串
        attrData.birthday = I(attrData.birthday, '') ? formatDate2Api(attrData.birthday) : '';
        attrData.relationship = this.dateToStr(attrData.relationship);
        attrData.job_title = this.dateToStr(attrData.job_title);
        //stakeholder type 转数字标识
        const typeIndex = stakeholdersType.findIndex(v=>v.name==attrData['stakeholderType']);
        attrData['stakeholderType'] = typeIndex !== -1 ? stakeholdersType[typeIndex]['type'] : 0;
        try {
            const { data: resData } = await post('mergeStakeholders', attrData);
            const { code, data } = resData;
            if (code === 0) {
                message['success']('Stakeholders merged!');
                close();
                forceUpdates();
                this.clearStakeholderData();
            } else {
                message.error(data)
            }
        } catch (err) {
            this.setState({ loading: false });
            message.error(err && err.message)
        }
    }

    /**
     * 清空 merge stakeholder 历史数据
     */
    clearStakeholderData = () => {
        const stakeholderData = { stakeholderType : '', nick_name : '', email : '', securities : '', idNumber : '', employeeId : '', address : '', country : '', birthday : '', relationship : '', job_title: '', id: '', mergeId: '', contract_email: '' };
        this.setState({stakeholderData});
    }

    dateToStr = (dataList) => {
        const newDataList = [...dataList];
        newDataList.forEach( (v, k) => {
            const effectiveDate = I(v.effective_date, '');
            newDataList[k].effective_date = effectiveDate ? formatDate2Api(effectiveDate.format()) : formatDate2Api(moment().format());
        });
        return newDataList;
    }

    /**
     * 更新用户录入的数据
     * @param data
     */
    mergeRequestData = (data, callback) => {
        const { stakeholderData } = this.state;
        let attrData = {...stakeholderData};
        for (let i in data){
            attrData[i] = data[i];
        }
        this.setState({
            stakeholderData : attrData
        }, ()=>{
            callback && callback();
        })
    }

    /**
     * actions 菜单
     * @returns {JSX.Element}
     */
    actionsMenu = (selectCount, inviteCount) => {
        const { helpStr } = this.state;
        const userAccessControl = security.companyInfo && security.companyInfo.user_access_control;
        return (
            <Menu>
                {selectCount > 2 || selectCount < 2 ?
                <Menu.Item disabled onClick={this.mergeStakeholders}>
                    <Tooltip title={helpStr} placement="right" color="#2b3946">{__('Merge stakeholders')}</Tooltip>
                </Menu.Item> :
                <Menu.Item onClick={this.mergeStakeholders}>{__('Merge stakeholders')}</Menu.Item>}
                <Menu.Item disabled={inviteCount <= 0} onClick={this.resendStakeholderEmailModel.bind(this, userAccessControl)} >{__('Invite selected')}</Menu.Item>
                <Menu.Item disabled={ selectCount > 0 ? false : true } onClick={this.terminateStakeholders} >{__('Terminate stakeholder')}</Menu.Item>
            </Menu>
        )
    }

    resendStakeholderEmailModel = (userAccessControl) => {
        const { resendStakeholderEmailModel } = this.props;
        resendStakeholderEmailModel(userAccessControl, null, 1);
    }

    render() {
        const { selectCount, inviteCount } = this.props;
        return (
            <div className="action-box onset">
                <div className="btn-group">
                    <Dropdown className="shares-actions" overlay={this.actionsMenu.bind(this, selectCount, inviteCount)}>
                        <Button
                            type="primary"
                            className="merge-stakeholder-actions"
                        ><div className="actions-left">{__('Actions')}</div><DownOutlined className="actions-right" /></Button>
                    </Dropdown>
                </div>
            </div>
        );
    }
}
export default MergeStakeholdersModels;