import React, { Component, Fragment } from "react";
import {Spin, Button, message} from "antd";
import OptionPoolModels from "./components/OptionPoolModels";
import ConvertibleNoteMethodologyModels from "./components/ConvertibleNoteMethodologyModels";
import SeniorityDetailsModels from "./components/SeniorityDetailsModels";
import RightsAndPreferencesModels from "./components/RightsAndPreferencesModels";
import DividendsModels from "./components/DividendsModels";
import Utils, { setCursorPos, I, clearNoNum } from "Utils";
import Loading from 'components/Loading'
import { post } from 'srcPath/http';
import './style.scss'

class AdvanceTermsModels extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            submitLoading : false,
            dataLoading : true,
            //初始化 advanceTerms
            advanceTerms : {
                // 1:Post-money, 2:Pre-money,
                optionPool : 1,
                // 1:post financing, 2:pre financing
                conversionMethod : 1,
                seniority : '1',
                //Pari-passu: checkbox，default:0, checked:1
                pariPassu : 1,
                seniorityDetailsTable : [],
                multiplier : '',
                //Participating preferred: checkbox，default:0, checked:1
                participatingPreferred : 0,
                //participatingPreferred=1时participatingPreferredCap有效
                participatingPreferredCap : '',
                //Dividend type, 0:Cumulative, 1:Non-Cumulative 等于1的时候 dividendYield、dividendAccrual、compoundingInterest无效
                dividendType : 2,
                dividendYield : '',
                dividendAccrual : 1,
                compoundingInterest : 1,
                dividendAccrualList : [],
                dividendAccrualListList : []
            }
        }
    }

    componentDidMount() {
        this.getData();
    }

    /**
     * 保存 advanceTerms数据
     */
    advanceTermsSubmit = () => {
        const { advanceTerms } = this.state;
        const { advanceTermsSubmit, k, close } = this.props;
        advanceTermsSubmit( advanceTerms, k, close);
    }

    /**m
     * 序列化Advanced Terms 数据
     */
    getData = () => {
        let {advanceTerms} = this.state;
        const {roundNameValue} = this.props;
        const propsAdvanceTerms = this.props.advanceTerms;
        advanceTerms = Object.keys(propsAdvanceTerms).length > 0 ? propsAdvanceTerms : advanceTerms
        this.getSeniorityDetails(advanceTerms, roundNameValue);
    }

    /**
     * 获取后端advanced terms数据
     * @param advanceTerms
     * @param roundNameValue
     */
    getSeniorityDetails = async (advanceTerms, roundNameValue) => {
        Loading.global(true);
        try {
            const { data: resData } = await post('getSeniorityDetails')
            const { code, data } = resData || {}
            if (code == 0) {
                this.initSeniorityDetails(data, advanceTerms, roundNameValue);
            } else {
                message.error(data)
            }
        } catch(err) {
            console.log(err);
            message.error(err && err.message)
        }
        Loading.global(false);
    }

    initSeniorityDetails = (data, advanceTerms, roundNameValue) => {
        let detailsTable = [];
        data.forEach( (v, k) => {
            detailsTable.push({
                name : v.shareClassName,
                seniority : v.level,
                seniorityNew : v.level,
                payoutOrder : v.payoutOrder,
                keyId : k + Date.now()
            });
        })
        //用户定义的行数据
        detailsTable.push({
            name : roundNameValue,
            seniority : advanceTerms.seniority,
            seniorityNew : advanceTerms.seniority,
            payoutOrder : '',
            keyId : detailsTable.length + Date.now(),
            isNew : 1
        });
        advanceTerms.seniorityDetailsTable = this.seniorityDetailsOrderBy(detailsTable, I(advanceTerms.seniority, '1'), advanceTerms.pariPassu);
        this.setState({
            advanceTerms,
            loading : false,
            dataLoading : false
        })
    }


    /**
     * 表格排序
     * @param detailsTable
     * @returns {*}
     */
    tableOrderBy = (detailsTable) => {
        //根据 seniority 重新排序
        detailsTable.sort( (x, y) => {
            return x['seniority'] - y['seniority'];
        });
        let payoutOrder = 1;
        detailsTable.forEach( (v, k) => {
            if(k == 0){
                detailsTable[k]['payoutOrder'] = 1;
            }else{
                if(v['seniority'] == detailsTable[k-1]['seniority']){
                    detailsTable[k]['payoutOrder'] = detailsTable[k-1]['payoutOrder'];
                }else{
                    detailsTable[k]['payoutOrder'] = I(detailsTable[k-1]['payoutOrder'], 1) + 1;
                }
            }
        })
        return detailsTable;
    }

    /**
     * optionPoolChange checked
     * @param val
     */
    optionPoolChange = (val) => {
        let advanceTerms = this.state.advanceTerms;
        advanceTerms['optionPool'] = val;
        this.setState({
            advanceTerms : advanceTerms
        })
    }

    /**
     * conversionMethodChange checked
     * @param val
     */
    conversionMethodChange = (val) => {
        let advanceTerms = this.state.advanceTerms;
        advanceTerms['conversionMethod'] = val;
        this.setState({
            advanceTerms : advanceTerms
        })
    }

    /**
     * seniorityChange checked
     * @param obj
     */
    seniorityChange = (filter, element) => {
        const { advanceTerms } = this.state;
        const { seniorityDetailsTable, pariPassu } = advanceTerms;
        advanceTerms['seniority'] = filter.value;
        advanceTerms['seniorityDetailsTable'] = this.seniorityDetailsOrderBy(seniorityDetailsTable, filter.value, pariPassu);
        this.setState({
            advanceTerms : advanceTerms
        }, () => {
            //恢复光标位置
            setCursorPos(element, filter.cursorPositionX);
        })
    }

    /**
     * pariPassuChange checked
     * @param val
     */
    pariPassuChange = (val) => {
        const { advanceTerms } = this.state;
        const { seniorityDetailsTable, seniority } = advanceTerms;
        advanceTerms['pariPassu'] = val;
        advanceTerms['seniorityDetailsTable'] = this.seniorityDetailsOrderBy(seniorityDetailsTable, seniority, val);
        this.setState({
            advanceTerms : advanceTerms
        })
    }

    /**
     * 序列化 Seniority details Table
     * @param seniorityDetailsTable
     * @param seniority
     * @param pariPassu
     * @returns {*}
     */
    seniorityDetailsOrderBy = ( seniorityDetailsTable, seniority, pariPassu) => {
        const str = {1:'st', 2:'nd', 3:'rd', 4:'th'};
        //删除用户自定义数据,目的是为了方便排序
        let newObject = {};

        seniorityDetailsTable.forEach( ( v, k) => {
            if(I(v.isNew, 0) == 1){
                seniorityDetailsTable[k]['seniority'] = clearNoNum({value: I(seniority, '1').toString() }).value;
                seniorityDetailsTable[k]['seniorityNew'] = seniorityDetailsTable[k]['seniority'];
                newObject = seniorityDetailsTable[k];
                seniorityDetailsTable.splice( k, 1);
            }
        });
        //在末端新增用户自定义数据
        seniorityDetailsTable.push(newObject);
        //排序
        seniorityDetailsTable = this.tableOrderBy(seniorityDetailsTable);
        let indexKey = -1;
        if(pariPassu != 1){
            seniorityDetailsTable.forEach( ( v, k) => {
                if(parseInt(I(v.seniority, 1)) >= I(seniority, 1) && I(v.isNew, 0) != 1){
                    seniorityDetailsTable[k]['payoutOrder'] = (parseInt(I(seniorityDetailsTable[k]['payoutOrder'], 1)) + 1) ;
                }
            })
            seniorityDetailsTable.sort( (x, y) => {
                return x['payoutOrder'] - y['payoutOrder'];
            });
            seniorityDetailsTable.forEach( ( v, k) => {
                indexKey = v.isNew == 1 ? k : indexKey;
                if(k >= indexKey && I(v.isNew, 0) != 1 && indexKey != -1){
                    const seniorityValue = parseInt(v.seniority) + 1;
                    seniorityDetailsTable[k]['seniorityNew'] = seniorityValue;
                }else{
                    seniorityDetailsTable[k]['seniorityNew'] = v.seniority;
                }
            });
        }else{
            //恢复初始数据
            seniorityDetailsTable.forEach( ( v, k) => {
                seniorityDetailsTable[k]['seniorityNew'] = v.seniority;
            })
        }
        //Payout Order加后缀内容
        seniorityDetailsTable.forEach( ( v, k) => {
            const index = seniorityDetailsTable[k]['payoutOrder'] > 4 ? 4 : seniorityDetailsTable[k]['payoutOrder'];
            seniorityDetailsTable[k]['payoutOrder'] += str[index];
        });
        return seniorityDetailsTable;
    }

    /**
     * multiplier change 事件
     * @param e
     */
    multiplierChange = ( obj, element) => {
        let { advanceTerms } = this.state;
        advanceTerms.multiplier = obj.value;
        this.setState({
            advanceTerms
        }, () => {
            setCursorPos( element, obj.pos);
        })
    }

    participatingPreferredChange = (val) => {
        let { advanceTerms } = this.state;
        advanceTerms.participatingPreferred = val;
        this.setState({
            advanceTerms
        })
    }

    participatingPreferredCapChange = (obj, element) => {
        let { advanceTerms } = this.state;
        advanceTerms.participatingPreferredCap = obj.value;
        this.setState({
            advanceTerms
        }, () => {
            setCursorPos( element, obj.cursorPositionX);
        })
    }

    dividendTypeChange = (val) => {
        let { advanceTerms } = this.state;
        advanceTerms.dividendType = val;
        this.setState({
            advanceTerms
        })
    }

    dividendYieldChange = (obj, element) => {
        let { advanceTerms } = this.state;
        advanceTerms.dividendYield = obj.value;
        this.setState({
            advanceTerms
        }, () => {
            setCursorPos( element, obj.cursorPositionX);
        })
    }

    dividendAccrualChange = (val) => {

        let { advanceTerms } = this.state;
        advanceTerms.dividendAccrual = val;
        this.setState({
            advanceTerms
        })
    }

    compoundingInterestChange = (val) => {
        let { advanceTerms } = this.state;
        advanceTerms.compoundingInterest = val;
        this.setState({
            advanceTerms
        })
    }

    render() {
        const { loading, submitLoading, dataLoading, advanceTerms } = this.state;
        const { close, roundNameValue, k } = this.props;
        return (
            <Fragment>
                <div className="advance-model-root">
                    <h5 className="advance-model-title">{__('Advanced Terms')}</h5>
                    <div className="advance-model-close " onClick={close} >×</div>
                </div>
                <OptionPoolModels
                    optionPool={advanceTerms.optionPool}
                    optionPoolChange={this.optionPoolChange}
                />
                <ConvertibleNoteMethodologyModels
                    conversionMethod={advanceTerms.conversionMethod}
                    conversionMethodChange={this.conversionMethodChange}
                />
                <SeniorityDetailsModels
                    seniority={advanceTerms.seniority}
                    pariPassu={advanceTerms.pariPassu}
                    seniorityChange={this.seniorityChange}
                    pariPassuChange={this.pariPassuChange}
                    loading={dataLoading}
                    seniorityDetailsTable={advanceTerms.seniorityDetailsTable}
                    roundNameValue={roundNameValue}
                />
                <RightsAndPreferencesModels
                    multiplier={advanceTerms.multiplier}
                    multiplierChange={this.multiplierChange}
                    participatingPreferred={advanceTerms.participatingPreferred}
                    participatingPreferredChange={this.participatingPreferredChange}
                    participatingPreferredCap={advanceTerms.participatingPreferredCap}
                    participatingPreferredCapChange={this.participatingPreferredCapChange}
                />
                <DividendsModels
                    dividendType={advanceTerms.dividendType}
                    dividendTypeChange={this.dividendTypeChange}
                    dividendYield={advanceTerms.dividendYield}
                    dividendYieldChange={this.dividendYieldChange}
                    dividendAccrual={advanceTerms.dividendAccrual}
                    dividendAccrualChange={this.dividendAccrualChange}
                    compoundingInterest={advanceTerms.compoundingInterest}
                    compoundingInterestChange={this.compoundingInterestChange}
                />
                <div className="advance-model-save-button">
                    <Button loading={submitLoading} onClick={this.advanceTermsSubmit.bind(this, close)} className="advance-model-submit" type="primary">{__('Save Advanced Terms')}</Button>
                </div>
            </Fragment>
        )
    }
}

export default AdvanceTermsModels