import React, { Component } from 'react';
import Modal from 'components/Modal';
import { Button, message, Table, Radio } from 'antd';
import { post } from 'srcPath/http';
import classnames from 'classnames'
import { LeftOutlined, CloseOutlined } from '@ant-design/icons';
import { accessLevelMap } from 'Utils/constant';
import security from 'stores/security';
import { openPreviewModal } from '../PreviewModal';

import icon0 from './images/icon-no.png';
import icon1 from './images/icon-0.png';
import icon2 from './images/icon-1.png';
import icon3 from './images/icon-2.png';
import icon4 from './images/icon-3.png';
import icon5 from './images/icon-4.png';

import './style.scss';

const levelArray = [
	{
		name: __('No Access'),
		value: '0',
        message: __('Stakeholders here will not have account access and see their holdings and other information about this company.'),
		icon: icon0
	},	
	{
		name: __('Basic view'),
		value: '1',
        message: __('Shows types of equity securities owned without displaying the percentage of company ownership.'),
		icon: icon1
	},
	{
		name: __('Individual view'),
		value: '2',
		message: __('Fully diluted ownership view for a single stakeholder.'),
		icon: icon2
	},
	{
		name: __('Summary view'),
		value: '3',
		message: __('Share class view. Includes option strike price details.'),
		icon: icon3
	},
	{
		name: __('Intermediate view'),
		value: '4',
		message: __('Detailed view for preferred stakeholders. Share class view for non-preferred.'),
		icon: icon4
	},
	{
		name: __('Detailed view'),
		value: '5',
		message: __('Detailed information view for all stakeholders and securities.'),
		icon: icon5
	}
]

function LevelItem(props) {
	const { icon, value, name, message } = props.data

	const active = value === props.value;
	return <div className="access-level-item" onClick={ () => props.onChange(value)}>
		<img className={classnames('icon', { active: active })} src={icon} />
		<div className="btn-wrap">
			{value !== '0' && <Button size="small" type="primary" onClick={() => openPreviewModal({ value, name })}>{__('Preview')}</Button>}
		</div>
		<Radio checked={active}><span className="name">{name}</span></Radio>
		<div className="message">{message}</div>
	</div>
}

class AccessLevelModal extends Component {
	state = {
		step2: false,
		accessLevel: (this.props.data && this.props.data.length === 1) ? this.props.data[0].ownerShip : undefined
	}

	getColumns = () => ([
		{
			title: __('Name'),
			dataIndex: 'stakeHolderName',
			fixed: true,
			ellipsis: true
		},
		{
			title: __('Ownership'),
			dataIndex: 'ownerShip',
			render: text => accessLevelMap[this.state.accessLevel] || '--'
		}
	])

	onSubmit = async () => {
		const { close, callback, data = [] } = this.props
		const { accessLevel } = this.state

		this.setState({ loading: true })

		const { data: resData } = await post('setStakeHolderAccess', {
			ownerShip: accessLevel,
			stakeHolderIds: data.map(val => val.stakeHolderId).filter(Boolean)
		})

		const { code, data: resMessage } = resData

		if(code === 0) {
			message.success('Successfully')

			callback && callback()
			await security.getStatus()
			close && close()
			this.setState({ loading: false })
		} else {
			message.error(resMessage)
		}

		this.setState({ loading: false })
	}

	handleLevelChange = val => {
		this.setState({ accessLevel: val })
	}

	goStep2 = () => {
		const { accessLevel } = this.state

		if (!accessLevel) {
			message.error('Please select Ownership Access!')

			return
		}

		this.setState({
			step2: true
		})
	}

	render() {
		const { loading, step2, accessLevel} = this.state
		const { close, data } = this.props
		const showToken = (security.companyInfo && security.companyInfo.is_show_coin) === '1'

		return <div className="set-access-root">
			<div className="sprout-modal-content">
				<div className="sprout-modal-header">
					{ step2 && <LeftOutlined className="set-access-back" onClick={() => this.setState({ step2: false })} />}
					{ step2 ? __('Set access') : __('Ownership Access1') }
					<span className="sprout-modal-close" onClick={close}><CloseOutlined/></span>
				</div>
				<div className="sprout-modal-detail">
					<div className={classnames('set-access-content', { 'set-access-content-transform': step2 })}>
						<div className="set-access-content-front">
							<div>
								<div className="tips">{__('There are six levels of access to the Cap Table you can grant')}.</div>
								<div className="access-level-wrap">
									{levelArray.map((val, index) => <LevelItem value={accessLevel} key={index} data={val} onChange={this.handleLevelChange} />)}
								</div>
							</div>
							{
								showToken && <div className="tips">* {__('if token is enabled. Includes token holdings')}</div>	
							}
							<div className="set-access-btns">
								<Button onClick={this.goStep2} type="primary">{__('Review and set access')}</Button>
							</div>
						</div>
						<div className="set-access-content-end">
							<div>
								<div className="tips">{__('No emails will be sent to stakeholders when their access level is changed')}.</div>
							<Table
								columns={this.getColumns()}
								rowKey="stakeHolderId"
								dataSource={data}
								pagination={false}
								scroll={{ y: 280 }}
								size="small"
							/>
							</div>
							<div className="set-access-btns">
								<Button loading={loading} type="primary" onClick={this.onSubmit}>{__('Confirm')}</Button>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	} 
}

export default function openAccessLevelModal(data, callback) {
	return Modal.open({
		component: <AccessLevelModal data={data} callback={callback} />,
		width: 1050,
		className: 'sprout-modal'
	})
}