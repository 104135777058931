import React, { Fragment } from 'react';
import SimpleTable from '../components/SimpleTable';
import HeaderTips from '../components/HeaderTips';
import { formatCurrencyNumber } from 'Utils'
import { formatDate } from 'Utils/format/formatDate';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import formatExercisePrice from 'Utils/format/formatExercisePrice';
import { certificateStatus } from 'Utils/constant';

export default function Certificate(props) {
	const { quantity, exercisedQuantity, purchase_price, status, name, email, remainningQuantity, certificateList, formatText, exercisePrice, boardApprovedDate, issuedBy, issueDate, exercisesInto, certificateHistory } = props.data || {}
	const config = [
		{
			label: __('Issued quantity'),
			value: formatFractionalNumber(quantity)
		},
		{
			label: __('Exercised quantity'),
			value: formatFractionalNumber(exercisedQuantity)
		},
		{
			label: __('Remaining quantity'),
			value: formatFractionalNumber(remainningQuantity)
		},
		{
			label: __('Purchase price'),
			value: formatCurrencyNumber(purchase_price)
		},
		{
			label: __('Exercise price'),
			value: formatExercisePrice(exercisePrice)
		},
		{
			label: __('Exercises into'),
			value: exercisesInto
		}
	]

	const issuerConfig = [
		{
			label: __('Issued by'),
			value: issuedBy
		},
		{
			label: __('Issue date'),
			value: formatDate(issueDate)
		},
		{
			label: __('Board approved date'),
			value: formatDate(boardApprovedDate)
		}		
	]

	const stateConfig = [
		{
			label: __('Name'),
			value: name
		},
		{
			label: __('Email'),
			value: email
		},
		{
			label: __('Status'),
			value: certificateStatus[status]
		}
	]

	if (certificateHistory?.length > 0) {
		// TODO
		certificateHistory.map((item) => {
			const statusText = item.type !== 'Canceled' ?  ( item.type === 'Settled' ? 'Settle' : (item.type ==='Transferred' ? 'Transfer' : ( item.type ==='Converted' ? 'Convert' : item.type.replace('d', ''))) ) : 'Cancellation'
			issuerConfig.push({
				label: `${statusText} date for ${formatFractionalNumber(item.share)} shares ${item.source || 'from'} ${item.certificatesName}`,
				value: formatDate(item.date)
			})
		})
	}

	return <Fragment>
		{certificateList && certificateList.length && formatText &&
			<HeaderTips data={{certificateList, formatText}} />
		}
		 <div className="certificate-detail-section">
			<SimpleTable title={__('Warrant holder')} config={stateConfig} />
		</div>
		 <div className="certificate-detail-section">
			<SimpleTable title={__('Quantities')} config={config} />
		</div>
		<div className="certificate-detail-section">
			<SimpleTable title={__('Issuer')} config={issuerConfig} />
		</div>
	</Fragment>
}