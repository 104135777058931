import openCertificateModal, { openTokenWarrant, openGrantDetail } from 'components/CertificateDetail';
import security from 'stores/security';

const funcMap = { 
    COIN_WARRANT: openTokenWarrant,
    COIN_GRANT: openGrantDetail
}

export function getOpenDetailFunc(security_type) {
    const func = funcMap[security_type] || openCertificateModal

    return func
}

export const getMenuConfig = () => { 
		const { taskDetail = {} } = security.task || {}

		const menuConfig = [
			{
				title: __('Accept certificates'),
				path: '/task/certificate',
				disabled: false,
				access: true,
				children: null,
				task: Number(taskDetail.acceptCertificates)
			},
			{
				title: __('Board consents'),
				path: '/task/board',
				disabled: false,
				access: true,
				task: Number(taskDetail.boardConsents)

			},
			{
				title: __('Sign certificate'),
				path: '/task/sign',
				disabled: false,
				access: true,
				task: Number(taskDetail.signCertificates)
			}		
		]

		return menuConfig.filter(val => val.task > 0)
	}