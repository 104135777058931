import React, { Component } from 'react';
import { Button, message, Input } from 'antd';
import classnames from 'classnames';
import { post } from 'srcPath/http';
import { cloneDeep } from 'lodash';
import Utils, { I, filterAmount } from "Utils";
import RelationshipSelector from 'components/RelationshipSelector';
import moment from "moment";
import DateSelector from "components/DateSelector";

import './addNewWallet.scss'

export default class AddNewRelationShip extends Component {
    state = {}

    handleInputChange = (index, e) => {
        const newWalletList = cloneDeep(this.props.data)
        newWalletList[index].address = e.target.value
        this.props.onChange(newWalletList)
    }

    /**
     * relationshipEffctiveDateChange
     * @param name
     * @param key
     * @param date
     */
    relationshipEffctiveDateChange = ( name, index, date) => {
        const newWalletList = cloneDeep(this.props.data)
        newWalletList[index].effective_date = moment(date).format('DD/MM/YYYY')
        this.props.onChange(newWalletList)
    }

    setRelation=(index,e)=>{
        const newWalletList = cloneDeep(this.props.data)
        newWalletList[index].relationship = e
        this.props.onChange(newWalletList)
    }

    addWallet = () => {
        const newWalletList = cloneDeep(this.props.data)
        newWalletList.push({
            effective_date: '',
            relationship: 'Advisor'
        })
        this.props.onChange(newWalletList)
    }

    removeWallet = (index) => {
        const newWalletList = cloneDeep(this.props.data)
        newWalletList.splice(index, 1)
        this.props.onChange(newWalletList)
    }

	render () {
        const { errorStatus, data: walletList = [] } = this.props
		return <div>
            <p class="basic-header header-border">
            Relationship
                <br />
                <i><small className="text-muted">- Required</small></i>
            </p>
            <table className="table table-striped table-rel marginBott20 mt20">
                <tbody>
                <tr className="first-row">
                    <td className="effective-date">Effective date</td>
                    <td>Relationship</td>
                    <td>&nbsp;</td>
                </tr>
                {walletList.map((wallet, index) => {
                    if (index == 0) {
                        return (
                            <tr>
                            <td width="300px" className={classnames({ 'has-error': errorStatus == 1 && !wallet.effective_date })}>
                            <DateSelector
                                onChange={this.relationshipEffctiveDateChange.bind(this, 'effective_date', index)}
                                value={ I(wallet.effective_date, '') ? moment(wallet.effective_date, 'DD/MM/YYYY') : ''}
                                inputReadOnly={false}
                                allowClear={false}
                            />
                            </td>
                            <td className="advisor-td add_td relationship-td" id={"add_relationship"+index}>
                                <RelationshipSelector style={{ width: 240 }} value={wallet.relationship} onChange={this.setRelation.bind(this,index)} />
                            </td>
                            <td className="box-lasttd">
                                <div className="btn-box relationship-btnBox">
                                    {walletList.length == 1 && <button type="button" onClick={this.addWallet} className="btn-mp plus"></button>}
                                    {walletList.length > 1 && <button type="button" onClick={this.removeWallet.bind(this, index)} className="btn-mp minus"></button>}
                                </div>
                            </td>
                        </tr>)
                    } else {
                        return (
                            <tr>
                            <td width="300px" className={classnames({ 'has-error': errorStatus == 1 && !wallet.effective_date })}>
                                <DateSelector
                                    onChange={this.relationshipEffctiveDateChange.bind(this, 'effective_date', index)}
                                    value={ I(wallet.effective_date, '') ? moment(wallet.effective_date, 'DD/MM/YYYY') : ''}
                                    inputReadOnly={false}
                                    allowClear={false}
                                />
                            </td>
                            <td className="advisor-td add_td relationship-td" id={"add_relationship"+index}>
                                <RelationshipSelector style={{ width: 240 }} value={wallet.relationship} onChange={this.setRelation.bind(this,index)} />
                            </td>
                            <td className="box-lasttd">
                                <div className="btn-box relationship-btnBox">
                                    {index == walletList.length - 1 && <button type="button" onClick={this.addWallet} className="btn-mp plus"></button>}
                                    <button type="button" onClick={this.removeWallet.bind(this, index)} className="btn-mp minus"></button>
                                </div>
                            </td>
                        </tr>)
                    }
                })}
                </tbody>
            </table>
            <p className="alarm_text  hidden" id="valid_relationship"><span className="alarm_text glyphicon glyphicon-exclamation-sign"></span> A valid relationship is required.</p>
        </div>
	}
}