import React, { Component } from 'react';
import {Card, Form, Input, Button, message, Select, Row, Col, Spin} from 'antd';
import { I } from 'Utils';
import CountrySelector from 'components/CountrySelector';
import { post } from 'srcPath/http';
import '../style.scss';
import Header from "./header";

export default class Setting extends Component {

    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            financeId: 0,
            formData: [
                [
                    {
                        span: 11,
                        label: __('Country'),
                        userName: 'country',
                        required: {
                            status: true,
                            message: __('Please input your username!')
                        },
                        info: <CountrySelector />,
                        inputType: 'select',
                        placeholder: __('United States'),
                        desc: '',
                    },
                    {
                        span: 12,
                        offset: 1,
                        label: __('Additional Notes'),
                        userName: 'additionalNotes',
                        isTextarea: true,
                        required: {
                            status: false,
                            message: ''
                        },
                        inputType: 'textarea',
                        placeholder: __('Additional Notes'),
                        desc: __('Use this field for any additional you’d like to include on your billing statement.'),
                    },
                ],
                [
                    {
                        span: 11,
                        label: __('Company Name'),
                        userName: 'companyName',
                        required: {
                            status: true,
                            message: __('Please input your Company Name!')
                        },
                        inputType: 'input',
                        placeholder: __('Company Name'),
                        desc: '',
                    }
                ],
                [
                    {
                        span: 11,
                        label: __('Street Address'),
                        userName: 'streetAddress',
                        required: {
                            status: false,
                            message: __('Please input your Street Address!')
                        },
                        inputType: 'input',
                        placeholder: __('Street Address'),
                        desc: '',
                    }
                ],
                [
                    {
                        span: 11,
                        label: __('Suite/Unit'),
                        userName: 'unit',
                        required: {
                            status: false,
                            message: __('Please input your Suite/Unit!')
                        },
                        inputType: 'input',
                        placeholder: '',
                        desc: '',
                    }
                ],
                [
                    {
                        span: 11,
                        label: __('City'),
                        userName: 'city',
                        required: {
                            status: false,
                            message: __('Please input your city')
                        },
                        inputType: 'input',
                        placeholder: '',
                        desc: '',
                    }
                ],
                [
                    {
                        span: 11,
                        label: __('State/Province/Region'),
                        userName: 'state',
                        required: {
                            status: false,
                            message: ''
                        },
                        inputType: 'input',
                        placeholder: '',
                        desc: '',
                    }
                ],
                [
                    {
                        span: 11,
                        label: __('Postal Code'),
                        userName: 'postalCode',
                        required: {
                            status: false,
                            message: ''
                        },
                        inputType: 'input',
                        placeholder: '',
                        desc: '',
                    }
                ],
                [
                    {
                        span: 11,
                        label: '',
                        userName: 'submit',
                        required: {
                            status: false,
                            message: ''
                        },
                        inputType: 'submit',
                        placeholder: '',
                        desc: '',
                        descMore: __('* Indicates a required field'),
                        itemClass: 'billing-setting-button-item'
                    }
                ],

            ]
        };
    }

    getForm = () => {
        const { formData } = this.state;
        return formData.map( (v, k) => {
            return <Row key={k} className="billing-setting-form-item">{this.inputList(v)}</Row>
        })
    }

    /**
     * 平铺表单
     * @param data
     * @returns {*}
     */
    inputList = (data) => {
        return data.map( (v1, k1) => {
            return (
                <Col className={I(v1.isTextarea,'') && 'billing-setting-desc-textarea'} key={k1} span={v1.span} offset={v1.offset}>
                    { I(v1.descMore, '') && <div className="billing-setting-card-desc-more">{v1.descMore}</div> }
                    <Form.Item
                        label={v1.label}
                        name={v1.userName}
                        rules={[{ required: v1.required.status, message: v1.required.message }]}
                        className={v1.itemClass}
                    >
                        { this.getInput(v1)}
                    </Form.Item>
                    { I(v1.desc, '') && <Row><Col span={7} className="billing-setting-card-desc-label">&nbsp;</Col><Col span={17}><div className="billing-setting-card-desc">{v1.desc}</div></Col></Row> }
                </Col>
            );
        });
    }

    /**
     * 判断输入框类型
     * @param data
     * @returns {JSX.Element}
     */
    getInput = (data) => {
        const { loading } = this.state;
        let inputs;
        switch (data.inputType){
            case 'input':
                inputs = <Input />
                break;
            case 'select':
                inputs = data.info;
                break;
            case 'textarea':
                inputs = <Input.TextArea />
                break;
            default:
                inputs = <Button loading={loading} type="primary" htmlType="submit" >{__('Save Settings')}</Button>
        }
        return inputs;
    }

    /**
     * 保存
     * @param value
     */
    submitCompany = ( value) => {
        const { financeId } = this.state;
        const { companyName, country, streetAddress, unit, city, state, postalCode, additionalNotes } = value;
        const data = {
            id: financeId,
            country: I(country, ''),
            companyName: I(companyName, ''),
            street_address: I(streetAddress, ''),
            unit: I(unit, ''),
            city: I(city, ''),
            state: I(state, ''),
            postalCode: I(postalCode, ''),
            notes: I(additionalNotes, ''),
        };
        this.addCompanyFinance(data).then(r => {
            this.setState({
                loading: false
            })
        });
    }

    componentDidMount = () => {
        this.getCompanyFinance().then(r => {
            this.setState({
                loading: false
            })
        });
    }

    /**
     * 获取company详情
     */
    getCompanyFinance = async() => {
        this.setState({ loading: true })
        try {
            const { data: resData } = await post('getCompanyFinanceInfo')
            const { code, data } = resData;
            if (code === 0) {
                if(!I(data, '')){
                    return;
                }
                !!this.formRef.current && this.formRef.current.setFieldsValue({
                    country: I(data.country, '') ? I(data.country, '') : I(data.country_profile, ''),
                    companyName: I(data.company_name, '') ? I(data.company_name, '') : I(data.company_name_profile, ''),
                    streetAddress: I(data.street_address, ''),
                    unit: I(data.unit, ''),
                    city: I(data.city, ''),
                    state: I(data.state, ''),
                    postalCode: I(data.postal_code, ''),
                    additionalNotes: I(data.notes, ''),
                });
                this.setState({
                    financeId: I(data.id, 0)
                })
            }
        } catch (err) {
            message.error(err && err.message)
        }
    }

    /**
     * 保存
     * @param value
     * @returns {Promise<void>}
     */
    addCompanyFinance = async(value) => {
        this.setState({ loading: true })
        try {
            const { data: resData } = await post('addCompanyFinanceInfo', value)
            const { code, data } = resData;
            if (code === 0) {
                message.success('Success');
                this.setState({
                    financeId: data
                })
            } else {
                message.error(data)
            }
        } catch (err) {
            message.error(err && err.message)
        }
    }

	render() {
        const { loading } = this.state;
        const { ...props } = this.props;
		return (
            <Spin tip="Loading..." spinning={loading}>
                <Header {...props} />
                <div className="sprout-setting-billing-component">
                    <Card className="sprout-setting-billing-setting-card">
                        <div className="billing-setting-card-tit">
                            <h3>{__('Company Name & Address')}</h3>
                            <p>{__('This information will be included on all billing invoices on your account')}.</p>
                        </div>
                        <Form labelCol={{span: 7}} colon={false} onFinish={this.submitCompany} className="billing-setting-form" ref={this.formRef} >{this.getForm}</Form>
                    </Card>
                </div>
            </Spin>
        )
	}
}