import React, { Component, Fragment } from 'react';
import { message, Collapse, Tooltip } from 'antd';
import Loading from 'components/Loading';
import { post } from 'srcPath/http';
import { formatPercent, formatNumber, calculator } from 'Utils';
import openCertificateModal from 'components/CertificateDetail';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import RoundTags from 'components/RoundTags'
import image1 from 'assets/images/CS-Certificate.png';
import Empty from 'components/Empty';
import { formatDate2Api } from 'Utils/format/formatDate';
import moment from "moment";
import { LOADING } from "Utils/constant";
import { printf } from 'Utils/i18n';
import DateSelector from 'components/DateSelector';

import './style.scss';

const { Panel } = Collapse
const { add } = calculator

const formatPercentMulti = val => formatPercent(val, 'multiply')

export default class ByStakeholder extends Component {
	state = {
		data: {},
		loading: true,
		fullyDiluted: 0,
		share_prefix: [],
        date: moment(),
	}

	componentDidMount() {
		this.getData()
	}

	renderItem(data, index) {
		const {  name, fullyDiluted, rounds } = data
		const { fullyDiluted: funnFullyDiluted, share_prefix } = this.state

		return <div className="captable-stake-holder-content" key={index}>
			<div className="stake-holder-content-first">
				{name}<br/>
			</div>
			<div className="item">{<Tooltip placement="right" title={<RoundTags isPick allTags={share_prefix || []}  tags={rounds}/>}><span><RoundTags allTags={share_prefix || []}  tags={rounds}/></span></Tooltip>}</div>
			<div className="item">{formatFractionalNumber(fullyDiluted)}</div>
			<div className="item">{formatPercentMulti(fullyDiluted / funnFullyDiluted)}</div>
		</div>
	}

	renderPanel(data, index, style) {
		const { certificateNumber, fullyDiluted, security_id, prefix } = data
		const { fullyDiluted: funnFullyDiluted, share_prefix } = this.state

		return <div className="captable-stake-holder-content" key={index}>
			<div className="stake-holder-content-first">
				<img src={image1} onClick={() => { security_id && openCertificateModal(security_id)  }}/>
				{certificateNumber}<br/>
			</div>
			<div className="item">{<RoundTags allTags={share_prefix || []}  tags={prefix}/>}</div>
			<div className="item">{formatFractionalNumber(fullyDiluted)}</div>
			<div className="item">{formatPercentMulti(fullyDiluted / funnFullyDiluted)}</div>
		</div>
	}

	getData = async () => {
        const { date } = this.state;
		const capTableDate = formatDate2Api(date);
		
		Loading.global(true)

		try {
			const { data: resData } = await post('getByStakeholderData', {capTableDate})

			const { data, code } = resData

			if (code === 0) {
				const { shareholders = [], eaAvailableList = [], share_prefix } = data

				shareholders.forEach((val, index) => {
					shareholders[index] = this.processData(val)
				})

				this.setState({
					// 按照fullyDiluted 降序排序
					shareholders: shareholders.sort((pre, next) => next.fullyDiluted - pre.fullyDiluted),
					eaAvailableList,
					share_prefix,
					...this.setSumData(shareholders, eaAvailableList)
				})
			}
		} catch (err) {
			message.error( err && err.message)
		}

		this.setState({ loading: false });
		Loading.global(false);
	}

	setSumData = (shareholders, eaAvailableList) => {
		let fullyDiluted = 0

		shareholders.forEach(val => {
			fullyDiluted = add(fullyDiluted, Number(val.fullyDiluted || 0))
		})

		eaAvailableList.forEach(val => {
			fullyDiluted = add(fullyDiluted, Number(val.size || 0))
		})

		return { fullyDiluted }
	}

	processData = (data = {}) => {
		const { certificateDetails = [], name } = data
		const res = {
			name,
			fullyDiluted: 0,
			certificateDetails,
			rounds: []
		}
			certificateDetails.forEach(item => {
				let fullyDiluted = Number(item.fullyDiluted || 0);
				res.fullyDiluted = add(res.fullyDiluted, fullyDiluted)

				if (item.prefix && !res.rounds.includes(item.prefix[0])) {
					res.rounds.push(item.prefix[0])
				}

			})

		return res
	}

    /**
     * 查询日期change事件
     * @param date
     */
    dateChange = (date) => {
        this.setState({date}, ()=>{
            this.getData();
        });
    }

    /**
     * 不允许选择未来日期
     * @param current
     * @returns {boolean}
     */
    checkDate = (date) => {
        return date && date > moment().endOf('day');
    }

	render() {
		const { shareholders = [], eaAvailableList = [], fullyDiluted, date, loading } = this.state
		
		const isEmpty = Math.max(shareholders.length, eaAvailableList.length) === 0	

		return <div className="captable-stake-holder">
            {/*<div className="captable-stakeholder-date">
                <div className="captable-label">{__('View cap table as of')}</div>
                <DateSelector
                    className="captable-stakeholder-date-selector"
                    inputReadOnly={true}
                    value={date}
                    disabledDate={this.checkDate}
                    onChange={this.dateChange}
                />
            </div>*/}
			<div className="captable-stake-holder-header">
				<div className="stake-holder-header-first">{__('Name')}</div>
				<div className="item">{__('Rounds')}</div>
				<div className="item">{__('Fully diluted')}</div>
				<div className="item">{__('Ownership')}</div>
			</div>
			{
				isEmpty ? <div className="captable-stake-holder-empty "><Empty description={loading ? LOADING : __('No Data')} /></div> : <Fragment> <div className="captable-stake-holder-wrap">
					{
						shareholders.map((val, index) => <Collapse key={index}>
							<Panel header={this.renderItem(val, index, '#59d9b0')} >
								{
									val.certificateDetails.map((item, count) => this.renderPanel(item, count))
								}
							</Panel>
						</Collapse>)
					}
					{
						eaAvailableList.map((val, index) => <div key={index} className="captable-stake-holder-option">
							<div className="captable-stake-holder-content">
								<div className="stake-holder-content-first">
									{printf(__('Options available under %s'), val.plan_name)}
								</div>
								<div className="item">--</div>					
								<div className="item">{formatNumber(val.size, null)}</div>
								<div className="item">{formatPercentMulti(val.size / fullyDiluted)}</div>
							</div>
						</div>)
					}
					<div className="captable-stake-holder-option captable-stake-holder-option-summary">
						<div className="captable-stake-holder-content" style={{ fontWeight: 'bold' }}>
							<div className="stake-holder-content-first">
								{__('Total')}
							</div>
							<div className="item">--</div>
							<div className="item">{formatNumber(fullyDiluted, null)}</div>
							<div className="item">{formatPercent(1)}</div>
						</div>
					</div>
				</div>
				</Fragment>
			}	
		</div>
	}
}