import React from 'react';
import { FormItem } from 'react-antd-formutil';
import { Checkbox } from 'antd'
import { omit } from 'lodash';
import './style.scss';

const options = [
    {
        label: __('Not yet'),
        value: 0
    },
    {
        label: __('StakeholdersTable-Registered-Yes'),
        value: 1
    },
    // {
    //     label: 'Not required',
    //     value: 2
    // }
]

const Group = Checkbox.Group

export default function Relationship(props) {
    const { name, label } = props

    return <div className="filter-relationship"><FormItem
        name={name || 'registered'}
        label={label || __('Registered')}
    >
        <Group {...omit(props, ['name', 'label'])} showSearch placeholder={__('Select')} options={options} />
    </FormItem>
    </div>
}