import React, { Component } from "react";
import {Button, Radio, Space, Spin, Tooltip} from "antd";

class AdvancedTermsModels extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading : false,
            vestingValue : 0
        }
    }

    componentDidMount() {
        const { vestingValue } = this.props;
        this.setState(()=>({
            vestingValue
        }))
    }

    vestingValueChange = (e) => {
        const { value } = e.target;
        this.setState(()=>({
            vestingValue : value
        }))
    }

    saveAdvancedTerms = () => {
        const { vestingValue } = this.state;
        const { close, vestingValueChange } = this.props;
        vestingValueChange( vestingValue);
        close();
    }

    render() {
        const { loading, vestingValue } = this.state;
        const { close } = this.props;
        return (
            <Spin tip="Loading..." spinning={loading}>
                <div className="advance-model-root">
                    <h5 className="advance-model-title">{__('Advanced Terms')}</h5>
                    <div className="advance-model-close " onClick={close} >×</div>
                </div>
                <div className="waterfall-advance-model-box">
                    <div className="advance-model-option-circle"></div>
                    <div className="advance-model-option-content">
                        <div>{__('Vesting')}</div>
                        <div>{__('Modify the vesting assumption used in the waterfall. Accelerated vesting will assume all equity awards are fully vested as of the selected exit date.')}</div>
                        <div>
                            <dl>
                                <dt>{__('Vesting')}</dt>
                                <dd>
                                    <Radio.Group value={vestingValue} onChange={this.vestingValueChange}>
                                        <Space direction="vertical">
                                            <Radio value={1} className="advance-model-radio">
                                                <span>{__('Standard')}</span>
                                            </Radio>
                                            <Radio value={2}>
                                                <span>{__('Accelerated')}</span>
                                            </Radio>
                                        </Space>
                                    </Radio.Group>
                                </dd>
                            </dl>
                        </div>
                    </div>
                    <div className="clear"></div>
                </div>
                <div className="advance-model-save-button">
                    <Button className="advance-model-submit" type="primary" onClick={this.saveAdvancedTerms}>{__('Save Advanced Terms')}</Button>
                </div>
            </Spin>
        )
    }
}

export default AdvancedTermsModels;