import React, { Component } from 'react';
import { post } from 'srcPath/http';
import { message, Table, Button } from 'antd';
import Modal from 'components/Modal';
import TimeLine from './TimeLine';
import { templateSelect } from './config';
import './index.scss';

export default class SelectTemplate extends Component {
	state = {
		loading: true,
		tableData: [],
		currentId: 0,
		disabled: true
	}

	componentDidMount() {
		this.getTableData()
    }
    
    handleNextStep() {
		const { currentId } = this.state;
		this.props.history.push({
			pathname: '/home/board/consents/edit',
			state: { templateId: currentId }
		});
	}


	getTableData = async () => {
		this.setState({ loading: true })

		try {
			const { data: resData } = await post('getTemplateList')

			const { code, data } = resData || {}

			if (code == 0) {
				data.forEach((val, index) => { 
					val.index = index;
					val.checked = 0;
				})
				this.setState({ tableData: data })
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}

		this.setState({ loading: false })
	}

	previewTemplate(data, e) {
		this.setState({
			currentId: data.template_id
		});
		if (!['SPAN', 'BUTTON'].includes(e.target.tagName)) {
			const { tableData } = this.state;
			tableData.map((item) => {
				if (data.template_id === item.template_id) {
					item.checked = 1;
				} else {
					item.checked = 0;
				}
			})
			this.setState({
				tableData
			});
		} else {
			return Modal.open({
				component: props => <div>
					<p style={{ textAlign: 'center', paddingTop: 15, paddingBottom: 15, borderBottom: '1px solid #ccc' }}>{data.template_name}</p>
					<p style={{ padding: 20 }} dangerouslySetInnerHTML = {{__html:data.template_content}}></p>
					<div style={{ textAlign: 'center', marginTop: 20 }}><Button onClick={props.close} style={{ width: 100 }} type="primary">{__('Ok')}</Button></div>
				</div>,
				closable: true,
			})
		}
		
	}

	render() {
		const { tableData, loading } = this.state

		return <div className="new-board-consents-root">
                <div className="board-consents-operate-area">
					<TimeLine step="0" type="template" />
					<Button type="primary" onClick={this.handleNextStep.bind(this)}>{__('Next')}</Button>
                </div>
                <div className="board-consents-wrapper">
					<div className="board-consents-right-wrapper">
                        <Table 
                            size="small" 
                            rowKey="index" 
                            columns={templateSelect} 
                            dataSource={tableData} 
                            pagination={false} 
                            loading={loading}
                            onRow={record => {
                                return {
                                onClick: this.previewTemplate.bind(this, record),
                                };
                            }}
                        />
                    </div>
				</div>
            </div>
	}
}