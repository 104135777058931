import React, { Component } from "react";
import noPermissionImg from "./Img/no-permission.png";
import security from 'stores/security';
import { printf, sprintf } from 'Utils/i18n';

class NoPermissionSubsidiary extends Component {

    /**
     * 获取 parent company name
     * @returns {*}
     */
    getCompanyName = () => {
        return security?.companyInfo?.parent_name;
    }

    render() {
        return (
            <div className="plan-message-root sprout-no-permission-root ">
                <img src={noPermissionImg} />
                <h3>{__('Account status: Suspended')}</h3>
                <p>{printf(__('Your parent company “%s” is currently not available and requires renewal.'), this.getCompanyName())}<br/>{__('Please reach out to the person in charge to complete the renewal to reactivate your account.')}<br/>{sprintf(__('If you have any other concerns, feel free to contact us at %s.'), <a href="mailto:support@getsprout.co">support@getsprout.co</a>)}</p>
            </div>
        );
    }
}
export default NoPermissionSubsidiary;