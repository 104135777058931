import React, { useState, useEffect } from 'react';
import { Button, message, Space, Radio, Select, Table } from 'antd';
import Loading from 'components/Loading';
import { FormItem, withForm } from 'react-antd-formutil';
import moment from 'moment';
import { post } from 'srcPath/http';
import security from 'stores/security';
import { formatCurrencyNumber, formatPercent } from 'Utils'
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import Modal, { ModalContent } from 'components/Modal';
import openFileListModal from 'components/Layout/Header/components/FileListModal';
import classnames from 'classnames';

function PreviewList({ $formutil, previewList, params, reportName, activeList, isShowTable }) {
	const { $invalid, $batchDirty, $params, $getFirstError } = $formutil
	const [activeKey, setactiveKey] = useState(0)
	const [dataSource, setDataSource] = useState([])

	useEffect(() => {
	})
	const onSubmit = async () => {
		Loading.global(true);
		let ledger = params.ledger || ''
		let url = {
			CapTable: 'exportCaptableSummary',
			Securities: 'exportShareSecurity',
			Options: 'exportShareEa',
			Warrant: 'exportShareWr',
			VotingPower: 'exportVotingPower',
		}
		try {

			const { data: resData } = await post(url[reportName], { ledger, is_export: 1 })

			const { data, code } = resData || {}

			if (code === 0) {
				openFileListModal()
				security.getFileData()
			} else {
				message.error(data)
			}

		} catch (e) {
			message.error(e.message)
		}

		Loading.global(false)
	}


	const cnColumns = {
		cap_table_by_share_class: [
			{
				title: '',
				dataIndex: 'Share Class Name',
				render: (val, k) => <div className={classnames({ 'report_total': k.type == 1 })}>{val}</div>
			},
			{
				title: 'Shares Authorized',
				dataIndex: 'shares_authorized',
				render: (val, k) => <div className={classnames({ 'report_total': k.Totals == 'Totals' })}>{formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val)}</div>
			},
			{
				title: 'Outstanding',
				dataIndex: 'outstanding',
				render: (val, k) => <div className={classnames({ 'report_total': k.Totals == 'Totals' })}>{formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val)}</div>
			},
			{
				title: 'Fully Diluted Shares',
				dataIndex: 'Fully Diluted Shares',
				render: (val, k) => <div className={classnames({ 'report_total': k.Totals == 'Totals' })}>{formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val)}</div>
			},
			{
				title: 'Fully Diluted Ownership',
				dataIndex: 'Fully Diluted Ownership',
				render: (val, k) => <div className={classnames({ 'report_total': k.Totals == 'Totals' })}>{val === '' ? '' : formatPercent(val)}</div>
			},
			{
				title: 'Cash Raised (USD)',
				dataIndex: 'Cash Raised (USD)',
				render: (val, k) => <div className={classnames({ 'report_total': k.Totals == 'Totals' })}>{formatCurrencyNumber(val) == '--' ? '' : formatCurrencyNumber(val)}</div>
			},
		],
		cap_table_by_stakeholder: [
			{
				title: '',
				dataIndex: 'stakeholder_name',
				render: (val, k) => <div className={classnames({ 'report_total': k.total == 'Totals' })}>{val || k.security_name || k.total}</div>,
			},
			{
				title: 'Outstanding',
				dataIndex: 'outstanding',
				render: (val, k) => <div className={classnames({ 'report_total': k.total == 'Totals' })}>{formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val)}</div>
			},
			{
				title: 'Fully Diluted Shares',
				dataIndex: 'fully_diluted_shares',
				render: (val, k) => <div className={classnames({ 'report_total': k.total == 'Totals' })}>{formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val)}</div>
			},
			{
				title: 'Fully Diluted Ownership',
				dataIndex: 'fully_diluted_ownership',
				render: (val, k) => <div className={classnames({ 'report_total': k.total == 'Totals' })}>{formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val)}</div>
			},
		],
		cn_certificate_ledger: [
			{
				title: 'Terms Name',
				dataIndex: 'terms_name',
				width: 150,
			},
			{
				title: 'Name',
				dataIndex: 'name',
				width: 150,
			},
			{
				title: 'Email',
				dataIndex: 'email',
				width: 150,
			},
			{
				title: 'Security',
				dataIndex: 'security',
				width: 150,
			},
			{
				title: 'Type',
				dataIndex: 'type',
				width: 150,
			},
			{
				title: 'Original Principal',
				dataIndex: 'original_principal',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Valuation Cap',
				dataIndex: 'valuation_cap',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Board approved Date',
				dataIndex: 'board_approved_date',
				width: 150,
			},
			{
				title: 'Issue Date',
				dataIndex: 'issue_date',
				width: 150,
			},
			{
				title: 'Conversion Discount',
				dataIndex: 'conversion_discount',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Interest Rate',
				dataIndex: 'interest_rate',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Converted',
				dataIndex: 'converted',
				width: 150,
			},
			{
				title: 'Conversion Security',
				dataIndex: 'conversion_security',
				width: 150,
			},
			{
				title: 'Quantity',
				dataIndex: 'quantity',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Amount Paid',
				dataIndex: 'amount_paid',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Note',
				dataIndex: 'note',
				width: 150,
			}
		],
		cs_certificate_ledger: [
			{
				title: 'Shareholder Name',
				dataIndex: 'shareholder_name',
				width: 150,
			},
			{
				title: 'Security',
				dataIndex: 'security',
				width: 150,
			},
			{
				title: 'Relationship',
				dataIndex: 'relationship',
				width: 150,
			},
			{
				title: 'Shareholder Email',
				dataIndex: 'shareholder_email',
				width: 150,
			},
			{
				title: 'Share Issued',
				dataIndex: 'share_issued',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,

			},
			{
				title: 'Outstanding',
				dataIndex: 'outstanding',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Share Class',
				dataIndex: 'share_class',
				width: 150,
			},
			{
				title: 'Price per share (USD)',
				dataIndex: 'price_per_share (USD)',
				render: val => formatCurrencyNumber(val),
				width: 150,
			},
			{
				title: 'Cash Paid (USD)',
				dataIndex: 'cash_paid (USD)',
				render: val => formatCurrencyNumber(val),
				width: 150,
			},
			{
				title: 'Debt Canceled (USD)',
				dataIndex: 'debt_canceled (USD)',
				render: val => formatCurrencyNumber(val),
				width: 150,
			},
			{
				title: 'Value of IP Transferred (USD)',
				dataIndex: 'value_of_ip_transferred (USD)',
				render: val => formatCurrencyNumber(val),
				width: 200,
			},
			{
				title: 'Other Consideration (USD)',
				dataIndex: 'other_consideration (USD)',
				render: val => formatCurrencyNumber(val),
				width: 200,
			},
			{
				title: 'Status',
				dataIndex: 'status',
				width: 150,
			},
			{
				title: 'Issue Date',
				dataIndex: 'issue_date',
				width: 150,
			},
			{
				title: 'Board approved Date',
				dataIndex: 'board_approved_date',
				width: 150,
			},
			{
				title: 'Termination Date',
				dataIndex: 'statermination_datetus',
				width: 150,
			},
			{
				title: 'Vesting Start Date',
				dataIndex: 'vesting_start_date',
				width: 150,
			},
			{
				title: 'Vesting Schedule Name',
				dataIndex: 'vesting_schedule_name',
				width: 150,
			},
			{
				title: 'Transfer Date',
				dataIndex: 'transfer_date',
				width: 150,
			},
			{
				title: 'Transfer Value',
				dataIndex: 'transfer_value',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Canceled Date',
				dataIndex: 'canceled_date',
				width: 150,
			},
			{
				title: 'Shares Canceled',
				dataIndex: 'shares_canceled',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Repurchase Reason',
				dataIndex: 'repurchase_reason',
				width: 150,
			},
			{
				title: 'Repurchase Date',
				dataIndex: 'repurchase_date',
				width: 150,
			},
			{
				title: 'Shares Repurchased',
				dataIndex: 'shares_repurchased',
				width: 150,
			},
			{
				title: 'Plan Name',
				dataIndex: 'plan_name',
				width: 150,
			},
			{
				title: 'Note',
				dataIndex: 'note',
				width: 150,
			}
		],
		ps_certificate_ledger: [
			{
				title: 'Shareholder Name',
				dataIndex: 'shareholder_name',
				width: 150,
			},
			{
				title: 'Security',
				dataIndex: 'security',
				width: 150,
			},
			{
				title: 'Relationship',
				dataIndex: 'relationship',
				width: 150,
			},
			{
				title: 'Shareholder Email',
				dataIndex: 'shareholder_email',
				width: 150,
			},
			{
				title: 'Share Issued',
				dataIndex: 'share_issued',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Outstanding',
				dataIndex: 'outstanding',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Share Class',
				dataIndex: 'share_class',
				width: 150,
			},
			{
				title: 'Price per share (USD)',
				dataIndex: 'price_per_share (USD)',
				render: val => formatCurrencyNumber(val),
				width: 150,
			},
			{
				title: 'Cash Paid (USD)',
				dataIndex: 'cash_paid (USD)',
				render: val => formatCurrencyNumber(val),
				width: 150,
			},
			{
				title: 'Debt Canceled (USD)',
				dataIndex: 'debt_canceled (USD)',
				render: val => formatCurrencyNumber(val),
				width: 150,
			},
			{
				title: 'Value of IP Transferred (USD)',
				dataIndex: 'value_of_ip_transferred (USD)',
				render: val => formatCurrencyNumber(val),
				width: 200,
			},
			{
				title: 'Other Consideration (USD)',
				dataIndex: 'other_consideration (USD)',
				render: val => formatCurrencyNumber(val),
				width: 200,
			},
			{
				title: 'Status',
				dataIndex: 'status',
				width: 150,
			},
			{
				title: 'Issue Date',
				dataIndex: 'issue_date',
				width: 150,
			},
			{
				title: 'Board approved Date',
				dataIndex: 'board_approved_date',
				width: 150,
			},
			{
				title: 'Termination Date',
				dataIndex: 'statermination_datetus',
				width: 150,
			},
			{
				title: 'Vesting Start Date',
				dataIndex: 'vesting_start_date',
				width: 150,
			},
			{
				title: 'Vesting Schedule Name',
				dataIndex: 'vesting_schedule_name',
				width: 150,
			},
			{
				title: 'Transfer Date',
				dataIndex: 'transfer_date',
				width: 150,
			},
			{
				title: 'Transfer Value',
				dataIndex: 'transfer_value',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Canceled Date',
				dataIndex: 'canceled_date',
				width: 150,
			},
			{
				title: 'Shares Canceled',
				dataIndex: 'shares_canceled',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Repurchase Reason',
				dataIndex: 'repurchase_reason',
				width: 150,
			},
			{
				title: 'Repurchase Date',
				dataIndex: 'repurchase_date',
				width: 150,
			},
			{
				title: 'Shares Repurchased',
				dataIndex: 'shares_repurchased',
				width: 150,
			},
			{
				title: 'Plan Name',
				dataIndex: 'plan_name',
				width: 150,
			},
			{
				title: 'Note',
				dataIndex: 'note',
				width: 150,
			}
		],
		share_option_awards: [
			{
				title: 'Equity plan name',
				dataIndex: 'equity_plan_name',
				width: 150,
			},
			{
				title: 'Stakeholder name',
				dataIndex: 'stakeholder_name',
				width: 150,
			},
			{
				title: 'Stakeholder email',
				dataIndex: 'stakeholder_email',
				width: 150,
			},
			{
				title: 'Job title',
				dataIndex: 'job_title',
				width: 150,
			},
			{
				title: 'Country',
				dataIndex: 'country',
				width: 150,
			},
			{
				title: 'Award category',
				dataIndex: 'award_category',
				width: 150,
			},
			{
				title: 'Relationship',
				dataIndex: 'relationship',
				width: 150,
			},
			{
				title: 'Quantity granted',
				dataIndex: 'quantity_granted',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Security',
				dataIndex: 'security',
				width: 150,
			},
			{
				title: 'Award type',
				dataIndex: 'award_type',
				width: 150,
			},
			{
				title: 'Common share class',
				dataIndex: 'common_share_class',
				width: 150,
			},
			{
				title: 'Prepay price',
				dataIndex: 'prepay_price',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Grant date',
				dataIndex: 'grant_date',
				width: 150,
			},
			{
				title: 'Board approved date',
				dataIndex: 'board_approved_date',
				width: 150,
			},
			{
				title: 'Expiration date',
				dataIndex: 'expiration_date',
				width: 150,
			},
			{
				title: 'Status',
				dataIndex: 'status',
				width: 150,
			},
			{
				title: 'Vesting start date',
				dataIndex: 'vesting_start_date',
				width: 150,
			},
			{
				title: 'Vested quantity',
				dataIndex: 'vested_quantity',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Vested',
				dataIndex: 'vested %',
				width: 150,
			},
			{
				title: 'Fully diluted quantity',
				dataIndex: 'fully_diluted_quantity',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Exercised quantity',
				dataIndex: 'exercised_quantity',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Remaining quantity to vest',
				dataIndex: 'remaining_quantity_to_vest',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 200,
			},
			{
				title: 'Forfeited and returned to ESOP pool',
				dataIndex: 'forfeited_and_returned_to_ESOP_pool',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 200,
			},
			{
				title: 'Remaining quantity on grant',
				dataIndex: 'remaining_quantity_on_grant',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 200,
			},
			{
				title: 'Exercise price (USD)',
				dataIndex: 'exercise_price (USD)',
				render: val => formatCurrencyNumber(val),
				width: 150,
			},
			{
				title: 'Termination date',
				dataIndex: 'termination_date',
				width: 150,
			},
			{
				title: 'Termination type',
				dataIndex: 'termination_type',
				width: 150,
			},
			{
				title: 'Notes',
				dataIndex: 'notes',
				width: 150,
			},
			{
				title: 'Payment date',
				dataIndex: 'payment_date',
				width: 150,
			},
			{
				title: 'Payment collected',
				dataIndex: 'payment_collected',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Payment verified by',
				dataIndex: 'payment_verified_by',
				width: 150,
			},
			{
				title: 'Amount reimbursed by company',
				dataIndex: 'amount_reimbursed_by_company',
				width: 200,
			},
			{
				title: 'Amount reimbursement date',
				dataIndex: 'amount_reimbursement_date',
				width: 200,
			}
		],
		share_rsa_awards: [
			{
				title: 'Equity Plan name',
				dataIndex: 'equity_plan_name',
				width: 150,
			},
			{
				title: 'Stakeholder Name',
				dataIndex: 'stakeholder_name',
				width: 150,
			},
			{
				title: 'Stakeholder Email',
				dataIndex: 'stakeholder_email',
				width: 150,
			},
			{
				title: 'Job title',
				dataIndex: 'job_title',
				width: 150,
			},
			{
				title: 'Country',
				dataIndex: 'country',
				width: 150,
			},
			{
				title: 'Relationship',
				dataIndex: 'relationship',
				width: 150,
			},
			{
				title: 'Quantity Granted',
				dataIndex: 'quantity_granted',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Security',
				dataIndex: 'security',
				width: 150,
			},
			{
				title: 'Award Type',
				dataIndex: 'award_type',
				width: 150,
			},
			{
				title: 'Common Share Class',
				dataIndex: 'common_share_class',
				width: 150,
			},
			{
				title: 'Prepay Price',
				dataIndex: 'prepay_price',
				render: val => formatCurrencyNumber(val),
				width: 150,
			},
			{
				title: 'Grant Date',
				dataIndex: 'grant_date',
				width: 150,
			},
			{
				title: 'Board approved Date',
				dataIndex: 'board_approved_date',
				width: 150,
			},
			{
				title: 'Expiration Date',
				dataIndex: 'expiration_date',
				width: 150,
			},
			{
				title: 'Status',
				dataIndex: 'status',
				width: 150,
			},
			{
				title: 'Vesting Start Date',
				dataIndex: 'vesting_start_date',
				width: 150,
			},
			{
				title: 'Vesting Schedule Name',
				dataIndex: 'vesting_schedule_name',
				width: 150,
			},
			{
				title: 'Vested Quantity',
				dataIndex: 'vested_quantity',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Vested',
				dataIndex: 'vested',
				width: 150,
			},
			{
				title: 'Exercised Quantity',
				dataIndex: 'exercised_quantity',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Remaining Quantity to exercise',
				dataIndex: 'remaining_quantity_to_vest',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 220,
			},
			{
				title: 'Exercise Price (USD)',
				dataIndex: 'exercise_price (USD)',
				render: val => formatCurrencyNumber(val),
				width: 150,
			},
			{
				title: 'Termination Date',
				dataIndex: 'termination_date',
				width: 150,
			},
			{
				title: 'Termination Type',
				dataIndex: 'termination_type',
				width: 150,
			},
			{
				title: 'Notes',
				dataIndex: 'notes',
				width: 150,
			},
		],
		share_rsu_awards: [
			{
				title: 'Equity Plan name',
				dataIndex: 'equity_plan_name',
				width: 150,
			},
			{
				title: 'Stakeholder Name',
				dataIndex: 'stakeholder_name',
				width: 150,
			},
			{
				title: 'Stakeholder Email',
				dataIndex: 'stakeholder_email',
				width: 150,
			},
			{
				title: 'Job title',
				dataIndex: 'job_title',
				width: 150,
			},
			{
				title: 'Country',
				dataIndex: 'country',
				width: 150,
			},
			{
				title: 'Relationship',
				dataIndex: 'relationship',
				width: 150,
			},
			{
				title: 'Quantity Granted',
				dataIndex: 'quantity_granted',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Security',
				dataIndex: 'security',
				width: 150,
			},
			{
				title: 'Award Type',
				dataIndex: 'award_type',
				width: 150,
			},
			{
				title: 'Common Share Class',
				dataIndex: 'common_share_class',
				width: 150,
			},
			{
				title: 'Prepay Price',
				dataIndex: 'prepay_price',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Grant Date',
				dataIndex: 'grant_date',
				width: 150,
			},
			{
				title: 'Board approved Date',
				dataIndex: 'board_approved_date',
				width: 150,
			},
			{
				title: 'Expiration Date',
				dataIndex: 'expiration_date',
				width: 150,
			},
			{
				title: 'Status',
				dataIndex: 'status',
				width: 150,
			},
			{
				title: 'Vesting Start Date',
				dataIndex: 'vesting_start_date',
				width: 150,
			},
			{
				title: 'Vesting Schedule Name',
				dataIndex: 'vesting_schedule_name',
				width: 150,
			},
			{
				title: 'Vested Quantity',
				dataIndex: 'vested_quantity',
				width: 150,
			},
			{
				title: 'Vested',
				dataIndex: 'vested',
				width: 150,
			},
			{
				title: 'Exercised Quantity',
				dataIndex: 'exercised_quantity',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Remaining Quantity to exercise',
				dataIndex: 'remaining_quantity_to_vest',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 220,
			},
			{
				title: 'Exercise Price (USD)',
				dataIndex: 'exercise_price (USD)',
				render: val => formatCurrencyNumber(val),
				width: 150,
			},
			{
				title: 'Termination Date',
				dataIndex: 'termination_date',
				width: 150,
			},
			{
				title: 'Termination Type',
				dataIndex: 'termination_type',
				width: 150,
			},
			{
				title: 'Notes',
				dataIndex: 'notes',
				width: 150,
			},
		],
		share_sar_awards: [
			{
				title: 'Equity Plan name',
				dataIndex: 'equity_plan_name',
				width: 150,
			},
			{
				title: 'Stakeholder Name',
				dataIndex: 'stakeholder_name',
				width: 150,
			},
			{
				title: 'Stakeholder Email',
				dataIndex: 'stakeholder_email',
				width: 150,
			},
			{
				title: 'Job title',
				dataIndex: 'job_title',
				width: 150,
			},
			{
				title: 'Country',
				dataIndex: 'country',
				width: 150,
			},
			{
				title: 'Relationship',
				dataIndex: 'relationship',
				width: 150,
			},
			{
				title: 'Quantity Granted',
				dataIndex: 'quantity_granted',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Security',
				dataIndex: 'security',
				width: 150,
			},
			{
				title: 'Award Type',
				dataIndex: 'award_type',
				width: 150,
			},
			{
				title: 'Common Share Class',
				dataIndex: 'common_share_class',
				width: 150,
			},
			{
				title: 'Prepay Price',
				dataIndex: 'prepay_price',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Grant Date',
				dataIndex: 'grant_date',
				width: 150,
			},
			{
				title: 'Board approved Date',
				dataIndex: 'board_approved_date',
				width: 150,
			},
			{
				title: 'Expiration Date',
				dataIndex: 'expiration_date',
				width: 150,
			},
			{
				title: 'Status',
				dataIndex: 'status',
				width: 150,
			},
			{
				title: 'Vesting Start Date',
				dataIndex: 'vesting_start_date',
				width: 150,
			},
			{
				title: 'Vesting Schedule Name',
				dataIndex: 'vesting_schedule_name',
				width: 150,
			},
			{
				title: 'Vested Quantity',
				dataIndex: 'vested_quantity',
				render: val => formatCurrencyNumber(val),
				width: 150,
			},
			{
				title: 'Vested',
				dataIndex: 'vested',
				width: 150,
			},
			{
				title: 'Exercised Quantity',
				dataIndex: 'exercised_quantity',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Remaining Quantity to exercise',
				dataIndex: 'remaining_quantity_to_vest',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 220,
			},
			{
				title: 'Exercise Price (USD)',
				dataIndex: 'exercise_price (USD)',
				render: val => formatCurrencyNumber(val),
				width: 150,
			},
			{
				title: 'Termination Date',
				dataIndex: 'termination_date',
				width: 150,
			},
			{
				title: 'Termination Type',
				dataIndex: 'termination_type',
				width: 150,
			},
			{
				title: 'Notes',
				dataIndex: 'notes',
				width: 150,
			},
		],
		warrant: [
			{
				title: 'Warrant holder',
				dataIndex: 'warrant_holder',
				width: 150,
			},
			{
				title: 'Stakeholder Email',
				dataIndex: 'stakeholder_email',
				width: 150,
			},
			{
				title: 'Security',
				dataIndex: 'security',
				width: 150,
			},
			{
				title: 'Warrant Block Name',
				dataIndex: 'warrant_block_name',
				width: 150,
			},
			{
				title: 'Associated Share Class',
				dataIndex: 'associated_share_class',
				width: 180,
			},
			{
				title: 'Original quantity',
				dataIndex: 'original_quantity',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},

			{
				title: 'Exercised quantity',
				dataIndex: 'exercised_quantity',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Remaining quantity',
				dataIndex: 'remaining_quantity',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Purchase price (USD)',
				dataIndex: 'purchase_price (USD)',
				render: val => formatCurrencyNumber(val),
				width: 150,
			},
			{
				title: 'Exercise price (USD)',
				dataIndex: 'exercise_price (USD)',
				render: val => formatCurrencyNumber(val),
				width: 150,
			},

			{
				title: 'Issue date',
				dataIndex: 'issue_date',
				width: 150,
			},
			{
				title: 'Board approved date',
				dataIndex: 'board_approved_date',
				width: 150,
			},
			{
				title: 'Expiration date',
				dataIndex: 'expiration_date',
				width: 150,
			},
			{
				title: 'Quantity Expired',
				dataIndex: 'quantity_Expired',
				render: val => formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val),
				width: 150,
			},
			{
				title: 'Status',
				dataIndex: 'status',
				width: 150,
			},
			{
				title: 'Note',
				dataIndex: 'note',
				width: 150,
			},

		],
		voting_power: [
			{
				title: 'Stakeholder Name',
				dataIndex: 'stakeholder_name',
				render: (val, k) => <div className={classnames({ 'report_total': k.Totals == 'Totals' })}>{val}</div>,
				width: 150,
			},
			{
				title: 'Stakeholder Email',
				dataIndex: 'stakeholder_email',
				render: (val, k) => <div className={classnames({ 'report_total': k.Totals == 'Totals' })}>{val}</div>,
				width: 150,
			},
			{
				title: 'Relationship',
				dataIndex: 'relationship',
				render: (val, k) => <div className={classnames({ 'report_total': k.Totals == 'Totals' })}>{val}</div>,
				width: 150,
			},
			{
				title: 'Fully Diluted Shares',
				dataIndex: 'fully_diluted_shares',
				render: (val, k) => <div className={classnames({ 'report_total': k.Totals == 'Totals' })}>{formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val)}</div>,
				width: 150,
			},
			{
				title: 'Voting Multiple',
				dataIndex: 'voting_multiple',
				render: (val, k) => <div className={classnames({ 'report_total': k.Totals == 'Totals' })}>{formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val)}</div>,
				width: 150,
			},
			{
				title: 'Votes',
				dataIndex: 'votes',
				render: (val, k) => <div className={classnames({ 'report_total': k.Totals == 'Totals' })}>{formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val)}</div>,
				width: 150,
			},
			{
				title: 'Voting Power',
				dataIndex: 'voting_power',
				render: (val, k) => <div className={classnames({ 'report_total': k.Totals == 'Totals' })}>{formatFractionalNumber(val) == '--' ? '' : formatFractionalNumber(val)}</div>,
				width: 150,
			},
		]
	}
	const cnColumnsName = {
		cap_table_by_share_class: 'By share class',
		cap_table_by_stakeholder: 'By stakeholder',
		cn_certificate_ledger: 'By common shares',
		cs_certificate_ledger: 'By preferred shares',
		ps_certificate_ledger: 'By convertibles',
		share_option_awards: 'Options report',
		share_rsa_awards: 'RSAs report',
		share_rsu_awards: 'RSUs report',
		share_sar_awards: 'SARs report',
		warrant: 'Warrants outstanding report',
		voting_power: 'Voting power report',
	}

	const getActiveKey = (e) => {
		setactiveKey(e)
	}

	return <div>
		{isShowTable && <div className="export-table">
			<div className="export-table-export-btn" >
				<div className="export-table-btn">
					{
						activeList && activeList.map((val, i) => {
							return <Button type={activeKey === i && 'primary'} key={i} onClick={() => getActiveKey(i)} >{cnColumnsName[val]}</Button>
						})
					}
				</div>
				<div className="btns">
					<Space>
						<Button className="export_sbc_btn shares-actions active" type="primary" onClick={() => onSubmit(1)}>{__('Export')}</Button>
					</Space>
				</div>
			</div>

			<div className="export-table-list">
				<Table
					className="sbc-table"
					columns={cnColumns[activeList[activeKey]]}
					dataSource={previewList[activeList[activeKey]]}
					pagination={false}
					scroll={{ x: true, y: 500 }}
				/>
			</div>

		</div>
		}
	</div>
}

export default withForm(PreviewList)
