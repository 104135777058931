import React, { Component } from 'react';
import noDataIcon from './no-data.png'
import { language } from 'Utils/i18n';
import './style.scss';

export default function EmptyTips (props) {
   
    return (
        <div className="no-data-root">
            <div className='no-data-item first-item'>
                <img className="no-data-img" src={noDataIcon} />
                <span>{__('No data to display')}</span>
            </div>
            <div className='no-data-item'>{__('We recommend that you contact your company administrator regarding your holdings, as they are currently unavailable.')} </div>
            <div className='no-data-item'>{__('Once the company administrator grants you the necessary options, you will receive an email notification.')}</div>
            <div className='no-data-item'>{__('If you have any additional questions, please do not hesitate to contact us at')} <a href="mailto:support@getsprout.co">support@getsprout.co</a>{language === 'en_US' ? '.' : '。'}</div>
            <div className='no-data-item'>{__('Thank you.')}</div>
        </div>
    )
}