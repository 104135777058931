import React, { Component, createRef } from 'react';
import { Input, Button, message, Tooltip } from 'antd';
import { post } from 'srcPath/http';
import security from 'stores/security';
import { bindKeyEvent } from 'Utils';
import { sprintf } from 'Utils/i18n';
import './style.scss';

class SignatureModal extends Component {
	state = {
		loading: false,
		documentSignature: ''
	}

	inputRef = createRef()

	componentDidMount() {
		this.inputRef.current.focus()
		this.removeKeyEvent = bindKeyEvent(this.onSubmit)
	}

	componentWillUnmount() {
		this.removeKeyEvent && this.removeKeyEvent()
	}

	onSubmit = async () => {
		const { callback, close } = this.props

		this.setState({ loading: true })

		try {
			const { data } = await post('editDocumentSignature', {
				uid: security.userInfo.id,
				documentSignature: this.state.documentSignature
			})

			if (data.code === 200) {
				this.setState({ loading: false })
				callback && callback()
				close();
			}

		} catch (e) {
			message.error(e.message)
		}

		this.setState({ loading: false })
	}

	handleChange = event => {
		this.setState({
			documentSignature: event.target.value
		})
	}

	render() {
		const { documentSignature } = this.props

		return <div className="signature-modal">
				<div className="signature-tips">{__('These certifiates need your signature in order to be sent. Your signature will appear on the front of the certifiates.')}</div>
				<div className="signature-input">
					<Tooltip 
						arrowPointAtCenter 
						title={__('Sign Here')}
						color="volcano" 
						placement="left" 
						visible={!this.state.documentSignature}>
						<Input ref={this.inputRef} bordered={false} onChange={this.handleChange}/>
					</Tooltip>
				</div>
				<div className="signature-current">{sprintf(__('Currently, it is %s'),<span className="signature-family">{ documentSignature ? `"${documentSignature}"` : '--' }</span>)}</div>
				<div className="signature-btn-wrap">
					<Button type="primary" loading={this.state.loading} onClick={this.onSubmit}>{__('Save Changes')}</Button>
				</div>
		</div>
	}
}

export default SignatureModal