import React, { Component } from 'react';
import Modal, { ModalContent } from 'components/Modal';
import { FormItem, withForm } from 'react-antd-formutil';
import { Input, Button, message, Select, Alert, Row, Col, Collapse, Tooltip, Space } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons'
import { PlusOutlined } from '@ant-design/icons';
import DateSelector from 'components/DateSelector';
import { post } from 'srcPath/http';
import { formatDateForDatePicker, formatDate2Api } from 'Utils/format/formatDate';
import  { bindKeyEvent } from 'Utils';
import SelectStakeholder from 'B/components/WalletLabel/components/StakeholderSelector';
import security from 'stores/security';
import openAssignToken from '../AssignToken';
import Loading from 'components/Loading';
import UploadItem from 'B/components/UploadItem'

import './style.scss';

const Option = Select.Option

class AssignTokenGrant extends Component {
	state = {
		loading: false,
		error: '',
		tokenPlanList:[],
		formatTokenPlanList: {},
		vestingSchedulesList:[],
		originWalletAddressList:[],
		destinationWalletAddressList:[]
	}

	componentDidMount() {
		this.getTokenOptionPlanList();
		this.getVestingScheduleList();

		const { data, $formutil } = this.props;

		if (data) {
			const {
				id, original_id, original_wallet_address, destination_id,
				destination_wallet_address, token_plan_id, grant_name, grant_date,cash_value,
				quantity, vesting_schedule_id, vesting_start_date,
				grant_letter, form_of_exercise_agreement, token_incentive_plan, additional_document
			} = data || {}

			if (original_id) { 
				this.getOriginAddress(original_id)
			}

			if (destination_id) { 
				this.getDestinationAddress(destination_id)
			}

			$formutil.$setValues({
				id, original_id, original_wallet_address, destination_id, destination_wallet_address,
				token_plan_id, grant_name, grant_date, quantity, vesting_schedule_id, vesting_start_date,
				grant_letter, form_of_exercise_agreement, token_incentive_plan, additional_document,cash_value
			})
		}
		this.removeKeyEvent = bindKeyEvent(this.onSubmit)
	}

	componentWillUnmount() {
		this.removeKeyEvent && this.removeKeyEvent()
	}

	onSubmit = async () => {
		const { $formutil, close, callback, data } = this.props
		const { $invalid, $batchDirty, $params, $getFirstError } = $formutil

		if ($invalid) {
			$batchDirty(true)
			message.error($getFirstError())

			return
		}

		this.setState({ loading: true })

		const api = data ? 'modifyTokenGrant' : 'assignTokenGrant'
		const params = {
			...$params,
		}

		try {
			const { data: resData } = await post(api, params)
			const { code, data } = resData || {}
			if (code == 10007) {
				this.setState({ error: __('Token grant name can not be duplicate.') })
			}
			if (code == 6) {
				this.setState({ error: data })
			}
			if (code == 200 || code == 0) {
				close && close()
				callback && callback(!!data)
			}
		} catch (e) {
			message.error(e.message)
		}

		this.setState({ loading: false })
	}

	getOriginAddress = async (id) => { 
		Loading.global(true)

		try {
			const { data: resData } = await post('getWalletAddressListById', { stakeholder_id: id })
			
			const { code, data } = resData || {}

			if (code === 0) {
				this.setState({ originWalletAddressList: data.list })
			}
		} catch (e) { 
			message.error(e.message)
		}

		Loading.global(false)
	}

	getDestinationAddress = async (id) => { 
		Loading.global(true)

		try {
			const { data: resData } = await post('getWalletAddressListById', { stakeholder_id: id })
			
			const { code, data } = resData || {}

			if (code === 0) {
				this.setState({ destinationWalletAddressList: data.list })
			} else {
				message.error(data)
			}
		} catch (e) { 
			message.error(e.message)
		}

		Loading.global(false)
	}

	/**
	 * 获取token plan list
	 */
	getTokenOptionPlanList=()=>{
		post('getTokenOptionPlanList').then((response)=> {
			if(response.data.code === 0){
				let formatTokenPlanList = this.state.formatTokenPlanList;
				let resList = response.data.data.plan_list;
				for(let i=0; i<resList.length; i++){
					formatTokenPlanList[resList[i].id] = resList[i];
				}
				this.setState({
					tokenPlanList : resList,
					loading : false
				})
			}else{
				console.log("failed, error code is: "+response.data.code);
			}
		}).catch(function (error) {
			console.log(error);
		});
	}

	/**
	 * 获取vesting schedule list
	 * @returns {JSX.Element}
	 */
	getVestingScheduleList = ()=>{
		post('getVestingScheduleList').then((response)=> {
			if(response.data.code === 0){
				this.setState({
					vestingSchedulesList:response.data.data,
				});
			}else{
				console.log("failed, error code is: "+response.data.code);
			}
		}).catch(function (error) {
			console.log(error);
		});
	}

	renderOriginDrop = menu => {
		const { $params } = this.props.$formutil
		const { original_id } = $params || {}
		return <>
			<Button type="link" onClick={() => openAssignToken(original_id, () => this.getOriginAddress(original_id))}>
				<PlusOutlined />{__('Add New')}
			</Button>
			{menu}
		</>
	}

	renderDestinationDrop = menu => {
		const { $params } = this.props.$formutil
		const { destination_id } = $params || {}
		return <>
			<Button type="link" onClick={() => openAssignToken(destination_id, () => this.getDestinationAddress(destination_id))}>
				<PlusOutlined />{__('Add New')}
			</Button>
			{menu}
		</>
	}

	render() {
		const isFractionalShare = security.companyInfo.fractional_control == '1';
		const { error, tokenPlanList, vestingSchedulesList, originWalletAddressList, destinationWalletAddressList } = this.state
		const { close, $formutil, history } = this.props
		const { $params, $setValues } = $formutil

		// const disabled = !!data
		const disabled = false
		const startDate = formatDateForDatePicker(security.companyInfo.date_of_incorporation)
		return <div className="assign-token-grant-modal">
			<div className="assign-token-grant-modal-content">
				{error && <Alert style={{ marginBottom: 24 }} message={error} type="error" showIcon />}
				<div className="address-content">
					<Row gutter={12}>
						<Col span={12}>
							<FormItem
								name="original_id"
								label={__("Origin name")}
								colon={false}
								className="assign-name-item"
								// $onFieldChange={(val) => { 
								// 	$setValues({ original_wallet_address: null });
								// 	this.getOriginAddress(val)
								// }}
								validMessage={{
									required: __('Origin name is required')
								}}
							>
								<SelectStakeholder showAdd includeLabel placeholder="Select" onSelect={ 
									(val) => { 
										console.info(val, '=====1111val')
										$setValues({ original_wallet_address: null });
										this.getOriginAddress(val)
									}
								} />
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem
								name="original_wallet_address"
								required
								label={__("Origin wallet")}
								colon={false}
								className={"assign-name-item"}
								validMessage={{
									required: __('Origin wallet is required')
								}}
							>
								<Select showSearch placeholder={__("Select an address")} disabled={!$params?.original_id} dropdownRender={this.renderOriginDrop}>
									{originWalletAddressList && originWalletAddressList.map((val, index) => <Option key={index} value={val}>{val}</Option>)}
								</Select>
							</FormItem>
						</Col>
					</Row>
					<Row gutter={12}>
						<Col span={12}>
							<FormItem
								name="destination_id"
								label={__("Destination name")}
								colon={false}
								className="assign-name-item"
								// $onFieldChange={(val) => {
								// 	$setValues({ destination_wallet_address: null });
								// 	this.getDestinationAddress(val)
								// }}
								validMessage={{
									required: __('Destination name is required')
								}}
							>
								<SelectStakeholder showAdd includeLabel placeholder={__("Select")} onSelect={ 
									(val) => {
										$setValues({ destination_wallet_address: null });
										this.getDestinationAddress(val)
									}
								} />
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem
								name="destination_wallet_address"
								required
								label={__("Destination wallet")}
								colon={false}
								className={"assign-name-item"}
								validMessage={{
									required: __('Destination wallet is required')
								}}
							>
								<Select showSearch placeholder={__("Select an address")} disabled={!$params?.destination_id} dropdownRender={this.renderDestinationDrop}>
									{destinationWalletAddressList && destinationWalletAddressList.map((val, index) => <Option key={index} value={val}>{val}</Option>)}
								</Select>
							</FormItem>
						</Col>
					</Row>
				</div>
				<div className="grant-min-content">
					<FormItem
						name="token_plan_id"
						required
						label={__("Plan name")}
						colon={false}
						validMessage={{
							required: __('Token option plan is required')
						}}
					>
						<Select placeholder={__("Select token plan")} disabled={disabled}>
							{tokenPlanList.map((val, index) => <Option key={index} value={val.id}>
								<div className="sprout-flex">
									<span>{val.name}</span>
									<span>{val.coin_name}</span>
								</div>
							</Option>)}
						</Select>
					</FormItem>
					<FormItem
						name="grant_name"
						label={<div>
							<Space>
								Vesting ID
								<Tooltip 
								title={__("If no grant name is added, the default name will be applied.")}
							>
								<QuestionCircleOutlined className="cursor-pointer" />
								</Tooltip>
							</Space>
							<div className="sub-form-title">- Optional</div>
						</div>}
						colon={false}
					>
						<Input disabled={disabled} />
					</FormItem>
					<FormItem
						name="quantity"
						label={__("Issued quantity")}
						required
						colon={false}
						$parser={(viewValue, $setViewValue) => {
							const reg = isFractionalShare ? /^\D*(\d*(?:\.\d{0,5})?).*$/g : /^\D*(\d*(?:)?).*$/g

							const value = viewValue.replace(reg, '$1');

							$setViewValue(value);

							return Number(value);
						}}

						validMessage={{
							required: __('Token number of tokens is required'),
						}}
					>
						<Input />
					</FormItem>
					<FormItem
						name="cash_value"
						label={<div>
							<Space>{__("Cash value")}</Space>
							<div className="sub-form-title">- Optional</div>
						</div>}
						colon={false}
					>
						<Input addonBefore={security.companyInfo.currency} />
					</FormItem>
					<FormItem
						name="grant_date"
						label={__("Grant date")}
						colon={false}
						required
						validMessage={{
							required: __('Grant date is required')
						}}
						$formatter={formatDateForDatePicker}
						$parser={val => val && formatDate2Api(val)}
					>
						<DateSelector
							disabled={disabled}
							disabledDate={val => val && startDate && startDate.isValid() && val.isBefore(startDate)}
						/>
					</FormItem>
					<FormItem
						name="vesting_schedule_id"
						label={<div>
							<Space>
								{__('Schedule name')}
							</Space>
							<div className="sub-form-title">- {__('Optional')}</div>
						</div>}
						colon={false}
					>
						<Select placeholder="Select vesting schedule" disabled={disabled}>
							{vestingSchedulesList.map((val, index) => <Option key={index} value={val.id}>{val.schedule_name}</Option>)}
						</Select>
					</FormItem>
					{
						history && <div className='permission-board-tips'>
							<a onClick={
								e => {
									e.preventDefault();
									close();
									history.push('/home/securities/templates/vesting')
								}
							}>{__('Manage vesting schedule')}
							</a>
						</div>
					}
					<FormItem
						name="vesting_start_date"
						label={<div>
							<Space>
								{__('Schedule start date')}
							</Space>
							<div className="sub-form-title">- {__('Optional')}</div>
						</div>}
						colon={false}
						$formatter={formatDateForDatePicker}
						$parser={val => val && formatDate2Api(val)}
					>
						<DateSelector
							disabled={disabled}
							disabledDate={val => val && startDate && startDate.isValid() && val.isBefore(startDate)}
						/>
					</FormItem>
				</div>
				<div className="assign-token-grant-modal-documents">
					<Collapse defaultActiveKey={['1']} ghost>
						<Collapse.Panel header="Documents" key="1">
							<FormItem
								name="grant_letter"
								label={<div>
									<Space>
										{/*Grant Letter:*/}
										{__('Vesting offer letter')}:
									</Space>
								</div>}
								required
								validMessage={{
									required: __('Vesting offer letter file is required!')
								}}
							>
								<UploadItem fileKey="file_obj" />
							</FormItem>
							<FormItem
								name="form_of_exercise_agreement"
								label={<div>
									<Space>
										{/*Form Of Exercise Agreement:*/}
										{__('Token purchase agreement')}:
									</Space>
									<div className="sub-form-title">- {__('Optional')}</div>
								</div>}
							>
								<UploadItem fileKey="file_obj" />
							</FormItem>
							<FormItem
								name="token_incentive_plan"
								label={<div>
									<Space>
										{__('Token incentive plan')}:
									</Space>
									<div className="sub-form-title">- {__('Optional')}</div>
								</div>}
							>
								<UploadItem fileKey="file_obj" />
							</FormItem>
							<FormItem
								name="additional_document"
								label={<div>
									<Space>
										{__('Additional document')}:
									</Space>
									<div className="sub-form-title">- {__('Optional')}</div>
								</div>}
							>
								<UploadItem fileKey="file_obj" multi={true} />
							</FormItem>
						</Collapse.Panel>
					</Collapse>
				</div>
				<div className="btn-wrap">
					<Button type="primary" loading={this.state.loading} onClick={this.onSubmit}>{__('Confirm')}</Button>
				</div>
			</div>
		</div>
	}
}

export const AssignTokenGrantContent = withForm(AssignTokenGrant)

export default function openAssignTokenGrantModal(callback, data, history) {
	return Modal.open({
		// component: props => <ModalContent close={props.close} title={data ? `Modify token grants` : 'Assign token grants'}><AssignTokenGrantContent data={data} close={props.close} callback={callback} history={history} /></ModalContent>,
		component: props => <ModalContent close={props.close} title={data ? __('Modify vesting') : __('Assign vesting')}><AssignTokenGrantContent data={data} close={props.close} callback={callback} history={history} /></ModalContent>,
		width: 780,
		className: 'sprout-modal'
	})
}

