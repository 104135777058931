import icon1 from './images/icon1.png';
import icon2 from './images/icon2.png';
import icon3 from './images/icon3.png';
import icon4 from './images/icon4.png';

import { formatNumber } from 'Utils';

const renderVaule = text => (text === 0 || text) ? text : '--'

const sorter = (a, b, c) => {
    console.log(a, b, c)
    return a.price - b.price
}

export const columns = [
    {
        title: __('Name'),
        dataIndex: 'name',
        ellipsis: true,
        fixed: 'left',
        render: renderVaule
    },
    {
        title: __('Price'),
        dataIndex: 'price',
        align: 'right',
        sorter: (a, b) => a.price - b.price,
        render: renderVaule
    },
    {
        title: '24h %',
        dataIndex: 'percentage_24h',
        sorter: (a, b) => a.percentage_24h - b.percentage_24h,
        render: renderVaule  
    },
    {
        title: '7d %',
        dataIndex: 'percentage_7d',
        sorter: (a, b) => a.percentage_7d - b.percentage_7d,
        render: renderVaule
    },
    // {
    //     title: 'Circulating supply market cap',
    //     dataIndex: 'circulating_supply_market_cap',
    //     sorter: (a, b) => a.circulating_supply_market_cap - b.circulating_supply_market_cap,
    //     align: 'right',
    //     render: text => formatNumber(text)
    // },
    {
        title: __('Fully diluted market cap'),
        dataIndex: 'fully_diluted_market_cap',
        sorter: (a, b) => a.fully_diluted_market_cap - b.fully_diluted_market_cap,
        align: 'right',
        render: text => formatNumber(text)
    },    
    {
        title: __('Max total supply'),
        dataIndex: 'max_total_supply',
        align: 'right',
        sorter: (a, b) => a.max_total_supply - b.max_total_supply,
        render: text => formatNumber(text)
    },
    {
        title: __('Holders'),
        dataIndex: 'holders',
        align: 'right',
        sorter: (a, b) => a.holders - b.holders,
        render: text => formatNumber(text, 0)
    },
    {
        title: __('Blockchain'),
        dataIndex: 'block_chain',
        align: 'right',
        render: renderVaule
    },
]

export function getOperationData({ addCoin }) {
    return [
        {
            title: __('Add Token'),
            color: '#00B080',
            func: addCoin,
            img: icon1
        }, 
        {
            title: __('How to Add Token?'),
            color: '#139FC5',
            img: icon2
        },
        {
            title: __('How to Add Tokenholder?'),
            color: '#00B080',
            img: icon3
        },
        {
            title: __('Need Help?'),
            color: '#139FC5',
            img: icon4
        }
    ]
}



