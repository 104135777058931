import React from 'react';
import Section from '../components/Section';

import {Table, Tooltip} from 'antd';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import { formatDate } from 'Utils/format/formatDate';
import {QuestionCircleOutlined} from "@ant-design/icons";
import { certificateStatus } from 'Utils/constant';

export default function Transaction(props) {
	const data = props.data || [];
	data.map((item, index) => {
		item.key = item.customlabelid + item.quantity;
		return item;
	});
	const finalResult = data;

	const transactionColumns = [
		{
			title: __('Security'),
			dataIndex: 'customlabelid',
		},
		{
			title: __('Transaction type'),
			dataIndex: 'transaction',
			render: value =>  certificateStatus[value]
		},
		{
			title: __('Quantity'),
			dataIndex: 'quantity',
			align: 'right',
			render: value => formatFractionalNumber(value)

		},
		{
			title: (
				<div>
					<span>{__('Applied date')}</span>
					<Tooltip title={__('the actual date that operation was done')}>
						<QuestionCircleOutlined  className="cert-title-position" />
					</Tooltip>
				</div>
			),
			dataIndex: 'apply_date',
			width: 180,
			align: 'center',
			render: formatDate

		},
		{
			title: (
				<div>
					<span>{__('Transaction date')}</span>
					<Tooltip title={__('the recorded date of the operation')}>
						<QuestionCircleOutlined  className="cert-title-position" />
					</Tooltip>
				</div>
			),
			dataIndex: 'transaction_date',
			width: 180,
			align: 'center',
			render: formatDate

		},
		{
			title: __('Operator'),
			dataIndex: 'operator',
			align: 'right',
		}
	]

	return <Section title={__('Transaction history')}>
		<div className="ea-transaction-table">
			<Table size="small" columns={transactionColumns} dataSource= {finalResult} pagination={false}/>
		</div>
	</Section>
}