import React, { Component } from 'react';
import { Table, Button,  Collapse, message, Spin } from 'antd';
import { formatNumber, formatPercent } from 'Utils';
import openCertificateModal from 'components/CertificateDetail';
import commonImg from '../../images/common.png';
import preferredImg from '../../images/preferred.png';
import optionImg from '../../images/option.png';
import convertibleImg from '../../images/convertible.png';
import Loading from 'components/Loading';

import CapTable from './components/Captable';
import Rights from './components/Rights';

import './style.scss';

export default class Capitalization extends Component {
	state = {
		tab: 'capTable'
	}

	handleClick = value => {
		const { tab } = this.state

		if(tab !== value) {
			this.setState({ tab: value })
		}
	}

	render() {
		const { tab } = this.state
		const { data = {} } = this.props
		const { currency } = data || {}

		return <div className="portfolio-capitalization-root">
			<div className="portfolio-btns">
				<Button type={ tab === 'capTable' ? 'primary' : '' } onClick={() => this.handleClick('capTable')} >{__('Cap Table')}</Button>
				<Button type={ tab === 'rights' ? 'primary' : '' } onClick={() => this.handleClick('rights')} >{__('Rights and Preferences')}</Button>
			</div>
			<div className="portfolio-capitalization-content">
				{
					tab === 'capTable' && <CapTable data={data} />
				}
				{
					tab === 'rights' && <Rights currency={currency} />
				}				
			</div>
		</div>
	}	
}