/**
 * Created by rain on 2019/7/30.
 */
import React from 'react';
import { Link } from "react-router-dom";
//import $ from 'jquery';
import axios from 'axios';
import Utils from 'Utils';

class RequestStockSplit extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            data:{
                stock_split_effective_date:"",
                common:{
                    before:"",
                    after:"",
                },
                preferred_stock:{
                    before:"",
                    after:"",
                },
                additional_notes:"",
            },
            stockSplitList:{
                checked:true,
                common:{
                    checked:true,
                    disabled:"",
                },
                preferred_stock:{
                    checked:true,
                    disabled:"",
                },
            },

        }
    }
    componentDidMount() {
        window.$('.stock_datepicker').datepicker({
            maxViewMode: 3,
            todayBtn: "linked",
            todayHighlight: true,
            autoclose: true,
            format: "dd/mm/yyyy"
        }).on("changeDate", (e) => {
            let curData = this.state.data;
            curData.stock_split_effective_date = e.format();
            this.setState({
                data: curData
            });
        });
    }
    resetActive=()=>{
        $("#requestStockSplitModal .tab-pane").removeClass("active");
        $("#requestStockSplitStep1").addClass("active");
    }
    checkNumInput=(key,e)=>{
        let dataKey = e.target.name;
        var curValue = e.target.value;
        curValue = Utils.checkNumInput(curValue);
        let curData = this.state.data;
        curData[key][dataKey] = curValue;
        this.setState({
            data:curData
        });
    }
    recordData=(e)=>{
        let dataKey = e.target.name;
        let curData = this.state.data;
        let curVal = e.target.value;
        curData[dataKey] = curVal;
        this.setState({
            data:curData
        })
    }
    itemsAllChecked=()=>{
        let curStSplitList = this.state.stockSplitList;
        curStSplitList.checked = !curStSplitList.checked;
        if(curStSplitList.checked === true){
            curStSplitList.common.checked = true;
            curStSplitList.preferred_stock.checked = true;
            curStSplitList.common.disabled="";
            curStSplitList.preferred_stock.disabled="";

        }else{
            curStSplitList.common.checked = false;
            curStSplitList.preferred_stock.checked = false;
            curStSplitList.common.disabled="disabled";
            curStSplitList.preferred_stock.disabled="disabled";
        }
        this.setState({
            stockSplitList:curStSplitList
        })
    }
    itemsChecked = (key)=>{
        let curChecked = this.state.stockSplitList;
        curChecked[key].checked = !curChecked[key].checked;
        if(curChecked[key].checked){
            curChecked[key].disabled="";
        }else{
            curChecked.checked = false;
            curChecked[key].disabled="disabled";
        }
        if(curChecked.common.checked && curChecked.preferred_stock.checked){
            curChecked.checked = true
        }
        this.setState({
            stockSplitList:curChecked
        })
    }
    render(){

        return(
            <div className="modal fade" id="requestStockSplitModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="tab-content">
                    <div className="tab-pane active" id="requestStockSplitStep1" role="tabpanel">
                        <div className="modal-dialog dialog-top40">
                            <div className="modal-content yescap-content">
                                <div className="modal-header box-header relation-header">
                                    <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                                    <h4 className="modal-title" id="myModalLabel">Request Stock Split</h4>
                                </div>
                                <div className="modal-body captable-body">
                                    <form className="captable-form">
                                        <div className="form-group">
                                            <div className="row">
                                                <label className="col-xs-7 add-label ques-label">Stock split effective date</label>
                                                <div className="col-xs-5 captable-input">
                                                    <input value={this.state.data.stock_split_effective_date} className="form-control date-calendar-with10padding calender-rCenter datepicker stock_datepicker" type="text" placeholder="DD/MM/YYYY"/>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                    <div className="captable-footer box-footer">
                                        <Link to="#requestStockSplitStep2" aria-controls="requestStockSplitStep2" role="tab" data-toggle="tab" type="button" className="btn boxbtn-to bg-darkblue ">Next: Enter Split Ratios</Link>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div className="tab-pane" id="requestStockSplitStep2" role="tabpanel">
                        <div className="modal-dialog stock-dialog">
                            <div className="modal-content">
                                <div className="modal-header box-header relation-header">
                                    <Link to="#requestStockSplitStep1" type="button" className="back-btn" aria-controls="requestStockSplitStep1" role="tab" data-toggle="tab"></Link>
                                    <button type="button" onClick={this.resetActive} className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                                    <h4 className="modal-title">Request Stock Split</h4>
                                </div>
                                <div className="modal-body captable-body reset_margin">
                                    <p className="text-muted" >Enter the split ratio for each share class.</p>
                                    <table className="table viewHoldings-table marginTop10 stock_split_table">
                                        <tbody>
                                            <tr className="first-row">
                                                <td className="check-box width50">
                                                    <input checked={this.state.stockSplitList.checked} onChange={this.itemsAllChecked} id="stock_check0" type="checkbox" className="re-checkbox checkboxTop8" />
                                                    <label htmlFor="stock_check0"></label>
                                                </td>
                                                <td className="width140">Share class</td>
                                                <td>Before</td>
                                                <td>After</td>
                                            </tr>
                                            <tr className="scheduleTr">
                                                <td className="check-box bg-waterblue">
                                                    <input checked={this.state.stockSplitList.common.checked}  onChange={this.itemsChecked.bind(this,"common")} id="stock_check1" type="checkbox" className="re-checkbox checkboxTop8" />
                                                    <label htmlFor="stock_check1"></label>
                                                </td>
                                                <td className="bg-waterblue">Common</td>
                                                <td className="bg-lightgray" colSpan="2">
                                                    <div className="flexDiv">
                                                        <div className="width40perc">
                                                            <input className="form-control " name="before" value={this.state.data.common.before} disabled={this.state.stockSplitList.common.disabled} onChange={this.checkNumInput.bind(this,"common")} type="text" placeholder="e.g. 1" />
                                                            <p className="alignLeft"><small className="text-muted">6,500,400 shares</small></p>
                                                        </div>
                                                        <span className="blockSpan">to</span>
                                                        <div className="width40perc">
                                                            <input className="form-control" name="after"  value={this.state.data.common.after}  disabled={this.state.stockSplitList.common.disabled} onChange={this.checkNumInput.bind(this,"common")}  type="text" placeholder="e.g. 2" />
                                                            <p className="alignLeft"><small className="text-muted">6,500,400 shares</small></p>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="scheduleTr">
                                                <td className="check-box bg-waterblue">
                                                    <input checked={this.state.stockSplitList.preferred_stock.checked} onChange={this.itemsChecked.bind(this,"preferred_stock")} id="stock_check2" type="checkbox" className="re-checkbox checkboxTop8" />
                                                    <label htmlFor="stock_check2"></label>
                                                </td>
                                                <td className="bg-waterblue">Preferred Stock</td>
                                                <td className="bg-lightgray" colSpan="2">
                                                    <div className="flexDiv">
                                                        <div className="width40perc">
                                                            <input className="form-control " name="before" value={this.state.data.preferred_stock.before}  disabled={this.state.stockSplitList.preferred_stock.disabled} onChange={this.checkNumInput.bind(this,"preferred_stock")} type="text" placeholder="e.g. 1" />
                                                            <p className="alignLeft"><small className="text-muted">6,500,400 shares</small></p>
                                                        </div>
                                                        <span className="blockSpan">to</span>
                                                        <div className="width40perc">
                                                            <input className="form-control" name="after"  value={this.state.data.preferred_stock.after}  disabled={this.state.stockSplitList.preferred_stock.disabled} onChange={this.checkNumInput.bind(this,"preferred_stock")}  type="text" placeholder="e.g. 2" />
                                                            <p className="alignLeft"><small className="text-muted">6,500,400 shares</small></p>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <textarea onChange={this.recordData} className="form-control minHeight100" value={this.state.data.additional_notes} name="additional_notes" placeholder="Additional notes (Optional)."></textarea>
                                </div>
                                <div className="captable-footer box-footer">
                                    <Link to="#requestStockSplitStep3" type="button" className="btn boxbtn-to bg-darkblue"  aria-controls="requestStockSplitStep3" role="tab" data-toggle="tab">Next: Review Split Request</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane" id="requestStockSplitStep3" role="tabpanel">
                        <div className="modal-dialog dialog-top40">
                            <div className="modal-content content520">
                                <div className="modal-header box-header">
                                    <Link to="#requestStockSplitStep2" type="button" className="back-btn"  aria-controls="requestStockSplitStep2" role="tab" data-toggle="tab"></Link>
                                    <button type="button" onClick={this.resetActive} className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                                    <h4 className="modal-title" id="myModalLabel">Request Stock Split</h4>
                                </div>
                                <div className="modal-body padding2026 reset_margin">
                                    <div className="body-one">
                                        <p className="box-text">Review the stock split details.</p>
                                        <table className="table merge-table review-relationship-table no-marginBottom ">
                                            <tbody>
                                            <tr>
                                                <td>Effective date</td>
                                                <td>{this.state.data.stock_split_effective_date}</td>
                                            </tr>
                                            <tr>
                                                <td>Common</td>
                                                <td>{this.state.data.common.before}&nbsp; to {this.state.data.common.after} split<br />
                                                    <small className="text-muted">From 6,5000,400 to 13,000,800 shares</small>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Preferred stock</td>
                                                <td>{this.state.data.preferred_stock.before} to {this.state.data.preferred_stock.after} split<br />
                                                    <small className="text-muted">From 6,5000,400 to 13,000,800 shares</small>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Additional notes</td>
                                                <td>{this.state.data.additional_notes}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <p className="text-muted marginTop10">You will receive a post-split cap table to review with within 3 business days.</p>
                                    </div>
                                </div>
                                <div className="modal-footer box-footer stock-footer">
                                    <button type="button" className="btn boxbtn-to bg-darkblue multiple-btn" onClick={this.resetActive}  data-dismiss="modal">Request</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default RequestStockSplit;

