import React, { Component } from "react";
import {Button, Card, Divider, message} from "antd";
import moment from 'moment'
import { formatDate } from 'Utils/format/formatDate'
import Back from "../../img/back.png"
import { calculator, I } from "Utils";
import { post } from 'srcPath/http';
import "./style.scss";

class ConfirmPlan extends Component {

    dateTime = moment().format();

    state = {
        loading: false,
    };

    goBack = () => {
        const { getPage } = this.props;
        getPage(0);
    }

    getMoment = (startDate, endDate) => {
        return moment(endDate).diff(moment(startDate),'month');
    }

    getDays = (startDate, endDate) => {
        if(startDate && endDate){
            return calculator.agile(`${moment(endDate).diff(moment(startDate),'day')} `);
        }
        return 0;
    }

    getUpgradePrice = (itemPrice, dayNum, planStartDate, planEndDate) => {
        const result = calculator.agile(` ${parseFloat(I(itemPrice, 0)).toFixed(2)} / ${parseInt(this.getDays(planStartDate, planEndDate))} * ${parseInt(dayNum)} `);
        return parseFloat(result).toFixed(2);
    }

    getPayUrl = async (price, planId, planName, couponSn, priceId) => {
        this.setState({ loading: true });
        try {
            const { data: resData } = await post('singlePay', { price: parseFloat(price).toFixed(2), goodsId: planId, couponSn, goodsPriceId: priceId })
            const { code, data } = resData;
            if (code === 0) {
                window.location.href = data.payUrl;
            }else{
                switch (code){
                    case 2:
                        message.error('Invalid code, please check again.');
                        break;
                    case 3:
                        message.error('Couldn\'t find the subscription number, please contact us to generate one.');
                        break;
                    case 5:
                        message.error('Current plan couldn\'t change to monthly payment.');
                        break;
                    case 6:
                        message.error('Order error, please try again.');
                        break;
                    case 7:
                        message.error('Order error, please try again.');
                        break;
                    default:
                }
                message.error(`Error code: ${code}!`);
            }
        } catch(err) {
            message.error(err && err.message)
        }
        this.setState({ loading: false });
    }

    planCycle = {3:'month', 4:'year'};

    getPrice = (price, decimal = 2) => {
        const newPrice = calculator.agile(`${Math.round(parseInt(I(calculator.agile(`${price} * 100`), 0)))} / 100`);
        const priceArray = newPrice.split('.');
        const right = I(priceArray[1], '').toString().padEnd(decimal, '0');
        return `${priceArray[0]}.${right}`;
    }

    render() {
        const { loading } = this.state;
        const { confirmData } = this.props;
        const { planId, planName, endDate, itemPrice, currentPrice, type, subscriptionCycle, oldPlanName, planStartDate, planEndDate, priceId } = confirmData;
        const dayNum = this.getDays(formatDate(this.dateTime), formatDate(endDate));
        const upgradePriceRes = this.getUpgradePrice(itemPrice, dayNum, planStartDate, planEndDate);
        const currentPriceRes = this.getUpgradePrice(currentPrice, dayNum, planStartDate, planEndDate);
        const upgradeFee = calculator.agile(` ${upgradePriceRes} - ${currentPriceRes} `);
        return (
            <div className="config-plan-root">
                <Button className="config-plan-back" icon={<img src={Back}/> } onClick={this.goBack}>Back</Button>
                <h2>Confirm upgrade</h2>
                {type === 1 && <Card className="config-plan-card">
                    <h3>Upgrade fee:  {oldPlanName} to {planName}</h3>
                    <div className="config-plan-table">
                        <h4>{formatDate(this.dateTime)} ~ {formatDate(endDate)}</h4>
                        <div className="config-plan-table-list">
                            <div className="config-plan-table-list-info">
                                <span>Upgrade to {planName} (${itemPrice} per {I(this.planCycle[subscriptionCycle], '')}) - {dayNum} days</span>
                                <span>${this.getPrice(upgradePriceRes)} USD</span>
                            </div>
                            <div className="config-plan-divider" />
                            <div className="config-plan-table-list-info">
                                <span>Current: {oldPlanName} (${currentPrice} per {I(this.planCycle[subscriptionCycle], '')}) - {dayNum} days</span>
                                <span>${this.getPrice(currentPriceRes, 2)} USD</span>
                            </div>
                            <div className="config-plan-divider" />
                            <div className="config-plan-table-list-info config-plan-black">
                                <span>Upgrade fee</span>
                                <span>${this.getPrice(upgradeFee, 2)} USD</span>
                            </div>
                            <div className="config-plan-table-list-total">
                                <span>Total</span>
                                <span>${this.getPrice(upgradeFee, 2)} USD</span>
                            </div>
                        </div>
                    </div>
                    <p className="config-plan-desc-first">You are upgrading your service plan to the <span className="config-plan-blue">{planName}</span> in Folium, according to our MSA, starting from <span className="config-plan-blue">{formatDate(this.dateTime)}</span>, the next billing date will be on <span className="config-plan-blue">{formatDate(endDate)}</span> for a subscription fee of <span className="config-plan-blue">${this.getPrice(upgradeFee, 2)} USD</span>.</p>
                    <Button loading={loading} className="config-plan-confirm-button-second" type="primary" onClick={this.getPayUrl.bind(this, upgradeFee, planId, planName, '', priceId)}>Confirm and upgrade</Button>
                    <p className="config-plan-desc-second">By pressing "Confirm and upgrade" button, you acknowledge that you have read our <a href="https://www.getsprout.co/legal/pps/" target="_blank">Privacy Policy</a> and agree to our <a href="https://www.getsprout.co/legal/tos/" target="_blank">Terms of Service</a>.</p>
                </Card>}
                {type === 2 && <Card className="config-plan-card-second">
                    <p className="config-plan-desc-first config-plan-desc-other">You are upgrading your service plan to the <span className="config-plan-blue">{planName}</span> in Folium, according to our MSA, starting from <span className="config-plan-blue">{formatDate(this.dateTime)}</span>, the next billing date will be on <span className="config-plan-blue">{formatDate(endDate)}</span> for a subscription fee of <span className="config-plan-blue">${itemPrice} USD</span>.</p>
                    <Button loading={loading} className="config-plan-confirm-button" type="primary" onClick={this.getPayUrl.bind(this, upgradeFee, planId, planName, '', priceId)}>Confirm and upgrade</Button>
                    <p className="config-plan-desc-second">By pressing "Confirm and upgrade" button, you acknowledge that you have read our <a href="https://www.getsprout.co/legal/pps/" target="_blank">Privacy Policy</a> and agree to our <a href="https://www.getsprout.co/legal/tos/" target="_blank">Terms of Service</a>.</p>
                </Card>}
            </div>
        );
    }
}
export default ConfirmPlan;