import React, { Component } from "react";
import {Button, Dropdown, Menu, Space} from "antd";
import { I } from "Utils";
import security from 'stores/security';
import "./style.scss";
import Checkbox from "antd/es/checkbox/Checkbox";

export default class ColumnFilter extends Component {

    state = {
        visible: false,
    };

    menuChange = (data) => {
        const { key } = data;
        const { filterList, updateState } = this.props;
        const index = filterList.findIndex( v => v.dataIndex == key );
        let newFilterList = [...filterList];
        newFilterList[index].isChecked = !!!newFilterList[index].isChecked;
        updateState({filterList: newFilterList});
        this.saveFilter(newFilterList, 'historyCertificate');
    }

    /**
     * 缓存filter数据,用于记录用户行为
     * @param newFilterList
     */
    saveFilter = (newFilterList, key) => {
        const uid = security.userInfo && security.userInfo.id;
        const { companyId } = security;
        const filter = localStorage.getItem('filter');
        const filterObj = I(filter, '') ? JSON.parse(filter) : {};
        filterObj[key] = {...filterObj[key], ...{[`${uid}-${companyId}`]: newFilterList}};
        localStorage.setItem('filter', JSON.stringify(filterObj));
    }

    menu = () => {
        const { filterList } = this.props;
        return <Menu
            multiple={false}
            onClick={this.menuChange}
            className="table-column-menu"
        >
            <Menu.ItemGroup title={__('SELECT COLUMN')}>
                {this.menuList(filterList)}
            </Menu.ItemGroup>
        </Menu>
    }

    menuList = (filterList) => {
        return filterList.map((v)=>{
            const { isChecked, isDisabled, title, dataIndex } = v;
            return (
                <Menu.Item key={dataIndex} disabled={isDisabled}>
                    <Space size={6}><Checkbox checked={isChecked} disabled={isDisabled} />
                        <span>{title}</span>
                    </Space>
                </Menu.Item>
            )
        })
    }

    onVisibleChange = (visible) => {
        this.setState({visible})
    }

    render() {
        const { visible } = this.state;
        return (
            <Dropdown
                overlay={this.menu}
                trigger={['click']}
                getPopupContainer={ triggerNode => triggerNode.parentNode }
                visible={visible}
                onVisibleChange={this.onVisibleChange}
                className="table-column"
            >
                <Button type="primary" className="table-column-filter">
                    <div className="set_filter-pic"></div>
                    <div className="triangle triangle-whitedown filter-triangle"></div>
                </Button>
            </Dropdown>
        );
    }
}

export const columnsFilter = (columns, filterList) => {
    for(let i in filterList){
        for(let j in columns){
            if(columns[j].dataIndex == filterList[i].dataIndex && filterList[i].isChecked == false){
                columns.splice(j, 1);
            }
        }
    }
    return [...columns];
}