import React from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import Utils from 'Utils';

class RequestRepricing extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            data:{
                repricing_effective_date:"",
                new_exercise_price:"0.00",
            }
        }
    }
    componentDidMount() {
        window.$('.stock_datepicker').datepicker({
            maxViewMode: 3,
            todayBtn: "linked",
            todayHighlight: true,
            autoclose: true,
            format: "dd/mm/yyyy"
        }).on("changeDate", (e) => {
            let curData = this.state.data;
            curData.repricing_effective_date = e.format();
            this.setState({
                data: curData
            });
        });
    }
    resetActive=()=>{
        $("#requestRepricingModal .tab-pane").removeClass("active");
        $("#requestRepricingStep1").addClass("active");
    }
    recordNumber=(key,e)=>{
        let curData = this.state.data;
        curData[key] = Utils.checkNumInput(e.target.value);
        this.setState({
            data:curData
        });
    }
    recordData=(e)=>{
        let dataKey = e.target.name;
        let curData = this.state.data;
        let curVal = e.target.value;
        curData[dataKey] = curVal;
        this.setState({
            data:curData
        })
    }
    render(){
        const { currency = '' } = this.props
        return(
            <div className="modal fade" id="requestRepricingModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="tab-content">
                    <div className="tab-pane active" id="requestRepricingStep1" role="tabpanel">
                        <div className="modal-dialog dialog-top40">
                            <div className="modal-content content460">
                                <div className="modal-header box-header">
                                    <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                                    <h4 className="modal-title" id="myModalLabel">Request a repricing</h4>
                                </div>
                                <div className="modal-body padding2030">
                                    <div className="form-group row marginBott10">
                                        <label className="col-xs-6 add-label">Repricing effective date<br/>
                                            <i><small className="text-muted">Required</small></i>
                                        </label>
                                        <div className="col-xs-6 no-paddingleft">
                                            <input value={this.state.data.stock_split_effective_date} className="form-control date-calendar-with10padding calender-rCenter datepicker stock_datepicker" type="text" placeholder="DD/MM/YYYY"/>
                                        </div>
                                    </div>
                                    <div className="form-group row marginBott10">
                                        <label className="col-xs-6 add-label">New exercise price<br/>
                                            <i><small className="text-muted">Required</small></i>
                                        </label>
                                        <div className="col-xs-6 no-paddingleft">
                                            <div className="input-group">
                                                <span className="input-group-addon" id="new_exercise_price">{currency}</span>
                                                <input type="text" name="new_exercise_price" onChange={this.recordNumber.bind(this,"new_exercise_price")} value={this.state.data.new_exercise_price} className="form-control" aria-describedby="new_exercise_price" placeholder="100.00"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer box-footer">
                                    <Link to="#requestRepricingStep2" aria-controls="requestStockSplitStep2" role="tab" data-toggle="tab" type="button" className="btn boxbtn-to bg-darkblue ">Next: review repricing request</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane" id="requestRepricingStep2" role="tabpanel">
                        <div className="modal-dialog dialog-top40">
                            <div className="modal-content content480">
                                <div className="modal-header box-header">
                                    <Link to="#requestRepricingStep1" type="button" className="back-btn"  aria-controls="requestRepricingStep2" role="tab" data-toggle="tab"></Link>
                                    <button type="button" onClick={this.resetActive} className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                                    <h4 className="modal-title" id="myModalLabel">Request repricing</h4>
                                </div>
                                <div className="modal-body padding2026">
                                    <div className="body-one">
                                        <p className="box-text">Review the repricing request details.</p>
                                        <table className="table merge-table review-relationship-table no-marginBottom ">
                                            <tbody>
                                            <tr>
                                                <td>Repricing effective date</td>
                                                <td>{this.state.data.repricing_effective_date}</td>
                                            </tr>
                                            <tr>
                                                <td>New exercise price</td>
                                                <td>{currency} {this.state.data.new_exercise_price}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <p className="text-muted marginTop10 mb-0">You will receive a post-repricing cap table to review with within 3 business days.</p>
                                    </div>
                                </div>
                                <div className="modal-footer box-footer stock-footer">
                                    <button type="button" className="btn boxbtn-to bg-darkblue multiple-btn" onClick={this.resetActive}  data-dismiss="modal">Request Repricing</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default RequestRepricing;

