import React, { Component, Fragment } from "react";
import {Button, Card, Radio, Checkbox, Space, message, Tooltip, Skeleton} from "antd";
import { post } from 'srcPath/http';
import { LeftOutlined, CheckOutlined } from '@ant-design/icons';
import { I } from 'Utils';
import Stars from '../../Img/stars.png'
import "./style.scss";
class ChangePlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 1,
            loading: false,
            myPlan: { planId:0, functionId:[], serviceId:[], checkPlan: 0},
            planList: {},
        }
    }

    componentDidMount() {
        //获取company 当前计划以及可调配计划
        this.initPlan();
    }

    /**
     * 获取company 当前计划以及可调配计划
     * @returns {Promise<void>}
     */
    initPlan = async () => {
        this.setState({loading:true});
        try {
            const { data: resData } = await post('getCompanyPlanInfo')
            const { code, data } = resData || {}
            if (code == 0) {
                const myPlan = {
                    planId: I(data.plan_id, 0),
                    functionId: I(data.function_id, []),
                    serviceId: I(data.service_id, []),
                    checkPlan: !!!data.plan_list[I(data.plan_id, 0)] ? Object.keys(data.plan_list)[0] : I(data.plan_id, 1),
                };
                let newPlanList = {...data.plan_list};
                if(!!newPlanList[myPlan['checkPlan']]){
                    let serviceList = newPlanList[myPlan['checkPlan']]['service'];
                    if(I(myPlan['serviceId'], '')){
                        serviceList.forEach((v, k)=>{
                            myPlan['serviceId'].forEach((v1, k1)=>{
                                if(v1 == v.id){
                                    newPlanList[myPlan['checkPlan']]['service'][k]['isChecked'] = true;
                                }
                            })
                        });
                    }
                }

                this.setState({
                    myPlan,
                    planList: I(newPlanList, {}),
                });
            } else {
                message.error(data)
            }
            this.setState({loading:false});
        } catch(err) {
            message.error(err && err.message)
        }
    }

    radioChange = (e) => {
        const { value } = e.target;
        const { myPlan } = this.state;
        let newMyPlan = {...myPlan};
        newMyPlan['checkPlan'] = value;
        this.setState({ myPlan: newMyPlan })
    }

    getPage = (value) => {
        const { getPage } = this.props;
        getPage(value);
    }

    getRadioPlan = () => {
        const { planList, myPlan } = this.state;
        return I(planList, {}) && (Object.keys(planList)).map((v, k)=>{
            return <Fragment key={k}>
                <Radio className="billing-change-plan-radio" value={v}>
                    <div>{myPlan.planId == v && 'Current plan: '}{I(planList[v]['plan_name'], '')}</div>
                    {I(planList[v]['plan_label'], '') && <div className="billing-change-plan-number">{planList[v]['plan_label']}</div>}
                </Radio>
                {I(planList[v]['plan_details'], '') &&
                <div className={['billing-change-plan-additional', myPlan.checkPlan == v && 'billing-change-plan-additional-active'].join(' ')}>
                    <p>{planList[v]['plan_details'][0]}</p>
                    <ul>
                        {
                            planList[v]['plan_details'] && planList[v]['plan_details'].map((v1, k1)=>{
                                if(k1 > 0){
                                    return <li><div className="billing-change-plan-yes"></div>{v1}</li>
                                }
                            })
                        }
                    </ul>
                </div>}
            </Fragment>
        });
    }

    getServiceList = () => {
        const { planList, myPlan } = this.state;
        if(myPlan['checkPlan']){
            if(I(planList[myPlan['checkPlan']], '')){
                const list = planList[myPlan['checkPlan']]['service'].concat(planList[myPlan['checkPlan']]['function']);
                return list && list.map((v1, k1)=>{
                return !!v1.name && <div className="billing-change-plan-service">
                        <img src={Stars} width={10} />
                        <span className="billing-change-plan-service-txt">{v1.name}</span>
                        {/*{I(v1.isChecked, '') && <Button disabled={true} className="billing-change-plan-service-button">Included</Button>}*/}
                        {I(v1.function_remark, '') && <Tooltip title={I(v1.function_remark, '')} overlayClassName="billing-change-plan-service-help">
                            <div className="billing-change-plan-help">?</div>
                        </Tooltip>}
                    </div>
                });
            }
        }
    }

    render() {
        const { myPlan, loading } = this.state;
        return (
                <div className="billing-change-plan-root">
                    <Button className="billing-change-plan-back" onClick={this.getPage.bind(this, 'overview')} >
                        <LeftOutlined />Back
                    </Button>
                    {/*<div className="billing-change-plan-title">Plans & Add-ons</div>*/}
                    <Card className="billing-change-plan-card">
                        <Skeleton loading={loading} active >
                        <h3>Plan</h3>
                        <div className="billing-change-plan-plan">
                            <Radio.Group onChange={this.radioChange} value={myPlan.checkPlan}>
                                <Space direction="vertical" size={4}>
                                    { this.getRadioPlan() }
                                </Space>
                            </Radio.Group>
                        </div>
                        {/*<h3 className="billing-change-plan-services-title">Add-ons</h3>
                        <div className="">
                            { this.getServiceList() }
                        </div>*/}
                        <Button
                            className="billing-change-plan-service-speak"
                            type="primary"
                            onClick={this.getPage.bind(this, 'SpeakToUs')}
                        >Speak with us</Button>
                        </Skeleton>
                    </Card>
                </div>
        );
    }
}
export default ChangePlan;