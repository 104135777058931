import React, { Component, Fragment } from "react";

/**
 * waterfall头部文件
 */
class HeaderModels extends Component {

    constructor(props) {
        super(props);
    }

    typeChange = (type) => {
        this.props.typeChange(type);
    }

    render() {
        const { __LANG } = this.props;
        return (
            <Fragment>
                <h2 className="title">{__LANG.title}</h2>
                <div className="form_box" >
                    {/*导航*/}
                    <div className="banner-title">
                        <div className="title-div" onClick={this.typeChange.bind(this, 1)}>
                            <span>{__LANG.financingRound}</span>
                        </div>
                    </div>
                    <div className="banner-title ml-10 width-140">
                        <div className="title-div active">
                            <span className="title-span">{__LANG.waterfall}</span>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default HeaderModels;