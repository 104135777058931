import React, { Component } from 'react';
import Chart from 'components/Echarts';
import Utils, { tranNumber, formatCurrencyNumber } from "Utils";
import emptyImg from 'assets/images/bar.png';

class Echarts extends Component {
    constructor(props){
        super(props);
        this.state = {}
    }
    totalCash = 0

    formatCashOption() {
        const { data = [], currency = 'HKD' } = this.props;
        const filterData = data.filter((item) => {
            return item.cashPaid > 0;
        });
        const result = [];
        const obj = {};
        filterData.map((item) => {
            if (!obj[item.shareClassName]) { // 第一个
                obj[item.shareClassName] =  { 'shareClassName': item.shareClassName, 'cashPaid':  Number(item.cashPaid), 'type': item.type, name: item.name };
            } else { // 已经有了，需要累加
                obj[item.shareClassName].cashPaid += Number(item.cashPaid); 
            }
        });
        for (let key in obj) {
            result.push(obj[key]);
        }

        const optionObj = {
            nameList: [],
            cashRaisedList: [],
        };
        result.map((item) => {
            optionObj.nameList.push(item.shareClassName);
            optionObj.cashRaisedList.push({...item, value: Number(item.cashPaid)});
            this.totalCash += Number(item.cashPaid);
        });

        const option = {
            grid: {
                containLabel: true,
                left: 10,
                top: 20,
                right: 20,
                bottom: 0
            },
            legend: {
                data: optionObj.nameList,
                right: 20
            },
            
            xAxis: {
                type: 'category',
                data: optionObj.nameList,
                axisLabel: {
                    rotate:20
                },
                axisTick: {
                    show: false
                },
                axisLine: {
                    lineStyle: {
                        color: '#524e4ed9'
                    }
                }
            },
            yAxis: {
                type: 'value',
                axisTick: {
                    show: false
                },
                axisLine: {
                    lineStyle: {
                        color: '#524e4ed9'
                    }
                },
                axisLabel: {
                    formatter: (value) => {
                        return tranNumber(value, 1, true);
                    }
                }
            },
            series: [{
                data: optionObj.cashRaisedList,
                type: 'bar',
                barMaxWidth: 50,
                barGap: 1,
                itemStyle: {
                    normal: {
                        label: {
                          formatter: (params) => {
                            return formatCurrencyNumber(params.value, 0)
                          },
                          show: true,
                          position: "top",
                          textStyle: {
                            fontWeight: "bolder",
                            fontSize: "12",
                            color: "#898989"
                          }
                        },
                        color: (params) => {
                            return result[params.dataIndex].type !== 'convertibles' ? 'rgb(2, 161, 254)' : '#d4d282';
                        }, 
                    },
                }
            }]
        };
        return option;
    }
    render() {
        const option = this.formatCashOption();
 
        if (option.series[0].data && option.series[0].data.length === 0) {
            return  <div className="empty-img"><img src={emptyImg} /></div>
        }

        return <Chart option={option} />
    }
}

export default Echarts;