import React from 'react';
import { FormItem } from 'react-antd-formutil';
import { Checkbox,} from 'antd'
import { relationshipList } from 'Utils/constant';
import { omit } from 'lodash';


const Group = Checkbox.Group

export default function Relationship(props) {
    const { name, label } = props

    return <FormItem
        name={name || 'relationship'}
        label={label || __('Relationship')}
    >
        <Group {...omit(props, ['name', 'label'])} showSearch placeholder={__('Select')} options={[ ...(relationshipList.map(value => ({ label: value, value }))), { label: 'None entered', value: 'None entered' }]} />
    </FormItem>

}