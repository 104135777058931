import React, { Fragment } from 'react';
import { Tooltip, Space } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import SimpleTable from '../components/SimpleTable';
import { formatDate } from 'Utils/format/formatDate';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';

export default function Certificate({ data }) {
    const { name, email, wallet_address_list, destination_wallet_address, grant_date, quantity = 0, vested_tokens = 0, claimed_tokens, unclaimed_tokens, original_wallet_address, cash_value = 0 } = data || {}

	const tokenHolderConfig = [
		{
			label: __('Name'),
			value:  name
		},
		{
			label: __('Email'),
			value: email
		},
		{
			label: __('Wallet address'),
			value: wallet_address_list?.length ? wallet_address_list.map((val, index) => <div key={index}>{val?.wallet_address}</div>) : '--'
		}
	]

	const grantDetailConfig = [
		{
			label: __('Grant date'),
			value: formatDate(grant_date)
		},
		{
			label: __('Cash Value'),
			value: formatFractionalNumber(cash_value)
		},
		{
			label: __('Issued quantity'),
			value: formatFractionalNumber(quantity),
		},
		{
			label: __('Origin Wallet'),
			value: original_wallet_address
		},
		{
			label: __('Destination Wallet'),
			value: destination_wallet_address
		},
		{
			label: __('Vested tokens'),
			value: formatFractionalNumber(vested_tokens)
		},
		{
			label: __('Unvested tokens'),
			value: formatFractionalNumber(quantity - vested_tokens)
		},
        {
			label: <Space><span>Claimed tokens</span><Tooltip title="Shows under Destination wallet on Cap Table"><QuestionCircleOutlined style={{ cursor: 'pointer' }} /></Tooltip></Space>,
            value: formatFractionalNumber(claimed_tokens)
        },
        {
            label: <Space><span>Unclaimed tokens</span><Tooltip title="Shows under Origin wallet on Cap Table"><QuestionCircleOutlined style={{ cursor: 'pointer' }}/></Tooltip></Space>,
            value: formatFractionalNumber(unclaimed_tokens)
        }
	]

	return <Fragment>
 		<div className="certificate-detail-section">
			<SimpleTable title={__('Tokenholder')} config={tokenHolderConfig} />
		</div>

 		<div className="certificate-detail-section">
			<SimpleTable title={__('Grant Details')} config={grantDetailConfig} />
		</div>
	</Fragment>
}