import React, { Component } from "react";
import {Button, Input, Table} from "antd";
import { formatNumber, formatCurrencyNumber, I, clearNoNum, filterAmount, setCursorPos } from "Utils";
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import moment from "moment";
import Loading from 'components/Loading';
import {formatWithArray} from "Utils";

class CapitalizationCn extends Component {

    state = {
        cnForm: []
    };

    cnColumns = ( key, financingData, k) => {
        const { cnForm } = this.state;
        const { amountSymbol } = this.props.data;
        const checkedValue = (text, selectedRowKeys, customLabelId) => !!selectedRowKeys && I(text, '') && selectedRowKeys.indexOf(customLabelId) !== -1 && true || false;
        const { selectedRowKeys } = financingData;
        return [
            {
                title: __('Stakeholder'),
                dataIndex: 'stakeholder',
                width: 200,
                render: (text, data, index) => {
                    return <>
                        {
                            data.isNew ? <Input
                                className="financing-investment-investment financing-investment-cn-name"
                                defaultValue={text}
                                value={cnForm[`${k}-${key}-${data.stakeholder_id}-stakeholder`]}
                                onChange={this.cnChangeUpdate.bind(this, `${k}-${key}-${data.stakeholder_id}-stakeholder`, {isNumber:false})}
                                onBlur={this.cnBlurUpdate.bind(this, index, key, 'stakeholder')}
                                bordered={false}
                            /> : `${text} (${data.custom_label_id})`
                        }
                    </>
                },
            },
            {
                title: __('Investment'),
                dataIndex: 'principal',
                align: 'right',
                width: 250,
                render: (text, data, index) => {
                    return <>
                        {data.isNew ? <Input
                            className="financing-investment-investment new-financing-align-right"
                            addonBefore={amountSymbol}
                            defaultValue={formatWithArray(text)}
                            value={cnForm[`${k}-${key}-${data.stakeholder_id}-principal`]}
                            bordered={false}
                            onChange={this.cnChangeUpdate.bind(this, `${k}-${key}-${data.stakeholder_id}-principal`, {isNumber:true})}
                            onBlur={this.cnBlurUpdate.bind(this, index, key, ['principal', 'principalInterest'])}
                        /> : <span className="new-financing-pr-10">{`${formatCurrencyNumber(text, 0)}`}</span>}
                    </>
                },
            },
            {
                title: __('Valuation cap'),
                dataIndex: 'valuation_cap',
                width: 250,
                render: (text, data, index) => {
                    return <>
                        {data.isNew ? <Input
                            className="financing-investment-investment new-financing-align-right"
                            addonBefore={amountSymbol}
                            defaultValue={formatWithArray(text)}
                            value={cnForm[`${k}-${key}-${data.stakeholder_id}-valuation_cap`]}
                            bordered={false}
                            onChange={this.cnChangeUpdate.bind(this, `${k}-${key}-${data.stakeholder_id}-valuation_cap`, {isNumber:true})}
                            onBlur={this.cnBlurUpdate.bind(this, index, key, 'valuation_cap')}
                        /> : <span className="new-financing-pr-10">{`${formatCurrencyNumber(text, 0)}`}</span>}
                    </>
                },
                align: 'right',
            },
            {
                title: __('Discount'),
                dataIndex: 'conversion_discount',
                align: 'right',
                className: 'new-financing-discount',
                width: 200,
                render: (text, data, index) => {
                    return <>
                        {data.isNew ? <Input
                            className="financing-investment-investment new-financing-align-right"
                            addonAfter="%"
                            defaultValue={text}
                            value={cnForm[`${k}-${key}-${data.stakeholder_id}-conversion_discount`]}
                            bordered={false}
                            onChange={this.cnChangeUpdate.bind(this, `${k}-${key}-${data.stakeholder_id}-conversion_discount`, {isNumber:true, max:100})}
                            onBlur={this.cnBlurUpdate.bind(this, index, key, 'conversion_discount')}
                        /> : <span className="new-financing-pr-45">{`${formatNumber(text, 2)}%`}</span>}
                    </>
                },
            },
            {
                title: __('Share price'),
                dataIndex: 'sharePrice',
                align: 'right',
                className: 'new-financing-cn-td',
                width: 250,
                render: (text, data, index) => checkedValue(text, selectedRowKeys[key], data.custom_label_id) && formatCurrencyNumber(text) || '--',
            },
            {
                title: __('Ownership'),
                dataIndex: 'ownership',
                align: 'right',
                className: 'new-financing-cn-td',
                width: 250,
                render: (text, data, index) => checkedValue(text, selectedRowKeys[key], data.custom_label_id) && `${formatNumber(text)}%` || '--',
            },
            {
                title: __('Shares'),
                dataIndex: 'shares',
                align: 'right',
                className: 'new-financing-cn-td',
                width: 250,
                render: (text, data, index) => checkedValue(text, selectedRowKeys[key], data.custom_label_id) && `${formatFractionalNumber(text)}` || '--',
            },
            {
                title: '',
                dataIndex: 'shares',
                align: "center",
                className: 'new-financing-cn-td',
                width: 60,
                render: (text, data, index) => {
                    return <>{data.isNew ? <span onClick={this.removeCnData.bind(this, key, index)} className="icons_minus">-</span> : ''}</>
                },
            },
        ];
    }

    rowSelection = (key) => {
        const { k, financingData, updateState } = this.props;
        const { selectedRowKeys } = !!financingData[k] && financingData[k];
        return {
            selectedRowKeys: selectedRowKeys[key],
            onChange: (rowKeys) => {
                const { financingData:data, k:index } = this.props;
                let newData = [...data];
                newData[index]['selectedRowKeys'][key] = rowKeys;
                updateState({financingData: newData});
            }
        }
    };

    getSummary = (cnTotalList, key) => {
        if(cnTotalList){
            const { ownershipTotal, sharesTotal } = cnTotalList[key];
            return (
                <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={6} className="new-financing-pl-12">{__('Subtotal')}</Table.Summary.Cell>
                    <Table.Summary.Cell className="new-financing-align-right new-financing-pr-12">{formatNumber(ownershipTotal)}%</Table.Summary.Cell>
                    <Table.Summary.Cell className="new-financing-align-right new-financing-pr-12">{formatFractionalNumber(sharesTotal)}</Table.Summary.Cell>
                    <Table.Summary.Cell className="new-financing-align-right"></Table.Summary.Cell>
                </Table.Summary.Row>
            )
        }
    }

    addCnData = (key) => {
        const { cnForm } = this.state;
        const { k, financingData, updateState, cnDataList, data, newStakeholderDataList } = this.props;
        const number = Math.ceil(Math.random()*1000000);
        const date = moment().format('DD/MM/YYYY');
        const newCn = {
            stakeholder_id: `stakeholderId${number}`,
            custom_label_id: `CN-${number}`,
            principal: '',
            valuation_cap: '',
            conversion_discount: '',
            issue_date: date,
            interest_rate: '0',
            stakeholder: '',
            principalInterest: '',
            isExist: 0,
            isNew: true,
        };
        const newFinancingData = [...financingData];
        const cnDataDetail = JSON.parse(JSON.stringify(newFinancingData[k].cnData));
        cnDataDetail[key] = [...[newCn], ...cnDataDetail[key]];
        newFinancingData[k].cnData = cnDataDetail;
        newFinancingData[k].selectedRowKeys = cnDataList(cnDataDetail);
        cnForm[`${k}-${key}-${newCn.stakeholder_id}-stakeholder`] = '';
        cnForm[`${k}-${key}-${newCn.stakeholder_id}-principal`] = '';
        cnForm[`${k}-${key}-${newCn.stakeholder_id}-valuation_cap`] = '';
        cnForm[`${k}-${key}-${newCn.stakeholder_id}-conversion_discount`] = '';
        this.setState({cnForm});
        updateState({financingData});
    }

    chBlurUpdate = () => {
        // const { financingData, updateState } = this.props;
        // updateState({financingData});
    }

    removeCnData = (key, index) => {
        Loading.global(true);
        const { k, financingData, updateState, data } = this.props;
        const { stakeholderData } = data;
        let newStakeholderData = [...stakeholderData];
        const { cnData } = financingData[k];
        if(cnData[key].length <= 1){
            Loading.global(false);
            return ;
        }
        let stakeholderIndex = newStakeholderData.findIndex( v => v.id === cnData[key][index].stakeholder_id);
        if(stakeholderIndex !== -1){
            newStakeholderData.splice(stakeholderIndex, 1);
        }
        cnData[key].splice(index, 1);
        financingData[k].cnData = JSON.parse(JSON.stringify(cnData));
        updateState({financingData, stakeholderData: newStakeholderData});
        Loading.global(false);
    }

    clearNumber = (value) => clearNoNum({value}).value;

    cnChangeUpdate = (key, {isNumber, max}, e) => {
        const { cnForm } = this.state;
        const element = e.target;
        const filter = isNumber && (max ? filterAmount(e, max) : filterAmount(e));
        cnForm[key] = isNumber ? filter.value : element.value;
        this.setState({cnForm}, ()=>{
            filter && setCursorPos(element, filter.cursorPositionX);
        });
    }

    cnBlurUpdate = (index, key, attr, e) => {
        const { k, financingData, updateState, data, newStakeholderDataList } = this.props;
        const { stakeholderData, stakeholderDataList } = data;
        let newStakeholderData = [...stakeholderDataList];
        const { cnData } = financingData[k];
        const { value } = e.target;
        if(Array.isArray(attr)){
            attr.forEach(v=>{
                cnData[key][index][v] = attr === 'stakeholder' ? value : I(this.clearNumber(value), 0);
            })
        }else{
            cnData[key][index][attr] = attr === 'stakeholder' ? value : I(this.clearNumber(value), 0);
        }
        cnData[key] = JSON.parse(JSON.stringify(cnData[key]));
        financingData[k].cnData = cnData;
        let stateData = {financingData};
        let stakeholderIndex = newStakeholderData.findIndex( v => v.id === cnData[key][index].stakeholder_id);
        if(stakeholderIndex === -1){
            newStakeholderData.push({id: cnData[key][index].stakeholder_id, name: cnData[key][index].stakeholder, quantity: 0, valuation: 0});
            newStakeholderData = newStakeholderDataList(newStakeholderData, data.fullDiluted)
        }else{
            newStakeholderData[stakeholderIndex].name = cnData[key][index].stakeholder;
        }
        stateData.stakeholderDataList = newStakeholderData;
        updateState(stateData);
    }

    render() {
        const { data, k, financingData } = this.props;
        const { loading } = data;
        const { cnData, cnTotalList, selectedRowKeys} = financingData[k];
        const title = {safe: __('SAFEs'), convertible_debt: 'Convertible debt', convertible_security: 'Convertible security'};

        return (
            <div className="new-financing-round-capitalization-cn">
                {cnData && Object.keys(cnData).map((key)=>(
                    <div key={key} className="new-financing-round-capitalization-cn-box">
                        <div className="new-financing-round-capitalization-cn-title">
                            <div className="new-financing-cn-span">
                                <span>{title[key]}</span>
                                <div>{selectedRowKeys[key].length}</div>
                            </div>
                            <Button type="primary" onClick={this.addCnData.bind(this, key)}>{__('Add')} {title[key]}</Button>
                        </div>
                        <Table
                            className="new-financing-round-capitalization-cn-table"
                            columns={this.cnColumns(key, financingData[k], k)}
                            dataSource={cnData[key]}
                            pagination={false}
                            loading={loading}
                            summary={ __ => this.getSummary(cnTotalList, key)}
                            rowKey={'custom_label_id'}
                            rowSelection={{
                                type: 'checkbox',
                                ...this.rowSelection(key),
                            }}
                            rowClassName={(record, index)=> record.isNew && 'new-financing-round-cn-table-tr'}
                        />
                    </div>
                ))}
            </div>
        );
    }
}
export default CapitalizationCn;
