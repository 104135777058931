import React, { Component, Fragment } from 'react';
import Modal, { ModalContent } from 'components/Modal';
import { FormItem, withForm } from 'react-antd-formutil';
import { Input, Button, message, Select, Alert, DatePicker, Radio } from 'antd';
import DateSelector from 'components/DateSelector';
import { InfoCircleOutlined, BulbOutlined, CheckOutlined, CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { post } from 'srcPath/http';
import { formatDateForDatePicker, formatDate2Api } from 'Utils/format/formatDate';
import Utils, { bindKeyEvent } from 'Utils';
import SelectStakeholder from 'components/SelectStakeholder'
import moment from 'moment';
import security from 'stores/security';
import { printf } from 'Utils/i18n';

import './style.scss';

const emailParse = new RegExp('(?![\\-_.])[\\w\\-.]+@(?:(?![\\-_])[\\w\\-\u4e00-\u9fa5]{1,63}\\.)+[A-Za-z0-9]{2,}')


const Option = Select.Option

const boardOption = [
	{ label: __('Board director'), value: 7 },
	{ label: __('Board observer'), value: 8 },
]

const boardTips = {
	1: <span style={{ color: '#00a9cd' }}> <InfoCircleOutlined /> {__('A board director can join meetings, view and sign resolutions.')}</span>,
	2: <span style={{ color: '#00a9cd' }}> <InfoCircleOutlined /> {__('A board observer can only view board related materials.')}</span>,
	3: <span style={{ color: '#00a9cd' }}> <InfoCircleOutlined /> {__('Existing board members leaving the board will no longer have access to boardroom.')}</span>
}
const boardObserver = [
	{
		label: __('View board documents'),
		value: true
	},
	{
		label: __('View board meetings'),
		value: true
	},
	{
		label: __('View board consents'),
		value: true
	},
	{
		label: __('View board notifications'),
		value: true
	}
]
const boardDirector = [
	{
		label: __('Upload board documents'),
		value: true
	},
	{
		label: __('View board meetings'),
		value: true
	},
	{
		label: __('Manage board consents'),
		value: true
	},
	{
		label: __('View board notifications'),
		value: true
	}
]
class AddBoard extends Component {
	state = {
		loading: false,
		error: '',
		defaultName: '',
		defaultEmail: '',
		resendMembers: ''
	}

	dateFormat = security.companyInfo && security.companyInfo.date_format || 'DD/MM/YYYY'

	componentDidMount() {

		const { data, $formutil, resendMembers } = this.props;

		if (data) {
			const { email, id, name, end_date, start_date, status, role } = data || {}

			$formutil.$setValues({
				email, id, name, end_date, start_date, status, role
			})
		}
		this.setState({ resendMembers })
		this.removeKeyEvent = bindKeyEvent(this.onSubmit)
	}

	componentWillUnmount() {
		this.removeKeyEvent && this.removeKeyEvent()
	}
	// email: "test120@163.com"
	// end_date: ""
	// name: "test"
	// role: "2"
	// start_date: "23/11/2020"
	// status: 1 Current member : 0 Past member

	onSubmit = async () => {
		const { $formutil, close, callback, data } = this.props
		const { $invalid, $batchDirty, $params, $getFirstError } = $formutil

		if ($invalid) {
			$batchDirty(true)
			message.error($getFirstError())

			return
		}

		this.setState({ loading: true })

		const api = data ? 'updateBoardMember' : 'addBoardMembers'
		const params = data ? {
			id: $params.id,
			end_date: $params.end_date
		} : $params

		try {
			const { data: resData } = await post(api, params)
			const { code, data } = resData || {}
			if (code == 3) {
				this.setState({ error: __('You cannot invite the same user more than one time.') })
			}
			if (code == 6) {
				this.setState({ error: data })
			}
			if (code == 200 || code == 0) {
				close && close()
				callback && callback(!!data)
			}
		} catch (e) {
			message.error(e.message)
		}

		this.setState({ loading: false })
	}

	handleSelectChange = (name, data) => {
		if (data) {
			const { $formutil } = this.props
			const { $setValues, $params } = $formutil
			$setValues({
				...$params,
				name: data.nick_name || '',
				email: data.email || ''
			})
		}
	}

	render() {
		const { error, resendMembers } = this.state
		const { $formutil, data } = this.props
		const { $params = {} } = $formutil
		const disabled = !!data
		const startDate = formatDateForDatePicker(security.companyInfo.date_of_incorporation)

		return <div className="permisson-board-modal">
			<div className="permisson-board-modal-content">
				{error && <Alert style={{ marginBottom: 24 }} message={error} type="error" showIcon />}
				<FormItem
					name="name"
					required
					label={__('Name')}
					colon={false}
					validMessage={{
						required: __('Name is required')
					}}
				>
					<SelectStakeholder disabled={disabled} onSelect={this.handleSelectChange} />
				</FormItem>
				<FormItem
					name="email"
					label={__('Email address')}
					required
					colon={false}
					pattern={emailParse}
					validMessage={{
						required: __('Email is required'),
						pattern: __('Email is invalid')
					}}
				>
					<Input placeholder={__('e.g.John@example.com')} disabled={disabled} />
				</FormItem>
				<FormItem
					name="role"
					required
					label={__('Board role')}
					colon={false}
					validMessage={{
						required: __('Board role is required'),
					}}
				>
					<Select placeholder={__('Select role')} disabled={disabled}>
						{boardOption.map((val, index) => <Option key={index} value={val.value}>{val.label}</Option>)}
					</Select>
				</FormItem>
				<div className="permission-board-tips">
					{$params.role && <div>
						<div className="role-title"><BulbOutlined /> {__('Permission details')}:</div>
						{$params.role == 7 && <div className="role-detail">
							{boardDirector.map((val, index) => {
								return (<div className="role-items" key={index}>{val.value ? <CheckOutlined style={{ color: '#63ba22' }} /> : <CloseOutlined style={{ color: '#e95252' }} />}<div className="value" key={index}>{val.label}</div></div>)
							})}</div>
						}
						{$params.role == 8 && <div className="role-detail">
							{boardObserver.map((val, index) => {
								return (
									<div className="role-items" key={index}>{val.value ? <CheckOutlined style={{ color: '#63ba22' }} /> : <CloseOutlined style={{ color: '#e95252' }} />}<div className="value" key={index}>{val.label}</div></div>)
							})}</div>
						}
					</div>}
					{data ? boardTips[$params.role] : <span>{__("Board members will be added to Account users but will not have access to view the company's cap table. You can modify their level of access by visiting Account Users.")}</span>}
				</div>
				<FormItem
					name="start_date"
					label={__('Start date')}
					required
					colon={false}
					$formatter={formatDateForDatePicker}
					$parser={val => val && formatDate2Api(val)}
					validMessage={{
						required: __('Start date is required'),
					}}
				>
					<DateSelector
						disabled={disabled}
						disabledDate={val => val && startDate && startDate.isValid() && val.isBefore(startDate)}
					/>
				</FormItem>

				<FormItem
					name="status"
					label={__('Status')}
					required
					colon={false}
					$defaultValue="1"
					validMessage={{
						required: __('Status is required'),
					}}
				>
					<Radio.Group disabled={disabled}>
						<Radio value='1'>{__('Current member')}</Radio>
						<Radio value='0'>{__('Past member')}</Radio>
					</Radio.Group>
				</FormItem>
				{
					((data && $params.status === '1') || !data && $params.status === '0') && <Fragment><FormItem
						name="end_date"
						label={__('End date')}
						required
						colon={false}
						$formatter={formatDateForDatePicker}
						$parser={val => val && formatDate2Api(val)}
						validMessage={{
							required: __('End date is required'),
						}}
					>
						<DateSelector disabledDate={val => moment($params.start_date).isValid() && val.isBefore(moment($params.start_date))} />
					</FormItem>
						<div className='permission-board-tips'>
							{
								data && boardTips[3]
							}
						</div>
						<div className='permission-board-tips'>
							{__("End of this person's tenure in this board role.")}
						</div>
					</Fragment>
				}
				{resendMembers &&
					<div className="permission-board-tips">
						<ExclamationCircleOutlined /> {printf(__("Your invited users will receive a reminder email every %s days if they haven't logged in."), resendMembers)}
					</div>
				}
				<div className="btn-wrap">
					<Button type="primary" loading={this.state.loading} onClick={this.onSubmit}>{__('Save')}</Button>
				</div>
			</div>
		</div>
	}
}

export const AddBoardContent = withForm(AddBoard)

export default function openAddBoardModal(callback, data, resendMembers) {
	const { name } = data || {}

	return Modal.open({
		component: props => <ModalContent close={props.close} title={data ? __('Edit member %s').replace('%s', name) : __('Add member')}> <AddBoardContent data={data} close={props.close} callback={callback} resendMembers={resendMembers} /></ModalContent>,
		width: 600,
		className: 'sprout-modal'
	})
}

