import React from "react";
import { post } from 'srcPath/http';
import Utils, { formatNumber, I, formatWithArray } from "Utils";
import moment from "moment";
import {DatePicker} from "antd";
import security from "../../../../../stores/security";
import DateSelector from "../../../../../components/DateSelector";
import { formatDate} from "Utils/format/formatDate";
import {calculator} from "../../../../Utils";
import formatFractionalNumber from "../../../../Utils/format/formatFractionalNumber";
import { printf } from 'Utils/i18n';

class AccelerateVesting extends React.Component {
    constructor(props){
        super(props);
        this.state={
            dateFormat:"DD/MM/YYYY",
            data:{
                period:0,
                date:"",
                shareVested:0,
                accelerate_reason:"",
                accelerate:1,
                cumulative:0,
                vested_status:1,
                isInsert:true
            },
            availableQuantity:0,
            vestingList:[],
            vestingListCopy:[],
            saveStatus: 0
        }
    }
    componentDidMount() {
        this.insertNewAcceleration();
    }

    /**
     * date
     * @param key
     * @param moment_key
     * @param date
     */
    handleDateChange = (key, moment_key, date) => {
        const { dateFormat } = this.state;
        let curData = this.state.data;
        curData[key] = date.format( dateFormat);
        curData[moment_key] = date;
        this.setState({
            data:curData
        });
        this.insertNewAcceleration();
    }

    setDefaultDate=()=>{
        let curData = this.state.data;
        curData.date = Utils.getCurDate();
        this.setState({
            data:curData
        });
        this.insertNewAcceleration()
    }
    
    recordNumber=(e)=>{
        let isFractionalShare = security.companyInfo.fractional_control;
        let curData = this.state.data;
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        if (isFractionalShare == 1) {
            curData[dataKey] = Utils.check5DecimalNumInputWithoutComma(dataVal, 5);
        } else {
            curData[dataKey] = Utils.checkNumInput(dataVal);
        }
        this.setState({
            data:curData,
        });
    }

    formatNum=(e)=>{
        let isFractionalShare = security.companyInfo.fractional_control;
        const { minus } = calculator;
        let curData = this.state.data;
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let inputVal = Utils.resetNumberWithCommas(dataVal)
        let availableVal = Utils.resetNumberWithCommas(this.state.availableQuantity)
        if(minus(inputVal,availableVal)>0){
            if (isFractionalShare == 1) {
                curData[dataKey] = formatWithArray(this.state.availableQuantity,5);
            } else {
                curData[dataKey] = formatWithArray(this.state.availableQuantity,null);
            }
        }else{
            if (isFractionalShare == 1) {
                curData[dataKey] = formatWithArray(dataVal,5);
            } else {
                curData[dataKey] = formatWithArray(dataVal,null);
            }
        }
        this.setState({
            data:curData
        });
        this.insertNewAcceleration();

    }
    focusNumber=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = Utils.resetNumberWithCommas(dataVal);
        this.setState({
            data:curData
        })
    }
    resetData=()=>{
        let curData = this.state.data;
        curData.shareVested = 0;
        this.setState({
           data:curData
        })
    }
    getInitData=()=>{
        let mydata = [];
        for(let k in this.state.vestingListCopy){
            let tmpObj = Object.assign({},this.state.vestingListCopy[k])
            mydata.push(tmpObj)
        }
        return mydata
    }

    insertNewAcceleration=()=>{
        let curVestingList = this.state.vestingList;
        let InitData = this.getInitData();
        if(curVestingList.length > InitData.length){
            curVestingList.pop();
        }
        for(let i=0;i<InitData.length;i++){
            curVestingList[i] = InitData[i]
        }
        //插入写的数据
        let curData = this.state.data;
        curVestingList.unshift(curData);
        //排序
        curVestingList = this.sortVesting(curVestingList);
        //判断插入数据vest状态
        let curDate = Utils.getCurDate();
        if(Utils.getWeightByDateString(curData.date)>Utils.getWeightByDateString(curDate)){
            curData.vested_status = 0
        }else{
            curData.vested_status = 1
        }
        curData.shareVested = formatFractionalNumber(curData.shareVested)

        //设置数据
        this.setState({
            data:curData,
            curVestingList:curVestingList,
        });
    }
    sortVesting=(curVestingList)=>{
        const { minus } = calculator;
        //排序
        curVestingList.sort(function(a,b){return Utils.getWeightByDateString(a.date)>Utils.getWeightByDateString(b.date)?1:-1});

        var sharesInput = parseFloat(Utils.resetNumberWithCommas(this.state.data.shareVested));
        var availableShares = this.props.curSelectSecurity.quantity;
        // var availableShares = this.state.availableQuantity;
        //先根据逻辑计算最大的available
        for(let i=0; i<curVestingList.length; i++){
            curVestingList[i].shareVested = parseFloat(Utils.resetNumberWithCommas(curVestingList[i].shareVested));
            if(curVestingList[i].isInsert) {
                if(curVestingList[i].shareVested > availableShares){
                    //到这里说明输入的超过计算的值了
                    curVestingList[i].shareVested = availableShares
                    let curData = this.state.data;
                    curData.shareVested = availableShares
                    this.setState({
                        data:curData
                    })
                    sharesInput = availableShares
                }
                break;
            }
            // availableShares -= curVestingList[i].shareVested
            availableShares = minus( availableShares ,curVestingList[i].shareVested)
        }
        this.setState({
            availableQuantity: availableShares
        })
        for(let i=curVestingList.length; i>0; i--){
            if((curVestingList[i-1].shareVested-sharesInput) >= 0){
                curVestingList[i-1].shareVested = (curVestingList[i-1].shareVested - sharesInput).toFixed(5);
                curVestingList[i-1].isChanged = true;
                break;
            }else{
                sharesInput -= curVestingList[i-1].shareVested;
                curVestingList[i-1].shareVested = 0;
                curVestingList[i-1].isChanged = true;
            }
        }

        let cumulativeSum = 0;
        let periodNum = 1;
        for(let j=0;j<curVestingList.length;j++){
            curVestingList[j].period = periodNum++;
            cumulativeSum += parseFloat(Utils.resetNumberWithCommas(curVestingList[j].shareVested));
            // curVestingList[j].cumulative = formatNumber(cumulativeSum,5)
            curVestingList[j].cumulative = cumulativeSum.toFixed(5);
            if(cumulativeSum > this.props.curSelectSecurity.quantity){
                curVestingList[j].cumulative = this.props.curSelectSecurity.quantity
            }
        }
        return curVestingList
    }

    recordData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        });
    }
    resetActive=()=>{
        window.$("#accelerateVestingModal .tab-pane").removeClass("active");
        window.$("#accelerate_step1").addClass("active");
    }
    getInitVestingList=()=>{
        const { listName, curSelectSecurity } = this.props;
        const getVestingByIdApi = listName == "grantsList" ? "getVestingByGrantId" : "getVestingBySecurityId"
        const requestData = (listName == "grantsList" ) ? { grant_id:curSelectSecurity.id} : { security_id:curSelectSecurity.id}
        post( getVestingByIdApi,requestData).then((response)=> {
            if(response.data.code === 0){
                let mydata = [];
                for(let k in response.data.data){
                    let tmpObj = Object.assign({},response.data.data[k])
                    mydata.push(tmpObj)
                }
                this.setState({
                    vestingList:response.data.data,
                    vestingListCopy:mydata,
                })
                this.insertNewAcceleration();
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    saveAcceleration=()=>{
        if(this.state.saveStatus == 1) return;
        this.setState({ saveStatus: 1})
        const { curSelectSecurity, listName } = this.props;
        let requestData = {};
        const accelerateVestingApi = (listName =='grantsList') ? 'accelerateVestingSchedule' : 'accelerateVesting';
        let curData = this.state.data;
        listName == 'grantsList' ? requestData.grant_id = curSelectSecurity.id : requestData.security_id = curSelectSecurity.id;
        requestData.accelerate_date = curData.date;
        requestData.accelerate_quantity = Utils.resetNumberWithCommas(curData.shareVested);
        requestData.accelerate_reason = curData.accelerate_reason;
        post(accelerateVestingApi, requestData).then((response)=> {
            if(response.data.code === 0){
                this.setState({ saveStatus: 0})
                $('#accelerateVestingModal').modal('hide')
                this.resetActive();
                this.refreshData();
                this.props.getData()
                // window.location.reload()
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    refreshData = () =>{
        this.setState({
            data:{
                period:0,
                date:"",
                shareVested:0,
                accelerate_reason:"",
                accelerate:1,
                cumulative:0,
                vested_status:1,
                isInsert:true
            },
            availableQuantity:0,
            vestingList:[],
            vestingListCopy:[]
        })
    }
    render(){
        let securityType = this.props.curSelectSecurity.security_type;
        let securityName = "shares"
        if(securityType=="CS"||securityType=="PS"){
            securityType = "share"
        }
        if(securityType=="RSU"){
            securityName = "units"
        }
        if(securityType=="ISO"||securityType=="INTL"||securityType=="NSO"){
            securityType = "option grant"
            securityName = "options"
        }
        if(securityType=="WARRANT"){
            securityType = "warrant"
            securityName = "warrants"
        }
        const { data, dateFormat, saveStatus } = this.state;
        const { curSelectSecurity, listName } = this.props;
        return(
            <div className="modal fade" id="accelerateVestingModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="modal-dialog tab-content dialog-top40">
                    <div className="modal-content content520 tab-pane active" id="accelerate_step1">
                        <div className="modal-header box-header">
                            <button type="button" className="close shut-btn" onClick={this.resetData} data-dismiss="modal" aria-hidden="true">×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Accelerate for')} { listName == 'grantsList' ? curSelectSecurity.grant_name : curSelectSecurity.custom_label_id}</h4>
                        </div>
                        <div className="modal-body padding202620">
                            <div className="body-one">
                                <form className="form-horizontal font_reset_form">
                                    <div className="form-group add-items ">
                                        <label className="col-xs-5 add-label">{__('Vesting acceleration date')}</label>
                                        <div className="col-xs-5 ">
                                            <DateSelector
                                                onChange={this.handleDateChange.bind(this, 'date', 'date_moment')}
                                                value={I(data['date_moment'], I(data['date'], '') ? moment(data['date'], dateFormat) : '')}
                                            />
                                        </div>
                                        <p className="text-muted col-xs-7 col-xs-push-5 mb-0">{__('Typically the same as termination date.')}</p>
                                    </div>
                                    <div className="form-group add-items">
                                        <label className="col-xs-5 add-label">{__('Accelerated quantity')}</label>
                                        <div id="last_name" className="col-xs-5">
                                            <input value={this.state.data.shareVested}  name="shareVested" onChange={this.recordNumber} onBlur={this.formatNum} onFocus={this.focusNumber} className="form-control" type="text" placeholder={"e.g. 0"} />
                                        </div>
                                        <p className="text-muted col-xs-7 col-xs-push-5 mb-0">{printf(__('There are %s %s available.'), this.state.availableQuantity==0?"0":formatFractionalNumber(this.state.availableQuantity), securityName)}</p>
                                    </div>
                                </form>
                                <div className="accelerate_list">
                                    <ul className="flexDiv accelerate_ul">
                                        {/*<li>Legend: <span className="type_tip legend_tip">Expired</span></li>*/}
                                        <li>Vested&nbsp;<span className="type_tip"> &radic;</span></li>
                                        <li><span className="type_tip new_tranche">{__('New tranche')}</span></li>
                                        <li><span className="type_tip bg-skyblue">{__('Modified tranches')}</span></li>
                                    </ul>
                                    {/*<table className="table table-striped accelerate_table">*/}
                                    <table className="table accelerate_table">
                                        <tbody>
                                        <tr className="first-row">
                                            <td className="effective-date">{__('Period')}</td>
                                            <td>{__('Date')}</td>
                                            <td className="vested-3Td">{__('Units vesting')}</td>
                                            <td>&radic;</td>
                                            <td className="vested-3Td">{__('Cumulative')}</td>
                                        </tr>
                                        {this.state.vestingList && this.state.vestingList.map((value,key)=>{
                                            let vestedTd = <td>&radic;</td>
                                            if(value.vested_status==0){
                                                vestedTd = <td></td>
                                            }
                                            let vestingList = "accelerate_vesting_tr";
                                            if(value.accelerate && value.isChanged){
                                                vestingList = "accelerate_vesting_tr new_tranche"
                                            }
                                            if(!value.accelerate && value.isChanged){
                                                vestingList = "accelerate_vesting_tr bg-skyblue "
                                            }
                                            if(!value.status){
                                                vestingList = "accelerate_vesting_tr line_through_tr "
                                            }
                                            return(
                                                <tr key={key} className={vestingList}>
                                                    <td>{value.period}</td>
                                                    <td>{ formatDate(value.date)}</td>
                                                    <td className="vested-3Td">{formatFractionalNumber(value.shareVested)}</td>
                                                    {vestedTd}
                                                    <td className="vested-3Td">{formatFractionalNumber(value.cumulative)}</td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                        <div className="modal-footer box-footer">
                            {this.state.data.shareVested!="" && <button type="button" data-toggle="tab" data-target="#accelerate_step2" className="btn boxbtn-to bg-darkblue">{__('Next: add acceleration reason')}</button>}
                            {this.state.data.shareVested=="" && <button type="button"  className="btn boxbtn-to send-disabledBtn">{__('Next: add acceleration reason')}</button>}
                        </div>
                    </div>
                    <div className="modal-content content480 tab-pane " id="accelerate_step2">
                        <div className="modal-header box-header">
                            <a data-target="#accelerate_step1" type="button" className="back-btn" aria-controls="accelerate_step1" role="tab" data-toggle="tab"></a>
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.resetActive}>×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Accelerate for')} { listName == 'grantsList' ? curSelectSecurity.grant_name : curSelectSecurity.custom_label_id}</h4>
                        </div>
                        <div className="modal-body padding2026">
                            <div className="body-one">
                                <form className="form-horizontal font_reset_form">
                                    <div className="form-group add-items">
                                        <label className="col-xs-5 add-label">{__('Reason for Acceleration')}<br/><i><span className="text-muted">{__('Required')}</span></i> </label>
                                        <div className="col-xs-7 ">
                                            <textarea name="accelerate_reason" value={this.state.data.accelerate_reason} onChange={this.recordData} className="form-control text-area minHeight140" type="text" />
                                        </div>
                                        <p className="text-muted col-xs-7 col-xs-push-5 mb-0">{__('The reason will be saved on the {securityType}.')}</p>
                                    </div>
                                </form>

                            </div>
                        </div>
                        <div className="modal-footer box-footer invite-footer">
                            {this.state.data.accelerate_reason!="" &&<button type="button" className="btn boxbtn-to bg-darkblue " onClick={this.saveAcceleration}>{ saveStatus == 1 ? __('Saving') : __('Save acceleration')}</button>}
                            {this.state.data.accelerate_reason=="" && <button type="button" className="btn boxbtn-to send-disabledBtn">{__('Save acceleration')}</button>}
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
export default AccelerateVesting;