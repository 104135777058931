import React from 'react';
import { post } from 'srcPath/http';
import axios from 'axios';
import Utils from "Utils";
import CreateVestingSchedule from '../TemplateModal/CreateVestingSchedule';
import 'assets/css/stakeholders.css';
import 'assets/css/securities.css';
import 'assets/css/equityawards.css';
import 'assets/css/permissions.css';
import 'assets/css/onboarding.css';
import {Empty} from "antd";
import Loading from 'components/Loading';


class VestingScheduleList extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            curSelectRelation:"all",
            loadingStatus:1,
            vestingSchedulesList : [
                {   id:'1',
                   type:'0',
                    custom_details_data:{
                        timebased:[
                            {period:1,tLength:"",frequency:"days",percentage:"",vesting_occurs:""},
                        ],
                        milestone:[
                            {milestone:"",percentage:10}
                        ]
                    },
                    schedule_name:'1/24 monthly,no cliff',
                    attached_num:"0",
                    custom_type:"",
                    length_of_schedule:'24 months',
                    immediate_vesting:'',
                    cliff_percentage:'2',
                    cliff_length:{value:'12',date_type:"months"},
                    vesting_occurs:{period:'Monthly',exact_data:"on the last day of the month"},
                    condition_name:"",
                    condition_type:"",
                    condition_details:"",
                },
                {   id:'2',
                    type:'0',
                    custom_details_data:{
                        timebased:[
                            {period:1,tLength:"",frequency:"days",percentage:"",vesting_occurs:""},
                        ],
                        milestone:[
                            {milestone:"",percentage:10}
                        ]
                    },
                    schedule_name:'1/24 monthly,no cliff',
                    attached_num:"0",
                    custom_type:"",
                    length_of_schedule:'24 months',
                    immediate_vesting:'',
                    cliff_percentage:'12',
                    cliff_length:{value:'24',date_type:"days"},
                    vesting_occurs:{period:'Monthly',exact_data:"on the last day of the month"},
                    condition_name:"",
                    condition_type:"",
                    condition_details:"",
                },
                {   id:'3',
                    type:'1',
                    custom_details_data:{
                        timebased:[
                            {period:1,tLength:"",frequency:"days",percentage:"",vesting_occurs:""},
                        ],
                        milestone:[
                            {milestone:"",percentage:10}
                        ]
                    },
                    schedule_name:'1/24 monthly,no cliff',
                    attached_num:"0",
                    custom_type:"Time based",
                    length_of_schedule:'24 months',
                    immediate_vesting:'',
                    cliff_percentage:'',
                    cliff_length:{value:'',date_type:""},
                    vesting_occurs:{period:'Monthly',exact_data:"on the last day of the month"},
                    condition_name:"",
                    condition_type:"",
                    condition_details:"",},
                {   id:'5',
                    type:'2',
                    custom_details_data:{
                        timebased:[
                            {period:1,tLength:"",frequency:"days",percentage:"",vesting_occurs:""},
                        ],
                        milestone:[
                            {milestone:"",percentage:10}
                        ]
                    },
                    schedule_name:'1/24 monthly,no cliff',
                    attached_num:"0",
                    custom_type:"",
                    length_of_schedule:'24 months',
                    immediate_vesting:'',
                    cliff_percentage:'',
                    cliff_length:{value:'',date_type:""},
                    vesting_occurs:{period:'Monthly',exact_data:"on the last day of the month"},
                    condition_name:"",
                    condition_type:"Performance condition",
                    condition_details:"conditional details",},
            ],
            // periodList:[
            //     {id:1,content:"Every day"},
            //     {id:2,content:"Every week"},
            //     {id:3,content:"Every month"},
            //     {id:4,content:"Every 2 month"},
            //     {id:5,content:"Every 3 month"},
            //     {id:6,content:"Every 6 month"},
            //     {id:7,content:"Every 12 month"},
            // ],
            // exactDataList:[
            //     {id:1,content:"on the same day as the start date"},
            //     {id:2,content:"on the same day as the start date, minus one"},
            //     {id:3,content:"on the first day of the month"},
            //     {id:4,content:"on the last day of the month"},
            // ],
        }
    }
    componentDidMount() {
        // let formatPeriodList = {};
        // let formatExactDataList = {};
        // for(let i=0;i<this.state.periodList.length;i++){
        //     formatPeriodList[this.state.periodList[i].id]=this.state.periodList[i]
        // }
        // for(let i=0;i<this.state.exactDataList.length;i++){
        //     formatExactDataList[this.state.exactDataList[i].id]=this.state.exactDataList[i]
        // }
        this.getScheduleList()
    }

    getScheduleList=()=>{
        post('getVestingScheduleList',
            []).then((response)=> {
            if(response.data.code === 0){
                this.setState({
                    vestingSchedulesList:response.data.data,
                    loadingStatus:0
                });
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    clearInput=()=>{
        this.refs.transaction_input.value = "";
    }
    editVestingSchedule=(key)=>{
        this.refs.vestingScheduleModal.changeToEditStatus();
        this.refs.vestingScheduleModal.initData(key,this.state.vestingSchedulesList[key]);
        this.refs.vestingScheduleModal.getVestingSchedulesList(this.state.vestingSchedulesList);
    }
    createVestingSchedule=()=>{
        this.refs.vestingScheduleModal.changeToCreateStatus();
        this.refs.vestingScheduleModal.getVestingSchedulesList(this.state.vestingSchedulesList);
    }
    render(){
        return(
            <div>
                <CreateVestingSchedule ref="vestingScheduleModal" getScheduleList={this.getScheduleList} />
                {this.state.loadingStatus==1 &&
                <div className="loading_position">
                    <Loading/>
                </div>
                }
                {this.state.loadingStatus==0 &&
                <div>
                    <div className="holders-nav marginBott10 clearfix">
                        {/*<div className="form-group width50perc transaction_search onset">*/}
                        {/*    <div className="input-group holders-search bg-white ">*/}
                        {/*        <span className="input-group-btn">*/}
                        {/*            <button type="button"><span className="glyphicon glyphicon-search search-pic"></span></button>*/}
                        {/*        </span>*/}
                        {/*        <input className="input-box" type="search" placeholder="Search" ref="transaction_input" />*/}
                        {/*        <span className="input-group-btn">*/}
                        {/*        <button type="button" onClick={this.clearInput}><span className="cha ">X</span></button>*/}
                        {/*    </span>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="btn-group offset">
                            <button type="button" onClick={this.createVestingSchedule} data-toggle="modal" data-target="#createVestingScheduleModal" className="btn drafts_btn bg-darkblue">{__('Create Vesting Schedule')}</button>
                        </div>
                    </div>
                    <div className=" table-box option_tableBox">
                        <table className="table stake-table">
                            <tbody>
                            <tr className="bg-darkblue stake-tableTh table-th transaction_tr">
                                <td className="th-td">
                                    <p>{__('Legend name')}</p>
                                </td>
                                <td className="th-td">
                                    <p>{__('Frequency')}</p>
                                </td>
                                <td className="th-td">
                                    <p>{__('Immediate Vesting')}</p>
                                </td>
                                <td className="th-td">
                                    <p>{__('Cliff')}</p>
                                </td>
                                <td className="th-td">
                                    <p>{__('Vesting Occurs')}</p>
                                </td>
                                <td className="th-td vested-3Td">
                                    <p></p>
                                </td>
                            </tr>
                            {this.state.vestingSchedulesList.length==0 &&
                            <tr>
                                <td colSpan={6}  className="empty-can">
                                    <Empty className="empty-pic"/>
                                </td>
                            </tr>
                            }
                            {this.state.vestingSchedulesList.map((value,key)=>{
                                let trClass = 'draft_table_row transaction_tr';
                                return(
                                    <tr key={key} className={"table-row "+trClass}>
                                        <td>
                                            <p className="schedule_p">{value.type==2 ? value.condition_name :value.schedule_name}<br/>
                                                <small className="text-muted">Attached to {value.attached_num} securities</small>
                                            </p>
                                        </td>
                                        <td className="width200">
                                            <p className="schedule_p">{value.type== 0 && value.length_of_schedule+" months"}{value.type==1 && "Custom"}{value.type==2 && value.condition_type}<br/>
                                                {value.type==1 && <small className="text-muted">{value.custom_type=="Date" ? "Time based" : value.custom_type}</small>}
                                            </p>
                                        </td>
                                        <td>
                                            {value.type==0 &&
                                            <p className="schedule_p">{value.immediate_vesting==="" ? "No": value.immediate_vesting+"%"}</p>
                                            }
                                        </td>
                                        <td>
                                            {value.type == 0 &&
                                            // <p className="schedule_p">{value.cliff_length.value !== "" && value.cliff_percentage !== "" ? value.cliff_percentage + "% vests at " + value.cliff_length.value + " " + value.cliff_length.date_type : "No"}</p>
                                            <p className="schedule_p">{value.cliff_length.value !== "" && value.cliff_percentage !== "" ? value.cliff_percentage + "% vests at " + value.cliff_length.value+" "+ value.cliff_length.date_type: "No"}</p>
                                            }
                                        </td>
                                        <td>
                                            <p className="schedule_p">{value.type==0 && Utils.vestingSchedulePeriodListId2Text( value.vesting_occurs.period)}{value.type==1 && "Custom"}{value.type==2 && value.condition_details}<br/>
                                                {value.type==0 && <small className="text-muted">{Utils.vestingScheduleExactDataListId2Text(value.vesting_occurs.exact_data)}</small>}
                                            </p>

                                        </td>
                                        <td className="last-permi">
                                            <button className="btn edit-btn vesting_edit_btn height34" onClick={this.editVestingSchedule.bind(this,key)} data-toggle="modal" data-target="#createVestingScheduleModal">{__('Edit')}</button>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
                }
            </div>
        )
    }

}

export default VestingScheduleList;