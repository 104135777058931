import React, { Component } from 'react';
import { FormItem, withForm } from 'react-antd-formutil';
import Section from 'components/CertificateDetail/components/Section';
import { Input, Select, Button, Spin, message } from 'antd';
import { post } from 'srcPath/http';
import security from 'stores/security';
import './style.scss';

const { Option, OptGroup } = Select

const optionList = [
	{
		label: 'Incorrect security information',
		list: [
			{
				label: 'Converted/expired securities still reflected as outstanding',
				value: '3-1'
			},
			{
				label: 'Incorrect name',
				value: '3-2'
			},
			{
				label: 'Incorrect security details (principle, cost, amount, issue date)',
				value: '3-3'
			},
		]
	},
	{
		label: 'Missing securities',
		list: [
			{
				label: 'Securities canceled due to partial transfer but not reissued',
				value: '4-1'
			},
			{
				label: 'Securities canceled without clarification',
				value: '4-2'
			},
		]
	},
	{
		label: 'Incorrect cap table details',
		list: [
			{
				label: 'Incorrect rights & perferences',
				value: '2-1'
			},
		]
	},
	{
		label: 'Exit(transaction)',
		list: [
			{
				label: 'Dissolution',
				value: '1-1'
			},
			{
				label: 'IPO',
				value: '1-2'
			},
			{
				label: 'M&A',
				value: '1-3'
			},
			{
				label: 'Secondary transaction',
				value: '1-4'
			},
		]
	},
	{
		label: 'Other',
		list: [
			{
				label: 'Other',
				value: '5-1'
			}
		]
	},
]
@withForm
class ReportIssue extends Component {
	state = {
		loading: false
	}

	onSubmit = async () => {
		const { $formutil, callback } = this.props
		const {  $invalid, $batchDirty, $params, $getFirstError } = $formutil

		if ($invalid) {
			$batchDirty(true)
			message.error($getFirstError())

			return
		}

		try {
			const { data: resData } = await post('stakeholderReportIssue', { ...$params })
			const { data, code } = resData

			if (code === 0) {
				message.success('Success')
			}

		} catch(e) {
			message.error(e.message)
		}
		
		callback && callback()
	}

	render() {
		const { certificateName, certificateId, hideHeader } = this.props
		const { loading } = this.state

		return <Section title="Report Issue" hideHeader={hideHeader}>
			<Spin spinning={loading}>
				<div className="sprout-report-issue">
					<FormItem
						name="reason"
						colon={false}
						itemProps={{
							label: 'Reason'
						}}
						required
						validMessage={{
							required: 'Reason is required'
						}}
					>
						<Select>
							{optionList.map((option) => (
								<OptGroup key={option.label} label={option.label}>
									{option.list.map((item) => (
										<Option key={item.value} value={item.value}>{item.label}</Option>
									))}
							  </OptGroup>
							))}
						</Select>
					</FormItem>

					<FormItem 
						name="message"
						colon={false}
						itemProps={{
							label: 'Message'
						}}
						required
						validMessage={{
							required: 'Message is required'
						}}
					>
						<Input.TextArea 
							autoSize={{ minRows: 4 }}
						/>
					</FormItem>
					<div className="sprout-report-issue-btns">
						<Button width="440px" type="primary" onClick={this.onSubmit}>Send</Button>
					</div>
				</div>
			</Spin>
		</Section>
	}
}

export default ReportIssue