const commonBtns = [{ label: __('Overview'), value: 'overview' }, { label: __('Holdings'), value: 'holdings' }]
const allBtns = [...commonBtns, { label: __('Capitalization'), value: __('capitalization') }, { label: __('Financing History'), value: 'financingHistory' }, { label: __('Fair Market Value'), value: 'fairmarketvalue' }]

export const btnsConfig = {
	0: commonBtns,
	1: commonBtns,
	2: commonBtns,
	3: allBtns,
	4: allBtns,
	5: allBtns,
	6: allBtns
}

// outstanding' => 'Outstanding',
// 'transferred' => 'Transferred',
// 'canceled' => 'Canceled',
// 'issued' => 'Issued',
// 'repurchased' => 'Repurchased',
// 'converted' => 'Converted',
// 'exercised' => 'Exercised',
// 'purchased' => 'Purchased',
// 'not_purchased' => 'Not purchased',
// 'settled' => 'Settled',
// 'partially_settled' => 'Partially settled',
// 'partially_exercised' => 'Partially exercised',
// 'fully_exercised' => 'Fully exercised',

// Partially settled
// Partially exercised


export function holdingsStatusMap(status, originalStatus){

    const returnObj = {
        'Outstanding': {
            label: __('Outstanding'),
            style: {
                backgroundColor: '#61BA24', // green
                color: '#FFFFFF'
            }
        },
        'Purchased': {
            label: __('Purchased'),
            style: {
                backgroundColor: '#61BA24', // green
                color: '#FFFFFF'
            }
        },
        'Convert at IPO': {
            label: __('Convert at IPO'),
            style: {
                backgroundColor: '#EEC739', // yellow
                color: '#FFFFFF'
            }
        },
        'Exercisable until': {
            label: originalStatus,
            style: {
                backgroundColor: '#EEC739', // yellow
                color: '#FFFFFF'
            }
        },
        'Settleable until': {
            label: originalStatus,
            style: {
                backgroundColor: '#EEC739', // yellow
                color: '#FFFFFF'
            }
        },
        'Vesting': {
            label: __('Vesting'),
            style: {
                backgroundColor: '#EEC739', // yellow
                color: '#FFFFFF'
            }
        },
        'Canceled': {
            label: __('Canceled'),
            style: {
                backgroundColor: '#EC5454' // red
            }
        },
        'Repurchased': {
            label: __('Repurchased'),
            style: {
                backgroundColor: '#EC5454'
            }
        },
        'Transferred': {
            label: __('Transferred'),
            style: {
                backgroundColor: '#EC5454'
            }
        },
        'Converted': {
            label: __('Converted'),
            style: {
                backgroundColor: '#EC5454'
            }
        },
        'Partially exercised': {
            label: __('Partially exercised'),
            style: {
                backgroundColor: '#EEC739'
            }
        },
        'Exercised': {
            label: __('Exercised'),
            style: {
                backgroundColor: '#EC5454'
            }
        },
        'Expired': {
            label: __('Expired'),
            style: {
                backgroundColor: '#EC5454'
            }
        },
        'Partially settled': {
            label: __('Partially settled'),
            style: {
                backgroundColor: '#EEC739'
            }
        },
        'Settled': {
            label: __('Settled'),
            style: {
                backgroundColor: '#EC5454'
            }
        },
        'Forfeited': {
            label: __('Forfeited'),
            style: {
                backgroundColor: '#EC5454'
            }
        },
        'Fully exercised': {
            label: __('Fully exercised'),
            style: {
                backgroundColor: '#EC5454'
            }
        },
        'Not purchased': {
            label: __('Not purchased'),
            style: {
                backgroundColor: '#EC5454'
            }
        }
    };
    return returnObj[status];
}

export function getHoldingsStatus(status) {
    const removeDateStatus = status?.replace(/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/, '');
    const newStatus = removeDateStatus?.trim();
	return holdingsStatusMap(newStatus, status) || {
		label: status,
		style: {
			backgroundColor: '#EC5454'
		}
	}
}


// 'overview' | 'holdings' | 'capitalization' | 'financingHistory'