import React, { useState, useRef, useEffect } from 'react';
import { FormItem } from 'react-antd-formutil';
import { Button, Space, Input } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'

const Item = React.memo(({ index, onAdd, onDelete, total, disable = false }) => {
	return <tr>
		<td>
			<FormItem
				name={`wallet_address_list[${index}].wallet_address`}
				required
			>
				<Input />
			</FormItem>
		</td>
		<td valign="top" align="right">
			<Space>
				{ total === (index + 1) && <Button icon={<PlusOutlined />} onClick={onAdd} type="primary" />}
				{
					total > 1 && <Button disabled={disable} onClick={onDelete} icon={<MinusOutlined />} />	
				}
			</Space >
		</td>
	</tr>
}, (currentProps, nextProps) => {
	return false
})

export default React.memo(function Wallet(props) {
	const idx = useRef(1)

	const [list, setList] = useState([])

	useEffect(() => {
		const data = [...(props.list || [{}])]

		
		setList(data.map(val => {
			idx.current = idx.current + 1
			return { ...val, idx: idx.current, disable: true }
		}))	

	}, [])

	const addItem = () => {
		idx.current = idx.current + 1

		const data = [...list]
		
		data.push({ idx: idx.current })

		setList(data)
	}

	const deleteItem = idx => {
		setList(list.filter(val => val.idx !== idx))
	}

	return <div className="form-relations-root">
			<table>
				<thead>
					<tr>
						<th>Address	</th>
						<th style={{ width: 105 }}></th>
					</tr>
				</thead>
				<tbody>
					{
						list.map((val, index) => (<Item disable={val.disable} total={list.length} onAdd={addItem} onDelete={() => deleteItem(val.idx)} index={index} idx={val.idx} key={val.idx} />))
					}
				</tbody>
			</table>
	</div>
}, (currentProps, nextProps) => {
	return false
})
