import React, { Component, Fragment } from "react";
import Loading from 'components/Loading';
import { post } from 'srcPath/http';
import SignupPlan from "./components/SignupPlan";
import SignupBdPlan from "./components/SignupBdPlan";
import ReportIssueModel from "./components/ReportIssueModel";
import security from 'stores/security';
import { I } from 'Utils';


class Message extends Component {

    render() {
        return <ReportIssueModel />;
    }
}
export default Message;