import React, { Component } from 'react';
import { post } from 'srcPath/http';
import { Spin, message, Table, Progress, Button } from 'antd';
import signIcon from '../images/sign.png';
import security from 'stores/security';
export default class Board extends Component {
	state = {
		tableData: [],
		loading: true
	}

	componentDidMount() {
		this.getTableData()
	}

	getTableData = async () => {
		this.setState({ loading: true })

		try {
			const { data: resData } = await post('getTaskList', { type: 'boardConsents' })

			const { code, data } = resData || {}

			if (code == 0) {
				this.setState({ tableData: data })
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}

		this.setState({ loading: false })
	}	

	handleReviewConsent = (record = {}) => {
		const { consentId, companyId } = record;
		const corporations = security.corporations || [];
		const result = corporations.findIndex((item) => {
			return item.company_id === companyId;
		})
		if (result > -1 && consentId) {
			security.setCompanyInfo = security.corporations[result];
			security.setPlatform = 'B';
			this.props.history.push({
                pathname: '/home/board/consents/preview',
                state: { consentId }
            });
		} else {
			message.warn('No company info');
		}
	}

	getColumns = () => {
		return [
			{
				title: 'Company',
				dataIndex: 'companyName',
				className: 'nowrap',
				ellipsis: true
			},
			{
				title: 'Consent',
				dataIndex: 'consentName',
				className: 'nowrap'
			},
			{
				title: 'Publish date',
				dataIndex: 'publishDate',
				className: 'nowrap',
				align: 'right',
			},
			{
				title: 'Status',
				dataIndex: 'status',
				width: 300,
				render: (text = {}, record) => <div>
					<div>
						<Progress percent={(text.approved / text.total) * 100} showInfo={false} status="active"  strokeColor="#87d068"/>
					</div>
					<div style={{ fontSize: 12, color: '#888888' }}>{ text.approved || 0 } / { text.total || 0 } people approved</div>
				</div>
			},
			{
				title: '',
				align: 'right',
				width: 160,
				render: (text, record) => <Button type="link" onClick={this.handleReviewConsent.bind(this, record)}>Review and Sign</Button>
			}
		]
	}

	render() {
		const { tableData, loading } = this.state;

		return <div className="task-board-root">
{/*			
				<div className="task-banner-wrap">
					<img src={signIcon} />
					{tableData.length > 0 &&<div>You have board consents to sign.</div>}
				</div>
*/}			
			<Table size="small" columns={this.getColumns()}  rowKey="consentId" dataSource={tableData} pagination={false} loading={loading} scroll={{ x: true }} />
		</div>
	}
}