import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { post } from 'srcPath/http';

const Option = Select.Option

export default function SelectStakeholderWalletLabel(props) {
    const { onChange, onSelect, allowClear = false } = props
    const [options, setOptions] = useState([])

    useEffect(() => {
        const getData = async () => {
                try {
                    const [{ data: { data: stakeholderData } }, { data: { data: walletLabelData } }] = await Promise.all([
                        post('getMailByStakeholder'),
                        post('getWalletLabelList')
                    ])
                    
                    const res = []

                    stakeholderData.forEach((val, index) => {
                        res.push({
                            name: val.nick_name,
                            type: val.stakeholder_type,
                            email: val.email,
                            id: val.id,
                            key: index,
                            labelType: __('Stakeholder')
                        })
                    })

                    const length = res.length

                    walletLabelData.forEach((val, index) => {
                        res.push({
                            name: val.name,
                            type: val.stakeholder_type,
                            id: val.id,
                            key: length + index,
                            labelType: __('Wallet label')
                        })
                    })

                    setOptions(res)
                } catch (e) {

                }
        }
        getData()
    }, [])

    function handleChange(value) {
        onChange(value)
    }

    function handleSelect(value) {
        onSelect(options.find(val => val.key === value) || {})
    }

    return <Select
        {...props}
        allowClear={allowClear}
        dropdownMatchSelectWidth={false}
        optionLabelProp="label"
        optionFilterProp="label"
        style={{ width: '100%' }}
        showSearch
        onChange={handleChange}
        onSelect={handleSelect}
    >
        {options.map(val => (<Option key={val.key} value={val.key} label={val.name}>
            <div style={{ display: 'flex', maxWidth: 600, justifyContent: 'space-between' }}>
                <div style={{ minWidth: 80, marginRight: 10 }}>{val.name}</div>
                <div style={val.email ? { color: '#1890ff' } : {}} className="text-left whitespace-normal">{val.email || val.labelType}</div>
            </div>
        </Option>))}
    </Select>
}