import React, { Component } from 'react';
import SideBar from '../components/SideBar';
import Detail from './Detail';
import PersonalDetail from './PersonalDetail';
import ExercisePeriods from './ExercisePeriods';
import PaymentInformation from './PaymentInformation';
import ExerciseDetail from './ExerciseDetail';
import TransferDetails from './TransferDetails';
import RepurchaseDetails from './RepurchaseDetails';
import Transaction from './Transaction';
import VestingSchedule from '../components/VestingSchedule/vestingSchedule';
import Documents from '../components/Documents';

import Approvals from '../components/Approvals';
import {message} from "antd";
import { post } from 'srcPath/http';
import { cloneDeep } from 'lodash';

const typeConfig = {
	RSA: __('RSA'),
	RSU: __('RSU'),
	ISO: __('ISO'),
	NSO: __('NSO'),
	SAR: __('SAR'),
	normal: __('Option grant')
};

const menuConfig = [
	{
		label: __('Details'),
		key: 'details'
	},
	{
		label: __('Exercise periods'),
		key: 'exercisePeriods'
	},
	{
		label: __('Exercise details'),
		key: 'exerciseDetail'
	},
	{
		label: __('Transfer details'),
		key: 'transferDetails'
	},
	{
		label: __('Repurchase details'),
		key: 'repurchaseDetails'
	},
	{
		label: __('Transaction history'),
		key: 'transactionHistory'
	},
	{
		label: __('Vesting schedule'),
		key: 'vestingSchedule'
	},
	{
		label: __('Personal detail'),
		key: 'personalDetails'
	},
	{
		label: __('Approvals'),
		key: 'approvals'
	},
	{
		label: __('Documents and notes'),
		key: 'documents'
	},
];

class EA extends Component {
	state = {
		checkedKey: this.props.checkedKey || 'details',
        accountUsers: []
	}

	componentDidMount() {
        this.getData();
    }


    getData = async () => {
        this.setState({ loading: true })
        try {
            const { data: resData } = await post('getAccountUsers')
            const { data, code } = resData || {}
            if (code == 0) {
                this.setState({ accountUsers: data })
            } else {
                message.error(data)
            }
        } catch(err) {
            message.error(err && err.message)
        }
        this.setState({ loading: false })
    }

    handleSideBar = key => {
		this.setState({ checkedKey: key })
	}
	formatConfig() {
		const data = this.props.data;

		const newMenuConfig = cloneDeep(menuConfig)

		const exerciseIndex = newMenuConfig.findIndex((item) =>{
			return item.key === 'exerciseDetail';
		})
		if(data.exercise && data.exercise.length === 0 ){
			newMenuConfig.splice(exerciseIndex, 1)
		}

		const transferIndex = newMenuConfig.findIndex((item) =>{
			return item.key === 'transferDetails';
		})
		if (data.transferDetails && data.transferDetails.length === 0 ) {
			newMenuConfig.splice(transferIndex, 1)
		}

		const repurchaseIndex = newMenuConfig.findIndex((item) =>{
			return item.key === 'repurchaseDetails';
		})
		if ( !data.repurchaseDetails  || data.type !== 'RSA' ) {
			newMenuConfig.splice(repurchaseIndex, 1)
		}

		return newMenuConfig
	}

	renderContent = () => {
		const { checkedKey, accountUsers } = this.state
		const { data } = this.props
		const { approvals, details, vestingSchedule, personalDetails, documents, exercisePeriods, companyId, name, type, certificateList, formatText, transactionHistory, digital_certificate, company_name, certificateHistory, payment_information, exercise, transferDetails, repurchaseDetails } = data || {}

		const objMap = {
			details: <Detail data={{...details, type, certificateList, formatText, vestingSchedule, digital_certificate, company_name, certificateHistory }} />,
			exercisePeriods: <ExercisePeriods data={{exercisePeriods,...details}} onChange={this.handleSideBar} />,
            paymentInformation: <PaymentInformation data={payment_information} accountUsers={accountUsers} />,
			exerciseDetail: <ExerciseDetail data={exercise} accountUsers={accountUsers} />,
			transferDetails: <TransferDetails data={transferDetails} />,
			repurchaseDetails: <RepurchaseDetails data={repurchaseDetails} />,
            transactionHistory: <Transaction data={transactionHistory} />,
			vestingSchedule: <VestingSchedule data={{name, ...vestingSchedule}} />,
			personalDetails: <PersonalDetail data={personalDetails} />,
			approvals: <Approvals data={approvals} />,
			documents: <Documents data={{companyId, ...documents, type}} />,
		}

		return objMap[checkedKey] || objMap.details
	}

	render() {
		const { checkedKey } = this.state
		const { data } = this.props
		const newMenuConfig = this.formatConfig()

		return <div className="certificate-detail-root">
			<SideBar onChange={this.handleSideBar} value={checkedKey} config={newMenuConfig} type={typeConfig[data.type] || __('Option grant')} name={data.name}/>
			<div className="certificate-detail-content">
				{ this.renderContent() }
			</div>
		</div>
	}
}

export default EA