import React, { Component } from "react";
import RoundTags from 'components/RoundTags';
import { formatWithArray, I, formatCurrencyNumber, formatNumber } from 'Utils';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import security from 'stores/security';
/**
 * stakeholder columns配置
 * @param prefix
 */
export const stakeHoldersConfig = (prefix) =>{

    return [
        {
            title : __('Stakeholder'),
            align : 'left',
            dataIndex : 'holderName',
            key : 'holderName',
            render: (text, record) => (
                <div>{text}</div>
            )
        },
        {
            title : __('Rounds'),
            align : 'right',
            dataIndex : 'rounds',
            key : 'rounds',
            render: (text, record) => <RoundTags  allTags={ prefix || []}  tags={record.rounds} />
        },
        {
            title : __('Outstanding shares'),
            align : 'right',
            dataIndex : 'outStanding',
            key : 'outStanding',
            render: text => formatFractionalNumber(text),
        },
        {
            title : __('Cost'),
            align : 'right',
            dataIndex : 'cost',
            key : 'cost',
            render: text => formatCurrencyNumber(text),
        },
        {
            title : __('Projected payout'),
            align : 'right',
            dataIndex : 'projectPayout',
            key : 'projectPayout',
            render: text => formatCurrencyNumber(text),
        }
    ]
}

export const shareClassConfig = [
    {
        title : __('Share class'),
        align : 'left',
        dataIndex : 'shareClassName',
        key : 'shareClassName',
        render: (text, record) => (
            <div>{text}</div>
        )
    },
    {
        title : __('Outstanding'),
        align : 'right',
        dataIndex : 'outstanding',
        key : 'outstanding',
        render: (text, record) => (
            <div >{formatWithArray(text)}</div>
        )
    },
    {
        title : __('Value per share'),
        align : 'left',
        dataIndex : 'valuePreShare',
        key : 'valuePreShare',
        render: (text, record) => (
            <div>{formatCurrencyNumber(text, 3)}</div>
        )
    },{
        title : __('Cost'),
        align : 'right',
        dataIndex : 'cost',
        key : 'cost',
        render: (text, record) => (
            <div>{I(text, '') && security.companyInfo.currency} {I(formatNumber(text, 2), '--')}</div>
        )
    },
    {
        title : __('Projected proceeds'),
        align : 'right',
        dataIndex : 'projectProceeds',
        key : 'projectProceeds',
        render: (text, record) => (
            <div>{formatCurrencyNumber(text, 2)}</div>
        )
    },
    {
        title : __('Return multiple'),
        align : 'right',
        dataIndex : 'returnMultiple',
        key : 'returnMultiple',
        render: (text, record) => (
            <div>{I(formatWithArray(text), '--')}</div>
        )
    },

]
