import React, { Component, createRef, Fragment } from 'react';
import { roundColor, I } from 'Utils';
import echarts from 'echarts';
import { merge, debounce } from 'lodash';
import { isEqual, cloneDeep } from 'lodash';
import './style.scss';
// import worldJson from 'echarts/map/json/world.json';
// echarts.registerMap('world', worldJson);

// window.devicePixelRatio = 2

const defaultOption = {
    color: cloneDeep(roundColor),
    textStyle: {
    	fontSize: 12
    }	
}


function getDefaultSize(type) {
    if (type === 'pie') {
        return {
            defaultChartWidth: 260,
            defaultChartHeight: 260
        };
    }

    return {
        defaultChartWidth: 400,
        defaultChartHeight: 260
    };
}


// interface ChartProps = {
// 	chartWidth?: number;
// 	chartHeight?: number;
// 	option: echarts option:
//  type?: pie
// }

class Chart extends Component {
	instance
	domRef = createRef();
	element = document.createElement('div');

	componentDidMount() {
		const { chartWidth, chartHeight, option, type, classNames, style, event } = this.props;

	    const { defaultChartWidth, defaultChartHeight } = getDefaultSize(type);
	    const node = this.domRef.current;

		this.element.setAttribute('class', `sprout-chart-wrap ${classNames}`);
        this.element.setAttribute('style', style);

	    // this.element.style.width = (chartWidth || defaultChartWidth) + 'px';
	    // this.element.style.height = (chartHeight || defaultChartHeight) + 'px';

		node && node.appendChild(this.element)

		this.instance = echarts.init(this.element)

		option.color = I(option.color, '') ? option.color : roundColor

		this.instance.setOption(option, true)

        //图表事件
        event && event.forEach( v => {
            const { type, callback, isZr } = v;
            const instance = !!isZr ? this.instance.getZr() : this.instance;
            instance.on(type, params => callback( params, this.instance) );
        });

        /*const aa = this.instance;
        this.instance.getZr().on('click', function(params) {
            const pointInPixel = [params.offsetX, params.offsetY]
            if (aa.containPixel('grid', pointInPixel)) {
                let xIndex = aa.convertFromPixel({ seriesIndex: 0 }, [params.offsetX, params.offsetY])[0]
                console.log(xIndex)
            }
        });*/

		window.addEventListener('resize', this.handleResize)
	}

	componentWillUnmount() {
		const node = this.domRef.current;

		node && node.removeChild(this.element)
		this.instance.dispose();
		window.removeEventListener('resize', this.handleResize);
	}

	componentDidUpdate(prevProps) {		
		if(!isEqual(prevProps.option,this.props.option)) {
			this.instance.setOption(merge(defaultOption, this.props.option), true)
		}
	}

	handleResize = () => { debounce(this.instance.resize, 500)() }

	render() {
	    return (
	        <Fragment>
	            <div className="sprout-chart-root" ref={this.domRef} />
	        </Fragment>
	    );		
	}
}

export default Chart;