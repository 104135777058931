import React from 'react';
import LinkTable from 'components/CertificateDetail/components/Documents/LinkTable';
import Utils from 'Utils'
const { getRealUrlForFiles } = Utils;

const getUrl = (obj) => getRealUrlForFiles(obj?.real_name, 'dataroom')

export default function Documents(props) {
	const {
		token_incentive_plan,
		grant_letter,
		form_of_exercise_agreement = {},
		additional_document = []
	} = props.data || {};

	const addDocs = []
	additional_document.map((item) => {
		addDocs.push({
			name: item.name,
			url: getUrl(item)
		})
	})

	const eaOptionConfig = [
		{
			label: __('Grant letter'),
			value: grant_letter.name,
			url: grant_letter.real_name,
		},
		{
			label: __('Form of exercise agreement'),
			value: form_of_exercise_agreement?.name,
			url: form_of_exercise_agreement.real_name,
		},
		{
			label: __('Token incentive plan'),
			value: token_incentive_plan?.name,
			url: token_incentive_plan.real_name,
		},
		{
			label: __('Additional document'),
			value: addDocs,
			type: 'additional'
		}
	];

	return <div className="certificate-detail-section">
		<LinkTable title={__('Documents')} config={eaOptionConfig} rate="40%" />
	</div>
}