import React, { Fragment } from 'react';
import Utils from "Utils";
import RenameDraftSet from "../components/RenameDraftSet";
import CreateDraftSet from "../components/CreateDraftSet";
import SendInfoForSignature from "../components/SendInfoForSignature";
import { post } from 'srcPath/http';
import { formatNumber, I } from "Utils";
import { relationshipList, countries } from "Utils/constant";
import DeleteDraftSet from "../components/DeleteDraftSet";
import DeleteDraftSecurity from "../components/DeleteDraftSecurity";
import Loading from 'components/Loading';
import Permission from 'components/Permission';
import security from 'stores/security';
import lineStyle from "echarts/src/model/mixin/lineStyle";
import SelectStakeholder from 'components/SelectStakeholder';
import DraftOptionsTable from "./components/DraftOptionTableModels/index";
import moment from "moment";
import { DatePicker, Checkbox, Button, Tooltip } from "antd";
import DateSelector from "../../../../components/DateSelector";
import CountrySelector from 'components/CountrySelector';
import EnterEaInformation from "./components/DraftOptionBatchUploadModels/EnterEaInformationTwo";
import Modal, { ModalContent } from 'components/Modal';
import {LeftOutlined, ExclamationCircleOutlined, DeleteOutlined, CloseOutlined} from '@ant-design/icons';
import PdfPreview from "./EquityAwardsModal/PdfPreview";
import formatFractionalNumber from '../../../Utils/format/formatFractionalNumber';
import { printf, sprintf } from 'Utils/i18n';

class DraftOptions extends React.Component {

    templateModel = null;

    constructor(props) {
        super(props);
        this.state = {
            loadingStatus: 1,
            formatScheduleList: {},
            formatEaPlanList: {},
            addingStatus: 0,
            listKey: -1,
            saveStatus: -1,
            type: "OPTION",
            draft_id: "",
            draft_name: "",
            data: [],
            currency: "",
            curPlanName: "",
            curPlanId: "",
            postList: [
                "voluntary_termination",
                "involuntary_termination",
                "involuntary_termination_cause",
                "death_exercise",
                "disability_exercise",
                "retirement_exercise"
            ],
            draftOptionRequiredKey: [
                "email",
                "form_of_option_agreement",
                "relationship",
                "name",
                "quantity",
            ],
            boardApprovalStatus: [
                "Not board approved",
                "Board approved",
            ],
            currencyList: ['USD', 'HKD', 'SGD',],
            scheduleList: [
                { id: 3, schedule_name: "ddd" }
            ],
            optionGrantTypeList: [
                { value: "NSO", text: "NSO" },
                { value: "INTL", text: "INTERNATIONAL" },
                { value: "ISO", text: "ISO" }

            ],
            earlyExerciseList: [
                "",
                "Yes",
                "No",
            ],
            periodList: ["", "Days", "Months", "Years",],
            documentSet: [
                // {id:0,set_name:"Custom documents",form_of_option_file:{name:"No file selected",file_obj:""},form_of_exercise_file:{name:"No file selected",file_obj:""},incentive_plan_file:{name:"No file selected",file_obj:""},additional_documents:[{name:"No file selected",file_obj:""}]},
                { id: 1, set_name: "setOption1", form_of_option_file: { name: "11", file_obj: "" }, form_of_exercise_file: { name: "11", file_obj: "" }, incentive_plan_file: { name: "11", file_obj: "" }, additional_documents: [{ name: "11", file_obj: "" }, { name: "12", file_obj: "" }] },
            ],
            selectedDocKey: -1,
            successDetails: {},
            sendResult: {
                "1": {
                    "id": 1,
                    "is_success": 1,//1-成功，0表示失败
                    "error_reason": ""
                },
                "4": {
                    "id": 4,
                    "is_success": 0,//1-成功，0表示失败
                    "error_reason": [
                        { "title": "Name", "message": "Can't be empty!" },
                        { "title": "age", "message": "Can't be bigger than 150!" },
                        { "title": "Certificate Id", "message": "Duplicated" }
                    ]
                },
            },
            pageData: {
                name: "Option Grant",
                title: "Option grant",
                text: "option grant",
                checked_count: "",
                error_status: 0,
                type: 1
            },
            eaDraftSetList: [],
            eaPlanList: [],
            dateFormat: 'DD/MM/YYYY',
            isChecked: false,
            genderList: [
                {
                    name: 'Female'
                },
                {
                    name: 'Male'
                },
                {
                    name: 'Non-binary / non-conforming'
                },
                {
                    name: 'Prefer not to respond'
                },
            ],
            award_category: [],
            stakeholderType: [
                {
                    name: __('Individual'),
                    value: 1
                },
                {
                    name: __('Non-individual'),
                    value: 0
                },
            ],
            equityPlanSize: '',
            curPlan_id: 0,
            isDocusign: 0,
            docusignTemplate: [__('Grant Letter'), __('Form of Exercise Agreement'), __('Equity Incentive Plan'), __('Additional documents')],
            documentList: '',
            checkedIds:[],
        }
    }
    getEaOptionDraftSetList = () => {
        post('getDraftSetList', { type: "OPTION" }).then((response) => {
            if (response.data.code === 0) {
                for (let i = 0; i < response.data.data.draftList.length; i++) {
                    response.data.data.draftList[i].checkbox_status = false;
                    response.data.data.draftList[i].is_show_error = 0;
                }
                let formatScheduleList = this.state.formatScheduleList;
                for (let i = 0; i < response.data.data.vestingScheduleList.length; i++) {
                    formatScheduleList[response.data.data.vestingScheduleList[i].id] = response.data.data.vestingScheduleList[i];
                }
                let formatEaPlanList = this.state.formatEaPlanList;
                for (let i = 0; i < response.data.data.eaPlanList.length; i++) {
                    let postList = response.data.data.eaPlanList[i].draft_post_termination_period_info;
                    postList.voluntary_termination.checked = false;
                    postList.involuntary_termination.checked = false;
                    postList.involuntary_termination_cause.checked = false;
                    postList.death_exercise.checked = false;
                    postList.disability_exercise.checked = false;
                    postList.retirement_exercise.checked = false;
                    formatEaPlanList[response.data.data.eaPlanList[i].id] = response.data.data.eaPlanList[i];
                }

                this.setState({
                    eaDraftSetList: response.data.data.setList,
                    data: response.data.data.draftList,
                    draft_id: response.data.data.defaultSetInfo.id,
                    draft_name: response.data.data.defaultSetInfo.name,
                    currency: response.data.data.currency,
                    eaPlanList: response.data.data.eaPlanList,
                    curPlanName: response.data.data.eaPlanList[0].plan_name,
                    curPlanId: response.data.data.eaPlanList[0].id,
                    scheduleList: response.data.data.vestingScheduleList,
                    formatScheduleList,
                    formatEaPlanList,
                    loadingStatus: 0,
                    documentSet: response.data.data.documentSet,
                    award_category: response.data.data.award_category,
                    documentList: response.data.data.documentList,
                    isESign: I(response.data.data.e_sign, 0),
                });
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    changeStatus = (key) => {
        let optionsLists = this.state.data;
        if (optionsLists[key].checkbox_status === false) {
            optionsLists[key].checkbox_status = true;
            if (optionsLists[key].is_show_error == 1) {
                $(".draft_error_option_tip" + key).removeClass("showErrorTip")
            }
        } else {
            optionsLists[key].checkbox_status = false;
            if (optionsLists[key].is_show_error == 1) {
                $(".draft_error_option_tip" + key).addClass("showErrorTip")
            }
            $('#check01').prop("checked", false);
        }
        this.setState({
            data: optionsLists
        })
    }
    changeDraftId = (key1, key2) => {
        this.setState({
            draft_id: key1,
            draft_name: key2
        })
    }
    componentDidMount() {
        this.getEaOptionDraftSetList();
    }
    recordData = (e) => {
        let listKey = this.state.listKey;
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        let equityPlanSize = this.state.equityPlanSize;
        let isDocusign = 0
        if (dataKey == "vesting_schedule_id") {
            curData[listKey].vesting_schedule_id = dataVal;
            if (dataVal) {
                curData[listKey].vesting_schedule_name = this.state.formatScheduleList[curData[listKey].vesting_schedule_id].schedule_name;
            } else {
                curData[listKey].vesting_schedule_name = "No schedule";
                curData[listKey].vesting_start_date = ""
            }
        }
        //docusign disabled 标识

        if (dataKey == "documentset_id") {
            if (dataVal == -1) {
                curData[listKey].documentset_id = -3
            } else if (dataVal == -2) {
                this.props.history.push({ pathname: '/home/securities/templates', state: { activeKey: '1' } })
            } else if (dataVal == -3) {
                curData[listKey].documentset_id = 0;
                curData[listKey].form_of_option_file.name = "";
                curData[listKey].form_of_exercise_file.name = "";
                curData[listKey].incentive_plan_file.name = "";
                curData[listKey].additional_documents = [{ name: "", file_obj: "" }];
            } else {
                let data_val = this.state.documentSet.findIndex(item => item.id == dataVal)
                curData[listKey].documentset_id = this.state.documentSet[data_val].id;
                curData[listKey].form_of_option_file.name = this.state.documentSet[data_val].form_of_option_file.name || "";
                curData[listKey].form_of_option_file.real_name = this.state.documentSet[data_val].form_of_option_file.real_name;
                curData[listKey].form_of_exercise_file.name = this.state.documentSet[data_val].form_of_exercise_file.name || "";
                curData[listKey].form_of_exercise_file.real_name = this.state.documentSet[data_val].form_of_exercise_file.real_name;
                curData[listKey].incentive_plan_file.name = this.state.documentSet[data_val].incentive_plan_file.name || "";
                curData[listKey].incentive_plan_file.real_name = this.state.documentSet[data_val].incentive_plan_file.real_name;
                curData[listKey].additional_documents = this.state.documentSet[data_val].additional_documents;
                isDocusign = this.state.documentSet[data_val].is_docusign;
            }
            this.setState({
                selectedDocKey: dataVal,
                isDocusign
            })
        }
        if (dataKey == 'stakeholder_type' && dataVal == 0) {
            curData[listKey].job_title_list = [
                { effective_date: '', job_title: "" },
            ]
            curData[listKey].gender = ''
            curData[listKey].date_of_birth = ''
            curData[listKey].annual_salary = ''
        }
        if (dataKey == 'security_plan_id') {
            let eaPlanList = this.state.eaPlanList
            let status = eaPlanList.findIndex(item => item.id == dataVal)
            if (status != -1) {
                equityPlanSize = eaPlanList[status].available
            }
        }
        curData[listKey][dataKey] = dataVal;
        this.setState({
            data: curData,
            equityPlanSize
        })
        if (dataKey == "security_plan_id" || dataKey == "relationship" || dataKey == "vesting_schedule_id") {
            this.editEaOptionDraft();
        }
    }
    recordDataForPost = (key, e) => {
        let listKey = this.state.listKey;
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[listKey][key][dataKey] = dataVal;
        this.setState({
            data: curData
        })
    }
    recordNumber = (e) => {
        const isFractionalShare = security.companyInfo.fractional_control;
        let listKey = this.state.listKey;
        let curData = this.state.data;
        let curVal = e.target.value;
        let dataKey = e.target.name;
        if (dataKey == "quantity") {
            if (isFractionalShare == 1) {
                curData[listKey][dataKey] = Utils.check5DecimalNumInputWithoutComma(curVal);
            } else {
                curData[listKey][dataKey] = Utils.checkNumInput(curVal);
            }
        } else if (dataKey == "exercise_price") {
            curData[listKey][dataKey] = Utils.check5DecimalNumInputWithoutComma(curVal);
        } else if (dataKey == "annual_salary") {
            curData[listKey][dataKey] = Utils.check2DecimalNumInputWithoutComma(curVal);
        } else {
            curData[listKey][dataKey] = Utils.checkIntInputWithoutComma(curVal);
        }
        this.setState({
            data: curData
        });
    }
    setDataFormat = (e) => {
        let listKey = this.state.listKey;
        let curData = this.state.data;
        let curVal = e.target.value;
        let dataKey = e.target.name;
        curData[listKey][dataKey] = Utils.resetNumberWithCommas(curVal);
        this.setState({
            data: curData
        });
    }
    resetDataFormat = (e) => {
        const isFractionalShare = security.companyInfo.fractional_control;
        let listKey = this.state.listKey;
        let curData = this.state.data;
        let curVal = e.target.value;
        let dataKey = e.target.name;
        if (dataKey == "quantity") {
            if (isFractionalShare == 1) {
                curData[listKey][dataKey] = formatNumber(curVal, 5);
            } else {
                curData[listKey][dataKey] = formatNumber(curVal, null);
            }
        } else if (dataKey == "exercise_price") {
            curData[listKey][dataKey] = formatNumber(curVal, null);

        } else if (dataKey == "annual_salary") {
            curData[listKey][dataKey] = formatNumber(curVal);
        } else {
            curData[listKey][dataKey] = Utils.checkNumInput(curVal);
        }
        this.setState({
            data: curData
        });
        this.editEaOptionDraft();
    }
    recordNumberForPost = (key, e) => {
        let listKey = this.state.listKey;
        let curData = this.state.data;
        let curVal = e.target.value;
        let dataKey = e.target.name;
        curData[listKey][key][dataKey] = Utils.checkNumInput(curVal);
        this.setState({
            data: curData
        });
    }

    changeCheckedStatus = (key) => {
        let listKey = this.state.listKey;
        let curChecked = this.state.data;
        curChecked[listKey][key].checked = !curChecked[listKey][key].checked;
        if (!curChecked[listKey][key].checked) {
            window.$("." + key).attr("disabled", "disabled");
        } else {
            window.$("." + key).removeAttr("disabled", "disabled");
        }
        this.setState({
            data: curChecked
        })
    }
    setFileName = (e) => {
        let listKey = this.state.listKey;
        let fileObj = e.target.files[0];
        let dataKey = e.target.name;
        let curData = this.state.data;
        Utils.uploadFile(fileObj).then((value) => {
            if (dataKey == "additional_documents") {
                let additionalDoc = { name: fileObj.name, file_obj: value };
                if (curData[listKey].additional_documents[0].name == '') {
                    curData[listKey].additional_documents[0] = additionalDoc;
                    $(".additional_documents0").removeClass("hidden");
                } else {
                    curData[listKey].additional_documents.push(additionalDoc);
                }
            } else {
                curData[listKey][dataKey].name = fileObj.name;
                curData[listKey][dataKey].file_obj = value;
            }
            this.setState({
                data: curData
            })
            this.editEaOptionDraft();
        })
    }
    fileDelete = (key, additionalKey) => {
        let listKey = this.state.listKey;
        this.refs[key].value = "";
        let curData = this.state.data;
        if (additionalKey != -1) {
            if (curData[listKey][key].length == 1) {
                curData[listKey][key][0].name = "";
                curData[listKey][key][0].file_obj = "";
            } else {
                curData[listKey][key].splice(additionalKey, 1)
            }
        } else {
            curData[listKey][key].name = "";
            curData[listKey][key].file_obj = "";
        }
        this.setState({
            data: curData
        })
        this.editEaOptionDraft();
    }
    createDraftOptions = () => {
        if (this.state.addingStatus == 1) {
            return;
        }
        this.setState({
            addingStatus: 1
        })
        let curData = this.state.data;
        for (let i = 0; i < this.state.data.length; i++) {
            this.state.data[i].checkbox_status = false
        }
        this.setState({
            data: curData
        });
        post('createEaOptionDraft', { draft_set_id: this.state.draft_id, security_plan_id: this.state.curPlanId }).then((response) => {
            let curData = this.state.data;
            let draftSetList = this.state.eaDraftSetList;
            let setId = this.state.draft_id;
            if (response.data.code === 0) {
                response.data.data.checkbox_status = false;
                response.data.data.is_show_error = 0;
                response.data.data.voluntary_termination.checked = false;
                response.data.data.involuntary_termination.checked = false;
                response.data.data.involuntary_termination_cause.checked = false;
                response.data.data.death_exercise.checked = false;
                response.data.data.disability_exercise.checked = false;
                response.data.data.retirement_exercise.checked = false;
                curData.push(response.data.data)
                for (let i = 0; i < curData.length; i++) {
                    curData[i].is_show_error = 0
                }
                for (let j = 0; j < draftSetList.length; j++) {
                    if (draftSetList[j].id == setId) {
                        draftSetList[j].count = parseInt(draftSetList[j].count) + 1
                    }
                }
                this.setState({
                    data: curData,
                    addingStatus: 0,
                    // listKey: this.state.data.length - 1,
                    eaDraftSetList: draftSetList
                })
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    changeEaDraftSelect = (planName, planId, e) => {
        let curData = this.state.data;
        let formatEaPlanList = this.state.formatEaPlanList;
        this.setState({
            curPlanName: planName,
            curPlanId: planId
        })
        for (let i = 0; i < curData.length; i++) {
            curData[i].voluntary_termination = formatEaPlanList[planId].draft_post_termination_period_info.voluntary_termination;
            curData[i].involuntary_termination = formatEaPlanList[planId].draft_post_termination_period_info.involuntary_termination;
            curData[i].involuntary_termination_cause = formatEaPlanList[planId].draft_post_termination_period_info.involuntary_termination_cause;
            curData[i].death_exercise = formatEaPlanList[planId].draft_post_termination_period_info.death_exercise;
            curData[i].disability_exercise = formatEaPlanList[planId].draft_post_termination_period_info.disability_exercise;
            curData[i].retirement_exercise = formatEaPlanList[planId].draft_post_termination_period_info.retirement_exercise;
        }
        this.checkPostCheckStatus();
        this.setState({
            data: curData
        })
    }
    checkPostCheckStatus = () => {
        let listKey = this.state.listKey;
        let curData = this.state.data;
        for (let i = 0; i < curData.length; i++) {
            curData[i].voluntary_termination.checked = false;
            curData[i].involuntary_termination.checked = false;
            curData[i].involuntary_termination_cause.checked = false;
            curData[i].death_exercise.checked = false;
            curData[i].disability_exercise.checked = false;
            curData[i].retirement_exercise.checked = false;
        }
        this.setState({
            data: curData
        })
        if (listKey != -1) {
            for (let i = 0; i < this.state.postList.length; i++) {
                // if(!this.state.data[listKey][this.state.postList[i]].checked){
                //     window.$("."+this.state.postList[i]).attr("disabled","disabled");
                // }else{
                //     window.$("."+this.state.postList[i]).removeAttr("disabled","disabled");
                // }
                window.$("." + this.state.postList[i]).attr("disabled", "disabled");
            }
        }
    }
    transferKey = (key) => {
        const { listKey, data } = this.state;
        let curKey = listKey;
        let curData = [...data];
        let equityPlanSize = ''
        let eaPlanList = this.state.eaPlanList
        let isDocusign = 0
        if (curKey === key) {
            curKey = -1;
        } else {
            curKey = key;
            let status = eaPlanList.findIndex(item => item.id == curData[curKey].security_plan_id)
            if (status != -1) {
                equityPlanSize = eaPlanList[status].available
            }
            let data_val = this.state.documentSet.findIndex(item => item.id == curData[curKey].documentset_id)
            if (data_val >= 0) {
                isDocusign = this.state.documentSet[data_val].is_docusign;
            }
            /**
             * 兼容历史数据
             * 没有docusign权限，但绑定过template时需初始化
             */
            if (!this.state.isESign && isDocusign) {
                curData[curKey].documentset_id = 0;
                curData[curKey].form_of_option_file.name = "";
                curData[curKey].form_of_exercise_file.name = "";
                curData[curKey].incentive_plan_file.name = "";
                curData[curKey].additional_documents = [{ name: "", file_obj: "" }];
                isDocusign = 0;
            }
            curData.forEach((v, k)=>{
                v.checkbox_status = false;
                curData[k] = {...v};
            });
        }
        $('.collapse').removeClass('in');
        this.setState({
            listKey: curKey,
            equityPlanSize,
            data: curData,
            isDocusign
        })
        setTimeout(() => {
            this.checkPostCheckStatus();
        }, 200)
    }

    handleDraftOptionDateChange = (key, moment_key, date) => {
        const { data, listKey, dateFormat } = this.state;
        let curData = [...data];
        curData[listKey][key] = date ? date.format(dateFormat) : '';
        curData[listKey][moment_key] = date || '';
        this.setState({
            data: curData
        });
        this.editEaOptionDraft();
    }

    selectCount = () => {
        let selectLength = this.state.pageData;
        let selectCount = 0;
        for (let j = 0; j < this.state.data.length; j++) {
            if (this.state.data[j].status === true) {
                selectCount++;
            }
        }
        selectLength.checked_count = selectCount;
        this.setState({
            pageData: selectLength
        })
        this.refs.sendForSignature.getSignatureAdminList();
        this.refs.sendForSignature.getCheckedId();
    }
    alertError = () => {
        window.$(".correct_error_tip").removeClass("hidden");
    }
    transferDraftSetData = (key, refName, e) => {
        this.refs[refName].initData(key, this.state.eaDraftSetList[key]);
    }
    getDraftSecurityList = () => {
        this.refreshDraftSecurityList();
        this.setState({ listKey: -1 })
    }
    editEaOptionDraft = (e) => {
        if (this.state.saveStatus == 1) {
            return;
        }
        this.setState({
            saveStatus: 0
        })
        let requestData = { ...this.state.data[this.state.listKey] };
        requestData.relationship_list = JSON.stringify(requestData.relationship_list)
        requestData.job_title_list = JSON.stringify(requestData.job_title_list)

        post('editEaOptionDraft', requestData).then((response) => {
            if (response.data.code === 0) {
                if (response.data.data.custom_label_id) {
                    requestData['custom_label_id'] = response.data.data.custom_label_id;
                    this.refreshDraftSecurityList();
                }
                this.setState({
                    saveStatus: 1
                })
                setTimeout(() => {
                    this.setState({
                        saveStatus: -1
                    });
                }, 300)
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    getDeleteCheckedId = () => {
        this.refs.deleteDraftSecurity.getCheckedId();
    }
    targetError = () => {
        this.setState({
            error_status: 1
        })
    }
    selectDraftSet = (key) => {
        let curDraftList = this.state.eaDraftSetList;
        this.setState({
            draft_name: curDraftList[key].name,
            draft_id: curDraftList[key].id,
            listKey: -1,
        })
        post('getDraftSecurityListById', { id: curDraftList[key].id }).then((response) => {
            if (response.data.code === 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    response.data.data[i].checkbox_status = false;
                    response.data.data[i].is_show_error = 0;
                }
                this.setState({
                    data: response.data.data,
                    listKey: -1
                })
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    getDeleteDraftId = () => {
        this.refs.deleteDraftSecurity.getDeleteDraftId();
    }
    removeSuccessData = (result) => {
        let removeIds = [];
        for (let i in result) {
            removeIds.push(parseInt(i));
        }
        removeIds.sort();
        let curData = [...this.state.data];
        let newData = [...this.state.data];
        for (let i = curData.length - 1; i >= 0; i--) {
            for (let j = 0; j < removeIds.length; j++) {
                if (curData[i].id == removeIds[j]) {
                    newData.splice(i, 1);
                }
            }
        }
        this.setState({
            data: newData,
            listKey: -1
        })
    }
    getErrorMessage = (result) => {
        this.setState({
            sendResult: result,
        })
        let curData = [...this.state.data];
        for (let i = 0; i < this.state.data.length; i++) {
            if (this.state.sendResult.hasOwnProperty(this.state.data[i].id)) {
                this.state.data[i].is_show_error = 1
            }
        }
        this.setState({
            data: curData
        })
    }

    handleSelectChange = (name, data) => {
        let curData = this.state.data;
        let listKey = this.state.listKey;
        if (data) {
            curData[listKey].name = data.nick_name || "";
            curData[listKey].email = data.email || "";
            curData[listKey].stakeholder_id = data.id || '';
        }
        this.setState({
            selectedData: data,
            data: curData
        })
    }

    /**
     * checkbox change事件
     * @param selectedRowKeys
     */
    securityListsChange = (selectedRowKeys) => {
        const { data } = this.state;
        let securityLists = [...data];
        securityLists.forEach((v, k) => {
            securityLists[k]['checkbox_status'] = false;
        });
        selectedRowKeys.forEach((v) => {
            securityLists.forEach((item) => {
                if (item.id == v) {
                    item['checkbox_status'] = true;
                }
            })
        })
        this.setState(() => ({
            data: securityLists,
            checkedIds: [].concat(selectedRowKeys)
        }));
    }

    /**
     * 设置custom_label_id之后更新列表
     */
    refreshDraftSecurityList = () => {
        post('getDraftSecurityListById', { id: this.state.draft_id }).then((response) => {
            if (response.data.code === 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    response.data.data[i].checkbox_status = false;
                    response.data.data[i].is_show_error = 0;
                }
                this.setState({
                    data: response.data.data,
                })
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    /**
     * 更新table checkbox
     * @param selectCount 选中总计
     * @param selectIndexArray 有更新的checkbox下标和状态
     * @param isEmpty checkbox是否为全不选状态
     */
    updateSelectCount = (selectCount, selectIndexArray, checkedIdArray, isEmpty = false) => {
        const { data, listKey } = this.state;
        let list = [...data];
        if (isEmpty) {
            list.forEach((v, k) => {
                list[k]['checkbox_status'] = false;
            })
        } else {
            selectIndexArray.forEach((v, k) => {
                list[v.selectIndex]['checkbox_status'] = v.checked;
            })
        }
        this.setState(() => ({
            selectCount,
            selectIndex: selectIndexArray[0],
            data: list,
            listKey: selectCount > 0 ? -1 : listKey,
            checkedIds: [].concat(checkedIdArray)
        }))
    }
    CheckboxChange(isChecked) {
        const { data, listKey } = this.state;
        let list = [...data];
        if (isChecked == 1) {
            list[listKey].relationship_list = [
                { effective_date: '', relationship: "" },
            ]
            list[listKey].job_title_list = [
                { effective_date: '', job_title: "" },
            ]
            list[listKey].employee_id = ''
            list[listKey].passport = ''
            list[listKey].date_of_birth = ''
            list[listKey].gender = ''
            list[listKey].annual_salary = ''
            list[listKey].country = ''
            list[listKey].address_details = ''
            list[listKey].name = ''
            list[listKey].email = ''
            list[listKey].stakeholder_type = ''
        } else {
            list[listKey].name = ''
            list[listKey].email = ''
            list[listKey].relationship_list = [
                { effective_date: Utils.getCurDate(), relationship: "Investor" },
            ]
            list[listKey].job_title_list = [
                { effective_date: Utils.getCurDate(), job_title: "" },
            ]
            list[listKey].stakeholder_type = 1
        }
        list[listKey].choose_type = isChecked
        this.setState({
            data: list
        })
        this.editEaOptionDraft()
    }
    relationshipEffctiveDateChange = (listName, name, key, date) => {
        if (!date) {
            return
        }
        const { data, dateFormat, listKey } = this.state;
        let curData = this.state.data[listKey];
        curData[listName][key][name] = date && date.format(dateFormat);
        data[listKey] = curData
        this.setState({
            data
        });
    }
    setRelation = (key, e) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        curList.relationship_list[key].relationship = e.target.value;
        data[listKey] = curList
        this.setState({
            data
        })

    }
    addRelation = () => {
        const { data, listKey } = this.state;
        let one = { effective_date: Utils.getCurDate(), relationship: "Investor" }
        let curList = this.state.data[listKey];
        curList.relationship_list.push(one);
        data[listKey] = curList
        this.setState({
            data
        })
    }
    delRelation = (num) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        if (curList.relationship_list.length <= 1) {
            return;
        }
        curList.relationship_list.splice(num, 1);
        data[listKey] = curList
        this.setState({
            data
        })
    }


    JobRecordData = (key, e) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        curList.job_title_list[key].job_title = e.target.value;
        data[listKey] = curList
        this.setState({
            data
        })
    }
    delJobTitle = (num) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        if (curList.job_title_list.length <= 1) {
            return;
        }
        curList.job_title_list.splice(num, 1);
        data[listKey] = curList
        this.setState({
            data
        })
        this.editEaOptionDraft()
    }
    addJobTitle = () => {
        let one = { effective_date: Utils.getCurDate(), job_title: "" }
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        curList.job_title_list.push(one);
        data[listKey] = curList
        this.setState({
            data
        })
    }
    countryRecordData = (val) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        curList['country'] = val
        data[listKey] = curList
        this.setState({
            data
        })
    }
    goTemplates = () => {
        this.props.history.push({ pathname: '/home/securities/templates', state: { activeKey: '3' } })
    }
    bindBatchUploadModel = () => {
        // let { eaHistoryList } = this.state

        this.modalRef = Modal.open({
            component: props => <ModalContent close={props.close} title={__("Bulk upload")}>
                {/* historyList={eaHistoryList} callback={this.getEaHistoryList} */}
                <EnterEaInformation close={props.close} callback={this.getEaOptionDraftSetList} />
            </ModalContent>,
            className: 'sprout-modal email-modal',
            width: 1300
        })
    }
    bindManageTemplates = () => {
        this.props.history.push({ pathname: '/home/securities/templates', state: { activeKey: '1' } })
    }
    bindSecurities = () => {
        this.props.history.push({ pathname: '/home/securities/stock-option-awards', state: { activeKey: '1' } })
    }

    docusignTemplate = () => {
        const { docusignTemplate, documentList, data, listKey } = this.state;
        const documentset_id = data[listKey] && data[listKey].documentset_id;
        if (!I(documentset_id, '')) {
            return;
        }
        const { template_list } = documentset_id && documentList && documentList[documentset_id];
        return (
            <div className="draft-option-docusign">
                {docusignTemplate.map((v, k) => {
                    const index = k + 1;
                    const openData = template_list ? { id: template_list[index] && template_list[index].id, name: template_list[index] && template_list[index].template_name } : {};
                    return template_list && (
                        <div className="draft-option-docusign-select">
                            <p>{v}</p>
                            <Button type="primary" disabled={!template_list[index]} onClick={this.openTemplateModel.bind(this, openData)}>{__('Preview')}</Button>
                            <span>{template_list[index] ? I(template_list[index].template_name, '') : '--'}</span>
                        </div>
                    )
                })}
            </div>
        );
    }

    openTemplateModel = (data) => {
        const { data: dataInfo, listKey } = this.state;
        const { name: nick_name, email, grant_date, quantity, exercise_price, custom_label_id, stakeholder_id, country, job_title_list, passport, choose_type, address_details, vesting_schedule_id, expiration_date } = dataInfo[listKey] || [];
        const postData = {
            id: data.id,
            stakeholder: { stakeholder_id: (choose_type == 2 ? 0 : stakeholder_id), nick_name, email, country, job_title_list: JSON.stringify(job_title_list), passport, address_details },
            secInfo: { issue_date: grant_date, quantity, exercise_price, custom_label_id, vesting_id: vesting_schedule_id, expiration_date },
        };
        this.templateModel = Modal.open({
            component: props => (
                <ModalContent close={props.close} title={data.name}>
                    <PdfPreview id={data.id} postData={postData} />
                </ModalContent>
            ),
            width: 1000,
            className: 'sprout-modal documents-set-model',
            maskClosable: false
        });
    }

    componentWillUnmount() {
        this.templateModel && this.templateModel.close()
    }

    bindStakeholders = () => {
        this.props.history.push('/home/stakeholders/stakeholders-list')
    }

    closeDraftLeft = () => {
        this.setState({ listKey: -1 });
    }

    render() {
        if (this.state.addingStatus === 1) {
            return (
                <div className="loading_position">
                    <Loading />
                </div>
            )
        }
        let listKey = this.state.listKey;
        let selectCount = 0;
        let inputStatus = "disabled"
        for (let j = 0; j < this.state.data.length; j++) {
            if (this.state.data[j].checkbox_status === true) {
                selectCount++;
            }
        }
        let actionButtonClass = 'btn action-disabledBtn action-btn dropdown-toggle';
        let sendButtonClass = 'btn boxbtn-to send-newdisabledBtn';

        if (selectCount >= 1) {
            actionButtonClass = 'btn action-availableBtn action-btn dropdown-toggle';
            sendButtonClass = 'btn boxbtn-to send-newavailableBtn';
        }
        if (this.state.listKey >= 0 && this.state.data[listKey].board_approval_status == "Board approved") {
            inputStatus = "";
        }
        let isShowDocList = 0;
        let hasAdditionalDoc = 0;
        let isCustomDoc = 0;
        if (listKey != "-1") {
            for (let k = 0; k < this.state.data[listKey].additional_documents.length; k++) {
                if (this.state.data[listKey].additional_documents[k].real_name != "") {
                    hasAdditionalDoc = 1
                }
            }
        }
        if (listKey >= 0) {
            if (this.state.data[listKey].documentset_id == 0) {
                isCustomDoc = 1;
            }
        }
        if (listKey >= 0) {
            if (this.state.data[listKey].form_of_option_file.real_name != ""
                || this.state.data[listKey].form_of_exercise_file.real_name != ""
                || this.state.data[listKey].incentive_plan_file.real_name != ""
                || hasAdditionalDoc == 1 || isCustomDoc == 1
            ) {
                isShowDocList = 1;
            }
        }
        let curData = this.state.data;
        let quantityInDraft = 0;
        for (let j = 0; j < curData.length; j++) {
            quantityInDraft += parseInt(Utils.resetNumberWithCommas(curData[j].quantity));
        }
        const { isDocusign, documentSet, currency = '', data, formatShareClassList, dateFormat, selectedData, isChecked, genderList, stakeholderType, eaPlanList, isESign } = this.state;
        const certificateKey = eaPlanList.findIndex(v=>v?.id == data[listKey]?.security_plan_id);

        const requiredTip = this.state.data[listKey]?.board_approval_status == 'Board approved' ? <i className="text-muted"><small> - {__('Required')}</small></i> : ''
        return (
            <div className="draftsContent">
                <RenameDraftSet type={this.state.type} ref="renameDraftSet" getDraftSecurityList={this.getDraftSecurityList} getDraftSetList={this.getEaOptionDraftSetList} draft_name={this.state.draft_name} draft_id={this.state.draft_id} changeDraftId={this.changeDraftId} />
                <DeleteDraftSet ref="deleteDraftSet" getDraftSecurityList={this.getDraftSecurityList} getDraftSetList={this.getEaOptionDraftSetList} draft_name={this.state.draft_name} draft_id={this.state.draft_id} changeDraftId={this.changeDraftId} />
                <DeleteDraftSecurity ref="deleteDraftSecurity" curData={this.state.data} getDraftSecurityList={this.getDraftSecurityList} listKey={this.state.listKey} checkedIds={this.state.checkedIds}/>
                <CreateDraftSet type={this.state.type} setList={this.state.eaDraftSetList} getDraftSecurityList={this.getDraftSecurityList} getEaOptionDraftSetList={this.getEaOptionDraftSetList} draft_name={this.state.draft_name} draft_id={this.state.draft_id} changeDraftId={this.changeDraftId} />
                <SendInfoForSignature curPlanId={this.state.curPlan_id} removeSuccessData={this.removeSuccessData} getErrorMessage={this.getErrorMessage} targetError={this.targetError} curData={this.state.data} ref="sendForSignature" alertError={this.alertError} pageData={this.state.pageData} checkedIds={this.state.checkedIds}/>
                {/*{this.state.reloadStatus==1 &&*/}
                {/*<div className="loading_position">*/}
                {/*    <Loading/>*/}
                {/*</div>*/}
                {/*}*/}
                <div className="prefer-main">
                    <div className={`prefer-left ${listKey < 0 && 'prefer-left-max'}`}>
                        <Button className="billing-change-plan-back" onClick={this.bindSecurities}>
                            <LeftOutlined />{__('Back')}
                        </Button>
                        <div className="holders-nav minWidth400 clearfix">
                            <div className="action-box onset align-center">
                                <Permission type="securities_draftEA_delete" hide>
                                    <button type="button" className={actionButtonClass + " marginRig10 width80"}
                                        data-toggle="modal" onClick={this.getDeleteCheckedId}
                                        data-target={"#deleteDraftSecurityModal"}>
                                        {__('Delete')}
                                    </button>
                                </Permission>
                                <div>
                                    {/* <div className="btn-group drafts-btn-group draft_option_btnGroup">
                                        <a className="dropdown-toggle text-blue drafts-btn blue-triangle-down" data-toggle="dropdown">{this.state.curPlanName}&nbsp;&nbsp;</a>
                                        <ul className="dropdown-menu draft_option_downmenu ">
                                            {this.state.eaPlanList && this.state.eaPlanList.map((value, key) => {
                                                let totalQuantity = parseInt(value.size);
                                                let availableQuantity = parseInt(value.available);
                                                let issuedQuantity = totalQuantity - availableQuantity;
                                                let issuedPercent = issuedQuantity * 100 / totalQuantity
                                                return (
                                                    <li key={key} className="option_li padding1010 link-cursor"
                                                        onClick={this.changeEaDraftSelect.bind(this, value.plan_name, value.id)}>
                                                        <p className="mb-0">{value.plan_name}</p>
                                                        <div className="progress option-progress option_downmenu_progress">
                                                            <div className="progress-bar option-progress-bar"
                                                                role="progressbar" aria-valuenow={Math.ceil(issuedPercent)}
                                                                aria-valuemin="0" aria-valuemax="100" style={{ width: Math.ceil(issuedPercent) + "%" }}>
                                                                <span className="sr-only">% Complete</span>
                                                            </div>
                                                        </div>
                                                        <p><span
                                                            className="draft_square marginRig10 mb-0 bg-seablue"></span>{quantityInDraft == 0 ? 0 : Utils.checkNumInput(quantityInDraft)} option in draft</p>
                                                        <p><span className="draft_square marginRig10 mb-0 bg-darkblue"></span>{issuedQuantity == 0 ? 0 : Utils.checkNumInput(issuedQuantity)} option issued</p>
                                                        <p><span
                                                            className="draft_square marginRig10 mb-0 bg-gray"></span>{Utils.checkNumInput(value.size)} options
                                                            available</p>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div> */}
                                    <Permission type="securities_draftEA_set" hide>
                                        <div className="btn-group drafts-btn-group">
                                            <a className="dropdown-toggle text-blue blue-triangle-down drafts-btn" data-toggle="dropdown">{this.state.draft_name}&nbsp;&nbsp;</a>
                                            <ul className="dropdown-menu drafts-downmenu">
                                                {this.state.eaDraftSetList.map((value, key) => {
                                                    return (
                                                        <li key={key}>
                                                            <a className="resetDrafts" value={value.id} onClick={this.selectDraftSet.bind(this, key)}>{value.name}
                                                                <DeleteOutlined className='drafts-trash-can' onClick={this.transferDraftSetData.bind(this, key, "deleteDraftSet")} data-toggle="modal" data-target="#deleteDraftSetModal"/>
                                                                <span className="drafts-icon"
                                                                    onClick={this.transferDraftSetData.bind(this, key, "renameDraftSet")}
                                                                    data-toggle="modal"
                                                                    data-target="#enterNewNameForDraftsModal">{value.count}
                                                                </span>
                                                            </a>
                                                        </li>
                                                    )
                                                })}
                                                <li><a data-toggle="modal" data-target="#createNewDraftSetModal">{__('Create a new draft set')}</a></li>
                                            </ul>
                                        </div>
                                    </Permission>
                                </div>
                            </div>
                            <div className="form-group search-group banner-rightBox offset">
                                <Permission type="securities_draftEA_add" hide>
                                    <div className="btn-group marginLf10">
                                        <a className="btn boxbtn-to send-availableBtn" onClick={this.bindBatchUploadModel}>{__('Bulk upload')}</a>
                                    </div>
                                    <div className="btn-group marginLf10">
                                        <a className="btn boxbtn-to bg-darkblue" onClick={this.createDraftOptions}>+ {__('Add option grant')}
                                        </a>
                                    </div>
                                </Permission>
                                <Permission type="securities_draftEA_send" hide>
                                    <div className="btn-group marginLf10">
                                        {selectCount >= 1 && <a onClick={this.selectCount} data-toggle="modal"
                                            data-target="#sendInfoForSignatureModal"
                                            className={sendButtonClass}>{__('Send')}</a>}
                                        {selectCount == 0 && <a className={sendButtonClass}>{__('Send')}</a>}
                                    </div>
                                </Permission>
                            </div>
                        </div>
                        {this.state.loadingStatus === 1 &&
                            <div className="loading_position">
                                <Loading />
                            </div>
                        }
                        {this.state.loadingStatus === 0 &&
                            <div>
                                {this.state.data.length == 0 &&
                                    <div className="empty_option ">
                                        <div className="empty_option_box marginTop80">
                                            <span className="empty_option_icon bg_draft_file"></span>
                                            <h5 className="empty_option_text">{__('This set has no option grants.')}</h5>
                                            <Permission type="securities_draftEA_add" hide>
                                                <button className="btn action-availableBtn action-btn white-text font_weight500"
                                                    onClick={this.createDraftOptions}>
                                                    {__('Add Option Grant')}
                                                </button>
                                            </Permission>
                                        </div>
                                    </div>
                                }
                                {this.state.data.length >= 1 &&
                                    <div className="draftList">
                                        <div className="scroll-div">
                                            <DraftOptionsTable
                                                tableList={data}
                                                transferKey={this.transferKey}
                                                eaPlanList={eaPlanList}
                                                listKey={listKey}
                                                securityListsChange={this.securityListsChange}
                                                updateSelectCount={this.updateSelectCount}
                                            />

                                        </div>

                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <div className={`prefer-right ${listKey < 0 && 'prefer-right-max'}`}>
                        <div className="error_tip_bar alert correct_error_tip bg-danger_text hidden" role="alert">
                            <div className="error_icon_box bg-lightred">
                                <span className="error_tip_icon  glyphicon glyphicon-exclamation-sign"></span>
                            </div>
                            <p className="error_tip_text bg-danger_text font_weight500">Correct the error in grant {this.state.listKey + 1}.</p>
                        </div>
                        {this.state.listKey == -1 &&
                            <div className="empty_option">
                                <div className="empty_option_box marginTop186">
                                    <span className="edit-icon"></span>
                                    <h5 className="edit_text font_weight500">{__('Select a draft to edit')}</h5>
                                </div>
                            </div>
                        }
                        {this.state.listKey >= 0 &&
                            <div>
                                {this.state.data[listKey].is_show_error == 1 &&
                                    <div className="error_details error_tip_bar bg-lightred alert alert-dismissible" role="alert">
                                        <div className="error_icon_box bg-redbtn">
                                            {/*<span className="error_tip_icon  glyphicon glyphicon-exclamation-sign"></span>*/}
                                            <ExclamationCircleOutlined style={{ color: '#d42f2e' }} />
                                        </div>
                                        <div className="error_tip_text bg-lightred font_weight500">
                                            <h5 className="error_tip_text">{__('There were some problems with this option grant.')}</h5>
                                            <div className="error_list">
                                                {this.state.sendResult && this.state.sendResult[this.state.data[listKey].id].error_reason.map((val, k) => {
                                                    return (
                                                        <p className="marginTop10 mb-0" key={k}>{val.title}: {val.message}</p>
                                                    )

                                                })}
                                            </div>
                                        </div>
                                        <div className="error_close bg-redbtn">
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                        </div>
                                    </div>
                                }
                                {this.state.saveStatus == 0 &&
                                    <div className="banner-download draft_save_banner bg-apple-green no-marginBottom ">
                                        <div className="circle-border draft-circle-border"></div>
                                        <p className="download-message">{__('Saving')}</p>
                                    </div>
                                }
                                {this.state.saveStatus == 1 &&
                                    <div className="banner-download draft_save_banner bg-apple-green no-marginBottom ">
                                        <span className="colorWhite glyphicon glyphicon-ok"></span>
                                        <p className="download-message">{__('Draft saved')}</p>
                                    </div>
                                }
                                <ul>
                                    <li className="draft-titLi clearfix">
                                        <div className="draft-titleLi-group">
                                            {/*<p className="onset">{__('Option Grant')} ({eaPlanList?.[certificateKey]?.prefix}-{data[listKey]?.custom_label_id})</p>*/}
                                            <p className="onset">{__('Option Grant')} ({data[listKey].custom_label_id.substring(0, eaPlanList[certificateKey]?.prefix.length) == eaPlanList[certificateKey].prefix ? data[listKey].custom_label_id : (eaPlanList[certificateKey].prefix + '-' + data[listKey].custom_label_id)})</p>
                                            <CloseOutlined onClick={this.closeDraftLeft} />
                                        </div>
                                        {/* <div className="offset">
                                            <button type="button" className="draft-certificate-btn marginRig6"><span className="white-text glyphicon glyphicon-search"></span></button>
                                            <button type="button" onClick={this.getDeleteDraftId} data-toggle="modal" data-target={"#deleteDraftSecurityModal"} className="draft-certificate-btn" ><span className="white-text glyphicon glyphicon-trash"></span></button>
                                        </div> */}
                                    </li>
                                    <li>
                                        <a className="prefer-togglebtn" href="#option_grant_li" data-toggle="collapse">{__('Option grant')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                        <div className="collapse" id="option_grant_li">
                                            <form className="row">
                                                <div className="form-group col-xs-6 equity-plan">
                                                    <label htmlFor="draft_option_quantity">{__('Equity plan')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                    <select id="draft_option_type" name="security_plan_id"
                                                        value={this.state.data[listKey].security_plan_id}
                                                        className="form-control select-container"
                                                        onChange={this.recordData}>
                                                    }
                                                        {this.state.eaPlanList.map((value, key) => {
                                                            return (
                                                                <option key={key} value={value.id}>{value.plan_name} ({value.prefix})</option>
                                                            )
                                                        })}
                                                    </select>

                                                </div>

                                                <div className="form-group col-xs-6">
                                                    <label htmlFor="draft_option_custom_id"></label>
                                                    <div className="equity-plan-size">{printf(__('%s options available'), formatFractionalNumber(this.state.equityPlanSize))}</div>
                                                </div>


                                            </form>
                                            <div className="row">
                                                <div className="form-group col-xs-6">
                                                    <label htmlFor="draft_option_custom_id">{__('Custom ID')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                    <input id="draft_option_custom_id" name="custom_label_id"
                                                        onBlur={this.editEaOptionDraft}
                                                        value={this.state.data[listKey].custom_label_id}
                                                        onChange={this.recordData} type="text" className="form-control" />
                                                </div>
                                                <div className="form-group col-xs-6">
                                                    <label htmlFor="draft_option_type">{__('US tax type')}</label>
                                                    <Tooltip placement="left" title={__('This category is applicable for US-based companies which are regulated by the US IRS.  ISOs (Incentive Stock Options) and NSOs (Non-Qualified Stock Options) are typically granted by US-based companies to their employees and provides potential tax advantages based on specific conditions. In contrast, options granted by international offshore businesses are outside the United States and are subject to the tax rules of the respective offshore jurisdiction, potentially differing significantly from US tax regulations. Please consult with the Sprout team and your professional tax advisors to better understanding the tax implications of US vs offshore options.')}>
                                                        <a className="create-ques award_tip_position award_tip_position-right"></a>
                                                    </Tooltip>
                                                    <select id="draft_option_type" name="security_type"
                                                        value={this.state.data[listKey].security_type}
                                                        onBlur={this.editEaOptionDraft}
                                                        className="form-control select-container"
                                                        onChange={this.recordData}>
                                                        {this.state.optionGrantTypeList.map((value, key) => {
                                                            return (
                                                                <option key={key} value={value.value}>{value.text}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="form-group col-xs-6">
                                                    <label htmlFor="draft_award_category">{__('Award category')}</label>
                                                    <a id="stock-quespop" className="create-ques award_tip_position">
                                                        <p className="captable-tiptext tiptext-top138">{__('Award category is an optional field that can be use to further organize and categorize option awards. Set award categories from the')}<a onClick={this.goTemplates}>{__('Award category page.')}</a></p>
                                                    </a>
                                                    <select id="draft_award_category" name="award_category"
                                                        value={this.state.data[listKey].award_category}
                                                        onBlur={this.editEaOptionDraft}
                                                        className="form-control select-container"
                                                        onChange={this.recordData}>
                                                        <option value="">{__('No Category')}</option>
                                                        {this.state.award_category.map((value, key) => {

                                                            return (
                                                                <option key={key} value={value.name}>{value.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <a className="prefer-togglebtn" href="#option_value_li" data-toggle="collapse">{__('Transaction value')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                        <div className="collapse" id="option_value_li">
                                            <div className="row">
                                                <div className="form-group col-xs-6">
                                                    <label htmlFor="draft_option_quantity">{__('Issued quantity')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                    <input id="draft_option_quantity" name="quantity"
                                                        onBlur={this.resetDataFormat} onFocus={this.setDataFormat} onChange={this.recordNumber}
                                                        value={I(this.state.data[listKey].quantity, '')}
                                                        className="form-control" />
                                                </div>
                                                <div className="form-group col-xs-6">
                                                    <label htmlFor="draft_option_exercise_price">{__('Exercise price')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                    <div>
                                                        <div className="input-group"><span className="input-group-addon" id="exercise-price-addon">{security.companyInfo.currency}</span>
                                                            <input type="text" id="draft_option_exercise_price" aria-describedby="exercise-price-addon"
                                                                onBlur={this.resetDataFormat} onFocus={this.setDataFormat} name="exercise_price"
                                                                value={this.state.data[listKey].exercise_price}
                                                                onChange={this.recordNumber}
                                                                className="form-control" />                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <a className="prefer-togglebtn" href="#option_holder_li" data-toggle="collapse">{__('Option holder')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                        <div className="collapse" id="option_holder_li">
                                            <form className="form-draftflextwo">
                                                <div className="row draft-checked">
                                                    {sprintf(__('Add new stakeholders on the %s page. This page only selects existing stakeholders.'), <a onClick={this.bindStakeholders}>{__('All stakeholders')}</a>)}
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-xs-6 no-paddingleft">
                                                        <label htmlFor="draft_option_name">{__('Name')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                        <SelectStakeholder value={this.state.data[listKey].name} add={false} onBlur={this.editEaOptionDraft} onChange={this.handleSelectChange} />
                                                    </div>
                                                    <div className="form-group col-xs-6 no-paddingright">
                                                        <label htmlFor="draft_option_email">{__('Email')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                        <SelectStakeholder value={this.state.data[listKey].email} add={false} isEmail={true} onBlur={this.editEaOptionDraft} onChange={this.handleSelectChange} />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </li>
                                    <li>
                                        <a className="prefer-togglebtn" href="#option_board_content_li"
                                            data-toggle="collapse">{__('Board consent')}<span
                                                className="triangle triangle-down prefer-triangle"></span></a>
                                        <div className="collapse" id="option_board_content_li">
                                            <form>
                                                <div className="row">
                                                    <div className="form-group col-xs-6">
                                                        <label htmlFor="option_board_approval_status">{__('Board approval status')}</label>
                                                        <select id="option_board_approval_status"
                                                            onBlur={this.editEaOptionDraft}
                                                            value={this.state.data[listKey].board_approval_status}
                                                            name="board_approval_status" onChange={this.recordData}
                                                            className="form-control select-container">
                                                            {this.state.boardApprovalStatus.map((value, key) => {
                                                                return (
                                                                    <option key={key} value={value}>{value}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-xs-6 ">
                                                        <label htmlFor="option_board_approval_date">{__('Board approval date')}{requiredTip}</label>
                                                        <DateSelector
                                                            disabled={inputStatus}
                                                            onChange={this.handleDraftOptionDateChange.bind(this, 'board_approval_date', 'board_approval_date_moment')}
                                                            value={I(data[listKey]['board_approval_date_moment'], I(data[listKey]['board_approval_date'], '') ? moment(data[listKey]['board_approval_date'], dateFormat) : '')}
                                                            inputReadOnly={false}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-xs-6 ">
                                                        <label htmlFor="option_grant_date">{__('Grant date')}{requiredTip}</label>
                                                        <DateSelector
                                                            disabled={inputStatus}
                                                            onChange={this.handleDraftOptionDateChange.bind(this, 'grant_date', 'grant_date_moment')}
                                                            value={I(data[listKey]['grant_date_moment'], I(data[listKey]['grant_date'], '') ? moment(data[listKey]['grant_date'], dateFormat) : '')}
                                                            inputReadOnly={false}
                                                        />
                                                    </div>
                                                    <div className="form-group col-xs-6 ">
                                                        <label htmlFor="option_expiration_date">{__('Expiration date')}</label>
                                                        <DateSelector
                                                            disabled={inputStatus}
                                                            onChange={this.handleDraftOptionDateChange.bind(this, 'expiration_date', 'expiration_date_moment')}
                                                            value={I(data[listKey]['expiration_date_moment'], I(data[listKey]['expiration_date'], '') ? moment(data[listKey]['expiration_date'], dateFormat) : '')}
                                                            inputReadOnly={false}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </li>
                                    <li>
                                        <a className="prefer-togglebtn" href="#option_vesting_schedule_li"
                                            data-toggle="collapse">{__('Vesting schedule')}<span
                                                className="triangle triangle-down prefer-triangle"></span></a>
                                        <div className="collapse" id="option_vesting_schedule_li">
                                            <form>
                                                <div className="row">
                                                    <div className="form-group col-xs-6 ">
                                                        <label htmlFor="draft_schedule">{__('Schedule')}</label>
                                                        <select id="draft_schedule"
                                                            className="form-control select-container"
                                                            onBlur={this.editEaOptionDraft}
                                                            value={this.state.data[listKey].vesting_schedule_id}
                                                            name="vesting_schedule_id" onChange={this.recordData}>
                                                            <option value="">{__('No schedule')}</option>
                                                            {this.state.scheduleList.map((value, key) => {
                                                                return (
                                                                    <option id="option_schedule_name" key={key}
                                                                        value={value.id}>{value.schedule_name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    {this.state.data[listKey].vesting_schedule_id != "" && this.state.data[listKey].vesting_schedule_id != 0 &&
                                                        <div className="form-group col-xs-6 ">
                                                            <label htmlFor="option_vesting_start_date">{__('Vesting start date')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                            <DateSelector
                                                                onChange={this.handleDraftOptionDateChange.bind(this, 'vesting_start_date', 'vesting_start_date_moment')}
                                                                value={I(data[listKey]['vesting_start_date_moment'], I(data[listKey]['vesting_start_date'], '') ? moment(data[listKey]['vesting_start_date'], dateFormat) : '')}
                                                                inputReadOnly={false}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-xs-6">
                                                        <label htmlFor="draft_option_early_exercise">{__('Early exercise')}</label>
                                                        <select id="draft_option_early_exercise"
                                                            onBlur={this.editEaOptionDraft} name="early_exercise"
                                                            value={this.state.data[listKey].early_exercise}
                                                            onChange={this.recordData}
                                                            className="form-control select-container">
                                                            {this.state.earlyExerciseList.map((value, key) => {
                                                                return (
                                                                    <option key={key} value={value}>{value}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </li>
                                    <li>
                                        <a className="prefer-togglebtn" href="#option_post_li" data-toggle="collapse">
                                            {__('Post-termination exercise periods')}<span className="triangle triangle-down prefer-triangle"></span>
                                            <a id="stock-quespop" className="create-ques post_tip_position">
                                                <p className="captable-tiptext tiptext-top74">{__('By default, it will be the same as the Plan.')}</p>
                                            </a>
                                        </a>
                                        <div className="collapse" id="option_post_li">
                                            <div className="row marginBott10">
                                                <div className="col-xs-6 no-paddingright">
                                                    <div className="form-group row">
                                                        <label className="col-xs-10 add-label no-paddingleft">{__('Voluntary termination')}</label>
                                                        <div className="col-xs-2 no-paddingright">
                                                            <div className="checkbox text-muted equity-checkbox">
                                                                <input type="checkbox" className="send-box post_checkbox"
                                                                    checked={this.state.data[listKey].voluntary_termination.checked}
                                                                    id="option_voluntary_termination"
                                                                    onChange={this.changeCheckedStatus.bind(this, "voluntary_termination")} />
                                                                <label className="send-div"
                                                                    htmlFor="option_voluntary_termination"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ">
                                                        <div className="col-xs-5 no-paddingleft">
                                                            <label>{__('Count')}</label>
                                                            <input name="count"
                                                                value={this.state.data[listKey].voluntary_termination.count}
                                                                disabled onBlur={this.editEaOptionDraft}
                                                                onChange={this.recordNumberForPost.bind(this, "voluntary_termination")}
                                                                className="form-control voluntary_termination"
                                                                type="text" />
                                                        </div>
                                                        <div className="col-xs-7 no-paddingleft">
                                                            <label>{__('Period')}</label>
                                                            <select name="period"
                                                                value={this.state.data[listKey].voluntary_termination.period}
                                                                disabled onBlur={this.editEaOptionDraft}
                                                                onChange={this.recordDataForPost.bind(this, "voluntary_termination")}
                                                                className="form-control select-container voluntary_termination">
                                                                {this.state.periodList.map((value, key) => {
                                                                    return (
                                                                        <option key={key} value={value}>{value}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xs-6 no-paddingright">
                                                    <div className="form-group row">
                                                        <label className="col-xs-10 add-label no-paddingleft">{__('Involuntary termination')}</label>
                                                        <div className="col-xs-2 no-paddingright">
                                                            <div className="checkbox text-muted equity-checkbox">
                                                                <input type="checkbox" className="send-box post_checkbox"
                                                                    checked={this.state.data[listKey].involuntary_termination.checked}
                                                                    id="option_involuntary_termination"
                                                                    name="involuntary_termination"
                                                                    onChange={this.changeCheckedStatus.bind(this, "involuntary_termination")} />
                                                                <label className="send-div"
                                                                    htmlFor="option_involuntary_termination"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ">
                                                        <div className="col-xs-5 no-paddingleft">
                                                            <label>{__('Count')}</label>
                                                            <input name="count"
                                                                value={this.state.data[listKey].involuntary_termination.count}
                                                                disabled onBlur={this.editEaOptionDraft}
                                                                onChange={this.recordNumberForPost.bind(this, "involuntary_termination")}
                                                                className="form-control involuntary_termination"
                                                                type="text" />
                                                        </div>
                                                        <div className="col-xs-7 no-paddingleft">
                                                            <label>{__('Period')}</label>
                                                            <select name="period"
                                                                value={this.state.data[listKey].involuntary_termination.period}
                                                                disabled onBlur={this.editEaOptionDraft}
                                                                onChange={this.recordDataForPost.bind(this, "involuntary_termination")}
                                                                className="form-control select-container involuntary_termination">
                                                                {this.state.periodList.map((value, key) => {
                                                                    return (
                                                                        <option key={key} value={value}>{value}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row marginBott10">
                                                <div className="col-xs-6 no-paddingright">
                                                    <div className="form-group row">
                                                        <label className="col-xs-10 add-label no-paddingleft">{__('Termination with cause')}</label>
                                                        <div className="col-xs-2 no-paddingright">
                                                            <div className="checkbox text-muted equity-checkbox">
                                                                <input type="checkbox" className="send-box post_checkbox"
                                                                    checked={this.state.data[listKey].involuntary_termination_cause.checked}
                                                                    id="option_termination_with_cause"
                                                                    name="termination_with_cause"
                                                                    onChange={this.changeCheckedStatus.bind(this, "involuntary_termination_cause")} />
                                                                <label className="send-div"
                                                                    htmlFor="option_termination_with_cause"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ">
                                                        <div className="col-xs-5 no-paddingleft">
                                                            <label>{__('Count')}</label>
                                                            <input name="count"
                                                                value={this.state.data[listKey].involuntary_termination_cause.count}
                                                                disabled onBlur={this.editEaOptionDraft}
                                                                onChange={this.recordNumberForPost.bind(this, "involuntary_termination_cause")}
                                                                className="form-control involuntary_termination_cause"
                                                                type="text" />
                                                        </div>
                                                        <div className="col-xs-7 no-paddingleft">
                                                            <label>{__('Period')}</label>
                                                            <select name="period"
                                                                value={this.state.data[listKey].involuntary_termination_cause.period}
                                                                disabled onBlur={this.editEaOptionDraft}
                                                                onChange={this.recordDataForPost.bind(this, "involuntary_termination_cause")}
                                                                className="form-control select-container involuntary_termination_cause">
                                                                {this.state.periodList.map((value, key) => {
                                                                    return (
                                                                        <option key={key} value={value}>{value}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xs-6 no-paddingright">
                                                    <div className="form-group row">
                                                        <label className="col-xs-10 add-label no-paddingleft">{__('Death exercise')}</label>
                                                        <div className="col-xs-2 no-paddingright">
                                                            <div className="checkbox text-muted equity-checkbox">
                                                                <input type="checkbox" className="send-box post_checkbox"
                                                                    checked={this.state.data[listKey].death_exercise.checked}
                                                                    id="option_death_exercise_count"
                                                                    name="death_exercise"
                                                                    onChange={this.changeCheckedStatus.bind(this, "death_exercise")} />
                                                                <label className="send-div"
                                                                    htmlFor="option_death_exercise_count"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ">
                                                        <div className="col-xs-5 no-paddingleft">
                                                            <label>{__('Count')}</label>
                                                            <input name="count"
                                                                value={this.state.data[listKey].death_exercise.count}
                                                                disabled onBlur={this.editEaOptionDraft}
                                                                onChange={this.recordNumberForPost.bind(this, "death_exercise")}
                                                                className="form-control death_exercise"
                                                                type="text" />
                                                        </div>
                                                        <div className="col-xs-7 no-paddingleft">
                                                            <label>{__('Period')}</label>
                                                            <select name="period"
                                                                value={this.state.data[listKey].death_exercise.period}
                                                                disabled onBlur={this.editEaOptionDraft}
                                                                onChange={this.recordDataForPost.bind(this, "death_exercise")}
                                                                className="form-control select-container death_exercise">
                                                                {this.state.periodList.map((value, key) => {
                                                                    return (
                                                                        <option key={key} value={value}>{value}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row marginBott20">
                                                <div className="col-xs-6 no-paddingright">
                                                    <div className="form-group row">
                                                        <label className="col-xs-10 add-label no-paddingleft">{__('Disability exercise')}</label>
                                                        <div className="col-xs-2 no-paddingright">
                                                            <div className="checkbox text-muted equity-checkbox">
                                                                <input type="checkbox" className="send-box post_checkbox"
                                                                    checked={this.state.data[listKey].disability_exercise.checked}
                                                                    id="option_disability_exercise_count"
                                                                    name="disability_exercise"
                                                                    onChange={this.changeCheckedStatus.bind(this, "disability_exercise")} />
                                                                <label className="send-div"
                                                                    htmlFor="option_disability_exercise_count"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ">
                                                        <div className="col-xs-5 no-paddingleft">
                                                            <label>{__('Count')}</label>
                                                            <input name="count"
                                                                value={this.state.data[listKey].disability_exercise.count}
                                                                disabled onBlur={this.editEaOptionDraft}
                                                                onChange={this.recordNumberForPost.bind(this, "disability_exercise")}
                                                                className="form-control disability_exercise"
                                                                type="text" />
                                                        </div>
                                                        <div className="col-xs-7 no-paddingleft">
                                                            <label>{__('Period')}</label>
                                                            <select name="period"
                                                                value={this.state.data[listKey].disability_exercise.period}
                                                                disabled onBlur={this.editEaOptionDraft}
                                                                onChange={this.recordDataForPost.bind(this, "disability_exercise")}
                                                                className="form-control select-container disability_exercise">
                                                                {this.state.periodList.map((value, key) => {
                                                                    return (
                                                                        <option key={key} value={value}>{value}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xs-6 no-paddingright">
                                                    <div className="form-group row">
                                                        <label className="col-xs-10 add-label no-paddingleft">{__('Retirement exercise')}</label>
                                                        <div className="col-xs-2 no-paddingright">
                                                            <div className="checkbox text-muted equity-checkbox">
                                                                <input type="checkbox" className="send-box post_checkbox"
                                                                    checked={this.state.data[listKey].retirement_exercise.checked}
                                                                    id="option_retirement_exercise_count"
                                                                    name="retirement_exercise"
                                                                    onChange={this.changeCheckedStatus.bind(this, "retirement_exercise")} />
                                                                <label className="send-div"
                                                                    htmlFor="option_retirement_exercise_count"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ">
                                                        <div className="col-xs-5 no-paddingleft">
                                                            <label>{__('Count')}</label>
                                                            <input name="count"
                                                                value={this.state.data[listKey].retirement_exercise.count}
                                                                disabled onBlur={this.editEaOptionDraft}
                                                                onChange={this.recordNumberForPost.bind(this, "retirement_exercise")}
                                                                className="form-control retirement_exercise"
                                                                type="text" />
                                                        </div>
                                                        <div className="col-xs-7 no-paddingleft">
                                                            <label>{__('Period')}</label>
                                                            <select name="period"
                                                                value={this.state.data[listKey].retirement_exercise.period}
                                                                disabled onBlur={this.editEaOptionDraft}
                                                                onChange={this.recordDataForPost.bind(this, "retirement_exercise")}
                                                                className="form-control select-container retirement_exercise">
                                                                {this.state.periodList.map((value, key) => {
                                                                    return (
                                                                        <option key={key} value={value}>{value}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <a className="prefer-togglebtn" href="#document_note_li" data-toggle="collapse">{__('Document and notes')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                        <div className="collapse" id="document_note_li">
                                            <form>
                                                <div className="row">
                                                    <div className="form-group col-xs-6">
                                                        <label htmlFor="draft_option_doc">{__('Document sets')}</label>
                                                        <select id="draft_option_doc" name="documentset_id"
                                                            onBlur={this.editEaOptionDraft}
                                                            value={this.state.data[listKey].documentset_id}
                                                            onChange={this.recordData}
                                                            className="form-control select-container">
                                                            <option value="-3">Custom documents</option>
                                                            {this.state.documentSet.map((value, key) => {
                                                                return <Fragment>{!isESign && !I(value.is_docusign, '') || isESign ? <option key={key} value={value.id}> {value.is_docusign ? `${value.set_name} (Docusign)` : value.set_name}</option> : ''}</Fragment>
                                                            })}
                                                        </select>
                                                        { }
                                                    </div>
                                                    <div className="form-group col-xs-6 document_set">
                                                        <div className="manage_document" onClick={this.bindManageTemplates}>{__('Manage document set')}</div>
                                                    </div>
                                                    <div className="clear"></div>
                                                    {isDocusign && isESign ? this.docusignTemplate() : ''}
                                                    {!isDocusign &&
                                                        <div>
                                                            <div className="form-group row marginBott20">
                                                                <label className="col-xs-12 text-muted">{__('Grant Letter')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                                <div className="col-xs-4 no-paddingright">
                                                                    <div className={`select-btn convertiblenote-select-btn select-blue ${isDocusign ? 'draft-upload-disabled' : ''}`}>
                                                                        {__('Choose a file')}
                                                                        <label htmlFor="doc_select1" className="sr-only">File input</label>
                                                                        <input name="form_of_option_file" onChange={this.setFileName} ref="form_of_option_file" type="file" id="doc_select1" className="draft-option-file" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-xs-8 filename-box no-paddingright">
                                                                    {this.state.data[listKey].form_of_option_file.name == "" &&
                                                                        <p className="text-muted paddingLeft6">{__('No file selected')}</p>
                                                                    }
                                                                    {this.state.data[listKey].form_of_option_file.name != "" &&
                                                                        <p className="text-muted paddingLeft6 paddingRight18 "><Tooltip className="draft-option-file-tooltip" placement="topRight" title={this.state.data[listKey].form_of_option_file.name}>{this.state.data[listKey].form_of_option_file.name}</Tooltip>
                                                                            {/*{!isDocusign && <span onClick={this.fileDelete.bind(this, 'form_of_option_file', -1)} className="draft_trash form_of_option_file glyphicon glyphicon-trash"></span>}*/}
                                                                            {/*{!isDocusign && <DeleteOutlined className="draft_trash" onClick={this.fileDelete.bind(this, 'form_of_option_file', -1)} />}*/}
                                                                        </p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="form-group row marginBott20">
                                                                <label className="col-xs-12 text-muted">{__('Form of Exercise Agreement')}</label>
                                                                <div className="col-xs-4 no-paddingright">
                                                                    <div
                                                                        className={`select-btn convertiblenote-select-btn select-blue ${isDocusign ? 'draft-upload-disabled' : ''}`}>
                                                                        {__('Choose a file')}
                                                                        <label htmlFor="doc_select2" className="sr-only">File
                                                                            input</label>
                                                                        <input name="form_of_exercise_file"
                                                                            onChange={this.setFileName}
                                                                            ref="form_of_exercise_file" type="file"
                                                                            id="doc_select2" className="draft-option-file" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-xs-8 filename-box no-paddingright">
                                                                    {this.state.data[listKey].form_of_exercise_file.name == "" &&
                                                                        <p className="text-muted paddingLeft6">{__('No file selected')}</p>
                                                                    }
                                                                    {this.state.data[listKey].form_of_exercise_file.name != "" &&
                                                                        <p className="text-muted paddingLeft6 paddingRight18 "><Tooltip className="draft-option-file-tooltip" placement="topRight" title={this.state.data[listKey].form_of_exercise_file.name}>{this.state.data[listKey].form_of_exercise_file.name}</Tooltip>
                                                                            {/*{!isDocusign && <span onClick={this.fileDelete.bind(this, 'form_of_exercise_file', -1)} className="draft_trash form_of_exercise_file glyphicon glyphicon-trash"></span>}*/}
                                                                            {/*{!isDocusign && <DeleteOutlined className="draft_trash" onClick={this.fileDelete.bind(this, 'form_of_exercise_file', -1)} /> }*/}
                                                                        </p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="form-group row marginBott20">
                                                                <label className="col-xs-12 text-muted">{__('Equity incentive plan')}</label>
                                                                <div className="col-xs-4 no-paddingright">
                                                                    <div
                                                                        className={`select-btn convertiblenote-select-btn select-blue ${isDocusign ? 'draft-upload-disabled' : ''}`}>
                                                                        {__('Choose a file')}
                                                                        <label htmlFor="doc_select3" className="sr-only">File
                                                                            input</label>
                                                                        <input name="incentive_plan_file"
                                                                            onChange={this.setFileName}
                                                                            ref="incentive_plan_file" type="file"
                                                                            id="doc_select3" className="draft-option-file" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-xs-8 filename-box no-paddingright">
                                                                    {this.state.data[listKey].incentive_plan_file.name == "" &&
                                                                        <p className="text-muted paddingLeft6">{__('No file selected')}</p>
                                                                    }
                                                                    {this.state.data[listKey].incentive_plan_file.name != "" &&
                                                                        <p className="text-muted paddingLeft6 paddingRight18 "><Tooltip className="draft-option-file-tooltip" placement="topRight" title={this.state.data[listKey].incentive_plan_file.name}>{this.state.data[listKey].incentive_plan_file.name}</Tooltip>
                                                                            {/*{!isDocusign && <span onClick={this.fileDelete.bind(this, 'incentive_plan_file', -1)} className="draft_trash incentive_plan_file glyphicon glyphicon-trash"></span>}*/}
                                                                            {/*{!isDocusign && <DeleteOutlined className="draft_trash" onClick={this.fileDelete.bind(this, 'incentive_plan_file', -1)} />}*/}
                                                                        </p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            {isDocusign != 1 &&
                                                                <div className="form-group row marginBott20">
                                                                    <label className="text-muted col-xs-12">{__('Additional document')}</label>
                                                                    <div className="col-xs-4 no-paddingright">
                                                                        <div
                                                                            className={"select-btn convertiblenote-select-btn select-blue"}>
                                                                            {__('Choose a file')}
                                                                            <label htmlFor="doc_select4" className="sr-only">File input</label>
                                                                            <input name="additional_documents" onChange={this.setFileName} multiple={true} ref="additional_documents" type="file" id="doc_select4" className="draft-option-file" />
                                                                        </div>
                                                                    </div>
                                                                    {this.state.data[listKey].additional_documents.map((val, k) => {
                                                                        let isPush = "";
                                                                        let isRow = "";
                                                                        if (k != 0) {
                                                                            isPush = "col-xs-push-4";
                                                                            isRow = "row"
                                                                        }
                                                                        return (
                                                                            <div className={isRow} key={k}>
                                                                                <div className={"col-xs-8 filename-box " + isPush}>
                                                                                    {(this.state.data[listKey].additional_documents[k].name == "" || this.state.data[listKey].additional_documents[k].name == null) &&
                                                                                        <p className="text-muted paddingLeft6">{__('No file selected')}</p>
                                                                                    }
                                                                                    {this.state.data[listKey].additional_documents[k].name != "" && this.state.data[listKey].additional_documents[k].name != null &&
                                                                                        <p className="text-muted paddingLeft6 paddingRight18 "><Tooltip className="draft-option-file-tooltip" placement="topRight" title={this.state.data[listKey].additional_documents[k].name}>{this.state.data[listKey].additional_documents[k].name}</Tooltip>
                                                                                            {/*<span onClick={this.fileDelete.bind(this, 'additional_documents', k)} className="draft_trash additional_documents glyphicon glyphicon-trash"></span><DeleteOutlined className="draft_trash" onClick={this.fileDelete.bind(this, 'additional_documents', k)} />*/}
                                                                                        </p>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            }

                                                        </div>
                                                    }
                                                    <div className="form-group col-xs-12 ">
                                                        <label htmlFor="options_notes">{__('Notes')}</label>
                                                        <textarea id="options_notes" type="text"
                                                            onBlur={this.editEaOptionDraft} name="notes"
                                                            className="form-control minHeight100"
                                                            value={this.state.data[listKey].notes}
                                                            onChange={this.recordData}></textarea>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        }
                    </div>
                </div>
            </div>

        )
    }

}

export default DraftOptions;

