import React from "react";
import Utils, { I, formatCurrencyNumber, filterAmount} from "Utils";
import axios from "axios";
import { post } from 'srcPath/http';
import security from 'stores/security';
import {DatePicker, message} from "antd";
import moment from "moment";
import DateSelector from "../../../../components/DateSelector";
import { formatDate } from 'Utils/format/formatDate'

class CancelModal extends React.Component {
    constructor(props){
        super(props);
        this.state={
            data:{
                id:[],
                cancellation_date:"",
                cancellation_reason:"",
                realized_value:"",
                notes:"",
            },
            dateFormat:"DD/MM/YYYY",
            isCancelSingle:1,
            currency:"",
            cancellation_reasonList:[
                "Acquisition",
                "Buyback",
                "Clerical error",
                "Conversion",
                "Dissolved",
                "Donation",
                "Merger",
                "Relationship change",
                "Secondary",
                "Stock split",
                "Other",
                "Write off cancel",
                "Repricing cancel",
            ],
        }
    }
    changeCancelNum=()=>{
        this.setState({
            isCancelSingle:0
        })
    }

    setCancelDateChange = (key, moment_key, date) => {
        const { dateFormat } = this.state;
        let curData = this.state.data;
        curData[key] = date.format( dateFormat);
        curData[moment_key] = date;
        this.setState({
            data:curData
        });
    }

    getCancelIds=()=>{
        let RequsetData = this.state.data;
        if(this.state.isCancelSingle==0){
            for(let i=0;i<this.props.securityList.length;i++){
                if(this.props.securityList[i].checkbox_status==true){
                    RequsetData.id.push(this.props.securityList[i].id)
                }
            }
        }
        this.setState({
            data:RequsetData
        })
    }
    recordData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        })
    }
    recordNumber=(key,e)=>{
        let curData = this.state.data;
        curData[key] = Utils.getNumFormatWithTwoComma(e.target.value);
        this.setState({
            data:curData
        });
    }
    /**
     * input change通用事件
     * @param isDecimal 是否需要小数
     * @param e
     * 2 digit
     */
    inputChange2Digit = (isDecimal,e) => {
        const { target: element } = e;
        const { name: dataKey } = element;
        const filter = filterAmount(e, 9999999999999, isDecimal);
        let curData = this.state.data;
        curData[dataKey] = filter.value;
        this.setState({
            data: curData
        }, () => {
            element.selectionStart = element.selectionEnd = filter.cursorPositionX;
        });
    }
    resetActive=()=>{
        window.$("#cancelModal .tab-pane").removeClass("active");
        window.$("#cancel_step1").addClass("active");
    }
    multipleCancellation=()=>{
        const { pageData } = this.props;
        let mulRequsetData = this.state.data;
        let cancelApi = pageData.title == 'Token grant' ? 'cancelGrant' : 'cancelCoinWarrant';
        post(cancelApi,mulRequsetData).then((response)=> {
            if(response.data.code === 0){
                    window.location.reload();
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    singleCancellation=()=>{
        this.setState({
            loadingStatus:1
        })
        const { pageData } = this.props;
        let requestData = this.state.data;
        requestData.id = this.props.curSelectSecurity.id;
        let cancelApi = pageData.title == 'Token grant' ? 'cancelGrant' : 'cancelCoinWarrant'
        post(cancelApi, requestData).then((response) => {
            if (response.data.code === 0) {
                window.$("#cancelModal").modal("hide");
                this.resetActive();
                this.props.resetCancel(requestData.id, 'Canceled', requestData.cancellation_date);
                this.setState({
                    data: {
                        cancellation_date: "",
                        cancellation_reason: "",
                        realized_value: "",
                        notes: "",
                    }
                });
                message.success("success! ")
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        })
    }



    render(){
        let requiredTest = 0;
        if(this.state.data.cancellation_date==""||this.state.data.cancellation_reason==""){
            requiredTest = 1;
        }
        const { currency = '',curSelectSecurity } = this.props;
        const { data, dateFormat } = this.state;
        return(
            <div className="modal fade" id="cancelModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="modal-dialog tab-content">
                    <div className="modal-content content500 tab-pane active" id="cancel_step1">
                        <div className="modal-header box-header">
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true">×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Cancel')} {this.state.isCancelSingle==1 && (curSelectSecurity.custom_label_id ? curSelectSecurity.custom_label_id : curSelectSecurity.grant_name) }{this.state.isCancelSingle==0 &&this.props.pageData.title}</h4>
                        </div>
                        <div className="modal-body padding2026 reset_margin">
                            <div className="body-one">
                                <form className="form-horizontal">
                                    <div className="form-group add-items ">
                                        <label className="col-xs-5 add-label">{__('Cancellation date')}<br/>
                                            <i><small className="text-muted">- {__('Required')}</small></i>
                                        </label>
                                        <div id="first_name" className="col-xs-5 ">
                                            <DateSelector
                                                onChange = {this.setCancelDateChange.bind(this, 'cancellation_date', 'cancellation_date_moment')}
                                                value = {I(data['cancellation_date_moment'], I(data['cancellation_date'], '') ? moment(data['cancellation_date'], dateFormat) : '')}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group add-items">
                                        <label className="col-xs-5 add-label">{__('Cancellation reason')}<br/>
                                            <i><small className="text-muted">- {__('Required')}</small></i>
                                        </label>
                                        <div className="col-xs-5">
                                            <select value={this.state.data.cancellation_reason}  onChange={this.recordData}  name="cancellation_reason" className="form-control select-container">
                                                <option value="">{__('Choose a reason')}</option>
                                                {this.state.cancellation_reasonList.map((value,key)=>{
                                                    return(
                                                        <option key={key}>{value}</option>
                                                    )
                                                })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    {this.state.isCancelSingle == 1 &&
                                    <div className="form-group add-items">
                                        <label className="col-xs-5 add-label">{__('Realized value ')}<br/><i><small
                                            className="text-muted">- {__('Optional')}</small></i></label>
                                        <div className="col-xs-5">
                                            <div className="input-group">
                                                <span className="input-group-addon"
                                                      id="realized_value">{currency}</span>
                                                <input type="text" name="realized_value" onChange={this.inputChange2Digit.bind(this, true)} value={this.state.data.realized_value} className="form-control" aria-describedby="realized_value" placeholder="100.00"/>
                                            </div>
                                        </div>
                                        <div className="stock-tipbox">
                                            <p className="col-xs-7   text-muted">{__('Enter any cash value realized from this transaction.')}</p>
                                        </div>
                                    </div>
                                    }
                                    <div className="form-group add-items">
                                        <div className="row  profile-row">
                                            <label className="col-xs-5 add-label">{__('Notes')} <br /><i><small className="text-muted">- {__('Optional')}</small></i></label>
                                            <div className="col-xs-6">
                                                <textarea name="notes" value={this.state.data.notes} onChange={this.recordData} className="form-control text-area" type="text"/>
                                            </div>
                                            <p className="marginTop2 text-muted col-xs-7 col-xs-push-5">{__('Add any notes regarding the cancellation. They will be included in the certificate notes.')}</p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer box-footer invite-footer">
                            {requiredTest==1 &&
                            <button type="button" className="btn boxbtn-to send-disabledBtn ">{__('Next: Review Cancellation')}</button>
                            }
                            {requiredTest==0 &&
                            <button type="button" data-toggle="tab" data-target="#cancel_step2" onClick={this.getCancelIds} className="btn boxbtn-to bg-darkblue">{__('Next: Review Cancellation')}</button>
                            }
                        </div>
                    </div>
                    <div className="modal-content content500 tab-pane " id="cancel_step2">
                        <div className="modal-header box-header">
                            <a data-target="#cancel_step1" type="button" className="back-btn" aria-controls="repurchase-step1" role="tab" data-toggle="tab"></a>
                            <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.resetActive}>×</button>
                            <h4 className="modal-title" id="myModalLabel">{__('Cancel')} {this.state.isCancelSingle==1 && this.props.curSelectSecurity.custom_label_id}{this.state.isCancelSingle==0 &&this.props.pageData.title}</h4>
                        </div>
                        <div className="modal-body padding2026">
                            <div className="body-one">
                                <table className="table merge-table review-relationship-table no-marginBottom ">
                                    <tbody>
                                    {this.state.isCancelSingle == 0 &&
                                    <tr>
                                        <td>{__('Number of canceled')} {this.props.pageData.name}</td>
                                        <td>{this.state.data.id.length}</td>
                                    </tr>
                                    }
                                    {this.state.isCancelSingle == 1 &&
                                    <tr>
                                        <td>{this.props.pageData.title}</td>
                                        <td>{curSelectSecurity.custom_label_id ? curSelectSecurity.custom_label_id : curSelectSecurity.grant_name} to {curSelectSecurity.stakeholder_name ? curSelectSecurity.stakeholder_name : curSelectSecurity.name||'--'}</td>
                                    </tr>
                                    }
                                    <tr>
                                        <td>{__('Cancellation date')}</td>
                                        <td>{formatDate(data.cancellation_date)}</td>
                                    </tr>
                                    <tr>
                                        <td>{__('Cancellation reason')}</td>
                                        <td>{data.cancellation_reason}</td>
                                    </tr>
                                    <tr>
                                        <td>{__('Realized value')}</td>
                                        <td>{data.realized_value!="" ? formatCurrencyNumber(data.realized_value) : 0}</td>
                                    </tr>
                                    <tr>
                                        <td>{__('Notes')}</td>
                                        <td>{data.notes==""?"No notes":data.notes}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer box-footer invite-footer">
                            {this.state.isCancelSingle==0 &&
                            <button type="button" className="btn boxbtn-to bg-darkblue update-btn"  onClick={this.multipleCancellation}>{__('Cancel')}</button>
                            }
                            {this.state.isCancelSingle==1 &&
                            <button type="button" className="btn boxbtn-to bg-darkblue update-btn"  onClick={this.singleCancellation}>{__('Cancel')} {this.props.pageData.name}</button>
                            }
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
export default CancelModal;