import React from 'react';
import { formatNumber, I, tableSort } from "Utils";
import moment from 'moment';
import { DownOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import openCertificateModal from 'components/CertificateDetail';
import TransactionRender from 'B/components/TransactionHistory';
import { relationshipList } from "Utils/constant";
import {formatDate} from "Utils/format/formatDate";
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import { certificateStatus } from 'Utils/constant';

export function foundColumns() {
    const renderValue = data => data || '--';
    let relationshipFilter = [];
    relationshipList.forEach( (v, k) => {
        relationshipFilter.push({text: v, value: v});
    })
    return [
        {
            title: __('Security'),
            dataIndex: 'custom_label_id',
            className: 'firstAttr',
            align: 'left',
            width: '150',
        },
        {
            title: __('Transaction type'),
            dataIndex: 'transaction_type',
            align: 'right',
            width: '200',
            // render: (text, record) => (<TransactionRender transferSecurity={record.transferSecurity} value={record.transaction_type} />),
            render: value =>  certificateStatus[value]
        },
        {
            title: __('Quantity'),
            dataIndex: 'quantity',
            render: text =>  formatFractionalNumber(text),
            width: '150',
            align: 'right'
            
        },
        {
            title: __('Applied date'),
            dataIndex: 'applied_date',
            width: '150',
            render: (text, value) => {
                return <span>{ formatDate(value.applied_date) }</span>
            },
        },
        {
            title: __('Transaction date'),
            dataIndex: 'transaction_date',
            width: '200',
            render: (text, value) => {
                return <span>{ formatDate(value.issue_date) }</span>
            },
        },
        {
            title: __('Operator'),
            dataIndex: 'operator',
        },
        
    ];
}
