import React, { Component } from 'react';
import { RightOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import './style.scss';

export default function MessageCard(props) {
	const { color, title, icon, onIconClick, bannerHeight, children, stakeholderInfo } = props
	return <div className="message-card-root">
		<div className="message-card-header" style={{ background: color, height: bannerHeight || 40 }}>
			{/*<div className="message-title">{title}</div>*/}
			<div className="message-title">{title}{ stakeholderInfo && <Tooltip placement="right" title={stakeholderInfo} > <QuestionCircleOutlined /></Tooltip>}</div>
			{ icon && <RightOutlined className="glyphicon" onClick={() => { onIconClick && onIconClick() } } />}
		</div>
		<div className="message-content">
			{children}
		</div>
	</div>
}