import { I, calculator, clearNoNum, formatWithArray } from "Utils";
import moment from "moment";
import security from 'stores/security';
const { agile, division, mul, add } = calculator;

/**
 * Financing round 计算公式集合
 */
export default class Formula {

    constructor( data, financingData, formDataId, stakeholderData, active ) {
        const { fullDiluted, sendEa } = data;
        let { preMoneyValuation, postMoneyOptionPool, postMoneyOptionPoolShares, postMoneyOptionPoolType, totalNewInvestment, closingDate, cnData, advancedTermsId } = financingData?.[formDataId] || {};
        //估值
        this.preMoneyValuation = this.__clearNumber(preMoneyValuation);
        //新轮融资金额
        this.totalNewInvestment = this.__clearNumber(totalNewInvestment);
        //期权池
        this.postMoneyOptionPool = division(postMoneyOptionPool, 100);
        this.postMoneyOptionPoolShares = this.__clearNumber(postMoneyOptionPoolShares);
        this.postMoneyOptionPoolType = postMoneyOptionPoolType;
        this.financingData = financingData;
        this.fullDiluted = fullDiluted;
        this.advancedTermsId = advancedTermsId;
        this.stakeholderData = stakeholderData;
        this.active = active;
        this.cnData = cnData;
        this.closingDate = closingDate;
        this.formDataId = formDataId;
        this.sendEa = sendEa;
    }

    /**
     * 计算 CN price per share
     * @param newPricePerShare
     * @param conversionDiscount
     * @param valuationCap
     * @returns {*}
     */
    getCnPricePerShare = ({ newPricePerShare, conversionDiscount, valuationCap }) => {
        const cnValCapRes = this.__checkCnValCapGreaterPreMoney(valuationCap, this.preMoneyValuation);
        /**
         * 公司估值 < 资本估值
         * newPricePerShare * (100 - discount) / 100
         * 如果 公司估值 > 资本估值
         * Val.Cap / fullDiluted
         */
        return cnValCapRes ? agile(`${newPricePerShare} * (100 - ${I(conversionDiscount, 0)}) / 100`) : division(valuationCap, this.fullDiluted);
    }

    /**
     * 计算CN share
     * @param principalInterest
     * @param conversion_discount
     * @param newPricePerShare
     */
    getCnShare = ( {principalInterest, conversion_discount, newPricePerShare, valuation_cap, principal, interest_rate: interestRate, issue_date:issueDate} ) => {
        const cnPricePerShare = this.getCnPricePerShare( {
            newPricePerShare, conversionDiscount:I(conversion_discount, 0), valuationCap:I(valuation_cap, 0)
        });
        principalInterest = this.getPrincipalInterest(principal, interestRate, this.closingDate, issueDate);
        return agile(`${I(principalInterest, 0)} / ${cnPricePerShare}`);
    }

    /**
     * 计算新轮融资每股价格 newPricePerShare
     * 1: post financing, post money, 2: post financing, pre money, 3: pre financing, pre money, 4: pre financing, post money
     * @param newTotal
     */
    getNewPricePerShare = (value = null) => {
        let calculatorRes = 0;
        switch (this.advancedTermsId){
            case 1:
                calculatorRes = agile(`${this.preMoneyValuation} / ${this.fullDiluted}`);
                // console.log('新轮融资每股价格（pricePerShare）：', `${this.preMoneyValuation} / ${this.fullDiluted},`, calculatorRes);
                break;
            case 2:
                //计算stakeholder总shares
                const principalTotal = this.__quantityTotal(this.stakeholderData);
                if(this.postMoneyOptionPoolType === 1){
                    calculatorRes = agile(`${this.preMoneyValuation} / ( ${principalTotal} + ( ${this.postMoneyOptionPool} * ${value.newTotal} ) )`);
                }else{
                    calculatorRes = agile(` ${this.preMoneyValuation} / ( ${principalTotal} + ${this.postMoneyOptionPoolShares} ) `);
                }
                break;
            case 3:
                calculatorRes = agile(`(${this.preMoneyValuation} + ${this.totalNewInvestment}) / ${value.newTotal}`);
                // console.log('新轮融资每股价格（pricePerShare）：', `(${this.preMoneyValuation} + ${this.totalNewInvestment}) / ${value.newTotal},`, calculatorRes);
                break;
            case 4:
                //计算cn本金加利息(没有discount)
                const { interestNoDiscountTotal } = this.__cnDataPrincipalInterestTotal(this.cnData, this.closingDate, this.formDataId);
                // console.log('cn本金加利息(没有discount)：', interestNoDiscountTotal);
                calculatorRes = agile(`( ${this.preMoneyValuation} - ${interestNoDiscountTotal} ) / ${this.fullDiluted}`);
                // console.log('新轮融资每股价格（pricePerShare）：', `( ${this.preMoneyValuation} - ${interestNoDiscountTotal} ) / ${this.fullDiluted},`, calculatorRes);
                break;
            default:
        }
        // calculatorRes = this.__clearNumber(this.__getNumber(calculatorRes, 4, false, true));
        return calculatorRes;
    }

    /**
     * 计算 Total dilution（头部右上角统计）
     * @param value
     * @returns {number}
     */
    getTotalDilution = (value) => {
        return Math.abs(agile(`(${value.newTotal} - ${this.fullDiluted}) / ${value.newTotal} * 100`));
    }

    /**
     * 计算 SAFE/note（头部右上角统计）
     * @param value
     * @returns {*}
     */
    getSafeNote = (value) => {
        //SAFE/ Note = CN转化的股份/ Post-money的全部股份
        return agile(`${value.sharesAllTotal} / ${value.newTotal} * 100`);
    }

    /**
     * 计算 New money（头部右上角统计）
     * @param value
     * @returns {*}
     */
    getNewMoney = (value) => {
        return agile(`${this.totalNewInvestment} / ${value.newPricePerShare} / ${value.newTotal} * 100`);
    }

    /**
     * 计算融资后估值 Post-money valuation（头部右上角统计）
     * @param value
     * @returns {*}
     */
    getPostMoneyValuation = (value) => {
        const idArray = [1,2];
        let optionPoolShares = this.postMoneyOptionPoolType === 1 ? this.getOptionPoolShares(value) : this.postMoneyOptionPoolShares;
        optionPoolShares = this.__clearNumber(optionPoolShares, 2);
        let optionPoolPercentage = this.postMoneyOptionPoolType === 1 ? agile(` ${this.postMoneyOptionPool} * 100 `) : this.getOptionPoolPercentage(value);
        optionPoolPercentage = optionPoolPercentage = this.__clearNumber(this.__getNumber(optionPoolPercentage, 2));
        // return idArray.indexOf(parseInt(this.advancedTermsId)) !== -1 ? agile(`${this.totalNewInvestment} / ( ${this.totalNewInvestment} / ${value.newPricePerShare} / ${value.newTotal} )`) : agile(`${this.totalNewInvestment} + ${this.preMoneyValuation} `);
        return idArray.indexOf(parseInt(this.advancedTermsId)) !== -1 ? agile(`( ${optionPoolShares} * ${value.newPricePerShare} ) / ${optionPoolPercentage} `) : agile(`${this.totalNewInvestment} + ${this.preMoneyValuation} `);
    }

    /**
     * 计算期权池总股数
     * @param value
     * @returns {*}
     */
    getOptionPoolShares = (value) => {
        return agile(`${this.postMoneyOptionPool} * ${value.newTotal}`);
    }

    /**
     * 计算option pool 占比
     * @param value
     * @returns {*}
     */
    getOptionPoolPercentage = (value) => {
        return agile(` ( ${this.postMoneyOptionPoolShares} / ${value.newTotal} ) * 100 `);
    }

    /**
     * 计算未分配的新轮投资的总股数
     * @param value
     * @returns {*}
     */
    getUnnamedNewRoundInvestorsShares = (value) => {
        const result = agile(`${value.unassignedNewInvestment} / ${value.newPricePerShare}`);
        return result;
    }

    /**
     * 计算 stakeholder、share class 每行数据总股数占比
     * @param value
     * @returns {*}
     */
    getRowSharePercentage = (value) => {
        return agile(`${value.rowShare} / ${value.newTotal} * 100`);
    }

    /**
     * 获取表单数据
     * @returns {*}
     */
    getFormKey = (key) => {
        return this[key];
    }

    /**
     * 计算新轮融资总股数
     * 1: post financing, post money, 2: post financing, pre money, 3: pre financing, pre money, 4: pre financing, post money
     * @param value
     * @returns {*}
     */
    getNewTotal = (value) => {
        let result;
        //计算stakeholder总shares
        // console.log('----------------------------------新轮融资总股数-start---------------------------------');
        const principalTotal = this.__quantityTotal(this.stakeholderData);
        // console.log('stakeholder总shares', principalTotal);

        const { interestTotal, cnValCapShare } = this.__cnDataPrincipalInterestTotal(this.cnData, this.closingDate, this.formDataId);
        // console.log('CN本金加利息（interestTotal）：', interestTotal);
        // console.log('CN本金加利息（cnValCapShare）：', cnValCapShare);
        if(this.advancedTermsId == 1){
            const newPricePerShare = this.getNewPricePerShare(null);
            const { interestSharesTotal } = this.__cnDataPrincipalInterestTotal(this.cnData, this.closingDate, this.formDataId, newPricePerShare);
            if(this.postMoneyOptionPoolType === 1){
                result = agile(`( ${principalTotal} + ${interestSharesTotal} + (${this.totalNewInvestment}/${newPricePerShare}) ) / ( 1 - ${this.postMoneyOptionPool})`);
                // console.log(`( ${principalTotal} + ${interestSharesTotal} + (${this.totalNewInvestment}/${newPricePerShare}) ) / ( 1 - ${this.postMoneyOptionPool})`);
            }else{
                result = agile(` ${principalTotal} + ${this.postMoneyOptionPoolShares} + ( ${this.totalNewInvestment} / ${newPricePerShare} ) + ${interestSharesTotal} `);
                // console.log(` ${principalTotal} + ${this.postMoneyOptionPoolShares} + ( ${this.totalNewInvestment} / ${newPricePerShare} ) + ${interestSharesTotal} `);
            }
        }else if(this.advancedTermsId == 2){
            if(this.postMoneyOptionPoolType === 1){
                result = agile(` ( ( ${this.preMoneyValuation} * ${principalTotal} ) + ( ${ cnValCapShare > 0 ? agile(`${cnValCapShare} * ${this.preMoneyValuation}`) : agile(`(${interestTotal} * ${principalTotal})`) } ) + ( ${this.totalNewInvestment} * ${principalTotal} ) ) / ( ( ( 1 - ${this.postMoneyOptionPool} ) * ${this.preMoneyValuation} ) - ( ${this.totalNewInvestment} * ${this.postMoneyOptionPool} ) - ( ${interestTotal} * ${this.postMoneyOptionPool} ) ) `);
                // console.log(` ( ( ${this.preMoneyValuation} * ${principalTotal} ) + ( ${ cnValCapShare > 0 ? agile(`${cnValCapShare} * ${this.preMoneyValuation}`) : agile(`(${interestTotal} * ${principalTotal})`) } ) + ( ${this.totalNewInvestment} * ${principalTotal} ) ) / ( ( ( 1 - ${this.postMoneyOptionPool} ) * ${this.preMoneyValuation} ) - ( ${this.totalNewInvestment} * ${this.postMoneyOptionPool} ) - ( ${interestTotal} * ${this.postMoneyOptionPool} ) ) `);
            }else{
                const newPricePerShare = this.getNewPricePerShare(null);
                const cnTotal = this.__cnDataPrincipalInterestTotal(this.cnData, this.closingDate, this.formDataId, newPricePerShare);
                result = agile(` ${principalTotal} + ${this.postMoneyOptionPoolShares} + ( ${this.totalNewInvestment} / ${newPricePerShare} ) + ${cnTotal.interestSharesTotal} `);
                // console.log(` ${principalTotal} + ${this.postMoneyOptionPoolShares} + ( ${this.totalNewInvestment} / ${newPricePerShare} ) + ${cnTotal.interestSharesTotal} `);
            }
        }else if(this.advancedTermsId == 3){
            const postMoneyValuation = agile(`${this.preMoneyValuation} + ${this.totalNewInvestment}`);
            const dataZ1ValCap = cnValCapShare > 0 ? agile(`${postMoneyValuation} * ${cnValCapShare}`) : 0;
            // option pool shares 和 % 为两种公式
            if(this.postMoneyOptionPoolType === 1){
                result = agile(`( ${principalTotal} * ${postMoneyValuation} + ${dataZ1ValCap} ) / ( ( 1 - ${this.postMoneyOptionPool} ) * ${postMoneyValuation} - ${interestTotal} - ${this.totalNewInvestment} )`);
                // console.log(result, this.formDataId, `( ${principalTotal} * ${postMoneyValuation} + ${dataZ1ValCap} ) / ( ( 1 - ${this.postMoneyOptionPool} ) * ${postMoneyValuation} - ${interestTotal} - ${this.totalNewInvestment} )`);
            }else{
                result = agile(`( ( ${postMoneyValuation} * ${principalTotal} ) + ( ${postMoneyValuation} * ${I(this.postMoneyOptionPoolShares, '0')} ) + ( ${dataZ1ValCap} ) ) / ( ${postMoneyValuation} - ${this.totalNewInvestment} - ${interestTotal} )`);
                // console.log(`( ( ${postMoneyValuation} * ${principalTotal} ) + ( ${postMoneyValuation} * ${I(this.postMoneyOptionPoolShares, '0')} ) + ( ${dataZ1ValCap} ) ) / ( ${postMoneyValuation} - ${this.totalNewInvestment} - ${interestTotal} )`);
            }
        }else if(this.advancedTermsId == 4){
            const newPricePerShare = this.getNewPricePerShare(null);
            const { interestSharesTotal } = this.__cnDataPrincipalInterestTotal(this.cnData, this.closingDate, this.formDataId, newPricePerShare);
            const newInvestmentShares = agile(`${this.totalNewInvestment} / ${newPricePerShare}`);
            if(this.postMoneyOptionPoolType === 1){
                result = agile(`( ${principalTotal} + ${this.active == 2 ? this.sendEa : 0} + ${interestSharesTotal} + ${newInvestmentShares} ) / ( 1 - ${this.postMoneyOptionPool} )`);
                // console.log(`( ${principalTotal} + ${this.active == 2 ? this.sendEa : 0} + ${interestSharesTotal} + ${newInvestmentShares} ) / ( 1 - ${this.postMoneyOptionPool} )`);
            }else{
                result = agile(` ${principalTotal} + ${interestSharesTotal} + ${newInvestmentShares} + ${this.postMoneyOptionPoolShares} `);
                // console.log(` ${principalTotal} + ${interestSharesTotal} + ${newInvestmentShares} + ${this.postMoneyOptionPoolShares} `);
            }
        }
        // console.log('----------------------------------新轮融资总股数-end---------------------------------');
        return result;
    }

    /**
     * 计算 Val.Cap PricePerShare
     * @param value
     * @returns {*}
     */
    getValCapPricePerShare = (value) => {
        // console.log('Val.Cap PricePerShare :', `${I(value.valuation_cap, 0)} / ${this.fullDiluted}`);
        return agile(`${I(value.valuation_cap, 0)} / ${this.fullDiluted}`);
    }

    /**
     * 提取公式
     * @param key
     * @param value
     */
    getFormula =(key, value) => {
        let calculatorRes;
        switch (key){
            //新轮每股价格
            case 'newPricePerShare':
                calculatorRes = this.getNewPricePerShare(value);
                break;
            //CN每股价值
            case 'cnPricePerShare':
                calculatorRes = this.getCnPricePerShare(value);
                break;
            case 'valCapPricePerShare':
                calculatorRes = this.getValCapPricePerShare(value);
                break;
            //cn股数计算
            case 'cnShares':
                calculatorRes = this.getCnShare(value);
                break;
            //模拟总股数
            case 'newTotal':
                calculatorRes = this.getNewTotal(value);
                break;
            case 'rowSharePercentage':
                calculatorRes = this.getRowSharePercentage(value);
                break;
            //未分配新一轮投资的总股数
            case 'unnamedNewRoundInvestorsShares':
                calculatorRes = this.getUnnamedNewRoundInvestorsShares(value);
                break;
            //期权池总股数
            case 'optionPoolShares':
                calculatorRes = this.getOptionPoolShares(value);
                break;
            //option pool 占比
            case 'optionPoolPercentage':
                calculatorRes = this.getOptionPoolPercentage(value);
                break;
            //融资后估值 Post-money valuation（头部右上角统计）
            case 'postMoneyValuation':
                calculatorRes = this.getPostMoneyValuation(value);
                break;
            //new money（头部右上角统计）
            case 'newMoney':
                calculatorRes = this.getNewMoney(value);
                break;
            //SAFE/note（头部右上角统计）
            case 'safeNote':
                calculatorRes = this.getSafeNote(value);
                break;
            //Total dilution（头部右上角统计）
            case 'totalDilution':
                calculatorRes = this.getTotalDilution(value);
                break;
            //获取用户填写数据 option pool
            case 'postMoneyOptionPool':
                calculatorRes = this.getFormKey(key);
                break;
            case 'postMoneyOptionPoolShares':
                calculatorRes = this.getFormKey(key);
                break;
            case 'postMoneyOptionPoolType':
                calculatorRes = this.getFormKey(key);
                break;
            default:
                calculatorRes = 0;
        }
        return I(calculatorRes, 0);
    }

    /**
     * 计算stakeholder总股数
     * @param stakeholderData
     * @returns {number}
     */
    __quantityTotal = (stakeholderData) => {
        const { add } = calculator;
        let quantityTotal = 0;
        stakeholderData && stakeholderData.forEach((v)=>{
            quantityTotal = add(quantityTotal, v.quantity);
        })
        const ea = this.active == 2 ? I(this.sendEa, 0) : 0;
        return agile(`${quantityTotal} + ${ea}`);
    }

    /**
     * 只保留数字
     * @param value
     * @private
     */
    __clearNumber = (value) => I(clearNoNum({value}).value, '0');

    /**
     * 本金+利息
     */
    getPrincipalInterest = (principal, interestRate, closingDate, issueDate) => {
        //利率
        interestRate = division(interestRate, 100);
        //closingDate
        const closingDateTime = closingDate && closingDate.unix();
        const issueTime = moment(issueDate, 'DD/MM/YYYY').unix();
        //利息
        let interest = parseInt(agile(`( ${I(closingDateTime, 0)} - ${issueTime} ) / 86400 / 365 * 12`));
        interest = I(interest, 0);
        principal = I(principal, 0);
        const cnPrincipalInterest = agile(`${principal} + ( ${principal} * ${interestRate} / 12 * ${interest})`);
        return cnPrincipalInterest;
    }

    /**
     * 计算CN本金加利息
     * Post-financing dilution、Pre-money
     * @param data
     * @param closingDate
     * @param key
     * @param newPricePerShare
     * @returns {{interestTotal: number, interestSharesTotal: number, interestNoDiscountTotal: number}}
     * @private
     */
    __cnDataPrincipalInterestTotal = (data, closingDate, key, newPricePerShare= 0) => {
        const { selectedRowKeys } = this.financingData[key];
        const { add, agile, division, mul } = calculator;
        let interestTotal = 0;
        let interestSharesTotal = 0;
        let interestNoDiscountTotal = 0;
        let cnValCapShare = 0;
        for(let i in data){
            // console.log('--------------------本金+利息 start--------------------');
            for(let j in data[i]){
                //本金 principal， 发行日期 issueDate, 折扣 discount
                let { principal, issue_date:issueDate, conversion_discount:discount, interest_rate, valuation_cap } = data[i][j];
                principal = I(principal, 0);
                discount = I(discount, 0);
                interest_rate = I(interest_rate, 0);
                const valuationCap = I(valuation_cap, 0);
                if(selectedRowKeys[i].indexOf(data[i][j].custom_label_id) !== -1){
                    //本金+利息
                    let cnPrincipalInterest = this.getPrincipalInterest(principal, interest_rate, closingDate, issueDate);
                    //验证valuation_cap 是否大于 pre-money， 如果小于使用 valuation_cap 计算
                    const cnValCapRes = this.__checkCnValCapGreaterPreMoney(valuation_cap, this.preMoneyValuation);
                    //Val.Cap pricePerShare
                    const valCapPricePerShare = this.getFormula('valCapPricePerShare', {valuation_cap});
                    //只有在post financing, post money时才会使用
                    let cnSingleShares = 0;
                    if(newPricePerShare){
                        //本金加利息股份总数
                        if(cnValCapRes){
                            let cnPricePerShare = this.getCnPricePerShare({newPricePerShare, valuationCap, conversionDiscount:discount});
                            // console.log('CN PPS: ', cnPricePerShare);
                            cnSingleShares = agile(`${cnPrincipalInterest} / ${cnPricePerShare}`);
                        }else{
                            cnSingleShares = agile(`${cnPrincipalInterest} / ${valCapPricePerShare}`);
                        }
                        // console.log(cnSingleShares);
                        interestSharesTotal = agile(`${interestSharesTotal} + ${cnSingleShares}`);
                    }
                    interestNoDiscountTotal = add(interestNoDiscountTotal, cnPrincipalInterest);
                    //val.cap < pre money 要使用cn shares计算
                    if(cnValCapRes){
                        cnPrincipalInterest = agile(`${cnPrincipalInterest} / ( 1 - (${discount} / 100) )`);
                        // console.log(`${cnPrincipalInterest} / ( 1 - (${discount} / 100) )`);
                    }else{
                        cnValCapShare = add(cnValCapShare, agile(`(${cnPrincipalInterest} / ${valCapPricePerShare})`));
                        cnPrincipalInterest = 0;
                    }
                    //cnPrincipalInterest = 本金+利息
                    interestTotal = add(interestTotal, cnPrincipalInterest);
                }
            }
            // console.log('--------------------本金+利息 end--------------------');
        }
        return { interestTotal, interestSharesTotal, interestNoDiscountTotal, cnValCapShare: Number(cnValCapShare) };
    }

    /**
     * 验证Val.Cap 是否大于 PreMoney
     * valuationCap: 0 => true
     * valuationCap > preMoneyValuation ? true : false
     * @param valuationCap
     * @param preMoneyValuation
     * @returns {boolean}
     * @private
     */
    __checkCnValCapGreaterPreMoney = (valuationCap, preMoneyValuation) => {
        // console.log('-------------------------------------------------------------');
        // console.log(valuationCap, preMoneyValuation, parseFloat(I(valuationCap, 0)) > parseFloat(preMoneyValuation));
        // if(!I(valuationCap, '')){
        //     return true
        // }
        if(!I(valuationCap, '')){
            return true;
        }
        return parseFloat(I(valuationCap, 0)) > parseFloat(preMoneyValuation);
    }

    /**
     * scenario modeling 小数格式化
     * @param value 内容
     * @param decimal 小数位 最多5位
     * @param isPrice 是否是金额
     * @param isShare 是否是股数
     * @returns {string|*}
     * @private
     */
    __getNumber = (value, decimal, isPrice = false, isShare = false) => {
        value = I(value, '0').toString();
        const { currency, fractional_digit, share_digit } = security.companyInfo;
        decimal = decimal || parseInt(isShare ? share_digit : fractional_digit);
        let {0:leftValue, 1:rightValue} = value.split('.');
        const right = (I(rightValue, '').padEnd(decimal, '0')).substring(0, decimal);
        const returnValue = `${leftValue}.${right}`;
        if( I(rightValue, '').toString().substring(decimal, decimal+1) === '9' && isPrice === false){
            const returnNumber = calculator.agile(`${returnValue} + 0.${'1'.padStart(decimal, '0')}`);
            return formatWithArray(returnNumber.padEnd(leftValue.toString().length+decimal+1, '0'));
        }
        return `${isPrice ? `${currency} ` : ''}${formatWithArray(returnValue)}`;
    };
}
