import React from 'react';
import axios from "axios";
import DeleteDoc from "../../components/DeleteDoc";
import Utils, { I, formatNumber, formatPercent } from "Utils";
import { cancellationReason } from "Utils/constant";
import moment from "moment";
import {DatePicker, message} from "antd";
import { post } from 'srcPath/http';
import DateSelector from "../../../../../components/DateSelector";
import {formatDate} from "../../../../Utils/format/formatDate";
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import security from 'stores/security';
import {format} from "echarts/src/export";
import {DeleteOutlined, EditOutlined, FolderOpenOutlined, DatabaseOutlined, DiffOutlined, ClockCircleOutlined, CalendarOutlined } from "@ant-design/icons";

class ModifyConvertibleModal extends React.Component{
    constructor(props){
        super(props);
        this.state={
            dateFormat:"DD/MM/YYYY",
            is_remove:0,
            is_show_delete_modal:0,
            isShowErrorTip:0,
            isAvailable:-1,
            document_action_type:"",
            documentActionArr : {'add_document':1,'remove_document':1},
            uploadProgress:0,
            uploadingStatus:0,
            initData:
                {
                    id: "2",
                    init_email: "78420344@qq.com",
                    combined_id: "212",
                    type: "cs",
                    name: "cn name",
                    email: "aaa",
                    issue_date: "11/02/2021",
                    board_approval_date: null,
                    cancellation_date: null,
                    cancellation_reason: null,
                    transfer_date:"",
                    convert_date:"",
                    currency: null,
                    maturity_date:"",
                    principal:"1",
                    valuation_cap:"",
                    conversion_discount:"",
                    conversion_trigger:"",
                    change_in_control_percent:"",
                    interest_rate:"",
                    interest_accrual_end_date:"",
                    day_count_convention:"",
                    interest_accrual_period:"",
                    interest_start_date:"",
                    interest_compounding_period:"",
                    interest_payout:"",
                    purchase_agreement:{name:"",real_name:""},
                    form_of_convertible:{name:"",real_name:""},
                    additional_documents:{name:"",real_name:""},
                    add_document:"",
                    remove_document:"",
                    documents: null,
                    notes: null,
                    other_consideration: null,
                    custom_label_id: "",
                    status: "Outstanding",
                    create_timestamp: "2020-03-09 12:09:01",
                    transaction_value: 44446,
                    checkbox_status: "unchecked",
                    sign_status: "holderSignature",

                },
            checkboxStatus:{
                // name:
                //     {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"name",fieldName:"Name"},
                // email:
                //     {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"email",fieldName:"Email"},
                custom_label_id:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"custom_label_id",fieldName:__("Custom label")},
                issue_date:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"issue_date",fieldName:__("Issue Date")},
                // currency:
                //     {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"currency",fieldName:"Currency"},
                board_approval_date:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"board_approval_date",fieldName:__("Board approval date")},
                cancellation_date:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"cancellation_date",fieldName:__("Canceled date")},
                cancellation_reason:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"cancellation_reason",fieldName:__("Canceled reason")},
                transfer_date:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"transfer_date",fieldName:__("Transfer date")},
                convert_date:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"convert_date",fieldName:__("Convert date")},
                maturity_date:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"maturity_date",fieldName:__("Maturity date")},
                principal:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"principal",fieldName:__("Principal")},
                valuation_cap:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"valuation_cap",fieldName:__("Valuation cap")},
                conversion_discount:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"conversion_discount",fieldName:__("Conversion discount")},
                conversion_trigger:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"conversion_trigger",fieldName:__("Conversion trigger")},
                change_in_control_percent:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"change_in_control_percent",fieldName:__("Change in control percent")},
                interest_rate:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"interest_rate",fieldName:__("Interest rate")},
                interest_accrual_end_date:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"interest_accrual_end_date",fieldName:__("Interest accrual end date")},
                day_count_convention:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"day_count_convention",fieldName:__("Day count convention")},
                interest_accrual_period:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"interest_accrual_period",fieldName:__("Interest accrual period")},
                interest_start_date:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"interest_start_date",fieldName:__("Interest start date")},
                interest_compounding_period:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"interest_compounding_period",fieldName:__("Interest compounding period")},
                interest_payout:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"interest_payout",fieldName:__("Interest payout")},
                add_document:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"add_document",fieldName:__("Add more additional documents")},
                purchase_agreement:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"purchase_agreement",fieldName:__("Purchase agreement")},
                form_of_convertible:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"form_of_convertible",fieldName:__("Form of convertible")},
                remove_document:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"remove_document",fieldName:__("Remove all additional documents")},
                notes:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"notes",fieldName:__("Notes")},
                other_consideration:
                    {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"other_consideration",fieldName:__("Other consideration")},
                // legend:
                //     {checked:false, bg:"modify-darkInput", disabled:"disabled",jsonKey:"legend",fieldName:"Legend"},
            },
            currencyList:['USD','HKD','SGD',],
            requiredKey:[
                "custom_label_id",
                "issue_date",
                "board_approval_date",
                "cancellation_date",
                "cancellation_reason",
                "maturity_date",
                "valuation_cap",
                "conversion_discount",
                "conversion_trigger",
                "change_in_control_percent",
                "interest_rate",
                "interest_accrual_end_date",
                "day_count_convention",
                "interest_accrual_period",
                "interest_start_date",
                "interest_compounding_period",
                "interest_payout",
                "purchase_agreement",
                "form_of_convertible",
                "add_document",
                "notes",
    ],
            optionalKey:[
                "principal",
                "other_consideration",
                "transfer_date",
                "convert_date",
            ],
            errorKeyArr:[],
            modifyReason:[
                "Clerical error",
                "Amendment"
            ],
            data:{
                reason:"",
                note:"",
                basicTemplateData:
                    {
                        custom_label_id:"",
                        issue_date:"",
                        board_approval_date:"",
                        cancellation_date:"",
                        cancellation_reason:"",
                        converted_from:"",
                        transfer_date:"",
                        convert_date:"",
                        add_document:{name:"",file_obj:""},
                        document:"No file selected",
                        purchase_agreement:{name:"",file_obj:""},
                        form_of_convertible:{name:"",file_obj:""},
                        additional_documents:{name:"",file_obj:""},
                        remove_document:"",
                        notes:"",
                        legend:"",
                        maturity_date:"",
                        principal:"",
                        valuation_cap:"",
                        conversion_discount:"",
                        conversion_trigger:"",
                        change_in_control_percent:"",
                        interest_rate:"",
                        interest_accrual_end_date:"",
                        day_count_convention:"",
                        interest_accrual_period:"",
                        interest_start_date:"",
                        interest_compounding_period:"",
                        interest_payout:"",
                        other_consideration:"",
                    },
                changedKey:[],
            },
            dayCountList:[
              "30/360",
              "Actual/365",
              "Actual/360",
            ],
            interestAccrualPeriodList:[
                "Daily",
                "Weekly",
                "Monthly",
                "Monthly(first day of month)",
                "Semi-annually",
                "Annually",
                "Quarterly(calendar year)",
                "Semi-annually(calendar year)",
                "Annually(calendar year)",
            ],
            interestCompoundingPeriodList:[
                "Simple interest",
                "Daily",
                "Monthly",
                "Semi-annually",
                "Annually",
                "Quarterly(calendar year)",
            ],
            interestPayoutList:[
                "Deferred",
                "Cash",
            ],

        }
    }
    recordData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        });

    }
    recordCurData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData.basicTemplateData[dataKey] = dataVal;
        this.setState({
            data:curData
        });

    }
    recordNumData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        if(dataKey=="conversion_discount" ||dataKey=="valuation_cap" ||dataKey=="change_in_control_percent" ||dataKey=="interest_rate" ){
            curData.basicTemplateData[dataKey] =  Utils.check2DecimalNumInputWithoutComma(dataVal);
        }else{
            if(dataVal != 0){
                curData.basicTemplateData[dataKey] =  Utils.checkIntInputWithoutComma(dataVal);
            }else{
                curData.basicTemplateData[dataKey] = 0;
            }
        }
        this.setState({
            data:curData
        });
    }
    formatNumData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        if(dataKey=="conversion_discount" ||dataKey=="valuation_cap" ||dataKey=="change_in_control_percent" ||dataKey=="interest_rate" ){
            curData.basicTemplateData[dataKey] = formatNumber(dataVal);
        }else{
            if(dataVal != 0){
                curData.basicTemplateData[dataKey] =  formatNumber(dataVal,null);
            }else{
                curData.basicTemplateData[dataKey] = 0;
            }
        }
        this.setState({
            data:curData
        });
    }
    focusNumData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        if(dataVal!=""){
            curData.basicTemplateData[dataKey] =  Utils.resetNumberWithCommas(dataVal)
        }
        this.setState({
            data:curData
        });
    }
    componentDidMount() {
        window.$('.share_class_datepicker').datepicker({
            maxViewMode: 3,
            todayBtn: "linked",
            todayHighlight: true,
            autoclose: true,
            format: "dd/mm/yyyy"
        }).on("changeDate", (e) => {
            let curData = this.state.data;
            let targetKey = e.target.name;
            curData.basicTemplateData[targetKey] = e.format();
            this.setState({
                data: curData
            });
        });
    }

    checkedStatus = (e)=>{
        let curChecked = this.state.checkboxStatus;
        let dataKey = e.target.name;
        curChecked[dataKey].checked = !curChecked[dataKey].checked;
        if(curChecked[dataKey].checked){
            curChecked[dataKey].bg="modify-whiteInput";
            curChecked[dataKey].disabled="";
        }else{
            curChecked[dataKey].bg="modify-darkInput"
            curChecked[dataKey].disabled="disabled";
        }
        let documentActionArr = this.state.documentActionArr;
        if(documentActionArr.hasOwnProperty(dataKey)){
            if(curChecked[dataKey].checked){
                this.setState({document_action_type:dataKey});
                for(var key in documentActionArr){
                    if(key !== dataKey){
                        curChecked[key].checked = false;
                        curChecked[key].bg="modify-darkInput"
                        curChecked[key].disabled="disabled";
                    }
                }
            }
        }
        this.setState({
            checkboxStatus:curChecked
        })
    }
    selectFile=(e)=>{
        let fileObj = e.target.files[0];
        let curList = this.state.data;
        let dataKey = e.target.name;
        Utils.uploadFile(fileObj).then((result)=>{
            curList.basicTemplateData[dataKey].name = fileObj.name;
            curList.basicTemplateData[dataKey].file_obj = result;
            this.setState({
                data:curList
            })
        })
    }
    resetAction=()=>{
        window.$("#modifyConvertibleModal .tab-pane").removeClass("active");
        window.$("#modifyReason").addClass("active");
    }

    submitAction=()=>{
        if(this.state.uploadingStatus ===1){
            return;
        }
        this.setState({
            uploadingStatus:1
        });
        if(Utils.isLocal()){
            this.props.setModifiedSecurity(this.state.initData.key,this.state.initData);
            window.$("#modifyConvertibleModal").modal("hide");
            window.$("#modifyConvertibleModal .tab-pane").removeClass("active");
            window.$("#modifyReason").addClass("active");
            return;
        }
        let requestData = {
            is_remove: this.state.is_remove,
            reason:this.state.data.reason,
            modify_note:this.state.data.modify_note,
            modify_id: this.state.initData.id
        };
        for(let key in this.state.checkboxStatus){
            if(this.state.checkboxStatus[key].checked){
                if(key =="interest_rate"||key =="conversion_discount"||key =="change_in_control_percent"){
                    requestData[key] = this.state.data.basicTemplateData[key];
                    // requestData[key] = this.state.data.basicTemplateData[key]/100;
                }else{
                    requestData[key] = this.state.data.basicTemplateData[key];
                }
            }
        }
        if(this.state.document_action_type==="add_document" && this.state.data.basicTemplateData.add_document.name!==__('No file selected')){
            //这里说明选择新增并且上传了文件
            requestData.document_action_type = 'add_document';
        }else if(this.state.document_action_type==="remove_document"){
            requestData.document_action_type = 'remove_document';
        }else{
            requestData.document_action_type = '';
        }

        axios.post('https://api.getsprout.co', {
            company_id:Utils.getLocalCompanyInfo().company_id,
            action:"modifyCnSecurity",
            data:requestData
        },{
            onUploadProgress:(progressEvent)=>{
                let complete = (progressEvent.loaded / progressEvent.total * 100 | 0);
                console.log('上传 ' + complete+'%') ;
                this.setState({
                    uploadProgress:complete
                });
                if(complete == 100){
                    this.setState({
                        isShowUploadProgress:0
                    })

                }
            }
        }).then((response)=> {
            if(response.data.code === 0){
                //这里需要将返回的数据覆盖到主页面
                // for(let key in this.state.checkboxStatus){
                //     if(this.state.checkboxStatus[key].checked){
                //         if(key === "add_document" ||key==="remove_document"){
                //             continue;
                //         }
                //         if(key =="interest_rate"||key =="conversion_discount"||key =="change_in_control_percent"){
                //             this.state.initData[key] = this.state.data.basicTemplateData[key];
                //             // this.state.initData[key] = this.state.data.basicTemplateData[key]/100;
                //         }else{
                //             this.state.initData[key] = this.state.data.basicTemplateData[key];
                //         }
                //         // this.state.initData[key] = this.state.data.basicTemplateData[key];
                //     }
                // }
                // let initData = this.state.initData;
                // initData.quantity = Utils.resetNumberWithCommas(initData.quantity);
                // if(response.data.data.hasOwnProperty("custom_label_id")){
                //     initData.custom_label_id = response.data.data.custom_label_id;
                // }
                // this.props.setModifiedSecurity(this.state.initData.key,initData);
                // this.setState({
                //     uploadProgress:0,
                //     uploadingStatus:0
                // });
                // window.$("#modifyConvertibleModal").modal("hide");
                // this.resetAction();
                // let curData = this.state.data;
                // curData.reason="";
                // curData.modify_note="";
                // curData.modify_id="";
                // for(let key in this.state.checkboxStatus){
                //     if(this.state.checkboxStatus[key].checked){
                //         if(key=="purchase_agreement"||key=="form_of_convertible"||key=="add_document"){
                //             curData.basicTemplateData[key].name="No file selected";
                //             curData.basicTemplateData[key].file_obj="";
                //         }else{
                //             curData.basicTemplateData[key]="";
                //         }
                //         this.state.checkboxStatus[key].checked = false;
                //         this.state.checkboxStatus[key].disabled = "disabled";
                //     }
                //
                // }
                // this.setState({
                //     is_remove:0,
                //     data:curData
                // })

                window.location.reload();
            }else{
                // console.log("failed, error code is: "+response.data.code);
                message.error(response.data.data)
            }
        }).catch(function (error) {
            // console.log(error);
            message.error(error && error.message)

        });


    }
    setInitInfo=()=>{
        this.setState({
            initData:this.props.curSelectSecurity
        });
    }
    targetToStep3=()=>{
        this.setState({
            isShowErrorTip:0,
            isAvailable:-1
        })
        window.$("#modifyConvertibleModal .tab-pane").removeClass("active");
        window.$("#reviewModifications").addClass("active");
    }

    /**
     * 校验重复的custom label id
     */
    checkCustomLabelId=()=>{
        const { data } = this.state;
        const { curSelectSecurity } = this.props;
        post('checkCustomLabelId',{custom_label_id: data.basicTemplateData.custom_label_id, security_plan_id: curSelectSecurity.security_plan_id, security_type: curSelectSecurity.security_type}).then((response)=> {
            if(response.data.code === 0){
                this.setState({
                    isAvailable:response.data.data.isAvailable
                }, ()=>this.checkNextAvailabel())
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    checkNextAvailabel=()=>{
        let flag =true; //用于验证勾选的checkbox有没有填值
        let isRequiredChecked = false;
        let isOptionalChecked = false;
        let curData = this.state.data;
        let optionalCheckKey = this.state.optionalKey;
        let checkKey = this.state.requiredKey;
        for(let i=0;i<this.state.requiredKey.length;i++){
            if(this.state.checkboxStatus[checkKey[i]].checked){
                isRequiredChecked = true;
                if(checkKey[i]=="add_document"||checkKey[i]=="purchase_agreement"||checkKey[i]=="form_of_convertible"||checkKey[i]=="additional_documents"){
                    if(!curData.basicTemplateData[checkKey[i]].file_obj){
                        this.state.errorKeyArr.push(checkKey[i]);
                        flag = false;
                    }
                }else if(checkKey[i]=="custom_label_id"){
                    if(!curData.basicTemplateData[checkKey[i]] || this.state.isAvailable==0) {
                        flag = false;
                    }
                }else{
                    if(!curData.basicTemplateData[checkKey[i]]){
                        this.state.errorKeyArr.push(checkKey[i]);
                        flag = false;
                    }
                }
            }
        }
        if(!isRequiredChecked){
            for(let k=0;k<this.state.optionalKey.length;k++){
                if(this.state.checkboxStatus[optionalCheckKey[k]].checked){
                    flag = true;
                    isOptionalChecked = true;
                    if(curData.basicTemplateData[optionalCheckKey[k]] == 0){
                        continue;
                    }
                    if(!curData.basicTemplateData[optionalCheckKey[k]]){
                        curData.basicTemplateData[optionalCheckKey[k]] = this.state.initData[optionalCheckKey[k]];
                        this.setState({
                                data:curData
                            }
                        )
                    }
                }

            }
        }
        if(!isRequiredChecked && !isOptionalChecked){
            flag = false;
        }
        if(flag){
            this.targetToStep3();
        }else{
            //如果custom_label_id重复
            if(this.state.isAvailable==0){
                this.setState({
                    isShowErrorTip:0,
                })
            }else if(!isRequiredChecked && !isOptionalChecked){

            }else{
                //开始展示错误信息：勾选了但是没填
                this.setState({
                    isShowErrorTip:1,
                })
            }
        }
    }
    showDeleteModal=()=>{
        this.setState({
            is_show_delete_modal:1,
        })
    }
    closeDeleteModal=()=>{
        this.setState({
            is_show_delete_modal:0,
        })
    }
    deleteAllDoc=()=>{
        this.setState({
            is_remove:1
        })
    }

    modifyCnDateChange = (key, moment_key, date) => {
        const { dateFormat } = this.state;
        let curData = this.state.data;
        curData.basicTemplateData[key] = date.format( dateFormat);
        curData.basicTemplateData[moment_key] = date;
        this.setState({
            data:curData
        });
    }


    render(){
        let showSelectCount = 0;
        const { currency = '' , curSelectSecurity} = this.props;
        const { data, dateFormat, checkboxStatus} = this.state
        return(
            <div className="modal fade" id="modifyConvertibleModal"  data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="modal-dialog dialog960 dialog-top40">
                    <div className="tab-content">
                        <div role="tabpanel" className="modal-content content720 tab-pane active" id="modifyReason">
                            <div className="modal-header box-header">
                                <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.resetAction}>×</button>
                                <h4 className="modal-title" id="myModalLabel">{__('Modify convertible')}</h4>
                            </div>
                            <div className="modal-body padding2030">
                                <div className="shareTitleBox">
                                    <div className="iconBox bg-lightgreen">
                                        <EditOutlined />
                                    </div>
                                    <div className="shareTextBox">
                                        <p className="shareTitle">{__('Select a reason for modifying the selected convertible')}</p>
                                        <p className="text-muted">{__('Some modifications will trigger an email to the stakeholder. You can review the modifications and email notifications before saving your changes.')}</p>
                                    </div>
                                </div>
                                <div className="padding2026026">
                                    <div className="form-group add-items">
                                        <div className="row  profile-row">
                                            <label className="col-xs-5 add-label">{__('Reason')}<br/><i className="text-muted"><small>- Required</small></i></label>
                                            <div className="col-xs-4">
                                                <select name="reason"  value={this.state.data.reason} onChange={this.recordData} className="form-control select-container">
                                                    <option value="">---</option>
                                                    {this.state.modifyReason.map((value,key)=>{
                                                        return(
                                                            <option key={key}>{value}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group add-items">
                                        <div className="row  profile-row">
                                            <label className="col-xs-5 add-label">{__('Note')} </label>
                                            <div className="col-xs-6">
                                                <textarea name="note" onChange={this.recordData} value={this.state.data.note} className="form-control text-area minHeight100" type="text"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div role="presentation" className="modal-footer box-footer no-paddingtop">
                                {this.state.data.reason=="" && <a className="btn boxbtn-to send-disabledBtn">{__('Next: modify value')}</a>}
                                {this.state.data.reason!=="" && <a onClick={this.setInitInfo} href="#modifyValue" role="tab" data-toggle="tab" className="btn boxbtn-to bg-darkblue">{__('Next: modify values')}</a>
                                }

                            </div>
                        </div>
                        <div role="tabpanel" className="modal-content content900 tab-pane" id="modifyValue">
                            <div className="modal-header box-header">
                                <a href="#modifyReason"  aria-controls="modifyReason" role="tab" data-toggle="tab" className="back-btn"></a>
                                <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.resetAction}>×</button>
                                <h4 className="modal-title" id="myModalLabel">{__('Modify convertible')}</h4>
                            </div>
                            <div className="modal-body padding2030">
                                {this.state.isShowErrorTip == 1 &&
                                <div className="alert alert-warning alert-dismissible pink-box">
                                    <p >{__('The required field(s) can not be empty.')}</p>
                                </div>
                                }
                                {this.state.isAvailable == 0 &&
                                <div  className="alert alert-warning alert-dismissible pink-box">
                                    <p>{__('Custom label already exists. Please select another.')}</p>
                                </div>
                                }
                                <p className="text-muted marginBott10">{__('Select the fields you would like to change and enter the new information.')}</p>
                                <div className="modify-content">
                                    <div className="shareTitleBox">
                                        <div className="iconBox bg-lightgreen">
                                            <DatabaseOutlined />
                                        </div>
                                        <div className="shareTextBox">
                                            <p className="shareTitle">{__('Note details')}</p>
                                            <p className="text-muted">{__('Update information included in the convertible note agreements such quantity, issue date, and board approval date date.')}</p>
                                        </div>
                                    </div>
                                    <table className="table modify-table modify-value-table">
                                        <tbody>
                                        <tr className="modify-firstRow">
                                            <td>{__('Field')}</td>
                                            <td>{__('Value')}</td>
                                            <td>{__('New value')}</td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="custom_label_check" type="checkbox" name="custom_label_id" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.custom_label_id.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="custom_label_check"></label>
                                                    <span className="modify-label text-muted">{__('Custom label')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.custom_label_id}</td>
                                            <td><input type="text" className="form-control " name="custom_label_id" value={this.state.data.basicTemplateData.custom_label_id} disabled={this.state.checkboxStatus.custom_label_id.disabled} onChange={this.recordCurData} /></td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <a className="convertible-ques top2">
                                                        <p className="convertible-tiptext modify-tiptext144">{__('Issue Date cannot be modified for certificates that are transferred canceled, terminated, converted repurchased or resulting from one of these transactions.')}</p>
                                                    </a>
                                                    <input id="detailsCheck3" type="checkbox" name="issue_date" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.issue_date.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="detailsCheck3"></label>
                                                    <span className="modify-label text-muted">{__('Issue Date')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.issue_date=="" ? "" : formatDate(curSelectSecurity.issue_date) }</td>
                                            <td>
                                                <DateSelector
                                                    disabled={checkboxStatus.issue_date.disabled}
                                                    onChange={this.modifyCnDateChange.bind(this, 'issue_date', 'issue_date_moment')}
                                                    value={I(data.basicTemplateData['issue_date_moment'], I(data.basicTemplateData['issue_date'], '') ? moment(data.basicTemplateData['issue_date'], dateFormat) : '')}
                                                    className="datepicker_opacity"
                                                />
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="detailsCheck7" type="checkbox" name="board_approval_date" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.board_approval_date.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="detailsCheck7"></label>
                                                    <span className="modify-label text-muted">{__('Board approval date')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.board_approval_date=="" ? "" : formatDate(curSelectSecurity.board_approval_date) }</td>
                                            <td>
                                                <DateSelector
                                                    disabled={checkboxStatus.board_approval_date.disabled}
                                                    onChange={this.modifyCnDateChange.bind(this, 'board_approval_date', 'board_approval_date_moment')}
                                                    value={I(data.basicTemplateData['board_approval_date_moment'], I(data.basicTemplateData['board_approval_date'], '') ? moment(data.basicTemplateData['board_approval_date'], dateFormat) : '')}
                                                    className="datepicker_opacity"
                                                />
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <a className="convertible-ques top2">
                                                        <p className="convertible-tiptext modify-tiptext144">{__('Canceled date cannot be modified for certificates that are transferred canceled, terminated, converted repurchased or resulting from one of these transactions.')}</p>
                                                    </a>
                                                    <input id="detailsCheck8" type="checkbox" name="cancellation_date" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.cancellation_date.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="detailsCheck8"></label>
                                                    <span className="modify-label text-muted">{__('Canceled date')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.cancellation_date=="" ? "" : formatDate(curSelectSecurity.cancellation_date) }</td>
                                            <td>
                                                <DateSelector
                                                    disabled={checkboxStatus.cancellation_date.disabled}
                                                    onChange={this.modifyCnDateChange.bind(this, 'cancellation_date', 'cancellation_date_moment')}
                                                    value={I(data.basicTemplateData['cancellation_date_moment'], I(data.basicTemplateData['cancellation_date'], '') ? moment(data.basicTemplateData['cancellation_date'], dateFormat) : '')}
                                                    className="datepicker_opacity"
                                                />
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="detailsCheck9" type="checkbox" name="cancellation_reason" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.cancellation_reason.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="detailsCheck9"></label>
                                                    <span className="modify-label text-muted">{__('Cancellation reason')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.cancellation_reason}</td>
                                            <td>
                                                <select type="text" className="form-control select-container modify-select" name="cancellation_reason" value={this.state.data.basicTemplateData.cancellation_reason} disabled={this.state.checkboxStatus.cancellation_reason.disabled} onChange={this.recordCurData} >
                                                    <option value="">---</option>
                                                    {cancellationReason.map((value,key)=>{
                                                        return(
                                                            <option key={key}>{value}</option>
                                                        )
                                                    })}
                                                </select>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="modify-content">
                                    <div className="shareTitleBox">
                                        <div className="iconBox bg-lightgreen">
                                            <ClockCircleOutlined />
                                        </div>
                                        <div className="shareTextBox">
                                            <p className="shareTitle">{__('Terms')}</p>
                                            <p className="text-muted">{__('Update terms of convertible note.')}</p>
                                        </div>
                                    </div>
                                    <table className="table modify-table modify-value-table">
                                        <tbody>
                                        <tr className="modify-firstRow">
                                            <td>{__('Field')}</td>
                                            <td>{__('Value')}</td>
                                            <td>{__('New value')}</td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="terms01" type="checkbox" name="maturity_date" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.maturity_date.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="terms01"></label>
                                                    <span className="modify-label text-muted">{__('Maturity date')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.maturity_date=="" ? "" :  formatDate(curSelectSecurity.maturity_date)}</td>
                                            <td>
                                                <DateSelector
                                                    disabled={checkboxStatus.maturity_date.disabled}
                                                    onChange={this.modifyCnDateChange.bind(this, 'maturity_date', 'maturity_date_moment')}
                                                    value={I(data.basicTemplateData['maturity_date_moment'], I(data.basicTemplateData['maturity_date'], '') ? moment(data.basicTemplateData['maturity_date'], dateFormat) : '')}
                                                    className="datepicker_opacity"
                                                />
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="terms02" type="checkbox" name="principal" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.principal.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="terms02"></label>
                                                    <span className="modify-label text-muted">{__('Principal')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.principal}</td>
                                            <td><input type="text" className="form-control " name="principal" value={this.state.data.basicTemplateData.principal} disabled={this.state.checkboxStatus.principal.disabled} onBlur={this.formatNumData} onFocus={this.focusNumData} onChange={this.recordNumData}/></td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="terms03" type="checkbox" name="valuation_cap" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.valuation_cap.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="terms03"></label>
                                                    <span className="modify-label text-muted">{__('Valuation cap')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{formatNumber(curSelectSecurity.valuation_cap,null) }</td>
                                            <td><input type="text" className="form-control " name="valuation_cap" value={this.state.data.basicTemplateData.valuation_cap} disabled={this.state.checkboxStatus.valuation_cap.disabled} onBlur={this.formatNumData} onFocus={this.focusNumData} onChange={this.recordNumData}/></td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <a className="convertible-ques top2">
                                                        <p className="convertible-tiptext modify-tiptext86">{__('The discount rate the note holder will purchase shares in the next financing round.')}</p>
                                                    </a>
                                                    <input id="terms04" type="checkbox" name="conversion_discount" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.conversion_discount.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="terms04"></label>
                                                    <span className="modify-label text-muted">{__('Conversion discount')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{formatPercent(curSelectSecurity.conversion_discount/100) }</td>
                                            <td>
                                                <div className="input-group">
                                                    <input type="text" className="form-control " name="conversion_discount" value={this.state.data.basicTemplateData.conversion_discount} disabled={this.state.checkboxStatus.conversion_discount.disabled} onBlur={this.formatNumData} onFocus={this.focusNumData} onChange={this.recordNumData}/>
                                                    <span className="input-group-addon">%</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="terms05" type="checkbox" name="conversion_trigger" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.conversion_trigger.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="terms05"></label>
                                                    <span className="modify-label text-muted">{__('Conversion trigger')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.conversion_trigger}</td>
                                            <td><input type="text" className="form-control " name="conversion_trigger" value={this.state.data.basicTemplateData.conversion_trigger} disabled={this.state.checkboxStatus.conversion_trigger.disabled} onBlur={this.formatNumData} onFocus={this.focusNumData} onChange={this.recordNumData}/></td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="terms06" type="checkbox" name="change_in_control_percent" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.change_in_control_percent.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="terms06"></label>
                                                    <span className="modify-label text-muted">{__('Change in control percent')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{formatPercent(curSelectSecurity.change_in_control_percent/100) }</td>
                                            <td>
                                                <div className="input-group">
                                                    <input type="text" className="form-control " name="change_in_control_percent" value={this.state.data.basicTemplateData.change_in_control_percent} disabled={this.state.checkboxStatus.change_in_control_percent.disabled} onBlur={this.formatNumData} onFocus={this.focusNumData} onChange={this.recordNumData}/>
                                                    <span className="input-group-addon">%</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <a className="convertible-ques top2">
                                                        <p className="convertible-tiptext modify-tiptext45">{__('Annual percentage rate.')}</p>
                                                    </a>
                                                    <input id="terms07" type="checkbox" name="interest_rate" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.interest_rate.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="terms07"></label>
                                                    <span className="modify-label text-muted">{__('Interest rate')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{formatPercent(curSelectSecurity.interest_rate/100) }</td>
                                            <td>
                                                <div className="input-group">
                                                    <input type="text" className="form-control " name="interest_rate" value={this.state.data.basicTemplateData.interest_rate} disabled={this.state.checkboxStatus.interest_rate.disabled} onBlur={this.formatNumData} onFocus={this.focusNumData} onChange={this.recordNumData}/>
                                                    <span className="input-group-addon">%</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <a className="convertible-ques top2">
                                                        <p className="convertible-tiptext modify-tiptext86">{__('Total interest will be calculated up to this date instead of maturity date.')}</p>
                                                    </a>
                                                    <input id="terms08" type="checkbox" name="interest_accrual_end_date" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.interest_accrual_end_date.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="terms08"></label>
                                                    <span className="modify-label text-muted">{__('Interest accrual end date')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.interest_accrual_end_date=="" ? "" :  formatDate(curSelectSecurity.interest_accrual_end_date) }</td>
                                            <td>
                                                <DateSelector
                                                    disabled={checkboxStatus.interest_accrual_end_date.disabled}
                                                    onChange={this.modifyCnDateChange.bind(this, 'interest_accrual_end_date', 'interest_accrual_end_date_moment')}
                                                    value={I(data.basicTemplateData['interest_accrual_end_date_moment'], I(data.basicTemplateData['interest_accrual_end_date'], '') ? moment(data.basicTemplateData['interest_accrual_end_date'], dateFormat) : '')}
                                                    className="datepicker_opacity"
                                                />
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <a className="convertible-ques top2">
                                                        <p className="convertible-tiptext modify-tiptext86">{__('Total interest will be calculated starting from this date instead of the issue date.')}</p>
                                                    </a>
                                                    <input id="terms09" type="checkbox" name="day_count_convention" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.day_count_convention.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="terms09"></label>
                                                    <span className="modify-label text-muted">{__('Day count convention')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.day_count_convention}</td>
                                            <td>
                                                <select type="text" className="form-control select-container modify-select" name="day_count_convention" value={this.state.data.basicTemplateData.day_count_convention} disabled={this.state.checkboxStatus.day_count_convention.disabled} onChange={this.recordCurData} >
                                                    {this.state.dayCountList.map((value,key)=>{
                                                        return(
                                                            <option key={key}>{value}</option>
                                                        )
                                                    })}
                                                </select>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="terms10" type="checkbox" name="interest_accrual_period" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.interest_accrual_period.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="terms10"></label>
                                                    <span className="modify-label text-muted">{__('Interest accrual period')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.interest_accrual_period}</td>
                                            <td>
                                                <select type="text" className="form-control select-container modify-select" name="interest_accrual_period" value={this.state.data.basicTemplateData.interest_accrual_period} disabled={this.state.checkboxStatus.interest_accrual_period.disabled} onChange={this.recordCurData} >
                                                    {this.state.interestAccrualPeriodList.map((value,key)=>{
                                                        return(
                                                            <option key={key}>{value}</option>
                                                        )
                                                    })}
                                                </select>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <a className="convertible-ques top2">
                                                        <p className="convertible-tiptext modify-tiptext86">{__('Total interest will be calculated starting from this date instead of the issue date.')}</p>
                                                    </a>
                                                    <input id="terms11" type="checkbox" name="interest_start_date" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.interest_start_date.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="terms11"></label>
                                                    <span className="modify-label text-muted">{__('Interest start date')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.interest_start_date=="" ? "" : formatDate(curSelectSecurity.interest_start_date)}</td>
                                            <td>
                                                <DateSelector
                                                    disabled={checkboxStatus.interest_start_date.disabled}
                                                    onChange={this.modifyCnDateChange.bind(this, 'interest_start_date', 'interest_start_date_moment')}
                                                    value={I(data.basicTemplateData['interest_start_date_moment'], I(data.basicTemplateData['interest_start_date'], '') ? moment(data.basicTemplateData['interest_start_date'], dateFormat) : '')}
                                                    className="datepicker_opacity"
                                                />

                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="terms12" type="checkbox" name="interest_compounding_period" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.interest_compounding_period.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="terms12"></label>
                                                    <span className="modify-label text-muted">{__('Interest compounding period')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.interest_compounding_period}</td>
                                            <td>
                                                <select type="text" className="form-control select-container modify-select" name="interest_compounding_period" value={this.state.data.basicTemplateData.interest_compounding_period} disabled={this.state.checkboxStatus.interest_compounding_period.disabled} onChange={this.recordCurData} >
                                                    {this.state.interestCompoundingPeriodList.map((value,key)=>{
                                                        return(
                                                            <option key={key}>{value}</option>
                                                        )
                                                    })}
                                                </select>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="terms13" type="checkbox" name="interest_payout" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.interest_payout.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="terms13"></label>
                                                    <span className="modify-label text-muted">{__('Interest payout')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.interest_payout}</td>
                                            <td>
                                                <select type="text" className="form-control select-container modify-select" name="interest_payout" value={this.state.data.basicTemplateData.interest_payout} disabled={this.state.checkboxStatus.interest_payout.disabled} onChange={this.recordCurData} >
                                                    {this.state.interestPayoutList.map((value,key)=>{
                                                        return(
                                                            <option key={key}>{value}</option>
                                                        )
                                                    })}
                                                </select>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {curSelectSecurity.status == "Transferred" &&
                                <div className="modify-content">
                                    <div className="shareTitleBox">
                                        <div className="iconBox bg-lightgreen">
                                            <CalendarOutlined />
                                        </div>
                                        <div className="shareTextBox">
                                            <p className="shareTitle">{__('Transfer date')}</p>
                                            <p className="text-muted">{__('Update information for transfer date.')}</p>
                                        </div>
                                    </div>
                                    <table className="table modify-table modify-value-table">
                                        <tbody>
                                        <tr className="modify-firstRow">
                                            <td>{__('Field')}</td>
                                            <td>{__('Value')}</td>
                                            <td>{__('New value')}</td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box transfer_date_list">
                                                    <input id="cn_transferCheck1" type="checkbox" name="transfer_date"
                                                           className="re-checkbox modify-checkbox "
                                                           checked={this.state.checkboxStatus.transfer_date.checked}
                                                           onChange={this.checkedStatus}/>
                                                    <label htmlFor="cn_transferCheck1"></label>
                                                    <ul>
                                                        {curSelectSecurity.transferSecurity && curSelectSecurity.transferSecurity.map((v, k) => {
                                                            return (
                                                                <li className="modify-label text-muted">Transfer date for <span className="text-blue">{formatFractionalNumber(v.quantity)}</span> shares to <span className="text-blue">{v.name}</span></li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.transfer_date == "" ? "" : formatDate(curSelectSecurity.transfer_date)}</td>
                                            <td>
                                                <DateSelector
                                                    disabled={checkboxStatus.transfer_date.disabled}
                                                    onChange={this.modifyCnDateChange.bind(this, 'transfer_date', 'transfer_date_moment')}
                                                    value={I(data.basicTemplateData['transfer_date_moment'], I(data.basicTemplateData['transfer_date'], '') ? moment(data.basicTemplateData['transfer_date'], dateFormat) : '')}
                                                    className="datepicker_opacity"
                                                />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                }
                                {curSelectSecurity.status == "Converted" &&
                                <div className="modify-content">
                                    <div className="shareTitleBox">
                                        <div className="iconBox bg-lightgreen">
                                            <DatabaseOutlined />
                                        </div>
                                        <div className="shareTextBox">
                                            <p className="shareTitle">{__('Convert date')}</p>
                                            <p className="text-muted">{__('Update information for convert date.')}</p>
                                        </div>
                                    </div>
                                    <table className="table modify-table modify-value-table">
                                        <tbody>
                                        <tr className="modify-firstRow">
                                            <td>{__('Field')}</td>
                                            <td>{__('Value')}</td>
                                            <td>{__('New value')}</td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box transfer_date_list">
                                                    <input id="cn_convertCheck1" type="checkbox" name="convert_date"
                                                           className="re-checkbox modify-checkbox "
                                                           checked={this.state.checkboxStatus.convert_date.checked}
                                                           onChange={this.checkedStatus}/>
                                                    <label htmlFor="cn_convertCheck1"></label>
                                                    <ul>
                                                        {curSelectSecurity.transferSecurity && curSelectSecurity.transferSecurity.map((v, k) => {
                                                            return (
                                                                <li className="modify-label text-muted">Convert date to <span className="text-blue">{v.name}</span></li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.convert_date == "" ? "" : formatDate(curSelectSecurity.convert_date)}</td>
                                            <td>
                                                <DateSelector
                                                    disabled={checkboxStatus.convert_date.disabled}
                                                    onChange={this.modifyCnDateChange.bind(this, 'convert_date', 'convert_date_moment')}
                                                    value={I(data.basicTemplateData['convert_date_moment'], I(data.basicTemplateData['convert_date'], '') ? moment(data.basicTemplateData['convert_date'], dateFormat) : '')}
                                                    className="datepicker_opacity"
                                                />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                }
                                <div className="modify-content">
                                    <div className="shareTitleBox">
                                        <div className="iconBox bg-lightgreen">
                                            <FolderOpenOutlined />
                                        </div>
                                        <div className="shareTextBox">
                                            <p className="shareTitle">{__('Documents and notes')}</p>
                                            <p className="text-muted">{__('Update all attachment types and notes available to holder.')}</p>
                                        </div>
                                    </div>
                                    {this.state.is_show_delete_modal==1 &&
                                    <div className="deleteModalBox">
                                        <DeleteDoc closeDeleteModal={this.closeDeleteModal} deleteAllDoc={this.deleteAllDoc}  modifyKey={this.state.data.modify_id}   />
                                    </div>
                                    }
                                    <table className="table modify-table modify-value-table">
                                        <tbody>
                                        <tr className="modify-firstRow">
                                            <td>{__('Field')}</td>
                                            <td>{__('Value')}</td>
                                            <td>{__('New value')}</td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td>
                                                <div className="check-box">
                                                    <input id="convertible_doc_check0" type="checkbox" name="purchase_agreement" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.purchase_agreement.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="convertible_doc_check0"></label>
                                                    <span className="modify-label text-muted">{__('Purchase agreement')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.purchase_agreement?.name}</td>
                                            <td className="fileTd ">
                                                <div className="select-btn select-blue lib-choose marginRig10">
                                                    {__('Choose a file')}
                                                    <label htmlFor="purchase_agreement" className="sr-only" >File input</label>
                                                    <input name="purchase_agreement"  ref="purchase_agreement" disabled={this.state.checkboxStatus.purchase_agreement.disabled} type="file"  id="purchase_agreement"  onChange={this.selectFile} />
                                                </div>
                                                <div className="filename-box">
                                                    <span className="close files-del hidden">x</span>
                                                    <p className="text-muted">{this.state.data.basicTemplateData.purchase_agreement.name==""?__("No file selected"):this.state.data.basicTemplateData.purchase_agreement.name}</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td>
                                                <div className="check-box">
                                                    <input id="convertible_doc_check1" type="checkbox" name="form_of_convertible" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.form_of_convertible.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="convertible_doc_check1"></label>
                                                    <span className="modify-label text-muted">{__('Form of convertible')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.form_of_convertible?.name}</td>
                                            <td className="fileTd ">
                                                <div className="select-btn select-blue lib-choose marginRig10">
                                                    {__('Choose a file')}
                                                    <label htmlFor="form_of_convertible" className="sr-only" >File input</label>
                                                    <input name="form_of_convertible"  disabled={this.state.checkboxStatus.form_of_convertible.disabled} type="file"  id="form_of_convertible"  onChange={this.selectFile} />
                                                </div>
                                                <div className="filename-box">
                                                    <span className="close files-del hidden">x</span>
                                                    <p className="text-muted">{this.state.data.basicTemplateData.form_of_convertible.name==""?__("No file selected"):this.state.data.basicTemplateData.form_of_convertible.name}</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td>
                                                <div className="check-box">
                                                    <input id="convertible_doc_check2" type="checkbox" name="add_document" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.add_document.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="convertible_doc_check2"></label>
                                                    <span className="modify-label text-muted">{__('Add more additional documents')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}></td>
                                            <td className="fileTd ">
                                                <div className="select-btn select-blue lib-choose marginRig10">
                                                    {__('Choose a file')}
                                                    <label htmlFor="add_doc" className="sr-only" >File input</label>
                                                    <input name="add_document"  ref="add_document" disabled={this.state.checkboxStatus.add_document.disabled} type="file"  id="add_doc"  onChange={this.selectFile} />
                                                </div>
                                                <div className="filename-box">
                                                    <span className="close files-del hidden">x</span>
                                                    <p className="text-muted">{this.state.data.basicTemplateData.add_document.name==""?__("No file selected"):this.state.data.basicTemplateData.add_document.name}</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td>
                                                <div className="check-box">
                                                    <input id="convertible_doc_check4" name="remove_document" type="checkbox" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.remove_document.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="convertible_doc_check4"></label>
                                                    <span className="modify-label text-muted">{__('Remove all additional documents')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}></td>
                                            {this.state.checkboxStatus.remove_document.checked===false &&
                                            <td>
                                                <div type="text" name="remove_document" className={"form-control "}  disabled={this.state.checkboxStatus.remove_document.disabled} >
                                                    <button type="button" ><DeleteOutlined style={{cursor:'not-allowed'}}/></button>
                                                </div>
                                            </td>
                                            }
                                            {this.state.checkboxStatus.remove_document.checked===true &&
                                            <td>
                                                <div type="text" name="remove_document" className={"form-control "}  disabled={this.state.checkboxStatus.remove_document.disabled} >
                                                    <button onClick={this.showDeleteModal} type="button" ><DeleteOutlined /></button>
                                                </div>
                                            </td>
                                            }
                                        </tr>
                                        <tr className="modify-contentRow ">
                                            <td>
                                                <div className="check-box">
                                                    <input id="convertible_doc_check5" type="checkbox" name="notes" className="re-checkbox modify-checkbox" checked={this.state.checkboxStatus.notes.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="convertible_doc_check5"></label>
                                                    <span className="modify-label text-muted">{__('Notes')}</span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.notes}</td>
                                            <td>
                                                <textarea type="text" className={"form-control "} name="notes" value={this.state.data.basicTemplateData.notes} disabled={this.state.checkboxStatus.notes.disabled} onChange={this.recordCurData} />
                                            </td>
                                        </tr>
                                        <tr className="modify-contentRow">
                                            <td>
                                                <div className="check-box">
                                                    <input id="convertible_doc_check6" type="checkbox" name="other_consideration" className="re-checkbox modify-checkbox " checked={this.state.checkboxStatus.other_consideration.checked} onChange={this.checkedStatus}/>
                                                    <label htmlFor="convertible_doc_check6"></label>
                                                    <span className="modify-label text-muted">{__('Other consideration ')}<i className="text-muted">- {__('Optional')}</i> </span>
                                                </div>
                                            </td>
                                            <td className={"mid_column"}>{curSelectSecurity.other_consideration}</td>
                                            <td><input type="text" className="form-control " name="other_consideration" value={this.state.data.basicTemplateData.other_consideration} disabled={this.state.checkboxStatus.other_consideration.disabled} onChange={this.recordCurData} /></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div role="presentation" className="modal-footer box-footer no-paddingtop">
                                {/*{this.state.data.financingHistoryList.checked.length>0 && <a href="#reviewModifications" role="tab" data-toggle="tab" className="btn boxbtn-to bg-darkblue">Next: Review Modifications</a>}*/}
                                {/*{this.state.data.financingHistoryList.checked.length===0 && <a  className="btn boxbtn-to bg-darkblue" onClick={this.alertTip}>Next: Review Modifications</a>}*/}

                                {/*<a href="#reviewModifications" role="tab" data-toggle="tab" className="btn boxbtn-to bg-darkblue">Next: Review Modifications</a>*/}
                                <a onClick={this.checkCustomLabelId}  role="tab" data-toggle="tab" className="btn boxbtn-to bg-darkblue">{__('Next: review modifications')}</a>
                            </div>
                        </div>
                        <div role="tabpanel" className="modal-content content900 tab-pane" id="reviewModifications">
                            <div className="modal-header box-header">
                                <a href="#modifyValue"  aria-controls="modifyReason" role="tab" data-toggle="tab" className="back-btn"></a>
                                <button type="button" className="close shut-btn" data-dismiss="modal" aria-hidden="true" onClick={this.resetAction}>×</button>
                                <h4 className="modal-title" id="myModalLabel">{__('Modify convertible')}</h4>
                            </div>
                            <div className="modal-body padding2030">
                                <div className="modify-content">
                                    <div className="shareTitleBox">
                                        <div className="iconBox bg-lightgreen">
                                            <DiffOutlined />
                                        </div>
                                        <div className="shareTextBox">
                                            <p className="shareTitle">{__('Review and apply modifications')}</p>
                                            <p className="text-muted">{__('Review the table below and save the modifications. For some fields on accepted securities, a notification will be sent out. Changes marked with  Yes on Sends email notification will send an email to the stakeholder.')}<br />
                                                {/*<a className="text-blue">Learn more about modifications ></a>*/}
                                            </p>
                                        </div>
                                    </div>
                                    <table className="modify-table reviewTb">
                                        <tbody>
                                        <tr className="modify-firstRow">
                                            <td className="width60">{__('Security')}</td>
                                            <td className="width120" >{__('Stakeholder')}</td>
                                            <td className="width140" >{__('Field')}</td>
                                            <td className="width160">{__('Old value')}</td>
                                            <td className="width160" >{__('New value')}</td>
                                            <td className="width180" >{__('Sends email notification')}</td>
                                        </tr>
                                        {Object.values(this.state.checkboxStatus).map((value,key)=>{
                                            if(value.checked){
                                                showSelectCount++;
                                                if(value.jsonKey === "add_document" ){
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd">{this.state.initData[value.jsonKey]}</td>
                                                            <td className="newValueTd">{this.state.data.basicTemplateData[value.jsonKey].name}</td>
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    );
                                                }else if(value.jsonKey === "purchase_agreement" || value.jsonKey === "form_of_convertible"|| value.jsonKey === "additional_documents" ){
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd">{this.state.initData[value.jsonKey].name}</td>
                                                            <td className="newValueTd">{this.state.data.basicTemplateData[value.jsonKey].name}</td>
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    )
                                                }else if(value.jsonKey === "conversion_discount" || value.jsonKey === "change_in_control_percent"|| value.jsonKey === "interest_rate" ){
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd">{this.state.initData[value.jsonKey]}{this.state.initData[value.jsonKey]==""?"":"%"}</td>
                                                            <td className="newValueTd">{this.state.data.basicTemplateData[value.jsonKey]}%</td>
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    );
                                                }else if(value.jsonKey === "convert_date" ||value.jsonKey === "transfer_date" ||value.jsonKey === "issue_date" || value.jsonKey === "board_approval_date" || value.jsonKey ==="cancellation_date" ){
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd">{ formatDate(this.state.initData[value.jsonKey])}</td>
                                                            <td className="newValueTd">{ formatDate(data.basicTemplateData[value.jsonKey])}</td>
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    );
                                                }else{
                                                    return(
                                                        <tr key={key} className="reviewTb-contentRow ">
                                                            <td>{ showSelectCount===1 && this.state.initData.custom_label_id } </td>
                                                            <td>{ showSelectCount===1 && this.state.initData.name}</td>
                                                            <td>{value.fieldName}</td>
                                                            <td className="oldValueTd">{(value.jsonKey == "principal"||value.jsonKey == "valuation_cap")?formatNumber(this.state.initData[value.jsonKey],null) : this.state.initData[value.jsonKey]}</td>
                                                            <td className="newValueTd">{(value.jsonKey == "principal" && this.state.data.basicTemplateData.principal == 0) ? " ":this.state.data.basicTemplateData[value.jsonKey]}</td>
                                                            {/*<td className="newValueTd">{this.state.data.basicTemplateData[value.jsonKey]}</td>*/}
                                                            <td>X {__('No')}</td>
                                                        </tr>
                                                    );
                                                }

                                            }

                                        })}
                                        {this.state.uploadProgress >0 &&
                                        <tr className="reviewTb-contentRow ">
                                            <td colSpan={"6"}>{this.state.uploadProgress}% {__('uploaded')}...</td>
                                        </tr>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer box-footer no-paddingtop">
                                <a className="btn boxbtn-to bg-darkblue" data-dismiss="modal" onClick={this.submitAction}>{__('Apply modifications')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ModifyConvertibleModal;