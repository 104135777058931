import React, { Component } from 'react';
import { post } from 'srcPath/http';
import { message, Table, Button, Dropdown } from 'antd';
import Modal from 'components/Modal';
import classnames from 'classnames';
import Utils from 'Utils';

import { columns } from './config';
import './index.scss';

export default class List extends Component {
	state = {
		tableData: [],
		loading: true,
		emailContent: {},
	}

	async componentDidMount() {
		this.getTableData();
	}

	getTableData = async () => {
		this.setState({ loading: true })
		try {
			const { data: resData } = await post('getNotificationList')

			const { code, data } = resData || {}

			if (code == 0) {
				data.map((val) => {
					val.key = val.id;
				 })
				this.setState({ tableData: data })
			} else {
				message.error(data)
			}
		} catch(err) {
			message.error(err && err.message)
		}

		this.setState({ loading: false })
	}


	async handleClickRow(record, e) {
        const notification_id = record.id;
		const action = e.target.getAttribute("data-action");
		let emailContent = {};
		let type = ''
        if (action === 'preview' || e.target.tagName === 'SPAN') {
			try {
				const { data: resData } = await post('getNotificationsMailView', { notification_id })
	
				const { code, data } = resData || {};
				emailContent = data?.html;
				type = data?.type
	
				if (code == 0) {
					this.setState({ emailContent: data })
				} else {
					message.error(data)
				}
			} catch(err) {
				message.error(err && err.message)
			}
			let companyInfo = Utils.getLocalCompanyInfo();
			const notifyTitle = type === 'consent' ? `[${__('Action required')}] ${companyInfo.company_name} ${__('sent you a board consent to sign.')} ` : (type === 'meeting' ? `[${__('Action required')}] ${__('Attend board meeting')} - ${companyInfo.company_name}.` : `[${__('Action required')}] ${__('Claim your')} ${companyInfo.company_name} ${__('board seat.')}`)
            Modal.open({
				component: props => <div className="notifications-modal-box">
                    <p style={{ textAlign: 'center', paddingTop: 15, paddingBottom: 15, borderBottom: '1px solid #ccc' }}>{notifyTitle}</p>
                    <div className="email-preview-content" dangerouslySetInnerHTML = {{__html:emailContent}}></div>
				</div>,
				closable: true,
				width: 800
			})
        }
	}

	render() {
		const { tableData, loading } = this.state;
		return <div className="notifications-list-root">
				<Table 
					size="small" 
					rowKey="index" 
					columns={columns()} 
					dataSource={tableData} 
					pagination={false} 
					loading={loading}
					onRow={record => {
						return {
						  onClick: this.handleClickRow.bind(this, record),
						};
					  }}
				/>
            </div>
	}
}