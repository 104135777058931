import React, { Fragment } from 'react';

import './style.scss';

import { roundColor } from 'Utils'
import { uniq, clone } from 'lodash';
/**
* allTags: rounds 标签全集
* tags: 需要高亮显示的tag 数组
* isPick 是否显示全集，然后pick 出高亮tag
**/

const colorLength = roundColor.length;

export default function RoundTags(props) {
	let { tags = [], allTags = tags || [], isPick = false } = props;
	const colorMap = {}

	allTags = uniq(allTags)

	allTags.forEach((val, index)=> {
		const colorIndex = index >= colorLength ? (index % colorLength) : index

		colorMap[val] = roundColor[colorIndex]
	})

	return <Fragment>
		{
			isPick ? allTags.map((item, index) => (<span key={index} className="round-item" style={{ background: tags.includes(item) ? colorMap[item] : '#B9CCD3' }}>{item || '--' }</span>)) :
			tags.map((item, index) => <span key={index} style={{ background: colorMap[item] }} className="round-item">{item || '--'}</span>)
		}		
	</Fragment>
}