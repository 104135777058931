import React, { Component } from 'react';
import { Button, Empty } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import security from 'stores/security';
import Echarts from 'components/Echarts';
import { formatPercent, formatCurrencyNumber, firstWordUpperCase, formatNumber } from 'Utils';
import { getHoldingsStatus } from 'C/Portfolio/config';
import formatShares from 'Utils/format/formatShares';
import { formatDate } from 'Utils/format/formatDate';

import './style.scss';

const renderValue = val => {
	return (val || val === 0) ? val : '--' 
}

const Ownship = props => {
	const { percent = 0, isToken = false } = props || {}

	const option = {
	    tooltip: {
			show: true,
			formatter: (data) => {
				const { name, value } = data

				return `${name} ${formatPercent(value/100)}`
			}
	    },
	    series: [
	        {
			    labelLine: {
			    	show: false,
			    	// showAbove: true
			    },
	            type: 'pie',	
	            radius: ['50%', '70%'],
	            label: {
	                show: false,
	                position: 'outside'
	            },
			    emphasis: {
			    	scale: false
			    },
	            data: [
		            {
		            	name: isToken ? __('Fully Diluted Ownership') : __('Ownership'),
		            	value: percent
		            },
		            {
		            	name: __('Other'),
		            	value: 100 - percent
		            }
	            ],
	        }
	    ]
	}

	return <div className="owner-ship-wrap">
		<div className="chart-wrap">
			<Echarts option={option} />
			<div className="tips">{formatPercent(percent/100)}</div>
		</div>
		<div className="title">{isToken ? __('Fully Diluted Ownership') : __('Ownership')}</div>
	</div>
}

const Card = props => {
	const { data } = props
	const { type, cost, issuedDate, status, quantity, issuedTo, shareClassName } = data || {}
	const colorMap = {
		common: '#84C44C',
		preferred: '#00AACD',
		convertible: '#E4D212',
		plan: '#006AAC',
		warrant: '#bf70dd'
	}

	const isPlan = type === 'plan'
	const holdingStatus = getHoldingsStatus(status) || {}

	return <div className="certificate-card" style={{ borderLeftColor: colorMap[type] || '#fff' }}> 
		<div className="certificate-banner">
			<span className="certificate-type" title={renderValue(shareClassName)} >{renderValue(shareClassName)}</span>
			<span className="certificate-value">{formatShares(quantity)}</span>
		</div>
		<div className="certificate-card-item">
			<span>{__('Issued to')}</span>
			<span>{renderValue(issuedTo)}</span>
		</div>
		<div className="certificate-card-item">
			<span>{ isPlan ? __('Grant date') : __('Issue date')}</span>
			<span>{formatDate(issuedDate)}</span>
		</div>
		<div className="certificate-card-item">
			<span>{ isPlan ? __('Exercise price') : __('Cost')}</span>
			<span>{formatCurrencyNumber(cost)}</span>
		</div>
		<div className="certificate-card-item">
			<span>{__('Type')}</span>
			<span>{firstWordUpperCase(type)}</span>
		</div>
		<div className="certificate-card-status" style={holdingStatus.style || {}}>
			{holdingStatus.label}
		</div>
	</div>
}

const TokenWarrantCard = props => {
	const { data } = props
	const { type, grantDate, purchasePrice, securityId, quantity, token, vestingPeriod, status } = data || {}
	const colorMap = {
		tokenWarrant: '#64b2e0',
		tokenVesting: '#5590bc',
	}
	const isWarrant = type === 'tokenWarrant'
	const holdingStatus = getHoldingsStatus(status) || {}

	return <div className="certificate-card" style={{ borderLeftColor: colorMap[type] || '#fff' }}> 
		<div className="certificate-banner">
			<span className="certificate-type" title={isWarrant ? __('Warrant(SAFT)') : __('Vesting')} >{isWarrant ?  __('Warrant(SAFT)') : __('Vesting')}</span>
			<span className="certificate-value">{formatNumber(quantity)}</span>
		</div>
		<div className="certificate-card-item">
			<span>{__('Token')}</span>
			<span>{renderValue(token)}</span>
		</div>
		<div className="certificate-card-item">
			<span>{__('Security ID')}</span>
			<span>{renderValue(securityId)}</span>
		</div>
		<div className="certificate-card-item">
			<span>{__('Grant Date')}</span>
			<span>{formatDate(grantDate)}</span>
		</div>
		<div className="certificate-card-item">
			<span>{isWarrant ? __('Purchase Price') : __('Vesting Period')}</span>
			{!isWarrant && <span>{+vestingPeriod === 0 ? __('No Vesting') : `${formatNumber(vestingPeriod)} ${__('months')}`}</span>}
			{isWarrant && <span>{formatNumber(purchasePrice)}</span>}
		</div>
		<div className="certificate-card-status" style={holdingStatus.style || {}}>
			{holdingStatus.label}
		</div>
	</div>
}

const TokenCard = (props) => {
	const { data, type, index } = props
	const { coin, currency, number, value, pricePerToken } = data || {}
	const colorMap = {
		0: '#ffb03e',
		1: '#fe3939',
		2: '#fd56b1',
		3: '#ab20c0',
		4: '#0adae2',
	}

	return <div className="certificate-card" style={{ borderLeftColor: colorMap[`${index%5}`] || '#fff' }}> 
		<div className="token-banner">
			<img src="https://sproutqa.s3.ap-southeast-1.amazonaws.com/companylogo/2.webp" />
			<span className="certificate-type">{coin}</span>
		</div>
		<div className="certificate-card-item">
			<span>{__('Total number of tokens')}</span>
			<span>{renderValue(number)}</span>
		</div>
		<div className="certificate-card-item">
			<span>{__('Current price per tokens')}</span>
			<span>{renderValue(pricePerToken)}</span>
		</div>
		<div className="certificate-card-item">
			<span>{__('Value')}</span>
			<span>{renderValue(value)}</span>
		</div>
	</div>
}

export default class Holdings extends Component {
	processData = holdings => {
		const res = []
		const keys = Object.keys(holdings || {})

		keys.forEach(value => {
			if(holdings[value] && holdings[value].length && Array.isArray(holdings[value])) {
				holdings[value].forEach(item => {
					res.push({
						type: value,
						issuedTo: this.props.data.issuedTo,
						currency: this.props.data.currency,
						...item
					})
				})
			}
		})

		return res
	}

	render() {
		const { data = {}, onLink, type } = this.props || {}
		const { accessLevel, ownerShip = 0, tokenOwnerShip = 0, currency, tokenHoldings, holdings, coinInfo }  = data || {} 
		const isEquity = type === 'Equity'
		const subTitle = isEquity ? __('Equity') : __('Crypto holdings breakdown')
		const title = isEquity ? __('Equity Holdings') : __('Token holding')
		const showToken = (security.companyInfo && security.companyInfo.is_show_coin) === '1'
		const coinList = this.processData(coinInfo || {}) || []
		let isShow = false
		if (isEquity) {
			isShow = this.processData(holdings || {})?.length > 0
		} else {
			isShow = coinList?.length > 0
		}
		
		return isShow && (<div className="portfolio-overview-holdings-root">
		<div className="overview-banner">
			<div>
				<p className="title">{title}</p>
				{/* {showToken && <p className="sub-title">{subTitle}</p>} */}
			</div>
			<Button type="link" onClick={() => onLink('holdings')}>{__('View details')} <RightOutlined /></Button>
		</div>
		<div className="holdings-content">
			{
				accessLevel > 1 && isEquity && <div className="owner-ship">
					<Ownship percent={ownerShip}/>
				</div>
			}
			{isEquity && (
				<div className="certificate-wrap">
					{this.processData(holdings || {}).map((val, index) => (<Card key={index} data={val} currency={currency} />))}
				</div>
			)}
			{!isEquity && (
				<>
					{/* <div className="owner-ship">
						<Ownship percent={tokenOwnerShip} isToken={true} />
					</div> */}
					<div className="certificate-wrap">
						{this.processData(tokenHoldings || {}).map((val, index) => (<TokenWarrantCard key={index} data={val} currency={currency} />))}
					</div>
				</>
			)}

			{/* {!isEquity && (
				<div className="certificate-wrap">
					{!coinList.length && <Empty />}
					{coinList.length > 0 && coinList.map((val, index) => (<TokenCard key={index} index={index} data={val} currency={currency} />))}
				</div>
			)} */}
			
		</div>
	</div>)
	}
}