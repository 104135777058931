import React, { Fragment } from 'react';
import { relationshipList, countries } from "Utils/constant";
import Utils from "Utils";
// import DeleteDraftSecurity from "../components/DeleteDraftSecurity";
import CreateDraftSet from "./components/CreateDraftSet";
import RenameDraftSet from "./components/RenameDraftSet";
import DeleteDraftSet from "./components/DeleteDraftSet";
import SendInfoForSignature from "./components/SendInfoForSignature";
import { post } from 'srcPath/http';
import moment from 'moment';
import Loading from 'components/Loading';
import Permission from 'components/Permission';
import security from 'stores/security';
import { formatNumber, formatCurrencyNumber, formatDateForDatePicker, I } from "Utils";
import { withForm, FormItem } from 'react-antd-formutil';
import { Input, TimePicker, DatePicker, Select, Button, message, Row, Col, Collapse} from 'antd';
// import SelectStakeholder from 'components/SelectStakeholder'
import DraftWarrantsTable from "./components/DraftWarrantsTable";
import DateSelector from "../../../../components/DateSelector";
import CountrySelector from 'components/CountrySelector';
import TokenVestingBatchUploadModel from './components/TokenBulkUpload/TokenVestingBatchUploadModel';
import Modal, { ModalContent } from 'components/Modal';
import {CloseOutlined, DeleteOutlined, ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons";
import { formatDate } from 'Utils/format/formatDate';
import SelectStakeholder from 'B/components/WalletLabel/components/StakeholderSelector';
import openAssignToken from './components/AssignToken';

import './style.scss'
class DraftVesting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingStatus: 1,
            curPlanId: 0,
            documentSet: [],
            isESign: 0,
            originWalletAddressList: [],
            destinationWalletAddressList:[],
            sendResult: {
                "1": {
                    "id": 1,
                    "is_success": 1,//1-成功，0表示失败
                    "error_reason": ""
                },
                "4": {
                    "id": 4,
                    "is_success": 0,//1-成功，0表示失败
                    "error_reason": [
                        { "title": "Name", "message": "Can't be empty!" },
                        { "title": "age", "message": "Can't be bigger than 150!" },
                        { "title": "Certificate Id", "message": "Duplicated" }
                    ]
                },
            },
            successDetails: {},
            showError: {},
            saveStatus: -1,
            listKey: -1,
            type: "COIN_VESTING",
            addingStatus: 0,
            curSelectRelation: "all",
            draft_name: "",
            draft_id: "",
            currency: "",
            curSelectShareClassDraftSet: { id: 1, name: "24/08/2020", count: "2" },
            securityLists: [
                { security_name: 'CS-5', stakeholder: 'Cody Belseager', security_status: 'Outstanding', shares: '100', price: '1.00', transaction_value: '100.00', issue_date: '12/12/2019', status: 'unchecked', sign_status: 'officerSignature' },
                { security_name: 'CS-5', stakeholder: 'Cody Belseager', security_status: 'Outstanding', shares: '100', price: '1.00', transaction_value: '100.00', issue_date: '12/12/2019', status: 'unchecked', sign_status: 'holderSignature' },

            ],
            data: [],
            checkedId: [],
            checkedDraftSecurity: [],
            draftSharesRequiredKey: [
                "board_approval_date",
                "email",
                "issue_date",
                "relationship",
                "name",
                "quantity",
            ],
            warrantDraftSetList: [],
            planList: [],
            warrantBlockList: [
                { id: "66", block_name: "c", prefix: "22" },
            ],
            formatWarrantBlockList: {
                66: { id: "66", block_name: "cccccc", prefix: "22" },
            },
            cnSecuritiesList: [
                { id: 3, custom_label_id: 4 },
            ],
            currencyList: ['USD', 'HKD', 'SGD',],
            schedule: [
                { id: 1, schedule_name: "" },
            ],
            pageData: {
                name: "Warrant",
                title: "Warrant",
                text: "warrant",
                error_status: 0,
                checked_count: "",
                type: 3
            },
            dateFormat: 'DD/MM/YYYY',
            genderList: [
                {
                    name: 'Female'
                },
                {
                    name: 'Male'
                },
                {
                    name: 'Non-binary / non-conforming'
                },
                {
                    name:'Prefer not to respond'
                },
            ],
            stakeholderType: [
                {
                    name: __('Individual'),
                    value: 1
                },
                {
                    name: __('Non-individual'),
                    value: 0
                },
            ],
        }
    }
    transferDraftSetData = (key, refName, e) => {
        this.refs[refName].initData(key, this.state.warrantDraftSetList[key]);
    }
    getCheckedId = () => {
        this.refs.deleteDraftSecurity.getCheckedId();
    }
    getDeleteDraftId = () => {
        this.refs.deleteDraftSecurity.getDeleteDraftId();
    }
    changeStatus = (key) => {
        var securityLists = this.state.data;
        if (securityLists[key].checkbox_status == false) {
            securityLists[key].checkbox_status = true;
            if (securityLists[key].is_show_error == 1) {
                $(".draft_error_shares_tip" + key).removeClass("showErrorTip")
            }
        } else {
            securityLists[key].checkbox_status = false;
            if (securityLists[key].is_show_error == 1) {
                $(".draft_error_shares_tip" + key).addClass("showErrorTip")
            }
            $('#cs_check01').prop("checked", false);
        }
        this.setState({
            data: securityLists,
        })
    }

    /**
     * 更新table checkbox
     * @param selectCount 选中总计
     * @param selectIndexArray 有更新的checkbox下标和状态
     * @param isEmpty checkbox是否为全不选状态
     */
    updateSelectCount = (selectCount, selectIndexArray, isEmpty = false) => {
        const { data, listKey } = this.state;
        let list = [...data];
        if (isEmpty) {
            list.forEach((v, k) => {
                list[k]['checkbox_status'] = false;
            })
        } else {
            selectIndexArray.forEach((v, k) => {
                list[v.selectIndex]['checkbox_status'] = v.checked;
            })
        }
        this.setState(() => ({
            selectCount,
            selectIndex: selectIndexArray[0],
            data: list,
            listKey: selectCount > 0 ? -1 : listKey,
        }))
    }

    quanXuan = () => {
        var securityLists = this.state.data;
        if ($('#cs_check01').prop("checked")) {
            $('.listCheckbox').prop('checked', true);
            for (let i = 0; i < securityLists.length; i++) {
                securityLists[i].checkbox_status = true;
            }
        } else {
            $('.listCheckbox').prop('checked', false);
            for (let i = 0; i < securityLists.length; i++) {
                securityLists[i].checkbox_status = false;
            }
        }
        this.setState({
            data: securityLists
        })
    }
    componentDidMount() {
        this.getWarrantDraftSetList();
    }
    getWarrantDraftSetList = () => {
        post('getCoinDraftVestingSetList').then((response) => {
            if (response.data.code === 0) {
                for (let i = 0; i < response.data.data.draftList.length; i++) {
                    response.data.data.draftList[i].checkbox_status = false;
                    response.data.data.draftList[i].is_show_error = 0;
                    response.data.data.draftList[i].vesting_start_date = response.data.data.draftList[i].vesting_start_date ;
                }

                this.setState({
                    planList: response.data.data.planList,
                    warrantDraftSetList: response.data.data.setList,
                    data: response.data.data.draftList,
                    draft_id: response.data.data.defaultSetInfo.id,
                    draft_name: response.data.data.defaultSetInfo.name,
                    warrantBlockList: response.data.data.warrantBlockList,
                    documentSet: response.data.data.documentSet || [],
                    isESign: I(response.data.data.e_sign, 0),
                    // shareClassList:response.data.data.shareClassList,
                    // formatWarrantBlockList: formatObj,
                    cnSecuritiesList: response.data.data.cnSecuritiesList,
                    currency: response.data.data.currency,
                    schedule: response.data.data.vestingScheduleList,
                    loadingStatus: 0,
                });
            } else {
                console.log("failed, error code is: " + response.data.code);
                this.setState({
                    loadingStatus: 0,
                })
            }
        }).catch((error) => {
            console.log(error);
            this.setState({
                loadingStatus: 0,
            })
        });
    }
    recordData = (e) => {
        let formatWarrantBlockList = this.state.formatWarrantBlockList;
        let listKey = this.state.listKey;
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;

        curData[listKey][dataKey] = dataVal;
        if (dataKey == "token_plan_id") {
            curData[listKey].token_plan_id = dataVal;
        }
        if (dataKey == 'document_set_id') {
            const { documentSet = [] } = this.state
            const currentItem = documentSet.find(i => i.id == dataVal)
            if (currentItem) {
                curData[listKey] = {
                    ...curData[listKey],
                    additional_document: currentItem?.additional_document,
                    form_of_exercise_agreement: currentItem?.form_of_exercise_agreement,
                    grant_letter: currentItem?.grant_letter,
                    token_incentive_plan: currentItem?.token_incentive_plan,
                }
            }
        }
        this.setState({
            data: curData
        })
        if (dataKey == "token_plan_id" || dataKey == "relationship" || dataKey == "vesting_schedule_id") {
            this.saveDraft();
        }
    }
    recordNumber = (e) => {
        let isFractionalShare = security.companyInfo.fractional_control;
        let listKey = this.state.listKey;
        let curData = this.state.data;
        let curVal = e.target.value;
        let dataKey = e.target.name;
        if (dataKey == "quantity") {
            if (isFractionalShare == 1) {
                curData[listKey][dataKey] = Utils.check5DecimalNumInputWithoutComma(curVal);
            } else {
                curData[listKey][dataKey] = Utils.checkNumInput(curVal);
            }
        } else if (dataKey == "exercise_price") {
            curData[listKey][dataKey] = Utils.check5DecimalNumInputWithoutComma(curVal);
        } else {
            curData[listKey][dataKey] = Utils.check2DecimalNumInputWithoutComma(curVal);
        }
        this.setState({
            data: curData
        });
    }
    setDataFormat = (e) => {
        let listKey = this.state.listKey;
        let curData = this.state.data;
        let curVal = e.target.value;
        let dataKey = e.target.name;
        curData[listKey][dataKey] = Utils.resetNumberWithCommas(curVal);
        this.setState({
            data: curData
        });
    }
    resetDataFormat = (e) => {
        let isFractionalShare = security.companyInfo.fractional_control;
        let listKey = this.state.listKey;
        let curData = this.state.data;
        let curVal = e.target.value;
        let dataKey = e.target.name;
        if (dataKey == "quantity") {
            if (isFractionalShare == 1) {
                curData[listKey][dataKey] = formatNumber(curVal, 5);
            } else {
                curData[listKey][dataKey] = formatNumber(curVal, null);
            }
        } else if (dataKey == "exercise_price") {
            curData[listKey][dataKey] = formatNumber(curVal, null);
        } else {
            curData[listKey][dataKey] = formatNumber(curVal);
        }
        this.setState({
            data: curData
        });
        this.saveDraft(e);
    }
    changeDraftId = (key1, key2) => {
        this.setState({
            draft_id: key1,
            draft_name: key2
        })
    }
    changeCheckedStatus = (key) => {
        let listKey = this.state.listKey;
        let curChecked = this.state.data;
        curChecked[listKey][key].checked = !curChecked[listKey][key].checked;
        this.setState({
            data: curChecked
        })
    }
    recordChecked = () => {
        let listKey = this.state.listKey;
        let curData = this.state.data;
        if (curData[listKey].is_certificated == 0) {
            curData[listKey].is_certificated == 1
        } else {
            curData[listKey].is_certificated == 0
        }
        this.setState({
            data: curData
        })
        this.saveDraft();
    }
    fileSelect = (e) => {
        let fileObj = e.target.files[0];
        let listKey = this.state.listKey;
        let dataKey = e.target.name;
        Utils.uploadFile(fileObj).then((result) => {
            let curData = this.state.data;
            if (dataKey == "additional_document") {
                let additionalDoc = { name: fileObj.name, file_obj: result };
                if (curData[listKey].additional_document[0].name == '') {
                    curData[listKey].additional_document[0] = additionalDoc;
                    $(".additional_document0").removeClass("hidden");
                } else {
                    curData[listKey].additional_document.push(additionalDoc);
                }
            } else {
                curData[listKey][dataKey].name = fileObj.name;
                curData[listKey][dataKey].file_obj = result;
            }
            this.setState({
                data: curData
            });
            this.saveDraft();
        })
    }
    fileDelete = (key, additionalKey) => {
        let listKey = this.state.listKey;
        this.refs[key].value = "";
        let curData = this.state.data;
        if (additionalKey != -1) {
            if (curData[listKey][key].length == 1) {
                curData[listKey][key][0].name = "";
                curData[listKey][key][0].file_obj = "";
            } else {
                curData[listKey][key].splice(additionalKey, 1)
            }
        } else {
            curData[listKey][key].name = "";
            curData[listKey][key].file_obj = "";
        }
        this.setState({
            data: curData
        })
        this.saveDraft();
    }
    createWarrantDraft = () => {
        if (this.state.addingStatus == 1) {
            return;
        }
        this.setState({
            addingStatus: 1
        })
        let curData = this.state.data;
        for (let i = 0; i < this.state.data.length; i++) {
            this.state.data[i].checkbox_status = false
        }
        this.setState({
            data: curData
        });
        post('createCoinVestingDraft', { draft_set_id: this.state.draft_id }).then((response) => {
            let curData = this.state.data;
            let draftSetList = this.state.warrantDraftSetList;
            let setId = this.state.draft_id;
            if (response.data.code === 0) {
                response.data.data.checkbox_status = false;
                response.data.data.is_show_error = 0;
                curData.push(response.data.data);
                for (let i = 0; i < curData.length; i++) {
                    curData[i].is_show_error = 0
                }
                for (let j = 0; j < draftSetList.length; j++) {
                    if (draftSetList[j].id == setId) {
                        draftSetList[j].count = parseInt(draftSetList[j].count) + 1
                    }
                }
                this.setState({
                    data: curData,
                    addingStatus: 0,
                    // listKey: this.state.data.length - 1,
                    warrantDraftSetList: draftSetList
                })
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    transferKey = (key) => {
        const { data, listKey } = this.state;
        let checked = false;
        let curKey = listKey;
        let list = [...data];
        if (curKey == key) {
            curKey = -1;
            this.setState({
                listKey: curKey,
            })
        } else {
            curKey = key;
            list.forEach((v, k)=>{
                v.checkbox_status = false;
                list[k] = {...v};
            });
            $('.collapse').removeClass('in');
            this.setState({
                listKey: curKey,
                data: list,
            })
        }
        // 这里
        const { close, $formutil, history } = this.props
		const { $params, $setValues } = $formutil
        const currentData = this.state.data?.[curKey] || {}
        $setValues({destination_stakeholder_id: currentData?.destination_stakeholder_id, original_wallet_address: currentData?.original_wallet_address, origin_stakeholder_id: currentData?.origin_stakeholder_id, destination_wallet_address: currentData?.destination_wallet_address})
    }
    selectDraftSet = (key) => {
        let curDraftList = this.state.warrantDraftSetList;
        this.setState({
            draft_name: curDraftList[key].name,
            draft_id: curDraftList[key].id,
            listKey: -1
        });
        post('getCoinDraftVestingListById', { id: curDraftList[key].id }).then((response) => {
            if (response.data.code === 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    response.data.data[i].checkbox_status = false;
                    response.data.data[i].is_show_error = 0;
                }
                this.setState({
                    data: response.data.data,
                    listKey: -1
                })
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    getDraftSecurityList = () => {
        this.refreshDraftSecurityList();
        this.setState({listKey: -1})
    }

    refreshDraftSecurityList = () => {
        const { close, $formutil, history } = this.props
		const { $params, $setValues } = $formutil

        post('getCoinDraftVestingListById', { id: this.state.draft_id }).then((response) => {
            if (response.data.code === 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    response.data.data[i].checkbox_status = false;
                    response.data.data[i].is_show_error = 0;
                }
                this.setState({
                    data: response.data.data,
                })
                const currentData = response.data.data[listKey]
                $setValues({destination_stakeholder_id: currentData?.destination_stakeholder_id, original_wallet_address: currentData?.original_wallet_address, origin_stakeholder_id: currentData?.origin_stakeholder_id, destination_wallet_address: currentData?.destination_wallet_address})
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    saveDraft = (e) => {
        if (this.state.saveStatus == 1) {
            return;
        }
        this.setState({
            saveStatus: 0
        })
        let requestData = { ...this.state.data[this.state.listKey] };
        // requestData.relationship_list = JSON.stringify(requestData.relationship_list)
        // requestData.job_title_list = JSON.stringify(requestData.job_title_list)
        post('modifyCoinVestingDraft', requestData).then((response) => {
            if (response.data.code === 0) {
                if (response.data.data.certificate) {
                    requestData['certificate'] = response.data.data.certificate;
                    this.refreshDraftSecurityList();
                }
                this.setState({
                    saveStatus: 1
                })
                this.getWarrantDraftSetList();
                setTimeout(() => {
                    this.setState({
                        saveStatus: -1
                    });
                }, 300)
            } else {
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            this.setState({
                saveStatus: -1
            });
            console.log(error);
        });
    }

    /**
     * 更新日历
     * @param key
     * @param moment_key
     * @param date
     */
    handleDraftWarrantDateChange = (key, moment_key, date) => {
        const { data, listKey, dateFormat } = this.state;
        let curData = [...data];
        curData[listKey][key] = date?.format(dateFormat);
        curData[listKey][moment_key] = date;
        this.setState({
            data: curData
        });
        this.saveDraft();
    }

    removeSuccessData = (result) => {
        //第一步, 将需要删除的id拿到
        let delIds = [];
        for (let k in result) {
            delIds.push(parseInt(k));
        }
        // 将delIds正序排列
        delIds.sort();
        //开始删除
        let curData = [...this.state.data];
        let newData = [...this.state.data];
        for (let i = curData.length - 1; i >= 0; i--) {
            for (let j = 0; j < delIds.length; j++) {
                if (curData[i].id == delIds[j]) {
                    newData.splice(i, 1);
                }
            }
        }
        this.setState({
            data: newData,
            listKey: -1
        })
    }
    getErrorMessage = (result) => {
        this.setState({
            sendResult: result,
        })
        let curData = [...this.state.data];
        for (let i = 0; i < this.state.data.length; i++) {
            if (this.state.sendResult.hasOwnProperty(this.state.data[i].id)) {
                this.state.data[i].is_show_error = 1
            }
        }
        this.setState({
            data: curData
        })
    }
    selectCount = () => {
        let selectLength = this.state.pageData;
        let selectCount = 0;
        for (let j = 0; j < this.state.data.length; j++) {
            if (this.state.data[j].checkbox_status === true) {
                selectCount++;
            }
        }
        selectLength.checked_count = selectCount;
        this.setState({
            pageData: selectLength
        })
        this.refs.sendForSignature.getSignatureAdminList();
        this.refs.sendForSignature.getCheckedId();
    }
    handleSelectChange = (name, data) => {
        let curData = this.state.data;
        let listKey = this.state.listKey;
        if (data) {
            curData[listKey].name = data.nick_name || "";
            curData[listKey].email = data.email || "";
        }
        this.setState({
            selectedData: data,
            data: curData
        })
    }
    CheckboxChange(isChecked) {
        const { data, listKey } = this.state;
        let list = [...data];
        if (isChecked == 1) {
            list[listKey].relationship_list = [
                { effective_date: '', relationship: "" },
            ]
            list[listKey].job_title_list = [
                { effective_date: '', job_title: "" },
            ]
            list[listKey].employee_id = ''
            list[listKey].passport = ''
            list[listKey].date_of_birth = ''
            list[listKey].gender = ''
            list[listKey].annual_salary = ''
            list[listKey].country = ''
            list[listKey].address_details = ''
            list[listKey].name = ''
            list[listKey].email = ''
        } else {
            list[listKey].name = ''
            list[listKey].email = ''
            list[listKey].relationship_list = [
                { effective_date: Utils.getCurDate(), relationship: "Investor" },
            ]
            list[listKey].job_title_list = [
                { effective_date: Utils.getCurDate(), job_title: "" },
            ]
        }
        list[listKey].choose_type = isChecked
        this.setState({
            data: list
        })
        this.saveDraft()
    }
    relationshipEffctiveDateChange = (listName, name, key, date) => {
        if (!date) {
            return
        }
        const { data, dateFormat, listKey } = this.state;
        let curData = this.state.data[listKey];
        curData[listName][key][name] = date && formatDate(date);
        data[listKey] = curData
        this.setState({
            data
        });
    }
    setRelation = (key, e) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        curList.relationship_list[key].relationship = e.target.value;
        data[listKey] = curList
        this.setState({
            data
        })

    }
    addRelation = () => {
        const { data, listKey } = this.state;
        let one = { effective_date: Utils.getCurDate(), relationship: "Investor" }
        let curList = this.state.data[listKey];
        curList.relationship_list.push(one);
        data[listKey] = curList
        this.setState({
            data
        })
    }
    delRelation = (num) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        if (curList.relationship_list.length <= 1) {
            return;
        }
        curList.relationship_list.splice(num, 1);
        data[listKey] = curList
        this.setState({
            data
        })
    }


    JobRecordData = (key, e) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        curList.job_title_list[key].job_title = e.target.value;
        data[listKey] = curList
        this.setState({
            data
        })
    }
    delJobTitle = (num) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        if (curList.job_title_list.length <= 1) {
            return;
        }
        curList.job_title_list.splice(num, 1);
        data[listKey] = curList
        this.setState({
            data
        })
        this.saveDraft()
    }
    addJobTitle = () => {
        let one = { effective_date: Utils.getCurDate(), job_title: "" }
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        curList.job_title_list.push(one);
        data[listKey] = curList
        this.setState({
            data
        })
    }
    countryRecordData = (val) => {
        const { data, listKey } = this.state;
        let curList = this.state.data[listKey];
        curList['country'] = val
        data[listKey] = curList
        this.setState({
            data
        })
    }
    bindBatchUploadModel = () => {
        this.modalRef = Modal.open({
            component: props => <ModalContent close={props.close} title={__('Bulk upload')}>
                <TokenVestingBatchUploadModel close={props.close} callback={this.getWarrantDraftSetList} />
            </ModalContent>,
            className: 'sprout-modal email-modal',
            width: 1300
        })
    }
    getOriginAddress = async (id) => {
		try {
			const { data: resData } = await post('getWalletAddressListById', { stakeholder_id: id })

			const { code, data } = resData || {}

			if (code === 0) {
				this.setState({ originWalletAddressList: data.list })
			} else {
				message.error(data)
			}
		} catch (e) {
			message.error(e.message)
		}

	}

    getDestinationAddress = async (id) => {
		try {
			const { data: resData } = await post('getWalletAddressListById', { stakeholder_id: id })

			const { code, data } = resData || {}

			if (code === 0) {
				this.setState({ destinationWalletAddressList: data.list })
			} else {
				message.error(data)
			}
		} catch (e) {
			message.error(e.message)
		}
	}

    handleAutoSave = (type, val) => {
        const { $params } = this.props.$formutil

        const editData = { ...this.state.data[this.state.listKey], ...$params, [type]: val };
        post('modifyCoinVestingDraft', editData).then((response) => {
            if (response.data.code === 0) {
                if (response.data.data.certificate) {
                    editData['certificate'] = response.data.data.certificate;
                    this.refreshDraftSecurityList();
                }
                this.setState({
                    saveStatus: 1
                })
                this.getWarrantDraftSetList();
                setTimeout(() => {
                    this.setState({
                        saveStatus: -1
                    });
                }, 300)
            } else {
                this.setState({
                    saveStatus: -1
                });
                console.log("failed, error code is: " + response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    renderOriginDrop = menu => {

		const { origin_stakeholder_id } = this.props.$formutil || {}
		return <>
			<Button type="link" onClick={() => openAssignToken(origin_stakeholder_id, () => this.getOriginAddress(origin_stakeholder_id))}>
				<PlusOutlined />{__('Add New')}
			</Button>
			{menu}
		</>
	}

    renderDestinationDrop = menu => {
		const { $params } = this.props.$formutil
		const { destination_stakeholder_id } = $params || {}
		return <>
			<Button type="link" onClick={() => openAssignToken(destination_stakeholder_id, () => this.getDestinationAddress(destination_stakeholder_id))}>
				<PlusOutlined />{__('Add New')}
			</Button>
			{menu}
		</>
	}

    bindManageTemplates = () => {
        this.props.history.push({ pathname: '/home/securities/templates', state: { activeKey: '1' } })
    }

    closeDraftLeft = () => {
        this.setState({ listKey: -1 });
    }

    render() {
        const { data: formData, $formutil } = this.props;
        const { $params, $setValues } = $formutil
        if (this.state.addingStatus === 1) {
            return (
                <div className="loading_position">
                    <Loading />
                </div>
            )
        }
        let selectCount = 0;
        let listKey = this.state.listKey;
        const choosenKey = []
        if (this.state.data.length > 0) {
            for (let j = 0; j < this.state.data.length; j++) {
                if (this.state.data[j].checkbox_status == true) {
                    selectCount++;
                    choosenKey.push(this.state.data[j].id)
                }
            }
        }


        let actionButtonClass = 'btn action-disabledBtn action-btn dropdown-toggle';
        let sendButtonClass = 'btn boxbtn-to send-newdisabledBtn';

        if (selectCount >= 1) {
            actionButtonClass = 'btn action-availableBtn action-btn dropdown-toggle';
            sendButtonClass = 'btn boxbtn-to send-newavailableBtn';
        }
        const { currency = '', data, formatWarrantBlockList, dateFormat, genderList, stakeholderType, originWalletAddressList, destinationWalletAddressList, documentSet, isESign, planList } = this.state;
        const certificateKey = planList.findIndex(v=>v?.id == data[listKey]?.token_plan_id);
        return (
            <div className="draftsContent">
                <RenameDraftSet ref="renameDraftSet" getDraftSecurityList={this.getDraftSecurityList} getDraftSetList={this.getWarrantDraftSetList} draft_name={this.state.draft_name} draft_id={this.state.draft_id} changeDraftId={this.changeDraftId} />
                <DeleteDraftSet ref="deleteDraftSet" choosenKey={choosenKey} getDraftSecurityList={this.getDraftSecurityList} getWarrantDraftSetList={this.getWarrantDraftSetList} draft_name={this.state.draft_name} draft_id={this.state.draft_id} changeDraftId={this.changeDraftId} />
                {/* <DeleteDraftSecurity ref="deleteDraftSecurity" curData={this.state.data} getDraftSecurityList={this.getDraftSecurityList} listKey={this.state.listKey} /> */}
                <CreateDraftSet type={this.state.type} setList={this.state.warrantDraftSetList} getDraftSecurityList={this.getDraftSecurityList} getWarrantDraftSetList={this.getWarrantDraftSetList} draft_name={this.state.draft_name} draft_id={this.state.draft_id} changeDraftId={this.changeDraftId} />
                <SendInfoForSignature curPlanId={this.state.curPlanId} removeSuccessData={this.removeSuccessData} getErrorMessage={this.getErrorMessage} ref="sendForSignature" pageData={this.state.pageData} curData={this.state.data} />

                <div className="">
                    <div className={`prefer-left ${listKey < 0 && 'prefer-left-max'}`}>
                        <div className="holders-nav minWidth400 clearfix">
                            <div className="action-box onset">
                                <div className="btn-group">
                                    <button type="button" className={actionButtonClass} data-toggle="dropdown">{__('Actions')}<span className="triangle triangle-whitedown action-triangle"></span></button>
                                    {selectCount >= 1 &&
                                        <ul className="dropdown-menu mange-downmenu action-downmenu">
                                            <Permission type="securities_draftWarrants_delete" hide><li><a data-toggle="modal" data-target={"#deleteDraftSetModal"} >{__('Delete')}</a></li></Permission>
                                        </ul>
                                    }
                                </div>
                                <div className="btn-group drafts-btn-group draft_share_btnGroup">
                                    <a className="dropdown-toggle text-blue blue-triangle-down drafts-btn" data-toggle="dropdown">{this.state.draft_name}&nbsp;&nbsp;</a>
                                    <ul className="dropdown-menu drafts-downmenu">
                                        {this.state.warrantDraftSetList.map((value, key) => {
                                            return (
                                                <li key={key}>
                                                    <a className="resetDrafts" value={value.id} onClick={this.selectDraftSet.bind(this, key)}>{value.name}
                                                        {/*{value.count >0 &&*/}
                                                        <span className="drafts-trash-can glyphicon glyphicon-trash" type="button" onClick={this.transferDraftSetData.bind(this, key, "deleteDraftSet")} data-toggle="modal" data-target="#deleteDraftSetModal"></span>
                                                        {/*}*/}
                                                        <span className="drafts-icon" type="button" onClick={this.transferDraftSetData.bind(this, key, "renameDraftSet")} data-toggle="modal" data-target="#enterNewNameForDraftsModal">{value.count}</span>
                                                    </a>
                                                </li>
                                            )
                                        })}
                                        <li><a data-toggle="modal" data-target="#createNewDraftSetModal">{__('Create a new draft set')}</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="form-group search-group banner-rightBox offset">
                                <Permission type="securities_draftWarrants_addWarrant" hide>
                                    <div className="btn-group marginLf10">
                                        <a className="btn boxbtn-to send-availableBtn" onClick={this.bindBatchUploadModel}>{__('Bulk upload')}</a>
                                    </div>
                                    <div className="btn-group marginLf10">
                                        <a className="btn boxbtn-to bg-darkblue" onClick={this.createWarrantDraft}>+ {__('Add option grant')}</a>
                                    </div>
                                </Permission>
                                <Permission type="securities_draftWarrants_send" hide>
                                    <div className="btn-group marginLf10">
                                        {selectCount >= 1 && <a onClick={this.selectCount} data-toggle="modal" data-target="#sendInfoForSignatureModal" className={sendButtonClass}>{__('Send')}</a>}
                                        {selectCount == 0 && <a className={sendButtonClass}>{__('Send')}</a>}
                                    </div>
                                </Permission>
                            </div>
                        </div>
                        {this.state.loadingStatus === 1 &&
                            <div className="loading_position">
                                <Loading />
                            </div>
                        }
                        {this.state.loadingStatus === 0 &&
                            <div>
                                {this.state.data.length == 0 &&
                                    <div className="empty_option ">
                                        <div className="empty_option_box marginTop80">
                                            <span className="empty_option_icon bg_draft_file"></span>
                                            <h5 className="empty_option_text">{__('This set has no vesting.')}</h5>
                                            <Permission type="securities_draftWarrants_addWarrant" hide>
                                                <button className="btn action-availableBtn action-btn white-text font_weight500"
                                                    onClick={this.createWarrantDraft}>
                                                        {__('Add option grant')}
                                                </button>
                                            </Permission>
                                        </div>
                                    </div>
                                }
                                {this.state.data.length >= 1 &&
                                    <div className="draftList">
                                        <div className="scroll-div">
                                            <DraftWarrantsTable
                                                data={data}
                                                updateSelectCount={this.updateSelectCount}
                                                listKey={listKey}
                                                transferKey={this.transferKey}
                                                formatWarrantBlockList={formatWarrantBlockList}
                                            />

                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <div className={`prefer-right ${listKey < 0 && 'prefer-right-max'}`}>
                        <div>
                            {this.state.listKey == -1 &&
                                <div className="empty_option">
                                    <div className="empty_option_box marginTop186">
                                        <span className="edit-icon"></span>
                                        <h5 className="edit_text font_weight500">{__('Select a draft to edit')}</h5>
                                    </div>
                                </div>
                            }
                            {this.state.listKey >= 0 &&
                                <div>
                                    {this.state.data[listKey].is_show_error == 1 &&
                                        <div className="error_details error_tip_bar bg-lightred alert alert-dismissible" role="alert">
                                            <div className="error_icon_box bg-redbtn">
                                                <ExclamationCircleOutlined style={{color:'#d42f2e'}} />
                                            </div>
                                            <div className="error_tip_text bg-lightred font_weight500">
                                                <h5 className="error_tip_text">{__('There were some problems with this certificate.')}</h5>
                                                <div className="error_list">
                                                    {this.state.sendResult && this.state.sendResult[this.state.data[listKey].id].error_reason.map((val, k) => {
                                                        return (
                                                            <p className="marginTop10 mb-0" key={k}>{val.title}: {val.message}</p>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            <div className="error_close bg-redbtn">
                                                <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                            </div>
                                        </div>
                                    }
                                    {this.state.saveStatus == 0 &&
                                        <div className="banner-download draft_save_banner bg-apple-green no-marginBottom ">
                                            <div className="circle-border draft-circle-border"></div>
                                            <p className="download-message">{__('Saving')}</p>
                                        </div>
                                    }
                                    {this.state.saveStatus == 1 &&
                                        <div className="banner-download draft_save_banner bg-apple-green no-marginBottom ">
                                            <span className="colorWhite glyphicon glyphicon-ok"></span>
                                            <p className="download-message">{__('Draft saved')}</p>
                                        </div>
                                    }
                                    {/* 编辑从这里开始 */}
                                    <ul>
                                        <li className="draft-titLi clearfix">
                                            <div className="draft-titleLi-group">
                                                <p className="onset">{__('Vesting details')} ({planList?.[certificateKey]?.prefix}-{data[listKey]?.vesting_id})</p>
                                                <CloseOutlined onClick={this.closeDraftLeft} />
                                            </div>
                                        </li>
                                        <li>
                                            <a className="prefer-togglebtn" href="#warrants_prefix_li" data-toggle="collapse">{__('Vesting')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                            <div className="collapse" id="warrants_prefix_li">
                                                <form className="row margin0">
                                                    <div className="form-group col-xs-6">
                                                        <label htmlFor="warrants_prefix">{__('Plan name')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                        <select id="warrants_prefix" name="token_plan_id" value={this.state.data[listKey].token_plan_id} onChange={this.recordData} className="form-control select-container">
                                                            {this.state.planList?.length > 0 && this.state.planList.map((value, key) => {
                                                                return (
                                                                    <option key={key} value={value.id} >{value.name} ({value.prefix})</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-xs-6">
                                                        <label htmlFor="draft_warrant">{__('Vesting ID')} <i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                        <input id="draft_warrant" onBlur={this.saveDraft} name="vesting_id" value={this.state.data[listKey].vesting_id} onChange={this.recordData} type="text" className="form-control" />
                                                    </div>

                                                </form>
                                            </div>
                                        </li>
                                        <li>
                                            <a className="prefer-togglebtn" href="#option_value_li" data-toggle="collapse">{__('Transaction value')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                            <div className="collapse" id="option_value_li">
                                                <div className="row margin0">
                                                    <div className="form-group col-xs-6">
                                                        <label htmlFor="quantity">{__('Issued quantity')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                        <input type="text" id="quantity" onFocus={this.setDataFormat} onBlur={this.resetDataFormat} name="quantity" value={I(this.state.data[listKey].quantity, '')} onChange={this.recordNumber} className="form-control" />
                                                    </div>
                                                    <div className="form-group col-xs-6">
                                                        <label htmlFor="warrants_purchase_price">{__('Grant date')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                        <DateSelector
                                                            onChange={this.handleDraftWarrantDateChange.bind(this, 'grant_date', 'grant_date_moment')}
                                                            value={I(data[listKey]['grant_date_moment'], I(data[listKey]['grant_date'], '') ? moment(data[listKey]['grant_date'], dateFormat) : '')}
                                                            inputReadOnly={false}
                                                        />
                                                    </div>
                                                    <div className="form-group col-xs-6">
                                                        <label htmlFor="warrants_purchase_price">{__('Cash value')}<i className="text-muted"><small> - {__('Optional')}</small></i></label>
                                                        <Input name="cash_value" id="cash_value" addonBefore={security.companyInfo.currency} defaultValue="0" onFocus={this.setDataFormat} onBlur={this.resetDataFormat} value={I(this.state.data[listKey].cash_value, '')} onChange={this.recordNumber} />
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <a className="prefer-togglebtn" href="#optionHolder" data-toggle="collapse">{__('Stakeholder/Wallet name')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                            <div className="collapse" id="optionHolder">
                                                <div className="row margin0">
                                                    <div className="form-group col-xs-6 form-add-label">
                                                    <FormItem
                                                        style={{ flexDirection: 'column', alignContent: 'stretch', alignItems: 'flex-start' }}
                                                        name="origin_stakeholder_id"
                                                        label={<>{__("Origin name")}<i className="text-muted"><small> - {__('Required')}</small></i></>}
                                                        colon={false}
                                                        className="assign-name-item"
                                                        validMessage={{
                                                            required: __('Origin name is required')
                                                        }}
                                                    >
                                                        <SelectStakeholder history={this.props.history} style={{ width: '200px' }} showAdd includeLabel placeholder="Select" onSelect={
                                                            (val) => {
                                                                $setValues({ original_wallet_address: null });
                                                                this.getOriginAddress(val)
                                                                this.handleAutoSave('origin_stakeholder_id', val)
                                                            }
                                                        } />
                                                    </FormItem>
                                                    </div>
                                                    <div className="form-group col-xs-6 form-add-label">
                                                        <FormItem
                                                            style={{ flexDirection: 'column', alignContent: 'stretch', alignItems: 'flex-start' }}
                                                            name="original_wallet_address"
                                                            required
                                                            label={<>{__("Origin wallet")}<i className="text-muted"><small> - {__('Required')}</small></i></>}
                                                            colon={false}
                                                            className={"assign-name-item"}
                                                            validMessage={{
                                                                required: __('Origin wallet is required')
                                                            }}
                                                        >
                                                            <Select style={{ width: '200px' }} showSearch placeholder={__("Select an address")} disabled={!$params?.origin_stakeholder_id} dropdownRender={this.renderOriginDrop} onSelect={
                                                            (val) => {
                                                                // $setValues({ original_wallet_address: null });
                                                                // this.getOriginAddress(val)
                                                                this.handleAutoSave('original_wallet_address', val)
                                                            }
                                                        }>
                                                                {originWalletAddressList && originWalletAddressList.map((val, index) => <Option key={index} value={val}>{val}</Option>)}
                                                            </Select>
                                                        </FormItem>
                                                    </div>
                                                </div>

                                                <div className="row margin0">
                                                    <div className="form-group col-xs-6 form-add-label">
                                                        <FormItem
                                                            style={{ flexDirection: 'column', alignContent: 'stretch', alignItems: 'flex-start' }}
                                                            name="destination_stakeholder_id"
                                                            label={<>{__("Destination name")}<i className="text-muted"><small> - {__('Required')}</small></i></>}
                                                            colon={false}
                                                            className="assign-name-item"
                                                            validMessage={{
                                                                required: __('Destination name is required')
                                                            }}
                                                        >
                                                            <SelectStakeholder style={{ width: '200px' }} showAdd includeLabel placeholder={__("Select")} onSelect={
                                                                (val) => {
                                                                    $setValues({ destination_wallet_address: null });
                                                                    this.handleAutoSave('destination_stakeholder_id', val)
                                                                    this.getDestinationAddress(val)
                                                                }
                                                            } />
                                                        </FormItem>
                                                    </div>
                                                    <div className="form-group col-xs-6 form-add-label">
                                                    <FormItem
                                                        style={{ flexDirection: 'column', alignContent: 'stretch', alignItems: 'flex-start' }}
                                                        name="destination_wallet_address"
                                                        required
                                                        label={<>{__("Destination wallet")}<i className="text-muted"><small> - {__('Required')}</small></i></>}
                                                        colon={false}
                                                        className={"assign-name-item"}
                                                        validMessage={{
                                                            required: __('Destination wallet is required')
                                                        }}
                                                    >
                                                        <Select style={{ width: '200px' }} showSearch placeholder={__("Select an address")} disabled={!$params?.destination_stakeholder_id} dropdownRender={this.renderDestinationDrop} onSelect={
                                                            (val) => {
                                                                // $setValues({ original_wallet_address: null });
                                                                // this.getOriginAddress(val)
                                                                this.handleAutoSave('destination_wallet_address', val)
                                                            }
                                                        }>
                                                            {destinationWalletAddressList && destinationWalletAddressList.map((val, index) => <Option key={index} value={val}>{val}</Option>)}
                                                        </Select>
                                                    </FormItem>
                                                    </div>
                                                </div>

                                            </div>
                                        </li>
                                        <li>
                                            <a className="prefer-togglebtn" href="#warrants_vesting_schedule_li" data-toggle="collapse">{__('Vesting schedule')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                            <div className="collapse" id="warrants_vesting_schedule_li">
                                                <form>
                                                    <div className="row margin0">
                                                        <div className="form-group col-xs-6 ">
                                                            <label htmlFor="warrants_schedule">{__('Schedule')}</label>
                                                            <select id="warrants_schedule" className="form-control select-container" onBlur={this.saveDraft} value={this.state.data[listKey].vesting_schedule_id} name="vesting_schedule_id" onChange={this.recordData}>
                                                                <option value="">{__('No schedule')}</option>
                                                                {this.state.schedule.map((value, key) => {
                                                                    return (
                                                                        <option id="vesting_schedule_name" key={key} value={value.id}>{value.schedule_name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                        {this.state.data[listKey].vesting_schedule_id != "" && this.state.data[listKey].vesting_schedule_id != 0 &&
                                                            <div className="form-group col-xs-6 ">
                                                                <label htmlFor="warrants_vesting_start_date">{__('Vesting start date')}<i className="text-muted"><small> - {__('Required')}</small></i></label>
                                                                <DateSelector
                                                                    onChange={this.handleDraftWarrantDateChange.bind(this, 'vesting_start_date', 'vesting_start_date_moment')}
                                                                    value={I(data[listKey]['vesting_start_date_moment'], I(data[listKey]['vesting_start_date'], '') ? moment(data[listKey]['vesting_start_date'], dateFormat) : '')}
                                                                    inputReadOnly={false}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </form>
                                            </div>
                                        </li>
                                        <li>
                                            <a className="prefer-togglebtn" href="#warrants_document_note_li" data-toggle="collapse">{__('Document and notes')}<span className="triangle triangle-down prefer-triangle"></span></a>
                                            <div className="collapse" id="warrants_document_note_li">
                                                <form>
                                                    <div className="row margin15">
                                                        <div>
                                                            <div className="form-group col-xs-6 padding0">
                                                                <label htmlFor="document_set_id">{__('Document sets')}</label>
                                                                <select id="document_set_id" name="document_set_id"
                                                                    onBlur={this.saveDraft}
                                                                    value={this.state.data[listKey].document_set_id}
                                                                    onChange={this.recordData}
                                                                    className="form-control select-container">
                                                                    <option value="-3">Custom documents</option>
                                                                    {documentSet.map((value, key) => {
                                                                        return <Fragment>{!isESign && !I(value.is_docusign, '') || isESign ? <option key={key} value={value.id}> {value.is_docusign ? `${value.set_name} (Docusign)` : value.set_name}</option> : ''}</Fragment>
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <div className="form-group col-xs-5 document_set padding0">
                                                                <div className="manage_document" onClick={this.bindManageTemplates}>{__('Manage document set')}</div>
                                                            </div>

                                                            <div className="form-group row marginBott20">
                                                                <label className="col-xs-12 text-muted">{__('Vesting offer letter')}</label>
                                                                <div className="col-xs-4 ">
                                                                    <div className="select-btn select-blue convertiblenote-select-btn">
                                                                        {__('Choose a file')}
                                                                        <label htmlFor="warrant_doc_select0" className="sr-only" >File input</label>
                                                                        <input name="grant_letter" onChange={this.fileSelect} ref="form_of_warrant_file" type="file" id="warrant_doc_select0" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-xs-8 filename-box no-paddingright repur-fileName">
                                                                    {!this.state.data[listKey]?.grant_letter?.name &&
                                                                        <p className="text-muted paddingLeft6">{__('No file selected')}</p>
                                                                    }
                                                                    {!!this.state.data[listKey]?.grant_letter?.name &&
                                                                        <p className="text-muted ">{this.state.data[listKey]?.grant_letter?.name}
                                                                            <DeleteOutlined className="draft_trash" onClick={this.fileDelete.bind(this, 'grant_letter', -1)} />
                                                                        </p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="form-group row marginBott20">
                                                                <label className="col-xs-12 text-muted">{__('Token purchase agreement')}</label>
                                                                <div className="col-xs-4">
                                                                    <div className="select-btn select-blue convertiblenote-select-btn">
                                                                        {__('Choose a file')}
                                                                        <label htmlFor="warrant_doc_select1" className="sr-only" >File input</label>
                                                                        <input name="form_of_exercise_agreement" onChange={this.fileSelect} ref="purchase_agreement_file" type="file" id="warrant_doc_select1" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-xs-8 filename-box no-paddingright repur-fileName">
                                                                    {this.state.data[listKey]?.form_of_exercise_agreement?.name == "" &&
                                                                        <p className="text-muted paddingLeft6">{__('No file selected')}</p>
                                                                    }
                                                                    {this.state.data[listKey]?.form_of_exercise_agreement?.name != "" &&
                                                                        <p className="text-muted ">{this.state.data[listKey].form_of_exercise_agreement?.name}
                                                                            <DeleteOutlined className="draft_trash" onClick={this.fileDelete.bind(this, 'form_of_exercise_agreement', -1)} />
                                                                        </p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="form-group row marginBott20">
                                                                <label className="col-xs-12 text-muted">{__('Token incentive plan')}</label>
                                                                <div className="col-xs-4">
                                                                    <div className="select-btn select-blue convertiblenote-select-btn">
                                                                        {__('Choose a file')}
                                                                        <label htmlFor="warrant_doc_select1" className="sr-only" >File input</label>
                                                                        <input name="token_incentive_plan" onChange={this.fileSelect} ref="purchase_agreement_file" type="file" id="warrant_doc_select1" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-xs-8 filename-box no-paddingright repur-fileName">
                                                                    {this.state.data[listKey]?.token_incentive_plan?.name == "" &&
                                                                        <p className="text-muted paddingLeft6">{__('No file selected')}</p>
                                                                    }
                                                                    {this.state.data[listKey]?.token_incentive_plan?.name != "" &&
                                                                        <p className="text-muted ">{this.state.data[listKey].token_incentive_plan?.name}
                                                                            <DeleteOutlined className="draft_trash" onClick={this.fileDelete.bind(this, 'token_incentive_plan', -1)} />
                                                                        </p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="form-group row marginBott20">
                                                                <label className="text-muted col-xs-12">{__('Additional document')}</label>
                                                                <div className="col-xs-4 no-paddingright">
                                                                    <div className={"select-btn convertiblenote-select-btn select-blue"}>
                                                                        {__('Choose a file')}
                                                                        <label htmlFor="warrant_doc_select2" className="sr-only">File input</label>
                                                                        <input name="additional_document" onChange={this.fileSelect} ref="additional_document" type="file" id="warrant_doc_select2" />
                                                                    </div>
                                                                </div>
                                                                {this.state.data[listKey].additional_document?.length > 0 && this.state.data[listKey].additional_document.map((val, k) => {
                                                                    let isPush = "";
                                                                    let isRow = "";
                                                                    if (k != 0) {
                                                                        isPush = "col-xs-push-4";
                                                                        isRow = "row"
                                                                    }
                                                                    return (
                                                                        <div className={isRow} key={k}>
                                                                            <div className={"col-xs-8 filename-box " + isPush}>
                                                                                {this.state.data[listKey].additional_document[k].name == "" &&
                                                                                    <p className="text-muted paddingLeft6">{__('No file selected')}</p>
                                                                                }
                                                                                {this.state.data[listKey].additional_document[k].name != "" &&
                                                                                    <p className="text-muted paddingLeft6 paddingRight18 ">{this.state.data[listKey].additional_document[k].name}
                                                                                        {/*<span onClick={this.fileDelete.bind(this, 'additional_document', k)} className="draft_trash additional_document glyphicon glyphicon-trash"></span>*/}
                                                                                        <DeleteOutlined className="draft_trash" onClick={this.fileDelete.bind(this, 'additional_document', k)} />
                                                                                    </p>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            }
                        </div>
                    </div >
                </div >
            </div >

        )
    }

}
export const DraftVestingContent = withForm(DraftVesting)

export default DraftVestingContent;

