import React, { Component } from 'react';
import Section from '../components/Section';

import { Table } from 'antd';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import { getYesOrNoText } from 'Utils/constant';


export default function RightsAndPreferences(props) {
	const { dividends = {}, participation = {}, shareClassDetails = {} } = props.data || {}
	
	const shareColumns = [
		{
			title: __('Share class'),
			dataIndex: 'shareClass',

		},
		{
			title: __('Seniority'),
			dataIndex: 'seniority',

		},
		{
			title: __('Original price'),
			dataIndex: 'originalPrice',
			width: 180,
			align: 'center'

		},
		{
			title: __('Conversion price'),
			dataIndex: 'conversionPrice',
			align: 'right',
			render: value => formatFractionalNumber(value)
		},	
		{
			title: __('Conversion ratio'),
			dataIndex: 'conversionRatio',
			align: 'right',
			// render: value => formatNumber(value, 0)
		},	
		{
			title: __('Multiplier'),
			dataIndex: 'multiplier',
			align: 'right',
			// render: value => formatNumber(value, 0)
		},	
	]

	const dividendsColumns = [
		{
			title: __('Share class'),
			dataIndex: 'shareClass'
		},
		{
			title: __('Cumulative'),
			dataIndex: 'cumulative'
		},
		{
			title: __('Dividend rate'),
			dataIndex: 'dividendRate'
		},
		{
			title: __('Dividend accrual'),
			dataIndex: 'dividendAccrual'
		},
		{
			title: __('Compound interest'),
			dataIndex: 'compoundInterest'
		},
		{
			title: __('Compound period'),
			dataIndex: 'compoundPeriod'
		},
	]

	const participationColumns = [
		{
			title: __('Share class'),
			dataIndex: 'shareClass'
		},
		{
			title: __('Participating'),
			dataIndex: 'participating',
			render: value => getYesOrNoText(value)
		},
		{
			title: __('Participating cap'),
			dataIndex: 'participatingCap',
			render: value => getYesOrNoText(value)
		}
	]

	return <div className="ps-rights">
		<div className="certificate-detail-section">
			<Section title={__('Share Class Details')}>
				<div className="ea-transaction-table">
					<Table size="small" columns={shareColumns}  rowKey="stakeholder_id" dataSource= {shareClassDetails.detail || []} pagination={false}/>
				</div>
			</Section>
		</div>
		<div className="certificate-detail-section">

		
			<Section title={__('Dividends')}>
				<div className="ea-transaction-table">
					<Table size="small" columns={dividendsColumns}  rowKey="stakeholder_id" dataSource= {dividends.detail || []} pagination={false}/>
				</div>
			</Section>
		</div>
		<div className="certificate-detail-section">
			<Section title={__('Participation')}>
				<div className="ea-transaction-table">
					<Table size="small" columns={participationColumns}  rowKey="stakeholder_id" dataSource= {participation.detail || []} pagination={false}/>
				</div>
			</Section>
		</div>
	</div>
}