import React, { Component } from 'react';
import { message, Select, Spin, Button, Tooltip } from 'antd';
import { FormItem, withForm } from 'react-antd-formutil';
import { post } from 'srcPath/http';
import Loading from 'components/Loading';
import Permission, { noPermission } from 'components/Permission';

const Option = Select.Option
import './style.scss';
// 提交数据的格式
// board_admins: ["59"]
// certificate1_user_id: "59"
// certificate2_user_id: "63"
// convertibles: "59"
// equity_plan_awards: "59"
// primary_contact: "59"
// stakeholder_relations: "59"

@withForm
export default class Signatories extends Component {
	state = {
		loading: true,
		bordList: [],
		selectOptions: []
	}

	key = 1

	componentDidMount() {
		this.getData()
	}

	getData = async () => {
		this.setState({ loading: true })

		try {
			const { data: resData } = await post('getUserList')

			const { data, code } = resData || {}

			if (code == 0) {
				const { curRoleSettings = {}, legal_admin = [] } = data || {}
				const { $setValues } = this.props.$formutil

				this.setState({
					selectOptions: legal_admin || [],
				}, () => {
					$setValues({ ...curRoleSettings })
				})
			} else {
				message.error(data)
			}
		} catch (err) {
			message.error(err && err.message)
		}

		this.setState({ loading: false })
	}

	onSubmit = async () => {
		const { $formutil, close, data } = this.props
		const { $invalid, $batchDirty, $params, $getFirstError } = $formutil

		if ($invalid) {
			$batchDirty(true)
			message.error($getFirstError())

			return
		}

		this.setState({ loading: true })

		try {
			const { data: resData } = await post('setRolesAndSignatories', $params)
			const { code, data } = resData

			if (code == 200 || code == 0) {
				message.success('Success!')

				this.getData()
			} else {
				message.error(data)
			}
		} catch (e) {
			message.error(e.message)
		}

		this.setState({ loading: false })
	}

	addBoardAdmin = () => {
		const bordList = this.state.bordList

		this.key = this.key + 1

		bordList.push(this.key)

		this.setState({ bordList })
	}

	deleteBoardAdmin = index => {
		const bordList = this.state.bordList

		bordList.splice(index, 1)

		this.setState({ bordList })
	}

	onFieldChange = (val) => {
		const { $setValues, $params, $setDirts } = this.props.$formutil

		if (val === $params.certificate2_user_id) {
			$setValues({ certificate2_user_id: undefined })
		} else {
			$setDirts({ certificate2_user_id: false })
		}
	}

	render() {
		const { loading, bordList, selectOptions } = this.state
		const { $params, $setDirty } = this.props.$formutil
		const board_admins = $params.board_admins || []
		const disabled = noPermission('company_permission_signatories_edit')

		return <div className="sprout-permission-roles">
			<Spin spinning={loading}>
				<div className="sprout-permission-section">
					<div className="permission-section-banner">
						{__('Signatories')}
					</div>
					<div className="permission-section-content">
						<p className="description">
							{__('Signatories will be required to sign securities upon issuance. You can add your company secretary as the 2nd signatory. Signatory privileges must be reassigned before a person can be deleted. To assign a role to a new user, first go back and add the person as a company administrator before you can give them new roles and signatory responsibility.')}
						</p>
						{$params.certificate_type == 1 &&
							<div className="tooltip-parent">
								<FormItem
									name="certificate1_user_id"
									label={__('Certificate')}
									$onFieldChange={this.onFieldChange}
									required
									colon={false}
									// $validators={{
									// 	isSame: val => val !== $params.certificate2_user_id || 'Can not same with Certificate(2nd)'
									// }}							
									validMessage={{
										required: __('Certificate is required'),
									}}
								>
									<Select className="form-length" allowClear placeholder={__('Select')} disabled={disabled}>
										{selectOptions.map((val, index) => <Option key={index} value={val.user_id}>{val.name}, {val.titles}</Option>)}
									</Select>
								</FormItem>
								<Tooltip className='tooltip-title certificate_type' title="Please contact us if you need to change the number of signatories required.">
									<div className="form-email-help-icon">?</div>
								</Tooltip>
								<p className="tips">
									{__('Select a signatory and their title.')}
								</p>
							</div>
						}
						{$params.certificate_type == 2 &&
							<div>
								<div className="tooltip-parent">
									<FormItem
										name="certificate1_user_id"
										label={__('Certificate (1st)')}
										$onFieldChange={this.onFieldChange}
										required
										colon={false}
										// $validators={{
										// 	isSame: val => val !== $params.certificate2_user_id || 'Can not same with Certificate(2nd)'
										// }}							
										validMessage={{
											required: __('Certificate (1st) is required'),
										}}
									>
										<Select className="form-length" allowClear placeholder={__('Select')} disabled={disabled}>
											{selectOptions.map((val, index) => <Option key={index} value={val.user_id}>{val.name}, {val.titles}</Option>)}
										</Select>
									</FormItem>
									<Tooltip className='tooltip-title' title={__('Please contact us if you need to change the number of signatories required.')}>
										<div className="form-email-help-icon">?</div>
									</Tooltip>
									<p className="tips">
									{__('Select a signatory and their title.')}
									</p>
								</div>
								<FormItem
									name="certificate2_user_id"
									label={__('Certificate (2nd)')}
									// $onFieldChange={() => this.onFieldChange('certificate1_user_id')}
									required
									colon={false}
									issame
									validMessage={{
										required: __('Certificate (2nd) is required'),
									}}
									$validators={{
										issame: val => val !== $params.certificate1_user_id || __('Can not same with Certificate (1st)')
									}}
								>
									<Select className="form-length" allowClear placeholder={__('Select')} disabled={disabled}>
										{selectOptions.map((val, index) => <Option key={index} value={val.user_id}>{val.name}, {val.titles}</Option>)}
									</Select>
								</FormItem>
								<p className="tips">
									{__('Select a signatory and their title.')}
								</p>
							</div>
						}
						<FormItem
							name="equity_plan_awards"
							label={__('Equity plan awards')}
							required
							colon={false}
							validMessage={{
								required: __('Equity plan awards is required'),
							}}
						>
							<Select className="form-length" allowClear placeholder={__('Select')} disabled={disabled}>
								{selectOptions.map((val, index) => <Option key={index} value={val.user_id}>{val.name}, {val.titles}</Option>)}
							</Select>
						</FormItem>
						<FormItem
							name="convertibles"
							label={__('Convertibles')}
							required
							colon={false}
							validMessage={{
								required: __('Convertibles is required'),
							}}
						>
							<Select className="form-length" allowClear placeholder={__('Select')}disabled={disabled}>
								{selectOptions.map((val, index) => <Option key={index} value={val.user_id}>{val.name}, {val.titles}</Option>)}
							</Select>
						</FormItem>
						<FormItem
							name="warrants"
							label={__('Warrants')}
							required
							colon={false}
							validMessage={{
								required: __('Warrants is required'),
							}}
						>
							<Select className="form-length" allowClear placeholder={__('Select')} disabled={disabled}>
								{selectOptions.map((val, index) => <Option key={index} value={val.user_id}>{val.name}, {val.titles}</Option>)}
							</Select>
						</FormItem>


						<div className="btns">
							<Permission type="company_permission_signatories_edit"><Button type="primary" loading={loading} onClick={this.onSubmit}>{__('Update signatories')}</Button></Permission>
						</div>
					</div>
				</div>
			</Spin>
		</div>
	}
}