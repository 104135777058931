import React, { Component, useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { post } from 'srcPath/http';
import Loading from 'components/Loading'
import './style.scss';

class AwardCategoryModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			awardValue: '',
			notes: '',
			awardValidate: {
				validateStatus: 'success',
				errorMsg: null
			}
		}
	}
	componentDidMount = () => {
		const { params, isAdd } = this.props
		if (!isAdd) {
			const { name, notes } = params || {}
			this.setState({
				awardValue: name,
				notes
			})
			this.formRef.current.setFieldsValue({
				awardValue: name,
				notes
			});
		}

	}
	formRef = React.createRef()
	bindChangeAward(e) {
		let awardValue = e.target.value
		this.setState({
			awardValue
		})
		this.validateAward()
	}
	bindChangeNotes(e) {
		let notes = e.target.value
		this.setState({
			notes
		})
	}
	onSubmit = () => {
		let { close, callback, tableList, params, isAdd } = this.props
		let newTablelist = [...tableList]
		const { id } = params || {}
		const { awardValue: name, notes } = this.state
		if (!name) {
			let awardValidate = {
				validateStatus: 'error',
				errorMsg: __("Award category name is required")
			}
			this.setState({
				awardValidate
			})
			return
		} else {
			if (!isAdd) {
				let idx = newTablelist && newTablelist.findIndex(item => item.id == id)
				newTablelist.splice(idx, 1)
			}
			let isError = newTablelist && newTablelist.findIndex(item => item.name == name)
			if (isError != -1) {
				let awardValidate = {
					validateStatus: 'error',
					errorMsg: __("Duplicated award category name!")
				}
				this.setState({
					awardValidate
				})
				return
			}
		}
		let data = { id, name, notes }
		Loading.global(true)
		post(isAdd ? 'addAwardCategory' : 'editAwardCategory', data).then(res => {
			let { data: resDate } = res || {}
			let { code } = resDate || {}
			if (code == 0) {
				callback && callback()
				close()
			} else {
				message.error(data.data)
			}
			Loading.global(false)
		}).catch(function (error) {
			Loading.global(false)
			message.error(error.data)
		});

	}
	validateAward() {
		let awardValidate = {
			validateStatus: 'success',
			errorMsg: null
		}
		this.setState({
			awardValidate
		})
	}
	render() {
		const { awardValue, notes, awardValidate } = this.state
		const { email } = this.props
		return <div className="award-model">
			<div className="award-form">
				<Form ref={this.formRef} name="control-hooks">
					<div className="name">
						<Form.Item name="awardValue" label={__("Award category name")} validateStatus={awardValidate.validateStatus} help={awardValidate.errorMsg}>
							<Input value={awardValue} onChange={this.bindChangeAward.bind(this)} autoComplete="off" />
						</Form.Item>
						<i className="required">- {__('Required')}</i>
					</div>

					<Form.Item name="notes" label={__("Notes")}>
						<Input.TextArea value={notes} onChange={this.bindChangeNotes.bind(this)} />
					</Form.Item>
				</Form>
			</div>
			<Button type="primary" loading={this.state.loading} onClick={this.onSubmit}>{__('Save')}</Button>
		</div>
	}
}

export default AwardCategoryModel