import React from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import Utils, { I, calculator } from "Utils";
import { post } from 'srcPath/http';
import {formatNumber, filterAmount} from "Utils";
import {DatePicker, Empty} from "antd";
import moment from "moment";
import DateSelector from "../../../../../components/DateSelector";
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import security from "../../../../../stores/security";

class EditEquityPlan extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            dateFormat:"DD/MM/YYYY",
            initPlanName:"TTT454",
            initPlanPrefix:"EA87",
            existingPrefix:["abc"],
            existingPlanName:["abc"],
            selectFile:[
                {name:__("No file selected")}
            ],
            preSize:"",
            isLegalSize:1,
            isAmendLegalSize:1,
            amendType:"",
            equityPlanList:[],
            cs_plan_list:[],
            planName:"",
            amendSize:"",
            amendAvailable:"",
            amendPlanId:"",
            amendId:"",
            exceedErrorText:"exceeds total authorized shares",
            curAuthorizedShares:10000,
            modifyStatus:0,
            amendmentList:[],
            amendData:{
                incentive_plan_file:{name:"",file_obj:""},
                plan_size:"",
                board_approval_date: "",
                stockholder_approval_date:"",
                board_consent_file:{name:"",file_obj:""},
                stockholder_consent_file:{name:"",file_obj:""},
            },
            data:{
                plan_name:"",
                share_class_id: "",
                prefix: "EA",
                size: "",
                incentive_plan_file:{name:__("No file uploaded"),file_obj:""},
                board_approval_date: "",
                shareholder_approval_date: "",
                terms_of_plan: "15 years",
                option_grant_expiration: {years:"10 years",minus_checked:false},
                exercise_periods: [
                    {title:__("Voluntary termination"),postKey:"voluntary_termination",value:false,num:"3",numType:"Months"},
                    {title:__("Involutary termination"),postKey:"involuntary_termination",value:false, num:"3",numType:"Months"},
                    {title:__("Termination with cause"),postKey:"involuntary_termination_cause",value:false, num:"1",numType:"Months"},
                    {title:__("Death"),value:false,postKey:"death_exercise", num:2,numType:"Years"},
                    {title:__("Disability"),value:false,postKey:"disability_exercise", num:1,numType:"Years"},
                    {title:__("Retirement"),value:false, num:1,numType:"Years"},
                ],
                repurchase_periods: {num:"",numType:"Months"},
                // equity_incentive_plan:"No file uploaded",
                // new_plan_size:"",
                // stockholder_approval_date:"",
                // board_consent:"No file uploaded",
                // stockholder_consent:"No file uploaded",
            },
            repurchasedStatus:false,
            showErrorInfo:"dontshow",
            isShowEmptyError:0,
            isShowExceedErrorInfo:0,
            requiredKey:["plan_name","prefix","size"],
            numberOfYears:[
                "1 year",
                "2 years",
                "3 years",
                "4 years",
                "5 years",
                "6 years",
                "7 years",
                "8 years",
                "9 years",
                "10 years",
                "11 years",
                "12 years",
                "13 years",
                "14 years",
                "15 years",
                "16 years",
                "17 years",
                "18 years",
                "19 years",
                "20 years",
            ],
            addStatus: 0
        }
    }
    backToAddMore=()=>{
        //close:equityAwardsModal
        //open: setStockModal
        window.$("#equityAwardsModal").modal("hide");
        $("#button-2-1 button").trigger("click")
    }

    setFileName=(key,e)=>{
        let fileName = e.target.files[0];
        let curData = this.state.data;
        Utils.uploadFile(fileName).then(
            (result)=>{
                curData.incentive_plan_file.name = fileName.name;
                curData.incentive_plan_file.file_obj = result;
                this.setState({
                    data:curData
                })
            }
        )
        $(".edit_equity-file").addClass("eqfile-box");
        $(".edit_file-del").removeClass("hidden")
    }
    selectAmendFile=(e)=>{
        let selectFile = e.target.files[0];
        let curAmendData = this.state.amendData;
        let fileKey = e.target.name;
        Utils.uploadFile(selectFile).then(
            (result)=>{
                curAmendData[fileKey].name = selectFile.name;
                curAmendData[fileKey].file_obj = result;
                this.setState({
                    amendData:curAmendData
                })
            }
        )

    }
    fileDelete =()=>{
        let curData = this.state.data;
        curData.incentive_plan_file.name = __("No file uploaded");
        curData.incentive_plan_file.file_obj="";
        this.refs.incentive_plan.value="";
        this.setState ({
            data:curData
        });
        $(".edit_equity-file").removeClass("eqfile-box");
        $(".edit_file-del").addClass("hidden");
    }

    initStep=()=>{
        // this.props.history.push({pathname:"/home"});
    }
    changeStatus=(key,e)=>{
        let curData = this.state.data;
        curData.exercise_periods[key].value = !curData.exercise_periods[key].value;
        this.setState({
            data:curData
        })
    }
    initPageData=()=>{
        axios.post('https://api.getsprout.co', {
            company_id:Utils.getLocalCompanyInfo().company_id,
            action:'getDateOfIncorporation',
            data:{
                withShareListType:"CS",
                withPrefix:1,
                withPlanName:1,
            }
        }).then((response)=> {
            if(response.data.code === 0){
                this.setState({
                    cs_plan_list:response.data.data.plan_list,
                    existingPrefix:response.data.data.prefixList,
                    existingPlanName:response.data.data.planNameList,
                });
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    setExercisePeriod(key,valueKey,e){ //第几个元素以及修改的是哪个值(num or numType)
        let curData = this.state.data;
        if(valueKey === 'num'){
            curData.exercise_periods[key][valueKey] = Utils.checkNumInput(e.target.value);
        }else{
            curData.exercise_periods[key][valueKey] = e.target.value;
        }
        this.setState({
            data:curData
        });
    }
    setRepurchasePeriods=(valueKey,e)=>{
        let curData = this.state.data;
        curData.repurchase_periods[valueKey] = Utils.checkNumInput(e.target.value);
        this.setState({
            data:curData
        })
    }
    optionGrantExpiration=(valueKey,e)=>{
        let curData = this.state.data;
        if(valueKey === "minus_checked"){
            curData.option_grant_expiration[valueKey] = !curData.option_grant_expiration[valueKey];
        }else{
            curData.option_grant_expiration[valueKey] = e.target.value;
        }
        this.setState({
            data:curData
        })
    }

    setRepuchaseState=()=>{
        this.setState({
            repurchasedStatus:!this.state.repurchasedStatus
        })
    }
    recordData=(e)=>{
        let dataKey = e.target.name;
        let dataVal = e.target.value;
        let curData = this.state.data;
        curData[dataKey] = dataVal;
        this.setState({
            data:curData
        })
    }

    /**
     * input change通用事件
     * @param isDecimal 是否需要小数
     * @param e
     * 5 digit
     */
    inputChange5Digit = (isDecimal,e) => {
        const { minus } = calculator;
        const { preSize } = this.state;
        const { target: element } = e;
        const { name: dataKey } = element;
        const filter = filterAmount(e, 9999999999999, isDecimal, 5);
        let curData = this.state.data;
        curData[dataKey] = filter.value;
        if(minus(Utils.resetNumberWithCommas(e.target.value), Utils.resetNumberWithCommas(preSize))<0){
            this.setState({
                isLegalSize : 0,
                data: curData
            }, () => {
                element.selectionStart = element.selectionEnd = filter.cursorPositionX;
            });
            $("#size").addClass("has-error");
        }else{
            this.setState({
                isLegalSize : 1,
                data: curData
            }, () => {
                element.selectionStart = element.selectionEnd = filter.cursorPositionX;
            });
            $("#size").removeClass("has-error");
        }
    }
    showErrorList=(emptyCheck,selectCheck,e)=>{
        this.state.requiredKey.map((value,key)=>{
            if(this.state.data[value].length ===0){
                $("#"+value).addClass("has-error");
            }else{
                $("#"+value).removeClass("has-error");
            }
        });
        this.setState({
            isShowEmptyError:emptyCheck,
            showErrorInfo:selectCheck,
        });
    }

    recordNumber=(isDecimal,e)=>{
        const { target: element } = e;
        const { name: dataKey } = element;
        const filter = filterAmount(e, 9999999999999, isDecimal, 5);
        let amendData = this.state.amendData;
        amendData[dataKey] = filter.value;
        this.setState({
            amendData
        }, () => {
            element.selectionStart = element.selectionEnd = filter.cursorPositionX;
        });

    }

    checkPlanSize=(e)=>{
        const { minus } = calculator;
        const { amendSize } = this.state;
        const { target: element } = e;
        if(minus(Utils.resetNumberWithCommas(element.value), Utils.resetNumberWithCommas(amendSize))<0){
            this.setState({
                isAmendLegalSize : 0,
            });
            $("#new_plan_size").addClass("has-error");
        }else{
            this.setState({
                isAmendLegalSize : 1,
            });
            $("#new_plan_size").removeClass("has-error");
        }

    }
    resetActive=()=>{
        window.$("#editEquityAwardsModal .tab-pane").removeClass("active");
        window.$("#edit_equity_awards_step1").addClass("active");
        window.$(".dropdown_li").removeClass("active");
        this.closeModalEaPlanList();
    }
    getInitEaPlan=(key,id,e)=>{
        post('getEaPlanInfoById',
            {   id:id,
                withPrefix:1,
                withPlanName:1,
            }
            ).then((response)=> {
            if(response.data.code === 0){
                let responseData = response.data.data.planInfo;
                let exercisePeriods = [
                    {title:__("Voluntary termination"),postKey:"voluntary_termination",value:false,num:"3",numType:"Months"},
                    {title:__("Involutary termination"),postKey:"involuntary_termination",value:false, num:"",numType:"Months"},
                    {title:__("Termination with cause"),postKey:"involuntary_termination_cause",value:false, num:"",numType:"Months"},
                    {title:__("Death"),value:false,postKey:"death_exercise", num:2,numType:"Years"},
                    {title:__("Disability"),value:false,postKey:"disability_exercise", num:1,numType:"Years"},
                    {title:__("Retirement"),value:false,postKey:"retirement_exercise", num:1,numType:"Years"},
                ];
                let jsonKeys = [
                    "voluntary_termination","involuntary_termination","involuntary_termination_cause",
                    "death_exercise","disability_exercise","retirement_exercise",
                ];
                for(let i=0;i<jsonKeys.length;i++){
                    let countKey = jsonKeys[i]+"_count";
                    if(responseData[countKey] && responseData[countKey]>0){
                        exercisePeriods[i].value = true;
                        exercisePeriods[i].num = responseData[countKey];
                        exercisePeriods[i].numType = responseData[jsonKeys[i]+"_period"];
                    }
                }
                responseData.exercise_periods = exercisePeriods;
                // responseData.incentive_plan_file.name = responseData.incentive_plan_file.name;
                if(responseData.incentive_plan_file.real_name!=""){
                    $(".edit_file-del").removeClass("hidden")
                }
                this.setState({
                    data:responseData,
                    preSize:responseData.size,
                    existingPrefix:response.data.data.prefixList,
                    existingPlanName:response.data.data.planNameList,
                    initPlanName:responseData.plan_name,
                    initPlanPrefix:responseData.prefix,
                })
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
        this.initPageData();
    }
    getAmendmentById=(key,id,e)=>{
        post('getAmendmentById',{id:id}).then((response)=> {
            if(response.data.code === 0){
                this.setState({
                    amendData:response.data.data,
                    amendType:"Edit",
                    amendId:id
                })
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    getAmendmentList=(key,id,name,size,available)=>{
        post('getAmendmentList',{plan_id:id}).then((response)=> {
            if(response.data.code === 0){
                this.setState({
                    amendmentList:response.data.data,
                    planName:name,
                    amendPlanId:id,
                    amendSize:size,
                    amendAvailable:available,
                })
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    changeAmendType=()=>{
        let curData = this.state.amendData;
        curData.incentive_plan_file.name="";
        curData.plan_size="";
        curData.board_approval_date="";
        curData.stockholder_approval_date="";
        curData.board_consent_file.name="";
        curData.stockholder_consent_file.name="";
        this.setState({
            amendType:"Add",
            amendData:curData
        })
    }
    addAmendment=()=>{
        if(this.state.addStatus == 1) return;
        this.setState({addStatus : 1})
        let requestData = this.state.amendData;
        requestData.plan_id = this.state.amendPlanId;
        post('addAmendment',requestData).then((response)=> {
            if(response.data.code === 0){
                this.setState({addStatus : 0})
                this.resetActive();
                window.$("#editEquityAwardsModal").modal("hide")
                this.props.getEaList();

            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    EditAmendment=()=>{
        let requestData = this.state.amendData;
        requestData.id = this.state.amendId;
        post('editAmendment',requestData).then((response)=> {
            if(response.data.code === 0){
                this.resetActive();
                window.$("#editEquityAwardsModal").modal("hide")
                this.props.getEaList();
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    editEaDateChange = (key, moment_key, date) => {
        const { dateFormat } = this.state;
        let curData = this.state.data;
        curData[key] = date.format( dateFormat);
        curData[moment_key] = date;
        this.setState({
            data:curData
        });
    }
    amendDateChange = (key, moment_key, date) => {
        const { dateFormat } = this.state;
        let curData = this.state.amendData;
        curData[key] = date.format( dateFormat);
        curData[moment_key] = date;
        this.setState({
            amendData:curData
        });
    }

    modifyOptionPlan=()=>{
        if(this.state.modifyStatus==1){
            return
        }
        this.setState({
            modifyStatus:1
        })
        let requestData = this.state.data;
        post('editEaPlan',requestData).then((response)=> {
            if(response.data.code === 0){
                // window.location.reload();
                this.setState({
                    modifyStatus:0
                })
                window.$("#editEquityAwardsModal").modal("hide");
                this.resetActive();
                this.props.getEaList();
            }else{
                console.log("failed, error code is: "+response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    closeModalEaPlanList=()=>{
        window.$("#editEquityAwardsModal").modal("hide");
    }
    render(){
        const { eaPlanList } = this.props;
        const { data, dateFormat, amendData, isLegalSize, preSize, isAmendLegalSize } = this.state;
        let isFractionalShare = security.companyInfo.fractional_control;

        let errorCheck = "dontshow";
        if(this.state.repurchasedStatus && data.repurchase_periods.num.length<1){
            errorCheck = "show";
        }
        data.exercise_periods.map((val,key)=>{
            if(val.value === true && val.num.length===0){
                errorCheck = "show";
            }
        })
        let isShowEmptyError = 0;
        if(data.plan_name.length===0 || data.prefix.length===0 || data.size.length===0){
            isShowEmptyError = 1;
        }

        let showButton="";
        if(errorCheck==="dontshow" && isShowEmptyError===0){
            showButton = <button onClick={this.modifyOptionPlan} className="btn boxbtn-to bg-darkblue update-btn">{this.state.modifyStatus==0? __("Modify"):__("Modifying")}</button>
        }else{
            showButton = <button onClick={this.showErrorList.bind(this,isShowEmptyError,errorCheck)} className="btn boxbtn-to bg-darkblue update-btn">{__('Modify')}</button>;
        }

        let isLegalPrefix = 1; //默认为合法的输入
        for(let i=0;i<this.state.existingPrefix.length;i++){
            if(data.prefix!=this.state.initPlanPrefix &&  data.prefix == this.state.existingPrefix[i]){
                isLegalPrefix = 0;  //有重复改为非法
            }
        }
        let isLegalPlanName = 1; //默认为合法的输入
        for(let i=0;i<this.state.existingPlanName.length;i++){
            if(this.state.data.plan_name!=this.state.initPlanName && this.state.data.plan_name == this.state.existingPlanName[i]){
                isLegalPlanName = 0;  //有重复改为非法
            }
        }
        if(isLegalPrefix === 0 || isLegalPlanName ===0 || isLegalSize === 0){
            //重复的prefix，按钮无任何反应
            showButton = <button  className="btn boxbtn-to bg-darkblue update-btn">{__('Modify')}</button>
        }
        return(
            <div className="modal fade" id="editEquityAwardsModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
                <div className="modal-dialog dialog-top40 dialog1230">
                    <div className="tab-content">
                        <div className="modal-content content680 tab-pane active" id="edit_equity_awards_step1">
                            <div className="modal-header box-header">
                                <button type="button" onClick={this.closeModalEaPlanList} className="close shut-btn" aria-hidden="true">×</button>
                                <h4 className="modal-title">{__('Equity plans')}</h4>
                            </div>
                            <div className="modal-body equity-body">
                                <table className="table viewHoldings-table equity_awards_table width100perc">
                                    <tbody>
                                        <tr className="first-row">
                                            <td>{__('Equity plan name')}</td>
                                            <td>{__('Plan size')}</td>
                                            <td>{__('Available')}</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        {eaPlanList.length===0 &&
                                        <tr>
                                            <td colSpan={5}><Empty/></td>
                                        </tr>
                                        }
                                        {eaPlanList.length>0 && eaPlanList.map((value,key)=>{
                                            let proportion = 0;
                                            if(value.available<=0){
                                                proportion =0
                                            }else{
                                                proportion = value.available/value.size*100
                                            }
                                            return(
                                                <tr className="equity_contentRow bg-lightgray" key={key}>
                                                    <td>{value.plan_name}</td>
                                                    <td>{formatFractionalNumber(value.size)}</td>
                                                    <td>{formatFractionalNumber(value.available)}</td>
                                                    <td>
                                                        <div className="progress option-progress equity_awards_progress">
                                                            <div style={{width:proportion+"%"}} className="progress-bar option-progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                                                                <span className="sr-only"> Complete</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="btn-group">
                                                            <button aria-haspopup="true" aria-expanded="true" id="edit_dropdown" className="btn manage-btn height32 white-text bg-darkblue dropdown-toggle" data-toggle="dropdown">{__('Actions')}<span className="triangle triangle-whitedown action-triangle"></span></button>
                                                            <ul className="dropdown-menu mange-downmenu width160"  aria-labelledby="edit_dropdown">
                                                                <li className="dropdown_li"><a onClick={this.getInitEaPlan.bind(this,key,value.id)} data-target="#edit_equity_awards_step2" data-toggle="tab">{__('Edit plan details')}</a></li>
                                                                <li className="dropdown_li"><a onClick={this.getAmendmentList.bind(this,key,value.id,value.plan_name,value.size,value.available)} data-target="#edit_equity_awards_step3" data-toggle="tab">{__('Amend plan size')}</a></li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-content equityAwards-content content680 tab-pane"  id="edit_equity_awards_step2">
                            <div className="modal-header box-header">
                                <button type="button" onClick={this.resetActive} className="close shut-btn" aria-hidden="true">×</button>
                                <h4 className="modal-title" id="myModalLabel">{__('Stock option awards plan')}</h4>
                            </div>
                            <div className="modal-body equity-body">
                                <div>
                                    { isLegalSize==0 &&
                                    <div id="exerciseQuantityError" className="alert alert-warning alert-dismissible pink-box">
                                        <p>{__('The new plan size needs to be larger than the current allocation.')}</p>
                                    </div>
                                    }
                                    <div className="equity-item-header bg-seablue">
                                        <h5>{__('Basic plan information')}</h5>
                                    </div>
                                    <form className="form-horizontal form_bg">
                                        <div className="form-group ">
                                            <label className="col-xs-5 add-label">{__('Equity plan name')}</label>
                                            <div id="plan_name" className="col-xs-7">
                                                <input name="plan_name" onChange={this.recordData} value={this.state.data.plan_name} className="form-control" type="text" placeholder="e.g. Roots Tech Equity Incentive Plan" />
                                            </div>
                                        </div>
                                        <div className="form-group ">
                                            <label className="col-xs-5 add-label">{__('Common share class')}</label>
                                            <div className="col-xs-7">
                                                <select name="share_class_id" onChange={this.recordData} value={this.state.data.share_class_id} className="form-control select-container select-bgwhite">
                                                    <option value="">--</option>
                                                    {Object.values(this.state.cs_plan_list).map((value,key)=>{
                                                        return(
                                                            <option key={key} value={value.id}>{value.share_class_name} ({value.prefix})</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            <p className="text-muted col-xs-7 col-xs-push-5">{__('When options are issued, this will be the share class used to issue them.')}</p>
                                        </div>
                                        <div className="form-group ">
                                            <label className="col-xs-5 add-label">
                                                {__('Prefix')}
                                                <a className="convertible-ques">
                                                    <p className="convertible-tiptext tiptext-top74">{__('Please contact Sprout if you need to change Prefix.')}</p>
                                                </a>
                                            </label>
                                            <div id="prefix" className="col-xs-4">
                                                <input name="prefix" disabled={true} onChange={this.recordData} value={this.state.data.prefix} className="form-control uppercase_form" type="text" placeholder="e.g. " />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div>
                                    <div className="equity-item-header bg-seablue">
                                        <h5>{__('Plan details')}</h5>
                                    </div>
                                    <form className="form-horizontal form_bg">
                                        <div className="form-group ">
                                            <label className="col-xs-5 add-label">
                                                {__('Plan size')}
                                                <a className="convertible-ques">
                                                    <p className="convertible-tiptext tiptext-top96">{__('We recommend the plan size to be 10-20% of Total Authorized Shares.')}</p>
                                                </a>
                                            </label>
                                            <div id="size" className="col-xs-4">
                                                { isFractionalShare == 1 ?
                                                    <input name="size" onChange={this.inputChange5Digit.bind(this,true)} value={this.state.data.size} className="form-control" type="text" placeholder="e.g. 1,500,000" /> :
                                                    <input name="size" onChange={this.inputChange5Digit.bind(this,false)} value={this.state.data.size} className="form-control" type="text" placeholder="e.g. 1,500,000" />
                                                }
                                            </div>
                                        </div>
                                        <div className="form-group ">
                                            <label className="col-xs-5 add-label">{__('Equity incentive plan')}</label>
                                            <div className="col-xs-4">
                                                <div className="select-btn select-blue convertiblenote-select-btn">
                                                    {__('Choose a file')}
                                                    <label htmlFor="incentiveFile" className="sr-only" >File input</label>
                                                    <input name="incentive_plan_file"  onChange={this.setFileName.bind(this,"incentive_plan_file")} id="incentiveFile" ref="incentive_plan" type="file" />
                                                </div>
                                            </div>
                                            <div className="col-xs-4 col-xs-push-5">
                                                <div className="file-box equity-file edit_equity-file">
                                                    <span onClick={this.fileDelete} className="close edit_file-del file-del eafile-del hidden">x</span>
                                                    <p className="text-muted">{this.state.data.incentive_plan_file.name?this.state.data.incentive_plan_file.name:__("No file uploaded ")}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group ">
                                            <div className="col-xs-5">
                                                <label className="add-label">{__('Board approval date')}<br /><i className="text-muted">- {__('Optional')}</i></label>
                                            </div>
                                            <div className="col-xs-4">
                                                <DateSelector
                                                    onChange={this.editEaDateChange.bind(this, 'board_approval_date', 'board_approval_date_moment')}
                                                    value={I(data['board_approval_date_moment'], I(data['board_approval_date'], '') ? moment(data['board_approval_date'], dateFormat) : '')}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group ">
                                            <div className="col-xs-5">
                                                <label className="add-label">{__('Shareholder approval date')}<br /><i className="text-muted">- {__('Optional')}</i></label>
                                            </div>
                                            <div className="col-xs-4">
                                                <DateSelector
                                                    onChange={this.editEaDateChange.bind(this, 'shareholder_approval_date', 'shareholder_approval_date_moment')}
                                                    value={I(data['shareholder_approval_date_moment'], I(data['shareholder_approval_date'], '') ? moment(data['shareholder_approval_date'], dateFormat) : '')}
                                                />
                                            </div>
                                            <p className="text-muted col-xs-5">{__('Shareholder approval date can be entered later.')}</p>
                                        </div>
                                    </form>
                                </div>
                                <div>
                                    <div className="equity-item-header bg-seablue">
                                        <h5>{__('Term and expiration')}<i className="optional-color optional-font">- {__('Optional')}</i></h5>
                                    </div>
                                    <form className="form-horizontal form_bg">
                                        <div className="form-group ">
                                            <label className="col-xs-5 add-label">{__('Term of plan')}</label>
                                            <div className="col-xs-4">
                                                <select name="terms_of_plan" value={this.state.data.terms_of_plan} onChange={this.recordData} className="form-control select-container select-bgwhite">
                                                    <option>-----</option>
                                                    {this.state.numberOfYears.map((value,key)=>{
                                                        return(
                                                            <option key={key}>{value}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group ">
                                            <label className="col-xs-5 add-label">{__('Option grant expiration')}</label>
                                            <div className="col-xs-4">
                                                <select onChange={this.optionGrantExpiration.bind(this,"years")} value={this.state.data.option_grant_expiration.years} className="form-control select-container select-bgwhite">
                                                    <option>-----</option>
                                                    {this.state.numberOfYears.map((value,key)=>{
                                                        return(
                                                            <option key={key}>{value}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            <div className="col-xs-3 no-padding">
                                                <div className="checkbox send-check text-muted">
                                                    <input onChange={this.optionGrantExpiration.bind(this,"minus_checked")} value={this.state.data.option_grant_expiration.minus_checked} type="checkbox" className="send-box" id="minus-box"/>
                                                    <label className="send-div" htmlFor="minus-box"></label><span className="send-label"> &nbsp;{__('Minus one day')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div>
                                    <div className="equity-item-header bg-seablue">
                                        <h5>{__('Exercise periods')}<i className="optional-color optional-font">- {__('Optional')}</i></h5>
                                    </div>
                                    <form className="form-horizontal form_bg">
                                        <div className="equity-tipbox">
                                            <div className="light-tip"></div>
                                            <p>{__('Changes to these fields will only apply to future issuances.')}</p>
                                        </div>
                                        {this.state.data.exercise_periods.map((val,k)=>{
                                            let inputKey = "checked"+k;
                                            let showClass = "";
                                            if(val.value){
                                                showClass = 'checked-show';
                                            }else{
                                                showClass = 'checked-hidden';
                                            }
                                            return (
                                                <div key={k} className="form-group">
                                                    <label className="col-xs-5 add-label">{val.title}</label>
                                                    <div className="col-xs-1 no-paddingright">
                                                        <div className="checkbox send-check text-muted">
                                                            <input type="checkbox" className="send-box" onChange={this.changeStatus.bind(this,k)}  id={inputKey} checked={val.value} />
                                                            <label className="send-div" htmlFor={inputKey}></label>
                                                        </div>
                                                    </div>
                                                    <div className={showClass}>
                                                        <div className="col-xs-3">
                                                            <input  value={val.num} onChange={this.setExercisePeriod.bind(this,k,"num")} className="form-control" type="text" placeholder="e.g. 3" />
                                                        </div>
                                                        <div className="col-xs-3 no-paddingleft">
                                                            <select value={val.numType} onChange={this.setExercisePeriod.bind(this,k,"numType")} className="form-control select-container select-bgwhite">
                                                                <option>Days</option>
                                                                <option>Months</option>
                                                                <option>Years</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </form>
                                </div>
                                <div>
                                    <div className="equity-item-header bg-seablue">
                                        <h5>{__('Repurchase periods')}<i className="optional-color optional-font">- {__('Optional')}</i></h5>
                                    </div>
                                    <form className="form-horizontal form_bg">
                                        <div className="equity-tipbox">
                                            <div className="light-tip"></div>
                                            <p>{__('Changes to these fields will only apply to future issuances.')}</p>
                                        </div>
                                        <div className="form-group">
                                            <div className="col-xs-5">
                                                <label className=" add-label">{__('Repurchase ')}<i className="text-muted">- {__('Optional')}</i></label>
                                            </div>
                                            <div className="col-xs-1 no-paddingright">
                                                <div className="checkbox send-check text-muted">
                                                    <input onChange={this.setRepuchaseState} type="checkbox" className="send-box" id="repurchase_edit" checked={this.state.repurchasedStatus}/>
                                                    <label className="send-div" htmlFor="repurchase_edit"></label>
                                                </div>
                                            </div>

                                            <div className={!this.state.repurchasedStatus && "checked-hidden"}>
                                                <div className="col-xs-3">
                                                    <input value={this.state.data.repurchase_periods.num} onChange={this.setRepurchasePeriods.bind(this,"num")} className="form-control" type="text" placeholder="e.g. 3" />
                                                </div>
                                                <div className="col-xs-3 no-paddingleft">
                                                    <select value={this.state.data.repurchase_periods.numType} onChange={this.setRepurchasePeriods.bind(this,"numType")} className="form-control select-container select-bgwhite">
                                                        <option value="Days">{__('Days')}</option>
                                                        <option value="Months">{__('Months')}</option>
                                                        <option value="Years">{__('Years')}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                {this.state.showErrorInfo === "show" &&
                                <div id="" className="alert alert-warning alert-dismissible pink-box eq-tipbox" role="alert">
                                    <p>{__('If you select a box, please input a corresponding number.')}</p>
                                </div>
                                }
                                {this.state.isShowEmptyError ===1 &&
                                <div className="alert alert-warning alert-dismissible pink-box eq-tipbox" role="alert">
                                    <p>{__("Required field(s) can't be empty.")}</p>
                                </div>
                                }
                                {this.state.isShowExceedErrorInfo ===1 &&
                                <div className="alert alert-warning alert-dismissible pink-box eq-tipbox" role="alert">
                                    <p>Your Plan Size of Equity Awards will exceed total Authorized Shares.  Please adjust or return <a className="text-seablue link-cursor" onClick={this.backToAddMore}>here</a> to adjust your total Authorized Shares.</p>
                                </div>
                                }
                                {isLegalPrefix ===0 &&
                                <div className="stock-form" id="prefix_exist_error">
                                    <div className="alert alert-warning alert-dismissable pink-box" role="alert">
                                        <p>{__('Prefix already exists. Please select another.')}</p>
                                    </div>
                                </div>
                                }
                                {isLegalPlanName ===0 &&
                                <div className="stock-form" id="prefix_exist_error">
                                    <div className="alert alert-warning alert-dismissable pink-box" role="alert">
                                        <p>{__('Plan name already exists. Please select another.')}</p>
                                    </div>
                                </div>
                                }
                            </div>
                            <div className="modal-footer box-footer">
                                {showButton}
                            </div>
                        </div>
                        <div className="modal-content content860 tab-pane" id="edit_equity_awards_step3">
                            <div className="modal-header box-header">
                                <button type="button" className="close shut-btn" onClick={this.resetActive}  aria-hidden="true">×</button>
                                <h4 className="modal-title">List of {this.state.planName} amendments</h4>
                            </div>
                            <div className="modal-body equity-body">
                                <p className="marginBott2">{__('To update the plan size, click "Add new amendment".')}</p>
                                {this.state.amendmentList.length>0&&
                                <table className="table viewHoldings-table equity_awards_table width800 mb-0">
                                    <tbody>
                                    <tr className="first-row">
                                        <td className="width90">{__('Amendment')}</td>
                                        <td className="width140 vested-3Td">{__('New plan size')}</td>
                                        <td className="width120 vested-3Td">{__('Board approval')}</td>
                                        <td className="width180 vested-3Td">{__('Shareholder consent')}</td>
                                        <td className="width200">{__('Documents')}</td>
                                        <td className="width80">&nbsp;</td>
                                    </tr>
                                    {this.state.amendmentList.map((value,key)=>{
                                        return(
                                            <tr className="equity_contentRow bg-lightgray">
                                                <td>{__('Initial')}</td>
                                                <td className="vested-3Td">{formatNumber(value.plan_size,null)}</td>
                                                <td className="vested-3Td">{value.board_approval_date}</td>
                                                <td className="vested-3Td">{value.stockholder_approval_date}</td>
                                                <td className="width160"><p className="file_text text-blue mb-0">{value.incentive_plan_file.name}</p></td>
                                                <td>
                                                    <button className="btn manage-btn equity_edit_btn bg-darkblue"  onClick={this.getAmendmentById.bind(this,key,value.id)}  data-toggle="tab" data-target="#edit_equity_awards_step4">{__('Edit')}</button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>

                                </table>
                                }
                            </div>
                            <div className="modal-footer box-footer">
                                <button type="button" data-toggle="tab" onClick={this.changeAmendType} data-target="#edit_equity_awards_step4" className="btn boxbtn-to bg-darkblue">{__('Add new amendment')}</button>
                            </div>
                        </div>
                        <div className="modal-content content640 tab-pane" id="edit_equity_awards_step4">
                            <div className="modal-header box-header">
                                <button type="button" className="close shut-btn" onClick={this.resetActive}  aria-hidden="true">×</button>
                                <h4 className="modal-title">{__('Amend')} {this.state.planName}</h4>
                            </div>
                            <div className="modal-body padding2026">
                                <div className="body-one">
                                    { isAmendLegalSize==0 &&
                                    <div id="newPlanSizeError" className="alert alert-warning alert-dismissible pink-box">
                                        <p>{__('The new plan size needs to be larger than the current allocation.')}</p>
                                    </div>
                                    }
                                    <form className="form-horizontal">
                                        <div className="form-group ">
                                            <label className="col-xs-5 add-label">{__('Equity incentive plan')}</label>
                                            <div className="col-xs-3 no-paddingright">
                                                <div className="select-btn select-blue convertiblenote-select-btn">
                                                    {__('Choose a file')}
                                                    <label htmlFor="equity_incentive_plan_select" className="sr-only" >File input</label>
                                                    <input onChange={this.selectAmendFile} name="incentive_plan_file" ref="equity_incentive_plan" type="file" id="equity_incentive_plan_select" />
                                                </div>
                                            </div>
                                            <div className="col-xs-4 no-paddingright amend-file-name">
                                                <p className="text-muted">{this.state.amendData.incentive_plan_file.name?this.state.amendData.incentive_plan_file.name:__("No file uploaded")}</p>
                                            </div>
                                        </div>
                                        <div className="form-group add-items">
                                            <label className="col-xs-5 add-label">{__('New plan size')}</label>
                                            <div className="col-xs-3 no-paddingright" id={'new_plan_size'}>
                                                {isFractionalShare == 1 ?
                                                    <input value={this.state.amendData.plan_size} name="plan_size" onChange={this.recordNumber.bind(this,true)}  onBlur={this.checkPlanSize} className="form-control" type="text" placeholder="2,000,000" /> :
                                                    <input value={this.state.amendData.plan_size} name="plan_size" onChange={this.recordNumber.bind(this,false)} onBlur={this.checkPlanSize} className="form-control" type="text" placeholder="2,000,000" />
                                                }
                                            </div>
                                            <p className="text-muted col-xs-7 col-xs-push-5 mb-0">Current plan size: {formatFractionalNumber(this.state.amendSize)} shares, with {formatFractionalNumber(this.state.amendAvailable)} available.</p>
                                        </div>
                                        <div className="form-group add-items ">
                                            <label className="col-xs-5 add-label">{__('Board approval date')}</label>
                                            <div className="col-xs-3 no-paddingright">
                                                <DateSelector
                                                    onChange={this.amendDateChange.bind(this, 'board_approval_date', 'board_approval_date_moment')}
                                                    value={I(amendData['board_approval_date_moment'], I(amendData['board_approval_date'], '') ? moment(amendData['board_approval_date'], dateFormat) : '')}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group add-items ">
                                            <label className="col-xs-5 add-label">{__('Stockholder approval date')}</label>
                                            <div className="col-xs-3 no-paddingright">
                                                <DateSelector
                                                    onChange={this.amendDateChange.bind(this, 'shareholder_approval_date', 'shareholder_approval_date_moment')}
                                                    value={I(amendData['shareholder_approval_date_moment'], I(amendData['shareholder_approval_date'], '') ? moment(amendData['shareholder_approval_date'], dateFormat) : '')}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group ">
                                            <label className="col-xs-5 add-label">Board consent</label>
                                            <div className="col-xs-3 no-paddingright">
                                                <div className="select-btn select-blue convertiblenote-select-btn">
                                                    {__('Choose a file')}
                                                    <label htmlFor="board_consent_select" className="sr-only" >File input</label>
                                                    <input onChange={this.selectAmendFile} name="board_consent_file" ref="board_consent" type="file" id="board_consent_select" />
                                                </div>
                                            </div>
                                            <div className="col-xs-4 no-paddingright amend-file-name">
                                                <p className="text-muted">{this.state.amendData.board_consent_file.name?this.state.amendData.board_consent_file.name:__("No file uploaded")}</p>
                                            </div>
                                            <p className="text-muted col-xs-6 col-xs-push-5 mb-0">{__('Upload a board resolution for this amendment.')}</p>
                                        </div>
                                        <div className="form-group ">
                                            <label className="col-xs-5 add-label">{__('Stockholder consent')}</label>
                                            <div className="col-xs-3 no-paddingright">
                                                <div className="select-btn select-blue convertiblenote-select-btn">
                                                    {__('Choose a file')}
                                                    <label htmlFor="stockholder_consent_select" className="sr-only" >File input</label>
                                                    <input onChange={this.selectAmendFile} name="stockholder_consent_file" ref="stockholder_consent" type="file" id="stockholder_consent_select" />
                                                </div>
                                            </div>
                                            <div className="col-xs-4 no-paddingright amend-file-name">
                                                <p className="text-muted">{this.state.amendData.stockholder_consent_file.name?this.state.amendData.stockholder_consent_file.name:__("No file uploaded")}</p>
                                            </div>
                                            <p className="text-muted col-xs-6 col-xs-push-5 mb-0">{__('Upload a stockholder resolution approving this amendment.')}</p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer box-footer invite-footer">
                                <button type="button" data-toggle="tab" data-target="#edit_equity_awards_step5" className="btn boxbtn-to bg-darkblue">{__('Next: Review This Amendment')}</button>
                            </div>
                        </div>
                        <div className="modal-content content580 tab-pane" id="edit_equity_awards_step5">
                            <div className="modal-header box-header">
                                <a href="#edit_equity_awards_step4" data-toggle="tab" className="back-btn" ></a>
                                <button type="button" className="close shut-btn"  aria-hidden="true" onClick={this.resetActive}>×</button>
                                <h4 className="modal-title">{__('Amend {this.state.planName}')}</h4>
                            </div>
                            <div className="modal-body padding2026">
                                <p>{this.state.planName} pool size will be {this.state.amendData.plan_size} shares, effective as of the Board approval date of {this.state.amendData.board_approval_date}.</p>
                            </div>
                            <div className="modal-footer box-footer invite-footer">
                                {this.state.amendType=="Add" &&
                                <button type="button" className="btn boxbtn-to bg-darkblue"  onClick={this.addAmendment}>{__('Save Amendment')}</button>
                                }
                                {this.state.amendType=="Edit" &&
                                <button type="button" className="btn boxbtn-to bg-darkblue"  onClick={this.EditAmendment}>{__('Save Amendment')}</button>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default EditEquityPlan;

