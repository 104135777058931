import React from 'react';
import Section from '../Section';
import SumaryHolder from '../SumaryHolder';
import { getColumns, renderDate } from './column-config';
import { Table } from 'antd';
import Utils, { formatPercent } from 'Utils';
import formatFractionalNumber from 'Utils/format/formatFractionalNumber';
import { Progress } from 'antd';
import { formatDate } from 'Utils/format/formatDate';
import { printf } from 'Utils/i18n'

import './style.scss'

export default function VestingSchedule(props) {
	const { isToken = false, data: initData = {} } = props
	const { fullyVested, vestingStart, totalShares, vestedShares, vestedSharesList, name, exercisedQuantity, terminateQuantity } = initData || {};
	
	vestedSharesList && vestedSharesList.length && vestedSharesList.map((item, index) => {
		return item.key = index;
	});

	const data = vestedSharesList || [];

	const percent = formatPercent(vestedShares/totalShares, 'multiply');
	const exercisePercent = formatPercent(exercisedQuantity/totalShares, 'multiply');
	const terminatePercent = formatPercent(terminateQuantity/totalShares, 'multiply');

	const getRowStyle = (value, index) => {
		const { status } = value
        return !status ? 'has-expired' : 'not-expired'
    }

	return <div className="ea-vesting-schedule">
		<div className="certificate-detail-section">
			 <SumaryHolder data={props.data} />
		</div>
		{data.length > 0 &&
			<div className="certificate-detail-section">
				<Section title={__('Progress')}>
					<div className="ea-vesting-schedule-progess">
						<div >
						{printf(__('%s of the %s %s %s in %s are currently vested.'), formatFractionalNumber(vestedShares), formatFractionalNumber(totalShares), isToken ? __('tokens') : __('options'), percent, name)}		
						</div>
						<Progress percent={totalShares ? (vestedShares/totalShares) * 100 : 50} showInfo={false} status="active"  strokeColor="#87d068"/>
						<div>
							<span>{__('Vesting start')}: {formatDate(vestingStart)}</span>
							<span>{__('Full Vested')}: {fullyVested ? renderDate(fullyVested) : __('Not vested')}</span>
						</div>
					</div>
					<div className="legend-container">
						<span className="legend-title">{__('Legend')}:</span>
						<span className="legend-blue">{__('Vested shares')}</span>
						<span className="legend-red">{__('Terminated/expired shares')}</span>
					</div>
					<div>
					

						{ exercisedQuantity > 0 && <p>{printf(__('%s of %s %s %s in %s are exercised.'), formatFractionalNumber(exercisedQuantity), formatFractionalNumber(totalShares), isToken ? __('tokens') : __('options'), exercisePercent, name)}.</p>}
						{ terminateQuantity > 0 && <p>{printf(__('%s of %s %s %s in %s are forfeited.'), formatFractionalNumber(terminateQuantity), formatFractionalNumber(totalShares), isToken ? __('tokens') : __('options'), terminatePercent, name)}.</p>}
					</div>
					<Table
						size="small"
						columns={getColumns(isToken)}
						rowClassName={getRowStyle}
						locale={{emptyText: 'No Data'}}
						dataSource={data}
						pagination={false}
						scroll={{ x: true }}
					/>
				</Section>
			</div>
		}
	</div>
}